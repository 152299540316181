(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korinject'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-korinject'.");
    }
    root['korge-root-korinject'] = factory(typeof this['korge-root-korinject'] === 'undefined' ? {} : this['korge-root-korinject'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var classMeta = kotlin_kotlin.$_$.zb;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.w1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var EmptyCoroutineContext_getInstance = kotlin_kotlin.$_$.f4;
  var Result__exceptionOrNull_impl_p6xea9 = kotlin_kotlin.$_$.r2;
  var _Result___get_value__impl__bjfvqg = kotlin_kotlin.$_$.t2;
  var isObject = kotlin_kotlin.$_$.ad;
  var _Result___get_isFailure__impl__jpiriv = kotlin_kotlin.$_$.s2;
  var Continuation = kotlin_kotlin.$_$.eb;
  var isInterface = kotlin_kotlin.$_$.xc;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var getKClass = kotlin_kotlin.$_$.e;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var RuntimeException_init_$Create$ = kotlin_kotlin.$_$.x1;
  var startCoroutine = kotlin_kotlin.$_$.mb;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var CancellationException = kotlin_kotlin.$_$.ta;
  var RuntimeException_init_$Create$_0 = kotlin_kotlin.$_$.z1;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var get = kotlin_kotlin.$_$.gb;
  var fold = kotlin_kotlin.$_$.fb;
  var minusKey = kotlin_kotlin.$_$.hb;
  var plus = kotlin_kotlin.$_$.jb;
  var Element = kotlin_kotlin.$_$.ib;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(RequestContext, 'RequestContext', classMeta);
  setMetadataFor(NotMappedException, 'NotMappedException', classMeta, RuntimeException);
  setMetadataFor(AsyncInjector$getSyncOrNull$slambda, 'AsyncInjector$getSyncOrNull$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(AsyncInjector$getSyncOrNull$2, VOID, classMeta, VOID, [Continuation]);
  setMetadataFor($getProviderOrNullCOROUTINE$0, '$getProviderOrNullCOROUTINE$0', classMeta, CoroutineImpl);
  setMetadataFor($getProviderCOROUTINE$1, '$getProviderCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor($getOrNullCOROUTINE$2, '$getOrNullCOROUTINE$2', classMeta, CoroutineImpl);
  setMetadataFor($getCOROUTINE$3, '$getCOROUTINE$3', classMeta, CoroutineImpl);
  setMetadataFor($createdCOROUTINE$5, '$createdCOROUTINE$5', classMeta, CoroutineImpl);
  setMetadataFor($deinitCOROUTINE$6, '$deinitCOROUTINE$6', classMeta, CoroutineImpl);
  setMetadataFor(AsyncInjector, 'AsyncInjector', classMeta, VOID, VOID, VOID, VOID, [2, 1, 0]);
  setMetadataFor(InjectorAsyncDependency, 'InjectorAsyncDependency', interfaceMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(AsyncDependency, 'AsyncDependency', interfaceMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(AsyncObjectProvider, 'AsyncObjectProvider', interfaceMeta, VOID, VOID, VOID, VOID, [1, 0]);
  setMetadataFor($deinitCOROUTINE$7, '$deinitCOROUTINE$7', classMeta, CoroutineImpl);
  setMetadataFor(InstanceAsyncObjectProvider, 'InstanceAsyncObjectProvider', classMeta, VOID, [AsyncObjectProvider], VOID, VOID, [1, 0]);
  setMetadataFor($getCOROUTINE$9, '$getCOROUTINE$9', classMeta, CoroutineImpl);
  setMetadataFor($deinitCOROUTINE$10, '$deinitCOROUTINE$10', classMeta, CoroutineImpl);
  setMetadataFor(SingletonAsyncObjectProvider, 'SingletonAsyncObjectProvider', classMeta, VOID, [AsyncObjectProvider], VOID, VOID, [1, 0]);
  setMetadataFor($getCOROUTINE$11, '$getCOROUTINE$11', classMeta, CoroutineImpl);
  setMetadataFor(PrototypeAsyncObjectProvider, 'PrototypeAsyncObjectProvider', classMeta, VOID, [AsyncObjectProvider], VOID, VOID, [1, 0]);
  setMetadataFor(AsyncDestructor, 'AsyncDestructor', interfaceMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(AsyncInjectorContext, 'AsyncInjectorContext', classMeta, VOID, [Element]);
  //endregion
  function Companion() {
    Companion_instance = this;
  }
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function RequestContext(initialClazz) {
    this.v9e_1 = initialClazz;
  }
  protoOf(RequestContext).toString = function () {
    return 'RequestContext(initialClazz=' + this.v9e_1 + ')';
  };
  protoOf(RequestContext).hashCode = function () {
    return this.v9e_1.hashCode();
  };
  protoOf(RequestContext).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RequestContext))
      return false;
    var tmp0_other_with_cast = other instanceof RequestContext ? other : THROW_CCE();
    if (!this.v9e_1.equals(tmp0_other_with_cast.v9e_1))
      return false;
    return true;
  };
  function NotMappedException(clazz, requestedByClass, ctx, msg) {
    msg = msg === VOID ? 'Not mapped ' + clazz + ' requested by ' + requestedByClass + ' in ' + ctx : msg;
    RuntimeException_init_$Init$(msg, this);
    captureStack(this, NotMappedException);
    this.w9e_1 = clazz;
    this.x9e_1 = requestedByClass;
    this.y9e_1 = ctx;
    this.z9e_1 = msg;
  }
  function AsyncInjector$getSyncOrNull$slambda(this$0, $clazz, $ctx, resultContinuation) {
    this.i9f_1 = this$0;
    this.j9f_1 = $clazz;
    this.k9f_1 = $ctx;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(AsyncInjector$getSyncOrNull$slambda).l9f = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector$getSyncOrNull$slambda).un = function ($completion) {
    return this.l9f($completion);
  };
  protoOf(AsyncInjector$getSyncOrNull$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.i9f_1.s9f(this.j9f_1, this.k9f_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return suspendResult;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(AsyncInjector$getSyncOrNull$slambda).g1p = function (completion) {
    var i = new AsyncInjector$getSyncOrNull$slambda(this.i9f_1, this.j9f_1, this.k9f_1, completion);
    return i;
  };
  function AsyncInjector$getSyncOrNull$slambda_0(this$0, $clazz, $ctx, resultContinuation) {
    var i = new AsyncInjector$getSyncOrNull$slambda(this$0, $clazz, $ctx, resultContinuation);
    var l = function ($completion) {
      return i.l9f($completion);
    };
    l.$arity = 0;
    return l;
  }
  function AsyncInjector$getSyncOrNull$2($rexception, $rresult) {
    this.u9f_1 = $rexception;
    this.v9f_1 = $rresult;
    this.t9f_1 = EmptyCoroutineContext_getInstance();
  }
  protoOf(AsyncInjector$getSyncOrNull$2).s5 = function () {
    return this.t9f_1;
  };
  protoOf(AsyncInjector$getSyncOrNull$2).t1r = function (result) {
    var exception = Result__exceptionOrNull_impl_p6xea9(result);
    if (!(exception == null)) {
      this.u9f_1._v = exception;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.Result.getOrNull' call
      var tmp;
      if (_Result___get_isFailure__impl__jpiriv(result)) {
        tmp = null;
      } else {
        var tmp_0 = _Result___get_value__impl__bjfvqg(result);
        tmp = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      }
      tmp$ret$0 = tmp;
      this.v9f_1._v = tmp$ret$0;
    }
  };
  protoOf(AsyncInjector$getSyncOrNull$2).q5 = function (result) {
    return this.t1r(result);
  };
  function $getProviderOrNullCOROUTINE$0(_this__u8e3s4, clazz, ctx, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.e9g_1 = _this__u8e3s4;
    this.f9g_1 = clazz;
    this.g9g_1 = ctx;
  }
  protoOf($getProviderOrNullCOROUTINE$0).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 7;
            this.h9g_1 = this.e9g_1.p9f_1.h3(this.f9g_1);
            if (this.h9g_1 == null) {
              this.j9g_1 = this.e9g_1.m9f_1;
              if (this.j9g_1 == null) {
                this.k9g_1 = null;
                this.y7_1 = 2;
                continue $sm;
              } else {
                this.y7_1 = 1;
                suspendResult = this.j9g_1.p9g(this.f9g_1, this.g9g_1, this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              }
            } else {
              this.i9g_1 = this.h9g_1;
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 1:
            this.k9g_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.i9g_1 = this.k9g_1;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.l9g_1 = this.i9g_1;
            if (this.l9g_1 == null) {
              this.n9g_1 = this.e9g_1.q9g();
              if (this.n9g_1 == null) {
                this.o9g_1 = null;
                this.y7_1 = 5;
                continue $sm;
              } else {
                this.y7_1 = 4;
                suspendResult = this.n9g_1(this.f9g_1, this.g9g_1, this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              }
            } else {
              this.m9g_1 = this.l9g_1;
              this.y7_1 = 6;
              continue $sm;
            }

            ;
            break;
          case 4:
            this.o9g_1 = suspendResult;
            this.y7_1 = 5;
            continue $sm;
          case 5:
            var tmp3_safe_receiver = this.o9g_1;
            var tmp_0 = this;
            var tmp_1;
            if (tmp3_safe_receiver == null) {
              tmp_1 = null;
            } else {
              var tmp0_set = this.e9g_1.p9f_1;
              tmp0_set.e5(this.f9g_1, tmp3_safe_receiver);
              tmp_1 = tmp3_safe_receiver;
            }

            tmp_0.m9g_1 = tmp_1;
            this.y7_1 = 6;
            continue $sm;
          case 6:
            var tmp_2 = this.m9g_1;
            return (tmp_2 == null ? true : isInterface(tmp_2, AsyncObjectProvider)) ? tmp_2 : null;
          case 7:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 7) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getProviderCOROUTINE$1(_this__u8e3s4, clazz, ctx, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.z9g_1 = _this__u8e3s4;
    this.a9h_1 = clazz;
    this.b9h_1 = ctx;
  }
  protoOf($getProviderCOROUTINE$1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.z9g_1.p9g(this.a9h_1, this.b9h_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp0_elvis_lhs = suspendResult;
            var tmp_0;
            if (tmp0_elvis_lhs == null) {
              throw new NotMappedException(this.a9h_1, this.b9h_1.v9e_1, this.b9h_1, "Class '" + this.a9h_1 + "' doesn't have constructors " + this.b9h_1);
            } else {
              tmp_0 = tmp0_elvis_lhs;
            }

            return tmp_0;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getOrNullCOROUTINE$2(_this__u8e3s4, clazz, ctx, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.k9h_1 = _this__u8e3s4;
    this.l9h_1 = clazz;
    this.m9h_1 = ctx;
  }
  protoOf($getOrNullCOROUTINE$2).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.k9h_1.p9g(this.l9h_1, this.m9h_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.n9h_1 = suspendResult;
            if (this.n9h_1 == null) {
              this.o9h_1 = null;
              this.y7_1 = 3;
              continue $sm;
            } else {
              this.y7_1 = 2;
              suspendResult = this.n9h_1.p9h(this.k9h_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 2:
            this.o9h_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.o9h_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getCOROUTINE$3(_this__u8e3s4, clazz, ctx, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.y9h_1 = _this__u8e3s4;
    this.z9h_1 = clazz;
    this.a9i_1 = ctx;
  }
  protoOf($getCOROUTINE$3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.y9h_1.c9i(this.z9h_1, this.a9i_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.b9i_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.b9i_1.p9h(this.y9h_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $createdCOROUTINE$5(_this__u8e3s4, instance, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l9i_1 = _this__u8e3s4;
    this.m9i_1 = instance;
  }
  protoOf($createdCOROUTINE$5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            var tmp_0 = this.m9i_1;
            if (!(tmp_0 == null) ? isInterface(tmp_0, AsyncDependency) : false) {
              this.y7_1 = 1;
              suspendResult = this.m9i_1.w22(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.y7_1 = 2;
            continue $sm;
          case 2:
            var tmp_1 = this.m9i_1;
            if (!(tmp_1 == null) ? isInterface(tmp_1, InjectorAsyncDependency) : false) {
              this.y7_1 = 3;
              suspendResult = this.m9i_1.n9i(this.l9i_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 3:
            this.y7_1 = 4;
            continue $sm;
          case 4:
            var tmp_2 = this.m9i_1;
            if (!(tmp_2 == null) ? isInterface(tmp_2, AsyncDestructor) : false) {
              this.l9i_1.r9f_1.b(this.m9i_1);
            }

            return this.m9i_1;
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $deinitCOROUTINE$6(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.w9i_1 = _this__u8e3s4;
  }
  protoOf($deinitCOROUTINE$6).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 7;
            var tmp_0 = this;
            var tmp0_iterator = this.w9i_1.p9f_1;
            tmp_0.x9i_1 = tmp0_iterator.d1().h();
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!this.x9i_1.j()) {
              this.y7_1 = 3;
              continue $sm;
            }

            this.y9i_1 = this.x9i_1.k();
            this.y7_1 = 2;
            suspendResult = this.y9i_1.f1().b9j(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            this.z9i_1 = this.w9i_1.r9f_1.h();
            this.y7_1 = 4;
            continue $sm;
          case 4:
            if (!this.z9i_1.j()) {
              this.y7_1 = 6;
              continue $sm;
            }

            this.a9j_1 = this.z9i_1.k();
            this.y7_1 = 5;
            suspendResult = this.a9j_1.b9j(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            this.y7_1 = 4;
            continue $sm;
          case 6:
            this.w9i_1.r9f_1.e4();
            return Unit_getInstance();
          case 7:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 7) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function AsyncInjector(parent, level) {
    Companion_getInstance();
    parent = parent === VOID ? null : parent;
    level = level === VOID ? 0 : level;
    this.m9f_1 = parent;
    this.n9f_1 = level;
    this.o9f_1 = null;
    this.p9f_1 = LinkedHashMap_init_$Create$();
    var tmp = this;
    var tmp0_safe_receiver = this.m9f_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.q9f_1;
    tmp.q9f_1 = tmp1_elvis_lhs == null ? this : tmp1_elvis_lhs;
    this.c9j(getKClass(AsyncInjector), this);
    var tmp_0 = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp_0.r9f_1 = tmp$ret$0;
  }
  protoOf(AsyncInjector).q9g = function () {
    var tmp1_elvis_lhs = this.o9f_1;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = this.m9f_1;
      tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.o9f_1;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  };
  protoOf(AsyncInjector).d9j = function () {
    return new AsyncInjector(this, this.n9f_1 + 1 | 0);
  };
  protoOf(AsyncInjector).c9j = function (clazz, instance) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.AsyncInjector.mapInstance.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.p9f_1;
    var tmp1_set = new InstanceAsyncObjectProvider(instance);
    tmp0_set.e5(clazz, tmp1_set);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(AsyncInjector).e9j = function (clazz, gen) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.AsyncInjector.mapSingleton.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.p9f_1;
    var tmp1_set = new SingletonAsyncObjectProvider(gen);
    tmp0_set.e5(clazz, tmp1_set);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(AsyncInjector).f9j = function (clazz, gen) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.inject.AsyncInjector.mapPrototype.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.p9f_1;
    var tmp1_set = new PrototypeAsyncObjectProvider(gen);
    tmp0_set.e5(clazz, tmp1_set);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(AsyncInjector).p9g = function (clazz, ctx, $completion) {
    var tmp = new $getProviderOrNullCOROUTINE$0(this, clazz, ctx, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector).c9i = function (clazz, ctx, $completion) {
    var tmp = new $getProviderCOROUTINE$1(this, clazz, ctx, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector).s9f = function (clazz, ctx, $completion) {
    var tmp = new $getOrNullCOROUTINE$2(this, clazz, ctx, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector).g9j = function (clazz, ctx) {
    var tmp0_elvis_lhs = this.h9j(clazz, ctx);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw RuntimeException_init_$Create$("Couldn't get instance of type " + clazz + ' synchronously');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(AsyncInjector).h9j = function (clazz, ctx) {
    var rresult = {_v: null};
    var rexception = {_v: null};
    var tmp$ret$0;
    // Inline function 'kotlin.suspend' call
    var tmp0_suspend = AsyncInjector$getSyncOrNull$slambda_0(this, clazz, ctx, null);
    tmp$ret$0 = tmp0_suspend;
    var tmp = tmp$ret$0;
    startCoroutine(tmp, new AsyncInjector$getSyncOrNull$2(rexception, rresult));
    if (!(rexception._v == null))
      throw ensureNotNull(rexception._v);
    try {
      return rresult._v;
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        if (e instanceof CancellationException)
          throw e;
        throw RuntimeException_init_$Create$_0("Couldn't get instance of type " + clazz + ' synchronously', e);
      } else {
        throw $p;
      }
    }
  };
  protoOf(AsyncInjector).i9j = function (clazz, ctx, $completion) {
    var tmp = new $getCOROUTINE$3(this, clazz, ctx, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector).j9j = function (clazz, ctx, $completion, $super) {
    ctx = ctx === VOID ? new RequestContext(clazz) : ctx;
    return $super === VOID ? this.i9j(clazz, ctx, $completion) : $super.i9j.call(this, clazz, ctx, $completion);
  };
  protoOf(AsyncInjector).toString = function () {
    return 'AsyncInjector(level=' + this.n9f_1 + ')';
  };
  protoOf(AsyncInjector).k9j = function (instance, $completion) {
    var tmp = new $createdCOROUTINE$5(this, instance, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncInjector).b9j = function ($completion) {
    var tmp = new $deinitCOROUTINE$6(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function InjectorAsyncDependency() {
  }
  function AsyncDependency() {
  }
  function AsyncObjectProvider() {
  }
  function $deinitCOROUTINE$7(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.t9j_1 = _this__u8e3s4;
  }
  protoOf($deinitCOROUTINE$7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.t9j_1.w9j_1;
            tmp_0.u9j_1 = (tmp_1 == null ? true : isInterface(tmp_1, AsyncDestructor)) ? tmp_1 : null;
            if (this.u9j_1 == null) {
              this.v9j_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.u9j_1.b9j(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            var tmp_2 = this;
            tmp_2.v9j_1 = Unit_getInstance();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            ;
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function InstanceAsyncObjectProvider(instance) {
    this.w9j_1 = instance;
  }
  protoOf(InstanceAsyncObjectProvider).p9h = function (injector, $completion) {
    return this.w9j_1;
  };
  protoOf(InstanceAsyncObjectProvider).b9j = function ($completion) {
    var tmp = new $deinitCOROUTINE$7(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(InstanceAsyncObjectProvider).toString = function () {
    return 'InstanceAsyncObjectProvider(' + this.w9j_1 + ')';
  };
  function $getCOROUTINE$9(_this__u8e3s4, injector, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.f9k_1 = _this__u8e3s4;
    this.g9k_1 = injector;
  }
  protoOf($getCOROUTINE$9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (this.f9k_1.j9k_1 == null) {
              this.y7_1 = 1;
              suspendResult = this.f9k_1.i9k_1(this.g9k_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 1:
            this.h9k_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.g9k_1.k9j(this.h9k_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            this.f9k_1.j9k_1 = ARGUMENT;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return ensureNotNull(this.f9k_1.j9k_1);
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $deinitCOROUTINE$10(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s9k_1 = _this__u8e3s4;
  }
  protoOf($deinitCOROUTINE$10).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.s9k_1.j9k_1;
            tmp_0.t9k_1 = (tmp_1 == null ? true : isInterface(tmp_1, AsyncDestructor)) ? tmp_1 : null;
            if (this.t9k_1 == null) {
              this.u9k_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.t9k_1.b9j(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            var tmp_2 = this;
            tmp_2.u9k_1 = Unit_getInstance();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            ;
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function SingletonAsyncObjectProvider(generator) {
    this.i9k_1 = generator;
    this.j9k_1 = null;
  }
  protoOf(SingletonAsyncObjectProvider).p9h = function (injector, $completion) {
    var tmp = new $getCOROUTINE$9(this, injector, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(SingletonAsyncObjectProvider).b9j = function ($completion) {
    var tmp = new $deinitCOROUTINE$10(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(SingletonAsyncObjectProvider).toString = function () {
    return 'SingletonAsyncObjectProvider(' + this.j9k_1 + ')';
  };
  function $getCOROUTINE$11(_this__u8e3s4, injector, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d9l_1 = _this__u8e3s4;
    this.e9l_1 = injector;
  }
  protoOf($getCOROUTINE$11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.d9l_1.g9l_1(this.e9l_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.f9l_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.e9l_1.k9j(this.f9l_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function PrototypeAsyncObjectProvider(generator) {
    this.g9l_1 = generator;
  }
  protoOf(PrototypeAsyncObjectProvider).p9h = function (injector, $completion) {
    var tmp = new $getCOROUTINE$11(this, injector, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(PrototypeAsyncObjectProvider).b9j = function ($completion) {
    return Unit_getInstance();
  };
  protoOf(PrototypeAsyncObjectProvider).toString = function () {
    return 'PrototypeAsyncObjectProvider()';
  };
  function AsyncDestructor() {
  }
  function Companion_0() {
    Companion_instance_0 = this;
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function AsyncInjectorContext(injector) {
    Companion_getInstance_0();
    this.h9l_1 = injector;
  }
  protoOf(AsyncInjectorContext).e1 = function () {
    return Companion_getInstance_0();
  };
  function injector($completion) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.getCoroutineContext' call
    tmp$ret$0 = $completion.s5();
    var tmp0_safe_receiver = tmp$ret$0.o8(Companion_getInstance_0());
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.h9l_1;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$('AsyncInjector not in the context, please call withInjector function');
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  //region block: post-declaration
  protoOf(AsyncInjectorContext).o8 = get;
  protoOf(AsyncInjectorContext).u8 = fold;
  protoOf(AsyncInjectorContext).t8 = minusKey;
  protoOf(AsyncInjectorContext).v8 = plus;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = injector;
  _.$_$.b = AsyncDependency;
  _.$_$.c = RequestContext;
  _.$_$.d = AsyncInjectorContext;
  _.$_$.e = AsyncInjector;
  _.$_$.f = InjectorAsyncDependency;
  //endregion
  return _;
}));
