(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './korge-root-klogger.js', './kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js', './korge-root-kmem.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./korge-root-klogger.js'), require('./kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'), require('./korge-root-kmem.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korau'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-korau'.");
    }
    if (typeof this['korge-root-klogger'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korau'. Its dependency 'korge-root-klogger' was not found. Please, check whether 'korge-root-klogger' is loaded prior to 'korge-root-korau'.");
    }
    if (typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korau'. Its dependency 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' was not found. Please, check whether 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' is loaded prior to 'korge-root-korau'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korau'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'korge-root-korau'.");
    }
    root['korge-root-korau'] = factory(typeof this['korge-root-korau'] === 'undefined' ? {} : this['korge-root-korau'], this['kotlin-kotlin-stdlib-js-ir'], this['korge-root-klogger'], this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'], this['korge-root-kmem']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korlibs_klogger_klogger, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core, kotlin_com_soywiz_korlibs_kmem_kmem) {
  'use strict';
  //region block: imports
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var addAll = kotlin_kotlin.$_$.u5;
  var toSet = kotlin_kotlin.$_$.ka;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var addAll_0 = kotlin_kotlin.$_$.t5;
  var classMeta = kotlin_kotlin.$_$.zb;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var isCharSequence = kotlin_kotlin.$_$.sc;
  var trim = kotlin_kotlin.$_$.hh;
  var toString = kotlin_kotlin.$_$.sd;
  var sorted = kotlin_kotlin.$_$.r9;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var lazy = kotlin_kotlin.$_$.cj;
  var toByte = kotlin_kotlin.$_$.pd;
  var Level_INFO_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.c;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var Companion_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.f;
  var Level_ERROR_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.b;
  var Job = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.x;
  var CompletableDeferred = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.q;
  var Deferred = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.u;
  var isInterface = kotlin_kotlin.$_$.xc;
  var Companion_getInstance_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(AudioFormat, 'AudioFormat', classMeta, VOID, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(AudioFormats, 'AudioFormats', classMeta, AudioFormat, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(WAV, 'WAV', classMeta, AudioFormat, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(Companion_0, 'Companion', objectMeta, WAV, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(MP3Decoder, 'MP3Decoder', classMeta, AudioFormat, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(Companion_1, 'Companion', objectMeta, MP3Decoder, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(BaseMinimp3AudioFormat, 'BaseMinimp3AudioFormat', classMeta, AudioFormat, VOID, VOID, VOID, [2, 3, 4]);
  setMetadataFor(Minimp3AudioFormat, 'Minimp3AudioFormat', objectMeta, BaseMinimp3AudioFormat, VOID, VOID, VOID, [2, 4]);
  setMetadataFor(NativeSoundProvider, 'NativeSoundProvider', classMeta, VOID, VOID, VOID, VOID, [1, 4, 3, 2]);
  setMetadataFor(DummyNativeSoundProvider, 'DummyNativeSoundProvider', classMeta, NativeSoundProvider, VOID, VOID, VOID, [1, 4, 3, 2]);
  setMetadataFor(Companion_3, 'Companion', objectMeta, DummyNativeSoundProvider, VOID, VOID, VOID, [1, 4, 3, 2]);
  setMetadataFor(HtmlNativeSoundProvider, 'HtmlNativeSoundProvider', classMeta, NativeSoundProvider, VOID, VOID, VOID, [4, 1, 3, 2]);
  setMetadataFor(HtmlSimpleSound, 'HtmlSimpleSound', objectMeta, VOID, VOID, VOID, VOID, [0, 2, 1]);
  //endregion
  function get_defaultAudioFormats() {
    _init_properties_AudioFormat_kt__9os7np();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = defaultAudioFormats$factory();
    tmp$ret$0 = defaultAudioFormats$delegate.f1();
    return tmp$ret$0;
  }
  var defaultAudioFormats$delegate;
  function Companion() {
    Companion_instance = this;
  }
  protoOf(Companion).b9e = function (formats) {
    return (new AudioFormats()).f9e(formats.slice());
  };
  var Companion_instance;
  function Companion_getInstance_1() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function invalidate($this) {
    $this.e9e_1 = null;
    return $this;
  }
  function AudioFormats() {
    Companion_getInstance_1();
    AudioFormat.call(this, []);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.d9e_1 = tmp$ret$0;
    this.e9e_1 = null;
  }
  protoOf(AudioFormats).w90 = function () {
    if (this.e9e_1 == null) {
      var tmp = this;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.flatMap' call
      var tmp1_flatMap = this.d9e_1;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.flatMapTo' call
      var tmp0_flatMapTo = ArrayList_init_$Create$();
      var tmp0_iterator = tmp1_flatMap.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.audio.format.AudioFormats.<get-extensions>.<anonymous>' call
        tmp$ret$0 = element.w90();
        var list = tmp$ret$0;
        addAll(tmp0_flatMapTo, list);
      }
      tmp$ret$1 = tmp0_flatMapTo;
      tmp$ret$2 = tmp$ret$1;
      tmp.e9e_1 = toSet(tmp$ret$2);
    }
    return ensureNotNull(this.e9e_1);
  };
  protoOf(AudioFormats).f9e = function (formats) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = invalidate(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.audio.format.AudioFormats.register.<anonymous>' call
    var tmp0_this = tmp0_apply;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.d9e_1;
    addAll_0(tmp0_plusAssign, formats);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  };
  function standardAudioFormats() {
    _init_properties_AudioFormat_kt__9os7np();
    return Companion_getInstance_1().b9e([Companion_getInstance_2(), get_FastMP3Decoder()]);
  }
  function AudioFormat(exts) {
    var tmp = this;
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(exts.length);
    var indexedObject = exts;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$3;
      // Inline function 'korlibs.audio.format.AudioFormat.extensions.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'kotlin.text.trim' call
      var tmp$ret$1;
      // Inline function 'kotlin.text.lowercase' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = item;
      tmp$ret$1 = tmp$ret$0.toLowerCase();
      var tmp0_trim = tmp$ret$1;
      tmp$ret$2 = toString(trim(isCharSequence(tmp0_trim) ? tmp0_trim : THROW_CCE()));
      tmp$ret$3 = tmp$ret$2;
      tmp0_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp0_mapTo;
    tmp$ret$5 = tmp$ret$4;
    tmp.g9e_1 = toSet(tmp$ret$5);
  }
  protoOf(AudioFormat).w90 = function () {
    return this.g9e_1;
  };
  protoOf(AudioFormat).mm = function () {
    return 'AudioFormat';
  };
  protoOf(AudioFormat).toString = function () {
    return this.mm() + '(' + sorted(this.w90()) + ')';
  };
  function defaultAudioFormats$delegate$lambda() {
    _init_properties_AudioFormat_kt__9os7np();
    return standardAudioFormats();
  }
  function defaultAudioFormats$factory() {
    return getPropertyCallableRef('defaultAudioFormats', 0, KProperty0, function () {
      return get_defaultAudioFormats();
    }, null);
  }
  var properties_initialized_AudioFormat_kt_2wpbb7;
  function _init_properties_AudioFormat_kt__9os7np() {
    if (properties_initialized_AudioFormat_kt_2wpbb7) {
    } else {
      properties_initialized_AudioFormat_kt_2wpbb7 = true;
      defaultAudioFormats$delegate = lazy(defaultAudioFormats$delegate$lambda);
    }
  }
  function Companion_0() {
    Companion_instance_0 = this;
    WAV.call(this);
  }
  var Companion_instance_0;
  function Companion_getInstance_2() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function WAV() {
    Companion_getInstance_2();
    AudioFormat.call(this, ['wav']);
  }
  function Companion_1() {
    Companion_instance_1 = this;
    MP3Decoder.call(this);
  }
  var Companion_instance_1;
  function Companion_getInstance_3() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function MP3Decoder() {
    Companion_getInstance_3();
    AudioFormat.call(this, ['mp3']);
    this.i9e_1 = Minimp3AudioFormat_getInstance();
  }
  protoOf(MP3Decoder).toString = function () {
    return 'NativeMp3DecoderFormat';
  };
  function Companion_2() {
    Companion_instance_2 = this;
    var tmp = this;
    var tmp$ret$4;
    // Inline function 'kotlin.byteArrayOf' call
    var tmp$ret$0;
    // Inline function 'kotlin.code' call
    tmp$ret$0 = 88;
    var tmp_0 = toByte(tmp$ret$0);
    var tmp$ret$1;
    // Inline function 'kotlin.code' call
    tmp$ret$1 = 105;
    var tmp_1 = toByte(tmp$ret$1);
    var tmp$ret$2;
    // Inline function 'kotlin.code' call
    tmp$ret$2 = 110;
    var tmp_2 = toByte(tmp$ret$2);
    var tmp$ret$3;
    // Inline function 'kotlin.code' call
    tmp$ret$3 = 103;
    var tmp0_byteArrayOf = new Int8Array([tmp_0, tmp_1, tmp_2, toByte(tmp$ret$3)]);
    tmp$ret$4 = tmp0_byteArrayOf;
    tmp.j9e_1 = tmp$ret$4;
    var tmp_3 = this;
    var tmp$ret$9;
    // Inline function 'kotlin.byteArrayOf' call
    var tmp$ret$5;
    // Inline function 'kotlin.code' call
    tmp$ret$5 = 73;
    var tmp_4 = toByte(tmp$ret$5);
    var tmp$ret$6;
    // Inline function 'kotlin.code' call
    tmp$ret$6 = 110;
    var tmp_5 = toByte(tmp$ret$6);
    var tmp$ret$7;
    // Inline function 'kotlin.code' call
    tmp$ret$7 = 102;
    var tmp_6 = toByte(tmp$ret$7);
    var tmp$ret$8;
    // Inline function 'kotlin.code' call
    tmp$ret$8 = 111;
    var tmp0_byteArrayOf_0 = new Int8Array([tmp_4, tmp_5, tmp_6, toByte(tmp$ret$8)]);
    tmp$ret$9 = tmp0_byteArrayOf_0;
    tmp_3.k9e_1 = tmp$ret$9;
  }
  var Companion_instance_2;
  function Companion_getInstance_4() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function BaseMinimp3AudioFormat() {
    Companion_getInstance_4();
    AudioFormat.call(this, ['mp3']);
  }
  function Minimp3AudioFormat() {
    Minimp3AudioFormat_instance = this;
    BaseMinimp3AudioFormat.call(this);
  }
  var Minimp3AudioFormat_instance;
  function Minimp3AudioFormat_getInstance() {
    if (Minimp3AudioFormat_instance == null)
      new Minimp3AudioFormat();
    return Minimp3AudioFormat_instance;
  }
  var DEFAULT_MAX_SAMPLES;
  function NativeSoundProvider$audioFormats$delegate$lambda() {
    return get_defaultAudioFormats();
  }
  function NativeSoundProvider() {
    this.l9e_1 = 'unknown';
    this.m9e_1 = false;
    var tmp = this;
    tmp.n9e_1 = lazy(NativeSoundProvider$audioFormats$delegate$lambda);
  }
  protoOf(NativeSoundProvider).o9e = function (_set____db54di) {
    this.m9e_1 = _set____db54di;
  };
  function Companion_3() {
    Companion_instance_3 = this;
    DummyNativeSoundProvider.call(this);
  }
  var Companion_instance_3;
  function Companion_getInstance_5() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function DummyNativeSoundProvider() {
    Companion_getInstance_5();
    NativeSoundProvider.call(this);
  }
  function get_FastMP3Decoder() {
    return Companion_getInstance_3();
  }
  function HtmlNativeSoundProvider() {
    NativeSoundProvider.call(this);
    HtmlSimpleSound_getInstance().u9e();
  }
  function HtmlSimpleSound$lambda$lambda($source) {
    return function () {
      $source.disconnect(0);
      HtmlSimpleSound_getInstance().r9e_1 = true;
      var tmp$ret$1;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp1_info = HtmlSimpleSound_getInstance().p9e_1;
      var tmp0_log = Level_INFO_getInstance();
      var tmp;
      if (tmp1_info.q1n(tmp0_log)) {
        var tmp$ret$0;
        // Inline function 'korlibs.audio.sound.HtmlSimpleSound.<anonymous>.<anonymous>.<anonymous>' call
        tmp$ret$0 = 'Web Audio was successfully unlocked';
        tmp1_info.r1n(tmp0_log, tmp$ret$0);
        tmp = Unit_getInstance();
      }
      tmp$ret$1 = tmp;
      HtmlSimpleSound_getInstance().s9e_1.ku(Unit_getInstance());
      return Unit_getInstance();
    };
  }
  function HtmlSimpleSound$lambda($unlock, $_scratchBuffer) {
    return function (it) {
      var tmp = document;
      var tmp_0;
      if ($unlock._v == null) {
        throwUninitializedPropertyAccessException('unlock');
      } else {
        tmp_0 = $unlock._v;
      }
      tmp.removeEventListener('keydown', tmp_0, true);
      var tmp_1 = document;
      var tmp_2;
      if ($unlock._v == null) {
        throwUninitializedPropertyAccessException('unlock');
      } else {
        tmp_2 = $unlock._v;
      }
      tmp_1.removeEventListener('touchstart', tmp_2, true);
      var tmp_3 = document;
      var tmp_4;
      if ($unlock._v == null) {
        throwUninitializedPropertyAccessException('unlock');
      } else {
        tmp_4 = $unlock._v;
      }
      tmp_3.removeEventListener('touchend', tmp_4, true);
      var tmp_5 = document;
      var tmp_6;
      if ($unlock._v == null) {
        throwUninitializedPropertyAccessException('unlock');
      } else {
        tmp_6 = $unlock._v;
      }
      tmp_5.removeEventListener('mousedown', tmp_6, true);
      var tmp_7;
      if (!(HtmlSimpleSound_getInstance().q9e_1 == null)) {
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp0_asDynamic = window;
        tmp$ret$0 = tmp0_asDynamic;
        var tmp1_unsafeCast = tmp$ret$0.globalAudioContext;
        tmp$ret$1 = tmp1_unsafeCast;
        var tmp0_safe_receiver = tmp$ret$1;
        if (tmp0_safe_receiver == null)
          null;
        else {
          tmp0_safe_receiver.resume();
        }
        var source = HtmlSimpleSound_getInstance().q9e_1.createBufferSource();
        source.buffer = $_scratchBuffer;
        source.connect(HtmlSimpleSound_getInstance().q9e_1.destination);
        source.start(0.0);
        var tmp$ret$2;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp2_asDynamic = HtmlSimpleSound_getInstance().q9e_1;
        tmp$ret$2 = tmp2_asDynamic;
        if (typeof tmp$ret$2.resume === 'function') {
          var tmp$ret$3;
          // Inline function 'kotlin.js.asDynamic' call
          var tmp3_asDynamic = HtmlSimpleSound_getInstance().q9e_1;
          tmp$ret$3 = tmp3_asDynamic;
          tmp$ret$3.resume();
        }
        source.onended = HtmlSimpleSound$lambda$lambda(source);
        tmp_7 = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function HtmlSimpleSound() {
    HtmlSimpleSound_instance = this;
    this.p9e_1 = Companion_getInstance().b1n('HtmlSimpleSound');
    var tmp = this;
    var tmp_0;
    try {
      var tmp$ret$3;
      // Inline function 'kotlin.also' call
      var tmp_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      var tmp0_asDynamic = window;
      tmp$ret$0 = tmp0_asDynamic;
      if (!(typeof tmp$ret$0.AudioContext === 'undefined')) {
        tmp_1 = new AudioContext();
      } else {
        var tmp$ret$1;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp1_asDynamic = window;
        tmp$ret$1 = tmp1_asDynamic;
        if (!(typeof tmp$ret$1.webkitAudioContext === 'undefined')) {
          tmp_1 = new webkitAudioContext();
        } else {
          tmp_1 = null;
        }
      }
      var tmp2_also = tmp_1;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.audio.sound.HtmlSimpleSound.ctx.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      var tmp0_asDynamic_0 = window;
      tmp$ret$2 = tmp0_asDynamic_0;
      tmp$ret$2.globalAudioContext = tmp2_also;
      tmp$ret$3 = tmp2_also;
      tmp_0 = tmp$ret$3;
    } catch ($p) {
      var tmp_2;
      if ($p instanceof Error) {
        var e = $p;
        var tmp$ret$5;
        // Inline function 'korlibs.logger.Logger.error' call
        var tmp4_error = this.p9e_1;
        var tmp3_log = Level_ERROR_getInstance();
        var tmp_3;
        if (tmp4_error.q1n(tmp3_log)) {
          var tmp$ret$4;
          // Inline function 'korlibs.audio.sound.HtmlSimpleSound.ctx.<anonymous>' call
          tmp$ret$4 = e;
          tmp4_error.r1n(tmp3_log, tmp$ret$4);
          tmp_3 = Unit_getInstance();
        }
        tmp$ret$5 = tmp_3;
        tmp_2 = null;
      } else {
        throw $p;
      }
      tmp_0 = tmp_2;
    }
    tmp.q9e_1 = tmp_0;
    this.r9e_1 = false;
    this.s9e_1 = CompletableDeferred(Job());
    var tmp_4 = this;
    var tmp_5 = this.s9e_1;
    tmp_4.t9e_1 = isInterface(tmp_5, Deferred) ? tmp_5 : THROW_CCE();
    var tmp0_safe_receiver = this.q9e_1;
    var _scratchBuffer = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.createBuffer(1, 1, 22050);
    var unlock = {_v: null};
    unlock._v = HtmlSimpleSound$lambda(unlock, _scratchBuffer);
    var tmp_6 = document;
    var tmp_7;
    if (unlock._v == null) {
      throwUninitializedPropertyAccessException('unlock');
    } else {
      tmp_7 = unlock._v;
    }
    tmp_6.addEventListener('keydown', tmp_7, true);
    var tmp_8 = document;
    var tmp_9;
    if (unlock._v == null) {
      throwUninitializedPropertyAccessException('unlock');
    } else {
      tmp_9 = unlock._v;
    }
    tmp_8.addEventListener('touchstart', tmp_9, true);
    var tmp_10 = document;
    var tmp_11;
    if (unlock._v == null) {
      throwUninitializedPropertyAccessException('unlock');
    } else {
      tmp_11 = unlock._v;
    }
    tmp_10.addEventListener('touchend', tmp_11, true);
    var tmp_12 = document;
    var tmp_13;
    if (unlock._v == null) {
      throwUninitializedPropertyAccessException('unlock');
    } else {
      tmp_13 = unlock._v;
    }
    tmp_12.addEventListener('mousedown', tmp_13, true);
  }
  protoOf(HtmlSimpleSound).u9e = function () {
  };
  var HtmlSimpleSound_instance;
  function HtmlSimpleSound_getInstance() {
    if (HtmlSimpleSound_instance == null)
      new HtmlSimpleSound();
    return HtmlSimpleSound_instance;
  }
  function get_nativeSoundProvider() {
    _init_properties_NativeAudioStreamJs_kt__cauafq();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = nativeSoundProvider$factory();
    tmp$ret$0 = nativeSoundProvider$delegate.f1();
    return tmp$ret$0;
  }
  var nativeSoundProvider$delegate;
  function nativeSoundProvider$delegate$lambda() {
    _init_properties_NativeAudioStreamJs_kt__cauafq();
    var tmp;
    if (Companion_getInstance_0().i1k()) {
      tmp = new HtmlNativeSoundProvider();
    } else {
      tmp = Companion_getInstance_5();
    }
    return tmp;
  }
  function nativeSoundProvider$factory() {
    return getPropertyCallableRef('nativeSoundProvider', 0, KProperty0, function () {
      return get_nativeSoundProvider();
    }, null);
  }
  var properties_initialized_NativeAudioStreamJs_kt_w5xceg;
  function _init_properties_NativeAudioStreamJs_kt__cauafq() {
    if (properties_initialized_NativeAudioStreamJs_kt_w5xceg) {
    } else {
      properties_initialized_NativeAudioStreamJs_kt_w5xceg = true;
      nativeSoundProvider$delegate = lazy(nativeSoundProvider$delegate$lambda);
    }
  }
  //region block: init
  DEFAULT_MAX_SAMPLES = 39690000;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = get_nativeSoundProvider;
  //endregion
  return _;
}));
