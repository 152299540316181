(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-kmem'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-kmem'.");
    }
    root['korge-root-kmem'] = factory(typeof this['korge-root-kmem'] === 'undefined' ? {} : this['korge-root-kmem'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var clz32 = Math.clz32;
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var Enum = kotlin_kotlin.$_$.rh;
  var classMeta = kotlin_kotlin.$_$.zb;
  var arrayCopy = kotlin_kotlin.$_$.w5;
  var toByte = kotlin_kotlin.$_$.pd;
  var toString = kotlin_kotlin.$_$.sd;
  var hashCode = kotlin_kotlin.$_$.lc;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var equals = kotlin_kotlin.$_$.cc;
  var toShort = kotlin_kotlin.$_$.rd;
  var fill = kotlin_kotlin.$_$.j7;
  var toLong = kotlin_kotlin.$_$.qd;
  var Long = kotlin_kotlin.$_$.vh;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var RuntimeException_init_$Create$ = kotlin_kotlin.$_$.x1;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var copyOf = kotlin_kotlin.$_$.v6;
  var copyOfRange = kotlin_kotlin.$_$.q6;
  var charArray = kotlin_kotlin.$_$.vb;
  var numberToChar = kotlin_kotlin.$_$.hd;
  var _UShort___init__impl__jigrne = kotlin_kotlin.$_$.v3;
  var _UShort___get_data__impl__g0245 = kotlin_kotlin.$_$.w3;
  var FloatCompanionObject_getInstance = kotlin_kotlin.$_$.j4;
  var floatFromBits = kotlin_kotlin.$_$.gc;
  var toRawBits = kotlin_kotlin.$_$.jj;
  var coerceIn = kotlin_kotlin.$_$.fe;
  var round = kotlin_kotlin.$_$.wd;
  var isInfinite = kotlin_kotlin.$_$.xi;
  var isNaN_0 = kotlin_kotlin.$_$.aj;
  var ShortCompanionObject_getInstance = kotlin_kotlin.$_$.l4;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var lazy = kotlin_kotlin.$_$.cj;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var numberToLong = kotlin_kotlin.$_$.kd;
  var roundToInt = kotlin_kotlin.$_$.vd;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Arch, 'Arch', classMeta, Enum);
  setMetadataFor(UByteArrayInt, 'UByteArrayInt', classMeta);
  setMetadataFor(ByteArrayBuilder, 'ByteArrayBuilder', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Endian, 'Endian', classMeta, Enum);
  setMetadataFor(FixedSizeByteArrayBuilder, 'FixedSizeByteArrayBuilder', classMeta);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Os, 'Os', classMeta, Enum);
  function get_isWindows() {
    return this.d1k().u1j();
  }
  function get_isLinux() {
    return this.d1k().v1j();
  }
  function get_isMac() {
    return this.d1k().w1j();
  }
  function get_isNative() {
    return this.e1k().g1k();
  }
  function get_isJsNodeJs() {
    return this.f1k() === 'js-node' ? true : this.f1k() === 'wasm-node';
  }
  function get_isJsBrowser() {
    return this.f1k() === 'js-web' ? true : this.f1k() === 'wasm-web';
  }
  setMetadataFor(Platform, 'Platform', interfaceMeta);
  setMetadataFor(Companion_3, 'Companion', objectMeta, VOID, [Platform]);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(Runtime, 'Runtime', classMeta, Enum);
  setMetadataFor(KMemLayoutBuilder, 'KMemLayoutBuilder', classMeta);
  setMetadataFor(Int2, 'Int2', classMeta);
  setMetadataFor(BFloat6Pack, 'BFloat6Pack', classMeta);
  setMetadataFor(BFloat3Half4Pack, 'BFloat3Half4Pack', classMeta);
  setMetadataFor(Short4LongPack, 'Short4LongPack', classMeta);
  setMetadataFor(Half4Pack, 'Half4Pack', classMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(ByteUnits, 'ByteUnits', classMeta);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(Buffer, 'Buffer', classMeta);
  //endregion
  var Arch_UNKNOWN_instance;
  var Arch_X86_instance;
  var Arch_X64_instance;
  var Arch_ARM32_instance;
  var Arch_ARM64_instance;
  var Arch_MIPS32_instance;
  var Arch_MIPSEL32_instance;
  var Arch_MIPS64_instance;
  var Arch_MIPSEL64_instance;
  var Arch_WASM32_instance;
  var Arch_POWERPC64_instance;
  function Companion() {
    Companion_instance = this;
  }
  protoOf(Companion).k1i = function () {
    return get_currentArch();
  };
  var Companion_instance;
  function Companion_getInstance() {
    Arch_initEntries();
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  var Arch_entriesInitialized;
  function Arch_initEntries() {
    if (Arch_entriesInitialized)
      return Unit_getInstance();
    Arch_entriesInitialized = true;
    Arch_UNKNOWN_instance = new Arch('UNKNOWN', 0, -1);
    Arch_X86_instance = new Arch('X86', 1, 32, VOID, true);
    Arch_X64_instance = new Arch('X64', 2, 64, VOID, true);
    Arch_ARM32_instance = new Arch('ARM32', 3, 32, true);
    Arch_ARM64_instance = new Arch('ARM64', 4, 64, true);
    Arch_MIPS32_instance = new Arch('MIPS32', 5, 32, VOID, VOID, true);
    Arch_MIPSEL32_instance = new Arch('MIPSEL32', 6, 32, VOID, VOID, true);
    Arch_MIPS64_instance = new Arch('MIPS64', 7, 64, VOID, VOID, true);
    Arch_MIPSEL64_instance = new Arch('MIPSEL64', 8, 64, VOID, VOID, true);
    Arch_WASM32_instance = new Arch('WASM32', 9, 32, VOID, VOID, VOID, true);
    Arch_POWERPC64_instance = new Arch('POWERPC64', 10, 64, VOID, VOID, VOID, VOID, true);
    Companion_getInstance();
  }
  function Arch(name, ordinal, bits, isArm, isX86OrX64, isMips, isWasm, isPowerPC) {
    isArm = isArm === VOID ? false : isArm;
    isX86OrX64 = isX86OrX64 === VOID ? false : isX86OrX64;
    isMips = isMips === VOID ? false : isMips;
    isWasm = isWasm === VOID ? false : isWasm;
    isPowerPC = isPowerPC === VOID ? false : isPowerPC;
    Enum.call(this, name, ordinal);
    this.n1i_1 = bits;
    this.o1i_1 = isArm;
    this.p1i_1 = isX86OrX64;
    this.q1i_1 = isMips;
    this.r1i_1 = isWasm;
    this.s1i_1 = isPowerPC;
  }
  protoOf(Arch).t1i = function () {
    return this.n1i_1 === 32;
  };
  function Arch_UNKNOWN_getInstance() {
    Arch_initEntries();
    return Arch_UNKNOWN_instance;
  }
  function arraycopy(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
  }
  function arraycopy_0(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
  }
  function _UByteArrayInt___init__impl__xif7bu(data) {
    return data;
  }
  function _UByteArrayInt___get_data__impl__l2b913($this) {
    return $this;
  }
  function _UByteArrayInt___init__impl__xif7bu_0(size) {
    var tmp = _UByteArrayInt___init__impl__xif7bu(new Int8Array(size));
    return tmp;
  }
  function _UByteArrayInt___get_size__impl__bcjnte($this) {
    return _UByteArrayInt___get_data__impl__l2b913($this).length;
  }
  function UByteArrayInt__get_impl_px6q8a($this, index) {
    return _UByteArrayInt___get_data__impl__l2b913($this)[index] & 255;
  }
  function UByteArrayInt__set_impl_gn4532($this, index, value) {
    _UByteArrayInt___get_data__impl__l2b913($this)[index] = toByte(value);
  }
  function UByteArrayInt__toString_impl_f8qdsi($this) {
    return 'UByteArrayInt(data=' + toString($this) + ')';
  }
  function UByteArrayInt__hashCode_impl_y17rdr($this) {
    return hashCode($this);
  }
  function UByteArrayInt__equals_impl_xlvwxn($this, other) {
    if (!(other instanceof UByteArrayInt))
      return false;
    var tmp0_other_with_cast = other instanceof UByteArrayInt ? other.u1i_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function UByteArrayInt(data) {
    this.u1i_1 = data;
  }
  protoOf(UByteArrayInt).toString = function () {
    return UByteArrayInt__toString_impl_f8qdsi(this.u1i_1);
  };
  protoOf(UByteArrayInt).hashCode = function () {
    return UByteArrayInt__hashCode_impl_y17rdr(this.u1i_1);
  };
  protoOf(UByteArrayInt).equals = function (other) {
    return UByteArrayInt__equals_impl_xlvwxn(this.u1i_1, other);
  };
  function asByteArray(_this__u8e3s4) {
    return _UByteArrayInt___get_data__impl__l2b913(_this__u8e3s4);
  }
  function _UShortArrayInt___init__impl__kdofb8(data) {
    return data;
  }
  function _UShortArrayInt___get_data__impl__l4stnv($this) {
    return $this;
  }
  function _UShortArrayInt___init__impl__kdofb8_0(size) {
    var tmp = _UShortArrayInt___init__impl__kdofb8(new Int16Array(size));
    return tmp;
  }
  function _UShortArrayInt___get_size__impl__hhgbgs($this) {
    return _UShortArrayInt___get_data__impl__l4stnv($this).length;
  }
  function UShortArrayInt__get_impl_ciz20k($this, index) {
    return _UShortArrayInt___get_data__impl__l4stnv($this)[index] & 65535;
  }
  function UShortArrayInt__set_impl_38wgvc($this, index, value) {
    _UShortArrayInt___get_data__impl__l4stnv($this)[index] = toShort(value);
  }
  function arraycopy_1(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
  }
  function arraycopy_2(src, srcPos, dst, dstPos, size) {
    Uint16Buffer__setArray_impl_x4uzt(dst, dstPos, src, srcPos, size);
  }
  function asUShortArrayInt(_this__u8e3s4) {
    return _UShortArrayInt___init__impl__kdofb8(_this__u8e3s4);
  }
  function arrayadd(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _Uint16Buffer___get_size__impl__xyon6y(array) : end;
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        Uint16Buffer__set_impl_g0kliu(array, n, Uint16Buffer__get_impl_6qi0dm(array, n) + value | 0);
      }
       while (inductionVariable < end);
  }
  function arraycopy_3(src, srcPos, dst, dstPos, size) {
    return arraycopy_4(_Int32Buffer___get_buffer__impl__dnrimm(src), imul(srcPos, 4), _Int32Buffer___get_buffer__impl__dnrimm(dst), imul(dstPos, 4), imul(size, 4));
  }
  function arrayfill(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? array.length : end;
    return fill(array, value, start, end);
  }
  function arraycopy_4(src, srcPos, dst, dstPos, size) {
    Companion_getInstance_6().v1i(src, srcPos, dst, dstPos, size);
  }
  function arraycopy_5(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
  }
  function arraycopy_6(src, srcPos, dst, dstPos, size) {
    Int8Buffer__setArray_impl_oimleb(dst, dstPos, src, srcPos, size);
  }
  function arraycopy_7(src, srcPos, dst, dstPos, size) {
    Float32Buffer__setArray_impl_eosj51(dst, dstPos, src, srcPos, size);
  }
  function arraycopy_8(src, srcPos, dst, dstPos, size) {
    Int32Buffer__setArray_impl_eeeiw8(dst, dstPos, src, srcPos, size);
  }
  function extract(_this__u8e3s4, offset, count) {
    return (_this__u8e3s4 >>> offset | 0) & mask(count);
  }
  function signExtend(_this__u8e3s4, bits) {
    return _this__u8e3s4 << (32 - bits | 0) >> (32 - bits | 0);
  }
  function insert(_this__u8e3s4, value, offset) {
    var ivalue = value ? 1 : 0;
    return _this__u8e3s4 & ~(1 << offset) | ivalue << offset;
  }
  function insert_0(_this__u8e3s4, value, offset, count) {
    var mask_0 = mask(count);
    var clearValue = _this__u8e3s4 & ~(mask_0 << offset);
    return clearValue | (value & mask_0) << offset;
  }
  function extractSigned(_this__u8e3s4, offset, count) {
    return signExtend((_this__u8e3s4 >>> offset | 0) & mask(count), count);
  }
  function insert16(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(65535 << offset) | (value & 65535) << offset;
    return tmp$ret$0;
  }
  function insert8(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(255 << offset) | (value & 255) << offset;
    return tmp$ret$0;
  }
  function setBits(_this__u8e3s4, bits, set) {
    return set ? setBits_0(_this__u8e3s4, bits) : unsetBits(_this__u8e3s4, bits);
  }
  function extractByte(_this__u8e3s4, offset) {
    return toByte(_this__u8e3s4 >>> offset | 0);
  }
  function mask(_this__u8e3s4) {
    return (1 << _this__u8e3s4) - 1 | 0;
  }
  function setBits_0(_this__u8e3s4, bits) {
    return _this__u8e3s4 | bits;
  }
  function unsetBits(_this__u8e3s4, bits) {
    return _this__u8e3s4 & ~bits;
  }
  function insert2(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(3 << offset) | (value & 3) << offset;
    return tmp$ret$0;
  }
  function hasBitSet(_this__u8e3s4, index) {
    return !(((_this__u8e3s4 >>> index | 0) & 1) === 0);
  }
  function insert5(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(31 << offset) | (value & 31) << offset;
    return tmp$ret$0;
  }
  function fromLowHigh(_this__u8e3s4, low, high) {
    return toLong(low).be(new Long(-1, 0)).an(toLong(high).gc(32));
  }
  function get_low(_this__u8e3s4) {
    return _this__u8e3s4.t1();
  }
  function get_high(_this__u8e3s4) {
    return _this__u8e3s4.ra(32).t1();
  }
  function insert3(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(7 << offset) | (value & 7) << offset;
    return tmp$ret$0;
  }
  function insert14(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(16383 << offset) | (value & 16383) << offset;
    return tmp$ret$0;
  }
  function insert4(_this__u8e3s4, value, offset) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.insertMask' call
    tmp$ret$0 = _this__u8e3s4 & ~(15 << offset) | (value & 15) << offset;
    return tmp$ret$0;
  }
  function extractScaledf01(_this__u8e3s4, offset, count) {
    return extract(_this__u8e3s4, offset, count) / mask(count);
  }
  function insertScaledf01(_this__u8e3s4, value, offset, count) {
    return insert_0(_this__u8e3s4, numberToInt(clamp01(value) * mask(offset)), offset, count);
  }
  function setInt32(_this__u8e3s4, index, value) {
    return setUnalignedInt32(_this__u8e3s4, imul(index, 4), value);
  }
  function getInt32(_this__u8e3s4, index) {
    return getUnalignedInt32(_this__u8e3s4, imul(index, 4));
  }
  function _Int8Buffer___init__impl__e20lvc(buffer) {
    return buffer;
  }
  function _Int8Buffer___get_buffer__impl__q0gulb($this) {
    return $this;
  }
  function _Int8Buffer___init__impl__e20lvc_0(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = Buffer_0(imul(size, 1));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.Int8Buffer.<init>.<anonymous>' call
    setArrayInt8(tmp0_also, 0, data, offset, size);
    tmp$ret$0 = tmp0_also;
    var tmp = _Int8Buffer___init__impl__e20lvc(tmp$ret$0);
    return tmp;
  }
  function Int8Buffer__setArray_impl_oimleb($this, index, inp, offset, size) {
    return setArrayInt8(_Int8Buffer___get_buffer__impl__q0gulb($this), index, inp, offset, size);
  }
  function _Float32Buffer___init__impl__403k2m(buffer) {
    return buffer;
  }
  function _Float32Buffer___get_buffer__impl__x5nu9x($this) {
    return $this;
  }
  function _Float32Buffer___init__impl__403k2m_0(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = Buffer_0(imul(size, 4));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.Float32Buffer.<init>.<anonymous>' call
    setArrayFloat32(tmp0_also, 0, data, offset, size);
    tmp$ret$0 = tmp0_also;
    var tmp = _Float32Buffer___init__impl__403k2m(tmp$ret$0);
    return tmp;
  }
  function Float32Buffer__get_impl_659lhu($this, index) {
    return getFloat32(_Float32Buffer___get_buffer__impl__x5nu9x($this), index);
  }
  function Float32Buffer__set_impl_34szne($this, index, value) {
    return setFloat32(_Float32Buffer___get_buffer__impl__x5nu9x($this), index, value);
  }
  function Float32Buffer__setArray_impl_eosj51($this, index, inp, offset, size) {
    return setArrayFloat32(_Float32Buffer___get_buffer__impl__x5nu9x($this), index, inp, offset, size);
  }
  function _Int32Buffer___init__impl__o98lxn(buffer) {
    return buffer;
  }
  function _Int32Buffer___get_buffer__impl__dnrimm($this) {
    return $this;
  }
  function Int32Buffer__setArray_impl_eeeiw8($this, index, inp, offset, size) {
    return setArrayInt32(_Int32Buffer___get_buffer__impl__dnrimm($this), index, inp, offset, size);
  }
  function get_size(_this__u8e3s4) {
    return get_sizeInBytes(_this__u8e3s4);
  }
  function sliceWithSize(_this__u8e3s4, start, size) {
    return slice(_this__u8e3s4, start, start + size | 0);
  }
  function clone(_this__u8e3s4, direct) {
    direct = direct === VOID ? false : direct;
    var out = Buffer_0(get_size(_this__u8e3s4), direct);
    arraycopy_4(_this__u8e3s4, 0, out, 0, get_size(_this__u8e3s4));
    return out;
  }
  function _Uint16Buffer___init__impl__4401ya(buffer) {
    return buffer;
  }
  function _Uint16Buffer___get_buffer__impl__rei8ax($this) {
    return $this;
  }
  function _Uint16Buffer___get_size__impl__xyon6y($this) {
    return get_sizeInBytes(_Uint16Buffer___get_buffer__impl__rei8ax($this)) / 2 | 0;
  }
  function Uint16Buffer__get_impl_6qi0dm($this, index) {
    return getUInt16(_Uint16Buffer___get_buffer__impl__rei8ax($this), index);
  }
  function Uint16Buffer__set_impl_g0kliu($this, index, value) {
    return setUInt16(_Uint16Buffer___get_buffer__impl__rei8ax($this), index, value);
  }
  function Uint16Buffer__setArray_impl_x4uzt($this, index, inp, offset, size) {
    return setArrayUInt16(_Uint16Buffer___get_buffer__impl__rei8ax($this), index, inp, offset, size);
  }
  function get_u16(_this__u8e3s4) {
    return _Uint16Buffer___init__impl__4401ya(_this__u8e3s4);
  }
  function setFloat32(_this__u8e3s4, index, value) {
    return setUnalignedFloat32(_this__u8e3s4, imul(index, 4), value);
  }
  function get_i32(_this__u8e3s4) {
    return _Int32Buffer___init__impl__o98lxn(_this__u8e3s4);
  }
  function get_f32(_this__u8e3s4) {
    return _Float32Buffer___init__impl__403k2m(_this__u8e3s4);
  }
  function allocDirect(_this__u8e3s4, size) {
    return Buffer_0(size, true);
  }
  function allocNoDirect(_this__u8e3s4, size) {
    return Buffer_0(size, false);
  }
  function getFloat32(_this__u8e3s4, index) {
    return getUnalignedFloat32(_this__u8e3s4, imul(index, 4));
  }
  function NBuffer_toString(buffer) {
    return 'Buffer(size=' + get_size(buffer) + ')';
  }
  function equalsCommon(_this__u8e3s4, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, use64) {
    use64 = use64 === VOID ? true : use64;
    // Inline function 'kotlin.check' call
    var tmp0_check = (srcPosBytes + sizeInBytes | 0) <= get_sizeInBytes(src);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_check) {
      var tmp$ret$0;
      // Inline function 'kotlin.check.<anonymous>' call
      tmp$ret$0 = 'Check failed.';
      var message = tmp$ret$0;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.check' call
    var tmp1_check = (dstPosBytes + sizeInBytes | 0) <= get_sizeInBytes(dst);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_check) {
      var tmp$ret$1;
      // Inline function 'kotlin.check.<anonymous>' call
      tmp$ret$1 = 'Check failed.';
      var message_0 = tmp$ret$1;
      throw IllegalStateException_init_$Create$(toString(message_0));
    }
    var offset = 0;
    var remaining = sizeInBytes;
    if (use64) {
      var WORD = 8;
      var words = remaining / WORD | 0;
      remaining = remaining % WORD | 0;
      var inductionVariable = 0;
      if (inductionVariable < words)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var v0 = getUnalignedInt64(src, (srcPosBytes + offset | 0) + imul(n, WORD) | 0);
          var v1 = getUnalignedInt64(dst, (dstPosBytes + offset | 0) + imul(n, WORD) | 0);
          if (!v0.equals(v1)) {
            return false;
          }
        }
         while (inductionVariable < words);
      offset = offset + imul(words, WORD) | 0;
    }
    {
      var WORD_0 = 4;
      var words_0 = remaining / WORD_0 | 0;
      remaining = remaining % WORD_0 | 0;
      var inductionVariable_0 = 0;
      if (inductionVariable_0 < words_0)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var v0_0 = getUnalignedInt32(src, (srcPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
          var v1_0 = getUnalignedInt32(dst, (dstPosBytes + offset | 0) + imul(n_0, WORD_0) | 0);
          if (!(v0_0 === v1_0)) {
            return false;
          }
        }
         while (inductionVariable_0 < words_0);
      offset = offset + imul(words_0, WORD_0) | 0;
    }
    {
      var inductionVariable_1 = 0;
      var last = remaining;
      if (inductionVariable_1 < last)
        do {
          var n_1 = inductionVariable_1;
          inductionVariable_1 = inductionVariable_1 + 1 | 0;
          var v0_1 = getUnalignedInt8(src, (srcPosBytes + offset | 0) + n_1 | 0);
          var v1_1 = getUnalignedInt8(dst, (dstPosBytes + offset | 0) + n_1 | 0);
          if (!(v0_1 === v1_1)) {
            return false;
          }
        }
         while (inductionVariable_1 < last);
    }
    return true;
  }
  function checkNBufferSize(size) {
    if (size < 0)
      throw IllegalArgumentException_init_$Create$('invalid size ' + size);
  }
  function setArrayInt8(_this__u8e3s4, index, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    return setUnalignedArrayInt8(_this__u8e3s4, imul(index, 1), inp, offset, size);
  }
  function getInt8(_this__u8e3s4, index) {
    return getUnalignedInt8(_this__u8e3s4, index);
  }
  function getArrayInt8(_this__u8e3s4, index, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    return getUnalignedArrayInt8(_this__u8e3s4, imul(index, 1), out, offset, size);
  }
  function slice(_this__u8e3s4, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? get_sizeInBytes(_this__u8e3s4) : end;
    if ((start > end ? true : !(0 <= start ? start <= get_sizeInBytes(_this__u8e3s4) : false)) ? true : !(0 <= end ? end <= get_sizeInBytes(_this__u8e3s4) : false)) {
      throw IllegalArgumentException_init_$Create$('invalid slice start:' + start + ', end:' + end + ' not in 0..' + get_sizeInBytes(_this__u8e3s4));
    }
    return sliceInternal(_this__u8e3s4, start, end);
  }
  function setUInt8(_this__u8e3s4, index, value) {
    return setUnalignedUInt8(_this__u8e3s4, index, value);
  }
  function setArrayFloat32(_this__u8e3s4, index, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    return setUnalignedArrayFloat32(_this__u8e3s4, imul(index, 4), inp, offset, size);
  }
  function getArrayFloat32(_this__u8e3s4, index, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    return getUnalignedArrayFloat32(_this__u8e3s4, imul(index, 4), out, offset, size);
  }
  function setArrayInt32(_this__u8e3s4, index, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    return setUnalignedArrayInt32(_this__u8e3s4, imul(index, 4), inp, offset, size);
  }
  function getArrayInt32(_this__u8e3s4, index, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    return getUnalignedArrayInt32(_this__u8e3s4, imul(index, 4), out, offset, size);
  }
  function setArrayUInt16(_this__u8e3s4, index, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? _UShortArrayInt___get_size__impl__hhgbgs(inp) - offset | 0 : size;
    return setUnalignedArrayInt16(_this__u8e3s4, imul(index, 2), _UShortArrayInt___get_data__impl__l4stnv(inp), offset, size);
  }
  function getUInt16(_this__u8e3s4, index) {
    return getUnalignedUInt16(_this__u8e3s4, imul(index, 2)) & 65535;
  }
  function setUInt16(_this__u8e3s4, index, value) {
    return setUnalignedUInt16(_this__u8e3s4, imul(index, 2), value);
  }
  function setUnalignedArrayInt8(_this__u8e3s4, byteOffset, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUnalignedInt8_0(_this__u8e3s4, byteOffset + imul(n, 1) | 0, inp[offset + n | 0]);
      }
       while (inductionVariable < size);
  }
  function getUnalignedArrayInt8(_this__u8e3s4, byteOffset, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[offset + n | 0] = getUnalignedInt8(_this__u8e3s4, byteOffset + imul(n, 1) | 0);
      }
       while (inductionVariable < size);
    return out;
  }
  function setUnalignedUInt8(_this__u8e3s4, byteOffset, value) {
    return setUnalignedInt8_0(_this__u8e3s4, byteOffset, toByte(value));
  }
  function setUnalignedArrayFloat32(_this__u8e3s4, byteOffset, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUnalignedFloat32(_this__u8e3s4, byteOffset + imul(n, 4) | 0, inp[offset + n | 0]);
      }
       while (inductionVariable < size);
  }
  function getUnalignedArrayFloat32(_this__u8e3s4, byteOffset, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[offset + n | 0] = getUnalignedFloat32(_this__u8e3s4, byteOffset + imul(n, 4) | 0);
      }
       while (inductionVariable < size);
    return out;
  }
  function setUnalignedArrayInt32(_this__u8e3s4, byteOffset, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUnalignedInt32(_this__u8e3s4, byteOffset + imul(n, 4) | 0, inp[offset + n | 0]);
      }
       while (inductionVariable < size);
  }
  function getUnalignedArrayInt32(_this__u8e3s4, byteOffset, out, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? out.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[offset + n | 0] = getUnalignedInt32(_this__u8e3s4, byteOffset + imul(n, 4) | 0);
      }
       while (inductionVariable < size);
    return out;
  }
  function setUnalignedArrayInt16(_this__u8e3s4, byteOffset, inp, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? inp.length - offset | 0 : size;
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUnalignedInt16(_this__u8e3s4, byteOffset + imul(n, 2) | 0, inp[offset + n | 0]);
      }
       while (inductionVariable < size);
  }
  function getUnalignedUInt16(_this__u8e3s4, byteOffset) {
    return getUnalignedInt16(_this__u8e3s4, byteOffset) & 65535;
  }
  function setUnalignedUInt16(_this__u8e3s4, byteOffset, value) {
    return setUnalignedInt16(_this__u8e3s4, byteOffset, toShort(value));
  }
  function copyOf_0(_this__u8e3s4, newSize) {
    var out = Buffer_0(newSize);
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = get_sizeInBytes(_this__u8e3s4);
    tmp$ret$0 = Math.min(tmp0_min, newSize);
    arraycopy_4(_this__u8e3s4, 0, out, 0, tmp$ret$0);
    return out;
  }
  function get_i8(_this__u8e3s4) {
    return _Int8Buffer___init__impl__e20lvc(_this__u8e3s4);
  }
  function setInt8(_this__u8e3s4, index, value) {
    return setUnalignedInt8(_this__u8e3s4, index, value);
  }
  function setUnalignedInt8(_this__u8e3s4, byteOffset, value) {
    return setUnalignedInt8_0(_this__u8e3s4, byteOffset, toByte(value));
  }
  function ByteArrayBuilder_init_$Init$(initialCapacity, $this) {
    initialCapacity = initialCapacity === VOID ? 4096 : initialCapacity;
    ByteArrayBuilder.call($this, new Int8Array(initialCapacity), 0);
    return $this;
  }
  function ByteArrayBuilder_init_$Create$(initialCapacity) {
    return ByteArrayBuilder_init_$Init$(initialCapacity, objectCreate(protoOf(ByteArrayBuilder)));
  }
  function ensure($this, expected) {
    if ($this.w1i_1.length < expected) {
      if (!$this.x1i_1)
        throw RuntimeException_init_$Create$('ByteArrayBuffer configured to not grow!');
      var newSize = imul($this.w1i_1.length + 7 | 0, 5);
      var realNewSize = newSize < 0 ? IntCompanionObject_getInstance().MAX_VALUE / 2 | 0 : newSize;
      if (newSize < 0 ? expected > realNewSize : false) {
        // Inline function 'kotlin.error' call
        throw IllegalStateException_init_$Create$("ByteArrayBuffer can't grow that much");
      }
      var tmp = $this;
      var tmp_0 = $this.w1i_1;
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      tmp$ret$0 = Math.max(expected, realNewSize);
      tmp.w1i_1 = copyOf(tmp_0, tmp$ret$0);
    }
  }
  function ensureCount($this, count) {
    ensure($this, $this.y1i_1 + count | 0);
  }
  function ByteArrayBuilder(data, size, allowGrow) {
    size = size === VOID ? data.length : size;
    allowGrow = allowGrow === VOID ? true : allowGrow;
    this.w1i_1 = data;
    this.x1i_1 = allowGrow;
    this.y1i_1 = size;
  }
  protoOf(ByteArrayBuilder).a1a = function (value) {
    var oldPosition = this.y1i_1;
    var newPosition = value;
    ensure(this, newPosition);
    this.y1i_1 = newPosition;
    if (newPosition > oldPosition) {
      arrayfill(this.w1i_1, 0, oldPosition, newPosition);
    }
  };
  protoOf(ByteArrayBuilder).l = function () {
    return this.y1i_1;
  };
  protoOf(ByteArrayBuilder).z1i = function (array, offset, len) {
    ensureCount(this, len);
    arraycopy(array, offset, this.w1i_1, this.y1i_1, len);
    var tmp0_this = this;
    tmp0_this.y1i_1 = tmp0_this.y1i_1 + len | 0;
  };
  protoOf(ByteArrayBuilder).a1j = function (array, offset, len, $super) {
    offset = offset === VOID ? 0 : offset;
    len = len === VOID ? array.length - offset | 0 : len;
    var tmp;
    if ($super === VOID) {
      this.z1i(array, offset, len);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.z1i.call(this, array, offset, len);
    }
    return tmp;
  };
  protoOf(ByteArrayBuilder).b1j = function (v) {
    ensure(this, this.y1i_1 + 1 | 0);
    var tmp = this.w1i_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.y1i_1;
    tmp0_this.y1i_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v;
  };
  protoOf(ByteArrayBuilder).c1j = function () {
    return copyOf(this.w1i_1, this.y1i_1);
  };
  function readS16(_this__u8e3s4, o, little) {
    return little ? readS16LE(_this__u8e3s4, o) : readS16BE(_this__u8e3s4, o);
  }
  function write16(_this__u8e3s4, o, v, little) {
    if (little) {
      write16LE(_this__u8e3s4, o, v);
    } else {
      write16BE(_this__u8e3s4, o, v);
    }
  }
  function readU16BE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read16BE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 8;
    return tmp$ret$0;
  }
  function readS32BE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read32BE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 3 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 2 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 16 | u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 24;
    return tmp$ret$0;
  }
  function write16LE(_this__u8e3s4, o, v) {
    _this__u8e3s4[o + 0 | 0] = extractByte(v, 0);
    _this__u8e3s4[o + 1 | 0] = extractByte(v, 8);
  }
  function readU16LE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read16LE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 8;
    return tmp$ret$0;
  }
  function readS32LE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read32LE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, o + 2 | 0) << 16 | u8$accessor$nv62cx(_this__u8e3s4, o + 3 | 0) << 24;
    return tmp$ret$0;
  }
  function readU24LE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read24LE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, o + 2 | 0) << 16;
    return tmp$ret$0;
  }
  function readU24BE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read24BE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 2 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 16;
    return tmp$ret$0;
  }
  function write16BE(_this__u8e3s4, o, v) {
    _this__u8e3s4[o + 1 | 0] = extractByte(v, 0);
    _this__u8e3s4[o + 0 | 0] = extractByte(v, 8);
  }
  function readU16(_this__u8e3s4, o, little) {
    return little ? readU16LE(_this__u8e3s4, o) : readU16BE(_this__u8e3s4, o);
  }
  function readS32(_this__u8e3s4, o, little) {
    return little ? readS32LE(_this__u8e3s4, o) : readS32BE(_this__u8e3s4, o);
  }
  function readU8(_this__u8e3s4, o) {
    return _this__u8e3s4[o] & 255;
  }
  function readS16LE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read16LE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 8;
    return signExtend(tmp$ret$0, 16);
  }
  function readS16BE(_this__u8e3s4, o) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read16BE' call
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, o + 1 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, o + 0 | 0) << 8;
    return signExtend(tmp$ret$0, 16);
  }
  function u8(_this__u8e3s4, o) {
    return _this__u8e3s4[o] & 255;
  }
  function readS8(_this__u8e3s4, o) {
    return _this__u8e3s4[o];
  }
  function readS64BE(_this__u8e3s4, o) {
    var tmp$ret$4;
    // Inline function 'korlibs.memory.read64BE' call
    var tmp$ret$1;
    // Inline function 'korlibs.memory.unsigned' call
    var tmp$ret$0;
    // Inline function 'korlibs.memory.read32BE' call
    var tmp0_read32BE = o + 4 | 0;
    tmp$ret$0 = u8$accessor$nv62cx(_this__u8e3s4, tmp0_read32BE + 3 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, tmp0_read32BE + 2 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, tmp0_read32BE + 1 | 0) << 16 | u8$accessor$nv62cx(_this__u8e3s4, tmp0_read32BE + 0 | 0) << 24;
    var tmp1__get_unsigned__51qu1x = tmp$ret$0;
    tmp$ret$1 = toLong(tmp1__get_unsigned__51qu1x).be(new Long(-1, 0));
    var tmp = tmp$ret$1.gc(0);
    var tmp$ret$3;
    // Inline function 'korlibs.memory.unsigned' call
    var tmp$ret$2;
    // Inline function 'korlibs.memory.read32BE' call
    var tmp2_read32BE = o + 0 | 0;
    tmp$ret$2 = u8$accessor$nv62cx(_this__u8e3s4, tmp2_read32BE + 3 | 0) << 0 | u8$accessor$nv62cx(_this__u8e3s4, tmp2_read32BE + 2 | 0) << 8 | u8$accessor$nv62cx(_this__u8e3s4, tmp2_read32BE + 1 | 0) << 16 | u8$accessor$nv62cx(_this__u8e3s4, tmp2_read32BE + 0 | 0) << 24;
    var tmp3__get_unsigned__lxnvfr = tmp$ret$2;
    tmp$ret$3 = toLong(tmp3__get_unsigned__lxnvfr).be(new Long(-1, 0));
    tmp$ret$4 = tmp.an(tmp$ret$3.gc(32));
    return tmp$ret$4;
  }
  function readByteArray(_this__u8e3s4, o, count) {
    return copyOfRange(_this__u8e3s4, o, o + count | 0);
  }
  function readShortArrayBE(_this__u8e3s4, o, count) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.readTypedArray' call
    var tmp0_readTypedArray = new Int16Array(count);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.readTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.readShortArrayBE.<anonymous>' call
        var tmp1__anonymous__uwfjfc = o + imul(n, 2) | 0;
        tmp0_readTypedArray[n] = toShort(readS16BE(_this__u8e3s4, tmp1__anonymous__uwfjfc));
      }
       while (inductionVariable < count);
    tmp$ret$0 = tmp0_readTypedArray;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function readCharArrayBE(_this__u8e3s4, o, count) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.readTypedArray' call
    var tmp0_readTypedArray = charArray(count);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.readTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.readCharArrayBE.<anonymous>' call
        var tmp1__anonymous__uwfjfc = o + imul(n, 2) | 0;
        tmp0_readTypedArray[n] = numberToChar(readS16BE(_this__u8e3s4, tmp1__anonymous__uwfjfc));
      }
       while (inductionVariable < count);
    tmp$ret$0 = tmp0_readTypedArray;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function readIntArrayLE(_this__u8e3s4, o, count) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.readTypedArray' call
    var tmp0_readTypedArray = new Int32Array(count);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.readTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.readIntArrayLE.<anonymous>' call
        var tmp1__anonymous__uwfjfc = o + imul(n, 4) | 0;
        tmp0_readTypedArray[n] = readS32LE(_this__u8e3s4, tmp1__anonymous__uwfjfc);
      }
       while (inductionVariable < count);
    tmp$ret$0 = tmp0_readTypedArray;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function readIntArrayBE(_this__u8e3s4, o, count) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.readTypedArray' call
    var tmp0_readTypedArray = new Int32Array(count);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.readTypedArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.memory.readIntArrayBE.<anonymous>' call
        var tmp1__anonymous__uwfjfc = o + imul(n, 4) | 0;
        tmp0_readTypedArray[n] = readS32BE(_this__u8e3s4, tmp1__anonymous__uwfjfc);
      }
       while (inductionVariable < count);
    tmp$ret$0 = tmp0_readTypedArray;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function readIntArray(_this__u8e3s4, o, count, little) {
    return little ? readIntArrayLE(_this__u8e3s4, o, count) : readIntArrayBE(_this__u8e3s4, o, count);
  }
  function u8$accessor$nv62cx(_this__u8e3s4, o) {
    return u8(_this__u8e3s4, o);
  }
  var Endian_LITTLE_ENDIAN_instance;
  var Endian_BIG_ENDIAN_instance;
  function Companion_0() {
    Companion_instance_0 = this;
    this.d1j_1 = get_currentIsLittleEndian() ? Endian_LITTLE_ENDIAN_getInstance() : Endian_BIG_ENDIAN_getInstance();
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    Endian_initEntries();
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  var Endian_entriesInitialized;
  function Endian_initEntries() {
    if (Endian_entriesInitialized)
      return Unit_getInstance();
    Endian_entriesInitialized = true;
    Endian_LITTLE_ENDIAN_instance = new Endian('LITTLE_ENDIAN', 0);
    Endian_BIG_ENDIAN_instance = new Endian('BIG_ENDIAN', 1);
    Companion_getInstance_0();
  }
  function Endian(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Endian).g1j = function () {
    return this.equals(Endian_LITTLE_ENDIAN_getInstance());
  };
  function Endian_LITTLE_ENDIAN_getInstance() {
    Endian_initEntries();
    return Endian_LITTLE_ENDIAN_instance;
  }
  function Endian_BIG_ENDIAN_getInstance() {
    Endian_initEntries();
    return Endian_BIG_ENDIAN_instance;
  }
  function FixedSizeByteArrayBuilder_init_$Init$(size, $this) {
    FixedSizeByteArrayBuilder.call($this, new Int8Array(size));
    return $this;
  }
  function FixedSizeByteArrayBuilder_init_$Create$(size) {
    return FixedSizeByteArrayBuilder_init_$Init$(size, objectCreate(protoOf(FixedSizeByteArrayBuilder)));
  }
  function FixedSizeByteArrayBuilder(data) {
    this.h1j_1 = data;
    this.i1j_1 = 0;
  }
  protoOf(FixedSizeByteArrayBuilder).e4 = function () {
    this.i1j_1 = 0;
  };
  protoOf(FixedSizeByteArrayBuilder).z1i = function (array, offset, len) {
    arraycopy(array, offset, this.h1j_1, this.i1j_1, len);
    var tmp0_this = this;
    tmp0_this.i1j_1 = tmp0_this.i1j_1 + len | 0;
  };
  protoOf(FixedSizeByteArrayBuilder).b1j = function (v) {
    var tmp0_this = this;
    var tmp1 = tmp0_this.i1j_1;
    tmp0_this.i1j_1 = tmp1 + 1 | 0;
    this.h1j_1[tmp1] = v;
  };
  function _Half___init__impl__edst2q(rawBits) {
    return rawBits;
  }
  function _Half___get_rawBits__impl__argb7f($this) {
    return $this;
  }
  function _Half___init__impl__edst2q_0(value) {
    var tmp = _Half___init__impl__edst2q(Companion_getInstance_1().k1j(value));
    return tmp;
  }
  function Half__toFloat_impl_solynz($this) {
    return Companion_getInstance_1().l1j(_Half___get_rawBits__impl__argb7f($this));
  }
  function Companion_1() {
    Companion_instance_1 = this;
    this.j1j_1 = 15;
  }
  protoOf(Companion_1).m1j = function (bits) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUShort' call
    tmp$ret$0 = _UShort___init__impl__jigrne(bits);
    return _Half___init__impl__edst2q(tmp$ret$0);
  };
  protoOf(Companion_1).n1j = function (bits) {
    return ((bits >>> 23 | 0) & 255) - 127 | 0;
  };
  protoOf(Companion_1).o1j = function (bits) {
    return bits & 8388607;
  };
  protoOf(Companion_1).p1j = function (bits) {
    return !((bits >>> 31 | 0) === 0);
  };
  protoOf(Companion_1).q1j = function (exp, mantissa, sign) {
    var sign_0 = sign ? 1 : 0;
    var e = (exp + 15 | 0) & 31;
    var m = mantissa & 1023;
    var tmp$ret$0;
    // Inline function 'kotlin.toUShort' call
    var tmp0_toUShort = sign_0 << 15 | e << 10 | m;
    tmp$ret$0 = _UShort___init__impl__jigrne(toShort(tmp0_toUShort));
    return tmp$ret$0;
  };
  protoOf(Companion_1).l1j = function (word) {
    var tmp$ret$0;
    // Inline function 'kotlin.UShort.toInt' call
    tmp$ret$0 = _UShort___get_data__impl__g0245(word) & 65535;
    var word_0 = tmp$ret$0;
    var t2 = word_0 & 32768;
    var t1 = word_0 & 32767;
    var t3 = word_0 & 31744;
    if (t3 === 0)
      return t2 === 0 ? 0.0 : -0.0;
    if (t3 === 31744) {
      if (!((t1 & 1023) === 0)) {
        FloatCompanionObject_getInstance();
        return NaN;
      }
      var tmp0_subject = t2;
      var tmp;
      if (tmp0_subject === 0) {
        FloatCompanionObject_getInstance();
        tmp = Infinity;
      } else {
        FloatCompanionObject_getInstance();
        tmp = -Infinity;
      }
      return tmp;
    }
    t1 = t1 << 13;
    t1 = t1 + 939524096 | 0;
    t1 = t3 === 0 ? 0 : t1;
    t1 = t1 | t2 << 16;
    var tmp$ret$2;
    // Inline function 'korlibs.memory.reinterpretAsFloat' call
    var tmp1_reinterpretAsFloat = t1;
    var tmp$ret$1;
    // Inline function 'kotlin.fromBits' call
    var tmp0_fromBits = FloatCompanionObject_getInstance();
    tmp$ret$1 = floatFromBits(tmp1_reinterpretAsFloat);
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(Companion_1).k1j = function (value) {
    var word = toRawBits(value);
    var exp = this.n1j(word);
    var mantissa = this.o1j(word) >>> 13 | 0;
    var sign = this.p1j(word);
    var eexp = coerceIn(exp, -15, 16);
    return this.q1j(eexp, mantissa, sign);
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function toHalf(_this__u8e3s4) {
    return _Half___init__impl__edst2q_0(_this__u8e3s4);
  }
  var MINUS_ZERO_F;
  function clamp(_this__u8e3s4, min, max) {
    return _this__u8e3s4.k1(min) < 0 ? min : _this__u8e3s4.k1(max) > 0 ? max : _this__u8e3s4;
  }
  function clamp_0(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function ilog2(v) {
    var tmp;
    if (v === 0) {
      tmp = -1;
    } else {
      var tmp$ret$1;
      // Inline function 'korlibs.memory.countLeadingZeros' call
      var tmp$ret$0;
      // Inline function 'kotlin.countLeadingZeroBits' call
      tmp$ret$0 = clz32(v);
      tmp$ret$1 = tmp$ret$0;
      tmp = 31 - tmp$ret$1 | 0;
    }
    return tmp;
  }
  function toIntClamp(_this__u8e3s4, min, max) {
    min = min === VOID ? IntCompanionObject_getInstance().MIN_VALUE : min;
    max = max === VOID ? IntCompanionObject_getInstance().MAX_VALUE : max;
    if (_this__u8e3s4.k1(toLong(min)) < 0)
      return min;
    if (_this__u8e3s4.k1(toLong(max)) > 0)
      return max;
    return _this__u8e3s4.t1();
  }
  function get_nextPowerOfTwo(_this__u8e3s4) {
    var v = _this__u8e3s4;
    var tmp0 = v;
    v = tmp0 - 1 | 0;
    v = v | v >> 1;
    v = v | v >> 2;
    v = v | v >> 4;
    v = v | v >> 8;
    v = v | v >> 16;
    var tmp1 = v;
    v = tmp1 + 1 | 0;
    return v;
  }
  function toIntRound(_this__u8e3s4) {
    return numberToInt(round(_this__u8e3s4));
  }
  function convertRange(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
  }
  function toIntRound_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.round' call
    tmp$ret$0 = round(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntCeil(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.ceil' call
    tmp$ret$0 = Math.ceil(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function toIntFloor(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.floor' call
    tmp$ret$0 = Math.floor(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function clamp_1(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function isAlmostZero(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.abs' call
    tmp$ret$0 = Math.abs(_this__u8e3s4);
    return tmp$ret$0 <= 1.0E-19;
  }
  function clamp01(_this__u8e3s4) {
    return clamp_1(_this__u8e3s4, 0.0, 1.0);
  }
  function convertRange_0(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return dstMin + (dstMax - dstMin) * ((_this__u8e3s4 - srcMin) / (srcMax - srcMin));
  }
  function clampUByte(_this__u8e3s4) {
    var n = _this__u8e3s4 & (-(_this__u8e3s4 >= 0 ? 1 : 0) | 0);
    return (n | (255 - n | 0) >> 31) & 255;
  }
  function clamp01_0(_this__u8e3s4) {
    return clamp_2(_this__u8e3s4, 0.0, 1.0);
  }
  function toIntCeil_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.ceil' call
    tmp$ret$0 = Math.ceil(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function divCeil(_this__u8e3s4, that) {
    return !((_this__u8e3s4 % that | 0) === 0) ? (_this__u8e3s4 / that | 0) + 1 | 0 : _this__u8e3s4 / that | 0;
  }
  function clamp_2(_this__u8e3s4, min, max) {
    return _this__u8e3s4 < min ? min : _this__u8e3s4 > max ? max : _this__u8e3s4;
  }
  function umod(_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other;
    var remainder = rm === MINUS_ZERO_F ? 0.0 : rm;
    return remainder < 0.0 ? remainder + other : remainder;
  }
  function toIntFloor_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.floor' call
    tmp$ret$0 = Math.floor(_this__u8e3s4);
    return numberToInt(tmp$ret$0);
  }
  function nextAlignedTo(_this__u8e3s4, align) {
    return isAlignedTo(_this__u8e3s4, align) ? _this__u8e3s4 : imul((_this__u8e3s4 / align | 0) + 1 | 0, align);
  }
  function umod_0(_this__u8e3s4, other) {
    var rm = _this__u8e3s4 % other | 0;
    var remainder = rm === 0 ? 0 : rm;
    return remainder < 0 ? remainder + other | 0 : remainder;
  }
  function convertRange_1(_this__u8e3s4, srcMin, srcMax, dstMin, dstMax) {
    return numberToInt(dstMin + (dstMax - dstMin | 0) * ((_this__u8e3s4 - srcMin | 0) / (srcMax - srcMin | 0)));
  }
  function isAlignedTo(_this__u8e3s4, alignment) {
    return alignment === 0 ? true : (_this__u8e3s4 % alignment | 0) === 0;
  }
  function isNanOrInfinite(_this__u8e3s4) {
    return isNaN_0(_this__u8e3s4) ? true : isInfinite(_this__u8e3s4);
  }
  function nextAlignedTo_0(_this__u8e3s4, align) {
    var tmp;
    if (isAlignedTo_0(_this__u8e3s4, align)) {
      tmp = _this__u8e3s4;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.Long.plus' call
      var tmp0_plus = _this__u8e3s4.jb(align);
      tmp$ret$0 = tmp0_plus.b9(new Long(1, 0));
      tmp = tmp$ret$0.ta(align);
    }
    return tmp;
  }
  function get_isPowerOfTwo(_this__u8e3s4) {
    return get_nextPowerOfTwo(_this__u8e3s4) === _this__u8e3s4;
  }
  function toShortClamped(_this__u8e3s4) {
    return toShort(clamp_0(_this__u8e3s4, ShortCompanionObject_getInstance().MIN_VALUE, ShortCompanionObject_getInstance().MAX_VALUE));
  }
  function isAlignedTo_0(_this__u8e3s4, alignment) {
    return alignment.equals(new Long(0, 0)) ? true : _this__u8e3s4.c9(alignment).equals(new Long(0, 0));
  }
  var Os_UNKNOWN_instance;
  var Os_MACOSX_instance;
  var Os_IOS_instance;
  var Os_LINUX_instance;
  var Os_WINDOWS_instance;
  var Os_ANDROID_instance;
  var Os_TVOS_instance;
  var Os_WATCHOS_instance;
  function Companion_2() {
    Companion_instance_2 = this;
    this.r1j_1 = values();
  }
  protoOf(Companion_2).k1i = function () {
    return get_currentOs();
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    Os_initEntries();
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function values() {
    return [Os_UNKNOWN_getInstance(), Os_MACOSX_getInstance(), Os_IOS_getInstance(), Os_LINUX_getInstance(), Os_WINDOWS_getInstance(), Os_ANDROID_getInstance(), Os_TVOS_getInstance(), Os_WATCHOS_getInstance()];
  }
  var Os_entriesInitialized;
  function Os_initEntries() {
    if (Os_entriesInitialized)
      return Unit_getInstance();
    Os_entriesInitialized = true;
    Os_UNKNOWN_instance = new Os('UNKNOWN', 0);
    Os_MACOSX_instance = new Os('MACOSX', 1);
    Os_IOS_instance = new Os('IOS', 2);
    Os_LINUX_instance = new Os('LINUX', 3);
    Os_WINDOWS_instance = new Os('WINDOWS', 4);
    Os_ANDROID_instance = new Os('ANDROID', 5);
    Os_TVOS_instance = new Os('TVOS', 6);
    Os_WATCHOS_instance = new Os('WATCHOS', 7);
    Companion_getInstance_2();
  }
  function Os(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Os).u1j = function () {
    return this.equals(Os_WINDOWS_getInstance());
  };
  protoOf(Os).v1j = function () {
    return this.equals(Os_LINUX_getInstance());
  };
  protoOf(Os).w1j = function () {
    return this.equals(Os_MACOSX_getInstance());
  };
  protoOf(Os).x1j = function () {
    return this.equals(Os_IOS_getInstance());
  };
  protoOf(Os).y1j = function () {
    return this.equals(Os_TVOS_getInstance());
  };
  protoOf(Os).z1j = function () {
    return this.equals(Os_WATCHOS_getInstance());
  };
  protoOf(Os).a1k = function () {
    return (this.x1j() ? true : this.y1j()) ? true : this.z1j();
  };
  protoOf(Os).b1k = function () {
    return this.w1j() ? true : this.a1k();
  };
  function Os_UNKNOWN_getInstance() {
    Os_initEntries();
    return Os_UNKNOWN_instance;
  }
  function Os_MACOSX_getInstance() {
    Os_initEntries();
    return Os_MACOSX_instance;
  }
  function Os_IOS_getInstance() {
    Os_initEntries();
    return Os_IOS_instance;
  }
  function Os_LINUX_getInstance() {
    Os_initEntries();
    return Os_LINUX_instance;
  }
  function Os_WINDOWS_getInstance() {
    Os_initEntries();
    return Os_WINDOWS_instance;
  }
  function Os_ANDROID_getInstance() {
    Os_initEntries();
    return Os_ANDROID_instance;
  }
  function Os_TVOS_getInstance() {
    Os_initEntries();
    return Os_TVOS_instance;
  }
  function Os_WATCHOS_getInstance() {
    Os_initEntries();
    return Os_WATCHOS_instance;
  }
  function Companion_3() {
    Companion_instance_3 = this;
  }
  protoOf(Companion_3).c1k = function () {
    return Companion_getInstance().k1i();
  };
  protoOf(Companion_3).d1k = function () {
    return Companion_getInstance_2().k1i();
  };
  protoOf(Companion_3).e1k = function () {
    return Companion_getInstance_4().k1i();
  };
  protoOf(Companion_3).f1k = function () {
    return get_currentRawPlatformName();
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function Platform() {
  }
  var Runtime_JS_instance;
  var Runtime_JVM_instance;
  var Runtime_ANDROID_instance;
  var Runtime_NATIVE_instance;
  var Runtime_WASM_instance;
  function Companion_4() {
    Companion_instance_4 = this;
  }
  protoOf(Companion_4).k1i = function () {
    return get_currentRuntime();
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    Runtime_initEntries();
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  var Runtime_entriesInitialized;
  function Runtime_initEntries() {
    if (Runtime_entriesInitialized)
      return Unit_getInstance();
    Runtime_entriesInitialized = true;
    Runtime_JS_instance = new Runtime('JS', 0);
    Runtime_JVM_instance = new Runtime('JVM', 1);
    Runtime_ANDROID_instance = new Runtime('ANDROID', 2);
    Runtime_NATIVE_instance = new Runtime('NATIVE', 3);
    Runtime_WASM_instance = new Runtime('WASM', 4);
    Companion_getInstance_4();
  }
  function Runtime(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Runtime).l1k = function () {
    return this.equals(Runtime_JVM_getInstance());
  };
  protoOf(Runtime).g1k = function () {
    return this.equals(Runtime_NATIVE_getInstance());
  };
  function Runtime_JS_getInstance() {
    Runtime_initEntries();
    return Runtime_JS_instance;
  }
  function Runtime_JVM_getInstance() {
    Runtime_initEntries();
    return Runtime_JVM_instance;
  }
  function Runtime_NATIVE_getInstance() {
    Runtime_initEntries();
    return Runtime_NATIVE_instance;
  }
  function arrayequal(src, srcPos, dst, dstPos, size) {
    return Companion_getInstance_6().m1k(src, srcPos, dst, dstPos, size);
  }
  function arrayfill_0(array, value, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? get_size(array) : end;
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        setUInt8(array, n, value);
      }
       while (inductionVariable < end);
  }
  function align($this, size) {
    var tmp = $this;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = $this.o1k_1;
    tmp$ret$0 = Math.max(tmp0_max, size);
    tmp.o1k_1 = tmp$ret$0;
    while (!(($this.n1k_1 % size | 0) === 0)) {
      var tmp0_this = $this;
      var tmp1 = tmp0_this.n1k_1;
      tmp0_this.n1k_1 = tmp1 + 1 | 0;
    }
    return $this;
  }
  function KMemLayoutBuilder$size$delegate$lambda(this$0) {
    return function () {
      align(this$0, this$0.o1k_1);
      return this$0.n1k_1;
    };
  }
  function KMemLayoutBuilder() {
    this.n1k_1 = 0;
    this.o1k_1 = 4;
    var tmp = this;
    tmp.p1k_1 = lazy(KMemLayoutBuilder$size$delegate$lambda(this));
  }
  protoOf(KMemLayoutBuilder).l = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = size$factory();
    tmp$ret$0 = this.p1k_1.f1();
    return tmp$ret$0;
  };
  protoOf(KMemLayoutBuilder).q1k = function (size, align_0) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = align(this, align_0).n1k_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.memory.dyn.KMemLayoutBuilder.rawAlloc.<anonymous>' call
    var tmp0_this = this;
    tmp0_this.n1k_1 = tmp0_this.n1k_1 + size | 0;
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  function size$factory() {
    return getPropertyCallableRef('size', 1, KProperty1, function (receiver) {
      return receiver.l();
    }, null);
  }
  function Int2(x, y) {
    this.r1k_1 = x;
    this.s1k_1 = y;
  }
  protoOf(Int2).toString = function () {
    return 'Int2(x=' + this.r1k_1 + ', y=' + this.s1k_1 + ')';
  };
  protoOf(Int2).hashCode = function () {
    var result = this.r1k_1;
    result = imul(result, 31) + this.s1k_1 | 0;
    return result;
  };
  protoOf(Int2).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Int2))
      return false;
    var tmp0_other_with_cast = other instanceof Int2 ? other : THROW_CCE();
    if (!(this.r1k_1 === tmp0_other_with_cast.r1k_1))
      return false;
    if (!(this.s1k_1 === tmp0_other_with_cast.s1k_1))
      return false;
    return true;
  };
  function get_i0(_this__u8e3s4) {
    return _this__u8e3s4.r1k_1;
  }
  function get_i1(_this__u8e3s4) {
    return _this__u8e3s4.s1k_1;
  }
  function int2PackOf(i0, i1) {
    return new Int2(i0, i1);
  }
  function BFloat6Pack(f0, f1, f2, f3, f4, f5, twobits) {
    this.t1k_1 = f0;
    this.u1k_1 = f1;
    this.v1k_1 = f2;
    this.w1k_1 = f3;
    this.x1k_1 = f4;
    this.y1k_1 = f5;
    this.z1k_1 = twobits;
  }
  protoOf(BFloat6Pack).toString = function () {
    return 'BFloat6Pack(f0=' + this.t1k_1 + ', f1=' + this.u1k_1 + ', f2=' + this.v1k_1 + ', f3=' + this.w1k_1 + ', f4=' + this.x1k_1 + ', f5=' + this.y1k_1 + ', twobits=' + this.z1k_1 + ')';
  };
  protoOf(BFloat6Pack).hashCode = function () {
    var result = getNumberHashCode(this.t1k_1);
    result = imul(result, 31) + getNumberHashCode(this.u1k_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.v1k_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.w1k_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.x1k_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.y1k_1) | 0;
    result = imul(result, 31) + this.z1k_1 | 0;
    return result;
  };
  protoOf(BFloat6Pack).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BFloat6Pack))
      return false;
    var tmp0_other_with_cast = other instanceof BFloat6Pack ? other : THROW_CCE();
    if (!equals(this.t1k_1, tmp0_other_with_cast.t1k_1))
      return false;
    if (!equals(this.u1k_1, tmp0_other_with_cast.u1k_1))
      return false;
    if (!equals(this.v1k_1, tmp0_other_with_cast.v1k_1))
      return false;
    if (!equals(this.w1k_1, tmp0_other_with_cast.w1k_1))
      return false;
    if (!equals(this.x1k_1, tmp0_other_with_cast.x1k_1))
      return false;
    if (!equals(this.y1k_1, tmp0_other_with_cast.y1k_1))
      return false;
    if (!(this.z1k_1 === tmp0_other_with_cast.z1k_1))
      return false;
    return true;
  };
  function get_bf0(_this__u8e3s4) {
    return _this__u8e3s4.t1k_1;
  }
  function get_bf1(_this__u8e3s4) {
    return _this__u8e3s4.u1k_1;
  }
  function get_bf2(_this__u8e3s4) {
    return _this__u8e3s4.v1k_1;
  }
  function get_bf3(_this__u8e3s4) {
    return _this__u8e3s4.w1k_1;
  }
  function get_bf4(_this__u8e3s4) {
    return _this__u8e3s4.x1k_1;
  }
  function get_bf5(_this__u8e3s4) {
    return _this__u8e3s4.y1k_1;
  }
  function bfloat6PackOf(bf0, bf1, bf2, bf3, bf4, bf5, twobits) {
    twobits = twobits === VOID ? 0 : twobits;
    return new BFloat6Pack(bf0, bf1, bf2, bf3, bf4, bf5, twobits);
  }
  function BFloat3Half4Pack(f0, f1, f2, f3, f4, f5, f6) {
    this.a1l_1 = f0;
    this.b1l_1 = f1;
    this.c1l_1 = f2;
    this.d1l_1 = f3;
    this.e1l_1 = f4;
    this.f1l_1 = f5;
    this.g1l_1 = f6;
  }
  protoOf(BFloat3Half4Pack).toString = function () {
    return 'BFloat3Half4Pack(f0=' + this.a1l_1 + ', f1=' + this.b1l_1 + ', f2=' + this.c1l_1 + ', f3=' + this.d1l_1 + ', f4=' + this.e1l_1 + ', f5=' + this.f1l_1 + ', f6=' + this.g1l_1 + ')';
  };
  protoOf(BFloat3Half4Pack).hashCode = function () {
    var result = getNumberHashCode(this.a1l_1);
    result = imul(result, 31) + getNumberHashCode(this.b1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.c1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.d1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.e1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f1l_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.g1l_1) | 0;
    return result;
  };
  protoOf(BFloat3Half4Pack).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BFloat3Half4Pack))
      return false;
    var tmp0_other_with_cast = other instanceof BFloat3Half4Pack ? other : THROW_CCE();
    if (!equals(this.a1l_1, tmp0_other_with_cast.a1l_1))
      return false;
    if (!equals(this.b1l_1, tmp0_other_with_cast.b1l_1))
      return false;
    if (!equals(this.c1l_1, tmp0_other_with_cast.c1l_1))
      return false;
    if (!equals(this.d1l_1, tmp0_other_with_cast.d1l_1))
      return false;
    if (!equals(this.e1l_1, tmp0_other_with_cast.e1l_1))
      return false;
    if (!equals(this.f1l_1, tmp0_other_with_cast.f1l_1))
      return false;
    if (!equals(this.g1l_1, tmp0_other_with_cast.g1l_1))
      return false;
    return true;
  };
  function get_b0(_this__u8e3s4) {
    return _this__u8e3s4.a1l_1;
  }
  function get_b1(_this__u8e3s4) {
    return _this__u8e3s4.b1l_1;
  }
  function get_b2(_this__u8e3s4) {
    return _this__u8e3s4.c1l_1;
  }
  function get_hf0(_this__u8e3s4) {
    return _this__u8e3s4.d1l_1;
  }
  function get_hf1(_this__u8e3s4) {
    return _this__u8e3s4.e1l_1;
  }
  function get_hf2(_this__u8e3s4) {
    return _this__u8e3s4.f1l_1;
  }
  function get_hf3(_this__u8e3s4) {
    return _this__u8e3s4.g1l_1;
  }
  function bfloat3Half4PackOf(b0, b1, b2, hf0, hf1, hf2, hf3) {
    return new BFloat3Half4Pack(b0, b1, b2, hf0, hf1, hf2, hf3);
  }
  function _Short4LongPack___init__impl__ra1it0(pack) {
    return pack;
  }
  function _Short4LongPack___get_pack__impl__1g8y6s($this) {
    return $this;
  }
  function _Short4LongPack___get_x__impl__ckr4u7($this) {
    return _Short4LongPack___get_pack__impl__1g8y6s($this).ra(0).cn();
  }
  function _Short4LongPack___get_y__impl__g8vc80($this) {
    return _Short4LongPack___get_pack__impl__1g8y6s($this).ra(16).cn();
  }
  function _Short4LongPack___get_z__impl__pym8ox($this) {
    return _Short4LongPack___get_pack__impl__1g8y6s($this).ra(32).cn();
  }
  function _Short4LongPack___get_w__impl__tmqg2q($this) {
    return _Short4LongPack___get_pack__impl__1g8y6s($this).ra(48).cn();
  }
  function _Short4LongPack___init__impl__ra1it0_0(x, y, z, w) {
    var tmp$ret$1;
    // Inline function 'kotlin.UShort.toLong' call
    var tmp$ret$0;
    // Inline function 'kotlin.toUShort' call
    tmp$ret$0 = _UShort___init__impl__jigrne(x);
    var tmp0_toLong = tmp$ret$0;
    tmp$ret$1 = toLong(_UShort___get_data__impl__g0245(tmp0_toLong)).be(new Long(65535, 0));
    var tmp = tmp$ret$1.gc(0);
    var tmp$ret$3;
    // Inline function 'kotlin.UShort.toLong' call
    var tmp$ret$2;
    // Inline function 'kotlin.toUShort' call
    tmp$ret$2 = _UShort___init__impl__jigrne(y);
    var tmp1_toLong = tmp$ret$2;
    tmp$ret$3 = toLong(_UShort___get_data__impl__g0245(tmp1_toLong)).be(new Long(65535, 0));
    var tmp_0 = tmp.an(tmp$ret$3.gc(16));
    var tmp$ret$5;
    // Inline function 'kotlin.UShort.toLong' call
    var tmp$ret$4;
    // Inline function 'kotlin.toUShort' call
    tmp$ret$4 = _UShort___init__impl__jigrne(z);
    var tmp2_toLong = tmp$ret$4;
    tmp$ret$5 = toLong(_UShort___get_data__impl__g0245(tmp2_toLong)).be(new Long(65535, 0));
    var tmp_1 = tmp_0.an(tmp$ret$5.gc(32));
    var tmp$ret$7;
    // Inline function 'kotlin.UShort.toLong' call
    var tmp$ret$6;
    // Inline function 'kotlin.toUShort' call
    tmp$ret$6 = _UShort___init__impl__jigrne(w);
    var tmp3_toLong = tmp$ret$6;
    tmp$ret$7 = toLong(_UShort___get_data__impl__g0245(tmp3_toLong)).be(new Long(65535, 0));
    var tmp_2 = _Short4LongPack___init__impl__ra1it0(tmp_1.an(tmp$ret$7.gc(48)));
    return tmp_2;
  }
  function Short4LongPack__toString_impl_fp63m4($this) {
    return 'Short4LongPack(pack=' + toString($this) + ')';
  }
  function Short4LongPack__hashCode_impl_xks1k5($this) {
    return $this.hashCode();
  }
  function Short4LongPack__equals_impl_v3edfl($this, other) {
    if (!(other instanceof Short4LongPack))
      return false;
    var tmp0_other_with_cast = other instanceof Short4LongPack ? other.h1l_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function Short4LongPack(pack) {
    this.h1l_1 = pack;
  }
  protoOf(Short4LongPack).toString = function () {
    return Short4LongPack__toString_impl_fp63m4(this.h1l_1);
  };
  protoOf(Short4LongPack).hashCode = function () {
    return Short4LongPack__hashCode_impl_xks1k5(this.h1l_1);
  };
  protoOf(Short4LongPack).equals = function (other) {
    return Short4LongPack__equals_impl_v3edfl(this.h1l_1, other);
  };
  function _Half4Pack___init__impl__htjstd(data) {
    return data;
  }
  function _Half4Pack___get_data__impl__l7ymuo($this) {
    return $this;
  }
  function _Half4Pack___init__impl__htjstd_0(x, y, z, w) {
    var tmp$ret$0;
    // Inline function 'kotlin.UShort.toShort' call
    var tmp0_toShort = _Half___get_rawBits__impl__argb7f(x);
    tmp$ret$0 = _UShort___get_data__impl__g0245(tmp0_toShort);
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.UShort.toShort' call
    var tmp1_toShort = _Half___get_rawBits__impl__argb7f(y);
    tmp$ret$1 = _UShort___get_data__impl__g0245(tmp1_toShort);
    var tmp_0 = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.UShort.toShort' call
    var tmp2_toShort = _Half___get_rawBits__impl__argb7f(z);
    tmp$ret$2 = _UShort___get_data__impl__g0245(tmp2_toShort);
    var tmp_1 = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.UShort.toShort' call
    var tmp3_toShort = _Half___get_rawBits__impl__argb7f(w);
    tmp$ret$3 = _UShort___get_data__impl__g0245(tmp3_toShort);
    var tmp_2 = _Half4Pack___init__impl__htjstd(_Short4LongPack___init__impl__ra1it0_0(tmp, tmp_0, tmp_1, tmp$ret$3));
    return tmp_2;
  }
  function _Half4Pack___get_x__impl__z3n838($this) {
    return Companion_getInstance_1().m1j(_Short4LongPack___get_x__impl__ckr4u7(_Half4Pack___get_data__impl__l7ymuo($this)));
  }
  function _Half4Pack___get_y__impl__6a0r11($this) {
    return Companion_getInstance_1().m1j(_Short4LongPack___get_y__impl__g8vc80(_Half4Pack___get_data__impl__l7ymuo($this)));
  }
  function _Half4Pack___get_z__impl__mjlq16($this) {
    return Companion_getInstance_1().m1j(_Short4LongPack___get_z__impl__pym8ox(_Half4Pack___get_data__impl__l7ymuo($this)));
  }
  function _Half4Pack___get_w__impl__73uctp($this) {
    return Companion_getInstance_1().m1j(_Short4LongPack___get_w__impl__tmqg2q(_Half4Pack___get_data__impl__l7ymuo($this)));
  }
  function Half4Pack__toString_impl_pp2u61($this) {
    return 'Half4Pack(data=' + new Short4LongPack($this) + ')';
  }
  function Half4Pack__hashCode_impl_nkvb08($this) {
    return Short4LongPack__hashCode_impl_xks1k5($this);
  }
  function Half4Pack__equals_impl_dcetp0($this, other) {
    if (!(other instanceof Half4Pack))
      return false;
    var tmp0_other_with_cast = other instanceof Half4Pack ? other.i1l_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Half4Pack(data) {
    this.i1l_1 = data;
  }
  protoOf(Half4Pack).toString = function () {
    return Half4Pack__toString_impl_pp2u61(this.i1l_1);
  };
  protoOf(Half4Pack).hashCode = function () {
    return Half4Pack__hashCode_impl_nkvb08(this.i1l_1);
  };
  protoOf(Half4Pack).equals = function (other) {
    return Half4Pack__equals_impl_dcetp0(this.i1l_1, other);
  };
  function _ByteUnits___init__impl__x795am(bytes) {
    return bytes;
  }
  function _ByteUnits___get_bytes__impl__leukoc($this) {
    return $this;
  }
  function _ByteUnits___get_bytesLong__impl__1lc1w0($this) {
    return numberToLong(_ByteUnits___get_bytes__impl__leukoc($this));
  }
  function _ByteUnits___get_kiloBytes__impl__jnym6r($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1024.0;
  }
  function _ByteUnits___get_megaBytes__impl__qjjio2($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1048576.0;
  }
  function _ByteUnits___get_gigaBytes__impl__i7s8s8($this) {
    return _ByteUnits___get_bytes__impl__leukoc($this) / 1.073741824E9;
  }
  function Companion_5() {
    Companion_instance_5 = this;
    this.j1l_1 = _ByteUnits___init__impl__x795am(0.0);
  }
  protoOf(Companion_5).k1l = function (bytes) {
    return _ByteUnits___init__impl__x795am(bytes);
  };
  protoOf(Companion_5).l1l = function (bytes) {
    return _ByteUnits___init__impl__x795am(bytes.wi());
  };
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function roundToDigits(_this__u8e3s4, $this, digits) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    tmp$ret$0 = Math.pow(10.0, digits);
    var num = tmp$ret$0;
    return roundToInt(_this__u8e3s4 * num) / num;
  }
  function ByteUnits__toString_impl_ox2ku2($this) {
    return _ByteUnits___get_gigaBytes__impl__i7s8s8($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_gigaBytes__impl__i7s8s8($this), $this, 1) + ' GB' : _ByteUnits___get_megaBytes__impl__qjjio2($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_megaBytes__impl__qjjio2($this), $this, 1) + ' MB' : _ByteUnits___get_kiloBytes__impl__jnym6r($this) >= 1.0 ? '' + roundToDigits(_ByteUnits___get_kiloBytes__impl__jnym6r($this), $this, 1) + ' KB' : toString(_ByteUnits___get_bytesLong__impl__1lc1w0($this)) + ' B';
  }
  function ByteUnits__hashCode_impl_35wo17($this) {
    return getNumberHashCode($this);
  }
  function ByteUnits__equals_impl_y9yptl($this, other) {
    if (!(other instanceof ByteUnits))
      return false;
    var tmp0_other_with_cast = other instanceof ByteUnits ? other.m1l_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function ByteUnits(bytes) {
    Companion_getInstance_5();
    this.m1l_1 = bytes;
  }
  protoOf(ByteUnits).toString = function () {
    return ByteUnits__toString_impl_ox2ku2(this.m1l_1);
  };
  protoOf(ByteUnits).hashCode = function () {
    return ByteUnits__hashCode_impl_35wo17(this.m1l_1);
  };
  protoOf(ByteUnits).equals = function (other) {
    return ByteUnits__equals_impl_y9yptl(this.m1l_1, other);
  };
  function asInt32Array(_this__u8e3s4) {
    return new Int32Array(_this__u8e3s4);
  }
  function Companion_6() {
    Companion_instance_6 = this;
  }
  protoOf(Companion_6).v1i = function (src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    dst.o1l(dstPosBytes, sizeInBytes).set(src.o1l(srcPosBytes, sizeInBytes), 0);
  };
  protoOf(Companion_6).m1k = function (src, srcPosBytes, dst, dstPosBytes, sizeInBytes) {
    return equalsCommon(this, src, srcPosBytes, dst, dstPosBytes, sizeInBytes, false);
  };
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function Buffer(dataView) {
    Companion_getInstance_6();
    this.n1l_1 = dataView;
  }
  protoOf(Buffer).p1l = function () {
    return this.n1l_1.buffer;
  };
  protoOf(Buffer).o1l = function (offset, size) {
    return new Uint8Array(this.p1l(), this.n1l_1.byteOffset + offset | 0, size);
  };
  protoOf(Buffer).toString = function () {
    return NBuffer_toString(this);
  };
  protoOf(Buffer).hashCode = function () {
    var h = 1;
    var inductionVariable = 0;
    var last = get_size(this);
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        h = imul(31, h) + this.n1l_1.getInt8(n) | 0;
      }
       while (inductionVariable < last);
    return h;
  };
  protoOf(Buffer).equals = function (other) {
    var tmp;
    if (!(other instanceof Buffer)) {
      tmp = true;
    } else {
      tmp = !(get_size(this) === get_size(other));
    }
    if (tmp)
      return false;
    var t = this.n1l_1;
    var o = other.n1l_1;
    var inductionVariable = 0;
    var last = get_size(this);
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(t.getInt8(n) === o.getInt8(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  };
  function Buffer_0(size, direct) {
    direct = direct === VOID ? false : direct;
    checkNBufferSize(size);
    return new Buffer(new DataView(new ArrayBuffer(size)));
  }
  function get_sizeInBytes(_this__u8e3s4) {
    return _this__u8e3s4.n1l_1.byteLength;
  }
  function setUnalignedInt32(_this__u8e3s4, byteOffset, value) {
    return _this__u8e3s4.n1l_1.setInt32(byteOffset, value, get_currentIsLittleEndian());
  }
  function getUnalignedInt32(_this__u8e3s4, byteOffset) {
    return _this__u8e3s4.n1l_1.getInt32(byteOffset, get_currentIsLittleEndian());
  }
  function setUnalignedFloat32(_this__u8e3s4, byteOffset, value) {
    return _this__u8e3s4.n1l_1.setFloat32(byteOffset, value, get_currentIsLittleEndian());
  }
  function getUnalignedFloat32(_this__u8e3s4, byteOffset) {
    return _this__u8e3s4.n1l_1.getFloat32(byteOffset, get_currentIsLittleEndian());
  }
  function getUnalignedInt64(_this__u8e3s4, byteOffset) {
    var v0 = toLong(getUnalignedInt32(_this__u8e3s4, byteOffset)).be(new Long(-1, 0));
    var v1 = toLong(getUnalignedInt32(_this__u8e3s4, byteOffset + 4 | 0)).be(new Long(-1, 0));
    return get_currentIsLittleEndian() ? v1.gc(32).an(v0) : v0.gc(32).an(v1);
  }
  function getUnalignedInt8(_this__u8e3s4, byteOffset) {
    return _this__u8e3s4.n1l_1.getInt8(byteOffset);
  }
  function setUnalignedInt8_0(_this__u8e3s4, byteOffset, value) {
    return _this__u8e3s4.n1l_1.setInt8(byteOffset, value);
  }
  function sliceInternal(_this__u8e3s4, start, end) {
    return new Buffer(new DataView(_this__u8e3s4.p1l(), get_byteOffset(_this__u8e3s4) + start | 0, end - start | 0));
  }
  function getUnalignedInt16(_this__u8e3s4, byteOffset) {
    return _this__u8e3s4.n1l_1.getInt16(byteOffset, get_currentIsLittleEndian());
  }
  function setUnalignedInt16(_this__u8e3s4, byteOffset, value) {
    return _this__u8e3s4.n1l_1.setInt16(byteOffset, value, get_currentIsLittleEndian());
  }
  function get_byteOffset(_this__u8e3s4) {
    return _this__u8e3s4.n1l_1.byteOffset;
  }
  function get_arrayUByte(_this__u8e3s4) {
    return new Uint8Array(_this__u8e3s4.p1l(), get_byteOffset(_this__u8e3s4), get_sizeInBytes(_this__u8e3s4));
  }
  function get_arrayInt(_this__u8e3s4) {
    return new Int32Array(_this__u8e3s4.p1l(), get_byteOffset(_this__u8e3s4), get_sizeInBytes(_this__u8e3s4) / 4 | 0);
  }
  function get_arrayFloat(_this__u8e3s4) {
    return new Float32Array(_this__u8e3s4.p1l(), get_byteOffset(_this__u8e3s4), get_sizeInBytes(_this__u8e3s4) / 4 | 0);
  }
  var POINTER_SIZE;
  var LONG_SIZE;
  function get_isDenoJs() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isDenoJs$factory();
    tmp$ret$0 = isDenoJs$delegate.f1();
    return tmp$ret$0;
  }
  var isDenoJs$delegate;
  function get_isWeb() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isWeb$factory();
    tmp$ret$0 = isWeb$delegate.f1();
    return tmp$ret$0;
  }
  var isWeb$delegate;
  function get_isWorker() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isWorker$factory();
    tmp$ret$0 = isWorker$delegate.f1();
    return tmp$ret$0;
  }
  var isWorker$delegate;
  function get_isNodeJs() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isNodeJs$factory();
    tmp$ret$0 = isNodeJs$delegate.f1();
    return tmp$ret$0;
  }
  var isNodeJs$delegate;
  function get_currentOs() {
    _init_properties_currentJs_kt__gx9m40();
    return currentOs;
  }
  var currentOs;
  function get_currentArch() {
    _init_properties_currentJs_kt__gx9m40();
    return currentArch;
  }
  var currentArch;
  function get_currentRuntime() {
    _init_properties_currentJs_kt__gx9m40();
    return currentRuntime;
  }
  var currentRuntime;
  var currentIsDebug;
  function get_currentIsLittleEndian() {
    _init_properties_currentJs_kt__gx9m40();
    return currentIsLittleEndian;
  }
  var currentIsLittleEndian;
  var multithreadedSharedHeap;
  function get_currentRawPlatformName() {
    _init_properties_currentJs_kt__gx9m40();
    return currentRawPlatformName;
  }
  var currentRawPlatformName;
  var currentRawOsName;
  function get_isShell() {
    _init_properties_currentJs_kt__gx9m40();
    return (!get_isWeb() ? !get_isNodeJs() : false) ? !get_isWorker() : false;
  }
  function isDenoJs$delegate$lambda() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof Deno === 'object' && Deno.statSync;
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isWeb$delegate$lambda() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof window === 'object';
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isWorker$delegate$lambda() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof importScripts === 'function';
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isNodeJs$delegate$lambda() {
    _init_properties_currentJs_kt__gx9m40();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isDenoJs$factory() {
    return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
      return get_isDenoJs();
    }, null);
  }
  function isWeb$factory() {
    return getPropertyCallableRef('isWeb', 0, KProperty0, function () {
      return get_isWeb();
    }, null);
  }
  function isWorker$factory() {
    return getPropertyCallableRef('isWorker', 0, KProperty0, function () {
      return get_isWorker();
    }, null);
  }
  function isNodeJs$factory() {
    return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
      return get_isNodeJs();
    }, null);
  }
  var properties_initialized_currentJs_kt_g6ftma;
  function _init_properties_currentJs_kt__gx9m40() {
    if (properties_initialized_currentJs_kt_g6ftma) {
    } else {
      properties_initialized_currentJs_kt_g6ftma = true;
      isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
      isWeb$delegate = lazy(isWeb$delegate$lambda);
      isWorker$delegate = lazy(isWorker$delegate$lambda);
      isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
      currentOs = Os_UNKNOWN_getInstance();
      currentArch = Arch_UNKNOWN_getInstance();
      currentRuntime = Runtime_JS_getInstance();
      currentIsDebug = false;
      var tmp$ret$4;
      // Inline function 'org.khronos.webgl.get' call
      var tmp$ret$2;
      // Inline function 'kotlin.arrayOf' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = [287454020];
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      var tmp0_get = new Uint8Array((new Uint32Array(tmp$ret$2)).buffer);
      var tmp$ret$3;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$3 = tmp0_get;
      tmp$ret$4 = tmp$ret$3[0];
      currentIsLittleEndian = tmp$ret$4 === 68;
      multithreadedSharedHeap = false;
      currentRawPlatformName = get_isDenoJs() ? 'js-deno' : get_isWeb() ? 'js-web' : get_isNodeJs() ? 'js-node' : get_isWorker() ? 'js-worker' : get_isShell() ? 'js-shell' : 'js';
      var tmp;
      if (get_isDenoJs()) {
        tmp = 'deno';
      } else if (get_isWeb() ? true : get_isWorker()) {
        var tmp$ret$0_0;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp0_unsafeCast = navigator.userAgent;
        tmp$ret$0_0 = tmp0_unsafeCast;
        tmp = tmp$ret$0_0;
      } else {
        var tmp$ret$1_0;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp1_unsafeCast = process.platform;
        tmp$ret$1_0 = tmp1_unsafeCast;
        tmp = tmp$ret$1_0;
      }
      currentRawOsName = tmp;
    }
  }
  //region block: post-declaration
  protoOf(Companion_3).u1j = get_isWindows;
  protoOf(Companion_3).v1j = get_isLinux;
  protoOf(Companion_3).w1j = get_isMac;
  protoOf(Companion_3).g1k = get_isNative;
  protoOf(Companion_3).h1k = get_isJsNodeJs;
  protoOf(Companion_3).i1k = get_isJsBrowser;
  //endregion
  //region block: init
  MINUS_ZERO_F = -0.0;
  POINTER_SIZE = 4;
  LONG_SIZE = 8;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Endian_BIG_ENDIAN_getInstance;
  _.$_$.b = Endian_LITTLE_ENDIAN_getInstance;
  _.$_$.c = Os_UNKNOWN_getInstance;
  _.$_$.d = ByteArrayBuilder_init_$Create$;
  _.$_$.e = FixedSizeByteArrayBuilder_init_$Create$;
  _.$_$.f = _Half4Pack___init__impl__htjstd_0;
  _.$_$.g = Half4Pack__hashCode_impl_nkvb08;
  _.$_$.h = _Half4Pack___get_w__impl__73uctp;
  _.$_$.i = _Half4Pack___get_x__impl__z3n838;
  _.$_$.j = _Half4Pack___get_y__impl__6a0r11;
  _.$_$.k = _Half4Pack___get_z__impl__mjlq16;
  _.$_$.l = _Short4LongPack___init__impl__ra1it0_0;
  _.$_$.m = Short4LongPack__hashCode_impl_xks1k5;
  _.$_$.n = _Short4LongPack___get_w__impl__tmqg2q;
  _.$_$.o = _Short4LongPack___get_x__impl__ckr4u7;
  _.$_$.p = _Short4LongPack___get_y__impl__g8vc80;
  _.$_$.q = _Short4LongPack___get_z__impl__pym8ox;
  _.$_$.r = _Float32Buffer___init__impl__403k2m_0;
  _.$_$.s = _Float32Buffer___get_buffer__impl__x5nu9x;
  _.$_$.t = Float32Buffer__get_impl_659lhu;
  _.$_$.u = Float32Buffer__set_impl_34szne;
  _.$_$.v = Half__toFloat_impl_solynz;
  _.$_$.w = _Int8Buffer___init__impl__e20lvc_0;
  _.$_$.x = _Int8Buffer___get_buffer__impl__q0gulb;
  _.$_$.y = _UByteArrayInt___init__impl__xif7bu;
  _.$_$.z = _UByteArrayInt___init__impl__xif7bu_0;
  _.$_$.a1 = UByteArrayInt__get_impl_px6q8a;
  _.$_$.b1 = UByteArrayInt__set_impl_gn4532;
  _.$_$.c1 = _UByteArrayInt___get_size__impl__bcjnte;
  _.$_$.d1 = _UShortArrayInt___init__impl__kdofb8_0;
  _.$_$.e1 = UShortArrayInt__get_impl_ciz20k;
  _.$_$.f1 = UShortArrayInt__set_impl_38wgvc;
  _.$_$.g1 = Uint16Buffer__set_impl_g0kliu;
  _.$_$.h1 = Companion_getInstance_5;
  _.$_$.i1 = Companion_getInstance_6;
  _.$_$.j1 = Companion_getInstance_2;
  _.$_$.k1 = Companion_getInstance_3;
  _.$_$.l1 = KMemLayoutBuilder;
  _.$_$.m1 = Half4Pack;
  _.$_$.n1 = get_b0;
  _.$_$.o1 = get_b1;
  _.$_$.p1 = get_b2;
  _.$_$.q1 = get_bf0;
  _.$_$.r1 = get_bf1;
  _.$_$.s1 = get_bf2;
  _.$_$.t1 = get_bf3;
  _.$_$.u1 = get_bf4;
  _.$_$.v1 = get_bf5;
  _.$_$.w1 = bfloat3Half4PackOf;
  _.$_$.x1 = bfloat6PackOf;
  _.$_$.y1 = get_hf0;
  _.$_$.z1 = get_hf1;
  _.$_$.a2 = get_hf2;
  _.$_$.b2 = get_hf3;
  _.$_$.c2 = get_i0;
  _.$_$.d2 = get_i1;
  _.$_$.e2 = int2PackOf;
  _.$_$.f2 = ByteUnits;
  _.$_$.g2 = Buffer_0;
  _.$_$.h2 = ByteArrayBuilder;
  _.$_$.i2 = UByteArrayInt;
  _.$_$.j2 = allocDirect;
  _.$_$.k2 = allocNoDirect;
  _.$_$.l2 = get_arrayFloat;
  _.$_$.m2 = get_arrayInt;
  _.$_$.n2 = get_arrayUByte;
  _.$_$.o2 = arrayadd;
  _.$_$.p2 = arraycopy;
  _.$_$.q2 = arraycopy_0;
  _.$_$.r2 = arraycopy_1;
  _.$_$.s2 = arraycopy_6;
  _.$_$.t2 = arraycopy_3;
  _.$_$.u2 = arraycopy_8;
  _.$_$.v2 = arraycopy_7;
  _.$_$.w2 = arraycopy_4;
  _.$_$.x2 = arraycopy_2;
  _.$_$.y2 = arraycopy_5;
  _.$_$.z2 = arrayequal;
  _.$_$.a3 = arrayfill;
  _.$_$.b3 = arrayfill_0;
  _.$_$.c3 = asByteArray;
  _.$_$.d3 = asInt32Array;
  _.$_$.e3 = asUShortArrayInt;
  _.$_$.f3 = clamp01_0;
  _.$_$.g3 = clamp01;
  _.$_$.h3 = clampUByte;
  _.$_$.i3 = clamp_1;
  _.$_$.j3 = clamp_0;
  _.$_$.k3 = clamp_2;
  _.$_$.l3 = clamp;
  _.$_$.m3 = clone;
  _.$_$.n3 = convertRange_0;
  _.$_$.o3 = convertRange;
  _.$_$.p3 = convertRange_1;
  _.$_$.q3 = copyOf_0;
  _.$_$.r3 = divCeil;
  _.$_$.s3 = extractScaledf01;
  _.$_$.t3 = extractSigned;
  _.$_$.u3 = extract;
  _.$_$.v3 = get_f32;
  _.$_$.w3 = fromLowHigh;
  _.$_$.x3 = getArrayFloat32;
  _.$_$.y3 = getArrayInt32;
  _.$_$.z3 = getArrayInt8;
  _.$_$.a4 = getInt32;
  _.$_$.b4 = getInt8;
  _.$_$.c4 = hasBitSet;
  _.$_$.d4 = get_high;
  _.$_$.e4 = get_i32;
  _.$_$.f4 = get_i8;
  _.$_$.g4 = ilog2;
  _.$_$.h4 = insert14;
  _.$_$.i4 = insert16;
  _.$_$.j4 = insert2;
  _.$_$.k4 = insert3;
  _.$_$.l4 = insert4;
  _.$_$.m4 = insert5;
  _.$_$.n4 = insert8;
  _.$_$.o4 = insertScaledf01;
  _.$_$.p4 = insert_0;
  _.$_$.q4 = insert;
  _.$_$.r4 = isAlmostZero;
  _.$_$.s4 = isNanOrInfinite;
  _.$_$.t4 = get_isPowerOfTwo;
  _.$_$.u4 = get_low;
  _.$_$.v4 = nextAlignedTo_0;
  _.$_$.w4 = nextAlignedTo;
  _.$_$.x4 = get_nextPowerOfTwo;
  _.$_$.y4 = readByteArray;
  _.$_$.z4 = readCharArrayBE;
  _.$_$.a5 = readIntArrayBE;
  _.$_$.b5 = readIntArrayLE;
  _.$_$.c5 = readIntArray;
  _.$_$.d5 = readS16BE;
  _.$_$.e5 = readS16;
  _.$_$.f5 = readS32BE;
  _.$_$.g5 = readS32LE;
  _.$_$.h5 = readS32;
  _.$_$.i5 = readS64BE;
  _.$_$.j5 = readS8;
  _.$_$.k5 = readShortArrayBE;
  _.$_$.l5 = readU16BE;
  _.$_$.m5 = readU16LE;
  _.$_$.n5 = readU16;
  _.$_$.o5 = readU24BE;
  _.$_$.p5 = readU24LE;
  _.$_$.q5 = readU8;
  _.$_$.r5 = setBits;
  _.$_$.s5 = setFloat32;
  _.$_$.t5 = setInt32;
  _.$_$.u5 = setInt8;
  _.$_$.v5 = setUnalignedFloat32;
  _.$_$.w5 = signExtend;
  _.$_$.x5 = get_size;
  _.$_$.y5 = get_sizeInBytes;
  _.$_$.z5 = sliceWithSize;
  _.$_$.a6 = slice;
  _.$_$.b6 = toHalf;
  _.$_$.c6 = toIntCeil_0;
  _.$_$.d6 = toIntCeil;
  _.$_$.e6 = toIntClamp;
  _.$_$.f6 = toIntFloor_0;
  _.$_$.g6 = toIntFloor;
  _.$_$.h6 = toIntRound;
  _.$_$.i6 = toIntRound_0;
  _.$_$.j6 = toShortClamped;
  _.$_$.k6 = get_u16;
  _.$_$.l6 = umod_0;
  _.$_$.m6 = umod;
  _.$_$.n6 = write16;
  //endregion
  return _;
}));
