(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx-serialization-kotlinx-serialization-core-js-ir'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'kotlinx-serialization-kotlinx-serialization-core-js-ir'.");
    }
    root['kotlinx-serialization-kotlinx-serialization-core-js-ir'] = factory(typeof this['kotlinx-serialization-kotlinx-serialization-core-js-ir'] === 'undefined' ? {} : this['kotlinx-serialization-kotlinx-serialization-core-js-ir'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.nd;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var StringCompanionObject_getInstance = kotlin_kotlin.$_$.m4;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var emptyList = kotlin_kotlin.$_$.e7;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.h;
  var lazy = kotlin_kotlin.$_$.bj;
  var classMeta = kotlin_kotlin.$_$.zb;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var asList = kotlin_kotlin.$_$.z5;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var Entry = kotlin_kotlin.$_$.l5;
  var isInterface = kotlin_kotlin.$_$.xc;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var zip = kotlin_kotlin.$_$.qa;
  var toMap = kotlin_kotlin.$_$.ga;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var toString = kotlin_kotlin.$_$.sd;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.t;
  var Map = kotlin_kotlin.$_$.m5;
  var IllegalArgumentException_init_$Init$ = kotlin_kotlin.$_$.l1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var IllegalArgumentException_init_$Init$_0 = kotlin_kotlin.$_$.n1;
  var IllegalArgumentException = kotlin_kotlin.$_$.uh;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.k;
  var KClass = kotlin_kotlin.$_$.ne;
  var Triple = kotlin_kotlin.$_$.ei;
  var getKClass = kotlin_kotlin.$_$.e;
  var Pair = kotlin_kotlin.$_$.yh;
  var LinkedHashMap = kotlin_kotlin.$_$.i5;
  var MutableMap = kotlin_kotlin.$_$.p5;
  var HashMap = kotlin_kotlin.$_$.e5;
  var LinkedHashSet = kotlin_kotlin.$_$.j5;
  var MutableSet = kotlin_kotlin.$_$.q5;
  var Set = kotlin_kotlin.$_$.s5;
  var HashSet = kotlin_kotlin.$_$.f5;
  var ArrayList = kotlin_kotlin.$_$.c5;
  var MutableList = kotlin_kotlin.$_$.o5;
  var List = kotlin_kotlin.$_$.k5;
  var Collection = kotlin_kotlin.$_$.d5;
  var copyToArray = kotlin_kotlin.$_$.a7;
  var Result__exceptionOrNull_impl_p6xea9 = kotlin_kotlin.$_$.r2;
  var _Result___get_value__impl__bjfvqg = kotlin_kotlin.$_$.t2;
  var isObject = kotlin_kotlin.$_$.ad;
  var _Result___get_isFailure__impl__jpiriv = kotlin_kotlin.$_$.s2;
  var Result = kotlin_kotlin.$_$.zh;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var equals = kotlin_kotlin.$_$.cc;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var Iterable = kotlin_kotlin.$_$.h5;
  var isBlank = kotlin_kotlin.$_$.pf;
  var toList = kotlin_kotlin.$_$.ea;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.l;
  var HashSet_init_$Create$ = kotlin_kotlin.$_$.r;
  var toHashSet = kotlin_kotlin.$_$.y9;
  var toBooleanArray = kotlin_kotlin.$_$.x9;
  var withIndex = kotlin_kotlin.$_$.oa;
  var to = kotlin_kotlin.$_$.lj;
  var lazy_0 = kotlin_kotlin.$_$.cj;
  var contentEquals = kotlin_kotlin.$_$.h6;
  var until = kotlin_kotlin.$_$.me;
  var joinToString = kotlin_kotlin.$_$.b8;
  var objectMeta = kotlin_kotlin.$_$.md;
  var Long = kotlin_kotlin.$_$.vh;
  var Char = kotlin_kotlin.$_$.mh;
  var Duration__toIsoString_impl_9h6wsm = kotlin_kotlin.$_$.f2;
  var Duration = kotlin_kotlin.$_$.ih;
  var Companion_getInstance = kotlin_kotlin.$_$.q4;
  var toIntOrNull = kotlin_kotlin.$_$.pg;
  var hashCode = kotlin_kotlin.$_$.lc;
  var ArrayList_init_$Create$_1 = kotlin_kotlin.$_$.m;
  var HashSet_init_$Create$_0 = kotlin_kotlin.$_$.s;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var LinkedHashSet_init_$Create$_0 = kotlin_kotlin.$_$.x;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.o;
  var HashMap_init_$Create$_0 = kotlin_kotlin.$_$.p;
  var LinkedHashMap_init_$Create$_1 = kotlin_kotlin.$_$.v;
  var isArray = kotlin_kotlin.$_$.oc;
  var arrayIterator = kotlin_kotlin.$_$.rb;
  var step = kotlin_kotlin.$_$.ke;
  var getValue = kotlin_kotlin.$_$.t7;
  var longArray = kotlin_kotlin.$_$.ed;
  var Companion_getInstance_0 = kotlin_kotlin.$_$.t4;
  var get_lastIndex = kotlin_kotlin.$_$.e8;
  var countTrailingZeroBits = kotlin_kotlin.$_$.ri;
  var indexOf = kotlin_kotlin.$_$.u7;
  var contentToString = kotlin_kotlin.$_$.o6;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var HashSet_init_$Create$_1 = kotlin_kotlin.$_$.q;
  var KTypeParameter = kotlin_kotlin.$_$.se;
  var booleanArray = kotlin_kotlin.$_$.sb;
  var emptyMap = kotlin_kotlin.$_$.f7;
  var contentHashCode = kotlin_kotlin.$_$.l6;
  var Companion_getInstance_1 = kotlin_kotlin.$_$.s4;
  var isCharArray = kotlin_kotlin.$_$.rc;
  var charArray = kotlin_kotlin.$_$.vb;
  var DoubleCompanionObject_getInstance = kotlin_kotlin.$_$.i4;
  var isDoubleArray = kotlin_kotlin.$_$.uc;
  var FloatCompanionObject_getInstance = kotlin_kotlin.$_$.j4;
  var isFloatArray = kotlin_kotlin.$_$.vc;
  var isLongArray = kotlin_kotlin.$_$.yc;
  var Companion_getInstance_2 = kotlin_kotlin.$_$.x4;
  var _ULongArray___get_size__impl__ju6dtr = kotlin_kotlin.$_$.t3;
  var ULongArray = kotlin_kotlin.$_$.ji;
  var _ULongArray___init__impl__twm1l3 = kotlin_kotlin.$_$.p3;
  var _ULong___init__impl__c78o9k = kotlin_kotlin.$_$.m3;
  var ULongArray__get_impl_pr71q9 = kotlin_kotlin.$_$.r3;
  var _ULong___get_data__impl__fggpzb = kotlin_kotlin.$_$.n3;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var isIntArray = kotlin_kotlin.$_$.wc;
  var Companion_getInstance_3 = kotlin_kotlin.$_$.w4;
  var _UIntArray___get_size__impl__r6l8ci = kotlin_kotlin.$_$.k3;
  var UIntArray = kotlin_kotlin.$_$.hi;
  var _UIntArray___init__impl__ghjpc6 = kotlin_kotlin.$_$.g3;
  var _UInt___init__impl__l7qpdl = kotlin_kotlin.$_$.d3;
  var UIntArray__get_impl_gp5kza = kotlin_kotlin.$_$.i3;
  var _UInt___get_data__impl__f0vqqw = kotlin_kotlin.$_$.e3;
  var ShortCompanionObject_getInstance = kotlin_kotlin.$_$.l4;
  var isShortArray = kotlin_kotlin.$_$.bd;
  var Companion_getInstance_4 = kotlin_kotlin.$_$.y4;
  var _UShortArray___get_size__impl__jqto1b = kotlin_kotlin.$_$.c4;
  var UShortArray = kotlin_kotlin.$_$.li;
  var _UShortArray___init__impl__9b26ef = kotlin_kotlin.$_$.y3;
  var _UShort___init__impl__jigrne = kotlin_kotlin.$_$.v3;
  var UShortArray__get_impl_fnbhmx = kotlin_kotlin.$_$.a4;
  var _UShort___get_data__impl__g0245 = kotlin_kotlin.$_$.w3;
  var ByteCompanionObject_getInstance = kotlin_kotlin.$_$.h4;
  var isByteArray = kotlin_kotlin.$_$.qc;
  var Companion_getInstance_5 = kotlin_kotlin.$_$.v4;
  var _UByteArray___get_size__impl__h6pkdv = kotlin_kotlin.$_$.b3;
  var UByteArray = kotlin_kotlin.$_$.fi;
  var _UByteArray___init__impl__ip4y9n = kotlin_kotlin.$_$.y2;
  var _UByte___init__impl__g9hnc4 = kotlin_kotlin.$_$.u2;
  var UByteArray__get_impl_t5f3hv = kotlin_kotlin.$_$.z2;
  var _UByte___get_data__impl__jof9qr = kotlin_kotlin.$_$.v2;
  var BooleanCompanionObject_getInstance = kotlin_kotlin.$_$.g4;
  var isBooleanArray = kotlin_kotlin.$_$.pc;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var copyOf = kotlin_kotlin.$_$.u6;
  var copyOf_0 = kotlin_kotlin.$_$.w6;
  var copyOf_1 = kotlin_kotlin.$_$.x6;
  var copyOf_2 = kotlin_kotlin.$_$.s6;
  var _ULongArray___get_storage__impl__28e64j = kotlin_kotlin.$_$.u3;
  var _ULongArray___init__impl__twm1l3_0 = kotlin_kotlin.$_$.q3;
  var ULongArray__set_impl_z19mvh = kotlin_kotlin.$_$.s3;
  var copyOf_3 = kotlin_kotlin.$_$.z6;
  var _UIntArray___get_storage__impl__92a0v0 = kotlin_kotlin.$_$.l3;
  var _UIntArray___init__impl__ghjpc6_0 = kotlin_kotlin.$_$.h3;
  var UIntArray__set_impl_7f2zu2 = kotlin_kotlin.$_$.j3;
  var copyOf_4 = kotlin_kotlin.$_$.r6;
  var _UShortArray___get_storage__impl__t2jpv5 = kotlin_kotlin.$_$.d4;
  var _UShortArray___init__impl__9b26ef_0 = kotlin_kotlin.$_$.z3;
  var UShortArray__set_impl_6d8whp = kotlin_kotlin.$_$.b4;
  var copyOf_5 = kotlin_kotlin.$_$.v6;
  var _UByteArray___get_storage__impl__d4kctt = kotlin_kotlin.$_$.c3;
  var _UByteArray___init__impl__ip4y9n_0 = kotlin_kotlin.$_$.x2;
  var UByteArray__set_impl_jvcicn = kotlin_kotlin.$_$.a3;
  var copyOf_6 = kotlin_kotlin.$_$.t6;
  var Unit = kotlin_kotlin.$_$.ni;
  var trimIndent = kotlin_kotlin.$_$.dh;
  var equals_0 = kotlin_kotlin.$_$.mf;
  var charSequenceLength = kotlin_kotlin.$_$.xb;
  var charSequenceGet = kotlin_kotlin.$_$.wb;
  var toString_0 = kotlin_kotlin.$_$.p2;
  var titlecase = kotlin_kotlin.$_$.lg;
  var isLowerCase = kotlin_kotlin.$_$.rf;
  var PrimitiveClasses_getInstance = kotlin_kotlin.$_$.p4;
  var ULong = kotlin_kotlin.$_$.ki;
  var UInt = kotlin_kotlin.$_$.ii;
  var UShort = kotlin_kotlin.$_$.mi;
  var UByte = kotlin_kotlin.$_$.gi;
  var mapOf = kotlin_kotlin.$_$.s8;
  var lastOrNull = kotlin_kotlin.$_$.j8;
  var get_lastIndex_0 = kotlin_kotlin.$_$.f8;
  var get_js = kotlin_kotlin.$_$.dd;
  var findAssociatedObject = kotlin_kotlin.$_$.c;
  var get_indices = kotlin_kotlin.$_$.z7;
  var IndexOutOfBoundsException_init_$Create$ = kotlin_kotlin.$_$.s1;
  var get_indices_0 = kotlin_kotlin.$_$.y7;
  var Companion_getInstance_6 = kotlin_kotlin.$_$.u4;
  var _Result___init__impl__xyqfz8 = kotlin_kotlin.$_$.q2;
  var createFailure = kotlin_kotlin.$_$.si;
  //endregion
  //region block: pre-declaration
  setMetadataFor(SerializationStrategy, 'SerializationStrategy', interfaceMeta);
  setMetadataFor(DeserializationStrategy, 'DeserializationStrategy', interfaceMeta);
  setMetadataFor(KSerializer, 'KSerializer', interfaceMeta, VOID, [SerializationStrategy, DeserializationStrategy]);
  setMetadataFor(AbstractPolymorphicSerializer, 'AbstractPolymorphicSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(PolymorphicSerializer, 'PolymorphicSerializer', classMeta, AbstractPolymorphicSerializer);
  setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta);
  setMetadataFor(SealedClassSerializer, 'SealedClassSerializer', classMeta, AbstractPolymorphicSerializer);
  setMetadataFor(SerializationException, 'SerializationException', classMeta, IllegalArgumentException);
  setMetadataFor(UnknownFieldException, 'UnknownFieldException', classMeta, SerializationException);
  setMetadataFor(MissingFieldException, 'MissingFieldException', classMeta, SerializationException);
  function get_isNullable() {
    return false;
  }
  function get_isInline() {
    return false;
  }
  function get_annotations() {
    return emptyList();
  }
  setMetadataFor(SerialDescriptor, 'SerialDescriptor', interfaceMeta);
  setMetadataFor(ContextDescriptor, 'ContextDescriptor', classMeta, VOID, [SerialDescriptor]);
  setMetadataFor(elementDescriptors$1$1, VOID, classMeta);
  setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(elementNames$1$1, VOID, classMeta);
  setMetadataFor(_no_name_provided__qut3iv_1, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(ClassSerialDescriptorBuilder, 'ClassSerialDescriptorBuilder', classMeta);
  setMetadataFor(CachedNames, 'CachedNames', interfaceMeta);
  setMetadataFor(SerialDescriptorImpl, 'SerialDescriptorImpl', classMeta, VOID, [SerialDescriptor, CachedNames]);
  setMetadataFor(SerialKind, 'SerialKind', classMeta);
  setMetadataFor(ENUM, 'ENUM', objectMeta, SerialKind);
  setMetadataFor(CONTEXTUAL, 'CONTEXTUAL', objectMeta, SerialKind);
  setMetadataFor(PolymorphicKind, 'PolymorphicKind', classMeta, SerialKind);
  setMetadataFor(SEALED, 'SEALED', objectMeta, PolymorphicKind);
  setMetadataFor(OPEN, 'OPEN', objectMeta, PolymorphicKind);
  setMetadataFor(PrimitiveKind, 'PrimitiveKind', classMeta, SerialKind);
  setMetadataFor(BOOLEAN, 'BOOLEAN', objectMeta, PrimitiveKind);
  setMetadataFor(BYTE, 'BYTE', objectMeta, PrimitiveKind);
  setMetadataFor(CHAR, 'CHAR', objectMeta, PrimitiveKind);
  setMetadataFor(SHORT, 'SHORT', objectMeta, PrimitiveKind);
  setMetadataFor(INT, 'INT', objectMeta, PrimitiveKind);
  setMetadataFor(LONG, 'LONG', objectMeta, PrimitiveKind);
  setMetadataFor(FLOAT, 'FLOAT', objectMeta, PrimitiveKind);
  setMetadataFor(DOUBLE, 'DOUBLE', objectMeta, PrimitiveKind);
  setMetadataFor(STRING, 'STRING', objectMeta, PrimitiveKind);
  setMetadataFor(StructureKind, 'StructureKind', classMeta, SerialKind);
  setMetadataFor(CLASS, 'CLASS', objectMeta, StructureKind);
  setMetadataFor(LIST, 'LIST', objectMeta, StructureKind);
  setMetadataFor(MAP, 'MAP', objectMeta, StructureKind);
  setMetadataFor(OBJECT, 'OBJECT', objectMeta, StructureKind);
  function decodeSerializableValue(deserializer) {
    return deserializer.sf5(this);
  }
  setMetadataFor(Decoder, 'Decoder', interfaceMeta);
  function decodeSequentially() {
    return false;
  }
  function decodeCollectionSize(descriptor) {
    return -1;
  }
  function decodeSerializableElement$default(descriptor, index, deserializer, previousValue, $super) {
    previousValue = previousValue === VOID ? null : previousValue;
    return $super === VOID ? this.lf9(descriptor, index, deserializer, previousValue) : $super.lf9.call(this, descriptor, index, deserializer, previousValue);
  }
  setMetadataFor(CompositeDecoder, 'CompositeDecoder', interfaceMeta);
  setMetadataFor(AbstractDecoder, 'AbstractDecoder', classMeta, VOID, [Decoder, CompositeDecoder]);
  function encodeNotNullMark() {
  }
  function beginCollection(descriptor, collectionSize) {
    return this.zf8(descriptor);
  }
  function encodeSerializableValue(serializer, value) {
    serializer.rf5(this, value);
  }
  function encodeNullableSerializableValue(serializer, value) {
    var isNullabilitySupported = serializer.qf5().zf6();
    if (isNullabilitySupported) {
      return this.rfa(isInterface(serializer, SerializationStrategy) ? serializer : THROW_CCE(), value);
    }
    if (value == null) {
      this.uf9();
    } else {
      this.ufa();
      this.rfa(serializer, value);
    }
  }
  setMetadataFor(Encoder, 'Encoder', interfaceMeta);
  function shouldEncodeElementDefault(descriptor, index) {
    return true;
  }
  setMetadataFor(CompositeEncoder, 'CompositeEncoder', interfaceMeta);
  setMetadataFor(AbstractEncoder, 'AbstractEncoder', classMeta, VOID, [Encoder, CompositeEncoder]);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(NothingSerializer_0, 'NothingSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(DurationSerializer, 'DurationSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(ListLikeDescriptor, 'ListLikeDescriptor', classMeta, VOID, [SerialDescriptor]);
  setMetadataFor(ArrayListClassDesc, 'ArrayListClassDesc', classMeta, ListLikeDescriptor);
  setMetadataFor(HashSetClassDesc, 'HashSetClassDesc', classMeta, ListLikeDescriptor);
  setMetadataFor(LinkedHashSetClassDesc, 'LinkedHashSetClassDesc', classMeta, ListLikeDescriptor);
  setMetadataFor(MapLikeDescriptor, 'MapLikeDescriptor', classMeta, VOID, [SerialDescriptor]);
  setMetadataFor(HashMapClassDesc, 'HashMapClassDesc', classMeta, MapLikeDescriptor);
  setMetadataFor(LinkedHashMapClassDesc, 'LinkedHashMapClassDesc', classMeta, MapLikeDescriptor);
  setMetadataFor(ArrayClassDesc, 'ArrayClassDesc', classMeta, ListLikeDescriptor);
  setMetadataFor(PrimitiveArrayDescriptor, 'PrimitiveArrayDescriptor', classMeta, ListLikeDescriptor);
  setMetadataFor(AbstractCollectionSerializer, 'AbstractCollectionSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(CollectionLikeSerializer, 'CollectionLikeSerializer', classMeta, AbstractCollectionSerializer);
  setMetadataFor(CollectionSerializer, 'CollectionSerializer', classMeta, CollectionLikeSerializer);
  setMetadataFor(ArrayListSerializer, 'ArrayListSerializer', classMeta, CollectionSerializer);
  setMetadataFor(HashSetSerializer, 'HashSetSerializer', classMeta, CollectionSerializer);
  setMetadataFor(LinkedHashSetSerializer, 'LinkedHashSetSerializer', classMeta, CollectionSerializer);
  setMetadataFor(MapLikeSerializer, 'MapLikeSerializer', classMeta, AbstractCollectionSerializer);
  setMetadataFor(HashMapSerializer, 'HashMapSerializer', classMeta, MapLikeSerializer);
  setMetadataFor(LinkedHashMapSerializer, 'LinkedHashMapSerializer', classMeta, MapLikeSerializer);
  setMetadataFor(ReferenceArraySerializer, 'ReferenceArraySerializer', classMeta, CollectionLikeSerializer);
  setMetadataFor(PrimitiveArraySerializer, 'PrimitiveArraySerializer', classMeta, CollectionLikeSerializer);
  setMetadataFor(PrimitiveArrayBuilder, 'PrimitiveArrayBuilder', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(ElementMarker, 'ElementMarker', classMeta);
  setMetadataFor(EnumSerializer, 'EnumSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(PluginGeneratedSerialDescriptor, 'PluginGeneratedSerialDescriptor', classMeta, VOID, [SerialDescriptor, CachedNames]);
  setMetadataFor(EnumDescriptor, 'EnumDescriptor', classMeta, PluginGeneratedSerialDescriptor);
  setMetadataFor(InlineClassDescriptor, 'InlineClassDescriptor', classMeta, PluginGeneratedSerialDescriptor);
  function typeParametersSerializers() {
    return get_EMPTY_SERIALIZER_ARRAY();
  }
  setMetadataFor(GeneratedSerializer, 'GeneratedSerializer', interfaceMeta, VOID, [KSerializer]);
  setMetadataFor(InlinePrimitiveDescriptor$1, VOID, classMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(NoOpEncoder, 'NoOpEncoder', objectMeta, AbstractEncoder);
  setMetadataFor(NothingSerialDescriptor, 'NothingSerialDescriptor', objectMeta, VOID, [SerialDescriptor]);
  setMetadataFor(NullableSerializer, 'NullableSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(SerialDescriptorForNullable, 'SerialDescriptorForNullable', classMeta, VOID, [SerialDescriptor, CachedNames]);
  setMetadataFor(ObjectSerializer, 'ObjectSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(SerializerFactory, 'SerializerFactory', interfaceMeta);
  setMetadataFor(CharArraySerializer_0, 'CharArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(DoubleArraySerializer_0, 'DoubleArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(FloatArraySerializer_0, 'FloatArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(LongArraySerializer_0, 'LongArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(ULongArraySerializer_0, 'ULongArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(IntArraySerializer_0, 'IntArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(UIntArraySerializer_0, 'UIntArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(ShortArraySerializer_0, 'ShortArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(UShortArraySerializer_0, 'UShortArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(ByteArraySerializer_0, 'ByteArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(UByteArraySerializer_0, 'UByteArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(BooleanArraySerializer_0, 'BooleanArraySerializer', objectMeta, PrimitiveArraySerializer, [KSerializer, PrimitiveArraySerializer]);
  setMetadataFor(CharArrayBuilder, 'CharArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(DoubleArrayBuilder, 'DoubleArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(FloatArrayBuilder, 'FloatArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(LongArrayBuilder, 'LongArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(ULongArrayBuilder, 'ULongArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(IntArrayBuilder, 'IntArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(UIntArrayBuilder, 'UIntArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(ShortArrayBuilder, 'ShortArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(UShortArrayBuilder, 'UShortArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(ByteArrayBuilder, 'ByteArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(UByteArrayBuilder, 'UByteArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(BooleanArrayBuilder, 'BooleanArrayBuilder', classMeta, PrimitiveArrayBuilder);
  setMetadataFor(StringSerializer, 'StringSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(CharSerializer, 'CharSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(DoubleSerializer, 'DoubleSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(FloatSerializer, 'FloatSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(LongSerializer, 'LongSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(IntSerializer, 'IntSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(ShortSerializer, 'ShortSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(ByteSerializer, 'ByteSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(BooleanSerializer, 'BooleanSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(UnitSerializer, 'UnitSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(PrimitiveSerialDescriptor_0, 'PrimitiveSerialDescriptor', classMeta, VOID, [SerialDescriptor]);
  setMetadataFor(TaggedDecoder, 'TaggedDecoder', classMeta, VOID, [Decoder, CompositeDecoder]);
  setMetadataFor(NamedValueDecoder, 'NamedValueDecoder', classMeta, TaggedDecoder);
  setMetadataFor(MapEntry, 'MapEntry', classMeta, VOID, [Entry]);
  setMetadataFor(KeyValueSerializer, 'KeyValueSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(MapEntrySerializer_0, 'MapEntrySerializer', classMeta, KeyValueSerializer);
  setMetadataFor(PairSerializer_0, 'PairSerializer', classMeta, KeyValueSerializer);
  setMetadataFor(TripleSerializer_0, 'TripleSerializer', classMeta, VOID, [KSerializer]);
  setMetadataFor(ULongSerializer, 'ULongSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(UIntSerializer, 'UIntSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(UShortSerializer, 'UShortSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(UByteSerializer, 'UByteSerializer', objectMeta, VOID, [KSerializer]);
  setMetadataFor(SerializersModule, 'SerializersModule', classMeta);
  setMetadataFor(SerialModuleImpl, 'SerialModuleImpl', classMeta, SerializersModule);
  setMetadataFor(ContextualProvider, 'ContextualProvider', classMeta);
  setMetadataFor(Argless, 'Argless', classMeta, ContextualProvider);
  setMetadataFor(WithTypeArguments, 'WithTypeArguments', classMeta, ContextualProvider);
  function contextual(kClass, serializer) {
    return this.cfp(kClass, SerializersModuleCollector$contextual$lambda(serializer));
  }
  setMetadataFor(SerializersModuleCollector, 'SerializersModuleCollector', interfaceMeta);
  setMetadataFor(SerializableWith, 'SerializableWith', classMeta, VOID, VOID, 0);
  setMetadataFor(createCache$1, VOID, classMeta);
  setMetadataFor(createParametrizedCache$1, VOID, classMeta);
  //endregion
  function KSerializer() {
  }
  function SerializationStrategy() {
  }
  function DeserializationStrategy() {
  }
  function PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.bf6('type', serializer_0(StringCompanionObject_getInstance()).qf5());
      $this$buildSerialDescriptor.bf6('value', buildSerialDescriptor('kotlinx.serialization.Polymorphic<' + this$0.cf6_1.zi() + '>', CONTEXTUAL_getInstance(), []));
      $this$buildSerialDescriptor.vf5_1 = this$0.df6_1;
      return Unit_getInstance();
    };
  }
  function PolymorphicSerializer$descriptor$delegate$lambda(this$0) {
    return function () {
      var tmp = OPEN_getInstance();
      return withContext(buildSerialDescriptor('kotlinx.serialization.Polymorphic', tmp, [], PolymorphicSerializer$descriptor$delegate$lambda$lambda(this$0)), this$0.cf6_1);
    };
  }
  function PolymorphicSerializer(baseClass) {
    AbstractPolymorphicSerializer.call(this);
    this.cf6_1 = baseClass;
    this.df6_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.ef6_1 = lazy(tmp_0, PolymorphicSerializer$descriptor$delegate$lambda(this));
  }
  protoOf(PolymorphicSerializer).ff6 = function () {
    return this.cf6_1;
  };
  protoOf(PolymorphicSerializer).qf5 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = descriptor$factory();
    tmp$ret$0 = this.ef6_1.f1();
    return tmp$ret$0;
  };
  protoOf(PolymorphicSerializer).toString = function () {
    return 'kotlinx.serialization.PolymorphicSerializer(baseClass: ' + this.cf6_1 + ')';
  };
  function findPolymorphicSerializer(_this__u8e3s4, encoder, value) {
    var tmp0_elvis_lhs = _this__u8e3s4.hf6(encoder, value);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throwSubtypeNotRegistered(getKClassFromExpression(value), _this__u8e3s4.ff6());
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function findPolymorphicSerializer_0(_this__u8e3s4, decoder, klassName) {
    var tmp0_elvis_lhs = _this__u8e3s4.gf6(decoder, klassName);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throwSubtypeNotRegistered_0(klassName, _this__u8e3s4.ff6());
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function descriptor$factory() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.qf5();
    }, null);
  }
  function SealedClassSerializer_init_$Init$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations, $this) {
    SealedClassSerializer.call($this, serialName, baseClass, subclasses, subclassSerializers);
    $this.jf6_1 = asList(classAnnotations);
    return $this;
  }
  function SealedClassSerializer_init_$Create$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations) {
    return SealedClassSerializer_init_$Init$(serialName, baseClass, subclasses, subclassSerializers, classAnnotations, objectCreate(protoOf(SealedClassSerializer)));
  }
  function SealedClassSerializer$descriptor$delegate$lambda$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      var tmp0_forEach = this$0.mf6_1;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.iterator' call
      tmp$ret$0 = tmp0_forEach.d1().h();
      var tmp0_iterator = tmp$ret$0;
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        // Inline function 'kotlinx.serialization.SealedClassSerializer.descriptor$delegate.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.collections.component1' call
        tmp$ret$1 = element.e1();
        var name = tmp$ret$1;
        var tmp$ret$2;
        // Inline function 'kotlin.collections.component2' call
        tmp$ret$2 = element.f1();
        var serializer = tmp$ret$2;
        $this$buildSerialDescriptor.bf6(name, serializer.qf5());
      }
      return Unit_getInstance();
    };
  }
  function SealedClassSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.bf6('type', serializer_0(StringCompanionObject_getInstance()).qf5());
      var tmp = 'kotlinx.serialization.Sealed<' + this$0.if6_1.zi() + '>';
      var tmp_0 = CONTEXTUAL_getInstance();
      var elementDescriptor = buildSerialDescriptor(tmp, tmp_0, [], SealedClassSerializer$descriptor$delegate$lambda$lambda$lambda(this$0));
      $this$buildSerialDescriptor.bf6('value', elementDescriptor);
      $this$buildSerialDescriptor.vf5_1 = this$0.jf6_1;
      return Unit_getInstance();
    };
  }
  function SealedClassSerializer$descriptor$delegate$lambda($serialName, this$0) {
    return function () {
      var tmp = SEALED_getInstance();
      return buildSerialDescriptor($serialName, tmp, [], SealedClassSerializer$descriptor$delegate$lambda$lambda(this$0));
    };
  }
  function _no_name_provided__qut3iv($tmp0_groupingBy) {
    this.nf6_1 = $tmp0_groupingBy;
  }
  protoOf(_no_name_provided__qut3iv).of6 = function () {
    return this.nf6_1.h();
  };
  protoOf(_no_name_provided__qut3iv).pf6 = function (element) {
    var tmp$ret$0;
    // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
    tmp$ret$0 = element.f1().qf5().qf6();
    return tmp$ret$0;
  };
  protoOf(_no_name_provided__qut3iv).rf6 = function (element) {
    return this.pf6((!(element == null) ? isInterface(element, Entry) : false) ? element : THROW_CCE());
  };
  function SealedClassSerializer(serialName, baseClass, subclasses, subclassSerializers) {
    AbstractPolymorphicSerializer.call(this);
    this.if6_1 = baseClass;
    this.jf6_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.kf6_1 = lazy(tmp_0, SealedClassSerializer$descriptor$delegate$lambda(serialName, this));
    if (!(subclasses.length === subclassSerializers.length)) {
      throw IllegalArgumentException_init_$Create$('All subclasses of sealed class ' + this.if6_1.zi() + ' should be marked @Serializable');
    }
    this.lf6_1 = toMap(zip(subclasses, subclassSerializers));
    var tmp_1 = this;
    var tmp$ret$10;
    // Inline function 'kotlin.collections.mapValues' call
    var tmp$ret$5;
    // Inline function 'kotlin.collections.aggregate' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.groupingBy' call
    var tmp0_groupingBy = this.lf6_1.d1();
    tmp$ret$0 = new _no_name_provided__qut3iv(tmp0_groupingBy);
    var tmp4_aggregate = tmp$ret$0;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.aggregateTo' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$1 = LinkedHashMap_init_$Create$();
    var tmp3_aggregateTo = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.iterator' call
    var tmp1_iterator = tmp4_aggregate.of6();
    tmp$ret$2 = tmp1_iterator;
    var tmp0_iterator = tmp$ret$2;
    while (tmp0_iterator.j()) {
      var e = tmp0_iterator.k();
      var key = tmp4_aggregate.rf6(e);
      var accumulator = tmp3_aggregateTo.h3(key);
      // Inline function 'kotlin.collections.set' call
      var tmp$ret$3;
      // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
      var tmp5__anonymous__kpxxpo = accumulator == null ? !tmp3_aggregateTo.b3(key) : false;
      if (!(accumulator == null)) {
        // Inline function 'kotlin.error' call
        var tmp0_error = "Multiple sealed subclasses of '" + this.if6_1 + "' have the same serial name '" + key + "':" + (" '" + accumulator.e1() + "', '" + e.e1() + "'");
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      }
      tmp$ret$3 = e;
      var tmp2_set = tmp$ret$3;
      tmp3_aggregateTo.e5(key, tmp2_set);
    }
    tmp$ret$4 = tmp3_aggregateTo;
    tmp$ret$5 = tmp$ret$4;
    var tmp8_mapValues = tmp$ret$5;
    var tmp$ret$9;
    // Inline function 'kotlin.collections.mapValuesTo' call
    var tmp7_mapValuesTo = LinkedHashMap_init_$Create$_0(mapCapacity(tmp8_mapValues.l()));
    var tmp$ret$8;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp6_associateByTo = tmp8_mapValues.d1();
    var tmp0_iterator_0 = tmp6_associateByTo.h();
    while (tmp0_iterator_0.j()) {
      var element = tmp0_iterator_0.k();
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
      tmp$ret$6 = element.e1();
      var tmp_2 = tmp$ret$6;
      var tmp$ret$7;
      // Inline function 'kotlinx.serialization.SealedClassSerializer.<anonymous>' call
      tmp$ret$7 = element.f1().f1();
      tmp7_mapValuesTo.e5(tmp_2, tmp$ret$7);
    }
    tmp$ret$8 = tmp7_mapValuesTo;
    tmp$ret$9 = tmp$ret$8;
    tmp$ret$10 = tmp$ret$9;
    tmp_1.mf6_1 = tmp$ret$10;
  }
  protoOf(SealedClassSerializer).ff6 = function () {
    return this.if6_1;
  };
  protoOf(SealedClassSerializer).qf5 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = descriptor$factory_0();
    tmp$ret$0 = this.kf6_1.f1();
    return tmp$ret$0;
  };
  protoOf(SealedClassSerializer).gf6 = function (decoder, klassName) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.get' call
    var tmp0_get = this.mf6_1;
    tmp$ret$0 = (isInterface(tmp0_get, Map) ? tmp0_get : THROW_CCE()).h3(klassName);
    var tmp0_elvis_lhs = tmp$ret$0;
    return tmp0_elvis_lhs == null ? protoOf(AbstractPolymorphicSerializer).gf6.call(this, decoder, klassName) : tmp0_elvis_lhs;
  };
  protoOf(SealedClassSerializer).hf6 = function (encoder, value) {
    var tmp0_elvis_lhs = this.lf6_1.h3(getKClassFromExpression(value));
    var tmp1_safe_receiver = tmp0_elvis_lhs == null ? protoOf(AbstractPolymorphicSerializer).hf6.call(this, encoder, value) : tmp0_elvis_lhs;
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp$ret$0 = (!(tmp1_safe_receiver == null) ? isInterface(tmp1_safe_receiver, SerializationStrategy) : false) ? tmp1_safe_receiver : THROW_CCE();
      tmp = tmp$ret$0;
    }
    return tmp;
  };
  function descriptor$factory_0() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.qf5();
    }, null);
  }
  function SerializationException_init_$Init$(message, $this) {
    IllegalArgumentException_init_$Init$(message, $this);
    SerializationException.call($this);
    return $this;
  }
  function SerializationException_init_$Create$(message) {
    var tmp = SerializationException_init_$Init$(message, objectCreate(protoOf(SerializationException)));
    captureStack(tmp, SerializationException_init_$Create$);
    return tmp;
  }
  function SerializationException_init_$Init$_0(message, cause, $this) {
    IllegalArgumentException_init_$Init$_0(message, cause, $this);
    SerializationException.call($this);
    return $this;
  }
  function SerializationException() {
    captureStack(this, SerializationException);
  }
  function UnknownFieldException_init_$Init$(index, $this) {
    UnknownFieldException.call($this, 'An unknown field for index ' + index);
    return $this;
  }
  function UnknownFieldException_init_$Create$(index) {
    var tmp = UnknownFieldException_init_$Init$(index, objectCreate(protoOf(UnknownFieldException)));
    captureStack(tmp, UnknownFieldException_init_$Create$);
    return tmp;
  }
  function UnknownFieldException(message) {
    SerializationException_init_$Init$(message, this);
    captureStack(this, UnknownFieldException);
  }
  function MissingFieldException_init_$Init$(missingFields, serialName, $this) {
    MissingFieldException.call($this, missingFields, missingFields.l() === 1 ? "Field '" + missingFields.n(0) + "' is required for type with serial name '" + serialName + "', but it was missing" : 'Fields ' + missingFields + " are required for type with serial name '" + serialName + "', but they were missing", null);
    return $this;
  }
  function MissingFieldException_init_$Create$(missingFields, serialName) {
    var tmp = MissingFieldException_init_$Init$(missingFields, serialName, objectCreate(protoOf(MissingFieldException)));
    captureStack(tmp, MissingFieldException_init_$Create$);
    return tmp;
  }
  function MissingFieldException(missingFields, message, cause) {
    SerializationException_init_$Init$_0(message, cause, this);
    captureStack(this, MissingFieldException);
    this.sf6_1 = missingFields;
  }
  function serializerOrNull(_this__u8e3s4) {
    var tmp0_elvis_lhs = compiledSerializerImpl(_this__u8e3s4);
    return tmp0_elvis_lhs == null ? builtinSerializerOrNull(_this__u8e3s4) : tmp0_elvis_lhs;
  }
  function serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer) {
    var tmp;
    if (failOnMissingTypeArgSerializer) {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.map' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(typeArguments, 10));
      var tmp0_iterator = typeArguments.h();
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
        tmp$ret$0 = serializer(_this__u8e3s4, item);
        tmp0_mapTo.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapTo;
      tmp$ret$2 = tmp$ret$1;
      tmp = tmp$ret$2;
    } else {
      var tmp$ret$5;
      // Inline function 'kotlin.collections.map' call
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp1_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(typeArguments, 10));
      var tmp0_iterator_0 = typeArguments.h();
      while (tmp0_iterator_0.j()) {
        var item_0 = tmp0_iterator_0.k();
        var tmp$ret$3;
        // Inline function 'kotlinx.serialization.serializersForParameters.<anonymous>' call
        var tmp0_elvis_lhs = serializerOrNull_0(_this__u8e3s4, item_0);
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          return null;
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        tmp$ret$3 = tmp_0;
        tmp1_mapTo.b(tmp$ret$3);
      }
      tmp$ret$4 = tmp1_mapTo;
      tmp$ret$5 = tmp$ret$4;
      tmp = tmp$ret$5;
    }
    var serializers = tmp;
    return serializers;
  }
  function parametrizedSerializerOrNull(_this__u8e3s4, serializers, elementClassifierIfArray) {
    var tmp0_elvis_lhs = builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray);
    return tmp0_elvis_lhs == null ? compiledParametrizedSerializer(_this__u8e3s4, serializers) : tmp0_elvis_lhs;
  }
  function serializer(_this__u8e3s4, type) {
    var tmp0_elvis_lhs = serializerByKTypeImpl(_this__u8e3s4, type, true);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      platformSpecificSerializerNotRegistered(kclass(type));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function serializerOrNull_0(_this__u8e3s4, type) {
    return serializerByKTypeImpl(_this__u8e3s4, type, false);
  }
  function builtinParametrizedSerializer(_this__u8e3s4, serializers, elementClassifierIfArray) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (((tmp0_subject.equals(getKClass(Collection)) ? true : tmp0_subject.equals(getKClass(List))) ? true : tmp0_subject.equals(getKClass(MutableList))) ? true : tmp0_subject.equals(getKClass(ArrayList))) {
      tmp = new ArrayListSerializer(serializers.n(0));
    } else if (tmp0_subject.equals(getKClass(HashSet))) {
      tmp = new HashSetSerializer(serializers.n(0));
    } else if ((tmp0_subject.equals(getKClass(Set)) ? true : tmp0_subject.equals(getKClass(MutableSet))) ? true : tmp0_subject.equals(getKClass(LinkedHashSet))) {
      tmp = new LinkedHashSetSerializer(serializers.n(0));
    } else if (tmp0_subject.equals(getKClass(HashMap))) {
      tmp = new HashMapSerializer(serializers.n(0), serializers.n(1));
    } else if ((tmp0_subject.equals(getKClass(Map)) ? true : tmp0_subject.equals(getKClass(MutableMap))) ? true : tmp0_subject.equals(getKClass(LinkedHashMap))) {
      tmp = new LinkedHashMapSerializer(serializers.n(0), serializers.n(1));
    } else if (tmp0_subject.equals(getKClass(Entry))) {
      tmp = MapEntrySerializer(serializers.n(0), serializers.n(1));
    } else if (tmp0_subject.equals(getKClass(Pair))) {
      tmp = PairSerializer(serializers.n(0), serializers.n(1));
    } else if (tmp0_subject.equals(getKClass(Triple))) {
      tmp = TripleSerializer(serializers.n(0), serializers.n(1), serializers.n(2));
    } else {
      var tmp_0;
      if (isReferenceArray(_this__u8e3s4)) {
        var tmp_1 = elementClassifierIfArray();
        tmp_0 = ArraySerializer((!(tmp_1 == null) ? isInterface(tmp_1, KClass) : false) ? tmp_1 : THROW_CCE(), serializers.n(0));
      } else {
        tmp_0 = null;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function compiledParametrizedSerializer(_this__u8e3s4, serializers) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.toTypedArray' call
    tmp$ret$0 = copyToArray(serializers);
    return constructSerializerForGivenTypeArgs(_this__u8e3s4, tmp$ret$0.slice());
  }
  function serializerByKTypeImpl(_this__u8e3s4, type, failOnMissingTypeArgSerializer) {
    var rootClass = kclass(type);
    var isNullable = type.nj();
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = type.mj();
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'kotlinx.serialization.serializerByKTypeImpl.<anonymous>' call
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.requireNotNull' call
        var tmp0_requireNotNull = item.uf6_1;
        // Inline function 'kotlin.contracts.contract' call
        if (tmp0_requireNotNull == null) {
          var tmp$ret$0;
          // Inline function 'kotlinx.serialization.serializerByKTypeImpl.<anonymous>.<anonymous>' call
          tmp$ret$0 = 'Star projections in type arguments are not allowed, but had ' + type;
          var message = tmp$ret$0;
          throw IllegalArgumentException_init_$Create$(toString(message));
        } else {
          tmp$ret$1 = tmp0_requireNotNull;
          break $l$block;
        }
      }
      tmp$ret$2 = tmp$ret$1;
      tmp0_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp0_mapTo;
    tmp$ret$4 = tmp$ret$3;
    var typeArguments = tmp$ret$4;
    var tmp;
    if (typeArguments.p()) {
      tmp = findCachedSerializer(rootClass, isNullable);
    } else {
      var cachedResult = findParametrizedCachedSerializer(rootClass, typeArguments, isNullable);
      var tmp_0;
      if (failOnMissingTypeArgSerializer) {
        var tmp$ret$5;
        // Inline function 'kotlin.Result.getOrNull' call
        var tmp_1;
        if (_Result___get_isFailure__impl__jpiriv(cachedResult)) {
          tmp_1 = null;
        } else {
          var tmp_2 = _Result___get_value__impl__bjfvqg(cachedResult);
          tmp_1 = (tmp_2 == null ? true : isObject(tmp_2)) ? tmp_2 : THROW_CCE();
        }
        tmp$ret$5 = tmp_1;
        tmp_0 = tmp$ret$5;
      } else {
        var tmp$ret$6;
        // Inline function 'kotlin.getOrElse' call
        // Inline function 'kotlin.contracts.contract' call
        var exception = Result__exceptionOrNull_impl_p6xea9(cachedResult);
        var tmp_3;
        if (exception == null) {
          var tmp_4 = _Result___get_value__impl__bjfvqg(cachedResult);
          tmp_3 = (tmp_4 == null ? true : isObject(tmp_4)) ? tmp_4 : THROW_CCE();
        } else {
          return null;
        }
        tmp$ret$6 = tmp_3;
        tmp_0 = tmp$ret$6;
      }
      tmp = tmp_0;
    }
    var cachedSerializer = tmp;
    var tmp0_safe_receiver = cachedSerializer;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$7;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      return tmp0_safe_receiver;
    }
    var tmp_5;
    if (typeArguments.p()) {
      tmp_5 = _this__u8e3s4.wf6(rootClass);
    } else {
      var tmp1_elvis_lhs = serializersForParameters(_this__u8e3s4, typeArguments, failOnMissingTypeArgSerializer);
      var tmp_6;
      if (tmp1_elvis_lhs == null) {
        return null;
      } else {
        tmp_6 = tmp1_elvis_lhs;
      }
      var serializers = tmp_6;
      var tmp2_elvis_lhs = parametrizedSerializerOrNull(rootClass, serializers, serializerByKTypeImpl$lambda(typeArguments));
      tmp_5 = tmp2_elvis_lhs == null ? _this__u8e3s4.vf6(rootClass, serializers) : tmp2_elvis_lhs;
    }
    var contextualSerializer = tmp_5;
    var tmp3_safe_receiver = contextualSerializer;
    var tmp_7;
    if (tmp3_safe_receiver == null) {
      tmp_7 = null;
    } else {
      var tmp$ret$8;
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp$ret$8 = (!(tmp3_safe_receiver == null) ? isInterface(tmp3_safe_receiver, KSerializer) : false) ? tmp3_safe_receiver : THROW_CCE();
      tmp_7 = tmp$ret$8;
    }
    var tmp4_safe_receiver = tmp_7;
    return tmp4_safe_receiver == null ? null : nullable(tmp4_safe_receiver, isNullable);
  }
  function nullable(_this__u8e3s4, shouldBeNullable) {
    if (shouldBeNullable)
      return get_nullable(_this__u8e3s4);
    return isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
  }
  function serializerByKTypeImpl$lambda($typeArguments) {
    return function () {
      return $typeArguments.n(0).lj();
    };
  }
  function get_SERIALIZERS_CACHE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return SERIALIZERS_CACHE;
  }
  var SERIALIZERS_CACHE;
  function get_SERIALIZERS_CACHE_NULLABLE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return SERIALIZERS_CACHE_NULLABLE;
  }
  var SERIALIZERS_CACHE_NULLABLE;
  function get_PARAMETRIZED_SERIALIZERS_CACHE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return PARAMETRIZED_SERIALIZERS_CACHE;
  }
  var PARAMETRIZED_SERIALIZERS_CACHE;
  function get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE() {
    _init_properties_SerializersCache_kt__hgwi2p();
    return PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
  }
  var PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE;
  function findCachedSerializer(clazz, isNullable) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp;
    if (!isNullable) {
      var tmp0_safe_receiver = get_SERIALIZERS_CACHE().xf6(clazz);
      var tmp_0;
      if (tmp0_safe_receiver == null) {
        tmp_0 = null;
      } else {
        var tmp$ret$0;
        // Inline function 'kotlinx.serialization.internal.cast' call
        tmp$ret$0 = (!(tmp0_safe_receiver == null) ? isInterface(tmp0_safe_receiver, KSerializer) : false) ? tmp0_safe_receiver : THROW_CCE();
        tmp_0 = tmp$ret$0;
      }
      tmp = tmp_0;
    } else {
      tmp = get_SERIALIZERS_CACHE_NULLABLE().xf6(clazz);
    }
    return tmp;
  }
  function findParametrizedCachedSerializer(clazz, types, isNullable) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp;
    if (!isNullable) {
      var tmp_0 = get_PARAMETRIZED_SERIALIZERS_CACHE().yf6(clazz, types);
      tmp = new Result(tmp_0) instanceof Result ? tmp_0 : THROW_CCE();
    } else {
      tmp = get_PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE().yf6(clazz, types);
    }
    return tmp;
  }
  function SERIALIZERS_CACHE$lambda(it) {
    _init_properties_SerializersCache_kt__hgwi2p();
    return serializerOrNull(it);
  }
  function SERIALIZERS_CACHE_NULLABLE$lambda(it) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var tmp0_safe_receiver = serializerOrNull(it);
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_nullable(tmp0_safe_receiver);
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp$ret$0 = (!(tmp1_safe_receiver == null) ? isInterface(tmp1_safe_receiver, KSerializer) : false) ? tmp1_safe_receiver : THROW_CCE();
      tmp = tmp$ret$0;
    }
    return tmp;
  }
  function PARAMETRIZED_SERIALIZERS_CACHE$lambda(clazz, types) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
    return parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda(types));
  }
  function PARAMETRIZED_SERIALIZERS_CACHE$lambda$lambda($types) {
    return function () {
      return $types.n(0).lj();
    };
  }
  function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda(clazz, types) {
    _init_properties_SerializersCache_kt__hgwi2p();
    var serializers = ensureNotNull(serializersForParameters(EmptySerializersModule_0(), types, true));
    var tmp0_safe_receiver = parametrizedSerializerOrNull(clazz, serializers, PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda(types));
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_nullable(tmp0_safe_receiver);
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.cast' call
      tmp$ret$0 = (!(tmp1_safe_receiver == null) ? isInterface(tmp1_safe_receiver, KSerializer) : false) ? tmp1_safe_receiver : THROW_CCE();
      tmp = tmp$ret$0;
    }
    return tmp;
  }
  function PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda$lambda($types) {
    return function () {
      return $types.n(0).lj();
    };
  }
  var properties_initialized_SerializersCache_kt_q8kf25;
  function _init_properties_SerializersCache_kt__hgwi2p() {
    if (properties_initialized_SerializersCache_kt_q8kf25) {
    } else {
      properties_initialized_SerializersCache_kt_q8kf25 = true;
      SERIALIZERS_CACHE = createCache(SERIALIZERS_CACHE$lambda);
      SERIALIZERS_CACHE_NULLABLE = createCache(SERIALIZERS_CACHE_NULLABLE$lambda);
      PARAMETRIZED_SERIALIZERS_CACHE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE$lambda);
      PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE = createParametrizedCache(PARAMETRIZED_SERIALIZERS_CACHE_NULLABLE$lambda);
    }
  }
  function get_nullable(_this__u8e3s4) {
    var tmp;
    if (_this__u8e3s4.qf5().zf6()) {
      tmp = isInterface(_this__u8e3s4, KSerializer) ? _this__u8e3s4 : THROW_CCE();
    } else {
      tmp = new NullableSerializer(_this__u8e3s4);
    }
    return tmp;
  }
  function serializer_0(_this__u8e3s4) {
    return StringSerializer_getInstance();
  }
  function serializer_1(_this__u8e3s4) {
    return CharSerializer_getInstance();
  }
  function CharArraySerializer() {
    return CharArraySerializer_getInstance();
  }
  function serializer_2(_this__u8e3s4) {
    return DoubleSerializer_getInstance();
  }
  function DoubleArraySerializer() {
    return DoubleArraySerializer_getInstance();
  }
  function serializer_3(_this__u8e3s4) {
    return FloatSerializer_getInstance();
  }
  function FloatArraySerializer() {
    return FloatArraySerializer_getInstance();
  }
  function serializer_4(_this__u8e3s4) {
    return LongSerializer_getInstance();
  }
  function LongArraySerializer() {
    return LongArraySerializer_getInstance();
  }
  function serializer_5(_this__u8e3s4) {
    return ULongSerializer_getInstance();
  }
  function ULongArraySerializer() {
    return ULongArraySerializer_getInstance();
  }
  function serializer_6(_this__u8e3s4) {
    return IntSerializer_getInstance();
  }
  function IntArraySerializer() {
    return IntArraySerializer_getInstance();
  }
  function serializer_7(_this__u8e3s4) {
    return UIntSerializer_getInstance();
  }
  function UIntArraySerializer() {
    return UIntArraySerializer_getInstance();
  }
  function serializer_8(_this__u8e3s4) {
    return ShortSerializer_getInstance();
  }
  function ShortArraySerializer() {
    return ShortArraySerializer_getInstance();
  }
  function serializer_9(_this__u8e3s4) {
    return UShortSerializer_getInstance();
  }
  function UShortArraySerializer() {
    return UShortArraySerializer_getInstance();
  }
  function serializer_10(_this__u8e3s4) {
    return ByteSerializer_getInstance();
  }
  function ByteArraySerializer() {
    return ByteArraySerializer_getInstance();
  }
  function serializer_11(_this__u8e3s4) {
    return UByteSerializer_getInstance();
  }
  function UByteArraySerializer() {
    return UByteArraySerializer_getInstance();
  }
  function serializer_12(_this__u8e3s4) {
    return BooleanSerializer_getInstance();
  }
  function BooleanArraySerializer() {
    return BooleanArraySerializer_getInstance();
  }
  function serializer_13(_this__u8e3s4) {
    return UnitSerializer_getInstance();
  }
  function NothingSerializer() {
    return NothingSerializer_getInstance();
  }
  function serializer_14(_this__u8e3s4) {
    return DurationSerializer_getInstance();
  }
  function MapEntrySerializer(keySerializer, valueSerializer) {
    return new MapEntrySerializer_0(keySerializer, valueSerializer);
  }
  function PairSerializer(keySerializer, valueSerializer) {
    return new PairSerializer_0(keySerializer, valueSerializer);
  }
  function TripleSerializer(aSerializer, bSerializer, cSerializer) {
    return new TripleSerializer_0(aSerializer, bSerializer, cSerializer);
  }
  function ArraySerializer(kClass, elementSerializer) {
    return new ReferenceArraySerializer(kClass, elementSerializer);
  }
  function ListSerializer(elementSerializer) {
    return new ArrayListSerializer(elementSerializer);
  }
  function MapSerializer(keySerializer, valueSerializer) {
    return new LinkedHashMapSerializer(keySerializer, valueSerializer);
  }
  function withContext(_this__u8e3s4, context) {
    return new ContextDescriptor(_this__u8e3s4, context);
  }
  function ContextDescriptor(original, kClass) {
    this.af7_1 = original;
    this.bf7_1 = kClass;
    this.cf7_1 = this.af7_1.qf6() + '<' + this.bf7_1.zi() + '>';
  }
  protoOf(ContextDescriptor).df7 = function () {
    return this.af7_1.df7();
  };
  protoOf(ContextDescriptor).ef7 = function () {
    return this.af7_1.ef7();
  };
  protoOf(ContextDescriptor).ff7 = function () {
    return this.af7_1.ff7();
  };
  protoOf(ContextDescriptor).zf6 = function () {
    return this.af7_1.zf6();
  };
  protoOf(ContextDescriptor).gf7 = function () {
    return this.af7_1.gf7();
  };
  protoOf(ContextDescriptor).hf7 = function (index) {
    return this.af7_1.hf7(index);
  };
  protoOf(ContextDescriptor).if7 = function (index) {
    return this.af7_1.if7(index);
  };
  protoOf(ContextDescriptor).jf7 = function (name) {
    return this.af7_1.jf7(name);
  };
  protoOf(ContextDescriptor).kf7 = function (index) {
    return this.af7_1.kf7(index);
  };
  protoOf(ContextDescriptor).lf7 = function (index) {
    return this.af7_1.lf7(index);
  };
  protoOf(ContextDescriptor).qf6 = function () {
    return this.cf7_1;
  };
  protoOf(ContextDescriptor).equals = function (other) {
    var tmp0_elvis_lhs = other instanceof ContextDescriptor ? other : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var another = tmp;
    return equals(this.af7_1, another.af7_1) ? another.bf7_1.equals(this.bf7_1) : false;
  };
  protoOf(ContextDescriptor).hashCode = function () {
    var result = this.bf7_1.hashCode();
    result = imul(31, result) + getStringHashCode(this.cf7_1) | 0;
    return result;
  };
  protoOf(ContextDescriptor).toString = function () {
    return 'ContextDescriptor(kClass: ' + this.bf7_1 + ', original: ' + this.af7_1 + ')';
  };
  function getContextualDescriptor(_this__u8e3s4, descriptor) {
    var tmp0_safe_receiver = get_capturedKClass(descriptor);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.descriptors.getContextualDescriptor.<anonymous>' call
      var tmp0_safe_receiver_0 = _this__u8e3s4.wf6(tmp0_safe_receiver);
      tmp$ret$0 = tmp0_safe_receiver_0 == null ? null : tmp0_safe_receiver_0.qf5();
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    return tmp;
  }
  function get_capturedKClass(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject instanceof ContextDescriptor) {
      tmp = _this__u8e3s4.bf7_1;
    } else {
      if (tmp0_subject instanceof SerialDescriptorForNullable) {
        tmp = get_capturedKClass(_this__u8e3s4.mf7_1);
      } else {
        tmp = null;
      }
    }
    return tmp;
  }
  function SerialDescriptor() {
  }
  function get_elementDescriptors(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.Iterable' call
    tmp$ret$0 = new _no_name_provided__qut3iv_0(_this__u8e3s4);
    return tmp$ret$0;
  }
  function get_elementNames(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.Iterable' call
    tmp$ret$0 = new _no_name_provided__qut3iv_1(_this__u8e3s4);
    return tmp$ret$0;
  }
  function elementDescriptors$1$1($this_elementDescriptors) {
    this.qf7_1 = $this_elementDescriptors;
    this.pf7_1 = $this_elementDescriptors.ef7();
  }
  protoOf(elementDescriptors$1$1).j = function () {
    return this.pf7_1 > 0;
  };
  protoOf(elementDescriptors$1$1).k = function () {
    var tmp = this.qf7_1.ef7();
    var tmp0_this = this;
    var tmp1 = tmp0_this.pf7_1;
    tmp0_this.pf7_1 = tmp1 - 1 | 0;
    return this.qf7_1.if7(tmp - tmp1 | 0);
  };
  function _no_name_provided__qut3iv_0($this_elementDescriptors) {
    this.rf7_1 = $this_elementDescriptors;
  }
  protoOf(_no_name_provided__qut3iv_0).h = function () {
    var tmp$ret$0;
    // Inline function 'kotlinx.serialization.descriptors.<get-elementDescriptors>.<anonymous>' call
    tmp$ret$0 = new elementDescriptors$1$1(this.rf7_1);
    return tmp$ret$0;
  };
  function elementNames$1$1($this_elementNames) {
    this.tf7_1 = $this_elementNames;
    this.sf7_1 = $this_elementNames.ef7();
  }
  protoOf(elementNames$1$1).j = function () {
    return this.sf7_1 > 0;
  };
  protoOf(elementNames$1$1).k = function () {
    var tmp = this.tf7_1.ef7();
    var tmp0_this = this;
    var tmp1 = tmp0_this.sf7_1;
    tmp0_this.sf7_1 = tmp1 - 1 | 0;
    return this.tf7_1.kf7(tmp - tmp1 | 0);
  };
  function _no_name_provided__qut3iv_1($this_elementNames) {
    this.uf7_1 = $this_elementNames;
  }
  protoOf(_no_name_provided__qut3iv_1).h = function () {
    var tmp$ret$0;
    // Inline function 'kotlinx.serialization.descriptors.<get-elementNames>.<anonymous>' call
    tmp$ret$0 = new elementNames$1$1(this.uf7_1);
    return tmp$ret$0;
  };
  function buildSerialDescriptor(serialName, kind, typeParameters, builder) {
    var tmp;
    if (builder === VOID) {
      tmp = buildSerialDescriptor$lambda;
    } else {
      tmp = builder;
    }
    builder = tmp;
    // Inline function 'kotlin.require' call
    var tmp$ret$0;
    // Inline function 'kotlin.text.isNotBlank' call
    tmp$ret$0 = !isBlank(serialName);
    var tmp0_require = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
      tmp$ret$1 = 'Blank serial names are prohibited';
      var message = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = !equals(kind, CLASS_getInstance());
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$2;
      // Inline function 'kotlinx.serialization.descriptors.buildSerialDescriptor.<anonymous>' call
      tmp$ret$2 = "For StructureKind.CLASS please use 'buildClassSerialDescriptor' instead";
      var message_0 = tmp$ret$2;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
    builder(sdBuilder);
    return new SerialDescriptorImpl(serialName, kind, sdBuilder.wf5_1.l(), toList(typeParameters), sdBuilder);
  }
  function ClassSerialDescriptorBuilder(serialName) {
    this.tf5_1 = serialName;
    this.uf5_1 = false;
    this.vf5_1 = emptyList();
    this.wf5_1 = ArrayList_init_$Create$_0();
    this.xf5_1 = HashSet_init_$Create$();
    this.yf5_1 = ArrayList_init_$Create$_0();
    this.zf5_1 = ArrayList_init_$Create$_0();
    this.af6_1 = ArrayList_init_$Create$_0();
  }
  protoOf(ClassSerialDescriptorBuilder).vf7 = function (elementName, descriptor, annotations, isOptional) {
    // Inline function 'kotlin.require' call
    var tmp0_require = this.xf5_1.b(elementName);
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.descriptors.ClassSerialDescriptorBuilder.element.<anonymous>' call
      tmp$ret$0 = "Element with name '" + elementName + "' is already registered in " + this.tf5_1;
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp1_plusAssign = tmp0_this.wf5_1;
    tmp1_plusAssign.b(elementName);
    var tmp1_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp2_plusAssign = tmp1_this.yf5_1;
    tmp2_plusAssign.b(descriptor);
    var tmp2_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp3_plusAssign = tmp2_this.zf5_1;
    tmp3_plusAssign.b(annotations);
    var tmp3_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp4_plusAssign = tmp3_this.af6_1;
    tmp4_plusAssign.b(isOptional);
  };
  protoOf(ClassSerialDescriptorBuilder).bf6 = function (elementName, descriptor, annotations, isOptional, $super) {
    annotations = annotations === VOID ? emptyList() : annotations;
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.vf7(elementName, descriptor, annotations, isOptional);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.vf7.call(this, elementName, descriptor, annotations, isOptional);
    }
    return tmp;
  };
  function buildClassSerialDescriptor(serialName, typeParameters, builderAction) {
    var tmp;
    if (builderAction === VOID) {
      tmp = buildClassSerialDescriptor$lambda;
    } else {
      tmp = builderAction;
    }
    builderAction = tmp;
    // Inline function 'kotlin.require' call
    var tmp$ret$0;
    // Inline function 'kotlin.text.isNotBlank' call
    tmp$ret$0 = !isBlank(serialName);
    var tmp0_require = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.descriptors.buildClassSerialDescriptor.<anonymous>' call
      tmp$ret$1 = 'Blank serial names are prohibited';
      var message = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var sdBuilder = new ClassSerialDescriptorBuilder(serialName);
    builderAction(sdBuilder);
    return new SerialDescriptorImpl(serialName, CLASS_getInstance(), sdBuilder.wf5_1.l(), toList(typeParameters), sdBuilder);
  }
  function _get__hashCode__tgwhef($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _hashCode$factory();
    tmp$ret$0 = $this.hf8_1.f1();
    return tmp$ret$0;
  }
  function SerialDescriptorImpl$_hashCode$delegate$lambda(this$0) {
    return function () {
      return hashCodeImpl(this$0, this$0.gf8_1);
    };
  }
  function SerialDescriptorImpl$toString$lambda(this$0) {
    return function (it) {
      return this$0.kf7(it) + ': ' + this$0.if7(it).qf6();
    };
  }
  function SerialDescriptorImpl(serialName, kind, elementsCount, typeParameters, builder) {
    this.wf7_1 = serialName;
    this.xf7_1 = kind;
    this.yf7_1 = elementsCount;
    this.zf7_1 = builder.vf5_1;
    this.af8_1 = toHashSet(builder.wf5_1);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp0_toTypedArray = builder.wf5_1;
    tmp$ret$0 = copyToArray(tmp0_toTypedArray);
    tmp.bf8_1 = tmp$ret$0;
    this.cf8_1 = compactArray(builder.yf5_1);
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp0_toTypedArray_0 = builder.zf5_1;
    tmp$ret$1 = copyToArray(tmp0_toTypedArray_0);
    tmp_0.df8_1 = tmp$ret$1;
    this.ef8_1 = toBooleanArray(builder.af6_1);
    var tmp_1 = this;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = withIndex(this.bf8_1);
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.name2Index.<anonymous>' call
      tmp$ret$2 = to(item.s4_1, item.r4_1);
      tmp0_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp0_mapTo;
    tmp$ret$4 = tmp$ret$3;
    tmp_1.ff8_1 = toMap(tmp$ret$4);
    this.gf8_1 = compactArray(typeParameters);
    var tmp_2 = this;
    tmp_2.hf8_1 = lazy_0(SerialDescriptorImpl$_hashCode$delegate$lambda(this));
  }
  protoOf(SerialDescriptorImpl).qf6 = function () {
    return this.wf7_1;
  };
  protoOf(SerialDescriptorImpl).gf7 = function () {
    return this.xf7_1;
  };
  protoOf(SerialDescriptorImpl).ef7 = function () {
    return this.yf7_1;
  };
  protoOf(SerialDescriptorImpl).df7 = function () {
    return this.zf7_1;
  };
  protoOf(SerialDescriptorImpl).if8 = function () {
    return this.af8_1;
  };
  protoOf(SerialDescriptorImpl).kf7 = function (index) {
    return getChecked(this.bf8_1, index);
  };
  protoOf(SerialDescriptorImpl).jf7 = function (name) {
    var tmp0_elvis_lhs = this.ff8_1.h3(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      Companion_getInstance_7();
      tmp = -3;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(SerialDescriptorImpl).hf7 = function (index) {
    return getChecked(this.df8_1, index);
  };
  protoOf(SerialDescriptorImpl).if7 = function (index) {
    return getChecked(this.cf8_1, index);
  };
  protoOf(SerialDescriptorImpl).lf7 = function (index) {
    return getChecked_0(this.ef8_1, index);
  };
  protoOf(SerialDescriptorImpl).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof SerialDescriptorImpl)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qf6() === other.qf6())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.descriptors.SerialDescriptorImpl.equals.<anonymous>' call
      var tmp0__anonymous__q1qw7t = other;
      tmp$ret$1 = contentEquals(this.gf8_1, tmp0__anonymous__q1qw7t.gf8_1);
      if (!tmp$ret$1) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.ef7() === other.ef7())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.ef7();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.if7(index).qf6() === other.if7(index).qf6())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.if7(index).gf7(), other.if7(index).gf7())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(SerialDescriptorImpl).hashCode = function () {
    return _get__hashCode__tgwhef(this);
  };
  protoOf(SerialDescriptorImpl).toString = function () {
    var tmp = until(0, this.yf7_1);
    var tmp_0 = this.wf7_1 + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, SerialDescriptorImpl$toString$lambda(this));
  };
  function PrimitiveSerialDescriptor(serialName, kind) {
    // Inline function 'kotlin.require' call
    var tmp$ret$0;
    // Inline function 'kotlin.text.isNotBlank' call
    tmp$ret$0 = !isBlank(serialName);
    var tmp0_require = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.descriptors.PrimitiveSerialDescriptor.<anonymous>' call
      tmp$ret$1 = 'Blank serial names are prohibited';
      var message = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return PrimitiveDescriptorSafe(serialName, kind);
  }
  function buildSerialDescriptor$lambda($this$null) {
    return Unit_getInstance();
  }
  function buildClassSerialDescriptor$lambda($this$null) {
    return Unit_getInstance();
  }
  function _hashCode$factory() {
    return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
      return _get__hashCode__tgwhef(receiver);
    }, null);
  }
  function ENUM() {
    ENUM_instance = this;
    SerialKind.call(this);
  }
  var ENUM_instance;
  function ENUM_getInstance() {
    if (ENUM_instance == null)
      new ENUM();
    return ENUM_instance;
  }
  function CONTEXTUAL() {
    CONTEXTUAL_instance = this;
    SerialKind.call(this);
  }
  var CONTEXTUAL_instance;
  function CONTEXTUAL_getInstance() {
    if (CONTEXTUAL_instance == null)
      new CONTEXTUAL();
    return CONTEXTUAL_instance;
  }
  function SerialKind() {
  }
  protoOf(SerialKind).toString = function () {
    return ensureNotNull(getKClassFromExpression(this).zi());
  };
  protoOf(SerialKind).hashCode = function () {
    return getStringHashCode(this.toString());
  };
  function SEALED() {
    SEALED_instance = this;
    PolymorphicKind.call(this);
  }
  var SEALED_instance;
  function SEALED_getInstance() {
    if (SEALED_instance == null)
      new SEALED();
    return SEALED_instance;
  }
  function OPEN() {
    OPEN_instance = this;
    PolymorphicKind.call(this);
  }
  var OPEN_instance;
  function OPEN_getInstance() {
    if (OPEN_instance == null)
      new OPEN();
    return OPEN_instance;
  }
  function PolymorphicKind() {
    SerialKind.call(this);
  }
  function BOOLEAN() {
    BOOLEAN_instance = this;
    PrimitiveKind.call(this);
  }
  var BOOLEAN_instance;
  function BOOLEAN_getInstance() {
    if (BOOLEAN_instance == null)
      new BOOLEAN();
    return BOOLEAN_instance;
  }
  function BYTE() {
    BYTE_instance = this;
    PrimitiveKind.call(this);
  }
  var BYTE_instance;
  function BYTE_getInstance() {
    if (BYTE_instance == null)
      new BYTE();
    return BYTE_instance;
  }
  function CHAR() {
    CHAR_instance = this;
    PrimitiveKind.call(this);
  }
  var CHAR_instance;
  function CHAR_getInstance() {
    if (CHAR_instance == null)
      new CHAR();
    return CHAR_instance;
  }
  function SHORT() {
    SHORT_instance = this;
    PrimitiveKind.call(this);
  }
  var SHORT_instance;
  function SHORT_getInstance() {
    if (SHORT_instance == null)
      new SHORT();
    return SHORT_instance;
  }
  function INT() {
    INT_instance = this;
    PrimitiveKind.call(this);
  }
  var INT_instance;
  function INT_getInstance() {
    if (INT_instance == null)
      new INT();
    return INT_instance;
  }
  function LONG() {
    LONG_instance = this;
    PrimitiveKind.call(this);
  }
  var LONG_instance;
  function LONG_getInstance() {
    if (LONG_instance == null)
      new LONG();
    return LONG_instance;
  }
  function FLOAT() {
    FLOAT_instance = this;
    PrimitiveKind.call(this);
  }
  var FLOAT_instance;
  function FLOAT_getInstance() {
    if (FLOAT_instance == null)
      new FLOAT();
    return FLOAT_instance;
  }
  function DOUBLE() {
    DOUBLE_instance = this;
    PrimitiveKind.call(this);
  }
  var DOUBLE_instance;
  function DOUBLE_getInstance() {
    if (DOUBLE_instance == null)
      new DOUBLE();
    return DOUBLE_instance;
  }
  function STRING() {
    STRING_instance = this;
    PrimitiveKind.call(this);
  }
  var STRING_instance;
  function STRING_getInstance() {
    if (STRING_instance == null)
      new STRING();
    return STRING_instance;
  }
  function PrimitiveKind() {
    SerialKind.call(this);
  }
  function CLASS() {
    CLASS_instance = this;
    StructureKind.call(this);
  }
  var CLASS_instance;
  function CLASS_getInstance() {
    if (CLASS_instance == null)
      new CLASS();
    return CLASS_instance;
  }
  function LIST() {
    LIST_instance = this;
    StructureKind.call(this);
  }
  var LIST_instance;
  function LIST_getInstance() {
    if (LIST_instance == null)
      new LIST();
    return LIST_instance;
  }
  function MAP() {
    MAP_instance = this;
    StructureKind.call(this);
  }
  var MAP_instance;
  function MAP_getInstance() {
    if (MAP_instance == null)
      new MAP();
    return MAP_instance;
  }
  function OBJECT() {
    OBJECT_instance = this;
    StructureKind.call(this);
  }
  var OBJECT_instance;
  function OBJECT_getInstance() {
    if (OBJECT_instance == null)
      new OBJECT();
    return OBJECT_instance;
  }
  function StructureKind() {
    SerialKind.call(this);
  }
  function AbstractDecoder() {
  }
  protoOf(AbstractDecoder).jf8 = function () {
    throw SerializationException_init_$Create$('' + getKClassFromExpression(this) + " can't retrieve untyped values");
  };
  protoOf(AbstractDecoder).kf8 = function () {
    return true;
  };
  protoOf(AbstractDecoder).lf8 = function () {
    return null;
  };
  protoOf(AbstractDecoder).mf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).nf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).of8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).pf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).qf8 = function () {
    var tmp = this.jf8();
    return tmp instanceof Long ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).rf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).sf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).tf8 = function () {
    var tmp = this.jf8();
    return tmp instanceof Char ? tmp.ja_1 : THROW_CCE();
  };
  protoOf(AbstractDecoder).uf8 = function () {
    var tmp = this.jf8();
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).vf8 = function (enumDescriptor) {
    var tmp = this.jf8();
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(AbstractDecoder).wf8 = function (descriptor) {
    return this;
  };
  protoOf(AbstractDecoder).xf8 = function (deserializer, previousValue) {
    return this.yf8(deserializer);
  };
  protoOf(AbstractDecoder).zf8 = function (descriptor) {
    return this;
  };
  protoOf(AbstractDecoder).af9 = function (descriptor) {
  };
  protoOf(AbstractDecoder).bf9 = function (descriptor, index) {
    return this.mf8();
  };
  protoOf(AbstractDecoder).cf9 = function (descriptor, index) {
    return this.nf8();
  };
  protoOf(AbstractDecoder).df9 = function (descriptor, index) {
    return this.of8();
  };
  protoOf(AbstractDecoder).ef9 = function (descriptor, index) {
    return this.pf8();
  };
  protoOf(AbstractDecoder).ff9 = function (descriptor, index) {
    return this.qf8();
  };
  protoOf(AbstractDecoder).gf9 = function (descriptor, index) {
    return this.rf8();
  };
  protoOf(AbstractDecoder).hf9 = function (descriptor, index) {
    return this.sf8();
  };
  protoOf(AbstractDecoder).if9 = function (descriptor, index) {
    return this.tf8();
  };
  protoOf(AbstractDecoder).jf9 = function (descriptor, index) {
    return this.uf8();
  };
  protoOf(AbstractDecoder).kf9 = function (descriptor, index) {
    return this.wf8(descriptor.if7(index));
  };
  protoOf(AbstractDecoder).lf9 = function (descriptor, index, deserializer, previousValue) {
    return this.xf8(deserializer, previousValue);
  };
  protoOf(AbstractDecoder).nf9 = function (descriptor, index, deserializer, previousValue) {
    var isNullabilitySupported = deserializer.qf5().zf6();
    return (isNullabilitySupported ? true : this.kf8()) ? this.xf8(deserializer, previousValue) : this.lf8();
  };
  function AbstractEncoder() {
  }
  protoOf(AbstractEncoder).zf8 = function (descriptor) {
    return this;
  };
  protoOf(AbstractEncoder).af9 = function (descriptor) {
  };
  protoOf(AbstractEncoder).sf9 = function (descriptor, index) {
    return true;
  };
  protoOf(AbstractEncoder).tf9 = function (value) {
    throw SerializationException_init_$Create$('Non-serializable ' + getKClassFromExpression(value) + ' is not supported by ' + getKClassFromExpression(this) + ' encoder');
  };
  protoOf(AbstractEncoder).uf9 = function () {
    throw SerializationException_init_$Create$("'null' is not supported by default");
  };
  protoOf(AbstractEncoder).vf9 = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).wf9 = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).xf9 = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).yf9 = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).zf9 = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).afa = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).bfa = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).cfa = function (value) {
    return this.tf9(new Char(value));
  };
  protoOf(AbstractEncoder).dfa = function (value) {
    return this.tf9(value);
  };
  protoOf(AbstractEncoder).efa = function (enumDescriptor, index) {
    return this.tf9(index);
  };
  protoOf(AbstractEncoder).ffa = function (descriptor) {
    return this;
  };
  protoOf(AbstractEncoder).gfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.vf9(value);
    }
  };
  protoOf(AbstractEncoder).hfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.wf9(value);
    }
  };
  protoOf(AbstractEncoder).ifa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.xf9(value);
    }
  };
  protoOf(AbstractEncoder).jfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.yf9(value);
    }
  };
  protoOf(AbstractEncoder).kfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.zf9(value);
    }
  };
  protoOf(AbstractEncoder).lfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.afa(value);
    }
  };
  protoOf(AbstractEncoder).mfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.bfa(value);
    }
  };
  protoOf(AbstractEncoder).nfa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.cfa(value);
    }
  };
  protoOf(AbstractEncoder).ofa = function (descriptor, index, value) {
    if (this.sf9(descriptor, index)) {
      this.dfa(value);
    }
  };
  protoOf(AbstractEncoder).pfa = function (descriptor, index) {
    return this.sf9(descriptor, index) ? this.ffa(descriptor.if7(index)) : NoOpEncoder_getInstance();
  };
  protoOf(AbstractEncoder).qfa = function (descriptor, index, serializer, value) {
    if (this.sf9(descriptor, index)) {
      this.rfa(serializer, value);
    }
  };
  protoOf(AbstractEncoder).sfa = function (descriptor, index, serializer, value) {
    if (this.sf9(descriptor, index)) {
      this.tfa(serializer, value);
    }
  };
  function Decoder() {
  }
  function Companion() {
    Companion_instance = this;
    this.xfa_1 = -1;
    this.yfa_1 = -3;
  }
  var Companion_instance;
  function Companion_getInstance_7() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function CompositeDecoder() {
  }
  function Encoder() {
  }
  function CompositeEncoder() {
  }
  function decodeSequentially_0($this, compositeDecoder) {
    var klassName = compositeDecoder.jf9($this.qf5(), 0);
    var serializer = findPolymorphicSerializer_0($this, compositeDecoder, klassName);
    return compositeDecoder.mf9($this.qf5(), 1, serializer);
  }
  function AbstractPolymorphicSerializer() {
  }
  protoOf(AbstractPolymorphicSerializer).rf5 = function (encoder, value) {
    var actualSerializer = findPolymorphicSerializer(this, encoder, value);
    // Inline function 'kotlinx.serialization.encoding.encodeStructure' call
    var tmp0_encodeStructure = this.qf5();
    var composite = encoder.zf8(tmp0_encodeStructure);
    // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.serialize.<anonymous>' call
    composite.ofa(this.qf5(), 0, actualSerializer.qf5().qf6());
    var tmp = this.qf5();
    var tmp$ret$0;
    // Inline function 'kotlinx.serialization.internal.cast' call
    tmp$ret$0 = isInterface(actualSerializer, SerializationStrategy) ? actualSerializer : THROW_CCE();
    composite.qfa(tmp, 1, tmp$ret$0, value);
    composite.af9(tmp0_encodeStructure);
  };
  protoOf(AbstractPolymorphicSerializer).sf5 = function (decoder) {
    var tmp$ret$5;
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var tmp0_decodeStructure = this.qf5();
    var composite = decoder.zf8(tmp0_decodeStructure);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>' call
      var klassName = null;
      var value = null;
      if (composite.pf9()) {
        tmp$ret$0 = decodeSequentially_0(this, composite);
        break $l$block;
      }
      mainLoop: while (true) {
        var index = composite.qf9(this.qf5());
        Companion_getInstance_7();
        if (index === -1) {
          break mainLoop;
        } else {
          if (index === 0) {
            klassName = composite.jf9(this.qf5(), index);
          } else {
            if (index === 1) {
              var tmp$ret$2;
              $l$block_0: {
                // Inline function 'kotlin.requireNotNull' call
                var tmp0_requireNotNull = klassName;
                // Inline function 'kotlin.contracts.contract' call
                if (tmp0_requireNotNull == null) {
                  var tmp$ret$1;
                  // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
                  tmp$ret$1 = 'Cannot read polymorphic value before its type token';
                  var message = tmp$ret$1;
                  throw IllegalArgumentException_init_$Create$(toString(message));
                } else {
                  tmp$ret$2 = tmp0_requireNotNull;
                  break $l$block_0;
                }
              }
              klassName = tmp$ret$2;
              var serializer = findPolymorphicSerializer_0(this, composite, klassName);
              value = composite.mf9(this.qf5(), index, serializer);
            } else {
              var tmp0_elvis_lhs = klassName;
              throw SerializationException_init_$Create$('Invalid index in polymorphic deserialization of ' + (tmp0_elvis_lhs == null ? 'unknown class' : tmp0_elvis_lhs) + ('\n Expected 0, 1 or DECODE_DONE(-1), but found ' + index));
            }
          }
        }
      }
      var tmp$ret$4;
      $l$block_1: {
        // Inline function 'kotlin.requireNotNull' call
        var tmp1_requireNotNull = value;
        // Inline function 'kotlin.contracts.contract' call
        if (tmp1_requireNotNull == null) {
          var tmp$ret$3;
          // Inline function 'kotlinx.serialization.internal.AbstractPolymorphicSerializer.deserialize.<anonymous>.<anonymous>' call
          tmp$ret$3 = 'Polymorphic value has not been read for class ' + klassName;
          var message_0 = tmp$ret$3;
          throw IllegalArgumentException_init_$Create$(toString(message_0));
        } else {
          tmp$ret$4 = tmp1_requireNotNull;
          break $l$block_1;
        }
      }
      var tmp = tmp$ret$4;
      tmp$ret$0 = isObject(tmp) ? tmp : THROW_CCE();
    }
    var result = tmp$ret$0;
    composite.af9(tmp0_decodeStructure);
    tmp$ret$5 = result;
    return tmp$ret$5;
  };
  protoOf(AbstractPolymorphicSerializer).gf6 = function (decoder, klassName) {
    return decoder.of9().zfa(this.ff6(), klassName);
  };
  protoOf(AbstractPolymorphicSerializer).hf6 = function (encoder, value) {
    return encoder.of9().afb(this.ff6(), value);
  };
  function throwSubtypeNotRegistered(subClass, baseClass) {
    var tmp0_elvis_lhs = subClass.zi();
    throwSubtypeNotRegistered_0(tmp0_elvis_lhs == null ? '' + subClass : tmp0_elvis_lhs, baseClass);
  }
  function throwSubtypeNotRegistered_0(subClassName, baseClass) {
    var scope = "in the scope of '" + baseClass.zi() + "'";
    throw SerializationException_init_$Create$(subClassName == null ? 'Class discriminator was missing and no default polymorphic serializers were registered ' + scope : "Class '" + subClassName + "' is not registered for polymorphic serialization " + scope + '.\n' + ("To be registered automatically, class '" + subClassName + "' has to be '@Serializable', and the base class '" + baseClass.zi() + "' has to be sealed and '@Serializable'.\n") + ("Alternatively, register the serializer for '" + subClassName + "' explicitly in a corresponding SerializersModule."));
  }
  function NothingSerializer_0() {
    NothingSerializer_instance = this;
    this.bfb_1 = NothingSerialDescriptor_getInstance();
  }
  protoOf(NothingSerializer_0).qf5 = function () {
    return this.bfb_1;
  };
  protoOf(NothingSerializer_0).cfb = function (encoder, value) {
    throw SerializationException_init_$Create$("'kotlin.Nothing' cannot be serialized");
  };
  protoOf(NothingSerializer_0).rf5 = function (encoder, value) {
    var tmp;
    if (false) {
      tmp = value;
    } else {
      tmp = THROW_CCE();
    }
    return this.cfb(encoder, tmp);
  };
  protoOf(NothingSerializer_0).sf5 = function (decoder) {
    throw SerializationException_init_$Create$("'kotlin.Nothing' does not have instances");
  };
  var NothingSerializer_instance;
  function NothingSerializer_getInstance() {
    if (NothingSerializer_instance == null)
      new NothingSerializer_0();
    return NothingSerializer_instance;
  }
  function DurationSerializer() {
    DurationSerializer_instance = this;
    this.dfb_1 = new PrimitiveSerialDescriptor_0('kotlin.time.Duration', STRING_getInstance());
  }
  protoOf(DurationSerializer).qf5 = function () {
    return this.dfb_1;
  };
  protoOf(DurationSerializer).efb = function (encoder, value) {
    encoder.dfa(Duration__toIsoString_impl_9h6wsm(value));
  };
  protoOf(DurationSerializer).rf5 = function (encoder, value) {
    return this.efb(encoder, value instanceof Duration ? value.zb_1 : THROW_CCE());
  };
  protoOf(DurationSerializer).ffb = function (decoder) {
    return Companion_getInstance().yb(decoder.uf8());
  };
  protoOf(DurationSerializer).sf5 = function (decoder) {
    return new Duration(this.ffb(decoder));
  };
  var DurationSerializer_instance;
  function DurationSerializer_getInstance() {
    if (DurationSerializer_instance == null)
      new DurationSerializer();
    return DurationSerializer_instance;
  }
  function CachedNames() {
  }
  function ArrayListClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(ArrayListClassDesc).qf6 = function () {
    return 'kotlin.collections.ArrayList';
  };
  function HashSetClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(HashSetClassDesc).qf6 = function () {
    return 'kotlin.collections.HashSet';
  };
  function LinkedHashSetClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(LinkedHashSetClassDesc).qf6 = function () {
    return 'kotlin.collections.LinkedHashSet';
  };
  function HashMapClassDesc(keyDesc, valueDesc) {
    MapLikeDescriptor.call(this, 'kotlin.collections.HashMap', keyDesc, valueDesc);
  }
  function LinkedHashMapClassDesc(keyDesc, valueDesc) {
    MapLikeDescriptor.call(this, 'kotlin.collections.LinkedHashMap', keyDesc, valueDesc);
  }
  function ArrayClassDesc(elementDesc) {
    ListLikeDescriptor.call(this, elementDesc);
  }
  protoOf(ArrayClassDesc).qf6 = function () {
    return 'kotlin.Array';
  };
  function PrimitiveArrayDescriptor(primitive) {
    ListLikeDescriptor.call(this, primitive);
    this.wfb_1 = primitive.qf6() + 'Array';
  }
  protoOf(PrimitiveArrayDescriptor).qf6 = function () {
    return this.wfb_1;
  };
  function ListLikeDescriptor(elementDescriptor) {
    this.ifb_1 = elementDescriptor;
    this.jfb_1 = 1;
  }
  protoOf(ListLikeDescriptor).gf7 = function () {
    return LIST_getInstance();
  };
  protoOf(ListLikeDescriptor).ef7 = function () {
    return this.jfb_1;
  };
  protoOf(ListLikeDescriptor).kf7 = function (index) {
    return index.toString();
  };
  protoOf(ListLikeDescriptor).jf7 = function (name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException_init_$Create$(name + ' is not a valid list index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(ListLikeDescriptor).lf7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.isElementOptional.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return false;
  };
  protoOf(ListLikeDescriptor).hf7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementAnnotations.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return emptyList();
  };
  protoOf(ListLikeDescriptor).if7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.ListLikeDescriptor.getElementDescriptor.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return this.ifb_1;
  };
  protoOf(ListLikeDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ListLikeDescriptor))
      return false;
    if (equals(this.ifb_1, other.ifb_1) ? this.qf6() === other.qf6() : false)
      return true;
    return false;
  };
  protoOf(ListLikeDescriptor).hashCode = function () {
    return imul(hashCode(this.ifb_1), 31) + getStringHashCode(this.qf6()) | 0;
  };
  protoOf(ListLikeDescriptor).toString = function () {
    return this.qf6() + '(' + this.ifb_1 + ')';
  };
  function MapLikeDescriptor(serialName, keyDescriptor, valueDescriptor) {
    this.ofb_1 = serialName;
    this.pfb_1 = keyDescriptor;
    this.qfb_1 = valueDescriptor;
    this.rfb_1 = 2;
  }
  protoOf(MapLikeDescriptor).qf6 = function () {
    return this.ofb_1;
  };
  protoOf(MapLikeDescriptor).gf7 = function () {
    return MAP_getInstance();
  };
  protoOf(MapLikeDescriptor).ef7 = function () {
    return this.rfb_1;
  };
  protoOf(MapLikeDescriptor).kf7 = function (index) {
    return index.toString();
  };
  protoOf(MapLikeDescriptor).jf7 = function (name) {
    var tmp0_elvis_lhs = toIntOrNull(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw IllegalArgumentException_init_$Create$(name + ' is not a valid map index');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(MapLikeDescriptor).lf7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.isElementOptional.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return false;
  };
  protoOf(MapLikeDescriptor).hf7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementAnnotations.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return emptyList();
  };
  protoOf(MapLikeDescriptor).if7 = function (index) {
    // Inline function 'kotlin.require' call
    var tmp0_require = index >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.MapLikeDescriptor.getElementDescriptor.<anonymous>' call
      tmp$ret$0 = 'Illegal index ' + index + ', ' + this.qf6() + ' expects only non-negative indices';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp0_subject = index % 2 | 0;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = this.pfb_1;
        break;
      case 1:
        tmp = this.qfb_1;
        break;
      default:
        throw IllegalStateException_init_$Create$('Unreached');
    }
    return tmp;
  };
  protoOf(MapLikeDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapLikeDescriptor))
      return false;
    if (!(this.qf6() === other.qf6()))
      return false;
    if (!equals(this.pfb_1, other.pfb_1))
      return false;
    if (!equals(this.qfb_1, other.qfb_1))
      return false;
    return true;
  };
  protoOf(MapLikeDescriptor).hashCode = function () {
    var result = getStringHashCode(this.qf6());
    result = imul(31, result) + hashCode(this.pfb_1) | 0;
    result = imul(31, result) + hashCode(this.qfb_1) | 0;
    return result;
  };
  protoOf(MapLikeDescriptor).toString = function () {
    return this.qf6() + '(' + this.pfb_1 + ', ' + this.qfb_1 + ')';
  };
  function ArrayListSerializer(element) {
    CollectionSerializer.call(this, element);
    this.yfb_1 = new ArrayListClassDesc(element.qf5());
  }
  protoOf(ArrayListSerializer).qf5 = function () {
    return this.yfb_1;
  };
  protoOf(ArrayListSerializer).zfb = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    return tmp$ret$0;
  };
  protoOf(ArrayListSerializer).afc = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(ArrayListSerializer).bfc = function (_this__u8e3s4) {
    return this.afc(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).cfc = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(ArrayListSerializer).dfc = function (_this__u8e3s4) {
    return this.cfc(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).efc = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? ArrayList_init_$Create$_1(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(ArrayListSerializer).ffc = function (_this__u8e3s4) {
    return this.efc((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, List) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ArrayListSerializer).gfc = function (_this__u8e3s4, size) {
    return _this__u8e3s4.ug(size);
  };
  protoOf(ArrayListSerializer).hfc = function (_this__u8e3s4, size) {
    return this.gfc(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(ArrayListSerializer).ifc = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.z3(index, element);
  };
  protoOf(ArrayListSerializer).jfc = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.ifc(tmp, index, (element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  function HashSetSerializer(eSerializer) {
    CollectionSerializer.call(this, eSerializer);
    this.ufc_1 = new HashSetClassDesc(eSerializer.qf5());
  }
  protoOf(HashSetSerializer).qf5 = function () {
    return this.ufc_1;
  };
  protoOf(HashSetSerializer).zfb = function () {
    return HashSet_init_$Create$();
  };
  protoOf(HashSetSerializer).vfc = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(HashSetSerializer).bfc = function (_this__u8e3s4) {
    return this.vfc(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).wfc = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(HashSetSerializer).dfc = function (_this__u8e3s4) {
    return this.wfc(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).xfc = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashSet_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(HashSetSerializer).ffc = function (_this__u8e3s4) {
    return this.xfc((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Set) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashSetSerializer).yfc = function (_this__u8e3s4, size) {
  };
  protoOf(HashSetSerializer).hfc = function (_this__u8e3s4, size) {
    return this.yfc(_this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(HashSetSerializer).zfc = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.b(element);
  };
  protoOf(HashSetSerializer).jfc = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof HashSet ? _this__u8e3s4 : THROW_CCE();
    return this.zfc(tmp, index, (element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  function LinkedHashSetSerializer(eSerializer) {
    CollectionSerializer.call(this, eSerializer);
    this.bfd_1 = new LinkedHashSetClassDesc(eSerializer.qf5());
  }
  protoOf(LinkedHashSetSerializer).qf5 = function () {
    return this.bfd_1;
  };
  protoOf(LinkedHashSetSerializer).zfb = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.linkedSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    return tmp$ret$0;
  };
  protoOf(LinkedHashSetSerializer).cfd = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(LinkedHashSetSerializer).bfc = function (_this__u8e3s4) {
    return this.cfd(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).dfd = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(LinkedHashSetSerializer).dfc = function (_this__u8e3s4) {
    return this.dfd(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).xfc = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashSet_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(LinkedHashSetSerializer).ffc = function (_this__u8e3s4) {
    return this.xfc((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Set) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashSetSerializer).efd = function (_this__u8e3s4, size) {
  };
  protoOf(LinkedHashSetSerializer).hfc = function (_this__u8e3s4, size) {
    return this.efd(_this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(LinkedHashSetSerializer).ffd = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.b(element);
  };
  protoOf(LinkedHashSetSerializer).jfc = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof LinkedHashSet ? _this__u8e3s4 : THROW_CCE();
    return this.ffd(tmp, index, (element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  function HashMapSerializer(kSerializer, vSerializer) {
    MapLikeSerializer.call(this, kSerializer, vSerializer);
    this.ifd_1 = new HashMapClassDesc(kSerializer.qf5(), vSerializer.qf5());
  }
  protoOf(HashMapSerializer).qf5 = function () {
    return this.ifd_1;
  };
  protoOf(HashMapSerializer).jfd = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(HashMapSerializer).kfd = function (_this__u8e3s4) {
    return this.jfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).lfd = function (_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = _this__u8e3s4.d1().h();
    return tmp$ret$0;
  };
  protoOf(HashMapSerializer).mfd = function (_this__u8e3s4) {
    return this.lfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).zfb = function () {
    return HashMap_init_$Create$();
  };
  protoOf(HashMapSerializer).nfd = function (_this__u8e3s4) {
    return imul(_this__u8e3s4.l(), 2);
  };
  protoOf(HashMapSerializer).bfc = function (_this__u8e3s4) {
    return this.nfd(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).ofd = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(HashMapSerializer).dfc = function (_this__u8e3s4) {
    return this.ofd(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).pfd = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? HashMap_init_$Create$_0(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(HashMapSerializer).ffc = function (_this__u8e3s4) {
    return this.pfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(HashMapSerializer).qfd = function (_this__u8e3s4, size) {
  };
  protoOf(HashMapSerializer).hfc = function (_this__u8e3s4, size) {
    return this.qfd(_this__u8e3s4 instanceof HashMap ? _this__u8e3s4 : THROW_CCE(), size);
  };
  function LinkedHashMapSerializer(kSerializer, vSerializer) {
    MapLikeSerializer.call(this, kSerializer, vSerializer);
    this.vfd_1 = new LinkedHashMapClassDesc(kSerializer.qf5(), vSerializer.qf5());
  }
  protoOf(LinkedHashMapSerializer).qf5 = function () {
    return this.vfd_1;
  };
  protoOf(LinkedHashMapSerializer).jfd = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(LinkedHashMapSerializer).kfd = function (_this__u8e3s4) {
    return this.jfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).lfd = function (_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = _this__u8e3s4.d1().h();
    return tmp$ret$0;
  };
  protoOf(LinkedHashMapSerializer).mfd = function (_this__u8e3s4) {
    return this.lfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).zfb = function () {
    return LinkedHashMap_init_$Create$();
  };
  protoOf(LinkedHashMapSerializer).wfd = function (_this__u8e3s4) {
    return imul(_this__u8e3s4.l(), 2);
  };
  protoOf(LinkedHashMapSerializer).bfc = function (_this__u8e3s4) {
    return this.wfd(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).xfd = function (_this__u8e3s4) {
    return _this__u8e3s4;
  };
  protoOf(LinkedHashMapSerializer).dfc = function (_this__u8e3s4) {
    return this.xfd(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).pfd = function (_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : null;
    return tmp0_elvis_lhs == null ? LinkedHashMap_init_$Create$_1(_this__u8e3s4) : tmp0_elvis_lhs;
  };
  protoOf(LinkedHashMapSerializer).ffc = function (_this__u8e3s4) {
    return this.pfd((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Map) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LinkedHashMapSerializer).yfd = function (_this__u8e3s4, size) {
  };
  protoOf(LinkedHashMapSerializer).hfc = function (_this__u8e3s4, size) {
    return this.yfd(_this__u8e3s4 instanceof LinkedHashMap ? _this__u8e3s4 : THROW_CCE(), size);
  };
  function ReferenceArraySerializer(kClass, eSerializer) {
    CollectionLikeSerializer.call(this, eSerializer);
    this.afe_1 = kClass;
    this.bfe_1 = new ArrayClassDesc(eSerializer.qf5());
  }
  protoOf(ReferenceArraySerializer).qf5 = function () {
    return this.bfe_1;
  };
  protoOf(ReferenceArraySerializer).cfe = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ReferenceArraySerializer).kfd = function (_this__u8e3s4) {
    return this.cfe((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).dfe = function (_this__u8e3s4) {
    return arrayIterator(_this__u8e3s4);
  };
  protoOf(ReferenceArraySerializer).mfd = function (_this__u8e3s4) {
    return this.dfe((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).zfb = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    return tmp$ret$0;
  };
  protoOf(ReferenceArraySerializer).efe = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(ReferenceArraySerializer).bfc = function (_this__u8e3s4) {
    return this.efe(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).ffe = function (_this__u8e3s4) {
    return toNativeArrayImpl(_this__u8e3s4, this.afe_1);
  };
  protoOf(ReferenceArraySerializer).dfc = function (_this__u8e3s4) {
    return this.ffe(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).gfe = function (_this__u8e3s4) {
    return ArrayList_init_$Create$_1(asList(_this__u8e3s4));
  };
  protoOf(ReferenceArraySerializer).ffc = function (_this__u8e3s4) {
    return this.gfe((!(_this__u8e3s4 == null) ? isArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ReferenceArraySerializer).hfe = function (_this__u8e3s4, size) {
    return _this__u8e3s4.ug(size);
  };
  protoOf(ReferenceArraySerializer).hfc = function (_this__u8e3s4, size) {
    return this.hfe(_this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE(), size);
  };
  protoOf(ReferenceArraySerializer).ife = function (_this__u8e3s4, index, element) {
    _this__u8e3s4.z3(index, element);
  };
  protoOf(ReferenceArraySerializer).jfc = function (_this__u8e3s4, index, element) {
    var tmp = _this__u8e3s4 instanceof ArrayList ? _this__u8e3s4 : THROW_CCE();
    return this.ife(tmp, index, (element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  function CollectionSerializer(element) {
    CollectionLikeSerializer.call(this, element);
  }
  protoOf(CollectionSerializer).lfc = function (_this__u8e3s4) {
    return _this__u8e3s4.l();
  };
  protoOf(CollectionSerializer).kfd = function (_this__u8e3s4) {
    return this.lfc((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Collection) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CollectionSerializer).mfc = function (_this__u8e3s4) {
    return _this__u8e3s4.h();
  };
  protoOf(CollectionSerializer).mfd = function (_this__u8e3s4) {
    return this.mfc((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Collection) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  function MapLikeSerializer(keySerializer, valueSerializer) {
    AbstractCollectionSerializer.call(this);
    this.rfd_1 = keySerializer;
    this.sfd_1 = valueSerializer;
  }
  protoOf(MapLikeSerializer).pfc = function (decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    var tmp0_require = size >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readAll.<anonymous>' call
      tmp$ret$0 = 'Size must be known in advance when using READ_ALL';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var progression = step(until(0, imul(size, 2)), 2);
    var inductionVariable = progression.p1_1;
    var last = progression.q1_1;
    var step_0 = progression.r1_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        this.qfc(decoder, startIndex + index | 0, builder, false);
      }
       while (!(index === last));
  };
  protoOf(MapLikeSerializer).qfc = function (decoder, index, builder, checkIndex) {
    var key = decoder.mf9(this.qf5(), index, this.rfd_1);
    var tmp;
    if (checkIndex) {
      var tmp$ret$1;
      // Inline function 'kotlin.also' call
      var tmp0_also = decoder.qf9(this.qf5());
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>' call
      // Inline function 'kotlin.require' call
      var tmp0_require = tmp0_also === (index + 1 | 0);
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_require) {
        var tmp$ret$0;
        // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.readElement.<anonymous>.<anonymous>' call
        tmp$ret$0 = 'Value must follow key in a map, index for key: ' + index + ', returned index for value: ' + tmp0_also;
        var message = tmp$ret$0;
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
      tmp$ret$1 = tmp0_also;
      tmp = tmp$ret$1;
    } else {
      tmp = index + 1 | 0;
    }
    var vIndex = tmp;
    var tmp_0;
    var tmp_1;
    if (builder.b3(key)) {
      var tmp_2 = this.sfd_1.qf5().gf7();
      tmp_1 = !(tmp_2 instanceof PrimitiveKind);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = decoder.lf9(this.qf5(), vIndex, this.sfd_1, getValue(builder, key));
    } else {
      tmp_0 = decoder.mf9(this.qf5(), vIndex, this.sfd_1);
    }
    var value = tmp_0;
    // Inline function 'kotlin.collections.set' call
    builder.e5(key, value);
  };
  protoOf(MapLikeSerializer).ofc = function (encoder, value) {
    var size = this.kfd(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var tmp0_encodeCollection = this.qf5();
    var composite = encoder.vfa(tmp0_encodeCollection, size);
    // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.serialize.<anonymous>' call
    var iterator = this.mfd(value);
    var index = 0;
    // Inline function 'kotlin.collections.forEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = iterator;
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'kotlinx.serialization.internal.MapLikeSerializer.serialize.<anonymous>.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$1 = element.e1();
      var k = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$2 = element.f1();
      var v = tmp$ret$2;
      var tmp = this.qf5();
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      composite.qfa(tmp, tmp0, this.rfd_1, k);
      var tmp_0 = this.qf5();
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      composite.qfa(tmp_0, tmp1, this.sfd_1, v);
    }
    composite.af9(tmp0_encodeCollection);
  };
  protoOf(MapLikeSerializer).rf5 = function (encoder, value) {
    return this.ofc(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  function CollectionLikeSerializer(elementSerializer) {
    AbstractCollectionSerializer.call(this);
    this.nfc_1 = elementSerializer;
  }
  protoOf(CollectionLikeSerializer).ofc = function (encoder, value) {
    var size = this.kfd(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var tmp0_encodeCollection = this.qf5();
    var composite = encoder.vfa(tmp0_encodeCollection, size);
    // Inline function 'kotlinx.serialization.internal.CollectionLikeSerializer.serialize.<anonymous>' call
    var iterator = this.mfd(value);
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        composite.qfa(this.qf5(), index, this.nfc_1, iterator.k());
      }
       while (inductionVariable < size);
    composite.af9(tmp0_encodeCollection);
  };
  protoOf(CollectionLikeSerializer).rf5 = function (encoder, value) {
    return this.ofc(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(CollectionLikeSerializer).pfc = function (decoder, builder, startIndex, size) {
    // Inline function 'kotlin.require' call
    var tmp0_require = size >= 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.CollectionLikeSerializer.readAll.<anonymous>' call
      tmp$ret$0 = 'Size must be known in advance when using READ_ALL';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.qfc(decoder, startIndex + index | 0, builder, false);
      }
       while (inductionVariable < size);
  };
  protoOf(CollectionLikeSerializer).qfc = function (decoder, index, builder, checkIndex) {
    this.jfc(builder, index, decoder.mf9(this.qf5(), index, this.nfc_1));
  };
  function readSize($this, decoder, builder) {
    var size = decoder.rf9($this.qf5());
    $this.hfc(builder, size);
    return size;
  }
  function AbstractCollectionSerializer() {
  }
  protoOf(AbstractCollectionSerializer).sfc = function (decoder, previous) {
    var tmp0_safe_receiver = previous;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : this.ffc(tmp0_safe_receiver);
    var builder = tmp1_elvis_lhs == null ? this.zfb() : tmp1_elvis_lhs;
    var startIndex = this.bfc(builder);
    var compositeDecoder = decoder.zf8(this.qf5());
    if (compositeDecoder.pf9()) {
      this.pfc(compositeDecoder, builder, startIndex, readSize(this, compositeDecoder, builder));
    } else {
      $l$loop: while (true) {
        var index = compositeDecoder.qf9(this.qf5());
        Companion_getInstance_7();
        if (index === -1)
          break $l$loop;
        this.rfc(compositeDecoder, startIndex + index | 0, builder);
      }
    }
    compositeDecoder.af9(this.qf5());
    return this.dfc(builder);
  };
  protoOf(AbstractCollectionSerializer).sf5 = function (decoder) {
    return this.sfc(decoder, null);
  };
  protoOf(AbstractCollectionSerializer).rfc = function (decoder, index, builder, checkIndex, $super) {
    checkIndex = checkIndex === VOID ? true : checkIndex;
    var tmp;
    if ($super === VOID) {
      this.qfc(decoder, index, builder, checkIndex);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.qfc.call(this, decoder, index, builder, checkIndex);
    }
    return tmp;
  };
  function PrimitiveArraySerializer(primitiveSerializer) {
    CollectionLikeSerializer.call(this, primitiveSerializer);
    this.kfe_1 = new PrimitiveArrayDescriptor(primitiveSerializer.qf5());
  }
  protoOf(PrimitiveArraySerializer).qf5 = function () {
    return this.kfe_1;
  };
  protoOf(PrimitiveArraySerializer).bfc = function (_this__u8e3s4) {
    return _this__u8e3s4.g3h();
  };
  protoOf(PrimitiveArraySerializer).dfc = function (_this__u8e3s4) {
    return _this__u8e3s4.tg();
  };
  protoOf(PrimitiveArraySerializer).hfc = function (_this__u8e3s4, size) {
    return _this__u8e3s4.ug(size);
  };
  protoOf(PrimitiveArraySerializer).lfe = function (_this__u8e3s4) {
    throw IllegalStateException_init_$Create$('This method lead to boxing and must not be used, use writeContents instead');
  };
  protoOf(PrimitiveArraySerializer).mfd = function (_this__u8e3s4) {
    return this.lfe((_this__u8e3s4 == null ? true : isObject(_this__u8e3s4)) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).jfc = function (_this__u8e3s4, index, element) {
    throw IllegalStateException_init_$Create$('This method lead to boxing and must not be used, use Builder.append instead');
  };
  protoOf(PrimitiveArraySerializer).zfb = function () {
    return this.ffc(this.mfe());
  };
  protoOf(PrimitiveArraySerializer).ofe = function (encoder, value) {
    var size = this.kfd(value);
    // Inline function 'kotlinx.serialization.encoding.encodeCollection' call
    var tmp0_encodeCollection = this.kfe_1;
    var composite = encoder.vfa(tmp0_encodeCollection, size);
    // Inline function 'kotlinx.serialization.internal.PrimitiveArraySerializer.serialize.<anonymous>' call
    this.nfe(composite, value, size);
    composite.af9(tmp0_encodeCollection);
  };
  protoOf(PrimitiveArraySerializer).rf5 = function (encoder, value) {
    return this.ofe(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).ofc = function (encoder, value) {
    return this.ofe(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(PrimitiveArraySerializer).sf5 = function (decoder) {
    return this.sfc(decoder, null);
  };
  function PrimitiveArrayBuilder() {
  }
  protoOf(PrimitiveArrayBuilder).pfe = function (requiredCapacity, $super) {
    requiredCapacity = requiredCapacity === VOID ? this.g3h() + 1 | 0 : requiredCapacity;
    var tmp;
    if ($super === VOID) {
      this.ug(requiredCapacity);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.ug.call(this, requiredCapacity);
    }
    return tmp;
  };
  function Companion_0() {
    Companion_instance_0 = this;
    this.qfe_1 = longArray(0);
  }
  var Companion_instance_0;
  function Companion_getInstance_8() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function prepareHighMarksArray($this, elementsCount) {
    var slotsCount = (elementsCount - 1 | 0) >>> 6 | 0;
    Companion_getInstance_0();
    var elementsInLastSlot = elementsCount & (64 - 1 | 0);
    var highMarks = longArray(slotsCount);
    if (!(elementsInLastSlot === 0)) {
      highMarks[get_lastIndex(highMarks)] = (new Long(-1, -1)).gc(elementsCount);
    }
    return highMarks;
  }
  function markHigh($this, index) {
    var slot = (index >>> 6 | 0) - 1 | 0;
    Companion_getInstance_0();
    var offsetInSlot = index & (64 - 1 | 0);
    $this.ufe_1[slot] = $this.ufe_1[slot].an((new Long(1, 0)).gc(offsetInSlot));
  }
  function nextUnmarkedHighIndex($this) {
    var inductionVariable = 0;
    var last = $this.ufe_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var slot = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = slot + 1 | 0;
        Companion_getInstance_0();
        var slotOffset = imul(tmp, 64);
        var slotMarks = $this.ufe_1[slot];
        while (!slotMarks.equals(new Long(-1, -1))) {
          var indexInSlot = countTrailingZeroBits(slotMarks.zm());
          slotMarks = slotMarks.an((new Long(1, 0)).gc(indexInSlot));
          var index = slotOffset + indexInSlot | 0;
          if ($this.sfe_1($this.rfe_1, index)) {
            $this.ufe_1[slot] = slotMarks;
            return index;
          }
        }
        $this.ufe_1[slot] = slotMarks;
      }
       while (inductionVariable <= last);
    Companion_getInstance_7();
    return -1;
  }
  function ElementMarker(descriptor, readIfAbsent) {
    Companion_getInstance_8();
    this.rfe_1 = descriptor;
    this.sfe_1 = readIfAbsent;
    var elementsCount = this.rfe_1.ef7();
    Companion_getInstance_0();
    if (elementsCount <= 64) {
      var tmp = this;
      var tmp_0;
      Companion_getInstance_0();
      if (elementsCount === 64) {
        tmp_0 = new Long(0, 0);
      } else {
        tmp_0 = (new Long(-1, -1)).gc(elementsCount);
      }
      tmp.tfe_1 = tmp_0;
      this.ufe_1 = Companion_getInstance_8().qfe_1;
    } else {
      this.tfe_1 = new Long(0, 0);
      this.ufe_1 = prepareHighMarksArray(this, elementsCount);
    }
  }
  protoOf(ElementMarker).vfe = function (index) {
    Companion_getInstance_0();
    if (index < 64) {
      this.tfe_1 = this.tfe_1.an((new Long(1, 0)).gc(index));
    } else {
      markHigh(this, index);
    }
  };
  protoOf(ElementMarker).wfe = function () {
    var elementsCount = this.rfe_1.ef7();
    while (!this.tfe_1.equals(new Long(-1, -1))) {
      var index = countTrailingZeroBits(this.tfe_1.zm());
      this.tfe_1 = this.tfe_1.an((new Long(1, 0)).gc(index));
      if (this.sfe_1(this.rfe_1, index)) {
        return index;
      }
    }
    Companion_getInstance_0();
    if (elementsCount > 64) {
      return nextUnmarkedHighIndex(this);
    }
    Companion_getInstance_7();
    return -1;
  };
  function createUnmarkedDescriptor($this, serialName) {
    var d = new EnumDescriptor(serialName, $this.xfe_1.length);
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = $this.xfe_1;
    var indexedObject = tmp0_forEach;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlinx.serialization.internal.EnumSerializer.createUnmarkedDescriptor.<anonymous>' call
      d.mff(element.a2_1);
    }
    return d;
  }
  function EnumSerializer$descriptor$delegate$lambda(this$0, $serialName) {
    return function () {
      var tmp0_elvis_lhs = this$0.yfe_1;
      return tmp0_elvis_lhs == null ? createUnmarkedDescriptor(this$0, $serialName) : tmp0_elvis_lhs;
    };
  }
  function EnumSerializer(serialName, values) {
    this.xfe_1 = values;
    this.yfe_1 = null;
    var tmp = this;
    tmp.zfe_1 = lazy_0(EnumSerializer$descriptor$delegate$lambda(this, serialName));
  }
  protoOf(EnumSerializer).qf5 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = descriptor$factory_1();
    tmp$ret$0 = this.zfe_1.f1();
    return tmp$ret$0;
  };
  protoOf(EnumSerializer).rf5 = function (encoder, value) {
    var index = indexOf(this.xfe_1, value);
    if (index === -1) {
      throw SerializationException_init_$Create$('' + value + ' is not a valid enum ' + this.qf5().qf6() + ', ' + ('must be one of ' + contentToString(this.xfe_1)));
    }
    encoder.efa(this.qf5(), index);
  };
  protoOf(EnumSerializer).sf5 = function (decoder) {
    var index = decoder.vf8(this.qf5());
    if (!(0 <= index ? index <= (this.xfe_1.length - 1 | 0) : false)) {
      throw SerializationException_init_$Create$('' + index + ' is not among valid ' + this.qf5().qf6() + ' enum values, ' + ('values size is ' + this.xfe_1.length));
    }
    return this.xfe_1[index];
  };
  protoOf(EnumSerializer).toString = function () {
    return 'kotlinx.serialization.internal.EnumSerializer<' + this.qf5().qf6() + '>';
  };
  function _get_elementDescriptors__y23q9p($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = elementDescriptors$factory();
    tmp$ret$0 = $this.afg_1.f1();
    return tmp$ret$0;
  }
  function EnumDescriptor$elementDescriptors$delegate$lambda($elementsCount, $name, this$0) {
    return function () {
      var tmp = 0;
      var tmp_0 = $elementsCount;
      var tmp$ret$0;
      // Inline function 'kotlin.arrayOfNulls' call
      tmp$ret$0 = fillArrayVal(Array(tmp_0), null);
      var tmp_1 = tmp$ret$0;
      while (tmp < tmp_0) {
        var tmp_2 = tmp;
        var tmp$ret$1;
        // Inline function 'kotlinx.serialization.internal.EnumDescriptor.elementDescriptors$delegate.<anonymous>.<anonymous>' call
        tmp$ret$1 = buildSerialDescriptor($name + '.' + this$0.kf7(tmp_2), OBJECT_getInstance(), []);
        tmp_1[tmp_2] = tmp$ret$1;
        tmp = tmp + 1 | 0;
      }
      return tmp_1;
    };
  }
  function EnumDescriptor(name, elementsCount) {
    PluginGeneratedSerialDescriptor.call(this, name, VOID, elementsCount);
    this.zff_1 = ENUM_getInstance();
    var tmp = this;
    tmp.afg_1 = lazy_0(EnumDescriptor$elementDescriptors$delegate$lambda(elementsCount, name, this));
  }
  protoOf(EnumDescriptor).gf7 = function () {
    return this.zff_1;
  };
  protoOf(EnumDescriptor).if7 = function (index) {
    return getChecked(_get_elementDescriptors__y23q9p(this), index);
  };
  protoOf(EnumDescriptor).equals = function (other) {
    if (this === other)
      return true;
    if (other == null)
      return false;
    if (!(!(other == null) ? isInterface(other, SerialDescriptor) : false))
      return false;
    if (!(other.gf7() === ENUM_getInstance()))
      return false;
    if (!(this.qf6() === other.qf6()))
      return false;
    if (!equals(cachedSerialNames(this), cachedSerialNames(other)))
      return false;
    return true;
  };
  protoOf(EnumDescriptor).toString = function () {
    return joinToString(get_elementNames(this), ', ', this.qf6() + '(', ')');
  };
  protoOf(EnumDescriptor).hashCode = function () {
    var result = getStringHashCode(this.qf6());
    var tmp$ret$4;
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    var tmp1_elementsHashCodeBy = get_elementNames(this);
    var tmp$ret$3;
    // Inline function 'kotlin.collections.fold' call
    var accumulator = 1;
    var tmp0_iterator = tmp1_elementsHashCodeBy.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var tmp0__anonymous__q1qw7t = accumulator;
      var tmp = imul(31, tmp0__anonymous__q1qw7t);
      var tmp$ret$1;
      // Inline function 'kotlin.hashCode' call
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.EnumDescriptor.hashCode.<anonymous>' call
      tmp$ret$0 = element;
      var tmp0_hashCode = tmp$ret$0;
      var tmp0_safe_receiver = tmp0_hashCode;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      tmp$ret$1 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
      tmp$ret$2 = tmp + tmp$ret$1 | 0;
      accumulator = tmp$ret$2;
    }
    tmp$ret$3 = accumulator;
    tmp$ret$4 = tmp$ret$3;
    var elementsHashCode = tmp$ret$4;
    result = imul(31, result) + elementsHashCode | 0;
    return result;
  };
  function descriptor$factory_1() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.qf5();
    }, null);
  }
  function elementDescriptors$factory() {
    return getPropertyCallableRef('elementDescriptors', 1, KProperty1, function (receiver) {
      return _get_elementDescriptors__y23q9p(receiver);
    }, null);
  }
  function InlinePrimitiveDescriptor(name, primitiveSerializer) {
    return new InlineClassDescriptor(name, new InlinePrimitiveDescriptor$1(primitiveSerializer));
  }
  function InlineClassDescriptor(name, generatedSerializer) {
    PluginGeneratedSerialDescriptor.call(this, name, generatedSerializer, 1);
    this.pfg_1 = true;
  }
  protoOf(InlineClassDescriptor).ff7 = function () {
    return this.pfg_1;
  };
  protoOf(InlineClassDescriptor).hashCode = function () {
    return imul(protoOf(PluginGeneratedSerialDescriptor).hashCode.call(this), 31);
  };
  protoOf(InlineClassDescriptor).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof InlineClassDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qf6() === other.qf6())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.internal.InlineClassDescriptor.equals.<anonymous>' call
      var tmp0__anonymous__q1qw7t = other;
      tmp$ret$1 = tmp0__anonymous__q1qw7t.pfg_1 ? contentEquals(this.bfg(), tmp0__anonymous__q1qw7t.bfg()) : false;
      if (!tmp$ret$1) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.ef7() === other.ef7())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.ef7();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.if7(index).qf6() === other.if7(index).qf6())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.if7(index).gf7(), other.if7(index).gf7())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  function InlinePrimitiveDescriptor$1($primitiveSerializer) {
    this.qfg_1 = $primitiveSerializer;
  }
  protoOf(InlinePrimitiveDescriptor$1).rfg = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = [this.qfg_1];
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(InlinePrimitiveDescriptor$1).qf5 = function () {
    throw IllegalStateException_init_$Create$('unsupported');
  };
  protoOf(InlinePrimitiveDescriptor$1).rf5 = function (encoder, value) {
    // Inline function 'kotlin.error' call
    throw IllegalStateException_init_$Create$('unsupported');
  };
  protoOf(InlinePrimitiveDescriptor$1).sf5 = function (decoder) {
    // Inline function 'kotlin.error' call
    throw IllegalStateException_init_$Create$('unsupported');
  };
  function jsonCachedSerialNames(_this__u8e3s4) {
    return cachedSerialNames(_this__u8e3s4);
  }
  function NoOpEncoder() {
    NoOpEncoder_instance = this;
    AbstractEncoder.call(this);
    this.tfg_1 = EmptySerializersModule_0();
  }
  protoOf(NoOpEncoder).of9 = function () {
    return this.tfg_1;
  };
  protoOf(NoOpEncoder).tf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).uf9 = function () {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).vf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).wf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).xf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).yf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).zf9 = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).afa = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).bfa = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).cfa = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).dfa = function (value) {
    return Unit_getInstance();
  };
  protoOf(NoOpEncoder).efa = function (enumDescriptor, index) {
    return Unit_getInstance();
  };
  var NoOpEncoder_instance;
  function NoOpEncoder_getInstance() {
    if (NoOpEncoder_instance == null)
      new NoOpEncoder();
    return NoOpEncoder_instance;
  }
  function error($this) {
    throw IllegalStateException_init_$Create$('Descriptor for type `kotlin.Nothing` does not have elements');
  }
  function NothingSerialDescriptor() {
    NothingSerialDescriptor_instance = this;
    this.ufg_1 = OBJECT_getInstance();
    this.vfg_1 = 'kotlin.Nothing';
  }
  protoOf(NothingSerialDescriptor).gf7 = function () {
    return this.ufg_1;
  };
  protoOf(NothingSerialDescriptor).qf6 = function () {
    return this.vfg_1;
  };
  protoOf(NothingSerialDescriptor).ef7 = function () {
    return 0;
  };
  protoOf(NothingSerialDescriptor).kf7 = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).jf7 = function (name) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).lf7 = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).if7 = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).hf7 = function (index) {
    error(this);
  };
  protoOf(NothingSerialDescriptor).toString = function () {
    return 'NothingSerialDescriptor';
  };
  protoOf(NothingSerialDescriptor).equals = function (other) {
    return this === other;
  };
  protoOf(NothingSerialDescriptor).hashCode = function () {
    return getStringHashCode(this.vfg_1) + imul(31, this.ufg_1.hashCode()) | 0;
  };
  var NothingSerialDescriptor_instance;
  function NothingSerialDescriptor_getInstance() {
    if (NothingSerialDescriptor_instance == null)
      new NothingSerialDescriptor();
    return NothingSerialDescriptor_instance;
  }
  function NullableSerializer(serializer) {
    this.wfg_1 = serializer;
    this.xfg_1 = new SerialDescriptorForNullable(this.wfg_1.qf5());
  }
  protoOf(NullableSerializer).qf5 = function () {
    return this.xfg_1;
  };
  protoOf(NullableSerializer).yfg = function (encoder, value) {
    if (!(value == null)) {
      encoder.ufa();
      encoder.rfa(this.wfg_1, value);
    } else {
      encoder.uf9();
    }
  };
  protoOf(NullableSerializer).rf5 = function (encoder, value) {
    return this.yfg(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(NullableSerializer).sf5 = function (decoder) {
    return decoder.kf8() ? decoder.yf8(this.wfg_1) : decoder.lf8();
  };
  protoOf(NullableSerializer).equals = function (other) {
    if (this === other)
      return true;
    if (other == null ? true : !getKClassFromExpression(this).equals(getKClassFromExpression(other)))
      return false;
    if (other instanceof NullableSerializer)
      other;
    else
      THROW_CCE();
    if (!equals(this.wfg_1, other.wfg_1))
      return false;
    return true;
  };
  protoOf(NullableSerializer).hashCode = function () {
    return hashCode(this.wfg_1);
  };
  function SerialDescriptorForNullable(original) {
    this.mf7_1 = original;
    this.nf7_1 = this.mf7_1.qf6() + '?';
    this.of7_1 = cachedSerialNames(this.mf7_1);
  }
  protoOf(SerialDescriptorForNullable).df7 = function () {
    return this.mf7_1.df7();
  };
  protoOf(SerialDescriptorForNullable).ef7 = function () {
    return this.mf7_1.ef7();
  };
  protoOf(SerialDescriptorForNullable).ff7 = function () {
    return this.mf7_1.ff7();
  };
  protoOf(SerialDescriptorForNullable).gf7 = function () {
    return this.mf7_1.gf7();
  };
  protoOf(SerialDescriptorForNullable).hf7 = function (index) {
    return this.mf7_1.hf7(index);
  };
  protoOf(SerialDescriptorForNullable).if7 = function (index) {
    return this.mf7_1.if7(index);
  };
  protoOf(SerialDescriptorForNullable).jf7 = function (name) {
    return this.mf7_1.jf7(name);
  };
  protoOf(SerialDescriptorForNullable).kf7 = function (index) {
    return this.mf7_1.kf7(index);
  };
  protoOf(SerialDescriptorForNullable).lf7 = function (index) {
    return this.mf7_1.lf7(index);
  };
  protoOf(SerialDescriptorForNullable).qf6 = function () {
    return this.nf7_1;
  };
  protoOf(SerialDescriptorForNullable).if8 = function () {
    return this.of7_1;
  };
  protoOf(SerialDescriptorForNullable).zf6 = function () {
    return true;
  };
  protoOf(SerialDescriptorForNullable).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SerialDescriptorForNullable))
      return false;
    if (!equals(this.mf7_1, other.mf7_1))
      return false;
    return true;
  };
  protoOf(SerialDescriptorForNullable).toString = function () {
    return '' + this.mf7_1 + '?';
  };
  protoOf(SerialDescriptorForNullable).hashCode = function () {
    return imul(hashCode(this.mf7_1), 31);
  };
  function ObjectSerializer$descriptor$delegate$lambda$lambda(this$0) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.vf5_1 = this$0.afh_1;
      return Unit_getInstance();
    };
  }
  function ObjectSerializer$descriptor$delegate$lambda($serialName, this$0) {
    return function () {
      var tmp = OBJECT_getInstance();
      return buildSerialDescriptor($serialName, tmp, [], ObjectSerializer$descriptor$delegate$lambda$lambda(this$0));
    };
  }
  function ObjectSerializer(serialName, objectInstance) {
    this.zfg_1 = objectInstance;
    this.afh_1 = emptyList();
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.bfh_1 = lazy(tmp_0, ObjectSerializer$descriptor$delegate$lambda(serialName, this));
  }
  protoOf(ObjectSerializer).qf5 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = descriptor$factory_2();
    tmp$ret$0 = this.bfh_1.f1();
    return tmp$ret$0;
  };
  protoOf(ObjectSerializer).rf5 = function (encoder, value) {
    encoder.zf8(this.qf5()).af9(this.qf5());
  };
  protoOf(ObjectSerializer).sf5 = function (decoder) {
    var tmp$ret$1;
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var tmp0_decodeStructure = this.qf5();
    var composite = decoder.zf8(tmp0_decodeStructure);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.serialization.internal.ObjectSerializer.deserialize.<anonymous>' call
      if (composite.pf9()) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block_0;
      }
      var index = composite.qf9(this.qf5());
      Companion_getInstance_7();
      if (index === -1) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block_0;
      } else {
        throw SerializationException_init_$Create$('Unexpected index ' + index);
      }
    }
    var result = tmp$ret$0;
    composite.af9(tmp0_decodeStructure);
    tmp$ret$1 = result;
    return this.zfg_1;
  };
  function descriptor$factory_2() {
    return getPropertyCallableRef('descriptor', 1, KProperty1, function (receiver) {
      return receiver.qf5();
    }, null);
  }
  function get_EMPTY_DESCRIPTOR_ARRAY() {
    _init_properties_Platform_common_kt__3qzecs();
    return EMPTY_DESCRIPTOR_ARRAY;
  }
  var EMPTY_DESCRIPTOR_ARRAY;
  function cachedSerialNames(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    if (isInterface(_this__u8e3s4, CachedNames))
      return _this__u8e3s4.if8();
    var result = HashSet_init_$Create$_1(_this__u8e3s4.ef7());
    var inductionVariable = 0;
    var last = _this__u8e3s4.ef7();
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.plusAssign' call
        var tmp0_plusAssign = _this__u8e3s4.kf7(i);
        result.b(tmp0_plusAssign);
      }
       while (inductionVariable < last);
    return result;
  }
  function kclass(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    var t = _this__u8e3s4.lj();
    var tmp;
    if (!(t == null) ? isInterface(t, KClass) : false) {
      tmp = t;
    } else {
      if (!(t == null) ? isInterface(t, KTypeParameter) : false) {
        var tmp0_error = 'Captured type parameter ' + t + ' from generic non-reified function. ' + ('Such functionality cannot be supported as ' + t + ' is erased, either specify serializer explicitly or make ') + ('calling function inline with reified ' + t);
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      } else {
        var tmp1_error = 'Only KClass supported as classifier, got ' + t;
        throw IllegalStateException_init_$Create$(toString(tmp1_error));
      }
    }
    var tmp_0 = tmp;
    return isInterface(tmp_0, KClass) ? tmp_0 : THROW_CCE();
  }
  function notRegisteredMessage(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    var tmp0_elvis_lhs = _this__u8e3s4.zi();
    return notRegisteredMessage_0(tmp0_elvis_lhs == null ? '<local class name not available>' : tmp0_elvis_lhs);
  }
  function notRegisteredMessage_0(className) {
    _init_properties_Platform_common_kt__3qzecs();
    return "Serializer for class '" + className + "' is not found.\n" + "Please ensure that class is marked as '@Serializable' and that the serialization compiler plugin is applied.\n";
  }
  function compactArray(_this__u8e3s4) {
    _init_properties_Platform_common_kt__3qzecs();
    var tmp$ret$2;
    // Inline function 'kotlin.takeUnless' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    var tmp$ret$1;
    // Inline function 'kotlinx.serialization.internal.compactArray.<anonymous>' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isNullOrEmpty' call
    // Inline function 'kotlin.contracts.contract' call
    tmp$ret$0 = _this__u8e3s4 == null ? true : _this__u8e3s4.p();
    tmp$ret$1 = tmp$ret$0;
    if (!tmp$ret$1) {
      tmp = _this__u8e3s4;
    } else {
      tmp = null;
    }
    tmp$ret$2 = tmp;
    var tmp0_safe_receiver = tmp$ret$2;
    var tmp_0;
    if (tmp0_safe_receiver == null) {
      tmp_0 = null;
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.collections.toTypedArray' call
      tmp$ret$3 = copyToArray(tmp0_safe_receiver);
      tmp_0 = tmp$ret$3;
    }
    var tmp1_elvis_lhs = tmp_0;
    return tmp1_elvis_lhs == null ? get_EMPTY_DESCRIPTOR_ARRAY() : tmp1_elvis_lhs;
  }
  var properties_initialized_Platform_common_kt_i7q4ty;
  function _init_properties_Platform_common_kt__3qzecs() {
    if (properties_initialized_Platform_common_kt_i7q4ty) {
    } else {
      properties_initialized_Platform_common_kt_i7q4ty = true;
      var tmp$ret$2;
      // Inline function 'kotlin.arrayOf' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = [];
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      EMPTY_DESCRIPTOR_ARRAY = tmp$ret$2;
    }
  }
  function throwMissingFieldException(seen, goldenMask, descriptor) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    var missingFields = tmp$ret$0;
    var missingFieldsBits = goldenMask & ~seen;
    var inductionVariable = 0;
    if (inductionVariable < 32)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!((missingFieldsBits & 1) === 0)) {
          // Inline function 'kotlin.collections.plusAssign' call
          var tmp0_plusAssign = descriptor.kf7(i);
          missingFields.b(tmp0_plusAssign);
        }
        missingFieldsBits = missingFieldsBits >>> 1 | 0;
      }
       while (inductionVariable < 32);
    throw MissingFieldException_init_$Create$(missingFields, descriptor.qf6());
  }
  function _get_childSerializers__7vnyfa($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = childSerializers$factory();
    tmp$ret$0 = $this.jff_1.f1();
    return tmp$ret$0;
  }
  function _get__hashCode__tgwhef_0($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _hashCode$factory_0();
    tmp$ret$0 = $this.lff_1.f1();
    return tmp$ret$0;
  }
  function buildIndices($this) {
    var indices = HashMap_init_$Create$();
    var inductionVariable = 0;
    var last = $this.eff_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.set' call
        var tmp0_set = $this.eff_1[i];
        indices.e5(tmp0_set, i);
      }
       while (inductionVariable <= last);
    return indices;
  }
  function PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this$0) {
    return function () {
      var tmp0_safe_receiver = this$0.bff_1;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.rfg();
      return tmp1_elvis_lhs == null ? get_EMPTY_SERIALIZER_ARRAY() : tmp1_elvis_lhs;
    };
  }
  function PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this$0) {
    return function () {
      var tmp0_safe_receiver = this$0.bff_1;
      var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.sfg();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp$ret$2;
        // Inline function 'kotlin.collections.map' call
        var tmp$ret$1;
        // Inline function 'kotlin.collections.mapTo' call
        var tmp0_mapTo = ArrayList_init_$Create$(tmp1_safe_receiver.length);
        var tmp0_iterator = arrayIterator(tmp1_safe_receiver);
        while (tmp0_iterator.j()) {
          var item = tmp0_iterator.k();
          var tmp$ret$0;
          // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.typeParameterDescriptors$delegate.<anonymous>.<anonymous>' call
          tmp$ret$0 = item.qf5();
          tmp0_mapTo.b(tmp$ret$0);
        }
        tmp$ret$1 = tmp0_mapTo;
        tmp$ret$2 = tmp$ret$1;
        tmp = tmp$ret$2;
      }
      return compactArray(tmp);
    };
  }
  function PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this$0) {
    return function () {
      return hashCodeImpl(this$0, this$0.bfg());
    };
  }
  function PluginGeneratedSerialDescriptor$toString$lambda(this$0) {
    return function (i) {
      return this$0.kf7(i) + ': ' + this$0.if7(i).qf6();
    };
  }
  function PluginGeneratedSerialDescriptor(serialName, generatedSerializer, elementsCount) {
    generatedSerializer = generatedSerializer === VOID ? null : generatedSerializer;
    this.aff_1 = serialName;
    this.bff_1 = generatedSerializer;
    this.cff_1 = elementsCount;
    this.dff_1 = -1;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = this.cff_1;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.names.<anonymous>' call
      tmp$ret$1 = '[UNINITIALIZED]';
      tmp_2[tmp_3] = tmp$ret$1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.eff_1 = tmp_2;
    var tmp_4 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp0_arrayOfNulls = this.cff_1;
    tmp$ret$2 = fillArrayVal(Array(tmp0_arrayOfNulls), null);
    tmp_4.fff_1 = tmp$ret$2;
    this.gff_1 = null;
    this.hff_1 = booleanArray(this.cff_1);
    this.iff_1 = emptyMap();
    var tmp_5 = this;
    var tmp_6 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_5.jff_1 = lazy(tmp_6, PluginGeneratedSerialDescriptor$childSerializers$delegate$lambda(this));
    var tmp_7 = this;
    var tmp_8 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_7.kff_1 = lazy(tmp_8, PluginGeneratedSerialDescriptor$typeParameterDescriptors$delegate$lambda(this));
    var tmp_9 = this;
    var tmp_10 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_9.lff_1 = lazy(tmp_10, PluginGeneratedSerialDescriptor$_hashCode$delegate$lambda(this));
  }
  protoOf(PluginGeneratedSerialDescriptor).qf6 = function () {
    return this.aff_1;
  };
  protoOf(PluginGeneratedSerialDescriptor).ef7 = function () {
    return this.cff_1;
  };
  protoOf(PluginGeneratedSerialDescriptor).gf7 = function () {
    return CLASS_getInstance();
  };
  protoOf(PluginGeneratedSerialDescriptor).df7 = function () {
    var tmp0_elvis_lhs = this.gff_1;
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  };
  protoOf(PluginGeneratedSerialDescriptor).if8 = function () {
    return this.iff_1.i3();
  };
  protoOf(PluginGeneratedSerialDescriptor).bfg = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = typeParameterDescriptors$factory();
    tmp$ret$0 = this.kff_1.f1();
    return tmp$ret$0;
  };
  protoOf(PluginGeneratedSerialDescriptor).cfg = function (name, isOptional) {
    var tmp0_this = this;
    tmp0_this.dff_1 = tmp0_this.dff_1 + 1 | 0;
    this.eff_1[tmp0_this.dff_1] = name;
    this.hff_1[this.dff_1] = isOptional;
    this.fff_1[this.dff_1] = null;
    if (this.dff_1 === (this.cff_1 - 1 | 0)) {
      this.iff_1 = buildIndices(this);
    }
  };
  protoOf(PluginGeneratedSerialDescriptor).mff = function (name, isOptional, $super) {
    isOptional = isOptional === VOID ? false : isOptional;
    var tmp;
    if ($super === VOID) {
      this.cfg(name, isOptional);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.cfg.call(this, name, isOptional);
    }
    return tmp;
  };
  protoOf(PluginGeneratedSerialDescriptor).if7 = function (index) {
    return getChecked(_get_childSerializers__7vnyfa(this), index).qf5();
  };
  protoOf(PluginGeneratedSerialDescriptor).lf7 = function (index) {
    return getChecked_0(this.hff_1, index);
  };
  protoOf(PluginGeneratedSerialDescriptor).hf7 = function (index) {
    var tmp0_elvis_lhs = getChecked(this.fff_1, index);
    return tmp0_elvis_lhs == null ? emptyList() : tmp0_elvis_lhs;
  };
  protoOf(PluginGeneratedSerialDescriptor).kf7 = function (index) {
    return getChecked(this.eff_1, index);
  };
  protoOf(PluginGeneratedSerialDescriptor).jf7 = function (name) {
    var tmp0_elvis_lhs = this.iff_1.h3(name);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      Companion_getInstance_7();
      tmp = -3;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(PluginGeneratedSerialDescriptor).equals = function (other) {
    var tmp$ret$0;
    $l$block_5: {
      // Inline function 'kotlinx.serialization.internal.equalsImpl' call
      if (this === other) {
        tmp$ret$0 = true;
        break $l$block_5;
      }
      if (!(other instanceof PluginGeneratedSerialDescriptor)) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.qf6() === other.qf6())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.internal.PluginGeneratedSerialDescriptor.equals.<anonymous>' call
      var tmp0__anonymous__q1qw7t = other;
      tmp$ret$1 = contentEquals(this.bfg(), tmp0__anonymous__q1qw7t.bfg());
      if (!tmp$ret$1) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      if (!(this.ef7() === other.ef7())) {
        tmp$ret$0 = false;
        break $l$block_5;
      }
      var inductionVariable = 0;
      var last = this.ef7();
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (!(this.if7(index).qf6() === other.if7(index).qf6())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
          if (!equals(this.if7(index).gf7(), other.if7(index).gf7())) {
            tmp$ret$0 = false;
            break $l$block_5;
          }
        }
         while (inductionVariable < last);
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(PluginGeneratedSerialDescriptor).hashCode = function () {
    return _get__hashCode__tgwhef_0(this);
  };
  protoOf(PluginGeneratedSerialDescriptor).toString = function () {
    var tmp = until(0, this.cff_1);
    var tmp_0 = this.qf6() + '(';
    return joinToString(tmp, ', ', tmp_0, ')', VOID, VOID, PluginGeneratedSerialDescriptor$toString$lambda(this));
  };
  function hashCodeImpl(_this__u8e3s4, typeParams) {
    var result = getStringHashCode(_this__u8e3s4.qf6());
    result = imul(31, result) + contentHashCode(typeParams) | 0;
    var elementDescriptors = get_elementDescriptors(_this__u8e3s4);
    var tmp$ret$4;
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.fold' call
    var accumulator = 1;
    var tmp0_iterator = elementDescriptors.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var tmp0__anonymous__q1qw7t = accumulator;
      var tmp = imul(31, tmp0__anonymous__q1qw7t);
      var tmp$ret$1;
      // Inline function 'kotlin.hashCode' call
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
      tmp$ret$0 = element.qf6();
      var tmp0_hashCode = tmp$ret$0;
      var tmp0_safe_receiver = tmp0_hashCode;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      tmp$ret$1 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
      tmp$ret$2 = tmp + tmp$ret$1 | 0;
      accumulator = tmp$ret$2;
    }
    tmp$ret$3 = accumulator;
    tmp$ret$4 = tmp$ret$3;
    var namesHash = tmp$ret$4;
    var tmp$ret$9;
    // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy' call
    var tmp$ret$8;
    // Inline function 'kotlin.collections.fold' call
    var accumulator_0 = 1;
    var tmp0_iterator_0 = elementDescriptors.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      var tmp$ret$7;
      // Inline function 'kotlinx.serialization.internal.elementsHashCodeBy.<anonymous>' call
      var tmp1__anonymous__uwfjfc = accumulator_0;
      var tmp_0 = imul(31, tmp1__anonymous__uwfjfc);
      var tmp$ret$6;
      // Inline function 'kotlin.hashCode' call
      var tmp$ret$5;
      // Inline function 'kotlinx.serialization.internal.hashCodeImpl.<anonymous>' call
      tmp$ret$5 = element_0.gf7();
      var tmp0_hashCode_0 = tmp$ret$5;
      var tmp0_safe_receiver_0 = tmp0_hashCode_0;
      var tmp1_elvis_lhs_0 = tmp0_safe_receiver_0 == null ? null : hashCode(tmp0_safe_receiver_0);
      tmp$ret$6 = tmp1_elvis_lhs_0 == null ? 0 : tmp1_elvis_lhs_0;
      tmp$ret$7 = tmp_0 + tmp$ret$6 | 0;
      accumulator_0 = tmp$ret$7;
    }
    tmp$ret$8 = accumulator_0;
    tmp$ret$9 = tmp$ret$8;
    var kindHash = tmp$ret$9;
    result = imul(31, result) + namesHash | 0;
    result = imul(31, result) + kindHash | 0;
    return result;
  }
  function childSerializers$factory() {
    return getPropertyCallableRef('childSerializers', 1, KProperty1, function (receiver) {
      return _get_childSerializers__7vnyfa(receiver);
    }, null);
  }
  function typeParameterDescriptors$factory() {
    return getPropertyCallableRef('typeParameterDescriptors', 1, KProperty1, function (receiver) {
      return receiver.bfg();
    }, null);
  }
  function _hashCode$factory_0() {
    return getPropertyCallableRef('_hashCode', 1, KProperty1, function (receiver) {
      return _get__hashCode__tgwhef_0(receiver);
    }, null);
  }
  function get_EMPTY_SERIALIZER_ARRAY() {
    _init_properties_PluginHelperInterfaces_kt__xgvzfp();
    return EMPTY_SERIALIZER_ARRAY;
  }
  var EMPTY_SERIALIZER_ARRAY;
  function SerializerFactory() {
  }
  function GeneratedSerializer() {
  }
  var properties_initialized_PluginHelperInterfaces_kt_ap8in1;
  function _init_properties_PluginHelperInterfaces_kt__xgvzfp() {
    if (properties_initialized_PluginHelperInterfaces_kt_ap8in1) {
    } else {
      properties_initialized_PluginHelperInterfaces_kt_ap8in1 = true;
      var tmp$ret$2;
      // Inline function 'kotlin.arrayOf' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = [];
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      EMPTY_SERIALIZER_ARRAY = tmp$ret$2;
    }
  }
  function CharArraySerializer_0() {
    CharArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_1(Companion_getInstance_1()));
  }
  protoOf(CharArraySerializer_0).ffh = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(CharArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.ffh((!(_this__u8e3s4 == null) ? isCharArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CharArraySerializer_0).gfh = function (_this__u8e3s4) {
    return new CharArrayBuilder(_this__u8e3s4);
  };
  protoOf(CharArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.gfh((!(_this__u8e3s4 == null) ? isCharArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(CharArraySerializer_0).mfe = function () {
    return charArray(0);
  };
  protoOf(CharArraySerializer_0).hfh = function (decoder, index, builder, checkIndex) {
    builder.kfh(decoder.if9(this.kfe_1, index));
  };
  protoOf(CharArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.hfh(decoder, index, builder instanceof CharArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(CharArraySerializer_0).lfh = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.nfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(CharArraySerializer_0).nfe = function (encoder, content, size) {
    return this.lfh(encoder, (!(content == null) ? isCharArray(content) : false) ? content : THROW_CCE(), size);
  };
  var CharArraySerializer_instance;
  function CharArraySerializer_getInstance() {
    if (CharArraySerializer_instance == null)
      new CharArraySerializer_0();
    return CharArraySerializer_instance;
  }
  function DoubleArraySerializer_0() {
    DoubleArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_2(DoubleCompanionObject_getInstance()));
  }
  protoOf(DoubleArraySerializer_0).ofh = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(DoubleArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.ofh((!(_this__u8e3s4 == null) ? isDoubleArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(DoubleArraySerializer_0).pfh = function (_this__u8e3s4) {
    return new DoubleArrayBuilder(_this__u8e3s4);
  };
  protoOf(DoubleArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.pfh((!(_this__u8e3s4 == null) ? isDoubleArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(DoubleArraySerializer_0).mfe = function () {
    return new Float64Array(0);
  };
  protoOf(DoubleArraySerializer_0).qfh = function (decoder, index, builder, checkIndex) {
    builder.tfh(decoder.hf9(this.kfe_1, index));
  };
  protoOf(DoubleArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.qfh(decoder, index, builder instanceof DoubleArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(DoubleArraySerializer_0).ufh = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.mfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(DoubleArraySerializer_0).nfe = function (encoder, content, size) {
    return this.ufh(encoder, (!(content == null) ? isDoubleArray(content) : false) ? content : THROW_CCE(), size);
  };
  var DoubleArraySerializer_instance;
  function DoubleArraySerializer_getInstance() {
    if (DoubleArraySerializer_instance == null)
      new DoubleArraySerializer_0();
    return DoubleArraySerializer_instance;
  }
  function FloatArraySerializer_0() {
    FloatArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_3(FloatCompanionObject_getInstance()));
  }
  protoOf(FloatArraySerializer_0).xfh = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(FloatArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.xfh((!(_this__u8e3s4 == null) ? isFloatArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(FloatArraySerializer_0).yfh = function (_this__u8e3s4) {
    return new FloatArrayBuilder(_this__u8e3s4);
  };
  protoOf(FloatArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.yfh((!(_this__u8e3s4 == null) ? isFloatArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(FloatArraySerializer_0).mfe = function () {
    return new Float32Array(0);
  };
  protoOf(FloatArraySerializer_0).zfh = function (decoder, index, builder, checkIndex) {
    builder.cfi(decoder.gf9(this.kfe_1, index));
  };
  protoOf(FloatArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.zfh(decoder, index, builder instanceof FloatArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(FloatArraySerializer_0).dfi = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.lfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(FloatArraySerializer_0).nfe = function (encoder, content, size) {
    return this.dfi(encoder, (!(content == null) ? isFloatArray(content) : false) ? content : THROW_CCE(), size);
  };
  var FloatArraySerializer_instance;
  function FloatArraySerializer_getInstance() {
    if (FloatArraySerializer_instance == null)
      new FloatArraySerializer_0();
    return FloatArraySerializer_instance;
  }
  function LongArraySerializer_0() {
    LongArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_4(Companion_getInstance_0()));
  }
  protoOf(LongArraySerializer_0).gfi = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(LongArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.gfi((!(_this__u8e3s4 == null) ? isLongArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LongArraySerializer_0).hfi = function (_this__u8e3s4) {
    return new LongArrayBuilder(_this__u8e3s4);
  };
  protoOf(LongArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.hfi((!(_this__u8e3s4 == null) ? isLongArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(LongArraySerializer_0).mfe = function () {
    return longArray(0);
  };
  protoOf(LongArraySerializer_0).ifi = function (decoder, index, builder, checkIndex) {
    builder.lfi(decoder.ff9(this.kfe_1, index));
  };
  protoOf(LongArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.ifi(decoder, index, builder instanceof LongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(LongArraySerializer_0).mfi = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.kfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(LongArraySerializer_0).nfe = function (encoder, content, size) {
    return this.mfi(encoder, (!(content == null) ? isLongArray(content) : false) ? content : THROW_CCE(), size);
  };
  var LongArraySerializer_instance;
  function LongArraySerializer_getInstance() {
    if (LongArraySerializer_instance == null)
      new LongArraySerializer_0();
    return LongArraySerializer_instance;
  }
  function ULongArraySerializer_0() {
    ULongArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_5(Companion_getInstance_2()));
  }
  protoOf(ULongArraySerializer_0).pfi = function (_this__u8e3s4) {
    return _ULongArray___get_size__impl__ju6dtr(_this__u8e3s4);
  };
  protoOf(ULongArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.pfi(_this__u8e3s4 instanceof ULongArray ? _this__u8e3s4.se_1 : THROW_CCE());
  };
  protoOf(ULongArraySerializer_0).qfi = function (_this__u8e3s4) {
    return new ULongArrayBuilder(_this__u8e3s4);
  };
  protoOf(ULongArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.qfi(_this__u8e3s4 instanceof ULongArray ? _this__u8e3s4.se_1 : THROW_CCE());
  };
  protoOf(ULongArraySerializer_0).rfi = function () {
    return _ULongArray___init__impl__twm1l3(0);
  };
  protoOf(ULongArraySerializer_0).mfe = function () {
    return new ULongArray(this.rfi());
  };
  protoOf(ULongArraySerializer_0).sfi = function (decoder, index, builder, checkIndex) {
    var tmp$ret$0;
    // Inline function 'kotlin.toULong' call
    var tmp0_toULong = decoder.kf9(this.kfe_1, index).qf8();
    tmp$ret$0 = _ULong___init__impl__c78o9k(tmp0_toULong);
    builder.vfi(tmp$ret$0);
  };
  protoOf(ULongArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.sfi(decoder, index, builder instanceof ULongArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ULongArraySerializer_0).wfi = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.pfa(this.kfe_1, i);
        var tmp$ret$0;
        // Inline function 'kotlin.ULong.toLong' call
        var tmp0_toLong = ULongArray__get_impl_pr71q9(content, i);
        tmp$ret$0 = _ULong___get_data__impl__fggpzb(tmp0_toLong);
        tmp.zf9(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(ULongArraySerializer_0).nfe = function (encoder, content, size) {
    return this.wfi(encoder, content instanceof ULongArray ? content.se_1 : THROW_CCE(), size);
  };
  var ULongArraySerializer_instance;
  function ULongArraySerializer_getInstance() {
    if (ULongArraySerializer_instance == null)
      new ULongArraySerializer_0();
    return ULongArraySerializer_instance;
  }
  function IntArraySerializer_0() {
    IntArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_6(IntCompanionObject_getInstance()));
  }
  protoOf(IntArraySerializer_0).zfi = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(IntArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.zfi((!(_this__u8e3s4 == null) ? isIntArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(IntArraySerializer_0).afj = function (_this__u8e3s4) {
    return new IntArrayBuilder(_this__u8e3s4);
  };
  protoOf(IntArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.afj((!(_this__u8e3s4 == null) ? isIntArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(IntArraySerializer_0).mfe = function () {
    return new Int32Array(0);
  };
  protoOf(IntArraySerializer_0).bfj = function (decoder, index, builder, checkIndex) {
    builder.efj(decoder.ef9(this.kfe_1, index));
  };
  protoOf(IntArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.bfj(decoder, index, builder instanceof IntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(IntArraySerializer_0).ffj = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.jfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(IntArraySerializer_0).nfe = function (encoder, content, size) {
    return this.ffj(encoder, (!(content == null) ? isIntArray(content) : false) ? content : THROW_CCE(), size);
  };
  var IntArraySerializer_instance;
  function IntArraySerializer_getInstance() {
    if (IntArraySerializer_instance == null)
      new IntArraySerializer_0();
    return IntArraySerializer_instance;
  }
  function UIntArraySerializer_0() {
    UIntArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_7(Companion_getInstance_3()));
  }
  protoOf(UIntArraySerializer_0).ifj = function (_this__u8e3s4) {
    return _UIntArray___get_size__impl__r6l8ci(_this__u8e3s4);
  };
  protoOf(UIntArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.ifj(_this__u8e3s4 instanceof UIntArray ? _this__u8e3s4.ge_1 : THROW_CCE());
  };
  protoOf(UIntArraySerializer_0).jfj = function (_this__u8e3s4) {
    return new UIntArrayBuilder(_this__u8e3s4);
  };
  protoOf(UIntArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.jfj(_this__u8e3s4 instanceof UIntArray ? _this__u8e3s4.ge_1 : THROW_CCE());
  };
  protoOf(UIntArraySerializer_0).kfj = function () {
    return _UIntArray___init__impl__ghjpc6(0);
  };
  protoOf(UIntArraySerializer_0).mfe = function () {
    return new UIntArray(this.kfj());
  };
  protoOf(UIntArraySerializer_0).lfj = function (decoder, index, builder, checkIndex) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUInt' call
    var tmp0_toUInt = decoder.kf9(this.kfe_1, index).pf8();
    tmp$ret$0 = _UInt___init__impl__l7qpdl(tmp0_toUInt);
    builder.ofj(tmp$ret$0);
  };
  protoOf(UIntArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.lfj(decoder, index, builder instanceof UIntArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UIntArraySerializer_0).pfj = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.pfa(this.kfe_1, i);
        var tmp$ret$0;
        // Inline function 'kotlin.UInt.toInt' call
        var tmp0_toInt = UIntArray__get_impl_gp5kza(content, i);
        tmp$ret$0 = _UInt___get_data__impl__f0vqqw(tmp0_toInt);
        tmp.yf9(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UIntArraySerializer_0).nfe = function (encoder, content, size) {
    return this.pfj(encoder, content instanceof UIntArray ? content.ge_1 : THROW_CCE(), size);
  };
  var UIntArraySerializer_instance;
  function UIntArraySerializer_getInstance() {
    if (UIntArraySerializer_instance == null)
      new UIntArraySerializer_0();
    return UIntArraySerializer_instance;
  }
  function ShortArraySerializer_0() {
    ShortArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_8(ShortCompanionObject_getInstance()));
  }
  protoOf(ShortArraySerializer_0).sfj = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ShortArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.sfj((!(_this__u8e3s4 == null) ? isShortArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ShortArraySerializer_0).tfj = function (_this__u8e3s4) {
    return new ShortArrayBuilder(_this__u8e3s4);
  };
  protoOf(ShortArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.tfj((!(_this__u8e3s4 == null) ? isShortArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ShortArraySerializer_0).mfe = function () {
    return new Int16Array(0);
  };
  protoOf(ShortArraySerializer_0).ufj = function (decoder, index, builder, checkIndex) {
    builder.xfj(decoder.df9(this.kfe_1, index));
  };
  protoOf(ShortArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.ufj(decoder, index, builder instanceof ShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ShortArraySerializer_0).yfj = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.ifa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(ShortArraySerializer_0).nfe = function (encoder, content, size) {
    return this.yfj(encoder, (!(content == null) ? isShortArray(content) : false) ? content : THROW_CCE(), size);
  };
  var ShortArraySerializer_instance;
  function ShortArraySerializer_getInstance() {
    if (ShortArraySerializer_instance == null)
      new ShortArraySerializer_0();
    return ShortArraySerializer_instance;
  }
  function UShortArraySerializer_0() {
    UShortArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_9(Companion_getInstance_4()));
  }
  protoOf(UShortArraySerializer_0).bfk = function (_this__u8e3s4) {
    return _UShortArray___get_size__impl__jqto1b(_this__u8e3s4);
  };
  protoOf(UShortArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.bfk(_this__u8e3s4 instanceof UShortArray ? _this__u8e3s4.ef_1 : THROW_CCE());
  };
  protoOf(UShortArraySerializer_0).cfk = function (_this__u8e3s4) {
    return new UShortArrayBuilder(_this__u8e3s4);
  };
  protoOf(UShortArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.cfk(_this__u8e3s4 instanceof UShortArray ? _this__u8e3s4.ef_1 : THROW_CCE());
  };
  protoOf(UShortArraySerializer_0).dfk = function () {
    return _UShortArray___init__impl__9b26ef(0);
  };
  protoOf(UShortArraySerializer_0).mfe = function () {
    return new UShortArray(this.dfk());
  };
  protoOf(UShortArraySerializer_0).efk = function (decoder, index, builder, checkIndex) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUShort' call
    var tmp0_toUShort = decoder.kf9(this.kfe_1, index).of8();
    tmp$ret$0 = _UShort___init__impl__jigrne(tmp0_toUShort);
    builder.hfk(tmp$ret$0);
  };
  protoOf(UShortArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.efk(decoder, index, builder instanceof UShortArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UShortArraySerializer_0).ifk = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.pfa(this.kfe_1, i);
        var tmp$ret$0;
        // Inline function 'kotlin.UShort.toShort' call
        var tmp0_toShort = UShortArray__get_impl_fnbhmx(content, i);
        tmp$ret$0 = _UShort___get_data__impl__g0245(tmp0_toShort);
        tmp.xf9(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UShortArraySerializer_0).nfe = function (encoder, content, size) {
    return this.ifk(encoder, content instanceof UShortArray ? content.ef_1 : THROW_CCE(), size);
  };
  var UShortArraySerializer_instance;
  function UShortArraySerializer_getInstance() {
    if (UShortArraySerializer_instance == null)
      new UShortArraySerializer_0();
    return UShortArraySerializer_instance;
  }
  function ByteArraySerializer_0() {
    ByteArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_10(ByteCompanionObject_getInstance()));
  }
  protoOf(ByteArraySerializer_0).lfk = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(ByteArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.lfk((!(_this__u8e3s4 == null) ? isByteArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ByteArraySerializer_0).mfk = function (_this__u8e3s4) {
    return new ByteArrayBuilder(_this__u8e3s4);
  };
  protoOf(ByteArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.mfk((!(_this__u8e3s4 == null) ? isByteArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(ByteArraySerializer_0).mfe = function () {
    return new Int8Array(0);
  };
  protoOf(ByteArraySerializer_0).nfk = function (decoder, index, builder, checkIndex) {
    builder.qfk(decoder.cf9(this.kfe_1, index));
  };
  protoOf(ByteArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.nfk(decoder, index, builder instanceof ByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(ByteArraySerializer_0).rfk = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.hfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(ByteArraySerializer_0).nfe = function (encoder, content, size) {
    return this.rfk(encoder, (!(content == null) ? isByteArray(content) : false) ? content : THROW_CCE(), size);
  };
  var ByteArraySerializer_instance;
  function ByteArraySerializer_getInstance() {
    if (ByteArraySerializer_instance == null)
      new ByteArraySerializer_0();
    return ByteArraySerializer_instance;
  }
  function UByteArraySerializer_0() {
    UByteArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_11(Companion_getInstance_5()));
  }
  protoOf(UByteArraySerializer_0).ufk = function (_this__u8e3s4) {
    return _UByteArray___get_size__impl__h6pkdv(_this__u8e3s4);
  };
  protoOf(UByteArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.ufk(_this__u8e3s4 instanceof UByteArray ? _this__u8e3s4.td_1 : THROW_CCE());
  };
  protoOf(UByteArraySerializer_0).vfk = function (_this__u8e3s4) {
    return new UByteArrayBuilder(_this__u8e3s4);
  };
  protoOf(UByteArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.vfk(_this__u8e3s4 instanceof UByteArray ? _this__u8e3s4.td_1 : THROW_CCE());
  };
  protoOf(UByteArraySerializer_0).wfk = function () {
    return _UByteArray___init__impl__ip4y9n(0);
  };
  protoOf(UByteArraySerializer_0).mfe = function () {
    return new UByteArray(this.wfk());
  };
  protoOf(UByteArraySerializer_0).xfk = function (decoder, index, builder, checkIndex) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUByte' call
    var tmp0_toUByte = decoder.kf9(this.kfe_1, index).nf8();
    tmp$ret$0 = _UByte___init__impl__g9hnc4(tmp0_toUByte);
    builder.afl(tmp$ret$0);
  };
  protoOf(UByteArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.xfk(decoder, index, builder instanceof UByteArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(UByteArraySerializer_0).bfl = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = encoder.pfa(this.kfe_1, i);
        var tmp$ret$0;
        // Inline function 'kotlin.UByte.toByte' call
        var tmp0_toByte = UByteArray__get_impl_t5f3hv(content, i);
        tmp$ret$0 = _UByte___get_data__impl__jof9qr(tmp0_toByte);
        tmp.wf9(tmp$ret$0);
      }
       while (inductionVariable < size);
  };
  protoOf(UByteArraySerializer_0).nfe = function (encoder, content, size) {
    return this.bfl(encoder, content instanceof UByteArray ? content.td_1 : THROW_CCE(), size);
  };
  var UByteArraySerializer_instance;
  function UByteArraySerializer_getInstance() {
    if (UByteArraySerializer_instance == null)
      new UByteArraySerializer_0();
    return UByteArraySerializer_instance;
  }
  function BooleanArraySerializer_0() {
    BooleanArraySerializer_instance = this;
    PrimitiveArraySerializer.call(this, serializer_12(BooleanCompanionObject_getInstance()));
  }
  protoOf(BooleanArraySerializer_0).efl = function (_this__u8e3s4) {
    return _this__u8e3s4.length;
  };
  protoOf(BooleanArraySerializer_0).kfd = function (_this__u8e3s4) {
    return this.efl((!(_this__u8e3s4 == null) ? isBooleanArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(BooleanArraySerializer_0).ffl = function (_this__u8e3s4) {
    return new BooleanArrayBuilder(_this__u8e3s4);
  };
  protoOf(BooleanArraySerializer_0).ffc = function (_this__u8e3s4) {
    return this.ffl((!(_this__u8e3s4 == null) ? isBooleanArray(_this__u8e3s4) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(BooleanArraySerializer_0).mfe = function () {
    return booleanArray(0);
  };
  protoOf(BooleanArraySerializer_0).gfl = function (decoder, index, builder, checkIndex) {
    builder.jfl(decoder.bf9(this.kfe_1, index));
  };
  protoOf(BooleanArraySerializer_0).qfc = function (decoder, index, builder, checkIndex) {
    return this.gfl(decoder, index, builder instanceof BooleanArrayBuilder ? builder : THROW_CCE(), checkIndex);
  };
  protoOf(BooleanArraySerializer_0).kfl = function (encoder, content, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        encoder.gfa(this.kfe_1, i, content[i]);
      }
       while (inductionVariable < size);
  };
  protoOf(BooleanArraySerializer_0).nfe = function (encoder, content, size) {
    return this.kfl(encoder, (!(content == null) ? isBooleanArray(content) : false) ? content : THROW_CCE(), size);
  };
  var BooleanArraySerializer_instance;
  function BooleanArraySerializer_getInstance() {
    if (BooleanArraySerializer_instance == null)
      new BooleanArraySerializer_0();
    return BooleanArraySerializer_instance;
  }
  function CharArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.ifh_1 = bufferWithData;
    this.jfh_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(CharArrayBuilder).g3h = function () {
    return this.jfh_1;
  };
  protoOf(CharArrayBuilder).ug = function (requiredCapacity) {
    if (this.ifh_1.length < requiredCapacity)
      this.ifh_1 = copyOf(this.ifh_1, coerceAtLeast(requiredCapacity, imul(this.ifh_1.length, 2)));
  };
  protoOf(CharArrayBuilder).kfh = function (c) {
    this.pfe();
    var tmp = this.ifh_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.jfh_1;
    tmp0_this.jfh_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(CharArrayBuilder).tg = function () {
    return copyOf(this.ifh_1, this.jfh_1);
  };
  function DoubleArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.rfh_1 = bufferWithData;
    this.sfh_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(DoubleArrayBuilder).g3h = function () {
    return this.sfh_1;
  };
  protoOf(DoubleArrayBuilder).ug = function (requiredCapacity) {
    if (this.rfh_1.length < requiredCapacity)
      this.rfh_1 = copyOf_0(this.rfh_1, coerceAtLeast(requiredCapacity, imul(this.rfh_1.length, 2)));
  };
  protoOf(DoubleArrayBuilder).tfh = function (c) {
    this.pfe();
    var tmp = this.rfh_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.sfh_1;
    tmp0_this.sfh_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(DoubleArrayBuilder).tg = function () {
    return copyOf_0(this.rfh_1, this.sfh_1);
  };
  function FloatArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.afi_1 = bufferWithData;
    this.bfi_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(FloatArrayBuilder).g3h = function () {
    return this.bfi_1;
  };
  protoOf(FloatArrayBuilder).ug = function (requiredCapacity) {
    if (this.afi_1.length < requiredCapacity)
      this.afi_1 = copyOf_1(this.afi_1, coerceAtLeast(requiredCapacity, imul(this.afi_1.length, 2)));
  };
  protoOf(FloatArrayBuilder).cfi = function (c) {
    this.pfe();
    var tmp = this.afi_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.bfi_1;
    tmp0_this.bfi_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(FloatArrayBuilder).tg = function () {
    return copyOf_1(this.afi_1, this.bfi_1);
  };
  function LongArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.jfi_1 = bufferWithData;
    this.kfi_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(LongArrayBuilder).g3h = function () {
    return this.kfi_1;
  };
  protoOf(LongArrayBuilder).ug = function (requiredCapacity) {
    if (this.jfi_1.length < requiredCapacity)
      this.jfi_1 = copyOf_2(this.jfi_1, coerceAtLeast(requiredCapacity, imul(this.jfi_1.length, 2)));
  };
  protoOf(LongArrayBuilder).lfi = function (c) {
    this.pfe();
    var tmp = this.jfi_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.kfi_1;
    tmp0_this.kfi_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(LongArrayBuilder).tg = function () {
    return copyOf_2(this.jfi_1, this.kfi_1);
  };
  function ULongArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.tfi_1 = bufferWithData;
    this.ufi_1 = _ULongArray___get_size__impl__ju6dtr(bufferWithData);
    this.ug(10);
  }
  protoOf(ULongArrayBuilder).g3h = function () {
    return this.ufi_1;
  };
  protoOf(ULongArrayBuilder).ug = function (requiredCapacity) {
    if (_ULongArray___get_size__impl__ju6dtr(this.tfi_1) < requiredCapacity) {
      var tmp = this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.copyOf' call
      var tmp0_copyOf = this.tfi_1;
      var tmp1_copyOf = coerceAtLeast(requiredCapacity, imul(_ULongArray___get_size__impl__ju6dtr(this.tfi_1), 2));
      tmp$ret$0 = _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(tmp0_copyOf), tmp1_copyOf));
      tmp.tfi_1 = tmp$ret$0;
    }
  };
  protoOf(ULongArrayBuilder).vfi = function (c) {
    this.pfe();
    var tmp = this.tfi_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.ufi_1;
    tmp0_this.ufi_1 = tmp1 + 1 | 0;
    ULongArray__set_impl_z19mvh(tmp, tmp1, c);
  };
  protoOf(ULongArrayBuilder).lfl = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.tfi_1;
    var tmp1_copyOf = this.ufi_1;
    tmp$ret$0 = _ULongArray___init__impl__twm1l3_0(copyOf_2(_ULongArray___get_storage__impl__28e64j(tmp0_copyOf), tmp1_copyOf));
    return tmp$ret$0;
  };
  protoOf(ULongArrayBuilder).tg = function () {
    return new ULongArray(this.lfl());
  };
  function IntArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.cfj_1 = bufferWithData;
    this.dfj_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(IntArrayBuilder).g3h = function () {
    return this.dfj_1;
  };
  protoOf(IntArrayBuilder).ug = function (requiredCapacity) {
    if (this.cfj_1.length < requiredCapacity)
      this.cfj_1 = copyOf_3(this.cfj_1, coerceAtLeast(requiredCapacity, imul(this.cfj_1.length, 2)));
  };
  protoOf(IntArrayBuilder).efj = function (c) {
    this.pfe();
    var tmp = this.cfj_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.dfj_1;
    tmp0_this.dfj_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(IntArrayBuilder).tg = function () {
    return copyOf_3(this.cfj_1, this.dfj_1);
  };
  function UIntArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.mfj_1 = bufferWithData;
    this.nfj_1 = _UIntArray___get_size__impl__r6l8ci(bufferWithData);
    this.ug(10);
  }
  protoOf(UIntArrayBuilder).g3h = function () {
    return this.nfj_1;
  };
  protoOf(UIntArrayBuilder).ug = function (requiredCapacity) {
    if (_UIntArray___get_size__impl__r6l8ci(this.mfj_1) < requiredCapacity) {
      var tmp = this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.copyOf' call
      var tmp0_copyOf = this.mfj_1;
      var tmp1_copyOf = coerceAtLeast(requiredCapacity, imul(_UIntArray___get_size__impl__r6l8ci(this.mfj_1), 2));
      tmp$ret$0 = _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(tmp0_copyOf), tmp1_copyOf));
      tmp.mfj_1 = tmp$ret$0;
    }
  };
  protoOf(UIntArrayBuilder).ofj = function (c) {
    this.pfe();
    var tmp = this.mfj_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.nfj_1;
    tmp0_this.nfj_1 = tmp1 + 1 | 0;
    UIntArray__set_impl_7f2zu2(tmp, tmp1, c);
  };
  protoOf(UIntArrayBuilder).mfl = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.mfj_1;
    var tmp1_copyOf = this.nfj_1;
    tmp$ret$0 = _UIntArray___init__impl__ghjpc6_0(copyOf_3(_UIntArray___get_storage__impl__92a0v0(tmp0_copyOf), tmp1_copyOf));
    return tmp$ret$0;
  };
  protoOf(UIntArrayBuilder).tg = function () {
    return new UIntArray(this.mfl());
  };
  function ShortArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.vfj_1 = bufferWithData;
    this.wfj_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(ShortArrayBuilder).g3h = function () {
    return this.wfj_1;
  };
  protoOf(ShortArrayBuilder).ug = function (requiredCapacity) {
    if (this.vfj_1.length < requiredCapacity)
      this.vfj_1 = copyOf_4(this.vfj_1, coerceAtLeast(requiredCapacity, imul(this.vfj_1.length, 2)));
  };
  protoOf(ShortArrayBuilder).xfj = function (c) {
    this.pfe();
    var tmp = this.vfj_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.wfj_1;
    tmp0_this.wfj_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(ShortArrayBuilder).tg = function () {
    return copyOf_4(this.vfj_1, this.wfj_1);
  };
  function UShortArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.ffk_1 = bufferWithData;
    this.gfk_1 = _UShortArray___get_size__impl__jqto1b(bufferWithData);
    this.ug(10);
  }
  protoOf(UShortArrayBuilder).g3h = function () {
    return this.gfk_1;
  };
  protoOf(UShortArrayBuilder).ug = function (requiredCapacity) {
    if (_UShortArray___get_size__impl__jqto1b(this.ffk_1) < requiredCapacity) {
      var tmp = this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.copyOf' call
      var tmp0_copyOf = this.ffk_1;
      var tmp1_copyOf = coerceAtLeast(requiredCapacity, imul(_UShortArray___get_size__impl__jqto1b(this.ffk_1), 2));
      tmp$ret$0 = _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(tmp0_copyOf), tmp1_copyOf));
      tmp.ffk_1 = tmp$ret$0;
    }
  };
  protoOf(UShortArrayBuilder).hfk = function (c) {
    this.pfe();
    var tmp = this.ffk_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.gfk_1;
    tmp0_this.gfk_1 = tmp1 + 1 | 0;
    UShortArray__set_impl_6d8whp(tmp, tmp1, c);
  };
  protoOf(UShortArrayBuilder).nfl = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.ffk_1;
    var tmp1_copyOf = this.gfk_1;
    tmp$ret$0 = _UShortArray___init__impl__9b26ef_0(copyOf_4(_UShortArray___get_storage__impl__t2jpv5(tmp0_copyOf), tmp1_copyOf));
    return tmp$ret$0;
  };
  protoOf(UShortArrayBuilder).tg = function () {
    return new UShortArray(this.nfl());
  };
  function ByteArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.ofk_1 = bufferWithData;
    this.pfk_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(ByteArrayBuilder).g3h = function () {
    return this.pfk_1;
  };
  protoOf(ByteArrayBuilder).ug = function (requiredCapacity) {
    if (this.ofk_1.length < requiredCapacity)
      this.ofk_1 = copyOf_5(this.ofk_1, coerceAtLeast(requiredCapacity, imul(this.ofk_1.length, 2)));
  };
  protoOf(ByteArrayBuilder).qfk = function (c) {
    this.pfe();
    var tmp = this.ofk_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.pfk_1;
    tmp0_this.pfk_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(ByteArrayBuilder).tg = function () {
    return copyOf_5(this.ofk_1, this.pfk_1);
  };
  function UByteArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.yfk_1 = bufferWithData;
    this.zfk_1 = _UByteArray___get_size__impl__h6pkdv(bufferWithData);
    this.ug(10);
  }
  protoOf(UByteArrayBuilder).g3h = function () {
    return this.zfk_1;
  };
  protoOf(UByteArrayBuilder).ug = function (requiredCapacity) {
    if (_UByteArray___get_size__impl__h6pkdv(this.yfk_1) < requiredCapacity) {
      var tmp = this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.copyOf' call
      var tmp0_copyOf = this.yfk_1;
      var tmp1_copyOf = coerceAtLeast(requiredCapacity, imul(_UByteArray___get_size__impl__h6pkdv(this.yfk_1), 2));
      tmp$ret$0 = _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(tmp0_copyOf), tmp1_copyOf));
      tmp.yfk_1 = tmp$ret$0;
    }
  };
  protoOf(UByteArrayBuilder).afl = function (c) {
    this.pfe();
    var tmp = this.yfk_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.zfk_1;
    tmp0_this.zfk_1 = tmp1 + 1 | 0;
    UByteArray__set_impl_jvcicn(tmp, tmp1, c);
  };
  protoOf(UByteArrayBuilder).ofl = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.yfk_1;
    var tmp1_copyOf = this.zfk_1;
    tmp$ret$0 = _UByteArray___init__impl__ip4y9n_0(copyOf_5(_UByteArray___get_storage__impl__d4kctt(tmp0_copyOf), tmp1_copyOf));
    return tmp$ret$0;
  };
  protoOf(UByteArrayBuilder).tg = function () {
    return new UByteArray(this.ofl());
  };
  function BooleanArrayBuilder(bufferWithData) {
    PrimitiveArrayBuilder.call(this);
    this.hfl_1 = bufferWithData;
    this.ifl_1 = bufferWithData.length;
    this.ug(10);
  }
  protoOf(BooleanArrayBuilder).g3h = function () {
    return this.ifl_1;
  };
  protoOf(BooleanArrayBuilder).ug = function (requiredCapacity) {
    if (this.hfl_1.length < requiredCapacity)
      this.hfl_1 = copyOf_6(this.hfl_1, coerceAtLeast(requiredCapacity, imul(this.hfl_1.length, 2)));
  };
  protoOf(BooleanArrayBuilder).jfl = function (c) {
    this.pfe();
    var tmp = this.hfl_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.ifl_1;
    tmp0_this.ifl_1 = tmp1 + 1 | 0;
    tmp[tmp1] = c;
  };
  protoOf(BooleanArrayBuilder).tg = function () {
    return copyOf_6(this.hfl_1, this.ifl_1);
  };
  function get_BUILTIN_SERIALIZERS() {
    _init_properties_Primitives_kt__k0eto4();
    return BUILTIN_SERIALIZERS;
  }
  var BUILTIN_SERIALIZERS;
  function builtinSerializerOrNull(_this__u8e3s4) {
    _init_properties_Primitives_kt__k0eto4();
    var tmp = get_BUILTIN_SERIALIZERS().h3(_this__u8e3s4);
    return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : THROW_CCE();
  }
  function StringSerializer() {
    StringSerializer_instance = this;
    this.pfl_1 = new PrimitiveSerialDescriptor_0('kotlin.String', STRING_getInstance());
  }
  protoOf(StringSerializer).qf5 = function () {
    return this.pfl_1;
  };
  protoOf(StringSerializer).qfl = function (encoder, value) {
    return encoder.dfa(value);
  };
  protoOf(StringSerializer).rf5 = function (encoder, value) {
    return this.qfl(encoder, (!(value == null) ? typeof value === 'string' : false) ? value : THROW_CCE());
  };
  protoOf(StringSerializer).sf5 = function (decoder) {
    return decoder.uf8();
  };
  var StringSerializer_instance;
  function StringSerializer_getInstance() {
    if (StringSerializer_instance == null)
      new StringSerializer();
    return StringSerializer_instance;
  }
  function CharSerializer() {
    CharSerializer_instance = this;
    this.rfl_1 = new PrimitiveSerialDescriptor_0('kotlin.Char', CHAR_getInstance());
  }
  protoOf(CharSerializer).qf5 = function () {
    return this.rfl_1;
  };
  protoOf(CharSerializer).sfl = function (encoder, value) {
    return encoder.cfa(value);
  };
  protoOf(CharSerializer).rf5 = function (encoder, value) {
    return this.sfl(encoder, value instanceof Char ? value.ja_1 : THROW_CCE());
  };
  protoOf(CharSerializer).tfl = function (decoder) {
    return decoder.tf8();
  };
  protoOf(CharSerializer).sf5 = function (decoder) {
    return new Char(this.tfl(decoder));
  };
  var CharSerializer_instance;
  function CharSerializer_getInstance() {
    if (CharSerializer_instance == null)
      new CharSerializer();
    return CharSerializer_instance;
  }
  function DoubleSerializer() {
    DoubleSerializer_instance = this;
    this.ufl_1 = new PrimitiveSerialDescriptor_0('kotlin.Double', DOUBLE_getInstance());
  }
  protoOf(DoubleSerializer).qf5 = function () {
    return this.ufl_1;
  };
  protoOf(DoubleSerializer).vfl = function (encoder, value) {
    return encoder.bfa(value);
  };
  protoOf(DoubleSerializer).rf5 = function (encoder, value) {
    return this.vfl(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(DoubleSerializer).sf5 = function (decoder) {
    return decoder.sf8();
  };
  var DoubleSerializer_instance;
  function DoubleSerializer_getInstance() {
    if (DoubleSerializer_instance == null)
      new DoubleSerializer();
    return DoubleSerializer_instance;
  }
  function FloatSerializer() {
    FloatSerializer_instance = this;
    this.wfl_1 = new PrimitiveSerialDescriptor_0('kotlin.Float', FLOAT_getInstance());
  }
  protoOf(FloatSerializer).qf5 = function () {
    return this.wfl_1;
  };
  protoOf(FloatSerializer).xfl = function (encoder, value) {
    return encoder.afa(value);
  };
  protoOf(FloatSerializer).rf5 = function (encoder, value) {
    return this.xfl(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(FloatSerializer).sf5 = function (decoder) {
    return decoder.rf8();
  };
  var FloatSerializer_instance;
  function FloatSerializer_getInstance() {
    if (FloatSerializer_instance == null)
      new FloatSerializer();
    return FloatSerializer_instance;
  }
  function LongSerializer() {
    LongSerializer_instance = this;
    this.yfl_1 = new PrimitiveSerialDescriptor_0('kotlin.Long', LONG_getInstance());
  }
  protoOf(LongSerializer).qf5 = function () {
    return this.yfl_1;
  };
  protoOf(LongSerializer).zfl = function (encoder, value) {
    return encoder.zf9(value);
  };
  protoOf(LongSerializer).rf5 = function (encoder, value) {
    return this.zfl(encoder, value instanceof Long ? value : THROW_CCE());
  };
  protoOf(LongSerializer).sf5 = function (decoder) {
    return decoder.qf8();
  };
  var LongSerializer_instance;
  function LongSerializer_getInstance() {
    if (LongSerializer_instance == null)
      new LongSerializer();
    return LongSerializer_instance;
  }
  function IntSerializer() {
    IntSerializer_instance = this;
    this.afm_1 = new PrimitiveSerialDescriptor_0('kotlin.Int', INT_getInstance());
  }
  protoOf(IntSerializer).qf5 = function () {
    return this.afm_1;
  };
  protoOf(IntSerializer).bfm = function (encoder, value) {
    return encoder.yf9(value);
  };
  protoOf(IntSerializer).rf5 = function (encoder, value) {
    return this.bfm(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(IntSerializer).sf5 = function (decoder) {
    return decoder.pf8();
  };
  var IntSerializer_instance;
  function IntSerializer_getInstance() {
    if (IntSerializer_instance == null)
      new IntSerializer();
    return IntSerializer_instance;
  }
  function ShortSerializer() {
    ShortSerializer_instance = this;
    this.cfm_1 = new PrimitiveSerialDescriptor_0('kotlin.Short', SHORT_getInstance());
  }
  protoOf(ShortSerializer).qf5 = function () {
    return this.cfm_1;
  };
  protoOf(ShortSerializer).dfm = function (encoder, value) {
    return encoder.xf9(value);
  };
  protoOf(ShortSerializer).rf5 = function (encoder, value) {
    return this.dfm(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(ShortSerializer).sf5 = function (decoder) {
    return decoder.of8();
  };
  var ShortSerializer_instance;
  function ShortSerializer_getInstance() {
    if (ShortSerializer_instance == null)
      new ShortSerializer();
    return ShortSerializer_instance;
  }
  function ByteSerializer() {
    ByteSerializer_instance = this;
    this.efm_1 = new PrimitiveSerialDescriptor_0('kotlin.Byte', BYTE_getInstance());
  }
  protoOf(ByteSerializer).qf5 = function () {
    return this.efm_1;
  };
  protoOf(ByteSerializer).ffm = function (encoder, value) {
    return encoder.wf9(value);
  };
  protoOf(ByteSerializer).rf5 = function (encoder, value) {
    return this.ffm(encoder, (!(value == null) ? typeof value === 'number' : false) ? value : THROW_CCE());
  };
  protoOf(ByteSerializer).sf5 = function (decoder) {
    return decoder.nf8();
  };
  var ByteSerializer_instance;
  function ByteSerializer_getInstance() {
    if (ByteSerializer_instance == null)
      new ByteSerializer();
    return ByteSerializer_instance;
  }
  function BooleanSerializer() {
    BooleanSerializer_instance = this;
    this.gfm_1 = new PrimitiveSerialDescriptor_0('kotlin.Boolean', BOOLEAN_getInstance());
  }
  protoOf(BooleanSerializer).qf5 = function () {
    return this.gfm_1;
  };
  protoOf(BooleanSerializer).hfm = function (encoder, value) {
    return encoder.vf9(value);
  };
  protoOf(BooleanSerializer).rf5 = function (encoder, value) {
    return this.hfm(encoder, (!(value == null) ? typeof value === 'boolean' : false) ? value : THROW_CCE());
  };
  protoOf(BooleanSerializer).sf5 = function (decoder) {
    return decoder.mf8();
  };
  var BooleanSerializer_instance;
  function BooleanSerializer_getInstance() {
    if (BooleanSerializer_instance == null)
      new BooleanSerializer();
    return BooleanSerializer_instance;
  }
  function UnitSerializer() {
    UnitSerializer_instance = this;
    this.ifm_1 = new ObjectSerializer('kotlin.Unit', Unit_getInstance());
  }
  protoOf(UnitSerializer).qf5 = function () {
    return this.ifm_1.qf5();
  };
  protoOf(UnitSerializer).jfm = function (decoder) {
    this.ifm_1.sf5(decoder);
  };
  protoOf(UnitSerializer).sf5 = function (decoder) {
    this.jfm(decoder);
    return Unit_getInstance();
  };
  protoOf(UnitSerializer).kfm = function (encoder, value) {
    this.ifm_1.rf5(encoder, Unit_getInstance());
  };
  protoOf(UnitSerializer).rf5 = function (encoder, value) {
    return this.kfm(encoder, value instanceof Unit ? value : THROW_CCE());
  };
  var UnitSerializer_instance;
  function UnitSerializer_getInstance() {
    if (UnitSerializer_instance == null)
      new UnitSerializer();
    return UnitSerializer_instance;
  }
  function error_0($this) {
    throw IllegalStateException_init_$Create$('Primitive descriptor does not have elements');
  }
  function PrimitiveSerialDescriptor_0(serialName, kind) {
    this.lfm_1 = serialName;
    this.mfm_1 = kind;
  }
  protoOf(PrimitiveSerialDescriptor_0).qf6 = function () {
    return this.lfm_1;
  };
  protoOf(PrimitiveSerialDescriptor_0).gf7 = function () {
    return this.mfm_1;
  };
  protoOf(PrimitiveSerialDescriptor_0).ef7 = function () {
    return 0;
  };
  protoOf(PrimitiveSerialDescriptor_0).kf7 = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).jf7 = function (name) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).lf7 = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).if7 = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).hf7 = function (index) {
    error_0(this);
  };
  protoOf(PrimitiveSerialDescriptor_0).toString = function () {
    return 'PrimitiveDescriptor(' + this.lfm_1 + ')';
  };
  protoOf(PrimitiveSerialDescriptor_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PrimitiveSerialDescriptor_0))
      return false;
    if (this.lfm_1 === other.lfm_1 ? equals(this.mfm_1, other.mfm_1) : false)
      return true;
    return false;
  };
  protoOf(PrimitiveSerialDescriptor_0).hashCode = function () {
    return getStringHashCode(this.lfm_1) + imul(31, this.mfm_1.hashCode()) | 0;
  };
  function PrimitiveDescriptorSafe(serialName, kind) {
    _init_properties_Primitives_kt__k0eto4();
    checkName(serialName);
    return new PrimitiveSerialDescriptor_0(serialName, kind);
  }
  function checkName(serialName) {
    _init_properties_Primitives_kt__k0eto4();
    var keys = get_BUILTIN_SERIALIZERS().i3();
    var tmp0_iterator = keys.h();
    while (tmp0_iterator.j()) {
      var primitive = tmp0_iterator.k();
      var simpleName = capitalize(ensureNotNull(primitive.zi()));
      var qualifiedName = 'kotlin.' + simpleName;
      if (equals_0(serialName, qualifiedName, true) ? true : equals_0(serialName, simpleName, true)) {
        throw IllegalArgumentException_init_$Create$(trimIndent('\n                The name of serial descriptor should uniquely identify associated serializer.\n                For serial name ' + serialName + ' there already exist ' + capitalize(simpleName) + 'Serializer.\n                Please refer to SerialDescriptor documentation for additional information.\n            '));
      }
    }
  }
  function capitalize(_this__u8e3s4) {
    _init_properties_Primitives_kt__k0eto4();
    var tmp$ret$4;
    // Inline function 'kotlin.text.replaceFirstChar' call
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.text.isNotEmpty' call
    tmp$ret$0 = charSequenceLength(_this__u8e3s4) > 0;
    if (tmp$ret$0) {
      var tmp$ret$1;
      // Inline function 'kotlinx.serialization.internal.capitalize.<anonymous>' call
      var tmp0__anonymous__q1qw7t = charSequenceGet(_this__u8e3s4, 0);
      tmp$ret$1 = isLowerCase(tmp0__anonymous__q1qw7t) ? titlecase(tmp0__anonymous__q1qw7t) : toString_0(tmp0__anonymous__q1qw7t);
      var tmp_0 = toString(tmp$ret$1);
      var tmp$ret$3;
      // Inline function 'kotlin.text.substring' call
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = _this__u8e3s4;
      tmp$ret$3 = tmp$ret$2.substring(1);
      tmp = tmp_0 + tmp$ret$3;
    } else {
      tmp = _this__u8e3s4;
    }
    tmp$ret$4 = tmp;
    return tmp$ret$4;
  }
  var properties_initialized_Primitives_kt_6dpii6;
  function _init_properties_Primitives_kt__k0eto4() {
    if (properties_initialized_Primitives_kt_6dpii6) {
    } else {
      properties_initialized_Primitives_kt_6dpii6 = true;
      BUILTIN_SERIALIZERS = mapOf([to(PrimitiveClasses_getInstance().yj(), serializer_0(StringCompanionObject_getInstance())), to(getKClass(Char), serializer_1(Companion_getInstance_1())), to(PrimitiveClasses_getInstance().bk(), CharArraySerializer()), to(PrimitiveClasses_getInstance().wj(), serializer_2(DoubleCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().hk(), DoubleArraySerializer()), to(PrimitiveClasses_getInstance().vj(), serializer_3(FloatCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().gk(), FloatArraySerializer()), to(getKClass(Long), serializer_4(Companion_getInstance_0())), to(PrimitiveClasses_getInstance().fk(), LongArraySerializer()), to(getKClass(ULong), serializer_5(Companion_getInstance_2())), to(getKClass(ULongArray), ULongArraySerializer()), to(PrimitiveClasses_getInstance().uj(), serializer_6(IntCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().ek(), IntArraySerializer()), to(getKClass(UInt), serializer_7(Companion_getInstance_3())), to(getKClass(UIntArray), UIntArraySerializer()), to(PrimitiveClasses_getInstance().tj(), serializer_8(ShortCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().dk(), ShortArraySerializer()), to(getKClass(UShort), serializer_9(Companion_getInstance_4())), to(getKClass(UShortArray), UShortArraySerializer()), to(PrimitiveClasses_getInstance().sj(), serializer_10(ByteCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().ck(), ByteArraySerializer()), to(getKClass(UByte), serializer_11(Companion_getInstance_5())), to(getKClass(UByteArray), UByteArraySerializer()), to(PrimitiveClasses_getInstance().rj(), serializer_12(BooleanCompanionObject_getInstance())), to(PrimitiveClasses_getInstance().ak(), BooleanArraySerializer()), to(getKClass(Unit), serializer_13(Unit_getInstance())), to(PrimitiveClasses_getInstance().qj(), NothingSerializer()), to(getKClass(Duration), serializer_14(Companion_getInstance()))]);
    }
  }
  function NamedValueDecoder() {
    TaggedDecoder.call(this);
  }
  protoOf(NamedValueDecoder).pfm = function (_this__u8e3s4, index) {
    return this.rfm(this.qfm(_this__u8e3s4, index));
  };
  protoOf(NamedValueDecoder).rfm = function (nestedName) {
    var tmp0_elvis_lhs = this.ufm();
    return this.vfm(tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs, nestedName);
  };
  protoOf(NamedValueDecoder).qfm = function (descriptor, index) {
    return descriptor.kf7(index);
  };
  protoOf(NamedValueDecoder).vfm = function (parentName, childName) {
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.text.isEmpty' call
    tmp$ret$0 = charSequenceLength(parentName) === 0;
    if (tmp$ret$0) {
      tmp = childName;
    } else {
      tmp = parentName + '.' + childName;
    }
    return tmp;
  };
  function tagBlock($this, tag, block) {
    $this.jfn(tag);
    var r = block();
    if (!$this.tfm_1) {
      $this.kfn();
    }
    $this.tfm_1 = false;
    return r;
  }
  function TaggedDecoder$decodeSerializableElement$lambda(this$0, $deserializer, $previousValue) {
    return function () {
      return this$0.xf8($deserializer, $previousValue);
    };
  }
  function TaggedDecoder$decodeNullableSerializableElement$lambda(this$0, $deserializer, $previousValue) {
    return function () {
      return this$0.kf8() ? this$0.xf8($deserializer, $previousValue) : this$0.lf8();
    };
  }
  function TaggedDecoder() {
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    tmp.sfm_1 = tmp$ret$0;
    this.tfm_1 = false;
  }
  protoOf(TaggedDecoder).of9 = function () {
    return EmptySerializersModule_0();
  };
  protoOf(TaggedDecoder).wfm = function (tag) {
    throw SerializationException_init_$Create$('' + getKClassFromExpression(this) + " can't retrieve untyped values");
  };
  protoOf(TaggedDecoder).xfm = function (tag) {
    return true;
  };
  protoOf(TaggedDecoder).yfm = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'boolean' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).zfm = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).afn = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).bfn = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).cfn = function (tag) {
    var tmp = this.wfm(tag);
    return tmp instanceof Long ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).dfn = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).efn = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).ffn = function (tag) {
    var tmp = this.wfm(tag);
    return tmp instanceof Char ? tmp.ja_1 : THROW_CCE();
  };
  protoOf(TaggedDecoder).gfn = function (tag) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'string' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).hfn = function (tag, enumDescriptor) {
    var tmp = this.wfm(tag);
    return typeof tmp === 'number' ? tmp : THROW_CCE();
  };
  protoOf(TaggedDecoder).ifn = function (tag, inlineDescriptor) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.serialization.internal.TaggedDecoder.decodeTaggedInline.<anonymous>' call
    this.jfn(tag);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(TaggedDecoder).xf8 = function (deserializer, previousValue) {
    return this.yf8(deserializer);
  };
  protoOf(TaggedDecoder).wf8 = function (descriptor) {
    return this.ifn(this.kfn(), descriptor);
  };
  protoOf(TaggedDecoder).kf8 = function () {
    var tmp0_elvis_lhs = this.ufm();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var currentTag = tmp;
    return this.xfm(currentTag);
  };
  protoOf(TaggedDecoder).lf8 = function () {
    return null;
  };
  protoOf(TaggedDecoder).mf8 = function () {
    return this.yfm(this.kfn());
  };
  protoOf(TaggedDecoder).nf8 = function () {
    return this.zfm(this.kfn());
  };
  protoOf(TaggedDecoder).of8 = function () {
    return this.afn(this.kfn());
  };
  protoOf(TaggedDecoder).pf8 = function () {
    return this.bfn(this.kfn());
  };
  protoOf(TaggedDecoder).qf8 = function () {
    return this.cfn(this.kfn());
  };
  protoOf(TaggedDecoder).rf8 = function () {
    return this.dfn(this.kfn());
  };
  protoOf(TaggedDecoder).sf8 = function () {
    return this.efn(this.kfn());
  };
  protoOf(TaggedDecoder).tf8 = function () {
    return this.ffn(this.kfn());
  };
  protoOf(TaggedDecoder).uf8 = function () {
    return this.gfn(this.kfn());
  };
  protoOf(TaggedDecoder).vf8 = function (enumDescriptor) {
    return this.hfn(this.kfn(), enumDescriptor);
  };
  protoOf(TaggedDecoder).zf8 = function (descriptor) {
    return this;
  };
  protoOf(TaggedDecoder).af9 = function (descriptor) {
  };
  protoOf(TaggedDecoder).bf9 = function (descriptor, index) {
    return this.yfm(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).cf9 = function (descriptor, index) {
    return this.zfm(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).df9 = function (descriptor, index) {
    return this.afn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).ef9 = function (descriptor, index) {
    return this.bfn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).ff9 = function (descriptor, index) {
    return this.cfn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).gf9 = function (descriptor, index) {
    return this.dfn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).hf9 = function (descriptor, index) {
    return this.efn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).if9 = function (descriptor, index) {
    return this.ffn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).jf9 = function (descriptor, index) {
    return this.gfn(this.pfm(descriptor, index));
  };
  protoOf(TaggedDecoder).kf9 = function (descriptor, index) {
    return this.ifn(this.pfm(descriptor, index), descriptor.if7(index));
  };
  protoOf(TaggedDecoder).lf9 = function (descriptor, index, deserializer, previousValue) {
    var tmp = this.pfm(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeSerializableElement$lambda(this, deserializer, previousValue));
  };
  protoOf(TaggedDecoder).nf9 = function (descriptor, index, deserializer, previousValue) {
    var tmp = this.pfm(descriptor, index);
    return tagBlock(this, tmp, TaggedDecoder$decodeNullableSerializableElement$lambda(this, deserializer, previousValue));
  };
  protoOf(TaggedDecoder).ufm = function () {
    return lastOrNull(this.sfm_1);
  };
  protoOf(TaggedDecoder).jfn = function (name) {
    this.sfm_1.b(name);
  };
  protoOf(TaggedDecoder).kfn = function () {
    var r = this.sfm_1.c4(get_lastIndex_0(this.sfm_1));
    this.tfm_1 = true;
    return r;
  };
  function get_NULL() {
    _init_properties_Tuples_kt__dz0qyd();
    return NULL;
  }
  var NULL;
  function MapEntry(key, value) {
    this.lfn_1 = key;
    this.mfn_1 = value;
  }
  protoOf(MapEntry).e1 = function () {
    return this.lfn_1;
  };
  protoOf(MapEntry).f1 = function () {
    return this.mfn_1;
  };
  protoOf(MapEntry).toString = function () {
    return 'MapEntry(key=' + this.lfn_1 + ', value=' + this.mfn_1 + ')';
  };
  protoOf(MapEntry).hashCode = function () {
    var result = this.lfn_1 == null ? 0 : hashCode(this.lfn_1);
    result = imul(result, 31) + (this.mfn_1 == null ? 0 : hashCode(this.mfn_1)) | 0;
    return result;
  };
  protoOf(MapEntry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapEntry))
      return false;
    var tmp0_other_with_cast = other instanceof MapEntry ? other : THROW_CCE();
    if (!equals(this.lfn_1, tmp0_other_with_cast.lfn_1))
      return false;
    if (!equals(this.mfn_1, tmp0_other_with_cast.mfn_1))
      return false;
    return true;
  };
  function MapEntrySerializer$descriptor$lambda($keySerializer, $valueSerializer) {
    return function ($this$buildSerialDescriptor) {
      $this$buildSerialDescriptor.bf6('key', $keySerializer.qf5());
      $this$buildSerialDescriptor.bf6('value', $valueSerializer.qf5());
      return Unit_getInstance();
    };
  }
  function MapEntrySerializer_0(keySerializer, valueSerializer) {
    KeyValueSerializer.call(this, keySerializer, valueSerializer);
    var tmp = this;
    var tmp_0 = MAP_getInstance();
    tmp.pfn_1 = buildSerialDescriptor('kotlin.collections.Map.Entry', tmp_0, [], MapEntrySerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  protoOf(MapEntrySerializer_0).qf5 = function () {
    return this.pfn_1;
  };
  protoOf(MapEntrySerializer_0).qfn = function (_this__u8e3s4) {
    return _this__u8e3s4.e1();
  };
  protoOf(MapEntrySerializer_0).rfn = function (_this__u8e3s4) {
    return this.qfn((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Entry) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(MapEntrySerializer_0).sfn = function (_this__u8e3s4) {
    return _this__u8e3s4.f1();
  };
  protoOf(MapEntrySerializer_0).tfn = function (_this__u8e3s4) {
    return this.sfn((!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Entry) : false) ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(MapEntrySerializer_0).ufn = function (key, value) {
    return new MapEntry(key, value);
  };
  function PairSerializer$descriptor$lambda($keySerializer, $valueSerializer) {
    return function ($this$buildClassSerialDescriptor) {
      $this$buildClassSerialDescriptor.bf6('first', $keySerializer.qf5());
      $this$buildClassSerialDescriptor.bf6('second', $valueSerializer.qf5());
      return Unit_getInstance();
    };
  }
  function PairSerializer_0(keySerializer, valueSerializer) {
    KeyValueSerializer.call(this, keySerializer, valueSerializer);
    var tmp = this;
    tmp.afo_1 = buildClassSerialDescriptor('kotlin.Pair', [], PairSerializer$descriptor$lambda(keySerializer, valueSerializer));
  }
  protoOf(PairSerializer_0).qf5 = function () {
    return this.afo_1;
  };
  protoOf(PairSerializer_0).bfo = function (_this__u8e3s4) {
    return _this__u8e3s4.a5_1;
  };
  protoOf(PairSerializer_0).rfn = function (_this__u8e3s4) {
    return this.bfo(_this__u8e3s4 instanceof Pair ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PairSerializer_0).cfo = function (_this__u8e3s4) {
    return _this__u8e3s4.b5_1;
  };
  protoOf(PairSerializer_0).tfn = function (_this__u8e3s4) {
    return this.cfo(_this__u8e3s4 instanceof Pair ? _this__u8e3s4 : THROW_CCE());
  };
  protoOf(PairSerializer_0).ufn = function (key, value) {
    return to(key, value);
  };
  function decodeSequentially_1($this, composite) {
    var a = composite.mf9($this.gfo_1, 0, $this.dfo_1);
    var b = composite.mf9($this.gfo_1, 1, $this.efo_1);
    var c = composite.mf9($this.gfo_1, 2, $this.ffo_1);
    composite.af9($this.gfo_1);
    return new Triple(a, b, c);
  }
  function decodeStructure($this, composite) {
    var a = get_NULL();
    var b = get_NULL();
    var c = get_NULL();
    mainLoop: while (true) {
      var index = composite.qf9($this.gfo_1);
      Companion_getInstance_7();
      if (index === -1) {
        break mainLoop;
      } else {
        if (index === 0) {
          a = composite.mf9($this.gfo_1, 0, $this.dfo_1);
        } else {
          if (index === 1) {
            b = composite.mf9($this.gfo_1, 1, $this.efo_1);
          } else {
            if (index === 2) {
              c = composite.mf9($this.gfo_1, 2, $this.ffo_1);
            } else {
              throw SerializationException_init_$Create$('Unexpected index ' + index);
            }
          }
        }
      }
    }
    composite.af9($this.gfo_1);
    if (a === get_NULL())
      throw SerializationException_init_$Create$("Element 'first' is missing");
    if (b === get_NULL())
      throw SerializationException_init_$Create$("Element 'second' is missing");
    if (c === get_NULL())
      throw SerializationException_init_$Create$("Element 'third' is missing");
    var tmp = (a == null ? true : isObject(a)) ? a : THROW_CCE();
    var tmp_0 = (b == null ? true : isObject(b)) ? b : THROW_CCE();
    return new Triple(tmp, tmp_0, (c == null ? true : isObject(c)) ? c : THROW_CCE());
  }
  function TripleSerializer$descriptor$lambda(this$0) {
    return function ($this$buildClassSerialDescriptor) {
      $this$buildClassSerialDescriptor.bf6('first', this$0.dfo_1.qf5());
      $this$buildClassSerialDescriptor.bf6('second', this$0.efo_1.qf5());
      $this$buildClassSerialDescriptor.bf6('third', this$0.ffo_1.qf5());
      return Unit_getInstance();
    };
  }
  function TripleSerializer_0(aSerializer, bSerializer, cSerializer) {
    this.dfo_1 = aSerializer;
    this.efo_1 = bSerializer;
    this.ffo_1 = cSerializer;
    var tmp = this;
    tmp.gfo_1 = buildClassSerialDescriptor('kotlin.Triple', [], TripleSerializer$descriptor$lambda(this));
  }
  protoOf(TripleSerializer_0).qf5 = function () {
    return this.gfo_1;
  };
  protoOf(TripleSerializer_0).hfo = function (encoder, value) {
    var structuredEncoder = encoder.zf8(this.gfo_1);
    structuredEncoder.qfa(this.gfo_1, 0, this.dfo_1, value.gd_1);
    structuredEncoder.qfa(this.gfo_1, 1, this.efo_1, value.hd_1);
    structuredEncoder.qfa(this.gfo_1, 2, this.ffo_1, value.id_1);
    structuredEncoder.af9(this.gfo_1);
  };
  protoOf(TripleSerializer_0).rf5 = function (encoder, value) {
    return this.hfo(encoder, value instanceof Triple ? value : THROW_CCE());
  };
  protoOf(TripleSerializer_0).sf5 = function (decoder) {
    var composite = decoder.zf8(this.gfo_1);
    if (composite.pf9()) {
      return decodeSequentially_1(this, composite);
    }
    return decodeStructure(this, composite);
  };
  function KeyValueSerializer(keySerializer, valueSerializer) {
    this.vfn_1 = keySerializer;
    this.wfn_1 = valueSerializer;
  }
  protoOf(KeyValueSerializer).xfn = function (encoder, value) {
    var structuredEncoder = encoder.zf8(this.qf5());
    structuredEncoder.qfa(this.qf5(), 0, this.vfn_1, this.rfn(value));
    structuredEncoder.qfa(this.qf5(), 1, this.wfn_1, this.tfn(value));
    structuredEncoder.af9(this.qf5());
  };
  protoOf(KeyValueSerializer).rf5 = function (encoder, value) {
    return this.xfn(encoder, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(KeyValueSerializer).sf5 = function (decoder) {
    var tmp$ret$1;
    // Inline function 'kotlinx.serialization.encoding.decodeStructure' call
    var tmp0_decodeStructure = this.qf5();
    var composite = decoder.zf8(tmp0_decodeStructure);
    var tmp$ret$0;
    $l$block: {
      // Inline function 'kotlinx.serialization.internal.KeyValueSerializer.deserialize.<anonymous>' call
      if (composite.pf9()) {
        var key = composite.mf9(this.qf5(), 0, this.vfn_1);
        var value = composite.mf9(this.qf5(), 1, this.wfn_1);
        tmp$ret$0 = this.ufn(key, value);
        break $l$block;
      }
      var key_0 = get_NULL();
      var value_0 = get_NULL();
      mainLoop: while (true) {
        var idx = composite.qf9(this.qf5());
        Companion_getInstance_7();
        if (idx === -1) {
          break mainLoop;
        } else {
          if (idx === 0) {
            key_0 = composite.mf9(this.qf5(), 0, this.vfn_1);
          } else {
            if (idx === 1) {
              value_0 = composite.mf9(this.qf5(), 1, this.wfn_1);
            } else {
              throw SerializationException_init_$Create$('Invalid index: ' + idx);
            }
          }
        }
      }
      if (key_0 === get_NULL())
        throw SerializationException_init_$Create$("Element 'key' is missing");
      if (value_0 === get_NULL())
        throw SerializationException_init_$Create$("Element 'value' is missing");
      var tmp = (key_0 == null ? true : isObject(key_0)) ? key_0 : THROW_CCE();
      tmp$ret$0 = this.ufn(tmp, (value_0 == null ? true : isObject(value_0)) ? value_0 : THROW_CCE());
    }
    var result = tmp$ret$0;
    composite.af9(tmp0_decodeStructure);
    tmp$ret$1 = result;
    return tmp$ret$1;
  };
  var properties_initialized_Tuples_kt_3vs7ar;
  function _init_properties_Tuples_kt__dz0qyd() {
    if (properties_initialized_Tuples_kt_3vs7ar) {
    } else {
      properties_initialized_Tuples_kt_3vs7ar = true;
      NULL = new Object();
    }
  }
  function ULongSerializer() {
    ULongSerializer_instance = this;
    this.ifo_1 = InlinePrimitiveDescriptor('kotlin.ULong', serializer_4(Companion_getInstance_0()));
  }
  protoOf(ULongSerializer).qf5 = function () {
    return this.ifo_1;
  };
  protoOf(ULongSerializer).jfo = function (encoder, value) {
    var tmp = encoder.ffa(this.ifo_1);
    var tmp$ret$0;
    // Inline function 'kotlin.ULong.toLong' call
    tmp$ret$0 = _ULong___get_data__impl__fggpzb(value);
    tmp.zf9(tmp$ret$0);
  };
  protoOf(ULongSerializer).rf5 = function (encoder, value) {
    return this.jfo(encoder, value instanceof ULong ? value.ne_1 : THROW_CCE());
  };
  protoOf(ULongSerializer).kfo = function (decoder) {
    var tmp$ret$0;
    // Inline function 'kotlin.toULong' call
    var tmp0_toULong = decoder.wf8(this.ifo_1).qf8();
    tmp$ret$0 = _ULong___init__impl__c78o9k(tmp0_toULong);
    return tmp$ret$0;
  };
  protoOf(ULongSerializer).sf5 = function (decoder) {
    return new ULong(this.kfo(decoder));
  };
  var ULongSerializer_instance;
  function ULongSerializer_getInstance() {
    if (ULongSerializer_instance == null)
      new ULongSerializer();
    return ULongSerializer_instance;
  }
  function UIntSerializer() {
    UIntSerializer_instance = this;
    this.lfo_1 = InlinePrimitiveDescriptor('kotlin.UInt', serializer_6(IntCompanionObject_getInstance()));
  }
  protoOf(UIntSerializer).qf5 = function () {
    return this.lfo_1;
  };
  protoOf(UIntSerializer).mfo = function (encoder, value) {
    var tmp = encoder.ffa(this.lfo_1);
    var tmp$ret$0;
    // Inline function 'kotlin.UInt.toInt' call
    tmp$ret$0 = _UInt___get_data__impl__f0vqqw(value);
    tmp.yf9(tmp$ret$0);
  };
  protoOf(UIntSerializer).rf5 = function (encoder, value) {
    return this.mfo(encoder, value instanceof UInt ? value.ae_1 : THROW_CCE());
  };
  protoOf(UIntSerializer).nfo = function (decoder) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUInt' call
    var tmp0_toUInt = decoder.wf8(this.lfo_1).pf8();
    tmp$ret$0 = _UInt___init__impl__l7qpdl(tmp0_toUInt);
    return tmp$ret$0;
  };
  protoOf(UIntSerializer).sf5 = function (decoder) {
    return new UInt(this.nfo(decoder));
  };
  var UIntSerializer_instance;
  function UIntSerializer_getInstance() {
    if (UIntSerializer_instance == null)
      new UIntSerializer();
    return UIntSerializer_instance;
  }
  function UShortSerializer() {
    UShortSerializer_instance = this;
    this.ofo_1 = InlinePrimitiveDescriptor('kotlin.UShort', serializer_8(ShortCompanionObject_getInstance()));
  }
  protoOf(UShortSerializer).qf5 = function () {
    return this.ofo_1;
  };
  protoOf(UShortSerializer).pfo = function (encoder, value) {
    var tmp = encoder.ffa(this.ofo_1);
    var tmp$ret$0;
    // Inline function 'kotlin.UShort.toShort' call
    tmp$ret$0 = _UShort___get_data__impl__g0245(value);
    tmp.xf9(tmp$ret$0);
  };
  protoOf(UShortSerializer).rf5 = function (encoder, value) {
    return this.pfo(encoder, value instanceof UShort ? value.ze_1 : THROW_CCE());
  };
  protoOf(UShortSerializer).qfo = function (decoder) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUShort' call
    var tmp0_toUShort = decoder.wf8(this.ofo_1).of8();
    tmp$ret$0 = _UShort___init__impl__jigrne(tmp0_toUShort);
    return tmp$ret$0;
  };
  protoOf(UShortSerializer).sf5 = function (decoder) {
    return new UShort(this.qfo(decoder));
  };
  var UShortSerializer_instance;
  function UShortSerializer_getInstance() {
    if (UShortSerializer_instance == null)
      new UShortSerializer();
    return UShortSerializer_instance;
  }
  function UByteSerializer() {
    UByteSerializer_instance = this;
    this.rfo_1 = InlinePrimitiveDescriptor('kotlin.UByte', serializer_10(ByteCompanionObject_getInstance()));
  }
  protoOf(UByteSerializer).qf5 = function () {
    return this.rfo_1;
  };
  protoOf(UByteSerializer).sfo = function (encoder, value) {
    var tmp = encoder.ffa(this.rfo_1);
    var tmp$ret$0;
    // Inline function 'kotlin.UByte.toByte' call
    tmp$ret$0 = _UByte___get_data__impl__jof9qr(value);
    tmp.wf9(tmp$ret$0);
  };
  protoOf(UByteSerializer).rf5 = function (encoder, value) {
    return this.sfo(encoder, value instanceof UByte ? value.od_1 : THROW_CCE());
  };
  protoOf(UByteSerializer).tfo = function (decoder) {
    var tmp$ret$0;
    // Inline function 'kotlin.toUByte' call
    var tmp0_toUByte = decoder.wf8(this.rfo_1).nf8();
    tmp$ret$0 = _UByte___init__impl__g9hnc4(tmp0_toUByte);
    return tmp$ret$0;
  };
  protoOf(UByteSerializer).sf5 = function (decoder) {
    return new UByte(this.tfo(decoder));
  };
  var UByteSerializer_instance;
  function UByteSerializer_getInstance() {
    if (UByteSerializer_instance == null)
      new UByteSerializer();
    return UByteSerializer_instance;
  }
  function get_EmptySerializersModuleLegacyJs() {
    _init_properties_SerializersModule_kt__u78ha3();
    return EmptySerializersModule;
  }
  var EmptySerializersModule;
  function SerializersModule() {
  }
  protoOf(SerializersModule).wf6 = function (kClass, typeArgumentsSerializers, $super) {
    typeArgumentsSerializers = typeArgumentsSerializers === VOID ? emptyList() : typeArgumentsSerializers;
    return $super === VOID ? this.vf6(kClass, typeArgumentsSerializers) : $super.vf6.call(this, kClass, typeArgumentsSerializers);
  };
  function SerialModuleImpl(class2ContextualFactory, polyBase2Serializers, polyBase2DefaultSerializerProvider, polyBase2NamedSerializers, polyBase2DefaultDeserializerProvider) {
    SerializersModule.call(this);
    this.vfo_1 = class2ContextualFactory;
    this.wfo_1 = polyBase2Serializers;
    this.xfo_1 = polyBase2DefaultSerializerProvider;
    this.yfo_1 = polyBase2NamedSerializers;
    this.zfo_1 = polyBase2DefaultDeserializerProvider;
  }
  protoOf(SerialModuleImpl).afb = function (baseClass, value) {
    if (!baseClass.aj(value))
      return null;
    var tmp0_safe_receiver = this.wfo_1.h3(baseClass);
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.h3(getKClassFromExpression(value));
    var registered = (!(tmp == null) ? isInterface(tmp, SerializationStrategy) : false) ? tmp : null;
    if (!(registered == null))
      return registered;
    var tmp_0 = this.xfo_1.h3(baseClass);
    var tmp1_safe_receiver = (!(tmp_0 == null) ? typeof tmp_0 === 'function' : false) ? tmp_0 : null;
    return tmp1_safe_receiver == null ? null : tmp1_safe_receiver(value);
  };
  protoOf(SerialModuleImpl).zfa = function (baseClass, serializedClassName) {
    var tmp0_safe_receiver = this.yfo_1.h3(baseClass);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.get' call
      tmp$ret$0 = ((!(tmp0_safe_receiver == null) ? isInterface(tmp0_safe_receiver, Map) : false) ? tmp0_safe_receiver : THROW_CCE()).h3(serializedClassName);
      tmp = tmp$ret$0;
    }
    var tmp_0 = tmp;
    var registered = (!(tmp_0 == null) ? isInterface(tmp_0, KSerializer) : false) ? tmp_0 : null;
    if (!(registered == null))
      return registered;
    var tmp_1 = this.zfo_1.h3(baseClass);
    var tmp1_safe_receiver = (!(tmp_1 == null) ? typeof tmp_1 === 'function' : false) ? tmp_1 : null;
    return tmp1_safe_receiver == null ? null : tmp1_safe_receiver(serializedClassName);
  };
  protoOf(SerialModuleImpl).vf6 = function (kClass, typeArgumentsSerializers) {
    var tmp0_safe_receiver = this.vfo_1.h3(kClass);
    var tmp = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.afp(typeArgumentsSerializers);
    return (tmp == null ? true : isInterface(tmp, KSerializer)) ? tmp : null;
  };
  protoOf(SerialModuleImpl).ufo = function (collector) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.vfo_1;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = tmp0_forEach.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$1 = element.e1();
      var kclass = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$2 = element.f1();
      var serial = tmp$ret$2;
      var tmp0_subject = serial;
      if (tmp0_subject instanceof Argless) {
        var tmp = isInterface(kclass, KClass) ? kclass : THROW_CCE();
        var tmp_0 = serial.dfp_1;
        collector.efp(tmp, isInterface(tmp_0, KSerializer) ? tmp_0 : THROW_CCE());
      } else {
        if (tmp0_subject instanceof WithTypeArguments) {
          collector.cfp(kclass, serial.bfp_1);
        }
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = this.wfo_1;
    var tmp$ret$3;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$3 = tmp1_forEach.d1().h();
    var tmp0_iterator_0 = tmp$ret$3;
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      var tmp$ret$4;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$4 = element_0.e1();
      var baseClass = tmp$ret$4;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$5 = element_0.f1();
      var classMap = tmp$ret$5;
      // Inline function 'kotlin.collections.forEach' call
      var tmp$ret$6;
      // Inline function 'kotlin.collections.iterator' call
      tmp$ret$6 = classMap.d1().h();
      var tmp0_iterator_1 = tmp$ret$6;
      while (tmp0_iterator_1.j()) {
        var element_1 = tmp0_iterator_1.k();
        // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>.<anonymous>' call
        var tmp$ret$7;
        // Inline function 'kotlin.collections.component1' call
        tmp$ret$7 = element_1.e1();
        var actualClass = tmp$ret$7;
        var tmp$ret$8;
        // Inline function 'kotlin.collections.component2' call
        tmp$ret$8 = element_1.f1();
        var serializer = tmp$ret$8;
        var tmp_1 = isInterface(baseClass, KClass) ? baseClass : THROW_CCE();
        var tmp_2 = isInterface(actualClass, KClass) ? actualClass : THROW_CCE();
        var tmp$ret$9;
        // Inline function 'kotlinx.serialization.internal.cast' call
        tmp$ret$9 = isInterface(serializer, KSerializer) ? serializer : THROW_CCE();
        collector.ffp(tmp_1, tmp_2, tmp$ret$9);
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp2_forEach = this.xfo_1;
    var tmp$ret$10;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$10 = tmp2_forEach.d1().h();
    var tmp0_iterator_2 = tmp$ret$10;
    while (tmp0_iterator_2.j()) {
      var element_2 = tmp0_iterator_2.k();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      var tmp$ret$11;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$11 = element_2.e1();
      var baseClass_0 = tmp$ret$11;
      var tmp$ret$12;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$12 = element_2.f1();
      var provider = tmp$ret$12;
      var tmp_3 = isInterface(baseClass_0, KClass) ? baseClass_0 : THROW_CCE();
      collector.gfp(tmp_3, typeof provider === 'function' ? provider : THROW_CCE());
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp3_forEach = this.zfo_1;
    var tmp$ret$13;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$13 = tmp3_forEach.d1().h();
    var tmp0_iterator_3 = tmp$ret$13;
    while (tmp0_iterator_3.j()) {
      var element_3 = tmp0_iterator_3.k();
      // Inline function 'kotlinx.serialization.modules.SerialModuleImpl.dumpTo.<anonymous>' call
      var tmp$ret$14;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$14 = element_3.e1();
      var baseClass_1 = tmp$ret$14;
      var tmp$ret$15;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$15 = element_3.f1();
      var provider_0 = tmp$ret$15;
      var tmp_4 = isInterface(baseClass_1, KClass) ? baseClass_1 : THROW_CCE();
      collector.hfp(tmp_4, typeof provider_0 === 'function' ? provider_0 : THROW_CCE());
    }
  };
  function Argless() {
  }
  function WithTypeArguments() {
  }
  function ContextualProvider() {
  }
  var properties_initialized_SerializersModule_kt_fjigjn;
  function _init_properties_SerializersModule_kt__u78ha3() {
    if (properties_initialized_SerializersModule_kt_fjigjn) {
    } else {
      properties_initialized_SerializersModule_kt_fjigjn = true;
      EmptySerializersModule = new SerialModuleImpl(emptyMap(), emptyMap(), emptyMap(), emptyMap(), emptyMap());
    }
  }
  function EmptySerializersModule_0() {
    return get_EmptySerializersModuleLegacyJs();
  }
  function SerializersModuleCollector$contextual$lambda($serializer) {
    return function (it) {
      return $serializer;
    };
  }
  function SerializersModuleCollector() {
  }
  function SerializableWith(serializer) {
    this.ifp_1 = serializer;
  }
  protoOf(SerializableWith).equals = function (other) {
    if (!(other instanceof SerializableWith))
      return false;
    var tmp0_other_with_cast = other instanceof SerializableWith ? other : THROW_CCE();
    if (!this.ifp_1.equals(tmp0_other_with_cast.ifp_1))
      return false;
    return true;
  };
  protoOf(SerializableWith).hashCode = function () {
    return imul(getStringHashCode('serializer'), 127) ^ this.ifp_1.hashCode();
  };
  protoOf(SerializableWith).toString = function () {
    return '@kotlinx.serialization.SerializableWith(serializer=' + this.ifp_1 + ')';
  };
  function createCache(factory) {
    return new createCache$1(factory);
  }
  function createParametrizedCache(factory) {
    return new createParametrizedCache$1(factory);
  }
  function compiledSerializerImpl(_this__u8e3s4) {
    var tmp1_elvis_lhs = constructSerializerForGivenTypeArgs(_this__u8e3s4, []);
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      var tmp0_asDynamic = get_js(_this__u8e3s4);
      tmp$ret$0 = tmp0_asDynamic;
      var tmp0_safe_receiver = tmp$ret$0.Companion;
      var tmp_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.serializer();
      tmp = (!(tmp_0 == null) ? isInterface(tmp_0, KSerializer) : false) ? tmp_0 : null;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function platformSpecificSerializerNotRegistered(_this__u8e3s4) {
    throw SerializationException_init_$Create$(notRegisteredMessage(_this__u8e3s4) + '\n' + 'On Kotlin/JS explicitly declared serializer should be used for interfaces and enums without @Serializable annotation');
  }
  function isReferenceArray(rootClass) {
    return rootClass.equals(PrimitiveClasses_getInstance().xj());
  }
  function constructSerializerForGivenTypeArgs(_this__u8e3s4, args) {
    var tmp;
    try {
      var tmp$ret$0;
      // Inline function 'kotlin.reflect.findAssociatedObject' call
      tmp$ret$0 = findAssociatedObject(_this__u8e3s4, getKClass(SerializableWith));
      var assocObject = tmp$ret$0;
      var tmp_0;
      if (!(assocObject == null) ? isInterface(assocObject, KSerializer) : false) {
        tmp_0 = (!(assocObject == null) ? isInterface(assocObject, KSerializer) : false) ? assocObject : THROW_CCE();
      } else {
        if (!(assocObject == null) ? isInterface(assocObject, SerializerFactory) : false) {
          var tmp_1 = assocObject.cfh(args.slice());
          tmp_0 = isInterface(tmp_1, KSerializer) ? tmp_1 : THROW_CCE();
        } else {
          if (get_isInterface(_this__u8e3s4)) {
            tmp_0 = new PolymorphicSerializer(_this__u8e3s4);
          } else {
            tmp_0 = null;
          }
        }
      }
      tmp = tmp_0;
    } catch ($p) {
      var tmp_2;
      {
        var e = $p;
        tmp_2 = null;
      }
      tmp = tmp_2;
    }
    return tmp;
  }
  function get_isInterface(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = get_js(_this__u8e3s4);
    tmp$ret$0 = tmp0_asDynamic;
    var tmp0_safe_receiver = tmp$ret$0.$metadata$;
    return (tmp0_safe_receiver == null ? null : tmp0_safe_receiver.kind) == 'interface';
  }
  function toNativeArrayImpl(_this__u8e3s4, eClass) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.toTypedArray' call
    tmp$ret$0 = copyToArray(_this__u8e3s4);
    return tmp$ret$0;
  }
  function getChecked(_this__u8e3s4, index) {
    if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
      throw IndexOutOfBoundsException_init_$Create$('Index ' + index + ' out of bounds ' + get_indices(_this__u8e3s4));
    return _this__u8e3s4[index];
  }
  function getChecked_0(_this__u8e3s4, index) {
    if (!(0 <= index ? index <= (_this__u8e3s4.length - 1 | 0) : false))
      throw IndexOutOfBoundsException_init_$Create$('Index ' + index + ' out of bounds ' + get_indices_0(_this__u8e3s4));
    return _this__u8e3s4[index];
  }
  function createCache$1($factory) {
    this.jfp_1 = $factory;
  }
  protoOf(createCache$1).xf6 = function (key) {
    return this.jfp_1(key);
  };
  function createParametrizedCache$1($factory) {
    this.kfp_1 = $factory;
  }
  protoOf(createParametrizedCache$1).yf6 = function (key, types) {
    var tmp$ret$3;
    // Inline function 'kotlin.runCatching' call
    var tmp;
    try {
      var tmp$ret$1;
      // Inline function 'kotlin.Companion.success' call
      var tmp0_success = Companion_getInstance_6();
      var tmp$ret$0;
      // Inline function 'kotlinx.serialization.internal.<no name provided>.get.<anonymous>' call
      tmp$ret$0 = this.kfp_1(key, types);
      var tmp1_success = tmp$ret$0;
      tmp$ret$1 = _Result___init__impl__xyqfz8(tmp1_success);
      tmp = tmp$ret$1;
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var e = $p;
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.failure' call
        var tmp2_failure = Companion_getInstance_6();
        tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(e));
        tmp_0 = tmp$ret$2;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    tmp$ret$3 = tmp;
    return tmp$ret$3;
  };
  //region block: post-declaration
  protoOf(SerialDescriptorImpl).zf6 = get_isNullable;
  protoOf(SerialDescriptorImpl).ff7 = get_isInline;
  protoOf(AbstractDecoder).mf9 = decodeSerializableElement$default;
  protoOf(AbstractDecoder).yf8 = decodeSerializableValue;
  protoOf(AbstractDecoder).pf9 = decodeSequentially;
  protoOf(AbstractDecoder).rf9 = decodeCollectionSize;
  protoOf(AbstractEncoder).ufa = encodeNotNullMark;
  protoOf(AbstractEncoder).vfa = beginCollection;
  protoOf(AbstractEncoder).rfa = encodeSerializableValue;
  protoOf(AbstractEncoder).tfa = encodeNullableSerializableValue;
  protoOf(AbstractEncoder).wfa = shouldEncodeElementDefault;
  protoOf(ListLikeDescriptor).zf6 = get_isNullable;
  protoOf(ListLikeDescriptor).ff7 = get_isInline;
  protoOf(ListLikeDescriptor).df7 = get_annotations;
  protoOf(ArrayListClassDesc).zf6 = get_isNullable;
  protoOf(ArrayListClassDesc).ff7 = get_isInline;
  protoOf(ArrayListClassDesc).df7 = get_annotations;
  protoOf(HashSetClassDesc).zf6 = get_isNullable;
  protoOf(HashSetClassDesc).ff7 = get_isInline;
  protoOf(HashSetClassDesc).df7 = get_annotations;
  protoOf(LinkedHashSetClassDesc).zf6 = get_isNullable;
  protoOf(LinkedHashSetClassDesc).ff7 = get_isInline;
  protoOf(LinkedHashSetClassDesc).df7 = get_annotations;
  protoOf(MapLikeDescriptor).zf6 = get_isNullable;
  protoOf(MapLikeDescriptor).ff7 = get_isInline;
  protoOf(MapLikeDescriptor).df7 = get_annotations;
  protoOf(HashMapClassDesc).zf6 = get_isNullable;
  protoOf(HashMapClassDesc).ff7 = get_isInline;
  protoOf(HashMapClassDesc).df7 = get_annotations;
  protoOf(LinkedHashMapClassDesc).zf6 = get_isNullable;
  protoOf(LinkedHashMapClassDesc).ff7 = get_isInline;
  protoOf(LinkedHashMapClassDesc).df7 = get_annotations;
  protoOf(ArrayClassDesc).zf6 = get_isNullable;
  protoOf(ArrayClassDesc).ff7 = get_isInline;
  protoOf(ArrayClassDesc).df7 = get_annotations;
  protoOf(PrimitiveArrayDescriptor).zf6 = get_isNullable;
  protoOf(PrimitiveArrayDescriptor).ff7 = get_isInline;
  protoOf(PrimitiveArrayDescriptor).df7 = get_annotations;
  protoOf(PluginGeneratedSerialDescriptor).zf6 = get_isNullable;
  protoOf(PluginGeneratedSerialDescriptor).ff7 = get_isInline;
  protoOf(EnumDescriptor).zf6 = get_isNullable;
  protoOf(EnumDescriptor).ff7 = get_isInline;
  protoOf(InlineClassDescriptor).zf6 = get_isNullable;
  protoOf(InlinePrimitiveDescriptor$1).sfg = typeParametersSerializers;
  protoOf(NoOpEncoder).ufa = encodeNotNullMark;
  protoOf(NoOpEncoder).vfa = beginCollection;
  protoOf(NoOpEncoder).rfa = encodeSerializableValue;
  protoOf(NoOpEncoder).tfa = encodeNullableSerializableValue;
  protoOf(NoOpEncoder).wfa = shouldEncodeElementDefault;
  protoOf(NothingSerialDescriptor).zf6 = get_isNullable;
  protoOf(NothingSerialDescriptor).ff7 = get_isInline;
  protoOf(NothingSerialDescriptor).df7 = get_annotations;
  protoOf(PrimitiveSerialDescriptor_0).zf6 = get_isNullable;
  protoOf(PrimitiveSerialDescriptor_0).ff7 = get_isInline;
  protoOf(PrimitiveSerialDescriptor_0).df7 = get_annotations;
  protoOf(TaggedDecoder).mf9 = decodeSerializableElement$default;
  protoOf(TaggedDecoder).yf8 = decodeSerializableValue;
  protoOf(TaggedDecoder).pf9 = decodeSequentially;
  protoOf(TaggedDecoder).rf9 = decodeCollectionSize;
  protoOf(NamedValueDecoder).yf8 = decodeSerializableValue;
  protoOf(NamedValueDecoder).mf9 = decodeSerializableElement$default;
  protoOf(NamedValueDecoder).pf9 = decodeSequentially;
  protoOf(NamedValueDecoder).rf9 = decodeCollectionSize;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = decodeSerializableElement$default;
  _.$_$.b = SealedClassSerializer_init_$Create$;
  _.$_$.c = SerializationException_init_$Init$;
  _.$_$.d = SerializationException_init_$Create$;
  _.$_$.e = UnknownFieldException_init_$Create$;
  _.$_$.f = SEALED_getInstance;
  _.$_$.g = STRING_getInstance;
  _.$_$.h = CONTEXTUAL_getInstance;
  _.$_$.i = ENUM_getInstance;
  _.$_$.j = CLASS_getInstance;
  _.$_$.k = LIST_getInstance;
  _.$_$.l = MAP_getInstance;
  _.$_$.m = Companion_getInstance_7;
  _.$_$.n = BooleanSerializer_getInstance;
  _.$_$.o = DoubleSerializer_getInstance;
  _.$_$.p = IntSerializer_getInstance;
  _.$_$.q = StringSerializer_getInstance;
  _.$_$.r = ListSerializer;
  _.$_$.s = MapSerializer;
  _.$_$.t = get_nullable;
  _.$_$.u = serializer_0;
  _.$_$.v = serializer_9;
  _.$_$.w = serializer_7;
  _.$_$.x = serializer_11;
  _.$_$.y = serializer_5;
  _.$_$.z = PolymorphicKind;
  _.$_$.a1 = PrimitiveKind;
  _.$_$.b1 = PrimitiveSerialDescriptor;
  _.$_$.c1 = get_annotations;
  _.$_$.d1 = get_isInline;
  _.$_$.e1 = get_isNullable;
  _.$_$.f1 = SerialDescriptor;
  _.$_$.g1 = ENUM;
  _.$_$.h1 = buildSerialDescriptor;
  _.$_$.i1 = getContextualDescriptor;
  _.$_$.j1 = AbstractDecoder;
  _.$_$.k1 = AbstractEncoder;
  _.$_$.l1 = decodeCollectionSize;
  _.$_$.m1 = decodeSequentially;
  _.$_$.n1 = CompositeDecoder;
  _.$_$.o1 = CompositeEncoder;
  _.$_$.p1 = decodeSerializableValue;
  _.$_$.q1 = Decoder;
  _.$_$.r1 = beginCollection;
  _.$_$.s1 = encodeNotNullMark;
  _.$_$.t1 = encodeNullableSerializableValue;
  _.$_$.u1 = Encoder;
  _.$_$.v1 = AbstractPolymorphicSerializer;
  _.$_$.w1 = ArrayListSerializer;
  _.$_$.x1 = ElementMarker;
  _.$_$.y1 = EnumSerializer;
  _.$_$.z1 = typeParametersSerializers;
  _.$_$.a2 = GeneratedSerializer;
  _.$_$.b2 = InlineClassDescriptor;
  _.$_$.c2 = InlinePrimitiveDescriptor;
  _.$_$.d2 = LinkedHashMapSerializer;
  _.$_$.e2 = NamedValueDecoder;
  _.$_$.f2 = PluginGeneratedSerialDescriptor;
  _.$_$.g2 = SerializerFactory;
  _.$_$.h2 = jsonCachedSerialNames;
  _.$_$.i2 = throwMissingFieldException;
  _.$_$.j2 = EmptySerializersModule_0;
  _.$_$.k2 = contextual;
  _.$_$.l2 = SerializersModuleCollector;
  _.$_$.m2 = DeserializationStrategy;
  _.$_$.n2 = KSerializer;
  _.$_$.o2 = MissingFieldException;
  _.$_$.p2 = SealedClassSerializer;
  _.$_$.q2 = SerializationException;
  _.$_$.r2 = findPolymorphicSerializer;
  _.$_$.s2 = serializer;
  //endregion
  return _;
}));
