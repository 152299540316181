(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './korge-root-korge.js', './korge-root-korma.js', './korge-root-korim.js', './XenoTacticV2-XenoECS.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./korge-root-korge.js'), require('./korge-root-korma.js'), require('./korge-root-korim.js'), require('./XenoTacticV2-XenoECS.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoKorgeCommon'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'XenoTacticV2-XenoKorgeCommon'.");
    }
    if (typeof this['korge-root-korge'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoKorgeCommon'. Its dependency 'korge-root-korge' was not found. Please, check whether 'korge-root-korge' is loaded prior to 'XenoTacticV2-XenoKorgeCommon'.");
    }
    if (typeof this['korge-root-korma'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoKorgeCommon'. Its dependency 'korge-root-korma' was not found. Please, check whether 'korge-root-korma' is loaded prior to 'XenoTacticV2-XenoKorgeCommon'.");
    }
    if (typeof this['korge-root-korim'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoKorgeCommon'. Its dependency 'korge-root-korim' was not found. Please, check whether 'korge-root-korim' is loaded prior to 'XenoTacticV2-XenoKorgeCommon'.");
    }
    if (typeof this['XenoTacticV2-XenoECS'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoKorgeCommon'. Its dependency 'XenoTacticV2-XenoECS' was not found. Please, check whether 'XenoTacticV2-XenoECS' is loaded prior to 'XenoTacticV2-XenoKorgeCommon'.");
    }
    root['XenoTacticV2-XenoKorgeCommon'] = factory(typeof this['XenoTacticV2-XenoKorgeCommon'] === 'undefined' ? {} : this['XenoTacticV2-XenoKorgeCommon'], this['kotlin-kotlin-stdlib-js-ir'], this['korge-root-korge'], this['korge-root-korma'], this['korge-root-korim'], this['XenoTacticV2-XenoECS']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korlibs_korge2_korge, kotlin_com_soywiz_korlibs_korma_korma, kotlin_com_soywiz_korlibs_korim_korim, kotlin_com_xenotactic_ecs_XenoECS) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var Container = kotlin_com_soywiz_korlibs_korge2_korge.$_$.v1;
  var addTo = kotlin_com_soywiz_korlibs_korge2_korge.$_$.h2;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var getKClass = kotlin_kotlin.$_$.e;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var get_indices = kotlin_kotlin.$_$.a8;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.t1;
  var centerXBetween = kotlin_com_soywiz_korlibs_korge2_korge.$_$.p1;
  var Size = kotlin_com_soywiz_korlibs_korma_korma.$_$.k7;
  var Colors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.i1;
  var SolidRect = kotlin_com_soywiz_korlibs_korge2_korge.$_$.d2;
  var classMeta = kotlin_kotlin.$_$.zb;
  var TypedInjections = kotlin_com_xenotactic_ecs_XenoECS.$_$.l;
  var hashCode = kotlin_kotlin.$_$.lc;
  var equals = kotlin_kotlin.$_$.cc;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var RGBA = kotlin_com_soywiz_korlibs_korim_korim.$_$.i2;
  var RGBA__hashCode_impl_h59qf6 = kotlin_com_soywiz_korlibs_korim_korim.$_$.n;
  var Enum = kotlin_kotlin.$_$.rh;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Column, 'Column', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Row, 'Row', classMeta);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Modifiers, 'Modifiers', classMeta);
  setMetadataFor(SpacingBetween, 'SpacingBetween', classMeta);
  setMetadataFor(SolidBackgroundColor, 'SolidBackgroundColor', classMeta);
  setMetadataFor(Padding, 'Padding', classMeta);
  setMetadataFor(HorizontalAlignment, 'HorizontalAlignment', classMeta);
  setMetadataFor(HorizontalAlignmentType, 'HorizontalAlignmentType', classMeta, Enum);
  //endregion
  function Companion() {
    Companion_instance = this;
  }
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function Column() {
    Companion_getInstance();
    this.ih5_1 = new Container();
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container = this.ih5_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, tmp2_container);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'com.xeno.korge.common.Column.innerContent.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.jh5_1 = tmp$ret$3;
  }
  protoOf(Column).mfr = function () {
    return this.ih5_1;
  };
  protoOf(Column).kh5 = function (addFn) {
    this.jh5_1.jbk(addFn().mfr());
  };
  protoOf(Column).lh5 = function (modifiers) {
    var i = 0;
    var tmp$ret$2;
    // Inline function 'com.xeno.korge.common.Modifiers.getOrDefault' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp0_getSingletonOrNull = modifiers.mh5_1;
    var tmp = tmp0_getSingletonOrNull.wg2_1.h3(getKClass(SpacingBetween));
    tmp$ret$0 = (tmp == null ? true : tmp instanceof SpacingBetween) ? tmp : THROW_CCE();
    var modifier = tmp$ret$0;
    var tmp0_elvis_lhs = modifier;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$1;
      // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
      tmp$ret$1 = new SpacingBetween();
      tmp_0 = tmp$ret$1;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    tmp$ret$2 = tmp_0;
    var spacing = tmp$ret$2;
    var currHeight = 0.0;
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = this.jh5_1.hb3_1;
    var tmp0_iterator = tmp1_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
      element.dbl(currHeight);
      currHeight = currHeight + element.y18();
      if (1 <= i ? i <= get_indices(this.jh5_1.hb3_1).q1_1 : false) {
        var tmp0_this = element;
        tmp0_this.dbl(tmp0_this.f6g() + spacing.nh5_1);
        currHeight = currHeight + spacing.nh5_1;
      }
      var tmp1 = i;
      i = tmp1 + 1 | 0;
    }
    var tmp$ret$5;
    // Inline function 'com.xeno.korge.common.Modifiers.getOrDefault' call
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp2_getSingletonOrNull = modifiers.mh5_1;
    var tmp_1 = tmp2_getSingletonOrNull.wg2_1.h3(getKClass(HorizontalAlignment));
    tmp$ret$3 = (tmp_1 == null ? true : tmp_1 instanceof HorizontalAlignment) ? tmp_1 : THROW_CCE();
    var modifier_0 = tmp$ret$3;
    var tmp0_elvis_lhs_0 = modifier_0;
    var tmp_2;
    if (tmp0_elvis_lhs_0 == null) {
      var tmp$ret$4;
      // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
      tmp$ret$4 = new HorizontalAlignment();
      tmp_2 = tmp$ret$4;
    } else {
      tmp_2 = tmp0_elvis_lhs_0;
    }
    tmp$ret$5 = tmp_2;
    var horizontalAlignment = tmp$ret$5;
    var tmp0_subject = horizontalAlignment.oh5_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 0)
    ;
    else if (tmp0 === 1) {
      var tmp$ret$9;
      // Inline function 'kotlin.collections.maxOf' call
      var tmp4_maxOf = this.jh5_1.hb3_1;
      var iterator = tmp4_maxOf.h();
      if (!iterator.j())
        throw NoSuchElementException_init_$Create$();
      var tmp$ret$6;
      // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
      var tmp5__anonymous__kpxxpo = iterator.k();
      tmp$ret$6 = tmp5__anonymous__kpxxpo.fbm();
      var maxValue = tmp$ret$6;
      while (iterator.j()) {
        var tmp$ret$7;
        // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
        var tmp6__anonymous__fv9ai5 = iterator.k();
        tmp$ret$7 = tmp6__anonymous__fv9ai5.fbm();
        var v = tmp$ret$7;
        var tmp$ret$8;
        // Inline function 'kotlin.comparisons.maxOf' call
        var tmp3_maxOf = maxValue;
        tmp$ret$8 = Math.max(tmp3_maxOf, v);
        maxValue = tmp$ret$8;
      }
      tmp$ret$9 = maxValue;
      var maxWidth = tmp$ret$9;
      // Inline function 'kotlin.collections.forEach' call
      var tmp7_forEach = this.jh5_1.hb3_1;
      var tmp0_iterator_0 = tmp7_forEach.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k();
        // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
        centerXBetween(element_0, 0.0, maxWidth);
      }
    }
    var tmp$ret$11;
    // Inline function 'com.xeno.korge.common.Modifiers.getOrNull' call
    var tmp$ret$10;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp8_getSingletonOrNull = modifiers.mh5_1;
    var tmp_3 = tmp8_getSingletonOrNull.wg2_1.h3(getKClass(Padding));
    tmp$ret$10 = (tmp_3 == null ? true : tmp_3 instanceof Padding) ? tmp_3 : THROW_CCE();
    tmp$ret$11 = tmp$ret$10;
    var padding = tmp$ret$11;
    if (!(padding == null)) {
      var tmp$ret$15;
      // Inline function 'kotlin.collections.maxOf' call
      var tmp10_maxOf = this.jh5_1.hb3_1;
      var iterator_0 = tmp10_maxOf.h();
      if (!iterator_0.j())
        throw NoSuchElementException_init_$Create$();
      var tmp$ret$12;
      // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
      var tmp11__anonymous__tkubxh = iterator_0.k();
      tmp$ret$12 = tmp11__anonymous__tkubxh.x18();
      var maxValue_0 = tmp$ret$12;
      while (iterator_0.j()) {
        var tmp$ret$13;
        // Inline function 'com.xeno.korge.common.Column.relayout.<anonymous>' call
        var tmp12__anonymous__oq5opy = iterator_0.k();
        tmp$ret$13 = tmp12__anonymous__oq5opy.x18();
        var v_0 = tmp$ret$13;
        var tmp$ret$14;
        // Inline function 'kotlin.comparisons.maxOf' call
        var tmp9_maxOf = maxValue_0;
        tmp$ret$14 = Math.max(tmp9_maxOf, v_0);
        maxValue_0 = tmp$ret$14;
      }
      tmp$ret$15 = maxValue_0;
      var width = tmp$ret$15 + padding.ph5_1 + padding.qh5_1;
      var height = currHeight + padding.rh5_1 + padding.sh5_1;
      var rect = new SolidRect(new Size(width, height), Colors_getInstance().p4s_1);
      this.ih5_1.qbk(rect, 0);
      this.jh5_1.cbl(padding.ph5_1);
      this.jh5_1.dbl(padding.rh5_1);
    }
    var tmp$ret$17;
    // Inline function 'com.xeno.korge.common.Modifiers.getOrNull' call
    var tmp$ret$16;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp13_getSingletonOrNull = modifiers.mh5_1;
    var tmp_4 = tmp13_getSingletonOrNull.wg2_1.h3(getKClass(SolidBackgroundColor));
    tmp$ret$16 = (tmp_4 == null ? true : tmp_4 instanceof SolidBackgroundColor) ? tmp_4 : THROW_CCE();
    tmp$ret$17 = tmp$ret$16;
    var solidBackgroundColor = tmp$ret$17;
    if (!(solidBackgroundColor == null)) {
      var rect_0 = new SolidRect(new Size(this.ih5_1.x18(), this.ih5_1.y18()), solidBackgroundColor.th5_1);
      this.ih5_1.qbk(rect_0, 0);
    }
  };
  protoOf(Column).uh5 = function (contain, modifiers, function_0) {
    addTo(this.ih5_1, contain);
    this.lh5(modifiers);
    function_0(this);
    return this;
  };
  function Companion_0() {
    Companion_instance_0 = this;
  }
  protoOf(Companion_0).vh5 = function (modifiers, fn) {
    var row = new Row();
    fn(row);
    row.lh5(modifiers);
    return row;
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function Row() {
    Companion_getInstance_0();
    this.wh5_1 = new Container();
  }
  protoOf(Row).mfr = function () {
    return this.wh5_1;
  };
  protoOf(Row).xh5 = function (addFn) {
    this.wh5_1.jbk(addFn());
  };
  protoOf(Row).lh5 = function (modifiers) {
    var currWidth = 0.0;
    var i = 0;
    var tmp$ret$2;
    // Inline function 'com.xeno.korge.common.Modifiers.getOrDefault' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp0_getSingletonOrNull = modifiers.mh5_1;
    var tmp = tmp0_getSingletonOrNull.wg2_1.h3(getKClass(SpacingBetween));
    tmp$ret$0 = (tmp == null ? true : tmp instanceof SpacingBetween) ? tmp : THROW_CCE();
    var modifier = tmp$ret$0;
    var tmp0_elvis_lhs = modifier;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$1;
      // Inline function 'com.xeno.korge.common.Row.relayout.<anonymous>' call
      tmp$ret$1 = new SpacingBetween();
      tmp_0 = tmp$ret$1;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    tmp$ret$2 = tmp_0;
    var rowModifier = tmp$ret$2;
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = this.wh5_1.hb3_1;
    var tmp0_iterator = tmp1_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xeno.korge.common.Row.relayout.<anonymous>' call
      element.cbl(currWidth);
      if (1 <= i ? i <= get_indices(this.wh5_1.hb3_1).q1_1 : false) {
        var tmp0_this = element;
        tmp0_this.cbl(tmp0_this.e6g() + rowModifier.nh5_1);
        currWidth = currWidth + rowModifier.nh5_1;
      }
      currWidth = currWidth + element.fbm();
      var tmp1 = i;
      i = tmp1 + 1 | 0;
    }
  };
  function Companion_1() {
    Companion_instance_1 = this;
    this.yh5_1 = new Modifiers();
  }
  protoOf(Companion_1).zh5 = function (modifiers) {
    var modifiersContainer = new Modifiers();
    var indexedObject = modifiers;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var modifier = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      modifiersContainer.mh5_1.xg2(modifier);
    }
    return modifiersContainer;
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function Modifiers(modifiers) {
    Companion_getInstance_1();
    modifiers = modifiers === VOID ? new TypedInjections() : modifiers;
    this.mh5_1 = modifiers;
  }
  protoOf(Modifiers).toString = function () {
    return 'Modifiers(modifiers=' + this.mh5_1 + ')';
  };
  protoOf(Modifiers).hashCode = function () {
    return hashCode(this.mh5_1);
  };
  protoOf(Modifiers).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Modifiers))
      return false;
    var tmp0_other_with_cast = other instanceof Modifiers ? other : THROW_CCE();
    if (!equals(this.mh5_1, tmp0_other_with_cast.mh5_1))
      return false;
    return true;
  };
  function SpacingBetween(between) {
    between = between === VOID ? 0.0 : between;
    this.nh5_1 = between;
  }
  protoOf(SpacingBetween).toString = function () {
    return 'SpacingBetween(between=' + this.nh5_1 + ')';
  };
  protoOf(SpacingBetween).hashCode = function () {
    return getNumberHashCode(this.nh5_1);
  };
  protoOf(SpacingBetween).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SpacingBetween))
      return false;
    var tmp0_other_with_cast = other instanceof SpacingBetween ? other : THROW_CCE();
    if (!equals(this.nh5_1, tmp0_other_with_cast.nh5_1))
      return false;
    return true;
  };
  function SolidBackgroundColor(colors) {
    colors = colors === VOID ? Colors_getInstance().p4s_1 : colors;
    this.th5_1 = colors;
  }
  protoOf(SolidBackgroundColor).toString = function () {
    return 'SolidBackgroundColor(colors=' + new RGBA(this.th5_1) + ')';
  };
  protoOf(SolidBackgroundColor).hashCode = function () {
    return RGBA__hashCode_impl_h59qf6(this.th5_1);
  };
  protoOf(SolidBackgroundColor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SolidBackgroundColor))
      return false;
    var tmp0_other_with_cast = other instanceof SolidBackgroundColor ? other : THROW_CCE();
    if (!(this.th5_1 === tmp0_other_with_cast.th5_1))
      return false;
    return true;
  };
  function Padding(left, right, top, bottom) {
    left = left === VOID ? 0.0 : left;
    right = right === VOID ? 0.0 : right;
    top = top === VOID ? 0.0 : top;
    bottom = bottom === VOID ? 0.0 : bottom;
    this.ph5_1 = left;
    this.qh5_1 = right;
    this.rh5_1 = top;
    this.sh5_1 = bottom;
  }
  protoOf(Padding).toString = function () {
    return 'Padding(left=' + this.ph5_1 + ', right=' + this.qh5_1 + ', top=' + this.rh5_1 + ', bottom=' + this.sh5_1 + ')';
  };
  protoOf(Padding).hashCode = function () {
    var result = getNumberHashCode(this.ph5_1);
    result = imul(result, 31) + getNumberHashCode(this.qh5_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.rh5_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.sh5_1) | 0;
    return result;
  };
  protoOf(Padding).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Padding))
      return false;
    var tmp0_other_with_cast = other instanceof Padding ? other : THROW_CCE();
    if (!equals(this.ph5_1, tmp0_other_with_cast.ph5_1))
      return false;
    if (!equals(this.qh5_1, tmp0_other_with_cast.qh5_1))
      return false;
    if (!equals(this.rh5_1, tmp0_other_with_cast.rh5_1))
      return false;
    if (!equals(this.sh5_1, tmp0_other_with_cast.sh5_1))
      return false;
    return true;
  };
  function HorizontalAlignment(type) {
    type = type === VOID ? HorizontalAlignmentType_LEFT_getInstance() : type;
    this.oh5_1 = type;
  }
  protoOf(HorizontalAlignment).toString = function () {
    return 'HorizontalAlignment(type=' + this.oh5_1 + ')';
  };
  protoOf(HorizontalAlignment).hashCode = function () {
    return this.oh5_1.hashCode();
  };
  protoOf(HorizontalAlignment).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof HorizontalAlignment))
      return false;
    var tmp0_other_with_cast = other instanceof HorizontalAlignment ? other : THROW_CCE();
    if (!this.oh5_1.equals(tmp0_other_with_cast.oh5_1))
      return false;
    return true;
  };
  var HorizontalAlignmentType_LEFT_instance;
  var HorizontalAlignmentType_CENTER_instance;
  var HorizontalAlignmentType_entriesInitialized;
  function HorizontalAlignmentType_initEntries() {
    if (HorizontalAlignmentType_entriesInitialized)
      return Unit_getInstance();
    HorizontalAlignmentType_entriesInitialized = true;
    HorizontalAlignmentType_LEFT_instance = new HorizontalAlignmentType('LEFT', 0);
    HorizontalAlignmentType_CENTER_instance = new HorizontalAlignmentType('CENTER', 1);
  }
  function HorizontalAlignmentType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function HorizontalAlignmentType_LEFT_getInstance() {
    HorizontalAlignmentType_initEntries();
    return HorizontalAlignmentType_LEFT_instance;
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Column;
  _.$_$.b = Padding;
  _.$_$.c = SolidBackgroundColor;
  _.$_$.d = SpacingBetween;
  _.$_$.e = Companion_getInstance_1;
  _.$_$.f = Companion_getInstance_0;
  //endregion
  return _;
}));
