(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './korge-root-kmem.js', './korge-root-kds.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./korge-root-kmem.js'), require('./korge-root-kds.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korma'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-korma'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korma'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'korge-root-korma'.");
    }
    if (typeof this['korge-root-kds'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korma'. Its dependency 'korge-root-kds' was not found. Please, check whether 'korge-root-kds' is loaded prior to 'korge-root-korma'.");
    }
    root['korge-root-korma'] = factory(typeof this['korge-root-korma'] === 'undefined' ? {} : this['korge-root-korma'], this['kotlin-kotlin-stdlib-js-ir'], this['korge-root-kmem'], this['korge-root-kds']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korlibs_kmem_kmem, kotlin_com_soywiz_korlibs_kds_kds) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var hypot = Math.hypot;
  var VOID = kotlin_kotlin.$_$.mj;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var round = kotlin_kotlin.$_$.wd;
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var objectMeta = kotlin_kotlin.$_$.md;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var equals = kotlin_kotlin.$_$.cc;
  var classMeta = kotlin_kotlin.$_$.zb;
  var compareTo = kotlin_kotlin.$_$.ac;
  var Comparable = kotlin_kotlin.$_$.nh;
  var lazy = kotlin_kotlin.$_$.cj;
  var isAlmostZero = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.r4;
  var ConcurrentPool = kotlin_com_soywiz_korlibs_kds_kds.$_$.w;
  var get_bf0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q1;
  var get_bf1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.r1;
  var get_bf2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.s1;
  var get_bf3 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.t1;
  var get_bf4 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u1;
  var get_bf5 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v1;
  var bfloat6PackOf = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.x1;
  var isNaN_0 = kotlin_kotlin.$_$.zi;
  var FloatCompanionObject_getInstance = kotlin_kotlin.$_$.j4;
  var Enum = kotlin_kotlin.$_$.rh;
  var get_b0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.n1;
  var get_b1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.o1;
  var get_b2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.p1;
  var get_hf0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.y1;
  var get_hf1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.z1;
  var get_hf2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.a2;
  var get_hf3 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.b2;
  var bfloat3Half4PackOf = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w1;
  var get_PI = kotlin_kotlin.$_$.td;
  var toString = kotlin_kotlin.$_$.sd;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var contentEquals = kotlin_kotlin.$_$.i6;
  var contentHashCode = kotlin_kotlin.$_$.m6;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var Mixin = kotlin_com_soywiz_korlibs_kds_kds.$_$.y;
  var FloatArrayList = kotlin_com_soywiz_korlibs_kds_kds.$_$.h1;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var IntArrayList = kotlin_com_soywiz_korlibs_kds_kds.$_$.m1;
  var toHalf = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.b6;
  var _Half4Pack___init__impl__htjstd = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.f;
  var _Half4Pack___get_x__impl__z3n838 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i;
  var Half__toFloat_impl_solynz = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v;
  var _Half4Pack___get_y__impl__6a0r11 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j;
  var _Half4Pack___get_z__impl__mjlq16 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k;
  var _Half4Pack___get_w__impl__73uctp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.h;
  var Half4Pack = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m1;
  var Half4Pack__hashCode_impl_nkvb08 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.g;
  var coerceIn = kotlin_kotlin.$_$.fe;
  var get_i0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.c2;
  var get_i1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.d2;
  var int2PackOf = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.e2;
  var primitiveArrayConcat = kotlin_kotlin.$_$.f;
  var convertRange = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.o3;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var sort = kotlin_com_soywiz_korlibs_kds_kds.$_$.u2;
  var contentToString = kotlin_kotlin.$_$.n6;
  var until = kotlin_kotlin.$_$.me;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var hashCode = kotlin_kotlin.$_$.lc;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var binarySearch = kotlin_com_soywiz_korlibs_kds_kds.$_$.w1;
  var _BSearchResult___get_index__impl__b5kraz = kotlin_com_soywiz_korlibs_kds_kds.$_$.k;
  var _BSearchResult___get_found__impl__om04iz = kotlin_com_soywiz_korlibs_kds_kds.$_$.j;
  var _BSearchResult___get_nearIndex__impl__s8nq43 = kotlin_com_soywiz_korlibs_kds_kds.$_$.l;
  var last = kotlin_kotlin.$_$.m8;
  var joinToString = kotlin_kotlin.$_$.b8;
  var checkIndexOverflow = kotlin_kotlin.$_$.c6;
  var binarySearch_0 = kotlin_kotlin.$_$.b6;
  var first = kotlin_kotlin.$_$.n7;
  var last_0 = kotlin_kotlin.$_$.l8;
  var getOrNull = kotlin_kotlin.$_$.r7;
  var emptyList = kotlin_kotlin.$_$.e7;
  var listOf = kotlin_kotlin.$_$.p8;
  var getExtraTyped = kotlin_com_soywiz_korlibs_kds_kds.$_$.h2;
  var setExtra = kotlin_com_soywiz_korlibs_kds_kds.$_$.q2;
  var PropertyThis = kotlin_com_soywiz_korlibs_kds_kds.$_$.z;
  var getCyclic = kotlin_com_soywiz_korlibs_kds_kds.$_$.f2;
  var addAll = kotlin_kotlin.$_$.u5;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var clamp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i3;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var clamp_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k3;
  var getCyclic_0 = kotlin_com_soywiz_korlibs_kds_kds.$_$.g2;
  var listOf_0 = kotlin_kotlin.$_$.q8;
  var extendThrowable = kotlin_kotlin.$_$.dc;
  var captureStack = kotlin_kotlin.$_$.tb;
  var FastArrayList_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.b;
  var DoubleCompanionObject_getInstance = kotlin_kotlin.$_$.i4;
  var fastArrayListOf = kotlin_com_soywiz_korlibs_kds_kds.$_$.z1;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var umod = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l6;
  var insert2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j4;
  var insert = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q4;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var swap = kotlin_com_soywiz_korlibs_kds_kds.$_$.v2;
  var rotateRight = kotlin_com_soywiz_korlibs_kds_kds.$_$.p2;
  var SortOps = kotlin_com_soywiz_korlibs_kds_kds.$_$.t1;
  var genericSort = kotlin_com_soywiz_korlibs_kds_kds.$_$.c2;
  var toIntRound = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.h6;
  var _BSearchResult___init__impl__em6zam = kotlin_com_soywiz_korlibs_kds_kds.$_$.i;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var plus = kotlin_kotlin.$_$.a9;
  var distinct = kotlin_kotlin.$_$.b7;
  var toIntArray = kotlin_kotlin.$_$.z9;
  var sortedArray = kotlin_kotlin.$_$.p9;
  var toList = kotlin_kotlin.$_$.ea;
  var Pair = kotlin_kotlin.$_$.yh;
  var get_sign = kotlin_kotlin.$_$.xd;
  var step = kotlin_kotlin.$_$.ke;
  var toIntRound_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i6;
  var FastIntMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.d1;
  var get_size = kotlin_com_soywiz_korlibs_kds_kds.$_$.t2;
  var Pool = kotlin_com_soywiz_korlibs_kds_kds.$_$.o1;
  var Pool_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.g;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var clamp_1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j3;
  var hasExtra = kotlin_com_soywiz_korlibs_kds_kds.$_$.k2;
  var IntArrayList_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.d;
  var FloatArrayList_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.c;
  var isCharSequence = kotlin_kotlin.$_$.sc;
  var trimEnd = kotlin_kotlin.$_$.bh;
  var lastOrNull = kotlin_kotlin.$_$.j8;
  var KMutableProperty1 = kotlin_kotlin.$_$.pe;
  var Property = kotlin_com_soywiz_korlibs_kds_kds.$_$.a1;
  var numberToLong = kotlin_kotlin.$_$.kd;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.t;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.h;
  var lazy_0 = kotlin_kotlin.$_$.bj;
  var replace = kotlin_kotlin.$_$.ag;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Anchor, 'Anchor', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Angle, 'Angle', classMeta, VOID, [Comparable]);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(BoundsBuilder, 'BoundsBuilder', classMeta);
  setMetadataFor(AbstractShape2D, 'AbstractShape2D', classMeta);
  setMetadataFor(Circle, 'Circle', classMeta, AbstractShape2D);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Line, 'Line', classMeta);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(MLine, 'MLine', classMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(MMatrix, 'MMatrix', classMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(MPoint, 'MPoint', classMeta, VOID, [Comparable]);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(MRectangle, 'MRectangle', classMeta);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(Margin, 'Margin', classMeta);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(MarginInt, 'MarginInt', classMeta);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(Matrix, 'Matrix', classMeta);
  setMetadataFor(MatrixType, 'MatrixType', classMeta, Enum);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(MatrixTransform, 'MatrixTransform', classMeta);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(Matrix4, 'Matrix4', classMeta);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(Orientation, 'Orientation', classMeta, Enum);
  function isEmpty() {
    return this.l() === 0;
  }
  function isNotEmpty() {
    return !(this.l() === 0);
  }
  setMetadataFor(IVectorArrayList, 'IVectorArrayList', interfaceMeta);
  function get_dimensions() {
    return 2;
  }
  function get(index, dim) {
    return dim === 0 ? this.v3z(index) : this.u3z(index);
  }
  function get_0(index) {
    return new Vector2(this.v3z(index), this.u3z(index));
  }
  function getComponentList(component, out) {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out[n] = this.a19(n, component);
      }
       while (inductionVariable < last);
    return out;
  }
  function getComponentList$default(component, out, $super) {
    out = out === VOID ? new Float32Array(this.l()) : out;
    return $super === VOID ? this.w3z(component, out) : getComponentList(component, out);
  }
  function get_first() {
    return this.n(0);
  }
  function get_last() {
    return this.n(this.l() - 1 | 0);
  }
  function clone(out) {
    out.c40(this);
    return out;
  }
  function clone$default(out, $super) {
    out = out === VOID ? new PointArrayList(this.l()) : out;
    return $super === VOID ? this.y3z(out) : clone(out);
  }
  setMetadataFor(PointList, 'PointList', interfaceMeta, VOID, [IVectorArrayList]);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor(PointArrayList, 'PointArrayList', classMeta, VOID, [PointList]);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor(PointIntArrayList, 'PointIntArrayList', classMeta);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor(RectCorners, 'RectCorners', classMeta);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor(Rectangle, 'Rectangle', classMeta);
  setMetadataFor(Companion_18, 'Companion', objectMeta);
  setMetadataFor(RectangleInt, 'RectangleInt', classMeta);
  setMetadataFor(RoundRectangle, 'RoundRectangle', classMeta, AbstractShape2D);
  setMetadataFor(Scale, 'Scale', classMeta);
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(ScaleMode, 'ScaleMode', classMeta);
  setMetadataFor(Companion_20, 'Companion', objectMeta);
  setMetadataFor(Size, 'Size', classMeta);
  setMetadataFor(SizeInt, 'SizeInt', classMeta);
  setMetadataFor(Companion_21, 'Companion', objectMeta);
  setMetadataFor(Vector2, 'Vector2', classMeta);
  setMetadataFor(Companion_22, 'Companion', objectMeta);
  setMetadataFor(Vector2Int, 'Vector2Int', classMeta);
  setMetadataFor(Companion_23, 'Companion', objectMeta);
  setMetadataFor(Vector4, 'Vector4', classMeta);
  setMetadataFor(VectorArrayList, 'VectorArrayList', classMeta, VOID, [IVectorArrayList]);
  setMetadataFor(Arc, 'Arc', objectMeta);
  setMetadataFor(ProjectedPoint, 'ProjectedPoint', classMeta);
  setMetadataFor(Extrema, 'Extrema', classMeta);
  setMetadataFor(Companion_24, 'Companion', objectMeta);
  setMetadataFor(Bezier, 'Bezier', classMeta);
  setMetadataFor(ClosestResult, 'ClosestResult', classMeta);
  setMetadataFor(Estimation, 'Estimation', classMeta);
  setMetadataFor(CurveLUT, 'CurveLUT', classMeta);
  setMetadataFor(CurveInfo, 'CurveInfo', classMeta);
  setMetadataFor(Curves, 'Curves', classMeta);
  setMetadataFor(StrokePointsMode, 'StrokePointsMode', classMeta, Enum);
  setMetadataFor(StrokePointsBuilder, 'StrokePointsBuilder', classMeta);
  setMetadataFor(Companion_25, 'Companion', objectMeta);
  setMetadataFor(SubBezier, 'SubBezier', classMeta);
  setMetadataFor(Companion_26, 'Companion', objectMeta);
  setMetadataFor(ImageDoNotFitException, 'ImageDoNotFitException', classMeta, Error);
  setMetadataFor(BinPacker, 'BinPacker', classMeta);
  setMetadataFor(MaxRects, 'MaxRects', classMeta);
  setMetadataFor(Companion_27, 'Companion', objectMeta);
  setMetadataFor(HitTestDirection, 'HitTestDirection', classMeta, Enum);
  setMetadataFor(Convex, 'Convex', objectMeta);
  function x(index) {
    var tmp0_subject = index;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = this.k4b();
        break;
      case 1:
        tmp = this.m4b();
        break;
      case 2:
        tmp = this.o4b();
        break;
      case 3:
        tmp = this.q4b();
        break;
      default:
        FloatCompanionObject_getInstance();
        tmp = NaN;
        break;
    }
    return tmp;
  }
  function y(index) {
    var tmp0_subject = index;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = this.l4b();
        break;
      case 1:
        tmp = this.n4b();
        break;
      case 2:
        tmp = this.p4b();
        break;
      case 3:
        tmp = this.r4b();
        break;
      default:
        FloatCompanionObject_getInstance();
        tmp = NaN;
        break;
    }
    return tmp;
  }
  setMetadataFor(SliceCoords, 'SliceCoords', interfaceMeta);
  setMetadataFor(RectCoords, 'RectCoords', classMeta, VOID, [SliceCoords]);
  function get_frameOffsetX() {
    return this.j4d().t3x_1;
  }
  function get_frameOffsetY() {
    return this.j4d().q3x_1;
  }
  function get_frameWidth() {
    return this.x18() + this.j4d().n3x() | 0;
  }
  function get_frameHeight() {
    return this.y18() + this.j4d().o3x() | 0;
  }
  setMetadataFor(SliceCoordsWithBase, 'SliceCoordsWithBase', interfaceMeta, VOID, [SliceCoords]);
  function get_left() {
    return this.i4d().f3s();
  }
  function get_top() {
    return this.i4d().h3s();
  }
  function get_right() {
    return this.i4d().g3s();
  }
  function get_bottom() {
    return this.i4d().i3s();
  }
  function get_area() {
    return this.i4d().l42();
  }
  setMetadataFor(SliceCoordsWithBaseAndRect, 'SliceCoordsWithBaseAndRect', interfaceMeta, VOID, [SliceCoordsWithBase]);
  setMetadataFor(RectSlice, 'RectSlice', classMeta, VOID, [SliceCoordsWithBaseAndRect]);
  setMetadataFor(Companion_28, 'Companion', objectMeta);
  setMetadataFor(SliceCoordsImpl, 'SliceCoordsImpl', classMeta, VOID, [SliceCoordsWithBase]);
  setMetadataFor(Companion_29, 'Companion', objectMeta);
  setMetadataFor(SliceRotation, 'SliceRotation', classMeta, Enum);
  setMetadataFor(Indices, 'Indices', objectMeta);
  setMetadataFor(Companion_30, 'Companion', objectMeta);
  setMetadataFor(SliceOrientation, 'SliceOrientation', classMeta);
  setMetadataFor(Companion_31, 'Companion', objectMeta);
  setMetadataFor(SortOps_0, 'SortOps', classMeta, SortOps);
  setMetadataFor(Item, 'Item', classMeta);
  setMetadataFor(FSegmentsInt, 'FSegmentsInt', classMeta);
  setMetadataFor(Companion_32, 'Companion', objectMeta);
  setMetadataFor(FTrapezoidsInt, 'FTrapezoidsInt', classMeta);
  setMetadataFor(Companion_33, 'Companion', objectMeta);
  setMetadataFor(SegmentIntToTrapezoidIntList, 'SegmentIntToTrapezoidIntList', objectMeta);
  setMetadataFor(Companion_34, 'Companion', objectMeta);
  setMetadataFor(Companion_35, 'Companion', objectMeta);
  setMetadataFor(MEdge, 'MEdge', classMeta);
  setMetadataFor(Companion_36, 'Companion', objectMeta);
  setMetadataFor(LineCap, 'LineCap', classMeta, Enum);
  setMetadataFor(Companion_37, 'Companion', objectMeta);
  setMetadataFor(LineJoin, 'LineJoin', classMeta, Enum);
  setMetadataFor(LineScaleMode, 'LineScaleMode', classMeta, Enum);
  setMetadataFor(Bucket, 'Bucket', classMeta);
  setMetadataFor(Buckets, 'Buckets', classMeta);
  setMetadataFor(AllBuckets, 'AllBuckets', classMeta);
  setMetadataFor(XWithWind, 'XWithWind', classMeta);
  setMetadataFor(IntArrayListSort, 'IntArrayListSort', objectMeta, SortOps);
  setMetadataFor(RastScale, 'RastScale', classMeta);
  setMetadataFor(PolygonScanline, 'PolygonScanline', classMeta, RastScale);
  setMetadataFor(Companion_38, 'Companion', objectMeta);
  setMetadataFor(StrokeInfo, 'StrokeInfo', classMeta);
  setMetadataFor(EdgePoint, 'EdgePoint', classMeta, Enum);
  setMetadataFor(StrokeToFill, 'StrokeToFill', classMeta);
  function quadTo(c, a) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Companion.quadToCubic' call
    var tmp0_quadToCubic = Companion_getInstance_24();
    var tmp1_quadToCubic = this.z44().p3r_1;
    var tmp2_quadToCubic = this.z44().q3r_1;
    var tmp3_quadToCubic = c.p3r_1;
    var tmp4_quadToCubic = c.q3r_1;
    var tmp5_quadToCubic = a.p3r_1;
    var tmp6_quadToCubic = a.q3r_1;
    // Inline function 'kotlin.contracts.contract' call
    var tmp7__anonymous__b0knam = tmp0_quadToCubic.k46(tmp1_quadToCubic, tmp3_quadToCubic);
    var tmp8__anonymous__65w033 = tmp0_quadToCubic.k46(tmp2_quadToCubic, tmp4_quadToCubic);
    var tmp9__anonymous__1b7cvk = tmp0_quadToCubic.l46(tmp3_quadToCubic, tmp5_quadToCubic);
    var tmp10__anonymous__yfiz50 = tmp0_quadToCubic.l46(tmp4_quadToCubic, tmp6_quadToCubic);
    this.d45(new Vector2(tmp7__anonymous__b0knam, tmp8__anonymous__65w033), new Vector2(tmp9__anonymous__1b7cvk, tmp10__anonymous__yfiz50), new Vector2(tmp5_quadToCubic, tmp6_quadToCubic));
    tmp$ret$0 = Unit_getInstance();
  }
  function line(p0, p1) {
    this.y44(p0);
    this.a45(p1);
  }
  function arcTo(a, c, r) {
    return Arc_getInstance().x44(this, a, c, r);
  }
  function isEmpty_0() {
    return this.y4k() === 0;
  }
  function isNotEmpty_0() {
    return !(this.y4k() === 0);
  }
  function rect(rect) {
    return this.e4l(rect.z3r_1, rect.a3s_1, rect.b3s_1, rect.c3s_1);
  }
  function rect_0(pos, size) {
    this.e4l(pos.p3r_1, pos.q3r_1, size.w40_1, size.x40_1);
  }
  function rect_1(x, y, width, height) {
    this.y44(Vector2_init_$Create$(x, y));
    this.a45(Vector2_init_$Create$(x + width, y));
    this.a45(Vector2_init_$Create$(x + width, y + height));
    this.a45(Vector2_init_$Create$(x, y + height));
    this.n1q();
  }
  function rect_2(x, y, width, height) {
    return this.d4l(x, y, width, height);
  }
  function rectHole(x, y, width, height) {
    this.y44(Vector2_init_$Create$(x, y));
    this.a45(Vector2_init_$Create$(x, y + height));
    this.a45(Vector2_init_$Create$(x + width, y + height));
    this.a45(Vector2_init_$Create$(x + width, y));
    this.n1q();
  }
  function roundRect(x, y, w, h, rtl, rtr, rbr, rbl) {
    if (((rtl === 0.0 ? rtr === 0.0 : false) ? rbr === 0.0 : false) ? rbl === 0.0 : false) {
      this.d4l(x, y, w, h);
    } else {
      this.y44(Vector2_init_$Create$(x + rtl, y));
      this.a4l(Vector2_init_$Create$(x + w, y), Vector2_init_$Create$(x + w, y + h), rtr);
      this.a4l(Vector2_init_$Create$(x + w, y + h), Vector2_init_$Create$(x, y + h), rbr);
      this.a4l(Vector2_init_$Create$(x, y + h), Vector2_init_$Create$(x, y), rbl);
      this.a4l(Vector2_init_$Create$(x, y), Vector2_init_$Create$(x + w, y), rtl);
      this.n1q();
    }
  }
  function roundRect_0(rect) {
    var r = rect.o42_1;
    var c = rect.p42_1;
    this.g4l(r.o3t(), r.p3t(), r.c41(), r.d41(), _RectCorners___get_topLeft__impl__yn4qy0(c), _RectCorners___get_topRight__impl__okc9ql(c), _RectCorners___get_bottomLeft__impl__ffl7d4(c), _RectCorners___get_bottomRight__impl__j7ud1v(c));
  }
  function rectHole_0(x, y, width, height) {
    return this.f4l(x, y, width, height);
  }
  function arc(center, r, start, end, counterclockwise) {
    return Arc_getInstance().c45(this, center, r, start, end, counterclockwise);
  }
  function arc$default(center, r, start, end, counterclockwise, $super) {
    counterclockwise = counterclockwise === VOID ? false : counterclockwise;
    var tmp;
    if ($super === VOID) {
      this.h4l(center, r, start, end, counterclockwise);
      tmp = Unit_getInstance();
    } else {
      arc(center, r, start, end, counterclockwise);
      tmp = Unit_getInstance();
    }
    return tmp;
  }
  function circle(center, radius) {
    return this.i4l(center, radius, Companion_getInstance_0().g3r_1, Companion_getInstance_0().k3r_1);
  }
  function rMoveTo(delta) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp0_plus = this.z44();
    tmp$ret$0 = new Vector2(tmp0_plus.p3r_1 + delta.p3r_1, tmp0_plus.q3r_1 + delta.q3r_1);
    return this.y44(tmp$ret$0);
  }
  function rLineTo(delta) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp0_plus = this.z44();
    tmp$ret$0 = new Vector2(tmp0_plus.p3r_1 + delta.p3r_1, tmp0_plus.q3r_1 + delta.q3r_1);
    return this.a45(tmp$ret$0);
  }
  function transformed(m) {
    var im = Matrix__inverted_impl_3pqbyv(m);
    var parent = this;
    return new VectorBuilder$transformed$1(parent, im, m);
  }
  function transformed_0(m, block) {
    return block(this.a42(m));
  }
  setMetadataFor(VectorBuilder, 'VectorBuilder', interfaceMeta);
  setMetadataFor(VectorBuilder$transformed$1, VOID, classMeta, VOID, [VectorBuilder]);
  setMetadataFor(Companion_39, 'Companion', objectMeta);
  function close() {
    return Unit_getInstance();
  }
  function moveTo(p) {
    return Unit_getInstance();
  }
  function lineTo(p) {
    return Unit_getInstance();
  }
  function quadTo_0(c, a) {
    return Unit_getInstance();
  }
  function cubicTo(c1, c2, a) {
    return Unit_getInstance();
  }
  setMetadataFor(Visitor, 'Visitor', interfaceMeta);
  setMetadataFor(Command, 'Command', objectMeta);
  setMetadataFor(VectorPath, 'VectorPath', classMeta, AbstractShape2D, [AbstractShape2D, VectorBuilder]);
  setMetadataFor(VectorPathTrapezoids, 'VectorPathTrapezoids', classMeta);
  setMetadataFor(with$1, VOID, classMeta, VOID, [Visitor]);
  setMetadataFor(Companion_40, 'Companion', objectMeta);
  setMetadataFor(Winding, 'Winding', classMeta, Enum);
  setMetadataFor(Companion_41, 'Companion', objectMeta);
  function invoke(it) {
    return this.w4m(it);
  }
  setMetadataFor(Easing, 'Easing', interfaceMeta);
  setMetadataFor(EasingCubic, 'EasingCubic', classMeta, VOID, [Easing]);
  setMetadataFor(Easings, 'Easings', classMeta, Enum, [Enum, Easing]);
  setMetadataFor(Easings$SMOOTH, 'SMOOTH', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_ELASTIC, 'EASE_IN_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_ELASTIC, 'EASE_OUT_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_BOUNCE, 'EASE_OUT_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$LINEAR, 'LINEAR', classMeta, Easings);
  setMetadataFor(Easings$EASE, 'EASE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN, 'EASE_IN', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT, 'EASE_OUT', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT, 'EASE_IN_OUT', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OLD, 'EASE_IN_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_OLD, 'EASE_OUT_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_OLD, 'EASE_IN_OUT_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_OLD, 'EASE_OUT_IN_OLD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_BACK, 'EASE_IN_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_BACK, 'EASE_OUT_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_BACK, 'EASE_IN_OUT_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_BACK, 'EASE_OUT_IN_BACK', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_ELASTIC, 'EASE_IN_OUT_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_ELASTIC, 'EASE_OUT_IN_ELASTIC', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_BOUNCE, 'EASE_IN_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_BOUNCE, 'EASE_IN_OUT_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_IN_BOUNCE, 'EASE_OUT_IN_BOUNCE', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_QUAD, 'EASE_IN_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_OUT_QUAD, 'EASE_OUT_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_IN_OUT_QUAD, 'EASE_IN_OUT_QUAD', classMeta, Easings);
  setMetadataFor(Easings$EASE_SINE, 'EASE_SINE', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_START, 'EASE_CLAMP_START', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_END, 'EASE_CLAMP_END', classMeta, Easings);
  setMetadataFor(Easings$EASE_CLAMP_MIDDLE, 'EASE_CLAMP_MIDDLE', classMeta, Easings);
  setMetadataFor(Companion_42, 'Companion', objectMeta);
  setMetadataFor(Ratio, 'Ratio', classMeta, VOID, [Comparable]);
  setMetadataFor(IntSegmentSet, 'IntSegmentSet', classMeta);
  //endregion
  var MINUS_ZERO_D;
  var MINUS_ZERO_F;
  function isAlmostEquals(_this__u8e3s4, other, epsilon) {
    epsilon = epsilon === VOID ? 1.0E-5 : epsilon;
    var tmp$ret$0;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp0__get_absoluteValue__nukmtt = _this__u8e3s4 - other;
    tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
    return tmp$ret$0 < epsilon;
  }
  function roundDecimalPlaces(_this__u8e3s4, places) {
    if (places < 0)
      return _this__u8e3s4;
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    var tmp0_pow = places;
    tmp$ret$0 = Math.pow(10.0, tmp0_pow);
    var placesFactor = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.round' call
    var tmp1_round = _this__u8e3s4 * placesFactor;
    tmp$ret$1 = round(tmp1_round);
    return tmp$ret$1 / placesFactor;
  }
  function roundDecimalPlaces_0(_this__u8e3s4, places) {
    if (places < 0)
      return _this__u8e3s4;
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    var tmp0_pow = places;
    tmp$ret$0 = Math.pow(10.0, tmp0_pow);
    var placesFactor = tmp$ret$0;
    return round(_this__u8e3s4 * placesFactor) / placesFactor;
  }
  function isAlmostZero_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.abs' call
    tmp$ret$0 = Math.abs(_this__u8e3s4);
    return tmp$ret$0 <= 1.0E-6;
  }
  function min(a, b, c, d) {
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    tmp$ret$0 = Math.min(a, b);
    var tmp0_min = tmp$ret$0;
    tmp$ret$1 = Math.min(tmp0_min, c);
    var tmp1_min = tmp$ret$1;
    tmp$ret$2 = Math.min(tmp1_min, d);
    return tmp$ret$2;
  }
  function max(a, b, c, d) {
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    tmp$ret$0 = Math.max(a, b);
    var tmp0_max = tmp$ret$0;
    tmp$ret$1 = Math.max(tmp0_max, c);
    var tmp1_max = tmp$ret$1;
    tmp$ret$2 = Math.max(tmp1_max, d);
    return tmp$ret$2;
  }
  function normalizeZero(_this__u8e3s4) {
    return _this__u8e3s4 === MINUS_ZERO_F ? 0.0 : _this__u8e3s4;
  }
  function isAlmostEquals_0(_this__u8e3s4, other, epsilon) {
    epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
    var tmp$ret$0;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp0__get_absoluteValue__nukmtt = _this__u8e3s4 - other;
    tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
    return tmp$ret$0 < epsilon;
  }
  function min_0(a, b, c, d) {
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    tmp$ret$0 = Math.min(a, b);
    var tmp0_min = tmp$ret$0;
    tmp$ret$1 = Math.min(tmp0_min, c);
    var tmp1_min = tmp$ret$1;
    tmp$ret$2 = Math.min(tmp1_min, d);
    return tmp$ret$2;
  }
  function max_0(a, b, c, d) {
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    tmp$ret$0 = Math.max(a, b);
    var tmp0_max = tmp$ret$0;
    tmp$ret$1 = Math.max(tmp0_max, c);
    var tmp1_max = tmp$ret$1;
    tmp$ret$2 = Math.max(tmp1_max, d);
    return tmp$ret$2;
  }
  function min_1(a, b, c, d, e) {
    var tmp$ret$3;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    tmp$ret$0 = Math.min(a, b);
    var tmp0_min = tmp$ret$0;
    tmp$ret$1 = Math.min(tmp0_min, c);
    var tmp1_min = tmp$ret$1;
    tmp$ret$2 = Math.min(tmp1_min, d);
    var tmp2_min = tmp$ret$2;
    tmp$ret$3 = Math.min(tmp2_min, e);
    return tmp$ret$3;
  }
  function max_1(a, b, c, d, e) {
    var tmp$ret$3;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    tmp$ret$0 = Math.max(a, b);
    var tmp0_max = tmp$ret$0;
    tmp$ret$1 = Math.max(tmp0_max, c);
    var tmp1_max = tmp$ret$1;
    tmp$ret$2 = Math.max(tmp1_max, d);
    var tmp2_max = tmp$ret$2;
    tmp$ret$3 = Math.max(tmp2_max, e);
    return tmp$ret$3;
  }
  function nextMultipleOf(_this__u8e3s4, multiple) {
    return isMultipleOf(_this__u8e3s4, multiple) ? _this__u8e3s4 : imul((_this__u8e3s4 / multiple | 0) + 1 | 0, multiple);
  }
  function isMultipleOf(_this__u8e3s4, multiple) {
    return multiple === 0 ? true : (_this__u8e3s4 % multiple | 0) === 0;
  }
  function Anchor_init_$Init$(sx, sy, $this) {
    Anchor.call($this, sx, sy);
    return $this;
  }
  function Anchor_init_$Create$(sx, sy) {
    return Anchor_init_$Init$(sx, sy, objectCreate(protoOf(Anchor)));
  }
  function Companion() {
    Companion_instance = this;
    this.k3q_1 = new Anchor(0.0, 0.0);
    this.l3q_1 = new Anchor(0.5, 0.0);
    this.m3q_1 = new Anchor(1.0, 0.0);
    this.n3q_1 = new Anchor(0.0, 0.5);
    this.o3q_1 = new Anchor(0.5, 0.5);
    this.p3q_1 = new Anchor(1.0, 0.5);
    this.q3q_1 = new Anchor(0.0, 1.0);
    this.r3q_1 = new Anchor(0.5, 1.0);
    this.s3q_1 = new Anchor(1.0, 1.0);
  }
  protoOf(Companion).t3q = function () {
    return this.l3q_1;
  };
  protoOf(Companion).u3q = function () {
    return this.n3q_1;
  };
  protoOf(Companion).v3q = function () {
    return this.p3q_1;
  };
  protoOf(Companion).w3q = function () {
    return this.r3q_1;
  };
  protoOf(Companion).x3q = function () {
    return this.o3q_1;
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function Anchor(sx, sy) {
    Companion_getInstance();
    this.y3q_1 = sx;
    this.z3q_1 = sy;
  }
  protoOf(Anchor).a3r = function () {
    return this.y3q_1;
  };
  protoOf(Anchor).b3r = function () {
    return this.z3q_1;
  };
  protoOf(Anchor).c3r = function () {
    return this.y3q_1;
  };
  protoOf(Anchor).d3r = function () {
    return this.z3q_1;
  };
  protoOf(Anchor).toString = function () {
    return 'Anchor(sx=' + this.y3q_1 + ', sy=' + this.z3q_1 + ')';
  };
  protoOf(Anchor).hashCode = function () {
    var result = getNumberHashCode(this.y3q_1);
    result = imul(result, 31) + getNumberHashCode(this.z3q_1) | 0;
    return result;
  };
  protoOf(Anchor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Anchor))
      return false;
    var tmp0_other_with_cast = other instanceof Anchor ? other : THROW_CCE();
    if (!equals(this.y3q_1, tmp0_other_with_cast.y3q_1))
      return false;
    if (!equals(this.z3q_1, tmp0_other_with_cast.z3q_1))
      return false;
    return true;
  };
  function get_PI2() {
    return PI2;
  }
  var PI2;
  function _Angle___init__impl__g23q1m(ratioF) {
    return ratioF;
  }
  function _Angle___get_ratioF__impl__m1m0g8($this) {
    return $this;
  }
  function _Angle___get_ratio__impl__ap3on4($this) {
    return _Angle___get_ratioF__impl__m1m0g8($this);
  }
  function _Angle___get_ratioD__impl__8nqwli($this) {
    return _Angle___get_ratioF__impl__m1m0g8($this);
  }
  function _Angle___get_radians__impl__n00yt5($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    var tmp0_ratioToRadians = Companion_getInstance_0();
    var tmp1_ratioToRadians = _Angle___get_ratioF__impl__m1m0g8($this);
    tmp$ret$0 = tmp1_ratioToRadians * 6.2831855;
    return tmp$ret$0;
  }
  function _Angle___get_radiansD__impl__thfj8f($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.ratioToRadians' call
    var tmp0_ratioToRadians = Companion_getInstance_0();
    var tmp1_ratioToRadians = _Angle___get_ratioD__impl__8nqwli($this);
    tmp$ret$0 = tmp1_ratioToRadians * 6.283185307179586;
    return tmp$ret$0;
  }
  function _Angle___get_degreesD__impl__9qro6($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.ratioToDegrees' call
    var tmp0_ratioToDegrees = Companion_getInstance_0();
    var tmp1_ratioToDegrees = _Angle___get_ratioD__impl__8nqwli($this);
    tmp$ret$0 = tmp1_ratioToDegrees * 360.0;
    return tmp$ret$0;
  }
  function _Angle___get_cosineD__impl__kykfkw($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.cos' call
    var tmp0_cos = _Angle___get_radiansD__impl__thfj8f($this);
    tmp$ret$0 = Math.cos(tmp0_cos);
    return tmp$ret$0;
  }
  function _Angle___get_sineD__impl__2ymbp0($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sin' call
    var tmp0_sin = _Angle___get_radiansD__impl__thfj8f($this);
    tmp$ret$0 = Math.sin(tmp0_sin);
    return tmp$ret$0;
  }
  function _Angle___get_tangentD__impl__vwxar8($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.tan' call
    var tmp0_tan = _Angle___get_radiansD__impl__thfj8f($this);
    tmp$ret$0 = Math.tan(tmp0_tan);
    return tmp$ret$0;
  }
  function _Angle___get_cosineF__impl__ycfjfm($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.cos' call
    var tmp0_cos = _Angle___get_radians__impl__n00yt5($this);
    tmp$ret$0 = Math.cos(tmp0_cos);
    return tmp$ret$0;
  }
  function _Angle___get_sineF__impl__af8s5q($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sin' call
    var tmp0_sin = _Angle___get_radians__impl__n00yt5($this);
    tmp$ret$0 = Math.sin(tmp0_sin);
    return tmp$ret$0;
  }
  function Angle__cosine_impl_bgvzun($this, up) {
    return Angle__cosineF_impl_aj2or7($this, up);
  }
  function Angle__sine_impl_3l11xv($this, up) {
    return Angle__sineF_impl_t5qbx5($this, up);
  }
  function Angle__cosineD_impl_9kzfyt($this, up) {
    return _Angle___get_cosineD__impl__kykfkw(adjustFromUp($this, up));
  }
  function Angle__sineD_impl_u3tkpj($this, up) {
    return _Angle___get_sineD__impl__2ymbp0(adjustFromUp($this, up));
  }
  function Angle__cosineF_impl_aj2or7($this, up) {
    return _Angle___get_cosineF__impl__ycfjfm(adjustFromUp($this, up));
  }
  function Angle__sineF_impl_t5qbx5($this, up) {
    return _Angle___get_sineF__impl__af8s5q(adjustFromUp($this, up));
  }
  function _Angle___get_absoluteValue__impl__4qoakv($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp1_fromRatio = Companion_getInstance_0();
    var tmp$ret$0;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp0__get_absoluteValue__nukmtt = _Angle___get_ratioF__impl__m1m0g8($this);
    tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
    var tmp2_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp2_fromRatio);
    return tmp$ret$1;
  }
  function Angle__div_impl_ut2btd($this, scale) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = _Angle___get_ratioF__impl__m1m0g8($this) / scale;
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__times_impl_v3s9y2($this, scale) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = _Angle___get_ratioF__impl__m1m0g8($this) * scale;
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__umod_impl_4wilf7($this, angle) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = umod_0(_Angle___get_ratioF__impl__m1m0g8($this), _Angle___get_ratioF__impl__m1m0g8(angle));
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__div_impl_ut2btd_0($this, other) {
    return _Angle___get_ratioF__impl__m1m0g8($this) / _Angle___get_ratioF__impl__m1m0g8(other);
  }
  function Angle__plus_impl_26v37k($this, other) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = _Angle___get_ratioF__impl__m1m0g8($this) + _Angle___get_ratioF__impl__m1m0g8(other);
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__minus_impl_xeau7k($this, other) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = _Angle___get_ratioF__impl__m1m0g8($this) - _Angle___get_ratioF__impl__m1m0g8(other);
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__unaryMinus_impl_7901fr($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = -_Angle___get_ratioF__impl__m1m0g8($this);
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__unaryPlus_impl_eyi5pl($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = +_Angle___get_ratioF__impl__m1m0g8($this);
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__inBetween_impl_ojsmql($this, range) {
    return Angle__inBetween_impl_ojsmql_0($this, range.u().e3r_1, range.v().e3r_1, true);
  }
  function Angle__inBetween_impl_ojsmql_0($this, min, max, inclusive) {
    var nthis = _Angle___get_normalized__impl__9s30ys($this);
    var nmin = _Angle___get_normalized__impl__9s30ys(min);
    var nmax = _Angle___get_normalized__impl__9s30ys(max);
    return Angle__compareTo_impl_a0hfds(nmin, nmax) > 0 ? Angle__compareTo_impl_a0hfds(nthis, nmin) >= 0 ? true : inclusive ? Angle__compareTo_impl_a0hfds(nthis, nmax) <= 0 : Angle__compareTo_impl_a0hfds(nthis, nmax) < 0 : Angle__compareTo_impl_a0hfds(nthis, nmin) >= 0 ? inclusive ? Angle__compareTo_impl_a0hfds(nthis, nmax) <= 0 : Angle__compareTo_impl_a0hfds(nthis, nmax) < 0 : false;
  }
  function _Angle___get_normalized__impl__9s30ys($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = umod_0(_Angle___get_ratioF__impl__m1m0g8($this), 1.0);
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function Angle__compareTo_impl_a0hfds($this, other) {
    return compareTo(_Angle___get_ratio__impl__ap3on4($this), _Angle___get_ratio__impl__ap3on4(other));
  }
  function Angle__compareTo_impl_a0hfds_0($this, other) {
    var tmp = $this.e3r_1;
    return Angle__compareTo_impl_a0hfds(tmp, other instanceof Angle ? other.e3r_1 : THROW_CCE());
  }
  function Angle__toString_impl_786qky($this) {
    return get_niceStr_0(roundDecimalPlaces_0(_Angle___get_degreesD__impl__9qro6($this), 2)) + '.degrees';
  }
  function Companion_0() {
    Companion_instance_0 = this;
    this.f3r_1 = _Angle___init__impl__g23q1m(1.0E-5);
    this.g3r_1 = _Angle___init__impl__g23q1m(0.0);
    this.h3r_1 = _Angle___init__impl__g23q1m(0.25);
    this.i3r_1 = _Angle___init__impl__g23q1m(0.5);
    this.j3r_1 = _Angle___init__impl__g23q1m(0.75);
    this.k3r_1 = _Angle___init__impl__g23q1m(1.0);
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function Angle__hashCode_impl_szcndt($this) {
    return getNumberHashCode($this);
  }
  function Angle__equals_impl_zcf5mt($this, other) {
    if (!(other instanceof Angle))
      return false;
    var tmp0_other_with_cast = other instanceof Angle ? other.e3r_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Angle(ratioF) {
    Companion_getInstance_0();
    this.e3r_1 = ratioF;
  }
  protoOf(Angle).l3r = function (other) {
    return Angle__compareTo_impl_a0hfds(this.e3r_1, other);
  };
  protoOf(Angle).fc = function (other) {
    return Angle__compareTo_impl_a0hfds_0(this, other);
  };
  protoOf(Angle).toString = function () {
    return Angle__toString_impl_786qky(this.e3r_1);
  };
  protoOf(Angle).hashCode = function () {
    return Angle__hashCode_impl_szcndt(this.e3r_1);
  };
  protoOf(Angle).equals = function (other) {
    return Angle__equals_impl_zcf5mt(this.e3r_1, other);
  };
  function adjustFromUp(_this__u8e3s4, up) {
    Companion_getInstance_13().o3r(up);
    return up.q3r_1 > 0 ? _this__u8e3s4 : Angle__unaryMinus_impl_7901fr(_this__u8e3s4);
  }
  function get_radians(_this__u8e3s4) {
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp1_fromRadians = Companion_getInstance_0();
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$0 = _this__u8e3s4 / 6.283185307179586;
    var tmp0_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp0_fromRatio);
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function get_radians_0(_this__u8e3s4) {
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp2_fromRadians = Companion_getInstance_0();
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp1_fromRadians = _this__u8e3s4;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$0 = tmp1_fromRadians / 6.283185307179586;
    var tmp0_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp0_fromRatio);
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    return tmp$ret$3;
  }
  function Angle_between(x0, y0, x1, y1, up) {
    up = up === VOID ? Companion_getInstance_21().v3r_1 : up;
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var tmp2_atan2 = Companion_getInstance_0();
    var tmp3_atan2 = y1 - y0;
    var tmp4_atan2 = x1 - x0;
    var tmp5_atan2 = Companion_getInstance_21().v3r_1;
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    tmp$ret$0 = Math.atan2(tmp3_atan2, tmp4_atan2);
    var tmp1_fromRadians = tmp$ret$0;
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$1 = tmp1_fromRadians / 6.283185307179586;
    var tmp0_fromRatio = tmp$ret$1;
    tmp$ret$2 = _Angle___init__impl__g23q1m(tmp0_fromRatio);
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = adjustFromUp(tmp$ret$3, tmp5_atan2);
    var angle = tmp$ret$4;
    return adjustFromUp(Angle__compareTo_impl_a0hfds(angle, Companion_getInstance_0().g3r_1) < 0 ? Angle__plus_impl_26v37k(angle, Companion_getInstance_0().k3r_1) : angle, up);
  }
  function get_radians_1(_this__u8e3s4) {
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp2_fromRadians = Companion_getInstance_0();
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp1_fromRadians = _this__u8e3s4;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$0 = tmp1_fromRadians / 6.283185307179586;
    var tmp0_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp0_fromRatio);
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    return tmp$ret$3;
  }
  function interpolateAngleDenormalized(_this__u8e3s4, l, r) {
    return interpolateAngle(_this__u8e3s4, l, r, false);
  }
  function get_degrees(_this__u8e3s4) {
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Companion.fromDegrees' call
    var tmp2_fromDegrees = Companion_getInstance_0();
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromDegrees' call
    var tmp1_fromDegrees = _this__u8e3s4;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.degreesToRatio' call
    tmp$ret$0 = tmp1_fromDegrees / 360.0;
    var tmp0_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp0_fromRatio);
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    return tmp$ret$3;
  }
  function interpolateAngle(_this__u8e3s4, l, r, minimizeAngle) {
    return _interpolateAngleAny(_this__u8e3s4, l, r, minimizeAngle);
  }
  function _interpolateAngleAny(ratio, l, r, minimizeAngle) {
    minimizeAngle = minimizeAngle === VOID ? true : minimizeAngle;
    if (!minimizeAngle) {
      var tmp$ret$0;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp0_fromRatio = Companion_getInstance_0();
      var tmp1_fromRatio = interpolate_0(ratio, _Angle___get_ratio__impl__ap3on4(l), _Angle___get_ratio__impl__ap3on4(r));
      tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
      return tmp$ret$0;
    }
    var ln = _Angle___get_normalized__impl__9s30ys(l);
    var rn = _Angle___get_normalized__impl__9s30ys(r);
    var tmp;
    if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(Angle__minus_impl_xeau7k(rn, ln)), Companion_getInstance_0().i3r_1) <= 0) {
      var tmp$ret$4;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp4_fromRadians = Companion_getInstance_0();
      var tmp5_fromRadians = interpolate_0(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(rn));
      var tmp$ret$3;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp3_fromRadians = tmp5_fromRadians;
      var tmp$ret$2;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
      tmp$ret$1 = tmp3_fromRadians / 6.283185307179586;
      var tmp2_fromRatio = tmp$ret$1;
      tmp$ret$2 = _Angle___init__impl__g23q1m(tmp2_fromRatio);
      tmp$ret$3 = tmp$ret$2;
      tmp$ret$4 = tmp$ret$3;
      tmp = tmp$ret$4;
    } else if (Angle__compareTo_impl_a0hfds(ln, rn) < 0) {
      var tmp$ret$8;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp8_fromRadians = Companion_getInstance_0();
      var tmp9_fromRadians = interpolate_0(ratio, _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(ln, Companion_getInstance_0().k3r_1)), _Angle___get_radians__impl__n00yt5(rn));
      var tmp$ret$7;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp7_fromRadians = tmp9_fromRadians;
      var tmp$ret$6;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp$ret$5;
      // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
      tmp$ret$5 = tmp7_fromRadians / 6.283185307179586;
      var tmp6_fromRatio = tmp$ret$5;
      tmp$ret$6 = _Angle___init__impl__g23q1m(tmp6_fromRatio);
      tmp$ret$7 = tmp$ret$6;
      tmp$ret$8 = tmp$ret$7;
      tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$8);
    } else {
      var tmp$ret$12;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp12_fromRadians = Companion_getInstance_0();
      var tmp13_fromRadians = interpolate_0(ratio, _Angle___get_radians__impl__n00yt5(ln), _Angle___get_radians__impl__n00yt5(Angle__plus_impl_26v37k(rn, Companion_getInstance_0().k3r_1)));
      var tmp$ret$11;
      // Inline function 'korlibs.math.geom.Companion.fromRadians' call
      var tmp11_fromRadians = tmp13_fromRadians;
      var tmp$ret$10;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp$ret$9;
      // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
      tmp$ret$9 = tmp11_fromRadians / 6.283185307179586;
      var tmp10_fromRatio = tmp$ret$9;
      tmp$ret$10 = _Angle___init__impl__g23q1m(tmp10_fromRatio);
      tmp$ret$11 = tmp$ret$10;
      tmp$ret$12 = tmp$ret$11;
      tmp = _Angle___get_normalized__impl__9s30ys(tmp$ret$12);
    }
    return tmp;
  }
  function _BoundsBuilder___init__impl__g6dprh(bounds) {
    return bounds;
  }
  function _BoundsBuilder___get_bounds__impl__s1l1gx($this) {
    return $this;
  }
  function _BoundsBuilder___get_isEmpty__impl__e9t785($this) {
    return _BoundsBuilder___get_bounds__impl__s1l1gx($this).d3s();
  }
  function _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this) {
    return _BoundsBuilder___get_bounds__impl__s1l1gx($this).e3s();
  }
  function _BoundsBuilder___get_xmin__impl__o2sib0($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = _BoundsBuilder___get_bounds__impl__s1l1gx($this).f3s();
    var tmp1_min = _BoundsBuilder___get_bounds__impl__s1l1gx($this).g3s();
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  }
  function _BoundsBuilder___get_xmax__impl__gsz0km($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = _BoundsBuilder___get_bounds__impl__s1l1gx($this).f3s();
    var tmp1_max = _BoundsBuilder___get_bounds__impl__s1l1gx($this).g3s();
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    return tmp$ret$0;
  }
  function _BoundsBuilder___get_ymin__impl__5n2e8j($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = _BoundsBuilder___get_bounds__impl__s1l1gx($this).h3s();
    var tmp1_min = _BoundsBuilder___get_bounds__impl__s1l1gx($this).i3s();
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  }
  function _BoundsBuilder___get_ymax__impl__oia4uz($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = _BoundsBuilder___get_bounds__impl__s1l1gx($this).h3s();
    var tmp1_max = _BoundsBuilder___get_bounds__impl__s1l1gx($this).i3s();
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    return tmp$ret$0;
  }
  function BoundsBuilder__xminOr_impl_k3l1m2($this, default_0) {
    return _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) ? _BoundsBuilder___get_xmin__impl__o2sib0($this) : default_0;
  }
  function _BoundsBuilder___get_hasPoints__impl__6mwi9x($this) {
    return _BoundsBuilder___get_isNotEmpty__impl__w6rbsy($this);
  }
  function Companion_1() {
    Companion_instance_1 = this;
    this.j3s_1 = _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_17().n3s());
  }
  protoOf(Companion_1).o3s = function () {
    return this.j3s_1;
  };
  protoOf(Companion_1).p3s = function (p1, p2, p3, p4) {
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_17().s3s(Companion_getInstance_21().q3s(p1, p2, p3, p4), Companion_getInstance_21().r3s(p1, p2, p3, p4)));
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function BoundsBuilder__plus_impl_cz7f7x($this, p) {
    if (_BoundsBuilder___get_bounds__impl__s1l1gx($this).d3s())
      return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_17().t3s(p, Size_init_$Create$_0(0, 0)));
    return _BoundsBuilder___init__impl__g6dprh(Companion_getInstance_17().s3s(Companion_getInstance_21().v3s(_BoundsBuilder___get_bounds__impl__s1l1gx($this).u3s(), p), Companion_getInstance_21().x3s(_BoundsBuilder___get_bounds__impl__s1l1gx($this).w3s(), p)));
  }
  function BoundsBuilder__plus_impl_cz7f7x_0($this, p) {
    var bb = $this;
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = p.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.BoundsBuilder.plus.<anonymous>' call
        var tmp0__anonymous__q1qw7t = p.n(n);
        bb = BoundsBuilder__plus_impl_cz7f7x(bb, tmp0__anonymous__q1qw7t);
      }
       while (inductionVariable < last);
    return bb;
  }
  function BoundsBuilder__plus_impl_cz7f7x_1($this, rect) {
    if (rect == null)
      return $this;
    if (rect.d3s())
      return $this;
    return BoundsBuilder__plus_impl_cz7f7x(BoundsBuilder__plus_impl_cz7f7x($this, rect.u3s()), rect.w3s());
  }
  function BoundsBuilder__plus_impl_cz7f7x_2($this, rects) {
    var bb = $this;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < rects.l()) {
      // Inline function 'korlibs.math.geom.BoundsBuilder.plus.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = rects.n(tmp0);
      bb = BoundsBuilder__plus_impl_cz7f7x_1(bb, tmp0__anonymous__q1qw7t);
    }
    return bb;
  }
  function BoundsBuilder__boundsOrNull_impl_se9lyg($this) {
    return _BoundsBuilder___get_isEmpty__impl__e9t785($this) ? null : _BoundsBuilder___get_bounds__impl__s1l1gx($this);
  }
  function BoundsBuilder__toString_impl_fmvxh7($this) {
    return 'BoundsBuilder(bounds=' + $this + ')';
  }
  function BoundsBuilder__hashCode_impl_649zbo($this) {
    return $this.hashCode();
  }
  function BoundsBuilder__equals_impl_jngfjs($this, other) {
    if (!(other instanceof BoundsBuilder))
      return false;
    var tmp0_other_with_cast = other instanceof BoundsBuilder ? other.y3s_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function BoundsBuilder(bounds) {
    Companion_getInstance_1();
    this.y3s_1 = bounds;
  }
  protoOf(BoundsBuilder).toString = function () {
    return BoundsBuilder__toString_impl_fmvxh7(this.y3s_1);
  };
  protoOf(BoundsBuilder).hashCode = function () {
    return BoundsBuilder__hashCode_impl_649zbo(this.y3s_1);
  };
  protoOf(BoundsBuilder).equals = function (other) {
    return BoundsBuilder__equals_impl_jngfjs(this.y3s_1, other);
  };
  function Circle$lazyVectorPath$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.shape.buildVectorPath' call
      var tmp0_buildVectorPath = new VectorPath();
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.Circle.lazyVectorPath$delegate.<anonymous>.<anonymous>' call
      tmp0_buildVectorPath.c3t(this$0.z3s_1, this$0.a3t_1);
      tmp$ret$0 = tmp0_buildVectorPath;
      tmp$ret$1 = tmp$ret$0;
      return tmp$ret$1;
    };
  }
  function Circle(center, radius) {
    AbstractShape2D.call(this);
    this.z3s_1 = center;
    this.a3t_1 = radius;
    var tmp = this;
    tmp.b3t_1 = lazy(Circle$lazyVectorPath$delegate$lambda(this));
  }
  protoOf(Circle).d3t = function () {
    return this.a3t_1 * this.a3t_1;
  };
  protoOf(Circle).e3t = function (p) {
    return Companion_getInstance_21().f3t(p, this.z3s_1);
  };
  protoOf(Circle).g3t = function (p) {
    return this.e3t(p) - this.d3t();
  };
  protoOf(Circle).h3t = function (p) {
    return this.e3t(p) + this.d3t();
  };
  protoOf(Circle).toString = function () {
    return 'Circle(center=' + this.z3s_1 + ', radius=' + this.a3t_1 + ')';
  };
  protoOf(Circle).hashCode = function () {
    var result = this.z3s_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.a3t_1) | 0;
    return result;
  };
  protoOf(Circle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Circle))
      return false;
    var tmp0_other_with_cast = other instanceof Circle ? other : THROW_CCE();
    if (!this.z3s_1.equals(tmp0_other_with_cast.z3s_1))
      return false;
    if (!equals(this.a3t_1, tmp0_other_with_cast.a3t_1))
      return false;
    return true;
  };
  function Line_init_$Init$(x0, y0, x1, y1, $this) {
    Line.call($this, Vector2_init_$Create$_0(x0, y0), Vector2_init_$Create$_0(x1, y1));
    return $this;
  }
  function Line_init_$Create$(x0, y0, x1, y1) {
    return Line_init_$Init$(x0, y0, x1, y1, objectCreate(protoOf(Line)));
  }
  function Companion_2() {
    Companion_instance_2 = this;
    this.i3t_1 = new Line(Companion_getInstance_21().r3r_1, Companion_getInstance_21().r3r_1);
    this.j3t_1 = new Line(Companion_getInstance_21().s3r_1, Companion_getInstance_21().s3r_1);
  }
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function Line(a, b) {
    Companion_getInstance_2();
    this.k3t_1 = a;
    this.l3t_1 = b;
  }
  protoOf(Line).m3t = function () {
    return Bezier_init_$Create$(this.k3t_1, this.l3t_1);
  };
  protoOf(Line).toString = function () {
    return 'Line(' + this.k3t_1 + ', ' + this.l3t_1 + ')';
  };
  protoOf(Line).hashCode = function () {
    var result = this.k3t_1.hashCode();
    result = imul(result, 31) + this.l3t_1.hashCode() | 0;
    return result;
  };
  protoOf(Line).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Line))
      return false;
    var tmp0_other_with_cast = other instanceof Line ? other : THROW_CCE();
    if (!this.k3t_1.equals(tmp0_other_with_cast.k3t_1))
      return false;
    if (!this.l3t_1.equals(tmp0_other_with_cast.l3t_1))
      return false;
    return true;
  };
  function MLine_init_$Init$($this) {
    MLine.call($this, Vector2_init_$Create$_1(), Vector2_init_$Create$_1());
    return $this;
  }
  function MLine_init_$Create$() {
    return MLine_init_$Init$(objectCreate(protoOf(MLine)));
  }
  function Companion_3() {
    Companion_instance_3 = this;
  }
  protoOf(Companion_3).n3t = function (point, direction, scale, out) {
    return out.s3t(point.o3t(), point.p3t(), point.p3r_1 + direction.p3r_1 * scale, point.q3r_1 + direction.q3r_1 * scale);
  };
  protoOf(Companion_3).t3t = function (point, direction, scale, out, $super) {
    scale = scale === VOID ? 1.0 : scale;
    out = out === VOID ? MLine_init_$Create$() : out;
    return $super === VOID ? this.n3t(point, direction, scale, out) : $super.n3t.call(this, point, direction, scale, out);
  };
  protoOf(Companion_3).u3t = function (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) {
    var a1 = By - Ay;
    var b1 = Ax - Bx;
    var c1 = a1 * Ax + b1 * Ay;
    var a2 = Dy - Cy;
    var b2 = Cx - Dx;
    var c2 = a2 * Cx + b2 * Cy;
    var determinant = a1 * b2 - a2 * b1;
    if (isAlmostZero(determinant))
      return null;
    var x = (b2 * c1 - b1 * c2) / determinant;
    var y = (a1 * c2 - a2 * c1) / determinant;
    return Vector2_init_$Create$(x, y);
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function MLine(a, b) {
    Companion_getInstance_3();
    this.q3t_1 = a;
    this.r3t_1 = b;
  }
  protoOf(MLine).z19 = function () {
    return new MLine(this.q3t_1, this.r3t_1);
  };
  protoOf(MLine).s3t = function (x0, y0, x1, y1) {
    this.q3t_1 = Vector2_init_$Create$(x0, y0);
    this.r3t_1 = Vector2_init_$Create$(x1, y1);
    return this;
  };
  protoOf(MLine).v3t = function (scale) {
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    var tmp2_minus = tmp0_this.q3t_1;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp1_times = this.w3t();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp0_times = scale;
    tmp$ret$0 = new Vector2(tmp1_times.p3r_1 * tmp0_times, tmp1_times.q3r_1 * tmp0_times);
    tmp$ret$1 = tmp$ret$0;
    var tmp3_minus = tmp$ret$1;
    tmp$ret$2 = new Vector2(tmp2_minus.p3r_1 - tmp3_minus.p3r_1, tmp2_minus.q3r_1 - tmp3_minus.q3r_1);
    tmp.q3t_1 = tmp$ret$2;
    var tmp1_this = this;
    var tmp_0 = tmp1_this;
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    var tmp6_minus = tmp1_this.r3t_1;
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp5_times = this.w3t();
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp4_times = scale;
    tmp$ret$3 = new Vector2(tmp5_times.p3r_1 * tmp4_times, tmp5_times.q3r_1 * tmp4_times);
    tmp$ret$4 = tmp$ret$3;
    var tmp7_minus = tmp$ret$4;
    tmp$ret$5 = new Vector2(tmp6_minus.p3r_1 - tmp7_minus.p3r_1, tmp6_minus.q3r_1 - tmp7_minus.q3r_1);
    tmp_0.r3t_1 = tmp$ret$5;
    return this;
  };
  protoOf(MLine).x3t = function () {
    return this.q3t_1.o3t();
  };
  protoOf(MLine).y3t = function () {
    return this.q3t_1.p3t();
  };
  protoOf(MLine).z3t = function () {
    return this.r3t_1.o3t();
  };
  protoOf(MLine).a3u = function () {
    return this.r3t_1.p3t();
  };
  protoOf(MLine).w3t = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    var tmp0_minus = this.r3t_1;
    var tmp1_minus = this.q3t_1;
    tmp$ret$0 = new Vector2(tmp0_minus.p3r_1 - tmp1_minus.p3r_1, tmp0_minus.q3r_1 - tmp1_minus.q3r_1);
    return tmp$ret$0;
  };
  protoOf(MLine).toString = function () {
    return 'Line(' + this.q3t_1 + ', ' + this.r3t_1 + ')';
  };
  protoOf(MLine).b3u = function (line) {
    return Companion_getInstance_3().u3t(this.x3t(), this.y3t(), this.z3t(), this.a3u(), line.x3t(), line.y3t(), line.z3t(), line.a3u());
  };
  protoOf(MLine).hashCode = function () {
    var result = this.q3t_1.hashCode();
    result = imul(result, 31) + this.r3t_1.hashCode() | 0;
    return result;
  };
  protoOf(MLine).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MLine))
      return false;
    var tmp0_other_with_cast = other instanceof MLine ? other : THROW_CCE();
    if (!this.q3t_1.equals(tmp0_other_with_cast.q3t_1))
      return false;
    if (!this.r3t_1.equals(tmp0_other_with_cast.r3t_1))
      return false;
    return true;
  };
  function lineIntersectionPoint(_this__u8e3s4, l1, l2) {
    return l1.b3u(l2);
  }
  function projectedPoint(_this__u8e3s4, point) {
    return projectedPoint_0(Companion_getInstance_3(), _this__u8e3s4.q3t_1, _this__u8e3s4.r3t_1, point);
  }
  function projectedPoint_0(_this__u8e3s4, v1, v2, point) {
    return projectedPoint_1(_this__u8e3s4, v1.o3t(), v1.p3t(), v2.o3t(), v2.p3t(), point.o3t(), point.p3t());
  }
  function projectedPoint_1(_this__u8e3s4, v1x, v1y, v2x, v2y, px, py) {
    var e1x = v2x - v1x;
    var e1y = v2y - v1y;
    var e2x = px - v1x;
    var e2y = py - v1y;
    var valDp = Companion_getInstance_6().j3u(e1x, e1y, e2x, e2y);
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    tmp$ret$0 = hypot(e1x, e1y);
    var lenLineE1 = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.hypot' call
    tmp$ret$1 = hypot(e2x, e2y);
    var lenLineE2 = tmp$ret$1;
    if (lenLineE1 === 0.0 ? true : lenLineE2 === 0.0) {
      return Vector2_init_$Create$(px, py);
    }
    var cos = valDp / (lenLineE1 * lenLineE2);
    var projLenOfLine = cos * lenLineE2;
    return Vector2_init_$Create$(v1x + projLenOfLine * e1x / lenLineE1, v1y + projLenOfLine * e1y / lenLineE1);
  }
  function MMatrix$Companion$POOL$lambda(it) {
    it.q3u();
    return Unit_getInstance();
  }
  function MMatrix$Companion$POOL$lambda_0(it) {
    return new MMatrix();
  }
  function Companion_4() {
    Companion_instance_4 = this;
    var tmp = this;
    var tmp_0 = MMatrix$Companion$POOL$lambda;
    tmp.r3u_1 = new ConcurrentPool(tmp_0, VOID, MMatrix$Companion$POOL$lambda_0);
  }
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function MMatrix(a, b, c, d, tx, ty) {
    Companion_getInstance_4();
    a = a === VOID ? 1.0 : a;
    b = b === VOID ? 0.0 : b;
    c = c === VOID ? 0.0 : c;
    d = d === VOID ? 1.0 : d;
    tx = tx === VOID ? 0.0 : tx;
    ty = ty === VOID ? 0.0 : ty;
    this.k3u_1 = a;
    this.l3u_1 = b;
    this.m3u_1 = c;
    this.n3u_1 = d;
    this.o3u_1 = tx;
    this.p3u_1 = ty;
  }
  protoOf(MMatrix).s3u = function () {
    return _Matrix___init__impl__q3kp4w_1(this.k3u_1, this.l3u_1, this.m3u_1, this.n3u_1, this.o3u_1, this.p3u_1);
  };
  protoOf(MMatrix).t3u = function (a, b, c, d, tx, ty) {
    this.k3u_1 = a;
    this.l3u_1 = b;
    this.m3u_1 = c;
    this.n3u_1 = d;
    this.o3u_1 = tx;
    this.p3u_1 = ty;
    return this;
  };
  protoOf(MMatrix).u3u = function (a, b, c, d, tx, ty) {
    return this.t3u(a, b, c, d, tx, ty);
  };
  protoOf(MMatrix).v3u = function (that) {
    return this.u3u(_Matrix___get_a__impl__f7jypo(that), _Matrix___get_b__impl__dm2icj(that), _Matrix___get_c__impl__slf2ke(that), _Matrix___get_d__impl__87eht(that), _Matrix___get_tx__impl__yc1n9(that), _Matrix___get_ty__impl__tryipg(that));
  };
  protoOf(MMatrix).w3u = function (sx, sy) {
    return this.t3u(this.k3u_1 * sx, this.l3u_1 * sx, this.m3u_1 * sy, this.n3u_1 * sy, this.o3u_1, this.p3u_1);
  };
  protoOf(MMatrix).x3u = function (sx, sy) {
    return this.w3u(sx, sy);
  };
  protoOf(MMatrix).y3u = function (sx, sy, $super) {
    sy = sy === VOID ? sx : sy;
    return $super === VOID ? this.x3u(sx, sy) : $super.x3u.call(this, sx, sy);
  };
  protoOf(MMatrix).z3u = function (dx, dy) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.MMatrix.translate.<anonymous>' call
    var tmp0_this = this;
    tmp0_this.o3u_1 = tmp0_this.o3u_1 + dx;
    var tmp1_this = this;
    tmp1_this.p3u_1 = tmp1_this.p3u_1 + dy;
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(MMatrix).a3v = function (dx, dy) {
    return this.z3u(dx, dy);
  };
  protoOf(MMatrix).b3v = function (dx, dy) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.MMatrix.pretranslate.<anonymous>' call
    var tmp0_this = this;
    tmp0_this.o3u_1 = tmp0_this.o3u_1 + (this.k3u_1 * dx + this.m3u_1 * dy);
    var tmp1_this = this;
    tmp1_this.p3u_1 = tmp1_this.p3u_1 + (this.l3u_1 * dx + this.n3u_1 * dy);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(MMatrix).c3v = function (dx, dy) {
    return this.b3v(dx, dy);
  };
  protoOf(MMatrix).q3u = function () {
    return this.t3u(1.0, 0.0, 0.0, 1.0, 0.0, 0.0);
  };
  protoOf(MMatrix).toString = function () {
    return 'Matrix(a=' + this.k3u_1 + ', b=' + this.l3u_1 + ', c=' + this.m3u_1 + ', d=' + this.n3u_1 + ', tx=' + this.o3u_1 + ', ty=' + this.p3u_1 + ')';
  };
  protoOf(MMatrix).hashCode = function () {
    var result = getNumberHashCode(this.k3u_1);
    result = imul(result, 31) + getNumberHashCode(this.l3u_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.m3u_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.n3u_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.o3u_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.p3u_1) | 0;
    return result;
  };
  protoOf(MMatrix).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MMatrix))
      return false;
    var tmp0_other_with_cast = other instanceof MMatrix ? other : THROW_CCE();
    if (!equals(this.k3u_1, tmp0_other_with_cast.k3u_1))
      return false;
    if (!equals(this.l3u_1, tmp0_other_with_cast.l3u_1))
      return false;
    if (!equals(this.m3u_1, tmp0_other_with_cast.m3u_1))
      return false;
    if (!equals(this.n3u_1, tmp0_other_with_cast.n3u_1))
      return false;
    if (!equals(this.o3u_1, tmp0_other_with_cast.o3u_1))
      return false;
    if (!equals(this.p3u_1, tmp0_other_with_cast.p3u_1))
      return false;
    return true;
  };
  function toMatrix4(_this__u8e3s4) {
    if (_Matrix___get_isNIL__impl__n63x5q(_this__u8e3s4))
      return Companion_getInstance_12().t3v_1;
    return Companion_getInstance_12().u3v(_Matrix___get_a__impl__f7jypo(_this__u8e3s4), _Matrix___get_c__impl__slf2ke(_this__u8e3s4), 0.0, _Matrix___get_tx__impl__yc1n9(_this__u8e3s4), _Matrix___get_b__impl__dm2icj(_this__u8e3s4), _Matrix___get_d__impl__87eht(_this__u8e3s4), 0.0, _Matrix___get_ty__impl__tryipg(_this__u8e3s4), 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0);
  }
  function Companion_5() {
    Companion_instance_5 = this;
    this.v3v_1 = 0;
    this.w3v_1 = 1;
    this.x3v_1 = 2;
    this.y3v_1 = 3;
    this.z3v_1 = 4;
    this.a3w_1 = 5;
    this.b3w_1 = 6;
    this.c3w_1 = 7;
    this.d3w_1 = 8;
    this.e3w_1 = 9;
    this.f3w_1 = 10;
    this.g3w_1 = 11;
    this.h3w_1 = 12;
    this.i3w_1 = 13;
    this.j3w_1 = 14;
    this.k3w_1 = 15;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.intArrayOf' call
    var tmp0_intArrayOf = new Int32Array([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
    tmp$ret$0 = tmp0_intArrayOf;
    tmp.l3w_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.intArrayOf' call
    var tmp0_intArrayOf_0 = new Int32Array([0, 4, 8, 12, 1, 5, 9, 13, 2, 6, 10, 14, 3, 7, 11, 15]);
    tmp$ret$1 = tmp0_intArrayOf_0;
    tmp_0.m3w_1 = tmp$ret$1;
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.intArrayOf' call
    var tmp0_intArrayOf_1 = new Int32Array([0, 1, 2, 4, 5, 6, 8, 9, 10]);
    tmp$ret$2 = tmp0_intArrayOf_1;
    tmp_1.n3w_1 = tmp$ret$2;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'kotlin.intArrayOf' call
    var tmp0_intArrayOf_2 = new Int32Array([0, 4, 8, 1, 5, 9, 2, 6, 10]);
    tmp$ret$3 = tmp0_intArrayOf_2;
    tmp_2.o3w_1 = tmp$ret$3;
  }
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function MPoint$Companion$POOL$lambda(it) {
    it.r3w(0.0, 0.0);
    return Unit_getInstance();
  }
  function MPoint$Companion$POOL$lambda_0(it) {
    return Companion_getInstance_6().s3w();
  }
  function Companion_6() {
    Companion_instance_6 = this;
    var tmp = this;
    var tmp_0 = MPoint$Companion$POOL$lambda;
    tmp.c3u_1 = new ConcurrentPool(tmp_0, VOID, MPoint$Companion$POOL$lambda_0);
    this.d3u_1 = new MPoint(0.0, 0.0);
    this.e3u_1 = new MPoint(1.0, 1.0);
    this.f3u_1 = new MPoint(0.0, -1.0);
    this.g3u_1 = new MPoint(0.0, 1.0);
    this.h3u_1 = new MPoint(-1.0, 0.0);
    this.i3u_1 = new MPoint(1.0, 0.0);
  }
  protoOf(Companion_6).s3w = function () {
    return new MPoint(0.0, 0.0);
  };
  protoOf(Companion_6).t3w = function (lx, ly, rx, ry) {
    var ret = compareTo(ly, ry);
    return ret === 0 ? compareTo(lx, rx) : ret;
  };
  protoOf(Companion_6).u3w = function (x1, y1, x2, y2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = x1 - x2;
    var tmp1_hypot = y1 - y2;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Companion_6).v3w = function (x1, y1, x2, y2) {
    return this.u3w(x1, y1, x2, y2);
  };
  protoOf(Companion_6).j3u = function (aX, aY, bX, bY) {
    return aX * bX + aY * bY;
  };
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function MPoint(x, y) {
    Companion_getInstance_6();
    this.p3w_1 = x;
    this.q3w_1 = y;
  }
  protoOf(MPoint).r3w = function (x, y) {
    this.p3w_1 = x;
    this.q3w_1 = y;
    return this;
  };
  protoOf(MPoint).w3w = function (other) {
    return Companion_getInstance_6().t3w(this.p3w_1, this.q3w_1, other.p3w_1, other.q3w_1);
  };
  protoOf(MPoint).fc = function (other) {
    return this.w3w(other instanceof MPoint ? other : THROW_CCE());
  };
  protoOf(MPoint).toString = function () {
    return '(' + get_niceStr_0(this.p3w_1) + ', ' + get_niceStr_0(this.q3w_1) + ')';
  };
  protoOf(MPoint).hashCode = function () {
    var result = getNumberHashCode(this.p3w_1);
    result = imul(result, 31) + getNumberHashCode(this.q3w_1) | 0;
    return result;
  };
  protoOf(MPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MPoint))
      return false;
    var tmp0_other_with_cast = other instanceof MPoint ? other : THROW_CCE();
    if (!equals(this.p3w_1, tmp0_other_with_cast.p3w_1))
      return false;
    if (!equals(this.q3w_1, tmp0_other_with_cast.q3w_1))
      return false;
    return true;
  };
  function MRectangle$Companion$POOL$lambda(it) {
    it.kk();
    return Unit_getInstance();
  }
  function MRectangle$Companion$POOL$lambda_0(it) {
    return Companion_getInstance_7().s3w();
  }
  function Companion_7() {
    Companion_instance_7 = this;
    var tmp = this;
    var tmp_0 = MRectangle$Companion$POOL$lambda;
    tmp.b3x_1 = new ConcurrentPool(tmp_0, VOID, MRectangle$Companion$POOL$lambda_0);
  }
  protoOf(Companion_7).s3w = function () {
    return new MRectangle(0.0, 0.0, 0.0, 0.0);
  };
  protoOf(Companion_7).c3x = function (x, y, width, height) {
    return new MRectangle(x, y, width, height);
  };
  var Companion_instance_7;
  function Companion_getInstance_7() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function MRectangle(x, y, width, height) {
    Companion_getInstance_7();
    this.x3w_1 = x;
    this.y3w_1 = y;
    this.z3w_1 = width;
    this.a3x_1 = height;
  }
  protoOf(MRectangle).f3s = function () {
    return this.x3w_1;
  };
  protoOf(MRectangle).h3s = function () {
    return this.y3w_1;
  };
  protoOf(MRectangle).g3s = function () {
    return this.x3w_1 + this.z3w_1;
  };
  protoOf(MRectangle).i3s = function () {
    return this.y3w_1 + this.a3x_1;
  };
  protoOf(MRectangle).s3t = function (x, y, width, height) {
    this.x3w_1 = x;
    this.y3w_1 = y;
    this.z3w_1 = width;
    this.a3x_1 = height;
    return this;
  };
  protoOf(MRectangle).d3x = function (left, top, right, bottom) {
    return this.s3t(left, top, right - left, bottom - top);
  };
  protoOf(MRectangle).e3x = function (left, top, right, bottom) {
    return this.d3x(this.f3s() - left, this.h3s() - top, this.g3s() + right, this.i3s() + bottom);
  };
  protoOf(MRectangle).f3x = function (left, top, right, bottom, $super) {
    top = top === VOID ? left : top;
    right = right === VOID ? left : right;
    bottom = bottom === VOID ? top : bottom;
    return $super === VOID ? this.e3x(left, top, right, bottom) : $super.e3x.call(this, left, top, right, bottom);
  };
  protoOf(MRectangle).kk = function () {
    return this.s3t(0.0, 0.0, 0.0, 0.0);
  };
  protoOf(MRectangle).toString = function () {
    return 'Rectangle(x=' + get_niceStr_0(this.x3w_1) + ', y=' + get_niceStr_0(this.y3w_1) + ', width=' + get_niceStr_0(this.z3w_1) + ', height=' + get_niceStr_0(this.a3x_1) + ')';
  };
  protoOf(MRectangle).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof MRectangle) {
      tmp_2 = isAlmostEquals_0(this.x3w_1, other.x3w_1);
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = isAlmostEquals_0(this.y3w_1, other.y3w_1);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = isAlmostEquals_0(this.z3w_1, other.z3w_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = isAlmostEquals_0(this.a3x_1, other.a3x_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(MRectangle).g3x = function () {
    return Companion_getInstance_17().h3x(this.x3w_1, this.y3w_1, this.z3w_1, this.a3x_1);
  };
  protoOf(MRectangle).hashCode = function () {
    var result = getNumberHashCode(this.x3w_1);
    result = imul(result, 31) + getNumberHashCode(this.y3w_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.z3w_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.a3x_1) | 0;
    return result;
  };
  function Margin_init_$Init$(margin, $this) {
    Margin.call($this, margin, margin, margin, margin);
    return $this;
  }
  function Margin_init_$Create$(margin) {
    return Margin_init_$Init$(margin, objectCreate(protoOf(Margin)));
  }
  function Companion_8() {
    Companion_instance_8 = this;
    this.i3x_1 = new Margin(0.0, 0.0, 0.0, 0.0);
  }
  var Companion_instance_8;
  function Companion_getInstance_8() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function Margin(top, right, bottom, left) {
    Companion_getInstance_8();
    this.j3x_1 = top;
    this.k3x_1 = right;
    this.l3x_1 = bottom;
    this.m3x_1 = left;
  }
  protoOf(Margin).n3x = function () {
    return this.m3x_1 + this.k3x_1;
  };
  protoOf(Margin).o3x = function () {
    return this.j3x_1 + this.l3x_1;
  };
  protoOf(Margin).toString = function () {
    return 'Margin(top=' + get_niceStr(this.j3x_1) + ', right=' + get_niceStr(this.k3x_1) + ', bottom=' + get_niceStr(this.l3x_1) + ', left=' + get_niceStr(this.m3x_1) + ')';
  };
  protoOf(Margin).hashCode = function () {
    var result = getNumberHashCode(this.j3x_1);
    result = imul(result, 31) + getNumberHashCode(this.k3x_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.l3x_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.m3x_1) | 0;
    return result;
  };
  protoOf(Margin).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Margin))
      return false;
    var tmp0_other_with_cast = other instanceof Margin ? other : THROW_CCE();
    if (!equals(this.j3x_1, tmp0_other_with_cast.j3x_1))
      return false;
    if (!equals(this.k3x_1, tmp0_other_with_cast.k3x_1))
      return false;
    if (!equals(this.l3x_1, tmp0_other_with_cast.l3x_1))
      return false;
    if (!equals(this.m3x_1, tmp0_other_with_cast.m3x_1))
      return false;
    return true;
  };
  function MarginInt_init_$Init$(margin, $this) {
    MarginInt.call($this, margin, margin, margin, margin);
    return $this;
  }
  function MarginInt_init_$Create$(margin) {
    return MarginInt_init_$Init$(margin, objectCreate(protoOf(MarginInt)));
  }
  function Companion_9() {
    Companion_instance_9 = this;
    this.p3x_1 = new MarginInt(0, 0, 0, 0);
  }
  var Companion_instance_9;
  function Companion_getInstance_9() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function MarginInt(top, right, bottom, left) {
    Companion_getInstance_9();
    this.q3x_1 = top;
    this.r3x_1 = right;
    this.s3x_1 = bottom;
    this.t3x_1 = left;
  }
  protoOf(MarginInt).u3x = function () {
    return ((!(this.q3x_1 === 0) ? true : !(this.t3x_1 === 0)) ? true : !(this.r3x_1 === 0)) ? true : !(this.s3x_1 === 0);
  };
  protoOf(MarginInt).n3x = function () {
    return this.t3x_1 + this.r3x_1 | 0;
  };
  protoOf(MarginInt).o3x = function () {
    return this.q3x_1 + this.s3x_1 | 0;
  };
  protoOf(MarginInt).toString = function () {
    return 'MarginInt(top=' + this.q3x_1 + ', right=' + this.r3x_1 + ', bottom=' + this.s3x_1 + ', left=' + this.t3x_1 + ')';
  };
  protoOf(MarginInt).hashCode = function () {
    var result = this.q3x_1;
    result = imul(result, 31) + this.r3x_1 | 0;
    result = imul(result, 31) + this.s3x_1 | 0;
    result = imul(result, 31) + this.t3x_1 | 0;
    return result;
  };
  protoOf(MarginInt).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MarginInt))
      return false;
    var tmp0_other_with_cast = other instanceof MarginInt ? other : THROW_CCE();
    if (!(this.q3x_1 === tmp0_other_with_cast.q3x_1))
      return false;
    if (!(this.r3x_1 === tmp0_other_with_cast.r3x_1))
      return false;
    if (!(this.s3x_1 === tmp0_other_with_cast.s3x_1))
      return false;
    if (!(this.t3x_1 === tmp0_other_with_cast.t3x_1))
      return false;
    return true;
  };
  function _Matrix___init__impl__q3kp4w(data) {
    return data;
  }
  function _Matrix___get_data__impl__kg6az5($this) {
    return $this;
  }
  function _Matrix___get_a__impl__f7jypo($this) {
    return get_bf0(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_b__impl__dm2icj($this) {
    return get_bf1(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_c__impl__slf2ke($this) {
    return get_bf2(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_d__impl__87eht($this) {
    return get_bf3(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_tx__impl__yc1n9($this) {
    return get_bf4(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_ty__impl__tryipg($this) {
    return get_bf5(_Matrix___get_data__impl__kg6az5($this));
  }
  function _Matrix___get_immutable__impl__k08qr($this) {
    return $this;
  }
  function _Matrix___get_mutable__impl__mr1r3($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_4();
    var tmp1_invoke = _Matrix___get_a__impl__f7jypo($this);
    var tmp2_invoke = _Matrix___get_b__impl__dm2icj($this);
    var tmp3_invoke = _Matrix___get_c__impl__slf2ke($this);
    var tmp4_invoke = _Matrix___get_d__impl__87eht($this);
    var tmp5_invoke = _Matrix___get_tx__impl__yc1n9($this);
    var tmp6_invoke = _Matrix___get_ty__impl__tryipg($this);
    tmp$ret$0 = new MMatrix(tmp1_invoke, tmp2_invoke, tmp3_invoke, tmp4_invoke, tmp5_invoke, tmp6_invoke);
    return tmp$ret$0;
  }
  function _Matrix___init__impl__q3kp4w_0(a, b, c, d, tx, ty) {
    tx = tx === VOID ? 0.0 : tx;
    ty = ty === VOID ? 0.0 : ty;
    var tmp = _Matrix___init__impl__q3kp4w(bfloat6PackOf(a, b, c, d, tx, ty));
    return tmp;
  }
  function _Matrix___init__impl__q3kp4w_1(a, b, c, d, tx, ty) {
    var tmp = _Matrix___init__impl__q3kp4w_0(a, b, c, d, tx, ty);
    return tmp;
  }
  function Matrix__times_impl_aro03c($this, other) {
    return Companion_getInstance_10().y3x($this, other);
  }
  function _Matrix___get_isNIL__impl__n63x5q($this) {
    return isNaN_0(_Matrix___get_a__impl__f7jypo($this));
  }
  function _Matrix___get_isNotNIL__impl__wjqa0f($this) {
    return !_Matrix___get_isNIL__impl__n63x5q($this);
  }
  function _Matrix___get_isIdentity__impl__oe9whb($this) {
    return ((((_Matrix___get_a__impl__f7jypo($this) === 1.0 ? _Matrix___get_b__impl__dm2icj($this) === 0.0 : false) ? _Matrix___get_c__impl__slf2ke($this) === 0.0 : false) ? _Matrix___get_d__impl__87eht($this) === 1.0 : false) ? _Matrix___get_tx__impl__yc1n9($this) === 0.0 : false) ? _Matrix___get_ty__impl__tryipg($this) === 0.0 : false;
  }
  function _Matrix___get_type__impl__g2man3($this) {
    var hasRotation = !(_Matrix___get_b__impl__dm2icj($this) === 0.0) ? true : !(_Matrix___get_c__impl__slf2ke($this) === 0.0);
    var hasScale = !(_Matrix___get_a__impl__f7jypo($this) === 1.0) ? true : !(_Matrix___get_d__impl__87eht($this) === 1.0);
    var hasTranslation = !(_Matrix___get_tx__impl__yc1n9($this) === 0.0) ? true : !(_Matrix___get_ty__impl__tryipg($this) === 0.0);
    return hasRotation ? MatrixType_COMPLEX_getInstance() : (hasScale ? hasTranslation : false) ? MatrixType_SCALE_TRANSLATE_getInstance() : hasScale ? MatrixType_SCALE_getInstance() : hasTranslation ? MatrixType_TRANSLATE_getInstance() : MatrixType_IDENTITY_getInstance();
  }
  function Matrix__transformX_impl_adb2o0($this, p) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (_Matrix___get_isNIL__impl__n63x5q($this)) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this) * p.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * p.q3r_1 + _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_d__impl__87eht($this) * p.q3r_1 + _Matrix___get_b__impl__dm2icj($this) * p.p3r_1 + _Matrix___get_ty__impl__tryipg($this));
    }
    return tmp$ret$0.p3r_1;
  }
  function Matrix__transformX_impl_adb2o0_0($this, x, y) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = new Vector2(x, y);
      if (_Matrix___get_isNIL__impl__n63x5q($this)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_d__impl__87eht($this) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj($this) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg($this));
    }
    return tmp$ret$0.p3r_1;
  }
  function Matrix__transformY_impl_aucp27($this, x, y) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = new Vector2(x, y);
      if (_Matrix___get_isNIL__impl__n63x5q($this)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_d__impl__87eht($this) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj($this) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg($this));
    }
    return tmp$ret$0.q3r_1;
  }
  function Matrix__transformX_impl_adb2o0_1($this, x, y) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = Vector2_init_$Create$_0(x, y);
      if (_Matrix___get_isNIL__impl__n63x5q($this)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_d__impl__87eht($this) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj($this) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg($this));
    }
    return tmp$ret$0.o3t();
  }
  function Matrix__transformY_impl_aucp27_0($this, x, y) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = Vector2_init_$Create$_0(x, y);
      if (_Matrix___get_isNIL__impl__n63x5q($this)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_d__impl__87eht($this) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj($this) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg($this));
    }
    return tmp$ret$0.p3t();
  }
  function Matrix__rotated_impl_2adiit($this, angle) {
    var theta = _Angle___get_radians__impl__n00yt5(angle);
    var tmp$ret$0;
    // Inline function 'kotlin.math.cos' call
    tmp$ret$0 = Math.cos(theta);
    var cos = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.sin' call
    tmp$ret$1 = Math.sin(theta);
    var sin = tmp$ret$1;
    var a1 = _Matrix___get_a__impl__f7jypo($this) * cos - _Matrix___get_b__impl__dm2icj($this) * sin;
    var b = _Matrix___get_a__impl__f7jypo($this) * sin + _Matrix___get_b__impl__dm2icj($this) * cos;
    var a = a1;
    var c1 = _Matrix___get_c__impl__slf2ke($this) * cos - _Matrix___get_d__impl__87eht($this) * sin;
    var d = _Matrix___get_c__impl__slf2ke($this) * sin + _Matrix___get_d__impl__87eht($this) * cos;
    var c = c1;
    var tx1 = _Matrix___get_tx__impl__yc1n9($this) * cos - _Matrix___get_ty__impl__tryipg($this) * sin;
    var ty = _Matrix___get_tx__impl__yc1n9($this) * sin + _Matrix___get_ty__impl__tryipg($this) * cos;
    var tx = tx1;
    return _Matrix___init__impl__q3kp4w_0(a, b, c, d, tx, ty);
  }
  function Matrix__scaled_impl_ogn4b6($this, scale) {
    return _Matrix___init__impl__q3kp4w_0(_Matrix___get_a__impl__f7jypo($this) * scale.z3x_1, _Matrix___get_b__impl__dm2icj($this) * scale.z3x_1, _Matrix___get_c__impl__slf2ke($this) * scale.a3y_1, _Matrix___get_d__impl__87eht($this) * scale.a3y_1, _Matrix___get_tx__impl__yc1n9($this) * scale.z3x_1, _Matrix___get_ty__impl__tryipg($this) * scale.a3y_1);
  }
  function Matrix__scaled_impl_ogn4b6_0($this, scaleX, scaleY) {
    return Matrix__scaled_impl_ogn4b6($this, new Scale(scaleX, scaleY));
  }
  function Matrix__scaled$default_impl_qe0yl1($this, scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    var tmp;
    if ($super === VOID) {
      tmp = Matrix__scaled_impl_ogn4b6_0($this, scaleX, scaleY);
    } else {
      var tmp_0 = new Matrix($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new Matrix(tmp_1)).c3y.call(tmp_0, scaleX, scaleY).b3y_1;
    }
    return tmp;
  }
  function Matrix__scaled_impl_ogn4b6_1($this, scaleX, scaleY) {
    return Matrix__scaled_impl_ogn4b6($this, Scale_init_$Create$_0(scaleX, scaleY));
  }
  function Matrix__scaled$default_impl_qe0yl1_0($this, scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    var tmp;
    if ($super === VOID) {
      tmp = Matrix__scaled_impl_ogn4b6_1($this, scaleX, scaleY);
    } else {
      var tmp_0 = new Matrix($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new Matrix(tmp_1)).d3y.call(tmp_0, scaleX, scaleY).b3y_1;
    }
    return tmp;
  }
  function Matrix__prescaled_impl_gy2bxt($this, scale) {
    return _Matrix___init__impl__q3kp4w_0(_Matrix___get_a__impl__f7jypo($this) * scale.z3x_1, _Matrix___get_b__impl__dm2icj($this) * scale.z3x_1, _Matrix___get_c__impl__slf2ke($this) * scale.a3y_1, _Matrix___get_d__impl__87eht($this) * scale.a3y_1, _Matrix___get_tx__impl__yc1n9($this), _Matrix___get_ty__impl__tryipg($this));
  }
  function Matrix__prescaled_impl_gy2bxt_0($this, scaleX, scaleY) {
    return Matrix__prescaled_impl_gy2bxt($this, new Scale(scaleX, scaleY));
  }
  function Matrix__prescaled$default_impl_m96zqs($this, scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    var tmp;
    if ($super === VOID) {
      tmp = Matrix__prescaled_impl_gy2bxt_0($this, scaleX, scaleY);
    } else {
      var tmp_0 = new Matrix($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new Matrix(tmp_1)).e3y.call(tmp_0, scaleX, scaleY).b3y_1;
    }
    return tmp;
  }
  function Matrix__prescaled_impl_gy2bxt_1($this, scaleX, scaleY) {
    return Matrix__prescaled_impl_gy2bxt($this, Scale_init_$Create$_0(scaleX, scaleY));
  }
  function Matrix__prescaled$default_impl_m96zqs_0($this, scaleX, scaleY, $super) {
    scaleY = scaleY === VOID ? scaleX : scaleY;
    var tmp;
    if ($super === VOID) {
      tmp = Matrix__prescaled_impl_gy2bxt_1($this, scaleX, scaleY);
    } else {
      var tmp_0 = new Matrix($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new Matrix(tmp_1)).f3y.call(tmp_0, scaleX, scaleY).b3y_1;
    }
    return tmp;
  }
  function Matrix__translated_impl_3xg5y($this, delta) {
    return _Matrix___init__impl__q3kp4w_0(_Matrix___get_a__impl__f7jypo($this), _Matrix___get_b__impl__dm2icj($this), _Matrix___get_c__impl__slf2ke($this), _Matrix___get_d__impl__87eht($this), _Matrix___get_tx__impl__yc1n9($this) + delta.p3r_1, _Matrix___get_ty__impl__tryipg($this) + delta.q3r_1);
  }
  function Matrix__translated_impl_3xg5y_0($this, x, y) {
    return Matrix__translated_impl_3xg5y($this, Vector2_init_$Create$_0(x, y));
  }
  function Matrix__translated_impl_3xg5y_1($this, x, y) {
    return Matrix__translated_impl_3xg5y($this, new Vector2(x, y));
  }
  function Matrix__translated_impl_3xg5y_2($this, x, y) {
    return Matrix__translated_impl_3xg5y($this, Vector2_init_$Create$(x, y));
  }
  function Matrix__pretranslated_impl_9mjs3v($this, delta) {
    return _Matrix___init__impl__q3kp4w_0(_Matrix___get_a__impl__f7jypo($this), _Matrix___get_b__impl__dm2icj($this), _Matrix___get_c__impl__slf2ke($this), _Matrix___get_d__impl__87eht($this), _Matrix___get_tx__impl__yc1n9($this) + (_Matrix___get_a__impl__f7jypo($this) * delta.p3r_1 + _Matrix___get_c__impl__slf2ke($this) * delta.q3r_1), _Matrix___get_ty__impl__tryipg($this) + (_Matrix___get_b__impl__dm2icj($this) * delta.p3r_1 + _Matrix___get_d__impl__87eht($this) * delta.q3r_1));
  }
  function Matrix__pretranslated_impl_9mjs3v_0($this, deltaX, deltaY) {
    return Matrix__pretranslated_impl_9mjs3v($this, new Vector2(deltaX, deltaY));
  }
  function Matrix__pretranslated_impl_9mjs3v_1($this, deltaX, deltaY) {
    return Matrix__pretranslated_impl_9mjs3v($this, Vector2_init_$Create$(deltaX, deltaY));
  }
  function Matrix__premultiplied_impl_rsndx4($this, m) {
    return Matrix__times_impl_aro03c(m, $this);
  }
  function Matrix__clone_impl_451ycv($this) {
    return $this;
  }
  function Matrix__inverted_impl_3pqbyv($this) {
    if (_Matrix___get_isNIL__impl__n63x5q($this))
      return Companion_getInstance_10().v3x_1;
    var m = $this;
    var norm = _Matrix___get_a__impl__f7jypo(m) * _Matrix___get_d__impl__87eht(m) - _Matrix___get_b__impl__dm2icj(m) * _Matrix___get_c__impl__slf2ke(m);
    var tmp0_subject = norm;
    var tmp;
    if (tmp0_subject === 0.0) {
      tmp = _Matrix___init__impl__q3kp4w_0(0.0, 0.0, 0.0, 0.0, -_Matrix___get_tx__impl__yc1n9(m), -_Matrix___get_ty__impl__tryipg(m));
    } else {
      var inorm = 1.0 / norm;
      var d = _Matrix___get_a__impl__f7jypo(m) * inorm;
      var a = _Matrix___get_d__impl__87eht(m) * inorm;
      var b = _Matrix___get_b__impl__dm2icj(m) * -inorm;
      var c = _Matrix___get_c__impl__slf2ke(m) * -inorm;
      tmp = _Matrix___init__impl__q3kp4w_0(a, b, c, d, -a * _Matrix___get_tx__impl__yc1n9(m) - c * _Matrix___get_ty__impl__tryipg(m), -b * _Matrix___get_tx__impl__yc1n9(m) - d * _Matrix___get_ty__impl__tryipg(m));
    }
    return tmp;
  }
  function Matrix__toTransform_impl_lr8urh($this) {
    return Matrix__decompose_impl_zhcfgd($this);
  }
  function Matrix__decompose_impl_zhcfgd($this) {
    return Companion_getInstance_11().h3y($this);
  }
  function Matrix__toString_impl_l0abk0($this) {
    return 'Matrix(' + get_niceStr(_Matrix___get_a__impl__f7jypo($this)) + ', ' + get_niceStr(_Matrix___get_b__impl__dm2icj($this)) + ', ' + get_niceStr(_Matrix___get_c__impl__slf2ke($this)) + ', ' + get_niceStr(_Matrix___get_d__impl__87eht($this)) + ', ' + get_niceStr(_Matrix___get_tx__impl__yc1n9($this)) + ', ' + get_niceStr(_Matrix___get_ty__impl__tryipg($this)) + ')';
  }
  function Matrix__preconcated_impl_s9s2ig($this, other) {
    return Matrix__times_impl_aro03c($this, other);
  }
  function Companion_10() {
    Companion_instance_10 = this;
    this.v3x_1 = _Matrix___init__impl__q3kp4w(bfloat6PackOf(1.0, 0.0, 0.0, 1.0, 0.0, 0.0));
    var tmp = this;
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    tmp.w3x_1 = _Matrix___init__impl__q3kp4w(bfloat6PackOf(NaN, NaN, NaN, NaN, NaN, NaN));
    this.x3x_1 = this.w3x_1;
  }
  protoOf(Companion_10).i3y = function () {
    return this.v3x_1;
  };
  protoOf(Companion_10).y3x = function (l, r) {
    if (_Matrix___get_isNIL__impl__n63x5q(l))
      return r;
    if (_Matrix___get_isNIL__impl__n63x5q(r))
      return l;
    return _Matrix___init__impl__q3kp4w_0(_Matrix___get_a__impl__f7jypo(l) * _Matrix___get_a__impl__f7jypo(r) + _Matrix___get_b__impl__dm2icj(l) * _Matrix___get_c__impl__slf2ke(r), _Matrix___get_a__impl__f7jypo(l) * _Matrix___get_b__impl__dm2icj(r) + _Matrix___get_b__impl__dm2icj(l) * _Matrix___get_d__impl__87eht(r), _Matrix___get_c__impl__slf2ke(l) * _Matrix___get_a__impl__f7jypo(r) + _Matrix___get_d__impl__87eht(l) * _Matrix___get_c__impl__slf2ke(r), _Matrix___get_c__impl__slf2ke(l) * _Matrix___get_b__impl__dm2icj(r) + _Matrix___get_d__impl__87eht(l) * _Matrix___get_d__impl__87eht(r), _Matrix___get_tx__impl__yc1n9(l) * _Matrix___get_a__impl__f7jypo(r) + _Matrix___get_ty__impl__tryipg(l) * _Matrix___get_c__impl__slf2ke(r) + _Matrix___get_tx__impl__yc1n9(r), _Matrix___get_tx__impl__yc1n9(l) * _Matrix___get_b__impl__dm2icj(r) + _Matrix___get_ty__impl__tryipg(l) * _Matrix___get_d__impl__87eht(r) + _Matrix___get_ty__impl__tryipg(r));
  };
  protoOf(Companion_10).j3y = function (x, y, rotation, scaleX, scaleY, skewX, skewY, pivotX, pivotY) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.cosf' call
    var tmp0_cosf = Angle__plus_impl_26v37k(rotation, skewY);
    var tmp1_cosf = Companion_getInstance_21().v3r_1;
    tmp$ret$0 = Angle__cosineF_impl_aj2or7(tmp0_cosf, tmp1_cosf);
    var a = tmp$ret$0 * scaleX + 0.0;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.sinf' call
    var tmp2_sinf = Angle__plus_impl_26v37k(rotation, skewY);
    var tmp3_sinf = Companion_getInstance_21().v3r_1;
    tmp$ret$1 = Angle__sineF_impl_t5qbx5(tmp2_sinf, tmp3_sinf);
    var b = tmp$ret$1 * scaleX + 0.0;
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.sinf' call
    var tmp4_sinf = Angle__minus_impl_xeau7k(rotation, skewX);
    var tmp5_sinf = Companion_getInstance_21().v3r_1;
    tmp$ret$2 = Angle__sineF_impl_t5qbx5(tmp4_sinf, tmp5_sinf);
    var c = -tmp$ret$2 * scaleY + 0.0;
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.cosf' call
    var tmp6_cosf = Angle__minus_impl_xeau7k(rotation, skewX);
    var tmp7_cosf = Companion_getInstance_21().v3r_1;
    tmp$ret$3 = Angle__cosineF_impl_aj2or7(tmp6_cosf, tmp7_cosf);
    var d = tmp$ret$3 * scaleY + 0.0;
    var tx;
    var ty;
    if (pivotX === 0.0 ? pivotY === 0.0 : false) {
      tx = x;
      ty = y;
    } else {
      tx = x - (pivotX * a + pivotY * c);
      ty = y - (pivotX * b + pivotY * d);
    }
    return _Matrix___init__impl__q3kp4w_0(a, b, c, d, tx, ty);
  };
  protoOf(Companion_10).k3y = function (a, b, c, d, tx, ty, p) {
    return new Vector2(a * p.p3r_1 + c * p.q3r_1 + tx, d * p.q3r_1 + b * p.p3r_1 + ty);
  };
  var Companion_instance_10;
  function Companion_getInstance_10() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function Matrix__hashCode_impl_s9ntm9($this) {
    return $this.hashCode();
  }
  function Matrix__equals_impl_g5p8p9($this, other) {
    if (!(other instanceof Matrix))
      return false;
    var tmp0_other_with_cast = other instanceof Matrix ? other.b3y_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function Matrix(data) {
    Companion_getInstance_10();
    this.b3y_1 = data;
  }
  protoOf(Matrix).toString = function () {
    return Matrix__toString_impl_l0abk0(this.b3y_1);
  };
  protoOf(Matrix).hashCode = function () {
    return Matrix__hashCode_impl_s9ntm9(this.b3y_1);
  };
  protoOf(Matrix).equals = function (other) {
    return Matrix__equals_impl_g5p8p9(this.b3y_1, other);
  };
  var MatrixType_IDENTITY_instance;
  var MatrixType_TRANSLATE_instance;
  var MatrixType_SCALE_instance;
  var MatrixType_SCALE_TRANSLATE_instance;
  var MatrixType_COMPLEX_instance;
  var MatrixType_entriesInitialized;
  function MatrixType_initEntries() {
    if (MatrixType_entriesInitialized)
      return Unit_getInstance();
    MatrixType_entriesInitialized = true;
    MatrixType_IDENTITY_instance = new MatrixType('IDENTITY', 0, 1, false, false, false);
    MatrixType_TRANSLATE_instance = new MatrixType('TRANSLATE', 1, 2, false, false, true);
    MatrixType_SCALE_instance = new MatrixType('SCALE', 2, 3, false, true, false);
    MatrixType_SCALE_TRANSLATE_instance = new MatrixType('SCALE_TRANSLATE', 3, 4, false, true, true);
    MatrixType_COMPLEX_instance = new MatrixType('COMPLEX', 4, 5, true, true, true);
  }
  function MatrixType(name, ordinal, id, hasRotation, hasScale, hasTranslation) {
    Enum.call(this, name, ordinal);
    this.n3y_1 = id;
    this.o3y_1 = hasRotation;
    this.p3y_1 = hasScale;
    this.q3y_1 = hasTranslation;
  }
  function _MatrixTransform___init__impl__7axsoi(raw) {
    return raw;
  }
  function _MatrixTransform___get_raw__impl__vuic4r($this) {
    return $this;
  }
  function _MatrixTransform___get_x__impl__5z12ed($this) {
    return get_b0(_MatrixTransform___get_raw__impl__vuic4r($this));
  }
  function _MatrixTransform___get_y__impl__mulenu($this) {
    return get_b1(_MatrixTransform___get_raw__impl__vuic4r($this));
  }
  function _MatrixTransform___get_rotation__impl__d5fsj($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = get_b2(_MatrixTransform___get_raw__impl__vuic4r($this));
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function _MatrixTransform___get_scaleX__impl__t9pp1f($this) {
    return get_hf0(_MatrixTransform___get_raw__impl__vuic4r($this));
  }
  function _MatrixTransform___get_scaleY__impl__g37z8($this) {
    return get_hf1(_MatrixTransform___get_raw__impl__vuic4r($this));
  }
  function _MatrixTransform___get_skewX__impl__luxf0b($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = get_hf2(_MatrixTransform___get_raw__impl__vuic4r($this));
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function _MatrixTransform___get_skewY__impl__6yp21w($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp0_fromRatio = Companion_getInstance_0();
    var tmp1_fromRatio = get_hf3(_MatrixTransform___get_raw__impl__vuic4r($this));
    tmp$ret$0 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    return tmp$ret$0;
  }
  function _MatrixTransform___get_scale__impl__8ztdah($this) {
    return new Scale(_MatrixTransform___get_scaleX__impl__t9pp1f($this), _MatrixTransform___get_scaleY__impl__g37z8($this));
  }
  function MatrixTransform__toString_impl_96x9na($this) {
    return 'MatrixTransform(x=' + get_niceStr(_MatrixTransform___get_x__impl__5z12ed($this)) + ', y=' + get_niceStr(_MatrixTransform___get_y__impl__mulenu($this)) + ', scaleX=' + _MatrixTransform___get_scaleX__impl__t9pp1f($this) + ', scaleY=' + _MatrixTransform___get_scaleY__impl__g37z8($this) + ', skewX=' + new Angle(_MatrixTransform___get_skewX__impl__luxf0b($this)) + ', skewY=' + new Angle(_MatrixTransform___get_skewY__impl__6yp21w($this)) + ', rotation=' + new Angle(_MatrixTransform___get_rotation__impl__d5fsj($this)) + ')';
  }
  function _MatrixTransform___init__impl__7axsoi_0(x, y, scaleX, scaleY, skewX, skewY, rotation) {
    x = x === VOID ? 0.0 : x;
    y = y === VOID ? 0.0 : y;
    scaleX = scaleX === VOID ? 1.0 : scaleX;
    scaleY = scaleY === VOID ? 1.0 : scaleY;
    skewX = skewX === VOID ? Companion_getInstance_0().g3r_1 : skewX;
    skewY = skewY === VOID ? Companion_getInstance_0().g3r_1 : skewY;
    rotation = rotation === VOID ? Companion_getInstance_0().g3r_1 : rotation;
    var tmp = _MatrixTransform___init__impl__7axsoi(bfloat3Half4PackOf(x, y, _Angle___get_ratioF__impl__m1m0g8(rotation), scaleX, scaleY, _Angle___get_ratioF__impl__m1m0g8(skewX), _Angle___get_ratioF__impl__m1m0g8(skewY)));
    return tmp;
  }
  function _MatrixTransform___init__impl__7axsoi_1() {
    var tmp = _MatrixTransform___init__impl__7axsoi_0(0.0, 0.0, 1.0, 1.0, Companion_getInstance_0().g3r_1, Companion_getInstance_0().g3r_1, Companion_getInstance_0().g3r_1);
    return tmp;
  }
  function Companion_11() {
    Companion_instance_11 = this;
    this.g3y_1 = _MatrixTransform___init__impl__7axsoi_0(0.0, 0.0, 1.0, 1.0, Companion_getInstance_0().g3r_1, Companion_getInstance_0().g3r_1, Companion_getInstance_0().g3r_1);
  }
  protoOf(Companion_11).r3y = function (matrix, pivotX, pivotY) {
    var a = _Matrix___get_a__impl__f7jypo(matrix);
    var b = _Matrix___get_b__impl__dm2icj(matrix);
    var c = _Matrix___get_c__impl__slf2ke(matrix);
    var d = _Matrix___get_d__impl__87eht(matrix);
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    var tmp0_atan2 = -c;
    tmp$ret$0 = Math.atan2(tmp0_atan2, d);
    var skewX = -tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.atan2' call
    tmp$ret$1 = Math.atan2(b, a);
    var skewY = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.abs' call
    var tmp1_abs = skewX + skewY;
    tmp$ret$2 = Math.abs(tmp1_abs);
    var delta = tmp$ret$2;
    var trotation;
    var tskewX;
    var tskewY;
    var tx;
    var ty;
    var tmp;
    if (delta < 0.001) {
      tmp = true;
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.math.abs' call
      var tmp2_abs = get_PI() * 2 - delta;
      tmp$ret$3 = Math.abs(tmp2_abs);
      tmp = tmp$ret$3 < 0.0010000000474974513;
    }
    if (tmp) {
      trotation = get_radians_0(skewY);
      tskewX = get_radians(0.0);
      tskewY = get_radians(0.0);
    } else {
      trotation = get_radians_1(0);
      tskewX = get_radians_0(skewX);
      tskewY = get_radians_0(skewY);
    }
    var tmp$ret$4;
    // Inline function 'kotlin.math.hypot' call
    tmp$ret$4 = hypot(a, b);
    var tscaleX = tmp$ret$4;
    var tmp$ret$5;
    // Inline function 'kotlin.math.hypot' call
    tmp$ret$5 = hypot(c, d);
    var tscaleY = tmp$ret$5;
    if (pivotX === 0.0 ? pivotY === 0.0 : false) {
      tx = _Matrix___get_tx__impl__yc1n9(matrix);
      ty = _Matrix___get_ty__impl__tryipg(matrix);
    } else {
      tx = _Matrix___get_tx__impl__yc1n9(matrix) + (pivotX * a + pivotY * c);
      ty = _Matrix___get_ty__impl__tryipg(matrix) + (pivotX * b + pivotY * d);
    }
    return _MatrixTransform___init__impl__7axsoi_0(tx, ty, tscaleX, tscaleY, tskewX, tskewY, trotation);
  };
  protoOf(Companion_11).h3y = function (matrix, pivotX, pivotY, $super) {
    pivotX = pivotX === VOID ? 0.0 : pivotX;
    pivotY = pivotY === VOID ? 0.0 : pivotY;
    return $super === VOID ? this.r3y(matrix, pivotX, pivotY) : $super.r3y.call(this, new Matrix(matrix), pivotX, pivotY).s3y_1;
  };
  var Companion_instance_11;
  function Companion_getInstance_11() {
    if (Companion_instance_11 == null)
      new Companion_11();
    return Companion_instance_11;
  }
  function _MatrixTransform___get_scaleAvg__impl__ku8ljx($this) {
    return (_MatrixTransform___get_scaleX__impl__t9pp1f($this) + _MatrixTransform___get_scaleY__impl__g37z8($this)) * 0.5;
  }
  function MatrixTransform__hashCode_impl_ck8n5l($this) {
    return $this.hashCode();
  }
  function MatrixTransform__equals_impl_xethpf($this, other) {
    if (!(other instanceof MatrixTransform))
      return false;
    var tmp0_other_with_cast = other instanceof MatrixTransform ? other.s3y_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function MatrixTransform(raw) {
    Companion_getInstance_11();
    this.s3y_1 = raw;
  }
  protoOf(MatrixTransform).toString = function () {
    return MatrixTransform__toString_impl_96x9na(this.s3y_1);
  };
  protoOf(MatrixTransform).hashCode = function () {
    return MatrixTransform__hashCode_impl_ck8n5l(this.s3y_1);
  };
  protoOf(MatrixTransform).equals = function (other) {
    return MatrixTransform__equals_impl_xethpf(this.s3y_1, other);
  };
  function MatrixType_IDENTITY_getInstance() {
    MatrixType_initEntries();
    return MatrixType_IDENTITY_instance;
  }
  function MatrixType_TRANSLATE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_TRANSLATE_instance;
  }
  function MatrixType_SCALE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_SCALE_instance;
  }
  function MatrixType_SCALE_TRANSLATE_getInstance() {
    MatrixType_initEntries();
    return MatrixType_SCALE_TRANSLATE_instance;
  }
  function MatrixType_COMPLEX_getInstance() {
    MatrixType_initEntries();
    return MatrixType_COMPLEX_instance;
  }
  function Matrix4_init_$Init$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33, $this) {
    var tmp$ret$0;
    // Inline function 'kotlin.floatArrayOf' call
    tmp$ret$0 = new Float32Array([v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33]);
    Matrix4.call($this, tmp$ret$0);
    return $this;
  }
  function Matrix4_init_$Create$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33) {
    return Matrix4_init_$Init$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33, objectCreate(protoOf(Matrix4)));
  }
  function Matrix4_init_$Init$_0($this) {
    Matrix4_init_$Init$(1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 0.0, 1.0, $this);
    return $this;
  }
  function Matrix4_init_$Create$_0() {
    return Matrix4_init_$Init$_0(objectCreate(protoOf(Matrix4)));
  }
  function Companion_12() {
    Companion_instance_12 = this;
    this.d3v_1 = 0;
    this.e3v_1 = 1;
    this.f3v_1 = 2;
    this.g3v_1 = 3;
    this.h3v_1 = 4;
    this.i3v_1 = 5;
    this.j3v_1 = 6;
    this.k3v_1 = 7;
    this.l3v_1 = 8;
    this.m3v_1 = 9;
    this.n3v_1 = 10;
    this.o3v_1 = 11;
    this.p3v_1 = 12;
    this.q3v_1 = 13;
    this.r3v_1 = 14;
    this.s3v_1 = 15;
    this.t3v_1 = Matrix4_init_$Create$_0();
  }
  protoOf(Companion_12).t3y = function () {
    return Companion_getInstance_5().l3w_1;
  };
  protoOf(Companion_12).u3v = function (v00, v01, v02, v03, v10, v11, v12, v13, v20, v21, v22, v23, v30, v31, v32, v33) {
    return Matrix4_init_$Create$(v00, v10, v20, v30, v01, v11, v21, v31, v02, v12, v22, v32, v03, v13, v23, v33);
  };
  protoOf(Companion_12).u3y = function (l, r) {
    return Companion_getInstance_12().u3v(l.w3y() * r.w3y() + l.x3y() * r.y3y() + l.z3y() * r.a3z() + l.b3z() * r.c3z(), l.w3y() * r.x3y() + l.x3y() * r.d3z() + l.z3y() * r.e3z() + l.b3z() * r.f3z(), l.w3y() * r.z3y() + l.x3y() * r.g3z() + l.z3y() * r.h3z() + l.b3z() * r.i3z(), l.w3y() * r.b3z() + l.x3y() * r.j3z() + l.z3y() * r.k3z() + l.b3z() * r.l3z(), l.y3y() * r.w3y() + l.d3z() * r.y3y() + l.g3z() * r.a3z() + l.j3z() * r.c3z(), l.y3y() * r.x3y() + l.d3z() * r.d3z() + l.g3z() * r.e3z() + l.j3z() * r.f3z(), l.y3y() * r.z3y() + l.d3z() * r.g3z() + l.g3z() * r.h3z() + l.j3z() * r.i3z(), l.y3y() * r.b3z() + l.d3z() * r.j3z() + l.g3z() * r.k3z() + l.j3z() * r.l3z(), l.a3z() * r.w3y() + l.e3z() * r.y3y() + l.h3z() * r.a3z() + l.k3z() * r.c3z(), l.a3z() * r.x3y() + l.e3z() * r.d3z() + l.h3z() * r.e3z() + l.k3z() * r.f3z(), l.a3z() * r.z3y() + l.e3z() * r.g3z() + l.h3z() * r.h3z() + l.k3z() * r.i3z(), l.a3z() * r.b3z() + l.e3z() * r.j3z() + l.h3z() * r.k3z() + l.k3z() * r.l3z(), l.c3z() * r.w3y() + l.f3z() * r.y3y() + l.i3z() * r.a3z() + l.l3z() * r.c3z(), l.c3z() * r.x3y() + l.f3z() * r.d3z() + l.i3z() * r.e3z() + l.l3z() * r.f3z(), l.c3z() * r.z3y() + l.f3z() * r.g3z() + l.i3z() * r.h3z() + l.l3z() * r.i3z(), l.c3z() * r.b3z() + l.f3z() * r.j3z() + l.i3z() * r.k3z() + l.l3z() * r.l3z());
  };
  protoOf(Companion_12).m3z = function (left, right, bottom, top, near, far) {
    var sx = 2.0 / (right - left);
    var sy = 2.0 / (top - bottom);
    var sz = -2.0 / (far - near);
    var tx = -(right + left) / (right - left);
    var ty = -(top + bottom) / (top - bottom);
    var tz = -(far + near) / (far - near);
    return Companion_getInstance_12().u3v(sx, 0.0, 0.0, tx, 0.0, sy, 0.0, ty, 0.0, 0.0, sz, tz, 0.0, 0.0, 0.0, 1.0);
  };
  protoOf(Companion_12).n3z = function (rect, near, far) {
    return this.m3z(rect.f3s(), rect.g3s(), rect.i3s(), rect.h3s(), near, far);
  };
  var Companion_instance_12;
  function Companion_getInstance_12() {
    if (Companion_instance_12 == null)
      new Companion_12();
    return Companion_instance_12;
  }
  function Matrix4(data) {
    Companion_getInstance_12();
    this.v3y_1 = data;
    // Inline function 'kotlin.check' call
    var tmp0_check = this.v3y_1.length === 16;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_check) {
      var tmp$ret$0;
      // Inline function 'kotlin.check.<anonymous>' call
      tmp$ret$0 = 'Check failed.';
      var message = tmp$ret$0;
      throw IllegalStateException_init_$Create$(toString(message));
    }
  }
  protoOf(Matrix4).w3y = function () {
    return this.v3y_1[0];
  };
  protoOf(Matrix4).y3y = function () {
    return this.v3y_1[1];
  };
  protoOf(Matrix4).a3z = function () {
    return this.v3y_1[2];
  };
  protoOf(Matrix4).c3z = function () {
    return this.v3y_1[3];
  };
  protoOf(Matrix4).x3y = function () {
    return this.v3y_1[4];
  };
  protoOf(Matrix4).d3z = function () {
    return this.v3y_1[5];
  };
  protoOf(Matrix4).e3z = function () {
    return this.v3y_1[6];
  };
  protoOf(Matrix4).f3z = function () {
    return this.v3y_1[7];
  };
  protoOf(Matrix4).z3y = function () {
    return this.v3y_1[8];
  };
  protoOf(Matrix4).g3z = function () {
    return this.v3y_1[9];
  };
  protoOf(Matrix4).h3z = function () {
    return this.v3y_1[10];
  };
  protoOf(Matrix4).i3z = function () {
    return this.v3y_1[11];
  };
  protoOf(Matrix4).b3z = function () {
    return this.v3y_1[12];
  };
  protoOf(Matrix4).j3z = function () {
    return this.v3y_1[13];
  };
  protoOf(Matrix4).k3z = function () {
    return this.v3y_1[14];
  };
  protoOf(Matrix4).l3z = function () {
    return this.v3y_1[15];
  };
  protoOf(Matrix4).equals = function (other) {
    var tmp;
    if (other instanceof Matrix4) {
      tmp = contentEquals(this.v3y_1, other.v3y_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Matrix4).hashCode = function () {
    return contentHashCode(this.v3y_1);
  };
  protoOf(Matrix4).o3z = function (that) {
    return Companion_getInstance_12().u3y(this, that);
  };
  protoOf(Matrix4).a19 = function (row, column) {
    if (!(0 <= column ? column <= 3 : false) ? true : !(0 <= row ? row <= 3 : false)) {
      // Inline function 'kotlin.error' call
      var tmp0_error = 'Invalid index ' + row + ',' + column;
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
    return this.v3y_1[imul(row, 4) + column | 0];
  };
  protoOf(Matrix4).p3z = function (index) {
    if (!(0 <= index ? index <= (this.v3y_1.length - 1 | 0) : false)) {
      // Inline function 'kotlin.error' call
      var tmp0_error = 'Invalid index ' + index;
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
    return this.v3y_1[index];
  };
  protoOf(Matrix4).toString = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.Matrix4.toString.<anonymous>' call
    tmp0_apply.bc('Matrix4(\n');
    var inductionVariable = 0;
    if (inductionVariable < 4)
      do {
        var row = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        tmp0_apply.bc('  [ ');
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < 4)
          do {
            var col = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            if (!(col === 0)) {
              tmp0_apply.bc(', ');
            }
            var v = this.a19(row, col);
            var tmp$ret$0;
            // Inline function 'kotlin.math.floor' call
            tmp$ret$0 = Math.floor(v);
            if (tmp$ret$0 === v) {
              tmp0_apply.ac(numberToInt(v));
            } else {
              tmp0_apply.ac(v);
            }
          }
           while (inductionVariable_0 < 4);
        tmp0_apply.bc(' ],\n');
      }
       while (inductionVariable < 4);
    tmp0_apply.bc(')');
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1.toString();
    return tmp$ret$2;
  };
  var Orientation_CLOCK_WISE_instance;
  var Orientation_COUNTER_CLOCK_WISE_instance;
  var Orientation_COLLINEAR_instance;
  function Companion_13() {
    Companion_instance_13 = this;
    this.m3r_1 = 1.0E-4;
    this.n3r_1 = 1.0E-7;
  }
  protoOf(Companion_13).o3r = function (up) {
    // Inline function 'kotlin.check' call
    var tmp;
    if (up.p3r_1 === 0.0) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.absoluteValue' call
      var tmp0__get_absoluteValue__nukmtt = up.q3r_1;
      tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
      tmp = tmp$ret$0 === 1.0;
    } else {
      tmp = false;
    }
    var tmp1_check = tmp;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_check) {
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.Companion.checkValidUpVector.<anonymous>' call
      tmp$ret$1 = 'up vector only supports (0, -1) and (0, +1) for now';
      var message = tmp$ret$1;
      throw IllegalStateException_init_$Create$(toString(message));
    }
  };
  var Companion_instance_13;
  function Companion_getInstance_13() {
    Orientation_initEntries();
    if (Companion_instance_13 == null)
      new Companion_13();
    return Companion_instance_13;
  }
  var Orientation_entriesInitialized;
  function Orientation_initEntries() {
    if (Orientation_entriesInitialized)
      return Unit_getInstance();
    Orientation_entriesInitialized = true;
    Orientation_CLOCK_WISE_instance = new Orientation('CLOCK_WISE', 0, 1);
    Orientation_COUNTER_CLOCK_WISE_instance = new Orientation('COUNTER_CLOCK_WISE', 1, -1);
    Orientation_COLLINEAR_instance = new Orientation('COLLINEAR', 2, 0);
    Companion_getInstance_13();
  }
  function Orientation(name, ordinal, value) {
    Enum.call(this, name, ordinal);
    this.s3z_1 = value;
  }
  function PointList() {
  }
  function Companion_14() {
    Companion_instance_14 = this;
  }
  var Companion_instance_14;
  function Companion_getInstance_14() {
    if (Companion_instance_14 == null)
      new Companion_14();
    return Companion_instance_14;
  }
  function index($this, index, offset) {
    return imul(index, 2) + offset | 0;
  }
  function PointArrayList(capacity) {
    Companion_getInstance_14();
    capacity = capacity === VOID ? 7 : capacity;
    this.z3z_1 = new Mixin();
    this.a40_1 = false;
    this.b40_1 = new FloatArrayList(imul(capacity, 2));
  }
  protoOf(PointArrayList).d1i = function (_set____db54di) {
    this.z3z_1.d1i(_set____db54di);
  };
  protoOf(PointArrayList).e1i = function () {
    return this.z3z_1.e1i();
  };
  protoOf(PointArrayList).l = function () {
    return this.b40_1.l() / 2 | 0;
  };
  protoOf(PointArrayList).kk = function () {
    this.b40_1.e4();
    return this;
  };
  protoOf(PointArrayList).e40 = function (x, y) {
    this.b40_1.c1a(x, y);
    return this;
  };
  protoOf(PointArrayList).f40 = function (x, y) {
    return this.e40(x, y);
  };
  protoOf(PointArrayList).g40 = function (p) {
    return this.e40(p.p3r_1, p.q3r_1);
  };
  protoOf(PointArrayList).h40 = function (p) {
    return this.i40(p);
  };
  protoOf(PointArrayList).i40 = function (p) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>' call
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = p.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.PointArrayList.addAll.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = p.n(n);
        var x = tmp0__anonymous__q1qw7t.c5();
        var y = tmp0__anonymous__q1qw7t.d5();
        this.e40(x, y);
      }
       while (inductionVariable < last);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(PointArrayList).j40 = function (p, index) {
    this.e40(p.v3z(index), p.u3z(index));
  };
  protoOf(PointArrayList).c40 = function (other) {
    this.kk();
    this.h40(other);
    return this;
  };
  protoOf(PointArrayList).y3z = function (out) {
    return out.kk().h40(this);
  };
  protoOf(PointArrayList).v3z = function (index_0) {
    return this.b40_1.z18(index(this, index_0, 0));
  };
  protoOf(PointArrayList).u3z = function (index_0) {
    return this.b40_1.z18(index(this, index_0, 1));
  };
  protoOf(PointArrayList).n = function (index_0) {
    var i = index(this, index_0, 0);
    return new Vector2(this.b40_1.z18(i), this.b40_1.z18(i + 1 | 0));
  };
  protoOf(PointArrayList).u19 = function (index_0, count) {
    this.b40_1.u19(index(this, index_0, 0), imul(count, 2));
    return this;
  };
  protoOf(PointArrayList).k40 = function (index, count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.u19(index, count) : $super.u19.call(this, index, count);
  };
  protoOf(PointArrayList).equals = function (other) {
    var tmp;
    if (other instanceof PointArrayList) {
      tmp = this.b40_1.equals(other.b40_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(PointArrayList).hashCode = function () {
    return this.b40_1.hashCode();
  };
  protoOf(PointArrayList).toString = function () {
    var sb = StringBuilder_init_$Create$();
    sb.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_container = this.n(n);
        var x = tmp1_container.c5();
        var y = tmp1_container.d5();
        if (!(n === 0)) {
          sb.bc(', ');
        }
        sb.ib(_Char___init__impl__6a9atx(40));
        var tmp$ret$0;
        // Inline function 'kotlin.math.round' call
        tmp$ret$0 = round(x);
        if (x === tmp$ret$0) {
          sb.ac(numberToInt(x));
        } else {
          sb.ac(x);
        }
        sb.bc(', ');
        var tmp$ret$1;
        // Inline function 'kotlin.math.round' call
        tmp$ret$1 = round(y);
        if (y === tmp$ret$1) {
          sb.ac(numberToInt(y));
        } else {
          sb.ac(y);
        }
        sb.ib(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.ib(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function pointArrayListOf(p0, p1) {
    return (new PointArrayList(2)).g40(p0).g40(p1);
  }
  function pointArrayListOf_0(p0, p1, p2) {
    return (new PointArrayList(3)).g40(p0).g40(p1).g40(p2);
  }
  function pointArrayListOf_1(p0, p1, p2, p3) {
    return (new PointArrayList(4)).g40(p0).g40(p1).g40(p2).g40(p3);
  }
  function Companion_15() {
    Companion_instance_15 = this;
  }
  var Companion_instance_15;
  function Companion_getInstance_15() {
    if (Companion_instance_15 == null)
      new Companion_15();
    return Companion_instance_15;
  }
  function PointIntArrayList(capacity) {
    Companion_getInstance_15();
    capacity = capacity === VOID ? 7 : capacity;
    this.l40_1 = new Mixin();
    this.m40_1 = false;
    this.n40_1 = new IntArrayList(capacity);
    this.o40_1 = new IntArrayList(capacity);
  }
  protoOf(PointIntArrayList).d1i = function (_set____db54di) {
    this.l40_1.d1i(_set____db54di);
  };
  protoOf(PointIntArrayList).e1i = function () {
    return this.l40_1.e1i();
  };
  protoOf(PointIntArrayList).l = function () {
    return this.n40_1.l();
  };
  protoOf(PointIntArrayList).p = function () {
    return this.l() === 0;
  };
  protoOf(PointIntArrayList).e4 = function () {
    this.n40_1.e4();
    this.o40_1.e4();
  };
  protoOf(PointIntArrayList).p40 = function (x, y) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.PointIntArrayList.add.<anonymous>' call
    var tmp0_this = this;
    tmp0_this.n40_1.t1a(x);
    var tmp1_this = this;
    tmp1_this.o40_1.t1a(y);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(PointIntArrayList).q40 = function (p) {
    return this.p40(p.r40_1, p.s40_1);
  };
  protoOf(PointIntArrayList).v3z = function (index) {
    return this.n40_1.z18(index);
  };
  protoOf(PointIntArrayList).u3z = function (index) {
    return this.o40_1.z18(index);
  };
  protoOf(PointIntArrayList).toString = function () {
    var sb = StringBuilder_init_$Create$();
    sb.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var x = this.v3z(n);
        var y = this.u3z(n);
        if (!(n === 0)) {
          sb.bc(', ');
        }
        sb.ib(_Char___init__impl__6a9atx(40));
        sb.ac(x);
        sb.bc(', ');
        sb.ac(y);
        sb.ib(_Char___init__impl__6a9atx(41));
      }
       while (inductionVariable < last);
    sb.ib(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function _RectCorners___init__impl__w373y9(raw) {
    return raw;
  }
  function _RectCorners___get_raw__impl__yfhw70($this) {
    return $this;
  }
  function Companion_16() {
    Companion_instance_16 = this;
    this.t40_1 = _RectCorners___init__impl__w373y9_0(0.0);
  }
  var Companion_instance_16;
  function Companion_getInstance_16() {
    if (Companion_instance_16 == null)
      new Companion_16();
    return Companion_instance_16;
  }
  function _RectCorners___init__impl__w373y9_0(corner) {
    var tmp = _RectCorners___init__impl__w373y9_2(corner, corner, corner, corner);
    return tmp;
  }
  function _RectCorners___init__impl__w373y9_1(topLeftBottomRight, topRightAndBottomLeft) {
    var tmp = _RectCorners___init__impl__w373y9_2(topLeftBottomRight, topRightAndBottomLeft, topLeftBottomRight, topRightAndBottomLeft);
    return tmp;
  }
  function _RectCorners___init__impl__w373y9_2(topLeft, topRight, bottomRight, bottomLeft) {
    var tmp = _RectCorners___init__impl__w373y9(_Half4Pack___init__impl__htjstd(toHalf(topLeft), toHalf(topRight), toHalf(bottomRight), toHalf(bottomLeft)));
    return tmp;
  }
  function _RectCorners___init__impl__w373y9_3(corner) {
    var tmp = _RectCorners___init__impl__w373y9_0(corner);
    return tmp;
  }
  function _RectCorners___init__impl__w373y9_4(topLeftBottomRight, topRightAndBottomLeft) {
    var tmp = _RectCorners___init__impl__w373y9_1(topLeftBottomRight, topRightAndBottomLeft);
    return tmp;
  }
  function _RectCorners___get_topLeft__impl__yn4qy0($this) {
    return Half__toFloat_impl_solynz(_Half4Pack___get_x__impl__z3n838(_RectCorners___get_raw__impl__yfhw70($this)));
  }
  function _RectCorners___get_topRight__impl__okc9ql($this) {
    return Half__toFloat_impl_solynz(_Half4Pack___get_y__impl__6a0r11(_RectCorners___get_raw__impl__yfhw70($this)));
  }
  function _RectCorners___get_bottomRight__impl__j7ud1v($this) {
    return Half__toFloat_impl_solynz(_Half4Pack___get_z__impl__mjlq16(_RectCorners___get_raw__impl__yfhw70($this)));
  }
  function _RectCorners___get_bottomLeft__impl__ffl7d4($this) {
    return Half__toFloat_impl_solynz(_Half4Pack___get_w__impl__73uctp(_RectCorners___get_raw__impl__yfhw70($this)));
  }
  function RectCorners__toString_impl_tcxxgn($this) {
    return 'RectCorners(raw=' + new Half4Pack($this) + ')';
  }
  function RectCorners__hashCode_impl_7ls0ns($this) {
    return Half4Pack__hashCode_impl_nkvb08($this);
  }
  function RectCorners__equals_impl_oey2lw($this, other) {
    if (!(other instanceof RectCorners))
      return false;
    var tmp0_other_with_cast = other instanceof RectCorners ? other.u40_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function RectCorners(raw) {
    Companion_getInstance_16();
    this.u40_1 = raw;
  }
  protoOf(RectCorners).toString = function () {
    return RectCorners__toString_impl_tcxxgn(this.u40_1);
  };
  protoOf(RectCorners).hashCode = function () {
    return RectCorners__hashCode_impl_7ls0ns(this.u40_1);
  };
  protoOf(RectCorners).equals = function (other) {
    return RectCorners__equals_impl_oey2lw(this.u40_1, other);
  };
  function Companion_17() {
    Companion_instance_17 = this;
    this.k3s_1 = Companion_getInstance_17().v40(0, 0, 0, 0);
    var tmp = this;
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    tmp.l3s_1 = new Rectangle(-Infinity, -Infinity, Infinity, Infinity);
    var tmp_0 = this;
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    tmp_0.m3s_1 = new Rectangle(NaN, NaN, 0.0, 0.0);
  }
  protoOf(Companion_17).n3s = function () {
    return this.m3s_1;
  };
  protoOf(Companion_17).s3w = function () {
    return this.k3s_1;
  };
  protoOf(Companion_17).t3s = function (p, s) {
    return new Rectangle(p.p3r_1, p.q3r_1, s.w40_1, s.x40_1);
  };
  protoOf(Companion_17).v40 = function (x, y, width, height) {
    return Companion_getInstance_17().t3s(Vector2_init_$Create$_0(x, y), Size_init_$Create$_0(width, height));
  };
  protoOf(Companion_17).h3x = function (x, y, width, height) {
    return Companion_getInstance_17().t3s(Vector2_init_$Create$(x, y), Size_init_$Create$(width, height));
  };
  protoOf(Companion_17).y40 = function (left, top, right, bottom) {
    return Companion_getInstance_17().h3x(left, top, right - left, bottom - top);
  };
  protoOf(Companion_17).z40 = function (left, top, right, bottom) {
    return this.y40(left, top, right, bottom);
  };
  protoOf(Companion_17).a41 = function (left, top, right, bottom) {
    return this.y40(left, top, right, bottom);
  };
  protoOf(Companion_17).s3s = function (point1, point2) {
    var tmp = Companion_getInstance_17();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$0 = new Vector2(point2.p3r_1 - point1.p3r_1, point2.q3r_1 - point1.q3r_1);
    return tmp.t3s(point1, toSize(tmp$ret$0));
  };
  protoOf(Companion_17).b41 = function (a, b) {
    return ((a.z3r_1 >= b.z3r_1 ? a.a3s_1 >= b.a3s_1 : false) ? a.z3r_1 + a.b3s_1 <= b.z3r_1 + b.b3s_1 : false) ? a.a3s_1 + a.c3s_1 <= b.a3s_1 + b.c3s_1 : false;
  };
  var Companion_instance_17;
  function Companion_getInstance_17() {
    if (Companion_instance_17 == null)
      new Companion_17();
    return Companion_instance_17;
  }
  function Rectangle(x, y, width, height) {
    Companion_getInstance_17();
    this.z3r_1 = x;
    this.a3s_1 = y;
    this.b3s_1 = width;
    this.c3s_1 = height;
  }
  protoOf(Rectangle).z19 = function () {
    return this;
  };
  protoOf(Rectangle).g3x = function () {
    return this;
  };
  protoOf(Rectangle).l = function () {
    return new Size(this.b3s_1, this.c3s_1);
  };
  protoOf(Rectangle).o3t = function () {
    return this.z3r_1;
  };
  protoOf(Rectangle).p3t = function () {
    return this.a3s_1;
  };
  protoOf(Rectangle).c41 = function () {
    return this.b3s_1;
  };
  protoOf(Rectangle).d41 = function () {
    return this.c3s_1;
  };
  protoOf(Rectangle).e41 = function () {
    return isNaN_0(this.z3r_1);
  };
  protoOf(Rectangle).d3s = function () {
    return this.e41();
  };
  protoOf(Rectangle).e3s = function () {
    return !this.d3s();
  };
  protoOf(Rectangle).toString = function () {
    return this.d3s() ? 'null' : 'Rectangle(x=' + get_niceStr(this.z3r_1) + ', y=' + get_niceStr(this.a3s_1) + ', width=' + get_niceStr(this.b3s_1) + ', height=' + get_niceStr(this.c3s_1) + ')';
  };
  protoOf(Rectangle).f41 = function (scale) {
    return new Rectangle(this.z3r_1 * scale, this.a3s_1 * scale, this.b3s_1 * scale, this.c3s_1 * scale);
  };
  protoOf(Rectangle).g41 = function (that) {
    return this.h41(that.p3r_1, that.q3r_1);
  };
  protoOf(Rectangle).h41 = function (x, y) {
    return (x >= this.f3s() ? x < this.g3s() : false) ? y >= this.h3s() ? y < this.i3s() : false : false;
  };
  protoOf(Rectangle).i41 = function () {
    return Companion_getInstance_7().c3x(this.z3r_1, this.a3s_1, this.b3s_1, this.c3s_1);
  };
  protoOf(Rectangle).f3s = function () {
    return this.z3r_1;
  };
  protoOf(Rectangle).h3s = function () {
    return this.a3s_1;
  };
  protoOf(Rectangle).g3s = function () {
    return this.z3r_1 + this.b3s_1;
  };
  protoOf(Rectangle).i3s = function () {
    return this.a3s_1 + this.c3s_1;
  };
  protoOf(Rectangle).u3s = function () {
    return new Vector2(this.f3s(), this.h3s());
  };
  protoOf(Rectangle).j41 = function () {
    return new Vector2(this.g3s(), this.h3s());
  };
  protoOf(Rectangle).k41 = function () {
    return new Vector2(this.f3s(), this.i3s());
  };
  protoOf(Rectangle).w3s = function () {
    return new Vector2(this.g3s(), this.i3s());
  };
  protoOf(Rectangle).l41 = function () {
    return (this.g3s() + this.f3s()) * 0.5;
  };
  protoOf(Rectangle).m41 = function () {
    return (this.i3s() + this.h3s()) * 0.5;
  };
  protoOf(Rectangle).n41 = function () {
    return new Vector2(this.l41(), this.m41());
  };
  protoOf(Rectangle).o41 = function () {
    var centerX = this.l41();
    var centerY = this.m41();
    return new Circle(this.n41(), Companion_getInstance_21().p41(centerX, centerY, this.g3s(), this.h3s()));
  };
  protoOf(Rectangle).q41 = function (padding) {
    return Companion_getInstance_17().a41(this.f3s() + padding.m3x_1, this.h3s() + padding.j3x_1, this.g3s() - padding.k3x_1, this.i3s() - padding.l3x_1);
  };
  protoOf(Rectangle).r41 = function (that) {
    return this.t41(that) ? this.s41(that) : false;
  };
  protoOf(Rectangle).t41 = function (that) {
    return that.f3s() <= this.g3s() ? that.g3s() >= this.f3s() : false;
  };
  protoOf(Rectangle).s41 = function (that) {
    return that.h3s() <= this.i3s() ? that.i3s() >= this.h3s() : false;
  };
  protoOf(Rectangle).u41 = function (that) {
    var tmp;
    if (this.r41(that)) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      var tmp0_max = this.f3s();
      var tmp1_max = that.f3s();
      tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
      var tmp_0 = tmp$ret$0;
      var tmp$ret$1;
      // Inline function 'kotlin.math.max' call
      var tmp2_max = this.h3s();
      var tmp3_max = that.h3s();
      tmp$ret$1 = Math.max(tmp2_max, tmp3_max);
      var tmp_1 = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.math.min' call
      var tmp4_min = this.g3s();
      var tmp5_min = that.g3s();
      tmp$ret$2 = Math.min(tmp4_min, tmp5_min);
      var tmp_2 = tmp$ret$2;
      var tmp$ret$3;
      // Inline function 'kotlin.math.min' call
      var tmp6_min = this.i3s();
      var tmp7_min = that.i3s();
      tmp$ret$3 = Math.min(tmp6_min, tmp7_min);
      tmp = new Rectangle(tmp_0, tmp_1, tmp_2, tmp$ret$3);
    } else {
      tmp = Companion_getInstance_17().n3s();
    }
    return tmp;
  };
  protoOf(Rectangle).t1 = function () {
    return new RectangleInt(numberToInt(this.z3r_1), numberToInt(this.a3s_1), numberToInt(this.b3s_1), numberToInt(this.c3s_1));
  };
  protoOf(Rectangle).v41 = function (border) {
    return Companion_getInstance_17().a41(this.f3s() - border.t3x_1, this.h3s() - border.q3x_1, this.g3s() + border.r3x_1, this.i3s() + border.s3x_1);
  };
  protoOf(Rectangle).w41 = function (left, top, right, bottom) {
    return Companion_getInstance_17().a41(left, top, right, bottom);
  };
  protoOf(Rectangle).x41 = function (left, top, right, bottom, $super) {
    left = left === VOID ? this.f3s() : left;
    top = top === VOID ? this.h3s() : top;
    right = right === VOID ? this.g3s() : right;
    bottom = bottom === VOID ? this.i3s() : bottom;
    return $super === VOID ? this.w41(left, top, right, bottom) : $super.w41.call(this, left, top, right, bottom);
  };
  protoOf(Rectangle).y41 = function (delta) {
    return this.z41(this.z3r_1 + delta.p3r_1, this.a3s_1 + delta.q3r_1);
  };
  protoOf(Rectangle).a42 = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = this.u3s();
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    var tl = tmp$ret$0;
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp1_transform = this.j41();
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$1 = tmp1_transform;
        break $l$block_0;
      }
      tmp$ret$1 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * tmp1_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * tmp1_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * tmp1_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * tmp1_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    var tr = tmp$ret$1;
    var tmp$ret$2;
    $l$block_1: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp2_transform = this.k41();
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$2 = tmp2_transform;
        break $l$block_1;
      }
      tmp$ret$2 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * tmp2_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * tmp2_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * tmp2_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * tmp2_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    var bl = tmp$ret$2;
    var tmp$ret$3;
    $l$block_2: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp3_transform = this.w3s();
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$3 = tmp3_transform;
        break $l$block_2;
      }
      tmp$ret$3 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * tmp3_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * tmp3_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * tmp3_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * tmp3_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    var br = tmp$ret$3;
    var min = Companion_getInstance_21().q3s(tl, tr, bl, br);
    var max = Companion_getInstance_21().r3s(tl, tr, bl, br);
    return Companion_getInstance_17().s3s(min, max);
  };
  protoOf(Rectangle).b42 = function () {
    return Companion_getInstance_17().s3s(Companion_getInstance_21().v3s(this.u3s(), this.w3s()), Companion_getInstance_21().x3s(this.u3s(), this.w3s()));
  };
  protoOf(Rectangle).c42 = function (x, y, width, height) {
    return new Rectangle(x, y, width, height);
  };
  protoOf(Rectangle).z41 = function (x, y, width, height, $super) {
    x = x === VOID ? this.z3r_1 : x;
    y = y === VOID ? this.a3s_1 : y;
    width = width === VOID ? this.b3s_1 : width;
    height = height === VOID ? this.c3s_1 : height;
    return $super === VOID ? this.c42(x, y, width, height) : $super.c42.call(this, x, y, width, height);
  };
  protoOf(Rectangle).hashCode = function () {
    var result = getNumberHashCode(this.z3r_1);
    result = imul(result, 31) + getNumberHashCode(this.a3s_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.b3s_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.c3s_1) | 0;
    return result;
  };
  protoOf(Rectangle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Rectangle))
      return false;
    var tmp0_other_with_cast = other instanceof Rectangle ? other : THROW_CCE();
    if (!equals(this.z3r_1, tmp0_other_with_cast.z3r_1))
      return false;
    if (!equals(this.a3s_1, tmp0_other_with_cast.a3s_1))
      return false;
    if (!equals(this.b3s_1, tmp0_other_with_cast.b3s_1))
      return false;
    if (!equals(this.c3s_1, tmp0_other_with_cast.c3s_1))
      return false;
    return true;
  };
  function RectangleInt_init_$Init$($this) {
    RectangleInt_init_$Init$_0(Vector2Int_init_$Create$(), _SizeInt___init__impl__vivn9_0(), $this);
    return $this;
  }
  function RectangleInt_init_$Create$() {
    return RectangleInt_init_$Init$(objectCreate(protoOf(RectangleInt)));
  }
  function RectangleInt_init_$Init$_0(position, size, $this) {
    RectangleInt.call($this, position.r40_1, position.s40_1, _SizeInt___get_width__impl__swntg6(size), _SizeInt___get_height__impl__d3sy61(size));
    return $this;
  }
  function RectangleInt_init_$Create$_0(position, size) {
    return RectangleInt_init_$Init$_0(position, size, objectCreate(protoOf(RectangleInt)));
  }
  function Companion_18() {
    Companion_instance_18 = this;
  }
  protoOf(Companion_18).d42 = function (a, b) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = a.f3s();
    var tmp1_min = b.f3s();
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp2_min = a.h3s();
    var tmp3_min = b.h3s();
    tmp$ret$1 = Math.min(tmp2_min, tmp3_min);
    var tmp_0 = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp4_max = a.g3s();
    var tmp5_max = b.g3s();
    tmp$ret$2 = Math.max(tmp4_max, tmp5_max);
    var tmp_1 = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.math.max' call
    var tmp6_max = a.i3s();
    var tmp7_max = b.i3s();
    tmp$ret$3 = Math.max(tmp6_max, tmp7_max);
    return this.z40(tmp, tmp_0, tmp_1, tmp$ret$3);
  };
  protoOf(Companion_18).i42 = function (topLeft, bottomRight) {
    return RectangleInt_init_$Create$_0(topLeft, toSize_0(bottomRight.j42(topLeft)));
  };
  protoOf(Companion_18).z40 = function (left, top, right, bottom) {
    return this.i42(new Vector2Int(left, top), new Vector2Int(right, bottom));
  };
  var Companion_instance_18;
  function Companion_getInstance_18() {
    if (Companion_instance_18 == null)
      new Companion_18();
    return Companion_instance_18;
  }
  function RectangleInt(x, y, width, height) {
    Companion_getInstance_18();
    this.e42_1 = x;
    this.f42_1 = y;
    this.g42_1 = width;
    this.h42_1 = height;
  }
  protoOf(RectangleInt).k42 = function () {
    return Companion_getInstance_17().v40(this.e42_1, this.f42_1, this.g42_1, this.h42_1);
  };
  protoOf(RectangleInt).l42 = function () {
    return imul(this.g42_1, this.h42_1);
  };
  protoOf(RectangleInt).f3s = function () {
    return this.e42_1;
  };
  protoOf(RectangleInt).h3s = function () {
    return this.f42_1;
  };
  protoOf(RectangleInt).g3s = function () {
    return this.e42_1 + this.g42_1 | 0;
  };
  protoOf(RectangleInt).i3s = function () {
    return this.f42_1 + this.h42_1 | 0;
  };
  protoOf(RectangleInt).m42 = function (left, top, right, bottom, clamped) {
    var left_0 = !clamped ? left : coerceIn(left, 0, this.g42_1);
    var right_0 = !clamped ? right : coerceIn(right, 0, this.g42_1);
    var top_0 = !clamped ? top : coerceIn(top, 0, this.h42_1);
    var bottom_0 = !clamped ? bottom : coerceIn(bottom, 0, this.h42_1);
    return Companion_getInstance_18().z40(this.e42_1 + left_0 | 0, this.f42_1 + top_0 | 0, this.e42_1 + right_0 | 0, this.f42_1 + bottom_0 | 0);
  };
  protoOf(RectangleInt).v41 = function (border) {
    return Companion_getInstance_18().z40(this.f3s() - border.t3x_1 | 0, this.h3s() - border.q3x_1 | 0, this.g3s() + border.r3x_1 | 0, this.i3s() + border.s3x_1 | 0);
  };
  protoOf(RectangleInt).toString = function () {
    return 'Rectangle(x=' + this.e42_1 + ', y=' + this.f42_1 + ', width=' + this.g42_1 + ', height=' + this.h42_1 + ')';
  };
  protoOf(RectangleInt).hashCode = function () {
    var result = this.e42_1;
    result = imul(result, 31) + this.f42_1 | 0;
    result = imul(result, 31) + this.g42_1 | 0;
    result = imul(result, 31) + this.h42_1 | 0;
    return result;
  };
  protoOf(RectangleInt).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectangleInt))
      return false;
    var tmp0_other_with_cast = other instanceof RectangleInt ? other : THROW_CCE();
    if (!(this.e42_1 === tmp0_other_with_cast.e42_1))
      return false;
    if (!(this.f42_1 === tmp0_other_with_cast.f42_1))
      return false;
    if (!(this.g42_1 === tmp0_other_with_cast.g42_1))
      return false;
    if (!(this.h42_1 === tmp0_other_with_cast.h42_1))
      return false;
    return true;
  };
  function RoundRectangle$lazyVectorPath$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.shape.buildVectorPath' call
      var tmp0_buildVectorPath = new VectorPath();
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.RoundRectangle.lazyVectorPath$delegate.<anonymous>.<anonymous>' call
      tmp0_buildVectorPath.n42(this$0);
      tmp$ret$0 = tmp0_buildVectorPath;
      tmp$ret$1 = tmp$ret$0;
      return tmp$ret$1;
    };
  }
  function RoundRectangle(rect, corners) {
    AbstractShape2D.call(this);
    this.o42_1 = rect;
    this.p42_1 = corners;
    var tmp = this;
    tmp.q42_1 = lazy(RoundRectangle$lazyVectorPath$delegate$lambda(this));
  }
  protoOf(RoundRectangle).toString = function () {
    return 'RoundRectangle(rect=' + this.o42_1 + ', corners=' + new RectCorners(this.p42_1) + ')';
  };
  protoOf(RoundRectangle).hashCode = function () {
    var result = this.o42_1.hashCode();
    result = imul(result, 31) + RectCorners__hashCode_impl_7ls0ns(this.p42_1) | 0;
    return result;
  };
  protoOf(RoundRectangle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RoundRectangle))
      return false;
    var tmp0_other_with_cast = other instanceof RoundRectangle ? other : THROW_CCE();
    if (!this.o42_1.equals(tmp0_other_with_cast.o42_1))
      return false;
    if (!equals(this.p42_1, tmp0_other_with_cast.p42_1))
      return false;
    return true;
  };
  function Scale_init_$Init$(scale, $this) {
    Scale.call($this, scale, scale);
    return $this;
  }
  function Scale_init_$Create$(scale) {
    return Scale_init_$Init$(scale, objectCreate(protoOf(Scale)));
  }
  function Scale_init_$Init$_0(scaleX, scaleY, $this) {
    Scale.call($this, scaleX, scaleY);
    return $this;
  }
  function Scale_init_$Create$_0(scaleX, scaleY) {
    return Scale_init_$Init$_0(scaleX, scaleY, objectCreate(protoOf(Scale)));
  }
  function Scale_init_$Init$_1(scaleX, scaleY, $this) {
    Scale.call($this, scaleX, scaleY);
    return $this;
  }
  function Scale_init_$Create$_1(scaleX, scaleY) {
    return Scale_init_$Init$_1(scaleX, scaleY, objectCreate(protoOf(Scale)));
  }
  function Scale(scaleX, scaleY) {
    this.z3x_1 = scaleX;
    this.a3y_1 = scaleY;
  }
  protoOf(Scale).r42 = function () {
    return this.z3x_1 * 0.5 + this.a3y_1 * 0.5;
  };
  protoOf(Scale).s42 = function () {
    return this.r42();
  };
  protoOf(Scale).t42 = function () {
    return this.r42();
  };
  protoOf(Scale).toString = function () {
    return 'Scale(scaleX=' + this.z3x_1 + ', scaleY=' + this.a3y_1 + ')';
  };
  protoOf(Scale).hashCode = function () {
    var result = getNumberHashCode(this.z3x_1);
    result = imul(result, 31) + getNumberHashCode(this.a3y_1) | 0;
    return result;
  };
  protoOf(Scale).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Scale))
      return false;
    var tmp0_other_with_cast = other instanceof Scale ? other : THROW_CCE();
    if (!equals(this.z3x_1, tmp0_other_with_cast.z3x_1))
      return false;
    if (!equals(this.a3y_1, tmp0_other_with_cast.a3y_1))
      return false;
    return true;
  };
  function toVector2(_this__u8e3s4) {
    return new Vector2(_this__u8e3s4.z3x_1, _this__u8e3s4.a3y_1);
  }
  function ScaleMode$Companion$COVER$lambda(i, c) {
    return i.f41(toVector2(c.u42(i)).v42());
  }
  function ScaleMode$Companion$SHOW_ALL$lambda(i, c) {
    return i.f41(toVector2(c.u42(i)).w42());
  }
  function ScaleMode$Companion$EXACT$lambda(i, c) {
    return c;
  }
  function ScaleMode$Companion$NO_SCALE$lambda(i, c) {
    return i;
  }
  function Companion_19() {
    Companion_instance_19 = this;
    var tmp = this;
    tmp.x42_1 = new ScaleMode('COVER', ScaleMode$Companion$COVER$lambda);
    var tmp_0 = this;
    tmp_0.y42_1 = new ScaleMode('SHOW_ALL', ScaleMode$Companion$SHOW_ALL$lambda);
    var tmp_1 = this;
    tmp_1.z42_1 = new ScaleMode('EXACT', ScaleMode$Companion$EXACT$lambda);
    var tmp_2 = this;
    tmp_2.a43_1 = new ScaleMode('NO_SCALE', ScaleMode$Companion$NO_SCALE$lambda);
  }
  protoOf(Companion_19).b43 = function () {
    return this.y42_1;
  };
  protoOf(Companion_19).c43 = function () {
    return this.z42_1;
  };
  var Companion_instance_19;
  function Companion_getInstance_19() {
    if (Companion_instance_19 == null)
      new Companion_19();
    return Companion_instance_19;
  }
  function ScaleMode(name, transform) {
    Companion_getInstance_19();
    name = name === VOID ? null : name;
    this.d43_1 = name;
    this.e43_1 = transform;
  }
  protoOf(ScaleMode).toString = function () {
    return 'ScaleMode(' + this.d43_1 + ')';
  };
  protoOf(ScaleMode).f43 = function (item, container) {
    return toInt(this.e43_1(toFloat(item), toFloat(container)));
  };
  function Companion_20() {
    Companion_instance_20 = this;
    this.g43_1 = new Size(0.0, 0.0);
  }
  var Companion_instance_20;
  function Companion_getInstance_20() {
    if (Companion_instance_20 == null)
      new Companion_20();
    return Companion_instance_20;
  }
  function Size_init_$Init$(width, height, $this) {
    Size.call($this, width, height);
    return $this;
  }
  function Size_init_$Create$(width, height) {
    return Size_init_$Init$(width, height, objectCreate(protoOf(Size)));
  }
  function Size_init_$Init$_0(width, height, $this) {
    Size.call($this, width, height);
    return $this;
  }
  function Size_init_$Create$_0(width, height) {
    return Size_init_$Init$_0(width, height, objectCreate(protoOf(Size)));
  }
  function Size(width, height) {
    Companion_getInstance_20();
    this.w40_1 = width;
    this.x40_1 = height;
  }
  protoOf(Size).c41 = function () {
    return this.w40_1;
  };
  protoOf(Size).d41 = function () {
    return this.x40_1;
  };
  protoOf(Size).h43 = function (scale) {
    return new Size(this.w40_1 * scale.z3x_1, this.x40_1 * scale.a3y_1);
  };
  protoOf(Size).f41 = function (s) {
    return new Size(this.w40_1 * s, this.x40_1 * s);
  };
  protoOf(Size).u42 = function (other) {
    return new Scale(this.w40_1 / other.w40_1, this.x40_1 / other.x40_1);
  };
  protoOf(Size).toString = function () {
    return 'Size(width=' + get_niceStr(this.w40_1) + ', height=' + get_niceStr(this.x40_1) + ')';
  };
  protoOf(Size).c5 = function () {
    return this.w40_1;
  };
  protoOf(Size).d5 = function () {
    return this.x40_1;
  };
  protoOf(Size).i43 = function (width, height) {
    return new Size(width, height);
  };
  protoOf(Size).j43 = function (width, height, $super) {
    width = width === VOID ? this.w40_1 : width;
    height = height === VOID ? this.x40_1 : height;
    return $super === VOID ? this.i43(width, height) : $super.i43.call(this, width, height);
  };
  protoOf(Size).hashCode = function () {
    var result = getNumberHashCode(this.w40_1);
    result = imul(result, 31) + getNumberHashCode(this.x40_1) | 0;
    return result;
  };
  protoOf(Size).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Size))
      return false;
    var tmp0_other_with_cast = other instanceof Size ? other : THROW_CCE();
    if (!equals(this.w40_1, tmp0_other_with_cast.w40_1))
      return false;
    if (!equals(this.x40_1, tmp0_other_with_cast.x40_1))
      return false;
    return true;
  };
  function toSize(_this__u8e3s4) {
    return new Size(_this__u8e3s4.p3r_1, _this__u8e3s4.q3r_1);
  }
  function toFloat(_this__u8e3s4) {
    return new Size(_SizeInt___get_width__impl__swntg6(_this__u8e3s4), _SizeInt___get_height__impl__d3sy61(_this__u8e3s4));
  }
  function toSize_0(_this__u8e3s4) {
    return _SizeInt___init__impl__vivn9_1(_this__u8e3s4.r40_1, _this__u8e3s4.s40_1);
  }
  function toInt(_this__u8e3s4) {
    return _SizeInt___init__impl__vivn9_1(numberToInt(_this__u8e3s4.w40_1), numberToInt(_this__u8e3s4.x40_1));
  }
  function _SizeInt___init__impl__vivn9(raw) {
    return raw;
  }
  function _SizeInt___get_raw__impl__tcf45k($this) {
    return $this;
  }
  function _SizeInt___get_width__impl__swntg6($this) {
    return get_i0(_SizeInt___get_raw__impl__tcf45k($this));
  }
  function _SizeInt___get_height__impl__d3sy61($this) {
    return get_i1(_SizeInt___get_raw__impl__tcf45k($this));
  }
  function _SizeInt___init__impl__vivn9_0() {
    var tmp = _SizeInt___init__impl__vivn9_1(0, 0);
    return tmp;
  }
  function _SizeInt___init__impl__vivn9_1(width, height) {
    var tmp = _SizeInt___init__impl__vivn9(int2PackOf(width, height));
    return tmp;
  }
  function SizeInt__toString_impl_bou47h($this) {
    return 'SizeInt(raw=' + $this + ')';
  }
  function SizeInt__hashCode_impl_xg010c($this) {
    return $this.hashCode();
  }
  function SizeInt__equals_impl_rrt54w($this, other) {
    if (!(other instanceof SizeInt))
      return false;
    var tmp0_other_with_cast = other instanceof SizeInt ? other.k43_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function SizeInt(raw) {
    this.k43_1 = raw;
  }
  protoOf(SizeInt).toString = function () {
    return SizeInt__toString_impl_bou47h(this.k43_1);
  };
  protoOf(SizeInt).hashCode = function () {
    return SizeInt__hashCode_impl_xg010c(this.k43_1);
  };
  protoOf(SizeInt).equals = function (other) {
    return SizeInt__equals_impl_rrt54w(this.k43_1, other);
  };
  function square($this, x) {
    return x * x;
  }
  function Vector2_init_$Init$(x, y, $this) {
    Vector2.call($this, x, y);
    return $this;
  }
  function Vector2_init_$Create$(x, y) {
    return Vector2_init_$Init$(x, y, objectCreate(protoOf(Vector2)));
  }
  function Vector2_init_$Init$_0(x, y, $this) {
    Vector2.call($this, x, y);
    return $this;
  }
  function Vector2_init_$Create$_0(x, y) {
    return Vector2_init_$Init$_0(x, y, objectCreate(protoOf(Vector2)));
  }
  function Vector2_init_$Init$_1($this) {
    Vector2.call($this, 0.0, 0.0);
    return $this;
  }
  function Vector2_init_$Create$_1() {
    return Vector2_init_$Init$_1(objectCreate(protoOf(Vector2)));
  }
  function Companion_21() {
    Companion_instance_21 = this;
    this.r3r_1 = new Vector2(0.0, 0.0);
    var tmp = this;
    FloatCompanionObject_getInstance();
    FloatCompanionObject_getInstance();
    tmp.s3r_1 = new Vector2(NaN, NaN);
    this.t3r_1 = new Vector2(-1.0, 0.0);
    this.u3r_1 = new Vector2(1.0, 0.0);
    this.v3r_1 = new Vector2(0.0, 1.0);
    this.w3r_1 = new Vector2(0.0, -1.0);
    this.x3r_1 = new Vector2(0.0, -1.0);
    this.y3r_1 = new Vector2(0.0, 1.0);
  }
  protoOf(Companion_21).l43 = function (a, b, up) {
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp8_between = Companion_getInstance_0();
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp4_between = a.p3r_1;
    var tmp5_between = a.q3r_1;
    var tmp6_between = b.p3r_1;
    var tmp7_between = b.q3r_1;
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp0_between = tmp4_between;
    var tmp1_between = tmp5_between;
    var tmp2_between = tmp6_between;
    var tmp3_between = tmp7_between;
    tmp$ret$0 = Angle_between(tmp0_between, tmp1_between, tmp2_between, tmp3_between, up);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(Companion_21).m43 = function (a, b, up, $super) {
    up = up === VOID ? this.v3r_1 : up;
    return $super === VOID ? this.l43(a, b, up) : $super.l43.call(this, a, b, up).e3r_1;
  };
  protoOf(Companion_21).n43 = function (a, b, up) {
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp3_fromRadians = Companion_getInstance_0();
    var tmp$ret$0;
    // Inline function 'kotlin.math.acos' call
    var tmp0_acos = a.o43(b) / (a.hf() * b.hf());
    tmp$ret$0 = Math.acos(tmp0_acos);
    var tmp4_fromRadians = tmp$ret$0;
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp2_fromRadians = tmp4_fromRadians;
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$1 = tmp2_fromRadians / get_PI2();
    var tmp1_fromRatio = tmp$ret$1;
    tmp$ret$2 = _Angle___init__impl__g23q1m(tmp1_fromRatio);
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    return adjustFromUp(tmp$ret$4, up);
  };
  protoOf(Companion_21).p43 = function (a, b, up, $super) {
    up = up === VOID ? this.v3r_1 : up;
    return $super === VOID ? this.n43(a, b, up) : $super.n43.call(this, a, b, up).e3r_1;
  };
  protoOf(Companion_21).u3w = function (x1, y1, x2, y2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = x1 - x2;
    var tmp1_hypot = y1 - y2;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Companion_21).p41 = function (x1, y1, x2, y2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = x1 - x2;
    var tmp1_hypot = y1 - y2;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Companion_21).v3w = function (x1, y1, x2, y2) {
    return this.p41(x1, y1, x2, y2);
  };
  protoOf(Companion_21).q43 = function (a, b) {
    return this.p41(a.p3r_1, a.q3r_1, b.p3r_1, b.q3r_1);
  };
  protoOf(Companion_21).f3t = function (a, b) {
    return this.r43(a.p3r_1, a.q3r_1, b.p3r_1, b.q3r_1);
  };
  protoOf(Companion_21).r43 = function (x1, y1, x2, y2) {
    return square(this, x1 - x2) + square(this, y1 - y2);
  };
  protoOf(Companion_21).s43 = function (ax, ay, bx, by) {
    return ax * by - bx * ay;
  };
  protoOf(Companion_21).t43 = function (p1, p2) {
    return this.s43(p1.p3r_1, p1.q3r_1, p2.p3r_1, p2.q3r_1);
  };
  protoOf(Companion_21).v3s = function (p1, p2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = p1.p3r_1;
    var tmp1_min = p2.p3r_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp2_min = p1.q3r_1;
    var tmp3_min = p2.q3r_1;
    tmp$ret$1 = Math.min(tmp2_min, tmp3_min);
    return new Vector2(tmp, tmp$ret$1);
  };
  protoOf(Companion_21).q3s = function (p1, p2, p3, p4) {
    return new Vector2(min(p1.p3r_1, p2.p3r_1, p3.p3r_1, p4.p3r_1), min(p1.q3r_1, p2.q3r_1, p3.q3r_1, p4.q3r_1));
  };
  protoOf(Companion_21).x3s = function (p1, p2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = p1.p3r_1;
    var tmp1_max = p2.p3r_1;
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp2_max = p1.q3r_1;
    var tmp3_max = p2.q3r_1;
    tmp$ret$1 = Math.max(tmp2_max, tmp3_max);
    return new Vector2(tmp, tmp$ret$1);
  };
  protoOf(Companion_21).r3s = function (p1, p2, p3, p4) {
    return new Vector2(max(p1.p3r_1, p2.p3r_1, p3.p3r_1, p4.p3r_1), max(p1.q3r_1, p2.q3r_1, p3.q3r_1, p4.q3r_1));
  };
  var Companion_instance_21;
  function Companion_getInstance_21() {
    if (Companion_instance_21 == null)
      new Companion_21();
    return Companion_instance_21;
  }
  function Vector2(x, y) {
    Companion_getInstance_21();
    this.p3r_1 = x;
    this.q3r_1 = y;
  }
  protoOf(Vector2).u43 = function () {
    return this.p3r_1;
  };
  protoOf(Vector2).v43 = function () {
    return this.q3r_1;
  };
  protoOf(Vector2).o3t = function () {
    return this.p3r_1;
  };
  protoOf(Vector2).p3t = function () {
    return this.q3r_1;
  };
  protoOf(Vector2).w42 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.p3r_1;
    var tmp1_min = this.q3r_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  };
  protoOf(Vector2).v42 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.p3r_1;
    var tmp1_max = this.q3r_1;
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    return tmp$ret$0;
  };
  protoOf(Vector2).w43 = function (x, y) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = x - this.p3r_1;
    var tmp1_hypot = y - this.q3r_1;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Vector2).x43 = function (that) {
    return this.w43(that.p3r_1, that.q3r_1);
  };
  protoOf(Vector2).o43 = function (that) {
    return this.p3r_1 * that.p3r_1 + this.q3r_1 * that.q3r_1;
  };
  protoOf(Vector2).y43 = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$0 = this;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * this.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * this.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * this.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * this.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    return tmp$ret$0.p3r_1;
  };
  protoOf(Vector2).z43 = function (m) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (_Matrix___get_isNIL__impl__n63x5q(m)) {
        tmp$ret$0 = this;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * this.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * this.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * this.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * this.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
    }
    return tmp$ret$0.q3r_1;
  };
  protoOf(Vector2).hf = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = this.p3r_1;
    var tmp1_hypot = this.q3r_1;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Vector2).a44 = function () {
    var x = this.p3r_1;
    var y = this.q3r_1;
    return x * x + y * y;
  };
  protoOf(Vector2).b44 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.hypot' call
    var tmp0_hypot = this.p3r_1;
    var tmp1_hypot = this.q3r_1;
    tmp$ret$0 = hypot(tmp0_hypot, tmp1_hypot);
    return tmp$ret$0;
  };
  protoOf(Vector2).c44 = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp0_times = 1.0 / this.b44();
    tmp$ret$0 = new Vector2(this.p3r_1 * tmp0_times, this.q3r_1 * tmp0_times);
    return tmp$ret$0;
  };
  protoOf(Vector2).d44 = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.div' call
    var tmp0_div = this.hf();
    tmp$ret$0 = new Vector2(this.p3r_1 / tmp0_div, this.q3r_1 / tmp0_div);
    return tmp$ret$0;
  };
  protoOf(Vector2).e44 = function () {
    return new Vector2(-this.q3r_1, this.p3r_1);
  };
  protoOf(Vector2).f44 = function (other, epsilon) {
    return isAlmostEquals(this.p3r_1, other.p3r_1, epsilon) ? isAlmostEquals(this.q3r_1, other.q3r_1, epsilon) : false;
  };
  protoOf(Vector2).g44 = function (other, epsilon, $super) {
    epsilon = epsilon === VOID ? 1.0E-5 : epsilon;
    return $super === VOID ? this.f44(other, epsilon) : $super.f44.call(this, other, epsilon);
  };
  protoOf(Vector2).h44 = function () {
    return '(' + get_niceStr(this.p3r_1) + ', ' + get_niceStr(this.q3r_1) + ')';
  };
  protoOf(Vector2).toString = function () {
    return this.h44();
  };
  protoOf(Vector2).c5 = function () {
    return this.p3r_1;
  };
  protoOf(Vector2).d5 = function () {
    return this.q3r_1;
  };
  protoOf(Vector2).hashCode = function () {
    var result = getNumberHashCode(this.p3r_1);
    result = imul(result, 31) + getNumberHashCode(this.q3r_1) | 0;
    return result;
  };
  protoOf(Vector2).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2 ? other : THROW_CCE();
    if (!equals(this.p3r_1, tmp0_other_with_cast.p3r_1))
      return false;
    if (!equals(this.q3r_1, tmp0_other_with_cast.q3r_1))
      return false;
    return true;
  };
  function toInt_0(_this__u8e3s4) {
    return new Vector2Int(numberToInt(_this__u8e3s4.p3r_1), numberToInt(_this__u8e3s4.q3r_1));
  }
  function Companion_22() {
    Companion_instance_22 = this;
    this.i44_1 = new Vector2Int(0, 0);
  }
  var Companion_instance_22;
  function Companion_getInstance_22() {
    if (Companion_instance_22 == null)
      new Companion_22();
    return Companion_instance_22;
  }
  function Vector2Int_init_$Init$($this) {
    Vector2Int.call($this, 0, 0);
    return $this;
  }
  function Vector2Int_init_$Create$() {
    return Vector2Int_init_$Init$(objectCreate(protoOf(Vector2Int)));
  }
  function Vector2Int(x, y) {
    Companion_getInstance_22();
    this.r40_1 = x;
    this.s40_1 = y;
  }
  protoOf(Vector2Int).j42 = function (that) {
    return new Vector2Int(this.r40_1 - that.r40_1 | 0, this.s40_1 - that.s40_1 | 0);
  };
  protoOf(Vector2Int).toString = function () {
    return '(' + this.r40_1 + ', ' + this.s40_1 + ')';
  };
  protoOf(Vector2Int).hashCode = function () {
    var result = this.r40_1;
    result = imul(result, 31) + this.s40_1 | 0;
    return result;
  };
  protoOf(Vector2Int).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector2Int))
      return false;
    var tmp0_other_with_cast = other instanceof Vector2Int ? other : THROW_CCE();
    if (!(this.r40_1 === tmp0_other_with_cast.r40_1))
      return false;
    if (!(this.s40_1 === tmp0_other_with_cast.s40_1))
      return false;
    return true;
  };
  function Companion_23() {
    Companion_instance_23 = this;
    this.j44_1 = new Vector4(0.0, 0.0, 0.0, 0.0);
    this.k44_1 = new Vector4(1.0, 1.0, 1.0, 1.0);
  }
  var Companion_instance_23;
  function Companion_getInstance_23() {
    if (Companion_instance_23 == null)
      new Companion_23();
    return Companion_instance_23;
  }
  function Vector4(x, y, z, w) {
    Companion_getInstance_23();
    this.l44_1 = x;
    this.m44_1 = y;
    this.n44_1 = z;
    this.o44_1 = w;
  }
  protoOf(Vector4).toString = function () {
    return 'Vector4(' + get_niceStr(this.l44_1) + ', ' + get_niceStr(this.m44_1) + ', ' + get_niceStr(this.n44_1) + ', ' + get_niceStr(this.o44_1) + ')';
  };
  protoOf(Vector4).hashCode = function () {
    var result = getNumberHashCode(this.l44_1);
    result = imul(result, 31) + getNumberHashCode(this.m44_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.n44_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.o44_1) | 0;
    return result;
  };
  protoOf(Vector4).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Vector4))
      return false;
    var tmp0_other_with_cast = other instanceof Vector4 ? other : THROW_CCE();
    if (!equals(this.l44_1, tmp0_other_with_cast.l44_1))
      return false;
    if (!equals(this.m44_1, tmp0_other_with_cast.m44_1))
      return false;
    if (!equals(this.n44_1, tmp0_other_with_cast.n44_1))
      return false;
    if (!equals(this.o44_1, tmp0_other_with_cast.o44_1))
      return false;
    return true;
  };
  function IVectorArrayList() {
  }
  function checkDimensions($this, dim) {
    if (!(dim === $this.p44_1)) {
      // Inline function 'kotlin.error' call
      var tmp0_error = 'Invalid dimensions ' + dim + ' != ' + $this.p44_1;
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
  }
  function VectorArrayList$vectorToStringBuilder$lambda(this$0, $index, $roundDecimalPlaces) {
    return function ($this$appendGenericArray, it) {
      var v = this$0.a19($index, it);
      appendNice_0($this$appendGenericArray, !($roundDecimalPlaces == null) ? roundDecimalPlaces_0(v, $roundDecimalPlaces) : v);
      return Unit_getInstance();
    };
  }
  function VectorArrayList(dimensions, capacity) {
    capacity = capacity === VOID ? 7 : capacity;
    this.p44_1 = dimensions;
    this.q44_1 = new Mixin();
    this.r44_1 = new FloatArrayList(imul(capacity, this.p44_1));
    this.s44_1 = false;
  }
  protoOf(VectorArrayList).t3z = function () {
    return this.p44_1;
  };
  protoOf(VectorArrayList).d1i = function (_set____db54di) {
    this.q44_1.d1i(_set____db54di);
  };
  protoOf(VectorArrayList).e1i = function () {
    return this.q44_1.e1i();
  };
  protoOf(VectorArrayList).l = function () {
    return this.r44_1.l() / this.p44_1 | 0;
  };
  protoOf(VectorArrayList).a19 = function (index, dim) {
    return this.r44_1.n(imul(index, this.p44_1) + dim | 0);
  };
  protoOf(VectorArrayList).c1a = function (v0, v1) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    checkDimensions(this, 2);
    var tmp0_also = Unit_getInstance();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.VectorArrayList.add.<anonymous>' call
    this.r44_1.c1a(v0, v1);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(VectorArrayList).d1a = function (v0, v1, v2, v3) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    checkDimensions(this, 4);
    var tmp0_also = Unit_getInstance();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.VectorArrayList.add.<anonymous>' call
    this.r44_1.d1a(v0, v1, v2, v3);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(VectorArrayList).e1a = function (v0, v1, v2, v3, v4, v5) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    checkDimensions(this, 6);
    var tmp0_also = Unit_getInstance();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.VectorArrayList.add.<anonymous>' call
    this.r44_1.e1a(v0, v1, v2, v3, v4, v5);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(VectorArrayList).o19 = function (v0, v1) {
    return this.c1a(v0, v1);
  };
  protoOf(VectorArrayList).t44 = function (v0, v1, v2, v3, v4, v5) {
    return this.e1a(v0, v1, v2, v3, v4, v5);
  };
  protoOf(VectorArrayList).u44 = function (index, out, roundDecimalPlaces) {
    appendGenericArray(out, this.p44_1, VectorArrayList$vectorToStringBuilder$lambda(this, index, roundDecimalPlaces));
  };
  protoOf(VectorArrayList).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof VectorArrayList) {
      tmp_0 = this.p44_1 === other.p44_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.r44_1.equals(other.r44_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(VectorArrayList).hashCode = function () {
    return this.r44_1.hashCode();
  };
  protoOf(VectorArrayList).toString = function () {
    return this.v44(null);
  };
  protoOf(VectorArrayList).v44 = function (roundDecimalPlaces) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.VectorArrayList.toString.<anonymous>' call
    tmp0_apply.bc('VectorArrayList[' + this.l() + '](\n');
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          tmp0_apply.bc(', \n');
        }
        tmp0_apply.bc('   ');
        this.u44(n, tmp0_apply, roundDecimalPlaces);
      }
       while (inductionVariable < last);
    tmp0_apply.bc('\n)');
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  protoOf(VectorArrayList).e4 = function () {
    this.r44_1.e4();
  };
  function appendGenericArray(_this__u8e3s4, size, appendElement) {
    _this__u8e3s4.bc('[');
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          _this__u8e3s4.bc(', ');
        }
        appendElement(_this__u8e3s4, n);
      }
       while (inductionVariable < size);
    _this__u8e3s4.bc(']');
  }
  function Arc() {
    Arc_instance = this;
    this.w44_1 = 0.5522847498307933;
  }
  protoOf(Arc).x44 = function (out, a, c, r) {
    if (out.p()) {
      out.y44(a);
    }
    var b = out.z44();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$0 = new Vector2(b.p3r_1 - a.p3r_1, b.q3r_1 - a.q3r_1);
    var AB = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$1 = new Vector2(c.p3r_1 - a.p3r_1, c.q3r_1 - a.q3r_1);
    var AC = tmp$ret$1;
    var angle = _Angle___get_radiansD__impl__thfj8f(Companion_getInstance_21().p43(AB, AC)) * 0.5;
    var tmp$ret$2;
    // Inline function 'kotlin.math.sin' call
    var tmp0_sin = get_PI() / 2.0 - angle;
    tmp$ret$2 = Math.sin(tmp0_sin);
    var tmp = r * tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.math.sin' call
    tmp$ret$3 = Math.sin(angle);
    var x = tmp / tmp$ret$3;
    var tmp$ret$6;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp2_times = AB.d44();
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp1_times = x;
    tmp$ret$4 = new Vector2(tmp2_times.p3r_1 * tmp1_times, tmp2_times.q3r_1 * tmp1_times);
    tmp$ret$5 = tmp$ret$4;
    var tmp3_plus = tmp$ret$5;
    tmp$ret$6 = new Vector2(a.p3r_1 + tmp3_plus.p3r_1, a.q3r_1 + tmp3_plus.q3r_1);
    var A = tmp$ret$6;
    var tmp$ret$9;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$8;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp5_times = AC.d44();
    var tmp$ret$7;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp4_times = x;
    tmp$ret$7 = new Vector2(tmp5_times.p3r_1 * tmp4_times, tmp5_times.q3r_1 * tmp4_times);
    tmp$ret$8 = tmp$ret$7;
    var tmp6_plus = tmp$ret$8;
    tmp$ret$9 = new Vector2(a.p3r_1 + tmp6_plus.p3r_1, a.q3r_1 + tmp6_plus.q3r_1);
    var B = tmp$ret$9;
    out.a45(A);
    out.b45(a, B);
  };
  protoOf(Arc).c45 = function (out, center, r, start, end, counterclockwise) {
    var tmp0_container = center;
    var x = tmp0_container.c5();
    var y = tmp0_container.d5();
    var startAngle = _Angle___get_normalized__impl__9s30ys(start);
    var endAngle1 = _Angle___get_normalized__impl__9s30ys(end);
    var endAngle = Angle__compareTo_impl_a0hfds(endAngle1, startAngle) < 0 ? Angle__plus_impl_26v37k(endAngle1, Companion_getInstance_0().k3r_1) : endAngle1;
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.min' call
    var tmp8_min = Companion_getInstance_0().k3r_1;
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.abs' call
    var tmp3_abs = Angle__minus_impl_xeau7k(endAngle, startAngle);
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp1_fromRatio = Companion_getInstance_0();
    var tmp$ret$0;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp0__get_absoluteValue__nukmtt = _Angle___get_ratio__impl__ap3on4(tmp3_abs);
    tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
    var tmp2_fromRatio = tmp$ret$0;
    tmp$ret$1 = _Angle___init__impl__g23q1m(tmp2_fromRatio);
    tmp$ret$2 = tmp$ret$1;
    var tmp9_min = tmp$ret$2;
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp6_fromRatio = Companion_getInstance_0();
    var tmp$ret$3;
    // Inline function 'kotlin.math.min' call
    var tmp4_min = _Angle___get_ratio__impl__ap3on4(tmp8_min);
    var tmp5_min = _Angle___get_ratio__impl__ap3on4(tmp9_min);
    tmp$ret$3 = Math.min(tmp4_min, tmp5_min);
    var tmp7_fromRatio = tmp$ret$3;
    tmp$ret$4 = _Angle___init__impl__g23q1m(tmp7_fromRatio);
    tmp$ret$5 = tmp$ret$4;
    var remainingAngle = tmp$ret$5;
    if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(remainingAngle), Companion_getInstance_0().f3r_1) < 0 ? !equals(start, end) : false)
      remainingAngle = Companion_getInstance_0().k3r_1;
    var sgn1 = Angle__compareTo_impl_a0hfds(startAngle, endAngle) < 0 ? 1 : -1;
    var sgn = counterclockwise ? -sgn1 | 0 : sgn1;
    if (counterclockwise) {
      remainingAngle = Angle__minus_impl_xeau7k(Companion_getInstance_0().k3r_1, remainingAngle);
      if (Angle__compareTo_impl_a0hfds(_Angle___get_absoluteValue__impl__4qoakv(remainingAngle), Companion_getInstance_0().f3r_1) < 0 ? !equals(start, end) : false)
        remainingAngle = Companion_getInstance_0().k3r_1;
    }
    var a1 = startAngle;
    var index = 0;
    while (Angle__compareTo_impl_a0hfds(remainingAngle, Companion_getInstance_0().f3r_1) > 0) {
      var tmp = a1;
      var tmp$ret$8;
      // Inline function 'korlibs.math.geom.min' call
      var tmp14_min = remainingAngle;
      var tmp15_min = Companion_getInstance_0().h3r_1;
      var tmp$ret$7;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp12_fromRatio = Companion_getInstance_0();
      var tmp$ret$6;
      // Inline function 'kotlin.math.min' call
      var tmp10_min = _Angle___get_ratio__impl__ap3on4(tmp14_min);
      var tmp11_min = _Angle___get_ratio__impl__ap3on4(tmp15_min);
      tmp$ret$6 = Math.min(tmp10_min, tmp11_min);
      var tmp13_fromRatio = tmp$ret$6;
      tmp$ret$7 = _Angle___init__impl__g23q1m(tmp13_fromRatio);
      tmp$ret$8 = tmp$ret$7;
      var a2 = Angle__plus_impl_26v37k(tmp, Angle__times_impl_v3s9y2(tmp$ret$8, sgn));
      var a = Angle__div_impl_ut2btd(Angle__minus_impl_xeau7k(a2, a1), 2.0);
      var x4 = r * _Angle___get_cosineD__impl__kykfkw(a);
      var y4 = r * _Angle___get_sineD__impl__2ymbp0(a);
      var x1 = x4;
      var y1 = -y4;
      var f = 0.5522847498307933 * _Angle___get_tangentD__impl__vwxar8(a);
      var x2 = x1 + f * y4;
      var y2 = y1 + f * x4;
      var x3 = x2;
      var y3 = -y2;
      var ar = Angle__plus_impl_26v37k(a, a1);
      var cos_ar = _Angle___get_cosineD__impl__kykfkw(ar);
      var sin_ar = _Angle___get_sineD__impl__2ymbp0(ar);
      if (index === 0) {
        out.y44(Vector2_init_$Create$(x + r * _Angle___get_cosineD__impl__kykfkw(a1), y + r * _Angle___get_sineD__impl__2ymbp0(a1)));
      }
      out.d45(Vector2_init_$Create$(x + x2 * cos_ar - y2 * sin_ar, y + x2 * sin_ar + y2 * cos_ar), Vector2_init_$Create$(x + x3 * cos_ar - y3 * sin_ar, y + x3 * sin_ar + y3 * cos_ar), Vector2_init_$Create$(x + r * _Angle___get_cosineD__impl__kykfkw(a2), y + r * _Angle___get_sineD__impl__2ymbp0(a2)));
      var tmp1 = index;
      index = tmp1 + 1 | 0;
      var tmp_0 = remainingAngle;
      var tmp$ret$11;
      // Inline function 'korlibs.math.geom.abs' call
      var tmp19_abs = Angle__minus_impl_xeau7k(a2, a1);
      var tmp$ret$10;
      // Inline function 'korlibs.math.geom.Companion.fromRatio' call
      var tmp17_fromRatio = Companion_getInstance_0();
      var tmp$ret$9;
      // Inline function 'kotlin.math.absoluteValue' call
      var tmp16__get_absoluteValue__sldpck = _Angle___get_ratio__impl__ap3on4(tmp19_abs);
      tmp$ret$9 = Math.abs(tmp16__get_absoluteValue__sldpck);
      var tmp18_fromRatio = tmp$ret$9;
      tmp$ret$10 = _Angle___init__impl__g23q1m(tmp18_fromRatio);
      tmp$ret$11 = tmp$ret$10;
      remainingAngle = Angle__minus_impl_xeau7k(tmp_0, tmp$ret$11);
      a1 = a2;
    }
    if (equals(startAngle, endAngle) ? !(index === 0) : false) {
      out.n1q();
    }
  };
  var Arc_instance;
  function Arc_getInstance() {
    if (Arc_instance == null)
      new Arc();
    return Arc_instance;
  }
  function Bezier$Extrema$allt$delegate$lambda(this$0) {
    return function () {
      return combineSmallDistinctSorted(Companion_getInstance_24(), this$0.e45_1, this$0.f45_1);
    };
  }
  function Bezier$Extrema$xt01$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.floatArrayOf' call
      var tmp0_floatArrayOf = primitiveArrayConcat([new Float32Array([0.0]), this$0.e45_1, new Float32Array([1.0])]);
      tmp$ret$0 = tmp0_floatArrayOf;
      return tmp$ret$0;
    };
  }
  function Bezier$Extrema$yt01$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.floatArrayOf' call
      var tmp0_floatArrayOf = primitiveArrayConcat([new Float32Array([0.0]), this$0.f45_1, new Float32Array([1.0])]);
      tmp$ret$0 = tmp0_floatArrayOf;
      return tmp$ret$0;
    };
  }
  function map($this, v, ds, de, ts, te) {
    return convertRange(v, ds, de, ts, te);
  }
  function angle($this, o, v1, v2) {
    var dx1 = v1.p3r_1 - o.p3r_1;
    var dy1 = v1.q3r_1 - o.q3r_1;
    var dx2 = v2.p3r_1 - o.p3r_1;
    var dy2 = v2.q3r_1 - o.q3r_1;
    var cross = dx1 * dy2 - dy1 * dx2;
    var dot = dx1 * dx2 + dy1 * dy2;
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    tmp$ret$0 = Math.atan2(cross, dot);
    return tmp$ret$0;
  }
  function compute($this, t, points) {
    var p = points;
    var order = p.l() - 1 | 0;
    if (t === 0.0)
      return p.n(0);
    if (t === 1.0)
      return p.n(order);
    if (order === 0)
      return p.n(0);
    var mt = 1 - t;
    var mt2 = mt * mt;
    var t2 = t * t;
    var tmp0_subject = order;
    var tmp;
    switch (tmp0_subject) {
      case 1:
        var tmp$ret$2;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp0_times = p.n(0);
        tmp$ret$0 = new Vector2(tmp0_times.p3r_1 * mt, tmp0_times.q3r_1 * mt);
        var tmp2_plus = tmp$ret$0;
        var tmp$ret$1;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp1_times = p.n(1);
        tmp$ret$1 = new Vector2(tmp1_times.p3r_1 * t, tmp1_times.q3r_1 * t);
        var tmp3_plus = tmp$ret$1;
        tmp$ret$2 = new Vector2(tmp2_plus.p3r_1 + tmp3_plus.p3r_1, tmp2_plus.q3r_1 + tmp3_plus.q3r_1);

        tmp = tmp$ret$2;
        break;
      case 2:
        var a = mt2;
        var b = mt * t * 2;
        var c = t2;
        var tmp$ret$7;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$5;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$3;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp4_times = p.n(0);
        tmp$ret$3 = new Vector2(tmp4_times.p3r_1 * a, tmp4_times.q3r_1 * a);
        var tmp6_plus = tmp$ret$3;
        var tmp$ret$4;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp5_times = p.n(1);
        tmp$ret$4 = new Vector2(tmp5_times.p3r_1 * b, tmp5_times.q3r_1 * b);
        var tmp7_plus = tmp$ret$4;
        tmp$ret$5 = new Vector2(tmp6_plus.p3r_1 + tmp7_plus.p3r_1, tmp6_plus.q3r_1 + tmp7_plus.q3r_1);
        var tmp9_plus = tmp$ret$5;
        var tmp$ret$6;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp8_times = p.n(2);
        tmp$ret$6 = new Vector2(tmp8_times.p3r_1 * c, tmp8_times.q3r_1 * c);
        var tmp10_plus = tmp$ret$6;
        tmp$ret$7 = new Vector2(tmp9_plus.p3r_1 + tmp10_plus.p3r_1, tmp9_plus.q3r_1 + tmp10_plus.q3r_1);

        tmp = tmp$ret$7;
        break;
      case 3:
        var a_0 = mt2 * mt;
        var b_0 = mt2 * t * 3;
        var c_0 = mt * t2 * 3;
        var d = t * t2;
        var tmp$ret$14;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$12;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$10;
        // Inline function 'korlibs.math.geom.Vector2.plus' call
        var tmp$ret$8;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp11_times = p.n(0);
        tmp$ret$8 = new Vector2(tmp11_times.p3r_1 * a_0, tmp11_times.q3r_1 * a_0);
        var tmp13_plus = tmp$ret$8;
        var tmp$ret$9;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp12_times = p.n(1);
        tmp$ret$9 = new Vector2(tmp12_times.p3r_1 * b_0, tmp12_times.q3r_1 * b_0);
        var tmp14_plus = tmp$ret$9;
        tmp$ret$10 = new Vector2(tmp13_plus.p3r_1 + tmp14_plus.p3r_1, tmp13_plus.q3r_1 + tmp14_plus.q3r_1);
        var tmp16_plus = tmp$ret$10;
        var tmp$ret$11;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp15_times = p.n(2);
        tmp$ret$11 = new Vector2(tmp15_times.p3r_1 * c_0, tmp15_times.q3r_1 * c_0);
        var tmp17_plus = tmp$ret$11;
        tmp$ret$12 = new Vector2(tmp16_plus.p3r_1 + tmp17_plus.p3r_1, tmp16_plus.q3r_1 + tmp17_plus.q3r_1);
        var tmp19_plus = tmp$ret$12;
        var tmp$ret$13;
        // Inline function 'korlibs.math.geom.Vector2.times' call
        var tmp18_times = p.n(3);
        tmp$ret$13 = new Vector2(tmp18_times.p3r_1 * d, tmp18_times.q3r_1 * d);
        var tmp20_plus = tmp$ret$13;
        tmp$ret$14 = new Vector2(tmp19_plus.p3r_1 + tmp20_plus.p3r_1, tmp19_plus.q3r_1 + tmp20_plus.q3r_1);

        tmp = tmp$ret$14;
        break;
      default:
        throw new NotImplementedError('An operation is not implemented: higher order curves');
    }
    return tmp;
  }
  function derive($this, points) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var out = tmp$ret$0;
    var current = points;
    while (current.l() >= 2) {
      var new_0 = new PointArrayList(current.l() - 1 | 0);
      var c = current.l() - 1 | 0;
      var inductionVariable = 0;
      var last = current.l() - 1 | 0;
      if (inductionVariable < last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$2;
          // Inline function 'korlibs.math.geom.Vector2.times' call
          var tmp$ret$1;
          // Inline function 'korlibs.math.geom.Vector2.minus' call
          var tmp0_minus = current.n(n + 1 | 0);
          var tmp1_minus = current.n(n);
          tmp$ret$1 = new Vector2(tmp0_minus.p3r_1 - tmp1_minus.p3r_1, tmp0_minus.q3r_1 - tmp1_minus.q3r_1);
          var tmp2_times = tmp$ret$1;
          tmp$ret$2 = new Vector2(tmp2_times.p3r_1 * c, tmp2_times.q3r_1 * c);
          new_0.g40(tmp$ret$2);
        }
         while (inductionVariable < last);
      out.b(new_0);
      current = new_0;
    }
    return out;
  }
  function align($this, points, line, out) {
    var p1 = line.k3t_1;
    var p2 = line.l3t_1;
    var tx = p1.p3r_1;
    var ty = p1.q3r_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    var tmp0_atan2 = p2.q3r_1 - ty;
    var tmp1_atan2 = p2.p3r_1 - tx;
    tmp$ret$0 = Math.atan2(tmp0_atan2, tmp1_atan2);
    var a = -tmp$ret$0;
    // Inline function 'korlibs.math.geom.fastForEach' call
    var inductionVariable = 0;
    var last = points.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.bezier.Companion.align.<anonymous>' call
        var tmp2__anonymous__z9zvc9 = points.n(n);
        var x = tmp2__anonymous__z9zvc9.c5();
        var y = tmp2__anonymous__z9zvc9.d5();
        var tmp = x - tx;
        var tmp$ret$1;
        // Inline function 'kotlin.math.cos' call
        tmp$ret$1 = Math.cos(a);
        var tmp_0 = tmp * tmp$ret$1;
        var tmp_1 = y - ty;
        var tmp$ret$2;
        // Inline function 'kotlin.math.sin' call
        tmp$ret$2 = Math.sin(a);
        var tmp_2 = tmp_0 - tmp_1 * tmp$ret$2;
        var tmp_3 = x - tx;
        var tmp$ret$3;
        // Inline function 'kotlin.math.sin' call
        tmp$ret$3 = Math.sin(a);
        var tmp_4 = tmp_3 * tmp$ret$3;
        var tmp_5 = y - ty;
        var tmp$ret$4;
        // Inline function 'kotlin.math.cos' call
        tmp$ret$4 = Math.cos(a);
        out.e40(tmp_2, tmp_4 + tmp_5 * tmp$ret$4);
      }
       while (inductionVariable < last);
    return out;
  }
  function align$default($this, points, line, out, $super) {
    out = out === VOID ? new PointArrayList() : out;
    return align($this, points, line, out);
  }
  function droots($this, p) {
    var tmp0_subject = p.length;
    switch (tmp0_subject) {
      case 3:
        var a = p[0];
        var b = p[1];
        var c = p[2];
        var d = a - 2 * b + c;
        if (!(d === 0.0)) {
          var tmp$ret$0;
          // Inline function 'kotlin.math.sqrt' call
          var tmp0_sqrt = b * b - a * c;
          tmp$ret$0 = Math.sqrt(tmp0_sqrt);
          var m1 = -tmp$ret$0;
          var m2 = -a + b;
          var v1 = -(m1 + m2) / d;
          var v2 = -(-m1 + m2) / d;
          return floatArrayOfValid01$default($this, v1, v2);
        } else if (!(b === c) ? d === 0.0 : false) {
          return floatArrayOfValid01$default($this, (2.0 * b - c) / (2.0 * (b - c)));
        }

        break;
      case 2:
        var a_0 = p[0];
        var b_0 = p[1];
        if (!(a_0 === b_0))
          return floatArrayOfValid01$default($this, a_0 / (a_0 - b_0));
        break;
      default:
        break;
    }
    return floatArrayOfValid01$default($this);
  }
  function floatArrayOfValid01($this, v1, v2, v3) {
    var containsArg = v1;
    var v1Valid = 0.0 <= containsArg ? containsArg <= 1.0 : false;
    var containsArg_0 = v2;
    var v2Valid = 0.0 <= containsArg_0 ? containsArg_0 <= 1.0 : false;
    var containsArg_1 = v3;
    var v3Valid = 0.0 <= containsArg_1 ? containsArg_1 <= 1.0 : false;
    var validCount = 0;
    if (v1Valid) {
      var tmp0 = validCount;
      validCount = tmp0 + 1 | 0;
    }
    if (v2Valid) {
      var tmp1 = validCount;
      validCount = tmp1 + 1 | 0;
    }
    if (v3Valid) {
      var tmp2 = validCount;
      validCount = tmp2 + 1 | 0;
    }
    if (validCount === 0)
      return $this.n45_1;
    var index = 0;
    var out = new Float32Array(validCount);
    if (v1Valid) {
      var tmp3 = index;
      index = tmp3 + 1 | 0;
      out[tmp3] = normalizeZero(v1);
    }
    if (v2Valid) {
      var tmp4 = index;
      index = tmp4 + 1 | 0;
      out[tmp4] = normalizeZero(v2);
    }
    if (v3Valid) {
      var tmp5 = index;
      index = tmp5 + 1 | 0;
      out[tmp5] = normalizeZero(v3);
    }
    return out;
  }
  function floatArrayOfValid01$default($this, v1, v2, v3, $super) {
    var tmp;
    if (v1 === VOID) {
      FloatCompanionObject_getInstance();
      tmp = NaN;
    } else {
      tmp = v1;
    }
    v1 = tmp;
    var tmp_0;
    if (v2 === VOID) {
      FloatCompanionObject_getInstance();
      tmp_0 = NaN;
    } else {
      tmp_0 = v2;
    }
    v2 = tmp_0;
    var tmp_1;
    if (v3 === VOID) {
      FloatCompanionObject_getInstance();
      tmp_1 = NaN;
    } else {
      tmp_1 = v3;
    }
    v3 = tmp_1;
    return floatArrayOfValid01($this, v1, v2, v3);
  }
  function combineSmallDistinctSorted($this, a, b) {
    var out = new FloatArrayList(a.length + b.length | 0);
    out.y19(a);
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < b.length) {
      // Inline function 'korlibs.math.geom.bezier.Companion.combineSmallDistinctSorted.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = b[tmp0];
      if (!out.i1a(tmp0__anonymous__q1qw7t)) {
        out.b1a(tmp0__anonymous__q1qw7t);
      }
    }
    sort(out);
    return out.k1a();
  }
  function Bezier_init_$Init$(p0, p1, $this) {
    Bezier.call($this, pointArrayListOf(p0, p1), Unit_getInstance());
    return $this;
  }
  function Bezier_init_$Create$(p0, p1) {
    return Bezier_init_$Init$(p0, p1, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_0(p0, p1, p2, $this) {
    Bezier.call($this, pointArrayListOf_0(p0, p1, p2), Unit_getInstance());
    return $this;
  }
  function Bezier_init_$Create$_0(p0, p1, p2) {
    return Bezier_init_$Init$_0(p0, p1, p2, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_1(p0, p1, p2, p3, $this) {
    Bezier.call($this, pointArrayListOf_1(p0, p1, p2, p3), Unit_getInstance());
    return $this;
  }
  function Bezier_init_$Create$_1(p0, p1, p2, p3) {
    return Bezier_init_$Init$_1(p0, p1, p2, p3, objectCreate(protoOf(Bezier)));
  }
  function Bezier_init_$Init$_2(points, $this) {
    Bezier.call($this, points.d40(), Unit_getInstance());
    return $this;
  }
  function Bezier_init_$Create$_2(points) {
    return Bezier_init_$Init$_2(points, objectCreate(protoOf(Bezier)));
  }
  function _get_aligned__7fjidv($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = aligned$factory();
    tmp$ret$0 = $this.p45_1.f1();
    return tmp$ret$0;
  }
  function _getBoundingBox($this, m) {
    var xmin = 0.0;
    var ymin = 0.0;
    var xmax = 0.0;
    var ymax = 0.0;
    var inductionVariable = 0;
    if (inductionVariable <= 1)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ext = $this.z45().a46(n);
        FloatCompanionObject_getInstance();
        var min = Infinity;
        FloatCompanionObject_getInstance();
        var max = -Infinity;
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var n_0 = 0;
        while (n_0 < ext.length) {
          // Inline function 'korlibs.math.geom.bezier.Bezier._getBoundingBox.<anonymous>' call
          var tmp0 = n_0;
          n_0 = tmp0 + 1 | 0;
          var tmp0__anonymous__q1qw7t = ext[tmp0];
          var p = $this.b46(tmp0__anonymous__q1qw7t);
          var tmp0_subject = n;
          var value = tmp0_subject === 0 ? p.y43(m) : p.z43(m);
          var tmp$ret$0;
          // Inline function 'kotlin.math.min' call
          var tmp0_min = min;
          tmp$ret$0 = Math.min(tmp0_min, value);
          min = tmp$ret$0;
          var tmp$ret$1;
          // Inline function 'kotlin.math.max' call
          var tmp1_max = max;
          tmp$ret$1 = Math.max(tmp1_max, value);
          max = tmp$ret$1;
        }
        if (n === 0) {
          xmin = min;
          xmax = max;
        } else {
          ymin = min;
          ymax = max;
        }
      }
       while (inductionVariable <= 1);
    return Companion_getInstance_17().a41(xmin, ymin, xmax, ymax);
  }
  function findNearestLine($this, pX, pY, aX, aY, bX, bY, out) {
    out.f46_1 = $this;
    var atobX = bX - aX;
    var atobY = bY - aY;
    var atopX = pX - aX;
    var atopY = pY - aY;
    var len = atobX * atobX + atobY * atobY;
    var dot = atopX * atobX + atopY * atobY;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = dot / len;
    tmp$ret$0 = Math.max(0.0, tmp0_max);
    var tmp1_min = tmp$ret$0;
    tmp$ret$1 = Math.min(1.0, tmp1_min);
    var t = tmp$ret$1;
    out.c46_1 = new Vector2(aX + atobX * t, aY + atobY * t);
    out.d46_1 = t;
    out.e46_1 = Companion_getInstance_21().r43(out.c46_1.p3r_1, out.c46_1.q3r_1, pX, pY);
    return out;
  }
  function ProjectedPoint(p, t, dSq) {
    p = p === VOID ? Vector2_init_$Create$_1() : p;
    t = t === VOID ? 0.0 : t;
    dSq = dSq === VOID ? 0.0 : dSq;
    this.c46_1 = p;
    this.d46_1 = t;
    this.e46_1 = dSq;
  }
  protoOf(ProjectedPoint).toString = function () {
    return 'ProjectedPoint(p=' + this.c46_1 + ', t=' + this.d46_1 + ', dSq=' + this.e46_1 + ')';
  };
  protoOf(ProjectedPoint).hashCode = function () {
    var result = this.c46_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.d46_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.e46_1) | 0;
    return result;
  };
  protoOf(ProjectedPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ProjectedPoint))
      return false;
    var tmp0_other_with_cast = other instanceof ProjectedPoint ? other : THROW_CCE();
    if (!this.c46_1.equals(tmp0_other_with_cast.c46_1))
      return false;
    if (!equals(this.d46_1, tmp0_other_with_cast.d46_1))
      return false;
    if (!equals(this.e46_1, tmp0_other_with_cast.e46_1))
      return false;
    return true;
  };
  function Extrema(xt, yt) {
    this.e45_1 = xt;
    this.f45_1 = yt;
    var tmp = this;
    tmp.g45_1 = lazy(Bezier$Extrema$allt$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.h45_1 = lazy(Bezier$Extrema$xt01$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.i45_1 = lazy(Bezier$Extrema$yt01$delegate$lambda(this));
  }
  protoOf(Extrema).g46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = xt01$factory();
    tmp$ret$0 = this.h45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Extrema).h46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = yt01$factory();
    tmp$ret$0 = this.i45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Extrema).a46 = function (index) {
    return index === 0 ? this.g46() : this.h46();
  };
  protoOf(Extrema).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof Extrema) {
      tmp_0 = contentEquals(this.e45_1, other.e45_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.f45_1, other.f45_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Extrema).hashCode = function () {
    return contentHashCode(this.e45_1) + imul(contentHashCode(this.f45_1), 7) | 0;
  };
  protoOf(Extrema).toString = function () {
    return 'Extrema(x=' + contentToString(this.e45_1) + ', y=' + contentToString(this.f45_1) + ')';
  };
  function Companion_24() {
    Companion_instance_24 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.floatArrayOf' call
    tmp$ret$0 = new Float32Array([-0.064056896, 0.064056896, -0.19111887, 0.19111887, -0.31504267, 0.31504267, -0.43379351, 0.43379351, -0.5454215, 0.5454215, -0.64809364, 0.64809364, -0.74012417, 0.74012417, -0.82000196, 0.82000196, -0.88641554, 0.88641554, -0.93827456, 0.93827456, -0.9747286, 0.9747286, -0.9951872, 0.9951872]);
    tmp.j45_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.floatArrayOf' call
    tmp$ret$1 = new Float32Array([0.1279382, 0.1279382, 0.12583746, 0.12583746, 0.12167047, 0.12167047, 0.115505666, 0.115505666, 0.10744427, 0.10744427, 0.097618654, 0.097618654, 0.086190164, 0.086190164, 0.07334648, 0.07334648, 0.059298586, 0.059298586, 0.044277437, 0.044277437, 0.02853139, 0.02853139, 0.01234123, 0.01234123]);
    tmp_0.k45_1 = tmp$ret$1;
    this.l45_1 = 6.2831855;
    this.m45_1 = Line_init_$Create$(0, 0, 1, 0);
    this.n45_1 = new Float32Array(0);
  }
  protoOf(Companion_24).i46 = function (p0, p1, p2, p3, t) {
    var out;
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Companion.cubicCalc' call
    var tmp0_cubicCalc = p0.p3r_1;
    var tmp1_cubicCalc = p0.q3r_1;
    var tmp2_cubicCalc = p1.p3r_1;
    var tmp3_cubicCalc = p1.q3r_1;
    var tmp4_cubicCalc = p2.p3r_1;
    var tmp5_cubicCalc = p2.q3r_1;
    var tmp6_cubicCalc = p3.p3r_1;
    var tmp7_cubicCalc = p3.q3r_1;
    // Inline function 'kotlin.contracts.contract' call
    var cx = 3.0 * (tmp2_cubicCalc - tmp0_cubicCalc);
    var bx = 3.0 * (tmp4_cubicCalc - tmp2_cubicCalc) - cx;
    var ax = tmp6_cubicCalc - tmp0_cubicCalc - cx - bx;
    var cy = 3.0 * (tmp3_cubicCalc - tmp1_cubicCalc);
    var by = 3.0 * (tmp5_cubicCalc - tmp3_cubicCalc) - cy;
    var ay = tmp7_cubicCalc - tmp1_cubicCalc - cy - by;
    var tSquared = t * t;
    var tCubed = tSquared * t;
    var tmp8__anonymous__65w033 = ax * tCubed + bx * tSquared + cx * t + tmp0_cubicCalc;
    var tmp9__anonymous__1b7cvk = ay * tCubed + by * tSquared + cy * t + tmp1_cubicCalc;
    out = new Vector2(tmp8__anonymous__65w033, tmp9__anonymous__1b7cvk);
    tmp$ret$0 = Unit_getInstance();
    return out;
  };
  protoOf(Companion_24).j46 = function (p, c, a, t) {
    var out;
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Companion.quadCalc' call
    var tmp0_quadCalc = p.p3r_1;
    var tmp1_quadCalc = p.q3r_1;
    var tmp2_quadCalc = c.p3r_1;
    var tmp3_quadCalc = c.q3r_1;
    var tmp4_quadCalc = a.p3r_1;
    var tmp5_quadCalc = a.q3r_1;
    // Inline function 'kotlin.contracts.contract' call
    var t1 = 1 - t;
    var a_0 = t1 * t1;
    var c_0 = t * t;
    var b = 2 * t1 * t;
    var tmp6__anonymous__fv9ai5 = a_0 * tmp0_quadCalc + b * tmp2_quadCalc + c_0 * tmp4_quadCalc;
    var tmp7__anonymous__b0knam = a_0 * tmp1_quadCalc + b * tmp3_quadCalc + c_0 * tmp5_quadCalc;
    out = new Vector2(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam);
    tmp$ret$0 = Unit_getInstance();
    return out;
  };
  protoOf(Companion_24).k46 = function (v0, v1) {
    return v0 + (v1 - v0) * (2.0 / 3.0);
  };
  protoOf(Companion_24).l46 = function (v1, v2) {
    return v2 + (v1 - v2) * (2.0 / 3.0);
  };
  var Companion_instance_24;
  function Companion_getInstance_24() {
    if (Companion_instance_24 == null)
      new Companion_24();
    return Companion_instance_24;
  }
  function Bezier$aligned$delegate$lambda(this$0) {
    return function () {
      return align$default(Companion_getInstance_24(), this$0.o45_1, new Line(this$0.o45_1.e1c(), this$0.o45_1.f1c()));
    };
  }
  function Bezier$dpoints$delegate$lambda(this$0) {
    return function () {
      return derive(Companion_getInstance_24(), this$0.o45_1);
    };
  }
  function Bezier$direction$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$2;
      // Inline function 'korlibs.math.geom.Companion.between' call
      var tmp8_between = Companion_getInstance_0();
      var tmp9_between = this$0.o45_1.n(0);
      var tmp10_between = this$0.o45_1.n(this$0.m46());
      var tmp11_between = this$0.o45_1.n(1);
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.Companion.between' call
      var tmp4_between = tmp9_between.p3r_1;
      var tmp5_between = tmp9_between.q3r_1;
      var tmp6_between = tmp10_between.p3r_1;
      var tmp7_between = tmp10_between.q3r_1;
      var tmp$ret$0;
      // Inline function 'korlibs.math.geom.Companion.between' call
      var tmp0_between = tmp4_between;
      var tmp1_between = tmp5_between;
      var tmp2_between = tmp6_between;
      var tmp3_between = tmp7_between;
      tmp$ret$0 = Angle_between(tmp0_between, tmp1_between, tmp2_between, tmp3_between, tmp11_between);
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      return new Angle(tmp$ret$2);
    };
  }
  function Bezier$extrema$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$8;
      // Inline function 'kotlin.collections.map' call
      var tmp1_map = until(0, this$0.n46());
      var tmp$ret$7;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
      var inductionVariable = tmp1_map.p1_1;
      var last = tmp1_map.q1_1;
      if (inductionVariable <= last)
        do {
          var item = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$6;
          // Inline function 'korlibs.math.geom.bezier.Bezier.extrema$delegate.<anonymous>.<anonymous>' call
          var tmp = Companion_getInstance_24();
          var tmp$ret$2;
          // Inline function 'korlibs.datastructure.mapFloat' call
          var tmp1_mapFloat = this$0.o46().n(0).x3z(item);
          var tmp$ret$1;
          // Inline function 'kotlin.also' call
          var tmp0_also = new Float32Array(tmp1_mapFloat.length);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.datastructure.mapFloat.<anonymous>' call
          var inductionVariable_0 = 0;
          var last_0 = tmp1_mapFloat.length;
          if (inductionVariable_0 < last_0)
            do {
              var n = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var tmp$ret$0;
              // Inline function 'korlibs.math.geom.bezier.Bezier.extrema$delegate.<anonymous>.<anonymous>.<anonymous>' call
              var tmp2__anonymous__z9zvc9 = tmp1_mapFloat[n];
              tmp$ret$0 = tmp2__anonymous__z9zvc9;
              tmp0_also[n] = tmp$ret$0;
            }
             while (inductionVariable_0 < last_0);
          tmp$ret$1 = tmp0_also;
          tmp$ret$2 = tmp$ret$1;
          var out = droots(tmp, tmp$ret$2);
          if (this$0.m46() === 3) {
            var tmp_0 = Companion_getInstance_24();
            var tmp_1 = out;
            var tmp_2 = Companion_getInstance_24();
            var tmp$ret$5;
            // Inline function 'korlibs.datastructure.mapFloat' call
            var tmp4_mapFloat = this$0.o46().n(1).x3z(item);
            var tmp$ret$4;
            // Inline function 'kotlin.also' call
            var tmp3_also = new Float32Array(tmp4_mapFloat.length);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.datastructure.mapFloat.<anonymous>' call
            var inductionVariable_1 = 0;
            var last_1 = tmp4_mapFloat.length;
            if (inductionVariable_1 < last_1)
              do {
                var n_0 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                var tmp$ret$3;
                // Inline function 'korlibs.math.geom.bezier.Bezier.extrema$delegate.<anonymous>.<anonymous>.<anonymous>' call
                var tmp5__anonymous__kpxxpo = tmp4_mapFloat[n_0];
                tmp$ret$3 = tmp5__anonymous__kpxxpo;
                tmp3_also[n_0] = tmp$ret$3;
              }
               while (inductionVariable_1 < last_1);
            tmp$ret$4 = tmp3_also;
            tmp$ret$5 = tmp$ret$4;
            out = combineSmallDistinctSorted(tmp_0, tmp_1, droots(tmp_2, tmp$ret$5));
          }
          tmp$ret$6 = out;
          tmp0_mapTo.b(tmp$ret$6);
        }
         while (!(item === last));
      tmp$ret$7 = tmp0_mapTo;
      tmp$ret$8 = tmp$ret$7;
      var out_0 = tmp$ret$8;
      return new Extrema(out_0.n(0), out_0.n(1));
    };
  }
  function Bezier$outerCircle$delegate$lambda(this$0) {
    return function () {
      return this$0.p46().o41();
    };
  }
  function Bezier$boundingBox$delegate$lambda(this$0) {
    return function () {
      return _getBoundingBox(this$0, Companion_getInstance_10().w3x_1);
    };
  }
  function Bezier$length$delegate$lambda(this$0) {
    return function () {
      var z = 0.5;
      var sum = 0.0;
      var inductionVariable = 0;
      var last = Companion_getInstance_24().j45_1.length - 1 | 0;
      var tmp;
      if (inductionVariable <= last) {
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var t = z * Companion_getInstance_24().j45_1[i] + z;
          var temp = this$0.q46(t);
          sum = sum + Companion_getInstance_24().k45_1[i] * temp.hf();
        }
         while (inductionVariable <= last);
        tmp = Unit_getInstance();
      }
      return z * sum;
    };
  }
  function Bezier$lut$delegate$lambda(this$0) {
    return function () {
      return this$0.r46();
    };
  }
  function Bezier$isLinear$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'korlibs.math.geom.Vector2.minus' call
      var tmp0_minus = this$0.o45_1.n(this$0.m46());
      var tmp1_minus = this$0.o45_1.n(0);
      tmp$ret$0 = new Vector2(tmp0_minus.p3r_1 - tmp1_minus.p3r_1, tmp0_minus.q3r_1 - tmp1_minus.q3r_1);
      var baseLength = tmp$ret$0.hf();
      var tmp$ret$3;
      // Inline function 'korlibs.datastructure.sumOfFloat' call
      var tmp2_sumOfFloat = until(0, _get_aligned__7fjidv(this$0).l());
      var sum = 0.0;
      var inductionVariable = tmp2_sumOfFloat.p1_1;
      var last = tmp2_sumOfFloat.q1_1;
      if (inductionVariable <= last)
        do {
          var element = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp = sum;
          var tmp$ret$2;
          // Inline function 'korlibs.math.geom.bezier.Bezier.isLinear$delegate.<anonymous>.<anonymous>' call
          var tmp$ret$1;
          // Inline function 'kotlin.math.absoluteValue' call
          var tmp0__get_absoluteValue__nukmtt = _get_aligned__7fjidv(this$0).u3z(element);
          tmp$ret$1 = Math.abs(tmp0__get_absoluteValue__nukmtt);
          tmp$ret$2 = tmp$ret$1;
          sum = tmp + tmp$ret$2;
        }
         while (!(element === last));
      tmp$ret$3 = sum;
      return tmp$ret$3 < baseLength / 50.0;
    };
  }
  function Bezier$isSimple$delegate$lambda(this$0) {
    return function () {
      var tmp;
      if (this$0.m46() === 3) {
        var a1 = angle(Companion_getInstance_24(), this$0.o45_1.n(0), this$0.o45_1.n(3), this$0.o45_1.n(1));
        var a2 = angle(Companion_getInstance_24(), this$0.o45_1.n(0), this$0.o45_1.n(3), this$0.o45_1.n(2));
        var tmp_0;
        if ((a1 > 0.0 ? a2 < 0.0 : false) ? true : a1 < 0.0 ? a2 > 0.0 : false) {
          return false;
        }
        tmp = tmp_0;
      }
      var n1 = this$0.s46(0.0);
      var n2 = this$0.s46(1.0);
      var s = n1.p3r_1 * n2.p3r_1 + n1.q3r_1 * n2.q3r_1;
      var tmp$ret$1;
      // Inline function 'kotlin.math.abs' call
      var tmp$ret$0;
      // Inline function 'kotlin.math.acos' call
      tmp$ret$0 = Math.acos(s);
      var tmp0_abs = tmp$ret$0;
      tmp$ret$1 = Math.abs(tmp0_abs);
      return tmp$ret$1 < get_PI() / 3.0;
    };
  }
  function Bezier(points, dummy) {
    Companion_getInstance_24();
    this.o45_1 = points;
    if (this.o45_1.l() > 4) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException_init_$Create$('Only supports quad and cubic beziers');
    }
    var tmp = this;
    tmp.p45_1 = lazy(Bezier$aligned$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.q45_1 = lazy(Bezier$dpoints$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.r45_1 = lazy(Bezier$direction$delegate$lambda(this));
    var tmp_2 = this;
    tmp_2.s45_1 = lazy(Bezier$extrema$delegate$lambda(this));
    var tmp_3 = this;
    tmp_3.t45_1 = lazy(Bezier$outerCircle$delegate$lambda(this));
    var tmp_4 = this;
    tmp_4.u45_1 = lazy(Bezier$boundingBox$delegate$lambda(this));
    var tmp_5 = this;
    tmp_5.v45_1 = lazy(Bezier$length$delegate$lambda(this));
    var tmp_6 = this;
    tmp_6.w45_1 = lazy(Bezier$lut$delegate$lambda(this));
    var tmp_7 = this;
    tmp_7.x45_1 = lazy(Bezier$isLinear$delegate$lambda(this));
    var tmp_8 = this;
    tmp_8.y45_1 = lazy(Bezier$isSimple$delegate$lambda(this));
  }
  protoOf(Bezier).b46 = function (t) {
    return this.t46(t);
  };
  protoOf(Bezier).u46 = function () {
    return this.p46();
  };
  protoOf(Bezier).v46 = function (m) {
    return _getBoundingBox(this, m);
  };
  protoOf(Bezier).w46 = function (t) {
    return this.t46(t);
  };
  protoOf(Bezier).equals = function (other) {
    var tmp;
    if (other instanceof Bezier) {
      tmp = equals(this.o45_1, other.o45_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Bezier).hashCode = function () {
    return hashCode(this.o45_1);
  };
  protoOf(Bezier).toString = function () {
    return 'Bezier(' + this.o45_1 + ')';
  };
  protoOf(Bezier).n46 = function () {
    return 2;
  };
  protoOf(Bezier).m46 = function () {
    return this.o45_1.l() - 1 | 0;
  };
  protoOf(Bezier).o46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = dpoints$factory();
    tmp$ret$0 = this.q45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).z45 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = extrema$factory();
    tmp$ret$0 = this.s45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).x46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = outerCircle$factory();
    tmp$ret$0 = this.t45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).p46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = boundingBox$factory();
    tmp$ret$0 = this.u45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).hf = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = length$factory();
    tmp$ret$0 = this.v45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).y46 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = lut$factory();
    tmp$ret$0 = this.w45_1.f1();
    return tmp$ret$0;
  };
  protoOf(Bezier).z46 = function (length) {
    return this.y46().h47(length).b47_1;
  };
  protoOf(Bezier).i47 = function (steps, out) {
    out.e4();
    var inductionVariable = 0;
    if (inductionVariable <= steps)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var t = n / steps;
        out.j47(t, this.t46(t));
      }
       while (!(n === steps));
    return out;
  };
  protoOf(Bezier).r46 = function (steps, out, $super) {
    steps = steps === VOID ? 100 : steps;
    out = out === VOID ? CurveLUT_init_$Create$(this, steps + 1 | 0) : out;
    return $super === VOID ? this.i47(steps, out) : $super.i47.call(this, steps, out);
  };
  protoOf(Bezier).k47 = function (point, out) {
    out.f46_1 = this;
    if (this.o45_1.l() === 2) {
      var p0 = this.o45_1.n(0);
      var p1 = this.o45_1.n(1);
      return findNearestLine(this, point.p3r_1, point.q3r_1, p0.p3r_1, p0.q3r_1, p1.p3r_1, p1.q3r_1, out);
    }
    var LUT = this.y46();
    var l = LUT.l47();
    var closest = LUT.m47(point);
    var mpos = closest.o47_1;
    var t1 = (mpos - 1 | 0) / l;
    var t2 = (mpos + 1 | 0) / l;
    var step = 0.1 / l;
    var mdistSq = closest.n47_1;
    var t = t1;
    var ft = t;
    mdistSq = mdistSq + 1;
    while (t < t2 + step) {
      var p = this.t46(t);
      var d = Companion_getInstance_21().f3t(point, p);
      if (d < mdistSq) {
        mdistSq = d;
        ft = t;
        out.c46_1 = p;
      }
      t = t + step;
    }
    out.c46_1 = this.t46(ft);
    out.d46_1 = ft < 0 ? 0.0 : ft > 1 ? 1.0 : ft;
    out.e46_1 = mdistSq;
    return out;
  };
  protoOf(Bezier).p47 = function () {
    if (this.o45_1.l() < 4)
      return Companion_getInstance_24().n45_1;
    var p = _get_aligned__7fjidv(this);
    var p1 = p.n(1);
    var p2 = p.n(2);
    var p3 = p.n(3);
    var a = p2.p3r_1 * p1.q3r_1;
    var b = p3.p3r_1 * p1.q3r_1;
    var c = p1.p3r_1 * p2.q3r_1;
    var d = p3.p3r_1 * p2.q3r_1;
    var v1 = 18.0 * (-3.0 * a + 2.0 * b + 3.0 * c - d);
    var v2 = 18.0 * (3.0 * a - b - 3.0 * c);
    var v3 = 18.0 * (c - a);
    if (isAlmostEquals(v1, 0.0)) {
      if (!isAlmostEquals(v2, 0.0)) {
        var t = -v3 / v2;
        var containsArg = t;
        if (0.0 <= containsArg ? containsArg <= 1.0 : false) {
          var tmp$ret$0;
          // Inline function 'kotlin.floatArrayOf' call
          tmp$ret$0 = new Float32Array([t]);
          return tmp$ret$0;
        }
      }
      return Companion_getInstance_24().n45_1;
    }
    var d2 = 2.0 * v1;
    if (isAlmostEquals(d2, 0.0))
      return Companion_getInstance_24().n45_1;
    var trm = v2 * v2 - 4.0 * v1 * v3;
    if (trm < 0)
      return Companion_getInstance_24().n45_1;
    var tmp$ret$1;
    // Inline function 'kotlin.math.sqrt' call
    tmp$ret$1 = Math.sqrt(trm);
    var sq = tmp$ret$1;
    var out0 = (sq - v2) / d2;
    var out1 = -(v2 + sq) / d2;
    var out = new FloatArrayList(2);
    if (0.0 <= out0 ? out0 <= 1.0 : false) {
      out.b1a(out0);
    }
    if (0.0 <= out1 ? out1 <= 1.0 : false) {
      out.b1a(out1);
    }
    return out.k1a();
  };
  protoOf(Bezier).t46 = function (t) {
    return compute(Companion_getInstance_24(), t, this.o45_1);
  };
  protoOf(Bezier).q47 = function (t, normalize) {
    var out = compute(Companion_getInstance_24(), t, this.o46().n(0));
    if ((t === 0.0 ? true : t === 1.0) ? isAlmostZero_0(out.a44()) : false) {
      var inductionVariable = 0;
      if (inductionVariable < 10)
        $l$loop: do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'kotlin.math.pow' call
          var tmp0_pow = -(10 - n | 0) | 0;
          tmp$ret$0 = Math.pow(10.0, tmp0_pow);
          var newT = tmp$ret$0;
          var nt = t === 1.0 ? 1.0 - newT : newT;
          out = compute(Companion_getInstance_24(), nt, this.o46().n(0));
          if (!isAlmostZero_0(out.a44()))
            break $l$loop;
        }
         while (inductionVariable < 10);
    }
    if (normalize)
      out = out.c44();
    return out;
  };
  protoOf(Bezier).q46 = function (t, normalize, $super) {
    normalize = normalize === VOID ? false : normalize;
    return $super === VOID ? this.q47(t, normalize) : $super.q47.call(this, t, normalize);
  };
  protoOf(Bezier).r47 = function (t, normalize) {
    return this.q47(t, normalize).e44();
  };
  protoOf(Bezier).s46 = function (t) {
    return this.r47(t, true);
  };
  protoOf(Bezier).s47 = function (t) {
    return this.q47(t, true);
  };
  protoOf(Bezier).t47 = function (t, out) {
    if (this.m46() < 2)
      return null;
    var p = this.o45_1;
    out.j40(p, 0);
    out.j40(p, 1);
    out.j40(p, 2);
    if (this.m46() === 3) {
      out.j40(p, 3);
    }
    while (p.l() > 1) {
      var next = new PointArrayList();
      var inductionVariable = 0;
      var last = p.l() - 1 | 0;
      if (inductionVariable < last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var p_0 = interpolate(toRatio(t), p.n(i), p.n(i + 1 | 0));
          out.g40(p_0);
          next.g40(p_0);
        }
         while (inductionVariable < last);
      p = next;
    }
    return out;
  };
  protoOf(Bezier).u47 = function (t, out, $super) {
    out = out === VOID ? new PointArrayList() : out;
    return $super === VOID ? this.t47(t, out) : $super.t47.call(this, t, out);
  };
  protoOf(Bezier).v47 = function (t0, t1) {
    return new SubBezier(this.a48(t0).b48(map(Companion_getInstance_24(), t1, t0, 1.0, 0.0, 1.0)).w47_1, t0, t1, this);
  };
  protoOf(Bezier).b48 = function (t) {
    return SubBezier_init_$Create$(this).b48(t);
  };
  protoOf(Bezier).a48 = function (t) {
    return SubBezier_init_$Create$(this).a48(t);
  };
  protoOf(Bezier).c48 = function () {
    return new Line(this.o45_1.n(0), this.o45_1.n(this.m46()));
  };
  function aligned$factory() {
    return getPropertyCallableRef('aligned', 1, KProperty1, function (receiver) {
      return _get_aligned__7fjidv(receiver);
    }, null);
  }
  function dpoints$factory() {
    return getPropertyCallableRef('dpoints', 1, KProperty1, function (receiver) {
      return receiver.o46();
    }, null);
  }
  function extrema$factory() {
    return getPropertyCallableRef('extrema', 1, KProperty1, function (receiver) {
      return receiver.z45();
    }, null);
  }
  function outerCircle$factory() {
    return getPropertyCallableRef('outerCircle', 1, KProperty1, function (receiver) {
      return receiver.x46();
    }, null);
  }
  function boundingBox$factory() {
    return getPropertyCallableRef('boundingBox', 1, KProperty1, function (receiver) {
      return receiver.p46();
    }, null);
  }
  function length$factory() {
    return getPropertyCallableRef('length', 1, KProperty1, function (receiver) {
      return receiver.hf();
    }, null);
  }
  function lut$factory() {
    return getPropertyCallableRef('lut', 1, KProperty1, function (receiver) {
      return receiver.y46();
    }, null);
  }
  function xt01$factory() {
    return getPropertyCallableRef('xt01', 1, KProperty1, function (receiver) {
      return receiver.g46();
    }, null);
  }
  function yt01$factory() {
    return getPropertyCallableRef('yt01', 1, KProperty1, function (receiver) {
      return receiver.h46();
    }, null);
  }
  function CurveLUT_init_$Init$(curve, capacity, $this) {
    CurveLUT.call($this, curve, new PointArrayList(capacity), new FloatArrayList(capacity), new FloatArrayList(capacity));
    return $this;
  }
  function CurveLUT_init_$Create$(curve, capacity) {
    return CurveLUT_init_$Init$(curve, capacity, objectCreate(protoOf(CurveLUT)));
  }
  function ClosestResult(mdistSq, mpos) {
    this.n47_1 = mdistSq;
    this.o47_1 = mpos;
  }
  function Estimation(point, ratio, length) {
    point = point === VOID ? Vector2_init_$Create$_1() : point;
    ratio = ratio === VOID ? 0.0 : ratio;
    length = length === VOID ? 0.0 : length;
    this.a47_1 = point;
    this.b47_1 = ratio;
    this.c47_1 = length;
  }
  protoOf(Estimation).toString = function () {
    return 'Estimation(point=' + this.a47_1.h44() + ', ratio=' + get_niceStr(this.b47_1) + ', length=' + get_niceStr(this.c47_1) + ')';
  };
  protoOf(Estimation).hashCode = function () {
    var result = this.a47_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.b47_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.c47_1) | 0;
    return result;
  };
  protoOf(Estimation).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Estimation))
      return false;
    var tmp0_other_with_cast = other instanceof Estimation ? other : THROW_CCE();
    if (!this.a47_1.equals(tmp0_other_with_cast.a47_1))
      return false;
    if (!equals(this.b47_1, tmp0_other_with_cast.b47_1))
      return false;
    if (!equals(this.c47_1, tmp0_other_with_cast.c47_1))
      return false;
    return true;
  };
  function estimateAt($this, values, value, out) {
    var result = binarySearch(values, value);
    if (_BSearchResult___get_found__impl__om04iz(result))
      return $this.d48(out, _BSearchResult___get_index__impl__b5kraz(result), 0.0);
    var index = _BSearchResult___get_nearIndex__impl__s8nq43(result);
    if (value <= 0.0)
      return $this.d48(out, 0, 0.0);
    if (index >= (values.l() - 1 | 0))
      return $this.d48(out, $this.e47_1.l() - 1 | 0, 0.0);
    var ratio0 = values.n(index);
    var ratio1 = values.n(index + 1 | 0);
    var ratio = convertRange(value, ratio0, ratio1, 0.0, 1.0);
    return $this.d48(out, index, ratio);
  }
  function CurveLUT$toString$lambda(this$0) {
    return function (it) {
      return '' + this$0.f47_1.n(it) + ',len=' + this$0.e48().n(it) + ': ' + this$0.e47_1.n(it);
    };
  }
  function CurveLUT(curve, points, ts, _estimatedLengths) {
    this.d47_1 = curve;
    this.e47_1 = points;
    this.f47_1 = ts;
    this.g47_1 = _estimatedLengths;
  }
  protoOf(CurveLUT).e48 = function () {
    if (this.g47_1.p()) {
      this.g47_1.b1a(0.0);
    }
    while (this.g47_1.l() < this.l()) {
      var pos = this.g47_1.l();
      var prev = last(this.g47_1);
      this.g47_1.b1a(prev + Companion_getInstance_21().q43(this.e47_1.n(pos - 1 | 0), this.e47_1.n(pos)));
    }
    return this.g47_1;
  };
  protoOf(CurveLUT).l47 = function () {
    return this.e47_1.l() - 1 | 0;
  };
  protoOf(CurveLUT).l = function () {
    return this.e47_1.l();
  };
  protoOf(CurveLUT).e4 = function () {
    this.e47_1.kk();
    this.f47_1.e4();
    this.g47_1.e4();
  };
  protoOf(CurveLUT).j47 = function (t, p) {
    this.e47_1.g40(p);
    this.f47_1.b1a(t);
  };
  protoOf(CurveLUT).m47 = function (point) {
    FloatCompanionObject_getInstance();
    var mdistSq = Infinity;
    var mpos = 0;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var d = Companion_getInstance_21().f3t(this.e47_1.n(n), point);
        if (d < mdistSq) {
          mdistSq = d;
          mpos = n;
        }
      }
       while (inductionVariable < last);
    return new ClosestResult(mdistSq, mpos);
  };
  protoOf(CurveLUT).d48 = function (_this__u8e3s4, index, ratio) {
    var ratio0 = this.f47_1.n(index);
    var length0 = this.e48().n(index);
    var point0 = this.e47_1.n(index);
    if (ratio === 0.0) {
      _this__u8e3s4.b47_1 = ratio0;
      _this__u8e3s4.c47_1 = length0;
      _this__u8e3s4.a47_1 = point0;
    } else {
      var ratio1 = this.f47_1.n(index + 1 | 0);
      var length1 = this.e48().n(index + 1 | 0);
      var point1 = this.e47_1.n(index + 1 | 0);
      _this__u8e3s4.b47_1 = interpolate_0(toRatio(ratio), ratio0, ratio1);
      _this__u8e3s4.c47_1 = interpolate_0(toRatio(ratio), length0, length1);
      _this__u8e3s4.a47_1 = interpolate(toRatio(ratio), point0, point1);
    }
    return _this__u8e3s4;
  };
  protoOf(CurveLUT).f48 = function (length, out) {
    return estimateAt(this, this.e48(), length, out);
  };
  protoOf(CurveLUT).h47 = function (length, out, $super) {
    out = out === VOID ? new Estimation() : out;
    return $super === VOID ? this.f48(length, out) : $super.f48.call(this, length, out);
  };
  protoOf(CurveLUT).toString = function () {
    var tmp = until(0, this.l());
    return 'CurveLUT[' + this.d47_1 + '](' + joinToString(tmp, ', ', VOID, VOID, VOID, VOID, CurveLUT$toString$lambda(this)) + ')';
  };
  protoOf(CurveLUT).hashCode = function () {
    var result = hashCode(this.d47_1);
    result = imul(result, 31) + this.e47_1.hashCode() | 0;
    result = imul(result, 31) + this.f47_1.hashCode() | 0;
    result = imul(result, 31) + this.g47_1.hashCode() | 0;
    return result;
  };
  protoOf(CurveLUT).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CurveLUT))
      return false;
    var tmp0_other_with_cast = other instanceof CurveLUT ? other : THROW_CCE();
    if (!equals(this.d47_1, tmp0_other_with_cast.d47_1))
      return false;
    if (!this.e47_1.equals(tmp0_other_with_cast.e47_1))
      return false;
    if (!this.f47_1.equals(tmp0_other_with_cast.f47_1))
      return false;
    if (!this.g47_1.equals(tmp0_other_with_cast.g47_1))
      return false;
    return true;
  };
  function CurveInfo(index, curve, startLength, endLength, bounds) {
    this.g48_1 = index;
    this.h48_1 = curve;
    this.i48_1 = startLength;
    this.j48_1 = endLength;
    this.k48_1 = bounds;
  }
  protoOf(CurveInfo).i1a = function (length) {
    var containsLower = this.i48_1;
    return length <= this.j48_1 ? containsLower <= length : false;
  };
  protoOf(CurveInfo).hf = function () {
    return this.j48_1 - this.i48_1;
  };
  protoOf(CurveInfo).toString = function () {
    return 'CurveInfo(index=' + this.g48_1 + ', curve=' + this.h48_1 + ', startLength=' + this.i48_1 + ', endLength=' + this.j48_1 + ', bounds=' + this.k48_1 + ')';
  };
  protoOf(CurveInfo).hashCode = function () {
    var result = this.g48_1;
    result = imul(result, 31) + this.h48_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.i48_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.j48_1) | 0;
    result = imul(result, 31) + this.k48_1.hashCode() | 0;
    return result;
  };
  protoOf(CurveInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CurveInfo))
      return false;
    var tmp0_other_with_cast = other instanceof CurveInfo ? other : THROW_CCE();
    if (!(this.g48_1 === tmp0_other_with_cast.g48_1))
      return false;
    if (!this.h48_1.equals(tmp0_other_with_cast.h48_1))
      return false;
    if (!equals(this.i48_1, tmp0_other_with_cast.i48_1))
      return false;
    if (!equals(this.j48_1, tmp0_other_with_cast.j48_1))
      return false;
    if (!this.k48_1.equals(tmp0_other_with_cast.k48_1))
      return false;
    return true;
  };
  function Curves$contiguous$delegate$lambda(this$0) {
    return function () {
      var inductionVariable = 1;
      var last = this$0.l48_1.l();
      var tmp;
      if (inductionVariable < last) {
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var curr = this$0.l48_1.n(n - 1 | 0);
          var next = this$0.l48_1.n(n);
          if (!curr.o45_1.f1c().g44(next.o45_1.e1c()))
            return false;
        }
         while (inductionVariable < last);
        tmp = Unit_getInstance();
      }
      return true;
    };
  }
  function Curves$infos$delegate$lambda(this$0) {
    return function () {
      var pos = 0.0;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mapIndexed' call
      var tmp1_mapIndexed = this$0.l48_1;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapIndexedTo' call
      var tmp0_mapIndexedTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_mapIndexed, 10));
      var index = 0;
      var tmp0_iterator = tmp1_mapIndexed.h();
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.bezier.Curves.infos$delegate.<anonymous>.<anonymous>' call
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = checkIndexOverflow(tmp1);
        var start = pos;
        pos = pos + item.hf();
        tmp$ret$0 = new CurveInfo(tmp2__anonymous__z9zvc9, item, start, pos, item.u46());
        tmp0_mapIndexedTo.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapIndexedTo;
      tmp$ret$2 = tmp$ret$1;
      return tmp$ret$2;
    };
  }
  function Curves$length$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.sumOfFloat' call
      var tmp0_sumOfFloat = this$0.s48();
      var sum = 0.0;
      var tmp0_iterator = tmp0_sumOfFloat.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp = sum;
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.bezier.Curves.length$delegate.<anonymous>.<anonymous>' call
        tmp$ret$0 = element.hf();
        sum = tmp + tmp$ret$0;
      }
      tmp$ret$1 = sum;
      return tmp$ret$1;
    };
  }
  function Curves$findInfo$lambda($pos) {
    return function (it) {
      return it.i1a($pos) ? 0 : it.j48_1 < $pos ? -1 : 1;
    };
  }
  function Curves$ratioFromLength$lambda($length) {
    return function (it) {
      return it.j48_1 < $length ? -1 : it.i48_1 > $length ? 1 : 0;
    };
  }
  function Curves(beziers, closed) {
    this.l48_1 = beziers;
    this.m48_1 = closed;
    this.n48_1 = new Mixin();
    this.o48_1 = false;
    var tmp = this;
    tmp.p48_1 = lazy(Curves$contiguous$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.q48_1 = lazy(Curves$infos$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.r48_1 = lazy(Curves$length$delegate$lambda(this));
  }
  protoOf(Curves).d1i = function (_set____db54di) {
    this.n48_1.d1i(_set____db54di);
  };
  protoOf(Curves).e1i = function () {
    return this.n48_1.e1i();
  };
  protoOf(Curves).s48 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = infos$factory();
    tmp$ret$0 = this.q48_1.f1();
    return tmp$ret$0;
  };
  protoOf(Curves).hf = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = length$factory_0();
    tmp$ret$0 = this.r48_1.f1();
    return tmp$ret$0;
  };
  protoOf(Curves).t48 = function (_this__u8e3s4) {
    return _this__u8e3s4.i48_1 / this.hf();
  };
  protoOf(Curves).u48 = function (_this__u8e3s4) {
    return _this__u8e3s4.j48_1 / this.hf();
  };
  protoOf(Curves).v48 = function (t) {
    var pos = t * this.hf();
    var tmp = this.s48();
    var index = binarySearch_0(tmp, VOID, VOID, Curves$findInfo$lambda(pos));
    if (t < 0.0)
      return first(this.s48());
    if (t > 1.0)
      return last_0(this.s48());
    var tmp0_elvis_lhs = getOrNull(this.s48(), index);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      throw IllegalStateException_init_$Create$('OUTSIDE');
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    return tmp_0;
  };
  protoOf(Curves).w46 = function (t) {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = t * this.hf();
    var info = this.v48(t);
    var posInCurve = pos - info.i48_1;
    var ratioInCurve = posInCurve / info.hf();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Curves.calc.<anonymous>' call
    tmp$ret$0 = info.h48_1.w46(ratioInCurve);
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(Curves).s46 = function (t) {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = t * this.hf();
    var info = this.v48(t);
    var posInCurve = pos - info.i48_1;
    var ratioInCurve = posInCurve / info.hf();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Curves.normal.<anonymous>' call
    tmp$ret$0 = info.h48_1.s46(ratioInCurve);
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(Curves).s47 = function (t) {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = t * this.hf();
    var info = this.v48(t);
    var posInCurve = pos - info.i48_1;
    var ratioInCurve = posInCurve / info.hf();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.bezier.Curves.tangent.<anonymous>' call
    tmp$ret$0 = info.h48_1.s47(ratioInCurve);
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(Curves).z46 = function (length) {
    if (length <= 0.0)
      return 0.0;
    if (length >= this.hf())
      return 1.0;
    var tmp = this.s48();
    var curveIndex = binarySearch_0(tmp, VOID, VOID, Curves$ratioFromLength$lambda(length));
    var index = curveIndex < 0 ? (-curveIndex | 0) + 1 | 0 : curveIndex;
    if (curveIndex < 0) {
      FloatCompanionObject_getInstance();
      return NaN;
    }
    var info = this.s48().n(index);
    var lengthInCurve = length - info.i48_1;
    var ratioInCurve = info.h48_1.z46(lengthInCurve);
    return convertRange(ratioInCurve, 0.0, 1.0, this.t48(info), this.u48(info));
  };
  protoOf(Curves).w48 = function (len0, len1) {
    return this.v47(this.z46(len0), this.z46(len1));
  };
  protoOf(Curves).v47 = function (t0, t1) {
    if (t0 > t1)
      return this.v47(t1, t0);
    // Inline function 'kotlin.check' call
    var tmp0_check = t0 <= t1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_check) {
      var tmp$ret$0;
      // Inline function 'kotlin.check.<anonymous>' call
      tmp$ret$0 = 'Check failed.';
      var message = tmp$ret$0;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    if (t0 === t1)
      return new Curves(emptyList(), false);
    var tmp$ret$7;
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos = t0 * this.hf();
    var info = this.v48(t0);
    var posInCurve = pos - info.i48_1;
    var ratioInCurve = posInCurve / info.hf();
    var tmp$ret$6;
    // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>' call
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.bezier.Curves.findTInCurve' call
    var pos_0 = t1 * this.hf();
    var info_0 = this.v48(t1);
    var posInCurve_0 = pos_0 - info_0.i48_1;
    var ratioInCurve_0 = posInCurve_0 / info_0.hf();
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>.<anonymous>' call
    var tmp;
    if (info.g48_1 === info_0.g48_1) {
      tmp = listOf(info.h48_1.v47(ratioInCurve, ratioInCurve_0).w47_1);
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.collections.buildList' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$2;
      // Inline function 'kotlin.collections.buildListInternal' call
      var tmp$ret$1;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = ArrayList_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.bezier.Curves.split.<anonymous>.<anonymous>.<anonymous>' call
      if (!(ratioInCurve === 1.0)) {
        tmp0_apply.b(info.h48_1.a48(ratioInCurve).w47_1);
      }
      var inductionVariable = info.g48_1 + 1 | 0;
      var last = info_0.g48_1;
      if (inductionVariable < last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          tmp0_apply.b(this.s48().n(index).h48_1);
        }
         while (inductionVariable < last);
      if (!(ratioInCurve_0 === 0.0)) {
        tmp0_apply.b(info_0.h48_1.b48(ratioInCurve_0).w47_1);
      }
      tmp$ret$1 = tmp0_apply;
      tmp$ret$2 = tmp$ret$1.tg();
      tmp$ret$3 = tmp$ret$2;
      tmp = tmp$ret$3;
    }
    tmp$ret$4 = tmp;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    return new Curves(tmp$ret$7, false);
  };
  protoOf(Curves).toString = function () {
    return 'Curves(beziers=' + this.l48_1 + ', closed=' + this.m48_1 + ')';
  };
  protoOf(Curves).hashCode = function () {
    var result = hashCode(this.l48_1);
    result = imul(result, 31) + (this.m48_1 | 0) | 0;
    return result;
  };
  protoOf(Curves).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Curves))
      return false;
    var tmp0_other_with_cast = other instanceof Curves ? other : THROW_CCE();
    if (!equals(this.l48_1, tmp0_other_with_cast.l48_1))
      return false;
    if (!(this.m48_1 === tmp0_other_with_cast.m48_1))
      return false;
    return true;
  };
  function toNonCurveSimplePointList(_this__u8e3s4, out) {
    out = out === VOID ? new PointArrayList() : out;
    var curves = _this__u8e3s4;
    var beziers = curves.l48_1;
    var epsilon = 1.0E-4;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < beziers.l()) {
      // Inline function 'korlibs.math.geom.bezier.toNonCurveSimplePointList.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = beziers.n(tmp0);
      var tmp$ret$1;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp0_isNotEmpty = tmp0__anonymous__q1qw7t.p47();
      var tmp$ret$0;
      // Inline function 'kotlin.collections.isEmpty' call
      tmp$ret$0 = tmp0_isNotEmpty.length === 0;
      tmp$ret$1 = !tmp$ret$0;
      if (tmp$ret$1)
        return null;
      var points = tmp0__anonymous__q1qw7t.o45_1;
      // Inline function 'korlibs.math.geom.fastForEach' call
      var inductionVariable = 0;
      var last = points.l();
      if (inductionVariable < last)
        do {
          var n_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'korlibs.math.geom.bezier.toNonCurveSimplePointList.<anonymous>.<anonymous>' call
          var tmp1__anonymous__uwfjfc = points.n(n_0);
          if (out.p() ? true : !out.f1c().f44(tmp1__anonymous__uwfjfc, epsilon)) {
            out.g40(tmp1__anonymous__uwfjfc);
          }
        }
         while (inductionVariable < last);
    }
    if (out.f1c().f44(out.e1c(), epsilon)) {
      out.k40(out.l() - 1 | 0);
    }
    return out;
  }
  function toCurves(_this__u8e3s4, closed) {
    return new Curves(_this__u8e3s4, closed);
  }
  function toVectorPath(_this__u8e3s4, out) {
    out = out === VOID ? new VectorPath() : out;
    var first = {_v: true};
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < _this__u8e3s4.l()) {
      // Inline function 'korlibs.math.geom.bezier.toVectorPath.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = _this__u8e3s4.n(tmp0);
      var tmp0_subject = tmp0__anonymous__q1qw7t;
      if (tmp0_subject instanceof Curves) {
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var tmp0_fastForEach = tmp0__anonymous__q1qw7t.l48_1;
        var n_0 = 0;
        while (n_0 < tmp0_fastForEach.l()) {
          // Inline function 'korlibs.math.geom.bezier.toVectorPath.<anonymous>.<anonymous>' call
          var tmp0_0 = n_0;
          n_0 = tmp0_0 + 1 | 0;
          var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0_0);
          toVectorPath$bezier(first, out, tmp1__anonymous__uwfjfc);
        }
        if (tmp0__anonymous__q1qw7t.m48_1) {
          out.n1q();
        }
      } else {
        if (tmp0_subject instanceof Bezier) {
          toVectorPath$bezier(first, out, tmp0__anonymous__q1qw7t);
        } else {
          throw new NotImplementedError();
        }
      }
    }
    return out;
  }
  function toVectorPath_0(_this__u8e3s4, out) {
    out = out === VOID ? new VectorPath() : out;
    return toVectorPath(listOf(_this__u8e3s4), out);
  }
  function toVectorPath$bezier(first, $out, bezier) {
    var points = bezier.o45_1;
    if (first._v) {
      $out.y44(points.e1c());
      first._v = false;
    }
    var tmp0_subject = bezier.m46();
    switch (tmp0_subject) {
      case 1:
        $out.a45(points.n(1));
        ;
        break;
      case 2:
        $out.b45(points.n(1), points.n(2));
        ;
        break;
      case 3:
        $out.d45(points.n(1), points.n(2), points.n(3));
        ;
        break;
      default:
        // Inline function 'kotlin.TODO' call

        throw new NotImplementedError();
    }
  }
  function infos$factory() {
    return getPropertyCallableRef('infos', 1, KProperty1, function (receiver) {
      return receiver.s48();
    }, null);
  }
  function length$factory_0() {
    return getPropertyCallableRef('length', 1, KProperty1, function (receiver) {
      return receiver.hf();
    }, null);
  }
  function get_isConvex(_this__u8e3s4) {
    _init_properties_CurvesConvex_kt__dr0i2d();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var tmp0_getValue = isConvex$factory();
      var tmp0_elvis_lhs = isConvex$delegate.h1i_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp0_getValue.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = isConvex$delegate.i1i_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = isConvex$delegate.h1i_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? tmp0_getValue.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var isConvex$delegate;
  function isConvex$delegate$lambda($this$extraPropertyThis) {
    _init_properties_CurvesConvex_kt__dr0i2d();
    return $this$extraPropertyThis.o48_1 ? true : Convex_getInstance().i49($this$extraPropertyThis);
  }
  function isConvex$delegate$lambda_0($this$null, it) {
    _init_properties_CurvesConvex_kt__dr0i2d();
    return it;
  }
  function isConvex$factory() {
    return getPropertyCallableRef('isConvex', 1, KProperty1, function (receiver) {
      return get_isConvex(receiver);
    }, null);
  }
  var properties_initialized_CurvesConvex_kt_cc36yb;
  function _init_properties_CurvesConvex_kt__dr0i2d() {
    if (properties_initialized_CurvesConvex_kt_cc36yb) {
    } else {
      properties_initialized_CurvesConvex_kt_cc36yb = true;
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.extraPropertyThis' call
      var tmp1_extraPropertyThis = isConvex$delegate$lambda;
      var tmp2_extraPropertyThis = isConvex$delegate$lambda_0;
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.PropertyThis.withTransform' call
      var tmp0_withTransform = new PropertyThis(null, tmp1_extraPropertyThis);
      tmp0_withTransform.j1i_1 = tmp2_extraPropertyThis;
      tmp$ret$0 = tmp0_withTransform;
      tmp$ret$1 = tmp$ret$0;
      isConvex$delegate = tmp$ret$1;
    }
  }
  function toDashes(_this__u8e3s4, pattern, offset) {
    offset = offset === VOID ? 0.0 : offset;
    if (pattern == null)
      return listOf(_this__u8e3s4);
    // Inline function 'kotlin.check' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.all' call
      var indexedObject = pattern;
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var element = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.bezier.toDashes.<anonymous>' call
        tmp$ret$0 = element <= 0.0;
        if (!tmp$ret$0) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
      tmp$ret$1 = true;
    }
    var tmp0_check = !tmp$ret$1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.check' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_check) {
      var tmp$ret$2;
      // Inline function 'kotlin.check.<anonymous>' call
      tmp$ret$2 = 'Check failed.';
      var message = tmp$ret$2;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    var length = _this__u8e3s4.hf();
    var current = offset;
    var dashNow = true;
    var index = 0;
    var tmp$ret$3;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$3 = ArrayList_init_$Create$();
    var out = tmp$ret$3;
    while (current < length) {
      var tmp0 = index;
      index = tmp0 + 1 | 0;
      var len = getCyclic(pattern, tmp0);
      if (dashNow) {
        // Inline function 'kotlin.collections.plusAssign' call
        var tmp1_plusAssign = _this__u8e3s4.w48(current, current + len);
        out.b(tmp1_plusAssign);
      }
      current = current + len;
      dashNow = !dashNow;
    }
    return out;
  }
  function toStrokePointsList(_this__u8e3s4, info, mode, generateDebug, forceClosed) {
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    forceClosed = forceClosed === VOID ? null : forceClosed;
    return toStrokePointsList_0(_this__u8e3s4, info.j49_1, info.o49_1, info.m49_1, info.n49_1, info.p49_1, mode, info.q49_1, info.r49_1, generateDebug, forceClosed);
  }
  var StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance;
  var StrokePointsMode_NON_SCALABLE_POS_instance;
  var StrokePointsMode_entriesInitialized;
  function StrokePointsMode_initEntries() {
    if (StrokePointsMode_entriesInitialized)
      return Unit_getInstance();
    StrokePointsMode_entriesInitialized = true;
    StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance = new StrokePointsMode('SCALABLE_POS_NORMAL_WIDTH', 0);
    StrokePointsMode_NON_SCALABLE_POS_instance = new StrokePointsMode('NON_SCALABLE_POS', 1);
  }
  function StrokePointsMode(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function toStrokePointsList_0(_this__u8e3s4, width, join, startCap, endCap, miterLimit, mode, lineDash, lineDashOffset, generateDebug, forceClosed) {
    join = join === VOID ? LineJoin_MITER_getInstance() : join;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? LineCap_BUTT_getInstance() : endCap;
    miterLimit = miterLimit === VOID ? 10.0 : miterLimit;
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    lineDash = lineDash === VOID ? null : lineDash;
    lineDashOffset = lineDashOffset === VOID ? 0.0 : lineDashOffset;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    forceClosed = forceClosed === VOID ? null : forceClosed;
    var tmp;
    if (!(lineDash == null)) {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.flatMap' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.flatMapTo' call
      var tmp0_flatMapTo = ArrayList_init_$Create$();
      var tmp0_iterator = _this__u8e3s4.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>' call
        tmp$ret$0 = toDashes(element, lineDash.k1a(), lineDashOffset);
        var list = tmp$ret$0;
        addAll(tmp0_flatMapTo, list);
      }
      tmp$ret$1 = tmp0_flatMapTo;
      tmp$ret$2 = tmp$ret$1;
      tmp = tmp$ret$2;
    } else {
      tmp = _this__u8e3s4;
    }
    var curvesList = tmp;
    var tmp$ret$6;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$5;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp1_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(curvesList, 10));
    var tmp0_iterator_0 = curvesList.h();
    while (tmp0_iterator_0.j()) {
      var item = tmp0_iterator_0.k();
      var tmp$ret$4;
      // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>' call
      var tmp$ret$3;
      // Inline function 'kotlin.also' call
      var tmp0_also = new StrokePointsBuilder(width / 2.0, mode, generateDebug);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.bezier.toStrokePointsList.<anonymous>.<anonymous>' call
      tmp0_also.z49(item, join, startCap, endCap, miterLimit, forceClosed);
      tmp$ret$3 = tmp0_also;
      tmp$ret$4 = tmp$ret$3;
      tmp1_mapTo.b(tmp$ret$4);
    }
    tmp$ret$5 = tmp1_mapTo;
    tmp$ret$6 = tmp$ret$5;
    return tmp$ret$6;
  }
  function StrokePointsBuilder(width, mode, generateDebug) {
    mode = mode === VOID ? StrokePointsMode_NON_SCALABLE_POS_getInstance() : mode;
    generateDebug = generateDebug === VOID ? false : generateDebug;
    this.s49_1 = width;
    this.t49_1 = mode;
    this.u49_1 = generateDebug;
    this.v49_1 = 20;
    var tmp = this;
    var tmp0_subject = this.t49_1;
    var tmp0 = tmp0_subject.b2_1;
    var tmp_0;
    switch (tmp0) {
      case 0:
        tmp_0 = 6;
        break;
      case 1:
        tmp_0 = 2;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    tmp.w49_1 = new VectorArrayList(tmp_0);
    this.x49_1 = new PointArrayList();
    var tmp_1 = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp_1.y49_1 = tmp$ret$0;
  }
  protoOf(StrokePointsBuilder).a4a = function () {
    return this.w49_1;
  };
  protoOf(StrokePointsBuilder).toString = function () {
    return 'StrokePointsBuilder(' + this.s49_1 + ', ' + this.w49_1 + ')';
  };
  protoOf(StrokePointsBuilder).b4a = function (pos, normal, width, maxWidth) {
    var tmp0_subject = this.t49_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 0) {
      var tmp = pos.o3t();
      var tmp_0 = pos.p3t();
      var tmp_1 = normal.o3t();
      var tmp_2 = normal.p3t();
      var tmp$ret$0;
      // Inline function 'kotlin.math.absoluteValue' call
      tmp$ret$0 = Math.abs(maxWidth);
      this.w49_1.t44(tmp, tmp_0, tmp_1, tmp_2, width, tmp$ret$0);
    } else if (tmp0 === 1) {
      this.w49_1.o19(pos.p3r_1 + normal.p3r_1 * width, pos.q3r_1 + normal.q3r_1 * width);
    }
  };
  protoOf(StrokePointsBuilder).c4a = function (pos, normal, width, maxWidth, $super) {
    maxWidth = maxWidth === VOID ? width : maxWidth;
    var tmp;
    if ($super === VOID) {
      this.b4a(pos, normal, width, maxWidth);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.b4a.call(this, pos, normal, width, maxWidth);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).d4a = function (center, pos, sign) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$0 = new Vector2(pos.p3r_1 - center.p3r_1, pos.q3r_1 - center.q3r_1);
    var dist = tmp$ret$0;
    var tmp;
    if (sign < 0.0) {
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.Vector2.unaryMinus' call
      tmp$ret$1 = new Vector2(-dist.p3r_1, -dist.q3r_1);
      tmp = tmp$ret$1;
    } else {
      tmp = dist;
    }
    var normal = tmp;
    this.c4a(center, normal.c44(), dist.hf() * sign);
  };
  protoOf(StrokePointsBuilder).e4a = function (center, pos, sign, $super) {
    sign = sign === VOID ? 1.0 : sign;
    var tmp;
    if ($super === VOID) {
      this.d4a(center, pos, sign);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.d4a.call(this, center, pos, sign);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).f4a = function (pos, normal, width) {
    this.c4a(pos, normal, width);
    this.c4a(pos, normal, -width);
  };
  protoOf(StrokePointsBuilder).g4a = function (curr, next, kind, miterLimitRatio) {
    var commonPoint = curr.w46(1.0);
    var currTangent = curr.s47(1.0);
    var currNormal = curr.s46(1.0);
    var nextTangent = next.s47(0.0);
    var nextNormal = next.s46(0.0);
    var tmp = Companion_getInstance_3();
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp1_times = this.s49_1;
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp0_times = tmp1_times;
    tmp$ret$0 = new Vector2(currNormal.p3r_1 * tmp0_times, currNormal.q3r_1 * tmp0_times);
    tmp$ret$1 = tmp$ret$0;
    var tmp2_plus = tmp$ret$1;
    tmp$ret$2 = new Vector2(commonPoint.p3r_1 + tmp2_plus.p3r_1, commonPoint.q3r_1 + tmp2_plus.q3r_1);
    var currLine0 = tmp.t3t(tmp$ret$2, currTangent);
    var tmp_0 = Companion_getInstance_3();
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp4_times = -this.s49_1;
    var tmp$ret$3;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp3_times = tmp4_times;
    tmp$ret$3 = new Vector2(currNormal.p3r_1 * tmp3_times, currNormal.q3r_1 * tmp3_times);
    tmp$ret$4 = tmp$ret$3;
    var tmp5_plus = tmp$ret$4;
    tmp$ret$5 = new Vector2(commonPoint.p3r_1 + tmp5_plus.p3r_1, commonPoint.q3r_1 + tmp5_plus.q3r_1);
    var currLine1 = tmp_0.t3t(tmp$ret$5, currTangent);
    var tmp_1 = Companion_getInstance_3();
    var tmp$ret$8;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$7;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp7_times = this.s49_1;
    var tmp$ret$6;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp6_times = tmp7_times;
    tmp$ret$6 = new Vector2(nextNormal.p3r_1 * tmp6_times, nextNormal.q3r_1 * tmp6_times);
    tmp$ret$7 = tmp$ret$6;
    var tmp8_plus = tmp$ret$7;
    tmp$ret$8 = new Vector2(commonPoint.p3r_1 + tmp8_plus.p3r_1, commonPoint.q3r_1 + tmp8_plus.q3r_1);
    var nextLine0 = tmp_1.t3t(tmp$ret$8, nextTangent);
    var tmp_2 = Companion_getInstance_3();
    var tmp$ret$11;
    // Inline function 'korlibs.math.geom.Vector2.plus' call
    var tmp$ret$10;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp10_times = -this.s49_1;
    var tmp$ret$9;
    // Inline function 'korlibs.math.geom.Vector2.times' call
    var tmp9_times = tmp10_times;
    tmp$ret$9 = new Vector2(nextNormal.p3r_1 * tmp9_times, nextNormal.q3r_1 * tmp9_times);
    tmp$ret$10 = tmp$ret$9;
    var tmp11_plus = tmp$ret$10;
    tmp$ret$11 = new Vector2(commonPoint.p3r_1 + tmp11_plus.p3r_1, commonPoint.q3r_1 + tmp11_plus.q3r_1);
    var nextLine1 = tmp_2.t3t(tmp$ret$11, nextTangent);
    var intersection0 = lineIntersectionPoint(Companion_getInstance_3(), currLine0, nextLine0);
    var intersection1 = lineIntersectionPoint(Companion_getInstance_3(), currLine1, nextLine1);
    if (intersection0 == null ? true : intersection1 == null) {
      this.f4a(commonPoint, currNormal, this.s49_1);
      return Unit_getInstance();
    }
    var direction = Companion_getInstance_21().t43(currTangent, nextTangent);
    var miterLength = Companion_getInstance_21().q43(intersection0, intersection1);
    var miterLimit = miterLimitRatio * this.s49_1;
    var tmp$ret$17;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var tmp16_atan2 = Companion_getInstance_0();
    var tmp17_atan2 = Companion_getInstance_21().v3r_1;
    var tmp$ret$16;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var tmp14_atan2 = nextTangent.o3t();
    var tmp15_atan2 = nextTangent.p3t();
    var tmp$ret$15;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp$ret$12;
    // Inline function 'kotlin.math.atan2' call
    tmp$ret$12 = Math.atan2(tmp14_atan2, tmp15_atan2);
    var tmp13_fromRadians = tmp$ret$12;
    var tmp$ret$14;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$13;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$13 = tmp13_fromRadians / get_PI2();
    var tmp12_fromRatio = tmp$ret$13;
    tmp$ret$14 = _Angle___init__impl__g23q1m(tmp12_fromRatio);
    tmp$ret$15 = tmp$ret$14;
    tmp$ret$16 = adjustFromUp(tmp$ret$15, tmp17_atan2);
    tmp$ret$17 = tmp$ret$16;
    var tmp_3 = tmp$ret$17;
    var tmp$ret$23;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var tmp22_atan2 = Companion_getInstance_0();
    var tmp23_atan2 = Companion_getInstance_21().v3r_1;
    var tmp$ret$22;
    // Inline function 'korlibs.math.geom.Companion.atan2' call
    var tmp20_atan2 = currTangent.o3t();
    var tmp21_atan2 = currTangent.p3t();
    var tmp$ret$21;
    // Inline function 'korlibs.math.geom.Companion.fromRadians' call
    var tmp$ret$18;
    // Inline function 'kotlin.math.atan2' call
    tmp$ret$18 = Math.atan2(tmp20_atan2, tmp21_atan2);
    var tmp19_fromRadians = tmp$ret$18;
    var tmp$ret$20;
    // Inline function 'korlibs.math.geom.Companion.fromRatio' call
    var tmp$ret$19;
    // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
    tmp$ret$19 = tmp19_fromRadians / get_PI2();
    var tmp18_fromRatio = tmp$ret$19;
    tmp$ret$20 = _Angle___init__impl__g23q1m(tmp18_fromRatio);
    tmp$ret$21 = tmp$ret$20;
    tmp$ret$22 = adjustFromUp(tmp$ret$21, tmp23_atan2);
    tmp$ret$23 = tmp$ret$22;
    var angle = Angle__minus_impl_xeau7k(tmp_3, tmp$ret$23);
    if (!kind.equals(LineJoin_MITER_getInstance()) ? true : miterLength > miterLimit) {
      var p1 = direction <= 0.0 ? projectedPoint(currLine0, commonPoint) : projectedPoint(nextLine1, commonPoint);
      var p2 = direction <= 0.0 ? projectedPoint(nextLine0, commonPoint) : projectedPoint(currLine1, commonPoint);
      var p3 = direction <= 0.0 ? lineIntersectionPoint(Companion_getInstance_3(), currLine1, nextLine1) : lineIntersectionPoint(Companion_getInstance_3(), currLine0, nextLine0);
      var p4Line = direction < 0.0 ? nextLine1 : nextLine0;
      var p4 = projectedPoint(p4Line, commonPoint);
      if (p3 == null) {
        p3 = p4;
      }
      var angleB = _Angle___get_absoluteValue__impl__4qoakv(Angle__plus_impl_26v37k(angle, get_degrees(180)));
      var angle2 = _Angle___get_absoluteValue__impl__4qoakv(Angle__umod_impl_4wilf7(angle, get_degrees(180)));
      var angle3 = Angle__compareTo_impl_a0hfds(angle2, get_degrees(90)) >= 0 ? Angle__minus_impl_xeau7k(get_degrees(180), angle2) : angle2;
      var tmp$ret$24;
      // Inline function 'kotlin.math.absoluteValue' call
      var tmp24__get_absoluteValue__c3ovrj = _Angle___get_ratio__impl__ap3on4(angle3);
      tmp$ret$24 = Math.abs(tmp24__get_absoluteValue__c3ovrj);
      var ratio = clamp(tmp$ret$24 * 4, 0.0, 1.0);
      var p5 = interpolate(toRatio(ratio), p4, ensureNotNull(p3));
      if (this.u49_1) {
        this.y49_1.b(nextLine1.v3t(1000.0).z19());
        this.y49_1.b(currLine1.v3t(1000.0).z19());
        this.y49_1.b(nextLine0.v3t(1000.0).z19());
        this.y49_1.b(currLine0.v3t(1000.0).z19());
        this.y49_1.b(Companion_getInstance_3().t3t(commonPoint, currTangent).v3t(1000.0).z19());
        this.y49_1.b(Companion_getInstance_3().t3t(commonPoint, nextTangent).v3t(1000.0).z19());
        this.x49_1.g40(p3);
        this.x49_1.g40(p4);
        this.x49_1.g40(p5);
      }
      var p6 = p5;
      if (direction < 0.0) {
        this.e4a(commonPoint, p1);
        this.d4a(commonPoint, p6, -1.0);
        this.e4a(commonPoint, p2);
        this.d4a(commonPoint, p6, -1.0);
      } else {
        this.e4a(commonPoint, p6);
        this.d4a(commonPoint, p2, -1.0);
        this.e4a(commonPoint, p6);
        this.d4a(commonPoint, p1, -1.0);
      }
      return Unit_getInstance();
    }
    var tmp$ret$25;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$25 = new Vector2(intersection0.p3r_1 - commonPoint.p3r_1, intersection0.q3r_1 - commonPoint.q3r_1);
    var d0 = tmp$ret$25;
    var tmp$ret$26;
    // Inline function 'korlibs.math.geom.Vector2.minus' call
    tmp$ret$26 = new Vector2(commonPoint.p3r_1 - intersection1.p3r_1, commonPoint.q3r_1 - intersection1.q3r_1);
    var d1 = tmp$ret$26;
    var tmp_4 = d0.c44();
    var tmp_5 = d0.hf();
    var tmp$ret$27;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp25__get_absoluteValue__8luwcu = d0.hf();
    tmp$ret$27 = Math.abs(tmp25__get_absoluteValue__8luwcu);
    this.b4a(commonPoint, tmp_4, tmp_5, tmp$ret$27);
    var tmp_6 = d1.c44();
    var tmp_7 = -d1.hf();
    var tmp$ret$28;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp26__get_absoluteValue__540wy5 = d1.hf();
    tmp$ret$28 = Math.abs(tmp26__get_absoluteValue__540wy5);
    this.b4a(commonPoint, tmp_6, tmp_7, tmp$ret$28);
  };
  protoOf(StrokePointsBuilder).h4a = function (curr, ratio, kind) {
    var tmp0_subject = kind;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 1:
      case 2:
        var tmp$ret$2;
        // Inline function 'kotlin.let' call
        var tmp0_let = curr.s46(ratio).e44();
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$1;
        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCap.<anonymous>' call
        var tmp;
        if (ratio === 1.0) {
          var tmp$ret$0;
          // Inline function 'korlibs.math.geom.Vector2.unaryMinus' call
          tmp$ret$0 = new Vector2(-tmp0_let.p3r_1, -tmp0_let.q3r_1);
          tmp = tmp$ret$0;
        } else {
          tmp = tmp0_let;
        }
        tmp$ret$1 = tmp;
        tmp$ret$2 = tmp$ret$1;

        var derivate = tmp$ret$2;
        var tmp1_subject = kind;
        var tmp0_0 = tmp1_subject.b2_1;
        switch (tmp0_0) {
          case 1:
            var tmp$ret$5;
            // Inline function 'korlibs.math.geom.Vector2.plus' call
            var tmp3_plus = curr.w46(ratio);
            var tmp$ret$4;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp2_times = this.s49_1;
            var tmp$ret$3;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp1_times = tmp2_times;
            tmp$ret$3 = new Vector2(derivate.p3r_1 * tmp1_times, derivate.q3r_1 * tmp1_times);
            tmp$ret$4 = tmp$ret$3;
            var tmp4_plus = tmp$ret$4;
            tmp$ret$5 = new Vector2(tmp3_plus.p3r_1 + tmp4_plus.p3r_1, tmp3_plus.q3r_1 + tmp4_plus.q3r_1);

            this.f4a(tmp$ret$5, curr.s46(ratio), this.s49_1);
            ;
            break;
          case 2:
            var mid = curr.w46(ratio);
            var normal = curr.s46(ratio);
            var tmp$ret$8;
            // Inline function 'korlibs.math.geom.Vector2.plus' call
            var tmp$ret$7;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp6_times = this.s49_1;
            var tmp$ret$6;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp5_times = tmp6_times;
            tmp$ret$6 = new Vector2(normal.p3r_1 * tmp5_times, normal.q3r_1 * tmp5_times);
            tmp$ret$7 = tmp$ret$6;
            var tmp7_plus = tmp$ret$7;
            tmp$ret$8 = new Vector2(mid.p3r_1 + tmp7_plus.p3r_1, mid.q3r_1 + tmp7_plus.q3r_1);

            var p0 = tmp$ret$8;
            var tmp$ret$11;
            // Inline function 'korlibs.math.geom.Vector2.plus' call
            var tmp$ret$10;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp9_times = -this.s49_1;
            var tmp$ret$9;
            // Inline function 'korlibs.math.geom.Vector2.times' call
            var tmp8_times = tmp9_times;
            tmp$ret$9 = new Vector2(normal.p3r_1 * tmp8_times, normal.q3r_1 * tmp8_times);
            tmp$ret$10 = tmp$ret$9;
            var tmp10_plus = tmp$ret$10;
            tmp$ret$11 = new Vector2(mid.p3r_1 + tmp10_plus.p3r_1, mid.q3r_1 + tmp10_plus.q3r_1);

            var p3 = tmp$ret$11;
            var a = ratio === 0.0 ? p0 : p3;
            var b = ratio === 0.0 ? p3 : p0;
            this.i4a(a, b, ratio, mid);
            ;
            break;
          default:
            // Inline function 'kotlin.error' call

            throw IllegalStateException_init_$Create$("Can't happen");
        }

        break;
      case 0:
        this.f4a(curr.w46(ratio), curr.s46(ratio), this.s49_1);
        ;
        break;
    }
  };
  protoOf(StrokePointsBuilder).j4a = function (p0, p3, ratio, mid, nsteps) {
    var tmp$ret$6;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp8_between = Companion_getInstance_0();
    var tmp9_between = Companion_getInstance_21().v3r_1;
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp4_between = mid.p3r_1;
    var tmp5_between = mid.q3r_1;
    var tmp6_between = p0.p3r_1;
    var tmp7_between = p0.q3r_1;
    var tmp$ret$4;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp0_between = tmp4_between;
    var tmp1_between = tmp5_between;
    var tmp2_between = tmp6_between;
    var tmp3_between = tmp7_between;
    tmp$ret$4 = Angle_between(tmp0_between, tmp1_between, tmp2_between, tmp3_between, tmp9_between);
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    var angleStart = tmp$ret$6;
    var tmp$ret$9;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp18_between = Companion_getInstance_0();
    var tmp19_between = Companion_getInstance_21().v3r_1;
    var tmp$ret$8;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp14_between = mid.p3r_1;
    var tmp15_between = mid.q3r_1;
    var tmp16_between = p3.p3r_1;
    var tmp17_between = p3.q3r_1;
    var tmp$ret$7;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp10_between = tmp14_between;
    var tmp11_between = tmp15_between;
    var tmp12_between = tmp16_between;
    var tmp13_between = tmp17_between;
    tmp$ret$7 = Angle_between(tmp10_between, tmp11_between, tmp12_between, tmp13_between, tmp19_between);
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var angleEnd = tmp$ret$9;
    if (ratio === 1.0) {
      var tmp$ret$11;
      // Inline function 'korlibs.math.geom.Companion.polar' call
      var tmp20_polar = Companion_getInstance_21();
      var tmp21_polar = tmp20_polar.v3r_1;
      var tmp$ret$10;
      // Inline function 'korlibs.math.geom.Companion.polar' call
      tmp$ret$10 = Vector2_init_$Create$(0.0 + Angle__cosineD_impl_9kzfyt(angleEnd, tmp21_polar) * 1.0, 0.0 + Angle__sineD_impl_u3tkpj(angleEnd, tmp21_polar) * 1.0);
      tmp$ret$11 = tmp$ret$10;
      this.f4a(mid, tmp$ret$11, this.s49_1);
    }
    var addAngle = Companion_getInstance_21().t43(p0, p3) <= 0.0 ? Companion_getInstance_0().g3r_1 : Companion_getInstance_0().i3r_1;
    // Inline function 'korlibs.math.interpolation.Companion.forEachRatio' call
    var tmp22_forEachRatio = Companion_getInstance_42();
    var NS = nsteps - 1 | 0;
    var NSf = NS;
    var start = 0;
    var end = NS;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio_0 = n / NSf;
        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCurvePointsCap.<anonymous>' call
        var tmp23__anonymous__bih5za = toRatio(ratio_0);
        var angle = interpolateAngleDenormalized(tmp23__anonymous__bih5za, angleStart, angleEnd);
        var tmp$ret$13;
        // Inline function 'korlibs.math.geom.Companion.polar' call
        var tmp0_polar = Companion_getInstance_21();
        var tmp1_polar = Angle__plus_impl_26v37k(angle, addAngle);
        var tmp2_polar = tmp0_polar.v3r_1;
        var tmp$ret$12;
        // Inline function 'korlibs.math.geom.Companion.polar' call
        tmp$ret$12 = Vector2_init_$Create$(0.0 + Angle__cosineD_impl_9kzfyt(tmp1_polar, tmp2_polar) * 1.0, 0.0 + Angle__sineD_impl_u3tkpj(tmp1_polar, tmp2_polar) * 1.0);
        tmp$ret$13 = tmp$ret$12;
        var dir = tmp$ret$13;
        this.b4a(mid, dir, 0.0, this.s49_1);
        this.b4a(mid, dir, this.s49_1, this.s49_1);
      }
       while (!(n === end));
    if (ratio === 0.0) {
      var tmp$ret$15;
      // Inline function 'korlibs.math.geom.Companion.polar' call
      var tmp24_polar = Companion_getInstance_21();
      var tmp25_polar = tmp24_polar.v3r_1;
      var tmp$ret$14;
      // Inline function 'korlibs.math.geom.Companion.polar' call
      tmp$ret$14 = Vector2_init_$Create$(0.0 + Angle__cosineD_impl_9kzfyt(angleStart, tmp25_polar) * 1.0, 0.0 + Angle__sineD_impl_u3tkpj(angleStart, tmp25_polar) * 1.0);
      tmp$ret$15 = tmp$ret$14;
      this.f4a(mid, tmp$ret$15, this.s49_1);
    }
  };
  protoOf(StrokePointsBuilder).i4a = function (p0, p3, ratio, mid, nsteps, $super) {
    var tmp;
    if (mid === VOID) {
      var tmp$ret$3;
      var tmp$ret$3_0;
      // Inline function 'korlibs.math.geom.Companion.middle' call
      var tmp2_middle = Companion_getInstance_21();
      var tmp$ret$2;
      var tmp$ret$2_0;
      // Inline function 'korlibs.math.geom.Vector2.times' call
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'korlibs.math.geom.Vector2.plus' call
      tmp$ret$0 = new Vector2(p0.p3r_1 + p3.p3r_1, p0.q3r_1 + p3.q3r_1);
      tmp$ret$0_0 = Unit_getInstance();
      var tmp1_times = tmp$ret$0;
      var tmp$ret$1;
      var tmp$ret$1_0;
      // Inline function 'korlibs.math.geom.Vector2.times' call
      var tmp0_times = 0.5;
      tmp$ret$1 = new Vector2(tmp1_times.p3r_1 * tmp0_times, tmp1_times.q3r_1 * tmp0_times);
      tmp$ret$1_0 = Unit_getInstance();
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$2_0 = Unit_getInstance();
      tmp$ret$3 = tmp$ret$2;
      tmp$ret$3_0 = Unit_getInstance();
      tmp = tmp$ret$3;
    } else {
      tmp = mid;
    }
    mid = tmp;
    nsteps = nsteps === VOID ? this.v49_1 : nsteps;
    var tmp_0;
    if ($super === VOID) {
      this.j4a(p0, p3, ratio, mid, nsteps);
      tmp_0 = Unit_getInstance();
    } else {
      tmp_0 = $super.j4a.call(this, p0, p3, ratio, mid, nsteps);
    }
    return tmp_0;
  };
  protoOf(StrokePointsBuilder).k4a = function (curr, nsteps) {
    // Inline function 'korlibs.math.interpolation.Companion.forEachRatio' call
    var tmp0_forEachRatio = Companion_getInstance_42();
    var NS = nsteps - 1 | 0;
    var NSf = NS;
    var start = false ? 0 : 1;
    var end = false ? NS : NS - 1 | 0;
    var inductionVariable = start;
    if (inductionVariable <= end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = n / NSf;
        // Inline function 'korlibs.math.geom.bezier.StrokePointsBuilder.addCurvePoints.<anonymous>' call
        var tmp1__anonymous__uwfjfc = toRatio(ratio);
        this.f4a(curr.w46(Ratio__toFloat_impl_1ftup5(tmp1__anonymous__uwfjfc)), curr.s46(Ratio__toFloat_impl_1ftup5(tmp1__anonymous__uwfjfc)), this.s49_1);
      }
       while (!(n === end));
  };
  protoOf(StrokePointsBuilder).l4a = function (curr, nsteps, $super) {
    nsteps = nsteps === VOID ? numberToInt(clamp_0(curr.hf() / 10.0, 10.0, 100.0)) : nsteps;
    var tmp;
    if ($super === VOID) {
      this.k4a(curr, nsteps);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.k4a.call(this, curr, nsteps);
    }
    return tmp;
  };
  protoOf(StrokePointsBuilder).z49 = function (curves, join, startCap, endCap, miterLimit, forceClosed) {
    var tmp0_elvis_lhs = forceClosed;
    var closed = tmp0_elvis_lhs == null ? curves.m48_1 : tmp0_elvis_lhs;
    var curves_0 = curves.l48_1;
    var inductionVariable = 0;
    var last = curves_0.l() - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var curr = getCyclic_0(curves_0, n + 0 | 0);
        var next = getCyclic_0(curves_0, n + 1 | 0);
        if (n === 0) {
          if (closed) {
            this.g4a(getCyclic_0(curves_0, n - 1 | 0), curr, join, miterLimit);
          } else {
            this.h4a(curr, 0.0, startCap);
          }
        }
        if (!(curr.m46() === 1)) {
          this.l4a(curr);
        }
        if (n < (curves_0.l() - 1 | 0)) {
          this.g4a(curr, next, join, miterLimit);
        } else {
          if (closed) {
            this.g4a(curr, next, join, miterLimit);
          } else {
            this.h4a(curr, 1.0, endCap);
          }
        }
      }
       while (inductionVariable <= last);
  };
  function StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance() {
    StrokePointsMode_initEntries();
    return StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_instance;
  }
  function StrokePointsMode_NON_SCALABLE_POS_getInstance() {
    StrokePointsMode_initEntries();
    return StrokePointsMode_NON_SCALABLE_POS_instance;
  }
  function BezierCurveFromIndices($this, indices, points) {
    var p = new PointArrayList(indices.length);
    var indexedObject = indices;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var index = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      p.j40(points, index);
    }
    return Bezier_init_$Create$_2(p);
  }
  function SubBezier_init_$Init$(curve, $this) {
    SubBezier.call($this, curve, 0.0, 1.0, null);
    return $this;
  }
  function SubBezier_init_$Create$(curve) {
    return SubBezier_init_$Init$(curve, objectCreate(protoOf(SubBezier)));
  }
  function Companion_25() {
    Companion_instance_25 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$0 = new Int32Array([0, 3, 5]);
    var tmp_0 = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$1 = new Int32Array([0, 4, 7, 9]);
    tmp.m4a_1 = listOf_0([null, null, tmp_0, tmp$ret$1]);
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$2 = new Int32Array([5, 4, 2]);
    var tmp_2 = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$3 = new Int32Array([9, 8, 6, 3]);
    tmp_1.n4a_1 = listOf_0([null, null, tmp_2, tmp$ret$3]);
  }
  var Companion_instance_25;
  function Companion_getInstance_25() {
    if (Companion_instance_25 == null)
      new Companion_25();
    return Companion_instance_25;
  }
  function _split($this, t, hull, left) {
    var rt = convertRange(t, 0.0, 1.0, $this.x47_1, $this.y47_1);
    var rt1 = left ? $this.x47_1 : rt;
    var rt2 = left ? rt : $this.y47_1;
    var tmp;
    if ($this.w47_1.m46() < 2) {
      var p1 = $this.w46(rt1);
      var p2 = $this.w46(rt2);
      tmp = Bezier_init_$Create$(p1, p2);
    } else {
      var indices = left ? Companion_getInstance_25().m4a_1 : Companion_getInstance_25().n4a_1;
      tmp = BezierCurveFromIndices(Companion_getInstance_25(), ensureNotNull(indices.n($this.w47_1.m46())), ensureNotNull(hull));
    }
    var curve = tmp;
    return new SubBezier(curve, rt1, rt2, $this.z47_1);
  }
  function _splitLeft($this, t, hull) {
    return _split($this, t, hull, true);
  }
  function _splitLeft$default($this, t, hull, $super) {
    hull = hull === VOID ? $this.w47_1.u47(t) : hull;
    return _splitLeft($this, t, hull);
  }
  function _splitRight($this, t, hull) {
    return _split($this, t, hull, false);
  }
  function _splitRight$default($this, t, hull, $super) {
    hull = hull === VOID ? $this.w47_1.u47(t) : hull;
    return _splitRight($this, t, hull);
  }
  function SubBezier(curve, t1, t2, parent) {
    Companion_getInstance_25();
    this.w47_1 = curve;
    this.x47_1 = t1;
    this.y47_1 = t2;
    this.z47_1 = parent;
  }
  protoOf(SubBezier).w46 = function (t) {
    return this.w47_1.w46(convertRange(t, this.x47_1, this.y47_1, 0.0, 1.0));
  };
  protoOf(SubBezier).b48 = function (t) {
    return _splitLeft$default(this, t);
  };
  protoOf(SubBezier).a48 = function (t) {
    return _splitRight$default(this, t);
  };
  protoOf(SubBezier).toString = function () {
    return 'SubBezier[' + get_niceStr(this.x47_1) + '..' + get_niceStr(this.y47_1) + '](' + this.w47_1 + ')';
  };
  function Companion_26() {
    Companion_instance_26 = this;
  }
  protoOf(Companion_26).o4a = function (width, height, algo) {
    return new BinPacker(Size_init_$Create$(width, height), algo);
  };
  protoOf(Companion_26).p4a = function (width, height, algo) {
    return Companion_getInstance_26().o4a(width, height, algo);
  };
  protoOf(Companion_26).q4a = function (width, height, algo, $super) {
    algo = algo === VOID ? MaxRects_init_$Create$(width, height) : algo;
    return $super === VOID ? this.p4a(width, height, algo) : $super.p4a.call(this, width, height, algo);
  };
  var Companion_instance_26;
  function Companion_getInstance_26() {
    if (Companion_instance_26 == null)
      new Companion_26();
    return Companion_instance_26;
  }
  function ImageDoNotFitException(width, height, packer) {
    extendThrowable(this, "Size '" + width + 'x' + height + "' doesn't fit in '" + packer.x18() + 'x' + packer.y18() + "'");
    captureStack(this, ImageDoNotFitException);
    this.u4a_1 = width;
    this.v4a_1 = height;
    this.w4a_1 = packer;
  }
  function BinPacker(size, algo) {
    Companion_getInstance_26();
    algo = algo === VOID ? new MaxRects(size) : algo;
    this.r4a_1 = size;
    this.s4a_1 = algo;
    this.t4a_1 = FastArrayList_init_$Create$();
  }
  protoOf(BinPacker).x18 = function () {
    return this.r4a_1.w40_1;
  };
  protoOf(BinPacker).y18 = function () {
    return this.r4a_1.x40_1;
  };
  protoOf(BinPacker).f40 = function (width, height) {
    var tmp0_elvis_lhs = this.x4a(width, height);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw new ImageDoNotFitException(width, height, this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(BinPacker).x4a = function (width, height) {
    var tmp0_elvis_lhs = this.s4a_1.y4a(Size_init_$Create$(width, height));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var rect = tmp;
    var tmp1_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp1_this.t4a_1;
    tmp0_plusAssign.b(rect);
    return rect;
  };
  function MaxRects_init_$Init$(width, height, $this) {
    MaxRects.call($this, Size_init_$Create$(width, height));
    return $this;
  }
  function MaxRects_init_$Create$(width, height) {
    return MaxRects_init_$Init$(width, height, objectCreate(protoOf(MaxRects)));
  }
  function quickFindPositionForNewNodeBestAreaFit($this, width, height) {
    DoubleCompanionObject_getInstance();
    var score = 1.7976931348623157E308;
    var areaFit;
    var bestNode = Companion_getInstance_17().s3w();
    var tmp0_iterator = $this.z4a_1.h();
    while (tmp0_iterator.j()) {
      var r = tmp0_iterator.k();
      if (r.b3s_1 >= width ? r.c3s_1 >= height : false) {
        areaFit = r.b3s_1 * r.c3s_1 - width * height;
        if (areaFit < score) {
          bestNode = new Rectangle(r.z3r_1, r.a3s_1, width, height);
          score = areaFit;
        }
      }
    }
    return bestNode;
  }
  function splitFreeNode($this, freeNode, usedNode) {
    var newNode;
    if (((usedNode.f3s() >= freeNode.g3s() ? true : usedNode.g3s() <= freeNode.z3r_1) ? true : usedNode.h3s() >= freeNode.i3s()) ? true : usedNode.i3s() <= freeNode.h3s()) {
      return false;
    }
    if (usedNode.z3r_1 < freeNode.g3s() ? usedNode.g3s() > freeNode.z3r_1 : false) {
      if (usedNode.a3s_1 > freeNode.a3s_1 ? usedNode.a3s_1 < freeNode.i3s() : false) {
        newNode = freeNode.z41(VOID, VOID, VOID, usedNode.a3s_1 - freeNode.a3s_1);
        $this.z4a_1.b(newNode);
      }
      if (usedNode.i3s() < freeNode.i3s()) {
        newNode = freeNode.z41(VOID, usedNode.i3s(), VOID, freeNode.i3s() - usedNode.i3s());
        $this.z4a_1.b(newNode);
      }
    }
    if (usedNode.a3s_1 < freeNode.i3s() ? usedNode.i3s() > freeNode.a3s_1 : false) {
      if (usedNode.z3r_1 > freeNode.z3r_1 ? usedNode.z3r_1 < freeNode.g3s() : false) {
        newNode = freeNode.z41(VOID, VOID, usedNode.z3r_1 - freeNode.z3r_1);
        $this.z4a_1.b(newNode);
      }
      if (usedNode.g3s() < freeNode.g3s()) {
        newNode = freeNode.z41(usedNode.g3s(), VOID, freeNode.g3s() - usedNode.g3s());
        $this.z4a_1.b(newNode);
      }
    }
    return true;
  }
  function pruneFreeList($this) {
    var len = $this.z4a_1.l();
    var i = 0;
    while (i < len) {
      var j = i + 1 | 0;
      var tmpRect = $this.z4a_1.n(i);
      $l$loop: while (j < len) {
        var tmpRect2 = $this.z4a_1.n(j);
        if (Companion_getInstance_17().b41(tmpRect, tmpRect2)) {
          $this.z4a_1.c4(i);
          i = i - 1 | 0;
          len = len - 1 | 0;
          break $l$loop;
        }
        if (Companion_getInstance_17().b41(tmpRect2, tmpRect)) {
          $this.z4a_1.c4(j);
          len = len - 1 | 0;
          j = j - 1 | 0;
        }
        var tmp0 = j;
        j = tmp0 + 1 | 0;
      }
      var tmp1 = i;
      i = tmp1 + 1 | 0;
    }
  }
  function MaxRects(maxSize) {
    this.z4a_1 = fastArrayListOf([Companion_getInstance_17().t3s(Companion_getInstance_21().r3r_1, maxSize)]);
  }
  protoOf(MaxRects).y4a = function (size) {
    return this.a4b(size);
  };
  protoOf(MaxRects).a4b = function (size) {
    var tmp0_container = size;
    var width = tmp0_container.c5();
    var height = tmp0_container.d5();
    if (width <= 0.0 ? height <= 0.0 : false)
      return Companion_getInstance_17().v40(0, 0, 0, 0);
    var newNode = quickFindPositionForNewNodeBestAreaFit(this, width, height);
    if (newNode.c3s_1 === 0.0)
      return null;
    var numRectanglesToProcess = this.z4a_1.l();
    var i = 0;
    while (i < numRectanglesToProcess) {
      if (splitFreeNode(this, this.z4a_1.n(i), newNode)) {
        this.z4a_1.c4(i);
        numRectanglesToProcess = numRectanglesToProcess - 1 | 0;
        i = i - 1 | 0;
      }
      var tmp1 = i;
      i = tmp1 + 1 | 0;
    }
    pruneFreeList(this);
    return newNode;
  };
  var HitTestDirection_ANY_instance;
  var HitTestDirection_UP_instance;
  var HitTestDirection_RIGHT_instance;
  var HitTestDirection_DOWN_instance;
  var HitTestDirection_LEFT_instance;
  function Companion_27() {
    Companion_instance_27 = this;
  }
  var Companion_instance_27;
  function Companion_getInstance_27() {
    HitTestDirection_initEntries();
    if (Companion_instance_27 == null)
      new Companion_27();
    return Companion_instance_27;
  }
  var HitTestDirection_entriesInitialized;
  function HitTestDirection_initEntries() {
    if (HitTestDirection_entriesInitialized)
      return Unit_getInstance();
    HitTestDirection_entriesInitialized = true;
    HitTestDirection_ANY_instance = new HitTestDirection('ANY', 0);
    HitTestDirection_UP_instance = new HitTestDirection('UP', 1);
    HitTestDirection_RIGHT_instance = new HitTestDirection('RIGHT', 2);
    HitTestDirection_DOWN_instance = new HitTestDirection('DOWN', 3);
    HitTestDirection_LEFT_instance = new HitTestDirection('LEFT', 4);
    Companion_getInstance_27();
  }
  function HitTestDirection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function HitTestDirection_ANY_getInstance() {
    HitTestDirection_initEntries();
    return HitTestDirection_ANY_instance;
  }
  function Convex() {
    Convex_instance = this;
  }
  protoOf(Convex).i49 = function (curves) {
    var tmp0_elvis_lhs = toNonCurveSimplePointList(curves);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var points = tmp;
    return this.b4b(points);
  };
  protoOf(Convex).b4b = function (vertices) {
    var base = 0;
    var n = vertices.l();
    var TWO_PI = get_PI2();
    if (n <= 3)
      return true;
    if (vertices.v3z(base) === vertices.v3z(n - 1 | 0) ? vertices.u3z(base) === vertices.u3z(n - 1 | 0) : false) {
      var tmp0 = n;
      n = tmp0 - 1 | 0;
    }
    var old_x = vertices.v3z(n - 2 | 0);
    var old_y = vertices.u3z(n - 2 | 0);
    var new_x = vertices.v3z(n - 1 | 0);
    var new_y = vertices.u3z(n - 1 | 0);
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    var tmp0_atan2 = new_y - old_y;
    var tmp1_atan2 = new_x - old_x;
    tmp$ret$0 = Math.atan2(tmp0_atan2, tmp1_atan2);
    var new_direction = tmp$ret$0;
    var old_direction;
    var angle_sum = Companion_getInstance_0().g3r_1;
    var orientation = 0.0;
    var inductionVariable = 0;
    var last = n;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        old_x = new_x;
        old_y = new_y;
        old_direction = new_direction;
        var tmp2 = base;
        base = tmp2 + 1 | 0;
        var p = tmp2;
        new_x = vertices.v3z(p);
        new_y = vertices.u3z(p);
        var tmp$ret$1;
        // Inline function 'kotlin.math.atan2' call
        var tmp2_atan2 = new_y - old_y;
        var tmp3_atan2 = new_x - old_x;
        tmp$ret$1 = Math.atan2(tmp2_atan2, tmp3_atan2);
        new_direction = tmp$ret$1;
        if (old_x === new_x ? old_y === new_y : false) {
          return false;
        }
        var angle = new_direction - old_direction;
        if (angle <= -get_PI())
          angle = angle + TWO_PI;
        else if (angle > get_PI())
          angle = angle - TWO_PI;
        if (i === 0) {
          if (angle === 0.0) {
            return false;
          }
          orientation = angle > 0.0 ? 1.0 : -1.0;
        } else if (orientation * angle < 0.0) {
          return false;
        }
        var tmp = angle_sum;
        var tmp$ret$4;
        // Inline function 'korlibs.math.geom.Companion.fromRadians' call
        var tmp5_fromRadians = Companion_getInstance_0();
        var tmp6_fromRadians = angle;
        var tmp$ret$3;
        // Inline function 'korlibs.math.geom.Companion.fromRatio' call
        var tmp$ret$2;
        // Inline function 'korlibs.math.geom.Companion.radiansToRatio' call
        tmp$ret$2 = tmp6_fromRadians / get_PI2();
        var tmp4_fromRatio = tmp$ret$2;
        tmp$ret$3 = _Angle___init__impl__g23q1m(tmp4_fromRatio);
        tmp$ret$4 = tmp$ret$3;
        angle_sum = Angle__plus_impl_26v37k(tmp, tmp$ret$4);
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp7__get_absoluteValue__mppjag = _Angle___get_ratio__impl__ap3on4(angle_sum);
    tmp$ret$5 = Math.abs(tmp7__get_absoluteValue__mppjag);
    return isAlmostEquals(1.0, tmp$ret$5);
  };
  var Convex_instance;
  function Convex_getInstance() {
    if (Convex_instance == null)
      new Convex();
    return Convex_instance;
  }
  function get_cachedPoints(_this__u8e3s4) {
    _init_properties_Shape2D_kt__ty8b9d();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var tmp0_getValue = cachedPoints$factory();
      var tmp0_elvis_lhs = cachedPoints$delegate.h1i_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp0_getValue.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = cachedPoints$delegate.i1i_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = cachedPoints$delegate.h1i_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? tmp0_getValue.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var cachedPoints$delegate;
  function getPoints2(_this__u8e3s4, out) {
    out = out === VOID ? new PointArrayList() : out;
    _init_properties_Shape2D_kt__ty8b9d();
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2_init_$Create$_1();
    var l = Vector2_init_$Create$_1();
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = _this__u8e3s4.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = _this__u8e3s4.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, _this__u8e3s4.y48_1.n(tmp2));
        i = tmp2__anonymous__z9zvc9;
        // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
        out.g40(tmp2__anonymous__z9zvc9);
        l = tmp2__anonymous__z9zvc9;
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = _this__u8e3s4.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, _this__u8e3s4.y48_1.n(tmp4));
          // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
          out.g40(tmp3__anonymous__ufb84q);
          l = tmp3__anonymous__ufb84q;
          var tmp$ret$1;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          tmp$ret$1 = Unit_getInstance();
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, _this__u8e3s4.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, _this__u8e3s4.y48_1.n(tmp8));
            var sum = Companion_getInstance_21().q43(l, tmp4__anonymous__pkmkx7) + Companion_getInstance_21().q43(tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            var tmp0_approximateCurve = numberToInt(sum);
            var tmp$ret$2;
            // Inline function 'kotlin.math.max' call
            tmp$ret$2 = Math.max(tmp0_approximateCurve, 20);
            var rcurveSteps = tmp$ret$2;
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2_init_$Create$_1()};
            var prevPos = {_v: Vector2_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            var tmp1__anonymous__uwfjfc_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, 0.0);
            lastPos._v = tmp1__anonymous__uwfjfc_0;
            var nStart = false ? 0 : 1;
            var nEnd = rcurveSteps;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio = n_1 * dt;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var tmp2__anonymous__z9zvc9_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, ratio);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                out.g40(tmp2__anonymous__z9zvc9_0);
                var tmp0_0 = emittedCount._v;
                emittedCount._v = tmp0_0 + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = tmp2__anonymous__z9zvc9_0;
              }
               while (!(n_1 === nEnd));
            l = tmp5__anonymous__kpxxpo;
            var tmp$ret$3;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            tmp$ret$3 = Unit_getInstance();
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = _this__u8e3s4.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, _this__u8e3s4.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = _this__u8e3s4.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, _this__u8e3s4.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = _this__u8e3s4.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, _this__u8e3s4.y48_1.n(tmp14));
              var sum_0 = Companion_getInstance_21().q43(l, tmp6__anonymous__fv9ai5) + Companion_getInstance_21().q43(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam) + Companion_getInstance_21().q43(tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              var tmp0_approximateCurve_0 = numberToInt(sum_0);
              var tmp$ret$4;
              // Inline function 'kotlin.math.max' call
              tmp$ret$4 = Math.max(tmp0_approximateCurve_0, 20);
              var rcurveSteps_0 = tmp$ret$4;
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              var tmp1__anonymous__uwfjfc_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, 0.0);
              lastPos_0._v = tmp1__anonymous__uwfjfc_1;
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = rcurveSteps_0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_0 = n_2 * dt_0;
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var tmp2__anonymous__z9zvc9_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, ratio_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                  out.g40(tmp2__anonymous__z9zvc9_1);
                  var tmp0_1 = emittedCount_0._v;
                  emittedCount_0._v = tmp0_1 + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = tmp2__anonymous__z9zvc9_1;
                }
                 while (!(n_2 === nEnd_0));
              l = tmp8__anonymous__65w033;
              var tmp$ret$5;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              tmp$ret$5 = Unit_getInstance();
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2.<anonymous>' call
                var tmp9__anonymous__1b7cvk = i;
                out.g40(tmp9__anonymous__1b7cvk);
                var tmp$ret$6;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                tmp$ret$6 = Unit_getInstance();
                var tmp$ret$7;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                tmp$ret$7 = Unit_getInstance();
              }
            }
          }
        }
      }
    }
    var tmp$ret$8;
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    tmp$ret$8 = Unit_getInstance();
    return out;
  }
  function AbstractShape2D() {
  }
  function getPoints2List(_this__u8e3s4) {
    _init_properties_Shape2D_kt__ty8b9d();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var out = tmp$ret$0;
    var current = {_v: new PointArrayList()};
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2_init_$Create$_1();
    var l = Vector2_init_$Create$_1();
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    tmp$ret$1 = Unit_getInstance();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = _this__u8e3s4.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = _this__u8e3s4.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, _this__u8e3s4.y48_1.n(tmp2));
        i = tmp2__anonymous__z9zvc9;
        // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
        {
          getPoints2List$flush(current, out);
        }
        current._v.g40(tmp2__anonymous__z9zvc9);
        l = tmp2__anonymous__z9zvc9;
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = _this__u8e3s4.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, _this__u8e3s4.y48_1.n(tmp4));
          // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
          if (false) {
            getPoints2List$flush(current, out);
          }
          current._v.g40(tmp3__anonymous__ufb84q);
          l = tmp3__anonymous__ufb84q;
          var tmp$ret$2;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          tmp$ret$2 = Unit_getInstance();
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, _this__u8e3s4.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, _this__u8e3s4.y48_1.n(tmp8));
            var sum = Companion_getInstance_21().q43(l, tmp4__anonymous__pkmkx7) + Companion_getInstance_21().q43(tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            var tmp0_approximateCurve = numberToInt(sum);
            var tmp$ret$3;
            // Inline function 'kotlin.math.max' call
            tmp$ret$3 = Math.max(tmp0_approximateCurve, 20);
            var rcurveSteps = tmp$ret$3;
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2_init_$Create$_1()};
            var prevPos = {_v: Vector2_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            var tmp1__anonymous__uwfjfc_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, 0.0);
            lastPos._v = tmp1__anonymous__uwfjfc_0;
            var nStart = false ? 0 : 1;
            var nEnd = rcurveSteps;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio = n_1 * dt;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var tmp2__anonymous__z9zvc9_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, ratio);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                if (false) {
                  getPoints2List$flush(current, out);
                }
                current._v.g40(tmp2__anonymous__z9zvc9_0);
                var tmp0_0 = emittedCount._v;
                emittedCount._v = tmp0_0 + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = tmp2__anonymous__z9zvc9_0;
              }
               while (!(n_1 === nEnd));
            l = tmp5__anonymous__kpxxpo;
            var tmp$ret$4;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            tmp$ret$4 = Unit_getInstance();
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = _this__u8e3s4.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, _this__u8e3s4.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = _this__u8e3s4.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, _this__u8e3s4.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = _this__u8e3s4.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, _this__u8e3s4.y48_1.n(tmp14));
              var sum_0 = Companion_getInstance_21().q43(l, tmp6__anonymous__fv9ai5) + Companion_getInstance_21().q43(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam) + Companion_getInstance_21().q43(tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              var tmp0_approximateCurve_0 = numberToInt(sum_0);
              var tmp$ret$5;
              // Inline function 'kotlin.math.max' call
              tmp$ret$5 = Math.max(tmp0_approximateCurve_0, 20);
              var rcurveSteps_0 = tmp$ret$5;
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              var tmp1__anonymous__uwfjfc_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, 0.0);
              lastPos_0._v = tmp1__anonymous__uwfjfc_1;
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = rcurveSteps_0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_0 = n_2 * dt_0;
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var tmp2__anonymous__z9zvc9_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, ratio_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                  if (false) {
                    getPoints2List$flush(current, out);
                  }
                  current._v.g40(tmp2__anonymous__z9zvc9_1);
                  var tmp0_1 = emittedCount_0._v;
                  emittedCount_0._v = tmp0_1 + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = tmp2__anonymous__z9zvc9_1;
                }
                 while (!(n_2 === nEnd_0));
              l = tmp8__anonymous__65w033;
              var tmp$ret$6;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              tmp$ret$6 = Unit_getInstance();
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.getPoints2List.<anonymous>' call
                var tmp9__anonymous__1b7cvk = i;
                if (false) {
                  getPoints2List$flush(current, out);
                }
                current._v.g40(tmp9__anonymous__1b7cvk);
                var tmp$ret$7;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                tmp$ret$7 = Unit_getInstance();
                var tmp$ret$8;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                tmp$ret$8 = Unit_getInstance();
              }
            }
          }
        }
      }
    }
    var tmp$ret$9;
    // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
    tmp$ret$9 = Unit_getInstance();
    getPoints2List$flush(current, out);
    return out;
  }
  function getPoints2List$flush(current, out) {
    if (!current._v.y1a())
      return Unit_getInstance();
    out.b(current._v);
    current._v = new PointArrayList();
  }
  function cachedPoints$delegate$lambda($this$$receiver) {
    _init_properties_Shape2D_kt__ty8b9d();
    return getPoints2($this$$receiver);
  }
  function cachedPoints$factory() {
    return getPropertyCallableRef('cachedPoints', 1, KProperty1, function (receiver) {
      return get_cachedPoints(receiver);
    }, null);
  }
  var properties_initialized_Shape2D_kt_4gndvn;
  function _init_properties_Shape2D_kt__ty8b9d() {
    if (properties_initialized_Shape2D_kt_4gndvn) {
    } else {
      properties_initialized_Shape2D_kt_4gndvn = true;
      cachedPoints$delegate = new PropertyThis(VOID, cachedPoints$delegate$lambda);
    }
  }
  function RectCoords(tlX, tlY, trX, trY, brX, brY, blX, blY) {
    this.c4b_1 = tlX;
    this.d4b_1 = tlY;
    this.e4b_1 = trX;
    this.f4b_1 = trY;
    this.g4b_1 = brX;
    this.h4b_1 = brY;
    this.i4b_1 = blX;
    this.j4b_1 = blY;
  }
  protoOf(RectCoords).k4b = function () {
    return this.c4b_1;
  };
  protoOf(RectCoords).l4b = function () {
    return this.d4b_1;
  };
  protoOf(RectCoords).m4b = function () {
    return this.e4b_1;
  };
  protoOf(RectCoords).n4b = function () {
    return this.f4b_1;
  };
  protoOf(RectCoords).o4b = function () {
    return this.g4b_1;
  };
  protoOf(RectCoords).p4b = function () {
    return this.h4b_1;
  };
  protoOf(RectCoords).q4b = function () {
    return this.i4b_1;
  };
  protoOf(RectCoords).r4b = function () {
    return this.j4b_1;
  };
  protoOf(RectCoords).toString = function () {
    return 'RectCoords(tlX=' + this.c4b_1 + ', tlY=' + this.d4b_1 + ', trX=' + this.e4b_1 + ', trY=' + this.f4b_1 + ', brX=' + this.g4b_1 + ', brY=' + this.h4b_1 + ', blX=' + this.i4b_1 + ', blY=' + this.j4b_1 + ')';
  };
  protoOf(RectCoords).hashCode = function () {
    var result = getNumberHashCode(this.c4b_1);
    result = imul(result, 31) + getNumberHashCode(this.d4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.e4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.f4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.g4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.h4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.i4b_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.j4b_1) | 0;
    return result;
  };
  protoOf(RectCoords).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectCoords))
      return false;
    var tmp0_other_with_cast = other instanceof RectCoords ? other : THROW_CCE();
    if (!equals(this.c4b_1, tmp0_other_with_cast.c4b_1))
      return false;
    if (!equals(this.d4b_1, tmp0_other_with_cast.d4b_1))
      return false;
    if (!equals(this.e4b_1, tmp0_other_with_cast.e4b_1))
      return false;
    if (!equals(this.f4b_1, tmp0_other_with_cast.f4b_1))
      return false;
    if (!equals(this.g4b_1, tmp0_other_with_cast.g4b_1))
      return false;
    if (!equals(this.h4b_1, tmp0_other_with_cast.h4b_1))
      return false;
    if (!equals(this.i4b_1, tmp0_other_with_cast.i4b_1))
      return false;
    if (!equals(this.j4b_1, tmp0_other_with_cast.j4b_1))
      return false;
    return true;
  };
  function RectSlice(base, rect, orientation, padding, name) {
    orientation = orientation === VOID ? Companion_getInstance_30().w4b_1 : orientation;
    padding = padding === VOID ? Companion_getInstance_9().p3x_1 : padding;
    name = name === VOID ? null : name;
    this.f4c_1 = base;
    this.g4c_1 = rect;
    this.h4c_1 = orientation;
    this.i4c_1 = padding;
    this.j4c_1 = name;
    this.k4c_1 = _SizeInt___get_width__impl__swntg6(this.f4c_1.g4d());
    this.l4c_1 = _SizeInt___get_height__impl__d3sy61(this.f4c_1.g4d());
    this.m4c_1 = this.g4c_1.g42_1;
    this.n4c_1 = this.g4c_1.h42_1;
    this.o4c_1 = !_SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq(this.h4c_1) ? this.g4c_1.g42_1 : this.g4c_1.h42_1;
    this.p4c_1 = !_SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq(this.h4c_1) ? this.g4c_1.h42_1 : this.g4c_1.g42_1;
    this.q4c_1 = this.o4c_1 + this.i4c_1.n3x() | 0;
    this.r4c_1 = this.p4c_1 + this.i4c_1.o3x() | 0;
    this.s4c_1 = this.g4c_1.f3s() / this.k4c_1;
    this.t4c_1 = this.g4c_1.g3s() / this.k4c_1;
    this.u4c_1 = this.g4c_1.h3s() / this.l4c_1;
    this.v4c_1 = this.g4c_1.i3s() / this.l4c_1;
    this.w4c_1 = new RectCoords(this.s4c_1, this.u4c_1, this.t4c_1, this.u4c_1, this.t4c_1, this.v4c_1, this.s4c_1, this.v4c_1);
    this.x4c_1 = transformed_1(this.w4c_1, this.h4c_1);
    this.y4c_1 = this.x4c_1.c4b_1;
    this.z4c_1 = this.x4c_1.d4b_1;
    this.a4d_1 = this.x4c_1.e4b_1;
    this.b4d_1 = this.x4c_1.f4b_1;
    this.c4d_1 = this.x4c_1.g4b_1;
    this.d4d_1 = this.x4c_1.h4b_1;
    this.e4d_1 = this.x4c_1.i4b_1;
    this.f4d_1 = this.x4c_1.j4b_1;
  }
  protoOf(RectSlice).h4d = function () {
    return this.f4c_1;
  };
  protoOf(RectSlice).i4d = function () {
    return this.g4c_1;
  };
  protoOf(RectSlice).j4d = function () {
    return this.i4c_1;
  };
  protoOf(RectSlice).x18 = function () {
    return this.o4c_1;
  };
  protoOf(RectSlice).y18 = function () {
    return this.p4c_1;
  };
  protoOf(RectSlice).k4d = function () {
    return this.q4c_1;
  };
  protoOf(RectSlice).l4d = function () {
    return this.r4c_1;
  };
  protoOf(RectSlice).m4d = function (left, top, right, bottom, name, clamped, orientation) {
    var tmp = this.g4c_1.m42(left, top, right, bottom, clamped);
    var tmp0_elvis_lhs = name;
    return new RectSlice(this.f4c_1, tmp, orientation, this.i4c_1, tmp0_elvis_lhs == null ? this.j4c_1 : tmp0_elvis_lhs);
  };
  protoOf(RectSlice).n4d = function (left, top, right, bottom, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.h4c_1 : orientation;
    return $super === VOID ? this.m4d(left, top, right, bottom, name, clamped, orientation) : $super.m4d.call(this, left, top, right, bottom, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).o4d = function (x, y, width, height, name, clamped, orientation) {
    return this.m4d(x, y, x + width | 0, y + height | 0, name, clamped, orientation);
  };
  protoOf(RectSlice).p4d = function (x, y, width, height, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.h4c_1 : orientation;
    return $super === VOID ? this.o4d(x, y, width, height, name, clamped, orientation) : $super.o4d.call(this, x, y, width, height, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).q4d = function (rect, name, clamped, orientation) {
    return this.m4d(rect.f3s(), rect.h3s(), rect.g3s(), rect.i3s(), name, clamped, orientation);
  };
  protoOf(RectSlice).r4d = function (rect, name, clamped, orientation, $super) {
    name = name === VOID ? null : name;
    clamped = clamped === VOID ? true : clamped;
    orientation = orientation === VOID ? this.h4c_1 : orientation;
    return $super === VOID ? this.q4d(rect, name, clamped, orientation) : $super.q4d.call(this, rect, name, clamped, new SliceOrientation(orientation));
  };
  protoOf(RectSlice).k4b = function () {
    return this.y4c_1;
  };
  protoOf(RectSlice).l4b = function () {
    return this.z4c_1;
  };
  protoOf(RectSlice).m4b = function () {
    return this.a4d_1;
  };
  protoOf(RectSlice).n4b = function () {
    return this.b4d_1;
  };
  protoOf(RectSlice).o4b = function () {
    return this.c4d_1;
  };
  protoOf(RectSlice).p4b = function () {
    return this.d4d_1;
  };
  protoOf(RectSlice).q4b = function () {
    return this.e4d_1;
  };
  protoOf(RectSlice).r4b = function () {
    return this.f4d_1;
  };
  protoOf(RectSlice).toString = function () {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.slice.RectSlice.toString.<anonymous>' call
    tmp0_apply.bc('RectSlice(');
    tmp0_apply.bc(this.j4c_1);
    tmp0_apply.bc(':');
    tmp0_apply.ac(this.g4c_1);
    if (!(this.h4c_1 === Companion_getInstance_30().w4b_1)) {
      tmp0_apply.bc(':');
      tmp0_apply.ac(new SliceOrientation(this.h4c_1));
    }
    if (this.i4c_1.u3x()) {
      tmp0_apply.bc(':');
      tmp0_apply.ac(this.i4c_1);
    }
    tmp0_apply.bc(')');
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  protoOf(RectSlice).hashCode = function () {
    var result = hashCode(this.f4c_1);
    result = imul(result, 31) + this.g4c_1.hashCode() | 0;
    result = imul(result, 31) + SliceOrientation__hashCode_impl_7mjkdw(this.h4c_1) | 0;
    result = imul(result, 31) + this.i4c_1.hashCode() | 0;
    result = imul(result, 31) + (this.j4c_1 == null ? 0 : getStringHashCode(this.j4c_1)) | 0;
    return result;
  };
  protoOf(RectSlice).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectSlice))
      return false;
    var tmp0_other_with_cast = other instanceof RectSlice ? other : THROW_CCE();
    if (!equals(this.f4c_1, tmp0_other_with_cast.f4c_1))
      return false;
    if (!this.g4c_1.equals(tmp0_other_with_cast.g4c_1))
      return false;
    if (!(this.h4c_1 === tmp0_other_with_cast.h4c_1))
      return false;
    if (!this.i4c_1.equals(tmp0_other_with_cast.i4c_1))
      return false;
    if (!(this.j4c_1 == tmp0_other_with_cast.j4c_1))
      return false;
    return true;
  };
  function splitInRows(_this__u8e3s4, width, height) {
    return split(_this__u8e3s4, width, height, true);
  }
  function split(_this__u8e3s4, width, height, inRows) {
    var nheight = _this__u8e3s4.p4c_1 / height | 0;
    var nwidth = _this__u8e3s4.o4c_1 / width | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var tmp0_also = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.slice.split.<anonymous>' call
    if (inRows) {
      var inductionVariable = 0;
      if (inductionVariable < nheight)
        do {
          var y = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var inductionVariable_0 = 0;
          if (inductionVariable_0 < nwidth)
            do {
              var x = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              tmp0_also.b(_this__u8e3s4.p4d(imul(x, width), imul(y, height), width, height));
            }
             while (inductionVariable_0 < nwidth);
        }
         while (inductionVariable < nheight);
    } else {
      var inductionVariable_1 = 0;
      if (inductionVariable_1 < nwidth)
        do {
          var x_0 = inductionVariable_1;
          inductionVariable_1 = inductionVariable_1 + 1 | 0;
          var inductionVariable_2 = 0;
          if (inductionVariable_2 < nheight)
            do {
              var y_0 = inductionVariable_2;
              inductionVariable_2 = inductionVariable_2 + 1 | 0;
              tmp0_also.b(_this__u8e3s4.p4d(imul(x_0, width), imul(y_0, height), width, height));
            }
             while (inductionVariable_2 < nheight);
        }
         while (inductionVariable_1 < nwidth);
    }
    tmp$ret$1 = tmp0_also;
    return tmp$ret$1;
  }
  function Companion_28() {
    Companion_instance_28 = this;
  }
  protoOf(Companion_28).u4d = function (base, coords, name, flippedWidthHeight) {
    return new SliceCoordsImpl(base, coords, name, flippedWidthHeight);
  };
  protoOf(Companion_28).v4d = function (base, coords, name, flippedWidthHeight, $super) {
    name = name === VOID ? null : name;
    flippedWidthHeight = flippedWidthHeight === VOID ? false : flippedWidthHeight;
    return $super === VOID ? this.u4d(base, coords, name, flippedWidthHeight) : $super.u4d.call(this, base, coords, name, flippedWidthHeight);
  };
  var Companion_instance_28;
  function Companion_getInstance_28() {
    if (Companion_instance_28 == null)
      new Companion_28();
    return Companion_instance_28;
  }
  function SliceCoordsWithBase() {
  }
  function SliceCoords() {
  }
  function SliceCoordsImpl(base, coords, name, flippedWidthHeight) {
    name = name === VOID ? null : name;
    flippedWidthHeight = flippedWidthHeight === VOID ? false : flippedWidthHeight;
    this.w4d_1 = base;
    this.x4d_1 = coords;
    this.y4d_1 = name;
    this.z4d_1 = flippedWidthHeight;
    this.a4e_1 = Companion_getInstance_9().p3x_1;
    this.b4e_1 = !this.z4d_1 ? _SizeInt___get_width__impl__swntg6(this.w4d_1.g4d()) : _SizeInt___get_height__impl__d3sy61(this.w4d_1.g4d());
    this.c4e_1 = !this.z4d_1 ? _SizeInt___get_height__impl__d3sy61(this.w4d_1.g4d()) : _SizeInt___get_width__impl__swntg6(this.w4d_1.g4d());
    this.d4e_1 = numberToInt(Companion_getInstance_21().p41(this.x4d_1.k4b(), this.x4d_1.l4b(), this.x4d_1.m4b(), this.x4d_1.n4b()) * this.b4e_1);
    this.e4e_1 = numberToInt(Companion_getInstance_21().p41(this.x4d_1.k4b(), this.x4d_1.l4b(), this.x4d_1.q4b(), this.x4d_1.r4b()) * this.c4e_1);
    this.f4e_1 = this.d4e_1 + this.a4e_1.n3x() | 0;
    this.g4e_1 = this.e4e_1 + this.a4e_1.o3x() | 0;
  }
  protoOf(SliceCoordsImpl).h4d = function () {
    return this.w4d_1;
  };
  protoOf(SliceCoordsImpl).j4d = function () {
    return this.a4e_1;
  };
  protoOf(SliceCoordsImpl).x18 = function () {
    return this.d4e_1;
  };
  protoOf(SliceCoordsImpl).y18 = function () {
    return this.e4e_1;
  };
  protoOf(SliceCoordsImpl).k4d = function () {
    return this.f4e_1;
  };
  protoOf(SliceCoordsImpl).l4d = function () {
    return this.g4e_1;
  };
  protoOf(SliceCoordsImpl).k4b = function () {
    return this.x4d_1.k4b();
  };
  protoOf(SliceCoordsImpl).l4b = function () {
    return this.x4d_1.l4b();
  };
  protoOf(SliceCoordsImpl).m4b = function () {
    return this.x4d_1.m4b();
  };
  protoOf(SliceCoordsImpl).n4b = function () {
    return this.x4d_1.n4b();
  };
  protoOf(SliceCoordsImpl).o4b = function () {
    return this.x4d_1.o4b();
  };
  protoOf(SliceCoordsImpl).p4b = function () {
    return this.x4d_1.p4b();
  };
  protoOf(SliceCoordsImpl).q4b = function () {
    return this.x4d_1.q4b();
  };
  protoOf(SliceCoordsImpl).r4b = function () {
    return this.x4d_1.r4b();
  };
  protoOf(SliceCoordsImpl).toString = function () {
    return 'SliceCoordsImpl(base=' + this.w4d_1 + ', coords=' + this.x4d_1 + ', name=' + this.y4d_1 + ', flippedWidthHeight=' + this.z4d_1 + ')';
  };
  protoOf(SliceCoordsImpl).hashCode = function () {
    var result = hashCode(this.w4d_1);
    result = imul(result, 31) + hashCode(this.x4d_1) | 0;
    result = imul(result, 31) + (this.y4d_1 == null ? 0 : getStringHashCode(this.y4d_1)) | 0;
    result = imul(result, 31) + (this.z4d_1 | 0) | 0;
    return result;
  };
  protoOf(SliceCoordsImpl).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SliceCoordsImpl))
      return false;
    var tmp0_other_with_cast = other instanceof SliceCoordsImpl ? other : THROW_CCE();
    if (!equals(this.w4d_1, tmp0_other_with_cast.w4d_1))
      return false;
    if (!equals(this.x4d_1, tmp0_other_with_cast.x4d_1))
      return false;
    if (!(this.y4d_1 == tmp0_other_with_cast.y4d_1))
      return false;
    if (!(this.z4d_1 === tmp0_other_with_cast.z4d_1))
      return false;
    return true;
  };
  function SliceCoordsWithBaseAndRect() {
  }
  function transformed_1(_this__u8e3s4, orientation) {
    var i = _SliceOrientation___get_indices__impl__km5on3(orientation);
    return new RectCoords(_this__u8e3s4.s4b(i[0]), _this__u8e3s4.t4b(i[0]), _this__u8e3s4.s4b(i[1]), _this__u8e3s4.t4b(i[1]), _this__u8e3s4.s4b(i[2]), _this__u8e3s4.t4b(i[2]), _this__u8e3s4.s4b(i[3]), _this__u8e3s4.t4b(i[3]));
  }
  var SliceRotation_R0_instance;
  var SliceRotation_R90_instance;
  var SliceRotation_R180_instance;
  var SliceRotation_R270_instance;
  function Companion_29() {
    Companion_instance_29 = this;
    this.h4e_1 = values();
  }
  protoOf(Companion_29).n = function (index) {
    return this.h4e_1[umod(index, 4)];
  };
  var Companion_instance_29;
  function Companion_getInstance_29() {
    SliceRotation_initEntries();
    if (Companion_instance_29 == null)
      new Companion_29();
    return Companion_instance_29;
  }
  function values() {
    return [SliceRotation_R0_getInstance(), SliceRotation_R90_getInstance(), SliceRotation_R180_getInstance(), SliceRotation_R270_getInstance()];
  }
  var SliceRotation_entriesInitialized;
  function SliceRotation_initEntries() {
    if (SliceRotation_entriesInitialized)
      return Unit_getInstance();
    SliceRotation_entriesInitialized = true;
    SliceRotation_R0_instance = new SliceRotation('R0', 0);
    SliceRotation_R90_instance = new SliceRotation('R90', 1);
    SliceRotation_R180_instance = new SliceRotation('R180', 2);
    SliceRotation_R270_instance = new SliceRotation('R270', 3);
    Companion_getInstance_29();
  }
  function SliceRotation(name, ordinal) {
    Enum.call(this, name, ordinal);
    this.k4e_1 = imul(this.b2_1, 90);
  }
  function _SliceOrientation___init__impl__ewylib(raw) {
    return raw;
  }
  function _SliceOrientation___get_raw__impl__tj3u8w($this) {
    return $this;
  }
  function _SliceOrientation___get_rotation__impl__m65r0o($this) {
    var tmp = Companion_getInstance_29();
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _SliceOrientation___get_raw__impl__tj3u8w($this);
    tmp$ret$0 = (tmp0_extract2 >>> 0 | 0) & 3;
    return tmp.n(tmp$ret$0);
  }
  function _SliceOrientation___get_flipX__impl__lgb7bx($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _SliceOrientation___get_raw__impl__tj3u8w($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 2 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _SliceOrientation___init__impl__ewylib_0(rotation, flipX) {
    rotation = rotation === VOID ? SliceRotation_R0_getInstance() : rotation;
    flipX = flipX === VOID ? false : flipX;
    var tmp = _SliceOrientation___init__impl__ewylib(insert(insert2(0, rotation.b2_1, 0), flipX, 2));
    return tmp;
  }
  function _SliceOrientation___get_indices__impl__km5on3($this) {
    var tmp = Companion_getInstance_30().u4b_1;
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _SliceOrientation___get_raw__impl__tj3u8w($this);
    tmp$ret$0 = (tmp0_extract3 >>> 0 | 0) & 7;
    return tmp[tmp$ret$0];
  }
  function _SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq($this) {
    return _SliceOrientation___get_rotation__impl__m65r0o($this).equals(SliceRotation_R90_getInstance()) ? true : _SliceOrientation___get_rotation__impl__m65r0o($this).equals(SliceRotation_R270_getInstance());
  }
  function SliceOrientation__toString_impl_tdph6r($this) {
    var tmp = Companion_getInstance_30().v4b_1;
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _SliceOrientation___get_raw__impl__tj3u8w($this);
    tmp$ret$0 = (tmp0_extract3 >>> 0 | 0) & 7;
    return tmp[tmp$ret$0];
  }
  function Indices() {
    Indices_instance = this;
    this.l4e_1 = 0;
    this.m4e_1 = 1;
    this.n4e_1 = 2;
    this.o4e_1 = 3;
  }
  var Indices_instance;
  function Indices_getInstance() {
    if (Indices_instance == null)
      new Indices();
    return Indices_instance;
  }
  function Companion_30() {
    Companion_instance_30 = this;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = 8;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$3;
      // Inline function 'korlibs.math.geom.slice.Companion.INDICES.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'kotlin.also' call
      var tmp$ret$1;
      // Inline function 'kotlin.intArrayOf' call
      tmp$ret$1 = new Int32Array([0, 1, 2, 3]);
      var tmp0_also = tmp$ret$1;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.slice.Companion.INDICES.<anonymous>' call
      var orientation = _SliceOrientation___init__impl__ewylib(tmp_3);
      if (_SliceOrientation___get_flipX__impl__lgb7bx(orientation)) {
        Indices_getInstance();
        Indices_getInstance();
        swap(tmp0_also, 0, 1);
        Indices_getInstance();
        Indices_getInstance();
        swap(tmp0_also, 3, 2);
      }
      rotateRight(tmp0_also, _SliceOrientation___get_rotation__impl__m65r0o(orientation).b2_1);
      tmp$ret$2 = tmp0_also;
      tmp$ret$3 = tmp$ret$2;
      tmp_2[tmp_3] = tmp$ret$3;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.u4b_1 = tmp_2;
    var tmp_4 = this;
    var tmp_5 = 0;
    var tmp_6 = 8;
    var tmp$ret$4;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$4 = fillArrayVal(Array(tmp_6), null);
    var tmp_7 = tmp$ret$4;
    while (tmp_5 < tmp_6) {
      var tmp_8 = tmp_5;
      var tmp$ret$7;
      // Inline function 'korlibs.math.geom.slice.Companion.NAMES.<anonymous>' call
      var orientation_0 = _SliceOrientation___init__impl__ewylib(tmp_8);
      var tmp$ret$6;
      // Inline function 'kotlin.text.buildString' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$5;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = StringBuilder_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.slice.Companion.NAMES.<anonymous>' call
      tmp0_apply.bc(_SliceOrientation___get_flipX__impl__lgb7bx(orientation_0) ? 'MIRROR_HORIZONTAL_ROTATE_' : 'ROTATE_');
      tmp0_apply.ac(_SliceOrientation___get_rotation__impl__m65r0o(orientation_0).k4e_1);
      tmp$ret$5 = tmp0_apply;
      tmp$ret$6 = tmp$ret$5.toString();
      tmp$ret$7 = tmp$ret$6;
      tmp_7[tmp_8] = tmp$ret$7;
      tmp_5 = tmp_5 + 1 | 0;
    }
    tmp_4.v4b_1 = tmp_7;
    var tmp_9 = this;
    var tmp0_rotation = SliceRotation_R0_getInstance();
    tmp_9.w4b_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation, false);
    var tmp_10 = this;
    var tmp0_rotation_0 = SliceRotation_R90_getInstance();
    tmp_10.x4b_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_0, false);
    var tmp_11 = this;
    var tmp0_rotation_1 = SliceRotation_R180_getInstance();
    tmp_11.y4b_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_1, false);
    var tmp_12 = this;
    var tmp0_rotation_2 = SliceRotation_R270_getInstance();
    tmp_12.z4b_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_2, false);
    this.a4c_1 = _SliceOrientation___init__impl__ewylib_0(VOID, true);
    var tmp_13 = this;
    var tmp0_rotation_3 = SliceRotation_R90_getInstance();
    tmp_13.b4c_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_3, true);
    var tmp_14 = this;
    var tmp0_rotation_4 = SliceRotation_R180_getInstance();
    tmp_14.c4c_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_4, true);
    var tmp_15 = this;
    var tmp0_rotation_5 = SliceRotation_R270_getInstance();
    tmp_15.d4c_1 = _SliceOrientation___init__impl__ewylib_0(tmp0_rotation_5, true);
    this.e4c_1 = listOf_0([new SliceOrientation(this.w4b_1), new SliceOrientation(this.x4b_1), new SliceOrientation(this.y4b_1), new SliceOrientation(this.z4b_1), new SliceOrientation(this.a4c_1), new SliceOrientation(this.b4c_1), new SliceOrientation(this.c4c_1), new SliceOrientation(this.d4c_1)]);
  }
  var Companion_instance_30;
  function Companion_getInstance_30() {
    if (Companion_instance_30 == null)
      new Companion_30();
    return Companion_instance_30;
  }
  function SliceOrientation__hashCode_impl_7mjkdw($this) {
    return $this;
  }
  function SliceOrientation__equals_impl_h4gywg($this, other) {
    if (!(other instanceof SliceOrientation))
      return false;
    var tmp0_other_with_cast = other instanceof SliceOrientation ? other.p4e_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function SliceOrientation(raw) {
    Companion_getInstance_30();
    this.p4e_1 = raw;
  }
  protoOf(SliceOrientation).toString = function () {
    return SliceOrientation__toString_impl_tdph6r(this.p4e_1);
  };
  protoOf(SliceOrientation).hashCode = function () {
    return SliceOrientation__hashCode_impl_7mjkdw(this.p4e_1);
  };
  protoOf(SliceOrientation).equals = function (other) {
    return SliceOrientation__equals_impl_h4gywg(this.p4e_1, other);
  };
  function SliceRotation_R0_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R0_instance;
  }
  function SliceRotation_R90_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R90_instance;
  }
  function SliceRotation_R180_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R180_instance;
  }
  function SliceRotation_R270_getInstance() {
    SliceRotation_initEntries();
    return SliceRotation_R270_instance;
  }
  function _Item___init__impl__hhk1le(index) {
    return index;
  }
  function _Item___get_index__impl__lpanhd($this) {
    return $this;
  }
  function Item__toString_impl_d711m6($this) {
    return 'Item(index=' + $this + ')';
  }
  function Item__hashCode_impl_8k4v6p($this) {
    return $this;
  }
  function Item__equals_impl_qczid($this, other) {
    if (!(other instanceof Item))
      return false;
    var tmp0_other_with_cast = other instanceof Item ? other.q4e_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function Companion_31() {
    Companion_instance_31 = this;
  }
  var Companion_instance_31;
  function Companion_getInstance_31() {
    if (Companion_instance_31 == null)
      new Companion_31();
    return Companion_instance_31;
  }
  function SortOps_0(gen) {
    SortOps.call(this);
    this.r4e_1 = gen;
  }
  protoOf(SortOps_0).s4e = function (subject, l, r) {
    var tmp$ret$0;
    // Inline function 'kotlin.compareTo' call
    var tmp0_compareTo = this.r4e_1(subject, new Item(_Item___init__impl__hhk1le(l)));
    var tmp1_compareTo = this.r4e_1(subject, new Item(_Item___init__impl__hhk1le(r)));
    tmp$ret$0 = compareTo(tmp0_compareTo, tmp1_compareTo);
    return tmp$ret$0;
  };
  protoOf(SortOps_0).f1b = function (subject, l, r) {
    return this.s4e(subject instanceof FSegmentsInt ? subject : THROW_CCE(), l, r);
  };
  protoOf(SortOps_0).t4e = function (subject, indexL, indexR) {
    return subject.v4e(_Item___init__impl__hhk1le(indexL), _Item___init__impl__hhk1le(indexR));
  };
  protoOf(SortOps_0).h1b = function (subject, indexL, indexR) {
    return this.t4e(subject instanceof FSegmentsInt ? subject : THROW_CCE(), indexL, indexR);
  };
  function Item(index) {
    this.q4e_1 = index;
  }
  protoOf(Item).toString = function () {
    return Item__toString_impl_d711m6(this.q4e_1);
  };
  protoOf(Item).hashCode = function () {
    return Item__hashCode_impl_8k4v6p(this.q4e_1);
  };
  protoOf(Item).equals = function (other) {
    return Item__equals_impl_qczid(this.q4e_1, other);
  };
  function FSegmentsInt(capacity) {
    Companion_getInstance_31();
    capacity = capacity === VOID ? 5 : capacity;
    this.u4e_1 = new IntArrayList(imul(capacity, 4));
  }
  protoOf(FSegmentsInt).l = function () {
    return this.u4e_1.l() / 4 | 0;
  };
  protoOf(FSegmentsInt).z19 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new FSegmentsInt(this.l());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.clone.<anonymous>' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.clone.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.w4e(n);
        tmp0_also.b4f(this.x4e(tmp0__anonymous__q1qw7t), this.y4e(tmp0__anonymous__q1qw7t), this.z4e(tmp0__anonymous__q1qw7t), this.a4f(tmp0__anonymous__q1qw7t));
      }
       while (inductionVariable < last);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(FSegmentsInt).c4f = function (gen) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.z19();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.sortedBy.<anonymous>' call
    tmp0_also.d4f(gen);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(FSegmentsInt).d4f = function (gen) {
    genericSort(this, 0, this.l() - 1 | 0, new SortOps_0(gen));
  };
  protoOf(FSegmentsInt).v4e = function (a, b) {
    var ax0 = this.x4e(a);
    var ay0 = this.y4e(a);
    var ax1 = this.z4e(a);
    var ay1 = this.a4f(a);
    this.e4f(a, this.x4e(b), this.y4e(b), this.z4e(b), this.a4f(b));
    this.e4f(b, ax0, ay0, ax1, ay1);
  };
  protoOf(FSegmentsInt).w4e = function (index) {
    return _Item___init__impl__hhk1le(index);
  };
  protoOf(FSegmentsInt).f4f = function (index) {
    return (0 <= index ? index < this.l() : false) ? this.w4e(index) : null;
  };
  protoOf(FSegmentsInt).g4f = function (_this__u8e3s4, value) {
    this.u4e_1.w1a(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 0 | 0, value);
  };
  protoOf(FSegmentsInt).x4e = function (_this__u8e3s4) {
    return this.u4e_1.n(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 0 | 0);
  };
  protoOf(FSegmentsInt).h4f = function (_this__u8e3s4, value) {
    this.u4e_1.w1a(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 1 | 0, value);
  };
  protoOf(FSegmentsInt).y4e = function (_this__u8e3s4) {
    return this.u4e_1.n(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 1 | 0);
  };
  protoOf(FSegmentsInt).i4f = function (_this__u8e3s4, value) {
    this.u4e_1.w1a(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 2 | 0, value);
  };
  protoOf(FSegmentsInt).z4e = function (_this__u8e3s4) {
    return this.u4e_1.n(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 2 | 0);
  };
  protoOf(FSegmentsInt).j4f = function (_this__u8e3s4, value) {
    this.u4e_1.w1a(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 3 | 0, value);
  };
  protoOf(FSegmentsInt).a4f = function (_this__u8e3s4) {
    return this.u4e_1.n(imul(_Item___get_index__impl__lpanhd(_this__u8e3s4), 4) + 3 | 0);
  };
  protoOf(FSegmentsInt).e4f = function (_this__u8e3s4, x0, y0, x1, y1) {
    this.g4f(_this__u8e3s4, x0);
    this.h4f(_this__u8e3s4, y0);
    this.i4f(_this__u8e3s4, x1);
    this.j4f(_this__u8e3s4, y1);
  };
  protoOf(FSegmentsInt).k4f = function (_this__u8e3s4) {
    return this.z4e(_this__u8e3s4) - this.x4e(_this__u8e3s4) | 0;
  };
  protoOf(FSegmentsInt).l4f = function (_this__u8e3s4) {
    return this.a4f(_this__u8e3s4) - this.y4e(_this__u8e3s4) | 0;
  };
  protoOf(FSegmentsInt).m4f = function (_this__u8e3s4) {
    return this.k4f(_this__u8e3s4) / this.l4f(_this__u8e3s4);
  };
  protoOf(FSegmentsInt).n4f = function (_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.comparisons.minOf' call
    var tmp0_minOf = this.x4e(_this__u8e3s4);
    var tmp1_minOf = this.z4e(_this__u8e3s4);
    tmp$ret$0 = Math.min(tmp0_minOf, tmp1_minOf);
    return tmp$ret$0;
  };
  protoOf(FSegmentsInt).o4f = function (_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.comparisons.minOf' call
    var tmp0_minOf = this.y4e(_this__u8e3s4);
    var tmp1_minOf = this.a4f(_this__u8e3s4);
    tmp$ret$0 = Math.min(tmp0_minOf, tmp1_minOf);
    return tmp$ret$0;
  };
  protoOf(FSegmentsInt).p4f = function (_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.comparisons.maxOf' call
    var tmp0_maxOf = this.y4e(_this__u8e3s4);
    var tmp1_maxOf = this.a4f(_this__u8e3s4);
    tmp$ret$0 = Math.max(tmp0_maxOf, tmp1_maxOf);
    return tmp$ret$0;
  };
  protoOf(FSegmentsInt).q4f = function (_this__u8e3s4, y) {
    return this.x4e(_this__u8e3s4) + toIntRound((y - this.y4e(_this__u8e3s4) | 0) * this.m4f(_this__u8e3s4)) | 0;
  };
  protoOf(FSegmentsInt).r4f = function (_this__u8e3s4, y) {
    var containsLower = this.o4f(_this__u8e3s4);
    return y <= this.p4f(_this__u8e3s4) ? containsLower <= y : false;
  };
  protoOf(FSegmentsInt).s4f = function (_this__u8e3s4, other) {
    return Companion_getInstance_33().t4f(this.x4e(_this__u8e3s4), this.y4e(_this__u8e3s4), this.z4e(_this__u8e3s4), this.a4f(_this__u8e3s4), this.x4e(other), this.y4e(other), this.z4e(other), this.a4f(other));
  };
  protoOf(FSegmentsInt).b4f = function (x0, y0, x1, y1) {
    var index = this.l();
    this.u4e_1.r1a(x0, y0, x1, y1);
    return _Item___init__impl__hhk1le(index);
  };
  protoOf(FSegmentsInt).u4f = function (v, segments) {
    var tmp$ret$1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.add.<anonymous>' call
    tmp$ret$0 = this.b4f(segments.x4e(v), segments.y4e(v), segments.z4e(v), segments.a4f(v));
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  function _Item___init__impl__hhk1le_0(index) {
    return index;
  }
  function _Item___get_index__impl__lpanhd_0($this) {
    return $this;
  }
  function Companion_32() {
    Companion_instance_32 = this;
  }
  var Companion_instance_32;
  function Companion_getInstance_32() {
    if (Companion_instance_32 == null)
      new Companion_32();
    return Companion_instance_32;
  }
  function FTrapezoidsInt(capacity) {
    Companion_getInstance_32();
    capacity = capacity === VOID ? 5 : capacity;
    this.v4f_1 = false;
    this.w4f_1 = new IntArrayList(imul(capacity, 7));
  }
  protoOf(FTrapezoidsInt).l = function () {
    return this.w4f_1.l() / 6 | 0;
  };
  protoOf(FTrapezoidsInt).w4e = function (index) {
    return _Item___init__impl__hhk1le_0(index);
  };
  protoOf(FTrapezoidsInt).toString = function () {
    return 'FTrapezoidsInt[' + this.l() + ']';
  };
  protoOf(FTrapezoidsInt).x4f = function (x, y, assumeSorted) {
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.trapezoid.FTrapezoidsInt.pointInside' call
    var iterations = 0;
    var iterations2 = 0;
    var found = false;
    if (assumeSorted) {
      var size = this.l();
      var tmp$ret$3;
      // Inline function 'korlibs.datastructure.genericBinarySearchResult' call
      var tmp2_genericBinarySearchResult = size - 1 | 0;
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.genericBinarySearch' call
        var low = 0;
        var high = tmp2_genericBinarySearchResult - 1 | 0;
        while (low <= high) {
          var mid = (low + high | 0) / 2 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.math.geom.trapezoid.FTrapezoidsInt.pointInside.<anonymous>' call
          var tmp0 = iterations2;
          iterations2 = tmp0 + 1 | 0;
          tmp$ret$0 = compareTo(this.y4e(_Item___init__impl__hhk1le_0(mid)), y);
          var mval = tmp$ret$0;
          if (mval < 0)
            low = mid + 1 | 0;
          else if (mval > 0)
            high = mid - 1 | 0;
          else {
            tmp$ret$1 = mid;
            break $l$block;
          }
        }
        var tmp$ret$2;
        // Inline function 'korlibs.datastructure.genericBinarySearchResult.<anonymous>' call
        var tmp0__anonymous__q1qw7t = low;
        var tmp1__anonymous__uwfjfc = high;
        tmp$ret$2 = (-tmp0__anonymous__q1qw7t | 0) - 1 | 0;
        tmp$ret$1 = tmp$ret$2;
      }
      tmp$ret$3 = _BSearchResult___init__impl__em6zam(tmp$ret$1);
      var result = tmp$ret$3;
      var index = _BSearchResult___get_nearIndex__impl__s8nq43(result);
      $l$loop: while (0 <= index ? index < size : false) {
        var item = _Item___init__impl__hhk1le_0(index);
        if (y > this.a4f(item))
          break $l$loop;
        var tmp0_0 = iterations;
        iterations = tmp0_0 + 1 | 0;
        var tmp1 = index;
        index = tmp1 - 1 | 0;
      }
      var tmp2 = index;
      index = tmp2 + 1 | 0;
      var inductionVariable = index;
      if (inductionVariable < size)
        $l$loop_1: do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp4 = iterations;
          iterations = tmp4 + 1 | 0;
          var item_0 = _Item___init__impl__hhk1le_0(n);
          var inside = this.y4f(item_0, x, y);
          if (inside) {
            found = true;
            break $l$loop_1;
          }
          if (this.y4e(item_0) > y)
            break $l$loop_1;
        }
         while (inductionVariable < size);
    } else {
      var inductionVariable_0 = 0;
      var last = this.l();
      if (inductionVariable_0 < last)
        $l$loop_2: do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          var tmp6 = iterations;
          iterations = tmp6 + 1 | 0;
          var it = this.w4e(n_0);
          if (this.y4f(it, x, y)) {
            found = true;
            var tmp$ret$4;
            // Inline function 'korlibs.math.geom.trapezoid.FTrapezoidsInt.pointInside.<anonymous>' call
            tmp$ret$4 = Unit_getInstance();
            break $l$loop_2;
          }
        }
         while (inductionVariable_0 < last);
    }
    if (!(null == null)) {
      null.a4g_1 = iterations;
      null.b4g_1 = iterations2;
      null.c4g_1 = this.l();
      null.z4f_1 = found;
    }
    tmp$ret$5 = found;
    return tmp$ret$5;
  };
  protoOf(FTrapezoidsInt).d4g = function (x, y, assumeSorted, $super) {
    assumeSorted = assumeSorted === VOID ? this.v4f_1 : assumeSorted;
    return $super === VOID ? this.x4f(x, y, assumeSorted) : $super.x4f.call(this, x, y, assumeSorted);
  };
  protoOf(FTrapezoidsInt).e4g = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 0 | 0);
  };
  protoOf(FTrapezoidsInt).f4g = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 1 | 0);
  };
  protoOf(FTrapezoidsInt).y4e = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 2 | 0);
  };
  protoOf(FTrapezoidsInt).g4g = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 3 | 0);
  };
  protoOf(FTrapezoidsInt).h4g = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 4 | 0);
  };
  protoOf(FTrapezoidsInt).a4f = function (_this__u8e3s4) {
    return this.w4f_1.n(imul(_Item___get_index__impl__lpanhd_0(_this__u8e3s4), 6) + 5 | 0);
  };
  protoOf(FTrapezoidsInt).y4f = function (_this__u8e3s4, x, y) {
    return Companion_getInstance_34().i4g(this.e4g(_this__u8e3s4), this.f4g(_this__u8e3s4), this.y4e(_this__u8e3s4), this.g4g(_this__u8e3s4), this.h4g(_this__u8e3s4), this.a4f(_this__u8e3s4), x, y);
  };
  protoOf(FTrapezoidsInt).j4g = function (x0a, x0b, y0, x1a, x1b, y1) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = _Item___init__impl__hhk1le_0(this.l());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FTrapezoidsInt.add.<anonymous>' call
    this.w4f_1.s1a(x0a, x0b, y0, x1a, x1b, y1);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  function Companion_33() {
    Companion_instance_33 = this;
  }
  protoOf(Companion_33).t4f = function (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) {
    var outY = IntCompanionObject_getInstance().MIN_VALUE;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.trapezoid.Companion.getIntersectXY' call
      var a1 = By - Ay | 0;
      var b1 = Ax - Bx | 0;
      var c1 = imul(a1, Ax) + imul(b1, Ay) | 0;
      var a2 = Dy - Cy | 0;
      var b2 = Cx - Dx | 0;
      var c2 = imul(a2, Cx) + imul(b2, Cy) | 0;
      var determinant = imul(a1, b2) - imul(a2, b1) | 0;
      if (determinant === 0) {
        tmp$ret$0 = false;
        break $l$block;
      }
      var x = (imul(b2, c1) - imul(b1, c2) | 0) / determinant | 0;
      var y = (imul(a1, c2) - imul(a2, c1) | 0) / determinant | 0;
      // Inline function 'korlibs.math.geom.trapezoid.Companion.getIntersectY.<anonymous>' call
      outY = y;
      tmp$ret$0 = true;
    }
    return outY;
  };
  var Companion_instance_33;
  function Companion_getInstance_33() {
    if (Companion_instance_33 == null)
      new Companion_33();
    return Companion_instance_33;
  }
  function toSegments(_this__u8e3s4, scale) {
    scale = scale === VOID ? 1 : scale;
    var segments = new FSegmentsInt();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges' call
    var m = Vector2_init_$Create$_1();
    var l = Vector2_init_$Create$_1();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = _this__u8e3s4.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = _this__u8e3s4.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, _this__u8e3s4.y48_1.n(tmp2));
        m = tmp2__anonymous__z9zvc9;
        l = tmp2__anonymous__z9zvc9;
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
        tmp$ret$0 = Unit_getInstance();
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = _this__u8e3s4.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, _this__u8e3s4.y48_1.n(tmp4));
          // Inline function 'korlibs.math.geom.trapezoid.toSegments.<anonymous>' call
          var tmp9__anonymous__1b7cvk = l;
          toSegments$emit(segments, scale, tmp9__anonymous__1b7cvk, tmp3__anonymous__ufb84q);
          l = tmp3__anonymous__ufb84q;
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = _this__u8e3s4.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, _this__u8e3s4.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = _this__u8e3s4.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, _this__u8e3s4.y48_1.n(tmp8));
            // Inline function 'korlibs.math.geom.trapezoid.toSegments.<anonymous>' call
            var tmp10__anonymous__yfiz50 = l;
            toSegments$emit_0(segments, scale, Bezier_init_$Create$_0(tmp10__anonymous__yfiz50, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo));
            l = tmp5__anonymous__kpxxpo;
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = _this__u8e3s4.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, _this__u8e3s4.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = _this__u8e3s4.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, _this__u8e3s4.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = _this__u8e3s4.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, _this__u8e3s4.y48_1.n(tmp14));
              // Inline function 'korlibs.math.geom.trapezoid.toSegments.<anonymous>' call
              var tmp11__anonymous__tkubxh = l;
              toSegments$emit_0(segments, scale, Bezier_init_$Create$_1(tmp11__anonymous__tkubxh, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033));
              l = tmp8__anonymous__65w033;
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
                var equal = false ? l.g44(m) : l.equals(m);
                if (!equal) {
                  // Inline function 'korlibs.math.geom.trapezoid.toSegments.<anonymous>' call
                  var tmp12__anonymous__oq5opy = l;
                  var tmp13__anonymous__jvh1if = m;
                  toSegments$emit(segments, scale, tmp12__anonymous__oq5opy, tmp13__anonymous__jvh1if);
                }
                var tmp$ret$1;
                // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
                tmp$ret$1 = Unit_getInstance();
              }
            }
          }
        }
      }
    }
    return segments;
  }
  function segmentLookups($this, segments) {
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.filter' call
    var tmp1_filter = segments.c4f(SegmentIntToTrapezoidIntList$segmentLookups$lambda);
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = new FSegmentsInt();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.filter.<anonymous>' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
    var inductionVariable = 0;
    var last = tmp1_filter.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.filter.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = tmp1_filter.w4e(n);
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
        tmp$ret$0 = !(tmp1_filter.l4f(tmp0__anonymous__q1qw7t) === 0);
        if (tmp$ret$0) {
          tmp0_also.u4f(tmp0__anonymous__q1qw7t, tmp1_filter);
        }
      }
       while (inductionVariable < last);
    tmp$ret$1 = tmp0_also;
    tmp$ret$2 = tmp$ret$1;
    var list = tmp$ret$2;
    var tmp$ret$5;
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map' call
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp2_also = fastArrayListOf([]);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map.<anonymous>' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
    var inductionVariable_0 = 0;
    var last_0 = list.l();
    if (inductionVariable_0 < last_0)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t_0 = list.w4e(n_0);
        var tmp$ret$3;
        // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
        tmp$ret$3 = list.o4f(tmp0__anonymous__q1qw7t_0);
        tmp2_also.b(tmp$ret$3);
      }
       while (inductionVariable_0 < last_0);
    tmp$ret$4 = tmp2_also;
    tmp$ret$5 = tmp$ret$4;
    var tmp = tmp$ret$5;
    var tmp$ret$8;
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map' call
    var tmp$ret$7;
    // Inline function 'kotlin.also' call
    var tmp3_also = fastArrayListOf([]);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map.<anonymous>' call
    // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
    var inductionVariable_1 = 0;
    var last_1 = list.l();
    if (inductionVariable_1 < last_1)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.map.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t_1 = list.w4e(n_1);
        var tmp$ret$6;
        // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
        tmp$ret$6 = list.p4f(tmp0__anonymous__q1qw7t_1);
        tmp3_also.b(tmp$ret$6);
      }
       while (inductionVariable_1 < last_1);
    tmp$ret$7 = tmp3_also;
    tmp$ret$8 = tmp$ret$7;
    var allY = sortedArray(toIntArray(distinct(plus(tmp, tmp$ret$8))));
    var tmp_0 = 0;
    var tmp_1 = allY.length;
    var tmp$ret$9;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$9 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$9;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$10;
      // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
      tmp$ret$10 = new FSegmentsInt();
      tmp_2[tmp_3] = tmp$ret$10;
      tmp_0 = tmp_0 + 1 | 0;
    }
    var initialSegmentsInY = toList(tmp_2);
    var tmp_4 = 0;
    var tmp_5 = allY.length;
    var tmp$ret$11;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$11 = fillArrayVal(Array(tmp_5), null);
    var tmp_6 = tmp$ret$11;
    while (tmp_4 < tmp_5) {
      var tmp_7 = tmp_4;
      var tmp$ret$12;
      // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
      tmp$ret$12 = new FSegmentsInt();
      tmp_6[tmp_7] = tmp$ret$12;
      tmp_4 = tmp_4 + 1 | 0;
    }
    var allSegmentsInY = toList(tmp_6);
    var listPivot = 0;
    var yPivot = 0;
    while (yPivot < allY.length ? listPivot < list.l() : false) {
      var currentY = allY[yPivot];
      var currentItem = list.w4e(listPivot);
      var tmp$ret$14;
      // Inline function 'korlibs.math.geom.trapezoid.Item.use' call
      var tmp$ret$13;
      // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
      tmp$ret$13 = list.o4f(currentItem);
      tmp$ret$14 = tmp$ret$13;
      if (tmp$ret$14 === currentY) {
        initialSegmentsInY.n(yPivot).u4f(currentItem, list);
        var tmp0 = listPivot;
        listPivot = tmp0 + 1 | 0;
      } else {
        var tmp1 = yPivot;
        yPivot = tmp1 + 1 | 0;
      }
    }
    var inductionVariable_2 = 0;
    var last_2 = allY.length - 1 | 0;
    if (inductionVariable_2 <= last_2)
      do {
        var n_2 = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
        var tmp4_fastForEach = initialSegmentsInY.n(n_2);
        var inductionVariable_3 = 0;
        var last_3 = tmp4_fastForEach.l();
        if (inductionVariable_3 < last_3)
          do {
            var n_3 = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
            var tmp5__anonymous__kpxxpo = tmp4_fastForEach.w4e(n_3);
            var inductionVariable_4 = n_2;
            var last_4 = allY.length;
            if (inductionVariable_4 < last_4)
              $l$loop: do {
                var m = inductionVariable_4;
                inductionVariable_4 = inductionVariable_4 + 1 | 0;
                var y = allY[m];
                if (!tmp4_fastForEach.r4f(tmp5__anonymous__kpxxpo, y) ? true : tmp4_fastForEach.p4f(tmp5__anonymous__kpxxpo) === y)
                  break $l$loop;
                allSegmentsInY.n(m).u4f(tmp5__anonymous__kpxxpo, tmp4_fastForEach);
              }
               while (inductionVariable_4 < last_4);
          }
           while (inductionVariable_3 < last_3);
      }
       while (inductionVariable_2 <= last_2);
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n_4 = 0;
    while (n_4 < allSegmentsInY.l()) {
      // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
      var tmp0_0 = n_4;
      n_4 = tmp0_0 + 1 | 0;
      var tmp6__anonymous__fv9ai5 = allSegmentsInY.n(tmp0_0);
      tmp6__anonymous__fv9ai5.d4f(SegmentIntToTrapezoidIntList$segmentLookups$lambda_0);
    }
    var inductionVariable_5 = 0;
    var last_5 = allY.length - 1 | 0;
    if (inductionVariable_5 <= last_5)
      do {
        var n_5 = inductionVariable_5;
        inductionVariable_5 = inductionVariable_5 + 1 | 0;
        // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.fastForEach' call
        var tmp7_fastForEach = allSegmentsInY.n(n_5);
        var inductionVariable_6 = 0;
        var last_6 = tmp7_fastForEach.l();
        if (inductionVariable_6 < last_6)
          do {
            var n_6 = inductionVariable_6;
            inductionVariable_6 = inductionVariable_6 + 1 | 0;
            // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.segmentLookups.<anonymous>' call
            var tmp8__anonymous__65w033 = tmp7_fastForEach.w4e(n_6);
            // Inline function 'kotlin.check' call
            var tmp0_check = tmp7_fastForEach.r4f(tmp8__anonymous__65w033, allY[n_5]);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlin.check' call
            // Inline function 'kotlin.contracts.contract' call
            if (!tmp0_check) {
              var tmp$ret$15;
              // Inline function 'kotlin.check.<anonymous>' call
              tmp$ret$15 = 'Check failed.';
              var message = tmp$ret$15;
              throw IllegalStateException_init_$Create$(toString(message));
            }
          }
           while (inductionVariable_6 < last_6);
      }
       while (inductionVariable_5 <= last_5);
    return new Pair(allY, allSegmentsInY);
  }
  function SegmentIntToTrapezoidIntList$segmentLookups$lambda($this$sortedBy, it) {
    return $this$sortedBy.o4f(it.q4e_1);
  }
  function SegmentIntToTrapezoidIntList$segmentLookups$lambda_0($this$sortBy, it) {
    return $this$sortBy.n4f(it.q4e_1);
  }
  function SegmentIntToTrapezoidIntList() {
    SegmentIntToTrapezoidIntList_instance = this;
  }
  protoOf(SegmentIntToTrapezoidIntList).k4g = function (segments, winding, trapezoids) {
    var tmp0_container = segmentLookups(this, segments);
    var allY = tmp0_container.c5();
    var allSegmentsInY = tmp0_container.d5();
    var inductionVariable = 0;
    var last = allY.length - 1 | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var y0 = allY[n];
        var y1 = allY[n + 1 | 0];
        var segs = allSegmentsInY.n(n);
        var tmp$ret$0;
        // Inline function 'kotlin.collections.arrayListOf' call
        tmp$ret$0 = ArrayList_init_$Create$();
        var chunks = tmp$ret$0;
        var tmp2_subject = winding;
        var tmp0 = tmp2_subject.b2_1;
        if (tmp0 === 0) {
          var progression = step(until(0, segs.l()), 2);
          var inductionVariable_0 = progression.p1_1;
          var last_0 = progression.q1_1;
          var step_0 = progression.r1_1;
          if ((step_0 > 0 ? inductionVariable_0 <= last_0 : false) ? true : step_0 < 0 ? last_0 <= inductionVariable_0 : false)
            $l$loop_1: do {
              var m = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + step_0 | 0;
              var tmp4_elvis_lhs = segs.f4f(m + 0 | 0);
              var tmp;
              var tmp_0 = tmp4_elvis_lhs;
              if ((tmp_0 == null ? null : new Item(tmp_0)) == null) {
                continue $l$loop_1;
              } else {
                tmp = tmp4_elvis_lhs;
              }
              var s0 = tmp;
              var tmp5_elvis_lhs = segs.f4f(m + 1 | 0);
              var tmp_1;
              var tmp_2 = tmp5_elvis_lhs;
              if ((tmp_2 == null ? null : new Item(tmp_2)) == null) {
                continue $l$loop_1;
              } else {
                tmp_1 = tmp5_elvis_lhs;
              }
              var s1 = tmp_1;
              var tmp_3;
              var tmp$ret$3;
              // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
              var tmp$ret$2;
              // Inline function 'kotlin.run' call
              // Inline function 'kotlin.contracts.contract' call
              var tmp$ret$1;
              // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
              tmp$ret$1 = segs.r4f(s0, y1);
              tmp$ret$2 = tmp$ret$1;
              tmp$ret$3 = tmp$ret$2;
              if (!tmp$ret$3) {
                tmp_3 = true;
              } else {
                var tmp$ret$6;
                // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
                var tmp$ret$5;
                // Inline function 'kotlin.run' call
                // Inline function 'kotlin.contracts.contract' call
                var tmp$ret$4;
                // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
                tmp$ret$4 = !segs.r4f(s1, y1);
                tmp$ret$5 = tmp$ret$4;
                tmp$ret$6 = tmp$ret$5;
                tmp_3 = tmp$ret$6;
              }
              if (tmp_3)
                continue $l$loop_1;
              // Inline function 'kotlin.collections.plusAssign' call
              var tmp0_plusAssign = new Pair(new Item(s0), new Item(s1));
              chunks.b(tmp0_plusAssign);
            }
             while (!(m === last_0));
        } else if (tmp0 === 1) {
          var sign = 0;
          var inductionVariable_1 = 0;
          var last_1 = segs.l();
          if (inductionVariable_1 < last_1)
            $l$loop_2: do {
              var m_0 = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              var seg = segs.w4e(m_0);
              if (!(sign === 0) ? m_0 > 0 : false) {
                var s0_0 = segs.w4e(m_0 - 1 | 0);
                var s1_0 = seg;
                var tmp_4;
                var tmp$ret$9;
                // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
                var tmp$ret$8;
                // Inline function 'kotlin.run' call
                // Inline function 'kotlin.contracts.contract' call
                var tmp$ret$7;
                // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
                tmp$ret$7 = segs.r4f(s0_0, y1);
                tmp$ret$8 = tmp$ret$7;
                tmp$ret$9 = tmp$ret$8;
                if (!tmp$ret$9) {
                  tmp_4 = true;
                } else {
                  var tmp$ret$12;
                  // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
                  var tmp$ret$11;
                  // Inline function 'kotlin.run' call
                  // Inline function 'kotlin.contracts.contract' call
                  var tmp$ret$10;
                  // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
                  tmp$ret$10 = !segs.r4f(s1_0, y1);
                  tmp$ret$11 = tmp$ret$10;
                  tmp$ret$12 = tmp$ret$11;
                  tmp_4 = tmp$ret$12;
                }
                if (tmp_4)
                  continue $l$loop_2;
                // Inline function 'kotlin.collections.plusAssign' call
                var tmp1_plusAssign = new Pair(new Item(s0_0), new Item(s1_0));
                chunks.b(tmp1_plusAssign);
              }
              var tmp_5 = sign;
              var tmp$ret$15;
              // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
              var tmp$ret$14;
              // Inline function 'kotlin.run' call
              // Inline function 'kotlin.contracts.contract' call
              var tmp$ret$13;
              // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
              tmp$ret$13 = get_sign(segs.l4f(seg));
              tmp$ret$14 = tmp$ret$13;
              tmp$ret$15 = tmp$ret$14;
              sign = tmp_5 + tmp$ret$15 | 0;
            }
             while (inductionVariable_1 < last_1);
        }
        var tmp7_iterator = chunks.h();
        while (tmp7_iterator.j()) {
          var tmp8_loop_parameter = tmp7_iterator.k();
          var s0_1 = tmp8_loop_parameter.c5().q4e_1;
          var s1_1 = tmp8_loop_parameter.d5().q4e_1;
          var tmp$ret$18;
          // Inline function 'korlibs.math.geom.trapezoid.FSegmentsInt.invoke' call
          var tmp$ret$17;
          // Inline function 'kotlin.run' call
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$16;
          // Inline function 'korlibs.math.geom.trapezoid.SegmentIntToTrapezoidIntList.convert.<anonymous>' call
          var x0a = segs.q4f(s0_1, y0);
          var x0b = segs.q4f(s1_1, y0);
          var x1a = segs.q4f(s0_1, y1);
          var x1b = segs.q4f(s1_1, y1);
          var tmp_6;
          if (x1b < x1a) {
            var intersectY = segs.s4f(s0_1, s1_1);
            var intersectX = segs.q4f(s0_1, intersectY);
            trapezoids.j4g(x0a, x0b, y0, intersectX, intersectX, intersectY);
            tmp_6 = trapezoids.j4g(intersectX, intersectX, intersectY, x1a, x1b, y1);
          } else {
            tmp_6 = trapezoids.j4g(x0a, x0b, y0, x1a, x1b, y1);
          }
          tmp$ret$16 = tmp_6;
          tmp$ret$17 = tmp$ret$16;
          tmp$ret$18 = tmp$ret$17;
        }
      }
       while (inductionVariable < last);
    return trapezoids;
  };
  protoOf(SegmentIntToTrapezoidIntList).l4g = function (segments, winding, trapezoids, $super) {
    winding = winding === VOID ? Winding_EVEN_ODD_getInstance() : winding;
    trapezoids = trapezoids === VOID ? new FTrapezoidsInt() : trapezoids;
    return $super === VOID ? this.k4g(segments, winding, trapezoids) : $super.k4g.call(this, segments, winding, trapezoids);
  };
  var SegmentIntToTrapezoidIntList_instance;
  function SegmentIntToTrapezoidIntList_getInstance() {
    if (SegmentIntToTrapezoidIntList_instance == null)
      new SegmentIntToTrapezoidIntList();
    return SegmentIntToTrapezoidIntList_instance;
  }
  function toSegments$emit(segments, $scale, p0, p1) {
    segments.b4f(toIntRound(p0.o3t() * $scale), toIntRound(p0.p3t() * $scale), toIntRound(p1.o3t() * $scale), toIntRound(p1.p3t() * $scale));
  }
  function toSegments$emit_0(segments, $scale, bezier) {
    var len = coerceIn(toIntRound_0(bezier.hf()), 2, 20);
    var oldPos = Vector2_init_$Create$_1();
    var inductionVariable = 0;
    if (inductionVariable <= len)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var ratio = n / len;
        var p = bezier.w46(ratio);
        if (n > 0) {
          toSegments$emit(segments, $scale, oldPos, p);
        }
        oldPos = p;
      }
       while (!(n === len));
  }
  function det($this, x0, y0, x1, y1) {
    return imul(x0, y1) - imul(x1, y0) | 0;
  }
  function Companion_34() {
    Companion_instance_34 = this;
  }
  protoOf(Companion_34).i4g = function (x0a, x0b, y0, x1a, x1b, y1, x, y) {
    if (y < y0 ? true : y > y1)
      return false;
    if ((x < x0a ? x < x1a : false) ? true : x > x0b ? x > x1b : false)
      return false;
    var sign1 = get_sign(det(this, x1a - x | 0, y1 - y | 0, x0a - x | 0, y0 - y | 0));
    var sign2 = get_sign(det(this, x1b - x | 0, y1 - y | 0, x0b - x | 0, y0 - y | 0));
    return !(sign1 === sign2);
  };
  var Companion_instance_34;
  function Companion_getInstance_34() {
    if (Companion_instance_34 == null)
      new Companion_34();
    return Companion_instance_34;
  }
  function Companion_35() {
    Companion_instance_35 = this;
  }
  protoOf(Companion_35).m4g = function (a, b) {
    var tmp$ret$2;
    // Inline function 'korlibs.math.geom.vector.Companion._getIntersectXY' call
    var Ax = a.n4g_1;
    var Ay = a.o4g_1;
    var Bx = a.p4g_1;
    var By = a.q4g_1;
    var Cx = b.n4g_1;
    var Cy = b.o4g_1;
    var Dx = b.p4g_1;
    var Dy = b.q4g_1;
    var tmp0_safe_receiver = this.u3t(Ax, Ay, Bx, By, Cx, Cy, Dx, Dy);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.math.geom.vector.Companion._getIntersectXY.<anonymous>' call
      tmp$ret$0 = new Vector2Int(numberToInt(floorCeil(tmp0_safe_receiver.o3t())), numberToInt(floorCeil(tmp0_safe_receiver.p3t())));
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    tmp$ret$2 = tmp;
    var tmp0_safe_receiver_0 = tmp$ret$2;
    var tmp_0;
    if (tmp0_safe_receiver_0 == null) {
      tmp_0 = null;
    } else {
      var tmp$ret$4;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$3;
      // Inline function 'korlibs.math.geom.vector.Companion.getIntersectXY.<anonymous>' call
      tmp$ret$3 = Vector2_init_$Create$_0(tmp0_safe_receiver_0.r40_1, tmp0_safe_receiver_0.s40_1);
      tmp$ret$4 = tmp$ret$3;
      tmp_0 = tmp$ret$4;
    }
    return tmp_0;
  };
  protoOf(Companion_35).x4g = function (a, b) {
    return Angle__minus_impl_xeau7k(b.y4g(), a.y4g());
  };
  protoOf(Companion_35).u3t = function (Ax, Ay, Bx, By, Cx, Cy, Dx, Dy) {
    return Companion_getInstance_3().u3t(Ax, Ay, Bx, By, Cx, Cy, Dx, Dy);
  };
  var Companion_instance_35;
  function Companion_getInstance_35() {
    if (Companion_instance_35 == null)
      new Companion_35();
    return Companion_instance_35;
  }
  function MEdge() {
    Companion_getInstance_35();
    this.n4g_1 = 0;
    this.o4g_1 = 0;
    this.p4g_1 = 0;
    this.q4g_1 = 0;
    this.r4g_1 = 0;
    this.s4g_1 = 0;
    this.t4g_1 = 0;
    this.u4g_1 = false;
    this.v4g_1 = false;
    this.w4g_1 = 0;
  }
  protoOf(MEdge).v3z = function (n) {
    return n === 0 ? this.n4g_1 : this.p4g_1;
  };
  protoOf(MEdge).u3z = function (n) {
    return n === 0 ? this.o4g_1 : this.q4g_1;
  };
  protoOf(MEdge).z4g = function (other) {
    return this.a4h(other.n4g_1, other.o4g_1, other.p4g_1, other.q4g_1, other.r4g_1);
  };
  protoOf(MEdge).a4h = function (ax, ay, bx, by, wind) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.MEdge.setTo.<anonymous>' call
    this.n4g_1 = ax;
    this.o4g_1 = ay;
    this.p4g_1 = bx;
    this.q4g_1 = by;
    this.t4g_1 = bx - ax | 0;
    this.s4g_1 = by - ay | 0;
    this.u4g_1 = ay === by;
    this.v4g_1 = ax === bx;
    this.r4g_1 = wind;
    this.w4g_1 = this.v4g_1 ? 0 : ay - (imul(ax, this.s4g_1) / this.t4g_1 | 0) | 0;
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(MEdge).b4h = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.o4g_1;
    var tmp1_min = this.q4g_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  };
  protoOf(MEdge).c4h = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.o4g_1;
    var tmp1_max = this.q4g_1;
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    return tmp$ret$0;
  };
  protoOf(MEdge).d4h = function (y) {
    return y >= this.o4g_1 ? y < this.q4g_1 : false;
  };
  protoOf(MEdge).e4h = function (y) {
    return (this.v4g_1 ? true : this.s4g_1 === 0) ? this.n4g_1 : imul(y - this.w4g_1 | 0, this.t4g_1) / this.s4g_1 | 0;
  };
  protoOf(MEdge).y4g = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp4_between = Companion_getInstance_0();
    var tmp5_between = this.n4g_1;
    var tmp6_between = this.o4g_1;
    var tmp7_between = this.p4g_1;
    var tmp8_between = this.q4g_1;
    var tmp9_between = Companion_getInstance_21().v3r_1;
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp0_between = tmp5_between;
    var tmp1_between = tmp6_between;
    var tmp2_between = tmp7_between;
    var tmp3_between = tmp8_between;
    tmp$ret$0 = Angle_between(tmp0_between, tmp1_between, tmp2_between, tmp3_between, tmp9_between);
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(MEdge).toString = function () {
    return 'Edge([' + this.n4g_1 + ',' + this.o4g_1 + ']-[' + this.p4g_1 + ',' + this.q4g_1 + '])';
  };
  var LineCap_BUTT_instance;
  var LineCap_SQUARE_instance;
  var LineCap_ROUND_instance;
  function Companion_36() {
    Companion_instance_36 = this;
  }
  var Companion_instance_36;
  function Companion_getInstance_36() {
    LineCap_initEntries();
    if (Companion_instance_36 == null)
      new Companion_36();
    return Companion_instance_36;
  }
  var LineCap_entriesInitialized;
  function LineCap_initEntries() {
    if (LineCap_entriesInitialized)
      return Unit_getInstance();
    LineCap_entriesInitialized = true;
    LineCap_BUTT_instance = new LineCap('BUTT', 0);
    LineCap_SQUARE_instance = new LineCap('SQUARE', 1);
    LineCap_ROUND_instance = new LineCap('ROUND', 2);
    Companion_getInstance_36();
  }
  function LineCap(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function LineCap_BUTT_getInstance() {
    LineCap_initEntries();
    return LineCap_BUTT_instance;
  }
  function LineCap_SQUARE_getInstance() {
    LineCap_initEntries();
    return LineCap_SQUARE_instance;
  }
  var LineJoin_BEVEL_instance;
  var LineJoin_ROUND_instance;
  var LineJoin_MITER_instance;
  function Companion_37() {
    Companion_instance_37 = this;
  }
  var Companion_instance_37;
  function Companion_getInstance_37() {
    LineJoin_initEntries();
    if (Companion_instance_37 == null)
      new Companion_37();
    return Companion_instance_37;
  }
  var LineJoin_entriesInitialized;
  function LineJoin_initEntries() {
    if (LineJoin_entriesInitialized)
      return Unit_getInstance();
    LineJoin_entriesInitialized = true;
    LineJoin_BEVEL_instance = new LineJoin('BEVEL', 0);
    LineJoin_ROUND_instance = new LineJoin('ROUND', 1);
    LineJoin_MITER_instance = new LineJoin('MITER', 2);
    Companion_getInstance_37();
  }
  function LineJoin(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function LineJoin_BEVEL_getInstance() {
    LineJoin_initEntries();
    return LineJoin_BEVEL_instance;
  }
  function LineJoin_MITER_getInstance() {
    LineJoin_initEntries();
    return LineJoin_MITER_instance;
  }
  var LineScaleMode_NONE_instance;
  var LineScaleMode_HORIZONTAL_instance;
  var LineScaleMode_VERTICAL_instance;
  var LineScaleMode_NORMAL_instance;
  var LineScaleMode_entriesInitialized;
  function LineScaleMode_initEntries() {
    if (LineScaleMode_entriesInitialized)
      return Unit_getInstance();
    LineScaleMode_entriesInitialized = true;
    LineScaleMode_NONE_instance = new LineScaleMode('NONE', 0, false, false);
    LineScaleMode_HORIZONTAL_instance = new LineScaleMode('HORIZONTAL', 1, true, false);
    LineScaleMode_VERTICAL_instance = new LineScaleMode('VERTICAL', 2, false, true);
    LineScaleMode_NORMAL_instance = new LineScaleMode('NORMAL', 3, true, true);
  }
  function LineScaleMode(name, ordinal, hScale, vScale) {
    Enum.call(this, name, ordinal);
    this.h4h_1 = hScale;
    this.i4h_1 = vScale;
    this.j4h_1 = this.h4h_1 ? true : this.i4h_1;
    this.k4h_1 = this.h4h_1 ? this.i4h_1 : false;
  }
  function LineScaleMode_NONE_getInstance() {
    LineScaleMode_initEntries();
    return LineScaleMode_NONE_instance;
  }
  function LineScaleMode_NORMAL_getInstance() {
    LineScaleMode_initEntries();
    return LineScaleMode_NORMAL_instance;
  }
  function PolygonScanline$AllBuckets$pool$lambda(it) {
    it.kk();
    return Unit_getInstance();
  }
  function PolygonScanline$AllBuckets$pool$lambda_0(it) {
    return new Bucket();
  }
  function Bucket() {
    this.l4h_1 = FastArrayList_init_$Create$();
  }
  protoOf(Bucket).kk = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.Bucket.clear.<anonymous>' call
    this.l4h_1.e4();
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  function Buckets(pool, ySize) {
    this.m4h_1 = pool;
    this.n4h_1 = ySize;
    this.o4h_1 = FastIntMap();
  }
  protoOf(Buckets).l = function () {
    return get_size(this.o4h_1);
  };
  protoOf(Buckets).p4h = function (y) {
    return y / this.n4h_1 | 0;
  };
  protoOf(Buckets).q4h = function (index) {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'korlibs.datastructure.getOrPut' call
      var tmp0_getOrPut = this.o4h_1;
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_getOrPut;
      tmp$ret$1 = tmp$ret$0.get(index);
      var res = tmp$ret$1;
      if (!(res == null)) {
        tmp$ret$2 = res;
        break $l$block;
      }
      var tmp$ret$3;
      // Inline function 'korlibs.math.geom.vector.Buckets.getForIndex.<anonymous>' call
      tmp$ret$3 = this.m4h_1.a1g();
      var out = tmp$ret$3;
      // Inline function 'korlibs.datastructure.set' call
      var tmp$ret$4;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$4 = tmp0_getOrPut;
      tmp$ret$4.set(index, out);
      tmp$ret$2 = out;
    }
    return tmp$ret$2;
  };
  protoOf(Buckets).r4h = function (y) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.get' call
    var tmp0_get = this.o4h_1;
    var tmp1_get = this.p4h(y);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_get;
    tmp$ret$1 = tmp$ret$0.get(tmp1_get);
    return tmp$ret$1;
  };
  protoOf(Buckets).e4 = function () {
    // Inline function 'korlibs.datastructure.fastForEach' call
    var tmp1_fastForEach = this.o4h_1;
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp1_fastForEach;
    var mapIterator = tmp$ret$0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEach.<anonymous>' call
      var tmp0__anonymous__q1qw7t = v.value;
      // Inline function 'korlibs.math.geom.vector.Buckets.clear.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp1_fastForEach;
      tmp$ret$2 = tmp$ret$1.get(tmp0__anonymous__q1qw7t);
      var tmp2__anonymous__z9zvc9 = ensureNotNull(tmp$ret$2);
      this.m4h_1.b1g(tmp2__anonymous__z9zvc9.kk());
    }
    // Inline function 'korlibs.datastructure.clear' call
    var tmp3_clear = this.o4h_1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp3_clear;
    tmp$ret$3.clear();
  };
  protoOf(Buckets).s4h = function (edge, threshold) {
    var min = this.p4h(edge.b4h());
    var max = this.p4h(edge.c4h());
    if ((max - min | 0) < threshold) {
      var inductionVariable = min;
      if (inductionVariable <= max)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          this.q4h(n).l4h_1.b(edge);
        }
         while (!(n === max));
      return true;
    }
    return false;
  };
  protoOf(Buckets).t4h = function (edge, threshold, $super) {
    threshold = threshold === VOID ? IntCompanionObject_getInstance().MAX_VALUE : threshold;
    return $super === VOID ? this.s4h(edge, threshold) : $super.s4h.call(this, edge, threshold);
  };
  function AllBuckets() {
    var tmp = this;
    var tmp_0 = PolygonScanline$AllBuckets$pool$lambda;
    tmp.u4h_1 = new Pool(tmp_0, VOID, PolygonScanline$AllBuckets$pool$lambda_0);
    var tmp_1 = this;
    Companion_getInstance_38();
    tmp_1.v4h_1 = new Buckets(this.u4h_1, 80);
    var tmp_2 = this;
    Companion_getInstance_38();
    tmp_2.w4h_1 = new Buckets(this.u4h_1, 320);
    var tmp_3 = this;
    Companion_getInstance_38();
    tmp_3.x4h_1 = new Buckets(this.u4h_1, 1280);
  }
  protoOf(AllBuckets).y4h = function (edge) {
    if (this.v4h_1.s4h(edge, 4))
      return Unit_getInstance();
    if (this.w4h_1.s4h(edge, 4))
      return Unit_getInstance();
    this.x4h_1.t4h(edge);
  };
  protoOf(AllBuckets).e4 = function () {
    this.v4h_1.e4();
    this.w4h_1.e4();
    this.x4h_1.e4();
  };
  function addPoint($this, x, y) {
    $this.i4i_1.f40(x, y);
  }
  function addEdge($this, ax, ay, bx, by) {
    if (ax === bx ? ay === by : false)
      return Unit_getInstance();
    var isHorizontal = ay === by;
    var iax = $this.r4i(ax);
    var ibx = $this.r4i(bx);
    var iay = $this.r4i(ay);
    var iby = $this.r4i(by);
    var edge = ay < by ? $this.c4i_1.a1g().a4h(iax, iay, ibx, iby, 1) : $this.c4i_1.a1g().a4h(ibx, iby, iax, iay, -1);
    $this.f4i_1.b(edge);
    if (isHorizontal) {
      $this.e4i_1.b(edge);
    } else {
      $this.d4i_1.b(edge);
      $this.g4i_1.y4h(edge);
    }
    var tmp0_this = $this;
    tmp0_this.b4i_1 = BoundsBuilder__plus_impl_cz7f7x(tmp0_this.b4i_1, Vector2_init_$Create$(ax, ay));
    var tmp1_this = $this;
    tmp1_this.b4i_1 = BoundsBuilder__plus_impl_cz7f7x(tmp1_this.b4i_1, Vector2_init_$Create$(bx, by));
  }
  function XWithWind() {
    this.s4i_1 = new IntArrayList(1024);
    this.t4i_1 = new IntArrayList(1024);
  }
  protoOf(XWithWind).l = function () {
    return this.s4i_1.l();
  };
  protoOf(XWithWind).u4i = function (x, wind) {
    this.s4i_1.q1a(x);
    this.t4i_1.q1a(wind);
  };
  protoOf(XWithWind).e4 = function () {
    this.s4i_1.e4();
    this.t4i_1.e4();
  };
  protoOf(XWithWind).toString = function () {
    return 'XWithWind(' + this.s4i_1 + ', ' + this.t4i_1 + ')';
  };
  function IntArrayListSort() {
    IntArrayListSort_instance = this;
    SortOps.call(this);
  }
  protoOf(IntArrayListSort).v4i = function (subject, l, r) {
    return compareTo(subject.s4i_1.z18(l), subject.s4i_1.z18(r));
  };
  protoOf(IntArrayListSort).f1b = function (subject, l, r) {
    return this.v4i(subject instanceof XWithWind ? subject : THROW_CCE(), l, r);
  };
  protoOf(IntArrayListSort).w4i = function (subject, indexL, indexR) {
    subject.s4i_1.t19(indexL, indexR);
    subject.t4i_1.t19(indexL, indexR);
  };
  protoOf(IntArrayListSort).h1b = function (subject, indexL, indexR) {
    return this.w4i(subject instanceof XWithWind ? subject : THROW_CCE(), indexL, indexR);
  };
  var IntArrayListSort_instance;
  function IntArrayListSort_getInstance() {
    if (IntArrayListSort_instance == null)
      new IntArrayListSort();
    return IntArrayListSort_instance;
  }
  function PolygonScanline$edgesPool$lambda(it) {
    return new MEdge();
  }
  function PolygonScanline() {
    RastScale.call(this);
    this.z4h_1 = -1;
    this.a4i_1 = Winding_NON_ZERO_getInstance();
    this.b4i_1 = Companion_getInstance_1().o3s();
    var tmp = this;
    tmp.c4i_1 = Pool_init_$Create$(VOID, PolygonScanline$edgesPool$lambda);
    this.d4i_1 = FastArrayList_init_$Create$();
    this.e4i_1 = FastArrayList_init_$Create$();
    this.f4i_1 = FastArrayList_init_$Create$();
    this.g4i_1 = new AllBuckets();
    this.h4i_1 = true;
    this.i4i_1 = new PointArrayList();
    this.j4i_1 = 0.0;
    this.k4i_1 = 0.0;
    this.l4i_1 = 0.0;
    this.m4i_1 = 0.0;
    this.n4i_1 = false;
    this.o4i_1 = new XWithWind();
    this.p4i_1 = 0;
    this.q4i_1 = new IntSegmentSet();
  }
  protoOf(PolygonScanline).u46 = function () {
    return _BoundsBuilder___get_bounds__impl__s1l1gx(this.b4i_1);
  };
  protoOf(PolygonScanline).p1g = function () {
    this.h4i_1 = true;
    this.b4i_1 = Companion_getInstance_1().j3s_1;
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    var tmp3_fastForEach = this.d4i_1;
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var array = tmp$ret$2;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.math.geom.vector.PolygonScanline.reset.<anonymous>' call
      var tmp$ret$6;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$4;
      // Inline function 'korlibs.datastructure.get' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1_get = tmp0;
      var tmp$ret$3;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$3 = array;
      tmp$ret$4 = tmp$ret$3[tmp1_get];
      var tmp2_unsafeCast = tmp$ret$4;
      var tmp$ret$5;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$5 = tmp2_unsafeCast;
      tmp$ret$6 = tmp$ret$5;
      var tmp4__anonymous__pkmkx7 = tmp$ret$6;
      this.c4i_1.b1g(tmp4__anonymous__pkmkx7);
    }
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    var tmp8_fastForEach = this.e4i_1;
    var tmp$ret$9;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$8;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp5_unsafeCast = tmp8_fastForEach.c1d_1;
    var tmp$ret$7;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$7 = tmp5_unsafeCast;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var array_0 = tmp$ret$9;
    var n_0 = 0;
    while (n_0 < array_0.length) {
      // Inline function 'korlibs.math.geom.vector.PolygonScanline.reset.<anonymous>' call
      var tmp$ret$13;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$11;
      // Inline function 'korlibs.datastructure.get' call
      var tmp0_0 = n_0;
      n_0 = tmp0_0 + 1 | 0;
      var tmp6_get = tmp0_0;
      var tmp$ret$10;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$10 = array_0;
      tmp$ret$11 = tmp$ret$10[tmp6_get];
      var tmp7_unsafeCast = tmp$ret$11;
      var tmp$ret$12;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$12 = tmp7_unsafeCast;
      tmp$ret$13 = tmp$ret$12;
      var tmp9__anonymous__1b7cvk = tmp$ret$13;
      this.c4i_1.b1g(tmp9__anonymous__1b7cvk);
    }
    this.d4i_1.e4();
    this.e4i_1.e4();
    this.f4i_1.e4();
    this.i4i_1.kk();
    this.g4i_1.e4();
    this.j4i_1 = 0.0;
    this.k4i_1 = 0.0;
    this.l4i_1 = 0.0;
    this.m4i_1 = 0.0;
    this.n4i_1 = false;
  };
  protoOf(PolygonScanline).x4i = function () {
    return this.d4i_1.l();
  };
  protoOf(PolygonScanline).y1a = function () {
    return this.x4i() > 0;
  };
  protoOf(PolygonScanline).y4i = function (x, y) {
    this.l4i_1 = x;
    this.m4i_1 = y;
    this.j4i_1 = x;
    this.k4i_1 = y;
    this.n4i_1 = true;
  };
  protoOf(PolygonScanline).z4i = function (x, y) {
    if (this.n4i_1) {
      addPoint(this, this.l4i_1, this.m4i_1);
    }
    addEdge(this, this.l4i_1, this.m4i_1, x, y);
    addPoint(this, x, y);
    this.l4i_1 = x;
    this.m4i_1 = y;
    this.n4i_1 = false;
  };
  protoOf(PolygonScanline).y44 = function (p) {
    return this.y4i(p.o3t(), p.p3t());
  };
  protoOf(PolygonScanline).a45 = function (p) {
    return this.z4i(p.o3t(), p.p3t());
  };
  protoOf(PolygonScanline).a4j = function (path) {
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2_init_$Create$_1();
    var l = Vector2_init_$Create$_1();
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
    if (false) {
      this.n1q();
    }
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = path.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = path.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, path.y48_1.n(tmp2));
        i = tmp2__anonymous__z9zvc9;
        // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
        this.b4j(tmp2__anonymous__z9zvc9, true);
        l = tmp2__anonymous__z9zvc9;
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = path.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, path.y48_1.n(tmp4));
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
          this.b4j(tmp3__anonymous__ufb84q, false);
          l = tmp3__anonymous__ufb84q;
          var tmp$ret$0;
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          tmp$ret$0 = Unit_getInstance();
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = path.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, path.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = path.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, path.y48_1.n(tmp8));
            var sum = Companion_getInstance_21().q43(l, tmp4__anonymous__pkmkx7) + Companion_getInstance_21().q43(tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            var tmp0_approximateCurve = numberToInt(sum);
            var tmp$ret$1;
            // Inline function 'kotlin.math.max' call
            tmp$ret$1 = Math.max(tmp0_approximateCurve, 20);
            var rcurveSteps = tmp$ret$1;
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2_init_$Create$_1()};
            var prevPos = {_v: Vector2_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            var tmp1__anonymous__uwfjfc_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, 0.0);
            lastPos._v = tmp1__anonymous__uwfjfc_0;
            var nStart = false ? 0 : 1;
            var nEnd = rcurveSteps;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio = n_1 * dt;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var tmp2__anonymous__z9zvc9_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, ratio);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                this.b4j(tmp2__anonymous__z9zvc9_0, false);
                var tmp0_0 = emittedCount._v;
                emittedCount._v = tmp0_0 + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = tmp2__anonymous__z9zvc9_0;
              }
               while (!(n_1 === nEnd));
            l = tmp5__anonymous__kpxxpo;
            var tmp$ret$2;
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            tmp$ret$2 = Unit_getInstance();
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = path.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, path.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = path.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, path.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = path.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, path.y48_1.n(tmp14));
              var sum_0 = Companion_getInstance_21().q43(l, tmp6__anonymous__fv9ai5) + Companion_getInstance_21().q43(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam) + Companion_getInstance_21().q43(tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              var tmp0_approximateCurve_0 = numberToInt(sum_0);
              var tmp$ret$3;
              // Inline function 'kotlin.math.max' call
              tmp$ret$3 = Math.max(tmp0_approximateCurve_0, 20);
              var rcurveSteps_0 = tmp$ret$3;
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              var tmp1__anonymous__uwfjfc_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, 0.0);
              lastPos_0._v = tmp1__anonymous__uwfjfc_1;
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = rcurveSteps_0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_0 = n_2 * dt_0;
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var tmp2__anonymous__z9zvc9_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, ratio_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                  this.b4j(tmp2__anonymous__z9zvc9_1, false);
                  var tmp0_1 = emittedCount_0._v;
                  emittedCount_0._v = tmp0_1 + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = tmp2__anonymous__z9zvc9_1;
                }
                 while (!(n_2 === nEnd_0));
              l = tmp8__anonymous__65w033;
              var tmp$ret$4;
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              tmp$ret$4 = Unit_getInstance();
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                var tmp9__anonymous__1b7cvk = i;
                this.b4j(tmp9__anonymous__1b7cvk, false);
                var tmp$ret$5;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                tmp$ret$5 = Unit_getInstance();
                // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
                {
                  this.n1q();
                }
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.add.<anonymous>' call
    if (false) {
      this.n1q();
    }
  };
  protoOf(PolygonScanline).b4j = function (p, move) {
    var tmp;
    if (move) {
      this.y44(p);
      tmp = Unit_getInstance();
    } else {
      this.a45(p);
      tmp = Unit_getInstance();
    }
    return tmp;
  };
  protoOf(PolygonScanline).c4j = function (x, y, move) {
    var tmp;
    if (move) {
      this.y4i(x, y);
      tmp = Unit_getInstance();
    } else {
      this.z4i(x, y);
      tmp = Unit_getInstance();
    }
    return tmp;
  };
  protoOf(PolygonScanline).d4j = function (x, y, move) {
    return this.c4j(x, y, move);
  };
  protoOf(PolygonScanline).n1q = function () {
    this.z4i(this.j4i_1, this.k4i_1);
  };
  protoOf(PolygonScanline).e4j = function (y, winding, out) {
    this.p4i_1 = 0;
    this.o4i_1.e4();
    out.kk();
    var tmp0_this = this;
    var tmp = tmp0_this;
    var tmp_0 = tmp0_this.p4i_1;
    var tmp$ret$24;
    // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY' call
    var edgesChecked = 0;
    // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY' call
    var tmp18_fastForEachY = this.g4i_1;
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var tmp4_fastForEachY = tmp18_fastForEachY.v4h_1;
    if (tmp4_fastForEachY.l() > 0) {
      var tmp0_safe_receiver = tmp4_fastForEachY.r4h(y);
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$7;
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        var tmp3_fastForEach = tmp0_safe_receiver.l4h_1;
        var tmp$ret$2;
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_unsafeCast;
        tmp$ret$1 = tmp$ret$0;
        tmp$ret$2 = tmp$ret$1;
        var array = tmp$ret$2;
        var n = 0;
        while (n < array.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          var tmp$ret$6;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$4;
          // Inline function 'korlibs.datastructure.get' call
          var tmp0 = n;
          n = tmp0 + 1 | 0;
          var tmp1_get = tmp0;
          var tmp$ret$3;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$3 = array;
          tmp$ret$4 = tmp$ret$3[tmp1_get];
          var tmp2_unsafeCast = tmp$ret$4;
          var tmp$ret$5;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$5 = tmp2_unsafeCast;
          tmp$ret$6 = tmp$ret$5;
          var tmp5__anonymous__kpxxpo = tmp$ret$6;
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var tmp0_0 = edgesChecked;
          edgesChecked = tmp0_0 + 1 | 0;
          if (tmp5__anonymous__kpxxpo.d4h(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!tmp5__anonymous__kpxxpo.u4g_1) {
              this.o4i_1.u4i(tmp5__anonymous__kpxxpo.e4h(y), tmp5__anonymous__kpxxpo.r4g_1);
            }
          }
        }
        tmp$ret$7 = Unit_getInstance();
      }
    }
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var tmp10_fastForEachY = tmp18_fastForEachY.w4h_1;
    if (tmp10_fastForEachY.l() > 0) {
      var tmp0_safe_receiver_0 = tmp10_fastForEachY.r4h(y);
      if (tmp0_safe_receiver_0 == null)
        null;
      else {
        var tmp$ret$15;
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        var tmp9_fastForEach = tmp0_safe_receiver_0.l4h_1;
        var tmp$ret$10;
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        var tmp$ret$9;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp6_unsafeCast = tmp9_fastForEach.c1d_1;
        var tmp$ret$8;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$8 = tmp6_unsafeCast;
        tmp$ret$9 = tmp$ret$8;
        tmp$ret$10 = tmp$ret$9;
        var array_0 = tmp$ret$10;
        var n_0 = 0;
        while (n_0 < array_0.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          var tmp$ret$14;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$12;
          // Inline function 'korlibs.datastructure.get' call
          var tmp0_1 = n_0;
          n_0 = tmp0_1 + 1 | 0;
          var tmp7_get = tmp0_1;
          var tmp$ret$11;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$11 = array_0;
          tmp$ret$12 = tmp$ret$11[tmp7_get];
          var tmp8_unsafeCast = tmp$ret$12;
          var tmp$ret$13;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$13 = tmp8_unsafeCast;
          tmp$ret$14 = tmp$ret$13;
          var tmp11__anonymous__tkubxh = tmp$ret$14;
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var tmp0_2 = edgesChecked;
          edgesChecked = tmp0_2 + 1 | 0;
          if (tmp11__anonymous__tkubxh.d4h(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!tmp11__anonymous__tkubxh.u4g_1) {
              this.o4i_1.u4i(tmp11__anonymous__tkubxh.e4h(y), tmp11__anonymous__tkubxh.r4g_1);
            }
          }
        }
        tmp$ret$15 = Unit_getInstance();
      }
    }
    // Inline function 'korlibs.math.geom.vector.Buckets.fastForEachY' call
    var tmp16_fastForEachY = tmp18_fastForEachY.x4h_1;
    if (tmp16_fastForEachY.l() > 0) {
      var tmp0_safe_receiver_1 = tmp16_fastForEachY.r4h(y);
      if (tmp0_safe_receiver_1 == null)
        null;
      else {
        var tmp$ret$23;
        // Inline function 'korlibs.math.geom.vector.Bucket.fastForEach' call
        var tmp15_fastForEach = tmp0_safe_receiver_1.l4h_1;
        var tmp$ret$18;
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        var tmp$ret$17;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp12_unsafeCast = tmp15_fastForEach.c1d_1;
        var tmp$ret$16;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$16 = tmp12_unsafeCast;
        tmp$ret$17 = tmp$ret$16;
        tmp$ret$18 = tmp$ret$17;
        var array_1 = tmp$ret$18;
        var n_1 = 0;
        while (n_1 < array_1.length) {
          // Inline function 'korlibs.math.geom.vector.AllBuckets.fastForEachY.<anonymous>' call
          var tmp$ret$22;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$20;
          // Inline function 'korlibs.datastructure.get' call
          var tmp0_3 = n_1;
          n_1 = tmp0_3 + 1 | 0;
          var tmp13_get = tmp0_3;
          var tmp$ret$19;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$19 = array_1;
          tmp$ret$20 = tmp$ret$19[tmp13_get];
          var tmp14_unsafeCast = tmp$ret$20;
          var tmp$ret$21;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$21 = tmp14_unsafeCast;
          tmp$ret$22 = tmp$ret$21;
          var tmp17__anonymous__gqgob = tmp$ret$22;
          // Inline function 'korlibs.math.geom.vector.PolygonScanline.forEachActiveEdgeAtY.<anonymous>' call
          var tmp0_4 = edgesChecked;
          edgesChecked = tmp0_4 + 1 | 0;
          if (tmp17__anonymous__gqgob.d4h(y)) {
            // Inline function 'korlibs.math.geom.vector.PolygonScanline.scanline.<anonymous>' call
            if (!tmp17__anonymous__gqgob.u4g_1) {
              this.o4i_1.u4i(tmp17__anonymous__gqgob.e4h(y), tmp17__anonymous__gqgob.r4g_1);
            }
          }
        }
        tmp$ret$23 = Unit_getInstance();
      }
    }
    tmp$ret$24 = edgesChecked;
    tmp.p4i_1 = tmp_0 + tmp$ret$24 | 0;
    genericSort(this.o4i_1, 0, this.o4i_1.l() - 1 | 0, IntArrayListSort_getInstance());
    var tempX = this.o4i_1.s4i_1;
    var tempW = this.o4i_1.t4i_1;
    if (this.o4i_1.l() >= 2) {
      var tmp1_subject = winding;
      var tmp0_5 = tmp1_subject.b2_1;
      if (tmp0_5 === 0) {
        var progression = step(until(0, tempX.l() - 1 | 0), 2);
        var inductionVariable = progression.p1_1;
        var last = progression.q1_1;
        var step_0 = progression.r1_1;
        if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
          do {
            var i = inductionVariable;
            inductionVariable = inductionVariable + step_0 | 0;
            var a = tempX.z18(i);
            var b = tempX.z18(i + 1 | 0);
            out.p40(a, b);
          }
           while (!(i === last));
      } else if (tmp0_5 === 1) {
        var count = 0;
        var startX = 0;
        var endX = 0;
        var pending = false;
        var inductionVariable_0 = 0;
        var last_0 = tempX.l() - 1 | 0;
        if (inductionVariable_0 < last_0)
          do {
            var i_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var a_0 = tempX.z18(i_0);
            count = count + tempW.z18(i_0) | 0;
            var b_0 = tempX.z18(i_0 + 1 | 0);
            if (!(count === 0)) {
              if (pending ? !(a_0 === endX) : false) {
                out.p40(startX, endX);
                startX = a_0;
                endX = b_0;
              } else {
                if (!pending) {
                  startX = a_0;
                }
                endX = b_0;
              }
              pending = true;
            }
          }
           while (inductionVariable_0 < last_0);
        if (pending) {
          out.p40(startX, endX);
        }
      }
    }
    return out;
  };
  protoOf(PolygonScanline).h4j = function (x, y, winding) {
    return this.i4j(this.r4i(x), this.r4i(y), winding);
  };
  protoOf(PolygonScanline).i4j = function (x, y, winding) {
    var ss = this.q4i_1;
    this.e4j(y, winding, ss.kk());
    return ss.y9(x);
  };
  function Companion_38() {
    Companion_instance_38 = this;
    this.l4j_1 = 20;
    this.m4j_1 = 0;
    this.n4j_1 = 80;
    this.o4j_1 = 320;
    this.p4j_1 = 1280;
  }
  var Companion_instance_38;
  function Companion_getInstance_38() {
    if (Companion_instance_38 == null)
      new Companion_38();
    return Companion_instance_38;
  }
  function RastScale() {
    Companion_getInstance_38();
  }
  protoOf(RastScale).j4j = function () {
    Companion_getInstance_38();
    return 20;
  };
  protoOf(RastScale).k4j = function () {
    Companion_getInstance_38();
    return 0;
  };
  protoOf(RastScale).q4j = function (_this__u8e3s4) {
    return numberToInt(_this__u8e3s4 * this.j4j()) + this.k4j() | 0;
  };
  protoOf(RastScale).r4i = function (_this__u8e3s4) {
    return numberToInt(_this__u8e3s4 * this.j4j()) + this.k4j() | 0;
  };
  function StrokeInfo(thickness, pixelHinting, scaleMode, startCap, endCap, join, miterLimit, dash, dashOffset) {
    thickness = thickness === VOID ? 1.0 : thickness;
    pixelHinting = pixelHinting === VOID ? false : pixelHinting;
    scaleMode = scaleMode === VOID ? LineScaleMode_NORMAL_getInstance() : scaleMode;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? LineCap_BUTT_getInstance() : endCap;
    join = join === VOID ? LineJoin_MITER_getInstance() : join;
    miterLimit = miterLimit === VOID ? 20.0 : miterLimit;
    dash = dash === VOID ? null : dash;
    dashOffset = dashOffset === VOID ? 0.0 : dashOffset;
    this.j49_1 = thickness;
    this.k49_1 = pixelHinting;
    this.l49_1 = scaleMode;
    this.m49_1 = startCap;
    this.n49_1 = endCap;
    this.o49_1 = join;
    this.p49_1 = miterLimit;
    this.q49_1 = dash;
    this.r49_1 = dashOffset;
  }
  protoOf(StrokeInfo).toString = function () {
    return 'StrokeInfo(thickness=' + this.j49_1 + ', pixelHinting=' + this.k49_1 + ', scaleMode=' + this.l49_1 + ', startCap=' + this.m49_1 + ', endCap=' + this.n49_1 + ', join=' + this.o49_1 + ', miterLimit=' + this.p49_1 + ', dash=' + this.q49_1 + ', dashOffset=' + this.r49_1 + ')';
  };
  protoOf(StrokeInfo).hashCode = function () {
    var result = getNumberHashCode(this.j49_1);
    result = imul(result, 31) + (this.k49_1 | 0) | 0;
    result = imul(result, 31) + this.l49_1.hashCode() | 0;
    result = imul(result, 31) + this.m49_1.hashCode() | 0;
    result = imul(result, 31) + this.n49_1.hashCode() | 0;
    result = imul(result, 31) + this.o49_1.hashCode() | 0;
    result = imul(result, 31) + getNumberHashCode(this.p49_1) | 0;
    result = imul(result, 31) + (this.q49_1 == null ? 0 : hashCode(this.q49_1)) | 0;
    result = imul(result, 31) + getNumberHashCode(this.r49_1) | 0;
    return result;
  };
  protoOf(StrokeInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StrokeInfo))
      return false;
    var tmp0_other_with_cast = other instanceof StrokeInfo ? other : THROW_CCE();
    if (!equals(this.j49_1, tmp0_other_with_cast.j49_1))
      return false;
    if (!(this.k49_1 === tmp0_other_with_cast.k49_1))
      return false;
    if (!this.l49_1.equals(tmp0_other_with_cast.l49_1))
      return false;
    if (!this.m49_1.equals(tmp0_other_with_cast.m49_1))
      return false;
    if (!this.n49_1.equals(tmp0_other_with_cast.n49_1))
      return false;
    if (!this.o49_1.equals(tmp0_other_with_cast.o49_1))
      return false;
    if (!equals(this.p49_1, tmp0_other_with_cast.p49_1))
      return false;
    if (!equals(this.q49_1, tmp0_other_with_cast.q49_1))
      return false;
    if (!equals(this.r49_1, tmp0_other_with_cast.r49_1))
      return false;
    return true;
  };
  var EdgePoint_A_instance;
  var EdgePoint_B_instance;
  var EdgePoint_entriesInitialized;
  function EdgePoint_initEntries() {
    if (EdgePoint_entriesInitialized)
      return Unit_getInstance();
    EdgePoint_entriesInitialized = true;
    EdgePoint_A_instance = new EdgePoint('A', 0, 0);
    EdgePoint_B_instance = new EdgePoint('B', 1, 1);
  }
  function _get_outFill__zcv64g($this) {
    var tmp = $this.s4j_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('outFill');
    }
  }
  function EdgePoint(name, ordinal, n) {
    Enum.call(this, name, ordinal);
    this.k4k_1 = n;
  }
  function EdgePoint_A_getInstance() {
    EdgePoint_initEntries();
    return EdgePoint_A_instance;
  }
  function EdgePoint_B_getInstance() {
    EdgePoint_initEntries();
    return EdgePoint_B_instance;
  }
  function StrokeToFill() {
    this.r4j_1 = 1;
    this.t4j_1 = LineCap_BUTT_getInstance();
    this.u4j_1 = LineCap_BUTT_getInstance();
    this.v4j_1 = LineJoin_BEVEL_getInstance();
    this.w4j_1 = 4.0;
    this.x4j_1 = new PointIntArrayList(1024);
    this.y4j_1 = new IntArrayList(1024);
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = 2;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$1;
      // Inline function 'korlibs.math.geom.vector.StrokeToFill.fillPoints.<anonymous>' call
      tmp$ret$1 = new PointIntArrayList(1024);
      tmp_2[tmp_3] = tmp$ret$1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.z4j_1 = tmp_2;
    this.a4k_1 = this.z4j_1[0];
    this.b4k_1 = this.z4j_1[1];
    this.c4k_1 = new MEdge();
    this.d4k_1 = new MEdge();
    this.e4k_1 = new MEdge();
    this.f4k_1 = new MEdge();
    this.g4k_1 = new MEdge();
    this.h4k_1 = new MEdge();
  }
  protoOf(StrokeToFill).l4k = function (_this__u8e3s4, edge, width, angle) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.setEdgeDisplaced.<anonymous>' call
    var ldx = width * _Angle___get_cosineD__impl__kykfkw(angle);
    var ldy = width * _Angle___get_sineD__impl__2ymbp0(angle);
    _this__u8e3s4.a4h(numberToInt(edge.n4g_1 + ldx), numberToInt(edge.o4g_1 + ldy), numberToInt(edge.p4g_1 + ldx), numberToInt(edge.q4g_1 + ldy), edge.r4g_1);
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0;
  };
  protoOf(StrokeToFill).m4k = function (_this__u8e3s4, e) {
    return _this__u8e3s4.p40(e.n4g_1, e.o4g_1);
  };
  protoOf(StrokeToFill).n4k = function (_this__u8e3s4, e) {
    return _this__u8e3s4.p40(e.p4g_1, e.q4g_1);
  };
  protoOf(StrokeToFill).o4k = function (_this__u8e3s4, e) {
    _this__u8e3s4.p40(numberToInt(e.p3r_1), numberToInt(e.q3r_1));
  };
  protoOf(StrokeToFill).p4k = function (out, mainPrev, mainCurr, prev, curr, join, miterLimit, scale, forcedMiter) {
    var rjoin = forcedMiter ? LineJoin_MITER_getInstance() : join;
    var tmp0_subject = rjoin;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 2:
        var intersection2 = Vector2_init_$Create$_0(mainPrev.p4g_1, mainPrev.q4g_1);
        var intersection = Companion_getInstance_35().m4g(prev, curr);
        if (!(intersection == null)) {
          var dist = Companion_getInstance_21().q43(intersection, intersection2);
          if (forcedMiter ? true : dist <= miterLimit) {
            out.q40(toInt_0(intersection));
          } else {
            this.n4k(out, prev);
            this.m4k(out, curr);
          }
        }

        break;
      case 0:
        this.n4k(out, prev);
        ;
        this.m4k(out, curr);
        ;
        break;
      case 1:
        var i = Companion_getInstance_35().m4g(prev, curr);
        if (!(i == null)) {
          var count = clamp_1(numberToInt(Companion_getInstance_21().v3w(prev.p4g_1, prev.q4g_1, curr.n4g_1, curr.o4g_1) * scale), 4, 64);
          var inductionVariable = 0;
          if (inductionVariable <= count)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              this.o4k(out, Companion_getInstance_24().j46(Vector2_init_$Create$(prev.p4g_1, prev.q4g_1), Vector2_init_$Create$(i.o3t(), i.p3t()), Vector2_init_$Create$(curr.n4g_1, curr.o4g_1), n / count));
            }
             while (!(n === count));
        } else {
          this.n4k(out, prev);
          this.m4k(out, curr);
        }

        break;
    }
  };
  protoOf(StrokeToFill).q4k = function (l, r, left, right, epoint, cap, scale) {
    var angle = epoint.equals(EdgePoint_A_getInstance()) ? Angle__unaryMinus_impl_7901fr(left.y4g()) : Angle__unaryPlus_impl_eyi5pl(left.y4g());
    var lx = left.v3z(epoint.k4k_1);
    var ly = left.u3z(epoint.k4k_1);
    var rx = right.v3z(epoint.k4k_1);
    var ry = right.u3z(epoint.k4k_1);
    var tmp0_subject = cap;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        l.p40(lx, ly);
        ;
        r.p40(rx, ry);
        ;
        break;
      case 2:
      case 1:
        var ax = numberToInt(_Angle___get_cosineD__impl__kykfkw(angle) * this.r4j_1 / 2);
        var ay = numberToInt(_Angle___get_sineD__impl__2ymbp0(angle) * this.r4j_1 / 2);
        var lx2 = lx + ax | 0;
        var ly2 = ly + ay | 0;
        var rx2 = rx + ax | 0;
        var ry2 = ry + ay | 0;
        if (cap.equals(LineCap_SQUARE_getInstance())) {
          l.p40(lx2, ly2);
          r.p40(rx2, ry2);
        } else {
          var count = clamp_1(numberToInt(Companion_getInstance_6().v3w(lx, ly, rx, ry) * scale), 4, 64);
          l.p40(lx, ly);
          var inductionVariable = 0;
          if (inductionVariable <= count)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              var m = epoint.equals(EdgePoint_A_getInstance()) ? n : count - n | 0;
              var ratio = m / count;
              this.o4k(r, Companion_getInstance_24().i46(Vector2_init_$Create$(lx, ly), Vector2_init_$Create$(lx2, ly2), Vector2_init_$Create$(rx2, ry2), Vector2_init_$Create$(rx, ry), ratio));
            }
             while (!(n === count));
        }

        break;
    }
  };
  protoOf(StrokeToFill).r4k = function (scale, closed) {
    if (this.x4j_1.p())
      return Unit_getInstance();
    var weightD2 = this.r4j_1 / 2 | 0;
    this.a4k_1.e4();
    this.b4k_1.e4();
    var sp = this.x4j_1;
    var nstrokePoints = sp.l();
    var inductionVariable = 0;
    if (inductionVariable < nstrokePoints)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var isFirst = n === 0;
        var isLast = n === (nstrokePoints - 1 | 0);
        var isMiddle = !isFirst ? !isLast ? true : closed : false;
        var n1 = isLast ? closed ? 1 : n : n + 1 | 0;
        this.c4k_1.z4g(this.f4k_1);
        this.d4k_1.z4g(this.g4k_1);
        this.e4k_1.z4g(this.h4k_1);
        var doJoin = !(this.y4j_1.z18(n) === 0);
        this.f4k_1.a4h(sp.v3z(n), sp.u3z(n), sp.v3z(n1), sp.u3z(n1), 1);
        this.l4k(this.g4k_1, this.f4k_1, weightD2, Angle__minus_impl_xeau7k(this.f4k_1.y4g(), get_degrees(90)));
        this.l4k(this.h4k_1, this.f4k_1, weightD2, Angle__plus_impl_26v37k(this.f4k_1.y4g(), get_degrees(90)));
        if (isFirst) {
          this.q4k(this.a4k_1, this.b4k_1, this.g4k_1, this.h4k_1, EdgePoint_A_getInstance(), closed ? LineCap_BUTT_getInstance() : this.t4j_1, scale);
        } else if (isMiddle) {
          var angle = Companion_getInstance_35().x4g(this.c4k_1, this.f4k_1);
          var leftAngle = Angle__compareTo_impl_a0hfds(angle, get_degrees(0)) > 0;
          if (doJoin) {
            this.p4k(this.a4k_1, this.c4k_1, this.f4k_1, this.d4k_1, this.g4k_1, this.v4j_1, this.w4j_1, scale, leftAngle);
            this.p4k(this.b4k_1, this.c4k_1, this.f4k_1, this.e4k_1, this.h4k_1, this.v4j_1, this.w4j_1, scale, !leftAngle);
          } else {
            this.m4k(this.a4k_1, this.g4k_1);
            this.m4k(this.b4k_1, this.h4k_1);
          }
        } else if (isLast) {
          if (closed) {
            this.q4k(this.a4k_1, this.b4k_1, this.g4k_1, this.h4k_1, EdgePoint_B_getInstance(), LineCap_BUTT_getInstance(), scale);
          } else {
            this.q4k(this.a4k_1, this.b4k_1, this.d4k_1, this.e4k_1, EdgePoint_B_getInstance(), this.u4j_1, scale);
          }
        }
      }
       while (inductionVariable < nstrokePoints);
    var inductionVariable_0 = 0;
    var last = this.a4k_1.l();
    if (inductionVariable_0 < last)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var x = this.a4k_1.v3z(n_0);
        var y = this.a4k_1.u3z(n_0);
        if (n_0 === 0) {
          _get_outFill__zcv64g(this).y44(new Vector2(x * scale, y * scale));
        } else {
          _get_outFill__zcv64g(this).a45(new Vector2(x * scale, y * scale));
        }
      }
       while (inductionVariable_0 < last);
    var inductionVariable_1 = 0;
    var last_0 = this.b4k_1.l();
    if (inductionVariable_1 < last_0)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var m = (this.b4k_1.l() - n_1 | 0) - 1 | 0;
        _get_outFill__zcv64g(this).a45(new Vector2(this.b4k_1.v3z(m) * scale, this.b4k_1.u3z(m) * scale));
      }
       while (inductionVariable_1 < last_0);
    _get_outFill__zcv64g(this).n1q();
    _get_outFill__zcv64g(this).z48_1 = Winding_NON_ZERO_getInstance();
    this.x4j_1.e4();
    this.y4j_1.e4();
  };
  protoOf(StrokeToFill).s4k = function (outFill, weight, startCap, endCap, joins, miterLimit) {
    this.s4j_1 = outFill;
    this.r4j_1 = weight;
    this.t4j_1 = startCap;
    this.u4j_1 = endCap;
    this.v4j_1 = joins;
    this.w4j_1 = miterLimit * weight;
  };
  protoOf(StrokeToFill).t4k = function (stroke, lineWidth, joins, startCap, endCap, miterLimit, outFill) {
    Companion_getInstance_38();
    var scale = 20;
    Companion_getInstance_38();
    var iscale = 1.0 / 20;
    this.s4k(outFill, numberToInt(lineWidth * scale), startCap, endCap, joins, miterLimit);
    // Inline function 'korlibs.math.geom.shape.emitPoints2' call
    var i = Vector2_init_$Create$_1();
    var l = Vector2_init_$Create$_1();
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
    if (false) {
      this.r4k(iscale, true);
    }
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = stroke.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = stroke.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, stroke.y48_1.n(tmp2));
        i = tmp2__anonymous__z9zvc9;
        // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
        {
          this.r4k(iscale, false);
        }
        this.x4j_1.p40(numberToInt(tmp2__anonymous__z9zvc9.o3t() * scale), numberToInt(tmp2__anonymous__z9zvc9.p3t() * scale));
        this.y4j_1.q1a(0);
        l = tmp2__anonymous__z9zvc9;
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = stroke.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, stroke.y48_1.n(tmp4));
          // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
          if (false) {
            this.r4k(iscale, false);
          }
          this.x4j_1.p40(numberToInt(tmp3__anonymous__ufb84q.o3t() * scale), numberToInt(tmp3__anonymous__ufb84q.p3t() * scale));
          this.y4j_1.q1a(0);
          l = tmp3__anonymous__ufb84q;
          // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
          this.y4j_1.w1a(this.y4j_1.l() - 1 | 0, 1);
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = stroke.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, stroke.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = stroke.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, stroke.y48_1.n(tmp8));
            var sum = Companion_getInstance_21().q43(l, tmp4__anonymous__pkmkx7) + Companion_getInstance_21().q43(tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
            // Inline function 'korlibs.math.geom.shape.approximateCurve' call
            var tmp0_approximateCurve = numberToInt(sum);
            var tmp$ret$0;
            // Inline function 'kotlin.math.max' call
            tmp$ret$0 = Math.max(tmp0_approximateCurve, 20);
            var rcurveSteps = tmp$ret$0;
            var dt = 1.0 / rcurveSteps;
            var lastPos = {_v: Vector2_init_$Create$_1()};
            var prevPos = {_v: Vector2_init_$Create$_1()};
            var emittedCount = {_v: 0};
            // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
            // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
            var tmp1__anonymous__uwfjfc_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, 0.0);
            lastPos._v = tmp1__anonymous__uwfjfc_0;
            var nStart = false ? 0 : 1;
            var nEnd = rcurveSteps;
            var inductionVariable = nStart;
            if (inductionVariable <= nEnd)
              do {
                var n_1 = inductionVariable;
                inductionVariable = inductionVariable + 1 | 0;
                var ratio = n_1 * dt;
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                var tmp2__anonymous__z9zvc9_0 = Companion_getInstance_24().j46(l, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo, ratio);
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                if (false) {
                  this.r4k(iscale, false);
                }
                this.x4j_1.p40(numberToInt(tmp2__anonymous__z9zvc9_0.o3t() * scale), numberToInt(tmp2__anonymous__z9zvc9_0.p3t() * scale));
                this.y4j_1.q1a(0);
                var tmp0_0 = emittedCount._v;
                emittedCount._v = tmp0_0 + 1 | 0;
                lastPos._v = prevPos._v;
                prevPos._v = tmp2__anonymous__z9zvc9_0;
              }
               while (!(n_1 === nEnd));
            l = tmp5__anonymous__kpxxpo;
            // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
            this.y4j_1.w1a(this.y4j_1.l() - 1 | 0, 1);
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = stroke.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, stroke.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = stroke.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, stroke.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = stroke.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, stroke.y48_1.n(tmp14));
              var sum_0 = Companion_getInstance_21().q43(l, tmp6__anonymous__fv9ai5) + Companion_getInstance_21().q43(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam) + Companion_getInstance_21().q43(tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
              // Inline function 'korlibs.math.geom.shape.approximateCurve' call
              var tmp0_approximateCurve_0 = numberToInt(sum_0);
              var tmp$ret$1;
              // Inline function 'kotlin.math.max' call
              tmp$ret$1 = Math.max(tmp0_approximateCurve_0, 20);
              var rcurveSteps_0 = tmp$ret$1;
              var dt_0 = 1.0 / rcurveSteps_0;
              var lastPos_0 = {_v: Vector2_init_$Create$_1()};
              var prevPos_0 = {_v: Vector2_init_$Create$_1()};
              var emittedCount_0 = {_v: 0};
              // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
              // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
              var tmp1__anonymous__uwfjfc_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, 0.0);
              lastPos_0._v = tmp1__anonymous__uwfjfc_1;
              var nStart_0 = false ? 0 : 1;
              var nEnd_0 = rcurveSteps_0;
              var inductionVariable_0 = nStart_0;
              if (inductionVariable_0 <= nEnd_0)
                do {
                  var n_2 = inductionVariable_0;
                  inductionVariable_0 = inductionVariable_0 + 1 | 0;
                  var ratio_0 = n_2 * dt_0;
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.shape.approximateCurve.<anonymous>' call
                  var tmp2__anonymous__z9zvc9_1 = Companion_getInstance_24().i46(l, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033, ratio_0);
                  // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>.<anonymous>' call
                  // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                  if (false) {
                    this.r4k(iscale, false);
                  }
                  this.x4j_1.p40(numberToInt(tmp2__anonymous__z9zvc9_1.o3t() * scale), numberToInt(tmp2__anonymous__z9zvc9_1.p3t() * scale));
                  this.y4j_1.q1a(0);
                  var tmp0_1 = emittedCount_0._v;
                  emittedCount_0._v = tmp0_1 + 1 | 0;
                  lastPos_0._v = prevPos_0._v;
                  prevPos_0._v = tmp2__anonymous__z9zvc9_1;
                }
                 while (!(n_2 === nEnd_0));
              l = tmp8__anonymous__65w033;
              // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
              this.y4j_1.w1a(this.y4j_1.l() - 1 | 0, 1);
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.shape.emitPoints2.<anonymous>' call
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                var tmp9__anonymous__1b7cvk = i;
                if (false) {
                  this.r4k(iscale, false);
                }
                this.x4j_1.p40(numberToInt(tmp9__anonymous__1b7cvk.o3t() * scale), numberToInt(tmp9__anonymous__1b7cvk.p3t() * scale));
                this.y4j_1.q1a(0);
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                this.y4j_1.w1a(this.y4j_1.l() - 1 | 0, 1);
                // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
                {
                  this.r4k(iscale, true);
                }
              }
            }
          }
        }
      }
    }
    // Inline function 'korlibs.math.geom.vector.StrokeToFill.strokeFill.<anonymous>' call
    if (false) {
      this.r4k(iscale, true);
    }
    this.r4k(iscale, false);
  };
  function strokeToFill(_this__u8e3s4, lineWidth, joins, startCap, endCap, miterLimit, lineDash, lineDashOffset, temp, outFill) {
    joins = joins === VOID ? LineJoin_MITER_getInstance() : joins;
    startCap = startCap === VOID ? LineCap_BUTT_getInstance() : startCap;
    endCap = endCap === VOID ? startCap : endCap;
    miterLimit = miterLimit === VOID ? 4.0 : miterLimit;
    lineDash = lineDash === VOID ? null : lineDash;
    lineDashOffset = lineDashOffset === VOID ? 0.0 : lineDashOffset;
    temp = temp === VOID ? new StrokeToFill() : temp;
    outFill = outFill === VOID ? new VectorPath(VOID, VOID, Winding_NON_ZERO_getInstance()) : outFill;
    var tmp;
    if (!(lineDash == null)) {
      var tmp$ret$5;
      // Inline function 'kotlin.collections.map' call
      var tmp$ret$2;
      // Inline function 'kotlin.collections.flatMap' call
      var tmp1_flatMap = toCurvesList(_this__u8e3s4);
      var tmp$ret$1;
      // Inline function 'kotlin.collections.flatMapTo' call
      var tmp0_flatMapTo = ArrayList_init_$Create$();
      var tmp0_iterator = tmp1_flatMap.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
        tmp$ret$0 = toDashes(element, lineDash.k1a(), lineDashOffset);
        var list = tmp$ret$0;
        addAll(tmp0_flatMapTo, list);
      }
      tmp$ret$1 = tmp0_flatMapTo;
      tmp$ret$2 = tmp$ret$1;
      var tmp3_map = tmp$ret$2;
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map, 10));
      var tmp0_iterator_0 = tmp3_map.h();
      while (tmp0_iterator_0.j()) {
        var item = tmp0_iterator_0.k();
        var tmp$ret$3;
        // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
        tmp$ret$3 = toVectorPath_0(item);
        tmp2_mapTo.b(tmp$ret$3);
      }
      tmp$ret$4 = tmp2_mapTo;
      tmp$ret$5 = tmp$ret$4;
      tmp = tmp$ret$5;
    } else {
      tmp = listOf(_this__u8e3s4);
    }
    var strokePaths = tmp;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < strokePaths.l()) {
      // Inline function 'korlibs.math.geom.vector.strokeToFill.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp4__anonymous__pkmkx7 = strokePaths.n(tmp0);
      temp.t4k(tmp4__anonymous__pkmkx7, lineWidth, joins, startCap, endCap, miterLimit, outFill);
    }
    return outFill;
  }
  function strokeToFill_0(_this__u8e3s4, info, temp, outFill) {
    temp = temp === VOID ? new StrokeToFill() : temp;
    outFill = outFill === VOID ? new VectorPath(VOID, VOID, Winding_NON_ZERO_getInstance()) : outFill;
    return strokeToFill(_this__u8e3s4, info.j49_1, info.o49_1, info.m49_1, info.n49_1, info.p49_1, info.q49_1, info.r49_1, temp, outFill);
  }
  function t($this, p) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      if (_Matrix___get_isNIL__impl__n63x5q($this.x4k_1)) {
        tmp$ret$0 = p;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo($this.x4k_1) * p.p3r_1 + _Matrix___get_c__impl__slf2ke($this.x4k_1) * p.q3r_1 + _Matrix___get_tx__impl__yc1n9($this.x4k_1), _Matrix___get_d__impl__87eht($this.x4k_1) * p.q3r_1 + _Matrix___get_b__impl__dm2icj($this.x4k_1) * p.p3r_1 + _Matrix___get_ty__impl__tryipg($this.x4k_1));
    }
    return tmp$ret$0;
  }
  function VectorBuilder$transformed$1($parent, $im, $m) {
    this.v4k_1 = $parent;
    this.w4k_1 = $im;
    this.x4k_1 = $m;
    this.u4k_1 = $parent.y4k();
  }
  protoOf(VectorBuilder$transformed$1).z44 = function () {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.math.geom.Matrix.transform' call
      var tmp0_transform = this.v4k_1.z44();
      if (_Matrix___get_isNIL__impl__n63x5q(this.w4k_1)) {
        tmp$ret$0 = tmp0_transform;
        break $l$block;
      }
      tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(this.w4k_1) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(this.w4k_1) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(this.w4k_1), _Matrix___get_d__impl__87eht(this.w4k_1) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(this.w4k_1) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(this.w4k_1));
    }
    return tmp$ret$0;
  };
  protoOf(VectorBuilder$transformed$1).y4k = function () {
    return this.u4k_1;
  };
  protoOf(VectorBuilder$transformed$1).n1q = function () {
    return this.v4k_1.n1q();
  };
  protoOf(VectorBuilder$transformed$1).a45 = function (p) {
    return this.v4k_1.a45(t(this, p));
  };
  protoOf(VectorBuilder$transformed$1).y44 = function (p) {
    return this.v4k_1.a45(t(this, p));
  };
  protoOf(VectorBuilder$transformed$1).b45 = function (c, a) {
    return this.v4k_1.b45(t(this, c), t(this, a));
  };
  protoOf(VectorBuilder$transformed$1).d45 = function (c1, c2, a) {
    return this.v4k_1.d45(t(this, c1), t(this, c2), t(this, a));
  };
  function VectorBuilder() {
  }
  var _bvhCurvesCacheVersion$delegate;
  var _bvhCurvesCache$delegate;
  function set__curvesCacheVersion(_this__u8e3s4, _set____db54di) {
    _init_properties_VectorPath_kt__1zs7iw();
    var tmp0_setValue = _curvesCacheVersion$factory();
    var tmp0_elvis_lhs = _curvesCacheVersion$delegate.f1i_1;
    var tmp = tmp0_elvis_lhs == null ? tmp0_setValue.callableName : tmp0_elvis_lhs;
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _set____db54di;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return setExtra(_this__u8e3s4, tmp, tmp$ret$2);
  }
  function get__curvesCacheVersion(_this__u8e3s4) {
    _init_properties_VectorPath_kt__1zs7iw();
    var tmp$ret$8;
    $l$block: {
      // Inline function 'korlibs.datastructure.Property.getValue' call
      var tmp2_getValue = _curvesCacheVersion$factory_0();
      var tmp$ret$4;
      // Inline function 'korlibs.datastructure.fastCastTo' call
      var tmp1_safe_receiver = _this__u8e3s4.e1i();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp$ret$1;
        // Inline function 'korlibs.datastructure.get' call
        var tmp0_elvis_lhs = _curvesCacheVersion$delegate.f1i_1;
        var tmp0_get = tmp0_elvis_lhs == null ? tmp2_getValue.callableName : tmp0_elvis_lhs;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp1_safe_receiver;
        tmp$ret$1 = tmp$ret$0.get(tmp0_get);
        tmp = tmp$ret$1;
      }
      var tmp1_fastCastTo = tmp;
      var tmp$ret$3;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp1_fastCastTo;
      tmp$ret$3 = tmp$ret$2;
      tmp$ret$4 = tmp$ret$3;
      var res = tmp$ret$4;
      if (res == null) {
        var r = _curvesCacheVersion$delegate.g1i_1();
        var tmp_0;
        if (!(r == null)) {
          tmp_0 = true;
        } else {
          var tmp2_elvis_lhs = _curvesCacheVersion$delegate.f1i_1;
          tmp_0 = hasExtra(_this__u8e3s4, tmp2_elvis_lhs == null ? tmp2_getValue.callableName : tmp2_elvis_lhs);
        }
        if (tmp_0) {
          // Inline function 'korlibs.datastructure.Property.setValue' call
          var tmp0_elvis_lhs_0 = _curvesCacheVersion$delegate.f1i_1;
          var tmp_1 = tmp0_elvis_lhs_0 == null ? tmp2_getValue.callableName : tmp0_elvis_lhs_0;
          var tmp$ret$7;
          // Inline function 'korlibs.datastructure.fastCastTo' call
          var tmp$ret$6;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$5;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$5 = r;
          tmp$ret$6 = tmp$ret$5;
          tmp$ret$7 = tmp$ret$6;
          setExtra(_this__u8e3s4, tmp_1, tmp$ret$7);
        }
        tmp$ret$8 = r;
        break $l$block;
      }
      tmp$ret$8 = res;
    }
    return tmp$ret$8;
  }
  var _curvesCacheVersion$delegate;
  function set__curvesCache(_this__u8e3s4, _set____db54di) {
    _init_properties_VectorPath_kt__1zs7iw();
    var tmp0_setValue = _curvesCache$factory();
    var tmp0_elvis_lhs = _curvesCache$delegate.f1i_1;
    var tmp = tmp0_elvis_lhs == null ? tmp0_setValue.callableName : tmp0_elvis_lhs;
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _set____db54di;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return setExtra(_this__u8e3s4, tmp, tmp$ret$2);
  }
  function get__curvesCache(_this__u8e3s4) {
    _init_properties_VectorPath_kt__1zs7iw();
    var tmp$ret$8;
    $l$block: {
      // Inline function 'korlibs.datastructure.Property.getValue' call
      var tmp2_getValue = _curvesCache$factory_0();
      var tmp$ret$4;
      // Inline function 'korlibs.datastructure.fastCastTo' call
      var tmp1_safe_receiver = _this__u8e3s4.e1i();
      var tmp;
      if (tmp1_safe_receiver == null) {
        tmp = null;
      } else {
        var tmp$ret$1;
        // Inline function 'korlibs.datastructure.get' call
        var tmp0_elvis_lhs = _curvesCache$delegate.f1i_1;
        var tmp0_get = tmp0_elvis_lhs == null ? tmp2_getValue.callableName : tmp0_elvis_lhs;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp1_safe_receiver;
        tmp$ret$1 = tmp$ret$0.get(tmp0_get);
        tmp = tmp$ret$1;
      }
      var tmp1_fastCastTo = tmp;
      var tmp$ret$3;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp1_fastCastTo;
      tmp$ret$3 = tmp$ret$2;
      tmp$ret$4 = tmp$ret$3;
      var res = tmp$ret$4;
      if (res == null) {
        var r = _curvesCache$delegate.g1i_1();
        var tmp_0;
        if (!(r == null)) {
          tmp_0 = true;
        } else {
          var tmp2_elvis_lhs = _curvesCache$delegate.f1i_1;
          tmp_0 = hasExtra(_this__u8e3s4, tmp2_elvis_lhs == null ? tmp2_getValue.callableName : tmp2_elvis_lhs);
        }
        if (tmp_0) {
          // Inline function 'korlibs.datastructure.Property.setValue' call
          var tmp0_elvis_lhs_0 = _curvesCache$delegate.f1i_1;
          var tmp_1 = tmp0_elvis_lhs_0 == null ? tmp2_getValue.callableName : tmp0_elvis_lhs_0;
          var tmp$ret$7;
          // Inline function 'korlibs.datastructure.fastCastTo' call
          var tmp$ret$6;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$5;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$5 = r;
          tmp$ret$6 = tmp$ret$5;
          tmp$ret$7 = tmp$ret$6;
          setExtra(_this__u8e3s4, tmp_1, tmp$ret$7);
        }
        tmp$ret$8 = r;
        break $l$block;
      }
      tmp$ret$8 = res;
    }
    return tmp$ret$8;
  }
  var _curvesCache$delegate;
  function Companion_39() {
    Companion_instance_39 = this;
  }
  var Companion_instance_39;
  function Companion_getInstance_39() {
    if (Companion_instance_39 == null)
      new Companion_39();
    return Companion_instance_39;
  }
  function Visitor() {
  }
  function ensureMoveTo($this, p) {
    if ($this.y1a())
      return false;
    $this.y44(p);
    return true;
  }
  function Command() {
    Command_instance = this;
    this.n4l_1 = 0;
    this.o4l_1 = 1;
    this.p4l_1 = 2;
    this.q4l_1 = 3;
    this.r4l_1 = 4;
  }
  var Command_instance;
  function Command_getInstance() {
    if (Command_instance == null)
      new Command();
    return Command_instance;
  }
  function VectorPath(commands, data, winding, optimize) {
    Companion_getInstance_39();
    commands = commands === VOID ? new IntArrayList() : commands;
    data = data === VOID ? new FloatArrayList() : data;
    winding = winding === VOID ? Companion_getInstance_40().s4l() : winding;
    optimize = optimize === VOID ? true : optimize;
    AbstractShape2D.call(this);
    this.x48_1 = commands;
    this.y48_1 = data;
    this.z48_1 = winding;
    this.a49_1 = optimize;
    this.b49_1 = new Mixin();
    this.c49_1 = false;
    this.d49_1 = 0;
    this.e49_1 = Vector2_init_$Create$_1();
    this.f49_1 = Vector2_init_$Create$_1();
    this.g49_1 = null;
    this.h49_1 = null;
  }
  protoOf(VectorPath).d1i = function (_set____db54di) {
    this.b49_1.d1i(_set____db54di);
  };
  protoOf(VectorPath).e1i = function () {
    return this.b49_1.e1i();
  };
  protoOf(VectorPath).z19 = function () {
    return new VectorPath(IntArrayList_init_$Create$(this.x48_1), FloatArrayList_init_$Create$(this.y48_1), this.z48_1);
  };
  protoOf(VectorPath).equals = function (other) {
    if (this === other)
      return true;
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof VectorPath) {
      tmp_1 = this.x48_1.equals(other.x48_1);
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.y48_1.equals(other.y48_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.z48_1.equals(other.z48_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(VectorPath).hashCode = function () {
    return (this.x48_1.hashCode() + imul(this.y48_1.hashCode(), 13) | 0) + imul(this.z48_1.b2_1, 111) | 0;
  };
  protoOf(VectorPath).t4l = function (visitor) {
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = this.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        visitor.y44(new Vector2(tmp, this.y48_1.n(tmp2)));
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = this.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          visitor.a45(new Vector2(tmp_0, this.y48_1.n(tmp4)));
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = this.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp_2 = new Vector2(tmp_1, this.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_3 = this.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            visitor.b45(tmp_2, new Vector2(tmp_3, this.y48_1.n(tmp8)));
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_4 = this.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp_5 = new Vector2(tmp_4, this.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_6 = this.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp_7 = new Vector2(tmp_6, this.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_8 = this.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              visitor.d45(tmp_5, tmp_7, new Vector2(tmp_8, this.y48_1.n(tmp14)));
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                visitor.n1q();
              }
            }
          }
        }
      }
    }
  };
  protoOf(VectorPath).e4 = function () {
    this.x48_1.e4();
    this.y48_1.e4();
    this.e49_1 = Vector2_init_$Create$_1();
    this.d49_1 = 0;
    this.u4l().z4h_1 = this.d49_1 - 1 | 0;
  };
  protoOf(VectorPath).z44 = function () {
    return this.e49_1;
  };
  protoOf(VectorPath).y44 = function (p) {
    var tmp;
    if (this.x48_1.y1a()) {
      var tmp_0 = this.x48_1.a1b();
      Command_getInstance();
      tmp = tmp_0 === 0;
    } else {
      tmp = false;
    }
    if (tmp) {
      if (this.e49_1.equals(p))
        return Unit_getInstance();
    }
    Command_getInstance();
    this.x48_1.q1a(0);
    this.y48_1.c1a(p.u43(), p.v43());
    this.e49_1 = p;
    this.f49_1 = p;
    var tmp0_this = this;
    var tmp1 = tmp0_this.d49_1;
    tmp0_this.d49_1 = tmp1 + 1 | 0;
  };
  protoOf(VectorPath).a45 = function (p) {
    if (ensureMoveTo(this, p) ? this.a49_1 : false)
      return Unit_getInstance();
    if (p.equals(this.e49_1) ? this.a49_1 : false)
      return Unit_getInstance();
    Command_getInstance();
    this.x48_1.q1a(1);
    this.y48_1.c1a(p.p3r_1, p.q3r_1);
    this.e49_1 = p;
    var tmp0_this = this;
    var tmp1 = tmp0_this.d49_1;
    tmp0_this.d49_1 = tmp1 + 1 | 0;
  };
  protoOf(VectorPath).b45 = function (c, a) {
    ensureMoveTo(this, c);
    Command_getInstance();
    this.x48_1.q1a(2);
    this.y48_1.d1a(c.p3r_1, c.q3r_1, a.p3r_1, a.q3r_1);
    this.e49_1 = a;
    var tmp0_this = this;
    var tmp1 = tmp0_this.d49_1;
    tmp0_this.d49_1 = tmp1 + 1 | 0;
  };
  protoOf(VectorPath).d45 = function (c1, c2, a) {
    ensureMoveTo(this, c1);
    Command_getInstance();
    this.x48_1.q1a(3);
    this.y48_1.e1a(c1.p3r_1, c1.q3r_1, c2.p3r_1, c2.q3r_1, a.p3r_1, a.q3r_1);
    this.e49_1 = a;
    var tmp0_this = this;
    var tmp1 = tmp0_this.d49_1;
    tmp0_this.d49_1 = tmp1 + 1 | 0;
  };
  protoOf(VectorPath).n1q = function () {
    Command_getInstance();
    this.x48_1.q1a(4);
    this.e49_1 = this.f49_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.d49_1;
    tmp0_this.d49_1 = tmp1 + 1 | 0;
  };
  protoOf(VectorPath).y4k = function () {
    return this.y48_1.l() / 2 | 0;
  };
  protoOf(VectorPath).u46 = function () {
    return _BoundsBuilder___get_bounds__impl__s1l1gx(plus_0(Companion_getInstance_1().o3s(), this));
  };
  protoOf(VectorPath).v4l = function (x, y) {
    return this.w4l().h4j(x, y, this.z48_1);
  };
  protoOf(VectorPath).e4m = function (p) {
    return this.h4j(p.o3t(), p.p3t(), this.z48_1);
  };
  protoOf(VectorPath).w4l = function () {
    if (this.g49_1 == null ? true : !(ensureNotNull(this.g49_1).x4l_1 === this.d49_1)) {
      this.g49_1 = new VectorPathTrapezoids(this.d49_1, this);
    }
    return ensureNotNull(this.g49_1);
  };
  protoOf(VectorPath).u4l = function () {
    if (this.h49_1 == null)
      this.h49_1 = new PolygonScanline();
    var scanline = ensureNotNull(this.h49_1);
    if (!(scanline.z4h_1 === this.d49_1)) {
      scanline.p1g();
      scanline.a4j(this);
      scanline.z4h_1 = this.d49_1;
    }
    return ensureNotNull(this.h49_1);
  };
  protoOf(VectorPath).h4j = function (x, y, winding) {
    return this.u4l().h4j(x, y, winding);
  };
  protoOf(VectorPath).f4m = function (path, transform) {
    var tmp0_this = this;
    tmp0_this.x48_1.u1a(path.x48_1);
    if (_Matrix___get_isIdentity__impl__oe9whb(transform)) {
      var tmp1_this = this;
      tmp1_this.y48_1.f1a(path.y48_1);
      this.e49_1 = path.e49_1;
    } else {
      var progression = step(until(0, path.y48_1.l()), 2);
      var inductionVariable = progression.p1_1;
      var last = progression.q1_1;
      var step_0 = progression.r1_1;
      if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          var tmp$ret$0;
          $l$block: {
            // Inline function 'korlibs.math.geom.Matrix.transform' call
            var tmp0_transform = new Vector2(path.y48_1.n(n + 0 | 0), path.y48_1.n(n + 1 | 0));
            if (_Matrix___get_isNIL__impl__n63x5q(transform)) {
              tmp$ret$0 = tmp0_transform;
              break $l$block;
            }
            tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(transform) * tmp0_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(transform) * tmp0_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(transform), _Matrix___get_d__impl__87eht(transform) * tmp0_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(transform) * tmp0_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(transform));
          }
          var p = tmp$ret$0;
          this.y48_1.c1a(p.p3r_1, p.q3r_1);
        }
         while (!(n === last));
      var tmp = this;
      var tmp$ret$1;
      $l$block_0: {
        // Inline function 'korlibs.math.geom.Matrix.transform' call
        var tmp1_transform = path.e49_1;
        if (_Matrix___get_isNIL__impl__n63x5q(transform)) {
          tmp$ret$1 = tmp1_transform;
          break $l$block_0;
        }
        tmp$ret$1 = new Vector2(_Matrix___get_a__impl__f7jypo(transform) * tmp1_transform.p3r_1 + _Matrix___get_c__impl__slf2ke(transform) * tmp1_transform.q3r_1 + _Matrix___get_tx__impl__yc1n9(transform), _Matrix___get_d__impl__87eht(transform) * tmp1_transform.q3r_1 + _Matrix___get_b__impl__dm2icj(transform) * tmp1_transform.p3r_1 + _Matrix___get_ty__impl__tryipg(transform));
      }
      tmp.e49_1 = tmp$ret$1;
    }
    var tmp3_this = this;
    var tmp4 = tmp3_this.d49_1;
    tmp3_this.d49_1 = tmp4 + 1 | 0;
  };
  protoOf(VectorPath).g4m = function (path, transform, $super) {
    transform = transform === VOID ? Companion_getInstance_10().v3x_1 : transform;
    var tmp;
    if ($super === VOID) {
      this.f4m(path, transform);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.f4m.call(this, path, new Matrix(transform));
    }
    return tmp;
  };
  protoOf(VectorPath).h4m = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.text.trimEnd' call
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>' call
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = this.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, this.y48_1.n(tmp2));
        var x = tmp2__anonymous__z9zvc9.c5();
        var y = tmp2__anonymous__z9zvc9.d5();
        tmp0_apply.bc('M' + get_niceStr(x) + ',' + get_niceStr(y) + ' ');
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = this.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, this.y48_1.n(tmp4));
          var x_0 = tmp3__anonymous__ufb84q.c5();
          var y_0 = tmp3__anonymous__ufb84q.d5();
          tmp0_apply.bc('L' + get_niceStr(x_0) + ',' + get_niceStr(y_0) + ' ');
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = this.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, this.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = this.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, this.y48_1.n(tmp8));
            var x1 = tmp4__anonymous__pkmkx7.c5();
            var y1 = tmp4__anonymous__pkmkx7.d5();
            var x2 = tmp5__anonymous__kpxxpo.c5();
            var y2 = tmp5__anonymous__kpxxpo.d5();
            tmp0_apply.bc('Q' + get_niceStr(x1) + ',' + get_niceStr(y1) + ',' + get_niceStr(x2) + ',' + get_niceStr(y2) + ' ');
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = this.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, this.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = this.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, this.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = this.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, this.y48_1.n(tmp14));
              var x1_0 = tmp6__anonymous__fv9ai5.c5();
              var y1_0 = tmp6__anonymous__fv9ai5.d5();
              var x2_0 = tmp7__anonymous__b0knam.c5();
              var y2_0 = tmp7__anonymous__b0knam.d5();
              var x3 = tmp8__anonymous__65w033.c5();
              var y3 = tmp8__anonymous__65w033.d5();
              tmp0_apply.bc('C' + get_niceStr(x1_0) + ',' + get_niceStr(y1_0) + ',' + get_niceStr(x2_0) + ',' + get_niceStr(y2_0) + ',' + get_niceStr(x3) + ',' + get_niceStr(y3) + ' ');
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.vector.VectorPath.toSvgString.<anonymous>.<anonymous>' call
                tmp0_apply.bc('Z ');
              }
            }
          }
        }
      }
    }
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    var tmp1_trimEnd = tmp$ret$1;
    tmp$ret$2 = toString(trimEnd(isCharSequence(tmp1_trimEnd) ? tmp1_trimEnd : THROW_CCE()));
    return tmp$ret$2;
  };
  protoOf(VectorPath).toString = function () {
    return 'VectorPath(' + this.h4m() + ')';
  };
  protoOf(VectorPath).i4m = function (sx, sy) {
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.vector.VectorPath.transformPoints' call
    var progression = step(until(0, this.y48_1.l()), 2);
    var inductionVariable = progression.p1_1;
    var last = progression.q1_1;
    var step_0 = progression.r1_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.math.geom.vector.VectorPath.scale.<anonymous>' call
        var tmp0__anonymous__q1qw7t = new Vector2(this.y48_1.n(n + 0 | 0), this.y48_1.n(n + 1 | 0));
        tmp$ret$0 = Vector2_init_$Create$(tmp0__anonymous__q1qw7t.p3r_1 * sx, tmp0__anonymous__q1qw7t.q3r_1 * sy);
        var p = tmp$ret$0;
        this.y48_1.h1a(n + 0 | 0, p.p3r_1);
        this.y48_1.h1a(n + 1 | 0, p.q3r_1);
      }
       while (!(n === last));
    var tmp1_this = this;
    var tmp2 = tmp1_this.d49_1;
    tmp1_this.d49_1 = tmp2 + 1 | 0;
    tmp$ret$1 = this;
    return tmp$ret$1;
  };
  function getCurvesList(_this__u8e3s4) {
    _init_properties_VectorPath_kt__1zs7iw();
    if (!(get__curvesCacheVersion(_this__u8e3s4) === _this__u8e3s4.d49_1)) {
      set__curvesCacheVersion(_this__u8e3s4, _this__u8e3s4.d49_1);
      var tmp$ret$2;
      // Inline function 'kotlin.also' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      var tmp0_also = tmp$ret$0;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>' call
      var currentClosed = {_v: false};
      var tmp$ret$1;
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp$ret$1 = ArrayList_init_$Create$();
      var current = {_v: tmp$ret$1};
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges' call
      var m = Vector2_init_$Create$_1();
      var l = Vector2_init_$Create$_1();
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
      var n = 0;
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var tmp0_fastForEach = _this__u8e3s4.x48_1;
      var n_0 = 0;
      while (n_0 < tmp0_fastForEach.l()) {
        // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
        var tmp0 = n_0;
        n_0 = tmp0 + 1 | 0;
        var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
        var tmp0_subject = tmp1__anonymous__uwfjfc;
        Command_getInstance();
        if (tmp0_subject === 0) {
          // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
          var tmp1 = n;
          n = tmp1 + 1 | 0;
          var tmp = _this__u8e3s4.y48_1.n(tmp1);
          var tmp2 = n;
          n = tmp2 + 1 | 0;
          var tmp2__anonymous__z9zvc9 = new Vector2(tmp, _this__u8e3s4.y48_1.n(tmp2));
          m = tmp2__anonymous__z9zvc9;
          l = tmp2__anonymous__z9zvc9;
          // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
          getCurvesList$_anonymous_$flush_1e57i7(current, tmp0_also, currentClosed, _this__u8e3s4);
        } else {
          Command_getInstance();
          if (tmp0_subject === 1) {
            // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
            var tmp3 = n;
            n = tmp3 + 1 | 0;
            var tmp_0 = _this__u8e3s4.y48_1.n(tmp3);
            var tmp4 = n;
            n = tmp4 + 1 | 0;
            var tmp3__anonymous__ufb84q = new Vector2(tmp_0, _this__u8e3s4.y48_1.n(tmp4));
            // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
            var tmp9__anonymous__1b7cvk = l;
            // Inline function 'kotlin.collections.plusAssign' call
            var tmp0_plusAssign = current._v;
            var tmp1_plusAssign = Bezier_init_$Create$(tmp9__anonymous__1b7cvk, tmp3__anonymous__ufb84q);
            tmp0_plusAssign.b(tmp1_plusAssign);
            l = tmp3__anonymous__ufb84q;
          } else {
            Command_getInstance();
            if (tmp0_subject === 2) {
              // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
              var tmp5 = n;
              n = tmp5 + 1 | 0;
              var tmp_1 = _this__u8e3s4.y48_1.n(tmp5);
              var tmp6 = n;
              n = tmp6 + 1 | 0;
              var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, _this__u8e3s4.y48_1.n(tmp6));
              var tmp7 = n;
              n = tmp7 + 1 | 0;
              var tmp_2 = _this__u8e3s4.y48_1.n(tmp7);
              var tmp8 = n;
              n = tmp8 + 1 | 0;
              var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, _this__u8e3s4.y48_1.n(tmp8));
              // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
              var tmp10__anonymous__yfiz50 = l;
              // Inline function 'kotlin.collections.plusAssign' call
              var tmp0_plusAssign_0 = current._v;
              var tmp1_plusAssign_0 = Bezier_init_$Create$_0(tmp10__anonymous__yfiz50, tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
              tmp0_plusAssign_0.b(tmp1_plusAssign_0);
              l = tmp5__anonymous__kpxxpo;
            } else {
              Command_getInstance();
              if (tmp0_subject === 3) {
                // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
                var tmp9 = n;
                n = tmp9 + 1 | 0;
                var tmp_3 = _this__u8e3s4.y48_1.n(tmp9);
                var tmp10 = n;
                n = tmp10 + 1 | 0;
                var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, _this__u8e3s4.y48_1.n(tmp10));
                var tmp11 = n;
                n = tmp11 + 1 | 0;
                var tmp_4 = _this__u8e3s4.y48_1.n(tmp11);
                var tmp12 = n;
                n = tmp12 + 1 | 0;
                var tmp7__anonymous__b0knam = new Vector2(tmp_4, _this__u8e3s4.y48_1.n(tmp12));
                var tmp13 = n;
                n = tmp13 + 1 | 0;
                var tmp_5 = _this__u8e3s4.y48_1.n(tmp13);
                var tmp14 = n;
                n = tmp14 + 1 | 0;
                var tmp8__anonymous__65w033 = new Vector2(tmp_5, _this__u8e3s4.y48_1.n(tmp14));
                // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
                var tmp11__anonymous__tkubxh = l;
                // Inline function 'kotlin.collections.plusAssign' call
                var tmp0_plusAssign_1 = current._v;
                var tmp1_plusAssign_1 = Bezier_init_$Create$_1(tmp11__anonymous__tkubxh, tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
                tmp0_plusAssign_1.b(tmp1_plusAssign_1);
                l = tmp8__anonymous__65w033;
              } else {
                Command_getInstance();
                if (tmp0_subject === 4) {
                  // Inline function 'korlibs.math.geom.vector.VectorPath.visitEdges.<anonymous>' call
                  var equal = l.g44(m);
                  if (!equal) {
                    // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
                    var tmp12__anonymous__oq5opy = l;
                    var tmp13__anonymous__jvh1if = m;
                    // Inline function 'kotlin.collections.plusAssign' call
                    var tmp0_plusAssign_2 = current._v;
                    var tmp1_plusAssign_2 = Bezier_init_$Create$(tmp12__anonymous__oq5opy, tmp13__anonymous__jvh1if);
                    tmp0_plusAssign_2.b(tmp1_plusAssign_2);
                  }
                  // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>.<anonymous>' call
                  currentClosed._v = true;
                  getCurvesList$_anonymous_$flush_1e57i7(current, tmp0_also, currentClosed, _this__u8e3s4);
                }
              }
            }
          }
        }
      }
      getCurvesList$_anonymous_$flush_1e57i7(current, tmp0_also, currentClosed, _this__u8e3s4);
      tmp$ret$2 = tmp0_also;
      set__curvesCache(_this__u8e3s4, tmp$ret$2);
    }
    return ensureNotNull(get__curvesCache(_this__u8e3s4));
  }
  function plus_0(_this__u8e3s4, path) {
    _init_properties_VectorPath_kt__1zs7iw();
    return with_0(_this__u8e3s4, path);
  }
  function VectorPathTrapezoids$trapezoidsEvenOdd$delegate$lambda(this$0) {
    return function () {
      return SegmentIntToTrapezoidIntList_getInstance().l4g(this$0.b4m_1, Winding_EVEN_ODD_getInstance());
    };
  }
  function VectorPathTrapezoids$trapezoidsNonZero$delegate$lambda(this$0) {
    return function () {
      return SegmentIntToTrapezoidIntList_getInstance().l4g(this$0.b4m_1, Winding_NON_ZERO_getInstance());
    };
  }
  function VectorPathTrapezoids(version, path, scale) {
    scale = scale === VOID ? 100 : scale;
    this.x4l_1 = version;
    this.y4l_1 = path;
    this.z4l_1 = scale;
    this.a4m_1 = imul(this.z4l_1, this.z4l_1);
    this.b4m_1 = toSegments(this.y4l_1, this.z4l_1);
    var tmp = this;
    tmp.c4m_1 = lazy(VectorPathTrapezoids$trapezoidsEvenOdd$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.d4m_1 = lazy(VectorPathTrapezoids$trapezoidsNonZero$delegate$lambda(this));
  }
  protoOf(VectorPathTrapezoids).j4m = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = trapezoidsEvenOdd$factory();
    tmp$ret$0 = this.c4m_1.f1();
    return tmp$ret$0;
  };
  protoOf(VectorPathTrapezoids).k4m = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = trapezoidsNonZero$factory();
    tmp$ret$0 = this.d4m_1.f1();
    return tmp$ret$0;
  };
  protoOf(VectorPathTrapezoids).l4m = function (winding) {
    var tmp0_subject = winding;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = this.j4m();
        break;
      case 1:
        tmp = this.k4m();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(VectorPathTrapezoids).h4j = function (x, y, winding) {
    return this.l4m(winding).d4g(toIntRound(x * this.z4l_1), toIntRound(y * this.z4l_1));
  };
  function with_0(_this__u8e3s4, path, transform) {
    transform = transform === VOID ? Companion_getInstance_10().w3x_1 : transform;
    _init_properties_VectorPath_kt__1zs7iw();
    var bb = {_v: new BoundsBuilder(_this__u8e3s4)};
    var curvesList = getCurvesList(path);
    if (curvesList.p() ? path.y1a() : false) {
      path.t4l(new with$1(bb));
    }
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < curvesList.l()) {
      // Inline function 'korlibs.math.geom.vector.with.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = curvesList.n(tmp0);
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var tmp0_fastForEach = tmp0__anonymous__q1qw7t.l48_1;
      var n_0 = 0;
      while (n_0 < tmp0_fastForEach.l()) {
        // Inline function 'korlibs.math.geom.vector.with.<anonymous>.<anonymous>' call
        var tmp0_0 = n_0;
        n_0 = tmp0_0 + 1 | 0;
        var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0_0);
        bb._v = new BoundsBuilder(BoundsBuilder__plus_impl_cz7f7x_1(bb._v.y3s_1, tmp1__anonymous__uwfjfc.v46(transform)));
      }
    }
    return bb._v.y3s_1;
  }
  function write(_this__u8e3s4, path) {
    _init_properties_VectorPath_kt__1zs7iw();
    // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds' call
    var n = 0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = path.x48_1;
    var n_0 = 0;
    while (n_0 < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.math.geom.vector.VectorPath.visitCmds.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      Command_getInstance();
      if (tmp0_subject === 0) {
        // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp = path.y48_1.n(tmp1);
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = new Vector2(tmp, path.y48_1.n(tmp2));
        _this__u8e3s4.y44(tmp2__anonymous__z9zvc9);
      } else {
        Command_getInstance();
        if (tmp0_subject === 1) {
          // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call
          var tmp3 = n;
          n = tmp3 + 1 | 0;
          var tmp_0 = path.y48_1.n(tmp3);
          var tmp4 = n;
          n = tmp4 + 1 | 0;
          var tmp3__anonymous__ufb84q = new Vector2(tmp_0, path.y48_1.n(tmp4));
          _this__u8e3s4.a45(tmp3__anonymous__ufb84q);
        } else {
          Command_getInstance();
          if (tmp0_subject === 2) {
            // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call
            var tmp5 = n;
            n = tmp5 + 1 | 0;
            var tmp_1 = path.y48_1.n(tmp5);
            var tmp6 = n;
            n = tmp6 + 1 | 0;
            var tmp4__anonymous__pkmkx7 = new Vector2(tmp_1, path.y48_1.n(tmp6));
            var tmp7 = n;
            n = tmp7 + 1 | 0;
            var tmp_2 = path.y48_1.n(tmp7);
            var tmp8 = n;
            n = tmp8 + 1 | 0;
            var tmp5__anonymous__kpxxpo = new Vector2(tmp_2, path.y48_1.n(tmp8));
            _this__u8e3s4.b45(tmp4__anonymous__pkmkx7, tmp5__anonymous__kpxxpo);
          } else {
            Command_getInstance();
            if (tmp0_subject === 3) {
              // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call
              var tmp9 = n;
              n = tmp9 + 1 | 0;
              var tmp_3 = path.y48_1.n(tmp9);
              var tmp10 = n;
              n = tmp10 + 1 | 0;
              var tmp6__anonymous__fv9ai5 = new Vector2(tmp_3, path.y48_1.n(tmp10));
              var tmp11 = n;
              n = tmp11 + 1 | 0;
              var tmp_4 = path.y48_1.n(tmp11);
              var tmp12 = n;
              n = tmp12 + 1 | 0;
              var tmp7__anonymous__b0knam = new Vector2(tmp_4, path.y48_1.n(tmp12));
              var tmp13 = n;
              n = tmp13 + 1 | 0;
              var tmp_5 = path.y48_1.n(tmp13);
              var tmp14 = n;
              n = tmp14 + 1 | 0;
              var tmp8__anonymous__65w033 = new Vector2(tmp_5, path.y48_1.n(tmp14));
              _this__u8e3s4.d45(tmp6__anonymous__fv9ai5, tmp7__anonymous__b0knam, tmp8__anonymous__65w033);
            } else {
              Command_getInstance();
              if (tmp0_subject === 4) {
                // Inline function 'korlibs.math.geom.vector.write.<anonymous>' call
                _this__u8e3s4.n1q();
              }
            }
          }
        }
      }
    }
  }
  function getCurves(_this__u8e3s4) {
    _init_properties_VectorPath_kt__1zs7iw();
    var curvesList = getCurvesList(_this__u8e3s4);
    var tmp$ret$2;
    // Inline function 'kotlin.collections.flatMap' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.flatMapTo' call
    var tmp0_flatMapTo = ArrayList_init_$Create$();
    var tmp0_iterator = curvesList.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.math.geom.vector.getCurves.<anonymous>' call
      tmp$ret$0 = element.l48_1;
      var list = tmp$ret$0;
      addAll(tmp0_flatMapTo, list);
    }
    tmp$ret$1 = tmp0_flatMapTo;
    tmp$ret$2 = tmp$ret$1;
    var tmp = tmp$ret$2;
    var tmp0_safe_receiver = lastOrNull(curvesList);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.m48_1;
    return toCurves(tmp, tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs);
  }
  function toCurvesList(_this__u8e3s4) {
    _init_properties_VectorPath_kt__1zs7iw();
    return getCurvesList(_this__u8e3s4);
  }
  function applyTransform(_this__u8e3s4, m) {
    _init_properties_VectorPath_kt__1zs7iw();
    var tmp;
    if (_Matrix___get_isNotNIL__impl__wjqa0f(m)) {
      var tmp$ret$2;
      // Inline function 'korlibs.math.geom.vector.VectorPath.transformPoints' call
      var progression = step(until(0, _this__u8e3s4.y48_1.l()), 2);
      var inductionVariable = progression.p1_1;
      var last = progression.q1_1;
      var step_0 = progression.r1_1;
      if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + step_0 | 0;
          var tmp$ret$1;
          // Inline function 'korlibs.math.geom.vector.applyTransform.<anonymous>' call
          var tmp0__anonymous__q1qw7t = new Vector2(_this__u8e3s4.y48_1.n(n + 0 | 0), _this__u8e3s4.y48_1.n(n + 1 | 0));
          var tmp$ret$0;
          $l$block: {
            // Inline function 'korlibs.math.geom.Matrix.transform' call
            if (_Matrix___get_isNIL__impl__n63x5q(m)) {
              tmp$ret$0 = tmp0__anonymous__q1qw7t;
              break $l$block;
            }
            tmp$ret$0 = new Vector2(_Matrix___get_a__impl__f7jypo(m) * tmp0__anonymous__q1qw7t.p3r_1 + _Matrix___get_c__impl__slf2ke(m) * tmp0__anonymous__q1qw7t.q3r_1 + _Matrix___get_tx__impl__yc1n9(m), _Matrix___get_d__impl__87eht(m) * tmp0__anonymous__q1qw7t.q3r_1 + _Matrix___get_b__impl__dm2icj(m) * tmp0__anonymous__q1qw7t.p3r_1 + _Matrix___get_ty__impl__tryipg(m));
          }
          tmp$ret$1 = tmp$ret$0;
          var p = tmp$ret$1;
          _this__u8e3s4.y48_1.h1a(n + 0 | 0, p.p3r_1);
          _this__u8e3s4.y48_1.h1a(n + 1 | 0, p.q3r_1);
        }
         while (!(n === last));
      var tmp1_this = _this__u8e3s4;
      var tmp2 = tmp1_this.d49_1;
      tmp1_this.d49_1 = tmp2 + 1 | 0;
      tmp$ret$2 = _this__u8e3s4;
      tmp = tmp$ret$2;
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  function getCurvesList$_anonymous_$flush_1e57i7(current, $out, currentClosed, $this_getCurvesList) {
    if (current._v.p())
      return Unit_getInstance();
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new Curves(current._v, currentClosed._v);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.geom.vector.getCurvesList.<anonymous>$flush.<anonymous>' call
    tmp0_also.o48_1 = $this_getCurvesList.c49_1;
    tmp$ret$0 = tmp0_also;
    $out.b(tmp$ret$0);
    currentClosed._v = false;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$1 = ArrayList_init_$Create$();
    current._v = tmp$ret$1;
  }
  function _bvhCurvesCacheVersion$delegate$lambda() {
    _init_properties_VectorPath_kt__1zs7iw();
    return -1;
  }
  function _bvhCurvesCache$delegate$lambda() {
    _init_properties_VectorPath_kt__1zs7iw();
    return null;
  }
  function _curvesCacheVersion$delegate$lambda() {
    _init_properties_VectorPath_kt__1zs7iw();
    return -1;
  }
  function _curvesCache$delegate$lambda() {
    _init_properties_VectorPath_kt__1zs7iw();
    return null;
  }
  function with$1($bb) {
    this.m4m_1 = $bb;
  }
  protoOf(with$1).y44 = function (p) {
    this.m4m_1._v = new BoundsBuilder(BoundsBuilder__plus_impl_cz7f7x(this.m4m_1._v.y3s_1, p));
  };
  function _curvesCacheVersion$factory() {
    return getPropertyCallableRef('_curvesCacheVersion', 1, KMutableProperty1, function (receiver) {
      return get__curvesCacheVersion(receiver);
    }, function (receiver, value) {
      return set__curvesCacheVersion(receiver, value);
    });
  }
  function _curvesCacheVersion$factory_0() {
    return getPropertyCallableRef('_curvesCacheVersion', 1, KMutableProperty1, function (receiver) {
      return get__curvesCacheVersion(receiver);
    }, function (receiver, value) {
      return set__curvesCacheVersion(receiver, value);
    });
  }
  function _curvesCache$factory() {
    return getPropertyCallableRef('_curvesCache', 1, KMutableProperty1, function (receiver) {
      return get__curvesCache(receiver);
    }, function (receiver, value) {
      return set__curvesCache(receiver, value);
    });
  }
  function _curvesCache$factory_0() {
    return getPropertyCallableRef('_curvesCache', 1, KMutableProperty1, function (receiver) {
      return get__curvesCache(receiver);
    }, function (receiver, value) {
      return set__curvesCache(receiver, value);
    });
  }
  function trapezoidsEvenOdd$factory() {
    return getPropertyCallableRef('trapezoidsEvenOdd', 1, KProperty1, function (receiver) {
      return receiver.j4m();
    }, null);
  }
  function trapezoidsNonZero$factory() {
    return getPropertyCallableRef('trapezoidsNonZero', 1, KProperty1, function (receiver) {
      return receiver.k4m();
    }, null);
  }
  var properties_initialized_VectorPath_kt_o4oo3u;
  function _init_properties_VectorPath_kt__1zs7iw() {
    if (properties_initialized_VectorPath_kt_o4oo3u) {
    } else {
      properties_initialized_VectorPath_kt_o4oo3u = true;
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.extraProperty' call
      var tmp0_extraProperty = _bvhCurvesCacheVersion$delegate$lambda;
      tmp$ret$0 = new Property(null, tmp0_extraProperty);
      _bvhCurvesCacheVersion$delegate = tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'korlibs.datastructure.extraProperty' call
      var tmp0_extraProperty_0 = _bvhCurvesCache$delegate$lambda;
      tmp$ret$0_0 = new Property(null, tmp0_extraProperty_0);
      _bvhCurvesCache$delegate = tmp$ret$0_0;
      var tmp$ret$0_1;
      // Inline function 'korlibs.datastructure.extraProperty' call
      var tmp0_extraProperty_1 = _curvesCacheVersion$delegate$lambda;
      tmp$ret$0_1 = new Property(null, tmp0_extraProperty_1);
      _curvesCacheVersion$delegate = tmp$ret$0_1;
      var tmp$ret$0_2;
      // Inline function 'korlibs.datastructure.extraProperty' call
      var tmp0_extraProperty_2 = _curvesCache$delegate$lambda;
      tmp$ret$0_2 = new Property(null, tmp0_extraProperty_2);
      _curvesCache$delegate = tmp$ret$0_2;
    }
  }
  var Winding_EVEN_ODD_instance;
  var Winding_NON_ZERO_instance;
  function Companion_40() {
    Companion_instance_40 = this;
  }
  protoOf(Companion_40).s4l = function () {
    return Winding_NON_ZERO_getInstance();
  };
  var Companion_instance_40;
  function Companion_getInstance_40() {
    Winding_initEntries();
    if (Companion_instance_40 == null)
      new Companion_40();
    return Companion_instance_40;
  }
  var Winding_entriesInitialized;
  function Winding_initEntries() {
    if (Winding_entriesInitialized)
      return Unit_getInstance();
    Winding_entriesInitialized = true;
    Winding_EVEN_ODD_instance = new Winding('EVEN_ODD', 0, 'evenOdd');
    Winding_NON_ZERO_instance = new Winding('NON_ZERO', 1, 'nonZero');
    Companion_getInstance_40();
  }
  function Winding(name, ordinal, str) {
    Enum.call(this, name, ordinal);
    this.p4m_1 = str;
  }
  function Winding_EVEN_ODD_getInstance() {
    Winding_initEntries();
    return Winding_EVEN_ODD_instance;
  }
  function Winding_NON_ZERO_getInstance() {
    Winding_initEntries();
    return Winding_NON_ZERO_instance;
  }
  function get_niceStr(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.internal.<get-niceStr>.<anonymous>' call
    appendNice(tmp0_apply, _this__u8e3s4);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  }
  function appendNice(_this__u8e3s4, value) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.round' call
    tmp$ret$0 = round(value);
    if (isAlmostEquals(tmp$ret$0, value))
      if (value >= IntCompanionObject_getInstance().MIN_VALUE ? value <= IntCompanionObject_getInstance().MAX_VALUE : false) {
        _this__u8e3s4.ac(numberToInt(value));
      } else {
        _this__u8e3s4.ac(numberToLong(value));
      }
     else {
      _this__u8e3s4.ac(value);
    }
  }
  function umod_0(_this__u8e3s4, other) {
    var remainder = _this__u8e3s4 % other;
    return remainder < 0 ? remainder + other : remainder;
  }
  function get_niceStr_0(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.internal.<get-niceStr>.<anonymous>' call
    appendNice_0(tmp0_apply, _this__u8e3s4);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  }
  function appendNice_0(_this__u8e3s4, value) {
    if (isAlmostEquals_0(round(value), value))
      if (value >= IntCompanionObject_getInstance().MIN_VALUE ? value <= IntCompanionObject_getInstance().MAX_VALUE : false) {
        _this__u8e3s4.ac(numberToInt(value));
      } else {
        _this__u8e3s4.ac(numberToLong(value));
      }
     else {
      _this__u8e3s4.ac(value);
    }
  }
  function floorCeil(v) {
    var tmp;
    if (v < 0.0) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.ceil' call
      tmp$ret$0 = Math.ceil(v);
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.math.floor' call
      tmp$ret$1 = Math.floor(v);
      tmp = tmp$ret$1;
    }
    return tmp;
  }
  function _get__ALL_LIST__dej8hi($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _ALL_LIST$factory();
    tmp$ret$0 = $this.q4m_1.f1();
    return tmp$ret$0;
  }
  function Easing$Companion$_ALL_LIST$delegate$lambda() {
    return toList(values_0());
  }
  function Easing$Companion$ALL$delegate$lambda() {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.associateBy' call
    var tmp1_associateBy = _get__ALL_LIST__dej8hi(Companion_getInstance_41());
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp1_associateBy, 10)), 16);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp0_associateByTo = LinkedHashMap_init_$Create$(capacity);
    var tmp0_iterator = tmp1_associateBy.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.math.interpolation.Companion.ALL$delegate.<anonymous>.<anonymous>' call
      tmp$ret$0 = element.a2_1;
      tmp0_associateByTo.e5(tmp$ret$0, element);
    }
    tmp$ret$1 = tmp0_associateByTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function Companion_41() {
    Companion_instance_41 = this;
    var tmp = this;
    var tmp_0 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp.q4m_1 = lazy_0(tmp_0, Easing$Companion$_ALL_LIST$delegate$lambda);
    var tmp_1 = this;
    var tmp_2 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_1.r4m_1 = lazy_0(tmp_2, Easing$Companion$ALL$delegate$lambda);
  }
  protoOf(Companion_41).s4m = function () {
    return Easings_LINEAR_getInstance();
  };
  protoOf(Companion_41).t4m = function () {
    return Easings_EASE_getInstance();
  };
  protoOf(Companion_41).u4m = function () {
    return Easings_EASE_IN_getInstance();
  };
  protoOf(Companion_41).v4m = function () {
    return Easings_EASE_IN_OUT_QUAD_getInstance();
  };
  var Companion_instance_41;
  function Companion_getInstance_41() {
    if (Companion_instance_41 == null)
      new Companion_41();
    return Companion_instance_41;
  }
  function Easing() {
  }
  function EasingCubic(x1, y1, x2, y2, name) {
    name = name === VOID ? null : name;
    this.y4m_1 = x1;
    this.z4m_1 = y1;
    this.a4n_1 = x2;
    this.b4n_1 = y2;
    this.c4n_1 = name;
    this.d4n_1 = Bezier_init_$Create$_1(new Vector2(0.0, 0.0), new Vector2(clamp(this.y4m_1, 0.0, 1.0), this.z4m_1), new Vector2(clamp(this.a4n_1, 0.0, 1.0), this.b4n_1), Vector2_init_$Create$(1.0, 1.0));
  }
  protoOf(EasingCubic).toString = function () {
    var tmp0_elvis_lhs = this.c4n_1;
    return tmp0_elvis_lhs == null ? 'cubic-bezier(' + this.y4m_1 + ', ' + this.z4m_1 + ', ' + this.a4n_1 + ', ' + this.b4n_1 + ')' : tmp0_elvis_lhs;
  };
  protoOf(EasingCubic).w4m = function (it) {
    var pivotLeft = it < 0.0 ? it * 10.0 : 0.0;
    var pivotRight = it > 1.0 ? it * 10.0 : 1.0;
    var pivot = it;
    var lastX = 0.0;
    var lastY = 0.0;
    var steps = 0;
    var inductionVariable = 0;
    if (inductionVariable < 50)
      $l$loop_0: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1 = steps;
        steps = tmp1 + 1 | 0;
        var res = this.d4n_1.w46(pivot);
        lastX = res.p3r_1;
        lastY = res.q3r_1;
        var tmp$ret$0;
        // Inline function 'kotlin.math.absoluteValue' call
        var tmp0__get_absoluteValue__nukmtt = lastX - it;
        tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
        if (tmp$ret$0 < 0.001)
          break $l$loop_0;
        if (it < lastX) {
          pivotRight = pivot;
          pivot = (pivotLeft + pivot) * 0.5;
        } else if (it > lastX) {
          pivotLeft = pivot;
          pivot = (pivotRight + pivot) * 0.5;
        } else {
          break $l$loop_0;
        }
      }
       while (inductionVariable < 50);
    return lastY;
  };
  function Easings$SMOOTH() {
    Easings.call(this, 'SMOOTH', 0);
    Easings_SMOOTH_instance = this;
  }
  protoOf(Easings$SMOOTH).w4m = function (it) {
    return it * it * (3 - 2 * it);
  };
  var Easings_SMOOTH_instance;
  function Easings$EASE_IN_ELASTIC() {
    Easings.call(this, 'EASE_IN_ELASTIC', 1);
    Easings_EASE_IN_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_IN_ELASTIC).w4m = function (it) {
    var tmp;
    switch (it) {
      case 0.0:
      case 1.0:
        tmp = it;
        break;
      default:
        var p = 0.3;
        var s = p / 4.0;
        var inv = it - 1;
        var tmp$ret$0;
        // Inline function 'kotlin.math.pow' call
        var tmp0_pow = 10.0 * inv;
        tmp$ret$0 = Math.pow(2.0, tmp0_pow);

        var tmp_0 = -1.0 * tmp$ret$0;
        var tmp$ret$1;
        // Inline function 'kotlin.math.sin' call
        var tmp1_sin = (inv - s) * (2.0 * get_PI()) / p;
        tmp$ret$1 = Math.sin(tmp1_sin);

        tmp = tmp_0 * tmp$ret$1;
        break;
    }
    return tmp;
  };
  var Easings_EASE_IN_ELASTIC_instance;
  function Easings$EASE_OUT_ELASTIC() {
    Easings.call(this, 'EASE_OUT_ELASTIC', 2);
    Easings_EASE_OUT_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_OUT_ELASTIC).w4m = function (it) {
    var tmp;
    switch (it) {
      case 0.0:
      case 1.0:
        tmp = it;
        break;
      default:
        var p = 0.3;
        var s = p / 4.0;
        var tmp$ret$0;
        // Inline function 'kotlin.math.pow' call
        var tmp0_pow = -10.0 * it;
        tmp$ret$0 = Math.pow(2.0, tmp0_pow);

        var tmp_0 = tmp$ret$0;
        var tmp$ret$1;
        // Inline function 'kotlin.math.sin' call
        var tmp1_sin = (it - s) * (2.0 * get_PI()) / p;
        tmp$ret$1 = Math.sin(tmp1_sin);

        tmp = tmp_0 * tmp$ret$1 + 1;
        break;
    }
    return tmp;
  };
  var Easings_EASE_OUT_ELASTIC_instance;
  function Easings$EASE_OUT_BOUNCE() {
    Easings.call(this, 'EASE_OUT_BOUNCE', 3);
    Easings_EASE_OUT_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_OUT_BOUNCE).w4m = function (it) {
    var s = 7.5625;
    var p = 2.75;
    var tmp;
    if (it < 1.0 / p) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.pow' call
      tmp$ret$0 = Math.pow(it, 2.0);
      tmp = s * tmp$ret$0;
    } else if (it < 2.0 / p) {
      var tmp$ret$1;
      // Inline function 'kotlin.math.pow' call
      var tmp0_pow = it - 1.5 / p;
      tmp$ret$1 = Math.pow(tmp0_pow, 2.0);
      tmp = s * tmp$ret$1 + 0.75;
    } else if (it < 2.5 / p) {
      var tmp$ret$2;
      // Inline function 'kotlin.math.pow' call
      var tmp1_pow = it - 2.25 / p;
      tmp$ret$2 = Math.pow(tmp1_pow, 2.0);
      tmp = s * tmp$ret$2 + 0.9375;
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.math.pow' call
      var tmp2_pow = it - 2.625 / p;
      tmp$ret$3 = Math.pow(tmp2_pow, 2.0);
      tmp = s * tmp$ret$3 + 0.984375;
    }
    return tmp;
  };
  var Easings_EASE_OUT_BOUNCE_instance;
  function Easings$LINEAR() {
    Easings.call(this, 'LINEAR', 4);
    Easings_LINEAR_instance = this;
  }
  protoOf(Easings$LINEAR).w4m = function (it) {
    return it;
  };
  var Easings_LINEAR_instance;
  function Easings$EASE() {
    Easings.call(this, 'EASE', 5);
    Easings_EASE_instance = this;
    this.q4n_1 = new EasingCubic(0.25, 0.1, 0.25, 1.0, 'ease');
  }
  protoOf(Easings$EASE).w4m = function (it) {
    return this.q4n_1.w4m(it);
  };
  var Easings_EASE_instance;
  function Easings$EASE_IN() {
    Easings.call(this, 'EASE_IN', 6);
    Easings_EASE_IN_instance = this;
    this.t4n_1 = new EasingCubic(0.42, 0.0, 1.0, 1.0, 'ease-in');
  }
  protoOf(Easings$EASE_IN).w4m = function (it) {
    return this.t4n_1.w4m(it);
  };
  var Easings_EASE_IN_instance;
  function Easings$EASE_OUT() {
    Easings.call(this, 'EASE_OUT', 7);
    Easings_EASE_OUT_instance = this;
    this.w4n_1 = new EasingCubic(0.0, 0.0, 0.58, 1.0, 'ease-out');
  }
  protoOf(Easings$EASE_OUT).w4m = function (it) {
    return this.w4n_1.w4m(it);
  };
  var Easings_EASE_OUT_instance;
  function Easings$EASE_IN_OUT() {
    Easings.call(this, 'EASE_IN_OUT', 8);
    Easings_EASE_IN_OUT_instance = this;
    this.z4n_1 = new EasingCubic(0.42, 0.0, 0.58, 1.0, 'ease-in-out');
  }
  protoOf(Easings$EASE_IN_OUT).w4m = function (it) {
    return this.z4n_1.w4m(it);
  };
  var Easings_EASE_IN_OUT_instance;
  function Easings$EASE_IN_OLD() {
    Easings.call(this, 'EASE_IN_OLD', 9);
    Easings_EASE_IN_OLD_instance = this;
  }
  protoOf(Easings$EASE_IN_OLD).w4m = function (it) {
    return it * it * it;
  };
  var Easings_EASE_IN_OLD_instance;
  function Easings$EASE_OUT_OLD() {
    Easings.call(this, 'EASE_OUT_OLD', 10);
    Easings_EASE_OUT_OLD_instance = this;
  }
  protoOf(Easings$EASE_OUT_OLD).w4m = function (it) {
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = it - 1.0;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.EASE_OUT_OLD.invoke.<anonymous>' call
    tmp$ret$0 = tmp0_let * tmp0_let * tmp0_let + 1;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  var Easings_EASE_OUT_OLD_instance;
  function Easings$EASE_IN_OUT_OLD() {
    Easings.call(this, 'EASE_IN_OUT_OLD', 11);
    Easings_EASE_IN_OUT_OLD_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_OLD).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_IN_OLD_getInstance();
    var tmp1_combine = Easings_EASE_OUT_OLD_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_IN_OUT_OLD_instance;
  function Easings$EASE_OUT_IN_OLD() {
    Easings.call(this, 'EASE_OUT_IN_OLD', 12);
    Easings_EASE_OUT_IN_OLD_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_OLD).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_OUT_OLD_getInstance();
    var tmp1_combine = Easings_EASE_IN_OLD_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_OUT_IN_OLD_instance;
  function Easings$EASE_IN_BACK() {
    Easings.call(this, 'EASE_IN_BACK', 13);
    Easings_EASE_IN_BACK_instance = this;
  }
  protoOf(Easings$EASE_IN_BACK).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    tmp$ret$0 = Math.pow(it, 2.0);
    return tmp$ret$0 * ((1.70158 + 1.0) * it - 1.70158);
  };
  var Easings_EASE_IN_BACK_instance;
  function Easings$EASE_OUT_BACK() {
    Easings.call(this, 'EASE_OUT_BACK', 14);
    Easings_EASE_OUT_BACK_instance = this;
  }
  protoOf(Easings$EASE_OUT_BACK).w4m = function (it) {
    var tmp$ret$2;
    // Inline function 'kotlin.let' call
    var tmp0_let = it - 1.0;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'korlibs.math.interpolation.EASE_OUT_BACK.invoke.<anonymous>' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    tmp$ret$0 = Math.pow(tmp0_let, 2.0);
    tmp$ret$1 = tmp$ret$0 * ((1.70158 + 1.0) * tmp0_let + 1.70158) + 1.0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  var Easings_EASE_OUT_BACK_instance;
  function Easings$EASE_IN_OUT_BACK() {
    Easings.call(this, 'EASE_IN_OUT_BACK', 15);
    Easings_EASE_IN_OUT_BACK_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_BACK).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_IN_BACK_getInstance();
    var tmp1_combine = Easings_EASE_OUT_BACK_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_IN_OUT_BACK_instance;
  function Easings$EASE_OUT_IN_BACK() {
    Easings.call(this, 'EASE_OUT_IN_BACK', 16);
    Easings_EASE_OUT_IN_BACK_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_BACK).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_OUT_BACK_getInstance();
    var tmp1_combine = Easings_EASE_IN_BACK_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_OUT_IN_BACK_instance;
  function Easings$EASE_IN_OUT_ELASTIC() {
    Easings.call(this, 'EASE_IN_OUT_ELASTIC', 17);
    Easings_EASE_IN_OUT_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_ELASTIC).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_IN_ELASTIC_getInstance();
    var tmp1_combine = Easings_EASE_OUT_ELASTIC_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_IN_OUT_ELASTIC_instance;
  function Easings$EASE_OUT_IN_ELASTIC() {
    Easings.call(this, 'EASE_OUT_IN_ELASTIC', 18);
    Easings_EASE_OUT_IN_ELASTIC_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_ELASTIC).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_OUT_ELASTIC_getInstance();
    var tmp1_combine = Easings_EASE_IN_ELASTIC_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_OUT_IN_ELASTIC_instance;
  function Easings$EASE_IN_BOUNCE() {
    Easings.call(this, 'EASE_IN_BOUNCE', 19);
    Easings_EASE_IN_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_IN_BOUNCE).w4m = function (it) {
    return 1.0 - Easings_EASE_OUT_BOUNCE_getInstance().w4m(1.0 - it);
  };
  var Easings_EASE_IN_BOUNCE_instance;
  function Easings$EASE_IN_OUT_BOUNCE() {
    Easings.call(this, 'EASE_IN_OUT_BOUNCE', 20);
    Easings_EASE_IN_OUT_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_BOUNCE).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_IN_BOUNCE_getInstance();
    var tmp1_combine = Easings_EASE_OUT_BOUNCE_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_IN_OUT_BOUNCE_instance;
  function Easings$EASE_OUT_IN_BOUNCE() {
    Easings.call(this, 'EASE_OUT_IN_BOUNCE', 21);
    Easings_EASE_OUT_IN_BOUNCE_instance = this;
  }
  protoOf(Easings$EASE_OUT_IN_BOUNCE).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.combine' call
    var tmp0_combine = Easings_EASE_OUT_BOUNCE_getInstance();
    var tmp1_combine = Easings_EASE_IN_BOUNCE_getInstance();
    tmp$ret$0 = it < 0.5 ? 0.5 * tmp0_combine.w4m(it * 2.0) : 0.5 * tmp1_combine.w4m((it - 0.5) * 2.0) + 0.5;
    return tmp$ret$0;
  };
  var Easings_EASE_OUT_IN_BOUNCE_instance;
  function Easings$EASE_IN_QUAD() {
    Easings.call(this, 'EASE_IN_QUAD', 22);
    Easings_EASE_IN_QUAD_instance = this;
  }
  protoOf(Easings$EASE_IN_QUAD).w4m = function (it) {
    return 1.0 * it * it;
  };
  var Easings_EASE_IN_QUAD_instance;
  function Easings$EASE_OUT_QUAD() {
    Easings.call(this, 'EASE_OUT_QUAD', 23);
    Easings_EASE_OUT_QUAD_instance = this;
  }
  protoOf(Easings$EASE_OUT_QUAD).w4m = function (it) {
    return -1.0 * it * (it - 2);
  };
  var Easings_EASE_OUT_QUAD_instance;
  function Easings$EASE_IN_OUT_QUAD() {
    Easings.call(this, 'EASE_IN_OUT_QUAD', 24);
    Easings_EASE_IN_OUT_QUAD_instance = this;
  }
  protoOf(Easings$EASE_IN_OUT_QUAD).w4m = function (it) {
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = it * 2.0;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.math.interpolation.EASE_IN_OUT_QUAD.invoke.<anonymous>' call
    var tmp;
    if (tmp0_let < 1) {
      tmp = 0.5 * tmp0_let * tmp0_let;
    } else {
      tmp = -0.5 * ((tmp0_let - 1) * (tmp0_let - 1 - 2) - 1);
    }
    tmp$ret$0 = tmp;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  var Easings_EASE_IN_OUT_QUAD_instance;
  function Easings$EASE_SINE() {
    Easings.call(this, 'EASE_SINE', 25);
    Easings_EASE_SINE_instance = this;
  }
  protoOf(Easings$EASE_SINE).w4m = function (it) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sin' call
    var tmp0_sin = it * 1.5707964;
    tmp$ret$0 = Math.sin(tmp0_sin);
    return tmp$ret$0;
  };
  var Easings_EASE_SINE_instance;
  function Easings$EASE_CLAMP_START() {
    Easings.call(this, 'EASE_CLAMP_START', 26);
    Easings_EASE_CLAMP_START_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_START).w4m = function (it) {
    return it <= 0.0 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_START_instance;
  function Easings$EASE_CLAMP_END() {
    Easings.call(this, 'EASE_CLAMP_END', 27);
    Easings_EASE_CLAMP_END_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_END).w4m = function (it) {
    return it < 1.0 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_END_instance;
  function Easings$EASE_CLAMP_MIDDLE() {
    Easings.call(this, 'EASE_CLAMP_MIDDLE', 28);
    Easings_EASE_CLAMP_MIDDLE_instance = this;
  }
  protoOf(Easings$EASE_CLAMP_MIDDLE).w4m = function (it) {
    return it < 0.5 ? 0.0 : 1.0;
  };
  var Easings_EASE_CLAMP_MIDDLE_instance;
  function values_0() {
    return [Easings_SMOOTH_getInstance(), Easings_EASE_IN_ELASTIC_getInstance(), Easings_EASE_OUT_ELASTIC_getInstance(), Easings_EASE_OUT_BOUNCE_getInstance(), Easings_LINEAR_getInstance(), Easings_EASE_getInstance(), Easings_EASE_IN_getInstance(), Easings_EASE_OUT_getInstance(), Easings_EASE_IN_OUT_getInstance(), Easings_EASE_IN_OLD_getInstance(), Easings_EASE_OUT_OLD_getInstance(), Easings_EASE_IN_OUT_OLD_getInstance(), Easings_EASE_OUT_IN_OLD_getInstance(), Easings_EASE_IN_BACK_getInstance(), Easings_EASE_OUT_BACK_getInstance(), Easings_EASE_IN_OUT_BACK_getInstance(), Easings_EASE_OUT_IN_BACK_getInstance(), Easings_EASE_IN_OUT_ELASTIC_getInstance(), Easings_EASE_OUT_IN_ELASTIC_getInstance(), Easings_EASE_IN_BOUNCE_getInstance(), Easings_EASE_IN_OUT_BOUNCE_getInstance(), Easings_EASE_OUT_IN_BOUNCE_getInstance(), Easings_EASE_IN_QUAD_getInstance(), Easings_EASE_OUT_QUAD_getInstance(), Easings_EASE_IN_OUT_QUAD_getInstance(), Easings_EASE_SINE_getInstance(), Easings_EASE_CLAMP_START_getInstance(), Easings_EASE_CLAMP_END_getInstance(), Easings_EASE_CLAMP_MIDDLE_getInstance()];
  }
  var Easings_entriesInitialized;
  function Easings_initEntries() {
    if (Easings_entriesInitialized)
      return Unit_getInstance();
    Easings_entriesInitialized = true;
    Easings_SMOOTH_instance = new Easings$SMOOTH();
    Easings_EASE_IN_ELASTIC_instance = new Easings$EASE_IN_ELASTIC();
    Easings_EASE_OUT_ELASTIC_instance = new Easings$EASE_OUT_ELASTIC();
    Easings_EASE_OUT_BOUNCE_instance = new Easings$EASE_OUT_BOUNCE();
    Easings_LINEAR_instance = new Easings$LINEAR();
    Easings_EASE_instance = new Easings$EASE();
    Easings_EASE_IN_instance = new Easings$EASE_IN();
    Easings_EASE_OUT_instance = new Easings$EASE_OUT();
    Easings_EASE_IN_OUT_instance = new Easings$EASE_IN_OUT();
    Easings_EASE_IN_OLD_instance = new Easings$EASE_IN_OLD();
    Easings_EASE_OUT_OLD_instance = new Easings$EASE_OUT_OLD();
    Easings_EASE_IN_OUT_OLD_instance = new Easings$EASE_IN_OUT_OLD();
    Easings_EASE_OUT_IN_OLD_instance = new Easings$EASE_OUT_IN_OLD();
    Easings_EASE_IN_BACK_instance = new Easings$EASE_IN_BACK();
    Easings_EASE_OUT_BACK_instance = new Easings$EASE_OUT_BACK();
    Easings_EASE_IN_OUT_BACK_instance = new Easings$EASE_IN_OUT_BACK();
    Easings_EASE_OUT_IN_BACK_instance = new Easings$EASE_OUT_IN_BACK();
    Easings_EASE_IN_OUT_ELASTIC_instance = new Easings$EASE_IN_OUT_ELASTIC();
    Easings_EASE_OUT_IN_ELASTIC_instance = new Easings$EASE_OUT_IN_ELASTIC();
    Easings_EASE_IN_BOUNCE_instance = new Easings$EASE_IN_BOUNCE();
    Easings_EASE_IN_OUT_BOUNCE_instance = new Easings$EASE_IN_OUT_BOUNCE();
    Easings_EASE_OUT_IN_BOUNCE_instance = new Easings$EASE_OUT_IN_BOUNCE();
    Easings_EASE_IN_QUAD_instance = new Easings$EASE_IN_QUAD();
    Easings_EASE_OUT_QUAD_instance = new Easings$EASE_OUT_QUAD();
    Easings_EASE_IN_OUT_QUAD_instance = new Easings$EASE_IN_OUT_QUAD();
    Easings_EASE_SINE_instance = new Easings$EASE_SINE();
    Easings_EASE_CLAMP_START_instance = new Easings$EASE_CLAMP_START();
    Easings_EASE_CLAMP_END_instance = new Easings$EASE_CLAMP_END();
    Easings_EASE_CLAMP_MIDDLE_instance = new Easings$EASE_CLAMP_MIDDLE();
  }
  function Easings(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Easings).toString = function () {
    var tmp$ret$1;
    // Inline function 'kotlin.text.lowercase' call
    var tmp0_lowercase = replace(protoOf(Enum).toString.call(this), _Char___init__impl__6a9atx(95), _Char___init__impl__6a9atx(45));
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_lowercase;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    return tmp$ret$1;
  };
  function Easings_SMOOTH_getInstance() {
    Easings_initEntries();
    return Easings_SMOOTH_instance;
  }
  function Easings_EASE_IN_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_ELASTIC_instance;
  }
  function Easings_EASE_OUT_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_ELASTIC_instance;
  }
  function Easings_EASE_OUT_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_BOUNCE_instance;
  }
  function Easings_LINEAR_getInstance() {
    Easings_initEntries();
    return Easings_LINEAR_instance;
  }
  function Easings_EASE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_instance;
  }
  function Easings_EASE_IN_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_instance;
  }
  function Easings_EASE_OUT_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_instance;
  }
  function Easings_EASE_IN_OUT_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_instance;
  }
  function Easings_EASE_IN_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OLD_instance;
  }
  function Easings_EASE_OUT_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_OLD_instance;
  }
  function Easings_EASE_IN_OUT_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_OLD_instance;
  }
  function Easings_EASE_OUT_IN_OLD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_OLD_instance;
  }
  function Easings_EASE_IN_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_BACK_instance;
  }
  function Easings_EASE_OUT_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_BACK_instance;
  }
  function Easings_EASE_IN_OUT_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_BACK_instance;
  }
  function Easings_EASE_OUT_IN_BACK_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_BACK_instance;
  }
  function Easings_EASE_IN_OUT_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_ELASTIC_instance;
  }
  function Easings_EASE_OUT_IN_ELASTIC_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_ELASTIC_instance;
  }
  function Easings_EASE_IN_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_BOUNCE_instance;
  }
  function Easings_EASE_IN_OUT_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_BOUNCE_instance;
  }
  function Easings_EASE_OUT_IN_BOUNCE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_IN_BOUNCE_instance;
  }
  function Easings_EASE_IN_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_QUAD_instance;
  }
  function Easings_EASE_OUT_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_OUT_QUAD_instance;
  }
  function Easings_EASE_IN_OUT_QUAD_getInstance() {
    Easings_initEntries();
    return Easings_EASE_IN_OUT_QUAD_instance;
  }
  function Easings_EASE_SINE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_SINE_instance;
  }
  function Easings_EASE_CLAMP_START_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_START_instance;
  }
  function Easings_EASE_CLAMP_END_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_END_instance;
  }
  function Easings_EASE_CLAMP_MIDDLE_getInstance() {
    Easings_initEntries();
    return Easings_EASE_CLAMP_MIDDLE_instance;
  }
  function _ALL_LIST$factory() {
    return getPropertyCallableRef('_ALL_LIST', 1, KProperty1, function (receiver) {
      return _get__ALL_LIST__dej8hi(receiver);
    }, null);
  }
  function interpolate(_this__u8e3s4, l, r) {
    return new Vector2(interpolate_0(_this__u8e3s4, l.p3r_1, r.p3r_1), interpolate_0(_this__u8e3s4, l.q3r_1, r.q3r_1));
  }
  function interpolate_0(_this__u8e3s4, l, r) {
    return l + (r - l) * _Ratio___get_valueF__impl__66hkuu(_this__u8e3s4);
  }
  function interpolate_1(_this__u8e3s4, l, r) {
    return numberToInt(l + (r - l | 0) * _Ratio___get_valueD__impl__jkcopk(_this__u8e3s4));
  }
  function _Ratio___init__impl__9mwvn2(valueF) {
    return valueF;
  }
  function _Ratio___get_valueF__impl__66hkuu($this) {
    return $this;
  }
  function _Ratio___init__impl__9mwvn2_0(ratio) {
    var tmp = _Ratio___init__impl__9mwvn2(ratio);
    return tmp;
  }
  function _Ratio___get_value__impl__57cli6($this) {
    return _Ratio___get_valueF__impl__66hkuu($this);
  }
  function _Ratio___get_valueD__impl__jkcopk($this) {
    return _Ratio___get_valueF__impl__66hkuu($this);
  }
  function Ratio__toFloat_impl_1ftup5($this) {
    return _Ratio___get_valueF__impl__66hkuu($this);
  }
  function _Ratio___init__impl__9mwvn2_1(value, maximum) {
    var tmp = _Ratio___init__impl__9mwvn2_0(value / maximum);
    return tmp;
  }
  function Ratio__compareTo_impl_z2ienc($this, other) {
    return compareTo(_Ratio___get_value__impl__57cli6($this), _Ratio___get_value__impl__57cli6(other));
  }
  function Ratio__compareTo_impl_z2ienc_0($this, other) {
    var tmp = $this.o4p_1;
    return Ratio__compareTo_impl_z2ienc(tmp, other instanceof Ratio ? other.o4p_1 : THROW_CCE());
  }
  function Companion_42() {
    Companion_instance_42 = this;
    this.p4p_1 = _Ratio___init__impl__9mwvn2(0.0);
    this.q4p_1 = _Ratio___init__impl__9mwvn2(0.25);
    this.r4p_1 = _Ratio___init__impl__9mwvn2(0.5);
    this.s4p_1 = _Ratio___init__impl__9mwvn2(1.0);
    var tmp = this;
    FloatCompanionObject_getInstance();
    tmp.t4p_1 = _Ratio___init__impl__9mwvn2(NaN);
  }
  var Companion_instance_42;
  function Companion_getInstance_42() {
    if (Companion_instance_42 == null)
      new Companion_42();
    return Companion_instance_42;
  }
  function Ratio__toString_impl_mfgt3e($this) {
    return 'Ratio(valueF=' + $this + ')';
  }
  function Ratio__hashCode_impl_quhc2v($this) {
    return getNumberHashCode($this);
  }
  function Ratio__equals_impl_mfc2gt($this, other) {
    if (!(other instanceof Ratio))
      return false;
    var tmp0_other_with_cast = other instanceof Ratio ? other.o4p_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Ratio(valueF) {
    Companion_getInstance_42();
    this.o4p_1 = valueF;
  }
  protoOf(Ratio).u4p = function (other) {
    return Ratio__compareTo_impl_z2ienc(this.o4p_1, other);
  };
  protoOf(Ratio).fc = function (other) {
    return Ratio__compareTo_impl_z2ienc_0(this, other);
  };
  protoOf(Ratio).toString = function () {
    return Ratio__toString_impl_mfgt3e(this.o4p_1);
  };
  protoOf(Ratio).hashCode = function () {
    return Ratio__hashCode_impl_quhc2v(this.o4p_1);
  };
  protoOf(Ratio).equals = function (other) {
    return Ratio__equals_impl_mfc2gt(this.o4p_1, other);
  };
  function toRatio(_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2(_this__u8e3s4);
  }
  function toRatio_0(_this__u8e3s4) {
    return _Ratio___init__impl__9mwvn2_0(_this__u8e3s4);
  }
  function insertAt($this, n, min, max) {
    $this.f4j_1.b1b(n, min);
    $this.g4j_1.b1b(n, max);
  }
  function removeAt($this, n, count) {
    $this.f4j_1.u19(n, count);
    $this.g4j_1.u19(n, count);
  }
  function IntSegmentSet() {
    this.f4j_1 = new IntArrayList(16);
    this.g4j_1 = new IntArrayList(16);
  }
  protoOf(IntSegmentSet).l = function () {
    return this.f4j_1.l();
  };
  protoOf(IntSegmentSet).p = function () {
    return this.l() === 0;
  };
  protoOf(IntSegmentSet).y1a = function () {
    return this.l() > 0;
  };
  protoOf(IntSegmentSet).kk = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.clear.<anonymous>' call
    this.f4j_1.e4();
    this.g4j_1.e4();
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(IntSegmentSet).v4p = function () {
    return this.y1a() ? this.f4j_1.z18(0) : 0;
  };
  protoOf(IntSegmentSet).w4p = function () {
    return this.y1a() ? this.g4j_1.z18(this.g4j_1.l() - 1 | 0) : 0;
  };
  protoOf(IntSegmentSet).x4p = function (x) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var tmp0_genericBinarySearch = this.l();
      var low = 0;
      var high = tmp0_genericBinarySearch - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findNearIndex.<anonymous>' call
        var min = this.f4j_1.z18(mid);
        var max = this.g4j_1.z18(mid);
        tmp$ret$0 = x < min ? 1 : x > max ? -1 : 0;
        var mval = tmp$ret$0;
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.genericBinarySearch.<anonymous>' call
      var tmp1__anonymous__uwfjfc = low;
      var tmp2__anonymous__z9zvc9 = high;
      tmp$ret$2 = (-tmp1__anonymous__uwfjfc | 0) - 1 | 0;
      tmp$ret$1 = tmp$ret$2;
    }
    return _BSearchResult___init__impl__em6zam(tmp$ret$1);
  };
  protoOf(IntSegmentSet).y4p = function (x) {
    var tmp$ret$4;
    // Inline function 'korlibs.math.segment.genericBinarySearchLeft' call
    var tmp2_genericBinarySearchLeft = this.l();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = tmp2_genericBinarySearchLeft - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findLeftBound.<anonymous>' call
        tmp$ret$0 = compareTo(this.f4j_1.z18(mid), x);
        var mval = tmp$ret$0;
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      var tmp$ret$3;
      // Inline function 'korlibs.math.segment.genericBinarySearchLeft.<anonymous>' call
      var tmp0__anonymous__q1qw7t = low;
      var tmp1__anonymous__uwfjfc = high;
      var tmp$ret$2;
      // Inline function 'kotlin.math.min' call
      tmp$ret$2 = Math.min(tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc);
      tmp$ret$3 = coerceIn(tmp$ret$2, 0, tmp2_genericBinarySearchLeft - 1 | 0);
      tmp$ret$1 = tmp$ret$3;
    }
    tmp$ret$4 = tmp$ret$1;
    return coerceIn(tmp$ret$4, 0, this.l() - 1 | 0);
  };
  protoOf(IntSegmentSet).z4p = function (x) {
    var tmp$ret$4;
    // Inline function 'korlibs.math.segment.genericBinarySearchRight' call
    var tmp2_genericBinarySearchRight = this.l();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = 0;
      var high = tmp2_genericBinarySearchRight - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.findRightBound.<anonymous>' call
        tmp$ret$0 = compareTo(this.g4j_1.z18(mid), x);
        var mval = tmp$ret$0;
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      var tmp$ret$3;
      // Inline function 'korlibs.math.segment.genericBinarySearchRight.<anonymous>' call
      var tmp0__anonymous__q1qw7t = low;
      var tmp1__anonymous__uwfjfc = high;
      var tmp$ret$2;
      // Inline function 'kotlin.math.max' call
      tmp$ret$2 = Math.max(tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc);
      tmp$ret$3 = coerceIn(tmp$ret$2, 0, tmp2_genericBinarySearchRight - 1 | 0);
      tmp$ret$1 = tmp$ret$3;
    }
    tmp$ret$4 = tmp$ret$1;
    return coerceIn(tmp$ret$4, 0, this.l() - 1 | 0);
  };
  protoOf(IntSegmentSet).p40 = function (min, max) {
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$6;
    $l$block_0: {
      // Inline function 'korlibs.math.segment.IntSegmentSet.add.<anonymous>' call
      // Inline function 'kotlin.check' call
      var tmp0_check = min <= max;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_check) {
        var tmp$ret$0;
        // Inline function 'kotlin.check.<anonymous>' call
        tmp$ret$0 = 'Check failed.';
        var message = tmp$ret$0;
        throw IllegalStateException_init_$Create$(toString(message));
      }
      if (this.p()) {
        insertAt(this, this.l(), min, max);
      } else if (min === this.w4p()) {
        this.g4j_1.w1a(this.g4j_1.l() - 1 | 0, max);
      } else if (max === this.v4p()) {
        this.f4j_1.w1a(0, min);
      } else {
        var removeStart = -1;
        var removeCount = -1;
        var tmp$ret$1;
        $l$block: {
          // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEachInterestingRange' call
          if (this.p()) {
            tmp$ret$1 = Unit_getInstance();
            break $l$block;
          }
          var nmin = this.y4p(min);
          var nmax = this.z4p(max);
          var inductionVariable = nmin;
          if (inductionVariable <= nmax)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              // Inline function 'korlibs.math.segment.IntSegmentSet.add.<anonymous>.<anonymous>' call
              var tmp1__anonymous__uwfjfc = this.f4j_1.z18(n);
              var tmp2__anonymous__z9zvc9 = this.g4j_1.z18(n);
              if (this.a4q(tmp1__anonymous__uwfjfc, tmp2__anonymous__z9zvc9, min, max)) {
                if (removeStart === -1)
                  removeStart = n;
                var tmp = removeStart;
                var tmp$ret$3;
                // Inline function 'kotlin.math.min' call
                var tmp0_min = this.f4j_1.z18(removeStart);
                var tmp$ret$2;
                // Inline function 'kotlin.math.min' call
                tmp$ret$2 = Math.min(tmp1__anonymous__uwfjfc, min);
                var tmp1_min = tmp$ret$2;
                tmp$ret$3 = Math.min(tmp0_min, tmp1_min);
                this.f4j_1.w1a(tmp, tmp$ret$3);
                var tmp_0 = removeStart;
                var tmp$ret$5;
                // Inline function 'kotlin.math.max' call
                var tmp2_max = this.g4j_1.z18(removeStart);
                var tmp$ret$4;
                // Inline function 'kotlin.math.max' call
                tmp$ret$4 = Math.max(tmp2__anonymous__z9zvc9, max);
                var tmp3_max = tmp$ret$4;
                tmp$ret$5 = Math.max(tmp2_max, tmp3_max);
                this.g4j_1.w1a(tmp_0, tmp$ret$5);
                var tmp0 = removeCount;
                removeCount = tmp0 + 1 | 0;
              }
            }
             while (!(n === nmax));
        }
        if (removeCount === 0)
        ;
        else if (removeCount > 0) {
          removeAt(this, removeStart + 1 | 0, removeCount);
        } else if (max < this.v4p()) {
          insertAt(this, 0, min, max);
        } else if (min > this.w4p()) {
          insertAt(this, this.l(), min, max);
        } else {
          var inductionVariable_0 = coerceAtLeast(this.y4p(min), 1);
          var last = this.z4p(max);
          if (inductionVariable_0 <= last)
            do {
              var m = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              var prevMax = this.g4j_1.z18(m - 1 | 0);
              var currMin = this.f4j_1.z18(m);
              if (min > prevMax ? max < currMin : false) {
                insertAt(this, m, min, max);
                tmp$ret$6 = Unit_getInstance();
                break $l$block_0;
              }
            }
             while (!(m === last));
          // Inline function 'kotlin.error' call
          throw IllegalStateException_init_$Create$('Unexpected');
        }
      }
    }
    tmp$ret$7 = this;
    return tmp$ret$7;
  };
  protoOf(IntSegmentSet).y9 = function (v) {
    return _BSearchResult___get_found__impl__om04iz(this.x4p(v));
  };
  protoOf(IntSegmentSet).b4q = function (a, b) {
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>' call
    var aSmaller = a.l() < b.l();
    var av = aSmaller ? a : b;
    var bv = aSmaller ? b : a;
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.kk();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEach' call
    var inductionVariable = 0;
    var last = av.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = av.f4j_1.z18(n);
        var tmp1__anonymous__uwfjfc = av.g4j_1.z18(n);
        var tmp$ret$3;
        // Inline function 'korlibs.math.segment.IntSegmentSet.intersection' call
        var count = 0;
        var tmp$ret$0;
        $l$block: {
          // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEachInterestingRange' call
          if (bv.p()) {
            tmp$ret$0 = Unit_getInstance();
            break $l$block;
          }
          var nmin = bv.y4p(tmp0__anonymous__q1qw7t);
          var nmax = bv.z4p(tmp1__anonymous__uwfjfc);
          var inductionVariable_0 = nmin;
          if (inductionVariable_0 <= nmax)
            do {
              var n_0 = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              // Inline function 'korlibs.math.segment.IntSegmentSet.intersection.<anonymous>' call
              var tmp0__anonymous__q1qw7t_0 = bv.f4j_1.z18(n_0);
              var tmp1__anonymous__uwfjfc_0 = bv.g4j_1.z18(n_0);
              if (bv.a4q(tmp0__anonymous__q1qw7t_0, tmp1__anonymous__uwfjfc_0, tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc)) {
                // Inline function 'korlibs.math.segment.IntSegmentSet.setToIntersect.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
                var tmp$ret$1;
                // Inline function 'kotlin.math.max' call
                tmp$ret$1 = Math.max(tmp0__anonymous__q1qw7t_0, tmp0__anonymous__q1qw7t);
                var tmp2__anonymous__z9zvc9 = tmp$ret$1;
                var tmp$ret$2;
                // Inline function 'kotlin.math.min' call
                tmp$ret$2 = Math.min(tmp1__anonymous__uwfjfc_0, tmp1__anonymous__uwfjfc);
                var tmp3__anonymous__ufb84q = tmp$ret$2;
                this.p40(tmp2__anonymous__z9zvc9, tmp3__anonymous__ufb84q);
                var tmp0 = count;
                count = tmp0 + 1 | 0;
              }
            }
             while (!(n_0 === nmax));
        }
        tmp$ret$3 = count > 0;
      }
       while (inductionVariable < last);
    tmp$ret$4 = tmp0_also;
    tmp$ret$5 = this;
    return tmp$ret$5;
  };
  protoOf(IntSegmentSet).a4q = function (x1, x2, y1, y2) {
    return x2 >= y1 ? y2 >= x1 : false;
  };
  protoOf(IntSegmentSet).toString = function () {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.math.segment.IntSegmentSet.toString.<anonymous>' call
    tmp0_apply.bc('[');
    // Inline function 'korlibs.math.segment.IntSegmentSet.fastForEach' call
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.math.segment.IntSegmentSet.toString.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.f4j_1.z18(n);
        var tmp1__anonymous__uwfjfc = this.g4j_1.z18(n);
        var first = n === 0;
        if (!first) {
          tmp0_apply.bc(', ');
        }
        tmp0_apply.bc('' + tmp0__anonymous__q1qw7t + '-' + tmp1__anonymous__uwfjfc);
      }
       while (inductionVariable < last);
    tmp0_apply.bc(']');
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  //region block: post-declaration
  protoOf(PointArrayList).d40 = clone$default;
  protoOf(PointArrayList).t3z = get_dimensions;
  protoOf(PointArrayList).a19 = get;
  protoOf(PointArrayList).w3z = getComponentList;
  protoOf(PointArrayList).x3z = getComponentList$default;
  protoOf(PointArrayList).e1c = get_first;
  protoOf(PointArrayList).f1c = get_last;
  protoOf(PointArrayList).p = isEmpty;
  protoOf(PointArrayList).y1a = isNotEmpty;
  protoOf(RectCoords).s4b = x;
  protoOf(RectCoords).t4b = y;
  protoOf(RectSlice).f3s = get_left;
  protoOf(RectSlice).h3s = get_top;
  protoOf(RectSlice).g3s = get_right;
  protoOf(RectSlice).i3s = get_bottom;
  protoOf(RectSlice).l42 = get_area;
  protoOf(RectSlice).s4d = get_frameOffsetX;
  protoOf(RectSlice).t4d = get_frameOffsetY;
  protoOf(RectSlice).s4b = x;
  protoOf(RectSlice).t4b = y;
  protoOf(SliceCoordsImpl).s4d = get_frameOffsetX;
  protoOf(SliceCoordsImpl).t4d = get_frameOffsetY;
  protoOf(SliceCoordsImpl).s4b = x;
  protoOf(SliceCoordsImpl).t4b = y;
  protoOf(VectorBuilder$transformed$1).z4k = line;
  protoOf(VectorBuilder$transformed$1).a4l = arcTo;
  protoOf(VectorBuilder$transformed$1).p = isEmpty_0;
  protoOf(VectorBuilder$transformed$1).b4l = rect;
  protoOf(VectorBuilder$transformed$1).c4l = rect_0;
  protoOf(VectorBuilder$transformed$1).d4l = rect_1;
  protoOf(VectorBuilder$transformed$1).e4l = rect_2;
  protoOf(VectorBuilder$transformed$1).f4l = rectHole;
  protoOf(VectorBuilder$transformed$1).g4l = roundRect;
  protoOf(VectorBuilder$transformed$1).h4l = arc;
  protoOf(VectorBuilder$transformed$1).i4l = arc$default;
  protoOf(VectorBuilder$transformed$1).c3t = circle;
  protoOf(VectorBuilder$transformed$1).j4l = rMoveTo;
  protoOf(VectorBuilder$transformed$1).k4l = rLineTo;
  protoOf(VectorBuilder$transformed$1).a42 = transformed;
  protoOf(VectorPath).z4k = line;
  protoOf(VectorPath).a4l = arcTo;
  protoOf(VectorPath).p = isEmpty_0;
  protoOf(VectorPath).y1a = isNotEmpty_0;
  protoOf(VectorPath).b4l = rect;
  protoOf(VectorPath).c4l = rect_0;
  protoOf(VectorPath).d4l = rect_1;
  protoOf(VectorPath).e4l = rect_2;
  protoOf(VectorPath).f4l = rectHole;
  protoOf(VectorPath).g4l = roundRect;
  protoOf(VectorPath).n42 = roundRect_0;
  protoOf(VectorPath).h4l = arc;
  protoOf(VectorPath).i4l = arc$default;
  protoOf(VectorPath).c3t = circle;
  protoOf(VectorPath).j4l = rMoveTo;
  protoOf(VectorPath).k4l = rLineTo;
  protoOf(VectorPath).a42 = transformed;
  protoOf(VectorPath).m4l = transformed_0;
  protoOf(with$1).n1q = close;
  protoOf(with$1).a45 = lineTo;
  protoOf(with$1).b45 = quadTo_0;
  protoOf(with$1).d45 = cubicTo;
  protoOf(EasingCubic).x4m = invoke;
  protoOf(Easings).x4m = invoke;
  protoOf(Easings$SMOOTH).x4m = invoke;
  protoOf(Easings$EASE_IN_ELASTIC).x4m = invoke;
  protoOf(Easings$EASE_OUT_ELASTIC).x4m = invoke;
  protoOf(Easings$EASE_OUT_BOUNCE).x4m = invoke;
  protoOf(Easings$LINEAR).x4m = invoke;
  protoOf(Easings$EASE).x4m = invoke;
  protoOf(Easings$EASE_IN).x4m = invoke;
  protoOf(Easings$EASE_OUT).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT).x4m = invoke;
  protoOf(Easings$EASE_IN_OLD).x4m = invoke;
  protoOf(Easings$EASE_OUT_OLD).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT_OLD).x4m = invoke;
  protoOf(Easings$EASE_OUT_IN_OLD).x4m = invoke;
  protoOf(Easings$EASE_IN_BACK).x4m = invoke;
  protoOf(Easings$EASE_OUT_BACK).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT_BACK).x4m = invoke;
  protoOf(Easings$EASE_OUT_IN_BACK).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT_ELASTIC).x4m = invoke;
  protoOf(Easings$EASE_OUT_IN_ELASTIC).x4m = invoke;
  protoOf(Easings$EASE_IN_BOUNCE).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT_BOUNCE).x4m = invoke;
  protoOf(Easings$EASE_OUT_IN_BOUNCE).x4m = invoke;
  protoOf(Easings$EASE_IN_QUAD).x4m = invoke;
  protoOf(Easings$EASE_OUT_QUAD).x4m = invoke;
  protoOf(Easings$EASE_IN_OUT_QUAD).x4m = invoke;
  protoOf(Easings$EASE_SINE).x4m = invoke;
  protoOf(Easings$EASE_CLAMP_START).x4m = invoke;
  protoOf(Easings$EASE_CLAMP_END).x4m = invoke;
  protoOf(Easings$EASE_CLAMP_MIDDLE).x4m = invoke;
  //endregion
  //region block: init
  MINUS_ZERO_D = -0.0;
  MINUS_ZERO_F = -0.0;
  PI2 = 6.283185307179586;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = StrokePointsMode_SCALABLE_POS_NORMAL_WIDTH_getInstance;
  _.$_$.b = HitTestDirection_ANY_getInstance;
  _.$_$.c = LineCap_BUTT_getInstance;
  _.$_$.d = LineJoin_MITER_getInstance;
  _.$_$.e = LineScaleMode_NONE_getInstance;
  _.$_$.f = LineScaleMode_NORMAL_getInstance;
  _.$_$.g = Winding_EVEN_ODD_getInstance;
  _.$_$.h = Winding_NON_ZERO_getInstance;
  _.$_$.i = MatrixType_IDENTITY_getInstance;
  _.$_$.j = arc$default;
  _.$_$.k = Bezier_init_$Create$;
  _.$_$.l = Anchor_init_$Create$;
  _.$_$.m = Margin_init_$Create$;
  _.$_$.n = MarginInt_init_$Create$;
  _.$_$.o = Matrix4_init_$Create$_0;
  _.$_$.p = RectangleInt_init_$Create$;
  _.$_$.q = Scale_init_$Create$_0;
  _.$_$.r = Scale_init_$Create$_1;
  _.$_$.s = Scale_init_$Create$;
  _.$_$.t = Size_init_$Create$;
  _.$_$.u = Size_init_$Create$_0;
  _.$_$.v = Vector2_init_$Create$;
  _.$_$.w = Vector2_init_$Create$_1;
  _.$_$.x = Vector2_init_$Create$_0;
  _.$_$.y = Matrix__prescaled$default_impl_m96zqs_0;
  _.$_$.z = Matrix__prescaled$default_impl_m96zqs;
  _.$_$.a1 = Matrix__scaled$default_impl_qe0yl1_0;
  _.$_$.b1 = Matrix__scaled$default_impl_qe0yl1;
  _.$_$.c1 = _SliceOrientation___get_flipX__impl__lgb7bx;
  _.$_$.d1 = _SliceOrientation___get_isRotatedDeg90CwOrCcw__impl__98v1vq;
  _.$_$.e1 = _SliceOrientation___get_rotation__impl__m65r0o;
  _.$_$.f1 = _Angle___init__impl__g23q1m;
  _.$_$.g1 = _Angle___get_absoluteValue__impl__4qoakv;
  _.$_$.h1 = Angle__cosine_impl_bgvzun;
  _.$_$.i1 = _Angle___get_degreesD__impl__9qro6;
  _.$_$.j1 = Angle__div_impl_ut2btd_0;
  _.$_$.k1 = Angle__hashCode_impl_szcndt;
  _.$_$.l1 = Angle__inBetween_impl_ojsmql;
  _.$_$.m1 = _Angle___get_radiansD__impl__thfj8f;
  _.$_$.n1 = _Angle___get_ratio__impl__ap3on4;
  _.$_$.o1 = Angle__sine_impl_3l11xv;
  _.$_$.p1 = Angle__times_impl_v3s9y2;
  _.$_$.q1 = Angle__unaryMinus_impl_7901fr;
  _.$_$.r1 = _BoundsBuilder___get_bounds__impl__s1l1gx;
  _.$_$.s1 = BoundsBuilder__boundsOrNull_impl_se9lyg;
  _.$_$.t1 = BoundsBuilder__hashCode_impl_649zbo;
  _.$_$.u1 = BoundsBuilder__plus_impl_cz7f7x_2;
  _.$_$.v1 = BoundsBuilder__plus_impl_cz7f7x;
  _.$_$.w1 = BoundsBuilder__plus_impl_cz7f7x_0;
  _.$_$.x1 = BoundsBuilder__plus_impl_cz7f7x_1;
  _.$_$.y1 = _BoundsBuilder___get_xmax__impl__gsz0km;
  _.$_$.z1 = _BoundsBuilder___get_xmin__impl__o2sib0;
  _.$_$.a2 = BoundsBuilder__xminOr_impl_k3l1m2;
  _.$_$.b2 = _BoundsBuilder___get_ymax__impl__oia4uz;
  _.$_$.c2 = _BoundsBuilder___get_ymin__impl__5n2e8j;
  _.$_$.d2 = _Matrix___init__impl__q3kp4w_1;
  _.$_$.e2 = _Matrix___get_a__impl__f7jypo;
  _.$_$.f2 = _Matrix___get_b__impl__dm2icj;
  _.$_$.g2 = _Matrix___get_c__impl__slf2ke;
  _.$_$.h2 = Matrix__clone_impl_451ycv;
  _.$_$.i2 = _Matrix___get_d__impl__87eht;
  _.$_$.j2 = Matrix__hashCode_impl_s9ntm9;
  _.$_$.k2 = _Matrix___get_immutable__impl__k08qr;
  _.$_$.l2 = Matrix__inverted_impl_3pqbyv;
  _.$_$.m2 = _Matrix___get_isIdentity__impl__oe9whb;
  _.$_$.n2 = _Matrix___get_isNIL__impl__n63x5q;
  _.$_$.o2 = _Matrix___get_isNotNIL__impl__wjqa0f;
  _.$_$.p2 = _Matrix___get_mutable__impl__mr1r3;
  _.$_$.q2 = Matrix__preconcated_impl_s9s2ig;
  _.$_$.r2 = Matrix__premultiplied_impl_rsndx4;
  _.$_$.s2 = Matrix__prescaled_impl_gy2bxt_1;
  _.$_$.t2 = Matrix__pretranslated_impl_9mjs3v_1;
  _.$_$.u2 = Matrix__pretranslated_impl_9mjs3v_0;
  _.$_$.v2 = Matrix__rotated_impl_2adiit;
  _.$_$.w2 = Matrix__scaled_impl_ogn4b6_1;
  _.$_$.x2 = Matrix__scaled_impl_ogn4b6_0;
  _.$_$.y2 = Matrix__scaled_impl_ogn4b6;
  _.$_$.z2 = Matrix__times_impl_aro03c;
  _.$_$.a3 = Matrix__toTransform_impl_lr8urh;
  _.$_$.b3 = Matrix__transformX_impl_adb2o0_1;
  _.$_$.c3 = Matrix__transformX_impl_adb2o0;
  _.$_$.d3 = Matrix__transformX_impl_adb2o0_0;
  _.$_$.e3 = Matrix__transformY_impl_aucp27;
  _.$_$.f3 = Matrix__transformY_impl_aucp27_0;
  _.$_$.g3 = Matrix__translated_impl_3xg5y;
  _.$_$.h3 = Matrix__translated_impl_3xg5y_2;
  _.$_$.i3 = Matrix__translated_impl_3xg5y_0;
  _.$_$.j3 = Matrix__translated_impl_3xg5y_1;
  _.$_$.k3 = _Matrix___get_tx__impl__yc1n9;
  _.$_$.l3 = _Matrix___get_ty__impl__tryipg;
  _.$_$.m3 = _Matrix___get_type__impl__g2man3;
  _.$_$.n3 = _MatrixTransform___init__impl__7axsoi_1;
  _.$_$.o3 = _MatrixTransform___get_rotation__impl__d5fsj;
  _.$_$.p3 = _MatrixTransform___get_scale__impl__8ztdah;
  _.$_$.q3 = _MatrixTransform___get_scaleAvg__impl__ku8ljx;
  _.$_$.r3 = _MatrixTransform___get_scaleX__impl__t9pp1f;
  _.$_$.s3 = _MatrixTransform___get_scaleY__impl__g37z8;
  _.$_$.t3 = _MatrixTransform___get_skewX__impl__luxf0b;
  _.$_$.u3 = _MatrixTransform___get_skewY__impl__6yp21w;
  _.$_$.v3 = _MatrixTransform___get_x__impl__5z12ed;
  _.$_$.w3 = _MatrixTransform___get_y__impl__mulenu;
  _.$_$.x3 = _RectCorners___init__impl__w373y9_4;
  _.$_$.y3 = _RectCorners___init__impl__w373y9_0;
  _.$_$.z3 = _RectCorners___init__impl__w373y9_1;
  _.$_$.a4 = _RectCorners___init__impl__w373y9_3;
  _.$_$.b4 = _RectCorners___get_bottomLeft__impl__ffl7d4;
  _.$_$.c4 = _RectCorners___get_bottomRight__impl__j7ud1v;
  _.$_$.d4 = _RectCorners___get_topLeft__impl__yn4qy0;
  _.$_$.e4 = _RectCorners___get_topRight__impl__okc9ql;
  _.$_$.f4 = _SizeInt___init__impl__vivn9_0;
  _.$_$.g4 = _SizeInt___init__impl__vivn9_1;
  _.$_$.h4 = _SizeInt___get_height__impl__d3sy61;
  _.$_$.i4 = _SizeInt___get_width__impl__swntg6;
  _.$_$.j4 = _Ratio___init__impl__9mwvn2_1;
  _.$_$.k4 = Ratio__toFloat_impl_1ftup5;
  _.$_$.l4 = _Ratio___get_value__impl__57cli6;
  _.$_$.m4 = Companion_getInstance_24;
  _.$_$.n4 = Companion_getInstance_26;
  _.$_$.o4 = Companion_getInstance_28;
  _.$_$.p4 = Companion_getInstance_30;
  _.$_$.q4 = Companion_getInstance_38;
  _.$_$.r4 = Command_getInstance;
  _.$_$.s4 = Companion_getInstance;
  _.$_$.t4 = Companion_getInstance_0;
  _.$_$.u4 = Companion_getInstance_1;
  _.$_$.v4 = Companion_getInstance_6;
  _.$_$.w4 = Companion_getInstance_8;
  _.$_$.x4 = Companion_getInstance_9;
  _.$_$.y4 = Companion_getInstance_10;
  _.$_$.z4 = Companion_getInstance_12;
  _.$_$.a5 = Companion_getInstance_16;
  _.$_$.b5 = Companion_getInstance_17;
  _.$_$.c5 = Companion_getInstance_18;
  _.$_$.d5 = Companion_getInstance_19;
  _.$_$.e5 = Companion_getInstance_20;
  _.$_$.f5 = Companion_getInstance_21;
  _.$_$.g5 = Companion_getInstance_41;
  _.$_$.h5 = Companion_getInstance_42;
  _.$_$.i5 = ProjectedPoint;
  _.$_$.j5 = get_isConvex;
  _.$_$.k5 = toStrokePointsList;
  _.$_$.l5 = ImageDoNotFitException;
  _.$_$.m5 = get_cachedPoints;
  _.$_$.n5 = getPoints2List;
  _.$_$.o5 = RectSlice;
  _.$_$.p5 = SliceOrientation;
  _.$_$.q5 = splitInRows;
  _.$_$.r5 = PolygonScanline;
  _.$_$.s5 = RastScale;
  _.$_$.t5 = StrokeInfo;
  _.$_$.u5 = StrokeToFill;
  _.$_$.v5 = arcTo;
  _.$_$.w5 = arc;
  _.$_$.x5 = circle;
  _.$_$.y5 = isEmpty_0;
  _.$_$.z5 = line;
  _.$_$.a6 = rLineTo;
  _.$_$.b6 = rMoveTo;
  _.$_$.c6 = rectHole;
  _.$_$.d6 = rectHole_0;
  _.$_$.e6 = rect;
  _.$_$.f6 = rect_2;
  _.$_$.g6 = rect_1;
  _.$_$.h6 = rect_0;
  _.$_$.i6 = roundRect;
  _.$_$.j6 = transformed;
  _.$_$.k6 = VectorBuilder;
  _.$_$.l6 = VectorPath;
  _.$_$.m6 = applyTransform;
  _.$_$.n6 = getCurves;
  _.$_$.o6 = plus_0;
  _.$_$.p6 = strokeToFill;
  _.$_$.q6 = strokeToFill_0;
  _.$_$.r6 = toCurvesList;
  _.$_$.s6 = write;
  _.$_$.t6 = Anchor;
  _.$_$.u6 = Angle_between;
  _.$_$.v6 = Angle;
  _.$_$.w6 = BoundsBuilder;
  _.$_$.x6 = Circle;
  _.$_$.y6 = Line;
  _.$_$.z6 = MMatrix;
  _.$_$.a7 = Margin;
  _.$_$.b7 = MatrixTransform;
  _.$_$.c7 = Matrix;
  _.$_$.d7 = PointArrayList;
  _.$_$.e7 = RectCorners;
  _.$_$.f7 = RectangleInt;
  _.$_$.g7 = Rectangle;
  _.$_$.h7 = RoundRectangle;
  _.$_$.i7 = Scale;
  _.$_$.j7 = SizeInt;
  _.$_$.k7 = Size;
  _.$_$.l7 = Vector2Int;
  _.$_$.m7 = Vector2;
  _.$_$.n7 = Vector4;
  _.$_$.o7 = VectorArrayList;
  _.$_$.p7 = get_degrees;
  _.$_$.q7 = get_radians_1;
  _.$_$.r7 = get_radians;
  _.$_$.s7 = toFloat;
  _.$_$.t7 = toInt;
  _.$_$.u7 = toMatrix4;
  _.$_$.v7 = Ratio;
  _.$_$.w7 = interpolate;
  _.$_$.x7 = interpolate_1;
  _.$_$.y7 = interpolate_0;
  _.$_$.z7 = toRatio;
  _.$_$.a8 = toRatio_0;
  _.$_$.b8 = IntSegmentSet;
  _.$_$.c8 = max_1;
  _.$_$.d8 = max_0;
  _.$_$.e8 = min_0;
  _.$_$.f8 = min_1;
  _.$_$.g8 = nextMultipleOf;
  _.$_$.h8 = roundDecimalPlaces_0;
  _.$_$.i8 = roundDecimalPlaces;
  //endregion
  return _;
}));
