(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './korge-root-korio.js', './korge-root-kds.js', './korge-root-klock.js', './korge-root-kmem.js', './korge-root-korma.js', './korge-root-klogger.js', './korge-root-korim.js', './korge-root-krypto.js', './kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./korge-root-korio.js'), require('./korge-root-kds.js'), require('./korge-root-klock.js'), require('./korge-root-kmem.js'), require('./korge-root-korma.js'), require('./korge-root-klogger.js'), require('./korge-root-korim.js'), require('./korge-root-krypto.js'), require('./kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-korio'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-korio' was not found. Please, check whether 'korge-root-korio' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-kds'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-kds' was not found. Please, check whether 'korge-root-kds' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-klock'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-klock' was not found. Please, check whether 'korge-root-klock' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-korma'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-korma' was not found. Please, check whether 'korge-root-korma' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-klogger'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-klogger' was not found. Please, check whether 'korge-root-klogger' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-korim'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-korim' was not found. Please, check whether 'korge-root-korim' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['korge-root-krypto'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'korge-root-krypto' was not found. Please, check whether 'korge-root-krypto' is loaded prior to 'korge-root-korgw'.");
    }
    if (typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korgw'. Its dependency 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' was not found. Please, check whether 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' is loaded prior to 'korge-root-korgw'.");
    }
    root['korge-root-korgw'] = factory(typeof this['korge-root-korgw'] === 'undefined' ? {} : this['korge-root-korgw'], this['kotlin-kotlin-stdlib-js-ir'], this['korge-root-korio'], this['korge-root-kds'], this['korge-root-klock'], this['korge-root-kmem'], this['korge-root-korma'], this['korge-root-klogger'], this['korge-root-korim'], this['korge-root-krypto'], this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir']);
  }
}(this, function (_, kotlin_kotlin, kotlin_com_soywiz_korlibs_korio_korio, kotlin_com_soywiz_korlibs_kds_kds, kotlin_com_soywiz_korlibs_klock_klock, kotlin_com_soywiz_korlibs_kmem_kmem, kotlin_com_soywiz_korlibs_korma_korma, kotlin_com_soywiz_korlibs_klogger_klogger, kotlin_com_soywiz_korlibs_korim_korim, kotlin_com_soywiz_korlibs_krypto_krypto, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.nd;
  var cancel = kotlin_com_soywiz_korlibs_korio_korio.$_$.d2;
  var CloseableCancellable = kotlin_com_soywiz_korlibs_korio_korio.$_$.e2;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var FastArrayList_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.b;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var FastIdentityMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.c1;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var get = kotlin_com_soywiz_korlibs_kds_kds.$_$.j2;
  var set = kotlin_com_soywiz_korlibs_kds_kds.$_$.r2;
  var clear = kotlin_com_soywiz_korlibs_kds_kds.$_$.x1;
  var get_keys = kotlin_com_soywiz_korlibs_kds_kds.$_$.m2;
  var toList = kotlin_kotlin.$_$.da;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var toFastList = kotlin_com_soywiz_korlibs_kds_kds.$_$.y2;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var CancellableGroup = kotlin_com_soywiz_korlibs_korio_korio.$_$.c2;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var objectMeta = kotlin_kotlin.$_$.md;
  var Enum = kotlin_kotlin.$_$.rh;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var equals = kotlin_kotlin.$_$.cc;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var Companion_getInstance = kotlin_com_soywiz_korlibs_klock_klock.$_$.w;
  var Char = kotlin_kotlin.$_$.mh;
  var Companion_getInstance_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k1;
  var Char__hashCode_impl_otmys = kotlin_kotlin.$_$.j2;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var Companion_getInstance_1 = kotlin_com_soywiz_korlibs_korma_korma.$_$.f5;
  var Mixin = kotlin_com_soywiz_korlibs_kds_kds.$_$.y;
  var Companion_getInstance_2 = kotlin_com_soywiz_korlibs_klock_klock.$_$.t;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var FastIntMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.d1;
  var joinToString = kotlin_kotlin.$_$.b8;
  var DateTime__hashCode_impl_yf0bc9 = kotlin_com_soywiz_korlibs_klock_klock.$_$.a;
  var Exception = kotlin_kotlin.$_$.th;
  var Exception_init_$Init$ = kotlin_kotlin.$_$.h1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var hashCode = kotlin_kotlin.$_$.lc;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var arraycopy = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.r2;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var get_niceStr = kotlin_com_soywiz_korlibs_korio_korio.$_$.f4;
  var booleanArray = kotlin_kotlin.$_$.sb;
  var IntArrayList = kotlin_com_soywiz_korlibs_kds_kds.$_$.m1;
  var get_lastIndex = kotlin_kotlin.$_$.g8;
  var emptySet = kotlin_kotlin.$_$.g7;
  var Companion_getInstance_3 = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.f;
  var Level_INFO_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.c;
  var _Int8Buffer___init__impl__e20lvc = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w;
  var _Int8Buffer___get_buffer__impl__q0gulb = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.x;
  var _Float32Buffer___init__impl__403k2m = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.r;
  var _Float32Buffer___get_buffer__impl__x5nu9x = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.s;
  var sliceWithSize = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.z5;
  var get_sizeInBytes = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.y5;
  var arrayequal = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.z2;
  var clone = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m3;
  var Closeable = kotlin_com_soywiz_korlibs_korio_korio.$_$.f2;
  var Level_ERROR_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.b;
  var Companion_getInstance_4 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.h1;
  var Long = kotlin_kotlin.$_$.vh;
  var MultiBitmap = kotlin_com_soywiz_korlibs_korim_korim.$_$.y1;
  var ForcedTexId = kotlin_com_soywiz_korlibs_korim_korim.$_$.x1;
  var isInterface = kotlin_kotlin.$_$.xc;
  var get_isPowerOfTwo = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.t4;
  var extract = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u3;
  var insert5 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m4;
  var insert2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j4;
  var insert = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q4;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var toString = kotlin_kotlin.$_$.sd;
  var RectangleInt_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.p;
  var NullBitmap = kotlin_com_soywiz_korlibs_korim_korim.$_$.c2;
  var distinct = kotlin_kotlin.$_$.b7;
  var compareTo = kotlin_kotlin.$_$.ac;
  var fastArrayListOf = kotlin_com_soywiz_korlibs_kds_kds.$_$.z1;
  var Companion_getInstance_5 = kotlin_kotlin.$_$.t4;
  var fromLowHigh = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w3;
  var get_low = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u4;
  var get_high = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.d4;
  var insert8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.n4;
  var insert3 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k4;
  var toShortClamped = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j6;
  var _Short4LongPack___init__impl__ra1it0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l;
  var toIntRound = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i6;
  var _Short4LongPack___get_x__impl__ckr4u7 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.o;
  var _Short4LongPack___get_y__impl__g8vc80 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.p;
  var _Short4LongPack___get_z__impl__pym8ox = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q;
  var _Short4LongPack___get_w__impl__tmqg2q = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.n;
  var Companion_getInstance_6 = kotlin_com_soywiz_korlibs_korma_korma.$_$.b5;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var ShortCompanionObject_getInstance = kotlin_kotlin.$_$.l4;
  var Short4LongPack__hashCode_impl_xks1k5 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m;
  var insert14 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.h4;
  var ilog2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.g4;
  var insert4 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l4;
  var get_unreachable = kotlin_com_soywiz_korlibs_korio_korio.$_$.w2;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var Vector2_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.x;
  var BoundsBuilder__plus_impl_cz7f7x = kotlin_com_soywiz_korlibs_korma_korma.$_$.v1;
  var Matrix__transformX_impl_adb2o0 = kotlin_com_soywiz_korlibs_korma_korma.$_$.b3;
  var Matrix__transformY_impl_aucp27 = kotlin_com_soywiz_korlibs_korma_korma.$_$.f3;
  var min = kotlin_com_soywiz_korlibs_korma_korma.$_$.e8;
  var max = kotlin_com_soywiz_korlibs_korma_korma.$_$.d8;
  var RGBA = kotlin_com_soywiz_korlibs_korim_korim.$_$.i2;
  var RGBA__hashCode_impl_h59qf6 = kotlin_com_soywiz_korlibs_korim_korim.$_$.n;
  var extractScaledf01 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.s3;
  var insertScaledf01 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.o4;
  var insert_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.p4;
  var ByteUnits = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.f2;
  var Buffer = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.g2;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var setExtra = kotlin_com_soywiz_korlibs_kds_kds.$_$.q2;
  var getExtraTyped = kotlin_com_soywiz_korlibs_kds_kds.$_$.h2;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var get_hex = kotlin_com_soywiz_korlibs_krypto_krypto.$_$.e;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.w1;
  var arraycopy_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w2;
  var get_i32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.e4;
  var arraycopy_1 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u2;
  var get_f32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v3;
  var arraycopy_2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v2;
  var FloatBitmap32 = kotlin_com_soywiz_korlibs_korim_korim.$_$.w1;
  var get_i8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.f4;
  var arraycopy_3 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.s2;
  var Bitmap8 = kotlin_com_soywiz_korlibs_korim_korim.$_$.t1;
  var unsupported = kotlin_com_soywiz_korlibs_korio_korio.$_$.x2;
  var NativeImage = kotlin_com_soywiz_korlibs_korim_korim.$_$.b2;
  var toLong = kotlin_kotlin.$_$.qd;
  var listOf = kotlin_kotlin.$_$.p8;
  var get_size = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.x5;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.o;
  var lazy = kotlin_kotlin.$_$.cj;
  var Ref = kotlin_com_soywiz_korlibs_kds_kds.$_$.p1;
  var _RGBA___get_rf__impl__kvz75k = kotlin_com_soywiz_korlibs_korim_korim.$_$.r;
  var _RGBA___get_gf__impl__5gcu1f = kotlin_com_soywiz_korlibs_korim_korim.$_$.m;
  var _RGBA___get_bf__impl__1xz5yg = kotlin_com_soywiz_korlibs_korim_korim.$_$.l;
  var _RGBA___get_af__impl__vu36qv = kotlin_com_soywiz_korlibs_korim_korim.$_$.k;
  var numberToLong = kotlin_kotlin.$_$.kd;
  var println = kotlin_kotlin.$_$.pb;
  var slice = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.a6;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var isByteArray = kotlin_kotlin.$_$.qc;
  var isFloatArray = kotlin_kotlin.$_$.vc;
  var isIntArray = kotlin_kotlin.$_$.wc;
  var Companion_getInstance_7 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i1;
  var allocDirect = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j2;
  var getArrayInt8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.z3;
  var getArrayFloat32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.x3;
  var getArrayInt32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.y3;
  var _RgbaArray___init__impl__oib4y4 = kotlin_com_soywiz_korlibs_korim_korim.$_$.b1;
  var Bitmap32_init_$Create$ = kotlin_com_soywiz_korlibs_korim_korim.$_$.g;
  var KMutableProperty1 = kotlin_kotlin.$_$.pe;
  var Companion_getInstance_8 = kotlin_com_soywiz_korlibs_korio_korio.$_$.a1;
  var PropertyThis = kotlin_com_soywiz_korlibs_kds_kds.$_$.z;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var korAtomic = kotlin_com_soywiz_korlibs_korio_korio.$_$.s1;
  var korAtomic_0 = kotlin_com_soywiz_korlibs_korio_korio.$_$.t1;
  var Lock = kotlin_com_soywiz_korlibs_kds_kds.$_$.t;
  var incrementAndGet = kotlin_com_soywiz_korlibs_korio_korio.$_$.r1;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.t;
  var FastStringMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.f1;
  var Level_TRACE_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.d;
  var listOf_0 = kotlin_kotlin.$_$.q8;
  var Level_DEBUG_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.a;
  var Triple = kotlin_kotlin.$_$.ei;
  var stackTraceToString = kotlin_kotlin.$_$.gj;
  var last = kotlin_kotlin.$_$.l8;
  var Exception_init_$Create$ = kotlin_kotlin.$_$.j1;
  var Regex_init_$Create$ = kotlin_kotlin.$_$.c1;
  var replace = kotlin_kotlin.$_$.zf;
  var isCharSequence = kotlin_kotlin.$_$.sc;
  var trim = kotlin_kotlin.$_$.hh;
  var toIntOrNull = kotlin_kotlin.$_$.pg;
  var Size_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.u;
  var linkedHashMapOf = kotlin_com_soywiz_korlibs_kds_kds.$_$.n2;
  var KMemLayoutBuilder = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l1;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var get_portableSimpleName = kotlin_com_soywiz_korlibs_korio_korio.$_$.o2;
  var nextMultipleOf = kotlin_com_soywiz_korlibs_korma_korma.$_$.g8;
  var copyOf = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q3;
  var _RGBAPremultiplied___get_rf__impl__pt0c4y = kotlin_com_soywiz_korlibs_korim_korim.$_$.a1;
  var _RGBAPremultiplied___get_gf__impl__ivron7 = kotlin_com_soywiz_korlibs_korim_korim.$_$.z;
  var _RGBAPremultiplied___get_bf__impl__qa3on2 = kotlin_com_soywiz_korlibs_korim_korim.$_$.y;
  var _RGBAPremultiplied___get_af__impl__euwcjn = kotlin_com_soywiz_korlibs_korim_korim.$_$.x;
  var _RectCorners___get_bottomRight__impl__j7ud1v = kotlin_com_soywiz_korlibs_korma_korma.$_$.c4;
  var _RectCorners___get_topRight__impl__okc9ql = kotlin_com_soywiz_korlibs_korma_korma.$_$.e4;
  var _RectCorners___get_bottomLeft__impl__ffl7d4 = kotlin_com_soywiz_korlibs_korma_korma.$_$.b4;
  var _RectCorners___get_topLeft__impl__yn4qy0 = kotlin_com_soywiz_korlibs_korma_korma.$_$.d4;
  var Companion_getInstance_9 = kotlin_com_soywiz_korlibs_korma_korma.$_$.z4;
  var setUnalignedFloat32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v5;
  var KMutableProperty0 = kotlin_kotlin.$_$.oe;
  var Companion_getInstance_10 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j1;
  var getOrNull = kotlin_kotlin.$_$.q7;
  var Os_UNKNOWN_getInstance = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.c;
  var Comparator = kotlin_kotlin.$_$.oh;
  var compareValues = kotlin_kotlin.$_$.ra;
  var sortedWith = kotlin_kotlin.$_$.q9;
  var charSequenceLength = kotlin_kotlin.$_$.xb;
  var emptyList = kotlin_kotlin.$_$.e7;
  var reversed = kotlin_kotlin.$_$.h9;
  var HashSet_init_$Create$ = kotlin_kotlin.$_$.r;
  var plus = kotlin_kotlin.$_$.a9;
  var Companion_getInstance_11 = kotlin_com_soywiz_korlibs_korio_korio.$_$.d1;
  var invalidOp = kotlin_com_soywiz_korlibs_korio_korio.$_$.n2;
  var Indenter = kotlin_com_soywiz_korlibs_korio_korio.$_$.b4;
  var joinToString_0 = kotlin_kotlin.$_$.c8;
  var contains = kotlin_kotlin.$_$.if;
  var toFastList_0 = kotlin_com_soywiz_korlibs_kds_kds.$_$.z2;
  var nextAlignedTo = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w4;
  var maxOrNull = kotlin_kotlin.$_$.u8;
  var Iterable = kotlin_kotlin.$_$.h5;
  var get_invalidOp = kotlin_com_soywiz_korlibs_korio_korio.$_$.m2;
  var toList_0 = kotlin_kotlin.$_$.ea;
  var checkIndexOverflow = kotlin_kotlin.$_$.c6;
  var contentEquals = kotlin_kotlin.$_$.h6;
  var contentHashCode = kotlin_kotlin.$_$.l6;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.u;
  var to = kotlin_kotlin.$_$.lj;
  var plus_0 = kotlin_kotlin.$_$.y8;
  var withIndex = kotlin_kotlin.$_$.pa;
  var charSequenceGet = kotlin_kotlin.$_$.wb;
  var Char__toInt_impl_vasixd = kotlin_kotlin.$_$.o2;
  var setInt8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u5;
  var getInt8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.b4;
  var numberToChar = kotlin_kotlin.$_$.hd;
  var toString_0 = kotlin_kotlin.$_$.kj;
  var until = kotlin_kotlin.$_$.me;
  var toSet = kotlin_kotlin.$_$.ka;
  var split = kotlin_kotlin.$_$.dg;
  var setInt32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.t5;
  var getInt32 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.a4;
  var get_degrees = kotlin_com_soywiz_korlibs_korma_korma.$_$.p7;
  var Angle__times_impl_v3s9y2 = kotlin_com_soywiz_korlibs_korma_korma.$_$.p1;
  var Angle = kotlin_com_soywiz_korlibs_korma_korma.$_$.v6;
  var mapOf = kotlin_kotlin.$_$.s8;
  var Companion_getInstance_12 = kotlin_com_soywiz_korlibs_korma_korma.$_$.s4;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var ObservableProperty = kotlin_kotlin.$_$.yd;
  var delay = kotlin_com_soywiz_korlibs_korio_korio.$_$.a;
  var launchImmediately = kotlin_com_soywiz_korlibs_korio_korio.$_$.h1;
  var TimeSpan__minus_impl_5w5v8s = kotlin_com_soywiz_korlibs_klock_klock.$_$.m;
  var TimeSpan__compareTo_impl_zidry4 = kotlin_com_soywiz_korlibs_klock_klock.$_$.e;
  var Colors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.i1;
  var Signal = kotlin_com_soywiz_korlibs_korio_korio.$_$.f1;
  var Delegates_getInstance = kotlin_kotlin.$_$.n4;
  var invoke = kotlin_com_soywiz_korlibs_korio_korio.$_$.g1;
  var cancelChildren = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.d1;
  var PerformanceCounter_getInstance = kotlin_com_soywiz_korlibs_klock_klock.$_$.u;
  var TimeSpan = kotlin_com_soywiz_korlibs_klock_klock.$_$.z;
  var printStackTrace = kotlin_kotlin.$_$.fj;
  var TimeSpan__times_impl_86ofia = kotlin_com_soywiz_korlibs_klock_klock.$_$.q;
  var max_0 = kotlin_com_soywiz_korlibs_klock_klock.$_$.c1;
  var get_0 = kotlin_kotlin.$_$.gb;
  var fold = kotlin_kotlin.$_$.fb;
  var minusKey = kotlin_kotlin.$_$.hb;
  var plus_1 = kotlin_kotlin.$_$.jb;
  var Element = kotlin_kotlin.$_$.ib;
  var CoroutineDispatcher = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.r;
  var TGenQueue = kotlin_com_soywiz_korlibs_kds_kds.$_$.u1;
  var Companion_getInstance_13 = kotlin_com_soywiz_korlibs_kds_kds.$_$.s;
  var NonRecursiveLock = kotlin_com_soywiz_korlibs_kds_kds.$_$.u;
  var TimeSpan__plus_impl_qdd6zo = kotlin_com_soywiz_korlibs_klock_klock.$_$.o;
  var Companion_getInstance_14 = kotlin_kotlin.$_$.u4;
  var _Result___init__impl__xyqfz8 = kotlin_kotlin.$_$.q2;
  var createFailure = kotlin_kotlin.$_$.si;
  var Level_WARN_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.e;
  var roundMilliseconds = kotlin_com_soywiz_korlibs_klock_klock.$_$.e1;
  var get_1 = kotlin_kotlin.$_$.ab;
  var minusKey_0 = kotlin_kotlin.$_$.bb;
  var Delay = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.w;
  var DateTime__minus_impl_10njw8 = kotlin_com_soywiz_korlibs_klock_klock.$_$.b;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  var toSet_0 = kotlin_kotlin.$_$.la;
  var reversed_0 = kotlin_kotlin.$_$.je;
  var toMutableList = kotlin_kotlin.$_$.ia;
  var get_arrayUByte = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.n2;
  var get_arrayInt = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m2;
  var get_arrayFloat = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l2;
  var HtmlNativeImage = kotlin_com_soywiz_korlibs_korim_korim.$_$.w2;
  var removeSuffix = kotlin_kotlin.$_$.xf;
  var Vector2 = kotlin_com_soywiz_korlibs_korma_korma.$_$.m7;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var cancelAndJoin = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.c;
  var toVfs = kotlin_com_soywiz_korlibs_korio_korio.$_$.b2;
  var isInfinite = kotlin_kotlin.$_$.yi;
  var isNaN_0 = kotlin_kotlin.$_$.zi;
  var toHtmlNative = kotlin_com_soywiz_korlibs_korim_korim.$_$.a3;
  var MainScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.z;
  var launchImmediately_0 = kotlin_com_soywiz_korlibs_korio_korio.$_$.i1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Listener, 'Listener', classMeta, VOID, [CloseableCancellable]);
  setMetadataFor(ListenerNode, 'ListenerNode', classMeta);
  function onEvents(etypes, handler) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isEmpty' call
    tmp$ret$0 = etypes.length === 0;
    if (tmp$ret$0) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException_init_$Create$('Must have at least one event type');
    }
    var closeable = new CancellableGroup();
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < etypes.length) {
      // Inline function 'korlibs.event.EventListener.onEvents.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = etypes[tmp0];
      closeable.d2r(this.s7c(tmp0__anonymous__q1qw7t, handler));
    }
    return closeable;
  }
  function dispatch$default(type, event, result, $super) {
    result = result === VOID ? null : result;
    var tmp;
    if ($super === VOID) {
      this.u7c(type, event, result);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.u7c.call(this, type, event, result);
    }
    return tmp;
  }
  function dispatch(event) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = event;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return this.z7c(tmp$ret$2.c7d(), event);
  }
  setMetadataFor(EventListener, 'EventListener', interfaceMeta);
  setMetadataFor(BaseEventListener, 'BaseEventListener', classMeta, VOID, [EventListener]);
  setMetadataFor(EventResult, 'EventResult', classMeta);
  setMetadataFor(Event, 'Event', classMeta);
  setMetadataFor(TypedEvent, 'TypedEvent', classMeta, Event);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Type, 'Type', classMeta, Enum);
  setMetadataFor(GestureEvent, 'GestureEvent', classMeta, Event);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Type_0, 'Type', classMeta, Enum);
  setMetadataFor(KeyEvent, 'KeyEvent', classMeta, Event);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Type_1, 'Type', classMeta, Enum);
  setMetadataFor(ScrollDeltaMode, 'ScrollDeltaMode', classMeta, Enum);
  setMetadataFor(MouseEvent, 'MouseEvent', classMeta, Event);
  setMetadataFor(Status, 'Status', classMeta, Enum);
  setMetadataFor(Kind, 'Kind', classMeta, Enum);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(Touch, 'Touch', classMeta);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(Type_2, 'Type', classMeta, Enum);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(TouchEvent, 'TouchEvent', classMeta, Event);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(ReshapeEvent, 'ReshapeEvent', classMeta, TypedEvent);
  setMetadataFor(PreventDefaultException, 'PreventDefaultException', classMeta, Exception);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(Type_3, 'Type', classMeta, Enum);
  setMetadataFor(DropFileEvent, 'DropFileEvent', classMeta, Event);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(ResumeEvent, 'ResumeEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(PauseEvent, 'PauseEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(StopEvent, 'StopEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(DestroyEvent, 'DestroyEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(RenderEvent, 'RenderEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(InitEvent, 'InitEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(DisposeEvent, 'DisposeEvent', classMeta, TypedEvent);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor(FullScreenEvent, 'FullScreenEvent', classMeta, TypedEvent);
  setMetadataFor(Mode, 'Mode', classMeta, Enum);
  setMetadataFor(TouchBuilder, 'TouchBuilder', classMeta);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor(GamePadUpdateEvent, 'GamePadUpdateEvent', classMeta, Event);
  setMetadataFor(BatteryStatus, 'BatteryStatus', classMeta, Enum);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor(GamepadInfo, 'GamepadInfo', classMeta);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor(Type_4, 'Type', classMeta, Enum);
  setMetadataFor(GamePadConnectionEvent, 'GamePadConnectionEvent', classMeta, Event);
  setMetadataFor(Companion_18, 'Companion', objectMeta);
  setMetadataFor(GameButton, 'GameButton', classMeta, Enum);
  setMetadataFor(GamepadInfoEmitter, 'GamepadInfoEmitter', classMeta);
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(Key, 'Key', classMeta, Enum);
  setMetadataFor(Companion_20, 'Companion', objectMeta);
  setMetadataFor(MouseButton, 'MouseButton', classMeta, Enum);
  setMetadataFor(ISoftKeyboardConfig, 'ISoftKeyboardConfig', interfaceMeta);
  setMetadataFor(SoftKeyboardType, 'SoftKeyboardType', classMeta, Enum);
  setMetadataFor(SoftKeyboardReturnKeyType, 'SoftKeyboardReturnKeyType', classMeta, Enum);
  setMetadataFor(SoftKeyboardConfig, 'SoftKeyboardConfig', classMeta, VOID, [ISoftKeyboardConfig]);
  function get_parentFeatures() {
    return null;
  }
  function get_isUniformBuffersSupported() {
    var tmp0_safe_receiver = this.h7l();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.i7l();
    return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
  }
  function get_isVertexArraysSupported() {
    var tmp0_safe_receiver = this.h7l();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.j7l();
    return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
  }
  setMetadataFor(AGFeatures, 'AGFeatures', interfaceMeta);
  setMetadataFor(Immutable, 'Immutable', classMeta, VOID, [AGFeatures]);
  setMetadataFor(Companion_21, 'Companion', objectMeta);
  setMetadataFor(AG, 'AG', classMeta, VOID, [AGFeatures]);
  setMetadataFor(AGObject, 'AGObject', classMeta, VOID, [Closeable]);
  setMetadataFor(AGBuffer, 'AGBuffer', classMeta, AGObject);
  setMetadataFor(AGNativeObject, 'AGNativeObject', interfaceMeta);
  setMetadataFor(AGTexture, 'AGTexture', classMeta, AGObject, [AGObject, Closeable]);
  setMetadataFor(Companion_22, 'Companion', objectMeta);
  setMetadataFor(AGTextureUnitInfo, 'AGTextureUnitInfo', classMeta);
  setMetadataFor(Companion_23, 'Companion', objectMeta);
  setMetadataFor(AGTextureUnits, 'AGTextureUnits', classMeta);
  setMetadataFor(AGFrameBufferBase, 'AGFrameBufferBase', classMeta, AGObject);
  setMetadataFor(Companion_24, 'Companion', objectMeta);
  setMetadataFor(AGFrameBuffer, 'AGFrameBuffer', classMeta, VOID, [Closeable]);
  setMetadataFor(AGTextureUnitInfoArray, 'AGTextureUnitInfoArray', classMeta);
  setMetadataFor(BufferCache, 'BufferCache', classMeta);
  setMetadataFor(AGProgramWithUniforms, 'AGProgramWithUniforms', classMeta);
  setMetadataFor(AGVertexArrayObject, 'AGVertexArrayObject', classMeta);
  setMetadataFor(AGVertexData, 'AGVertexData', classMeta);
  setMetadataFor(Companion_25, 'Companion', objectMeta);
  setMetadataFor(AGStencilReference, 'AGStencilReference', classMeta);
  setMetadataFor(Companion_26, 'Companion', objectMeta);
  setMetadataFor(AGStencilOpFunc, 'AGStencilOpFunc', classMeta);
  setMetadataFor(Companion_27, 'Companion', objectMeta);
  setMetadataFor(AGColorMask, 'AGColorMask', classMeta);
  setMetadataFor(Companion_28, 'Companion', objectMeta);
  setMetadataFor(AGScissor, 'AGScissor', classMeta);
  setMetadataFor(AGBatch, 'AGBatch', classMeta);
  setMetadataFor(Companion_29, 'Companion', objectMeta);
  setMetadataFor(AGFrameBufferInfo, 'AGFrameBufferInfo', classMeta);
  setMetadataFor(Companion_30, 'Companion', objectMeta);
  setMetadataFor(AGDrawType, 'AGDrawType', classMeta);
  setMetadataFor(Companion_31, 'Companion', objectMeta);
  setMetadataFor(AGBlending, 'AGBlending', classMeta);
  setMetadataFor(AGMultiBatch, 'AGMultiBatch', classMeta);
  setMetadataFor(Companion_32, 'Companion', objectMeta);
  setMetadataFor(AGBlendFactor, 'AGBlendFactor', classMeta);
  setMetadataFor(Companion_33, 'Companion', objectMeta);
  setMetadataFor(AGBlendEquation, 'AGBlendEquation', classMeta);
  setMetadataFor(Companion_34, 'Companion', objectMeta);
  setMetadataFor(AGTextureTargetKind, 'AGTextureTargetKind', classMeta);
  setMetadataFor(Companion_35, 'Companion', objectMeta);
  setMetadataFor(AGCompareMode, 'AGCompareMode', classMeta);
  setMetadataFor(Companion_36, 'Companion', objectMeta);
  setMetadataFor(AGStencilOp, 'AGStencilOp', classMeta);
  setMetadataFor(Companion_37, 'Companion', objectMeta);
  setMetadataFor(AGCullFace, 'AGCullFace', classMeta);
  setMetadataFor(AGFinish, 'AGFinish', objectMeta);
  setMetadataFor(AGClear, 'AGClear', classMeta);
  setMetadataFor(Companion_38, 'Companion', objectMeta);
  setMetadataFor(AGIndexType, 'AGIndexType', classMeta);
  setMetadataFor(Companion_39, 'Companion', objectMeta);
  setMetadataFor(AGDepthAndFrontFace, 'AGDepthAndFrontFace', classMeta);
  setMetadataFor(Companion_40, 'Companion', objectMeta);
  setMetadataFor(Companion_41, 'Companion', objectMeta);
  setMetadataFor(AGWrapMode, 'AGWrapMode', classMeta);
  setMetadataFor(Companion_42, 'Companion', objectMeta);
  setMetadataFor(Companion_43, 'Companion', objectMeta);
  setMetadataFor(AGConfig, 'AGConfig', classMeta);
  setMetadataFor(AGBufferKind, 'AGBufferKind', classMeta, Enum);
  setMetadataFor(AGStats, 'AGStats', classMeta);
  setMetadataFor(Companion_44, 'Companion', objectMeta);
  setMetadataFor(AGTextureDrawer, 'AGTextureDrawer', classMeta);
  setMetadataFor(ShaderIndices, 'ShaderIndices', objectMeta);
  setMetadataFor(UniformBlock, 'UniformBlock', classMeta);
  setMetadataFor(ProjViewUB, 'ProjViewUB', objectMeta, UniformBlock);
  setMetadataFor(DefaultShaders, 'DefaultShaders', objectMeta);
  function get_Float1() {
    return VarType_Float1_getInstance();
  }
  function get_Float2() {
    return VarType_Float2_getInstance();
  }
  function get_Float4() {
    return VarType_Float4_getInstance();
  }
  setMetadataFor(VarTypeAccessor, 'VarTypeAccessor', interfaceMeta);
  function get_out() {
    return Output_getInstance();
  }
  function atan(y, x) {
    return Func_init_$Create$('atan', [y, x]);
  }
  function texture2D(sampler, coord) {
    return Func_init_$Create$('texture2D', [sampler, coord], VarType_Float4_getInstance());
  }
  function TERNARY(cond, otrue, ofalse) {
    return new Ternary(cond, otrue, ofalse);
  }
  function pow(b, e) {
    return Func_init_$Create$('pow', [b, e]);
  }
  function sqrt(v) {
    return Func_init_$Create$('sqrt', [v]);
  }
  function abs(v) {
    return Func_init_$Create$('abs', [v]);
  }
  function fract(v) {
    return Func_init_$Create$('fract', [v]);
  }
  function clamp(v, min, max) {
    return Func_init_$Create$('clamp', [v, min, max]);
  }
  function min_0(a, b) {
    return Func_init_$Create$('min', [a, b]);
  }
  function max_1(a, b) {
    return Func_init_$Create$('max', [a, b]);
  }
  function fwidth(a) {
    return Func_init_$Create$('fwidth', [a]);
  }
  function mix(a, b, step) {
    return Func_init_$Create$('mix', [a, b, step]);
  }
  function smoothstep(a, b, c) {
    return Func_init_$Create$('smoothstep', [a, b, c]);
  }
  function length(a) {
    return Func_init_$Create$('length', [a]);
  }
  function get_lit(_this__u8e3s4) {
    return new FloatLiteral(_this__u8e3s4);
  }
  function vec2(ops) {
    return new Vector(VarType_Float2_getInstance(), ops);
  }
  function vec4(ops) {
    return new Vector(VarType_Float4_getInstance(), ops);
  }
  function get_2(_this__u8e3s4, swizzle) {
    return new Swizzle(_this__u8e3s4, swizzle);
  }
  function get_x(_this__u8e3s4) {
    return this.n7z(_this__u8e3s4, 'x');
  }
  function get_y(_this__u8e3s4) {
    return this.n7z(_this__u8e3s4, 'y');
  }
  function get_z(_this__u8e3s4) {
    return this.n7z(_this__u8e3s4, 'z');
  }
  function get_w(_this__u8e3s4) {
    return this.n7z(_this__u8e3s4, 'w');
  }
  function get_a(_this__u8e3s4) {
    return this.n7z(_this__u8e3s4, 'w');
  }
  function unaryMinus(_this__u8e3s4) {
    return new Unop('-', _this__u8e3s4);
  }
  function minus(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '-', that);
  }
  function plus_2(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '+', that);
  }
  function times(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '*', that);
  }
  function div(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '/', that);
  }
  function eq(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '==', that);
  }
  function lt(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '<', that);
  }
  function le(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '<=', that);
  }
  function gt(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '>', that);
  }
  function minus_0(_this__u8e3s4, that) {
    return this.t83(this.j7z(_this__u8e3s4), that);
  }
  function plus_3(_this__u8e3s4, that) {
    return this.v83(this.j7z(_this__u8e3s4), that);
  }
  function times_0(_this__u8e3s4, that) {
    return this.e82(this.j7z(_this__u8e3s4), that);
  }
  function plus_4(_this__u8e3s4, that) {
    return this.v83(_this__u8e3s4, this.j7z(that));
  }
  function times_1(_this__u8e3s4, that) {
    return this.e82(_this__u8e3s4, this.j7z(that));
  }
  function div_0(_this__u8e3s4, that) {
    return this.j82(_this__u8e3s4, this.j7z(that));
  }
  function lt_0(_this__u8e3s4, that) {
    return this.z83(_this__u8e3s4, this.j7z(that));
  }
  function gt_0(_this__u8e3s4, that) {
    return this.c84(_this__u8e3s4, this.j7z(that));
  }
  function and(_this__u8e3s4, that) {
    return new Binop(_this__u8e3s4, '&&', that);
  }
  setMetadataFor(ExpressionBuilder, 'ExpressionBuilder', interfaceMeta);
  setMetadataFor(Builder, 'Builder', classMeta, VOID, [VarTypeAccessor, ExpressionBuilder]);
  function get_u_Tex() {
    return DefaultShaders_getInstance().m7z();
  }
  function get_u_ProjMat() {
    return DefaultShaders_getInstance().j81();
  }
  function get_u_ViewMat() {
    return DefaultShaders_getInstance().k81();
  }
  function get_a_Pos() {
    return DefaultShaders_getInstance().a7w_1;
  }
  function get_a_Tex() {
    return DefaultShaders_getInstance().b7w_1;
  }
  function get_a_Col() {
    return DefaultShaders_getInstance().c7w_1;
  }
  function get_v_Tex() {
    return DefaultShaders_getInstance().d7w_1;
  }
  function get_v_Col() {
    return DefaultShaders_getInstance().e7w_1;
  }
  function get_t_Temp0() {
    return DefaultShaders_getInstance().f7w_1;
  }
  function get_t_Temp1() {
    return DefaultShaders_getInstance().g7w_1;
  }
  setMetadataFor(IDefaultShaders, 'IDefaultShaders', interfaceMeta);
  setMetadataFor(ProgramBuilderDefault, 'ProgramBuilderDefault', classMeta, Builder, [Builder, IDefaultShaders]);
  setMetadataFor(GLVAO, 'GLVAO', classMeta);
  setMetadataFor(ShaderException, 'ShaderException', classMeta, RuntimeException);
  setMetadataFor(TextureUnitParams, 'TextureUnitParams', classMeta);
  setMetadataFor(AGOpengl, 'AGOpengl', classMeta, AG);
  setMetadataFor(GLGlobalState, 'GLGlobalState', classMeta);
  setMetadataFor(GLBaseObject, 'GLBaseObject', classMeta, VOID, [AGNativeObject]);
  setMetadataFor(GLBaseProgram, 'GLBaseProgram', classMeta, GLBaseObject);
  setMetadataFor(GLBuffer, 'GLBuffer', classMeta, GLBaseObject);
  setMetadataFor(GLTexture, 'GLTexture', classMeta, GLBaseObject);
  setMetadataFor(GLFrameBuffer, 'GLFrameBuffer', classMeta, GLBaseObject);
  setMetadataFor(GLProgramInfo, 'GLProgramInfo', classMeta);
  setMetadataFor(GLShaderCompiler, 'GLShaderCompiler', objectMeta);
  setMetadataFor(AGDummy, 'AGDummy', classMeta, AG);
  setMetadataFor(AGBaseLog, 'AGBaseLog', classMeta, AGDummy);
  setMetadataFor(AGPrint, 'AGPrint', classMeta, AGBaseLog);
  setMetadataFor(Kind_0, 'Kind', classMeta, Enum);
  setMetadataFor(Operand, 'Operand', classMeta);
  setMetadataFor(OperandWithArray, 'OperandWithArray', classMeta, Operand);
  setMetadataFor(Variable, 'Variable', classMeta, OperandWithArray);
  setMetadataFor(VariableWithOffset, 'VariableWithOffset', classMeta, Variable);
  setMetadataFor(TypedUniform, 'TypedUniform', classMeta, VariableWithOffset);
  setMetadataFor(Gen, 'Gen', classMeta);
  setMetadataFor(Companion_45, 'Companion', objectMeta);
  setMetadataFor(UniformBlocksBuffersRef, 'UniformBlocksBuffersRef', classMeta);
  setMetadataFor(Companion_46, 'Companion', objectMeta);
  setMetadataFor(UniformBlockBuffer, 'UniformBlockBuffer', classMeta);
  setMetadataFor(UniformsRef, 'UniformsRef', classMeta);
  setMetadataFor(Companion_47, 'Companion', objectMeta);
  setMetadataFor(GLVariant, 'GLVariant', classMeta);
  setMetadataFor(Visitor, 'Visitor', classMeta);
  setMetadataFor(GlobalsProgramVisitor, 'GlobalsProgramVisitor', classMeta, Visitor);
  setMetadataFor(Companion_48, 'Companion', objectMeta);
  setMetadataFor(Result, 'Result', classMeta);
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  function precToString(prec) {
    var tmp;
    if (!_GLVariant___get_isES__impl__yezzg8(this.f8k().k87_1)) {
      tmp = '';
    } else {
      var tmp0_subject = prec;
      var tmp0 = tmp0_subject.b2_1;
      var tmp_0;
      switch (tmp0) {
        case 0:
          tmp_0 = '';
          break;
        case 1:
          tmp_0 = 'lowp ';
          break;
        case 2:
          tmp_0 = 'mediump ';
          break;
        case 3:
          tmp_0 = 'highp ';
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      tmp = tmp_0;
    }
    return tmp;
  }
  function typeToString(type) {
    var tmp0_subject = type;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = 'void';
        break;
      case 21:
        tmp = 'vec4';
        break;
      case 1:
        tmp = 'mat2';
        break;
      case 2:
        tmp = 'mat3';
        break;
      case 3:
        tmp = 'mat4';
        break;
      case 4:
        tmp = 'sampler1D';
        break;
      case 5:
        tmp = 'sampler2D';
        break;
      case 6:
        tmp = 'sampler3D';
        break;
      case 7:
        tmp = 'samplerCube';
        break;
      default:
        var tmp1_subject = type.y88_1;
        var tmp0_0 = tmp1_subject.b2_1;
        var tmp_0;
        switch (tmp0_0) {
          case 0:
            var tmp2_subject = type.z88_1;
            var tmp_1;
            switch (tmp2_subject) {
              case 1:
                tmp_1 = 'bool';
                break;
              case 2:
                tmp_1 = 'bvec2';
                break;
              case 3:
                tmp_1 = 'bvec3';
                break;
              case 4:
                tmp_1 = 'bvec4';
                break;
              default:
                errorType(this, type);
                break;
            }

            tmp_0 = tmp_1;
            break;
          case 1:
          case 2:
          case 3:
          case 4:
          case 6:
            var tmp3_subject = type.z88_1;
            var tmp_2;
            switch (tmp3_subject) {
              case 1:
                tmp_2 = 'float';
                break;
              case 2:
                tmp_2 = 'vec2';
                break;
              case 3:
                tmp_2 = 'vec3';
                break;
              case 4:
                tmp_2 = 'vec4';
                break;
              default:
                errorType(this, type);
                break;
            }

            tmp_0 = tmp_2;
            break;
          case 5:
            var tmp4_subject = type.z88_1;
            var tmp_3;
            switch (tmp4_subject) {
              case 1:
                tmp_3 = 'int';
                break;
              case 2:
                tmp_3 = 'ivec2';
                break;
              case 3:
                tmp_3 = 'ivec3';
                break;
              case 4:
                tmp_3 = 'ivec4';
                break;
              default:
                errorType(this, type);
                break;
            }

            tmp_0 = tmp_3;
            break;
          default:
            noWhenBranchMatchedException();
            break;
        }

        tmp = tmp_0;
        break;
    }
    return tmp;
  }
  function get_arrayDecl(_this__u8e3s4) {
    return !(_this__u8e3s4.r88_1 === 1) ? '[' + _this__u8e3s4.r88_1 + ']' : '';
  }
  function get_IN() {
    return this.f8k().o8j();
  }
  function get_OUT() {
    return this.f8k().t8j();
  }
  function get_UNIFORM() {
    return this.f8k().s8j();
  }
  setMetadataFor(BaseGlslGenerator, 'BaseGlslGenerator', interfaceMeta);
  setMetadataFor(GlslGenerator, 'GlslGenerator', classMeta, VOID, [BaseGlslGenerator]);
  setMetadataFor(Companion_49, 'Companion', objectMeta);
  setMetadataFor(GlslConfig, 'GlslConfig', classMeta);
  setMetadataFor(GlslBodyGenerator, 'GlslBodyGenerator', classMeta, Visitor, [Visitor, BaseGlslGenerator]);
  setMetadataFor(ProgramLayout, 'ProgramLayout', classMeta, VOID, [Iterable]);
  setMetadataFor(Attribute, 'Attribute', classMeta, VariableWithOffset);
  setMetadataFor(VarKind, 'VarKind', classMeta, Enum);
  setMetadataFor(Companion_50, 'Companion', objectMeta);
  setMetadataFor(VarType, 'VarType', classMeta, Enum);
  setMetadataFor(Precision, 'Precision', classMeta, Enum);
  setMetadataFor(Uniform, 'Uniform', classMeta, VariableWithOffset);
  setMetadataFor(Arg, 'Arg', classMeta, Variable);
  setMetadataFor(Provider, 'Provider', classMeta);
  setMetadataFor(Sampler, 'Sampler', classMeta, Variable);
  setMetadataFor(Temp, 'Temp', classMeta, Variable);
  setMetadataFor(Varying, 'Varying', classMeta, Variable);
  setMetadataFor(SamplerVarType, 'SamplerVarType', classMeta, Enum);
  setMetadataFor(Stm, 'Stm', classMeta);
  setMetadataFor(Stms, 'Stms', classMeta, Stm);
  setMetadataFor(Set, 'Set', classMeta, Stm);
  setMetadataFor(Return, 'Return', classMeta, Stm);
  setMetadataFor(Break, 'Break', objectMeta, Stm);
  setMetadataFor(Continue, 'Continue', objectMeta, Stm);
  setMetadataFor(Discard, 'Discard', objectMeta, Stm);
  setMetadataFor(If, 'If', classMeta, Stm);
  setMetadataFor(ForSimple, 'ForSimple', classMeta, Stm);
  setMetadataFor(Raw, 'Raw', classMeta, Stm);
  setMetadataFor(Program$ExpressionBuilder$Companion$INSTANCE$1, VOID, classMeta, VOID, [ExpressionBuilder]);
  setMetadataFor(Companion_51, 'Companion', objectMeta);
  setMetadataFor(FuncBuilder, 'FuncBuilder', classMeta, Builder);
  setMetadataFor(FuncDeclGetter, 'FuncDeclGetter', classMeta);
  setMetadataFor(FuncProvider, 'FuncProvider', classMeta);
  setMetadataFor(Program$Builder$_funcs$1, VOID, classMeta, Visitor);
  setMetadataFor(Ternary, 'Ternary', classMeta, Operand);
  setMetadataFor(Unop, 'Unop', classMeta, Operand);
  setMetadataFor(Binop, 'Binop', classMeta, Operand);
  setMetadataFor(IntLiteral, 'IntLiteral', classMeta, Operand);
  setMetadataFor(FloatLiteral, 'FloatLiteral', classMeta, Operand);
  setMetadataFor(BoolLiteral, 'BoolLiteral', classMeta, Operand);
  setMetadataFor(Vector, 'Vector', classMeta, Operand);
  setMetadataFor(Swizzle, 'Swizzle', classMeta, Operand);
  setMetadataFor(ArrayAccess, 'ArrayAccess', classMeta, Operand);
  setMetadataFor(BaseFunc, 'BaseFunc', classMeta, Operand);
  setMetadataFor(Func, 'Func', classMeta, BaseFunc);
  setMetadataFor(CustomFunc, 'CustomFunc', classMeta, BaseFunc);
  setMetadataFor(FuncRef, 'FuncRef', interfaceMeta);
  setMetadataFor(BuilderContext, 'BuilderContext', classMeta);
  setMetadataFor(Program, 'Program', classMeta, VOID, [Closeable]);
  setMetadataFor(Shader, 'Shader', classMeta);
  setMetadataFor(Output, 'Output', objectMeta, Varying);
  setMetadataFor(UniformInProgram, 'UniformInProgram', classMeta);
  setMetadataFor(FuncDecl, 'FuncDecl', classMeta, VOID, [FuncRef]);
  setMetadataFor(ShaderType, 'ShaderType', classMeta, Enum);
  setMetadataFor(ContextInfo, 'ContextInfo', classMeta);
  setMetadataFor(Companion_52, 'Companion', objectMeta);
  setMetadataFor(KmlGl, 'KmlGl', classMeta, VOID, [AGFeatures]);
  function get_devicePixelRatio() {
    return 1.0;
  }
  function get_pixelsPerInch() {
    return 96.0 * this.l91();
  }
  function get_pixelsPerLogicalInchRatio() {
    var tmp = this.m91();
    Companion_getInstance_37();
    return tmp / 96.0;
  }
  function get_computedPixelRatio() {
    return this.l91() * this.n91();
  }
  setMetadataFor(DeviceDimensionsProvider, 'DeviceDimensionsProvider', interfaceMeta);
  setMetadataFor(DEFAULT, 'DEFAULT', objectMeta, VOID, [DeviceDimensionsProvider]);
  setMetadataFor(Companion_53, 'Companion', objectMeta);
  setMetadataFor(Cursor, 'Cursor', classMeta, Enum);
  setMetadataFor(MenuItem, 'MenuItem', classMeta);
  setMetadataFor(CoroutineKey, 'CoroutineKey', objectMeta);
  setMetadataFor(Quality, 'Quality', classMeta, Enum);
  setMetadataFor(GameWindow$loop$slambda, 'GameWindow$loop$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, ObservableProperty);
  setMetadataFor($waitCloseCOROUTINE$0, '$waitCloseCOROUTINE$0', classMeta, CoroutineImpl);
  setMetadataFor($loopCOROUTINE$1, '$loopCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor(GameWindow, 'GameWindow', classMeta, BaseEventListener, [BaseEventListener, DeviceDimensionsProvider, Element], VOID, VOID, [0, 1]);
  setMetadataFor(Impl, 'Impl', classMeta);
  setMetadataFor(GameWindowCreationConfig, 'GameWindowCreationConfig', classMeta);
  setMetadataFor(TimedTask, 'TimedTask', classMeta);
  setMetadataFor(Companion_54, 'Companion', objectMeta);
  setMetadataFor(sam$kotlinx_coroutines_DisposableHandle$0, 'sam$kotlinx_coroutines_DisposableHandle$0', classMeta);
  setMetadataFor(GameWindowCoroutineDispatcher, 'GameWindowCoroutineDispatcher', classMeta, CoroutineDispatcher, [CoroutineDispatcher, Delay, Closeable], VOID, VOID, [1]);
  setMetadataFor(TextClipboardData, 'TextClipboardData', classMeta);
  setMetadataFor(IntTimedCache, 'IntTimedCache', classMeta);
  setMetadataFor(AGOpenglFactory, 'AGOpenglFactory', objectMeta);
  setMetadataFor(KmlGlJsCanvas, 'KmlGlJsCanvas', classMeta, KmlGl);
  setMetadataFor(WebGLExtension, 'WebGLExtension', classMeta);
  setMetadataFor(JsGameWindow, 'JsGameWindow', classMeta, GameWindow, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(NodeJsGameWindow, 'NodeJsGameWindow', classMeta, JsGameWindow, VOID, VOID, VOID, [1, 0]);
  setMetadataFor(BrowserCanvasJsGameWindow$close$slambda, 'BrowserCanvasJsGameWindow$close$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(BrowserCanvasJsGameWindow$loop$slambda, 'BrowserCanvasJsGameWindow$loop$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor($loopCOROUTINE$2, '$loopCOROUTINE$2', classMeta, CoroutineImpl);
  setMetadataFor(BrowserCanvasJsGameWindow, 'BrowserCanvasJsGameWindow', classMeta, JsGameWindow, VOID, VOID, VOID, [1, 0]);
  setMetadataFor(DialogInterfaceJs, 'DialogInterfaceJs', classMeta, VOID, VOID, VOID, VOID, [1, 2, 4]);
  //endregion
  function Listener(func, node, base) {
    this.e7c_1 = func;
    this.f7c_1 = node;
    this.g7c_1 = base;
  }
  protoOf(Listener).n1q = function () {
    if (this.f7c_1.i7c_1.b4(this)) {
      __updateChildListenerCount(this.g7c_1, this.f7c_1.h7c_1, -1);
    }
  };
  protoOf(Listener).k7c = function () {
    this.f7c_1.i7c_1.b(this);
    __updateChildListenerCount(this.g7c_1, this.f7c_1.h7c_1, 1);
  };
  function ListenerNode(type) {
    this.h7c_1 = type;
    this.i7c_1 = FastArrayList_init_$Create$();
    this.j7c_1 = FastArrayList_init_$Create$();
  }
  function __updateChildListenerCount($this, type, delta) {
    if (delta === 0)
      return Unit_getInstance();
    if ($this.o7c_1 == null)
      $this.o7c_1 = FastIdentityMap();
    var tmp1_safe_receiver = $this.o7c_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp0_elvis_lhs = get(ensureNotNull($this.o7c_1), type);
      set(tmp1_safe_receiver, type, (tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs) + delta | 0);
    }
    var tmp2_safe_receiver = $this.m7c_1;
    if (tmp2_safe_receiver == null)
      null;
    else {
      __updateChildListenerCount(tmp2_safe_receiver, type, delta);
    }
  }
  function __updateChildListenerCount_0($this, child, add) {
    var tmp0_safe_receiver = child.o7c_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      // Inline function 'korlibs.datastructure.fastForEach' call
      // Inline function 'korlibs.datastructure.fastKeyForEach' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_safe_receiver;
      var mapIterator = tmp$ret$0.keys();
      $l$loop: while (true) {
        var v = mapIterator.next();
        if (v.done)
          break $l$loop;
        // Inline function 'korlibs.datastructure.fastForEach.<anonymous>' call
        var tmp0__anonymous__q1qw7t = v.value;
        // Inline function 'korlibs.event.BaseEventListener.__updateChildListenerCount.<anonymous>' call
        var tmp1__anonymous__uwfjfc = ensureNotNull(get(tmp0_safe_receiver, tmp0__anonymous__q1qw7t));
        __updateChildListenerCount($this, tmp0__anonymous__q1qw7t, add ? +tmp1__anonymous__uwfjfc : -tmp1__anonymous__uwfjfc | 0);
      }
    }
  }
  function BaseEventListener() {
    this.l7c_1 = null;
    this.m7c_1 = null;
    this.n7c_1 = null;
    this.o7c_1 = null;
  }
  protoOf(BaseEventListener).d1i = function (_set____db54di) {
    this.l7c_1 = _set____db54di;
  };
  protoOf(BaseEventListener).e1i = function () {
    return this.l7c_1;
  };
  protoOf(BaseEventListener).p7c = function (other) {
    var tmp0_safe_receiver = this.m7c_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      __updateChildListenerCount_0(tmp0_safe_receiver, this, false);
    }
    this.m7c_1 = other;
    var tmp1_safe_receiver = this.m7c_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      __updateChildListenerCount_0(tmp1_safe_receiver, this, true);
    }
  };
  protoOf(BaseEventListener).q7c = function (type) {
    var tmp0_safe_receiver = this.n7c_1;
    var lists = tmp0_safe_receiver == null ? null : get(tmp0_safe_receiver, type);
    var tmp1_safe_receiver = lists;
    var listeners = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.i7c_1;
    if (!(listeners == null)) {
      __updateChildListenerCount(this, type, -listeners.l() | 0);
      var tmp2_safe_receiver = this.n7c_1;
      if (tmp2_safe_receiver == null)
        null;
      else {
        clear(tmp2_safe_receiver);
      }
    }
  };
  protoOf(BaseEventListener).r7c = function () {
    var tmp0_safe_receiver = this.n7c_1;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_keys(tmp0_safe_receiver);
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : toList(tmp1_safe_receiver);
    var tmp;
    if (tmp2_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp2_elvis_lhs;
    }
    var types = tmp;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < types.l()) {
      // Inline function 'korlibs.event.BaseEventListener.clearEvents.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = types.n(tmp0);
      this.q7c(tmp0__anonymous__q1qw7t);
    }
  };
  protoOf(BaseEventListener).s7c = function (type, handler) {
    if (this.n7c_1 == null)
      this.n7c_1 = FastIdentityMap();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.getOrPut' call
      var tmp0_getOrPut = ensureNotNull(this.n7c_1);
      var res = get(tmp0_getOrPut, type);
      if (!(res == null)) {
        tmp$ret$0 = res;
        break $l$block;
      }
      var tmp$ret$1;
      // Inline function 'korlibs.event.BaseEventListener.onEvent.<anonymous>' call
      tmp$ret$1 = new ListenerNode(type);
      var out = tmp$ret$1;
      set(tmp0_getOrPut, type, out);
      tmp$ret$0 = out;
    }
    var tmp = tmp$ret$0;
    var lists = tmp instanceof ListenerNode ? tmp : THROW_CCE();
    var tmp$ret$2;
    // Inline function 'kotlin.also' call
    var tmp1_also = new Listener(handler, lists, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.event.BaseEventListener.onEvent.<anonymous>' call
    tmp1_also.k7c();
    tmp$ret$2 = tmp1_also;
    return tmp$ret$2;
  };
  protoOf(BaseEventListener).t7c = function (type) {
    var tmp0_safe_receiver = this.n7c_1;
    var tmp = tmp0_safe_receiver == null ? null : get(tmp0_safe_receiver, type);
    return tmp instanceof ListenerNode ? tmp : null;
  };
  protoOf(BaseEventListener).u7c = function (type, event, result) {
    var eventListenerCount = this.v7c(type);
    if (eventListenerCount <= 0)
      return Unit_getInstance();
    this.w7c(type, event, result);
    var listeners = this.t7c(type);
    var tmp0_safe_receiver = listeners;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.i7c_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      // Inline function 'korlibs.datastructure.iterators.fastForEachWithTemp' call
      var tmp3_fastForEachWithTemp = listeners.j7c_1;
      toFastList(tmp1_safe_receiver, tmp3_fastForEachWithTemp);
      try {
        // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
        var tmp$ret$2;
        // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp0_unsafeCast = tmp3_fastForEachWithTemp.c1d_1;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_unsafeCast;
        tmp$ret$1 = tmp$ret$0;
        tmp$ret$2 = tmp$ret$1;
        var array = tmp$ret$2;
        var n = 0;
        while (n < array.length) {
          // Inline function 'korlibs.event.BaseEventListener.dispatch.<anonymous>' call
          var tmp$ret$6;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$4;
          // Inline function 'korlibs.datastructure.get' call
          var tmp0 = n;
          n = tmp0 + 1 | 0;
          var tmp1_get = tmp0;
          var tmp$ret$3;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$3 = array;
          tmp$ret$4 = tmp$ret$3[tmp1_get];
          var tmp2_unsafeCast = tmp$ret$4;
          var tmp$ret$5;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$5 = tmp2_unsafeCast;
          tmp$ret$6 = tmp$ret$5;
          var tmp4__anonymous__pkmkx7 = tmp$ret$6;
          tmp4__anonymous__pkmkx7.e7c_1(event);
          var tmp0_safe_receiver_0 = result;
          if (tmp0_safe_receiver_0 == null)
            null;
          else {
            var tmp$ret$7;
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            var tmp0_this = tmp0_safe_receiver_0;
            var tmp1 = tmp0_this.y7c_1;
            tmp0_this.y7c_1 = tmp1 + 1 | 0;
            tmp$ret$7 = Unit_getInstance();
          }
        }
      }finally {
        tmp3_fastForEachWithTemp.e4();
      }
    }
    var tmp2_safe_receiver = result;
    if (tmp2_safe_receiver == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.event.BaseEventListener.dispatch.<anonymous>' call
      var tmp0_this_0 = tmp2_safe_receiver;
      var tmp1_0 = tmp0_this_0.x7c_1;
      tmp0_this_0.x7c_1 = tmp1_0 + 1 | 0;
      tmp$ret$8 = tmp1_0;
      tmp$ret$9 = tmp$ret$8;
    }
  };
  protoOf(BaseEventListener).w7c = function (type, event, result) {
  };
  protoOf(BaseEventListener).v7c = function (type) {
    var tmp0_safe_receiver = this.o7c_1;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.getNull' call
      tmp$ret$0 = type == null ? null : get(tmp0_safe_receiver, type);
      tmp = tmp$ret$0;
    }
    var tmp1_elvis_lhs = tmp;
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  function EventResult(iterationCount, resultCount) {
    iterationCount = iterationCount === VOID ? 0 : iterationCount;
    resultCount = resultCount === VOID ? 0 : resultCount;
    this.x7c_1 = iterationCount;
    this.y7c_1 = resultCount;
  }
  protoOf(EventResult).p1g = function () {
    this.x7c_1 = 0;
    this.y7c_1 = 0;
  };
  protoOf(EventResult).toString = function () {
    return 'EventResult(iterationCount=' + this.x7c_1 + ', resultCount=' + this.y7c_1 + ')';
  };
  protoOf(EventResult).hashCode = function () {
    var result = this.x7c_1;
    result = imul(result, 31) + this.y7c_1 | 0;
    return result;
  };
  protoOf(EventResult).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EventResult))
      return false;
    var tmp0_other_with_cast = other instanceof EventResult ? other : THROW_CCE();
    if (!(this.x7c_1 === tmp0_other_with_cast.x7c_1))
      return false;
    if (!(this.y7c_1 === tmp0_other_with_cast.y7c_1))
      return false;
    return true;
  };
  function EventListener() {
  }
  function TypedEvent(type) {
    Event.call(this);
    this.f7d_1 = type;
  }
  protoOf(TypedEvent).c7d = function () {
    return this.f7d_1;
  };
  function Event() {
    this.g7d_1 = null;
    this.h7d_1 = false;
  }
  protoOf(Event).i7d = function (_set____db54di) {
    this.g7d_1 = _set____db54di;
  };
  protoOf(Event).j7d = function () {
    this.h7d_1 = true;
  };
  var Type_MAGNIFY_instance;
  var Type_ROTATE_instance;
  var Type_SWIPE_instance;
  var Type_SMART_MAGNIFY_instance;
  function Companion() {
    Companion_instance = this;
    this.k7d_1 = values();
  }
  var Companion_instance;
  function Companion_getInstance_15() {
    Type_initEntries();
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function values() {
    return [Type_MAGNIFY_getInstance(), Type_ROTATE_getInstance(), Type_SWIPE_getInstance(), Type_SMART_MAGNIFY_getInstance()];
  }
  var Type_entriesInitialized;
  function Type_initEntries() {
    if (Type_entriesInitialized)
      return Unit_getInstance();
    Type_entriesInitialized = true;
    Type_MAGNIFY_instance = new Type('MAGNIFY', 0);
    Type_ROTATE_instance = new Type('ROTATE', 1);
    Type_SWIPE_instance = new Type('SWIPE', 2);
    Type_SMART_MAGNIFY_instance = new Type('SMART_MAGNIFY', 3);
    Companion_getInstance_15();
  }
  function Type(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Type_MAGNIFY_getInstance() {
    Type_initEntries();
    return Type_MAGNIFY_instance;
  }
  function Type_ROTATE_getInstance() {
    Type_initEntries();
    return Type_ROTATE_instance;
  }
  function Type_SWIPE_getInstance() {
    Type_initEntries();
    return Type_SWIPE_instance;
  }
  function Type_SMART_MAGNIFY_getInstance() {
    Type_initEntries();
    return Type_SMART_MAGNIFY_instance;
  }
  function GestureEvent(type, id, amountX, amountY) {
    type = type === VOID ? Type_MAGNIFY_getInstance() : type;
    id = id === VOID ? 0 : id;
    amountX = amountX === VOID ? 0.0 : amountX;
    amountY = amountY === VOID ? 0.0 : amountY;
    Event.call(this);
    this.n7d_1 = type;
    this.o7d_1 = id;
    this.p7d_1 = amountX;
    this.q7d_1 = amountY;
  }
  protoOf(GestureEvent).c7d = function () {
    return this.n7d_1;
  };
  protoOf(GestureEvent).toString = function () {
    return 'GestureEvent(type=' + this.n7d_1 + ', id=' + this.o7d_1 + ', amountX=' + this.p7d_1 + ', amountY=' + this.q7d_1 + ')';
  };
  protoOf(GestureEvent).hashCode = function () {
    var result = this.n7d_1.hashCode();
    result = imul(result, 31) + this.o7d_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.p7d_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.q7d_1) | 0;
    return result;
  };
  protoOf(GestureEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GestureEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GestureEvent ? other : THROW_CCE();
    if (!this.n7d_1.equals(tmp0_other_with_cast.n7d_1))
      return false;
    if (!(this.o7d_1 === tmp0_other_with_cast.o7d_1))
      return false;
    if (!equals(this.p7d_1, tmp0_other_with_cast.p7d_1))
      return false;
    if (!equals(this.q7d_1, tmp0_other_with_cast.q7d_1))
      return false;
    return true;
  };
  var Type_UP_instance;
  var Type_DOWN_instance;
  var Type_TYPE_instance;
  function Companion_0() {
    Companion_instance_0 = this;
    this.r7d_1 = values_0();
  }
  var Companion_instance_0;
  function Companion_getInstance_16() {
    Type_initEntries_0();
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function values_0() {
    return [Type_UP_getInstance(), Type_DOWN_getInstance(), Type_TYPE_getInstance()];
  }
  var Type_entriesInitialized_0;
  function Type_initEntries_0() {
    if (Type_entriesInitialized_0)
      return Unit_getInstance();
    Type_entriesInitialized_0 = true;
    Type_UP_instance = new Type_0('UP', 0);
    Type_DOWN_instance = new Type_0('DOWN', 1);
    Type_TYPE_instance = new Type_0('TYPE', 2);
    Companion_getInstance_16();
  }
  function Type_0(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Type_UP_getInstance() {
    Type_initEntries_0();
    return Type_UP_instance;
  }
  function Type_DOWN_getInstance() {
    Type_initEntries_0();
    return Type_DOWN_instance;
  }
  function Type_TYPE_getInstance() {
    Type_initEntries_0();
    return Type_TYPE_instance;
  }
  function KeyEvent(type, id, key, keyCode, character, shift, ctrl, alt, meta, str) {
    type = type === VOID ? Type_UP_getInstance() : type;
    id = id === VOID ? 0 : id;
    key = key === VOID ? Key_UP_getInstance() : key;
    keyCode = keyCode === VOID ? 0 : keyCode;
    character = character === VOID ? _Char___init__impl__6a9atx(0) : character;
    shift = shift === VOID ? false : shift;
    ctrl = ctrl === VOID ? false : ctrl;
    alt = alt === VOID ? false : alt;
    meta = meta === VOID ? false : meta;
    str = str === VOID ? null : str;
    Event.call(this);
    this.u7d_1 = type;
    this.v7d_1 = id;
    this.w7d_1 = key;
    this.x7d_1 = keyCode;
    this.y7d_1 = character;
    this.z7d_1 = shift;
    this.a7e_1 = ctrl;
    this.b7e_1 = alt;
    this.c7e_1 = meta;
    this.d7e_1 = str;
    this.e7e_1 = Companion_getInstance().y17_1;
  }
  protoOf(KeyEvent).c7d = function () {
    return this.u7d_1;
  };
  protoOf(KeyEvent).f7e = function () {
    var tmp0_elvis_lhs = this.d7e_1;
    return tmp0_elvis_lhs == null ? '' + new Char(this.y7d_1) : tmp0_elvis_lhs;
  };
  protoOf(KeyEvent).g7e = function () {
    return Companion_getInstance_0().d1k().b1k() ? this.c7e_1 : this.a7e_1;
  };
  protoOf(KeyEvent).toString = function () {
    return 'KeyEvent(type=' + this.u7d_1 + ', id=' + this.v7d_1 + ', key=' + this.w7d_1 + ', keyCode=' + this.x7d_1 + ', character=' + new Char(this.y7d_1) + ', shift=' + this.z7d_1 + ', ctrl=' + this.a7e_1 + ', alt=' + this.b7e_1 + ', meta=' + this.c7e_1 + ', str=' + this.d7e_1 + ')';
  };
  protoOf(KeyEvent).hashCode = function () {
    var result = this.u7d_1.hashCode();
    result = imul(result, 31) + this.v7d_1 | 0;
    result = imul(result, 31) + this.w7d_1.hashCode() | 0;
    result = imul(result, 31) + this.x7d_1 | 0;
    result = imul(result, 31) + Char__hashCode_impl_otmys(this.y7d_1) | 0;
    result = imul(result, 31) + (this.z7d_1 | 0) | 0;
    result = imul(result, 31) + (this.a7e_1 | 0) | 0;
    result = imul(result, 31) + (this.b7e_1 | 0) | 0;
    result = imul(result, 31) + (this.c7e_1 | 0) | 0;
    result = imul(result, 31) + (this.d7e_1 == null ? 0 : getStringHashCode(this.d7e_1)) | 0;
    return result;
  };
  protoOf(KeyEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof KeyEvent))
      return false;
    var tmp0_other_with_cast = other instanceof KeyEvent ? other : THROW_CCE();
    if (!this.u7d_1.equals(tmp0_other_with_cast.u7d_1))
      return false;
    if (!(this.v7d_1 === tmp0_other_with_cast.v7d_1))
      return false;
    if (!this.w7d_1.equals(tmp0_other_with_cast.w7d_1))
      return false;
    if (!(this.x7d_1 === tmp0_other_with_cast.x7d_1))
      return false;
    if (!equals(new Char(this.y7d_1), new Char(tmp0_other_with_cast.y7d_1)))
      return false;
    if (!(this.z7d_1 === tmp0_other_with_cast.z7d_1))
      return false;
    if (!(this.a7e_1 === tmp0_other_with_cast.a7e_1))
      return false;
    if (!(this.b7e_1 === tmp0_other_with_cast.b7e_1))
      return false;
    if (!(this.c7e_1 === tmp0_other_with_cast.c7e_1))
      return false;
    if (!(this.d7e_1 == tmp0_other_with_cast.d7e_1))
      return false;
    return true;
  };
  var Type_MOVE_instance;
  var Type_DRAG_instance;
  var Type_UP_instance_0;
  var Type_DOWN_instance_0;
  var Type_CLICK_instance;
  var Type_ENTER_instance;
  var Type_EXIT_instance;
  var Type_SCROLL_instance;
  function Companion_1() {
    Companion_instance_1 = this;
    this.h7e_1 = values_1();
  }
  var Companion_instance_1;
  function Companion_getInstance_17() {
    Type_initEntries_1();
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function values_1() {
    return [Type_MOVE_getInstance(), Type_DRAG_getInstance(), Type_UP_getInstance_0(), Type_DOWN_getInstance_0(), Type_CLICK_getInstance(), Type_ENTER_getInstance(), Type_EXIT_getInstance(), Type_SCROLL_getInstance()];
  }
  var Type_entriesInitialized_1;
  function Type_initEntries_1() {
    if (Type_entriesInitialized_1)
      return Unit_getInstance();
    Type_entriesInitialized_1 = true;
    Type_MOVE_instance = new Type_1('MOVE', 0);
    Type_DRAG_instance = new Type_1('DRAG', 1);
    Type_UP_instance_0 = new Type_1('UP', 2);
    Type_DOWN_instance_0 = new Type_1('DOWN', 3);
    Type_CLICK_instance = new Type_1('CLICK', 4);
    Type_ENTER_instance = new Type_1('ENTER', 5);
    Type_EXIT_instance = new Type_1('EXIT', 6);
    Type_SCROLL_instance = new Type_1('SCROLL', 7);
    Companion_getInstance_17();
  }
  var ScrollDeltaMode_PIXEL_instance;
  var ScrollDeltaMode_LINE_instance;
  var ScrollDeltaMode_PAGE_instance;
  var ScrollDeltaMode_entriesInitialized;
  function ScrollDeltaMode_initEntries() {
    if (ScrollDeltaMode_entriesInitialized)
      return Unit_getInstance();
    ScrollDeltaMode_entriesInitialized = true;
    ScrollDeltaMode_PIXEL_instance = new ScrollDeltaMode('PIXEL', 0, 1.0);
    ScrollDeltaMode_LINE_instance = new ScrollDeltaMode('LINE', 1, 10.0);
    ScrollDeltaMode_PAGE_instance = new ScrollDeltaMode('PAGE', 2, 100.0);
  }
  function Type_1(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function ScrollDeltaMode(name, ordinal, scale) {
    Enum.call(this, name, ordinal);
    this.k7e_1 = scale;
  }
  protoOf(ScrollDeltaMode).l7e = function (value, target) {
    return value * (this.k7e_1 / target.k7e_1);
  };
  function MouseEvent$requestLock$lambda() {
    return Unit_getInstance();
  }
  function Type_MOVE_getInstance() {
    Type_initEntries_1();
    return Type_MOVE_instance;
  }
  function Type_DRAG_getInstance() {
    Type_initEntries_1();
    return Type_DRAG_instance;
  }
  function Type_UP_getInstance_0() {
    Type_initEntries_1();
    return Type_UP_instance_0;
  }
  function Type_DOWN_getInstance_0() {
    Type_initEntries_1();
    return Type_DOWN_instance_0;
  }
  function Type_CLICK_getInstance() {
    Type_initEntries_1();
    return Type_CLICK_instance;
  }
  function Type_ENTER_getInstance() {
    Type_initEntries_1();
    return Type_ENTER_instance;
  }
  function Type_EXIT_getInstance() {
    Type_initEntries_1();
    return Type_EXIT_instance;
  }
  function Type_SCROLL_getInstance() {
    Type_initEntries_1();
    return Type_SCROLL_instance;
  }
  function ScrollDeltaMode_PIXEL_getInstance() {
    ScrollDeltaMode_initEntries();
    return ScrollDeltaMode_PIXEL_instance;
  }
  function ScrollDeltaMode_LINE_getInstance() {
    ScrollDeltaMode_initEntries();
    return ScrollDeltaMode_LINE_instance;
  }
  function ScrollDeltaMode_PAGE_getInstance() {
    ScrollDeltaMode_initEntries();
    return ScrollDeltaMode_PAGE_instance;
  }
  function MouseEvent(type, id, x, y, button, buttons, scrollDeltaX, scrollDeltaY, scrollDeltaZ, isShiftDown, isCtrlDown, isAltDown, isMetaDown, scaleCoords, emulated, scrollDeltaMode) {
    type = type === VOID ? Type_MOVE_getInstance() : type;
    id = id === VOID ? 0 : id;
    x = x === VOID ? 0 : x;
    y = y === VOID ? 0 : y;
    button = button === VOID ? MouseButton_NONE_getInstance() : button;
    buttons = buttons === VOID ? 0 : buttons;
    scrollDeltaX = scrollDeltaX === VOID ? 0.0 : scrollDeltaX;
    scrollDeltaY = scrollDeltaY === VOID ? 0.0 : scrollDeltaY;
    scrollDeltaZ = scrollDeltaZ === VOID ? 0.0 : scrollDeltaZ;
    isShiftDown = isShiftDown === VOID ? false : isShiftDown;
    isCtrlDown = isCtrlDown === VOID ? false : isCtrlDown;
    isAltDown = isAltDown === VOID ? false : isAltDown;
    isMetaDown = isMetaDown === VOID ? false : isMetaDown;
    scaleCoords = scaleCoords === VOID ? true : scaleCoords;
    emulated = emulated === VOID ? false : emulated;
    scrollDeltaMode = scrollDeltaMode === VOID ? ScrollDeltaMode_LINE_getInstance() : scrollDeltaMode;
    Event.call(this);
    this.o7e_1 = type;
    this.p7e_1 = id;
    this.q7e_1 = x;
    this.r7e_1 = y;
    this.s7e_1 = button;
    this.t7e_1 = buttons;
    this.u7e_1 = scrollDeltaX;
    this.v7e_1 = scrollDeltaY;
    this.w7e_1 = scrollDeltaZ;
    this.x7e_1 = isShiftDown;
    this.y7e_1 = isCtrlDown;
    this.z7e_1 = isAltDown;
    this.a7f_1 = isMetaDown;
    this.b7f_1 = scaleCoords;
    this.c7f_1 = emulated;
    this.d7f_1 = scrollDeltaMode;
    this.e7f_1 = null;
    var tmp = this;
    tmp.f7f_1 = MouseEvent$requestLock$lambda;
  }
  protoOf(MouseEvent).c7d = function () {
    return this.o7e_1;
  };
  protoOf(MouseEvent).g7f = function (other) {
    this.o7e_1 = other.o7e_1;
    this.p7e_1 = other.p7e_1;
    this.q7e_1 = other.q7e_1;
    this.r7e_1 = other.r7e_1;
    this.s7e_1 = other.s7e_1;
    this.t7e_1 = other.t7e_1;
    this.u7e_1 = other.u7e_1;
    this.v7e_1 = other.v7e_1;
    this.w7e_1 = other.w7e_1;
    this.x7e_1 = other.x7e_1;
    this.y7e_1 = other.y7e_1;
    this.z7e_1 = other.z7e_1;
    this.a7f_1 = other.a7f_1;
    this.b7f_1 = other.b7f_1;
    this.c7f_1 = other.c7f_1;
    this.d7f_1 = other.d7f_1;
  };
  protoOf(MouseEvent).h7f = function (mode) {
    return this.d7f_1.l7e(this.u7e_1, mode);
  };
  protoOf(MouseEvent).i7f = function (mode) {
    return this.d7f_1.l7e(this.v7e_1, mode);
  };
  protoOf(MouseEvent).j7f = function (mode, x, y, z) {
    this.d7f_1 = mode;
    this.u7e_1 = x;
    this.v7e_1 = y;
    this.w7e_1 = z;
  };
  protoOf(MouseEvent).toString = function () {
    return 'MouseEvent(type=' + this.o7e_1 + ', id=' + this.p7e_1 + ', x=' + this.q7e_1 + ', y=' + this.r7e_1 + ', button=' + this.s7e_1 + ', buttons=' + this.t7e_1 + ', scrollDeltaX=' + this.u7e_1 + ', scrollDeltaY=' + this.v7e_1 + ', scrollDeltaZ=' + this.w7e_1 + ', isShiftDown=' + this.x7e_1 + ', isCtrlDown=' + this.y7e_1 + ', isAltDown=' + this.z7e_1 + ', isMetaDown=' + this.a7f_1 + ', scaleCoords=' + this.b7f_1 + ', emulated=' + this.c7f_1 + ', scrollDeltaMode=' + this.d7f_1 + ')';
  };
  protoOf(MouseEvent).hashCode = function () {
    var result = this.o7e_1.hashCode();
    result = imul(result, 31) + this.p7e_1 | 0;
    result = imul(result, 31) + this.q7e_1 | 0;
    result = imul(result, 31) + this.r7e_1 | 0;
    result = imul(result, 31) + this.s7e_1.hashCode() | 0;
    result = imul(result, 31) + this.t7e_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.u7e_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.v7e_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.w7e_1) | 0;
    result = imul(result, 31) + (this.x7e_1 | 0) | 0;
    result = imul(result, 31) + (this.y7e_1 | 0) | 0;
    result = imul(result, 31) + (this.z7e_1 | 0) | 0;
    result = imul(result, 31) + (this.a7f_1 | 0) | 0;
    result = imul(result, 31) + (this.b7f_1 | 0) | 0;
    result = imul(result, 31) + (this.c7f_1 | 0) | 0;
    result = imul(result, 31) + this.d7f_1.hashCode() | 0;
    return result;
  };
  protoOf(MouseEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MouseEvent))
      return false;
    var tmp0_other_with_cast = other instanceof MouseEvent ? other : THROW_CCE();
    if (!this.o7e_1.equals(tmp0_other_with_cast.o7e_1))
      return false;
    if (!(this.p7e_1 === tmp0_other_with_cast.p7e_1))
      return false;
    if (!(this.q7e_1 === tmp0_other_with_cast.q7e_1))
      return false;
    if (!(this.r7e_1 === tmp0_other_with_cast.r7e_1))
      return false;
    if (!this.s7e_1.equals(tmp0_other_with_cast.s7e_1))
      return false;
    if (!(this.t7e_1 === tmp0_other_with_cast.t7e_1))
      return false;
    if (!equals(this.u7e_1, tmp0_other_with_cast.u7e_1))
      return false;
    if (!equals(this.v7e_1, tmp0_other_with_cast.v7e_1))
      return false;
    if (!equals(this.w7e_1, tmp0_other_with_cast.w7e_1))
      return false;
    if (!(this.x7e_1 === tmp0_other_with_cast.x7e_1))
      return false;
    if (!(this.y7e_1 === tmp0_other_with_cast.y7e_1))
      return false;
    if (!(this.z7e_1 === tmp0_other_with_cast.z7e_1))
      return false;
    if (!(this.a7f_1 === tmp0_other_with_cast.a7f_1))
      return false;
    if (!(this.b7f_1 === tmp0_other_with_cast.b7f_1))
      return false;
    if (!(this.c7f_1 === tmp0_other_with_cast.c7f_1))
      return false;
    if (!this.d7f_1.equals(tmp0_other_with_cast.d7f_1))
      return false;
    return true;
  };
  function preventDefault(reason) {
    reason = reason === VOID ? null : reason;
    throw new PreventDefaultException(reason);
  }
  var Status_ADD_instance;
  var Status_KEEP_instance;
  var Status_REMOVE_instance;
  var Status_entriesInitialized;
  function Status_initEntries() {
    if (Status_entriesInitialized)
      return Unit_getInstance();
    Status_entriesInitialized = true;
    Status_ADD_instance = new Status('ADD', 0);
    Status_KEEP_instance = new Status('KEEP', 1);
    Status_REMOVE_instance = new Status('REMOVE', 2);
  }
  var Kind_FINGER_instance;
  var Kind_MOUSE_instance;
  var Kind_STYLUS_instance;
  var Kind_ERASER_instance;
  var Kind_UNKNOWN_instance;
  var Kind_entriesInitialized;
  function Kind_initEntries() {
    if (Kind_entriesInitialized)
      return Unit_getInstance();
    Kind_entriesInitialized = true;
    Kind_FINGER_instance = new Kind('FINGER', 0);
    Kind_MOUSE_instance = new Kind('MOUSE', 1);
    Kind_STYLUS_instance = new Kind('STYLUS', 2);
    Kind_ERASER_instance = new Kind('ERASER', 3);
    Kind_UNKNOWN_instance = new Kind('UNKNOWN', 4);
  }
  function Status(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Kind(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Companion_2() {
    Companion_instance_2 = this;
    this.k7f_1 = new Touch(-1);
  }
  var Companion_instance_2;
  function Companion_getInstance_18() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function Status_ADD_getInstance() {
    Status_initEntries();
    return Status_ADD_instance;
  }
  function Status_KEEP_getInstance() {
    Status_initEntries();
    return Status_KEEP_instance;
  }
  function Status_REMOVE_getInstance() {
    Status_initEntries();
    return Status_REMOVE_instance;
  }
  function Kind_FINGER_getInstance() {
    Kind_initEntries();
    return Kind_FINGER_instance;
  }
  function Kind_MOUSE_getInstance() {
    Kind_initEntries();
    return Kind_MOUSE_instance;
  }
  function Touch(index, id, p, force, status, kind, button) {
    Companion_getInstance_18();
    index = index === VOID ? -1 : index;
    id = id === VOID ? -1 : id;
    p = p === VOID ? Companion_getInstance_1().r3r_1 : p;
    force = force === VOID ? 1.0 : force;
    status = status === VOID ? Status_KEEP_getInstance() : status;
    kind = kind === VOID ? Kind_FINGER_getInstance() : kind;
    button = button === VOID ? MouseButton_LEFT_getInstance() : button;
    this.l7f_1 = index;
    this.m7f_1 = id;
    this.n7f_1 = p;
    this.o7f_1 = force;
    this.p7f_1 = status;
    this.q7f_1 = kind;
    this.r7f_1 = button;
    this.s7f_1 = new Mixin();
  }
  protoOf(Touch).d1i = function (_set____db54di) {
    this.s7f_1.d1i(_set____db54di);
  };
  protoOf(Touch).e1i = function () {
    return this.s7f_1.e1i();
  };
  protoOf(Touch).e6g = function () {
    return this.n7f_1.p3r_1;
  };
  protoOf(Touch).f6g = function () {
    return this.n7f_1.q3r_1;
  };
  protoOf(Touch).uo = function () {
    return !this.p7f_1.equals(Status_REMOVE_getInstance());
  };
  protoOf(Touch).t7f = function (other) {
    this.m7f_1 = other.m7f_1;
    this.n7f_1 = other.n7f_1;
    this.o7f_1 = other.o7f_1;
    this.p7f_1 = other.p7f_1;
    this.q7f_1 = other.q7f_1;
    this.r7f_1 = other.r7f_1;
  };
  protoOf(Touch).hashCode = function () {
    return this.l7f_1;
  };
  protoOf(Touch).equals = function (other) {
    var tmp;
    if (other instanceof Touch) {
      tmp = this.l7f_1 === other.l7f_1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Touch).toString = function () {
    return 'Touch(index=' + this.l7f_1 + ', id=' + this.m7f_1 + ', p=' + this.n7f_1 + ', force=' + this.o7f_1 + ', status=' + this.p7f_1 + ', kind=' + this.q7f_1 + ', button=' + this.r7f_1 + ')';
  };
  var Type_START_instance;
  var Type_END_instance;
  var Type_MOVE_instance_0;
  var Type_HOVER_instance;
  var Type_UNKNOWN_instance;
  function Companion_3() {
    Companion_instance_3 = this;
    this.u7f_1 = values_2();
  }
  var Companion_instance_3;
  function Companion_getInstance_19() {
    Type_initEntries_2();
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function values_2() {
    return [Type_START_getInstance(), Type_END_getInstance(), Type_MOVE_getInstance_0(), Type_HOVER_getInstance(), Type_UNKNOWN_getInstance()];
  }
  var Type_entriesInitialized_2;
  function Type_initEntries_2() {
    if (Type_entriesInitialized_2)
      return Unit_getInstance();
    Type_entriesInitialized_2 = true;
    Type_START_instance = new Type_2('START', 0);
    Type_END_instance = new Type_2('END', 1);
    Type_MOVE_instance_0 = new Type_2('MOVE', 2);
    Type_HOVER_instance = new Type_2('HOVER', 3);
    Type_UNKNOWN_instance = new Type_2('UNKNOWN', 4);
    Companion_getInstance_19();
  }
  function Type_2(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Companion_4() {
    Companion_instance_4 = this;
    this.v7f_1 = 10;
  }
  var Companion_instance_4;
  function Companion_getInstance_20() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function TouchEvent$toString$lambda(it) {
    return it.toString();
  }
  function Type_START_getInstance() {
    Type_initEntries_2();
    return Type_START_instance;
  }
  function Type_END_getInstance() {
    Type_initEntries_2();
    return Type_END_instance;
  }
  function Type_MOVE_getInstance_0() {
    Type_initEntries_2();
    return Type_MOVE_instance_0;
  }
  function Type_HOVER_getInstance() {
    Type_initEntries_2();
    return Type_HOVER_instance;
  }
  function Type_UNKNOWN_getInstance() {
    Type_initEntries_2();
    return Type_UNKNOWN_instance;
  }
  function TouchEvent(type, screen, currentTime, scaleCoords, emulated) {
    Companion_getInstance_20();
    type = type === VOID ? Type_START_getInstance() : type;
    screen = screen === VOID ? 0 : screen;
    currentTime = currentTime === VOID ? Companion_getInstance_2().o17_1 : currentTime;
    scaleCoords = scaleCoords === VOID ? true : scaleCoords;
    emulated = emulated === VOID ? false : emulated;
    Event.call(this);
    this.y7f_1 = type;
    this.z7f_1 = screen;
    this.a7g_1 = currentTime;
    this.b7g_1 = scaleCoords;
    this.c7g_1 = emulated;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = Companion_getInstance_20().v7f_1;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$1;
      // Inline function 'korlibs.event.TouchEvent.bufferTouches.<anonymous>' call
      tmp$ret$1 = new Touch(tmp_3);
      tmp_2[tmp_3] = tmp$ret$1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.d7g_1 = tmp_2;
    this.e7g_1 = FastArrayList_init_$Create$();
    this.f7g_1 = FastArrayList_init_$Create$();
    this.g7g_1 = FastIntMap();
  }
  protoOf(TouchEvent).c7d = function () {
    return this.y7f_1;
  };
  protoOf(TouchEvent).h7g = function () {
    return this.e7g_1;
  };
  protoOf(TouchEvent).i7g = function () {
    return this.f7g_1;
  };
  protoOf(TouchEvent).j7g = function () {
    return this.h7g().l();
  };
  protoOf(TouchEvent).k7g = function (id) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.get' call
    var tmp0_get = this.g7g_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_get;
    tmp$ret$1 = tmp$ret$0.get(id);
    return tmp$ret$1;
  };
  protoOf(TouchEvent).toString = function () {
    var tmp = this.y7f_1;
    var tmp_0 = this.j7g();
    var tmp_1 = this.h7g();
    return 'TouchEvent[' + tmp + '][' + tmp_0 + '](' + joinToString(tmp_1, ', ', VOID, VOID, VOID, VOID, TouchEvent$toString$lambda) + ')';
  };
  protoOf(TouchEvent).l7g = function (type) {
    this.y7f_1 = type;
    this.a7g_1 = Companion_getInstance_2().s17();
    this.e7g_1.e4();
    // Inline function 'korlibs.datastructure.clear' call
    var tmp0_clear = this.g7g_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_clear;
    tmp$ret$0.clear();
  };
  protoOf(TouchEvent).m7g = function () {
    this.f7g_1.e4();
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.h7g();
    var n = 0;
    while (n < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.event.TouchEvent.endFrame.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0);
      if (tmp1__anonymous__uwfjfc.uo()) {
        this.f7g_1.b(tmp1__anonymous__uwfjfc);
      }
    }
  };
  protoOf(TouchEvent).n7g = function (id) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.get' call
    var tmp0_get = this.g7g_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_get;
    tmp$ret$1 = tmp$ret$0.get(id);
    var tmp0_elvis_lhs = tmp$ret$1;
    return tmp0_elvis_lhs == null ? this.o7g(id) : tmp0_elvis_lhs;
  };
  protoOf(TouchEvent).o7g = function (id) {
    var touch = this.d7g_1[this.e7g_1.l()];
    touch.m7f_1 = id;
    this.e7g_1.b(touch);
    // Inline function 'korlibs.datastructure.set' call
    var tmp0_set = this.g7g_1;
    var tmp1_set = touch.m7f_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_set;
    tmp$ret$0.set(tmp1_set, touch);
    return touch;
  };
  protoOf(TouchEvent).p7g = function (id, p, status, force, kind, button) {
    var touch = this.n7g(id);
    touch.n7f_1 = p;
    touch.p7f_1 = status;
    touch.o7f_1 = force;
    touch.q7f_1 = kind;
    touch.r7f_1 = button;
  };
  protoOf(TouchEvent).q7g = function (id, p, status, force, kind, button, $super) {
    status = status === VOID ? Status_KEEP_getInstance() : status;
    force = force === VOID ? 1.0 : force;
    kind = kind === VOID ? Kind_FINGER_getInstance() : kind;
    button = button === VOID ? MouseButton_LEFT_getInstance() : button;
    var tmp;
    if ($super === VOID) {
      this.p7g(id, p, status, force, kind, button);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.p7g.call(this, id, p, status, force, kind, button);
    }
    return tmp;
  };
  protoOf(TouchEvent).r7g = function (touch) {
    this.p7g(touch.m7f_1, touch.n7f_1, touch.p7f_1, touch.o7f_1, touch.q7f_1, touch.r7f_1);
  };
  protoOf(TouchEvent).s7g = function (other) {
    this.y7f_1 = other.y7f_1;
    this.z7f_1 = other.z7f_1;
    this.a7g_1 = other.a7g_1;
    this.b7g_1 = other.b7g_1;
    this.c7g_1 = other.c7g_1;
    var inductionVariable = 0;
    var last = Companion_getInstance_20().v7f_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.d7g_1[n].t7f(other.d7g_1[n]);
      }
       while (inductionVariable < last);
    this.e7g_1.e4();
    this.f7g_1.e4();
    // Inline function 'korlibs.datastructure.clear' call
    var tmp0_clear = this.g7g_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_clear;
    tmp$ret$0.clear();
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp1_fastForEach = other.h7g();
    var n_0 = 0;
    while (n_0 < tmp1_fastForEach.l()) {
      // Inline function 'korlibs.event.TouchEvent.copyFrom.<anonymous>' call
      var tmp0 = n_0;
      n_0 = tmp0 + 1 | 0;
      var tmp2__anonymous__z9zvc9 = tmp1_fastForEach.n(tmp0);
      var touch = this.d7g_1[tmp2__anonymous__z9zvc9.l7f_1];
      this.e7g_1.b(touch);
      if (touch.uo()) {
        this.f7g_1.b(touch);
      }
      // Inline function 'korlibs.datastructure.set' call
      var tmp0_set = this.g7g_1;
      var tmp1_set = touch.m7f_1;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp0_set;
      tmp$ret$1.set(tmp1_set, touch);
    }
  };
  protoOf(TouchEvent).t7g = function () {
    return this.y7f_1.equals(Type_START_getInstance());
  };
  protoOf(TouchEvent).u7g = function () {
    return this.y7f_1.equals(Type_END_getInstance());
  };
  protoOf(TouchEvent).hashCode = function () {
    var result = this.y7f_1.hashCode();
    result = imul(result, 31) + this.z7f_1 | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.a7g_1) | 0;
    result = imul(result, 31) + (this.b7g_1 | 0) | 0;
    result = imul(result, 31) + (this.c7g_1 | 0) | 0;
    return result;
  };
  protoOf(TouchEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TouchEvent))
      return false;
    var tmp0_other_with_cast = other instanceof TouchEvent ? other : THROW_CCE();
    if (!this.y7f_1.equals(tmp0_other_with_cast.y7f_1))
      return false;
    if (!(this.z7f_1 === tmp0_other_with_cast.z7f_1))
      return false;
    if (!equals(this.a7g_1, tmp0_other_with_cast.a7g_1))
      return false;
    if (!(this.b7g_1 === tmp0_other_with_cast.b7g_1))
      return false;
    if (!(this.c7g_1 === tmp0_other_with_cast.c7g_1))
      return false;
    return true;
  };
  function Companion_5() {
    Companion_instance_5 = this;
  }
  var Companion_instance_5;
  function Companion_getInstance_21() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function ReshapeEvent(x, y, width, height) {
    Companion_getInstance_21();
    x = x === VOID ? 0 : x;
    y = y === VOID ? 0 : y;
    width = width === VOID ? 0 : width;
    height = height === VOID ? 0 : height;
    TypedEvent.call(this, Companion_getInstance_21());
    this.y7g_1 = x;
    this.z7g_1 = y;
    this.a7h_1 = width;
    this.b7h_1 = height;
  }
  protoOf(ReshapeEvent).toString = function () {
    return 'ReshapeEvent(x=' + this.y7g_1 + ', y=' + this.z7g_1 + ', width=' + this.a7h_1 + ', height=' + this.b7h_1 + ')';
  };
  protoOf(ReshapeEvent).hashCode = function () {
    var result = this.y7g_1;
    result = imul(result, 31) + this.z7g_1 | 0;
    result = imul(result, 31) + this.a7h_1 | 0;
    result = imul(result, 31) + this.b7h_1 | 0;
    return result;
  };
  protoOf(ReshapeEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ReshapeEvent))
      return false;
    var tmp0_other_with_cast = other instanceof ReshapeEvent ? other : THROW_CCE();
    if (!(this.y7g_1 === tmp0_other_with_cast.y7g_1))
      return false;
    if (!(this.z7g_1 === tmp0_other_with_cast.z7g_1))
      return false;
    if (!(this.a7h_1 === tmp0_other_with_cast.a7h_1))
      return false;
    if (!(this.b7h_1 === tmp0_other_with_cast.b7h_1))
      return false;
    return true;
  };
  function PreventDefaultException(reason) {
    reason = reason === VOID ? null : reason;
    Exception_init_$Init$(this);
    captureStack(this, PreventDefaultException);
    this.c7h_1 = reason;
  }
  var Type_START_instance_0;
  var Type_END_instance_0;
  var Type_DROP_instance;
  function Companion_6() {
    Companion_instance_6 = this;
    this.d7h_1 = values_3();
  }
  var Companion_instance_6;
  function Companion_getInstance_22() {
    Type_initEntries_3();
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function values_3() {
    return [Type_START_getInstance_0(), Type_END_getInstance_0(), Type_DROP_getInstance()];
  }
  var Type_entriesInitialized_3;
  function Type_initEntries_3() {
    if (Type_entriesInitialized_3)
      return Unit_getInstance();
    Type_entriesInitialized_3 = true;
    Type_START_instance_0 = new Type_3('START', 0);
    Type_END_instance_0 = new Type_3('END', 1);
    Type_DROP_instance = new Type_3('DROP', 2);
    Companion_getInstance_22();
  }
  function Type_3(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Type_START_getInstance_0() {
    Type_initEntries_3();
    return Type_START_instance_0;
  }
  function Type_END_getInstance_0() {
    Type_initEntries_3();
    return Type_END_instance_0;
  }
  function Type_DROP_getInstance() {
    Type_initEntries_3();
    return Type_DROP_instance;
  }
  function DropFileEvent(type, files) {
    type = type === VOID ? Type_START_getInstance_0() : type;
    files = files === VOID ? null : files;
    Event.call(this);
    this.g7h_1 = type;
    this.h7h_1 = files;
  }
  protoOf(DropFileEvent).c7d = function () {
    return this.g7h_1;
  };
  protoOf(DropFileEvent).toString = function () {
    return 'DropFileEvent(type=' + this.g7h_1 + ', files=' + this.h7h_1 + ')';
  };
  protoOf(DropFileEvent).hashCode = function () {
    var result = this.g7h_1.hashCode();
    result = imul(result, 31) + (this.h7h_1 == null ? 0 : hashCode(this.h7h_1)) | 0;
    return result;
  };
  protoOf(DropFileEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DropFileEvent))
      return false;
    var tmp0_other_with_cast = other instanceof DropFileEvent ? other : THROW_CCE();
    if (!this.g7h_1.equals(tmp0_other_with_cast.g7h_1))
      return false;
    if (!equals(this.h7h_1, tmp0_other_with_cast.h7h_1))
      return false;
    return true;
  };
  function Companion_7() {
    Companion_instance_7 = this;
  }
  var Companion_instance_7;
  function Companion_getInstance_23() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function ResumeEvent() {
    Companion_getInstance_23();
    TypedEvent.call(this, Companion_getInstance_23());
  }
  function Companion_8() {
    Companion_instance_8 = this;
  }
  var Companion_instance_8;
  function Companion_getInstance_24() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function PauseEvent() {
    Companion_getInstance_24();
    TypedEvent.call(this, Companion_getInstance_24());
  }
  function Companion_9() {
    Companion_instance_9 = this;
  }
  var Companion_instance_9;
  function Companion_getInstance_25() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function StopEvent() {
    Companion_getInstance_25();
    TypedEvent.call(this, Companion_getInstance_25());
  }
  function Companion_10() {
    Companion_instance_10 = this;
  }
  var Companion_instance_10;
  function Companion_getInstance_26() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function DestroyEvent() {
    Companion_getInstance_26();
    TypedEvent.call(this, Companion_getInstance_26());
  }
  function Companion_11() {
    Companion_instance_11 = this;
  }
  var Companion_instance_11;
  function Companion_getInstance_27() {
    if (Companion_instance_11 == null)
      new Companion_11();
    return Companion_instance_11;
  }
  function RenderEvent() {
    Companion_getInstance_27();
    TypedEvent.call(this, Companion_getInstance_27());
    this.l7h_1 = true;
    this.m7h_1 = true;
  }
  protoOf(RenderEvent).toString = function () {
    return 'RenderEvent(update=' + this.l7h_1 + ', render=' + this.m7h_1 + ')';
  };
  function Companion_12() {
    Companion_instance_12 = this;
  }
  var Companion_instance_12;
  function Companion_getInstance_28() {
    if (Companion_instance_12 == null)
      new Companion_12();
    return Companion_instance_12;
  }
  function InitEvent() {
    Companion_getInstance_28();
    TypedEvent.call(this, Companion_getInstance_28());
  }
  function Companion_13() {
    Companion_instance_13 = this;
  }
  var Companion_instance_13;
  function Companion_getInstance_29() {
    if (Companion_instance_13 == null)
      new Companion_13();
    return Companion_instance_13;
  }
  function DisposeEvent() {
    Companion_getInstance_29();
    TypedEvent.call(this, Companion_getInstance_29());
  }
  function Companion_14() {
    Companion_instance_14 = this;
  }
  var Companion_instance_14;
  function Companion_getInstance_30() {
    if (Companion_instance_14 == null)
      new Companion_14();
    return Companion_instance_14;
  }
  function FullScreenEvent(fullscreen) {
    Companion_getInstance_30();
    fullscreen = fullscreen === VOID ? false : fullscreen;
    TypedEvent.call(this, Companion_getInstance_30());
    this.q7h_1 = fullscreen;
  }
  protoOf(FullScreenEvent).toString = function () {
    return 'FullScreenEvent(fullscreen=' + this.q7h_1 + ')';
  };
  protoOf(FullScreenEvent).hashCode = function () {
    return this.q7h_1 | 0;
  };
  protoOf(FullScreenEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FullScreenEvent))
      return false;
    var tmp0_other_with_cast = other instanceof FullScreenEvent ? other : THROW_CCE();
    if (!(this.q7h_1 === tmp0_other_with_cast.q7h_1))
      return false;
    return true;
  };
  var Mode_JS_instance;
  var Mode_Android_instance;
  var Mode_IOS_instance;
  var Mode_entriesInitialized;
  function Mode_initEntries() {
    if (Mode_entriesInitialized)
      return Unit_getInstance();
    Mode_entriesInitialized = true;
    Mode_JS_instance = new Mode('JS', 0);
    Mode_Android_instance = new Mode('Android', 1);
    Mode_IOS_instance = new Mode('IOS', 2);
  }
  function Mode(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Mode_JS_getInstance() {
    Mode_initEntries();
    return Mode_JS_instance;
  }
  function TouchBuilder() {
    this.r7h_1 = new TouchEvent();
    this.s7h_1 = new TouchEvent();
    this.t7h_1 = Mode_JS_getInstance();
  }
  protoOf(TouchBuilder).u7h = function (type, scaleCoords) {
    this.s7h_1.b7g_1 = scaleCoords;
    this.s7h_1.l7g(type);
    var tmp0_subject = this.t7h_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 2) {
      this.s7h_1.s7g(this.r7h_1);
      this.s7h_1.y7f_1 = type;
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.iterators.fastIterateRemove' call
      var tmp0_fastIterateRemove = this.s7h_1.e7g_1;
      var n = 0;
      var m = 0;
      while (n < tmp0_fastIterateRemove.l()) {
        if (m >= 0 ? !(m === n) : false) {
          tmp0_fastIterateRemove.a4(m, tmp0_fastIterateRemove.n(n));
        }
        var tmp$ret$1;
        // Inline function 'korlibs.event.TouchBuilder.startFrame.<anonymous>' call
        var tmp1__anonymous__uwfjfc = tmp0_fastIterateRemove.n(n);
        var tmp;
        if (tmp1__anonymous__uwfjfc.uo()) {
          tmp1__anonymous__uwfjfc.p7f_1 = Status_KEEP_getInstance();
          tmp = false;
        } else {
          // Inline function 'korlibs.datastructure.remove' call
          var tmp0_remove = this.s7h_1.g7g_1;
          var tmp1_remove = tmp1__anonymous__uwfjfc.m7f_1;
          var tmp$ret$0;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$0 = tmp0_remove;
          tmp$ret$0.delete(tmp1_remove);
          tmp = true;
        }
        tmp$ret$1 = tmp;
        if (tmp$ret$1) {
          var tmp0_0 = m;
          m = tmp0_0 - 1 | 0;
        }
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp2 = m;
        m = tmp2 + 1 | 0;
      }
      while (tmp0_fastIterateRemove.l() > m) {
        tmp0_fastIterateRemove.c4(tmp0_fastIterateRemove.l() - 1 | 0);
      }
      tmp$ret$2 = tmp0_fastIterateRemove;
    } else
    ;
  };
  protoOf(TouchBuilder).v7h = function () {
    var tmp0_subject = this.t7h_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var tmp0_fastForEach = this.r7h_1.h7g();
        var n = 0;
        while (n < tmp0_fastForEach.l()) {
          // Inline function 'korlibs.event.TouchBuilder.endFrame.<anonymous>' call
          var tmp0_0 = n;
          n = tmp0_0 + 1 | 0;
          var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0_0);
          if (this.s7h_1.k7g(tmp1__anonymous__uwfjfc.m7f_1) == null) {
            if (tmp1__anonymous__uwfjfc.uo()) {
              tmp1__anonymous__uwfjfc.p7f_1 = Status_REMOVE_getInstance();
              this.s7h_1.r7g(tmp1__anonymous__uwfjfc);
            }
          }
        }

        break;
      case 2:
        break;
      default:
        ;
        break;
    }
    this.s7h_1.m7g();
    this.r7h_1.s7g(this.s7h_1);
    return this.s7h_1;
  };
  protoOf(TouchBuilder).w7h = function (id, p, force, kind, button) {
    var touch = this.s7h_1.n7g(id);
    touch.n7f_1 = p;
    touch.o7f_1 = force;
    touch.q7f_1 = kind;
    touch.r7f_1 = button;
    var tmp0_subject = this.t7h_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 2) {
      var tmp = touch;
      var tmp1_subject = this.s7h_1.y7f_1;
      var tmp0_0 = tmp1_subject.b2_1;
      switch (tmp0_0) {
        case 0:
          tmp.p7f_1 = Status_ADD_getInstance();
          break;
        case 1:
          tmp.p7f_1 = Status_REMOVE_getInstance();
          break;
        default:
          tmp.p7f_1 = Status_KEEP_getInstance();
          break;
      }
    } else {
      var oldTouch = this.r7h_1.k7g(id);
      touch.p7f_1 = oldTouch == null ? Status_ADD_getInstance() : Status_KEEP_getInstance();
    }
  };
  protoOf(TouchBuilder).x7h = function (id, p, force, kind, button, $super) {
    force = force === VOID ? 1.0 : force;
    kind = kind === VOID ? Kind_FINGER_getInstance() : kind;
    button = button === VOID ? MouseButton_LEFT_getInstance() : button;
    var tmp;
    if ($super === VOID) {
      this.w7h(id, p, force, kind, button);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.w7h.call(this, id, p, force, kind, button);
    }
    return tmp;
  };
  function Companion_15() {
    Companion_instance_15 = this;
  }
  var Companion_instance_15;
  function Companion_getInstance_31() {
    if (Companion_instance_15 == null)
      new Companion_15();
    return Companion_instance_15;
  }
  function GamePadUpdateEvent(gamepadsLength, gamepads) {
    Companion_getInstance_31();
    gamepadsLength = gamepadsLength === VOID ? 0 : gamepadsLength;
    var tmp;
    if (gamepads === VOID) {
      var tmp_0 = 0;
      Companion_getInstance_32();
      var tmp_1 = 4;
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.arrayOfNulls' call
      tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
      tmp$ret$0_0 = Unit_getInstance();
      var tmp_2 = tmp$ret$0;
      while (tmp_0 < tmp_1) {
        var tmp_3 = tmp_0;
        var tmp$ret$1;
        var tmp$ret$1_0;
        // Inline function 'korlibs.event.GamePadUpdateEvent.<init>.<anonymous>' call
        tmp$ret$1 = new GamepadInfo(tmp_3);
        tmp$ret$1_0 = Unit_getInstance();
        tmp_2[tmp_3] = tmp$ret$1;
        tmp_0 = tmp_0 + 1 | 0;
      }
      tmp = tmp_2;
    } else {
      tmp = gamepads;
    }
    gamepads = tmp;
    Event.call(this);
    this.a7i_1 = gamepadsLength;
    this.b7i_1 = gamepads;
  }
  protoOf(GamePadUpdateEvent).c7d = function () {
    return Companion_getInstance_31();
  };
  protoOf(GamePadUpdateEvent).toString = function () {
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filter' call
    var tmp1_filter = this.b7i_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = ArrayList_init_$Create$();
    var indexedObject = tmp1_filter;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'korlibs.event.GamePadUpdateEvent.toString.<anonymous>' call
      tmp$ret$0 = element.d7i_1;
      if (tmp$ret$0) {
        tmp0_filterTo.b(element);
      }
    }
    tmp$ret$1 = tmp0_filterTo;
    tmp$ret$2 = tmp$ret$1;
    var tmp3_map = tmp$ret$2;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map, 10));
    var tmp0_iterator = tmp3_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$3;
      // Inline function 'korlibs.event.GamePadUpdateEvent.toString.<anonymous>' call
      tmp$ret$3 = item.j7i();
      tmp2_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp2_mapTo;
    tmp$ret$5 = tmp$ret$4;
    return 'GamePadUpdateEvent(' + tmp$ret$5 + ')';
  };
  protoOf(GamePadUpdateEvent).hashCode = function () {
    var result = this.a7i_1;
    result = imul(result, 31) + hashCode(this.b7i_1) | 0;
    return result;
  };
  protoOf(GamePadUpdateEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GamePadUpdateEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GamePadUpdateEvent ? other : THROW_CCE();
    if (!(this.a7i_1 === tmp0_other_with_cast.a7i_1))
      return false;
    if (!equals(this.b7i_1, tmp0_other_with_cast.b7i_1))
      return false;
    return true;
  };
  var BatteryStatus_CHARGING_instance;
  var BatteryStatus_DISCHARGING_instance;
  var BatteryStatus_FULL_instance;
  var BatteryStatus_UNKNOWN_instance;
  var BatteryStatus_entriesInitialized;
  function BatteryStatus_initEntries() {
    if (BatteryStatus_entriesInitialized)
      return Unit_getInstance();
    BatteryStatus_entriesInitialized = true;
    BatteryStatus_CHARGING_instance = new BatteryStatus('CHARGING', 0);
    BatteryStatus_DISCHARGING_instance = new BatteryStatus('DISCHARGING', 1);
    BatteryStatus_FULL_instance = new BatteryStatus('FULL', 2);
    BatteryStatus_UNKNOWN_instance = new BatteryStatus('UNKNOWN', 3);
  }
  function BatteryStatus(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Companion_16() {
    Companion_instance_16 = this;
    this.k7i_1 = 'Wireless Controller';
    this.l7i_1 = 4;
    this.m7i_1 = 0.075;
  }
  protoOf(Companion_16).n7i = function (value, margin, apply) {
    var tmp;
    if (apply) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.absoluteValue' call
      tmp$ret$0 = Math.abs(value);
      tmp = tmp$ret$0 < margin;
    } else {
      tmp = false;
    }
    if (tmp)
      return 0.0;
    return value;
  };
  protoOf(Companion_16).o7i = function (value, margin, apply, $super) {
    margin = margin === VOID ? 0.075 : margin;
    apply = apply === VOID ? true : apply;
    return $super === VOID ? this.n7i(value, margin, apply) : $super.n7i.call(this, value, margin, apply);
  };
  var Companion_instance_16;
  function Companion_getInstance_32() {
    if (Companion_instance_16 == null)
      new Companion_16();
    return Companion_instance_16;
  }
  function BatteryStatus_UNKNOWN_getInstance() {
    BatteryStatus_initEntries();
    return BatteryStatus_UNKNOWN_instance;
  }
  function GamepadInfo(index, connected, name, rawButtons, batteryLevel, name2, batteryStatus) {
    Companion_getInstance_32();
    index = index === VOID ? 0 : index;
    connected = connected === VOID ? false : connected;
    name = name === VOID ? null : name;
    var tmp;
    if (rawButtons === VOID) {
      Companion_getInstance_34();
      tmp = new Float32Array(32);
    } else {
      tmp = rawButtons;
    }
    rawButtons = tmp;
    batteryLevel = batteryLevel === VOID ? 1.0 : batteryLevel;
    name2 = name2 === VOID ? Companion_getInstance_32().k7i_1 : name2;
    batteryStatus = batteryStatus === VOID ? BatteryStatus_UNKNOWN_getInstance() : batteryStatus;
    this.c7i_1 = index;
    this.d7i_1 = connected;
    this.e7i_1 = name;
    this.f7i_1 = rawButtons;
    this.g7i_1 = batteryLevel;
    this.h7i_1 = name2;
    this.i7i_1 = batteryStatus;
  }
  protoOf(GamepadInfo).p7i = function () {
    var tmp0_elvis_lhs = this.e7i_1;
    return (tmp0_elvis_lhs == null ? 'unknown' : tmp0_elvis_lhs) + ' - ' + this.h7i_1;
  };
  protoOf(GamepadInfo).q7i = function (that) {
    this.c7i_1 = that.c7i_1;
    this.e7i_1 = that.e7i_1;
    this.h7i_1 = that.h7i_1;
    this.d7i_1 = that.d7i_1;
    this.g7i_1 = that.g7i_1;
    this.i7i_1 = that.i7i_1;
    var tmp = that.f7i_1;
    var tmp_0 = this.f7i_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.f7i_1.length;
    var tmp1_min = that.f7i_1.length;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    arraycopy(tmp, 0, tmp_0, 0, tmp$ret$0);
  };
  protoOf(GamepadInfo).r7i = function (button) {
    return this.f7i_1[button.u7i()];
  };
  protoOf(GamepadInfo).v7i = function (includeButtons) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.event.GamepadInfo.toStringEx.<anonymous>' call
    tmp0_apply.bc('Gamepad[' + this.c7i_1 + '][' + this.p7i() + ']');
    if (includeButtons) {
      tmp0_apply.bc('[');
      var count = 0;
      var indexedObject = values_5();
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var button = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        var value = this.r7i(button);
        if (!(value === 0.0)) {
          if (count > 0) {
            tmp0_apply.bc(',');
          }
          tmp0_apply.bc('' + button + '=' + get_niceStr(value));
          var tmp1 = count;
          count = tmp1 + 1 | 0;
        }
      }
      tmp0_apply.bc(']');
    }
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  protoOf(GamepadInfo).j7i = function (includeButtons, $super) {
    includeButtons = includeButtons === VOID ? true : includeButtons;
    return $super === VOID ? this.v7i(includeButtons) : $super.v7i.call(this, includeButtons);
  };
  protoOf(GamepadInfo).toString = function () {
    return this.v7i(false);
  };
  var Type_CONNECTED_instance;
  var Type_DISCONNECTED_instance;
  function Companion_17() {
    Companion_instance_17 = this;
    this.w7i_1 = values_4();
  }
  protoOf(Companion_17).x7i = function (connected) {
    return connected ? Type_CONNECTED_getInstance() : Type_DISCONNECTED_getInstance();
  };
  var Companion_instance_17;
  function Companion_getInstance_33() {
    Type_initEntries_4();
    if (Companion_instance_17 == null)
      new Companion_17();
    return Companion_instance_17;
  }
  function values_4() {
    return [Type_CONNECTED_getInstance(), Type_DISCONNECTED_getInstance()];
  }
  var Type_entriesInitialized_4;
  function Type_initEntries_4() {
    if (Type_entriesInitialized_4)
      return Unit_getInstance();
    Type_entriesInitialized_4 = true;
    Type_CONNECTED_instance = new Type_4('CONNECTED', 0);
    Type_DISCONNECTED_instance = new Type_4('DISCONNECTED', 1);
    Companion_getInstance_33();
  }
  function Type_4(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Type_CONNECTED_getInstance() {
    Type_initEntries_4();
    return Type_CONNECTED_instance;
  }
  function Type_DISCONNECTED_getInstance() {
    Type_initEntries_4();
    return Type_DISCONNECTED_instance;
  }
  function GamePadConnectionEvent(type, gamepad) {
    type = type === VOID ? Type_CONNECTED_getInstance() : type;
    gamepad = gamepad === VOID ? 0 : gamepad;
    Event.call(this);
    this.a7j_1 = type;
    this.b7j_1 = gamepad;
  }
  protoOf(GamePadConnectionEvent).c7d = function () {
    return this.a7j_1;
  };
  protoOf(GamePadConnectionEvent).toString = function () {
    return 'GamePadConnectionEvent(type=' + this.a7j_1 + ', gamepad=' + this.b7j_1 + ')';
  };
  protoOf(GamePadConnectionEvent).hashCode = function () {
    var result = this.a7j_1.hashCode();
    result = imul(result, 31) + this.b7j_1 | 0;
    return result;
  };
  protoOf(GamePadConnectionEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GamePadConnectionEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GamePadConnectionEvent ? other : THROW_CCE();
    if (!this.a7j_1.equals(tmp0_other_with_cast.a7j_1))
      return false;
    if (!(this.b7j_1 === tmp0_other_with_cast.b7j_1))
      return false;
    return true;
  };
  var GameButton_LEFT_instance;
  var GameButton_RIGHT_instance;
  var GameButton_UP_instance;
  var GameButton_DOWN_instance;
  var GameButton_BUTTON_SOUTH_instance;
  var GameButton_BUTTON_EAST_instance;
  var GameButton_BUTTON_WEST_instance;
  var GameButton_BUTTON_NORTH_instance;
  var GameButton_SELECT_instance;
  var GameButton_START_instance;
  var GameButton_SYSTEM_instance;
  var GameButton_L1_instance;
  var GameButton_R1_instance;
  var GameButton_L2_instance;
  var GameButton_R2_instance;
  var GameButton_L3_instance;
  var GameButton_R3_instance;
  var GameButton_LX_instance;
  var GameButton_LY_instance;
  var GameButton_RX_instance;
  var GameButton_RY_instance;
  var GameButton_BUTTON4_instance;
  var GameButton_BUTTON5_instance;
  var GameButton_BUTTON6_instance;
  var GameButton_BUTTON7_instance;
  var GameButton_BUTTON8_instance;
  var GameButton_RECORD_instance;
  var GameButton_DPADX_instance;
  var GameButton_DPADY_instance;
  function Companion_18() {
    Companion_instance_18 = this;
    this.c7j_1 = values_5();
    this.d7j_1 = 32;
  }
  var Companion_instance_18;
  function Companion_getInstance_34() {
    GameButton_initEntries();
    if (Companion_instance_18 == null)
      new Companion_18();
    return Companion_instance_18;
  }
  function values_5() {
    return [GameButton_LEFT_getInstance(), GameButton_RIGHT_getInstance(), GameButton_UP_getInstance(), GameButton_DOWN_getInstance(), GameButton_BUTTON_SOUTH_getInstance(), GameButton_BUTTON_EAST_getInstance(), GameButton_BUTTON_WEST_getInstance(), GameButton_BUTTON_NORTH_getInstance(), GameButton_SELECT_getInstance(), GameButton_START_getInstance(), GameButton_SYSTEM_getInstance(), GameButton_L1_getInstance(), GameButton_R1_getInstance(), GameButton_L2_getInstance(), GameButton_R2_getInstance(), GameButton_L3_getInstance(), GameButton_R3_getInstance(), GameButton_LX_getInstance(), GameButton_LY_getInstance(), GameButton_RX_getInstance(), GameButton_RY_getInstance(), GameButton_BUTTON4_getInstance(), GameButton_BUTTON5_getInstance(), GameButton_BUTTON6_getInstance(), GameButton_BUTTON7_getInstance(), GameButton_BUTTON8_getInstance(), GameButton_RECORD_getInstance(), GameButton_DPADX_getInstance(), GameButton_DPADY_getInstance()];
  }
  var GameButton_entriesInitialized;
  function GameButton_initEntries() {
    if (GameButton_entriesInitialized)
      return Unit_getInstance();
    GameButton_entriesInitialized = true;
    GameButton_LEFT_instance = new GameButton('LEFT', 0);
    GameButton_RIGHT_instance = new GameButton('RIGHT', 1);
    GameButton_UP_instance = new GameButton('UP', 2);
    GameButton_DOWN_instance = new GameButton('DOWN', 3);
    GameButton_BUTTON_SOUTH_instance = new GameButton('BUTTON_SOUTH', 4);
    GameButton_BUTTON_EAST_instance = new GameButton('BUTTON_EAST', 5);
    GameButton_BUTTON_WEST_instance = new GameButton('BUTTON_WEST', 6);
    GameButton_BUTTON_NORTH_instance = new GameButton('BUTTON_NORTH', 7);
    GameButton_SELECT_instance = new GameButton('SELECT', 8);
    GameButton_START_instance = new GameButton('START', 9);
    GameButton_SYSTEM_instance = new GameButton('SYSTEM', 10);
    GameButton_L1_instance = new GameButton('L1', 11);
    GameButton_R1_instance = new GameButton('R1', 12);
    GameButton_L2_instance = new GameButton('L2', 13);
    GameButton_R2_instance = new GameButton('R2', 14);
    GameButton_L3_instance = new GameButton('L3', 15);
    GameButton_R3_instance = new GameButton('R3', 16);
    GameButton_LX_instance = new GameButton('LX', 17);
    GameButton_LY_instance = new GameButton('LY', 18);
    GameButton_RX_instance = new GameButton('RX', 19);
    GameButton_RY_instance = new GameButton('RY', 20);
    GameButton_BUTTON4_instance = new GameButton('BUTTON4', 21);
    GameButton_BUTTON5_instance = new GameButton('BUTTON5', 22);
    GameButton_BUTTON6_instance = new GameButton('BUTTON6', 23);
    GameButton_BUTTON7_instance = new GameButton('BUTTON7', 24);
    GameButton_BUTTON8_instance = new GameButton('BUTTON8', 25);
    GameButton_RECORD_instance = new GameButton('RECORD', 26);
    GameButton_DPADX_instance = new GameButton('DPADX', 27);
    GameButton_DPADY_instance = new GameButton('DPADY', 28);
    Companion_getInstance_34();
  }
  function GameButton(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(GameButton).u7i = function () {
    return this.b2_1;
  };
  function GamepadInfoEmitter(dispatcher) {
    this.e7j_1 = dispatcher;
    var tmp = this;
    Companion_getInstance_32();
    tmp.f7j_1 = booleanArray(4);
    this.g7j_1 = new GamePadUpdateEvent();
    this.h7j_1 = new GamePadConnectionEvent();
    this.i7j_1 = new IntArrayList();
  }
  protoOf(GamepadInfoEmitter).j7j = function () {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.g7j_1.b7i_1;
    var n = 0;
    while (n < tmp0_fastForEach.length) {
      // Inline function 'korlibs.event.GamepadInfoEmitter.dispatchGamepadUpdateStart.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach[tmp0];
      tmp1__anonymous__uwfjfc.d7i_1 = false;
    }
    this.g7j_1.a7i_1 = 0;
  };
  protoOf(GamepadInfoEmitter).k7j = function (info) {
    var tmp0_this = this.g7j_1;
    var tmp1 = tmp0_this.a7i_1;
    tmp0_this.a7i_1 = tmp1 + 1 | 0;
    var index = tmp1;
    var pad = this.g7j_1.b7i_1[index];
    pad.q7i(info);
    pad.d7i_1 = true;
    pad.c7i_1 = index;
  };
  protoOf(GamepadInfoEmitter).l7j = function (out) {
    out.e4();
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.g7j_1.b7i_1;
    var n = 0;
    while (n < tmp0_fastForEach.length) {
      // Inline function 'korlibs.event.GamepadInfoEmitter.dispatchGamepadUpdateEnd.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach[tmp0];
      if (!(this.f7j_1[tmp1__anonymous__uwfjfc.c7i_1] === tmp1__anonymous__uwfjfc.d7i_1)) {
        this.f7j_1[tmp1__anonymous__uwfjfc.c7i_1] = tmp1__anonymous__uwfjfc.d7i_1;
        out.q1a(tmp1__anonymous__uwfjfc.c7i_1);
        if (!tmp1__anonymous__uwfjfc.d7i_1) {
          tmp1__anonymous__uwfjfc.e7i_1 = null;
        }
        var tmp$ret$0;
        // Inline function 'kotlin.apply' call
        var tmp0_apply = this.h7j_1;
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.event.GamepadInfoEmitter.dispatchGamepadUpdateEnd.<anonymous>.<anonymous>' call
        tmp0_apply.a7j_1 = Companion_getInstance_33().x7i(tmp1__anonymous__uwfjfc.d7i_1);
        tmp0_apply.b7j_1 = tmp1__anonymous__uwfjfc.c7i_1;
        tmp$ret$0 = tmp0_apply;
        this.e7j_1.b7d(tmp$ret$0);
      }
    }
    this.e7j_1.b7d(this.g7j_1);
    return out;
  };
  function GameButton_LEFT_getInstance() {
    GameButton_initEntries();
    return GameButton_LEFT_instance;
  }
  function GameButton_RIGHT_getInstance() {
    GameButton_initEntries();
    return GameButton_RIGHT_instance;
  }
  function GameButton_UP_getInstance() {
    GameButton_initEntries();
    return GameButton_UP_instance;
  }
  function GameButton_DOWN_getInstance() {
    GameButton_initEntries();
    return GameButton_DOWN_instance;
  }
  function GameButton_BUTTON_SOUTH_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON_SOUTH_instance;
  }
  function GameButton_BUTTON_EAST_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON_EAST_instance;
  }
  function GameButton_BUTTON_WEST_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON_WEST_instance;
  }
  function GameButton_BUTTON_NORTH_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON_NORTH_instance;
  }
  function GameButton_SELECT_getInstance() {
    GameButton_initEntries();
    return GameButton_SELECT_instance;
  }
  function GameButton_START_getInstance() {
    GameButton_initEntries();
    return GameButton_START_instance;
  }
  function GameButton_SYSTEM_getInstance() {
    GameButton_initEntries();
    return GameButton_SYSTEM_instance;
  }
  function GameButton_L1_getInstance() {
    GameButton_initEntries();
    return GameButton_L1_instance;
  }
  function GameButton_R1_getInstance() {
    GameButton_initEntries();
    return GameButton_R1_instance;
  }
  function GameButton_L2_getInstance() {
    GameButton_initEntries();
    return GameButton_L2_instance;
  }
  function GameButton_R2_getInstance() {
    GameButton_initEntries();
    return GameButton_R2_instance;
  }
  function GameButton_L3_getInstance() {
    GameButton_initEntries();
    return GameButton_L3_instance;
  }
  function GameButton_R3_getInstance() {
    GameButton_initEntries();
    return GameButton_R3_instance;
  }
  function GameButton_LX_getInstance() {
    GameButton_initEntries();
    return GameButton_LX_instance;
  }
  function GameButton_LY_getInstance() {
    GameButton_initEntries();
    return GameButton_LY_instance;
  }
  function GameButton_RX_getInstance() {
    GameButton_initEntries();
    return GameButton_RX_instance;
  }
  function GameButton_RY_getInstance() {
    GameButton_initEntries();
    return GameButton_RY_instance;
  }
  function GameButton_BUTTON4_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON4_instance;
  }
  function GameButton_BUTTON5_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON5_instance;
  }
  function GameButton_BUTTON6_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON6_instance;
  }
  function GameButton_BUTTON7_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON7_instance;
  }
  function GameButton_BUTTON8_getInstance() {
    GameButton_initEntries();
    return GameButton_BUTTON8_instance;
  }
  function GameButton_RECORD_getInstance() {
    GameButton_initEntries();
    return GameButton_RECORD_instance;
  }
  function GameButton_DPADX_getInstance() {
    GameButton_initEntries();
    return GameButton_DPADX_instance;
  }
  function GameButton_DPADY_getInstance() {
    GameButton_initEntries();
    return GameButton_DPADY_instance;
  }
  var Key_SPACE_instance;
  var Key_APOSTROPHE_instance;
  var Key_COMMA_instance;
  var Key_MINUS_instance;
  var Key_PLUS_instance;
  var Key_PERIOD_instance;
  var Key_SLASH_instance;
  var Key_N0_instance;
  var Key_N1_instance;
  var Key_N2_instance;
  var Key_N3_instance;
  var Key_N4_instance;
  var Key_N5_instance;
  var Key_N6_instance;
  var Key_N7_instance;
  var Key_N8_instance;
  var Key_N9_instance;
  var Key_N11_instance;
  var Key_N12_instance;
  var Key_N3D_MODE_instance;
  var Key_SEMICOLON_instance;
  var Key_EQUAL_instance;
  var Key_AT_instance;
  var Key_A_instance;
  var Key_B_instance;
  var Key_C_instance;
  var Key_D_instance;
  var Key_E_instance;
  var Key_F_instance;
  var Key_G_instance;
  var Key_H_instance;
  var Key_I_instance;
  var Key_J_instance;
  var Key_K_instance;
  var Key_L_instance;
  var Key_M_instance;
  var Key_N_instance;
  var Key_O_instance;
  var Key_P_instance;
  var Key_Q_instance;
  var Key_R_instance;
  var Key_S_instance;
  var Key_T_instance;
  var Key_U_instance;
  var Key_V_instance;
  var Key_W_instance;
  var Key_X_instance;
  var Key_Y_instance;
  var Key_Z_instance;
  var Key_LEFT_BRACKET_instance;
  var Key_BACKSLASH_instance;
  var Key_RIGHT_BRACKET_instance;
  var Key_GRAVE_ACCENT_instance;
  var Key_WORLD_1_instance;
  var Key_WORLD_2_instance;
  var Key_ESCAPE_instance;
  var Key_META_instance;
  var Key_ENTER_instance;
  var Key_TAB_instance;
  var Key_BACKSPACE_instance;
  var Key_INSERT_instance;
  var Key_DELETE_instance;
  var Key_RIGHT_instance;
  var Key_LEFT_instance;
  var Key_DOWN_instance;
  var Key_UP_instance;
  var Key_PAGE_UP_instance;
  var Key_PAGE_DOWN_instance;
  var Key_FUNCTION_instance;
  var Key_HELP_instance;
  var Key_MUTE_instance;
  var Key_VOLUME_DOWN_instance;
  var Key_VOLUME_UP_instance;
  var Key_VOLUME_MUTE_instance;
  var Key_HOME_instance;
  var Key_END_instance;
  var Key_CAPS_LOCK_instance;
  var Key_SCROLL_LOCK_instance;
  var Key_NUM_LOCK_instance;
  var Key_PRINT_SCREEN_instance;
  var Key_PAUSE_instance;
  var Key_F1_instance;
  var Key_F2_instance;
  var Key_F3_instance;
  var Key_F4_instance;
  var Key_F5_instance;
  var Key_F6_instance;
  var Key_F7_instance;
  var Key_F8_instance;
  var Key_F9_instance;
  var Key_F10_instance;
  var Key_F11_instance;
  var Key_F12_instance;
  var Key_F13_instance;
  var Key_F14_instance;
  var Key_F15_instance;
  var Key_F16_instance;
  var Key_F17_instance;
  var Key_F18_instance;
  var Key_F19_instance;
  var Key_F20_instance;
  var Key_F21_instance;
  var Key_F22_instance;
  var Key_F23_instance;
  var Key_F24_instance;
  var Key_F25_instance;
  var Key_KP_0_instance;
  var Key_KP_1_instance;
  var Key_KP_2_instance;
  var Key_KP_3_instance;
  var Key_KP_4_instance;
  var Key_KP_5_instance;
  var Key_KP_6_instance;
  var Key_KP_7_instance;
  var Key_KP_8_instance;
  var Key_KP_9_instance;
  var Key_KP_DECIMAL_instance;
  var Key_KP_DIVIDE_instance;
  var Key_KP_MULTIPLY_instance;
  var Key_KP_SUBTRACT_instance;
  var Key_KP_ADD_instance;
  var Key_KP_COMMA_instance;
  var Key_KP_DOT_instance;
  var Key_KP_ENTER_instance;
  var Key_KP_EQUAL_instance;
  var Key_KP_SEPARATOR_instance;
  var Key_KP_LEFT_PAREN_instance;
  var Key_KP_RIGHT_PAREN_instance;
  var Key_SHIFT_instance;
  var Key_CONTROL_instance;
  var Key_ALT_instance;
  var Key_SUPER_instance;
  var Key_MENU_instance;
  var Key_BACK_instance;
  var Key_BACKQUOTE_instance;
  var Key_QUOTE_instance;
  var Key_KP_UP_instance;
  var Key_KP_DOWN_instance;
  var Key_KP_LEFT_instance;
  var Key_KP_RIGHT_instance;
  var Key_UNDERLINE_instance;
  var Key_SELECT_KEY_instance;
  var Key_CANCEL_instance;
  var Key_CLEAR_instance;
  var Key_OPEN_BRACKET_instance;
  var Key_CLOSE_BRACKET_instance;
  var Key_PLAY_instance;
  var Key_NONAME_instance;
  var Key_FINAL_instance;
  var Key_OEM102_instance;
  var Key_OEM1_instance;
  var Key_OEM2_instance;
  var Key_OEM3_instance;
  var Key_OEM4_instance;
  var Key_OEM5_instance;
  var Key_OEM6_instance;
  var Key_OEM7_instance;
  var Key_OEM8_instance;
  var Key_LEFT_MENU_instance;
  var Key_RIGHT_MENU_instance;
  var Key_SLEEP_instance;
  var Key_SNAPSHOT_instance;
  var Key_INFO_instance;
  var Key_XBUTTON1_instance;
  var Key_XBUTTON2_instance;
  var Key_XBUTTON3_instance;
  var Key_XBUTTON4_instance;
  var Key_XBUTTON5_instance;
  var Key_XBUTTON6_instance;
  var Key_XBUTTON7_instance;
  var Key_XBUTTON8_instance;
  var Key_XBUTTON9_instance;
  var Key_XBUTTON10_instance;
  var Key_XBUTTON11_instance;
  var Key_XBUTTON12_instance;
  var Key_XBUTTON13_instance;
  var Key_XBUTTON14_instance;
  var Key_XBUTTON15_instance;
  var Key_XBUTTON16_instance;
  var Key_XBUTTON_A_instance;
  var Key_XBUTTON_B_instance;
  var Key_XBUTTON_C_instance;
  var Key_XBUTTON_L1_instance;
  var Key_XBUTTON_L2_instance;
  var Key_XBUTTON_MODE_instance;
  var Key_XBUTTON_R1_instance;
  var Key_XBUTTON_R2_instance;
  var Key_XBUTTON_SELECT_instance;
  var Key_XBUTTON_START_instance;
  var Key_XBUTTON_THUMBL_instance;
  var Key_XBUTTON_THUMBR_instance;
  var Key_XBUTTON_X_instance;
  var Key_XBUTTON_Y_instance;
  var Key_XBUTTON_Z_instance;
  var Key_DPAD_CENTER_instance;
  var Key_DPAD_DOWN_instance;
  var Key_DPAD_DOWN_LEFT_instance;
  var Key_DPAD_DOWN_RIGHT_instance;
  var Key_DPAD_LEFT_instance;
  var Key_DPAD_RIGHT_instance;
  var Key_DPAD_UP_instance;
  var Key_DPAD_UP_LEFT_instance;
  var Key_DPAD_UP_RIGHT_instance;
  var Key_DVR_instance;
  var Key_EISU_instance;
  var Key_ENDCALL_instance;
  var Key_ENVELOPE_instance;
  var Key_EXPLORER_instance;
  var Key_FOCUS_instance;
  var Key_FORWARD_instance;
  var Key_GRAVE_instance;
  var Key_GUIDE_instance;
  var Key_HEADSETHOOK_instance;
  var Key_ABNT_C1_instance;
  var Key_ABNT_C2_instance;
  var Key_ATTN_instance;
  var Key_CRSEL_instance;
  var Key_EREOF_instance;
  var Key_EXECUTE_instance;
  var Key_EXSEL_instance;
  var Key_ICO_CLEAR_instance;
  var Key_ICO_HELP_instance;
  var Key_HENKAN_instance;
  var Key_PAIRING_instance;
  var Key_APP_SWITCH_instance;
  var Key_ASSIST_instance;
  var Key_AVR_INPUT_instance;
  var Key_AVR_POWER_instance;
  var Key_BOOKMARK_instance;
  var Key_BREAK_instance;
  var Key_CAPTIONS_instance;
  var Key_CAMERA_instance;
  var Key_CALL_instance;
  var Key_CALENDAR_instance;
  var Key_BRIGHTNESS_DOWN_instance;
  var Key_BRIGHTNESS_UP_instance;
  var Key_CHANNEL_DOWN_instance;
  var Key_CHANNEL_UP_instance;
  var Key_CALCULATOR_instance;
  var Key_CONTACTS_instance;
  var Key_NOTIFICATION_instance;
  var Key_COPY_instance;
  var Key_CUT_instance;
  var Key_PASTE_instance;
  var Key_SEARCH_instance;
  var Key_SETTINGS_instance;
  var Key_SOFT_LEFT_instance;
  var Key_SOFT_RIGHT_instance;
  var Key_SOFT_SLEEP_instance;
  var Key_STAR_instance;
  var Key_STB_INPUT_instance;
  var Key_STB_POWER_instance;
  var Key_STEM_1_instance;
  var Key_STEM_2_instance;
  var Key_STEM_3_instance;
  var Key_STEM_PRIMARY_instance;
  var Key_SWITCH_CHARSET_instance;
  var Key_SYM_instance;
  var Key_SYSRQ_instance;
  var Key_NUM_instance;
  var Key_TV_instance;
  var Key_TV_ANTENNA_CABLE_instance;
  var Key_TV_AUDIO_DESCRIPTION_instance;
  var Key_TV_AUDIO_DESCRIPTION_MIX_DOWN_instance;
  var Key_TV_AUDIO_DESCRIPTION_MIX_UP_instance;
  var Key_TV_CONTENTS_MENU_instance;
  var Key_TV_DATA_SERVICE_instance;
  var Key_TV_INPUT_instance;
  var Key_TV_INPUT_COMPONENT_1_instance;
  var Key_TV_INPUT_COMPONENT_2_instance;
  var Key_TV_INPUT_COMPOSITE_1_instance;
  var Key_TV_INPUT_COMPOSITE_2_instance;
  var Key_TV_INPUT_HDMI_1_instance;
  var Key_TV_INPUT_HDMI_2_instance;
  var Key_TV_INPUT_HDMI_3_instance;
  var Key_TV_INPUT_HDMI_4_instance;
  var Key_TV_INPUT_VGA_1_instance;
  var Key_TV_MEDIA_CONTEXT_MENU_instance;
  var Key_TV_NETWORK_instance;
  var Key_TV_NUMBER_ENTRY_instance;
  var Key_TV_POWER_instance;
  var Key_TV_RADIO_SERVICE_instance;
  var Key_TV_SATELLITE_instance;
  var Key_TV_SATELLITE_BS_instance;
  var Key_TV_SATELLITE_CS_instance;
  var Key_TV_SATELLITE_SERVICE_instance;
  var Key_TV_TELETEXT_instance;
  var Key_TV_TERRESTRIAL_ANALOG_instance;
  var Key_TV_TERRESTRIAL_DIGITAL_instance;
  var Key_TV_TIMER_PROGRAMMING_instance;
  var Key_TV_ZOOM_MODE_instance;
  var Key_VOICE_ASSIST_instance;
  var Key_WAKEUP_instance;
  var Key_WINDOW_instance;
  var Key_YEN_instance;
  var Key_ZENKAKU_HANKAKU_instance;
  var Key_ZOOM_IN_instance;
  var Key_ZOOM_OUT_instance;
  var Key_SYSTEM_NAVIGATION_DOWN_instance;
  var Key_SYSTEM_NAVIGATION_LEFT_instance;
  var Key_SYSTEM_NAVIGATION_RIGHT_instance;
  var Key_SYSTEM_NAVIGATION_UP_instance;
  var Key_PICTSYMBOLS_instance;
  var Key_POUND_instance;
  var Key_POWER_instance;
  var Key_PROG_BLUE_instance;
  var Key_PROG_GREEN_instance;
  var Key_PROG_RED_instance;
  var Key_PROG_YELLOW_instance;
  var Key_RO_instance;
  var Key_OEM_ATTN_instance;
  var Key_OEM_AUTO_instance;
  var Key_OEM_AX_instance;
  var Key_OEM_BACKTAB_instance;
  var Key_OEM_CLEAR_instance;
  var Key_OEM_COMMA_instance;
  var Key_OEM_COPY_instance;
  var Key_OEM_CUSEL_instance;
  var Key_OEM_ENLW_instance;
  var Key_OEM_FINISH_instance;
  var Key_OEM_FJ_LOYA_instance;
  var Key_OEM_FJ_MASSHOU_instance;
  var Key_OEM_FJ_ROYA_instance;
  var Key_OEM_FJ_TOUROKU_instance;
  var Key_OEM_JUMP_instance;
  var Key_OEM_MINUS_instance;
  var Key_OEM_PA1_instance;
  var Key_OEM_PA2_instance;
  var Key_OEM_PA3_instance;
  var Key_OEM_PERIOD_instance;
  var Key_OEM_PLUS_instance;
  var Key_OEM_RESET_instance;
  var Key_OEM_WSCTRL_instance;
  var Key_PA1_instance;
  var Key_PACKET_instance;
  var Key_PROCESSKEY_instance;
  var Key_ZOOM_instance;
  var Key_NONE_instance;
  var Key_ACCEPT_instance;
  var Key_APPS_instance;
  var Key_BROWSER_BACK_instance;
  var Key_BROWSER_FAVORITES_instance;
  var Key_BROWSER_FORWARD_instance;
  var Key_BROWSER_HOME_instance;
  var Key_BROWSER_REFRESH_instance;
  var Key_BROWSER_SEARCH_instance;
  var Key_BROWSER_STOP_instance;
  var Key_CAPITAL_instance;
  var Key_CONVERT_instance;
  var Key_ICO_00_instance;
  var Key_JUNJA_instance;
  var Key_KANA_instance;
  var Key_KANJI_instance;
  var Key_KATAKANA_HIRAGANA_instance;
  var Key_LANGUAGE_SWITCH_instance;
  var Key_MUHENKAN_instance;
  var Key_LAUNCH_APP1_instance;
  var Key_LAUNCH_APP2_instance;
  var Key_LAUNCH_MAIL_instance;
  var Key_LAUNCH_MEDIA_SELECT_instance;
  var Key_LEFT_BUTTON_instance;
  var Key_MIDDLE_BUTTON_instance;
  var Key_MUSIC_instance;
  var Key_MEDIA_NEXT_TRACK_instance;
  var Key_MEDIA_PLAY_PAUSE_instance;
  var Key_MEDIA_PREV_TRACK_instance;
  var Key_MEDIA_STOP_instance;
  var Key_MEDIA_PLAY_instance;
  var Key_MEDIA_PAUSE_instance;
  var Key_MEDIA_AUDIO_TRACK_instance;
  var Key_MEDIA_CLOSE_instance;
  var Key_MEDIA_EJECT_instance;
  var Key_MEDIA_FAST_FORWARD_instance;
  var Key_MEDIA_RECORD_instance;
  var Key_MEDIA_REWIND_instance;
  var Key_MEDIA_SKIP_BACKWARD_instance;
  var Key_MEDIA_SKIP_FORWARD_instance;
  var Key_MEDIA_STEP_BACKWARD_instance;
  var Key_MEDIA_STEP_FORWARD_instance;
  var Key_MEDIA_TOP_MENU_instance;
  var Key_MODECHANGE_instance;
  var Key_NEXT_instance;
  var Key_NONCONVERT_instance;
  var Key_OEM_FJ_JISHO_instance;
  var Key_PRIOR_instance;
  var Key_RIGHT_BUTTON_instance;
  var Key_LAST_CHANNEL_instance;
  var Key_MANNER_MODE_instance;
  var Key_NAVIGATE_IN_instance;
  var Key_NAVIGATE_NEXT_instance;
  var Key_NAVIGATE_OUT_instance;
  var Key_NAVIGATE_PREVIOUS_instance;
  var Key_HYPHEN_instance;
  var Key_UNDEFINED_instance;
  var Key_UNKNOWN_instance;
  function Companion_19() {
    Companion_instance_19 = this;
    this.m7j_1 = Key_UNKNOWN_getInstance().b2_1 + 1 | 0;
    this.n7j_1 = Key_ENTER_getInstance();
    this.o7j_1 = Key_N0_getInstance();
    this.p7j_1 = Key_N1_getInstance();
    this.q7j_1 = Key_N2_getInstance();
    this.r7j_1 = Key_N3_getInstance();
    this.s7j_1 = Key_N4_getInstance();
    this.t7j_1 = Key_N5_getInstance();
    this.u7j_1 = Key_N6_getInstance();
    this.v7j_1 = Key_N7_getInstance();
    this.w7j_1 = Key_N8_getInstance();
    this.x7j_1 = Key_N9_getInstance();
  }
  protoOf(Companion_19).y7j = function () {
    return Key_CONTROL_getInstance();
  };
  protoOf(Companion_19).z7j = function () {
    return Key_CONTROL_getInstance();
  };
  protoOf(Companion_19).a7k = function () {
    return Key_SHIFT_getInstance();
  };
  protoOf(Companion_19).b7k = function () {
    return Key_SHIFT_getInstance();
  };
  protoOf(Companion_19).c7k = function () {
    return Key_ALT_getInstance();
  };
  protoOf(Companion_19).d7k = function () {
    return Key_ALT_getInstance();
  };
  protoOf(Companion_19).e7k = function () {
    return Key_SUPER_getInstance();
  };
  protoOf(Companion_19).f7k = function () {
    return Key_SUPER_getInstance();
  };
  var Companion_instance_19;
  function Companion_getInstance_35() {
    Key_initEntries();
    if (Companion_instance_19 == null)
      new Companion_19();
    return Companion_instance_19;
  }
  var Key_entriesInitialized;
  function Key_initEntries() {
    if (Key_entriesInitialized)
      return Unit_getInstance();
    Key_entriesInitialized = true;
    Key_SPACE_instance = new Key('SPACE', 0);
    Key_APOSTROPHE_instance = new Key('APOSTROPHE', 1);
    Key_COMMA_instance = new Key('COMMA', 2);
    Key_MINUS_instance = new Key('MINUS', 3);
    Key_PLUS_instance = new Key('PLUS', 4);
    Key_PERIOD_instance = new Key('PERIOD', 5);
    Key_SLASH_instance = new Key('SLASH', 6);
    Key_N0_instance = new Key('N0', 7);
    Key_N1_instance = new Key('N1', 8);
    Key_N2_instance = new Key('N2', 9);
    Key_N3_instance = new Key('N3', 10);
    Key_N4_instance = new Key('N4', 11);
    Key_N5_instance = new Key('N5', 12);
    Key_N6_instance = new Key('N6', 13);
    Key_N7_instance = new Key('N7', 14);
    Key_N8_instance = new Key('N8', 15);
    Key_N9_instance = new Key('N9', 16);
    Key_N11_instance = new Key('N11', 17);
    Key_N12_instance = new Key('N12', 18);
    Key_N3D_MODE_instance = new Key('N3D_MODE', 19);
    Key_SEMICOLON_instance = new Key('SEMICOLON', 20);
    Key_EQUAL_instance = new Key('EQUAL', 21);
    Key_AT_instance = new Key('AT', 22);
    Key_A_instance = new Key('A', 23);
    Key_B_instance = new Key('B', 24);
    Key_C_instance = new Key('C', 25);
    Key_D_instance = new Key('D', 26);
    Key_E_instance = new Key('E', 27);
    Key_F_instance = new Key('F', 28);
    Key_G_instance = new Key('G', 29);
    Key_H_instance = new Key('H', 30);
    Key_I_instance = new Key('I', 31);
    Key_J_instance = new Key('J', 32);
    Key_K_instance = new Key('K', 33);
    Key_L_instance = new Key('L', 34);
    Key_M_instance = new Key('M', 35);
    Key_N_instance = new Key('N', 36);
    Key_O_instance = new Key('O', 37);
    Key_P_instance = new Key('P', 38);
    Key_Q_instance = new Key('Q', 39);
    Key_R_instance = new Key('R', 40);
    Key_S_instance = new Key('S', 41);
    Key_T_instance = new Key('T', 42);
    Key_U_instance = new Key('U', 43);
    Key_V_instance = new Key('V', 44);
    Key_W_instance = new Key('W', 45);
    Key_X_instance = new Key('X', 46);
    Key_Y_instance = new Key('Y', 47);
    Key_Z_instance = new Key('Z', 48);
    Key_LEFT_BRACKET_instance = new Key('LEFT_BRACKET', 49);
    Key_BACKSLASH_instance = new Key('BACKSLASH', 50);
    Key_RIGHT_BRACKET_instance = new Key('RIGHT_BRACKET', 51);
    Key_GRAVE_ACCENT_instance = new Key('GRAVE_ACCENT', 52);
    Key_WORLD_1_instance = new Key('WORLD_1', 53);
    Key_WORLD_2_instance = new Key('WORLD_2', 54);
    Key_ESCAPE_instance = new Key('ESCAPE', 55);
    Key_META_instance = new Key('META', 56);
    Key_ENTER_instance = new Key('ENTER', 57);
    Key_TAB_instance = new Key('TAB', 58);
    Key_BACKSPACE_instance = new Key('BACKSPACE', 59);
    Key_INSERT_instance = new Key('INSERT', 60);
    Key_DELETE_instance = new Key('DELETE', 61);
    Key_RIGHT_instance = new Key('RIGHT', 62);
    Key_LEFT_instance = new Key('LEFT', 63);
    Key_DOWN_instance = new Key('DOWN', 64);
    Key_UP_instance = new Key('UP', 65);
    Key_PAGE_UP_instance = new Key('PAGE_UP', 66);
    Key_PAGE_DOWN_instance = new Key('PAGE_DOWN', 67);
    Key_FUNCTION_instance = new Key('FUNCTION', 68);
    Key_HELP_instance = new Key('HELP', 69);
    Key_MUTE_instance = new Key('MUTE', 70);
    Key_VOLUME_DOWN_instance = new Key('VOLUME_DOWN', 71);
    Key_VOLUME_UP_instance = new Key('VOLUME_UP', 72);
    Key_VOLUME_MUTE_instance = new Key('VOLUME_MUTE', 73);
    Key_HOME_instance = new Key('HOME', 74);
    Key_END_instance = new Key('END', 75);
    Key_CAPS_LOCK_instance = new Key('CAPS_LOCK', 76);
    Key_SCROLL_LOCK_instance = new Key('SCROLL_LOCK', 77);
    Key_NUM_LOCK_instance = new Key('NUM_LOCK', 78);
    Key_PRINT_SCREEN_instance = new Key('PRINT_SCREEN', 79);
    Key_PAUSE_instance = new Key('PAUSE', 80);
    Key_F1_instance = new Key('F1', 81);
    Key_F2_instance = new Key('F2', 82);
    Key_F3_instance = new Key('F3', 83);
    Key_F4_instance = new Key('F4', 84);
    Key_F5_instance = new Key('F5', 85);
    Key_F6_instance = new Key('F6', 86);
    Key_F7_instance = new Key('F7', 87);
    Key_F8_instance = new Key('F8', 88);
    Key_F9_instance = new Key('F9', 89);
    Key_F10_instance = new Key('F10', 90);
    Key_F11_instance = new Key('F11', 91);
    Key_F12_instance = new Key('F12', 92);
    Key_F13_instance = new Key('F13', 93);
    Key_F14_instance = new Key('F14', 94);
    Key_F15_instance = new Key('F15', 95);
    Key_F16_instance = new Key('F16', 96);
    Key_F17_instance = new Key('F17', 97);
    Key_F18_instance = new Key('F18', 98);
    Key_F19_instance = new Key('F19', 99);
    Key_F20_instance = new Key('F20', 100);
    Key_F21_instance = new Key('F21', 101);
    Key_F22_instance = new Key('F22', 102);
    Key_F23_instance = new Key('F23', 103);
    Key_F24_instance = new Key('F24', 104);
    Key_F25_instance = new Key('F25', 105);
    Key_KP_0_instance = new Key('KP_0', 106);
    Key_KP_1_instance = new Key('KP_1', 107);
    Key_KP_2_instance = new Key('KP_2', 108);
    Key_KP_3_instance = new Key('KP_3', 109);
    Key_KP_4_instance = new Key('KP_4', 110);
    Key_KP_5_instance = new Key('KP_5', 111);
    Key_KP_6_instance = new Key('KP_6', 112);
    Key_KP_7_instance = new Key('KP_7', 113);
    Key_KP_8_instance = new Key('KP_8', 114);
    Key_KP_9_instance = new Key('KP_9', 115);
    Key_KP_DECIMAL_instance = new Key('KP_DECIMAL', 116);
    Key_KP_DIVIDE_instance = new Key('KP_DIVIDE', 117);
    Key_KP_MULTIPLY_instance = new Key('KP_MULTIPLY', 118);
    Key_KP_SUBTRACT_instance = new Key('KP_SUBTRACT', 119);
    Key_KP_ADD_instance = new Key('KP_ADD', 120);
    Key_KP_COMMA_instance = new Key('KP_COMMA', 121);
    Key_KP_DOT_instance = new Key('KP_DOT', 122);
    Key_KP_ENTER_instance = new Key('KP_ENTER', 123);
    Key_KP_EQUAL_instance = new Key('KP_EQUAL', 124);
    Key_KP_SEPARATOR_instance = new Key('KP_SEPARATOR', 125);
    Key_KP_LEFT_PAREN_instance = new Key('KP_LEFT_PAREN', 126);
    Key_KP_RIGHT_PAREN_instance = new Key('KP_RIGHT_PAREN', 127);
    Key_SHIFT_instance = new Key('SHIFT', 128);
    Key_CONTROL_instance = new Key('CONTROL', 129);
    Key_ALT_instance = new Key('ALT', 130);
    Key_SUPER_instance = new Key('SUPER', 131);
    Key_MENU_instance = new Key('MENU', 132);
    Key_BACK_instance = new Key('BACK', 133);
    Key_BACKQUOTE_instance = new Key('BACKQUOTE', 134);
    Key_QUOTE_instance = new Key('QUOTE', 135);
    Key_KP_UP_instance = new Key('KP_UP', 136);
    Key_KP_DOWN_instance = new Key('KP_DOWN', 137);
    Key_KP_LEFT_instance = new Key('KP_LEFT', 138);
    Key_KP_RIGHT_instance = new Key('KP_RIGHT', 139);
    Key_UNDERLINE_instance = new Key('UNDERLINE', 140);
    Key_SELECT_KEY_instance = new Key('SELECT_KEY', 141);
    Key_CANCEL_instance = new Key('CANCEL', 142);
    Key_CLEAR_instance = new Key('CLEAR', 143);
    Key_OPEN_BRACKET_instance = new Key('OPEN_BRACKET', 144);
    Key_CLOSE_BRACKET_instance = new Key('CLOSE_BRACKET', 145);
    Key_PLAY_instance = new Key('PLAY', 146);
    Key_NONAME_instance = new Key('NONAME', 147);
    Key_FINAL_instance = new Key('FINAL', 148);
    Key_OEM102_instance = new Key('OEM102', 149);
    Key_OEM1_instance = new Key('OEM1', 150);
    Key_OEM2_instance = new Key('OEM2', 151);
    Key_OEM3_instance = new Key('OEM3', 152);
    Key_OEM4_instance = new Key('OEM4', 153);
    Key_OEM5_instance = new Key('OEM5', 154);
    Key_OEM6_instance = new Key('OEM6', 155);
    Key_OEM7_instance = new Key('OEM7', 156);
    Key_OEM8_instance = new Key('OEM8', 157);
    Key_LEFT_MENU_instance = new Key('LEFT_MENU', 158);
    Key_RIGHT_MENU_instance = new Key('RIGHT_MENU', 159);
    Key_SLEEP_instance = new Key('SLEEP', 160);
    Key_SNAPSHOT_instance = new Key('SNAPSHOT', 161);
    Key_INFO_instance = new Key('INFO', 162);
    Key_XBUTTON1_instance = new Key('XBUTTON1', 163);
    Key_XBUTTON2_instance = new Key('XBUTTON2', 164);
    Key_XBUTTON3_instance = new Key('XBUTTON3', 165);
    Key_XBUTTON4_instance = new Key('XBUTTON4', 166);
    Key_XBUTTON5_instance = new Key('XBUTTON5', 167);
    Key_XBUTTON6_instance = new Key('XBUTTON6', 168);
    Key_XBUTTON7_instance = new Key('XBUTTON7', 169);
    Key_XBUTTON8_instance = new Key('XBUTTON8', 170);
    Key_XBUTTON9_instance = new Key('XBUTTON9', 171);
    Key_XBUTTON10_instance = new Key('XBUTTON10', 172);
    Key_XBUTTON11_instance = new Key('XBUTTON11', 173);
    Key_XBUTTON12_instance = new Key('XBUTTON12', 174);
    Key_XBUTTON13_instance = new Key('XBUTTON13', 175);
    Key_XBUTTON14_instance = new Key('XBUTTON14', 176);
    Key_XBUTTON15_instance = new Key('XBUTTON15', 177);
    Key_XBUTTON16_instance = new Key('XBUTTON16', 178);
    Key_XBUTTON_A_instance = new Key('XBUTTON_A', 179);
    Key_XBUTTON_B_instance = new Key('XBUTTON_B', 180);
    Key_XBUTTON_C_instance = new Key('XBUTTON_C', 181);
    Key_XBUTTON_L1_instance = new Key('XBUTTON_L1', 182);
    Key_XBUTTON_L2_instance = new Key('XBUTTON_L2', 183);
    Key_XBUTTON_MODE_instance = new Key('XBUTTON_MODE', 184);
    Key_XBUTTON_R1_instance = new Key('XBUTTON_R1', 185);
    Key_XBUTTON_R2_instance = new Key('XBUTTON_R2', 186);
    Key_XBUTTON_SELECT_instance = new Key('XBUTTON_SELECT', 187);
    Key_XBUTTON_START_instance = new Key('XBUTTON_START', 188);
    Key_XBUTTON_THUMBL_instance = new Key('XBUTTON_THUMBL', 189);
    Key_XBUTTON_THUMBR_instance = new Key('XBUTTON_THUMBR', 190);
    Key_XBUTTON_X_instance = new Key('XBUTTON_X', 191);
    Key_XBUTTON_Y_instance = new Key('XBUTTON_Y', 192);
    Key_XBUTTON_Z_instance = new Key('XBUTTON_Z', 193);
    Key_DPAD_CENTER_instance = new Key('DPAD_CENTER', 194);
    Key_DPAD_DOWN_instance = new Key('DPAD_DOWN', 195);
    Key_DPAD_DOWN_LEFT_instance = new Key('DPAD_DOWN_LEFT', 196);
    Key_DPAD_DOWN_RIGHT_instance = new Key('DPAD_DOWN_RIGHT', 197);
    Key_DPAD_LEFT_instance = new Key('DPAD_LEFT', 198);
    Key_DPAD_RIGHT_instance = new Key('DPAD_RIGHT', 199);
    Key_DPAD_UP_instance = new Key('DPAD_UP', 200);
    Key_DPAD_UP_LEFT_instance = new Key('DPAD_UP_LEFT', 201);
    Key_DPAD_UP_RIGHT_instance = new Key('DPAD_UP_RIGHT', 202);
    Key_DVR_instance = new Key('DVR', 203);
    Key_EISU_instance = new Key('EISU', 204);
    Key_ENDCALL_instance = new Key('ENDCALL', 205);
    Key_ENVELOPE_instance = new Key('ENVELOPE', 206);
    Key_EXPLORER_instance = new Key('EXPLORER', 207);
    Key_FOCUS_instance = new Key('FOCUS', 208);
    Key_FORWARD_instance = new Key('FORWARD', 209);
    Key_GRAVE_instance = new Key('GRAVE', 210);
    Key_GUIDE_instance = new Key('GUIDE', 211);
    Key_HEADSETHOOK_instance = new Key('HEADSETHOOK', 212);
    Key_ABNT_C1_instance = new Key('ABNT_C1', 213);
    Key_ABNT_C2_instance = new Key('ABNT_C2', 214);
    Key_ATTN_instance = new Key('ATTN', 215);
    Key_CRSEL_instance = new Key('CRSEL', 216);
    Key_EREOF_instance = new Key('EREOF', 217);
    Key_EXECUTE_instance = new Key('EXECUTE', 218);
    Key_EXSEL_instance = new Key('EXSEL', 219);
    Key_ICO_CLEAR_instance = new Key('ICO_CLEAR', 220);
    Key_ICO_HELP_instance = new Key('ICO_HELP', 221);
    Key_HENKAN_instance = new Key('HENKAN', 222);
    Key_PAIRING_instance = new Key('PAIRING', 223);
    Key_APP_SWITCH_instance = new Key('APP_SWITCH', 224);
    Key_ASSIST_instance = new Key('ASSIST', 225);
    Key_AVR_INPUT_instance = new Key('AVR_INPUT', 226);
    Key_AVR_POWER_instance = new Key('AVR_POWER', 227);
    Key_BOOKMARK_instance = new Key('BOOKMARK', 228);
    Key_BREAK_instance = new Key('BREAK', 229);
    Key_CAPTIONS_instance = new Key('CAPTIONS', 230);
    Key_CAMERA_instance = new Key('CAMERA', 231);
    Key_CALL_instance = new Key('CALL', 232);
    Key_CALENDAR_instance = new Key('CALENDAR', 233);
    Key_BRIGHTNESS_DOWN_instance = new Key('BRIGHTNESS_DOWN', 234);
    Key_BRIGHTNESS_UP_instance = new Key('BRIGHTNESS_UP', 235);
    Key_CHANNEL_DOWN_instance = new Key('CHANNEL_DOWN', 236);
    Key_CHANNEL_UP_instance = new Key('CHANNEL_UP', 237);
    Key_CALCULATOR_instance = new Key('CALCULATOR', 238);
    Key_CONTACTS_instance = new Key('CONTACTS', 239);
    Key_NOTIFICATION_instance = new Key('NOTIFICATION', 240);
    Key_COPY_instance = new Key('COPY', 241);
    Key_CUT_instance = new Key('CUT', 242);
    Key_PASTE_instance = new Key('PASTE', 243);
    Key_SEARCH_instance = new Key('SEARCH', 244);
    Key_SETTINGS_instance = new Key('SETTINGS', 245);
    Key_SOFT_LEFT_instance = new Key('SOFT_LEFT', 246);
    Key_SOFT_RIGHT_instance = new Key('SOFT_RIGHT', 247);
    Key_SOFT_SLEEP_instance = new Key('SOFT_SLEEP', 248);
    Key_STAR_instance = new Key('STAR', 249);
    Key_STB_INPUT_instance = new Key('STB_INPUT', 250);
    Key_STB_POWER_instance = new Key('STB_POWER', 251);
    Key_STEM_1_instance = new Key('STEM_1', 252);
    Key_STEM_2_instance = new Key('STEM_2', 253);
    Key_STEM_3_instance = new Key('STEM_3', 254);
    Key_STEM_PRIMARY_instance = new Key('STEM_PRIMARY', 255);
    Key_SWITCH_CHARSET_instance = new Key('SWITCH_CHARSET', 256);
    Key_SYM_instance = new Key('SYM', 257);
    Key_SYSRQ_instance = new Key('SYSRQ', 258);
    Key_NUM_instance = new Key('NUM', 259);
    Key_TV_instance = new Key('TV', 260);
    Key_TV_ANTENNA_CABLE_instance = new Key('TV_ANTENNA_CABLE', 261);
    Key_TV_AUDIO_DESCRIPTION_instance = new Key('TV_AUDIO_DESCRIPTION', 262);
    Key_TV_AUDIO_DESCRIPTION_MIX_DOWN_instance = new Key('TV_AUDIO_DESCRIPTION_MIX_DOWN', 263);
    Key_TV_AUDIO_DESCRIPTION_MIX_UP_instance = new Key('TV_AUDIO_DESCRIPTION_MIX_UP', 264);
    Key_TV_CONTENTS_MENU_instance = new Key('TV_CONTENTS_MENU', 265);
    Key_TV_DATA_SERVICE_instance = new Key('TV_DATA_SERVICE', 266);
    Key_TV_INPUT_instance = new Key('TV_INPUT', 267);
    Key_TV_INPUT_COMPONENT_1_instance = new Key('TV_INPUT_COMPONENT_1', 268);
    Key_TV_INPUT_COMPONENT_2_instance = new Key('TV_INPUT_COMPONENT_2', 269);
    Key_TV_INPUT_COMPOSITE_1_instance = new Key('TV_INPUT_COMPOSITE_1', 270);
    Key_TV_INPUT_COMPOSITE_2_instance = new Key('TV_INPUT_COMPOSITE_2', 271);
    Key_TV_INPUT_HDMI_1_instance = new Key('TV_INPUT_HDMI_1', 272);
    Key_TV_INPUT_HDMI_2_instance = new Key('TV_INPUT_HDMI_2', 273);
    Key_TV_INPUT_HDMI_3_instance = new Key('TV_INPUT_HDMI_3', 274);
    Key_TV_INPUT_HDMI_4_instance = new Key('TV_INPUT_HDMI_4', 275);
    Key_TV_INPUT_VGA_1_instance = new Key('TV_INPUT_VGA_1', 276);
    Key_TV_MEDIA_CONTEXT_MENU_instance = new Key('TV_MEDIA_CONTEXT_MENU', 277);
    Key_TV_NETWORK_instance = new Key('TV_NETWORK', 278);
    Key_TV_NUMBER_ENTRY_instance = new Key('TV_NUMBER_ENTRY', 279);
    Key_TV_POWER_instance = new Key('TV_POWER', 280);
    Key_TV_RADIO_SERVICE_instance = new Key('TV_RADIO_SERVICE', 281);
    Key_TV_SATELLITE_instance = new Key('TV_SATELLITE', 282);
    Key_TV_SATELLITE_BS_instance = new Key('TV_SATELLITE_BS', 283);
    Key_TV_SATELLITE_CS_instance = new Key('TV_SATELLITE_CS', 284);
    Key_TV_SATELLITE_SERVICE_instance = new Key('TV_SATELLITE_SERVICE', 285);
    Key_TV_TELETEXT_instance = new Key('TV_TELETEXT', 286);
    Key_TV_TERRESTRIAL_ANALOG_instance = new Key('TV_TERRESTRIAL_ANALOG', 287);
    Key_TV_TERRESTRIAL_DIGITAL_instance = new Key('TV_TERRESTRIAL_DIGITAL', 288);
    Key_TV_TIMER_PROGRAMMING_instance = new Key('TV_TIMER_PROGRAMMING', 289);
    Key_TV_ZOOM_MODE_instance = new Key('TV_ZOOM_MODE', 290);
    Key_VOICE_ASSIST_instance = new Key('VOICE_ASSIST', 291);
    Key_WAKEUP_instance = new Key('WAKEUP', 292);
    Key_WINDOW_instance = new Key('WINDOW', 293);
    Key_YEN_instance = new Key('YEN', 294);
    Key_ZENKAKU_HANKAKU_instance = new Key('ZENKAKU_HANKAKU', 295);
    Key_ZOOM_IN_instance = new Key('ZOOM_IN', 296);
    Key_ZOOM_OUT_instance = new Key('ZOOM_OUT', 297);
    Key_SYSTEM_NAVIGATION_DOWN_instance = new Key('SYSTEM_NAVIGATION_DOWN', 298);
    Key_SYSTEM_NAVIGATION_LEFT_instance = new Key('SYSTEM_NAVIGATION_LEFT', 299);
    Key_SYSTEM_NAVIGATION_RIGHT_instance = new Key('SYSTEM_NAVIGATION_RIGHT', 300);
    Key_SYSTEM_NAVIGATION_UP_instance = new Key('SYSTEM_NAVIGATION_UP', 301);
    Key_PICTSYMBOLS_instance = new Key('PICTSYMBOLS', 302);
    Key_POUND_instance = new Key('POUND', 303);
    Key_POWER_instance = new Key('POWER', 304);
    Key_PROG_BLUE_instance = new Key('PROG_BLUE', 305);
    Key_PROG_GREEN_instance = new Key('PROG_GREEN', 306);
    Key_PROG_RED_instance = new Key('PROG_RED', 307);
    Key_PROG_YELLOW_instance = new Key('PROG_YELLOW', 308);
    Key_RO_instance = new Key('RO', 309);
    Key_OEM_ATTN_instance = new Key('OEM_ATTN', 310);
    Key_OEM_AUTO_instance = new Key('OEM_AUTO', 311);
    Key_OEM_AX_instance = new Key('OEM_AX', 312);
    Key_OEM_BACKTAB_instance = new Key('OEM_BACKTAB', 313);
    Key_OEM_CLEAR_instance = new Key('OEM_CLEAR', 314);
    Key_OEM_COMMA_instance = new Key('OEM_COMMA', 315);
    Key_OEM_COPY_instance = new Key('OEM_COPY', 316);
    Key_OEM_CUSEL_instance = new Key('OEM_CUSEL', 317);
    Key_OEM_ENLW_instance = new Key('OEM_ENLW', 318);
    Key_OEM_FINISH_instance = new Key('OEM_FINISH', 319);
    Key_OEM_FJ_LOYA_instance = new Key('OEM_FJ_LOYA', 320);
    Key_OEM_FJ_MASSHOU_instance = new Key('OEM_FJ_MASSHOU', 321);
    Key_OEM_FJ_ROYA_instance = new Key('OEM_FJ_ROYA', 322);
    Key_OEM_FJ_TOUROKU_instance = new Key('OEM_FJ_TOUROKU', 323);
    Key_OEM_JUMP_instance = new Key('OEM_JUMP', 324);
    Key_OEM_MINUS_instance = new Key('OEM_MINUS', 325);
    Key_OEM_PA1_instance = new Key('OEM_PA1', 326);
    Key_OEM_PA2_instance = new Key('OEM_PA2', 327);
    Key_OEM_PA3_instance = new Key('OEM_PA3', 328);
    Key_OEM_PERIOD_instance = new Key('OEM_PERIOD', 329);
    Key_OEM_PLUS_instance = new Key('OEM_PLUS', 330);
    Key_OEM_RESET_instance = new Key('OEM_RESET', 331);
    Key_OEM_WSCTRL_instance = new Key('OEM_WSCTRL', 332);
    Key_PA1_instance = new Key('PA1', 333);
    Key_PACKET_instance = new Key('PACKET', 334);
    Key_PROCESSKEY_instance = new Key('PROCESSKEY', 335);
    Key_ZOOM_instance = new Key('ZOOM', 336);
    Key_NONE_instance = new Key('NONE', 337);
    Key_ACCEPT_instance = new Key('ACCEPT', 338);
    Key_APPS_instance = new Key('APPS', 339);
    Key_BROWSER_BACK_instance = new Key('BROWSER_BACK', 340);
    Key_BROWSER_FAVORITES_instance = new Key('BROWSER_FAVORITES', 341);
    Key_BROWSER_FORWARD_instance = new Key('BROWSER_FORWARD', 342);
    Key_BROWSER_HOME_instance = new Key('BROWSER_HOME', 343);
    Key_BROWSER_REFRESH_instance = new Key('BROWSER_REFRESH', 344);
    Key_BROWSER_SEARCH_instance = new Key('BROWSER_SEARCH', 345);
    Key_BROWSER_STOP_instance = new Key('BROWSER_STOP', 346);
    Key_CAPITAL_instance = new Key('CAPITAL', 347);
    Key_CONVERT_instance = new Key('CONVERT', 348);
    Key_ICO_00_instance = new Key('ICO_00', 349);
    Key_JUNJA_instance = new Key('JUNJA', 350);
    Key_KANA_instance = new Key('KANA', 351);
    Key_KANJI_instance = new Key('KANJI', 352);
    Key_KATAKANA_HIRAGANA_instance = new Key('KATAKANA_HIRAGANA', 353);
    Key_LANGUAGE_SWITCH_instance = new Key('LANGUAGE_SWITCH', 354);
    Key_MUHENKAN_instance = new Key('MUHENKAN', 355);
    Key_LAUNCH_APP1_instance = new Key('LAUNCH_APP1', 356);
    Key_LAUNCH_APP2_instance = new Key('LAUNCH_APP2', 357);
    Key_LAUNCH_MAIL_instance = new Key('LAUNCH_MAIL', 358);
    Key_LAUNCH_MEDIA_SELECT_instance = new Key('LAUNCH_MEDIA_SELECT', 359);
    Key_LEFT_BUTTON_instance = new Key('LEFT_BUTTON', 360);
    Key_MIDDLE_BUTTON_instance = new Key('MIDDLE_BUTTON', 361);
    Key_MUSIC_instance = new Key('MUSIC', 362);
    Key_MEDIA_NEXT_TRACK_instance = new Key('MEDIA_NEXT_TRACK', 363);
    Key_MEDIA_PLAY_PAUSE_instance = new Key('MEDIA_PLAY_PAUSE', 364);
    Key_MEDIA_PREV_TRACK_instance = new Key('MEDIA_PREV_TRACK', 365);
    Key_MEDIA_STOP_instance = new Key('MEDIA_STOP', 366);
    Key_MEDIA_PLAY_instance = new Key('MEDIA_PLAY', 367);
    Key_MEDIA_PAUSE_instance = new Key('MEDIA_PAUSE', 368);
    Key_MEDIA_AUDIO_TRACK_instance = new Key('MEDIA_AUDIO_TRACK', 369);
    Key_MEDIA_CLOSE_instance = new Key('MEDIA_CLOSE', 370);
    Key_MEDIA_EJECT_instance = new Key('MEDIA_EJECT', 371);
    Key_MEDIA_FAST_FORWARD_instance = new Key('MEDIA_FAST_FORWARD', 372);
    Key_MEDIA_RECORD_instance = new Key('MEDIA_RECORD', 373);
    Key_MEDIA_REWIND_instance = new Key('MEDIA_REWIND', 374);
    Key_MEDIA_SKIP_BACKWARD_instance = new Key('MEDIA_SKIP_BACKWARD', 375);
    Key_MEDIA_SKIP_FORWARD_instance = new Key('MEDIA_SKIP_FORWARD', 376);
    Key_MEDIA_STEP_BACKWARD_instance = new Key('MEDIA_STEP_BACKWARD', 377);
    Key_MEDIA_STEP_FORWARD_instance = new Key('MEDIA_STEP_FORWARD', 378);
    Key_MEDIA_TOP_MENU_instance = new Key('MEDIA_TOP_MENU', 379);
    Key_MODECHANGE_instance = new Key('MODECHANGE', 380);
    Key_NEXT_instance = new Key('NEXT', 381);
    Key_NONCONVERT_instance = new Key('NONCONVERT', 382);
    Key_OEM_FJ_JISHO_instance = new Key('OEM_FJ_JISHO', 383);
    Key_PRIOR_instance = new Key('PRIOR', 384);
    Key_RIGHT_BUTTON_instance = new Key('RIGHT_BUTTON', 385);
    Key_LAST_CHANNEL_instance = new Key('LAST_CHANNEL', 386);
    Key_MANNER_MODE_instance = new Key('MANNER_MODE', 387);
    Key_NAVIGATE_IN_instance = new Key('NAVIGATE_IN', 388);
    Key_NAVIGATE_NEXT_instance = new Key('NAVIGATE_NEXT', 389);
    Key_NAVIGATE_OUT_instance = new Key('NAVIGATE_OUT', 390);
    Key_NAVIGATE_PREVIOUS_instance = new Key('NAVIGATE_PREVIOUS', 391);
    Key_HYPHEN_instance = new Key('HYPHEN', 392);
    Key_UNDEFINED_instance = new Key('UNDEFINED', 393);
    Key_UNKNOWN_instance = new Key('UNKNOWN', 394);
    Companion_getInstance_35();
  }
  function Key(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(Key).i7k = function () {
    var tmp0_subject = this;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 81:
      case 82:
      case 83:
      case 84:
      case 85:
      case 86:
      case 87:
      case 88:
      case 89:
      case 90:
      case 91:
      case 92:
      case 93:
      case 94:
      case 95:
      case 96:
      case 97:
      case 98:
      case 99:
      case 100:
      case 101:
      case 102:
      case 103:
      case 104:
      case 105:
        return true;
      default:
        return false;
    }
  };
  function Key_SPACE_getInstance() {
    Key_initEntries();
    return Key_SPACE_instance;
  }
  function Key_APOSTROPHE_getInstance() {
    Key_initEntries();
    return Key_APOSTROPHE_instance;
  }
  function Key_COMMA_getInstance() {
    Key_initEntries();
    return Key_COMMA_instance;
  }
  function Key_MINUS_getInstance() {
    Key_initEntries();
    return Key_MINUS_instance;
  }
  function Key_PLUS_getInstance() {
    Key_initEntries();
    return Key_PLUS_instance;
  }
  function Key_PERIOD_getInstance() {
    Key_initEntries();
    return Key_PERIOD_instance;
  }
  function Key_SLASH_getInstance() {
    Key_initEntries();
    return Key_SLASH_instance;
  }
  function Key_N0_getInstance() {
    Key_initEntries();
    return Key_N0_instance;
  }
  function Key_N1_getInstance() {
    Key_initEntries();
    return Key_N1_instance;
  }
  function Key_N2_getInstance() {
    Key_initEntries();
    return Key_N2_instance;
  }
  function Key_N3_getInstance() {
    Key_initEntries();
    return Key_N3_instance;
  }
  function Key_N4_getInstance() {
    Key_initEntries();
    return Key_N4_instance;
  }
  function Key_N5_getInstance() {
    Key_initEntries();
    return Key_N5_instance;
  }
  function Key_N6_getInstance() {
    Key_initEntries();
    return Key_N6_instance;
  }
  function Key_N7_getInstance() {
    Key_initEntries();
    return Key_N7_instance;
  }
  function Key_N8_getInstance() {
    Key_initEntries();
    return Key_N8_instance;
  }
  function Key_N9_getInstance() {
    Key_initEntries();
    return Key_N9_instance;
  }
  function Key_SEMICOLON_getInstance() {
    Key_initEntries();
    return Key_SEMICOLON_instance;
  }
  function Key_EQUAL_getInstance() {
    Key_initEntries();
    return Key_EQUAL_instance;
  }
  function Key_A_getInstance() {
    Key_initEntries();
    return Key_A_instance;
  }
  function Key_B_getInstance() {
    Key_initEntries();
    return Key_B_instance;
  }
  function Key_C_getInstance() {
    Key_initEntries();
    return Key_C_instance;
  }
  function Key_D_getInstance() {
    Key_initEntries();
    return Key_D_instance;
  }
  function Key_E_getInstance() {
    Key_initEntries();
    return Key_E_instance;
  }
  function Key_F_getInstance() {
    Key_initEntries();
    return Key_F_instance;
  }
  function Key_G_getInstance() {
    Key_initEntries();
    return Key_G_instance;
  }
  function Key_H_getInstance() {
    Key_initEntries();
    return Key_H_instance;
  }
  function Key_I_getInstance() {
    Key_initEntries();
    return Key_I_instance;
  }
  function Key_J_getInstance() {
    Key_initEntries();
    return Key_J_instance;
  }
  function Key_K_getInstance() {
    Key_initEntries();
    return Key_K_instance;
  }
  function Key_L_getInstance() {
    Key_initEntries();
    return Key_L_instance;
  }
  function Key_M_getInstance() {
    Key_initEntries();
    return Key_M_instance;
  }
  function Key_N_getInstance() {
    Key_initEntries();
    return Key_N_instance;
  }
  function Key_O_getInstance() {
    Key_initEntries();
    return Key_O_instance;
  }
  function Key_P_getInstance() {
    Key_initEntries();
    return Key_P_instance;
  }
  function Key_Q_getInstance() {
    Key_initEntries();
    return Key_Q_instance;
  }
  function Key_R_getInstance() {
    Key_initEntries();
    return Key_R_instance;
  }
  function Key_S_getInstance() {
    Key_initEntries();
    return Key_S_instance;
  }
  function Key_T_getInstance() {
    Key_initEntries();
    return Key_T_instance;
  }
  function Key_U_getInstance() {
    Key_initEntries();
    return Key_U_instance;
  }
  function Key_V_getInstance() {
    Key_initEntries();
    return Key_V_instance;
  }
  function Key_W_getInstance() {
    Key_initEntries();
    return Key_W_instance;
  }
  function Key_X_getInstance() {
    Key_initEntries();
    return Key_X_instance;
  }
  function Key_Y_getInstance() {
    Key_initEntries();
    return Key_Y_instance;
  }
  function Key_Z_getInstance() {
    Key_initEntries();
    return Key_Z_instance;
  }
  function Key_ESCAPE_getInstance() {
    Key_initEntries();
    return Key_ESCAPE_instance;
  }
  function Key_META_getInstance() {
    Key_initEntries();
    return Key_META_instance;
  }
  function Key_ENTER_getInstance() {
    Key_initEntries();
    return Key_ENTER_instance;
  }
  function Key_TAB_getInstance() {
    Key_initEntries();
    return Key_TAB_instance;
  }
  function Key_BACKSPACE_getInstance() {
    Key_initEntries();
    return Key_BACKSPACE_instance;
  }
  function Key_INSERT_getInstance() {
    Key_initEntries();
    return Key_INSERT_instance;
  }
  function Key_DELETE_getInstance() {
    Key_initEntries();
    return Key_DELETE_instance;
  }
  function Key_RIGHT_getInstance() {
    Key_initEntries();
    return Key_RIGHT_instance;
  }
  function Key_LEFT_getInstance() {
    Key_initEntries();
    return Key_LEFT_instance;
  }
  function Key_DOWN_getInstance() {
    Key_initEntries();
    return Key_DOWN_instance;
  }
  function Key_UP_getInstance() {
    Key_initEntries();
    return Key_UP_instance;
  }
  function Key_PAGE_UP_getInstance() {
    Key_initEntries();
    return Key_PAGE_UP_instance;
  }
  function Key_PAGE_DOWN_getInstance() {
    Key_initEntries();
    return Key_PAGE_DOWN_instance;
  }
  function Key_HOME_getInstance() {
    Key_initEntries();
    return Key_HOME_instance;
  }
  function Key_END_getInstance() {
    Key_initEntries();
    return Key_END_instance;
  }
  function Key_F1_getInstance() {
    Key_initEntries();
    return Key_F1_instance;
  }
  function Key_F2_getInstance() {
    Key_initEntries();
    return Key_F2_instance;
  }
  function Key_F3_getInstance() {
    Key_initEntries();
    return Key_F3_instance;
  }
  function Key_F4_getInstance() {
    Key_initEntries();
    return Key_F4_instance;
  }
  function Key_F5_getInstance() {
    Key_initEntries();
    return Key_F5_instance;
  }
  function Key_F6_getInstance() {
    Key_initEntries();
    return Key_F6_instance;
  }
  function Key_F7_getInstance() {
    Key_initEntries();
    return Key_F7_instance;
  }
  function Key_F8_getInstance() {
    Key_initEntries();
    return Key_F8_instance;
  }
  function Key_F9_getInstance() {
    Key_initEntries();
    return Key_F9_instance;
  }
  function Key_F10_getInstance() {
    Key_initEntries();
    return Key_F10_instance;
  }
  function Key_F11_getInstance() {
    Key_initEntries();
    return Key_F11_instance;
  }
  function Key_F12_getInstance() {
    Key_initEntries();
    return Key_F12_instance;
  }
  function Key_F13_getInstance() {
    Key_initEntries();
    return Key_F13_instance;
  }
  function Key_F14_getInstance() {
    Key_initEntries();
    return Key_F14_instance;
  }
  function Key_F15_getInstance() {
    Key_initEntries();
    return Key_F15_instance;
  }
  function Key_F16_getInstance() {
    Key_initEntries();
    return Key_F16_instance;
  }
  function Key_F17_getInstance() {
    Key_initEntries();
    return Key_F17_instance;
  }
  function Key_F18_getInstance() {
    Key_initEntries();
    return Key_F18_instance;
  }
  function Key_F19_getInstance() {
    Key_initEntries();
    return Key_F19_instance;
  }
  function Key_F20_getInstance() {
    Key_initEntries();
    return Key_F20_instance;
  }
  function Key_F21_getInstance() {
    Key_initEntries();
    return Key_F21_instance;
  }
  function Key_F22_getInstance() {
    Key_initEntries();
    return Key_F22_instance;
  }
  function Key_F23_getInstance() {
    Key_initEntries();
    return Key_F23_instance;
  }
  function Key_F24_getInstance() {
    Key_initEntries();
    return Key_F24_instance;
  }
  function Key_F25_getInstance() {
    Key_initEntries();
    return Key_F25_instance;
  }
  function Key_SHIFT_getInstance() {
    Key_initEntries();
    return Key_SHIFT_instance;
  }
  function Key_CONTROL_getInstance() {
    Key_initEntries();
    return Key_CONTROL_instance;
  }
  function Key_ALT_getInstance() {
    Key_initEntries();
    return Key_ALT_instance;
  }
  function Key_SUPER_getInstance() {
    Key_initEntries();
    return Key_SUPER_instance;
  }
  function Key_QUOTE_getInstance() {
    Key_initEntries();
    return Key_QUOTE_instance;
  }
  function Key_UNKNOWN_getInstance() {
    Key_initEntries();
    return Key_UNKNOWN_instance;
  }
  var MouseButton_LEFT_instance;
  var MouseButton_MIDDLE_instance;
  var MouseButton_RIGHT_instance;
  var MouseButton_BUTTON3_instance;
  var MouseButton_BUTTON4_instance;
  var MouseButton_BUTTON5_instance;
  var MouseButton_BUTTON6_instance;
  var MouseButton_BUTTON7_instance;
  var MouseButton_BUTTON_WHEEL_instance;
  var MouseButton_BUTTON_UNKNOWN_instance;
  var MouseButton_NONE_instance;
  function Companion_20() {
    Companion_instance_20 = this;
    this.j7k_1 = MouseButton_NONE_getInstance().b2_1 + 1 | 0;
    this.k7k_1 = values_6();
  }
  protoOf(Companion_20).n = function (id) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_getOrElse = this.k7k_1;
    var tmp;
    if (id >= 0 ? id <= get_lastIndex(tmp0_getOrElse) : false) {
      tmp = tmp0_getOrElse[id];
    } else {
      var tmp$ret$0;
      // Inline function 'korlibs.event.Companion.get.<anonymous>' call
      tmp$ret$0 = MouseButton_BUTTON_UNKNOWN_getInstance();
      tmp = tmp$ret$0;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  };
  var Companion_instance_20;
  function Companion_getInstance_36() {
    MouseButton_initEntries();
    if (Companion_instance_20 == null)
      new Companion_20();
    return Companion_instance_20;
  }
  function values_6() {
    return [MouseButton_LEFT_getInstance(), MouseButton_MIDDLE_getInstance(), MouseButton_RIGHT_getInstance(), MouseButton_BUTTON3_getInstance(), MouseButton_BUTTON4_getInstance(), MouseButton_BUTTON5_getInstance(), MouseButton_BUTTON6_getInstance(), MouseButton_BUTTON7_getInstance(), MouseButton_BUTTON_WHEEL_getInstance(), MouseButton_BUTTON_UNKNOWN_getInstance(), MouseButton_NONE_getInstance()];
  }
  var MouseButton_entriesInitialized;
  function MouseButton_initEntries() {
    if (MouseButton_entriesInitialized)
      return Unit_getInstance();
    MouseButton_entriesInitialized = true;
    MouseButton_LEFT_instance = new MouseButton('LEFT', 0, 0);
    MouseButton_MIDDLE_instance = new MouseButton('MIDDLE', 1, 1);
    MouseButton_RIGHT_instance = new MouseButton('RIGHT', 2, 2);
    MouseButton_BUTTON3_instance = new MouseButton('BUTTON3', 3, 3);
    MouseButton_BUTTON4_instance = new MouseButton('BUTTON4', 4, 4);
    MouseButton_BUTTON5_instance = new MouseButton('BUTTON5', 5, 5);
    MouseButton_BUTTON6_instance = new MouseButton('BUTTON6', 6, 6);
    MouseButton_BUTTON7_instance = new MouseButton('BUTTON7', 7, 7);
    MouseButton_BUTTON_WHEEL_instance = new MouseButton('BUTTON_WHEEL', 8, 8);
    MouseButton_BUTTON_UNKNOWN_instance = new MouseButton('BUTTON_UNKNOWN', 9, 10);
    MouseButton_NONE_instance = new MouseButton('NONE', 10, 11, 0);
    Companion_getInstance_36();
  }
  function MouseButton(name, ordinal, id, bits) {
    bits = bits === VOID ? 1 << id : bits;
    Enum.call(this, name, ordinal);
    this.n7k_1 = id;
    this.o7k_1 = bits;
  }
  function MouseButton_LEFT_getInstance() {
    MouseButton_initEntries();
    return MouseButton_LEFT_instance;
  }
  function MouseButton_MIDDLE_getInstance() {
    MouseButton_initEntries();
    return MouseButton_MIDDLE_instance;
  }
  function MouseButton_RIGHT_getInstance() {
    MouseButton_initEntries();
    return MouseButton_RIGHT_instance;
  }
  function MouseButton_BUTTON3_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON3_instance;
  }
  function MouseButton_BUTTON4_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON4_instance;
  }
  function MouseButton_BUTTON5_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON5_instance;
  }
  function MouseButton_BUTTON6_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON6_instance;
  }
  function MouseButton_BUTTON7_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON7_instance;
  }
  function MouseButton_BUTTON_WHEEL_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON_WHEEL_instance;
  }
  function MouseButton_BUTTON_UNKNOWN_getInstance() {
    MouseButton_initEntries();
    return MouseButton_BUTTON_UNKNOWN_instance;
  }
  function MouseButton_NONE_getInstance() {
    MouseButton_initEntries();
    return MouseButton_NONE_instance;
  }
  function ISoftKeyboardConfig() {
  }
  var SoftKeyboardType_DEFAULT_instance;
  var SoftKeyboardType_ASCII_CAPABLE_instance;
  var SoftKeyboardType_NUMBERS_AND_PUNCTUATION_instance;
  var SoftKeyboardType_URL_instance;
  var SoftKeyboardType_NUMBER_PAD_instance;
  var SoftKeyboardType_PHONE_PAD_instance;
  var SoftKeyboardType_NAME_PHONE_PAD_instance;
  var SoftKeyboardType_EMAIL_ADDRESS_instance;
  var SoftKeyboardType_DECIMAL_PAD_instance;
  var SoftKeyboardType_TWITTER_instance;
  var SoftKeyboardType_WEB_SEARCH_instance;
  var SoftKeyboardType_ASCII_CAPABLE_NUMBER_PAD_instance;
  var SoftKeyboardType_ALPHABET_instance;
  var SoftKeyboardType_entriesInitialized;
  function SoftKeyboardType_initEntries() {
    if (SoftKeyboardType_entriesInitialized)
      return Unit_getInstance();
    SoftKeyboardType_entriesInitialized = true;
    SoftKeyboardType_DEFAULT_instance = new SoftKeyboardType('DEFAULT', 0);
    SoftKeyboardType_ASCII_CAPABLE_instance = new SoftKeyboardType('ASCII_CAPABLE', 1);
    SoftKeyboardType_NUMBERS_AND_PUNCTUATION_instance = new SoftKeyboardType('NUMBERS_AND_PUNCTUATION', 2);
    SoftKeyboardType_URL_instance = new SoftKeyboardType('URL', 3);
    SoftKeyboardType_NUMBER_PAD_instance = new SoftKeyboardType('NUMBER_PAD', 4);
    SoftKeyboardType_PHONE_PAD_instance = new SoftKeyboardType('PHONE_PAD', 5);
    SoftKeyboardType_NAME_PHONE_PAD_instance = new SoftKeyboardType('NAME_PHONE_PAD', 6);
    SoftKeyboardType_EMAIL_ADDRESS_instance = new SoftKeyboardType('EMAIL_ADDRESS', 7);
    SoftKeyboardType_DECIMAL_PAD_instance = new SoftKeyboardType('DECIMAL_PAD', 8);
    SoftKeyboardType_TWITTER_instance = new SoftKeyboardType('TWITTER', 9);
    SoftKeyboardType_WEB_SEARCH_instance = new SoftKeyboardType('WEB_SEARCH', 10);
    SoftKeyboardType_ASCII_CAPABLE_NUMBER_PAD_instance = new SoftKeyboardType('ASCII_CAPABLE_NUMBER_PAD', 11);
    SoftKeyboardType_ALPHABET_instance = new SoftKeyboardType('ALPHABET', 12);
  }
  function SoftKeyboardType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  var SoftKeyboardReturnKeyType_DEFAULT_instance;
  var SoftKeyboardReturnKeyType_GO_instance;
  var SoftKeyboardReturnKeyType_JOIN_instance;
  var SoftKeyboardReturnKeyType_NEXT_instance;
  var SoftKeyboardReturnKeyType_ROUTE_instance;
  var SoftKeyboardReturnKeyType_SEARCH_instance;
  var SoftKeyboardReturnKeyType_DONE_instance;
  var SoftKeyboardReturnKeyType_EMERGENCY_CALL_instance;
  var SoftKeyboardReturnKeyType_CONTINUE_instance;
  var SoftKeyboardReturnKeyType_entriesInitialized;
  function SoftKeyboardReturnKeyType_initEntries() {
    if (SoftKeyboardReturnKeyType_entriesInitialized)
      return Unit_getInstance();
    SoftKeyboardReturnKeyType_entriesInitialized = true;
    SoftKeyboardReturnKeyType_DEFAULT_instance = new SoftKeyboardReturnKeyType('DEFAULT', 0);
    SoftKeyboardReturnKeyType_GO_instance = new SoftKeyboardReturnKeyType('GO', 1);
    SoftKeyboardReturnKeyType_JOIN_instance = new SoftKeyboardReturnKeyType('JOIN', 2);
    SoftKeyboardReturnKeyType_NEXT_instance = new SoftKeyboardReturnKeyType('NEXT', 3);
    SoftKeyboardReturnKeyType_ROUTE_instance = new SoftKeyboardReturnKeyType('ROUTE', 4);
    SoftKeyboardReturnKeyType_SEARCH_instance = new SoftKeyboardReturnKeyType('SEARCH', 5);
    SoftKeyboardReturnKeyType_DONE_instance = new SoftKeyboardReturnKeyType('DONE', 6);
    SoftKeyboardReturnKeyType_EMERGENCY_CALL_instance = new SoftKeyboardReturnKeyType('EMERGENCY_CALL', 7);
    SoftKeyboardReturnKeyType_CONTINUE_instance = new SoftKeyboardReturnKeyType('CONTINUE', 8);
  }
  function SoftKeyboardReturnKeyType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function SoftKeyboardConfig(softKeyboardType, softKeyboardSmartDashes, softKeyboardSmartQuotes, softKeyboardSpellChecking, softKeyboardTextContentType, softKeyboardSmartInsertDelete, softKeyboardPassword, softKeyboardReturnKeyType, softKeyboardAutocapitalization, softKeyboardAutocorrection, softKeyboardEnablesReturnKeyAutomatically) {
    softKeyboardType = softKeyboardType === VOID ? SoftKeyboardType_DEFAULT_getInstance() : softKeyboardType;
    softKeyboardSmartDashes = softKeyboardSmartDashes === VOID ? null : softKeyboardSmartDashes;
    softKeyboardSmartQuotes = softKeyboardSmartQuotes === VOID ? null : softKeyboardSmartQuotes;
    softKeyboardSpellChecking = softKeyboardSpellChecking === VOID ? null : softKeyboardSpellChecking;
    softKeyboardTextContentType = softKeyboardTextContentType === VOID ? null : softKeyboardTextContentType;
    softKeyboardSmartInsertDelete = softKeyboardSmartInsertDelete === VOID ? null : softKeyboardSmartInsertDelete;
    softKeyboardPassword = softKeyboardPassword === VOID ? false : softKeyboardPassword;
    softKeyboardReturnKeyType = softKeyboardReturnKeyType === VOID ? SoftKeyboardReturnKeyType_DEFAULT_getInstance() : softKeyboardReturnKeyType;
    softKeyboardAutocapitalization = softKeyboardAutocapitalization === VOID ? null : softKeyboardAutocapitalization;
    softKeyboardAutocorrection = softKeyboardAutocorrection === VOID ? null : softKeyboardAutocorrection;
    softKeyboardEnablesReturnKeyAutomatically = softKeyboardEnablesReturnKeyAutomatically === VOID ? false : softKeyboardEnablesReturnKeyAutomatically;
    this.p7k_1 = softKeyboardType;
    this.q7k_1 = softKeyboardSmartDashes;
    this.r7k_1 = softKeyboardSmartQuotes;
    this.s7k_1 = softKeyboardSpellChecking;
    this.t7k_1 = softKeyboardTextContentType;
    this.u7k_1 = softKeyboardSmartInsertDelete;
    this.v7k_1 = softKeyboardPassword;
    this.w7k_1 = softKeyboardReturnKeyType;
    this.x7k_1 = softKeyboardAutocapitalization;
    this.y7k_1 = softKeyboardAutocorrection;
    this.z7k_1 = softKeyboardEnablesReturnKeyAutomatically;
  }
  protoOf(SoftKeyboardConfig).toString = function () {
    return 'SoftKeyboardConfig(softKeyboardType=' + this.p7k_1 + ', softKeyboardSmartDashes=' + this.q7k_1 + ', softKeyboardSmartQuotes=' + this.r7k_1 + ', softKeyboardSpellChecking=' + this.s7k_1 + ', softKeyboardTextContentType=' + this.t7k_1 + ', softKeyboardSmartInsertDelete=' + this.u7k_1 + ', softKeyboardPassword=' + this.v7k_1 + ', softKeyboardReturnKeyType=' + this.w7k_1 + ', softKeyboardAutocapitalization=' + this.x7k_1 + ', softKeyboardAutocorrection=' + this.y7k_1 + ', softKeyboardEnablesReturnKeyAutomatically=' + this.z7k_1 + ')';
  };
  protoOf(SoftKeyboardConfig).hashCode = function () {
    var result = this.p7k_1.hashCode();
    result = imul(result, 31) + (this.q7k_1 == null ? 0 : this.q7k_1 | 0) | 0;
    result = imul(result, 31) + (this.r7k_1 == null ? 0 : this.r7k_1 | 0) | 0;
    result = imul(result, 31) + (this.s7k_1 == null ? 0 : this.s7k_1 | 0) | 0;
    result = imul(result, 31) + (this.t7k_1 == null ? 0 : getStringHashCode(this.t7k_1)) | 0;
    result = imul(result, 31) + (this.u7k_1 == null ? 0 : this.u7k_1 | 0) | 0;
    result = imul(result, 31) + (this.v7k_1 | 0) | 0;
    result = imul(result, 31) + this.w7k_1.hashCode() | 0;
    result = imul(result, 31) + (this.x7k_1 == null ? 0 : this.x7k_1 | 0) | 0;
    result = imul(result, 31) + (this.y7k_1 == null ? 0 : this.y7k_1 | 0) | 0;
    result = imul(result, 31) + (this.z7k_1 | 0) | 0;
    return result;
  };
  protoOf(SoftKeyboardConfig).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SoftKeyboardConfig))
      return false;
    var tmp0_other_with_cast = other instanceof SoftKeyboardConfig ? other : THROW_CCE();
    if (!this.p7k_1.equals(tmp0_other_with_cast.p7k_1))
      return false;
    if (!(this.q7k_1 == tmp0_other_with_cast.q7k_1))
      return false;
    if (!(this.r7k_1 == tmp0_other_with_cast.r7k_1))
      return false;
    if (!(this.s7k_1 == tmp0_other_with_cast.s7k_1))
      return false;
    if (!(this.t7k_1 == tmp0_other_with_cast.t7k_1))
      return false;
    if (!(this.u7k_1 == tmp0_other_with_cast.u7k_1))
      return false;
    if (!(this.v7k_1 === tmp0_other_with_cast.v7k_1))
      return false;
    if (!this.w7k_1.equals(tmp0_other_with_cast.w7k_1))
      return false;
    if (!(this.x7k_1 == tmp0_other_with_cast.x7k_1))
      return false;
    if (!(this.y7k_1 == tmp0_other_with_cast.y7k_1))
      return false;
    if (!(this.z7k_1 === tmp0_other_with_cast.z7k_1))
      return false;
    return true;
  };
  function SoftKeyboardType_DEFAULT_getInstance() {
    SoftKeyboardType_initEntries();
    return SoftKeyboardType_DEFAULT_instance;
  }
  function SoftKeyboardReturnKeyType_DEFAULT_getInstance() {
    SoftKeyboardReturnKeyType_initEntries();
    return SoftKeyboardReturnKeyType_DEFAULT_instance;
  }
  function Immutable(parentFeatures, graphicExtensions, isInstancedSupported, isUniformBuffersSupported, isVertexArraysSupported, isStorageMultisampleSupported, isFloatTextureSupported) {
    parentFeatures = parentFeatures === VOID ? null : parentFeatures;
    graphicExtensions = graphicExtensions === VOID ? emptySet() : graphicExtensions;
    isInstancedSupported = isInstancedSupported === VOID ? false : isInstancedSupported;
    isUniformBuffersSupported = isUniformBuffersSupported === VOID ? false : isUniformBuffersSupported;
    isVertexArraysSupported = isVertexArraysSupported === VOID ? false : isVertexArraysSupported;
    isStorageMultisampleSupported = isStorageMultisampleSupported === VOID ? false : isStorageMultisampleSupported;
    isFloatTextureSupported = isFloatTextureSupported === VOID ? false : isFloatTextureSupported;
    this.a7l_1 = parentFeatures;
    this.b7l_1 = graphicExtensions;
    this.c7l_1 = isInstancedSupported;
    this.d7l_1 = isUniformBuffersSupported;
    this.e7l_1 = isVertexArraysSupported;
    this.f7l_1 = isStorageMultisampleSupported;
    this.g7l_1 = isFloatTextureSupported;
  }
  protoOf(Immutable).h7l = function () {
    return this.a7l_1;
  };
  protoOf(Immutable).i7l = function () {
    return this.d7l_1;
  };
  protoOf(Immutable).j7l = function () {
    return this.e7l_1;
  };
  protoOf(Immutable).toString = function () {
    return 'Immutable(parentFeatures=' + this.a7l_1 + ', graphicExtensions=' + this.b7l_1 + ', isInstancedSupported=' + this.c7l_1 + ', isUniformBuffersSupported=' + this.d7l_1 + ', isVertexArraysSupported=' + this.e7l_1 + ', isStorageMultisampleSupported=' + this.f7l_1 + ', isFloatTextureSupported=' + this.g7l_1 + ')';
  };
  protoOf(Immutable).hashCode = function () {
    var result = this.a7l_1 == null ? 0 : hashCode(this.a7l_1);
    result = imul(result, 31) + hashCode(this.b7l_1) | 0;
    result = imul(result, 31) + (this.c7l_1 | 0) | 0;
    result = imul(result, 31) + (this.d7l_1 | 0) | 0;
    result = imul(result, 31) + (this.e7l_1 | 0) | 0;
    result = imul(result, 31) + (this.f7l_1 | 0) | 0;
    result = imul(result, 31) + (this.g7l_1 | 0) | 0;
    return result;
  };
  protoOf(Immutable).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Immutable))
      return false;
    var tmp0_other_with_cast = other instanceof Immutable ? other : THROW_CCE();
    if (!equals(this.a7l_1, tmp0_other_with_cast.a7l_1))
      return false;
    if (!equals(this.b7l_1, tmp0_other_with_cast.b7l_1))
      return false;
    if (!(this.c7l_1 === tmp0_other_with_cast.c7l_1))
      return false;
    if (!(this.d7l_1 === tmp0_other_with_cast.d7l_1))
      return false;
    if (!(this.e7l_1 === tmp0_other_with_cast.e7l_1))
      return false;
    if (!(this.f7l_1 === tmp0_other_with_cast.f7l_1))
      return false;
    if (!(this.g7l_1 === tmp0_other_with_cast.g7l_1))
      return false;
    return true;
  };
  function AGFeatures() {
  }
  function Companion_21() {
    Companion_instance_21 = this;
    this.k7l_1 = Companion_getInstance_3().b1n('AG');
    this.l7l_1 = 96.0;
  }
  var Companion_instance_21;
  function Companion_getInstance_37() {
    if (Companion_instance_21 == null)
      new Companion_21();
    return Companion_instance_21;
  }
  function AG() {
    Companion_getInstance_37();
    this.m7l_1 = new Mixin();
    this.n7l_1 = AGFrameBuffer_init_$Create$(true);
    this.o7l_1 = 0;
    this.p7l_1 = new AGStats();
  }
  protoOf(AG).d1i = function (_set____db54di) {
    this.m7l_1.d1i(_set____db54di);
  };
  protoOf(AG).e1i = function () {
    return this.m7l_1.e1i();
  };
  protoOf(AG).q7l = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_37().k7l_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.AG.contextLost.<anonymous>' call
      tmp$ret$0 = this;
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    var tmp$ret$3;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp3_info = Companion_getInstance_37().k7l_1;
    var tmp2_log = Level_INFO_getInstance();
    var tmp_0;
    if (tmp3_info.q1n(tmp2_log)) {
      var tmp$ret$2;
      // Inline function 'korlibs.graphics.AG.contextLost.<anonymous>' call
      tmp$ret$2 = 'AG.contextLost()';
      tmp3_info.r1n(tmp2_log, tmp$ret$2);
      tmp_0 = Unit_getInstance();
    }
    tmp$ret$3 = tmp_0;
    var tmp0_this = this;
    var tmp1 = tmp0_this.o7l_1;
    tmp0_this.o7l_1 = tmp1 + 1 | 0;
  };
  protoOf(AG).r7l = function () {
    return this.s7l(AGFinish_getInstance());
  };
  protoOf(AG).t7l = function () {
    return Unit_getInstance();
  };
  protoOf(AG).m7g = function () {
    return Unit_getInstance();
  };
  protoOf(AG).s7l = function (command) {
    return Unit_getInstance();
  };
  protoOf(AG).u7l = function (frameBuffer, frameBufferInfo, color, depth, stencil, clearColor, clearDepth, clearStencil, scissor) {
    return this.s7l(new AGClear(frameBuffer, frameBufferInfo, color, depth, stencil, clearColor, clearDepth, clearStencil));
  };
  protoOf(AG).v7l = function (frameBuffer, frameBufferInfo, vertexData, program, drawType, vertexCount, indices, indexType, drawOffset, blending, uniformBlocks, textureUnits, stencilRef, stencilOpFunc, colorMask, depthAndFrontFace, scissor, cullFace, instances) {
    return this.s7l(new AGBatch(frameBuffer, frameBufferInfo, vertexData, indices, indexType, program, uniformBlocks, textureUnits, blending, stencilOpFunc, stencilRef, colorMask, depthAndFrontFace, scissor, cullFace, drawType, drawOffset, vertexCount, instances));
  };
  protoOf(AG).w7l = function (frameBuffer, frameBufferInfo, x, y, width, height, data, kind) {
    return Unit_getInstance();
  };
  function draw(_this__u8e3s4, batch) {
    return batch.y7l(_this__u8e3s4);
  }
  function draw_0(_this__u8e3s4, frameBuffer, vertexData, program, drawType, vertexCount, indices, indexType, drawOffset, blending, uniformBlocks, textureUnits, stencilRef, stencilOpFunc, colorMask, depthAndFrontFace, scissor, cullFace, instances) {
    indices = indices === VOID ? null : indices;
    indexType = indexType === VOID ? Companion_getInstance_54().b7m_1 : indexType;
    drawOffset = drawOffset === VOID ? 0 : drawOffset;
    blending = blending === VOID ? Companion_getInstance_47().f7m_1 : blending;
    uniformBlocks = uniformBlocks === VOID ? Companion_getInstance_61().j7m_1 : uniformBlocks;
    textureUnits = textureUnits === VOID ? Companion_getInstance_39().l7m() : textureUnits;
    stencilRef = stencilRef === VOID ? Companion_getInstance_41().n7m_1 : stencilRef;
    stencilOpFunc = stencilOpFunc === VOID ? Companion_getInstance_42().p7m_1 : stencilOpFunc;
    colorMask = colorMask === VOID ? Companion_getInstance_43().t7m_1 : colorMask;
    depthAndFrontFace = depthAndFrontFace === VOID ? Companion_getInstance_55().x7m_1 : depthAndFrontFace;
    scissor = scissor === VOID ? Companion_getInstance_44().b7n_1 : scissor;
    cullFace = cullFace === VOID ? Companion_getInstance_53().d7n_1 : cullFace;
    instances = instances === VOID ? 1 : instances;
    return _this__u8e3s4.v7l(frameBuffer.h7n_1, frameBuffer.u7n(), vertexData, program, drawType, vertexCount, indices, indexType, drawOffset, blending, uniformBlocks, textureUnits, stencilRef, stencilOpFunc, colorMask, depthAndFrontFace, scissor, cullFace, instances);
  }
  function readColor(_this__u8e3s4, frameBuffer, bitmap, x, y) {
    x = x === VOID ? 0 : x;
    y = y === VOID ? 0 : y;
    return _this__u8e3s4.w7l(frameBuffer.h7n_1, frameBuffer.u7n(), x, y, bitmap.s4q_1, bitmap.t4q_1, bitmap.u4r_1, Companion_getInstance_56().v7n_1);
  }
  function draw_1(_this__u8e3s4, batch) {
    return batch.y7l(_this__u8e3s4);
  }
  function AGBuffer() {
    AGObject.call(this);
    this.v7o_1 = null;
  }
  protoOf(AGBuffer).w7o = function (data, offset, length) {
    return this.x7o(_Int8Buffer___get_buffer__impl__q0gulb(_Int8Buffer___init__impl__e20lvc(data, offset, length)));
  };
  protoOf(AGBuffer).y7o = function (data, offset, length) {
    return this.x7o(_Float32Buffer___get_buffer__impl__x5nu9x(_Float32Buffer___init__impl__403k2m(data, offset, length)));
  };
  protoOf(AGBuffer).z7o = function (data, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? data.length - offset | 0 : length;
    return $super === VOID ? this.y7o(data, offset, length) : $super.y7o.call(this, data, offset, length);
  };
  protoOf(AGBuffer).a7p = function (data, offset, length) {
    return this.x7o(sliceWithSize(data, offset, length));
  };
  protoOf(AGBuffer).x7o = function (data) {
    if (get_sizeInBytes(data) < 1024) {
      if ((!(this.v7o_1 == null) ? get_sizeInBytes(ensureNotNull(this.v7o_1)) === get_sizeInBytes(data) : false) ? arrayequal(ensureNotNull(this.v7o_1), 0, data, 0, get_sizeInBytes(data)) : false)
        return this;
    }
    this.v7o_1 = clone(data);
    this.f7p();
    return this;
  };
  protoOf(AGBuffer).toString = function () {
    var tmp0_safe_receiver = this.v7o_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : get_sizeInBytes(tmp0_safe_receiver);
    return 'AGBuffer(' + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) + ')';
  };
  function AGObject() {
    this.b7p_1 = null;
    this.c7p_1 = -1;
    this.d7p_1 = -2;
    this.e7p_1 = -1;
  }
  protoOf(AGObject).g7p = function () {
    this.f7p();
    this.d7p_1 = this.e7p_1 - 1 | 0;
  };
  protoOf(AGObject).f7p = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.e7p_1;
    tmp0_this.e7p_1 = tmp1 + 1 | 0;
  };
  protoOf(AGObject).n1q = function () {
    var tmp0_safe_receiver = this.b7p_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.h7p();
    }
    this.b7p_1 = null;
  };
  function AGNativeObject() {
  }
  function checkBitmaps($this, bmp) {
    if (!bmp.z4q_1) {
      var tmp$ret$1;
      // Inline function 'korlibs.logger.Logger.error' call
      var tmp1_error = $this.n7p_1;
      var tmp0_log = Level_ERROR_getInstance();
      var tmp;
      if (tmp1_error.q1n(tmp0_log)) {
        var tmp$ret$0;
        // Inline function 'korlibs.graphics.AGTexture.checkBitmaps.<anonymous>' call
        tmp$ret$0 = 'Trying to upload a non-premultiplied bitmap: ' + bmp + '. This will cause rendering artifacts';
        tmp1_error.r1n(tmp0_log, tmp$ret$0);
        tmp = Unit_getInstance();
      }
      tmp$ret$1 = tmp;
    }
  }
  function AGTexture(targetKind) {
    targetKind = targetKind === VOID ? Companion_getInstance_50().w7p_1 : targetKind;
    AGObject.call(this);
    this.m7p_1 = targetKind;
    this.n7p_1 = Companion_getInstance_3().b1n('AGTexture');
    this.o7p_1 = false;
    this.p7p_1 = false;
    this.q7p_1 = null;
    this.r7p_1 = null;
    this.s7p_1 = null;
    this.t7p_1 = false;
    this.u7p_1 = null;
    this.v7p_1 = Companion_getInstance_4().l1l(new Long(0, 0));
  }
  protoOf(AGTexture).a7q = function () {
    var tmp0_safe_receiver = this.u7p_1;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.t55();
    var tmp;
    if (tmp1_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.AGTexture.<get-implForcedTexTarget>.<anonymous>' call
      tmp$ret$0 = fromGl(Companion_getInstance_50(), tmp1_safe_receiver);
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    var tmp2_elvis_lhs = tmp;
    var tmp_0;
    var tmp_1 = tmp2_elvis_lhs;
    if ((tmp_1 == null ? null : new AGTextureTargetKind(tmp_1)) == null) {
      tmp_0 = this.m7p_1;
    } else {
      tmp_0 = tmp2_elvis_lhs;
    }
    return tmp_0;
  };
  protoOf(AGTexture).x18 = function () {
    var tmp0_safe_receiver = this.s7p_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.s4q_1;
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  protoOf(AGTexture).y18 = function () {
    var tmp0_safe_receiver = this.s7p_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.t4q_1;
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  protoOf(AGTexture).b7q = function () {
    var tmp = this.s7p_1;
    var tmp0_safe_receiver = (tmp == null ? true : tmp instanceof MultiBitmap) ? tmp : null;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.p7q_1;
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.l();
    return tmp2_elvis_lhs == null ? 1 : tmp2_elvis_lhs;
  };
  protoOf(AGTexture).q7q = function (bmp, mipmaps, baseMipmapLevel, maxMipmapLevel) {
    var tmp0_safe_receiver = bmp;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      checkBitmaps(this, tmp0_safe_receiver);
      tmp$ret$0 = Unit_getInstance();
    }
    var tmp = this;
    tmp.u7p_1 = (bmp == null ? true : isInterface(bmp, ForcedTexId)) ? bmp : null;
    this.s7p_1 = bmp;
    this.v7p_1 = Companion_getInstance_4().k1l(imul(imul(imul(this.x18(), this.y18()), this.b7q()), 4));
    this.f7p();
    this.p7p_1 = mipmaps;
    this.q7p_1 = baseMipmapLevel;
    this.r7p_1 = maxMipmapLevel;
    return this;
  };
  protoOf(AGTexture).r7q = function (bmp, mipmaps, baseMipmapLevel, maxMipmapLevel, $super) {
    mipmaps = mipmaps === VOID ? false : mipmaps;
    baseMipmapLevel = baseMipmapLevel === VOID ? null : baseMipmapLevel;
    maxMipmapLevel = maxMipmapLevel === VOID ? null : maxMipmapLevel;
    return $super === VOID ? this.q7q(bmp, mipmaps, baseMipmapLevel, maxMipmapLevel) : $super.q7q.call(this, bmp, mipmaps, baseMipmapLevel, maxMipmapLevel);
  };
  protoOf(AGTexture).s7q = function (bitmap, requestMipmaps) {
    var tmp0_safe_receiver = bitmap;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.s4q_1;
    var width = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    var tmp2_safe_receiver = bitmap;
    var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.t4q_1;
    var height = tmp3_elvis_lhs == null ? 0 : tmp3_elvis_lhs;
    return (requestMipmaps ? get_isPowerOfTwo(width) : false) ? get_isPowerOfTwo(height) : false;
  };
  protoOf(AGTexture).toString = function () {
    return 'AGTexture(size=' + this.x18() + ',' + this.y18() + ')';
  };
  function _AGTextureUnitInfo___init__impl__s1tthu(data) {
    return data;
  }
  function _AGTextureUnitInfo___get_data__impl__oupy3n($this) {
    return $this;
  }
  function Companion_22() {
    Companion_instance_22 = this;
    this.t7q_1 = _AGTextureUnitInfo___init__impl__s1tthu(-1);
    this.u7q_1 = AGTextureUnitInfo__withKind_impl_aa0b04(AGTextureUnitInfo__withWrap_impl_akwb6y(AGTextureUnitInfo__withLinearTrilinear_impl_aactvz(_AGTextureUnitInfo___init__impl__s1tthu(0), true, true), Companion_getInstance_57().v7q_1), Companion_getInstance_50().w7p_1);
  }
  protoOf(Companion_22).y7q = function (data) {
    return _AGTextureUnitInfo___init__impl__s1tthu(data);
  };
  protoOf(Companion_22).z7q = function (wrap, linear, trilinear) {
    return AGTextureUnitInfo__withLinearTrilinear_impl_aactvz(AGTextureUnitInfo__withWrap_impl_akwb6y(_AGTextureUnitInfo___init__impl__s1tthu(0), wrap), linear, trilinear);
  };
  protoOf(Companion_22).a7r = function (wrap, linear, trilinear, $super) {
    wrap = wrap === VOID ? Companion_getInstance_57().v7q_1 : wrap;
    linear = linear === VOID ? true : linear;
    trilinear = trilinear === VOID ? linear : trilinear;
    return $super === VOID ? this.z7q(wrap, linear, trilinear) : $super.z7q.call(this, new AGWrapMode(wrap), linear, trilinear).b7r_1;
  };
  var Companion_instance_22;
  function Companion_getInstance_38() {
    if (Companion_instance_22 == null)
      new Companion_22();
    return Companion_instance_22;
  }
  function _AGTextureUnitInfo___get_wrap__impl__dwp6l($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _AGTextureUnitInfo___get_data__impl__oupy3n($this);
    tmp$ret$0 = (tmp0_extract2 >>> 0 | 0) & 3;
    return _AGWrapMode___init__impl__x5wmya(tmp$ret$0);
  }
  function _AGTextureUnitInfo___get_linear__impl__jak6ny($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extract' call
    var tmp0_extract = _AGTextureUnitInfo___get_data__impl__oupy3n($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extract >>> 2 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGTextureUnitInfo___get_trilinear__impl__hxtqfb($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extract' call
    var tmp0_extract = _AGTextureUnitInfo___get_data__impl__oupy3n($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extract >>> 3 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGTextureUnitInfo___get_kind__impl__6xtjoz($this) {
    return _AGTextureTargetKind___init__impl__4zdmch(extract(_AGTextureUnitInfo___get_data__impl__oupy3n($this), 8, 5));
  }
  function AGTextureUnitInfo__withKind_impl_aa0b04($this, kind) {
    return _AGTextureUnitInfo___init__impl__s1tthu(insert5(_AGTextureUnitInfo___get_data__impl__oupy3n($this), _AGTextureTargetKind___get_ordinal__impl__gjzc5p(kind), 8));
  }
  function AGTextureUnitInfo__withWrap_impl_akwb6y($this, wrap) {
    return _AGTextureUnitInfo___init__impl__s1tthu(insert2(_AGTextureUnitInfo___get_data__impl__oupy3n($this), _AGWrapMode___get_ordinal__impl__rc38w2(wrap), 0));
  }
  function AGTextureUnitInfo__withLinear_impl_jl8yud($this, linear) {
    return _AGTextureUnitInfo___init__impl__s1tthu(insert(_AGTextureUnitInfo___get_data__impl__oupy3n($this), linear, 2));
  }
  function AGTextureUnitInfo__withTrilinear_impl_vhkcy2($this, trilinear) {
    return _AGTextureUnitInfo___init__impl__s1tthu(insert(_AGTextureUnitInfo___get_data__impl__oupy3n($this), trilinear, 3));
  }
  function AGTextureUnitInfo__withLinearTrilinear_impl_aactvz($this, linear, trilinear) {
    return AGTextureUnitInfo__withTrilinear_impl_vhkcy2(AGTextureUnitInfo__withLinear_impl_jl8yud($this, linear), trilinear);
  }
  function AGTextureUnitInfo__toString_impl_7im6je($this) {
    return 'AGTextureUnitInfo(wrap=' + new AGWrapMode(_AGTextureUnitInfo___get_wrap__impl__dwp6l($this)) + ', linear=' + _AGTextureUnitInfo___get_linear__impl__jak6ny($this) + ', trilinear=' + _AGTextureUnitInfo___get_trilinear__impl__hxtqfb($this) + ', kind=' + new AGTextureTargetKind(_AGTextureUnitInfo___get_kind__impl__6xtjoz($this)) + ')';
  }
  function AGTextureUnitInfo__hashCode_impl_e8jq9h($this) {
    return $this;
  }
  function AGTextureUnitInfo__equals_impl_j2jlgn($this, other) {
    if (!(other instanceof AGTextureUnitInfo))
      return false;
    var tmp0_other_with_cast = other instanceof AGTextureUnitInfo ? other.b7r_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGTextureUnitInfo(data) {
    Companion_getInstance_38();
    this.b7r_1 = data;
  }
  protoOf(AGTextureUnitInfo).toString = function () {
    return AGTextureUnitInfo__toString_impl_7im6je(this.b7r_1);
  };
  protoOf(AGTextureUnitInfo).hashCode = function () {
    return AGTextureUnitInfo__hashCode_impl_e8jq9h(this.b7r_1);
  };
  protoOf(AGTextureUnitInfo).equals = function (other) {
    return AGTextureUnitInfo__equals_impl_j2jlgn(this.b7r_1, other);
  };
  function Companion_23() {
    Companion_instance_23 = this;
    this.k7m_1 = 8;
  }
  protoOf(Companion_23).l7m = function () {
    return AGTextureUnits_init_$Create$();
  };
  var Companion_instance_23;
  function Companion_getInstance_39() {
    if (Companion_instance_23 == null)
      new Companion_23();
    return Companion_instance_23;
  }
  function AGTextureUnits_init_$Init$(size, $this) {
    size = size === VOID ? Companion_getInstance_39().k7m_1 : size;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(size), null);
    AGTextureUnits.call($this, tmp$ret$0, _AGTextureUnitInfoArray___init__impl__c77rdb_0(size));
    return $this;
  }
  function AGTextureUnits_init_$Create$(size) {
    return AGTextureUnits_init_$Init$(size, objectCreate(protoOf(AGTextureUnits)));
  }
  function AGTextureUnits(textures, infos) {
    Companion_getInstance_39();
    this.c7r_1 = textures;
    this.d7r_1 = infos;
  }
  protoOf(AGTextureUnits).l = function () {
    return this.c7r_1.length;
  };
  protoOf(AGTextureUnits).e7r = function (index, texture, info) {
    this.c7r_1[index] = texture;
    AGTextureUnitInfoArray__set_impl_s95rfh(this.d7r_1, index, info);
  };
  protoOf(AGTextureUnits).f7r = function (sampler, texture, info) {
    this.e7r(sampler.r7r_1, texture, info);
  };
  protoOf(AGTextureUnits).t7r = function (sampler, texture, info, $super) {
    info = info === VOID ? Companion_getInstance_38().u7q_1 : info;
    var tmp;
    if ($super === VOID) {
      this.f7r(sampler, texture, info);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.f7r.call(this, sampler, texture, new AGTextureUnitInfo(info));
    }
    return tmp;
  };
  protoOf(AGTextureUnits).e4 = function () {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.e7r(n, null, Companion_getInstance_38().u7q_1);
      }
       while (inductionVariable < last);
  };
  protoOf(AGTextureUnits).z19 = function () {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.c7r_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_copyOf;
    tmp$ret$1 = tmp$ret$0.slice();
    return new AGTextureUnits(tmp$ret$1, AGTextureUnitInfoArray__copyOf_impl_z2w8z3(this.d7r_1));
  };
  protoOf(AGTextureUnits).toString = function () {
    return 'AGTextureUnits(textures=' + toString(this.c7r_1) + ', infos=' + new AGTextureUnitInfoArray(this.d7r_1) + ')';
  };
  protoOf(AGTextureUnits).hashCode = function () {
    var result = hashCode(this.c7r_1);
    result = imul(result, 31) + AGTextureUnitInfoArray__hashCode_impl_siosps(this.d7r_1) | 0;
    return result;
  };
  protoOf(AGTextureUnits).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGTextureUnits))
      return false;
    var tmp0_other_with_cast = other instanceof AGTextureUnits ? other : THROW_CCE();
    if (!equals(this.c7r_1, tmp0_other_with_cast.c7r_1))
      return false;
    if (!equals(this.d7r_1, tmp0_other_with_cast.d7r_1))
      return false;
    return true;
  };
  function AGFrameBufferBase(isMain) {
    AGObject.call(this);
    this.y7r_1 = isMain;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new AGTexture();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.AGFrameBufferBase.tex.<anonymous>' call
    tmp0_also.o7p_1 = true;
    tmp$ret$0 = tmp0_also;
    tmp.z7r_1 = tmp$ret$0;
    this.a7s_1 = Companion_getInstance_4().k1l(0);
  }
  protoOf(AGFrameBufferBase).b7s = function () {
    return !this.y7r_1;
  };
  protoOf(AGFrameBufferBase).n1q = function () {
    this.z7r_1.n1q();
  };
  protoOf(AGFrameBufferBase).toString = function () {
    return 'AGFrameBufferBase(isMain=' + this.y7r_1 + ')';
  };
  function AGFrameBuffer_init_$Init$(isMain, id, $this) {
    isMain = isMain === VOID ? false : isMain;
    id = id === VOID ? -1 : id;
    AGFrameBuffer.call($this, new AGFrameBufferBase(isMain), id);
    return $this;
  }
  function AGFrameBuffer_init_$Create$(isMain, id) {
    return AGFrameBuffer_init_$Init$(isMain, id, objectCreate(protoOf(AGFrameBuffer)));
  }
  function Companion_24() {
    Companion_instance_24 = this;
    this.c7s_1 = 128;
    this.d7s_1 = 128;
  }
  var Companion_instance_24;
  function Companion_getInstance_40() {
    if (Companion_instance_24 == null)
      new Companion_24();
    return Companion_instance_24;
  }
  function markAsDirty($this) {
  }
  function AGFrameBuffer(base, id) {
    Companion_getInstance_40();
    id = id === VOID ? -1 : id;
    this.h7n_1 = base;
    this.i7n_1 = id;
    this.j7n_1 = 1;
    this.k7n_1 = true;
    this.l7n_1 = true;
    this.m7n_1 = 0;
    this.n7n_1 = 0;
    var tmp = this;
    Companion_getInstance_40();
    tmp.o7n_1 = 128;
    var tmp_0 = this;
    Companion_getInstance_40();
    tmp_0.p7n_1 = 128;
    var tmp_1 = this;
    Companion_getInstance_40();
    tmp_1.q7n_1 = 128;
    var tmp_2 = this;
    Companion_getInstance_40();
    tmp_2.r7n_1 = 128;
    this.s7n_1 = RectangleInt_init_$Create$();
    this.t7n_1 = null;
  }
  protoOf(AGFrameBuffer).b7s = function () {
    return this.h7n_1.b7s();
  };
  protoOf(AGFrameBuffer).e7s = function () {
    return this.h7n_1.y7r_1;
  };
  protoOf(AGFrameBuffer).f7s = function () {
    return this.h7n_1.z7r_1;
  };
  protoOf(AGFrameBuffer).u7n = function () {
    return AGFrameBufferInfo__withHasStencil_impl_nbaemo(AGFrameBufferInfo__withHasDepth_impl_fjgbiv(AGFrameBufferInfo__withSamples_impl_83818f(AGFrameBufferInfo__withSize_impl_sc5iep(_AGFrameBufferInfo___init__impl__dbxiro(0), this.o7n_1, this.p7n_1), this.j7n_1), this.l7n_1), this.k7n_1);
  };
  protoOf(AGFrameBuffer).g7s = function (width, height) {
    this.h7s(0, 0, width, height);
  };
  protoOf(AGFrameBuffer).i7s = function (x, y, width, height, fullWidth, fullHeight) {
    if (((((this.m7n_1 === x ? this.n7n_1 === y : false) ? this.o7n_1 === width : false) ? this.p7n_1 === height : false) ? this.q7n_1 === fullWidth : false) ? this.r7n_1 === fullHeight : false)
      return Unit_getInstance();
    this.f7s().r7q(new NullBitmap(width, height));
    this.h7n_1.a7s_1 = Companion_getInstance_4().k1l(imul(imul(fullWidth, fullHeight), 8));
    this.m7n_1 = x;
    this.n7n_1 = y;
    this.o7n_1 = width;
    this.p7n_1 = height;
    this.q7n_1 = fullWidth;
    this.r7n_1 = fullHeight;
    markAsDirty(this);
  };
  protoOf(AGFrameBuffer).h7s = function (x, y, width, height, fullWidth, fullHeight, $super) {
    fullWidth = fullWidth === VOID ? width : fullWidth;
    fullHeight = fullHeight === VOID ? height : fullHeight;
    var tmp;
    if ($super === VOID) {
      this.i7s(x, y, width, height, fullWidth, fullHeight);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.i7s.call(this, x, y, width, height, fullWidth, fullHeight);
    }
    return tmp;
  };
  protoOf(AGFrameBuffer).n1q = function () {
    this.h7n_1.n1q();
  };
  protoOf(AGFrameBuffer).j7s = function (samples) {
    if (this.j7n_1 === samples)
      return Unit_getInstance();
    this.j7n_1 = samples;
    markAsDirty(this);
  };
  protoOf(AGFrameBuffer).k7s = function (hasDepth, hasStencil) {
    if (this.l7n_1 === hasDepth ? this.k7n_1 === hasStencil : false)
      return Unit_getInstance();
    this.l7n_1 = hasDepth;
    this.k7n_1 = hasStencil;
    markAsDirty(this);
  };
  protoOf(AGFrameBuffer).toString = function () {
    return 'AGFrameBuffer(' + (this.e7s() ? 'main' : '' + this.i7n_1) + ', ' + this.o7n_1 + ', ' + this.p7n_1 + ')';
  };
  function _AGTextureUnitInfoArray___init__impl__c77rdb(data) {
    return data;
  }
  function _AGTextureUnitInfoArray___get_data__impl__a4ycyo($this) {
    return $this;
  }
  function _AGTextureUnitInfoArray___init__impl__c77rdb_0(size) {
    var tmp = 0;
    var tmp_0 = size;
    var tmp_1 = new Int32Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.AGTextureUnitInfoArray.<init>.<anonymous>' call
      tmp$ret$0 = _AGTextureUnitInfo___get_data__impl__oupy3n(Companion_getInstance_38().u7q_1);
      tmp_1[tmp_2] = tmp$ret$0;
      tmp = tmp + 1 | 0;
    }
    var tmp_3 = _AGTextureUnitInfoArray___init__impl__c77rdb(tmp_1);
    return tmp_3;
  }
  function AGTextureUnitInfoArray__set_impl_s95rfh($this, index, value) {
    _AGTextureUnitInfoArray___get_data__impl__a4ycyo($this)[index] = _AGTextureUnitInfo___get_data__impl__oupy3n(value);
  }
  function AGTextureUnitInfoArray__get_impl_xhvpef($this, index) {
    return Companion_getInstance_38().y7q(_AGTextureUnitInfoArray___get_data__impl__a4ycyo($this)[index]);
  }
  function AGTextureUnitInfoArray__copyOf_impl_z2w8z3($this) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = _AGTextureUnitInfoArray___get_data__impl__a4ycyo($this);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_copyOf;
    tmp$ret$1 = tmp$ret$0.slice();
    return _AGTextureUnitInfoArray___init__impl__c77rdb(tmp$ret$1);
  }
  function AGTextureUnitInfoArray__toString_impl_kr9cgh($this) {
    return 'AGTextureUnitInfoArray(data=' + toString($this) + ')';
  }
  function AGTextureUnitInfoArray__hashCode_impl_siosps($this) {
    return hashCode($this);
  }
  function AGTextureUnitInfoArray__equals_impl_e2lbno($this, other) {
    if (!(other instanceof AGTextureUnitInfoArray))
      return false;
    var tmp0_other_with_cast = other instanceof AGTextureUnitInfoArray ? other.l7s_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGTextureUnitInfoArray(data) {
    this.l7s_1 = data;
  }
  protoOf(AGTextureUnitInfoArray).toString = function () {
    return AGTextureUnitInfoArray__toString_impl_kr9cgh(this.l7s_1);
  };
  protoOf(AGTextureUnitInfoArray).hashCode = function () {
    return AGTextureUnitInfoArray__hashCode_impl_siosps(this.l7s_1);
  };
  protoOf(AGTextureUnitInfoArray).equals = function (other) {
    return AGTextureUnitInfoArray__equals_impl_e2lbno(this.l7s_1, other);
  };
  function BufferCache() {
    this.m7s_1 = FastIdentityMap();
  }
  protoOf(BufferCache).p1g = function () {
    // Inline function 'korlibs.datastructure.fastForEach' call
    var tmp1_fastForEach = this.m7s_1;
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp1_fastForEach;
    var mapIterator = tmp$ret$0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEach.<anonymous>' call
      var tmp0__anonymous__q1qw7t = v.value;
      // Inline function 'korlibs.graphics.BufferCache.reset.<anonymous>' call
      var tmp2__anonymous__z9zvc9 = ensureNotNull(get(tmp1_fastForEach, tmp0__anonymous__q1qw7t));
      tmp2__anonymous__z9zvc9.p1g();
    }
  };
  protoOf(BufferCache).w7s = function () {
    // Inline function 'korlibs.datastructure.fastForEach' call
    var tmp1_fastForEach = this.m7s_1;
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp1_fastForEach;
    var mapIterator = tmp$ret$0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEach.<anonymous>' call
      var tmp0__anonymous__q1qw7t = v.value;
      // Inline function 'korlibs.graphics.BufferCache.uploadUpdatedBuffers.<anonymous>' call
      var tmp2__anonymous__z9zvc9 = ensureNotNull(get(tmp1_fastForEach, tmp0__anonymous__q1qw7t));
      tmp2__anonymous__z9zvc9.x7s();
    }
  };
  protoOf(BufferCache).y7s = function (block) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.getOrPut' call
      var tmp0_getOrPut = this.m7s_1;
      var res = get(tmp0_getOrPut, block);
      if (!(res == null)) {
        tmp$ret$0 = res;
        break $l$block;
      }
      var tmp$ret$1;
      // Inline function 'korlibs.graphics.BufferCache.get.<anonymous>' call
      tmp$ret$1 = new UniformBlockBuffer(block);
      var out = tmp$ret$1;
      set(tmp0_getOrPut, block, out);
      tmp$ret$0 = out;
    }
    var tmp = tmp$ret$0;
    return tmp instanceof UniformBlockBuffer ? tmp : THROW_CCE();
  };
  function AGProgramWithUniforms(program, bufferCache) {
    bufferCache = bufferCache === VOID ? new BufferCache() : bufferCache;
    this.z7s_1 = program;
    this.a7t_1 = bufferCache;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.z7s_1.m7t_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.uniformLayouts.<anonymous>' call
      tmp$ret$0 = item.g7u_1;
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.b7t_1 = distinct(tmp$ret$2);
    var tmp_0 = this;
    var tmp$ret$3;
    $l$block: {
      // Inline function 'kotlin.collections.maxOfOrNull' call
      var tmp0_maxOfOrNull = this.b7t_1;
      var iterator = tmp0_maxOfOrNull.h();
      if (!iterator.j()) {
        tmp$ret$3 = null;
        break $l$block;
      }
      var tmp$ret$4;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.maxLocation.<anonymous>' call
      var tmp1__anonymous__uwfjfc = iterator.k();
      tmp$ret$4 = tmp1__anonymous__uwfjfc.i7u_1 + 1 | 0;
      var maxValue = tmp$ret$4;
      while (iterator.j()) {
        var tmp$ret$5;
        // Inline function 'korlibs.graphics.AGProgramWithUniforms.maxLocation.<anonymous>' call
        var tmp2__anonymous__z9zvc9 = iterator.k();
        tmp$ret$5 = tmp2__anonymous__z9zvc9.i7u_1 + 1 | 0;
        var v = tmp$ret$5;
        if (compareTo(maxValue, v) < 0) {
          maxValue = v;
        }
      }
      tmp$ret$3 = maxValue;
    }
    var tmp0_elvis_lhs = tmp$ret$3;
    tmp_0.c7t_1 = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp_1 = this;
    var tmp$ret$8;
    // Inline function 'kotlin.also' call
    var tmp_2 = 0;
    var tmp_3 = this.c7t_1;
    var tmp$ret$6;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$6 = fillArrayVal(Array(tmp_3), null);
    var tmp_4 = tmp$ret$6;
    while (tmp_2 < tmp_3) {
      var tmp_5 = tmp_2;
      var tmp$ret$7;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.uniformsBlocks.<anonymous>' call
      tmp$ret$7 = null;
      tmp_4[tmp_5] = tmp$ret$7;
      tmp_2 = tmp_2 + 1 | 0;
    }
    var tmp0_also = tmp_4;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.AGProgramWithUniforms.uniformsBlocks.<anonymous>' call
    var tmp0_iterator_0 = this.b7t_1.h();
    while (tmp0_iterator_0.j()) {
      var layout = tmp0_iterator_0.k();
      tmp0_also[layout.i7u_1] = layout;
    }
    tmp$ret$8 = tmp0_also;
    tmp_1.d7t_1 = tmp$ret$8;
    var tmp_6 = this;
    var tmp_7 = 0;
    var tmp_8 = this.d7t_1.length;
    var tmp$ret$9;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$9 = fillArrayVal(Array(tmp_8), null);
    var tmp_9 = tmp$ret$9;
    while (tmp_7 < tmp_8) {
      var tmp_10 = tmp_7;
      var tmp$ret$12;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.uniformsBlocksData.<anonymous>' call
      var tmp0_safe_receiver = this.d7t_1[tmp_10];
      var tmp_11;
      if (tmp0_safe_receiver == null) {
        tmp_11 = null;
      } else {
        var tmp$ret$11;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$10;
        // Inline function 'korlibs.graphics.AGProgramWithUniforms.uniformsBlocksData.<anonymous>' call
        tmp$ret$10 = this.a7t_1.y7s(tmp0_safe_receiver);
        tmp$ret$11 = tmp$ret$10;
        tmp_11 = tmp$ret$11;
      }
      tmp$ret$12 = tmp_11;
      tmp_9[tmp_10] = tmp$ret$12;
      tmp_7 = tmp_7 + 1 | 0;
    }
    tmp_6.e7t_1 = tmp_9;
    var tmp_12 = this;
    var tmp_13 = 0;
    var tmp_14 = this.d7t_1.length;
    var tmp$ret$13;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$13 = fillArrayVal(Array(tmp_14), null);
    var tmp_15 = tmp$ret$13;
    while (tmp_13 < tmp_14) {
      var tmp_16 = tmp_13;
      var tmp$ret$16;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.agUniformBlockDatas.<anonymous>' call
      var tmp0_safe_receiver_0 = this.e7t_1[tmp_16];
      var tmp1_safe_receiver = tmp0_safe_receiver_0 == null ? null : tmp0_safe_receiver_0.n7s_1;
      var tmp_17;
      if (tmp1_safe_receiver == null) {
        tmp_17 = null;
      } else {
        var tmp$ret$15;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$14;
        // Inline function 'korlibs.graphics.AGProgramWithUniforms.agUniformBlockDatas.<anonymous>' call
        tmp$ret$14 = new UniformBlockBuffer(tmp1_safe_receiver);
        tmp$ret$15 = tmp$ret$14;
        tmp_17 = tmp$ret$15;
      }
      tmp$ret$16 = tmp_17;
      tmp_15[tmp_16] = tmp$ret$16;
      tmp_13 = tmp_13 + 1 | 0;
    }
    tmp_12.f7t_1 = tmp_15;
    var tmp_18 = this;
    var tmp_19 = 0;
    var tmp_20 = this.d7t_1.length;
    var tmp$ret$17;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$17 = fillArrayVal(Array(tmp_20), null);
    var tmp_21 = tmp$ret$17;
    while (tmp_19 < tmp_20) {
      var tmp_22 = tmp_19;
      var tmp$ret$18;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.agBuffers.<anonymous>' call
      var tmp0_safe_receiver_1 = this.e7t_1[tmp_22];
      tmp$ret$18 = tmp0_safe_receiver_1 == null ? null : tmp0_safe_receiver_1.o7s_1;
      tmp_21[tmp_22] = tmp$ret$18;
      tmp_19 = tmp_19 + 1 | 0;
    }
    tmp_18.g7t_1 = tmp_21;
    var tmp_23 = this;
    var tmp_24 = 0;
    var tmp_25 = this.d7t_1.length;
    var tmp_26 = new Int32Array(tmp_25);
    while (tmp_24 < tmp_25) {
      var tmp_27 = tmp_24;
      var tmp$ret$19;
      // Inline function 'korlibs.graphics.AGProgramWithUniforms.agBufferIndices.<anonymous>' call
      tmp$ret$19 = 0;
      tmp_26[tmp_27] = tmp$ret$19;
      tmp_24 = tmp_24 + 1 | 0;
    }
    tmp_23.h7t_1 = tmp_26;
  }
  protoOf(AGProgramWithUniforms).m7u = function () {
    var inductionVariable = 0;
    var last = this.h7t_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_safe_receiver = this.e7t_1[n];
        var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.n7u();
        this.h7t_1[n] = tmp2_elvis_lhs == null ? -1 : tmp2_elvis_lhs;
      }
       while (inductionVariable <= last);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = this.h7t_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_copyOf;
    tmp$ret$1 = tmp$ret$0.slice();
    return new UniformBlocksBuffersRef(this.f7t_1, this.g7t_1, tmp$ret$1);
  };
  function AGVertexArrayObject_init_$Init$(datas, isDynamic, $this) {
    isDynamic = isDynamic === VOID ? true : isDynamic;
    AGVertexArrayObject.call($this, fastArrayListOf(datas.slice()), isDynamic);
    return $this;
  }
  function AGVertexArrayObject_init_$Create$(datas, isDynamic) {
    return AGVertexArrayObject_init_$Init$(datas, isDynamic, objectCreate(protoOf(AGVertexArrayObject)));
  }
  function AGVertexArrayObject(list, isDynamic) {
    isDynamic = isDynamic === VOID ? true : isDynamic;
    this.o7u_1 = list;
    this.p7u_1 = isDynamic;
    this.q7u_1 = new Mixin();
  }
  protoOf(AGVertexArrayObject).d1i = function (_set____db54di) {
    this.q7u_1.d1i(_set____db54di);
  };
  protoOf(AGVertexArrayObject).e1i = function () {
    return this.q7u_1.e1i();
  };
  protoOf(AGVertexArrayObject).toString = function () {
    return 'AGVertexArrayObject(list=' + this.o7u_1 + ', isDynamic=' + this.p7u_1 + ')';
  };
  protoOf(AGVertexArrayObject).hashCode = function () {
    var result = this.o7u_1.hashCode();
    result = imul(result, 31) + (this.p7u_1 | 0) | 0;
    return result;
  };
  protoOf(AGVertexArrayObject).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGVertexArrayObject))
      return false;
    var tmp0_other_with_cast = other instanceof AGVertexArrayObject ? other : THROW_CCE();
    if (!this.o7u_1.equals(tmp0_other_with_cast.o7u_1))
      return false;
    if (!(this.p7u_1 === tmp0_other_with_cast.p7u_1))
      return false;
    return true;
  };
  function AGVertexData(layout, buffer, baseOffset) {
    layout = layout === VOID ? ProgramLayout_init_$Create$([]) : layout;
    buffer = buffer === VOID ? new AGBuffer() : buffer;
    baseOffset = baseOffset === VOID ? 0 : baseOffset;
    this.r7u_1 = layout;
    this.s7u_1 = buffer;
    this.t7u_1 = baseOffset;
  }
  protoOf(AGVertexData).toString = function () {
    return 'AGVertexData(layout=' + this.r7u_1 + ', buffer=' + this.s7u_1 + ', baseOffset=' + this.t7u_1 + ')';
  };
  protoOf(AGVertexData).hashCode = function () {
    var result = hashCode(this.r7u_1);
    result = imul(result, 31) + hashCode(this.s7u_1) | 0;
    result = imul(result, 31) + this.t7u_1 | 0;
    return result;
  };
  protoOf(AGVertexData).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGVertexData))
      return false;
    var tmp0_other_with_cast = other instanceof AGVertexData ? other : THROW_CCE();
    if (!equals(this.r7u_1, tmp0_other_with_cast.r7u_1))
      return false;
    if (!equals(this.s7u_1, tmp0_other_with_cast.s7u_1))
      return false;
    if (!(this.t7u_1 === tmp0_other_with_cast.t7u_1))
      return false;
    return true;
  };
  function _AGStencilReference___init__impl__rta8vg(data) {
    return data;
  }
  function _AGStencilReference___get_data__impl__j5p6bn($this) {
    return $this;
  }
  function _AGStencilReference___init__impl__rta8vg_0(front, back) {
    var tmp = _AGStencilReference___init__impl__rta8vg(fromLowHigh(Companion_getInstance_5(), front, back));
    return tmp;
  }
  function Companion_25() {
    Companion_instance_25 = this;
    this.m7m_1 = _AGStencilReference___init__impl__rta8vg(new Long(-1, -1));
    this.n7m_1 = AGStencilReference__withWriteMask$default_impl_b7j7b4(AGStencilReference__withReadMask$default_impl_7mdffx(AGStencilReference__withReferenceValue$default_impl_2trj0v(_AGStencilReference___init__impl__rta8vg(new Long(0, 0)), 0), 255), 255);
  }
  var Companion_instance_25;
  function Companion_getInstance_41() {
    if (Companion_instance_25 == null)
      new Companion_25();
    return Companion_instance_25;
  }
  function _AGStencilReference___get_front__impl__6ggyy0($this) {
    return get_low(_AGStencilReference___get_data__impl__j5p6bn($this));
  }
  function _AGStencilReference___get_back__impl__mm7fqe($this) {
    return get_high(_AGStencilReference___get_data__impl__j5p6bn($this));
  }
  function _AGStencilReference___get_referenceValueFront__impl__p7smsu($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_front__impl__6ggyy0($this);
    tmp$ret$0 = (tmp0_extract8 >>> 0 | 0) & 255;
    return tmp$ret$0;
  }
  function _AGStencilReference___get_readMaskFront__impl__oc0s46($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_front__impl__6ggyy0($this);
    tmp$ret$0 = (tmp0_extract8 >>> 8 | 0) & 255;
    return tmp$ret$0;
  }
  function _AGStencilReference___get_writeMaskFront__impl__cqdpbj($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_front__impl__6ggyy0($this);
    tmp$ret$0 = (tmp0_extract8 >>> 16 | 0) & 255;
    return tmp$ret$0;
  }
  function _AGStencilReference___get_referenceValueBack__impl__mlrbhc($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_back__impl__mm7fqe($this);
    tmp$ret$0 = (tmp0_extract8 >>> 0 | 0) & 255;
    return tmp$ret$0;
  }
  function _AGStencilReference___get_readMaskBack__impl__lwtaqk($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_back__impl__mm7fqe($this);
    tmp$ret$0 = (tmp0_extract8 >>> 8 | 0) & 255;
    return tmp$ret$0;
  }
  function _AGStencilReference___get_writeMaskBack__impl__mths73($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _AGStencilReference___get_back__impl__mm7fqe($this);
    tmp$ret$0 = (tmp0_extract8 >>> 16 | 0) & 255;
    return tmp$ret$0;
  }
  function AGStencilReference__withReferenceValue_impl_lm1qpg($this, referenceValue, referenceValueBack) {
    return _AGStencilReference___init__impl__rta8vg_0(insert8(_AGStencilReference___get_front__impl__6ggyy0($this), referenceValue, 0), insert8(_AGStencilReference___get_back__impl__mm7fqe($this), referenceValueBack, 0));
  }
  function AGStencilReference__withReferenceValue$default_impl_2trj0v($this, referenceValue, referenceValueBack, $super) {
    referenceValueBack = referenceValueBack === VOID ? referenceValue : referenceValueBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilReference__withReferenceValue_impl_lm1qpg($this, referenceValue, referenceValueBack);
    } else {
      var tmp_0 = new AGStencilReference($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilReference(tmp_1)).v7u.call(tmp_0, referenceValue, referenceValueBack).u7u_1;
    }
    return tmp;
  }
  function AGStencilReference__withReadMask_impl_75whrk($this, readMask, readMaskBack) {
    return _AGStencilReference___init__impl__rta8vg_0(insert8(_AGStencilReference___get_front__impl__6ggyy0($this), readMask, 8), insert8(_AGStencilReference___get_back__impl__mm7fqe($this), readMaskBack, 8));
  }
  function AGStencilReference__withReadMask$default_impl_7mdffx($this, readMask, readMaskBack, $super) {
    readMaskBack = readMaskBack === VOID ? readMask : readMaskBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilReference__withReadMask_impl_75whrk($this, readMask, readMaskBack);
    } else {
      var tmp_0 = new AGStencilReference($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilReference(tmp_1)).w7u.call(tmp_0, readMask, readMaskBack).u7u_1;
    }
    return tmp;
  }
  function AGStencilReference__withWriteMask_impl_s5s8t($this, writeMask, writeMaskBack) {
    return _AGStencilReference___init__impl__rta8vg_0(insert8(_AGStencilReference___get_front__impl__6ggyy0($this), writeMask, 16), insert8(_AGStencilReference___get_back__impl__mm7fqe($this), writeMaskBack, 16));
  }
  function AGStencilReference__withWriteMask$default_impl_b7j7b4($this, writeMask, writeMaskBack, $super) {
    writeMaskBack = writeMaskBack === VOID ? writeMask : writeMaskBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilReference__withWriteMask_impl_s5s8t($this, writeMask, writeMaskBack);
    } else {
      var tmp_0 = new AGStencilReference($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilReference(tmp_1)).x7u.call(tmp_0, writeMask, writeMaskBack).u7u_1;
    }
    return tmp;
  }
  function AGStencilReference__toString_impl_qu5r8s($this) {
    return 'AGStencilReference(front=[referenceValue=' + _AGStencilReference___get_referenceValueFront__impl__p7smsu($this) + ', readMask=' + _AGStencilReference___get_readMaskFront__impl__oc0s46($this) + ', writeMask=' + _AGStencilReference___get_writeMaskFront__impl__cqdpbj($this) + '], back=[referenceValue=' + _AGStencilReference___get_referenceValueBack__impl__mlrbhc($this) + ', readMask=' + _AGStencilReference___get_readMaskBack__impl__lwtaqk($this) + ', writeMask=' + _AGStencilReference___get_writeMaskBack__impl__mths73($this) + '])';
  }
  function AGStencilReference__hashCode_impl_52zufx($this) {
    return $this.hashCode();
  }
  function AGStencilReference__equals_impl_kj05j($this, other) {
    if (!(other instanceof AGStencilReference))
      return false;
    var tmp0_other_with_cast = other instanceof AGStencilReference ? other.u7u_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGStencilReference(data) {
    Companion_getInstance_41();
    this.u7u_1 = data;
  }
  protoOf(AGStencilReference).toString = function () {
    return AGStencilReference__toString_impl_qu5r8s(this.u7u_1);
  };
  protoOf(AGStencilReference).hashCode = function () {
    return AGStencilReference__hashCode_impl_52zufx(this.u7u_1);
  };
  protoOf(AGStencilReference).equals = function (other) {
    return AGStencilReference__equals_impl_kj05j(this.u7u_1, other);
  };
  function _AGStencilOpFunc___init__impl__t3f62q(data) {
    return data;
  }
  function _AGStencilOpFunc___get_data__impl__jitrg1($this) {
    return $this;
  }
  function Companion_26() {
    Companion_instance_26 = this;
    this.o7m_1 = _AGStencilOpFunc___init__impl__t3f62q(-1);
    this.p7m_1 = AGStencilOpFunc__withAction$default_impl_jx9cm1(AGStencilOpFunc__withCompareMode$default_impl_rzbauv(AGStencilOpFunc__withEnabled_impl_mpcwmr(_AGStencilOpFunc___init__impl__t3f62q(0), false), Companion_getInstance_51().y7u_1), Companion_getInstance_52().l7v_1, Companion_getInstance_52().l7v_1, Companion_getInstance_52().l7v_1);
  }
  var Companion_instance_26;
  function Companion_getInstance_42() {
    if (Companion_instance_26 == null)
      new Companion_26();
    return Companion_instance_26;
  }
  function _AGStencilOpFunc___get_enabled__impl__hxq8wy($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 31 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGStencilOpFunc___get_compareModeFront__impl__uctsey($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 0 | 0) & 7;
    return _AGCompareMode___init__impl__j8kqbb(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnBothPassFront__impl__aehsgx($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 3 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnDepthFailFront__impl__vuqmyq($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 6 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnDepthPassStencilFailFront__impl__kl5ndh($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 9 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_compareModeBack__impl__2rklx0($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 12 | 0) & 7;
    return _AGCompareMode___init__impl__j8kqbb(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnBothPassBack__impl__i5t9l9($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 15 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnDepthFailBack__impl__3tna28($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 18 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function _AGStencilOpFunc___get_actionOnDepthPassStencilFailBack__impl__4qprmh($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGStencilOpFunc___get_data__impl__jitrg1($this);
    tmp$ret$0 = (tmp0_extract3 >>> 21 | 0) & 7;
    return _AGStencilOp___init__impl__llpkv6(tmp$ret$0);
  }
  function AGStencilOpFunc__withEnabled_impl_mpcwmr($this, enabled) {
    return _AGStencilOpFunc___init__impl__t3f62q(insert(_AGStencilOpFunc___get_data__impl__jitrg1($this), enabled, 31));
  }
  function AGStencilOpFunc__withCompareMode_impl_5ovohy($this, compareMode, compareModeBack) {
    return _AGStencilOpFunc___init__impl__t3f62q(insert3(insert3(_AGStencilOpFunc___get_data__impl__jitrg1($this), _AGCompareMode___get_ordinal__impl__ikvlmz(compareMode), 0), _AGCompareMode___get_ordinal__impl__ikvlmz(compareModeBack), 12));
  }
  function AGStencilOpFunc__withCompareMode$default_impl_rzbauv($this, compareMode, compareModeBack, $super) {
    compareModeBack = compareModeBack === VOID ? compareMode : compareModeBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilOpFunc__withCompareMode_impl_5ovohy($this, compareMode, compareModeBack);
    } else {
      var tmp_0 = new AGStencilOpFunc($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilOpFunc(tmp_1)).p7v.call(tmp_0, new AGCompareMode(compareMode), new AGCompareMode(compareModeBack)).o7v_1;
    }
    return tmp;
  }
  function AGStencilOpFunc__withActionOnBothPass_impl_qbn6at($this, actionOnBothPass, actionOnBothPassBack) {
    return _AGStencilOpFunc___init__impl__t3f62q(insert3(insert3(_AGStencilOpFunc___get_data__impl__jitrg1($this), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnBothPass), 3), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnBothPassBack), 15));
  }
  function AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo($this, actionOnBothPass, actionOnBothPassBack, $super) {
    actionOnBothPassBack = actionOnBothPassBack === VOID ? actionOnBothPass : actionOnBothPassBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilOpFunc__withActionOnBothPass_impl_qbn6at($this, actionOnBothPass, actionOnBothPassBack);
    } else {
      var tmp_0 = new AGStencilOpFunc($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilOpFunc(tmp_1)).q7v.call(tmp_0, new AGStencilOp(actionOnBothPass), new AGStencilOp(actionOnBothPassBack)).o7v_1;
    }
    return tmp;
  }
  function AGStencilOpFunc__withActionOnDepthFail_impl_6tt0te($this, actionOnDepthFail, actionOnDepthFailBack) {
    return _AGStencilOpFunc___init__impl__t3f62q(insert3(insert3(_AGStencilOpFunc___get_data__impl__jitrg1($this), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnDepthFail), 6), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnDepthFailBack), 18));
  }
  function AGStencilOpFunc__withActionOnDepthPassStencilFail_impl_q34w35($this, actionOnDepthPassStencilFail, actionOnDepthPassStencilFailBack) {
    return _AGStencilOpFunc___init__impl__t3f62q(insert3(insert3(_AGStencilOpFunc___get_data__impl__jitrg1($this), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnDepthPassStencilFail), 9), _AGStencilOp___get_ordinal__impl__xkavfu(actionOnDepthPassStencilFailBack), 21));
  }
  function AGStencilOpFunc__withAction_impl_p1jbl6($this, actionOnBothPass, actionOnDepthFail, actionOnDepthPassStencilFail, actionOnBothPassBack, actionOnDepthFailBack, actionOnDepthPassStencilFailBack) {
    return AGStencilOpFunc__withActionOnDepthPassStencilFail_impl_q34w35(AGStencilOpFunc__withActionOnDepthFail_impl_6tt0te(AGStencilOpFunc__withActionOnBothPass_impl_qbn6at($this, actionOnBothPass, actionOnBothPassBack), actionOnDepthFail, actionOnDepthFailBack), actionOnDepthPassStencilFail, actionOnDepthPassStencilFailBack);
  }
  function AGStencilOpFunc__withAction$default_impl_jx9cm1($this, actionOnBothPass, actionOnDepthFail, actionOnDepthPassStencilFail, actionOnBothPassBack, actionOnDepthFailBack, actionOnDepthPassStencilFailBack, $super) {
    actionOnDepthFail = actionOnDepthFail === VOID ? actionOnBothPass : actionOnDepthFail;
    actionOnDepthPassStencilFail = actionOnDepthPassStencilFail === VOID ? actionOnDepthFail : actionOnDepthPassStencilFail;
    actionOnBothPassBack = actionOnBothPassBack === VOID ? actionOnBothPass : actionOnBothPassBack;
    actionOnDepthFailBack = actionOnDepthFailBack === VOID ? actionOnDepthFail : actionOnDepthFailBack;
    actionOnDepthPassStencilFailBack = actionOnDepthPassStencilFailBack === VOID ? actionOnDepthPassStencilFail : actionOnDepthPassStencilFailBack;
    var tmp;
    if ($super === VOID) {
      tmp = AGStencilOpFunc__withAction_impl_p1jbl6($this, actionOnBothPass, actionOnDepthFail, actionOnDepthPassStencilFail, actionOnBothPassBack, actionOnDepthFailBack, actionOnDepthPassStencilFailBack);
    } else {
      var tmp_0 = new AGStencilOpFunc($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGStencilOpFunc(tmp_1)).r7v.call(tmp_0, new AGStencilOp(actionOnBothPass), new AGStencilOp(actionOnDepthFail), new AGStencilOp(actionOnDepthPassStencilFail), new AGStencilOp(actionOnBothPassBack), new AGStencilOp(actionOnDepthFailBack), new AGStencilOp(actionOnDepthPassStencilFailBack)).o7v_1;
    }
    return tmp;
  }
  function AGStencilOpFunc__toString_impl_pl8quy($this) {
    if (!_AGStencilOpFunc___get_enabled__impl__hxq8wy($this))
      return 'AGStencilOpFunc(enabled=false)';
    return 'AGStencilOpFunc(enabled=' + _AGStencilOpFunc___get_enabled__impl__hxq8wy($this) + ', front=[compareMode=' + new AGCompareMode(_AGStencilOpFunc___get_compareModeFront__impl__uctsey($this)) + ', actions=[' + new AGStencilOp(_AGStencilOpFunc___get_actionOnBothPassFront__impl__aehsgx($this)) + ', ' + new AGStencilOp(_AGStencilOpFunc___get_actionOnDepthFailFront__impl__vuqmyq($this)) + ', ' + new AGStencilOp(_AGStencilOpFunc___get_actionOnDepthPassStencilFailFront__impl__kl5ndh($this)) + ']], back=[compareMode=' + new AGCompareMode(_AGStencilOpFunc___get_compareModeBack__impl__2rklx0($this)) + ', actions=[' + new AGStencilOp(_AGStencilOpFunc___get_actionOnBothPassBack__impl__i5t9l9($this)) + ', ' + new AGStencilOp(_AGStencilOpFunc___get_actionOnDepthFailBack__impl__3tna28($this)) + ', ' + new AGStencilOp(_AGStencilOpFunc___get_actionOnDepthPassStencilFailBack__impl__4qprmh($this)) + ']])';
  }
  function AGStencilOpFunc__hashCode_impl_nopebb($this) {
    return $this;
  }
  function AGStencilOpFunc__equals_impl_y44i4z($this, other) {
    if (!(other instanceof AGStencilOpFunc))
      return false;
    var tmp0_other_with_cast = other instanceof AGStencilOpFunc ? other.o7v_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGStencilOpFunc(data) {
    Companion_getInstance_42();
    this.o7v_1 = data;
  }
  protoOf(AGStencilOpFunc).toString = function () {
    return AGStencilOpFunc__toString_impl_pl8quy(this.o7v_1);
  };
  protoOf(AGStencilOpFunc).hashCode = function () {
    return AGStencilOpFunc__hashCode_impl_nopebb(this.o7v_1);
  };
  protoOf(AGStencilOpFunc).equals = function (other) {
    return AGStencilOpFunc__equals_impl_y44i4z(this.o7v_1, other);
  };
  function _AGColorMask___init__impl__j38ai8(data) {
    return data;
  }
  function _AGColorMask___get_data__impl__swj1bz($this) {
    return $this;
  }
  function _AGColorMask___get_red__impl__opo1fo($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGColorMask___get_data__impl__swj1bz($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 0 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGColorMask___get_green__impl__d3m1w2($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGColorMask___get_data__impl__swj1bz($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 1 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGColorMask___get_blue__impl__42l0s1($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGColorMask___get_data__impl__swj1bz($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 2 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGColorMask___get_alpha__impl__xmrqzb($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGColorMask___get_data__impl__swj1bz($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 3 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGColorMask___init__impl__j38ai8_0(red, green, blue, alpha) {
    var tmp = _AGColorMask___init__impl__j38ai8(insert(insert(insert(insert(0, red, 0), green, 1), blue, 2), alpha, 3));
    return tmp;
  }
  function _AGColorMask___init__impl__j38ai8_1(value) {
    value = value === VOID ? true : value;
    var tmp = _AGColorMask___init__impl__j38ai8_0(value, value, value, value);
    return tmp;
  }
  function AGColorMask__toString_impl_5i5oqg($this) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.AGColorMask.toString.<anonymous>' call
    tmp0_apply.bc('AGColorMask(');
    tmp0_apply.bc(_AGColorMask___get_red__impl__opo1fo($this) ? 'R' : 'r');
    tmp0_apply.bc(_AGColorMask___get_green__impl__d3m1w2($this) ? 'G' : 'g');
    tmp0_apply.bc(_AGColorMask___get_blue__impl__42l0s1($this) ? 'B' : 'b');
    tmp0_apply.bc(_AGColorMask___get_alpha__impl__xmrqzb($this) ? 'A' : 'a');
    tmp0_apply.bc(')');
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  }
  function Companion_27() {
    Companion_instance_27 = this;
    this.q7m_1 = _AGColorMask___init__impl__j38ai8(-1);
    this.r7m_1 = _AGColorMask___init__impl__j38ai8_1();
    this.s7m_1 = _AGColorMask___init__impl__j38ai8_1(true);
    this.t7m_1 = _AGColorMask___init__impl__j38ai8_1(true);
    this.u7m_1 = _AGColorMask___init__impl__j38ai8_0(true, true, true, false);
    this.v7m_1 = _AGColorMask___init__impl__j38ai8_1(false);
  }
  var Companion_instance_27;
  function Companion_getInstance_43() {
    if (Companion_instance_27 == null)
      new Companion_27();
    return Companion_instance_27;
  }
  function AGColorMask__hashCode_impl_g9082f($this) {
    return $this;
  }
  function AGColorMask__equals_impl_mrih8l($this, other) {
    if (!(other instanceof AGColorMask))
      return false;
    var tmp0_other_with_cast = other instanceof AGColorMask ? other.s7v_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGColorMask(data) {
    Companion_getInstance_43();
    this.s7v_1 = data;
  }
  protoOf(AGColorMask).toString = function () {
    return AGColorMask__toString_impl_5i5oqg(this.s7v_1);
  };
  protoOf(AGColorMask).hashCode = function () {
    return AGColorMask__hashCode_impl_g9082f(this.s7v_1);
  };
  protoOf(AGColorMask).equals = function (other) {
    return AGColorMask__equals_impl_mrih8l(this.s7v_1, other);
  };
  function _AGScissor___init__impl__g8s683(data) {
    return data;
  }
  function _AGScissor___get_data__impl__efv7ho($this) {
    return $this;
  }
  function _AGScissor___get_isNIL__impl__vqe1z3($this) {
    return equals($this, Companion_getInstance_44().b7n_1);
  }
  function _AGScissor___init__impl__g8s683_0(x, y, width, height) {
    var tmp = _AGScissor___init__impl__g8s683(_Short4LongPack___init__impl__ra1it0(toShortClamped(x), toShortClamped(y), toShortClamped(x + width | 0), toShortClamped(y + height | 0)));
    return tmp;
  }
  function _AGScissor___init__impl__g8s683_1(x, y, width, height) {
    var tmp = _AGScissor___init__impl__g8s683_0(toIntRound(x), toIntRound(y), toIntRound(width), toIntRound(height));
    return tmp;
  }
  function _AGScissor___get_left__impl__jiavfz($this) {
    return _Short4LongPack___get_x__impl__ckr4u7(_AGScissor___get_data__impl__efv7ho($this));
  }
  function _AGScissor___get_top__impl__wmf8dv($this) {
    return _Short4LongPack___get_y__impl__g8vc80(_AGScissor___get_data__impl__efv7ho($this));
  }
  function _AGScissor___get_right__impl__3hpzkc($this) {
    return _Short4LongPack___get_z__impl__pym8ox(_AGScissor___get_data__impl__efv7ho($this));
  }
  function _AGScissor___get_bottom__impl__6wmhqj($this) {
    return _Short4LongPack___get_w__impl__tmqg2q(_AGScissor___get_data__impl__efv7ho($this));
  }
  function _AGScissor___get_x__impl__pn9kpc($this) {
    return _AGScissor___get_left__impl__jiavfz($this);
  }
  function _AGScissor___get_y__impl__36cwcv($this) {
    return _AGScissor___get_top__impl__wmf8dv($this);
  }
  function _AGScissor___get_width__impl__tn9xsy($this) {
    return _AGScissor___get_right__impl__3hpzkc($this) - _AGScissor___get_left__impl__jiavfz($this) | 0;
  }
  function _AGScissor___get_height__impl__qag5an($this) {
    return _AGScissor___get_bottom__impl__6wmhqj($this) - _AGScissor___get_top__impl__wmf8dv($this) | 0;
  }
  function AGScissor__toString_impl_g8p6s5($this) {
    if (equals($this, Companion_getInstance_44().b7n_1))
      return 'null';
    return 'Scissor(x=' + _AGScissor___get_x__impl__pn9kpc($this) + ', y=' + _AGScissor___get_y__impl__36cwcv($this) + ', width=' + _AGScissor___get_width__impl__tn9xsy($this) + ', height=' + _AGScissor___get_height__impl__qag5an($this) + ')';
  }
  function AGScissor__toRect_impl_ylyczc($this) {
    return _AGScissor___get_isNIL__impl__vqe1z3($this) ? Companion_getInstance_6().n3s() : Companion_getInstance_6().v40(_AGScissor___get_x__impl__pn9kpc($this), _AGScissor___get_y__impl__36cwcv($this), _AGScissor___get_width__impl__tn9xsy($this), _AGScissor___get_height__impl__qag5an($this));
  }
  function AGScissor__toRectOrNull_impl_bkzy1a($this) {
    var tmp$ret$1;
    // Inline function 'kotlin.takeIf' call
    var tmp0_takeIf = AGScissor__toRect_impl_ylyczc($this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.AGScissor.toRectOrNull.<anonymous>' call
    tmp$ret$0 = tmp0_takeIf.e3s();
    if (tmp$ret$0) {
      tmp = tmp0_takeIf;
    } else {
      tmp = null;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  }
  function Companion_28() {
    Companion_instance_28 = this;
    this.y7m_1 = _AGScissor___init__impl__g8s683_0(0, 0, 0, 0);
    this.z7m_1 = Companion_getInstance_44().t7v(IntCompanionObject_getInstance().MIN_VALUE, IntCompanionObject_getInstance().MIN_VALUE, IntCompanionObject_getInstance().MAX_VALUE, IntCompanionObject_getInstance().MAX_VALUE);
    this.a7n_1 = Companion_getInstance_44().t7v(ShortCompanionObject_getInstance().MIN_VALUE + 2 | 0, ShortCompanionObject_getInstance().MIN_VALUE + 2 | 0, IntCompanionObject_getInstance().MAX_VALUE, IntCompanionObject_getInstance().MAX_VALUE);
    this.b7n_1 = Companion_getInstance_44().t7v(ShortCompanionObject_getInstance().MIN_VALUE + 1 | 0, ShortCompanionObject_getInstance().MIN_VALUE + 1 | 0, IntCompanionObject_getInstance().MAX_VALUE, IntCompanionObject_getInstance().MAX_VALUE);
  }
  protoOf(Companion_28).t7v = function (left, top, right, bottom) {
    return _AGScissor___init__impl__g8s683_0(left, top, right - left | 0, bottom - top | 0);
  };
  protoOf(Companion_28).u7v = function (left, top, right, bottom) {
    return _AGScissor___init__impl__g8s683_1(left, top, right - left, bottom - top);
  };
  protoOf(Companion_28).v7v = function (rect) {
    if (rect == null)
      return this.b7n_1;
    if (rect.d3s())
      return this.b7n_1;
    return _AGScissor___init__impl__g8s683_1(rect.z3r_1, rect.a3s_1, rect.b3s_1, rect.c3s_1);
  };
  protoOf(Companion_28).w7v = function (prev, next) {
    if (equals(prev, this.b7n_1))
      return next;
    if (equals(next, this.b7n_1))
      return prev;
    var intersectsX = _AGScissor___get_left__impl__jiavfz(prev) <= _AGScissor___get_right__impl__3hpzkc(next) ? _AGScissor___get_right__impl__3hpzkc(prev) >= _AGScissor___get_left__impl__jiavfz(next) : false;
    var intersectsY = _AGScissor___get_top__impl__wmf8dv(prev) <= _AGScissor___get_bottom__impl__6wmhqj(next) ? _AGScissor___get_bottom__impl__6wmhqj(prev) >= _AGScissor___get_top__impl__wmf8dv(next) : false;
    if (!intersectsX ? true : !intersectsY)
      return this.y7m_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = _AGScissor___get_left__impl__jiavfz(prev);
    var tmp1_max = _AGScissor___get_left__impl__jiavfz(next);
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    var left = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp2_max = _AGScissor___get_top__impl__wmf8dv(prev);
    var tmp3_max = _AGScissor___get_top__impl__wmf8dv(next);
    tmp$ret$1 = Math.max(tmp2_max, tmp3_max);
    var top = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp4_min = _AGScissor___get_right__impl__3hpzkc(prev);
    var tmp5_min = _AGScissor___get_right__impl__3hpzkc(next);
    tmp$ret$2 = Math.min(tmp4_min, tmp5_min);
    var right = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.math.min' call
    var tmp6_min = _AGScissor___get_bottom__impl__6wmhqj(prev);
    var tmp7_min = _AGScissor___get_bottom__impl__6wmhqj(next);
    tmp$ret$3 = Math.min(tmp6_min, tmp7_min);
    var bottom = tmp$ret$3;
    return this.t7v(left, top, right, bottom);
  };
  var Companion_instance_28;
  function Companion_getInstance_44() {
    if (Companion_instance_28 == null)
      new Companion_28();
    return Companion_instance_28;
  }
  function AGScissor__hashCode_impl_x18ye4($this) {
    return Short4LongPack__hashCode_impl_xks1k5($this);
  }
  function AGScissor__equals_impl_c8up2w($this, other) {
    if (!(other instanceof AGScissor))
      return false;
    var tmp0_other_with_cast = other instanceof AGScissor ? other.x7v_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGScissor(data) {
    Companion_getInstance_44();
    this.x7v_1 = data;
  }
  protoOf(AGScissor).toString = function () {
    return AGScissor__toString_impl_g8p6s5(this.x7v_1);
  };
  protoOf(AGScissor).hashCode = function () {
    return AGScissor__hashCode_impl_x18ye4(this.x7v_1);
  };
  protoOf(AGScissor).equals = function (other) {
    return AGScissor__equals_impl_c8up2w(this.x7v_1, other);
  };
  function AGBatch(frameBuffer, frameBufferInfo, vertexData, indices, indexType, program, uniformBlocks, textureUnits, blending, stencilOpFunc, stencilRef, colorMask, depthAndFrontFace, scissor, cullFace, drawType, drawOffset, vertexCount, instances) {
    vertexData = vertexData === VOID ? AGVertexArrayObject_init_$Create$([new AGVertexData()], true) : vertexData;
    indices = indices === VOID ? null : indices;
    indexType = indexType === VOID ? Companion_getInstance_54().b7m_1 : indexType;
    program = program === VOID ? DefaultShaders_getInstance().r7w_1 : program;
    uniformBlocks = uniformBlocks === VOID ? Companion_getInstance_61().j7m_1 : uniformBlocks;
    textureUnits = textureUnits === VOID ? Companion_getInstance_39().l7m() : textureUnits;
    blending = blending === VOID ? Companion_getInstance_47().f7m_1 : blending;
    stencilOpFunc = stencilOpFunc === VOID ? Companion_getInstance_42().p7m_1 : stencilOpFunc;
    stencilRef = stencilRef === VOID ? Companion_getInstance_41().n7m_1 : stencilRef;
    colorMask = colorMask === VOID ? Companion_getInstance_43().s7m_1 : colorMask;
    depthAndFrontFace = depthAndFrontFace === VOID ? Companion_getInstance_55().x7m_1 : depthAndFrontFace;
    scissor = scissor === VOID ? Companion_getInstance_44().b7n_1 : scissor;
    cullFace = cullFace === VOID ? Companion_getInstance_53().d7n_1 : cullFace;
    drawType = drawType === VOID ? Companion_getInstance_46().x7w_1 : drawType;
    drawOffset = drawOffset === VOID ? 0 : drawOffset;
    vertexCount = vertexCount === VOID ? 0 : vertexCount;
    instances = instances === VOID ? 1 : instances;
    this.y7n_1 = frameBuffer;
    this.z7n_1 = frameBufferInfo;
    this.a7o_1 = vertexData;
    this.b7o_1 = indices;
    this.c7o_1 = indexType;
    this.d7o_1 = program;
    this.e7o_1 = uniformBlocks;
    this.f7o_1 = textureUnits;
    this.g7o_1 = blending;
    this.h7o_1 = stencilOpFunc;
    this.i7o_1 = stencilRef;
    this.j7o_1 = colorMask;
    this.k7o_1 = depthAndFrontFace;
    this.l7o_1 = scissor;
    this.m7o_1 = cullFace;
    this.n7o_1 = drawType;
    this.o7o_1 = drawOffset;
    this.p7o_1 = vertexCount;
    this.q7o_1 = instances;
  }
  protoOf(AGBatch).y7l = function (ag) {
    ag.v7l(this.y7n_1, this.z7n_1, this.a7o_1, this.d7o_1, this.n7o_1, this.p7o_1, this.b7o_1, this.c7o_1, this.o7o_1, this.g7o_1, this.e7o_1, this.f7o_1, this.i7o_1, this.h7o_1, this.j7o_1, this.k7o_1, this.l7o_1, this.m7o_1, this.q7o_1);
  };
  protoOf(AGBatch).toString = function () {
    return 'AGBatch(frameBuffer=' + this.y7n_1 + ', frameBufferInfo=' + new AGFrameBufferInfo(this.z7n_1) + ', vertexData=' + this.a7o_1 + ', indices=' + this.b7o_1 + ', indexType=' + new AGIndexType(this.c7o_1) + ', program=' + this.d7o_1 + ', uniformBlocks=' + this.e7o_1 + ', textureUnits=' + this.f7o_1 + ', blending=' + new AGBlending(this.g7o_1) + ', stencilOpFunc=' + new AGStencilOpFunc(this.h7o_1) + ', stencilRef=' + new AGStencilReference(this.i7o_1) + ', colorMask=' + new AGColorMask(this.j7o_1) + ', depthAndFrontFace=' + new AGDepthAndFrontFace(this.k7o_1) + ', scissor=' + new AGScissor(this.l7o_1) + ', cullFace=' + new AGCullFace(this.m7o_1) + ', drawType=' + new AGDrawType(this.n7o_1) + ', drawOffset=' + this.o7o_1 + ', vertexCount=' + this.p7o_1 + ', instances=' + this.q7o_1 + ')';
  };
  protoOf(AGBatch).hashCode = function () {
    var result = hashCode(this.y7n_1);
    result = imul(result, 31) + AGFrameBufferInfo__hashCode_impl_fvl6f1(this.z7n_1) | 0;
    result = imul(result, 31) + this.a7o_1.hashCode() | 0;
    result = imul(result, 31) + (this.b7o_1 == null ? 0 : hashCode(this.b7o_1)) | 0;
    result = imul(result, 31) + AGIndexType__hashCode_impl_47f06s(this.c7o_1) | 0;
    result = imul(result, 31) + this.d7o_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.e7o_1) | 0;
    result = imul(result, 31) + this.f7o_1.hashCode() | 0;
    result = imul(result, 31) + AGBlending__hashCode_impl_ywnf51(this.g7o_1) | 0;
    result = imul(result, 31) + AGStencilOpFunc__hashCode_impl_nopebb(this.h7o_1) | 0;
    result = imul(result, 31) + AGStencilReference__hashCode_impl_52zufx(this.i7o_1) | 0;
    result = imul(result, 31) + AGColorMask__hashCode_impl_g9082f(this.j7o_1) | 0;
    result = imul(result, 31) + AGDepthAndFrontFace__hashCode_impl_xu62ey(this.k7o_1) | 0;
    result = imul(result, 31) + AGScissor__hashCode_impl_x18ye4(this.l7o_1) | 0;
    result = imul(result, 31) + AGCullFace__hashCode_impl_t4e5ur(this.m7o_1) | 0;
    result = imul(result, 31) + AGDrawType__hashCode_impl_ylaury(this.n7o_1) | 0;
    result = imul(result, 31) + this.o7o_1 | 0;
    result = imul(result, 31) + this.p7o_1 | 0;
    result = imul(result, 31) + this.q7o_1 | 0;
    return result;
  };
  protoOf(AGBatch).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGBatch))
      return false;
    var tmp0_other_with_cast = other instanceof AGBatch ? other : THROW_CCE();
    if (!equals(this.y7n_1, tmp0_other_with_cast.y7n_1))
      return false;
    if (!(this.z7n_1 === tmp0_other_with_cast.z7n_1))
      return false;
    if (!this.a7o_1.equals(tmp0_other_with_cast.a7o_1))
      return false;
    if (!equals(this.b7o_1, tmp0_other_with_cast.b7o_1))
      return false;
    if (!(this.c7o_1 === tmp0_other_with_cast.c7o_1))
      return false;
    if (!this.d7o_1.equals(tmp0_other_with_cast.d7o_1))
      return false;
    if (!equals(this.e7o_1, tmp0_other_with_cast.e7o_1))
      return false;
    if (!this.f7o_1.equals(tmp0_other_with_cast.f7o_1))
      return false;
    if (!(this.g7o_1 === tmp0_other_with_cast.g7o_1))
      return false;
    if (!(this.h7o_1 === tmp0_other_with_cast.h7o_1))
      return false;
    if (!equals(this.i7o_1, tmp0_other_with_cast.i7o_1))
      return false;
    if (!(this.j7o_1 === tmp0_other_with_cast.j7o_1))
      return false;
    if (!(this.k7o_1 === tmp0_other_with_cast.k7o_1))
      return false;
    if (!equals(this.l7o_1, tmp0_other_with_cast.l7o_1))
      return false;
    if (!(this.m7o_1 === tmp0_other_with_cast.m7o_1))
      return false;
    if (!(this.n7o_1 === tmp0_other_with_cast.n7o_1))
      return false;
    if (!(this.o7o_1 === tmp0_other_with_cast.o7o_1))
      return false;
    if (!(this.p7o_1 === tmp0_other_with_cast.p7o_1))
      return false;
    if (!(this.q7o_1 === tmp0_other_with_cast.q7o_1))
      return false;
    return true;
  };
  function _AGFrameBufferInfo___init__impl__dbxiro(data) {
    return data;
  }
  function _AGFrameBufferInfo___get_data__impl__7q146j($this) {
    return $this;
  }
  function _AGFrameBufferInfo___get_width__impl__yhp94z($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract14' call
    var tmp0_extract14 = _AGFrameBufferInfo___get_data__impl__7q146j($this);
    tmp$ret$0 = (tmp0_extract14 >>> 0 | 0) & 16383;
    return tmp$ret$0;
  }
  function _AGFrameBufferInfo___get_height__impl__yfespa($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract14' call
    var tmp0_extract14 = _AGFrameBufferInfo___get_data__impl__7q146j($this);
    tmp$ret$0 = (tmp0_extract14 >>> 14 | 0) & 16383;
    return tmp$ret$0;
  }
  function _AGFrameBufferInfo___get_size__impl__uw80y4($this) {
    return Companion_getInstance_58().a7x(_AGFrameBufferInfo___get_width__impl__yhp94z($this), _AGFrameBufferInfo___get_height__impl__yfespa($this));
  }
  function _AGFrameBufferInfo___get_hasDepth__impl__9knjmc($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGFrameBufferInfo___get_data__impl__7q146j($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 28 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGFrameBufferInfo___get_hasStencil__impl__hjoes3($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGFrameBufferInfo___get_data__impl__7q146j($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 29 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _get_samplesBits__rfaq0u($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _AGFrameBufferInfo___get_data__impl__7q146j($this);
    tmp$ret$0 = (tmp0_extract2 >>> 30 | 0) & 3;
    return tmp$ret$0;
  }
  function _AGFrameBufferInfo___get_samples__impl__7k555s($this) {
    return 1 << _get_samplesBits__rfaq0u($this);
  }
  function _AGFrameBufferInfo___get_hasStencilAndDepth__impl__aauqdj($this) {
    return _AGFrameBufferInfo___get_hasDepth__impl__9knjmc($this) ? _AGFrameBufferInfo___get_hasStencil__impl__hjoes3($this) : false;
  }
  function AGFrameBufferInfo__withSize_impl_sc5iep($this, width, height) {
    return _AGFrameBufferInfo___init__impl__dbxiro(insert14(insert14(_AGFrameBufferInfo___get_data__impl__7q146j($this), width, 0), height, 14));
  }
  function AGFrameBufferInfo__withHasDepth_impl_fjgbiv($this, hasDepth) {
    return _AGFrameBufferInfo___init__impl__dbxiro(insert(_AGFrameBufferInfo___get_data__impl__7q146j($this), hasDepth, 28));
  }
  function AGFrameBufferInfo__withHasStencil_impl_nbaemo($this, hasStencil) {
    return _AGFrameBufferInfo___init__impl__dbxiro(insert(_AGFrameBufferInfo___get_data__impl__7q146j($this), hasStencil, 29));
  }
  function AGFrameBufferInfo__withSamples_impl_83818f($this, samples) {
    return withSamplesBits($this, ilog2(samples));
  }
  function withSamplesBits($this, bits) {
    return _AGFrameBufferInfo___init__impl__dbxiro(insert2(_AGFrameBufferInfo___get_data__impl__7q146j($this), bits, 30));
  }
  function AGFrameBufferInfo__toString_impl_xecyr8($this) {
    return 'AGFrameBufferInfo(width=' + _AGFrameBufferInfo___get_width__impl__yhp94z($this) + ', height=' + _AGFrameBufferInfo___get_height__impl__yfespa($this) + ', hasDepth=' + _AGFrameBufferInfo___get_hasDepth__impl__9knjmc($this) + ', hasStencil=' + _AGFrameBufferInfo___get_hasStencil__impl__hjoes3($this) + ', samples=' + _AGFrameBufferInfo___get_samples__impl__7k555s($this) + ')';
  }
  function Companion_29() {
    Companion_instance_29 = this;
    this.b7x_1 = _AGFrameBufferInfo___init__impl__dbxiro(0);
    this.c7x_1 = _AGFrameBufferInfo___init__impl__dbxiro(-1);
  }
  var Companion_instance_29;
  function Companion_getInstance_45() {
    if (Companion_instance_29 == null)
      new Companion_29();
    return Companion_instance_29;
  }
  function AGFrameBufferInfo__hashCode_impl_fvl6f1($this) {
    return $this;
  }
  function AGFrameBufferInfo__equals_impl_cj6qp3($this, other) {
    if (!(other instanceof AGFrameBufferInfo))
      return false;
    var tmp0_other_with_cast = other instanceof AGFrameBufferInfo ? other.d7x_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGFrameBufferInfo(data) {
    Companion_getInstance_45();
    this.d7x_1 = data;
  }
  protoOf(AGFrameBufferInfo).toString = function () {
    return AGFrameBufferInfo__toString_impl_xecyr8(this.d7x_1);
  };
  protoOf(AGFrameBufferInfo).hashCode = function () {
    return AGFrameBufferInfo__hashCode_impl_fvl6f1(this.d7x_1);
  };
  protoOf(AGFrameBufferInfo).equals = function (other) {
    return AGFrameBufferInfo__equals_impl_cj6qp3(this.d7x_1, other);
  };
  function _AGDrawType___init__impl__emm6mb(ordinal) {
    return ordinal;
  }
  function Companion_30() {
    Companion_instance_30 = this;
    this.t7w_1 = _AGDrawType___init__impl__emm6mb(0);
    this.u7w_1 = _AGDrawType___init__impl__emm6mb(1);
    this.v7w_1 = _AGDrawType___init__impl__emm6mb(2);
    this.w7w_1 = _AGDrawType___init__impl__emm6mb(3);
    this.x7w_1 = _AGDrawType___init__impl__emm6mb(4);
    this.y7w_1 = _AGDrawType___init__impl__emm6mb(5);
    this.z7w_1 = _AGDrawType___init__impl__emm6mb(6);
  }
  var Companion_instance_30;
  function Companion_getInstance_46() {
    if (Companion_instance_30 == null)
      new Companion_30();
    return Companion_instance_30;
  }
  function AGDrawType__toString_impl_eonaeb($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_46().t7w_1 ? 'POINTS' : tmp0_subject === Companion_getInstance_46().u7w_1 ? 'LINE_STRIP' : tmp0_subject === Companion_getInstance_46().v7w_1 ? 'LINE_LOOP' : tmp0_subject === Companion_getInstance_46().w7w_1 ? 'LINES' : tmp0_subject === Companion_getInstance_46().x7w_1 ? 'TRIANGLES' : tmp0_subject === Companion_getInstance_46().y7w_1 ? 'TRIANGLE_STRIP' : tmp0_subject === Companion_getInstance_46().z7w_1 ? 'TRIANGLE_FAN' : '-';
  }
  function AGDrawType__hashCode_impl_ylaury($this) {
    return $this;
  }
  function AGDrawType__equals_impl_rtafo6($this, other) {
    if (!(other instanceof AGDrawType))
      return false;
    var tmp0_other_with_cast = other instanceof AGDrawType ? other.e7x_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGDrawType(ordinal) {
    Companion_getInstance_46();
    this.e7x_1 = ordinal;
  }
  protoOf(AGDrawType).toString = function () {
    return AGDrawType__toString_impl_eonaeb(this.e7x_1);
  };
  protoOf(AGDrawType).hashCode = function () {
    return AGDrawType__hashCode_impl_ylaury(this.e7x_1);
  };
  protoOf(AGDrawType).equals = function (other) {
    return AGDrawType__equals_impl_rtafo6(this.e7x_1, other);
  };
  function _AGBlending___init__impl__upt3bq(data) {
    return data;
  }
  function _AGBlending___get_data__impl__7m3zd1($this) {
    return $this;
  }
  function _AGBlending___get_srcRGB__impl__jvh03e($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract4' call
    var tmp0_extract4 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract4 >>> 0 | 0) & 15;
    return _AGBlendFactor___init__impl__iqtmxr(tmp$ret$0);
  }
  function _AGBlending___get_srcA__impl__ftmwxe($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract4' call
    var tmp0_extract4 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract4 >>> 4 | 0) & 15;
    return _AGBlendFactor___init__impl__iqtmxr(tmp$ret$0);
  }
  function _AGBlending___get_dstRGB__impl__nz9ogp($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract4' call
    var tmp0_extract4 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract4 >>> 8 | 0) & 15;
    return _AGBlendFactor___init__impl__iqtmxr(tmp$ret$0);
  }
  function _AGBlending___get_dstA__impl__l7q6tv($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract4' call
    var tmp0_extract4 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract4 >>> 12 | 0) & 15;
    return _AGBlendFactor___init__impl__iqtmxr(tmp$ret$0);
  }
  function _AGBlending___get_eqRGB__impl__7pv8oi($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract2 >>> 16 | 0) & 3;
    return _AGBlendEquation___init__impl__fpv7c2(tmp$ret$0);
  }
  function _AGBlending___get_eqA__impl__2j4ray($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _AGBlending___get_data__impl__7m3zd1($this);
    tmp$ret$0 = (tmp0_extract2 >>> 18 | 0) & 3;
    return _AGBlendEquation___init__impl__fpv7c2(tmp$ret$0);
  }
  function AGBlending__withSRC_impl_hh0mjq($this, rgb, a) {
    return _AGBlending___init__impl__upt3bq(insert4(insert4(_AGBlending___get_data__impl__7m3zd1($this), _AGBlendFactor___get_ordinal__impl__r9efzn(rgb), 0), _AGBlendFactor___get_ordinal__impl__r9efzn(a), 4));
  }
  function AGBlending__withSRC$default_impl_uuu5hz($this, rgb, a, $super) {
    a = a === VOID ? rgb : a;
    var tmp;
    if ($super === VOID) {
      tmp = AGBlending__withSRC_impl_hh0mjq($this, rgb, a);
    } else {
      var tmp_0 = new AGBlending($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGBlending(tmp_1)).g7x.call(tmp_0, new AGBlendFactor(rgb), new AGBlendFactor(a)).f7x_1;
    }
    return tmp;
  }
  function AGBlending__withDST_impl_y1hpet($this, rgb, a) {
    return _AGBlending___init__impl__upt3bq(insert4(insert4(_AGBlending___get_data__impl__7m3zd1($this), _AGBlendFactor___get_ordinal__impl__r9efzn(rgb), 8), _AGBlendFactor___get_ordinal__impl__r9efzn(a), 12));
  }
  function AGBlending__withDST$default_impl_leu6qg($this, rgb, a, $super) {
    a = a === VOID ? rgb : a;
    var tmp;
    if ($super === VOID) {
      tmp = AGBlending__withDST_impl_y1hpet($this, rgb, a);
    } else {
      var tmp_0 = new AGBlending($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGBlending(tmp_1)).h7x.call(tmp_0, new AGBlendFactor(rgb), new AGBlendFactor(a)).f7x_1;
    }
    return tmp;
  }
  function AGBlending__withEQ_impl_e9gm04($this, rgb, a) {
    return _AGBlending___init__impl__upt3bq(insert2(insert2(_AGBlending___get_data__impl__7m3zd1($this), _AGBlendEquation___get_ordinal__impl__895p3q(rgb), 16), _AGBlendEquation___get_ordinal__impl__895p3q(a), 18));
  }
  function AGBlending__withEQ$default_impl_iltiw7($this, rgb, a, $super) {
    a = a === VOID ? rgb : a;
    var tmp;
    if ($super === VOID) {
      tmp = AGBlending__withEQ_impl_e9gm04($this, rgb, a);
    } else {
      var tmp_0 = new AGBlending($this);
      var tmp_1 = $super;
      tmp = (tmp_1 == null ? null : new AGBlending(tmp_1)).i7x.call(tmp_0, new AGBlendEquation(rgb), new AGBlendEquation(a)).f7x_1;
    }
    return tmp;
  }
  function _AGBlending___get_enabled__impl__s7igz6($this) {
    return !($this === Companion_getInstance_47().e7m_1);
  }
  function AGBlending__toString_impl_d5hic6($this) {
    return 'Blending(outRGB = (srcRGB * ' + _AGBlendFactor___get_op__impl__b5pfnd(_AGBlending___get_srcRGB__impl__jvh03e($this)) + ') ' + _AGBlendEquation___get_op__impl__7f9os6(_AGBlending___get_eqRGB__impl__7pv8oi($this)) + ' (dstRGB * ' + _AGBlendFactor___get_op__impl__b5pfnd(_AGBlending___get_dstRGB__impl__nz9ogp($this)) + '), outA = (srcA * ' + _AGBlendFactor___get_op__impl__b5pfnd(_AGBlending___get_srcA__impl__ftmwxe($this)) + ') ' + _AGBlendEquation___get_op__impl__7f9os6(_AGBlending___get_eqA__impl__2j4ray($this)) + ' (dstA * ' + _AGBlendFactor___get_op__impl__b5pfnd(_AGBlending___get_dstA__impl__l7q6tv($this)) + '))';
  }
  function Companion_31() {
    Companion_instance_31 = this;
    this.d7m_1 = _AGBlending___init__impl__upt3bq(-1);
    this.e7m_1 = Companion_getInstance_47().t7x(Companion_getInstance_48().l7x_1, Companion_getInstance_48().s7x_1, Companion_getInstance_48().l7x_1, Companion_getInstance_48().s7x_1);
    this.f7m_1 = Companion_getInstance_47().t7x(Companion_getInstance_48().q7x_1, Companion_getInstance_48().o7x_1, Companion_getInstance_48().l7x_1, Companion_getInstance_48().o7x_1);
    this.g7m_1 = Companion_getInstance_47().u7x(Companion_getInstance_48().l7x_1, Companion_getInstance_48().o7x_1);
    this.h7m_1 = Companion_getInstance_47().t7x(Companion_getInstance_48().q7x_1, Companion_getInstance_48().j7x_1, Companion_getInstance_48().l7x_1, Companion_getInstance_48().l7x_1);
    this.i7m_1 = Companion_getInstance_47().t7x(Companion_getInstance_48().l7x_1, Companion_getInstance_48().l7x_1, Companion_getInstance_48().l7x_1, Companion_getInstance_48().l7x_1);
  }
  protoOf(Companion_31).v7x = function (srcRGB, dstRGB, srcA, dstA, eqRGB, eqA) {
    return AGBlending__withEQ_impl_e9gm04(AGBlending__withDST_impl_y1hpet(AGBlending__withSRC_impl_hh0mjq(_AGBlending___init__impl__upt3bq(0), srcRGB, srcA), dstRGB, dstA), eqRGB, eqA);
  };
  protoOf(Companion_31).t7x = function (srcRGB, dstRGB, srcA, dstA, eqRGB, eqA, $super) {
    srcA = srcA === VOID ? srcRGB : srcA;
    dstA = dstA === VOID ? dstRGB : dstA;
    eqRGB = eqRGB === VOID ? Companion_getInstance_49().w7x_1 : eqRGB;
    eqA = eqA === VOID ? eqRGB : eqA;
    return $super === VOID ? this.v7x(srcRGB, dstRGB, srcA, dstA, eqRGB, eqA) : $super.v7x.call(this, new AGBlendFactor(srcRGB), new AGBlendFactor(dstRGB), new AGBlendFactor(srcA), new AGBlendFactor(dstA), new AGBlendEquation(eqRGB), new AGBlendEquation(eqA)).f7x_1;
  };
  protoOf(Companion_31).z7x = function (src, dst, eq) {
    return AGBlending__withEQ$default_impl_iltiw7(AGBlending__withDST$default_impl_leu6qg(AGBlending__withSRC$default_impl_uuu5hz(_AGBlending___init__impl__upt3bq(0), src), dst), eq);
  };
  protoOf(Companion_31).u7x = function (src, dst, eq, $super) {
    eq = eq === VOID ? Companion_getInstance_49().w7x_1 : eq;
    return $super === VOID ? this.z7x(src, dst, eq) : $super.z7x.call(this, new AGBlendFactor(src), new AGBlendFactor(dst), new AGBlendEquation(eq)).f7x_1;
  };
  var Companion_instance_31;
  function Companion_getInstance_47() {
    if (Companion_instance_31 == null)
      new Companion_31();
    return Companion_instance_31;
  }
  function AGBlending__hashCode_impl_ywnf51($this) {
    return $this;
  }
  function AGBlending__equals_impl_rp3dmf($this, other) {
    if (!(other instanceof AGBlending))
      return false;
    var tmp0_other_with_cast = other instanceof AGBlending ? other.f7x_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGBlending(data) {
    Companion_getInstance_47();
    this.f7x_1 = data;
  }
  protoOf(AGBlending).toString = function () {
    return AGBlending__toString_impl_d5hic6(this.f7x_1);
  };
  protoOf(AGBlending).hashCode = function () {
    return AGBlending__hashCode_impl_ywnf51(this.f7x_1);
  };
  protoOf(AGBlending).equals = function (other) {
    return AGBlending__equals_impl_rp3dmf(this.f7x_1, other);
  };
  function AGMultiBatch(list) {
    this.x7l_1 = list;
  }
  protoOf(AGMultiBatch).y7l = function (ag) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.x7l_1;
    var n = 0;
    while (n < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.graphics.AGMultiBatch.execute.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0);
      tmp1__anonymous__uwfjfc.y7l(ag);
    }
  };
  protoOf(AGMultiBatch).toString = function () {
    return 'AGMultiBatch(list=' + this.x7l_1 + ')';
  };
  protoOf(AGMultiBatch).hashCode = function () {
    return hashCode(this.x7l_1);
  };
  protoOf(AGMultiBatch).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGMultiBatch))
      return false;
    var tmp0_other_with_cast = other instanceof AGMultiBatch ? other : THROW_CCE();
    if (!equals(this.x7l_1, tmp0_other_with_cast.x7l_1))
      return false;
    return true;
  };
  function _AGBlendFactor___init__impl__iqtmxr(ordinal) {
    return ordinal;
  }
  function _AGBlendFactor___get_ordinal__impl__r9efzn($this) {
    return $this;
  }
  function Companion_32() {
    Companion_instance_32 = this;
    this.j7x_1 = _AGBlendFactor___init__impl__iqtmxr(0);
    this.k7x_1 = _AGBlendFactor___init__impl__iqtmxr(1);
    this.l7x_1 = _AGBlendFactor___init__impl__iqtmxr(2);
    this.m7x_1 = _AGBlendFactor___init__impl__iqtmxr(3);
    this.n7x_1 = _AGBlendFactor___init__impl__iqtmxr(4);
    this.o7x_1 = _AGBlendFactor___init__impl__iqtmxr(5);
    this.p7x_1 = _AGBlendFactor___init__impl__iqtmxr(6);
    this.q7x_1 = _AGBlendFactor___init__impl__iqtmxr(7);
    this.r7x_1 = _AGBlendFactor___init__impl__iqtmxr(8);
    this.s7x_1 = _AGBlendFactor___init__impl__iqtmxr(9);
  }
  var Companion_instance_32;
  function Companion_getInstance_48() {
    if (Companion_instance_32 == null)
      new Companion_32();
    return Companion_instance_32;
  }
  function AGBlendFactor__toString_impl_1hr6m1($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_48().j7x_1 ? 'DESTINATION_ALPHA' : tmp0_subject === Companion_getInstance_48().k7x_1 ? 'DESTINATION_COLOR' : tmp0_subject === Companion_getInstance_48().l7x_1 ? 'ONE' : tmp0_subject === Companion_getInstance_48().m7x_1 ? 'ONE_MINUS_DESTINATION_ALPHA' : tmp0_subject === Companion_getInstance_48().n7x_1 ? 'ONE_MINUS_DESTINATION_COLOR' : tmp0_subject === Companion_getInstance_48().o7x_1 ? 'ONE_MINUS_SOURCE_ALPHA' : tmp0_subject === Companion_getInstance_48().p7x_1 ? 'ONE_MINUS_SOURCE_COLOR' : tmp0_subject === Companion_getInstance_48().q7x_1 ? 'SOURCE_ALPHA' : tmp0_subject === Companion_getInstance_48().r7x_1 ? 'SOURCE_COLOR' : tmp0_subject === Companion_getInstance_48().s7x_1 ? 'ZERO' : '-';
  }
  function _AGBlendFactor___get_op__impl__b5pfnd($this) {
    var tmp0_subject = $this;
    var tmp;
    if (tmp0_subject === Companion_getInstance_48().j7x_1) {
      tmp = 'dstA';
    } else if (tmp0_subject === Companion_getInstance_48().k7x_1) {
      tmp = 'dstRGB';
    } else if (tmp0_subject === Companion_getInstance_48().l7x_1) {
      tmp = '1';
    } else if (tmp0_subject === Companion_getInstance_48().m7x_1) {
      tmp = '(1 - dstA)';
    } else if (tmp0_subject === Companion_getInstance_48().n7x_1) {
      tmp = '(1 - dstRGB)';
    } else if (tmp0_subject === Companion_getInstance_48().o7x_1) {
      tmp = '(1 - srcA)';
    } else if (tmp0_subject === Companion_getInstance_48().p7x_1) {
      tmp = '(1 - srcRGB)';
    } else if (tmp0_subject === Companion_getInstance_48().q7x_1) {
      tmp = 'srcA';
    } else if (tmp0_subject === Companion_getInstance_48().r7x_1) {
      tmp = 'srcRGB';
    } else if (tmp0_subject === Companion_getInstance_48().s7x_1) {
      tmp = '0';
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function AGBlendFactor__hashCode_impl_n8x3ew($this) {
    return $this;
  }
  function AGBlendFactor__equals_impl_dlauac($this, other) {
    if (!(other instanceof AGBlendFactor))
      return false;
    var tmp0_other_with_cast = other instanceof AGBlendFactor ? other.a7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGBlendFactor(ordinal) {
    Companion_getInstance_48();
    this.a7y_1 = ordinal;
  }
  protoOf(AGBlendFactor).toString = function () {
    return AGBlendFactor__toString_impl_1hr6m1(this.a7y_1);
  };
  protoOf(AGBlendFactor).hashCode = function () {
    return AGBlendFactor__hashCode_impl_n8x3ew(this.a7y_1);
  };
  protoOf(AGBlendFactor).equals = function (other) {
    return AGBlendFactor__equals_impl_dlauac(this.a7y_1, other);
  };
  function _AGBlendEquation___init__impl__fpv7c2(ordinal) {
    return ordinal;
  }
  function _AGBlendEquation___get_ordinal__impl__895p3q($this) {
    return $this;
  }
  function Companion_33() {
    Companion_instance_33 = this;
    this.w7x_1 = _AGBlendEquation___init__impl__fpv7c2(0);
    this.x7x_1 = _AGBlendEquation___init__impl__fpv7c2(1);
    this.y7x_1 = _AGBlendEquation___init__impl__fpv7c2(2);
  }
  var Companion_instance_33;
  function Companion_getInstance_49() {
    if (Companion_instance_33 == null)
      new Companion_33();
    return Companion_instance_33;
  }
  function AGBlendEquation__toString_impl_5t2sx6($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_49().w7x_1 ? 'ADD' : tmp0_subject === Companion_getInstance_49().x7x_1 ? 'SUBTRACT' : tmp0_subject === Companion_getInstance_49().y7x_1 ? 'REVERSE_SUBTRACT' : '-';
  }
  function _AGBlendEquation___get_op__impl__7f9os6($this) {
    var tmp0_subject = $this;
    var tmp;
    if (tmp0_subject === Companion_getInstance_49().w7x_1) {
      tmp = '+';
    } else if (tmp0_subject === Companion_getInstance_49().x7x_1) {
      tmp = '-';
    } else if (tmp0_subject === Companion_getInstance_49().y7x_1) {
      tmp = 'r-';
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function AGBlendEquation__hashCode_impl_fy33vp($this) {
    return $this;
  }
  function AGBlendEquation__equals_impl_yykk3b($this, other) {
    if (!(other instanceof AGBlendEquation))
      return false;
    var tmp0_other_with_cast = other instanceof AGBlendEquation ? other.b7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGBlendEquation(ordinal) {
    Companion_getInstance_49();
    this.b7y_1 = ordinal;
  }
  protoOf(AGBlendEquation).toString = function () {
    return AGBlendEquation__toString_impl_5t2sx6(this.b7y_1);
  };
  protoOf(AGBlendEquation).hashCode = function () {
    return AGBlendEquation__hashCode_impl_fy33vp(this.b7y_1);
  };
  protoOf(AGBlendEquation).equals = function (other) {
    return AGBlendEquation__equals_impl_yykk3b(this.b7y_1, other);
  };
  function _AGTextureTargetKind___init__impl__4zdmch(ordinal) {
    return ordinal;
  }
  function _AGTextureTargetKind___get_ordinal__impl__gjzc5p($this) {
    return $this;
  }
  function AGTextureTargetKind__toString_impl_68648p($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_50().w7p_1 ? 'TEXTURE_2D' : tmp0_subject === Companion_getInstance_50().x7p_1 ? 'TEXTURE_3D' : tmp0_subject === Companion_getInstance_50().y7p_1 ? 'TEXTURE_CUBE_MAP' : tmp0_subject === Companion_getInstance_50().z7p_1 ? 'EXTERNAL_TEXTURE' : 'AGTextureTargetKind(' + _AGTextureTargetKind___get_ordinal__impl__gjzc5p($this) + ')';
  }
  function _AGTextureTargetKind___get_dims__impl__k3cmhd($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_50().w7p_1 ? 2 : tmp0_subject === Companion_getInstance_50().x7p_1 ? 3 : tmp0_subject === Companion_getInstance_50().y7p_1 ? 3 : tmp0_subject === Companion_getInstance_50().z7p_1 ? 3 : 0;
  }
  function Companion_34() {
    Companion_instance_34 = this;
    this.w7p_1 = _AGTextureTargetKind___init__impl__4zdmch(0);
    this.x7p_1 = _AGTextureTargetKind___init__impl__4zdmch(1);
    this.y7p_1 = _AGTextureTargetKind___init__impl__4zdmch(2);
    this.z7p_1 = _AGTextureTargetKind___init__impl__4zdmch(3);
  }
  var Companion_instance_34;
  function Companion_getInstance_50() {
    if (Companion_instance_34 == null)
      new Companion_34();
    return Companion_instance_34;
  }
  function AGTextureTargetKind__hashCode_impl_rzc11k($this) {
    return $this;
  }
  function AGTextureTargetKind__equals_impl_q2tams($this, other) {
    if (!(other instanceof AGTextureTargetKind))
      return false;
    var tmp0_other_with_cast = other instanceof AGTextureTargetKind ? other.c7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGTextureTargetKind(ordinal) {
    Companion_getInstance_50();
    this.c7y_1 = ordinal;
  }
  protoOf(AGTextureTargetKind).toString = function () {
    return AGTextureTargetKind__toString_impl_68648p(this.c7y_1);
  };
  protoOf(AGTextureTargetKind).hashCode = function () {
    return AGTextureTargetKind__hashCode_impl_rzc11k(this.c7y_1);
  };
  protoOf(AGTextureTargetKind).equals = function (other) {
    return AGTextureTargetKind__equals_impl_q2tams(this.c7y_1, other);
  };
  function toAGScissor(_this__u8e3s4) {
    if (_this__u8e3s4 == null)
      return Companion_getInstance_44().b7n_1;
    return _AGScissor___init__impl__g8s683_0(numberToInt(_this__u8e3s4.z3r_1), numberToInt(_this__u8e3s4.a3s_1), numberToInt(_this__u8e3s4.b3s_1), numberToInt(_this__u8e3s4.c3s_1));
  }
  function plus_5(_this__u8e3s4, scissor) {
    return BoundsBuilder__plus_impl_cz7f7x(BoundsBuilder__plus_impl_cz7f7x(_this__u8e3s4, Vector2_init_$Create$(_AGScissor___get_left__impl__jiavfz(scissor), _AGScissor___get_top__impl__wmf8dv(scissor))), Vector2_init_$Create$(_AGScissor___get_right__impl__3hpzkc(scissor), _AGScissor___get_bottom__impl__6wmhqj(scissor)));
  }
  function _AGCompareMode___init__impl__j8kqbb(ordinal) {
    return ordinal;
  }
  function _AGCompareMode___get_ordinal__impl__ikvlmz($this) {
    return $this;
  }
  function Companion_35() {
    Companion_instance_35 = this;
    this.y7u_1 = _AGCompareMode___init__impl__j8kqbb(0);
    this.z7u_1 = _AGCompareMode___init__impl__j8kqbb(1);
    this.a7v_1 = _AGCompareMode___init__impl__j8kqbb(2);
    this.b7v_1 = _AGCompareMode___init__impl__j8kqbb(3);
    this.c7v_1 = _AGCompareMode___init__impl__j8kqbb(4);
    this.d7v_1 = _AGCompareMode___init__impl__j8kqbb(5);
    this.e7v_1 = _AGCompareMode___init__impl__j8kqbb(6);
    this.f7v_1 = _AGCompareMode___init__impl__j8kqbb(7);
  }
  var Companion_instance_35;
  function Companion_getInstance_51() {
    if (Companion_instance_35 == null)
      new Companion_35();
    return Companion_instance_35;
  }
  function AGCompareMode__toString_impl_gs33a9($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_51().y7u_1 ? 'ALWAYS' : tmp0_subject === Companion_getInstance_51().z7u_1 ? 'EQUAL' : tmp0_subject === Companion_getInstance_51().a7v_1 ? 'GREATER' : tmp0_subject === Companion_getInstance_51().b7v_1 ? 'GREATER_EQUAL' : tmp0_subject === Companion_getInstance_51().c7v_1 ? 'LESS' : tmp0_subject === Companion_getInstance_51().d7v_1 ? 'LESS_EQUAL' : tmp0_subject === Companion_getInstance_51().e7v_1 ? 'NEVER' : tmp0_subject === Companion_getInstance_51().f7v_1 ? 'NOT_EQUAL' : '-';
  }
  function AGCompareMode__inverted_impl_7xxk8m($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_51().y7u_1 ? Companion_getInstance_51().e7v_1 : tmp0_subject === Companion_getInstance_51().z7u_1 ? Companion_getInstance_51().f7v_1 : tmp0_subject === Companion_getInstance_51().a7v_1 ? Companion_getInstance_51().d7v_1 : tmp0_subject === Companion_getInstance_51().b7v_1 ? Companion_getInstance_51().c7v_1 : tmp0_subject === Companion_getInstance_51().c7v_1 ? Companion_getInstance_51().b7v_1 : tmp0_subject === Companion_getInstance_51().d7v_1 ? Companion_getInstance_51().a7v_1 : tmp0_subject === Companion_getInstance_51().e7v_1 ? Companion_getInstance_51().y7u_1 : tmp0_subject === Companion_getInstance_51().f7v_1 ? Companion_getInstance_51().z7u_1 : Companion_getInstance_51().e7v_1;
  }
  function AGCompareMode__hashCode_impl_whv1w0($this) {
    return $this;
  }
  function AGCompareMode__equals_impl_rbniy4($this, other) {
    if (!(other instanceof AGCompareMode))
      return false;
    var tmp0_other_with_cast = other instanceof AGCompareMode ? other.d7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGCompareMode(ordinal) {
    Companion_getInstance_51();
    this.d7y_1 = ordinal;
  }
  protoOf(AGCompareMode).toString = function () {
    return AGCompareMode__toString_impl_gs33a9(this.d7y_1);
  };
  protoOf(AGCompareMode).hashCode = function () {
    return AGCompareMode__hashCode_impl_whv1w0(this.d7y_1);
  };
  protoOf(AGCompareMode).equals = function (other) {
    return AGCompareMode__equals_impl_rbniy4(this.d7y_1, other);
  };
  function _AGStencilOp___init__impl__llpkv6(ordinal) {
    return ordinal;
  }
  function _AGStencilOp___get_ordinal__impl__xkavfu($this) {
    return $this;
  }
  function Companion_36() {
    Companion_instance_36 = this;
    this.g7v_1 = _AGStencilOp___init__impl__llpkv6(0);
    this.h7v_1 = _AGStencilOp___init__impl__llpkv6(1);
    this.i7v_1 = _AGStencilOp___init__impl__llpkv6(2);
    this.j7v_1 = _AGStencilOp___init__impl__llpkv6(3);
    this.k7v_1 = _AGStencilOp___init__impl__llpkv6(4);
    this.l7v_1 = _AGStencilOp___init__impl__llpkv6(5);
    this.m7v_1 = _AGStencilOp___init__impl__llpkv6(6);
    this.n7v_1 = _AGStencilOp___init__impl__llpkv6(7);
  }
  var Companion_instance_36;
  function Companion_getInstance_52() {
    if (Companion_instance_36 == null)
      new Companion_36();
    return Companion_instance_36;
  }
  function AGStencilOp__toString_impl_1dm6fq($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_52().g7v_1 ? 'DECREMENT_SATURATE' : tmp0_subject === Companion_getInstance_52().h7v_1 ? 'DECREMENT_WRAP' : tmp0_subject === Companion_getInstance_52().i7v_1 ? 'INCREMENT_SATURATE' : tmp0_subject === Companion_getInstance_52().j7v_1 ? 'INCREMENT_WRAP' : tmp0_subject === Companion_getInstance_52().k7v_1 ? 'INVERT' : tmp0_subject === Companion_getInstance_52().l7v_1 ? 'KEEP' : tmp0_subject === Companion_getInstance_52().m7v_1 ? 'SET' : tmp0_subject === Companion_getInstance_52().n7v_1 ? 'ZERO' : '-';
  }
  function AGStencilOp__hashCode_impl_n4s38l($this) {
    return $this;
  }
  function AGStencilOp__equals_impl_rq4p55($this, other) {
    if (!(other instanceof AGStencilOp))
      return false;
    var tmp0_other_with_cast = other instanceof AGStencilOp ? other.e7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGStencilOp(ordinal) {
    Companion_getInstance_52();
    this.e7y_1 = ordinal;
  }
  protoOf(AGStencilOp).toString = function () {
    return AGStencilOp__toString_impl_1dm6fq(this.e7y_1);
  };
  protoOf(AGStencilOp).hashCode = function () {
    return AGStencilOp__hashCode_impl_n4s38l(this.e7y_1);
  };
  protoOf(AGStencilOp).equals = function (other) {
    return AGStencilOp__equals_impl_rq4p55(this.e7y_1, other);
  };
  function _AGCullFace___init__impl__ckmjdg(ordinal) {
    return ordinal;
  }
  function _AGCullFace___get_ordinal__impl__jd1wps($this) {
    return $this;
  }
  function Companion_37() {
    Companion_instance_37 = this;
    this.c7n_1 = _AGCullFace___init__impl__ckmjdg(-1);
    this.d7n_1 = _AGCullFace___init__impl__ckmjdg(0);
    this.e7n_1 = _AGCullFace___init__impl__ckmjdg(1);
    this.f7n_1 = _AGCullFace___init__impl__ckmjdg(2);
    this.g7n_1 = _AGCullFace___init__impl__ckmjdg(3);
  }
  var Companion_instance_37;
  function Companion_getInstance_53() {
    if (Companion_instance_37 == null)
      new Companion_37();
    return Companion_instance_37;
  }
  function AGCullFace__toString_impl_7d891w($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_53().d7n_1 ? 'NONE' : tmp0_subject === Companion_getInstance_53().g7n_1 ? 'BOTH' : tmp0_subject === Companion_getInstance_53().e7n_1 ? 'FRONT' : tmp0_subject === Companion_getInstance_53().f7n_1 ? 'BACK' : '-';
  }
  function AGCullFace__hashCode_impl_t4e5ur($this) {
    return $this;
  }
  function AGCullFace__equals_impl_wgngjr($this, other) {
    if (!(other instanceof AGCullFace))
      return false;
    var tmp0_other_with_cast = other instanceof AGCullFace ? other.f7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGCullFace(ordinal) {
    Companion_getInstance_53();
    this.f7y_1 = ordinal;
  }
  protoOf(AGCullFace).toString = function () {
    return AGCullFace__toString_impl_7d891w(this.f7y_1);
  };
  protoOf(AGCullFace).hashCode = function () {
    return AGCullFace__hashCode_impl_t4e5ur(this.f7y_1);
  };
  protoOf(AGCullFace).equals = function (other) {
    return AGCullFace__equals_impl_wgngjr(this.f7y_1, other);
  };
  function applyMatrixBounds(_this__u8e3s4, m) {
    var x0 = Matrix__transformX_impl_adb2o0(m, _AGScissor___get_left__impl__jiavfz(_this__u8e3s4), _AGScissor___get_top__impl__wmf8dv(_this__u8e3s4));
    var x1 = Matrix__transformX_impl_adb2o0(m, _AGScissor___get_right__impl__3hpzkc(_this__u8e3s4), _AGScissor___get_top__impl__wmf8dv(_this__u8e3s4));
    var x2 = Matrix__transformX_impl_adb2o0(m, _AGScissor___get_left__impl__jiavfz(_this__u8e3s4), _AGScissor___get_bottom__impl__6wmhqj(_this__u8e3s4));
    var x3 = Matrix__transformX_impl_adb2o0(m, _AGScissor___get_right__impl__3hpzkc(_this__u8e3s4), _AGScissor___get_bottom__impl__6wmhqj(_this__u8e3s4));
    var y0 = Matrix__transformY_impl_aucp27(m, _AGScissor___get_left__impl__jiavfz(_this__u8e3s4), _AGScissor___get_top__impl__wmf8dv(_this__u8e3s4));
    var y1 = Matrix__transformY_impl_aucp27(m, _AGScissor___get_right__impl__3hpzkc(_this__u8e3s4), _AGScissor___get_top__impl__wmf8dv(_this__u8e3s4));
    var y2 = Matrix__transformY_impl_aucp27(m, _AGScissor___get_left__impl__jiavfz(_this__u8e3s4), _AGScissor___get_bottom__impl__6wmhqj(_this__u8e3s4));
    var y3 = Matrix__transformY_impl_aucp27(m, _AGScissor___get_right__impl__3hpzkc(_this__u8e3s4), _AGScissor___get_bottom__impl__6wmhqj(_this__u8e3s4));
    return Companion_getInstance_44().t7v(numberToInt(min(x0, x1, x2, x3)), numberToInt(min(y0, y1, y2, y3)), numberToInt(max(x0, x1, x2, x3)), numberToInt(max(y0, y1, y2, y3)));
  }
  function AGFinish() {
    AGFinish_instance = this;
  }
  var AGFinish_instance;
  function AGFinish_getInstance() {
    if (AGFinish_instance == null)
      new AGFinish();
    return AGFinish_instance;
  }
  function AGClear(frameBuffer, frameBufferInfo, color, depth, stencil, clearColor, clearDepth, clearStencil) {
    this.g7y_1 = frameBuffer;
    this.h7y_1 = frameBufferInfo;
    this.i7y_1 = color;
    this.j7y_1 = depth;
    this.k7y_1 = stencil;
    this.l7y_1 = clearColor;
    this.m7y_1 = clearDepth;
    this.n7y_1 = clearStencil;
  }
  protoOf(AGClear).toString = function () {
    return 'AGClear(frameBuffer=' + this.g7y_1 + ', frameBufferInfo=' + new AGFrameBufferInfo(this.h7y_1) + ', color=' + new RGBA(this.i7y_1) + ', depth=' + this.j7y_1 + ', stencil=' + this.k7y_1 + ', clearColor=' + this.l7y_1 + ', clearDepth=' + this.m7y_1 + ', clearStencil=' + this.n7y_1 + ')';
  };
  protoOf(AGClear).hashCode = function () {
    var result = hashCode(this.g7y_1);
    result = imul(result, 31) + AGFrameBufferInfo__hashCode_impl_fvl6f1(this.h7y_1) | 0;
    result = imul(result, 31) + RGBA__hashCode_impl_h59qf6(this.i7y_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.j7y_1) | 0;
    result = imul(result, 31) + this.k7y_1 | 0;
    result = imul(result, 31) + (this.l7y_1 | 0) | 0;
    result = imul(result, 31) + (this.m7y_1 | 0) | 0;
    result = imul(result, 31) + (this.n7y_1 | 0) | 0;
    return result;
  };
  protoOf(AGClear).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGClear))
      return false;
    var tmp0_other_with_cast = other instanceof AGClear ? other : THROW_CCE();
    if (!equals(this.g7y_1, tmp0_other_with_cast.g7y_1))
      return false;
    if (!(this.h7y_1 === tmp0_other_with_cast.h7y_1))
      return false;
    if (!(this.i7y_1 === tmp0_other_with_cast.i7y_1))
      return false;
    if (!equals(this.j7y_1, tmp0_other_with_cast.j7y_1))
      return false;
    if (!(this.k7y_1 === tmp0_other_with_cast.k7y_1))
      return false;
    if (!(this.l7y_1 === tmp0_other_with_cast.l7y_1))
      return false;
    if (!(this.m7y_1 === tmp0_other_with_cast.m7y_1))
      return false;
    if (!(this.n7y_1 === tmp0_other_with_cast.n7y_1))
      return false;
    return true;
  };
  function _AGIndexType___init__impl__yqnmb7(ordinal) {
    return ordinal;
  }
  function Companion_38() {
    Companion_instance_38 = this;
    this.z7l_1 = _AGIndexType___init__impl__yqnmb7(0);
    this.a7m_1 = _AGIndexType___init__impl__yqnmb7(1);
    this.b7m_1 = _AGIndexType___init__impl__yqnmb7(2);
    this.c7m_1 = _AGIndexType___init__impl__yqnmb7(3);
  }
  var Companion_instance_38;
  function Companion_getInstance_54() {
    if (Companion_instance_38 == null)
      new Companion_38();
    return Companion_instance_38;
  }
  function AGIndexType__toString_impl_hjqwm3($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_54().z7l_1 ? 'null' : tmp0_subject === Companion_getInstance_54().a7m_1 ? 'UBYTE' : tmp0_subject === Companion_getInstance_54().b7m_1 ? 'USHORT' : tmp0_subject === Companion_getInstance_54().c7m_1 ? 'UINT' : '-';
  }
  function AGIndexType__hashCode_impl_47f06s($this) {
    return $this;
  }
  function AGIndexType__equals_impl_goy6ag($this, other) {
    if (!(other instanceof AGIndexType))
      return false;
    var tmp0_other_with_cast = other instanceof AGIndexType ? other.o7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGIndexType(ordinal) {
    Companion_getInstance_54();
    this.o7y_1 = ordinal;
  }
  protoOf(AGIndexType).toString = function () {
    return AGIndexType__toString_impl_hjqwm3(this.o7y_1);
  };
  protoOf(AGIndexType).hashCode = function () {
    return AGIndexType__hashCode_impl_47f06s(this.o7y_1);
  };
  protoOf(AGIndexType).equals = function (other) {
    return AGIndexType__equals_impl_goy6ag(this.o7y_1, other);
  };
  function _AGDepthAndFrontFace___init__impl__ra8x65(data) {
    return data;
  }
  function _AGDepthAndFrontFace___get_data__impl__v8zkce($this) {
    return $this;
  }
  function Companion_39() {
    Companion_instance_39 = this;
    this.w7m_1 = _AGDepthAndFrontFace___init__impl__ra8x65(-2);
    this.x7m_1 = AGDepthAndFrontFace__withFrontFace_impl_mwk87t(AGDepthAndFrontFace__withDepthFunc_impl_7t7yrs(AGDepthAndFrontFace__withDepthMask_impl_610nhs(AGDepthAndFrontFace__withDepth_impl_wsx82k(_AGDepthAndFrontFace___init__impl__ra8x65(0), 0.0, 1.0), true), Companion_getInstance_51().y7u_1), Companion_getInstance_59().p7y_1);
  }
  var Companion_instance_39;
  function Companion_getInstance_55() {
    if (Companion_instance_39 == null)
      new Companion_39();
    return Companion_instance_39;
  }
  function _AGDepthAndFrontFace___get_depthNear__impl__cir3a1($this) {
    return extractScaledf01(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), 0, 12);
  }
  function _AGDepthAndFrontFace___get_depthFar__impl__g0cno8($this) {
    return extractScaledf01(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), 12, 12);
  }
  function _AGDepthAndFrontFace___get_depthMask__impl__ouijn($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _AGDepthAndFrontFace___get_data__impl__v8zkce($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 24 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _AGDepthAndFrontFace___get_depthFunc__impl__8fo7zp($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract3' call
    var tmp0_extract3 = _AGDepthAndFrontFace___get_data__impl__v8zkce($this);
    tmp$ret$0 = (tmp0_extract3 >>> 26 | 0) & 7;
    return _AGCompareMode___init__impl__j8kqbb(tmp$ret$0);
  }
  function _AGDepthAndFrontFace___get_frontFace__impl__w07k4s($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract2' call
    var tmp0_extract2 = _AGDepthAndFrontFace___get_data__impl__v8zkce($this);
    tmp$ret$0 = (tmp0_extract2 >>> 30 | 0) & 3;
    return _AGFrontFace___init__impl__vsm7rb(tmp$ret$0);
  }
  function AGDepthAndFrontFace__withDepth_impl_wsx82k($this, near, far) {
    return _AGDepthAndFrontFace___init__impl__ra8x65(insertScaledf01(insertScaledf01(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), near, 0, 12), far, 12, 12));
  }
  function AGDepthAndFrontFace__withDepthMask_impl_610nhs($this, depthMask) {
    return _AGDepthAndFrontFace___init__impl__ra8x65(insert(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), depthMask, 24));
  }
  function AGDepthAndFrontFace__withDepthFunc_impl_7t7yrs($this, depthFunc) {
    return _AGDepthAndFrontFace___init__impl__ra8x65(insert3(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), _AGCompareMode___get_ordinal__impl__ikvlmz(depthFunc), 26));
  }
  function AGDepthAndFrontFace__withFrontFace_impl_mwk87t($this, frontFace) {
    return _AGDepthAndFrontFace___init__impl__ra8x65(insert2(_AGDepthAndFrontFace___get_data__impl__v8zkce($this), _AGFrontFace___get_ordinal__impl__d4iagj(frontFace), 30));
  }
  function AGDepthAndFrontFace__toString_impl_c305m3($this) {
    return 'AGDepthAndFrontFace(data=' + $this + ')';
  }
  function AGDepthAndFrontFace__hashCode_impl_xu62ey($this) {
    return $this;
  }
  function AGDepthAndFrontFace__equals_impl_tycw66($this, other) {
    if (!(other instanceof AGDepthAndFrontFace))
      return false;
    var tmp0_other_with_cast = other instanceof AGDepthAndFrontFace ? other.s7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGDepthAndFrontFace(data) {
    Companion_getInstance_55();
    this.s7y_1 = data;
  }
  protoOf(AGDepthAndFrontFace).toString = function () {
    return AGDepthAndFrontFace__toString_impl_c305m3(this.s7y_1);
  };
  protoOf(AGDepthAndFrontFace).hashCode = function () {
    return AGDepthAndFrontFace__hashCode_impl_xu62ey(this.s7y_1);
  };
  protoOf(AGDepthAndFrontFace).equals = function (other) {
    return AGDepthAndFrontFace__equals_impl_tycw66(this.s7y_1, other);
  };
  function _AGReadKind___init__impl__sw15xt(ordinal) {
    return ordinal;
  }
  function Companion_40() {
    Companion_instance_40 = this;
    this.v7n_1 = _AGReadKind___init__impl__sw15xt(0);
    this.w7n_1 = _AGReadKind___init__impl__sw15xt(1);
    this.x7n_1 = _AGReadKind___init__impl__sw15xt(2);
  }
  var Companion_instance_40;
  function Companion_getInstance_56() {
    if (Companion_instance_40 == null)
      new Companion_40();
    return Companion_instance_40;
  }
  function _AGWrapMode___init__impl__x5wmya(ordinal) {
    return ordinal;
  }
  function _AGWrapMode___get_ordinal__impl__rc38w2($this) {
    return $this;
  }
  function Companion_41() {
    Companion_instance_41 = this;
    this.v7q_1 = _AGWrapMode___init__impl__x5wmya(0);
    this.w7q_1 = _AGWrapMode___init__impl__x5wmya(1);
    this.x7q_1 = _AGWrapMode___init__impl__x5wmya(2);
  }
  var Companion_instance_41;
  function Companion_getInstance_57() {
    if (Companion_instance_41 == null)
      new Companion_41();
    return Companion_instance_41;
  }
  function AGWrapMode__toString_impl_8bjnw2($this) {
    var tmp0_subject = $this;
    return tmp0_subject === Companion_getInstance_57().v7q_1 ? 'CLAMP_TO_EDGE' : tmp0_subject === Companion_getInstance_57().w7q_1 ? 'REPEAT' : tmp0_subject === Companion_getInstance_57().x7q_1 ? 'MIRRORED_REPEAT' : '<' + _AGWrapMode___get_ordinal__impl__rc38w2($this) + '>';
  }
  function AGWrapMode__hashCode_impl_u2pkox($this) {
    return $this;
  }
  function AGWrapMode__equals_impl_4aw0kl($this, other) {
    if (!(other instanceof AGWrapMode))
      return false;
    var tmp0_other_with_cast = other instanceof AGWrapMode ? other.t7y_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function AGWrapMode(ordinal) {
    Companion_getInstance_57();
    this.t7y_1 = ordinal;
  }
  protoOf(AGWrapMode).toString = function () {
    return AGWrapMode__toString_impl_8bjnw2(this.t7y_1);
  };
  protoOf(AGWrapMode).hashCode = function () {
    return AGWrapMode__hashCode_impl_u2pkox(this.t7y_1);
  };
  protoOf(AGWrapMode).equals = function (other) {
    return AGWrapMode__equals_impl_4aw0kl(this.t7y_1, other);
  };
  function _AGSize___init__impl__h9blcq(data) {
    return data;
  }
  function AGSize__with_impl_qn6qww($this, width, height) {
    return _AGSize___init__impl__h9blcq(insert_0(insert_0(0, width, 0, 16), height, 16, 16));
  }
  function Companion_42() {
    Companion_instance_42 = this;
  }
  protoOf(Companion_42).u7y = function () {
    return _AGSize___init__impl__h9blcq(-2);
  };
  protoOf(Companion_42).a7x = function (width, height) {
    return AGSize__with_impl_qn6qww(_AGSize___init__impl__h9blcq(0), width, height);
  };
  var Companion_instance_42;
  function Companion_getInstance_58() {
    if (Companion_instance_42 == null)
      new Companion_42();
    return Companion_instance_42;
  }
  function _AGFrontFace___init__impl__vsm7rb(ordinal) {
    return ordinal;
  }
  function _AGFrontFace___get_ordinal__impl__d4iagj($this) {
    return $this;
  }
  function Companion_43() {
    Companion_instance_43 = this;
    this.p7y_1 = _AGFrontFace___init__impl__vsm7rb(0);
    this.q7y_1 = _AGFrontFace___init__impl__vsm7rb(1);
    this.r7y_1 = _AGFrontFace___init__impl__vsm7rb(2);
  }
  var Companion_instance_43;
  function Companion_getInstance_59() {
    if (Companion_instance_43 == null)
      new Companion_43();
    return Companion_instance_43;
  }
  function AGConfig(antialiasHint) {
    antialiasHint = antialiasHint === VOID ? true : antialiasHint;
    this.v7y_1 = antialiasHint;
  }
  protoOf(AGConfig).toString = function () {
    return 'AGConfig(antialiasHint=' + this.v7y_1 + ')';
  };
  protoOf(AGConfig).hashCode = function () {
    return this.v7y_1 | 0;
  };
  protoOf(AGConfig).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AGConfig))
      return false;
    var tmp0_other_with_cast = other instanceof AGConfig ? other : THROW_CCE();
    if (!(this.v7y_1 === tmp0_other_with_cast.v7y_1))
      return false;
    return true;
  };
  var AGBufferKind_INDEX_instance;
  var AGBufferKind_VERTEX_instance;
  var AGBufferKind_UNIFORM_instance;
  var AGBufferKind_entriesInitialized;
  function AGBufferKind_initEntries() {
    if (AGBufferKind_entriesInitialized)
      return Unit_getInstance();
    AGBufferKind_entriesInitialized = true;
    AGBufferKind_INDEX_instance = new AGBufferKind('INDEX', 0);
    AGBufferKind_VERTEX_instance = new AGBufferKind('VERTEX', 1);
    AGBufferKind_UNIFORM_instance = new AGBufferKind('UNIFORM', 2);
  }
  function AGBufferKind(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function AGBufferKind_INDEX_getInstance() {
    AGBufferKind_initEntries();
    return AGBufferKind_INDEX_instance;
  }
  function AGBufferKind_VERTEX_getInstance() {
    AGBufferKind_initEntries();
    return AGBufferKind_VERTEX_instance;
  }
  function AGBufferKind_UNIFORM_getInstance() {
    AGBufferKind_initEntries();
    return AGBufferKind_UNIFORM_instance;
  }
  function AGStats(texturesCount, texturesMemory, buffersCount, buffersMemory, frameBuffersCount, frameBuffersMemory, texturesCreated, texturesDeleted, programCount) {
    texturesCount = texturesCount === VOID ? 0 : texturesCount;
    texturesMemory = texturesMemory === VOID ? Companion_getInstance_4().k1l(0) : texturesMemory;
    buffersCount = buffersCount === VOID ? 0 : buffersCount;
    buffersMemory = buffersMemory === VOID ? Companion_getInstance_4().k1l(0) : buffersMemory;
    frameBuffersCount = frameBuffersCount === VOID ? 0 : frameBuffersCount;
    frameBuffersMemory = frameBuffersMemory === VOID ? Companion_getInstance_4().k1l(0) : frameBuffersMemory;
    texturesCreated = texturesCreated === VOID ? 0 : texturesCreated;
    texturesDeleted = texturesDeleted === VOID ? 0 : texturesDeleted;
    programCount = programCount === VOID ? 0 : programCount;
    this.w7y_1 = texturesCount;
    this.x7y_1 = texturesMemory;
    this.y7y_1 = buffersCount;
    this.z7y_1 = buffersMemory;
    this.a7z_1 = frameBuffersCount;
    this.b7z_1 = frameBuffersMemory;
    this.c7z_1 = texturesCreated;
    this.d7z_1 = texturesDeleted;
    this.e7z_1 = programCount;
  }
  protoOf(AGStats).toString = function () {
    return 'AGStats(textures[' + this.w7y_1 + '] = ' + new ByteUnits(this.x7y_1) + ', buffers[' + this.y7y_1 + '] = ' + new ByteUnits(this.z7y_1) + ', frameBuffers[' + this.a7z_1 + '] = ' + new ByteUnits(this.b7z_1) + ', programs[' + this.e7z_1 + '])';
  };
  function Companion_44() {
    Companion_instance_44 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'korlibs.graphics.shader.VertexShader' call
    var builder = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.Companion.PROGRAM.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'korlibs.graphics.DefaultShaders.invoke' call
    var tmp0_invoke = DefaultShaders_getInstance();
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.Companion.PROGRAM.<anonymous>.<anonymous>' call
    builder.h7z(tmp0_invoke.d7w_1, tmp0_invoke.b7w_1);
    builder.h7z(builder.i7z(), builder.k7z([tmp0_invoke.a7w_1, builder.j7z(0.0), builder.j7z(1.0)]));
    tmp$ret$0 = tmp0_invoke;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = VertexShader(builder.l7z());
    var tmp_0 = tmp$ret$2;
    var tmp$ret$5;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_0 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.Companion.PROGRAM.<anonymous>' call
    var tmp$ret$4;
    // Inline function 'korlibs.graphics.DefaultShaders.invoke' call
    var tmp0_invoke_0 = DefaultShaders_getInstance();
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.Companion.PROGRAM.<anonymous>.<anonymous>' call
    builder_0.h7z(builder_0.i7z(), builder_0.o7z(tmp0_invoke_0.m7z(), builder_0.n7z(tmp0_invoke_0.d7w_1, 'xy')));
    tmp$ret$3 = tmp0_invoke_0;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = FragmentShader(builder_0.l7z());
    tmp.p7z_1 = new Program(tmp_0, tmp$ret$5);
  }
  var Companion_instance_44;
  function Companion_getInstance_60() {
    if (Companion_instance_44 == null)
      new Companion_44();
    return Companion_instance_44;
  }
  function AGTextureDrawer(ag) {
    Companion_getInstance_60();
    this.q7z_1 = ag;
    this.r7z_1 = 4;
    this.s7z_1 = new AGBuffer();
    this.t7z_1 = ProgramLayout_init_$Create$([DefaultShaders_getInstance().a7w_1, DefaultShaders_getInstance().b7w_1]);
    this.u7z_1 = AGVertexArrayObject_init_$Create$([new AGVertexData(this.t7z_1, this.s7z_1)], false);
    this.v7z_1 = Buffer(imul(this.r7z_1, this.t7z_1.f80_1));
    this.w7z_1 = AGTextureUnits_init_$Create$(1);
    this.x7z_1 = new AGProgramWithUniforms(Companion_getInstance_60().p7z_1);
  }
  function ShaderIndices() {
    ShaderIndices_instance = this;
    this.g80_1 = 0;
    this.h80_1 = 1;
    this.i80_1 = 2;
    this.j80_1 = 3;
    this.k80_1 = 4;
    this.l80_1 = 5;
    this.m80_1 = 6;
    this.n80_1 = 7;
    this.o80_1 = 8;
    this.p80_1 = 1;
    this.q80_1 = 2;
    this.r80_1 = 3;
  }
  var ShaderIndices_instance;
  function ShaderIndices_getInstance() {
    if (ShaderIndices_instance == null)
      new ShaderIndices();
    return ShaderIndices_instance;
  }
  function ProjViewUB() {
    ProjViewUB_instance = this;
    UniformBlock.call(this, 0);
    this.w80_1 = this.y80().i81(this, u_ProjMat$factory());
    this.x80_1 = this.y80().i81(this, u_ViewMat$factory());
  }
  protoOf(ProjViewUB).j81 = function () {
    return this.w80_1.g9(this, u_ProjMat$factory_0());
  };
  protoOf(ProjViewUB).k81 = function () {
    return this.x80_1.g9(this, u_ViewMat$factory_0());
  };
  var ProjViewUB_instance;
  function ProjViewUB_getInstance() {
    if (ProjViewUB_instance == null)
      new ProjViewUB();
    return ProjViewUB_instance;
  }
  function DefaultShaders() {
    DefaultShaders_instance = this;
    var tmp = this;
    ShaderIndices_getInstance();
    tmp.y7v_1 = Sampler_0(1, SamplerVarType_Sampler2D_getInstance()).z81(this, u_Tex$factory());
    var tmp_0 = this;
    ShaderIndices_getInstance();
    tmp_0.z7v_1 = Sampler_0(2, SamplerVarType_Sampler2D_getInstance()).z81(this, u_TexEx$factory());
    this.a7w_1 = Attribute_init_$Create$('a_Pos', VarType_Float2_getInstance(), false, Precision_HIGH_getInstance(), 0);
    this.b7w_1 = Attribute_init_$Create$('a_Tex', VarType_Float2_getInstance(), false, Precision_MEDIUM_getInstance(), 1);
    this.c7w_1 = Attribute_init_$Create$('a_Col', VarType_UByte4_getInstance(), true, Precision_LOW_getInstance(), 2);
    this.d7w_1 = Varying_init_$Create$('v_Tex', VarType_Float2_getInstance(), Precision_MEDIUM_getInstance());
    this.e7w_1 = Varying_init_$Create$('v_Col', VarType_Float4_getInstance());
    this.f7w_1 = Temp_init_$Create$(0, VarType_Float4_getInstance());
    this.g7w_1 = Temp_init_$Create$(1, VarType_Float4_getInstance());
    this.h7w_1 = Temp_init_$Create$(2, VarType_Mat2_getInstance());
    this.i7w_1 = ProgramLayout_init_$Create$([this.a7w_1, this.b7w_1, this.c7w_1]);
    var tmp_1 = this;
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.VertexShader' call
    var builder = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.VERTEX_DEFAULT.<anonymous>' call
    builder.h7z(builder.a82(), builder.b82());
    builder.h7z(builder.c82(), builder.d82());
    builder.h7z(builder.i7z(), builder.e82(builder.e82(builder.j81(), builder.k81()), builder.k7z([builder.f82(), builder.j7z(0.0), builder.j7z(1.0)])));
    tmp$ret$0 = VertexShader(builder.l7z());
    tmp_1.j7w_1 = tmp$ret$0;
    var tmp_2 = this;
    var tmp$ret$2;
    // Inline function 'korlibs.graphics.FragmentShaderDefault' call
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = new ProgramBuilderDefault();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.DefaultShaders.MERGE_ALPHA_PROGRAM.<anonymous>' call
    var coords = tmp0_also.n7z(tmp0_also.a82(), 'xy');
    tmp0_also.h7z(tmp0_also.i7z(), tmp0_also.e82(tmp0_also.o7z(tmp0_also.m7z(), coords), tmp0_also.h82(tmp0_also.o7z(DefaultShaders_getInstance().g82(), coords))));
    tmp$ret$1 = tmp0_also;
    tmp$ret$2 = FragmentShader(tmp$ret$1.l7z());
    tmp_2.k7w_1 = new Program(this.j7w_1, tmp$ret$2);
    var tmp_3 = this;
    var tmp$ret$3;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_0 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.FRAGMENT_DEBUG.<anonymous>' call
    builder_0.h7z(builder_0.i7z(), builder_0.k7z([builder_0.j7z(1.0), builder_0.j7z(1.0), builder_0.j7z(0.0), builder_0.j7z(1.0)]));
    tmp$ret$3 = FragmentShader(builder_0.l7z());
    tmp_3.l7w_1 = tmp$ret$3;
    var tmp_4 = this;
    var tmp$ret$4;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_1 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.FRAGMENT_SOLID_COLOR.<anonymous>' call
    builder_1.h7z(builder_1.i7z(), builder_1.c82());
    tmp$ret$4 = FragmentShader(builder_1.l7z());
    tmp_4.m7w_1 = tmp$ret$4;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_2 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_TINTED_TEXTURE.<anonymous>' call
    builder_2.h7z(builder_2.i7z(), builder_2.e82(builder_2.n7z(builder_2.o7z(builder_2.m7z(), builder_2.n7z(builder_2.a82(), 'xy')), 'rgba'), builder_2.c82()));
    tmp$ret$5 = FragmentShader(builder_2.l7z());
    tmp_5.n7w_1 = new Program(this.j7w_1, tmp$ret$5, 'PROGRAM_TINTED_TEXTURE');
    var tmp_6 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_3 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_TINTED_TEXTURE_PREMULT.<anonymous>' call
    builder_3.h7z(builder_3.i82(), builder_3.o7z(builder_3.m7z(), builder_3.n7z(builder_3.a82(), 'xy')));
    builder_3.h7z(builder_3.n7z(builder_3.i82(), 'rgb'), builder_3.j82(builder_3.n7z(builder_3.i82(), 'rgb'), builder_3.n7z(builder_3.i82(), 'a')));
    builder_3.h7z(builder_3.i7z(), builder_3.e82(builder_3.n7z(builder_3.i82(), 'rgba'), builder_3.c82()));
    tmp$ret$6 = FragmentShader(builder_3.l7z());
    tmp_6.o7w_1 = new Program(this.j7w_1, tmp$ret$6, 'PROGRAM_TINTED_TEXTURE');
    this.p7w_1 = new Program(this.j7w_1, this.m7w_1, 'PROGRAM_SOLID_COLOR');
    this.q7w_1 = ProgramLayout_init_$Create$([this.a7w_1]);
    var tmp_7 = this;
    var tmp$ret$7;
    // Inline function 'korlibs.graphics.shader.VertexShader' call
    var builder_4 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_DEBUG.<anonymous>' call
    builder_4.h7z(builder_4.i7z(), builder_4.k7z([builder_4.f82(), builder_4.j7z(0.0), builder_4.j7z(1.0)]));
    tmp$ret$7 = VertexShader(builder_4.l7z());
    var tmp_8 = tmp$ret$7;
    var tmp$ret$8;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_5 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_DEBUG.<anonymous>' call
    builder_5.h7z(builder_5.i7z(), builder_5.k7z([builder_5.j7z(1.0), builder_5.j7z(0.0), builder_5.j7z(0.0), builder_5.j7z(1.0)]));
    tmp$ret$8 = FragmentShader(builder_5.l7z());
    tmp_7.r7w_1 = new Program(tmp_8, tmp$ret$8, 'PROGRAM_DEBUG');
    var tmp_9 = this;
    var tmp$ret$9;
    // Inline function 'korlibs.graphics.shader.VertexShader' call
    var builder_6 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_DEBUG_WITH_PROJ.<anonymous>' call
    builder_6.h7z(builder_6.i7z(), builder_6.e82(builder_6.e82(builder_6.j81(), builder_6.k81()), builder_6.k7z([builder_6.f82(), builder_6.j7z(0.0), builder_6.j7z(1.0)])));
    tmp$ret$9 = VertexShader(builder_6.l7z());
    var tmp_10 = tmp$ret$9;
    var tmp$ret$10;
    // Inline function 'korlibs.graphics.shader.FragmentShader' call
    var builder_7 = new ProgramBuilderDefault();
    // Inline function 'korlibs.graphics.DefaultShaders.PROGRAM_DEBUG_WITH_PROJ.<anonymous>' call
    builder_7.h7z(builder_7.i7z(), builder_7.k7z([builder_7.j7z(1.0), builder_7.j7z(0.0), builder_7.j7z(0.0), builder_7.j7z(1.0)]));
    tmp$ret$10 = FragmentShader(builder_7.l7z());
    tmp_9.s7w_1 = new Program(tmp_10, tmp$ret$10, 'PROGRAM_DEBUG_WITH_PROJ');
  }
  protoOf(DefaultShaders).m7z = function () {
    return this.y7v_1.g9(this, u_Tex$factory_0());
  };
  protoOf(DefaultShaders).g82 = function () {
    return this.z7v_1.g9(this, u_TexEx$factory_0());
  };
  protoOf(DefaultShaders).j81 = function () {
    return ProjViewUB_getInstance().j81().h7u_1;
  };
  protoOf(DefaultShaders).k81 = function () {
    return ProjViewUB_getInstance().k81().h7u_1;
  };
  var DefaultShaders_instance;
  function DefaultShaders_getInstance() {
    if (DefaultShaders_instance == null)
      new DefaultShaders();
    return DefaultShaders_instance;
  }
  function ProgramBuilderDefault() {
    Builder.call(this);
  }
  function IDefaultShaders() {
  }
  function u_Tex$factory() {
    return getPropertyCallableRef('u_Tex', 1, KProperty1, function (receiver) {
      return receiver.m7z();
    }, null);
  }
  function u_TexEx$factory() {
    return getPropertyCallableRef('u_TexEx', 1, KProperty1, function (receiver) {
      return receiver.g82();
    }, null);
  }
  function u_Tex$factory_0() {
    return getPropertyCallableRef('u_Tex', 1, KProperty1, function (receiver) {
      return receiver.m7z();
    }, null);
  }
  function u_TexEx$factory_0() {
    return getPropertyCallableRef('u_TexEx', 1, KProperty1, function (receiver) {
      return receiver.g82();
    }, null);
  }
  function u_ProjMat$factory() {
    return getPropertyCallableRef('u_ProjMat', 1, KProperty1, function (receiver) {
      return receiver.j81();
    }, null);
  }
  function u_ViewMat$factory() {
    return getPropertyCallableRef('u_ViewMat', 1, KProperty1, function (receiver) {
      return receiver.k81();
    }, null);
  }
  function u_ProjMat$factory_0() {
    return getPropertyCallableRef('u_ProjMat', 1, KProperty1, function (receiver) {
      return receiver.j81();
    }, null);
  }
  function u_ViewMat$factory_0() {
    return getPropertyCallableRef('u_ViewMat', 1, KProperty1, function (receiver) {
      return receiver.k81();
    }, null);
  }
  function get_ENABLE_UNIFORM_BLOCKS() {
    _init_properties_AGOpengl_kt__dtzqx7();
    return ENABLE_UNIFORM_BLOCKS;
  }
  var ENABLE_UNIFORM_BLOCKS;
  function get_ENABLE_VERTEX_ARRAY_OBJECTS() {
    _init_properties_AGOpengl_kt__dtzqx7();
    return ENABLE_VERTEX_ARRAY_OBJECTS;
  }
  var ENABLE_VERTEX_ARRAY_OBJECTS;
  function set_gl(_this__u8e3s4, _set____db54di) {
    _init_properties_AGOpengl_kt__dtzqx7();
    var tmp1_setValue = gl$factory();
    var tmp0_setValueUntransformed = gl$delegate.j1i_1(_this__u8e3s4, _set____db54di);
    var tmp0_elvis_lhs = gl$delegate.h1i_1;
    setExtra(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp1_setValue.callableName : tmp0_elvis_lhs, tmp0_setValueUntransformed);
    return Unit_getInstance();
  }
  function get_gl(_this__u8e3s4) {
    _init_properties_AGOpengl_kt__dtzqx7();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var tmp0_getValue = gl$factory_0();
      var tmp0_elvis_lhs = gl$delegate.h1i_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp0_getValue.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = gl$delegate.i1i_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = gl$delegate.h1i_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? tmp0_getValue.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var gl$delegate;
  function GLVAO(vao, glId, contextVersion) {
    glId = glId === VOID ? -1 : glId;
    contextVersion = contextVersion === VOID ? -1 : contextVersion;
    this.g84_1 = vao;
    this.h84_1 = glId;
    this.i84_1 = contextVersion;
  }
  function ShaderException(str, error, errorInt, gl, debugName, type, shaderReturnInt) {
    RuntimeException_init_$Init$('Error Compiling Shader : ' + debugName + ' type=' + type + ' : ' + get_hex(errorInt) + " : '" + error + "' : source='" + str + "', shaderReturnInt=" + shaderReturnInt + ', gl.versionInt=' + get_versionInt(gl) + ", gl.versionString='" + get_versionString(gl) + "', gl=" + gl, this);
    captureStack(this, ShaderException);
    this.j84_1 = str;
    this.k84_1 = error;
    this.l84_1 = errorInt;
    this.m84_1 = gl;
    this.n84_1 = debugName;
    this.o84_1 = type;
    this.p84_1 = shaderReturnInt;
  }
  function useProgram($this, program) {
    var gl = $this.u84_1;
    var map = $this.y84_1;
    if ($this.z84_1 == null) {
      var tmp = $this;
      Companion_getInstance_68();
      tmp.z84_1 = gl.d86(35724);
      var tmp$ret$1;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp1_info = Companion_getInstance_37().k7l_1;
      var tmp0_log = Level_INFO_getInstance();
      var tmp_0;
      if (tmp1_info.q1n(tmp0_log)) {
        var tmp$ret$0;
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
        Companion_getInstance_68();
        tmp$ret$0 = 'GL_VERSION=' + gl.d86(7938);
        tmp1_info.r1n(tmp0_log, tmp$ret$0);
        tmp_0 = Unit_getInstance();
      }
      tmp$ret$1 = tmp_0;
      var tmp$ret$3;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp3_info = Companion_getInstance_37().k7l_1;
      var tmp2_log = Level_INFO_getInstance();
      var tmp_1;
      if (tmp3_info.q1n(tmp2_log)) {
        var tmp$ret$2;
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
        tmp$ret$2 = 'GL_SHADING_LANGUAGE_VERSION=' + $this.z84_1;
        tmp3_info.r1n(tmp2_log, tmp$ret$2);
        tmp_1 = Unit_getInstance();
      }
      tmp$ret$3 = tmp_1;
      var tmp$ret$5;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp5_info = Companion_getInstance_37().k7l_1;
      var tmp4_log = Level_INFO_getInstance();
      var tmp_2;
      if (tmp5_info.q1n(tmp4_log)) {
        var tmp$ret$4;
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
        Companion_getInstance_68();
        tmp$ret$4 = 'GL_UNIFORM_BUFFER_OFFSET_ALIGNMENT=' + getInteger(gl, 35380);
        tmp5_info.r1n(tmp4_log, tmp$ret$4);
        tmp_2 = Unit_getInstance();
      }
      tmp$ret$5 = tmp_2;
      var tmp$ret$7;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp7_info = Companion_getInstance_37().k7l_1;
      var tmp6_log = Level_INFO_getInstance();
      var tmp_3;
      if (tmp7_info.q1n(tmp6_log)) {
        var tmp$ret$6;
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
        tmp$ret$6 = 'gl.versionString=' + get_versionString(gl);
        tmp7_info.r1n(tmp6_log, tmp$ret$6);
        tmp_3 = Unit_getInstance();
      }
      tmp$ret$7 = tmp_3;
      var tmp$ret$9;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp9_info = Companion_getInstance_37().k7l_1;
      var tmp8_log = Level_INFO_getInstance();
      var tmp_4;
      if (tmp9_info.q1n(tmp8_log)) {
        var tmp$ret$8;
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
        tmp$ret$8 = 'gl.versionInt=' + get_versionInt(gl);
        tmp9_info.r1n(tmp8_log, tmp$ret$8);
        tmp_4 = Unit_getInstance();
      }
      tmp$ret$9 = tmp_4;
    }
    var tmp$ret$12;
    // Inline function 'kotlin.collections.getOrPut' call
    var value = map.h3(program);
    var tmp_5;
    if (value == null) {
      var tmp$ret$11;
      // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>' call
      var tmp$ret$10;
      // Inline function 'kotlin.also' call
      var tmp0_also = new GLBaseProgram($this.x84_1, GLShaderCompiler_getInstance().g86(gl, $this.e86(), program, program.k7t_1));
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>.<anonymous>' call
      tmp0_also.k86();
      var programInfo = tmp0_also.j86_1;
      var programId = programInfo.l86_1;
      // Inline function 'kotlin.collections.forEach' call
      var tmp0_forEach = program.o7t_1;
      var tmp0_iterator = tmp0_forEach.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>.<anonymous>.<anonymous>' call
        var location = programInfo.g87(gl, element.b87_1);
        gl.h87(location, element.r7r_1);
      }
      if (programInfo.p86_1.o87()) {
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var tmp1_fastForEach = program.n7t_1;
        var n = 0;
        while (n < tmp1_fastForEach.l()) {
          // Inline function 'korlibs.graphics.gl.AGOpengl.useProgram.<anonymous>.<anonymous>.<anonymous>' call
          var tmp0 = n;
          n = tmp0 + 1 | 0;
          var tmp2__anonymous__z9zvc9 = tmp1_fastForEach.n(tmp0);
          var index = gl.i87(programId, tmp2__anonymous__z9zvc9.mm());
          gl.j87(programId, index, tmp2__anonymous__z9zvc9.i7u_1);
        }
      }
      tmp$ret$10 = tmp0_also;
      tmp$ret$11 = tmp$ret$10;
      var answer = tmp$ret$11;
      map.e5(program, answer);
      tmp_5 = answer;
    } else {
      tmp_5 = value;
    }
    tmp$ret$12 = tmp_5;
    var nprogram = tmp$ret$12;
    if (!equals($this.i85_1, nprogram)) {
      $this.i85_1 = nprogram;
      nprogram.k86();
    }
  }
  function resetObjects($this) {
    $this.b85_1 = null;
    $this.m85_1 = Companion_getInstance_44().a7n_1;
    $this.c85_1 = Companion_getInstance_47().d7m_1;
    $this.d85_1 = Companion_getInstance_53().c7n_1;
    $this.e85_1 = Companion_getInstance_42().o7m_1;
    $this.f85_1 = Companion_getInstance_41().m7m_1;
    $this.g85_1 = Companion_getInstance_43().q7m_1;
    $this.h85_1 = Companion_getInstance_55().w7m_1;
    $this.j85_1.e4();
    $this.i85_1 = null;
    $this.w85_1 = -1;
    $this.u85_1 = 0;
    $this.v85_1 = Companion_getInstance_58().u7y();
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = $this.s85_1;
    var n = 0;
    while (n < tmp0_fastForEach.length) {
      // Inline function 'korlibs.graphics.gl.AGOpengl.resetObjects.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach[tmp0];
      tmp1__anonymous__uwfjfc.p1g();
    }
  }
  function deletePendingObjects($this) {
    while (true) {
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var tmp$ret$3;
      // Inline function 'korlibs.datastructure.lock.Lock.invoke' call
      var tmp0_invoke = $this.x84_1.d88_1;
      var tmp$ret$2;
      // Inline function 'korlibs.graphics.gl.AGOpengl.deletePendingObjects.<anonymous>' call
      var tmp;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp0_isNotEmpty = $this.x84_1.e88_1;
      tmp$ret$0 = !tmp0_isNotEmpty.p();
      if (tmp$ret$0) {
        var tmp$ret$1;
        // Inline function 'kotlin.also' call
        var tmp1_also = toList($this.x84_1.e88_1);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.graphics.gl.AGOpengl.deletePendingObjects.<anonymous>.<anonymous>' call
        $this.x84_1.e88_1.e4();
        tmp$ret$1 = tmp1_also;
        tmp = tmp$ret$1;
      } else {
        return Unit_getInstance();
      }
      tmp$ret$2 = tmp;
      tmp$ret$3 = tmp$ret$2;
      var tmp1_fastForEach = tmp$ret$3;
      var n = 0;
      while (n < tmp1_fastForEach.l()) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.deletePendingObjects.<anonymous>' call
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = tmp1_fastForEach.n(tmp0);
        tmp2__anonymous__z9zvc9.h88();
      }
    }
  }
  function bindBuffer($this, buffer, target, onlyUpdate, reallocated, updated) {
    var gl = $this.u84_1;
    var tmp0_safe_receiver = reallocated;
    if (tmp0_safe_receiver == null) {
    } else {
      tmp0_safe_receiver.f1h(false);
    }
    var tmp1_safe_receiver = updated;
    if (tmp1_safe_receiver == null) {
    } else {
      tmp1_safe_receiver.f1h(false);
    }
    if (buffer == null) {
      gl.i88(toGl_6(target), 0);
      return null;
    }
    var bufferInfo = _get_gl__ndc1ty(buffer, $this);
    if (!onlyUpdate) {
      gl.i88(toGl_6(target), bufferInfo.l88_1);
    }
    $this.o88(buffer, AGOpengl$bindBuffer$lambda(buffer, bufferInfo, onlyUpdate, gl, target, reallocated, updated));
    return bufferInfo;
  }
  function bindBuffer$default($this, buffer, target, onlyUpdate, reallocated, updated, $super) {
    onlyUpdate = onlyUpdate === VOID ? false : onlyUpdate;
    reallocated = reallocated === VOID ? null : reallocated;
    updated = updated === VOID ? null : updated;
    return bindBuffer($this, buffer, target, onlyUpdate, reallocated, updated);
  }
  function writeUniform($this, uniform, programInfo, data, source) {
    var gl = $this.u84_1;
    var location = programInfo.g87(gl, uniform.b87_1);
    var uniformType = uniform.c7d();
    var arrayCount = uniform.r88_1;
    var tmp0_subject = uniformType.y88_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 6) {
      var tmp1_subject = uniformType;
      var tmp0_0 = tmp1_subject.b2_1;
      switch (tmp0_0) {
        case 1:
          gl.g89(location, arrayCount, false, data);
          ;
          break;
        case 2:
          var inductionVariable = 0;
          if (inductionVariable < 3)
            do {
              var n = inductionVariable;
              inductionVariable = inductionVariable + 1 | 0;
              arraycopy_0(data, imul(n, 16), $this.r85_1, imul(n, 12), 12);
            }
             while (inductionVariable < 3);
          gl.h89(location, arrayCount, false, $this.r85_1);
          ;
          break;
        case 3:
          gl.i89(location, arrayCount, false, data);
          ;
          break;
        default:
          var tmp3_subject = uniformType.z88_1;
          switch (tmp3_subject) {
            case 1:
              gl.j89(location, arrayCount, data);
              ;
              break;
            case 2:
              gl.k89(location, arrayCount, data);
              ;
              break;
            case 3:
              gl.l89(location, arrayCount, data);
              ;
              break;
            case 4:
              gl.m89(location, arrayCount, data);
              ;
              break;
          }

          break;
      }
    } else {
      var tmp4_subject = uniformType.z88_1;
      switch (tmp4_subject) {
        case 1:
          gl.c89(location, arrayCount, data);
          ;
          break;
        case 2:
          gl.d89(location, arrayCount, data);
          ;
          break;
        case 3:
          gl.e89(location, arrayCount, data);
          ;
          break;
        case 4:
          gl.f89(location, arrayCount, data);
          ;
          break;
      }
    }
  }
  function TextureUnitParams(index) {
    this.p87_1 = index;
    this.q87_1 = _AGWrapMode___init__impl__x5wmya(-1);
    this.r87_1 = -1;
    this.s87_1 = -1;
    this.t87_1 = -1;
    this.u87_1 = -1;
  }
  protoOf(TextureUnitParams).p1g = function () {
    this.q87_1 = _AGWrapMode___init__impl__x5wmya(-1);
    this.r87_1 = -1;
    this.s87_1 = -1;
    this.t87_1 = -1;
    this.u87_1 = -1;
  };
  function textureUnitParameters($this, implForcedTexTarget, wrap, minFilter, magFilter, dims) {
    var gl = $this.u84_1;
    var params = $this.s85_1[$this.u85_1];
    var glTarget = toGl_8(implForcedTexTarget);
    {
      params.q87_1 = wrap;
      var glWrap = toGl_9(wrap);
      Companion_getInstance_68();
      gl.n89(glTarget, 10242, glWrap);
      Companion_getInstance_68();
      gl.n89(glTarget, 10243, glWrap);
      if (dims >= 3) {
        Companion_getInstance_68();
        gl.n89(glTarget, 32882, glWrap);
      }
    }
    {
      params.r87_1 = minFilter;
      params.s87_1 = magFilter;
      Companion_getInstance_68();
      gl.n89(glTarget, 10241, minFilter);
      Companion_getInstance_68();
      gl.n89(glTarget, 10240, magFilter);
    }
  }
  function createBufferForBitmap($this, bmp) {
    var tmp0_subject = bmp;
    var tmp;
    if (tmp0_subject == null) {
      tmp = null;
    } else {
      if (tmp0_subject instanceof NativeImage) {
        unsupported('Should not call createBufferForBitmap with a NativeImage');
      } else {
        if (tmp0_subject instanceof Bitmap8) {
          var tmp$ret$0;
          // Inline function 'kotlin.also' call
          var tmp0_also = Buffer(bmp.l42());
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.graphics.gl.AGOpengl.createBufferForBitmap.<anonymous>' call
          arraycopy_3(bmp.r50(), 0, get_i8(tmp0_also), 0, bmp.l42());
          tmp$ret$0 = tmp0_also;
          tmp = tmp$ret$0;
        } else {
          if (tmp0_subject instanceof FloatBitmap32) {
            var tmp$ret$1;
            // Inline function 'kotlin.also' call
            var tmp1_also = Buffer(imul(imul(bmp.l42(), 4), 4));
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.graphics.gl.AGOpengl.createBufferForBitmap.<anonymous>' call
            arraycopy_2(bmp.l54_1, 0, get_f32(tmp1_also), 0, imul(bmp.l42(), 4));
            tmp$ret$1 = tmp1_also;
            tmp = tmp$ret$1;
          } else {
            var tmp$ret$2;
            // Inline function 'kotlin.also' call
            var tmp2_also = Buffer(imul(bmp.l42(), 4));
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.graphics.gl.AGOpengl.createBufferForBitmap.<anonymous>' call
            var abmp = bmp.z4q_1 ? bmp.e4z().a52() : bmp.e4z().b52();
            arraycopy_1(abmp.u4r_1, 0, get_i32(tmp2_also), 0, abmp.l42());
            tmp$ret$2 = tmp2_also;
            tmp = tmp$ret$2;
          }
        }
      }
    }
    return tmp;
  }
  function selectTextureUnit($this, index) {
    var old = $this.u85_1;
    if (!($this.u85_1 === index)) {
      $this.u85_1 = index;
      if (index >= 0) {
        Companion_getInstance_68();
        $this.u84_1.o89(33984 + index | 0);
      } else {
      }
    }
    return old;
  }
  function _get_gl__ndc1ty(_this__u8e3s4, $this) {
    return gl(_this__u8e3s4, $this.x84_1);
  }
  function _get_gl__ndc1ty_0(_this__u8e3s4, $this) {
    return gl_0(_this__u8e3s4, $this.x84_1);
  }
  function _get_gl__ndc1ty_1(_this__u8e3s4, $this) {
    return gl_1(_this__u8e3s4, $this.x84_1);
  }
  function AGOpengl$glslConfig$delegate$lambda(this$0) {
    return function () {
      return new GlslConfig(this$0.u84_1.p89(), this$0.u84_1);
    };
  }
  function AGOpengl$bindBuffer$lambda($buffer, $bufferInfo, $onlyUpdate, $gl, $target, $reallocated, $updated) {
    return function (it) {
      var tmp0_elvis_lhs = $buffer.v7o_1;
      var mem = tmp0_elvis_lhs == null ? Buffer(0) : tmp0_elvis_lhs;
      $bufferInfo.q89(toLong(get_sizeInBytes(mem)));
      var tmp;
      if ($onlyUpdate) {
        $gl.i88(toGl_6($target), $bufferInfo.l88_1);
        tmp = Unit_getInstance();
      }
      var tmp_0;
      if ($bufferInfo.m88_1 < get_sizeInBytes(mem)) {
        $bufferInfo.m88_1 = get_sizeInBytes(mem);
        var tmp_1 = toGl_6($target);
        var tmp_2 = get_sizeInBytes(mem);
        Companion_getInstance_68();
        $gl.s89(tmp_1, tmp_2, mem, 35048);
        var tmp1_safe_receiver = $reallocated;
        var tmp_3;
        if (tmp1_safe_receiver == null) {
          tmp_3 = Unit_getInstance();
        } else {
          tmp1_safe_receiver.f1h(true);
          tmp_3 = Unit_getInstance();
        }
        tmp_0 = tmp_3;
      } else {
        $gl.r89(toGl_6($target), 0, get_sizeInBytes(mem), mem);
        tmp_0 = Unit_getInstance();
      }
      var tmp2_safe_receiver = $updated;
      var tmp_4;
      if (tmp2_safe_receiver == null) {
        tmp_4 = Unit_getInstance();
      } else {
        tmp2_safe_receiver.f1h(true);
        tmp_4 = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function AGOpengl$textureBind$lambda($tex, $target, $gl, this$0, $glTex) {
    return function (it) {
      var rbmp = $tex.s7p_1;
      var tmp0_safe_receiver = (rbmp == null ? true : rbmp instanceof MultiBitmap) ? rbmp : null;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.p7q_1;
      var bmps = tmp1_elvis_lhs == null ? listOf(rbmp) : tmp1_elvis_lhs;
      var requestMipmaps = $tex.p7p_1;
      $tex.t7p_1 = $tex.s7q(rbmp, requestMipmaps);
      var totalSize = new Long(0, 0);
      var iterator = bmps.h();
      var index = 0;
      while (iterator.j()) {
        var index_0 = index;
        index = index + 1 | 0;
        var bmp = iterator.k();
        var isFloat = bmp instanceof FloatBitmap32;
        var tmp;
        if (bmp instanceof Bitmap8) {
          Companion_getInstance_68();
          tmp = 6409;
        } else {
          Companion_getInstance_68();
          tmp = 6408;
        }
        var type = tmp;
        var tmp4_subject = $target;
        var tmp_0;
        if (tmp4_subject === Companion_getInstance_50().y7p_1) {
          Companion_getInstance_68();
          tmp_0 = 34069 + index_0 | 0;
        } else {
          tmp_0 = toGl_8($target);
        }
        var texTarget = tmp_0;
        var tmp_1;
        if (isFloat ? _GLVariant___get_isWebGL__impl__bbd1dd($gl.p89()) ? _GLVariant___get_version__impl__9nyfwa($gl.p89()) >= 2 : false : false) {
          Companion_getInstance_68();
          tmp_1 = 34836;
        } else {
          tmp_1 = type;
        }
        var internalFormat = tmp_1;
        var format = type;
        var tmp_2;
        if (isFloat) {
          Companion_getInstance_68();
          tmp_2 = 5126;
        } else {
          Companion_getInstance_68();
          tmp_2 = 5121;
        }
        var texType = tmp_2;
        if (_GLVariant___get_os__impl__aswhdw($gl.p89()).v1j()) {
          Companion_getInstance_68();
          Companion_getInstance_68();
          $gl.t89(3313, 0);
          Companion_getInstance_68();
          Companion_getInstance_68();
          $gl.t89(3312, 1);
        }
        var tmp5_subject = bmp;
        if (tmp5_subject == null) {
          var tmp_3 = toGl_8($target);
          var tmp_4 = $tex.x18();
          var tmp_5 = $tex.y18();
          Companion_getInstance_68();
          $gl.u89(tmp_3, 0, type, tmp_4, tmp_5, 0, type, 5121, null);
        } else {
          if (tmp5_subject instanceof NativeImage) {
            if (!(bmp.l42() === 0)) {
              Companion_getInstance_68();
              $gl.v89(texTarget, 0, type, type, 5121, bmp);
            }
          } else {
            if (tmp5_subject instanceof NullBitmap) {
              $gl.u89(texTarget, 0, internalFormat, bmp.s4q_1, bmp.t4q_1, 0, format, texType, null);
            } else {
              var buffer = createBufferForBitmap(this$0, bmp);
              if (((!(buffer == null) ? !(bmp.s4q_1 === 0) : false) ? !(bmp.t4q_1 === 0) : false) ? !(get_size(buffer) === 0) : false) {
                $gl.u89(texTarget, 0, internalFormat, bmp.s4q_1, bmp.t4q_1, 0, format, texType, buffer);
              }
            }
          }
        }
        var tmp$ret$0;
        // Inline function 'kotlin.Long.plus' call
        var tmp0_plus = totalSize;
        var tmp1_plus = imul(imul($tex.x18(), $tex.y18()), 4);
        tmp$ret$0 = tmp0_plus.b9(toLong(tmp1_plus));
        totalSize = tmp$ret$0;
        if (_GLVariant___get_supportTextureLevel__impl__3yeu62($gl.p89())) {
          Companion_getInstance_68();
          Companion_getInstance_68();
          var tmp_6;
          if ($tex.t7p_1) {
            var tmp6_elvis_lhs = $tex.q7p_1;
            tmp_6 = tmp6_elvis_lhs == null ? 0 : tmp6_elvis_lhs;
          } else {
            tmp_6 = 0;
          }
          $gl.n89(3553, 33084, tmp_6);
          Companion_getInstance_68();
          Companion_getInstance_68();
          var tmp_7;
          if ($tex.t7p_1) {
            var tmp7_elvis_lhs = $tex.r7p_1;
            tmp_7 = tmp7_elvis_lhs == null ? 1000 : tmp7_elvis_lhs;
          } else {
            tmp_7 = 0;
          }
          $gl.n89(3553, 33085, tmp_7);
        }
        if ($tex.t7p_1) {
          $gl.w89(texTarget);
        }
      }
      $glTex.q89(totalSize);
      return Unit_getInstance();
    };
  }
  function AGOpengl(gl, context) {
    context = context === VOID ? null : context;
    AG.call(this);
    this.u84_1 = gl;
    this.v84_1 = context;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.linkedSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    tmp.w84_1 = tmp$ret$0;
    this.x84_1 = new GLGlobalState(this.u84_1, this);
    this.y84_1 = HashMap_init_$Create$();
    this.z84_1 = null;
    var tmp_0 = this;
    tmp_0.a85_1 = lazy(AGOpengl$glslConfig$delegate$lambda(this));
    this.b85_1 = null;
    this.c85_1 = Companion_getInstance_47().d7m_1;
    this.d85_1 = Companion_getInstance_53().c7n_1;
    this.e85_1 = Companion_getInstance_42().o7m_1;
    this.f85_1 = Companion_getInstance_41().m7m_1;
    this.g85_1 = Companion_getInstance_43().q7m_1;
    this.h85_1 = Companion_getInstance_55().w7m_1;
    this.i85_1 = null;
    this.j85_1 = AGTextureUnits_init_$Create$();
    this.k85_1 = new Int32Array(this.j85_1.l());
    this.l85_1 = 0;
    this.m85_1 = Companion_getInstance_44().a7n_1;
    this.n85_1 = -1;
    this.o85_1 = -1;
    this.p85_1 = get_ENABLE_VERTEX_ARRAY_OBJECTS();
    this.q85_1 = Ref();
    this.r85_1 = Buffer(36);
    var tmp_1 = this;
    var tmp_2 = 0;
    var tmp_3 = 32;
    var tmp$ret$1;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$1 = fillArrayVal(Array(tmp_3), null);
    var tmp_4 = tmp$ret$1;
    while (tmp_2 < tmp_3) {
      var tmp_5 = tmp_2;
      var tmp$ret$2;
      // Inline function 'korlibs.graphics.gl.AGOpengl.textureParams.<anonymous>' call
      tmp$ret$2 = new TextureUnitParams(tmp_5);
      tmp_4[tmp_5] = tmp$ret$2;
      tmp_2 = tmp_2 + 1 | 0;
    }
    tmp_1.s85_1 = tmp_4;
    this.t85_1 = 7;
    this.u85_1 = 0;
    this.v85_1 = Companion_getInstance_58().u7y();
    this.w85_1 = -1;
    this.x85_1 = new Long(-1, -1);
    this.y85_1 = null;
  }
  protoOf(AGOpengl).h7l = function () {
    return this.u84_1;
  };
  protoOf(AGOpengl).q7l = function () {
    var gl = this.u84_1;
    protoOf(AG).q7l.call(this);
    gl.d8a();
    gl.e8a();
    this.y84_1.e4();
    resetObjects(this);
    this.n85_1 = -1;
    this.o85_1 = -1;
    this.p85_1 = true;
  };
  protoOf(AGOpengl).u7l = function (frameBuffer, frameBufferInfo, color, depth, stencil, clearColor, clearDepth, clearStencil, scissor) {
    var gl = this.u84_1;
    this.f8a(frameBuffer, frameBufferInfo);
    this.g8a(scissor, frameBuffer, frameBufferInfo);
    var mask = 0;
    if (clearColor) {
      this.h8a(Companion_getInstance_43().t7m_1);
      gl.i8a(_RGBA___get_rf__impl__kvz75k(color), _RGBA___get_gf__impl__5gcu1f(color), _RGBA___get_bf__impl__1xz5yg(color), _RGBA___get_af__impl__vu36qv(color));
      var tmp = mask;
      Companion_getInstance_68();
      mask = tmp | 16384;
    }
    if (clearDepth) {
      gl.j8a(true);
      gl.k8a(depth);
      var tmp_0 = mask;
      Companion_getInstance_68();
      mask = tmp_0 | 256;
    }
    if (clearStencil) {
      this.e85_1 = Companion_getInstance_42().o7m_1;
      gl.l8a(-1);
      gl.m8a(stencil);
      var tmp_1 = mask;
      Companion_getInstance_68();
      mask = tmp_1 | 1024;
    }
    gl.n8a(mask);
  };
  protoOf(AGOpengl).v7l = function (frameBuffer, frameBufferInfo, vertexData, program, drawType, vertexCount, indices, indexType, drawOffset, blending, uniformBlocks, textureUnits, stencilRef, stencilOpFunc, colorMask, depthAndFrontFace, scissor, cullFace, instances) {
    var gl = this.u84_1;
    this.f8a(frameBuffer, frameBufferInfo);
    this.g8a(scissor, frameBuffer, frameBufferInfo);
    var tmp0_safe_receiver = this.b85_1;
    if (!equals(tmp0_safe_receiver == null ? null : tmp0_safe_receiver.o7u_1, vertexData.o7u_1)) {
      var tmp1_safe_receiver = this.b85_1;
      if (tmp1_safe_receiver == null)
        null;
      else {
        var tmp$ret$0;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        this.o8a(tmp1_safe_receiver);
        tmp$ret$0 = Unit_getInstance();
      }
      this.b85_1 = vertexData;
      this.p8a(vertexData);
    } else {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      var tmp3_fastForEach = vertexData.o7u_1;
      var tmp$ret$3;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$2;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp0_unsafeCast;
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$3 = tmp$ret$2;
      var array = tmp$ret$3;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.draw.<anonymous>' call
        var tmp$ret$7;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$5;
        // Inline function 'korlibs.datastructure.get' call
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        var tmp1_get = tmp0;
        var tmp$ret$4;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$4 = array;
        tmp$ret$5 = tmp$ret$4[tmp1_get];
        var tmp2_unsafeCast = tmp$ret$5;
        var tmp$ret$6;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$6 = tmp2_unsafeCast;
        tmp$ret$7 = tmp$ret$6;
        var tmp4__anonymous__pkmkx7 = tmp$ret$7;
        bindBuffer$default(this, tmp4__anonymous__pkmkx7.s7u_1, AGBufferKind_VERTEX_getInstance(), true);
      }
    }
    useProgram(this, program);
    this.q8a(uniformBlocks, textureUnits, program, frameBuffer);
    if (!(this.c85_1 === blending)) {
      this.c85_1 = blending;
      // Inline function 'korlibs.kgl.KmlGl.enableDisable' call
      Companion_getInstance_68();
      var tmp5_enableDisable = 3042;
      var tmp6_enableDisable = _AGBlending___get_enabled__impl__s7igz6(blending);
      if (gl.t8a(tmp5_enableDisable, tmp6_enableDisable)) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.draw.<anonymous>' call
        gl.r8a(toGl(_AGBlending___get_eqRGB__impl__7pv8oi(blending)), toGl(_AGBlending___get_eqA__impl__2j4ray(blending)));
        gl.s8a(toGl_0(_AGBlending___get_srcRGB__impl__jvh03e(blending)), toGl_0(_AGBlending___get_dstRGB__impl__nz9ogp(blending)), toGl_0(_AGBlending___get_srcA__impl__ftmwxe(blending)), toGl_0(_AGBlending___get_dstA__impl__l7q6tv(blending)));
      }
    }
    this.u8a(depthAndFrontFace);
    this.h8a(colorMask);
    if (!(this.d85_1 === cullFace)) {
      this.d85_1 = cullFace;
      this.v8a(cullFace);
    }
    if (!(this.e85_1 === stencilOpFunc) ? true : !equals(this.f85_1, stencilRef)) {
      this.e85_1 = stencilOpFunc;
      this.f85_1 = stencilRef;
      if (_AGStencilOpFunc___get_enabled__impl__hxq8wy(stencilOpFunc)) {
        Companion_getInstance_68();
        gl.x8a(2960);
        if ((_AGStencilOpFunc___get_compareModeFront__impl__uctsey(stencilOpFunc) === _AGStencilOpFunc___get_compareModeBack__impl__2rklx0(stencilOpFunc) ? _AGStencilReference___get_referenceValueFront__impl__p7smsu(stencilRef) === _AGStencilReference___get_referenceValueBack__impl__mlrbhc(stencilRef) : false) ? _AGStencilReference___get_readMaskFront__impl__oc0s46(stencilRef) === _AGStencilReference___get_readMaskBack__impl__lwtaqk(stencilRef) : false) {
          gl.z8a(toGl_1(_AGStencilOpFunc___get_compareModeFront__impl__uctsey(stencilOpFunc)), _AGStencilReference___get_referenceValueFront__impl__p7smsu(stencilRef), _AGStencilReference___get_readMaskFront__impl__oc0s46(stencilRef));
        } else {
          Companion_getInstance_68();
          gl.y8a(1028, toGl_1(_AGStencilOpFunc___get_compareModeFront__impl__uctsey(stencilOpFunc)), _AGStencilReference___get_referenceValueFront__impl__p7smsu(stencilRef), _AGStencilReference___get_readMaskFront__impl__oc0s46(stencilRef));
          Companion_getInstance_68();
          gl.y8a(1029, toGl_1(_AGStencilOpFunc___get_compareModeBack__impl__2rklx0(stencilOpFunc)), _AGStencilReference___get_referenceValueBack__impl__mlrbhc(stencilRef), _AGStencilReference___get_readMaskBack__impl__lwtaqk(stencilRef));
        }
        if ((_AGStencilOpFunc___get_actionOnDepthFailFront__impl__vuqmyq(stencilOpFunc) === _AGStencilOpFunc___get_actionOnDepthFailBack__impl__3tna28(stencilOpFunc) ? _AGStencilOpFunc___get_actionOnDepthPassStencilFailFront__impl__kl5ndh(stencilOpFunc) === _AGStencilOpFunc___get_actionOnDepthPassStencilFailBack__impl__4qprmh(stencilOpFunc) : false) ? _AGStencilOpFunc___get_actionOnBothPassFront__impl__aehsgx(stencilOpFunc) === _AGStencilOpFunc___get_actionOnBothPassBack__impl__i5t9l9(stencilOpFunc) : false) {
          gl.b8b(toGl_2(_AGStencilOpFunc___get_actionOnDepthFailFront__impl__vuqmyq(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnDepthPassStencilFailFront__impl__kl5ndh(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnBothPassFront__impl__aehsgx(stencilOpFunc)));
        } else {
          Companion_getInstance_68();
          gl.a8b(1028, toGl_2(_AGStencilOpFunc___get_actionOnDepthFailFront__impl__vuqmyq(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnDepthPassStencilFailFront__impl__kl5ndh(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnBothPassFront__impl__aehsgx(stencilOpFunc)));
          Companion_getInstance_68();
          gl.a8b(1029, toGl_2(_AGStencilOpFunc___get_actionOnDepthFailBack__impl__3tna28(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnDepthPassStencilFailBack__impl__4qprmh(stencilOpFunc)), toGl_2(_AGStencilOpFunc___get_actionOnBothPassBack__impl__i5t9l9(stencilOpFunc)));
        }
        if (_AGStencilReference___get_writeMaskFront__impl__cqdpbj(stencilRef) === _AGStencilReference___get_writeMaskBack__impl__mths73(stencilRef)) {
          gl.l8a(_AGStencilReference___get_writeMaskFront__impl__cqdpbj(stencilRef));
        } else {
          Companion_getInstance_68();
          gl.c8b(1028, _AGStencilReference___get_writeMaskFront__impl__cqdpbj(stencilRef));
          Companion_getInstance_68();
          gl.c8b(1029, _AGStencilReference___get_writeMaskBack__impl__mths73(stencilRef));
        }
      } else {
        Companion_getInstance_68();
        gl.w8a(2960);
        gl.l8a(0);
      }
    }
    var tmp2_safe_receiver = indices;
    if (tmp2_safe_receiver == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.graphics.gl.AGOpengl.draw.<anonymous>' call
      tmp$ret$8 = bindBuffer$default(this, tmp2_safe_receiver, AGBufferKind_INDEX_getInstance());
      tmp$ret$9 = tmp$ret$8;
    }
    var indexType_0 = !(indices == null) ? indexType : Companion_getInstance_54().z7l_1;
    if (!(indexType_0 === Companion_getInstance_54().z7l_1))
      if (!(instances === 1)) {
        gl.g8b(toGl_3(drawType), vertexCount, toGl_4(indexType_0), drawOffset, instances);
      } else {
        gl.f8b(toGl_3(drawType), vertexCount, toGl_4(indexType_0), drawOffset);
      }
     else if (!(instances === 1)) {
      gl.e8b(toGl_3(drawType), drawOffset, vertexCount, instances);
    } else {
      gl.d8b(toGl_3(drawType), drawOffset, vertexCount);
    }
  };
  protoOf(AGOpengl).e86 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = glslConfig$factory();
    tmp$ret$0 = this.a85_1.f1();
    return tmp$ret$0;
  };
  protoOf(AGOpengl).t7l = function () {
    var gl = this.u84_1;
    var tmp0_safe_receiver = this.v84_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.k8b();
    }
    gl.l8b(this.o7l_1);
    resetObjects(this);
    var tmp = this;
    Companion_getInstance_68();
    tmp.l85_1 = getIntegerv(gl, 36006);
    Companion_getInstance_68();
    gl.o89(33984);
    if (_GLVariant___get_supportTextureLevel__impl__3yeu62(gl.p89())) {
      var tmp$ret$0;
      // Inline function 'korlibs.kgl.KmlGl.TEXTURE_2D' call
      Companion_getInstance_68();
      tmp$ret$0 = 3553;
      var tmp_0 = tmp$ret$0;
      Companion_getInstance_68();
      gl.n89(tmp_0, 33084, 0);
      var tmp$ret$1;
      // Inline function 'korlibs.kgl.KmlGl.TEXTURE_2D' call
      Companion_getInstance_68();
      tmp$ret$1 = 3553;
      var tmp_1 = tmp$ret$1;
      Companion_getInstance_68();
      gl.n89(tmp_1, 33085, 0);
    }
  };
  protoOf(AGOpengl).m7g = function () {
    var tmp0_safe_receiver = this.b85_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      this.o8a(tmp0_safe_receiver);
      tmp$ret$0 = Unit_getInstance();
    }
    this.b85_1 = null;
    this.f8a(this.n7l_1.h7n_1, this.n7l_1.u7n());
    var tmp1_safe_receiver = this.v84_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      tmp1_safe_receiver.m8b();
    }
  };
  protoOf(AGOpengl).r7l = function () {
    var tmp0_safe_receiver = this.b85_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      this.o8a(tmp0_safe_receiver);
      tmp$ret$0 = Unit_getInstance();
    }
    this.b85_1 = null;
    this.u84_1.ui();
    deletePendingObjects(this);
  };
  protoOf(AGOpengl).v8a = function (face) {
    Companion_getInstance_68();
    if (this.u84_1.t8a(2884, _AGCullFace___get_ordinal__impl__jd1wps(face) > _AGCullFace___get_ordinal__impl__jd1wps(Companion_getInstance_53().d7n_1))) {
      this.u84_1.n8b(toGl_5(face));
    }
  };
  protoOf(AGOpengl).o88 = function (_this__u8e3s4, block) {
    if (!(_this__u8e3s4.d7p_1 === _this__u8e3s4.e7p_1)) {
      _this__u8e3s4.d7p_1 = _this__u8e3s4.e7p_1;
      block(_this__u8e3s4);
    }
  };
  protoOf(AGOpengl).o8a = function (vao) {
    if (this.p85_1 ? this.u84_1.j7l() : false) {
      this.u84_1.g8c(0);
    } else {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      var tmp3_fastForEach = vao.o7u_1;
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      var array = tmp$ret$2;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.vaoUnuse.<anonymous>' call
        var tmp$ret$6;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$4;
        // Inline function 'korlibs.datastructure.get' call
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        var tmp1_get = tmp0;
        var tmp$ret$3;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = array;
        tmp$ret$4 = tmp$ret$3[tmp1_get];
        var tmp2_unsafeCast = tmp$ret$4;
        var tmp$ret$5;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$5 = tmp2_unsafeCast;
        tmp$ret$6 = tmp$ret$5;
        var tmp4__anonymous__pkmkx7 = tmp$ret$6;
        // Inline function 'korlibs.datastructure.iterators.fastForEach' call
        var tmp$ret$7;
        // Inline function 'korlibs.graphics.shader.attributes' call
        var tmp0__get_attributes__t2d10m = tmp4__anonymous__pkmkx7.r7u_1;
        tmp$ret$7 = tmp0__get_attributes__t2d10m.y7z_1;
        var tmp1_fastForEach = tmp$ret$7;
        var n_0 = 0;
        while (n_0 < tmp1_fastForEach.l()) {
          // Inline function 'korlibs.graphics.gl.AGOpengl.vaoUnuse.<anonymous>.<anonymous>' call
          var tmp0_0 = n_0;
          n_0 = tmp0_0 + 1 | 0;
          var tmp2__anonymous__z9zvc9 = tmp1_fastForEach.n(tmp0_0);
          if (tmp2__anonymous__z9zvc9.b8c_1) {
            var loc = tmp2__anonymous__z9zvc9.d8c_1;
            if (loc >= 0) {
              if (!(tmp2__anonymous__z9zvc9.c8c_1 === 0)) {
                this.u84_1.e8c(loc, 0);
              }
              this.u84_1.f8c(loc);
            }
          }
        }
      }
    }
  };
  protoOf(AGOpengl).p8a = function (vao) {
    var gl = this.u84_1;
    if (this.p85_1 ? gl.j7l() : false) {
      var vaoGl = get_gl(vao);
      if (vao.p7u_1) {
        if (!(this.o85_1 === this.o7l_1)) {
          this.n85_1 = genVertexArray(gl);
          this.o85_1 = this.o7l_1;
          if (this.n85_1 <= 0)
            this.p85_1 = false;
        }
        gl.g8c(this.n85_1);
        this.h8c(vao);
      } else {
        if (!(vaoGl.i84_1 === this.o7l_1)) {
          vaoGl.i84_1 = this.o7l_1;
          vaoGl.h84_1 = genVertexArray(gl);
          if (vaoGl.h84_1 <= 0)
            this.p85_1 = false;
          gl.g8c(vaoGl.h84_1);
          this.h8c(vao);
        }
        gl.g8c(vaoGl.h84_1);
        this.i8c(vao, this.p85_1);
      }
    } else {
      this.h8c(vao);
    }
  };
  protoOf(AGOpengl).i8c = function (vao, updateBuffersOnly) {
    var gl = this.u84_1;
    if (updateBuffersOnly) {
      // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
      var tmp3_fastForEach = vao.o7u_1;
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      var array = tmp$ret$2;
      var n = 0;
      while (n < array.length) {
        // Inline function 'korlibs.graphics.gl.AGOpengl._vaoUse.<anonymous>' call
        var tmp$ret$6;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$4;
        // Inline function 'korlibs.datastructure.get' call
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        var tmp1_get = tmp0;
        var tmp$ret$3;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = array;
        tmp$ret$4 = tmp$ret$3[tmp1_get];
        var tmp2_unsafeCast = tmp$ret$4;
        var tmp$ret$5;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$5 = tmp2_unsafeCast;
        tmp$ret$6 = tmp$ret$5;
        var tmp4__anonymous__pkmkx7 = tmp$ret$6;
        bindBuffer$default(this, tmp4__anonymous__pkmkx7.s7u_1, AGBufferKind_VERTEX_getInstance(), VOID, VOID, this.q85_1);
      }
      return Unit_getInstance();
    }
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    var tmp8_fastForEach = vao.o7u_1;
    var tmp$ret$9;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$8;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp5_unsafeCast = tmp8_fastForEach.c1d_1;
    var tmp$ret$7;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$7 = tmp5_unsafeCast;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var array_0 = tmp$ret$9;
    var n_0 = 0;
    while (n_0 < array_0.length) {
      // Inline function 'korlibs.graphics.gl.AGOpengl._vaoUse.<anonymous>' call
      var tmp$ret$13;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$11;
      // Inline function 'korlibs.datastructure.get' call
      var tmp0_0 = n_0;
      n_0 = tmp0_0 + 1 | 0;
      var tmp6_get = tmp0_0;
      var tmp$ret$10;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$10 = array_0;
      tmp$ret$11 = tmp$ret$10[tmp6_get];
      var tmp7_unsafeCast = tmp$ret$11;
      var tmp$ret$12;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$12 = tmp7_unsafeCast;
      tmp$ret$13 = tmp$ret$12;
      var tmp9__anonymous__1b7cvk = tmp$ret$13;
      var vertices = tmp9__anonymous__1b7cvk.s7u_1;
      var vertexLayout = tmp9__anonymous__1b7cvk.r7u_1;
      var tmp$ret$14;
      // Inline function 'korlibs.graphics.shader.attributes' call
      tmp$ret$14 = vertexLayout.y7z_1;
      var vattrs = tmp$ret$14;
      var tmp$ret$15;
      // Inline function 'korlibs.graphics.shader.attributePositions' call
      tmp$ret$15 = vertexLayout.d80_1;
      var vattrspos = tmp$ret$15;
      bindBuffer$default(this, vertices, AGBufferKind_VERTEX_getInstance(), VOID, VOID, this.q85_1);
      var totalSize = vertexLayout.f80_1;
      var inductionVariable = 0;
      var last = vattrspos.l() - 1 | 0;
      if (inductionVariable <= last)
        $l$loop: do {
          var n_1 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var att = vattrs.n(n_1);
          if (!att.b8c_1)
            continue $l$loop;
          var off = vattrspos.n(n_1);
          var loc = att.d8c_1;
          var glElementType = toGl_7(att.c7d());
          var elementCount = att.c7d().z88_1;
          if (loc >= 0) {
            gl.j8c(loc);
            gl.k8c(loc, elementCount, glElementType, att.a8c_1, totalSize, numberToLong(tmp9__anonymous__1b7cvk.t7u_1).b9(toLong(off)));
            if (!(att.c8c_1 === 0)) {
              gl.e8c(loc, att.c8c_1);
            }
          }
        }
         while (inductionVariable <= last);
    }
  };
  protoOf(AGOpengl).h8c = function (vao, updateBuffersOnly, $super) {
    updateBuffersOnly = updateBuffersOnly === VOID ? false : updateBuffersOnly;
    var tmp;
    if ($super === VOID) {
      this.i8c(vao, updateBuffersOnly);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.i8c.call(this, vao, updateBuffersOnly);
    }
    return tmp;
  };
  protoOf(AGOpengl).q8a = function (uniformBlocks, textureUnits, program, frameBuffer) {
    var tmp0_elvis_lhs = this.i85_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var glProgram = tmp;
    // Inline function 'korlibs.graphics.AGTextureUnits.fastForEach' call
    var inductionVariable = 0;
    var last = textureUnits.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.graphics.gl.AGOpengl.uniformsSet.<anonymous>' call
        var tmp0__anonymous__q1qw7t = textureUnits.c7r_1[n];
        var tmp1__anonymous__uwfjfc = AGTextureUnitInfoArray__get_impl_xhvpef(textureUnits.d7r_1, n);
        var tex = tmp0__anonymous__q1qw7t;
        if (equals(frameBuffer.z7r_1, tex)) {
          tex = null;
        }
        var tmp0_safe_receiver = tex;
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.e7p_1;
        var texVersion = tmp1_elvis_lhs == null ? -1 : tmp1_elvis_lhs;
        if ((!(this.j85_1.c7r_1[n] === tex) ? true : !(AGTextureUnitInfoArray__get_impl_xhvpef(this.j85_1.d7r_1, n) === tmp1__anonymous__uwfjfc)) ? true : !(this.k85_1[n] === texVersion)) {
          this.j85_1.e7r(n, tex, tmp1__anonymous__uwfjfc);
          this.k85_1[n] = texVersion;
          selectTextureUnit(this, n);
          if (!(tex == null)) {
            var wrap = _AGTextureUnitInfo___get_wrap__impl__dwp6l(tmp1__anonymous__uwfjfc);
            var linear = _AGTextureUnitInfo___get_linear__impl__jak6ny(tmp1__anonymous__uwfjfc);
            var trilinear = _AGTextureUnitInfo___get_trilinear__impl__hxtqfb(tmp1__anonymous__uwfjfc);
            this.l8c(tex, _AGTextureUnitInfo___get_kind__impl__6xtjoz(tmp1__anonymous__uwfjfc));
            textureUnitParameters(this, tex.a7q(), wrap, this.m8c(tex, linear, trilinear), this.n8c(tex, linear, trilinear), _AGTextureTargetKind___get_dims__impl__k3cmhd(tex.a7q()));
          } else {
            this.l8c(null, Companion_getInstance_50().w7p_1);
          }
        }
      }
       while (inductionVariable < last);
    var glProgramInfo = glProgram.j86_1;
    // Inline function 'korlibs.graphics.shader.UniformBlocksBuffersRef.fastForEachBlock' call
    var inductionVariable_0 = 0;
    var last_0 = uniformBlocks.l();
    if (inductionVariable_0 < last_0)
      $l$loop: do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var tmp1_elvis_lhs_0 = uniformBlocks.o8c_1[n_0];
        var tmp_0;
        if (tmp1_elvis_lhs_0 == null) {
          continue $l$loop;
        } else {
          tmp_0 = tmp1_elvis_lhs_0;
        }
        var block = tmp_0;
        var tmp$ret$0;
        $l$block: {
          // Inline function 'korlibs.graphics.gl.AGOpengl.uniformsSet.<anonymous>' call
          var tmp2__anonymous__z9zvc9 = uniformBlocks.p8c_1[n_0];
          var tmp3__anonymous__ufb84q = uniformBlocks.q8c_1[n_0];
          if (glProgram.j86_1.p86_1.o87()) {
            var buffer = bindBuffer$default(this, tmp2__anonymous__z9zvc9, AGBufferKind_UNIFORM_getInstance());
            Companion_getInstance_68();
            var tmp0_safe_receiver_0 = buffer;
            var tmp1_elvis_lhs_1 = tmp0_safe_receiver_0 == null ? null : tmp0_safe_receiver_0.l88_1;
            this.u84_1.r8c(35345, block.n7s_1.i7u_1, tmp1_elvis_lhs_1 == null ? -1 : tmp1_elvis_lhs_1, imul(tmp3__anonymous__ufb84q, block.q7s_1), block.q7s_1);
            tmp$ret$0 = Unit_getInstance();
            break $l$block;
          }
          var tmp2_elvis_lhs = glProgramInfo.s86_1[block.n7s_1.i7u_1];
          var tmp_1;
          if (tmp2_elvis_lhs == null) {
            var tmp0_error = "Can't find uniform at " + block.n7s_1.i7u_1 + ' for program ' + program + ' for block ' + block;
            throw IllegalStateException_init_$Create$(toString(tmp0_error));
          } else {
            tmp_1 = tmp2_elvis_lhs;
          }
          var ref = tmp_1;
          var bufferMem = ensureNotNull(ensureNotNull(tmp2__anonymous__z9zvc9).v7o_1);
          var currentMem = ref.t8c_1;
          var ublock = ref.s8c_1;
          if (tmp3__anonymous__ufb84q >= 0) {
            if (!arrayequal(bufferMem, tmp3__anonymous__ufb84q, currentMem, 0, ublock.n81())) {
              arraycopy_0(bufferMem, imul(ublock.n81(), tmp3__anonymous__ufb84q), currentMem, 0, ublock.n81());
              // Inline function 'korlibs.datastructure.iterators.fastForEach' call
              var tmp1_fastForEach = ublock.l81();
              var n_1 = 0;
              while (n_1 < tmp1_fastForEach.l()) {
                // Inline function 'korlibs.graphics.gl.AGOpengl.uniformsSet.<anonymous>.<anonymous>' call
                var tmp0 = n_1;
                n_1 = tmp0 + 1 | 0;
                var tmp2__anonymous__z9zvc9_0 = tmp1_fastForEach.n(tmp0);
                writeUniform(this, tmp2__anonymous__z9zvc9_0.h7u_1, glProgramInfo, slice(currentMem, tmp2__anonymous__z9zvc9_0.e7u_1, tmp2__anonymous__z9zvc9_0.e7u_1 + tmp2__anonymous__z9zvc9_0.v88_1 | 0), 'blockUniformSet');
              }
            }
          } else {
            println('ERROR block: ' + block.n7s_1 + ' has an invalid valueIndex=' + tmp3__anonymous__ufb84q);
          }
        }
      }
       while (inductionVariable_0 < last_0);
  };
  protoOf(AGOpengl).m8c = function (_this__u8e3s4, linear, trilinear) {
    var tmp;
    var tmp0_safe_receiver = _this__u8e3s4;
    if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.t7p_1) === true) {
      var tmp_0;
      if (linear) {
        var tmp_1;
        if (trilinear) {
          Companion_getInstance_68();
          tmp_1 = 9987;
        } else {
          Companion_getInstance_68();
          tmp_1 = 9985;
        }
        tmp_0 = tmp_1;
      } else {
        var tmp_2;
        if (trilinear) {
          Companion_getInstance_68();
          tmp_2 = 9986;
        } else {
          Companion_getInstance_68();
          tmp_2 = 9984;
        }
        tmp_0 = tmp_2;
      }
      tmp = tmp_0;
    } else {
      var tmp_3;
      if (linear) {
        Companion_getInstance_68();
        tmp_3 = 9729;
      } else {
        Companion_getInstance_68();
        tmp_3 = 9728;
      }
      tmp = tmp_3;
    }
    return tmp;
  };
  protoOf(AGOpengl).n8c = function (_this__u8e3s4, linear, trilinear) {
    var tmp;
    if (linear) {
      Companion_getInstance_68();
      tmp = 9729;
    } else {
      Companion_getInstance_68();
      tmp = 9728;
    }
    return tmp;
  };
  protoOf(AGOpengl).w8c = function (scissor, frameBuffer, frameBufferInfo) {
    return (equals(scissor, Companion_getInstance_44().b7n_1) ? true : equals(scissor, Companion_getInstance_44().z7m_1)) ? scissor : frameBuffer.y7r_1 ? Companion_getInstance_44().t7v(_AGScissor___get_left__impl__jiavfz(scissor), _AGFrameBufferInfo___get_height__impl__yfespa(frameBufferInfo) - _AGScissor___get_bottom__impl__6wmhqj(scissor) | 0, _AGScissor___get_right__impl__3hpzkc(scissor), _AGFrameBufferInfo___get_height__impl__yfespa(frameBufferInfo) - _AGScissor___get_top__impl__wmf8dv(scissor) | 0) : scissor;
  };
  protoOf(AGOpengl).w7l = function (frameBuffer, frameBufferInfo, x, y, width, height, data, kind) {
    var gl = this.u84_1;
    this.f8a(frameBuffer, frameBufferInfo);
    var region = this.w8c(_AGScissor___init__impl__g8s683_0(x, y, width, height), frameBuffer, frameBufferInfo);
    var tmp0_subject = data;
    var tmp;
    if (isIntArray(tmp0_subject)) {
      tmp = 4;
    } else {
      if (isFloatArray(tmp0_subject)) {
        tmp = 4;
      } else {
        if (isByteArray(tmp0_subject)) {
          tmp = 1;
        } else {
          throw new NotImplementedError();
        }
      }
    }
    var bytesPerPixel = tmp;
    var flipY = frameBuffer.y7r_1;
    var area = imul(width, height);
    var stride = imul(width, bytesPerPixel);
    var tmp$ret$3;
    // Inline function 'korlibs.memory.BufferTemp' call
    var tmp1_BufferTemp = imul(height, stride);
    var tmp$ret$2;
    // Inline function 'kotlin.run' call
    var tmp0_run = allocDirect(Companion_getInstance_7(), tmp1_BufferTemp);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'korlibs.memory.BufferTemp' call
    var tmp$ret$0;
    // Inline function 'kotlin.run' call
    var tmp0_run_0 = allocDirect(Companion_getInstance_7(), stride);
    // Inline function 'kotlin.contracts.contract' call
    var tmp0_subject_0 = kind;
    if (tmp0_subject_0 === Companion_getInstance_56().v7n_1) {
      var tmp_0 = _AGScissor___get_x__impl__pn9kpc(region);
      var tmp_1 = _AGScissor___get_y__impl__36cwcv(region);
      var tmp_2 = _AGScissor___get_width__impl__tn9xsy(region);
      var tmp_3 = _AGScissor___get_height__impl__qag5an(region);
      Companion_getInstance_68();
      Companion_getInstance_68();
      gl.x8c(tmp_0, tmp_1, tmp_2, tmp_3, 6408, 5121, tmp0_run);
    } else if (tmp0_subject_0 === Companion_getInstance_56().w7n_1) {
      var tmp_4 = _AGScissor___get_x__impl__pn9kpc(region);
      var tmp_5 = _AGScissor___get_y__impl__36cwcv(region);
      var tmp_6 = _AGScissor___get_width__impl__tn9xsy(region);
      var tmp_7 = _AGScissor___get_height__impl__qag5an(region);
      Companion_getInstance_68();
      Companion_getInstance_68();
      gl.x8c(tmp_4, tmp_5, tmp_6, tmp_7, 6402, 5126, tmp0_run);
    } else if (tmp0_subject_0 === Companion_getInstance_56().x7n_1) {
      var tmp_8 = _AGScissor___get_x__impl__pn9kpc(region);
      var tmp_9 = _AGScissor___get_y__impl__36cwcv(region);
      var tmp_10 = _AGScissor___get_width__impl__tn9xsy(region);
      var tmp_11 = _AGScissor___get_height__impl__qag5an(region);
      Companion_getInstance_68();
      Companion_getInstance_68();
      gl.x8c(tmp_8, tmp_9, tmp_10, tmp_11, 6401, 5121, tmp0_run);
    }
    var tmp1_subject = data;
    if (isIntArray(tmp1_subject)) {
      getArrayInt32(tmp0_run, 0, data, VOID, area);
    } else {
      if (isFloatArray(tmp1_subject)) {
        getArrayFloat32(tmp0_run, 0, data, VOID, area);
      } else {
        if (isByteArray(tmp1_subject)) {
          getArrayInt8(tmp0_run, 0, data, VOID, area);
        } else {
          throw new NotImplementedError();
        }
      }
    }
    var tmp_12;
    if (flipY) {
      var tmp2_subject = data;
      var tmp_13;
      if (isIntArray(tmp2_subject)) {
        tmp_13 = Bitmap32_init_$Create$(width, height, _RgbaArray___init__impl__oib4y4(data));
      } else {
        if (isFloatArray(tmp2_subject)) {
          tmp_13 = new FloatBitmap32(width, height, data);
        } else {
          if (isByteArray(tmp2_subject)) {
            tmp_13 = new Bitmap8(width, height, data);
          } else {
            throw new NotImplementedError();
          }
        }
      }
      tmp_13.v4y();
      tmp_12 = Unit_getInstance();
    }
    tmp$ret$0 = tmp_12;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
  };
  protoOf(AGOpengl).l8c = function (tex, target) {
    var gl = this.u84_1;
    var tmp0_safe_receiver = tex;
    var glTex = tmp0_safe_receiver == null ? null : _get_gl__ndc1ty_1(tmp0_safe_receiver, this);
    var tmp = toGl_8(target);
    var tmp1_safe_receiver = glTex;
    var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.z89_1;
    gl.y8c(tmp, tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs);
    var tmp3_safe_receiver = tex;
    var texBitmap = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.s7p_1;
    if (!(glTex == null) ? !(texBitmap == null) : false) {
      if (!(glTex.a8a_1 === texBitmap.c4r_1) ? true : !(glTex.b8a_1 === this.o7l_1)) {
        glTex.a8a_1 = texBitmap.c4r_1;
        glTex.b8a_1 = this.o7l_1;
        tex.g7p();
      }
      this.o88(tex, AGOpengl$textureBind$lambda(tex, target, gl, this, glTex));
    }
  };
  protoOf(AGOpengl).f8a = function (frameBuffer, info) {
    var gl = this.u84_1;
    if (!(this.v85_1 === _AGFrameBufferInfo___get_size__impl__uw80y4(info))) {
      gl.z8c(0, 0, _AGFrameBufferInfo___get_width__impl__yhp94z(info), _AGFrameBufferInfo___get_height__impl__yfespa(info));
    }
    if (frameBuffer.y7r_1) {
      if (!(this.w85_1 === this.l85_1)) {
        this.w85_1 = this.l85_1;
        Companion_getInstance_68();
        gl.a8d(36160, this.l85_1);
      }
      return Unit_getInstance();
    }
    var fb = _get_gl__ndc1ty_0(frameBuffer, this);
    var tex = fb.d8d_1.z7r_1;
    var doMsaa = false;
    if (!(fb.g8d_1 === info)) {
      fb.g8d_1 = info;
      var tmp;
      if (_AGFrameBufferInfo___get_hasStencilAndDepth__impl__aauqdj(info)) {
        Companion_getInstance_68();
        tmp = 34041;
      } else if (_AGFrameBufferInfo___get_hasStencil__impl__hjoes3(info)) {
        Companion_getInstance_68();
        tmp = 36168;
      } else if (_AGFrameBufferInfo___get_hasDepth__impl__9knjmc(info)) {
        Companion_getInstance_68();
        tmp = 6402;
      } else {
        tmp = 0;
      }
      var internalFormat = tmp;
      var tmp_0;
      if (doMsaa) {
        Companion_getInstance_68();
        tmp_0 = 37120;
      } else {
        Companion_getInstance_68();
        tmp_0 = 3553;
      }
      var texTarget = tmp_0;
      tex.s7p_1 = new NullBitmap(_AGFrameBufferInfo___get_width__impl__yhp94z(info), _AGFrameBufferInfo___get_height__impl__yfespa(info), false);
      // Inline function 'korlibs.graphics.gl.AGOpengl.selectTextureUnitTemp' call
      var tmp0_selectTextureUnitTemp = this.t85_1;
      var old = selectTextureUnit(this, tmp0_selectTextureUnitTemp);
      try {
        // Inline function 'korlibs.graphics.gl.AGOpengl.bindFrameBuffer.<anonymous>' call
        this.l8c(tex, Companion_getInstance_50().w7p_1);
        var tmp_1 = Companion_getInstance_50().w7p_1;
        var tmp_2 = Companion_getInstance_57().v7q_1;
        Companion_getInstance_68();
        Companion_getInstance_68();
        textureUnitParameters(this, tmp_1, tmp_2, 9729, 9729, 2);
      }finally {
        {
          this.l8c(null, Companion_getInstance_50().w7p_1);
        }
        selectTextureUnit(this, old);
      }
      Companion_getInstance_68();
      gl.h8d(36161, fb.e8d_1);
      if (!(internalFormat === 0)) {
        Companion_getInstance_68();
        gl.i8d(36161, internalFormat, _AGFrameBufferInfo___get_width__impl__yhp94z(info), _AGFrameBufferInfo___get_height__impl__yfespa(info));
      }
      Companion_getInstance_68();
      gl.h8d(36161, 0);
      Companion_getInstance_68();
      gl.a8d(36160, fb.f8d_1);
      Companion_getInstance_68();
      Companion_getInstance_68();
      Companion_getInstance_68();
      gl.j8d(36160, 36064, 3553, _get_gl__ndc1ty_1(fb.d8d_1.z7r_1, this).z89_1, 0);
      if (!(internalFormat === 0)) {
        Companion_getInstance_68();
        var tmp_3;
        if (_AGFrameBufferInfo___get_hasStencilAndDepth__impl__aauqdj(info)) {
          Companion_getInstance_68();
          tmp_3 = 33306;
        } else if (_AGFrameBufferInfo___get_hasStencil__impl__hjoes3(info)) {
          Companion_getInstance_68();
          tmp_3 = 36128;
        } else if (_AGFrameBufferInfo___get_hasDepth__impl__9knjmc(info)) {
          Companion_getInstance_68();
          tmp_3 = 36096;
        } else {
          tmp_3 = 0;
        }
        var tmp_4 = tmp_3;
        Companion_getInstance_68();
        gl.k8d(36160, tmp_4, 36161, fb.e8d_1);
      } else {
        Companion_getInstance_68();
        Companion_getInstance_68();
        Companion_getInstance_68();
        gl.k8d(36160, 36128, 36161, 0);
        Companion_getInstance_68();
        Companion_getInstance_68();
        Companion_getInstance_68();
        gl.k8d(36160, 36096, 36161, 0);
      }
    }
    if (!(this.w85_1 === fb.f8d_1)) {
      this.w85_1 = fb.f8d_1;
      Companion_getInstance_68();
      gl.a8d(36160, fb.f8d_1);
    }
  };
  protoOf(AGOpengl).u8a = function (renderState) {
    var gl = this.u84_1;
    if (!(this.h85_1 === renderState)) {
      this.h85_1 = renderState;
      gl.l8d(toGl_10(_AGDepthAndFrontFace___get_frontFace__impl__w07k4s(renderState)));
      gl.j8a(_AGDepthAndFrontFace___get_depthMask__impl__ouijn(renderState));
      gl.m8d(_AGDepthAndFrontFace___get_depthNear__impl__cir3a1(renderState), _AGDepthAndFrontFace___get_depthFar__impl__g0cno8(renderState));
      // Inline function 'korlibs.kgl.KmlGl.enableDisable' call
      Companion_getInstance_68();
      var tmp0_enableDisable = 2929;
      var tmp1_enableDisable = !(_AGDepthAndFrontFace___get_depthFunc__impl__8fo7zp(renderState) === Companion_getInstance_51().y7u_1);
      if (gl.t8a(tmp0_enableDisable, tmp1_enableDisable)) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.setDepthAndFrontFace.<anonymous>' call
        gl.n8d(toGl_1(_AGDepthAndFrontFace___get_depthFunc__impl__8fo7zp(renderState)));
      }
    }
  };
  protoOf(AGOpengl).h8a = function (colorMask) {
    var gl = this.u84_1;
    if (!(this.g85_1 === colorMask)) {
      this.g85_1 = colorMask;
      gl.o8d(_AGColorMask___get_red__impl__opo1fo(colorMask), _AGColorMask___get_green__impl__d3m1w2(colorMask), _AGColorMask___get_blue__impl__42l0s1(colorMask), _AGColorMask___get_alpha__impl__xmrqzb(colorMask));
    }
  };
  protoOf(AGOpengl).g8a = function (scissor, frameBuffer, frameBufferInfo) {
    var gl = this.u84_1;
    var scissor_0 = this.w8c(scissor, frameBuffer, frameBufferInfo);
    if (!equals(this.m85_1, scissor_0)) {
      this.m85_1 = scissor_0;
      // Inline function 'korlibs.kgl.KmlGl.enableDisable' call
      Companion_getInstance_68();
      var tmp0_enableDisable = 3089;
      var tmp1_enableDisable = !equals(scissor_0, Companion_getInstance_44().b7n_1);
      if (gl.t8a(tmp0_enableDisable, tmp1_enableDisable)) {
        // Inline function 'korlibs.graphics.gl.AGOpengl.setScissorState.<anonymous>' call
        gl.p8d(_AGScissor___get_x__impl__pn9kpc(scissor_0), _AGScissor___get_y__impl__36cwcv(scissor_0), _AGScissor___get_width__impl__tn9xsy(scissor_0), _AGScissor___get_height__impl__qag5an(scissor_0));
      }
    }
  };
  function gl$delegate$lambda($this$$receiver) {
    _init_properties_AGOpengl_kt__dtzqx7();
    return new GLVAO($this$$receiver);
  }
  function gl$factory() {
    return getPropertyCallableRef('gl', 1, KMutableProperty1, function (receiver) {
      return get_gl(receiver);
    }, function (receiver, value) {
      return set_gl(receiver, value);
    });
  }
  function gl$factory_0() {
    return getPropertyCallableRef('gl', 1, KMutableProperty1, function (receiver) {
      return get_gl(receiver);
    }, function (receiver, value) {
      return set_gl(receiver, value);
    });
  }
  function glslConfig$factory() {
    return getPropertyCallableRef('glslConfig', 1, KProperty1, function (receiver) {
      return receiver.e86();
    }, null);
  }
  var properties_initialized_AGOpengl_kt_5em8g9;
  function _init_properties_AGOpengl_kt__dtzqx7() {
    if (properties_initialized_AGOpengl_kt_5em8g9) {
    } else {
      properties_initialized_AGOpengl_kt_5em8g9 = true;
      ENABLE_UNIFORM_BLOCKS = !(Companion_getInstance_8().p1b('DISABLE_UNIFORM_BLOCKS') === 'true');
      ENABLE_VERTEX_ARRAY_OBJECTS = !(Companion_getInstance_8().p1b('DISABLE_VERTEX_ARRAY_OBJECTS') === 'true');
      gl$delegate = new PropertyThis(VOID, gl$delegate$lambda);
    }
  }
  function fromGl(_this__u8e3s4, value) {
    var tmp0_subject = value;
    var tmp;
    Companion_getInstance_68();
    if (tmp0_subject === 3553) {
      tmp = Companion_getInstance_50().w7p_1;
    } else {
      Companion_getInstance_68();
      if (tmp0_subject === 32879) {
        tmp = Companion_getInstance_50().x7p_1;
      } else {
        Companion_getInstance_68();
        if (tmp0_subject === 34067) {
          tmp = Companion_getInstance_50().y7p_1;
        } else {
          Companion_getInstance_68();
          if (tmp0_subject === 36197) {
            tmp = Companion_getInstance_50().z7p_1;
          } else {
            Companion_getInstance_68();
            if (tmp0_subject === 34069) {
              tmp = Companion_getInstance_50().y7p_1;
            } else {
              Companion_getInstance_68();
              if (tmp0_subject === 34070) {
                tmp = Companion_getInstance_50().y7p_1;
              } else {
                Companion_getInstance_68();
                if (tmp0_subject === 34071) {
                  tmp = Companion_getInstance_50().y7p_1;
                } else {
                  Companion_getInstance_68();
                  if (tmp0_subject === 34072) {
                    tmp = Companion_getInstance_50().y7p_1;
                  } else {
                    Companion_getInstance_68();
                    if (tmp0_subject === 34073) {
                      tmp = Companion_getInstance_50().y7p_1;
                    } else {
                      Companion_getInstance_68();
                      if (tmp0_subject === 34074) {
                        tmp = Companion_getInstance_50().y7p_1;
                      } else {
                        var tmp0_TODO = 'Unknown TextureTargetKind: ' + value;
                        throw new NotImplementedError('An operation is not implemented: ' + tmp0_TODO);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  }
  function toGl(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_49().w7x_1) {
      Companion_getInstance_68();
      tmp = 32774;
    } else if (tmp0_subject === Companion_getInstance_49().x7x_1) {
      Companion_getInstance_68();
      tmp = 32778;
    } else if (tmp0_subject === Companion_getInstance_49().y7x_1) {
      Companion_getInstance_68();
      tmp = 32779;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function toGl_0(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_48().j7x_1) {
      Companion_getInstance_68();
      tmp = 772;
    } else if (tmp0_subject === Companion_getInstance_48().k7x_1) {
      Companion_getInstance_68();
      tmp = 774;
    } else if (tmp0_subject === Companion_getInstance_48().l7x_1) {
      Companion_getInstance_68();
      tmp = 1;
    } else if (tmp0_subject === Companion_getInstance_48().m7x_1) {
      Companion_getInstance_68();
      tmp = 773;
    } else if (tmp0_subject === Companion_getInstance_48().n7x_1) {
      Companion_getInstance_68();
      tmp = 775;
    } else if (tmp0_subject === Companion_getInstance_48().o7x_1) {
      Companion_getInstance_68();
      tmp = 771;
    } else if (tmp0_subject === Companion_getInstance_48().p7x_1) {
      Companion_getInstance_68();
      tmp = 769;
    } else if (tmp0_subject === Companion_getInstance_48().q7x_1) {
      Companion_getInstance_68();
      tmp = 770;
    } else if (tmp0_subject === Companion_getInstance_48().r7x_1) {
      Companion_getInstance_68();
      tmp = 768;
    } else if (tmp0_subject === Companion_getInstance_48().s7x_1) {
      Companion_getInstance_68();
      tmp = 0;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function toGl_1(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_51().y7u_1) {
      Companion_getInstance_68();
      tmp = 519;
    } else if (tmp0_subject === Companion_getInstance_51().z7u_1) {
      Companion_getInstance_68();
      tmp = 514;
    } else if (tmp0_subject === Companion_getInstance_51().a7v_1) {
      Companion_getInstance_68();
      tmp = 516;
    } else if (tmp0_subject === Companion_getInstance_51().b7v_1) {
      Companion_getInstance_68();
      tmp = 518;
    } else if (tmp0_subject === Companion_getInstance_51().c7v_1) {
      Companion_getInstance_68();
      tmp = 513;
    } else if (tmp0_subject === Companion_getInstance_51().d7v_1) {
      Companion_getInstance_68();
      tmp = 515;
    } else if (tmp0_subject === Companion_getInstance_51().e7v_1) {
      Companion_getInstance_68();
      tmp = 512;
    } else if (tmp0_subject === Companion_getInstance_51().f7v_1) {
      Companion_getInstance_68();
      tmp = 517;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function toGl_2(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_52().g7v_1) {
      Companion_getInstance_68();
      tmp = 7683;
    } else if (tmp0_subject === Companion_getInstance_52().h7v_1) {
      Companion_getInstance_68();
      tmp = 34056;
    } else if (tmp0_subject === Companion_getInstance_52().i7v_1) {
      Companion_getInstance_68();
      tmp = 7682;
    } else if (tmp0_subject === Companion_getInstance_52().j7v_1) {
      Companion_getInstance_68();
      tmp = 34055;
    } else if (tmp0_subject === Companion_getInstance_52().k7v_1) {
      Companion_getInstance_68();
      tmp = 5386;
    } else if (tmp0_subject === Companion_getInstance_52().l7v_1) {
      Companion_getInstance_68();
      tmp = 7680;
    } else if (tmp0_subject === Companion_getInstance_52().m7v_1) {
      Companion_getInstance_68();
      tmp = 7681;
    } else if (tmp0_subject === Companion_getInstance_52().n7v_1) {
      Companion_getInstance_68();
      tmp = 0;
    } else {
      Companion_getInstance_68();
      tmp = 0;
    }
    return tmp;
  }
  function toGl_3(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_46().t7w_1) {
      Companion_getInstance_68();
      tmp = 0;
    } else if (tmp0_subject === Companion_getInstance_46().u7w_1) {
      Companion_getInstance_68();
      tmp = 3;
    } else if (tmp0_subject === Companion_getInstance_46().v7w_1) {
      Companion_getInstance_68();
      tmp = 2;
    } else if (tmp0_subject === Companion_getInstance_46().w7w_1) {
      Companion_getInstance_68();
      tmp = 1;
    } else if (tmp0_subject === Companion_getInstance_46().y7w_1) {
      Companion_getInstance_68();
      tmp = 5;
    } else if (tmp0_subject === Companion_getInstance_46().z7w_1) {
      Companion_getInstance_68();
      tmp = 6;
    } else if (tmp0_subject === Companion_getInstance_46().x7w_1) {
      Companion_getInstance_68();
      tmp = 4;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function toGl_4(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_54().z7l_1) {
      Companion_getInstance_68();
      tmp = 0;
    } else if (tmp0_subject === Companion_getInstance_54().a7m_1) {
      Companion_getInstance_68();
      tmp = 5121;
    } else if (tmp0_subject === Companion_getInstance_54().b7m_1) {
      Companion_getInstance_68();
      tmp = 5123;
    } else if (tmp0_subject === Companion_getInstance_54().c7m_1) {
      Companion_getInstance_68();
      tmp = 5125;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function toGl_5(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_53().g7n_1) {
      Companion_getInstance_68();
      tmp = 1032;
    } else if (tmp0_subject === Companion_getInstance_53().e7n_1) {
      Companion_getInstance_68();
      tmp = 1028;
    } else if (tmp0_subject === Companion_getInstance_53().f7n_1) {
      Companion_getInstance_68();
      tmp = 1029;
    } else {
      var tmp0_TODO = 'Invalid AGCullFace(' + new AGCullFace(_this__u8e3s4) + ')';
      throw new NotImplementedError('An operation is not implemented: ' + tmp0_TODO);
    }
    return tmp;
  }
  function toGl_6(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        Companion_getInstance_68();
        tmp = 34963;
        break;
      case 1:
        Companion_getInstance_68();
        tmp = 34962;
        break;
      case 2:
        Companion_getInstance_68();
        tmp = 35345;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function toGl_7(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4.y88_1;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        Companion_getInstance_68();
        tmp = 35670;
        break;
      case 1:
        Companion_getInstance_68();
        tmp = 5120;
        break;
      case 2:
        Companion_getInstance_68();
        tmp = 5121;
        break;
      case 3:
        Companion_getInstance_68();
        tmp = 5122;
        break;
      case 4:
        Companion_getInstance_68();
        tmp = 5123;
        break;
      case 5:
        Companion_getInstance_68();
        tmp = 5125;
        break;
      case 6:
        Companion_getInstance_68();
        tmp = 5126;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function toGl_8(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_50().w7p_1) {
      Companion_getInstance_68();
      tmp = 3553;
    } else if (tmp0_subject === Companion_getInstance_50().x7p_1) {
      Companion_getInstance_68();
      tmp = 32879;
    } else if (tmp0_subject === Companion_getInstance_50().y7p_1) {
      Companion_getInstance_68();
      tmp = 34067;
    } else if (tmp0_subject === Companion_getInstance_50().z7p_1) {
      Companion_getInstance_68();
      tmp = 36197;
    } else {
      tmp = _AGTextureTargetKind___get_ordinal__impl__gjzc5p(_this__u8e3s4);
    }
    return tmp;
  }
  function toGl_9(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_57().v7q_1) {
      Companion_getInstance_68();
      tmp = 33071;
    } else if (tmp0_subject === Companion_getInstance_57().w7q_1) {
      Companion_getInstance_68();
      tmp = 10497;
    } else if (tmp0_subject === Companion_getInstance_57().x7q_1) {
      Companion_getInstance_68();
      tmp = 33648;
    } else {
      Companion_getInstance_68();
      tmp = 33071;
    }
    return tmp;
  }
  function toGl_10(_this__u8e3s4) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject === Companion_getInstance_59().p7y_1) {
      Companion_getInstance_68();
      tmp = 2305;
    } else if (tmp0_subject === Companion_getInstance_59().r7y_1) {
      Companion_getInstance_68();
      tmp = 2304;
    } else if (tmp0_subject === Companion_getInstance_59().q7y_1) {
      Companion_getInstance_68();
      tmp = 2305;
    } else {
      get_unreachable();
    }
    return tmp;
  }
  function GLGlobalState(gl, ag) {
    this.v87_1 = gl;
    this.w87_1 = ag;
    this.x87_1 = korAtomic(0);
    this.y87_1 = korAtomic(0);
    this.z87_1 = korAtomic_0(new Long(0, 0));
    this.a88_1 = korAtomic(0);
    this.b88_1 = korAtomic(0);
    this.c88_1 = korAtomic_0(new Long(0, 0));
    this.d88_1 = new Lock();
    this.e88_1 = fastArrayListOf([]);
  }
  function GLBaseProgram(globalState, programInfo) {
    GLBaseObject.call(this, globalState);
    this.j86_1 = programInfo;
  }
  protoOf(GLBaseProgram).h88 = function () {
    this.j86_1.q8d(this.g88_1);
  };
  protoOf(GLBaseProgram).k86 = function () {
    this.j86_1.r8d(this.g88_1);
  };
  function GLBuffer(state) {
    GLBaseObject.call(this, state);
    this.l88_1 = genBuffer(this.g88_1);
    this.m88_1 = -1;
    this.n88_1 = new Long(0, 0);
    incrementAndGet(this.f88_1.a88_1);
  }
  protoOf(GLBuffer).q89 = function (value) {
    var tmp = this.f88_1.c88_1;
    var tmp$ret$0;
    // Inline function 'kotlin.Long.unaryPlus' call
    tmp$ret$0 = value;
    tmp.g21(tmp$ret$0.l1(this.n88_1));
    this.n88_1 = value;
  };
  protoOf(GLBuffer).h88 = function () {
    incrementAndGet(this.f88_1.b88_1);
    deleteBuffer(this.g88_1, this.l88_1);
    this.l88_1 = -1;
    this.m88_1 = -1;
  };
  protoOf(GLBuffer).toString = function () {
    return 'GLBuffer(' + this.l88_1 + ')';
  };
  function GLBaseObject(globalState) {
    this.f88_1 = globalState;
    this.g88_1 = this.f88_1.v87_1;
  }
  protoOf(GLBaseObject).h88 = function () {
  };
  protoOf(GLBaseObject).h7p = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.lock.Lock.invoke' call
    var tmp0_invoke = this.f88_1.d88_1;
    var tmp0_this = this.f88_1;
    var tmp0_plusAssign = tmp0_this.e88_1;
    tmp0_plusAssign.b(this);
    tmp$ret$0 = Unit_getInstance();
  };
  function GLTexture(state) {
    GLBaseObject.call(this, state);
    this.z89_1 = genTexture(this.g88_1);
    this.a8a_1 = -2;
    this.b8a_1 = -2;
    this.c8a_1 = new Long(0, 0);
    incrementAndGet(this.f88_1.x87_1);
  }
  protoOf(GLTexture).q89 = function (value) {
    var tmp = this.f88_1.z87_1;
    var tmp$ret$0;
    // Inline function 'kotlin.Long.unaryPlus' call
    tmp$ret$0 = value;
    tmp.g21(tmp$ret$0.l1(this.c8a_1));
    this.c8a_1 = value;
  };
  protoOf(GLTexture).h88 = function () {
    incrementAndGet(this.f88_1.y87_1);
    deleteTexture(this.g88_1, this.z89_1);
    this.z89_1 = -1;
  };
  function GLFrameBuffer(state, ag) {
    GLBaseObject.call(this, state);
    this.d8d_1 = ag;
    this.e8d_1 = genRenderbuffer(this.g88_1);
    this.f8d_1 = genFramebuffer(this.g88_1);
    this.g8d_1 = Companion_getInstance_45().c7x_1;
  }
  protoOf(GLFrameBuffer).h88 = function () {
    deleteRenderbuffer(this.g88_1, this.e8d_1);
    deleteFramebuffer(this.g88_1, this.f8d_1);
    this.e8d_1 = -1;
    this.f8d_1 = -1;
  };
  function gl(_this__u8e3s4, state) {
    return createOnce(_this__u8e3s4, state, gl$lambda(state));
  }
  function gl_0(_this__u8e3s4, state) {
    return createOnce(_this__u8e3s4, state, gl$lambda_0(state, _this__u8e3s4));
  }
  function gl_1(_this__u8e3s4, state) {
    return createOnce(_this__u8e3s4, state, gl$lambda_1(state));
  }
  function createOnce(_this__u8e3s4, state, block) {
    if (_this__u8e3s4.b7p_1 == null ? true : !(_this__u8e3s4.c7p_1 === state.w87_1.o7l_1)) {
      _this__u8e3s4.c7p_1 = state.w87_1.o7l_1;
      _this__u8e3s4.g7p();
      _this__u8e3s4.b7p_1 = block(_this__u8e3s4);
    }
    var tmp = _this__u8e3s4.b7p_1;
    return (!(tmp == null) ? isInterface(tmp, AGNativeObject) : false) ? tmp : THROW_CCE();
  }
  function gl$lambda($state) {
    return function (it) {
      return new GLBuffer($state);
    };
  }
  function gl$lambda_0($state, $this_gl) {
    return function (it) {
      return new GLFrameBuffer($state, $this_gl);
    };
  }
  function gl$lambda_1($state) {
    return function (it) {
      return new GLTexture($state);
    };
  }
  function get_versionString(_this__u8e3s4) {
    _init_properties_GLShaderCompiler_kt__3td561();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var tmp0_getValue = versionString$factory();
      var tmp0_elvis_lhs = versionString$delegate.h1i_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp0_getValue.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = versionString$delegate.i1i_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = versionString$delegate.h1i_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? tmp0_getValue.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var versionString$delegate;
  function get_versionInt(_this__u8e3s4) {
    _init_properties_GLShaderCompiler_kt__3td561();
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure.PropertyThis.getValue' call
      var tmp0_getValue = versionInt$factory();
      var tmp0_elvis_lhs = versionInt$delegate.h1i_1;
      var res = getExtraTyped(_this__u8e3s4, tmp0_elvis_lhs == null ? tmp0_getValue.callableName : tmp0_elvis_lhs);
      if (res == null) {
        var r = versionInt$delegate.i1i_1(_this__u8e3s4);
        // Inline function 'korlibs.datastructure.PropertyThis.setValueUntransformed' call
        var tmp0_elvis_lhs_0 = versionInt$delegate.h1i_1;
        setExtra(_this__u8e3s4, tmp0_elvis_lhs_0 == null ? tmp0_getValue.callableName : tmp0_elvis_lhs_0, r);
        tmp$ret$0 = r;
        break $l$block;
      }
      tmp$ret$0 = res;
    }
    return tmp$ret$0;
  }
  var versionInt$delegate;
  function GLProgramInfo(programId, vertexId, fragmentId, blocks, config) {
    this.l86_1 = programId;
    this.m86_1 = vertexId;
    this.n86_1 = fragmentId;
    this.o86_1 = blocks;
    this.p86_1 = config;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.associateBy' call
    var tmp1_associateBy = this.o86_1;
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp1_associateBy, 10)), 16);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp0_associateByTo = LinkedHashMap_init_$Create$(capacity);
    var tmp0_iterator = tmp1_associateBy.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.gl.GLProgramInfo.blocksByFixedLocation.<anonymous>' call
      tmp$ret$0 = element.i7u_1;
      tmp0_associateByTo.e5(tmp$ret$0, element);
    }
    tmp$ret$1 = tmp0_associateByTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.q86_1 = tmp$ret$2;
    var tmp_0 = this;
    var tmp$ret$3;
    $l$block: {
      // Inline function 'kotlin.collections.maxOfOrNull' call
      var tmp0_maxOfOrNull = this.o86_1;
      var iterator = tmp0_maxOfOrNull.h();
      if (!iterator.j()) {
        tmp$ret$3 = null;
        break $l$block;
      }
      var tmp$ret$4;
      // Inline function 'korlibs.graphics.gl.GLProgramInfo.maxBlockId.<anonymous>' call
      var tmp1__anonymous__uwfjfc = iterator.k();
      tmp$ret$4 = tmp1__anonymous__uwfjfc.i7u_1;
      var maxValue = tmp$ret$4;
      while (iterator.j()) {
        var tmp$ret$5;
        // Inline function 'korlibs.graphics.gl.GLProgramInfo.maxBlockId.<anonymous>' call
        var tmp2__anonymous__z9zvc9 = iterator.k();
        tmp$ret$5 = tmp2__anonymous__z9zvc9.i7u_1;
        var v = tmp$ret$5;
        if (compareTo(maxValue, v) < 0) {
          maxValue = v;
        }
      }
      tmp$ret$3 = maxValue;
    }
    var tmp0_elvis_lhs = tmp$ret$3;
    tmp_0.r86_1 = (tmp0_elvis_lhs == null ? -1 : tmp0_elvis_lhs) + 1 | 0;
    var tmp_1 = this;
    var tmp_2 = 0;
    var tmp_3 = this.r86_1 + 1 | 0;
    var tmp$ret$6;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$6 = fillArrayVal(Array(tmp_3), null);
    var tmp_4 = tmp$ret$6;
    while (tmp_2 < tmp_3) {
      var tmp_5 = tmp_2;
      var tmp$ret$9;
      // Inline function 'korlibs.graphics.gl.GLProgramInfo.uniforms.<anonymous>' call
      var tmp0_safe_receiver = this.q86_1.h3(tmp_5);
      var tmp_6;
      if (tmp0_safe_receiver == null) {
        tmp_6 = null;
      } else {
        var tmp$ret$8;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$7;
        // Inline function 'korlibs.graphics.gl.GLProgramInfo.uniforms.<anonymous>' call
        tmp$ret$7 = UniformsRef_init_$Create$(tmp0_safe_receiver);
        tmp$ret$8 = tmp$ret$7;
        tmp_6 = tmp$ret$8;
      }
      tmp$ret$9 = tmp_6;
      tmp_4[tmp_5] = tmp$ret$9;
      tmp_2 = tmp_2 + 1 | 0;
    }
    tmp_1.s86_1 = tmp_4;
    this.t86_1 = FastStringMap();
    this.u86_1 = FastStringMap();
  }
  protoOf(GLProgramInfo).g87 = function (gl, name) {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'korlibs.datastructure.getOrPut' call
      var tmp0_getOrPut = this.u86_1;
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_getOrPut;
      tmp$ret$1 = tmp$ret$0.get(name);
      var res = tmp$ret$1;
      if (!(res == null)) {
        tmp$ret$2 = res;
        break $l$block;
      }
      var tmp$ret$3;
      // Inline function 'korlibs.graphics.gl.GLProgramInfo.getUniformLocation.<anonymous>' call
      tmp$ret$3 = gl.s8d(this.l86_1, name);
      var out = tmp$ret$3;
      var tmp$ret$6;
      // Inline function 'korlibs.datastructure.set' call
      var tmp$ret$5;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$4;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$4 = tmp0_getOrPut;
      tmp$ret$5 = tmp$ret$4.set(name, out);
      tmp$ret$6 = tmp$ret$5;
      tmp$ret$2 = out;
    }
    return tmp$ret$2;
  };
  protoOf(GLProgramInfo).r8d = function (gl) {
    gl.t8d(this.l86_1);
  };
  protoOf(GLProgramInfo).q8d = function (gl) {
    if (!(this.m86_1 === 0)) {
      gl.u8d(this.m86_1);
    }
    if (!(this.n86_1 === 0)) {
      gl.u8d(this.n86_1);
    }
    if (!(this.l86_1 === 0)) {
      gl.v8d(this.l86_1);
    }
    this.m86_1 = 0;
    this.n86_1 = 0;
    this.l86_1 = 0;
  };
  protoOf(GLProgramInfo).toString = function () {
    return 'GLProgramInfo(programId=' + this.l86_1 + ', vertexId=' + this.m86_1 + ', fragmentId=' + this.n86_1 + ', blocks=' + this.o86_1 + ', config=' + this.p86_1 + ')';
  };
  protoOf(GLProgramInfo).hashCode = function () {
    var result = this.l86_1;
    result = imul(result, 31) + this.m86_1 | 0;
    result = imul(result, 31) + this.n86_1 | 0;
    result = imul(result, 31) + hashCode(this.o86_1) | 0;
    result = imul(result, 31) + this.p86_1.hashCode() | 0;
    return result;
  };
  protoOf(GLProgramInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GLProgramInfo))
      return false;
    var tmp0_other_with_cast = other instanceof GLProgramInfo ? other : THROW_CCE();
    if (!(this.l86_1 === tmp0_other_with_cast.l86_1))
      return false;
    if (!(this.m86_1 === tmp0_other_with_cast.m86_1))
      return false;
    if (!(this.n86_1 === tmp0_other_with_cast.n86_1))
      return false;
    if (!equals(this.o86_1, tmp0_other_with_cast.o86_1))
      return false;
    if (!this.p86_1.equals(tmp0_other_with_cast.p86_1))
      return false;
    return true;
  };
  function createShader($this, gl, type, str, debugName) {
    var shaderId = gl.w8d(type);
    gl.x8d(shaderId, str);
    gl.y8d(shaderId);
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.KmlGl.COMPILE_STATUS' call
    Companion_getInstance_68();
    tmp$ret$0 = 35713;
    var out = getShaderiv(gl, shaderId, tmp$ret$0);
    var errorInt = gl.z8d();
    var tmp$ret$1;
    // Inline function 'korlibs.kgl.KmlGl.GTRUE' call
    Companion_getInstance_68();
    tmp$ret$1 = 1;
    if (!(out === tmp$ret$1)) {
      var error = getShaderInfoLog(gl, shaderId);
      throw new ShaderException(str, error, errorInt, gl, debugName, type, out);
    }
    return shaderId;
  }
  function GLShaderCompiler() {
    GLShaderCompiler_instance = this;
    this.f86_1 = Companion_getInstance_3().b1n('GLShaderCompiler');
  }
  protoOf(GLShaderCompiler).g86 = function (gl, config, program, debugName) {
    var id = gl.a8e();
    var guessedGlSlVersion = get_versionInt(gl);
    var tmp0_elvis_lhs = Companion_getInstance_64().c8e();
    var usedGlSlVersion = tmp0_elvis_lhs == null ? guessedGlSlVersion : tmp0_elvis_lhs;
    if (Companion_getInstance_64().d8e()) {
      var tmp$ret$1;
      // Inline function 'korlibs.logger.Logger.trace' call
      var tmp1_trace = this.f86_1;
      var tmp0_log = Level_TRACE_getInstance();
      var tmp;
      if (tmp1_trace.q1n(tmp0_log)) {
        var tmp$ret$0;
        // Inline function 'korlibs.graphics.gl.GLShaderCompiler.programCreate.<anonymous>' call
        tmp$ret$0 = 'GLSL version: usedGlSlVersion=' + usedGlSlVersion + ', guessed=' + guessedGlSlVersion + ', forced=' + Companion_getInstance_64().c8e() + '. used=' + usedGlSlVersion;
        tmp1_trace.r1n(tmp0_log, tmp$ret$0);
        tmp = Unit_getInstance();
      }
      tmp$ret$1 = tmp;
    }
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp3_map = listOf_0([false, true]);
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map, 10));
    var tmp0_iterator = tmp3_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'korlibs.graphics.gl.GLShaderCompiler.programCreate.<anonymous>' call
      tmp$ret$2 = config.e8e(VOID, VOID, usedGlSlVersion, item);
      tmp2_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp2_mapTo;
    tmp$ret$4 = tmp$ret$3;
    var tmp1_container = this.f8e(gl, program, debugName, tmp$ret$4);
    var fragmentShaderId = tmp1_container.c5();
    var vertexShaderId = tmp1_container.d5();
    var finalConfig = tmp1_container.jd();
    var tmp2_iterator = program.q7t_1.h();
    while (tmp2_iterator.j()) {
      var attr = tmp2_iterator.k();
      var location = attr.d8c_1;
      gl.g8e(id, location, attr.b87_1);
    }
    gl.h8e(id, fragmentShaderId);
    gl.h8e(id, vertexShaderId);
    gl.i8e(id);
    var tmp$ret$5;
    // Inline function 'korlibs.kgl.KmlGl.LINK_STATUS' call
    Companion_getInstance_68();
    tmp$ret$5 = 35714;
    var linkStatus = getProgramiv(gl, id, tmp$ret$5);
    return new GLProgramInfo(id, vertexShaderId, fragmentShaderId, program.n7t_1, finalConfig);
  };
  protoOf(GLShaderCompiler).f8e = function (gl, program, debugName, configs) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var errors = tmp$ret$0;
    var tmp0_iterator = configs.h();
    $l$loop: while (tmp0_iterator.j()) {
      var config = tmp0_iterator.k();
      try {
        var fragmentString = toNewGlslString(program.j7t_1, config);
        var vertexString = toNewGlslString(program.i7t_1, config);
        Companion_getInstance_68();
        var fragmentShaderId = createShader(this, gl, 35632, fragmentString, debugName);
        Companion_getInstance_68();
        var vertexShaderId = createShader(this, gl, 35633, vertexString, debugName);
        var tmp$ret$2;
        // Inline function 'korlibs.logger.Logger.debug' call
        var tmp1_debug = this.f86_1;
        var tmp0_log = Level_DEBUG_getInstance();
        var tmp;
        if (tmp1_debug.q1n(tmp0_log)) {
          var tmp$ret$1;
          // Inline function 'korlibs.graphics.gl.GLShaderCompiler.createShaderWithConfigs.<anonymous>' call
          tmp$ret$1 = '!!! PROGRAM SUCCESSFULLY COMPILED: config=' + config + '\n' + vertexString + '\n' + fragmentString;
          tmp1_debug.r1n(tmp0_log, tmp$ret$1);
          tmp = Unit_getInstance();
        }
        tmp$ret$2 = tmp;
        return new Triple(fragmentShaderId, vertexShaderId, config);
      } catch ($p) {
        if ($p instanceof ShaderException) {
          var e = $p;
          var tmp$ret$4;
          // Inline function 'korlibs.logger.Logger.info' call
          var tmp3_info = this.f86_1;
          var tmp2_log = Level_INFO_getInstance();
          var tmp_0;
          if (tmp3_info.q1n(tmp2_log)) {
            var tmp$ret$3;
            // Inline function 'korlibs.graphics.gl.GLShaderCompiler.createShaderWithConfigs.<anonymous>' call
            tmp$ret$3 = stackTraceToString(e);
            tmp3_info.r1n(tmp2_log, tmp$ret$3);
            tmp_0 = Unit_getInstance();
          }
          tmp$ret$4 = tmp_0;
          // Inline function 'kotlin.collections.plusAssign' call
          errors.b(e);
          continue $l$loop;
        } else {
          throw $p;
        }
      }
    }
    var tmp$ret$7;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$6;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp4_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(errors, 10));
    var tmp0_iterator_0 = errors.h();
    while (tmp0_iterator_0.j()) {
      var item = tmp0_iterator_0.k();
      var tmp$ret$5;
      // Inline function 'korlibs.graphics.gl.GLShaderCompiler.createShaderWithConfigs.<anonymous>' call
      tmp$ret$5 = item.message;
      tmp4_mapTo.b(tmp$ret$5);
    }
    tmp$ret$6 = tmp4_mapTo;
    tmp$ret$7 = tmp$ret$6;
    throw Exception_init_$Create$('Tried several shaders: ' + tmp$ret$7, last(errors));
  };
  var GLShaderCompiler_instance;
  function GLShaderCompiler_getInstance() {
    if (GLShaderCompiler_instance == null)
      new GLShaderCompiler();
    return GLShaderCompiler_instance;
  }
  function versionString$delegate$lambda($this$$receiver) {
    _init_properties_GLShaderCompiler_kt__3td561();
    var tmp;
    if (_GLVariant___get_isWebGL__impl__bbd1dd($this$$receiver.p89())) {
      tmp = _GLVariant___get_version__impl__9nyfwa($this$$receiver.p89()) === 1 ? '1.00' : '3.00';
    } else {
      var tmp$ret$0;
      // Inline function 'korlibs.kgl.KmlGl.SHADING_LANGUAGE_VERSION' call
      Companion_getInstance_68();
      tmp$ret$0 = 35724;
      tmp = $this$$receiver.d86(tmp$ret$0);
    }
    return tmp;
  }
  function versionInt$delegate$lambda($this$$receiver) {
    _init_properties_GLShaderCompiler_kt__3td561();
    var tmp0_safe_receiver = Regex_init_$Create$('(\\d+\\.\\d+)').xk(get_versionString($this$$receiver));
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.f1();
    var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : replace(tmp1_safe_receiver, '.', '');
    var tmp;
    if (tmp2_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.text.trim' call
      tmp$ret$0 = toString(trim((!(tmp2_safe_receiver == null) ? isCharSequence(tmp2_safe_receiver) : false) ? tmp2_safe_receiver : THROW_CCE()));
      tmp = tmp$ret$0;
    }
    var tmp3_safe_receiver = tmp;
    var tmp4_elvis_lhs = tmp3_safe_receiver == null ? null : toIntOrNull(tmp3_safe_receiver);
    return tmp4_elvis_lhs == null ? 100 : tmp4_elvis_lhs;
  }
  function versionString$factory() {
    return getPropertyCallableRef('versionString', 1, KProperty1, function (receiver) {
      return get_versionString(receiver);
    }, null);
  }
  function versionInt$factory() {
    return getPropertyCallableRef('versionInt', 1, KProperty1, function (receiver) {
      return get_versionInt(receiver);
    }, null);
  }
  var properties_initialized_GLShaderCompiler_kt_k11ei3;
  function _init_properties_GLShaderCompiler_kt__3td561() {
    if (properties_initialized_GLShaderCompiler_kt_k11ei3) {
    } else {
      properties_initialized_GLShaderCompiler_kt_k11ei3 = true;
      versionString$delegate = new PropertyThis(VOID, versionString$delegate$lambda);
      versionInt$delegate = new PropertyThis(VOID, versionInt$delegate$lambda);
    }
  }
  function AGDummy(size) {
    size = size === VOID ? Size_init_$Create$(640, 480) : size;
    AG.call(this);
    this.n7l_1.g7s(numberToInt(size.w40_1), numberToInt(size.x40_1));
  }
  function AGPrint(size) {
    size = size === VOID ? Size_init_$Create$(640, 480) : size;
    AGBaseLog.call(this, size);
  }
  protoOf(AGPrint).s8e = function (str, kind) {
    println('PrintAG: ' + str);
  };
  var Kind_COMMAND_instance;
  var Kind_DRAW_instance;
  var Kind_DRAW_DETAILS_instance;
  var Kind_CLEAR_instance;
  var Kind_METRICS_instance;
  var Kind_FLIP_instance;
  var Kind_READ_instance;
  var Kind_REPAINT_instance;
  var Kind_DISPOSE_instance;
  var Kind_TEXTURE_UPLOAD_instance;
  var Kind_CLOSE_instance;
  var Kind_FRAME_BUFFER_instance;
  var Kind_BUFFER_instance;
  var Kind_TEXTURE_instance;
  var Kind_SHADER_instance;
  var Kind_OTHER_instance;
  var Kind_UNIFORM_instance;
  var Kind_UNIFORM_VALUES_instance;
  var Kind_SCISSORS_instance;
  var Kind_VIEWPORT_instance;
  var Kind_VERTEX_instance;
  var Kind_ENABLE_DISABLE_instance;
  var Kind_CONTEXT_LOST_instance;
  var Kind_FLUSH_instance;
  var Kind_entriesInitialized_0;
  function Kind_initEntries_0() {
    if (Kind_entriesInitialized_0)
      return Unit_getInstance();
    Kind_entriesInitialized_0 = true;
    Kind_COMMAND_instance = new Kind_0('COMMAND', 0);
    Kind_DRAW_instance = new Kind_0('DRAW', 1);
    Kind_DRAW_DETAILS_instance = new Kind_0('DRAW_DETAILS', 2);
    Kind_CLEAR_instance = new Kind_0('CLEAR', 3);
    Kind_METRICS_instance = new Kind_0('METRICS', 4);
    Kind_FLIP_instance = new Kind_0('FLIP', 5);
    Kind_READ_instance = new Kind_0('READ', 6);
    Kind_REPAINT_instance = new Kind_0('REPAINT', 7);
    Kind_DISPOSE_instance = new Kind_0('DISPOSE', 8);
    Kind_TEXTURE_UPLOAD_instance = new Kind_0('TEXTURE_UPLOAD', 9);
    Kind_CLOSE_instance = new Kind_0('CLOSE', 10);
    Kind_FRAME_BUFFER_instance = new Kind_0('FRAME_BUFFER', 11);
    Kind_BUFFER_instance = new Kind_0('BUFFER', 12);
    Kind_TEXTURE_instance = new Kind_0('TEXTURE', 13);
    Kind_SHADER_instance = new Kind_0('SHADER', 14);
    Kind_OTHER_instance = new Kind_0('OTHER', 15);
    Kind_UNIFORM_instance = new Kind_0('UNIFORM', 16);
    Kind_UNIFORM_VALUES_instance = new Kind_0('UNIFORM_VALUES', 17);
    Kind_SCISSORS_instance = new Kind_0('SCISSORS', 18);
    Kind_VIEWPORT_instance = new Kind_0('VIEWPORT', 19);
    Kind_VERTEX_instance = new Kind_0('VERTEX', 20);
    Kind_ENABLE_DISABLE_instance = new Kind_0('ENABLE_DISABLE', 21);
    Kind_CONTEXT_LOST_instance = new Kind_0('CONTEXT_LOST', 22);
    Kind_FLUSH_instance = new Kind_0('FLUSH', 23);
  }
  function Kind_0(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Kind_COMMAND_getInstance() {
    Kind_initEntries_0();
    return Kind_COMMAND_instance;
  }
  function Kind_FLIP_getInstance() {
    Kind_initEntries_0();
    return Kind_FLIP_instance;
  }
  function AGBaseLog(size) {
    size = size === VOID ? Size_init_$Create$(640, 480) : size;
    AGDummy.call(this, size);
    this.x8e_1 = 0;
    this.y8e_1 = 0;
    this.z8e_1 = 0;
    this.a8f_1 = 0;
    this.b8f_1 = linkedHashMapOf([]);
  }
  protoOf(AGBaseLog).s8e = function (str, kind) {
  };
  protoOf(AGBaseLog).s7l = function (command) {
    this.s8e('' + command, Kind_COMMAND_getInstance());
  };
  protoOf(AGBaseLog).r7l = function () {
    return this.s8e('finish()', Kind_FLIP_getInstance());
  };
  function TypedUniform(name, voffset, vindex, block, type, arrayCount) {
    VariableWithOffset.call(this, name, type, arrayCount, Precision_DEFAULT_getInstance());
    this.e7u_1 = voffset;
    this.f7u_1 = vindex;
    this.g7u_1 = block;
    this.h7u_1 = new Uniform(name, type, arrayCount, VOID, VOID, this);
  }
  protoOf(TypedUniform).g9 = function (thisRef, property) {
    return this;
  };
  protoOf(TypedUniform).toString = function () {
    return "TypedUniform(name='" + this.b87_1 + "', offset=" + this.e7u_1 + ', type=' + this.c7d() + ')';
  };
  function UniformBlock$Gen$uniform$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.also' call
      var tmp0_elvis_lhs = this$0.a81_1;
      var tmp0_also = new TypedUniform(tmp0_elvis_lhs == null ? 'unknown' + this$0.z80_1.o81() : tmp0_elvis_lhs, this$0.b81_1, this$0.c81_1, this$0.z80_1, this$0.d81_1, this$0.e81_1);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.graphics.shader.Gen.uniform$delegate.<anonymous>.<anonymous>' call
      this$0.z80_1.k7u_1.b(tmp0_also);
      tmp$ret$0 = tmp0_also;
      return tmp$ret$0;
    };
  }
  function Gen(block, name, voffset, vindex, type, arrayCount, size, align) {
    arrayCount = arrayCount === VOID ? 1 : arrayCount;
    this.z80_1 = block;
    this.a81_1 = name;
    this.b81_1 = voffset;
    this.c81_1 = vindex;
    this.d81_1 = type;
    this.e81_1 = arrayCount;
    this.f81_1 = size;
    this.g81_1 = align;
    var tmp = this;
    tmp.h81_1 = lazy(UniformBlock$Gen$uniform$delegate$lambda(this));
  }
  protoOf(Gen).c8f = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = uniform$factory();
    tmp$ret$0 = this.h81_1.f1();
    return tmp$ret$0;
  };
  protoOf(Gen).i81 = function (block, property) {
    var tmp = this;
    var tmp0_elvis_lhs = this.a81_1;
    tmp.a81_1 = tmp0_elvis_lhs == null ? property.callableName : tmp0_elvis_lhs;
    return this.c8f();
  };
  protoOf(Gen).toString = function () {
    return 'Gen(block=' + this.z80_1 + ', name=' + this.a81_1 + ', voffset=' + this.b81_1 + ', vindex=' + this.c81_1 + ', type=' + this.d81_1 + ', arrayCount=' + this.e81_1 + ', size=' + this.f81_1 + ', align=' + this.g81_1 + ')';
  };
  protoOf(Gen).hashCode = function () {
    var result = hashCode(this.z80_1);
    result = imul(result, 31) + (this.a81_1 == null ? 0 : getStringHashCode(this.a81_1)) | 0;
    result = imul(result, 31) + this.b81_1 | 0;
    result = imul(result, 31) + this.c81_1 | 0;
    result = imul(result, 31) + this.d81_1.hashCode() | 0;
    result = imul(result, 31) + this.e81_1 | 0;
    result = imul(result, 31) + this.f81_1 | 0;
    result = imul(result, 31) + this.g81_1 | 0;
    return result;
  };
  protoOf(Gen).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Gen))
      return false;
    var tmp0_other_with_cast = other instanceof Gen ? other : THROW_CCE();
    if (!equals(this.z80_1, tmp0_other_with_cast.z80_1))
      return false;
    if (!(this.a81_1 == tmp0_other_with_cast.a81_1))
      return false;
    if (!(this.b81_1 === tmp0_other_with_cast.b81_1))
      return false;
    if (!(this.c81_1 === tmp0_other_with_cast.c81_1))
      return false;
    if (!this.d81_1.equals(tmp0_other_with_cast.d81_1))
      return false;
    if (!(this.e81_1 === tmp0_other_with_cast.e81_1))
      return false;
    if (!(this.f81_1 === tmp0_other_with_cast.f81_1))
      return false;
    if (!(this.g81_1 === tmp0_other_with_cast.g81_1))
      return false;
    return true;
  };
  function UniformBlock(fixedLocation) {
    this.i7u_1 = fixedLocation;
    this.j7u_1 = new KMemLayoutBuilder();
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.k7u_1 = tmp$ret$0;
    this.l7u_1 = 0;
  }
  protoOf(UniformBlock).mm = function () {
    return get_portableSimpleName(getKClassFromExpression(this));
  };
  protoOf(UniformBlock).l81 = function () {
    return this.k7u_1;
  };
  protoOf(UniformBlock).m81 = function () {
    return this.j7u_1.l();
  };
  protoOf(UniformBlock).n81 = function () {
    return nextMultipleOf(this.m81(), 256);
  };
  protoOf(UniformBlock).o81 = function () {
    return this.l81().l();
  };
  protoOf(UniformBlock).p81 = function (name) {
    return this.w81(name, VarType_Float1_getInstance(), 4, 4);
  };
  protoOf(UniformBlock).q81 = function (name, $super) {
    name = name === VOID ? null : name;
    return $super === VOID ? this.p81(name) : $super.p81.call(this, name);
  };
  protoOf(UniformBlock).r81 = function (name) {
    return this.w81(name, VarType_Float2_getInstance(), 8, 8);
  };
  protoOf(UniformBlock).s81 = function (name, $super) {
    name = name === VOID ? null : name;
    return $super === VOID ? this.r81(name) : $super.r81.call(this, name);
  };
  protoOf(UniformBlock).t81 = function (name) {
    return this.w81(name, VarType_Float4_getInstance(), 16, 16);
  };
  protoOf(UniformBlock).u81 = function (name, $super) {
    name = name === VOID ? null : name;
    return $super === VOID ? this.t81(name) : $super.t81.call(this, name);
  };
  protoOf(UniformBlock).v81 = function (name) {
    return this.w81(name, VarType_Mat4_getInstance(), 64, 16);
  };
  protoOf(UniformBlock).y80 = function (name, $super) {
    name = name === VOID ? null : name;
    return $super === VOID ? this.v81(name) : $super.v81.call(this, name);
  };
  protoOf(UniformBlock).w81 = function (name, type, size, align) {
    var tmp = this.j7u_1.q1k(size, align);
    var tmp0_this = this;
    var tmp1 = tmp0_this.l7u_1;
    tmp0_this.l7u_1 = tmp1 + 1 | 0;
    return new Gen(this, name, tmp, tmp1, type, 1, size, align);
  };
  protoOf(UniformBlock).toString = function () {
    return 'UniformBlock[' + get_portableSimpleName(getKClassFromExpression(this)) + '][' + joinToString(this.l81(), ', ') + ', fixedLocation=' + this.i7u_1 + ']';
  };
  function Companion_45() {
    Companion_instance_45 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.emptyArray' call
    tmp$ret$0 = [];
    var tmp_0 = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.emptyArray' call
    tmp$ret$1 = [];
    tmp.j7m_1 = new UniformBlocksBuffersRef(tmp_0, tmp$ret$1, new Int32Array(0));
  }
  var Companion_instance_45;
  function Companion_getInstance_61() {
    if (Companion_instance_45 == null)
      new Companion_45();
    return Companion_instance_45;
  }
  function UniformBlocksBuffersRef(blocks, buffers, valueIndices) {
    Companion_getInstance_61();
    this.o8c_1 = blocks;
    this.p8c_1 = buffers;
    this.q8c_1 = valueIndices;
  }
  protoOf(UniformBlocksBuffersRef).l = function () {
    return this.o8c_1.length;
  };
  function Companion_46() {
    Companion_instance_46 = this;
  }
  var Companion_instance_46;
  function Companion_getInstance_62() {
    if (Companion_instance_46 == null)
      new Companion_46();
    return Companion_instance_46;
  }
  function _get_bufferSize__mp12kq($this) {
    return get_sizeInBytes($this.s7s_1) / $this.q7s_1 | 0;
  }
  function UniformBlockBuffer(block) {
    Companion_getInstance_62();
    this.n7s_1 = block;
    this.o7s_1 = new AGBuffer();
    this.p7s_1 = this.n7s_1.m81();
    this.q7s_1 = this.n7s_1.n81();
    this.r7s_1 = this.n7s_1.l81().l();
    this.s7s_1 = Buffer(imul(this.q7s_1, 1));
    this.t7s_1 = new UniformsRef(this.n7s_1, this.s7s_1, -1);
    this.u7s_1 = index$factory(this.t7s_1);
    this.v7s_1 = Buffer(this.n7s_1.n81());
  }
  protoOf(UniformBlockBuffer).toString = function () {
    return 'UniformBlockBuffer(' + this.n7s_1 + ')';
  };
  protoOf(UniformBlockBuffer).d8f = function (_set____db54di) {
    var tmp0_setValue = currentIndex$factory();
    return this.u7s_1.set(_set____db54di);
  };
  protoOf(UniformBlockBuffer).n7u = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = currentIndex$factory_0();
    tmp$ret$0 = this.u7s_1.get();
    return tmp$ret$0;
  };
  protoOf(UniformBlockBuffer).p1a = function (index) {
    if (index >= (_get_bufferSize__mp12kq(this) - 1 | 0)) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      var tmp0_max = index + 1 | 0;
      var tmp1_max = imul(index + 2 | 0, 3);
      tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
      var newCapacity = tmp$ret$0;
      this.s7s_1 = copyOf(this.s7s_1, imul(this.q7s_1, newCapacity));
      this.t7s_1.t8c_1 = this.s7s_1;
    }
  };
  protoOf(UniformBlockBuffer).p1g = function () {
    this.d8f(-1);
  };
  protoOf(UniformBlockBuffer).x7s = function () {
    var tmp = this.s7s_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = imul(this.n7u() + 1 | 0, this.n7s_1.n81());
    tmp$ret$0 = Math.max(0, tmp0_max);
    this.o7s_1.a7p(tmp, 0, tmp$ret$0);
    return this;
  };
  protoOf(UniformBlockBuffer).e8f = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.n7u();
    tmp0_this.d8f(tmp1 - 1 | 0);
  };
  function UniformsRef_init_$Init$(block, size, index, $this) {
    size = size === VOID ? 1 : size;
    index = index === VOID ? 0 : index;
    UniformsRef.call($this, block, Buffer(imul(block.n81(), size)), index);
    return $this;
  }
  function UniformsRef_init_$Create$(block, size, index) {
    return UniformsRef_init_$Init$(block, size, index, objectCreate(protoOf(UniformsRef)));
  }
  function UniformsRef(block, buffer, index) {
    this.s8c_1 = block;
    this.t8c_1 = buffer;
    this.u8c_1 = index;
    this.v8c_1 = this.s8c_1.n81();
  }
  protoOf(UniformsRef).f8f = function (uniform) {
    return imul(this.u8c_1, this.v8c_1) + uniform.e7u_1 | 0;
  };
  protoOf(UniformsRef).g8f = function (ref) {
    arraycopy_0(ref.s7s_1, 0, this.t8c_1, imul(this.u8c_1, this.v8c_1), this.v8c_1);
  };
  protoOf(UniformsRef).h8f = function (uniform, value) {
    return this.i8f(uniform, value.p3r_1, value.q3r_1);
  };
  protoOf(UniformsRef).j8f = function (uniform, value) {
    return this.i8f(uniform, value.w40_1, value.x40_1);
  };
  protoOf(UniformsRef).k8f = function (uniform, value) {
    return this.l8f(uniform, _RGBA___get_rf__impl__kvz75k(value), _RGBA___get_gf__impl__5gcu1f(value), _RGBA___get_bf__impl__1xz5yg(value), _RGBA___get_af__impl__vu36qv(value));
  };
  protoOf(UniformsRef).m8f = function (uniform, value) {
    return this.l8f(uniform, _RGBAPremultiplied___get_rf__impl__pt0c4y(value), _RGBAPremultiplied___get_gf__impl__ivron7(value), _RGBAPremultiplied___get_bf__impl__qa3on2(value), _RGBAPremultiplied___get_af__impl__euwcjn(value));
  };
  protoOf(UniformsRef).n8f = function (uniform, value) {
    return this.l8f(uniform, value.l44_1, value.m44_1, value.n44_1, value.o44_1);
  };
  protoOf(UniformsRef).o8f = function (uniform, value) {
    return this.l8f(uniform, _RectCorners___get_bottomRight__impl__j7ud1v(value), _RectCorners___get_topRight__impl__okc9ql(value), _RectCorners___get_bottomLeft__impl__ffl7d4(value), _RectCorners___get_topLeft__impl__yn4qy0(value));
  };
  protoOf(UniformsRef).p8f = function (uniform, value) {
    var tmp0_subject = uniform.c7d();
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 3:
        this.q8f(uniform, value, Companion_getInstance_9().t3y());
        ;
        break;
      case 2:
        this.r8f(uniform, value, Companion_getInstance_9().t3y(), 12);
        ;
        break;
      default:
        // Inline function 'kotlin.TODO' call

        throw new NotImplementedError();
    }
  };
  protoOf(UniformsRef).r8f = function (uniform, value, indices, max) {
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.f8f(uniform);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.UniformsRef.set.<anonymous>' call
    // Inline function 'korlibs.graphics.shader.UniformsRef.bufferSetFloatNIndexed' call
    var inductionVariable = 0;
    if (inductionVariable < max)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.t8c_1;
        var tmp_0 = tmp0_also + imul(n, 4) | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.graphics.shader.UniformsRef.set.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = indices[n];
        tmp$ret$0 = value.p3z(tmp0__anonymous__q1qw7t);
        setUnalignedFloat32(tmp, tmp_0, tmp$ret$0);
      }
       while (inductionVariable < max);
    tmp$ret$1 = tmp0_also;
  };
  protoOf(UniformsRef).q8f = function (uniform, value, indices, max, $super) {
    max = max === VOID ? indices.length : max;
    var tmp;
    if ($super === VOID) {
      this.r8f(uniform, value, indices, max);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.r8f.call(this, uniform, value, indices, max);
    }
    return tmp;
  };
  protoOf(UniformsRef).s8f = function (uniform, value) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.f8f(uniform);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.UniformsRef.set.<anonymous>' call
    setUnalignedFloat32(this.t8c_1, tmp0_also + 0 | 0, value);
    tmp$ret$0 = tmp0_also;
  };
  protoOf(UniformsRef).i8f = function (uniform, x, y) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.f8f(uniform);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.UniformsRef.set.<anonymous>' call
    this.t8f(tmp0_also, x, y);
    tmp$ret$0 = tmp0_also;
  };
  protoOf(UniformsRef).l8f = function (uniform, x, y, z, w) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.f8f(uniform);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.UniformsRef.set.<anonymous>' call
    this.u8f(tmp0_also, x, y, z, w);
    tmp$ret$0 = tmp0_also;
  };
  protoOf(UniformsRef).t8f = function (index, x, y) {
    setUnalignedFloat32(this.t8c_1, index + 0 | 0, x);
    setUnalignedFloat32(this.t8c_1, index + 4 | 0, y);
  };
  protoOf(UniformsRef).u8f = function (index, x, y, z, w) {
    setUnalignedFloat32(this.t8c_1, index + 0 | 0, x);
    setUnalignedFloat32(this.t8c_1, index + 4 | 0, y);
    setUnalignedFloat32(this.t8c_1, index + 8 | 0, z);
    setUnalignedFloat32(this.t8c_1, index + 12 | 0, w);
  };
  function uniform$factory() {
    return getPropertyCallableRef('uniform', 1, KProperty1, function (receiver) {
      return receiver.c8f();
    }, null);
  }
  function index$factory($b0) {
    return getPropertyCallableRef('index', 0, KMutableProperty0, function () {
      return $b0.u8c_1;
    }, function (value) {
      $b0.u8c_1 = value;
      return Unit_getInstance();
    });
  }
  function currentIndex$factory() {
    return getPropertyCallableRef('currentIndex', 1, KMutableProperty1, function (receiver) {
      return receiver.n7u();
    }, function (receiver, value) {
      return receiver.d8f(value);
    });
  }
  function currentIndex$factory_0() {
    return getPropertyCallableRef('currentIndex', 1, KMutableProperty1, function (receiver) {
      return receiver.n7u();
    }, function (receiver, value) {
      return receiver.d8f(value);
    });
  }
  function _GLVariant___init__impl__cjzpjb(data) {
    return data;
  }
  function _GLVariant___get_data__impl__nn46ye($this) {
    return $this;
  }
  function _GLVariant___get_isES__impl__yezzg8($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _GLVariant___get_data__impl__nn46ye($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 0 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _GLVariant___get_isWebGL__impl__bbd1dd($this) {
    var tmp$ret$1;
    // Inline function 'korlibs.memory.extractBool' call
    var tmp0_extractBool = _GLVariant___get_data__impl__nn46ye($this);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract1' call
    tmp$ret$0 = (tmp0_extractBool >>> 1 | 0) & 1;
    tmp$ret$1 = !(tmp$ret$0 === 0);
    return tmp$ret$1;
  }
  function _GLVariant___get_version__impl__9nyfwa($this) {
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _GLVariant___get_data__impl__nn46ye($this);
    tmp$ret$0 = (tmp0_extract8 >>> 8 | 0) & 255;
    return tmp$ret$0;
  }
  function _GLVariant___get_os__impl__aswhdw($this) {
    var tmp = Companion_getInstance_10().r1j_1;
    var tmp$ret$0;
    // Inline function 'korlibs.memory.extract8' call
    var tmp0_extract8 = _GLVariant___get_data__impl__nn46ye($this);
    tmp$ret$0 = (tmp0_extract8 >>> 16 | 0) & 255;
    var tmp0_elvis_lhs = getOrNull(tmp, tmp$ret$0);
    return tmp0_elvis_lhs == null ? Os_UNKNOWN_getInstance() : tmp0_elvis_lhs;
  }
  function _GLVariant___get_supportTextureLevel__impl__3yeu62($this) {
    return !_GLVariant___get_isWebGL__impl__bbd1dd($this) ? !_GLVariant___get_isES__impl__yezzg8($this) : false;
  }
  function Companion_47() {
    Companion_instance_47 = this;
  }
  protoOf(Companion_47).v8f = function (isES, isWebGL, isCore, version, os) {
    return _GLVariant___init__impl__cjzpjb(insert8(insert8(insert(insert(insert(0, isES, 0), isWebGL, 1), isCore, 2), version, 8), os.b2_1, 16));
  };
  protoOf(Companion_47).w8f = function (isES, isWebGL, isCore, version, os, $super) {
    isES = isES === VOID ? false : isES;
    isWebGL = isWebGL === VOID ? false : isWebGL;
    isCore = isCore === VOID ? false : isCore;
    version = version === VOID ? 0 : version;
    os = os === VOID ? Os_UNKNOWN_getInstance() : os;
    return $super === VOID ? this.v8f(isES, isWebGL, isCore, version, os) : $super.v8f.call(this, isES, isWebGL, isCore, version, os).x8f_1;
  };
  protoOf(Companion_47).y8f = function () {
    return Companion_getInstance_63().w8f(false, VOID, VOID, 1, Os_UNKNOWN_getInstance());
  };
  protoOf(Companion_47).z8f = function (version) {
    return Companion_getInstance_63().w8f(true, true, VOID, version);
  };
  var Companion_instance_47;
  function Companion_getInstance_63() {
    if (Companion_instance_47 == null)
      new Companion_47();
    return Companion_instance_47;
  }
  function GLVariant__toString_impl_o5yi3l($this) {
    return 'GLVariant(data=' + $this + ')';
  }
  function GLVariant__hashCode_impl_2eslaq($this) {
    return $this;
  }
  function GLVariant__equals_impl_kmlw2($this, other) {
    if (!(other instanceof GLVariant))
      return false;
    var tmp0_other_with_cast = other instanceof GLVariant ? other.x8f_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function GLVariant(data) {
    Companion_getInstance_63();
    this.x8f_1 = data;
  }
  protoOf(GLVariant).toString = function () {
    return GLVariant__toString_impl_o5yi3l(this.x8f_1);
  };
  protoOf(GLVariant).hashCode = function () {
    return GLVariant__hashCode_impl_2eslaq(this.x8f_1);
  };
  protoOf(GLVariant).equals = function (other) {
    return GLVariant__equals_impl_kmlw2(this.x8f_1, other);
  };
  function toNewGlslString(_this__u8e3s4, config) {
    return toNewGlslStringResult(_this__u8e3s4, config).b8g_1;
  }
  function toNewGlslStringResult(_this__u8e3s4, config) {
    return (new GlslGenerator(_this__u8e3s4.f8g_1, config)).t8g(_this__u8e3s4);
  }
  function GlobalsProgramVisitor() {
    Visitor.call(this, Unit_getInstance());
    this.v8g_1 = LinkedHashSet_init_$Create$();
    this.w8g_1 = LinkedHashSet_init_$Create$();
    this.x8g_1 = LinkedHashSet_init_$Create$();
    this.y8g_1 = LinkedHashSet_init_$Create$();
    this.z8g_1 = LinkedHashSet_init_$Create$();
    this.a8h_1 = LinkedHashSet_init_$Create$();
    this.b8h_1 = LinkedHashSet_init_$Create$();
  }
  protoOf(GlobalsProgramVisitor).c8h = function (attribute) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.v8g_1;
    tmp0_plusAssign.b(attribute);
  };
  protoOf(GlobalsProgramVisitor).d8h = function (attribute) {
    this.c8h(attribute);
    return Unit_getInstance();
  };
  protoOf(GlobalsProgramVisitor).e8h = function (varying) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.w8g_1;
    tmp0_plusAssign.b(varying);
  };
  protoOf(GlobalsProgramVisitor).f8h = function (varying) {
    this.e8h(varying);
    return Unit_getInstance();
  };
  protoOf(GlobalsProgramVisitor).g8h = function (uniform) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.x8g_1;
    tmp0_plusAssign.b(uniform);
    var tmp1_safe_receiver = uniform.t8h_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp0_this_0 = this;
      var tmp0_plusAssign_0 = tmp0_this_0.y8g_1;
      tmp0_plusAssign_0.b(tmp1_safe_receiver);
      tmp$ret$0 = Unit_getInstance();
    }
    var tmp2_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp1_plusAssign = tmp2_this.z8g_1;
    var tmp2_plusAssign = uniform.t8h_1.g7u_1;
    tmp1_plusAssign.b(tmp2_plusAssign);
  };
  protoOf(GlobalsProgramVisitor).u8h = function (uniform) {
    this.g8h(uniform);
    return Unit_getInstance();
  };
  protoOf(GlobalsProgramVisitor).v8h = function (typedUniform) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.x8g_1;
    var tmp1_plusAssign = typedUniform.h7u_1;
    tmp0_plusAssign.b(tmp1_plusAssign);
    var tmp1_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp2_plusAssign = tmp1_this.y8g_1;
    tmp2_plusAssign.b(typedUniform);
    var tmp2_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp3_plusAssign = tmp2_this.z8g_1;
    var tmp4_plusAssign = typedUniform.g7u_1;
    tmp3_plusAssign.b(tmp4_plusAssign);
  };
  protoOf(GlobalsProgramVisitor).w8h = function (typedUniform) {
    this.v8h(typedUniform);
    return Unit_getInstance();
  };
  protoOf(GlobalsProgramVisitor).x8h = function (sampler) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.a8h_1;
    tmp0_plusAssign.b(sampler);
  };
  protoOf(GlobalsProgramVisitor).y8h = function (sampler) {
    this.x8h(sampler);
    return Unit_getInstance();
  };
  protoOf(GlobalsProgramVisitor).z8h = function (func) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.b8h_1;
    var tmp1_plusAssign = func.d8i().mm();
    tmp0_plusAssign.b(tmp1_plusAssign);
  };
  protoOf(GlobalsProgramVisitor).e8i = function (func) {
    this.z8h(func);
    return Unit_getInstance();
  };
  function Companion_48() {
    Companion_instance_48 = this;
    this.b8e_1 = Companion_getInstance_3().b1n('GlslGenerator');
  }
  protoOf(Companion_48).c8e = function () {
    return Companion_getInstance_65().c8e();
  };
  protoOf(Companion_48).d8e = function () {
    return Companion_getInstance_65().d8e();
  };
  var Companion_instance_48;
  function Companion_getInstance_64() {
    if (Companion_instance_48 == null)
      new Companion_48();
    return Companion_instance_48;
  }
  function Result(generator, result, attributes, uniforms, varyings) {
    this.a8g_1 = generator;
    this.b8g_1 = result;
    this.c8g_1 = attributes;
    this.d8g_1 = uniforms;
    this.e8g_1 = varyings;
  }
  protoOf(Result).toString = function () {
    return 'Result(generator=' + this.a8g_1 + ', result=' + this.b8g_1 + ', attributes=' + this.c8g_1 + ', uniforms=' + this.d8g_1 + ', varyings=' + this.e8g_1 + ')';
  };
  protoOf(Result).hashCode = function () {
    var result = hashCode(this.a8g_1);
    result = imul(result, 31) + getStringHashCode(this.b8g_1) | 0;
    result = imul(result, 31) + hashCode(this.c8g_1) | 0;
    result = imul(result, 31) + hashCode(this.d8g_1) | 0;
    result = imul(result, 31) + hashCode(this.e8g_1) | 0;
    return result;
  };
  protoOf(Result).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Result))
      return false;
    var tmp0_other_with_cast = other instanceof Result ? other : THROW_CCE();
    if (!equals(this.a8g_1, tmp0_other_with_cast.a8g_1))
      return false;
    if (!(this.b8g_1 === tmp0_other_with_cast.b8g_1))
      return false;
    if (!equals(this.c8g_1, tmp0_other_with_cast.c8g_1))
      return false;
    if (!equals(this.d8g_1, tmp0_other_with_cast.d8g_1))
      return false;
    if (!equals(this.e8g_1, tmp0_other_with_cast.e8g_1))
      return false;
    return true;
  };
  function sam$kotlin_Comparator$0(function_0) {
    this.m8j_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).l8 = function (a, b) {
    return this.m8j_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function GlslGenerator$generateResult$lambda$lambda(a, b) {
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
    tmp$ret$0 = a.b87_1;
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
    tmp$ret$1 = b.b87_1;
    tmp$ret$2 = compareValues(tmp, tmp$ret$1);
    return tmp$ret$2;
  }
  function GlslGenerator$generateResult$lambda(this$0, $types, $allFuncs) {
    return function ($this$invoke) {
      var tmp;
      if (!this$0.s8g_1.n87_1) {
        var suffix = _GLVariant___get_isES__impl__yezzg8(this$0.s8g_1.k87_1) ? ' es' : '';
        $this$invoke.y3k('#version ' + this$0.s8g_1.m87_1 + suffix);
        tmp = Unit_getInstance();
      }
      var tmp_0;
      if (this$0.r8g_1.equals(ShaderType_FRAGMENT_getInstance())) {
        $this$invoke.y3k('#extension GL_OES_standard_derivatives : enable');
        tmp_0 = Unit_getInstance();
      }
      $this$invoke.y3k('#ifdef GL_ES');
      $this$invoke.y3k('precision mediump float;');
      $this$invoke.y3k('#endif');
      var tmp0_iterator = $types.v8g_1.h();
      while (tmp0_iterator.j()) {
        var it = tmp0_iterator.k();
        var layout = (this$0.s8g_1.n8j() ? this$0.s8g_1.m87_1 >= 410 : false) ? 'layout(location = ' + it.d8c_1 + ') ' : '';
        $this$invoke.y3k(layout + this$0.o8j() + ' ' + this$0.p8j(it.c87_1) + this$0.q8j(it.c7d()) + ' ' + it.b87_1 + this$0.r8j(it) + ';');
      }
      var tmp1_iterator = $types.a8h_1.h();
      while (tmp1_iterator.j()) {
        var it_0 = tmp1_iterator.k();
        var layout_0 = this$0.s8g_1.n8j() ? '' : '';
        $this$invoke.y3k(layout_0 + this$0.s8j() + ' ' + this$0.p8j(it_0.c87_1) + this$0.q8j(it_0.c7d()) + ' ' + it_0.b87_1 + this$0.r8j(it_0) + ';');
      }
      var tmp_1;
      if (this$0.s8g_1.o87()) {
        var tmp2_iterator = $types.z8g_1.h();
        while (tmp2_iterator.j()) {
          var block = tmp2_iterator.k();
          $this$invoke.y3k('layout(std140) uniform ' + block.mm() + ' {');
          var tmp3_iterator = block.l81().h();
          while (tmp3_iterator.j()) {
            var uniform = tmp3_iterator.k();
            $this$invoke.y3k('  ' + this$0.p8j(uniform.c87_1) + this$0.q8j(uniform.c7d()) + ' ' + uniform.b87_1 + this$0.r8j(uniform) + ';');
          }
          $this$invoke.y3k('};');
        }
        tmp_1 = Unit_getInstance();
      } else {
        var tmp4_iterator = $types.x8g_1.h();
        while (tmp4_iterator.j()) {
          var it_1 = tmp4_iterator.k();
          $this$invoke.y3k(this$0.s8j() + ' ' + this$0.p8j(it_1.c87_1) + this$0.q8j(it_1.c7d()) + ' ' + it_1.b87_1 + this$0.r8j(it_1) + ';');
        }
        tmp_1 = Unit_getInstance();
      }
      var tmp$ret$1;
      // Inline function 'kotlin.collections.sortedBy' call
      var tmp0_sortedBy = $types.w8g_1;
      var tmp$ret$0;
      // Inline function 'kotlin.comparisons.compareBy' call
      var tmp_2 = GlslGenerator$generateResult$lambda$lambda;
      tmp$ret$0 = new sam$kotlin_Comparator$0(tmp_2);
      tmp$ret$1 = sortedWith(tmp0_sortedBy, tmp$ret$0);
      var iterator = tmp$ret$1.h();
      var index = 0;
      $l$loop: while (iterator.j()) {
        var index_0 = index;
        index = index + 1 | 0;
        var it_2 = iterator.k();
        if (it_2 instanceof Output)
          continue $l$loop;
        var INOUT = !this$0.s8g_1.n8j() ? this$0.t8j() : (it_2.b87_1 === this$0.s8g_1.u8j() ? this$0.r8g_1.equals(ShaderType_FRAGMENT_getInstance()) : false) ? this$0.t8j() : this$0.r8g_1.equals(ShaderType_VERTEX_getInstance()) ? this$0.t8j() : this$0.o8j();
        $this$invoke.y3k(INOUT + ' ' + this$0.p8j(it_2.c87_1) + this$0.q8j(it_2.c7d()) + ' ' + it_2.b87_1 + ';');
      }
      var tmp7_iterator = $allFuncs.h();
      while (tmp7_iterator.j()) {
        var func = tmp7_iterator.k();
        var gen = new GlslBodyGenerator(this$0.r8g_1, this$0.s8g_1);
        gen.g8i(func);
        var tmp$ret$4;
        // Inline function 'kotlin.collections.map' call
        var tmp2_map = func.x8j_1;
        var tmp$ret$3;
        // Inline function 'kotlin.collections.mapTo' call
        var tmp1_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp2_map, 10));
        var tmp0_iterator_0 = tmp2_map.h();
        while (tmp0_iterator_0.j()) {
          var item = tmp0_iterator_0.k();
          var tmp$ret$2;
          // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
          tmp$ret$2 = this$0.q8j(item.b5_1) + ' ' + item.a5_1;
          tmp1_mapTo.b(tmp$ret$2);
        }
        tmp$ret$3 = tmp1_mapTo;
        tmp$ret$4 = tmp$ret$3;
        var argsStrings = tmp$ret$4;
        var tmp$ret$7;
        // Inline function 'korlibs.io.util.Indenter.line' call
        var tmp3_line = this$0.q8j(func.w8j_1) + ' ' + func.v8j_1 + '(' + joinToString(argsStrings, ', ') + ')';
        var tmp_3;
        var tmp$ret$5;
        // Inline function 'kotlin.text.isEmpty' call
        tmp$ret$5 = charSequenceLength(tmp3_line) === 0;
        if (tmp$ret$5) {
          tmp_3 = '{';
        } else {
          tmp_3 = tmp3_line + ' {';
        }
        $this$invoke.y3k(tmp_3);
        var tmp$ret$6;
        // Inline function 'korlibs.io.util.Indenter.indent' call
        $this$invoke.z3k();
        try {
          // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
          var tmp0_iterator_1 = gen.d8k_1.h();
          while (tmp0_iterator_1.j()) {
            var temp = tmp0_iterator_1.k();
            $this$invoke.y3k(this$0.p8j(temp.c87_1) + this$0.q8j(temp.c7d()) + ' ' + temp.b87_1 + ';');
          }
          $this$invoke.x3k(gen.e8k_1);
        }finally {
          $this$invoke.a3l();
        }
        tmp$ret$6 = $this$invoke;
        $this$invoke.y3k('}');
        tmp$ret$7 = $this$invoke;
      }
      return Unit_getInstance();
    };
  }
  function GlslGenerator(kind, config) {
    Companion_getInstance_64();
    this.r8g_1 = kind;
    this.s8g_1 = config;
  }
  protoOf(GlslGenerator).f8k = function () {
    return this.s8g_1;
  };
  protoOf(GlslGenerator).t8g = function (shader) {
    return this.g8k(shader.g8g_1, shader.h8g_1);
  };
  protoOf(GlslGenerator).g8k = function (root, funcs) {
    var types = new GlobalsProgramVisitor();
    if (this.r8g_1.equals(ShaderType_FRAGMENT_getInstance()) ? this.s8g_1.n8j() : false) {
      types.w8g_1.b(Varying_init_$Create$(this.s8g_1.u8j(), VarType_Float4_getInstance()));
    }
    var tmp = VarType_TVOID_getInstance();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.listOf' call
    tmp$ret$0 = emptyList();
    var mainFunc = new FuncDecl('main', tmp, tmp$ret$0, root);
    types.g8i(mainFunc);
    var tmp$ret$5;
    // Inline function 'kotlin.collections.distinctBy' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.filter' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = ArrayList_init_$Create$();
    var tmp0_iterator = funcs.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>' call
      tmp$ret$1 = types.b8h_1.q(element.z8j_1.mm());
      if (tmp$ret$1) {
        tmp0_filterTo.b(element);
      }
    }
    tmp$ret$2 = tmp0_filterTo;
    tmp$ret$3 = tmp$ret$2;
    var tmp1_distinctBy = reversed(tmp$ret$3);
    var set = HashSet_init_$Create$();
    var list = ArrayList_init_$Create$();
    var tmp0_iterator_0 = tmp1_distinctBy.h();
    while (tmp0_iterator_0.j()) {
      var e = tmp0_iterator_0.k();
      var tmp$ret$4;
      // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>' call
      tmp$ret$4 = e.z8j_1.mm();
      var key = tmp$ret$4;
      if (set.b(key)) {
        list.b(e);
      }
    }
    tmp$ret$5 = list;
    var customFuncs = tmp$ret$5;
    var tmp0_iterator_1 = funcs.h();
    while (tmp0_iterator_1.j()) {
      var func = tmp0_iterator_1.k();
      types.g8i(mainFunc);
    }
    var allFuncs = plus(customFuncs, listOf(mainFunc));
    var tmp$ret$10;
    // Inline function 'kotlin.also' call
    var tmp_0 = Companion_getInstance_11();
    var tmp2_also = tmp_0.f3k(GlslGenerator$generateResult$lambda(this, types, allFuncs)).toString();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>' call
    if (Companion_getInstance_65().d8e()) {
      var tmp$ret$7;
      // Inline function 'korlibs.logger.Logger.info' call
      var tmp1_info = Companion_getInstance_64().b8e_1;
      var tmp0_log = Level_INFO_getInstance();
      var tmp_1;
      if (tmp1_info.q1n(tmp0_log)) {
        var tmp$ret$6;
        // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
        tmp$ret$6 = 'GlSlGenerator.version: ' + this.s8g_1.m87_1;
        tmp1_info.r1n(tmp0_log, tmp$ret$6);
        tmp_1 = Unit_getInstance();
      }
      tmp$ret$7 = tmp_1;
      var tmp$ret$9;
      // Inline function 'korlibs.logger.Logger.debug' call
      var tmp3_debug = Companion_getInstance_64().b8e_1;
      var tmp2_log = Level_DEBUG_getInstance();
      var tmp_2;
      if (tmp3_debug.q1n(tmp2_log)) {
        var tmp$ret$8;
        // Inline function 'korlibs.graphics.shader.gl.GlslGenerator.generateResult.<anonymous>.<anonymous>' call
        tmp$ret$8 = 'GlSlGenerator:\n' + tmp2_also;
        tmp3_debug.r1n(tmp2_log, tmp$ret$8);
        tmp_2 = Unit_getInstance();
      }
      tmp$ret$9 = tmp_2;
    }
    tmp$ret$10 = tmp2_also;
    var result = tmp$ret$10;
    var tmp_3;
    if (root instanceof Raw) {
      tmp_3 = root.j8k(Companion_getInstance_65().i8j_1);
    } else {
      tmp_3 = result;
    }
    return new Result(this, tmp_3, toList(types.v8g_1), toList(types.x8g_1), toList(types.w8g_1));
  };
  function Companion_49() {
    Companion_instance_49 = this;
    this.h8j_1 = new GlslConfig(Companion_getInstance_63().y8f(), new Immutable(VOID, VOID, VOID, true));
    this.i8j_1 = 'GLSL';
    this.j8j_1 = 100;
    this.k8j_1 = 'fragColor';
    this.l8j_1 = 'gl_FragColor';
  }
  protoOf(Companion_49).c8e = function () {
    var tmp0_safe_receiver = Companion_getInstance_8().p1b('FORCE_GLSL_VERSION');
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : replace(tmp0_safe_receiver, '.', '');
    return tmp1_safe_receiver == null ? null : toIntOrNull(tmp1_safe_receiver);
  };
  protoOf(Companion_49).d8e = function () {
    return Companion_getInstance_8().p1b('DEBUG_GLSL') === 'true';
  };
  var Companion_instance_49;
  function Companion_getInstance_65() {
    if (Companion_instance_49 == null)
      new Companion_49();
    return Companion_instance_49;
  }
  function GlslConfig(variant, features, glslVersion, compatibility) {
    Companion_getInstance_65();
    glslVersion = glslVersion === VOID ? -1 : glslVersion;
    compatibility = compatibility === VOID ? true : compatibility;
    this.k87_1 = variant;
    this.l87_1 = features;
    this.m87_1 = glslVersion;
    this.n87_1 = compatibility;
  }
  protoOf(GlslConfig).k8k = function (name) {
    if (this.n87_1)
      return name;
    var tmp0_subject = name;
    return tmp0_subject === 'texture2D' ? 'texture' : name;
  };
  protoOf(GlslConfig).n8j = function () {
    if (_GLVariant___get_isES__impl__yezzg8(this.k87_1))
      return this.m87_1 >= 300;
    return !this.n87_1;
  };
  protoOf(GlslConfig).o87 = function () {
    return (get_ENABLE_UNIFORM_BLOCKS() ? this.n8j() : false) ? this.l87_1.i7l() : false;
  };
  protoOf(GlslConfig).o8j = function () {
    return this.n8j() ? 'in' : 'attribute';
  };
  protoOf(GlslConfig).t8j = function () {
    return this.n8j() ? 'out' : 'varying';
  };
  protoOf(GlslConfig).s8j = function () {
    return 'uniform';
  };
  protoOf(GlslConfig).u8j = function () {
    return this.n8j() ? Companion_getInstance_65().k8j_1 : Companion_getInstance_65().l8j_1;
  };
  protoOf(GlslConfig).l8k = function (variant, features, glslVersion, compatibility) {
    return new GlslConfig(variant, features, glslVersion, compatibility);
  };
  protoOf(GlslConfig).e8e = function (variant, features, glslVersion, compatibility, $super) {
    variant = variant === VOID ? this.k87_1 : variant;
    features = features === VOID ? this.l87_1 : features;
    glslVersion = glslVersion === VOID ? this.m87_1 : glslVersion;
    compatibility = compatibility === VOID ? this.n87_1 : compatibility;
    return $super === VOID ? this.l8k(variant, features, glslVersion, compatibility) : $super.l8k.call(this, new GLVariant(variant), features, glslVersion, compatibility);
  };
  protoOf(GlslConfig).toString = function () {
    return 'GlslConfig(variant=' + new GLVariant(this.k87_1) + ', features=' + this.l87_1 + ', glslVersion=' + this.m87_1 + ', compatibility=' + this.n87_1 + ')';
  };
  protoOf(GlslConfig).hashCode = function () {
    var result = GLVariant__hashCode_impl_2eslaq(this.k87_1);
    result = imul(result, 31) + hashCode(this.l87_1) | 0;
    result = imul(result, 31) + this.m87_1 | 0;
    result = imul(result, 31) + (this.n87_1 | 0) | 0;
    return result;
  };
  protoOf(GlslConfig).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GlslConfig))
      return false;
    var tmp0_other_with_cast = other instanceof GlslConfig ? other : THROW_CCE();
    if (!(this.k87_1 === tmp0_other_with_cast.k87_1))
      return false;
    if (!equals(this.l87_1, tmp0_other_with_cast.l87_1))
      return false;
    if (!(this.m87_1 === tmp0_other_with_cast.m87_1))
      return false;
    if (!(this.n87_1 === tmp0_other_with_cast.n87_1))
      return false;
    return true;
  };
  function errorType($this, type) {
    invalidOp("Don't know how to serialize type " + type);
  }
  function BaseGlslGenerator() {
  }
  function GlslBodyGenerator$visit$lambda(this$0) {
    return function (it) {
      return this$0.r8i(it);
    };
  }
  function GlslBodyGenerator$visit$lambda_0(this$0) {
    return function (it) {
      return this$0.r8i(it);
    };
  }
  function GlslBodyGenerator(kind, config) {
    Visitor.call(this, '');
    this.b8k_1 = kind;
    this.c8k_1 = config;
    this.d8k_1 = LinkedHashSet_init_$Create$();
    this.e8k_1 = new Indenter();
  }
  protoOf(GlslBodyGenerator).f8k = function () {
    return this.c8k_1;
  };
  protoOf(GlslBodyGenerator).i8i = function (stms) {
    var tmp0_iterator = stms.m8k_1.h();
    while (tmp0_iterator.j()) {
      var stm = tmp0_iterator.k();
      this.h8i(stm);
    }
  };
  protoOf(GlslBodyGenerator).l8i = function (stm) {
    this.e8k_1.y3k(this.r8i(stm.n8k_1) + ' = ' + this.r8i(stm.o8k_1) + ';');
  };
  protoOf(GlslBodyGenerator).m8i = function (stm) {
    this.e8k_1.y3k('discard;');
  };
  protoOf(GlslBodyGenerator).n8i = function (stm) {
    this.e8k_1.y3k('break;');
  };
  protoOf(GlslBodyGenerator).o8i = function (stm) {
    this.e8k_1.y3k('continue;');
  };
  protoOf(GlslBodyGenerator).q8i = function (stm) {
    var result = stm.p8k_1;
    if (!(result == null)) {
      this.e8k_1.y3k('return ' + this.r8i(result) + ';');
    } else {
      this.e8k_1.y3k('return;');
    }
  };
  protoOf(GlslBodyGenerator).d8j = function (operand) {
    var tmp = this.q8j(operand.r8k_1) + '(';
    return tmp + joinToString_0(operand.s8k_1, ', ', VOID, VOID, VOID, VOID, GlslBodyGenerator$visit$lambda(this)) + ')';
  };
  protoOf(GlslBodyGenerator).y8i = function (operand) {
    return '(' + operand.u8k_1 + '(' + this.r8i(operand.v8k_1) + ')' + ')';
  };
  protoOf(GlslBodyGenerator).z8i = function (operand) {
    return '(' + this.r8i(operand.x8k_1) + ' ' + operand.y8k_1 + ' ' + this.r8i(operand.z8k_1) + ')';
  };
  protoOf(GlslBodyGenerator).s8i = function (func) {
    var tmp = this.c8k_1.k8k(func.mm()) + '(';
    var tmp_0 = func.b8l();
    return tmp + joinToString(tmp_0, ', ', VOID, VOID, VOID, VOID, GlslBodyGenerator$visit$lambda_0(this)) + ')';
  };
  protoOf(GlslBodyGenerator).a8j = function (ternary) {
    return '((' + this.r8i(ternary.d8l_1) + ') ? (' + this.r8i(ternary.e8l_1) + ') : (' + this.r8i(ternary.f8l_1) + '))';
  };
  protoOf(GlslBodyGenerator).j8i = function (stm) {
    var tmp$ret$6;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.e8k_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>' call
    var tmp$ret$2;
    // Inline function 'korlibs.io.util.Indenter.line' call
    var tmp0_line = 'if (' + this.r8i(stm.g8l_1) + ')';
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.text.isEmpty' call
    tmp$ret$0 = charSequenceLength(tmp0_line) === 0;
    if (tmp$ret$0) {
      tmp = '{';
    } else {
      tmp = tmp0_line + ' {';
    }
    tmp0_apply.y3k(tmp);
    var tmp$ret$1;
    // Inline function 'korlibs.io.util.Indenter.indent' call
    tmp0_apply.z3k();
    try {
      // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>.<anonymous>' call
      this.h8i(stm.h8l_1);
    }finally {
      tmp0_apply.a3l();
    }
    tmp$ret$1 = tmp0_apply;
    tmp0_apply.y3k('}');
    tmp$ret$2 = tmp0_apply;
    if (!(stm.i8l_1 == null)) {
      var tmp$ret$5;
      // Inline function 'korlibs.io.util.Indenter.line' call
      var tmp_0;
      var tmp$ret$3;
      // Inline function 'kotlin.text.isEmpty' call
      tmp$ret$3 = charSequenceLength('else') === 0;
      if (tmp$ret$3) {
        tmp_0 = '{';
      } else {
        tmp_0 = 'else {';
      }
      tmp0_apply.y3k(tmp_0);
      var tmp$ret$4;
      // Inline function 'korlibs.io.util.Indenter.indent' call
      tmp0_apply.z3k();
      try {
        // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>.<anonymous>' call
        this.h8i(ensureNotNull(stm.i8l_1));
      }finally {
        tmp0_apply.a3l();
      }
      tmp$ret$4 = tmp0_apply;
      tmp0_apply.y3k('}');
      tmp$ret$5 = tmp0_apply;
    }
    tmp$ret$6 = tmp0_apply;
  };
  protoOf(GlslBodyGenerator).k8i = function (stm) {
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.e8k_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>' call
    var varType = this.q8j(stm.j8l_1.c7d());
    var loopVar = this.u8i(stm.j8l_1);
    var min = this.r8i(stm.k8l_1);
    var maxExclusive = this.r8i(stm.l8l_1);
    var tmp$ret$2;
    // Inline function 'korlibs.io.util.Indenter.line' call
    var tmp0_line = 'for (' + varType + ' ' + loopVar + ' = (' + min + '); ' + loopVar + ' < (' + maxExclusive + '); ' + loopVar + '++)';
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.text.isEmpty' call
    tmp$ret$0 = charSequenceLength(tmp0_line) === 0;
    if (tmp$ret$0) {
      tmp = '{';
    } else {
      tmp = tmp0_line + ' {';
    }
    tmp0_apply.y3k(tmp);
    var tmp$ret$1;
    // Inline function 'korlibs.io.util.Indenter.indent' call
    tmp0_apply.z3k();
    try {
      // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>.<anonymous>' call
      this.h8i(stm.m8l_1);
    }finally {
      tmp0_apply.a3l();
    }
    tmp$ret$1 = tmp0_apply;
    tmp0_apply.y3k('}');
    tmp$ret$2 = tmp0_apply;
    tmp$ret$3 = tmp0_apply;
  };
  protoOf(GlslBodyGenerator).p8i = function (stm) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.e8k_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.gl.GlslBodyGenerator.visit.<anonymous>' call
    tmp0_apply.y3k(stm.j8k(Companion_getInstance_65().i8j_1));
    tmp$ret$0 = tmp0_apply;
  };
  protoOf(GlslBodyGenerator).u8i = function (operand) {
    protoOf(Visitor).u8i.call(this, operand);
    var tmp0_subject = operand;
    var tmp;
    if (tmp0_subject instanceof Output) {
      var tmp1_subject = this.b8k_1;
      var tmp0 = tmp1_subject.b2_1;
      var tmp_0;
      switch (tmp0) {
        case 0:
          tmp_0 = 'gl_Position';
          break;
        case 1:
          tmp_0 = this.c8k_1.u8j();
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      tmp = tmp_0;
    } else {
      tmp = operand.b87_1;
    }
    return tmp;
  };
  protoOf(GlslBodyGenerator).v8i = function (temp) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.d8k_1;
    tmp0_plusAssign.b(temp);
    return protoOf(Visitor).v8i.call(this, temp);
  };
  protoOf(GlslBodyGenerator).x8i = function (output) {
    return protoOf(Visitor).x8i.call(this, output);
  };
  protoOf(GlslBodyGenerator).e8j = function (operand) {
    return '' + operand.o8l_1;
  };
  protoOf(GlslBodyGenerator).f8j = function (operand) {
    var str = '' + operand.q8l_1;
    return contains(str, _Char___init__impl__6a9atx(46)) ? str : str + '.0';
  };
  protoOf(GlslBodyGenerator).g8j = function (operand) {
    return '' + operand.s8l_1;
  };
  protoOf(GlslBodyGenerator).b8j = function (operand) {
    return this.r8i(operand.u8l_1) + '.' + operand.v8l_1;
  };
  protoOf(GlslBodyGenerator).c8j = function (operand) {
    return this.r8i(operand.x8l_1) + '[' + this.r8i(operand.y8l_1) + ']';
  };
  function ProgramLayout_init_$Init$(variables, layoutSize, $this) {
    layoutSize = layoutSize === VOID ? null : layoutSize;
    ProgramLayout.call($this, toFastList_0(variables), layoutSize);
    return $this;
  }
  function ProgramLayout_init_$Create$(variables, layoutSize) {
    return ProgramLayout_init_$Init$(variables, layoutSize, objectCreate(protoOf(ProgramLayout)));
  }
  function ProgramLayout$names$lambda(it) {
    return it.b87_1;
  }
  function ProgramLayout(items, layoutSize, fixedLocation) {
    fixedLocation = fixedLocation === VOID ? -1 : fixedLocation;
    this.y7z_1 = items;
    this.z7z_1 = layoutSize;
    this.a80_1 = fixedLocation;
    this.b80_1 = 0;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.mapInt' call
    var tmp1_mapInt = this.y7z_1;
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = new IntArrayList();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.mapInt.<anonymous>' call
    var tmp0_iterator = tmp1_mapInt.h();
    while (tmp0_iterator.j()) {
      var v = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.ProgramLayout.alignments.<anonymous>' call
      var a = v.c7d().y88_1.b8m_1;
      tmp$ret$0 = a <= 1 ? 1 : a;
      tmp0_also.q1a(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_also;
    tmp$ret$2 = tmp$ret$1;
    tmp.c80_1 = tmp$ret$2;
    var tmp_0 = this;
    var tmp$ret$5;
    // Inline function 'korlibs.datastructure.mapInt' call
    var tmp1_mapInt_0 = this.y7z_1;
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp0_also_0 = new IntArrayList();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.mapInt.<anonymous>' call
    var tmp0_iterator_0 = tmp1_mapInt_0.h();
    while (tmp0_iterator_0.j()) {
      var v_0 = tmp0_iterator_0.k();
      var tmp$ret$3;
      // Inline function 'korlibs.graphics.shader.ProgramLayout._positions.<anonymous>' call
      this.b80_1 = !(v_0.n8m_1 == null) ? v_0.n8m_1 : nextAlignedTo(this.b80_1, v_0.c7d().y88_1.b8m_1);
      var out = this.b80_1;
      var tmp0_this = this;
      tmp0_this.b80_1 = tmp0_this.b80_1 + v_0.c7d().b89_1 | 0;
      tmp$ret$3 = out;
      tmp0_also_0.q1a(tmp$ret$3);
    }
    tmp$ret$4 = tmp0_also_0;
    tmp$ret$5 = tmp$ret$4;
    tmp_0.d80_1 = tmp$ret$5;
    var tmp_1 = this;
    var tmp0_elvis_lhs = maxOrNull(this.c80_1);
    tmp_1.e80_1 = tmp0_elvis_lhs == null ? 1 : tmp0_elvis_lhs;
    var tmp_2 = this;
    var tmp0_elvis_lhs_0 = this.z7z_1;
    tmp_2.f80_1 = tmp0_elvis_lhs_0 == null ? nextAlignedTo(this.b80_1, this.e80_1) : tmp0_elvis_lhs_0;
  }
  protoOf(ProgramLayout).h = function () {
    return this.y7z_1.h();
  };
  protoOf(ProgramLayout).o8m = function () {
    return joinToString(this.y7z_1, ', ', VOID, VOID, VOID, VOID, ProgramLayout$names$lambda);
  };
  protoOf(ProgramLayout).toString = function () {
    return get_portableSimpleName(getKClassFromExpression(this)) + '[' + this.o8m() + ', fixedLocation=' + this.a80_1 + ']';
  };
  function Attribute_init_$Init$(name, type, normalized, precision, fixedLocation, $this) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Attribute.call($this, name, type, normalized, null, true, precision, VOID, fixedLocation);
    return $this;
  }
  function Attribute_init_$Create$(name, type, normalized, precision, fixedLocation) {
    return Attribute_init_$Init$(name, type, normalized, precision, fixedLocation, objectCreate(protoOf(Attribute)));
  }
  function Attribute(name, type, normalized, offset, active, precision, divisor, fixedLocation) {
    offset = offset === VOID ? null : offset;
    active = active === VOID ? true : active;
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    divisor = divisor === VOID ? 0 : divisor;
    VariableWithOffset.call(this, name, type, 1, precision, offset);
    this.a8c_1 = normalized;
    this.b8c_1 = active;
    this.c8c_1 = divisor;
    this.d8c_1 = fixedLocation;
  }
  protoOf(Attribute).toString = function () {
    return 'Attribute(' + this.b87_1 + ')';
  };
  protoOf(Attribute).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp_2;
    var tmp_3;
    var tmp_4;
    var tmp_5;
    if (other instanceof Attribute) {
      tmp_5 = this.e87_1 === other.e87_1;
    } else {
      tmp_5 = false;
    }
    if (tmp_5) {
      tmp_4 = this.c7d().equals(other.c7d());
    } else {
      tmp_4 = false;
    }
    if (tmp_4) {
      tmp_3 = this.r88_1 === other.r88_1;
    } else {
      tmp_3 = false;
    }
    if (tmp_3) {
      tmp_2 = this.b87_1 === other.b87_1;
    } else {
      tmp_2 = false;
    }
    tmp$ret$0 = tmp_2;
    if (tmp$ret$0) {
      tmp_1 = this.a8c_1 === (other instanceof Attribute ? other : THROW_CCE()).a8c_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.n8m_1 == other.n8m_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b8c_1 === other.b8c_1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Attribute).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    var out = tmp$ret$0;
    out = imul(out, 7);
    out = out + (this.a8c_1 | 0) | 0;
    out = imul(out, 7);
    var tmp = out;
    var tmp$ret$1;
    // Inline function 'kotlin.hashCode' call
    var tmp0_hashCode = this.n8m_1;
    var tmp0_safe_receiver = tmp0_hashCode;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    tmp$ret$1 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
    out = tmp + tmp$ret$1 | 0;
    out = imul(out, 7);
    out = out + (this.b8c_1 | 0) | 0;
    return out;
  };
  function VariableWithOffset(name, type, arrayCount, precision, offset) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    offset = offset === VOID ? null : offset;
    Variable.call(this, name, type, arrayCount, precision);
    this.n8m_1 = offset;
  }
  var VarKind_TBOOL_instance;
  var VarKind_TBYTE_instance;
  var VarKind_TUNSIGNED_BYTE_instance;
  var VarKind_TSHORT_instance;
  var VarKind_TUNSIGNED_SHORT_instance;
  var VarKind_TINT_instance;
  var VarKind_TFLOAT_instance;
  var VarKind_entriesInitialized;
  function VarKind_initEntries() {
    if (VarKind_entriesInitialized)
      return Unit_getInstance();
    VarKind_entriesInitialized = true;
    VarKind_TBOOL_instance = new VarKind('TBOOL', 0, 1);
    VarKind_TBYTE_instance = new VarKind('TBYTE', 1, 1);
    VarKind_TUNSIGNED_BYTE_instance = new VarKind('TUNSIGNED_BYTE', 2, 1);
    VarKind_TSHORT_instance = new VarKind('TSHORT', 3, 2);
    VarKind_TUNSIGNED_SHORT_instance = new VarKind('TUNSIGNED_SHORT', 4, 2);
    VarKind_TINT_instance = new VarKind('TINT', 5, 4);
    VarKind_TFLOAT_instance = new VarKind('TFLOAT', 6, 4);
  }
  function VarKind(name, ordinal, bytesSize) {
    Enum.call(this, name, ordinal);
    this.b8m_1 = bytesSize;
  }
  var VarType_TVOID_instance;
  var VarType_Mat2_instance;
  var VarType_Mat3_instance;
  var VarType_Mat4_instance;
  var VarType_Sampler1D_instance;
  var VarType_Sampler2D_instance;
  var VarType_Sampler3D_instance;
  var VarType_SamplerCube_instance;
  var VarType_Int1_instance;
  var VarType_Float1_instance;
  var VarType_Float2_instance;
  var VarType_Float3_instance;
  var VarType_Float4_instance;
  var VarType_Short1_instance;
  var VarType_Short2_instance;
  var VarType_Short3_instance;
  var VarType_Short4_instance;
  var VarType_Bool1_instance;
  var VarType_Bool2_instance;
  var VarType_Bool3_instance;
  var VarType_Bool4_instance;
  var VarType_Byte4_instance;
  var VarType_SByte1_instance;
  var VarType_SByte2_instance;
  var VarType_SByte3_instance;
  var VarType_SByte4_instance;
  var VarType_UByte1_instance;
  var VarType_UByte2_instance;
  var VarType_UByte3_instance;
  var VarType_UByte4_instance;
  var VarType_SShort1_instance;
  var VarType_SShort2_instance;
  var VarType_SShort3_instance;
  var VarType_SShort4_instance;
  var VarType_UShort1_instance;
  var VarType_UShort2_instance;
  var VarType_UShort3_instance;
  var VarType_UShort4_instance;
  var VarType_SInt1_instance;
  var VarType_SInt2_instance;
  var VarType_SInt3_instance;
  var VarType_SInt4_instance;
  function Companion_50() {
    Companion_instance_50 = this;
  }
  protoOf(Companion_50).p8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_SByte1_getInstance();
        break;
      case 2:
        tmp = VarType_SByte2_getInstance();
        break;
      case 3:
        tmp = VarType_SByte3_getInstance();
        break;
      case 4:
        tmp = VarType_SByte4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  protoOf(Companion_50).q8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_UByte1_getInstance();
        break;
      case 2:
        tmp = VarType_UByte2_getInstance();
        break;
      case 3:
        tmp = VarType_UByte3_getInstance();
        break;
      case 4:
        tmp = VarType_UByte4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  protoOf(Companion_50).r8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_SShort1_getInstance();
        break;
      case 2:
        tmp = VarType_SShort2_getInstance();
        break;
      case 3:
        tmp = VarType_SShort3_getInstance();
        break;
      case 4:
        tmp = VarType_SShort4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  protoOf(Companion_50).s8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_UShort1_getInstance();
        break;
      case 2:
        tmp = VarType_UShort2_getInstance();
        break;
      case 3:
        tmp = VarType_UShort3_getInstance();
        break;
      case 4:
        tmp = VarType_UShort4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  protoOf(Companion_50).t8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_SInt1_getInstance();
        break;
      case 2:
        tmp = VarType_SInt2_getInstance();
        break;
      case 3:
        tmp = VarType_SInt3_getInstance();
        break;
      case 4:
        tmp = VarType_SInt4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  protoOf(Companion_50).u8m = function (count) {
    var tmp0_subject = count;
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = VarType_TVOID_getInstance();
        break;
      case 1:
        tmp = VarType_Float1_getInstance();
        break;
      case 2:
        tmp = VarType_Float2_getInstance();
        break;
      case 3:
        tmp = VarType_Float3_getInstance();
        break;
      case 4:
        tmp = VarType_Float4_getInstance();
        break;
      default:
        get_invalidOp();
        break;
    }
    return tmp;
  };
  var Companion_instance_50;
  function Companion_getInstance_66() {
    VarType_initEntries();
    if (Companion_instance_50 == null)
      new Companion_50();
    return Companion_instance_50;
  }
  var VarType_entriesInitialized;
  function VarType_initEntries() {
    if (VarType_entriesInitialized)
      return Unit_getInstance();
    VarType_entriesInitialized = true;
    VarType_TVOID_instance = new VarType('TVOID', 0, VarKind_TBYTE_getInstance(), 0);
    VarType_Mat2_instance = new VarType('Mat2', 1, VarKind_TFLOAT_getInstance(), 4, true);
    VarType_Mat3_instance = new VarType('Mat3', 2, VarKind_TFLOAT_getInstance(), 9, true);
    VarType_Mat4_instance = new VarType('Mat4', 3, VarKind_TFLOAT_getInstance(), 16, true);
    VarType_Sampler1D_instance = new VarType('Sampler1D', 4, VarKind_TINT_getInstance(), 1);
    VarType_Sampler2D_instance = new VarType('Sampler2D', 5, VarKind_TINT_getInstance(), 1);
    VarType_Sampler3D_instance = new VarType('Sampler3D', 6, VarKind_TINT_getInstance(), 1);
    VarType_SamplerCube_instance = new VarType('SamplerCube', 7, VarKind_TINT_getInstance(), 1);
    VarType_Int1_instance = new VarType('Int1', 8, VarKind_TINT_getInstance(), 1);
    VarType_Float1_instance = new VarType('Float1', 9, VarKind_TFLOAT_getInstance(), 1);
    VarType_Float2_instance = new VarType('Float2', 10, VarKind_TFLOAT_getInstance(), 2);
    VarType_Float3_instance = new VarType('Float3', 11, VarKind_TFLOAT_getInstance(), 3);
    VarType_Float4_instance = new VarType('Float4', 12, VarKind_TFLOAT_getInstance(), 4);
    VarType_Short1_instance = new VarType('Short1', 13, VarKind_TSHORT_getInstance(), 1);
    VarType_Short2_instance = new VarType('Short2', 14, VarKind_TSHORT_getInstance(), 2);
    VarType_Short3_instance = new VarType('Short3', 15, VarKind_TSHORT_getInstance(), 3);
    VarType_Short4_instance = new VarType('Short4', 16, VarKind_TSHORT_getInstance(), 4);
    VarType_Bool1_instance = new VarType('Bool1', 17, VarKind_TBOOL_getInstance(), 1);
    VarType_Bool2_instance = new VarType('Bool2', 18, VarKind_TBOOL_getInstance(), 2);
    VarType_Bool3_instance = new VarType('Bool3', 19, VarKind_TBOOL_getInstance(), 3);
    VarType_Bool4_instance = new VarType('Bool4', 20, VarKind_TBOOL_getInstance(), 4);
    VarType_Byte4_instance = new VarType('Byte4', 21, VarKind_TUNSIGNED_BYTE_getInstance(), 4);
    VarType_SByte1_instance = new VarType('SByte1', 22, VarKind_TBYTE_getInstance(), 1);
    VarType_SByte2_instance = new VarType('SByte2', 23, VarKind_TBYTE_getInstance(), 2);
    VarType_SByte3_instance = new VarType('SByte3', 24, VarKind_TBYTE_getInstance(), 3);
    VarType_SByte4_instance = new VarType('SByte4', 25, VarKind_TBYTE_getInstance(), 4);
    VarType_UByte1_instance = new VarType('UByte1', 26, VarKind_TUNSIGNED_BYTE_getInstance(), 1);
    VarType_UByte2_instance = new VarType('UByte2', 27, VarKind_TUNSIGNED_BYTE_getInstance(), 2);
    VarType_UByte3_instance = new VarType('UByte3', 28, VarKind_TUNSIGNED_BYTE_getInstance(), 3);
    VarType_UByte4_instance = new VarType('UByte4', 29, VarKind_TUNSIGNED_BYTE_getInstance(), 4);
    VarType_SShort1_instance = new VarType('SShort1', 30, VarKind_TSHORT_getInstance(), 1);
    VarType_SShort2_instance = new VarType('SShort2', 31, VarKind_TSHORT_getInstance(), 2);
    VarType_SShort3_instance = new VarType('SShort3', 32, VarKind_TSHORT_getInstance(), 3);
    VarType_SShort4_instance = new VarType('SShort4', 33, VarKind_TSHORT_getInstance(), 4);
    VarType_UShort1_instance = new VarType('UShort1', 34, VarKind_TUNSIGNED_SHORT_getInstance(), 1);
    VarType_UShort2_instance = new VarType('UShort2', 35, VarKind_TUNSIGNED_SHORT_getInstance(), 2);
    VarType_UShort3_instance = new VarType('UShort3', 36, VarKind_TUNSIGNED_SHORT_getInstance(), 3);
    VarType_UShort4_instance = new VarType('UShort4', 37, VarKind_TUNSIGNED_SHORT_getInstance(), 4);
    VarType_SInt1_instance = new VarType('SInt1', 38, VarKind_TINT_getInstance(), 1);
    VarType_SInt2_instance = new VarType('SInt2', 39, VarKind_TINT_getInstance(), 2);
    VarType_SInt3_instance = new VarType('SInt3', 40, VarKind_TINT_getInstance(), 3);
    VarType_SInt4_instance = new VarType('SInt4', 41, VarKind_TINT_getInstance(), 4);
    Companion_getInstance_66();
  }
  function VarType(name, ordinal, kind, elementCount, isMatrix) {
    isMatrix = isMatrix === VOID ? false : isMatrix;
    Enum.call(this, name, ordinal);
    this.y88_1 = kind;
    this.z88_1 = elementCount;
    this.a89_1 = isMatrix;
    this.b89_1 = imul(this.y88_1.b8m_1, this.z88_1);
  }
  protoOf(VarType).v8m = function (length) {
    var tmp0_subject = this.y88_1;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 1:
        tmp = Companion_getInstance_66().p8m(length);
        break;
      case 2:
        tmp = Companion_getInstance_66().q8m(length);
        break;
      case 3:
        tmp = Companion_getInstance_66().r8m(length);
        break;
      case 4:
        tmp = Companion_getInstance_66().s8m(length);
        break;
      case 5:
        tmp = Companion_getInstance_66().t8m(length);
        break;
      case 6:
        tmp = Companion_getInstance_66().u8m(length);
        break;
      default:
        throw new NotImplementedError();
    }
    return tmp;
  };
  protoOf(VarType).w8m = function () {
    return ((this.equals(VarType_Sampler1D_getInstance()) ? true : this.equals(VarType_Sampler2D_getInstance())) ? true : this.equals(VarType_Sampler3D_getInstance())) ? true : this.equals(VarType_SamplerCube_getInstance());
  };
  var Precision_DEFAULT_instance;
  var Precision_LOW_instance;
  var Precision_MEDIUM_instance;
  var Precision_HIGH_instance;
  var Precision_entriesInitialized;
  function Precision_initEntries() {
    if (Precision_entriesInitialized)
      return Unit_getInstance();
    Precision_entriesInitialized = true;
    Precision_DEFAULT_instance = new Precision('DEFAULT', 0);
    Precision_LOW_instance = new Precision('LOW', 1);
    Precision_MEDIUM_instance = new Precision('MEDIUM', 2);
    Precision_HIGH_instance = new Precision('HIGH', 3);
  }
  function Precision(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Variable_init_$Init$(name, type, precision, $this) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Variable.call($this, name, type, 1, precision);
    return $this;
  }
  function Variable(name, type, arrayCount, precision) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    OperandWithArray.call(this, type, arrayCount);
    this.b87_1 = name;
    this.c87_1 = precision;
    var tmp = this;
    var tmp_0 = 0;
    var tmp_1 = arrayCount;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$1;
      // Inline function 'korlibs.graphics.shader.Variable.indexNames.<anonymous>' call
      tmp$ret$1 = this.b87_1 + '[' + tmp_3 + ']';
      tmp_2[tmp_3] = tmp$ret$1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    tmp.d87_1 = tmp_2;
    this.e87_1 = 0;
    this.f87_1 = null;
  }
  protoOf(Variable).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Variable) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Variable).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  function Uniform(name, type, arrayCount, precision, offset, typedUniform) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    offset = offset === VOID ? null : offset;
    VariableWithOffset.call(this, name, type, arrayCount, precision, offset);
    this.t8h_1 = typedUniform;
    if (type.w8m()) {
      // Inline function 'kotlin.error' call
      var tmp0_error = "Can't create uniform '" + name + "' with a sampler type '" + type + "', please use `Sampler` class instead";
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
  }
  protoOf(Uniform).toString = function () {
    return 'Uniform(' + this.b87_1 + ')';
  };
  protoOf(Uniform).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Uniform) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Uniform).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  function OperandWithArray(type, arrayCount) {
    Operand.call(this, type);
    this.r88_1 = arrayCount;
    this.s88_1 = type.y88_1;
    this.t88_1 = type.z88_1;
    this.u88_1 = imul(this.t88_1, this.r88_1);
    this.v88_1 = imul(type.b89_1, this.r88_1);
  }
  function Arg(name, type, arrayCount, precision) {
    arrayCount = arrayCount === VOID ? 1 : arrayCount;
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Variable.call(this, name, type, arrayCount, precision);
  }
  protoOf(Arg).toString = function () {
    return 'Arg(' + this.b87_1 + ')';
  };
  protoOf(Arg).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Arg) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Arg).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  function Provider(index, stype) {
    this.x81_1 = index;
    this.y81_1 = stype;
  }
  protoOf(Provider).z81 = function (thisRef, property) {
    return new Sampler(property.callableName, this.x81_1, this.y81_1);
  };
  function Sampler(name, index, stype) {
    Variable_init_$Init$(name, stype.z8m_1, VOID, this);
    this.r7r_1 = index;
    this.s7r_1 = stype;
  }
  protoOf(Sampler).toString = function () {
    return 'Sampler(' + this.b87_1 + ', ' + this.r7r_1 + ', ' + this.s7r_1 + ')';
  };
  protoOf(Sampler).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Sampler) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Sampler).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  protoOf(Sampler).g9 = function (thisRef, property) {
    return this;
  };
  function Temp_init_$Init$(id, type, precision, $this) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Temp.call($this, id, type, 1, precision);
    return $this;
  }
  function Temp_init_$Create$(id, type, precision) {
    return Temp_init_$Init$(id, type, precision, objectCreate(protoOf(Temp)));
  }
  function Temp(id, type, arrayCount, precision) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Variable.call(this, 'temp' + id, type, arrayCount, precision);
  }
  protoOf(Temp).toString = function () {
    return 'Temp(' + this.b87_1 + ')';
  };
  protoOf(Temp).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Temp) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Temp).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  function Varying_init_$Init$(name, type, precision, $this) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Varying.call($this, name, type, 1, precision);
    return $this;
  }
  function Varying_init_$Create$(name, type, precision) {
    return Varying_init_$Init$(name, type, precision, objectCreate(protoOf(Varying)));
  }
  function Varying(name, type, arrayCount, precision) {
    precision = precision === VOID ? Precision_DEFAULT_getInstance() : precision;
    Variable.call(this, name, type, arrayCount, precision);
  }
  protoOf(Varying).toString = function () {
    return 'Varying(' + this.b87_1 + ')';
  };
  protoOf(Varying).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Varying) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Varying).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  function Operand(type) {
    this.p88_1 = type;
  }
  protoOf(Operand).c7d = function () {
    return this.p88_1;
  };
  var SamplerVarType_Sampler1D_instance;
  var SamplerVarType_Sampler2D_instance;
  var SamplerVarType_Sampler3D_instance;
  var SamplerVarType_SamplerCube_instance;
  var SamplerVarType_entriesInitialized;
  function SamplerVarType_initEntries() {
    if (SamplerVarType_entriesInitialized)
      return Unit_getInstance();
    SamplerVarType_entriesInitialized = true;
    SamplerVarType_Sampler1D_instance = new SamplerVarType('Sampler1D', 0, VarType_Sampler1D_getInstance());
    SamplerVarType_Sampler2D_instance = new SamplerVarType('Sampler2D', 1, VarType_Sampler2D_getInstance());
    SamplerVarType_Sampler3D_instance = new SamplerVarType('Sampler3D', 2, VarType_Sampler3D_getInstance());
    SamplerVarType_SamplerCube_instance = new SamplerVarType('SamplerCube', 3, VarType_SamplerCube_getInstance());
  }
  function SamplerVarType(name, ordinal, vtype) {
    Enum.call(this, name, ordinal);
    this.z8m_1 = vtype;
  }
  function Func_init_$Init$(name, ops, type, $this) {
    type = type === VOID ? VarType_Float1_getInstance() : type;
    Func.call($this, name, toList_0(ops), type);
    return $this;
  }
  function Func_init_$Create$(name, ops, type) {
    return Func_init_$Init$(name, ops, type, objectCreate(protoOf(Func)));
  }
  function Stms(stms) {
    Stm.call(this);
    this.m8k_1 = stms;
  }
  protoOf(Stms).toString = function () {
    return 'Stms(stms=' + this.m8k_1 + ')';
  };
  protoOf(Stms).hashCode = function () {
    return hashCode(this.m8k_1);
  };
  protoOf(Stms).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Stms))
      return false;
    var tmp0_other_with_cast = other instanceof Stms ? other : THROW_CCE();
    if (!equals(this.m8k_1, tmp0_other_with_cast.m8k_1))
      return false;
    return true;
  };
  function Set(to, from) {
    Stm.call(this);
    this.n8k_1 = to;
    this.o8k_1 = from;
  }
  protoOf(Set).toString = function () {
    return 'Set(to=' + this.n8k_1 + ', from=' + this.o8k_1 + ')';
  };
  protoOf(Set).hashCode = function () {
    var result = hashCode(this.n8k_1);
    result = imul(result, 31) + hashCode(this.o8k_1) | 0;
    return result;
  };
  protoOf(Set).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Set))
      return false;
    var tmp0_other_with_cast = other instanceof Set ? other : THROW_CCE();
    if (!equals(this.n8k_1, tmp0_other_with_cast.n8k_1))
      return false;
    if (!equals(this.o8k_1, tmp0_other_with_cast.o8k_1))
      return false;
    return true;
  };
  function Return(result) {
    Stm.call(this);
    this.p8k_1 = result;
  }
  protoOf(Return).toString = function () {
    return 'Return(result=' + this.p8k_1 + ')';
  };
  protoOf(Return).hashCode = function () {
    return this.p8k_1 == null ? 0 : hashCode(this.p8k_1);
  };
  protoOf(Return).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Return))
      return false;
    var tmp0_other_with_cast = other instanceof Return ? other : THROW_CCE();
    if (!equals(this.p8k_1, tmp0_other_with_cast.p8k_1))
      return false;
    return true;
  };
  function Break() {
  }
  function Continue() {
  }
  function Discard() {
    Discard_instance = this;
    Stm.call(this);
  }
  protoOf(Discard).toString = function () {
    return 'Discard';
  };
  var Discard_instance;
  function Discard_getInstance() {
    if (Discard_instance == null)
      new Discard();
    return Discard_instance;
  }
  function If(cond, tbody, fbody) {
    fbody = fbody === VOID ? null : fbody;
    Stm.call(this);
    this.g8l_1 = cond;
    this.h8l_1 = tbody;
    this.i8l_1 = fbody;
  }
  protoOf(If).toString = function () {
    return 'If(cond=' + this.g8l_1 + ', tbody=' + this.h8l_1 + ', fbody=' + this.i8l_1 + ')';
  };
  protoOf(If).hashCode = function () {
    var result = hashCode(this.g8l_1);
    result = imul(result, 31) + hashCode(this.h8l_1) | 0;
    result = imul(result, 31) + (this.i8l_1 == null ? 0 : hashCode(this.i8l_1)) | 0;
    return result;
  };
  protoOf(If).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof If))
      return false;
    var tmp0_other_with_cast = other instanceof If ? other : THROW_CCE();
    if (!equals(this.g8l_1, tmp0_other_with_cast.g8l_1))
      return false;
    if (!equals(this.h8l_1, tmp0_other_with_cast.h8l_1))
      return false;
    if (!equals(this.i8l_1, tmp0_other_with_cast.i8l_1))
      return false;
    return true;
  };
  function ForSimple() {
  }
  function Raw() {
  }
  protoOf(Raw).a8n = function (name) {
    return this.h8k_1.h3(name);
  };
  protoOf(Raw).b8n = function (name, default_0) {
    var tmp0_elvis_lhs = this.a8n(name);
    return tmp0_elvis_lhs == null ? default_0 : tmp0_elvis_lhs;
  };
  protoOf(Raw).j8k = function (name, default_0, $super) {
    default_0 = default_0 === VOID ? '' : default_0;
    return $super === VOID ? this.b8n(name, default_0) : $super.b8n.call(this, name, default_0);
  };
  function Program$ExpressionBuilder$Companion$INSTANCE$1() {
  }
  function Companion_51() {
    Companion_instance_51 = this;
    var tmp = this;
    tmp.c8n_1 = new Program$ExpressionBuilder$Companion$INSTANCE$1();
  }
  var Companion_instance_51;
  function Companion_getInstance_67() {
    if (Companion_instance_51 == null)
      new Companion_51();
    return Companion_instance_51;
  }
  function Program$Builder$FuncProvider$provideDelegate$lambda(this$0) {
    return function ($this$FUNC) {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mapIndexed' call
      var tmp1_mapIndexed = this$0.e8n_1;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapIndexedTo' call
      var tmp0_mapIndexedTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_mapIndexed, 10));
      var index = 0;
      var tmp0_iterator = tmp1_mapIndexed.h();
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.graphics.shader.FuncProvider.provideDelegate.<anonymous>.<anonymous>' call
        var tmp1 = index;
        index = tmp1 + 1 | 0;
        var tmp2__anonymous__z9zvc9 = checkIndexOverflow(tmp1);
        tmp$ret$0 = $this$FUNC.k8n('p' + tmp2__anonymous__z9zvc9, item);
        tmp0_mapIndexedTo.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapIndexedTo;
      tmp$ret$2 = tmp$ret$1;
      var args = tmp$ret$2;
      this$0.g8n_1($this$FUNC, args);
      return Unit_getInstance();
    };
  }
  function Builder_init_$Init$(parent, $this) {
    Builder.call($this, parent.f7z_1);
    return $this;
  }
  function Builder_init_$Create$(parent) {
    return Builder_init_$Init$(parent, objectCreate(protoOf(Builder)));
  }
  function FuncBuilder(parent) {
    Builder_init_$Init$(parent, this);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.j8n_1 = tmp$ret$0;
  }
  protoOf(FuncBuilder).k8n = function (name, type) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new Arg(name, type);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.FuncBuilder.ARG.<anonymous>' call
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.j8n_1;
    tmp0_plusAssign.b(tmp0_also);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  function FuncDeclGetter(decl) {
    this.w8n_1 = decl;
  }
  protoOf(FuncDeclGetter).g9 = function (thisRef, property) {
    var tmp = this.w8n_1;
    return isInterface(tmp, FuncRef) ? tmp : THROW_CCE();
  };
  function FuncProvider(rettype, varTypes, builder, block) {
    this.d8n_1 = rettype;
    this.e8n_1 = varTypes;
    this.f8n_1 = builder;
    this.g8n_1 = block;
  }
  protoOf(FuncProvider).z81 = function (thisRef, property) {
    var tmp = property.callableName;
    var tmp_0 = this.f8n_1.o82(tmp, this.d8n_1, Program$Builder$FuncProvider$provideDelegate$lambda(this));
    return new FuncDeclGetter(tmp_0 instanceof FuncDecl ? tmp_0 : THROW_CCE());
  };
  function Program$Builder$_funcs$1($funcs) {
    this.y8n_1 = $funcs;
    Visitor.call(this, Unit_getInstance());
  }
  protoOf(Program$Builder$_funcs$1).z8h = function (func) {
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = func.mm();
    var tmp = func.d8i();
    var tmp1_set = tmp instanceof FuncDecl ? tmp : THROW_CCE();
    this.y8n_1.e5(tmp0_set, tmp1_set);
  };
  protoOf(Program$Builder$_funcs$1).e8i = function (func) {
    this.z8h(func);
    return Unit_getInstance();
  };
  function Program$Builder$FUNC$lambda($block) {
    return function ($this$$receiver, it) {
      $block($this$$receiver, it.n(0));
      return Unit_getInstance();
    };
  }
  function Program$Builder$FUNC$lambda_0($block) {
    return function ($this$$receiver, it) {
      $block($this$$receiver, it.n(0), it.n(1));
      return Unit_getInstance();
    };
  }
  function Program$Builder$FUNC$lambda_1($block) {
    return function ($this$$receiver, it) {
      $block($this$$receiver, it.n(0), it.n(1), it.n(2));
      return Unit_getInstance();
    };
  }
  function Program$Builder$IF_ELSE_BINARY_LOOKUP$lambda($block, $max) {
    return function ($this$null) {
      $block($this$null, $max);
      return Unit_getInstance();
    };
  }
  function Program$Builder$IF_ELSE_BINARY_LOOKUP$lambda_0($ref, $middle, $max, $block) {
    return function ($this$null) {
      $this$null.a83($ref, $middle + 1 | 0, $max, $block);
      return Unit_getInstance();
    };
  }
  function Program$Builder$IF_ELSE_LIST$lambda($ref, $min, $max, $block) {
    return function ($this$null) {
      $this$null.b83($ref, $min + 1 | 0, $max, $block);
      return Unit_getInstance();
    };
  }
  function Ternary(cond, otrue, ofalse) {
    Operand.call(this, otrue.c7d());
    this.d8l_1 = cond;
    this.e8l_1 = otrue;
    this.f8l_1 = ofalse;
  }
  protoOf(Ternary).toString = function () {
    return 'Ternary(cond=' + this.d8l_1 + ', otrue=' + this.e8l_1 + ', ofalse=' + this.f8l_1 + ')';
  };
  protoOf(Ternary).hashCode = function () {
    var result = hashCode(this.d8l_1);
    result = imul(result, 31) + hashCode(this.e8l_1) | 0;
    result = imul(result, 31) + hashCode(this.f8l_1) | 0;
    return result;
  };
  protoOf(Ternary).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Ternary))
      return false;
    var tmp0_other_with_cast = other instanceof Ternary ? other : THROW_CCE();
    if (!equals(this.d8l_1, tmp0_other_with_cast.d8l_1))
      return false;
    if (!equals(this.e8l_1, tmp0_other_with_cast.e8l_1))
      return false;
    if (!equals(this.f8l_1, tmp0_other_with_cast.f8l_1))
      return false;
    return true;
  };
  function Unop(op, right) {
    Operand.call(this, right.c7d());
    this.u8k_1 = op;
    this.v8k_1 = right;
  }
  protoOf(Unop).toString = function () {
    return 'Unop(op=' + this.u8k_1 + ', right=' + this.v8k_1 + ')';
  };
  protoOf(Unop).hashCode = function () {
    var result = getStringHashCode(this.u8k_1);
    result = imul(result, 31) + hashCode(this.v8k_1) | 0;
    return result;
  };
  protoOf(Unop).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Unop))
      return false;
    var tmp0_other_with_cast = other instanceof Unop ? other : THROW_CCE();
    if (!(this.u8k_1 === tmp0_other_with_cast.u8k_1))
      return false;
    if (!equals(this.v8k_1, tmp0_other_with_cast.v8k_1))
      return false;
    return true;
  };
  function Binop(left, op, right) {
    Operand.call(this, left.c7d());
    this.x8k_1 = left;
    this.y8k_1 = op;
    this.z8k_1 = right;
  }
  protoOf(Binop).toString = function () {
    return 'Binop(left=' + this.x8k_1 + ', op=' + this.y8k_1 + ', right=' + this.z8k_1 + ')';
  };
  protoOf(Binop).hashCode = function () {
    var result = hashCode(this.x8k_1);
    result = imul(result, 31) + getStringHashCode(this.y8k_1) | 0;
    result = imul(result, 31) + hashCode(this.z8k_1) | 0;
    return result;
  };
  protoOf(Binop).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Binop))
      return false;
    var tmp0_other_with_cast = other instanceof Binop ? other : THROW_CCE();
    if (!equals(this.x8k_1, tmp0_other_with_cast.x8k_1))
      return false;
    if (!(this.y8k_1 === tmp0_other_with_cast.y8k_1))
      return false;
    if (!equals(this.z8k_1, tmp0_other_with_cast.z8k_1))
      return false;
    return true;
  };
  function IntLiteral() {
  }
  function FloatLiteral(value) {
    Operand.call(this, VarType_Float1_getInstance());
    this.q8l_1 = value;
  }
  protoOf(FloatLiteral).toString = function () {
    return 'FloatLiteral(value=' + this.q8l_1 + ')';
  };
  protoOf(FloatLiteral).hashCode = function () {
    return getNumberHashCode(this.q8l_1);
  };
  protoOf(FloatLiteral).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FloatLiteral))
      return false;
    var tmp0_other_with_cast = other instanceof FloatLiteral ? other : THROW_CCE();
    if (!equals(this.q8l_1, tmp0_other_with_cast.q8l_1))
      return false;
    return true;
  };
  function BoolLiteral() {
  }
  function Vector(type, ops) {
    Operand.call(this, type);
    this.r8k_1 = type;
    this.s8k_1 = ops;
  }
  protoOf(Vector).c7d = function () {
    return this.r8k_1;
  };
  protoOf(Vector).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof Vector) {
      tmp_0 = this.r8k_1.equals(other.r8k_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.s8k_1, other.s8k_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Vector).hashCode = function () {
    return imul(this.r8k_1.hashCode(), 7) + contentHashCode(this.s8k_1) | 0;
  };
  protoOf(Vector).toString = function () {
    return 'Vector(type=' + this.r8k_1 + ', ops=' + toString(this.s8k_1) + ')';
  };
  function Swizzle(left, swizzle) {
    Operand.call(this, left.c7d().v8m(swizzle.length));
    this.u8l_1 = left;
    this.v8l_1 = swizzle;
  }
  protoOf(Swizzle).toString = function () {
    return 'Swizzle(left=' + this.u8l_1 + ', swizzle=' + this.v8l_1 + ')';
  };
  protoOf(Swizzle).hashCode = function () {
    var result = hashCode(this.u8l_1);
    result = imul(result, 31) + getStringHashCode(this.v8l_1) | 0;
    return result;
  };
  protoOf(Swizzle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Swizzle))
      return false;
    var tmp0_other_with_cast = other instanceof Swizzle ? other : THROW_CCE();
    if (!equals(this.u8l_1, tmp0_other_with_cast.u8l_1))
      return false;
    if (!(this.v8l_1 === tmp0_other_with_cast.v8l_1))
      return false;
    return true;
  };
  function ArrayAccess() {
  }
  function BaseFunc(type) {
    Operand.call(this, type);
  }
  function Func(name, ops, type) {
    type = type === VOID ? VarType_Float1_getInstance() : type;
    BaseFunc.call(this, type);
    this.b8o_1 = name;
    this.c8o_1 = ops;
    this.d8o_1 = type;
  }
  protoOf(Func).mm = function () {
    return this.b8o_1;
  };
  protoOf(Func).b8l = function () {
    return this.c8o_1;
  };
  protoOf(Func).c7d = function () {
    return this.d8o_1;
  };
  protoOf(Func).toString = function () {
    return 'Func(name=' + this.b8o_1 + ', ops=' + this.c8o_1 + ', type=' + this.d8o_1 + ')';
  };
  protoOf(Func).hashCode = function () {
    var result = getStringHashCode(this.b8o_1);
    result = imul(result, 31) + hashCode(this.c8o_1) | 0;
    result = imul(result, 31) + this.d8o_1.hashCode() | 0;
    return result;
  };
  protoOf(Func).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Func))
      return false;
    var tmp0_other_with_cast = other instanceof Func ? other : THROW_CCE();
    if (!(this.b8o_1 === tmp0_other_with_cast.b8o_1))
      return false;
    if (!equals(this.c8o_1, tmp0_other_with_cast.c8o_1))
      return false;
    if (!this.d8o_1.equals(tmp0_other_with_cast.d8o_1))
      return false;
    return true;
  };
  function CustomFunc(ref, ops) {
    BaseFunc.call(this, ref.e8o());
    this.b8i_1 = ref;
    this.c8i_1 = ops;
  }
  protoOf(CustomFunc).d8i = function () {
    return this.b8i_1;
  };
  protoOf(CustomFunc).b8l = function () {
    return this.c8i_1;
  };
  protoOf(CustomFunc).mm = function () {
    return this.d8i().mm();
  };
  function FuncRef() {
  }
  function Stm() {
  }
  function BuilderContext() {
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.f8o_1 = tmp$ret$0;
    this.g8o_1 = 3;
  }
  function ExpressionBuilder() {
  }
  function Builder(context) {
    context = context === VOID ? new BuilderContext() : context;
    this.f7z_1 = context;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.g7z_1 = tmp$ret$0;
  }
  protoOf(Builder).k82 = function () {
    return Builder_init_$Create$(this);
  };
  protoOf(Builder).l82 = function () {
    return new FuncBuilder(this);
  };
  protoOf(Builder).m82 = function () {
    return new Stms(toList(this.g7z_1));
  };
  protoOf(Builder).n82 = function () {
    var funcs = LinkedHashMap_init_$Create$_0();
    (new Program$Builder$_funcs$1(funcs)).z8n(this.g7z_1);
    var tmp0_iterator = this.f7z_1.f8o_1.h();
    while (tmp0_iterator.j()) {
      var func = tmp0_iterator.k();
      // Inline function 'kotlin.collections.set' call
      var tmp0_set = func.v8j_1;
      funcs.e5(tmp0_set, func);
    }
    return toList(funcs.j3());
  };
  protoOf(Builder).l7z = function () {
    return to(this.m82(), this.n82());
  };
  protoOf(Builder).o82 = function (name, rettype, block) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.l82();
    // Inline function 'kotlin.contracts.contract' call
    block(tmp0_apply);
    tmp$ret$0 = tmp0_apply;
    var funcBuild = tmp$ret$0;
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp2_map = funcBuild.j8n_1;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp1_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp2_map, 10));
    var tmp0_iterator = tmp2_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.graphics.shader.Builder.FUNC.<anonymous>' call
      tmp$ret$1 = to(item.b87_1, item.c7d());
      tmp1_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp1_mapTo;
    tmp$ret$3 = tmp$ret$2;
    var tmp3_also = new FuncDecl(name, rettype, tmp$ret$3, funcBuild.m82());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.Builder.FUNC.<anonymous>' call
    var tmp0_this = this.f7z_1;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.f8o_1;
    tmp0_plusAssign.b(tmp3_also);
    tmp$ret$4 = tmp3_also;
    return tmp$ret$4;
  };
  protoOf(Builder).p82 = function (p0, dummy, returns, block) {
    var tmp = listOf(p0);
    return new FuncProvider(returns, tmp, this, Program$Builder$FUNC$lambda(block));
  };
  protoOf(Builder).q82 = function (p0, dummy, returns, block, $super) {
    dummy = dummy === VOID ? Unit_getInstance() : dummy;
    var tmp;
    if ($super === VOID) {
      tmp = this.p82(p0, dummy, returns, block);
    } else {
      var tmp_0 = $super.p82;
      tmp = tmp_0.call(this, p0, Unit_getInstance(), returns, block);
    }
    return tmp;
  };
  protoOf(Builder).r82 = function (p0, p1, dummy, returns, block) {
    var tmp = listOf_0([p0, p1]);
    return new FuncProvider(returns, tmp, this, Program$Builder$FUNC$lambda_0(block));
  };
  protoOf(Builder).s82 = function (p0, p1, dummy, returns, block, $super) {
    dummy = dummy === VOID ? Unit_getInstance() : dummy;
    var tmp;
    if ($super === VOID) {
      tmp = this.r82(p0, p1, dummy, returns, block);
    } else {
      var tmp_0 = $super.r82;
      tmp = tmp_0.call(this, p0, p1, Unit_getInstance(), returns, block);
    }
    return tmp;
  };
  protoOf(Builder).t82 = function (p0, p1, p2, dummy, returns, block) {
    var tmp = listOf_0([p0, p1, p2]);
    return new FuncProvider(returns, tmp, this, Program$Builder$FUNC$lambda_1(block));
  };
  protoOf(Builder).u82 = function (p0, p1, p2, dummy, returns, block, $super) {
    dummy = dummy === VOID ? Unit_getInstance() : dummy;
    var tmp;
    if ($super === VOID) {
      tmp = this.t82(p0, p1, p2, dummy, returns, block);
    } else {
      var tmp_0 = $super.t82;
      tmp = tmp_0.call(this, p0, p1, p2, Unit_getInstance(), returns, block);
    }
    return tmp;
  };
  protoOf(Builder).v82 = function (type) {
    var tmp0_this = this.f7z_1;
    var tmp1 = tmp0_this.g8o_1;
    tmp0_this.g8o_1 = tmp1 + 1 | 0;
    return Temp_init_$Create$(tmp1, type);
  };
  protoOf(Builder).w82 = function (_this__u8e3s4, p0) {
    return new CustomFunc(_this__u8e3s4, listOf(p0));
  };
  protoOf(Builder).x82 = function (_this__u8e3s4, p0, p1) {
    return new CustomFunc(_this__u8e3s4, listOf_0([p0, p1]));
  };
  protoOf(Builder).y82 = function (_this__u8e3s4, p0, p1, p2) {
    return new CustomFunc(_this__u8e3s4, listOf_0([p0, p1, p2]));
  };
  protoOf(Builder).z82 = function (_this__u8e3s4, callback) {
    var body = this.k82();
    callback(body);
    _this__u8e3s4.i8l_1 = body.m82();
  };
  protoOf(Builder).a83 = function (ref, min, max, block) {
    if (min >= max) {
      block(this, min);
    } else if ((max - min | 0) <= 1) {
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.Builder.IF' call
      var tmp1_IF = this.y83(ref, this.j7z(min));
      var body = this.k82();
      // Inline function 'korlibs.graphics.shader.Builder.IF_ELSE_BINARY_LOOKUP.<anonymous>' call
      block(body, min);
      var stmIf = new If(tmp1_IF, body.m82());
      var tmp0_this = this;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp0_plusAssign = tmp0_this.g7z_1;
      tmp0_plusAssign.b(stmIf);
      tmp$ret$0 = stmIf;
      var tmp = tmp$ret$0;
      this.z82(tmp, Program$Builder$IF_ELSE_BINARY_LOOKUP$lambda(block, max));
    } else {
      var middle = (min + max | 0) / 2 | 0;
      var tmp$ret$1;
      // Inline function 'korlibs.graphics.shader.Builder.IF' call
      var tmp3_IF = this.b84(ref, this.j7z(middle));
      var body_0 = this.k82();
      // Inline function 'korlibs.graphics.shader.Builder.IF_ELSE_BINARY_LOOKUP.<anonymous>' call
      body_0.a83(ref, min, middle, block);
      var stmIf_0 = new If(tmp3_IF, body_0.m82());
      var tmp0_this_0 = this;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp2_plusAssign = tmp0_this_0.g7z_1;
      tmp2_plusAssign.b(stmIf_0);
      tmp$ret$1 = stmIf_0;
      var tmp_0 = tmp$ret$1;
      this.z82(tmp_0, Program$Builder$IF_ELSE_BINARY_LOOKUP$lambda_0(ref, middle, max, block));
    }
  };
  protoOf(Builder).b83 = function (ref, min, max, block) {
    if (min >= max) {
      block(this, min);
    } else {
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.Builder.IF' call
      var tmp1_IF = this.y83(ref, this.j7z(min));
      var body = this.k82();
      // Inline function 'korlibs.graphics.shader.Builder.IF_ELSE_LIST.<anonymous>' call
      block(body, min);
      var stmIf = new If(tmp1_IF, body.m82());
      var tmp0_this = this;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp0_plusAssign = tmp0_this.g7z_1;
      tmp0_plusAssign.b(stmIf);
      tmp$ret$0 = stmIf;
      var tmp = tmp$ret$0;
      this.z82(tmp, Program$Builder$IF_ELSE_LIST$lambda(ref, min, max, block));
    }
  };
  protoOf(Builder).h7z = function (target, expr) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.g7z_1;
    var tmp1_plusAssign = new Set(target, expr);
    tmp0_plusAssign.b(tmp1_plusAssign);
  };
  protoOf(Builder).c83 = function () {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.g7z_1;
    var tmp1_plusAssign = Discard_getInstance();
    tmp0_plusAssign.b(tmp1_plusAssign);
  };
  protoOf(Builder).d83 = function (operand) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.g7z_1;
    var tmp1_plusAssign = new Return(operand);
    tmp0_plusAssign.b(tmp1_plusAssign);
  };
  protoOf(Builder).e83 = function (operand, $super) {
    operand = operand === VOID ? null : operand;
    var tmp;
    if ($super === VOID) {
      this.d83(operand);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.d83.call(this, operand);
    }
    return tmp;
  };
  protoOf(Builder).f83 = function (type) {
    var tmp0_this = this.f7z_1;
    var tmp1 = tmp0_this.g8o_1;
    tmp0_this.g8o_1 = tmp1 + 1 | 0;
    return new Temp(tmp1, type, 1);
  };
  function Visitor(default_0) {
    this.f8i_1 = default_0;
  }
  protoOf(Visitor).g8i = function (func) {
    this.h8i(func.y8j_1);
  };
  protoOf(Visitor).z8n = function (stms) {
    var tmp0_iterator = stms.h();
    while (tmp0_iterator.j()) {
      var stm = tmp0_iterator.k();
      this.h8i(stm);
    }
  };
  protoOf(Visitor).h8i = function (stm) {
    var tmp0_subject = stm;
    var tmp;
    if (tmp0_subject == null) {
      tmp = Unit_getInstance();
    } else {
      if (tmp0_subject instanceof Stms) {
        this.i8i(stm);
        tmp = Unit_getInstance();
      } else {
        if (tmp0_subject instanceof Set) {
          this.l8i(stm);
          tmp = Unit_getInstance();
        } else {
          if (tmp0_subject instanceof If) {
            this.j8i(stm);
            tmp = Unit_getInstance();
          } else {
            if (tmp0_subject instanceof ForSimple) {
              this.k8i(stm);
              tmp = Unit_getInstance();
            } else {
              if (tmp0_subject instanceof Discard) {
                this.m8i(stm);
                tmp = Unit_getInstance();
              } else {
                if (tmp0_subject instanceof Continue) {
                  this.o8i(stm);
                  tmp = Unit_getInstance();
                } else {
                  if (tmp0_subject instanceof Break) {
                    this.n8i(stm);
                    tmp = Unit_getInstance();
                  } else {
                    if (tmp0_subject instanceof Raw) {
                      this.p8i(stm);
                      tmp = Unit_getInstance();
                    } else {
                      if (tmp0_subject instanceof Return) {
                        this.q8i(stm);
                        tmp = Unit_getInstance();
                      } else {
                        noWhenBranchMatchedException();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  protoOf(Visitor).i8i = function (stms) {
    var tmp0_iterator = stms.m8k_1.h();
    while (tmp0_iterator.j()) {
      var stm = tmp0_iterator.k();
      this.h8i(stm);
    }
  };
  protoOf(Visitor).j8i = function (stm) {
    this.r8i(stm.g8l_1);
    this.h8i(stm.h8l_1);
    this.h8i(stm.i8l_1);
  };
  protoOf(Visitor).k8i = function (stm) {
    this.u8i(stm.j8l_1);
    this.r8i(stm.k8l_1);
    this.h8i(stm.m8l_1);
  };
  protoOf(Visitor).l8i = function (stm) {
    this.r8i(stm.o8k_1);
    this.r8i(stm.n8k_1);
  };
  protoOf(Visitor).m8i = function (stm) {
  };
  protoOf(Visitor).n8i = function (stm) {
  };
  protoOf(Visitor).o8i = function (stm) {
  };
  protoOf(Visitor).p8i = function (stm) {
    this.h8i(stm.i8k_1);
  };
  protoOf(Visitor).q8i = function (stm) {
    var tmp0_safe_receiver = stm.p8k_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.Visitor.visit.<anonymous>' call
      tmp$ret$0 = this.r8i(tmp0_safe_receiver);
      tmp$ret$1 = tmp$ret$0;
    }
  };
  protoOf(Visitor).r8i = function (operand) {
    var tmp0_subject = operand;
    var tmp;
    if (tmp0_subject instanceof Variable) {
      tmp = this.u8i(operand);
    } else {
      if (tmp0_subject instanceof Unop) {
        tmp = this.y8i(operand);
      } else {
        if (tmp0_subject instanceof Binop) {
          tmp = this.z8i(operand);
        } else {
          if (tmp0_subject instanceof Ternary) {
            tmp = this.a8j(operand);
          } else {
            if (tmp0_subject instanceof BoolLiteral) {
              tmp = this.g8j(operand);
            } else {
              if (tmp0_subject instanceof IntLiteral) {
                tmp = this.e8j(operand);
              } else {
                if (tmp0_subject instanceof FloatLiteral) {
                  tmp = this.f8j(operand);
                } else {
                  if (tmp0_subject instanceof Vector) {
                    tmp = this.d8j(operand);
                  } else {
                    if (tmp0_subject instanceof Swizzle) {
                      tmp = this.b8j(operand);
                    } else {
                      if (tmp0_subject instanceof ArrayAccess) {
                        tmp = this.c8j(operand);
                      } else {
                        if (tmp0_subject instanceof BaseFunc) {
                          tmp = this.s8i(operand);
                        } else {
                          invalidOp("Don't know how to visit operand " + operand);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  protoOf(Visitor).s8i = function (func) {
    var tmp0_iterator = func.b8l().h();
    while (tmp0_iterator.j()) {
      var op = tmp0_iterator.k();
      this.r8i(op);
    }
    var tmp1_subject = func;
    var tmp;
    if (tmp1_subject instanceof Func) {
      tmp = this.t8i(func);
    } else {
      if (tmp1_subject instanceof CustomFunc) {
        tmp = this.e8i(func);
      } else {
        invalidOp("Don't know how to visit func " + func);
      }
    }
    return tmp;
  };
  protoOf(Visitor).t8i = function (func) {
    return this.f8i_1;
  };
  protoOf(Visitor).e8i = function (func) {
    return this.f8i_1;
  };
  protoOf(Visitor).u8i = function (operand) {
    var tmp0_subject = operand;
    var tmp;
    if (tmp0_subject instanceof Attribute) {
      tmp = this.d8h(operand);
    } else {
      if (tmp0_subject instanceof Varying) {
        tmp = this.f8h(operand);
      } else {
        if (tmp0_subject instanceof Sampler) {
          tmp = this.y8h(operand);
        } else {
          if (tmp0_subject instanceof Uniform) {
            tmp = this.u8h(operand);
          } else {
            if (tmp0_subject instanceof TypedUniform) {
              tmp = this.w8h(operand);
            } else {
              if (tmp0_subject instanceof Output) {
                tmp = this.x8i(operand);
              } else {
                if (tmp0_subject instanceof Temp) {
                  tmp = this.v8i(operand);
                } else {
                  if (tmp0_subject instanceof Arg) {
                    tmp = this.w8i(operand);
                  } else {
                    noWhenBranchMatchedException();
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  protoOf(Visitor).v8i = function (temp) {
    return this.f8i_1;
  };
  protoOf(Visitor).w8i = function (arg) {
    return this.f8i_1;
  };
  protoOf(Visitor).d8h = function (attribute) {
    return this.f8i_1;
  };
  protoOf(Visitor).f8h = function (varying) {
    return this.f8i_1;
  };
  protoOf(Visitor).u8h = function (uniform) {
    return this.f8i_1;
  };
  protoOf(Visitor).y8h = function (sampler) {
    return this.f8i_1;
  };
  protoOf(Visitor).w8h = function (typedUniform) {
    return this.u8h(typedUniform.h7u_1);
  };
  protoOf(Visitor).x8i = function (output) {
    return this.f8i_1;
  };
  protoOf(Visitor).y8i = function (operand) {
    this.r8i(operand.v8k_1);
    return this.f8i_1;
  };
  protoOf(Visitor).z8i = function (operand) {
    this.r8i(operand.x8k_1);
    this.r8i(operand.z8k_1);
    return this.f8i_1;
  };
  protoOf(Visitor).a8j = function (operand) {
    this.r8i(operand.d8l_1);
    this.r8i(operand.e8l_1);
    this.r8i(operand.f8l_1);
    return this.f8i_1;
  };
  protoOf(Visitor).b8j = function (operand) {
    this.r8i(operand.u8l_1);
    return this.f8i_1;
  };
  protoOf(Visitor).c8j = function (operand) {
    this.r8i(operand.x8l_1);
    this.r8i(operand.y8l_1);
    return this.f8i_1;
  };
  protoOf(Visitor).d8j = function (operand) {
    var indexedObject = operand.s8k_1;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var op = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      this.r8i(op);
    }
    return this.f8i_1;
  };
  protoOf(Visitor).e8j = function (operand) {
    return this.f8i_1;
  };
  protoOf(Visitor).f8j = function (operand) {
    return this.f8i_1;
  };
  protoOf(Visitor).g8j = function (operand) {
    return this.f8i_1;
  };
  function Program(vertex, fragment, name) {
    name = name === VOID ? 'program-' + vertex.i8g_1 + '-' + fragment.i8g_1 : name;
    this.i7t_1 = vertex;
    this.j7t_1 = fragment;
    this.k7t_1 = name;
    this.l7t_1 = plus_0(this.i7t_1.n8g_1, this.j7t_1.n8g_1);
    this.m7t_1 = plus_0(this.i7t_1.o8g_1, this.j7t_1.o8g_1);
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.m7t_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.Program.uniformBlocks.<anonymous>' call
      tmp$ret$0 = item.g7u_1;
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.n7t_1 = distinct(tmp$ret$2);
    this.o7t_1 = plus_0(this.i7t_1.q8g_1, this.j7t_1.q8g_1);
    var tmp_0 = this;
    var tmp$ret$5;
    // Inline function 'kotlin.collections.associate' call
    var tmp2_associate = withIndex(this.l7t_1);
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp2_associate, 10)), 16);
    var tmp$ret$4;
    // Inline function 'kotlin.collections.associateTo' call
    var tmp1_associateTo = LinkedHashMap_init_$Create$(capacity);
    var tmp0_iterator_0 = tmp2_associate.h();
    while (tmp0_iterator_0.j()) {
      var element = tmp0_iterator_0.k();
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp$ret$3;
      // Inline function 'korlibs.graphics.shader.Program.uniformsToIndex.<anonymous>' call
      tmp$ret$3 = to(element.s4_1, new UniformInProgram(element.s4_1, element.r4_1));
      var tmp0_plusAssign = tmp$ret$3;
      tmp1_associateTo.e5(tmp0_plusAssign.a5_1, tmp0_plusAssign.b5_1);
    }
    tmp$ret$4 = tmp1_associateTo;
    tmp$ret$5 = tmp$ret$4;
    tmp_0.p7t_1 = tmp$ret$5;
    this.q7t_1 = plus_0(this.i7t_1.p8g_1, this.j7t_1.p8g_1);
    this.r7t_1 = (imul(this.i7t_1.hashCode(), 11) + imul(this.j7t_1.hashCode(), 7) | 0) + getStringHashCode(this.k7t_1) | 0;
  }
  protoOf(Program).hashCode = function () {
    return this.r7t_1;
  };
  protoOf(Program).equals = function (other) {
    var tmp;
    if (this === other) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      if (other instanceof Program) {
        tmp_2 = this.i7t_1.equals(other.i7t_1);
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp_1 = this.j7t_1.equals(other.j7t_1);
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = this.k7t_1 === other.k7t_1;
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(Program).n1q = function () {
  };
  protoOf(Program).toString = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.q7t_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.shader.Program.toString.<anonymous>' call
      tmp$ret$0 = item.b87_1;
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    var tmp = tmp$ret$2;
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp3_map = this.l7t_1;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map, 10));
    var tmp0_iterator_0 = tmp3_map.h();
    while (tmp0_iterator_0.j()) {
      var item_0 = tmp0_iterator_0.k();
      var tmp$ret$3;
      // Inline function 'korlibs.graphics.shader.Program.toString.<anonymous>' call
      tmp$ret$3 = item_0.b87_1;
      tmp2_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp2_mapTo;
    tmp$ret$5 = tmp$ret$4;
    return 'Program(name=' + this.k7t_1 + ', attributes=' + tmp + ', uniforms=' + tmp$ret$5 + ')';
  };
  protoOf(Program).h8o = function (vertex, fragment, name) {
    return new Program(vertex, fragment, name);
  };
  protoOf(Program).i8o = function (vertex, fragment, name, $super) {
    vertex = vertex === VOID ? this.i7t_1 : vertex;
    fragment = fragment === VOID ? this.j7t_1 : fragment;
    name = name === VOID ? this.k7t_1 : name;
    return $super === VOID ? this.h8o(vertex, fragment, name) : $super.h8o.call(this, vertex, fragment, name);
  };
  function Shader(type, stm, functions, name) {
    name = name === VOID ? null : name;
    this.f8g_1 = type;
    this.g8g_1 = stm;
    this.h8g_1 = functions;
    this.i8g_1 = name;
    this.j8g_1 = hashCode(this.g8g_1);
    this.k8g_1 = hashCode(this.h8g_1);
    this.l8g_1 = (imul(this.f8g_1.hashCode(), 17) + this.j8g_1 | 0) + imul(this.k8g_1, 53) | 0;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new GlobalsProgramVisitor();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.shader.Shader.globals.<anonymous>' call
    tmp0_also.h8i(this.g8g_1);
    tmp$ret$0 = tmp0_also;
    tmp.m8g_1 = tmp$ret$0;
    this.n8g_1 = this.m8g_1.x8g_1;
    this.o8g_1 = this.m8g_1.y8g_1;
    this.p8g_1 = this.m8g_1.v8g_1;
    this.q8g_1 = this.m8g_1.a8h_1;
  }
  protoOf(Shader).equals = function (other) {
    var tmp;
    if (this === other) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      var tmp_3;
      if (other instanceof Shader) {
        tmp_3 = this.f8g_1.equals(other.f8g_1);
      } else {
        tmp_3 = false;
      }
      if (tmp_3) {
        tmp_2 = this.l8g_1 === other.l8g_1;
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp_1 = equals(this.g8g_1, other.g8g_1);
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = equals(this.h8g_1, other.h8g_1);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(Shader).hashCode = function () {
    return this.l8g_1;
  };
  protoOf(Shader).toString = function () {
    return 'Shader(type=' + this.f8g_1 + ', stm=' + this.g8g_1 + ', functions=' + this.h8g_1 + ', name=' + this.i8g_1 + ')';
  };
  function Output() {
    Output_instance = this;
    Varying_init_$Init$('out', VarType_Float4_getInstance(), VOID, this);
  }
  protoOf(Output).toString = function () {
    return 'Output';
  };
  protoOf(Output).equals = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mequals' call
    var tmp;
    var tmp_0;
    var tmp_1;
    var tmp_2;
    if (other instanceof Output) {
      tmp_2 = this.e87_1 === other.e87_1;
    } else {
      tmp_2 = false;
    }
    if (tmp_2) {
      tmp_1 = this.c7d().equals(other.c7d());
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.r88_1 === other.r88_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.b87_1 === other.b87_1;
    } else {
      tmp = false;
    }
    tmp$ret$0 = tmp;
    return tmp$ret$0;
  };
  protoOf(Output).hashCode = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.graphics.shader.Variable.mhashcode' call
    tmp$ret$0 = (this.e87_1 + imul(this.c7d().hashCode(), 7) | 0) + imul(getStringHashCode(this.b87_1), 11) | 0;
    return tmp$ret$0;
  };
  var Output_instance;
  function Output_getInstance() {
    if (Output_instance == null)
      new Output();
    return Output_instance;
  }
  function UniformInProgram(uniform, index) {
    this.j8o_1 = uniform;
    this.k8o_1 = index;
  }
  protoOf(UniformInProgram).toString = function () {
    return 'UniformInProgram(uniform=' + this.j8o_1 + ', index=' + this.k8o_1 + ')';
  };
  protoOf(UniformInProgram).hashCode = function () {
    var result = this.j8o_1.hashCode();
    result = imul(result, 31) + this.k8o_1 | 0;
    return result;
  };
  protoOf(UniformInProgram).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UniformInProgram))
      return false;
    var tmp0_other_with_cast = other instanceof UniformInProgram ? other : THROW_CCE();
    if (!this.j8o_1.equals(tmp0_other_with_cast.j8o_1))
      return false;
    if (!(this.k8o_1 === tmp0_other_with_cast.k8o_1))
      return false;
    return true;
  };
  function FuncDecl(name, rettype, args, stm) {
    this.v8j_1 = name;
    this.w8j_1 = rettype;
    this.x8j_1 = args;
    this.y8j_1 = stm;
    this.z8j_1 = this;
  }
  protoOf(FuncDecl).mm = function () {
    return this.v8j_1;
  };
  protoOf(FuncDecl).e8o = function () {
    return this.w8j_1;
  };
  protoOf(FuncDecl).toString = function () {
    return 'FuncDecl(name=' + this.v8j_1 + ', rettype=' + this.w8j_1 + ', args=' + this.x8j_1 + ', stm=' + this.y8j_1 + ')';
  };
  protoOf(FuncDecl).hashCode = function () {
    var result = getStringHashCode(this.v8j_1);
    result = imul(result, 31) + this.w8j_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.x8j_1) | 0;
    result = imul(result, 31) + hashCode(this.y8j_1) | 0;
    return result;
  };
  protoOf(FuncDecl).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FuncDecl))
      return false;
    var tmp0_other_with_cast = other instanceof FuncDecl ? other : THROW_CCE();
    if (!(this.v8j_1 === tmp0_other_with_cast.v8j_1))
      return false;
    if (!this.w8j_1.equals(tmp0_other_with_cast.w8j_1))
      return false;
    if (!equals(this.x8j_1, tmp0_other_with_cast.x8j_1))
      return false;
    if (!equals(this.y8j_1, tmp0_other_with_cast.y8j_1))
      return false;
    return true;
  };
  function VarTypeAccessor() {
  }
  var ShaderType_VERTEX_instance;
  var ShaderType_FRAGMENT_instance;
  var ShaderType_entriesInitialized;
  function ShaderType_initEntries() {
    if (ShaderType_entriesInitialized)
      return Unit_getInstance();
    ShaderType_entriesInitialized = true;
    ShaderType_VERTEX_instance = new ShaderType('VERTEX', 0);
    ShaderType_FRAGMENT_instance = new ShaderType('FRAGMENT', 1);
  }
  function ShaderType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function VertexShader(info) {
    return VertexShader_0(info.a5_1, info.b5_1);
  }
  function FragmentShader(info) {
    return FragmentShader_0(info.a5_1, info.b5_1);
  }
  function VertexShader_0(stm, funcs) {
    var tmp;
    if (funcs === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = funcs;
    }
    funcs = tmp;
    return new Shader(ShaderType_VERTEX_getInstance(), stm, funcs);
  }
  function FragmentShader_0(stm, funcs) {
    var tmp;
    if (funcs === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = funcs;
    }
    funcs = tmp;
    return new Shader(ShaderType_FRAGMENT_getInstance(), stm, funcs);
  }
  function Sampler_0(index, stype) {
    return new Provider(index, stype);
  }
  function VarKind_TBOOL_getInstance() {
    VarKind_initEntries();
    return VarKind_TBOOL_instance;
  }
  function VarKind_TBYTE_getInstance() {
    VarKind_initEntries();
    return VarKind_TBYTE_instance;
  }
  function VarKind_TUNSIGNED_BYTE_getInstance() {
    VarKind_initEntries();
    return VarKind_TUNSIGNED_BYTE_instance;
  }
  function VarKind_TSHORT_getInstance() {
    VarKind_initEntries();
    return VarKind_TSHORT_instance;
  }
  function VarKind_TUNSIGNED_SHORT_getInstance() {
    VarKind_initEntries();
    return VarKind_TUNSIGNED_SHORT_instance;
  }
  function VarKind_TINT_getInstance() {
    VarKind_initEntries();
    return VarKind_TINT_instance;
  }
  function VarKind_TFLOAT_getInstance() {
    VarKind_initEntries();
    return VarKind_TFLOAT_instance;
  }
  function VarType_TVOID_getInstance() {
    VarType_initEntries();
    return VarType_TVOID_instance;
  }
  function VarType_Mat2_getInstance() {
    VarType_initEntries();
    return VarType_Mat2_instance;
  }
  function VarType_Mat4_getInstance() {
    VarType_initEntries();
    return VarType_Mat4_instance;
  }
  function VarType_Sampler1D_getInstance() {
    VarType_initEntries();
    return VarType_Sampler1D_instance;
  }
  function VarType_Sampler2D_getInstance() {
    VarType_initEntries();
    return VarType_Sampler2D_instance;
  }
  function VarType_Sampler3D_getInstance() {
    VarType_initEntries();
    return VarType_Sampler3D_instance;
  }
  function VarType_SamplerCube_getInstance() {
    VarType_initEntries();
    return VarType_SamplerCube_instance;
  }
  function VarType_Float1_getInstance() {
    VarType_initEntries();
    return VarType_Float1_instance;
  }
  function VarType_Float2_getInstance() {
    VarType_initEntries();
    return VarType_Float2_instance;
  }
  function VarType_Float3_getInstance() {
    VarType_initEntries();
    return VarType_Float3_instance;
  }
  function VarType_Float4_getInstance() {
    VarType_initEntries();
    return VarType_Float4_instance;
  }
  function VarType_SByte1_getInstance() {
    VarType_initEntries();
    return VarType_SByte1_instance;
  }
  function VarType_SByte2_getInstance() {
    VarType_initEntries();
    return VarType_SByte2_instance;
  }
  function VarType_SByte3_getInstance() {
    VarType_initEntries();
    return VarType_SByte3_instance;
  }
  function VarType_SByte4_getInstance() {
    VarType_initEntries();
    return VarType_SByte4_instance;
  }
  function VarType_UByte1_getInstance() {
    VarType_initEntries();
    return VarType_UByte1_instance;
  }
  function VarType_UByte2_getInstance() {
    VarType_initEntries();
    return VarType_UByte2_instance;
  }
  function VarType_UByte3_getInstance() {
    VarType_initEntries();
    return VarType_UByte3_instance;
  }
  function VarType_UByte4_getInstance() {
    VarType_initEntries();
    return VarType_UByte4_instance;
  }
  function VarType_SShort1_getInstance() {
    VarType_initEntries();
    return VarType_SShort1_instance;
  }
  function VarType_SShort2_getInstance() {
    VarType_initEntries();
    return VarType_SShort2_instance;
  }
  function VarType_SShort3_getInstance() {
    VarType_initEntries();
    return VarType_SShort3_instance;
  }
  function VarType_SShort4_getInstance() {
    VarType_initEntries();
    return VarType_SShort4_instance;
  }
  function VarType_UShort1_getInstance() {
    VarType_initEntries();
    return VarType_UShort1_instance;
  }
  function VarType_UShort2_getInstance() {
    VarType_initEntries();
    return VarType_UShort2_instance;
  }
  function VarType_UShort3_getInstance() {
    VarType_initEntries();
    return VarType_UShort3_instance;
  }
  function VarType_UShort4_getInstance() {
    VarType_initEntries();
    return VarType_UShort4_instance;
  }
  function VarType_SInt1_getInstance() {
    VarType_initEntries();
    return VarType_SInt1_instance;
  }
  function VarType_SInt2_getInstance() {
    VarType_initEntries();
    return VarType_SInt2_instance;
  }
  function VarType_SInt3_getInstance() {
    VarType_initEntries();
    return VarType_SInt3_instance;
  }
  function VarType_SInt4_getInstance() {
    VarType_initEntries();
    return VarType_SInt4_instance;
  }
  function Precision_DEFAULT_getInstance() {
    Precision_initEntries();
    return Precision_DEFAULT_instance;
  }
  function Precision_LOW_getInstance() {
    Precision_initEntries();
    return Precision_LOW_instance;
  }
  function Precision_MEDIUM_getInstance() {
    Precision_initEntries();
    return Precision_MEDIUM_instance;
  }
  function Precision_HIGH_getInstance() {
    Precision_initEntries();
    return Precision_HIGH_instance;
  }
  function SamplerVarType_Sampler2D_getInstance() {
    SamplerVarType_initEntries();
    return SamplerVarType_Sampler2D_instance;
  }
  function ShaderType_VERTEX_getInstance() {
    ShaderType_initEntries();
    return ShaderType_VERTEX_instance;
  }
  function ShaderType_FRAGMENT_getInstance() {
    ShaderType_initEntries();
    return ShaderType_FRAGMENT_instance;
  }
  function putAsciiString(_this__u8e3s4, str) {
    var n = 0;
    var indexedObject = str;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var c = charSequenceGet(indexedObject, inductionVariable);
      inductionVariable = inductionVariable + 1 | 0;
      if (get_size(_this__u8e3s4) >= n) {
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.code' call
        tmp$ret$0 = Char__toInt_impl_vasixd(c);
        setInt8(_this__u8e3s4, tmp1, tmp$ret$0);
      }
    }
    if (get_size(_this__u8e3s4) >= n) {
      var tmp2 = n;
      n = tmp2 + 1 | 0;
      setInt8(_this__u8e3s4, tmp2, 0);
    }
    return _this__u8e3s4;
  }
  function toAsciiString(_this__u8e3s4) {
    var out = '';
    var inductionVariable = 0;
    var last = get_size(_this__u8e3s4);
    if (inductionVariable < last)
      $l$loop: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var b = getInt8(_this__u8e3s4, n);
        if (b === 0)
          break $l$loop;
        out = out + new Char(numberToChar(b));
      }
       while (inductionVariable < last);
    return out;
  }
  function KmlGl$ContextInfo$current$lambda() {
    return null;
  }
  function ContextInfo() {
    var tmp = this;
    tmp.m8o_1 = KmlGl$ContextInfo$current$lambda;
  }
  protoOf(ContextInfo).toString = function () {
    return 'current=' + toString_0(this.m8o_1());
  };
  function Companion_52() {
    Companion_instance_52 = this;
    this.n8o_1 = 33309;
    this.o8o_1 = 33084;
    this.p8o_1 = 33085;
    this.q8o_1 = 256;
    this.r8o_1 = 1024;
    this.s8o_1 = 16384;
    this.t8o_1 = 0;
    this.u8o_1 = 1;
    this.v8o_1 = 0;
    this.w8o_1 = 1;
    this.x8o_1 = 2;
    this.y8o_1 = 3;
    this.z8o_1 = 4;
    this.a8p_1 = 5;
    this.b8p_1 = 6;
    this.c8p_1 = 0;
    this.d8p_1 = 1;
    this.e8p_1 = 768;
    this.f8p_1 = 769;
    this.g8p_1 = 770;
    this.h8p_1 = 771;
    this.i8p_1 = 772;
    this.j8p_1 = 773;
    this.k8p_1 = 774;
    this.l8p_1 = 775;
    this.m8p_1 = 776;
    this.n8p_1 = 32774;
    this.o8p_1 = 32777;
    this.p8p_1 = 32777;
    this.q8p_1 = 34877;
    this.r8p_1 = 32778;
    this.s8p_1 = 32779;
    this.t8p_1 = 32968;
    this.u8p_1 = 32969;
    this.v8p_1 = 32970;
    this.w8p_1 = 32971;
    this.x8p_1 = 32769;
    this.y8p_1 = 32770;
    this.z8p_1 = 32771;
    this.a8q_1 = 32772;
    this.b8q_1 = 32773;
    this.c8q_1 = 34962;
    this.d8q_1 = 34963;
    this.e8q_1 = 34964;
    this.f8q_1 = 34965;
    this.g8q_1 = 35040;
    this.h8q_1 = 35044;
    this.i8q_1 = 35048;
    this.j8q_1 = 34660;
    this.k8q_1 = 34661;
    this.l8q_1 = 34342;
    this.m8q_1 = 1028;
    this.n8q_1 = 1029;
    this.o8q_1 = 1032;
    this.p8q_1 = 3553;
    this.q8q_1 = 32879;
    this.r8q_1 = 2884;
    this.s8q_1 = 3042;
    this.t8q_1 = 3024;
    this.u8q_1 = 2960;
    this.v8q_1 = 2929;
    this.w8q_1 = 3089;
    this.x8q_1 = 32823;
    this.y8q_1 = 32926;
    this.z8q_1 = 32928;
    this.a8r_1 = 0;
    this.b8r_1 = 1280;
    this.c8r_1 = 1281;
    this.d8r_1 = 1282;
    this.e8r_1 = 1285;
    this.f8r_1 = 2304;
    this.g8r_1 = 2305;
    this.h8r_1 = 2849;
    this.i8r_1 = 33901;
    this.j8r_1 = 33902;
    this.k8r_1 = 2885;
    this.l8r_1 = 2886;
    this.m8r_1 = 2928;
    this.n8r_1 = 2930;
    this.o8r_1 = 2931;
    this.p8r_1 = 2932;
    this.q8r_1 = 2961;
    this.r8r_1 = 2962;
    this.s8r_1 = 2964;
    this.t8r_1 = 2965;
    this.u8r_1 = 2966;
    this.v8r_1 = 2967;
    this.w8r_1 = 2963;
    this.x8r_1 = 2968;
    this.y8r_1 = 34816;
    this.z8r_1 = 34817;
    this.a8s_1 = 34818;
    this.b8s_1 = 34819;
    this.c8s_1 = 36003;
    this.d8s_1 = 36004;
    this.e8s_1 = 36005;
    this.f8s_1 = 2978;
    this.g8s_1 = 3088;
    this.h8s_1 = 3106;
    this.i8s_1 = 3107;
    this.j8s_1 = 3317;
    this.k8s_1 = 3333;
    this.l8s_1 = 3379;
    this.m8s_1 = 3386;
    this.n8s_1 = 3408;
    this.o8s_1 = 3410;
    this.p8s_1 = 3411;
    this.q8s_1 = 3412;
    this.r8s_1 = 3413;
    this.s8s_1 = 3414;
    this.t8s_1 = 3415;
    this.u8s_1 = 10752;
    this.v8s_1 = 32824;
    this.w8s_1 = 32873;
    this.x8s_1 = 32936;
    this.y8s_1 = 32937;
    this.z8s_1 = 32938;
    this.a8t_1 = 32939;
    this.b8t_1 = 34466;
    this.c8t_1 = 34467;
    this.d8t_1 = 4352;
    this.e8t_1 = 4353;
    this.f8t_1 = 4354;
    this.g8t_1 = 33170;
    this.h8t_1 = 5120;
    this.i8t_1 = 5121;
    this.j8t_1 = 5122;
    this.k8t_1 = 5123;
    this.l8t_1 = 5124;
    this.m8t_1 = 5125;
    this.n8t_1 = 5126;
    this.o8t_1 = 5132;
    this.p8t_1 = 6402;
    this.q8t_1 = 6406;
    this.r8t_1 = 6407;
    this.s8t_1 = 6408;
    this.t8t_1 = 6409;
    this.u8t_1 = 6410;
    this.v8t_1 = 32819;
    this.w8t_1 = 32820;
    this.x8t_1 = 33635;
    this.y8t_1 = 35632;
    this.z8t_1 = 35633;
    this.a8u_1 = 34921;
    this.b8u_1 = 36347;
    this.c8u_1 = 36348;
    this.d8u_1 = 35661;
    this.e8u_1 = 35660;
    this.f8u_1 = 34930;
    this.g8u_1 = 36349;
    this.h8u_1 = 35663;
    this.i8u_1 = 35712;
    this.j8u_1 = 35714;
    this.k8u_1 = 35715;
    this.l8u_1 = 35717;
    this.m8u_1 = 35718;
    this.n8u_1 = 35719;
    this.o8u_1 = 35721;
    this.p8u_1 = 35722;
    this.q8u_1 = 35724;
    this.r8u_1 = 35725;
    this.s8u_1 = 512;
    this.t8u_1 = 513;
    this.u8u_1 = 514;
    this.v8u_1 = 515;
    this.w8u_1 = 516;
    this.x8u_1 = 517;
    this.y8u_1 = 518;
    this.z8u_1 = 519;
    this.a8v_1 = 7680;
    this.b8v_1 = 7681;
    this.c8v_1 = 7682;
    this.d8v_1 = 7683;
    this.e8v_1 = 5386;
    this.f8v_1 = 34055;
    this.g8v_1 = 34056;
    this.h8v_1 = 7936;
    this.i8v_1 = 7937;
    this.j8v_1 = 7938;
    this.k8v_1 = 7939;
    this.l8v_1 = 9728;
    this.m8v_1 = 9729;
    this.n8v_1 = 9984;
    this.o8v_1 = 9985;
    this.p8v_1 = 9986;
    this.q8v_1 = 9987;
    this.r8v_1 = 10240;
    this.s8v_1 = 10241;
    this.t8v_1 = 10242;
    this.u8v_1 = 10243;
    this.v8v_1 = 32882;
    this.w8v_1 = 5890;
    this.x8v_1 = 34067;
    this.y8v_1 = 34068;
    this.z8v_1 = 34069;
    this.a8w_1 = 34070;
    this.b8w_1 = 34071;
    this.c8w_1 = 34072;
    this.d8w_1 = 34073;
    this.e8w_1 = 34074;
    this.f8w_1 = 34076;
    this.g8w_1 = 33984;
    this.h8w_1 = 33985;
    this.i8w_1 = 33986;
    this.j8w_1 = 33987;
    this.k8w_1 = 33988;
    this.l8w_1 = 33989;
    this.m8w_1 = 33990;
    this.n8w_1 = 33991;
    this.o8w_1 = 33992;
    this.p8w_1 = 33993;
    this.q8w_1 = 33994;
    this.r8w_1 = 33995;
    this.s8w_1 = 33996;
    this.t8w_1 = 33997;
    this.u8w_1 = 33998;
    this.v8w_1 = 33999;
    this.w8w_1 = 34000;
    this.x8w_1 = 34001;
    this.y8w_1 = 34002;
    this.z8w_1 = 34003;
    this.a8x_1 = 34004;
    this.b8x_1 = 34005;
    this.c8x_1 = 34006;
    this.d8x_1 = 34007;
    this.e8x_1 = 34008;
    this.f8x_1 = 34009;
    this.g8x_1 = 34010;
    this.h8x_1 = 34011;
    this.i8x_1 = 34012;
    this.j8x_1 = 34013;
    this.k8x_1 = 34014;
    this.l8x_1 = 34015;
    this.m8x_1 = 34016;
    this.n8x_1 = 10497;
    this.o8x_1 = 33071;
    this.p8x_1 = 33648;
    this.q8x_1 = 35664;
    this.r8x_1 = 35665;
    this.s8x_1 = 35666;
    this.t8x_1 = 35667;
    this.u8x_1 = 35668;
    this.v8x_1 = 35669;
    this.w8x_1 = 35670;
    this.x8x_1 = 35671;
    this.y8x_1 = 35672;
    this.z8x_1 = 35673;
    this.a8y_1 = 35674;
    this.b8y_1 = 35675;
    this.c8y_1 = 35676;
    this.d8y_1 = 35678;
    this.e8y_1 = 35680;
    this.f8y_1 = 34338;
    this.g8y_1 = 34339;
    this.h8y_1 = 34340;
    this.i8y_1 = 34341;
    this.j8y_1 = 34922;
    this.k8y_1 = 34373;
    this.l8y_1 = 34975;
    this.m8y_1 = 35738;
    this.n8y_1 = 35739;
    this.o8y_1 = 35713;
    this.p8y_1 = 35716;
    this.q8y_1 = 35720;
    this.r8y_1 = 36346;
    this.s8y_1 = 36344;
    this.t8y_1 = 36345;
    this.u8y_1 = 36336;
    this.v8y_1 = 36337;
    this.w8y_1 = 36338;
    this.x8y_1 = 36339;
    this.y8y_1 = 36340;
    this.z8y_1 = 36341;
    this.a8z_1 = 36160;
    this.b8z_1 = 36161;
    this.c8z_1 = 32854;
    this.d8z_1 = 32855;
    this.e8z_1 = 36194;
    this.f8z_1 = 33189;
    this.g8z_1 = 6401;
    this.h8z_1 = 36168;
    this.i8z_1 = 36162;
    this.j8z_1 = 36163;
    this.k8z_1 = 36164;
    this.l8z_1 = 36176;
    this.m8z_1 = 36177;
    this.n8z_1 = 36178;
    this.o8z_1 = 36179;
    this.p8z_1 = 36180;
    this.q8z_1 = 36181;
    this.r8z_1 = 36048;
    this.s8z_1 = 36049;
    this.t8z_1 = 36050;
    this.u8z_1 = 36051;
    this.v8z_1 = 36064;
    this.w8z_1 = 36096;
    this.x8z_1 = 33306;
    this.y8z_1 = 34041;
    this.z8z_1 = 36128;
    this.a90_1 = 0;
    this.b90_1 = 36053;
    this.c90_1 = 36054;
    this.d90_1 = 36055;
    this.e90_1 = 36057;
    this.f90_1 = 36061;
    this.g90_1 = 36006;
    this.h90_1 = 36007;
    this.i90_1 = 34024;
    this.j90_1 = 1286;
    this.k90_1 = 37120;
    this.l90_1 = 34836;
    this.m90_1 = 3313;
    this.n90_1 = 3314;
    this.o90_1 = 3316;
    this.p90_1 = 3315;
    this.q90_1 = 3312;
    this.r90_1 = 37441;
    this.s90_1 = 36197;
    this.t90_1 = 35345;
    this.u90_1 = 35368;
    this.v90_1 = 35380;
  }
  var Companion_instance_52;
  function Companion_getInstance_68() {
    if (Companion_instance_52 == null)
      new Companion_52();
    return Companion_instance_52;
  }
  function KmlGl$isFloatTextureSupported$delegate$lambda(this$0) {
    return function () {
      return this$0.w90().q('GL_OES_texture_float') ? true : this$0.w90().q('GL_ARB_texture_float');
    };
  }
  function KmlGl() {
    Companion_getInstance_68();
    this.z85_1 = new Mixin();
    this.a86_1 = new ContextInfo();
    this.b86_1 = null;
    var tmp = this;
    tmp.c86_1 = lazy(KmlGl$isFloatTextureSupported$delegate$lambda(this));
  }
  protoOf(KmlGl).d1i = function (_set____db54di) {
    this.z85_1.d1i(_set____db54di);
  };
  protoOf(KmlGl).e1i = function () {
    return this.z85_1.e1i();
  };
  protoOf(KmlGl).o89 = function (texture) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).h8e = function (program, shader) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).g8e = function (program, index, name) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i88 = function (target, buffer) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).a8d = function (target, framebuffer) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).h8d = function (target, renderbuffer) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).y8c = function (target, texture) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).r8a = function (modeRGB, modeAlpha) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).s8a = function (sfactorRGB, dfactorRGB, sfactorAlpha, dfactorAlpha) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).s89 = function (target, size, data, usage) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).r89 = function (target, offset, size, data) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).n8a = function (mask) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i8a = function (red, green, blue, alpha) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).k8a = function (d) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).m8a = function (s) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).o8d = function (red, green, blue, alpha) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).y8d = function (shader) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).a8e = function () {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).w8d = function (type) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).n8b = function (mode) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).x90 = function (n, items) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).y90 = function (n, items) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).v8d = function (program) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).z90 = function (n, items) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).u8d = function (shader) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).a91 = function (n, items) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).n8d = function (func) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).j8a = function (flag) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).m8d = function (n, f) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).w8a = function (cap) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).f8c = function (index) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).d8b = function (mode, first, count) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).f8b = function (mode, count, type, indices) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).x8a = function (cap) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).j8c = function (index) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).ui = function () {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).k8d = function (target, attachment, renderbuffertarget, renderbuffer) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).j8d = function (target, attachment, textarget, texture, level) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).l8d = function (mode) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).b91 = function (n, buffers) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).w89 = function (target) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).c91 = function (n, framebuffers) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).d91 = function (n, renderbuffers) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).e91 = function (n, textures) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).s8d = function (program, name) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).z8d = function () {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).f91 = function (pname, data) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).g91 = function (program, pname, params) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).h91 = function (shader, pname, params) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i91 = function (shader, bufSize, length, infoLog) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).d86 = function (name) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i8e = function (program) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).t89 = function (pname, param) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).x8c = function (x, y, width, height, format, type, pixels) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i8d = function (target, internalformat, width, height) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).p8d = function (x, y, width, height) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).x8d = function (shader, string) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).z8a = function (func, ref, mask) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).y8a = function (face, func, ref, mask) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).l8a = function (mask) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).c8b = function (face, mask) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).b8b = function (fail, zfail, zpass) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).a8b = function (face, sfail, dpfail, dppass) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).u89 = function (target, level, internalformat, width, height, border, format, type, pixels) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).v89 = function (target, level, internalformat, format, type, data) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).n89 = function (target, pname, param) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).j89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).h87 = function (location, v0) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).c89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).k89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).d89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).l89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).e89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).m89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).f89 = function (location, count, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).g89 = function (location, count, transpose, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).h89 = function (location, count, transpose, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).i89 = function (location, count, transpose, value) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).t8d = function (program) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).k8c = function (index, size, type, normalized, stride, pointer) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).z8c = function (x, y, width, height) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).t8a = function (cap, enable) {
    if (enable) {
      this.x8a(cap);
    } else {
      this.w8a(cap);
    }
    return enable;
  };
  protoOf(KmlGl).e8b = function (mode, first, count, instancecount) {
    unsupported('Not supported instanced drawing');
  };
  protoOf(KmlGl).g8b = function (mode, count, type, indices, instancecount) {
    unsupported('Not supported instanced drawing');
  };
  protoOf(KmlGl).e8c = function (index, divisor) {
    unsupported();
  };
  protoOf(KmlGl).r8c = function (target, index, buffer, offset, size) {
    unsupported('Not supported uniform buffers ' + getKClassFromExpression(this));
  };
  protoOf(KmlGl).i87 = function (program, name) {
    unsupported('Not supported uniform buffers ' + getKClassFromExpression(this));
  };
  protoOf(KmlGl).j87 = function (program, uniformBlockIndex, uniformBlockBinding) {
    unsupported('Not supported uniform buffers ' + getKClassFromExpression(this));
  };
  protoOf(KmlGl).j91 = function (n, arrays) {
    unsupported('Not supported vertex arrays ' + getKClassFromExpression(this));
  };
  protoOf(KmlGl).g8c = function (array) {
    unsupported('Not supported vertex arrays ' + getKClassFromExpression(this));
  };
  protoOf(KmlGl).p89 = function () {
    return Companion_getInstance_63().y8f();
  };
  protoOf(KmlGl).d8a = function () {
    return Unit_getInstance();
  };
  protoOf(KmlGl).l8b = function (contextVersion) {
    return Unit_getInstance();
  };
  protoOf(KmlGl).k91 = function (name, index) {
    throw new NotImplementedError();
  };
  protoOf(KmlGl).w90 = function () {
    if (this.b86_1 == null) {
      var tmp = this;
      var tmp_0;
      try {
        Companion_getInstance_68();
        var numExtensions = getIntegerv(this, 33309);
        if (numExtensions <= 0) {
          // Inline function 'kotlin.TODO' call
          throw new NotImplementedError();
        }
        var tmp$ret$4;
        // Inline function 'kotlin.collections.mapNotNull' call
        var tmp1_mapNotNull = until(0, numExtensions);
        var tmp$ret$3;
        // Inline function 'kotlin.collections.mapNotNullTo' call
        var tmp0_mapNotNullTo = ArrayList_init_$Create$();
        // Inline function 'kotlin.collections.forEach' call
        var inductionVariable = tmp1_mapNotNull.p1_1;
        var last = tmp1_mapNotNull.q1_1;
        if (inductionVariable <= last)
          do {
            var element = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
            var tmp$ret$1;
            // Inline function 'korlibs.kgl.KmlGl.<get-extensions>.<anonymous>' call
            var tmp$ret$0;
            // Inline function 'korlibs.kgl.KmlGl.EXTENSIONS' call
            Companion_getInstance_68();
            tmp$ret$0 = 7939;
            tmp$ret$1 = this.k91(tmp$ret$0, element);
            var tmp0_safe_receiver = tmp$ret$1;
            if (tmp0_safe_receiver == null)
              null;
            else {
              var tmp$ret$2;
              // Inline function 'kotlin.let' call
              // Inline function 'kotlin.contracts.contract' call
              tmp0_mapNotNullTo.b(tmp0_safe_receiver);
              tmp$ret$2 = Unit_getInstance();
            }
          }
           while (!(element === last));
        tmp$ret$3 = tmp0_mapNotNullTo;
        tmp$ret$4 = tmp$ret$3;
        tmp_0 = toSet(tmp$ret$4);
      } catch ($p) {
        var tmp_1;
        if ($p instanceof Error) {
          var e = $p;
          var tmp$ret$5;
          // Inline function 'korlibs.kgl.KmlGl.EXTENSIONS' call
          Companion_getInstance_68();
          tmp$ret$5 = 7939;
          tmp_1 = toSet(split(this.d86(tmp$ret$5), [' ']));
        } else {
          throw $p;
        }
        tmp_0 = tmp_1;
      }
      tmp.b86_1 = tmp_0;
    }
    return ensureNotNull(this.b86_1);
  };
  protoOf(KmlGl).e8a = function () {
    return this.w90();
  };
  var logger;
  var tempNBufferByte;
  function get_tempNBuffer1() {
    _init_properties_KmlGlExt_kt__r5nfv2();
    return tempNBuffer1;
  }
  var tempNBuffer1;
  var tempNBuffer4;
  function getIntegerv(_this__u8e3s4, pname) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.getIntegerv.<anonymous>' call
    _this__u8e3s4.f91(pname, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function getInteger(_this__u8e3s4, pname) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    return getIntegerv(_this__u8e3s4, pname);
  }
  function genVertexArray(_this__u8e3s4) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.genVertexArray.<anonymous>' call
    _this__u8e3s4.j91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function getProgramiv(_this__u8e3s4, program, type) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.getProgramiv.<anonymous>' call
    _this__u8e3s4.g91(program, type, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function getShaderiv(_this__u8e3s4, shader, type) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.getShaderiv.<anonymous>' call
    _this__u8e3s4.h91(shader, type, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function getShaderInfoLog(_this__u8e3s4, shader) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$7;
    // Inline function 'korlibs.kgl.getInfoLog' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.KmlGl.INFO_LOG_LENGTH' call
    Companion_getInstance_68();
    tmp$ret$0 = 35716;
    var size = getShaderiv(_this__u8e3s4, shader, tmp$ret$0);
    var tmp$ret$6;
    // Inline function 'korlibs.memory.BufferTemp' call
    var tmp1_BufferTemp = 4;
    var tmp$ret$5;
    // Inline function 'kotlin.run' call
    var tmp0_run = allocDirect(Companion_getInstance_7(), tmp1_BufferTemp);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.kgl.getInfoLog.<anonymous>' call
    var tmp$ret$3;
    // Inline function 'korlibs.memory.BufferTemp' call
    var tmp$ret$2;
    // Inline function 'kotlin.run' call
    var tmp0_run_0 = allocDirect(Companion_getInstance_7(), size);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'korlibs.kgl.getInfoLog.<anonymous>.<anonymous>' call
    _this__u8e3s4.i91(shader, size, tmp0_run, tmp0_run_0);
    tmp$ret$1 = toAsciiString(tmp0_run_0);
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    return tmp$ret$7;
  }
  function genBuffer(_this__u8e3s4) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.genBuffer.<anonymous>' call
    _this__u8e3s4.b91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function deleteBuffer(_this__u8e3s4, id) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, id);
    // Inline function 'korlibs.kgl.deleteBuffer.<anonymous>' call
    _this__u8e3s4.x90(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
  }
  function genTexture(_this__u8e3s4) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.genTexture.<anonymous>' call
    _this__u8e3s4.e91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function deleteTexture(_this__u8e3s4, id) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, id);
    // Inline function 'korlibs.kgl.deleteTexture.<anonymous>' call
    _this__u8e3s4.a91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
  }
  function genRenderbuffer(_this__u8e3s4) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.genRenderbuffer.<anonymous>' call
    _this__u8e3s4.d91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function genFramebuffer(_this__u8e3s4) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, 0);
    // Inline function 'korlibs.kgl.genFramebuffer.<anonymous>' call
    _this__u8e3s4.c91(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function deleteRenderbuffer(_this__u8e3s4, id) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, id);
    // Inline function 'korlibs.kgl.deleteRenderbuffer.<anonymous>' call
    _this__u8e3s4.z90(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
  }
  function deleteFramebuffer(_this__u8e3s4, id) {
    _init_properties_KmlGlExt_kt__r5nfv2();
    var tmp$ret$2;
    // Inline function 'korlibs.kgl.tempInt1Buffer' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_tempNBuffer1_$accessor$1ucca1a_al8duo();
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.tempInt1Buffer.<anonymous>' call
    setInt32(tmp0_let, 0, id);
    // Inline function 'korlibs.kgl.deleteFramebuffer.<anonymous>' call
    _this__u8e3s4.y90(1, tmp0_let);
    tmp$ret$0 = getInt32(tmp0_let, 0);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
  }
  function _get_tempNBuffer1_$accessor$1ucca1a_al8duo() {
    _init_properties_KmlGlExt_kt__r5nfv2();
    return get_tempNBuffer1();
  }
  var properties_initialized_KmlGlExt_kt_yrhd4c;
  function _init_properties_KmlGlExt_kt__r5nfv2() {
    if (properties_initialized_KmlGlExt_kt_yrhd4c) {
    } else {
      properties_initialized_KmlGlExt_kt_yrhd4c = true;
      logger = Companion_getInstance_3().b1n('KmlGlException');
      tempNBufferByte = Buffer(1, true);
      tempNBuffer1 = Buffer(4, true);
      tempNBuffer4 = Buffer(16, true);
    }
  }
  function DEFAULT() {
    DEFAULT_instance = this;
  }
  var DEFAULT_instance;
  function DEFAULT_getInstance() {
    if (DEFAULT_instance == null)
      new DEFAULT();
    return DEFAULT_instance;
  }
  function DeviceDimensionsProvider() {
  }
  var GLOBAL_CHECK_GL;
  var Cursor_DEFAULT_instance;
  var Cursor_CROSSHAIR_instance;
  var Cursor_TEXT_instance;
  var Cursor_HAND_instance;
  var Cursor_MOVE_instance;
  var Cursor_WAIT_instance;
  var Cursor_RESIZE_EAST_instance;
  var Cursor_RESIZE_WEST_instance;
  var Cursor_RESIZE_SOUTH_instance;
  var Cursor_RESIZE_NORTH_instance;
  var Cursor_RESIZE_NORTH_EAST_instance;
  var Cursor_RESIZE_NORTH_WEST_instance;
  var Cursor_RESIZE_SOUTH_EAST_instance;
  var Cursor_RESIZE_SOUTH_WEST_instance;
  function Companion_53() {
    Companion_instance_53 = this;
    this.p91_1 = mapOf([to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 0)), Cursor_RESIZE_EAST_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 1)), Cursor_RESIZE_SOUTH_EAST_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 2)), Cursor_RESIZE_SOUTH_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 3)), Cursor_RESIZE_SOUTH_WEST_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 4)), Cursor_RESIZE_WEST_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 5)), Cursor_RESIZE_NORTH_WEST_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 6)), Cursor_RESIZE_NORTH_getInstance()), to(new Angle(Angle__times_impl_v3s9y2(get_degrees(45), 7)), Cursor_RESIZE_NORTH_EAST_getInstance())]);
  }
  protoOf(Companion_53).q91 = function (anchor) {
    var tmp0_subject = anchor;
    return tmp0_subject.equals(Companion_getInstance_12().k3q_1) ? Cursor_RESIZE_NORTH_WEST_getInstance() : tmp0_subject.equals(Companion_getInstance_12().t3q()) ? Cursor_RESIZE_NORTH_getInstance() : tmp0_subject.equals(Companion_getInstance_12().m3q_1) ? Cursor_RESIZE_NORTH_EAST_getInstance() : tmp0_subject.equals(Companion_getInstance_12().u3q()) ? Cursor_RESIZE_WEST_getInstance() : tmp0_subject.equals(Companion_getInstance_12().v3q()) ? Cursor_RESIZE_EAST_getInstance() : tmp0_subject.equals(Companion_getInstance_12().q3q_1) ? Cursor_RESIZE_SOUTH_WEST_getInstance() : tmp0_subject.equals(Companion_getInstance_12().w3q()) ? Cursor_RESIZE_SOUTH_getInstance() : tmp0_subject.equals(Companion_getInstance_12().s3q_1) ? Cursor_RESIZE_SOUTH_EAST_getInstance() : null;
  };
  var Companion_instance_53;
  function Companion_getInstance_69() {
    Cursor_initEntries();
    if (Companion_instance_53 == null)
      new Companion_53();
    return Companion_instance_53;
  }
  var Cursor_entriesInitialized;
  function Cursor_initEntries() {
    if (Cursor_entriesInitialized)
      return Unit_getInstance();
    Cursor_entriesInitialized = true;
    Cursor_DEFAULT_instance = new Cursor('DEFAULT', 0);
    Cursor_CROSSHAIR_instance = new Cursor('CROSSHAIR', 1);
    Cursor_TEXT_instance = new Cursor('TEXT', 2);
    Cursor_HAND_instance = new Cursor('HAND', 3);
    Cursor_MOVE_instance = new Cursor('MOVE', 4);
    Cursor_WAIT_instance = new Cursor('WAIT', 5);
    Cursor_RESIZE_EAST_instance = new Cursor('RESIZE_EAST', 6);
    Cursor_RESIZE_WEST_instance = new Cursor('RESIZE_WEST', 7);
    Cursor_RESIZE_SOUTH_instance = new Cursor('RESIZE_SOUTH', 8);
    Cursor_RESIZE_NORTH_instance = new Cursor('RESIZE_NORTH', 9);
    Cursor_RESIZE_NORTH_EAST_instance = new Cursor('RESIZE_NORTH_EAST', 10);
    Cursor_RESIZE_NORTH_WEST_instance = new Cursor('RESIZE_NORTH_WEST', 11);
    Cursor_RESIZE_SOUTH_EAST_instance = new Cursor('RESIZE_SOUTH_EAST', 12);
    Cursor_RESIZE_SOUTH_WEST_instance = new Cursor('RESIZE_SOUTH_WEST', 13);
    Companion_getInstance_69();
  }
  function GameWindow$MenuItem$_init_$lambda_465oqx() {
    return Unit_getInstance();
  }
  var Quality_PERFORMANCE_instance;
  var Quality_QUALITY_instance;
  var Quality_AUTOMATIC_instance;
  var Quality_entriesInitialized;
  function Quality_initEntries() {
    if (Quality_entriesInitialized)
      return Unit_getInstance();
    Quality_entriesInitialized = true;
    Quality_PERFORMANCE_instance = new Quality('PERFORMANCE', 0);
    Quality_QUALITY_instance = new Quality('QUALITY', 1);
    Quality_AUTOMATIC_instance = new Quality('AUTOMATIC', 2);
  }
  function Cursor(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function MenuItem(text, enabled, children, action) {
    enabled = enabled === VOID ? true : enabled;
    children = children === VOID ? null : children;
    var tmp;
    if (action === VOID) {
      tmp = GameWindow$MenuItem$_init_$lambda_465oqx;
    } else {
      tmp = action;
    }
    action = tmp;
    this.r91_1 = text;
    this.s91_1 = enabled;
    this.t91_1 = children;
    this.u91_1 = action;
  }
  protoOf(MenuItem).toString = function () {
    return 'MenuItem(text=' + this.r91_1 + ', enabled=' + this.s91_1 + ', children=' + this.t91_1 + ', action=' + this.u91_1 + ')';
  };
  protoOf(MenuItem).hashCode = function () {
    var result = this.r91_1 == null ? 0 : getStringHashCode(this.r91_1);
    result = imul(result, 31) + (this.s91_1 | 0) | 0;
    result = imul(result, 31) + (this.t91_1 == null ? 0 : hashCode(this.t91_1)) | 0;
    result = imul(result, 31) + hashCode(this.u91_1) | 0;
    return result;
  };
  protoOf(MenuItem).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MenuItem))
      return false;
    var tmp0_other_with_cast = other instanceof MenuItem ? other : THROW_CCE();
    if (!(this.r91_1 == tmp0_other_with_cast.r91_1))
      return false;
    if (!(this.s91_1 === tmp0_other_with_cast.s91_1))
      return false;
    if (!equals(this.t91_1, tmp0_other_with_cast.t91_1))
      return false;
    if (!equals(this.u91_1, tmp0_other_with_cast.u91_1))
      return false;
    return true;
  };
  function CoroutineKey() {
    CoroutineKey_instance = this;
    this.v91_1 = 60;
    this.w91_1 = Companion_getInstance_3().b1n('GameWindow');
    this.x91_1 = new MenuItem(null);
  }
  var CoroutineKey_instance;
  function CoroutineKey_getInstance() {
    if (CoroutineKey_instance == null)
      new CoroutineKey();
    return CoroutineKey_instance;
  }
  function _get_fpsCached__581dty($this) {
    return $this.v92_1.g9($this, fpsCached$factory());
  }
  function Quality(name, ordinal) {
    Enum.call(this, name, ordinal);
    this.o94_1 = 4000000;
  }
  protoOf(Quality).p94 = function (width, height, devicePixelRatio, targetPixels) {
    var tmp0_subject = this;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = 1.0;
        break;
      case 1:
        tmp = devicePixelRatio;
        break;
      case 2:
        var tmp$ret$1;
        $l$block: {
          // Inline function 'kotlin.collections.firstOrNull' call
          var tmp0_firstOrNull = listOf_0([devicePixelRatio, 2.0, 1.0]);
          var tmp0_iterator = tmp0_firstOrNull.h();
          while (tmp0_iterator.j()) {
            var element = tmp0_iterator.k();
            var tmp$ret$0;
            // Inline function 'korlibs.render.Quality.computeTargetScale.<anonymous>' call
            tmp$ret$0 = imul(width, height) * element <= targetPixels;
            if (tmp$ret$0) {
              tmp$ret$1 = element;
              break $l$block;
            }
          }
          tmp$ret$1 = null;
        }

        var tmp1_elvis_lhs = tmp$ret$1;
        tmp = tmp1_elvis_lhs == null ? 1.0 : tmp1_elvis_lhs;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(Quality).q94 = function (width, height, devicePixelRatio, targetPixels, $super) {
    targetPixels = targetPixels === VOID ? this.o94_1 : targetPixels;
    return $super === VOID ? this.p94(width, height, devicePixelRatio, targetPixels) : $super.p94.call(this, width, height, devicePixelRatio, targetPixels);
  };
  function GameWindow$fpsCached$delegate$lambda(this$0) {
    return function () {
      return this$0.r94();
    };
  }
  function GameWindow$loop$slambda($entry, this$0, resultContinuation) {
    this.a95_1 = $entry;
    this.b95_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GameWindow$loop$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameWindow$loop$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(GameWindow$loop$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.a95_1(this.b95_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GameWindow$loop$slambda).g1p = function (completion) {
    var i = new GameWindow$loop$slambda(this.a95_1, this.b95_1, completion);
    return i;
  };
  function GameWindow$loop$slambda_0($entry, this$0, resultContinuation) {
    var i = new GameWindow$loop$slambda($entry, this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function _no_name_provided__qut3iv(this$0) {
    this.d95_1 = this$0;
    ObservableProperty.call(this, true);
  }
  protoOf(_no_name_provided__qut3iv).e95 = function (property, oldValue, newValue) {
    var tmp0_safe_receiver = this.d95_1.p93_1;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver(newValue);
    return Unit_getInstance();
  };
  protoOf(_no_name_provided__qut3iv).f9 = function (property, oldValue, newValue) {
    var tmp = (!(oldValue == null) ? typeof oldValue === 'boolean' : false) ? oldValue : THROW_CCE();
    return this.e95(property, tmp, (!(newValue == null) ? typeof newValue === 'boolean' : false) ? newValue : THROW_CCE());
  };
  function Cursor_DEFAULT_getInstance() {
    Cursor_initEntries();
    return Cursor_DEFAULT_instance;
  }
  function Cursor_TEXT_getInstance() {
    Cursor_initEntries();
    return Cursor_TEXT_instance;
  }
  function Cursor_HAND_getInstance() {
    Cursor_initEntries();
    return Cursor_HAND_instance;
  }
  function Cursor_RESIZE_EAST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_EAST_instance;
  }
  function Cursor_RESIZE_WEST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_WEST_instance;
  }
  function Cursor_RESIZE_SOUTH_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_SOUTH_instance;
  }
  function Cursor_RESIZE_NORTH_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_NORTH_instance;
  }
  function Cursor_RESIZE_NORTH_EAST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_NORTH_EAST_instance;
  }
  function Cursor_RESIZE_NORTH_WEST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_NORTH_WEST_instance;
  }
  function Cursor_RESIZE_SOUTH_EAST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_SOUTH_EAST_instance;
  }
  function Cursor_RESIZE_SOUTH_WEST_getInstance() {
    Cursor_initEntries();
    return Cursor_RESIZE_SOUTH_WEST_instance;
  }
  function Quality_PERFORMANCE_getInstance() {
    Quality_initEntries();
    return Quality_PERFORMANCE_instance;
  }
  function Quality_AUTOMATIC_getInstance() {
    Quality_initEntries();
    return Quality_AUTOMATIC_instance;
  }
  function $waitCloseCOROUTINE$0(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.n95_1 = _this__u8e3s4;
  }
  protoOf($waitCloseCOROUTINE$0).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!this.n95_1.l93_1) {
              this.y7_1 = 3;
              continue $sm;
            }

            this.y7_1 = 2;
            suspendResult = delay(this.n95_1, Companion_getInstance().b18(100.0), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            return Unit_getInstance();
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $loopCOROUTINE$1(_this__u8e3s4, entry, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.w95_1 = _this__u8e3s4;
    this.x95_1 = entry;
  }
  protoOf($loopCOROUTINE$1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.y7_1 = 1;
            suspendResult = this.w95_1.b96(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.y95_1 = suspendResult;
            launchImmediately(this.y95_1, GameWindow$loop$slambda_0(this.x95_1, this.w95_1, null));
            ;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            if (!this.w95_1.l93_1) {
              this.y7_1 = 5;
              continue $sm;
            }

            this.z95_1 = this.w95_1.c96();
            this.a96_1 = TimeSpan__minus_impl_5w5v8s(this.w95_1.d96(), this.z95_1);
            if (TimeSpan__compareTo_impl_zidry4(this.a96_1, Companion_getInstance().y17_1) > 0) {
              this.y7_1 = 3;
              suspendResult = delay(this.w95_1, this.a96_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 3:
            this.y7_1 = 4;
            continue $sm;
          case 4:
            this.y7_1 = 2;
            continue $sm;
          case 5:
            return Unit_getInstance();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function GameWindow() {
    CoroutineKey_getInstance();
    BaseEventListener.call(this);
    this.c92_1 = new Mixin();
    this.d92_1 = Cursor_DEFAULT_getInstance();
    this.e92_1 = false;
    this.f92_1 = new AGDummy();
    this.g92_1 = new GameWindowCoroutineDispatcher();
    this.h92_1 = new PauseEvent();
    this.i92_1 = new ResumeEvent();
    this.j92_1 = new StopEvent();
    this.k92_1 = new DestroyEvent();
    this.l92_1 = new RenderEvent();
    this.m92_1 = new InitEvent();
    this.n92_1 = new DisposeEvent();
    this.o92_1 = new FullScreenEvent();
    this.p92_1 = new ReshapeEvent();
    this.q92_1 = new KeyEvent();
    this.r92_1 = new MouseEvent();
    this.s92_1 = new GestureEvent();
    this.t92_1 = new TouchBuilder();
    this.u92_1 = new DropFileEvent();
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    tmp$ret$0 = Companion_getInstance().b18(1000.0);
    var tmp_0 = tmp$ret$0;
    tmp.v92_1 = new IntTimedCache(tmp_0, GameWindow$fpsCached$delegate$lambda(this));
    var tmp_1 = this;
    CoroutineKey_getInstance();
    tmp_1.w92_1 = 60;
    this.x92_1 = 0;
    this.y92_1 = 0;
    this.z92_1 = true;
    this.a93_1 = null;
    this.b93_1 = false;
    this.c93_1 = false;
    this.d93_1 = Colors_getInstance().l4s_1;
    this.e93_1 = new Signal();
    this.f93_1 = new Signal();
    this.g93_1 = null;
    this.h93_1 = false;
    this.i93_1 = true;
    this.j93_1 = exitProcessOnClose$factory(this);
    this.k93_1 = 0;
    this.l93_1 = true;
    this.m93_1 = false;
    var tmp_2 = this;
    var tmp$ret$1;
    // Inline function 'korlibs.time.milliseconds' call
    tmp$ret$1 = Companion_getInstance().b18(0.0);
    tmp_2.n93_1 = tmp$ret$1;
    var tmp_3 = this;
    var tmp$ret$2;
    // Inline function 'korlibs.time.milliseconds' call
    tmp$ret$2 = Companion_getInstance().b18(0.0);
    tmp_3.o93_1 = tmp$ret$2;
    this.p93_1 = null;
    var tmp_4 = this;
    var tmp$ret$3;
    // Inline function 'kotlin.properties.Delegates.observable' call
    var tmp0_observable = Delegates_getInstance();
    tmp$ret$3 = new _no_name_provided__qut3iv(this);
    tmp_4.q93_1 = tmp$ret$3;
    this.r93_1 = false;
    this.s93_1 = -1;
    this.t93_1 = -1;
    this.u93_1 = -1;
    this.v93_1 = -1;
    this.w93_1 = false;
    this.x93_1 = false;
    this.y93_1 = false;
    this.z93_1 = 0;
    this.a94_1 = Companion_getInstance().y17_1;
    this.b94_1 = booleanArray(Companion_getInstance_35().m7j_1);
    this.c94_1 = 0;
    this.d94_1 = 0.0;
    this.e94_1 = 0.0;
    this.f94_1 = 0.0;
    this.g94_1 = false;
    this.h94_1 = new GamepadInfoEmitter(this);
  }
  protoOf(GameWindow).d1i = function (_set____db54di) {
    this.c92_1.d1i(_set____db54di);
  };
  protoOf(GameWindow).e1i = function () {
    return this.c92_1.e1i();
  };
  protoOf(GameWindow).e96 = function (windowRect) {
  };
  protoOf(GameWindow).f96 = function (force, config) {
  };
  protoOf(GameWindow).g96 = function (force, config, $super) {
    force = force === VOID ? true : force;
    config = config === VOID ? null : config;
    var tmp;
    if ($super === VOID) {
      this.f96(force, config);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.f96.call(this, force, config);
    }
    return tmp;
  };
  protoOf(GameWindow).h96 = function () {
  };
  protoOf(GameWindow).i96 = function (_set____db54di) {
    this.d92_1 = _set____db54di;
  };
  protoOf(GameWindow).j96 = function () {
    return this.d92_1;
  };
  protoOf(GameWindow).e1 = function () {
    return CoroutineKey_getInstance();
  };
  protoOf(GameWindow).k96 = function () {
    return this.f92_1;
  };
  protoOf(GameWindow).l96 = function () {
    return this.g92_1;
  };
  protoOf(GameWindow).m96 = function (coroutineContext) {
    return coroutineContext.v8(this.l96());
  };
  protoOf(GameWindow).b96 = function ($completion) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.getCoroutineContext' call
    tmp$ret$0 = $completion.s5();
    return this.m96(tmp$ret$0);
  };
  protoOf(GameWindow).n96 = function (_this__u8e3s4, block) {
    block(_this__u8e3s4);
    return _this__u8e3s4;
  };
  protoOf(GameWindow).o96 = function (block) {
    this.s7c(Companion_getInstance_27(), block);
  };
  protoOf(GameWindow).d96 = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.time.microseconds' call
    var tmp0__get_microseconds__mq1imi = 1000000.0 / this.p96();
    tmp$ret$0 = Companion_getInstance().l18(tmp0__get_microseconds__mq1imi);
    return tmp$ret$0;
  };
  protoOf(GameWindow).r94 = function () {
    return 60;
  };
  protoOf(GameWindow).q96 = function (name, time) {
  };
  protoOf(GameWindow).p96 = function () {
    return _get_fpsCached__581dty(this);
  };
  protoOf(GameWindow).r96 = function (value) {
    return Unit_getInstance();
  };
  protoOf(GameWindow).x18 = function () {
    return this.x92_1;
  };
  protoOf(GameWindow).y18 = function () {
    return this.y92_1;
  };
  protoOf(GameWindow).s96 = function (_set____db54di) {
    this.a93_1 = _set____db54di;
  };
  protoOf(GameWindow).t96 = function (_set____db54di) {
    this.b93_1 = _set____db54di;
  };
  protoOf(GameWindow).u96 = function (_set____db54di) {
    this.c93_1 = _set____db54di;
  };
  protoOf(GameWindow).v96 = function (_set____db54di) {
    this.d93_1 = _set____db54di;
  };
  protoOf(GameWindow).w96 = function () {
    return this.d93_1;
  };
  protoOf(GameWindow).x96 = function (value) {
    return Unit_getInstance();
  };
  protoOf(GameWindow).y96 = function () {
    return Quality_AUTOMATIC_getInstance();
  };
  protoOf(GameWindow).z96 = function (value) {
    this.h93_1 = value;
    this.f93_1.h1q(value);
    if (value) {
      invoke(this.e93_1);
    }
  };
  protoOf(GameWindow).a97 = function () {
    return this.h93_1;
  };
  protoOf(GameWindow).g7s = function (width, height) {
    return Unit_getInstance();
  };
  protoOf(GameWindow).b97 = function (exitCode) {
    return this.c97(exitCode);
  };
  protoOf(GameWindow).d97 = function (exitCode, $super) {
    exitCode = exitCode === VOID ? 0 : exitCode;
    var tmp;
    if ($super === VOID) {
      this.b97(exitCode);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.b97.call(this, exitCode);
    }
    return tmp;
  };
  protoOf(GameWindow).c97 = function (exitCode) {
    if (this.m93_1)
      return Unit_getInstance();
    this.m93_1 = true;
    this.l93_1 = false;
    this.k93_1 = exitCode;
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = CoroutineKey_getInstance().w91_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'korlibs.render.GameWindow.close.<anonymous>' call
      tmp$ret$0 = 'GameWindow.close';
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    this.l96().n1q();
    cancelChildren(this.l96());
  };
  protoOf(GameWindow).m97 = function (exitCode, $super) {
    exitCode = exitCode === VOID ? 0 : exitCode;
    var tmp;
    if ($super === VOID) {
      this.c97(exitCode);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.c97.call(this, exitCode);
    }
    return tmp;
  };
  protoOf(GameWindow).n97 = function ($completion) {
    var tmp = new $waitCloseCOROUTINE$0(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameWindow).o97 = function (entry, $completion) {
    var tmp = new $loopCOROUTINE$1(this, entry, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameWindow).p97 = function (_set____db54di) {
    return this.q93_1.j9(this, continuousRenderMode$factory(), _set____db54di);
  };
  protoOf(GameWindow).q97 = function () {
    return this.q93_1.h9(this, continuousRenderMode$factory_0());
  };
  protoOf(GameWindow).r97 = function (doUpdate, doRender, frameStartTime) {
    var startTime = PerformanceCounter_getInstance().i18();
    if (doRender) {
      var tmp = this;
      var tmp$ret$1;
      // Inline function 'korlibs.time.measureTime' call
      var start = PerformanceCounter_getInstance().g18();
      // Inline function 'korlibs.render.GameWindow.frame.<anonymous>' call
      this.s97(doUpdate, true);
      var end = PerformanceCounter_getInstance().g18();
      var tmp$ret$0;
      // Inline function 'korlibs.time.microseconds' call
      var tmp0__get_microseconds__mq1imi = end - start;
      tmp$ret$0 = Companion_getInstance().l18(tmp0__get_microseconds__mq1imi);
      tmp$ret$1 = tmp$ret$0;
      tmp.n93_1 = tmp$ret$1;
    }
    if (doUpdate) {
      var tmp_0 = this;
      var tmp$ret$3;
      // Inline function 'korlibs.time.measureTime' call
      var start_0 = PerformanceCounter_getInstance().g18();
      // Inline function 'korlibs.render.GameWindow.frame.<anonymous>' call
      if (!doRender) {
        this.s97(true, false);
      }
      this.t97(frameStartTime);
      var end_0 = PerformanceCounter_getInstance().g18();
      var tmp$ret$2;
      // Inline function 'korlibs.time.microseconds' call
      var tmp1__get_microseconds__thg1ez = end_0 - start_0;
      tmp$ret$2 = Companion_getInstance().l18(tmp1__get_microseconds__thg1ez);
      tmp$ret$3 = tmp$ret$2;
      tmp_0.o93_1 = tmp$ret$3;
    }
    var endTime = PerformanceCounter_getInstance().i18();
    return TimeSpan__minus_impl_5w5v8s(endTime, startTime);
  };
  protoOf(GameWindow).c96 = function (doUpdate, doRender, frameStartTime, $super) {
    doUpdate = doUpdate === VOID ? true : doUpdate;
    doRender = doRender === VOID ? true : doRender;
    frameStartTime = frameStartTime === VOID ? PerformanceCounter_getInstance().i18() : frameStartTime;
    return $super === VOID ? this.r97(doUpdate, doRender, frameStartTime) : $super.r97.call(this, doUpdate, doRender, new TimeSpan(frameStartTime)).j18_1;
  };
  protoOf(GameWindow).s97 = function (doUpdate, doRender) {
    if (doRender) {
      if (this.y93_1) {
        this.y93_1 = false;
        this.k96().q7l();
      }
      if (this.r93_1) {
        this.r93_1 = false;
        this.k96().n7l_1.h7s(this.s93_1, this.t93_1, this.u93_1, this.v93_1);
        this.u97(this.s93_1, this.t93_1, this.u93_1, this.v93_1);
      }
    }
    if (this.w93_1) {
      this.w93_1 = false;
      println('---------------- Trigger AG.initialized ag.mainFrameBuffer.setSize (' + this.x18() + ', ' + this.y18() + ') --------------');
      var tmp$ret$1;
      // Inline function 'korlibs.render.GameWindow.reset' call
      var tmp0_reset = this.m92_1;
      tmp0_reset.h7d_1 = false;
      var tmp$ret$0;
      // Inline function 'korlibs.render.GameWindow.reset.<anonymous>' call
      tmp$ret$0 = Unit_getInstance();
      tmp$ret$1 = tmp0_reset;
      this.b7d(tmp$ret$1);
    }
    try {
      this.v97(doUpdate, doRender);
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        println('ERROR GameWindow.frameRender:');
        println(e);
        printStackTrace(e);
      } else {
        throw $p;
      }
    }
  };
  protoOf(GameWindow).t7l = function () {
    this.z93_1 = 0;
  };
  protoOf(GameWindow).w97 = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.z93_1;
    tmp0_this.z93_1 = tmp1 + 1 | 0;
  };
  protoOf(GameWindow).t97 = function (startTime) {
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'korlibs.time.measureTime' call
    var start = PerformanceCounter_getInstance().g18();
    // Inline function 'korlibs.render.GameWindow.frameUpdate.<anonymous>' call
    this.x97();
    var end = PerformanceCounter_getInstance().g18();
    var tmp$ret$0;
    // Inline function 'korlibs.time.microseconds' call
    var tmp0__get_microseconds__mq1imi = end - start;
    tmp$ret$0 = Companion_getInstance().l18(tmp0__get_microseconds__mq1imi);
    tmp$ret$1 = tmp$ret$0;
    tmp.a94_1 = tmp$ret$1;
    try {
      var now = PerformanceCounter_getInstance().i18();
      var consumed = TimeSpan__minus_impl_5w5v8s(now, startTime);
      var tmp0_elvis_lhs = this.l96().k97_1;
      var tmp_0;
      var tmp_1 = tmp0_elvis_lhs;
      if ((tmp_1 == null ? null : new TimeSpan(tmp_1)) == null) {
        tmp_0 = TimeSpan__times_impl_86ofia(this.d96(), 10);
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var timeForTasks = tmp_0;
      var tmp$ret$2;
      // Inline function 'korlibs.time.milliseconds' call
      tmp$ret$2 = Companion_getInstance().b18(4.0);
      var finalTimeForTasks = max_0(timeForTasks, tmp$ret$2);
      this.l96().y97(finalTimeForTasks);
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        println('ERROR GameWindow.frameRender:');
        println(e);
      } else {
        throw $p;
      }
    }
  };
  protoOf(GameWindow).x97 = function () {
  };
  protoOf(GameWindow).v97 = function (update, render) {
    var tmp$ret$0;
    // Inline function 'korlibs.render.GameWindow.reset' call
    var tmp0_reset = this.l92_1;
    tmp0_reset.h7d_1 = false;
    // Inline function 'korlibs.render.GameWindow.dispatchRenderEvent.<anonymous>' call
    tmp0_reset.l7h_1 = update;
    tmp0_reset.m7h_1 = render;
    tmp$ret$0 = tmp0_reset;
    return this.b7d(tmp$ret$0);
  };
  protoOf(GameWindow).z97 = function (type, files) {
    var tmp$ret$0;
    // Inline function 'korlibs.render.GameWindow.reset' call
    var tmp0_reset = this.u92_1;
    tmp0_reset.h7d_1 = false;
    // Inline function 'korlibs.render.GameWindow.dispatchDropfileEvent.<anonymous>' call
    tmp0_reset.g7h_1 = type;
    tmp0_reset.h7h_1 = files;
    tmp$ret$0 = tmp0_reset;
    return this.b7d(tmp$ret$0);
  };
  protoOf(GameWindow).u97 = function (x, y, width, height) {
    this.a98(x, y, width, height, width, height);
  };
  protoOf(GameWindow).a98 = function (x, y, width, height, fullWidth, fullHeight) {
    this.k96().n7l_1.i7s(x, y, width, height, fullWidth, fullHeight);
    var tmp$ret$0;
    // Inline function 'korlibs.render.GameWindow.reset' call
    var tmp0_reset = this.p92_1;
    tmp0_reset.h7d_1 = false;
    // Inline function 'korlibs.render.GameWindow.dispatchReshapeEventEx.<anonymous>' call
    tmp0_reset.y7g_1 = x;
    tmp0_reset.z7g_1 = y;
    tmp0_reset.a7h_1 = width;
    tmp0_reset.b7h_1 = height;
    tmp$ret$0 = tmp0_reset;
    this.b7d(tmp$ret$0);
  };
  protoOf(GameWindow).j7j = function () {
    this.h94_1.j7j();
  };
  protoOf(GameWindow).k7j = function (info) {
    this.h94_1.k7j(info);
  };
  protoOf(GameWindow).l7j = function (out) {
    return this.h94_1.l7j(out);
  };
  protoOf(GameWindow).b98 = function (out, $super) {
    out = out === VOID ? new IntArrayList() : out;
    return $super === VOID ? this.l7j(out) : $super.l7j.call(this, out);
  };
  protoOf(GameWindow).c98 = function (data, $completion) {
    return Unit_getInstance();
  };
  protoOf(GameWindow).d98 = function ($completion) {
    return null;
  };
  function Impl(quality) {
    quality = quality === VOID ? Quality_AUTOMATIC_getInstance() : quality;
    this.e98_1 = quality;
  }
  function GameWindowCreationConfig(multithreaded, hdr, msaa, checkGl, logGl, cacheGl, fullscreen) {
    multithreaded = multithreaded === VOID ? null : multithreaded;
    hdr = hdr === VOID ? null : hdr;
    msaa = msaa === VOID ? null : msaa;
    checkGl = checkGl === VOID ? false : checkGl;
    logGl = logGl === VOID ? false : logGl;
    cacheGl = cacheGl === VOID ? false : cacheGl;
    fullscreen = fullscreen === VOID ? null : fullscreen;
    this.f98_1 = multithreaded;
    this.g98_1 = hdr;
    this.h98_1 = msaa;
    this.i98_1 = checkGl;
    this.j98_1 = logGl;
    this.k98_1 = cacheGl;
    this.l98_1 = fullscreen;
  }
  protoOf(GameWindowCreationConfig).toString = function () {
    return 'GameWindowCreationConfig(multithreaded=' + this.f98_1 + ', hdr=' + this.g98_1 + ', msaa=' + this.h98_1 + ', checkGl=' + this.i98_1 + ', logGl=' + this.j98_1 + ', cacheGl=' + this.k98_1 + ', fullscreen=' + this.l98_1 + ')';
  };
  protoOf(GameWindowCreationConfig).hashCode = function () {
    var result = this.f98_1 == null ? 0 : this.f98_1 | 0;
    result = imul(result, 31) + (this.g98_1 == null ? 0 : this.g98_1 | 0) | 0;
    result = imul(result, 31) + (this.h98_1 == null ? 0 : this.h98_1) | 0;
    result = imul(result, 31) + (this.i98_1 | 0) | 0;
    result = imul(result, 31) + (this.j98_1 | 0) | 0;
    result = imul(result, 31) + (this.k98_1 | 0) | 0;
    result = imul(result, 31) + (this.l98_1 == null ? 0 : this.l98_1 | 0) | 0;
    return result;
  };
  protoOf(GameWindowCreationConfig).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GameWindowCreationConfig))
      return false;
    var tmp0_other_with_cast = other instanceof GameWindowCreationConfig ? other : THROW_CCE();
    if (!(this.f98_1 == tmp0_other_with_cast.f98_1))
      return false;
    if (!(this.g98_1 == tmp0_other_with_cast.g98_1))
      return false;
    if (!(this.h98_1 == tmp0_other_with_cast.h98_1))
      return false;
    if (!(this.i98_1 === tmp0_other_with_cast.i98_1))
      return false;
    if (!(this.j98_1 === tmp0_other_with_cast.j98_1))
      return false;
    if (!(this.k98_1 === tmp0_other_with_cast.k98_1))
      return false;
    if (!(this.l98_1 == tmp0_other_with_cast.l98_1))
      return false;
    return true;
  };
  function configure(_this__u8e3s4, size, title, icon, fullscreen, bgcolor) {
    title = title === VOID ? 'GameWindow' : title;
    icon = icon === VOID ? null : icon;
    fullscreen = fullscreen === VOID ? null : fullscreen;
    bgcolor = bgcolor === VOID ? Colors_getInstance().l4s_1 : bgcolor;
    _this__u8e3s4.g7s(numberToInt(size.w40_1), numberToInt(size.x40_1));
    if (!(title == null)) {
      _this__u8e3s4.r96(title);
    }
    _this__u8e3s4.s96(icon);
    if (!(fullscreen == null)) {
      _this__u8e3s4.t96(fullscreen);
    }
    _this__u8e3s4.v96(bgcolor);
    _this__u8e3s4.u96(true);
  }
  function TimedTask(time, continuation, callback) {
    this.m98_1 = time;
    this.n98_1 = continuation;
    this.o98_1 = callback;
    this.p98_1 = null;
  }
  function Companion_54() {
    Companion_instance_54 = this;
    this.q98_1 = Companion_getInstance_3().b1n('GameWindow');
  }
  var Companion_instance_54;
  function Companion_getInstance_70() {
    if (Companion_instance_54 == null)
      new Companion_54();
    return Companion_instance_54;
  }
  function sam$kotlinx_coroutines_DisposableHandle$0(function_0) {
    this.r98_1 = function_0;
  }
  protoOf(sam$kotlinx_coroutines_DisposableHandle$0).gs = function () {
    return this.r98_1();
  };
  function GameWindowCoroutineDispatcher$timedTasks$lambda(a, b) {
    return TimeSpan__compareTo_impl_zidry4(a.m98_1, b.m98_1);
  }
  function GameWindowCoroutineDispatcher$scheduleResumeAfterDelay$lambda($task) {
    return function (it) {
      $task.p98_1 = it;
      return Unit_getInstance();
    };
  }
  function GameWindowCoroutineDispatcher$invokeOnTimeout$lambda(this$0, $task) {
    return function () {
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
      var tmp0_invoke = this$0.h97_1;
      this$0.g97_1.i1c($task);
      tmp$ret$0 = Unit_getInstance();
      return Unit_getInstance();
    };
  }
  function GameWindowCoroutineDispatcher() {
    Companion_getInstance_70();
    CoroutineDispatcher.call(this);
    this.f97_1 = new TGenQueue();
    var tmp = this;
    var tmp_0 = Companion_getInstance_13();
    tmp.g97_1 = tmp_0.t1g(VOID, GameWindowCoroutineDispatcher$timedTasks$lambda);
    this.h97_1 = new NonRecursiveLock();
    var tmp_1 = this;
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    tmp$ret$0 = Companion_getInstance().b18(0.0);
    tmp_1.i97_1 = tmp$ret$0;
    var tmp_2 = this;
    var tmp$ret$1;
    // Inline function 'korlibs.time.milliseconds' call
    tmp$ret$1 = Companion_getInstance().b18(0.0);
    tmp_2.j97_1 = tmp$ret$1;
    this.k97_1 = null;
    this.l97_1 = Companion_getInstance_3().b1n('Korgw.GameWindow.TooManyCallbacks');
  }
  protoOf(GameWindowCoroutineDispatcher).s98 = function (block) {
    if (block == null)
      return Unit_getInstance();
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp0_invoke = this.h97_1;
    this.f97_1.d1h(block);
    tmp$ret$0 = Unit_getInstance();
  };
  protoOf(GameWindowCoroutineDispatcher).cv = function (context, block) {
    return this.s98(block);
  };
  protoOf(GameWindowCoroutineDispatcher).t98 = function () {
    return PerformanceCounter_getInstance().i18();
  };
  protoOf(GameWindowCoroutineDispatcher).kv = function (timeMillis, continuation) {
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp0__get_milliseconds__kazo1t = timeMillis.wi();
    tmp$ret$0 = Companion_getInstance().b18(tmp0__get_milliseconds__kazo1t);
    this.u98(tmp$ret$0, continuation);
  };
  protoOf(GameWindowCoroutineDispatcher).u98 = function (time, continuation) {
    var task = new TimedTask(TimeSpan__plus_impl_qdd6zo(this.t98(), time), continuation, null);
    continuation.bs(GameWindowCoroutineDispatcher$scheduleResumeAfterDelay$lambda(task));
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp0_invoke = this.h97_1;
    var tmp$ret$0;
    // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.scheduleResumeAfterDelay.<anonymous>' call
    tmp$ret$0 = this.g97_1.h1c(task);
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(GameWindowCoroutineDispatcher).lv = function (timeMillis, block, context) {
    var tmp = this.t98();
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp0__get_milliseconds__kazo1t = timeMillis.wi();
    tmp$ret$0 = Companion_getInstance().b18(tmp0__get_milliseconds__kazo1t);
    var task = new TimedTask(TimeSpan__plus_impl_qdd6zo(tmp, tmp$ret$0), null, block);
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp1_invoke = this.h97_1;
    var tmp$ret$1;
    // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.invokeOnTimeout.<anonymous>' call
    tmp$ret$1 = this.g97_1.h1c(task);
    tmp$ret$2 = tmp$ret$1;
    var tmp_0 = GameWindowCoroutineDispatcher$invokeOnTimeout$lambda(this, task);
    return new sam$kotlinx_coroutines_DisposableHandle$0(tmp_0);
  };
  protoOf(GameWindowCoroutineDispatcher).y97 = function (availableTime) {
    try {
      var startTime = this.t98();
      var processedTimedTasks = 0;
      var processedTasks = 0;
      var tmp = this;
      var tmp$ret$8;
      // Inline function 'korlibs.time.measureTime' call
      var start = PerformanceCounter_getInstance().g18();
      // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.executePending.<anonymous>' call
      $l$loop_0: while (true) {
        var tmp$ret$2;
        // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
        var tmp0_invoke = this.h97_1;
        var tmp$ret$1;
        // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.executePending.<anonymous>.<anonymous>' call
        var tmp_0;
        var tmp_1;
        var tmp$ret$0;
        // Inline function 'kotlin.collections.isNotEmpty' call
        var tmp0_isNotEmpty = this.g97_1;
        tmp$ret$0 = !tmp0_isNotEmpty.p();
        if (tmp$ret$0) {
          tmp_1 = TimeSpan__compareTo_impl_zidry4(startTime, this.g97_1.z1g().m98_1) >= 0;
        } else {
          tmp_1 = false;
        }
        if (tmp_1) {
          tmp_0 = this.g97_1.a1h();
        } else {
          tmp_0 = null;
        }
        tmp$ret$1 = tmp_0;
        tmp$ret$2 = tmp$ret$1;
        var tmp0_elvis_lhs = tmp$ret$2;
        var tmp_2;
        if (tmp0_elvis_lhs == null) {
          break $l$loop_0;
        } else {
          tmp_2 = tmp0_elvis_lhs;
        }
        var item = tmp_2;
        try {
          if (!(item.p98_1 == null)) {
            var tmp1_safe_receiver = item.n98_1;
            if (tmp1_safe_receiver == null)
              null;
            else {
              var tmp$ret$4;
              // Inline function 'kotlin.coroutines.resumeWithException' call
              var tmp2_resumeWithException = ensureNotNull(item.p98_1);
              var tmp$ret$3;
              // Inline function 'kotlin.Companion.failure' call
              var tmp1_failure = Companion_getInstance_14();
              tmp$ret$3 = _Result___init__impl__xyqfz8(createFailure(tmp2_resumeWithException));
              tmp1_safe_receiver.q5(tmp$ret$3);
              tmp$ret$4 = Unit_getInstance();
            }
            if (!(item.o98_1 == null)) {
              var tmp2_safe_receiver = item.p98_1;
              if (tmp2_safe_receiver == null)
                null;
              else {
                printStackTrace(tmp2_safe_receiver);
              }
            }
          } else {
            var tmp3_safe_receiver = item.n98_1;
            if (tmp3_safe_receiver == null)
              null;
            else {
              var tmp$ret$6;
              // Inline function 'kotlin.coroutines.resume' call
              var tmp$ret$5;
              // Inline function 'kotlin.Companion.success' call
              var tmp3_success = Companion_getInstance_14();
              tmp$ret$5 = _Result___init__impl__xyqfz8(Unit_getInstance());
              tmp3_safe_receiver.q5(tmp$ret$5);
              tmp$ret$6 = Unit_getInstance();
            }
            var tmp4_safe_receiver = item.o98_1;
            if (tmp4_safe_receiver == null)
              null;
            else {
              tmp4_safe_receiver.fu();
            }
          }
        } catch ($p) {
          if ($p instanceof Error) {
            var e = $p;
            printStackTrace(e);
          } else {
            throw $p;
          }
        }
        finally {
          var tmp5 = processedTimedTasks;
          processedTimedTasks = tmp5 + 1 | 0;
        }
        var elapsedTime = TimeSpan__minus_impl_5w5v8s(this.t98(), startTime);
        if (TimeSpan__compareTo_impl_zidry4(elapsedTime, availableTime) >= 0) {
          this.v98(elapsedTime, availableTime, processedTimedTasks, processedTasks);
          break $l$loop_0;
        }
      }
      var end = PerformanceCounter_getInstance().g18();
      var tmp$ret$7;
      // Inline function 'korlibs.time.microseconds' call
      var tmp0__get_microseconds__mq1imi = end - start;
      tmp$ret$7 = Companion_getInstance().l18(tmp0__get_microseconds__mq1imi);
      tmp$ret$8 = tmp$ret$7;
      tmp.i97_1 = tmp$ret$8;
      var tmp_3 = this;
      var tmp$ret$15;
      // Inline function 'korlibs.time.measureTime' call
      var start_0 = PerformanceCounter_getInstance().g18();
      // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.executePending.<anonymous>' call
      $l$loop_2: while (true) {
        var tmp$ret$11;
        // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
        var tmp0_invoke_0 = this.h97_1;
        var tmp$ret$10;
        // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.executePending.<anonymous>.<anonymous>' call
        var tmp_4;
        var tmp$ret$9;
        // Inline function 'kotlin.collections.isNotEmpty' call
        var tmp0_isNotEmpty_0 = this.f97_1;
        tmp$ret$9 = !tmp0_isNotEmpty_0.p();
        if (tmp$ret$9) {
          tmp_4 = this.f97_1.e1h();
        } else {
          tmp_4 = null;
        }
        tmp$ret$10 = tmp_4;
        tmp$ret$11 = tmp$ret$10;
        var tmp0_elvis_lhs_0 = tmp$ret$11;
        var tmp_5;
        if (tmp0_elvis_lhs_0 == null) {
          break $l$loop_2;
        } else {
          tmp_5 = tmp0_elvis_lhs_0;
        }
        var task = tmp_5;
        var tmp$ret$13;
        // Inline function 'korlibs.time.measureTime' call
        var start_1 = PerformanceCounter_getInstance().g18();
        // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.executePending.<anonymous>.<anonymous>' call
        try {
          task.fu();
        } catch ($p) {
          if ($p instanceof Error) {
            var e_0 = $p;
            printStackTrace(e_0);
          } else {
            throw $p;
          }
        }
        finally {
          var tmp0 = processedTasks;
          processedTasks = tmp0 + 1 | 0;
        }
        var end_0 = PerformanceCounter_getInstance().g18();
        var tmp$ret$12;
        // Inline function 'korlibs.time.microseconds' call
        var tmp1__get_microseconds__thg1ez = end_0 - start_1;
        tmp$ret$12 = Companion_getInstance().l18(tmp1__get_microseconds__thg1ez);
        tmp$ret$13 = tmp$ret$12;
        var time = tmp$ret$13;
        var elapsed = TimeSpan__minus_impl_5w5v8s(this.t98(), startTime);
        if (TimeSpan__compareTo_impl_zidry4(elapsed, availableTime) >= 0) {
          this.v98(elapsed, availableTime, processedTimedTasks, processedTasks);
          break $l$loop_2;
        }
      }
      var end_1 = PerformanceCounter_getInstance().g18();
      var tmp$ret$14;
      // Inline function 'korlibs.time.microseconds' call
      var tmp1__get_microseconds__thg1ez_0 = end_1 - start_0;
      tmp$ret$14 = Companion_getInstance().l18(tmp1__get_microseconds__thg1ez_0);
      tmp$ret$15 = tmp$ret$14;
      tmp_3.j97_1 = tmp$ret$15;
    } catch ($p) {
      if ($p instanceof Error) {
        var e_1 = $p;
        println('Error in GameWindowCoroutineDispatcher.executePending:');
        printStackTrace(e_1);
      } else {
        throw $p;
      }
    }
  };
  protoOf(GameWindowCoroutineDispatcher).v98 = function (elapsedTime, availableTime, processedTimedTasks, processedTasks) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.warn' call
    var tmp1_warn = this.l97_1;
    var tmp0_log = Level_WARN_getInstance();
    var tmp;
    if (tmp1_warn.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.informTooManyCallbacksToHandleInThisFrame.<anonymous>' call
      tmp$ret$0 = 'Too many callbacks to handle in this frame elapsedTime=' + new TimeSpan(roundMilliseconds(elapsedTime)) + ', availableTime=' + new TimeSpan(roundMilliseconds(availableTime)) + ' pending timedTasks=' + this.g97_1.w1g_1 + ', tasks=' + this.f97_1.l() + ', processedTimedTasks=' + processedTimedTasks + ', processedTasks=' + processedTasks;
      tmp1_warn.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
  };
  protoOf(GameWindowCoroutineDispatcher).n1q = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.time.seconds' call
    tmp$ret$0 = Companion_getInstance().m18(2.0);
    this.y97(tmp$ret$0);
    var tmp$ret$2;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_70().q98_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$1;
      // Inline function 'korlibs.render.GameWindowCoroutineDispatcher.close.<anonymous>' call
      tmp$ret$1 = 'GameWindowCoroutineDispatcher.close';
      tmp1_info.r1n(tmp0_log, tmp$ret$1);
      tmp = Unit_getInstance();
    }
    tmp$ret$2 = tmp;
    $l$loop: while (true) {
      var tmp$ret$3;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp2_isNotEmpty = this.g97_1;
      tmp$ret$3 = !tmp2_isNotEmpty.p();
      if (!tmp$ret$3) {
        break $l$loop;
      }
      var tmp0_safe_receiver = this.g97_1.a1h().n98_1;
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$5;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp$ret$4;
        // Inline function 'kotlin.Companion.success' call
        var tmp3_success = Companion_getInstance_14();
        tmp$ret$4 = _Result___init__impl__xyqfz8(Unit_getInstance());
        tmp0_safe_receiver.q5(tmp$ret$4);
        tmp$ret$5 = Unit_getInstance();
      }
    }
    $l$loop_0: while (true) {
      var tmp$ret$6;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp4_isNotEmpty = this.f97_1;
      tmp$ret$6 = !tmp4_isNotEmpty.p();
      if (!tmp$ret$6) {
        break $l$loop_0;
      }
      this.f97_1.e1h().fu();
    }
  };
  protoOf(GameWindowCoroutineDispatcher).toString = function () {
    return 'GameWindowCoroutineDispatcher';
  };
  function TextClipboardData(text, contentType) {
    contentType = contentType === VOID ? null : contentType;
    this.w98_1 = text;
    this.x98_1 = contentType;
  }
  protoOf(TextClipboardData).toString = function () {
    return 'TextClipboardData(text=' + this.w98_1 + ', contentType=' + this.x98_1 + ')';
  };
  protoOf(TextClipboardData).hashCode = function () {
    var result = getStringHashCode(this.w98_1);
    result = imul(result, 31) + (this.x98_1 == null ? 0 : getStringHashCode(this.x98_1)) | 0;
    return result;
  };
  protoOf(TextClipboardData).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TextClipboardData))
      return false;
    var tmp0_other_with_cast = other instanceof TextClipboardData ? other : THROW_CCE();
    if (!(this.w98_1 === tmp0_other_with_cast.w98_1))
      return false;
    if (!(this.x98_1 == tmp0_other_with_cast.x98_1))
      return false;
    return true;
  };
  function exitProcessOnClose$factory($b0) {
    return getPropertyCallableRef('exitProcessOnClose', 0, KMutableProperty0, function () {
      return $b0.i93_1;
    }, function (value) {
      $b0.i93_1 = value;
      return Unit_getInstance();
    });
  }
  function fpsCached$factory() {
    return getPropertyCallableRef('fpsCached', 1, KProperty1, function (receiver) {
      return _get_fpsCached__581dty(receiver);
    }, null);
  }
  function continuousRenderMode$factory() {
    return getPropertyCallableRef('continuousRenderMode', 1, KMutableProperty1, function (receiver) {
      return receiver.q97();
    }, function (receiver, value) {
      return receiver.p97(value);
    });
  }
  function continuousRenderMode$factory_0() {
    return getPropertyCallableRef('continuousRenderMode', 1, KMutableProperty1, function (receiver) {
      return receiver.q97();
    }, function (receiver, value) {
      return receiver.p97(value);
    });
  }
  function IntTimedCache(ttl, gen) {
    this.i94_1 = ttl;
    this.j94_1 = gen;
    this.k94_1 = Companion_getInstance_2().o17_1;
    this.l94_1 = 0;
  }
  protoOf(IntTimedCache).g9 = function (obj, property) {
    var tmp$ret$0;
    // Inline function 'korlibs.render.internal.IntTimedCache.get' call
    var now = Companion_getInstance_2().s17();
    if (TimeSpan__compareTo_impl_zidry4(DateTime__minus_impl_10njw8(now, this.k94_1), this.i94_1) > 0) {
      this.k94_1 = now;
      this.l94_1 = this.j94_1();
    }
    tmp$ret$0 = this.l94_1;
    return tmp$ret$0;
  };
  var korgwJvmEngine;
  function AGOpenglFactory() {
    AGOpenglFactory_instance = this;
  }
  protoOf(AGOpenglFactory).y98 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = 'ontouchstart' in window || navigator.maxTouchPoints;
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  };
  var AGOpenglFactory_instance;
  function AGOpenglFactory_getInstance() {
    if (AGOpenglFactory_instance == null)
      new AGOpenglFactory();
    return AGOpenglFactory_instance;
  }
  function get_korgwCanvasQuery() {
    _init_properties_GlExt_kt__oxmdm();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = korgwCanvasQuery$factory();
    tmp$ret$0 = korgwCanvasQuery$delegate.f1();
    return tmp$ret$0;
  }
  var korgwCanvasQuery$delegate;
  function AGDefaultCanvas() {
    _init_properties_GlExt_kt__oxmdm();
    var tmp0_safe_receiver = get_korgwCanvasQuery();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.graphics.gl.AGDefaultCanvas.<anonymous>' call
      var tmp_0 = document.querySelector(tmp0_safe_receiver);
      tmp$ret$0 = tmp_0 instanceof HTMLCanvasElement ? tmp_0 : THROW_CCE();
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    var tmp1_elvis_lhs = tmp;
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      var tmp_2 = document.createElement('canvas');
      tmp_1 = tmp_2 instanceof HTMLCanvasElement ? tmp_2 : THROW_CCE();
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    return tmp_1;
  }
  function AGWebgl(config, canvas) {
    canvas = canvas === VOID ? AGDefaultCanvas() : canvas;
    _init_properties_GlExt_kt__oxmdm();
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = new AGOpengl(new KmlGlJsCanvas(canvas, jsObject([to('premultipliedAlpha', false), to('alpha', false), to('stencil', true), to('antialias', config.v7y_1)])));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.graphics.gl.AGWebgl.<anonymous>' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = window;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.ag = tmp0_also;
    canvas.addEventListener('webglcontextlost', AGWebgl$lambda, false);
    canvas.addEventListener('webglcontextrestored', AGWebgl$lambda_0(tmp0_also), false);
    tmp$ret$1 = tmp0_also;
    return tmp$ret$1;
  }
  function get_isCanvasCreatedAndHandled() {
    _init_properties_GlExt_kt__oxmdm();
    return get_korgwCanvasQuery() == null;
  }
  function jsObject(pairs) {
    _init_properties_GlExt_kt__oxmdm();
    var out = jsEmptyObject();
    var indexedObject = pairs;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var tmp1_loop_parameter = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var k = tmp1_loop_parameter.c5();
      var v = tmp1_loop_parameter.d5();
      if (!(v == null))
        out[k] = v;
    }
    return out;
  }
  function jsEmptyObject() {
    _init_properties_GlExt_kt__oxmdm();
    return {};
  }
  function korgwCanvasQuery$delegate$lambda() {
    _init_properties_GlExt_kt__oxmdm();
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = window;
    tmp$ret$0 = tmp0_asDynamic;
    var tmp1_unsafeCast = tmp$ret$0.korgwCanvasQuery;
    tmp$ret$1 = tmp1_unsafeCast;
    return tmp$ret$1;
  }
  function AGWebgl$lambda(e) {
    _init_properties_GlExt_kt__oxmdm();
    e.preventDefault();
    return Unit_getInstance();
  }
  function AGWebgl$lambda_0($tmp0_also) {
    return function (e) {
      $tmp0_also.q7l();
      return Unit_getInstance();
    };
  }
  function korgwCanvasQuery$factory() {
    return getPropertyCallableRef('korgwCanvasQuery', 0, KProperty0, function () {
      return get_korgwCanvasQuery();
    }, null);
  }
  var properties_initialized_GlExt_kt_6pmiss;
  function _init_properties_GlExt_kt__oxmdm() {
    if (properties_initialized_GlExt_kt_6pmiss) {
    } else {
      properties_initialized_GlExt_kt_6pmiss = true;
      korgwCanvasQuery$delegate = lazy(korgwCanvasQuery$delegate$lambda);
    }
  }
  function alloc(_this__u8e3s4, $this) {
    if (_this__u8e3s4 === null)
      return 0;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    if (tmp$ret$0.id === undefined) {
      if ($this.i99_1.p()) {
        // Inline function 'kotlin.error' call
        throw IllegalStateException_init_$Create$('KmlGlJsCanvas.freeList is empty. (Probably allocating lots of OpenGL objects without releasing them)');
      }
      var index = $this.i99_1.c4($this.i99_1.l() - 1 | 0);
      $this.h99_1[index] = _this__u8e3s4;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = _this__u8e3s4;
      tmp$ret$1.id = index;
    }
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = _this__u8e3s4;
    var tmp0_unsafeCast = tmp$ret$2.id;
    tmp$ret$3 = tmp0_unsafeCast;
    return tmp$ret$3;
  }
  function get_3(_this__u8e3s4, $this) {
    var tmp;
    if (!(_this__u8e3s4 === 0)) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = $this.h99_1[_this__u8e3s4];
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    } else {
      tmp = null;
    }
    return tmp;
  }
  function free(_this__u8e3s4, $this) {
    var tmp;
    if (!(_this__u8e3s4 === 0)) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = $this.h99_1[_this__u8e3s4];
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      var out = tmp$ret$1;
      var tmp0_this = $this;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp1_plusAssign = tmp0_this.i99_1;
      tmp1_plusAssign.b(_this__u8e3s4);
      $this.h99_1[_this__u8e3s4] = null;
      tmp = out;
    } else {
      tmp = null;
    }
    return tmp;
  }
  function KmlGlJsCanvas$extensions$delegate$lambda(this$0) {
    return function () {
      var tmp0_elvis_lhs = this$0.g99_1.getSupportedExtensions();
      var tmp;
      if (tmp0_elvis_lhs == null) {
        var tmp$ret$2;
        // Inline function 'kotlin.arrayOf' call
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = [];
        tmp$ret$1 = tmp$ret$0;
        tmp$ret$2 = tmp$ret$1;
        tmp = tmp$ret$2;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      return toSet_0(tmp);
    };
  }
  function KmlGlJsCanvas$isFloatTextureSupported$delegate$lambda(this$0) {
    return function () {
      return (this$0.g99_1.getExtension('OES_texture_float_linear') != null ? true : this$0.g99_1.getExtension('OES_texture_float') != null) ? true : this$0.f99_1 >= 2;
    };
  }
  function KmlGlJsCanvas(canvas, glOpts) {
    KmlGl.call(this);
    this.d99_1 = canvas;
    this.e99_1 = glOpts;
    this.f99_1 = 1;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp1_elvis_lhs = null;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = this.d99_1.getContext('webgl2', this.e99_1);
      var tmp_1;
      if (tmp0_safe_receiver == null) {
        tmp_1 = null;
      } else {
        var tmp$ret$0;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.kgl.KmlGlJsCanvas.gl.<anonymous>' call
        this.f99_1 = 2;
        tmp$ret$0 = tmp0_safe_receiver;
        tmp_1 = tmp$ret$0;
      }
      tmp_0 = tmp_1;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var tmp2_elvis_lhs = tmp_0;
    var tmp3_elvis_lhs = tmp2_elvis_lhs == null ? this.d99_1.getContext('webgl', this.e99_1) : tmp2_elvis_lhs;
    var tmp0_unsafeCast = tmp3_elvis_lhs == null ? this.d99_1.getContext('experimental-webgl', this.e99_1) : tmp3_elvis_lhs;
    var tmp$ret$1;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$1 = tmp0_unsafeCast;
    tmp$ret$2 = tmp$ret$1;
    var tmp4_safe_receiver = tmp$ret$2;
    var tmp_2;
    if (tmp4_safe_receiver == null) {
      tmp_2 = null;
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.kgl.KmlGlJsCanvas.gl.<anonymous>' call
      println('Created WebGL version=' + this.f99_1 + ', opts=' + JSON.stringify(this.e99_1));
      tmp$ret$3 = tmp4_safe_receiver;
      tmp_2 = tmp$ret$3;
    }
    var tmp5_safe_receiver = tmp_2;
    var tmp_3;
    if (tmp5_safe_receiver == null) {
      tmp_3 = null;
    } else {
      var tmp$ret$4;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.kgl.KmlGlJsCanvas.gl.<anonymous>' call
      tmp5_safe_receiver.getExtension('OES_standard_derivatives');
      tmp5_safe_receiver.getExtension('OES_texture_float');
      tmp5_safe_receiver.getExtension('OES_texture_float_linear');
      tmp5_safe_receiver.getExtension('OES_element_index_uint');
      tmp$ret$4 = tmp5_safe_receiver;
      tmp_3 = tmp$ret$4;
    }
    var tmp6_elvis_lhs = tmp_3;
    var tmp_4;
    if (tmp6_elvis_lhs == null) {
      var tmp$ret$7;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$6;
      // Inline function 'korlibs.kgl.KmlGlJsCanvas.gl.<anonymous>' call
      try {
        var tmp0_safe_receiver_0 = document.body;
        if (tmp0_safe_receiver_0 == null)
          null;
        else {
          var tmp$ret$5;
          // Inline function 'kotlin.apply' call
          var tmp_5 = document.createElement('div');
          var tmp0_apply = tmp_5 instanceof HTMLElement ? tmp_5 : THROW_CCE();
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.kgl.KmlGlJsCanvas.gl.<anonymous>.<anonymous>' call
          tmp0_apply.style.color = 'red';
          tmp0_apply.style.font = '30px Arial';
          tmp0_apply.innerText = "Can't get webgl context. Running in an android emulator without cross-walk?";
          tmp$ret$5 = tmp0_apply;
          tmp0_safe_receiver_0.prepend(tmp$ret$5);
        }
      } catch ($p) {
        if ($p instanceof Error) {
          var e = $p;
          printStackTrace(e);
        } else {
          throw $p;
        }
      }
      throw IllegalStateException_init_$Create$("Can't get webgl context");
      tmp$ret$7 = tmp$ret$6;
      tmp_4 = tmp$ret$7;
    } else {
      tmp_4 = tmp6_elvis_lhs;
    }
    tmp.g99_1 = tmp_4;
    var tmp_6 = this;
    var tmp$ret$8;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp0_arrayOfNulls = 8192;
    tmp$ret$8 = fillArrayVal(Array(tmp0_arrayOfNulls), null);
    tmp_6.h99_1 = tmp$ret$8;
    this.i99_1 = toMutableList(reversed_0(until(1, this.h99_1.length)));
    var tmp_7 = this;
    tmp_7.j99_1 = lazy(KmlGlJsCanvas$extensions$delegate$lambda(this));
    var tmp_8 = this;
    tmp_8.k99_1 = lazy(KmlGlJsCanvas$isFloatTextureSupported$delegate$lambda(this));
    this.l99_1 = new WebGLExtension(this, 'ANGLE_instanced_arrays', 2, listOf_0(['drawArraysInstanced', 'drawElementsInstanced', 'vertexAttribDivisor']), 'ANGLE');
    this.m99_1 = new WebGLExtension(this, 'OES_vertex_array_object', 2, listOf_0(['createVertexArrayOES', 'deleteVertexArrayOES', 'isVertexArrayOES', 'bindVertexArrayOES']), 'OES');
  }
  protoOf(KmlGlJsCanvas).p89 = function () {
    return Companion_getInstance_63().z8f(this.f99_1);
  };
  protoOf(KmlGlJsCanvas).o89 = function (texture) {
    return this.g99_1.activeTexture(texture);
  };
  protoOf(KmlGlJsCanvas).h8e = function (program, shader) {
    return this.g99_1.attachShader(get_3(program, this), get_3(shader, this));
  };
  protoOf(KmlGlJsCanvas).g8e = function (program, index, name) {
    return this.g99_1.bindAttribLocation(get_3(program, this), index, name);
  };
  protoOf(KmlGlJsCanvas).i88 = function (target, buffer) {
    return this.g99_1.bindBuffer(target, get_3(buffer, this));
  };
  protoOf(KmlGlJsCanvas).a8d = function (target, framebuffer) {
    return this.g99_1.bindFramebuffer(target, get_3(framebuffer, this));
  };
  protoOf(KmlGlJsCanvas).h8d = function (target, renderbuffer) {
    return this.g99_1.bindRenderbuffer(target, get_3(renderbuffer, this));
  };
  protoOf(KmlGlJsCanvas).y8c = function (target, texture) {
    return this.g99_1.bindTexture(target, get_3(texture, this));
  };
  protoOf(KmlGlJsCanvas).r8a = function (modeRGB, modeAlpha) {
    return this.g99_1.blendEquationSeparate(modeRGB, modeAlpha);
  };
  protoOf(KmlGlJsCanvas).s8a = function (sfactorRGB, dfactorRGB, sfactorAlpha, dfactorAlpha) {
    return this.g99_1.blendFuncSeparate(sfactorRGB, dfactorRGB, sfactorAlpha, dfactorAlpha);
  };
  protoOf(KmlGlJsCanvas).s89 = function (target, size, data, usage) {
    return this.g99_1.bufferData(target, get_arrayUByte(slice(data, 0, size)), usage);
  };
  protoOf(KmlGlJsCanvas).r89 = function (target, offset, size, data) {
    return this.g99_1.bufferSubData(target, offset, get_arrayUByte(slice(data, 0, size)));
  };
  protoOf(KmlGlJsCanvas).n8a = function (mask) {
    return this.g99_1.clear(mask);
  };
  protoOf(KmlGlJsCanvas).i8a = function (red, green, blue, alpha) {
    return this.g99_1.clearColor(red, green, blue, alpha);
  };
  protoOf(KmlGlJsCanvas).k8a = function (d) {
    return this.g99_1.clearDepth(d);
  };
  protoOf(KmlGlJsCanvas).m8a = function (s) {
    return this.g99_1.clearStencil(s);
  };
  protoOf(KmlGlJsCanvas).o8d = function (red, green, blue, alpha) {
    return this.g99_1.colorMask(red, green, blue, alpha);
  };
  protoOf(KmlGlJsCanvas).y8d = function (shader) {
    return this.g99_1.compileShader(get_3(shader, this));
  };
  protoOf(KmlGlJsCanvas).a8e = function () {
    return alloc(this.g99_1.createProgram(), this);
  };
  protoOf(KmlGlJsCanvas).w8d = function (type) {
    return alloc(this.g99_1.createShader(type), this);
  };
  protoOf(KmlGlJsCanvas).n8b = function (mode) {
    return this.g99_1.cullFace(mode);
  };
  protoOf(KmlGlJsCanvas).x90 = function (n, items) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$1;
        // Inline function 'org.khronos.webgl.get' call
        var tmp0_get = get_arrayInt(items);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_get;
        tmp$ret$1 = tmp$ret$0[p];
        this.g99_1.deleteBuffer(free(tmp$ret$1, this));
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).y90 = function (n, items) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$1;
        // Inline function 'org.khronos.webgl.get' call
        var tmp0_get = get_arrayInt(items);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_get;
        tmp$ret$1 = tmp$ret$0[p];
        this.g99_1.deleteFramebuffer(free(tmp$ret$1, this));
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).v8d = function (program) {
    return this.g99_1.deleteProgram(get_3(program, this));
  };
  protoOf(KmlGlJsCanvas).z90 = function (n, items) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$1;
        // Inline function 'org.khronos.webgl.get' call
        var tmp0_get = get_arrayInt(items);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_get;
        tmp$ret$1 = tmp$ret$0[p];
        this.g99_1.deleteRenderbuffer(free(tmp$ret$1, this));
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).u8d = function (shader) {
    return this.g99_1.deleteShader(get_3(shader, this));
  };
  protoOf(KmlGlJsCanvas).a91 = function (n, items) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$1;
        // Inline function 'org.khronos.webgl.get' call
        var tmp0_get = get_arrayInt(items);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_get;
        tmp$ret$1 = tmp$ret$0[p];
        this.g99_1.deleteTexture(free(tmp$ret$1, this));
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).n8d = function (func) {
    return this.g99_1.depthFunc(func);
  };
  protoOf(KmlGlJsCanvas).j8a = function (flag) {
    return this.g99_1.depthMask(flag);
  };
  protoOf(KmlGlJsCanvas).m8d = function (n, f) {
    return this.g99_1.depthRange(n, f);
  };
  protoOf(KmlGlJsCanvas).w8a = function (cap) {
    return this.g99_1.disable(cap);
  };
  protoOf(KmlGlJsCanvas).f8c = function (index) {
    return this.g99_1.disableVertexAttribArray(index);
  };
  protoOf(KmlGlJsCanvas).d8b = function (mode, first, count) {
    return this.g99_1.drawArrays(mode, first, count);
  };
  protoOf(KmlGlJsCanvas).f8b = function (mode, count, type, indices) {
    return this.g99_1.drawElements(mode, count, type, indices);
  };
  protoOf(KmlGlJsCanvas).x8a = function (cap) {
    return this.g99_1.enable(cap);
  };
  protoOf(KmlGlJsCanvas).j8c = function (index) {
    return this.g99_1.enableVertexAttribArray(index);
  };
  protoOf(KmlGlJsCanvas).ui = function () {
    return this.g99_1.flush();
  };
  protoOf(KmlGlJsCanvas).k8d = function (target, attachment, renderbuffertarget, renderbuffer) {
    return this.g99_1.framebufferRenderbuffer(target, attachment, renderbuffertarget, get_3(renderbuffer, this));
  };
  protoOf(KmlGlJsCanvas).j8d = function (target, attachment, textarget, texture, level) {
    return this.g99_1.framebufferTexture2D(target, attachment, textarget, get_3(texture, this), level);
  };
  protoOf(KmlGlJsCanvas).l8d = function (mode) {
    return this.g99_1.frontFace(mode);
  };
  protoOf(KmlGlJsCanvas).b91 = function (n, buffers) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'org.khronos.webgl.set' call
        var tmp0_set = get_arrayInt(buffers);
        var tmp1_set = alloc(this.g99_1.createBuffer(), this);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_set;
        tmp$ret$0[p] = tmp1_set;
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).w89 = function (target) {
    return this.g99_1.generateMipmap(target);
  };
  protoOf(KmlGlJsCanvas).c91 = function (n, framebuffers) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'org.khronos.webgl.set' call
        var tmp0_set = get_arrayInt(framebuffers);
        var tmp1_set = alloc(this.g99_1.createFramebuffer(), this);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_set;
        tmp$ret$0[p] = tmp1_set;
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).d91 = function (n, renderbuffers) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'org.khronos.webgl.set' call
        var tmp0_set = get_arrayInt(renderbuffers);
        var tmp1_set = alloc(this.g99_1.createRenderbuffer(), this);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_set;
        tmp$ret$0[p] = tmp1_set;
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).e91 = function (n, textures) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var p = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'org.khronos.webgl.set' call
        var tmp0_set = get_arrayInt(textures);
        var tmp1_set = alloc(this.g99_1.createTexture(), this);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_set;
        tmp$ret$0[p] = tmp1_set;
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).s8d = function (program, name) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = get_3(program, this);
    tmp$ret$0 = tmp0_asDynamic;
    var prg = tmp$ret$0;
    if (prg.uniforms === undefined)
      prg.uniforms = {};
    if (prg.uniforms[name] === undefined)
      prg.uniforms[name] = alloc(this.g99_1.getUniformLocation(prg, name), this);
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp1_unsafeCast = prg.uniforms[name];
    tmp$ret$1 = tmp1_unsafeCast;
    return tmp$ret$1;
  };
  protoOf(KmlGlJsCanvas).z8d = function () {
    return this.g99_1.getError();
  };
  protoOf(KmlGlJsCanvas).f91 = function (pname, data) {
    // Inline function 'org.khronos.webgl.set' call
    var tmp1_set = get_arrayInt(data);
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.g99_1.getParameter(pname);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    var tmp2_set = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = tmp1_set;
    tmp$ret$2[0] = tmp2_set;
  };
  protoOf(KmlGlJsCanvas).g91 = function (program, pname, params) {
    var tmp0_subject = pname;
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.KmlGl.INFO_LOG_LENGTH' call
    Companion_getInstance_68();
    tmp$ret$0 = 35716;
    if (tmp0_subject === tmp$ret$0) {
      // Inline function 'org.khronos.webgl.set' call
      var tmp0_set = get_arrayInt(params);
      var tmp1_safe_receiver = this.g99_1.getProgramInfoLog(get_3(program, this));
      var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.length;
      var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver + 1 | 0;
      var tmp1_set = tmp3_elvis_lhs == null ? 1 : tmp3_elvis_lhs;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp0_set;
      tmp$ret$1[0] = tmp1_set;
    } else {
      var tmp3_set = get_arrayInt(params);
      var tmp$ret$3;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp2_unsafeCast = this.g99_1.getProgramParameter(get_3(program, this), pname);
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp2_unsafeCast;
      tmp$ret$3 = tmp$ret$2;
      var tmp4_set = tmp$ret$3;
      var tmp$ret$4;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$4 = tmp3_set;
      tmp$ret$4[0] = tmp4_set;
    }
  };
  protoOf(KmlGlJsCanvas).h91 = function (shader, pname, params) {
    var tmp0_subject = pname;
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.KmlGl.INFO_LOG_LENGTH' call
    Companion_getInstance_68();
    tmp$ret$0 = 35716;
    if (tmp0_subject === tmp$ret$0) {
      // Inline function 'org.khronos.webgl.set' call
      var tmp0_set = get_arrayInt(params);
      var tmp1_safe_receiver = this.g99_1.getShaderInfoLog(get_3(shader, this));
      var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.length;
      var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver + 1 | 0;
      var tmp1_set = tmp3_elvis_lhs == null ? 1 : tmp3_elvis_lhs;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp0_set;
      tmp$ret$1[0] = tmp1_set;
    } else {
      var tmp3_set = get_arrayInt(params);
      var tmp$ret$3;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp2_unsafeCast = this.g99_1.getShaderParameter(get_3(shader, this), pname);
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp2_unsafeCast;
      tmp$ret$3 = tmp$ret$2;
      var tmp4_set = tmp$ret$3;
      var tmp$ret$4;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$4 = tmp3_set;
      tmp$ret$4[0] = tmp4_set;
    }
  };
  protoOf(KmlGlJsCanvas).i91 = function (shader, bufSize, length, infoLog) {
    var tmp0_elvis_lhs = this.g99_1.getShaderInfoLog(get_3(shader, this));
    var str = tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
    // Inline function 'org.khronos.webgl.set' call
    var tmp0_set = get_arrayInt(length);
    var tmp1_set = str.length;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_set;
    tmp$ret$0[0] = tmp1_set;
    putAsciiString(infoLog, str);
  };
  protoOf(KmlGlJsCanvas).d86 = function (name) {
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.g99_1.getParameter(name);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(KmlGlJsCanvas).i8e = function (program) {
    return this.g99_1.linkProgram(get_3(program, this));
  };
  protoOf(KmlGlJsCanvas).t89 = function (pname, param) {
    return this.g99_1.pixelStorei(pname, param);
  };
  protoOf(KmlGlJsCanvas).x8c = function (x, y, width, height, format, type, pixels) {
    return this.g99_1.readPixels(x, y, width, height, format, type, get_arrayUByte(pixels));
  };
  protoOf(KmlGlJsCanvas).i8d = function (target, internalformat, width, height) {
    return this.g99_1.renderbufferStorage(target, internalformat, width, height);
  };
  protoOf(KmlGlJsCanvas).p8d = function (x, y, width, height) {
    return this.g99_1.scissor(x, y, width, height);
  };
  protoOf(KmlGlJsCanvas).x8d = function (shader, string) {
    return this.g99_1.shaderSource(get_3(shader, this), string);
  };
  protoOf(KmlGlJsCanvas).z8a = function (func, ref, mask) {
    return this.g99_1.stencilFunc(func, ref, mask);
  };
  protoOf(KmlGlJsCanvas).y8a = function (face, func, ref, mask) {
    return this.g99_1.stencilFuncSeparate(face, func, ref, mask);
  };
  protoOf(KmlGlJsCanvas).l8a = function (mask) {
    return this.g99_1.stencilMask(mask);
  };
  protoOf(KmlGlJsCanvas).c8b = function (face, mask) {
    return this.g99_1.stencilMaskSeparate(face, mask);
  };
  protoOf(KmlGlJsCanvas).b8b = function (fail, zfail, zpass) {
    return this.g99_1.stencilOp(fail, zfail, zpass);
  };
  protoOf(KmlGlJsCanvas).a8b = function (face, sfail, dpfail, dppass) {
    return this.g99_1.stencilOpSeparate(face, sfail, dpfail, dppass);
  };
  protoOf(KmlGlJsCanvas).u89 = function (target, level, internalformat, width, height, border, format, type, pixels) {
    var tmp0_subject = type;
    var tmp;
    var tmp$ret$0;
    // Inline function 'korlibs.kgl.KmlGl.FLOAT' call
    Companion_getInstance_68();
    tmp$ret$0 = 5126;
    if (tmp0_subject === tmp$ret$0) {
      var tmp1_safe_receiver = pixels;
      tmp = tmp1_safe_receiver == null ? null : get_arrayFloat(tmp1_safe_receiver);
    } else {
      var tmp2_safe_receiver = pixels;
      tmp = tmp2_safe_receiver == null ? null : get_arrayUByte(tmp2_safe_receiver);
    }
    var vpixels = tmp;
    this.g99_1.texImage2D(target, level, internalformat, width, height, border, format, type, vpixels);
  };
  protoOf(KmlGlJsCanvas).v89 = function (target, level, internalformat, format, type, data) {
    Companion_getInstance_68();
    var tmp$ret$0;
    // Inline function 'korlibs.memory.toInt' call
    var tmp0_toInt = !data.a4r_1 ? data.z4q_1 : false;
    tmp$ret$0 = tmp0_toInt ? 1 : 0;
    this.g99_1.pixelStorei(37441, tmp$ret$0);
    this.g99_1.texImage2D(target, level, internalformat, format, type, (data instanceof HtmlNativeImage ? data : THROW_CCE()).g7a_1);
    Companion_getInstance_68();
    this.g99_1.pixelStorei(37441, 0);
  };
  protoOf(KmlGlJsCanvas).n89 = function (target, pname, param) {
    return this.g99_1.texParameteri(target, pname, param);
  };
  protoOf(KmlGlJsCanvas).j89 = function (location, count, value) {
    return this.g99_1.uniform1fv(get_3(location, this), get_arrayFloat(value).subarray(0, imul(count, 1)));
  };
  protoOf(KmlGlJsCanvas).h87 = function (location, v0) {
    return this.g99_1.uniform1i(get_3(location, this), v0);
  };
  protoOf(KmlGlJsCanvas).c89 = function (location, count, value) {
    return this.g99_1.uniform1iv(get_3(location, this), get_arrayInt(value).subarray(0, imul(count, 1)));
  };
  protoOf(KmlGlJsCanvas).k89 = function (location, count, value) {
    return this.g99_1.uniform2fv(get_3(location, this), get_arrayFloat(value).subarray(0, imul(count, 2)));
  };
  protoOf(KmlGlJsCanvas).d89 = function (location, count, value) {
    return this.g99_1.uniform2iv(get_3(location, this), get_arrayInt(value).subarray(0, imul(count, 2)));
  };
  protoOf(KmlGlJsCanvas).l89 = function (location, count, value) {
    return this.g99_1.uniform3fv(get_3(location, this), get_arrayFloat(value).subarray(0, imul(count, 3)));
  };
  protoOf(KmlGlJsCanvas).e89 = function (location, count, value) {
    return this.g99_1.uniform3iv(get_3(location, this), get_arrayInt(value).subarray(0, imul(count, 3)));
  };
  protoOf(KmlGlJsCanvas).m89 = function (location, count, value) {
    return this.g99_1.uniform4fv(get_3(location, this), get_arrayFloat(value).subarray(0, imul(count, 4)));
  };
  protoOf(KmlGlJsCanvas).f89 = function (location, count, value) {
    return this.g99_1.uniform4iv(get_3(location, this), get_arrayInt(value).subarray(0, imul(count, 4)));
  };
  protoOf(KmlGlJsCanvas).g89 = function (location, count, transpose, value) {
    return this.g99_1.uniformMatrix2fv(get_3(location, this), transpose, get_arrayFloat(value).subarray(0, imul(count, 4)));
  };
  protoOf(KmlGlJsCanvas).h89 = function (location, count, transpose, value) {
    return this.g99_1.uniformMatrix3fv(get_3(location, this), transpose, get_arrayFloat(value).subarray(0, imul(count, 9)));
  };
  protoOf(KmlGlJsCanvas).i89 = function (location, count, transpose, value) {
    return this.g99_1.uniformMatrix4fv(get_3(location, this), transpose, get_arrayFloat(value).subarray(0, imul(count, 16)));
  };
  protoOf(KmlGlJsCanvas).t8d = function (program) {
    return this.g99_1.useProgram(get_3(program, this));
  };
  protoOf(KmlGlJsCanvas).k8c = function (index, size, type, normalized, stride, pointer) {
    return this.g99_1.vertexAttribPointer(index, size, type, normalized, stride, pointer.t1());
  };
  protoOf(KmlGlJsCanvas).z8c = function (x, y, width, height) {
    return this.g99_1.viewport(x, y, width, height);
  };
  protoOf(KmlGlJsCanvas).w90 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = extensions$factory();
    tmp$ret$0 = this.j99_1.f1();
    return tmp$ret$0;
  };
  protoOf(KmlGlJsCanvas).e8b = function (mode, first, count, instancecount) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.drawArraysInstanced(mode, first, count, instancecount);
  };
  protoOf(KmlGlJsCanvas).g8b = function (mode, count, type, indices, instancecount) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.drawElementsInstanced(mode, count, type, indices, instancecount);
  };
  protoOf(KmlGlJsCanvas).e8c = function (index, divisor) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.vertexAttribDivisor(index, divisor);
  };
  protoOf(KmlGlJsCanvas).i7l = function () {
    return get_versionInt(this) >= 2;
  };
  protoOf(KmlGlJsCanvas).r8c = function (target, index, buffer, offset, size) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.bindBufferRange(target, index, get_3(buffer, this), offset, size);
  };
  protoOf(KmlGlJsCanvas).i87 = function (program, name) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    return tmp$ret$0.getUniformBlockIndex(get_3(program, this), name);
  };
  protoOf(KmlGlJsCanvas).j87 = function (program, uniformBlockIndex, uniformBlockBinding) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.uniformBlockBinding(get_3(program, this), uniformBlockIndex, uniformBlockBinding);
  };
  protoOf(KmlGlJsCanvas).j7l = function () {
    return this.m99_1.u99();
  };
  protoOf(KmlGlJsCanvas).j91 = function (n, arrays) {
    var inductionVariable = 0;
    if (inductionVariable < n)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp0_asDynamic = this.g99_1;
        tmp$ret$0 = tmp0_asDynamic;
        var tmp1_unsafeCast = tmp$ret$0.createVertexArray();
        tmp$ret$1 = tmp1_unsafeCast;
        setInt32(arrays, i, alloc(tmp$ret$1, this));
      }
       while (inductionVariable < n);
  };
  protoOf(KmlGlJsCanvas).g8c = function (array) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = this.g99_1;
    tmp$ret$0 = tmp0_asDynamic;
    tmp$ret$0.bindVertexArray(get_3(array, this));
  };
  function WebGLExtension(canvas, name, coreSince, functions, suffix) {
    coreSince = coreSince === VOID ? 1000 : coreSince;
    functions = functions === VOID ? emptyList() : functions;
    suffix = suffix === VOID ? '' : suffix;
    this.n99_1 = canvas;
    this.o99_1 = name;
    this.p99_1 = coreSince;
    this.q99_1 = functions;
    this.r99_1 = suffix;
    this.s99_1 = false;
    this.t99_1 = null;
  }
  protoOf(WebGLExtension).f1 = function () {
    if (!this.s99_1) {
      this.s99_1 = true;
      this.t99_1 = this.n99_1.g99_1.getExtension(this.o99_1);
      var tmp0_iterator = this.q99_1.h();
      while (tmp0_iterator.j()) {
        var func = tmp0_iterator.k();
        var base = removeSuffix(func, this.r99_1);
        var vfunc = this.t99_1[func];
        if (typeof vfunc === 'function') {
          var tmp$ret$0;
          // Inline function 'kotlin.js.asDynamic' call
          var tmp0_asDynamic = this.n99_1.g99_1;
          tmp$ret$0 = tmp0_asDynamic;
          tmp$ret$0[base] = vfunc.bind(this.t99_1);
        }
      }
    }
    return this.t99_1;
  };
  protoOf(WebGLExtension).u99 = function () {
    return this.n99_1.f99_1 >= this.p99_1 ? true : this.f1() != null;
  };
  function extensions$factory() {
    return getPropertyCallableRef('extensions', 1, KProperty1, function (receiver) {
      return receiver.w90();
    }, null);
  }
  function CreateDefaultGameWindow(config) {
    return Companion_getInstance_0().h1k() ? new NodeJsGameWindow() : new BrowserCanvasJsGameWindow();
  }
  function NodeJsGameWindow() {
    JsGameWindow.call(this);
  }
  function onResized($this) {
    $this.i9c_1 = null;
    if (get_isCanvasCreatedAndHandled()) {
      var scale = $this.y96().q94(window.innerWidth, window.innerHeight, $this.l91());
      var canvasWidth = numberToInt(window.innerWidth * scale);
      var canvasHeight = numberToInt(window.innerHeight * scale);
      $this.f9c_1.width = canvasWidth;
      $this.f9c_1.height = canvasHeight;
      $this.f9c_1.style.position = 'absolute';
      $this.f9c_1.style.left = '0';
      $this.f9c_1.style.right = '0';
      $this.f9c_1.style.width = '' + window.innerWidth + 'px';
      $this.f9c_1.style.height = '' + window.innerHeight + 'px';
      $this.n9c_1 = scale;
    } else {
      $this.n9c_1 = $this.f9c_1.width / $this.f9c_1.clientWidth;
    }
    $this.u97(0, 0, $this.f9c_1.width, $this.f9c_1.height);
  }
  function keyEvent($this, me) {
    var tmp0_subject = me.key;
    var tmp;
    switch (tmp0_subject) {
      case '0':
        tmp = Key_N0_getInstance();
        break;
      case '1':
        tmp = Key_N1_getInstance();
        break;
      case '2':
        tmp = Key_N2_getInstance();
        break;
      case '3':
        tmp = Key_N3_getInstance();
        break;
      case '4':
        tmp = Key_N4_getInstance();
        break;
      case '5':
        tmp = Key_N5_getInstance();
        break;
      case '6':
        tmp = Key_N6_getInstance();
        break;
      case '7':
        tmp = Key_N7_getInstance();
        break;
      case '8':
        tmp = Key_N8_getInstance();
        break;
      case '9':
        tmp = Key_N9_getInstance();
        break;
      case 'a':
        tmp = Key_A_getInstance();
        break;
      case 'b':
        tmp = Key_B_getInstance();
        break;
      case 'c':
        tmp = Key_C_getInstance();
        break;
      case 'd':
        tmp = Key_D_getInstance();
        break;
      case 'e':
        tmp = Key_E_getInstance();
        break;
      case 'f':
        tmp = Key_F_getInstance();
        break;
      case 'g':
        tmp = Key_G_getInstance();
        break;
      case 'h':
        tmp = Key_H_getInstance();
        break;
      case 'i':
        tmp = Key_I_getInstance();
        break;
      case 'j':
        tmp = Key_J_getInstance();
        break;
      case 'k':
        tmp = Key_K_getInstance();
        break;
      case 'l':
        tmp = Key_L_getInstance();
        break;
      case 'm':
        tmp = Key_M_getInstance();
        break;
      case 'n':
        tmp = Key_N_getInstance();
        break;
      case 'o':
        tmp = Key_O_getInstance();
        break;
      case 'p':
        tmp = Key_P_getInstance();
        break;
      case 'q':
        tmp = Key_Q_getInstance();
        break;
      case 'r':
        tmp = Key_R_getInstance();
        break;
      case 's':
        tmp = Key_S_getInstance();
        break;
      case 't':
        tmp = Key_T_getInstance();
        break;
      case 'u':
        tmp = Key_U_getInstance();
        break;
      case 'v':
        tmp = Key_V_getInstance();
        break;
      case 'w':
        tmp = Key_W_getInstance();
        break;
      case 'x':
        tmp = Key_X_getInstance();
        break;
      case 'y':
        tmp = Key_Y_getInstance();
        break;
      case 'z':
        tmp = Key_Z_getInstance();
        break;
      case 'F1':
        tmp = Key_F1_getInstance();
        break;
      case 'F2':
        tmp = Key_F2_getInstance();
        break;
      case 'F3':
        tmp = Key_F3_getInstance();
        break;
      case 'F4':
        tmp = Key_F4_getInstance();
        break;
      case 'F5':
        tmp = Key_F5_getInstance();
        break;
      case 'F6':
        tmp = Key_F6_getInstance();
        break;
      case 'F7':
        tmp = Key_F7_getInstance();
        break;
      case 'F8':
        tmp = Key_F8_getInstance();
        break;
      case 'F9':
        tmp = Key_F9_getInstance();
        break;
      case 'F10':
        tmp = Key_F10_getInstance();
        break;
      case 'F11':
        tmp = Key_F11_getInstance();
        break;
      case 'F12':
        tmp = Key_F12_getInstance();
        break;
      case 'F13':
        tmp = Key_F13_getInstance();
        break;
      case 'F14':
        tmp = Key_F14_getInstance();
        break;
      case 'F15':
        tmp = Key_F15_getInstance();
        break;
      case 'F16':
        tmp = Key_F16_getInstance();
        break;
      case 'F17':
        tmp = Key_F17_getInstance();
        break;
      case 'F18':
        tmp = Key_F18_getInstance();
        break;
      case 'F19':
        tmp = Key_F19_getInstance();
        break;
      case 'F20':
        tmp = Key_F20_getInstance();
        break;
      case 'F21':
        tmp = Key_F21_getInstance();
        break;
      case 'F22':
        tmp = Key_F22_getInstance();
        break;
      case 'F23':
        tmp = Key_F23_getInstance();
        break;
      case 'F24':
        tmp = Key_F24_getInstance();
        break;
      case 'F25':
        tmp = Key_F25_getInstance();
        break;
      case '+':
        tmp = Key_PLUS_getInstance();
        break;
      case '-':
        tmp = Key_MINUS_getInstance();
        break;
      case "'":
        tmp = Key_APOSTROPHE_getInstance();
        break;
      case '"':
        tmp = Key_QUOTE_getInstance();
        break;
      default:
        var tmp1_subject = me.code;
        var tmp_0;
        switch (tmp1_subject) {
          case 'MetaLeft':
            tmp_0 = Companion_getInstance_35().e7k();
            break;
          case 'MetaRight':
            tmp_0 = Companion_getInstance_35().f7k();
            break;
          case 'ShiftLeft':
            tmp_0 = Companion_getInstance_35().a7k();
            break;
          case 'ShiftRight':
            tmp_0 = Companion_getInstance_35().b7k();
            break;
          case 'ControlLeft':
            tmp_0 = Companion_getInstance_35().y7j();
            break;
          case 'ControlRight':
            tmp_0 = Companion_getInstance_35().z7j();
            break;
          case 'AltLeft':
            tmp_0 = Companion_getInstance_35().c7k();
            break;
          case 'AltRight':
            tmp_0 = Companion_getInstance_35().d7k();
            break;
          case 'Space':
            tmp_0 = Key_SPACE_getInstance();
            break;
          case 'ArrowUp':
            tmp_0 = Key_UP_getInstance();
            break;
          case 'ArrowDown':
            tmp_0 = Key_DOWN_getInstance();
            break;
          case 'ArrowLeft':
            tmp_0 = Key_LEFT_getInstance();
            break;
          case 'ArrowRight':
            tmp_0 = Key_RIGHT_getInstance();
            break;
          case 'PageUp':
            tmp_0 = Key_PAGE_UP_getInstance();
            break;
          case 'PageDown':
            tmp_0 = Key_PAGE_DOWN_getInstance();
            break;
          case 'Home':
            tmp_0 = Key_HOME_getInstance();
            break;
          case 'End':
            tmp_0 = Key_END_getInstance();
            break;
          case 'Enter':
            tmp_0 = Key_ENTER_getInstance();
            break;
          case 'Escape':
            tmp_0 = Key_ESCAPE_getInstance();
            break;
          case 'Backspace':
            tmp_0 = Key_BACKSPACE_getInstance();
            break;
          case 'Delete':
            tmp_0 = Key_DELETE_getInstance();
            break;
          case 'Insert':
            tmp_0 = Key_INSERT_getInstance();
            break;
          case 'Period':
            tmp_0 = Key_PERIOD_getInstance();
            break;
          case 'Comma':
            tmp_0 = Key_COMMA_getInstance();
            break;
          case 'Semicolon':
            tmp_0 = Key_SEMICOLON_getInstance();
            break;
          case 'Slash':
            tmp_0 = Key_SLASH_getInstance();
            break;
          case 'Tab':
            tmp_0 = Key_TAB_getInstance();
            break;
          default:
            var tmp$ret$0;
            // Inline function 'kotlin.js.asDynamic' call
            var tmp0_asDynamic = window;
            tmp$ret$0 = tmp0_asDynamic;

            if (tmp$ret$0.korgwShowUnsupportedKeys) {
              var tmp$ret$2;
              // Inline function 'korlibs.logger.Logger.info' call
              var tmp2_info = CoroutineKey_getInstance().w91_1;
              var tmp1_log = Level_INFO_getInstance();
              var tmp_1;
              if (tmp2_info.q1n(tmp1_log)) {
                var tmp$ret$1;
                // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.keyEvent.<anonymous>' call
                tmp$ret$1 = 'Unsupported key key=' + me.key + ', code=' + me.code;
                tmp2_info.r1n(tmp1_log, tmp$ret$1);
                tmp_1 = Unit_getInstance();
              }
              tmp$ret$2 = tmp_1;
            }

            tmp_0 = Key_UNKNOWN_getInstance();
            break;
        }

        tmp = tmp_0;
        break;
    }
    var key = tmp;
    $this.b7d($this.n96($this.q92_1, BrowserCanvasJsGameWindow$keyEvent$lambda(me, key)));
    if (key.equals(Key_TAB_getInstance()) ? true : key.i7k()) {
      me.preventDefault();
    }
  }
  function touchEvent($this, e, type) {
    var canvasBounds = $this.f9c_1.getBoundingClientRect();
    var tmp$ret$6;
    // Inline function 'kotlin.also' call
    var tmp$ret$4;
    // Inline function 'korlibs.event.TouchBuilder.frame' call
    var tmp0_frame = $this.t92_1;
    var tmp1_frame = Mode_JS_getInstance();
    tmp0_frame.t7h_1 = tmp1_frame;
    tmp0_frame.u7h(type, false);
    try {
      // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.touchEvent.<anonymous>' call
      var inductionVariable = 0;
      var last = e.touches.length;
      if (inductionVariable < last)
        $l$loop: do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp1_elvis_lhs = e.touches.item(n);
          var tmp;
          if (tmp1_elvis_lhs == null) {
            continue $l$loop;
          } else {
            tmp = tmp1_elvis_lhs;
          }
          var touch = tmp;
          var touchId = touch.identifier;
          var tmp$ret$0;
          // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.transformEventX' call
          var tmp0_transformEventX = touch.clientX - canvasBounds.left;
          tmp$ret$0 = tmp0_transformEventX * $this.n9c_1;
          var tmp_0 = tmp$ret$0;
          var tmp$ret$1;
          // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.transformEventY' call
          var tmp1_transformEventY = touch.clientY - canvasBounds.top;
          tmp$ret$1 = tmp1_transformEventY * $this.n9c_1;
          var tmp_1 = new Vector2(tmp_0, tmp$ret$1);
          var tmp$ret$3;
          // Inline function 'kotlin.js.unsafeCast' call
          var tmp$ret$2;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$2 = touch;
          var tmp2_unsafeCast = tmp$ret$2.force;
          tmp$ret$3 = tmp2_unsafeCast;
          var tmp2_elvis_lhs = tmp$ret$3;
          tmp0_frame.x7h(touchId, tmp_1, tmp2_elvis_lhs == null ? 1.0 : tmp2_elvis_lhs, Kind_FINGER_getInstance());
        }
         while (inductionVariable < last);
    }finally {
      tmp0_frame.v7h();
    }
    tmp$ret$4 = tmp0_frame.s7h_1;
    var tmp2_also = tmp$ret$4;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$5;
    // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.touchEvent.<anonymous>' call
    tmp$ret$5 = Unit_getInstance();
    tmp$ret$6 = tmp2_also;
    $this.b7d(tmp$ret$6);
  }
  function mouseEvent($this, e, type, pressingType) {
    var canvasBounds = $this.f9c_1.getBoundingClientRect();
    var tmp$ret$0;
    // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.transformEventX' call
    var tmp0_transformEventX = e.clientX - canvasBounds.left;
    tmp$ret$0 = tmp0_transformEventX * $this.n9c_1;
    var tx = numberToInt(tmp$ret$0);
    var tmp$ret$1;
    // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.transformEventY' call
    var tmp1_transformEventY = e.clientY - canvasBounds.top;
    tmp$ret$1 = tmp1_transformEventY * $this.n9c_1;
    var ty = numberToInt(tmp$ret$1);
    $this.n96($this.r92_1, BrowserCanvasJsGameWindow$mouseEvent$lambda(e, pressingType, type, tx, ty));
    if (!$this.t9c() ? true : type.equals(Type_SCROLL_getInstance())) {
      $this.b7d($this.r92_1);
    }
  }
  function mouseEvent$default($this, e, type, pressingType, $super) {
    pressingType = pressingType === VOID ? type : pressingType;
    return mouseEvent($this, e, type, pressingType);
  }
  function _get_jsFrame__gugpar($this) {
    var tmp = $this.r9c_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('jsFrame');
    }
  }
  function ensureSoftKeyboardInput($this) {
    if ($this.s9c_1 == null) {
      var tmp = $this;
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = document.createElement('input');
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp.s9c_1 = tmp$ret$1;
      var tmp0_safe_receiver = $this.s9c_1;
      if (tmp0_safe_receiver == null) {
      } else {
        tmp0_safe_receiver.id = 'softKeyboardInput';
      }
      var tmp1_safe_receiver = $this.s9c_1;
      if (tmp1_safe_receiver == null) {
      } else {
        tmp1_safe_receiver.type = 'input';
      }
      var tmp2_safe_receiver = $this.s9c_1;
      var tmp3_safe_receiver = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.style;
      if (tmp3_safe_receiver == null)
        null;
      else {
        var tmp$ret$2;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp3_safe_receiver.zIndex = '10000000';
        tmp3_safe_receiver.position = 'absolute';
        tmp3_safe_receiver.top = '0';
        tmp3_safe_receiver.left = '0';
        tmp3_safe_receiver.width = '200px';
        tmp3_safe_receiver.height = '24px';
        tmp3_safe_receiver.background = 'transparent';
        tmp$ret$2 = Unit_getInstance();
      }
    }
  }
  function BrowserCanvasJsGameWindow$keyEvent$lambda($me, $key) {
    return function ($this$invoke) {
      var tmp = $this$invoke;
      var tmp0_subject = $me.type;
      var tmp_0;
      switch (tmp0_subject) {
        case 'keydown':
          tmp_0 = Type_DOWN_getInstance();
          break;
        case 'keyup':
          tmp_0 = Type_UP_getInstance();
          break;
        case 'keypress':
          tmp_0 = Type_TYPE_getInstance();
          break;
        default:
          var tmp0_error = 'Unsupported event type ' + $me.type;
          throw IllegalStateException_init_$Create$(toString(tmp0_error));
      }
      tmp.u7d_1 = tmp_0;
      $this$invoke.v7d_1 = 0;
      $this$invoke.x7d_1 = $me.keyCode;
      $this$invoke.w7d_1 = $key;
      $this$invoke.z7d_1 = $me.shiftKey;
      $this$invoke.a7e_1 = $me.ctrlKey;
      $this$invoke.b7e_1 = $me.altKey;
      $this$invoke.c7e_1 = $me.metaKey;
      $this$invoke.y7d_1 = numberToChar($me.charCode);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$mouseEvent$lambda($e, $pressingType, $type, $tx, $ty) {
    return function ($this$invoke) {
      $this$invoke.o7e_1 = !($e.buttons === 0) ? $pressingType : $type;
      $this$invoke.b7f_1 = false;
      $this$invoke.p7e_1 = 0;
      $this$invoke.q7e_1 = $tx;
      $this$invoke.r7e_1 = $ty;
      $this$invoke.s7e_1 = Companion_getInstance_36().n($e.button);
      $this$invoke.t7e_1 = $e.buttons;
      $this$invoke.x7e_1 = $e.shiftKey;
      $this$invoke.y7e_1 = $e.ctrlKey;
      $this$invoke.z7e_1 = $e.altKey;
      $this$invoke.a7f_1 = $e.metaKey;
      var tmp;
      if ($type.equals(Type_SCROLL_getInstance())) {
        var tmp$ret$1;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = $e;
        tmp$ret$1 = tmp$ret$0;
        var we = tmp$ret$1;
        var tmp0_subject = we.deltaMode;
        var mode = tmp0_subject === WheelEvent.DOM_DELTA_PIXEL ? ScrollDeltaMode_PIXEL_getInstance() : tmp0_subject === WheelEvent.DOM_DELTA_LINE ? ScrollDeltaMode_LINE_getInstance() : tmp0_subject === WheelEvent.DOM_DELTA_PAGE ? ScrollDeltaMode_PAGE_getInstance() : ScrollDeltaMode_LINE_getInstance();
        var sensitivity = 0.05;
        $this$invoke.j7f(mode, we.deltaX * sensitivity, we.deltaY * sensitivity, we.deltaZ * sensitivity);
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$close$slambda(this$0, resultContinuation) {
    this.c9d_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(BrowserCanvasJsGameWindow$close$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BrowserCanvasJsGameWindow$close$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(BrowserCanvasJsGameWindow$close$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.d9d_1 = this.c9d_1.q9c_1;
            if (this.d9d_1 == null) {
              this.e9d_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = cancelAndJoin(this.d9d_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            var tmp_0 = this;
            tmp_0.e9d_1 = Unit_getInstance();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            ;
            window.close();
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(BrowserCanvasJsGameWindow$close$slambda).g1p = function (completion) {
    var i = new BrowserCanvasJsGameWindow$close$slambda(this.c9d_1, completion);
    return i;
  };
  function BrowserCanvasJsGameWindow$close$slambda_0(this$0, resultContinuation) {
    var i = new BrowserCanvasJsGameWindow$close$slambda(this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function BrowserCanvasJsGameWindow$loop$slambda($entry, this$0, resultContinuation) {
    this.n9d_1 = $entry;
    this.o9d_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(BrowserCanvasJsGameWindow$loop$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BrowserCanvasJsGameWindow$loop$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(BrowserCanvasJsGameWindow$loop$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.n9d_1(this.o9d_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(BrowserCanvasJsGameWindow$loop$slambda).g1p = function (completion) {
    var i = new BrowserCanvasJsGameWindow$loop$slambda(this.n9d_1, this.o9d_1, completion);
    return i;
  };
  function BrowserCanvasJsGameWindow$loop$slambda_0($entry, this$0, resultContinuation) {
    var i = new BrowserCanvasJsGameWindow$loop$slambda($entry, this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function BrowserCanvasJsGameWindow$lambda(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_SCROLL_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_0(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_ENTER_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_1(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_EXIT_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_2(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent(this$0, tmp$ret$1, Type_MOVE_getInstance(), Type_DRAG_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_3(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent(this$0, tmp$ret$1, Type_MOVE_getInstance(), Type_DRAG_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_4(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_EXIT_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_5(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_UP_getInstance_0());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_6(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_DOWN_getInstance_0());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_7(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      mouseEvent$default(this$0, tmp$ret$1, Type_CLICK_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_8(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      touchEvent(this$0, tmp$ret$1, Type_START_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_9(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      touchEvent(this$0, tmp$ret$1, Type_MOVE_getInstance_0());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_10(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      touchEvent(this$0, tmp$ret$1, Type_END_getInstance());
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_11(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      keyEvent(this$0, tmp$ret$1);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_12(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      keyEvent(this$0, tmp$ret$1);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_13(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = it;
      tmp$ret$1 = tmp$ret$0;
      keyEvent(this$0, tmp$ret$1);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_14(this$0) {
    return function (it) {
      onResized(this$0);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_15(this$0) {
    return function (it) {
      this$0.z97(Type_START_getInstance_0(), null);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_16(this$0) {
    return function (it) {
      this$0.z97(Type_END_getInstance_0(), null);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_17(this$0) {
    return function (it) {
      this$0.z97(Type_END_getInstance_0(), null);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_18(it) {
    it.preventDefault();
    return Unit_getInstance();
  }
  function BrowserCanvasJsGameWindow$lambda_19(this$0) {
    return function (it) {
      this$0.z97(Type_START_getInstance_0(), null);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_20(this$0) {
    return function (it) {
      this$0.z97(Type_END_getInstance_0(), null);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_21(this$0) {
    return function (it) {
      it.preventDefault();
      this$0.z97(Type_END_getInstance_0(), null);
      var items = ensureNotNull(it.dataTransfer).items;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mapNotNull' call
      var tmp1_mapNotNull = until(0, items.length);
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mapNotNullTo' call
      var tmp0_mapNotNullTo = ArrayList_init_$Create$();
      // Inline function 'kotlin.collections.forEach' call
      var inductionVariable = tmp1_mapNotNull.p1_1;
      var last = tmp1_mapNotNull.q1_1;
      if (inductionVariable <= last)
        do {
          var element = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
          var tmp$ret$2;
          // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.<anonymous>.<anonymous>' call
          var tmp$ret$1;
          // Inline function 'org.w3c.dom.get' call
          var tmp$ret$0;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$0 = items;
          tmp$ret$1 = tmp$ret$0[element];
          var tmp0_safe_receiver = tmp$ret$1;
          var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.getAsFile();
          tmp$ret$2 = tmp1_safe_receiver == null ? null : toVfs(tmp1_safe_receiver);
          var tmp0_safe_receiver_0 = tmp$ret$2;
          if (tmp0_safe_receiver_0 == null)
            null;
          else {
            var tmp$ret$3;
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            tmp0_mapNotNullTo.b(tmp0_safe_receiver_0);
            tmp$ret$3 = Unit_getInstance();
          }
        }
         while (!(element === last));
      tmp$ret$4 = tmp0_mapNotNullTo;
      tmp$ret$5 = tmp$ret$4;
      var files = tmp$ret$5;
      this$0.z97(Type_DROP_getInstance(), files);
      return Unit_getInstance();
    };
  }
  function BrowserCanvasJsGameWindow$lambda_22(this$0) {
    return function (step) {
      window.requestAnimationFrame(_get_jsFrame__gugpar(this$0));
      this$0.c96();
      return Unit_getInstance();
    };
  }
  function $loopCOROUTINE$2(_this__u8e3s4, entry, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.x9d_1 = _this__u8e3s4;
    this.y9d_1 = entry;
  }
  protoOf($loopCOROUTINE$2).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.x9d_1.b96(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            var ARGUMENT_0 = launchImmediately(ARGUMENT, BrowserCanvasJsGameWindow$loop$slambda_0(this.y9d_1, this.x9d_1, null));
            this.x9d_1.q9c_1 = ARGUMENT_0;
            _get_jsFrame__gugpar(this.x9d_1)(0.0);
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function BrowserCanvasJsGameWindow(canvas) {
    canvas = canvas === VOID ? AGDefaultCanvas() : canvas;
    JsGameWindow.call(this);
    this.f9c_1 = canvas;
    this.g9c_1 = AGWebgl(new AGConfig(), this.f9c_1);
    this.h9c_1 = new DialogInterfaceJs();
    this.i9c_1 = null;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [GameButton_BUTTON_SOUTH_getInstance(), GameButton_BUTTON_EAST_getInstance(), GameButton_BUTTON_WEST_getInstance(), GameButton_BUTTON_NORTH_getInstance(), GameButton_L1_getInstance(), GameButton_R1_getInstance(), GameButton_L2_getInstance(), GameButton_R2_getInstance(), GameButton_SELECT_getInstance(), GameButton_START_getInstance(), GameButton_L3_getInstance(), GameButton_R3_getInstance(), GameButton_UP_getInstance(), GameButton_DOWN_getInstance(), GameButton_LEFT_getInstance(), GameButton_RIGHT_getInstance(), GameButton_SYSTEM_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.j9c_1 = tmp$ret$2;
    var tmp_0 = this;
    var tmp$ret$5;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf_0 = [GameButton_LX_getInstance(), GameButton_LY_getInstance(), GameButton_RX_getInstance(), GameButton_RY_getInstance(), GameButton_L2_getInstance(), GameButton_R2_getInstance(), GameButton_DPADX_getInstance(), GameButton_DPADY_getInstance()];
    var tmp$ret$4;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp0_arrayOf_0;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
    tmp_0.k9c_1 = tmp$ret$5;
    this.l9c_1 = new GamepadInfo();
    this.m9c_1 = Quality_AUTOMATIC_getInstance();
    this.n9c_1 = 1.0;
    this.o9c_1 = Cursor_DEFAULT_getInstance();
    this.p9c_1 = null;
    this.q9c_1 = null;
    var tmp$ret$6;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = window;
    tmp$ret$6 = tmp0_asDynamic;
    tmp$ret$6.canvas = this.f9c_1;
    var tmp$ret$7;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp1_asDynamic = window;
    tmp$ret$7 = tmp1_asDynamic;
    tmp$ret$7.ag = this.k96();
    var tmp$ret$8;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp2_asDynamic = window;
    tmp$ret$8 = tmp2_asDynamic;
    tmp$ret$8.gl = this.k96().u84_1;
    if (get_isCanvasCreatedAndHandled()) {
      var tmp0_safe_receiver = document.body;
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver.appendChild(this.f9c_1);
      var tmp1_safe_receiver = document.body;
      var tmp2_safe_receiver = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.style;
      if (tmp2_safe_receiver == null) {
      } else {
        tmp2_safe_receiver.margin = '0px';
      }
      var tmp3_safe_receiver = document.body;
      var tmp4_safe_receiver = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.style;
      if (tmp4_safe_receiver == null) {
      } else {
        tmp4_safe_receiver.padding = '0px';
      }
      var tmp5_safe_receiver = document.body;
      var tmp6_safe_receiver = tmp5_safe_receiver == null ? null : tmp5_safe_receiver.style;
      if (tmp6_safe_receiver == null) {
      } else {
        tmp6_safe_receiver.overflowX = 'hidden';
      }
      var tmp7_safe_receiver = document.body;
      var tmp8_safe_receiver = tmp7_safe_receiver == null ? null : tmp7_safe_receiver.style;
      if (tmp8_safe_receiver == null) {
      } else {
        tmp8_safe_receiver.overflowY = 'hidden';
      }
    }
    this.f9c_1.addEventListener('wheel', BrowserCanvasJsGameWindow$lambda(this));
    this.f9c_1.addEventListener('mouseenter', BrowserCanvasJsGameWindow$lambda_0(this));
    this.f9c_1.addEventListener('mouseleave', BrowserCanvasJsGameWindow$lambda_1(this));
    this.f9c_1.addEventListener('mouseover', BrowserCanvasJsGameWindow$lambda_2(this));
    this.f9c_1.addEventListener('mousemove', BrowserCanvasJsGameWindow$lambda_3(this));
    this.f9c_1.addEventListener('mouseout', BrowserCanvasJsGameWindow$lambda_4(this));
    this.f9c_1.addEventListener('mouseup', BrowserCanvasJsGameWindow$lambda_5(this));
    this.f9c_1.addEventListener('mousedown', BrowserCanvasJsGameWindow$lambda_6(this));
    this.f9c_1.addEventListener('click', BrowserCanvasJsGameWindow$lambda_7(this));
    this.f9c_1.addEventListener('touchstart', BrowserCanvasJsGameWindow$lambda_8(this));
    this.f9c_1.addEventListener('touchmove', BrowserCanvasJsGameWindow$lambda_9(this));
    this.f9c_1.addEventListener('touchend', BrowserCanvasJsGameWindow$lambda_10(this));
    var tmp_1 = window;
    tmp_1.addEventListener('keypress', BrowserCanvasJsGameWindow$lambda_11(this));
    var tmp_2 = window;
    tmp_2.addEventListener('keydown', BrowserCanvasJsGameWindow$lambda_12(this));
    var tmp_3 = window;
    tmp_3.addEventListener('keyup', BrowserCanvasJsGameWindow$lambda_13(this));
    var tmp_4 = window;
    tmp_4.addEventListener('resize', BrowserCanvasJsGameWindow$lambda_14(this));
    this.f9c_1.ondragenter = BrowserCanvasJsGameWindow$lambda_15(this);
    this.f9c_1.ondragexit = BrowserCanvasJsGameWindow$lambda_16(this);
    this.f9c_1.ondragleave = BrowserCanvasJsGameWindow$lambda_17(this);
    this.f9c_1.ondragover = BrowserCanvasJsGameWindow$lambda_18;
    this.f9c_1.ondragstart = BrowserCanvasJsGameWindow$lambda_19(this);
    this.f9c_1.ondragend = BrowserCanvasJsGameWindow$lambda_20(this);
    this.f9c_1.ondrop = BrowserCanvasJsGameWindow$lambda_21(this);
    onResized(this);
    var tmp_5 = this;
    tmp_5.r9c_1 = BrowserCanvasJsGameWindow$lambda_22(this);
    this.s9c_1 = null;
  }
  protoOf(BrowserCanvasJsGameWindow).z9d = function () {
    return window.devicePixelRatio;
  };
  protoOf(BrowserCanvasJsGameWindow).l91 = function () {
    return this.z9d() <= 0.0 ? 1.0 : isNaN_0(this.z9d()) ? 1.0 : isInfinite(this.z9d()) ? 1.0 : this.z9d();
  };
  protoOf(BrowserCanvasJsGameWindow).k96 = function () {
    return this.g9c_1;
  };
  protoOf(BrowserCanvasJsGameWindow).t9c = function () {
    if (this.i9c_1 == null) {
      var tmp = this;
      var tmp_0;
      try {
        document.createEvent('TouchEvent');
        tmp_0 = true;
      } catch ($p) {
        var tmp_1;
        {
          var e = $p;
          tmp_1 = false;
        }
        tmp_0 = tmp_1;
      }
      tmp.i9c_1 = tmp_0;
    }
    return ensureNotNull(this.i9c_1);
  };
  protoOf(BrowserCanvasJsGameWindow).x97 = function () {
    try {
      if (navigator.getGamepads != null) {
        var tmp$ret$0;
        // Inline function 'kotlin.js.unsafeCast' call
        var tmp0_unsafeCast = navigator.getGamepads();
        tmp$ret$0 = tmp0_unsafeCast;
        var gamepads = tmp$ret$0;
        this.j7j();
        var inductionVariable = 0;
        var last = gamepads.length;
        if (inductionVariable < last)
          $l$loop_0: do {
            var gamepadId = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp$ret$2;
            // Inline function 'korlibs.render.get' call
            var tmp$ret$1;
            // Inline function 'kotlin.js.asDynamic' call
            tmp$ret$1 = gamepads;
            tmp$ret$2 = tmp$ret$1[gamepadId];
            var tmp1_elvis_lhs = tmp$ret$2;
            var tmp;
            if (tmp1_elvis_lhs == null) {
              continue $l$loop_0;
            } else {
              tmp = tmp1_elvis_lhs;
            }
            var controller = tmp;
            if (!(controller.mapping === 'standard'))
              continue $l$loop_0;
            var gamepad = this.l9c_1;
            gamepad.e7i_1 = controller.id;
            var inductionVariable_0 = 0;
            var tmp$ret$3;
            // Inline function 'kotlin.math.min' call
            var tmp1_min = controller.buttons.length;
            var tmp2_min = this.j9c_1.length;
            tmp$ret$3 = Math.min(tmp1_min, tmp2_min);
            var last_0 = tmp$ret$3;
            if (inductionVariable_0 < last_0)
              do {
                var n = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var tmp_0 = gamepad.f7i_1;
                var tmp_1 = this.j9c_1[n].u7i();
                var tmp$ret$5;
                // Inline function 'korlibs.render.get' call
                var tmp3_get = controller.buttons;
                var tmp$ret$4;
                // Inline function 'kotlin.js.asDynamic' call
                tmp$ret$4 = tmp3_get;
                tmp$ret$5 = tmp$ret$4[n];
                tmp_0[tmp_1] = tmp$ret$5.value;
              }
               while (inductionVariable_0 < last_0);
            var inductionVariable_1 = 0;
            var tmp$ret$6;
            // Inline function 'kotlin.math.min' call
            var tmp4_min = controller.axes.length;
            var tmp5_min = this.k9c_1.length;
            tmp$ret$6 = Math.min(tmp4_min, tmp5_min);
            var last_1 = tmp$ret$6;
            if (inductionVariable_1 < last_1)
              do {
                var n_0 = inductionVariable_1;
                inductionVariable_1 = inductionVariable_1 + 1 | 0;
                var isX = (n_0 % 2 | 0) === 0;
                var isY = !isX;
                var tmp$ret$8;
                // Inline function 'korlibs.render.get' call
                var tmp6_get = controller.axes;
                var tmp$ret$7;
                // Inline function 'kotlin.js.asDynamic' call
                tmp$ret$7 = tmp6_get;
                tmp$ret$8 = tmp$ret$7[n_0];
                var value = tmp$ret$8;
                var valueWithoutDeadRange = Companion_getInstance_32().o7i(value, VOID, n_0 <= 3);
                gamepad.f7i_1[this.k9c_1[n_0].u7i()] = isY ? -valueWithoutDeadRange : valueWithoutDeadRange;
              }
               while (inductionVariable_1 < last_1);
            this.k7j(gamepad);
          }
           while (inductionVariable < last);
        this.b98();
      }
    } catch ($p) {
      var e = $p;
      var tmp$ret$10;
      // Inline function 'korlibs.logger.Logger.error' call
      var tmp8_error = CoroutineKey_getInstance().w91_1;
      var tmp7_log = Level_ERROR_getInstance();
      var tmp_2;
      if (tmp8_error.q1n(tmp7_log)) {
        var tmp$ret$9;
        // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.updateGamepads.<anonymous>' call
        tmp$ret$9 = e;
        tmp8_error.r1n(tmp7_log, tmp$ret$9);
        tmp_2 = Unit_getInstance();
      }
      tmp$ret$10 = tmp_2;
    }
  };
  protoOf(BrowserCanvasJsGameWindow).x96 = function (value) {
    if (!this.m9c_1.equals(value)) {
      this.m9c_1 = value;
      onResized(this);
    }
  };
  protoOf(BrowserCanvasJsGameWindow).y96 = function () {
    return this.m9c_1;
  };
  protoOf(BrowserCanvasJsGameWindow).r96 = function (value) {
    document.title = value;
  };
  protoOf(BrowserCanvasJsGameWindow).x18 = function () {
    return this.f9c_1.clientWidth;
  };
  protoOf(BrowserCanvasJsGameWindow).y18 = function () {
    return this.f9c_1.clientHeight;
  };
  protoOf(BrowserCanvasJsGameWindow).i96 = function (value) {
    this.o9c_1 = value;
    var tmp = this.f9c_1.style;
    var tmp0_subject = value;
    var tmp_0;
    if (tmp0_subject instanceof Cursor) {
      var tmp1_subject = value;
      var tmp0 = tmp1_subject.b2_1;
      switch (tmp0) {
        case 0:
          tmp_0 = 'default';
          break;
        case 1:
          tmp_0 = 'crosshair';
          break;
        case 2:
          tmp_0 = 'text';
          break;
        case 3:
          tmp_0 = 'pointer';
          break;
        case 4:
          tmp_0 = 'move';
          break;
        case 5:
          tmp_0 = 'wait';
          break;
        case 6:
          tmp_0 = 'e-resize';
          break;
        case 7:
          tmp_0 = 'w-resize';
          break;
        case 8:
          tmp_0 = 's-resize';
          break;
        case 9:
          tmp_0 = 'n-resize';
          break;
        case 10:
          tmp_0 = 'ne-resize';
          break;
        case 11:
          tmp_0 = 'nw-resize';
          break;
        case 12:
          tmp_0 = 'se-resize';
          break;
        case 13:
          tmp_0 = 'sw-resize';
          break;
        default:
          tmp_0 = 'default';
          break;
      }
    } else {
      tmp_0 = 'default';
    }
    tmp.cursor = tmp_0;
  };
  protoOf(BrowserCanvasJsGameWindow).j96 = function () {
    return this.o9c_1;
  };
  protoOf(BrowserCanvasJsGameWindow).s96 = function (value) {
    this.p9c_1 = value;
    if (!(value == null)) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = document.querySelector("link[rel*='icon']");
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      var link = tmp$ret$1;
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = toHtmlNative(value).o7a();
      var tmp$ret$3;
      // Inline function 'org.w3c.dom.get' call
      var tmp1_get = document.getElementsByTagName('head');
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp1_get;
      tmp$ret$3 = tmp$ret$2[0];
      var tmp0_safe_receiver = tmp$ret$3;
      if (tmp0_safe_receiver == null)
        null;
      else
        tmp0_safe_receiver.appendChild(link);
    } else {
      var tmp1_safe_receiver = document.querySelector("link[rel*='icon']");
      if (tmp1_safe_receiver == null)
        null;
      else {
        tmp1_safe_receiver.remove();
      }
    }
  };
  protoOf(BrowserCanvasJsGameWindow).t96 = function (value) {
    if (!(this.a9e() === value)) {
      var tmp$ret$3;
      // Inline function 'kotlin.runCatching' call
      var tmp;
      try {
        var tmp$ret$1;
        // Inline function 'kotlin.Companion.success' call
        var tmp0_success = Companion_getInstance_14();
        var tmp$ret$0;
        // Inline function 'korlibs.render.BrowserCanvasJsGameWindow.<set-fullscreen>.<anonymous>' call
        var tmp_0;
        if (value) {
          tmp_0 = this.f9c_1.requestFullscreen();
        } else {
          tmp_0 = document.exitFullscreen();
        }
        tmp$ret$0 = tmp_0;
        var tmp1_success = tmp$ret$0;
        tmp$ret$1 = _Result___init__impl__xyqfz8(tmp1_success);
        tmp = tmp$ret$1;
      } catch ($p) {
        var tmp_1;
        if ($p instanceof Error) {
          var e = $p;
          var tmp$ret$2;
          // Inline function 'kotlin.Companion.failure' call
          var tmp2_failure = Companion_getInstance_14();
          tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(e));
          tmp_1 = tmp$ret$2;
        } else {
          throw $p;
        }
        tmp = tmp_1;
      }
      tmp$ret$3 = tmp;
    }
  };
  protoOf(BrowserCanvasJsGameWindow).a9e = function () {
    return !(document.fullscreenElement == null);
  };
  protoOf(BrowserCanvasJsGameWindow).u96 = function (value) {
    this.f9c_1.style.visibility = value ? 'visible' : 'hidden';
  };
  protoOf(BrowserCanvasJsGameWindow).g7s = function (width, height) {
  };
  protoOf(BrowserCanvasJsGameWindow).c97 = function (exitCode) {
    var tmp = MainScope();
    launchImmediately_0(tmp, BrowserCanvasJsGameWindow$close$slambda_0(this, null));
    this.q9c_1 = null;
  };
  protoOf(BrowserCanvasJsGameWindow).o97 = function (entry, $completion) {
    var tmp = new $loopCOROUTINE$2(this, entry, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BrowserCanvasJsGameWindow).e96 = function (windowRect) {
    ensureSoftKeyboardInput(this);
    var tmp0_safe_receiver = this.s9c_1;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.style;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      tmp1_safe_receiver.left = '' + windowRect.f3s() / this.n9c_1 + 'px';
      tmp1_safe_receiver.top = '' + (windowRect.h3s() / this.n9c_1 - 16) + 'px';
      tmp1_safe_receiver.width = '' + windowRect.b3s_1 / this.n9c_1 + 'px';
      tmp1_safe_receiver.font = '32px Arial';
      tmp1_safe_receiver.height = '1px';
      tmp1_safe_receiver.opacity = '0';
      tmp1_safe_receiver.background = 'transparent';
      tmp1_safe_receiver.color = 'transparent';
      println('BOUNDS.setInputRectangle:' + tmp1_safe_receiver.left + ',' + tmp1_safe_receiver.top + ',' + tmp1_safe_receiver.width + ',' + tmp1_safe_receiver.height);
      tmp$ret$0 = Unit_getInstance();
    }
  };
  protoOf(BrowserCanvasJsGameWindow).f96 = function (force, config) {
    var tmp0_safe_receiver = document.body;
    if (tmp0_safe_receiver == null)
      null;
    else
      tmp0_safe_receiver.appendChild(ensureNotNull(this.s9c_1));
    var tmp1_safe_receiver = this.s9c_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      tmp1_safe_receiver.focus();
    }
  };
  protoOf(BrowserCanvasJsGameWindow).h96 = function () {
    var tmp0_safe_receiver = this.s9c_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.blur();
    }
    var tmp1_safe_receiver = document.body;
    if (tmp1_safe_receiver == null)
      null;
    else
      tmp1_safe_receiver.removeChild(ensureNotNull(this.s9c_1));
  };
  function JsGameWindow() {
    GameWindow.call(this);
  }
  function DialogInterfaceJs() {
  }
  //region block: post-declaration
  protoOf(Listener).b2r = cancel;
  protoOf(BaseEventListener).z7c = dispatch$default;
  protoOf(BaseEventListener).a7d = onEvents;
  protoOf(BaseEventListener).b7d = dispatch;
  protoOf(AG).h7l = get_parentFeatures;
  protoOf(AG).i7l = get_isUniformBuffersSupported;
  protoOf(AG).j7l = get_isVertexArraysSupported;
  protoOf(Builder).i7z = get_out;
  protoOf(Builder).h83 = atan;
  protoOf(Builder).o7z = texture2D;
  protoOf(Builder).i83 = pow;
  protoOf(Builder).j83 = sqrt;
  protoOf(Builder).k83 = abs;
  protoOf(Builder).l83 = fract;
  protoOf(Builder).m83 = smoothstep;
  protoOf(Builder).j7z = get_lit;
  protoOf(Builder).n83 = vec2;
  protoOf(Builder).k7z = vec4;
  protoOf(Builder).n7z = get_2;
  protoOf(Builder).o83 = get_x;
  protoOf(Builder).p83 = get_y;
  protoOf(Builder).q83 = get_z;
  protoOf(Builder).r83 = get_w;
  protoOf(Builder).s83 = unaryMinus;
  protoOf(Builder).t83 = minus;
  protoOf(Builder).u83 = minus_0;
  protoOf(Builder).v83 = plus_2;
  protoOf(Builder).e82 = times;
  protoOf(Builder).w83 = times_1;
  protoOf(Builder).j82 = div;
  protoOf(Builder).x83 = div_0;
  protoOf(Builder).y83 = eq;
  protoOf(Builder).z83 = lt;
  protoOf(Builder).b84 = le;
  protoOf(Builder).c84 = gt;
  protoOf(Builder).d84 = gt_0;
  protoOf(ProgramBuilderDefault).g83 = get_Float1;
  protoOf(ProgramBuilderDefault).i7z = get_out;
  protoOf(ProgramBuilderDefault).h83 = atan;
  protoOf(ProgramBuilderDefault).o7z = texture2D;
  protoOf(ProgramBuilderDefault).i83 = pow;
  protoOf(ProgramBuilderDefault).j83 = sqrt;
  protoOf(ProgramBuilderDefault).k83 = abs;
  protoOf(ProgramBuilderDefault).l83 = fract;
  protoOf(ProgramBuilderDefault).m83 = smoothstep;
  protoOf(ProgramBuilderDefault).j7z = get_lit;
  protoOf(ProgramBuilderDefault).n83 = vec2;
  protoOf(ProgramBuilderDefault).k7z = vec4;
  protoOf(ProgramBuilderDefault).n7z = get_2;
  protoOf(ProgramBuilderDefault).o83 = get_x;
  protoOf(ProgramBuilderDefault).p83 = get_y;
  protoOf(ProgramBuilderDefault).q83 = get_z;
  protoOf(ProgramBuilderDefault).r83 = get_w;
  protoOf(ProgramBuilderDefault).h82 = get_a;
  protoOf(ProgramBuilderDefault).s83 = unaryMinus;
  protoOf(ProgramBuilderDefault).t83 = minus;
  protoOf(ProgramBuilderDefault).u83 = minus_0;
  protoOf(ProgramBuilderDefault).v83 = plus_2;
  protoOf(ProgramBuilderDefault).e82 = times;
  protoOf(ProgramBuilderDefault).w83 = times_1;
  protoOf(ProgramBuilderDefault).j82 = div;
  protoOf(ProgramBuilderDefault).x83 = div_0;
  protoOf(ProgramBuilderDefault).y83 = eq;
  protoOf(ProgramBuilderDefault).z83 = lt;
  protoOf(ProgramBuilderDefault).a84 = lt_0;
  protoOf(ProgramBuilderDefault).b84 = le;
  protoOf(ProgramBuilderDefault).c84 = gt;
  protoOf(ProgramBuilderDefault).d84 = gt_0;
  protoOf(ProgramBuilderDefault).e84 = and;
  protoOf(ProgramBuilderDefault).m7z = get_u_Tex;
  protoOf(ProgramBuilderDefault).j81 = get_u_ProjMat;
  protoOf(ProgramBuilderDefault).k81 = get_u_ViewMat;
  protoOf(ProgramBuilderDefault).f82 = get_a_Pos;
  protoOf(ProgramBuilderDefault).b82 = get_a_Tex;
  protoOf(ProgramBuilderDefault).d82 = get_a_Col;
  protoOf(ProgramBuilderDefault).a82 = get_v_Tex;
  protoOf(ProgramBuilderDefault).c82 = get_v_Col;
  protoOf(ProgramBuilderDefault).i82 = get_t_Temp0;
  protoOf(ProgramBuilderDefault).f84 = get_t_Temp1;
  protoOf(AGOpengl).i7l = get_isUniformBuffersSupported;
  protoOf(AGOpengl).j7l = get_isVertexArraysSupported;
  protoOf(AGDummy).h7l = get_parentFeatures;
  protoOf(AGDummy).i7l = get_isUniformBuffersSupported;
  protoOf(AGDummy).j7l = get_isVertexArraysSupported;
  protoOf(AGBaseLog).h7l = get_parentFeatures;
  protoOf(AGBaseLog).i7l = get_isUniformBuffersSupported;
  protoOf(AGBaseLog).j7l = get_isVertexArraysSupported;
  protoOf(AGPrint).h7l = get_parentFeatures;
  protoOf(AGPrint).i7l = get_isUniformBuffersSupported;
  protoOf(AGPrint).j7l = get_isVertexArraysSupported;
  protoOf(GlslGenerator).p8j = precToString;
  protoOf(GlslGenerator).q8j = typeToString;
  protoOf(GlslGenerator).r8j = get_arrayDecl;
  protoOf(GlslGenerator).o8j = get_IN;
  protoOf(GlslGenerator).t8j = get_OUT;
  protoOf(GlslGenerator).s8j = get_UNIFORM;
  protoOf(GlslBodyGenerator).q8j = typeToString;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).i83 = pow;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).j7z = get_lit;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).n7z = get_2;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).t83 = minus;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).v83 = plus_2;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).e82 = times;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).j82 = div;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).z83 = lt;
  protoOf(Program$ExpressionBuilder$Companion$INSTANCE$1).c84 = gt;
  protoOf(FuncBuilder).g83 = get_Float1;
  protoOf(FuncBuilder).l8n = get_Float2;
  protoOf(FuncBuilder).i7z = get_out;
  protoOf(FuncBuilder).h83 = atan;
  protoOf(FuncBuilder).o7z = texture2D;
  protoOf(FuncBuilder).m8n = TERNARY;
  protoOf(FuncBuilder).i83 = pow;
  protoOf(FuncBuilder).j83 = sqrt;
  protoOf(FuncBuilder).k83 = abs;
  protoOf(FuncBuilder).l83 = fract;
  protoOf(FuncBuilder).n8n = clamp;
  protoOf(FuncBuilder).o8n = min_0;
  protoOf(FuncBuilder).p8n = max_1;
  protoOf(FuncBuilder).q8n = fwidth;
  protoOf(FuncBuilder).r8n = mix;
  protoOf(FuncBuilder).m83 = smoothstep;
  protoOf(FuncBuilder).s8n = length;
  protoOf(FuncBuilder).j7z = get_lit;
  protoOf(FuncBuilder).n83 = vec2;
  protoOf(FuncBuilder).k7z = vec4;
  protoOf(FuncBuilder).n7z = get_2;
  protoOf(FuncBuilder).o83 = get_x;
  protoOf(FuncBuilder).p83 = get_y;
  protoOf(FuncBuilder).q83 = get_z;
  protoOf(FuncBuilder).r83 = get_w;
  protoOf(FuncBuilder).h82 = get_a;
  protoOf(FuncBuilder).s83 = unaryMinus;
  protoOf(FuncBuilder).t83 = minus;
  protoOf(FuncBuilder).u83 = minus_0;
  protoOf(FuncBuilder).v83 = plus_2;
  protoOf(FuncBuilder).t8n = plus_3;
  protoOf(FuncBuilder).u8n = plus_4;
  protoOf(FuncBuilder).e82 = times;
  protoOf(FuncBuilder).v8n = times_0;
  protoOf(FuncBuilder).w83 = times_1;
  protoOf(FuncBuilder).j82 = div;
  protoOf(FuncBuilder).x83 = div_0;
  protoOf(FuncBuilder).y83 = eq;
  protoOf(FuncBuilder).z83 = lt;
  protoOf(FuncBuilder).b84 = le;
  protoOf(FuncBuilder).c84 = gt;
  protoOf(FuncBuilder).d84 = gt_0;
  protoOf(KmlGl).h7l = get_parentFeatures;
  protoOf(KmlGl).i7l = get_isUniformBuffersSupported;
  protoOf(KmlGl).j7l = get_isVertexArraysSupported;
  protoOf(DEFAULT).l91 = get_devicePixelRatio;
  protoOf(DEFAULT).m91 = get_pixelsPerInch;
  protoOf(DEFAULT).n91 = get_pixelsPerLogicalInchRatio;
  protoOf(DEFAULT).o91 = get_computedPixelRatio;
  protoOf(GameWindow).z7c = dispatch$default;
  protoOf(GameWindow).b7d = dispatch;
  protoOf(GameWindow).a7d = onEvents;
  protoOf(GameWindow).l91 = get_devicePixelRatio;
  protoOf(GameWindow).m91 = get_pixelsPerInch;
  protoOf(GameWindow).n91 = get_pixelsPerLogicalInchRatio;
  protoOf(GameWindow).o91 = get_computedPixelRatio;
  protoOf(GameWindow).o8 = get_0;
  protoOf(GameWindow).u8 = fold;
  protoOf(GameWindow).t8 = minusKey;
  protoOf(GameWindow).v8 = plus_1;
  protoOf(GameWindowCoroutineDispatcher).v8 = plus_1;
  protoOf(GameWindowCoroutineDispatcher).o8 = get_1;
  protoOf(GameWindowCoroutineDispatcher).u8 = fold;
  protoOf(GameWindowCoroutineDispatcher).t8 = minusKey_0;
  protoOf(KmlGlJsCanvas).h7l = get_parentFeatures;
  protoOf(JsGameWindow).z7c = dispatch$default;
  protoOf(JsGameWindow).b7d = dispatch;
  protoOf(JsGameWindow).a7d = onEvents;
  protoOf(JsGameWindow).l91 = get_devicePixelRatio;
  protoOf(JsGameWindow).m91 = get_pixelsPerInch;
  protoOf(JsGameWindow).n91 = get_pixelsPerLogicalInchRatio;
  protoOf(JsGameWindow).o91 = get_computedPixelRatio;
  protoOf(JsGameWindow).o8 = get_0;
  protoOf(JsGameWindow).u8 = fold;
  protoOf(JsGameWindow).t8 = minusKey;
  protoOf(JsGameWindow).v8 = plus_1;
  protoOf(NodeJsGameWindow).z7c = dispatch$default;
  protoOf(NodeJsGameWindow).b7d = dispatch;
  protoOf(NodeJsGameWindow).a7d = onEvents;
  protoOf(NodeJsGameWindow).l91 = get_devicePixelRatio;
  protoOf(NodeJsGameWindow).m91 = get_pixelsPerInch;
  protoOf(NodeJsGameWindow).n91 = get_pixelsPerLogicalInchRatio;
  protoOf(NodeJsGameWindow).o91 = get_computedPixelRatio;
  protoOf(NodeJsGameWindow).o8 = get_0;
  protoOf(NodeJsGameWindow).u8 = fold;
  protoOf(NodeJsGameWindow).t8 = minusKey;
  protoOf(NodeJsGameWindow).v8 = plus_1;
  protoOf(BrowserCanvasJsGameWindow).z7c = dispatch$default;
  protoOf(BrowserCanvasJsGameWindow).b7d = dispatch;
  protoOf(BrowserCanvasJsGameWindow).a7d = onEvents;
  protoOf(BrowserCanvasJsGameWindow).m91 = get_pixelsPerInch;
  protoOf(BrowserCanvasJsGameWindow).n91 = get_pixelsPerLogicalInchRatio;
  protoOf(BrowserCanvasJsGameWindow).o91 = get_computedPixelRatio;
  protoOf(BrowserCanvasJsGameWindow).o8 = get_0;
  protoOf(BrowserCanvasJsGameWindow).u8 = fold;
  protoOf(BrowserCanvasJsGameWindow).t8 = minusKey;
  protoOf(BrowserCanvasJsGameWindow).v8 = plus_1;
  //endregion
  //region block: init
  GLOBAL_CHECK_GL = false;
  korgwJvmEngine = null;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Key_BACKSPACE_getInstance;
  _.$_$.b = Key_DOWN_getInstance;
  _.$_$.c = Key_EQUAL_getInstance;
  _.$_$.d = Key_ESCAPE_getInstance;
  _.$_$.e = Key_F12_getInstance;
  _.$_$.f = Key_F7_getInstance;
  _.$_$.g = Key_LEFT_getInstance;
  _.$_$.h = Key_META_getInstance;
  _.$_$.i = Key_MINUS_getInstance;
  _.$_$.j = Key_RIGHT_getInstance;
  _.$_$.k = Key_SHIFT_getInstance;
  _.$_$.l = Key_SPACE_getInstance;
  _.$_$.m = Key_TAB_getInstance;
  _.$_$.n = Key_UP_getInstance;
  _.$_$.o = Key_X_getInstance;
  _.$_$.p = Type_DOWN_getInstance;
  _.$_$.q = Type_TYPE_getInstance;
  _.$_$.r = Type_UP_getInstance;
  _.$_$.s = MouseButton_BUTTON_WHEEL_getInstance;
  _.$_$.t = MouseButton_LEFT_getInstance;
  _.$_$.u = MouseButton_MIDDLE_getInstance;
  _.$_$.v = MouseButton_RIGHT_getInstance;
  _.$_$.w = ScrollDeltaMode_LINE_getInstance;
  _.$_$.x = ScrollDeltaMode_PIXEL_getInstance;
  _.$_$.y = Type_CLICK_getInstance;
  _.$_$.z = Type_DOWN_getInstance_0;
  _.$_$.a1 = Type_DRAG_getInstance;
  _.$_$.b1 = Type_MOVE_getInstance;
  _.$_$.c1 = Type_SCROLL_getInstance;
  _.$_$.d1 = Type_UP_getInstance_0;
  _.$_$.e1 = Kind_MOUSE_getInstance;
  _.$_$.f1 = Status_ADD_getInstance;
  _.$_$.g1 = Status_KEEP_getInstance;
  _.$_$.h1 = Status_REMOVE_getInstance;
  _.$_$.i1 = Type_END_getInstance;
  _.$_$.j1 = Type_MOVE_getInstance_0;
  _.$_$.k1 = Type_START_getInstance;
  _.$_$.l1 = Precision_LOW_getInstance;
  _.$_$.m1 = Precision_MEDIUM_getInstance;
  _.$_$.n1 = SamplerVarType_Sampler2D_getInstance;
  _.$_$.o1 = VarType_Float1_getInstance;
  _.$_$.p1 = VarType_Float2_getInstance;
  _.$_$.q1 = VarType_Float4_getInstance;
  _.$_$.r1 = VarType_UByte1_getInstance;
  _.$_$.s1 = VarType_UByte4_getInstance;
  _.$_$.t1 = Cursor_DEFAULT_getInstance;
  _.$_$.u1 = Cursor_HAND_getInstance;
  _.$_$.v1 = Cursor_TEXT_getInstance;
  _.$_$.w1 = Quality_AUTOMATIC_getInstance;
  _.$_$.x1 = Quality_PERFORMANCE_getInstance;
  _.$_$.y1 = dispatch$default;
  _.$_$.z1 = Attribute_init_$Create$;
  _.$_$.a2 = ProgramLayout_init_$Create$;
  _.$_$.b2 = Varying_init_$Create$;
  _.$_$.c2 = AGFrameBuffer_init_$Create$;
  _.$_$.d2 = AGTextureUnits_init_$Create$;
  _.$_$.e2 = AGVertexArrayObject_init_$Create$;
  _.$_$.f2 = AGStencilOpFunc__withActionOnBothPass$default_impl_69nsgo;
  _.$_$.g2 = AGStencilOpFunc__withCompareMode$default_impl_rzbauv;
  _.$_$.h2 = AGStencilReference__withReadMask$default_impl_7mdffx;
  _.$_$.i2 = AGStencilReference__withReferenceValue$default_impl_2trj0v;
  _.$_$.j2 = AGStencilReference__withWriteMask$default_impl_b7j7b4;
  _.$_$.k2 = AGBlending__hashCode_impl_ywnf51;
  _.$_$.l2 = _AGColorMask___init__impl__j38ai8_0;
  _.$_$.m2 = _AGColorMask___init__impl__j38ai8_1;
  _.$_$.n2 = AGColorMask__hashCode_impl_g9082f;
  _.$_$.o2 = AGCompareMode__inverted_impl_7xxk8m;
  _.$_$.p2 = AGCullFace__hashCode_impl_t4e5ur;
  _.$_$.q2 = AGDrawType__hashCode_impl_ylaury;
  _.$_$.r2 = _AGScissor___init__impl__g8s683_0;
  _.$_$.s2 = _AGScissor___get_bottom__impl__6wmhqj;
  _.$_$.t2 = AGScissor__hashCode_impl_x18ye4;
  _.$_$.u2 = _AGScissor___get_left__impl__jiavfz;
  _.$_$.v2 = _AGScissor___get_right__impl__3hpzkc;
  _.$_$.w2 = AGScissor__toRectOrNull_impl_bkzy1a;
  _.$_$.x2 = _AGScissor___get_top__impl__wmf8dv;
  _.$_$.y2 = AGStencilOpFunc__hashCode_impl_nopebb;
  _.$_$.z2 = AGStencilOpFunc__withActionOnBothPass_impl_qbn6at;
  _.$_$.a3 = AGStencilOpFunc__withEnabled_impl_mpcwmr;
  _.$_$.b3 = AGStencilReference__hashCode_impl_52zufx;
  _.$_$.c3 = Companion_getInstance_26;
  _.$_$.d3 = Companion_getInstance_22;
  _.$_$.e3 = Companion_getInstance_33;
  _.$_$.f3 = Companion_getInstance_31;
  _.$_$.g3 = Companion_getInstance_15;
  _.$_$.h3 = Companion_getInstance_35;
  _.$_$.i3 = Companion_getInstance_16;
  _.$_$.j3 = Companion_getInstance_17;
  _.$_$.k3 = Companion_getInstance_24;
  _.$_$.l3 = Companion_getInstance_21;
  _.$_$.m3 = Companion_getInstance_23;
  _.$_$.n3 = Companion_getInstance_25;
  _.$_$.o3 = Companion_getInstance_18;
  _.$_$.p3 = Companion_getInstance_19;
  _.$_$.q3 = AGOpenglFactory_getInstance;
  _.$_$.r3 = Companion_getInstance_67;
  _.$_$.s3 = Companion_getInstance_62;
  _.$_$.t3 = Companion_getInstance_49;
  _.$_$.u3 = Companion_getInstance_48;
  _.$_$.v3 = Companion_getInstance_47;
  _.$_$.w3 = Companion_getInstance_43;
  _.$_$.x3 = Companion_getInstance_51;
  _.$_$.y3 = Companion_getInstance_53;
  _.$_$.z3 = Companion_getInstance_46;
  _.$_$.a4 = Companion_getInstance_44;
  _.$_$.b4 = Companion_getInstance_52;
  _.$_$.c4 = Companion_getInstance_42;
  _.$_$.d4 = Companion_getInstance_41;
  _.$_$.e4 = Companion_getInstance_50;
  _.$_$.f4 = Companion_getInstance_38;
  _.$_$.g4 = ProjViewUB_getInstance;
  _.$_$.h4 = DefaultShaders_getInstance;
  _.$_$.i4 = ShaderIndices_getInstance;
  _.$_$.j4 = DEFAULT_getInstance;
  _.$_$.k4 = CoroutineKey_getInstance;
  _.$_$.l4 = Companion_getInstance_69;
  _.$_$.m4 = BaseEventListener;
  _.$_$.n4 = dispatch;
  _.$_$.o4 = onEvents;
  _.$_$.p4 = EventResult;
  _.$_$.q4 = Event;
  _.$_$.r4 = GamepadInfo;
  _.$_$.s4 = ISoftKeyboardConfig;
  _.$_$.t4 = KeyEvent;
  _.$_$.u4 = MouseEvent;
  _.$_$.v4 = PreventDefaultException;
  _.$_$.w4 = ReshapeEvent;
  _.$_$.x4 = SoftKeyboardConfig;
  _.$_$.y4 = TouchEvent;
  _.$_$.z4 = preventDefault;
  _.$_$.a5 = AGPrint;
  _.$_$.b5 = FragmentShader;
  _.$_$.c5 = Builder;
  _.$_$.d5 = abs;
  _.$_$.e5 = atan;
  _.$_$.f5 = div_0;
  _.$_$.g5 = div;
  _.$_$.h5 = eq;
  _.$_$.i5 = fract;
  _.$_$.j5 = get_2;
  _.$_$.k5 = gt;
  _.$_$.l5 = gt_0;
  _.$_$.m5 = le;
  _.$_$.n5 = get_lit;
  _.$_$.o5 = lt;
  _.$_$.p5 = minus;
  _.$_$.q5 = minus_0;
  _.$_$.r5 = get_out;
  _.$_$.s5 = plus_2;
  _.$_$.t5 = pow;
  _.$_$.u5 = smoothstep;
  _.$_$.v5 = sqrt;
  _.$_$.w5 = texture2D;
  _.$_$.x5 = times;
  _.$_$.y5 = times_1;
  _.$_$.z5 = unaryMinus;
  _.$_$.a6 = vec2;
  _.$_$.b6 = vec4;
  _.$_$.c6 = get_w;
  _.$_$.d6 = get_x;
  _.$_$.e6 = get_y;
  _.$_$.f6 = get_z;
  _.$_$.g6 = If;
  _.$_$.h6 = Program;
  _.$_$.i6 = Sampler;
  _.$_$.j6 = UniformBlockBuffer;
  _.$_$.k6 = UniformBlock;
  _.$_$.l6 = get_Float1;
  _.$_$.m6 = get_Float2;
  _.$_$.n6 = get_Float4;
  _.$_$.o6 = VertexShader;
  _.$_$.p6 = AGBatch;
  _.$_$.q6 = AGBlending;
  _.$_$.r6 = AGBuffer;
  _.$_$.s6 = AGColorMask;
  _.$_$.t6 = AGCullFace;
  _.$_$.u6 = AGDrawType;
  _.$_$.v6 = AGFeatures;
  _.$_$.w6 = AGMultiBatch;
  _.$_$.x6 = BufferCache;
  _.$_$.y6 = AGProgramWithUniforms;
  _.$_$.z6 = AGScissor;
  _.$_$.a7 = AGStencilOpFunc;
  _.$_$.b7 = AGStencilReference;
  _.$_$.c7 = AGTextureDrawer;
  _.$_$.d7 = AGTexture;
  _.$_$.e7 = AGVertexArrayObject;
  _.$_$.f7 = AGVertexData;
  _.$_$.g7 = AG;
  _.$_$.h7 = ProgramBuilderDefault;
  _.$_$.i7 = applyMatrixBounds;
  _.$_$.j7 = draw_0;
  _.$_$.k7 = draw;
  _.$_$.l7 = draw_1;
  _.$_$.m7 = plus_5;
  _.$_$.n7 = readColor;
  _.$_$.o7 = toAGScissor;
  _.$_$.p7 = CreateDefaultGameWindow;
  _.$_$.q7 = DeviceDimensionsProvider;
  _.$_$.r7 = Impl;
  _.$_$.s7 = GameWindowCreationConfig;
  _.$_$.t7 = GameWindow;
  _.$_$.u7 = TextClipboardData;
  _.$_$.v7 = configure;
  //endregion
  return _;
}));
