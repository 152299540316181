(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-klock'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-klock'.");
    }
    root['korge-root-klock'] = factory(typeof this['korge-root-klock'] === 'undefined' ? {} : this['korge-root-klock'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var Long = kotlin_kotlin.$_$.vh;
  var protoOf = kotlin_kotlin.$_$.nd;
  var numberToLong = kotlin_kotlin.$_$.kd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var compareTo = kotlin_kotlin.$_$.ac;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var toString = kotlin_kotlin.$_$.sd;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var equals = kotlin_kotlin.$_$.cc;
  var Comparable = kotlin_kotlin.$_$.nh;
  var classMeta = kotlin_kotlin.$_$.zb;
  var hashCode = kotlin_kotlin.$_$.lc;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var isNaN_0 = kotlin_kotlin.$_$.aj;
  var DoubleCompanionObject_getInstance = kotlin_kotlin.$_$.i4;
  var listOf = kotlin_kotlin.$_$.q8;
  var round = kotlin_kotlin.$_$.wd;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var lazy = kotlin_kotlin.$_$.cj;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(DateTime, 'DateTime', classMeta, VOID, [Comparable]);
  setMetadataFor(TimedResult, 'TimedResult', classMeta);
  setMetadataFor(PerformanceCounter, 'PerformanceCounter', objectMeta);
  setMetadataFor(TimeProvider, 'TimeProvider', interfaceMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta, VOID, [TimeProvider]);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(TimeSpan, 'TimeSpan', classMeta, VOID, [Comparable]);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(KlockInternal, 'KlockInternal', objectMeta);
  //endregion
  function _DateTime___init__impl__ifgty2(unixMillis) {
    return unixMillis;
  }
  function _DateTime___get_unixMillis__impl__ofxkzp($this) {
    return $this;
  }
  function Companion() {
    Companion_instance = this;
    this.n17_1 = new Long(1, 0);
    this.o17_1 = _DateTime___init__impl__ifgty2(0.0);
    this.p17_1 = 6.21355968E13;
  }
  protoOf(Companion).q17 = function (unix) {
    return _DateTime___init__impl__ifgty2(unix);
  };
  protoOf(Companion).r17 = function (unix) {
    return this.q17(unix.wi());
  };
  protoOf(Companion).s17 = function () {
    return _DateTime___init__impl__ifgty2(KlockInternal_getInstance().t17());
  };
  protoOf(Companion).u17 = function () {
    return numberToLong(KlockInternal_getInstance().t17());
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function _DateTime___get_unixMillisDouble__impl__3dzlsc($this) {
    return _DateTime___get_unixMillis__impl__ofxkzp($this);
  }
  function _DateTime___get_unixMillisLong__impl__sdx9bd($this) {
    return numberToLong(_DateTime___get_unixMillisDouble__impl__3dzlsc($this));
  }
  function DateTime__minus_impl_10njw8($this, other) {
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp0__get_milliseconds__kazo1t = _DateTime___get_unixMillisDouble__impl__3dzlsc($this) - _DateTime___get_unixMillisDouble__impl__3dzlsc(other);
    tmp$ret$0 = Companion_getInstance_1().b18(tmp0__get_milliseconds__kazo1t);
    return tmp$ret$0;
  }
  function DateTime__compareTo_impl_geo3bs($this, other) {
    return compareTo(_DateTime___get_unixMillis__impl__ofxkzp($this), _DateTime___get_unixMillis__impl__ofxkzp(other));
  }
  function DateTime__compareTo_impl_geo3bs_0($this, other) {
    var tmp = $this.c18_1;
    return DateTime__compareTo_impl_geo3bs(tmp, other instanceof DateTime ? other.c18_1 : THROW_CCE());
  }
  function DateTime__toString_impl_cnueje($this) {
    return 'DateTime(' + toString(_DateTime___get_unixMillisLong__impl__sdx9bd($this)) + ')';
  }
  function DateTime__hashCode_impl_yf0bc9($this) {
    return getNumberHashCode($this);
  }
  function DateTime__equals_impl_qd7s8j($this, other) {
    if (!(other instanceof DateTime))
      return false;
    var tmp0_other_with_cast = other instanceof DateTime ? other.c18_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function DateTime(unixMillis) {
    Companion_getInstance();
    this.c18_1 = unixMillis;
  }
  protoOf(DateTime).d18 = function (other) {
    return DateTime__compareTo_impl_geo3bs(this.c18_1, other);
  };
  protoOf(DateTime).fc = function (other) {
    return DateTime__compareTo_impl_geo3bs_0(this, other);
  };
  protoOf(DateTime).toString = function () {
    return DateTime__toString_impl_cnueje(this.c18_1);
  };
  protoOf(DateTime).hashCode = function () {
    return DateTime__hashCode_impl_yf0bc9(this.c18_1);
  };
  protoOf(DateTime).equals = function (other) {
    return DateTime__equals_impl_qd7s8j(this.c18_1, other);
  };
  function _Frequency___init__impl__upasnx(hertz) {
    return hertz;
  }
  function _Frequency___get_hertz__impl__9m7k6x($this) {
    return $this;
  }
  function toFrequency(_this__u8e3s4) {
    return get_timesPerSecond(_this__u8e3s4);
  }
  function get_timesPerSecond(_this__u8e3s4) {
    return _Frequency___init__impl__upasnx(1.0 / _TimeSpan___get_seconds__impl__cx2k1c(_this__u8e3s4));
  }
  var KlockLocale_default;
  function TimedResult(result, time) {
    this.e18_1 = result;
    this.f18_1 = time;
  }
  protoOf(TimedResult).toString = function () {
    return 'TimedResult(result=' + this.e18_1 + ', time=' + new TimeSpan(this.f18_1) + ')';
  };
  protoOf(TimedResult).hashCode = function () {
    var result = this.e18_1 == null ? 0 : hashCode(this.e18_1);
    result = imul(result, 31) + TimeSpan__hashCode_impl_izs3k5(this.f18_1) | 0;
    return result;
  };
  protoOf(TimedResult).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TimedResult))
      return false;
    var tmp0_other_with_cast = other instanceof TimedResult ? other : THROW_CCE();
    if (!equals(this.e18_1, tmp0_other_with_cast.e18_1))
      return false;
    if (!equals(this.f18_1, tmp0_other_with_cast.f18_1))
      return false;
    return true;
  };
  function PerformanceCounter() {
    PerformanceCounter_instance = this;
  }
  protoOf(PerformanceCounter).g18 = function () {
    return _HRTimeSpan___get_microsecondsDouble__impl__mglvkj(KlockInternal_getInstance().h18());
  };
  protoOf(PerformanceCounter).i18 = function () {
    return get_timeSpan(KlockInternal_getInstance().h18());
  };
  var PerformanceCounter_instance;
  function PerformanceCounter_getInstance() {
    if (PerformanceCounter_instance == null)
      new PerformanceCounter();
    return PerformanceCounter_instance;
  }
  function Companion_0() {
    Companion_instance_0 = this;
  }
  protoOf(Companion_0).s17 = function () {
    return Companion_getInstance().s17();
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function TimeProvider() {
  }
  function _TimeSpan___init__impl__hqf6tm(milliseconds) {
    return milliseconds;
  }
  function _TimeSpan___get_milliseconds__impl__pb0we5($this) {
    return $this;
  }
  function _TimeSpan___get_nanoseconds__impl__gaf49o($this) {
    var tmp = _TimeSpan___get_milliseconds__impl__pb0we5($this);
    Companion_getInstance_1();
    return tmp / 1.0E-6;
  }
  function _TimeSpan___get_microsecondsInt__impl__sej7yz($this) {
    var tmp = _TimeSpan___get_milliseconds__impl__pb0we5($this);
    Companion_getInstance_1();
    return numberToInt(tmp / 0.001);
  }
  function _TimeSpan___get_seconds__impl__cx2k1c($this) {
    return _TimeSpan___get_milliseconds__impl__pb0we5($this) / get_MILLIS_PER_SECOND();
  }
  function _TimeSpan___get_millisecondsLong__impl__v1fxun($this) {
    return numberToLong(_TimeSpan___get_milliseconds__impl__pb0we5($this));
  }
  function TimeSpan__compareTo_impl_zidry4($this, other) {
    return compareTo(_TimeSpan___get_milliseconds__impl__pb0we5($this), _TimeSpan___get_milliseconds__impl__pb0we5(other));
  }
  function TimeSpan__compareTo_impl_zidry4_0($this, other) {
    var tmp = $this.j18_1;
    return TimeSpan__compareTo_impl_zidry4(tmp, other instanceof TimeSpan ? other.j18_1 : THROW_CCE());
  }
  function TimeSpan__unaryMinus_impl_24dqtx($this) {
    return _TimeSpan___init__impl__hqf6tm(-_TimeSpan___get_milliseconds__impl__pb0we5($this));
  }
  function TimeSpan__plus_impl_qdd6zo($this, other) {
    return _TimeSpan___init__impl__hqf6tm(_TimeSpan___get_milliseconds__impl__pb0we5($this) + _TimeSpan___get_milliseconds__impl__pb0we5(other));
  }
  function TimeSpan__minus_impl_5w5v8s($this, other) {
    return TimeSpan__plus_impl_qdd6zo($this, TimeSpan__unaryMinus_impl_24dqtx(other));
  }
  function TimeSpan__times_impl_86ofia($this, scale) {
    return _TimeSpan___init__impl__hqf6tm(_TimeSpan___get_milliseconds__impl__pb0we5($this) * scale);
  }
  function TimeSpan__times_impl_86ofia_0($this, scale) {
    return _TimeSpan___init__impl__hqf6tm(_TimeSpan___get_milliseconds__impl__pb0we5($this) * scale);
  }
  function TimeSpan__times_impl_86ofia_1($this, scale) {
    return _TimeSpan___init__impl__hqf6tm(_TimeSpan___get_milliseconds__impl__pb0we5($this) * scale);
  }
  function TimeSpan__div_impl_l42f2r($this, scale) {
    return _TimeSpan___init__impl__hqf6tm(_TimeSpan___get_milliseconds__impl__pb0we5($this) / scale);
  }
  function TimeSpan__div_impl_l42f2r_0($this, other) {
    return _TimeSpan___get_milliseconds__impl__pb0we5($this) / _TimeSpan___get_milliseconds__impl__pb0we5(other);
  }
  function _TimeSpan___get_isNil__impl__cma714($this) {
    return isNaN_0(_TimeSpan___get_milliseconds__impl__pb0we5($this));
  }
  function Companion_1() {
    Companion_instance_1 = this;
    this.v17_1 = new Long(1, 0);
    this.w17_1 = 0.001;
    this.x17_1 = 1.0E-6;
    this.y17_1 = _TimeSpan___init__impl__hqf6tm(0.0);
    var tmp = this;
    DoubleCompanionObject_getInstance();
    tmp.z17_1 = _TimeSpan___init__impl__hqf6tm(NaN);
    this.a18_1 = listOf([60, 60, 24]);
  }
  protoOf(Companion_1).b18 = function (ms) {
    var tmp0_subject = ms;
    return tmp0_subject === 0.0 ? this.y17_1 : _TimeSpan___init__impl__hqf6tm(ms);
  };
  protoOf(Companion_1).k18 = function (s) {
    return this.b18(s * 1.0E-6);
  };
  protoOf(Companion_1).l18 = function (s) {
    return this.b18(s * 0.001);
  };
  protoOf(Companion_1).m18 = function (s) {
    return this.b18(s * get_MILLIS_PER_SECOND());
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function TimeSpan__toString_impl_2rdt8q($this) {
    return get_niceStr(_TimeSpan___get_milliseconds__impl__pb0we5($this)) + 'ms';
  }
  function TimeSpan__hashCode_impl_izs3k5($this) {
    return getNumberHashCode($this);
  }
  function TimeSpan__equals_impl_p77wuv($this, other) {
    if (!(other instanceof TimeSpan))
      return false;
    var tmp0_other_with_cast = other instanceof TimeSpan ? other.j18_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function TimeSpan(milliseconds) {
    Companion_getInstance_1();
    this.j18_1 = milliseconds;
  }
  protoOf(TimeSpan).n18 = function (other) {
    return TimeSpan__compareTo_impl_zidry4(this.j18_1, other);
  };
  protoOf(TimeSpan).fc = function (other) {
    return TimeSpan__compareTo_impl_zidry4_0(this, other);
  };
  protoOf(TimeSpan).toString = function () {
    return TimeSpan__toString_impl_2rdt8q(this.j18_1);
  };
  protoOf(TimeSpan).hashCode = function () {
    return TimeSpan__hashCode_impl_izs3k5(this.j18_1);
  };
  protoOf(TimeSpan).equals = function (other) {
    return TimeSpan__equals_impl_p77wuv(this.j18_1, other);
  };
  function min(a, b) {
    var tmp$ret$1;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = _TimeSpan___get_milliseconds__impl__pb0we5(a);
    var tmp1_min = _TimeSpan___get_milliseconds__impl__pb0we5(b);
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var tmp2__get_milliseconds__xtspmr = tmp$ret$0;
    tmp$ret$1 = Companion_getInstance_1().b18(tmp2__get_milliseconds__xtspmr);
    return tmp$ret$1;
  }
  function clamp(_this__u8e3s4, min, max) {
    return TimeSpan__compareTo_impl_zidry4(_this__u8e3s4, min) < 0 ? min : TimeSpan__compareTo_impl_zidry4(_this__u8e3s4, max) > 0 ? max : _this__u8e3s4;
  }
  function max(a, b) {
    var tmp$ret$1;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = _TimeSpan___get_milliseconds__impl__pb0we5(a);
    var tmp1_max = _TimeSpan___get_milliseconds__impl__pb0we5(b);
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    var tmp2__get_milliseconds__xtspmr = tmp$ret$0;
    tmp$ret$1 = Companion_getInstance_1().b18(tmp2__get_milliseconds__xtspmr);
    return tmp$ret$1;
  }
  function roundMilliseconds(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'korlibs.time.milliseconds' call
    var tmp0__get_milliseconds__kazo1t = round(_TimeSpan___get_milliseconds__impl__pb0we5(_this__u8e3s4));
    tmp$ret$0 = Companion_getInstance_1().b18(tmp0__get_milliseconds__kazo1t);
    return tmp$ret$0;
  }
  function _HRTimeSpan___init__impl__12vyrk(nanosecondsRaw) {
    return nanosecondsRaw;
  }
  function _HRTimeSpan___get_nanosecondsRaw__impl__33lkpw($this) {
    return $this;
  }
  function Companion_2() {
    Companion_instance_2 = this;
    this.o18_1 = _HRTimeSpan___init__impl__12vyrk(0.0);
    var tmp = this;
    DoubleCompanionObject_getInstance();
    tmp.p18_1 = _HRTimeSpan___init__impl__12vyrk(NaN);
  }
  protoOf(Companion_2).q18 = function (value) {
    return _HRTimeSpan___init__impl__12vyrk(round(value * 1000000000));
  };
  protoOf(Companion_2).r18 = function (value) {
    return _HRTimeSpan___init__impl__12vyrk(round(value * 1000000));
  };
  protoOf(Companion_2).s18 = function (value) {
    return _HRTimeSpan___init__impl__12vyrk(round(value));
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function _HRTimeSpan___get_microsecondsDouble__impl__mglvkj($this) {
    return _HRTimeSpan___get_nanosecondsRaw__impl__33lkpw($this) / 1000;
  }
  function HRTimeSpan__plus_impl_q133f6($this, other) {
    return Companion_getInstance_2().s18(_HRTimeSpan___get_nanosecondsRaw__impl__33lkpw($this) + _HRTimeSpan___get_nanosecondsRaw__impl__33lkpw(other));
  }
  function get_timeSpan(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'korlibs.time.nanoseconds' call
    var tmp0__get_nanoseconds__hup2jk = _HRTimeSpan___get_nanosecondsRaw__impl__33lkpw(_this__u8e3s4);
    tmp$ret$0 = Companion_getInstance_1().k18(tmp0__get_nanoseconds__hup2jk);
    return tmp$ret$0;
  }
  function get_niceStr(_this__u8e3s4) {
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.math.floor' call
    tmp$ret$0 = Math.floor(_this__u8e3s4);
    if (tmp$ret$0 === _this__u8e3s4) {
      tmp = '' + numberToInt(_this__u8e3s4);
    } else {
      tmp = '' + _this__u8e3s4;
    }
    return tmp;
  }
  function get_MILLIS_PER_SECOND() {
    return MILLIS_PER_SECOND;
  }
  var MILLIS_PER_SECOND;
  var ExtendedTimezoneNamesOrNull;
  function get_isNode() {
    _init_properties_KlockInternal_kt__golekf();
    return isNode;
  }
  var isNode;
  function get_initialHrTime() {
    _init_properties_KlockInternal_kt__golekf();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = initialHrTime$factory();
    tmp$ret$0 = initialHrTime$delegate.f1();
    return tmp$ret$0;
  }
  var initialHrTime$delegate;
  function KlockInternal() {
    KlockInternal_instance = this;
  }
  protoOf(KlockInternal).t17 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = Date.now();
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  };
  protoOf(KlockInternal).h18 = function () {
    var tmp;
    if (get_isNode()) {
      var tmp$ret$0;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = process.hrtime(get_initialHrTime());
      tmp$ret$0 = tmp0_unsafeCast;
      var result = tmp$ret$0;
      tmp = HRTimeSpan__plus_impl_q133f6(Companion_getInstance_2().q18(result[0]), Companion_getInstance_2().s18(result[1]));
    } else {
      tmp = Companion_getInstance_2().r18(window.performance.now());
    }
    return tmp;
  };
  var KlockInternal_instance;
  function KlockInternal_getInstance() {
    if (KlockInternal_instance == null)
      new KlockInternal();
    return KlockInternal_instance;
  }
  function initialHrTime$delegate$lambda() {
    _init_properties_KlockInternal_kt__golekf();
    return process.hrtime();
  }
  function initialHrTime$factory() {
    return getPropertyCallableRef('initialHrTime', 0, KProperty0, function () {
      return get_initialHrTime();
    }, null);
  }
  var properties_initialized_KlockInternal_kt_cyz5dp;
  function _init_properties_KlockInternal_kt__golekf() {
    if (properties_initialized_KlockInternal_kt_cyz5dp) {
    } else {
      properties_initialized_KlockInternal_kt_cyz5dp = true;
      isNode = typeof window === 'undefined';
      initialHrTime$delegate = lazy(initialHrTime$delegate$lambda);
    }
  }
  //region block: init
  KlockLocale_default = null;
  MILLIS_PER_SECOND = 1000;
  ExtendedTimezoneNamesOrNull = null;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = DateTime__hashCode_impl_yf0bc9;
  _.$_$.b = DateTime__minus_impl_10njw8;
  _.$_$.c = _Frequency___get_hertz__impl__9m7k6x;
  _.$_$.d = _TimeSpan___init__impl__hqf6tm;
  _.$_$.e = TimeSpan__compareTo_impl_zidry4;
  _.$_$.f = TimeSpan__div_impl_l42f2r;
  _.$_$.g = TimeSpan__div_impl_l42f2r_0;
  _.$_$.h = TimeSpan__hashCode_impl_izs3k5;
  _.$_$.i = _TimeSpan___get_isNil__impl__cma714;
  _.$_$.j = _TimeSpan___get_microsecondsInt__impl__sej7yz;
  _.$_$.k = _TimeSpan___get_milliseconds__impl__pb0we5;
  _.$_$.l = _TimeSpan___get_millisecondsLong__impl__v1fxun;
  _.$_$.m = TimeSpan__minus_impl_5w5v8s;
  _.$_$.n = _TimeSpan___get_nanoseconds__impl__gaf49o;
  _.$_$.o = TimeSpan__plus_impl_qdd6zo;
  _.$_$.p = _TimeSpan___get_seconds__impl__cx2k1c;
  _.$_$.q = TimeSpan__times_impl_86ofia;
  _.$_$.r = TimeSpan__times_impl_86ofia_0;
  _.$_$.s = TimeSpan__times_impl_86ofia_1;
  _.$_$.t = Companion_getInstance;
  _.$_$.u = PerformanceCounter_getInstance;
  _.$_$.v = Companion_getInstance_0;
  _.$_$.w = Companion_getInstance_1;
  _.$_$.x = DateTime;
  _.$_$.y = TimeProvider;
  _.$_$.z = TimeSpan;
  _.$_$.a1 = TimedResult;
  _.$_$.b1 = clamp;
  _.$_$.c1 = max;
  _.$_$.d1 = min;
  _.$_$.e1 = roundMilliseconds;
  _.$_$.f1 = toFrequency;
  //endregion
  return _;
}));
