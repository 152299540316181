(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './kotlinx-serialization-kotlinx-serialization-core-js-ir.js', './XenoTacticV2-XenoECS.js', './korge-root-klogger.js', './korge-root-korio.js', './korge-root-korma.js', './korge-root-kds.js', './korge-root-korim.js', './korge-root-kmem.js', './korge-root-korge.js', './kotlinx-serialization-kotlinx-serialization-json-js-ir.js', './kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js', './korge-root-korgw.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./kotlinx-serialization-kotlinx-serialization-core-js-ir.js'), require('./XenoTacticV2-XenoECS.js'), require('./korge-root-klogger.js'), require('./korge-root-korio.js'), require('./korge-root-korma.js'), require('./korge-root-kds.js'), require('./korge-root-korim.js'), require('./korge-root-kmem.js'), require('./korge-root-korge.js'), require('./kotlinx-serialization-kotlinx-serialization-json-js-ir.js'), require('./kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'), require('./korge-root-korgw.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['kotlinx-serialization-kotlinx-serialization-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'kotlinx-serialization-kotlinx-serialization-core-js-ir' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-core-js-ir' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['XenoTacticV2-XenoECS'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'XenoTacticV2-XenoECS' was not found. Please, check whether 'XenoTacticV2-XenoECS' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-klogger'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-klogger' was not found. Please, check whether 'korge-root-klogger' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-korio'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-korio' was not found. Please, check whether 'korge-root-korio' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-korma'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-korma' was not found. Please, check whether 'korge-root-korma' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-kds'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-kds' was not found. Please, check whether 'korge-root-kds' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-korim'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-korim' was not found. Please, check whether 'korge-root-korim' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-korge'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-korge' was not found. Please, check whether 'korge-root-korge' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['kotlinx-serialization-kotlinx-serialization-json-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'kotlinx-serialization-kotlinx-serialization-json-js-ir' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-json-js-ir' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' was not found. Please, check whether 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    if (typeof this['korge-root-korgw'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoTactic-GameLogic'. Its dependency 'korge-root-korgw' was not found. Please, check whether 'korge-root-korgw' is loaded prior to 'XenoTacticV2-XenoTactic-GameLogic'.");
    }
    root['XenoTacticV2-XenoTactic-GameLogic'] = factory(typeof this['XenoTacticV2-XenoTactic-GameLogic'] === 'undefined' ? {} : this['XenoTacticV2-XenoTactic-GameLogic'], this['kotlin-kotlin-stdlib-js-ir'], this['kotlinx-serialization-kotlinx-serialization-core-js-ir'], this['XenoTacticV2-XenoECS'], this['korge-root-klogger'], this['korge-root-korio'], this['korge-root-korma'], this['korge-root-kds'], this['korge-root-korim'], this['korge-root-kmem'], this['korge-root-korge'], this['kotlinx-serialization-kotlinx-serialization-json-js-ir'], this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'], this['korge-root-korgw']);
  }
}(this, function (_, kotlin_kotlin, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core, kotlin_com_xenotactic_ecs_XenoECS, kotlin_com_soywiz_korlibs_klogger_klogger, kotlin_com_soywiz_korlibs_korio_korio, kotlin_com_soywiz_korlibs_korma_korma, kotlin_com_soywiz_korlibs_kds_kds, kotlin_com_soywiz_korlibs_korim_korim, kotlin_com_soywiz_korlibs_kmem_kmem, kotlin_com_soywiz_korlibs_korge2_korge, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_json, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core, kotlin_com_soywiz_korlibs_korgw_korgw) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var sign = Math.sign;
  var protoOf = kotlin_kotlin.$_$.nd;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var equals = kotlin_kotlin.$_$.cc;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var objectMeta = kotlin_kotlin.$_$.md;
  var PluginGeneratedSerialDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.f2;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var UnknownFieldException_init_$Create$ = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.e;
  var typeParametersSerializers = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.z1;
  var GeneratedSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.a2;
  var throwMissingFieldException = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.i2;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var hashCode = kotlin_kotlin.$_$.lc;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var toString = kotlin_kotlin.$_$.sd;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var _Duration___get_inWholeMicroseconds__impl__8oe8vv = kotlin_kotlin.$_$.c2;
  var IntSerializer_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.p;
  var LinkedHashMapSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.d2;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var getKClass = kotlin_kotlin.$_$.e;
  var SealedClassSerializer_init_$Create$ = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.b;
  var LazyThreadSafetyMode_PUBLICATION_getInstance = kotlin_kotlin.$_$.h;
  var lazy = kotlin_kotlin.$_$.bj;
  var SerializerFactory = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.g2;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var EntityId = kotlin_com_xenotactic_ecs_XenoECS.$_$.h;
  var EntityId__hashCode_impl_1s8fhw = kotlin_com_xenotactic_ecs_XenoECS.$_$.o;
  var Companion_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.f;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var launchImmediately = kotlin_com_soywiz_korlibs_korio_korio.$_$.i1;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.o;
  var isSuspendFunction = kotlin_kotlin.$_$.cd;
  var Companion_getInstance_0 = kotlin_com_soywiz_korlibs_korio_korio.$_$.z;
  var MutableCollection = kotlin_kotlin.$_$.n5;
  var isInterface = kotlin_kotlin.$_$.xc;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var ArrayListSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.w1;
  var get_nullable = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.t;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var asSequence = kotlin_kotlin.$_$.a6;
  var emptySequence = kotlin_kotlin.$_$.ve;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var trimIndent = kotlin_kotlin.$_$.dh;
  var addAll = kotlin_kotlin.$_$.v5;
  var filter = kotlin_kotlin.$_$.we;
  var listOfNotNull = kotlin_kotlin.$_$.o8;
  var plus = kotlin_kotlin.$_$.a9;
  var Comparator = kotlin_kotlin.$_$.oh;
  var compareValues = kotlin_kotlin.$_$.ra;
  var World = kotlin_com_xenotactic_ecs_XenoECS.$_$.m;
  var setOf = kotlin_kotlin.$_$.l9;
  var FamilyConfiguration = kotlin_com_xenotactic_ecs_XenoECS.$_$.i;
  var setOf_0 = kotlin_kotlin.$_$.k9;
  var Companion_getInstance_1 = kotlin_com_xenotactic_ecs_XenoECS.$_$.p;
  var map = kotlin_kotlin.$_$.af;
  var sum = kotlin_kotlin.$_$.df;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.t;
  var sortedWith = kotlin_kotlin.$_$.q9;
  var emptyList = kotlin_kotlin.$_$.e7;
  var Companion_getInstance_2 = kotlin_com_soywiz_korlibs_korma_korma.$_$.t4;
  var Companion_getInstance_3 = kotlin_com_soywiz_korlibs_korma_korma.$_$.f5;
  var Angle_between = kotlin_com_soywiz_korlibs_korma_korma.$_$.u6;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var toSet = kotlin_kotlin.$_$.ka;
  var Collection = kotlin_kotlin.$_$.d5;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var EnumSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.y1;
  var StringSerializer_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.q;
  var BooleanSerializer_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.n;
  var DoubleSerializer_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.o;
  var Enum = kotlin_kotlin.$_$.rh;
  var sortWith = kotlin_kotlin.$_$.o9;
  var firstOrNull = kotlin_kotlin.$_$.l7;
  var newThrowable = kotlin_kotlin.$_$.fd;
  var stackTraceToString = kotlin_kotlin.$_$.gj;
  var Companion_getInstance_4 = kotlin_com_soywiz_korlibs_kds_kds.$_$.s;
  var plus_0 = kotlin_kotlin.$_$.z8;
  var reverse = kotlin_kotlin.$_$.j9;
  var Pair = kotlin_kotlin.$_$.yh;
  var first = kotlin_kotlin.$_$.n7;
  var toList = kotlin_kotlin.$_$.da;
  var dropLast = kotlin_kotlin.$_$.c7;
  var plus_1 = kotlin_kotlin.$_$.b9;
  var compareTo = kotlin_kotlin.$_$.ac;
  var emptySet = kotlin_kotlin.$_$.g7;
  var Angle__cosine_impl_bgvzun = kotlin_com_soywiz_korlibs_korma_korma.$_$.h1;
  var Angle__sine_impl_3l11xv = kotlin_com_soywiz_korlibs_korma_korma.$_$.o1;
  var println = kotlin_kotlin.$_$.pb;
  var System = kotlin_com_xenotactic_ecs_XenoECS.$_$.k;
  var SingletonInjectionDoesNotExistException = kotlin_com_xenotactic_ecs_XenoECS.$_$.j;
  var _Duration___get_inWholeMilliseconds__impl__msfiry = kotlin_kotlin.$_$.d2;
  var to = kotlin_kotlin.$_$.lj;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.u1;
  var AseLayer = kotlin_com_soywiz_korlibs_korim_korim.$_$.v2;
  var Bitmap32 = kotlin_com_soywiz_korlibs_korim_korim.$_$.s1;
  var get_bmp = kotlin_com_soywiz_korlibs_korim_korim.$_$.e2;
  var _RGBA___get_a__impl__6msvu7 = kotlin_com_soywiz_korlibs_korim_korim.$_$.j;
  var TypedInjections = kotlin_com_xenotactic_ecs_XenoECS.$_$.l;
  var asIterable = kotlin_kotlin.$_$.y5;
  var mapNotNull = kotlin_kotlin.$_$.ze;
  var toSet_0 = kotlin_kotlin.$_$.ef;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var Companion_getInstance_5 = kotlin_kotlin.$_$.q4;
  var DurationUnit_MILLISECONDS_getInstance = kotlin_kotlin.$_$.g;
  var toDuration = kotlin_kotlin.$_$.kh;
  var Long = kotlin_kotlin.$_$.vh;
  var numberToDouble = kotlin_kotlin.$_$.id;
  var InlineClassDescriptor = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.b2;
  var Comparable = kotlin_kotlin.$_$.nh;
  var ClosedRange = kotlin_kotlin.$_$.ae;
  var Iterable = kotlin_kotlin.$_$.h5;
  var Companion_getInstance_6 = kotlin_com_soywiz_korlibs_korma_korma.$_$.v4;
  var numberRangeToNumber = kotlin_kotlin.$_$.gd;
  var get_resourcesVfs = kotlin_com_soywiz_korlibs_korio_korio.$_$.z1;
  var ASE_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.n1;
  var toProps = kotlin_com_soywiz_korlibs_korim_korim.$_$.b3;
  var readImageDataContainer = kotlin_com_soywiz_korlibs_korim_korim.$_$.f;
  var set_onlyReadVisibleLayers = kotlin_com_soywiz_korlibs_korim_korim.$_$.z2;
  var readTtfFont = kotlin_com_soywiz_korlibs_korim_korim.$_$.b;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var listOf = kotlin_kotlin.$_$.p8;
  var listOf_0 = kotlin_kotlin.$_$.q8;
  var mapOf = kotlin_kotlin.$_$.s8;
  var Companion_getInstance_7 = kotlin_com_soywiz_korlibs_kds_kds.$_$.r;
  var isIntArray = kotlin_kotlin.$_$.wc;
  var IntArray2 = kotlin_com_soywiz_korlibs_kds_kds.$_$.l1;
  var count = kotlin_kotlin.$_$.ue;
  var MaterialColors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.j1;
  var RGBA = kotlin_com_soywiz_korlibs_korim_korim.$_$.i2;
  var roundDecimalPlaces = kotlin_com_soywiz_korlibs_korma_korma.$_$.h8;
  var clamp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k3;
  var clamp_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j3;
  var Companion_getInstance_8 = kotlin_kotlin.$_$.t4;
  var toLong = kotlin_kotlin.$_$.qd;
  var Companion_getInstance_9 = kotlin_com_soywiz_korlibs_kds_kds.$_$.p;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var isArray = kotlin_kotlin.$_$.oc;
  var Array2 = kotlin_com_soywiz_korlibs_kds_kds.$_$.v;
  var Vector2_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.v;
  var Size = kotlin_com_soywiz_korlibs_korma_korma.$_$.k7;
  var Scale_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.s;
  var _RectCorners___init__impl__w373y9 = kotlin_com_soywiz_korlibs_korma_korma.$_$.z3;
  var sequenceOf = kotlin_kotlin.$_$.bf;
  var get_radians = kotlin_com_soywiz_korlibs_korma_korma.$_$.r7;
  var xy = kotlin_com_soywiz_korlibs_korge2_korge.$_$.s2;
  var size = kotlin_com_soywiz_korlibs_korge2_korge.$_$.r2;
  var Default_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_json.$_$.a;
  var arrayOf = kotlin_kotlin.$_$.qi;
  var createKType = kotlin_kotlin.$_$.b;
  var serializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.s2;
  var KSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.n2;
  var runBlockingNoJs = kotlin_com_soywiz_korlibs_korio_korio.$_$.n1;
  var CoroutineScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.t;
  var get_localCurrentDirVfs = kotlin_com_soywiz_korlibs_korio_korio.$_$.y1;
  var toList_0 = kotlin_kotlin.$_$.ea;
  var RectBase = kotlin_com_soywiz_korlibs_korge2_korge.$_$.y1;
  var dispatch$default = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.y1;
  var dispatch = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.n4;
  var onEvents = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.o4;
  var Container = kotlin_com_soywiz_korlibs_korge2_korge.$_$.v1;
  var Colors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.i1;
  var Circle = kotlin_com_soywiz_korlibs_korge2_korge.$_$.u1;
  var addTo = kotlin_com_soywiz_korlibs_korge2_korge.$_$.h2;
  var SolidRect = kotlin_com_soywiz_korlibs_korge2_korge.$_$.d2;
  var Size_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.t;
  var centerOn = kotlin_com_soywiz_korlibs_korge2_korge.$_$.o1;
  var RGBA__withAd_impl_cralao = kotlin_com_soywiz_korlibs_korim_korim.$_$.v;
  var Companion_getInstance_10 = kotlin_com_soywiz_korlibs_korma_korma.$_$.s4;
  var anchor = kotlin_com_soywiz_korlibs_korge2_korge.$_$.j2;
  //endregion
  //region block: pre-declaration
  setMetadataFor(AnimationComponent, 'AnimationComponent', classMeta);
  setMetadataFor(BaseDamageComponent, 'BaseDamageComponent', classMeta);
  setMetadataFor(BaseWeaponSpeedComponent, 'BaseWeaponSpeedComponent', classMeta);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor($serializer, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(BottomLeftPositionComponent, 'BottomLeftPositionComponent', classMeta, VOID, VOID, VOID, {0: $serializer_getInstance});
  setMetadataFor(CollideWithTargetComponent, 'CollideWithTargetComponent', objectMeta);
  setMetadataFor(ComputedSpeedEffectComponent, 'ComputedSpeedEffectComponent', classMeta);
  setMetadataFor(DamageMultiplierComponent, 'DamageMultiplierComponent', classMeta);
  setMetadataFor(DamageUpgradeComponent, 'DamageUpgradeComponent', classMeta);
  setMetadataFor(EntityBlockingComponent, 'EntityBlockingComponent', objectMeta);
  setMetadataFor(EntityCheckpointComponent, 'EntityCheckpointComponent', classMeta);
  setMetadataFor(EntityCostComponent, 'EntityCostComponent', classMeta);
  setMetadataFor(EntityFinishComponent, 'EntityFinishComponent', objectMeta);
  setMetadataFor(EntityRenderComponent, 'EntityRenderComponent', classMeta);
  setMetadataFor(EntityRockComponent, 'EntityRockComponent', objectMeta);
  setMetadataFor(EntitySpeedAreaComponent, 'EntitySpeedAreaComponent', classMeta);
  setMetadataFor(EntityStartComponent, 'EntityStartComponent', objectMeta);
  setMetadataFor(EntitySupplyDepotComponent, 'EntitySupplyDepotComponent', objectMeta);
  setMetadataFor(EntityTeleportInComponent, 'EntityTeleportInComponent', classMeta);
  setMetadataFor(EntityTeleportOutComponent, 'EntityTeleportOutComponent', classMeta);
  setMetadataFor(EntityTowerComponent, 'EntityTowerComponent', objectMeta);
  setMetadataFor(EntityTypeComponent, 'EntityTypeComponent', classMeta);
  setMetadataFor(HealthComponent, 'HealthComponent', classMeta);
  setMetadataFor(MaxHealthComponent, 'MaxHealthComponent', classMeta);
  setMetadataFor(MonsterComponent, 'MonsterComponent', objectMeta);
  setMetadataFor(MutableCenterPositionComponent, 'MutableCenterPositionComponent', classMeta);
  setMetadataFor(PathSequenceTraversalComponent, 'PathSequenceTraversalComponent', classMeta);
  setMetadataFor(PreSelectionComponent, 'PreSelectionComponent', objectMeta);
  setMetadataFor(ProjectileComponent, 'ProjectileComponent', objectMeta);
  setMetadataFor(ProjectileDamageComponent, 'ProjectileDamageComponent', classMeta);
  setMetadataFor(RangeComponent, 'RangeComponent', classMeta);
  setMetadataFor(ReadyToAttackComponent, 'ReadyToAttackComponent', objectMeta);
  setMetadataFor(ReloadDowntimeComponent, 'ReloadDowntimeComponent', classMeta);
  setMetadataFor(SelectableComponent, 'SelectableComponent', objectMeta);
  setMetadataFor(SelectedComponent, 'SelectedComponent', objectMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor($serializer_0, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(SizeComponent, 'SizeComponent', classMeta, VOID, VOID, VOID, {0: $serializer_getInstance_0});
  setMetadataFor(SpeedUpgradeComponent, 'SpeedUpgradeComponent', classMeta);
  setMetadataFor(SupplyCostComponent, 'SupplyCostComponent', classMeta);
  setMetadataFor(TargetingComponent, 'TargetingComponent', classMeta);
  setMetadataFor(UIEightDirectionalSpriteComponent, 'UIEightDirectionalSpriteComponent', classMeta);
  setMetadataFor(UIEntityContainerComponent, 'UIEntityContainerComponent', classMeta);
  setMetadataFor(UIEntityViewComponent, 'UIEntityViewComponent', classMeta);
  setMetadataFor(UIGunBarrelComponent, 'UIGunBarrelComponent', classMeta);
  setMetadataFor(UIHealthBarComponent, 'UIHealthBarComponent', classMeta);
  setMetadataFor(UIMapEntityComponent, 'UIMapEntityComponent', classMeta);
  setMetadataFor(UIMapEntityTextComponent, 'UIMapEntityTextComponent', classMeta);
  setMetadataFor(UISelectionComponent, 'UISelectionComponent', classMeta);
  setMetadataFor(VelocityComponent, 'VelocityComponent', classMeta);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor($serializer_1, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(BlockingPointContainer, 'BlockingPointContainer', classMeta, VOID, VOID, VOID, {0: Companion_getInstance_15});
  setMetadataFor(Mutable, 'Mutable', classMeta, BlockingPointContainer, VOID, VOID, {0: $serializer_getInstance_1});
  setMetadataFor(View, 'View', classMeta, BlockingPointContainer);
  setMetadataFor(Companion_3, 'Companion', objectMeta, VOID, [SerializerFactory]);
  setMetadataFor(AddedEntityEvent, 'AddedEntityEvent', classMeta);
  setMetadataFor(AddedMonsterEntityEvent, 'AddedMonsterEntityEvent', classMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(EventBus$send$slambda, 'EventBus$send$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(EventBus, 'EventBus', classMeta);
  setMetadataFor($sendCOROUTINE$0, '$sendCOROUTINE$0', classMeta, CoroutineImpl);
  setMetadataFor(GlobalBus, 'GlobalBus', classMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldStateUpdated, 'GoldStateUpdated', classMeta);
  setMetadataFor(InformErrorMessageEvent, 'InformErrorMessageEvent', classMeta);
  setMetadataFor(MonsterDeathsEvent, 'MonsterDeathsEvent', classMeta);
  setMetadataFor(RemovedSupplyDepotEntityEvent, 'RemovedSupplyDepotEntityEvent', classMeta);
  setMetadataFor(RemovedTowerEntityEvent, 'RemovedTowerEntityEvent', classMeta);
  setMetadataFor(ResizeMapEvent, 'ResizeMapEvent', classMeta);
  setMetadataFor(UpdatedPathLineEvent, 'UpdatedPathLineEvent', classMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  function get_left() {
    return this.jg8();
  }
  function get_right() {
    return GameUnit__plus_impl_rh1b6r(this.jg8(), this.lg8());
  }
  function get_bottom() {
    return this.kg8();
  }
  function get_top() {
    return GameUnit__plus_impl_rh1b6r(this.kg8(), this.mg8());
  }
  function get_blockGameUnitPoints() {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    var gameUnitPoints = tmp$ret$0;
    var tmp0_iterator = until(0, this.lg8()).h();
    while (tmp0_iterator.j()) {
      var i = tmp0_iterator.k().vg3_1;
      var tmp1_iterator = until(0, this.mg8()).h();
      while (tmp1_iterator.j()) {
        var j = tmp1_iterator.k().vg3_1;
        gameUnitPoints.b(new GameUnitTuple(GameUnit__plus_impl_rh1b6r(this.jg8(), i), GameUnit__plus_impl_rh1b6r(this.kg8(), j)));
      }
    }
    return toSet(gameUnitPoints);
  }
  function get_topLeftUnitSquareGameUnitPoint() {
    return new GameUnitTuple(this.jg8(), GameUnit__minus_impl_uz6kmr_0(GameUnit__plus_impl_rh1b6r(this.kg8(), this.mg8()), 1));
  }
  function get_topRightUnitSquareGameUnitPoint() {
    return new GameUnitTuple(GameUnit__minus_impl_uz6kmr_0(GameUnit__plus_impl_rh1b6r(this.jg8(), this.lg8()), 1), GameUnit__minus_impl_uz6kmr_0(GameUnit__plus_impl_rh1b6r(this.kg8(), this.mg8()), 1));
  }
  function get_bottomLeftUnitSquareGameUnitPoint() {
    return new GameUnitTuple(this.jg8(), this.kg8());
  }
  function get_bottomRightUnitSquareGameUnitPoint() {
    return new GameUnitTuple(GameUnit__minus_impl_uz6kmr_0(GameUnit__plus_impl_rh1b6r(this.jg8(), this.lg8()), 1), this.kg8());
  }
  function get_centerPoint() {
    return new IPoint(_GameUnit___get_value__impl__9ocgox(this.jg8()) + _GameUnit___get_value__impl__9ocgox(this.lg8()) / 2.0, _GameUnit___get_value__impl__9ocgox(this.kg8()) + _GameUnit___get_value__impl__9ocgox(this.mg8()) / 2.0);
  }
  function getRectangle() {
    return new IRectangle(_GameUnit___get_value__impl__9ocgox(this.jg8()), _GameUnit___get_value__impl__9ocgox(this.kg8()), _GameUnit___get_value__impl__9ocgox(this.lg8()), _GameUnit___get_value__impl__9ocgox(this.mg8()));
  }
  function isFullyCoveredBy(entities) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp;
      if (isInterface(entities, Collection)) {
        tmp = entities.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = entities.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.model.IRectangleEntity.isFullyCoveredBy.<anonymous>' call
        tmp$ret$1 = this.yg8(element);
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  function isFullyCoveredBy_0(other) {
    return isFullyCoveredBy_1(GameUnit__toDouble_impl_5m2yph(this.jg8()), GameUnit__toDouble_impl_5m2yph(this.kg8()), GameUnit__toDouble_impl_5m2yph(this.lg8()), GameUnit__toDouble_impl_5m2yph(this.mg8()), GameUnit__toDouble_impl_5m2yph(other.jg8()), GameUnit__toDouble_impl_5m2yph(other.kg8()), GameUnit__toDouble_impl_5m2yph(other.lg8()), GameUnit__toDouble_impl_5m2yph(other.mg8()));
  }
  setMetadataFor(IRectangleEntity, 'IRectangleEntity', interfaceMeta);
  setMetadataFor(GRectInt, 'GRectInt', classMeta, VOID, [IRectangleEntity]);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor($serializer_2, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(GameMap, 'GameMap', classMeta, VOID, VOID, VOID, {0: $serializer_getInstance_2});
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor($serializer_3, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(GameUnitTuple, 'GameUnitTuple', classMeta, VOID, VOID, VOID, {0: $serializer_getInstance_3});
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(GameWorld, 'GameWorld', classMeta);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(IPoint, 'IPoint', classMeta);
  setMetadataFor(IRectangle, 'IRectangle', classMeta);
  setMetadataFor(RectangleEntity, 'RectangleEntity', classMeta, VOID, [IRectangleEntity]);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor($serializer_4, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor($serializer_5, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor($serializer_6, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor($serializer_7, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor($serializer_8, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor($serializer_9, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor($serializer_10, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor($serializer_11, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor($serializer_12, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(Companion_18, 'Companion', objectMeta);
  setMetadataFor($serializer_13, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(MapEntity, 'MapEntity', classMeta, VOID, [IRectangleEntity]);
  setMetadataFor(Start, 'Start', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_4});
  setMetadataFor(Finish, 'Finish', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_5});
  setMetadataFor(TeleportIn, 'TeleportIn', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_6});
  setMetadataFor(TeleportOut, 'TeleportOut', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_7});
  setMetadataFor(Rock, 'Rock', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_8});
  setMetadataFor(Checkpoint, 'Checkpoint', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_9});
  setMetadataFor(Tower, 'Tower', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_10});
  setMetadataFor(SupplyDepot, 'SupplyDepot', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_11});
  setMetadataFor(SmallBlocker, 'SmallBlocker', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_12});
  setMetadataFor(SpeedArea, 'SpeedArea', classMeta, MapEntity, VOID, VOID, {0: $serializer_getInstance_13});
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(TeleportPair, 'TeleportPair', classMeta);
  setMetadataFor(MapEntityData, 'MapEntityData', classMeta);
  setMetadataFor(Start_0, 'Start', objectMeta, MapEntityData);
  setMetadataFor(Finish_0, 'Finish', objectMeta, MapEntityData);
  setMetadataFor(Rock_0, 'Rock', objectMeta, MapEntityData);
  setMetadataFor(Tower_0, 'Tower', objectMeta, MapEntityData);
  setMetadataFor(SupplyDepot_0, 'SupplyDepot', objectMeta, MapEntityData);
  setMetadataFor(SmallBlocker_0, 'SmallBlocker', objectMeta, MapEntityData);
  setMetadataFor(TeleportIn_0, 'TeleportIn', classMeta, MapEntityData);
  setMetadataFor(TeleportOut_0, 'TeleportOut', classMeta, MapEntityData);
  setMetadataFor(Checkpoint_0, 'Checkpoint', classMeta, MapEntityData);
  setMetadataFor(SpeedArea_0, 'SpeedArea', classMeta, MapEntityData);
  setMetadataFor(Monster, 'Monster', objectMeta, MapEntityData);
  setMetadataFor(EntitySize, 'EntitySize', classMeta);
  setMetadataFor(Varied, 'Varied', objectMeta, EntitySize);
  setMetadataFor(Fixed, 'Fixed', classMeta, EntitySize);
  setMetadataFor(Companion_20, 'Companion', objectMeta);
  setMetadataFor(MapEntityType, 'MapEntityType', classMeta, Enum);
  setMetadataFor(Companion_21, 'Companion', objectMeta);
  setMetadataFor($serializer_14, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(PlayerData, 'PlayerData', classMeta, VOID, VOID, VOID, {0: $serializer_getInstance_14});
  setMetadataFor(TowerType, 'TowerType', classMeta, Enum);
  setMetadataFor(sam$kotlin_Comparator$0_0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(FullPathSearcherInternal, 'FullPathSearcherInternal', classMeta);
  setMetadataFor(SearchNode, 'SearchNode', classMeta);
  setMetadataFor(AStarSearcher, 'AStarSearcher', objectMeta);
  setMetadataFor(PathFinder, 'PathFinder', objectMeta);
  setMetadataFor(PathSequenceTraversal, 'PathSequenceTraversal', classMeta);
  setMetadataFor(PathTraversal, 'PathTraversal', classMeta);
  setMetadataFor(SegmentTraversal, 'SegmentTraversal', classMeta);
  setMetadataFor(Companion_22, 'Companion', objectMeta);
  setMetadataFor(PathSequence, 'PathSequence', classMeta);
  setMetadataFor(PathFindingResult, 'PathFindingResult', classMeta);
  setMetadataFor(Success, 'Success', classMeta, PathFindingResult);
  setMetadataFor(Failure, 'Failure', classMeta, PathFindingResult);
  setMetadataFor(GamePath, 'GamePath', classMeta);
  setMetadataFor(CircleIntersectionResult, 'CircleIntersectionResult', classMeta);
  setMetadataFor(Companion_23, 'Companion', objectMeta);
  setMetadataFor(Path, 'Path', classMeta);
  setMetadataFor(Segment, 'Segment', classMeta);
  setMetadataFor(EntityPath, 'EntityPath', classMeta);
  setMetadataFor(EntityToEntityPath, 'EntityToEntityPath', classMeta, EntityPath);
  setMetadataFor(EntityToEntityIntersectsTeleport, 'EntityToEntityIntersectsTeleport', classMeta, EntityPath);
  setMetadataFor(TeleportIntersectionCandidate, 'TeleportIntersectionCandidate', classMeta);
  setMetadataFor(Companion_24, 'Companion', objectMeta);
  setMetadataFor(PathSequenceInfo, 'PathSequenceInfo', classMeta);
  setMetadataFor(Companion_25, 'Companion', objectMeta);
  setMetadataFor(PathingPoint, 'PathingPoint', classMeta);
  setMetadataFor(VerticalDirection, 'VerticalDirection', classMeta, Enum);
  setMetadataFor(HorizontalDirection, 'HorizontalDirection', classMeta, Enum);
  setMetadataFor(Companion_26, 'Companion', objectMeta);
  setMetadataFor(CornerPathingPointConfiguration, 'CornerPathingPointConfiguration', classMeta);
  setMetadataFor(PathingPointsForUnitSquareConfiguration, 'PathingPointsForUnitSquareConfiguration', classMeta);
  setMetadataFor(StandardSolverInternal, 'StandardSolverInternal', classMeta);
  setMetadataFor(StandardSolver3, 'StandardSolver3', classMeta);
  setMetadataFor(SolverSettings, 'SolverSettings', classMeta);
  setMetadataFor(TowerPlacementsToPathCache, 'TowerPlacementsToPathCache', classMeta);
  setMetadataFor(SolverResult, 'SolverResult', classMeta);
  setMetadataFor(Unsuccessful, 'Unsuccessful', objectMeta, SolverResult);
  setMetadataFor(Success_0, 'Success', classMeta, SolverResult);
  setMetadataFor(SolverParams, 'SolverParams', classMeta);
  setMetadataFor(OptimizationGoal, 'OptimizationGoal', classMeta);
  setMetadataFor(MaxPath, 'MaxPath', objectMeta, OptimizationGoal);
  setMetadataFor(SearchResult, 'SearchResult', classMeta);
  setMetadataFor(Unsuccessful_0, 'Unsuccessful', objectMeta, SearchResult);
  setMetadataFor(Success_1, 'Success', classMeta, SearchResult);
  setMetadataFor(SearchState, 'SearchState', classMeta);
  setMetadataFor(NextTowerPlacementSpotsOutput, 'NextTowerPlacementSpotsOutput', classMeta);
  setMetadataFor(GameMapDimensionsState, 'GameMapDimensionsState', classMeta);
  setMetadataFor(GameMapPathState, 'GameMapPathState', classMeta);
  setMetadataFor(Companion_27, 'Companion', objectMeta);
  setMetadataFor(GameplayState, 'GameplayState', classMeta);
  setMetadataFor(MutableCurrentlySelectedTowerState, 'MutableCurrentlySelectedTowerState', classMeta);
  setMetadataFor(MutableEventQueueState, 'MutableEventQueueState', classMeta);
  setMetadataFor(MutableGoldState, 'MutableGoldState', classMeta);
  setMetadataFor(MonsterComputeSpeedEffectSystem, 'MonsterComputeSpeedEffectSystem', classMeta, System);
  setMetadataFor(MonsterDeathSystem, 'MonsterDeathSystem', classMeta, System);
  setMetadataFor(MonsterMoveSystem, 'MonsterMoveSystem', classMeta, System);
  setMetadataFor(MonsterRemoveSystem, 'MonsterRemoveSystem', classMeta, System);
  setMetadataFor(ProjectileCollideSystem, 'ProjectileCollideSystem', classMeta, System);
  setMetadataFor(ProjectileMoveSystem, 'ProjectileMoveSystem', classMeta, System);
  setMetadataFor(ProjectileRemoveSystem, 'ProjectileRemoveSystem', classMeta, System);
  setMetadataFor(ReloadSystem, 'ReloadSystem', classMeta, System);
  setMetadataFor(TargetingAddSystem, 'TargetingAddSystem', classMeta, System);
  setMetadataFor(Companion_28, 'Companion', objectMeta);
  setMetadataFor(TowerAttackSystem, 'TowerAttackSystem', classMeta, System);
  setMetadataFor(TowerTargetingRemoveSystem, 'TowerTargetingRemoveSystem', classMeta, System);
  setMetadataFor(AsepriteModel, 'AsepriteModel', classMeta);
  setMetadataFor(AsepriteFrameWithAllLayers, 'AsepriteFrameWithAllLayers', classMeta);
  setMetadataFor(AsepriteLayer, 'AsepriteLayer', classMeta);
  setMetadataFor(AsepriteLayerWithAllFrames, 'AsepriteLayerWithAllFrames', classMeta);
  setMetadataFor(Engine, 'Engine', classMeta);
  setMetadataFor(EntitiesBlockingEntityResult, 'EntitiesBlockingEntityResult', classMeta);
  setMetadataFor(EntitiesBlockingEntityUtil, 'EntitiesBlockingEntityUtil', objectMeta);
  setMetadataFor(EventLog, 'EventLog', classMeta);
  setMetadataFor(LogEntry, 'LogEntry', classMeta);
  setMetadataFor(GameMapApi, 'GameMapApi', classMeta);
  setMetadataFor(GameSimulator, 'GameSimulator', classMeta);
  setMetadataFor(GameEvent, 'GameEvent', classMeta);
  setMetadataFor(PlaceEntities, 'PlaceEntities', classMeta, GameEvent);
  setMetadataFor(RemoveEntities, 'RemoveEntities', classMeta, GameEvent);
  setMetadataFor(Companion_29, 'Companion', objectMeta);
  setMetadataFor($serializer_15, '$serializer', objectMeta, VOID, [GeneratedSerializer]);
  setMetadataFor(GameUnit, 'GameUnit', classMeta, VOID, [Comparable], VOID, {0: $serializer_getInstance_15});
  setMetadataFor(GameUnitRange$iterator$1, VOID, classMeta);
  setMetadataFor(GameUnitRange, 'GameUnitRange', classMeta, VOID, [ClosedRange, Iterable]);
  setMetadataFor($initCOROUTINE$1, '$initCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor(GlobalResources, 'GlobalResources', objectMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(MapBlockingUtilResults, 'MapBlockingUtilResults', classMeta);
  setMetadataFor(MapBlockingUtil, 'MapBlockingUtil', objectMeta);
  setMetadataFor(MapToId, 'MapToId', objectMeta);
  setMetadataFor(PathingPointUtil, 'PathingPointUtil', classMeta);
  setMetadataFor(EntityMask, 'EntityMask', classMeta);
  setMetadataFor(RockCounterUtil, 'RockCounterUtil', objectMeta);
  setMetadataFor(SpeedAreaColorUtil, 'SpeedAreaColorUtil', objectMeta);
  setMetadataFor(IntStatCounter, 'IntStatCounter', classMeta);
  setMetadataFor(StateUtils, 'StateUtils', classMeta);
  setMetadataFor(TowerCache, 'TowerCache', classMeta);
  setMetadataFor(Companion_30, 'Companion', objectMeta);
  setMetadataFor(WorldUnit, 'WorldUnit', classMeta);
  setMetadataFor(UpgradeDecision, 'UpgradeDecision', classMeta);
  setMetadataFor(TEST_DATA_VFS$slambda, 'TEST_DATA_VFS$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(getGoldenJsonFiles$slambda, 'getGoldenJsonFiles$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(existsBlocking$slambda, 'existsBlocking$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(readStringBlocking$slambda, 'readStringBlocking$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($GOLDENS_DATA_VFSCOROUTINE$2, '$GOLDENS_DATA_VFSCOROUTINE$2', classMeta, CoroutineImpl);
  setMetadataFor(ErrorType, 'ErrorType', classMeta, Enum);
  setMetadataFor(ValidationResult, 'ValidationResult', classMeta);
  setMetadataFor(Errors, 'Errors', classMeta, ValidationResult);
  setMetadataFor(Ok, 'Ok', objectMeta, ValidationResult);
  setMetadataFor(Validator, 'Validator', classMeta);
  setMetadataFor(ValidatorTypes, 'ValidatorTypes', classMeta);
  setMetadataFor(NoError, 'NoError', objectMeta, ValidatorTypes);
  setMetadataFor(CheckEntityBlocksPath, 'CheckEntityBlocksPath', classMeta, ValidatorTypes);
  setMetadataFor(CheckIntersectsBlockingEntities, 'CheckIntersectsBlockingEntities', classMeta, ValidatorTypes);
  setMetadataFor(CheckIsAtMaxSupply, 'CheckIsAtMaxSupply', classMeta, ValidatorTypes);
  setMetadataFor(CheckNotEnoughGold, 'CheckNotEnoughGold', classMeta, ValidatorTypes);
  setMetadataFor(CheckNotEnoughSupply, 'CheckNotEnoughSupply', classMeta, ValidatorTypes);
  setMetadataFor(SellingSupplyDepotLeadsToInsufficientSupply, 'SellingSupplyDepotLeadsToInsufficientSupply', classMeta, ValidatorTypes);
  setMetadataFor(MapVerificationResult, 'MapVerificationResult', classMeta);
  setMetadataFor(Success_2, 'Success', objectMeta, MapVerificationResult);
  setMetadataFor(Failure_0, 'Failure', classMeta, MapVerificationResult);
  setMetadataFor(UIEightDirectionalSprite, 'UIEightDirectionalSprite', classMeta, RectBase);
  setMetadataFor(EightDirection, 'EightDirection', classMeta, Enum);
  setMetadataFor(UIEntity, 'UIEntity', classMeta, Container);
  //endregion
  function AnimationComponent(baseChangeTimeMillis, cumulatedTimeMillisSinceLastFrame) {
    this.qg3_1 = baseChangeTimeMillis;
    this.rg3_1 = cumulatedTimeMillisSinceLastFrame;
  }
  protoOf(AnimationComponent).toString = function () {
    return 'AnimationComponent(baseChangeTimeMillis=' + this.qg3_1 + ', cumulatedTimeMillisSinceLastFrame=' + this.rg3_1 + ')';
  };
  protoOf(AnimationComponent).hashCode = function () {
    var result = getNumberHashCode(this.qg3_1);
    result = imul(result, 31) + getNumberHashCode(this.rg3_1) | 0;
    return result;
  };
  protoOf(AnimationComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AnimationComponent))
      return false;
    var tmp0_other_with_cast = other instanceof AnimationComponent ? other : THROW_CCE();
    if (!equals(this.qg3_1, tmp0_other_with_cast.qg3_1))
      return false;
    if (!equals(this.rg3_1, tmp0_other_with_cast.rg3_1))
      return false;
    return true;
  };
  function BaseDamageComponent(damage) {
    this.sg3_1 = damage;
  }
  protoOf(BaseDamageComponent).toString = function () {
    return 'BaseDamageComponent(damage=' + this.sg3_1 + ')';
  };
  protoOf(BaseDamageComponent).hashCode = function () {
    return getNumberHashCode(this.sg3_1);
  };
  protoOf(BaseDamageComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BaseDamageComponent))
      return false;
    var tmp0_other_with_cast = other instanceof BaseDamageComponent ? other : THROW_CCE();
    if (!equals(this.sg3_1, tmp0_other_with_cast.sg3_1))
      return false;
    return true;
  };
  function BaseWeaponSpeedComponent(millis) {
    this.tg3_1 = millis;
  }
  protoOf(BaseWeaponSpeedComponent).toString = function () {
    return 'BaseWeaponSpeedComponent(millis=' + this.tg3_1 + ')';
  };
  protoOf(BaseWeaponSpeedComponent).hashCode = function () {
    return getNumberHashCode(this.tg3_1);
  };
  protoOf(BaseWeaponSpeedComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BaseWeaponSpeedComponent))
      return false;
    var tmp0_other_with_cast = other instanceof BaseWeaponSpeedComponent ? other : THROW_CCE();
    if (!equals(this.tg3_1, tmp0_other_with_cast.tg3_1))
      return false;
    return true;
  };
  function Companion() {
    Companion_instance = this;
  }
  var Companion_instance;
  function Companion_getInstance_11() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function $serializer() {
    $serializer_instance = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.components.BottomLeftPositionComponent', this, 2);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    this.ug3_1 = tmp0_serialDesc;
  }
  protoOf($serializer).qf5 = function () {
    return this.ug3_1;
  };
  protoOf($serializer).rfg = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer).sf5 = function (decoder) {
    var tmp0_desc = this.ug3_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.zf8(tmp0_desc);
    if (tmp6_input.pf9()) {
      tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp6_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    return BottomLeftPositionComponent_init_$Create$(tmp, tmp_1, tmp_2 == null ? null : tmp_2.vg3_1, null);
  };
  protoOf($serializer).wg3 = function (encoder, value) {
    var tmp0_desc = this.ug3_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.xg3_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.yg3_1));
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer).rf5 = function (encoder, value) {
    return this.wg3(encoder, value instanceof BottomLeftPositionComponent ? value : THROW_CCE());
  };
  var $serializer_instance;
  function $serializer_getInstance() {
    if ($serializer_instance == null)
      new $serializer();
    return $serializer_instance;
  }
  function BottomLeftPositionComponent_init_$Init$(seen1, x, y, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance().ug3_1);
    }
    $this.xg3_1 = x;
    $this.yg3_1 = y;
    return $this;
  }
  function BottomLeftPositionComponent_init_$Create$(seen1, x, y, serializationConstructorMarker) {
    return BottomLeftPositionComponent_init_$Init$(seen1, x, y, serializationConstructorMarker, objectCreate(protoOf(BottomLeftPositionComponent)));
  }
  function BottomLeftPositionComponent(x, y) {
    Companion_getInstance_11();
    this.xg3_1 = x;
    this.yg3_1 = y;
  }
  protoOf(BottomLeftPositionComponent).zg3 = function () {
    return new GameUnitTuple(this.xg3_1, this.yg3_1);
  };
  protoOf(BottomLeftPositionComponent).toString = function () {
    return 'BottomLeftPositionComponent(x=' + new GameUnit(this.xg3_1) + ', y=' + new GameUnit(this.yg3_1) + ')';
  };
  protoOf(BottomLeftPositionComponent).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.xg3_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.yg3_1) | 0;
    return result;
  };
  protoOf(BottomLeftPositionComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof BottomLeftPositionComponent))
      return false;
    var tmp0_other_with_cast = other instanceof BottomLeftPositionComponent ? other : THROW_CCE();
    if (!equals(this.xg3_1, tmp0_other_with_cast.xg3_1))
      return false;
    if (!equals(this.yg3_1, tmp0_other_with_cast.yg3_1))
      return false;
    return true;
  };
  function CollideWithTargetComponent() {
    CollideWithTargetComponent_instance = this;
  }
  var CollideWithTargetComponent_instance;
  function CollideWithTargetComponent_getInstance() {
    if (CollideWithTargetComponent_instance == null)
      new CollideWithTargetComponent();
    return CollideWithTargetComponent_instance;
  }
  function ComputedSpeedEffectComponent(computedSpeedEffect) {
    this.ag4_1 = computedSpeedEffect;
  }
  protoOf(ComputedSpeedEffectComponent).toString = function () {
    return 'ComputedSpeedEffectComponent(computedSpeedEffect=' + this.ag4_1 + ')';
  };
  protoOf(ComputedSpeedEffectComponent).hashCode = function () {
    return getNumberHashCode(this.ag4_1);
  };
  protoOf(ComputedSpeedEffectComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ComputedSpeedEffectComponent))
      return false;
    var tmp0_other_with_cast = other instanceof ComputedSpeedEffectComponent ? other : THROW_CCE();
    if (!equals(this.ag4_1, tmp0_other_with_cast.ag4_1))
      return false;
    return true;
  };
  function DamageMultiplierComponent(multiplier) {
    this.bg4_1 = multiplier;
  }
  protoOf(DamageMultiplierComponent).toString = function () {
    return 'DamageMultiplierComponent(multiplier=' + this.bg4_1 + ')';
  };
  protoOf(DamageMultiplierComponent).hashCode = function () {
    return getNumberHashCode(this.bg4_1);
  };
  protoOf(DamageMultiplierComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DamageMultiplierComponent))
      return false;
    var tmp0_other_with_cast = other instanceof DamageMultiplierComponent ? other : THROW_CCE();
    if (!equals(this.bg4_1, tmp0_other_with_cast.bg4_1))
      return false;
    return true;
  };
  function DamageUpgradeComponent(numUpgrades) {
    this.cg4_1 = numUpgrades;
  }
  function EntityBlockingComponent() {
    EntityBlockingComponent_instance = this;
  }
  var EntityBlockingComponent_instance;
  function EntityBlockingComponent_getInstance() {
    if (EntityBlockingComponent_instance == null)
      new EntityBlockingComponent();
    return EntityBlockingComponent_instance;
  }
  function EntityCheckpointComponent(sequenceNumber) {
    this.dg4_1 = sequenceNumber;
  }
  protoOf(EntityCheckpointComponent).eg4 = function () {
    return this.dg4_1 + 1 | 0;
  };
  protoOf(EntityCheckpointComponent).toString = function () {
    return 'EntityCheckpointComponent(sequenceNumber=' + this.dg4_1 + ')';
  };
  protoOf(EntityCheckpointComponent).hashCode = function () {
    return this.dg4_1;
  };
  protoOf(EntityCheckpointComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityCheckpointComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityCheckpointComponent ? other : THROW_CCE();
    if (!(this.dg4_1 === tmp0_other_with_cast.dg4_1))
      return false;
    return true;
  };
  function EntityCostComponent(cost) {
    this.fg4_1 = cost;
  }
  protoOf(EntityCostComponent).toString = function () {
    return 'EntityCostComponent(cost=' + this.fg4_1 + ')';
  };
  protoOf(EntityCostComponent).hashCode = function () {
    return this.fg4_1;
  };
  protoOf(EntityCostComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityCostComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityCostComponent ? other : THROW_CCE();
    if (!(this.fg4_1 === tmp0_other_with_cast.fg4_1))
      return false;
    return true;
  };
  function EntityFinishComponent() {
    EntityFinishComponent_instance = this;
  }
  var EntityFinishComponent_instance;
  function EntityFinishComponent_getInstance() {
    if (EntityFinishComponent_instance == null)
      new EntityFinishComponent();
    return EntityFinishComponent_instance;
  }
  function EntityRenderComponent(entity) {
    this.gg4_1 = entity;
  }
  protoOf(EntityRenderComponent).toString = function () {
    return 'EntityRenderComponent(entity=' + this.gg4_1 + ')';
  };
  protoOf(EntityRenderComponent).hashCode = function () {
    return hashCode(this.gg4_1);
  };
  protoOf(EntityRenderComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityRenderComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityRenderComponent ? other : THROW_CCE();
    if (!equals(this.gg4_1, tmp0_other_with_cast.gg4_1))
      return false;
    return true;
  };
  function EntityRockComponent() {
    EntityRockComponent_instance = this;
  }
  var EntityRockComponent_instance;
  function EntityRockComponent_getInstance() {
    if (EntityRockComponent_instance == null)
      new EntityRockComponent();
    return EntityRockComponent_instance;
  }
  function EntitySpeedAreaComponent(speedEffect) {
    this.hg4_1 = speedEffect;
  }
  protoOf(EntitySpeedAreaComponent).ig4 = function () {
    return '' + numberToInt(this.hg4_1 * 100) + '%';
  };
  protoOf(EntitySpeedAreaComponent).toString = function () {
    return 'EntitySpeedAreaComponent(speedEffect=' + this.hg4_1 + ')';
  };
  protoOf(EntitySpeedAreaComponent).hashCode = function () {
    return getNumberHashCode(this.hg4_1);
  };
  protoOf(EntitySpeedAreaComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntitySpeedAreaComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntitySpeedAreaComponent ? other : THROW_CCE();
    if (!equals(this.hg4_1, tmp0_other_with_cast.hg4_1))
      return false;
    return true;
  };
  function EntityStartComponent() {
    EntityStartComponent_instance = this;
  }
  var EntityStartComponent_instance;
  function EntityStartComponent_getInstance() {
    if (EntityStartComponent_instance == null)
      new EntityStartComponent();
    return EntityStartComponent_instance;
  }
  function EntitySupplyDepotComponent() {
    EntitySupplyDepotComponent_instance = this;
  }
  var EntitySupplyDepotComponent_instance;
  function EntitySupplyDepotComponent_getInstance() {
    if (EntitySupplyDepotComponent_instance == null)
      new EntitySupplyDepotComponent();
    return EntitySupplyDepotComponent_instance;
  }
  function EntityTeleportInComponent(sequenceNumber) {
    this.jg4_1 = sequenceNumber;
  }
  protoOf(EntityTeleportInComponent).eg4 = function () {
    return this.jg4_1 + 1 | 0;
  };
  protoOf(EntityTeleportInComponent).toString = function () {
    return 'EntityTeleportInComponent(sequenceNumber=' + this.jg4_1 + ')';
  };
  protoOf(EntityTeleportInComponent).hashCode = function () {
    return this.jg4_1;
  };
  protoOf(EntityTeleportInComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityTeleportInComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityTeleportInComponent ? other : THROW_CCE();
    if (!(this.jg4_1 === tmp0_other_with_cast.jg4_1))
      return false;
    return true;
  };
  function EntityTeleportOutComponent(sequenceNumber) {
    this.kg4_1 = sequenceNumber;
  }
  protoOf(EntityTeleportOutComponent).eg4 = function () {
    return this.kg4_1 + 1 | 0;
  };
  protoOf(EntityTeleportOutComponent).toString = function () {
    return 'EntityTeleportOutComponent(sequenceNumber=' + this.kg4_1 + ')';
  };
  protoOf(EntityTeleportOutComponent).hashCode = function () {
    return this.kg4_1;
  };
  protoOf(EntityTeleportOutComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityTeleportOutComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityTeleportOutComponent ? other : THROW_CCE();
    if (!(this.kg4_1 === tmp0_other_with_cast.kg4_1))
      return false;
    return true;
  };
  function EntityTowerComponent() {
    EntityTowerComponent_instance = this;
  }
  var EntityTowerComponent_instance;
  function EntityTowerComponent_getInstance() {
    if (EntityTowerComponent_instance == null)
      new EntityTowerComponent();
    return EntityTowerComponent_instance;
  }
  function EntityTypeComponent(type) {
    this.lg4_1 = type;
  }
  protoOf(EntityTypeComponent).toString = function () {
    return 'EntityTypeComponent(type=' + this.lg4_1 + ')';
  };
  protoOf(EntityTypeComponent).hashCode = function () {
    return this.lg4_1.hashCode();
  };
  protoOf(EntityTypeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityTypeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof EntityTypeComponent ? other : THROW_CCE();
    if (!this.lg4_1.equals(tmp0_other_with_cast.lg4_1))
      return false;
    return true;
  };
  function HealthComponent(health) {
    this.mg4_1 = health;
  }
  protoOf(HealthComponent).toString = function () {
    return 'HealthComponent(health=' + this.mg4_1 + ')';
  };
  protoOf(HealthComponent).hashCode = function () {
    return getNumberHashCode(this.mg4_1);
  };
  protoOf(HealthComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof HealthComponent))
      return false;
    var tmp0_other_with_cast = other instanceof HealthComponent ? other : THROW_CCE();
    if (!equals(this.mg4_1, tmp0_other_with_cast.mg4_1))
      return false;
    return true;
  };
  function MaxHealthComponent(maxHealth) {
    this.ng4_1 = maxHealth;
  }
  protoOf(MaxHealthComponent).toString = function () {
    return 'MaxHealthComponent(maxHealth=' + this.ng4_1 + ')';
  };
  protoOf(MaxHealthComponent).hashCode = function () {
    return getNumberHashCode(this.ng4_1);
  };
  protoOf(MaxHealthComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MaxHealthComponent))
      return false;
    var tmp0_other_with_cast = other instanceof MaxHealthComponent ? other : THROW_CCE();
    if (!equals(this.ng4_1, tmp0_other_with_cast.ng4_1))
      return false;
    return true;
  };
  function MonsterComponent() {
    MonsterComponent_instance = this;
  }
  var MonsterComponent_instance;
  function MonsterComponent_getInstance() {
    if (MonsterComponent_instance == null)
      new MonsterComponent();
    return MonsterComponent_instance;
  }
  function MutableCenterPositionComponent(x, y) {
    this.og4_1 = x;
    this.pg4_1 = y;
  }
  protoOf(MutableCenterPositionComponent).toString = function () {
    return 'MutableCenterPositionComponent(x=' + new GameUnit(this.og4_1) + ', y=' + new GameUnit(this.pg4_1) + ')';
  };
  protoOf(MutableCenterPositionComponent).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.og4_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.pg4_1) | 0;
    return result;
  };
  protoOf(MutableCenterPositionComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MutableCenterPositionComponent))
      return false;
    var tmp0_other_with_cast = other instanceof MutableCenterPositionComponent ? other : THROW_CCE();
    if (!equals(this.og4_1, tmp0_other_with_cast.og4_1))
      return false;
    if (!equals(this.pg4_1, tmp0_other_with_cast.pg4_1))
      return false;
    return true;
  };
  function PathSequenceTraversalComponent(pathSequenceTraversal) {
    this.qg4_1 = pathSequenceTraversal;
  }
  protoOf(PathSequenceTraversalComponent).toString = function () {
    return 'PathSequenceTraversalComponent(pathSequenceTraversal=' + this.qg4_1 + ')';
  };
  protoOf(PathSequenceTraversalComponent).hashCode = function () {
    return hashCode(this.qg4_1);
  };
  protoOf(PathSequenceTraversalComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PathSequenceTraversalComponent))
      return false;
    var tmp0_other_with_cast = other instanceof PathSequenceTraversalComponent ? other : THROW_CCE();
    if (!equals(this.qg4_1, tmp0_other_with_cast.qg4_1))
      return false;
    return true;
  };
  function PreSelectionComponent() {
    PreSelectionComponent_instance = this;
  }
  var PreSelectionComponent_instance;
  function PreSelectionComponent_getInstance() {
    if (PreSelectionComponent_instance == null)
      new PreSelectionComponent();
    return PreSelectionComponent_instance;
  }
  function ProjectileComponent() {
    ProjectileComponent_instance = this;
  }
  var ProjectileComponent_instance;
  function ProjectileComponent_getInstance() {
    if (ProjectileComponent_instance == null)
      new ProjectileComponent();
    return ProjectileComponent_instance;
  }
  function ProjectileDamageComponent(damage) {
    this.rg4_1 = damage;
  }
  protoOf(ProjectileDamageComponent).toString = function () {
    return 'ProjectileDamageComponent(damage=' + this.rg4_1 + ')';
  };
  protoOf(ProjectileDamageComponent).hashCode = function () {
    return getNumberHashCode(this.rg4_1);
  };
  protoOf(ProjectileDamageComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ProjectileDamageComponent))
      return false;
    var tmp0_other_with_cast = other instanceof ProjectileDamageComponent ? other : THROW_CCE();
    if (!equals(this.rg4_1, tmp0_other_with_cast.rg4_1))
      return false;
    return true;
  };
  function RangeComponent(range) {
    this.sg4_1 = range;
  }
  protoOf(RangeComponent).toString = function () {
    return 'RangeComponent(range=' + new GameUnit(this.sg4_1) + ')';
  };
  protoOf(RangeComponent).hashCode = function () {
    return GameUnit__hashCode_impl_playmc(this.sg4_1);
  };
  protoOf(RangeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RangeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof RangeComponent ? other : THROW_CCE();
    if (!equals(this.sg4_1, tmp0_other_with_cast.sg4_1))
      return false;
    return true;
  };
  function ReadyToAttackComponent() {
    ReadyToAttackComponent_instance = this;
  }
  var ReadyToAttackComponent_instance;
  function ReadyToAttackComponent_getInstance() {
    if (ReadyToAttackComponent_instance == null)
      new ReadyToAttackComponent();
    return ReadyToAttackComponent_instance;
  }
  function ReloadDowntimeComponent(currentDowntimeMillis) {
    this.tg4_1 = currentDowntimeMillis;
  }
  protoOf(ReloadDowntimeComponent).toString = function () {
    return 'ReloadDowntimeComponent(currentDowntimeMillis=' + this.tg4_1 + ')';
  };
  protoOf(ReloadDowntimeComponent).hashCode = function () {
    return getNumberHashCode(this.tg4_1);
  };
  protoOf(ReloadDowntimeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ReloadDowntimeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof ReloadDowntimeComponent ? other : THROW_CCE();
    if (!equals(this.tg4_1, tmp0_other_with_cast.tg4_1))
      return false;
    return true;
  };
  function SelectableComponent() {
    SelectableComponent_instance = this;
  }
  var SelectableComponent_instance;
  function SelectableComponent_getInstance() {
    if (SelectableComponent_instance == null)
      new SelectableComponent();
    return SelectableComponent_instance;
  }
  function SelectedComponent() {
    SelectedComponent_instance = this;
  }
  var SelectedComponent_instance;
  function SelectedComponent_getInstance() {
    if (SelectedComponent_instance == null)
      new SelectedComponent();
    return SelectedComponent_instance;
  }
  function Companion_0() {
    Companion_instance_0 = this;
    this.ug4_1 = new SizeComponent(toGameUnit(2), toGameUnit(2));
  }
  var Companion_instance_0;
  function Companion_getInstance_12() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function $serializer_0() {
    $serializer_instance_0 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.components.SizeComponent', this, 2);
    tmp0_serialDesc.cfg('width', false);
    tmp0_serialDesc.cfg('height', false);
    this.vg4_1 = tmp0_serialDesc;
  }
  protoOf($serializer_0).qf5 = function () {
    return this.vg4_1;
  };
  protoOf($serializer_0).rfg = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_0).sf5 = function (decoder) {
    var tmp0_desc = this.vg4_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.zf8(tmp0_desc);
    if (tmp6_input.pf9()) {
      tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp6_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    return SizeComponent_init_$Create$(tmp, tmp_1, tmp_2 == null ? null : tmp_2.vg3_1, null);
  };
  protoOf($serializer_0).wg4 = function (encoder, value) {
    var tmp0_desc = this.vg4_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.xg4_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.yg4_1));
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_0).rf5 = function (encoder, value) {
    return this.wg4(encoder, value instanceof SizeComponent ? value : THROW_CCE());
  };
  var $serializer_instance_0;
  function $serializer_getInstance_0() {
    if ($serializer_instance_0 == null)
      new $serializer_0();
    return $serializer_instance_0;
  }
  function SizeComponent_init_$Init$(seen1, width, height, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_0().vg4_1);
    }
    $this.xg4_1 = width;
    $this.yg4_1 = height;
    return $this;
  }
  function SizeComponent_init_$Create$(seen1, width, height, serializationConstructorMarker) {
    return SizeComponent_init_$Init$(seen1, width, height, serializationConstructorMarker, objectCreate(protoOf(SizeComponent)));
  }
  function SizeComponent(width, height) {
    Companion_getInstance_12();
    this.xg4_1 = width;
    this.yg4_1 = height;
  }
  protoOf(SizeComponent).zg4 = function () {
    // Inline function 'kotlin.require' call
    var tmp0_require = equals(this.xg4_1, this.yg4_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return GameUnit__div_impl_u8qjua(this.xg4_1, 2.0);
  };
  protoOf(SizeComponent).toString = function () {
    return 'SizeComponent(width=' + new GameUnit(this.xg4_1) + ', height=' + new GameUnit(this.yg4_1) + ')';
  };
  protoOf(SizeComponent).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.xg4_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.yg4_1) | 0;
    return result;
  };
  protoOf(SizeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SizeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof SizeComponent ? other : THROW_CCE();
    if (!equals(this.xg4_1, tmp0_other_with_cast.xg4_1))
      return false;
    if (!equals(this.yg4_1, tmp0_other_with_cast.yg4_1))
      return false;
    return true;
  };
  function SpeedUpgradeComponent(numUpgrades) {
    this.ag5_1 = numUpgrades;
  }
  protoOf(SpeedUpgradeComponent).toString = function () {
    return 'SpeedUpgradeComponent(numUpgrades=' + this.ag5_1 + ')';
  };
  protoOf(SpeedUpgradeComponent).hashCode = function () {
    return this.ag5_1;
  };
  protoOf(SpeedUpgradeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SpeedUpgradeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof SpeedUpgradeComponent ? other : THROW_CCE();
    if (!(this.ag5_1 === tmp0_other_with_cast.ag5_1))
      return false;
    return true;
  };
  function SupplyCostComponent(cost) {
    this.bg5_1 = cost;
  }
  protoOf(SupplyCostComponent).toString = function () {
    return 'SupplyCostComponent(cost=' + this.bg5_1 + ')';
  };
  protoOf(SupplyCostComponent).hashCode = function () {
    return this.bg5_1;
  };
  protoOf(SupplyCostComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SupplyCostComponent))
      return false;
    var tmp0_other_with_cast = other instanceof SupplyCostComponent ? other : THROW_CCE();
    if (!(this.bg5_1 === tmp0_other_with_cast.bg5_1))
      return false;
    return true;
  };
  function TargetingComponent(targetEntityId) {
    this.cg5_1 = targetEntityId;
  }
  function UIEightDirectionalSpriteComponent(uiEightDirectionalSprite) {
    this.dg5_1 = uiEightDirectionalSprite;
  }
  protoOf(UIEightDirectionalSpriteComponent).toString = function () {
    return 'UIEightDirectionalSpriteComponent(uiEightDirectionalSprite=' + this.dg5_1 + ')';
  };
  protoOf(UIEightDirectionalSpriteComponent).hashCode = function () {
    return hashCode(this.dg5_1);
  };
  protoOf(UIEightDirectionalSpriteComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIEightDirectionalSpriteComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIEightDirectionalSpriteComponent ? other : THROW_CCE();
    if (!equals(this.dg5_1, tmp0_other_with_cast.dg5_1))
      return false;
    return true;
  };
  function UIEntityContainerComponent(container) {
    this.eg5_1 = container;
  }
  protoOf(UIEntityContainerComponent).toString = function () {
    return 'UIEntityContainerComponent(container=' + this.eg5_1 + ')';
  };
  protoOf(UIEntityContainerComponent).hashCode = function () {
    return hashCode(this.eg5_1);
  };
  protoOf(UIEntityContainerComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIEntityContainerComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIEntityContainerComponent ? other : THROW_CCE();
    if (!equals(this.eg5_1, tmp0_other_with_cast.eg5_1))
      return false;
    return true;
  };
  function UIEntityViewComponent(entityView) {
    this.fg5_1 = entityView;
  }
  protoOf(UIEntityViewComponent).toString = function () {
    return 'UIEntityViewComponent(entityView=' + this.fg5_1 + ')';
  };
  protoOf(UIEntityViewComponent).hashCode = function () {
    return hashCode(this.fg5_1);
  };
  protoOf(UIEntityViewComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIEntityViewComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIEntityViewComponent ? other : THROW_CCE();
    if (!equals(this.fg5_1, tmp0_other_with_cast.fg5_1))
      return false;
    return true;
  };
  function UIGunBarrelComponent(view) {
    this.gg5_1 = view;
  }
  protoOf(UIGunBarrelComponent).toString = function () {
    return 'UIGunBarrelComponent(view=' + this.gg5_1 + ')';
  };
  protoOf(UIGunBarrelComponent).hashCode = function () {
    return hashCode(this.gg5_1);
  };
  protoOf(UIGunBarrelComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIGunBarrelComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIGunBarrelComponent ? other : THROW_CCE();
    if (!equals(this.gg5_1, tmp0_other_with_cast.gg5_1))
      return false;
    return true;
  };
  function UIHealthBarComponent(view) {
    this.hg5_1 = view;
  }
  protoOf(UIHealthBarComponent).toString = function () {
    return 'UIHealthBarComponent(view=' + this.hg5_1 + ')';
  };
  protoOf(UIHealthBarComponent).hashCode = function () {
    return hashCode(this.hg5_1);
  };
  protoOf(UIHealthBarComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIHealthBarComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIHealthBarComponent ? other : THROW_CCE();
    if (!equals(this.hg5_1, tmp0_other_with_cast.hg5_1))
      return false;
    return true;
  };
  function UIMapEntityComponent(uiEntity) {
    this.ig5_1 = uiEntity;
  }
  protoOf(UIMapEntityComponent).toString = function () {
    return 'UIMapEntityComponent(uiEntity=' + this.ig5_1 + ')';
  };
  protoOf(UIMapEntityComponent).hashCode = function () {
    return hashCode(this.ig5_1);
  };
  protoOf(UIMapEntityComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIMapEntityComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIMapEntityComponent ? other : THROW_CCE();
    if (!equals(this.ig5_1, tmp0_other_with_cast.ig5_1))
      return false;
    return true;
  };
  function UIMapEntityTextComponent(textView) {
    this.jg5_1 = textView;
  }
  protoOf(UIMapEntityTextComponent).toString = function () {
    return 'UIMapEntityTextComponent(textView=' + this.jg5_1 + ')';
  };
  protoOf(UIMapEntityTextComponent).hashCode = function () {
    return hashCode(this.jg5_1);
  };
  protoOf(UIMapEntityTextComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIMapEntityTextComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIMapEntityTextComponent ? other : THROW_CCE();
    if (!equals(this.jg5_1, tmp0_other_with_cast.jg5_1))
      return false;
    return true;
  };
  function UISelectionComponent(graphics) {
    this.kg5_1 = graphics;
  }
  protoOf(UISelectionComponent).toString = function () {
    return 'UISelectionComponent(graphics=' + this.kg5_1 + ')';
  };
  protoOf(UISelectionComponent).hashCode = function () {
    return hashCode(this.kg5_1);
  };
  protoOf(UISelectionComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UISelectionComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UISelectionComponent ? other : THROW_CCE();
    if (!equals(this.kg5_1, tmp0_other_with_cast.kg5_1))
      return false;
    return true;
  };
  function VelocityComponent(velocity) {
    velocity = velocity === VOID ? toGameUnit(5) : velocity;
    this.lg5_1 = velocity;
  }
  protoOf(VelocityComponent).mg5 = function (duration) {
    var tmp$ret$0;
    // Inline function 'kotlin.Long.div' call
    var tmp0_div = _Duration___get_inWholeMicroseconds__impl__8oe8vv(duration);
    tmp$ret$0 = tmp0_div.wi() / 1000000.0;
    var secs = tmp$ret$0;
    return _GameUnit___init__impl__teirvv(secs * _GameUnit___get_value__impl__9ocgox(this.lg5_1));
  };
  protoOf(VelocityComponent).toString = function () {
    return 'VelocityComponent(velocity=' + new GameUnit(this.lg5_1) + ')';
  };
  protoOf(VelocityComponent).hashCode = function () {
    return GameUnit__hashCode_impl_playmc(this.lg5_1);
  };
  protoOf(VelocityComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof VelocityComponent))
      return false;
    var tmp0_other_with_cast = other instanceof VelocityComponent ? other : THROW_CCE();
    if (!equals(this.lg5_1, tmp0_other_with_cast.lg5_1))
      return false;
    return true;
  };
  function Companion_1() {
    Companion_instance_1 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [new LinkedHashMapSerializer(IntSerializer_getInstance(), new LinkedHashMapSerializer(IntSerializer_getInstance(), IntSerializer_getInstance())), null, new LinkedHashMapSerializer(IntSerializer_getInstance(), new LinkedHashMapSerializer(IntSerializer_getInstance(), IntSerializer_getInstance()))];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.ng5_1 = tmp$ret$2;
  }
  var Companion_instance_1;
  function Companion_getInstance_13() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function $serializer_1() {
    $serializer_instance_1 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.containers.BlockingPointContainer.Mutable', this, 3);
    tmp0_serialDesc.cfg('pointToCounter', false);
    tmp0_serialDesc.cfg('size', true);
    tmp0_serialDesc.cfg('mutablePointToCounter', true);
    this.og5_1 = tmp0_serialDesc;
  }
  protoOf($serializer_1).qf5 = function () {
    return this.og5_1;
  };
  protoOf($serializer_1).rfg = function () {
    var tmp0_cached = Companion_getInstance_13().ng5_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [tmp0_cached[0], IntSerializer_getInstance(), tmp0_cached[2]];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_1).sf5 = function (decoder) {
    var tmp0_desc = this.og5_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = 0;
    var tmp6_local2 = null;
    var tmp7_input = decoder.zf8(tmp0_desc);
    var tmp8_cached = Companion_getInstance_13().ng5_1;
    if (tmp7_input.pf9()) {
      tmp4_local0 = tmp7_input.lf9(tmp0_desc, 0, tmp8_cached[0], tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp7_input.ef9(tmp0_desc, 1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp7_input.lf9(tmp0_desc, 2, tmp8_cached[2], tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp7_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp7_input.lf9(tmp0_desc, 0, tmp8_cached[0], tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp7_input.ef9(tmp0_desc, 1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp7_input.lf9(tmp0_desc, 2, tmp8_cached[2], tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp7_input.af9(tmp0_desc);
    return Mutable_init_$Create$(tmp3_bitMask0, tmp4_local0, tmp5_local1, tmp6_local2, null);
  };
  protoOf($serializer_1).pg5 = function (encoder, value) {
    var tmp0_desc = this.og5_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_13().ng5_1;
    tmp1_output.qfa(tmp0_desc, 0, tmp2_cached[0], value.qg5_1);
    if (tmp1_output.wfa(tmp0_desc, 1) ? true : !(value.rg5_1 === 0)) {
      tmp1_output.jfa(tmp0_desc, 1, value.rg5_1);
    }
    var tmp;
    if (tmp1_output.wfa(tmp0_desc, 2)) {
      tmp = true;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp = !equals(value.ug5_1, tmp$ret$0);
    }
    if (tmp) {
      tmp1_output.qfa(tmp0_desc, 2, tmp2_cached[2], value.ug5_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_1).rf5 = function (encoder, value) {
    return this.pg5(encoder, value instanceof Mutable ? value : THROW_CCE());
  };
  var $serializer_instance_1;
  function $serializer_getInstance_1() {
    if ($serializer_instance_1 == null)
      new $serializer_1();
    return $serializer_instance_1;
  }
  function Mutable_init_$Init$(seen1, pointToCounter, size, mutablePointToCounter, serializationConstructorMarker, $this) {
    if (!(1 === (1 & seen1))) {
      throwMissingFieldException(seen1, 1, $serializer_getInstance_1().og5_1);
    }
    BlockingPointContainer_init_$Init$(seen1, pointToCounter, size, serializationConstructorMarker, $this);
    if (0 === (seen1 & 4)) {
      var tmp = $this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp.ug5_1 = tmp$ret$0;
    } else
      $this.ug5_1 = mutablePointToCounter;
    return $this;
  }
  function Mutable_init_$Create$(seen1, pointToCounter, size, mutablePointToCounter, serializationConstructorMarker) {
    return Mutable_init_$Init$(seen1, pointToCounter, size, mutablePointToCounter, serializationConstructorMarker, objectCreate(protoOf(Mutable)));
  }
  function Companion_2() {
    Companion_instance_2 = this;
    this.vg5_1 = new View();
  }
  protoOf(Companion_2).wg5 = function (entities) {
    var blockingPoints = new Mutable();
    var tmp0_iterator = entities.h();
    while (tmp0_iterator.j()) {
      var entity = tmp0_iterator.k();
      blockingPoints.xg5(entity);
    }
    return blockingPoints.yg5();
  };
  var Companion_instance_2;
  function Companion_getInstance_14() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function _get_$cachedSerializer__te6jhj($this) {
    return $this.ag6_1.f1();
  }
  function BlockingPointContainer$Companion$_anonymous__8sv7fb() {
    var tmp = getKClass(BlockingPointContainer);
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [getKClass(Mutable)];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp_0 = tmp$ret$2;
    var tmp$ret$5;
    // Inline function 'kotlin.arrayOf' call
    var tmp1_arrayOf = [$serializer_getInstance_1()];
    var tmp$ret$4;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp1_arrayOf;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
    var tmp_1 = tmp$ret$5;
    var tmp$ret$8;
    // Inline function 'kotlin.arrayOf' call
    var tmp$ret$7;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$6;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$6 = [];
    tmp$ret$7 = tmp$ret$6;
    tmp$ret$8 = tmp$ret$7;
    return SealedClassSerializer_init_$Create$('com.xenotactic.gamelogic.containers.BlockingPointContainer', tmp, tmp_0, tmp_1, tmp$ret$8);
  }
  function Mutable(mutablePointToCounter) {
    Companion_getInstance_13();
    var tmp;
    if (mutablePointToCounter === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = mutablePointToCounter;
    }
    mutablePointToCounter = tmp;
    BlockingPointContainer.call(this, mutablePointToCounter);
    this.ug5_1 = mutablePointToCounter;
  }
  protoOf(Mutable).xg5 = function (entity) {
    this.cg6(entity.bg6());
  };
  protoOf(Mutable).u4i = function (x, y) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.ug5_1;
    var value = tmp0_getOrPut.h3(x);
    var tmp;
    if (value == null) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.containers.Mutable.add.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$1 = tmp$ret$0;
      var answer = tmp$ret$1;
      tmp0_getOrPut.e5(x, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$2 = tmp;
    var yMap = tmp$ret$2;
    // Inline function 'kotlin.collections.set' call
    var tmp$ret$4;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_elvis_lhs = yMap.h3(y);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$3;
      // Inline function 'com.xenotactic.gamelogic.containers.Mutable.add.<anonymous>' call
      tmp$ret$3 = 0;
      tmp_0 = tmp$ret$3;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    tmp$ret$4 = tmp_0;
    var tmp1_set = tmp$ret$4 + 1 | 0;
    yMap.e5(y, tmp1_set);
    var tmp0_this = this;
    var tmp1 = tmp0_this.rg5_1;
    tmp0_this.rg5_1 = tmp1 + 1 | 0;
  };
  protoOf(Mutable).cg6 = function (gameUnitPoints) {
    var tmp0_iterator = gameUnitPoints.h();
    while (tmp0_iterator.j()) {
      var point = tmp0_iterator.k();
      this.u4i(GameUnit__toInt_impl_9qttgv(point.dg6_1), GameUnit__toInt_impl_9qttgv(point.eg6_1));
    }
  };
  protoOf(Mutable).fg6 = function (x, y) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.ug5_1;
    var value = tmp0_getOrPut.h3(x);
    var tmp;
    if (value == null) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.containers.Mutable.remove.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$1 = tmp$ret$0;
      var answer = tmp$ret$1;
      tmp0_getOrPut.e5(x, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$2 = tmp;
    var yMap = tmp$ret$2;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.getOrPut' call
    var value_0 = yMap.h3(y);
    var tmp_0;
    if (value_0 == null) {
      var tmp$ret$3;
      // Inline function 'com.xenotactic.gamelogic.containers.Mutable.remove.<anonymous>' call
      tmp$ret$3 = 0;
      var answer_0 = tmp$ret$3;
      yMap.e5(y, answer_0);
      tmp_0 = answer_0;
    } else {
      tmp_0 = value_0;
    }
    tmp$ret$4 = tmp_0;
    var newValue = tmp$ret$4 - 1 | 0;
    if (newValue >= 0) {
      // Inline function 'kotlin.collections.set' call
      yMap.e5(y, newValue);
      var tmp0_this = this;
      var tmp1 = tmp0_this.rg5_1;
      tmp0_this.rg5_1 = tmp1 - 1 | 0;
    }
  };
  protoOf(Mutable).gg6 = function (gameUnitPoints) {
    var tmp0_iterator = gameUnitPoints.h();
    while (tmp0_iterator.j()) {
      var point = tmp0_iterator.k();
      this.fg6(GameUnit__toInt_impl_9qttgv(point.dg6_1), GameUnit__toInt_impl_9qttgv(point.eg6_1));
    }
  };
  protoOf(Mutable).yg5 = function () {
    return new View(this.ug5_1);
  };
  function View(mutablePointToCounter) {
    Companion_getInstance_14();
    var tmp;
    if (mutablePointToCounter === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = mutablePointToCounter;
    }
    mutablePointToCounter = tmp;
    BlockingPointContainer.call(this, mutablePointToCounter);
  }
  function Companion_3() {
    Companion_instance_3 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [new LinkedHashMapSerializer(IntSerializer_getInstance(), new LinkedHashMapSerializer(IntSerializer_getInstance(), IntSerializer_getInstance())), null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.zg5_1 = tmp$ret$2;
    var tmp_0 = this;
    var tmp_1 = LazyThreadSafetyMode_PUBLICATION_getInstance();
    tmp_0.ag6_1 = lazy(tmp_1, BlockingPointContainer$Companion$_anonymous__8sv7fb);
  }
  protoOf(Companion_3).sfr = function () {
    return _get_$cachedSerializer__te6jhj(this);
  };
  protoOf(Companion_3).cfh = function (typeParamsSerializers) {
    return this.sfr();
  };
  var Companion_instance_3;
  function Companion_getInstance_15() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function BlockingPointContainer_init_$Init$(seen1, pointToCounter, size, serializationConstructorMarker, $this) {
    $this.qg5_1 = pointToCounter;
    if (0 === (seen1 & 2))
      $this.rg5_1 = 0;
    else
      $this.rg5_1 = size;
    return $this;
  }
  function BlockingPointContainer(pointToCounter) {
    Companion_getInstance_15();
    this.qg5_1 = pointToCounter;
    this.rg5_1 = 0;
  }
  protoOf(BlockingPointContainer).hg6 = function (x, y) {
    return this.ig6(GameUnit__toInt_impl_9qttgv(x), GameUnit__toInt_impl_9qttgv(y));
  };
  protoOf(BlockingPointContainer).ig6 = function (x, y) {
    var tmp;
    if (this.qg5_1.b3(x)) {
      var tmp$ret$1;
      // Inline function 'kotlin.collections.getOrElse' call
      var tmp0_getOrElse = ensureNotNull(this.qg5_1.h3(x));
      var tmp0_elvis_lhs = tmp0_getOrElse.h3(y);
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.containers.BlockingPointContainer.contains.<anonymous>' call
        tmp$ret$0 = 0;
        tmp_0 = tmp$ret$0;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      tmp$ret$1 = tmp_0;
      tmp = tmp$ret$1 > 0;
    } else {
      tmp = false;
    }
    return tmp;
  };
  function AddedEntityEvent(entityId) {
    this.jg6_1 = entityId;
  }
  protoOf(AddedEntityEvent).toString = function () {
    return 'AddedEntityEvent(entityId=' + new EntityId(this.jg6_1) + ')';
  };
  protoOf(AddedEntityEvent).hashCode = function () {
    return EntityId__hashCode_impl_1s8fhw(this.jg6_1);
  };
  protoOf(AddedEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AddedEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof AddedEntityEvent ? other : THROW_CCE();
    if (!(this.jg6_1 === tmp0_other_with_cast.jg6_1))
      return false;
    return true;
  };
  function AddedMonsterEntityEvent(entityId) {
    this.kg6_1 = entityId;
  }
  protoOf(AddedMonsterEntityEvent).toString = function () {
    return 'AddedMonsterEntityEvent(entityId=' + new EntityId(this.kg6_1) + ')';
  };
  protoOf(AddedMonsterEntityEvent).hashCode = function () {
    return EntityId__hashCode_impl_1s8fhw(this.kg6_1);
  };
  protoOf(AddedMonsterEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AddedMonsterEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof AddedMonsterEntityEvent ? other : THROW_CCE();
    if (!(this.kg6_1 === tmp0_other_with_cast.kg6_1))
      return false;
    return true;
  };
  function Companion_4() {
    Companion_instance_4 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance();
    var tmp0_elvis_lhs = getKClass(EventBus).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.lg6_1 = tmp$ret$0;
  }
  var Companion_instance_4;
  function Companion_getInstance_16() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function EventBus$send$slambda(this$0, $message, resultContinuation) {
    this.ug6_1 = this$0;
    this.vg6_1 = $message;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(EventBus$send$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(EventBus$send$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(EventBus$send$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ug6_1.xg6_1.ag7(this.vg6_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(EventBus$send$slambda).g1p = function (completion) {
    var i = new EventBus$send$slambda(this.ug6_1, this.vg6_1, completion);
    return i;
  };
  function EventBus$send$slambda_0(this$0, $message, resultContinuation) {
    var i = new EventBus$send$slambda(this$0, $message, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function EventBus(scope) {
    Companion_getInstance_16();
    this.wg6_1 = scope;
    this.xg6_1 = new GlobalBus(this.wg6_1.to());
  }
  protoOf(EventBus).bg7 = function (message) {
    launchImmediately(this.wg6_1, EventBus$send$slambda_0(this, message, null));
  };
  protoOf(EventBus).cg7 = function (clazz, handler) {
    return this.xg6_1.cg7(clazz, handler);
  };
  function forClass($this, clazz) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = $this.zg6_1;
    var value = tmp0_getOrPut.h3(clazz);
    var tmp;
    if (value == null) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.events.GlobalBus.forClass.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$1 = tmp$ret$0;
      var answer = tmp$ret$1;
      tmp0_getOrPut.e5(clazz, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$2 = tmp;
    return tmp$ret$2;
  }
  function GlobalBus$register$lambda(this$0, $clazz, $chandler) {
    return function () {
      this$0.dg7($clazz, $chandler);
      return Unit_getInstance();
    };
  }
  function $sendCOROUTINE$0(_this__u8e3s4, message, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.mg7_1 = _this__u8e3s4;
    this.ng7_1 = message;
  }
  protoOf($sendCOROUTINE$0).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.og7_1 = getKClassFromExpression(this.ng7_1);
            this.pg7_1 = this.mg7_1.zg6_1.h3(this.og7_1);
            if (this.pg7_1 == null) {
              this.qg7_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              this.rg7_1 = 0;
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            if (!(this.rg7_1 < this.pg7_1.l())) {
              this.y7_1 = 3;
              continue $sm;
            }

            var tmp_0 = this;
            var tmp0 = this.rg7_1;
            this.rg7_1 = tmp0 + 1 | 0;
            tmp_0.sg7_1 = this.pg7_1.n(tmp0);
            this.y7_1 = 2;
            suspendResult = this.sg7_1(this.ng7_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            this.qg7_1 = Unit_getInstance();
            this.y7_1 = 4;
            continue $sm;
          case 4:
            ;
            return Unit_getInstance();
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function GlobalBus(coroutineContext) {
    this.yg6_1 = coroutineContext;
    this.zg6_1 = HashMap_init_$Create$();
  }
  protoOf(GlobalBus).ag7 = function (message, $completion) {
    var tmp = new $sendCOROUTINE$0(this, message, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GlobalBus).cg7 = function (clazz, handler) {
    var chandler = isSuspendFunction(handler, 1) ? handler : THROW_CCE();
    forClass(this, clazz).b(chandler);
    var tmp = Companion_getInstance_0();
    return tmp.a2r(GlobalBus$register$lambda(this, clazz, chandler));
  };
  protoOf(GlobalBus).dg7 = function (clazz, handler) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.remove' call
    var tmp0_remove = forClass(this, clazz);
    tmp$ret$0 = (isInterface(tmp0_remove, MutableCollection) ? tmp0_remove : THROW_CCE()).b4(handler);
  };
  function GoldStateUpdated(previous, current) {
    this.tg7_1 = previous;
    this.ug7_1 = current;
  }
  protoOf(GoldStateUpdated).toString = function () {
    return 'GoldStateUpdated(previous=' + this.tg7_1 + ', current=' + this.ug7_1 + ')';
  };
  protoOf(GoldStateUpdated).hashCode = function () {
    var result = this.tg7_1;
    result = imul(result, 31) + this.ug7_1 | 0;
    return result;
  };
  protoOf(GoldStateUpdated).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GoldStateUpdated))
      return false;
    var tmp0_other_with_cast = other instanceof GoldStateUpdated ? other : THROW_CCE();
    if (!(this.tg7_1 === tmp0_other_with_cast.tg7_1))
      return false;
    if (!(this.ug7_1 === tmp0_other_with_cast.ug7_1))
      return false;
    return true;
  };
  function InformErrorMessageEvent(errorMsg) {
    this.vg7_1 = errorMsg;
  }
  protoOf(InformErrorMessageEvent).toString = function () {
    return 'InformErrorMessageEvent(errorMsg=' + this.vg7_1 + ')';
  };
  protoOf(InformErrorMessageEvent).hashCode = function () {
    return getStringHashCode(this.vg7_1);
  };
  protoOf(InformErrorMessageEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof InformErrorMessageEvent))
      return false;
    var tmp0_other_with_cast = other instanceof InformErrorMessageEvent ? other : THROW_CCE();
    if (!(this.vg7_1 === tmp0_other_with_cast.vg7_1))
      return false;
    return true;
  };
  function MonsterDeathsEvent(deaths) {
    this.wg7_1 = deaths;
  }
  protoOf(MonsterDeathsEvent).toString = function () {
    return 'MonsterDeathsEvent(deaths=' + this.wg7_1 + ')';
  };
  protoOf(MonsterDeathsEvent).hashCode = function () {
    return this.wg7_1;
  };
  protoOf(MonsterDeathsEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MonsterDeathsEvent))
      return false;
    var tmp0_other_with_cast = other instanceof MonsterDeathsEvent ? other : THROW_CCE();
    if (!(this.wg7_1 === tmp0_other_with_cast.wg7_1))
      return false;
    return true;
  };
  function RemovedSupplyDepotEntityEvent(entityId) {
    this.xg7_1 = entityId;
  }
  protoOf(RemovedSupplyDepotEntityEvent).toString = function () {
    return 'RemovedSupplyDepotEntityEvent(entityId=' + new EntityId(this.xg7_1) + ')';
  };
  protoOf(RemovedSupplyDepotEntityEvent).hashCode = function () {
    return EntityId__hashCode_impl_1s8fhw(this.xg7_1);
  };
  protoOf(RemovedSupplyDepotEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemovedSupplyDepotEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof RemovedSupplyDepotEntityEvent ? other : THROW_CCE();
    if (!(this.xg7_1 === tmp0_other_with_cast.xg7_1))
      return false;
    return true;
  };
  function RemovedTowerEntityEvent(entityId) {
    this.yg7_1 = entityId;
  }
  protoOf(RemovedTowerEntityEvent).toString = function () {
    return 'RemovedTowerEntityEvent(entityId=' + new EntityId(this.yg7_1) + ')';
  };
  protoOf(RemovedTowerEntityEvent).hashCode = function () {
    return EntityId__hashCode_impl_1s8fhw(this.yg7_1);
  };
  protoOf(RemovedTowerEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemovedTowerEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof RemovedTowerEntityEvent ? other : THROW_CCE();
    if (!(this.yg7_1 === tmp0_other_with_cast.yg7_1))
      return false;
    return true;
  };
  function ResizeMapEvent(oldMapWidth, oldMapHeight, newMapWidth, newMapHeight) {
    this.zg7_1 = oldMapWidth;
    this.ag8_1 = oldMapHeight;
    this.bg8_1 = newMapWidth;
    this.cg8_1 = newMapHeight;
  }
  protoOf(ResizeMapEvent).toString = function () {
    return 'ResizeMapEvent(oldMapWidth=' + new GameUnit(this.zg7_1) + ', oldMapHeight=' + new GameUnit(this.ag8_1) + ', newMapWidth=' + new GameUnit(this.bg8_1) + ', newMapHeight=' + new GameUnit(this.cg8_1) + ')';
  };
  protoOf(ResizeMapEvent).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.zg7_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ag8_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.bg8_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.cg8_1) | 0;
    return result;
  };
  protoOf(ResizeMapEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ResizeMapEvent))
      return false;
    var tmp0_other_with_cast = other instanceof ResizeMapEvent ? other : THROW_CCE();
    if (!equals(this.zg7_1, tmp0_other_with_cast.zg7_1))
      return false;
    if (!equals(this.ag8_1, tmp0_other_with_cast.ag8_1))
      return false;
    if (!equals(this.bg8_1, tmp0_other_with_cast.bg8_1))
      return false;
    if (!equals(this.cg8_1, tmp0_other_with_cast.cg8_1))
      return false;
    return true;
  };
  function UpdatedPathLineEvent(pathSequence, newPathLength) {
    this.dg8_1 = pathSequence;
    this.eg8_1 = newPathLength;
  }
  protoOf(UpdatedPathLineEvent).toString = function () {
    var tmp = this.eg8_1;
    return 'UpdatedPathLineEvent(pathSequence=' + this.dg8_1 + ', newPathLength=' + (tmp == null ? null : new GameUnit(tmp)) + ')';
  };
  protoOf(UpdatedPathLineEvent).hashCode = function () {
    var result = this.dg8_1 == null ? 0 : this.dg8_1.hashCode();
    var tmp = imul(result, 31);
    var tmp_0;
    var tmp_1 = this.eg8_1;
    if ((tmp_1 == null ? null : new GameUnit(tmp_1)) == null) {
      tmp_0 = 0;
    } else {
      tmp_0 = GameUnit__hashCode_impl_playmc(this.eg8_1);
    }
    result = tmp + tmp_0 | 0;
    return result;
  };
  protoOf(UpdatedPathLineEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpdatedPathLineEvent))
      return false;
    var tmp0_other_with_cast = other instanceof UpdatedPathLineEvent ? other : THROW_CCE();
    if (!equals(this.dg8_1, tmp0_other_with_cast.dg8_1))
      return false;
    var tmp = this.eg8_1;
    var tmp_0 = tmp == null ? null : new GameUnit(tmp);
    var tmp_1 = tmp0_other_with_cast.eg8_1;
    if (!equals(tmp_0, tmp_1 == null ? null : new GameUnit(tmp_1)))
      return false;
    return true;
  };
  function Companion_5() {
    Companion_instance_5 = this;
  }
  var Companion_instance_5;
  function Companion_getInstance_17() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function GRectInt(x, y, width, height) {
    Companion_getInstance_17();
    this.fg8_1 = x;
    this.gg8_1 = y;
    this.hg8_1 = width;
    this.ig8_1 = height;
  }
  protoOf(GRectInt).jg8 = function () {
    return this.fg8_1;
  };
  protoOf(GRectInt).kg8 = function () {
    return this.gg8_1;
  };
  protoOf(GRectInt).lg8 = function () {
    return this.hg8_1;
  };
  protoOf(GRectInt).mg8 = function () {
    return this.ig8_1;
  };
  protoOf(GRectInt).toString = function () {
    return 'GRectInt(x=' + new GameUnit(this.fg8_1) + ', y=' + new GameUnit(this.gg8_1) + ', width=' + new GameUnit(this.hg8_1) + ', height=' + new GameUnit(this.ig8_1) + ')';
  };
  protoOf(GRectInt).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.fg8_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.gg8_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.hg8_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ig8_1) | 0;
    return result;
  };
  protoOf(GRectInt).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GRectInt))
      return false;
    var tmp0_other_with_cast = other instanceof GRectInt ? other : THROW_CCE();
    if (!equals(this.fg8_1, tmp0_other_with_cast.fg8_1))
      return false;
    if (!equals(this.gg8_1, tmp0_other_with_cast.gg8_1))
      return false;
    if (!equals(this.hg8_1, tmp0_other_with_cast.hg8_1))
      return false;
    if (!equals(this.ig8_1, tmp0_other_with_cast.ig8_1))
      return false;
    return true;
  };
  function Companion_6() {
    Companion_instance_6 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new ArrayListSerializer($serializer_getInstance_9()), new LinkedHashMapSerializer(IntSerializer_getInstance(), $serializer_getInstance_6()), new LinkedHashMapSerializer(IntSerializer_getInstance(), $serializer_getInstance_7()), new ArrayListSerializer($serializer_getInstance_10()), new ArrayListSerializer($serializer_getInstance_8()), new ArrayListSerializer($serializer_getInstance_12()), new ArrayListSerializer($serializer_getInstance_13()), new ArrayListSerializer($serializer_getInstance_11())];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.zg8_1 = tmp$ret$2;
  }
  protoOf(Companion_6).z6e = function (width, height) {
    return new GameMap(toGameUnit(width), toGameUnit(height));
  };
  protoOf(Companion_6).ag9 = function (width, height, entities) {
    var gameMap = new GameMap(toGameUnit(width), toGameUnit(height));
    // Inline function 'kotlin.collections.forEach' call
    var indexedObject = entities;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'com.xenotactic.gamelogic.model.Companion.create.<anonymous>' call
      gameMap.og9(element);
    }
    return gameMap;
  };
  var Companion_instance_6;
  function Companion_getInstance_18() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function $serializer_2() {
    $serializer_instance_2 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.GameMap', this, 12);
    tmp0_serialDesc.cfg('width', false);
    tmp0_serialDesc.cfg('height', false);
    tmp0_serialDesc.cfg('start', true);
    tmp0_serialDesc.cfg('finish', true);
    tmp0_serialDesc.cfg('checkpoints', true);
    tmp0_serialDesc.cfg('teleportIns', true);
    tmp0_serialDesc.cfg('teleportOuts', true);
    tmp0_serialDesc.cfg('towers', true);
    tmp0_serialDesc.cfg('rocks', true);
    tmp0_serialDesc.cfg('smallBlockers', true);
    tmp0_serialDesc.cfg('speedAreas', true);
    tmp0_serialDesc.cfg('supplyDepots', true);
    this.pg9_1 = tmp0_serialDesc;
  }
  protoOf($serializer_2).qf5 = function () {
    return this.pg9_1;
  };
  protoOf($serializer_2).rfg = function () {
    var tmp0_cached = Companion_getInstance_18().zg8_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), get_nullable($serializer_getInstance_4()), get_nullable($serializer_getInstance_5()), tmp0_cached[4], tmp0_cached[5], tmp0_cached[6], tmp0_cached[7], tmp0_cached[8], tmp0_cached[9], tmp0_cached[10], tmp0_cached[11]];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_2).sf5 = function (decoder) {
    var tmp0_desc = this.pg9_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = null;
    var tmp12_local8 = null;
    var tmp13_local9 = null;
    var tmp14_local10 = null;
    var tmp15_local11 = null;
    var tmp16_transient0 = null;
    var tmp17_input = decoder.zf8(tmp0_desc);
    var tmp18_cached = Companion_getInstance_18().zg8_1;
    if (tmp17_input.pf9()) {
      tmp4_local0 = tmp17_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp17_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp17_input.nf9(tmp0_desc, 2, $serializer_getInstance_4(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp17_input.nf9(tmp0_desc, 3, $serializer_getInstance_5(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp17_input.lf9(tmp0_desc, 4, tmp18_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp17_input.lf9(tmp0_desc, 5, tmp18_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp17_input.lf9(tmp0_desc, 6, tmp18_cached[6], tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp17_input.lf9(tmp0_desc, 7, tmp18_cached[7], tmp11_local7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp17_input.lf9(tmp0_desc, 8, tmp18_cached[8], tmp12_local8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
      tmp13_local9 = tmp17_input.lf9(tmp0_desc, 9, tmp18_cached[9], tmp13_local9);
      tmp3_bitMask0 = tmp3_bitMask0 | 512;
      tmp14_local10 = tmp17_input.lf9(tmp0_desc, 10, tmp18_cached[10], tmp14_local10);
      tmp3_bitMask0 = tmp3_bitMask0 | 1024;
      tmp15_local11 = tmp17_input.lf9(tmp0_desc, 11, tmp18_cached[11], tmp15_local11);
      tmp3_bitMask0 = tmp3_bitMask0 | 2048;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp17_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp17_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp17_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp17_input.nf9(tmp0_desc, 2, $serializer_getInstance_4(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp17_input.nf9(tmp0_desc, 3, $serializer_getInstance_5(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp17_input.lf9(tmp0_desc, 4, tmp18_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp17_input.lf9(tmp0_desc, 5, tmp18_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp17_input.lf9(tmp0_desc, 6, tmp18_cached[6], tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp17_input.lf9(tmp0_desc, 7, tmp18_cached[7], tmp11_local7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp17_input.lf9(tmp0_desc, 8, tmp18_cached[8], tmp12_local8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          case 9:
            tmp13_local9 = tmp17_input.lf9(tmp0_desc, 9, tmp18_cached[9], tmp13_local9);
            tmp3_bitMask0 = tmp3_bitMask0 | 512;
            break;
          case 10:
            tmp14_local10 = tmp17_input.lf9(tmp0_desc, 10, tmp18_cached[10], tmp14_local10);
            tmp3_bitMask0 = tmp3_bitMask0 | 1024;
            break;
          case 11:
            tmp15_local11 = tmp17_input.lf9(tmp0_desc, 11, tmp18_cached[11], tmp15_local11);
            tmp3_bitMask0 = tmp3_bitMask0 | 2048;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp17_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    return GameMap_init_$Create$(tmp, tmp_1, tmp_2 == null ? null : tmp_2.vg3_1, tmp6_local2, tmp7_local3, tmp8_local4, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, tmp13_local9, tmp14_local10, tmp15_local11, null);
  };
  protoOf($serializer_2).qg9 = function (encoder, value) {
    var tmp0_desc = this.pg9_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_18().zg8_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.bg9_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.cg9_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !(value.dg9_1 == null)) {
      tmp1_output.sfa(tmp0_desc, 2, $serializer_getInstance_4(), value.dg9_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !(value.eg9_1 == null)) {
      tmp1_output.sfa(tmp0_desc, 3, $serializer_getInstance_5(), value.eg9_1);
    }
    var tmp;
    if (tmp1_output.wfa(tmp0_desc, 4)) {
      tmp = true;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp = !equals(value.fg9_1, tmp$ret$0);
    }
    if (tmp) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.fg9_1);
    }
    var tmp_0;
    if (tmp1_output.wfa(tmp0_desc, 5)) {
      tmp_0 = true;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$1 = LinkedHashMap_init_$Create$();
      tmp_0 = !equals(value.gg9_1, tmp$ret$1);
    }
    if (tmp_0) {
      tmp1_output.qfa(tmp0_desc, 5, tmp2_cached[5], value.gg9_1);
    }
    var tmp_1;
    if (tmp1_output.wfa(tmp0_desc, 6)) {
      tmp_1 = true;
    } else {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$2 = LinkedHashMap_init_$Create$();
      tmp_1 = !equals(value.hg9_1, tmp$ret$2);
    }
    if (tmp_1) {
      tmp1_output.qfa(tmp0_desc, 6, tmp2_cached[6], value.hg9_1);
    }
    var tmp_2;
    if (tmp1_output.wfa(tmp0_desc, 7)) {
      tmp_2 = true;
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$3 = ArrayList_init_$Create$();
      tmp_2 = !equals(value.ig9_1, tmp$ret$3);
    }
    if (tmp_2) {
      tmp1_output.qfa(tmp0_desc, 7, tmp2_cached[7], value.ig9_1);
    }
    var tmp_3;
    if (tmp1_output.wfa(tmp0_desc, 8)) {
      tmp_3 = true;
    } else {
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$4 = ArrayList_init_$Create$();
      tmp_3 = !equals(value.jg9_1, tmp$ret$4);
    }
    if (tmp_3) {
      tmp1_output.qfa(tmp0_desc, 8, tmp2_cached[8], value.jg9_1);
    }
    var tmp_4;
    if (tmp1_output.wfa(tmp0_desc, 9)) {
      tmp_4 = true;
    } else {
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$5 = ArrayList_init_$Create$();
      tmp_4 = !equals(value.kg9_1, tmp$ret$5);
    }
    if (tmp_4) {
      tmp1_output.qfa(tmp0_desc, 9, tmp2_cached[9], value.kg9_1);
    }
    var tmp_5;
    if (tmp1_output.wfa(tmp0_desc, 10)) {
      tmp_5 = true;
    } else {
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$6 = ArrayList_init_$Create$();
      tmp_5 = !equals(value.lg9_1, tmp$ret$6);
    }
    if (tmp_5) {
      tmp1_output.qfa(tmp0_desc, 10, tmp2_cached[10], value.lg9_1);
    }
    var tmp_6;
    if (tmp1_output.wfa(tmp0_desc, 11)) {
      tmp_6 = true;
    } else {
      var tmp$ret$7;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$7 = ArrayList_init_$Create$();
      tmp_6 = !equals(value.mg9_1, tmp$ret$7);
    }
    if (tmp_6) {
      tmp1_output.qfa(tmp0_desc, 11, tmp2_cached[11], value.mg9_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_2).rf5 = function (encoder, value) {
    return this.qg9(encoder, value instanceof GameMap ? value : THROW_CCE());
  };
  var $serializer_instance_2;
  function $serializer_getInstance_2() {
    if ($serializer_instance_2 == null)
      new $serializer_2();
    return $serializer_instance_2;
  }
  function GameMap_init_$Init$(seen1, width, height, start, finish, checkpoints, teleportIns, teleportOuts, towers, rocks, smallBlockers, speedAreas, supplyDepots, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_2().pg9_1);
    }
    $this.bg9_1 = width;
    $this.cg9_1 = height;
    if (0 === (seen1 & 4))
      $this.dg9_1 = null;
    else
      $this.dg9_1 = start;
    if (0 === (seen1 & 8))
      $this.eg9_1 = null;
    else
      $this.eg9_1 = finish;
    if (0 === (seen1 & 16)) {
      var tmp = $this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp.fg9_1 = tmp$ret$0;
    } else
      $this.fg9_1 = checkpoints;
    if (0 === (seen1 & 32)) {
      var tmp_0 = $this;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$1 = LinkedHashMap_init_$Create$();
      tmp_0.gg9_1 = tmp$ret$1;
    } else
      $this.gg9_1 = teleportIns;
    if (0 === (seen1 & 64)) {
      var tmp_1 = $this;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$2 = LinkedHashMap_init_$Create$();
      tmp_1.hg9_1 = tmp$ret$2;
    } else
      $this.hg9_1 = teleportOuts;
    if (0 === (seen1 & 128)) {
      var tmp_2 = $this;
      var tmp$ret$3;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$3 = ArrayList_init_$Create$();
      tmp_2.ig9_1 = tmp$ret$3;
    } else
      $this.ig9_1 = towers;
    if (0 === (seen1 & 256)) {
      var tmp_3 = $this;
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$4 = ArrayList_init_$Create$();
      tmp_3.jg9_1 = tmp$ret$4;
    } else
      $this.jg9_1 = rocks;
    if (0 === (seen1 & 512)) {
      var tmp_4 = $this;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$5 = ArrayList_init_$Create$();
      tmp_4.kg9_1 = tmp$ret$5;
    } else
      $this.kg9_1 = smallBlockers;
    if (0 === (seen1 & 1024)) {
      var tmp_5 = $this;
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$6 = ArrayList_init_$Create$();
      tmp_5.lg9_1 = tmp$ret$6;
    } else
      $this.lg9_1 = speedAreas;
    if (0 === (seen1 & 2048)) {
      var tmp_6 = $this;
      var tmp$ret$7;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$7 = ArrayList_init_$Create$();
      tmp_6.mg9_1 = tmp$ret$7;
    } else
      $this.mg9_1 = supplyDepots;
    $this.ng9_1 = new Mutable();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = $this.ig9_1;
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.<init>.<anonymous>' call
      $this.ng9_1.xg5(element);
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = $this.jg9_1;
    var tmp0_iterator_0 = tmp1_forEach.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.<init>.<anonymous>' call
      $this.ng9_1.xg5(element_0);
    }
    // Inline function 'kotlin.require' call
    var tmp2_require = $this.gg9_1.l() === $this.hg9_1.l();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp2_require) {
      var tmp$ret$8;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$8 = 'Failed requirement.';
      var message = tmp$ret$8;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp0_iterator_1 = $this.gg9_1.i3().h();
    while (tmp0_iterator_1.j()) {
      var sequenceNumber = tmp0_iterator_1.k();
      // Inline function 'kotlin.require' call
      var tmp3_require = $this.hg9_1.b3(sequenceNumber);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp3_require) {
        var tmp$ret$9;
        // Inline function 'kotlin.require.<anonymous>' call
        tmp$ret$9 = 'Failed requirement.';
        var message_0 = tmp$ret$9;
        throw IllegalArgumentException_init_$Create$(toString(message_0));
      }
    }
    return $this;
  }
  function GameMap_init_$Create$(seen1, width, height, start, finish, checkpoints, teleportIns, teleportOuts, towers, rocks, smallBlockers, speedAreas, supplyDepots, serializationConstructorMarker) {
    return GameMap_init_$Init$(seen1, width, height, start, finish, checkpoints, teleportIns, teleportOuts, towers, rocks, smallBlockers, speedAreas, supplyDepots, serializationConstructorMarker, objectCreate(protoOf(GameMap)));
  }
  function GameMap$getAllRocksAtPoint$lambda($x, $y) {
    return function (it) {
      return it.rg9($x, $y);
    };
  }
  function GameMap(width, height, start, finish, checkpoints, teleportIns, teleportOuts, towers, rocks, smallBlockers, speedAreas, supplyDepots) {
    Companion_getInstance_18();
    start = start === VOID ? null : start;
    finish = finish === VOID ? null : finish;
    var tmp;
    if (checkpoints === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = checkpoints;
    }
    checkpoints = tmp;
    var tmp_0;
    if (teleportIns === VOID) {
      var tmp$ret$0_1;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0_1 = LinkedHashMap_init_$Create$();
      tmp$ret$1 = Unit_getInstance();
      tmp_0 = tmp$ret$0_1;
    } else {
      tmp_0 = teleportIns;
    }
    teleportIns = tmp_0;
    var tmp_1;
    if (teleportOuts === VOID) {
      var tmp$ret$0_2;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0_2 = LinkedHashMap_init_$Create$();
      tmp$ret$2 = Unit_getInstance();
      tmp_1 = tmp$ret$0_2;
    } else {
      tmp_1 = teleportOuts;
    }
    teleportOuts = tmp_1;
    var tmp_2;
    if (towers === VOID) {
      var tmp$ret$0_3;
      var tmp$ret$3;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0_3 = ArrayList_init_$Create$();
      tmp$ret$3 = Unit_getInstance();
      tmp_2 = tmp$ret$0_3;
    } else {
      tmp_2 = towers;
    }
    towers = tmp_2;
    var tmp_3;
    if (rocks === VOID) {
      var tmp$ret$0_4;
      var tmp$ret$4;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0_4 = ArrayList_init_$Create$();
      tmp$ret$4 = Unit_getInstance();
      tmp_3 = tmp$ret$0_4;
    } else {
      tmp_3 = rocks;
    }
    rocks = tmp_3;
    var tmp_4;
    if (smallBlockers === VOID) {
      var tmp$ret$0_5;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0_5 = ArrayList_init_$Create$();
      tmp$ret$5 = Unit_getInstance();
      tmp_4 = tmp$ret$0_5;
    } else {
      tmp_4 = smallBlockers;
    }
    smallBlockers = tmp_4;
    var tmp_5;
    if (speedAreas === VOID) {
      var tmp$ret$0_6;
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0_6 = ArrayList_init_$Create$();
      tmp$ret$6 = Unit_getInstance();
      tmp_5 = tmp$ret$0_6;
    } else {
      tmp_5 = speedAreas;
    }
    speedAreas = tmp_5;
    var tmp_6;
    if (supplyDepots === VOID) {
      var tmp$ret$0_7;
      var tmp$ret$7;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0_7 = ArrayList_init_$Create$();
      tmp$ret$7 = Unit_getInstance();
      tmp_6 = tmp$ret$0_7;
    } else {
      tmp_6 = supplyDepots;
    }
    supplyDepots = tmp_6;
    this.bg9_1 = width;
    this.cg9_1 = height;
    this.dg9_1 = start;
    this.eg9_1 = finish;
    this.fg9_1 = checkpoints;
    this.gg9_1 = teleportIns;
    this.hg9_1 = teleportOuts;
    this.ig9_1 = towers;
    this.jg9_1 = rocks;
    this.kg9_1 = smallBlockers;
    this.lg9_1 = speedAreas;
    this.mg9_1 = supplyDepots;
    this.ng9_1 = new Mutable();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.ig9_1;
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.<anonymous>' call
      this.ng9_1.xg5(element);
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = this.jg9_1;
    var tmp0_iterator_0 = tmp1_forEach.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.<anonymous>' call
      this.ng9_1.xg5(element_0);
    }
    // Inline function 'kotlin.require' call
    var tmp2_require = this.gg9_1.l() === this.hg9_1.l();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp2_require) {
      var tmp$ret$8;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$8 = 'Failed requirement.';
      var message = tmp$ret$8;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp0_iterator_1 = this.gg9_1.i3().h();
    while (tmp0_iterator_1.j()) {
      var sequenceNumber = tmp0_iterator_1.k();
      // Inline function 'kotlin.require' call
      var tmp3_require = this.hg9_1.b3(sequenceNumber);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp3_require) {
        var tmp$ret$9;
        // Inline function 'kotlin.require.<anonymous>' call
        tmp$ret$9 = 'Failed requirement.';
        var message_0 = tmp$ret$9;
        throw IllegalArgumentException_init_$Create$(toString(message_0));
      }
    }
  }
  protoOf(GameMap).sg9 = function () {
    return this.fg9_1.l();
  };
  protoOf(GameMap).tg9 = function () {
    return this.gg9_1.l();
  };
  protoOf(GameMap).ug9 = function () {
    return this.jg9_1.l();
  };
  protoOf(GameMap).vg9 = function () {
    return this.lg9_1.l();
  };
  protoOf(GameMap).wg9 = function () {
    return this.dg9_1;
  };
  protoOf(GameMap).xg9 = function () {
    return this.eg9_1;
  };
  protoOf(GameMap).yg9 = function () {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.gg9_1;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(tmp1_map.l());
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = tmp1_map.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.<get-teleportPairs>.<anonymous>' call
      tmp$ret$1 = new TeleportPair(item.f1(), ensureNotNull(this.hg9_1.h3(item.e1())), item.f1().zg9_1);
      tmp0_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp0_mapTo;
    tmp$ret$3 = tmp$ret$2;
    return tmp$ret$3;
  };
  protoOf(GameMap).iga = function (entityType) {
    var tmp0_subject = entityType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = sequenceOfNullable(this.dg9_1);
        break;
      case 1:
        tmp = sequenceOfNullable(this.eg9_1);
        break;
      case 2:
        tmp = asSequence(this.fg9_1);
        break;
      case 3:
        tmp = asSequence(this.jg9_1);
        break;
      case 4:
        tmp = asSequence(this.ig9_1);
        break;
      case 5:
        tmp = asSequence(this.gg9_1.j3());
        break;
      case 6:
        tmp = asSequence(this.hg9_1.j3());
        break;
      case 7:
        tmp = asSequence(this.kg9_1);
        break;
      case 8:
        tmp = asSequence(this.lg9_1);
        break;
      case 9:
        tmp = emptySequence();
        break;
      case 10:
        tmp = asSequence(this.mg9_1);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(GameMap).jga = function () {
    return this.ng9_1.yg5();
  };
  protoOf(GameMap).toString = function () {
    return trimIndent('\n            GameMap(\n                start=' + this.dg9_1 + ',\n                finish=' + this.eg9_1 + ',\n                checkpoints=' + this.fg9_1 + ',\n                teleportIns=' + this.gg9_1 + ',\n                teleportOuts=' + this.hg9_1 + ',\n                towers=' + this.ig9_1 + ',\n                rocks=' + this.jg9_1 + ',\n                smallBlockers=' + this.kg9_1 + '\n            )\n        ');
  };
  protoOf(GameMap).og9 = function (entity) {
    var tmp0_subject = entity;
    if (tmp0_subject instanceof Rock) {
      this.jg9_1.b(entity);
    } else {
      if (tmp0_subject instanceof Start)
        this.dg9_1 = entity;
      else {
        if (tmp0_subject instanceof Finish)
          this.eg9_1 = entity;
        else {
          if (tmp0_subject instanceof Checkpoint) {
            this.fg9_1.b(entity);
          } else {
            if (tmp0_subject instanceof TeleportIn) {
              // Inline function 'kotlin.collections.set' call
              var tmp0_set = this.gg9_1;
              var tmp1_set = entity.zg9_1;
              var tmp2_set = entity;
              tmp0_set.e5(tmp1_set, tmp2_set);
            } else {
              if (tmp0_subject instanceof TeleportOut) {
                // Inline function 'kotlin.collections.set' call
                var tmp3_set = this.hg9_1;
                var tmp4_set = entity.kga_1;
                var tmp5_set = entity;
                tmp3_set.e5(tmp4_set, tmp5_set);
              } else {
                if (tmp0_subject instanceof Tower) {
                  this.ig9_1.b(entity);
                } else {
                  if (tmp0_subject instanceof SmallBlocker) {
                    this.kg9_1.b(entity);
                  } else {
                    if (tmp0_subject instanceof SpeedArea) {
                      this.lg9_1.b(entity);
                    } else {
                      if (tmp0_subject instanceof SupplyDepot) {
                        this.mg9_1.b(entity);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (entity.sga()) {
      this.ng9_1.xg5(entity);
    }
  };
  protoOf(GameMap).tga = function (entity) {
    var tmp0_subject = entity;
    if (tmp0_subject instanceof Rock) {
      this.jg9_1.b4(entity);
    } else {
      if (tmp0_subject instanceof Start)
        this.dg9_1 = null;
      else {
        if (tmp0_subject instanceof Finish)
          this.dg9_1 = null;
        else {
          if (tmp0_subject instanceof Checkpoint) {
            this.fg9_1.b4(entity);
          } else {
            if (tmp0_subject instanceof TeleportIn) {
              this.gg9_1.lg(entity.zg9_1);
            } else {
              if (tmp0_subject instanceof TeleportOut) {
                this.hg9_1.lg(entity.kga_1);
              } else {
                if (tmp0_subject instanceof Tower) {
                  this.ig9_1.b4(entity);
                } else {
                  if (tmp0_subject instanceof SpeedArea) {
                    this.lg9_1.b4(entity);
                  } else {
                    if (tmp0_subject instanceof SmallBlocker) {
                      this.kg9_1.b4(entity);
                    } else {
                      if (tmp0_subject instanceof SupplyDepot) {
                        this.mg9_1.b4(entity);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (entity.sga()) {
      this.ng9_1.gg6(entity.bg6());
    }
  };
  protoOf(GameMap).uga = function (entity) {
    return this.vga(entity.jg8(), entity.kg8(), entity.lg8(), entity.mg8());
  };
  protoOf(GameMap).vga = function (x, y, width, height) {
    var tmp0_iterator = until(0, width).h();
    while (tmp0_iterator.j()) {
      var i = tmp0_iterator.k().vg3_1;
      var tmp1_iterator = until(0, height).h();
      while (tmp1_iterator.j()) {
        var j = tmp1_iterator.k().vg3_1;
        if (this.ng9_1.hg6(GameUnit__plus_impl_rh1b6r(x, i), GameUnit__plus_impl_rh1b6r(y, j)))
          return true;
      }
    }
    return false;
  };
  protoOf(GameMap).yga = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.flatMap' call
    var tmp1_flatMap = values();
    var tmp$ret$1;
    // Inline function 'kotlin.collections.flatMapTo' call
    var tmp0_flatMapTo = ArrayList_init_$Create$();
    var indexedObject = tmp1_flatMap;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.getAllEntities.<anonymous>' call
      tmp$ret$0 = this.iga(element);
      var list = tmp$ret$0;
      addAll(tmp0_flatMapTo, list);
    }
    tmp$ret$1 = tmp0_flatMapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(GameMap).zga = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.flatMap' call
    var tmp1_flatMap = Companion_getInstance_32().agb_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.flatMapTo' call
    var tmp0_flatMapTo = ArrayList_init_$Create$();
    var tmp0_iterator = tmp1_flatMap.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.model.GameMap.getBlockingEntities.<anonymous>' call
      tmp$ret$0 = this.iga(element);
      var list = tmp$ret$0;
      addAll(tmp0_flatMapTo, list);
    }
    tmp$ret$1 = tmp0_flatMapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(GameMap).bgb = function (x, y) {
    var tmp = asSequence(this.jg9_1);
    return filter(tmp, GameMap$getAllRocksAtPoint$lambda(x, y));
  };
  protoOf(GameMap).cgb = function (x, y) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_firstOrNull = this.jg9_1;
      var tmp0_iterator = tmp0_firstOrNull.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.model.GameMap.getFirstRockAt.<anonymous>' call
        tmp$ret$0 = element.rg9(x, y);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    return tmp$ret$1;
  };
  protoOf(GameMap).dgb = function (x, y) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_firstOrNull = this.ig9_1;
      var tmp0_iterator = tmp0_firstOrNull.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.model.GameMap.getFirstTowerAt.<anonymous>' call
        tmp$ret$0 = element.rg9(x, y);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    return tmp$ret$1;
  };
  protoOf(GameMap).egb = function (x, y) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_firstOrNull = this.mg9_1;
      var tmp0_iterator = tmp0_firstOrNull.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.model.GameMap.getFirstSupplyDepotAt.<anonymous>' call
        tmp$ret$0 = element.rg9(x, y);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    return tmp$ret$1;
  };
  protoOf(GameMap).fgb = function () {
    return plus(plus(this.fg9_1, listOfNotNull([this.dg9_1, this.eg9_1])), this.hg9_1.j3());
  };
  protoOf(GameMap).ggb = function () {
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var tmp0_apply = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.gamelogic.model.GameMap.getSequentialPathingEntities.<anonymous>' call
    if (!(this.dg9_1 == null)) {
      tmp0_apply.b(ensureNotNull(this.dg9_1));
    }
    tmp0_apply.m(this.fg9_1);
    if (!(this.eg9_1 == null)) {
      tmp0_apply.b(ensureNotNull(this.eg9_1));
    }
    tmp$ret$1 = tmp0_apply;
    return tmp$ret$1;
  };
  protoOf(GameMap).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.bg9_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.cg9_1) | 0;
    result = imul(result, 31) + (this.dg9_1 == null ? 0 : this.dg9_1.hashCode()) | 0;
    result = imul(result, 31) + (this.eg9_1 == null ? 0 : this.eg9_1.hashCode()) | 0;
    result = imul(result, 31) + hashCode(this.fg9_1) | 0;
    result = imul(result, 31) + hashCode(this.gg9_1) | 0;
    result = imul(result, 31) + hashCode(this.hg9_1) | 0;
    result = imul(result, 31) + hashCode(this.ig9_1) | 0;
    result = imul(result, 31) + hashCode(this.jg9_1) | 0;
    result = imul(result, 31) + hashCode(this.kg9_1) | 0;
    result = imul(result, 31) + hashCode(this.lg9_1) | 0;
    result = imul(result, 31) + hashCode(this.mg9_1) | 0;
    return result;
  };
  protoOf(GameMap).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GameMap))
      return false;
    var tmp0_other_with_cast = other instanceof GameMap ? other : THROW_CCE();
    if (!equals(this.bg9_1, tmp0_other_with_cast.bg9_1))
      return false;
    if (!equals(this.cg9_1, tmp0_other_with_cast.cg9_1))
      return false;
    if (!equals(this.dg9_1, tmp0_other_with_cast.dg9_1))
      return false;
    if (!equals(this.eg9_1, tmp0_other_with_cast.eg9_1))
      return false;
    if (!equals(this.fg9_1, tmp0_other_with_cast.fg9_1))
      return false;
    if (!equals(this.gg9_1, tmp0_other_with_cast.gg9_1))
      return false;
    if (!equals(this.hg9_1, tmp0_other_with_cast.hg9_1))
      return false;
    if (!equals(this.ig9_1, tmp0_other_with_cast.ig9_1))
      return false;
    if (!equals(this.jg9_1, tmp0_other_with_cast.jg9_1))
      return false;
    if (!equals(this.kg9_1, tmp0_other_with_cast.kg9_1))
      return false;
    if (!equals(this.lg9_1, tmp0_other_with_cast.lg9_1))
      return false;
    if (!equals(this.mg9_1, tmp0_other_with_cast.mg9_1))
      return false;
    return true;
  };
  function Companion_7() {
    Companion_instance_7 = this;
    this.hgb_1 = new GameUnitTuple(toGameUnit(0), toGameUnit(0));
  }
  protoOf(Companion_7).z6e = function (x, y) {
    return new GameUnitTuple(toGameUnit(x), toGameUnit(y));
  };
  var Companion_instance_7;
  function Companion_getInstance_19() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function $serializer_3() {
    $serializer_instance_3 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.GameUnitTuple', this, 2);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    this.igb_1 = tmp0_serialDesc;
  }
  protoOf($serializer_3).qf5 = function () {
    return this.igb_1;
  };
  protoOf($serializer_3).rfg = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_3).sf5 = function (decoder) {
    var tmp0_desc = this.igb_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.zf8(tmp0_desc);
    if (tmp6_input.pf9()) {
      tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp6_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    return GameUnitTuple_init_$Create$(tmp, tmp_1, tmp_2 == null ? null : tmp_2.vg3_1, null);
  };
  protoOf($serializer_3).jgb = function (encoder, value) {
    var tmp0_desc = this.igb_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.dg6_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.eg6_1));
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_3).rf5 = function (encoder, value) {
    return this.jgb(encoder, value instanceof GameUnitTuple ? value : THROW_CCE());
  };
  var $serializer_instance_3;
  function $serializer_getInstance_3() {
    if ($serializer_instance_3 == null)
      new $serializer_3();
    return $serializer_instance_3;
  }
  function GameUnitTuple_init_$Init$(seen1, x, y, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_3().igb_1);
    }
    $this.dg6_1 = x;
    $this.eg6_1 = y;
    return $this;
  }
  function GameUnitTuple_init_$Create$(seen1, x, y, serializationConstructorMarker) {
    return GameUnitTuple_init_$Init$(seen1, x, y, serializationConstructorMarker, objectCreate(protoOf(GameUnitTuple)));
  }
  function GameUnitTuple(x, y) {
    Companion_getInstance_19();
    this.dg6_1 = x;
    this.eg6_1 = y;
  }
  protoOf(GameUnitTuple).kgb = function () {
    return this.dg6_1;
  };
  protoOf(GameUnitTuple).lgb = function () {
    return this.eg6_1;
  };
  protoOf(GameUnitTuple).mgb = function () {
    return new IPoint(_GameUnit___get_value__impl__9ocgox(this.dg6_1), _GameUnit___get_value__impl__9ocgox(this.eg6_1));
  };
  protoOf(GameUnitTuple).ngb = function (point2) {
    return distance(this.dg6_1, this.eg6_1, point2.dg6_1, point2.eg6_1);
  };
  protoOf(GameUnitTuple).ogb = function () {
    return this.dg6_1;
  };
  protoOf(GameUnitTuple).pgb = function () {
    return this.eg6_1;
  };
  protoOf(GameUnitTuple).qgb = function (x, y) {
    return new GameUnitTuple(x, y);
  };
  protoOf(GameUnitTuple).rgb = function (x, y, $super) {
    x = x === VOID ? this.dg6_1 : x;
    y = y === VOID ? this.eg6_1 : y;
    return $super === VOID ? this.qgb(x, y) : $super.qgb.call(this, new GameUnit(x), new GameUnit(y));
  };
  protoOf(GameUnitTuple).toString = function () {
    return 'GameUnitTuple(x=' + new GameUnit(this.dg6_1) + ', y=' + new GameUnit(this.eg6_1) + ')';
  };
  protoOf(GameUnitTuple).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.dg6_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.eg6_1) | 0;
    return result;
  };
  protoOf(GameUnitTuple).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GameUnitTuple))
      return false;
    var tmp0_other_with_cast = other instanceof GameUnitTuple ? other : THROW_CCE();
    if (!equals(this.dg6_1, tmp0_other_with_cast.dg6_1))
      return false;
    if (!equals(this.eg6_1, tmp0_other_with_cast.eg6_1))
      return false;
    return true;
  };
  function toGameUnitPoint(_this__u8e3s4) {
    return new GameUnitTuple(toGameUnit_0(_this__u8e3s4.sgb_1), toGameUnit_0(_this__u8e3s4.tgb_1));
  }
  function sam$kotlin_Comparator$0(function_0) {
    this.ugb_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).l8 = function (a, b) {
    return this.ugb_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function GameWorld$_get_currentSupplyUsage_$lambda_cq7txn(this$0) {
    return function (it) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.<get-currentSupplyUsage>.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
      var tmp0_getComponentContainer = this$0.vgb_1;
      tmp$ret$0 = tmp0_getComponentContainer.kg3(getKClass(SupplyCostComponent));
      var tmp1__anonymous__uwfjfc = tmp$ret$0.tg0(it.zg0_1);
      tmp$ret$1 = tmp1__anonymous__uwfjfc.bg5_1;
      return tmp$ret$1;
    };
  }
  function GameWorld$getPathFindingResult$lambda(a, b) {
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
    tmp$ret$0 = a.tfz(getKClass(EntityCheckpointComponent)).dg4_1;
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
    tmp$ret$1 = b.tfz(getKClass(EntityCheckpointComponent)).dg4_1;
    tmp$ret$2 = compareValues(tmp, tmp$ret$1);
    return tmp$ret$2;
  }
  function GameWorld(world) {
    world = world === VOID ? new World() : world;
    this.vgb_1 = world;
    this.wgb_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf([getKClass(EntityTypeComponent), getKClass(SizeComponent), getKClass(BottomLeftPositionComponent)])));
    this.xgb_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf([getKClass(EntityTypeComponent), getKClass(SizeComponent), getKClass(BottomLeftPositionComponent), getKClass(SelectableComponent)])));
    this.ygb_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf_0(getKClass(UIEntityViewComponent))));
    this.zgb_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf_0(getKClass(PreSelectionComponent))));
    this.agc_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf_0(getKClass(SelectedComponent))));
    this.bgc_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf_0(getKClass(EntitySupplyDepotComponent))));
    this.cgc_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(PathSequenceTraversalComponent)])));
    this.dgc_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf([getKClass(EntitySpeedAreaComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent)])));
    this.egc_1 = this.vgb_1.kg2(new FamilyConfiguration(setOf_0(getKClass(EntityBlockingComponent))));
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer = this.vgb_1;
    tmp$ret$0 = tmp0_getComponentContainer.kg3(getKClass(BottomLeftPositionComponent));
    tmp.fgc_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_0 = this.vgb_1;
    tmp$ret$1 = tmp0_getComponentContainer_0.kg3(getKClass(SizeComponent));
    tmp_0.ggc_1 = tmp$ret$1;
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_1 = this.vgb_1;
    tmp$ret$2 = tmp0_getComponentContainer_1.kg3(getKClass(EntityTypeComponent));
    tmp_1.hgc_1 = tmp$ret$2;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_2 = this.vgb_1;
    tmp$ret$3 = tmp0_getComponentContainer_2.kg3(getKClass(UIEntityViewComponent));
    tmp_2.igc_1 = tmp$ret$3;
    var tmp_3 = this;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_3 = this.vgb_1;
    tmp$ret$4 = tmp0_getComponentContainer_3.kg3(getKClass(UIMapEntityComponent));
    tmp_3.jgc_1 = tmp$ret$4;
    var tmp_4 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_4 = this.vgb_1;
    tmp$ret$5 = tmp0_getComponentContainer_4.kg3(getKClass(UIMapEntityTextComponent));
    tmp_4.kgc_1 = tmp$ret$5;
    var tmp_5 = this;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_5 = this.vgb_1;
    tmp$ret$6 = tmp0_getComponentContainer_5.kg3(getKClass(SelectedComponent));
    tmp_5.lgc_1 = tmp$ret$6;
    var tmp_6 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer_6 = this.vgb_1;
    tmp$ret$7 = tmp0_getComponentContainer_6.kg3(getKClass(PreSelectionComponent));
    tmp_6.mgc_1 = tmp$ret$7;
  }
  protoOf(GameWorld).ngc = function () {
    return this.vgb_1.og3(Companion_getInstance_1().zg1([getKClass(EntityStartComponent)]));
  };
  protoOf(GameWorld).ogc = function () {
    return this.vgb_1.og3(Companion_getInstance_1().zg1([getKClass(EntityFinishComponent)]));
  };
  protoOf(GameWorld).pgc = function () {
    return this.vgb_1.ng3(Companion_getInstance_1().zg1([getKClass(EntityCheckpointComponent)]));
  };
  protoOf(GameWorld).qgc = function () {
    return this.vgb_1.ng3(Companion_getInstance_1().zg1([getKClass(EntityTeleportInComponent)]));
  };
  protoOf(GameWorld).rgc = function () {
    return this.vgb_1.ng3(Companion_getInstance_1().zg1([getKClass(EntityTeleportOutComponent)]));
  };
  protoOf(GameWorld).sgc = function () {
    return this.vgb_1.ng3(Companion_getInstance_1().zg1([getKClass(EntityTowerComponent)]));
  };
  protoOf(GameWorld).tgc = function () {
    return this.vgb_1.ng3(Companion_getInstance_1().zg1([getKClass(EntityBlockingComponent)]));
  };
  protoOf(GameWorld).ugc = function () {
    var fam = this.vgb_1.kg2(Companion_getInstance_1().zg1([getKClass(SupplyCostComponent)]));
    var tmp$ret$0;
    // Inline function 'com.xenotactic.gamelogic.model.GameWorld.mapComponent' call
    var tmp0_mapComponent = fam.tg1();
    tmp$ret$0 = map(tmp0_mapComponent, GameWorld$_get_currentSupplyUsage_$lambda_cq7txn(this));
    return sum(tmp$ret$0);
  };
  protoOf(GameWorld).vgc = function (initialSupply, supplyPerDepot, maxSupply) {
    var numDepots = this.bgc_1.l();
    var tmp$ret$0;
    // Inline function 'kotlin.comparisons.minOf' call
    var tmp0_minOf = initialSupply + imul(numDepots, supplyPerDepot) | 0;
    tmp$ret$0 = Math.min(tmp0_minOf, maxSupply);
    return tmp$ret$0;
  };
  protoOf(GameWorld).wgc = function (width, height, blockingEntities, additionalBlockingEntities) {
    var startEntity = this.ngc();
    var finishEntity = this.ogc();
    var addedCheckpoints = this.pgc();
    var tmp$ret$6;
    // Inline function 'kotlin.collections.associateBy' call
    var tmp1_associateBy = this.qgc();
    var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp1_associateBy, 10)), 16);
    var tmp$ret$5;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp0_associateByTo = LinkedHashMap_init_$Create$_0(capacity);
    var tmp0_iterator = tmp1_associateBy.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$3;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$3 = element.tfz(getKClass(EntityTeleportInComponent)).jg4_1;
      var tmp = tmp$ret$3;
      var tmp$ret$4;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$4 = element;
      tmp0_associateByTo.e5(tmp, tmp$ret$4);
    }
    tmp$ret$5 = tmp0_associateByTo;
    tmp$ret$6 = tmp$ret$5;
    var sequenceNumToTeleportInEntities = tmp$ret$6;
    var tmp$ret$10;
    // Inline function 'kotlin.collections.associateBy' call
    var tmp3_associateBy = this.rgc();
    var capacity_0 = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp3_associateBy, 10)), 16);
    var tmp$ret$9;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp2_associateByTo = LinkedHashMap_init_$Create$_0(capacity_0);
    var tmp0_iterator_0 = tmp3_associateBy.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      var tmp$ret$7;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$7 = element_0.tfz(getKClass(EntityTeleportOutComponent)).kg4_1;
      var tmp_0 = tmp$ret$7;
      var tmp$ret$8;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$8 = element_0;
      tmp2_associateByTo.e5(tmp_0, tmp$ret$8);
    }
    tmp$ret$9 = tmp2_associateByTo;
    tmp$ret$10 = tmp$ret$9;
    var sequenceNumToTeleportOutEntities = tmp$ret$10;
    // Inline function 'kotlin.require' call
    var tmp4_require = equals(sequenceNumToTeleportInEntities.i3(), sequenceNumToTeleportOutEntities.i3());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp4_require) {
      var tmp$ret$11;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$11 = 'Failed requirement.';
      var message = tmp$ret$11;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp$ret$15;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$14;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp5_mapTo = ArrayList_init_$Create$_0(sequenceNumToTeleportInEntities.l());
    var tmp$ret$12;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$12 = sequenceNumToTeleportInEntities.d1().h();
    var tmp0_iterator_1 = tmp$ret$12;
    while (tmp0_iterator_1.j()) {
      var item = tmp0_iterator_1.k();
      var tmp$ret$13;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$13 = new TeleportPair(toRectangleEntity(item.f1()), toRectangleEntity(ensureNotNull(sequenceNumToTeleportOutEntities.h3(item.e1()))), item.e1());
      tmp5_mapTo.b(tmp$ret$13);
    }
    tmp$ret$14 = tmp5_mapTo;
    tmp$ret$15 = tmp$ret$14;
    var teleportPairs = tmp$ret$15;
    var tmp_1 = PathFinder_getInstance();
    var tmp_2 = toRectangleEntity(startEntity);
    var tmp_3 = toRectangleEntity(finishEntity);
    var tmp_4 = plus(blockingEntities, additionalBlockingEntities);
    var tmp$ret$20;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$17;
    // Inline function 'kotlin.collections.sortedBy' call
    var tmp$ret$16;
    // Inline function 'kotlin.comparisons.compareBy' call
    var tmp_5 = GameWorld$getPathFindingResult$lambda;
    tmp$ret$16 = new sam$kotlin_Comparator$0(tmp_5);
    tmp$ret$17 = sortedWith(addedCheckpoints, tmp$ret$16);
    var tmp7_map = tmp$ret$17;
    var tmp$ret$19;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp6_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp7_map, 10));
    var tmp0_iterator_2 = tmp7_map.h();
    while (tmp0_iterator_2.j()) {
      var item_0 = tmp0_iterator_2.k();
      var tmp$ret$18;
      // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
      tmp$ret$18 = toRectangleEntity(item_0);
      tmp6_mapTo.b(tmp$ret$18);
    }
    tmp$ret$19 = tmp6_mapTo;
    tmp$ret$20 = tmp$ret$19;
    return tmp_1.xgc(width, height, tmp_2, tmp_3, tmp_4, tmp$ret$20, teleportPairs);
  };
  protoOf(GameWorld).ygc = function (width, height, blockingEntities, additionalBlockingEntities, $super) {
    var tmp;
    if (blockingEntities === VOID) {
      var tmp$ret$2;
      var tmp$ret$2_0;
      // Inline function 'kotlin.collections.map' call
      var tmp1_map = this.tgc();
      var tmp$ret$1;
      var tmp$ret$1_0;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
      var tmp0_iterator = tmp1_map.h();
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$0;
        var tmp$ret$0_0;
        // Inline function 'com.xenotactic.gamelogic.model.GameWorld.getPathFindingResult.<anonymous>' call
        tmp$ret$0 = toRectangleEntity(item);
        tmp$ret$0_0 = Unit_getInstance();
        tmp0_mapTo.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapTo;
      tmp$ret$1_0 = Unit_getInstance();
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$2_0 = Unit_getInstance();
      tmp = tmp$ret$2;
    } else {
      tmp = blockingEntities;
    }
    blockingEntities = tmp;
    additionalBlockingEntities = additionalBlockingEntities === VOID ? emptyList() : additionalBlockingEntities;
    return $super === VOID ? this.wgc(width, height, blockingEntities, additionalBlockingEntities) : $super.wgc.call(this, new GameUnit(width), new GameUnit(height), blockingEntities, additionalBlockingEntities);
  };
  protoOf(GameWorld).zgc = function (id) {
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer = this.vgb_1;
    tmp$ret$0 = tmp0_getComponentContainer.kg3(getKClass(EntityTowerComponent));
    return tmp$ret$0.xg0(id);
  };
  protoOf(GameWorld).agd = function (id) {
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.World.getComponentContainer' call
    var tmp0_getComponentContainer = this.vgb_1;
    tmp$ret$0 = tmp0_getComponentContainer.kg3(getKClass(EntitySupplyDepotComponent));
    return tmp$ret$0.xg0(id);
  };
  function Companion_8() {
    Companion_instance_8 = this;
    this.bgd_1 = new IPoint(0.0, 0.0);
  }
  var Companion_instance_8;
  function Companion_getInstance_20() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function IPoint(x, y) {
    Companion_getInstance_20();
    this.sgb_1 = x;
    this.tgb_1 = y;
  }
  protoOf(IPoint).cgd = function (other) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sqrt' call
    var tmp0_sqrt = (this.sgb_1 - other.sgb_1) * (this.sgb_1 - other.sgb_1) + (this.tgb_1 - other.tgb_1) * (this.tgb_1 - other.tgb_1);
    tmp$ret$0 = Math.sqrt(tmp0_sqrt);
    return tmp$ret$0;
  };
  protoOf(IPoint).dgd = function (other) {
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.Companion.between' call
    var tmp0_between = Companion_getInstance_2();
    var tmp1_between = this.sgb_1;
    var tmp2_between = this.tgb_1;
    var tmp3_between = other.sgb_1;
    var tmp4_between = other.tgb_1;
    var tmp5_between = Companion_getInstance_3().v3r_1;
    tmp$ret$0 = Angle_between(tmp1_between, tmp2_between, tmp3_between, tmp4_between, tmp5_between);
    return tmp$ret$0;
  };
  protoOf(IPoint).toString = function () {
    return 'IPoint(x=' + this.sgb_1 + ', y=' + this.tgb_1 + ')';
  };
  protoOf(IPoint).hashCode = function () {
    var result = getNumberHashCode(this.sgb_1);
    result = imul(result, 31) + getNumberHashCode(this.tgb_1) | 0;
    return result;
  };
  protoOf(IPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof IPoint))
      return false;
    var tmp0_other_with_cast = other instanceof IPoint ? other : THROW_CCE();
    if (!equals(this.sgb_1, tmp0_other_with_cast.sgb_1))
      return false;
    if (!equals(this.tgb_1, tmp0_other_with_cast.tgb_1))
      return false;
    return true;
  };
  function IRectangle(x, y, width, height) {
    this.egd_1 = x;
    this.fgd_1 = y;
    this.ggd_1 = width;
    this.hgd_1 = height;
  }
  protoOf(IRectangle).igd = function (p) {
    var tmp;
    var containsLower = this.egd_1;
    var containsUpper = this.egd_1 + this.ggd_1;
    var containsArg = p.sgb_1;
    if (containsLower <= containsArg ? containsArg <= containsUpper : false) {
      var containsLower_0 = this.fgd_1;
      var containsUpper_0 = this.fgd_1 + this.hgd_1;
      var containsArg_0 = p.tgb_1;
      tmp = containsLower_0 <= containsArg_0 ? containsArg_0 <= containsUpper_0 : false;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(IRectangle).toString = function () {
    return 'IRectangle(x=' + this.egd_1 + ', y=' + this.fgd_1 + ', width=' + this.ggd_1 + ', height=' + this.hgd_1 + ')';
  };
  protoOf(IRectangle).hashCode = function () {
    var result = getNumberHashCode(this.egd_1);
    result = imul(result, 31) + getNumberHashCode(this.fgd_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ggd_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.hgd_1) | 0;
    return result;
  };
  protoOf(IRectangle).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof IRectangle))
      return false;
    var tmp0_other_with_cast = other instanceof IRectangle ? other : THROW_CCE();
    if (!equals(this.egd_1, tmp0_other_with_cast.egd_1))
      return false;
    if (!equals(this.fgd_1, tmp0_other_with_cast.fgd_1))
      return false;
    if (!equals(this.ggd_1, tmp0_other_with_cast.ggd_1))
      return false;
    if (!equals(this.hgd_1, tmp0_other_with_cast.hgd_1))
      return false;
    return true;
  };
  function RectangleEntity(x, y, width, height) {
    this.jgd_1 = x;
    this.kgd_1 = y;
    this.lgd_1 = width;
    this.mgd_1 = height;
  }
  protoOf(RectangleEntity).jg8 = function () {
    return this.jgd_1;
  };
  protoOf(RectangleEntity).kg8 = function () {
    return this.kgd_1;
  };
  protoOf(RectangleEntity).lg8 = function () {
    return this.lgd_1;
  };
  protoOf(RectangleEntity).mg8 = function () {
    return this.mgd_1;
  };
  protoOf(RectangleEntity).toString = function () {
    return 'RectangleEntity(x=' + new GameUnit(this.jgd_1) + ', y=' + new GameUnit(this.kgd_1) + ', width=' + new GameUnit(this.lgd_1) + ', height=' + new GameUnit(this.mgd_1) + ')';
  };
  protoOf(RectangleEntity).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.jgd_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.kgd_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.lgd_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.mgd_1) | 0;
    return result;
  };
  protoOf(RectangleEntity).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RectangleEntity))
      return false;
    var tmp0_other_with_cast = other instanceof RectangleEntity ? other : THROW_CCE();
    if (!equals(this.jgd_1, tmp0_other_with_cast.jgd_1))
      return false;
    if (!equals(this.kgd_1, tmp0_other_with_cast.kgd_1))
      return false;
    if (!equals(this.lgd_1, tmp0_other_with_cast.lgd_1))
      return false;
    if (!equals(this.mgd_1, tmp0_other_with_cast.mgd_1))
      return false;
    return true;
  };
  function IRectangleEntity() {
  }
  function Companion_9() {
    Companion_instance_9 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.ngd_1 = tmp$ret$2;
  }
  var Companion_instance_9;
  function Companion_getInstance_21() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function $serializer_4() {
    $serializer_instance_4 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.Start', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.ogd_1 = tmp0_serialDesc;
  }
  protoOf($serializer_4).qf5 = function () {
    return this.ogd_1;
  };
  protoOf($serializer_4).rfg = function () {
    var tmp0_cached = Companion_getInstance_21().ngd_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_4).sf5 = function (decoder) {
    var tmp0_desc = this.ogd_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_21().ngd_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return Start_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_4).pgd = function (encoder, value) {
    var tmp0_desc = this.ogd_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_21().ngd_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.qgd_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.rgd_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !equals(value.sgd_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.sgd_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.tgd_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.tgd_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.ugd_1.equals(MapEntityType_START_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.ugd_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.vgd_1 === 'Start')) {
      tmp1_output.ofa(tmp0_desc, 5, value.vgd_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.wgd_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 6, value.wgd_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_4).rf5 = function (encoder, value) {
    return this.pgd(encoder, value instanceof Start ? value : THROW_CCE());
  };
  var $serializer_instance_4;
  function $serializer_getInstance_4() {
    if ($serializer_instance_4 == null)
      new $serializer_4();
    return $serializer_instance_4;
  }
  function Start_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_4().ogd_1);
    }
    MapEntity.call($this);
    $this.qgd_1 = x;
    $this.rgd_1 = y;
    if (0 === (seen1 & 4))
      $this.sgd_1 = toGameUnit(2);
    else
      $this.sgd_1 = width;
    if (0 === (seen1 & 8))
      $this.tgd_1 = toGameUnit(2);
    else
      $this.tgd_1 = height;
    if (0 === (seen1 & 16))
      $this.ugd_1 = MapEntityType_START_getInstance();
    else
      $this.ugd_1 = type;
    if (0 === (seen1 & 32))
      $this.vgd_1 = 'Start';
    else
      $this.vgd_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.wgd_1 = false;
    else
      $this.wgd_1 = isBlockingEntity;
    return $this;
  }
  function Start_init_$Create$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return Start_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(Start)));
  }
  function Companion_10() {
    Companion_instance_10 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.xgd_1 = tmp$ret$2;
  }
  var Companion_instance_10;
  function Companion_getInstance_22() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function $serializer_5() {
    $serializer_instance_5 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.Finish', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.ygd_1 = tmp0_serialDesc;
  }
  protoOf($serializer_5).qf5 = function () {
    return this.ygd_1;
  };
  protoOf($serializer_5).rfg = function () {
    var tmp0_cached = Companion_getInstance_22().xgd_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_5).sf5 = function (decoder) {
    var tmp0_desc = this.ygd_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_22().xgd_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return Finish_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_5).zgd = function (encoder, value) {
    var tmp0_desc = this.ygd_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_22().xgd_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.age_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.bge_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !equals(value.cge_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.cge_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.dge_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.dge_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.ege_1.equals(MapEntityType_FINISH_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.ege_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.fge_1 === 'Finish')) {
      tmp1_output.ofa(tmp0_desc, 5, value.fge_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.gge_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 6, value.gge_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_5).rf5 = function (encoder, value) {
    return this.zgd(encoder, value instanceof Finish ? value : THROW_CCE());
  };
  var $serializer_instance_5;
  function $serializer_getInstance_5() {
    if ($serializer_instance_5 == null)
      new $serializer_5();
    return $serializer_instance_5;
  }
  function Finish_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_5().ygd_1);
    }
    MapEntity.call($this);
    $this.age_1 = x;
    $this.bge_1 = y;
    if (0 === (seen1 & 4))
      $this.cge_1 = toGameUnit(2);
    else
      $this.cge_1 = width;
    if (0 === (seen1 & 8))
      $this.dge_1 = toGameUnit(2);
    else
      $this.dge_1 = height;
    if (0 === (seen1 & 16))
      $this.ege_1 = MapEntityType_FINISH_getInstance();
    else
      $this.ege_1 = type;
    if (0 === (seen1 & 32))
      $this.fge_1 = 'Finish';
    else
      $this.fge_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.gge_1 = false;
    else
      $this.gge_1 = isBlockingEntity;
    return $this;
  }
  function Finish_init_$Create$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return Finish_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(Finish)));
  }
  function Companion_11() {
    Companion_instance_11 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.hge_1 = tmp$ret$2;
  }
  var Companion_instance_11;
  function Companion_getInstance_23() {
    if (Companion_instance_11 == null)
      new Companion_11();
    return Companion_instance_11;
  }
  function $serializer_6() {
    $serializer_instance_6 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.TeleportIn', this, 9);
    tmp0_serialDesc.cfg('sequenceNumber', false);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    tmp0_serialDesc.cfg('radius', true);
    this.ige_1 = tmp0_serialDesc;
  }
  protoOf($serializer_6).qf5 = function () {
    return this.ige_1;
  };
  protoOf($serializer_6).rfg = function () {
    var tmp0_cached = Companion_getInstance_23().hge_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [IntSerializer_getInstance(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[5], StringSerializer_getInstance(), BooleanSerializer_getInstance(), DoubleSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_6).sf5 = function (decoder) {
    var tmp0_desc = this.ige_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = false;
    var tmp12_local8 = 0.0;
    var tmp13_input = decoder.zf8(tmp0_desc);
    var tmp14_cached = Companion_getInstance_23().hge_1;
    if (tmp13_input.pf9()) {
      tmp4_local0 = tmp13_input.ef9(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp13_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp13_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp13_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp13_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp13_input.lf9(tmp0_desc, 5, tmp14_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp13_input.jf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp13_input.bf9(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp13_input.hf9(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp13_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp13_input.ef9(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp13_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp13_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp13_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp13_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp13_input.lf9(tmp0_desc, 5, tmp14_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp13_input.jf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp13_input.bf9(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp13_input.hf9(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp13_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp5_local1;
    var tmp_2 = tmp_1 == null ? null : tmp_1.vg3_1;
    var tmp_3 = tmp6_local2;
    var tmp_4 = tmp_3 == null ? null : tmp_3.vg3_1;
    var tmp_5 = tmp7_local3;
    var tmp_6 = tmp_5 == null ? null : tmp_5.vg3_1;
    var tmp_7 = tmp8_local4;
    return TeleportIn_init_$Create$(tmp, tmp_0, tmp_2, tmp_4, tmp_6, tmp_7 == null ? null : tmp_7.vg3_1, tmp9_local5, tmp10_local6, tmp11_local7, tmp12_local8, null);
  };
  protoOf($serializer_6).jge = function (encoder, value) {
    var tmp0_desc = this.ige_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_23().hge_1;
    tmp1_output.jfa(tmp0_desc, 0, value.zg9_1);
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.aga_1));
    tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.bga_1));
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.cga_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.cga_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !equals(value.dga_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 4, $serializer_getInstance_15(), new GameUnit(value.dga_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !value.ega_1.equals(MapEntityType_TELEPORT_IN_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 5, tmp2_cached[5], value.ega_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.fga_1 === 'Teleport In ' + value.zg9_1)) {
      tmp1_output.ofa(tmp0_desc, 6, value.fga_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 7) ? true : !(value.gga_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 7, value.gga_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 8) ? true : !equals(value.hga_1, 1.0)) {
      tmp1_output.mfa(tmp0_desc, 8, value.hga_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_6).rf5 = function (encoder, value) {
    return this.jge(encoder, value instanceof TeleportIn ? value : THROW_CCE());
  };
  var $serializer_instance_6;
  function $serializer_getInstance_6() {
    if ($serializer_instance_6 == null)
      new $serializer_6();
    return $serializer_instance_6;
  }
  function TeleportIn_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, radius, serializationConstructorMarker, $this) {
    if (!(7 === (7 & seen1))) {
      throwMissingFieldException(seen1, 7, $serializer_getInstance_6().ige_1);
    }
    MapEntity.call($this);
    $this.zg9_1 = sequenceNumber;
    $this.aga_1 = x;
    $this.bga_1 = y;
    if (0 === (seen1 & 8))
      $this.cga_1 = toGameUnit(2);
    else
      $this.cga_1 = width;
    if (0 === (seen1 & 16))
      $this.dga_1 = toGameUnit(2);
    else
      $this.dga_1 = height;
    if (0 === (seen1 & 32))
      $this.ega_1 = MapEntityType_TELEPORT_IN_getInstance();
    else
      $this.ega_1 = type;
    if (0 === (seen1 & 64))
      $this.fga_1 = 'Teleport In ' + $this.zg9_1;
    else
      $this.fga_1 = friendlyName;
    if (0 === (seen1 & 128))
      $this.gga_1 = false;
    else
      $this.gga_1 = isBlockingEntity;
    if (0 === (seen1 & 256))
      $this.hga_1 = 1.0;
    else
      $this.hga_1 = radius;
    return $this;
  }
  function TeleportIn_init_$Create$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, radius, serializationConstructorMarker) {
    return TeleportIn_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, radius, serializationConstructorMarker, objectCreate(protoOf(TeleportIn)));
  }
  function Companion_12() {
    Companion_instance_12 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.kge_1 = tmp$ret$2;
  }
  var Companion_instance_12;
  function Companion_getInstance_24() {
    if (Companion_instance_12 == null)
      new Companion_12();
    return Companion_instance_12;
  }
  function $serializer_7() {
    $serializer_instance_7 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.TeleportOut', this, 8);
    tmp0_serialDesc.cfg('sequenceNumber', false);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.lge_1 = tmp0_serialDesc;
  }
  protoOf($serializer_7).qf5 = function () {
    return this.lge_1;
  };
  protoOf($serializer_7).rfg = function () {
    var tmp0_cached = Companion_getInstance_24().kge_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [IntSerializer_getInstance(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[5], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_7).sf5 = function (decoder) {
    var tmp0_desc = this.lge_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = false;
    var tmp12_input = decoder.zf8(tmp0_desc);
    var tmp13_cached = Companion_getInstance_24().kge_1;
    if (tmp12_input.pf9()) {
      tmp4_local0 = tmp12_input.ef9(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.lf9(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.jf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.bf9(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.ef9(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.lf9(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.jf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.bf9(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp12_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp5_local1;
    var tmp_2 = tmp_1 == null ? null : tmp_1.vg3_1;
    var tmp_3 = tmp6_local2;
    var tmp_4 = tmp_3 == null ? null : tmp_3.vg3_1;
    var tmp_5 = tmp7_local3;
    var tmp_6 = tmp_5 == null ? null : tmp_5.vg3_1;
    var tmp_7 = tmp8_local4;
    return TeleportOut_init_$Create$(tmp, tmp_0, tmp_2, tmp_4, tmp_6, tmp_7 == null ? null : tmp_7.vg3_1, tmp9_local5, tmp10_local6, tmp11_local7, null);
  };
  protoOf($serializer_7).mge = function (encoder, value) {
    var tmp0_desc = this.lge_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_24().kge_1;
    tmp1_output.jfa(tmp0_desc, 0, value.kga_1);
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.lga_1));
    tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.mga_1));
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.nga_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.nga_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !equals(value.oga_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 4, $serializer_getInstance_15(), new GameUnit(value.oga_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !value.pga_1.equals(MapEntityType_TELEPORT_OUT_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 5, tmp2_cached[5], value.pga_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.qga_1 === 'Teleport Out ' + value.kga_1)) {
      tmp1_output.ofa(tmp0_desc, 6, value.qga_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 7) ? true : !(value.rga_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 7, value.rga_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_7).rf5 = function (encoder, value) {
    return this.mge(encoder, value instanceof TeleportOut ? value : THROW_CCE());
  };
  var $serializer_instance_7;
  function $serializer_getInstance_7() {
    if ($serializer_instance_7 == null)
      new $serializer_7();
    return $serializer_instance_7;
  }
  function TeleportOut_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(7 === (7 & seen1))) {
      throwMissingFieldException(seen1, 7, $serializer_getInstance_7().lge_1);
    }
    MapEntity.call($this);
    $this.kga_1 = sequenceNumber;
    $this.lga_1 = x;
    $this.mga_1 = y;
    if (0 === (seen1 & 8))
      $this.nga_1 = toGameUnit(2);
    else
      $this.nga_1 = width;
    if (0 === (seen1 & 16))
      $this.oga_1 = toGameUnit(2);
    else
      $this.oga_1 = height;
    if (0 === (seen1 & 32))
      $this.pga_1 = MapEntityType_TELEPORT_OUT_getInstance();
    else
      $this.pga_1 = type;
    if (0 === (seen1 & 64))
      $this.qga_1 = 'Teleport Out ' + $this.kga_1;
    else
      $this.qga_1 = friendlyName;
    if (0 === (seen1 & 128))
      $this.rga_1 = false;
    else
      $this.rga_1 = isBlockingEntity;
    return $this;
  }
  function TeleportOut_init_$Create$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return TeleportOut_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(TeleportOut)));
  }
  function Companion_13() {
    Companion_instance_13 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.nge_1 = tmp$ret$2;
  }
  var Companion_instance_13;
  function Companion_getInstance_25() {
    if (Companion_instance_13 == null)
      new Companion_13();
    return Companion_instance_13;
  }
  function $serializer_8() {
    $serializer_instance_8 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.Rock', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', false);
    tmp0_serialDesc.cfg('height', false);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.oge_1 = tmp0_serialDesc;
  }
  protoOf($serializer_8).qf5 = function () {
    return this.oge_1;
  };
  protoOf($serializer_8).rfg = function () {
    var tmp0_cached = Companion_getInstance_25().nge_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_8).sf5 = function (decoder) {
    var tmp0_desc = this.oge_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_25().nge_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return Rock_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_8).pge = function (encoder, value) {
    var tmp0_desc = this.oge_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_25().nge_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.qge_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.rge_1));
    tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.sge_1));
    tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.tge_1));
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.uge_1.equals(MapEntityType_ROCK_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.uge_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.vge_1 === 'Rock (' + new GameUnit(value.sge_1) + 'x' + new GameUnit(value.tge_1) + ')')) {
      tmp1_output.ofa(tmp0_desc, 5, value.vge_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.wge_1 === true)) {
      tmp1_output.gfa(tmp0_desc, 6, value.wge_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_8).rf5 = function (encoder, value) {
    return this.pge(encoder, value instanceof Rock ? value : THROW_CCE());
  };
  var $serializer_instance_8;
  function $serializer_getInstance_8() {
    if ($serializer_instance_8 == null)
      new $serializer_8();
    return $serializer_instance_8;
  }
  function Rock_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(15 === (15 & seen1))) {
      throwMissingFieldException(seen1, 15, $serializer_getInstance_8().oge_1);
    }
    MapEntity.call($this);
    $this.qge_1 = x;
    $this.rge_1 = y;
    $this.sge_1 = width;
    $this.tge_1 = height;
    if (0 === (seen1 & 16))
      $this.uge_1 = MapEntityType_ROCK_getInstance();
    else
      $this.uge_1 = type;
    if (0 === (seen1 & 32))
      $this.vge_1 = 'Rock (' + new GameUnit($this.sge_1) + 'x' + new GameUnit($this.tge_1) + ')';
    else
      $this.vge_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.wge_1 = true;
    else
      $this.wge_1 = isBlockingEntity;
    // Inline function 'kotlin.require' call
    var tmp0_require = GameUnit__compareTo_impl_qyacwt($this.sge_1, 0) >= 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = GameUnit__compareTo_impl_qyacwt($this.tge_1, 0) >= 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    return $this;
  }
  function Rock_init_$Create$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return Rock_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(Rock)));
  }
  function Companion_14() {
    Companion_instance_14 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.xge_1 = tmp$ret$2;
  }
  var Companion_instance_14;
  function Companion_getInstance_26() {
    if (Companion_instance_14 == null)
      new Companion_14();
    return Companion_instance_14;
  }
  function $serializer_9() {
    $serializer_instance_9 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.Checkpoint', this, 8);
    tmp0_serialDesc.cfg('sequenceNumber', false);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.yge_1 = tmp0_serialDesc;
  }
  protoOf($serializer_9).qf5 = function () {
    return this.yge_1;
  };
  protoOf($serializer_9).rfg = function () {
    var tmp0_cached = Companion_getInstance_26().xge_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [IntSerializer_getInstance(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[5], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_9).sf5 = function (decoder) {
    var tmp0_desc = this.yge_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = 0;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = false;
    var tmp12_input = decoder.zf8(tmp0_desc);
    var tmp13_cached = Companion_getInstance_26().xge_1;
    if (tmp12_input.pf9()) {
      tmp4_local0 = tmp12_input.ef9(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp12_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp12_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp12_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp12_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp12_input.lf9(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp12_input.jf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp12_input.bf9(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp12_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp12_input.ef9(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp12_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp12_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp12_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp12_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp12_input.lf9(tmp0_desc, 5, tmp13_cached[5], tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp12_input.jf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp12_input.bf9(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp12_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp5_local1;
    var tmp_2 = tmp_1 == null ? null : tmp_1.vg3_1;
    var tmp_3 = tmp6_local2;
    var tmp_4 = tmp_3 == null ? null : tmp_3.vg3_1;
    var tmp_5 = tmp7_local3;
    var tmp_6 = tmp_5 == null ? null : tmp_5.vg3_1;
    var tmp_7 = tmp8_local4;
    return Checkpoint_init_$Create$(tmp, tmp_0, tmp_2, tmp_4, tmp_6, tmp_7 == null ? null : tmp_7.vg3_1, tmp9_local5, tmp10_local6, tmp11_local7, null);
  };
  protoOf($serializer_9).zge = function (encoder, value) {
    var tmp0_desc = this.yge_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_26().xge_1;
    tmp1_output.jfa(tmp0_desc, 0, value.agf_1);
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.bgf_1));
    tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.cgf_1));
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.dgf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.dgf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !equals(value.egf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 4, $serializer_getInstance_15(), new GameUnit(value.egf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !value.fgf_1.equals(MapEntityType_CHECKPOINT_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 5, tmp2_cached[5], value.fgf_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.ggf_1 === 'Checkpoint ' + value.agf_1)) {
      tmp1_output.ofa(tmp0_desc, 6, value.ggf_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 7) ? true : !(value.hgf_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 7, value.hgf_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_9).rf5 = function (encoder, value) {
    return this.zge(encoder, value instanceof Checkpoint ? value : THROW_CCE());
  };
  var $serializer_instance_9;
  function $serializer_getInstance_9() {
    if ($serializer_instance_9 == null)
      new $serializer_9();
    return $serializer_instance_9;
  }
  function Checkpoint_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(7 === (7 & seen1))) {
      throwMissingFieldException(seen1, 7, $serializer_getInstance_9().yge_1);
    }
    MapEntity.call($this);
    $this.agf_1 = sequenceNumber;
    $this.bgf_1 = x;
    $this.cgf_1 = y;
    if (0 === (seen1 & 8))
      $this.dgf_1 = toGameUnit(2);
    else
      $this.dgf_1 = width;
    if (0 === (seen1 & 16))
      $this.egf_1 = toGameUnit(2);
    else
      $this.egf_1 = height;
    if (0 === (seen1 & 32))
      $this.fgf_1 = MapEntityType_CHECKPOINT_getInstance();
    else
      $this.fgf_1 = type;
    if (0 === (seen1 & 64))
      $this.ggf_1 = 'Checkpoint ' + $this.agf_1;
    else
      $this.ggf_1 = friendlyName;
    if (0 === (seen1 & 128))
      $this.hgf_1 = false;
    else
      $this.hgf_1 = isBlockingEntity;
    return $this;
  }
  function Checkpoint_init_$Create$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return Checkpoint_init_$Init$(seen1, sequenceNumber, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(Checkpoint)));
  }
  function Companion_15() {
    Companion_instance_15 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.igf_1 = tmp$ret$2;
  }
  var Companion_instance_15;
  function Companion_getInstance_27() {
    if (Companion_instance_15 == null)
      new Companion_15();
    return Companion_instance_15;
  }
  function $serializer_10() {
    $serializer_instance_10 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.Tower', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.jgf_1 = tmp0_serialDesc;
  }
  protoOf($serializer_10).qf5 = function () {
    return this.jgf_1;
  };
  protoOf($serializer_10).rfg = function () {
    var tmp0_cached = Companion_getInstance_27().igf_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_10).sf5 = function (decoder) {
    var tmp0_desc = this.jgf_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_27().igf_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return Tower_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_10).kgf = function (encoder, value) {
    var tmp0_desc = this.jgf_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_27().igf_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.lgf_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.mgf_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !equals(value.ngf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.ngf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.ogf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.ogf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.pgf_1.equals(MapEntityType_TOWER_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.pgf_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.qgf_1 === 'Tower')) {
      tmp1_output.ofa(tmp0_desc, 5, value.qgf_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.rgf_1 === true)) {
      tmp1_output.gfa(tmp0_desc, 6, value.rgf_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_10).rf5 = function (encoder, value) {
    return this.kgf(encoder, value instanceof Tower ? value : THROW_CCE());
  };
  var $serializer_instance_10;
  function $serializer_getInstance_10() {
    if ($serializer_instance_10 == null)
      new $serializer_10();
    return $serializer_instance_10;
  }
  function Tower_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_10().jgf_1);
    }
    MapEntity.call($this);
    $this.lgf_1 = x;
    $this.mgf_1 = y;
    if (0 === (seen1 & 4))
      $this.ngf_1 = toGameUnit(2);
    else
      $this.ngf_1 = width;
    if (0 === (seen1 & 8))
      $this.ogf_1 = toGameUnit(2);
    else
      $this.ogf_1 = height;
    if (0 === (seen1 & 16))
      $this.pgf_1 = MapEntityType_TOWER_getInstance();
    else
      $this.pgf_1 = type;
    if (0 === (seen1 & 32))
      $this.qgf_1 = 'Tower';
    else
      $this.qgf_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.rgf_1 = true;
    else
      $this.rgf_1 = isBlockingEntity;
    return $this;
  }
  function Tower_init_$Create$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return Tower_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(Tower)));
  }
  function SupplyDepot_init_$Init$(gameUnitPoint, $this) {
    SupplyDepot.call($this, gameUnitPoint.dg6_1, gameUnitPoint.eg6_1);
    return $this;
  }
  function SupplyDepot_init_$Create$(gameUnitPoint) {
    return SupplyDepot_init_$Init$(gameUnitPoint, objectCreate(protoOf(SupplyDepot)));
  }
  function Companion_16() {
    Companion_instance_16 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.sgf_1 = tmp$ret$2;
  }
  var Companion_instance_16;
  function Companion_getInstance_28() {
    if (Companion_instance_16 == null)
      new Companion_16();
    return Companion_instance_16;
  }
  function $serializer_11() {
    $serializer_instance_11 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.SupplyDepot', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.tgf_1 = tmp0_serialDesc;
  }
  protoOf($serializer_11).qf5 = function () {
    return this.tgf_1;
  };
  protoOf($serializer_11).rfg = function () {
    var tmp0_cached = Companion_getInstance_28().sgf_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_11).sf5 = function (decoder) {
    var tmp0_desc = this.tgf_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_28().sgf_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return SupplyDepot_init_$Create$_0(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_11).ugf = function (encoder, value) {
    var tmp0_desc = this.tgf_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_28().sgf_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.vgf_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.wgf_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !equals(value.xgf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.xgf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.ygf_1, toGameUnit(2))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.ygf_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.zgf_1.equals(MapEntityType_SUPPLY_DEPOT_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.zgf_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.agg_1 === 'Supply depot')) {
      tmp1_output.ofa(tmp0_desc, 5, value.agg_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.bgg_1 === true)) {
      tmp1_output.gfa(tmp0_desc, 6, value.bgg_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_11).rf5 = function (encoder, value) {
    return this.ugf(encoder, value instanceof SupplyDepot ? value : THROW_CCE());
  };
  var $serializer_instance_11;
  function $serializer_getInstance_11() {
    if ($serializer_instance_11 == null)
      new $serializer_11();
    return $serializer_instance_11;
  }
  function SupplyDepot_init_$Init$_0(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_11().tgf_1);
    }
    MapEntity.call($this);
    $this.vgf_1 = x;
    $this.wgf_1 = y;
    if (0 === (seen1 & 4))
      $this.xgf_1 = toGameUnit(2);
    else
      $this.xgf_1 = width;
    if (0 === (seen1 & 8))
      $this.ygf_1 = toGameUnit(2);
    else
      $this.ygf_1 = height;
    if (0 === (seen1 & 16))
      $this.zgf_1 = MapEntityType_SUPPLY_DEPOT_getInstance();
    else
      $this.zgf_1 = type;
    if (0 === (seen1 & 32))
      $this.agg_1 = 'Supply depot';
    else
      $this.agg_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.bgg_1 = true;
    else
      $this.bgg_1 = isBlockingEntity;
    return $this;
  }
  function SupplyDepot_init_$Create$_0(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return SupplyDepot_init_$Init$_0(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(SupplyDepot)));
  }
  function Companion_17() {
    Companion_instance_17 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.cgg_1 = tmp$ret$2;
  }
  var Companion_instance_17;
  function Companion_getInstance_29() {
    if (Companion_instance_17 == null)
      new Companion_17();
    return Companion_instance_17;
  }
  function $serializer_12() {
    $serializer_instance_12 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.SmallBlocker', this, 7);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    this.dgg_1 = tmp0_serialDesc;
  }
  protoOf($serializer_12).qf5 = function () {
    return this.dgg_1;
  };
  protoOf($serializer_12).rfg = function () {
    var tmp0_cached = Companion_getInstance_29().cgg_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[4], StringSerializer_getInstance(), BooleanSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_12).sf5 = function (decoder) {
    var tmp0_desc = this.dgg_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = null;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = false;
    var tmp11_input = decoder.zf8(tmp0_desc);
    var tmp12_cached = Companion_getInstance_29().cgg_1;
    if (tmp11_input.pf9()) {
      tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp11_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp11_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp11_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp11_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp11_input.lf9(tmp0_desc, 3, $serializer_getInstance_15(), tmp7_local3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp11_input.lf9(tmp0_desc, 4, tmp12_cached[4], tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp11_input.jf9(tmp0_desc, 5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp11_input.bf9(tmp0_desc, 6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp11_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    return SmallBlocker_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6 == null ? null : tmp_6.vg3_1, tmp8_local4, tmp9_local5, tmp10_local6, null);
  };
  protoOf($serializer_12).egg = function (encoder, value) {
    var tmp0_desc = this.dgg_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_29().cgg_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.fgg_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.ggg_1));
    if (tmp1_output.wfa(tmp0_desc, 2) ? true : !equals(value.hgg_1, toGameUnit(1))) {
      tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.hgg_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 3) ? true : !equals(value.igg_1, toGameUnit(1))) {
      tmp1_output.qfa(tmp0_desc, 3, $serializer_getInstance_15(), new GameUnit(value.igg_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !value.jgg_1.equals(MapEntityType_SMALL_BLOCKER_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 4, tmp2_cached[4], value.jgg_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !(value.kgg_1 === 'Small Blocker')) {
      tmp1_output.ofa(tmp0_desc, 5, value.kgg_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !(value.lgg_1 === true)) {
      tmp1_output.gfa(tmp0_desc, 6, value.lgg_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_12).rf5 = function (encoder, value) {
    return this.egg(encoder, value instanceof SmallBlocker ? value : THROW_CCE());
  };
  var $serializer_instance_12;
  function $serializer_getInstance_12() {
    if ($serializer_instance_12 == null)
      new $serializer_12();
    return $serializer_instance_12;
  }
  function SmallBlocker_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, $this) {
    if (!(3 === (3 & seen1))) {
      throwMissingFieldException(seen1, 3, $serializer_getInstance_12().dgg_1);
    }
    MapEntity.call($this);
    $this.fgg_1 = x;
    $this.ggg_1 = y;
    if (0 === (seen1 & 4))
      $this.hgg_1 = toGameUnit(1);
    else
      $this.hgg_1 = width;
    if (0 === (seen1 & 8))
      $this.igg_1 = toGameUnit(1);
    else
      $this.igg_1 = height;
    if (0 === (seen1 & 16))
      $this.jgg_1 = MapEntityType_SMALL_BLOCKER_getInstance();
    else
      $this.jgg_1 = type;
    if (0 === (seen1 & 32))
      $this.kgg_1 = 'Small Blocker';
    else
      $this.kgg_1 = friendlyName;
    if (0 === (seen1 & 64))
      $this.lgg_1 = true;
    else
      $this.lgg_1 = isBlockingEntity;
    return $this;
  }
  function SmallBlocker_init_$Create$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker) {
    return SmallBlocker_init_$Init$(seen1, x, y, width, height, type, friendlyName, isBlockingEntity, serializationConstructorMarker, objectCreate(protoOf(SmallBlocker)));
  }
  function Companion_18() {
    Companion_instance_18 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, null, null, null, null, null, new EnumSerializer('com.xenotactic.gamelogic.model.MapEntityType', values()), null, null];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.mgg_1 = tmp$ret$2;
  }
  var Companion_instance_18;
  function Companion_getInstance_30() {
    if (Companion_instance_18 == null)
      new Companion_18();
    return Companion_instance_18;
  }
  function $serializer_13() {
    $serializer_instance_13 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.MapEntity.SpeedArea', this, 9);
    tmp0_serialDesc.cfg('x', false);
    tmp0_serialDesc.cfg('y', false);
    tmp0_serialDesc.cfg('radius', false);
    tmp0_serialDesc.cfg('speedEffect', false);
    tmp0_serialDesc.cfg('width', true);
    tmp0_serialDesc.cfg('height', true);
    tmp0_serialDesc.cfg('type', true);
    tmp0_serialDesc.cfg('isBlockingEntity', true);
    tmp0_serialDesc.cfg('friendlyName', true);
    this.ngg_1 = tmp0_serialDesc;
  }
  protoOf($serializer_13).qf5 = function () {
    return this.ngg_1;
  };
  protoOf($serializer_13).rfg = function () {
    var tmp0_cached = Companion_getInstance_30().mgg_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [$serializer_getInstance_15(), $serializer_getInstance_15(), $serializer_getInstance_15(), DoubleSerializer_getInstance(), $serializer_getInstance_15(), $serializer_getInstance_15(), tmp0_cached[6], BooleanSerializer_getInstance(), StringSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_13).sf5 = function (decoder) {
    var tmp0_desc = this.ngg_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_local2 = null;
    var tmp7_local3 = 0.0;
    var tmp8_local4 = null;
    var tmp9_local5 = null;
    var tmp10_local6 = null;
    var tmp11_local7 = false;
    var tmp12_local8 = null;
    var tmp13_input = decoder.zf8(tmp0_desc);
    var tmp14_cached = Companion_getInstance_30().mgg_1;
    if (tmp13_input.pf9()) {
      tmp4_local0 = tmp13_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp13_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
      tmp6_local2 = tmp13_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
      tmp3_bitMask0 = tmp3_bitMask0 | 4;
      tmp7_local3 = tmp13_input.hf9(tmp0_desc, 3);
      tmp3_bitMask0 = tmp3_bitMask0 | 8;
      tmp8_local4 = tmp13_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
      tmp3_bitMask0 = tmp3_bitMask0 | 16;
      tmp9_local5 = tmp13_input.lf9(tmp0_desc, 5, $serializer_getInstance_15(), tmp9_local5);
      tmp3_bitMask0 = tmp3_bitMask0 | 32;
      tmp10_local6 = tmp13_input.lf9(tmp0_desc, 6, tmp14_cached[6], tmp10_local6);
      tmp3_bitMask0 = tmp3_bitMask0 | 64;
      tmp11_local7 = tmp13_input.bf9(tmp0_desc, 7);
      tmp3_bitMask0 = tmp3_bitMask0 | 128;
      tmp12_local8 = tmp13_input.jf9(tmp0_desc, 8);
      tmp3_bitMask0 = tmp3_bitMask0 | 256;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp13_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp13_input.lf9(tmp0_desc, 0, $serializer_getInstance_15(), tmp4_local0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp13_input.lf9(tmp0_desc, 1, $serializer_getInstance_15(), tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          case 2:
            tmp6_local2 = tmp13_input.lf9(tmp0_desc, 2, $serializer_getInstance_15(), tmp6_local2);
            tmp3_bitMask0 = tmp3_bitMask0 | 4;
            break;
          case 3:
            tmp7_local3 = tmp13_input.hf9(tmp0_desc, 3);
            tmp3_bitMask0 = tmp3_bitMask0 | 8;
            break;
          case 4:
            tmp8_local4 = tmp13_input.lf9(tmp0_desc, 4, $serializer_getInstance_15(), tmp8_local4);
            tmp3_bitMask0 = tmp3_bitMask0 | 16;
            break;
          case 5:
            tmp9_local5 = tmp13_input.lf9(tmp0_desc, 5, $serializer_getInstance_15(), tmp9_local5);
            tmp3_bitMask0 = tmp3_bitMask0 | 32;
            break;
          case 6:
            tmp10_local6 = tmp13_input.lf9(tmp0_desc, 6, tmp14_cached[6], tmp10_local6);
            tmp3_bitMask0 = tmp3_bitMask0 | 64;
            break;
          case 7:
            tmp11_local7 = tmp13_input.bf9(tmp0_desc, 7);
            tmp3_bitMask0 = tmp3_bitMask0 | 128;
            break;
          case 8:
            tmp12_local8 = tmp13_input.jf9(tmp0_desc, 8);
            tmp3_bitMask0 = tmp3_bitMask0 | 256;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp13_input.af9(tmp0_desc);
    var tmp = tmp3_bitMask0;
    var tmp_0 = tmp4_local0;
    var tmp_1 = tmp_0 == null ? null : tmp_0.vg3_1;
    var tmp_2 = tmp5_local1;
    var tmp_3 = tmp_2 == null ? null : tmp_2.vg3_1;
    var tmp_4 = tmp6_local2;
    var tmp_5 = tmp_4 == null ? null : tmp_4.vg3_1;
    var tmp_6 = tmp7_local3;
    var tmp_7 = tmp8_local4;
    var tmp_8 = tmp_7 == null ? null : tmp_7.vg3_1;
    var tmp_9 = tmp9_local5;
    return SpeedArea_init_$Create$(tmp, tmp_1, tmp_3, tmp_5, tmp_6, tmp_8, tmp_9 == null ? null : tmp_9.vg3_1, tmp10_local6, tmp11_local7, tmp12_local8, null);
  };
  protoOf($serializer_13).ogg = function (encoder, value) {
    var tmp0_desc = this.ngg_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_30().mgg_1;
    tmp1_output.qfa(tmp0_desc, 0, $serializer_getInstance_15(), new GameUnit(value.pgg_1));
    tmp1_output.qfa(tmp0_desc, 1, $serializer_getInstance_15(), new GameUnit(value.qgg_1));
    tmp1_output.qfa(tmp0_desc, 2, $serializer_getInstance_15(), new GameUnit(value.rgg_1));
    tmp1_output.mfa(tmp0_desc, 3, value.sgg_1);
    if (tmp1_output.wfa(tmp0_desc, 4) ? true : !equals(value.tgg_1, GameUnit__times_impl_soo0d9(value.rgg_1, 2))) {
      tmp1_output.qfa(tmp0_desc, 4, $serializer_getInstance_15(), new GameUnit(value.tgg_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 5) ? true : !equals(value.ugg_1, GameUnit__times_impl_soo0d9(value.rgg_1, 2))) {
      tmp1_output.qfa(tmp0_desc, 5, $serializer_getInstance_15(), new GameUnit(value.ugg_1));
    }
    if (tmp1_output.wfa(tmp0_desc, 6) ? true : !value.vgg_1.equals(MapEntityType_SPEED_AREA_getInstance())) {
      tmp1_output.qfa(tmp0_desc, 6, tmp2_cached[6], value.vgg_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 7) ? true : !(value.wgg_1 === false)) {
      tmp1_output.gfa(tmp0_desc, 7, value.wgg_1);
    }
    if (tmp1_output.wfa(tmp0_desc, 8) ? true : !(value.xgg_1 === 'Speed Area')) {
      tmp1_output.ofa(tmp0_desc, 8, value.xgg_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_13).rf5 = function (encoder, value) {
    return this.ogg(encoder, value instanceof SpeedArea ? value : THROW_CCE());
  };
  var $serializer_instance_13;
  function $serializer_getInstance_13() {
    if ($serializer_instance_13 == null)
      new $serializer_13();
    return $serializer_instance_13;
  }
  function SpeedArea_init_$Init$(seen1, x, y, radius, speedEffect, width, height, type, isBlockingEntity, friendlyName, serializationConstructorMarker, $this) {
    if (!(15 === (15 & seen1))) {
      throwMissingFieldException(seen1, 15, $serializer_getInstance_13().ngg_1);
    }
    MapEntity.call($this);
    $this.pgg_1 = x;
    $this.qgg_1 = y;
    $this.rgg_1 = radius;
    $this.sgg_1 = speedEffect;
    if (0 === (seen1 & 16))
      $this.tgg_1 = GameUnit__times_impl_soo0d9($this.rgg_1, 2);
    else
      $this.tgg_1 = width;
    if (0 === (seen1 & 32))
      $this.ugg_1 = GameUnit__times_impl_soo0d9($this.rgg_1, 2);
    else
      $this.ugg_1 = height;
    if (0 === (seen1 & 64))
      $this.vgg_1 = MapEntityType_SPEED_AREA_getInstance();
    else
      $this.vgg_1 = type;
    if (0 === (seen1 & 128))
      $this.wgg_1 = false;
    else
      $this.wgg_1 = isBlockingEntity;
    if (0 === (seen1 & 256))
      $this.xgg_1 = 'Speed Area';
    else
      $this.xgg_1 = friendlyName;
    return $this;
  }
  function SpeedArea_init_$Create$(seen1, x, y, radius, speedEffect, width, height, type, isBlockingEntity, friendlyName, serializationConstructorMarker) {
    return SpeedArea_init_$Init$(seen1, x, y, radius, speedEffect, width, height, type, isBlockingEntity, friendlyName, serializationConstructorMarker, objectCreate(protoOf(SpeedArea)));
  }
  function Start(x, y) {
    Companion_getInstance_21();
    MapEntity.call(this);
    this.qgd_1 = x;
    this.rgd_1 = y;
    this.sgd_1 = toGameUnit(2);
    this.tgd_1 = toGameUnit(2);
    this.ugd_1 = MapEntityType_START_getInstance();
    this.vgd_1 = 'Start';
    this.wgd_1 = false;
  }
  protoOf(Start).jg8 = function () {
    return this.qgd_1;
  };
  protoOf(Start).kg8 = function () {
    return this.rgd_1;
  };
  protoOf(Start).lg8 = function () {
    return this.sgd_1;
  };
  protoOf(Start).mg8 = function () {
    return this.tgd_1;
  };
  protoOf(Start).c7d = function () {
    return this.ugd_1;
  };
  protoOf(Start).ygg = function () {
    return this.vgd_1;
  };
  protoOf(Start).sga = function () {
    return this.wgd_1;
  };
  protoOf(Start).toString = function () {
    return 'Start(x=' + new GameUnit(this.qgd_1) + ', y=' + new GameUnit(this.rgd_1) + ')';
  };
  protoOf(Start).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.qgd_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.rgd_1) | 0;
    return result;
  };
  protoOf(Start).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Start))
      return false;
    var tmp0_other_with_cast = other instanceof Start ? other : THROW_CCE();
    if (!equals(this.qgd_1, tmp0_other_with_cast.qgd_1))
      return false;
    if (!equals(this.rgd_1, tmp0_other_with_cast.rgd_1))
      return false;
    return true;
  };
  function Finish(x, y) {
    Companion_getInstance_22();
    MapEntity.call(this);
    this.age_1 = x;
    this.bge_1 = y;
    this.cge_1 = toGameUnit(2);
    this.dge_1 = toGameUnit(2);
    this.ege_1 = MapEntityType_FINISH_getInstance();
    this.fge_1 = 'Finish';
    this.gge_1 = false;
  }
  protoOf(Finish).jg8 = function () {
    return this.age_1;
  };
  protoOf(Finish).kg8 = function () {
    return this.bge_1;
  };
  protoOf(Finish).lg8 = function () {
    return this.cge_1;
  };
  protoOf(Finish).mg8 = function () {
    return this.dge_1;
  };
  protoOf(Finish).c7d = function () {
    return this.ege_1;
  };
  protoOf(Finish).ygg = function () {
    return this.fge_1;
  };
  protoOf(Finish).sga = function () {
    return this.gge_1;
  };
  protoOf(Finish).toString = function () {
    return 'Finish(x=' + new GameUnit(this.age_1) + ', y=' + new GameUnit(this.bge_1) + ')';
  };
  protoOf(Finish).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.age_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.bge_1) | 0;
    return result;
  };
  protoOf(Finish).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Finish))
      return false;
    var tmp0_other_with_cast = other instanceof Finish ? other : THROW_CCE();
    if (!equals(this.age_1, tmp0_other_with_cast.age_1))
      return false;
    if (!equals(this.bge_1, tmp0_other_with_cast.bge_1))
      return false;
    return true;
  };
  function TeleportIn(sequenceNumber, x, y) {
    Companion_getInstance_23();
    MapEntity.call(this);
    this.zg9_1 = sequenceNumber;
    this.aga_1 = x;
    this.bga_1 = y;
    this.cga_1 = toGameUnit(2);
    this.dga_1 = toGameUnit(2);
    this.ega_1 = MapEntityType_TELEPORT_IN_getInstance();
    this.fga_1 = 'Teleport In ' + this.zg9_1;
    this.gga_1 = false;
    this.hga_1 = 1.0;
  }
  protoOf(TeleportIn).jg8 = function () {
    return this.aga_1;
  };
  protoOf(TeleportIn).kg8 = function () {
    return this.bga_1;
  };
  protoOf(TeleportIn).lg8 = function () {
    return this.cga_1;
  };
  protoOf(TeleportIn).mg8 = function () {
    return this.dga_1;
  };
  protoOf(TeleportIn).c7d = function () {
    return this.ega_1;
  };
  protoOf(TeleportIn).ygg = function () {
    return this.fga_1;
  };
  protoOf(TeleportIn).sga = function () {
    return this.gga_1;
  };
  protoOf(TeleportIn).toString = function () {
    return 'TeleportIn(sequenceNumber=' + this.zg9_1 + ', x=' + new GameUnit(this.aga_1) + ', y=' + new GameUnit(this.bga_1) + ')';
  };
  protoOf(TeleportIn).hashCode = function () {
    var result = this.zg9_1;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.aga_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.bga_1) | 0;
    return result;
  };
  protoOf(TeleportIn).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportIn))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportIn ? other : THROW_CCE();
    if (!(this.zg9_1 === tmp0_other_with_cast.zg9_1))
      return false;
    if (!equals(this.aga_1, tmp0_other_with_cast.aga_1))
      return false;
    if (!equals(this.bga_1, tmp0_other_with_cast.bga_1))
      return false;
    return true;
  };
  function TeleportOut(sequenceNumber, x, y) {
    Companion_getInstance_24();
    MapEntity.call(this);
    this.kga_1 = sequenceNumber;
    this.lga_1 = x;
    this.mga_1 = y;
    this.nga_1 = toGameUnit(2);
    this.oga_1 = toGameUnit(2);
    this.pga_1 = MapEntityType_TELEPORT_OUT_getInstance();
    this.qga_1 = 'Teleport Out ' + this.kga_1;
    this.rga_1 = false;
  }
  protoOf(TeleportOut).jg8 = function () {
    return this.lga_1;
  };
  protoOf(TeleportOut).kg8 = function () {
    return this.mga_1;
  };
  protoOf(TeleportOut).lg8 = function () {
    return this.nga_1;
  };
  protoOf(TeleportOut).mg8 = function () {
    return this.oga_1;
  };
  protoOf(TeleportOut).c7d = function () {
    return this.pga_1;
  };
  protoOf(TeleportOut).ygg = function () {
    return this.qga_1;
  };
  protoOf(TeleportOut).sga = function () {
    return this.rga_1;
  };
  protoOf(TeleportOut).toString = function () {
    return 'TeleportOut(sequenceNumber=' + this.kga_1 + ', x=' + new GameUnit(this.lga_1) + ', y=' + new GameUnit(this.mga_1) + ')';
  };
  protoOf(TeleportOut).hashCode = function () {
    var result = this.kga_1;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.lga_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.mga_1) | 0;
    return result;
  };
  protoOf(TeleportOut).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportOut))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportOut ? other : THROW_CCE();
    if (!(this.kga_1 === tmp0_other_with_cast.kga_1))
      return false;
    if (!equals(this.lga_1, tmp0_other_with_cast.lga_1))
      return false;
    if (!equals(this.mga_1, tmp0_other_with_cast.mga_1))
      return false;
    return true;
  };
  function Rock(x, y, width, height) {
    Companion_getInstance_25();
    MapEntity.call(this);
    this.qge_1 = x;
    this.rge_1 = y;
    this.sge_1 = width;
    this.tge_1 = height;
    this.uge_1 = MapEntityType_ROCK_getInstance();
    this.vge_1 = 'Rock (' + new GameUnit(this.sge_1) + 'x' + new GameUnit(this.tge_1) + ')';
    this.wge_1 = true;
    // Inline function 'kotlin.require' call
    var tmp0_require = GameUnit__compareTo_impl_qyacwt(this.sge_1, 0) >= 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = GameUnit__compareTo_impl_qyacwt(this.tge_1, 0) >= 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
  }
  protoOf(Rock).jg8 = function () {
    return this.qge_1;
  };
  protoOf(Rock).kg8 = function () {
    return this.rge_1;
  };
  protoOf(Rock).lg8 = function () {
    return this.sge_1;
  };
  protoOf(Rock).mg8 = function () {
    return this.tge_1;
  };
  protoOf(Rock).c7d = function () {
    return this.uge_1;
  };
  protoOf(Rock).ygg = function () {
    return this.vge_1;
  };
  protoOf(Rock).sga = function () {
    return this.wge_1;
  };
  protoOf(Rock).toString = function () {
    return 'Rock(x=' + new GameUnit(this.qge_1) + ', y=' + new GameUnit(this.rge_1) + ', width=' + new GameUnit(this.sge_1) + ', height=' + new GameUnit(this.tge_1) + ')';
  };
  protoOf(Rock).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.qge_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.rge_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.sge_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.tge_1) | 0;
    return result;
  };
  protoOf(Rock).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Rock))
      return false;
    var tmp0_other_with_cast = other instanceof Rock ? other : THROW_CCE();
    if (!equals(this.qge_1, tmp0_other_with_cast.qge_1))
      return false;
    if (!equals(this.rge_1, tmp0_other_with_cast.rge_1))
      return false;
    if (!equals(this.sge_1, tmp0_other_with_cast.sge_1))
      return false;
    if (!equals(this.tge_1, tmp0_other_with_cast.tge_1))
      return false;
    return true;
  };
  function Checkpoint(sequenceNumber, x, y) {
    Companion_getInstance_26();
    MapEntity.call(this);
    this.agf_1 = sequenceNumber;
    this.bgf_1 = x;
    this.cgf_1 = y;
    this.dgf_1 = toGameUnit(2);
    this.egf_1 = toGameUnit(2);
    this.fgf_1 = MapEntityType_CHECKPOINT_getInstance();
    this.ggf_1 = 'Checkpoint ' + this.agf_1;
    this.hgf_1 = false;
  }
  protoOf(Checkpoint).jg8 = function () {
    return this.bgf_1;
  };
  protoOf(Checkpoint).kg8 = function () {
    return this.cgf_1;
  };
  protoOf(Checkpoint).lg8 = function () {
    return this.dgf_1;
  };
  protoOf(Checkpoint).mg8 = function () {
    return this.egf_1;
  };
  protoOf(Checkpoint).c7d = function () {
    return this.fgf_1;
  };
  protoOf(Checkpoint).ygg = function () {
    return this.ggf_1;
  };
  protoOf(Checkpoint).sga = function () {
    return this.hgf_1;
  };
  protoOf(Checkpoint).toString = function () {
    return 'Checkpoint(sequenceNumber=' + this.agf_1 + ', x=' + new GameUnit(this.bgf_1) + ', y=' + new GameUnit(this.cgf_1) + ')';
  };
  protoOf(Checkpoint).hashCode = function () {
    var result = this.agf_1;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.bgf_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.cgf_1) | 0;
    return result;
  };
  protoOf(Checkpoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Checkpoint))
      return false;
    var tmp0_other_with_cast = other instanceof Checkpoint ? other : THROW_CCE();
    if (!(this.agf_1 === tmp0_other_with_cast.agf_1))
      return false;
    if (!equals(this.bgf_1, tmp0_other_with_cast.bgf_1))
      return false;
    if (!equals(this.cgf_1, tmp0_other_with_cast.cgf_1))
      return false;
    return true;
  };
  function Tower(x, y) {
    Companion_getInstance_27();
    MapEntity.call(this);
    this.lgf_1 = x;
    this.mgf_1 = y;
    this.ngf_1 = toGameUnit(2);
    this.ogf_1 = toGameUnit(2);
    this.pgf_1 = MapEntityType_TOWER_getInstance();
    this.qgf_1 = 'Tower';
    this.rgf_1 = true;
  }
  protoOf(Tower).jg8 = function () {
    return this.lgf_1;
  };
  protoOf(Tower).kg8 = function () {
    return this.mgf_1;
  };
  protoOf(Tower).lg8 = function () {
    return this.ngf_1;
  };
  protoOf(Tower).mg8 = function () {
    return this.ogf_1;
  };
  protoOf(Tower).c7d = function () {
    return this.pgf_1;
  };
  protoOf(Tower).ygg = function () {
    return this.qgf_1;
  };
  protoOf(Tower).sga = function () {
    return this.rgf_1;
  };
  protoOf(Tower).toString = function () {
    return 'Tower(x=' + new GameUnit(this.lgf_1) + ', y=' + new GameUnit(this.mgf_1) + ')';
  };
  protoOf(Tower).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.lgf_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.mgf_1) | 0;
    return result;
  };
  protoOf(Tower).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Tower))
      return false;
    var tmp0_other_with_cast = other instanceof Tower ? other : THROW_CCE();
    if (!equals(this.lgf_1, tmp0_other_with_cast.lgf_1))
      return false;
    if (!equals(this.mgf_1, tmp0_other_with_cast.mgf_1))
      return false;
    return true;
  };
  function SupplyDepot(x, y) {
    Companion_getInstance_28();
    MapEntity.call(this);
    this.vgf_1 = x;
    this.wgf_1 = y;
    this.xgf_1 = toGameUnit(2);
    this.ygf_1 = toGameUnit(2);
    this.zgf_1 = MapEntityType_SUPPLY_DEPOT_getInstance();
    this.agg_1 = 'Supply depot';
    this.bgg_1 = true;
  }
  protoOf(SupplyDepot).jg8 = function () {
    return this.vgf_1;
  };
  protoOf(SupplyDepot).kg8 = function () {
    return this.wgf_1;
  };
  protoOf(SupplyDepot).lg8 = function () {
    return this.xgf_1;
  };
  protoOf(SupplyDepot).mg8 = function () {
    return this.ygf_1;
  };
  protoOf(SupplyDepot).c7d = function () {
    return this.zgf_1;
  };
  protoOf(SupplyDepot).ygg = function () {
    return this.agg_1;
  };
  protoOf(SupplyDepot).sga = function () {
    return this.bgg_1;
  };
  protoOf(SupplyDepot).toString = function () {
    return 'SupplyDepot(x=' + new GameUnit(this.vgf_1) + ', y=' + new GameUnit(this.wgf_1) + ')';
  };
  protoOf(SupplyDepot).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.vgf_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.wgf_1) | 0;
    return result;
  };
  protoOf(SupplyDepot).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SupplyDepot))
      return false;
    var tmp0_other_with_cast = other instanceof SupplyDepot ? other : THROW_CCE();
    if (!equals(this.vgf_1, tmp0_other_with_cast.vgf_1))
      return false;
    if (!equals(this.wgf_1, tmp0_other_with_cast.wgf_1))
      return false;
    return true;
  };
  function SmallBlocker(x, y) {
    Companion_getInstance_29();
    MapEntity.call(this);
    this.fgg_1 = x;
    this.ggg_1 = y;
    this.hgg_1 = toGameUnit(1);
    this.igg_1 = toGameUnit(1);
    this.jgg_1 = MapEntityType_SMALL_BLOCKER_getInstance();
    this.kgg_1 = 'Small Blocker';
    this.lgg_1 = true;
  }
  protoOf(SmallBlocker).jg8 = function () {
    return this.fgg_1;
  };
  protoOf(SmallBlocker).kg8 = function () {
    return this.ggg_1;
  };
  protoOf(SmallBlocker).lg8 = function () {
    return this.hgg_1;
  };
  protoOf(SmallBlocker).mg8 = function () {
    return this.igg_1;
  };
  protoOf(SmallBlocker).c7d = function () {
    return this.jgg_1;
  };
  protoOf(SmallBlocker).ygg = function () {
    return this.kgg_1;
  };
  protoOf(SmallBlocker).sga = function () {
    return this.lgg_1;
  };
  protoOf(SmallBlocker).toString = function () {
    return 'SmallBlocker(x=' + new GameUnit(this.fgg_1) + ', y=' + new GameUnit(this.ggg_1) + ')';
  };
  protoOf(SmallBlocker).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.fgg_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ggg_1) | 0;
    return result;
  };
  protoOf(SmallBlocker).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SmallBlocker))
      return false;
    var tmp0_other_with_cast = other instanceof SmallBlocker ? other : THROW_CCE();
    if (!equals(this.fgg_1, tmp0_other_with_cast.fgg_1))
      return false;
    if (!equals(this.ggg_1, tmp0_other_with_cast.ggg_1))
      return false;
    return true;
  };
  function SpeedArea(x, y, radius, speedEffect) {
    Companion_getInstance_30();
    MapEntity.call(this);
    this.pgg_1 = x;
    this.qgg_1 = y;
    this.rgg_1 = radius;
    this.sgg_1 = speedEffect;
    this.tgg_1 = GameUnit__times_impl_soo0d9(this.rgg_1, 2);
    this.ugg_1 = GameUnit__times_impl_soo0d9(this.rgg_1, 2);
    this.vgg_1 = MapEntityType_SPEED_AREA_getInstance();
    this.wgg_1 = false;
    this.xgg_1 = 'Speed Area';
  }
  protoOf(SpeedArea).jg8 = function () {
    return this.pgg_1;
  };
  protoOf(SpeedArea).kg8 = function () {
    return this.qgg_1;
  };
  protoOf(SpeedArea).lg8 = function () {
    return this.tgg_1;
  };
  protoOf(SpeedArea).mg8 = function () {
    return this.ugg_1;
  };
  protoOf(SpeedArea).c7d = function () {
    return this.vgg_1;
  };
  protoOf(SpeedArea).sga = function () {
    return this.wgg_1;
  };
  protoOf(SpeedArea).ygg = function () {
    return this.xgg_1;
  };
  protoOf(SpeedArea).toString = function () {
    return 'SpeedArea(x=' + new GameUnit(this.pgg_1) + ', y=' + new GameUnit(this.qgg_1) + ', radius=' + new GameUnit(this.rgg_1) + ', speedEffect=' + this.sgg_1 + ')';
  };
  protoOf(SpeedArea).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.pgg_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.qgg_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.rgg_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.sgg_1) | 0;
    return result;
  };
  protoOf(SpeedArea).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SpeedArea))
      return false;
    var tmp0_other_with_cast = other instanceof SpeedArea ? other : THROW_CCE();
    if (!equals(this.pgg_1, tmp0_other_with_cast.pgg_1))
      return false;
    if (!equals(this.qgg_1, tmp0_other_with_cast.qgg_1))
      return false;
    if (!equals(this.rgg_1, tmp0_other_with_cast.rgg_1))
      return false;
    if (!equals(this.sgg_1, tmp0_other_with_cast.sgg_1))
      return false;
    return true;
  };
  function Companion_19() {
    Companion_instance_19 = this;
    this.fgh_1 = new Checkpoint(0, toGameUnit(0), toGameUnit(0));
    this.ggh_1 = new TeleportIn(0, toGameUnit(0), toGameUnit(0));
    this.hgh_1 = new TeleportOut(0, toGameUnit(0), toGameUnit(0));
    this.igh_1 = new Rock(toGameUnit(0), toGameUnit(0), toGameUnit(1), toGameUnit(1));
    this.jgh_1 = new Rock(toGameUnit(0), toGameUnit(0), toGameUnit(2), toGameUnit(4));
    this.kgh_1 = new Rock(toGameUnit(0), toGameUnit(0), toGameUnit(4), toGameUnit(2));
    this.lgh_1 = new Tower(toGameUnit(0), toGameUnit(0));
    this.mgh_1 = new Start(toGameUnit(0), toGameUnit(0));
    this.ngh_1 = new Finish(toGameUnit(0), toGameUnit(0));
  }
  var Companion_instance_19;
  function Companion_getInstance_31() {
    if (Companion_instance_19 == null)
      new Companion_19();
    return Companion_instance_19;
  }
  function MapEntity() {
    Companion_getInstance_31();
  }
  protoOf(MapEntity).zgg = function () {
    return new GameUnitTuple(this.jg8(), this.kg8());
  };
  protoOf(MapEntity).agh = function () {
    return GameUnit__plus_impl_rh1b6r(this.zgg().eg6_1, this.mg8());
  };
  protoOf(MapEntity).bgh = function () {
    return GameUnit__plus_impl_rh1b6r(this.zgg().dg6_1, this.lg8());
  };
  protoOf(MapEntity).cgh = function (p) {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject instanceof Checkpoint) {
      tmp = new Checkpoint(this.agf_1, p.dg6_1, p.eg6_1);
    } else {
      if (tmp0_subject instanceof Finish) {
        tmp = new Finish(p.dg6_1, p.eg6_1);
      } else {
        if (tmp0_subject instanceof Rock) {
          tmp = new Rock(p.dg6_1, p.eg6_1, this.lg8(), this.mg8());
        } else {
          if (tmp0_subject instanceof Start) {
            tmp = new Start(p.dg6_1, p.eg6_1);
          } else {
            if (tmp0_subject instanceof Tower) {
              tmp = new Tower(p.dg6_1, p.eg6_1);
            } else {
              if (tmp0_subject instanceof TeleportIn) {
                tmp = new TeleportIn(this.zg9_1, p.dg6_1, p.eg6_1);
              } else {
                if (tmp0_subject instanceof TeleportOut) {
                  tmp = new TeleportOut(this.kga_1, p.dg6_1, p.eg6_1);
                } else {
                  if (tmp0_subject instanceof SmallBlocker) {
                    tmp = new SmallBlocker(p.dg6_1, p.eg6_1);
                  } else {
                    if (tmp0_subject instanceof SpeedArea) {
                      tmp = new SpeedArea(p.dg6_1, p.eg6_1, this.rgg_1, this.sgg_1);
                    } else {
                      if (tmp0_subject instanceof SupplyDepot) {
                        tmp = SupplyDepot_init_$Create$(p);
                      } else {
                        noWhenBranchMatchedException();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  protoOf(MapEntity).dgh = function () {
    return new GRectInt(this.jg8(), this.kg8(), this.lg8(), this.mg8());
  };
  protoOf(MapEntity).rg9 = function (unitX, unitY) {
    var tmp;
    var tmp_0;
    if (((GameUnit__compareTo_impl_qyacwt_0(unitX, this.jg8()) >= 0 ? GameUnit__compareTo_impl_qyacwt_0(unitX, this.bgh()) <= 0 : false) ? GameUnit__compareTo_impl_qyacwt_0(unitY, this.kg8()) >= 0 : false) ? GameUnit__compareTo_impl_qyacwt_0(unitY, this.agh()) <= 0 : false) {
      var tmp$ret$1;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.model.MapEntity.intersectsUnitBlock.<anonymous>' call
      var unitRightX = GameUnit__plus_impl_rh1b6r_0(unitX, 1);
      tmp$ret$0 = GameUnit__compareTo_impl_qyacwt_0(unitRightX, this.jg8()) >= 0 ? GameUnit__compareTo_impl_qyacwt_0(unitRightX, this.bgh()) <= 0 : false;
      tmp$ret$1 = tmp$ret$0;
      tmp_0 = tmp$ret$1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$3;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$2;
      // Inline function 'com.xenotactic.gamelogic.model.MapEntity.intersectsUnitBlock.<anonymous>' call
      var unitTopY = GameUnit__plus_impl_rh1b6r_0(unitY, 1);
      tmp$ret$2 = GameUnit__compareTo_impl_qyacwt_0(unitTopY, this.kg8()) >= 0 ? GameUnit__compareTo_impl_qyacwt_0(unitTopY, this.agh()) <= 0 : false;
      tmp$ret$3 = tmp$ret$2;
      tmp = tmp$ret$3;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(MapEntity).egh = function () {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject instanceof Checkpoint) {
      tmp = new Checkpoint_0(this.agf_1);
    } else {
      if (tmp0_subject instanceof Finish) {
        tmp = Finish_getInstance();
      } else {
        if (tmp0_subject instanceof Rock) {
          tmp = Rock_getInstance();
        } else {
          if (tmp0_subject instanceof SmallBlocker) {
            tmp = SmallBlocker_getInstance();
          } else {
            if (tmp0_subject instanceof SpeedArea) {
              tmp = new SpeedArea_0(this.rgg_1, this.sgg_1);
            } else {
              if (tmp0_subject instanceof Start) {
                tmp = Start_getInstance();
              } else {
                if (tmp0_subject instanceof TeleportIn) {
                  tmp = new TeleportIn_0(this.zg9_1);
                } else {
                  if (tmp0_subject instanceof TeleportOut) {
                    tmp = new TeleportOut_0(this.kga_1);
                  } else {
                    if (tmp0_subject instanceof Tower) {
                      tmp = Tower_getInstance();
                    } else {
                      if (tmp0_subject instanceof SupplyDepot) {
                        tmp = SupplyDepot_getInstance();
                      } else {
                        noWhenBranchMatchedException();
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  function TeleportPair(teleportIn, teleportOut, sequenceNumber) {
    this.ogh_1 = teleportIn;
    this.pgh_1 = teleportOut;
    this.qgh_1 = sequenceNumber;
  }
  protoOf(TeleportPair).toString = function () {
    return 'TeleportPair(teleportIn=' + this.ogh_1 + ', teleportOut=' + this.pgh_1 + ', sequenceNumber=' + this.qgh_1 + ')';
  };
  protoOf(TeleportPair).hashCode = function () {
    var result = hashCode(this.ogh_1);
    result = imul(result, 31) + hashCode(this.pgh_1) | 0;
    result = imul(result, 31) + this.qgh_1 | 0;
    return result;
  };
  protoOf(TeleportPair).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportPair))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportPair ? other : THROW_CCE();
    if (!equals(this.ogh_1, tmp0_other_with_cast.ogh_1))
      return false;
    if (!equals(this.pgh_1, tmp0_other_with_cast.pgh_1))
      return false;
    if (!(this.qgh_1 === tmp0_other_with_cast.qgh_1))
      return false;
    return true;
  };
  function Start_0() {
    Start_instance = this;
    MapEntityData.call(this);
  }
  var Start_instance;
  function Start_getInstance() {
    if (Start_instance == null)
      new Start_0();
    return Start_instance;
  }
  function Finish_0() {
    Finish_instance = this;
    MapEntityData.call(this);
  }
  var Finish_instance;
  function Finish_getInstance() {
    if (Finish_instance == null)
      new Finish_0();
    return Finish_instance;
  }
  function Rock_0() {
    Rock_instance = this;
    MapEntityData.call(this);
  }
  var Rock_instance;
  function Rock_getInstance() {
    if (Rock_instance == null)
      new Rock_0();
    return Rock_instance;
  }
  function Tower_0() {
    Tower_instance = this;
    MapEntityData.call(this);
  }
  var Tower_instance;
  function Tower_getInstance() {
    if (Tower_instance == null)
      new Tower_0();
    return Tower_instance;
  }
  function SupplyDepot_0() {
    SupplyDepot_instance = this;
    MapEntityData.call(this);
  }
  var SupplyDepot_instance;
  function SupplyDepot_getInstance() {
    if (SupplyDepot_instance == null)
      new SupplyDepot_0();
    return SupplyDepot_instance;
  }
  function SmallBlocker_0() {
    SmallBlocker_instance = this;
    MapEntityData.call(this);
  }
  var SmallBlocker_instance;
  function SmallBlocker_getInstance() {
    if (SmallBlocker_instance == null)
      new SmallBlocker_0();
    return SmallBlocker_instance;
  }
  function TeleportIn_0(sequenceNumber) {
    MapEntityData.call(this);
    this.sgh_1 = sequenceNumber;
  }
  protoOf(TeleportIn_0).eg4 = function () {
    return this.sgh_1 + 1 | 0;
  };
  protoOf(TeleportIn_0).toString = function () {
    return 'TeleportIn(sequenceNumber=' + this.sgh_1 + ')';
  };
  protoOf(TeleportIn_0).hashCode = function () {
    return this.sgh_1;
  };
  protoOf(TeleportIn_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportIn_0))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportIn_0 ? other : THROW_CCE();
    if (!(this.sgh_1 === tmp0_other_with_cast.sgh_1))
      return false;
    return true;
  };
  function TeleportOut_0(sequenceNumber) {
    MapEntityData.call(this);
    this.tgh_1 = sequenceNumber;
  }
  protoOf(TeleportOut_0).eg4 = function () {
    return this.tgh_1 + 1 | 0;
  };
  protoOf(TeleportOut_0).toString = function () {
    return 'TeleportOut(sequenceNumber=' + this.tgh_1 + ')';
  };
  protoOf(TeleportOut_0).hashCode = function () {
    return this.tgh_1;
  };
  protoOf(TeleportOut_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportOut_0))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportOut_0 ? other : THROW_CCE();
    if (!(this.tgh_1 === tmp0_other_with_cast.tgh_1))
      return false;
    return true;
  };
  function Checkpoint_0(sequenceNumber) {
    MapEntityData.call(this);
    this.ugh_1 = sequenceNumber;
  }
  protoOf(Checkpoint_0).eg4 = function () {
    return this.ugh_1 + 1 | 0;
  };
  protoOf(Checkpoint_0).toString = function () {
    return 'Checkpoint(sequenceNumber=' + this.ugh_1 + ')';
  };
  protoOf(Checkpoint_0).hashCode = function () {
    return this.ugh_1;
  };
  protoOf(Checkpoint_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Checkpoint_0))
      return false;
    var tmp0_other_with_cast = other instanceof Checkpoint_0 ? other : THROW_CCE();
    if (!(this.ugh_1 === tmp0_other_with_cast.ugh_1))
      return false;
    return true;
  };
  function SpeedArea_0(radius, speedEffect) {
    MapEntityData.call(this);
    this.vgh_1 = radius;
    this.wgh_1 = speedEffect;
  }
  protoOf(SpeedArea_0).ig4 = function () {
    return '' + numberToInt(this.wgh_1 * 100) + '%';
  };
  protoOf(SpeedArea_0).toString = function () {
    return 'SpeedArea(radius=' + new GameUnit(this.vgh_1) + ', speedEffect=' + this.wgh_1 + ')';
  };
  protoOf(SpeedArea_0).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.vgh_1);
    result = imul(result, 31) + getNumberHashCode(this.wgh_1) | 0;
    return result;
  };
  protoOf(SpeedArea_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SpeedArea_0))
      return false;
    var tmp0_other_with_cast = other instanceof SpeedArea_0 ? other : THROW_CCE();
    if (!equals(this.vgh_1, tmp0_other_with_cast.vgh_1))
      return false;
    if (!equals(this.wgh_1, tmp0_other_with_cast.wgh_1))
      return false;
    return true;
  };
  function Monster() {
    Monster_instance = this;
    MapEntityData.call(this);
  }
  var Monster_instance;
  function Monster_getInstance() {
    if (Monster_instance == null)
      new Monster();
    return Monster_instance;
  }
  function MapEntityData() {
  }
  protoOf(MapEntityData).rgh = function () {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject instanceof Checkpoint_0) {
      tmp = 'CP ' + this.eg4();
    } else {
      if (equals(tmp0_subject, Finish_getInstance())) {
        tmp = 'FINISH';
      } else {
        if (equals(tmp0_subject, Rock_getInstance())) {
          tmp = null;
        } else {
          if (equals(tmp0_subject, SmallBlocker_getInstance())) {
            tmp = null;
          } else {
            if (tmp0_subject instanceof SpeedArea_0) {
              tmp = this.ig4();
            } else {
              if (equals(tmp0_subject, Start_getInstance())) {
                tmp = 'START';
              } else {
                if (tmp0_subject instanceof TeleportIn_0) {
                  tmp = 'TP ' + this.eg4() + ' IN';
                } else {
                  if (tmp0_subject instanceof TeleportOut_0) {
                    tmp = 'TP ' + this.eg4() + ' OUT';
                  } else {
                    if (equals(tmp0_subject, Tower_getInstance())) {
                      tmp = null;
                    } else {
                      if (equals(tmp0_subject, Monster_getInstance())) {
                        tmp = null;
                      } else {
                        if (equals(tmp0_subject, SupplyDepot_getInstance())) {
                          tmp = null;
                        } else {
                          noWhenBranchMatchedException();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return tmp;
  };
  function Varied() {
    Varied_instance = this;
    EntitySize.call(this);
  }
  var Varied_instance;
  function Varied_getInstance() {
    if (Varied_instance == null)
      new Varied();
    return Varied_instance;
  }
  function Fixed(width, height) {
    EntitySize.call(this);
    this.xgh_1 = width;
    this.ygh_1 = height;
  }
  protoOf(Fixed).ogb = function () {
    return this.xgh_1;
  };
  protoOf(Fixed).pgb = function () {
    return this.ygh_1;
  };
  protoOf(Fixed).toString = function () {
    return 'Fixed(width=' + new GameUnit(this.xgh_1) + ', height=' + new GameUnit(this.ygh_1) + ')';
  };
  protoOf(Fixed).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.xgh_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ygh_1) | 0;
    return result;
  };
  protoOf(Fixed).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Fixed))
      return false;
    var tmp0_other_with_cast = other instanceof Fixed ? other : THROW_CCE();
    if (!equals(this.xgh_1, tmp0_other_with_cast.xgh_1))
      return false;
    if (!equals(this.ygh_1, tmp0_other_with_cast.ygh_1))
      return false;
    return true;
  };
  var MapEntityType_START_instance;
  var MapEntityType_FINISH_instance;
  var MapEntityType_CHECKPOINT_instance;
  var MapEntityType_ROCK_instance;
  var MapEntityType_TOWER_instance;
  var MapEntityType_TELEPORT_IN_instance;
  var MapEntityType_TELEPORT_OUT_instance;
  var MapEntityType_SMALL_BLOCKER_instance;
  var MapEntityType_SPEED_AREA_instance;
  var MapEntityType_MONSTER_instance;
  var MapEntityType_SUPPLY_DEPOT_instance;
  function EntitySize() {
  }
  function Companion_20() {
    Companion_instance_20 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filter' call
    var tmp1_filter = values();
    var tmp$ret$1;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = ArrayList_init_$Create$();
    var indexedObject = tmp1_filter;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.model.Companion.blockingEntityTypes.<anonymous>' call
      var tmp0_subject = element;
      var tmp0 = tmp0_subject.b2_1;
      var tmp_0;
      switch (tmp0) {
        case 0:
          tmp_0 = false;
          break;
        case 1:
          tmp_0 = false;
          break;
        case 2:
          tmp_0 = false;
          break;
        case 3:
          tmp_0 = true;
          break;
        case 4:
          tmp_0 = true;
          break;
        case 5:
          tmp_0 = false;
          break;
        case 6:
          tmp_0 = false;
          break;
        case 7:
          tmp_0 = true;
          break;
        case 8:
          tmp_0 = false;
          break;
        case 9:
          tmp_0 = false;
          break;
        case 10:
          tmp_0 = true;
          break;
        default:
          noWhenBranchMatchedException();
          break;
      }
      tmp$ret$0 = tmp_0;
      if (tmp$ret$0) {
        tmp0_filterTo.b(element);
      }
    }
    tmp$ret$1 = tmp0_filterTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.agb_1 = toSet(tmp$ret$2);
  }
  protoOf(Companion_20).zgh = function (entityType) {
    var tmp0_subject = entityType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
      case 10:
        tmp = new Fixed(toGameUnit(2), toGameUnit(2));
        break;
      case 3:
      case 8:
        tmp = Varied_getInstance();
        break;
      case 7:
        tmp = new Fixed(toGameUnit(1), toGameUnit(1));
        break;
      case 9:
        tmp = Varied_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  var Companion_instance_20;
  function Companion_getInstance_32() {
    MapEntityType_initEntries();
    if (Companion_instance_20 == null)
      new Companion_20();
    return Companion_instance_20;
  }
  function values() {
    return [MapEntityType_START_getInstance(), MapEntityType_FINISH_getInstance(), MapEntityType_CHECKPOINT_getInstance(), MapEntityType_ROCK_getInstance(), MapEntityType_TOWER_getInstance(), MapEntityType_TELEPORT_IN_getInstance(), MapEntityType_TELEPORT_OUT_getInstance(), MapEntityType_SMALL_BLOCKER_getInstance(), MapEntityType_SPEED_AREA_getInstance(), MapEntityType_MONSTER_getInstance(), MapEntityType_SUPPLY_DEPOT_getInstance()];
  }
  var MapEntityType_entriesInitialized;
  function MapEntityType_initEntries() {
    if (MapEntityType_entriesInitialized)
      return Unit_getInstance();
    MapEntityType_entriesInitialized = true;
    MapEntityType_START_instance = new MapEntityType('START', 0);
    MapEntityType_FINISH_instance = new MapEntityType('FINISH', 1);
    MapEntityType_CHECKPOINT_instance = new MapEntityType('CHECKPOINT', 2);
    MapEntityType_ROCK_instance = new MapEntityType('ROCK', 3);
    MapEntityType_TOWER_instance = new MapEntityType('TOWER', 4);
    MapEntityType_TELEPORT_IN_instance = new MapEntityType('TELEPORT_IN', 5);
    MapEntityType_TELEPORT_OUT_instance = new MapEntityType('TELEPORT_OUT', 6);
    MapEntityType_SMALL_BLOCKER_instance = new MapEntityType('SMALL_BLOCKER', 7);
    MapEntityType_SPEED_AREA_instance = new MapEntityType('SPEED_AREA', 8);
    MapEntityType_MONSTER_instance = new MapEntityType('MONSTER', 9);
    MapEntityType_SUPPLY_DEPOT_instance = new MapEntityType('SUPPLY_DEPOT', 10);
    Companion_getInstance_32();
  }
  function MapEntityType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function MapEntityType_START_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_START_instance;
  }
  function MapEntityType_FINISH_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_FINISH_instance;
  }
  function MapEntityType_CHECKPOINT_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_CHECKPOINT_instance;
  }
  function MapEntityType_ROCK_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_ROCK_instance;
  }
  function MapEntityType_TOWER_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_TOWER_instance;
  }
  function MapEntityType_TELEPORT_IN_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_TELEPORT_IN_instance;
  }
  function MapEntityType_TELEPORT_OUT_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_TELEPORT_OUT_instance;
  }
  function MapEntityType_SMALL_BLOCKER_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_SMALL_BLOCKER_instance;
  }
  function MapEntityType_SPEED_AREA_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_SPEED_AREA_instance;
  }
  function MapEntityType_MONSTER_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_MONSTER_instance;
  }
  function MapEntityType_SUPPLY_DEPOT_getInstance() {
    MapEntityType_initEntries();
    return MapEntityType_SUPPLY_DEPOT_instance;
  }
  function Companion_21() {
    Companion_instance_21 = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [null, new LinkedHashMapSerializer(StringSerializer_getInstance(), $serializer_getInstance_2())];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.agi_1 = tmp$ret$2;
  }
  var Companion_instance_21;
  function Companion_getInstance_33() {
    if (Companion_instance_21 == null)
      new Companion_21();
    return Companion_instance_21;
  }
  function $serializer_14() {
    $serializer_instance_14 = this;
    var tmp0_serialDesc = new PluginGeneratedSerialDescriptor('com.xenotactic.gamelogic.model.PlayerData', this, 2);
    tmp0_serialDesc.cfg('userName', true);
    tmp0_serialDesc.cfg('maps', true);
    this.bgi_1 = tmp0_serialDesc;
  }
  protoOf($serializer_14).qf5 = function () {
    return this.bgi_1;
  };
  protoOf($serializer_14).rfg = function () {
    var tmp0_cached = Companion_getInstance_33().agi_1;
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [StringSerializer_getInstance(), tmp0_cached[1]];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_14).sf5 = function (decoder) {
    var tmp0_desc = this.bgi_1;
    var tmp1_flag = true;
    var tmp2_index = 0;
    var tmp3_bitMask0 = 0;
    var tmp4_local0 = null;
    var tmp5_local1 = null;
    var tmp6_input = decoder.zf8(tmp0_desc);
    var tmp7_cached = Companion_getInstance_33().agi_1;
    if (tmp6_input.pf9()) {
      tmp4_local0 = tmp6_input.jf9(tmp0_desc, 0);
      tmp3_bitMask0 = tmp3_bitMask0 | 1;
      tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, tmp7_cached[1], tmp5_local1);
      tmp3_bitMask0 = tmp3_bitMask0 | 2;
    } else
      while (tmp1_flag) {
        tmp2_index = tmp6_input.qf9(tmp0_desc);
        switch (tmp2_index) {
          case -1:
            tmp1_flag = false;
            break;
          case 0:
            tmp4_local0 = tmp6_input.jf9(tmp0_desc, 0);
            tmp3_bitMask0 = tmp3_bitMask0 | 1;
            break;
          case 1:
            tmp5_local1 = tmp6_input.lf9(tmp0_desc, 1, tmp7_cached[1], tmp5_local1);
            tmp3_bitMask0 = tmp3_bitMask0 | 2;
            break;
          default:
            throw UnknownFieldException_init_$Create$(tmp2_index);
        }
      }
    tmp6_input.af9(tmp0_desc);
    return PlayerData_init_$Create$(tmp3_bitMask0, tmp4_local0, tmp5_local1, null);
  };
  protoOf($serializer_14).cgi = function (encoder, value) {
    var tmp0_desc = this.bgi_1;
    var tmp1_output = encoder.zf8(tmp0_desc);
    var tmp2_cached = Companion_getInstance_33().agi_1;
    if (tmp1_output.wfa(tmp0_desc, 0) ? true : !(value.dgi_1 === 'XenoTactic')) {
      tmp1_output.ofa(tmp0_desc, 0, value.dgi_1);
    }
    var tmp;
    if (tmp1_output.wfa(tmp0_desc, 1)) {
      tmp = true;
    } else {
      var tmp_0 = value.egi_1;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp = !equals(tmp_0, tmp$ret$0);
    }
    if (tmp) {
      tmp1_output.qfa(tmp0_desc, 1, tmp2_cached[1], value.egi_1);
    }
    tmp1_output.af9(tmp0_desc);
  };
  protoOf($serializer_14).rf5 = function (encoder, value) {
    return this.cgi(encoder, value instanceof PlayerData ? value : THROW_CCE());
  };
  var $serializer_instance_14;
  function $serializer_getInstance_14() {
    if ($serializer_instance_14 == null)
      new $serializer_14();
    return $serializer_instance_14;
  }
  function PlayerData_init_$Init$(seen1, userName, maps, serializationConstructorMarker, $this) {
    if (!(0 === (0 & seen1))) {
      throwMissingFieldException(seen1, 0, $serializer_getInstance_14().bgi_1);
    }
    if (0 === (seen1 & 1))
      $this.dgi_1 = 'XenoTactic';
    else
      $this.dgi_1 = userName;
    if (0 === (seen1 & 2)) {
      var tmp = $this;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp.egi_1 = tmp$ret$0;
    } else
      $this.egi_1 = maps;
    return $this;
  }
  function PlayerData_init_$Create$(seen1, userName, maps, serializationConstructorMarker) {
    return PlayerData_init_$Init$(seen1, userName, maps, serializationConstructorMarker, objectCreate(protoOf(PlayerData)));
  }
  function PlayerData(userName, maps) {
    Companion_getInstance_33();
    userName = userName === VOID ? 'XenoTactic' : userName;
    var tmp;
    if (maps === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = maps;
    }
    maps = tmp;
    this.dgi_1 = userName;
    this.egi_1 = maps;
  }
  protoOf(PlayerData).toString = function () {
    return 'PlayerData(userName=' + this.dgi_1 + ', maps=' + this.egi_1 + ')';
  };
  protoOf(PlayerData).hashCode = function () {
    var result = getStringHashCode(this.dgi_1);
    result = imul(result, 31) + hashCode(this.egi_1) | 0;
    return result;
  };
  protoOf(PlayerData).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PlayerData))
      return false;
    var tmp0_other_with_cast = other instanceof PlayerData ? other : THROW_CCE();
    if (!(this.dgi_1 === tmp0_other_with_cast.dgi_1))
      return false;
    if (!equals(this.egi_1, tmp0_other_with_cast.egi_1))
      return false;
    return true;
  };
  var TowerType_BASIC_instance;
  var TowerType_HIGH_DAMAGE_instance;
  var TowerType_entriesInitialized;
  function TowerType_initEntries() {
    if (TowerType_entriesInitialized)
      return Unit_getInstance();
    TowerType_entriesInitialized = true;
    TowerType_BASIC_instance = new TowerType('BASIC', 0);
    TowerType_HIGH_DAMAGE_instance = new TowerType('HIGH_DAMAGE', 1);
  }
  function TowerType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function TowerType_BASIC_getInstance() {
    TowerType_initEntries();
    return TowerType_BASIC_instance;
  }
  function TowerType_HIGH_DAMAGE_getInstance() {
    TowerType_initEntries();
    return TowerType_HIGH_DAMAGE_instance;
  }
  function getFirstTeleportIntersection($this, shortestPath, teleportPairs, activatedTeleportsThusFar, teleportsUsedForThisPathSequence) {
    if (shortestPath == null)
      return null;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var intersectionPointCandidates = tmp$ret$0;
    var tmp0_iterator = teleportPairs.h();
    $l$loop: while (tmp0_iterator.j()) {
      var teleportPair = tmp0_iterator.k();
      if (activatedTeleportsThusFar.q(teleportPair.qgh_1) ? true : teleportsUsedForThisPathSequence.q(teleportPair.qgh_1))
        continue $l$loop;
      var intersectionPoint = shortestPath.hgi(teleportPair.ogh_1.tg8().mgb(), _GameUnit___get_value__impl__9ocgox(teleportPair.ogh_1.lg8()), _GameUnit___get_value__impl__9ocgox(teleportPair.ogh_1.mg8()));
      if (!(intersectionPoint == null)) {
        intersectionPointCandidates.b(new TeleportIntersectionCandidate(teleportPair.qgh_1, intersectionPoint));
      }
    }
    if (intersectionPointCandidates.p()) {
      return null;
    }
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.thenBy' call
    var tmp$ret$1;
    // Inline function 'kotlin.comparisons.compareBy' call
    var tmp = AStarSearcher$FullPathSearcherInternal$getFirstTeleportIntersection$lambda;
    tmp$ret$1 = new sam$kotlin_Comparator$0_0(tmp);
    var tmp0_thenBy = tmp$ret$1;
    var tmp_0 = AStarSearcher$FullPathSearcherInternal$getFirstTeleportIntersection$lambda_0(tmp0_thenBy, shortestPath);
    tmp$ret$2 = new sam$kotlin_Comparator$0_0(tmp_0);
    sortWith(intersectionPointCandidates, tmp$ret$2);
    return firstOrNull(intersectionPointCandidates);
  }
  function failure($this, errorMessage) {
    return new Failure(stackTraceToString(newThrowable(errorMessage)));
  }
  function getShortestPathFromStartToFinish($this, start, finish, blockingEntities, blockingPoints) {
    var endCenter = finish.vg8();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    var cameFrom = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$1 = LinkedHashMap_init_$Create$();
    var costSoFar = tmp$ret$1;
    var tmp = Companion_getInstance_4();
    var frontier = tmp.t1g(VOID, AStarSearcher$FullPathSearcherInternal$getShortestPathFromStartToFinish$lambda);
    var startingPoints = (new PathingPointUtil()).lgi(start, blockingPoints);
    var tmp0_iterator = startingPoints.h();
    while (tmp0_iterator.j()) {
      var shortestStartingPoint = tmp0_iterator.k();
      frontier.h1c(new SearchNode(shortestStartingPoint, 0.0, setOf_0(shortestStartingPoint)));
      // Inline function 'kotlin.collections.set' call
      costSoFar.e5(shortestStartingPoint, 0.0);
    }
    var endPoints = (new PathingPointUtil()).lgi(finish, blockingPoints);
    var nodesSearched = 0;
    $l$loop_0: while (true) {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.isNotEmpty' call
      tmp$ret$2 = !frontier.p();
      if (!tmp$ret$2) {
        break $l$loop_0;
      }
      var current = frontier.a1h();
      if (endPoints.q(current.mgi_1))
        break $l$loop_0;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.filter' call
      var tmp$ret$4;
      // Inline function 'kotlin.collections.filterTo' call
      var tmp0_filterTo = ArrayList_init_$Create$();
      var tmp0_iterator_0 = endPoints.h();
      while (tmp0_iterator_0.j()) {
        var element = tmp0_iterator_0.k();
        var tmp$ret$3;
        // Inline function 'pathing.FullPathSearcherInternal.getShortestPathFromStartToFinish.<anonymous>' call
        tmp$ret$3 = !lineIntersectsEntities(current.mgi_1, element, blockingEntities);
        if (tmp$ret$3) {
          tmp0_filterTo.b(element);
        }
      }
      tmp$ret$4 = tmp0_filterTo;
      tmp$ret$5 = tmp$ret$4;
      var validEndPoints = tmp$ret$5;
      var tmp$ret$7;
      // Inline function 'kotlin.collections.ifEmpty' call
      var tmp_0;
      if (validEndPoints.p()) {
        var tmp$ret$6;
        // Inline function 'pathing.FullPathSearcherInternal.getShortestPathFromStartToFinish.<anonymous>' call
        tmp$ret$6 = getNextPoints(current.mgi_1, blockingEntities, $this.sgi_1);
        tmp_0 = tmp$ret$6;
      } else {
        tmp_0 = validEndPoints;
      }
      tmp$ret$7 = tmp_0;
      var nextPathingPoints = tmp$ret$7;
      var tmp1_iterator = nextPathingPoints.h();
      $l$loop_1: while (tmp1_iterator.j()) {
        var pathingPoint = tmp1_iterator.k();
        if (current.ogi_1.q(pathingPoint))
          continue $l$loop_1;
        var tmp2 = nodesSearched;
        nodesSearched = tmp2 + 1 | 0;
        var newCost = ensureNotNull(costSoFar.h3(current.mgi_1)) + current.mgi_1.cgd(pathingPoint);
        if (!costSoFar.b3(pathingPoint) ? true : newCost < ensureNotNull(costSoFar.h3(pathingPoint))) {
          // Inline function 'kotlin.collections.set' call
          costSoFar.e5(pathingPoint, newCost);
          var shortestPointAtEndToPathingPoint = calculateShortestPointFromStartToEndCircleCached($this, pathingPoint, endCenter, get_PATHING_RADIUS());
          var priority = newCost + (lineIntersectsEntities(pathingPoint, shortestPointAtEndToPathingPoint, blockingEntities) ? 0.0 : pathingPoint.cgd(shortestPointAtEndToPathingPoint));
          frontier.h1c(new SearchNode(pathingPoint, priority, plus_0(current.ogi_1, pathingPoint)));
          // Inline function 'kotlin.collections.set' call
          var tmp1_set = current.mgi_1;
          cameFrom.e5(pathingPoint, tmp1_set);
        }
      }
    }
    AStarSearcher_getInstance().tgi_1.zgi(nodesSearched);
    var tmp3_iterator = endPoints.h();
    while (tmp3_iterator.j()) {
      var allowedEndPoint = tmp3_iterator.k();
      if (cameFrom.b3(allowedEndPoint)) {
        var tmp$ret$8;
        // Inline function 'kotlin.collections.mutableListOf' call
        tmp$ret$8 = ArrayList_init_$Create$();
        var resultingPath = tmp$ret$8;
        var currentPoint = allowedEndPoint;
        while (!startingPoints.q(currentPoint)) {
          resultingPath.b(currentPoint);
          currentPoint = ensureNotNull(cameFrom.h3(currentPoint));
        }
        resultingPath.b(currentPoint);
        reverse(resultingPath);
        var tmp$ret$11;
        // Inline function 'kotlin.collections.map' call
        var tmp$ret$10;
        // Inline function 'kotlin.collections.mapTo' call
        var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(resultingPath, 10));
        var tmp0_iterator_1 = resultingPath.h();
        while (tmp0_iterator_1.j()) {
          var item = tmp0_iterator_1.k();
          var tmp$ret$9;
          // Inline function 'pathing.FullPathSearcherInternal.getShortestPathFromStartToFinish.<anonymous>' call
          tmp$ret$9 = toGameUnitPoint(item);
          tmp2_mapTo.b(tmp$ret$9);
        }
        tmp$ret$10 = tmp2_mapTo;
        tmp$ret$11 = tmp$ret$10;
        return new Path(tmp$ret$11);
      }
    }
    return null;
  }
  function calculateShortestPointFromStartToEndCircleCached($this, point, circleCenter, radius) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = $this.rgi_1;
    var tmp1_getOrPut = new Pair(point, circleCenter);
    var value = tmp0_getOrPut.h3(tmp1_getOrPut);
    var tmp;
    if (value == null) {
      var tmp$ret$0;
      // Inline function 'pathing.FullPathSearcherInternal.calculateShortestPointFromStartToEndCircleCached.<anonymous>' call
      tmp$ret$0 = calculateShortestPointFromStartToEndCircle(point, circleCenter, radius);
      var answer = tmp$ret$0;
      tmp0_getOrPut.e5(tmp1_getOrPut, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  }
  function sam$kotlin_Comparator$0_0(function_0) {
    this.agj_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0_0).l8 = function (a, b) {
    return this.agj_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0_0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function AStarSearcher$FullPathSearcherInternal$getFirstTeleportIntersection$lambda(a, b) {
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$0;
    // Inline function 'pathing.FullPathSearcherInternal.getFirstTeleportIntersection.<anonymous>' call
    tmp$ret$0 = a.egj_1.bgj_1;
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'pathing.FullPathSearcherInternal.getFirstTeleportIntersection.<anonymous>' call
    tmp$ret$1 = b.egj_1.bgj_1;
    tmp$ret$2 = compareValues(tmp, tmp$ret$1);
    return tmp$ret$2;
  }
  function AStarSearcher$FullPathSearcherInternal$getFirstTeleportIntersection$lambda_0($tmp0_thenBy, $shortestPath) {
    return function (a, b) {
      var previousCompare = $tmp0_thenBy.compare(a, b);
      var tmp;
      if (!(previousCompare === 0)) {
        tmp = previousCompare;
      } else {
        var tmp$ret$2;
        // Inline function 'kotlin.comparisons.compareValuesBy' call
        var tmp$ret$0;
        // Inline function 'pathing.FullPathSearcherInternal.getFirstTeleportIntersection.<anonymous>' call
        tmp$ret$0 = new GameUnit($shortestPath.jgj().n(a.egj_1.bgj_1).fgj_1.ngb(a.egj_1.cgj_1));
        var tmp_0 = tmp$ret$0;
        var tmp$ret$1;
        // Inline function 'pathing.FullPathSearcherInternal.getFirstTeleportIntersection.<anonymous>' call
        tmp$ret$1 = new GameUnit($shortestPath.jgj().n(b.egj_1.bgj_1).fgj_1.ngb(b.egj_1.cgj_1));
        tmp$ret$2 = compareValues(tmp_0, tmp$ret$1);
        tmp = tmp$ret$2;
      }
      return tmp;
    };
  }
  function AStarSearcher$FullPathSearcherInternal$getShortestPathFromStartToFinish$lambda(o1, o2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sign' call
    var tmp0__get_sign__8hfh9w = o1.ngi_1 - o2.ngi_1;
    tmp$ret$0 = sign(tmp0__get_sign__8hfh9w);
    return numberToInt(tmp$ret$0);
  }
  function FullPathSearcherInternal(mapWidth, mapHeight, _blockingEntities, _blockingPoints) {
    this.pgi_1 = _blockingEntities;
    this.qgi_1 = _blockingPoints;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.rgi_1 = tmp$ret$0;
    this.sgi_1 = getAvailablePathingPointsFromBlockingEntities(this.pgi_1, mapWidth, mapHeight, this.qgi_1);
  }
  protoOf(FullPathSearcherInternal).kgj = function (pathingEntities, teleportPairs) {
    if (pathingEntities.l() < 2) {
      return failure(this, 'Requires at least 2 pathing entities.');
    }
    var tmp$ret$9;
    // Inline function 'kotlin.collections.mapValues' call
    var tmp$ret$4;
    // Inline function 'kotlin.collections.groupBy' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.groupByTo' call
    var tmp0_groupByTo = LinkedHashMap_init_$Create$();
    var tmp0_iterator = teleportPairs.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'pathing.FullPathSearcherInternal.getShortestPath.<anonymous>' call
      tmp$ret$0 = element.qgh_1;
      var key = tmp$ret$0;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.getOrPut' call
      var value = tmp0_groupByTo.h3(key);
      var tmp;
      if (value == null) {
        var tmp$ret$1;
        // Inline function 'kotlin.collections.groupByTo.<anonymous>' call
        tmp$ret$1 = ArrayList_init_$Create$();
        var answer = tmp$ret$1;
        tmp0_groupByTo.e5(key, answer);
        tmp = answer;
      } else {
        tmp = value;
      }
      tmp$ret$2 = tmp;
      var list = tmp$ret$2;
      list.b(element);
    }
    tmp$ret$3 = tmp0_groupByTo;
    tmp$ret$4 = tmp$ret$3;
    var tmp3_mapValues = tmp$ret$4;
    var tmp$ret$8;
    // Inline function 'kotlin.collections.mapValuesTo' call
    var tmp2_mapValuesTo = LinkedHashMap_init_$Create$_0(mapCapacity(tmp3_mapValues.l()));
    var tmp$ret$7;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp1_associateByTo = tmp3_mapValues.d1();
    var tmp0_iterator_0 = tmp1_associateByTo.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
      tmp$ret$5 = element_0.e1();
      var tmp_0 = tmp$ret$5;
      var tmp$ret$6;
      // Inline function 'pathing.FullPathSearcherInternal.getShortestPath.<anonymous>' call
      tmp$ret$6 = first(element_0.f1());
      tmp2_mapValuesTo.e5(tmp_0, tmp$ret$6);
    }
    tmp$ret$7 = tmp2_mapValuesTo;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var sequenceNumToTeleportPair = tmp$ret$9;
    var prevEntity = null;
    var tmp$ret$10;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$10 = ArrayList_init_$Create$();
    var entityPaths = tmp$ret$10;
    var tmp$ret$11;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$11 = ArrayList_init_$Create$();
    var pathSequenceInfos = tmp$ret$11;
    var tmp$ret$12;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$12 = LinkedHashSet_init_$Create$();
    var activatedTeleportsThusFar = tmp$ret$12;
    var tmp0_iterator_1 = pathingEntities.h();
    $l$loop: while (tmp0_iterator_1.j()) {
      var entity = tmp0_iterator_1.k();
      if (prevEntity == null) {
        prevEntity = entity;
        continue $l$loop;
      }
      var tmp$ret$13;
      // Inline function 'kotlin.collections.mutableSetOf' call
      tmp$ret$13 = LinkedHashSet_init_$Create$();
      var teleportsUsedForThisPathSequence = tmp$ret$13;
      var shortestPath = getShortestPathFromStartToFinish(this, prevEntity, entity, this.pgi_1, this.qgi_1);
      $l$loop_0: while (true) {
        if (shortestPath == null)
          return failure(this, trimIndent('\n                        No path found to start with.\n                            prevEntity: ' + prevEntity + ',\n                            entity: ' + entity + '\n                            pathingEntities: ' + pathingEntities + '\n                    '));
        var teleportCandidate = getFirstTeleportIntersection(this, shortestPath, teleportPairs, activatedTeleportsThusFar, teleportsUsedForThisPathSequence);
        if (teleportCandidate == null) {
          entityPaths.b(new EntityToEntityPath(ensureNotNull(prevEntity), entity, shortestPath));
          break $l$loop_0;
        }
        teleportsUsedForThisPathSequence.b(teleportCandidate.dgj_1);
        entityPaths.b(new EntityToEntityIntersectsTeleport(ensureNotNull(prevEntity), entity, teleportCandidate.dgj_1, shortestPath, shortestPath.lgj(teleportCandidate.egj_1)));
        prevEntity = ensureNotNull(sequenceNumToTeleportPair.h3(teleportCandidate.dgj_1)).pgh_1;
        shortestPath = getShortestPathFromStartToFinish(this, prevEntity, entity, this.pgi_1, this.qgi_1);
      }
      pathSequenceInfos.b(new PathSequenceInfo(teleportsUsedForThisPathSequence));
      activatedTeleportsThusFar.m(teleportsUsedForThisPathSequence);
      prevEntity = entity;
    }
    return new Success(new GamePath(entityPaths));
  };
  function SearchNode(point, priority, exploredPoints) {
    this.mgi_1 = point;
    this.ngi_1 = priority;
    this.ogi_1 = exploredPoints;
  }
  protoOf(SearchNode).toString = function () {
    return 'SearchNode(point=' + this.mgi_1 + ', priority=' + this.ngi_1 + ', exploredPoints=' + this.ogi_1 + ')';
  };
  protoOf(SearchNode).hashCode = function () {
    var result = this.mgi_1.hashCode();
    result = imul(result, 31) + getNumberHashCode(this.ngi_1) | 0;
    result = imul(result, 31) + hashCode(this.ogi_1) | 0;
    return result;
  };
  protoOf(SearchNode).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SearchNode))
      return false;
    var tmp0_other_with_cast = other instanceof SearchNode ? other : THROW_CCE();
    if (!this.mgi_1.equals(tmp0_other_with_cast.mgi_1))
      return false;
    if (!equals(this.ngi_1, tmp0_other_with_cast.ngi_1))
      return false;
    if (!equals(this.ogi_1, tmp0_other_with_cast.ogi_1))
      return false;
    return true;
  };
  function AStarSearcher() {
    AStarSearcher_instance = this;
    this.tgi_1 = new IntStatCounter('a star');
  }
  protoOf(AStarSearcher).mgj = function (mapWidth, mapHeight, pathingEntities, teleportPairs, blockingEntities) {
    var nonNullBlockingPoints = Companion_getInstance_14().wg5(blockingEntities);
    return (new FullPathSearcherInternal(mapWidth, mapHeight, blockingEntities, nonNullBlockingPoints)).kgj(pathingEntities, teleportPairs);
  };
  var AStarSearcher_instance;
  function AStarSearcher_getInstance() {
    if (AStarSearcher_instance == null)
      new AStarSearcher();
    return AStarSearcher_instance;
  }
  function getUpdatablePath($this, width, height, blockingEntities, pathingEntities, teleportPairs, searcher) {
    return searcher.mgj(GameUnit__toInt_impl_9qttgv(width), GameUnit__toInt_impl_9qttgv(height), pathingEntities, teleportPairs, blockingEntities);
  }
  function getUpdatablePath$default($this, width, height, blockingEntities, pathingEntities, teleportPairs, searcher, $super) {
    blockingEntities = blockingEntities === VOID ? emptyList() : blockingEntities;
    pathingEntities = pathingEntities === VOID ? emptyList() : pathingEntities;
    teleportPairs = teleportPairs === VOID ? emptyList() : teleportPairs;
    searcher = searcher === VOID ? AStarSearcher_getInstance() : searcher;
    return getUpdatablePath($this, width, height, blockingEntities, pathingEntities, teleportPairs, searcher);
  }
  function PathFinder() {
    PathFinder_instance = this;
  }
  protoOf(PathFinder).ngj = function (gameMap, searcher) {
    return getUpdatablePath(this, gameMap.bg9_1, gameMap.cg9_1, gameMap.zga(), gameMap.ggb(), gameMap.yg9(), searcher);
  };
  protoOf(PathFinder).ogj = function (gameMap, searcher) {
    var tmp0_safe_receiver = this.ngj(gameMap, searcher).pgj();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.sgj();
  };
  protoOf(PathFinder).tgj = function (gameMap, searcher, $super) {
    searcher = searcher === VOID ? AStarSearcher_getInstance() : searcher;
    return $super === VOID ? this.ogj(gameMap, searcher) : $super.ogj.call(this, gameMap, searcher);
  };
  protoOf(PathFinder).ugj = function (gameMap, towers, searcher) {
    var tmp0_safe_receiver = getUpdatablePath(this, gameMap.bg9_1, gameMap.cg9_1, plus(gameMap.zga(), towers), gameMap.ggb(), gameMap.yg9(), searcher).pgj();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.sgj();
  };
  protoOf(PathFinder).vgj = function (gameMap, towers, searcher, $super) {
    searcher = searcher === VOID ? AStarSearcher_getInstance() : searcher;
    return $super === VOID ? this.ugj(gameMap, towers, searcher) : $super.ugj.call(this, gameMap, towers, searcher);
  };
  protoOf(PathFinder).wgj = function (gameMap, pathingEntities, teleportPairs, searcher) {
    var tmp$ret$1;
    // Inline function 'kotlin.run' call
    var tmp0_run = getUpdatablePath(this, gameMap.bg9_1, gameMap.cg9_1, gameMap.zga(), pathingEntities, teleportPairs, searcher);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'pathing.PathFinder.getShortestPathOnPathingPoints.<anonymous>' call
    var tmp0_subject = tmp0_run;
    var tmp;
    if (tmp0_subject instanceof Failure) {
      tmp = null;
    } else {
      if (tmp0_subject instanceof Success) {
        tmp = tmp0_run.xgj_1;
      } else {
        noWhenBranchMatchedException();
      }
    }
    tmp$ret$0 = tmp;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(PathFinder).ygj = function (gameMap, pathingEntities, teleportPairs, searcher, $super) {
    teleportPairs = teleportPairs === VOID ? gameMap.yg9() : teleportPairs;
    searcher = searcher === VOID ? AStarSearcher_getInstance() : searcher;
    return $super === VOID ? this.wgj(gameMap, pathingEntities, teleportPairs, searcher) : $super.wgj.call(this, gameMap, pathingEntities, teleportPairs, searcher);
  };
  protoOf(PathFinder).zgj = function (width, height, start, finish, blockingEntities, pathingEntities, teleportPairs, additionalTeleportPairs) {
    if (start == null)
      return new Failure('Start is null');
    if (finish == null)
      return new Failure('Finish is null');
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var tmp0_apply = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'pathing.PathFinder.getUpdatablePath.<anonymous>' call
    tmp0_apply.b(start);
    tmp0_apply.m(pathingEntities);
    tmp0_apply.b(finish);
    tmp$ret$1 = tmp0_apply;
    return getUpdatablePath$default(this, width, height, blockingEntities, tmp$ret$1, plus(teleportPairs, additionalTeleportPairs));
  };
  protoOf(PathFinder).xgc = function (width, height, start, finish, blockingEntities, pathingEntities, teleportPairs, additionalTeleportPairs, $super) {
    blockingEntities = blockingEntities === VOID ? emptyList() : blockingEntities;
    pathingEntities = pathingEntities === VOID ? emptyList() : pathingEntities;
    teleportPairs = teleportPairs === VOID ? emptyList() : teleportPairs;
    additionalTeleportPairs = additionalTeleportPairs === VOID ? emptyList() : additionalTeleportPairs;
    return $super === VOID ? this.zgj(width, height, start, finish, blockingEntities, pathingEntities, teleportPairs, additionalTeleportPairs) : $super.zgj.call(this, new GameUnit(width), new GameUnit(height), start, finish, blockingEntities, pathingEntities, teleportPairs, additionalTeleportPairs);
  };
  var PathFinder_instance;
  function PathFinder_getInstance() {
    if (PathFinder_instance == null)
      new PathFinder();
    return PathFinder_instance;
  }
  function isLastPath($this) {
    return $this.cgk_1 === ($this.bgk_1.l() - 1 | 0);
  }
  function PathSequenceTraversal(pathSequence) {
    this.agk_1 = Companion_getInstance_41().egk_1;
    this.bgk_1 = pathSequence.fgk_1;
    this.cgk_1 = 0;
    this.dgk_1 = new PathTraversal(first(this.bgk_1));
  }
  protoOf(PathSequenceTraversal).hgk = function () {
    return this.dgk_1.hgk();
  };
  protoOf(PathSequenceTraversal).ngk = function () {
    return this.dgk_1.ngk();
  };
  protoOf(PathSequenceTraversal).ogk = function (distance) {
    if (this.pgk())
      return Unit_getInstance();
    var remainingDistanceToTraverse = distance;
    while (GameUnit__compareTo_impl_qyacwt_0(remainingDistanceToTraverse, Companion_getInstance_41().egk_1) > 0) {
      this.dgk_1.ogk(remainingDistanceToTraverse);
      var tmp0_this = this;
      tmp0_this.agk_1 = GameUnit__plus_impl_rh1b6r(tmp0_this.agk_1, GameUnit__minus_impl_uz6kmr(remainingDistanceToTraverse, this.dgk_1.qgk()));
      remainingDistanceToTraverse = this.dgk_1.qgk();
      if (this.dgk_1.rgk()) {
        if (isLastPath(this))
          return Unit_getInstance();
        var tmp1_this = this;
        var tmp2 = tmp1_this.cgk_1;
        tmp1_this.cgk_1 = tmp2 + 1 | 0;
        this.dgk_1 = new PathTraversal(this.bgk_1.n(this.cgk_1));
      }
    }
  };
  protoOf(PathSequenceTraversal).pgk = function () {
    return isLastPath(this) ? this.dgk_1.rgk() : false;
  };
  function isLastSegment($this) {
    return $this.lgk_1 === ($this.kgk_1.l() - 1 | 0);
  }
  function PathTraversal(path) {
    this.igk_1 = path;
    this.jgk_1 = Companion_getInstance_41().egk_1;
    this.kgk_1 = this.igk_1.jgj();
    this.lgk_1 = 0;
    this.mgk_1 = new SegmentTraversal(first(this.kgk_1));
  }
  protoOf(PathTraversal).hgk = function () {
    return this.mgk_1.hgk();
  };
  protoOf(PathTraversal).ngk = function () {
    return this.mgk_1.ugk_1;
  };
  protoOf(PathTraversal).qgk = function () {
    return this.mgk_1.vgk_1;
  };
  protoOf(PathTraversal).ogk = function (distance) {
    if (this.rgk())
      return Unit_getInstance();
    var remainingDistanceToTraverse = distance;
    while (GameUnit__compareTo_impl_qyacwt_0(remainingDistanceToTraverse, Companion_getInstance_41().egk_1) > 0) {
      this.mgk_1.ogk(remainingDistanceToTraverse);
      var tmp0_this = this;
      tmp0_this.jgk_1 = GameUnit__plus_impl_rh1b6r(tmp0_this.jgk_1, GameUnit__minus_impl_uz6kmr(remainingDistanceToTraverse, this.mgk_1.vgk_1));
      remainingDistanceToTraverse = this.mgk_1.vgk_1;
      if (this.mgk_1.rgk()) {
        if (isLastSegment(this))
          return Unit_getInstance();
        var tmp1_this = this;
        var tmp2 = tmp1_this.lgk_1;
        tmp1_this.lgk_1 = tmp2 + 1 | 0;
        this.mgk_1 = new SegmentTraversal(this.kgk_1.n(this.lgk_1));
      }
    }
  };
  protoOf(PathTraversal).rgk = function () {
    return isLastSegment(this) ? this.mgk_1.rgk() : false;
  };
  function SegmentTraversal(segment) {
    this.sgk_1 = segment;
    this.tgk_1 = _GameUnit___init__impl__teirvv(0.0);
    this.ugk_1 = this.sgk_1.fgj_1.rgb();
    this.vgk_1 = Companion_getInstance_41().egk_1;
  }
  protoOf(SegmentTraversal).hgk = function () {
    return this.sgk_1.ggj_1;
  };
  protoOf(SegmentTraversal).ogk = function (distance) {
    if (this.rgk())
      return Unit_getInstance();
    var newDistanceTraversed = GameUnit__plus_impl_rh1b6r(this.tgk_1, distance);
    if (GameUnit__compareTo_impl_qyacwt_0(newDistanceTraversed, this.sgk_1.igj_1) < 0) {
      this.tgk_1 = newDistanceTraversed;
      this.ugk_1 = toGameUnitPoint(lerp(this.sgk_1.fgj_1.mgb(), this.sgk_1.ggj_1.mgb(), _GameUnit___get_value__impl__9ocgox(GameUnit__div_impl_u8qjua_0(newDistanceTraversed, this.sgk_1.igj_1))));
    } else {
      this.vgk_1 = GameUnit__minus_impl_uz6kmr(GameUnit__plus_impl_rh1b6r(distance, this.tgk_1), this.sgk_1.igj_1);
      this.tgk_1 = this.sgk_1.igj_1;
      this.ugk_1 = this.sgk_1.ggj_1;
    }
  };
  protoOf(SegmentTraversal).rgk = function () {
    return GameUnit__compareTo_impl_qyacwt_0(this.tgk_1, this.sgk_1.igj_1) >= 0;
  };
  function Companion_22() {
    Companion_instance_22 = this;
  }
  var Companion_instance_22;
  function Companion_getInstance_34() {
    if (Companion_instance_22 == null)
      new Companion_22();
    return Companion_instance_22;
  }
  function PathSequence$pathLength$lambda(it) {
    return new GameUnit(it.ggi_1);
  }
  function PathSequence(paths) {
    Companion_getInstance_34();
    var tmp;
    if (paths === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = paths;
    }
    paths = tmp;
    this.fgk_1 = paths;
    var tmp_0 = this;
    tmp_0.ggk_1 = sumOf(this.fgk_1, PathSequence$pathLength$lambda);
  }
  protoOf(PathSequence).wgk = function (rect) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = this.fgk_1;
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_any.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.pathing.PathSequence.intersectsRectangle.<anonymous>' call
        tmp$ret$1 = element.wgk(rect);
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(PathSequence).toString = function () {
    return 'PathSequence(paths=' + this.fgk_1 + ')';
  };
  protoOf(PathSequence).hashCode = function () {
    return hashCode(this.fgk_1);
  };
  protoOf(PathSequence).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PathSequence))
      return false;
    var tmp0_other_with_cast = other instanceof PathSequence ? other : THROW_CCE();
    if (!equals(this.fgk_1, tmp0_other_with_cast.fgk_1))
      return false;
    return true;
  };
  function Success(gamePath) {
    PathFindingResult.call(this);
    this.xgj_1 = gamePath;
  }
  protoOf(Success).toString = function () {
    return 'Success(gamePath=' + this.xgj_1 + ')';
  };
  protoOf(Success).hashCode = function () {
    return this.xgj_1.hashCode();
  };
  protoOf(Success).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Success))
      return false;
    var tmp0_other_with_cast = other instanceof Success ? other : THROW_CCE();
    if (!this.xgj_1.equals(tmp0_other_with_cast.xgj_1))
      return false;
    return true;
  };
  function Failure(errorMessage) {
    PathFindingResult.call(this);
    this.xgk_1 = errorMessage;
  }
  protoOf(Failure).toString = function () {
    return 'Failure(errorMessage=' + this.xgk_1 + ')';
  };
  protoOf(Failure).hashCode = function () {
    return getStringHashCode(this.xgk_1);
  };
  protoOf(Failure).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Failure))
      return false;
    var tmp0_other_with_cast = other instanceof Failure ? other : THROW_CCE();
    if (!(this.xgk_1 === tmp0_other_with_cast.xgk_1))
      return false;
    return true;
  };
  function PathFindingResult() {
  }
  protoOf(PathFindingResult).pgj = function () {
    var tmp0_subject = this;
    var tmp;
    if (tmp0_subject instanceof Failure) {
      tmp = null;
    } else {
      if (tmp0_subject instanceof Success) {
        tmp = this.xgj_1;
      } else {
        noWhenBranchMatchedException();
      }
    }
    return tmp;
  };
  function GamePath$pathLength$lambda(it) {
    var tmp0_subject = it;
    var tmp;
    if (tmp0_subject instanceof EntityToEntityPath) {
      tmp = it.fgl_1.ggi_1;
    } else {
      if (tmp0_subject instanceof EntityToEntityIntersectsTeleport) {
        tmp = it.cgl_1.ggi_1;
      } else {
        noWhenBranchMatchedException();
      }
    }
    return new GameUnit(tmp);
  }
  function GamePath(entityPaths) {
    this.qgj_1 = entityPaths;
    var tmp = this;
    tmp.rgj_1 = sumOf(this.qgj_1, GamePath$pathLength$lambda);
  }
  protoOf(GamePath).sgj = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.qgj_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.pathing.GamePath.toPathSequence.<anonymous>' call
      var tmp0_subject = item;
      var tmp;
      if (tmp0_subject instanceof EntityToEntityPath) {
        tmp = item.fgl_1;
      } else {
        if (tmp0_subject instanceof EntityToEntityIntersectsTeleport) {
          tmp = item.cgl_1;
        } else {
          noWhenBranchMatchedException();
        }
      }
      tmp$ret$0 = tmp;
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return new PathSequence(tmp$ret$2);
  };
  protoOf(GamePath).toString = function () {
    return 'GamePath(entityPaths=' + this.qgj_1 + ')';
  };
  protoOf(GamePath).hashCode = function () {
    return hashCode(this.qgj_1);
  };
  protoOf(GamePath).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GamePath))
      return false;
    var tmp0_other_with_cast = other instanceof GamePath ? other : THROW_CCE();
    if (!equals(this.qgj_1, tmp0_other_with_cast.qgj_1))
      return false;
    return true;
  };
  function CircleIntersectionResult(segmentIdx, intersectionPoint) {
    this.bgj_1 = segmentIdx;
    this.cgj_1 = intersectionPoint;
  }
  protoOf(CircleIntersectionResult).toString = function () {
    return 'CircleIntersectionResult(segmentIdx=' + this.bgj_1 + ', intersectionPoint=' + this.cgj_1 + ')';
  };
  protoOf(CircleIntersectionResult).hashCode = function () {
    var result = this.bgj_1;
    result = imul(result, 31) + this.cgj_1.hashCode() | 0;
    return result;
  };
  protoOf(CircleIntersectionResult).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CircleIntersectionResult))
      return false;
    var tmp0_other_with_cast = other instanceof CircleIntersectionResult ? other : THROW_CCE();
    if (!(this.bgj_1 === tmp0_other_with_cast.bgj_1))
      return false;
    if (!this.cgj_1.equals(tmp0_other_with_cast.cgj_1))
      return false;
    return true;
  };
  function Companion_23() {
    Companion_instance_23 = this;
  }
  var Companion_instance_23;
  function Companion_getInstance_35() {
    if (Companion_instance_23 == null)
      new Companion_23();
    return Companion_instance_23;
  }
  function Path(points) {
    Companion_getInstance_35();
    this.fgi_1 = points;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.gamelogic.pathing.Path.pathLength.<anonymous>' call
    var sum = _GameUnit___init__impl__teirvv(0.0);
    var prevPoint = null;
    var tmp0_iterator = this.fgi_1.h();
    while (tmp0_iterator.j()) {
      var p = tmp0_iterator.k();
      if (prevPoint == null) {
        prevPoint = p;
      } else {
        sum = GameUnit__plus_impl_rh1b6r(sum, ensureNotNull(prevPoint).ngb(p));
        prevPoint = p;
      }
    }
    tmp$ret$0 = sum;
    tmp$ret$1 = tmp$ret$0;
    tmp.ggi_1 = tmp$ret$1;
  }
  protoOf(Path).ggl = function () {
    return this.fgi_1.l() - 1 | 0;
  };
  protoOf(Path).wgk = function (rect) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = this.jgj();
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_any.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.pathing.Path.intersectsRectangle.<anonymous>' call
        tmp$ret$1 = element.wgk(rect);
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(Path).jgj = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var segments = tmp$ret$0;
    var previousPoint = null;
    var tmp0_iterator = this.fgi_1.h();
    while (tmp0_iterator.j()) {
      var point = tmp0_iterator.k();
      if (previousPoint == null) {
        previousPoint = point;
      } else {
        segments.b(new Segment(previousPoint, point));
        previousPoint = point;
      }
    }
    return toList(segments);
  };
  protoOf(Path).hgi = function (rectBottomLeft, width, height) {
    var segments = this.jgj();
    var iterator = segments.h();
    var index = 0;
    while (iterator.j()) {
      var idx = index;
      index = index + 1 | 0;
      var segment = iterator.k();
      var intersect = segment.hgi(rectBottomLeft, width, height);
      if (!(intersect == null)) {
        return new CircleIntersectionResult(idx, toGameUnitPoint(intersect));
      }
    }
    return null;
  };
  protoOf(Path).lgj = function (intersect) {
    // Inline function 'kotlin.require' call
    var tmp0_require = intersect.bgj_1 < this.ggl();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = intersect.bgj_1 >= 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    return new Path(plus_1(dropLast(this.fgi_1, this.fgi_1.l() - (intersect.bgj_1 + 1 | 0) | 0), intersect.cgj_1));
  };
  protoOf(Path).toString = function () {
    return 'Path(points=' + this.fgi_1 + ')';
  };
  protoOf(Path).hashCode = function () {
    return hashCode(this.fgi_1);
  };
  protoOf(Path).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Path))
      return false;
    var tmp0_other_with_cast = other instanceof Path ? other : THROW_CCE();
    if (!equals(this.fgi_1, tmp0_other_with_cast.fgi_1))
      return false;
    return true;
  };
  function Segment(point1, point2, attributes) {
    var tmp;
    if (attributes === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = attributes;
    }
    attributes = tmp;
    this.fgj_1 = point1;
    this.ggj_1 = point2;
    this.hgj_1 = attributes;
    this.igj_1 = this.fgj_1.ngb(this.ggj_1);
  }
  protoOf(Segment).wgk = function (rect) {
    return intersectSegmentRectangle(this.fgj_1.mgb(), this.ggj_1.mgb(), rect);
  };
  protoOf(Segment).hgi = function (rectBottomLeft, width, height) {
    var points = getIntersectionPointsOfLineSegmentAndRectangle(this.fgj_1.mgb(), this.ggj_1.mgb(), rectBottomLeft, width, height);
    if (points.p())
      return null;
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.minByOrNull' call
      var iterator = points.h();
      if (!iterator.j()) {
        tmp$ret$0 = null;
        break $l$block_0;
      }
      var minElem = iterator.k();
      if (!iterator.j()) {
        tmp$ret$0 = minElem;
        break $l$block_0;
      }
      var tmp$ret$1;
      // Inline function 'com.xenotactic.gamelogic.pathing.Segment.getFirstIntersectionPointToRectangle.<anonymous>' call
      var tmp0__anonymous__q1qw7t = minElem;
      tmp$ret$1 = this.fgj_1.ngb(toGameUnitPoint(tmp0__anonymous__q1qw7t));
      var minValue = tmp$ret$1;
      do {
        var e = iterator.k();
        var tmp$ret$2;
        // Inline function 'com.xenotactic.gamelogic.pathing.Segment.getFirstIntersectionPointToRectangle.<anonymous>' call
        tmp$ret$2 = this.fgj_1.ngb(toGameUnitPoint(e));
        var v = tmp$ret$2;
        if (compareTo(new GameUnit(minValue), new GameUnit(v)) > 0) {
          minElem = e;
          minValue = v;
        }
      }
       while (iterator.j());
      tmp$ret$0 = minElem;
    }
    return tmp$ret$0;
  };
  protoOf(Segment).toString = function () {
    return 'Segment(point1=' + this.fgj_1 + ', point2=' + this.ggj_1 + ', attributes=' + this.hgj_1 + ')';
  };
  protoOf(Segment).hashCode = function () {
    var result = this.fgj_1.hashCode();
    result = imul(result, 31) + this.ggj_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.hgj_1) | 0;
    return result;
  };
  protoOf(Segment).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Segment))
      return false;
    var tmp0_other_with_cast = other instanceof Segment ? other : THROW_CCE();
    if (!this.fgj_1.equals(tmp0_other_with_cast.fgj_1))
      return false;
    if (!this.ggj_1.equals(tmp0_other_with_cast.ggj_1))
      return false;
    if (!equals(this.hgj_1, tmp0_other_with_cast.hgj_1))
      return false;
    return true;
  };
  function EntityToEntityPath(startEntity, endEntity, path) {
    EntityPath.call(this);
    this.dgl_1 = startEntity;
    this.egl_1 = endEntity;
    this.fgl_1 = path;
  }
  protoOf(EntityToEntityPath).toString = function () {
    return 'EntityToEntityPath(startEntity=' + this.dgl_1 + ', endEntity=' + this.egl_1 + ', path=' + this.fgl_1 + ')';
  };
  protoOf(EntityToEntityPath).hashCode = function () {
    var result = hashCode(this.dgl_1);
    result = imul(result, 31) + hashCode(this.egl_1) | 0;
    result = imul(result, 31) + this.fgl_1.hashCode() | 0;
    return result;
  };
  protoOf(EntityToEntityPath).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityToEntityPath))
      return false;
    var tmp0_other_with_cast = other instanceof EntityToEntityPath ? other : THROW_CCE();
    if (!equals(this.dgl_1, tmp0_other_with_cast.dgl_1))
      return false;
    if (!equals(this.egl_1, tmp0_other_with_cast.egl_1))
      return false;
    if (!this.fgl_1.equals(tmp0_other_with_cast.fgl_1))
      return false;
    return true;
  };
  function EntityToEntityIntersectsTeleport(startEntity, endEntity, intersectingTeleport, startToEndPath, startToTeleportPath) {
    EntityPath.call(this);
    this.ygk_1 = startEntity;
    this.zgk_1 = endEntity;
    this.agl_1 = intersectingTeleport;
    this.bgl_1 = startToEndPath;
    this.cgl_1 = startToTeleportPath;
  }
  protoOf(EntityToEntityIntersectsTeleport).toString = function () {
    return 'EntityToEntityIntersectsTeleport(startEntity=' + this.ygk_1 + ', endEntity=' + this.zgk_1 + ', intersectingTeleport=' + this.agl_1 + ', startToEndPath=' + this.bgl_1 + ', startToTeleportPath=' + this.cgl_1 + ')';
  };
  protoOf(EntityToEntityIntersectsTeleport).hashCode = function () {
    var result = hashCode(this.ygk_1);
    result = imul(result, 31) + hashCode(this.zgk_1) | 0;
    result = imul(result, 31) + this.agl_1 | 0;
    result = imul(result, 31) + this.bgl_1.hashCode() | 0;
    result = imul(result, 31) + this.cgl_1.hashCode() | 0;
    return result;
  };
  protoOf(EntityToEntityIntersectsTeleport).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityToEntityIntersectsTeleport))
      return false;
    var tmp0_other_with_cast = other instanceof EntityToEntityIntersectsTeleport ? other : THROW_CCE();
    if (!equals(this.ygk_1, tmp0_other_with_cast.ygk_1))
      return false;
    if (!equals(this.zgk_1, tmp0_other_with_cast.zgk_1))
      return false;
    if (!(this.agl_1 === tmp0_other_with_cast.agl_1))
      return false;
    if (!this.bgl_1.equals(tmp0_other_with_cast.bgl_1))
      return false;
    if (!this.cgl_1.equals(tmp0_other_with_cast.cgl_1))
      return false;
    return true;
  };
  function EntityPath() {
  }
  function intersectSegmentRectangle(startPoint, endPoint, rectangle) {
    var rectangleEndX = rectangle.egd_1 + rectangle.ggd_1;
    var rectangleEndY = rectangle.fgd_1 + rectangle.hgd_1;
    if (intersectSegments(startPoint, endPoint, rectangle.egd_1, rectangle.fgd_1, rectangle.egd_1, rectangleEndY))
      return true;
    if (intersectSegments(startPoint, endPoint, rectangle.egd_1, rectangle.fgd_1, rectangleEndX, rectangle.fgd_1))
      return true;
    if (intersectSegments(startPoint, endPoint, rectangleEndX, rectangle.fgd_1, rectangleEndX, rectangleEndY))
      return true;
    return intersectSegments(startPoint, endPoint, rectangle.egd_1, rectangleEndY, rectangleEndX, rectangleEndY) ? true : rectangle.igd(startPoint) ? true : rectangle.igd(endPoint);
  }
  function intersectSegments(p1, p2, rx1, ry1, rx2, ry2) {
    var d = (ry2 - ry1) * (p2.sgb_1 - p1.sgb_1) - (rx2 - rx1) * (p2.tgb_1 - p1.tgb_1);
    if (d === 0.0)
      return false;
    var yd = p1.tgb_1 - ry1;
    var xd = p1.sgb_1 - rx1;
    var ua = ((rx2 - rx1) * yd - (ry2 - ry1) * xd) / d;
    if (ua < 0.0 ? true : ua > 1.0)
      return false;
    var ub = ((p2.sgb_1 - p1.sgb_1) * yd - (p2.tgb_1 - p1.tgb_1) * xd) / d;
    if (ub < 0.0 ? true : ub > 1.0)
      return false;
    return true;
  }
  function TeleportIntersectionCandidate(sequenceNumber, circleIntersectionResult) {
    this.dgj_1 = sequenceNumber;
    this.egj_1 = circleIntersectionResult;
  }
  protoOf(TeleportIntersectionCandidate).toString = function () {
    return 'TeleportIntersectionCandidate(sequenceNumber=' + this.dgj_1 + ', circleIntersectionResult=' + this.egj_1 + ')';
  };
  protoOf(TeleportIntersectionCandidate).hashCode = function () {
    var result = this.dgj_1;
    result = imul(result, 31) + this.egj_1.hashCode() | 0;
    return result;
  };
  protoOf(TeleportIntersectionCandidate).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof TeleportIntersectionCandidate))
      return false;
    var tmp0_other_with_cast = other instanceof TeleportIntersectionCandidate ? other : THROW_CCE();
    if (!(this.dgj_1 === tmp0_other_with_cast.dgj_1))
      return false;
    if (!this.egj_1.equals(tmp0_other_with_cast.egj_1))
      return false;
    return true;
  };
  function Companion_24() {
    Companion_instance_24 = this;
    this.hgl_1 = new PathSequenceInfo(emptySet());
  }
  var Companion_instance_24;
  function Companion_getInstance_36() {
    if (Companion_instance_24 == null)
      new Companion_24();
    return Companion_instance_24;
  }
  function PathSequenceInfo(teleportsUsedForThisPath) {
    Companion_getInstance_36();
    this.igl_1 = teleportsUsedForThisPath;
  }
  protoOf(PathSequenceInfo).toString = function () {
    return 'PathSequenceInfo(teleportsUsedForThisPath=' + this.igl_1 + ')';
  };
  protoOf(PathSequenceInfo).hashCode = function () {
    return hashCode(this.igl_1);
  };
  protoOf(PathSequenceInfo).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PathSequenceInfo))
      return false;
    var tmp0_other_with_cast = other instanceof PathSequenceInfo ? other : THROW_CCE();
    if (!equals(this.igl_1, tmp0_other_with_cast.igl_1))
      return false;
    return true;
  };
  function lineIntersectsEntities(p1, p2, entities) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp;
      if (isInterface(entities, Collection)) {
        tmp = entities.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = entities.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.pathing.lineIntersectsEntities.<anonymous>' call
        tmp$ret$1 = lineIntersectsEntity(p1, p2, element);
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  }
  function lineIntersectsEntity(p1, p2, entity) {
    return intersectSegmentRectangle(p1, p2, entity.wg8());
  }
  function Companion_25() {
    Companion_instance_25 = this;
  }
  protoOf(Companion_25).jgl = function (x, y, entityVerticalDirection, entityHorizontalDirection) {
    return new PathingPoint(new IPoint(x, y), entityVerticalDirection, entityHorizontalDirection);
  };
  var Companion_instance_25;
  function Companion_getInstance_37() {
    if (Companion_instance_25 == null)
      new Companion_25();
    return Companion_instance_25;
  }
  function PathingPoint(v, entityVerticalDirection, entityHorizontalDirection) {
    Companion_getInstance_37();
    this.kgl_1 = v;
    this.lgl_1 = entityVerticalDirection;
    this.mgl_1 = entityHorizontalDirection;
  }
  protoOf(PathingPoint).toString = function () {
    return 'PathingPoint(v=' + this.kgl_1 + ', entityVerticalDirection=' + this.lgl_1 + ', entityHorizontalDirection=' + this.mgl_1 + ')';
  };
  protoOf(PathingPoint).hashCode = function () {
    var result = this.kgl_1.hashCode();
    result = imul(result, 31) + this.lgl_1.hashCode() | 0;
    result = imul(result, 31) + this.mgl_1.hashCode() | 0;
    return result;
  };
  protoOf(PathingPoint).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PathingPoint))
      return false;
    var tmp0_other_with_cast = other instanceof PathingPoint ? other : THROW_CCE();
    if (!this.kgl_1.equals(tmp0_other_with_cast.kgl_1))
      return false;
    if (!this.lgl_1.equals(tmp0_other_with_cast.lgl_1))
      return false;
    if (!this.mgl_1.equals(tmp0_other_with_cast.mgl_1))
      return false;
    return true;
  };
  function getAvailablePathingPointsFromBlockingEntities(blockingEntities, mapWidth, mapHeight, blockingPoints, cornerPathingPointConfiguration, pathingPointPrecision) {
    cornerPathingPointConfiguration = cornerPathingPointConfiguration === VOID ? Companion_getInstance_38().pgl_1 : cornerPathingPointConfiguration;
    pathingPointPrecision = pathingPointPrecision === VOID ? get_PATHING_POINT_PRECISION() : pathingPointPrecision;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    var availablePoints = tmp$ret$0;
    var tmp0_iterator = blockingEntities.h();
    while (tmp0_iterator.j()) {
      var entity = tmp0_iterator.k();
      var tmp1_x = GameUnit__toInt_impl_9qttgv(entity.rg8().dg6_1);
      var tmp2_y = GameUnit__toInt_impl_9qttgv(entity.rg8().eg6_1);
      var tmp3_calculateTopRight = Companion_getInstance_38().ogl_1;
      var tmp4_calculateBottomLeft = Companion_getInstance_38().ogl_1;
      var tmp5_calculateBottomRight = Companion_getInstance_38().ogl_1;
      availablePoints.m(getPathingPointsForUnitSquare(new PathingPointsForUnitSquareConfiguration(tmp1_x, tmp2_y, mapWidth, mapHeight, pathingPointPrecision, cornerPathingPointConfiguration, tmp3_calculateTopRight, tmp5_calculateBottomRight, tmp4_calculateBottomLeft), blockingPoints));
      var tmp6_x = GameUnit__toInt_impl_9qttgv(entity.sg8().dg6_1);
      var tmp7_y = GameUnit__toInt_impl_9qttgv(entity.sg8().eg6_1);
      var tmp8_calculateTopLeft = Companion_getInstance_38().ogl_1;
      var tmp9_calculateBottomLeft = Companion_getInstance_38().ogl_1;
      var tmp10_calculateBottomRight = Companion_getInstance_38().ogl_1;
      availablePoints.m(getPathingPointsForUnitSquare(new PathingPointsForUnitSquareConfiguration(tmp6_x, tmp7_y, mapWidth, mapHeight, pathingPointPrecision, tmp8_calculateTopLeft, cornerPathingPointConfiguration, tmp10_calculateBottomRight, tmp9_calculateBottomLeft), blockingPoints));
      var tmp11_x = GameUnit__toInt_impl_9qttgv(entity.tg8().dg6_1);
      var tmp12_y = GameUnit__toInt_impl_9qttgv(entity.tg8().eg6_1);
      var tmp13_calculateTopLeft = Companion_getInstance_38().ogl_1;
      var tmp14_calculateTopRight = Companion_getInstance_38().ogl_1;
      var tmp15_calculateBottomRight = Companion_getInstance_38().ogl_1;
      availablePoints.m(getPathingPointsForUnitSquare(new PathingPointsForUnitSquareConfiguration(tmp11_x, tmp12_y, mapWidth, mapHeight, pathingPointPrecision, tmp13_calculateTopLeft, tmp14_calculateTopRight, tmp15_calculateBottomRight, cornerPathingPointConfiguration), blockingPoints));
      var tmp16_x = GameUnit__toInt_impl_9qttgv(entity.ug8().dg6_1);
      var tmp17_y = GameUnit__toInt_impl_9qttgv(entity.ug8().eg6_1);
      var tmp18_calculateTopLeft = Companion_getInstance_38().ogl_1;
      var tmp19_calculateTopRight = Companion_getInstance_38().ogl_1;
      var tmp20_calculateBottomLeft = Companion_getInstance_38().ogl_1;
      availablePoints.m(getPathingPointsForUnitSquare(new PathingPointsForUnitSquareConfiguration(tmp16_x, tmp17_y, mapWidth, mapHeight, pathingPointPrecision, tmp18_calculateTopLeft, tmp19_calculateTopRight, cornerPathingPointConfiguration, tmp20_calculateBottomLeft), blockingPoints));
    }
    return toSet(availablePoints);
  }
  var VerticalDirection_UP_instance;
  var VerticalDirection_NONE_instance;
  var VerticalDirection_DOWN_instance;
  var VerticalDirection_entriesInitialized;
  function VerticalDirection_initEntries() {
    if (VerticalDirection_entriesInitialized)
      return Unit_getInstance();
    VerticalDirection_entriesInitialized = true;
    VerticalDirection_UP_instance = new VerticalDirection('UP', 0);
    VerticalDirection_NONE_instance = new VerticalDirection('NONE', 1);
    VerticalDirection_DOWN_instance = new VerticalDirection('DOWN', 2);
  }
  function VerticalDirection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  var HorizontalDirection_LEFT_instance;
  var HorizontalDirection_NONE_instance;
  var HorizontalDirection_RIGHT_instance;
  var HorizontalDirection_entriesInitialized;
  function HorizontalDirection_initEntries() {
    if (HorizontalDirection_entriesInitialized)
      return Unit_getInstance();
    HorizontalDirection_entriesInitialized = true;
    HorizontalDirection_LEFT_instance = new HorizontalDirection('LEFT', 0);
    HorizontalDirection_NONE_instance = new HorizontalDirection('NONE', 1);
    HorizontalDirection_RIGHT_instance = new HorizontalDirection('RIGHT', 2);
  }
  function HorizontalDirection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Companion_26() {
    Companion_instance_26 = this;
    this.ngl_1 = new CornerPathingPointConfiguration(true, true, true);
    this.ogl_1 = new CornerPathingPointConfiguration(false, false, false);
    this.pgl_1 = new CornerPathingPointConfiguration(true, false, false);
  }
  var Companion_instance_26;
  function Companion_getInstance_38() {
    if (Companion_instance_26 == null)
      new Companion_26();
    return Companion_instance_26;
  }
  function CornerPathingPointConfiguration(calculateDiagonal, calculateP1, calculateP2) {
    Companion_getInstance_38();
    calculateDiagonal = calculateDiagonal === VOID ? false : calculateDiagonal;
    calculateP1 = calculateP1 === VOID ? false : calculateP1;
    calculateP2 = calculateP2 === VOID ? false : calculateP2;
    this.qgl_1 = calculateDiagonal;
    this.rgl_1 = calculateP1;
    this.sgl_1 = calculateP2;
  }
  protoOf(CornerPathingPointConfiguration).tgl = function () {
    return (this.qgl_1 ? true : this.rgl_1) ? true : this.sgl_1;
  };
  protoOf(CornerPathingPointConfiguration).toString = function () {
    return 'CornerPathingPointConfiguration(calculateDiagonal=' + this.qgl_1 + ', calculateP1=' + this.rgl_1 + ', calculateP2=' + this.sgl_1 + ')';
  };
  protoOf(CornerPathingPointConfiguration).hashCode = function () {
    var result = this.qgl_1 | 0;
    result = imul(result, 31) + (this.rgl_1 | 0) | 0;
    result = imul(result, 31) + (this.sgl_1 | 0) | 0;
    return result;
  };
  protoOf(CornerPathingPointConfiguration).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CornerPathingPointConfiguration))
      return false;
    var tmp0_other_with_cast = other instanceof CornerPathingPointConfiguration ? other : THROW_CCE();
    if (!(this.qgl_1 === tmp0_other_with_cast.qgl_1))
      return false;
    if (!(this.rgl_1 === tmp0_other_with_cast.rgl_1))
      return false;
    if (!(this.sgl_1 === tmp0_other_with_cast.sgl_1))
      return false;
    return true;
  };
  function getPathingPointsForUnitSquare(config, blockingPoints) {
    blockingPoints = blockingPoints === VOID ? Companion_getInstance_14().vg5_1 : blockingPoints;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    var pathingPoints = tmp$ret$0;
    var x = config.ugl_1;
    var y = config.vgl_1;
    var pathingPointPrecision = config.ygl_1;
    var mapBlockingResults = MapBlockingUtil_getInstance().dgm(x, y, 1, 1, config.wgl_1, config.xgl_1);
    var hasBlockingTopLeft = blockingPoints.ig6(x - 1 | 0, y + 1 | 0);
    var hasBlockingTop = blockingPoints.ig6(x, y + 1 | 0);
    var hasBlockingTopRight = blockingPoints.ig6(x + 1 | 0, y + 1 | 0);
    var hasBlockingLeft = blockingPoints.ig6(x - 1 | 0, y);
    var hasBlockingRight = blockingPoints.ig6(x + 1 | 0, y);
    var hasBlockingBottomLeft = blockingPoints.ig6(x - 1 | 0, y - 1 | 0);
    var hasBlockingBottom = blockingPoints.ig6(x, y - 1 | 0);
    var hasBlockingBottomRight = blockingPoints.ig6(x + 1 | 0, y - 1 | 0);
    var tmp$ret$1;
    // Inline function 'kotlin.math.sqrt' call
    tmp$ret$1 = Math.sqrt(2.0);
    var diagonalPointPrecision = pathingPointPrecision / tmp$ret$1;
    if ((((config.zgl_1.tgl() ? !mapBlockingResults.egm_1 : false) ? !mapBlockingResults.ggm_1 : false) ? !hasBlockingTopLeft : false) ? !hasBlockingTop : false) {
      if (config.zgl_1.qgl_1 ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x - diagonalPointPrecision, (y + 1 | 0) + diagonalPointPrecision, VerticalDirection_DOWN_getInstance(), HorizontalDirection_RIGHT_getInstance()));
      }
      if (config.zgl_1.rgl_1 ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x - pathingPointPrecision, y + 1.0, VerticalDirection_NONE_getInstance(), HorizontalDirection_RIGHT_getInstance()));
      }
      if (config.zgl_1.sgl_1 ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x, (y + 1 | 0) + pathingPointPrecision, VerticalDirection_DOWN_getInstance(), HorizontalDirection_NONE_getInstance()));
      }
    }
    if ((((config.agm_1.tgl() ? !mapBlockingResults.egm_1 : false) ? !hasBlockingTop : false) ? !mapBlockingResults.hgm_1 : false) ? !hasBlockingTopRight : false) {
      if (config.agm_1.qgl_1 ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl((x + 1 | 0) + diagonalPointPrecision, (y + 1 | 0) + diagonalPointPrecision, VerticalDirection_DOWN_getInstance(), HorizontalDirection_LEFT_getInstance()));
      }
      if (config.agm_1.rgl_1 ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x + 1.0, (y + 1 | 0) + pathingPointPrecision, VerticalDirection_DOWN_getInstance(), HorizontalDirection_NONE_getInstance()));
      }
      if (config.agm_1.sgl_1 ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl((x + 1 | 0) + pathingPointPrecision, y + 1.0, VerticalDirection_NONE_getInstance(), HorizontalDirection_LEFT_getInstance()));
      }
    }
    if ((((config.bgm_1.tgl() ? !mapBlockingResults.fgm_1 : false) ? !mapBlockingResults.hgm_1 : false) ? !hasBlockingBottom : false) ? !hasBlockingBottomRight : false) {
      if (config.bgm_1.qgl_1 ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl((x + 1 | 0) + diagonalPointPrecision, y - diagonalPointPrecision, VerticalDirection_UP_getInstance(), HorizontalDirection_LEFT_getInstance()));
      }
      if (config.bgm_1.rgl_1 ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl((x + 1 | 0) + pathingPointPrecision, y, VerticalDirection_NONE_getInstance(), HorizontalDirection_LEFT_getInstance()));
      }
      if ((config.bgm_1.sgl_1 ? !hasBlockingBottom : false) ? !hasBlockingRight : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x + 1.0, y - pathingPointPrecision, VerticalDirection_UP_getInstance(), HorizontalDirection_NONE_getInstance()));
      }
    }
    if ((((config.cgm_1.tgl() ? !mapBlockingResults.fgm_1 : false) ? !mapBlockingResults.ggm_1 : false) ? !hasBlockingBottom : false) ? !hasBlockingBottomLeft : false) {
      if (config.cgm_1.qgl_1 ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x - diagonalPointPrecision, y - diagonalPointPrecision, VerticalDirection_UP_getInstance(), HorizontalDirection_RIGHT_getInstance()));
      }
      if ((config.cgm_1.rgl_1 ? !hasBlockingBottom : false) ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x, y - pathingPointPrecision, VerticalDirection_UP_getInstance(), HorizontalDirection_NONE_getInstance()));
      }
      if (config.cgm_1.sgl_1 ? !hasBlockingLeft : false) {
        pathingPoints.b(Companion_getInstance_37().jgl(x - pathingPointPrecision, y, VerticalDirection_NONE_getInstance(), HorizontalDirection_RIGHT_getInstance()));
      }
    }
    return toSet(pathingPoints);
  }
  function PathingPointsForUnitSquareConfiguration(x, y, mapWidth, mapHeight, pathingPointPrecision, calculateTopLeft, calculateTopRight, calculateBottomRight, calculateBottomLeft) {
    pathingPointPrecision = pathingPointPrecision === VOID ? get_PATHING_POINT_PRECISION() : pathingPointPrecision;
    calculateTopLeft = calculateTopLeft === VOID ? Companion_getInstance_38().ngl_1 : calculateTopLeft;
    calculateTopRight = calculateTopRight === VOID ? Companion_getInstance_38().ngl_1 : calculateTopRight;
    calculateBottomRight = calculateBottomRight === VOID ? Companion_getInstance_38().ngl_1 : calculateBottomRight;
    calculateBottomLeft = calculateBottomLeft === VOID ? Companion_getInstance_38().ngl_1 : calculateBottomLeft;
    this.ugl_1 = x;
    this.vgl_1 = y;
    this.wgl_1 = mapWidth;
    this.xgl_1 = mapHeight;
    this.ygl_1 = pathingPointPrecision;
    this.zgl_1 = calculateTopLeft;
    this.agm_1 = calculateTopRight;
    this.bgm_1 = calculateBottomRight;
    this.cgm_1 = calculateBottomLeft;
  }
  protoOf(PathingPointsForUnitSquareConfiguration).toString = function () {
    return 'PathingPointsForUnitSquareConfiguration(x=' + this.ugl_1 + ', y=' + this.vgl_1 + ', mapWidth=' + this.wgl_1 + ', mapHeight=' + this.xgl_1 + ', pathingPointPrecision=' + this.ygl_1 + ', calculateTopLeft=' + this.zgl_1 + ', calculateTopRight=' + this.agm_1 + ', calculateBottomRight=' + this.bgm_1 + ', calculateBottomLeft=' + this.cgm_1 + ')';
  };
  protoOf(PathingPointsForUnitSquareConfiguration).hashCode = function () {
    var result = this.ugl_1;
    result = imul(result, 31) + this.vgl_1 | 0;
    result = imul(result, 31) + this.wgl_1 | 0;
    result = imul(result, 31) + this.xgl_1 | 0;
    result = imul(result, 31) + getNumberHashCode(this.ygl_1) | 0;
    result = imul(result, 31) + this.zgl_1.hashCode() | 0;
    result = imul(result, 31) + this.agm_1.hashCode() | 0;
    result = imul(result, 31) + this.bgm_1.hashCode() | 0;
    result = imul(result, 31) + this.cgm_1.hashCode() | 0;
    return result;
  };
  protoOf(PathingPointsForUnitSquareConfiguration).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PathingPointsForUnitSquareConfiguration))
      return false;
    var tmp0_other_with_cast = other instanceof PathingPointsForUnitSquareConfiguration ? other : THROW_CCE();
    if (!(this.ugl_1 === tmp0_other_with_cast.ugl_1))
      return false;
    if (!(this.vgl_1 === tmp0_other_with_cast.vgl_1))
      return false;
    if (!(this.wgl_1 === tmp0_other_with_cast.wgl_1))
      return false;
    if (!(this.xgl_1 === tmp0_other_with_cast.xgl_1))
      return false;
    if (!equals(this.ygl_1, tmp0_other_with_cast.ygl_1))
      return false;
    if (!this.zgl_1.equals(tmp0_other_with_cast.zgl_1))
      return false;
    if (!this.agm_1.equals(tmp0_other_with_cast.agm_1))
      return false;
    if (!this.bgm_1.equals(tmp0_other_with_cast.bgm_1))
      return false;
    if (!this.cgm_1.equals(tmp0_other_with_cast.cgm_1))
      return false;
    return true;
  };
  function getNextPoints(currentPoint, blockingEntities, availablePathingPoints) {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapNotNull' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapNotNullTo' call
    var tmp0_mapNotNullTo = ArrayList_init_$Create$();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = availablePathingPoints.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.pathing.getNextPoints.<anonymous>' call
      var verticalDirectionToPathingPoint = verticalDirectionTo(currentPoint, element.kgl_1);
      var horizontalDirectionToPathingPoint = horizontalDirectionTo(currentPoint, element.kgl_1);
      var lineIntersectsEntity = lineIntersectsEntities(currentPoint, element.kgl_1, blockingEntities);
      var tmp;
      if (((((((element.lgl_1.equals(VerticalDirection_DOWN_getInstance()) ? element.mgl_1.equals(HorizontalDirection_LEFT_getInstance()) : false) ? verticalDirectionToPathingPoint.equals(VerticalDirection_UP_getInstance()) : false) ? horizontalDirectionToPathingPoint.equals(HorizontalDirection_RIGHT_getInstance()) : false) ? true : ((element.lgl_1.equals(VerticalDirection_DOWN_getInstance()) ? element.mgl_1.equals(HorizontalDirection_RIGHT_getInstance()) : false) ? verticalDirectionToPathingPoint.equals(VerticalDirection_UP_getInstance()) : false) ? horizontalDirectionToPathingPoint.equals(HorizontalDirection_LEFT_getInstance()) : false) ? true : ((element.lgl_1.equals(VerticalDirection_UP_getInstance()) ? element.mgl_1.equals(HorizontalDirection_LEFT_getInstance()) : false) ? verticalDirectionToPathingPoint.equals(VerticalDirection_DOWN_getInstance()) : false) ? horizontalDirectionToPathingPoint.equals(HorizontalDirection_RIGHT_getInstance()) : false) ? true : ((element.lgl_1.equals(VerticalDirection_UP_getInstance()) ? element.mgl_1.equals(HorizontalDirection_RIGHT_getInstance()) : false) ? verticalDirectionToPathingPoint.equals(VerticalDirection_DOWN_getInstance()) : false) ? horizontalDirectionToPathingPoint.equals(HorizontalDirection_LEFT_getInstance()) : false) ? true : lineIntersectsEntity) {
        tmp = null;
      } else {
        tmp = element.kgl_1;
      }
      tmp$ret$0 = tmp;
      var tmp0_safe_receiver = tmp$ret$0;
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$1;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp0_mapNotNullTo.b(tmp0_safe_receiver);
        tmp$ret$1 = Unit_getInstance();
      }
    }
    tmp$ret$2 = tmp0_mapNotNullTo;
    tmp$ret$3 = tmp$ret$2;
    var filtered = tmp$ret$3;
    return filtered;
  }
  function calculateShortestPointFromStartToEndCircle(point, circleCenter, radius) {
    var angleRadians_0 = angleRadians(circleCenter, point);
    var tmp$ret$0;
    // Inline function 'korlibs.math.geom.cos' call
    var tmp0_cos = Companion_getInstance_3().v3r_1;
    tmp$ret$0 = Angle__cosine_impl_bgvzun(angleRadians_0, tmp0_cos);
    var tmp = circleCenter.sgb_1 + tmp$ret$0 * radius;
    var tmp$ret$1;
    // Inline function 'korlibs.math.geom.sin' call
    var tmp1_sin = Companion_getInstance_3().v3r_1;
    tmp$ret$1 = Angle__sine_impl_3l11xv(angleRadians_0, tmp1_sin);
    return new IPoint(tmp, circleCenter.tgb_1 + tmp$ret$1 * radius);
  }
  function VerticalDirection_UP_getInstance() {
    VerticalDirection_initEntries();
    return VerticalDirection_UP_instance;
  }
  function VerticalDirection_NONE_getInstance() {
    VerticalDirection_initEntries();
    return VerticalDirection_NONE_instance;
  }
  function VerticalDirection_DOWN_getInstance() {
    VerticalDirection_initEntries();
    return VerticalDirection_DOWN_instance;
  }
  function HorizontalDirection_LEFT_getInstance() {
    HorizontalDirection_initEntries();
    return HorizontalDirection_LEFT_instance;
  }
  function HorizontalDirection_NONE_getInstance() {
    HorizontalDirection_initEntries();
    return HorizontalDirection_NONE_instance;
  }
  function HorizontalDirection_RIGHT_getInstance() {
    HorizontalDirection_initEntries();
    return HorizontalDirection_RIGHT_instance;
  }
  function StandardSolver3$StandardSolverInternal$solveInternal$lambda(o1, o2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.sign' call
    var tmp0__get_sign__8hfh9w = o2.a5_1 - o1.a5_1;
    tmp$ret$0 = sign(tmp0__get_sign__8hfh9w);
    return numberToInt(tmp$ret$0);
  }
  function StandardSolverInternal(_solverSettings, _map, _solverParams) {
    this.igm_1 = _solverSettings;
    this.jgm_1 = _map;
    this.kgm_1 = _solverParams;
    this.lgm_1 = getAvailableTowerPlacementSpots(this.jgm_1);
    this.mgm_1 = new TowerCache(GameUnit__toInt_impl_9qttgv(this.jgm_1.bg9_1), GameUnit__toInt_impl_9qttgv(this.jgm_1.cg9_1));
    this.ngm_1 = new TowerPlacementsToPathCache();
    this.ogm_1 = 0;
    this.pgm_1 = 0;
    this.qgm_1 = 0;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.rgm_1 = tmp$ret$0;
  }
  protoOf(StandardSolverInternal).sgm = function () {
    // Inline function 'kotlin.require' call
    var tmp = this.kgm_1.ugm_1;
    var tmp0_require = tmp instanceof MaxPath;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var initialShortestPath = PathFinder_getInstance().tgj(this.jgm_1);
    var tmp0_elvis_lhs = initialShortestPath;
    if (tmp0_elvis_lhs == null)
      return Unsuccessful_getInstance();
    else
      tmp0_elvis_lhs;
    var result = this.vgm(new SearchState(emptySet()));
    println(trimIndent('\n                numTotalSolverInternalCalls: ' + this.ogm_1 + '\n                numStatesExplored: ' + this.pgm_1 + '\n                numCacheHits: ' + this.qgm_1 + '\n            '));
    var tmp1_subject = result;
    var tmp_0;
    if (tmp1_subject instanceof Success_1) {
      println(trimIndent('\n                        Initial path length: ' + new GameUnit(initialShortestPath.ggk_1) + '\n                        Path length of found solver: ' + new GameUnit(result.xgm_1.ggk_1) + '\n                    '));
      tmp_0 = new Success_0(result);
    } else {
      if (equals(tmp1_subject, Unsuccessful_getInstance_0())) {
        tmp_0 = Unsuccessful_getInstance();
      } else {
        noWhenBranchMatchedException();
      }
    }
    return tmp_0;
  };
  protoOf(StandardSolverInternal).vgm = function (state) {
    var tmp0_this = this;
    var tmp1 = tmp0_this.ogm_1;
    tmp0_this.ogm_1 = tmp1 + 1 | 0;
    if (this.rgm_1.b3(state)) {
      var tmp2_this = this;
      var tmp3 = tmp2_this.qgm_1;
      tmp2_this.qgm_1 = tmp3 + 1 | 0;
      return ensureNotNull(this.rgm_1.h3(state));
    }
    var tmp4_this = this;
    var tmp5 = tmp4_this.pgm_1;
    tmp4_this.pgm_1 = tmp5 + 1 | 0;
    var path = this.ngm_1.agn(this.jgm_1, state.ygm_1, this.mgm_1);
    if (path == null) {
      var result = Unsuccessful_getInstance_0();
      // Inline function 'kotlin.collections.set' call
      var tmp0_set = this.rgm_1;
      tmp0_set.e5(state, result);
      return result;
    }
    if (state.bgn(this.kgm_1)) {
      var result_0 = new Success_1(state, path);
      // Inline function 'kotlin.collections.set' call
      var tmp1_set = this.rgm_1;
      tmp1_set.e5(state, result_0);
      return result_0;
    }
    var tmp = Companion_getInstance_4();
    var pointsOrderedByPathLength = tmp.t1g(VOID, StandardSolver3$StandardSolverInternal$solveInternal$lambda);
    var numSpotsConsidered = 0;
    var tmp6_iterator = getNextTowerPlacementSpotsV2(this.jgm_1, state.ygm_1, path, this.lgm_1, this.mgm_1).cgn_1.h();
    $l$loop_0: while (tmp6_iterator.j()) {
      var spot = tmp6_iterator.k();
      var tmp7_elvis_lhs = this.ngm_1.agn(this.jgm_1, plus_0(state.ygm_1, spot), this.mgm_1);
      var tmp_0;
      if (tmp7_elvis_lhs == null) {
        continue $l$loop_0;
      } else {
        tmp_0 = tmp7_elvis_lhs;
      }
      var pathWithSpot = tmp_0;
      pointsOrderedByPathLength.h1c(new Pair(GameUnit__toDouble_impl_5m2yph(pathWithSpot.ggk_1), spot));
      var tmp8 = numSpotsConsidered;
      numSpotsConsidered = tmp8 + 1 | 0;
      if (numSpotsConsidered >= this.igm_1.egn_1) {
        break $l$loop_0;
      }
    }
    var bestResult = new Success_1(state, path);
    var bestPathLength = path.ggk_1;
    var numProcessed = 0;
    var tmp9_iterator = pointsOrderedByPathLength.h();
    $l$loop_2: while (tmp9_iterator.j()) {
      var pair = tmp9_iterator.k();
      if (numProcessed > this.igm_1.fgn_1) {
        break $l$loop_2;
      }
      var tmp10 = numProcessed;
      numProcessed = tmp10 + 1 | 0;
      var result_1 = this.vgm(new SearchState(plus_0(state.ygm_1, pair.b5_1)));
      var tmp11_subject = result_1;
      if (tmp11_subject instanceof Success_1) {
        var pathLength = result_1.xgm_1.ggk_1;
        if (GameUnit__compareTo_impl_qyacwt_0(pathLength, bestPathLength) > 0) {
          bestResult = result_1;
          bestPathLength = pathLength;
        }
      } else {
        if (equals(tmp11_subject, Unsuccessful_getInstance_0()))
          continue $l$loop_2;
      }
    }
    // Inline function 'kotlin.collections.set' call
    var tmp2_set = this.rgm_1;
    var tmp3_set = bestResult;
    tmp2_set.e5(state, tmp3_set);
    return bestResult;
  };
  function StandardSolver3(solverSettings) {
    solverSettings = solverSettings === VOID ? new SolverSettings() : solverSettings;
    this.ggn_1 = solverSettings;
  }
  protoOf(StandardSolver3).hgn = function (map, solverParams) {
    return (new StandardSolverInternal(this.ggn_1, map, solverParams)).sgm();
  };
  function SolverSettings(numSpotsToConsider, numSpotsToExplore) {
    numSpotsToConsider = numSpotsToConsider === VOID ? 1 : numSpotsToConsider;
    numSpotsToExplore = numSpotsToExplore === VOID ? 1 : numSpotsToExplore;
    this.egn_1 = numSpotsToConsider;
    this.fgn_1 = numSpotsToExplore;
  }
  protoOf(SolverSettings).toString = function () {
    return 'SolverSettings(numSpotsToConsider=' + this.egn_1 + ', numSpotsToExplore=' + this.fgn_1 + ')';
  };
  protoOf(SolverSettings).hashCode = function () {
    var result = this.egn_1;
    result = imul(result, 31) + this.fgn_1 | 0;
    return result;
  };
  protoOf(SolverSettings).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SolverSettings))
      return false;
    var tmp0_other_with_cast = other instanceof SolverSettings ? other : THROW_CCE();
    if (!(this.egn_1 === tmp0_other_with_cast.egn_1))
      return false;
    if (!(this.fgn_1 === tmp0_other_with_cast.fgn_1))
      return false;
    return true;
  };
  function TowerPlacementsToPathCache() {
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.zgm_1 = tmp$ret$0;
  }
  protoOf(TowerPlacementsToPathCache).agn = function (map, placementSpots, towerCache) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.zgm_1;
    var value = tmp0_getOrPut.h3(placementSpots);
    var tmp;
    if (value == null) {
      var tmp$ret$3;
      // Inline function 'solver.TowerPlacementsToPathCache.getShortestPath.<anonymous>' call
      var tmp_0 = PathFinder_getInstance();
      var tmp$ret$2;
      // Inline function 'kotlin.collections.map' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(placementSpots, 10));
      var tmp0_iterator = placementSpots.h();
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'solver.TowerPlacementsToPathCache.getShortestPath.<anonymous>.<anonymous>' call
        tmp$ret$0 = towerCache.jgn(GameUnit__toInt_impl_9qttgv(item.dg6_1), GameUnit__toInt_impl_9qttgv(item.eg6_1));
        tmp0_mapTo.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapTo;
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$3 = tmp_0.vgj(map, tmp$ret$2);
      var answer = tmp$ret$3;
      tmp0_getOrPut.e5(placementSpots, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$4 = tmp;
    return tmp$ret$4;
  };
  function Unsuccessful() {
    Unsuccessful_instance = this;
    SolverResult.call(this);
  }
  var Unsuccessful_instance;
  function Unsuccessful_getInstance() {
    if (Unsuccessful_instance == null)
      new Unsuccessful();
    return Unsuccessful_instance;
  }
  function Success_0(searchResult) {
    SolverResult.call(this);
    this.kgn_1 = searchResult;
  }
  protoOf(Success_0).toString = function () {
    return 'Success(searchResult=' + this.kgn_1 + ')';
  };
  protoOf(Success_0).hashCode = function () {
    return this.kgn_1.hashCode();
  };
  protoOf(Success_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Success_0))
      return false;
    var tmp0_other_with_cast = other instanceof Success_0 ? other : THROW_CCE();
    if (!this.kgn_1.equals(tmp0_other_with_cast.kgn_1))
      return false;
    return true;
  };
  function SolverResult() {
  }
  function SolverParams(maxTowers, optimizationGoal) {
    this.tgm_1 = maxTowers;
    this.ugm_1 = optimizationGoal;
  }
  protoOf(SolverParams).toString = function () {
    return 'SolverParams(maxTowers=' + this.tgm_1 + ', optimizationGoal=' + this.ugm_1 + ')';
  };
  protoOf(SolverParams).hashCode = function () {
    var result = this.tgm_1;
    result = imul(result, 31) + hashCode(this.ugm_1) | 0;
    return result;
  };
  protoOf(SolverParams).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SolverParams))
      return false;
    var tmp0_other_with_cast = other instanceof SolverParams ? other : THROW_CCE();
    if (!(this.tgm_1 === tmp0_other_with_cast.tgm_1))
      return false;
    if (!equals(this.ugm_1, tmp0_other_with_cast.ugm_1))
      return false;
    return true;
  };
  function MaxPath() {
    MaxPath_instance = this;
    OptimizationGoal.call(this);
  }
  var MaxPath_instance;
  function MaxPath_getInstance() {
    if (MaxPath_instance == null)
      new MaxPath();
    return MaxPath_instance;
  }
  function OptimizationGoal() {
  }
  function Unsuccessful_0() {
    Unsuccessful_instance_0 = this;
    SearchResult.call(this);
  }
  var Unsuccessful_instance_0;
  function Unsuccessful_getInstance_0() {
    if (Unsuccessful_instance_0 == null)
      new Unsuccessful_0();
    return Unsuccessful_instance_0;
  }
  function Success_1(state, pathSequence) {
    SearchResult.call(this);
    this.wgm_1 = state;
    this.xgm_1 = pathSequence;
  }
  protoOf(Success_1).toString = function () {
    return 'Success(state=' + this.wgm_1 + ', pathSequence=' + this.xgm_1 + ')';
  };
  protoOf(Success_1).hashCode = function () {
    var result = this.wgm_1.hashCode();
    result = imul(result, 31) + this.xgm_1.hashCode() | 0;
    return result;
  };
  protoOf(Success_1).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Success_1))
      return false;
    var tmp0_other_with_cast = other instanceof Success_1 ? other : THROW_CCE();
    if (!this.wgm_1.equals(tmp0_other_with_cast.wgm_1))
      return false;
    if (!this.xgm_1.equals(tmp0_other_with_cast.xgm_1))
      return false;
    return true;
  };
  function SearchResult() {
  }
  function SearchState(towerPlacements) {
    this.ygm_1 = towerPlacements;
  }
  protoOf(SearchState).lgn = function () {
    return this.ygm_1.l();
  };
  protoOf(SearchState).bgn = function (solverParams) {
    // Inline function 'kotlin.require' call
    var tmp0_require = this.lgn() <= solverParams.tgm_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return this.lgn() === solverParams.tgm_1;
  };
  protoOf(SearchState).toString = function () {
    return 'SearchState(towerPlacements=' + this.ygm_1 + ')';
  };
  protoOf(SearchState).hashCode = function () {
    return hashCode(this.ygm_1);
  };
  protoOf(SearchState).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SearchState))
      return false;
    var tmp0_other_with_cast = other instanceof SearchState ? other : THROW_CCE();
    if (!equals(this.ygm_1, tmp0_other_with_cast.ygm_1))
      return false;
    return true;
  };
  function NextTowerPlacementSpotsOutput(neighborBlockingEntities, otherPlacementSpots) {
    this.cgn_1 = neighborBlockingEntities;
    this.dgn_1 = otherPlacementSpots;
  }
  protoOf(NextTowerPlacementSpotsOutput).toString = function () {
    return 'NextTowerPlacementSpotsOutput(neighborBlockingEntities=' + this.cgn_1 + ', otherPlacementSpots=' + this.dgn_1 + ')';
  };
  protoOf(NextTowerPlacementSpotsOutput).hashCode = function () {
    var result = hashCode(this.cgn_1);
    result = imul(result, 31) + hashCode(this.dgn_1) | 0;
    return result;
  };
  protoOf(NextTowerPlacementSpotsOutput).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof NextTowerPlacementSpotsOutput))
      return false;
    var tmp0_other_with_cast = other instanceof NextTowerPlacementSpotsOutput ? other : THROW_CCE();
    if (!equals(this.cgn_1, tmp0_other_with_cast.cgn_1))
      return false;
    if (!equals(this.dgn_1, tmp0_other_with_cast.dgn_1))
      return false;
    return true;
  };
  function getNextTowerPlacementSpotsV2(map, currentPlacements, pathSequence, towerPlacementSpots, towerCache) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    var neighborsBlockingEntities = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$1 = LinkedHashSet_init_$Create$();
    var otherPlacementSpots = tmp$ret$1;
    var tmp0_iterator = currentPlacements.h();
    while (tmp0_iterator.j()) {
      var spot = tmp0_iterator.k();
      map.og9(towerCache.mgn(spot.dg6_1, spot.eg6_1));
    }
    var tmp1_iterator = towerPlacementSpots.h();
    while (tmp1_iterator.j()) {
      var spot_0 = tmp1_iterator.k();
      var tower = towerCache.mgn(spot_0.dg6_1, spot_0.eg6_1);
      var intersectsPath = pathSequence.wgk(new IRectangle(_GameUnit___get_value__impl__9ocgox(spot_0.dg6_1), _GameUnit___get_value__impl__9ocgox(spot_0.eg6_1), 2.0, 2.0));
      if (intersectsPath ? !map.vga(spot_0.dg6_1, spot_0.eg6_1, toGameUnit(2), toGameUnit(2)) : false) {
        if (EntitiesBlockingEntityUtil_getInstance().pgn(tower, map).ygn() ? true : MapBlockingUtil_getInstance().ngn(tower, GameUnit__toInt_impl_9qttgv(map.bg9_1), GameUnit__toInt_impl_9qttgv(map.cg9_1)).ogn()) {
          neighborsBlockingEntities.b(spot_0);
        } else {
          otherPlacementSpots.b(spot_0);
        }
      }
    }
    var tmp2_iterator = currentPlacements.h();
    while (tmp2_iterator.j()) {
      var spot_1 = tmp2_iterator.k();
      map.tga(towerCache.mgn(spot_1.dg6_1, spot_1.eg6_1));
    }
    return new NextTowerPlacementSpotsOutput(neighborsBlockingEntities, otherPlacementSpots);
  }
  function getAvailableTowerPlacementSpots(map) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var availableSpots = tmp$ret$0;
    var pathingEntities = map.fgb();
    var inductionVariable = 0;
    var last = GameUnit__toInt_impl_9qttgv(map.bg9_1) - 2 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        var last_0 = GameUnit__toInt_impl_9qttgv(map.cg9_1) - 2 | 0;
        if (inductionVariable_0 <= last_0)
          $l$loop_0: do {
            var j = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var candidate = new Tower(toGameUnit(i), toGameUnit(j));
            if (map.uga(candidate)) {
              continue $l$loop_0;
            }
            var tmp$ret$1;
            $l$block_0: {
              // Inline function 'kotlin.collections.any' call
              var tmp;
              if (isInterface(pathingEntities, Collection)) {
                tmp = pathingEntities.p();
              } else {
                tmp = false;
              }
              if (tmp) {
                tmp$ret$1 = false;
                break $l$block_0;
              }
              var tmp0_iterator = pathingEntities.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                var tmp$ret$2;
                // Inline function 'solver.getAvailableTowerPlacementSpots.<anonymous>' call
                tmp$ret$2 = element.yg8(candidate);
                if (tmp$ret$2) {
                  tmp$ret$1 = true;
                  break $l$block_0;
                }
              }
              tmp$ret$1 = false;
            }
            if (tmp$ret$1) {
              continue $l$loop_0;
            }
            availableSpots.b(candidate.zgg());
          }
           while (!(j === last_0));
      }
       while (!(i === last));
    return availableSpots;
  }
  function GameMapDimensionsState(engine, width, height) {
    this.zgn_1 = engine;
    this.ago_1 = width;
    this.bgo_1 = height;
  }
  protoOf(GameMapDimensionsState).cgo = function (newWidth, newHeight) {
    if (equals(this.ago_1, newWidth) ? equals(this.bgo_1, newHeight) : false)
      return Unit_getInstance();
    var oldMapWidth = this.ago_1;
    var oldMapHeight = this.bgo_1;
    this.ago_1 = newWidth;
    this.bgo_1 = newHeight;
    this.zgn_1.dgo_1.bg7(new ResizeMapEvent(oldMapWidth, oldMapHeight, newWidth, newHeight));
  };
  function GameMapPathState(engine) {
    this.igo_1 = engine;
    this.jgo_1 = null;
  }
  protoOf(GameMapPathState).kgo = function (newPath) {
    this.jgo_1 = newPath;
    var tmp = this.jgo_1;
    var tmp0_safe_receiver = this.jgo_1;
    this.igo_1.dgo_1.bg7(new UpdatedPathLineEvent(tmp, tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ggk_1));
  };
  function Companion_27() {
    Companion_instance_27 = this;
    this.lgo_1 = new GameplayState(3, 0.04, 7, 4, 5, 31);
  }
  var Companion_instance_27;
  function Companion_getInstance_39() {
    if (Companion_instance_27 == null)
      new Companion_27();
    return Companion_instance_27;
  }
  function GameplayState(maxSpeedUpgrades, speedPercentPerUpgrade, basicTowerCost, initialDamageUpgradeCost, initialSpeedUpgradeCost, supplyDepotCost) {
    Companion_getInstance_39();
    this.mgo_1 = maxSpeedUpgrades;
    this.ngo_1 = speedPercentPerUpgrade;
    this.ogo_1 = basicTowerCost;
    this.pgo_1 = initialDamageUpgradeCost;
    this.qgo_1 = initialSpeedUpgradeCost;
    this.rgo_1 = supplyDepotCost;
    this.sgo_1 = 34;
    this.tgo_1 = 15;
    this.ugo_1 = 10;
  }
  protoOf(GameplayState).toString = function () {
    return 'GameplayState(maxSpeedUpgrades=' + this.mgo_1 + ', speedPercentPerUpgrade=' + this.ngo_1 + ', basicTowerCost=' + this.ogo_1 + ', initialDamageUpgradeCost=' + this.pgo_1 + ', initialSpeedUpgradeCost=' + this.qgo_1 + ', supplyDepotCost=' + this.rgo_1 + ')';
  };
  protoOf(GameplayState).hashCode = function () {
    var result = this.mgo_1;
    result = imul(result, 31) + getNumberHashCode(this.ngo_1) | 0;
    result = imul(result, 31) + this.ogo_1 | 0;
    result = imul(result, 31) + this.pgo_1 | 0;
    result = imul(result, 31) + this.qgo_1 | 0;
    result = imul(result, 31) + this.rgo_1 | 0;
    return result;
  };
  protoOf(GameplayState).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GameplayState))
      return false;
    var tmp0_other_with_cast = other instanceof GameplayState ? other : THROW_CCE();
    if (!(this.mgo_1 === tmp0_other_with_cast.mgo_1))
      return false;
    if (!equals(this.ngo_1, tmp0_other_with_cast.ngo_1))
      return false;
    if (!(this.ogo_1 === tmp0_other_with_cast.ogo_1))
      return false;
    if (!(this.pgo_1 === tmp0_other_with_cast.pgo_1))
      return false;
    if (!(this.qgo_1 === tmp0_other_with_cast.qgo_1))
      return false;
    if (!(this.rgo_1 === tmp0_other_with_cast.rgo_1))
      return false;
    return true;
  };
  function MutableCurrentlySelectedTowerState(currentTowerId) {
    this.vgo_1 = currentTowerId;
  }
  protoOf(MutableCurrentlySelectedTowerState).toString = function () {
    var tmp = this.vgo_1;
    return 'MutableCurrentlySelectedTowerState(currentTowerId=' + (tmp == null ? null : new EntityId(tmp)) + ')';
  };
  protoOf(MutableCurrentlySelectedTowerState).hashCode = function () {
    var tmp;
    var tmp_0 = this.vgo_1;
    if ((tmp_0 == null ? null : new EntityId(tmp_0)) == null) {
      tmp = 0;
    } else {
      tmp = EntityId__hashCode_impl_1s8fhw(this.vgo_1);
    }
    return tmp;
  };
  protoOf(MutableCurrentlySelectedTowerState).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MutableCurrentlySelectedTowerState))
      return false;
    var tmp0_other_with_cast = other instanceof MutableCurrentlySelectedTowerState ? other : THROW_CCE();
    var tmp = this.vgo_1;
    var tmp_0 = tmp == null ? null : new EntityId(tmp);
    var tmp_1 = tmp0_other_with_cast.vgo_1;
    if (!equals(tmp_0, tmp_1 == null ? null : new EntityId(tmp_1)))
      return false;
    return true;
  };
  function MutableEventQueueState(eventQueue) {
    var tmp;
    if (eventQueue === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = eventQueue;
    }
    eventQueue = tmp;
    this.wgo_1 = eventQueue;
  }
  protoOf(MutableEventQueueState).xgo = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp0_isNotEmpty = this.wgo_1;
    tmp$ret$0 = !tmp0_isNotEmpty.p();
    return tmp$ret$0;
  };
  protoOf(MutableEventQueueState).ygo = function (event) {
    this.wgo_1.b(event);
  };
  protoOf(MutableEventQueueState).c5z = function () {
    return toList(this.wgo_1);
  };
  protoOf(MutableEventQueueState).r7c = function () {
    return this.wgo_1.e4();
  };
  function MutableGoldState(initialCurrentGold, eventBus) {
    eventBus = eventBus === VOID ? null : eventBus;
    this.zgo_1 = eventBus;
    this.agp_1 = initialCurrentGold;
  }
  protoOf(MutableGoldState).bgp = function (value) {
    var prev = this.agp_1;
    this.agp_1 = value;
    var tmp0_safe_receiver = this.zgo_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.bg7(new GoldStateUpdated(prev, value));
    }
  };
  function MonsterComputeSpeedEffectSystem(engine) {
    System.call(this);
    this.egp_1 = engine;
    this.fgp_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(PathSequenceTraversalComponent)]));
    this.ggp_1 = this.egp_1.ego_1.vgb_1;
  }
  protoOf(MonsterComputeSpeedEffectSystem).jg2 = function () {
    return this.fgp_1;
  };
  protoOf(MonsterComputeSpeedEffectSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.MonsterComputeSpeedEffectSystem.update.<anonymous>' call
      var pathSequenceTraversalComponent = this.ggp_1.lg3(element, getKClass(PathSequenceTraversalComponent));
      var computedSpeedEffectComponent = this.ggp_1.lg3(element, getKClass(ComputedSpeedEffectComponent));
      var currentPosition = pathSequenceTraversalComponent.qg4_1.ngk();
      var tmp = computedSpeedEffectComponent;
      var tmp$ret$1;
      // Inline function 'kotlin.sequences.fold' call
      var tmp0_fold = this.egp_1.ego_1.dgc_1.tg1();
      var accumulator = 1.0;
      var tmp0_iterator_0 = tmp0_fold.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k().zg0_1;
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.system.MonsterComputeSpeedEffectSystem.update.<anonymous>.<anonymous>' call
        var tmp1__anonymous__uwfjfc = accumulator;
        var speedAreaEffectComponent = this.ggp_1.lg3(element_0, getKClass(EntitySpeedAreaComponent));
        var bottomLeftPositionComponent = this.ggp_1.lg3(element_0, getKClass(BottomLeftPositionComponent));
        var sizeComponent = this.ggp_1.lg3(element_0, getKClass(SizeComponent));
        var centerPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
        var radius = sizeComponent.zg4();
        var distance = currentPosition.ngb(centerPoint);
        var tmp_0;
        if (GameUnit__compareTo_impl_qyacwt_0(distance, radius) <= 0) {
          tmp_0 = tmp1__anonymous__uwfjfc * speedAreaEffectComponent.hg4_1;
        } else {
          tmp_0 = tmp1__anonymous__uwfjfc;
        }
        tmp$ret$0 = tmp_0;
        accumulator = tmp$ret$0;
      }
      tmp$ret$1 = accumulator;
      tmp.ag4_1 = tmp$ret$1;
    }
  };
  function MonsterDeathSystem(engine) {
    System.call(this);
    this.jgp_1 = engine;
    this.kgp_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(HealthComponent), getKClass(MaxHealthComponent)]));
    this.lgp_1 = this.jgp_1.ego_1.vgb_1;
  }
  protoOf(MonsterDeathSystem).jg2 = function () {
    return this.kgp_1;
  };
  protoOf(MonsterDeathSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.MonsterDeathSystem.update.<anonymous>' call
      var healthComponent = this.lgp_1.lg3(element, getKClass(HealthComponent));
      var maxHealthComponent = this.lgp_1.lg3(element, getKClass(MaxHealthComponent));
      var numDeaths = 0;
      while (healthComponent.mg4_1 <= 0.0) {
        var tmp0_this = healthComponent;
        tmp0_this.mg4_1 = tmp0_this.mg4_1 + maxHealthComponent.ng4_1;
        var tmp1 = numDeaths;
        numDeaths = tmp1 + 1 | 0;
      }
      this.jgp_1.dgo_1.bg7(new MonsterDeathsEvent(numDeaths));
    }
  };
  function MonsterMoveSystem(world) {
    System.call(this);
    this.ogp_1 = world;
    this.pgp_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(PathSequenceTraversalComponent), getKClass(VelocityComponent)]));
  }
  protoOf(MonsterMoveSystem).jg2 = function () {
    return this.pgp_1;
  };
  protoOf(MonsterMoveSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.MonsterMoveSystem.update.<anonymous>' call
      var traversal = this.ogp_1.lg3(element, getKClass(PathSequenceTraversalComponent)).qg4_1;
      var movementVelocityComponent = this.ogp_1.lg3(element, getKClass(VelocityComponent));
      var computedSpeedEffectComponent = this.ogp_1.lg3(element, getKClass(ComputedSpeedEffectComponent));
      var distanceTraversed = GameUnit__times_impl_soo0d9_0(movementVelocityComponent.mg5(deltaTime), computedSpeedEffectComponent.ag4_1);
      traversal.ogk(distanceTraversed);
    }
  };
  function MonsterRemoveSystem$update$lambda($this$modifyEntity) {
    $this$modifyEntity.dg3();
    return Unit_getInstance();
  }
  function MonsterRemoveSystem(world) {
    System.call(this);
    this.sgp_1 = world;
    this.tgp_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(PathSequenceTraversalComponent)]));
  }
  protoOf(MonsterRemoveSystem).jg2 = function () {
    return this.tgp_1;
  };
  protoOf(MonsterRemoveSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.ug2().vg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.MonsterRemoveSystem.update.<anonymous>' call
      var traversal = this.sgp_1.lg3(element, getKClass(PathSequenceTraversalComponent)).qg4_1;
      if (traversal.pgk()) {
        this.sgp_1.gg3(element, MonsterRemoveSystem$update$lambda);
      }
    }
  };
  function ProjectileCollideSystem$update$lambda($this$modifyEntity) {
    $this$modifyEntity.dg3();
    return Unit_getInstance();
  }
  function ProjectileCollideSystem(world) {
    System.call(this);
    this.wgp_1 = world;
    this.xgp_1 = new FamilyConfiguration(setOf([getKClass(ProjectileComponent), getKClass(MutableCenterPositionComponent), getKClass(TargetingComponent), getKClass(VelocityComponent), getKClass(ProjectileDamageComponent), getKClass(CollideWithTargetComponent)]));
  }
  protoOf(ProjectileCollideSystem).jg2 = function () {
    return this.xgp_1;
  };
  protoOf(ProjectileCollideSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.ProjectileCollideSystem.update.<anonymous>' call
      var targetingComponent = this.wgp_1.lg3(element, getKClass(TargetingComponent));
      var projectileDamageComponent = this.wgp_1.lg3(element, getKClass(ProjectileDamageComponent));
      var monsterHealthComponent = this.wgp_1.lg3(targetingComponent.cg5_1, getKClass(HealthComponent));
      var tmp0_this = monsterHealthComponent;
      tmp0_this.mg4_1 = tmp0_this.mg4_1 - projectileDamageComponent.rg4_1;
      this.wgp_1.gg3(element, ProjectileCollideSystem$update$lambda);
    }
  };
  function ProjectileMoveSystem$update$lambda($this$modifyEntity) {
    $this$modifyEntity.og2(CollideWithTargetComponent_getInstance());
    return Unit_getInstance();
  }
  function ProjectileMoveSystem(world) {
    System.call(this);
    this.agq_1 = world;
    this.bgq_1 = new FamilyConfiguration(setOf([getKClass(ProjectileComponent), getKClass(MutableCenterPositionComponent), getKClass(TargetingComponent), getKClass(VelocityComponent)]), VOID, setOf_0(getKClass(CollideWithTargetComponent)));
  }
  protoOf(ProjectileMoveSystem).jg2 = function () {
    return this.bgq_1;
  };
  protoOf(ProjectileMoveSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.ProjectileMoveSystem.update.<anonymous>' call
      var mutableCenterPositionComponent = this.agq_1.lg3(element, getKClass(MutableCenterPositionComponent));
      var targetingComponent = this.agq_1.lg3(element, getKClass(TargetingComponent));
      var velocityComponent = this.agq_1.lg3(element, getKClass(VelocityComponent));
      var monsterCenterPoint = this.agq_1.lg3(targetingComponent.cg5_1, getKClass(PathSequenceTraversalComponent)).qg4_1.ngk();
      var segmentTraversal = new SegmentTraversal(new Segment(new GameUnitTuple(mutableCenterPositionComponent.og4_1, mutableCenterPositionComponent.pg4_1), monsterCenterPoint));
      segmentTraversal.ogk(velocityComponent.mg5(deltaTime));
      mutableCenterPositionComponent.og4_1 = segmentTraversal.ugk_1.dg6_1;
      mutableCenterPositionComponent.pg4_1 = segmentTraversal.ugk_1.eg6_1;
      if (segmentTraversal.rgk()) {
        this.agq_1.gg3(element, ProjectileMoveSystem$update$lambda);
      }
    }
  };
  function ProjectileRemoveSystem$update$lambda($this$modifyEntity) {
    $this$modifyEntity.dg3();
    return Unit_getInstance();
  }
  function ProjectileRemoveSystem(world) {
    System.call(this);
    this.egq_1 = world;
    this.fgq_1 = new FamilyConfiguration(setOf([getKClass(ProjectileComponent), getKClass(MutableCenterPositionComponent), getKClass(TargetingComponent), getKClass(VelocityComponent)]));
  }
  protoOf(ProjectileRemoveSystem).jg2 = function () {
    return this.fgq_1;
  };
  protoOf(ProjectileRemoveSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.ProjectileRemoveSystem.update.<anonymous>' call
      var targetingComponent = this.egq_1.lg3(element, getKClass(TargetingComponent));
      if (!this.egq_1.xg1(targetingComponent.cg5_1)) {
        this.egq_1.gg3(element, ProjectileRemoveSystem$update$lambda);
      }
    }
  };
  function ReloadSystem$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function ReloadSystem$update$lambda($this$modifyEntity) {
    $this$modifyEntity.og2(ReadyToAttackComponent_getInstance());
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(ReloadDowntimeComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function ReloadSystem(engine) {
    System.call(this);
    this.igq_1 = engine;
    this.jgq_1 = this.igq_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.igq_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapApi) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(ReloadSystem$gameMapApi$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.kgq_1 = tmp$ret$1;
    this.lgq_1 = new FamilyConfiguration(setOf([getKClass(BaseWeaponSpeedComponent), getKClass(ReloadDowntimeComponent)]), VOID, setOf_0(getKClass(ReadyToAttackComponent)));
  }
  protoOf(ReloadSystem).jg2 = function () {
    return this.lgq_1;
  };
  protoOf(ReloadSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.ReloadSystem.update.<anonymous>' call
      var weaponSpeedMillis = this.kgq_1.ugq(element);
      var reloadDowntimeComponent = this.jgq_1.lg3(element, getKClass(ReloadDowntimeComponent));
      var tmp0_this = reloadDowntimeComponent;
      tmp0_this.tg4_1 = tmp0_this.tg4_1 + _Duration___get_inWholeMilliseconds__impl__msfiry(deltaTime).wi();
      // Inline function 'kotlin.require' call
      var tmp0_require = reloadDowntimeComponent.tg4_1 / weaponSpeedMillis < 2.0;
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_require) {
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.system.ReloadSystem.update.<anonymous>.<anonymous>' call
        tmp$ret$0 = 'Is eligible for 2 attacks in the same tick, which is weird...';
        var message = tmp$ret$0;
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
      if (reloadDowntimeComponent.tg4_1 >= weaponSpeedMillis) {
        this.jgq_1.gg3(element, ReloadSystem$update$lambda);
      }
    }
  };
  function TargetingAddSystem$update$lambda(this$0, $centerPoint) {
    return function (it) {
      var monsterCenterPoint = this$0.xgq_1.vgb_1.lg3(it.zg0_1, getKClass(PathSequenceTraversalComponent)).qg4_1.ngk();
      return to(it, new GameUnit(distance_0($centerPoint, monsterCenterPoint)));
    };
  }
  function TargetingAddSystem$update$lambda_0($nearestMonster) {
    return function ($this$modifyEntity) {
      $this$modifyEntity.og2(new TargetingComponent($nearestMonster.a5_1.zg0_1));
      return Unit_getInstance();
    };
  }
  function TargetingAddSystem(gameWorld) {
    System.call(this);
    this.xgq_1 = gameWorld;
    this.ygq_1 = new FamilyConfiguration(setOf([getKClass(EntityTowerComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent), getKClass(RangeComponent)]), VOID, setOf_0(getKClass(TargetingComponent)));
  }
  protoOf(TargetingAddSystem).jg2 = function () {
    return this.ygq_1;
  };
  protoOf(TargetingAddSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.TargetingAddSystem.update.<anonymous>' call
      var sizeComponent = this.xgq_1.vgb_1.lg3(element, getKClass(SizeComponent));
      var bottomLeftPositionComponent = this.xgq_1.vgb_1.lg3(element, getKClass(BottomLeftPositionComponent));
      var rangeComponent = this.xgq_1.vgb_1.lg3(element, getKClass(RangeComponent));
      var centerPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
      var tmp$ret$0;
      $l$block_0: {
        // Inline function 'kotlin.sequences.minByOrNull' call
        var tmp = this.xgq_1.cgc_1.tg1();
        var tmp0_minByOrNull = map(tmp, TargetingAddSystem$update$lambda(this, centerPoint));
        var iterator = tmp0_minByOrNull.h();
        if (!iterator.j()) {
          tmp$ret$0 = null;
          break $l$block_0;
        }
        var minElem = iterator.k();
        if (!iterator.j()) {
          tmp$ret$0 = minElem;
          break $l$block_0;
        }
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.system.TargetingAddSystem.update.<anonymous>.<anonymous>' call
        var tmp1__anonymous__uwfjfc = minElem;
        tmp$ret$1 = tmp1__anonymous__uwfjfc.b5_1.vg3_1;
        var minValue = tmp$ret$1;
        do {
          var e = iterator.k();
          var tmp$ret$2;
          // Inline function 'com.xenotactic.gamelogic.system.TargetingAddSystem.update.<anonymous>.<anonymous>' call
          tmp$ret$2 = e.b5_1.vg3_1;
          var v = tmp$ret$2;
          if (compareTo(new GameUnit(minValue), new GameUnit(v)) > 0) {
            minElem = e;
            minValue = v;
          }
        }
         while (iterator.j());
        tmp$ret$0 = minElem;
      }
      var nearestMonster = tmp$ret$0;
      if (!(nearestMonster == null)) {
        if (GameUnit__compareTo_impl_qyacwt_0(nearestMonster.b5_1.vg3_1, rangeComponent.sg4_1) <= 0) {
          this.xgq_1.vgb_1.gg3(element, TargetingAddSystem$update$lambda_0(nearestMonster));
        }
      }
    }
  };
  function Companion_28() {
    Companion_instance_28 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance();
    var tmp0_elvis_lhs = getKClass(TowerAttackSystem).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.zgq_1 = tmp$ret$0;
  }
  var Companion_instance_28;
  function Companion_getInstance_40() {
    if (Companion_instance_28 == null)
      new Companion_28();
    return Companion_instance_28;
  }
  function TowerAttackSystem$update$lambda($targetingComponent, $towerCenterPoint, $damage) {
    return function ($this$addEntity) {
      $this$addEntity.og2(ProjectileComponent_getInstance());
      $this$addEntity.og2($targetingComponent);
      $this$addEntity.og2(new VelocityComponent(toGameUnit(10)));
      $this$addEntity.og2(new MutableCenterPositionComponent($towerCenterPoint.dg6_1, $towerCenterPoint.eg6_1));
      $this$addEntity.og2(new ProjectileDamageComponent($damage));
      return Unit_getInstance();
    };
  }
  function TowerAttackSystem$update$lambda_0($this$modifyEntity) {
    $this$modifyEntity.og2(new ReloadDowntimeComponent(0.0));
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(ReadyToAttackComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function TowerAttackSystem(world, gameMapApi) {
    Companion_getInstance_40();
    System.call(this);
    this.cgr_1 = world;
    this.dgr_1 = gameMapApi;
    this.egr_1 = new FamilyConfiguration(setOf([getKClass(EntityTowerComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent), getKClass(RangeComponent), getKClass(TargetingComponent), getKClass(BaseWeaponSpeedComponent), getKClass(ReadyToAttackComponent)]));
  }
  protoOf(TowerAttackSystem).jg2 = function () {
    return this.egr_1;
  };
  protoOf(TowerAttackSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.gamelogic.system.TowerAttackSystem.update.<anonymous>' call
      var sizeComponent = this.cgr_1.lg3(element, getKClass(SizeComponent));
      var bottomLeftPositionComponent = this.cgr_1.lg3(element, getKClass(BottomLeftPositionComponent));
      var towerCenterPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
      var damage = this.dgr_1.fgr(element);
      var targetingComponent = this.cgr_1.lg3(element, getKClass(TargetingComponent));
      this.cgr_1.fg3(TowerAttackSystem$update$lambda(targetingComponent, towerCenterPoint, damage));
      this.cgr_1.gg3(element, TowerAttackSystem$update$lambda_0);
    }
  };
  function TowerTargetingRemoveSystem$update$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(TargetingComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function TowerTargetingRemoveSystem$update$lambda_0($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(TargetingComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function TowerTargetingRemoveSystem(world) {
    System.call(this);
    this.igr_1 = world;
    this.jgr_1 = new FamilyConfiguration(setOf([getKClass(EntityTowerComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent), getKClass(RangeComponent), getKClass(TargetingComponent)]));
  }
  protoOf(TowerTargetingRemoveSystem).jg2 = function () {
    return this.jgr_1;
  };
  protoOf(TowerTargetingRemoveSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.ug2().vg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      var tmp$ret$0;
      $l$block: {
        // Inline function 'com.xenotactic.gamelogic.system.TowerTargetingRemoveSystem.update.<anonymous>' call
        var targetingComponent = this.igr_1.lg3(element, getKClass(TargetingComponent));
        if (!this.igr_1.xg1(targetingComponent.cg5_1)) {
          this.igr_1.gg3(element, TowerTargetingRemoveSystem$update$lambda);
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        }
        var rangeComponent = this.igr_1.lg3(element, getKClass(RangeComponent));
        var sizeComponent = this.igr_1.lg3(element, getKClass(SizeComponent));
        var bottomLeftPositionComponent = this.igr_1.lg3(element, getKClass(BottomLeftPositionComponent));
        var centerPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
        var monsterCenterPoint = this.igr_1.lg3(targetingComponent.cg5_1, getKClass(PathSequenceTraversalComponent)).qg4_1.ngk();
        if (GameUnit__compareTo_impl_qyacwt_0(distance_0(centerPoint, monsterCenterPoint), rangeComponent.sg4_1) > 0) {
          this.igr_1.gg3(element, TowerTargetingRemoveSystem$update$lambda_0);
        }
      }
    }
  };
  function AsepriteModel(baseWidth, baseHeight, frames) {
    this.kgr_1 = baseWidth;
    this.lgr_1 = baseHeight;
    this.mgr_1 = frames;
  }
  protoOf(AsepriteModel).ngr = function (name) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.mgr_1;
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'com.xenotactic.gamelogic.utils.AsepriteModel.getAsepriteLayerWithAllFrames.<anonymous>' call
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.collections.first' call
        var tmp0_first = item.ogr_1;
        var tmp0_iterator_0 = tmp0_first.h();
        while (tmp0_iterator_0.j()) {
          var element = tmp0_iterator_0.k();
          var tmp$ret$0;
          // Inline function 'com.xenotactic.gamelogic.utils.AsepriteModel.getAsepriteLayerWithAllFrames.<anonymous>.<anonymous>' call
          tmp$ret$0 = element.rgr_1 === name;
          if (tmp$ret$0) {
            tmp$ret$1 = element;
            break $l$block;
          }
        }
        throw NoSuchElementException_init_$Create$('Collection contains no element matching the predicate.');
      }
      tmp$ret$2 = tmp$ret$1;
      tmp0_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp0_mapTo;
    tmp$ret$4 = tmp$ret$3;
    var frames = tmp$ret$4;
    return new AsepriteLayerWithAllFrames(this.kgr_1, this.lgr_1, frames);
  };
  protoOf(AsepriteModel).toString = function () {
    return 'AsepriteModel(baseWidth=' + this.kgr_1 + ', baseHeight=' + this.lgr_1 + ', frames=' + this.mgr_1 + ')';
  };
  protoOf(AsepriteModel).hashCode = function () {
    var result = this.kgr_1;
    result = imul(result, 31) + this.lgr_1 | 0;
    result = imul(result, 31) + hashCode(this.mgr_1) | 0;
    return result;
  };
  protoOf(AsepriteModel).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AsepriteModel))
      return false;
    var tmp0_other_with_cast = other instanceof AsepriteModel ? other : THROW_CCE();
    if (!(this.kgr_1 === tmp0_other_with_cast.kgr_1))
      return false;
    if (!(this.lgr_1 === tmp0_other_with_cast.lgr_1))
      return false;
    if (!equals(this.mgr_1, tmp0_other_with_cast.mgr_1))
      return false;
    return true;
  };
  function toAsepriteModel(_this__u8e3s4) {
    var imageData = _this__u8e3s4.s6n_1;
    var baseWidth = imageData.f6n_1;
    var baseHeight = imageData.g6n_1;
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = imageData.d6n_1;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$3;
      // Inline function 'com.xenotactic.gamelogic.utils.toAsepriteModel.<anonymous>' call
      var imageFrameLayers = item.q6m_1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.map' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo_0 = ArrayList_init_$Create$_0(collectionSizeOrDefault(imageFrameLayers, 10));
      var tmp0_iterator_0 = imageFrameLayers.h();
      while (tmp0_iterator_0.j()) {
        var item_0 = tmp0_iterator_0.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.utils.toAsepriteModel.<anonymous>.<anonymous>' call
        var tmp = item_0.k6k_1;
        var aseLayer = tmp instanceof AseLayer ? tmp : THROW_CCE();
        var originalAseIndex = aseLayer.e6h_1;
        var id = aseLayer.h6k_1;
        var name = ensureNotNull(aseLayer.i6k_1);
        var offsetX = item_0.l6k_1;
        var offsetY = item_0.m6k_1;
        var slice = item_0.s6k_1;
        tmp$ret$0 = new AsepriteLayer(originalAseIndex, id, name, baseWidth, baseHeight, slice, offsetX, offsetY);
        tmp0_mapTo_0.b(tmp$ret$0);
      }
      tmp$ret$1 = tmp0_mapTo_0;
      tmp$ret$2 = tmp$ret$1;
      var aseLayers = tmp$ret$2;
      tmp$ret$3 = new AsepriteFrameWithAllLayers(aseLayers);
      tmp0_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp0_mapTo;
    tmp$ret$5 = tmp$ret$4;
    var frames = tmp$ret$5;
    return new AsepriteModel(baseWidth, baseHeight, frames);
  }
  function createMergedBitmapInternal($this, layers) {
    var uncroppedBitmap = new Bitmap32($this.xgr(), $this.ygr());
    var tmp0_iterator = layers.h();
    while (tmp0_iterator.j()) {
      var layer = tmp0_iterator.k();
      var bmp = get_bmp(layer.ugr_1);
      // Inline function 'korlibs.image.bitmap.Bitmap.forEach' call
      var tmp0_forEach = bmp.s4q_1 - 0 | 0;
      var tmp1_forEach = bmp.t4q_1 - 0 | 0;
      var inductionVariable = 0;
      var last = 0 + tmp1_forEach | 0;
      if (inductionVariable < last)
        do {
          var y = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var n = bmp.d4s(0, 0 + y | 0);
          var inductionVariable_0 = 0;
          var last_0 = 0 + tmp0_forEach | 0;
          if (inductionVariable_0 < last_0)
            do {
              var x = inductionVariable_0;
              inductionVariable_0 = inductionVariable_0 + 1 | 0;
              // Inline function 'com.xenotactic.gamelogic.utils.AsepriteFrameWithAllLayers.createMergedBitmapInternal.<anonymous>' call
              var tmp2 = n;
              n = tmp2 + 1 | 0;
              var tmp2__anonymous__z9zvc9 = tmp2;
              var pixel = bmp.c4y(x, y);
              if (!(_RGBA___get_a__impl__6msvu7(pixel) === 0)) {
                uncroppedBitmap.i51(x + layer.vgr_1 | 0, y + layer.wgr_1 | 0, pixel);
              }
            }
             while (inductionVariable_0 < last_0);
        }
         while (inductionVariable < last);
    }
    return uncroppedBitmap;
  }
  function AsepriteFrameWithAllLayers(layers) {
    this.ogr_1 = layers;
  }
  protoOf(AsepriteFrameWithAllLayers).xgr = function () {
    return first(this.ogr_1).sgr_1;
  };
  protoOf(AsepriteFrameWithAllLayers).ygr = function () {
    return first(this.ogr_1).tgr_1;
  };
  protoOf(AsepriteFrameWithAllLayers).zgr = function (layerNames) {
    var tmp$ret$6;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$5;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(layerNames.length);
    var indexedObject = layerNames;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$4;
      // Inline function 'com.xenotactic.gamelogic.utils.AsepriteFrameWithAllLayers.createMergedBitmap.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'kotlin.collections.filter' call
      var tmp1_filter = this.ogr_1;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.filterTo' call
      var tmp0_filterTo = ArrayList_init_$Create$();
      var tmp0_iterator = tmp1_filter.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.gamelogic.utils.AsepriteFrameWithAllLayers.createMergedBitmap.<anonymous>.<anonymous>' call
        tmp$ret$0 = element.rgr_1 === item;
        if (tmp$ret$0) {
          tmp0_filterTo.b(element);
        }
      }
      tmp$ret$1 = tmp0_filterTo;
      tmp$ret$2 = tmp$ret$1;
      var layersMatching = tmp$ret$2;
      // Inline function 'kotlin.require' call
      var tmp2_require = layersMatching.l() === 1;
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp2_require) {
        var tmp$ret$3;
        // Inline function 'com.xenotactic.gamelogic.utils.AsepriteFrameWithAllLayers.createMergedBitmap.<anonymous>.<anonymous>' call
        tmp$ret$3 = 'There should be only one layer with that name: ' + item + ', but found: ' + layersMatching;
        var message = tmp$ret$3;
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
      tmp$ret$4 = first(layersMatching);
      tmp0_mapTo.b(tmp$ret$4);
    }
    tmp$ret$5 = tmp0_mapTo;
    tmp$ret$6 = tmp$ret$5;
    var layers = tmp$ret$6;
    return createMergedBitmapInternal(this, layers);
  };
  protoOf(AsepriteFrameWithAllLayers).toString = function () {
    return 'AsepriteFrameWithAllLayers(layers=' + this.ogr_1 + ')';
  };
  protoOf(AsepriteFrameWithAllLayers).hashCode = function () {
    return hashCode(this.ogr_1);
  };
  protoOf(AsepriteFrameWithAllLayers).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AsepriteFrameWithAllLayers))
      return false;
    var tmp0_other_with_cast = other instanceof AsepriteFrameWithAllLayers ? other : THROW_CCE();
    if (!equals(this.ogr_1, tmp0_other_with_cast.ogr_1))
      return false;
    return true;
  };
  function AsepriteLayer(originalAseIndex, id, name, baseWidth, baseHeight, bitmapSlice, offsetX, offsetY) {
    this.pgr_1 = originalAseIndex;
    this.qgr_1 = id;
    this.rgr_1 = name;
    this.sgr_1 = baseWidth;
    this.tgr_1 = baseHeight;
    this.ugr_1 = bitmapSlice;
    this.vgr_1 = offsetX;
    this.wgr_1 = offsetY;
  }
  protoOf(AsepriteLayer).ags = function () {
    var uncroppedBitmap = new Bitmap32(this.sgr_1, this.tgr_1);
    uncroppedBitmap.v51(get_bmp(this.ugr_1).f4r(), this.vgr_1, this.wgr_1);
    return uncroppedBitmap;
  };
  protoOf(AsepriteLayer).toString = function () {
    return 'AsepriteLayer(originalAseIndex=' + this.pgr_1 + ', id=' + this.qgr_1 + ', name=' + this.rgr_1 + ', baseWidth=' + this.sgr_1 + ', baseHeight=' + this.tgr_1 + ', bitmapSlice=' + this.ugr_1 + ', offsetX=' + this.vgr_1 + ', offsetY=' + this.wgr_1 + ')';
  };
  protoOf(AsepriteLayer).hashCode = function () {
    var result = this.pgr_1;
    result = imul(result, 31) + this.qgr_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.rgr_1) | 0;
    result = imul(result, 31) + this.sgr_1 | 0;
    result = imul(result, 31) + this.tgr_1 | 0;
    result = imul(result, 31) + this.ugr_1.hashCode() | 0;
    result = imul(result, 31) + this.vgr_1 | 0;
    result = imul(result, 31) + this.wgr_1 | 0;
    return result;
  };
  protoOf(AsepriteLayer).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AsepriteLayer))
      return false;
    var tmp0_other_with_cast = other instanceof AsepriteLayer ? other : THROW_CCE();
    if (!(this.pgr_1 === tmp0_other_with_cast.pgr_1))
      return false;
    if (!(this.qgr_1 === tmp0_other_with_cast.qgr_1))
      return false;
    if (!(this.rgr_1 === tmp0_other_with_cast.rgr_1))
      return false;
    if (!(this.sgr_1 === tmp0_other_with_cast.sgr_1))
      return false;
    if (!(this.tgr_1 === tmp0_other_with_cast.tgr_1))
      return false;
    if (!this.ugr_1.equals(tmp0_other_with_cast.ugr_1))
      return false;
    if (!(this.vgr_1 === tmp0_other_with_cast.vgr_1))
      return false;
    if (!(this.wgr_1 === tmp0_other_with_cast.wgr_1))
      return false;
    return true;
  };
  function AsepriteLayerWithAllFrames(baseWidth, baseHeight, frames) {
    this.bgs_1 = baseWidth;
    this.cgs_1 = baseHeight;
    this.dgs_1 = frames;
  }
  protoOf(AsepriteLayerWithAllFrames).toString = function () {
    return 'AsepriteLayerWithAllFrames(baseWidth=' + this.bgs_1 + ', baseHeight=' + this.cgs_1 + ', frames=' + this.dgs_1 + ')';
  };
  protoOf(AsepriteLayerWithAllFrames).hashCode = function () {
    var result = this.bgs_1;
    result = imul(result, 31) + this.cgs_1 | 0;
    result = imul(result, 31) + hashCode(this.dgs_1) | 0;
    return result;
  };
  protoOf(AsepriteLayerWithAllFrames).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AsepriteLayerWithAllFrames))
      return false;
    var tmp0_other_with_cast = other instanceof AsepriteLayerWithAllFrames ? other : THROW_CCE();
    if (!(this.bgs_1 === tmp0_other_with_cast.bgs_1))
      return false;
    if (!(this.cgs_1 === tmp0_other_with_cast.cgs_1))
      return false;
    if (!equals(this.dgs_1, tmp0_other_with_cast.dgs_1))
      return false;
    return true;
  };
  function Engine(eventBus, gameWorld) {
    gameWorld = gameWorld === VOID ? new GameWorld() : gameWorld;
    this.dgo_1 = eventBus;
    this.ego_1 = gameWorld;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.fgo_1 = tmp$ret$0;
    this.ggo_1 = new TypedInjections();
    this.hgo_1 = new TypedInjections();
  }
  function EntitiesBlockingEntityResult(topLeftDiagonalBlocking, topRightDiagonalBlocking, bottomLeftDiagonalBlocking, bottomRightDiagonalBlocking, topPartiallyBlocking, bottomPartiallyBlocking, leftPartiallyBlocking, rightPartiallyBlocking) {
    topLeftDiagonalBlocking = topLeftDiagonalBlocking === VOID ? false : topLeftDiagonalBlocking;
    topRightDiagonalBlocking = topRightDiagonalBlocking === VOID ? false : topRightDiagonalBlocking;
    bottomLeftDiagonalBlocking = bottomLeftDiagonalBlocking === VOID ? false : bottomLeftDiagonalBlocking;
    bottomRightDiagonalBlocking = bottomRightDiagonalBlocking === VOID ? false : bottomRightDiagonalBlocking;
    topPartiallyBlocking = topPartiallyBlocking === VOID ? false : topPartiallyBlocking;
    bottomPartiallyBlocking = bottomPartiallyBlocking === VOID ? false : bottomPartiallyBlocking;
    leftPartiallyBlocking = leftPartiallyBlocking === VOID ? false : leftPartiallyBlocking;
    rightPartiallyBlocking = rightPartiallyBlocking === VOID ? false : rightPartiallyBlocking;
    this.qgn_1 = topLeftDiagonalBlocking;
    this.rgn_1 = topRightDiagonalBlocking;
    this.sgn_1 = bottomLeftDiagonalBlocking;
    this.tgn_1 = bottomRightDiagonalBlocking;
    this.ugn_1 = topPartiallyBlocking;
    this.vgn_1 = bottomPartiallyBlocking;
    this.wgn_1 = leftPartiallyBlocking;
    this.xgn_1 = rightPartiallyBlocking;
  }
  protoOf(EntitiesBlockingEntityResult).ygn = function () {
    return ((((((this.qgn_1 ? true : this.rgn_1) ? true : this.sgn_1) ? true : this.tgn_1) ? true : this.ugn_1) ? true : this.vgn_1) ? true : this.wgn_1) ? true : this.xgn_1;
  };
  protoOf(EntitiesBlockingEntityResult).toString = function () {
    return 'EntitiesBlockingEntityResult(topLeftDiagonalBlocking=' + this.qgn_1 + ', topRightDiagonalBlocking=' + this.rgn_1 + ', bottomLeftDiagonalBlocking=' + this.sgn_1 + ', bottomRightDiagonalBlocking=' + this.tgn_1 + ', topPartiallyBlocking=' + this.ugn_1 + ', bottomPartiallyBlocking=' + this.vgn_1 + ', leftPartiallyBlocking=' + this.wgn_1 + ', rightPartiallyBlocking=' + this.xgn_1 + ')';
  };
  protoOf(EntitiesBlockingEntityResult).hashCode = function () {
    var result = this.qgn_1 | 0;
    result = imul(result, 31) + (this.rgn_1 | 0) | 0;
    result = imul(result, 31) + (this.sgn_1 | 0) | 0;
    result = imul(result, 31) + (this.tgn_1 | 0) | 0;
    result = imul(result, 31) + (this.ugn_1 | 0) | 0;
    result = imul(result, 31) + (this.vgn_1 | 0) | 0;
    result = imul(result, 31) + (this.wgn_1 | 0) | 0;
    result = imul(result, 31) + (this.xgn_1 | 0) | 0;
    return result;
  };
  protoOf(EntitiesBlockingEntityResult).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntitiesBlockingEntityResult))
      return false;
    var tmp0_other_with_cast = other instanceof EntitiesBlockingEntityResult ? other : THROW_CCE();
    if (!(this.qgn_1 === tmp0_other_with_cast.qgn_1))
      return false;
    if (!(this.rgn_1 === tmp0_other_with_cast.rgn_1))
      return false;
    if (!(this.sgn_1 === tmp0_other_with_cast.sgn_1))
      return false;
    if (!(this.tgn_1 === tmp0_other_with_cast.tgn_1))
      return false;
    if (!(this.ugn_1 === tmp0_other_with_cast.ugn_1))
      return false;
    if (!(this.vgn_1 === tmp0_other_with_cast.vgn_1))
      return false;
    if (!(this.wgn_1 === tmp0_other_with_cast.wgn_1))
      return false;
    if (!(this.xgn_1 === tmp0_other_with_cast.xgn_1))
      return false;
    return true;
  };
  function EntitiesBlockingEntityUtil() {
    EntitiesBlockingEntityUtil_instance = this;
  }
  protoOf(EntitiesBlockingEntityUtil).pgn = function (entity, gameMap) {
    var blockingPoints = gameMap.jga();
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = GameUnit__until_impl_8xh2rf(entity.jg8(), entity.bgh());
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_any.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k().vg3_1;
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.utils.EntitiesBlockingEntityUtil.invoke.<anonymous>' call
        tmp$ret$1 = blockingPoints.hg6(element, entity.agh());
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    var topPartiallyBlocking = tmp$ret$0;
    var tmp$ret$2;
    $l$block_2: {
      // Inline function 'kotlin.collections.any' call
      var tmp1_any = GameUnit__until_impl_8xh2rf(entity.jg8(), entity.bgh());
      var tmp_0;
      if (isInterface(tmp1_any, Collection)) {
        tmp_0 = tmp1_any.p();
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp$ret$2 = false;
        break $l$block_2;
      }
      var tmp0_iterator_0 = tmp1_any.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k().vg3_1;
        var tmp$ret$3;
        // Inline function 'com.xenotactic.gamelogic.utils.EntitiesBlockingEntityUtil.invoke.<anonymous>' call
        tmp$ret$3 = blockingPoints.hg6(element_0, GameUnit__minus_impl_uz6kmr_0(entity.kg8(), 1));
        if (tmp$ret$3) {
          tmp$ret$2 = true;
          break $l$block_2;
        }
      }
      tmp$ret$2 = false;
    }
    var bottomPartiallyBlocking = tmp$ret$2;
    var tmp$ret$4;
    $l$block_4: {
      // Inline function 'kotlin.collections.any' call
      var tmp2_any = GameUnit__until_impl_8xh2rf(entity.kg8(), entity.agh());
      var tmp_1;
      if (isInterface(tmp2_any, Collection)) {
        tmp_1 = tmp2_any.p();
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp$ret$4 = false;
        break $l$block_4;
      }
      var tmp0_iterator_1 = tmp2_any.h();
      while (tmp0_iterator_1.j()) {
        var element_1 = tmp0_iterator_1.k().vg3_1;
        var tmp$ret$5;
        // Inline function 'com.xenotactic.gamelogic.utils.EntitiesBlockingEntityUtil.invoke.<anonymous>' call
        tmp$ret$5 = blockingPoints.hg6(GameUnit__minus_impl_uz6kmr_0(entity.jg8(), 1), element_1);
        if (tmp$ret$5) {
          tmp$ret$4 = true;
          break $l$block_4;
        }
      }
      tmp$ret$4 = false;
    }
    var leftPartiallyBlocking = tmp$ret$4;
    var tmp$ret$6;
    $l$block_6: {
      // Inline function 'kotlin.collections.any' call
      var tmp3_any = GameUnit__until_impl_8xh2rf(entity.kg8(), entity.agh());
      var tmp_2;
      if (isInterface(tmp3_any, Collection)) {
        tmp_2 = tmp3_any.p();
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp$ret$6 = false;
        break $l$block_6;
      }
      var tmp0_iterator_2 = tmp3_any.h();
      while (tmp0_iterator_2.j()) {
        var element_2 = tmp0_iterator_2.k().vg3_1;
        var tmp$ret$7;
        // Inline function 'com.xenotactic.gamelogic.utils.EntitiesBlockingEntityUtil.invoke.<anonymous>' call
        tmp$ret$7 = blockingPoints.hg6(entity.bgh(), element_2);
        if (tmp$ret$7) {
          tmp$ret$6 = true;
          break $l$block_6;
        }
      }
      tmp$ret$6 = false;
    }
    var rightPartiallyBlocking = tmp$ret$6;
    return new EntitiesBlockingEntityResult(blockingPoints.ig6(GameUnit__toInt_impl_9qttgv(entity.jg8()) - 1 | 0, GameUnit__toInt_impl_9qttgv(entity.agh())), blockingPoints.ig6(GameUnit__toInt_impl_9qttgv(entity.bgh()), GameUnit__toInt_impl_9qttgv(entity.agh())), blockingPoints.ig6(GameUnit__toInt_impl_9qttgv(entity.jg8()) - 1 | 0, GameUnit__toInt_impl_9qttgv(entity.kg8()) - 1 | 0), blockingPoints.ig6(GameUnit__toInt_impl_9qttgv(entity.bgh()), GameUnit__toInt_impl_9qttgv(entity.kg8()) - 1 | 0), topPartiallyBlocking, bottomPartiallyBlocking, leftPartiallyBlocking, rightPartiallyBlocking);
  };
  var EntitiesBlockingEntityUtil_instance;
  function EntitiesBlockingEntityUtil_getInstance() {
    if (EntitiesBlockingEntityUtil_instance == null)
      new EntitiesBlockingEntityUtil();
    return EntitiesBlockingEntityUtil_instance;
  }
  function EventLog(events) {
    var tmp;
    if (events === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = events;
    }
    events = tmp;
    this.egs_1 = events;
  }
  protoOf(EventLog).fgs = function (logEntry) {
    this.egs_1.b(logEntry);
  };
  protoOf(EventLog).toString = function () {
    return 'EventLog(events=' + this.egs_1 + ')';
  };
  protoOf(EventLog).hashCode = function () {
    return hashCode(this.egs_1);
  };
  protoOf(EventLog).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EventLog))
      return false;
    var tmp0_other_with_cast = other instanceof EventLog ? other : THROW_CCE();
    if (!equals(this.egs_1, tmp0_other_with_cast.egs_1))
      return false;
    return true;
  };
  function LogEntry(tickNum, events) {
    this.ggs_1 = tickNum;
    this.hgs_1 = events;
  }
  protoOf(LogEntry).toString = function () {
    return 'LogEntry(tickNum=' + toString(this.ggs_1) + ', events=' + this.hgs_1 + ')';
  };
  protoOf(LogEntry).hashCode = function () {
    var result = this.ggs_1.hashCode();
    result = imul(result, 31) + hashCode(this.hgs_1) | 0;
    return result;
  };
  protoOf(LogEntry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof LogEntry))
      return false;
    var tmp0_other_with_cast = other instanceof LogEntry ? other : THROW_CCE();
    if (!this.ggs_1.equals(tmp0_other_with_cast.ggs_1))
      return false;
    if (!equals(this.hgs_1, tmp0_other_with_cast.hgs_1))
      return false;
    return true;
  };
  function placeEntities($this, entities) {
    $this.tgq_1.ygo(new PlaceEntities(toList(entities)));
  }
  function GameMapApi$gameMapPathState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapPathState);
  }
  function GameMapApi$gameMapDimensionsState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapDimensionsState);
  }
  function GameMapApi$gameplayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function GameMapApi$mutableEventQueueState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableEventQueueState);
  }
  function GameMapApi$spawnCreep$lambda(this$0) {
    return function ($this$addEntity) {
      $this$addEntity.og2(new EntityTypeComponent(MapEntityType_MONSTER_getInstance()));
      $this$addEntity.og2(new SizeComponent(toGameUnit(1), toGameUnit(1)));
      $this$addEntity.og2(MonsterComponent_getInstance());
      $this$addEntity.og2(new VelocityComponent());
      var maxHealthComponent = new MaxHealthComponent(100.0);
      $this$addEntity.og2(maxHealthComponent);
      $this$addEntity.og2(new HealthComponent(maxHealthComponent.ng4_1));
      $this$addEntity.og2(new AnimationComponent(100.0, 0.0));
      $this$addEntity.og2(new ComputedSpeedEffectComponent(1.0));
      this$0.mgq_1.dgo_1.bg7(new AddedMonsterEntityEvent($this$addEntity.yg2_1));
      var pathSequenceTraversal = new PathSequenceTraversal(ensureNotNull(this$0.pgq_1.jgo_1));
      $this$addEntity.og2(new PathSequenceTraversalComponent(pathSequenceTraversal));
      return Unit_getInstance();
    };
  }
  function GameMapApi$getIntersectingEntities$lambda(this$0, $rect) {
    return function (it) {
      var comp = this$0.ngq_1.igc_1.tg0(it.zg0_1);
      var tmp;
      if ($rect.r41(comp.fg5_1.tbn())) {
        tmp = it.zg0_1;
      } else {
        tmp = null;
      }
      var tmp_0 = tmp;
      return tmp_0 == null ? null : new EntityId(tmp_0);
    };
  }
  function GameMapApi(engine) {
    this.mgq_1 = engine;
    this.ngq_1 = this.mgq_1.ego_1;
    this.ogq_1 = this.ngq_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.mgq_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapPathState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapPathState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(GameMapApi$gameMapPathState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.pgq_1 = tmp$ret$1;
    this.qgq_1 = this.mgq_1.dgo_1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.mgq_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameMapDimensionsState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameMapDimensionsState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(GameMapApi$gameMapDimensionsState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.rgq_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.mgq_1.ggo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof GameplayState) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(GameMapApi$gameplayState$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.sgq_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_2 = this.mgq_1.ggo_1;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_9 = tmp0_getSingleton_2.wg2_1.h3(getKClass(MutableEventQueueState));
    tmp$ret$6 = (tmp_9 == null ? true : tmp_9 instanceof MutableEventQueueState) ? tmp_9 : THROW_CCE();
    var tmp0_elvis_lhs_2 = tmp$ret$6;
    var tmp_10;
    if (tmp0_elvis_lhs_2 == null) {
      throw new SingletonInjectionDoesNotExistException(GameMapApi$mutableEventQueueState$lambda);
    } else {
      tmp_10 = tmp0_elvis_lhs_2;
    }
    tmp$ret$7 = tmp_10;
    tmp_8.tgq_1 = tmp$ret$7;
  }
  protoOf(GameMapApi).sg9 = function () {
    return this.ngq_1.pgc().l();
  };
  protoOf(GameMapApi).igs = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'com.xenotactic.gamelogic.utils.GameMapApi.<get-numCompletedTeleports>.<anonymous>' call
    var numTpIn = this.ngq_1.qgc().l();
    var numTpOut = this.ngq_1.rgc().l();
    var tmp$ret$0;
    // Inline function 'kotlin.comparisons.minOf' call
    tmp$ret$0 = Math.min(numTpIn, numTpOut);
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(GameMapApi).jgs = function (entities) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(entities.length);
    var indexedObject = entities;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.utils.GameMapApi.checkNewEntitiesBlocksPath.<anonymous>' call
      tmp$ret$0 = toRectangleEntity(item);
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    var asRects = tmp$ret$2;
    var tmp = this.ngq_1.ygc(this.rgq_1.ago_1, this.rgq_1.bgo_1, VOID, asRects);
    return tmp instanceof Failure;
  };
  protoOf(GameMapApi).kgs = function (entity) {
    var asRect = toRectangleEntity(entity);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = this.ngq_1.tgc();
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_any.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.utils.GameMapApi.checkNewEntityIntersectsExistingBlockingEntities.<anonymous>' call
        tmp$ret$1 = rectangleIntersects(asRect, toRectangleEntity(element));
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(GameMapApi).lgs = function (otherGameWorld) {
    var entities = otherGameWorld.vgb_1.pg3();
    placeEntities(this, entities);
  };
  protoOf(GameMapApi).mgs = function (entities) {
    return placeEntities(this, asIterable(entities));
  };
  protoOf(GameMapApi).ngs = function () {
    this.ngq_1.vgb_1.fg3(GameMapApi$spawnCreep$lambda(this));
  };
  protoOf(GameMapApi).ogs = function (rect) {
    var tmp = this.ngq_1.xgb_1.tg1();
    return toSet_0(mapNotNull(tmp, GameMapApi$getIntersectingEntities$lambda(this, rect)));
  };
  protoOf(GameMapApi).pgs = function (entityType) {
    var tmp0_subject = entityType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = 'Start';
        break;
      case 1:
        tmp = 'Finish';
        break;
      case 2:
        tmp = 'Checkpoint ' + (this.sg9() + 1 | 0);
        break;
      case 3:
        tmp = 'Rock';
        break;
      case 4:
        tmp = 'Tower';
        break;
      case 5:
        tmp = 'Teleport In ' + (this.igs() + 1 | 0);
        break;
      case 6:
        tmp = 'Teleport Out ' + (this.igs() + 1 | 0);
        break;
      case 7:
        throw new NotImplementedError();
      case 8:
        throw new NotImplementedError();
      case 9:
        throw new NotImplementedError();
      case 10:
        tmp = 'Supply depot';
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    var entityName = tmp;
    return 'Placement Mode: ' + entityName;
  };
  protoOf(GameMapApi).qgs = function (entities) {
    this.tgq_1.ygo(new RemoveEntities(entities));
  };
  protoOf(GameMapApi).fgr = function (towerId) {
    var baseDamageComponent = this.ogq_1.lg3(towerId, getKClass(BaseDamageComponent));
    var damageUpgradeComponent = this.ogq_1.lg3(towerId, getKClass(DamageUpgradeComponent));
    var damageMultiplierComponent = this.ogq_1.lg3(towerId, getKClass(DamageMultiplierComponent));
    return (baseDamageComponent.sg3_1 + damageUpgradeComponent.cg4_1) * damageMultiplierComponent.bg4_1;
  };
  protoOf(GameMapApi).ugq = function (towerId) {
    var baseWeaponSpeedComponent = this.ogq_1.lg3(towerId, getKClass(BaseWeaponSpeedComponent));
    var speedUpgradeComponent = this.ogq_1.lg3(towerId, getKClass(SpeedUpgradeComponent));
    var speedIncreasePerUpgrade = this.sgq_1.ngo_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    var tmp0_pow = 1 + speedIncreasePerUpgrade;
    var tmp1_pow = speedUpgradeComponent.ag5_1;
    tmp$ret$0 = Math.pow(tmp0_pow, tmp1_pow);
    return baseWeaponSpeedComponent.tg3_1 / tmp$ret$0;
  };
  protoOf(GameMapApi).rgs = function (towerId, weaponSpeed) {
    return 1000.0 / weaponSpeed;
  };
  protoOf(GameMapApi).sgs = function (towerId, weaponSpeed, $super) {
    weaponSpeed = weaponSpeed === VOID ? this.ugq(towerId) : weaponSpeed;
    return $super === VOID ? this.rgs(towerId, weaponSpeed) : $super.rgs.call(this, new EntityId(towerId), weaponSpeed);
  };
  protoOf(GameMapApi).tgs = function () {
    var currentSupplyLimit = this.ngq_1.vgc(this.sgq_1.tgo_1, this.sgq_1.ugo_1, this.sgq_1.sgo_1);
    var towers = this.ngq_1.sgc();
    // Inline function 'kotlin.require' call
    var tmp0_require = towers.l() <= currentSupplyLimit;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    return towers.l() === currentSupplyLimit;
  };
  function handleRemoveEntitiesEvent($this, event) {
    var tmp0_iterator = event.ugs_1.h();
    $l$loop: while (tmp0_iterator.j()) {
      var it = tmp0_iterator.k().zg0_1;
      var mapEntityComponent = $this.zgs_1.mg3(it, getKClass(EntityTypeComponent));
      if (!(mapEntityComponent == null)) {
        var tmp1_subject = mapEntityComponent.lg4_1;
        var tmp0 = tmp1_subject.b2_1;
        switch (tmp0) {
          case 0:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 1:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 2:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 3:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 4:
            var tmp2_this = $this.igt_1;
            tmp2_this.bgp(tmp2_this.agp_1 + $this.ggt_1.ogo_1 | 0);
            ;
            break;
          case 5:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 6:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 7:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 8:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 9:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 10:
            var result = checkCanSellSupplyDepotEntity($this.vgs_1);
            if (result instanceof Errors) {
              $this.ygs_1.bg7(new InformErrorMessageEvent(result.lgt()));
              break $l$loop;
            } else {
              if (equals(result, Ok_getInstance()))
              ;
            }

            var tmp3_this = $this.igt_1;
            tmp3_this.bgp(tmp3_this.agp_1 + $this.ggt_1.rgo_1 | 0);
            ;
            break;
        }
      }
      $this.xgs_1.vgb_1.gg3(it, GameSimulator$handleRemoveEntitiesEvent$lambda);
      if (!(mapEntityComponent == null)) {
        var tmp4_subject = mapEntityComponent.lg4_1;
        var tmp0_0 = tmp4_subject.b2_1;
        switch (tmp0_0) {
          case 0:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 1:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 2:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 3:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 4:
            $this.ygs_1.bg7(new RemovedTowerEntityEvent(it));
            ;
            break;
          case 5:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 6:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 7:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 8:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 9:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 10:
            $this.ygs_1.bg7(new RemovedSupplyDepotEntityEvent(it));
            ;
            break;
        }
      }
    }
    updateShortestPath($this);
  }
  function handlePlaceEntitiesEvent($this, event) {
    var tmp0_iterator = event.mgt_1.h();
    $l$loop_0: while (tmp0_iterator.j()) {
      var entity = tmp0_iterator.k();
      var entityTypeComponent = entity.tfz(getKClass(EntityTypeComponent));
      if (entityTypeComponent.lg4_1.equals(MapEntityType_TOWER_getInstance())) {
        var tmp1_subject = checkCanPlaceTowerEntity($this.vgs_1, entity);
        if (tmp1_subject instanceof Errors)
          break $l$loop_0;
        else {
          if (equals(tmp1_subject, Ok_getInstance()))
          ;
        }
        var towerCost = entity.tfz(getKClass(EntityCostComponent)).fg4_1;
        var tmp2_this = $this.igt_1;
        tmp2_this.bgp(tmp2_this.agp_1 - towerCost | 0);
      }
      if (entityTypeComponent.lg4_1.equals(MapEntityType_SUPPLY_DEPOT_getInstance())) {
        var tmp3_subject = checkCanPlaceSupplyDepotEntity($this.vgs_1, entity);
        if (tmp3_subject instanceof Errors)
          break $l$loop_0;
        else {
          if (equals(tmp3_subject, Ok_getInstance()))
          ;
        }
        var towerCost_0 = entity.tfz(getKClass(EntityCostComponent)).fg4_1;
        var tmp4_this = $this.igt_1;
        tmp4_this.bgp(tmp4_this.agp_1 - towerCost_0 | 0);
      }
      var entityId = $this.xgs_1.vgb_1.fg3(GameSimulator$handlePlaceEntitiesEvent$lambda(entity, entityTypeComponent));
      $this.ygs_1.bg7(new AddedEntityEvent(entityId));
    }
    updateShortestPath($this);
  }
  function updateShortestPath($this) {
    var pathFinderResult = $this.xgs_1.ygc($this.egt_1.ago_1, $this.egt_1.bgo_1);
    var tmp0_safe_receiver = pathFinderResult.pgj();
    $this.fgt_1.kgo(tmp0_safe_receiver == null ? null : tmp0_safe_receiver.sgj());
  }
  function GameSimulator$handleRemoveEntitiesEvent$lambda($this$modifyEntity) {
    $this$modifyEntity.dg3();
    return Unit_getInstance();
  }
  function GameSimulator$handlePlaceEntitiesEvent$lambda($entity, $entityTypeComponent) {
    return function ($this$addEntity) {
      $this$addEntity.ag3($entity);
      var tmp0_subject = $entityTypeComponent.lg4_1;
      var tmp0 = tmp0_subject.b2_1;
      var tmp;
      switch (tmp0) {
        case 0:
          tmp = Unit_getInstance();
          break;
        case 1:
          tmp = Unit_getInstance();
          break;
        case 2:
          tmp = Unit_getInstance();
          break;
        case 3:
          $this$addEntity.og2(SelectableComponent_getInstance());
          tmp = Unit_getInstance();
          break;
        case 4:
          $this$addEntity.og2(new BaseDamageComponent(10.0));
          $this$addEntity.og2(new DamageUpgradeComponent(0));
          $this$addEntity.og2(new SpeedUpgradeComponent(0));
          $this$addEntity.og2(new DamageMultiplierComponent(1.0));
          $this$addEntity.og2(new RangeComponent(toGameUnit(7)));
          $this$addEntity.og2(new BaseWeaponSpeedComponent(1000.0));
          $this$addEntity.og2(new ReloadDowntimeComponent(0.0));
          $this$addEntity.og2(SelectableComponent_getInstance());
          tmp = Unit_getInstance();
          break;
        case 5:
          tmp = Unit_getInstance();
          break;
        case 6:
          tmp = Unit_getInstance();
          break;
        case 7:
          tmp = Unit_getInstance();
          break;
        case 8:
          tmp = Unit_getInstance();
          break;
        case 9:
          tmp = Unit_getInstance();
          break;
        case 10:
          $this$addEntity.og2(SelectableComponent_getInstance());
          tmp = Unit_getInstance();
          break;
      }
      return Unit_getInstance();
    };
  }
  function GameSimulator(width, height, engine, ticksPerSecond) {
    ticksPerSecond = ticksPerSecond === VOID ? 60 : ticksPerSecond;
    this.vgs_1 = engine;
    this.wgs_1 = ticksPerSecond;
    this.xgs_1 = this.vgs_1.ego_1;
    this.ygs_1 = this.vgs_1.dgo_1;
    this.zgs_1 = this.xgs_1.vgb_1;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.time.Companion.milliseconds' call
    var tmp0__get_milliseconds__kazo1t = Companion_getInstance_5();
    var tmp1__get_milliseconds__r2e6ua = 1000.0 / this.wgs_1;
    tmp$ret$0 = toDuration(tmp1__get_milliseconds__r2e6ua, DurationUnit_MILLISECONDS_getInstance());
    tmp.bgt_1 = tmp$ret$0;
    this.cgt_1 = new Long(0, 0);
    this.dgt_1 = new EventLog();
    this.egt_1 = new GameMapDimensionsState(this.vgs_1, width, height);
    this.fgt_1 = new GameMapPathState(this.vgs_1);
    this.ggt_1 = new GameplayState(61, 0.04, 7, 4, 5, 31);
    this.hgt_1 = new MutableEventQueueState();
    this.igt_1 = new MutableGoldState(200, this.ygs_1);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.vgs_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.gamelogic.utils.GameSimulator.<anonymous>' call
    tmp0_apply.ggo_1.xg2(this.egt_1);
    tmp0_apply.ggo_1.xg2(this.fgt_1);
    tmp0_apply.ggo_1.xg2(this.hgt_1);
    tmp0_apply.ggo_1.xg2(this.ggt_1);
    tmp0_apply.ggo_1.xg2(this.igt_1);
    tmp0_apply.hgo_1.xg2(this);
    tmp$ret$1 = tmp0_apply;
    this.agt_1 = new GameMapApi(this.vgs_1);
    this.jgt_1 = new StateUtils(this.vgs_1);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp1_apply = this.vgs_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.gamelogic.utils.GameSimulator.<anonymous>' call
    tmp1_apply.hgo_1.xg2(this.agt_1);
    tmp1_apply.hgo_1.xg2(this.jgt_1);
    tmp$ret$2 = tmp1_apply;
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = this.zgs_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.gamelogic.utils.GameSimulator.<anonymous>' call
    tmp2_apply.jg3(new MonsterMoveSystem(tmp2_apply));
    tmp2_apply.jg3(new MonsterRemoveSystem(tmp2_apply));
    tmp2_apply.jg3(new MonsterComputeSpeedEffectSystem(this.vgs_1));
    tmp2_apply.jg3(new ProjectileRemoveSystem(tmp2_apply));
    tmp2_apply.jg3(new TowerTargetingRemoveSystem(tmp2_apply));
    tmp2_apply.jg3(new TargetingAddSystem(this.xgs_1));
    tmp2_apply.jg3(new ProjectileMoveSystem(tmp2_apply));
    tmp2_apply.jg3(new ProjectileCollideSystem(tmp2_apply));
    tmp2_apply.jg3(new MonsterDeathSystem(this.vgs_1));
    tmp2_apply.jg3(new ReloadSystem(this.vgs_1));
    tmp2_apply.jg3(new TowerAttackSystem(tmp2_apply, this.agt_1));
    tmp$ret$3 = tmp2_apply;
  }
  protoOf(GameSimulator).ngt = function () {
    this.zgs_1.vg2(this.bgt_1);
    if (this.hgt_1.xgo()) {
      var eventsToProcess = this.hgt_1.c5z();
      var tmp0_iterator = eventsToProcess.h();
      while (tmp0_iterator.j()) {
        var event = tmp0_iterator.k();
        var tmp1_subject = event;
        if (tmp1_subject instanceof PlaceEntities) {
          handlePlaceEntitiesEvent(this, event);
        } else {
          if (tmp1_subject instanceof RemoveEntities) {
            handleRemoveEntitiesEvent(this, event);
          }
        }
      }
      this.dgt_1.fgs(new LogEntry(this.cgt_1, eventsToProcess));
      this.hgt_1.r7c();
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this.cgt_1;
    tmp2_this.cgt_1 = tmp3.xm();
  };
  protoOf(GameSimulator).ogt = function () {
    println(this.dgt_1);
  };
  function PlaceEntities(entities) {
    GameEvent.call(this);
    this.mgt_1 = entities;
  }
  protoOf(PlaceEntities).toString = function () {
    return 'PlaceEntities(entities=' + this.mgt_1 + ')';
  };
  protoOf(PlaceEntities).hashCode = function () {
    return hashCode(this.mgt_1);
  };
  protoOf(PlaceEntities).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PlaceEntities))
      return false;
    var tmp0_other_with_cast = other instanceof PlaceEntities ? other : THROW_CCE();
    if (!equals(this.mgt_1, tmp0_other_with_cast.mgt_1))
      return false;
    return true;
  };
  function RemoveEntities(entities) {
    GameEvent.call(this);
    this.ugs_1 = entities;
  }
  protoOf(RemoveEntities).toString = function () {
    return 'RemoveEntities(entities=' + this.ugs_1 + ')';
  };
  protoOf(RemoveEntities).hashCode = function () {
    return hashCode(this.ugs_1);
  };
  protoOf(RemoveEntities).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemoveEntities))
      return false;
    var tmp0_other_with_cast = other instanceof RemoveEntities ? other : THROW_CCE();
    if (!equals(this.ugs_1, tmp0_other_with_cast.ugs_1))
      return false;
    return true;
  };
  function GameEvent() {
  }
  function _GameUnit___init__impl__teirvv(value) {
    return value;
  }
  function _GameUnit___get_value__impl__9ocgox($this) {
    return $this;
  }
  function _GameUnit___init__impl__teirvv_0(v) {
    var tmp = _GameUnit___init__impl__teirvv(v);
    return tmp;
  }
  function GameUnit__toInt_impl_9qttgv($this) {
    return numberToInt(_GameUnit___get_value__impl__9ocgox($this));
  }
  function GameUnit__toDouble_impl_5m2yph($this) {
    return _GameUnit___get_value__impl__9ocgox($this);
  }
  function GameUnit__toFloat_impl_sc3672($this) {
    return _GameUnit___get_value__impl__9ocgox($this);
  }
  function GameUnit__plus_impl_rh1b6r($this, other) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) + _GameUnit___get_value__impl__9ocgox(other));
  }
  function GameUnit__plus_impl_rh1b6r_0($this, other) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) + other);
  }
  function GameUnit__minus_impl_uz6kmr($this, y) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) - _GameUnit___get_value__impl__9ocgox(y));
  }
  function GameUnit__minus_impl_uz6kmr_0($this, y) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) - y);
  }
  function GameUnit__times_impl_soo0d9($this, i) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) * i);
  }
  function GameUnit__compareTo_impl_qyacwt($this, i) {
    return compareTo(_GameUnit___get_value__impl__9ocgox($this), i);
  }
  function GameUnit__compareTo_impl_qyacwt_0($this, other) {
    return compareTo(_GameUnit___get_value__impl__9ocgox($this), _GameUnit___get_value__impl__9ocgox(other));
  }
  function GameUnit__compareTo_impl_qyacwt_1($this, other) {
    var tmp = $this.vg3_1;
    return GameUnit__compareTo_impl_qyacwt_0(tmp, other instanceof GameUnit ? other.vg3_1 : THROW_CCE());
  }
  function GameUnit__until_impl_8xh2rf($this, o) {
    return new GameUnitRange($this, GameUnit__minus_impl_uz6kmr_0(o, 1));
  }
  function GameUnit__times_impl_soo0d9_0($this, o) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) * o);
  }
  function GameUnit__div_impl_u8qjua($this, d) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) / d);
  }
  function GameUnit__div_impl_u8qjua_0($this, d) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) / _GameUnit___get_value__impl__9ocgox(d));
  }
  function GameUnit__div_impl_u8qjua_1($this, other) {
    return _GameUnit___init__impl__teirvv(_GameUnit___get_value__impl__9ocgox($this) / numberToDouble(other));
  }
  function GameUnit__toWorldUnit_impl_m803zs($this, gridSize) {
    return _WorldUnit___init__impl__qt45s3(_GameUnit___get_value__impl__9ocgox($this) * numberToDouble(gridSize));
  }
  function GameUnit__tup_impl_pf7bck($this, gridYInt) {
    return new GameUnitTuple($this, gridYInt);
  }
  function GameUnit__inc_impl_pnncud($this) {
    return GameUnit__plus_impl_rh1b6r_0($this, 1);
  }
  function Companion_29() {
    Companion_instance_29 = this;
    this.egk_1 = _GameUnit___init__impl__teirvv_0(0);
  }
  var Companion_instance_29;
  function Companion_getInstance_41() {
    if (Companion_instance_29 == null)
      new Companion_29();
    return Companion_instance_29;
  }
  function $serializer_15() {
    $serializer_instance_15 = this;
    var tmp0_serialDesc = new InlineClassDescriptor('com.xenotactic.gamelogic.utils.GameUnit', this);
    tmp0_serialDesc.cfg('value', false);
    this.pgt_1 = tmp0_serialDesc;
  }
  protoOf($serializer_15).qf5 = function () {
    return this.pgt_1;
  };
  protoOf($serializer_15).rfg = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOf' call
    var tmp0_arrayOf = [DoubleSerializer_getInstance()];
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_arrayOf;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf($serializer_15).qgt = function (decoder) {
    return _GameUnit___init__impl__teirvv(decoder.wf8(this.pgt_1).sf8());
  };
  protoOf($serializer_15).sf5 = function (decoder) {
    return new GameUnit(this.qgt(decoder));
  };
  protoOf($serializer_15).rgt = function (encoder, value) {
    var tmp0_inlineEncoder = encoder.ffa(this.pgt_1);
    if (tmp0_inlineEncoder == null)
      null;
    else {
      tmp0_inlineEncoder.bfa(_GameUnit___get_value__impl__9ocgox(value));
    }
  };
  protoOf($serializer_15).rf5 = function (encoder, value) {
    return this.rgt(encoder, value instanceof GameUnit ? value.vg3_1 : THROW_CCE());
  };
  var $serializer_instance_15;
  function $serializer_getInstance_15() {
    if ($serializer_instance_15 == null)
      new $serializer_15();
    return $serializer_instance_15;
  }
  function GameUnit__toString_impl_3u51th($this) {
    return 'GameUnit(value=' + $this + ')';
  }
  function GameUnit__hashCode_impl_playmc($this) {
    return getNumberHashCode($this);
  }
  function GameUnit__equals_impl_vp4peg($this, other) {
    if (!(other instanceof GameUnit))
      return false;
    var tmp0_other_with_cast = other instanceof GameUnit ? other.vg3_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function GameUnit(value) {
    Companion_getInstance_41();
    this.vg3_1 = value;
  }
  protoOf(GameUnit).sgt = function (other) {
    return GameUnit__compareTo_impl_qyacwt_0(this.vg3_1, other);
  };
  protoOf(GameUnit).fc = function (other) {
    return GameUnit__compareTo_impl_qyacwt_1(this, other);
  };
  protoOf(GameUnit).toString = function () {
    return GameUnit__toString_impl_3u51th(this.vg3_1);
  };
  protoOf(GameUnit).hashCode = function () {
    return GameUnit__hashCode_impl_playmc(this.vg3_1);
  };
  protoOf(GameUnit).equals = function (other) {
    return GameUnit__equals_impl_vp4peg(this.vg3_1, other);
  };
  function GameUnitRange$iterator$1(this$0) {
    this.tgt_1 = this$0.wga_1;
    this.ugt_1 = this$0.xga_1;
  }
  protoOf(GameUnitRange$iterator$1).j = function () {
    return GameUnit__compareTo_impl_qyacwt_0(this.tgt_1, this.ugt_1) <= 0;
  };
  protoOf(GameUnitRange$iterator$1).vgt = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.tgt_1;
    tmp0_this.tgt_1 = GameUnit__inc_impl_pnncud(tmp1);
    return tmp1;
  };
  protoOf(GameUnitRange$iterator$1).k = function () {
    return new GameUnit(this.vgt());
  };
  function GameUnitRange(start, endInclusive) {
    this.wga_1 = start;
    this.xga_1 = endInclusive;
  }
  protoOf(GameUnitRange).wgt = function () {
    return this.wga_1;
  };
  protoOf(GameUnitRange).u = function () {
    return new GameUnit(this.wgt());
  };
  protoOf(GameUnitRange).xgt = function () {
    return this.xga_1;
  };
  protoOf(GameUnitRange).v = function () {
    return new GameUnit(this.xgt());
  };
  protoOf(GameUnitRange).ygt = function (value) {
    return GameUnit__compareTo_impl_qyacwt_0(value, this.wga_1) >= 0 ? GameUnit__compareTo_impl_qyacwt_0(value, this.xga_1) <= 0 : false;
  };
  protoOf(GameUnitRange).s1 = function (value) {
    return this.ygt(value instanceof GameUnit ? value.vg3_1 : THROW_CCE());
  };
  protoOf(GameUnitRange).h = function () {
    return new GameUnitRange$iterator$1(this);
  };
  function toGameUnit(_this__u8e3s4) {
    return _GameUnit___init__impl__teirvv(_this__u8e3s4);
  }
  function toGameUnit_0(_this__u8e3s4) {
    return _GameUnit___init__impl__teirvv(_this__u8e3s4);
  }
  function distance(x1, y1, x2, y2) {
    return toGameUnit_0(Companion_getInstance_6().u3w(GameUnit__toDouble_impl_5m2yph(x1), GameUnit__toDouble_impl_5m2yph(y1), GameUnit__toDouble_impl_5m2yph(x2), GameUnit__toDouble_impl_5m2yph(y2)));
  }
  function max(a, b) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = _GameUnit___get_value__impl__9ocgox(a);
    var tmp1_max = b;
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    return _GameUnit___init__impl__teirvv(tmp$ret$0);
  }
  function min(width, gameUnit) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = _GameUnit___get_value__impl__9ocgox(width);
    var tmp1_min = _GameUnit___get_value__impl__9ocgox(gameUnit);
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return _GameUnit___init__impl__teirvv(tmp$ret$0);
  }
  function until(_this__u8e3s4, o) {
    return GameUnit__until_impl_8xh2rf(toGameUnit(_this__u8e3s4), o);
  }
  function rangeTo(_this__u8e3s4, o) {
    return numberRangeToNumber(_this__u8e3s4, numberToInt(_GameUnit___get_value__impl__9ocgox(o)));
  }
  function sumOf(_this__u8e3s4, function_0) {
    var sum = _GameUnit___init__impl__teirvv(0.0);
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = _this__u8e3s4.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.gamelogic.utils.sumOf.<anonymous>' call
      sum = GameUnit__plus_impl_rh1b6r(sum, function_0(element).vg3_1);
    }
    return sum;
  }
  function distance_0(p1, p2) {
    return distance(p1.dg6_1, p1.eg6_1, p2.dg6_1, p2.eg6_1);
  }
  function $initCOROUTINE$1(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.hgu_1 = _this__u8e3s4;
  }
  protoOf($initCOROUTINE$1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.y7_1 = 1;
            suspendResult = readImageDataContainer(get_resourcesVfs().p1b('8_directional_character.aseprite'), toProps(ASE_getInstance()), VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.igu_1 = suspendResult;
            this.hgu_1.vgu_1 = this.igu_1;
            this.y7_1 = 2;
            var tmp_0 = get_resourcesVfs().p1b('tower_sprites.aseprite');
            var tmp0_apply = toProps(ASE_getInstance());
            set_onlyReadVisibleLayers(tmp0_apply, false);
            ;
            suspendResult = readImageDataContainer(tmp_0, tmp0_apply, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.jgu_1 = suspendResult;
            this.kgu_1 = toAsepriteModel(this.jgu_1);
            this.hgu_1.zgu_1 = first(this.kgu_1.ngr('base').dgs_1).ags();
            this.hgu_1.agv_1 = first(this.kgu_1.ngr('base_detail').dgs_1).ags();
            this.hgu_1.wgu_1 = first(this.kgu_1.ngr('gun3').dgs_1).ags();
            this.hgu_1.xgu_1 = first(this.kgu_1.ngr('high_damage').dgs_1).ags();
            this.hgu_1.ygu_1 = first(this.kgu_1.ngr('depot').dgs_1).ags();
            this.y7_1 = 3;
            var tmp_1 = get_resourcesVfs().p1b('icons.aseprite');
            var tmp1_apply = toProps(ASE_getInstance());
            set_onlyReadVisibleLayers(tmp1_apply, false);
            ;
            suspendResult = readImageDataContainer(tmp_1, tmp1_apply, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.lgu_1 = suspendResult;
            this.mgu_1 = toAsepriteModel(this.lgu_1);
            this.ngu_1 = 'background';
            this.ogu_1 = 'background_upgrade';
            this.pgu_1 = 'gold_icon';
            this.qgu_1 = 'supply_icon';
            this.rgu_1 = 'damage_icon';
            this.sgu_1 = 'cooldown_icon';
            this.tgu_1 = 'upgrade_tower_icon';
            this.hgu_1.bgv_1 = first(this.mgu_1.mgr_1).zgr([this.ogu_1, this.rgu_1]);
            this.hgu_1.cgv_1 = first(this.mgu_1.mgr_1).zgr([this.ogu_1, this.sgu_1]);
            this.hgu_1.dgv_1 = first(this.mgu_1.mgr_1).zgr([this.ogu_1, this.tgu_1]);
            this.hgu_1.egv_1 = first(this.mgu_1.mgr_1).zgr([this.ngu_1, this.pgu_1]);
            this.hgu_1.fgv_1 = first(this.mgu_1.mgr_1).zgr([this.ngu_1, this.qgu_1]);
            this.y7_1 = 4;
            suspendResult = readTtfFont(get_resourcesVfs().p1b('fonts/AtkinsonHyperlegible-Regular.ttf'), VOID, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            this.ugu_1 = suspendResult;
            this.hgu_1.ggv_1 = this.ugu_1;
            this.y7_1 = 5;
            suspendResult = readTtfFont(get_resourcesVfs().p1b('fonts/AtkinsonHyperlegible-Bold.ttf'), VOID, false, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            var ARGUMENT = suspendResult;
            this.hgu_1.hgv_1 = ARGUMENT;
            return Unit_getInstance();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function GlobalResources() {
    GlobalResources_instance = this;
  }
  protoOf(GlobalResources).igv = function () {
    var tmp = this.vgu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('MONSTER_SPRITE');
    }
  };
  protoOf(GlobalResources).jgv = function () {
    var tmp = this.wgu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('GUN_SPRITE');
    }
  };
  protoOf(GlobalResources).kgv = function () {
    var tmp = this.xgu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('HIGH_DAMAGE_GUN_SPRITE');
    }
  };
  protoOf(GlobalResources).lgv = function () {
    var tmp = this.ygu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('DEPOT_SPRITE');
    }
  };
  protoOf(GlobalResources).mgv = function () {
    var tmp = this.zgu_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('TOWER_BASE_SPRITE');
    }
  };
  protoOf(GlobalResources).ngv = function () {
    var tmp = this.agv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('TOWER_BASE_DETAIL_SPRITE');
    }
  };
  protoOf(GlobalResources).ogv = function () {
    var tmp = this.bgv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('DAMAGE_ICON');
    }
  };
  protoOf(GlobalResources).pgv = function () {
    var tmp = this.cgv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('COOLDOWN_ICON');
    }
  };
  protoOf(GlobalResources).qgv = function () {
    var tmp = this.dgv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('UPGRADE_TOWER_ICON');
    }
  };
  protoOf(GlobalResources).rgv = function () {
    var tmp = this.egv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('GOLD_ICON');
    }
  };
  protoOf(GlobalResources).sgv = function () {
    var tmp = this.fgv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('SUPPLY_ICON');
    }
  };
  protoOf(GlobalResources).tgv = function () {
    var tmp = this.ggv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('FONT_ATKINSON_REGULAR');
    }
  };
  protoOf(GlobalResources).ugv = function () {
    var tmp = this.hgv_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('FONT_ATKINSON_BOLD');
    }
  };
  protoOf(GlobalResources).w22 = function ($completion) {
    var tmp = new $initCOROUTINE$1(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  var GlobalResources_instance;
  function GlobalResources_getInstance() {
    if (GlobalResources_instance == null)
      new GlobalResources();
    return GlobalResources_instance;
  }
  function MapBlockingUtilResults(isBlockingTop, isBlockingBottom, isBlockingLeft, isBlockingRight) {
    this.egm_1 = isBlockingTop;
    this.fgm_1 = isBlockingBottom;
    this.ggm_1 = isBlockingLeft;
    this.hgm_1 = isBlockingRight;
  }
  protoOf(MapBlockingUtilResults).ogn = function () {
    return ((this.egm_1 ? true : this.fgm_1) ? true : this.ggm_1) ? true : this.hgm_1;
  };
  protoOf(MapBlockingUtilResults).toString = function () {
    return 'MapBlockingUtilResults(isBlockingTop=' + this.egm_1 + ', isBlockingBottom=' + this.fgm_1 + ', isBlockingLeft=' + this.ggm_1 + ', isBlockingRight=' + this.hgm_1 + ')';
  };
  protoOf(MapBlockingUtilResults).hashCode = function () {
    var result = this.egm_1 | 0;
    result = imul(result, 31) + (this.fgm_1 | 0) | 0;
    result = imul(result, 31) + (this.ggm_1 | 0) | 0;
    result = imul(result, 31) + (this.hgm_1 | 0) | 0;
    return result;
  };
  protoOf(MapBlockingUtilResults).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapBlockingUtilResults))
      return false;
    var tmp0_other_with_cast = other instanceof MapBlockingUtilResults ? other : THROW_CCE();
    if (!(this.egm_1 === tmp0_other_with_cast.egm_1))
      return false;
    if (!(this.fgm_1 === tmp0_other_with_cast.fgm_1))
      return false;
    if (!(this.ggm_1 === tmp0_other_with_cast.ggm_1))
      return false;
    if (!(this.hgm_1 === tmp0_other_with_cast.hgm_1))
      return false;
    return true;
  };
  function MapBlockingUtil() {
    MapBlockingUtil_instance = this;
  }
  protoOf(MapBlockingUtil).ngn = function (entity, mapWidth, mapHeight) {
    return this.dgm(GameUnit__toInt_impl_9qttgv(entity.jg8()), GameUnit__toInt_impl_9qttgv(entity.kg8()), GameUnit__toInt_impl_9qttgv(entity.lg8()), GameUnit__toInt_impl_9qttgv(entity.mg8()), mapWidth, mapHeight);
  };
  protoOf(MapBlockingUtil).dgm = function (x, y, entityWidth, entityHeight, mapWidth, mapHeight) {
    return new MapBlockingUtilResults((y + entityHeight | 0) >= mapHeight, y <= 0, x <= 0, (x + entityWidth | 0) >= mapWidth);
  };
  var MapBlockingUtil_instance;
  function MapBlockingUtil_getInstance() {
    if (MapBlockingUtil_instance == null)
      new MapBlockingUtil();
    return MapBlockingUtil_instance;
  }
  function MapToId() {
    MapToId_instance = this;
  }
  protoOf(MapToId).vgv = function (gameMap) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError();
  };
  var MapToId_instance;
  function MapToId_getInstance() {
    if (MapToId_instance == null)
      new MapToId();
    return MapToId_instance;
  }
  function PathingPointUtil(pathingRadius) {
    pathingRadius = pathingRadius === VOID ? get_PATHING_RADIUS() : pathingRadius;
    this.igi_1 = pathingRadius;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.math.sqrt' call
    tmp$ret$0 = Math.sqrt(2.0);
    tmp.jgi_1 = this.igi_1 / tmp$ret$0;
    this.kgi_1 = mapOf([to(new EntityMask(false, false, false, false), listOf(to(0.0, 0.0))), to(new EntityMask(true, false, false, false), listOf(to(this.jgi_1, -this.jgi_1))), to(new EntityMask(false, true, false, false), listOf(to(-this.jgi_1, -this.jgi_1))), to(new EntityMask(false, false, true, false), listOf(to(this.jgi_1, this.jgi_1))), to(new EntityMask(false, false, false, true), listOf(to(-this.jgi_1, this.jgi_1))), to(new EntityMask(true, false, true, false), listOf(to(this.igi_1, 0.0))), to(new EntityMask(false, true, true, false), listOf_0([to(-this.jgi_1, this.jgi_1), to(this.jgi_1, -this.jgi_1)])), to(new EntityMask(true, true, false, false), listOf(to(0.0, -this.igi_1))), to(new EntityMask(true, false, false, true), listOf_0([to(this.jgi_1, this.jgi_1), to(-this.jgi_1, -this.jgi_1)])), to(new EntityMask(false, true, false, true), listOf(to(-this.igi_1, 0.0))), to(new EntityMask(false, false, true, true), listOf(to(0.0, this.igi_1))), to(new EntityMask(true, true, true, false), listOf(to(this.jgi_1, -this.jgi_1))), to(new EntityMask(true, true, false, true), listOf(to(-this.jgi_1, -this.jgi_1))), to(new EntityMask(false, true, true, true), listOf(to(-this.jgi_1, this.jgi_1))), to(new EntityMask(true, false, true, true), listOf(to(this.jgi_1, this.jgi_1))), to(new EntityMask(true, true, true, true), emptyList())]);
  }
  protoOf(PathingPointUtil).lgi = function (entity, blockingPoints) {
    // Inline function 'kotlin.require' call
    var tmp0_require = GameUnit__toInt_impl_9qttgv(entity.lg8()) === 2 ? GameUnit__toInt_impl_9qttgv(entity.mg8()) === 2 : false;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'utils.PathingPointUtil.calculate.<anonymous>' call
      tmp$ret$0 = 'Only works with 2x2 entities!';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var entityMask = new EntityMask(blockingPoints.hg6(entity.jg8(), GameUnit__plus_impl_rh1b6r_0(entity.kg8(), 1)), blockingPoints.hg6(GameUnit__plus_impl_rh1b6r_0(entity.jg8(), 1), GameUnit__plus_impl_rh1b6r_0(entity.kg8(), 1)), blockingPoints.hg6(entity.jg8(), entity.kg8()), blockingPoints.hg6(GameUnit__plus_impl_rh1b6r_0(entity.jg8(), 1), entity.kg8()));
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp2_map = ensureNotNull(this.kgi_1.h3(entityMask));
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp1_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp2_map, 10));
    var tmp0_iterator = tmp2_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'utils.PathingPointUtil.calculate.<anonymous>' call
      tmp$ret$1 = new IPoint(entity.vg8().sgb_1 + item.a5_1, entity.vg8().tgb_1 + item.b5_1);
      tmp1_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp1_mapTo;
    tmp$ret$3 = tmp$ret$2;
    return toSet(tmp$ret$3);
  };
  function EntityMask(topLeftFilled, topRightFilled, bottomLeftFilled, bottomRightFilled) {
    topLeftFilled = topLeftFilled === VOID ? false : topLeftFilled;
    topRightFilled = topRightFilled === VOID ? false : topRightFilled;
    bottomLeftFilled = bottomLeftFilled === VOID ? false : bottomLeftFilled;
    bottomRightFilled = bottomRightFilled === VOID ? false : bottomRightFilled;
    this.wgv_1 = topLeftFilled;
    this.xgv_1 = topRightFilled;
    this.ygv_1 = bottomLeftFilled;
    this.zgv_1 = bottomRightFilled;
  }
  protoOf(EntityMask).toString = function () {
    return 'EntityMask(topLeftFilled=' + this.wgv_1 + ', topRightFilled=' + this.xgv_1 + ', bottomLeftFilled=' + this.ygv_1 + ', bottomRightFilled=' + this.zgv_1 + ')';
  };
  protoOf(EntityMask).hashCode = function () {
    var result = this.wgv_1 | 0;
    result = imul(result, 31) + (this.xgv_1 | 0) | 0;
    result = imul(result, 31) + (this.ygv_1 | 0) | 0;
    result = imul(result, 31) + (this.zgv_1 | 0) | 0;
    return result;
  };
  protoOf(EntityMask).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntityMask))
      return false;
    var tmp0_other_with_cast = other instanceof EntityMask ? other : THROW_CCE();
    if (!(this.wgv_1 === tmp0_other_with_cast.wgv_1))
      return false;
    if (!(this.xgv_1 === tmp0_other_with_cast.xgv_1))
      return false;
    if (!(this.ygv_1 === tmp0_other_with_cast.ygv_1))
      return false;
    if (!(this.zgv_1 === tmp0_other_with_cast.zgv_1))
      return false;
    return true;
  };
  function RockCounterUtil() {
    RockCounterUtil_instance = this;
  }
  protoOf(RockCounterUtil).agw = function (gameMap) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_7();
    var tmp1_invoke = GameUnit__toInt_impl_9qttgv(gameMap.bg9_1);
    var tmp2_invoke = GameUnit__toInt_impl_9qttgv(gameMap.cg9_1);
    var tmp = 0;
    var tmp_0 = imul(tmp1_invoke, tmp2_invoke);
    var tmp_1 = new Int32Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.Companion.invoke.<anonymous>' call
      tmp$ret$0 = 0;
      tmp_1[tmp_2] = tmp$ret$0;
      tmp = tmp + 1 | 0;
    }
    var tmp_3 = tmp_1;
    tmp$ret$1 = new IntArray2(tmp1_invoke, tmp2_invoke, isIntArray(tmp_3) ? tmp_3 : THROW_CCE());
    var result = tmp$ret$1;
    // Inline function 'kotlin.repeat' call
    var tmp3_repeat = result.t18_1;
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < tmp3_repeat)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'com.xenotactic.gamelogic.utils.RockCounterUtil.calculate.<anonymous>' call
        // Inline function 'kotlin.repeat' call
        var tmp0_repeat = result.u18_1;
        // Inline function 'kotlin.contracts.contract' call
        var inductionVariable_0 = 0;
        if (inductionVariable_0 < tmp0_repeat)
          do {
            var index_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            // Inline function 'com.xenotactic.gamelogic.utils.RockCounterUtil.calculate.<anonymous>.<anonymous>' call
            var allRocks = gameMap.bgb(toGameUnit(index), toGameUnit(index_0));
            result.b19(index, index_0, count(allRocks));
          }
           while (inductionVariable_0 < tmp0_repeat);
      }
       while (inductionVariable < tmp3_repeat);
    return result;
  };
  var RockCounterUtil_instance;
  function RockCounterUtil_getInstance() {
    if (RockCounterUtil_instance == null)
      new RockCounterUtil();
    return RockCounterUtil_instance;
  }
  function SpeedAreaColorUtil() {
    SpeedAreaColorUtil_instance = this;
    this.bgw_1 = listOf_0([new RGBA(MaterialColors_getInstance().x57_1), new RGBA(MaterialColors_getInstance().y57_1), new RGBA(MaterialColors_getInstance().z57_1), new RGBA(MaterialColors_getInstance().a58_1), new RGBA(MaterialColors_getInstance().b58_1)]);
    this.cgw_1 = listOf_0([new RGBA(MaterialColors_getInstance().b5a_1), new RGBA(MaterialColors_getInstance().c5a_1), new RGBA(MaterialColors_getInstance().d5a_1), new RGBA(MaterialColors_getInstance().e5a_1), new RGBA(MaterialColors_getInstance().f5a_1)]);
  }
  protoOf(SpeedAreaColorUtil).dgw = function (speedEffect, slowAreaColors, fastAreaColors, slowLow, slowHigh, fastLow, fastHigh) {
    var tmp;
    if (speedEffect <= 1.0) {
      tmp = this.fgw(speedEffect, slowAreaColors, slowLow, slowHigh);
    } else {
      tmp = this.egw(speedEffect, fastAreaColors, fastLow, fastHigh);
    }
    return tmp;
  };
  protoOf(SpeedAreaColorUtil).ggw = function (speedEffect, slowAreaColors, fastAreaColors, slowLow, slowHigh, fastLow, fastHigh, $super) {
    slowAreaColors = slowAreaColors === VOID ? this.cgw_1 : slowAreaColors;
    fastAreaColors = fastAreaColors === VOID ? this.bgw_1 : fastAreaColors;
    slowLow = slowLow === VOID ? 0.0 : slowLow;
    slowHigh = slowHigh === VOID ? 1.0 : slowHigh;
    fastLow = fastLow === VOID ? 1.0 : fastLow;
    fastHigh = fastHigh === VOID ? 2.0 : fastHigh;
    return $super === VOID ? this.dgw(speedEffect, slowAreaColors, fastAreaColors, slowLow, slowHigh, fastLow, fastHigh) : $super.dgw.call(this, speedEffect, slowAreaColors, fastAreaColors, slowLow, slowHigh, fastLow, fastHigh).r57_1;
  };
  protoOf(SpeedAreaColorUtil).fgw = function (speedEffect, slowAreaColors, low, high) {
    var colorIncrements = roundDecimalPlaces((high - low) / slowAreaColors.l(), 2);
    var inversedSlow = roundDecimalPlaces(high - clamp(speedEffect, low, high), 2);
    var tmp$ret$0;
    // Inline function 'kotlin.math.floor' call
    var tmp0_floor = roundDecimalPlaces(inversedSlow / colorIncrements, 2);
    tmp$ret$0 = Math.floor(tmp0_floor);
    var colorIndex = clamp_0(numberToInt(tmp$ret$0), 0, slowAreaColors.l() - 1 | 0);
    return slowAreaColors.n(colorIndex).r57_1;
  };
  protoOf(SpeedAreaColorUtil).egw = function (speedEffect, fastAreaColors, low, high) {
    var colorIncrements = (high - low) / fastAreaColors.l();
    var normalizedSpeedEffect = speedEffect - low;
    var tmp$ret$0;
    // Inline function 'kotlin.math.floor' call
    var tmp0_floor = normalizedSpeedEffect / colorIncrements;
    tmp$ret$0 = Math.floor(tmp0_floor);
    var colorIndex = clamp_0(numberToInt(tmp$ret$0), 0, fastAreaColors.l() - 1 | 0);
    return fastAreaColors.n(colorIndex).r57_1;
  };
  var SpeedAreaColorUtil_instance;
  function SpeedAreaColorUtil_getInstance() {
    if (SpeedAreaColorUtil_instance == null)
      new SpeedAreaColorUtil();
    return SpeedAreaColorUtil_instance;
  }
  function IntStatCounter(name) {
    this.ugi_1 = name;
    var tmp = this;
    Companion_getInstance_8();
    tmp.vgi_1 = new Long(0, -2147483648);
    var tmp_0 = this;
    Companion_getInstance_8();
    tmp_0.wgi_1 = new Long(-1, 2147483647);
    this.xgi_1 = new Long(0, 0);
    this.ygi_1 = new Long(0, 0);
  }
  protoOf(IntStatCounter).hgw = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.Long.div' call
    var tmp0_div = this.xgi_1;
    var tmp1_div = this.ygi_1.wi();
    tmp$ret$0 = tmp0_div.wi() / tmp1_div;
    return tmp$ret$0;
  };
  protoOf(IntStatCounter).zgi = function (x) {
    this.igw(toLong(x));
  };
  protoOf(IntStatCounter).igw = function (x) {
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.vgi_1;
    tmp$ret$0 = x.k1(tmp0_max) >= 0 ? x : tmp0_max;
    tmp.vgi_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp1_min = this.wgi_1;
    tmp$ret$1 = x.k1(tmp1_min) <= 0 ? x : tmp1_min;
    tmp_0.wgi_1 = tmp$ret$1;
    var tmp0_this = this;
    tmp0_this.xgi_1 = tmp0_this.xgi_1.b9(x);
    var tmp1_this = this;
    var tmp2 = tmp1_this.ygi_1;
    tmp1_this.ygi_1 = tmp2.xm();
  };
  protoOf(IntStatCounter).toString = function () {
    return 'IntStatCounter: ' + this.ugi_1 + ', total=' + toString(this.xgi_1) + ', count=' + toString(this.ygi_1) + ', min=' + toString(this.wgi_1) + ', ' + ('max=' + toString(this.vgi_1) + ', average=' + this.hgw() + ', ');
  };
  function StateUtils$gameplayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function StateUtils(engine) {
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = engine.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameplayState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(StateUtils$gameplayState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.jgw_1 = tmp$ret$1;
    this.kgw_1 = engine.ego_1;
  }
  protoOf(StateUtils).lgw = function () {
    return this.kgw_1.vgc(this.jgw_1.tgo_1, this.jgw_1.ugo_1, this.jgw_1.sgo_1);
  };
  function TowerCache(width, height) {
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.datastructure.Companion.withGen' call
    var tmp0_withGen = Companion_getInstance_9();
    var tmp_0 = 0;
    var tmp_1 = imul(width, height);
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.Companion.withGen.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'utils.TowerCache.towerPool.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp_3 % width | 0;
      var tmp2__anonymous__z9zvc9 = tmp_3 / width | 0;
      tmp$ret$1 = new Tower(toGameUnit(tmp1__anonymous__uwfjfc), toGameUnit(tmp2__anonymous__z9zvc9));
      tmp$ret$2 = tmp$ret$1;
      tmp_2[tmp_3] = tmp$ret$2;
      tmp_0 = tmp_0 + 1 | 0;
    }
    var tmp_4 = tmp_2;
    tmp$ret$3 = new Array2(width, height, isArray(tmp_4) ? tmp_4 : THROW_CCE());
    tmp.ign_1 = tmp$ret$3;
  }
  protoOf(TowerCache).mgn = function (x, y) {
    return this.jgn(GameUnit__toInt_impl_9qttgv(x), GameUnit__toInt_impl_9qttgv(y));
  };
  protoOf(TowerCache).jgn = function (x, y) {
    return this.ign_1.a19(x, y);
  };
  function _WorldUnit___init__impl__qt45s3(value) {
    return value;
  }
  function _WorldUnit___get_value__impl__xwm9rd($this) {
    return $this;
  }
  function WorldUnit__div_impl_b90n6a($this, i) {
    return _WorldUnit___init__impl__qt45s3(_WorldUnit___get_value__impl__xwm9rd($this) / i);
  }
  function WorldUnit__minus_impl_yejnmb($this, other) {
    return _WorldUnit___init__impl__qt45s3(_WorldUnit___get_value__impl__xwm9rd($this) - other);
  }
  function WorldUnit__minus_impl_yejnmb_0($this, other) {
    return _WorldUnit___init__impl__qt45s3(_WorldUnit___get_value__impl__xwm9rd($this) - numberToDouble(other));
  }
  function WorldUnit__minus_impl_yejnmb_1($this, other) {
    return _WorldUnit___init__impl__qt45s3(_WorldUnit___get_value__impl__xwm9rd($this) - _WorldUnit___get_value__impl__xwm9rd(other));
  }
  function WorldUnit__toDouble_impl_9mljzf($this) {
    return _WorldUnit___get_value__impl__xwm9rd($this);
  }
  function WorldUnit__toFloat_impl_2n0xxa($this) {
    return _WorldUnit___get_value__impl__xwm9rd($this);
  }
  function WorldUnit__plus_impl_n1o1x9($this, other) {
    return _WorldUnit___init__impl__qt45s3(_WorldUnit___get_value__impl__xwm9rd($this) + _WorldUnit___get_value__impl__xwm9rd(other));
  }
  function Companion_30() {
    Companion_instance_30 = this;
    this.mgw_1 = _WorldUnit___init__impl__qt45s3(0.0);
  }
  var Companion_instance_30;
  function Companion_getInstance_42() {
    if (Companion_instance_30 == null)
      new Companion_30();
    return Companion_instance_30;
  }
  function WorldUnit__toString_impl_bejgvf($this) {
    return 'WorldUnit(value=' + $this + ')';
  }
  function WorldUnit__hashCode_impl_acmfxg($this) {
    return getNumberHashCode($this);
  }
  function WorldUnit__equals_impl_44ttxk($this, other) {
    if (!(other instanceof WorldUnit))
      return false;
    var tmp0_other_with_cast = other instanceof WorldUnit ? other.ngw_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function WorldUnit(value) {
    Companion_getInstance_42();
    this.ngw_1 = value;
  }
  protoOf(WorldUnit).toString = function () {
    return WorldUnit__toString_impl_bejgvf(this.ngw_1);
  };
  protoOf(WorldUnit).hashCode = function () {
    return WorldUnit__hashCode_impl_acmfxg(this.ngw_1);
  };
  protoOf(WorldUnit).equals = function (other) {
    return WorldUnit__equals_impl_44ttxk(this.ngw_1, other);
  };
  function toPoint(_this__u8e3s4) {
    return Vector2_init_$Create$(WorldUnit__toDouble_impl_9mljzf(_this__u8e3s4.a5_1.ngw_1), WorldUnit__toDouble_impl_9mljzf(_this__u8e3s4.b5_1.ngw_1));
  }
  function toWorldUnit(_this__u8e3s4) {
    return _WorldUnit___init__impl__qt45s3(numberToDouble(_this__u8e3s4));
  }
  function size_0(_this__u8e3s4, right) {
    return new Size(numberToDouble(_this__u8e3s4), numberToDouble(right));
  }
  function toScale(_this__u8e3s4) {
    return Scale_init_$Create$(numberToDouble(_this__u8e3s4));
  }
  function rectangleIntersects(a, b) {
    return ((GameUnit__compareTo_impl_qyacwt_0(a.ng8(), b.og8()) < 0 ? GameUnit__compareTo_impl_qyacwt_0(a.og8(), b.ng8()) > 0 : false) ? GameUnit__compareTo_impl_qyacwt_0(a.pg8(), b.qg8()) < 0 : false) ? GameUnit__compareTo_impl_qyacwt_0(a.qg8(), b.pg8()) > 0 : false;
  }
  function times(_this__u8e3s4, scale) {
    return numberToDouble(_this__u8e3s4) * scale.s42();
  }
  function minus(_this__u8e3s4, other) {
    return Scale_init_$Create$(_this__u8e3s4.s42() - numberToDouble(other));
  }
  function toWorldDimensions(width, height, gridSize) {
    return new Pair(new WorldUnit(_WorldUnit___init__impl__qt45s3(_GameUnit___get_value__impl__9ocgox(width) * numberToDouble(gridSize))), new WorldUnit(_WorldUnit___init__impl__qt45s3(_GameUnit___get_value__impl__9ocgox(height) * numberToDouble(gridSize))));
  }
  function calculateCostOfUpgrades(currentNumUpgrades, initialUpgradeCost, numUpgradesWanted) {
    return imul(currentNumUpgrades + initialUpgradeCost | 0, numUpgradesWanted) + (imul(numUpgradesWanted - 1 | 0, numUpgradesWanted) / 2 | 0) | 0;
  }
  function UpgradeDecision(maxPossibleUpgradesDelta, upgradesCost) {
    this.ogw_1 = maxPossibleUpgradesDelta;
    this.pgw_1 = upgradesCost;
  }
  protoOf(UpgradeDecision).toString = function () {
    return 'UpgradeDecision(maxPossibleUpgradesDelta=' + this.ogw_1 + ', upgradesCost=' + this.pgw_1 + ')';
  };
  protoOf(UpgradeDecision).hashCode = function () {
    var result = this.ogw_1;
    result = imul(result, 31) + this.pgw_1 | 0;
    return result;
  };
  protoOf(UpgradeDecision).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpgradeDecision))
      return false;
    var tmp0_other_with_cast = other instanceof UpgradeDecision ? other : THROW_CCE();
    if (!(this.ogw_1 === tmp0_other_with_cast.ogw_1))
      return false;
    if (!(this.pgw_1 === tmp0_other_with_cast.pgw_1))
      return false;
    return true;
  };
  function calculateUpgradeDecision(currentMoney, currentNumUpgrades, maxUpgrades, initialUpgradeCost, numUpgradesWanted) {
    var availableMoney = currentMoney;
    var maxPossibleUpgrades = 0;
    $l$loop: while ((currentNumUpgrades + maxPossibleUpgrades | 0) < maxUpgrades ? maxPossibleUpgrades < numUpgradesWanted : false) {
      var currentUpgradeCost = (initialUpgradeCost + currentNumUpgrades | 0) + maxPossibleUpgrades | 0;
      if (currentUpgradeCost > availableMoney) {
        break $l$loop;
      } else {
        availableMoney = availableMoney - currentUpgradeCost | 0;
        var tmp0 = maxPossibleUpgrades;
        maxPossibleUpgrades = tmp0 + 1 | 0;
      }
    }
    return new UpgradeDecision(maxPossibleUpgrades, currentMoney - availableMoney | 0);
  }
  function getCenterPoint(bottomLeftPositionComponent, sizeComponent) {
    return new GameUnitTuple(GameUnit__plus_impl_rh1b6r(bottomLeftPositionComponent.xg3_1, GameUnit__div_impl_u8qjua(sizeComponent.xg4_1, 2.0)), GameUnit__plus_impl_rh1b6r(bottomLeftPositionComponent.yg3_1, GameUnit__div_impl_u8qjua(sizeComponent.yg4_1, 2.0)));
  }
  function toRectangleEntity(_this__u8e3s4) {
    var thisPosition = _this__u8e3s4.tfz(getKClass(BottomLeftPositionComponent));
    var thisSize = _this__u8e3s4.tfz(getKClass(SizeComponent));
    return new RectangleEntity(thisPosition.xg3_1, thisPosition.yg3_1, thisSize.xg4_1, thisSize.yg4_1);
  }
  function rectCorner(_this__u8e3s4, right) {
    return _RectCorners___init__impl__w373y9(numberToDouble(_this__u8e3s4), numberToDouble(right));
  }
  function toWorldCoordinates(gridSize, point, gameHeight, entityHeight) {
    entityHeight = entityHeight === VOID ? _GameUnit___init__impl__teirvv_0(0) : entityHeight;
    return toWorldCoordinates_1(gridSize, point.sgb_1, point.tgb_1, gameHeight, entityHeight);
  }
  function toWorldCoordinates_0(gridSize, gameUnitPoint, gameHeight, entityHeight) {
    entityHeight = entityHeight === VOID ? _GameUnit___init__impl__teirvv_0(0) : entityHeight;
    return toWorldCoordinates_1(gridSize, _GameUnit___get_value__impl__9ocgox(gameUnitPoint.dg6_1), _GameUnit___get_value__impl__9ocgox(gameUnitPoint.eg6_1), gameHeight, entityHeight);
  }
  function toWorldDimensions_0(entity, gridSize) {
    return toWorldDimensions(entity.lg8(), entity.mg8(), gridSize);
  }
  function Point(x, y) {
    return Vector2_init_$Create$(WorldUnit__toDouble_impl_9mljzf(x), WorldUnit__toDouble_impl_9mljzf(y));
  }
  function intersectRectangles(x1, y1, w1, h1, x2, y2, w2, h2) {
    return ((x1 < x2 + w2 ? y1 < y2 + h2 : false) ? x1 + w1 > x2 : false) ? y1 + h1 > y2 : false;
  }
  function isFullyCoveredBy_1(x1, y1, w1, h1, x2, y2, w2, h2) {
    if (w1 > w2)
      return false;
    if (h1 > h2)
      return false;
    return ((x1 >= x2 ? x1 + w1 <= x2 + w2 : false) ? y1 >= y2 : false) ? y1 + h1 <= y2 + h2 : false;
  }
  function toWorldCoordinates_1(gridSize, x, y, gameHeight, entityHeight) {
    entityHeight = entityHeight === VOID ? _GameUnit___init__impl__teirvv_0(0) : entityHeight;
    return new Pair(new WorldUnit(toWorldUnit_0(gridSize, x)), new WorldUnit(toWorldUnit_0(gridSize, _GameUnit___get_value__impl__9ocgox(gameHeight) - y - _GameUnit___get_value__impl__9ocgox(entityHeight))));
  }
  function toWorldUnit_0(gridSize, value) {
    return GameUnit__toWorldUnit_impl_m803zs(toGameUnit_0(value), gridSize);
  }
  function getIntersectionPointsOfLineSegmentAndRectangle(p1, p2, rectBottomLeft, width, height) {
    var lowerRectX = rectBottomLeft.sgb_1;
    var upperRectX = rectBottomLeft.sgb_1 + width;
    var lowerRectY = rectBottomLeft.tgb_1;
    var upperRectY = rectBottomLeft.tgb_1 + height;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = p1.sgb_1;
    var tmp1_min = p2.sgb_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var lowerPointX = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp2_max = p1.sgb_1;
    var tmp3_max = p2.sgb_1;
    tmp$ret$1 = Math.max(tmp2_max, tmp3_max);
    var upperPointX = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp4_min = p1.tgb_1;
    var tmp5_min = p2.tgb_1;
    tmp$ret$2 = Math.min(tmp4_min, tmp5_min);
    var lowerPointY = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.math.max' call
    var tmp6_max = p1.tgb_1;
    var tmp7_max = p2.tgb_1;
    tmp$ret$3 = Math.max(tmp6_max, tmp7_max);
    var upperPointY = tmp$ret$3;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$4 = LinkedHashSet_init_$Create$();
    var intersectionPoints = tmp$ret$4;
    if (p1.sgb_1 === p2.sgb_1) {
      var containsArg = p1.sgb_1;
      if (!(lowerRectX <= containsArg ? containsArg <= upperRectX : false))
        return emptySet();
      if (lowerPointY <= lowerRectY ? lowerRectY <= upperPointY : false) {
        intersectionPoints.b(new IPoint(p1.sgb_1, lowerRectY));
      }
      if (lowerPointY <= upperRectY ? upperRectY <= upperPointY : false) {
        intersectionPoints.b(new IPoint(p1.sgb_1, upperRectY));
      }
      return toSet(intersectionPoints);
    }
    if (p1.tgb_1 === p2.tgb_1) {
      var containsArg_0 = p1.tgb_1;
      if (!(lowerRectY <= containsArg_0 ? containsArg_0 <= upperRectY : false))
        return emptySet();
      if (lowerPointX <= lowerRectX ? lowerRectX <= upperPointX : false) {
        intersectionPoints.b(new IPoint(lowerRectX, p1.tgb_1));
      }
      if (lowerPointX <= upperRectX ? upperRectX <= upperPointX : false) {
        intersectionPoints.b(new IPoint(upperRectX, p1.tgb_1));
      }
      return toSet(intersectionPoints);
    }
    var yLineM = (p2.tgb_1 - p1.tgb_1) / (p2.sgb_1 - p1.sgb_1);
    var yLineB = p1.tgb_1 - yLineM * p1.sgb_1;
    var yFunc = getIntersectionPointsOfLineSegmentAndRectangle$lambda(yLineM, yLineB);
    var xFunc = getIntersectionPointsOfLineSegmentAndRectangle$lambda_0(yLineB, yLineM);
    var rectTopY = rectBottomLeft.tgb_1 + height;
    var rectBotY = rectBottomLeft.tgb_1;
    var rectLeftX = rectBottomLeft.sgb_1;
    var rectRightX = rectBottomLeft.sgb_1 + width;
    if (lowerPointY <= rectBotY ? rectBotY <= upperPointY : false) {
      var intersectRectX = xFunc(rectBotY);
      if (lowerRectX <= intersectRectX ? intersectRectX <= upperRectX : false) {
        intersectionPoints.b(new IPoint(intersectRectX, rectBotY));
      }
    }
    if (lowerPointY <= rectTopY ? rectTopY <= upperPointY : false) {
      var intersectRectX_0 = xFunc(rectTopY);
      if (lowerRectX <= intersectRectX_0 ? intersectRectX_0 <= upperRectX : false) {
        intersectionPoints.b(new IPoint(intersectRectX_0, rectTopY));
      }
    }
    if (lowerPointX <= rectLeftX ? rectLeftX <= upperPointX : false) {
      var rectY = yFunc(rectLeftX);
      if (lowerRectY <= rectY ? rectY <= upperRectY : false) {
        intersectionPoints.b(new IPoint(rectLeftX, rectY));
      }
    }
    if (lowerPointX <= rectRightX ? rectRightX <= upperPointX : false) {
      var rectY_0 = yFunc(rectRightX);
      if (lowerRectY <= rectY_0 ? rectY_0 <= upperRectY : false) {
        intersectionPoints.b(new IPoint(rectRightX, rectY_0));
      }
    }
    return toSet(intersectionPoints);
  }
  function sequenceOfNullable(element) {
    return element == null ? emptySequence() : sequenceOf([element]);
  }
  function lerp(_this__u8e3s4, target, alpha) {
    var invAlpha = 1.0 - alpha;
    return new IPoint(_this__u8e3s4.sgb_1 * invAlpha + target.sgb_1 * alpha, _this__u8e3s4.tgb_1 * invAlpha + target.tgb_1 * alpha);
  }
  function verticalDirectionTo(_this__u8e3s4, v, allowableDifference) {
    allowableDifference = allowableDifference === VOID ? get_ALLOWABLE_DIRECTION_DIFF() : allowableDifference;
    var tmp$ret$0;
    // Inline function 'kotlin.math.abs' call
    var tmp0_abs = _this__u8e3s4.tgb_1 - v.tgb_1;
    tmp$ret$0 = Math.abs(tmp0_abs);
    if (tmp$ret$0 < allowableDifference) {
      return VerticalDirection_NONE_getInstance();
    }
    if (_this__u8e3s4.tgb_1 > v.tgb_1) {
      return VerticalDirection_DOWN_getInstance();
    } else {
      return VerticalDirection_UP_getInstance();
    }
  }
  function horizontalDirectionTo(_this__u8e3s4, v, allowableDifference) {
    allowableDifference = allowableDifference === VOID ? get_ALLOWABLE_DIRECTION_DIFF() : allowableDifference;
    var tmp$ret$0;
    // Inline function 'kotlin.math.abs' call
    var tmp0_abs = _this__u8e3s4.sgb_1 - v.sgb_1;
    tmp$ret$0 = Math.abs(tmp0_abs);
    if (tmp$ret$0 < allowableDifference) {
      return HorizontalDirection_NONE_getInstance();
    }
    if (_this__u8e3s4.sgb_1 > v.sgb_1) {
      return HorizontalDirection_LEFT_getInstance();
    } else {
      return HorizontalDirection_RIGHT_getInstance();
    }
  }
  function angleRadians(v1, v2) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.atan2' call
    var tmp0_atan2 = v2.tgb_1 - v1.tgb_1;
    var tmp1_atan2 = v2.sgb_1 - v1.sgb_1;
    tmp$ret$0 = Math.atan2(tmp0_atan2, tmp1_atan2);
    return get_radians(tmp$ret$0);
  }
  function getIntersectionPointsOfLineSegmentAndRectangle$lambda($yLineM, $yLineB) {
    return function (x) {
      return $yLineM * x + $yLineB;
    };
  }
  function getIntersectionPointsOfLineSegmentAndRectangle$lambda_0($yLineB, $yLineM) {
    return function (y) {
      return (y - $yLineB) / $yLineM;
    };
  }
  function get_GAME_WIDTH() {
    _init_properties_constants_kt__o0zklf();
    return GAME_WIDTH;
  }
  var GAME_WIDTH;
  function get_GAME_HEIGHT() {
    _init_properties_constants_kt__o0zklf();
    return GAME_HEIGHT;
  }
  var GAME_HEIGHT;
  var LEFT_CLICK_BUTTON;
  var RIGHT_CLICK_BUTTON;
  function get_GRID_SIZE() {
    return GRID_SIZE;
  }
  var GRID_SIZE;
  function get_BORDER_RATIO() {
    return BORDER_RATIO;
  }
  var BORDER_RATIO;
  function get_GRID_LINES_RATIO() {
    return GRID_LINES_RATIO;
  }
  var GRID_LINES_RATIO;
  function get_GRID_NUMBERS_RATIO() {
    return GRID_NUMBERS_RATIO;
  }
  var GRID_NUMBERS_RATIO;
  function get_PATH_LINES_RATIO() {
    return PATH_LINES_RATIO;
  }
  var PATH_LINES_RATIO;
  function get_ENTITY_LABEL_SIZE() {
    return ENTITY_LABEL_SIZE;
  }
  var ENTITY_LABEL_SIZE;
  function get_PATHING_POINT_PRECISION() {
    return PATHING_POINT_PRECISION;
  }
  var PATHING_POINT_PRECISION;
  function get_PATHING_RADIUS() {
    return PATHING_RADIUS;
  }
  var PATHING_RADIUS;
  function get_ALLOWABLE_DIRECTION_DIFF() {
    return ALLOWABLE_DIRECTION_DIFF;
  }
  var ALLOWABLE_DIRECTION_DIFF;
  var properties_initialized_constants_kt_tf27pd;
  function _init_properties_constants_kt__o0zklf() {
    if (properties_initialized_constants_kt_tf27pd) {
    } else {
      properties_initialized_constants_kt_tf27pd = true;
      GAME_WIDTH = _GameUnit___init__impl__teirvv_0(20);
      GAME_HEIGHT = _GameUnit___init__impl__teirvv_0(30);
      LEFT_CLICK_BUTTON = 0;
      RIGHT_CLICK_BUTTON = 0;
    }
  }
  function xy_0(_this__u8e3s4, worldX, worldY) {
    return xy(_this__u8e3s4, _WorldUnit___get_value__impl__xwm9rd(worldX), _WorldUnit___get_value__impl__xwm9rd(worldY));
  }
  function size_1(_this__u8e3s4, worldWidth, worldHeight) {
    return size(_this__u8e3s4, WorldUnit__toFloat_impl_2n0xxa(worldWidth), WorldUnit__toFloat_impl_2n0xxa(worldHeight));
  }
  var TEST_DATA_VFS;
  function toGameMap(_this__u8e3s4) {
    _init_properties_korge_vfs_utils_kt__mxu1rk();
    var tmp$ret$3;
    $l$block: {
      // Inline function 'com.xenotactic.gamelogic.utils.decodeJsonBlocking' call
      if (existsBlocking(_this__u8e3s4)) {
        var tmp$ret$2;
        // Inline function 'kotlinx.serialization.decodeFromString' call
        var tmp2_decodeFromString = Default_getInstance();
        var tmp3_decodeFromString = readStringBlocking(_this__u8e3s4);
        var tmp$ret$1;
        // Inline function 'kotlinx.serialization.serializer' call
        var tmp1_serializer = tmp2_decodeFromString.of9();
        var tmp$ret$0;
        // Inline function 'kotlinx.serialization.internal.cast' call
        var tmp0_cast = serializer(tmp1_serializer, createKType(getKClass(GameMap), arrayOf([]), false));
        tmp$ret$0 = isInterface(tmp0_cast, KSerializer) ? tmp0_cast : THROW_CCE();
        tmp$ret$1 = tmp$ret$0;
        tmp$ret$2 = tmp2_decodeFromString.pfp(tmp$ret$1, tmp3_decodeFromString);
        tmp$ret$3 = tmp$ret$2;
        break $l$block;
      }
      tmp$ret$3 = null;
    }
    return tmp$ret$3;
  }
  function getGoldenJsonFiles() {
    _init_properties_korge_vfs_utils_kt__mxu1rk();
    return runBlockingNoJs(VOID, getGoldenJsonFiles$slambda_0(null));
  }
  function existsBlocking(_this__u8e3s4) {
    _init_properties_korge_vfs_utils_kt__mxu1rk();
    return runBlockingNoJs(VOID, existsBlocking$slambda_0(_this__u8e3s4, null));
  }
  function GOLDENS_DATA_VFS($completion) {
    var tmp = new $GOLDENS_DATA_VFSCOROUTINE$2($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readStringBlocking(_this__u8e3s4) {
    _init_properties_korge_vfs_utils_kt__mxu1rk();
    return runBlockingNoJs(VOID, readStringBlocking$slambda_0(_this__u8e3s4, null));
  }
  function TEST_DATA_VFS$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(TEST_DATA_VFS$slambda).jgx = function ($this$runBlockingNoJs, $completion) {
    var tmp = this.d1o($this$runBlockingNoJs, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(TEST_DATA_VFS$slambda).g8 = function (p1, $completion) {
    return this.jgx((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(TEST_DATA_VFS$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            var tmp_0 = this;
            tmp_0.igx_1 = get_localCurrentDirVfs().p1b('src/commonTest/testdata');
            this.y7_1 = 1;
            suspendResult = this.igx_1.l28([], this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            return this.igx_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(TEST_DATA_VFS$slambda).d1o = function ($this$runBlockingNoJs, completion) {
    var i = new TEST_DATA_VFS$slambda(completion);
    i.hgx_1 = $this$runBlockingNoJs;
    return i;
  };
  function TEST_DATA_VFS$slambda_0(resultContinuation) {
    var i = new TEST_DATA_VFS$slambda(resultContinuation);
    var l = function ($this$runBlockingNoJs, $completion) {
      return i.jgx($this$runBlockingNoJs, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function getGoldenJsonFiles$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(getGoldenJsonFiles$slambda).ugx = function ($this$runBlockingNoJs, $completion) {
    var tmp = this.d1o($this$runBlockingNoJs, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(getGoldenJsonFiles$slambda).g8 = function (p1, $completion) {
    return this.ugx((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(getGoldenJsonFiles$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = GOLDENS_DATA_VFS(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.tgx_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.tgx_1.y2d(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(getGoldenJsonFiles$slambda).d1o = function ($this$runBlockingNoJs, completion) {
    var i = new getGoldenJsonFiles$slambda(completion);
    i.sgx_1 = $this$runBlockingNoJs;
    return i;
  };
  function getGoldenJsonFiles$slambda_0(resultContinuation) {
    var i = new getGoldenJsonFiles$slambda(resultContinuation);
    var l = function ($this$runBlockingNoJs, $completion) {
      return i.ugx($this$runBlockingNoJs, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function existsBlocking$slambda($this_existsBlocking, resultContinuation) {
    this.dgy_1 = $this_existsBlocking;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(existsBlocking$slambda).fgy = function ($this$runBlockingNoJs, $completion) {
    var tmp = this.d1o($this$runBlockingNoJs, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(existsBlocking$slambda).g8 = function (p1, $completion) {
    return this.fgy((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(existsBlocking$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.dgy_1.w2d(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return suspendResult;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(existsBlocking$slambda).d1o = function ($this$runBlockingNoJs, completion) {
    var i = new existsBlocking$slambda(this.dgy_1, completion);
    i.egy_1 = $this$runBlockingNoJs;
    return i;
  };
  function existsBlocking$slambda_0($this_existsBlocking, resultContinuation) {
    var i = new existsBlocking$slambda($this_existsBlocking, resultContinuation);
    var l = function ($this$runBlockingNoJs, $completion) {
      return i.fgy($this$runBlockingNoJs, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function readStringBlocking$slambda($this_readStringBlocking, resultContinuation) {
    this.ogy_1 = $this_readStringBlocking;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(readStringBlocking$slambda).qgy = function ($this$runBlockingNoJs, $completion) {
    var tmp = this.d1o($this$runBlockingNoJs, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(readStringBlocking$slambda).g8 = function (p1, $completion) {
    return this.qgy((!(p1 == null) ? isInterface(p1, CoroutineScope) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(readStringBlocking$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ogy_1.s2d(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return suspendResult;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(readStringBlocking$slambda).d1o = function ($this$runBlockingNoJs, completion) {
    var i = new readStringBlocking$slambda(this.ogy_1, completion);
    i.pgy_1 = $this$runBlockingNoJs;
    return i;
  };
  function readStringBlocking$slambda_0($this_readStringBlocking, resultContinuation) {
    var i = new readStringBlocking$slambda($this_readStringBlocking, resultContinuation);
    var l = function ($this$runBlockingNoJs, $completion) {
      return i.qgy($this$runBlockingNoJs, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function $GOLDENS_DATA_VFSCOROUTINE$2(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf($GOLDENS_DATA_VFSCOROUTINE$2).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            var tmp_0 = this;
            tmp_0.ygw_1 = get_localCurrentDirVfs().p1b('src/commonTest/testdata/goldens');
            this.y7_1 = 1;
            suspendResult = this.ygw_1.l28([], this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            return this.ygw_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  var properties_initialized_korge_vfs_utils_kt_xv78ni;
  function _init_properties_korge_vfs_utils_kt__mxu1rk() {
    if (properties_initialized_korge_vfs_utils_kt_xv78ni) {
    } else {
      properties_initialized_korge_vfs_utils_kt_xv78ni = true;
      TEST_DATA_VFS = runBlockingNoJs(VOID, TEST_DATA_VFS$slambda_0(null));
    }
  }
  function get_PLACEMENT_ERRORS() {
    _init_properties_restrictions_kt__f2ugbr();
    return PLACEMENT_ERRORS;
  }
  var PLACEMENT_ERRORS;
  var ErrorType_NONE_instance;
  var ErrorType_BLOCKS_PATH_instance;
  var ErrorType_INTERSECTS_BLOCKING_ENTITIES_instance;
  var ErrorType_MAX_SUPPLY_instance;
  var ErrorType_NOT_ENOUGH_GOLD_instance;
  var ErrorType_NOT_ENOUGH_SUPPLY_instance;
  var ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_instance;
  var ErrorType_entriesInitialized;
  function ErrorType_initEntries() {
    if (ErrorType_entriesInitialized)
      return Unit_getInstance();
    ErrorType_entriesInitialized = true;
    ErrorType_NONE_instance = new ErrorType('NONE', 0, 'No errors', 'No errors');
    ErrorType_BLOCKS_PATH_instance = new ErrorType('BLOCKS_PATH', 1, 'Placing entity blocks the path.', 'Blocks path');
    ErrorType_INTERSECTS_BLOCKING_ENTITIES_instance = new ErrorType('INTERSECTS_BLOCKING_ENTITIES', 2, 'Placing entity intersects with other blocking entities.', 'Intersects with another entity');
    ErrorType_MAX_SUPPLY_instance = new ErrorType('MAX_SUPPLY', 3, 'Unable to place because already at max supply.', 'Is at max supply');
    ErrorType_NOT_ENOUGH_GOLD_instance = new ErrorType('NOT_ENOUGH_GOLD', 4, "Unable to place because there's not enough gold", 'Not enough gold.');
    ErrorType_NOT_ENOUGH_SUPPLY_instance = new ErrorType('NOT_ENOUGH_SUPPLY', 5, "Unable to place because there's not enough supply.", 'Not enough supply');
    ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_instance = new ErrorType('SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY', 6, 'Unable to sell the supply depot because it will result in insufficient supply', 'Unable to sell supply depot, will result in insufficient supply');
  }
  function ErrorType(name, ordinal, description, shortString) {
    Enum.call(this, name, ordinal);
    this.tgy_1 = description;
    this.ugy_1 = shortString;
  }
  function Errors(errors) {
    ValidationResult.call(this);
    this.kgt_1 = errors;
    // Inline function 'kotlin.require' call
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp0_isNotEmpty = this.kgt_1;
    tmp$ret$0 = !tmp0_isNotEmpty.p();
    var tmp1_require = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
  }
  protoOf(Errors).lgt = function () {
    return first(this.kgt_1).ugy_1;
  };
  protoOf(Errors).toString = function () {
    return 'Errors(errors=' + this.kgt_1 + ')';
  };
  protoOf(Errors).hashCode = function () {
    return hashCode(this.kgt_1);
  };
  protoOf(Errors).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Errors))
      return false;
    var tmp0_other_with_cast = other instanceof Errors ? other : THROW_CCE();
    if (!equals(this.kgt_1, tmp0_other_with_cast.kgt_1))
      return false;
    return true;
  };
  function Ok() {
    Ok_instance = this;
    ValidationResult.call(this);
  }
  var Ok_instance;
  function Ok_getInstance() {
    if (Ok_instance == null)
      new Ok();
    return Ok_instance;
  }
  function ValidationResult() {
  }
  function checkCanPlaceTowerEntity(engine, entity) {
    _init_properties_restrictions_kt__f2ugbr();
    var validator = new Validator(engine, entity);
    return validator.zgy(plus_1(plus_1(get_PLACEMENT_ERRORS(), ErrorType_MAX_SUPPLY_getInstance()), ErrorType_NOT_ENOUGH_GOLD_getInstance()));
  }
  function checkCanPlaceEntity(engine, entity) {
    _init_properties_restrictions_kt__f2ugbr();
    var validator = new Validator(engine, entity);
    return validator.zgy(get_PLACEMENT_ERRORS());
  }
  function getChecker($this, errorType) {
    var tmp0_subject = errorType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = NoError_getInstance();
        break;
      case 1:
        tmp = new CheckEntityBlocksPath($this.xgy_1, ensureNotNull($this.wgy_1));
        break;
      case 2:
        tmp = new CheckIntersectsBlockingEntities($this.xgy_1, ensureNotNull($this.wgy_1));
        break;
      case 3:
        tmp = new CheckIsAtMaxSupply($this.xgy_1);
        break;
      case 4:
        var tmp$ret$1;
        // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
        var tmp0_getSingleton = $this.vgy_1.ggo_1;
        var tmp$ret$0;
        // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
        var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(MutableGoldState));
        tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof MutableGoldState) ? tmp_0 : THROW_CCE();
        var tmp0_elvis_lhs = tmp$ret$0;
        var tmp_1;
        if (tmp0_elvis_lhs == null) {
          throw new SingletonInjectionDoesNotExistException(Validator$getChecker$lambda);
        } else {
          tmp_1 = tmp0_elvis_lhs;
        }
        tmp$ret$1 = tmp_1;

        tmp = new CheckNotEnoughGold(tmp$ret$1, ensureNotNull($this.wgy_1));
        break;
      case 5:
        tmp = new CheckNotEnoughSupply($this.vgy_1.ego_1, $this.ygy_1, ensureNotNull($this.wgy_1));
        break;
      case 6:
        var tmp$ret$3;
        // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
        var tmp1_getSingleton = $this.vgy_1.ggo_1;
        var tmp$ret$2;
        // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
        var tmp_2 = tmp1_getSingleton.wg2_1.h3(getKClass(GameplayState));
        tmp$ret$2 = (tmp_2 == null ? true : tmp_2 instanceof GameplayState) ? tmp_2 : THROW_CCE();
        var tmp0_elvis_lhs_0 = tmp$ret$2;
        var tmp_3;
        if (tmp0_elvis_lhs_0 == null) {
          throw new SingletonInjectionDoesNotExistException(Validator$getChecker$lambda_0);
        } else {
          tmp_3 = tmp0_elvis_lhs_0;
        }
        tmp$ret$3 = tmp_3;

        tmp = new SellingSupplyDepotLeadsToInsufficientSupply($this.vgy_1.ego_1, tmp$ret$3);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function Validator$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function Validator$getChecker$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableGoldState);
  }
  function Validator$getChecker$lambda_0() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function Validator(engine, entity) {
    entity = entity === VOID ? null : entity;
    this.vgy_1 = engine;
    this.wgy_1 = entity;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.vgy_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapApi) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(Validator$gameMapApi$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.xgy_1 = tmp$ret$1;
    this.ygy_1 = new StateUtils(this.vgy_1);
  }
  protoOf(Validator).agz = function (errorsToValidate) {
    return this.zgy(toList_0(errorsToValidate));
  };
  protoOf(Validator).zgy = function (errorsToValidate) {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapNotNull' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapNotNullTo' call
    var tmp0_mapNotNullTo = ArrayList_init_$Create$();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = errorsToValidate.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'kotlin.collections.mapNotNullTo.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'com.xenotactic.gamelogic.utils.Validator.validate.<anonymous>' call
      var checker = getChecker(this, element);
      var tmp;
      if (checker.cgz()) {
        tmp = checker.bgz_1;
      } else {
        tmp = null;
      }
      tmp$ret$0 = tmp;
      var tmp0_safe_receiver = tmp$ret$0;
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$1;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp0_mapNotNullTo.b(tmp0_safe_receiver);
        tmp$ret$1 = Unit_getInstance();
      }
    }
    tmp$ret$2 = tmp0_mapNotNullTo;
    tmp$ret$3 = tmp$ret$2;
    var errors = tmp$ret$3;
    return errors.p() ? Ok_getInstance() : new Errors(errors);
  };
  function NoError() {
    NoError_instance = this;
    ValidatorTypes.call(this, ErrorType_NONE_getInstance());
  }
  protoOf(NoError).cgz = function () {
    return false;
  };
  var NoError_instance;
  function NoError_getInstance() {
    if (NoError_instance == null)
      new NoError();
    return NoError_instance;
  }
  function CheckEntityBlocksPath(gameMapApi, entity) {
    ValidatorTypes.call(this, ErrorType_BLOCKS_PATH_getInstance());
    this.fgz_1 = gameMapApi;
    this.ggz_1 = entity;
  }
  protoOf(CheckEntityBlocksPath).cgz = function () {
    return this.fgz_1.jgs([this.ggz_1]);
  };
  protoOf(CheckEntityBlocksPath).toString = function () {
    return 'CheckEntityBlocksPath(gameMapApi=' + this.fgz_1 + ', entity=' + this.ggz_1 + ')';
  };
  protoOf(CheckEntityBlocksPath).hashCode = function () {
    var result = hashCode(this.fgz_1);
    result = imul(result, 31) + this.ggz_1.hashCode() | 0;
    return result;
  };
  protoOf(CheckEntityBlocksPath).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CheckEntityBlocksPath))
      return false;
    var tmp0_other_with_cast = other instanceof CheckEntityBlocksPath ? other : THROW_CCE();
    if (!equals(this.fgz_1, tmp0_other_with_cast.fgz_1))
      return false;
    if (!this.ggz_1.equals(tmp0_other_with_cast.ggz_1))
      return false;
    return true;
  };
  function CheckIntersectsBlockingEntities(gameMapApi, entity) {
    ValidatorTypes.call(this, ErrorType_INTERSECTS_BLOCKING_ENTITIES_getInstance());
    this.igz_1 = gameMapApi;
    this.jgz_1 = entity;
  }
  protoOf(CheckIntersectsBlockingEntities).cgz = function () {
    return this.igz_1.kgs(this.jgz_1);
  };
  protoOf(CheckIntersectsBlockingEntities).toString = function () {
    return 'CheckIntersectsBlockingEntities(gameMapApi=' + this.igz_1 + ', entity=' + this.jgz_1 + ')';
  };
  protoOf(CheckIntersectsBlockingEntities).hashCode = function () {
    var result = hashCode(this.igz_1);
    result = imul(result, 31) + this.jgz_1.hashCode() | 0;
    return result;
  };
  protoOf(CheckIntersectsBlockingEntities).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CheckIntersectsBlockingEntities))
      return false;
    var tmp0_other_with_cast = other instanceof CheckIntersectsBlockingEntities ? other : THROW_CCE();
    if (!equals(this.igz_1, tmp0_other_with_cast.igz_1))
      return false;
    if (!this.jgz_1.equals(tmp0_other_with_cast.jgz_1))
      return false;
    return true;
  };
  function CheckIsAtMaxSupply(gameMapApi) {
    ValidatorTypes.call(this, ErrorType_MAX_SUPPLY_getInstance());
    this.lgz_1 = gameMapApi;
  }
  protoOf(CheckIsAtMaxSupply).cgz = function () {
    return this.lgz_1.tgs();
  };
  protoOf(CheckIsAtMaxSupply).toString = function () {
    return 'CheckIsAtMaxSupply(gameMapApi=' + this.lgz_1 + ')';
  };
  protoOf(CheckIsAtMaxSupply).hashCode = function () {
    return hashCode(this.lgz_1);
  };
  protoOf(CheckIsAtMaxSupply).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CheckIsAtMaxSupply))
      return false;
    var tmp0_other_with_cast = other instanceof CheckIsAtMaxSupply ? other : THROW_CCE();
    if (!equals(this.lgz_1, tmp0_other_with_cast.lgz_1))
      return false;
    return true;
  };
  function CheckNotEnoughGold(mutableGoldState, entity) {
    ValidatorTypes.call(this, ErrorType_NOT_ENOUGH_GOLD_getInstance());
    this.ngz_1 = mutableGoldState;
    this.ogz_1 = entity;
  }
  protoOf(CheckNotEnoughGold).cgz = function () {
    var cost = this.ogz_1.tfz(getKClass(EntityCostComponent));
    return cost.fg4_1 > this.ngz_1.agp_1;
  };
  protoOf(CheckNotEnoughGold).toString = function () {
    return 'CheckNotEnoughGold(mutableGoldState=' + this.ngz_1 + ', entity=' + this.ogz_1 + ')';
  };
  protoOf(CheckNotEnoughGold).hashCode = function () {
    var result = hashCode(this.ngz_1);
    result = imul(result, 31) + this.ogz_1.hashCode() | 0;
    return result;
  };
  protoOf(CheckNotEnoughGold).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CheckNotEnoughGold))
      return false;
    var tmp0_other_with_cast = other instanceof CheckNotEnoughGold ? other : THROW_CCE();
    if (!equals(this.ngz_1, tmp0_other_with_cast.ngz_1))
      return false;
    if (!this.ogz_1.equals(tmp0_other_with_cast.ogz_1))
      return false;
    return true;
  };
  function CheckNotEnoughSupply(gameWorld, stateUtils, entity) {
    ValidatorTypes.call(this, ErrorType_NOT_ENOUGH_SUPPLY_getInstance());
    this.qgz_1 = gameWorld;
    this.rgz_1 = stateUtils;
    this.sgz_1 = entity;
  }
  protoOf(CheckNotEnoughSupply).cgz = function () {
    var supplyCostComponent = this.sgz_1.tfz(getKClass(SupplyCostComponent));
    var currentSupplyUsage = this.qgz_1.ugc();
    var currentMaxSupplyCost = this.rgz_1.lgw();
    return (supplyCostComponent.bg5_1 + currentSupplyUsage | 0) > currentMaxSupplyCost;
  };
  protoOf(CheckNotEnoughSupply).toString = function () {
    return 'CheckNotEnoughSupply(gameWorld=' + this.qgz_1 + ', stateUtils=' + this.rgz_1 + ', entity=' + this.sgz_1 + ')';
  };
  protoOf(CheckNotEnoughSupply).hashCode = function () {
    var result = hashCode(this.qgz_1);
    result = imul(result, 31) + hashCode(this.rgz_1) | 0;
    result = imul(result, 31) + this.sgz_1.hashCode() | 0;
    return result;
  };
  protoOf(CheckNotEnoughSupply).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CheckNotEnoughSupply))
      return false;
    var tmp0_other_with_cast = other instanceof CheckNotEnoughSupply ? other : THROW_CCE();
    if (!equals(this.qgz_1, tmp0_other_with_cast.qgz_1))
      return false;
    if (!equals(this.rgz_1, tmp0_other_with_cast.rgz_1))
      return false;
    if (!this.sgz_1.equals(tmp0_other_with_cast.sgz_1))
      return false;
    return true;
  };
  function SellingSupplyDepotLeadsToInsufficientSupply(gameWorld, gameplayState) {
    ValidatorTypes.call(this, ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_getInstance());
    this.ugz_1 = gameWorld;
    this.vgz_1 = gameplayState;
  }
  protoOf(SellingSupplyDepotLeadsToInsufficientSupply).cgz = function () {
    var currentNumDepots = this.ugz_1.bgc_1.l();
    // Inline function 'kotlin.require' call
    var tmp0_require = currentNumDepots >= 1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var uncappedSupply = this.vgz_1.tgo_1 + imul(currentNumDepots, this.vgz_1.ugo_1) | 0;
    var uncappedSupplyAfterSelling = uncappedSupply - this.vgz_1.ugo_1 | 0;
    var currentSupplyUsage = this.ugz_1.ugc();
    return currentSupplyUsage > uncappedSupplyAfterSelling;
  };
  protoOf(SellingSupplyDepotLeadsToInsufficientSupply).toString = function () {
    return 'SellingSupplyDepotLeadsToInsufficientSupply(gameWorld=' + this.ugz_1 + ', gameplayState=' + this.vgz_1 + ')';
  };
  protoOf(SellingSupplyDepotLeadsToInsufficientSupply).hashCode = function () {
    var result = hashCode(this.ugz_1);
    result = imul(result, 31) + this.vgz_1.hashCode() | 0;
    return result;
  };
  protoOf(SellingSupplyDepotLeadsToInsufficientSupply).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof SellingSupplyDepotLeadsToInsufficientSupply))
      return false;
    var tmp0_other_with_cast = other instanceof SellingSupplyDepotLeadsToInsufficientSupply ? other : THROW_CCE();
    if (!equals(this.ugz_1, tmp0_other_with_cast.ugz_1))
      return false;
    if (!this.vgz_1.equals(tmp0_other_with_cast.vgz_1))
      return false;
    return true;
  };
  function ValidatorTypes(errorType) {
    this.bgz_1 = errorType;
  }
  function checkCanSellSupplyDepotEntity(engine) {
    _init_properties_restrictions_kt__f2ugbr();
    var validator = new Validator(engine);
    return validator.agz([ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_getInstance()]);
  }
  function checkCanPlaceSupplyDepotEntity(engine, entity) {
    _init_properties_restrictions_kt__f2ugbr();
    var validator = new Validator(engine, entity);
    return validator.zgy(plus_1(get_PLACEMENT_ERRORS(), ErrorType_NOT_ENOUGH_GOLD_getInstance()));
  }
  function ErrorType_NONE_getInstance() {
    ErrorType_initEntries();
    return ErrorType_NONE_instance;
  }
  function ErrorType_BLOCKS_PATH_getInstance() {
    ErrorType_initEntries();
    return ErrorType_BLOCKS_PATH_instance;
  }
  function ErrorType_INTERSECTS_BLOCKING_ENTITIES_getInstance() {
    ErrorType_initEntries();
    return ErrorType_INTERSECTS_BLOCKING_ENTITIES_instance;
  }
  function ErrorType_MAX_SUPPLY_getInstance() {
    ErrorType_initEntries();
    return ErrorType_MAX_SUPPLY_instance;
  }
  function ErrorType_NOT_ENOUGH_GOLD_getInstance() {
    ErrorType_initEntries();
    return ErrorType_NOT_ENOUGH_GOLD_instance;
  }
  function ErrorType_NOT_ENOUGH_SUPPLY_getInstance() {
    ErrorType_initEntries();
    return ErrorType_NOT_ENOUGH_SUPPLY_instance;
  }
  function ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_getInstance() {
    ErrorType_initEntries();
    return ErrorType_SELLING_SUPPLY_DEPOT_LEADS_TO_INSUFFICIENT_SUPPLY_instance;
  }
  var properties_initialized_restrictions_kt_52by95;
  function _init_properties_restrictions_kt__f2ugbr() {
    if (properties_initialized_restrictions_kt_52by95) {
    } else {
      properties_initialized_restrictions_kt_52by95 = true;
      PLACEMENT_ERRORS = listOf_0([ErrorType_BLOCKS_PATH_getInstance(), ErrorType_INTERSECTS_BLOCKING_ENTITIES_getInstance()]);
    }
  }
  function Success_2() {
    Success_instance = this;
    MapVerificationResult.call(this);
  }
  var Success_instance;
  function Success_getInstance() {
    if (Success_instance == null)
      new Success_2();
    return Success_instance;
  }
  function Failure_0(error) {
    MapVerificationResult.call(this);
    this.wgz_1 = error;
  }
  protoOf(Failure_0).toString = function () {
    return 'Failure(error=' + this.wgz_1 + ')';
  };
  protoOf(Failure_0).hashCode = function () {
    return getStringHashCode(this.wgz_1);
  };
  protoOf(Failure_0).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Failure_0))
      return false;
    var tmp0_other_with_cast = other instanceof Failure_0 ? other : THROW_CCE();
    if (!(this.wgz_1 === tmp0_other_with_cast.wgz_1))
      return false;
    return true;
  };
  function MapVerificationResult() {
  }
  function verify(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4.wg9();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return new Failure_0('Missing start');
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var start = tmp;
    var tmp1_elvis_lhs = _this__u8e3s4.xg9();
    if (tmp1_elvis_lhs == null)
      return new Failure_0('Missing finish');
    else
      tmp1_elvis_lhs;
    var blockingEntities = _this__u8e3s4.zga();
    var pathingEntities = _this__u8e3s4.fgb();
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = pathingEntities.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'verify.<anonymous>' call
      if (element.xg8(blockingEntities))
        return new Failure_0('Pathing entity is fully covered by a blocking entity');
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = _this__u8e3s4.yg9();
    var tmp0_iterator_0 = tmp0_forEach.h();
    while (tmp0_iterator_0.j()) {
      var element_0 = tmp0_iterator_0.k();
      // Inline function 'verify.<anonymous>' call
      // Inline function 'kotlin.collections.forEach' call
      var tmp0_iterator_1 = pathingEntities.h();
      while (tmp0_iterator_1.j()) {
        var element_1 = tmp0_iterator_1.k();
        // Inline function 'verify.<anonymous>.<anonymous>' call
        if (element_0.ogh_1.yg8(element_1))
          return new Failure_0('Teleport in ' + element_0.ogh_1 + ' is fully ' + ('covered by pathing entity: ' + element_1));
      }
      if (element_0.pgh_1.xg8(blockingEntities)) {
        return new Failure_0('Teleport out ' + element_0.pgh_1 + ' is fully covered by blocking entity');
      }
      var tmp0_elvis_lhs_0 = PathFinder_getInstance().ygj(_this__u8e3s4, listOf_0([start, element_0.pgh_1]), emptyList());
      if (tmp0_elvis_lhs_0 == null)
        return new Failure_0('Teleport ' + element_0.pgh_1 + ' is unreachable');
      else
        tmp0_elvis_lhs_0;
    }
    var tmp2_elvis_lhs = PathFinder_getInstance().tgj(_this__u8e3s4);
    if (tmp2_elvis_lhs == null)
      return new Failure_0('No path available');
    else
      tmp2_elvis_lhs;
    return Success_getInstance();
  }
  function UIEightDirectionalSprite(imageDataContainer) {
    RectBase.call(this);
    this.eh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('up_left'));
    this.fh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('up'));
    this.gh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('up_right'));
    this.hh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('left'));
    this.ih2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('right'));
    this.jh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('down_left'));
    this.kh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('down'));
    this.lh2_1 = ensureNotNull(imageDataContainer.r6n_1.h3('down_right'));
    this.mh2_1 = listOf_0([this.eh2_1, this.fh2_1, this.gh2_1, this.hh2_1, this.ih2_1, this.jh2_1, this.kh2_1, this.lh2_1]);
    this.nh2_1 = EightDirection_DOWN_getInstance();
    this.le3(first(this.kh2_1.l6n_1.t6m_1).t6o());
    this.oh2_1 = this.kh2_1.d6n_1.l();
    this.ph2_1 = 0;
    println(trimIndent('\n            EightDirectionalSpriteUI\n            baseBitmap: ' + this.ady_1 + '\n            baseBitmap.width: ' + this.ady_1.x18() + '\n            baseBitmap.height: ' + this.ady_1.y18() + '\n            down: ' + this.kh2_1 + '\n        '));
    // Inline function 'kotlin.require' call
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp0_all = this.mh2_1;
      var tmp;
      if (isInterface(tmp0_all, Collection)) {
        tmp = tmp0_all.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_all.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.gamelogic.views.UIEightDirectionalSprite.<anonymous>' call
        tmp$ret$1 = element.d6n_1.l() === this.oh2_1;
        if (!tmp$ret$1) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    var tmp1_require = tmp$ret$0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$2;
      // Inline function 'com.xenotactic.gamelogic.views.UIEightDirectionalSprite.<anonymous>' call
      tmp$ret$2 = 'Expected all sprites to have the same number of walking frames.';
      var message = tmp$ret$2;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    this.zdx_1 = false;
  }
  protoOf(UIEightDirectionalSprite).de3 = function () {
    return this.ady_1.x18();
  };
  protoOf(UIEightDirectionalSprite).ee3 = function () {
    return this.ady_1.y18();
  };
  protoOf(UIEightDirectionalSprite).qh2 = function (direction) {
    var imageData = this.rh2(direction);
    this.le3(imageData.l6n_1.t6m_1.n(this.ph2_1).t6o());
    this.nh2_1 = direction;
  };
  protoOf(UIEightDirectionalSprite).sh2 = function () {
    this.ph2_1 = (this.ph2_1 + 1 | 0) % this.oh2_1 | 0;
    this.qh2(this.nh2_1);
  };
  protoOf(UIEightDirectionalSprite).rh2 = function (direction) {
    var tmp0_subject = direction;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = this.eh2_1;
        break;
      case 1:
        tmp = this.fh2_1;
        break;
      case 2:
        tmp = this.gh2_1;
        break;
      case 3:
        tmp = this.hh2_1;
        break;
      case 4:
        tmp = this.ih2_1;
        break;
      case 5:
        tmp = this.jh2_1;
        break;
      case 6:
        tmp = this.kh2_1;
        break;
      case 7:
        tmp = this.lh2_1;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  var EightDirection_UP_LEFT_instance;
  var EightDirection_UP_instance;
  var EightDirection_UP_RIGHT_instance;
  var EightDirection_LEFT_instance;
  var EightDirection_RIGHT_instance;
  var EightDirection_DOWN_LEFT_instance;
  var EightDirection_DOWN_instance;
  var EightDirection_DOWN_RIGHT_instance;
  var EightDirection_entriesInitialized;
  function EightDirection_initEntries() {
    if (EightDirection_entriesInitialized)
      return Unit_getInstance();
    EightDirection_entriesInitialized = true;
    EightDirection_UP_LEFT_instance = new EightDirection('UP_LEFT', 0);
    EightDirection_UP_instance = new EightDirection('UP', 1);
    EightDirection_UP_RIGHT_instance = new EightDirection('UP_RIGHT', 2);
    EightDirection_LEFT_instance = new EightDirection('LEFT', 3);
    EightDirection_RIGHT_instance = new EightDirection('RIGHT', 4);
    EightDirection_DOWN_LEFT_instance = new EightDirection('DOWN_LEFT', 5);
    EightDirection_DOWN_instance = new EightDirection('DOWN', 6);
    EightDirection_DOWN_RIGHT_instance = new EightDirection('DOWN_RIGHT', 7);
  }
  function EightDirection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function EightDirection_UP_LEFT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_UP_LEFT_instance;
  }
  function EightDirection_UP_getInstance() {
    EightDirection_initEntries();
    return EightDirection_UP_instance;
  }
  function EightDirection_UP_RIGHT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_UP_RIGHT_instance;
  }
  function EightDirection_LEFT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_LEFT_instance;
  }
  function EightDirection_RIGHT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_RIGHT_instance;
  }
  function EightDirection_DOWN_LEFT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_DOWN_LEFT_instance;
  }
  function EightDirection_DOWN_getInstance() {
    EightDirection_initEntries();
    return EightDirection_DOWN_instance;
  }
  function EightDirection_DOWN_RIGHT_getInstance() {
    EightDirection_initEntries();
    return EightDirection_DOWN_RIGHT_instance;
  }
  function UIEntity(entityType, entityWidth, entityHeight, gridSize, borderSize, speedEffect) {
    speedEffect = speedEffect === VOID ? null : speedEffect;
    Container.call(this);
    this.ch5_1 = entityType;
    this.dh5_1 = entityWidth;
    this.eh5_1 = entityHeight;
    this.fh5_1 = speedEffect;
    this.gh5_1 = numberToDouble(gridSize);
    this.hh5_1 = numberToDouble(borderSize);
    var tmp0_container = toWorldDimensions(this.dh5_1, this.eh5_1, gridSize);
    var worldWidth = tmp0_container.c5().ngw_1;
    var worldHeight = tmp0_container.d5().ngw_1;
    println(trimIndent('\n            rendering: entityType: ' + this.ch5_1 + ', worldWidth: ' + new WorldUnit(worldWidth) + ', worldHeight: ' + new WorldUnit(worldHeight) + '\n        '));
    var tmp1_subject = this.ch5_1;
    var tmp0 = tmp1_subject.b2_1;
    switch (tmp0) {
      case 2:
        var tmp$ret$0;
        // Inline function 'kotlin.apply' call
        var tmp0_apply = new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().u4v_1));
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.xenotactic.gamelogic.views.UIEntity.<anonymous>' call
        addTo(tmp0_apply, this);
        tmp$ret$0 = tmp0_apply;

        ;
        break;
      case 1:
        addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().r4t_1)), this);
        ;
        break;
      case 0:
        var tmp$ret$1;
        // Inline function 'kotlin.apply' call
        var tmp1_apply = new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().m4s_1));
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.xenotactic.gamelogic.views.UIEntity.<anonymous>' call
        addTo(tmp1_apply, this);
        tmp$ret$1 = tmp1_apply;

        ;
        break;
      case 4:
        var tmp$ret$5;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp4_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
        var tmp5_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
        var tmp6_solidRect = MaterialColors_getInstance().w5d_1;
        var tmp$ret$4;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp3_addTo = new SolidRect(new Size(tmp4_solidRect, tmp5_solidRect), tmp6_solidRect);
        var tmp$ret$3;
        // Inline function 'kotlin.apply' call
        var tmp2_apply = addTo(tmp3_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$2;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$2 = Unit_getInstance();
        tmp$ret$3 = tmp2_apply;
        tmp$ret$4 = tmp$ret$3;
        tmp$ret$5 = tmp$ret$4;

        ;
        var tmp$ret$9;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp9_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldWidth, borderSize));
        var tmp10_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldHeight, borderSize));
        var tmp11_solidRect = MaterialColors_getInstance().a5e_1;
        var tmp$ret$8;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp8_addTo = new SolidRect(Size_init_$Create$(tmp9_solidRect, tmp10_solidRect), tmp11_solidRect);
        var tmp$ret$7;
        // Inline function 'kotlin.apply' call
        var tmp7_apply = addTo(tmp8_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$6;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$6 = Unit_getInstance();
        tmp$ret$7 = tmp7_apply;
        tmp$ret$8 = tmp$ret$7;
        tmp$ret$9 = tmp$ret$8;

        centerOn(tmp$ret$9, this);
        ;
        break;
      case 3:
        var tmp$ret$13;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp14_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
        var tmp15_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
        var tmp16_solidRect = MaterialColors_getInstance().a5g_1;
        var tmp$ret$12;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp13_addTo = new SolidRect(new Size(tmp14_solidRect, tmp15_solidRect), tmp16_solidRect);
        var tmp$ret$11;
        // Inline function 'kotlin.apply' call
        var tmp12_apply = addTo(tmp13_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$10;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$10 = Unit_getInstance();
        tmp$ret$11 = tmp12_apply;
        tmp$ret$12 = tmp$ret$11;
        tmp$ret$13 = tmp$ret$12;

        ;
        var tmp$ret$17;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp19_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldWidth, borderSize));
        var tmp20_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldHeight, borderSize));
        var tmp21_solidRect = MaterialColors_getInstance().e5g_1;
        var tmp$ret$16;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp18_addTo = new SolidRect(Size_init_$Create$(tmp19_solidRect, tmp20_solidRect), tmp21_solidRect);
        var tmp$ret$15;
        // Inline function 'kotlin.apply' call
        var tmp17_apply = addTo(tmp18_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$14;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$14 = Unit_getInstance();
        tmp$ret$15 = tmp17_apply;
        tmp$ret$16 = tmp$ret$15;
        tmp$ret$17 = tmp$ret$16;

        centerOn(tmp$ret$17, this);
        ;
        break;
      case 5:
        addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2, new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().n4s_1, 0.6))), this);
        ;
        break;
      case 6:
        addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2, new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().m4s_1, 0.6))), this);
        ;
        break;
      case 7:
        var tmp$ret$21;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp24_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
        var tmp25_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
        var tmp26_solidRect = MaterialColors_getInstance().w5d_1;
        var tmp$ret$20;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp23_addTo = new SolidRect(new Size(tmp24_solidRect, tmp25_solidRect), tmp26_solidRect);
        var tmp$ret$19;
        // Inline function 'kotlin.apply' call
        var tmp22_apply = addTo(tmp23_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$18;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$18 = Unit_getInstance();
        tmp$ret$19 = tmp22_apply;
        tmp$ret$20 = tmp$ret$19;
        tmp$ret$21 = tmp$ret$20;

        ;
        var tmp$ret$25;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp29_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldWidth, borderSize));
        var tmp30_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb_0(worldHeight, borderSize));
        var tmp31_solidRect = MaterialColors_getInstance().a5e_1;
        var tmp$ret$24;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp28_addTo = new SolidRect(Size_init_$Create$(tmp29_solidRect, tmp30_solidRect), tmp31_solidRect);
        var tmp$ret$23;
        // Inline function 'kotlin.apply' call
        var tmp27_apply = addTo(tmp28_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$22;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$22 = Unit_getInstance();
        tmp$ret$23 = tmp27_apply;
        tmp$ret$24 = tmp$ret$23;
        tmp$ret$25 = tmp$ret$24;

        centerOn(tmp$ret$25, this);
        ;
        break;
      case 8:
        var speedAreaColor = RGBA__withAd_impl_cralao(SpeedAreaColorUtil_getInstance().ggw(ensureNotNull(this.fh5_1), VOID, VOID, 0.3, 0.9, 1.2, 2.0), 0.4);
        addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2, new RGBA(speedAreaColor)), this);
        ;
        break;
      case 9:
        var tmp$ret$27;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp33_addTo = new UIEightDirectionalSprite(GlobalResources_getInstance().igv());
        var tmp$ret$26;
        // Inline function 'kotlin.apply' call
        var tmp32_apply = addTo(tmp33_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.xenotactic.gamelogic.views.UIEntity.<anonymous>' call
        anchor(tmp32_apply, Companion_getInstance_10().x3q());
        tmp32_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
        tmp32_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
        tmp$ret$26 = tmp32_apply;
        tmp$ret$27 = tmp$ret$26;

        ;
        break;
      case 10:
        var tmp$ret$31;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp36_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
        var tmp37_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
        var tmp38_solidRect = MaterialColors_getInstance().a5g_1;
        var tmp$ret$30;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp35_addTo = new SolidRect(new Size(tmp36_solidRect, tmp37_solidRect), tmp38_solidRect);
        var tmp$ret$29;
        // Inline function 'kotlin.apply' call
        var tmp34_apply = addTo(tmp35_addTo, this);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$28;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$28 = Unit_getInstance();
        tmp$ret$29 = tmp34_apply;
        tmp$ret$30 = tmp$ret$29;
        tmp$ret$31 = tmp$ret$30;

        ;
        break;
    }
  }
  //region block: post-declaration
  protoOf($serializer).sfg = typeParametersSerializers;
  protoOf($serializer_0).sfg = typeParametersSerializers;
  protoOf($serializer_1).sfg = typeParametersSerializers;
  protoOf(GRectInt).ng8 = get_left;
  protoOf(GRectInt).og8 = get_right;
  protoOf(GRectInt).pg8 = get_bottom;
  protoOf(GRectInt).qg8 = get_top;
  protoOf(GRectInt).bg6 = get_blockGameUnitPoints;
  protoOf(GRectInt).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(GRectInt).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(GRectInt).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(GRectInt).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(GRectInt).vg8 = get_centerPoint;
  protoOf(GRectInt).wg8 = getRectangle;
  protoOf(GRectInt).xg8 = isFullyCoveredBy;
  protoOf(GRectInt).yg8 = isFullyCoveredBy_0;
  protoOf($serializer_2).sfg = typeParametersSerializers;
  protoOf($serializer_3).sfg = typeParametersSerializers;
  protoOf(RectangleEntity).ng8 = get_left;
  protoOf(RectangleEntity).og8 = get_right;
  protoOf(RectangleEntity).pg8 = get_bottom;
  protoOf(RectangleEntity).qg8 = get_top;
  protoOf(RectangleEntity).bg6 = get_blockGameUnitPoints;
  protoOf(RectangleEntity).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(RectangleEntity).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(RectangleEntity).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(RectangleEntity).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(RectangleEntity).vg8 = get_centerPoint;
  protoOf(RectangleEntity).wg8 = getRectangle;
  protoOf(RectangleEntity).xg8 = isFullyCoveredBy;
  protoOf(RectangleEntity).yg8 = isFullyCoveredBy_0;
  protoOf($serializer_4).sfg = typeParametersSerializers;
  protoOf($serializer_5).sfg = typeParametersSerializers;
  protoOf($serializer_6).sfg = typeParametersSerializers;
  protoOf($serializer_7).sfg = typeParametersSerializers;
  protoOf($serializer_8).sfg = typeParametersSerializers;
  protoOf($serializer_9).sfg = typeParametersSerializers;
  protoOf($serializer_10).sfg = typeParametersSerializers;
  protoOf($serializer_11).sfg = typeParametersSerializers;
  protoOf($serializer_12).sfg = typeParametersSerializers;
  protoOf($serializer_13).sfg = typeParametersSerializers;
  protoOf(MapEntity).ng8 = get_left;
  protoOf(MapEntity).og8 = get_right;
  protoOf(MapEntity).pg8 = get_bottom;
  protoOf(MapEntity).qg8 = get_top;
  protoOf(MapEntity).bg6 = get_blockGameUnitPoints;
  protoOf(MapEntity).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(MapEntity).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(MapEntity).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(MapEntity).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(MapEntity).vg8 = get_centerPoint;
  protoOf(MapEntity).wg8 = getRectangle;
  protoOf(MapEntity).xg8 = isFullyCoveredBy;
  protoOf(MapEntity).yg8 = isFullyCoveredBy_0;
  protoOf(Start).ng8 = get_left;
  protoOf(Start).og8 = get_right;
  protoOf(Start).pg8 = get_bottom;
  protoOf(Start).qg8 = get_top;
  protoOf(Start).bg6 = get_blockGameUnitPoints;
  protoOf(Start).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(Start).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(Start).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(Start).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(Start).vg8 = get_centerPoint;
  protoOf(Start).wg8 = getRectangle;
  protoOf(Start).xg8 = isFullyCoveredBy;
  protoOf(Start).yg8 = isFullyCoveredBy_0;
  protoOf(Finish).ng8 = get_left;
  protoOf(Finish).og8 = get_right;
  protoOf(Finish).pg8 = get_bottom;
  protoOf(Finish).qg8 = get_top;
  protoOf(Finish).bg6 = get_blockGameUnitPoints;
  protoOf(Finish).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(Finish).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(Finish).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(Finish).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(Finish).vg8 = get_centerPoint;
  protoOf(Finish).wg8 = getRectangle;
  protoOf(Finish).xg8 = isFullyCoveredBy;
  protoOf(Finish).yg8 = isFullyCoveredBy_0;
  protoOf(TeleportIn).ng8 = get_left;
  protoOf(TeleportIn).og8 = get_right;
  protoOf(TeleportIn).pg8 = get_bottom;
  protoOf(TeleportIn).qg8 = get_top;
  protoOf(TeleportIn).bg6 = get_blockGameUnitPoints;
  protoOf(TeleportIn).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(TeleportIn).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(TeleportIn).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(TeleportIn).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(TeleportIn).vg8 = get_centerPoint;
  protoOf(TeleportIn).wg8 = getRectangle;
  protoOf(TeleportIn).xg8 = isFullyCoveredBy;
  protoOf(TeleportIn).yg8 = isFullyCoveredBy_0;
  protoOf(TeleportOut).ng8 = get_left;
  protoOf(TeleportOut).og8 = get_right;
  protoOf(TeleportOut).pg8 = get_bottom;
  protoOf(TeleportOut).qg8 = get_top;
  protoOf(TeleportOut).bg6 = get_blockGameUnitPoints;
  protoOf(TeleportOut).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(TeleportOut).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(TeleportOut).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(TeleportOut).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(TeleportOut).vg8 = get_centerPoint;
  protoOf(TeleportOut).wg8 = getRectangle;
  protoOf(TeleportOut).xg8 = isFullyCoveredBy;
  protoOf(TeleportOut).yg8 = isFullyCoveredBy_0;
  protoOf(Rock).ng8 = get_left;
  protoOf(Rock).og8 = get_right;
  protoOf(Rock).pg8 = get_bottom;
  protoOf(Rock).qg8 = get_top;
  protoOf(Rock).bg6 = get_blockGameUnitPoints;
  protoOf(Rock).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(Rock).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(Rock).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(Rock).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(Rock).vg8 = get_centerPoint;
  protoOf(Rock).wg8 = getRectangle;
  protoOf(Rock).xg8 = isFullyCoveredBy;
  protoOf(Rock).yg8 = isFullyCoveredBy_0;
  protoOf(Checkpoint).ng8 = get_left;
  protoOf(Checkpoint).og8 = get_right;
  protoOf(Checkpoint).pg8 = get_bottom;
  protoOf(Checkpoint).qg8 = get_top;
  protoOf(Checkpoint).bg6 = get_blockGameUnitPoints;
  protoOf(Checkpoint).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(Checkpoint).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(Checkpoint).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(Checkpoint).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(Checkpoint).vg8 = get_centerPoint;
  protoOf(Checkpoint).wg8 = getRectangle;
  protoOf(Checkpoint).xg8 = isFullyCoveredBy;
  protoOf(Checkpoint).yg8 = isFullyCoveredBy_0;
  protoOf(Tower).ng8 = get_left;
  protoOf(Tower).og8 = get_right;
  protoOf(Tower).pg8 = get_bottom;
  protoOf(Tower).qg8 = get_top;
  protoOf(Tower).bg6 = get_blockGameUnitPoints;
  protoOf(Tower).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(Tower).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(Tower).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(Tower).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(Tower).vg8 = get_centerPoint;
  protoOf(Tower).wg8 = getRectangle;
  protoOf(Tower).xg8 = isFullyCoveredBy;
  protoOf(Tower).yg8 = isFullyCoveredBy_0;
  protoOf(SupplyDepot).ng8 = get_left;
  protoOf(SupplyDepot).og8 = get_right;
  protoOf(SupplyDepot).pg8 = get_bottom;
  protoOf(SupplyDepot).qg8 = get_top;
  protoOf(SupplyDepot).bg6 = get_blockGameUnitPoints;
  protoOf(SupplyDepot).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(SupplyDepot).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(SupplyDepot).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(SupplyDepot).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(SupplyDepot).vg8 = get_centerPoint;
  protoOf(SupplyDepot).wg8 = getRectangle;
  protoOf(SupplyDepot).xg8 = isFullyCoveredBy;
  protoOf(SupplyDepot).yg8 = isFullyCoveredBy_0;
  protoOf(SmallBlocker).ng8 = get_left;
  protoOf(SmallBlocker).og8 = get_right;
  protoOf(SmallBlocker).pg8 = get_bottom;
  protoOf(SmallBlocker).qg8 = get_top;
  protoOf(SmallBlocker).bg6 = get_blockGameUnitPoints;
  protoOf(SmallBlocker).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(SmallBlocker).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(SmallBlocker).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(SmallBlocker).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(SmallBlocker).vg8 = get_centerPoint;
  protoOf(SmallBlocker).wg8 = getRectangle;
  protoOf(SmallBlocker).xg8 = isFullyCoveredBy;
  protoOf(SmallBlocker).yg8 = isFullyCoveredBy_0;
  protoOf(SpeedArea).ng8 = get_left;
  protoOf(SpeedArea).og8 = get_right;
  protoOf(SpeedArea).pg8 = get_bottom;
  protoOf(SpeedArea).qg8 = get_top;
  protoOf(SpeedArea).bg6 = get_blockGameUnitPoints;
  protoOf(SpeedArea).rg8 = get_topLeftUnitSquareGameUnitPoint;
  protoOf(SpeedArea).sg8 = get_topRightUnitSquareGameUnitPoint;
  protoOf(SpeedArea).tg8 = get_bottomLeftUnitSquareGameUnitPoint;
  protoOf(SpeedArea).ug8 = get_bottomRightUnitSquareGameUnitPoint;
  protoOf(SpeedArea).vg8 = get_centerPoint;
  protoOf(SpeedArea).wg8 = getRectangle;
  protoOf(SpeedArea).xg8 = isFullyCoveredBy;
  protoOf(SpeedArea).yg8 = isFullyCoveredBy_0;
  protoOf($serializer_14).sfg = typeParametersSerializers;
  protoOf($serializer_15).sfg = typeParametersSerializers;
  protoOf(UIEightDirectionalSprite).z7c = dispatch$default;
  protoOf(UIEightDirectionalSprite).b7d = dispatch;
  protoOf(UIEightDirectionalSprite).a7d = onEvents;
  protoOf(UIEntity).z7c = dispatch$default;
  protoOf(UIEntity).b7d = dispatch;
  protoOf(UIEntity).a7d = onEvents;
  //endregion
  //region block: init
  GRID_SIZE = 25.0;
  BORDER_RATIO = 0.5;
  GRID_LINES_RATIO = 0.04;
  GRID_NUMBERS_RATIO = 0.5;
  PATH_LINES_RATIO = 0.25;
  ENTITY_LABEL_SIZE = 15.0;
  PATHING_POINT_PRECISION = 0.01;
  PATHING_RADIUS = 0.05;
  ALLOWABLE_DIRECTION_DIFF = 0.05;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Failure_0;
  _.$_$.b = Success_2;
  _.$_$.c = verify;
  _.$_$.d = AnimationComponent;
  _.$_$.e = BottomLeftPositionComponent;
  _.$_$.f = ComputedSpeedEffectComponent;
  _.$_$.g = DamageUpgradeComponent;
  _.$_$.h = EntityCheckpointComponent;
  _.$_$.i = EntityCostComponent;
  _.$_$.j = EntityFinishComponent;
  _.$_$.k = EntityRenderComponent;
  _.$_$.l = EntitySpeedAreaComponent;
  _.$_$.m = EntityStartComponent;
  _.$_$.n = EntityTeleportInComponent;
  _.$_$.o = EntityTeleportOutComponent;
  _.$_$.p = EntityTowerComponent;
  _.$_$.q = EntityTypeComponent;
  _.$_$.r = HealthComponent;
  _.$_$.s = MaxHealthComponent;
  _.$_$.t = MonsterComponent;
  _.$_$.u = MutableCenterPositionComponent;
  _.$_$.v = PathSequenceTraversalComponent;
  _.$_$.w = PreSelectionComponent;
  _.$_$.x = ProjectileComponent;
  _.$_$.y = RangeComponent;
  _.$_$.z = SelectedComponent;
  _.$_$.a1 = SizeComponent;
  _.$_$.b1 = SpeedUpgradeComponent;
  _.$_$.c1 = SupplyCostComponent;
  _.$_$.d1 = TargetingComponent;
  _.$_$.e1 = UIEightDirectionalSpriteComponent;
  _.$_$.f1 = UIEntityContainerComponent;
  _.$_$.g1 = UIEntityViewComponent;
  _.$_$.h1 = UIGunBarrelComponent;
  _.$_$.i1 = UIHealthBarComponent;
  _.$_$.j1 = UIMapEntityTextComponent;
  _.$_$.k1 = UISelectionComponent;
  _.$_$.l1 = VelocityComponent;
  _.$_$.m1 = AddedEntityEvent;
  _.$_$.n1 = AddedMonsterEntityEvent;
  _.$_$.o1 = EventBus;
  _.$_$.p1 = GoldStateUpdated;
  _.$_$.q1 = InformErrorMessageEvent;
  _.$_$.r1 = RemovedSupplyDepotEntityEvent;
  _.$_$.s1 = RemovedTowerEntityEvent;
  _.$_$.t1 = ResizeMapEvent;
  _.$_$.u1 = UpdatedPathLineEvent;
  _.$_$.v1 = GRectInt;
  _.$_$.w1 = GameMap;
  _.$_$.x1 = GameUnitTuple;
  _.$_$.y1 = GameWorld;
  _.$_$.z1 = IPoint;
  _.$_$.a2 = Checkpoint;
  _.$_$.b2 = Finish;
  _.$_$.c2 = Rock;
  _.$_$.d2 = SmallBlocker;
  _.$_$.e2 = SpeedArea;
  _.$_$.f2 = Start;
  _.$_$.g2 = TeleportIn;
  _.$_$.h2 = TeleportOut;
  _.$_$.i2 = Tower;
  _.$_$.j2 = Fixed;
  _.$_$.k2 = PlayerData;
  _.$_$.l2 = RectangleEntity;
  _.$_$.m2 = TeleportPair;
  _.$_$.n2 = Failure;
  _.$_$.o2 = Success;
  _.$_$.p2 = getAvailablePathingPointsFromBlockingEntities;
  _.$_$.q2 = GameMapDimensionsState;
  _.$_$.r2 = GameMapPathState;
  _.$_$.s2 = GameplayState;
  _.$_$.t2 = MutableCurrentlySelectedTowerState;
  _.$_$.u2 = MutableGoldState;
  _.$_$.v2 = get_BORDER_RATIO;
  _.$_$.w2 = get_ENTITY_LABEL_SIZE;
  _.$_$.x2 = Engine;
  _.$_$.y2 = get_GAME_HEIGHT;
  _.$_$.z2 = get_GAME_WIDTH;
  _.$_$.a3 = get_GRID_LINES_RATIO;
  _.$_$.b3 = get_GRID_NUMBERS_RATIO;
  _.$_$.c3 = get_GRID_SIZE;
  _.$_$.d3 = GameMapApi;
  _.$_$.e3 = GameSimulator;
  _.$_$.f3 = GameUnit;
  _.$_$.g3 = get_PATH_LINES_RATIO;
  _.$_$.h3 = Point;
  _.$_$.i3 = Errors;
  _.$_$.j3 = WorldUnit;
  _.$_$.k3 = calculateCostOfUpgrades;
  _.$_$.l3 = calculateUpgradeDecision;
  _.$_$.m3 = checkCanPlaceEntity;
  _.$_$.n3 = checkCanPlaceTowerEntity;
  _.$_$.o3 = existsBlocking;
  _.$_$.p3 = getCenterPoint;
  _.$_$.q3 = getGoldenJsonFiles;
  _.$_$.r3 = intersectRectangles;
  _.$_$.s3 = max;
  _.$_$.t3 = minus;
  _.$_$.u3 = min;
  _.$_$.v3 = rangeTo;
  _.$_$.w3 = rectCorner;
  _.$_$.x3 = rectangleIntersects;
  _.$_$.y3 = size_1;
  _.$_$.z3 = size_0;
  _.$_$.a4 = times;
  _.$_$.b4 = toGameMap;
  _.$_$.c4 = toGameUnit;
  _.$_$.d4 = toPoint;
  _.$_$.e4 = toRectangleEntity;
  _.$_$.f4 = toScale;
  _.$_$.g4 = toWorldCoordinates_0;
  _.$_$.h4 = toWorldCoordinates;
  _.$_$.i4 = toWorldDimensions_0;
  _.$_$.j4 = toWorldDimensions;
  _.$_$.k4 = toWorldUnit;
  _.$_$.l4 = until;
  _.$_$.m4 = xy_0;
  _.$_$.n4 = UIEightDirectionalSprite;
  _.$_$.o4 = UIEntity;
  _.$_$.p4 = MapEntityType_CHECKPOINT_getInstance;
  _.$_$.q4 = MapEntityType_FINISH_getInstance;
  _.$_$.r4 = MapEntityType_ROCK_getInstance;
  _.$_$.s4 = MapEntityType_SPEED_AREA_getInstance;
  _.$_$.t4 = MapEntityType_START_getInstance;
  _.$_$.u4 = MapEntityType_SUPPLY_DEPOT_getInstance;
  _.$_$.v4 = MapEntityType_TELEPORT_IN_getInstance;
  _.$_$.w4 = MapEntityType_TELEPORT_OUT_getInstance;
  _.$_$.x4 = MapEntityType_TOWER_getInstance;
  _.$_$.y4 = TowerType_BASIC_getInstance;
  _.$_$.z4 = TowerType_HIGH_DAMAGE_getInstance;
  _.$_$.a5 = EightDirection_DOWN_LEFT_getInstance;
  _.$_$.b5 = EightDirection_DOWN_RIGHT_getInstance;
  _.$_$.c5 = EightDirection_DOWN_getInstance;
  _.$_$.d5 = EightDirection_LEFT_getInstance;
  _.$_$.e5 = EightDirection_RIGHT_getInstance;
  _.$_$.f5 = EightDirection_UP_LEFT_getInstance;
  _.$_$.g5 = EightDirection_UP_RIGHT_getInstance;
  _.$_$.h5 = EightDirection_UP_getInstance;
  _.$_$.i5 = _GameUnit___init__impl__teirvv_0;
  _.$_$.j5 = GameUnit__div_impl_u8qjua_1;
  _.$_$.k5 = GameUnit__hashCode_impl_playmc;
  _.$_$.l5 = GameUnit__minus_impl_uz6kmr;
  _.$_$.m5 = GameUnit__plus_impl_rh1b6r;
  _.$_$.n5 = GameUnit__toDouble_impl_5m2yph;
  _.$_$.o5 = GameUnit__toFloat_impl_sc3672;
  _.$_$.p5 = GameUnit__toInt_impl_9qttgv;
  _.$_$.q5 = GameUnit__toString_impl_3u51th;
  _.$_$.r5 = GameUnit__toWorldUnit_impl_m803zs;
  _.$_$.s5 = GameUnit__tup_impl_pf7bck;
  _.$_$.t5 = _GameUnit___get_value__impl__9ocgox;
  _.$_$.u5 = _WorldUnit___init__impl__qt45s3;
  _.$_$.v5 = WorldUnit__div_impl_b90n6a;
  _.$_$.w5 = WorldUnit__minus_impl_yejnmb_1;
  _.$_$.x5 = WorldUnit__minus_impl_yejnmb;
  _.$_$.y5 = WorldUnit__plus_impl_n1o1x9;
  _.$_$.z5 = WorldUnit__toDouble_impl_9mljzf;
  _.$_$.a6 = WorldUnit__toFloat_impl_2n0xxa;
  _.$_$.b6 = _WorldUnit___get_value__impl__xwm9rd;
  _.$_$.c6 = Success_getInstance;
  _.$_$.d6 = EntityBlockingComponent_getInstance;
  _.$_$.e6 = EntityFinishComponent_getInstance;
  _.$_$.f6 = EntityRockComponent_getInstance;
  _.$_$.g6 = EntityStartComponent_getInstance;
  _.$_$.h6 = EntitySupplyDepotComponent_getInstance;
  _.$_$.i6 = EntityTowerComponent_getInstance;
  _.$_$.j6 = PreSelectionComponent_getInstance;
  _.$_$.k6 = SelectedComponent_getInstance;
  _.$_$.l6 = Companion_getInstance_18;
  _.$_$.m6 = Companion_getInstance_19;
  _.$_$.n6 = Companion_getInstance_20;
  _.$_$.o6 = Companion_getInstance_31;
  _.$_$.p6 = Companion_getInstance_32;
  _.$_$.q6 = GlobalResources_getInstance;
  _.$_$.r6 = MapToId_getInstance;
  _.$_$.s6 = RockCounterUtil_getInstance;
  _.$_$.t6 = SpeedAreaColorUtil_getInstance;
  _.$_$.u6 = Ok_getInstance;
  _.$_$.v6 = Companion_getInstance_42;
  _.$_$.w6 = AStarSearcher_getInstance;
  _.$_$.x6 = PathFinder_getInstance;
  _.$_$.y6 = MaxPath_getInstance;
  _.$_$.z6 = Unsuccessful_getInstance;
  _.$_$.a7 = SolverParams;
  _.$_$.b7 = Success_0;
  _.$_$.c7 = SolverSettings;
  _.$_$.d7 = StandardSolver3;
  //endregion
  return _;
}));
