(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-XenoECS'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'XenoTacticV2-XenoECS'.");
    }
    root['XenoTacticV2-XenoECS'] = factory(typeof this['XenoTacticV2-XenoECS'] === 'undefined' ? {} : this['XenoTacticV2-XenoECS'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.nd;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var isObject = kotlin_kotlin.$_$.ad;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var emptyList = kotlin_kotlin.$_$.e7;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var emptySet = kotlin_kotlin.$_$.g7;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var toSet = kotlin_kotlin.$_$.ka;
  var objectMeta = kotlin_kotlin.$_$.md;
  var first = kotlin_kotlin.$_$.n7;
  var asSequence = kotlin_kotlin.$_$.a6;
  var toList = kotlin_kotlin.$_$.da;
  var toSet_0 = kotlin_kotlin.$_$.la;
  var hashCode = kotlin_kotlin.$_$.lc;
  var equals = kotlin_kotlin.$_$.cc;
  var Collection = kotlin_kotlin.$_$.d5;
  var isInterface = kotlin_kotlin.$_$.xc;
  var filter = kotlin_kotlin.$_$.we;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var Comparator = kotlin_kotlin.$_$.oh;
  var toString = kotlin_kotlin.$_$.sd;
  var compareValues = kotlin_kotlin.$_$.ra;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var to = kotlin_kotlin.$_$.lj;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var sortedWith = kotlin_kotlin.$_$.q9;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.u1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.w1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(AbstractEntity, 'AbstractEntity', classMeta);
  setMetadataFor(ComponentEntityContainer, 'ComponentEntityContainer', classMeta);
  function onAdd(entityId, new_0) {
    return Unit_getInstance();
  }
  function onReplace(entityId, old, new_0) {
    return Unit_getInstance();
  }
  function onAddOrReplace(entityId, old, new_0) {
    return Unit_getInstance();
  }
  function onRemove(entityId, component) {
    return Unit_getInstance();
  }
  function onExisting(entityId, component) {
    return Unit_getInstance();
  }
  setMetadataFor(ComponentListener, 'ComponentListener', interfaceMeta);
  setMetadataFor(ComponentService, 'ComponentService', classMeta);
  function onAdd_0(newComponent) {
    return Unit_getInstance();
  }
  function onReplace_0(oldComponent, newComponent) {
    return Unit_getInstance();
  }
  function onAddOrReplace_0(oldComponent, newComponent) {
    return Unit_getInstance();
  }
  setMetadataFor(EntityComponentListener, 'EntityComponentListener', interfaceMeta);
  setMetadataFor(EntityId, 'EntityId', classMeta);
  setMetadataFor(EntityIdService, 'EntityIdService', classMeta);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Family, 'Family', classMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(FamilyConfiguration, 'FamilyConfiguration', classMeta);
  setMetadataFor(FamilyService, 'FamilyService', classMeta);
  setMetadataFor(FamilyNode, 'FamilyNode', classMeta);
  setMetadataFor(StagingEntity, 'StagingEntity', classMeta, AbstractEntity);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(StatefulEntity, 'StatefulEntity', classMeta, AbstractEntity);
  setMetadataFor(System, 'System', classMeta);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(TypedInjections, 'TypedInjections', classMeta);
  setMetadataFor(ModifyEntityApi, 'ModifyEntityApi', classMeta);
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(World, 'World', classMeta);
  setMetadataFor(ECSException, 'ECSException', classMeta, RuntimeException);
  setMetadataFor(SingletonInjectionAlreadyExistsException, 'SingletonInjectionAlreadyExistsException', classMeta, ECSException);
  setMetadataFor(SingletonInjectionDoesNotExistException, 'SingletonInjectionDoesNotExistException', classMeta, ECSException);
  setMetadataFor(ECSComponentAlreadyExistsException, 'ECSComponentAlreadyExistsException', classMeta, ECSException);
  setMetadataFor(ECSEntityDoesNotExist, 'ECSEntityDoesNotExist', classMeta, ECSException);
  setMetadataFor(ECSComponentNotFoundException, 'ECSComponentNotFoundException', classMeta, ECSException);
  //endregion
  function AbstractEntity() {
  }
  protoOf(AbstractEntity).sfz = function () {
    return this.rfz().j3();
  };
  protoOf(AbstractEntity).tfz = function (klass) {
    var tmp = ensureNotNull(this.rfz().h3(klass));
    return isObject(tmp) ? tmp : THROW_CCE();
  };
  function ComponentEntityContainer$getComponent$lambda(this$0, $entityId) {
    return function () {
      var statefulEntity = this$0.vfz_1.hg0($entityId);
      return 'Component of class (' + this$0.ufz_1 + '), not found for entity: ' + _EntityId___get_id__impl__v6colh($entityId) + '.\nCurrent data: ' + statefulEntity;
    };
  }
  function ComponentEntityContainer(klass, world) {
    this.ufz_1 = klass;
    this.vfz_1 = world;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.wfz_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$1 = LinkedHashMap_init_$Create$();
    tmp_0.xfz_1 = tmp$ret$1;
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$2 = ArrayList_init_$Create$();
    tmp_1.yfz_1 = tmp$ret$2;
  }
  protoOf(ComponentEntityContainer).ig0 = function (entityId, component) {
    var tmp = new EntityId(entityId);
    var previousComponent = this.wfz_1.e5(tmp, isObject(component) ? component : THROW_CCE());
    this.vfz_1.cg0_1.mg0(entityId);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_getOrElse = this.xfz_1;
    var tmp0_elvis_lhs = tmp0_getOrElse.h3(new EntityId(entityId));
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
      tmp$ret$0 = emptyList();
      tmp_0 = tmp$ret$0;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_0;
    var entityListeners = tmp$ret$1;
    if (previousComponent == null) {
      // Inline function 'kotlin.collections.forEach' call
      var tmp1_forEach = this.yfz_1;
      var tmp0_iterator = tmp1_forEach.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
        element.pg0(entityId, component);
      }
      // Inline function 'kotlin.collections.forEach' call
      var tmp0_iterator_0 = entityListeners.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k();
        // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
        element_0.qg0(component);
      }
    } else {
      // Inline function 'kotlin.collections.forEach' call
      var tmp2_forEach = this.yfz_1;
      var tmp0_iterator_1 = tmp2_forEach.h();
      while (tmp0_iterator_1.j()) {
        var element_1 = tmp0_iterator_1.k();
        // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
        element_1.ng0(entityId, previousComponent, component);
      }
      // Inline function 'kotlin.collections.forEach' call
      var tmp0_iterator_2 = entityListeners.h();
      while (tmp0_iterator_2.j()) {
        var element_2 = tmp0_iterator_2.k();
        // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
        element_2.og0(previousComponent, component);
      }
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp3_forEach = this.yfz_1;
    var tmp0_iterator_3 = tmp3_forEach.h();
    while (tmp0_iterator_3.j()) {
      var element_3 = tmp0_iterator_3.k();
      // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
      element_3.rg0(entityId, previousComponent, component);
    }
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator_4 = entityListeners.h();
    while (tmp0_iterator_4.j()) {
      var element_4 = tmp0_iterator_4.k();
      // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.addOrReplaceComponentInternal.<anonymous>' call
      element_4.sg0(previousComponent, component);
    }
    return previousComponent;
  };
  protoOf(ComponentEntityContainer).tg0 = function (entityId) {
    var tmp0_elvis_lhs = this.ug0(entityId);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      throw new ECSComponentNotFoundException(ComponentEntityContainer$getComponent$lambda(this, entityId));
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(ComponentEntityContainer).ug0 = function (entityId) {
    return this.wfz_1.h3(new EntityId(entityId));
  };
  protoOf(ComponentEntityContainer).vg0 = function (entityId) {
    var tmp0_elvis_lhs = this.wfz_1.lg(new EntityId(entityId));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var removedComponent = tmp;
    this.vfz_1.cg0_1.mg0(entityId);
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.yfz_1;
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.ecs.ComponentEntityContainer.removeComponent.<anonymous>' call
      element.wg0(entityId, removedComponent);
    }
    return removedComponent;
  };
  protoOf(ComponentEntityContainer).xg0 = function (entityId) {
    return this.wfz_1.b3(new EntityId(entityId));
  };
  protoOf(ComponentEntityContainer).yg0 = function (listener) {
    this.yfz_1.b(listener);
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this.wfz_1;
    tmp$ret$0 = tmp0_iterator.d1().h();
    var tmp0_iterator_0 = tmp$ret$0;
    while (tmp0_iterator_0.j()) {
      var tmp1_loop_parameter = tmp0_iterator_0.k();
      var tmp$ret$1;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$1 = tmp1_loop_parameter.e1().zg0_1;
      var entity = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$2 = tmp1_loop_parameter.f1();
      var component = tmp$ret$2;
      listener.ag1(entity, component);
    }
  };
  function ComponentListener() {
  }
  function getComponentMap($this, entityId) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_getOrElse = $this.dg1_1;
    var tmp0_elvis_lhs = tmp0_getOrElse.h3(new EntityId(entityId));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.ComponentService.getComponentMap.<anonymous>' call
      tmp$ret$0 = emptySet();
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp;
    var activeComponentKlassSet = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$2 = LinkedHashMap_init_$Create$();
    var componentMap = tmp$ret$2;
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_iterator = activeComponentKlassSet.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.ecs.ComponentService.getComponentMap.<anonymous>' call
      var component = ensureNotNull($this.cg1_1.h3(element)).tg0(entityId);
      // Inline function 'kotlin.collections.set' call
      componentMap.e5(element, component);
    }
    return componentMap;
  }
  function addOrReplaceComponentInternal($this, container, entityId, component) {
    container.ig0(entityId, component);
    var tmp$ret$2;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = $this.dg1_1;
    var value = tmp0_getOrPut.h3(new EntityId(entityId));
    var tmp;
    if (value == null) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.ecs.ComponentService.addOrReplaceComponentInternal.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableSetOf' call
      tmp$ret$0 = LinkedHashSet_init_$Create$();
      tmp$ret$1 = tmp$ret$0;
      var answer = tmp$ret$1;
      tmp0_getOrPut.e5(new EntityId(entityId), answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$2 = tmp;
    tmp$ret$2.b(getKClassFromExpression(component));
  }
  function ComponentService$addComponentOrThrow$lambda($component, $entityId) {
    return function () {
      return 'Class `' + getKClassFromExpression($component) + '` of component `' + $component + '` already exists for entity: ' + new EntityId($entityId);
    };
  }
  function ComponentService(world) {
    this.bg1_1 = world;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.cg1_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$1 = LinkedHashMap_init_$Create$();
    tmp_0.dg1_1 = tmp$ret$1;
  }
  protoOf(ComponentService).eg1 = function (entityId) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_getOrElse = this.dg1_1;
    var tmp0_elvis_lhs = tmp0_getOrElse.h3(new EntityId(entityId));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.ComponentService.getAllComponentsForEntity.<anonymous>' call
      tmp$ret$0 = emptySet();
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp;
    var activeComponentKlassSet = tmp$ret$1;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp1_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(activeComponentKlassSet, 10));
    var tmp0_iterator = activeComponentKlassSet.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'com.xenotactic.ecs.ComponentService.getAllComponentsForEntity.<anonymous>' call
      tmp$ret$2 = ensureNotNull(this.cg1_1.h3(item)).tg0(entityId);
      tmp1_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp1_mapTo;
    tmp$ret$4 = tmp$ret$3;
    return tmp$ret$4;
  };
  protoOf(ComponentService).hg0 = function (entityId) {
    var componentMap = getComponentMap(this, entityId);
    return Companion_getInstance_1().fg1(entityId, componentMap);
  };
  protoOf(ComponentService).gg1 = function (entityId) {
    var componentMap = getComponentMap(this, entityId);
    return new StagingEntity(componentMap);
  };
  protoOf(ComponentService).hg1 = function (entityId) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrElse' call
    var tmp0_getOrElse = this.dg1_1;
    var tmp0_elvis_lhs = tmp0_getOrElse.h3(new EntityId(entityId));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.ComponentService.removeEntity.<anonymous>' call
      tmp$ret$0 = emptySet();
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp;
    var activeComponentKlasses = toSet(tmp$ret$1);
    var tmp0_iterator = activeComponentKlasses.h();
    while (tmp0_iterator.j()) {
      var activeComponentKlass = tmp0_iterator.k();
      this.ig1(entityId, activeComponentKlass);
    }
  };
  protoOf(ComponentService).jg1 = function (kClass, entityId) {
    var tmp0_elvis_lhs = this.cg1_1.h3(kClass);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var arr = tmp;
    return arr.xg0(entityId);
  };
  protoOf(ComponentService).kg1 = function (entityId, component) {
    var container = this.lg1(getKClassFromExpression(component));
    addOrReplaceComponentInternal(this, container, entityId, component);
  };
  protoOf(ComponentService).mg1 = function (entityId, component) {
    var container = this.lg1(getKClassFromExpression(component));
    if (!container.xg0(entityId)) {
      addOrReplaceComponentInternal(this, container, entityId, component);
    }
  };
  protoOf(ComponentService).ng1 = function (entityId, component) {
    var container = this.lg1(getKClassFromExpression(component));
    if (container.xg0(entityId)) {
      throw new ECSComponentAlreadyExistsException(ComponentService$addComponentOrThrow$lambda(component, entityId));
    } else {
      addOrReplaceComponentInternal(this, container, entityId, component);
    }
  };
  protoOf(ComponentService).lg1 = function (klass) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.cg1_1;
    var value = tmp0_getOrPut.h3(klass);
    var tmp;
    if (value == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.ComponentService.getOrPutContainer.<anonymous>' call
      tmp$ret$0 = new ComponentEntityContainer(klass, this.bg1_1);
      var answer = tmp$ret$0;
      tmp0_getOrPut.e5(klass, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  };
  protoOf(ComponentService).ig1 = function (entityId, componentKlass) {
    var tmp0_elvis_lhs = this.cg1_1.h3(componentKlass);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var container = tmp;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.dg1_1;
    var value = tmp0_getOrPut.h3(new EntityId(entityId));
    var tmp_0;
    if (value == null) {
      var tmp$ret$1;
      // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableSetOf' call
      tmp$ret$0 = LinkedHashSet_init_$Create$();
      tmp$ret$1 = tmp$ret$0;
      var answer = tmp$ret$1;
      tmp0_getOrPut.e5(new EntityId(entityId), answer);
      tmp_0 = answer;
    } else {
      tmp_0 = value;
    }
    tmp$ret$2 = tmp_0;
    tmp$ret$2.b4(componentKlass);
    var tmp_1 = container.vg0(entityId);
    return (tmp_1 == null ? true : isObject(tmp_1)) ? tmp_1 : THROW_CCE();
  };
  function EntityComponentListener() {
  }
  function _EntityId___init__impl__cq1mhp(id) {
    return id;
  }
  function _EntityId___get_id__impl__v6colh($this) {
    return $this;
  }
  function EntityId__toString_impl_njecar($this) {
    return 'EntityId(id=' + $this + ')';
  }
  function EntityId__hashCode_impl_1s8fhw($this) {
    return $this;
  }
  function EntityId__equals_impl_5w8h0w($this, other) {
    if (!(other instanceof EntityId))
      return false;
    var tmp0_other_with_cast = other instanceof EntityId ? other.zg0_1 : THROW_CCE();
    if (!($this === tmp0_other_with_cast))
      return false;
    return true;
  }
  function EntityId(id) {
    this.zg0_1 = id;
  }
  protoOf(EntityId).toString = function () {
    return EntityId__toString_impl_njecar(this.zg0_1);
  };
  protoOf(EntityId).hashCode = function () {
    return EntityId__hashCode_impl_1s8fhw(this.zg0_1);
  };
  protoOf(EntityId).equals = function (other) {
    return EntityId__equals_impl_5w8h0w(this.zg0_1, other);
  };
  function EntityIdService() {
    this.og1_1 = 0;
  }
  protoOf(EntityIdService).pg1 = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.og1_1;
    tmp0_this.og1_1 = tmp1 + 1 | 0;
    return tmp1;
  };
  function Companion() {
    Companion_instance = this;
    this.qg1_1 = new Family(ArrayList_init_$Create$());
  }
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function Family(entities) {
    Companion_getInstance();
    this.rg1_1 = entities;
  }
  protoOf(Family).l = function () {
    return this.rg1_1.l();
  };
  protoOf(Family).sg1 = function () {
    return first(this.rg1_1).zg0_1;
  };
  protoOf(Family).tg1 = function () {
    return asSequence(this.rg1_1);
  };
  protoOf(Family).ug1 = function () {
    return this.rg1_1;
  };
  protoOf(Family).vg1 = function () {
    return toList(this.rg1_1);
  };
  protoOf(Family).wg1 = function (entityId) {
    this.rg1_1.b(new EntityId(entityId));
  };
  protoOf(Family).hg1 = function (entityId) {
    this.rg1_1.b4(new EntityId(entityId));
  };
  protoOf(Family).xg1 = function (entityId) {
    return this.rg1_1.q(new EntityId(entityId));
  };
  protoOf(Family).toString = function () {
    return 'Family(entities=' + this.rg1_1 + ')';
  };
  protoOf(Family).hashCode = function () {
    return this.rg1_1.hashCode();
  };
  protoOf(Family).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Family))
      return false;
    var tmp0_other_with_cast = other instanceof Family ? other : THROW_CCE();
    if (!this.rg1_1.equals(tmp0_other_with_cast.rg1_1))
      return false;
    return true;
  };
  function Companion_0() {
    Companion_instance_0 = this;
    this.yg1_1 = new FamilyConfiguration();
  }
  protoOf(Companion_0).zg1 = function (components) {
    return new FamilyConfiguration(toSet_0(components));
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function FamilyConfiguration(allOfComponents, anyOfComponents, noneOfComponents) {
    Companion_getInstance_0();
    allOfComponents = allOfComponents === VOID ? emptySet() : allOfComponents;
    anyOfComponents = anyOfComponents === VOID ? emptySet() : anyOfComponents;
    noneOfComponents = noneOfComponents === VOID ? emptySet() : noneOfComponents;
    this.ag2_1 = allOfComponents;
    this.bg2_1 = anyOfComponents;
    this.cg2_1 = noneOfComponents;
  }
  protoOf(FamilyConfiguration).toString = function () {
    return getString(this);
  };
  protoOf(FamilyConfiguration).hashCode = function () {
    var result = hashCode(this.ag2_1);
    result = imul(result, 31) + hashCode(this.bg2_1) | 0;
    result = imul(result, 31) + hashCode(this.cg2_1) | 0;
    return result;
  };
  protoOf(FamilyConfiguration).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FamilyConfiguration))
      return false;
    var tmp0_other_with_cast = other instanceof FamilyConfiguration ? other : THROW_CCE();
    if (!equals(this.ag2_1, tmp0_other_with_cast.ag2_1))
      return false;
    if (!equals(this.bg2_1, tmp0_other_with_cast.bg2_1))
      return false;
    if (!equals(this.cg2_1, tmp0_other_with_cast.cg2_1))
      return false;
    return true;
  };
  function FamilyService$getOrCreateFamily$lambda(this$0, $familyConfiguration) {
    return function (it) {
      return this$0.dg2(it.zg0_1, $familyConfiguration);
    };
  }
  function FamilyService(world, componentService) {
    this.jg0_1 = world;
    this.kg0_1 = componentService;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.lg0_1 = tmp$ret$0;
  }
  protoOf(FamilyService).mg0 = function (entityId) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    var tmp0_iterator = this.lg0_1;
    tmp$ret$0 = tmp0_iterator.d1().h();
    var tmp0_iterator_0 = tmp$ret$0;
    while (tmp0_iterator_0.j()) {
      var tmp1_loop_parameter = tmp0_iterator_0.k();
      var tmp$ret$1;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$1 = tmp1_loop_parameter.e1();
      var config = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$2 = tmp1_loop_parameter.f1();
      var node = tmp$ret$2;
      if (this.dg2(entityId, config)) {
        var familyAlreadyContainsEntity = node.eg2_1.xg1(entityId);
        if (!familyAlreadyContainsEntity) {
          node.eg2_1.wg1(entityId);
          var tmp2_iterator = node.fg2_1.h();
          while (tmp2_iterator.j()) {
            var listener = tmp2_iterator.k();
            listener.hg2(entityId);
          }
        }
      } else {
        if (node.eg2_1.xg1(entityId)) {
          node.eg2_1.hg1(entityId);
          var tmp3_iterator = node.fg2_1.h();
          while (tmp3_iterator.j()) {
            var listener_0 = tmp3_iterator.k();
            listener_0.gg2(entityId);
          }
        }
      }
    }
  };
  protoOf(FamilyService).dg2 = function (entityId, familyConfiguration) {
    var tmp;
    var tmp_0;
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp0_all = familyConfiguration.ag2_1;
      var tmp_1;
      if (isInterface(tmp0_all, Collection)) {
        tmp_1 = tmp0_all.p();
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_all.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.ecs.FamilyService.matchesFamilyConfiguration.<anonymous>' call
        tmp$ret$1 = this.kg0_1.jg1(element, entityId);
        if (!tmp$ret$1) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    if (tmp$ret$0) {
      var tmp_2;
      if (familyConfiguration.bg2_1.p()) {
        tmp_2 = true;
      } else {
        var tmp$ret$2;
        $l$block_2: {
          // Inline function 'kotlin.collections.any' call
          var tmp1_any = familyConfiguration.bg2_1;
          var tmp_3;
          if (isInterface(tmp1_any, Collection)) {
            tmp_3 = tmp1_any.p();
          } else {
            tmp_3 = false;
          }
          if (tmp_3) {
            tmp$ret$2 = false;
            break $l$block_2;
          }
          var tmp0_iterator_0 = tmp1_any.h();
          while (tmp0_iterator_0.j()) {
            var element_0 = tmp0_iterator_0.k();
            var tmp$ret$3;
            // Inline function 'com.xenotactic.ecs.FamilyService.matchesFamilyConfiguration.<anonymous>' call
            tmp$ret$3 = this.kg0_1.jg1(element_0, entityId);
            if (tmp$ret$3) {
              tmp$ret$2 = true;
              break $l$block_2;
            }
          }
          tmp$ret$2 = false;
        }
        tmp_2 = tmp$ret$2;
      }
      tmp_0 = tmp_2;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$4;
      $l$block_4: {
        // Inline function 'kotlin.collections.none' call
        var tmp2_none = familyConfiguration.cg2_1;
        var tmp_4;
        if (isInterface(tmp2_none, Collection)) {
          tmp_4 = tmp2_none.p();
        } else {
          tmp_4 = false;
        }
        if (tmp_4) {
          tmp$ret$4 = true;
          break $l$block_4;
        }
        var tmp0_iterator_1 = tmp2_none.h();
        while (tmp0_iterator_1.j()) {
          var element_1 = tmp0_iterator_1.k();
          var tmp$ret$5;
          // Inline function 'com.xenotactic.ecs.FamilyService.matchesFamilyConfiguration.<anonymous>' call
          tmp$ret$5 = this.kg0_1.jg1(element_1, entityId);
          if (tmp$ret$5) {
            tmp$ret$4 = false;
            break $l$block_4;
          }
        }
        tmp$ret$4 = true;
      }
      tmp = tmp$ret$4;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(FamilyService).ig2 = function (listener) {
    var node = this.kg2(listener.jg2());
    node.fg2_1.b(listener);
    var tmp0_iterator = node.eg2_1.tg1().h();
    while (tmp0_iterator.j()) {
      var entity = tmp0_iterator.k().zg0_1;
      listener.lg2(entity);
    }
  };
  protoOf(FamilyService).kg2 = function (familyConfiguration) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp0_getOrPut = this.lg0_1;
    var value = tmp0_getOrPut.h3(familyConfiguration);
    var tmp;
    if (value == null) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.FamilyService.getOrCreateFamily.<anonymous>' call
      var newNode = new FamilyNode(new Family(ArrayList_init_$Create$()));
      // Inline function 'kotlin.sequences.forEach' call
      var tmp_0 = asSequence(this.jg0_1.dg0_1);
      var tmp0_forEach = filter(tmp_0, FamilyService$getOrCreateFamily$lambda(this, familyConfiguration));
      var tmp0_iterator = tmp0_forEach.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k().zg0_1;
        // Inline function 'com.xenotactic.ecs.FamilyService.getOrCreateFamily.<anonymous>.<anonymous>' call
        newNode.eg2_1.wg1(element);
      }
      tmp$ret$0 = newNode;
      var answer = tmp$ret$0;
      tmp0_getOrPut.e5(familyConfiguration, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  };
  function FamilyNode(family, listeners) {
    var tmp;
    if (listeners === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = listeners;
    }
    listeners = tmp;
    this.eg2_1 = family;
    this.fg2_1 = listeners;
  }
  protoOf(FamilyNode).toString = function () {
    return 'FamilyNode(family=' + this.eg2_1 + ', listeners=' + this.fg2_1 + ')';
  };
  protoOf(FamilyNode).hashCode = function () {
    var result = this.eg2_1.hashCode();
    result = imul(result, 31) + hashCode(this.fg2_1) | 0;
    return result;
  };
  protoOf(FamilyNode).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FamilyNode))
      return false;
    var tmp0_other_with_cast = other instanceof FamilyNode ? other : THROW_CCE();
    if (!this.eg2_1.equals(tmp0_other_with_cast.eg2_1))
      return false;
    if (!equals(this.fg2_1, tmp0_other_with_cast.fg2_1))
      return false;
    return true;
  };
  function StagingEntity_init_$Init$(block, $this) {
    StagingEntity.call($this);
    block($this);
    return $this;
  }
  function StagingEntity_init_$Create$(block) {
    return StagingEntity_init_$Init$(block, objectCreate(protoOf(StagingEntity)));
  }
  function StagingEntity$addComponentOrThrow$lambda($component) {
    return function () {
      return 'Class `' + getKClassFromExpression($component) + '` of component `' + $component + '` already exists for this staging entity.';
    };
  }
  function StagingEntity(componentMap) {
    var tmp;
    if (componentMap === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableMapOf' call
      tmp$ret$0 = LinkedHashMap_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = componentMap;
    }
    componentMap = tmp;
    AbstractEntity.call(this);
    this.mg2_1 = componentMap;
  }
  protoOf(StagingEntity).rfz = function () {
    return this.mg2_1;
  };
  protoOf(StagingEntity).ng2 = function (component) {
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.mg2_1;
    var tmp1_set = getKClassFromExpression(component);
    tmp0_set.e5(tmp1_set, component);
  };
  protoOf(StagingEntity).og2 = function (component) {
    if (this.mg2_1.b3(getKClassFromExpression(component))) {
      throw new ECSComponentAlreadyExistsException(StagingEntity$addComponentOrThrow$lambda(component));
    } else {
      this.ng2(component);
    }
  };
  protoOf(StagingEntity).toString = function () {
    return 'StagingEntity(componentMap=' + this.mg2_1 + ')';
  };
  protoOf(StagingEntity).hashCode = function () {
    return hashCode(this.mg2_1);
  };
  protoOf(StagingEntity).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StagingEntity))
      return false;
    var tmp0_other_with_cast = other instanceof StagingEntity ? other : THROW_CCE();
    if (!equals(this.mg2_1, tmp0_other_with_cast.mg2_1))
      return false;
    return true;
  };
  function Companion_1() {
    Companion_instance_1 = this;
  }
  protoOf(Companion_1).fg1 = function (entityId, componentMap) {
    return new StatefulEntity(entityId, componentMap);
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function StatefulEntity(entityId, componentMap) {
    Companion_getInstance_1();
    AbstractEntity.call(this);
    this.pg2_1 = entityId;
    this.qg2_1 = componentMap;
  }
  protoOf(StatefulEntity).rfz = function () {
    return this.qg2_1;
  };
  protoOf(StatefulEntity).toString = function () {
    return 'StatefulEntity(entityId=' + new EntityId(this.pg2_1) + ', componentMap=' + this.qg2_1 + ')';
  };
  protoOf(StatefulEntity).hashCode = function () {
    var result = EntityId__hashCode_impl_1s8fhw(this.pg2_1);
    result = imul(result, 31) + hashCode(this.qg2_1) | 0;
    return result;
  };
  protoOf(StatefulEntity).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StatefulEntity))
      return false;
    var tmp0_other_with_cast = other instanceof StatefulEntity ? other : THROW_CCE();
    if (!(this.pg2_1 === tmp0_other_with_cast.pg2_1))
      return false;
    if (!equals(this.qg2_1, tmp0_other_with_cast.qg2_1))
      return false;
    return true;
  };
  function System() {
    this.rg2_1 = true;
    this.sg2_1 = Companion_getInstance().qg1_1;
  }
  protoOf(System).tg2 = function (newFamily) {
    this.sg2_1 = newFamily;
  };
  protoOf(System).ug2 = function () {
    return this.sg2_1;
  };
  function Companion_2() {
    Companion_instance_2 = this;
  }
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function TypedInjections$setSingletonOrThrow$lambda($klass) {
    return function () {
      return 'Singleton injection already exists: ' + $klass;
    };
  }
  function TypedInjections() {
    Companion_getInstance_2();
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.wg2_1 = tmp$ret$0;
  }
  protoOf(TypedInjections).xg2 = function (obj) {
    var klass = getKClassFromExpression(obj);
    if (this.wg2_1.b3(klass)) {
      throw new SingletonInjectionAlreadyExistsException(TypedInjections$setSingletonOrThrow$lambda(klass));
    }
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.wg2_1;
    tmp0_set.e5(klass, obj);
  };
  function ModifyEntityApi($outer, entityId) {
    this.zg2_1 = $outer;
    this.yg2_1 = entityId;
  }
  protoOf(ModifyEntityApi).ag3 = function (stagingEntity) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.onEach' call
    var tmp0_onEach = stagingEntity.sfz();
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.collections.onEach.<anonymous>' call
    var tmp0_iterator = tmp0_onEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.ecs.ModifyEntityApi.addComponentsFromStagingEntity.<anonymous>' call
      this.zg2_1.bg0_1.ng1(this.yg2_1, element);
    }
    tmp$ret$0 = tmp0_onEach;
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(ModifyEntityApi).bg3 = function (component) {
    this.zg2_1.bg0_1.kg1(this.yg2_1, component);
  };
  protoOf(ModifyEntityApi).cg3 = function (component) {
    this.zg2_1.bg0_1.mg1(this.yg2_1, component);
  };
  protoOf(ModifyEntityApi).og2 = function (component) {
    this.zg2_1.bg0_1.ng1(this.yg2_1, component);
  };
  protoOf(ModifyEntityApi).dg3 = function () {
    removeEntity(this.zg2_1, this.yg2_1);
  };
  function removeEntity($this, entityId) {
    $this.bg0_1.hg1(entityId);
    $this.dg0_1.b4(new EntityId(entityId));
  }
  function sam$kotlin_Comparator$0(function_0) {
    this.eg3_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).l8 = function (a, b) {
    return this.eg3_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function World$get$lambda($entityId) {
    return function () {
      return 'Entity ' + new EntityId($entityId) + ' does not exist.';
    };
  }
  function World$getOrNull$lambda($entityId) {
    return function () {
      return 'Entity ' + new EntityId($entityId) + ' does not exist.';
    };
  }
  function World$toString$lambda(a, b) {
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
    tmp$ret$0 = toString(getKClassFromExpression(a));
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
    tmp$ret$1 = toString(getKClassFromExpression(b));
    tmp$ret$2 = compareValues(tmp, tmp$ret$1);
    return tmp$ret$2;
  }
  function World() {
    this.zfz_1 = new TypedInjections();
    this.ag0_1 = new EntityIdService();
    this.bg0_1 = new ComponentService(this);
    this.cg0_1 = new FamilyService(this, this.bg0_1);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.dg0_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$1 = ArrayList_init_$Create$();
    tmp_0.eg0_1 = tmp$ret$1;
    this.fg0_1 = false;
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$2 = ArrayList_init_$Create$();
    tmp_1.gg0_1 = tmp$ret$2;
  }
  protoOf(World).xg1 = function (entityId) {
    return this.dg0_1.q(new EntityId(entityId));
  };
  protoOf(World).fg3 = function (builder) {
    var id = this.ag0_1.pg1();
    var newEntityId = _EntityId___init__impl__cq1mhp(id);
    this.dg0_1.b(new EntityId(newEntityId));
    this.gg3(newEntityId, builder);
    return newEntityId;
  };
  protoOf(World).hg3 = function (builder) {
    // Inline function 'kotlin.require' call
    var tmp0_require = !this.fg0_1;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.World.addEntityReturnStateful.<anonymous>' call
      tmp$ret$0 = 'Cannot return a newly added stateful entity during an update.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var id = this.fg3(builder);
    return this.hg0(id);
  };
  protoOf(World).gg3 = function (entityId, builder) {
    if (this.fg0_1) {
      this.gg0_1.b(to(new EntityId(entityId), builder));
      return Unit_getInstance();
    }
    builder(new ModifyEntityApi(this, entityId));
    this.cg0_1.mg0(entityId);
  };
  protoOf(World).kg2 = function (familyConfiguration) {
    return this.cg0_1.kg2(familyConfiguration).eg2_1;
  };
  protoOf(World).ig3 = function (listener) {
    this.cg0_1.ig2(listener);
  };
  protoOf(World).jg3 = function (system) {
    var familyNode = this.cg0_1.kg2(system.jg2());
    system.tg2(familyNode.eg2_1);
    this.eg0_1.b(system);
  };
  protoOf(World).vg2 = function (deltaTime) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = this.eg0_1;
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.ecs.World.update.<anonymous>' call
      this.fg0_1 = true;
      if (element.rg2_1) {
        element.vg2(deltaTime);
      }
      this.fg0_1 = false;
      // Inline function 'kotlin.collections.forEach' call
      var tmp0_forEach_0 = this.gg0_1;
      var tmp0_iterator_0 = tmp0_forEach_0.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k();
        // Inline function 'com.xenotactic.ecs.World.update.<anonymous>.<anonymous>' call
        this.gg3(element_0.a5_1.zg0_1, element_0.b5_1);
      }
      this.gg0_1.e4();
    }
  };
  protoOf(World).kg3 = function (kClass) {
    var tmp = this.bg0_1.lg1(kClass);
    return tmp instanceof ComponentEntityContainer ? tmp : THROW_CCE();
  };
  protoOf(World).lg3 = function (entityId, kClass) {
    if (!this.xg1(entityId)) {
      throw new ECSEntityDoesNotExist(World$get$lambda(entityId));
    }
    return this.kg3(kClass).tg0(entityId);
  };
  protoOf(World).mg3 = function (entityId, kClass) {
    if (!this.xg1(entityId)) {
      throw new ECSEntityDoesNotExist(World$getOrNull$lambda(entityId));
    }
    return this.kg3(kClass).ug0(entityId);
  };
  protoOf(World).toString = function () {
    var sb = StringBuilder_init_$Create$();
    var tmp$ret$1;
    // Inline function 'kotlin.text.appendLine' call
    var tmp$ret$0;
    // Inline function 'kotlin.text.appendLine' call
    var tmp0_appendLine = sb.bc('Entities:');
    tmp$ret$0 = tmp0_appendLine.ib(_Char___init__impl__6a9atx(10));
    tmp$ret$1 = tmp$ret$0;
    // Inline function 'kotlin.collections.forEach' call
    var tmp1_forEach = this.dg0_1;
    var tmp0_iterator = tmp1_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>' call
      var tmp$ret$3;
      // Inline function 'kotlin.text.appendLine' call
      var tmp1_appendLine = '\t' + new EntityId(element);
      var tmp$ret$2;
      // Inline function 'kotlin.text.appendLine' call
      var tmp0_appendLine_0 = sb.bc(tmp1_appendLine);
      tmp$ret$2 = tmp0_appendLine_0.ib(_Char___init__impl__6a9atx(10));
      tmp$ret$3 = tmp$ret$2;
      // Inline function 'kotlin.collections.forEach' call
      var tmp$ret$5;
      // Inline function 'kotlin.collections.sortedBy' call
      var tmp2_sortedBy = this.bg0_1.eg1(element);
      var tmp$ret$4;
      // Inline function 'kotlin.comparisons.compareBy' call
      var tmp = World$toString$lambda;
      tmp$ret$4 = new sam$kotlin_Comparator$0(tmp);
      tmp$ret$5 = sortedWith(tmp2_sortedBy, tmp$ret$4);
      var tmp3_forEach = tmp$ret$5;
      var tmp0_iterator_0 = tmp3_forEach.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k();
        // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
        var tmp$ret$7;
        // Inline function 'kotlin.text.appendLine' call
        var tmp1_appendLine_0 = '\t\t' + toString(element_0);
        var tmp$ret$6;
        // Inline function 'kotlin.text.appendLine' call
        var tmp0_appendLine_1 = sb.bc(tmp1_appendLine_0);
        tmp$ret$6 = tmp0_appendLine_1.ib(_Char___init__impl__6a9atx(10));
        tmp$ret$7 = tmp$ret$6;
      }
    }
    var tmp$ret$9;
    // Inline function 'kotlin.text.appendLine' call
    var tmp$ret$8;
    // Inline function 'kotlin.text.appendLine' call
    var tmp2_appendLine = sb.bc('Families:');
    tmp$ret$8 = tmp2_appendLine.ib(_Char___init__impl__6a9atx(10));
    tmp$ret$9 = tmp$ret$8;
    // Inline function 'kotlin.collections.forEach' call
    var tmp3_forEach_0 = this.cg0_1.lg0_1;
    var tmp$ret$10;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$10 = tmp3_forEach_0.d1().h();
    var tmp0_iterator_1 = tmp$ret$10;
    while (tmp0_iterator_1.j()) {
      var element_1 = tmp0_iterator_1.k();
      // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>' call
      var tmp$ret$12;
      // Inline function 'kotlin.text.appendLine' call
      var tmp$ret$11;
      // Inline function 'kotlin.text.appendLine' call
      var tmp0_appendLine_2 = sb.bc('\tFamilyConfiguration');
      tmp$ret$11 = tmp0_appendLine_2.ib(_Char___init__impl__6a9atx(10));
      tmp$ret$12 = tmp$ret$11;
      var tmp$ret$14;
      // Inline function 'kotlin.text.appendLine' call
      var tmp$ret$13;
      // Inline function 'kotlin.text.appendLine' call
      var tmp1_appendLine_1 = sb.bc('\t\tallOfComponents');
      tmp$ret$13 = tmp1_appendLine_1.ib(_Char___init__impl__6a9atx(10));
      tmp$ret$14 = tmp$ret$13;
      var tmp$ret$15;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp2_isNotEmpty = element_1.e1().ag2_1;
      tmp$ret$15 = !tmp2_isNotEmpty.p();
      if (tmp$ret$15) {
        // Inline function 'kotlin.collections.forEach' call
        var tmp3_forEach_1 = element_1.e1().ag2_1;
        var tmp0_iterator_2 = tmp3_forEach_1.h();
        while (tmp0_iterator_2.j()) {
          var element_2 = tmp0_iterator_2.k();
          // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
          var tmp$ret$17;
          // Inline function 'kotlin.text.appendLine' call
          var tmp1_appendLine_2 = '\t\t\t' + element_2;
          var tmp$ret$16;
          // Inline function 'kotlin.text.appendLine' call
          var tmp0_appendLine_3 = sb.bc(tmp1_appendLine_2);
          tmp$ret$16 = tmp0_appendLine_3.ib(_Char___init__impl__6a9atx(10));
          tmp$ret$17 = tmp$ret$16;
        }
      }
      var tmp$ret$18;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp4_isNotEmpty = element_1.e1().bg2_1;
      tmp$ret$18 = !tmp4_isNotEmpty.p();
      if (tmp$ret$18) {
        var tmp$ret$20;
        // Inline function 'kotlin.text.appendLine' call
        var tmp$ret$19;
        // Inline function 'kotlin.text.appendLine' call
        var tmp5_appendLine = sb.bc('\t\tanyOfComponents');
        tmp$ret$19 = tmp5_appendLine.ib(_Char___init__impl__6a9atx(10));
        tmp$ret$20 = tmp$ret$19;
        // Inline function 'kotlin.collections.forEach' call
        var tmp6_forEach = element_1.e1().bg2_1;
        var tmp0_iterator_3 = tmp6_forEach.h();
        while (tmp0_iterator_3.j()) {
          var element_3 = tmp0_iterator_3.k();
          // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
          var tmp$ret$22;
          // Inline function 'kotlin.text.appendLine' call
          var tmp1_appendLine_3 = '\t\t\t' + element_3;
          var tmp$ret$21;
          // Inline function 'kotlin.text.appendLine' call
          var tmp0_appendLine_4 = sb.bc(tmp1_appendLine_3);
          tmp$ret$21 = tmp0_appendLine_4.ib(_Char___init__impl__6a9atx(10));
          tmp$ret$22 = tmp$ret$21;
        }
      }
      var tmp$ret$23;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp7_isNotEmpty = element_1.e1().cg2_1;
      tmp$ret$23 = !tmp7_isNotEmpty.p();
      if (tmp$ret$23) {
        var tmp$ret$25;
        // Inline function 'kotlin.text.appendLine' call
        var tmp$ret$24;
        // Inline function 'kotlin.text.appendLine' call
        var tmp8_appendLine = sb.bc('\t\tnoneOfComponents');
        tmp$ret$24 = tmp8_appendLine.ib(_Char___init__impl__6a9atx(10));
        tmp$ret$25 = tmp$ret$24;
        // Inline function 'kotlin.collections.forEach' call
        var tmp9_forEach = element_1.e1().cg2_1;
        var tmp0_iterator_4 = tmp9_forEach.h();
        while (tmp0_iterator_4.j()) {
          var element_4 = tmp0_iterator_4.k();
          // Inline function 'com.xenotactic.ecs.World.toString.<anonymous>.<anonymous>' call
          var tmp$ret$27;
          // Inline function 'kotlin.text.appendLine' call
          var tmp1_appendLine_4 = '\t\t\t' + element_4;
          var tmp$ret$26;
          // Inline function 'kotlin.text.appendLine' call
          var tmp0_appendLine_5 = sb.bc(tmp1_appendLine_4);
          tmp$ret$26 = tmp0_appendLine_5.ib(_Char___init__impl__6a9atx(10));
          tmp$ret$27 = tmp$ret$26;
        }
      }
      var tmp$ret$29;
      // Inline function 'kotlin.text.appendLine' call
      var tmp11_appendLine = '\t\t' + element_1.f1();
      var tmp$ret$28;
      // Inline function 'kotlin.text.appendLine' call
      var tmp10_appendLine = sb.bc(tmp11_appendLine);
      tmp$ret$28 = tmp10_appendLine.ib(_Char___init__impl__6a9atx(10));
      tmp$ret$29 = tmp$ret$28;
    }
    return sb.toString();
  };
  protoOf(World).ng3 = function (familyConfiguration) {
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filter' call
    var tmp1_filter = this.dg0_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = ArrayList_init_$Create$();
    var tmp0_iterator = tmp1_filter.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.World.getStatefulEntitySnapshots.<anonymous>' call
      tmp$ret$0 = this.cg0_1.dg2(element, familyConfiguration);
      if (tmp$ret$0) {
        tmp0_filterTo.b(new EntityId(element));
      }
    }
    tmp$ret$1 = tmp0_filterTo;
    tmp$ret$2 = tmp$ret$1;
    var tmp3_map = tmp$ret$2;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp3_map, 10));
    var tmp0_iterator_0 = tmp3_map.h();
    while (tmp0_iterator_0.j()) {
      var item = tmp0_iterator_0.k().zg0_1;
      var tmp$ret$3;
      // Inline function 'com.xenotactic.ecs.World.getStatefulEntitySnapshots.<anonymous>' call
      tmp$ret$3 = this.hg0(item);
      tmp2_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp2_mapTo;
    tmp$ret$5 = tmp$ret$4;
    return tmp$ret$5;
  };
  protoOf(World).hg0 = function (entityId) {
    return this.bg0_1.hg0(entityId);
  };
  protoOf(World).og3 = function (familyConfiguration) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.first' call
      var tmp0_first = this.dg0_1;
      var tmp0_iterator = tmp0_first.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k().zg0_1;
        var tmp$ret$0;
        // Inline function 'com.xenotactic.ecs.World.getFirstStatefulEntityMatching.<anonymous>' call
        tmp$ret$0 = this.cg0_1.dg2(element, familyConfiguration);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      throw NoSuchElementException_init_$Create$('Collection contains no element matching the predicate.');
    }
    var entityId = tmp$ret$1;
    return this.hg0(entityId);
  };
  protoOf(World).pg3 = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.dg0_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k().zg0_1;
      var tmp$ret$0;
      // Inline function 'com.xenotactic.ecs.World.getStagingEntities.<anonymous>' call
      tmp$ret$0 = this.gg1(item);
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(World).gg1 = function (entityId) {
    return this.bg0_1.gg1(entityId);
  };
  function SingletonInjectionAlreadyExistsException(messageFn) {
    ECSException.call(this, messageFn);
    captureStack(this, SingletonInjectionAlreadyExistsException);
  }
  function SingletonInjectionDoesNotExistException(messageFn) {
    ECSException.call(this, messageFn);
    captureStack(this, SingletonInjectionDoesNotExistException);
  }
  function ECSComponentAlreadyExistsException(messageFn) {
    ECSException.call(this, messageFn);
    captureStack(this, ECSComponentAlreadyExistsException);
  }
  function ECSEntityDoesNotExist(messageFn) {
    ECSException.call(this, messageFn);
    captureStack(this, ECSEntityDoesNotExist);
  }
  function ECSComponentNotFoundException(messageFn) {
    ECSException.call(this, messageFn);
    captureStack(this, ECSComponentNotFoundException);
  }
  function ECSException(messageFn) {
    RuntimeException_init_$Init$(messageFn(), this);
    captureStack(this, ECSException);
  }
  function getString(_this__u8e3s4) {
    return 'FamilyConfiguration(JS NOT IMPLEMENTED YET)';
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = ComponentEntityContainer;
  _.$_$.b = onAddOrReplace;
  _.$_$.c = onAdd;
  _.$_$.d = onExisting;
  _.$_$.e = onRemove;
  _.$_$.f = onReplace;
  _.$_$.g = ComponentListener;
  _.$_$.h = EntityId;
  _.$_$.i = FamilyConfiguration;
  _.$_$.j = SingletonInjectionDoesNotExistException;
  _.$_$.k = System;
  _.$_$.l = TypedInjections;
  _.$_$.m = World;
  _.$_$.n = StagingEntity_init_$Create$;
  _.$_$.o = EntityId__hashCode_impl_1s8fhw;
  _.$_$.p = Companion_getInstance_0;
  //endregion
  return _;
}));
