(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './88b0986a7186d029-atomicfu-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./88b0986a7186d029-atomicfu-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir'.");
    }
    if (typeof this['88b0986a7186d029-atomicfu-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir'. Its dependency '88b0986a7186d029-atomicfu-js-ir' was not found. Please, check whether '88b0986a7186d029-atomicfu-js-ir' is loaded prior to 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir'.");
    }
    root['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] = factory(typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined' ? {} : this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'], this['kotlin-kotlin-stdlib-js-ir'], this['88b0986a7186d029-atomicfu-js-ir']);
  }
}(this, function (_, kotlin_kotlin, kotlin_org_jetbrains_kotlinx_atomicfu) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var protoOf = kotlin_kotlin.$_$.nd;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var isObject = kotlin_kotlin.$_$.ad;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var plus = kotlin_kotlin.$_$.jb;
  var get = kotlin_kotlin.$_$.gb;
  var fold = kotlin_kotlin.$_$.fb;
  var minusKey = kotlin_kotlin.$_$.hb;
  var Continuation = kotlin_kotlin.$_$.eb;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var EmptyCoroutineContext_getInstance = kotlin_kotlin.$_$.f4;
  var Key_getInstance = kotlin_kotlin.$_$.e4;
  var equals = kotlin_kotlin.$_$.cc;
  var createCoroutineUnintercepted = kotlin_kotlin.$_$.va;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var atomic$int$1 = kotlin_org_jetbrains_kotlinx_atomicfu.$_$.e;
  var intercepted = kotlin_kotlin.$_$.wa;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var isInterface = kotlin_kotlin.$_$.xc;
  var toString = kotlin_kotlin.$_$.sd;
  var toString_0 = kotlin_kotlin.$_$.kj;
  var atomic$ref$1 = kotlin_org_jetbrains_kotlinx_atomicfu.$_$.d;
  var objectMeta = kotlin_kotlin.$_$.md;
  var hashCode = kotlin_kotlin.$_$.lc;
  var Result__exceptionOrNull_impl_p6xea9 = kotlin_kotlin.$_$.r2;
  var _Result___get_value__impl__bjfvqg = kotlin_kotlin.$_$.t2;
  var atomic$boolean$1 = kotlin_org_jetbrains_kotlinx_atomicfu.$_$.b;
  var CancellationException_init_$Create$ = kotlin_kotlin.$_$.a1;
  var Companion_getInstance = kotlin_kotlin.$_$.u4;
  var _Result___init__impl__xyqfz8 = kotlin_kotlin.$_$.q2;
  var createFailure = kotlin_kotlin.$_$.si;
  var AbstractCoroutineContextKey = kotlin_kotlin.$_$.za;
  var AbstractCoroutineContextElement = kotlin_kotlin.$_$.ya;
  var get_0 = kotlin_kotlin.$_$.ab;
  var minusKey_0 = kotlin_kotlin.$_$.bb;
  var ContinuationInterceptor = kotlin_kotlin.$_$.db;
  var RuntimeException_init_$Create$ = kotlin_kotlin.$_$.z1;
  var Enum = kotlin_kotlin.$_$.rh;
  var startCoroutine = kotlin_kotlin.$_$.nb;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var Long = kotlin_kotlin.$_$.vh;
  var Companion_getInstance_0 = kotlin_kotlin.$_$.t4;
  var ArrayDeque_init_$Create$ = kotlin_kotlin.$_$.j;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.y1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var Error_0 = kotlin_kotlin.$_$.sh;
  var Error_init_$Init$ = kotlin_kotlin.$_$.g1;
  var Element = kotlin_kotlin.$_$.ib;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.k;
  var CancellationException = kotlin_kotlin.$_$.ta;
  var ArrayList = kotlin_kotlin.$_$.c5;
  var SequenceScope = kotlin_kotlin.$_$.te;
  var IllegalStateException_init_$Create$_0 = kotlin_kotlin.$_$.q1;
  var sequence = kotlin_kotlin.$_$.cf;
  var anyToString = kotlin_kotlin.$_$.qb;
  var UnsupportedOperationException = kotlin_kotlin.$_$.oi;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var CancellationException_init_$Init$ = kotlin_kotlin.$_$.z;
  var Companion_getInstance_1 = kotlin_kotlin.$_$.q4;
  var DurationUnit_MILLISECONDS_getInstance = kotlin_kotlin.$_$.g;
  var toDuration = kotlin_kotlin.$_$.lh;
  var UnsupportedOperationException_init_$Create$ = kotlin_kotlin.$_$.b2;
  var arrayIterator = kotlin_kotlin.$_$.rb;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.l;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var addSuppressed = kotlin_kotlin.$_$.pi;
  var Exception = kotlin_kotlin.$_$.th;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var toLong = kotlin_kotlin.$_$.qd;
  var toLongOrNull = kotlin_kotlin.$_$.ug;
  var Unit = kotlin_kotlin.$_$.ni;
  var atomic$long$1 = kotlin_org_jetbrains_kotlinx_atomicfu.$_$.c;
  var atomicfu$AtomicRefArray$ofNulls = kotlin_org_jetbrains_kotlinx_atomicfu.$_$.a;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var CancellationException_init_$Init$_0 = kotlin_kotlin.$_$.b1;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var removeFirstOrNull = kotlin_kotlin.$_$.e9;
  var MutableList = kotlin_kotlin.$_$.o5;
  var coerceIn = kotlin_kotlin.$_$.ge;
  var HashSet_init_$Create$ = kotlin_kotlin.$_$.q;
  var RuntimeException_init_$Init$_0 = kotlin_kotlin.$_$.w1;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var UnsupportedOperationException_init_$Create$_0 = kotlin_kotlin.$_$.a2;
  //endregion
  //region block: pre-declaration
  function cancel$default(cause, $super) {
    cause = cause === VOID ? null : cause;
    var tmp;
    if ($super === VOID) {
      this.bq(cause);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.bq.call(this, cause);
    }
    return tmp;
  }
  function invokeOnCompletion$default(onCancelling, invokeImmediately, handler, $super) {
    onCancelling = onCancelling === VOID ? false : onCancelling;
    invokeImmediately = invokeImmediately === VOID ? true : invokeImmediately;
    return $super === VOID ? this.wp(onCancelling, invokeImmediately, handler) : $super.wp.call(this, onCancelling, invokeImmediately, handler);
  }
  setMetadataFor(Job, 'Job', interfaceMeta, VOID, [Element], VOID, VOID, [0]);
  setMetadataFor(ParentJob, 'ParentJob', interfaceMeta, VOID, [Job], VOID, VOID, [0]);
  setMetadataFor(JobSupport, 'JobSupport', classMeta, VOID, [Job, ParentJob], VOID, VOID, [0]);
  setMetadataFor(CoroutineScope, 'CoroutineScope', interfaceMeta);
  setMetadataFor(AbstractCoroutine, 'AbstractCoroutine', classMeta, JobSupport, [JobSupport, Job, Continuation, CoroutineScope], VOID, VOID, [0]);
  setMetadataFor(StandaloneCoroutine, 'StandaloneCoroutine', classMeta, AbstractCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor(LazyStandaloneCoroutine, 'LazyStandaloneCoroutine', classMeta, StandaloneCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor($awaitCOROUTINE$0, '$awaitCOROUTINE$0', classMeta, CoroutineImpl);
  setMetadataFor(Deferred, 'Deferred', interfaceMeta, VOID, [Job], VOID, VOID, [0]);
  setMetadataFor(DeferredCoroutine, 'DeferredCoroutine', classMeta, AbstractCoroutine, [AbstractCoroutine, Deferred], VOID, VOID, [0]);
  setMetadataFor(LazyDeferredCoroutine, 'LazyDeferredCoroutine', classMeta, DeferredCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor(ScopeCoroutine, 'ScopeCoroutine', classMeta, AbstractCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor(DispatchedCoroutine, 'DispatchedCoroutine', classMeta, ScopeCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor(CancellableContinuation, 'CancellableContinuation', interfaceMeta, VOID, [Continuation]);
  setMetadataFor(CancelHandlerBase, 'CancelHandlerBase', classMeta);
  setMetadataFor(NotCompleted, 'NotCompleted', interfaceMeta);
  setMetadataFor(CancelHandler, 'CancelHandler', classMeta, CancelHandlerBase, [CancelHandlerBase, NotCompleted]);
  setMetadataFor(DisposeOnCancel, 'DisposeOnCancel', classMeta, CancelHandler);
  setMetadataFor(Runnable, 'Runnable', interfaceMeta);
  setMetadataFor(SchedulerTask, 'SchedulerTask', classMeta, VOID, [Runnable]);
  setMetadataFor(DispatchedTask, 'DispatchedTask', classMeta, SchedulerTask);
  setMetadataFor(Waiter, 'Waiter', interfaceMeta);
  setMetadataFor(CancellableContinuationImpl, 'CancellableContinuationImpl', classMeta, DispatchedTask, [DispatchedTask, CancellableContinuation, Waiter]);
  setMetadataFor(Active, 'Active', objectMeta, VOID, [NotCompleted]);
  setMetadataFor(CompletedContinuation, 'CompletedContinuation', classMeta);
  setMetadataFor(InvokeOnCancel, 'InvokeOnCancel', classMeta, CancelHandler);
  setMetadataFor($awaitCOROUTINE$1, '$awaitCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor(CompletableDeferredImpl, 'CompletableDeferredImpl', classMeta, JobSupport, [JobSupport, Deferred], VOID, VOID, [0]);
  setMetadataFor(CompletedExceptionally, 'CompletedExceptionally', classMeta);
  setMetadataFor(CancelledContinuation, 'CancelledContinuation', classMeta, CompletedExceptionally);
  setMetadataFor(CompletedWithCancellation, 'CompletedWithCancellation', classMeta);
  setMetadataFor(Key, 'Key', objectMeta, AbstractCoroutineContextKey);
  setMetadataFor(CoroutineDispatcher, 'CoroutineDispatcher', classMeta, AbstractCoroutineContextElement, [AbstractCoroutineContextElement, ContinuationInterceptor]);
  setMetadataFor(Key_0, 'Key', objectMeta);
  setMetadataFor(GlobalScope, 'GlobalScope', objectMeta, VOID, [CoroutineScope]);
  setMetadataFor(CoroutineStart, 'CoroutineStart', classMeta, Enum);
  function invokeOnTimeout(timeMillis, block, context) {
    return get_DefaultDelay().lv(timeMillis, block, context);
  }
  setMetadataFor(Delay, 'Delay', interfaceMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(DelayWithTimeoutDiagnostics, 'DelayWithTimeoutDiagnostics', interfaceMeta, VOID, [Delay], VOID, VOID, [1]);
  setMetadataFor(EventLoop, 'EventLoop', classMeta, CoroutineDispatcher);
  setMetadataFor(ThreadLocalEventLoop, 'ThreadLocalEventLoop', objectMeta);
  setMetadataFor(CompletionHandlerException, 'CompletionHandlerException', classMeta, RuntimeException);
  setMetadataFor(CoroutinesInternalError, 'CoroutinesInternalError', classMeta, Error_0);
  setMetadataFor(Key_1, 'Key', objectMeta);
  setMetadataFor(ChildHandle, 'ChildHandle', interfaceMeta);
  setMetadataFor(NonDisposableHandle, 'NonDisposableHandle', objectMeta, VOID, [ChildHandle]);
  setMetadataFor(Incomplete, 'Incomplete', interfaceMeta);
  setMetadataFor(Empty, 'Empty', classMeta, VOID, [Incomplete]);
  setMetadataFor(LinkedListNode, 'LinkedListNode', classMeta);
  setMetadataFor(LinkedListHead, 'LinkedListHead', classMeta, LinkedListNode);
  setMetadataFor(NodeList, 'NodeList', classMeta, LinkedListHead, [LinkedListHead, Incomplete]);
  setMetadataFor(CompletionHandlerBase, 'CompletionHandlerBase', classMeta, LinkedListNode);
  setMetadataFor(JobNode, 'JobNode', classMeta, CompletionHandlerBase, [CompletionHandlerBase, Incomplete]);
  setMetadataFor(Finishing, 'Finishing', classMeta, VOID, [Incomplete]);
  setMetadataFor(ChildCompletion, 'ChildCompletion', classMeta, JobNode);
  setMetadataFor(AwaitContinuation, 'AwaitContinuation', classMeta, CancellableContinuationImpl);
  setMetadataFor(JobSupport$_get_children_$slambda_k839f8, 'JobSupport$<get-children>$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(JobCancellingNode, 'JobCancellingNode', classMeta, JobNode);
  setMetadataFor(InactiveNodeList, 'InactiveNodeList', classMeta, VOID, [Incomplete]);
  setMetadataFor(ChildHandleNode, 'ChildHandleNode', classMeta, JobCancellingNode, [JobCancellingNode, ChildHandle]);
  setMetadataFor(InvokeOnCancelling, 'InvokeOnCancelling', classMeta, JobCancellingNode);
  setMetadataFor(InvokeOnCompletion, 'InvokeOnCompletion', classMeta, JobNode);
  setMetadataFor(ResumeOnCompletion, 'ResumeOnCompletion', classMeta, JobNode);
  setMetadataFor(ResumeAwaitOnCompletion, 'ResumeAwaitOnCompletion', classMeta, JobNode);
  setMetadataFor(IncompleteStateBox, 'IncompleteStateBox', classMeta);
  setMetadataFor(ChildContinuation, 'ChildContinuation', classMeta, JobCancellingNode);
  setMetadataFor(JobImpl, 'JobImpl', classMeta, JobSupport, [JobSupport, Job], VOID, VOID, [0]);
  setMetadataFor(DisposeOnCompletion, 'DisposeOnCompletion', classMeta, JobNode);
  setMetadataFor(MainCoroutineDispatcher, 'MainCoroutineDispatcher', classMeta, CoroutineDispatcher);
  setMetadataFor(SupervisorJobImpl, 'SupervisorJobImpl', classMeta, JobImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(TimeoutCancellationException, 'TimeoutCancellationException', classMeta, CancellationException);
  setMetadataFor(TimeoutCoroutine, 'TimeoutCoroutine', classMeta, ScopeCoroutine, [ScopeCoroutine, Runnable], VOID, VOID, [0]);
  setMetadataFor(Unconfined, 'Unconfined', objectMeta, CoroutineDispatcher);
  setMetadataFor(Key_2, 'Key', objectMeta);
  setMetadataFor(AbstractFlow, 'AbstractFlow', classMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(SafeFlow, 'SafeFlow', classMeta, AbstractFlow, VOID, VOID, VOID, [1]);
  setMetadataFor($collectCOROUTINE$8, '$collectCOROUTINE$8', classMeta, CoroutineImpl);
  setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor($collectCOROUTINE$9, '$collectCOROUTINE$9', classMeta, CoroutineImpl);
  setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor($collectCOROUTINE$10, '$collectCOROUTINE$10', classMeta, CoroutineImpl);
  setMetadataFor(FlowCollector, 'FlowCollector', interfaceMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', classMeta, VOID, [FlowCollector], VOID, VOID, [1]);
  setMetadataFor(toCollection$slambda, 'toCollection$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($toCollectionCOROUTINE$11, '$toCollectionCOROUTINE$11', classMeta, CoroutineImpl);
  setMetadataFor(OpDescriptor, 'OpDescriptor', classMeta);
  setMetadataFor(ConcurrentLinkedListNode, 'ConcurrentLinkedListNode', classMeta);
  setMetadataFor(Segment, 'Segment', classMeta, ConcurrentLinkedListNode, [ConcurrentLinkedListNode, NotCompleted]);
  setMetadataFor(ExceptionSuccessfullyProcessed, 'ExceptionSuccessfullyProcessed', objectMeta, Exception);
  setMetadataFor(DispatchedContinuation, 'DispatchedContinuation', classMeta, DispatchedTask, [DispatchedTask, Continuation]);
  setMetadataFor(ContextScope, 'ContextScope', classMeta, VOID, [CoroutineScope]);
  setMetadataFor(Symbol, 'Symbol', classMeta);
  setMetadataFor(SelectInstance, 'SelectInstance', interfaceMeta);
  function lock$default(owner, $completion, $super) {
    owner = owner === VOID ? null : owner;
    return $super === VOID ? this.g14(owner, $completion) : $super.g14.call(this, owner, $completion);
  }
  function unlock$default(owner, $super) {
    owner = owner === VOID ? null : owner;
    var tmp;
    if ($super === VOID) {
      this.i14(owner);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.i14.call(this, owner);
    }
    return tmp;
  }
  setMetadataFor(Mutex, 'Mutex', interfaceMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor(CancellableContinuationWithOwner, 'CancellableContinuationWithOwner', classMeta, VOID, [CancellableContinuation, Waiter]);
  setMetadataFor(SemaphoreImpl, 'SemaphoreImpl', classMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(MutexImpl, 'MutexImpl', classMeta, SemaphoreImpl, [SemaphoreImpl, Mutex], VOID, VOID, [1, 0]);
  setMetadataFor(SemaphoreSegment, 'SemaphoreSegment', classMeta, Segment);
  setMetadataFor(UndispatchedCoroutine, 'UndispatchedCoroutine', classMeta, ScopeCoroutine, VOID, VOID, VOID, [0]);
  setMetadataFor(Dispatchers, 'Dispatchers', objectMeta);
  setMetadataFor(JsMainDispatcher, 'JsMainDispatcher', classMeta, MainCoroutineDispatcher);
  setMetadataFor(UnconfinedEventLoop, 'UnconfinedEventLoop', classMeta, EventLoop);
  setMetadataFor(JobCancellationException, 'JobCancellationException', classMeta, CancellationException);
  setMetadataFor(SetTimeoutBasedDispatcher, 'SetTimeoutBasedDispatcher', classMeta, CoroutineDispatcher, [CoroutineDispatcher, Delay], VOID, VOID, [1]);
  setMetadataFor(NodeDispatcher, 'NodeDispatcher', objectMeta, SetTimeoutBasedDispatcher, VOID, VOID, VOID, [1]);
  setMetadataFor(SetTimeoutDispatcher, 'SetTimeoutDispatcher', objectMeta, SetTimeoutBasedDispatcher, VOID, VOID, VOID, [1]);
  setMetadataFor(MessageQueue, 'MessageQueue', classMeta, VOID, [MutableList]);
  setMetadataFor(ScheduledMessageQueue, 'ScheduledMessageQueue', classMeta, MessageQueue);
  setMetadataFor(ClearTimeout, 'ClearTimeout', classMeta, CancelHandler);
  setMetadataFor(WindowClearTimeout, 'WindowClearTimeout', classMeta, ClearTimeout);
  setMetadataFor(WindowDispatcher, 'WindowDispatcher', classMeta, CoroutineDispatcher, [CoroutineDispatcher, Delay], VOID, VOID, [1]);
  setMetadataFor(WindowMessageQueue, 'WindowMessageQueue', classMeta, MessageQueue);
  setMetadataFor(SafeCollector, 'SafeCollector', classMeta, VOID, [FlowCollector], VOID, VOID, [1]);
  setMetadataFor(DiagnosticCoroutineContextException, 'DiagnosticCoroutineContextException', classMeta, RuntimeException);
  setMetadataFor(CommonThreadLocal, 'CommonThreadLocal', classMeta);
  //endregion
  function AbstractCoroutine(parentContext, initParentJob, active) {
    JobSupport.call(this, active);
    if (initParentJob) {
      this.po(parentContext.o8(Key_getInstance_2()));
    }
    this.so_1 = parentContext.v8(this);
  }
  protoOf(AbstractCoroutine).s5 = function () {
    return this.so_1;
  };
  protoOf(AbstractCoroutine).to = function () {
    return this.so_1;
  };
  protoOf(AbstractCoroutine).uo = function () {
    return protoOf(JobSupport).uo.call(this);
  };
  protoOf(AbstractCoroutine).vo = function (value) {
  };
  protoOf(AbstractCoroutine).wo = function (cause, handled) {
  };
  protoOf(AbstractCoroutine).xo = function () {
    return get_classSimpleName(this) + ' was cancelled';
  };
  protoOf(AbstractCoroutine).yo = function (state) {
    if (state instanceof CompletedExceptionally) {
      this.wo(state.zo_1, state.bp());
    } else {
      this.vo((state == null ? true : isObject(state)) ? state : THROW_CCE());
    }
  };
  protoOf(AbstractCoroutine).q5 = function (result) {
    var state = this.cp(toState_0(result));
    if (state === get_COMPLETING_WAITING_CHILDREN())
      return Unit_getInstance();
    this.dp(state);
  };
  protoOf(AbstractCoroutine).dp = function (state) {
    return this.ep(state);
  };
  protoOf(AbstractCoroutine).fp = function (exception) {
    handleCoroutineException(this.so_1, exception);
  };
  protoOf(AbstractCoroutine).gp = function () {
    var tmp0_elvis_lhs = get_coroutineName(this.so_1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return protoOf(JobSupport).gp.call(this);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var coroutineName = tmp;
    return '"' + coroutineName + '":' + protoOf(JobSupport).gp.call(this);
  };
  protoOf(AbstractCoroutine).hp = function (start, receiver, block) {
    start.kp(block, receiver, this);
  };
  function launch(_this__u8e3s4, context, start, block) {
    context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
    start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
    var newContext = newCoroutineContext(_this__u8e3s4, context);
    var coroutine = start.pq() ? new LazyStandaloneCoroutine(newContext, block) : new StandaloneCoroutine(newContext, true);
    coroutine.hp(start, coroutine, block);
    return coroutine;
  }
  function async(_this__u8e3s4, context, start, block) {
    context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
    start = start === VOID ? CoroutineStart_DEFAULT_getInstance() : start;
    var newContext = newCoroutineContext(_this__u8e3s4, context);
    var coroutine = start.pq() ? new LazyDeferredCoroutine(newContext, block) : new DeferredCoroutine(newContext, true);
    coroutine.hp(start, coroutine, block);
    return coroutine;
  }
  function withContext(context, block, $completion) {
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.withContext.<anonymous>' call
      var tmp0__anonymous__q1qw7t = $completion;
      var oldContext = tmp0__anonymous__q1qw7t.s5();
      var newContext = newCoroutineContext_0(oldContext, context);
      ensureActive(newContext);
      if (newContext === oldContext) {
        var coroutine = new ScopeCoroutine(newContext, tmp0__anonymous__q1qw7t);
        tmp$ret$0 = startUndispatchedOrReturn(coroutine, coroutine, block);
        break $l$block_0;
      }
      if (equals(newContext.o8(Key_getInstance()), oldContext.o8(Key_getInstance()))) {
        var coroutine_0 = new UndispatchedCoroutine(newContext, tmp0__anonymous__q1qw7t);
        var tmp$ret$1;
        // Inline function 'kotlinx.coroutines.withCoroutineContext' call
        var tmp0_withCoroutineContext = coroutine_0.so_1;
        tmp$ret$0 = startUndispatchedOrReturn(coroutine_0, coroutine_0, block);
        break $l$block_0;
      }
      var coroutine_1 = new DispatchedCoroutine(newContext, tmp0__anonymous__q1qw7t);
      startCoroutineCancellable(block, coroutine_1, coroutine_1);
      tmp$ret$0 = coroutine_1.vq();
    }
    var tmp0 = tmp$ret$0;
    return tmp0;
  }
  function StandaloneCoroutine(parentContext, active) {
    AbstractCoroutine.call(this, parentContext, true, active);
  }
  protoOf(StandaloneCoroutine).nq = function (exception) {
    handleCoroutineException(this.so_1, exception);
    return true;
  };
  function LazyStandaloneCoroutine(parentContext, block) {
    StandaloneCoroutine.call(this, parentContext, false);
    this.cr_1 = createCoroutineUnintercepted(block, this, this);
  }
  protoOf(LazyStandaloneCoroutine).rp = function () {
    startCoroutineCancellable_0(this.cr_1, this);
  };
  function $awaitCOROUTINE$0(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.lr_1 = _this__u8e3s4;
  }
  protoOf($awaitCOROUTINE$0).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.lr_1.mr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return (suspendResult == null ? true : isObject(suspendResult)) ? suspendResult : THROW_CCE();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function DeferredCoroutine(parentContext, active) {
    AbstractCoroutine.call(this, parentContext, true, active);
  }
  protoOf(DeferredCoroutine).qr = function ($completion) {
    var tmp = new $awaitCOROUTINE$0(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function LazyDeferredCoroutine(parentContext, block) {
    DeferredCoroutine.call(this, parentContext, false);
    this.ur_1 = createCoroutineUnintercepted(block, this, this);
  }
  protoOf(LazyDeferredCoroutine).rp = function () {
    startCoroutineCancellable_0(this.ur_1, this);
  };
  function trySuspend($this) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.uq_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.DispatchedCoroutine.trySuspend.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      switch (tmp0_subject) {
        case 0:
          if ($this.uq_1.atomicfu$compareAndSet(0, 1))
            return true;
          break;
        case 2:
          return false;
        default:
          // Inline function 'kotlin.error' call

          throw IllegalStateException_init_$Create$('Already suspended');
      }
    }
  }
  function tryResume($this) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.uq_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.DispatchedCoroutine.tryResume.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      switch (tmp0_subject) {
        case 0:
          if ($this.uq_1.atomicfu$compareAndSet(0, 2))
            return true;
          break;
        case 1:
          return false;
        default:
          // Inline function 'kotlin.error' call

          throw IllegalStateException_init_$Create$('Already resumed');
      }
    }
  }
  function DispatchedCoroutine(context, uCont) {
    ScopeCoroutine.call(this, context, uCont);
    this.uq_1 = atomic$int$1(0);
  }
  protoOf(DispatchedCoroutine).ep = function (state) {
    this.dp(state);
  };
  protoOf(DispatchedCoroutine).dp = function (state) {
    if (tryResume(this))
      return Unit_getInstance();
    resumeCancellableWith(intercepted(this.yr_1), recoverResult(state, this.yr_1));
  };
  protoOf(DispatchedCoroutine).vq = function () {
    if (trySuspend(this))
      return get_COROUTINE_SUSPENDED();
    var state = unboxState(this.op());
    if (state instanceof CompletedExceptionally)
      throw state.zo_1;
    return (state == null ? true : isObject(state)) ? state : THROW_CCE();
  };
  function CancellableContinuation() {
  }
  function disposeOnCancellation(_this__u8e3s4, handle) {
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new DisposeOnCancel(handle);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    return _this__u8e3s4.bs(tmp$ret$1);
  }
  function DisposeOnCancel(handle) {
    CancelHandler.call(this);
    this.es_1 = handle;
  }
  protoOf(DisposeOnCancel).fs = function (cause) {
    return this.es_1.gs();
  };
  protoOf(DisposeOnCancel).invoke = function (cause) {
    return this.fs(cause);
  };
  protoOf(DisposeOnCancel).toString = function () {
    return 'DisposeOnCancel[' + this.es_1 + ']';
  };
  function getOrCreateCancellableContinuation(delegate) {
    if (!(delegate instanceof DispatchedContinuation)) {
      return new CancellableContinuationImpl(delegate, get_MODE_CANCELLABLE());
    }
    var tmp0_safe_receiver = delegate.ns();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.takeIf' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0;
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.getOrCreateCancellableContinuation.<anonymous>' call
      tmp$ret$0 = tmp0_safe_receiver.us();
      if (tmp$ret$0) {
        tmp_0 = tmp0_safe_receiver;
      } else {
        tmp_0 = null;
      }
      tmp$ret$1 = tmp_0;
      tmp = tmp$ret$1;
    }
    var tmp1_elvis_lhs = tmp;
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      return new CancellableContinuationImpl(delegate, get_MODE_CANCELLABLE_REUSABLE());
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    return tmp_1;
  }
  function get_RESUME_TOKEN() {
    _init_properties_CancellableContinuationImpl_kt__6rrtdd();
    return RESUME_TOKEN;
  }
  var RESUME_TOKEN;
  function _get_parentHandle__f8dcex($this) {
    return $this.ts_1.kotlinx$atomicfu$value;
  }
  function _get_stateDebugRepresentation__bf18u4($this) {
    var tmp0_subject = $this.op();
    var tmp;
    if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
      tmp = 'Active';
    } else {
      if (tmp0_subject instanceof CancelledContinuation) {
        tmp = 'Cancelled';
      } else {
        tmp = 'Completed';
      }
    }
    return tmp;
  }
  function isReusable($this) {
    var tmp;
    if (get_isReusableMode($this.ws_1)) {
      var tmp_0 = $this.ps_1;
      tmp = (tmp_0 instanceof DispatchedContinuation ? tmp_0 : THROW_CCE()).vs();
    } else {
      tmp = false;
    }
    return tmp;
  }
  function cancelLater($this, cause) {
    if (!isReusable($this))
      return false;
    var tmp = $this.ps_1;
    var dispatched = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
    return dispatched.xs(cause);
  }
  function callSegmentOnCancellation($this, segment, cause) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.index' call
    var tmp0__get_index__prvnk7 = $this.rs_1.kotlinx$atomicfu$value;
    tmp$ret$0 = tmp0__get_index__prvnk7 & 536870911;
    var index = tmp$ret$0;
    // Inline function 'kotlin.check' call
    var tmp1_check = !(index === 536870911);
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_check) {
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
      tmp$ret$1 = 'The index for Segment.onCancellation(..) is broken';
      var message = tmp$ret$1;
      throw IllegalStateException_init_$Create$(toString(message));
    }
    // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callCancelHandlerSafely' call
    try {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.callSegmentOnCancellation.<anonymous>' call
      segment.ct(index, cause, $this.s5());
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException($this.s5(), new CompletionHandlerException('Exception in invokeOnCancellation handler for ' + $this, ex));
      } else {
        throw $p;
      }
    }
  }
  function trySuspend_0($this) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.rs_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.trySuspend.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.decision' call
      tmp$ret$0 = tmp1__anonymous__uwfjfc >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i();
      var tmp0_subject = tmp$ret$0;
      switch (tmp0_subject) {
        case 0:
          var tmp$ret$2;
          // Inline function 'kotlinx.coroutines.decisionAndIndex' call
          var tmp0_decisionAndIndex = 1;
          var tmp$ret$1;
          // Inline function 'kotlinx.coroutines.index' call
          tmp$ret$1 = tmp1__anonymous__uwfjfc & 536870911;
          var tmp1_decisionAndIndex = tmp$ret$1;
          tmp$ret$2 = (tmp0_decisionAndIndex << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + tmp1_decisionAndIndex | 0;

          if ($this.rs_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, tmp$ret$2))
            return true;
          break;
        case 2:
          return false;
        default:
          // Inline function 'kotlin.error' call

          throw IllegalStateException_init_$Create$('Already suspended');
      }
    }
  }
  function tryResume_0($this) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.rs_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResume.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.decision' call
      tmp$ret$0 = tmp1__anonymous__uwfjfc >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i();
      var tmp0_subject = tmp$ret$0;
      switch (tmp0_subject) {
        case 0:
          var tmp$ret$2;
          // Inline function 'kotlinx.coroutines.decisionAndIndex' call
          var tmp0_decisionAndIndex = 2;
          var tmp$ret$1;
          // Inline function 'kotlinx.coroutines.index' call
          tmp$ret$1 = tmp1__anonymous__uwfjfc & 536870911;
          var tmp1_decisionAndIndex = tmp$ret$1;
          tmp$ret$2 = (tmp0_decisionAndIndex << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + tmp1_decisionAndIndex | 0;

          if ($this.rs_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, tmp$ret$2))
            return true;
          break;
        case 1:
          return false;
        default:
          // Inline function 'kotlin.error' call

          throw IllegalStateException_init_$Create$('Already resumed');
      }
    }
  }
  function installParentHandle($this) {
    var tmp0_elvis_lhs = $this.s5().o8(Key_getInstance_2());
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var parent = tmp;
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new ChildContinuation($this);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    var handle = parent.xp(true, VOID, tmp$ret$1);
    $this.ts_1.atomicfu$compareAndSet(null, handle);
    return handle;
  }
  function invokeOnCancellationImpl($this, handler) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.ss_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellationImpl.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      if (tmp0_subject instanceof Active) {
        if ($this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, handler))
          return Unit_getInstance();
      } else {
        var tmp;
        if (tmp0_subject instanceof CancelHandler) {
          tmp = true;
        } else {
          tmp = tmp0_subject instanceof Segment;
        }
        if (tmp) {
          multipleHandlersError($this, handler, tmp1__anonymous__uwfjfc);
        } else {
          if (tmp0_subject instanceof CompletedExceptionally) {
            if (!tmp1__anonymous__uwfjfc.lt()) {
              multipleHandlersError($this, handler, tmp1__anonymous__uwfjfc);
            }
            if (tmp1__anonymous__uwfjfc instanceof CancelledContinuation) {
              var tmp1_safe_receiver = tmp1__anonymous__uwfjfc instanceof CompletedExceptionally ? tmp1__anonymous__uwfjfc : null;
              var cause = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.zo_1;
              if (handler instanceof CancelHandler) {
                $this.it(handler, cause);
              } else {
                var segment = handler instanceof Segment ? handler : THROW_CCE();
                callSegmentOnCancellation($this, segment, cause);
              }
            }
            return Unit_getInstance();
          } else {
            if (tmp0_subject instanceof CompletedContinuation) {
              if (!(tmp1__anonymous__uwfjfc.et_1 == null)) {
                multipleHandlersError($this, handler, tmp1__anonymous__uwfjfc);
              }
              if (handler instanceof Segment)
                return Unit_getInstance();
              if (handler instanceof CancelHandler)
                handler;
              else
                THROW_CCE();
              if (tmp1__anonymous__uwfjfc.jt()) {
                $this.it(handler, tmp1__anonymous__uwfjfc.ht_1);
                return Unit_getInstance();
              }
              var update = tmp1__anonymous__uwfjfc.kt(VOID, handler);
              if ($this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update))
                return Unit_getInstance();
            } else {
              if (handler instanceof Segment)
                return Unit_getInstance();
              if (handler instanceof CancelHandler)
                handler;
              else
                THROW_CCE();
              var update_0 = new CompletedContinuation(tmp1__anonymous__uwfjfc, handler);
              if ($this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update_0))
                return Unit_getInstance();
            }
          }
        }
      }
    }
  }
  function multipleHandlersError($this, handler, state) {
    // Inline function 'kotlin.error' call
    var tmp0_error = "It's prohibited to register multiple handlers, tried to register " + toString(handler) + ', already has ' + toString_0(state);
    throw IllegalStateException_init_$Create$(toString(tmp0_error));
  }
  function makeCancelHandler($this, handler) {
    var tmp;
    if (handler instanceof CancelHandler) {
      tmp = handler;
    } else {
      tmp = new InvokeOnCancel(handler);
    }
    return tmp;
  }
  function dispatchResume($this, mode) {
    if (tryResume_0($this))
      return Unit_getInstance();
    dispatch($this, mode);
  }
  function resumedState($this, state, proposedUpdate, resumeMode, onCancellation, idempotent) {
    var tmp;
    if (proposedUpdate instanceof CompletedExceptionally) {
      // Inline function 'kotlinx.coroutines.assert' call
      // Inline function 'kotlinx.coroutines.assert' call
      tmp = proposedUpdate;
    } else {
      if (!get_isCancellableMode(resumeMode) ? idempotent == null : false) {
        tmp = proposedUpdate;
      } else {
        var tmp_0;
        var tmp_1;
        if (!(onCancellation == null)) {
          tmp_1 = true;
        } else {
          tmp_1 = state instanceof CancelHandler;
        }
        if (tmp_1) {
          tmp_0 = true;
        } else {
          tmp_0 = !(idempotent == null);
        }
        if (tmp_0) {
          tmp = new CompletedContinuation(proposedUpdate, state instanceof CancelHandler ? state : null, onCancellation, idempotent);
        } else {
          tmp = proposedUpdate;
        }
      }
    }
    return tmp;
  }
  function resumeImpl($this, proposedUpdate, resumeMode, onCancellation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.ss_1;
    while (true) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.resumeImpl.<anonymous>' call
        var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
        var tmp0_subject = tmp1__anonymous__uwfjfc;
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
          var update = resumedState($this, tmp1__anonymous__uwfjfc, proposedUpdate, resumeMode, onCancellation, null);
          if (!$this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update)) {
            tmp$ret$0 = Unit_getInstance();
            break $l$block;
          }
          detachChildIfNonResuable($this);
          dispatchResume($this, resumeMode);
          return Unit_getInstance();
        } else {
          if (tmp0_subject instanceof CancelledContinuation) {
            if (tmp1__anonymous__uwfjfc.qt()) {
              var tmp1_safe_receiver = onCancellation;
              if (tmp1_safe_receiver == null)
                null;
              else {
                var tmp$ret$1;
                // Inline function 'kotlin.let' call
                // Inline function 'kotlin.contracts.contract' call
                $this.mt(tmp1_safe_receiver, tmp1__anonymous__uwfjfc.zo_1);
                tmp$ret$1 = Unit_getInstance();
              }
              return Unit_getInstance();
            }
          }
        }
        alreadyResumedError($this, proposedUpdate);
      }
    }
  }
  function resumeImpl$default($this, proposedUpdate, resumeMode, onCancellation, $super) {
    onCancellation = onCancellation === VOID ? null : onCancellation;
    return resumeImpl($this, proposedUpdate, resumeMode, onCancellation);
  }
  function tryResumeImpl($this, proposedUpdate, idempotent, onCancellation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = $this.ss_1;
    while (true) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.tryResumeImpl.<anonymous>' call
        var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
        var tmp0_subject = tmp1__anonymous__uwfjfc;
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
          var update = resumedState($this, tmp1__anonymous__uwfjfc, proposedUpdate, $this.ws_1, onCancellation, idempotent);
          if (!$this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update)) {
            tmp$ret$0 = Unit_getInstance();
            break $l$block;
          }
          detachChildIfNonResuable($this);
          return get_RESUME_TOKEN();
        } else {
          if (tmp0_subject instanceof CompletedContinuation) {
            var tmp;
            if (!(idempotent == null) ? tmp1__anonymous__uwfjfc.gt_1 === idempotent : false) {
              // Inline function 'kotlinx.coroutines.assert' call
              tmp = get_RESUME_TOKEN();
            } else {
              tmp = null;
            }
            return tmp;
          } else {
            return null;
          }
        }
      }
    }
  }
  function alreadyResumedError($this, proposedUpdate) {
    // Inline function 'kotlin.error' call
    var tmp0_error = 'Already resumed, but proposed with update ' + toString_0(proposedUpdate);
    throw IllegalStateException_init_$Create$(toString(tmp0_error));
  }
  function detachChildIfNonResuable($this) {
    if (!isReusable($this)) {
      $this.rt();
    }
  }
  function CancellableContinuationImpl(delegate, resumeMode) {
    DispatchedTask.call(this, resumeMode);
    this.ps_1 = delegate;
    // Inline function 'kotlinx.coroutines.assert' call
    this.qs_1 = this.ps_1.s5();
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    var tmp0_decisionAndIndex = 0;
    var tmp1_decisionAndIndex = 536870911;
    tmp$ret$0 = (tmp0_decisionAndIndex << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + tmp1_decisionAndIndex | 0;
    tmp.rs_1 = atomic$int$1(tmp$ret$0);
    this.ss_1 = atomic$ref$1(Active_getInstance());
    this.ts_1 = atomic$ref$1(null);
  }
  protoOf(CancellableContinuationImpl).st = function () {
    return this.ps_1;
  };
  protoOf(CancellableContinuationImpl).s5 = function () {
    return this.qs_1;
  };
  protoOf(CancellableContinuationImpl).op = function () {
    return this.ss_1.kotlinx$atomicfu$value;
  };
  protoOf(CancellableContinuationImpl).pp = function () {
    var tmp = this.op();
    return !(!(tmp == null) ? isInterface(tmp, NotCompleted) : false);
  };
  protoOf(CancellableContinuationImpl).tt = function () {
    var tmp0_elvis_lhs = installParentHandle(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    if (this.pp()) {
      handle.gs();
      this.ts_1.kotlinx$atomicfu$value = NonDisposableHandle_getInstance();
    }
  };
  protoOf(CancellableContinuationImpl).us = function () {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    var state = this.ss_1.kotlinx$atomicfu$value;
    // Inline function 'kotlinx.coroutines.assert' call
    var tmp;
    if (state instanceof CompletedContinuation) {
      tmp = !(state.gt_1 == null);
    } else {
      tmp = false;
    }
    if (tmp) {
      this.rt();
      return false;
    }
    var tmp_0 = this.rs_1;
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.decisionAndIndex' call
    var tmp0_decisionAndIndex = 0;
    var tmp1_decisionAndIndex = 536870911;
    tmp$ret$0 = (tmp0_decisionAndIndex << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + tmp1_decisionAndIndex | 0;
    tmp_0.kotlinx$atomicfu$value = tmp$ret$0;
    this.ss_1.kotlinx$atomicfu$value = Active_getInstance();
    return true;
  };
  protoOf(CancellableContinuationImpl).ut = function () {
    return this.op();
  };
  protoOf(CancellableContinuationImpl).vt = function (takenState, cause) {
    var tmp0_loop = this.ss_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, NotCompleted) : false) {
        // Inline function 'kotlin.error' call
        throw IllegalStateException_init_$Create$('Not completed');
      } else {
        if (tmp0_subject instanceof CompletedExceptionally)
          return Unit_getInstance();
        else {
          if (tmp0_subject instanceof CompletedContinuation) {
            // Inline function 'kotlin.check' call
            var tmp0_check = !tmp1__anonymous__uwfjfc.jt();
            // Inline function 'kotlin.contracts.contract' call
            if (!tmp0_check) {
              var tmp$ret$0;
              // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancelCompletedResult.<anonymous>.<anonymous>' call
              tmp$ret$0 = 'Must be called at most once';
              var message = tmp$ret$0;
              throw IllegalStateException_init_$Create$(toString(message));
            }
            var update = tmp1__anonymous__uwfjfc.kt(VOID, VOID, VOID, VOID, cause);
            if (this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update)) {
              tmp1__anonymous__uwfjfc.wt(this, cause);
              return Unit_getInstance();
            }
          } else {
            if (this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, new CompletedContinuation(tmp1__anonymous__uwfjfc, VOID, VOID, VOID, cause))) {
              return Unit_getInstance();
            }
          }
        }
      }
    }
    return Unit_getInstance();
  };
  protoOf(CancellableContinuationImpl).xt = function (cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.ss_1;
    while (true) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.cancel.<anonymous>' call
        var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
        if (!(!(tmp1__anonymous__uwfjfc == null) ? isInterface(tmp1__anonymous__uwfjfc, NotCompleted) : false))
          return false;
        var tmp;
        if (tmp1__anonymous__uwfjfc instanceof CancelHandler) {
          tmp = true;
        } else {
          tmp = tmp1__anonymous__uwfjfc instanceof Segment;
        }
        var update = new CancelledContinuation(this, cause, tmp);
        if (!this.ss_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, update)) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        }
        var tmp0_subject = tmp1__anonymous__uwfjfc;
        if (tmp0_subject instanceof CancelHandler) {
          this.it(tmp1__anonymous__uwfjfc, cause);
        } else {
          if (tmp0_subject instanceof Segment) {
            callSegmentOnCancellation(this, tmp1__anonymous__uwfjfc, cause);
          }
        }
        detachChildIfNonResuable(this);
        dispatchResume(this, this.ws_1);
        return true;
      }
    }
  };
  protoOf(CancellableContinuationImpl).yt = function (cause) {
    if (cancelLater(this, cause))
      return Unit_getInstance();
    this.xt(cause);
    detachChildIfNonResuable(this);
  };
  protoOf(CancellableContinuationImpl).it = function (handler, cause) {
    var tmp;
    try {
      handler.invoke(cause);
      tmp = Unit_getInstance();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.s5(), new CompletionHandlerException('Exception in invokeOnCancellation handler for ' + this, ex));
        tmp_0 = Unit_getInstance();
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(CancellableContinuationImpl).mt = function (onCancellation, cause) {
    try {
      onCancellation(cause);
    } catch ($p) {
      if ($p instanceof Error) {
        var ex = $p;
        handleCoroutineException(this.s5(), new CompletionHandlerException('Exception in resume onCancellation handler for ' + this, ex));
      } else {
        throw $p;
      }
    }
  };
  protoOf(CancellableContinuationImpl).zt = function (parent) {
    return parent.sp();
  };
  protoOf(CancellableContinuationImpl).vq = function () {
    var isReusable_0 = isReusable(this);
    if (trySuspend_0(this)) {
      if (_get_parentHandle__f8dcex(this) == null) {
        installParentHandle(this);
      }
      if (isReusable_0) {
        this.au();
      }
      return get_COROUTINE_SUSPENDED();
    }
    if (isReusable_0) {
      this.au();
    }
    var state = this.op();
    if (state instanceof CompletedExceptionally)
      throw recoverStackTrace(state.zo_1, this);
    if (get_isCancellableMode(this.ws_1)) {
      var job = this.s5().o8(Key_getInstance_2());
      if (!(job == null) ? !job.uo() : false) {
        var cause = job.sp();
        this.vt(state, cause);
        throw recoverStackTrace(cause, this);
      }
    }
    return this.bu(state);
  };
  protoOf(CancellableContinuationImpl).au = function () {
    var tmp = this.ps_1;
    var tmp0_safe_receiver = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.cu(this);
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var cancellationCause = tmp_0;
    this.rt();
    this.xt(cancellationCause);
  };
  protoOf(CancellableContinuationImpl).q5 = function (result) {
    return resumeImpl$default(this, toState(result, this), this.ws_1);
  };
  protoOf(CancellableContinuationImpl).ds = function (value, onCancellation) {
    return resumeImpl(this, value, this.ws_1, onCancellation);
  };
  protoOf(CancellableContinuationImpl).du = function (segment, index) {
    var tmp$ret$5;
    $l$block: {
      // Inline function 'kotlinx.atomicfu.update' call
      var tmp0_update = this.rs_1;
      while (true) {
        var cur = tmp0_update.kotlinx$atomicfu$value;
        var tmp$ret$4;
        // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>' call
        // Inline function 'kotlin.check' call
        var tmp$ret$0;
        // Inline function 'kotlinx.coroutines.index' call
        tmp$ret$0 = cur & 536870911;
        var tmp0_check = tmp$ret$0 === 536870911;
        // Inline function 'kotlin.contracts.contract' call
        if (!tmp0_check) {
          var tmp$ret$1;
          // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.invokeOnCancellation.<anonymous>.<anonymous>' call
          tmp$ret$1 = 'invokeOnCancellation should be called at most once';
          var message = tmp$ret$1;
          throw IllegalStateException_init_$Create$(toString(message));
        }
        var tmp$ret$3;
        // Inline function 'kotlinx.coroutines.decisionAndIndex' call
        var tmp$ret$2;
        // Inline function 'kotlinx.coroutines.decision' call
        tmp$ret$2 = cur >> _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i();
        var tmp1_decisionAndIndex = tmp$ret$2;
        tmp$ret$3 = (tmp1_decisionAndIndex << _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i()) + index | 0;
        tmp$ret$4 = tmp$ret$3;
        var upd = tmp$ret$4;
        if (tmp0_update.atomicfu$compareAndSet(cur, upd)) {
          tmp$ret$5 = Unit_getInstance();
          break $l$block;
        }
      }
    }
    invokeOnCancellationImpl(this, segment);
  };
  protoOf(CancellableContinuationImpl).bs = function (handler) {
    var cancelHandler = makeCancelHandler(this, handler);
    invokeOnCancellationImpl(this, cancelHandler);
  };
  protoOf(CancellableContinuationImpl).rt = function () {
    var tmp0_elvis_lhs = _get_parentHandle__f8dcex(this);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return Unit_getInstance();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var handle = tmp;
    handle.gs();
    this.ts_1.kotlinx$atomicfu$value = NonDisposableHandle_getInstance();
  };
  protoOf(CancellableContinuationImpl).zr = function (value, idempotent, onCancellation) {
    return tryResumeImpl(this, value, idempotent, onCancellation);
  };
  protoOf(CancellableContinuationImpl).as = function (token) {
    // Inline function 'kotlinx.coroutines.assert' call
    dispatchResume(this, this.ws_1);
  };
  protoOf(CancellableContinuationImpl).cs = function (_this__u8e3s4, value) {
    var tmp = this.ps_1;
    var dc = tmp instanceof DispatchedContinuation ? tmp : null;
    var tmp_0;
    var tmp0_safe_receiver = dc;
    if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.is_1) === _this__u8e3s4) {
      tmp_0 = get_MODE_UNDISPATCHED();
    } else {
      tmp_0 = this.ws_1;
    }
    resumeImpl$default(this, value, tmp_0);
  };
  protoOf(CancellableContinuationImpl).bu = function (state) {
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof CompletedContinuation) {
      var tmp_0 = state.dt_1;
      tmp = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
    } else {
      tmp = (state == null ? true : isObject(state)) ? state : THROW_CCE();
    }
    return tmp;
  };
  protoOf(CancellableContinuationImpl).eu = function (state) {
    var tmp0_safe_receiver = protoOf(DispatchedTask).eu.call(this, state);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.CancellableContinuationImpl.getExceptionalResult.<anonymous>' call
      tmp$ret$0 = recoverStackTrace(tmp0_safe_receiver, this.ps_1);
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    return tmp;
  };
  protoOf(CancellableContinuationImpl).toString = function () {
    return this.gp() + '(' + toDebugString(this.ps_1) + '){' + _get_stateDebugRepresentation__bf18u4(this) + '}@' + get_hexAddress(this);
  };
  protoOf(CancellableContinuationImpl).gp = function () {
    return 'CancellableContinuation';
  };
  function NotCompleted() {
  }
  function CancelHandler() {
    CancelHandlerBase.call(this);
  }
  function Active() {
    Active_instance = this;
  }
  protoOf(Active).toString = function () {
    return 'Active';
  };
  var Active_instance;
  function Active_getInstance() {
    if (Active_instance == null)
      new Active();
    return Active_instance;
  }
  function CompletedContinuation(result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    cancelHandler = cancelHandler === VOID ? null : cancelHandler;
    onCancellation = onCancellation === VOID ? null : onCancellation;
    idempotentResume = idempotentResume === VOID ? null : idempotentResume;
    cancelCause = cancelCause === VOID ? null : cancelCause;
    this.dt_1 = result;
    this.et_1 = cancelHandler;
    this.ft_1 = onCancellation;
    this.gt_1 = idempotentResume;
    this.ht_1 = cancelCause;
  }
  protoOf(CompletedContinuation).jt = function () {
    return !(this.ht_1 == null);
  };
  protoOf(CompletedContinuation).wt = function (cont, cause) {
    var tmp0_safe_receiver = this.et_1;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.it(tmp0_safe_receiver, cause);
      tmp$ret$0 = Unit_getInstance();
    }
    var tmp1_safe_receiver = this.ft_1;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      cont.mt(tmp1_safe_receiver, cause);
      tmp$ret$1 = Unit_getInstance();
    }
  };
  protoOf(CompletedContinuation).hu = function (result, cancelHandler, onCancellation, idempotentResume, cancelCause) {
    return new CompletedContinuation(result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  };
  protoOf(CompletedContinuation).kt = function (result, cancelHandler, onCancellation, idempotentResume, cancelCause, $super) {
    result = result === VOID ? this.dt_1 : result;
    cancelHandler = cancelHandler === VOID ? this.et_1 : cancelHandler;
    onCancellation = onCancellation === VOID ? this.ft_1 : onCancellation;
    idempotentResume = idempotentResume === VOID ? this.gt_1 : idempotentResume;
    cancelCause = cancelCause === VOID ? this.ht_1 : cancelCause;
    return $super === VOID ? this.hu(result, cancelHandler, onCancellation, idempotentResume, cancelCause) : $super.hu.call(this, result, cancelHandler, onCancellation, idempotentResume, cancelCause);
  };
  protoOf(CompletedContinuation).toString = function () {
    return 'CompletedContinuation(result=' + toString_0(this.dt_1) + ', cancelHandler=' + this.et_1 + ', onCancellation=' + this.ft_1 + ', idempotentResume=' + toString_0(this.gt_1) + ', cancelCause=' + this.ht_1 + ')';
  };
  protoOf(CompletedContinuation).hashCode = function () {
    var result = this.dt_1 == null ? 0 : hashCode(this.dt_1);
    result = imul(result, 31) + (this.et_1 == null ? 0 : hashCode(this.et_1)) | 0;
    result = imul(result, 31) + (this.ft_1 == null ? 0 : hashCode(this.ft_1)) | 0;
    result = imul(result, 31) + (this.gt_1 == null ? 0 : hashCode(this.gt_1)) | 0;
    result = imul(result, 31) + (this.ht_1 == null ? 0 : hashCode(this.ht_1)) | 0;
    return result;
  };
  protoOf(CompletedContinuation).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedContinuation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedContinuation ? other : THROW_CCE();
    if (!equals(this.dt_1, tmp0_other_with_cast.dt_1))
      return false;
    if (!equals(this.et_1, tmp0_other_with_cast.et_1))
      return false;
    if (!equals(this.ft_1, tmp0_other_with_cast.ft_1))
      return false;
    if (!equals(this.gt_1, tmp0_other_with_cast.gt_1))
      return false;
    if (!equals(this.ht_1, tmp0_other_with_cast.ht_1))
      return false;
    return true;
  };
  function InvokeOnCancel(handler) {
    CancelHandler.call(this);
    this.iu_1 = handler;
  }
  protoOf(InvokeOnCancel).fs = function (cause) {
    this.iu_1(cause);
  };
  protoOf(InvokeOnCancel).invoke = function (cause) {
    return this.fs(cause);
  };
  protoOf(InvokeOnCancel).toString = function () {
    return 'InvokeOnCancel[' + get_classSimpleName(this.iu_1) + '@' + get_hexAddress(this) + ']';
  };
  function _get_DECISION_SHIFT_$accessor$2jt7ek_1tkg2i() {
    _init_properties_CancellableContinuationImpl_kt__6rrtdd();
    return 29;
  }
  var properties_initialized_CancellableContinuationImpl_kt_xtzb03;
  function _init_properties_CancellableContinuationImpl_kt__6rrtdd() {
    if (properties_initialized_CancellableContinuationImpl_kt_xtzb03) {
    } else {
      properties_initialized_CancellableContinuationImpl_kt_xtzb03 = true;
      RESUME_TOKEN = new Symbol('RESUME_TOKEN');
    }
  }
  function CompletableDeferred(parent) {
    parent = parent === VOID ? null : parent;
    return new CompletableDeferredImpl(parent);
  }
  function completeWith(_this__u8e3s4, result) {
    var tmp$ret$2;
    // Inline function 'kotlin.fold' call
    // Inline function 'kotlin.contracts.contract' call
    var exception = Result__exceptionOrNull_impl_p6xea9(result);
    var tmp;
    if (exception == null) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.completeWith.<anonymous>' call
      var tmp_0 = _Result___get_value__impl__bjfvqg(result);
      var tmp0__anonymous__q1qw7t = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      tmp$ret$0 = _this__u8e3s4.ku(tmp0__anonymous__q1qw7t);
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.completeWith.<anonymous>' call
      tmp$ret$1 = _this__u8e3s4.ju(exception);
      tmp = tmp$ret$1;
    }
    tmp$ret$2 = tmp;
    return tmp$ret$2;
  }
  function $awaitCOROUTINE$1(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.tu_1 = _this__u8e3s4;
  }
  protoOf($awaitCOROUTINE$1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.tu_1.mr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return (suspendResult == null ? true : isObject(suspendResult)) ? suspendResult : THROW_CCE();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function CompletableDeferredImpl(parent) {
    JobSupport.call(this, true);
    this.po(parent);
  }
  protoOf(CompletableDeferredImpl).aq = function () {
    return true;
  };
  protoOf(CompletableDeferredImpl).qr = function ($completion) {
    var tmp = new $awaitCOROUTINE$1(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CompletableDeferredImpl).ku = function (value) {
    return this.wu(value);
  };
  protoOf(CompletableDeferredImpl).ju = function (exception) {
    return this.wu(new CompletedExceptionally(exception));
  };
  function CompletedExceptionally(cause, handled) {
    handled = handled === VOID ? false : handled;
    this.zo_1 = cause;
    this.ap_1 = atomic$boolean$1(handled);
  }
  protoOf(CompletedExceptionally).bp = function () {
    return this.ap_1.kotlinx$atomicfu$value;
  };
  protoOf(CompletedExceptionally).lt = function () {
    return this.ap_1.atomicfu$compareAndSet(false, true);
  };
  protoOf(CompletedExceptionally).toString = function () {
    return get_classSimpleName(this) + '[' + this.zo_1 + ']';
  };
  function CancelledContinuation(continuation, cause, handled) {
    var tmp0_elvis_lhs = cause;
    CompletedExceptionally.call(this, tmp0_elvis_lhs == null ? CancellationException_init_$Create$('Continuation ' + continuation + ' was cancelled normally') : tmp0_elvis_lhs, handled);
    this.pt_1 = atomic$boolean$1(false);
  }
  protoOf(CancelledContinuation).qt = function () {
    return this.pt_1.atomicfu$compareAndSet(false, true);
  };
  function toState(_this__u8e3s4, caller) {
    var tmp$ret$2;
    // Inline function 'kotlin.fold' call
    // Inline function 'kotlin.contracts.contract' call
    var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
    var tmp;
    if (exception == null) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
      var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
      var tmp0__anonymous__q1qw7t = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      tmp$ret$0 = tmp0__anonymous__q1qw7t;
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
      tmp$ret$1 = new CompletedExceptionally(recoverStackTrace(exception, caller));
      tmp = tmp$ret$1;
    }
    tmp$ret$2 = tmp;
    return tmp$ret$2;
  }
  function toState_0(_this__u8e3s4, onCancellation) {
    onCancellation = onCancellation === VOID ? null : onCancellation;
    var tmp$ret$2;
    // Inline function 'kotlin.fold' call
    // Inline function 'kotlin.contracts.contract' call
    var exception = Result__exceptionOrNull_impl_p6xea9(_this__u8e3s4);
    var tmp;
    if (exception == null) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
      var tmp_0 = _Result___get_value__impl__bjfvqg(_this__u8e3s4);
      var tmp0__anonymous__q1qw7t = (tmp_0 == null ? true : isObject(tmp_0)) ? tmp_0 : THROW_CCE();
      tmp$ret$0 = !(onCancellation == null) ? new CompletedWithCancellation(tmp0__anonymous__q1qw7t, onCancellation) : tmp0__anonymous__q1qw7t;
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.toState.<anonymous>' call
      tmp$ret$1 = new CompletedExceptionally(exception);
      tmp = tmp$ret$1;
    }
    tmp$ret$2 = tmp;
    return tmp$ret$2;
  }
  function CompletedWithCancellation(result, onCancellation) {
    this.yu_1 = result;
    this.zu_1 = onCancellation;
  }
  protoOf(CompletedWithCancellation).toString = function () {
    return 'CompletedWithCancellation(result=' + toString_0(this.yu_1) + ', onCancellation=' + this.zu_1 + ')';
  };
  protoOf(CompletedWithCancellation).hashCode = function () {
    var result = this.yu_1 == null ? 0 : hashCode(this.yu_1);
    result = imul(result, 31) + hashCode(this.zu_1) | 0;
    return result;
  };
  protoOf(CompletedWithCancellation).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedWithCancellation))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedWithCancellation ? other : THROW_CCE();
    if (!equals(this.yu_1, tmp0_other_with_cast.yu_1))
      return false;
    if (!equals(this.zu_1, tmp0_other_with_cast.zu_1))
      return false;
    return true;
  };
  function recoverResult(state, uCont) {
    var tmp;
    if (state instanceof CompletedExceptionally) {
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.failure' call
      var tmp0_failure = Companion_getInstance();
      var tmp1_failure = recoverStackTrace(state.zo_1, uCont);
      tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_failure));
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.Companion.success' call
      var tmp2_success = Companion_getInstance();
      var tmp3_success = (state == null ? true : isObject(state)) ? state : THROW_CCE();
      tmp$ret$1 = _Result___init__impl__xyqfz8(tmp3_success);
      tmp = tmp$ret$1;
    }
    return tmp;
  }
  function CoroutineDispatcher$Key$_init_$lambda_akl8b5(it) {
    return it instanceof CoroutineDispatcher ? it : null;
  }
  function Key() {
    Key_instance = this;
    var tmp = Key_getInstance();
    AbstractCoroutineContextKey.call(this, tmp, CoroutineDispatcher$Key$_init_$lambda_akl8b5);
  }
  var Key_instance;
  function Key_getInstance_0() {
    if (Key_instance == null)
      new Key();
    return Key_instance;
  }
  function CoroutineDispatcher() {
    Key_getInstance_0();
    AbstractCoroutineContextElement.call(this, Key_getInstance());
  }
  protoOf(CoroutineDispatcher).bv = function (context) {
    return true;
  };
  protoOf(CoroutineDispatcher).m8 = function (continuation) {
    return new DispatchedContinuation(this, continuation);
  };
  protoOf(CoroutineDispatcher).n8 = function (continuation) {
    var dispatched = continuation instanceof DispatchedContinuation ? continuation : THROW_CCE();
    dispatched.dv();
  };
  protoOf(CoroutineDispatcher).toString = function () {
    return get_classSimpleName(this) + '@' + get_hexAddress(this);
  };
  function handleCoroutineException(context, exception) {
    try {
      var tmp0_safe_receiver = context.o8(Key_getInstance_1());
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$0;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp0_safe_receiver.ev(context, exception);
        return Unit_getInstance();
      }
    } catch ($p) {
      if ($p instanceof Error) {
        var t = $p;
        handleUncaughtCoroutineException(context, handlerException(exception, t));
        return Unit_getInstance();
      } else {
        throw $p;
      }
    }
    handleUncaughtCoroutineException(context, exception);
  }
  function Key_0() {
    Key_instance_0 = this;
  }
  var Key_instance_0;
  function Key_getInstance_1() {
    if (Key_instance_0 == null)
      new Key_0();
    return Key_instance_0;
  }
  function handlerException(originalException, thrownException) {
    if (originalException === thrownException)
      return originalException;
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = RuntimeException_init_$Create$('Exception while trying to handle coroutine exception', thrownException);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.handlerException.<anonymous>' call
    // Inline function 'kotlinx.coroutines.addSuppressedThrowable' call
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function CoroutineScope() {
  }
  function CoroutineScope_0(context) {
    return new ContextScope(!(context.o8(Key_getInstance_2()) == null) ? context : context.v8(Job_0()));
  }
  function coroutineScope(block, $completion) {
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.coroutineScope.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var coroutine = new ScopeCoroutine(tmp0__anonymous__q1qw7t.s5(), tmp0__anonymous__q1qw7t);
    tmp$ret$0 = startUndispatchedOrReturn(coroutine, coroutine, block);
    var tmp0 = tmp$ret$0;
    return tmp0;
  }
  function MainScope() {
    return new ContextScope(SupervisorJob().v8(Dispatchers_getInstance().jv()));
  }
  function GlobalScope() {
    GlobalScope_instance = this;
  }
  protoOf(GlobalScope).to = function () {
    return EmptyCoroutineContext_getInstance();
  };
  var GlobalScope_instance;
  function GlobalScope_getInstance() {
    if (GlobalScope_instance == null)
      new GlobalScope();
    return GlobalScope_instance;
  }
  var CoroutineStart_DEFAULT_instance;
  var CoroutineStart_LAZY_instance;
  var CoroutineStart_ATOMIC_instance;
  var CoroutineStart_UNDISPATCHED_instance;
  var CoroutineStart_entriesInitialized;
  function CoroutineStart_initEntries() {
    if (CoroutineStart_entriesInitialized)
      return Unit_getInstance();
    CoroutineStart_entriesInitialized = true;
    CoroutineStart_DEFAULT_instance = new CoroutineStart('DEFAULT', 0);
    CoroutineStart_LAZY_instance = new CoroutineStart('LAZY', 1);
    CoroutineStart_ATOMIC_instance = new CoroutineStart('ATOMIC', 2);
    CoroutineStart_UNDISPATCHED_instance = new CoroutineStart('UNDISPATCHED', 3);
  }
  function CoroutineStart(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  protoOf(CoroutineStart).kp = function (block, receiver, completion) {
    var tmp0_subject = this;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        startCoroutineCancellable(block, receiver, completion);
        tmp = Unit_getInstance();
        break;
      case 2:
        startCoroutine(block, receiver, completion);
        tmp = Unit_getInstance();
        break;
      case 3:
        startCoroutineUndispatched(block, receiver, completion);
        tmp = Unit_getInstance();
        break;
      case 1:
        tmp = Unit_getInstance();
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(CoroutineStart).pq = function () {
    return this === CoroutineStart_LAZY_getInstance();
  };
  function CoroutineStart_DEFAULT_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_DEFAULT_instance;
  }
  function CoroutineStart_LAZY_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_LAZY_instance;
  }
  function CoroutineStart_UNDISPATCHED_getInstance() {
    CoroutineStart_initEntries();
    return CoroutineStart_UNDISPATCHED_instance;
  }
  function Deferred() {
  }
  function Delay() {
  }
  function get_delay(_this__u8e3s4) {
    var tmp = _this__u8e3s4.o8(Key_getInstance());
    var tmp0_elvis_lhs = (!(tmp == null) ? isInterface(tmp, Delay) : false) ? tmp : null;
    return tmp0_elvis_lhs == null ? get_DefaultDelay() : tmp0_elvis_lhs;
  }
  function delay(timeMillis, $completion) {
    if (timeMillis.k1(new Long(0, 0)) <= 0)
      return Unit_getInstance();
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'kotlinx.coroutines.delay.<anonymous>' call
    Companion_getInstance_0();
    if (timeMillis.k1(new Long(-1, 2147483647)) < 0) {
      get_delay(cancellable.s5()).kv(timeMillis, cancellable);
    }
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  }
  function DelayWithTimeoutDiagnostics() {
  }
  function delta($this, unconfined) {
    return unconfined ? new Long(0, 1) : new Long(1, 0);
  }
  function EventLoop() {
    CoroutineDispatcher.call(this);
    this.ov_1 = new Long(0, 0);
    this.pv_1 = false;
    this.qv_1 = null;
  }
  protoOf(EventLoop).rv = function () {
    var tmp0_elvis_lhs = this.qv_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    var tmp1_elvis_lhs = queue.x3();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var task = tmp_0;
    task.fu();
    return true;
  };
  protoOf(EventLoop).sv = function (task) {
    var tmp0_elvis_lhs = this.qv_1;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'kotlin.also' call
      var tmp0_also = ArrayDeque_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.EventLoop.dispatchUnconfined.<anonymous>' call
      this.qv_1 = tmp0_also;
      tmp$ret$0 = tmp0_also;
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var queue = tmp;
    queue.v3(task);
  };
  protoOf(EventLoop).tv = function () {
    return this.ov_1.k1(delta(this, true)) >= 0;
  };
  protoOf(EventLoop).uv = function () {
    var tmp0_safe_receiver = this.qv_1;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.p();
    return tmp1_elvis_lhs == null ? true : tmp1_elvis_lhs;
  };
  protoOf(EventLoop).vv = function (unconfined) {
    var tmp0_this = this;
    tmp0_this.ov_1 = tmp0_this.ov_1.b9(delta(this, unconfined));
    if (!unconfined)
      this.pv_1 = true;
  };
  protoOf(EventLoop).wv = function (unconfined) {
    var tmp0_this = this;
    tmp0_this.ov_1 = tmp0_this.ov_1.l1(delta(this, unconfined));
    if (this.ov_1.k1(new Long(0, 0)) > 0)
      return Unit_getInstance();
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.pv_1) {
      this.xv();
    }
  };
  protoOf(EventLoop).xv = function () {
  };
  function ThreadLocalEventLoop() {
    ThreadLocalEventLoop_instance = this;
    this.yv_1 = commonThreadLocal(new Symbol('ThreadLocalEventLoop'));
  }
  protoOf(ThreadLocalEventLoop).zv = function () {
    var tmp0_elvis_lhs = this.yv_1.bw();
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'kotlin.also' call
      var tmp0_also = createEventLoop();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.ThreadLocalEventLoop.<get-eventLoop>.<anonymous>' call
      ThreadLocalEventLoop_getInstance().yv_1.cw(tmp0_also);
      tmp$ret$0 = tmp0_also;
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  var ThreadLocalEventLoop_instance;
  function ThreadLocalEventLoop_getInstance() {
    if (ThreadLocalEventLoop_instance == null)
      new ThreadLocalEventLoop();
    return ThreadLocalEventLoop_instance;
  }
  function CompletionHandlerException(message, cause) {
    RuntimeException_init_$Init$(message, cause, this);
    captureStack(this, CompletionHandlerException);
  }
  function CoroutinesInternalError(message, cause) {
    Error_init_$Init$(message, cause, this);
    captureStack(this, CoroutinesInternalError);
  }
  function Key_1() {
    Key_instance_1 = this;
  }
  var Key_instance_1;
  function Key_getInstance_2() {
    if (Key_instance_1 == null)
      new Key_1();
    return Key_instance_1;
  }
  function Job() {
  }
  function ParentJob() {
  }
  function ChildHandle() {
  }
  function NonDisposableHandle() {
    NonDisposableHandle_instance = this;
  }
  protoOf(NonDisposableHandle).np = function () {
    return null;
  };
  protoOf(NonDisposableHandle).gs = function () {
  };
  protoOf(NonDisposableHandle).fq = function (cause) {
    return false;
  };
  protoOf(NonDisposableHandle).toString = function () {
    return 'NonDisposableHandle';
  };
  var NonDisposableHandle_instance;
  function NonDisposableHandle_getInstance() {
    if (NonDisposableHandle_instance == null)
      new NonDisposableHandle();
    return NonDisposableHandle_instance;
  }
  function ensureActive(_this__u8e3s4) {
    var tmp0_safe_receiver = _this__u8e3s4.o8(Key_getInstance_2());
    if (tmp0_safe_receiver == null)
      null;
    else {
      ensureActive_0(tmp0_safe_receiver);
    }
  }
  function ensureActive_0(_this__u8e3s4) {
    if (!_this__u8e3s4.uo())
      throw _this__u8e3s4.sp();
  }
  function Job_0(parent) {
    parent = parent === VOID ? null : parent;
    return new JobImpl(parent);
  }
  function cancel(_this__u8e3s4, cause) {
    cause = cause === VOID ? null : cause;
    var tmp0_safe_receiver = _this__u8e3s4.o8(Key_getInstance_2());
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.bq(cause);
    }
  }
  function cancelChildren(_this__u8e3s4, cause) {
    cause = cause === VOID ? null : cause;
    var tmp0_safe_receiver = _this__u8e3s4.o8(Key_getInstance_2());
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.iq();
    if (tmp1_safe_receiver == null)
      null;
    else {
      // Inline function 'kotlin.sequences.forEach' call
      var tmp0_iterator = tmp1_safe_receiver.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        // Inline function 'kotlinx.coroutines.cancelChildren.<anonymous>' call
        element.bq(cause);
      }
    }
  }
  function cancelAndJoin(_this__u8e3s4, $completion) {
    _this__u8e3s4.cq();
    var tmp0 = _this__u8e3s4.yp($completion);
    return tmp0;
  }
  function disposeOnCompletion(_this__u8e3s4, handle) {
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new DisposeOnCompletion(handle);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    return _this__u8e3s4.vp(tmp$ret$1);
  }
  function get_COMPLETING_ALREADY() {
    _init_properties_JobSupport_kt__68f172();
    return COMPLETING_ALREADY;
  }
  var COMPLETING_ALREADY;
  function get_COMPLETING_WAITING_CHILDREN() {
    _init_properties_JobSupport_kt__68f172();
    return COMPLETING_WAITING_CHILDREN;
  }
  var COMPLETING_WAITING_CHILDREN;
  function get_COMPLETING_RETRY() {
    _init_properties_JobSupport_kt__68f172();
    return COMPLETING_RETRY;
  }
  var COMPLETING_RETRY;
  function get_TOO_LATE_TO_CANCEL() {
    _init_properties_JobSupport_kt__68f172();
    return TOO_LATE_TO_CANCEL;
  }
  var TOO_LATE_TO_CANCEL;
  function get_SEALED() {
    _init_properties_JobSupport_kt__68f172();
    return SEALED;
  }
  var SEALED;
  function get_EMPTY_NEW() {
    _init_properties_JobSupport_kt__68f172();
    return EMPTY_NEW;
  }
  var EMPTY_NEW;
  function get_EMPTY_ACTIVE() {
    _init_properties_JobSupport_kt__68f172();
    return EMPTY_ACTIVE;
  }
  var EMPTY_ACTIVE;
  function Empty(isActive) {
    this.dw_1 = isActive;
  }
  protoOf(Empty).uo = function () {
    return this.dw_1;
  };
  protoOf(Empty).ew = function () {
    return null;
  };
  protoOf(Empty).toString = function () {
    return 'Empty{' + (this.dw_1 ? 'Active' : 'New') + '}';
  };
  function Incomplete() {
  }
  function NodeList() {
    LinkedListHead.call(this);
  }
  protoOf(NodeList).uo = function () {
    return true;
  };
  protoOf(NodeList).ew = function () {
    return this;
  };
  protoOf(NodeList).iw = function (state) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>' call
    tmp0_apply.bc('List{');
    tmp0_apply.bc(state);
    tmp0_apply.bc('}[');
    var first = true;
    // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
    var cur = this.jw_1;
    while (!equals(cur, this)) {
      if (cur instanceof JobNode) {
        // Inline function 'kotlinx.coroutines.NodeList.getString.<anonymous>.<anonymous>' call
        var tmp0__anonymous__q1qw7t = cur;
        if (first)
          first = false;
        else {
          tmp0_apply.bc(', ');
        }
        tmp0_apply.ac(tmp0__anonymous__q1qw7t);
      }
      cur = cur.jw_1;
    }
    tmp0_apply.bc(']');
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  protoOf(NodeList).toString = function () {
    return get_DEBUG() ? this.iw('Active') : protoOf(LinkedListHead).toString.call(this);
  };
  function JobNode() {
    CompletionHandlerBase.call(this);
  }
  protoOf(JobNode).ww = function () {
    var tmp = this.vw_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('job');
    }
  };
  protoOf(JobNode).uo = function () {
    return true;
  };
  protoOf(JobNode).ew = function () {
    return null;
  };
  protoOf(JobNode).gs = function () {
    return this.ww().zp(this);
  };
  protoOf(JobNode).toString = function () {
    return get_classSimpleName(this) + '@' + get_hexAddress(this) + '[job@' + get_hexAddress(this.ww()) + ']';
  };
  function _set_exceptionsHolder__tqm22h($this, value) {
    $this.bx_1.kotlinx$atomicfu$value = value;
  }
  function _get_exceptionsHolder__nhszp($this) {
    return $this.bx_1.kotlinx$atomicfu$value;
  }
  function allocateList($this) {
    return ArrayList_init_$Create$(4);
  }
  function finalizeFinishingState($this, state, proposedUpdate) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    var tmp0_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
    var proposedException = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.zo_1;
    var wasCancelling;
    var tmp$ret$2;
    // Inline function 'kotlinx.coroutines.internal.synchronized' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.JobSupport.finalizeFinishingState.<anonymous>' call
    wasCancelling = state.cx();
    var exceptions = state.dx(proposedException);
    var finalCause = getFinalRootCause($this, state, exceptions);
    if (!(finalCause == null)) {
      addSuppressedExceptions($this, finalCause, exceptions);
    }
    tmp$ret$0 = finalCause;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var finalException = tmp$ret$2;
    var finalState = finalException == null ? proposedUpdate : finalException === proposedException ? proposedUpdate : new CompletedExceptionally(finalException);
    if (!(finalException == null)) {
      var handled = cancelParent($this, finalException) ? true : $this.nq(finalException);
      if (handled) {
        (finalState instanceof CompletedExceptionally ? finalState : THROW_CCE()).lt();
      }
    }
    if (!wasCancelling) {
      $this.kq(finalException);
    }
    $this.yo(finalState);
    var casSuccess = $this.no_1.atomicfu$compareAndSet(state, boxIncomplete(finalState));
    // Inline function 'kotlinx.coroutines.assert' call
    completeStateFinalization($this, state, finalState);
    return finalState;
  }
  function getFinalRootCause($this, state, exceptions) {
    if (exceptions.p()) {
      if (state.cx()) {
        var tmp$ret$0;
        // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
        var tmp0_elvis_lhs = null;
        tmp$ret$0 = new JobCancellationException(tmp0_elvis_lhs == null ? $this.xo() : tmp0_elvis_lhs, null, $this);
        return tmp$ret$0;
      }
      return null;
    }
    var tmp$ret$2;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_iterator = exceptions.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
        tmp$ret$1 = !(element instanceof CancellationException);
        if (tmp$ret$1) {
          tmp$ret$2 = element;
          break $l$block;
        }
      }
      tmp$ret$2 = null;
    }
    var firstNonCancellation = tmp$ret$2;
    if (!(firstNonCancellation == null))
      return firstNonCancellation;
    var first = exceptions.n(0);
    if (first instanceof TimeoutCancellationException) {
      var tmp$ret$4;
      $l$block_0: {
        // Inline function 'kotlin.collections.firstOrNull' call
        var tmp0_iterator_0 = exceptions.h();
        while (tmp0_iterator_0.j()) {
          var element_0 = tmp0_iterator_0.k();
          var tmp$ret$3;
          // Inline function 'kotlinx.coroutines.JobSupport.getFinalRootCause.<anonymous>' call
          var tmp;
          if (!(element_0 === first)) {
            tmp = element_0 instanceof TimeoutCancellationException;
          } else {
            tmp = false;
          }
          tmp$ret$3 = tmp;
          if (tmp$ret$3) {
            tmp$ret$4 = element_0;
            break $l$block_0;
          }
        }
        tmp$ret$4 = null;
      }
      var detailedTimeoutException = tmp$ret$4;
      if (!(detailedTimeoutException == null))
        return detailedTimeoutException;
    }
    return first;
  }
  function addSuppressedExceptions($this, rootCause, exceptions) {
    if (exceptions.l() <= 1)
      return Unit_getInstance();
    var seenExceptions = identitySet(exceptions.l());
    var unwrappedCause = unwrap(rootCause);
    var tmp0_iterator = exceptions.h();
    while (tmp0_iterator.j()) {
      var exception = tmp0_iterator.k();
      var unwrapped = unwrap(exception);
      var tmp;
      var tmp_0;
      if (!(unwrapped === rootCause) ? !(unwrapped === unwrappedCause) : false) {
        tmp_0 = !(unwrapped instanceof CancellationException);
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = seenExceptions.b(unwrapped);
      } else {
        tmp = false;
      }
      if (tmp) {
        // Inline function 'kotlinx.coroutines.addSuppressedThrowable' call
      }
    }
  }
  function tryFinalizeSimpleState($this, state, update) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    if (!$this.no_1.atomicfu$compareAndSet(state, boxIncomplete(update)))
      return false;
    $this.kq(null);
    $this.yo(update);
    completeStateFinalization($this, state, update);
    return true;
  }
  function completeStateFinalization($this, state, update) {
    var tmp0_safe_receiver = $this.mp();
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      tmp0_safe_receiver.gs();
      $this.lp(NonDisposableHandle_getInstance());
      tmp$ret$0 = Unit_getInstance();
    }
    var tmp1_safe_receiver = update instanceof CompletedExceptionally ? update : null;
    var cause = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.zo_1;
    if (state instanceof JobNode) {
      try {
        state.invoke(cause);
      } catch ($p) {
        if ($p instanceof Error) {
          var ex = $p;
          $this.fp(new CompletionHandlerException('Exception in completion handler ' + state + ' for ' + $this, ex));
        } else {
          throw $p;
        }
      }
    } else {
      var tmp2_safe_receiver = state.ew();
      if (tmp2_safe_receiver == null)
        null;
      else {
        notifyCompletion(tmp2_safe_receiver, $this, cause);
      }
    }
  }
  function notifyCancelling($this, list, cause) {
    $this.kq(cause);
    // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers' call
    var exception = null;
    // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
    var cur = list.jw_1;
    while (!equals(cur, list)) {
      if (cur instanceof JobCancellingNode) {
        // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
        var tmp0__anonymous__q1qw7t = cur;
        try {
          tmp0__anonymous__q1qw7t.invoke(cause);
        } catch ($p) {
          if ($p instanceof Error) {
            var ex = $p;
            var tmp0_safe_receiver = exception;
            var tmp;
            if (tmp0_safe_receiver == null) {
              tmp = null;
            } else {
              var tmp$ret$0;
              // Inline function 'kotlin.apply' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
              // Inline function 'kotlinx.coroutines.addSuppressedThrowable' call
              tmp$ret$0 = tmp0_safe_receiver;
              tmp = tmp$ret$0;
            }
            var tmp1_elvis_lhs = tmp;
            if (tmp1_elvis_lhs == null) {
              var tmp$ret$1;
              // Inline function 'kotlin.run' call
              // Inline function 'kotlin.contracts.contract' call
              exception = new CompletionHandlerException('Exception in completion handler ' + tmp0__anonymous__q1qw7t + ' for ' + $this, ex);
              tmp$ret$1 = Unit_getInstance();
            } else
              tmp1_elvis_lhs;
          } else {
            throw $p;
          }
        }
      }
      cur = cur.jw_1;
    }
    var tmp0_safe_receiver_0 = exception;
    if (tmp0_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$2;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      $this.fp(tmp0_safe_receiver_0);
      tmp$ret$2 = Unit_getInstance();
    }
    cancelParent($this, cause);
  }
  function cancelParent($this, cause) {
    if ($this.lq())
      return true;
    var isCancellation = cause instanceof CancellationException;
    var parent = $this.mp();
    if (parent === null ? true : parent === NonDisposableHandle_getInstance()) {
      return isCancellation;
    }
    return parent.fq(cause) ? true : isCancellation;
  }
  function notifyCompletion(_this__u8e3s4, $this, cause) {
    var exception = null;
    // Inline function 'kotlinx.coroutines.internal.LinkedListHead.forEach' call
    var cur = _this__u8e3s4.jw_1;
    while (!equals(cur, _this__u8e3s4)) {
      if (cur instanceof JobNode) {
        // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>' call
        var tmp0__anonymous__q1qw7t = cur;
        try {
          tmp0__anonymous__q1qw7t.invoke(cause);
        } catch ($p) {
          if ($p instanceof Error) {
            var ex = $p;
            var tmp0_safe_receiver = exception;
            var tmp;
            if (tmp0_safe_receiver == null) {
              tmp = null;
            } else {
              var tmp$ret$0;
              // Inline function 'kotlin.apply' call
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'kotlinx.coroutines.JobSupport.notifyHandlers.<anonymous>.<anonymous>' call
              // Inline function 'kotlinx.coroutines.addSuppressedThrowable' call
              tmp$ret$0 = tmp0_safe_receiver;
              tmp = tmp$ret$0;
            }
            var tmp1_elvis_lhs = tmp;
            if (tmp1_elvis_lhs == null) {
              var tmp$ret$1;
              // Inline function 'kotlin.run' call
              // Inline function 'kotlin.contracts.contract' call
              exception = new CompletionHandlerException('Exception in completion handler ' + tmp0__anonymous__q1qw7t + ' for ' + $this, ex);
              tmp$ret$1 = Unit_getInstance();
            } else
              tmp1_elvis_lhs;
          } else {
            throw $p;
          }
        }
      }
      cur = cur.jw_1;
    }
    var tmp0_safe_receiver_0 = exception;
    if (tmp0_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$2;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      $this.fp(tmp0_safe_receiver_0);
      tmp$ret$2 = Unit_getInstance();
    }
    return Unit_getInstance();
  }
  function startInternal($this, state) {
    var tmp0_subject = state;
    if (tmp0_subject instanceof Empty) {
      if (state.dw_1)
        return 0;
      if (!$this.no_1.atomicfu$compareAndSet(state, get_EMPTY_ACTIVE()))
        return -1;
      $this.rp();
      return 1;
    } else {
      if (tmp0_subject instanceof InactiveNodeList) {
        if (!$this.no_1.atomicfu$compareAndSet(state, state.ex_1))
          return -1;
        $this.rp();
        return 1;
      } else {
        return 0;
      }
    }
  }
  function makeNode($this, handler, onCancelling) {
    var tmp;
    if (onCancelling) {
      var tmp0_elvis_lhs = handler instanceof JobCancellingNode ? handler : null;
      tmp = tmp0_elvis_lhs == null ? new InvokeOnCancelling(handler) : tmp0_elvis_lhs;
    } else {
      var tmp1_safe_receiver = handler instanceof JobNode ? handler : null;
      var tmp_0;
      if (tmp1_safe_receiver == null) {
        tmp_0 = null;
      } else {
        var tmp$ret$0;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.JobSupport.makeNode.<anonymous>' call
        // Inline function 'kotlinx.coroutines.assert' call
        tmp$ret$0 = tmp1_safe_receiver;
        tmp_0 = tmp$ret$0;
      }
      var tmp2_elvis_lhs = tmp_0;
      tmp = tmp2_elvis_lhs == null ? new InvokeOnCompletion(handler) : tmp2_elvis_lhs;
    }
    var node = tmp;
    node.vw_1 = $this;
    return node;
  }
  function addLastAtomic($this, expect, list, node) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlinx.coroutines.internal.LinkedListNode.addLastIf' call
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.JobSupport.addLastAtomic.<anonymous>' call
      tmp$ret$0 = $this.op() === expect;
      if (!tmp$ret$0) {
        tmp$ret$1 = false;
        break $l$block;
      }
      list.qw(node);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function promoteEmptyToNodeList($this, state) {
    var list = new NodeList();
    var update = state.dw_1 ? list : new InactiveNodeList(list);
    $this.no_1.atomicfu$compareAndSet(state, update);
  }
  function promoteSingleToNodeList($this, state) {
    state.xw(new NodeList());
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.internal.LinkedListNode.nextNode' call
    tmp$ret$0 = state.jw_1;
    var list = tmp$ret$0;
    $this.no_1.atomicfu$compareAndSet(state, list);
  }
  function joinInternal($this) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.joinInternal.<anonymous>' call
      var tmp0__anonymous__q1qw7t = $this.op();
      if (!(!(tmp0__anonymous__q1qw7t == null) ? isInterface(tmp0__anonymous__q1qw7t, Incomplete) : false))
        return false;
      if (startInternal($this, tmp0__anonymous__q1qw7t) >= 0)
        return true;
    }
  }
  function joinSuspend($this, $completion) {
    var tmp$ret$2;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'kotlinx.coroutines.JobSupport.joinSuspend.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new ResumeOnCompletion(cancellable);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    disposeOnCancellation(cancellable, $this.vp(tmp$ret$1));
    tmp$ret$2 = cancellable.vq();
    var tmp0 = tmp$ret$2;
    return tmp0;
  }
  function cancelMakeCompleting($this, cause) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.cancelMakeCompleting.<anonymous>' call
      var tmp0__anonymous__q1qw7t = $this.op();
      var tmp;
      if (!(!(tmp0__anonymous__q1qw7t == null) ? isInterface(tmp0__anonymous__q1qw7t, Incomplete) : false)) {
        tmp = true;
      } else {
        var tmp_0;
        if (tmp0__anonymous__q1qw7t instanceof Finishing) {
          tmp_0 = tmp0__anonymous__q1qw7t.fx();
        } else {
          tmp_0 = false;
        }
        tmp = tmp_0;
      }
      if (tmp) {
        return get_COMPLETING_ALREADY();
      }
      var proposedUpdate = new CompletedExceptionally(createCauseException($this, cause));
      var finalState = tryMakeCompleting($this, tmp0__anonymous__q1qw7t, proposedUpdate);
      if (!(finalState === get_COMPLETING_RETRY()))
        return finalState;
    }
  }
  function createCauseException($this, cause) {
    var tmp0_subject = cause;
    var tmp;
    if (tmp0_subject == null ? true : tmp0_subject instanceof Error) {
      var tmp1_elvis_lhs = cause;
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp$ret$0;
        // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
        var tmp0_elvis_lhs = null;
        tmp$ret$0 = new JobCancellationException(tmp0_elvis_lhs == null ? $this.xo() : tmp0_elvis_lhs, null, $this);
        tmp_0 = tmp$ret$0;
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      tmp = ((!(cause == null) ? isInterface(cause, ParentJob) : false) ? cause : THROW_CCE()).hq();
    }
    return tmp;
  }
  function makeCancelling($this, cause) {
    var causeExceptionCache = null;
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      var tmp$ret$8;
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>' call
        var tmp0__anonymous__q1qw7t = $this.op();
        var tmp0_subject = tmp0__anonymous__q1qw7t;
        if (tmp0_subject instanceof Finishing) {
          var tmp$ret$5;
          // Inline function 'kotlinx.coroutines.internal.synchronized' call
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$4;
          // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
          var tmp$ret$3;
          // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
          if (tmp0__anonymous__q1qw7t.gx())
            return get_TOO_LATE_TO_CANCEL();
          var wasCancelling = tmp0__anonymous__q1qw7t.cx();
          if (!(cause == null) ? true : !wasCancelling) {
            var tmp0_elvis_lhs = causeExceptionCache;
            var tmp;
            if (tmp0_elvis_lhs == null) {
              var tmp$ret$0;
              // Inline function 'kotlin.also' call
              var tmp0_also = createCauseException($this, cause);
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
              causeExceptionCache = tmp0_also;
              tmp$ret$0 = tmp0_also;
              tmp = tmp$ret$0;
            } else {
              tmp = tmp0_elvis_lhs;
            }
            var causeException = tmp;
            tmp0__anonymous__q1qw7t.hx(causeException);
          }
          var tmp$ret$2;
          // Inline function 'kotlin.takeIf' call
          var tmp1_takeIf = tmp0__anonymous__q1qw7t.ix();
          // Inline function 'kotlin.contracts.contract' call
          var tmp_0;
          var tmp$ret$1;
          // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>.<anonymous>' call
          tmp$ret$1 = !wasCancelling;
          if (tmp$ret$1) {
            tmp_0 = tmp1_takeIf;
          } else {
            tmp_0 = null;
          }
          tmp$ret$2 = tmp_0;
          tmp$ret$3 = tmp$ret$2;
          tmp$ret$4 = tmp$ret$3;
          tmp$ret$5 = tmp$ret$4;
          var notifyRootCause = tmp$ret$5;
          var tmp1_safe_receiver = notifyRootCause;
          if (tmp1_safe_receiver == null)
            null;
          else {
            var tmp$ret$6;
            // Inline function 'kotlin.let' call
            // Inline function 'kotlin.contracts.contract' call
            notifyCancelling($this, tmp0__anonymous__q1qw7t.yw_1, tmp1_safe_receiver);
            tmp$ret$6 = Unit_getInstance();
          }
          return get_COMPLETING_ALREADY();
        } else {
          if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
            var tmp2_elvis_lhs = causeExceptionCache;
            var tmp_1;
            if (tmp2_elvis_lhs == null) {
              var tmp$ret$7;
              // Inline function 'kotlin.also' call
              var tmp0_also_0 = createCauseException($this, cause);
              // Inline function 'kotlin.contracts.contract' call
              // Inline function 'kotlinx.coroutines.JobSupport.makeCancelling.<anonymous>.<anonymous>' call
              causeExceptionCache = tmp0_also_0;
              tmp$ret$7 = tmp0_also_0;
              tmp_1 = tmp$ret$7;
            } else {
              tmp_1 = tmp2_elvis_lhs;
            }
            var causeException_0 = tmp_1;
            if (tmp0__anonymous__q1qw7t.uo()) {
              if (tryMakeCancelling($this, tmp0__anonymous__q1qw7t, causeException_0))
                return get_COMPLETING_ALREADY();
            } else {
              var finalState = tryMakeCompleting($this, tmp0__anonymous__q1qw7t, new CompletedExceptionally(causeException_0));
              if (finalState === get_COMPLETING_ALREADY()) {
                // Inline function 'kotlin.error' call
                var tmp1_error = 'Cannot happen in ' + toString_0(tmp0__anonymous__q1qw7t);
                throw IllegalStateException_init_$Create$(toString(tmp1_error));
              } else if (finalState === get_COMPLETING_RETRY()) {
                tmp$ret$8 = Unit_getInstance();
                break $l$block;
              } else
                return finalState;
            }
          } else {
            return get_TOO_LATE_TO_CANCEL();
          }
        }
      }
    }
  }
  function getOrPromoteCancellingList($this, state) {
    var tmp1_elvis_lhs = state.ew();
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_subject = state;
      var tmp_0;
      if (tmp0_subject instanceof Empty) {
        tmp_0 = new NodeList();
      } else {
        if (tmp0_subject instanceof JobNode) {
          promoteSingleToNodeList($this, state);
          tmp_0 = null;
        } else {
          var tmp0_error = 'State should have list: ' + state;
          throw IllegalStateException_init_$Create$(toString(tmp0_error));
        }
      }
      tmp = tmp_0;
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function tryMakeCancelling($this, state, rootCause) {
    // Inline function 'kotlinx.coroutines.assert' call
    // Inline function 'kotlinx.coroutines.assert' call
    var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var list = tmp;
    var cancelling = new Finishing(list, false, rootCause);
    if (!$this.no_1.atomicfu$compareAndSet(state, cancelling))
      return false;
    notifyCancelling($this, list, rootCause);
    return true;
  }
  function tryMakeCompleting($this, state, proposedUpdate) {
    if (!(!(state == null) ? isInterface(state, Incomplete) : false))
      return get_COMPLETING_ALREADY();
    var tmp;
    var tmp_0;
    var tmp_1;
    if (state instanceof Empty) {
      tmp_1 = true;
    } else {
      tmp_1 = state instanceof JobNode;
    }
    if (tmp_1) {
      tmp_0 = !(state instanceof ChildHandleNode);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = !(proposedUpdate instanceof CompletedExceptionally);
    } else {
      tmp = false;
    }
    if (tmp) {
      if (tryFinalizeSimpleState($this, state, proposedUpdate)) {
        return proposedUpdate;
      }
      return get_COMPLETING_RETRY();
    }
    return tryMakeCompletingSlowPath($this, state, proposedUpdate);
  }
  function tryMakeCompletingSlowPath($this, state, proposedUpdate) {
    var tmp0_elvis_lhs = getOrPromoteCancellingList($this, state);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return get_COMPLETING_RETRY();
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var list = tmp;
    var tmp1_elvis_lhs = state instanceof Finishing ? state : null;
    var finishing = tmp1_elvis_lhs == null ? new Finishing(list, false, null) : tmp1_elvis_lhs;
    var notifyRootCause = null;
    var tmp$ret$4;
    // Inline function 'kotlinx.coroutines.internal.synchronized' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$3;
    // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
    if (finishing.fx())
      return get_COMPLETING_ALREADY();
    finishing.jx(true);
    if (!(finishing === state)) {
      if (!$this.no_1.atomicfu$compareAndSet(state, finishing))
        return get_COMPLETING_RETRY();
    }
    // Inline function 'kotlinx.coroutines.assert' call
    var wasCancelling = finishing.cx();
    var tmp0_safe_receiver = proposedUpdate instanceof CompletedExceptionally ? proposedUpdate : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      finishing.hx(tmp0_safe_receiver.zo_1);
      tmp$ret$0 = Unit_getInstance();
    }
    var tmp$ret$2;
    // Inline function 'kotlin.takeIf' call
    var tmp0_takeIf = finishing.ix();
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.JobSupport.tryMakeCompletingSlowPath.<anonymous>.<anonymous>' call
    tmp$ret$1 = !wasCancelling;
    if (tmp$ret$1) {
      tmp_0 = tmp0_takeIf;
    } else {
      tmp_0 = null;
    }
    tmp$ret$2 = tmp_0;
    notifyRootCause = tmp$ret$2;
    tmp$ret$3 = Unit_getInstance();
    tmp$ret$4 = tmp$ret$3;
    var tmp2_safe_receiver = notifyRootCause;
    if (tmp2_safe_receiver == null)
      null;
    else {
      var tmp$ret$5;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      notifyCancelling($this, list, tmp2_safe_receiver);
      tmp$ret$5 = Unit_getInstance();
    }
    var child = firstChild($this, state);
    if (!(child == null) ? tryWaitForChild($this, finishing, child, proposedUpdate) : false)
      return get_COMPLETING_WAITING_CHILDREN();
    return finalizeFinishingState($this, finishing, proposedUpdate);
  }
  function _get_exceptionOrNull__b3j7js(_this__u8e3s4, $this) {
    var tmp0_safe_receiver = _this__u8e3s4 instanceof CompletedExceptionally ? _this__u8e3s4 : null;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.zo_1;
  }
  function firstChild($this, state) {
    var tmp1_elvis_lhs = state instanceof ChildHandleNode ? state : null;
    var tmp;
    if (tmp1_elvis_lhs == null) {
      var tmp0_safe_receiver = state.ew();
      tmp = tmp0_safe_receiver == null ? null : nextChild(tmp0_safe_receiver, $this);
    } else {
      tmp = tmp1_elvis_lhs;
    }
    return tmp;
  }
  function tryWaitForChild($this, state, child, proposedUpdate) {
    var $this_0 = $this;
    var state_0 = state;
    var child_0 = child;
    var proposedUpdate_0 = proposedUpdate;
    $l$1: do {
      $l$0: do {
        var tmp = child_0.ox_1;
        var tmp$ret$1;
        // Inline function 'kotlinx.coroutines.asHandler' call
        var tmp0__get_asHandler__gq3rkj = new ChildCompletion($this_0, state_0, child_0, proposedUpdate_0);
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
        tmp$ret$1 = tmp$ret$0;
        var handle = tmp.xp(VOID, false, tmp$ret$1);
        if (!(handle === NonDisposableHandle_getInstance()))
          return true;
        var tmp0_elvis_lhs = nextChild(child_0, $this_0);
        var tmp_0;
        if (tmp0_elvis_lhs == null) {
          return false;
        } else {
          tmp_0 = tmp0_elvis_lhs;
        }
        var nextChild_0 = tmp_0;
        var tmp0 = $this_0;
        var tmp1 = state_0;
        var tmp2 = nextChild_0;
        var tmp3 = proposedUpdate_0;
        $this_0 = tmp0;
        state_0 = tmp1;
        child_0 = tmp2;
        proposedUpdate_0 = tmp3;
        continue $l$0;
      }
       while (false);
    }
     while (true);
  }
  function continueCompleting($this, state, lastChild, proposedUpdate) {
    // Inline function 'kotlinx.coroutines.assert' call
    var waitChild = nextChild(lastChild, $this);
    if (!(waitChild == null) ? tryWaitForChild($this, state, waitChild, proposedUpdate) : false)
      return Unit_getInstance();
    var finalState = finalizeFinishingState($this, state, proposedUpdate);
    $this.ep(finalState);
  }
  function nextChild(_this__u8e3s4, $this) {
    var cur = _this__u8e3s4;
    $l$loop: while (true) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.internal.LinkedListNode.isRemoved' call
      var tmp0__get_isRemoved__hsfvgr = cur;
      tmp$ret$0 = tmp0__get_isRemoved__hsfvgr.lw_1;
      if (!tmp$ret$0) {
        break $l$loop;
      }
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.internal.LinkedListNode.prevNode' call
      var tmp1__get_prevNode__b1i0ed = cur;
      tmp$ret$1 = tmp1__get_prevNode__b1i0ed.kw_1;
      cur = tmp$ret$1;
    }
    $l$loop_0: while (true) {
      var tmp$ret$2;
      // Inline function 'kotlinx.coroutines.internal.LinkedListNode.nextNode' call
      var tmp2__get_nextNode__ek7k4a = cur;
      tmp$ret$2 = tmp2__get_nextNode__ek7k4a.jw_1;
      cur = tmp$ret$2;
      var tmp$ret$3;
      // Inline function 'kotlinx.coroutines.internal.LinkedListNode.isRemoved' call
      var tmp3__get_isRemoved__lodk3s = cur;
      tmp$ret$3 = tmp3__get_isRemoved__lodk3s.lw_1;
      if (tmp$ret$3)
        continue $l$loop_0;
      if (cur instanceof ChildHandleNode)
        return cur;
      if (cur instanceof NodeList)
        return null;
    }
  }
  function stateString($this, state) {
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof Finishing) {
      tmp = state.cx() ? 'Cancelling' : state.fx() ? 'Completing' : 'Active';
    } else {
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
        tmp = state.uo() ? 'Active' : 'New';
      } else {
        if (tmp0_subject instanceof CompletedExceptionally) {
          tmp = 'Cancelled';
        } else {
          tmp = 'Completed';
        }
      }
    }
    return tmp;
  }
  function Finishing(list, isCompleting, rootCause) {
    this.yw_1 = list;
    this.zw_1 = atomic$boolean$1(isCompleting);
    this.ax_1 = atomic$ref$1(rootCause);
    this.bx_1 = atomic$ref$1(null);
  }
  protoOf(Finishing).ew = function () {
    return this.yw_1;
  };
  protoOf(Finishing).jx = function (value) {
    this.zw_1.kotlinx$atomicfu$value = value;
  };
  protoOf(Finishing).fx = function () {
    return this.zw_1.kotlinx$atomicfu$value;
  };
  protoOf(Finishing).px = function (value) {
    this.ax_1.kotlinx$atomicfu$value = value;
  };
  protoOf(Finishing).ix = function () {
    return this.ax_1.kotlinx$atomicfu$value;
  };
  protoOf(Finishing).gx = function () {
    return _get_exceptionsHolder__nhszp(this) === get_SEALED();
  };
  protoOf(Finishing).cx = function () {
    return !(this.ix() == null);
  };
  protoOf(Finishing).uo = function () {
    return this.ix() == null;
  };
  protoOf(Finishing).dx = function (proposedException) {
    var eh = _get_exceptionsHolder__nhszp(this);
    var tmp;
    if (eh == null) {
      tmp = allocateList(this);
    } else {
      if (eh instanceof Error) {
        var tmp$ret$0;
        // Inline function 'kotlin.also' call
        var tmp0_also = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.sealLocked.<anonymous>' call
        tmp0_also.b(eh);
        tmp$ret$0 = tmp0_also;
        tmp = tmp$ret$0;
      } else {
        if (eh instanceof ArrayList) {
          tmp = eh instanceof ArrayList ? eh : THROW_CCE();
        } else {
          var tmp1_error = 'State is ' + toString_0(eh);
          throw IllegalStateException_init_$Create$(toString(tmp1_error));
        }
      }
    }
    var list = tmp;
    var rootCause = this.ix();
    var tmp0_safe_receiver = rootCause;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      list.z3(0, tmp0_safe_receiver);
      tmp$ret$1 = Unit_getInstance();
    }
    if (!(proposedException == null) ? !equals(proposedException, rootCause) : false) {
      list.b(proposedException);
    }
    _set_exceptionsHolder__tqm22h(this, get_SEALED());
    return list;
  };
  protoOf(Finishing).hx = function (exception) {
    var rootCause = this.ix();
    if (rootCause == null) {
      this.px(exception);
      return Unit_getInstance();
    }
    if (exception === rootCause)
      return Unit_getInstance();
    var eh = _get_exceptionsHolder__nhszp(this);
    if (eh == null) {
      _set_exceptionsHolder__tqm22h(this, exception);
    } else {
      if (eh instanceof Error) {
        if (exception === eh)
          return Unit_getInstance();
        var tmp$ret$0;
        // Inline function 'kotlin.apply' call
        var tmp0_apply = allocateList(this);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'kotlinx.coroutines.Finishing.addExceptionLocked.<anonymous>' call
        tmp0_apply.b(eh);
        tmp0_apply.b(exception);
        tmp$ret$0 = tmp0_apply;
        _set_exceptionsHolder__tqm22h(this, tmp$ret$0);
      } else {
        if (eh instanceof ArrayList) {
          (eh instanceof ArrayList ? eh : THROW_CCE()).b(exception);
        } else {
          var tmp1_error = 'State is ' + toString_0(eh);
          throw IllegalStateException_init_$Create$(toString(tmp1_error));
        }
      }
    }
  };
  protoOf(Finishing).toString = function () {
    return 'Finishing[cancelling=' + this.cx() + ', completing=' + this.fx() + ', rootCause=' + this.ix() + ', exceptions=' + toString_0(_get_exceptionsHolder__nhszp(this)) + ', list=' + this.yw_1 + ']';
  };
  function ChildCompletion(parent, state, child, proposedUpdate) {
    JobNode.call(this);
    this.ux_1 = parent;
    this.vx_1 = state;
    this.wx_1 = child;
    this.xx_1 = proposedUpdate;
  }
  protoOf(ChildCompletion).fs = function (cause) {
    continueCompleting(this.ux_1, this.vx_1, this.wx_1, this.xx_1);
  };
  protoOf(ChildCompletion).invoke = function (cause) {
    return this.fs(cause);
  };
  function AwaitContinuation(delegate, job) {
    CancellableContinuationImpl.call(this, delegate, get_MODE_CANCELLABLE());
    this.ey_1 = job;
  }
  protoOf(AwaitContinuation).zt = function (parent) {
    var state = this.ey_1.op();
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state.ix();
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$0;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        return tmp0_safe_receiver;
      }
    }
    if (state instanceof CompletedExceptionally)
      return state.zo_1;
    return parent.sp();
  };
  protoOf(AwaitContinuation).gp = function () {
    return 'AwaitContinuation';
  };
  function awaitSuspend($this, $completion) {
    var tmp$ret$2;
    // Inline function 'kotlinx.coroutines.JobSupport.awaitSuspend.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cont = new AwaitContinuation(intercepted(tmp0__anonymous__q1qw7t), $this);
    cont.tt();
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new ResumeAwaitOnCompletion(cont);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    disposeOnCancellation(cont, $this.vp(tmp$ret$1));
    tmp$ret$2 = cont.vq();
    var tmp0 = tmp$ret$2;
    return tmp0;
  }
  function JobSupport$_get_children_$slambda_k839f8(this$0, resultContinuation) {
    this.ny_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(JobSupport$_get_children_$slambda_k839f8).uy = function ($this$sequence, $completion) {
    var tmp = this.vy($this$sequence, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(JobSupport$_get_children_$slambda_k839f8).g8 = function (p1, $completion) {
    return this.uy(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  };
  protoOf(JobSupport$_get_children_$slambda_k839f8).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 8;
            this.py_1 = this.ny_1.op();
            var tmp_0 = this.py_1;
            if (tmp_0 instanceof ChildHandleNode) {
              this.y7_1 = 6;
              suspendResult = this.oy_1.l5(this.py_1.ox_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              var tmp_1 = this.py_1;
              if (!(tmp_1 == null) ? isInterface(tmp_1, Incomplete) : false) {
                this.qy_1 = this.py_1.ew();
                if (this.qy_1 == null) {
                  this.ry_1 = null;
                  this.y7_1 = 5;
                  continue $sm;
                } else {
                  this.sy_1 = this.qy_1.jw_1;
                  this.y7_1 = 1;
                  continue $sm;
                }
              } else {
                this.y7_1 = 7;
                continue $sm;
              }
            }

            break;
          case 1:
            if (!!equals(this.sy_1, this.qy_1)) {
              this.y7_1 = 4;
              continue $sm;
            }

            var tmp_2 = this.sy_1;
            if (tmp_2 instanceof ChildHandleNode) {
              var tmp_3 = this;
              tmp_3.ty_1 = this.sy_1;
              this.y7_1 = 2;
              suspendResult = this.oy_1.l5(this.ty_1.ox_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.sy_1 = this.sy_1.jw_1;
            this.y7_1 = 1;
            continue $sm;
          case 4:
            this.ry_1 = Unit_getInstance();
            this.y7_1 = 5;
            continue $sm;
          case 5:
            ;
            this.y7_1 = 7;
            continue $sm;
          case 6:
            this.y7_1 = 7;
            continue $sm;
          case 7:
            return Unit_getInstance();
          case 8:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 8) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(JobSupport$_get_children_$slambda_k839f8).vy = function ($this$sequence, completion) {
    var i = new JobSupport$_get_children_$slambda_k839f8(this.ny_1, completion);
    i.oy_1 = $this$sequence;
    return i;
  };
  function JobSupport$_get_children_$slambda_k839f8_0(this$0, resultContinuation) {
    var i = new JobSupport$_get_children_$slambda_k839f8(this$0, resultContinuation);
    var l = function ($this$sequence, $completion) {
      return i.uy($this$sequence, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function JobSupport(active) {
    this.no_1 = atomic$ref$1(active ? get_EMPTY_ACTIVE() : get_EMPTY_NEW());
    this.oo_1 = atomic$ref$1(null);
  }
  protoOf(JobSupport).e1 = function () {
    return Key_getInstance_2();
  };
  protoOf(JobSupport).lp = function (value) {
    this.oo_1.kotlinx$atomicfu$value = value;
  };
  protoOf(JobSupport).mp = function () {
    return this.oo_1.kotlinx$atomicfu$value;
  };
  protoOf(JobSupport).np = function () {
    var tmp0_safe_receiver = this.mp();
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.np();
  };
  protoOf(JobSupport).po = function (parent) {
    // Inline function 'kotlinx.coroutines.assert' call
    if (parent == null) {
      this.lp(NonDisposableHandle_getInstance());
      return Unit_getInstance();
    }
    parent.qp();
    var handle = parent.jq(this);
    this.lp(handle);
    if (this.pp()) {
      handle.gs();
      this.lp(NonDisposableHandle_getInstance());
    }
  };
  protoOf(JobSupport).op = function () {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.no_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.<get-state>.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      if (!(tmp1__anonymous__uwfjfc instanceof OpDescriptor))
        return tmp1__anonymous__uwfjfc;
      tmp1__anonymous__uwfjfc.wy(this);
    }
  };
  protoOf(JobSupport).uo = function () {
    var state = this.op();
    var tmp;
    if (!(state == null) ? isInterface(state, Incomplete) : false) {
      tmp = state.uo();
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(JobSupport).pp = function () {
    var tmp = this.op();
    return !(!(tmp == null) ? isInterface(tmp, Incomplete) : false);
  };
  protoOf(JobSupport).xu = function () {
    var state = this.op();
    var tmp;
    if (state instanceof CompletedExceptionally) {
      tmp = true;
    } else {
      var tmp_0;
      if (state instanceof Finishing) {
        tmp_0 = state.cx();
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(JobSupport).qp = function () {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.start.<anonymous>' call
      var tmp0__anonymous__q1qw7t = this.op();
      var tmp0_subject = startInternal(this, tmp0__anonymous__q1qw7t);
      if (tmp0_subject === 0)
        return false;
      else if (tmp0_subject === 1)
        return true;
    }
  };
  protoOf(JobSupport).rp = function () {
  };
  protoOf(JobSupport).sp = function () {
    var state = this.op();
    var tmp;
    if (state instanceof Finishing) {
      var tmp0_safe_receiver = state.ix();
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : this.tp(tmp0_safe_receiver, get_classSimpleName(this) + ' is cancelling');
      var tmp_0;
      if (tmp1_elvis_lhs == null) {
        var tmp0_error = 'Job is still new or active: ' + this;
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      } else {
        tmp_0 = tmp1_elvis_lhs;
      }
      tmp = tmp_0;
    } else {
      if (!(state == null) ? isInterface(state, Incomplete) : false) {
        var tmp1_error = 'Job is still new or active: ' + this;
        throw IllegalStateException_init_$Create$(toString(tmp1_error));
      } else {
        if (state instanceof CompletedExceptionally) {
          tmp = this.up(state.zo_1);
        } else {
          tmp = new JobCancellationException(get_classSimpleName(this) + ' has completed normally', null, this);
        }
      }
    }
    return tmp;
  };
  protoOf(JobSupport).tp = function (_this__u8e3s4, message) {
    var tmp0_elvis_lhs = _this__u8e3s4 instanceof CancellationException ? _this__u8e3s4 : null;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      var tmp0_elvis_lhs_0 = message;
      tmp$ret$0 = new JobCancellationException(tmp0_elvis_lhs_0 == null ? this.xo() : tmp0_elvis_lhs_0, _this__u8e3s4, this);
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  protoOf(JobSupport).up = function (_this__u8e3s4, message, $super) {
    message = message === VOID ? null : message;
    return $super === VOID ? this.tp(_this__u8e3s4, message) : $super.tp.call(this, _this__u8e3s4, message);
  };
  protoOf(JobSupport).vp = function (handler) {
    return this.wp(false, true, handler);
  };
  protoOf(JobSupport).wp = function (onCancelling, invokeImmediately, handler) {
    var node = makeNode(this, handler, onCancelling);
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.invokeOnCompletion.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.op();
        var tmp0_subject = tmp0__anonymous__q1qw7t;
        if (tmp0_subject instanceof Empty) {
          if (tmp0__anonymous__q1qw7t.dw_1) {
            if (this.no_1.atomicfu$compareAndSet(tmp0__anonymous__q1qw7t, node))
              return node;
          } else {
            promoteEmptyToNodeList(this, tmp0__anonymous__q1qw7t);
          }
        } else {
          if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
            var list = tmp0__anonymous__q1qw7t.ew();
            if (list == null) {
              promoteSingleToNodeList(this, tmp0__anonymous__q1qw7t instanceof JobNode ? tmp0__anonymous__q1qw7t : THROW_CCE());
            } else {
              var rootCause = null;
              var handle = NonDisposableHandle_getInstance();
              var tmp;
              if (onCancelling) {
                tmp = tmp0__anonymous__q1qw7t instanceof Finishing;
              } else {
                tmp = false;
              }
              if (tmp) {
                var tmp$ret$3;
                // Inline function 'kotlinx.coroutines.internal.synchronized' call
                // Inline function 'kotlin.contracts.contract' call
                var tmp$ret$2;
                // Inline function 'kotlinx.coroutines.internal.synchronizedImpl' call
                rootCause = tmp0__anonymous__q1qw7t.ix();
                var tmp_0;
                var tmp_1;
                if (rootCause == null) {
                  tmp_1 = true;
                } else {
                  var tmp_2;
                  var tmp$ret$0;
                  // Inline function 'kotlinx.coroutines.isHandlerOf' call
                  tmp$ret$0 = handler instanceof ChildHandleNode;
                  if (tmp$ret$0) {
                    tmp_2 = !tmp0__anonymous__q1qw7t.fx();
                  } else {
                    tmp_2 = false;
                  }
                  tmp_1 = tmp_2;
                }
                if (tmp_1) {
                  if (!addLastAtomic(this, tmp0__anonymous__q1qw7t, list, node)) {
                    tmp$ret$1 = Unit_getInstance();
                    break $l$block;
                  }
                  if (rootCause == null)
                    return node;
                  handle = node;
                  tmp_0 = Unit_getInstance();
                }
                tmp$ret$2 = tmp_0;
                tmp$ret$3 = tmp$ret$2;
              }
              if (!(rootCause == null)) {
                if (invokeImmediately) {
                  invokeIt(handler, rootCause);
                }
                return handle;
              } else {
                if (addLastAtomic(this, tmp0__anonymous__q1qw7t, list, node))
                  return node;
              }
            }
          } else {
            if (invokeImmediately) {
              var tmp1_safe_receiver = tmp0__anonymous__q1qw7t instanceof CompletedExceptionally ? tmp0__anonymous__q1qw7t : null;
              invokeIt(handler, tmp1_safe_receiver == null ? null : tmp1_safe_receiver.zo_1);
            }
            return NonDisposableHandle_getInstance();
          }
        }
      }
    }
  };
  protoOf(JobSupport).yp = function ($completion) {
    if (!joinInternal(this)) {
      var tmp$ret$0;
      // Inline function 'kotlin.js.getCoroutineContext' call
      tmp$ret$0 = $completion.s5();
      ensureActive(tmp$ret$0);
      return Unit_getInstance();
    }
    var tmp0 = joinSuspend(this, $completion);
    return tmp0;
  };
  protoOf(JobSupport).zp = function (node) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      // Inline function 'kotlinx.coroutines.JobSupport.removeNode.<anonymous>' call
      var tmp0__anonymous__q1qw7t = this.op();
      var tmp0_subject = tmp0__anonymous__q1qw7t;
      if (tmp0_subject instanceof JobNode) {
        if (!(tmp0__anonymous__q1qw7t === node))
          return Unit_getInstance();
        if (this.no_1.atomicfu$compareAndSet(tmp0__anonymous__q1qw7t, get_EMPTY_ACTIVE()))
          return Unit_getInstance();
      } else {
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
          if (!(tmp0__anonymous__q1qw7t.ew() == null)) {
            node.pw();
          }
          return Unit_getInstance();
        } else {
          return Unit_getInstance();
        }
      }
    }
  };
  protoOf(JobSupport).aq = function () {
    return false;
  };
  protoOf(JobSupport).bq = function (cause) {
    var tmp0_elvis_lhs = cause;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.JobSupport.defaultCancellationException' call
      var tmp0_elvis_lhs_0 = null;
      tmp$ret$0 = new JobCancellationException(tmp0_elvis_lhs_0 == null ? this.xo() : tmp0_elvis_lhs_0, null, this);
      tmp = tmp$ret$0;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    this.dq(tmp);
  };
  protoOf(JobSupport).xo = function () {
    return 'Job was cancelled';
  };
  protoOf(JobSupport).dq = function (cause) {
    this.gq(cause);
  };
  protoOf(JobSupport).eq = function (parentJob) {
    this.gq(parentJob);
  };
  protoOf(JobSupport).fq = function (cause) {
    if (cause instanceof CancellationException)
      return true;
    return this.gq(cause) ? this.mq() : false;
  };
  protoOf(JobSupport).xy = function (cause) {
    return this.gq(cause);
  };
  protoOf(JobSupport).gq = function (cause) {
    var finalState = get_COMPLETING_ALREADY();
    if (this.aq()) {
      finalState = cancelMakeCompleting(this, cause);
      if (finalState === get_COMPLETING_WAITING_CHILDREN())
        return true;
    }
    if (finalState === get_COMPLETING_ALREADY()) {
      finalState = makeCancelling(this, cause);
    }
    var tmp;
    if (finalState === get_COMPLETING_ALREADY()) {
      tmp = true;
    } else if (finalState === get_COMPLETING_WAITING_CHILDREN()) {
      tmp = true;
    } else if (finalState === get_TOO_LATE_TO_CANCEL()) {
      tmp = false;
    } else {
      this.ep(finalState);
      tmp = true;
    }
    return tmp;
  };
  protoOf(JobSupport).hq = function () {
    var state = this.op();
    var tmp0_subject = state;
    var tmp;
    if (tmp0_subject instanceof Finishing) {
      tmp = state.ix();
    } else {
      if (tmp0_subject instanceof CompletedExceptionally) {
        tmp = state.zo_1;
      } else {
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Incomplete) : false) {
          var tmp0_error = 'Cannot be cancelling child in this state: ' + toString_0(state);
          throw IllegalStateException_init_$Create$(toString(tmp0_error));
        } else {
          tmp = null;
        }
      }
    }
    var rootCause = tmp;
    var tmp1_elvis_lhs = rootCause instanceof CancellationException ? rootCause : null;
    return tmp1_elvis_lhs == null ? new JobCancellationException('Parent job is ' + stateString(this, state), rootCause, this) : tmp1_elvis_lhs;
  };
  protoOf(JobSupport).wu = function (proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompleting.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.op();
        var finalState = tryMakeCompleting(this, tmp0__anonymous__q1qw7t, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          return false;
        else if (finalState === get_COMPLETING_WAITING_CHILDREN())
          return true;
        else if (finalState === get_COMPLETING_RETRY()) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        } else {
          this.ep(finalState);
          return true;
        }
      }
    }
  };
  protoOf(JobSupport).cp = function (proposedUpdate) {
    // Inline function 'kotlinx.coroutines.JobSupport.loopOnState' call
    while (true) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.JobSupport.makeCompletingOnce.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.op();
        var finalState = tryMakeCompleting(this, tmp0__anonymous__q1qw7t, proposedUpdate);
        if (finalState === get_COMPLETING_ALREADY())
          throw IllegalStateException_init_$Create$_0('Job ' + this + ' is already complete or completing, ' + ('but is being completed with ' + toString_0(proposedUpdate)), _get_exceptionOrNull__b3j7js(proposedUpdate, this));
        else if (finalState === get_COMPLETING_RETRY()) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        } else
          return finalState;
      }
    }
  };
  protoOf(JobSupport).iq = function () {
    return sequence(JobSupport$_get_children_$slambda_k839f8_0(this, null));
  };
  protoOf(JobSupport).jq = function (child) {
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new ChildHandleNode(child);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    var tmp = this.xp(true, VOID, tmp$ret$1);
    return isInterface(tmp, ChildHandle) ? tmp : THROW_CCE();
  };
  protoOf(JobSupport).fp = function (exception) {
    throw exception;
  };
  protoOf(JobSupport).kq = function (cause) {
  };
  protoOf(JobSupport).lq = function () {
    return false;
  };
  protoOf(JobSupport).mq = function () {
    return true;
  };
  protoOf(JobSupport).nq = function (exception) {
    return false;
  };
  protoOf(JobSupport).yo = function (state) {
  };
  protoOf(JobSupport).ep = function (state) {
  };
  protoOf(JobSupport).toString = function () {
    return this.oq() + '@' + get_hexAddress(this);
  };
  protoOf(JobSupport).oq = function () {
    return this.gp() + '{' + stateString(this, this.op()) + '}';
  };
  protoOf(JobSupport).gp = function () {
    return get_classSimpleName(this);
  };
  protoOf(JobSupport).mr = function ($completion) {
    $l$loop: while (true) {
      var state = this.op();
      if (!(!(state == null) ? isInterface(state, Incomplete) : false)) {
        if (state instanceof CompletedExceptionally) {
          // Inline function 'kotlinx.coroutines.internal.recoverAndThrow' call
          var tmp0_recoverAndThrow = state.zo_1;
          throw tmp0_recoverAndThrow;
        }
        return unboxState(state);
      }
      if (startInternal(this, state) >= 0)
        break $l$loop;
    }
    var tmp0 = awaitSuspend(this, $completion);
    return tmp0;
  };
  function boxIncomplete(_this__u8e3s4) {
    _init_properties_JobSupport_kt__68f172();
    var tmp;
    if (!(_this__u8e3s4 == null) ? isInterface(_this__u8e3s4, Incomplete) : false) {
      tmp = new IncompleteStateBox(_this__u8e3s4);
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  function JobCancellingNode() {
    JobNode.call(this);
  }
  function InactiveNodeList(list) {
    this.ex_1 = list;
  }
  protoOf(InactiveNodeList).ew = function () {
    return this.ex_1;
  };
  protoOf(InactiveNodeList).uo = function () {
    return false;
  };
  protoOf(InactiveNodeList).toString = function () {
    return get_DEBUG() ? this.ex_1.iw('New') : anyToString(this);
  };
  function ChildHandleNode(childJob) {
    JobCancellingNode.call(this);
    this.ox_1 = childJob;
  }
  protoOf(ChildHandleNode).np = function () {
    return this.ww();
  };
  protoOf(ChildHandleNode).fs = function (cause) {
    return this.ox_1.eq(this.ww());
  };
  protoOf(ChildHandleNode).invoke = function (cause) {
    return this.fs(cause);
  };
  protoOf(ChildHandleNode).fq = function (cause) {
    return this.ww().fq(cause);
  };
  function InvokeOnCancelling(handler) {
    JobCancellingNode.call(this);
    this.cz_1 = handler;
    this.dz_1 = atomic$int$1(0);
  }
  protoOf(InvokeOnCancelling).fs = function (cause) {
    if (this.dz_1.atomicfu$compareAndSet(0, 1))
      this.cz_1(cause);
  };
  protoOf(InvokeOnCancelling).invoke = function (cause) {
    return this.fs(cause);
  };
  function InvokeOnCompletion(handler) {
    JobNode.call(this);
    this.iz_1 = handler;
  }
  protoOf(InvokeOnCompletion).fs = function (cause) {
    return this.iz_1(cause);
  };
  protoOf(InvokeOnCompletion).invoke = function (cause) {
    return this.fs(cause);
  };
  function ResumeOnCompletion(continuation) {
    JobNode.call(this);
    this.nz_1 = continuation;
  }
  protoOf(ResumeOnCompletion).fs = function (cause) {
    var tmp$ret$1;
    // Inline function 'kotlin.coroutines.resume' call
    var tmp1_resume = this.nz_1;
    var tmp$ret$0;
    // Inline function 'kotlin.Companion.success' call
    var tmp0_success = Companion_getInstance();
    tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_getInstance());
    tmp1_resume.q5(tmp$ret$0);
    tmp$ret$1 = Unit_getInstance();
    return tmp$ret$1;
  };
  protoOf(ResumeOnCompletion).invoke = function (cause) {
    return this.fs(cause);
  };
  function unboxState(_this__u8e3s4) {
    _init_properties_JobSupport_kt__68f172();
    var tmp0_safe_receiver = _this__u8e3s4 instanceof IncompleteStateBox ? _this__u8e3s4 : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.oz_1;
    return tmp1_elvis_lhs == null ? _this__u8e3s4 : tmp1_elvis_lhs;
  }
  function ResumeAwaitOnCompletion(continuation) {
    JobNode.call(this);
    this.tz_1 = continuation;
  }
  protoOf(ResumeAwaitOnCompletion).fs = function (cause) {
    var state = this.ww().op();
    // Inline function 'kotlinx.coroutines.assert' call
    if (state instanceof CompletedExceptionally) {
      var tmp$ret$1;
      // Inline function 'kotlin.coroutines.resumeWithException' call
      var tmp1_resumeWithException = this.tz_1;
      var tmp2_resumeWithException = state.zo_1;
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.failure' call
      var tmp0_failure = Companion_getInstance();
      tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp2_resumeWithException));
      tmp1_resumeWithException.q5(tmp$ret$0);
      tmp$ret$1 = Unit_getInstance();
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.coroutines.resume' call
      var tmp4_resume = this.tz_1;
      var tmp = unboxState(state);
      var tmp5_resume = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
      var tmp$ret$2;
      // Inline function 'kotlin.Companion.success' call
      var tmp3_success = Companion_getInstance();
      tmp$ret$2 = _Result___init__impl__xyqfz8(tmp5_resume);
      tmp4_resume.q5(tmp$ret$2);
      tmp$ret$3 = Unit_getInstance();
    }
  };
  protoOf(ResumeAwaitOnCompletion).invoke = function (cause) {
    return this.fs(cause);
  };
  function IncompleteStateBox(state) {
    this.oz_1 = state;
  }
  function ChildContinuation(child) {
    JobCancellingNode.call(this);
    this.yz_1 = child;
  }
  protoOf(ChildContinuation).fs = function (cause) {
    this.yz_1.yt(this.yz_1.zt(this.ww()));
  };
  protoOf(ChildContinuation).invoke = function (cause) {
    return this.fs(cause);
  };
  function handlesException($this) {
    var tmp = $this.mp();
    var tmp0_safe_receiver = tmp instanceof ChildHandleNode ? tmp : null;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ww();
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      return false;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    var parentJob = tmp_0;
    while (true) {
      if (parentJob.mq())
        return true;
      var tmp_1 = parentJob.mp();
      var tmp2_safe_receiver = tmp_1 instanceof ChildHandleNode ? tmp_1 : null;
      var tmp3_elvis_lhs = tmp2_safe_receiver == null ? null : tmp2_safe_receiver.ww();
      var tmp_2;
      if (tmp3_elvis_lhs == null) {
        return false;
      } else {
        tmp_2 = tmp3_elvis_lhs;
      }
      parentJob = tmp_2;
    }
  }
  function JobImpl(parent) {
    JobSupport.call(this, true);
    this.po(parent);
    this.b10_1 = handlesException(this);
  }
  protoOf(JobImpl).aq = function () {
    return true;
  };
  protoOf(JobImpl).mq = function () {
    return this.b10_1;
  };
  function DisposeOnCompletion(handle) {
    JobNode.call(this);
    this.g10_1 = handle;
  }
  protoOf(DisposeOnCompletion).fs = function (cause) {
    return this.g10_1.gs();
  };
  protoOf(DisposeOnCompletion).invoke = function (cause) {
    return this.fs(cause);
  };
  var properties_initialized_JobSupport_kt_5iq8a4;
  function _init_properties_JobSupport_kt__68f172() {
    if (properties_initialized_JobSupport_kt_5iq8a4) {
    } else {
      properties_initialized_JobSupport_kt_5iq8a4 = true;
      COMPLETING_ALREADY = new Symbol('COMPLETING_ALREADY');
      COMPLETING_WAITING_CHILDREN = new Symbol('COMPLETING_WAITING_CHILDREN');
      COMPLETING_RETRY = new Symbol('COMPLETING_RETRY');
      TOO_LATE_TO_CANCEL = new Symbol('TOO_LATE_TO_CANCEL');
      SEALED = new Symbol('SEALED');
      EMPTY_NEW = new Empty(false);
      EMPTY_ACTIVE = new Empty(true);
    }
  }
  function MainCoroutineDispatcher() {
    CoroutineDispatcher.call(this);
  }
  protoOf(MainCoroutineDispatcher).toString = function () {
    var tmp0_elvis_lhs = this.j10();
    return tmp0_elvis_lhs == null ? get_classSimpleName(this) + '@' + get_hexAddress(this) : tmp0_elvis_lhs;
  };
  protoOf(MainCoroutineDispatcher).j10 = function () {
    var main = Dispatchers_getInstance().jv();
    if (this === main)
      return 'Dispatchers.Main';
    var tmp;
    try {
      tmp = main.i10();
    } catch ($p) {
      var tmp_0;
      if ($p instanceof UnsupportedOperationException) {
        var e = $p;
        tmp_0 = null;
      } else {
        throw $p;
      }
      tmp = tmp_0;
    }
    var immediate = tmp;
    if (this === immediate)
      return 'Dispatchers.Main.immediate';
    return null;
  };
  function SupervisorJob(parent) {
    parent = parent === VOID ? null : parent;
    return new SupervisorJobImpl(parent);
  }
  function SupervisorJobImpl(parent) {
    JobImpl.call(this, parent);
  }
  protoOf(SupervisorJobImpl).fq = function (cause) {
    return false;
  };
  function TimeoutCancellationException_init_$Init$(message, $this) {
    TimeoutCancellationException.call($this, message, null);
    return $this;
  }
  function TimeoutCancellationException_init_$Create$(message) {
    var tmp = TimeoutCancellationException_init_$Init$(message, objectCreate(protoOf(TimeoutCancellationException)));
    captureStack(tmp, TimeoutCancellationException_init_$Create$);
    return tmp;
  }
  function TimeoutCancellationException(message, coroutine) {
    CancellationException_init_$Init$(message, this);
    captureStack(this, TimeoutCancellationException);
    this.n10_1 = coroutine;
  }
  function withTimeout(timeMillis, block, $completion) {
    // Inline function 'kotlin.contracts.contract' call
    if (timeMillis.k1(new Long(0, 0)) <= 0)
      throw TimeoutCancellationException_init_$Create$('Timed out immediately');
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.withTimeout.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    tmp$ret$0 = setupTimeout(new TimeoutCoroutine(timeMillis, tmp0__anonymous__q1qw7t), block);
    var tmp0 = tmp$ret$0;
    return tmp0;
  }
  function setupTimeout(coroutine, block) {
    var cont = coroutine.yr_1;
    var context = cont.s5();
    disposeOnCompletion(coroutine, get_delay(context).lv(coroutine.s10_1, coroutine, coroutine.so_1));
    return startUndispatchedOrReturnIgnoreTimeout(coroutine, coroutine, block);
  }
  function TimeoutCoroutine(time, uCont) {
    ScopeCoroutine.call(this, uCont.s5(), uCont);
    this.s10_1 = time;
  }
  protoOf(TimeoutCoroutine).fu = function () {
    this.xy(TimeoutCancellationException_0(this.s10_1, get_delay(this.so_1), this));
  };
  protoOf(TimeoutCoroutine).gp = function () {
    return protoOf(ScopeCoroutine).gp.call(this) + '(timeMillis=' + toString(this.s10_1) + ')';
  };
  function TimeoutCancellationException_0(time, delay, coroutine) {
    var tmp0_safe_receiver = isInterface(delay, DelayWithTimeoutDiagnostics) ? delay : null;
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.time.Companion.milliseconds' call
      var tmp0__get_milliseconds__kazo1t = Companion_getInstance_1();
      tmp$ret$0 = toDuration(time, DurationUnit_MILLISECONDS_getInstance());
      tmp = tmp0_safe_receiver.mv(tmp$ret$0);
    }
    var tmp1_elvis_lhs = tmp;
    var message = tmp1_elvis_lhs == null ? 'Timed out waiting for ' + toString(time) + ' ms' : tmp1_elvis_lhs;
    return new TimeoutCancellationException(message, coroutine);
  }
  function Unconfined() {
    Unconfined_instance = this;
    CoroutineDispatcher.call(this);
  }
  protoOf(Unconfined).bv = function (context) {
    return false;
  };
  protoOf(Unconfined).cv = function (context, block) {
    var yieldContext = context.o8(Key_getInstance_3());
    if (!(yieldContext == null)) {
      yieldContext.v10_1 = true;
      return Unit_getInstance();
    }
    throw UnsupportedOperationException_init_$Create$('Dispatchers.Unconfined.dispatch function can only be used by the yield function. If you wrap Unconfined dispatcher in your code, make sure you properly delegate isDispatchNeeded and dispatch calls.');
  };
  protoOf(Unconfined).toString = function () {
    return 'Dispatchers.Unconfined';
  };
  var Unconfined_instance;
  function Unconfined_getInstance() {
    if (Unconfined_instance == null)
      new Unconfined();
    return Unconfined_instance;
  }
  function Key_2() {
    Key_instance_2 = this;
  }
  var Key_instance_2;
  function Key_getInstance_3() {
    if (Key_instance_2 == null)
      new Key_2();
    return Key_instance_2;
  }
  function Waiter() {
  }
  function flowOf(elements) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.flow.internal.unsafeFlow' call
    tmp$ret$0 = new _no_name_provided__qut3iv(elements);
    return tmp$ret$0;
  }
  function flow(block) {
    return new SafeFlow(block);
  }
  function asFlow(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.flow.internal.unsafeFlow' call
    tmp$ret$0 = new _no_name_provided__qut3iv_0(_this__u8e3s4);
    return tmp$ret$0;
  }
  function SafeFlow(block) {
    AbstractFlow.call(this);
    this.w10_1 = block;
  }
  protoOf(SafeFlow).x10 = function (collector, $completion) {
    var tmp0 = this.w10_1(collector, $completion);
    return tmp0;
  };
  function $collectCOROUTINE$8(_this__u8e3s4, collector, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.h11_1 = _this__u8e3s4;
    this.i11_1 = collector;
  }
  protoOf($collectCOROUTINE$8).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.j11_1 = arrayIterator(this.h11_1.l11_1);
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!this.j11_1.j()) {
              this.y7_1 = 3;
              continue $sm;
            }

            this.k11_1 = this.j11_1.k();
            this.y7_1 = 2;
            suspendResult = this.i11_1.m11(this.k11_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            return Unit_getInstance();
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function _no_name_provided__qut3iv($elements) {
    this.l11_1 = $elements;
  }
  protoOf(_no_name_provided__qut3iv).y10 = function (collector, $completion) {
    var tmp = new $collectCOROUTINE$8(this, collector, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $collectCOROUTINE$9(_this__u8e3s4, collector, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.v11_1 = _this__u8e3s4;
    this.w11_1 = collector;
  }
  protoOf($collectCOROUTINE$9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.x11_1 = this.v11_1.z11_1.h();
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!this.x11_1.j()) {
              this.y7_1 = 3;
              continue $sm;
            }

            this.y11_1 = this.x11_1.k();
            this.y7_1 = 2;
            suspendResult = this.w11_1.m11(this.y11_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            return Unit_getInstance();
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function _no_name_provided__qut3iv_0($this_asFlow) {
    this.z11_1 = $this_asFlow;
  }
  protoOf(_no_name_provided__qut3iv_0).y10 = function (collector, $completion) {
    var tmp = new $collectCOROUTINE$9(this, collector, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $collectCOROUTINE$10(_this__u8e3s4, collector, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.i12_1 = _this__u8e3s4;
    this.j12_1 = collector;
  }
  protoOf($collectCOROUTINE$10).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            var tmp_0 = this;
            tmp_0.k12_1 = new SafeCollector(this.j12_1, this.s5());
            this.y7_1 = 1;
            continue $sm;
          case 1:
            this.z7_1 = 4;
            this.y7_1 = 2;
            suspendResult = this.i12_1.x10(this.k12_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.l12_1 = suspendResult;
            this.z7_1 = 5;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.k12_1.q12();
            ;
            return Unit_getInstance();
          case 4:
            this.z7_1 = 5;
            var t = this.b8_1;
            this.k12_1.q12();
            ;
            throw t;
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function AbstractFlow() {
  }
  protoOf(AbstractFlow).y10 = function (collector, $completion) {
    var tmp = new $collectCOROUTINE$10(this, collector, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function FlowCollector() {
  }
  function checkContext(_this__u8e3s4, currentContext) {
    var result = currentContext.u8(0, checkContext$lambda(_this__u8e3s4));
    if (!(result === _this__u8e3s4.o12_1)) {
      // Inline function 'kotlin.error' call
      var tmp0_error = 'Flow invariant is violated:\n' + ('\t\tFlow was collected in ' + _this__u8e3s4.n12_1 + ',\n') + ('\t\tbut emission happened in ' + currentContext + '.\n') + "\t\tPlease refer to 'flow' documentation or use 'flowOn' instead";
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
  }
  function transitiveCoroutineParent(_this__u8e3s4, collectJob) {
    var $this = _this__u8e3s4;
    var collectJob_0 = collectJob;
    $l$1: do {
      $l$0: do {
        if ($this === null)
          return null;
        if ($this === collectJob_0)
          return $this;
        if (!($this instanceof ScopeCoroutine))
          return $this;
        var tmp0 = $this.np();
        var tmp1 = collectJob_0;
        $this = tmp0;
        collectJob_0 = tmp1;
        continue $l$0;
      }
       while (false);
    }
     while (true);
  }
  function checkContext$lambda($this_checkContext) {
    return function (count, element) {
      var key = element.e1();
      var collectElement = $this_checkContext.n12_1.o8(key);
      var tmp;
      if (!(key === Key_getInstance_2())) {
        return !(element === collectElement) ? IntCompanionObject_getInstance().MIN_VALUE : count + 1 | 0;
      }
      var collectJob = (collectElement == null ? true : isInterface(collectElement, Job)) ? collectElement : THROW_CCE();
      var emissionParentJob = transitiveCoroutineParent(isInterface(element, Job) ? element : THROW_CCE(), collectJob);
      var tmp_0;
      if (!(emissionParentJob === collectJob)) {
        var tmp0_error = 'Flow invariant is violated:\n\t\tEmission from another coroutine is detected.\n' + ('\t\tChild of ' + emissionParentJob + ', expected child of ' + collectJob + '.\n') + '\t\tFlowCollector is not thread-safe and concurrent emissions are prohibited.\n' + "\t\tTo mitigate this restriction please use 'channelFlow' builder instead of 'flow'";
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      }
      return collectJob == null ? count : count + 1 | 0;
    };
  }
  function toList(_this__u8e3s4, destination, $completion) {
    destination = destination === VOID ? ArrayList_init_$Create$_0() : destination;
    var tmp0 = toCollection(_this__u8e3s4, destination, $completion);
    return tmp0;
  }
  function toCollection(_this__u8e3s4, destination, $completion) {
    var tmp = new $toCollectionCOROUTINE$11(_this__u8e3s4, destination, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function sam$kotlinx_coroutines_flow_FlowCollector$0(function_0) {
    this.b13_1 = function_0;
  }
  protoOf(sam$kotlinx_coroutines_flow_FlowCollector$0).m11 = function (value, $completion) {
    var tmp0 = this.b13_1(value, $completion);
    return tmp0;
  };
  function toCollection$slambda($destination, resultContinuation) {
    this.k13_1 = $destination;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(toCollection$slambda).m13 = function (value, $completion) {
    var tmp = this.n13(value, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(toCollection$slambda).g8 = function (p1, $completion) {
    return this.m13((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(toCollection$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.k13_1.b(this.l13_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(toCollection$slambda).n13 = function (value, completion) {
    var i = new toCollection$slambda(this.k13_1, completion);
    i.l13_1 = value;
    return i;
  };
  function toCollection$slambda_0($destination, resultContinuation) {
    var i = new toCollection$slambda($destination, resultContinuation);
    var l = function (value, $completion) {
      return i.m13(value, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function $toCollectionCOROUTINE$11(_this__u8e3s4, destination, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.z12_1 = _this__u8e3s4;
    this.a13_1 = destination;
  }
  protoOf($toCollectionCOROUTINE$11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            var tmp_0 = toCollection$slambda_0(this.a13_1, null);
            suspendResult = this.z12_1.y10(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp_0), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return this.a13_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function OpDescriptor() {
  }
  function get_CLOSED() {
    _init_properties_ConcurrentLinkedList_kt__5gcgzy();
    return CLOSED;
  }
  var CLOSED;
  function Segment(id, prev, pointers) {
    ConcurrentLinkedListNode.call(this, prev);
    this.at_1 = id;
    this.bt_1 = atomic$int$1(pointers << 16);
  }
  protoOf(Segment).p13 = function () {
    return this.bt_1.kotlinx$atomicfu$value === this.o13() ? !this.s13() : false;
  };
  protoOf(Segment).t13 = function () {
    var tmp$ret$1;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.internal.addConditionally' call
      var tmp0_addConditionally = this.bt_1;
      var tmp1_addConditionally = 65536;
      while (true) {
        var cur = tmp0_addConditionally.kotlinx$atomicfu$value;
        var tmp$ret$0;
        // Inline function 'kotlinx.coroutines.internal.Segment.tryIncPointers.<anonymous>' call
        tmp$ret$0 = !(cur === this.o13()) ? true : this.s13();
        if (!tmp$ret$0) {
          tmp$ret$1 = false;
          break $l$block_0;
        }
        if (tmp0_addConditionally.atomicfu$compareAndSet(cur, cur + tmp1_addConditionally | 0)) {
          tmp$ret$1 = true;
          break $l$block_0;
        }
      }
    }
    return tmp$ret$1;
  };
  protoOf(Segment).u13 = function () {
    return this.bt_1.atomicfu$addAndGet(-65536) === this.o13() ? !this.s13() : false;
  };
  protoOf(Segment).v13 = function () {
    if (this.bt_1.atomicfu$incrementAndGet() === this.o13()) {
      this.f5();
    }
  };
  function _SegmentOrClosed___init__impl__jnexvb(value) {
    return value;
  }
  function _get_value__a43j40($this) {
    return $this;
  }
  function _SegmentOrClosed___get_isClosed__impl__qmxmlo($this) {
    return _get_value__a43j40($this) === get_CLOSED();
  }
  function _SegmentOrClosed___get_segment__impl__jvcr9l($this) {
    var tmp;
    if (_get_value__a43j40($this) === get_CLOSED()) {
      throw IllegalStateException_init_$Create$('Does not contain segment');
    } else {
      var tmp_0 = _get_value__a43j40($this);
      tmp = tmp_0 instanceof Segment ? tmp_0 : THROW_CCE();
    }
    return tmp;
  }
  function _get_nextOrClosed__w0gmuv($this) {
    return $this.q13_1.kotlinx$atomicfu$value;
  }
  function _get_aliveSegmentLeft__mr4ndu($this) {
    var cur = $this.y13();
    while (!(cur === null) ? cur.p13() : false)
      cur = cur.r13_1.kotlinx$atomicfu$value;
    return cur;
  }
  function _get_aliveSegmentRight__7ulr0b($this) {
    // Inline function 'kotlinx.coroutines.assert' call
    var cur = ensureNotNull($this.w13());
    while (cur.p13()) {
      var tmp0_elvis_lhs = cur.w13();
      var tmp;
      if (tmp0_elvis_lhs == null) {
        return cur;
      } else {
        tmp = tmp0_elvis_lhs;
      }
      cur = tmp;
    }
    return cur;
  }
  function ConcurrentLinkedListNode(prev) {
    this.q13_1 = atomic$ref$1(null);
    this.r13_1 = atomic$ref$1(prev);
  }
  protoOf(ConcurrentLinkedListNode).w13 = function () {
    var tmp$ret$2;
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = _get_nextOrClosed__w0gmuv(this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
    var tmp;
    if (tmp0_let === get_CLOSED()) {
      return null;
    } else {
      tmp = (tmp0_let == null ? true : tmp0_let instanceof ConcurrentLinkedListNode) ? tmp0_let : THROW_CCE();
    }
    tmp$ret$0 = tmp;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(ConcurrentLinkedListNode).x13 = function (value) {
    return this.q13_1.atomicfu$compareAndSet(null, value);
  };
  protoOf(ConcurrentLinkedListNode).s13 = function () {
    return this.w13() == null;
  };
  protoOf(ConcurrentLinkedListNode).y13 = function () {
    return this.r13_1.kotlinx$atomicfu$value;
  };
  protoOf(ConcurrentLinkedListNode).z13 = function () {
    // Inline function 'kotlinx.atomicfu.AtomicRef.lazySet' call
    var tmp0_lazySet = this.r13_1;
    tmp0_lazySet.kotlinx$atomicfu$value = null;
  };
  protoOf(ConcurrentLinkedListNode).f5 = function () {
    // Inline function 'kotlinx.coroutines.assert' call
    if (this.s13())
      return Unit_getInstance();
    $l$loop_0: while (true) {
      var prev = _get_aliveSegmentLeft__mr4ndu(this);
      var next = _get_aliveSegmentRight__7ulr0b(this);
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlinx.atomicfu.update' call
        var tmp0_update = next.r13_1;
        while (true) {
          var cur = tmp0_update.kotlinx$atomicfu$value;
          var tmp$ret$0;
          // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.remove.<anonymous>' call
          tmp$ret$0 = cur === null ? null : prev;
          var upd = tmp$ret$0;
          if (tmp0_update.atomicfu$compareAndSet(cur, upd)) {
            tmp$ret$1 = Unit_getInstance();
            break $l$block;
          }
        }
      }
      if (!(prev === null))
        prev.q13_1.kotlinx$atomicfu$value = next;
      if (next.p13() ? !next.s13() : false)
        continue $l$loop_0;
      if (!(prev === null) ? prev.p13() : false)
        continue $l$loop_0;
      return Unit_getInstance();
    }
  };
  function findSegmentInternal(_this__u8e3s4, id, createNewSegment) {
    _init_properties_ConcurrentLinkedList_kt__5gcgzy();
    var cur = _this__u8e3s4;
    $l$loop: while (cur.at_1.k1(id) < 0 ? true : cur.p13()) {
      var tmp$ret$2;
      // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed' call
      var tmp1_nextOrIfClosed = cur;
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      var tmp0_let = _get_nextOrClosed__w0gmuv(tmp1_nextOrIfClosed);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.internal.ConcurrentLinkedListNode.nextOrIfClosed.<anonymous>' call
      var tmp;
      if (tmp0_let === get_CLOSED()) {
        return _SegmentOrClosed___init__impl__jnexvb(get_CLOSED());
      } else {
        tmp = (tmp0_let == null ? true : tmp0_let instanceof ConcurrentLinkedListNode) ? tmp0_let : THROW_CCE();
      }
      tmp$ret$0 = tmp;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      var next = tmp$ret$2;
      if (!(next == null)) {
        cur = next;
        continue $l$loop;
      }
      var tmp$ret$3;
      // Inline function 'kotlin.Long.plus' call
      var tmp2_plus = cur.at_1;
      tmp$ret$3 = tmp2_plus.b9(new Long(1, 0));
      var newTail = createNewSegment(tmp$ret$3, cur);
      if (cur.x13(newTail)) {
        if (cur.p13()) {
          cur.f5();
        }
        cur = newTail;
      }
    }
    return _SegmentOrClosed___init__impl__jnexvb(cur);
  }
  var properties_initialized_ConcurrentLinkedList_kt_kwt434;
  function _init_properties_ConcurrentLinkedList_kt__5gcgzy() {
    if (properties_initialized_ConcurrentLinkedList_kt_kwt434) {
    } else {
      properties_initialized_ConcurrentLinkedList_kt_kwt434 = true;
      CLOSED = new Symbol('CLOSED');
    }
  }
  function handleUncaughtCoroutineException(context, exception) {
    var tmp0_iterator = get_platformExceptionHandlers().h();
    while (tmp0_iterator.j()) {
      var handler = tmp0_iterator.k();
      try {
        handler.ev(context, exception);
      } catch ($p) {
        if ($p instanceof ExceptionSuccessfullyProcessed) {
          var _ = $p;
          return Unit_getInstance();
        } else {
          if ($p instanceof Error) {
            var t = $p;
            propagateExceptionFinalResort(handlerException(exception, t));
          } else {
            throw $p;
          }
        }
      }
    }
    try {
      addSuppressed(exception, new DiagnosticCoroutineContextException(context));
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
      } else {
        throw $p;
      }
    }
    propagateExceptionFinalResort(exception);
  }
  function ExceptionSuccessfullyProcessed() {
  }
  function get_UNDEFINED() {
    _init_properties_DispatchedContinuation_kt__tnmqc0();
    return UNDEFINED;
  }
  var UNDEFINED;
  function get_REUSABLE_CLAIMED() {
    _init_properties_DispatchedContinuation_kt__tnmqc0();
    return REUSABLE_CLAIMED;
  }
  var REUSABLE_CLAIMED;
  function _get_reusableCancellableContinuation__9qex09($this) {
    var tmp = $this.ms_1.kotlinx$atomicfu$value;
    return tmp instanceof CancellableContinuationImpl ? tmp : null;
  }
  function DispatchedContinuation(dispatcher, continuation) {
    DispatchedTask.call(this, get_MODE_UNINITIALIZED());
    this.is_1 = dispatcher;
    this.js_1 = continuation;
    this.ks_1 = get_UNDEFINED();
    this.ls_1 = threadContextElements(this.s5());
    this.ms_1 = atomic$ref$1(null);
  }
  protoOf(DispatchedContinuation).s5 = function () {
    return this.js_1.s5();
  };
  protoOf(DispatchedContinuation).vs = function () {
    return !(this.ms_1.kotlinx$atomicfu$value == null);
  };
  protoOf(DispatchedContinuation).a14 = function () {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.ms_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.awaitReusability.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      if (!(tmp1__anonymous__uwfjfc === get_REUSABLE_CLAIMED()))
        return Unit_getInstance();
    }
  };
  protoOf(DispatchedContinuation).dv = function () {
    this.a14();
    var tmp0_safe_receiver = _get_reusableCancellableContinuation__9qex09(this);
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.rt();
    }
  };
  protoOf(DispatchedContinuation).ns = function () {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.ms_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.claimReusableCancellableContinuation.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      if (tmp1__anonymous__uwfjfc === null) {
        this.ms_1.kotlinx$atomicfu$value = get_REUSABLE_CLAIMED();
        return null;
      } else {
        if (tmp1__anonymous__uwfjfc instanceof CancellableContinuationImpl) {
          if (this.ms_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, get_REUSABLE_CLAIMED())) {
            return tmp1__anonymous__uwfjfc instanceof CancellableContinuationImpl ? tmp1__anonymous__uwfjfc : THROW_CCE();
          }
        } else {
          if (tmp1__anonymous__uwfjfc === get_REUSABLE_CLAIMED()) {
          } else {
            if (tmp1__anonymous__uwfjfc instanceof Error) {
            } else {
              var tmp0_error = 'Inconsistent state ' + toString_0(tmp1__anonymous__uwfjfc);
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }
          }
        }
      }
    }
  };
  protoOf(DispatchedContinuation).cu = function (continuation) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.ms_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.tryReleaseClaimedContinuation.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      if (tmp1__anonymous__uwfjfc === get_REUSABLE_CLAIMED()) {
        if (this.ms_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), continuation))
          return null;
      } else {
        if (tmp1__anonymous__uwfjfc instanceof Error) {
          // Inline function 'kotlin.require' call
          var tmp0_require = this.ms_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, null);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'kotlin.require' call
          // Inline function 'kotlin.contracts.contract' call
          if (!tmp0_require) {
            var tmp$ret$0;
            // Inline function 'kotlin.require.<anonymous>' call
            tmp$ret$0 = 'Failed requirement.';
            var message = tmp$ret$0;
            throw IllegalArgumentException_init_$Create$(toString(message));
          }
          return tmp1__anonymous__uwfjfc;
        } else {
          var tmp1_error = 'Inconsistent state ' + toString_0(tmp1__anonymous__uwfjfc);
          throw IllegalStateException_init_$Create$(toString(tmp1_error));
        }
      }
    }
  };
  protoOf(DispatchedContinuation).xs = function (cause) {
    // Inline function 'kotlinx.atomicfu.loop' call
    var tmp0_loop = this.ms_1;
    while (true) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.postponeCancellation.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp0_loop.kotlinx$atomicfu$value;
      var tmp0_subject = tmp1__anonymous__uwfjfc;
      if (equals(tmp0_subject, get_REUSABLE_CLAIMED())) {
        if (this.ms_1.atomicfu$compareAndSet(get_REUSABLE_CLAIMED(), cause))
          return true;
      } else {
        if (tmp0_subject instanceof Error)
          return true;
        else {
          if (this.ms_1.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, null))
            return false;
        }
      }
    }
  };
  protoOf(DispatchedContinuation).ut = function () {
    var state = this.ks_1;
    // Inline function 'kotlinx.coroutines.assert' call
    this.ks_1 = get_UNDEFINED();
    return state;
  };
  protoOf(DispatchedContinuation).st = function () {
    return this;
  };
  protoOf(DispatchedContinuation).q5 = function (result) {
    var context = this.js_1.s5();
    var state = toState_0(result);
    if (this.is_1.bv(context)) {
      this.ks_1 = state;
      this.ws_1 = get_MODE_ATOMIC();
      this.is_1.cv(context, this);
    } else {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
        var tmp0_executeUnconfined = get_MODE_ATOMIC();
        // Inline function 'kotlinx.coroutines.assert' call
        var eventLoop = ThreadLocalEventLoop_getInstance().zv();
        if (false ? eventLoop.uv() : false) {
          tmp$ret$0 = false;
          break $l$block;
        }
        var tmp;
        if (eventLoop.tv()) {
          this.ks_1 = state;
          this.ws_1 = tmp0_executeUnconfined;
          eventLoop.sv(this);
          tmp = true;
        } else {
          // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
          eventLoop.vv(true);
          try {
            // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeWith.<anonymous>' call
            var tmp$ret$1;
            // Inline function 'kotlinx.coroutines.withCoroutineContext' call
            var tmp0_withCoroutineContext = this.s5();
            var tmp1_withCoroutineContext = this.ls_1;
            this.js_1.q5(result);
            tmp$ret$1 = Unit_getInstance();
            $l$loop: while (true) {
              if (!eventLoop.rv())
                break $l$loop;
            }
          } catch ($p) {
            if ($p instanceof Error) {
              var e = $p;
              this.gu(e, null);
            } else {
              throw $p;
            }
          }
          finally {
            eventLoop.wv(true);
          }
          tmp = false;
        }
        tmp$ret$0 = tmp;
      }
    }
  };
  protoOf(DispatchedContinuation).vt = function (takenState, cause) {
    if (takenState instanceof CompletedWithCancellation) {
      takenState.zu_1(cause);
    }
  };
  protoOf(DispatchedContinuation).toString = function () {
    return 'DispatchedContinuation[' + this.is_1 + ', ' + toDebugString(this.js_1) + ']';
  };
  function resumeCancellableWith(_this__u8e3s4, result, onCancellation) {
    onCancellation = onCancellation === VOID ? null : onCancellation;
    _init_properties_DispatchedContinuation_kt__tnmqc0();
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (tmp0_subject instanceof DispatchedContinuation) {
      var tmp1_resumeCancellableWith = _this__u8e3s4;
      var state = toState_0(result, onCancellation);
      var tmp_0;
      if (tmp1_resumeCancellableWith.is_1.bv(tmp1_resumeCancellableWith.s5())) {
        tmp1_resumeCancellableWith.ks_1 = state;
        tmp1_resumeCancellableWith.ws_1 = get_MODE_CANCELLABLE();
        tmp1_resumeCancellableWith.is_1.cv(tmp1_resumeCancellableWith.s5(), tmp1_resumeCancellableWith);
        tmp_0 = Unit_getInstance();
      } else {
        var tmp$ret$0;
        $l$block: {
          // Inline function 'kotlinx.coroutines.internal.executeUnconfined' call
          var tmp0_executeUnconfined = get_MODE_CANCELLABLE();
          // Inline function 'kotlinx.coroutines.assert' call
          var eventLoop = ThreadLocalEventLoop_getInstance().zv();
          if (false ? eventLoop.uv() : false) {
            tmp$ret$0 = false;
            break $l$block;
          }
          var tmp_1;
          if (eventLoop.tv()) {
            tmp1_resumeCancellableWith.ks_1 = state;
            tmp1_resumeCancellableWith.ws_1 = tmp0_executeUnconfined;
            eventLoop.sv(tmp1_resumeCancellableWith);
            tmp_1 = true;
          } else {
            // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
            eventLoop.vv(true);
            try {
              // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancellableWith.<anonymous>' call
              var tmp$ret$3;
              $l$block_0: {
                // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeCancelled' call
                var job = tmp1_resumeCancellableWith.s5().o8(Key_getInstance_2());
                if (!(job == null) ? !job.uo() : false) {
                  var cause = job.sp();
                  tmp1_resumeCancellableWith.vt(state, cause);
                  var tmp$ret$2;
                  // Inline function 'kotlin.coroutines.resumeWithException' call
                  var tmp$ret$1;
                  // Inline function 'kotlin.Companion.failure' call
                  var tmp0_failure = Companion_getInstance();
                  tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(cause));
                  tmp1_resumeCancellableWith.q5(tmp$ret$1);
                  tmp$ret$2 = Unit_getInstance();
                  tmp$ret$3 = true;
                  break $l$block_0;
                }
                tmp$ret$3 = false;
              }
              if (!tmp$ret$3) {
                // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
                var tmp$ret$4;
                // Inline function 'kotlinx.coroutines.withContinuationContext' call
                var tmp1_withContinuationContext = tmp1_resumeCancellableWith.js_1;
                var tmp2_withContinuationContext = tmp1_resumeCancellableWith.ls_1;
                tmp1_resumeCancellableWith.js_1.q5(result);
                tmp$ret$4 = Unit_getInstance();
              }
              $l$loop: while (true) {
                if (!eventLoop.rv())
                  break $l$loop;
              }
            } catch ($p) {
              if ($p instanceof Error) {
                var e = $p;
                tmp1_resumeCancellableWith.gu(e, null);
              } else {
                throw $p;
              }
            }
            finally {
              eventLoop.wv(true);
            }
            tmp_1 = false;
          }
          tmp$ret$0 = tmp_1;
        }
        tmp_0 = Unit_getInstance();
      }
      tmp = tmp_0;
    } else {
      _this__u8e3s4.q5(result);
      tmp = Unit_getInstance();
    }
    return tmp;
  }
  var properties_initialized_DispatchedContinuation_kt_2siadq;
  function _init_properties_DispatchedContinuation_kt__tnmqc0() {
    if (properties_initialized_DispatchedContinuation_kt_2siadq) {
    } else {
      properties_initialized_DispatchedContinuation_kt_2siadq = true;
      UNDEFINED = new Symbol('UNDEFINED');
      REUSABLE_CLAIMED = new Symbol('REUSABLE_CLAIMED');
    }
  }
  function get_MODE_CANCELLABLE() {
    return MODE_CANCELLABLE;
  }
  var MODE_CANCELLABLE;
  function get_MODE_CANCELLABLE_REUSABLE() {
    return MODE_CANCELLABLE_REUSABLE;
  }
  var MODE_CANCELLABLE_REUSABLE;
  function DispatchedTask(resumeMode) {
    SchedulerTask.call(this);
    this.ws_1 = resumeMode;
  }
  protoOf(DispatchedTask).vt = function (takenState, cause) {
  };
  protoOf(DispatchedTask).bu = function (state) {
    return (state == null ? true : isObject(state)) ? state : THROW_CCE();
  };
  protoOf(DispatchedTask).eu = function (state) {
    var tmp0_safe_receiver = state instanceof CompletedExceptionally ? state : null;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.zo_1;
  };
  protoOf(DispatchedTask).fu = function () {
    // Inline function 'kotlinx.coroutines.assert' call
    get_taskContext(this);
    var taskContext = Unit_getInstance();
    var fatalException = null;
    try {
      var tmp = this.st();
      var delegate = tmp instanceof DispatchedContinuation ? tmp : THROW_CCE();
      var continuation = delegate.js_1;
      var tmp$ret$5;
      // Inline function 'kotlinx.coroutines.withContinuationContext' call
      var tmp0_withContinuationContext = delegate.ls_1;
      var context = continuation.s5();
      var state = this.ut();
      var exception = this.eu(state);
      var job = (exception == null ? get_isCancellableMode(this.ws_1) : false) ? context.o8(Key_getInstance_2()) : null;
      var tmp_0;
      if (!(job == null) ? !job.uo() : false) {
        var cause = job.sp();
        this.vt(state, cause);
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.failure' call
        var tmp0_failure = Companion_getInstance();
        var tmp1_failure = recoverStackTrace(cause, continuation);
        tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_failure));
        continuation.q5(tmp$ret$0);
        tmp_0 = Unit_getInstance();
      } else {
        var tmp_1;
        if (!(exception == null)) {
          var tmp$ret$2;
          // Inline function 'kotlin.coroutines.resumeWithException' call
          var tmp$ret$1;
          // Inline function 'kotlin.Companion.failure' call
          var tmp2_failure = Companion_getInstance();
          tmp$ret$1 = _Result___init__impl__xyqfz8(createFailure(exception));
          continuation.q5(tmp$ret$1);
          tmp$ret$2 = Unit_getInstance();
          tmp_1 = tmp$ret$2;
        } else {
          var tmp$ret$4;
          // Inline function 'kotlin.coroutines.resume' call
          var tmp4_resume = this.bu(state);
          var tmp$ret$3;
          // Inline function 'kotlin.Companion.success' call
          var tmp3_success = Companion_getInstance();
          tmp$ret$3 = _Result___init__impl__xyqfz8(tmp4_resume);
          continuation.q5(tmp$ret$3);
          tmp$ret$4 = Unit_getInstance();
          tmp_1 = tmp$ret$4;
        }
        tmp_0 = tmp_1;
      }
      tmp$ret$5 = tmp_0;
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        fatalException = e;
      } else {
        throw $p;
      }
    }
    finally {
      var tmp$ret$8;
      // Inline function 'kotlin.runCatching' call
      var tmp_2;
      try {
        var tmp$ret$6;
        // Inline function 'kotlin.Companion.success' call
        var tmp1_success = Companion_getInstance();
        var tmp2_success = Unit_getInstance();
        tmp$ret$6 = _Result___init__impl__xyqfz8(Unit_getInstance());
        tmp_2 = tmp$ret$6;
      } catch ($p) {
        var tmp_3;
        if ($p instanceof Error) {
          var e_0 = $p;
          var tmp$ret$7;
          // Inline function 'kotlin.Companion.failure' call
          var tmp3_failure = Companion_getInstance();
          tmp$ret$7 = _Result___init__impl__xyqfz8(createFailure(e_0));
          tmp_3 = tmp$ret$7;
        } else {
          throw $p;
        }
        tmp_2 = tmp_3;
      }
      tmp$ret$8 = tmp_2;
      var result = tmp$ret$8;
      this.gu(fatalException, Result__exceptionOrNull_impl_p6xea9(result));
    }
  };
  protoOf(DispatchedTask).gu = function (exception, finallyException) {
    if (exception === null ? finallyException === null : false)
      return Unit_getInstance();
    if (!(exception === null) ? !(finallyException === null) : false) {
      // Inline function 'kotlinx.coroutines.addSuppressedThrowable' call
    }
    var tmp0_elvis_lhs = exception;
    var cause = tmp0_elvis_lhs == null ? finallyException : tmp0_elvis_lhs;
    var reason = new CoroutinesInternalError('Fatal exception in coroutines machinery for ' + this + '. ' + "Please read KDoc to 'handleFatalException' method and report this incident to maintainers", ensureNotNull(cause));
    handleCoroutineException(this.st().s5(), reason);
  };
  function get_MODE_UNINITIALIZED() {
    return MODE_UNINITIALIZED;
  }
  var MODE_UNINITIALIZED;
  function get_isReusableMode(_this__u8e3s4) {
    return _this__u8e3s4 === 2;
  }
  function get_isCancellableMode(_this__u8e3s4) {
    return _this__u8e3s4 === 1 ? true : _this__u8e3s4 === 2;
  }
  function dispatch(_this__u8e3s4, mode) {
    // Inline function 'kotlinx.coroutines.assert' call
    var delegate = _this__u8e3s4.st();
    var undispatched = mode === 4;
    var tmp;
    var tmp_0;
    if (!undispatched) {
      tmp_0 = delegate instanceof DispatchedContinuation;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = get_isCancellableMode(mode) === get_isCancellableMode(_this__u8e3s4.ws_1);
    } else {
      tmp = false;
    }
    if (tmp) {
      var dispatcher = delegate.is_1;
      var context = delegate.s5();
      if (dispatcher.bv(context)) {
        dispatcher.cv(context, _this__u8e3s4);
      } else {
        resumeUnconfined(_this__u8e3s4);
      }
    } else {
      resume(_this__u8e3s4, delegate, undispatched);
    }
  }
  function get_MODE_UNDISPATCHED() {
    return MODE_UNDISPATCHED;
  }
  var MODE_UNDISPATCHED;
  function resumeUnconfined(_this__u8e3s4) {
    var eventLoop = ThreadLocalEventLoop_getInstance().zv();
    if (eventLoop.tv()) {
      eventLoop.sv(_this__u8e3s4);
    } else {
      // Inline function 'kotlinx.coroutines.runUnconfinedEventLoop' call
      eventLoop.vv(true);
      try {
        // Inline function 'kotlinx.coroutines.resumeUnconfined.<anonymous>' call
        resume(_this__u8e3s4, _this__u8e3s4.st(), true);
        $l$loop: while (true) {
          if (!eventLoop.rv())
            break $l$loop;
        }
      } catch ($p) {
        if ($p instanceof Error) {
          var e = $p;
          _this__u8e3s4.gu(e, null);
        } else {
          throw $p;
        }
      }
      finally {
        eventLoop.wv(true);
      }
    }
  }
  function resume(_this__u8e3s4, delegate, undispatched) {
    var state = _this__u8e3s4.ut();
    var exception = _this__u8e3s4.eu(state);
    var tmp;
    if (!(exception == null)) {
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.failure' call
      var tmp0_failure = Companion_getInstance();
      tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(exception));
      tmp = tmp$ret$0;
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.Companion.success' call
      var tmp1_success = Companion_getInstance();
      var tmp2_success = _this__u8e3s4.bu(state);
      tmp$ret$1 = _Result___init__impl__xyqfz8(tmp2_success);
      tmp = tmp$ret$1;
    }
    var result = tmp;
    if (undispatched) {
      // Inline function 'kotlinx.coroutines.internal.DispatchedContinuation.resumeUndispatchedWith' call
      var tmp5_resumeUndispatchedWith = delegate instanceof DispatchedContinuation ? delegate : THROW_CCE();
      var tmp$ret$2;
      // Inline function 'kotlinx.coroutines.withContinuationContext' call
      var tmp3_withContinuationContext = tmp5_resumeUndispatchedWith.js_1;
      var tmp4_withContinuationContext = tmp5_resumeUndispatchedWith.ls_1;
      tmp5_resumeUndispatchedWith.js_1.q5(result);
      tmp$ret$2 = Unit_getInstance();
    } else {
      delegate.q5(result);
    }
  }
  function get_MODE_ATOMIC() {
    return MODE_ATOMIC;
  }
  var MODE_ATOMIC;
  function ContextScope(context) {
    this.b14_1 = context;
  }
  protoOf(ContextScope).to = function () {
    return this.b14_1;
  };
  protoOf(ContextScope).toString = function () {
    return 'CoroutineScope(coroutineContext=' + this.b14_1 + ')';
  };
  function ScopeCoroutine(context, uCont) {
    AbstractCoroutine.call(this, context, true, true);
    this.yr_1 = uCont;
  }
  protoOf(ScopeCoroutine).lq = function () {
    return true;
  };
  protoOf(ScopeCoroutine).ep = function (state) {
    resumeCancellableWith(intercepted(this.yr_1), recoverResult(state, this.yr_1));
  };
  protoOf(ScopeCoroutine).dp = function (state) {
    this.yr_1.q5(recoverResult(state, this.yr_1));
  };
  function Symbol(symbol) {
    this.c14_1 = symbol;
  }
  protoOf(Symbol).toString = function () {
    return '<' + this.c14_1 + '>';
  };
  function systemProp(propertyName, defaultValue, minValue, maxValue) {
    minValue = minValue === VOID ? 1 : minValue;
    maxValue = maxValue === VOID ? IntCompanionObject_getInstance().MAX_VALUE : maxValue;
    return systemProp_0(propertyName, toLong(defaultValue), toLong(minValue), toLong(maxValue)).t1();
  }
  function systemProp_0(propertyName, defaultValue, minValue, maxValue) {
    minValue = minValue === VOID ? new Long(1, 0) : minValue;
    var tmp;
    if (maxValue === VOID) {
      Companion_getInstance_0();
      tmp = new Long(-1, 2147483647);
    } else {
      tmp = maxValue;
    }
    maxValue = tmp;
    var tmp0_elvis_lhs = systemProp_1(propertyName);
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      return defaultValue;
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    var value = tmp_0;
    var tmp1_elvis_lhs = toLongOrNull(value);
    var tmp_1;
    if (tmp1_elvis_lhs == null) {
      var tmp0_error = "System property '" + propertyName + "' has unrecognized value '" + value + "'";
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    } else {
      tmp_1 = tmp1_elvis_lhs;
    }
    var parsed = tmp_1;
    if (!(minValue.k1(parsed) <= 0 ? parsed.k1(maxValue) <= 0 : false)) {
      // Inline function 'kotlin.error' call
      var tmp1_error = "System property '" + propertyName + "' should be in range " + toString(minValue) + '..' + toString(maxValue) + ", but is '" + toString(parsed) + "'";
      throw IllegalStateException_init_$Create$(toString(tmp1_error));
    }
    return parsed;
  }
  function startCoroutineCancellable(_this__u8e3s4, receiver, completion, onCancellation) {
    onCancellation = onCancellation === VOID ? null : onCancellation;
    var tmp;
    try {
      var tmp_0 = intercepted(createCoroutineUnintercepted(_this__u8e3s4, receiver, completion));
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.success' call
      var tmp0_success = Companion_getInstance();
      tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_getInstance());
      resumeCancellableWith(tmp_0, tmp$ret$0, onCancellation);
      tmp = Unit_getInstance();
    } catch ($p) {
      var tmp_1;
      if ($p instanceof Error) {
        var e = $p;
        dispatcherFailure$accessor$glj1hg(completion, e);
        tmp_1 = Unit_getInstance();
      } else {
        throw $p;
      }
      tmp = tmp_1;
    }
    return tmp;
  }
  function startCoroutineCancellable_0(_this__u8e3s4, fatalCompletion) {
    var tmp;
    try {
      var tmp_0 = intercepted(_this__u8e3s4);
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.success' call
      var tmp0_success = Companion_getInstance();
      tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_getInstance());
      resumeCancellableWith(tmp_0, tmp$ret$0);
      tmp = Unit_getInstance();
    } catch ($p) {
      var tmp_1;
      if ($p instanceof Error) {
        var e = $p;
        dispatcherFailure$accessor$glj1hg(fatalCompletion, e);
        tmp_1 = Unit_getInstance();
      } else {
        throw $p;
      }
      tmp = tmp_1;
    }
    return tmp;
  }
  function dispatcherFailure(completion, e) {
    var tmp$ret$0;
    // Inline function 'kotlin.Companion.failure' call
    var tmp0_failure = Companion_getInstance();
    tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(e));
    completion.q5(tmp$ret$0);
    throw e;
  }
  function dispatcherFailure$accessor$glj1hg(completion, e) {
    return dispatcherFailure(completion, e);
  }
  function startUndispatchedOrReturn(_this__u8e3s4, receiver, block) {
    var tmp$ret$3;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.intrinsics.undispatchedResult' call
      var tmp;
      try {
        var tmp$ret$2;
        // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturn.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.intrinsics.startCoroutineUninterceptedOrReturn' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = block;
        var a = tmp$ret$0;
        tmp$ret$1 = typeof a === 'function' ? a(receiver, _this__u8e3s4) : block.g8(receiver, _this__u8e3s4);
        tmp$ret$2 = tmp$ret$1;
        tmp = tmp$ret$2;
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var e = $p;
          tmp_0 = new CompletedExceptionally(e);
        } else {
          throw $p;
        }
        tmp = tmp_0;
      }
      var result = tmp;
      if (result === get_COROUTINE_SUSPENDED()) {
        tmp$ret$3 = get_COROUTINE_SUSPENDED();
        break $l$block_0;
      }
      var state = _this__u8e3s4.cp(result);
      if (state === get_COMPLETING_WAITING_CHILDREN()) {
        tmp$ret$3 = get_COROUTINE_SUSPENDED();
        break $l$block_0;
      }
      var tmp_1;
      if (state instanceof CompletedExceptionally) {
        var tmp_2;
        var tmp$ret$4;
        // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturn.<anonymous>' call
        var tmp0__anonymous__q1qw7t = state.zo_1;
        tmp$ret$4 = true;
        if (tmp$ret$4) {
          throw recoverStackTrace(state.zo_1, _this__u8e3s4.yr_1);
        } else {
          if (result instanceof CompletedExceptionally) {
            throw recoverStackTrace(result.zo_1, _this__u8e3s4.yr_1);
          } else {
            tmp_2 = result;
          }
        }
        tmp_1 = tmp_2;
      } else {
        tmp_1 = unboxState(state);
      }
      tmp$ret$3 = tmp_1;
    }
    return tmp$ret$3;
  }
  function startCoroutineUndispatched(_this__u8e3s4, receiver, completion) {
    var tmp$ret$8;
    $l$block: {
      // Inline function 'kotlinx.coroutines.intrinsics.startDirect' call
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.internal.probeCoroutineCreated' call
      tmp$ret$0 = completion;
      var actualCompletion = tmp$ret$0;
      var tmp;
      try {
        var tmp$ret$5;
        // Inline function 'kotlinx.coroutines.intrinsics.startCoroutineUndispatched.<anonymous>' call
        var tmp$ret$4;
        // Inline function 'kotlinx.coroutines.withCoroutineContext' call
        var tmp0_withCoroutineContext = completion.s5();
        var tmp$ret$3;
        // Inline function 'kotlinx.coroutines.intrinsics.startCoroutineUndispatched.<anonymous>.<anonymous>' call
        var tmp$ret$2;
        // Inline function 'kotlin.coroutines.intrinsics.startCoroutineUninterceptedOrReturn' call
        var tmp$ret$1;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$1 = _this__u8e3s4;
        var a = tmp$ret$1;
        tmp$ret$2 = typeof a === 'function' ? a(receiver, actualCompletion) : _this__u8e3s4.g8(receiver, actualCompletion);
        tmp$ret$3 = tmp$ret$2;
        tmp$ret$4 = tmp$ret$3;
        tmp$ret$5 = tmp$ret$4;
        tmp = tmp$ret$5;
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var e = $p;
          var tmp$ret$7;
          // Inline function 'kotlin.coroutines.resumeWithException' call
          var tmp$ret$6;
          // Inline function 'kotlin.Companion.failure' call
          var tmp0_failure = Companion_getInstance();
          tmp$ret$6 = _Result___init__impl__xyqfz8(createFailure(e));
          actualCompletion.q5(tmp$ret$6);
          tmp$ret$7 = Unit_getInstance();
          tmp$ret$8 = Unit_getInstance();
          break $l$block;
        } else {
          throw $p;
        }
        tmp = tmp_0;
      }
      var value = tmp;
      if (!(value === get_COROUTINE_SUSPENDED())) {
        var tmp$ret$10;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp2_resume = (value == null ? true : isObject(value)) ? value : THROW_CCE();
        var tmp$ret$9;
        // Inline function 'kotlin.Companion.success' call
        var tmp1_success = Companion_getInstance();
        tmp$ret$9 = _Result___init__impl__xyqfz8(tmp2_resume);
        actualCompletion.q5(tmp$ret$9);
        tmp$ret$10 = Unit_getInstance();
      }
    }
  }
  function startUndispatchedOrReturnIgnoreTimeout(_this__u8e3s4, receiver, block) {
    var tmp$ret$3;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.intrinsics.undispatchedResult' call
      var tmp;
      try {
        var tmp$ret$2;
        // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturnIgnoreTimeout.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.intrinsics.startCoroutineUninterceptedOrReturn' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = block;
        var a = tmp$ret$0;
        tmp$ret$1 = typeof a === 'function' ? a(receiver, _this__u8e3s4) : block.g8(receiver, _this__u8e3s4);
        tmp$ret$2 = tmp$ret$1;
        tmp = tmp$ret$2;
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var e = $p;
          tmp_0 = new CompletedExceptionally(e);
        } else {
          throw $p;
        }
        tmp = tmp_0;
      }
      var result = tmp;
      if (result === get_COROUTINE_SUSPENDED()) {
        tmp$ret$3 = get_COROUTINE_SUSPENDED();
        break $l$block_0;
      }
      var state = _this__u8e3s4.cp(result);
      if (state === get_COMPLETING_WAITING_CHILDREN()) {
        tmp$ret$3 = get_COROUTINE_SUSPENDED();
        break $l$block_0;
      }
      var tmp_1;
      if (state instanceof CompletedExceptionally) {
        var tmp_2;
        var tmp$ret$4;
        // Inline function 'kotlinx.coroutines.intrinsics.startUndispatchedOrReturnIgnoreTimeout.<anonymous>' call
        var tmp0__anonymous__q1qw7t = state.zo_1;
        var tmp_3;
        if (tmp0__anonymous__q1qw7t instanceof TimeoutCancellationException) {
          tmp_3 = tmp0__anonymous__q1qw7t.n10_1 === _this__u8e3s4;
        } else {
          tmp_3 = false;
        }
        tmp$ret$4 = !tmp_3;
        if (tmp$ret$4) {
          throw recoverStackTrace(state.zo_1, _this__u8e3s4.yr_1);
        } else {
          if (result instanceof CompletedExceptionally) {
            throw recoverStackTrace(result.zo_1, _this__u8e3s4.yr_1);
          } else {
            tmp_2 = result;
          }
        }
        tmp_1 = tmp_2;
      } else {
        tmp_1 = unboxState(state);
      }
      tmp$ret$3 = tmp_1;
    }
    return tmp$ret$3;
  }
  function SelectInstance() {
  }
  function get_NO_OWNER() {
    _init_properties_Mutex_kt__jod56b();
    return NO_OWNER;
  }
  var NO_OWNER;
  var ON_LOCK_ALREADY_LOCKED_BY_OWNER;
  function Mutex() {
  }
  function Mutex_0(locked) {
    locked = locked === VOID ? false : locked;
    _init_properties_Mutex_kt__jod56b();
    return new MutexImpl(locked);
  }
  function MutexImpl$CancellableContinuationWithOwner$tryResume$lambda(this$0, this$1) {
    return function (it) {
      this$0.r14_1.kotlinx$atomicfu$value = this$1.u14_1;
      this$0.i14(this$1.u14_1);
      return Unit_getInstance();
    };
  }
  function MutexImpl$CancellableContinuationWithOwner$resume$lambda(this$0, this$1) {
    return function (it) {
      this$0.i14(this$1.u14_1);
      return Unit_getInstance();
    };
  }
  function holdsLockImpl($this, owner) {
    $l$loop: while (true) {
      if (!$this.f14())
        return 0;
      var curOwner = $this.r14_1.kotlinx$atomicfu$value;
      if (curOwner === get_NO_OWNER())
        continue $l$loop;
      return curOwner === owner ? 1 : 2;
    }
  }
  function lockSuspend($this, owner, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutineReusable.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = getOrCreateCancellableContinuation(intercepted(tmp0__anonymous__q1qw7t));
    try {
      // Inline function 'kotlinx.coroutines.sync.MutexImpl.lockSuspend.<anonymous>' call
      var contWithOwner = new CancellableContinuationWithOwner($this, cancellable, owner);
      $this.acquireCont(contWithOwner);
    } catch ($p) {
      if ($p instanceof Error) {
        var e = $p;
        cancellable.au();
        throw e;
      } else {
        throw $p;
      }
    }
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  }
  function tryLockImpl($this, owner) {
    $l$loop: while (true) {
      if ($this.d15()) {
        // Inline function 'kotlinx.coroutines.assert' call
        $this.r14_1.kotlinx$atomicfu$value = owner;
        return 0;
      } else {
        if (owner == null)
          return 1;
        var tmp0_subject = holdsLockImpl($this, owner);
        switch (tmp0_subject) {
          case 1:
            return 2;
          case 2:
            return 1;
          case 0:
            continue $l$loop;
        }
      }
    }
  }
  function CancellableContinuationWithOwner($outer, cont, owner) {
    this.v14_1 = $outer;
    this.t14_1 = cont;
    this.u14_1 = owner;
  }
  protoOf(CancellableContinuationWithOwner).s5 = function () {
    return this.t14_1.s5();
  };
  protoOf(CancellableContinuationWithOwner).as = function (token) {
    this.t14_1.as(token);
  };
  protoOf(CancellableContinuationWithOwner).bs = function (handler) {
    this.t14_1.bs(handler);
  };
  protoOf(CancellableContinuationWithOwner).r5 = function (result) {
    this.t14_1.q5(result);
  };
  protoOf(CancellableContinuationWithOwner).q5 = function (result) {
    return this.r5(result);
  };
  protoOf(CancellableContinuationWithOwner).e15 = function (_this__u8e3s4, value) {
    this.t14_1.cs(_this__u8e3s4, Unit_getInstance());
  };
  protoOf(CancellableContinuationWithOwner).cs = function (_this__u8e3s4, value) {
    return this.e15(_this__u8e3s4, value instanceof Unit ? value : THROW_CCE());
  };
  protoOf(CancellableContinuationWithOwner).du = function (segment, index) {
    this.t14_1.du(segment, index);
  };
  protoOf(CancellableContinuationWithOwner).f15 = function (value, idempotent, onCancellation) {
    // Inline function 'kotlinx.coroutines.assert' call
    var token = this.t14_1.zr(Unit_getInstance(), idempotent, MutexImpl$CancellableContinuationWithOwner$tryResume$lambda(this.v14_1, this));
    if (!(token == null)) {
      // Inline function 'kotlinx.coroutines.assert' call
      this.v14_1.r14_1.kotlinx$atomicfu$value = this.u14_1;
    }
    return token;
  };
  protoOf(CancellableContinuationWithOwner).zr = function (value, idempotent, onCancellation) {
    return this.f15(value instanceof Unit ? value : THROW_CCE(), idempotent, onCancellation);
  };
  protoOf(CancellableContinuationWithOwner).g15 = function (value, onCancellation) {
    // Inline function 'kotlinx.coroutines.assert' call
    this.v14_1.r14_1.kotlinx$atomicfu$value = this.u14_1;
    this.t14_1.ds(Unit_getInstance(), MutexImpl$CancellableContinuationWithOwner$resume$lambda(this.v14_1, this));
  };
  protoOf(CancellableContinuationWithOwner).ds = function (value, onCancellation) {
    return this.g15(value instanceof Unit ? value : THROW_CCE(), onCancellation);
  };
  function MutexImpl$onSelectCancellationUnlockConstructor$lambda$lambda(this$0, $owner) {
    return function (it) {
      this$0.i14($owner);
      return Unit_getInstance();
    };
  }
  function MutexImpl$onSelectCancellationUnlockConstructor$lambda(this$0) {
    return function (_anonymous_parameter_0__qggqh8, owner, _anonymous_parameter_2__qggqfi) {
      return MutexImpl$onSelectCancellationUnlockConstructor$lambda$lambda(this$0, owner);
    };
  }
  function MutexImpl(locked) {
    SemaphoreImpl.call(this, 1, locked ? 1 : 0);
    this.r14_1 = atomic$ref$1(locked ? null : get_NO_OWNER());
    var tmp = this;
    tmp.s14_1 = MutexImpl$onSelectCancellationUnlockConstructor$lambda(this);
  }
  protoOf(MutexImpl).f14 = function () {
    return this.h15() === 0;
  };
  protoOf(MutexImpl).g14 = function (owner, $completion) {
    if (this.i15(owner))
      return Unit_getInstance();
    var tmp0 = lockSuspend(this, owner, $completion);
    return tmp0;
  };
  protoOf(MutexImpl).i15 = function (owner) {
    var tmp0_subject = tryLockImpl(this, owner);
    var tmp;
    switch (tmp0_subject) {
      case 0:
        tmp = true;
        break;
      case 1:
        tmp = false;
        break;
      case 2:
        var tmp0_error = 'This mutex is already locked by the specified owner: ' + toString_0(owner);
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      default:
        throw IllegalStateException_init_$Create$('unexpected');
    }
    return tmp;
  };
  protoOf(MutexImpl).i14 = function (owner) {
    $l$loop_0: while (true) {
      // Inline function 'kotlin.check' call
      var tmp0_check = this.f14();
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_check) {
        var tmp$ret$0;
        // Inline function 'kotlinx.coroutines.sync.MutexImpl.unlock.<anonymous>' call
        tmp$ret$0 = 'This mutex is not locked';
        var message = tmp$ret$0;
        throw IllegalStateException_init_$Create$(toString(message));
      }
      var curOwner = this.r14_1.kotlinx$atomicfu$value;
      if (curOwner === get_NO_OWNER())
        continue $l$loop_0;
      // Inline function 'kotlin.check' call
      var tmp1_check = curOwner === owner ? true : owner == null;
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp1_check) {
        var tmp$ret$1;
        // Inline function 'kotlinx.coroutines.sync.MutexImpl.unlock.<anonymous>' call
        tmp$ret$1 = 'This mutex is locked by ' + toString_0(curOwner) + ', but ' + toString_0(owner) + ' is expected';
        var message_0 = tmp$ret$1;
        throw IllegalStateException_init_$Create$(toString(message_0));
      }
      if (!this.r14_1.atomicfu$compareAndSet(curOwner, get_NO_OWNER()))
        continue $l$loop_0;
      this.dv();
      return Unit_getInstance();
    }
  };
  protoOf(MutexImpl).toString = function () {
    return 'Mutex@' + get_hexAddress(this) + '[isLocked=' + this.f14() + ',owner=' + toString_0(this.r14_1.kotlinx$atomicfu$value) + ']';
  };
  var properties_initialized_Mutex_kt_yv4p3j;
  function _init_properties_Mutex_kt__jod56b() {
    if (properties_initialized_Mutex_kt_yv4p3j) {
    } else {
      properties_initialized_Mutex_kt_yv4p3j = true;
      NO_OWNER = new Symbol('NO_OWNER');
      ON_LOCK_ALREADY_LOCKED_BY_OWNER = new Symbol('ALREADY_LOCKED_BY_OWNER');
    }
  }
  function get_MAX_SPIN_CYCLES() {
    _init_properties_Semaphore_kt__t514r6();
    return MAX_SPIN_CYCLES;
  }
  var MAX_SPIN_CYCLES;
  function get_PERMIT() {
    _init_properties_Semaphore_kt__t514r6();
    return PERMIT;
  }
  var PERMIT;
  function get_TAKEN() {
    _init_properties_Semaphore_kt__t514r6();
    return TAKEN;
  }
  var TAKEN;
  function get_BROKEN() {
    _init_properties_Semaphore_kt__t514r6();
    return BROKEN;
  }
  var BROKEN;
  function get_CANCELLED() {
    _init_properties_Semaphore_kt__t514r6();
    return CANCELLED;
  }
  var CANCELLED;
  function get_SEGMENT_SIZE() {
    _init_properties_Semaphore_kt__t514r6();
    return SEGMENT_SIZE;
  }
  var SEGMENT_SIZE;
  function decPermits($this) {
    $l$loop: while (true) {
      var p = $this.b15_1.atomicfu$getAndDecrement();
      if (p > $this.w14_1)
        continue $l$loop;
      return p;
    }
  }
  function coerceAvailablePermitsAtMaximum($this) {
    $l$loop_0: while (true) {
      var cur = $this.b15_1.kotlinx$atomicfu$value;
      if (cur <= $this.w14_1)
        break $l$loop_0;
      if ($this.b15_1.atomicfu$compareAndSet(cur, $this.w14_1))
        break $l$loop_0;
    }
  }
  function addAcquireToQueue($this, waiter) {
    var curTail = $this.z14_1.kotlinx$atomicfu$value;
    var enqIdx = $this.a15_1.atomicfu$getAndIncrement$long();
    var createNewSegment = createSegment$ref();
    var tmp$ret$2;
    $l$block_2: {
      // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
      var tmp3_findSegmentAndMoveForward = $this.z14_1;
      var tmp$ret$0;
      // Inline function 'kotlin.Long.div' call
      var tmp0_div = get_SEGMENT_SIZE();
      tmp$ret$0 = enqIdx.jb(toLong(tmp0_div));
      var tmp4_findSegmentAndMoveForward = tmp$ret$0;
      while (true) {
        var s = findSegmentInternal(curTail, tmp4_findSegmentAndMoveForward, createNewSegment);
        var tmp;
        if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
          tmp = true;
        } else {
          var tmp$ret$1;
          $l$block_1: {
            // Inline function 'kotlinx.coroutines.internal.moveForward' call
            var tmp2_moveForward = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
            while (true) {
              // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
              var tmp1__anonymous__uwfjfc = tmp3_findSegmentAndMoveForward.kotlinx$atomicfu$value;
              if (tmp1__anonymous__uwfjfc.at_1.k1(tmp2_moveForward.at_1) >= 0) {
                tmp$ret$1 = true;
                break $l$block_1;
              }
              if (!tmp2_moveForward.t13()) {
                tmp$ret$1 = false;
                break $l$block_1;
              }
              if (tmp3_findSegmentAndMoveForward.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, tmp2_moveForward)) {
                if (tmp1__anonymous__uwfjfc.u13()) {
                  tmp1__anonymous__uwfjfc.f5();
                }
                tmp$ret$1 = true;
                break $l$block_1;
              }
              if (tmp2_moveForward.u13()) {
                tmp2_moveForward.f5();
              }
            }
            tmp$ret$1 = Unit_getInstance();
          }
          tmp = tmp$ret$1;
        }
        if (tmp) {
          tmp$ret$2 = s;
          break $l$block_2;
        }
      }
    }
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(tmp$ret$2);
    var tmp$ret$3;
    // Inline function 'kotlin.Long.rem' call
    var tmp5_rem = get_SEGMENT_SIZE();
    tmp$ret$3 = enqIdx.c9(toLong(tmp5_rem));
    var i = tmp$ret$3.t1();
    var tmp$ret$4;
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
    tmp$ret$4 = segment.n15_1.atomicfu$get(i).atomicfu$compareAndSet(null, waiter);
    if (tmp$ret$4) {
      waiter.du(segment, i);
      return true;
    }
    var tmp$ret$5;
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
    var tmp6_cas = get_PERMIT();
    var tmp7_cas = get_TAKEN();
    tmp$ret$5 = segment.n15_1.atomicfu$get(i).atomicfu$compareAndSet(tmp6_cas, tmp7_cas);
    if (tmp$ret$5) {
      var tmp0_subject = waiter;
      if (isInterface(tmp0_subject, CancellableContinuation)) {
        if (isInterface(waiter, CancellableContinuation))
          waiter;
        else
          THROW_CCE();
        waiter.ds(Unit_getInstance(), $this.c15_1);
      } else {
        if (isInterface(tmp0_subject, SelectInstance)) {
          waiter.e14(Unit_getInstance());
        } else {
          var tmp8_error = 'unexpected: ' + waiter;
          throw IllegalStateException_init_$Create$(toString(tmp8_error));
        }
      }
      return true;
    }
    // Inline function 'kotlinx.coroutines.assert' call
    return false;
  }
  function tryResumeNextFromQueue($this) {
    var curHead = $this.x14_1.kotlinx$atomicfu$value;
    var deqIdx = $this.y14_1.atomicfu$getAndIncrement$long();
    var tmp$ret$0;
    // Inline function 'kotlin.Long.div' call
    var tmp0_div = get_SEGMENT_SIZE();
    tmp$ret$0 = deqIdx.jb(toLong(tmp0_div));
    var id = tmp$ret$0;
    var createNewSegment = createSegment$ref_0();
    var tmp$ret$2;
    $l$block_2: {
      // Inline function 'kotlinx.coroutines.internal.findSegmentAndMoveForward' call
      var tmp3_findSegmentAndMoveForward = $this.x14_1;
      while (true) {
        var s = findSegmentInternal(curHead, id, createNewSegment);
        var tmp;
        if (_SegmentOrClosed___get_isClosed__impl__qmxmlo(s)) {
          tmp = true;
        } else {
          var tmp$ret$1;
          $l$block_1: {
            // Inline function 'kotlinx.coroutines.internal.moveForward' call
            var tmp2_moveForward = _SegmentOrClosed___get_segment__impl__jvcr9l(s);
            while (true) {
              // Inline function 'kotlinx.coroutines.internal.moveForward.<anonymous>' call
              var tmp1__anonymous__uwfjfc = tmp3_findSegmentAndMoveForward.kotlinx$atomicfu$value;
              if (tmp1__anonymous__uwfjfc.at_1.k1(tmp2_moveForward.at_1) >= 0) {
                tmp$ret$1 = true;
                break $l$block_1;
              }
              if (!tmp2_moveForward.t13()) {
                tmp$ret$1 = false;
                break $l$block_1;
              }
              if (tmp3_findSegmentAndMoveForward.atomicfu$compareAndSet(tmp1__anonymous__uwfjfc, tmp2_moveForward)) {
                if (tmp1__anonymous__uwfjfc.u13()) {
                  tmp1__anonymous__uwfjfc.f5();
                }
                tmp$ret$1 = true;
                break $l$block_1;
              }
              if (tmp2_moveForward.u13()) {
                tmp2_moveForward.f5();
              }
            }
            tmp$ret$1 = Unit_getInstance();
          }
          tmp = tmp$ret$1;
        }
        if (tmp) {
          tmp$ret$2 = s;
          break $l$block_2;
        }
      }
    }
    var segment = _SegmentOrClosed___get_segment__impl__jvcr9l(tmp$ret$2);
    segment.z13();
    if (segment.at_1.k1(id) > 0)
      return false;
    var tmp$ret$3;
    // Inline function 'kotlin.Long.rem' call
    var tmp4_rem = get_SEGMENT_SIZE();
    tmp$ret$3 = deqIdx.c9(toLong(tmp4_rem));
    var i = tmp$ret$3.t1();
    var tmp$ret$4;
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.getAndSet' call
    var tmp5_getAndSet = get_PERMIT();
    tmp$ret$4 = segment.n15_1.atomicfu$get(i).atomicfu$getAndSet(tmp5_getAndSet);
    var cellState = tmp$ret$4;
    if (cellState === null) {
      // Inline function 'kotlin.repeat' call
      var tmp6_repeat = get_MAX_SPIN_CYCLES();
      // Inline function 'kotlin.contracts.contract' call
      var inductionVariable = 0;
      if (inductionVariable < tmp6_repeat)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.tryResumeNextFromQueue.<anonymous>' call
          var tmp$ret$5;
          // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.get' call
          tmp$ret$5 = segment.n15_1.atomicfu$get(i).kotlinx$atomicfu$value;
          if (tmp$ret$5 === get_TAKEN())
            return true;
        }
         while (inductionVariable < tmp6_repeat);
      var tmp$ret$6;
      // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.cas' call
      var tmp7_cas = get_PERMIT();
      var tmp8_cas = get_BROKEN();
      tmp$ret$6 = segment.n15_1.atomicfu$get(i).atomicfu$compareAndSet(tmp7_cas, tmp8_cas);
      return !tmp$ret$6;
    } else if (cellState === get_CANCELLED())
      return false;
    else
      return tryResumeAcquire(cellState, $this);
  }
  function tryResumeAcquire(_this__u8e3s4, $this) {
    var tmp0_subject = _this__u8e3s4;
    var tmp;
    if (isInterface(tmp0_subject, CancellableContinuation)) {
      if (isInterface(_this__u8e3s4, CancellableContinuation))
        _this__u8e3s4;
      else
        THROW_CCE();
      var token = _this__u8e3s4.zr(Unit_getInstance(), null, $this.c15_1);
      var tmp_0;
      if (!(token == null)) {
        _this__u8e3s4.as(token);
        tmp_0 = true;
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    } else {
      if (isInterface(tmp0_subject, SelectInstance)) {
        tmp = _this__u8e3s4.d14($this, Unit_getInstance());
      } else {
        var tmp0_error = 'unexpected: ' + toString(_this__u8e3s4);
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      }
    }
    return tmp;
  }
  function SemaphoreImpl$onCancellationRelease$lambda(this$0) {
    return function (_anonymous_parameter_0__qggqh8) {
      this$0.dv();
      return Unit_getInstance();
    };
  }
  function createSegment$ref() {
    var l = function (p0, p1) {
      return createSegment(p0, p1);
    };
    l.callableName = 'createSegment';
    return l;
  }
  function createSegment$ref_0() {
    var l = function (p0, p1) {
      return createSegment(p0, p1);
    };
    l.callableName = 'createSegment';
    return l;
  }
  function SemaphoreImpl(permits, acquiredPermits) {
    this.w14_1 = permits;
    this.y14_1 = atomic$long$1(new Long(0, 0));
    this.a15_1 = atomic$long$1(new Long(0, 0));
    // Inline function 'kotlin.require' call
    var tmp0_require = this.w14_1 > 0;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.<anonymous>' call
      tmp$ret$0 = 'Semaphore should have at least 1 permit, but had ' + this.w14_1;
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = 0 <= acquiredPermits ? acquiredPermits <= this.w14_1 : false;
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.<anonymous>' call
      tmp$ret$1 = 'The number of acquired permits should be in 0..' + this.w14_1;
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    var s = new SemaphoreSegment(new Long(0, 0), null, 2);
    this.x14_1 = atomic$ref$1(s);
    this.z14_1 = atomic$ref$1(s);
    this.b15_1 = atomic$int$1(this.w14_1 - acquiredPermits | 0);
    var tmp = this;
    tmp.c15_1 = SemaphoreImpl$onCancellationRelease$lambda(this);
  }
  protoOf(SemaphoreImpl).h15 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.b15_1.kotlinx$atomicfu$value;
    tmp$ret$0 = Math.max(tmp0_max, 0);
    return tmp$ret$0;
  };
  protoOf(SemaphoreImpl).d15 = function () {
    $l$loop: while (true) {
      var p = this.b15_1.kotlinx$atomicfu$value;
      if (p > this.w14_1) {
        coerceAvailablePermitsAtMaximum(this);
        continue $l$loop;
      }
      if (p <= 0)
        return false;
      if (this.b15_1.atomicfu$compareAndSet(p, p - 1 | 0))
        return true;
    }
  };
  protoOf(SemaphoreImpl).acquireCont = function (waiter) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire' call
      while (true) {
        var p = decPermits(this);
        if (p > 0) {
          // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire.<anonymous>' call
          waiter.ds(Unit_getInstance(), this.c15_1);
          tmp$ret$0 = Unit_getInstance();
          break $l$block_0;
        }
        var tmp$ret$1;
        // Inline function 'kotlinx.coroutines.sync.SemaphoreImpl.acquire.<anonymous>' call
        tmp$ret$1 = addAcquireToQueue(this, isInterface(waiter, Waiter) ? waiter : THROW_CCE());
        if (tmp$ret$1) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_0;
        }
      }
    }
    return tmp$ret$0;
  };
  protoOf(SemaphoreImpl).dv = function () {
    while (true) {
      var p = this.b15_1.atomicfu$getAndIncrement();
      if (p >= this.w14_1) {
        coerceAvailablePermitsAtMaximum(this);
        // Inline function 'kotlin.error' call
        var tmp0_error = 'The number of released permits cannot be greater than ' + this.w14_1;
        throw IllegalStateException_init_$Create$(toString(tmp0_error));
      }
      if (p >= 0)
        return Unit_getInstance();
      if (tryResumeNextFromQueue(this))
        return Unit_getInstance();
    }
  };
  function SemaphoreSegment(id, prev, pointers) {
    Segment.call(this, id, prev, pointers);
    this.n15_1 = atomicfu$AtomicRefArray$ofNulls(get_SEGMENT_SIZE());
  }
  protoOf(SemaphoreSegment).o13 = function () {
    return get_SEGMENT_SIZE();
  };
  protoOf(SemaphoreSegment).ct = function (index, cause, context) {
    // Inline function 'kotlinx.coroutines.sync.SemaphoreSegment.set' call
    var tmp0_set = get_CANCELLED();
    this.n15_1.atomicfu$get(index).kotlinx$atomicfu$value = tmp0_set;
    this.v13();
  };
  protoOf(SemaphoreSegment).toString = function () {
    return 'SemaphoreSegment[id=' + toString(this.at_1) + ', hashCode=' + hashCode(this) + ']';
  };
  function createSegment(id, prev) {
    _init_properties_Semaphore_kt__t514r6();
    return new SemaphoreSegment(id, prev, 0);
  }
  var properties_initialized_Semaphore_kt_uqcwok;
  function _init_properties_Semaphore_kt__t514r6() {
    if (properties_initialized_Semaphore_kt_uqcwok) {
    } else {
      properties_initialized_Semaphore_kt_uqcwok = true;
      MAX_SPIN_CYCLES = systemProp('kotlinx.coroutines.semaphore.maxSpinCycles', 100);
      PERMIT = new Symbol('PERMIT');
      TAKEN = new Symbol('TAKEN');
      BROKEN = new Symbol('BROKEN');
      CANCELLED = new Symbol('CANCELLED');
      SEGMENT_SIZE = systemProp('kotlinx.coroutines.semaphore.segmentSize', 16);
    }
  }
  function CompletionHandlerBase() {
    LinkedListNode.call(this);
  }
  function invokeIt(_this__u8e3s4, cause) {
    var tmp0_subject = typeof _this__u8e3s4;
    if (tmp0_subject === 'function')
      _this__u8e3s4(cause);
    else {
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = _this__u8e3s4;
      tmp$ret$0.invoke(cause);
    }
  }
  function CancelHandlerBase() {
  }
  function toDebugString(_this__u8e3s4) {
    return toString(_this__u8e3s4);
  }
  function get_DefaultDelay() {
    var tmp = Dispatchers_getInstance().fv_1;
    return isInterface(tmp, Delay) ? tmp : THROW_CCE();
  }
  function createDefaultDispatcher() {
    var tmp;
    if (isJsdom()) {
      tmp = NodeDispatcher_getInstance();
    } else {
      var tmp_0;
      var tmp_1;
      if (!(typeof window === 'undefined')) {
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp0_asDynamic = window;
        tmp$ret$0 = tmp0_asDynamic;
        tmp_1 = tmp$ret$0 != null;
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        var tmp$ret$1;
        // Inline function 'kotlin.js.asDynamic' call
        var tmp1_asDynamic = window;
        tmp$ret$1 = tmp1_asDynamic;
        tmp_0 = !(typeof tmp$ret$1.addEventListener === 'undefined');
      } else {
        tmp_0 = false;
      }
      if (tmp_0) {
        tmp = asCoroutineDispatcher(window);
      } else {
        if (typeof process === 'undefined' ? true : typeof process.nextTick === 'undefined') {
          tmp = SetTimeoutDispatcher_getInstance();
        } else {
          tmp = NodeDispatcher_getInstance();
        }
      }
    }
    return tmp;
  }
  function isJsdom() {
    return ((((!(typeof navigator === 'undefined') ? navigator != null : false) ? navigator.userAgent != null : false) ? !(typeof navigator.userAgent === 'undefined') : false) ? !(typeof navigator.userAgent.match === 'undefined') : false) ? navigator.userAgent.match('\\bjsdom\\b') : false;
  }
  function newCoroutineContext(_this__u8e3s4, context) {
    var combined = _this__u8e3s4.to().v8(context);
    return (!(combined === Dispatchers_getInstance().fv_1) ? combined.o8(Key_getInstance()) == null : false) ? combined.v8(Dispatchers_getInstance().fv_1) : combined;
  }
  function newCoroutineContext_0(_this__u8e3s4, addedContext) {
    return _this__u8e3s4.v8(addedContext);
  }
  function UndispatchedCoroutine(context, uCont) {
    ScopeCoroutine.call(this, context, uCont);
  }
  protoOf(UndispatchedCoroutine).dp = function (state) {
    return this.yr_1.q5(recoverResult(state, this.yr_1));
  };
  function get_coroutineName(_this__u8e3s4) {
    return null;
  }
  var counter;
  function get_DEBUG() {
    return DEBUG;
  }
  var DEBUG;
  function get_classSimpleName(_this__u8e3s4) {
    var tmp0_elvis_lhs = getKClassFromExpression(_this__u8e3s4).zi();
    return tmp0_elvis_lhs == null ? 'Unknown' : tmp0_elvis_lhs;
  }
  function get_hexAddress(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var result = tmp$ret$0.__debug_counter;
    if (!(typeof result === 'number')) {
      counter = counter + 1 | 0;
      result = counter;
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = _this__u8e3s4;
      tmp$ret$1.__debug_counter = result;
    }
    return ((!(result == null) ? typeof result === 'number' : false) ? result : THROW_CCE()).toString();
  }
  function Dispatchers() {
    Dispatchers_instance = this;
    this.fv_1 = createDefaultDispatcher();
    this.gv_1 = Unconfined_getInstance();
    this.hv_1 = new JsMainDispatcher(this.fv_1, false);
    this.iv_1 = null;
  }
  protoOf(Dispatchers).jv = function () {
    var tmp0_elvis_lhs = this.iv_1;
    return tmp0_elvis_lhs == null ? this.hv_1 : tmp0_elvis_lhs;
  };
  var Dispatchers_instance;
  function Dispatchers_getInstance() {
    if (Dispatchers_instance == null)
      new Dispatchers();
    return Dispatchers_instance;
  }
  function JsMainDispatcher(delegate, invokeImmediately) {
    MainCoroutineDispatcher.call(this);
    this.t15_1 = delegate;
    this.u15_1 = invokeImmediately;
    this.v15_1 = this.u15_1 ? this : new JsMainDispatcher(this.t15_1, true);
  }
  protoOf(JsMainDispatcher).i10 = function () {
    return this.v15_1;
  };
  protoOf(JsMainDispatcher).bv = function (context) {
    return !this.u15_1;
  };
  protoOf(JsMainDispatcher).cv = function (context, block) {
    return this.t15_1.cv(context, block);
  };
  protoOf(JsMainDispatcher).toString = function () {
    var tmp0_elvis_lhs = this.j10();
    return tmp0_elvis_lhs == null ? this.t15_1.toString() : tmp0_elvis_lhs;
  };
  function createEventLoop() {
    return new UnconfinedEventLoop();
  }
  function UnconfinedEventLoop() {
    EventLoop.call(this);
  }
  protoOf(UnconfinedEventLoop).cv = function (context, block) {
    unsupported();
  };
  function unsupported() {
    throw UnsupportedOperationException_init_$Create$('runBlocking event loop is not supported');
  }
  function JobCancellationException(message, cause, job) {
    CancellationException_init_$Init$_0(message, cause, this);
    captureStack(this, JobCancellationException);
    this.a16_1 = job;
  }
  protoOf(JobCancellationException).toString = function () {
    return protoOf(CancellationException).toString.call(this) + '; job=' + this.a16_1;
  };
  protoOf(JobCancellationException).equals = function (other) {
    var tmp;
    if (other === this) {
      tmp = true;
    } else {
      var tmp_0;
      var tmp_1;
      var tmp_2;
      if (other instanceof JobCancellationException) {
        tmp_2 = other.message == this.message;
      } else {
        tmp_2 = false;
      }
      if (tmp_2) {
        tmp_1 = equals(other.a16_1, this.a16_1);
      } else {
        tmp_1 = false;
      }
      if (tmp_1) {
        tmp_0 = equals(other.cause, this.cause);
      } else {
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(JobCancellationException).hashCode = function () {
    var tmp = imul(imul(getStringHashCode(ensureNotNull(this.message)), 31) + hashCode(this.a16_1) | 0, 31);
    var tmp0_safe_receiver = this.cause;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp + (tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs) | 0;
  };
  function NodeDispatcher() {
    NodeDispatcher_instance = this;
    SetTimeoutBasedDispatcher.call(this);
  }
  protoOf(NodeDispatcher).d16 = function () {
    process.nextTick(this.k16_1.h16_1);
  };
  var NodeDispatcher_instance;
  function NodeDispatcher_getInstance() {
    if (NodeDispatcher_instance == null)
      new NodeDispatcher();
    return NodeDispatcher_instance;
  }
  function SetTimeoutDispatcher() {
    SetTimeoutDispatcher_instance = this;
    SetTimeoutBasedDispatcher.call(this);
  }
  protoOf(SetTimeoutDispatcher).d16 = function () {
    setTimeout(this.k16_1.h16_1, 0);
  };
  var SetTimeoutDispatcher_instance;
  function SetTimeoutDispatcher_getInstance() {
    if (SetTimeoutDispatcher_instance == null)
      new SetTimeoutDispatcher();
    return SetTimeoutDispatcher_instance;
  }
  function SetTimeoutBasedDispatcher$ScheduledMessageQueue$processQueue$lambda(this$0) {
    return function () {
      this$0.q16();
      return Unit_getInstance();
    };
  }
  function ScheduledMessageQueue($outer) {
    this.i16_1 = $outer;
    MessageQueue.call(this);
    var tmp = this;
    tmp.h16_1 = SetTimeoutBasedDispatcher$ScheduledMessageQueue$processQueue$lambda(this);
  }
  protoOf(ScheduledMessageQueue).r16 = function () {
    this.i16_1.d16();
  };
  protoOf(ScheduledMessageQueue).s16 = function () {
    setTimeout(this.h16_1, 0);
  };
  function SetTimeoutBasedDispatcher$invokeOnTimeout$lambda($block) {
    return function () {
      $block.fu();
      return Unit_getInstance();
    };
  }
  function SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.with' call
      // Inline function 'kotlin.contracts.contract' call
      $continuation.cs(this$0, Unit_getInstance());
      tmp$ret$0 = Unit_getInstance();
      return Unit_getInstance();
    };
  }
  function SetTimeoutBasedDispatcher() {
    CoroutineDispatcher.call(this);
    this.k16_1 = new ScheduledMessageQueue(this);
  }
  protoOf(SetTimeoutBasedDispatcher).cv = function (context, block) {
    this.k16_1.b17(block);
  };
  protoOf(SetTimeoutBasedDispatcher).lv = function (timeMillis, block, context) {
    var handle = setTimeout(SetTimeoutBasedDispatcher$invokeOnTimeout$lambda(block), delayToInt(timeMillis));
    return new ClearTimeout(handle);
  };
  protoOf(SetTimeoutBasedDispatcher).kv = function (timeMillis, continuation) {
    var handle = setTimeout(SetTimeoutBasedDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new ClearTimeout(handle);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    continuation.bs(tmp$ret$1);
  };
  function MessageQueue() {
    this.n16_1 = ArrayDeque_init_$Create$();
    this.o16_1 = 16;
    this.p16_1 = false;
  }
  protoOf(MessageQueue).l = function () {
    return this.n16_1.p3_1;
  };
  protoOf(MessageQueue).t16 = function (element) {
    return this.n16_1.b(element);
  };
  protoOf(MessageQueue).b = function (element) {
    return this.t16((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  };
  protoOf(MessageQueue).u16 = function (elements) {
    return this.n16_1.m(elements);
  };
  protoOf(MessageQueue).m = function (elements) {
    return this.u16(elements);
  };
  protoOf(MessageQueue).e4 = function () {
    this.n16_1.e4();
  };
  protoOf(MessageQueue).v16 = function (element) {
    return this.n16_1.q(element);
  };
  protoOf(MessageQueue).q = function (element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.v16((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  };
  protoOf(MessageQueue).w16 = function (elements) {
    return this.n16_1.w1(elements);
  };
  protoOf(MessageQueue).w1 = function (elements) {
    return this.w16(elements);
  };
  protoOf(MessageQueue).n = function (index) {
    return this.n16_1.n(index);
  };
  protoOf(MessageQueue).x16 = function (element) {
    return this.n16_1.x(element);
  };
  protoOf(MessageQueue).x = function (element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return -1;
    return this.x16((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  };
  protoOf(MessageQueue).p = function () {
    return this.n16_1.p();
  };
  protoOf(MessageQueue).h = function () {
    return this.n16_1.h();
  };
  protoOf(MessageQueue).o = function (index) {
    return this.n16_1.o(index);
  };
  protoOf(MessageQueue).y16 = function (element) {
    return this.n16_1.b4(element);
  };
  protoOf(MessageQueue).b4 = function (element) {
    if (!(!(element == null) ? isInterface(element, Runnable) : false))
      return false;
    return this.y16((!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  };
  protoOf(MessageQueue).z16 = function (elements) {
    return this.n16_1.d4(elements);
  };
  protoOf(MessageQueue).d4 = function (elements) {
    return this.z16(elements);
  };
  protoOf(MessageQueue).c4 = function (index) {
    return this.n16_1.c4(index);
  };
  protoOf(MessageQueue).a17 = function (index, element) {
    return this.n16_1.a4(index, element);
  };
  protoOf(MessageQueue).a4 = function (index, element) {
    return this.a17(index, (!(element == null) ? isInterface(element, Runnable) : false) ? element : THROW_CCE());
  };
  protoOf(MessageQueue).w = function (fromIndex, toIndex) {
    return this.n16_1.w(fromIndex, toIndex);
  };
  protoOf(MessageQueue).b17 = function (element) {
    this.t16(element);
    if (!this.p16_1) {
      this.p16_1 = true;
      this.r16();
    }
  };
  protoOf(MessageQueue).q16 = function () {
    try {
      // Inline function 'kotlin.repeat' call
      var tmp0_repeat = this.o16_1;
      // Inline function 'kotlin.contracts.contract' call
      var inductionVariable = 0;
      if (inductionVariable < tmp0_repeat)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          // Inline function 'kotlinx.coroutines.MessageQueue.process.<anonymous>' call
          var tmp0_elvis_lhs = removeFirstOrNull(this);
          var tmp;
          if (tmp0_elvis_lhs == null) {
            return Unit_getInstance();
          } else {
            tmp = tmp0_elvis_lhs;
          }
          var element = tmp;
          element.fu();
        }
         while (inductionVariable < tmp0_repeat);
    }finally {
      if (this.p()) {
        this.p16_1 = false;
      } else {
        this.s16();
      }
    }
  };
  function delayToInt(timeMillis) {
    return coerceIn(timeMillis, new Long(0, 0), new Long(2147483647, 0)).t1();
  }
  function ClearTimeout(handle) {
    CancelHandler.call(this);
    this.c17_1 = handle;
  }
  protoOf(ClearTimeout).gs = function () {
    clearTimeout(this.c17_1);
  };
  protoOf(ClearTimeout).fs = function (cause) {
    this.gs();
  };
  protoOf(ClearTimeout).invoke = function (cause) {
    return this.fs(cause);
  };
  protoOf(ClearTimeout).toString = function () {
    return 'ClearTimeout[' + this.c17_1 + ']';
  };
  function WindowClearTimeout($outer, handle) {
    this.e17_1 = $outer;
    ClearTimeout.call(this, handle);
  }
  protoOf(WindowClearTimeout).gs = function () {
    this.e17_1.g17_1.clearTimeout(this.c17_1);
  };
  function WindowDispatcher$scheduleResumeAfterDelay$lambda($continuation, this$0) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.with' call
      // Inline function 'kotlin.contracts.contract' call
      $continuation.cs(this$0, Unit_getInstance());
      tmp$ret$0 = Unit_getInstance();
      return Unit_getInstance();
    };
  }
  function WindowDispatcher$invokeOnTimeout$lambda($block) {
    return function () {
      $block.fu();
      return Unit_getInstance();
    };
  }
  function WindowDispatcher(window_0) {
    CoroutineDispatcher.call(this);
    this.g17_1 = window_0;
    this.h17_1 = new WindowMessageQueue(this.g17_1);
  }
  protoOf(WindowDispatcher).cv = function (context, block) {
    return this.h17_1.b17(block);
  };
  protoOf(WindowDispatcher).kv = function (timeMillis, continuation) {
    var handle = this.g17_1.setTimeout(WindowDispatcher$scheduleResumeAfterDelay$lambda(continuation, this), delayToInt(timeMillis));
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.asHandler' call
    var tmp0__get_asHandler__gq3rkj = new WindowClearTimeout(this, handle);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0__get_asHandler__gq3rkj;
    tmp$ret$1 = tmp$ret$0;
    continuation.bs(tmp$ret$1);
  };
  protoOf(WindowDispatcher).lv = function (timeMillis, block, context) {
    var handle = this.g17_1.setTimeout(WindowDispatcher$invokeOnTimeout$lambda(block), delayToInt(timeMillis));
    return new WindowClearTimeout(this, handle);
  };
  function WindowMessageQueue$lambda(this$0) {
    return function (event) {
      var tmp;
      if (event.source == this$0.l17_1 ? event.data == this$0.m17_1 : false) {
        event.stopPropagation();
        this$0.q16();
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function WindowMessageQueue$schedule$lambda(this$0) {
    return function (it) {
      this$0.q16();
      return Unit_getInstance();
    };
  }
  function WindowMessageQueue(window_0) {
    MessageQueue.call(this);
    this.l17_1 = window_0;
    this.m17_1 = 'dispatchCoroutine';
    this.l17_1.addEventListener('message', WindowMessageQueue$lambda(this), true);
  }
  protoOf(WindowMessageQueue).r16 = function () {
    var tmp = Promise.resolve(Unit_getInstance());
    tmp.then(WindowMessageQueue$schedule$lambda(this));
  };
  protoOf(WindowMessageQueue).s16 = function () {
    this.l17_1.postMessage(this.m17_1, '*');
  };
  function Runnable() {
  }
  function SchedulerTask() {
  }
  function get_taskContext(_this__u8e3s4) {
    return Unit_getInstance();
  }
  function asCoroutineDispatcher(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var tmp0_elvis_lhs = tmp$ret$0.coroutineDispatcher;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp$ret$2;
      // Inline function 'kotlin.also' call
      var tmp0_also = new WindowDispatcher(_this__u8e3s4);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlinx.coroutines.asCoroutineDispatcher.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = _this__u8e3s4;
      tmp$ret$1.coroutineDispatcher = tmp0_also;
      tmp$ret$2 = tmp0_also;
      tmp = tmp$ret$2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function SafeCollector$collectContextSize$lambda(count, _anonymous_parameter_1__qggqgd) {
    return count + 1 | 0;
  }
  function SafeCollector(collector, collectContext) {
    this.m12_1 = collector;
    this.n12_1 = collectContext;
    var tmp = this;
    tmp.o12_1 = this.n12_1.u8(0, SafeCollector$collectContextSize$lambda);
    this.p12_1 = null;
  }
  protoOf(SafeCollector).m11 = function (value, $completion) {
    var tmp$ret$1;
    // Inline function 'kotlinx.coroutines.currentCoroutineContext' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.getCoroutineContext' call
    tmp$ret$0 = $completion.s5();
    tmp$ret$1 = tmp$ret$0;
    var currentContext = tmp$ret$1;
    ensureActive(currentContext);
    if (!(this.p12_1 === currentContext)) {
      checkContext(this, currentContext);
      this.p12_1 = currentContext;
    }
    var tmp0 = this.m12_1.m11(value, $completion);
    return tmp0;
  };
  protoOf(SafeCollector).q12 = function () {
  };
  function identitySet(expectedSize) {
    return HashSet_init_$Create$(expectedSize);
  }
  function get_platformExceptionHandlers_() {
    _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
    return platformExceptionHandlers_;
  }
  var platformExceptionHandlers_;
  function get_platformExceptionHandlers() {
    _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
    return get_platformExceptionHandlers_();
  }
  function propagateExceptionFinalResort(exception) {
    _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf();
    console.error(exception);
  }
  function DiagnosticCoroutineContextException(context) {
    RuntimeException_init_$Init$_0(toString(context), this);
    captureStack(this, DiagnosticCoroutineContextException);
  }
  var properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx;
  function _init_properties_CoroutineExceptionHandlerImpl_kt__37d7wf() {
    if (properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx) {
    } else {
      properties_initialized_CoroutineExceptionHandlerImpl_kt_qhrgvx = true;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.mutableSetOf' call
      tmp$ret$0 = LinkedHashSet_init_$Create$();
      platformExceptionHandlers_ = tmp$ret$0;
    }
  }
  function LinkedListHead() {
    LinkedListNode.call(this);
  }
  protoOf(LinkedListHead).pw = function () {
    throw UnsupportedOperationException_init_$Create$_0();
  };
  function LinkedListNode() {
    this.jw_1 = this;
    this.kw_1 = this;
    this.lw_1 = false;
  }
  protoOf(LinkedListNode).qw = function (node) {
    var prev = this.kw_1;
    node.jw_1 = this;
    node.kw_1 = prev;
    prev.jw_1 = node;
    this.kw_1 = node;
  };
  protoOf(LinkedListNode).pw = function () {
    return this.rw();
  };
  protoOf(LinkedListNode).gs = function () {
    this.pw();
  };
  protoOf(LinkedListNode).rw = function () {
    if (this.lw_1)
      return false;
    var prev = this.kw_1;
    var next = this.jw_1;
    prev.jw_1 = next;
    next.kw_1 = prev;
    this.lw_1 = true;
    return true;
  };
  protoOf(LinkedListNode).xw = function (node) {
    if (!(this.jw_1 === this))
      return false;
    this.qw(node);
    return true;
  };
  function unwrap(exception) {
    return exception;
  }
  function recoverStackTrace(exception, continuation) {
    return exception;
  }
  function systemProp_1(propertyName) {
    return null;
  }
  function threadContextElements(context) {
    return 0;
  }
  function CommonThreadLocal() {
    this.aw_1 = null;
  }
  protoOf(CommonThreadLocal).bw = function () {
    var tmp = this.aw_1;
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  protoOf(CommonThreadLocal).cw = function (value) {
    this.aw_1 = value;
  };
  function commonThreadLocal(name) {
    return new CommonThreadLocal();
  }
  //region block: post-declaration
  protoOf(JobSupport).xp = invokeOnCompletion$default;
  protoOf(JobSupport).cq = cancel$default;
  protoOf(JobSupport).v8 = plus;
  protoOf(JobSupport).o8 = get;
  protoOf(JobSupport).u8 = fold;
  protoOf(JobSupport).t8 = minusKey;
  protoOf(AbstractCoroutine).xp = invokeOnCompletion$default;
  protoOf(AbstractCoroutine).cq = cancel$default;
  protoOf(AbstractCoroutine).v8 = plus;
  protoOf(AbstractCoroutine).o8 = get;
  protoOf(AbstractCoroutine).u8 = fold;
  protoOf(AbstractCoroutine).t8 = minusKey;
  protoOf(StandaloneCoroutine).xp = invokeOnCompletion$default;
  protoOf(StandaloneCoroutine).cq = cancel$default;
  protoOf(StandaloneCoroutine).v8 = plus;
  protoOf(StandaloneCoroutine).o8 = get;
  protoOf(StandaloneCoroutine).u8 = fold;
  protoOf(StandaloneCoroutine).t8 = minusKey;
  protoOf(LazyStandaloneCoroutine).xp = invokeOnCompletion$default;
  protoOf(LazyStandaloneCoroutine).cq = cancel$default;
  protoOf(LazyStandaloneCoroutine).v8 = plus;
  protoOf(LazyStandaloneCoroutine).o8 = get;
  protoOf(LazyStandaloneCoroutine).u8 = fold;
  protoOf(LazyStandaloneCoroutine).t8 = minusKey;
  protoOf(DeferredCoroutine).xp = invokeOnCompletion$default;
  protoOf(DeferredCoroutine).cq = cancel$default;
  protoOf(DeferredCoroutine).v8 = plus;
  protoOf(DeferredCoroutine).o8 = get;
  protoOf(DeferredCoroutine).u8 = fold;
  protoOf(DeferredCoroutine).t8 = minusKey;
  protoOf(LazyDeferredCoroutine).xp = invokeOnCompletion$default;
  protoOf(LazyDeferredCoroutine).cq = cancel$default;
  protoOf(LazyDeferredCoroutine).v8 = plus;
  protoOf(LazyDeferredCoroutine).o8 = get;
  protoOf(LazyDeferredCoroutine).u8 = fold;
  protoOf(LazyDeferredCoroutine).t8 = minusKey;
  protoOf(ScopeCoroutine).xp = invokeOnCompletion$default;
  protoOf(ScopeCoroutine).cq = cancel$default;
  protoOf(ScopeCoroutine).v8 = plus;
  protoOf(ScopeCoroutine).o8 = get;
  protoOf(ScopeCoroutine).u8 = fold;
  protoOf(ScopeCoroutine).t8 = minusKey;
  protoOf(DispatchedCoroutine).xp = invokeOnCompletion$default;
  protoOf(DispatchedCoroutine).cq = cancel$default;
  protoOf(DispatchedCoroutine).v8 = plus;
  protoOf(DispatchedCoroutine).o8 = get;
  protoOf(DispatchedCoroutine).u8 = fold;
  protoOf(DispatchedCoroutine).t8 = minusKey;
  protoOf(CompletableDeferredImpl).xp = invokeOnCompletion$default;
  protoOf(CompletableDeferredImpl).cq = cancel$default;
  protoOf(CompletableDeferredImpl).v8 = plus;
  protoOf(CompletableDeferredImpl).o8 = get;
  protoOf(CompletableDeferredImpl).u8 = fold;
  protoOf(CompletableDeferredImpl).t8 = minusKey;
  protoOf(CoroutineDispatcher).o8 = get_0;
  protoOf(CoroutineDispatcher).u8 = fold;
  protoOf(CoroutineDispatcher).t8 = minusKey_0;
  protoOf(CoroutineDispatcher).v8 = plus;
  protoOf(EventLoop).v8 = plus;
  protoOf(EventLoop).o8 = get_0;
  protoOf(EventLoop).u8 = fold;
  protoOf(EventLoop).t8 = minusKey_0;
  protoOf(JobImpl).xp = invokeOnCompletion$default;
  protoOf(JobImpl).cq = cancel$default;
  protoOf(JobImpl).v8 = plus;
  protoOf(JobImpl).o8 = get;
  protoOf(JobImpl).u8 = fold;
  protoOf(JobImpl).t8 = minusKey;
  protoOf(MainCoroutineDispatcher).v8 = plus;
  protoOf(MainCoroutineDispatcher).o8 = get_0;
  protoOf(MainCoroutineDispatcher).u8 = fold;
  protoOf(MainCoroutineDispatcher).t8 = minusKey_0;
  protoOf(SupervisorJobImpl).xp = invokeOnCompletion$default;
  protoOf(SupervisorJobImpl).cq = cancel$default;
  protoOf(SupervisorJobImpl).v8 = plus;
  protoOf(SupervisorJobImpl).o8 = get;
  protoOf(SupervisorJobImpl).u8 = fold;
  protoOf(SupervisorJobImpl).t8 = minusKey;
  protoOf(TimeoutCoroutine).xp = invokeOnCompletion$default;
  protoOf(TimeoutCoroutine).cq = cancel$default;
  protoOf(TimeoutCoroutine).v8 = plus;
  protoOf(TimeoutCoroutine).o8 = get;
  protoOf(TimeoutCoroutine).u8 = fold;
  protoOf(TimeoutCoroutine).t8 = minusKey;
  protoOf(Unconfined).v8 = plus;
  protoOf(Unconfined).o8 = get_0;
  protoOf(Unconfined).u8 = fold;
  protoOf(Unconfined).t8 = minusKey_0;
  protoOf(MutexImpl).h14 = lock$default;
  protoOf(MutexImpl).j14 = unlock$default;
  protoOf(UndispatchedCoroutine).xp = invokeOnCompletion$default;
  protoOf(UndispatchedCoroutine).cq = cancel$default;
  protoOf(UndispatchedCoroutine).v8 = plus;
  protoOf(UndispatchedCoroutine).o8 = get;
  protoOf(UndispatchedCoroutine).u8 = fold;
  protoOf(UndispatchedCoroutine).t8 = minusKey;
  protoOf(JsMainDispatcher).v8 = plus;
  protoOf(JsMainDispatcher).o8 = get_0;
  protoOf(JsMainDispatcher).u8 = fold;
  protoOf(JsMainDispatcher).t8 = minusKey_0;
  protoOf(UnconfinedEventLoop).v8 = plus;
  protoOf(UnconfinedEventLoop).o8 = get_0;
  protoOf(UnconfinedEventLoop).u8 = fold;
  protoOf(UnconfinedEventLoop).t8 = minusKey_0;
  protoOf(SetTimeoutBasedDispatcher).v8 = plus;
  protoOf(SetTimeoutBasedDispatcher).o8 = get_0;
  protoOf(SetTimeoutBasedDispatcher).u8 = fold;
  protoOf(SetTimeoutBasedDispatcher).t8 = minusKey_0;
  protoOf(NodeDispatcher).v8 = plus;
  protoOf(NodeDispatcher).o8 = get_0;
  protoOf(NodeDispatcher).u8 = fold;
  protoOf(NodeDispatcher).t8 = minusKey_0;
  protoOf(SetTimeoutDispatcher).v8 = plus;
  protoOf(SetTimeoutDispatcher).o8 = get_0;
  protoOf(SetTimeoutDispatcher).u8 = fold;
  protoOf(SetTimeoutDispatcher).t8 = minusKey_0;
  protoOf(WindowDispatcher).v8 = plus;
  protoOf(WindowDispatcher).o8 = get_0;
  protoOf(WindowDispatcher).u8 = fold;
  protoOf(WindowDispatcher).t8 = minusKey_0;
  //endregion
  //region block: init
  MODE_CANCELLABLE = 1;
  MODE_CANCELLABLE_REUSABLE = 2;
  MODE_UNINITIALIZED = -1;
  MODE_UNDISPATCHED = 4;
  MODE_ATOMIC = 0;
  counter = 0;
  DEBUG = false;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = CoroutineStart_UNDISPATCHED_getInstance;
  _.$_$.b = toList;
  _.$_$.c = cancelAndJoin;
  _.$_$.d = coroutineScope;
  _.$_$.e = delay;
  _.$_$.f = withContext;
  _.$_$.g = withTimeout;
  _.$_$.h = Dispatchers_getInstance;
  _.$_$.i = GlobalScope_getInstance;
  _.$_$.j = Key_getInstance_2;
  _.$_$.k = FlowCollector;
  _.$_$.l = asFlow;
  _.$_$.m = flowOf;
  _.$_$.n = flow;
  _.$_$.o = Mutex_0;
  _.$_$.p = CancellableContinuationImpl;
  _.$_$.q = CompletableDeferred;
  _.$_$.r = CoroutineDispatcher;
  _.$_$.s = CoroutineScope_0;
  _.$_$.t = CoroutineScope;
  _.$_$.u = Deferred;
  _.$_$.v = invokeOnTimeout;
  _.$_$.w = Delay;
  _.$_$.x = Job_0;
  _.$_$.y = get_MODE_CANCELLABLE;
  _.$_$.z = MainScope;
  _.$_$.a1 = SupervisorJob;
  _.$_$.b1 = TimeoutCancellationException;
  _.$_$.c1 = async;
  _.$_$.d1 = cancelChildren;
  _.$_$.e1 = cancel;
  _.$_$.f1 = completeWith;
  _.$_$.g1 = launch;
  //endregion
  return _;
}));
