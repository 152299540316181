(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-kds'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-kds'.");
    }
    root['korge-root-kds'] = factory(typeof this['korge-root-kds'] === 'undefined' ? {} : this['korge-root-kds'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var log2 = Math.log2;
  var protoOf = kotlin_kotlin.$_$.nd;
  var objectMeta = kotlin_kotlin.$_$.md;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var contentEquals = kotlin_kotlin.$_$.j6;
  var contentHashCode = kotlin_kotlin.$_$.k6;
  var intArrayIterator = kotlin_kotlin.$_$.mc;
  var classMeta = kotlin_kotlin.$_$.zb;
  var contentEquals_0 = kotlin_kotlin.$_$.i6;
  var contentHashCode_0 = kotlin_kotlin.$_$.m6;
  var floatArrayIterator = kotlin_kotlin.$_$.fc;
  var contentEquals_1 = kotlin_kotlin.$_$.h6;
  var contentHashCode_1 = kotlin_kotlin.$_$.l6;
  var arrayIterator = kotlin_kotlin.$_$.rb;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var toString = kotlin_kotlin.$_$.sd;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var reverse = kotlin_kotlin.$_$.i9;
  var copyOf = kotlin_kotlin.$_$.w6;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var isDoubleArray = kotlin_kotlin.$_$.uc;
  var IndexOutOfBoundsException_init_$Create$ = kotlin_kotlin.$_$.r1;
  var take = kotlin_kotlin.$_$.v9;
  var equals = kotlin_kotlin.$_$.cc;
  var List = kotlin_kotlin.$_$.k5;
  var isInterface = kotlin_kotlin.$_$.xc;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.d1;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var Collection = kotlin_kotlin.$_$.d5;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var copyOf_0 = kotlin_kotlin.$_$.x6;
  var isFloatArray = kotlin_kotlin.$_$.vc;
  var take_0 = kotlin_kotlin.$_$.t9;
  var isIntArray = kotlin_kotlin.$_$.wc;
  var copyOf_1 = kotlin_kotlin.$_$.z6;
  var take_1 = kotlin_kotlin.$_$.u9;
  var compareTo = kotlin_kotlin.$_$.ac;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var isObject = kotlin_kotlin.$_$.ad;
  var MutableMap = kotlin_kotlin.$_$.p5;
  var hashCode = kotlin_kotlin.$_$.lc;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var isArray = kotlin_kotlin.$_$.oc;
  var toSet = kotlin_kotlin.$_$.ka;
  var toList = kotlin_kotlin.$_$.da;
  var until = kotlin_kotlin.$_$.me;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.k;
  var to = kotlin_kotlin.$_$.lj;
  var Map_0 = kotlin_kotlin.$_$.m5;
  var StringBuilder_init_$Create$_0 = kotlin_kotlin.$_$.e1;
  var MutableCollection = kotlin_kotlin.$_$.n5;
  var addAll = kotlin_kotlin.$_$.t5;
  var MutableList = kotlin_kotlin.$_$.o5;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.t;
  var AbstractMutableSet = kotlin_kotlin.$_$.b5;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var IndexOutOfBoundsException_init_$Create$_0 = kotlin_kotlin.$_$.s1;
  var toMutableSet = kotlin_kotlin.$_$.ja;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.t1;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var coerceAtMost = kotlin_kotlin.$_$.ee;
  var joinToString = kotlin_kotlin.$_$.b8;
  var Iterable = kotlin_kotlin.$_$.h5;
  var IllegalStateException_init_$Create$_0 = kotlin_kotlin.$_$.o1;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var copyOf_2 = kotlin_kotlin.$_$.y6;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var Comparator = kotlin_kotlin.$_$.oh;
  var reversed = kotlin_kotlin.$_$.sa;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.m;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var getOrNull = kotlin_kotlin.$_$.r7;
  var Pair = kotlin_kotlin.$_$.yh;
  var toMap = kotlin_kotlin.$_$.ga;
  var step = kotlin_kotlin.$_$.ke;
  var arrayCopy = kotlin_kotlin.$_$.w5;
  var toRawBits = kotlin_kotlin.$_$.jj;
  var AbstractMutableList = kotlin_kotlin.$_$.a5;
  var copyToArray = kotlin_kotlin.$_$.a7;
  var get_lastIndex = kotlin_kotlin.$_$.f8;
  var joinToString_0 = kotlin_kotlin.$_$.c8;
  var RandomAccess = kotlin_kotlin.$_$.r5;
  var toList_0 = kotlin_kotlin.$_$.ea;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Companion, 'Companion', objectMeta);
  function getAt(x, y) {
    return this.z18(index(this, x, y));
  }
  function asString() {
    var tmp = until(0, this.y18());
    return joinToString(tmp, '\n', VOID, VOID, VOID, VOID, IArray2$asString$lambda(this));
  }
  setMetadataFor(IArray2, 'IArray2', interfaceMeta, VOID, [Iterable]);
  setMetadataFor(IntArray2, 'IntArray2', classMeta, VOID, [IArray2]);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(FloatArray2, 'FloatArray2', classMeta, VOID, [IArray2]);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(Array2, 'Array2', classMeta, VOID, [IArray2]);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(DoubleArrayList, 'DoubleArrayList', classMeta, VOID, [Collection]);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(FloatArrayList, 'FloatArrayList', classMeta, VOID, [Collection]);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(IntArrayList, 'IntArrayList', classMeta, VOID, [Collection]);
  setMetadataFor(SortOps, 'SortOps', classMeta);
  setMetadataFor(FloatArrayListSortOps, 'FloatArrayListSortOps', objectMeta, SortOps);
  setMetadataFor(DoubleArrayListSortOps, 'DoubleArrayListSortOps', objectMeta, SortOps);
  setMetadataFor(CaseInsensitiveStringMap, 'CaseInsensitiveStringMap', classMeta, VOID, [MutableMap]);
  setMetadataFor(TGenDeque$iterator$1, VOID, classMeta);
  setMetadataFor(TGenDeque, 'TGenDeque', classMeta, VOID, [MutableCollection]);
  setMetadataFor(IntDeque$iterator$1, VOID, classMeta);
  setMetadataFor(IntDeque, 'IntDeque', classMeta, VOID, [MutableCollection]);
  function setAddAll(index, elements, offset, size) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.l() - index | 0;
    tmp$ret$0 = Math.min(size, tmp0_min);
    var setCount = tmp$ret$0;
    this.g1d(index, elements, offset, setCount);
    this.h1d(elements, offset + setCount | 0, size - setCount | 0);
  }
  function setAddAll$default(index, elements, offset, size, $super) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? elements.l() - offset | 0 : size;
    var tmp;
    if ($super === VOID) {
      this.f1d(index, elements, offset, size);
      tmp = Unit_getInstance();
    } else {
      setAddAll(index, elements, offset, size);
      tmp = Unit_getInstance();
    }
    return tmp;
  }
  function setAll(index, elements, offset, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.a4(index + n | 0, elements.n(offset + n | 0));
      }
       while (inductionVariable < size);
  }
  function addAll_0(elements, offset, size) {
    var inductionVariable = 0;
    if (inductionVariable < size)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.b(elements.n(offset + n | 0));
      }
       while (inductionVariable < size);
  }
  function removeToSize(size) {
    this.i4(size, this.l());
  }
  setMetadataFor(MutableListEx, 'MutableListEx', interfaceMeta, VOID, [MutableList]);
  setMetadataFor(FastSmallSet, 'FastSmallSet', classMeta, AbstractMutableSet);
  setMetadataFor(GenericSubList, 'GenericSubList', classMeta, VOID, [List]);
  setMetadataFor(GenericListIterator, 'GenericListIterator', classMeta);
  setMetadataFor(HistoryStack, 'HistoryStack', classMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(IntIntMap, 'IntIntMap', classMeta);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(Entry, 'Entry', classMeta);
  setMetadataFor(Iterator, 'Iterator', classMeta);
  setMetadataFor(_no_name_provided__qut3iv, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(_no_name_provided__qut3iv_0, VOID, classMeta, VOID, [Iterable]);
  setMetadataFor(IntMap, 'IntMap', classMeta);
  setMetadataFor(Iterator$1, VOID, classMeta);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(Pool, 'Pool', classMeta);
  setMetadataFor(ConcurrentPool, 'ConcurrentPool', classMeta, Pool);
  setMetadataFor(ReturnablePool, 'ReturnablePool', classMeta);
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(TGenPriorityQueue$iterator$1, VOID, classMeta);
  setMetadataFor(TGenPriorityQueue, 'TGenPriorityQueue', classMeta, VOID, [MutableCollection]);
  setMetadataFor(TGenQueue, 'TGenQueue', classMeta, VOID, [Collection]);
  setMetadataFor(Ref$2, VOID, classMeta);
  setMetadataFor(Ref$3, VOID, classMeta);
  setMetadataFor(ByteRingBuffer, 'ByteRingBuffer', classMeta);
  setMetadataFor(RingBuffer, 'RingBuffer', classMeta, ByteRingBuffer);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(TGenStack, 'TGenStack', classMeta, VOID, [Collection]);
  setMetadataFor(Mixin, 'Mixin', classMeta);
  setMetadataFor(Property, 'Property', classMeta);
  setMetadataFor(PropertyThis, 'PropertyThis', classMeta);
  setMetadataFor(FastArrayList, 'FastArrayList', classMeta, AbstractMutableList, [AbstractMutableList, MutableListEx, RandomAccess]);
  setMetadataFor(NonRecursiveLock, 'NonRecursiveLock', classMeta);
  setMetadataFor(Lock, 'Lock', classMeta);
  //endregion
  function Companion() {
    Companion_instance = this;
  }
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function IntArray2(width, height, data) {
    Companion_getInstance();
    this.t18_1 = width;
    this.u18_1 = height;
    this.v18_1 = data;
    Companion_getInstance_5().w18(this.t18_1, this.u18_1, this.v18_1.length);
  }
  protoOf(IntArray2).x18 = function () {
    return this.t18_1;
  };
  protoOf(IntArray2).y18 = function () {
    return this.u18_1;
  };
  protoOf(IntArray2).z18 = function (idx) {
    return this.v18_1[idx];
  };
  protoOf(IntArray2).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof IntArray2) {
      tmp_1 = this.t18_1 === other.t18_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.u18_1 === other.u18_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals(this.v18_1, other.v18_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(IntArray2).a19 = function (x, y) {
    return this.v18_1[index(this, x, y)];
  };
  protoOf(IntArray2).b19 = function (x, y, value) {
    this.v18_1[index(this, x, y)] = value;
  };
  protoOf(IntArray2).hashCode = function () {
    return (this.t18_1 + this.u18_1 | 0) + contentHashCode(this.v18_1) | 0;
  };
  protoOf(IntArray2).h = function () {
    return intArrayIterator(this.v18_1);
  };
  protoOf(IntArray2).toString = function () {
    return this.c19();
  };
  function Companion_0() {
    Companion_instance_0 = this;
  }
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function FloatArray2(width, height, data) {
    Companion_getInstance_0();
    this.e19_1 = width;
    this.f19_1 = height;
    this.g19_1 = data;
    Companion_getInstance_5().w18(this.e19_1, this.f19_1, this.g19_1.length);
  }
  protoOf(FloatArray2).x18 = function () {
    return this.e19_1;
  };
  protoOf(FloatArray2).y18 = function () {
    return this.f19_1;
  };
  protoOf(FloatArray2).z18 = function (idx) {
    return this.g19_1[idx];
  };
  protoOf(FloatArray2).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof FloatArray2) {
      tmp_1 = this.e19_1 === other.e19_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.f19_1 === other.f19_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_0(this.g19_1, other.g19_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(FloatArray2).a19 = function (x, y) {
    return this.g19_1[index(this, x, y)];
  };
  protoOf(FloatArray2).h19 = function (x, y, value) {
    this.g19_1[index(this, x, y)] = value;
  };
  protoOf(FloatArray2).hashCode = function () {
    return (this.e19_1 + this.f19_1 | 0) + contentHashCode_0(this.g19_1) | 0;
  };
  protoOf(FloatArray2).h = function () {
    return floatArrayIterator(this.g19_1);
  };
  protoOf(FloatArray2).toString = function () {
    return this.c19();
  };
  function Companion_1() {
    Companion_instance_1 = this;
  }
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function Array2(width, height, data) {
    Companion_getInstance_1();
    this.i19_1 = width;
    this.j19_1 = height;
    this.k19_1 = data;
    Companion_getInstance_5().w18(this.i19_1, this.j19_1, this.k19_1.length);
  }
  protoOf(Array2).x18 = function () {
    return this.i19_1;
  };
  protoOf(Array2).y18 = function () {
    return this.j19_1;
  };
  protoOf(Array2).z18 = function (idx) {
    return this.k19_1[idx];
  };
  protoOf(Array2).equals = function (other) {
    var tmp;
    var tmp_0;
    var tmp_1;
    if (other instanceof Array2) {
      tmp_1 = this.i19_1 === other.i19_1;
    } else {
      tmp_1 = false;
    }
    if (tmp_1) {
      tmp_0 = this.j19_1 === other.j19_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = contentEquals_1(this.k19_1, other.k19_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(Array2).a19 = function (x, y) {
    return this.k19_1[index(this, x, y)];
  };
  protoOf(Array2).hashCode = function () {
    return (this.i19_1 + this.j19_1 | 0) + contentHashCode_1(this.k19_1) | 0;
  };
  protoOf(Array2).h = function () {
    return arrayIterator(this.k19_1);
  };
  protoOf(Array2).toString = function () {
    return this.c19();
  };
  function swap(_this__u8e3s4, lIndex, rIndex) {
    var temp = _this__u8e3s4[lIndex];
    _this__u8e3s4[lIndex] = _this__u8e3s4[rIndex];
    _this__u8e3s4[rIndex] = temp;
  }
  function rotateRight(_this__u8e3s4, offset) {
    offset = offset === VOID ? 1 : offset;
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.datastructure._rotateRight' call
      var tmp1__rotateRight = _this__u8e3s4.length;
      var offset_0 = umod(offset, tmp1__rotateRight);
      if (offset_0 === 0) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block;
      }
      // Inline function 'kotlin.check' call
      var tmp0_check = 1 <= offset_0 ? offset_0 < tmp1__rotateRight : false;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.check' call
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_check) {
        var tmp$ret$1;
        // Inline function 'kotlin.check.<anonymous>' call
        tmp$ret$1 = 'Check failed.';
        var message = tmp$ret$1;
        throw IllegalStateException_init_$Create$(toString(message));
      }
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, 0, tmp1__rotateRight);
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, 0, offset_0);
      // Inline function 'korlibs.datastructure.rotateRight.<anonymous>' call
      reverse(_this__u8e3s4, offset_0, tmp1__rotateRight);
    }
    return tmp$ret$0;
  }
  function Companion_2() {
    Companion_instance_2 = this;
  }
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function ensure($this, count) {
    if (($this.m19_1 + count | 0) > $this.l19_1.length) {
      var tmp = $this;
      var tmp_0 = $this.l19_1;
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      var tmp0_max = $this.m19_1 + count | 0;
      var tmp1_max = imul($this.l19_1.length, 3);
      tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
      var tmp_1 = copyOf(tmp_0, tmp$ret$0);
      tmp.l19_1 = isDoubleArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  function DoubleArrayList(capacity) {
    Companion_getInstance_2();
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Float64Array(capacity);
    tmp.l19_1 = isDoubleArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.m19_1 = 0;
  }
  protoOf(DoubleArrayList).l = function () {
    return this.m19_1;
  };
  protoOf(DoubleArrayList).e4 = function () {
    this.m19_1 = 0;
  };
  protoOf(DoubleArrayList).n19 = function (v0) {
    ensure(this, 1);
    var tmp = this.l19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m19_1;
    tmp0_this.m19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
  };
  protoOf(DoubleArrayList).o19 = function (v0, v1) {
    ensure(this, 2);
    var tmp = this.l19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m19_1;
    tmp0_this.m19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.l19_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.m19_1;
    tmp2_this.m19_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
  };
  protoOf(DoubleArrayList).p19 = function (value) {
    return this.n19(value);
  };
  protoOf(DoubleArrayList).n = function (index) {
    return this.l19_1[index];
  };
  protoOf(DoubleArrayList).z18 = function (index) {
    return this.l19_1[index];
  };
  protoOf(DoubleArrayList).q19 = function (index, value) {
    if (index >= this.m19_1) {
      ensure(this, index + 1 | 0);
      this.m19_1 = index + 1 | 0;
    }
    this.l19_1[index] = value;
  };
  protoOf(DoubleArrayList).h = function () {
    return this.o(0);
  };
  protoOf(DoubleArrayList).r19 = function (element) {
    var inductionVariable = 0;
    var last = this.m19_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.l19_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(DoubleArrayList).q = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.r19((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(DoubleArrayList).s19 = function (elements) {
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var e = tmp0_iterator.k();
      if (!this.r19(e))
        return false;
    }
    return true;
  };
  protoOf(DoubleArrayList).w1 = function (elements) {
    return this.s19(elements);
  };
  protoOf(DoubleArrayList).p = function () {
    return this.l() === 0;
  };
  protoOf(DoubleArrayList).t19 = function (indexA, indexB) {
    var l = this.z18(indexA);
    var r = this.z18(indexB);
    this.q19(indexA, r);
    this.q19(indexB, l);
  };
  protoOf(DoubleArrayList).c4 = function (index) {
    return this.u19(index, 1);
  };
  protoOf(DoubleArrayList).u19 = function (index, count) {
    if ((index < 0 ? true : index >= this.m19_1) ? true : (index + count | 0) > this.m19_1)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.l19_1[index];
    if (count > 0) {
      if (index < (this.m19_1 - count | 0)) {
        arraycopy(this.l19_1, index + count | 0, this.l19_1, index, (this.m19_1 - index | 0) - count | 0);
      }
      var tmp0_this = this;
      tmp0_this.m19_1 = tmp0_this.m19_1 - count | 0;
    }
    return out;
  };
  protoOf(DoubleArrayList).o = function (index) {
    return take(this.l19_1, this.m19_1).o(index);
  };
  protoOf(DoubleArrayList).hashCode = function () {
    return contentHashCode_2(this.l19_1, 0, this.l());
  };
  protoOf(DoubleArrayList).equals = function (other) {
    if (other instanceof DoubleArrayList)
      return this.l() === other.l() ? contentEquals_2(this.l19_1, other.l19_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(DoubleArrayList).toString = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.DoubleArrayList.toString.<anonymous>' call
    tmp0_also.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          tmp0_also.bc(', ');
        }
        tmp0_also.ac(this.z18(n));
      }
       while (inductionVariable < last);
    tmp0_also.ib(_Char___init__impl__6a9atx(93));
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0.toString();
  };
  function Companion_3() {
    Companion_instance_3 = this;
  }
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function FloatArrayList_init_$Init$(other, $this) {
    FloatArrayList.call($this);
    $this.x19(other);
    return $this;
  }
  function FloatArrayList_init_$Create$(other) {
    return FloatArrayList_init_$Init$(other, objectCreate(protoOf(FloatArrayList)));
  }
  function FloatArrayList_init_$Init$_0(other, $this) {
    FloatArrayList.call($this);
    $this.y19(other);
    return $this;
  }
  function FloatArrayList_init_$Create$_0(other) {
    return FloatArrayList_init_$Init$_0(other, objectCreate(protoOf(FloatArrayList)));
  }
  function ensure_0($this, count) {
    if (($this.w19_1 + count | 0) > $this.v19_1.length) {
      var tmp = $this;
      var tmp_0 = $this.v19_1;
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      var tmp0_max = $this.w19_1 + count | 0;
      var tmp1_max = imul($this.v19_1.length, 3);
      tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
      var tmp_1 = copyOf_0(tmp_0, tmp$ret$0);
      tmp.v19_1 = isFloatArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  }
  function FloatArrayList(capacity) {
    Companion_getInstance_3();
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Float32Array(capacity);
    tmp.v19_1 = isFloatArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.w19_1 = 0;
  }
  protoOf(FloatArrayList).z19 = function () {
    return FloatArrayList_init_$Create$(this);
  };
  protoOf(FloatArrayList).a1a = function (value) {
    ensure_0(this, value);
    this.w19_1 = value;
  };
  protoOf(FloatArrayList).l = function () {
    return this.w19_1;
  };
  protoOf(FloatArrayList).e4 = function () {
    this.w19_1 = 0;
  };
  protoOf(FloatArrayList).b1a = function (value) {
    ensure_0(this, 1);
    var tmp = this.v19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.w19_1;
    tmp0_this.w19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = value;
  };
  protoOf(FloatArrayList).c1a = function (v0, v1) {
    ensure_0(this, 2);
    var tmp = this.v19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.w19_1;
    tmp0_this.w19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.v19_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.w19_1;
    tmp2_this.w19_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
  };
  protoOf(FloatArrayList).d1a = function (v0, v1, v2, v3) {
    ensure_0(this, 4);
    var tmp = this.v19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.w19_1;
    tmp0_this.w19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.v19_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.w19_1;
    tmp2_this.w19_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.v19_1;
    var tmp4_this = this;
    var tmp5 = tmp4_this.w19_1;
    tmp4_this.w19_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.v19_1;
    var tmp6_this = this;
    var tmp7 = tmp6_this.w19_1;
    tmp6_this.w19_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
  };
  protoOf(FloatArrayList).e1a = function (v0, v1, v2, v3, v4, v5) {
    ensure_0(this, 6);
    var tmp = this.v19_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.w19_1;
    tmp0_this.w19_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.v19_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.w19_1;
    tmp2_this.w19_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.v19_1;
    var tmp4_this = this;
    var tmp5 = tmp4_this.w19_1;
    tmp4_this.w19_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.v19_1;
    var tmp6_this = this;
    var tmp7 = tmp6_this.w19_1;
    tmp6_this.w19_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
    var tmp_3 = this.v19_1;
    var tmp8_this = this;
    var tmp9 = tmp8_this.w19_1;
    tmp8_this.w19_1 = tmp9 + 1 | 0;
    tmp_3[tmp9] = v4;
    var tmp_4 = this.v19_1;
    var tmp10_this = this;
    var tmp11 = tmp10_this.w19_1;
    tmp10_this.w19_1 = tmp11 + 1 | 0;
    tmp_4[tmp11] = v5;
  };
  protoOf(FloatArrayList).f1a = function (value) {
    return this.x19(value);
  };
  protoOf(FloatArrayList).g1a = function (values, offset, length) {
    ensure_0(this, length);
    arraycopy_0(isFloatArray(values) ? values : THROW_CCE(), offset, this.v19_1, this.l(), length);
    var tmp0_this = this;
    tmp0_this.a1a(tmp0_this.l() + length | 0);
  };
  protoOf(FloatArrayList).y19 = function (values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.g1a(values, offset, length);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.g1a.call(this, values, offset, length);
    }
    return tmp;
  };
  protoOf(FloatArrayList).x19 = function (values) {
    return this.g1a(values.v19_1, 0, values.l());
  };
  protoOf(FloatArrayList).n = function (index) {
    return this.v19_1[index];
  };
  protoOf(FloatArrayList).z18 = function (index) {
    return this.v19_1[index];
  };
  protoOf(FloatArrayList).h1a = function (index, value) {
    if (index >= this.w19_1) {
      ensure_0(this, index + 1 | 0);
      this.w19_1 = index + 1 | 0;
    }
    this.v19_1[index] = value;
  };
  protoOf(FloatArrayList).h = function () {
    return this.o(0);
  };
  protoOf(FloatArrayList).i1a = function (element) {
    var inductionVariable = 0;
    var last = this.w19_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.v19_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(FloatArrayList).q = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.i1a((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(FloatArrayList).j1a = function (elements) {
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var e = tmp0_iterator.k();
      if (!this.i1a(e))
        return false;
    }
    return true;
  };
  protoOf(FloatArrayList).w1 = function (elements) {
    return this.j1a(elements);
  };
  protoOf(FloatArrayList).p = function () {
    return this.l() === 0;
  };
  protoOf(FloatArrayList).u19 = function (index, count) {
    if ((index < 0 ? true : index >= this.w19_1) ? true : (index + count | 0) > this.w19_1)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.v19_1[index];
    if (count > 0) {
      if (index < (this.w19_1 - count | 0)) {
        arraycopy_0(this.v19_1, index + count | 0, this.v19_1, index, (this.w19_1 - index | 0) - count | 0);
      }
      var tmp0_this = this;
      tmp0_this.w19_1 = tmp0_this.w19_1 - count | 0;
    }
    return out;
  };
  protoOf(FloatArrayList).k1a = function () {
    return copyOf_0(this.v19_1, this.w19_1);
  };
  protoOf(FloatArrayList).o = function (index) {
    return take_0(this.v19_1, this.w19_1).o(index);
  };
  protoOf(FloatArrayList).hashCode = function () {
    return contentHashCode_3(this.v19_1, 0, this.l());
  };
  protoOf(FloatArrayList).equals = function (other) {
    if (other instanceof FloatArrayList)
      return this.l() === other.l() ? contentEquals_3(this.v19_1, other.v19_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(FloatArrayList).toString = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FloatArrayList.toString.<anonymous>' call
    tmp0_also.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          tmp0_also.bc(', ');
        }
        tmp0_also.ac(this.z18(n));
      }
       while (inductionVariable < last);
    tmp0_also.ib(_Char___init__impl__6a9atx(93));
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0.toString();
  };
  function Companion_4() {
    Companion_instance_4 = this;
  }
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function IntArrayList_init_$Init$(other, $this) {
    IntArrayList.call($this);
    $this.n1a(other);
    return $this;
  }
  function IntArrayList_init_$Create$(other) {
    return IntArrayList_init_$Init$(other, objectCreate(protoOf(IntArrayList)));
  }
  function IntArrayList_init_$Init$_0(other, $this) {
    IntArrayList.call($this);
    $this.o1a(other);
    return $this;
  }
  function IntArrayList_init_$Create$_0(other) {
    return IntArrayList_init_$Init$_0(other, objectCreate(protoOf(IntArrayList)));
  }
  function IntArrayList(capacity) {
    Companion_getInstance_4();
    capacity = capacity === VOID ? 7 : capacity;
    var tmp = this;
    var tmp_0 = new Int32Array(capacity);
    tmp.l1a_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.m1a_1 = 0;
  }
  protoOf(IntArrayList).a1a = function (value) {
    this.p1a(value);
    this.m1a_1 = value;
  };
  protoOf(IntArrayList).l = function () {
    return this.m1a_1;
  };
  protoOf(IntArrayList).p1a = function (count) {
    if ((this.m1a_1 + count | 0) > this.l1a_1.length) {
      var tmp = this;
      var tmp_0 = this.l1a_1;
      var tmp$ret$0;
      // Inline function 'kotlin.math.max' call
      var tmp0_max = this.m1a_1 + count | 0;
      var tmp1_max = imul(this.l1a_1.length, 3);
      tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
      var tmp_1 = copyOf_1(tmp_0, tmp$ret$0);
      tmp.l1a_1 = isIntArray(tmp_1) ? tmp_1 : THROW_CCE();
    }
  };
  protoOf(IntArrayList).e4 = function () {
    this.m1a_1 = 0;
  };
  protoOf(IntArrayList).q1a = function (v0) {
    this.p1a(1);
    var tmp = this.l1a_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m1a_1;
    tmp0_this.m1a_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
  };
  protoOf(IntArrayList).r1a = function (v0, v1, v2, v3) {
    this.p1a(4);
    var tmp = this.l1a_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m1a_1;
    tmp0_this.m1a_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.l1a_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.m1a_1;
    tmp2_this.m1a_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.l1a_1;
    var tmp4_this = this;
    var tmp5 = tmp4_this.m1a_1;
    tmp4_this.m1a_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.l1a_1;
    var tmp6_this = this;
    var tmp7 = tmp6_this.m1a_1;
    tmp6_this.m1a_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
  };
  protoOf(IntArrayList).s1a = function (v0, v1, v2, v3, v4, v5) {
    this.p1a(6);
    var tmp = this.l1a_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m1a_1;
    tmp0_this.m1a_1 = tmp1 + 1 | 0;
    tmp[tmp1] = v0;
    var tmp_0 = this.l1a_1;
    var tmp2_this = this;
    var tmp3 = tmp2_this.m1a_1;
    tmp2_this.m1a_1 = tmp3 + 1 | 0;
    tmp_0[tmp3] = v1;
    var tmp_1 = this.l1a_1;
    var tmp4_this = this;
    var tmp5 = tmp4_this.m1a_1;
    tmp4_this.m1a_1 = tmp5 + 1 | 0;
    tmp_1[tmp5] = v2;
    var tmp_2 = this.l1a_1;
    var tmp6_this = this;
    var tmp7 = tmp6_this.m1a_1;
    tmp6_this.m1a_1 = tmp7 + 1 | 0;
    tmp_2[tmp7] = v3;
    var tmp_3 = this.l1a_1;
    var tmp8_this = this;
    var tmp9 = tmp8_this.m1a_1;
    tmp8_this.m1a_1 = tmp9 + 1 | 0;
    tmp_3[tmp9] = v4;
    var tmp_4 = this.l1a_1;
    var tmp10_this = this;
    var tmp11 = tmp10_this.m1a_1;
    tmp10_this.m1a_1 = tmp11 + 1 | 0;
    tmp_4[tmp11] = v5;
  };
  protoOf(IntArrayList).t1a = function (value) {
    return this.q1a(value);
  };
  protoOf(IntArrayList).u1a = function (value) {
    return this.n1a(value);
  };
  protoOf(IntArrayList).v1a = function (values, offset, length) {
    this.p1a(length);
    arraycopy_1(isIntArray(values) ? values : THROW_CCE(), offset, this.l1a_1, this.l(), length);
    var tmp0_this = this;
    tmp0_this.a1a(tmp0_this.l() + length | 0);
  };
  protoOf(IntArrayList).o1a = function (values, offset, length, $super) {
    offset = offset === VOID ? 0 : offset;
    length = length === VOID ? values.length : length;
    var tmp;
    if ($super === VOID) {
      this.v1a(values, offset, length);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.v1a.call(this, values, offset, length);
    }
    return tmp;
  };
  protoOf(IntArrayList).n1a = function (values) {
    return this.v1a(values.l1a_1, 0, values.l());
  };
  protoOf(IntArrayList).n = function (index) {
    return this.l1a_1[index];
  };
  protoOf(IntArrayList).z18 = function (index) {
    return this.l1a_1[index];
  };
  protoOf(IntArrayList).w1a = function (index, value) {
    if (index >= this.m1a_1) {
      this.p1a(index + 1 | 0);
      this.m1a_1 = index + 1 | 0;
    }
    this.l1a_1[index] = value;
  };
  protoOf(IntArrayList).h = function () {
    return this.o(0);
  };
  protoOf(IntArrayList).y9 = function (element) {
    var inductionVariable = 0;
    var last = this.m1a_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.l1a_1[n] === element)
          return true;
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(IntArrayList).q = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.y9((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntArrayList).x1a = function (elements) {
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var e = tmp0_iterator.k();
      if (!this.y9(e))
        return false;
    }
    return true;
  };
  protoOf(IntArrayList).w1 = function (elements) {
    return this.x1a(elements);
  };
  protoOf(IntArrayList).p = function () {
    return this.l() === 0;
  };
  protoOf(IntArrayList).y1a = function () {
    return !(this.l() === 0);
  };
  protoOf(IntArrayList).z1a = function () {
    var tmp;
    if (this.p()) {
      throw IndexOutOfBoundsException_init_$Create$();
    } else {
      tmp = this.l1a_1[0];
    }
    return tmp;
  };
  protoOf(IntArrayList).a1b = function () {
    var tmp;
    if (this.p()) {
      throw IndexOutOfBoundsException_init_$Create$();
    } else {
      tmp = this.l1a_1[this.m1a_1 - 1 | 0];
    }
    return tmp;
  };
  protoOf(IntArrayList).b1b = function (index, value) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.insertAt.<anonymous>' call
    this.p1a(1);
    if (this.y1a()) {
      arraycopy_1(this.l1a_1, index, this.l1a_1, index + 1 | 0, this.m1a_1 - index | 0);
    }
    this.l1a_1[index] = value;
    var tmp0_this = this;
    var tmp1 = tmp0_this.m1a_1;
    tmp0_this.m1a_1 = tmp1 + 1 | 0;
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(IntArrayList).t19 = function (indexA, indexB) {
    var l = this.z18(indexA);
    var r = this.z18(indexB);
    this.w1a(indexA, r);
    this.w1a(indexB, l);
  };
  protoOf(IntArrayList).c4 = function (index) {
    return this.u19(index, 1);
  };
  protoOf(IntArrayList).u19 = function (index, count) {
    if ((index < 0 ? true : index >= this.m1a_1) ? true : (index + count | 0) > this.m1a_1)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.l1a_1[index];
    if (count > 0) {
      if (index < (this.m1a_1 - count | 0)) {
        arraycopy_1(this.l1a_1, index + count | 0, this.l1a_1, index, (this.m1a_1 - index | 0) - count | 0);
      }
      var tmp0_this = this;
      tmp0_this.m1a_1 = tmp0_this.m1a_1 - count | 0;
    }
    return out;
  };
  protoOf(IntArrayList).c1b = function () {
    return copyOf_1(this.l1a_1, this.m1a_1);
  };
  protoOf(IntArrayList).o = function (index) {
    return take_1(this.l1a_1, this.m1a_1).o(index);
  };
  protoOf(IntArrayList).hashCode = function () {
    return contentHashCode_4(this.l1a_1, 0, this.l());
  };
  protoOf(IntArrayList).equals = function (other) {
    if (other instanceof IntArrayList)
      return this.l() === other.l() ? contentEquals_4(this.l1a_1, other.l1a_1, 0, this.l()) : false;
    if (!(other == null) ? isInterface(other, List) : false)
      return equals(other, this);
    return false;
  };
  protoOf(IntArrayList).toString = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = StringBuilder_init_$Create$(2 + imul(5, this.l()) | 0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntArrayList.toString.<anonymous>' call
    tmp0_also.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(n === 0)) {
          tmp0_also.bc(', ');
        }
        tmp0_also.ac(this.z18(n));
      }
       while (inductionVariable < last);
    tmp0_also.ib(_Char___init__impl__6a9atx(93));
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0.toString();
  };
  function intArrayListOf(values) {
    return IntArrayList_init_$Create$_0(values.slice());
  }
  function toIntArrayList(_this__u8e3s4) {
    return IntArrayList_init_$Create$_0(_this__u8e3s4.slice());
  }
  function floatArrayListOf(values) {
    return FloatArrayList_init_$Create$_0(values.slice());
  }
  function sort(_this__u8e3s4, start, end, reversed) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.l() : end;
    reversed = reversed === VOID ? false : reversed;
    return genericSort_0(_this__u8e3s4, start, end - 1 | 0, FloatArrayListSortOps_getInstance(), reversed);
  }
  function reverse_0(_this__u8e3s4, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.l() : end;
    return DoubleArrayListSortOps_getInstance().d1b(_this__u8e3s4, start, end - 1 | 0);
  }
  function FloatArrayListSortOps() {
    FloatArrayListSortOps_instance = this;
    SortOps.call(this);
  }
  protoOf(FloatArrayListSortOps).e1b = function (subject, l, r) {
    return compareTo(subject.z18(l), subject.z18(r));
  };
  protoOf(FloatArrayListSortOps).f1b = function (subject, l, r) {
    return this.e1b(subject instanceof FloatArrayList ? subject : THROW_CCE(), l, r);
  };
  protoOf(FloatArrayListSortOps).g1b = function (subject, indexL, indexR) {
    var l = subject.z18(indexL);
    var r = subject.z18(indexR);
    subject.h1a(indexR, l);
    subject.h1a(indexL, r);
  };
  protoOf(FloatArrayListSortOps).h1b = function (subject, indexL, indexR) {
    return this.g1b(subject instanceof FloatArrayList ? subject : THROW_CCE(), indexL, indexR);
  };
  var FloatArrayListSortOps_instance;
  function FloatArrayListSortOps_getInstance() {
    if (FloatArrayListSortOps_instance == null)
      new FloatArrayListSortOps();
    return FloatArrayListSortOps_instance;
  }
  function DoubleArrayListSortOps() {
    DoubleArrayListSortOps_instance = this;
    SortOps.call(this);
  }
  protoOf(DoubleArrayListSortOps).j1b = function (subject, l, r) {
    return compareTo(subject.z18(l), subject.z18(r));
  };
  protoOf(DoubleArrayListSortOps).f1b = function (subject, l, r) {
    return this.j1b(subject instanceof DoubleArrayList ? subject : THROW_CCE(), l, r);
  };
  protoOf(DoubleArrayListSortOps).k1b = function (subject, indexL, indexR) {
    var l = subject.z18(indexL);
    var r = subject.z18(indexR);
    subject.q19(indexR, l);
    subject.q19(indexL, r);
  };
  protoOf(DoubleArrayListSortOps).h1b = function (subject, indexL, indexR) {
    return this.k1b(subject instanceof DoubleArrayList ? subject : THROW_CCE(), indexL, indexR);
  };
  var DoubleArrayListSortOps_instance;
  function DoubleArrayListSortOps_getInstance() {
    if (DoubleArrayListSortOps_instance == null)
      new DoubleArrayListSortOps();
    return DoubleArrayListSortOps_instance;
  }
  function CaseInsensitiveStringMap_init_$Init$($this) {
    CaseInsensitiveStringMap.call($this, LinkedHashMap_init_$Create$(), LinkedHashMap_init_$Create$(), LinkedHashMap_init_$Create$());
    return $this;
  }
  function CaseInsensitiveStringMap_init_$Create$() {
    return CaseInsensitiveStringMap_init_$Init$(objectCreate(protoOf(CaseInsensitiveStringMap)));
  }
  function CaseInsensitiveStringMap(mapOrig, lcToOrig, mapLC) {
    this.l1b_1 = mapOrig;
    this.m1b_1 = lcToOrig;
    this.n1b_1 = mapLC;
  }
  protoOf(CaseInsensitiveStringMap).d1 = function () {
    return this.l1b_1.d1();
  };
  protoOf(CaseInsensitiveStringMap).i3 = function () {
    return this.l1b_1.i3();
  };
  protoOf(CaseInsensitiveStringMap).l = function () {
    return this.l1b_1.l();
  };
  protoOf(CaseInsensitiveStringMap).j3 = function () {
    return this.l1b_1.j3();
  };
  protoOf(CaseInsensitiveStringMap).p = function () {
    return this.l1b_1.p();
  };
  protoOf(CaseInsensitiveStringMap).o1b = function (key) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = key;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    return this.n1b_1.b3(tmp$ret$1);
  };
  protoOf(CaseInsensitiveStringMap).b3 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return false;
    return this.o1b((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).p1b = function (key) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = key;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    return this.n1b_1.h3(tmp$ret$1);
  };
  protoOf(CaseInsensitiveStringMap).h3 = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.p1b((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).q1b = function (key, value) {
    this.r1b(key);
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.l1b_1;
    tmp0_set.e5(key, value);
    // Inline function 'kotlin.collections.set' call
    var tmp1_set = this.m1b_1;
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = key;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    var tmp2_set = tmp$ret$1;
    tmp1_set.e5(tmp2_set, key);
    var tmp$ret$3;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = key;
    tmp$ret$3 = tmp$ret$2.toLowerCase();
    return this.n1b_1.e5(tmp$ret$3, value);
  };
  protoOf(CaseInsensitiveStringMap).e5 = function (key, value) {
    var tmp = (!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE();
    return this.q1b(tmp, (value == null ? true : isObject(value)) ? value : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).s1b = function (from) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = from.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var v = tmp0_iterator.k();
      this.q1b(v.e1(), v.f1());
    }
  };
  protoOf(CaseInsensitiveStringMap).r1b = function (key) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = key;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    var lkey = tmp$ret$1;
    var okey = this.m1b_1.h3(lkey);
    var tmp$ret$2;
    // Inline function 'kotlin.collections.remove' call
    var tmp0_remove = this.l1b_1;
    tmp$ret$2 = (isInterface(tmp0_remove, MutableMap) ? tmp0_remove : THROW_CCE()).lg(okey);
    var res = this.n1b_1.lg(lkey);
    this.m1b_1.lg(lkey);
    return res;
  };
  protoOf(CaseInsensitiveStringMap).lg = function (key) {
    if (!(!(key == null) ? typeof key === 'string' : false))
      return null;
    return this.r1b((!(key == null) ? typeof key === 'string' : false) ? key : THROW_CCE());
  };
  protoOf(CaseInsensitiveStringMap).equals = function (other) {
    var tmp;
    if (other instanceof CaseInsensitiveStringMap) {
      tmp = equals(this.n1b_1, other.n1b_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(CaseInsensitiveStringMap).hashCode = function () {
    return hashCode(this.n1b_1);
  };
  function toCaseInsensitiveMap(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = CaseInsensitiveStringMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toCaseInsensitiveMap.<anonymous>' call
    tmp0_also.s1b(_this__u8e3s4);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function _get__data__kyoi3c($this) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp0_fastCastTo = $this.v1b_1;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_fastCastTo;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function _get_capacity__a9k9f3($this) {
    return $this.v1b_1.length;
  }
  function TGenDeque_init_$Init$($this) {
    TGenDeque.call($this, 16);
    return $this;
  }
  function TGenDeque_init_$Create$() {
    return TGenDeque_init_$Init$(objectCreate(protoOf(TGenDeque)));
  }
  function resizeIfRequiredFor($this, count) {
    if (($this.l() + count | 0) > _get_capacity__a9k9f3($this)) {
      var i = $this.v1b_1;
      var istart = $this.t1b_1;
      var tmp$ret$2;
      // Inline function 'kotlin.arrayOfNulls' call
      var tmp$ret$1;
      // Inline function 'kotlin.comparisons.maxOf' call
      var tmp2_maxOf = $this.v1b_1.length + 7 | 0;
      var tmp$ret$0;
      // Inline function 'kotlin.comparisons.maxOf' call
      var tmp0_maxOf = $this.l() + count | 0;
      var tmp1_maxOf = imul($this.v1b_1.length, 2);
      tmp$ret$0 = Math.max(tmp0_maxOf, tmp1_maxOf);
      var tmp3_maxOf = tmp$ret$0;
      tmp$ret$1 = Math.max(tmp2_maxOf, tmp3_maxOf);
      var tmp4_arrayOfNulls = tmp$ret$1;
      tmp$ret$2 = fillArrayVal(Array(tmp4_arrayOfNulls), null);
      var _o = tmp$ret$2;
      var o = isArray(_o) ? _o : THROW_CCE();
      copyCyclic($this, i, istart, o, $this.u1b_1);
      $this.v1b_1 = o;
      $this.t1b_1 = 0;
    }
  }
  function copyCyclic($this, i, istart, o, count) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = i.length - istart | 0;
    tmp$ret$0 = Math.min(tmp0_min, count);
    var size1 = tmp$ret$0;
    var size2 = count - size1 | 0;
    arraycopy_3(i, istart, o, 0, size1);
    if (size2 > 0) {
      arraycopy_3(i, 0, o, size1, size2);
    }
  }
  function _addLast($this, item) {
    $this.v1b_1[($this.t1b_1 + $this.u1b_1 | 0) % _get_capacity__a9k9f3($this) | 0] = item;
    var tmp0_this = $this;
    var tmp1 = tmp0_this.u1b_1;
    tmp0_this.u1b_1 = tmp1 + 1 | 0;
  }
  function nullify($this, index) {
    _get__data__kyoi3c($this)[index] = null;
  }
  function _removeRetainAll($this, elements, retain) {
    var eset = toSet(elements);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = $this.v1b_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_copyOf;
    tmp$ret$1 = tmp$ret$0.slice();
    var _temp = tmp$ret$1;
    var tsize = 0;
    var osize = $this.l();
    var inductionVariable = 0;
    var last = $this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = $this.n(n);
        if (eset.q(c) === retain) {
          var tmp1 = tsize;
          tsize = tmp1 + 1 | 0;
          _temp[tmp1] = c;
        }
      }
       while (inductionVariable < last);
    $this.v1b_1 = _temp;
    $this.t1b_1 = 0;
    $this.u1b_1 = tsize;
    return !(tsize === osize);
  }
  function internalIndex($this, index) {
    return umod($this.t1b_1 + index | 0, _get_capacity__a9k9f3($this));
  }
  function TGenDeque$iterator$1($that, this$0) {
    this.x1b_1 = $that;
    this.y1b_1 = this$0;
    this.w1b_1 = 0;
  }
  protoOf(TGenDeque$iterator$1).k = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.w1b_1;
    tmp0_this.w1b_1 = tmp1 + 1 | 0;
    return this.x1b_1.n(tmp1);
  };
  protoOf(TGenDeque$iterator$1).j = function () {
    return this.w1b_1 < this.y1b_1.l();
  };
  protoOf(TGenDeque$iterator$1).f5 = function () {
    var tmp0_this = this;
    tmp0_this.w1b_1 = tmp0_this.w1b_1 - 1 | 0;
    this.y1b_1.c4(tmp0_this.w1b_1);
  };
  function TGenDeque(initialCapacity) {
    this.t1b_1 = 0;
    this.u1b_1 = 0;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(initialCapacity), null);
    var tmp_0 = tmp$ret$0;
    tmp.v1b_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  protoOf(TGenDeque).l = function () {
    return this.u1b_1;
  };
  protoOf(TGenDeque).p = function () {
    return this.l() === 0;
  };
  protoOf(TGenDeque).z1b = function (item) {
    resizeIfRequiredFor(this, 1);
    _addLast(this, item);
  };
  protoOf(TGenDeque).a1c = function (item) {
    resizeIfRequiredFor(this, 1);
    this.t1b_1 = umod(this.t1b_1 - 1 | 0, _get_capacity__a9k9f3(this));
    var tmp0_this = this;
    var tmp1 = tmp0_this.u1b_1;
    tmp0_this.u1b_1 = tmp1 + 1 | 0;
    this.v1b_1[this.t1b_1] = item;
  };
  protoOf(TGenDeque).b1c = function (list) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.TGenDeque._addAll' call
    var tmp0__addAll = list.l();
    resizeIfRequiredFor(this, tmp0__addAll);
    var base = this.t1b_1 + this.u1b_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0__addAll)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.v1b_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3(this) | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.TGenDeque.addAll.<anonymous>' call
        tmp$ret$0 = list.n(n);
        tmp[tmp_0] = tmp$ret$0;
      }
       while (inductionVariable < tmp0__addAll);
    var tmp1_this = this;
    tmp1_this.u1b_1 = tmp1_this.u1b_1 + tmp0__addAll | 0;
    tmp$ret$1 = true;
    return tmp$ret$1;
  };
  protoOf(TGenDeque).c1c = function (elements) {
    return this.b1c(toList(elements));
  };
  protoOf(TGenDeque).m = function (elements) {
    return this.c1c(elements);
  };
  protoOf(TGenDeque).d1c = function (items) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.TGenDeque._addAllFirst' call
    var tmp0__addAllFirst = items.l();
    resizeIfRequiredFor(this, tmp0__addAllFirst);
    this.t1b_1 = umod(this.t1b_1 - tmp0__addAllFirst | 0, _get_capacity__a9k9f3(this));
    var tmp0_this = this;
    tmp0_this.u1b_1 = tmp0_this.u1b_1 + tmp0__addAllFirst | 0;
    var pos = this.t1b_1;
    var inductionVariable = 0;
    if (inductionVariable < tmp0__addAllFirst)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.v1b_1;
        var tmp2 = pos;
        pos = tmp2 + 1 | 0;
        var tmp_0 = umod(tmp2, _get_capacity__a9k9f3(this));
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.TGenDeque.addAllFirst.<anonymous>' call
        tmp$ret$0 = items.n(n);
        tmp[tmp_0] = tmp$ret$0;
      }
       while (inductionVariable < tmp0__addAllFirst);
    tmp$ret$1 = true;
    return tmp$ret$1;
  };
  protoOf(TGenDeque).w3 = function () {
    if (this.u1b_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.e1c();
    nullify(this, this.t1b_1);
    this.t1b_1 = (this.t1b_1 + 1 | 0) % _get_capacity__a9k9f3(this) | 0;
    var tmp0_this = this;
    var tmp1 = tmp0_this.u1b_1;
    tmp0_this.u1b_1 = tmp1 - 1 | 0;
    return out;
  };
  protoOf(TGenDeque).y3 = function () {
    if (this.u1b_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.f1c();
    nullify(this, internalIndex(this, this.l() - 1 | 0));
    var tmp0_this = this;
    var tmp1 = tmp0_this.u1b_1;
    tmp0_this.u1b_1 = tmp1 - 1 | 0;
    return out;
  };
  protoOf(TGenDeque).c4 = function (index) {
    if (index < 0 ? true : index >= this.l())
      throw IndexOutOfBoundsException_init_$Create$();
    if (index === 0)
      return this.w3();
    if (index === (this.l() - 1 | 0))
      return this.y3();
    var old = this.n(index);
    if (index < (this.l() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.g1c(n, this.n(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.t1b_1 = umod(this.t1b_1 + 1 | 0, _get_capacity__a9k9f3(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.l() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.g1c(n_0, this.n(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this.u1b_1;
    tmp2_this.u1b_1 = tmp3 - 1 | 0;
    return old;
  };
  protoOf(TGenDeque).h1c = function (element) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.TGenDeque.add.<anonymous>' call
    this.z1b(element);
    tmp$ret$0 = true;
    return tmp$ret$0;
  };
  protoOf(TGenDeque).b = function (element) {
    return this.h1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenDeque).e4 = function () {
    this.u1b_1 = 0;
  };
  protoOf(TGenDeque).i1c = function (element) {
    var index = this.j1c(element);
    if (index >= 0) {
      this.c4(index);
    }
    return index >= 0;
  };
  protoOf(TGenDeque).b4 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.i1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenDeque).k1c = function (elements) {
    return _removeRetainAll(this, elements, false);
  };
  protoOf(TGenDeque).d4 = function (elements) {
    return this.k1c(elements);
  };
  protoOf(TGenDeque).e1c = function () {
    return this.v1b_1[this.t1b_1];
  };
  protoOf(TGenDeque).f1c = function () {
    return this.v1b_1[internalIndex(this, this.l() - 1 | 0)];
  };
  protoOf(TGenDeque).g1c = function (index, value) {
    this.v1b_1[internalIndex(this, index)] = value;
  };
  protoOf(TGenDeque).n = function (index) {
    return this.v1b_1[internalIndex(this, index)];
  };
  protoOf(TGenDeque).l1c = function (index) {
    return (0 <= index ? index <= (this.l() - 1 | 0) : false) ? this.n(index) : null;
  };
  protoOf(TGenDeque).m1c = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = until(0, this.l());
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var inductionVariable = tmp0_any.p1_1;
      var last = tmp0_any.q1_1;
      if (inductionVariable <= last)
        do {
          var element_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$1;
          // Inline function 'korlibs.datastructure.TGenDeque.contains.<anonymous>' call
          tmp$ret$1 = equals(this.n(element_0), element);
          if (tmp$ret$1) {
            tmp$ret$0 = true;
            break $l$block_0;
          }
        }
         while (!(element_0 === last));
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(TGenDeque).q = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.m1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenDeque).j1c = function (element) {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.n(n), element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  };
  protoOf(TGenDeque).n1c = function (elements) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
      tmp$ret$0 = to(item, 0);
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    var emap = toLinkedMap_0(tmp$ret$2);
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.n(it);
        var tmp$ret$4;
        // Inline function 'kotlin.collections.contains' call
        var tmp$ret$3;
        // Inline function 'kotlin.collections.containsKey' call
        tmp$ret$3 = (isInterface(emap, Map_0) ? emap : THROW_CCE()).b3(e);
        tmp$ret$4 = tmp$ret$3;
        if (tmp$ret$4) {
          // Inline function 'kotlin.collections.set' call
          emap.e5(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp1_all = emap.j3();
      var tmp;
      if (isInterface(tmp1_all, Collection)) {
        tmp = tmp1_all.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = tmp1_all.h();
      while (tmp0_iterator_0.j()) {
        var element = tmp0_iterator_0.k();
        var tmp$ret$6;
        // Inline function 'korlibs.datastructure.TGenDeque.containsAll.<anonymous>' call
        tmp$ret$6 = element === 1;
        if (!tmp$ret$6) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  };
  protoOf(TGenDeque).w1 = function (elements) {
    return this.n1c(elements);
  };
  protoOf(TGenDeque).h = function () {
    var that = this;
    return new TGenDeque$iterator$1(that, this);
  };
  protoOf(TGenDeque).hashCode = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var tmp1_contentHashCode = this.l();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < tmp1_contentHashCode)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        var tmp$ret$1;
        // Inline function 'kotlin.hashCode' call
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.TGenDeque.hashCode.<anonymous>' call
        tmp$ret$0 = this.n(n);
        var tmp0_hashCode = tmp$ret$0;
        var tmp0_safe_receiver = tmp0_hashCode;
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        tmp$ret$1 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
        result = tmp + tmp$ret$1 | 0;
      }
       while (inductionVariable < tmp1_contentHashCode);
    tmp$ret$2 = result;
    return tmp$ret$2;
  };
  protoOf(TGenDeque).equals = function (other) {
    if (!(other instanceof TGenDeque))
      return false;
    if (!(other.l() === this.l()))
      return false;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(this.n(n), other.n(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  };
  protoOf(TGenDeque).toString = function () {
    var sb = StringBuilder_init_$Create$_0();
    sb.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.ac(this.n(n));
        if (!(n === (this.l() - 1 | 0))) {
          sb.bc(', ');
        }
      }
       while (inductionVariable < last);
    sb.ib(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function _get__data__kyoi3c_0($this) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp0_fastCastTo = $this.q1c_1;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_fastCastTo;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function _get_capacity__a9k9f3_0($this) {
    return $this.q1c_1.length;
  }
  function resizeIfRequiredFor_0($this, count) {
    if (($this.l() + count | 0) > _get_capacity__a9k9f3_0($this)) {
      var i = $this.q1c_1;
      var istart = $this.o1c_1;
      var tmp$ret$1;
      // Inline function 'kotlin.comparisons.maxOf' call
      var tmp2_maxOf = $this.q1c_1.length + 7 | 0;
      var tmp$ret$0;
      // Inline function 'kotlin.comparisons.maxOf' call
      var tmp0_maxOf = $this.l() + count | 0;
      var tmp1_maxOf = imul($this.q1c_1.length, 2);
      tmp$ret$0 = Math.max(tmp0_maxOf, tmp1_maxOf);
      var tmp3_maxOf = tmp$ret$0;
      tmp$ret$1 = Math.max(tmp2_maxOf, tmp3_maxOf);
      var _o = new Int32Array(tmp$ret$1);
      var o = isIntArray(_o) ? _o : THROW_CCE();
      copyCyclic_0($this, i, istart, o, $this.p1c_1);
      $this.q1c_1 = o;
      $this.o1c_1 = 0;
    }
  }
  function copyCyclic_0($this, i, istart, o, count) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = i.length - istart | 0;
    tmp$ret$0 = Math.min(tmp0_min, count);
    var size1 = tmp$ret$0;
    var size2 = count - size1 | 0;
    arraycopy_1(i, istart, o, 0, size1);
    if (size2 > 0) {
      arraycopy_1(i, 0, o, size1, size2);
    }
  }
  function _addLast_0($this, item) {
    $this.q1c_1[($this.o1c_1 + $this.p1c_1 | 0) % _get_capacity__a9k9f3_0($this) | 0] = item;
    var tmp0_this = $this;
    var tmp1 = tmp0_this.p1c_1;
    tmp0_this.p1c_1 = tmp1 + 1 | 0;
  }
  function nullify_0($this, index) {
    _get__data__kyoi3c_0($this)[index] = 0;
  }
  function _removeRetainAll_0($this, elements, retain) {
    var eset = toSet(elements);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.copyOf' call
    var tmp0_copyOf = $this.q1c_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_copyOf;
    tmp$ret$1 = tmp$ret$0.slice();
    var _temp = tmp$ret$1;
    var tsize = 0;
    var osize = $this.l();
    var inductionVariable = 0;
    var last = $this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = $this.n(n);
        if (eset.q(c) === retain) {
          var tmp1 = tsize;
          tsize = tmp1 + 1 | 0;
          _temp[tmp1] = c;
        }
      }
       while (inductionVariable < last);
    $this.q1c_1 = _temp;
    $this.o1c_1 = 0;
    $this.p1c_1 = tsize;
    return !(tsize === osize);
  }
  function internalIndex_0($this, index) {
    return umod($this.o1c_1 + index | 0, _get_capacity__a9k9f3_0($this));
  }
  function IntDeque$iterator$1($that, this$0) {
    this.s1c_1 = $that;
    this.t1c_1 = this$0;
    this.r1c_1 = 0;
  }
  protoOf(IntDeque$iterator$1).k = function () {
    var tmp0_this = this;
    var tmp1 = tmp0_this.r1c_1;
    tmp0_this.r1c_1 = tmp1 + 1 | 0;
    return this.s1c_1.n(tmp1);
  };
  protoOf(IntDeque$iterator$1).j = function () {
    return this.r1c_1 < this.t1c_1.l();
  };
  protoOf(IntDeque$iterator$1).f5 = function () {
    var tmp0_this = this;
    tmp0_this.r1c_1 = tmp0_this.r1c_1 - 1 | 0;
    this.t1c_1.c4(tmp0_this.r1c_1);
  };
  function IntDeque(initialCapacity) {
    this.o1c_1 = 0;
    this.p1c_1 = 0;
    var tmp = this;
    var tmp_0 = new Int32Array(initialCapacity);
    tmp.q1c_1 = isIntArray(tmp_0) ? tmp_0 : THROW_CCE();
  }
  protoOf(IntDeque).l = function () {
    return this.p1c_1;
  };
  protoOf(IntDeque).p = function () {
    return this.l() === 0;
  };
  protoOf(IntDeque).u1c = function (item) {
    resizeIfRequiredFor_0(this, 1);
    _addLast_0(this, item);
  };
  protoOf(IntDeque).v1c = function (list) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.IntDeque._addAll' call
    var tmp0__addAll = list.l();
    resizeIfRequiredFor_0(this, tmp0__addAll);
    var base = this.o1c_1 + this.p1c_1 | 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0__addAll)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = this.q1c_1;
        var tmp_0 = (base + n | 0) % _get_capacity__a9k9f3_0(this) | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.IntDeque.addAll.<anonymous>' call
        tmp$ret$0 = list.n(n);
        tmp[tmp_0] = tmp$ret$0;
      }
       while (inductionVariable < tmp0__addAll);
    var tmp1_this = this;
    tmp1_this.p1c_1 = tmp1_this.p1c_1 + tmp0__addAll | 0;
    tmp$ret$1 = true;
    return tmp$ret$1;
  };
  protoOf(IntDeque).w1c = function (elements) {
    return this.v1c(toList(elements));
  };
  protoOf(IntDeque).m = function (elements) {
    return this.w1c(elements);
  };
  protoOf(IntDeque).w3 = function () {
    if (this.p1c_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.e1c();
    nullify_0(this, this.o1c_1);
    this.o1c_1 = (this.o1c_1 + 1 | 0) % _get_capacity__a9k9f3_0(this) | 0;
    var tmp0_this = this;
    var tmp1 = tmp0_this.p1c_1;
    tmp0_this.p1c_1 = tmp1 - 1 | 0;
    return out;
  };
  protoOf(IntDeque).y3 = function () {
    if (this.p1c_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    var out = this.f1c();
    nullify_0(this, internalIndex_0(this, this.l() - 1 | 0));
    var tmp0_this = this;
    var tmp1 = tmp0_this.p1c_1;
    tmp0_this.p1c_1 = tmp1 - 1 | 0;
    return out;
  };
  protoOf(IntDeque).c4 = function (index) {
    if (index < 0 ? true : index >= this.l())
      throw IndexOutOfBoundsException_init_$Create$();
    if (index === 0)
      return this.w3();
    if (index === (this.l() - 1 | 0))
      return this.y3();
    var old = this.n(index);
    if (index < (this.l() / 2 | 0)) {
      var inductionVariable = index;
      if (1 <= inductionVariable)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + -1 | 0;
          this.w1a(n, this.n(n - 1 | 0));
        }
         while (1 <= inductionVariable);
      this.o1c_1 = umod(this.o1c_1 + 1 | 0, _get_capacity__a9k9f3_0(this));
    } else {
      var inductionVariable_0 = index;
      var last = this.l() - 1 | 0;
      if (inductionVariable_0 < last)
        do {
          var n_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          this.w1a(n_0, this.n(n_0 + 1 | 0));
        }
         while (inductionVariable_0 < last);
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this.p1c_1;
    tmp2_this.p1c_1 = tmp3 - 1 | 0;
    return old;
  };
  protoOf(IntDeque).x1c = function (element) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.IntDeque.add.<anonymous>' call
    this.u1c(element);
    tmp$ret$0 = true;
    return tmp$ret$0;
  };
  protoOf(IntDeque).b = function (element) {
    return this.x1c((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntDeque).y1c = function (element) {
    var index = this.z1c(element);
    if (index >= 0) {
      this.c4(index);
    }
    return index >= 0;
  };
  protoOf(IntDeque).b4 = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.y1c((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntDeque).a1d = function (elements) {
    return _removeRetainAll_0(this, elements, false);
  };
  protoOf(IntDeque).d4 = function (elements) {
    return this.a1d(elements);
  };
  protoOf(IntDeque).e1c = function () {
    return this.q1c_1[this.o1c_1];
  };
  protoOf(IntDeque).f1c = function () {
    return this.q1c_1[internalIndex_0(this, this.l() - 1 | 0)];
  };
  protoOf(IntDeque).w1a = function (index, value) {
    this.q1c_1[internalIndex_0(this, index)] = value;
  };
  protoOf(IntDeque).n = function (index) {
    return this.q1c_1[internalIndex_0(this, index)];
  };
  protoOf(IntDeque).y9 = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = until(0, this.l());
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var inductionVariable = tmp0_any.p1_1;
      var last = tmp0_any.q1_1;
      if (inductionVariable <= last)
        do {
          var element_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$1;
          // Inline function 'korlibs.datastructure.IntDeque.contains.<anonymous>' call
          tmp$ret$1 = this.n(element_0) === element;
          if (tmp$ret$1) {
            tmp$ret$0 = true;
            break $l$block_0;
          }
        }
         while (!(element_0 === last));
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(IntDeque).q = function (element) {
    if (!(!(element == null) ? typeof element === 'number' : false))
      return false;
    return this.y9((!(element == null) ? typeof element === 'number' : false) ? element : THROW_CCE());
  };
  protoOf(IntDeque).z1c = function (element) {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (this.n(n) === element)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  };
  protoOf(IntDeque).x1a = function (elements) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(elements, 10));
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
      tmp$ret$0 = to(item, 0);
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    var emap = toLinkedMap_0(tmp$ret$2);
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var it = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var e = this.n(it);
        var tmp$ret$4;
        // Inline function 'kotlin.collections.contains' call
        var tmp$ret$3;
        // Inline function 'kotlin.collections.containsKey' call
        tmp$ret$3 = (isInterface(emap, Map_0) ? emap : THROW_CCE()).b3(e);
        tmp$ret$4 = tmp$ret$3;
        if (tmp$ret$4) {
          // Inline function 'kotlin.collections.set' call
          emap.e5(e, 1);
        }
      }
       while (inductionVariable < last);
    var tmp$ret$5;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp1_all = emap.j3();
      var tmp;
      if (isInterface(tmp1_all, Collection)) {
        tmp = tmp1_all.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$5 = true;
        break $l$block_0;
      }
      var tmp0_iterator_0 = tmp1_all.h();
      while (tmp0_iterator_0.j()) {
        var element = tmp0_iterator_0.k();
        var tmp$ret$6;
        // Inline function 'korlibs.datastructure.IntDeque.containsAll.<anonymous>' call
        tmp$ret$6 = element === 1;
        if (!tmp$ret$6) {
          tmp$ret$5 = false;
          break $l$block_0;
        }
      }
      tmp$ret$5 = true;
    }
    return tmp$ret$5;
  };
  protoOf(IntDeque).w1 = function (elements) {
    return this.x1a(elements);
  };
  protoOf(IntDeque).h = function () {
    var that = this;
    return new IntDeque$iterator$1(that, this);
  };
  protoOf(IntDeque).hashCode = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.internal.contentHashCode' call
    var tmp1_contentHashCode = this.l();
    var result = 1;
    var inductionVariable = 0;
    if (inductionVariable < tmp1_contentHashCode)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = imul(31, result);
        var tmp$ret$1;
        // Inline function 'kotlin.hashCode' call
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.IntDeque.hashCode.<anonymous>' call
        tmp$ret$0 = this.n(n);
        var tmp0_hashCode = tmp$ret$0;
        var tmp0_safe_receiver = tmp0_hashCode;
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        tmp$ret$1 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
        result = tmp + tmp$ret$1 | 0;
      }
       while (inductionVariable < tmp1_contentHashCode);
    tmp$ret$2 = result;
    return tmp$ret$2;
  };
  protoOf(IntDeque).equals = function (other) {
    if (!(other instanceof IntDeque))
      return false;
    if (!(other.l() === this.l()))
      return false;
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!(this.n(n) === other.n(n)))
          return false;
      }
       while (inductionVariable < last);
    return true;
  };
  protoOf(IntDeque).toString = function () {
    var sb = StringBuilder_init_$Create$_0();
    sb.ib(_Char___init__impl__6a9atx(91));
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        sb.ac(this.n(n));
        if (!(n === (this.l() - 1 | 0))) {
          sb.bc(', ');
        }
      }
       while (inductionVariable < last);
    sb.ib(_Char___init__impl__6a9atx(93));
    return sb.toString();
  };
  function toDeque(_this__u8e3s4) {
    return Deque(_this__u8e3s4);
  }
  function Deque(other) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new TGenDeque(other.l());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Deque.<anonymous>' call
    tmp0_also.c1c(other);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function fastArrayListOf(values) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = FastArrayList_init_$Create$_0(values.length);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.fastArrayListOf.<anonymous>' call
    addAll(tmp0_also, values);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function toFastList(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = FastArrayList_init_$Create$_0(_this__u8e3s4.length);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toFastList.<anonymous>' call
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < _this__u8e3s4.length) {
      // Inline function 'korlibs.datastructure.toFastList.<anonymous>.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = _this__u8e3s4[tmp0];
      tmp0_also.b(tmp0__anonymous__q1qw7t);
    }
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function toFastList_0(_this__u8e3s4, out) {
    out = out === VOID ? FastArrayList_init_$Create$() : out;
    out.d1d(0, _this__u8e3s4);
    out.e1d(_this__u8e3s4.l());
    return out;
  }
  function MutableListEx() {
  }
  function toMap_0(_this__u8e3s4) {
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp0_also = LinkedHashMap_init_$Create$_0(get_size_1(_this__u8e3s4));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toMap.<anonymous>' call
    // Inline function 'korlibs.datastructure.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var mapIterator = tmp$ret$0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEachNullable.<anonymous>' call
      var tmp0__anonymous__q1qw7t = v.value;
      // Inline function 'korlibs.datastructure.toMap.<anonymous>.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = _this__u8e3s4;
      tmp$ret$2 = tmp$ret$1.get(tmp0__anonymous__q1qw7t);
      var tmp1__anonymous__uwfjfc = tmp$ret$2;
      var tmp0_safe_receiver = tmp1__anonymous__uwfjfc;
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$3;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        tmp0_also.e5(tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc);
        tmp$ret$3 = Unit_getInstance();
      }
    }
    tmp$ret$4 = tmp0_also;
    return tmp$ret$4;
  }
  function getAndRemove(_this__u8e3s4, key) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = get(_this__u8e3s4, key);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.getAndRemove.<anonymous>' call
    remove(_this__u8e3s4, key);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function get_keys(_this__u8e3s4) {
    return keys(_this__u8e3s4);
  }
  function toLinkedMap(_this__u8e3s4) {
    var out = linkedHashMapOf([]);
    // Inline function 'korlibs.datastructure.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.fastKeyForEach' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var mapIterator = tmp$ret$0.keys();
    $l$loop: while (true) {
      var v = mapIterator.next();
      if (v.done)
        break $l$loop;
      // Inline function 'korlibs.datastructure.fastForEachNullable.<anonymous>' call
      var tmp0__anonymous__q1qw7t = v.value;
      // Inline function 'korlibs.datastructure.toLinkedMap.<anonymous>' call
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = _this__u8e3s4;
      tmp$ret$2 = tmp$ret$1.get(tmp0__anonymous__q1qw7t);
      var tmp1__anonymous__uwfjfc = tmp$ret$2;
      if (!(tmp1__anonymous__uwfjfc == null)) {
        // Inline function 'kotlin.collections.set' call
        out.e5(tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc);
      }
    }
    return out;
  }
  function FastSmallSet() {
    AbstractMutableSet.call(this);
    this.i1d_1 = LinkedHashSet_init_$Create$();
    this.j1d_1 = null;
    this.k1d_1 = null;
    this.l1d_1 = null;
  }
  protoOf(FastSmallSet).l = function () {
    return this.i1d_1.l();
  };
  protoOf(FastSmallSet).h = function () {
    return this.i1d_1.h();
  };
  protoOf(FastSmallSet).m1d = function (element) {
    if (this.s1(element))
      return false;
    this.i1d_1.b(element);
    return true;
  };
  protoOf(FastSmallSet).b = function (element) {
    return this.m1d((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(FastSmallSet).n1d = function (element) {
    this.j1d_1 = null;
    this.k1d_1 = null;
    this.l1d_1 = null;
    this.i1d_1.b4(element);
    return true;
  };
  protoOf(FastSmallSet).b4 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.n1d((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(FastSmallSet).s1 = function (element) {
    if ((element === this.j1d_1 ? true : element === this.k1d_1) ? true : element === this.j1d_1)
      return true;
    var result = this.i1d_1.q(element);
    if (result) {
      this.k1d_1 = this.j1d_1;
      this.l1d_1 = this.k1d_1;
      this.j1d_1 = element;
    }
    return result;
  };
  protoOf(FastSmallSet).q = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.s1((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(FastSmallSet).e4 = function () {
    this.i1d_1.e4();
    this.j1d_1 = null;
    this.k1d_1 = null;
    this.l1d_1 = null;
  };
  function translateIndex(_this__u8e3s4, $this) {
    if (!(0 <= _this__u8e3s4 ? _this__u8e3s4 < $this.l() : false))
      throw IndexOutOfBoundsException_init_$Create$_0('' + _this__u8e3s4);
    return $this.p1d_1 + _this__u8e3s4 | 0;
  }
  function GenericSubList(base, start, end) {
    this.o1d_1 = base;
    this.p1d_1 = start;
    this.q1d_1 = end;
    var containsUpper = this.o1d_1.l();
    var containsArg = this.p1d_1;
    if (!(0 <= containsArg ? containsArg <= containsUpper : false))
      throw IndexOutOfBoundsException_init_$Create$_0('' + this.p1d_1);
    var containsUpper_0 = this.o1d_1.l();
    var containsArg_0 = this.q1d_1;
    if (!(0 <= containsArg_0 ? containsArg_0 <= containsUpper_0 : false))
      throw IndexOutOfBoundsException_init_$Create$_0('' + this.q1d_1);
  }
  protoOf(GenericSubList).l = function () {
    return this.q1d_1 - this.p1d_1 | 0;
  };
  protoOf(GenericSubList).s1 = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = until(0, this.l());
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var inductionVariable = tmp0_any.p1_1;
      var last = tmp0_any.q1_1;
      if (inductionVariable <= last)
        do {
          var element_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$1;
          // Inline function 'korlibs.datastructure.GenericSubList.contains.<anonymous>' call
          tmp$ret$1 = equals(this.n(element_0), element);
          if (tmp$ret$1) {
            tmp$ret$0 = true;
            break $l$block_0;
          }
        }
         while (!(element_0 === last));
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(GenericSubList).q = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.s1((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(GenericSubList).m4 = function (elements) {
    var elementsSet = toMutableSet(elements);
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'kotlin.collections.minusAssign' call
        var tmp0_minusAssign = this.n(n);
        elementsSet.b4(tmp0_minusAssign);
      }
       while (inductionVariable < last);
    return elementsSet.p();
  };
  protoOf(GenericSubList).w1 = function (elements) {
    return this.m4(elements);
  };
  protoOf(GenericSubList).n = function (index) {
    return this.o1d_1.n(translateIndex(index, this));
  };
  protoOf(GenericSubList).r1d = function (element) {
    var inductionVariable = 0;
    var last = this.l();
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.n(n), element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  };
  protoOf(GenericSubList).x = function (element) {
    if (!(element == null ? true : isObject(element)))
      return -1;
    return this.r1d((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(GenericSubList).p = function () {
    return this.l() === 0;
  };
  protoOf(GenericSubList).h = function () {
    return new GenericListIterator(this);
  };
  protoOf(GenericSubList).o = function (index) {
    return new GenericListIterator(this, index);
  };
  protoOf(GenericSubList).w = function (fromIndex, toIndex) {
    return new GenericSubList(this, fromIndex, toIndex);
  };
  protoOf(GenericSubList).toString = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = until(0, this.l());
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var inductionVariable = tmp1_map.p1_1;
    var last = tmp1_map.q1_1;
    if (inductionVariable <= last)
      do {
        var item = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.GenericSubList.toString.<anonymous>' call
        tmp$ret$0 = this.n(item);
        tmp0_mapTo.b(tmp$ret$0);
      }
       while (!(item === last));
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return toString(tmp$ret$2);
  };
  protoOf(GenericSubList).equals = function (other) {
    var tmp;
    if (other instanceof GenericSubList) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var tmp0_equaler = this.l();
        var inductionVariable = 0;
        if (inductionVariable < tmp0_equaler)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp$ret$0;
            // Inline function 'korlibs.datastructure.GenericSubList.equals.<anonymous>' call
            tmp$ret$0 = equals(this.n(n), other.n(n));
            if (!tmp$ret$0) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < tmp0_equaler);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(GenericSubList).hashCode = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var tmp0_hashCoder = this.l();
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_hashCoder)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        var tmp$ret$1;
        // Inline function 'korlibs.datastructure.GenericSubList.hashCode.<anonymous>' call
        var tmp$ret$0;
        // Inline function 'kotlin.hashCode' call
        var tmp0_hashCode = this.n(n);
        var tmp0_safe_receiver = tmp0_hashCode;
        var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
        tmp$ret$0 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
        tmp$ret$1 = tmp$ret$0;
        out = tmp + tmp$ret$1 | 0;
      }
       while (inductionVariable < tmp0_hashCoder);
    tmp$ret$2 = out;
    return tmp$ret$2;
  };
  function GenericListIterator(list, iindex) {
    iindex = iindex === VOID ? 0 : iindex;
    this.s1d_1 = list;
    this.t1d_1 = iindex;
    var containsUpper = this.s1d_1.l();
    var containsArg = this.t1d_1;
    if (!(0 <= containsArg ? containsArg < containsUpper : false))
      throw IndexOutOfBoundsException_init_$Create$_0('' + this.t1d_1);
    this.u1d_1 = this.t1d_1;
  }
  protoOf(GenericListIterator).j = function () {
    return this.u1d_1 < this.s1d_1.l();
  };
  protoOf(GenericListIterator).k = function () {
    if (!this.j())
      throw NoSuchElementException_init_$Create$();
    var tmp0_this = this;
    var tmp1 = tmp0_this.u1d_1;
    tmp0_this.u1d_1 = tmp1 + 1 | 0;
    return this.s1d_1.n(tmp1);
  };
  protoOf(GenericListIterator).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof GenericListIterator) {
      tmp_0 = equals(this.s1d_1, other.s1d_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.u1d_1 === other.u1d_1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(GenericListIterator).hashCode = function () {
    return hashCode(this.s1d_1) + this.u1d_1 | 0;
  };
  function HistoryStack(maxLength, initialCapacity) {
    maxLength = maxLength === VOID ? IntCompanionObject_getInstance().MAX_VALUE - 10 | 0 : maxLength;
    initialCapacity = initialCapacity === VOID ? 7 : initialCapacity;
    this.v1d_1 = maxLength;
    this.w1d_1 = new TGenDeque(initialCapacity);
    this.x1d_1 = 0;
  }
  protoOf(HistoryStack).y1d = function (value) {
    $l$loop: while (true) {
      var tmp;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp0_isNotEmpty = this.w1d_1;
      tmp$ret$0 = !tmp0_isNotEmpty.p();
      if (tmp$ret$0) {
        tmp = this.w1d_1.l() > this.x1d_1;
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$loop;
      }
      this.w1d_1.y3();
    }
    this.w1d_1.h1c(value);
    this.x1d_1 = this.w1d_1.l();
    $l$loop_0: while (true) {
      var tmp_0;
      var tmp$ret$1;
      // Inline function 'kotlin.collections.isNotEmpty' call
      var tmp1_isNotEmpty = this.w1d_1;
      tmp$ret$1 = !tmp1_isNotEmpty.p();
      if (tmp$ret$1) {
        tmp_0 = this.w1d_1.l() > this.v1d_1;
      } else {
        tmp_0 = false;
      }
      if (!tmp_0) {
        break $l$loop_0;
      }
      this.w1d_1.w3();
      var tmp0_this = this;
      var tmp1 = tmp0_this.x1d_1;
      tmp0_this.x1d_1 = tmp1 - 1 | 0;
    }
  };
  protoOf(HistoryStack).z1d = function () {
    this.x1d_1 = coerceAtLeast(this.x1d_1 - 1 | 0, 0);
    return this.w1d_1.l1c(this.x1d_1 - 1 | 0);
  };
  protoOf(HistoryStack).a1e = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.w1d_1.l1c(this.x1d_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.HistoryStack.redo.<anonymous>' call
    this.x1d_1 = coerceAtMost(this.x1d_1 + 1 | 0, this.w1d_1.l() + 1 | 0);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  function Companion_5() {
    Companion_instance_5 = this;
  }
  protoOf(Companion_5).w18 = function (width, height, arraySize) {
    // Inline function 'kotlin.check' call
    var tmp0_check = arraySize >= imul(width, height);
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_check) {
      var tmp$ret$0;
      // Inline function 'korlibs.datastructure.Companion.checkArraySize.<anonymous>' call
      tmp$ret$0 = 'backing array of size=' + arraySize + ', has less elements than ' + width + ' * ' + height;
      var message = tmp$ret$0;
      throw IllegalStateException_init_$Create$(toString(message));
    }
  };
  var Companion_instance_5;
  function Companion_getInstance_5() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function IArray2$asString$lambda(this$0) {
    return function (y) {
      var tmp$ret$2;
      // Inline function 'kotlin.collections.map' call
      var tmp1_map = until(0, this$0.x18());
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
      var inductionVariable = tmp1_map.p1_1;
      var last = tmp1_map.q1_1;
      if (inductionVariable <= last)
        do {
          var item = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.datastructure.IArray2.asString.<anonymous>.<anonymous>' call
          tmp$ret$0 = this$0.d19(item, y);
          tmp0_mapTo.b(tmp$ret$0);
        }
         while (!(item === last));
      tmp$ret$1 = tmp0_mapTo;
      tmp$ret$2 = tmp$ret$1;
      return joinToString(tmp$ret$2, ', ');
    };
  }
  function IArray2() {
  }
  function index(_this__u8e3s4, x, y) {
    if (!(0 <= x ? x < _this__u8e3s4.x18() : false) ? true : !(0 <= y ? y < _this__u8e3s4.y18() : false))
      throw IndexOutOfBoundsException_init_$Create$();
    return imul(y, _this__u8e3s4.x18()) + x | 0;
  }
  function IntIntMap_init_$Init$(initialCapacity, loadFactor, $this) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = ilog2Ceil(initialCapacity);
    tmp$ret$0 = Math.max(4, tmp0_max);
    IntIntMap.call($this, tmp$ret$0, loadFactor, true);
    return $this;
  }
  function IntIntMap_init_$Create$(initialCapacity, loadFactor) {
    return IntIntMap_init_$Init$(initialCapacity, loadFactor, objectCreate(protoOf(IntIntMap)));
  }
  function Companion_6() {
    Companion_instance_6 = this;
    this.b1e_1 = 2147483646;
    this.c1e_1 = 2147483647;
    this.d1e_1 = 0;
  }
  var Companion_instance_6;
  function Companion_getInstance_6() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function _get_stashStart__3psegu($this) {
    return $this.l1e_1.length - $this.k1e_1 | 0;
  }
  function grow($this) {
    var inc = $this.e1e_1 < 20 ? 3 : 1;
    var newnbits = $this.e1e_1 + inc | 0;
    var new_0 = new IntIntMap(newnbits, $this.f1e_1, true);
    var inductionVariable = 0;
    var last = $this.l1e_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var k = $this.l1e_1[n];
        Companion_getInstance_6();
        if (!(k === 0)) {
          new_0.p1e(k, $this.m1e_1[n]);
        }
      }
       while (inductionVariable <= last);
    $this.e1e_1 = new_0.e1e_1;
    $this.g1e_1 = new_0.g1e_1;
    $this.j1e_1 = new_0.j1e_1;
    $this.k1e_1 = new_0.k1e_1;
    $this.l1e_1 = new_0.l1e_1;
    $this.m1e_1 = new_0.m1e_1;
    $this.n1e_1 = new_0.n1e_1;
  }
  function growStash($this) {
    $this.k1e_1 = imul($this.k1e_1, 2);
    $this.l1e_1 = copyOf_1($this.l1e_1, $this.q1e());
    $this.m1e_1 = copyOf_1($this.m1e_1, $this.q1e());
  }
  function _getKeyIndex($this, key) {
    if (key === 0) {
      var tmp;
      if ($this.h1e_1) {
        Companion_getInstance_6();
        tmp = 2147483647;
      } else {
        tmp = -1;
      }
      return tmp;
    }
    var index1 = hash1($this, key);
    if ($this.l1e_1[index1] === key)
      return index1;
    var index2 = hash2($this, key);
    if ($this.l1e_1[index2] === key)
      return index2;
    var index3 = hash3($this, key);
    if ($this.l1e_1[index3] === key)
      return index3;
    var inductionVariable = _get_stashStart__3psegu($this);
    var last = $this.l1e_1.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if ($this.l1e_1[n] === key)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  function setEmptySlot($this, index, key, value) {
    var tmp = $this.l1e_1[index];
    Companion_getInstance_6();
    if (!(tmp === 0))
      throw IllegalStateException_init_$Create$_0();
    $this.l1e_1[index] = key;
    $this.m1e_1[index] = value;
    var tmp0_this = $this;
    var tmp1 = tmp0_this.o1e_1;
    tmp0_this.o1e_1 = tmp1 + 1 | 0;
    return 0;
  }
  function hash1($this, key) {
    return _hash1(key, $this.j1e_1);
  }
  function hash2($this, key) {
    return _hash2(key, $this.j1e_1);
  }
  function hash3($this, key) {
    return _hash3(key, $this.j1e_1);
  }
  function IntIntMap(nbits, loadFactor, dummy) {
    Companion_getInstance_6();
    this.e1e_1 = nbits;
    this.f1e_1 = loadFactor;
    this.g1e_1 = 1 << this.e1e_1;
    this.h1e_1 = false;
    this.i1e_1 = 0;
    this.j1e_1 = this.g1e_1 - 1 | 0;
    this.k1e_1 = 1 + imul(this.e1e_1, this.e1e_1) | 0;
    this.l1e_1 = new Int32Array(this.q1e());
    this.m1e_1 = new Int32Array(this.q1e());
    this.n1e_1 = numberToInt(this.g1e_1 * this.f1e_1);
    this.o1e_1 = 0;
  }
  protoOf(IntIntMap).toString = function () {
    return toString(this.r1e());
  };
  protoOf(IntIntMap).q1e = function () {
    return this.g1e_1 + this.k1e_1 | 0;
  };
  protoOf(IntIntMap).s1e = function (out) {
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var tmp;
    if (this.h1e_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.l1e_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.l1e_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntIntMap.toMap.<anonymous>' call
      var tmp1__anonymous__uwfjfc = this.n(tmp0__anonymous__q1qw7t);
      // Inline function 'kotlin.collections.set' call
      out.e5(tmp0__anonymous__q1qw7t, tmp1__anonymous__uwfjfc);
      var tmp_3 = this.l1e_1;
      var tmp_4;
      var tmp_5 = index;
      Companion_getInstance_7();
      if (tmp_5 === 2147483647) {
        tmp_4 = 0;
      } else {
        tmp_4 = index + 1 | 0;
      }
      index = this.t1e(tmp_3, tmp_4);
    }
    return out;
  };
  protoOf(IntIntMap).r1e = function (out, $super) {
    out = out === VOID ? linkedHashMapOf([]) : out;
    return $super === VOID ? this.s1e(out) : $super.s1e.call(this, out);
  };
  protoOf(IntIntMap).y9 = function (key) {
    return _getKeyIndex(this, key) >= 0;
  };
  protoOf(IntIntMap).e4 = function () {
    this.h1e_1 = false;
    this.i1e_1 = 0;
    fill(this.l1e_1, 0);
    fill(this.m1e_1, 0);
    this.o1e_1 = 0;
  };
  protoOf(IntIntMap).n = function (key) {
    var index = _getKeyIndex(this, key);
    if (index < 0)
      return 0;
    Companion_getInstance_6();
    if (index === 2147483647)
      return this.i1e_1;
    return this.m1e_1[index];
  };
  protoOf(IntIntMap).p1e = function (key, value) {
    retry: while (true) {
      var index = _getKeyIndex(this, key);
      if (index < 0) {
        if (key === 0) {
          this.h1e_1 = true;
          this.i1e_1 = value;
          var tmp0_this = this;
          var tmp1 = tmp0_this.o1e_1;
          tmp0_this.o1e_1 = tmp1 + 1 | 0;
          return 0;
        }
        if (this.o1e_1 >= this.n1e_1) {
          grow(this);
        }
        var index1 = hash1(this, key);
        var tmp = this.l1e_1[index1];
        Companion_getInstance_6();
        if (tmp === 0)
          return setEmptySlot(this, index1, key, value);
        var index2 = hash2(this, key);
        var tmp_0 = this.l1e_1[index2];
        Companion_getInstance_6();
        if (tmp_0 === 0)
          return setEmptySlot(this, index2, key, value);
        var index3 = hash3(this, key);
        var tmp_1 = this.l1e_1[index3];
        Companion_getInstance_6();
        if (tmp_1 === 0)
          return setEmptySlot(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu(this);
        var last = this.l1e_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp_2 = this.l1e_1[n];
            Companion_getInstance_6();
            if (tmp_2 === 0)
              return setEmptySlot(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.k1e_1 > 512) {
          grow(this);
        } else {
          growStash(this);
        }
        continue retry;
      } else {
        Companion_getInstance_6();
        if (index === 2147483647) {
          var tmp$ret$0;
          // Inline function 'kotlin.apply' call
          var tmp0_apply = this.i1e_1;
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
          this.i1e_1 = value;
          tmp$ret$0 = tmp0_apply;
          return tmp$ret$0;
        } else {
          var tmp$ret$1;
          // Inline function 'kotlin.apply' call
          var tmp1_apply = this.m1e_1[index];
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.datastructure.IntIntMap.set.<anonymous>' call
          this.m1e_1[index] = value;
          tmp$ret$1 = tmp1_apply;
          return tmp$ret$1;
        }
      }
    }
  };
  protoOf(IntIntMap).t1e = function (keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = keys[n];
        Companion_getInstance_6();
        if (!(tmp === 0))
          return n;
      }
       while (inductionVariable < last);
    Companion_getInstance_6();
    return 2147483646;
  };
  protoOf(IntIntMap).equals = function (other) {
    if (!(other instanceof IntIntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var tmp;
    if (this.h1e_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.l1e_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.l1e_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntIntMap.equals.<anonymous>' call
      var tmp1__anonymous__uwfjfc = this.n(tmp0__anonymous__q1qw7t);
      if (!(other.n(tmp0__anonymous__q1qw7t) === tmp1__anonymous__uwfjfc))
        return false;
      var tmp_3 = this.l1e_1;
      var tmp_4;
      var tmp_5 = index;
      Companion_getInstance_7();
      if (tmp_5 === 2147483647) {
        tmp_4 = 0;
      } else {
        tmp_4 = index + 1 | 0;
      }
      index = this.t1e(tmp_3, tmp_4);
    }
    return true;
  };
  protoOf(IntIntMap).hashCode = function () {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntIntMap.fastForEach' call
    // Inline function 'korlibs.datastructure.IntIntMap.fastKeyForEach' call
    var tmp;
    if (this.h1e_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.l1e_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntIntMap.fastForEach.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.l1e_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntIntMap.hashCode.<anonymous>' call
      var tmp1__anonymous__uwfjfc = this.n(tmp0__anonymous__q1qw7t);
      out = out + (tmp0__anonymous__q1qw7t + tmp1__anonymous__uwfjfc | 0) | 0;
      var tmp_3 = this.l1e_1;
      var tmp_4;
      var tmp_5 = index;
      Companion_getInstance_7();
      if (tmp_5 === 2147483647) {
        tmp_4 = 0;
      } else {
        tmp_4 = index + 1 | 0;
      }
      index = this.t1e(tmp_3, tmp_4);
    }
    return out;
  };
  function currentKey($this) {
    var tmp0_subject = $this.v1e_1;
    var tmp;
    var tmp_0;
    Companion_getInstance_7();
    if (tmp0_subject === 2147483647) {
      tmp_0 = true;
    } else {
      Companion_getInstance_7();
      tmp_0 = tmp0_subject === 2147483646;
    }
    if (tmp_0) {
      tmp = 0;
    } else {
      tmp = $this.u1e_1.e1f_1[$this.v1e_1];
    }
    return tmp;
  }
  function currentValue($this) {
    var tmp0_subject = $this.v1e_1;
    var tmp;
    Companion_getInstance_7();
    if (tmp0_subject === 2147483647) {
      tmp = $this.u1e_1.b1f_1;
    } else {
      Companion_getInstance_7();
      if (tmp0_subject === 2147483646) {
        tmp = null;
      } else {
        tmp = $this.u1e_1.f1f_1[$this.v1e_1];
      }
    }
    return tmp;
  }
  function nextNonEmptyIndex($this, keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = keys[n];
        Companion_getInstance_7();
        if (!(tmp === 0))
          return n;
      }
       while (inductionVariable < last);
    Companion_getInstance_7();
    return 2147483646;
  }
  function next($this) {
    var tmp = $this.v1e_1;
    Companion_getInstance_7();
    if (!(tmp === 2147483646)) {
      var tmp_0 = $this;
      var tmp_1 = $this.u1e_1.e1f_1;
      var tmp_2;
      var tmp_3 = $this.v1e_1;
      Companion_getInstance_7();
      if (tmp_3 === 2147483647) {
        tmp_2 = 0;
      } else {
        tmp_2 = $this.v1e_1 + 1 | 0;
      }
      tmp_0.v1e_1 = nextNonEmptyIndex($this, tmp_1, tmp_2);
    }
  }
  function IntMap$_get_keys_$o$iterator$lambda_bn8atq($tmp0_let) {
    return function () {
      return $tmp0_let.j();
    };
  }
  function IntMap$_get_keys_$o$iterator$lambda_bn8atq_0($tmp0_let) {
    return function () {
      return $tmp0_let.i1f();
    };
  }
  function IntMap$_get_values_$o$iterator$lambda_ggo058($tmp0_let) {
    return function () {
      return $tmp0_let.j();
    };
  }
  function IntMap$_get_values_$o$iterator$lambda_ggo058_0($tmp0_let) {
    return function () {
      return $tmp0_let.j1f();
    };
  }
  function IntMap_init_$Init$(initialCapacity, loadFactor, $this) {
    initialCapacity = initialCapacity === VOID ? 16 : initialCapacity;
    loadFactor = loadFactor === VOID ? 0.75 : loadFactor;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = ilog2Ceil(initialCapacity);
    tmp$ret$0 = Math.max(4, tmp0_max);
    IntMap.call($this, tmp$ret$0, loadFactor, true);
    return $this;
  }
  function IntMap_init_$Create$(initialCapacity, loadFactor) {
    return IntMap_init_$Init$(initialCapacity, loadFactor, objectCreate(protoOf(IntMap)));
  }
  function Companion_7() {
    Companion_instance_7 = this;
    this.k1f_1 = 2147483646;
    this.l1f_1 = 2147483647;
    this.m1f_1 = 0;
  }
  var Companion_instance_7;
  function Companion_getInstance_7() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function _get_stashStart__3psegu_0($this) {
    return $this.e1f_1.length - $this.d1f_1 | 0;
  }
  function grow_0($this) {
    var inc = $this.x1e_1 < 20 ? 3 : 1;
    var newnbits = $this.x1e_1 + inc | 0;
    var new_0 = new IntMap(newnbits, $this.y1e_1, true);
    var inductionVariable = 0;
    var last = $this.e1f_1.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var k = $this.e1f_1[n];
        Companion_getInstance_7();
        if (!(k === 0)) {
          new_0.n1f(k, $this.f1f_1[n]);
        }
      }
       while (inductionVariable <= last);
    $this.x1e_1 = new_0.x1e_1;
    $this.z1e_1 = new_0.z1e_1;
    $this.c1f_1 = new_0.c1f_1;
    $this.d1f_1 = new_0.d1f_1;
    $this.e1f_1 = new_0.e1f_1;
    $this.f1f_1 = new_0.f1f_1;
    $this.g1f_1 = new_0.g1f_1;
  }
  function growStash_0($this) {
    $this.d1f_1 = imul($this.d1f_1, 2);
    $this.e1f_1 = copyOf_1($this.e1f_1, $this.q1e());
    $this.f1f_1 = copyOf_2($this.f1f_1, $this.q1e());
  }
  function _getKeyIndex_0($this, key) {
    if (key === 0) {
      var tmp;
      if ($this.a1f_1) {
        Companion_getInstance_7();
        tmp = 2147483647;
      } else {
        tmp = -1;
      }
      return tmp;
    }
    var index1 = hash1_0($this, key);
    if ($this.e1f_1[index1] === key)
      return index1;
    var index2 = hash2_0($this, key);
    if ($this.e1f_1[index2] === key)
      return index2;
    var index3 = hash3_0($this, key);
    if ($this.e1f_1[index3] === key)
      return index3;
    var inductionVariable = _get_stashStart__3psegu_0($this);
    var last = $this.e1f_1.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if ($this.e1f_1[n] === key)
          return n;
      }
       while (inductionVariable < last);
    return -1;
  }
  function setEmptySlot_0($this, index, key, value) {
    var tmp = $this.e1f_1[index];
    Companion_getInstance_7();
    if (!(tmp === 0))
      throw IllegalStateException_init_$Create$_0();
    $this.e1f_1[index] = key;
    $this.f1f_1[index] = value;
    var tmp0_this = $this;
    var tmp1 = tmp0_this.h1f_1;
    tmp0_this.h1f_1 = tmp1 + 1 | 0;
    return null;
  }
  function hash1_0($this, key) {
    return _hash1(key, $this.c1f_1);
  }
  function hash2_0($this, key) {
    return _hash2(key, $this.c1f_1);
  }
  function hash3_0($this, key) {
    return _hash3(key, $this.c1f_1);
  }
  function Entry(key, value) {
    this.o1f_1 = key;
    this.p1f_1 = value;
  }
  protoOf(Entry).toString = function () {
    return 'Entry(key=' + this.o1f_1 + ', value=' + this.p1f_1 + ')';
  };
  protoOf(Entry).hashCode = function () {
    var result = this.o1f_1;
    result = imul(result, 31) + (this.p1f_1 == null ? 0 : hashCode(this.p1f_1)) | 0;
    return result;
  };
  protoOf(Entry).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Entry))
      return false;
    var tmp0_other_with_cast = other instanceof Entry ? other : THROW_CCE();
    if (!(this.o1f_1 === tmp0_other_with_cast.o1f_1))
      return false;
    if (!equals(this.p1f_1, tmp0_other_with_cast.p1f_1))
      return false;
    return true;
  };
  function Iterator(map) {
    this.u1e_1 = map;
    var tmp = this;
    var tmp_0;
    if (this.u1e_1.a1f_1) {
      Companion_getInstance_7();
      tmp_0 = 2147483647;
    } else {
      tmp_0 = nextNonEmptyIndex(this, this.u1e_1.e1f_1, 0);
    }
    tmp.v1e_1 = tmp_0;
    this.w1e_1 = new Entry(0, null);
  }
  protoOf(Iterator).j = function () {
    var tmp = this.v1e_1;
    Companion_getInstance_7();
    return !(tmp === 2147483646);
  };
  protoOf(Iterator).i1f = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = currentKey(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextKey.<anonymous>' call
    next(this);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  };
  protoOf(Iterator).j1f = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = currentValue(this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Iterator.nextValue.<anonymous>' call
    next(this);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  };
  function _no_name_provided__qut3iv(this$0) {
    this.q1f_1 = this$0;
  }
  protoOf(_no_name_provided__qut3iv).h = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = new Iterator(this.q1f_1);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.IntMap.<get-keys>.<anonymous>.<anonymous>' call
    var tmp = IntMap$_get_keys_$o$iterator$lambda_bn8atq(tmp0_let);
    tmp$ret$0 = Iterator_0(tmp, IntMap$_get_keys_$o$iterator$lambda_bn8atq_0(tmp0_let));
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  function _no_name_provided__qut3iv_0(this$0) {
    this.r1f_1 = this$0;
  }
  protoOf(_no_name_provided__qut3iv_0).h = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'kotlin.let' call
    var tmp0_let = new Iterator(this.r1f_1);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.IntMap.<get-values>.<anonymous>.<anonymous>' call
    var tmp = IntMap$_get_values_$o$iterator$lambda_ggo058(tmp0_let);
    tmp$ret$0 = Iterator_0(tmp, IntMap$_get_values_$o$iterator$lambda_ggo058_0(tmp0_let));
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  function IntMap(nbits, loadFactor, dummy) {
    Companion_getInstance_7();
    dummy = dummy === VOID ? false : dummy;
    this.x1e_1 = nbits;
    this.y1e_1 = loadFactor;
    this.z1e_1 = 1 << this.x1e_1;
    this.a1f_1 = false;
    this.b1f_1 = null;
    this.c1f_1 = this.z1e_1 - 1 | 0;
    this.d1f_1 = 1 + imul(this.x1e_1, this.x1e_1) | 0;
    this.e1f_1 = new Int32Array(this.q1e());
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp0_arrayOfNulls = this.q1e();
    tmp$ret$0 = fillArrayVal(Array(tmp0_arrayOfNulls), null);
    var tmp_0 = tmp$ret$0;
    tmp.f1f_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    this.g1f_1 = numberToInt(this.z1e_1 * this.y1e_1);
    this.h1f_1 = 0;
  }
  protoOf(IntMap).q1e = function () {
    return this.z1e_1 + this.d1f_1 | 0;
  };
  protoOf(IntMap).n = function (key) {
    var index = _getKeyIndex_0(this, key);
    if (index < 0)
      return null;
    Companion_getInstance_7();
    if (index === 2147483647)
      return this.b1f_1;
    return this.f1f_1[index];
  };
  protoOf(IntMap).n1f = function (key, value) {
    retry: while (true) {
      var index = _getKeyIndex_0(this, key);
      if (index < 0) {
        if (key === 0) {
          this.a1f_1 = true;
          this.b1f_1 = value;
          var tmp0_this = this;
          var tmp1 = tmp0_this.h1f_1;
          tmp0_this.h1f_1 = tmp1 + 1 | 0;
          return null;
        }
        if (this.h1f_1 >= this.g1f_1) {
          grow_0(this);
        }
        var index1 = hash1_0(this, key);
        var tmp = this.e1f_1[index1];
        Companion_getInstance_7();
        if (tmp === 0)
          return setEmptySlot_0(this, index1, key, value);
        var index2 = hash2_0(this, key);
        var tmp_0 = this.e1f_1[index2];
        Companion_getInstance_7();
        if (tmp_0 === 0)
          return setEmptySlot_0(this, index2, key, value);
        var index3 = hash3_0(this, key);
        var tmp_1 = this.e1f_1[index3];
        Companion_getInstance_7();
        if (tmp_1 === 0)
          return setEmptySlot_0(this, index3, key, value);
        var inductionVariable = _get_stashStart__3psegu_0(this);
        var last = this.e1f_1.length;
        if (inductionVariable < last)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp_2 = this.e1f_1[n];
            Companion_getInstance_7();
            if (tmp_2 === 0)
              return setEmptySlot_0(this, n, key, value);
          }
           while (inductionVariable < last);
        if (this.d1f_1 > 512) {
          grow_0(this);
        } else {
          growStash_0(this);
        }
        continue retry;
      } else {
        Companion_getInstance_7();
        if (index === 2147483647) {
          var tmp$ret$0;
          // Inline function 'kotlin.apply' call
          var tmp0_apply = this.b1f_1;
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
          this.b1f_1 = value;
          tmp$ret$0 = tmp0_apply;
          return tmp$ret$0;
        } else {
          var tmp$ret$1;
          // Inline function 'kotlin.apply' call
          var tmp1_apply = this.f1f_1[index];
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.datastructure.IntMap.set.<anonymous>' call
          this.f1f_1[index] = value;
          tmp$ret$1 = tmp1_apply;
          return tmp$ret$1;
        }
      }
    }
  };
  protoOf(IntMap).i3 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.Iterable' call
    tmp$ret$0 = new _no_name_provided__qut3iv(this);
    return tmp$ret$0;
  };
  protoOf(IntMap).j3 = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.Iterable' call
    tmp$ret$0 = new _no_name_provided__qut3iv_0(this);
    return tmp$ret$0;
  };
  protoOf(IntMap).t1e = function (keys, offset) {
    var inductionVariable = offset;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp = keys[n];
        Companion_getInstance_7();
        if (!(tmp === 0))
          return n;
      }
       while (inductionVariable < last);
    Companion_getInstance_7();
    return 2147483646;
  };
  protoOf(IntMap).equals = function (other) {
    if (!(other instanceof IntMap))
      return false;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var tmp;
    if (this.a1f_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.e1f_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.e1f_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntMap.equals.<anonymous>' call
      var tmp1__anonymous__uwfjfc = this.n(tmp0__anonymous__q1qw7t);
      if (!equals(other.n(tmp0__anonymous__q1qw7t), tmp1__anonymous__uwfjfc))
        return false;
      var tmp_3 = this.e1f_1;
      var tmp_4;
      var tmp_5 = index;
      Companion_getInstance_7();
      if (tmp_5 === 2147483647) {
        tmp_4 = 0;
      } else {
        tmp_4 = index + 1 | 0;
      }
      index = this.t1e(tmp_3, tmp_4);
    }
    return true;
  };
  protoOf(IntMap).hashCode = function () {
    var out = 0;
    // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var tmp;
    if (this.a1f_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.e1f_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntMap.fastForEachNullable.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.e1f_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntMap.hashCode.<anonymous>' call
      var tmp1__anonymous__uwfjfc = this.n(tmp0__anonymous__q1qw7t);
      var tmp_3 = out;
      var tmp_4 = tmp0__anonymous__q1qw7t;
      var tmp$ret$0;
      // Inline function 'kotlin.hashCode' call
      var tmp0_safe_receiver = tmp1__anonymous__uwfjfc;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
      tmp$ret$0 = tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
      out = tmp_3 + (tmp_4 + tmp$ret$0 | 0) | 0;
      var tmp_5 = this.e1f_1;
      var tmp_6;
      var tmp_7 = index;
      Companion_getInstance_7();
      if (tmp_7 === 2147483647) {
        tmp_6 = 0;
      } else {
        tmp_6 = index + 1 | 0;
      }
      index = this.t1e(tmp_5, tmp_6);
    }
    return out;
  };
  protoOf(IntMap).s1f = function () {
    // Inline function 'korlibs.datastructure.IntMap.fastValueForEach' call
    // Inline function 'korlibs.datastructure.IntMap.fastKeyForEach' call
    var tmp;
    if (this.a1f_1) {
      Companion_getInstance_7();
      tmp = 2147483647;
    } else {
      tmp = this.t1e(this.e1f_1, 0);
    }
    var index = tmp;
    $l$loop: while (true) {
      var tmp_0 = index;
      Companion_getInstance_7();
      if (!!(tmp_0 === 2147483646)) {
        break $l$loop;
      }
      // Inline function 'korlibs.datastructure.IntMap.fastValueForEach.<anonymous>' call
      var tmp0_subject = index;
      var tmp_1;
      var tmp_2;
      Companion_getInstance_7();
      if (tmp0_subject === 2147483647) {
        tmp_2 = true;
      } else {
        Companion_getInstance_7();
        tmp_2 = tmp0_subject === 2147483646;
      }
      if (tmp_2) {
        tmp_1 = 0;
      } else {
        tmp_1 = this.e1f_1[index];
      }
      var tmp0__anonymous__q1qw7t = tmp_1;
      // Inline function 'korlibs.datastructure.IntMap.firstValue.<anonymous>' call
      var tmp1__anonymous__uwfjfc = ensureNotNull(this.n(tmp0__anonymous__q1qw7t));
      return tmp1__anonymous__uwfjfc;
      var tmp_3 = this.e1f_1;
      var tmp_4;
      var tmp_5 = index;
      Companion_getInstance_7();
      if (tmp_5 === 2147483647) {
        tmp_4 = 0;
      } else {
        tmp_4 = index + 1 | 0;
      }
      index = this.t1e(tmp_3, tmp_4);
    }
    // Inline function 'kotlin.error' call
    throw IllegalStateException_init_$Create$('firstValue on empty IntMap');
  };
  function toIntMap(_this__u8e3s4) {
    var out = IntMap_init_$Create$(numberToInt(_this__u8e3s4.l() * 1.25));
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = _this__u8e3s4.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var tmp1_loop_parameter = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'kotlin.collections.component1' call
      tmp$ret$1 = tmp1_loop_parameter.e1();
      var k = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.collections.component2' call
      tmp$ret$2 = tmp1_loop_parameter.f1();
      var v = tmp$ret$2;
      out.n1f(k, v);
    }
    return out;
  }
  function _hash1(key, mask) {
    return _mask(key, mask);
  }
  function _hash2(key, mask) {
    return _mask(imul(key, 1294968109), mask);
  }
  function _hash3(key, mask) {
    return _mask(imul(key, 294969449), mask);
  }
  function _mask(value, mask) {
    return (((value + ((value >>> 8 | 0) & 255) | 0) + ((value >>> 16 | 0) & 255) | 0) + (value >> 24 & 255) | 0) & mask;
  }
  function associateByInt(_this__u8e3s4, block) {
    var n = 0;
    var out = IntMap_init_$Create$();
    var tmp0_iterator = _this__u8e3s4.h();
    while (tmp0_iterator.j()) {
      var it = tmp0_iterator.k();
      var tmp1 = n;
      n = tmp1 + 1 | 0;
      out.n1f(block(tmp1, it), it);
    }
    return out;
  }
  function Iterator_0(hasNext, next) {
    return new Iterator$1(hasNext, next);
  }
  function Iterator$1($hasNext, $next) {
    this.t1f_1 = $hasNext;
    this.u1f_1 = $next;
  }
  protoOf(Iterator$1).j = function () {
    return this.t1f_1();
  };
  protoOf(Iterator$1).k = function () {
    return this.u1f_1();
  };
  function Companion_8() {
    Companion_instance_8 = this;
  }
  var Companion_instance_8;
  function Companion_getInstance_8() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function Pool_init_$Init$(preallocate, gen, $this) {
    preallocate = preallocate === VOID ? 0 : preallocate;
    Pool.call($this, Pool$_init_$lambda_q3cbhr_0, preallocate, gen);
    return $this;
  }
  function Pool_init_$Create$(preallocate, gen) {
    return Pool_init_$Init$(preallocate, gen, objectCreate(protoOf(Pool)));
  }
  function Pool$_init_$lambda_q3cbhr(it) {
    return Unit_getInstance();
  }
  function Pool$_init_$lambda_q3cbhr_0(it) {
    return Unit_getInstance();
  }
  function Pool(reset, preallocate, gen) {
    Companion_getInstance_8();
    var tmp;
    if (reset === VOID) {
      tmp = Pool$_init_$lambda_q3cbhr;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    this.v1f_1 = reset;
    this.w1f_1 = gen;
    this.x1f_1 = _TGenStack___init__impl__yynjgt();
    this.y1f_1 = 0;
    var inductionVariable = 0;
    if (inductionVariable < preallocate)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp1_this = this;
        var tmp2 = tmp1_this.y1f_1;
        tmp1_this.y1f_1 = tmp2 + 1 | 0;
        TGenStack__push_impl_9yh33t(this.x1f_1, this.w1f_1(tmp2));
      }
       while (inductionVariable < preallocate);
  }
  protoOf(Pool).z1f = function () {
    return _TGenStack___get_size__impl__cvg66t(this.x1f_1);
  };
  protoOf(Pool).a1g = function () {
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isNotEmpty' call
    var tmp0_isNotEmpty = this.x1f_1;
    tmp$ret$0 = !(new TGenStack(tmp0_isNotEmpty)).p();
    if (tmp$ret$0) {
      tmp = TGenStack__pop_impl_qisxa0(this.x1f_1);
    } else {
      var tmp0_this = this;
      var tmp1 = tmp0_this.y1f_1;
      tmp0_this.y1f_1 = tmp1 + 1 | 0;
      tmp = this.w1f_1(tmp1);
    }
    return tmp;
  };
  protoOf(Pool).e4 = function () {
    TGenStack__clear_impl_pali4s(this.x1f_1);
    this.y1f_1 = 0;
  };
  protoOf(Pool).b1g = function (element) {
    this.v1f_1(element);
    TGenStack__push_impl_9yh33t(this.x1f_1, element);
  };
  protoOf(Pool).c1g = function (elements) {
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      this.b1g(element);
    }
  };
  protoOf(Pool).hashCode = function () {
    return TGenStack__hashCode_impl_l04zom(this.x1f_1);
  };
  protoOf(Pool).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof Pool) {
      tmp_0 = equals(this.x1f_1, other.x1f_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = this.z1f() === other.z1f();
    } else {
      tmp = false;
    }
    return tmp;
  };
  function ConcurrentPool$_init_$lambda_gqhtk8(it) {
    return Unit_getInstance();
  }
  function ConcurrentPool(reset, preallocate, gen) {
    var tmp;
    if (reset === VOID) {
      tmp = ConcurrentPool$_init_$lambda_gqhtk8;
    } else {
      tmp = reset;
    }
    reset = tmp;
    preallocate = preallocate === VOID ? 0 : preallocate;
    Pool.call(this, reset, preallocate, gen);
    this.h1g_1 = reset;
    this.i1g_1 = gen;
    this.j1g_1 = new NonRecursiveLock();
  }
  protoOf(ConcurrentPool).a1g = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp0_invoke = this.j1g_1;
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.ConcurrentPool.alloc.<anonymous>' call
    tmp$ret$0 = protoOf(Pool).a1g.call(this);
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  };
  protoOf(ConcurrentPool).e4 = function () {
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp0_invoke = this.j1g_1;
    protoOf(Pool).e4.call(this);
    tmp$ret$0 = Unit_getInstance();
  };
  protoOf(ConcurrentPool).b1g = function (element) {
    var tmp$ret$0;
    // Inline function 'korlibs.datastructure.lock.NonRecursiveLock.invoke' call
    var tmp0_invoke = this.j1g_1;
    protoOf(Pool).b1g.call(this, element);
    tmp$ret$0 = Unit_getInstance();
  };
  function ReturnablePool$_init_$lambda_rsm60r(it) {
    return Unit_getInstance();
  }
  function ReturnablePool$list$lambda(this$0) {
    return function (it) {
      this$0.k1g_1(it);
      return Unit_getInstance();
    };
  }
  function ReturnablePool$list$lambda_0(this$0) {
    return function (it) {
      return this$0.l1g_1(it);
    };
  }
  function ReturnablePool(_reset, gen) {
    var tmp;
    if (_reset === VOID) {
      tmp = ReturnablePool$_init_$lambda_rsm60r;
    } else {
      tmp = _reset;
    }
    _reset = tmp;
    this.k1g_1 = _reset;
    this.l1g_1 = gen;
    this.m1g_1 = fastArrayListOf([]);
    var tmp_0 = this;
    var tmp_1 = ReturnablePool$list$lambda(this);
    tmp_0.n1g_1 = new Pool(tmp_1, VOID, ReturnablePool$list$lambda_0(this));
    this.o1g_1 = this.n1g_1.a1g();
  }
  protoOf(ReturnablePool).k = function () {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.m1g_1;
    var tmp1_plusAssign = this.o1g_1;
    tmp0_plusAssign.b(tmp1_plusAssign);
    this.o1g_1 = this.n1g_1.a1g();
    return this.o1g_1;
  };
  protoOf(ReturnablePool).p1g = function () {
    // Inline function 'korlibs.datastructure.FastArrayList.fastForEach' call
    var tmp3_fastForEach = this.m1g_1;
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = tmp3_fastForEach.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var array = tmp$ret$2;
    var n = 0;
    while (n < array.length) {
      // Inline function 'korlibs.datastructure.ReturnablePool.reset.<anonymous>' call
      var tmp$ret$6;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$4;
      // Inline function 'korlibs.datastructure.get' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1_get = tmp0;
      var tmp$ret$3;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$3 = array;
      tmp$ret$4 = tmp$ret$3[tmp1_get];
      var tmp2_unsafeCast = tmp$ret$4;
      var tmp$ret$5;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$5 = tmp2_unsafeCast;
      tmp$ret$6 = tmp$ret$5;
      var tmp4__anonymous__pkmkx7 = tmp$ret$6;
      this.n1g_1.b1g(tmp4__anonymous__pkmkx7);
    }
    this.m1g_1.e4();
  };
  function sam$kotlin_Comparator$0(function_0) {
    this.q1g_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).l8 = function (a, b) {
    return this.q1g_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function Companion_9() {
    Companion_instance_9 = this;
  }
  protoOf(Companion_9).r1g = function (comparator, reversed_0) {
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(16), null);
    var tmp = tmp$ret$0;
    return new TGenPriorityQueue(isArray(tmp) ? tmp : THROW_CCE(), reversed_0 ? reversed(comparator) : comparator);
  };
  protoOf(Companion_9).s1g = function (reversed, comparator) {
    return Companion_getInstance_9().r1g(new sam$kotlin_Comparator$0(comparator), reversed);
  };
  protoOf(Companion_9).t1g = function (reversed, comparator, $super) {
    reversed = reversed === VOID ? false : reversed;
    return $super === VOID ? this.s1g(reversed, comparator) : $super.s1g.call(this, reversed, comparator);
  };
  var Companion_instance_9;
  function Companion_getInstance_9() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function _set_value__lx0xdg(_this__u8e3s4, $this, value) {
    $this.u1g_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)] = value;
  }
  function _get_value__a43j40(_this__u8e3s4, $this) {
    return $this.u1g_1[_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)];
  }
  function _get_isRoot__1006zj(_this__u8e3s4, $this) {
    return _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) === 0;
  }
  function _get_parent__oo9xup(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu((_PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4) - 1 | 0) / 2 | 0);
  }
  function _get_left__d9qyp0(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 1 | 0);
  }
  function _get_right__bvz45n(_this__u8e3s4, $this) {
    return _PriorityQueueNode___init__impl__34dlfu(imul(2, _PriorityQueueNode___get_index__impl__b6r7i5(_this__u8e3s4)) + 2 | 0);
  }
  function gt($this, a, b) {
    return $this.v1g_1.compare(a, b) > 0;
  }
  function lt($this, a, b) {
    return $this.v1g_1.compare(a, b) < 0;
  }
  function _get_capacity__a9k9f3_1($this) {
    return $this.u1g_1.length;
  }
  function ensure_1($this, index) {
    if (index >= _get_capacity__a9k9f3_1($this)) {
      var tmp = $this;
      var tmp_0 = copyOf_2($this.u1g_1, 2 + imul(_get_capacity__a9k9f3_1($this), 2) | 0);
      tmp.u1g_1 = isArray(tmp_0) ? tmp_0 : THROW_CCE();
    }
  }
  function minHeapify($this, index) {
    var i = _PriorityQueueNode___init__impl__34dlfu(index);
    $l$loop: while (true) {
      var left = _get_left__d9qyp0(i, $this);
      var right = _get_right__bvz45n(i, $this);
      var smallest = i;
      if (_PriorityQueueNode___get_index__impl__b6r7i5(left) < $this.w1g_1 ? lt($this, _get_value__a43j40(left, $this), _get_value__a43j40(i, $this)) : false)
        smallest = left;
      if (_PriorityQueueNode___get_index__impl__b6r7i5(right) < $this.w1g_1 ? lt($this, _get_value__a43j40(right, $this), _get_value__a43j40(smallest, $this)) : false)
        smallest = right;
      if (!(smallest === i)) {
        swap_0($this, i, smallest);
        i = smallest;
      } else {
        break $l$loop;
      }
    }
  }
  function swap_0($this, l, r) {
    var temp = _get_value__a43j40(r, $this);
    _set_value__lx0xdg(r, $this, _get_value__a43j40(l, $this));
    _set_value__lx0xdg(l, $this, temp);
  }
  function TGenPriorityQueue$iterator$1($index, this$0) {
    this.x1g_1 = $index;
    this.y1g_1 = this$0;
  }
  protoOf(TGenPriorityQueue$iterator$1).j = function () {
    return this.x1g_1._v < this.y1g_1.w1g_1;
  };
  protoOf(TGenPriorityQueue$iterator$1).k = function () {
    var tmp0 = this.x1g_1._v;
    this.x1g_1._v = tmp0 + 1 | 0;
    return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(tmp0), this.y1g_1);
  };
  protoOf(TGenPriorityQueue$iterator$1).pw = function () {
    throw new NotImplementedError();
  };
  protoOf(TGenPriorityQueue$iterator$1).f5 = function () {
    return this.pw();
  };
  function TGenPriorityQueue(data, comparator) {
    Companion_getInstance_9();
    this.u1g_1 = data;
    this.v1g_1 = comparator;
    this.w1g_1 = 0;
  }
  protoOf(TGenPriorityQueue).l = function () {
    return this.w1g_1;
  };
  protoOf(TGenPriorityQueue).z1g = function () {
    if (this.w1g_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    return this.u1g_1[0];
  };
  protoOf(TGenPriorityQueue).h1c = function (element) {
    var tmp0_this = this;
    var tmp1 = tmp0_this.w1g_1;
    tmp0_this.w1g_1 = tmp1 + 1 | 0;
    ensure_1(this, this.w1g_1);
    var i = _PriorityQueueNode___init__impl__34dlfu(this.w1g_1 - 1 | 0);
    _set_value__lx0xdg(i, this, element);
    while (!_get_isRoot__1006zj(i, this) ? gt(this, _get_value__a43j40(_get_parent__oo9xup(i, this), this), _get_value__a43j40(i, this)) : false) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    return true;
  };
  protoOf(TGenPriorityQueue).b = function (element) {
    return this.h1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenPriorityQueue).a1h = function () {
    if (this.w1g_1 <= 0)
      throw IndexOutOfBoundsException_init_$Create$();
    if (this.w1g_1 === 1) {
      var tmp0_this = this;
      var tmp1 = tmp0_this.w1g_1;
      tmp0_this.w1g_1 = tmp1 - 1 | 0;
      return _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    }
    var root = _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(0), this);
    _set_value__lx0xdg(_PriorityQueueNode___init__impl__34dlfu(0), this, _get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(this.w1g_1 - 1 | 0), this));
    var tmp2_this = this;
    var tmp3 = tmp2_this.w1g_1;
    tmp2_this.w1g_1 = tmp3 - 1 | 0;
    minHeapify(this, 0);
    return root;
  };
  protoOf(TGenPriorityQueue).j1c = function (element) {
    var inductionVariable = 0;
    var last = this.w1g_1;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.u1g_1[n], element))
          return n;
      }
       while (inductionVariable < last);
    return -1;
  };
  protoOf(TGenPriorityQueue).i1c = function (element) {
    var index = this.j1c(element);
    if (index >= 0) {
      this.b1h(index);
    }
    return index >= 0;
  };
  protoOf(TGenPriorityQueue).b4 = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.i1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenPriorityQueue).b1h = function (index) {
    var i = _PriorityQueueNode___init__impl__34dlfu(index);
    while (!(_PriorityQueueNode___get_index__impl__b6r7i5(i) === 0)) {
      swap_0(this, i, _get_parent__oo9xup(i, this));
      i = _get_parent__oo9xup(i, this);
    }
    this.a1h();
  };
  protoOf(TGenPriorityQueue).m1c = function (element) {
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = until(0, this.w1g_1);
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var inductionVariable = tmp0_any.p1_1;
      var last = tmp0_any.q1_1;
      if (inductionVariable <= last)
        do {
          var element_0 = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$1;
          // Inline function 'korlibs.datastructure.TGenPriorityQueue.contains.<anonymous>' call
          tmp$ret$1 = equals(_get_value__a43j40(_PriorityQueueNode___init__impl__34dlfu(element_0), this), element);
          if (tmp$ret$1) {
            tmp$ret$0 = true;
            break $l$block_0;
          }
        }
         while (!(element_0 === last));
      tmp$ret$0 = false;
    }
    return tmp$ret$0;
  };
  protoOf(TGenPriorityQueue).q = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.m1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenPriorityQueue).n1c = function (elements) {
    var thisSet = toSet(this);
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.all' call
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = true;
        break $l$block_0;
      }
      var tmp0_iterator = elements.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'korlibs.datastructure.TGenPriorityQueue.containsAll.<anonymous>' call
        tmp$ret$1 = thisSet.q(element);
        if (!tmp$ret$1) {
          tmp$ret$0 = false;
          break $l$block_0;
        }
      }
      tmp$ret$0 = true;
    }
    return tmp$ret$0;
  };
  protoOf(TGenPriorityQueue).w1 = function (elements) {
    return this.n1c(elements);
  };
  protoOf(TGenPriorityQueue).p = function () {
    return this.w1g_1 === 0;
  };
  protoOf(TGenPriorityQueue).c1c = function (elements) {
    var tmp0_iterator = elements.h();
    while (tmp0_iterator.j()) {
      var e = tmp0_iterator.k();
      this.h1c(e);
    }
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isNotEmpty' call
    tmp$ret$0 = !elements.p();
    return tmp$ret$0;
  };
  protoOf(TGenPriorityQueue).m = function (elements) {
    return this.c1c(elements);
  };
  protoOf(TGenPriorityQueue).e4 = function () {
    this.w1g_1 = 0;
  };
  protoOf(TGenPriorityQueue).k1c = function (elements) {
    var temp = ArrayList_init_$Create$_0(toList(this));
    var res = temp.d4(elements);
    this.e4();
    this.c1c(temp);
    return res;
  };
  protoOf(TGenPriorityQueue).d4 = function (elements) {
    return this.k1c(elements);
  };
  protoOf(TGenPriorityQueue).h = function () {
    var index = {_v: 0};
    return new TGenPriorityQueue$iterator$1(index, this);
  };
  protoOf(TGenPriorityQueue).toString = function () {
    return toString(toList(this));
  };
  protoOf(TGenPriorityQueue).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof TGenPriorityQueue) {
      tmp_0 = contentEquals_1(this.u1g_1, other.u1g_1);
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      tmp = equals(this.v1g_1, other.v1g_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(TGenPriorityQueue).hashCode = function () {
    return contentHashCode_1(this.u1g_1);
  };
  function _PriorityQueueNode___init__impl__34dlfu(index) {
    return index;
  }
  function _PriorityQueueNode___get_index__impl__b6r7i5($this) {
    return $this;
  }
  function TGenQueue() {
    this.c1h_1 = TGenDeque_init_$Create$();
  }
  protoOf(TGenQueue).l = function () {
    return this.c1h_1.l();
  };
  protoOf(TGenQueue).p = function () {
    return this.l() === 0;
  };
  protoOf(TGenQueue).d1h = function (v) {
    this.c1h_1.z1b(v);
  };
  protoOf(TGenQueue).e1h = function () {
    return this.c1h_1.w3();
  };
  protoOf(TGenQueue).m1c = function (element) {
    return this.c1h_1.m1c(element);
  };
  protoOf(TGenQueue).q = function (element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    return this.m1c((element == null ? true : isObject(element)) ? element : THROW_CCE());
  };
  protoOf(TGenQueue).n1c = function (elements) {
    return this.c1h_1.n1c(elements);
  };
  protoOf(TGenQueue).w1 = function (elements) {
    return this.n1c(elements);
  };
  protoOf(TGenQueue).h = function () {
    return this.c1h_1.h();
  };
  protoOf(TGenQueue).hashCode = function () {
    return this.c1h_1.hashCode();
  };
  protoOf(TGenQueue).equals = function (other) {
    var tmp;
    if (other instanceof TGenQueue) {
      tmp = this.c1h_1.equals(other.c1h_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  function Ref(value) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = Ref_0();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.Ref.<anonymous>' call
    tmp0_also.f1h(value);
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function toRef(_this__u8e3s4) {
    return Ref_1(_this__u8e3s4);
  }
  function Ref_0() {
    return new Ref$2();
  }
  function Ref_1(prop) {
    return new Ref$3(prop);
  }
  function Ref$2() {
  }
  protoOf(Ref$2).f1h = function (_set____db54di) {
    this.g1h_1 = _set____db54di;
  };
  protoOf(Ref$2).f1 = function () {
    var tmp = this.g1h_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('value');
    }
  };
  function Ref$3($prop) {
    this.h1h_1 = $prop;
  }
  protoOf(Ref$3).f1h = function (value) {
    this.h1h_1.set(value);
  };
  protoOf(Ref$3).f1 = function () {
    return this.h1h_1.get();
  };
  function RingBuffer(bits) {
    ByteRingBuffer.call(this, bits);
  }
  function ByteRingBuffer(bits) {
    this.i1h_1 = bits;
    this.j1h_1 = 1 << this.i1h_1;
    this.k1h_1 = this.j1h_1 - 1 | 0;
    this.l1h_1 = new Int8Array(this.j1h_1);
    this.m1h_1 = 0;
    this.n1h_1 = 0;
    this.o1h_1 = this.j1h_1;
    this.p1h_1 = 0;
  }
  protoOf(ByteRingBuffer).q1h = function () {
    return this.l1h_1;
  };
  protoOf(ByteRingBuffer).r1h = function () {
    return this.n1h_1 & this.k1h_1;
  };
  protoOf(ByteRingBuffer).s1h = function (count) {
    if (count < 0 ? true : count > this.o1h_1) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException_init_$Create$('Try to write more than available');
    }
    var tmp0_this = this;
    tmp0_this.n1h_1 = tmp0_this.n1h_1 + count | 0;
    var tmp1_this = this;
    tmp1_this.p1h_1 = tmp1_this.p1h_1 + count | 0;
    var tmp2_this = this;
    tmp2_this.o1h_1 = tmp2_this.o1h_1 - count | 0;
  };
  protoOf(ByteRingBuffer).t1h = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.p1h_1;
    var tmp1_min = this.j1h_1 - (this.m1h_1 & this.k1h_1) | 0;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  };
  protoOf(ByteRingBuffer).u1h = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.o1h_1;
    var tmp1_min = this.j1h_1 - (this.n1h_1 & this.k1h_1) | 0;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    return tmp$ret$0;
  };
  protoOf(ByteRingBuffer).v1h = function (data, offset, size) {
    return this.w1h(this.x1h(data, offset, size));
  };
  protoOf(ByteRingBuffer).w1h = function (size) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.p1h_1;
    tmp$ret$0 = Math.min(tmp0_min, size);
    var toRead = tmp$ret$0;
    this.m1h_1 = (this.m1h_1 + toRead | 0) & this.k1h_1;
    var tmp0_this = this;
    tmp0_this.o1h_1 = tmp0_this.o1h_1 + toRead | 0;
    var tmp1_this = this;
    tmp1_this.p1h_1 = tmp1_this.p1h_1 - toRead | 0;
    return toRead;
  };
  protoOf(ByteRingBuffer).x1h = function (data, offset, size) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.p1h_1;
    tmp$ret$0 = Math.min(tmp0_min, size);
    var toRead = tmp$ret$0;
    var readCount = 0;
    var buffer = this.l1h_1;
    var mask = this.k1h_1;
    var coffset = offset;
    var lReadPos = this.m1h_1;
    $l$loop: while (true) {
      var tmp$ret$1;
      // Inline function 'kotlin.math.min' call
      var tmp1_min = toRead;
      var tmp2_min = this.t1h();
      tmp$ret$1 = Math.min(tmp1_min, tmp2_min);
      var toReadChunk = tmp$ret$1;
      if (toReadChunk <= 0)
        break $l$loop;
      arraycopy_2(buffer, lReadPos & mask, data, coffset, toReadChunk);
      toRead = toRead - toReadChunk | 0;
      coffset = coffset + toReadChunk | 0;
      lReadPos = lReadPos + toReadChunk | 0;
      readCount = readCount + toReadChunk | 0;
    }
    return readCount;
  };
  protoOf(ByteRingBuffer).z1h = function () {
    if (this.p1h_1 <= 0)
      return -1;
    var out = this.l1h_1[this.m1h_1] & 255;
    this.m1h_1 = (this.m1h_1 + 1 | 0) & this.k1h_1;
    var tmp0_this = this;
    var tmp1 = tmp0_this.p1h_1;
    tmp0_this.p1h_1 = tmp1 - 1 | 0;
    var tmp2_this = this;
    var tmp3 = tmp2_this.o1h_1;
    tmp2_this.o1h_1 = tmp3 + 1 | 0;
    return out;
  };
  protoOf(ByteRingBuffer).y1h = function (offset) {
    return this.l1h_1[(this.m1h_1 + offset | 0) & this.k1h_1];
  };
  protoOf(ByteRingBuffer).equals = function (other) {
    var tmp;
    var tmp_0;
    if (other instanceof ByteRingBuffer) {
      tmp_0 = this.p1h_1 === other.p1h_1;
    } else {
      tmp_0 = false;
    }
    if (tmp_0) {
      var tmp$ret$1;
      $l$block: {
        // Inline function 'korlibs.datastructure.internal.equaler' call
        var tmp0_equaler = this.p1h_1;
        var inductionVariable = 0;
        if (inductionVariable < tmp0_equaler)
          do {
            var n = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp$ret$0;
            // Inline function 'korlibs.datastructure.ByteRingBuffer.equals.<anonymous>' call
            tmp$ret$0 = this.y1h(n) === other.y1h(n);
            if (!tmp$ret$0) {
              tmp$ret$1 = false;
              break $l$block;
            }
          }
           while (inductionVariable < tmp0_equaler);
        tmp$ret$1 = true;
      }
      tmp = tmp$ret$1;
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(ByteRingBuffer).hashCode = function () {
    return this.a1i();
  };
  protoOf(ByteRingBuffer).a1i = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var tmp0_hashCoder = this.p1h_1;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_hashCoder)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.ByteRingBuffer.contentHashCode.<anonymous>' call
        tmp$ret$0 = this.y1h(n);
        out = tmp + tmp$ret$0 | 0;
      }
       while (inductionVariable < tmp0_hashCoder);
    tmp$ret$1 = out;
    return tmp$ret$1;
  };
  function _TGenStack___init__impl__yynjgt(items) {
    items = items === VOID ? FastArrayList_init_$Create$() : items;
    return items;
  }
  function _get_items__fzd5gv($this) {
    return $this;
  }
  function _TGenStack___get_size__impl__cvg66t($this) {
    return _get_items__fzd5gv($this).l();
  }
  function TGenStack__isEmpty_impl_bsbpyi($this) {
    return _TGenStack___get_size__impl__cvg66t($this) === 0;
  }
  function Companion_10() {
    Companion_instance_10 = this;
  }
  var Companion_instance_10;
  function Companion_getInstance_10() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function TGenStack__push_impl_9yh33t($this, v) {
    _get_items__fzd5gv($this).b(v);
  }
  function TGenStack__pop_impl_qisxa0($this) {
    return _get_items__fzd5gv($this).c4(_get_items__fzd5gv($this).l() - 1 | 0);
  }
  function TGenStack__clear_impl_pali4s($this) {
    _get_items__fzd5gv($this).e4();
  }
  function TGenStack__contains_impl_l8kli6($this, element) {
    return _get_items__fzd5gv($this).q(element);
  }
  function TGenStack__contains_impl_l8kli6_0($this, element) {
    if (!(element == null ? true : isObject(element)))
      return false;
    var tmp = $this.b1i_1;
    return TGenStack__contains_impl_l8kli6(tmp, (element == null ? true : isObject(element)) ? element : THROW_CCE());
  }
  function TGenStack__containsAll_impl_ic9i7r($this, elements) {
    return _get_items__fzd5gv($this).w1(elements);
  }
  function TGenStack__containsAll_impl_ic9i7r_0($this, elements) {
    return TGenStack__containsAll_impl_ic9i7r($this.b1i_1, elements);
  }
  function TGenStack__iterator_impl_2pdia5($this) {
    return _get_items__fzd5gv($this).h();
  }
  function TGenStack__toString_impl_s9t5hn($this) {
    return 'TGenStack(items=' + $this + ')';
  }
  function TGenStack__hashCode_impl_l04zom($this) {
    return $this.hashCode();
  }
  function TGenStack__equals_impl_w9i35u($this, other) {
    if (!(other instanceof TGenStack))
      return false;
    var tmp0_other_with_cast = other instanceof TGenStack ? other.b1i_1 : THROW_CCE();
    if (!$this.equals(tmp0_other_with_cast))
      return false;
    return true;
  }
  function TGenStack(items) {
    Companion_getInstance_10();
    this.b1i_1 = items;
  }
  protoOf(TGenStack).l = function () {
    return _TGenStack___get_size__impl__cvg66t(this.b1i_1);
  };
  protoOf(TGenStack).p = function () {
    return TGenStack__isEmpty_impl_bsbpyi(this.b1i_1);
  };
  protoOf(TGenStack).m1c = function (element) {
    return TGenStack__contains_impl_l8kli6(this.b1i_1, element);
  };
  protoOf(TGenStack).q = function (element) {
    return TGenStack__contains_impl_l8kli6_0(this, element);
  };
  protoOf(TGenStack).n1c = function (elements) {
    return TGenStack__containsAll_impl_ic9i7r(this.b1i_1, elements);
  };
  protoOf(TGenStack).w1 = function (elements) {
    return TGenStack__containsAll_impl_ic9i7r_0(this, elements);
  };
  protoOf(TGenStack).h = function () {
    return TGenStack__iterator_impl_2pdia5(this.b1i_1);
  };
  protoOf(TGenStack).toString = function () {
    return TGenStack__toString_impl_s9t5hn(this.b1i_1);
  };
  protoOf(TGenStack).hashCode = function () {
    return TGenStack__hashCode_impl_l04zom(this.b1i_1);
  };
  protoOf(TGenStack).equals = function (other) {
    return TGenStack__equals_impl_w9i35u(this.b1i_1, other);
  };
  function Extra$PropertyThis$transform$lambda($this$null, it) {
    return it;
  }
  function Mixin(extra) {
    extra = extra === VOID ? null : extra;
    this.c1i_1 = extra;
  }
  protoOf(Mixin).d1i = function (_set____db54di) {
    this.c1i_1 = _set____db54di;
  };
  protoOf(Mixin).e1i = function () {
    return this.c1i_1;
  };
  function Property(name, defaultGen) {
    name = name === VOID ? null : name;
    this.f1i_1 = name;
    this.g1i_1 = defaultGen;
  }
  function PropertyThis(name, defaultGen) {
    name = name === VOID ? null : name;
    this.h1i_1 = name;
    this.i1i_1 = defaultGen;
    var tmp = this;
    tmp.j1i_1 = Extra$PropertyThis$transform$lambda;
  }
  function getExtra(_this__u8e3s4, name) {
    var tmp0_safe_receiver = _this__u8e3s4.e1i();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_safe_receiver;
      tmp$ret$1 = tmp$ret$0.get(name);
      tmp = tmp$ret$1;
    }
    return tmp;
  }
  function setExtra(_this__u8e3s4, name, value) {
    if (_this__u8e3s4.e1i() == null) {
      if (value == null)
        return Unit_getInstance();
      _this__u8e3s4.d1i(ExtraTypeCreate());
    }
    var tmp0_safe_receiver = _this__u8e3s4.e1i();
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.set' call
      var tmp$ret$1;
      // Inline function 'kotlin.run' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_safe_receiver;
      tmp$ret$1 = tmp$ret$0.set(name, value);
      tmp$ret$2 = tmp$ret$1;
    }
  }
  function hasExtra(_this__u8e3s4, name) {
    var tmp0_safe_receiver = _this__u8e3s4.e1i();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.contains' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_safe_receiver;
      tmp$ret$1 = tmp$ret$0.has(name);
      tmp = tmp$ret$1;
    }
    return tmp === true;
  }
  function getExtraTyped(_this__u8e3s4, name) {
    var tmp$ret$4;
    // Inline function 'korlibs.datastructure.fastCastTo' call
    var tmp0_safe_receiver = _this__u8e3s4.e1i();
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.get' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_safe_receiver;
      tmp$ret$1 = tmp$ret$0.get(name);
      tmp = tmp$ret$1;
    }
    var tmp0_fastCastTo = tmp;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = tmp0_fastCastTo;
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    return tmp$ret$4;
  }
  function ExtraTypeCreate() {
    return FastStringMap();
  }
  function linkedHashMapOf(pairs) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.linkedHashMapOf.<anonymous>' call
    var indexedObject = pairs;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var tmp1_loop_parameter = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var key = tmp1_loop_parameter.c5();
      var value = tmp1_loop_parameter.d5();
      // Inline function 'kotlin.collections.set' call
      tmp0_also.e5(key, value);
    }
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function _BSearchResult___init__impl__em6zam(raw) {
    return raw;
  }
  function _BSearchResult___get_raw__impl__uyuhf3($this) {
    return $this;
  }
  function _BSearchResult___get_found__impl__om04iz($this) {
    return _BSearchResult___get_raw__impl__uyuhf3($this) >= 0;
  }
  function _BSearchResult___get_index__impl__b5kraz($this) {
    return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : -1;
  }
  function _BSearchResult___get_nearIndex__impl__s8nq43($this) {
    return _BSearchResult___get_found__impl__om04iz($this) ? _BSearchResult___get_raw__impl__uyuhf3($this) : (-_BSearchResult___get_raw__impl__uyuhf3($this) | 0) - 1 | 0;
  }
  function binarySearch(_this__u8e3s4, v, fromIndex, toIndex) {
    fromIndex = fromIndex === VOID ? 0 : fromIndex;
    toIndex = toIndex === VOID ? _this__u8e3s4.l() : toIndex;
    var tmp$ret$3;
    // Inline function 'korlibs.datastructure.genericBinarySearchResult' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.genericBinarySearch' call
      var low = fromIndex;
      var high = toIndex - 1 | 0;
      while (low <= high) {
        var mid = (low + high | 0) / 2 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.binarySearch.<anonymous>' call
        tmp$ret$0 = compareTo(_this__u8e3s4.z18(mid), v);
        var mval = tmp$ret$0;
        if (mval < 0)
          low = mid + 1 | 0;
        else if (mval > 0)
          high = mid - 1 | 0;
        else {
          tmp$ret$1 = mid;
          break $l$block;
        }
      }
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.genericBinarySearchResult.<anonymous>' call
      var tmp0__anonymous__q1qw7t = low;
      var tmp1__anonymous__uwfjfc = high;
      tmp$ret$2 = (-tmp0__anonymous__q1qw7t | 0) - 1 | 0;
      tmp$ret$1 = tmp$ret$2;
    }
    tmp$ret$3 = _BSearchResult___init__impl__em6zam(tmp$ret$1);
    return tmp$ret$3;
  }
  function getCyclic(_this__u8e3s4, index) {
    return _this__u8e3s4.n(umod(index, _this__u8e3s4.l()));
  }
  function getCyclicOrNull(_this__u8e3s4, index) {
    return _this__u8e3s4.p() ? null : getOrNull(_this__u8e3s4, umod(index, _this__u8e3s4.l()));
  }
  function toLinkedMap_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.toLinkedMap.<anonymous>' call
    var tmp0_iterator = _this__u8e3s4.h();
    while (tmp0_iterator.j()) {
      var tmp1_loop_parameter = tmp0_iterator.k();
      var key = tmp1_loop_parameter.c5();
      var value = tmp1_loop_parameter.d5();
      // Inline function 'kotlin.collections.set' call
      tmp0_also.e5(key, value);
    }
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  }
  function flip(_this__u8e3s4) {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(_this__u8e3s4.l());
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = _this__u8e3s4.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.datastructure.flip.<anonymous>' call
      tmp$ret$1 = new Pair(item.f1(), item.e1());
      tmp0_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp0_mapTo;
    tmp$ret$3 = tmp$ret$2;
    return toMap(tmp$ret$3);
  }
  function getCyclic_0(_this__u8e3s4, index) {
    return _this__u8e3s4[umod(index, _this__u8e3s4.length)];
  }
  function genericSort(subject, left, right, ops) {
    return genericSort_0(subject, left, right, ops, false);
  }
  function SortOps() {
  }
  protoOf(SortOps).i1b = function (subject, indexL, indexR) {
    var inductionVariable = indexR;
    var last = indexL + 1 | 0;
    if (last <= inductionVariable)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + -1 | 0;
        this.h1b(subject, n - 1 | 0, n);
      }
       while (!(n === last));
  };
  protoOf(SortOps).d1b = function (subject, indexL, indexR) {
    var count = (indexR - indexL | 0) + 1 | 0;
    var inductionVariable = 0;
    var last = count / 2 | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        this.h1b(subject, indexL + n | 0, indexR - n | 0);
      }
       while (inductionVariable < last);
  };
  function genericSort_0(subject, left, right, ops, reversed) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.genericSort.<anonymous>' call
    timSort(subject, left, right, ops, reversed);
    tmp$ret$0 = subject;
    return tmp$ret$0;
  }
  function timSort(arr, l, r, ops, reversed, RUN) {
    RUN = RUN === VOID ? 32 : RUN;
    var n = (r - l | 0) + 1 | 0;
    var progression = step(until(0, n), RUN);
    var inductionVariable = progression.p1_1;
    var last = progression.q1_1;
    var step_0 = progression.r1_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        var tmp = l + i | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.math.min' call
        var tmp0_min = (i + RUN | 0) - 1 | 0;
        var tmp1_min = n - 1 | 0;
        tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
        insertionSort(arr, tmp, l + tmp$ret$0 | 0, ops, reversed);
      }
       while (!(i === last));
    var size = RUN;
    while (size < n) {
      var progression_0 = step(until(0, n), imul(2, size));
      var inductionVariable_0 = progression_0.p1_1;
      var last_0 = progression_0.q1_1;
      var step_1 = progression_0.r1_1;
      if ((step_1 > 0 ? inductionVariable_0 <= last_0 : false) ? true : step_1 < 0 ? last_0 <= inductionVariable_0 : false)
        do {
          var left = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + step_1 | 0;
          var tmp$ret$1;
          // Inline function 'kotlin.math.min' call
          var tmp2_min = size;
          var tmp3_min = (n - left | 0) - 1 | 0;
          tmp$ret$1 = Math.min(tmp2_min, tmp3_min);
          var rize = tmp$ret$1;
          var mid = (left + rize | 0) - 1 | 0;
          var tmp$ret$2;
          // Inline function 'kotlin.math.min' call
          var tmp4_min = (left + imul(2, rize) | 0) - 1 | 0;
          var tmp5_min = n - 1 | 0;
          tmp$ret$2 = Math.min(tmp4_min, tmp5_min);
          var right = tmp$ret$2;
          merge(arr, l + left | 0, l + mid | 0, l + right | 0, ops, reversed);
        }
         while (!(left === last_0));
      size = imul(size, 2);
    }
  }
  function insertionSort(arr, left, right, ops, reversed) {
    var inductionVariable = left + 1 | 0;
    if (inductionVariable <= right)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var m = n - 1 | 0;
        $l$loop: while (m >= left) {
          if (negateIf(ops.f1b(arr, m, n), reversed) <= 0)
            break $l$loop;
          var tmp1 = m;
          m = tmp1 - 1 | 0;
        }
        var tmp2 = m;
        m = tmp2 + 1 | 0;
        if (!(m === n)) {
          ops.i1b(arr, m, n);
        }
      }
       while (!(n === right));
  }
  function merge(arr, start, mid, end, ops, reversed) {
    var s = start;
    var m = mid;
    var s2 = m + 1 | 0;
    if (negateIf(ops.f1b(arr, m, s2), reversed) <= 0)
      return Unit_getInstance();
    while (s <= m ? s2 <= end : false) {
      if (negateIf(ops.f1b(arr, s, s2), reversed) <= 0) {
        var tmp0 = s;
        s = tmp0 + 1 | 0;
      } else {
        ops.i1b(arr, s, s2);
        var tmp1 = s;
        s = tmp1 + 1 | 0;
        var tmp2 = m;
        m = tmp2 + 1 | 0;
        var tmp3 = s2;
        s2 = tmp3 + 1 | 0;
      }
    }
  }
  function negateIf(_this__u8e3s4, doNegate) {
    return doNegate ? -_this__u8e3s4 | 0 : _this__u8e3s4;
  }
  function umod(_this__u8e3s4, other) {
    var remainder = _this__u8e3s4 % other | 0;
    return remainder < 0 ? remainder + other | 0 : remainder;
  }
  function ilog2Ceil(v) {
    var tmp$ret$1;
    // Inline function 'kotlin.math.ceil' call
    var tmp$ret$0;
    // Inline function 'kotlin.math.log2' call
    var tmp0_log2 = v;
    tmp$ret$0 = log2(tmp0_log2);
    var tmp1_ceil = tmp$ret$0;
    tmp$ret$1 = Math.ceil(tmp1_ceil);
    return numberToInt(tmp$ret$1);
  }
  function fill(_this__u8e3s4, value) {
    var inductionVariable = 0;
    var last = _this__u8e3s4.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        _this__u8e3s4[n] = value;
      }
       while (inductionVariable < last);
  }
  function arraycopy(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
    return tmp$ret$4;
  }
  function contentHashCode_2(_this__u8e3s4, src, dst) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var tmp0_hashCoder = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_hashCoder)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        tmp$ret$0 = numberToInt(_this__u8e3s4[src + n | 0]);
        out = tmp + tmp$ret$0 | 0;
      }
       while (inductionVariable < tmp0_hashCoder);
    tmp$ret$1 = out;
    return tmp$ret$1;
  }
  function contentEquals_2(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var tmp0_equaler = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < tmp0_equaler)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          tmp$ret$0 = _this__u8e3s4[src + n | 0] === that[src + n | 0];
          if (!tmp$ret$0) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < tmp0_equaler);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function arraycopy_0(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
    return tmp$ret$4;
  }
  function contentHashCode_3(_this__u8e3s4, src, dst) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var tmp0_hashCoder = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_hashCoder)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        tmp$ret$0 = toRawBits(_this__u8e3s4[src + n | 0]);
        out = tmp + tmp$ret$0 | 0;
      }
       while (inductionVariable < tmp0_hashCoder);
    tmp$ret$1 = out;
    return tmp$ret$1;
  }
  function contentEquals_3(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var tmp0_equaler = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < tmp0_equaler)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          tmp$ret$0 = _this__u8e3s4[src + n | 0] === that[src + n | 0];
          if (!tmp$ret$0) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < tmp0_equaler);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function arraycopy_1(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
    return tmp$ret$4;
  }
  function contentHashCode_4(_this__u8e3s4, src, dst) {
    var tmp$ret$1;
    // Inline function 'korlibs.datastructure.internal.hashCoder' call
    var tmp0_hashCoder = dst - src | 0;
    var out = 0;
    var inductionVariable = 0;
    if (inductionVariable < tmp0_hashCoder)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out = imul(out, 7);
        var tmp = out;
        var tmp$ret$0;
        // Inline function 'korlibs.datastructure.internal.contentHashCode.<anonymous>' call
        tmp$ret$0 = _this__u8e3s4[src + n | 0];
        out = tmp + tmp$ret$0 | 0;
      }
       while (inductionVariable < tmp0_hashCoder);
    tmp$ret$1 = out;
    return tmp$ret$1;
  }
  function contentEquals_4(_this__u8e3s4, that, src, dst) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.datastructure.internal.equaler' call
      var tmp0_equaler = dst - src | 0;
      var inductionVariable = 0;
      if (inductionVariable < tmp0_equaler)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.datastructure.internal.contentEquals.<anonymous>' call
          tmp$ret$0 = _this__u8e3s4[src + n | 0] === that[src + n | 0];
          if (!tmp$ret$0) {
            tmp$ret$1 = false;
            break $l$block;
          }
        }
         while (inductionVariable < tmp0_equaler);
      tmp$ret$1 = true;
    }
    return tmp$ret$1;
  }
  function arraycopy_2(src, srcPos, dst, dstPos, size) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = src;
    tmp$ret$1 = tmp$ret$0;
    var tmp = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = dst;
    tmp$ret$3 = tmp$ret$2;
    arrayCopy(tmp, tmp$ret$3, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$4 = dst;
    return tmp$ret$4;
  }
  function arraycopy_3(src, srcPos, dst, dstPos, size) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.copyInto' call
    var tmp0_copyInto = srcPos + size | 0;
    arrayCopy(src, dst, dstPos, srcPos, tmp0_copyInto);
    tmp$ret$0 = dst;
    return tmp$ret$0;
  }
  function FastArrayList_init_$Init$($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.emptyArray' call
    tmp$ret$0 = [];
    FastArrayList.call($this, tmp$ret$0);
    return $this;
  }
  function FastArrayList_init_$Create$() {
    return FastArrayList_init_$Init$(objectCreate(protoOf(FastArrayList)));
  }
  function FastArrayList_init_$Init$_0(initialCapacity, $this) {
    initialCapacity = initialCapacity === VOID ? 0 : initialCapacity;
    var tmp$ret$0;
    // Inline function 'kotlin.emptyArray' call
    tmp$ret$0 = [];
    FastArrayList.call($this, tmp$ret$0);
    return $this;
  }
  function FastArrayList_init_$Create$_0(initialCapacity) {
    return FastArrayList_init_$Init$_0(initialCapacity, objectCreate(protoOf(FastArrayList)));
  }
  function _addAll($this, elements) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.isEmpty' call
    tmp$ret$0 = elements.length === 0;
    if (tmp$ret$0)
      return false;
    var tmp$ret$3;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$2;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = $this.c1d_1;
    var tmp$ret$1;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$1 = tmp0_unsafeCast;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    (function () {
      var $externalVarargReceiverTmp = tmp$ret$3;
      return $externalVarargReceiverTmp.push.apply($externalVarargReceiverTmp, [].slice.call(elements.slice()));
    }.call(this));
    var tmp0_this = $this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
    return true;
  }
  function rangeCheck($this, index) {
    if (index < 0 ? true : index >= $this.l())
      throw IndexOutOfBoundsException_init_$Create$_0('index: ' + index + ', size: ' + $this.l());
    return index;
  }
  function insertionRangeCheck($this, index) {
    if (index < 0 ? true : index > $this.l())
      throw IndexOutOfBoundsException_init_$Create$_0('index: ' + index + ', size: ' + $this.l());
    return index;
  }
  function FastArrayList(__array) {
    AbstractMutableList.call(this);
    this.c1d_1 = __array;
  }
  protoOf(FastArrayList).l = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2.length;
  };
  protoOf(FastArrayList).n = function (index) {
    var tmp$ret$4;
    // Inline function 'korlibs.datastructure.get' call
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp1_get = tmp$ret$2;
    var tmp2_get = rangeCheck(this, index);
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp1_get;
    tmp$ret$4 = tmp$ret$3[tmp2_get];
    return tmp$ret$4;
  };
  protoOf(FastArrayList).a4 = function (index, element) {
    rangeCheck(this, index);
    var tmp$ret$9;
    // Inline function 'kotlin.apply' call
    var tmp$ret$4;
    // Inline function 'korlibs.datastructure.get' call
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp1_get = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp1_get;
    tmp$ret$4 = tmp$ret$3[index];
    var tmp2_apply = tmp$ret$4;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.FastArrayList.set.<anonymous>' call
    // Inline function 'korlibs.datastructure.set' call
    var tmp$ret$7;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$6;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast_0 = this.c1d_1;
    var tmp$ret$5;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$5 = tmp0_unsafeCast_0;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    var tmp1_set = tmp$ret$7;
    var tmp$ret$8;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$8 = tmp1_set;
    tmp$ret$8[index] = element;
    tmp$ret$9 = tmp2_apply;
    return tmp$ret$9;
  };
  protoOf(FastArrayList).b = function (element) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$2.push(element);
    var tmp0_this = this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
    return true;
  };
  protoOf(FastArrayList).z3 = function (index, element) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$2.splice(insertionRangeCheck(this, index), 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
  };
  protoOf(FastArrayList).m = function (elements) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.toTypedArray' call
    tmp$ret$0 = copyToArray(elements);
    return _addAll(this, tmp$ret$0);
  };
  protoOf(FastArrayList).c4 = function (index) {
    rangeCheck(this, index);
    var tmp0_this = this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
    var tmp2_subject = index;
    var tmp;
    if (tmp2_subject === 0) {
      var tmp$ret$2;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = this.c1d_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      tmp = tmp$ret$2.shift();
    } else if (tmp2_subject === get_lastIndex(this)) {
      var tmp$ret$5;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$4;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp1_unsafeCast = this.c1d_1;
      var tmp$ret$3;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$3 = tmp1_unsafeCast;
      tmp$ret$4 = tmp$ret$3;
      tmp$ret$5 = tmp$ret$4;
      tmp = tmp$ret$5.pop();
    } else {
      var tmp$ret$10;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$8;
      // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
      var tmp$ret$7;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp2_unsafeCast = this.c1d_1;
      var tmp$ret$6;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$6 = tmp2_unsafeCast;
      tmp$ret$7 = tmp$ret$6;
      tmp$ret$8 = tmp$ret$7;
      var tmp3_unsafeCast = tmp$ret$8.splice(index, 1);
      var tmp$ret$9;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$9 = tmp3_unsafeCast;
      tmp$ret$10 = tmp$ret$9;
      tmp = tmp$ret$10[0];
    }
    return tmp;
  };
  protoOf(FastArrayList).b4 = function (element) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var array = tmp$ret$2;
    var inductionVariable = 0;
    var last = array.length;
    if (inductionVariable < last)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$4;
        // Inline function 'korlibs.datastructure.get' call
        var tmp$ret$3;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$3 = array;
        tmp$ret$4 = tmp$ret$3[index];
        if (equals(tmp$ret$4, element)) {
          array.splice(index, 1);
          var tmp1_this = this;
          var tmp2 = tmp1_this.h4_1;
          tmp1_this.h4_1 = tmp2 + 1 | 0;
          return true;
        }
      }
       while (inductionVariable < last);
    return false;
  };
  protoOf(FastArrayList).i4 = function (fromIndex, toIndex) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$2.splice(fromIndex, toIndex - fromIndex | 0);
    var tmp0_this = this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
  };
  protoOf(FastArrayList).e4 = function () {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$2.length = 0;
    var tmp0_this = this;
    var tmp1 = tmp0_this.h4_1;
    tmp0_this.h4_1 = tmp1 + 1 | 0;
  };
  protoOf(FastArrayList).x = function (element) {
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2.indexOf(element);
  };
  protoOf(FastArrayList).toString = function () {
    var tmp$ret$4;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp1_unsafeCast = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp1_unsafeCast;
    tmp$ret$4 = tmp$ret$3;
    return '[' + joinToString_0(tmp$ret$4, ', ') + ']';
  };
  protoOf(FastArrayList).g4 = function () {
    var tmp$ret$4;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$2;
    // Inline function 'korlibs.datastructure.FastArrayList.jsArray' call
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.c1d_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp1_unsafeCast = tmp$ret$2.concat();
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp1_unsafeCast;
    tmp$ret$4 = tmp$ret$3;
    return tmp$ret$4;
  };
  protoOf(FastArrayList).toArray = function () {
    return this.g4();
  };
  function FastStringMap() {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = new Map();
    tmp$ret$0 = tmp0_asDynamic;
    return tmp$ret$0;
  }
  function FastIntMap() {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = new Map();
    tmp$ret$0 = tmp0_asDynamic;
    return tmp$ret$0;
  }
  function get_size(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0.size;
  }
  function FastIdentityMap() {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    var tmp0_asDynamic = new Map();
    tmp$ret$0 = tmp0_asDynamic;
    return tmp$ret$0;
  }
  function clear(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    tmp$ret$0.clear();
  }
  function get_size_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0.size;
  }
  function contains(_this__u8e3s4, key) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0.has(key);
  }
  function get_size_1(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0.size;
  }
  function get(_this__u8e3s4, key) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    return tmp$ret$0.get(key);
  }
  function set(_this__u8e3s4, key, value) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    tmp$ret$0.set(key, value);
  }
  function remove(_this__u8e3s4, key) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    tmp$ret$0.delete(key);
  }
  function keys(_this__u8e3s4) {
    var tmp$ret$2;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var tmp0_unsafeCast = Array_from(tmp$ret$0.keys());
    var tmp$ret$1;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$1 = tmp0_unsafeCast;
    tmp$ret$2 = tmp$ret$1;
    return toList_0(tmp$ret$2);
  }
  function Array_from(value) {
    return Array.from(value);
  }
  var CONCURRENCY_COUNT;
  function NonRecursiveLock() {
  }
  function Lock() {
  }
  //region block: post-declaration
  protoOf(IntArray2).d19 = getAt;
  protoOf(IntArray2).c19 = asString;
  protoOf(FloatArray2).d19 = getAt;
  protoOf(FloatArray2).c19 = asString;
  protoOf(Array2).d19 = getAt;
  protoOf(Array2).c19 = asString;
  protoOf(FastArrayList).h1d = addAll_0;
  protoOf(FastArrayList).f1d = setAddAll;
  protoOf(FastArrayList).d1d = setAddAll$default;
  protoOf(FastArrayList).g1d = setAll;
  protoOf(FastArrayList).e1d = removeToSize;
  //endregion
  //region block: init
  CONCURRENCY_COUNT = 1;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = FastArrayList_init_$Create$_0;
  _.$_$.b = FastArrayList_init_$Create$;
  _.$_$.c = FloatArrayList_init_$Create$;
  _.$_$.d = IntArrayList_init_$Create$;
  _.$_$.e = IntIntMap_init_$Create$;
  _.$_$.f = IntMap_init_$Create$;
  _.$_$.g = Pool_init_$Create$;
  _.$_$.h = TGenDeque_init_$Create$;
  _.$_$.i = _BSearchResult___init__impl__em6zam;
  _.$_$.j = _BSearchResult___get_found__impl__om04iz;
  _.$_$.k = _BSearchResult___get_index__impl__b5kraz;
  _.$_$.l = _BSearchResult___get_nearIndex__impl__s8nq43;
  _.$_$.m = _TGenStack___init__impl__yynjgt;
  _.$_$.n = TGenStack__pop_impl_qisxa0;
  _.$_$.o = TGenStack__push_impl_9yh33t;
  _.$_$.p = Companion_getInstance_1;
  _.$_$.q = Companion_getInstance_0;
  _.$_$.r = Companion_getInstance;
  _.$_$.s = Companion_getInstance_9;
  _.$_$.t = Lock;
  _.$_$.u = NonRecursiveLock;
  _.$_$.v = Array2;
  _.$_$.w = ConcurrentPool;
  _.$_$.x = DoubleArrayList;
  _.$_$.y = Mixin;
  _.$_$.z = PropertyThis;
  _.$_$.a1 = Property;
  _.$_$.b1 = FastArrayList;
  _.$_$.c1 = FastIdentityMap;
  _.$_$.d1 = FastIntMap;
  _.$_$.e1 = FastSmallSet;
  _.$_$.f1 = FastStringMap;
  _.$_$.g1 = FloatArray2;
  _.$_$.h1 = FloatArrayList;
  _.$_$.i1 = GenericListIterator;
  _.$_$.j1 = GenericSubList;
  _.$_$.k1 = HistoryStack;
  _.$_$.l1 = IntArray2;
  _.$_$.m1 = IntArrayList;
  _.$_$.n1 = IntDeque;
  _.$_$.o1 = Pool;
  _.$_$.p1 = Ref_0;
  _.$_$.q1 = Ref;
  _.$_$.r1 = ReturnablePool;
  _.$_$.s1 = RingBuffer;
  _.$_$.t1 = SortOps;
  _.$_$.u1 = TGenQueue;
  _.$_$.v1 = associateByInt;
  _.$_$.w1 = binarySearch;
  _.$_$.x1 = clear;
  _.$_$.y1 = contains;
  _.$_$.z1 = fastArrayListOf;
  _.$_$.a2 = flip;
  _.$_$.b2 = floatArrayListOf;
  _.$_$.c2 = genericSort;
  _.$_$.d2 = getAndRemove;
  _.$_$.e2 = getCyclicOrNull;
  _.$_$.f2 = getCyclic_0;
  _.$_$.g2 = getCyclic;
  _.$_$.h2 = getExtraTyped;
  _.$_$.i2 = getExtra;
  _.$_$.j2 = get;
  _.$_$.k2 = hasExtra;
  _.$_$.l2 = intArrayListOf;
  _.$_$.m2 = get_keys;
  _.$_$.n2 = linkedHashMapOf;
  _.$_$.o2 = reverse_0;
  _.$_$.p2 = rotateRight;
  _.$_$.q2 = setExtra;
  _.$_$.r2 = set;
  _.$_$.s2 = get_size_0;
  _.$_$.t2 = get_size;
  _.$_$.u2 = sort;
  _.$_$.v2 = swap;
  _.$_$.w2 = toCaseInsensitiveMap;
  _.$_$.x2 = toDeque;
  _.$_$.y2 = toFastList_0;
  _.$_$.z2 = toFastList;
  _.$_$.a3 = toIntArrayList;
  _.$_$.b3 = toIntMap;
  _.$_$.c3 = toLinkedMap;
  _.$_$.d3 = toMap_0;
  _.$_$.e3 = toRef;
  //endregion
  return _;
}));
