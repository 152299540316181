(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js', './kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js', './korge-root-klogger.js', './korge-root-klock.js', './korge-root-kds.js', './korge-root-kmem.js', './korge-root-krypto.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'), require('./kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'), require('./korge-root-klogger.js'), require('./korge-root-klock.js'), require('./korge-root-kds.js'), require('./korge-root-kmem.js'), require('./korge-root-krypto.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' was not found. Please, check whether 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['korge-root-klogger'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'korge-root-klogger' was not found. Please, check whether 'korge-root-klogger' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['korge-root-klock'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'korge-root-klock' was not found. Please, check whether 'korge-root-klock' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['korge-root-kds'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'korge-root-kds' was not found. Please, check whether 'korge-root-kds' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'korge-root-korio'.");
    }
    if (typeof this['korge-root-krypto'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-korio'. Its dependency 'korge-root-krypto' was not found. Please, check whether 'korge-root-krypto' is loaded prior to 'korge-root-korio'.");
    }
    root['korge-root-korio'] = factory(typeof this['korge-root-korio'] === 'undefined' ? {} : this['korge-root-korio'], this['kotlin-kotlin-stdlib-js-ir'], this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'], this['korge-root-klogger'], this['korge-root-klock'], this['korge-root-kds'], this['korge-root-kmem'], this['korge-root-krypto']);
  }
}(this, function (_, kotlin_kotlin, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core, kotlin_com_soywiz_korlibs_klogger_klogger, kotlin_com_soywiz_korlibs_klock_klock, kotlin_com_soywiz_korlibs_kds_kds, kotlin_com_soywiz_korlibs_kmem_kmem, kotlin_com_soywiz_korlibs_krypto_krypto) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var CoroutineScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.s;
  var CoroutineStart_UNDISPATCHED_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.a;
  var launch = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.g1;
  var async = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.c1;
  var CompletableDeferred = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.q;
  var startCoroutine = kotlin_kotlin.$_$.mb;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var protoOf = kotlin_kotlin.$_$.nd;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var CoroutineScope_0 = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.t;
  var isInterface = kotlin_kotlin.$_$.xc;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var Level_ERROR_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.b;
  var printStackTrace = kotlin_kotlin.$_$.fj;
  var CancellationException = kotlin_kotlin.$_$.ta;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var completeWith = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.f1;
  var Continuation = kotlin_kotlin.$_$.eb;
  var Result__exceptionOrNull_impl_p6xea9 = kotlin_kotlin.$_$.r2;
  var _Result___get_isFailure__impl__jpiriv = kotlin_kotlin.$_$.s2;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var Companion_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.f;
  var Companion_getInstance_0 = kotlin_com_soywiz_korlibs_klock_klock.$_$.w;
  var lazy = kotlin_kotlin.$_$.cj;
  var _TimeSpan___get_millisecondsLong__impl__v1fxun = kotlin_com_soywiz_korlibs_klock_klock.$_$.l;
  var delay = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.e;
  var withTimeout = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.g;
  var _TimeSpan___get_isNil__impl__cma714 = kotlin_com_soywiz_korlibs_klock_klock.$_$.i;
  var FastArrayList_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.b;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var Companion_getInstance_1 = kotlin_kotlin.$_$.u4;
  var createFailure = kotlin_kotlin.$_$.si;
  var _Result___init__impl__xyqfz8 = kotlin_kotlin.$_$.q2;
  var isObject = kotlin_kotlin.$_$.ad;
  var AbstractCoroutineContextElement = kotlin_kotlin.$_$.ya;
  var Key_getInstance = kotlin_kotlin.$_$.e4;
  var get = kotlin_kotlin.$_$.ab;
  var fold = kotlin_kotlin.$_$.fb;
  var minusKey = kotlin_kotlin.$_$.bb;
  var plus = kotlin_kotlin.$_$.jb;
  var releaseInterceptedContinuation = kotlin_kotlin.$_$.cb;
  var invokeOnTimeout = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.v;
  var ContinuationInterceptor = kotlin_kotlin.$_$.db;
  var Delay = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.w;
  var throwOnFailure = kotlin_kotlin.$_$.hj;
  var _Result___get_value__impl__bjfvqg = kotlin_kotlin.$_$.t2;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var ByteArrayBuilder_init_$Create$ = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.d;
  var objectMeta = kotlin_kotlin.$_$.md;
  var FixedSizeByteArrayBuilder_init_$Create$ = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.e;
  var toByte = kotlin_kotlin.$_$.pd;
  var IllegalStateException_init_$Create$ = kotlin_kotlin.$_$.p1;
  var fill = kotlin_kotlin.$_$.h7;
  var toString = kotlin_kotlin.$_$.sd;
  var extract = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.u3;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var get_hex = kotlin_com_soywiz_korlibs_krypto_krypto.$_$.e;
  var Long = kotlin_kotlin.$_$.vh;
  var get_nextPowerOfTwo = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.x4;
  var ilog2 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.g4;
  var RingBuffer = kotlin_com_soywiz_korlibs_kds_kds.$_$.s1;
  var readU16LE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.m5;
  var readS32LE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.g5;
  var readS32BE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.f5;
  var numberToLong = kotlin_kotlin.$_$.kd;
  var toShort = kotlin_kotlin.$_$.rd;
  var until = kotlin_kotlin.$_$.me;
  var step = kotlin_kotlin.$_$.ke;
  var isComparable = kotlin_kotlin.$_$.tc;
  var isNumber = kotlin_kotlin.$_$.zc;
  var numberToDouble = kotlin_kotlin.$_$.id;
  var compareTo = kotlin_kotlin.$_$.ac;
  var getKClassFromExpression = kotlin_kotlin.$_$.d;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.k;
  var toList = kotlin_kotlin.$_$.da;
  var emptyList = kotlin_kotlin.$_$.e7;
  var toList_0 = kotlin_kotlin.$_$.ca;
  var Map = kotlin_kotlin.$_$.m5;
  var toList_1 = kotlin_kotlin.$_$.sg;
  var isCharSequence = kotlin_kotlin.$_$.sc;
  var Iterable = kotlin_kotlin.$_$.h5;
  var toString_0 = kotlin_kotlin.$_$.kj;
  var MutableList = kotlin_kotlin.$_$.o5;
  var MutableMap = kotlin_kotlin.$_$.p5;
  var getOrNull = kotlin_kotlin.$_$.r7;
  var List = kotlin_kotlin.$_$.k5;
  var NullPointerException_init_$Create$ = kotlin_kotlin.$_$.v1;
  var listOf = kotlin_kotlin.$_$.p8;
  var toIntOrNull = kotlin_kotlin.$_$.pg;
  var toDoubleOrNull = kotlin_kotlin.$_$.mg;
  var DoubleCompanionObject_getInstance = kotlin_kotlin.$_$.i4;
  var joinToString = kotlin_kotlin.$_$.b8;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var equals = kotlin_kotlin.$_$.cc;
  var hashCode = kotlin_kotlin.$_$.lc;
  var Comparable = kotlin_kotlin.$_$.nh;
  var toIntOrNull_0 = kotlin_kotlin.$_$.og;
  var toLongOrNull = kotlin_kotlin.$_$.tg;
  var indexOf = kotlin_kotlin.$_$.nf;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var replace = kotlin_kotlin.$_$.ag;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.l;
  var split = kotlin_kotlin.$_$.dg;
  var startsWith = kotlin_kotlin.$_$.eg;
  var charSequenceLength = kotlin_kotlin.$_$.xb;
  var charArrayOf = kotlin_kotlin.$_$.ub;
  var trimEnd = kotlin_kotlin.$_$.ch;
  var trim = kotlin_kotlin.$_$.gh;
  var substringBefore = kotlin_kotlin.$_$.jg;
  var contains = kotlin_kotlin.$_$.if;
  var substringAfterLast = kotlin_kotlin.$_$.gg;
  var Enum = kotlin_kotlin.$_$.rh;
  var FlowCollector = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.k;
  var copyToArray = kotlin_kotlin.$_$.a7;
  var flow = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.n;
  var IntCompanionObject_getInstance = kotlin_kotlin.$_$.k4;
  var toLong = kotlin_kotlin.$_$.qd;
  var toList_2 = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.b;
  var Companion_getInstance_2 = kotlin_com_soywiz_korlibs_klock_klock.$_$.t;
  var DateTime = kotlin_com_soywiz_korlibs_klock_klock.$_$.x;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var DateTime__hashCode_impl_yf0bc9 = kotlin_com_soywiz_korlibs_klock_klock.$_$.a;
  var get_0 = kotlin_kotlin.$_$.gb;
  var minusKey_0 = kotlin_kotlin.$_$.hb;
  var Element = kotlin_kotlin.$_$.ib;
  var arrayConcat = kotlin_kotlin.$_$.a;
  var Mixin = kotlin_com_soywiz_korlibs_kds_kds.$_$.y;
  var toList_3 = kotlin_kotlin.$_$.ea;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var withContext = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.f;
  var asFlow = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.l;
  var emptyMap = kotlin_kotlin.$_$.f7;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var endsWith = kotlin_kotlin.$_$.lf;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.t;
  var FastStringMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.f1;
  var UnsupportedOperationException_init_$Create$ = kotlin_kotlin.$_$.b2;
  var removeAll = kotlin_kotlin.$_$.c9;
  var sortWith = kotlin_kotlin.$_$.o9;
  var Comparator = kotlin_kotlin.$_$.oh;
  var compareValues = kotlin_kotlin.$_$.ra;
  var to = kotlin_kotlin.$_$.lj;
  var Companion_getInstance_3 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k1;
  var until_0 = kotlin_kotlin.$_$.le;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var linkedHashMapOf = kotlin_com_soywiz_korlibs_kds_kds.$_$.n2;
  var toLongOrNull_0 = kotlin_kotlin.$_$.ug;
  var Char = kotlin_kotlin.$_$.mh;
  var Pool_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.g;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var numberToChar = kotlin_kotlin.$_$.hd;
  var readS16 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.e5;
  var charSequenceGet = kotlin_kotlin.$_$.wb;
  var Char__toInt_impl_vasixd = kotlin_kotlin.$_$.o2;
  var write16 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.n6;
  var IntIntMap_init_$Create$ = kotlin_com_soywiz_korlibs_kds_kds.$_$.e;
  var StringBuilder_init_$Create$_0 = kotlin_kotlin.$_$.d1;
  var insert = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.p4;
  var charArray = kotlin_kotlin.$_$.vb;
  var concatToString = kotlin_kotlin.$_$.ff;
  var NonRecursiveLock = kotlin_com_soywiz_korlibs_kds_kds.$_$.u;
  var CancellationException_init_$Create$ = kotlin_kotlin.$_$.a1;
  var CancellationException_init_$Create$_0 = kotlin_kotlin.$_$.y;
  var Exception = kotlin_kotlin.$_$.th;
  var Exception_init_$Init$ = kotlin_kotlin.$_$.i1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var clamp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j3;
  var concatToString_0 = kotlin_kotlin.$_$.gf;
  var get_lastIndex = kotlin_kotlin.$_$.sf;
  var lastIndexOf = kotlin_kotlin.$_$.tf;
  var substring = kotlin_kotlin.$_$.kg;
  var Regex_init_$Create$ = kotlin_kotlin.$_$.c1;
  var copyOf = kotlin_kotlin.$_$.z6;
  var copyOfRange = kotlin_kotlin.$_$.p6;
  var contentHashCode = kotlin_kotlin.$_$.k6;
  var contentEquals = kotlin_kotlin.$_$.j6;
  var listOf_0 = kotlin_kotlin.$_$.q8;
  var split_0 = kotlin_kotlin.$_$.cg;
  var get_lastIndex_0 = kotlin_kotlin.$_$.f8;
  var dropLast = kotlin_kotlin.$_$.jf;
  var substringAfter = kotlin_kotlin.$_$.hg;
  var substringBeforeLast = kotlin_kotlin.$_$.ig;
  var trimStart = kotlin_kotlin.$_$.fh;
  var Char__toByte_impl_7s7yt0 = kotlin_kotlin.$_$.n2;
  var toInt = kotlin_kotlin.$_$.rg;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var toMap = kotlin_kotlin.$_$.ga;
  var trim_0 = kotlin_kotlin.$_$.hh;
  var filterNotNull = kotlin_kotlin.$_$.k7;
  var equals_0 = kotlin_kotlin.$_$.mf;
  var first = kotlin_kotlin.$_$.n7;
  var sortedWith = kotlin_kotlin.$_$.q9;
  var toSet = kotlin_kotlin.$_$.ka;
  var plus_0 = kotlin_kotlin.$_$.a9;
  var Collection = kotlin_kotlin.$_$.d5;
  var linkedMapOf = kotlin_kotlin.$_$.n8;
  var DoubleArrayList = kotlin_com_soywiz_korlibs_kds_kds.$_$.x;
  var Char__compareTo_impl_ypi4mb = kotlin_kotlin.$_$.i2;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var sequence = kotlin_kotlin.$_$.cf;
  var SequenceScope = kotlin_kotlin.$_$.te;
  var firstOrNull = kotlin_kotlin.$_$.l7;
  var println = kotlin_kotlin.$_$.pb;
  var NoSuchElementException = kotlin_kotlin.$_$.wh;
  var flip = kotlin_com_soywiz_korlibs_kds_kds.$_$.a2;
  var toInt_0 = kotlin_kotlin.$_$.qg;
  var startsWith_0 = kotlin_kotlin.$_$.fg;
  var toCaseInsensitiveMap = kotlin_com_soywiz_korlibs_kds_kds.$_$.w2;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var addAll = kotlin_kotlin.$_$.u5;
  var UnsupportedOperationException_init_$Create$_0 = kotlin_kotlin.$_$.a2;
  var UnsupportedOperationException = kotlin_kotlin.$_$.oi;
  var arraycopy = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.p2;
  var ClosedRange = kotlin_kotlin.$_$.ae;
  var contains_0 = kotlin_kotlin.$_$.he;
  var clamp_0 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l3;
  var Pair = kotlin_kotlin.$_$.yh;
  var nextAlignedTo = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.v4;
  var readU16BE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.l5;
  var copyOf_0 = kotlin_kotlin.$_$.v6;
  var toIntClamp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.e6;
  var Endian_LITTLE_ENDIAN_getInstance = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.b;
  var coerceIn = kotlin_kotlin.$_$.fe;
  var copyOfRange_0 = kotlin_kotlin.$_$.q6;
  var readS8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j5;
  var readU8 = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.q5;
  var readS16BE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.d5;
  var readU24BE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.o5;
  var readS64BE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.i5;
  var readByteArray = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.y4;
  var readShortArrayBE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.k5;
  var readCharArrayBE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.z4;
  var readIntArrayLE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.b5;
  var readIntArrayBE = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.a5;
  var ByteArrayBuilder = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.h2;
  var indexOf_0 = kotlin_kotlin.$_$.w7;
  var signExtend = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.w5;
  var RuntimeException_init_$Create$ = kotlin_kotlin.$_$.x1;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var isNanOrInfinite = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.s4;
  var indexOf_1 = kotlin_kotlin.$_$.of;
  var contains_1 = kotlin_kotlin.$_$.hf;
  var abs = kotlin_kotlin.$_$.ud;
  var repeat = kotlin_kotlin.$_$.yf;
  var replace_0 = kotlin_kotlin.$_$.zf;
  var round = kotlin_kotlin.$_$.wd;
  var Char__minus_impl_a2frrh = kotlin_kotlin.$_$.k2;
  var Companion_getInstance_4 = kotlin_kotlin.$_$.t4;
  var sorted = kotlin_kotlin.$_$.r9;
  var reversed = kotlin_kotlin.$_$.h9;
  var distinct = kotlin_kotlin.$_$.b7;
  var joinToString_0 = kotlin_kotlin.$_$.c8;
  var StringCompanionObject_getInstance = kotlin_kotlin.$_$.m4;
  var Hex_getInstance = kotlin_com_soywiz_korlibs_krypto_krypto.$_$.a;
  var endsWith_0 = kotlin_kotlin.$_$.kf;
  var isArray = kotlin_kotlin.$_$.oc;
  var EmptyCoroutineContext_getInstance = kotlin_kotlin.$_$.f4;
  var newThrowable = kotlin_kotlin.$_$.fd;
  var charSequenceSubSequence = kotlin_kotlin.$_$.yb;
  var toMap_0 = kotlin_kotlin.$_$.fa;
  var Level_INFO_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.c;
  var setOf = kotlin_kotlin.$_$.l9;
  var Job = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.x;
  var flowOf = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.m;
  var intercepted = kotlin_kotlin.$_$.wa;
  var get_MODE_CANCELLABLE = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.y;
  var CancellableContinuationImpl = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.p;
  var toTypedArray = kotlin_kotlin.$_$.ma;
  var returnIfSuspended = kotlin_kotlin.$_$.i;
  var isByteArray = kotlin_kotlin.$_$.qc;
  //endregion
  //region block: pre-declaration
  setMetadataFor(_launch$slambda, '_launch$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(_async$slambda, '_async$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(launchUnscopedAndWait$1, VOID, classMeta, VOID, [Continuation]);
  setMetadataFor(launchUnscoped$1, VOID, classMeta, VOID, [Continuation]);
  setMetadataFor(AsyncThread$sync$slambda, 'AsyncThread$sync$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(AsyncThread, 'AsyncThread', classMeta, VOID, VOID, VOID, VOID, [0, 1]);
  setMetadataFor($withTimeoutCOROUTINE$11, '$withTimeoutCOROUTINE$11', classMeta, CoroutineImpl);
  setMetadataFor(BaseSignal, 'BaseSignal', classMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(Signal, 'Signal', classMeta, BaseSignal, VOID, VOID, VOID, [0]);
  setMetadataFor(Closeable, 'Closeable', interfaceMeta);
  setMetadataFor(Node, 'Node', classMeta, VOID, [Closeable]);
  setMetadataFor($invokeCOROUTINE$12, '$invokeCOROUTINE$12', classMeta, CoroutineImpl);
  setMetadataFor(AsyncSignal, 'AsyncSignal', classMeta, BaseSignal, VOID, VOID, VOID, [1, 0]);
  setMetadataFor(runBlockingNoSuspensions$1$context$1, VOID, classMeta, AbstractCoroutineContextElement, [AbstractCoroutineContextElement, ContinuationInterceptor, Delay], VOID, VOID, [1]);
  setMetadataFor(runBlockingNoSuspensions$1, VOID, classMeta, VOID, [Continuation]);
  function uncompress(reader, out, $completion) {
    unsupported_0();
  }
  setMetadataFor(CompressionMethod, 'CompressionMethod', interfaceMeta, VOID, VOID, VOID, VOID, [2, 3]);
  setMetadataFor(uncompress$slambda, 'uncompress$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor($uncompressCOROUTINE$13, '$uncompressCOROUTINE$13', classMeta, CoroutineImpl);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(SlidingWindowWithOutput, 'SlidingWindowWithOutput', classMeta, VOID, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(DeflatePortable, 'DeflatePortable', classMeta, VOID, [CompressionMethod], VOID, VOID, [3, 2]);
  setMetadataFor(Companion_0, 'Companion', objectMeta, DeflatePortable, VOID, VOID, VOID, [3, 2]);
  setMetadataFor($uncompressCOROUTINE$16, '$uncompressCOROUTINE$16', classMeta, CoroutineImpl);
  setMetadataFor($writeCOROUTINE$25, '$writeCOROUTINE$25', classMeta, CoroutineImpl);
  setMetadataFor(ZLib, 'ZLib', classMeta, VOID, [CompressionMethod], VOID, VOID, [2, 3]);
  setMetadataFor(Companion_1, 'Companion', objectMeta, ZLib, VOID, VOID, VOID, [2, 3]);
  setMetadataFor(ZLib$uncompress$2, VOID, classMeta, VOID, VOID, VOID, VOID, [0, 3, 1]);
  setMetadataFor($uncompressCOROUTINE$23, '$uncompressCOROUTINE$23', classMeta, CoroutineImpl);
  setMetadataFor($forInputCOROUTINE$27, '$forInputCOROUTINE$27', classMeta, CoroutineImpl);
  setMetadataFor(Companion_2, 'Companion', objectMeta, VOID, VOID, VOID, VOID, [1]);
  setMetadataFor($prepareBytesUpToCOROUTINE$29, '$prepareBytesUpToCOROUTINE$29', classMeta, CoroutineImpl);
  setMetadataFor($abytesCOROUTINE$30, '$abytesCOROUTINE$30', classMeta, CoroutineImpl);
  setMetadataFor($readCOROUTINE$31, '$readCOROUTINE$31', classMeta, CoroutineImpl);
  setMetadataFor($hasLengthCOROUTINE$34, '$hasLengthCOROUTINE$34', classMeta, CoroutineImpl);
  setMetadataFor($getLengthCOROUTINE$35, '$getLengthCOROUTINE$35', classMeta, CoroutineImpl);
  function getPosition($completion) {
    throw UnsupportedOperationException_init_$Create$_0();
  }
  setMetadataFor(AsyncGetPositionStream, 'AsyncGetPositionStream', interfaceMeta, VOID, VOID, VOID, VOID, [0]);
  function hasLength($completion) {
    var tmp = new $hasLengthCOROUTINE$167(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function getLength($completion) {
    throw UnsupportedOperationException_init_$Create$_0();
  }
  setMetadataFor(AsyncGetLengthStream, 'AsyncGetLengthStream', interfaceMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(BitReader, 'BitReader', classMeta, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], VOID, VOID, [0, 1, 2, 3]);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(HuffmanTree, 'HuffmanTree', classMeta);
  setMetadataFor(SlidingWindow, 'SlidingWindow', classMeta);
  setMetadataFor(KorAtomicRef, 'KorAtomicRef', classMeta);
  setMetadataFor(KorAtomicLong, 'KorAtomicLong', classMeta);
  setMetadataFor(KorAtomicInt, 'KorAtomicInt', classMeta);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(Dyn, 'Dyn', classMeta, VOID, [Comparable], VOID, VOID, [2, 1]);
  function get_global() {
    return null;
  }
  setMetadataFor(DynApi, 'DynApi', interfaceMeta, VOID, VOID, VOID, VOID, [2, 3]);
  setMetadataFor(VfsNamed, 'VfsNamed', classMeta);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(Standard, 'Standard', classMeta, Enum);
  setMetadataFor(Vfs$Proxy$listFlow$slambda$slambda, 'Vfs$Proxy$listFlow$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(sam$kotlinx_coroutines_flow_FlowCollector$0, 'sam$kotlinx_coroutines_flow_FlowCollector$0', classMeta, VOID, [FlowCollector], VOID, VOID, [1]);
  setMetadataFor(Vfs$Proxy$initOnce$slambda, 'Vfs$Proxy$initOnce$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(Vfs$Proxy$listFlow$slambda, 'Vfs$Proxy$listFlow$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($getUnderlyingUnscapedFileCOROUTINE$50, '$getUnderlyingUnscapedFileCOROUTINE$50', classMeta, CoroutineImpl);
  setMetadataFor($initOnceCOROUTINE$51, '$initOnceCOROUTINE$51', classMeta, CoroutineImpl);
  setMetadataFor($openCOROUTINE$53, '$openCOROUTINE$53', classMeta, CoroutineImpl);
  setMetadataFor($readRangeCOROUTINE$54, '$readRangeCOROUTINE$54', classMeta, CoroutineImpl);
  setMetadataFor($putCOROUTINE$55, '$putCOROUTINE$55', classMeta, CoroutineImpl);
  setMetadataFor($statCOROUTINE$57, '$statCOROUTINE$57', classMeta, CoroutineImpl);
  setMetadataFor($deleteCOROUTINE$58, '$deleteCOROUTINE$58', classMeta, CoroutineImpl);
  setMetadataFor($mkdirCOROUTINE$62, '$mkdirCOROUTINE$62', classMeta, CoroutineImpl);
  setMetadataFor(Vfs, 'Vfs', classMeta, VOID, VOID, VOID, VOID, [0, 3, 2, 4, 1]);
  setMetadataFor(Proxy, 'Proxy', classMeta, Vfs, VOID, VOID, VOID, [1, 0, 4, 2, 3]);
  setMetadataFor($readRangeCOROUTINE$41, '$readRangeCOROUTINE$41', classMeta, CoroutineImpl);
  setMetadataFor($putCOROUTINE$42, '$putCOROUTINE$42', classMeta, CoroutineImpl);
  setMetadataFor($listSimpleCOROUTINE$47, '$listSimpleCOROUTINE$47', classMeta, CoroutineImpl);
  setMetadataFor(VfsOpenMode, 'VfsOpenMode', classMeta, Enum);
  setMetadataFor(VfsStat, 'VfsStat', classMeta);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(VfsCachedStatContext, 'VfsCachedStatContext', classMeta, VOID, [Element]);
  setMetadataFor($readStringCOROUTINE$72, '$readStringCOROUTINE$72', classMeta, CoroutineImpl);
  setMetadataFor($writeStringCOROUTINE$73, '$writeStringCOROUTINE$73', classMeta, CoroutineImpl);
  setMetadataFor($readAsSyncStreamCOROUTINE$74, '$readAsSyncStreamCOROUTINE$74', classMeta, CoroutineImpl);
  setMetadataFor($statCOROUTINE$75, '$statCOROUTINE$75', classMeta, CoroutineImpl);
  setMetadataFor($existsCOROUTINE$77, '$existsCOROUTINE$77', classMeta, CoroutineImpl);
  setMetadataFor(VfsFile, 'VfsFile', classMeta, VfsNamed, VOID, VOID, VOID, [2, 3, 0, 1, 4, 5]);
  setMetadataFor(FinalVfsFile, 'FinalVfsFile', classMeta);
  setMetadataFor($getLengthCOROUTINE$95, '$getLengthCOROUTINE$95', classMeta, CoroutineImpl);
  setMetadataFor(CatalogVfs$open$slambda, 'CatalogVfs$open$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(AsyncStreamBase, 'AsyncStreamBase', classMeta, VOID, [AsyncGetLengthStream], VOID, VOID, [4, 1, 0]);
  setMetadataFor(CatalogVfs$open$2, VOID, classMeta, AsyncStreamBase, VOID, VOID, VOID, [4, 1, 0]);
  setMetadataFor($openCOROUTINE$88, '$openCOROUTINE$88', classMeta, CoroutineImpl);
  setMetadataFor($statCOROUTINE$89, '$statCOROUTINE$89', classMeta, CoroutineImpl);
  setMetadataFor($statOrNullCOROUTINE$90, '$statOrNullCOROUTINE$90', classMeta, CoroutineImpl);
  setMetadataFor($listFlowCOROUTINE$91, '$listFlowCOROUTINE$91', classMeta, CoroutineImpl);
  setMetadataFor($cachedListSimpleStatsCOROUTINE$92, '$cachedListSimpleStatsCOROUTINE$92', classMeta, CoroutineImpl);
  setMetadataFor($cachedListSimpleStatsOrNullCOROUTINE$93, '$cachedListSimpleStatsOrNullCOROUTINE$93', classMeta, CoroutineImpl);
  setMetadataFor($listSimpleStatsOrNullCOROUTINE$94, '$listSimpleStatsOrNullCOROUTINE$94', classMeta, CoroutineImpl);
  setMetadataFor(CatalogVfs, 'CatalogVfs', classMeta, Proxy, VOID, VOID, VOID, [2, 1, 0, 4, 3]);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(JailVfs, 'JailVfs', classMeta, Proxy, VOID, VOID, VOID, [1, 0, 4, 3, 2]);
  setMetadataFor(StandardVfs, 'StandardVfs', classMeta);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(LocalVfs, 'LocalVfs', classMeta, Vfs, VOID, VOID, VOID, [1, 0, 3, 4, 2]);
  setMetadataFor(Mountable, 'Mountable', interfaceMeta);
  setMetadataFor(sam$kotlin_Comparator$0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor($closeCOROUTINE$114, '$closeCOROUTINE$114', classMeta, CoroutineImpl);
  setMetadataFor(MountableVfs_0, 'MountableVfs', classMeta, Proxy, [Proxy, Mountable], VOID, VOID, [0, 1, 4, 3, 2]);
  setMetadataFor($MountableVfsCOROUTINE$113, '$MountableVfsCOROUTINE$113', classMeta, CoroutineImpl);
  function appPreferencesFolder(appId) {
    return Companion_getInstance_3().w1j() ? '/Users/' + Companion_getInstance_17().p1b('USER') + '/Library/Preferences/' + appId : Companion_getInstance_3().u1j() ? '' + Companion_getInstance_17().p1b('APPDATA') + '/' + appId : '' + Companion_getInstance_17().p1b('HOME') + '/.config/' + appId;
  }
  setMetadataFor(StandardPathsBase, 'StandardPathsBase', interfaceMeta);
  setMetadataFor($readCOROUTINE$122, '$readCOROUTINE$122', classMeta, CoroutineImpl);
  setMetadataFor($getLengthCOROUTINE$123, '$getLengthCOROUTINE$123', classMeta, CoroutineImpl);
  setMetadataFor(HttpHeaders, 'HttpHeaders', classMeta);
  setMetadataFor(UrlVfs$open$2, VOID, classMeta, AsyncStreamBase, VOID, VOID, VOID, [4, 0, 1]);
  setMetadataFor($openCOROUTINE$116, '$openCOROUTINE$116', classMeta, CoroutineImpl);
  setMetadataFor($_readRangeBaseCOROUTINE$118, '$_readRangeBaseCOROUTINE$118', classMeta, CoroutineImpl);
  setMetadataFor($readRangeCOROUTINE$119, '$readRangeCOROUTINE$119', classMeta, CoroutineImpl);
  setMetadataFor($putCOROUTINE$120, '$putCOROUTINE$120', classMeta, CoroutineImpl);
  setMetadataFor($statCOROUTINE$121, '$statCOROUTINE$121', classMeta, CoroutineImpl);
  setMetadataFor(UrlVfs_0, 'UrlVfs', classMeta, Vfs, VOID, VOID, VOID, [2, 1, 3, 0, 4]);
  setMetadataFor(Charset, 'Charset', classMeta);
  setMetadataFor(BaseSingleByteCharset, 'BaseSingleByteCharset', classMeta, Charset);
  setMetadataFor(SingleByteCharset, 'SingleByteCharset', classMeta, BaseSingleByteCharset);
  setMetadataFor(ISO_8859_1, 'ISO_8859_1', objectMeta, SingleByteCharset);
  setMetadataFor(UTF16Charset, 'UTF16Charset', classMeta, Charset);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(UTC8CharsetBase, 'UTC8CharsetBase', classMeta, Charset);
  setMetadataFor(ASCII, 'ASCII', objectMeta, SingleByteCharset);
  setMetadataFor(Charsets, 'Charsets', objectMeta);
  setMetadataFor(Closeable$Companion$invoke$1, VOID, classMeta, VOID, [Closeable]);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  function cancel(e) {
    this.n1q();
  }
  setMetadataFor(CloseableCancellable, 'CloseableCancellable', interfaceMeta, VOID, [Closeable]);
  setMetadataFor(CancellableGroup, 'CancellableGroup', classMeta, VOID, [CloseableCancellable]);
  setMetadataFor(sam$korlibs_io_lang_Cancellable$0, 'sam$korlibs_io_lang_Cancellable$0', classMeta);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(InvalidOperationException, 'InvalidOperationException', classMeta, Exception);
  setMetadataFor(IOException, 'IOException', classMeta, Exception);
  setMetadataFor(FileAlreadyExistsException, 'FileAlreadyExistsException', classMeta, IOException);
  setMetadataFor(ReservedException, 'ReservedException', classMeta, Exception);
  setMetadataFor(UnreachableException, 'UnreachableException', classMeta, Exception);
  setMetadataFor(UnexpectedException, 'UnexpectedException', classMeta, Exception);
  setMetadataFor(NativeThreadLocal, 'NativeThreadLocal', classMeta);
  setMetadataFor(threadLocal$local$1, VOID, classMeta, NativeThreadLocal);
  setMetadataFor(threadLocal, 'threadLocal', classMeta);
  setMetadataFor(WStringReader, 'WStringReader', classMeta);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(WString, 'WString', classMeta);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(MimeType, 'MimeType', classMeta);
  setMetadataFor(QueryString, 'QueryString', objectMeta);
  setMetadataFor(Companion_14, 'Companion', objectMeta);
  setMetadataFor(URL, 'URL', classMeta);
  setMetadataFor(Companion_15, 'Companion', objectMeta);
  setMetadataFor(Companion_16, 'Companion', objectMeta);
  setMetadataFor(Companion_17, 'Companion', objectMeta);
  setMetadataFor(sam$kotlin_Comparator$0_0, 'sam$kotlin_Comparator$0', classMeta, VOID, [Comparator]);
  setMetadataFor(Methods, 'Methods', classMeta, Enum);
  setMetadataFor(HttpException, 'HttpException', classMeta, IOException);
  setMetadataFor(Headers, 'Headers', classMeta, VOID, [Iterable]);
  setMetadataFor(Companion_18, 'Companion', objectMeta, VOID, VOID, VOID, VOID, [4]);
  setMetadataFor($readAllBytesCOROUTINE$127, '$readAllBytesCOROUTINE$127', classMeta, CoroutineImpl);
  setMetadataFor($readAllStringCOROUTINE$128, '$readAllStringCOROUTINE$128', classMeta, CoroutineImpl);
  setMetadataFor($checkErrorsCOROUTINE$129, '$checkErrorsCOROUTINE$129', classMeta, CoroutineImpl);
  setMetadataFor(Companion_19, 'Companion', objectMeta);
  setMetadataFor(Response, 'Response', classMeta, VOID, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(CompletedResponse, 'CompletedResponse', classMeta);
  setMetadataFor(RequestConfig, 'RequestConfig', classMeta);
  setMetadataFor(Companion_20, 'Companion', objectMeta);
  setMetadataFor($requestCOROUTINE$132, '$requestCOROUTINE$132', classMeta, CoroutineImpl);
  setMetadataFor($requestAsBytesCOROUTINE$134, '$requestAsBytesCOROUTINE$134', classMeta, CoroutineImpl);
  setMetadataFor($readBytesCOROUTINE$135, '$readBytesCOROUTINE$135', classMeta, CoroutineImpl);
  setMetadataFor(HttpClient, 'HttpClient', classMeta, VOID, VOID, VOID, VOID, [4, 5, 2]);
  setMetadataFor(HttpStatusMessage, 'HttpStatusMessage', objectMeta);
  setMetadataFor(ProxiedHttpFactory, 'ProxiedHttpFactory', classMeta);
  setMetadataFor(Fixed, 'Fixed', classMeta, VOID, VOID, VOID, VOID, [0]);
  setMetadataFor(Resources, 'Resources', classMeta);
  setMetadataFor(Companion_21, 'Companion', objectMeta);
  setMetadataFor(Context, 'Context', classMeta);
  setMetadataFor(Json, 'Json', objectMeta);
  setMetadataFor(Xml$Companion$parse$Level, 'Level', classMeta);
  setMetadataFor(Element_0, 'Element', classMeta);
  setMetadataFor(ProcessingInstructionTag, 'ProcessingInstructionTag', classMeta, Element_0);
  setMetadataFor(OpenCloseTag, 'OpenCloseTag', classMeta, Element_0);
  setMetadataFor(OpenTag, 'OpenTag', classMeta, Element_0);
  setMetadataFor(CommentTag, 'CommentTag', classMeta, Element_0);
  setMetadataFor(CloseTag, 'CloseTag', classMeta, Element_0);
  setMetadataFor(Text, 'Text', classMeta, Element_0);
  setMetadataFor(Xml2Iterable, 'Xml2Iterable', classMeta, VOID, [Iterable]);
  setMetadataFor(Xml$Stream$xmlSequence$slambda, 'Xml$Stream$xmlSequence$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(Companion_22, 'Companion', objectMeta);
  setMetadataFor(Type, 'Type', classMeta, Enum);
  setMetadataFor(Entities, 'Entities', objectMeta);
  setMetadataFor(Stream, 'Stream', objectMeta);
  setMetadataFor(Xml, 'Xml', classMeta);
  setMetadataFor(AsyncStream$read$slambda, 'AsyncStream$read$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(AsyncStream$write$slambda, 'AsyncStream$write$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor($readCOROUTINE$156, '$readCOROUTINE$156', classMeta, CoroutineImpl);
  setMetadataFor($writeCOROUTINE$157, '$writeCOROUTINE$157', classMeta, CoroutineImpl);
  setMetadataFor($readInternalCOROUTINE$158, '$readInternalCOROUTINE$158', classMeta, CoroutineImpl);
  setMetadataFor($writeInternalCOROUTINE$159, '$writeInternalCOROUTINE$159', classMeta, CoroutineImpl);
  setMetadataFor($getAvailableCOROUTINE$160, '$getAvailableCOROUTINE$160', classMeta, CoroutineImpl);
  setMetadataFor(AsyncPositionLengthStream, 'AsyncPositionLengthStream', interfaceMeta, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], VOID, VOID, [1, 0]);
  setMetadataFor(AsyncStream, 'AsyncStream', classMeta, VOID, [AsyncGetPositionStream, AsyncGetLengthStream, AsyncPositionLengthStream], VOID, VOID, [3, 1, 0]);
  setMetadataFor($hasLengthCOROUTINE$167, '$hasLengthCOROUTINE$167', classMeta, CoroutineImpl);
  setMetadataFor($_readCOROUTINE$171, '$_readCOROUTINE$171', classMeta, CoroutineImpl);
  setMetadataFor(BufferedStreamBase, 'BufferedStreamBase', classMeta, AsyncStreamBase, VOID, VOID, VOID, [4, 1, 0]);
  setMetadataFor(MemoryAsyncStreamBase, 'MemoryAsyncStreamBase', classMeta, AsyncStreamBase, VOID, VOID, VOID, [1, 0, 4]);
  setMetadataFor($closeCOROUTINE$177, '$closeCOROUTINE$177', classMeta, CoroutineImpl);
  setMetadataFor(SliceAsyncStreamBase, 'SliceAsyncStreamBase', classMeta, AsyncStreamBase, VOID, VOID, VOID, [4, 0, 1]);
  setMetadataFor($getPositionCOROUTINE$203, '$getPositionCOROUTINE$203', classMeta, CoroutineImpl);
  setMetadataFor($getLengthCOROUTINE$204, '$getLengthCOROUTINE$204', classMeta, CoroutineImpl);
  setMetadataFor(toAsyncInputStream$1, VOID, classMeta, VOID, [AsyncGetPositionStream, AsyncGetLengthStream], VOID, VOID, [3, 0]);
  setMetadataFor(toAsyncOutputStream$1, VOID, classMeta, VOID, VOID, VOID, VOID, [3, 0, 1]);
  setMetadataFor($readAllCOROUTINE$163, '$readAllCOROUTINE$163', classMeta, CoroutineImpl);
  setMetadataFor($readBytesExactCOROUTINE$166, '$readBytesExactCOROUTINE$166', classMeta, CoroutineImpl);
  setMetadataFor($copyToCOROUTINE$168, '$copyToCOROUTINE$168', classMeta, CoroutineImpl);
  setMetadataFor($readU16BECOROUTINE$169, '$readU16BECOROUTINE$169', classMeta, CoroutineImpl);
  setMetadataFor($readS32BECOROUTINE$170, '$readS32BECOROUTINE$170', classMeta, CoroutineImpl);
  setMetadataFor($readExactCOROUTINE$172, '$readExactCOROUTINE$172', classMeta, CoroutineImpl);
  setMetadataFor($sliceWithBoundsCOROUTINE$173, '$sliceWithBoundsCOROUTINE$173', classMeta, CoroutineImpl);
  setMetadataFor($readBytesCOROUTINE$176, '$readBytesCOROUTINE$176', classMeta, CoroutineImpl);
  setMetadataFor($readBytesUpToCOROUTINE$178, '$readBytesUpToCOROUTINE$178', classMeta, CoroutineImpl);
  setMetadataFor($readBytesUpToCopyCOROUTINE$183, '$readBytesUpToCopyCOROUTINE$183', classMeta, CoroutineImpl);
  setMetadataFor($getAvailableCOROUTINE$184, '$getAvailableCOROUTINE$184', classMeta, CoroutineImpl);
  setMetadataFor($readBytesUpToCOROUTINE$185, '$readBytesUpToCOROUTINE$185', classMeta, CoroutineImpl);
  setMetadataFor($sliceStartCOROUTINE$187, '$sliceStartCOROUTINE$187', classMeta, CoroutineImpl);
  setMetadataFor($readStringCOROUTINE$189, '$readStringCOROUTINE$189', classMeta, CoroutineImpl);
  setMetadataFor($sliceHereCOROUTINE$190, '$sliceHereCOROUTINE$190', classMeta, CoroutineImpl);
  setMetadataFor($readU16COROUTINE$191, '$readU16COROUTINE$191', classMeta, CoroutineImpl);
  setMetadataFor($readS32COROUTINE$192, '$readS32COROUTINE$192', classMeta, CoroutineImpl);
  setMetadataFor($readStringzCOROUTINE$193, '$readStringzCOROUTINE$193', classMeta, CoroutineImpl);
  setMetadataFor($readS32LECOROUTINE$195, '$readS32LECOROUTINE$195', classMeta, CoroutineImpl);
  setMetadataFor($readU16LECOROUTINE$198, '$readU16LECOROUTINE$198', classMeta, CoroutineImpl);
  setMetadataFor(FastByteArrayInputStream, 'FastByteArrayInputStream', classMeta);
  function read() {
    var tmp$ret$2;
    $l$block: {
      // Inline function 'korlibs.datastructure.Pool.alloc' call
      var tmp0_alloc = get_smallBytesPool();
      var temp = tmp0_alloc.a1g();
      try {
        var tmp$ret$1;
        // Inline function 'korlibs.io.stream.SyncInputStream.read.<anonymous>' call
        var tmp;
        if (this.v1h(temp, 0, 1) > 0) {
          var tmp$ret$0;
          // Inline function 'korlibs.memory.unsigned' call
          var tmp0__get_unsigned__3e7on0 = temp[0];
          tmp$ret$0 = tmp0__get_unsigned__3e7on0 & 255;
          tmp = tmp$ret$0;
        } else {
          tmp = -1;
        }
        tmp$ret$1 = tmp;
        tmp$ret$2 = tmp$ret$1;
        break $l$block;
      }finally {
        tmp0_alloc.b1g(temp);
      }
    }
    return tmp$ret$2;
  }
  setMetadataFor(SyncInputStream, 'SyncInputStream', interfaceMeta, VOID, [Closeable]);
  setMetadataFor(SyncPositionStream, 'SyncPositionStream', interfaceMeta);
  setMetadataFor(SyncLengthStream, 'SyncLengthStream', interfaceMeta);
  setMetadataFor(SyncStream, 'SyncStream', classMeta, VOID, [Closeable, SyncInputStream, SyncPositionStream, SyncLengthStream]);
  setMetadataFor(SyncStreamBase, 'SyncStreamBase', classMeta, VOID, [Closeable, SyncLengthStream]);
  setMetadataFor(MemorySyncStreamBase, 'MemorySyncStreamBase', classMeta, SyncStreamBase);
  setMetadataFor(ReadonlySyncStreamBase, 'ReadonlySyncStreamBase', classMeta, SyncStreamBase);
  setMetadataFor(SliceSyncStreamBase, 'SliceSyncStreamBase', classMeta, SyncStreamBase);
  setMetadataFor(Text_0, 'Text', interfaceMeta);
  setMetadataFor(Marker, 'Marker', classMeta);
  setMetadataFor(Line, 'Line', classMeta, VOID, [Text_0]);
  setMetadataFor(LineDeferred, 'LineDeferred', classMeta);
  setMetadataFor(EmptyLineOnce, 'EmptyLineOnce', objectMeta);
  setMetadataFor(Indent, 'Indent', objectMeta);
  setMetadataFor(Unindent, 'Unindent', objectMeta);
  setMetadataFor(INDENTS, 'INDENTS', objectMeta);
  setMetadataFor(Companion_23, 'Companion', objectMeta);
  setMetadataFor(IndenterEvaluator, 'IndenterEvaluator', classMeta);
  setMetadataFor(Indenter, 'Indenter', classMeta);
  setMetadataFor(NumberParser, 'NumberParser', objectMeta);
  setMetadataFor(Once, 'Once', classMeta);
  setMetadataFor(Companion_24, 'Companion', objectMeta);
  setMetadataFor(Companion_25, 'Companion', objectMeta);
  setMetadataFor(Literals, 'Literals', classMeta);
  setMetadataFor(BaseStrReader, 'BaseStrReader', classMeta);
  setMetadataFor(StrReader, 'StrReader', classMeta, BaseStrReader);
  setMetadataFor(Adler32, 'Adler32', objectMeta);
  setMetadataFor(DynamicInternal, 'DynamicInternal', objectMeta, VOID, [DynApi], VOID, VOID, [2, 3]);
  setMetadataFor(BlobAsyncBaseStream, 'BlobAsyncBaseStream', classMeta, AsyncStreamBase, VOID, VOID, VOID, [0, 4, 1]);
  setMetadataFor(toVfs$1, VOID, classMeta, Vfs, VOID, VOID, VOID, [2, 1, 0, 3, 4]);
  setMetadataFor(standardVfs$1, VOID, classMeta, StandardVfs);
  setMetadataFor(StandardPaths, 'StandardPaths', objectMeta, VOID, [StandardPathsBase]);
  setMetadataFor(EnvironmentInternal, 'EnvironmentInternal', objectMeta);
  setMetadataFor(FileNotFoundException, 'FileNotFoundException', classMeta, IOException);
  setMetadataFor(EOFException, 'EOFException', classMeta, IOException);
  setMetadataFor(JS_UTF8$1, VOID, classMeta, UTC8CharsetBase);
  setMetadataFor(httpFactory$2$1, VOID, classMeta);
  setMetadataFor(Companion_26, 'Companion', objectMeta);
  setMetadataFor(JsRuntime, 'JsRuntime', classMeta, VOID, VOID, VOID, VOID, [1, 4]);
  setMetadataFor(JsRuntimeBrowser, 'JsRuntimeBrowser', objectMeta, JsRuntime, VOID, VOID, VOID, [1, 4]);
  setMetadataFor(Companion_27, 'Companion', objectMeta);
  setMetadataFor($requestInternalCOROUTINE$205, '$requestInternalCOROUTINE$205', classMeta, CoroutineImpl);
  setMetadataFor(HttpClientBrowserJs, 'HttpClientBrowserJs', classMeta, HttpClient, VOID, VOID, VOID, [4, 5, 2]);
  setMetadataFor(JsRuntimeDeno, 'JsRuntimeDeno', objectMeta, JsRuntime, VOID, VOID, VOID, [1, 4]);
  setMetadataFor(JsRuntimeNode, 'JsRuntimeNode', objectMeta, JsRuntime, VOID, VOID, VOID, [1, 4]);
  setMetadataFor($listFlowCOROUTINE$207, '$listFlowCOROUTINE$207', classMeta, CoroutineImpl);
  setMetadataFor($openCOROUTINE$209, '$openCOROUTINE$209', classMeta, CoroutineImpl);
  setMetadataFor(NodeJsLocalVfs, 'NodeJsLocalVfs', classMeta, LocalVfs, VOID, VOID, VOID, [2, 1, 4, 0, 3]);
  setMetadataFor($requestInternalCOROUTINE$214, '$requestInternalCOROUTINE$214', classMeta, CoroutineImpl);
  setMetadataFor(HttpClientNodeJs, 'HttpClientNodeJs', classMeta, HttpClient, VOID, VOID, VOID, [4, 5, 2]);
  setMetadataFor($closeCOROUTINE$217, '$closeCOROUTINE$217', classMeta, CoroutineImpl);
  setMetadataFor(NodeFDStream, 'NodeFDStream', classMeta, AsyncStreamBase, VOID, VOID, VOID, [4, 1, 0]);
  //endregion
  function get_logger() {
    _init_properties_AsyncExt_kt__ahev1h();
    return logger;
  }
  var logger;
  var DEFAULT_SUSPEND_TEST_TIMEOUT;
  function get_DEBUG_ASYNC_LAUNCH_ERRORS() {
    _init_properties_AsyncExt_kt__ahev1h();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = DEBUG_ASYNC_LAUNCH_ERRORS$factory();
    tmp$ret$0 = DEBUG_ASYNC_LAUNCH_ERRORS$delegate.f1();
    return tmp$ret$0;
  }
  var DEBUG_ASYNC_LAUNCH_ERRORS$delegate;
  function launchImmediately(context, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return launchImmediately_0(CoroutineScope(context), callback);
  }
  function async_0(context, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return asyncImmediately(CoroutineScope(context), callback);
  }
  function launchImmediately_0(_this__u8e3s4, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return _launch(_this__u8e3s4, CoroutineStart_UNDISPATCHED_getInstance(), callback);
  }
  function asyncImmediately(_this__u8e3s4, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return _async(_this__u8e3s4, CoroutineStart_UNDISPATCHED_getInstance(), callback);
  }
  function _launch(_this__u8e3s4, start, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    var tmp = _this__u8e3s4.to();
    return launch(_this__u8e3s4, tmp, start, _launch$slambda_0(callback, null));
  }
  function _async(_this__u8e3s4, start, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    var tmp = _this__u8e3s4.to();
    return async(_this__u8e3s4, tmp, start, _async$slambda_0(callback, null));
  }
  function asyncImmediately_0(context, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return asyncImmediately(CoroutineScope(context), callback);
  }
  function launchUnscopedAndWait(_this__u8e3s4, block, $completion) {
    var deferred = CompletableDeferred();
    startCoroutine(block, new launchUnscopedAndWait$1(_this__u8e3s4, deferred));
    var tmp0 = deferred.qr($completion);
    return tmp0;
  }
  function launchUnscoped(_this__u8e3s4, block) {
    _init_properties_AsyncExt_kt__ahev1h();
    return launchUnscoped_0(_this__u8e3s4.to(), block);
  }
  function launchUnscoped_0(_this__u8e3s4, block) {
    _init_properties_AsyncExt_kt__ahev1h();
    startCoroutine(block, new launchUnscoped$1(_this__u8e3s4));
  }
  function launch_0(context, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return launchImmediately_0(CoroutineScope(context), callback);
  }
  function launch_1(_this__u8e3s4, callback) {
    _init_properties_AsyncExt_kt__ahev1h();
    return _launch(_this__u8e3s4, CoroutineStart_UNDISPATCHED_getInstance(), callback);
  }
  function DEBUG_ASYNC_LAUNCH_ERRORS$delegate$lambda() {
    _init_properties_AsyncExt_kt__ahev1h();
    return Companion_getInstance_17().p1b('DEBUG_ASYNC_LAUNCH_ERRORS') === 'true';
  }
  function _launch$slambda($callback, resultContinuation) {
    this.a1o_1 = $callback;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(_launch$slambda).c1o = function ($this$launch, $completion) {
    var tmp = this.d1o($this$launch, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(_launch$slambda).g8 = function (p1, $completion) {
    return this.c1o((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(_launch$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.a1o_1(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof CancellationException) {
              var e = this.b8_1;
              throw e;
            } else {
              var tmp_1 = this.b8_1;
              if (tmp_1 instanceof Error) {
                var e_0 = this.b8_1;
                if (get_DEBUG_ASYNC_LAUNCH_ERRORS()) {
                  var tmp1_error = get_logger();
                  var tmp0_log = Level_ERROR_getInstance();
                  if (tmp1_error.q1n(tmp0_log)) {
                    tmp1_error.r1n(tmp0_log, 'CoroutineScope._launch.catch:');
                  }
                  printStackTrace(e_0);
                }
                throw e_0;
              } else {
                throw this.b8_1;
              }
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            return Unit_getInstance();
        }
      } catch ($p) {
        var e_1 = $p;
        if (this.z7_1 === 3) {
          throw e_1;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_1;
        }
      }
     while (true);
  };
  protoOf(_launch$slambda).d1o = function ($this$launch, completion) {
    var i = new _launch$slambda(this.a1o_1, completion);
    i.b1o_1 = $this$launch;
    return i;
  };
  function _launch$slambda_0($callback, resultContinuation) {
    var i = new _launch$slambda($callback, resultContinuation);
    var l = function ($this$launch, $completion) {
      return i.c1o($this$launch, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function _async$slambda($callback, resultContinuation) {
    this.m1o_1 = $callback;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(_async$slambda).p1o = function ($this$async, $completion) {
    var tmp = this.d1o($this$async, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(_async$slambda).g8 = function (p1, $completion) {
    return this.p1o((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(_async$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.m1o_1(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.o1o_1 = suspendResult;
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              var e = this.b8_1;
              var tmp_1 = this;
              if (e instanceof CancellationException)
                throw e;
              if (get_DEBUG_ASYNC_LAUNCH_ERRORS()) {
                var tmp1_error = get_logger();
                var tmp0_log = Level_ERROR_getInstance();
                if (tmp1_error.q1n(tmp0_log)) {
                  tmp1_error.r1n(tmp0_log, 'CoroutineScope._async.catch:');
                }
                printStackTrace(e);
              }
              throw e;
            } else {
              throw this.b8_1;
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            return this.o1o_1;
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 3) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  protoOf(_async$slambda).d1o = function ($this$async, completion) {
    var i = new _async$slambda(this.m1o_1, completion);
    i.n1o_1 = $this$async;
    return i;
  };
  function _async$slambda_0($callback, resultContinuation) {
    var i = new _async$slambda($callback, resultContinuation);
    var l = function ($this$async, $completion) {
      return i.p1o($this$async, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function launchUnscopedAndWait$1($this_launchUnscopedAndWait, $deferred) {
    this.r1o_1 = $deferred;
    this.q1o_1 = $this_launchUnscopedAndWait;
  }
  protoOf(launchUnscopedAndWait$1).s5 = function () {
    return this.q1o_1;
  };
  protoOf(launchUnscopedAndWait$1).q5 = function (result) {
    completeWith(this.r1o_1, result);
  };
  function launchUnscoped$1($this_launchUnscoped) {
    this.s1o_1 = $this_launchUnscoped;
  }
  protoOf(launchUnscoped$1).s5 = function () {
    return this.s1o_1;
  };
  protoOf(launchUnscoped$1).r5 = function (result) {
    if (_Result___get_isFailure__impl__jpiriv(result)) {
      var tmp0_safe_receiver = Result__exceptionOrNull_impl_p6xea9(result);
      if (tmp0_safe_receiver == null)
        null;
      else {
        printStackTrace(tmp0_safe_receiver);
      }
    }
  };
  protoOf(launchUnscoped$1).q5 = function (result) {
    return this.r5(result);
  };
  function DEBUG_ASYNC_LAUNCH_ERRORS$factory() {
    return getPropertyCallableRef('DEBUG_ASYNC_LAUNCH_ERRORS', 0, KProperty0, function () {
      return get_DEBUG_ASYNC_LAUNCH_ERRORS();
    }, null);
  }
  var properties_initialized_AsyncExt_kt_7k2grr;
  function _init_properties_AsyncExt_kt__ahev1h() {
    if (properties_initialized_AsyncExt_kt_7k2grr) {
    } else {
      properties_initialized_AsyncExt_kt_7k2grr = true;
      logger = Companion_getInstance().b1n('AsyncExt');
      var tmp$ret$0;
      // Inline function 'korlibs.time.seconds' call
      tmp$ret$0 = Companion_getInstance_0().m18(20.0);
      DEFAULT_SUSPEND_TEST_TIMEOUT = tmp$ret$0;
      DEBUG_ASYNC_LAUNCH_ERRORS$delegate = lazy(DEBUG_ASYNC_LAUNCH_ERRORS$delegate$lambda);
    }
  }
  function AsyncThread$sync$slambda($oldPromise, $func, resultContinuation) {
    this.b1p_1 = $oldPromise;
    this.c1p_1 = $func;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(AsyncThread$sync$slambda).f1p = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncThread$sync$slambda).un = function ($completion) {
    return this.f1p($completion);
  };
  protoOf(AsyncThread$sync$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.d1p_1 = this.b1p_1;
            if (this.d1p_1 == null) {
              this.e1p_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.d1p_1.qr(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.e1p_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            ;
            this.y7_1 = 3;
            suspendResult = this.c1p_1(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(AsyncThread$sync$slambda).g1p = function (completion) {
    var i = new AsyncThread$sync$slambda(this.b1p_1, this.c1p_1, completion);
    return i;
  };
  function AsyncThread$sync$slambda_0($oldPromise, $func, resultContinuation) {
    var i = new AsyncThread$sync$slambda($oldPromise, $func, resultContinuation);
    var l = function ($completion) {
      return i.f1p($completion);
    };
    l.$arity = 0;
    return l;
  }
  function AsyncThread() {
    this.h1p_1 = null;
  }
  protoOf(AsyncThread).i1p = function (func, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.getCoroutineContext' call
    tmp$ret$0 = $completion.s5();
    var task = this.j1p(tmp$ret$0, func);
    var tmp0 = task.qr($completion);
    return tmp0;
  };
  protoOf(AsyncThread).j1p = function (context, func) {
    var oldPromise = this.h1p_1;
    var promise = asyncImmediately_0(context, AsyncThread$sync$slambda_0(oldPromise, func, null));
    this.h1p_1 = promise;
    return promise;
  };
  function delay_0(_this__u8e3s4, time, $completion) {
    var tmp0 = delay(_TimeSpan___get_millisecondsLong__impl__v1fxun(time), $completion);
    return tmp0;
  }
  function withTimeout_0(time, block, $completion) {
    var tmp = new $withTimeoutCOROUTINE$11(time, block, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function $withTimeoutCOROUTINE$11(time, block, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s1p_1 = time;
    this.t1p_1 = block;
  }
  protoOf($withTimeoutCOROUTINE$11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (_TimeSpan___get_isNil__impl__cma714(this.s1p_1)) {
              this.y7_1 = 2;
              suspendResult = this.t1p_1(CoroutineScope(this.s5()), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = withTimeout(_TimeSpan___get_millisecondsLong__impl__v1fxun(this.s1p_1), this.t1p_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.u1p_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 2:
            this.u1p_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.u1p_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Signal$_init_$lambda_6vimhp() {
    return Unit_getInstance();
  }
  function Signal(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = Signal$_init_$lambda_6vimhp;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    BaseSignal.call(this, onRegister);
  }
  protoOf(Signal).z1p = function (handler) {
    return this.e1q(true, handler);
  };
  protoOf(Signal).f1q = function (handler) {
    return this.e1q(false, handler);
  };
  protoOf(Signal).g1q = function (handler) {
    return this.f1q(handler);
  };
  protoOf(Signal).h1q = function (value) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks' call
      if (this.b1q_1.p()) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block;
      }
      try {
        var tmp0_this = this;
        var tmp1 = tmp0_this.d1q_1;
        tmp0_this.d1q_1 = tmp1 + 1 | 0;
        var tmp$ret$2;
        // Inline function 'korlibs.datastructure.iterators.fastIterateRemove' call
        var tmp0_fastIterateRemove = this.b1q_1;
        var n = 0;
        var m = 0;
        while (n < tmp0_fastIterateRemove.l()) {
          if (m >= 0 ? !(m === n) : false) {
            tmp0_fastIterateRemove.a4(m, tmp0_fastIterateRemove.n(n));
          }
          var tmp$ret$1;
          // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
          var tmp1__anonymous__uwfjfc = tmp0_fastIterateRemove.n(n);
          var remove = tmp1__anonymous__uwfjfc.i1q_1;
          // Inline function 'korlibs.io.async.Signal.invoke.<anonymous>' call
          var tmp5__anonymous__kpxxpo = tmp1__anonymous__uwfjfc.j1q_1;
          tmp5__anonymous__kpxxpo(value);
          tmp$ret$1 = remove;
          if (tmp$ret$1) {
            var tmp0 = m;
            m = tmp0 - 1 | 0;
          }
          var tmp1_0 = n;
          n = tmp1_0 + 1 | 0;
          var tmp2 = m;
          m = tmp2 + 1 | 0;
        }
        while (tmp0_fastIterateRemove.l() > m) {
          tmp0_fastIterateRemove.c4(tmp0_fastIterateRemove.l() - 1 | 0);
        }
        tmp$ret$2 = tmp0_fastIterateRemove;
      }finally {
        var tmp2_this = this;
        var tmp3 = tmp2_this.d1q_1;
        tmp2_this.d1q_1 = tmp3 - 1 | 0;
        var tmp$ret$3;
        // Inline function 'kotlin.collections.isNotEmpty' call
        var tmp2_isNotEmpty = this.c1q_1;
        tmp$ret$3 = !tmp2_isNotEmpty.p();
        if (tmp$ret$3) {
          var tmp$ret$5;
          // Inline function 'korlibs.datastructure.iterators.fastIterateRemove' call
          var tmp3_fastIterateRemove = this.c1q_1;
          var n_0 = 0;
          var m_0 = 0;
          while (n_0 < tmp3_fastIterateRemove.l()) {
            if (m_0 >= 0 ? !(m_0 === n_0) : false) {
              tmp3_fastIterateRemove.a4(m_0, tmp3_fastIterateRemove.n(n_0));
            }
            var tmp$ret$4;
            // Inline function 'korlibs.io.async.BaseSignal.iterateCallbacks.<anonymous>' call
            var tmp4__anonymous__pkmkx7 = tmp3_fastIterateRemove.n(n_0);
            this.b1q_1.b4(tmp4__anonymous__pkmkx7);
            tmp$ret$4 = true;
            if (tmp$ret$4) {
              var tmp0_0 = m_0;
              m_0 = tmp0_0 - 1 | 0;
            }
            var tmp1_1 = n_0;
            n_0 = tmp1_1 + 1 | 0;
            var tmp2_0 = m_0;
            m_0 = tmp2_0 + 1 | 0;
          }
          while (tmp3_fastIterateRemove.l() > m_0) {
            tmp3_fastIterateRemove.c4(tmp3_fastIterateRemove.l() - 1 | 0);
          }
          tmp$ret$5 = tmp3_fastIterateRemove;
        }
      }
    }
    return tmp$ret$0;
  };
  function Node($outer, once, item) {
    this.k1q_1 = $outer;
    this.i1q_1 = once;
    this.j1q_1 = item;
  }
  protoOf(Node).n1q = function () {
    if (this.k1q_1.d1q_1 > 0) {
      this.k1q_1.c1q_1.b(this);
    } else {
      this.k1q_1.b1q_1.b4(this);
    }
  };
  function BaseSignal$_init_$lambda_tuu04y() {
    return Unit_getInstance();
  }
  function BaseSignal(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = BaseSignal$_init_$lambda_tuu04y;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    this.a1q_1 = onRegister;
    this.b1q_1 = FastArrayList_init_$Create$();
    this.c1q_1 = FastArrayList_init_$Create$();
    this.d1q_1 = 0;
  }
  protoOf(BaseSignal).l1q = function () {
    return this.b1q_1.l();
  };
  protoOf(BaseSignal).m1q = function () {
    return this.l1q() > 0;
  };
  protoOf(BaseSignal).e1q = function (once, handler) {
    this.a1q_1();
    var node = new Node(this, once, handler);
    this.b1q_1.b(node);
    return node;
  };
  function invoke(_this__u8e3s4) {
    return _this__u8e3s4.h1q(Unit_getInstance());
  }
  function AsyncSignal$_init_$lambda_rsv6fb() {
    return Unit_getInstance();
  }
  function $invokeCOROUTINE$12(_this__u8e3s4, value, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.w1q_1 = _this__u8e3s4;
    this.x1q_1 = value;
  }
  protoOf($invokeCOROUTINE$12).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 11;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (this.w1q_1.b1q_1.p()) {
              this.y1q_1 = Unit_getInstance();
              this.y7_1 = 10;
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.z7_1 = 9;
            var tmp0_this = this.w1q_1;
            var tmp1 = tmp0_this.d1q_1;
            tmp0_this.d1q_1 = tmp1 + 1 | 0;
            ;
            var tmp_0 = this;
            tmp_0.a1r_1 = this.w1q_1.b1q_1;
            this.b1r_1 = 0;
            this.c1r_1 = 0;
            this.y7_1 = 4;
            continue $sm;
          case 4:
            if (!(this.b1r_1 < this.a1r_1.l())) {
              this.y7_1 = 7;
              continue $sm;
            }

            if (this.c1r_1 >= 0 ? !(this.c1r_1 === this.b1r_1) : false) {
              this.a1r_1.a4(this.c1r_1, this.a1r_1.n(this.b1r_1));
            }

            var tmp_1 = this;
            tmp_1.d1r_1 = this.a1r_1.n(this.b1r_1);
            this.e1r_1 = this.d1r_1.i1q_1;
            var tmp_2 = this;
            tmp_2.f1r_1 = this.d1r_1.j1q_1;
            this.y7_1 = 5;
            suspendResult = this.f1r_1(this.x1q_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            if (this.e1r_1) {
              var tmp0 = this.c1r_1;
              this.c1r_1 = tmp0 - 1 | 0;
              this.y7_1 = 6;
              continue $sm;
            } else {
              this.y7_1 = 6;
              continue $sm;
            }

            break;
          case 6:
            var tmp1_0 = this.b1r_1;
            this.b1r_1 = tmp1_0 + 1 | 0;
            ;
            var tmp2 = this.c1r_1;
            this.c1r_1 = tmp2 + 1 | 0;
            ;
            this.y7_1 = 4;
            continue $sm;
          case 7:
            while (this.a1r_1.l() > this.c1r_1) {
              this.a1r_1.c4(this.a1r_1.l() - 1 | 0);
            }

            var tmp_3 = this;
            tmp_3.z1q_1 = Unit_getInstance();
            this.z7_1 = 11;
            this.y7_1 = 8;
            continue $sm;
          case 8:
            var tmp2_this = this.w1q_1;
            var tmp3 = tmp2_this.d1q_1;
            tmp2_this.d1q_1 = tmp3 - 1 | 0;
            ;
            var tmp2_isNotEmpty = this.w1q_1.c1q_1;
            if (!tmp2_isNotEmpty.p()) {
              var tmp3_fastIterateRemove = this.w1q_1.c1q_1;
              var n = 0;
              var m = 0;
              while (n < tmp3_fastIterateRemove.l()) {
                if (m >= 0 ? !(m === n) : false) {
                  tmp3_fastIterateRemove.a4(m, tmp3_fastIterateRemove.n(n));
                }
                var tmp4__anonymous__pkmkx7 = tmp3_fastIterateRemove.n(n);
                this.w1q_1.b1q_1.b4(tmp4__anonymous__pkmkx7);
                {
                  var tmp0_0 = m;
                  m = tmp0_0 - 1 | 0;
                }
                var tmp1_1 = n;
                n = tmp1_1 + 1 | 0;
                var tmp2_0 = m;
                m = tmp2_0 + 1 | 0;
              }
              while (tmp3_fastIterateRemove.l() > m) {
                tmp3_fastIterateRemove.c4(tmp3_fastIterateRemove.l() - 1 | 0);
              }
            }

            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 10;
            continue $sm;
          case 9:
            this.z7_1 = 11;
            var t = this.b8_1;
            var tmp2_this_0 = this.w1q_1;
            var tmp3_0 = tmp2_this_0.d1q_1;
            tmp2_this_0.d1q_1 = tmp3_0 - 1 | 0;
            ;
            var tmp2_isNotEmpty_0 = this.w1q_1.c1q_1;
            if (!tmp2_isNotEmpty_0.p()) {
              var tmp3_fastIterateRemove_0 = this.w1q_1.c1q_1;
              var n_0 = 0;
              var m_0 = 0;
              while (n_0 < tmp3_fastIterateRemove_0.l()) {
                if (m_0 >= 0 ? !(m_0 === n_0) : false) {
                  tmp3_fastIterateRemove_0.a4(m_0, tmp3_fastIterateRemove_0.n(n_0));
                }
                var tmp4__anonymous__pkmkx7_0 = tmp3_fastIterateRemove_0.n(n_0);
                this.w1q_1.b1q_1.b4(tmp4__anonymous__pkmkx7_0);
                {
                  var tmp0_1 = m_0;
                  m_0 = tmp0_1 - 1 | 0;
                }
                var tmp1_2 = n_0;
                n_0 = tmp1_2 + 1 | 0;
                var tmp2_1 = m_0;
                m_0 = tmp2_1 + 1 | 0;
              }
              while (tmp3_fastIterateRemove_0.l() > m_0) {
                tmp3_fastIterateRemove_0.c4(tmp3_fastIterateRemove_0.l() - 1 | 0);
              }
            }

            throw t;
          case 10:
            return Unit_getInstance();
          case 11:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 11) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function AsyncSignal(onRegister) {
    var tmp;
    if (onRegister === VOID) {
      tmp = AsyncSignal$_init_$lambda_rsv6fb;
    } else {
      tmp = onRegister;
    }
    onRegister = tmp;
    BaseSignal.call(this, onRegister);
  }
  protoOf(AsyncSignal).k1r = function (handler) {
    return this.e1q(false, handler);
  };
  protoOf(AsyncSignal).l1r = function (handler) {
    return this.k1r(handler);
  };
  protoOf(AsyncSignal).m13 = function (value, $completion) {
    var tmp = new $invokeCOROUTINE$12(this, value, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function runBlockingNoSuspensions(callback) {
    var completed = {_v: false};
    var rresult = {_v: null};
    var resultEx = {_v: null};
    var suspendCount = {_v: 0};
    startCoroutineUndispatched(callback, new runBlockingNoSuspensions$1(suspendCount, resultEx, completed, rresult));
    if (!completed._v) {
      invalidOp('runBlockingNoSuspensions was not completed synchronously! suspendCount=' + suspendCount._v);
    }
    if (!(resultEx._v == null))
      throw ensureNotNull(resultEx._v);
    var tmp;
    if (rresult._v == null) {
      throwUninitializedPropertyAccessException('rresult');
    } else {
      tmp = rresult._v;
    }
    return tmp;
  }
  function startCoroutineUndispatched(_this__u8e3s4, completion) {
    var tmp$ret$7;
    $l$block: {
      // Inline function 'korlibs.io.async.startDirect' call
      var tmp;
      try {
        var tmp$ret$4;
        // Inline function 'korlibs.io.async.startCoroutineUndispatched.<anonymous>' call
        var tmp$ret$3;
        // Inline function 'korlibs.io.async.withCoroutineContext' call
        var tmp0_withCoroutineContext = completion.s5();
        var tmp$ret$2;
        // Inline function 'korlibs.io.async.startCoroutineUndispatched.<anonymous>.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.intrinsics.startCoroutineUninterceptedOrReturn' call
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = _this__u8e3s4;
        var a = tmp$ret$0;
        tmp$ret$1 = typeof a === 'function' ? a(completion) : _this__u8e3s4.un(completion);
        tmp$ret$2 = tmp$ret$1;
        tmp$ret$3 = tmp$ret$2;
        tmp$ret$4 = tmp$ret$3;
        tmp = tmp$ret$4;
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var e = $p;
          var tmp$ret$6;
          // Inline function 'kotlin.coroutines.resumeWithException' call
          var tmp$ret$5;
          // Inline function 'kotlin.Companion.failure' call
          var tmp0_failure = Companion_getInstance_1();
          tmp$ret$5 = _Result___init__impl__xyqfz8(createFailure(e));
          completion.q5(tmp$ret$5);
          tmp$ret$6 = Unit_getInstance();
          tmp$ret$7 = Unit_getInstance();
          break $l$block;
        } else {
          throw $p;
        }
        tmp = tmp_0;
      }
      var value = tmp;
      if (!(value === get_COROUTINE_SUSPENDED())) {
        var tmp$ret$9;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp2_resume = (value == null ? true : isObject(value)) ? value : THROW_CCE();
        var tmp$ret$8;
        // Inline function 'kotlin.Companion.success' call
        var tmp1_success = Companion_getInstance_1();
        tmp$ret$8 = _Result___init__impl__xyqfz8(tmp2_resume);
        completion.q5(tmp$ret$8);
        tmp$ret$9 = Unit_getInstance();
      }
    }
  }
  function _get_unitInstance__yoa8mq($this) {
    return Unit_getInstance();
  }
  function runBlockingNoSuspensions$1$context$1($suspendCount) {
    this.o1r_1 = $suspendCount;
    AbstractCoroutineContextElement.call(this, Key_getInstance());
    this.n1r_1 = Key_getInstance();
  }
  protoOf(runBlockingNoSuspensions$1$context$1).e1 = function () {
    return this.n1r_1;
  };
  protoOf(runBlockingNoSuspensions$1$context$1).m8 = function (continuation) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.async.<no name provided>.interceptContinuation.<anonymous>' call
    var tmp0 = this.o1r_1._v;
    this.o1r_1._v = tmp0 + 1 | 0;
    tmp$ret$0 = continuation;
    return tmp$ret$0;
  };
  protoOf(runBlockingNoSuspensions$1$context$1).kv = function (timeMillis, continuation) {
    var tmp$ret$1;
    // Inline function 'kotlin.coroutines.resume' call
    var tmp$ret$0;
    // Inline function 'kotlin.Companion.success' call
    var tmp0_success = Companion_getInstance_1();
    tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_getInstance());
    continuation.q5(tmp$ret$0);
    tmp$ret$1 = Unit_getInstance();
    return tmp$ret$1;
  };
  function runBlockingNoSuspensions$1($suspendCount, $resultEx, $completed, $rresult) {
    this.q1r_1 = $resultEx;
    this.r1r_1 = $completed;
    this.s1r_1 = $rresult;
    var tmp = this;
    tmp.p1r_1 = new runBlockingNoSuspensions$1$context$1($suspendCount);
  }
  protoOf(runBlockingNoSuspensions$1).s5 = function () {
    return this.p1r_1;
  };
  protoOf(runBlockingNoSuspensions$1).t1r = function (result) {
    var exception = Result__exceptionOrNull_impl_p6xea9(result);
    if (!(exception == null)) {
      this.q1r_1._v = exception;
      this.r1r_1._v = true;
      printStackTrace(exception);
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.getOrThrow' call
      throwOnFailure(result);
      var tmp = _Result___get_value__impl__bjfvqg(result);
      tmp$ret$0 = (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
      var tmp0_elvis_lhs = tmp$ret$0;
      var tmp_0;
      if (tmp0_elvis_lhs == null) {
        _get_unitInstance__yoa8mq(this);
        var tmp_1 = Unit_getInstance();
        var tmp_2;
        if (isObject(Unit_getInstance())) {
          tmp_2 = Unit_getInstance();
        } else {
          tmp_2 = THROW_CCE();
        }
        tmp_0 = tmp_2;
      } else {
        tmp_0 = tmp0_elvis_lhs;
      }
      var rvalue = tmp_0;
      this.s1r_1._v = rvalue;
      this.r1r_1._v = true;
    }
  };
  protoOf(runBlockingNoSuspensions$1).q5 = function (result) {
    return this.t1r(result);
  };
  function CompressionMethod() {
  }
  function uncompress_0(_this__u8e3s4, i, o, $completion) {
    var tmp = new $uncompressCOROUTINE$13(_this__u8e3s4, i, o, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function uncompress_1(_this__u8e3s4, method, outputSizeHint) {
    outputSizeHint = outputSizeHint === VOID ? imul(_this__u8e3s4.length, 2) : outputSizeHint;
    var tmp$ret$0;
    // Inline function 'korlibs.io.stream.MemorySyncStreamToByteArray' call
    var buffer = ByteArrayBuilder_init_$Create$(outputSizeHint);
    var s = MemorySyncStream(buffer);
    // Inline function 'korlibs.io.compression.uncompress.<anonymous>' call
    uncompress_2(method, openSync(_this__u8e3s4), s);
    tmp$ret$0 = buffer.c1j();
    return tmp$ret$0;
  }
  function uncompress_2(_this__u8e3s4, i, o) {
    return runBlockingNoSuspensions(uncompress$slambda_0(_this__u8e3s4, i, o, null));
  }
  function uncompress$slambda($this_uncompress, $i, $o, resultContinuation) {
    this.p1s_1 = $this_uncompress;
    this.q1s_1 = $i;
    this.r1s_1 = $o;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(uncompress$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(uncompress$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(uncompress$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = uncompress_0(this.p1s_1, toAsyncInputStream(this.q1s_1), toAsyncOutputStream(this.r1s_1), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(uncompress$slambda).g1p = function (completion) {
    var i = new uncompress$slambda(this.p1s_1, this.q1s_1, this.r1s_1, completion);
    return i;
  };
  function uncompress$slambda_0($this_uncompress, $i, $o, resultContinuation) {
    var i = new uncompress$slambda($this_uncompress, $i, $o, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function $uncompressCOROUTINE$13(_this__u8e3s4, i, o, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d1s_1 = _this__u8e3s4;
    this.e1s_1 = i;
    this.f1s_1 = o;
  }
  protoOf($uncompressCOROUTINE$13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = Companion_getInstance_8().v1s(this.e1s_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.g1s_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.d1s_1.u1r(this.g1s_1, this.f1s_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Companion() {
    Companion_instance = this;
    this.w1s_1 = 8388608;
    this.x1s_1 = 131072;
  }
  var Companion_instance;
  function Companion_getInstance_5() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function SlidingWindowWithOutput(sliding, out, flushSize, extraSize) {
    Companion_getInstance_5();
    var tmp;
    if (flushSize === VOID) {
      Companion_getInstance_5();
      tmp = 8388608;
    } else {
      tmp = flushSize;
    }
    flushSize = tmp;
    var tmp_0;
    if (extraSize === VOID) {
      Companion_getInstance_5();
      tmp_0 = 131072;
    } else {
      tmp_0 = extraSize;
    }
    extraSize = tmp_0;
    this.y1s_1 = sliding;
    this.z1s_1 = out;
    this.a1t_1 = flushSize;
    this.b1t_1 = extraSize;
    this.c1t_1 = FixedSizeByteArrayBuilder_init_$Create$(this.a1t_1 + this.b1t_1 | 0);
  }
  protoOf(SlidingWindowWithOutput).d1t = function () {
    return this.c1t_1.i1j_1 >= this.a1t_1;
  };
  protoOf(SlidingWindowWithOutput).e1t = function (distance, length) {
    var inductionVariable = 0;
    if (inductionVariable < length)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = this.y1s_1.j1t(distance);
        this.c1t_1.b1j(toByte(v));
      }
       while (inductionVariable < length);
  };
  protoOf(SlidingWindowWithOutput).k1t = function (bytes, offset, len) {
    this.c1t_1.z1i(bytes, offset, len);
    this.y1s_1.l1t(bytes, offset, len);
  };
  protoOf(SlidingWindowWithOutput).m1t = function (byte) {
    this.c1t_1.b1j(byte);
    var tmp$ret$0;
    // Inline function 'korlibs.memory.unsigned' call
    tmp$ret$0 = byte & 255;
    this.y1s_1.n1t(tmp$ret$0);
  };
  function Companion_0() {
    Companion_instance_0 = this;
    DeflatePortable.call(this, 15);
    var tmp = this;
    var tmp_0 = new HuffmanTree();
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new Int32Array(288);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.compression.deflate.Companion.FIXED_TREE.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable <= 143)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        tmp0_apply[n] = 8;
      }
       while (inductionVariable <= 143);
    var inductionVariable_0 = 144;
    if (inductionVariable_0 <= 255)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        tmp0_apply[n_0] = 9;
      }
       while (inductionVariable_0 <= 255);
    var inductionVariable_1 = 256;
    if (inductionVariable_1 <= 279)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        tmp0_apply[n_1] = 7;
      }
       while (inductionVariable_1 <= 279);
    var inductionVariable_2 = 280;
    if (inductionVariable_2 <= 287)
      do {
        var n_2 = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + 1 | 0;
        tmp0_apply[n_2] = 8;
      }
       while (inductionVariable_2 <= 287);
    tmp$ret$0 = tmp0_apply;
    tmp.p1t_1 = tmp_0.i1u(tmp$ret$0);
    var tmp_1 = this;
    var tmp_2 = new HuffmanTree();
    var tmp_3 = 0;
    var tmp_4 = 32;
    var tmp_5 = new Int32Array(tmp_4);
    while (tmp_3 < tmp_4) {
      var tmp_6 = tmp_3;
      var tmp$ret$1;
      // Inline function 'korlibs.io.compression.deflate.Companion.FIXED_DIST.<anonymous>' call
      tmp$ret$1 = 5;
      tmp_5[tmp_6] = tmp$ret$1;
      tmp_3 = tmp_3 + 1 | 0;
    }
    tmp_1.q1t_1 = tmp_2.i1u(tmp_5);
    var tmp_7 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$2 = new Int32Array([0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3, 3, 3, 3, 4, 4, 4, 4, 5, 5, 5, 5, 0, 0, 0]);
    tmp_7.r1t_1 = tmp$ret$2;
    var tmp_8 = this;
    var tmp$ret$3;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$3 = new Int32Array([3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 15, 17, 19, 23, 27, 31, 35, 43, 51, 59, 67, 83, 99, 115, 131, 163, 195, 227, 258, 0, 0]);
    tmp_8.s1t_1 = tmp$ret$3;
    var tmp_9 = this;
    var tmp$ret$4;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$4 = new Int32Array([0, 0, 0, 0, 1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 8, 8, 9, 9, 10, 10, 11, 11, 12, 12, 13, 13]);
    tmp_9.t1t_1 = tmp$ret$4;
    var tmp_10 = this;
    var tmp$ret$5;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$5 = new Int32Array([1, 2, 3, 4, 5, 7, 9, 13, 17, 25, 33, 49, 65, 97, 129, 193, 257, 385, 513, 769, 1025, 1537, 2049, 3073, 4097, 6145, 8193, 12289, 16385, 24577, 0, 0]);
    tmp_10.u1t_1 = tmp$ret$5;
    var tmp_11 = this;
    var tmp$ret$6;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$6 = new Int32Array([16, 17, 18, 0, 8, 7, 9, 6, 10, 5, 11, 4, 12, 3, 13, 2, 14, 1, 15]);
    tmp_11.v1t_1 = tmp$ret$6;
  }
  var Companion_instance_0;
  function Companion_getInstance_6() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function $uncompressCOROUTINE$16(_this__u8e3s4, reader, out, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s1u_1 = _this__u8e3s4;
    this.t1u_1 = reader;
    this.u1u_1 = out;
  }
  protoOf($uncompressCOROUTINE$16).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 20;
            this.v1u_1 = new SlidingWindowWithOutput(new SlidingWindow(this.s1u_1.j1u_1), this.u1u_1, this.t1u_1.k1v_1, this.t1u_1.l1v_1);
            this.w1u_1 = false;
            this.x1u_1 = new HuffmanTree();
            this.y1u_1 = new HuffmanTree();
            this.z1u_1 = new Int32Array(32);
            this.a1v_1 = new Int32Array(512);
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!!this.w1u_1) {
              this.y7_1 = 17;
              continue $sm;
            }

            if (false ? true : this.v1u_1.d1t()) {
              this.y7_1 = 2;
              suspendResult = this.v1u_1.z1s_1.s1v(this.v1u_1.c1t_1.h1j_1, 0, this.v1u_1.c1t_1.i1j_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.v1u_1.c1t_1.e4();
            this.y7_1 = 3;
            continue $sm;
          case 3:
            if (this.t1u_1.u1v()) {
              this.y7_1 = 4;
              suspendResult = this.t1u_1.t1v(this.t1u_1.k1v_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 5;
              continue $sm;
            }

            break;
          case 4:
            this.y7_1 = 5;
            continue $sm;
          case 5:
            this.w1u_1 = this.t1u_1.v1v();
            this.b1v_1 = this.t1u_1.w1v(2);
            var containsArg = this.b1v_1;
            if (!(0 <= containsArg ? containsArg <= 2 : false)) {
              throw IllegalStateException_init_$Create$('invalid bit');
            }

            if (this.b1v_1 === 0) {
              this.c1v_1 = this.t1u_1.a1w();
              this.d1v_1 = this.t1u_1.a1w();
              this.e1v_1 = ~this.d1v_1 & 65535;
              if (!(this.c1v_1 === this.e1v_1)) {
                var tmp0_error = 'Invalid deflate stream: len(' + this.c1v_1 + ') != ~nlen(' + this.e1v_1 + ') :: nlen=' + this.d1v_1;
                throw IllegalStateException_init_$Create$(toString(tmp0_error));
              }
              this.y7_1 = 13;
              suspendResult = this.t1u_1.b1w(this.c1v_1, VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              if (this.b1v_1 === 1) {
                this.f1v_1 = Companion_getInstance_6().p1t_1;
                this.g1v_1 = Companion_getInstance_6().q1t_1;
              } else {
                var hlit = this.t1u_1.w1v(5) + 257 | 0;
                var hdist = this.t1u_1.w1v(5) + 1 | 0;
                var hclen = this.t1u_1.w1v(4) + 4 | 0;
                fill(this.z1u_1, 0);
                var inductionVariable = 0;
                if (inductionVariable < hclen)
                  do {
                    var i = inductionVariable;
                    inductionVariable = inductionVariable + 1 | 0;
                    this.z1u_1[Companion_getInstance_6().v1t_1[i]] = this.t1u_1.w1v(3);
                  }
                   while (inductionVariable < hclen);
                this.x1u_1.x1v(this.z1u_1);
                var codeLen = this.x1u_1;
                var hlithdist = hlit + hdist | 0;
                var n = 0;
                fill(this.a1v_1, 0);
                while (n < hlithdist) {
                  var value = codeLen.y1v(this.t1u_1);
                  if (!(0 <= value ? value <= 18 : false)) {
                    throw IllegalStateException_init_$Create$('Invalid');
                  }
                  var tmp1_subject = value;
                  {
                    var len;
                    switch (tmp1_subject) {
                      case 16:
                        len = this.t1u_1.w1v(2) + 3 | 0;
                        break;
                      case 17:
                        len = this.t1u_1.w1v(3) + 3 | 0;
                        break;
                      case 18:
                        len = this.t1u_1.w1v(7) + 11 | 0;
                        break;
                      default:
                        len = 1;
                        break;
                    }
                  }
                  var tmp2_subject = value;
                  {
                    var vv;
                    switch (tmp2_subject) {
                      case 16:
                        vv = this.a1v_1[n - 1 | 0];
                        break;
                      case 17:
                        vv = 0;
                        break;
                      case 18:
                        vv = 0;
                        break;
                      default:
                        vv = value;
                        break;
                    }
                  }
                  fill(this.a1v_1, vv, n, n + len | 0);
                  n = n + len | 0;
                }
                this.x1u_1.z1v(this.a1v_1, 0, hlit);
                this.y1u_1.z1v(this.a1v_1, hlit, hlithdist);
                this.f1v_1 = this.x1u_1;
                this.g1v_1 = this.y1u_1;
              }
              this.y7_1 = 6;
              continue $sm;
            }

            break;
          case 6:
            if (false) {
              this.y7_1 = 12;
              continue $sm;
            }

            if (this.t1u_1.u1v()) {
              this.y7_1 = 7;
              suspendResult = this.t1u_1.t1v(this.t1u_1.k1v_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 8;
              continue $sm;
            }

            break;
          case 7:
            this.y7_1 = 8;
            continue $sm;
          case 8:
            this.h1v_1 = this.f1v_1.y1v(this.t1u_1);
            if (this.h1v_1 === 256) {
              this.y7_1 = 12;
              continue $sm;
            } else {
              this.y7_1 = 9;
              continue $sm;
            }

            break;
          case 9:
            if (this.h1v_1 < 256) {
              this.v1u_1.m1t(toByte(this.h1v_1));
            } else {
              var zlenof = this.h1v_1 - 257 | 0;
              var lengthExtra = this.t1u_1.w1v(Companion_getInstance_6().r1t_1[zlenof]);
              var distanceData = this.g1v_1.y1v(this.t1u_1);
              var distanceExtra = this.t1u_1.w1v(Companion_getInstance_6().t1t_1[distanceData]);
              var distance = Companion_getInstance_6().u1t_1[distanceData] + distanceExtra | 0;
              var length = Companion_getInstance_6().s1t_1[zlenof] + lengthExtra | 0;
              this.v1u_1.e1t(distance, length);
            }

            if (false ? true : this.v1u_1.d1t()) {
              this.y7_1 = 10;
              suspendResult = this.v1u_1.z1s_1.s1v(this.v1u_1.c1t_1.h1j_1, 0, this.v1u_1.c1t_1.i1j_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 11;
              continue $sm;
            }

            break;
          case 10:
            this.v1u_1.c1t_1.e4();
            this.y7_1 = 11;
            continue $sm;
          case 11:
            this.y7_1 = 6;
            continue $sm;
          case 12:
            this.y7_1 = 16;
            continue $sm;
          case 13:
            this.i1v_1 = suspendResult;
            this.v1u_1.k1t(this.i1v_1, 0, this.c1v_1);
            if (false ? true : this.v1u_1.d1t()) {
              this.y7_1 = 14;
              suspendResult = this.v1u_1.z1s_1.s1v(this.v1u_1.c1t_1.h1j_1, 0, this.v1u_1.c1t_1.i1j_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 15;
              continue $sm;
            }

            break;
          case 14:
            this.v1u_1.c1t_1.e4();
            this.y7_1 = 15;
            continue $sm;
          case 15:
            this.y7_1 = 16;
            continue $sm;
          case 16:
            this.y7_1 = 1;
            continue $sm;
          case 17:
            if (true) {
              this.y7_1 = 18;
              suspendResult = this.v1u_1.z1s_1.s1v(this.v1u_1.c1t_1.h1j_1, 0, this.v1u_1.c1t_1.i1j_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 19;
              continue $sm;
            }

            break;
          case 18:
            this.v1u_1.c1t_1.e4();
            this.y7_1 = 19;
            continue $sm;
          case 19:
            return Unit_getInstance();
          case 20:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 20) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function DeflatePortable(windowBits) {
    Companion_getInstance_6();
    this.j1u_1 = windowBits;
  }
  protoOf(DeflatePortable).u1r = function (reader, out, $completion) {
    var tmp = new $uncompressCOROUTINE$16(this, reader, out, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function ZLib$Companion$_init_$lambda_nrgg4o(it) {
    return Deflate(it);
  }
  function $writeCOROUTINE$25(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.k1w_1 = _this__u8e3s4;
    this.l1w_1 = buffer;
    this.m1w_1 = offset;
    this.n1w_1 = len;
  }
  protoOf($writeCOROUTINE$25).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.k1w_1.o1w_1.s1v(this.l1w_1, this.m1w_1, this.n1w_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.k1w_1.p1w_1._v = Adler32_getInstance().s1w(this.k1w_1.p1w_1._v, this.l1w_1, this.m1w_1, this.n1w_1);
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Companion_1() {
    Companion_instance_1 = this;
    ZLib.call(this, ZLib$Companion$_init_$lambda_nrgg4o);
  }
  var Companion_instance_1;
  function Companion_getInstance_7() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function ZLib$uncompress$2($o, $chash) {
    this.o1w_1 = $o;
    this.p1w_1 = $chash;
  }
  protoOf(ZLib$uncompress$2).u1w = function ($completion) {
    var tmp0 = this.o1w_1.u1w($completion);
    return tmp0;
  };
  protoOf(ZLib$uncompress$2).s1v = function (buffer, offset, len, $completion) {
    var tmp = new $writeCOROUTINE$25(this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $uncompressCOROUTINE$23(_this__u8e3s4, reader, out, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d1x_1 = _this__u8e3s4;
    this.e1x_1 = reader;
    this.f1x_1 = out;
  }
  protoOf($uncompressCOROUTINE$23).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.g1x_1 = this.e1x_1;
            this.h1x_1 = this.f1x_1;
            if (this.g1x_1.u1v()) {
              this.y7_1 = 1;
              suspendResult = this.g1x_1.t1v(this.g1x_1.k1v_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.i1x_1 = this.g1x_1.r1x();
            this.j1x_1 = this.g1x_1.r1x();
            if (!(((imul(this.i1x_1, 256) + this.j1x_1 | 0) % 31 | 0) === 0)) {
              throw IllegalStateException_init_$Create$('bad zlib header');
            }

            this.k1x_1 = extract(this.i1x_1, 0, 4);
            if (!(this.k1x_1 === 8)) {
              var tmp0_error = 'Invalid zlib stream compressionMethod=' + this.k1x_1;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }

            this.l1x_1 = extract(this.i1x_1, 4, 4) + 8 | 0;
            this.m1x_1 = extract(this.j1x_1, 0, 5);
            var tmp_0 = this;
            tmp_0.n1x_1 = !(((this.j1x_1 >>> 5 | 0) & 1) === 0);
            this.o1x_1 = extract(this.j1x_1, 6, 2);
            this.p1x_1 = 0;
            if (this.n1x_1) {
              this.p1x_1 = this.g1x_1.s1x();
              var tmp1_TODO = 'Unsupported custom dictionaries (Provided DICTID=' + this.p1x_1 + ')';
              throw new NotImplementedError('An operation is not implemented: ' + tmp1_TODO);
            }

            this.q1x_1 = {_v: Adler32_getInstance().r1w_1};
            this.y7_1 = 3;
            var tmp_1 = this.d1x_1.t1w_1(this.l1x_1);
            suspendResult = tmp_1.u1r(this.g1x_1, new ZLib$uncompress$2(this.h1x_1, this.q1x_1), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            if (this.g1x_1.u1v()) {
              this.y7_1 = 4;
              suspendResult = this.g1x_1.t1v(this.g1x_1.k1v_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 5;
              continue $sm;
            }

            break;
          case 4:
            this.y7_1 = 5;
            continue $sm;
          case 5:
            var adler32 = this.g1x_1.t1x();
            if (!(this.q1x_1._v === adler32)) {
              invalidOp("Adler32 doesn't match " + get_hex(this.q1x_1._v) + ' != ' + get_hex(adler32));
            }

            return Unit_getInstance();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function ZLib(deflater) {
    Companion_getInstance_7();
    this.t1w_1 = deflater;
  }
  protoOf(ZLib).u1r = function (reader, out, $completion) {
    var tmp = new $uncompressCOROUTINE$23(this, reader, out, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $forInputCOROUTINE$27(_this__u8e3s4, s, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.c1y_1 = _this__u8e3s4;
    this.d1y_1 = s;
  }
  protoOf($forInputCOROUTINE$27).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            var tmp_0 = this.d1y_1;
            if (isInterface(tmp_0, AsyncGetLengthStream)) {
              this.y7_1 = 1;
              suspendResult = this.d1y_1.g1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.e1y_1 = false;
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.e1y_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            if (this.e1y_1) {
              var tmp_1 = this;
              tmp_1.f1y_1 = 32768;
              this.y7_1 = 4;
              suspendResult = this.d1y_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            ;
            break;
          case 3:
            return new BitReader(this.d1y_1);
          case 4:
            var tmp0_min = suspendResult;
            var tmp1_min = new Long(8388608, 0);
            var ARGUMENT = tmp0_min.k1(tmp1_min) <= 0 ? tmp0_min : tmp1_min;
            var tmp3_max = ARGUMENT.t1();
            var bigChunkSize = Math.max(this.f1y_1, tmp3_max);
            var tmp4_max = bigChunkSize / 2 | 0;
            var tmp5_max = 32768;
            var readWithSize = Math.max(tmp4_max, tmp5_max);
            return new BitReader(this.d1y_1, bigChunkSize, readWithSize);
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Companion_2() {
    Companion_instance_2 = this;
    this.t1s_1 = 8388608;
    this.u1s_1 = 32768;
  }
  protoOf(Companion_2).v1s = function (s, $completion) {
    var tmp = new $forInputCOROUTINE$27(this, s, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  var Companion_instance_2;
  function Companion_getInstance_8() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function $prepareBytesUpToCOROUTINE$29(_this__u8e3s4, expectedBytes, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.q1y_1 = _this__u8e3s4;
    this.r1y_1 = expectedBytes;
  }
  protoOf($prepareBytesUpToCOROUTINE$29).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!(this.q1y_1.o1v_1.p1h_1 < this.r1y_1)) {
              this.y7_1 = 5;
              continue $sm;
            }

            var tmp_0 = this;
            var tmp0_min = this.q1y_1.o1v_1.u1h();
            tmp_0.s1y_1 = Math.min(this.r1y_1, tmp0_min);
            if (this.s1y_1 <= 0) {
              this.y7_1 = 5;
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 2:
            this.y7_1 = 3;
            suspendResult = this.q1y_1.j1v_1.t1y(this.q1y_1.o1v_1.q1h(), this.q1y_1.o1v_1.r1h(), this.s1y_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var transferred = suspendResult;
            if (transferred <= 0) {
              this.y7_1 = 5;
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 4:
            this.q1y_1.o1v_1.s1h(transferred);
            var tmp0_this = this.q1y_1;
            tmp0_this.q1v_1 = tmp0_this.q1v_1 + transferred;
            this.y7_1 = 1;
            continue $sm;
          case 5:
            return Unit_getInstance();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $abytesCOROUTINE$30(_this__u8e3s4, count, out, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.c1z_1 = _this__u8e3s4;
    this.d1z_1 = count;
    this.e1z_1 = out;
  }
  protoOf($abytesCOROUTINE$30).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.c1z_1.t1v(this.d1z_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return this.c1z_1.f1z(this.d1z_1, this.e1z_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readCOROUTINE$31(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.o1z_1 = _this__u8e3s4;
    this.p1z_1 = buffer;
    this.q1z_1 = offset;
    this.r1z_1 = len;
  }
  protoOf($readCOROUTINE$31).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.o1z_1.t1v(this.r1z_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var out = this.o1z_1.o1v_1.v1h(this.p1z_1, this.q1z_1, this.r1z_1);
            var tmp0_this = this.o1z_1;
            tmp0_this.p1v_1 = tmp0_this.p1v_1 + out;
            return out;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $hasLengthCOROUTINE$34(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.a20_1 = _this__u8e3s4;
  }
  protoOf($hasLengthCOROUTINE$34).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.a20_1.j1v_1;
            tmp_0.b20_1 = isInterface(tmp_1, AsyncGetLengthStream) ? tmp_1 : null;
            if (this.b20_1 == null) {
              this.c20_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.b20_1.g1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.c20_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            var tmp1_elvis_lhs = this.c20_1;
            return tmp1_elvis_lhs == null ? false : tmp1_elvis_lhs;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getLengthCOROUTINE$35(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l20_1 = _this__u8e3s4;
  }
  protoOf($getLengthCOROUTINE$35).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.l20_1.j1v_1;
            tmp_0.m20_1 = isInterface(tmp_1, AsyncGetLengthStream) ? tmp_1 : null;
            if (this.m20_1 == null) {
              this.n20_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.m20_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.n20_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            var tmp1_elvis_lhs = this.n20_1;
            var tmp_2;
            if (tmp1_elvis_lhs == null) {
              throw IllegalStateException_init_$Create$('Length not available on Stream');
            } else {
              tmp_2 = tmp1_elvis_lhs;
            }

            return tmp_2;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function BitReader(s, bigChunkSize, readWithSize) {
    Companion_getInstance_8();
    var tmp;
    if (bigChunkSize === VOID) {
      Companion_getInstance_8();
      tmp = 8388608;
    } else {
      tmp = bigChunkSize;
    }
    bigChunkSize = tmp;
    var tmp_0;
    if (readWithSize === VOID) {
      Companion_getInstance_8();
      tmp_0 = 32768;
    } else {
      tmp_0 = readWithSize;
    }
    readWithSize = tmp_0;
    this.j1v_1 = s;
    this.k1v_1 = bigChunkSize;
    this.l1v_1 = readWithSize;
    this.m1v_1 = 0;
    this.n1v_1 = 0;
    this.o1v_1 = new RingBuffer(ilog2(get_nextPowerOfTwo(this.k1v_1)));
    this.p1v_1 = 0.0;
    this.q1v_1 = 0.0;
    this.r1v_1 = new Int8Array(4);
  }
  protoOf(BitReader).toString = function () {
    return 'BitReader(' + this.j1v_1 + ', bigChunkSize=' + this.k1v_1 + ', readWithSize=' + this.l1v_1 + ')';
  };
  protoOf(BitReader).u1v = function () {
    return this.o1v_1.p1h_1 < this.l1v_1;
  };
  protoOf(BitReader).t1v = function (expectedBytes, $completion) {
    var tmp = new $prepareBytesUpToCOROUTINE$29(this, expectedBytes, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BitReader).o20 = function (bitcount) {
    while (this.n1v_1 < bitcount) {
      var tmp = this;
      var tmp_0 = this.m1v_1;
      var tmp$ret$0;
      // Inline function 'korlibs.io.compression.util.BitReader._su8' call
      var tmp0_this = this;
      var tmp1 = tmp0_this.p1v_1;
      tmp0_this.p1v_1 = tmp1 + 1;
      tmp$ret$0 = this.o1v_1.z1h();
      tmp.m1v_1 = tmp_0 | tmp$ret$0 << this.n1v_1;
      var tmp0_this_0 = this;
      tmp0_this_0.n1v_1 = tmp0_this_0.n1v_1 + 8 | 0;
    }
  };
  protoOf(BitReader).p20 = function (bitcount) {
    return this.m1v_1 & ((1 << bitcount) - 1 | 0);
  };
  protoOf(BitReader).q20 = function (bitcount) {
    this.m1v_1 = this.m1v_1 >>> bitcount | 0;
    var tmp0_this = this;
    tmp0_this.n1v_1 = tmp0_this.n1v_1 - bitcount | 0;
  };
  protoOf(BitReader).w1v = function (bitcount) {
    this.o20(bitcount);
    var readed = this.p20(bitcount);
    this.q20(bitcount);
    return readed;
  };
  protoOf(BitReader).v1v = function () {
    return !(this.w1v(1) === 0);
  };
  protoOf(BitReader).r20 = function (count, out) {
    var offset = 0;
    var count_0 = count;
    if (this.n1v_1 >= 8) {
      if (!((this.n1v_1 % 8 | 0) === 0)) {
        var bits = this.n1v_1 % 8 | 0;
        this.q20(bits);
      }
      while (this.n1v_1 >= 8) {
        var byte = toByte(this.w1v(8));
        var tmp0 = offset;
        offset = tmp0 + 1 | 0;
        out[tmp0] = byte;
        var tmp1 = count_0;
        count_0 = tmp1 - 1 | 0;
      }
    }
    var tmp$ret$0;
    // Inline function 'korlibs.io.compression.util.BitReader.discardBits' call
    this.m1v_1 = 0;
    this.n1v_1 = 0;
    tmp$ret$0 = this;
    var readCount = this.o1v_1.v1h(out, offset, count_0);
    if (readCount > 0) {
      var tmp2_this = this;
      tmp2_this.p1v_1 = tmp2_this.p1v_1 + readCount;
    }
  };
  protoOf(BitReader).f1z = function (count, out) {
    this.r20(count, out);
    return out;
  };
  protoOf(BitReader).r1x = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.io.compression.util.BitReader._su8' call
    var tmp$ret$0;
    // Inline function 'korlibs.io.compression.util.BitReader.discardBits' call
    this.m1v_1 = 0;
    this.n1v_1 = 0;
    tmp$ret$0 = this;
    var tmp0__su8 = tmp$ret$0;
    var tmp0_this = tmp0__su8;
    var tmp1 = tmp0_this.p1v_1;
    tmp0_this.p1v_1 = tmp1 + 1;
    tmp$ret$1 = tmp0__su8.o1v_1.z1h();
    return tmp$ret$1;
  };
  protoOf(BitReader).a1w = function () {
    this.r20(2, this.r1v_1);
    return readU16LE(this.r1v_1, 0);
  };
  protoOf(BitReader).s1x = function () {
    this.r20(4, this.r1v_1);
    return readS32LE(this.r1v_1, 0);
  };
  protoOf(BitReader).t1x = function () {
    this.r20(4, this.r1v_1);
    return readS32BE(this.r1v_1, 0);
  };
  protoOf(BitReader).s20 = function (count, out, $completion) {
    var tmp = new $abytesCOROUTINE$30(this, count, out, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BitReader).b1w = function (count, out, $completion, $super) {
    out = out === VOID ? new Int8Array(count) : out;
    return $super === VOID ? this.s20(count, out, $completion) : $super.s20.call(this, count, out, $completion);
  };
  protoOf(BitReader).t1y = function (buffer, offset, len, $completion) {
    var tmp = new $readCOROUTINE$31(this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BitReader).u1w = function ($completion) {
    var tmp0 = this.j1v_1.u1w($completion);
    return tmp0;
  };
  protoOf(BitReader).t20 = function ($completion) {
    return numberToLong(this.p1v_1);
  };
  protoOf(BitReader).g1y = function ($completion) {
    var tmp = new $hasLengthCOROUTINE$34(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BitReader).h1y = function ($completion) {
    var tmp = new $getLengthCOROUTINE$35(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function Companion_3() {
    Companion_instance_3 = this;
    this.u20_1 = -1;
    this.v20_1 = -2;
    this.w20_1 = 1023;
    this.x20_1 = 16;
    this.y20_1 = 288;
    this.z20_1 = true;
    this.a21_1 = true;
    this.b21_1 = 10;
  }
  var Companion_instance_3;
  function Companion_getInstance_9() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function resetAlloc($this) {
    $this.z1t_1 = 0;
  }
  function alloc($this, value, left, right) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_this = $this;
    var tmp1 = tmp0_this.z1t_1;
    tmp0_this.z1t_1 = tmp1 + 1 | 0;
    var tmp0_apply = tmp1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.compression.util.HuffmanTree.alloc.<anonymous>' call
    $this.w1t_1[tmp0_apply] = value;
    $this.x1t_1[tmp0_apply] = left;
    $this.y1t_1[tmp0_apply] = right;
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function allocLeaf($this, value) {
    Companion_getInstance_9();
    Companion_getInstance_9();
    return alloc($this, value, 1023, 1023);
  }
  function allocNode($this, left, right) {
    Companion_getInstance_9();
    return alloc($this, -1, left, right);
  }
  function computeFastLookup($this) {
    Companion_getInstance_9();
    {
      Companion_getInstance_9();
      fill($this.c1u_1, -1);
      computeEncodedValues($this, $this.a1u_1, 0, 0);
    }
  }
  function computeEncodedValues($this, node, encoded, encodedBits) {
    var tmp$ret$0;
    // Inline function 'korlibs.io.compression.util.HuffmanTree.value' call
    tmp$ret$0 = $this.w1t_1[node];
    var tmp = tmp$ret$0;
    Companion_getInstance_9();
    if (tmp === -1) {
      Companion_getInstance_9();
      if (encodedBits < 10) {
        var tmp$ret$1;
        // Inline function 'korlibs.io.compression.util.HuffmanTree.left' call
        tmp$ret$1 = $this.x1t_1[node];
        computeEncodedValues($this, tmp$ret$1, encoded, encodedBits + 1 | 0);
        var tmp$ret$2;
        // Inline function 'korlibs.io.compression.util.HuffmanTree.right' call
        tmp$ret$2 = $this.y1t_1[node];
        computeEncodedValues($this, tmp$ret$2, encoded | 1 << encodedBits, encodedBits + 1 | 0);
      } else {
        Companion_getInstance_9();
        writeVariants($this, encoded, encodedBits, node, -2);
      }
    } else {
      var tmp$ret$3;
      // Inline function 'korlibs.io.compression.util.HuffmanTree.value' call
      tmp$ret$3 = $this.w1t_1[node];
      writeVariants($this, encoded, encodedBits, node, tmp$ret$3);
    }
  }
  function writeVariants($this, encoded, encodedBits, node, nvalue) {
    var encodedInfo = nvalue & 65535 | encodedBits << 16;
    Companion_getInstance_9();
    var rangeCount = 1 << (10 - encodedBits | 0);
    var inductionVariable = 0;
    if (inductionVariable < rangeCount)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var i = encoded | n << encodedBits;
        $this.c1u_1[i] = encodedInfo;
        $this.d1u_1[i] = node;
      }
       while (inductionVariable < rangeCount);
  }
  function HuffmanTree() {
    Companion_getInstance_9();
    this.w1t_1 = new Int32Array(1024);
    this.x1t_1 = new Int32Array(1024);
    this.y1t_1 = new Int32Array(1024);
    this.z1t_1 = 0;
    var tmp = this;
    Companion_getInstance_9();
    tmp.a1u_1 = 1023;
    this.b1u_1 = 0;
    var tmp_0 = this;
    var tmp_1 = 0;
    Companion_getInstance_9();
    var tmp_2 = 1 << 10;
    var tmp_3 = new Int32Array(tmp_2);
    while (tmp_1 < tmp_2) {
      var tmp_4 = tmp_1;
      var tmp$ret$0;
      // Inline function 'korlibs.io.compression.util.HuffmanTree.FAST_INFO.<anonymous>' call
      Companion_getInstance_9();
      tmp$ret$0 = -1;
      tmp_3[tmp_4] = tmp$ret$0;
      tmp_1 = tmp_1 + 1 | 0;
    }
    tmp_0.c1u_1 = tmp_3;
    var tmp_5 = this;
    var tmp_6 = 0;
    Companion_getInstance_9();
    var tmp_7 = 1 << 10;
    var tmp_8 = new Int32Array(tmp_7);
    while (tmp_6 < tmp_7) {
      var tmp_9 = tmp_6;
      var tmp$ret$1;
      // Inline function 'korlibs.io.compression.util.HuffmanTree.FAST_NODE.<anonymous>' call
      tmp$ret$1 = 0;
      tmp_8[tmp_9] = tmp$ret$1;
      tmp_6 = tmp_6 + 1 | 0;
    }
    tmp_5.d1u_1 = tmp_8;
    var tmp_10 = this;
    Companion_getInstance_9();
    tmp_10.e1u_1 = new Int32Array(16 + 1 | 0);
    var tmp_11 = this;
    Companion_getInstance_9();
    tmp_11.f1u_1 = new Int32Array(16 + 1 | 0);
    var tmp_12 = this;
    Companion_getInstance_9();
    tmp_12.g1u_1 = new Int32Array(16 + 1 | 0);
    var tmp_13 = this;
    Companion_getInstance_9();
    tmp_13.h1u_1 = new Int32Array(288);
  }
  protoOf(HuffmanTree).y1v = function (reader) {
    Companion_getInstance_9();
    {
      Companion_getInstance_9();
      reader.o20(10);
    }
    var node = this.a1u_1;
    var tmp;
    Companion_getInstance_9();
    {
      var tmp_0 = reader.n1v_1;
      Companion_getInstance_9();
      tmp = tmp_0 >= 10;
    }
    if (tmp) {
      Companion_getInstance_9();
      var bits = reader.p20(10);
      var raw = this.c1u_1[bits];
      var value = toShort(raw);
      var len = raw >> 16;
      if (len > 0) {
        reader.q20(len);
        Companion_getInstance_9();
        if (value === -2) {
          node = this.d1u_1[bits];
        } else {
          return value;
        }
      }
    }
    $l$1: do {
      $l$0: do {
        var tmp_1;
        if (reader.v1v()) {
          var tmp$ret$0;
          // Inline function 'korlibs.io.compression.util.HuffmanTree.right' call
          var tmp0__get_right__ll61w1 = node;
          tmp$ret$0 = this.y1t_1[tmp0__get_right__ll61w1];
          tmp_1 = tmp$ret$0;
        } else {
          var tmp$ret$1;
          // Inline function 'korlibs.io.compression.util.HuffmanTree.left' call
          var tmp1__get_left__aid1ft = node;
          tmp$ret$1 = this.x1t_1[tmp1__get_left__aid1ft];
          tmp_1 = tmp$ret$1;
        }
        node = tmp_1;
      }
       while (false);
      var tmp_2;
      var tmp_3 = node;
      Companion_getInstance_9();
      if (!(tmp_3 === 1023)) {
        var tmp$ret$2;
        // Inline function 'korlibs.io.compression.util.HuffmanTree.value' call
        var tmp2__get_value__tinr9g = node;
        tmp$ret$2 = this.w1t_1[tmp2__get_value__tinr9g];
        var tmp_4 = tmp$ret$2;
        Companion_getInstance_9();
        tmp_2 = tmp_4 === -1;
      } else {
        tmp_2 = false;
      }
    }
     while (tmp_2);
    var tmp$ret$3;
    // Inline function 'korlibs.io.compression.util.HuffmanTree.value' call
    var tmp3__get_value__ydcegz = node;
    tmp$ret$3 = this.w1t_1[tmp3__get_value__ydcegz];
    return tmp$ret$3;
  };
  protoOf(HuffmanTree).c21 = function (codeLengths, start, end) {
    this.z1v(codeLengths, start, end);
    return this;
  };
  protoOf(HuffmanTree).i1u = function (codeLengths, start, end, $super) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? codeLengths.length : end;
    return $super === VOID ? this.c21(codeLengths, start, end) : $super.c21.call(this, codeLengths, start, end);
  };
  protoOf(HuffmanTree).z1v = function (codeLengths, start, end) {
    var oldOffset = 0;
    var oldCount = 0;
    var ncodes = end - start | 0;
    resetAlloc(this);
    fill(this.e1u_1, 0);
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var codeLen = codeLengths[n];
        var tmp;
        if (0 <= codeLen) {
          Companion_getInstance_9();
          tmp = codeLen <= 16;
        } else {
          tmp = false;
        }
        if (!tmp) {
          // Inline function 'kotlin.error' call
          var tmp0_error = 'Invalid HuffmanTree.codeLengths ' + codeLen;
          throw IllegalStateException_init_$Create$(toString(tmp0_error));
        }
        var tmp1_array = this.e1u_1;
        var tmp2_index0 = codeLen;
        var tmp3 = tmp1_array[tmp2_index0];
        tmp1_array[tmp2_index0] = tmp3 + 1 | 0;
      }
       while (inductionVariable < end);
    var currentOffset = 0;
    var inductionVariable_0 = 0;
    Companion_getInstance_9();
    var last = 16;
    if (inductionVariable_0 < last)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var count = this.e1u_1[n_0];
        this.f1u_1[n_0] = currentOffset;
        this.g1u_1[n_0] = currentOffset;
        currentOffset = currentOffset + count | 0;
      }
       while (inductionVariable_0 < last);
    var inductionVariable_1 = start;
    if (inductionVariable_1 < end)
      do {
        var n_1 = inductionVariable_1;
        inductionVariable_1 = inductionVariable_1 + 1 | 0;
        var codeLen_0 = codeLengths[n_1];
        var tmp6_array = this.g1u_1;
        var tmp7_index0 = codeLen_0;
        var tmp8 = tmp6_array[tmp7_index0];
        tmp6_array[tmp7_index0] = tmp8 + 1 | 0;
        this.h1u_1[tmp8] = n_1 - start | 0;
      }
       while (inductionVariable_1 < end);
    Companion_getInstance_9();
    var inductionVariable_2 = 16;
    if (1 <= inductionVariable_2)
      do {
        var i = inductionVariable_2;
        inductionVariable_2 = inductionVariable_2 + -1 | 0;
        var newOffset = this.z1t_1;
        var OFFSET = this.f1u_1[i];
        var SIZE = this.e1u_1[i];
        var inductionVariable_3 = 0;
        if (inductionVariable_3 < SIZE)
          do {
            var j = inductionVariable_3;
            inductionVariable_3 = inductionVariable_3 + 1 | 0;
            allocLeaf(this, this.h1u_1[OFFSET + j | 0]);
          }
           while (inductionVariable_3 < SIZE);
        var progression = step(until(0, oldCount), 2);
        var inductionVariable_4 = progression.p1_1;
        var last_0 = progression.q1_1;
        var step_0 = progression.r1_1;
        if ((step_0 > 0 ? inductionVariable_4 <= last_0 : false) ? true : step_0 < 0 ? last_0 <= inductionVariable_4 : false)
          do {
            var j_0 = inductionVariable_4;
            inductionVariable_4 = inductionVariable_4 + step_0 | 0;
            allocNode(this, oldOffset + j_0 | 0, (oldOffset + j_0 | 0) + 1 | 0);
          }
           while (!(j_0 === last_0));
        oldOffset = newOffset;
        oldCount = SIZE + (oldCount / 2 | 0) | 0;
        if (oldCount >= 2 ? !((oldCount % 2 | 0) === 0) : false) {
          // Inline function 'kotlin.error' call
          var tmp1_error = 'This canonical code does not represent a Huffman code tree: ' + oldCount;
          throw IllegalStateException_init_$Create$(toString(tmp1_error));
        }
      }
       while (1 <= inductionVariable_2);
    if (!(oldCount === 2)) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException_init_$Create$('This canonical code does not represent a Huffman code tree');
    }
    this.a1u_1 = allocNode(this, this.z1t_1 - 2 | 0, this.z1t_1 - 1 | 0);
    this.b1u_1 = ncodes;
    Companion_getInstance_9();
    {
      computeFastLookup(this);
    }
  };
  protoOf(HuffmanTree).x1v = function (codeLengths, start, end, $super) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? codeLengths.length : end;
    var tmp;
    if ($super === VOID) {
      this.z1v(codeLengths, start, end);
      tmp = Unit_getInstance();
    } else {
      tmp = $super.z1v.call(this, codeLengths, start, end);
    }
    return tmp;
  };
  function SlidingWindow(nbits) {
    this.f1t_1 = nbits;
    this.g1t_1 = new Int8Array(1 << this.f1t_1);
    this.h1t_1 = this.g1t_1.length - 1 | 0;
    this.i1t_1 = 0;
  }
  protoOf(SlidingWindow).n = function (offset) {
    return this.g1t_1[(this.i1t_1 - offset | 0) & this.h1t_1] & 255;
  };
  protoOf(SlidingWindow).j1t = function (offset) {
    return this.n1t(this.n(offset));
  };
  protoOf(SlidingWindow).n1t = function (value) {
    this.g1t_1[this.i1t_1] = toByte(value);
    this.i1t_1 = (this.i1t_1 + 1 | 0) & this.h1t_1;
    return value;
  };
  protoOf(SlidingWindow).l1t = function (bytes, offset, len) {
    var inductionVariable = 0;
    if (inductionVariable < len)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'korlibs.memory.unsigned' call
        var tmp0__get_unsigned__3e7on0 = bytes[offset + n | 0];
        tmp$ret$0 = tmp0__get_unsigned__3e7on0 & 255;
        this.n1t(tmp$ret$0);
      }
       while (inductionVariable < len);
  };
  function KorAtomicRef(initial, dummy) {
    this.d21_1 = initial;
  }
  protoOf(KorAtomicRef).f1h = function (_set____db54di) {
    this.d21_1 = _set____db54di;
  };
  protoOf(KorAtomicRef).f1 = function () {
    return this.d21_1;
  };
  protoOf(KorAtomicRef).toString = function () {
    return '' + this.f1();
  };
  function KorAtomicRef_0(initial) {
    return korAtomic_0(initial);
  }
  function KorAtomicLong(initial, dummy) {
    this.e21_1 = initial;
  }
  protoOf(KorAtomicLong).f21 = function (_set____db54di) {
    this.e21_1 = _set____db54di;
  };
  protoOf(KorAtomicLong).f1 = function () {
    return this.e21_1;
  };
  protoOf(KorAtomicLong).g21 = function (delta) {
    var tmp0_this = this;
    tmp0_this.f21(tmp0_this.f1().b9(delta));
    return this.f1();
  };
  protoOf(KorAtomicLong).toString = function () {
    return toString(this.f1());
  };
  function KorAtomicInt(initial, dummy) {
    this.h21_1 = initial;
  }
  protoOf(KorAtomicInt).i21 = function (_set____db54di) {
    this.h21_1 = _set____db54di;
  };
  protoOf(KorAtomicInt).f1 = function () {
    return this.h21_1;
  };
  protoOf(KorAtomicInt).j21 = function (delta) {
    var tmp0_this = this;
    tmp0_this.i21(tmp0_this.f1() + delta | 0);
    return this.f1();
  };
  protoOf(KorAtomicInt).toString = function () {
    return '' + this.f1();
  };
  function incrementAndGet(_this__u8e3s4) {
    return _this__u8e3s4.j21(1);
  }
  function _Dyn___init__impl__6cuxxc(value) {
    return value;
  }
  function _Dyn___get_value__impl__bemnlg($this) {
    return $this;
  }
  function _Dyn___get_isNotNull__impl__di7my3($this) {
    return !(_Dyn___get_value__impl__bemnlg($this) == null);
  }
  function Dyn__toComparable_impl_pfql3z($this) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (tmp0_subject == null) {
      tmp = isComparable(0) ? 0 : THROW_CCE();
    } else {
      if (!(tmp0_subject == null) ? isComparable(tmp0_subject) : false) {
        var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
        tmp = (!(tmp_0 == null) ? isComparable(tmp_0) : false) ? tmp_0 : THROW_CCE();
      } else {
        var tmp_1 = toString(_Dyn___get_value__impl__bemnlg($this));
        tmp = isComparable(tmp_1) ? tmp_1 : THROW_CCE();
      }
    }
    return tmp;
  }
  function Dyn__compareTo_impl_7znl52($this, other) {
    var l = $this;
    var r = other;
    var tmp;
    var tmp_0 = _Dyn___get_value__impl__bemnlg(l);
    if (isNumber(tmp_0)) {
      var tmp_1 = _Dyn___get_value__impl__bemnlg(r);
      tmp = isNumber(tmp_1);
    } else {
      tmp = false;
    }
    if (tmp) {
      return compareTo(numberToDouble(_Dyn___get_value__impl__bemnlg(l)), numberToDouble(_Dyn___get_value__impl__bemnlg(r)));
    }
    var lc = Dyn__toComparable_impl_pfql3z(l);
    var rc = Dyn__toComparable_impl_pfql3z(r);
    return getKClassFromExpression(lc).aj(rc) ? compareTo(lc, rc) : -1;
  }
  function Dyn__compareTo_impl_7znl52_0($this, other) {
    var tmp = $this.k21_1;
    return Dyn__compareTo_impl_7znl52(tmp, other instanceof Dyn ? other.k21_1 : THROW_CCE());
  }
  function Dyn__toString_impl_9l0v3s($this) {
    return toString_1($this, _Dyn___get_value__impl__bemnlg($this));
  }
  function Dyn__toStringOrNull_impl_7e6cgy($this) {
    var tmp;
    if (_Dyn___get_isNotNull__impl__di7my3($this)) {
      tmp = Dyn__toString_impl_9l0v3s($this);
    } else {
      tmp = null;
    }
    return tmp;
  }
  function Companion_4() {
    Companion_instance_4 = this;
  }
  protoOf(Companion_4).l21 = function () {
    return get_dyn(get_dynApi().m21());
  };
  var Companion_instance_4;
  function Companion_getInstance_10() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function Dyn__toList_impl_pxssgr($this) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = Dyn__toListAny_impl_f7wxv7($this);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.dynamic.Dyn.toList.<anonymous>' call
      tmp$ret$0 = get_dyn(item);
      tmp0_mapTo.b(new Dyn(tmp$ret$0));
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function Dyn__toListAny_impl_f7wxv7($this) {
    return toList(Dyn__toIterableAny_impl_fib3cp($this));
  }
  function Dyn__toIterableAny_impl_fib3cp($this) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (tmp0_subject == null) {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp = tmp$ret$0;
    } else {
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Iterable) : false) {
        tmp = _Dyn___get_value__impl__bemnlg($this);
      } else {
        if (!(tmp0_subject == null) ? isCharSequence(tmp0_subject) : false) {
          tmp = toList_1(_Dyn___get_value__impl__bemnlg($this));
        } else {
          if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Map) : false) {
            tmp = toList_0(_Dyn___get_value__impl__bemnlg($this));
          } else {
            var tmp$ret$1;
            // Inline function 'kotlin.collections.listOf' call
            tmp$ret$1 = emptyList();
            tmp = tmp$ret$1;
          }
        }
      }
    }
    return tmp;
  }
  function Dyn__set_impl_2ozvx8($this, key, value) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    if (!(tmp0_subject == null) ? isInterface(tmp0_subject, MutableMap) : false) {
      // Inline function 'kotlin.collections.set' call
      var tmp = _Dyn___get_value__impl__bemnlg($this);
      var tmp0_set = (!(tmp == null) ? isInterface(tmp, MutableMap) : false) ? tmp : THROW_CCE();
      tmp0_set.e5(key, value);
    } else {
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, MutableList) : false) {
        var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
        ((!(tmp_0 == null) ? isInterface(tmp_0, MutableList) : false) ? tmp_0 : THROW_CCE()).a4(Dyn__toInt_impl_nu3u6q(get_dyn(key)), value);
      } else {
        get_dynApi().n21(_Dyn___get_value__impl__bemnlg($this), toString_0(key), value);
      }
    }
  }
  function Dyn__get_impl_6l2p80($this, key) {
    return Dyn__get_impl_6l2p80_0($this, _Dyn___get_value__impl__bemnlg(key));
  }
  function Dyn__get_impl_6l2p80_0($this, key) {
    return _getOrThrow($this, key, false);
  }
  function _getOrThrow($this, key, doThrow) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (tmp0_subject == null) {
      var tmp_0;
      if (doThrow) {
        throw NullPointerException_init_$Create$("Trying to access '" + toString_0(key) + "'");
      } else {
        tmp_0 = get_dyn(null);
      }
      tmp = tmp_0;
    } else {
      if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Map) : false) {
        var tmp_1 = _Dyn___get_value__impl__bemnlg($this);
        tmp = get_dyn(((!(tmp_1 == null) ? isInterface(tmp_1, Map) : false) ? tmp_1 : THROW_CCE()).h3(key));
      } else {
        if (!(tmp0_subject == null) ? isInterface(tmp0_subject, List) : false) {
          tmp = get_dyn(getOrNull(_Dyn___get_value__impl__bemnlg($this), Dyn__toInt_impl_nu3u6q(get_dyn(key))));
        } else {
          tmp = get_dyn(get_dynApi().o21(_Dyn___get_value__impl__bemnlg($this), toString_0(key)));
        }
      }
    }
    return tmp;
  }
  function _Dyn___get_listAny__impl__vrilgp($this) {
    var tmp;
    if (_Dyn___get_value__impl__bemnlg($this) == null) {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp = tmp$ret$0;
    } else {
      var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
      if (!(tmp_0 == null) ? isInterface(tmp_0, List) : false) {
        tmp = _Dyn___get_value__impl__bemnlg($this);
      } else {
        var tmp_1 = _Dyn___get_value__impl__bemnlg($this);
        if (!(tmp_1 == null) ? isInterface(tmp_1, Iterable) : false) {
          tmp = toList(_Dyn___get_value__impl__bemnlg($this));
        } else {
          tmp = listOf(_Dyn___get_value__impl__bemnlg($this));
        }
      }
    }
    return tmp;
  }
  function _Dyn___get_keysAny__impl__whx5cj($this) {
    var tmp;
    var tmp_0 = _Dyn___get_value__impl__bemnlg($this);
    if (!(tmp_0 == null) ? isInterface(tmp_0, Map) : false) {
      tmp = toList(_Dyn___get_value__impl__bemnlg($this).i3());
    } else {
      var tmp$ret$0;
      // Inline function 'kotlin.collections.listOf' call
      tmp$ret$0 = emptyList();
      tmp = tmp$ret$0;
    }
    return tmp;
  }
  function _Dyn___get_list__impl__qpwjp($this) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = _Dyn___get_listAny__impl__vrilgp($this);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.dynamic.Dyn.<get-list>.<anonymous>' call
      tmp$ret$0 = get_dyn(item);
      tmp0_mapTo.b(new Dyn(tmp$ret$0));
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function _Dyn___get_keys__impl__o4xmor($this) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = _Dyn___get_keysAny__impl__whx5cj($this);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.dynamic.Dyn.<get-keys>.<anonymous>' call
      tmp$ret$0 = get_dyn(item);
      tmp0_mapTo.b(new Dyn(tmp$ret$0));
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function Dyn__toNumber_impl_rlhtn4($this, $receiver) {
    var tmp = toIntOrNull($receiver);
    var tmp0_elvis_lhs = (tmp == null ? true : isNumber(tmp)) ? tmp : null;
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? toDoubleOrNull($receiver) : tmp0_elvis_lhs;
    var tmp_0;
    if (tmp1_elvis_lhs == null) {
      DoubleCompanionObject_getInstance();
      tmp_0 = NaN;
    } else {
      tmp_0 = tmp1_elvis_lhs;
    }
    return tmp_0;
  }
  function Dyn__toNumber_impl_rlhtn4_0($this) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (tmp0_subject == null) {
      tmp = 0;
    } else {
      if (isNumber(tmp0_subject)) {
        tmp = _Dyn___get_value__impl__bemnlg($this);
      } else {
        if (!(tmp0_subject == null) ? typeof tmp0_subject === 'boolean' : false) {
          tmp = _Dyn___get_value__impl__bemnlg($this) ? 1 : 0;
        } else {
          if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
            var tmp1_elvis_lhs = toIntSafe(_Dyn___get_value__impl__bemnlg($this));
            var tmp2_elvis_lhs = tmp1_elvis_lhs == null ? toDoubleSafe(_Dyn___get_value__impl__bemnlg($this)) : tmp1_elvis_lhs;
            tmp = tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs;
          } else {
            tmp = Dyn__toNumber_impl_rlhtn4($this, toString(_Dyn___get_value__impl__bemnlg($this)));
          }
        }
      }
    }
    return tmp;
  }
  function toString_1($this, value) {
    var tmp0_subject = value;
    var tmp;
    if (tmp0_subject == null) {
      tmp = '';
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        tmp = value;
      } else {
        if (!(tmp0_subject == null) ? typeof tmp0_subject === 'number' : false) {
          var tmp_0;
          if (value === numberToInt(value)) {
            tmp_0 = numberToInt(value).toString();
          } else {
            tmp_0 = toString(value);
          }
          tmp = tmp_0;
        } else {
          if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Iterable) : false) {
            tmp = '[' + joinToString(value, ', ', VOID, VOID, VOID, VOID, Dyn$toString$lambda($this)) + ']';
          } else {
            if (!(tmp0_subject == null) ? isInterface(tmp0_subject, Map) : false) {
              var tmp$ret$3;
              // Inline function 'kotlin.collections.map' call
              var tmp1_map = value;
              var tmp$ret$2;
              // Inline function 'kotlin.collections.mapTo' call
              var tmp0_mapTo = ArrayList_init_$Create$(tmp1_map.l());
              var tmp$ret$0;
              // Inline function 'kotlin.collections.iterator' call
              tmp$ret$0 = tmp1_map.d1().h();
              var tmp0_iterator = tmp$ret$0;
              while (tmp0_iterator.j()) {
                var item = tmp0_iterator.k();
                var tmp$ret$1;
                // Inline function 'korlibs.io.dynamic.Dyn.toString.<anonymous>' call
                tmp$ret$1 = quote(toString_1($this, item.e1())) + ': ' + toString_1($this, item.f1());
                tmp0_mapTo.b(tmp$ret$1);
              }
              tmp$ret$2 = tmp0_mapTo;
              tmp$ret$3 = tmp$ret$2;
              tmp = '{' + joinToString(tmp$ret$3, ', ') + '}';
            } else {
              tmp = toString(value);
            }
          }
        }
      }
    }
    return tmp;
  }
  function Dyn__toInt_impl_nu3u6q($this) {
    return numberToInt(Dyn__toNumber_impl_rlhtn4_0($this));
  }
  function Dyn__toLong_impl_mq8zcz($this) {
    return numberToLong(Dyn__toNumber_impl_rlhtn4_0($this));
  }
  function Dyn__toFloat_impl_hazkud($this) {
    return numberToDouble(Dyn__toNumber_impl_rlhtn4_0($this));
  }
  function Dyn__toBoolOrNull_impl_2dk92d($this) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (!(tmp0_subject == null) ? typeof tmp0_subject === 'boolean' : false) {
      tmp = _Dyn___get_value__impl__bemnlg($this);
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        tmp = (equals(_Dyn___get_value__impl__bemnlg($this), '1') ? true : equals(_Dyn___get_value__impl__bemnlg($this), 'true')) ? true : equals(_Dyn___get_value__impl__bemnlg($this), 'on');
      } else {
        if (isNumber(tmp0_subject)) {
          tmp = !(Dyn__toInt_impl_nu3u6q($this) === 0);
        } else {
          tmp = null;
        }
      }
    }
    return tmp;
  }
  function Dyn__toLongOrNull_impl_ws7y8j($this) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (isNumber(tmp0_subject)) {
      tmp = Dyn__toLong_impl_mq8zcz($this);
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        tmp = toLongSafe(_Dyn___get_value__impl__bemnlg($this));
      } else {
        tmp = null;
      }
    }
    return tmp;
  }
  function Dyn__toIntDefault_impl_x52w9z($this, default_0) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (isNumber(tmp0_subject)) {
      tmp = Dyn__toInt_impl_nu3u6q($this);
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        var tmp1_elvis_lhs = toIntSafe(_Dyn___get_value__impl__bemnlg($this), 10);
        tmp = tmp1_elvis_lhs == null ? default_0 : tmp1_elvis_lhs;
      } else {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function Dyn__toIntDefault$default_impl_hr0tbe($this, default_0, $super) {
    default_0 = default_0 === VOID ? 0 : default_0;
    return $super === VOID ? Dyn__toIntDefault_impl_x52w9z($this, default_0) : $super.p21.call(new Dyn($this), default_0);
  }
  function Dyn__toLongDefault_impl_auunl0($this, default_0) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (isNumber(tmp0_subject)) {
      tmp = Dyn__toLong_impl_mq8zcz($this);
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        var tmp1_elvis_lhs = toLongSafe(_Dyn___get_value__impl__bemnlg($this), 10);
        tmp = tmp1_elvis_lhs == null ? default_0 : tmp1_elvis_lhs;
      } else {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function Dyn__toLongDefault$default_impl_w5acm9($this, default_0, $super) {
    default_0 = default_0 === VOID ? new Long(0, 0) : default_0;
    return $super === VOID ? Dyn__toLongDefault_impl_auunl0($this, default_0) : $super.q21.call(new Dyn($this), default_0);
  }
  function Dyn__toFloatDefault_impl_tuvm0c($this, default_0) {
    var tmp0_subject = _Dyn___get_value__impl__bemnlg($this);
    var tmp;
    if (isNumber(tmp0_subject)) {
      tmp = Dyn__toFloat_impl_hazkud($this);
    } else {
      if (!(tmp0_subject == null) ? typeof tmp0_subject === 'string' : false) {
        tmp = Dyn__toFloat_impl_hazkud($this);
      } else {
        tmp = default_0;
      }
    }
    return tmp;
  }
  function _Dyn___get_str__impl__dup4cs($this) {
    return Dyn__toString_impl_9l0v3s($this);
  }
  function _Dyn___get_int__impl__sh8lhm($this) {
    return Dyn__toIntDefault$default_impl_hr0tbe($this);
  }
  function _Dyn___get_bool__impl__nv3iun($this) {
    var tmp0_elvis_lhs = Dyn__toBoolOrNull_impl_2dk92d($this);
    return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
  }
  function _Dyn___get_long__impl__wdxl7n($this) {
    return Dyn__toLongDefault$default_impl_w5acm9($this);
  }
  function Dyn__hashCode_impl_vc6rwn($this) {
    return $this == null ? 0 : hashCode($this);
  }
  function Dyn__equals_impl_sp125n($this, other) {
    if (!(other instanceof Dyn))
      return false;
    var tmp0_other_with_cast = other instanceof Dyn ? other.k21_1 : THROW_CCE();
    if (!equals($this, tmp0_other_with_cast))
      return false;
    return true;
  }
  function Dyn$toString$lambda(this$0) {
    return function (it) {
      return toString_1(this$0, it);
    };
  }
  function Dyn(value) {
    Companion_getInstance_10();
    this.k21_1 = value;
  }
  protoOf(Dyn).r21 = function (other) {
    return Dyn__compareTo_impl_7znl52(this.k21_1, other);
  };
  protoOf(Dyn).fc = function (other) {
    return Dyn__compareTo_impl_7znl52_0(this, other);
  };
  protoOf(Dyn).toString = function () {
    return Dyn__toString_impl_9l0v3s(this.k21_1);
  };
  protoOf(Dyn).hashCode = function () {
    return Dyn__hashCode_impl_vc6rwn(this.k21_1);
  };
  protoOf(Dyn).equals = function (other) {
    return Dyn__equals_impl_sp125n(this.k21_1, other);
  };
  function get_dyn(_this__u8e3s4) {
    return _Dyn___init__impl__6cuxxc(_this__u8e3s4);
  }
  function toIntSafe(_this__u8e3s4, radix) {
    radix = radix === VOID ? 10 : radix;
    return toIntOrNull_0(_this__u8e3s4, radix);
  }
  function toDoubleSafe(_this__u8e3s4) {
    return toDoubleOrNull(_this__u8e3s4);
  }
  function toLongSafe(_this__u8e3s4, radix) {
    radix = radix === VOID ? 10 : radix;
    return toLongOrNull(_this__u8e3s4, radix);
  }
  function get_dynApi() {
    _init_properties_DynApi_kt__g2fyyp();
    return dynApi;
  }
  var dynApi;
  function DynApi() {
  }
  var properties_initialized_DynApi_kt_kcoydf;
  function _init_properties_DynApi_kt__g2fyyp() {
    if (properties_initialized_DynApi_kt_kcoydf) {
    } else {
      properties_initialized_DynApi_kt_kcoydf = true;
      dynApi = DynamicInternal_getInstance();
    }
  }
  function normalize(_this__u8e3s4) {
    var path = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
    var schemeIndex = indexOf(path, ':');
    var tmp;
    if (schemeIndex >= 0) {
      var tmp_0;
      var tmp$ret$1;
      // Inline function 'kotlin.text.substring' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = path;
      tmp$ret$1 = tmp$ret$0.substring(schemeIndex);
      if (startsWith(tmp$ret$1, '://')) {
        tmp_0 = 3;
      } else {
        tmp_0 = 1;
      }
      var take = tmp_0;
      var tmp$ret$3;
      // Inline function 'kotlin.text.substring' call
      var tmp0_substring = schemeIndex + take | 0;
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = path;
      tmp$ret$3 = tmp$ret$2.substring(0, tmp0_substring);
      var tmp_1 = tmp$ret$3;
      var tmp$ret$5;
      // Inline function 'kotlin.text.substring' call
      var tmp1_substring = schemeIndex + take | 0;
      var tmp$ret$4;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$4 = path;
      tmp$ret$5 = tmp$ret$4.substring(tmp1_substring);
      tmp = tmp_1 + normalize(get_pathInfo(tmp$ret$5));
    } else {
      var path2 = replace(path, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47));
      var out = ArrayList_init_$Create$_0();
      // Inline function 'korlibs.datastructure.iterators.fastForEach' call
      var tmp2_fastForEach = split(path2, ['/']);
      var n = 0;
      while (n < tmp2_fastForEach.l()) {
        // Inline function 'korlibs.io.file.normalize.<anonymous>' call
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        var tmp3__anonymous__ufb84q = tmp2_fastForEach.n(tmp0);
        var tmp0_subject = tmp3__anonymous__ufb84q;
        switch (tmp0_subject) {
          case '':
          case '.':
            if (out.p()) {
              // Inline function 'kotlin.collections.plusAssign' call
              out.b('');
            } else
            ;
            break;
          case '..':
            var tmp$ret$6;
            // Inline function 'kotlin.collections.isNotEmpty' call
            tmp$ret$6 = !out.p();

            if (tmp$ret$6) {
              out.c4(out.l() - 1 | 0);
            }

            break;
          default:
            // Inline function 'kotlin.collections.plusAssign' call

            out.b(tmp3__anonymous__ufb84q);
            ;
            break;
        }
      }
      tmp = joinToString(out, '/');
    }
    return tmp;
  }
  function _PathInfo___init__impl__vpw96a(fullPath) {
    return fullPath;
  }
  function _PathInfo___get_fullPath__impl__21fx6x($this) {
    return $this;
  }
  function get_pathInfo(_this__u8e3s4) {
    return _PathInfo___init__impl__vpw96a(_this__u8e3s4);
  }
  function VfsNamed(pathInfo) {
    this.s21_1 = pathInfo;
  }
  protoOf(VfsNamed).t21 = function () {
    return this.s21_1;
  };
  function get_folder(_this__u8e3s4) {
    return get_folder_0(_this__u8e3s4.t21());
  }
  function combine(_this__u8e3s4, access) {
    var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
    var access_0 = _PathInfo___get_fullPath__impl__21fx6x(access);
    return get_pathInfo(isAbsolute(get_pathInfo(access_0)) ? normalize(get_pathInfo(access_0)) : normalize(get_pathInfo(base + '/' + access_0)));
  }
  function get_baseName(_this__u8e3s4) {
    return get_baseName_0(_this__u8e3s4.t21());
  }
  function lightCombine(_this__u8e3s4, access) {
    var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
    var access_0 = _PathInfo___get_fullPath__impl__21fx6x(access);
    var tmp;
    var tmp$ret$0;
    // Inline function 'kotlin.text.isNotEmpty' call
    tmp$ret$0 = charSequenceLength(base) > 0;
    if (tmp$ret$0) {
      tmp = trimEnd(base, charArrayOf([_Char___init__impl__6a9atx(47)])) + '/' + trim(access_0, charArrayOf([_Char___init__impl__6a9atx(47)]));
    } else {
      tmp = access_0;
    }
    var res = tmp;
    return get_pathInfo(res);
  }
  function get_parent(_this__u8e3s4) {
    return _PathInfo___init__impl__vpw96a(get_folder_0(_this__u8e3s4));
  }
  function get_folder_0(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.substring' call
    var tmp0_substring = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
    var tmp0_elvis_lhs = lastIndexOfOrNull(get_fullPathNormalized(_this__u8e3s4), _Char___init__impl__6a9atx(47));
    var tmp1_substring = tmp0_elvis_lhs == null ? 0 : tmp0_elvis_lhs;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_substring;
    tmp$ret$1 = tmp$ret$0.substring(0, tmp1_substring);
    return tmp$ret$1;
  }
  function isAbsolute(_this__u8e3s4) {
    var base = _PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4);
    var tmp$ret$0;
    // Inline function 'kotlin.text.isEmpty' call
    tmp$ret$0 = charSequenceLength(base) === 0;
    if (tmp$ret$0)
      return false;
    var b = substringBefore(replace(base, _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47)), _Char___init__impl__6a9atx(47));
    var tmp$ret$1;
    // Inline function 'kotlin.text.isEmpty' call
    tmp$ret$1 = charSequenceLength(b) === 0;
    if (tmp$ret$1)
      return true;
    if (contains(b, _Char___init__impl__6a9atx(58)))
      return true;
    return false;
  }
  function get_baseName_0(_this__u8e3s4) {
    return substringAfterLast(get_fullPathNormalized(_this__u8e3s4), _Char___init__impl__6a9atx(47));
  }
  function get_fullPathNormalized(_this__u8e3s4) {
    return replace(_PathInfo___get_fullPath__impl__21fx6x(_this__u8e3s4), _Char___init__impl__6a9atx(92), _Char___init__impl__6a9atx(47));
  }
  var Standard_BINARY_instance;
  var Standard_STRING_instance;
  var Standard_LONG_instance;
  var Standard_INT_instance;
  var Standard_entriesInitialized;
  function Standard_initEntries() {
    if (Standard_entriesInitialized)
      return Unit_getInstance();
    Standard_entriesInitialized = true;
    Standard_BINARY_instance = new Standard('BINARY', 0);
    Standard_STRING_instance = new Standard('STRING', 1);
    Standard_LONG_instance = new Standard('LONG', 2);
    Standard_INT_instance = new Standard('INT', 3);
  }
  function Companion_5() {
    Companion_instance_5 = this;
  }
  protoOf(Companion_5).u21 = function () {
    return Standard_STRING_getInstance();
  };
  var Companion_instance_5;
  function Companion_getInstance_11() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function Standard(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Standard_STRING_getInstance() {
    Standard_initEntries();
    return Standard_STRING_instance;
  }
  function Vfs$Proxy$listFlow$slambda$slambda($this_flow, this$0, resultContinuation) {
    this.d22_1 = $this_flow;
    this.e22_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(Vfs$Proxy$listFlow$slambda$slambda).h22 = function (it, $completion) {
    var tmp = this.i22(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs$Proxy$listFlow$slambda$slambda).g8 = function (p1, $completion) {
    return this.h22(p1 instanceof VfsFile ? p1 : THROW_CCE(), $completion);
  };
  protoOf(Vfs$Proxy$listFlow$slambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.e22_1.l22(this.f22_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.g22_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.d22_1.m11(this.g22_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(Vfs$Proxy$listFlow$slambda$slambda).i22 = function (it, completion) {
    var i = new Vfs$Proxy$listFlow$slambda$slambda(this.d22_1, this.e22_1, completion);
    i.f22_1 = it;
    return i;
  };
  function Vfs$Proxy$listFlow$slambda$slambda_0($this_flow, this$0, resultContinuation) {
    var i = new Vfs$Proxy$listFlow$slambda$slambda($this_flow, this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.h22(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function sam$kotlinx_coroutines_flow_FlowCollector$0(function_0) {
    this.m22_1 = function_0;
  }
  protoOf(sam$kotlinx_coroutines_flow_FlowCollector$0).m11 = function (value, $completion) {
    var tmp0 = this.m22_1(value, $completion);
    return tmp0;
  };
  function Vfs$Proxy$initOnce$slambda(this$0, resultContinuation) {
    this.v22_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(Vfs$Proxy$initOnce$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs$Proxy$initOnce$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(Vfs$Proxy$initOnce$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.v22_1.w22(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              var e = this.b8_1;
              var tmp1_error = this.v22_1.j22_1;
              var tmp0_log = Level_ERROR_getInstance();
              if (tmp1_error.q1n(tmp0_log)) {
                tmp1_error.r1n(tmp0_log, 'Error initializing ' + this.v22_1);
              }
              printStackTrace(e);
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            return Unit_getInstance();
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 3) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  protoOf(Vfs$Proxy$initOnce$slambda).g1p = function (completion) {
    var i = new Vfs$Proxy$initOnce$slambda(this.v22_1, completion);
    return i;
  };
  function Vfs$Proxy$initOnce$slambda_0(this$0, resultContinuation) {
    var i = new Vfs$Proxy$initOnce$slambda(this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function Vfs$Proxy$listFlow$slambda(this$0, $path, resultContinuation) {
    this.f23_1 = this$0;
    this.g23_1 = $path;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(Vfs$Proxy$listFlow$slambda).k23 = function ($this$flow, $completion) {
    var tmp = this.l23($this$flow, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs$Proxy$listFlow$slambda).g8 = function (p1, $completion) {
    return this.k23((!(p1 == null) ? isInterface(p1, FlowCollector) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(Vfs$Proxy$listFlow$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.y7_1 = 1;
            suspendResult = this.f23_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            this.y7_1 = 2;
            suspendResult = this.f23_1.n23(this.g23_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.i23_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.i23_1.t23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.j23_1 = suspendResult;
            this.y7_1 = 4;
            var tmp_0 = Vfs$Proxy$listFlow$slambda$slambda_0(this.h23_1, this.f23_1, null);
            suspendResult = this.j23_1.y10(new sam$kotlinx_coroutines_flow_FlowCollector$0(tmp_0), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            return Unit_getInstance();
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(Vfs$Proxy$listFlow$slambda).l23 = function ($this$flow, completion) {
    var i = new Vfs$Proxy$listFlow$slambda(this.f23_1, this.g23_1, completion);
    i.h23_1 = $this$flow;
    return i;
  };
  function Vfs$Proxy$listFlow$slambda_0(this$0, $path, resultContinuation) {
    var i = new Vfs$Proxy$listFlow$slambda(this$0, $path, resultContinuation);
    var l = function ($this$flow, $completion) {
      return i.k23($this$flow, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function $getUnderlyingUnscapedFileCOROUTINE$50(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.c24_1 = _this__u8e3s4;
    this.d24_1 = path;
  }
  protoOf($getUnderlyingUnscapedFileCOROUTINE$50).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.c24_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.e24_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.e24_1.n23(this.d24_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.f24_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.f24_1.g24(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $initOnceCOROUTINE$51(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.p24_1 = _this__u8e3s4;
  }
  protoOf($initOnceCOROUTINE$51).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            if (this.p24_1.k22_1 == null) {
              var tmp_0 = this.p24_1;
              var tmp_1 = this.s5();
              tmp_0.k22_1 = async_0(tmp_1, Vfs$Proxy$initOnce$slambda_0(this.p24_1, null));
            }

            this.y7_1 = 1;
            suspendResult = ensureNotNull(this.p24_1.k22_1).qr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return this.p24_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $openCOROUTINE$53(_this__u8e3s4, path, mode, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.y24_1 = _this__u8e3s4;
    this.z24_1 = path;
    this.a25_1 = mode;
  }
  protoOf($openCOROUTINE$53).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.y24_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.b25_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.b25_1.n23(this.z24_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.c25_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.c25_1.d25(this.a25_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readRangeCOROUTINE$54(_this__u8e3s4, path, range, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.m25_1 = _this__u8e3s4;
    this.n25_1 = path;
    this.o25_1 = range;
  }
  protoOf($readRangeCOROUTINE$54).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.m25_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.p25_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.p25_1.n23(this.n25_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.q25_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.q25_1.r25(this.o25_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $putCOROUTINE$55(_this__u8e3s4, path, content, attributes, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.a26_1 = _this__u8e3s4;
    this.b26_1 = path;
    this.c26_1 = content;
    this.d26_1 = attributes;
  }
  protoOf($putCOROUTINE$55).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.a26_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.e26_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.e26_1.n23(this.b26_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.f26_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.f26_1.g26(this.c26_1, copyToArray(this.d26_1).slice(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $statCOROUTINE$57(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.p26_1 = _this__u8e3s4;
    this.q26_1 = path;
  }
  protoOf($statCOROUTINE$57).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.p26_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.r26_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.r26_1.n23(this.q26_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.s26_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.s26_1.t26(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var ARGUMENT = suspendResult;
            return ARGUMENT.k27(this.p26_1.u26(this.q26_1));
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $deleteCOROUTINE$58(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.t27_1 = _this__u8e3s4;
    this.u27_1 = path;
  }
  protoOf($deleteCOROUTINE$58).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.t27_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.v27_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.v27_1.n23(this.u27_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.w27_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.w27_1.x27(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $mkdirCOROUTINE$62(_this__u8e3s4, path, attributes, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.g28_1 = _this__u8e3s4;
    this.h28_1 = path;
    this.i28_1 = attributes;
  }
  protoOf($mkdirCOROUTINE$62).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.g28_1.m23(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.j28_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.j28_1.n23(this.h28_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.k28_1 = suspendResult;
            this.y7_1 = 3;
            suspendResult = this.k28_1.l28(copyToArray(this.i28_1).slice(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function unsupported($this) {
    unsupported_0('unsupported for ' + getKClassFromExpression($this) + ' : ' + $this);
  }
  function Proxy() {
    Vfs.call(this);
    this.j22_1 = Companion_getInstance().b1n('Proxy');
    this.k22_1 = null;
  }
  protoOf(Proxy).l22 = function (_this__u8e3s4, $completion) {
    return this.u26(_this__u8e3s4.q23_1);
  };
  protoOf(Proxy).m28 = function (path, $completion) {
    var tmp = new $getUnderlyingUnscapedFileCOROUTINE$50(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).w22 = function ($completion) {
    return Unit_getInstance();
  };
  protoOf(Proxy).m23 = function ($completion) {
    var tmp = new $initOnceCOROUTINE$51(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).n28 = function (path, mode, $completion) {
    var tmp = new $openCOROUTINE$53(this, path, mode, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).o28 = function (path, range, $completion) {
    var tmp = new $readRangeCOROUTINE$54(this, path, range, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).p28 = function (path, content, attributes, $completion) {
    var tmp = new $putCOROUTINE$55(this, path, content, attributes, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).q28 = function (path, $completion) {
    var tmp = new $statCOROUTINE$57(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).r28 = function (path, $completion) {
    return flow(Vfs$Proxy$listFlow$slambda_0(this, path, null));
  };
  protoOf(Proxy).s28 = function (path, $completion) {
    var tmp = new $deleteCOROUTINE$58(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Proxy).t28 = function (path, attributes, $completion) {
    var tmp = new $mkdirCOROUTINE$62(this, path, attributes, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $readRangeCOROUTINE$41(_this__u8e3s4, path, range, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l29_1 = _this__u8e3s4;
    this.m29_1 = path;
    this.n29_1 = range;
  }
  protoOf($readRangeCOROUTINE$41).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.y7_1 = 1;
            suspendResult = this.l29_1.n28(this.m29_1, VfsOpenMode_READ_getInstance(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.o29_1 = suspendResult;
            this.p29_1 = null;
            this.z7_1 = 3;
            this.o29_1.u29_1 = this.n29_1.u();
            this.y7_1 = 2;
            var tmp0_minus = toLong(IntCompanionObject_getInstance().MAX_VALUE);
            var tmp1_min = tmp0_minus.l1(new Long(1, 0));
            var tmp2_min = this.n29_1.v().l1(this.n29_1.u());
            suspendResult = readBytesUpTo(this.o29_1, (tmp1_min.k1(tmp2_min) <= 0 ? tmp1_min : tmp2_min).t1() + 1 | 0, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.q29_1 = suspendResult;
            this.z7_1 = 6;
            this.y7_1 = 4;
            continue $sm;
          case 3:
            this.z7_1 = 6;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              this.r29_1 = this.b8_1;
              var tmp_1 = this;
              this.p29_1 = this.r29_1;
              tmp_1.q29_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 4:
            this.z7_1 = 6;
            this.s29_1 = this.q29_1;
            this.y7_1 = 5;
            suspendResult = this.o29_1.u1w(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            if (!(this.p29_1 == null))
              throw this.p29_1;
            var tmp_2 = this.s29_1;
            return (tmp_2 == null ? true : isObject(tmp_2)) ? tmp_2 : THROW_CCE();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $putCOROUTINE$42(_this__u8e3s4, path, content, attributes, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.h2a_1 = _this__u8e3s4;
    this.i2a_1 = path;
    this.j2a_1 = content;
    this.k2a_1 = attributes;
  }
  protoOf($putCOROUTINE$42).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.y7_1 = 1;
            suspendResult = this.h2a_1.n28(this.i2a_1, VfsOpenMode_CREATE_OR_TRUNCATE_getInstance(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.l2a_1 = suspendResult;
            this.m2a_1 = null;
            this.z7_1 = 3;
            this.y7_1 = 2;
            suspendResult = copyTo(this.j2a_1, this.l2a_1, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.n2a_1 = suspendResult;
            this.z7_1 = 6;
            this.y7_1 = 4;
            continue $sm;
          case 3:
            this.z7_1 = 6;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              this.o2a_1 = this.b8_1;
              var tmp_1 = this;
              this.m2a_1 = this.o2a_1;
              tmp_1.n2a_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 4:
            this.z7_1 = 6;
            this.p2a_1 = this.n2a_1;
            this.y7_1 = 5;
            suspendResult = this.l2a_1.u1w(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            if (!(this.m2a_1 == null))
              throw this.m2a_1;
            var tmp_2 = this.p2a_1;
            return (tmp_2 == null ? true : isObject(tmp_2)) ? tmp_2 : THROW_CCE();
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $listSimpleCOROUTINE$47(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.y2a_1 = _this__u8e3s4;
    this.z2a_1 = path;
  }
  protoOf($listSimpleCOROUTINE$47).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.y2a_1.r28(this.z2a_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.a2b_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = toList_2(this.a2b_1, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Vfs() {
  }
  protoOf(Vfs).u28 = function () {
    return '';
  };
  protoOf(Vfs).v28 = function (path) {
    return _PathInfo___get_fullPath__impl__21fx6x(lightCombine(get_pathInfo(this.u28()), get_pathInfo(path)));
  };
  protoOf(Vfs).w28 = function () {
    return new VfsFile(this, '');
  };
  protoOf(Vfs).p1b = function (path) {
    return this.w28().p1b(path);
  };
  protoOf(Vfs).u26 = function (path) {
    return this.w28().p1b(path);
  };
  protoOf(Vfs).u1w = function ($completion) {
    return Unit_getInstance();
  };
  protoOf(Vfs).x28 = function (path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = new VfsStat(this.u26(path), true, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, VOID, id);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.Vfs.createExistsStat.<anonymous>' call
    if (cache)
      tmp0_also.v26_1.s23_1 = tmp0_also;
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(Vfs).y28 = function (path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache, $super) {
    device = device === VOID ? new Long(-1, -1) : device;
    inode = inode === VOID ? new Long(-1, -1) : inode;
    mode = mode === VOID ? 511 : mode;
    owner = owner === VOID ? 'nobody' : owner;
    group = group === VOID ? 'nobody' : group;
    createTime = createTime === VOID ? Companion_getInstance_2().o17_1 : createTime;
    modifiedTime = modifiedTime === VOID ? Companion_getInstance_2().o17_1 : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? modifiedTime : lastAccessTime;
    extraInfo = extraInfo === VOID ? null : extraInfo;
    id = id === VOID ? null : id;
    cache = cache === VOID ? false : cache;
    return $super === VOID ? this.x28(path, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, id, cache) : $super.x28.call(this, path, isDirectory, size, device, inode, mode, owner, group, new DateTime(createTime), new DateTime(modifiedTime), new DateTime(lastAccessTime), extraInfo, id, cache);
  };
  protoOf(Vfs).z28 = function (path, extraInfo, cache) {
    return new VfsStat(this.u26(path), false, false, new Long(0, 0), new Long(-1, -1), new Long(-1, -1), 511, 'nobody', 'nobody', Companion_getInstance_2().o17_1, Companion_getInstance_2().o17_1, Companion_getInstance_2().o17_1, extraInfo);
  };
  protoOf(Vfs).a29 = function (path, extraInfo, cache, $super) {
    extraInfo = extraInfo === VOID ? null : extraInfo;
    cache = cache === VOID ? false : cache;
    return $super === VOID ? this.z28(path, extraInfo, cache) : $super.z28.call(this, path, extraInfo, cache);
  };
  protoOf(Vfs).n28 = function (path, mode, $completion) {
    unsupported(this);
  };
  protoOf(Vfs).o28 = function (path, range, $completion) {
    var tmp = new $readRangeCOROUTINE$41(this, path, range, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs).p28 = function (path, content, attributes, $completion) {
    var tmp = new $putCOROUTINE$42(this, path, content, attributes, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs).b29 = function (path, content, attributes, $completion) {
    var tmp0 = this.p28(path, openAsync(content), attributes, $completion);
    return tmp0;
  };
  protoOf(Vfs).q28 = function (path, $completion) {
    return this.a29(path);
  };
  protoOf(Vfs).c29 = function (path, $completion) {
    var tmp = new $listSimpleCOROUTINE$47(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Vfs).r28 = function (path, $completion) {
    unsupported(this);
  };
  protoOf(Vfs).t28 = function (path, attributes, $completion) {
    unsupported(this);
  };
  protoOf(Vfs).s28 = function (path, $completion) {
    unsupported(this);
  };
  protoOf(Vfs).m28 = function (path, $completion) {
    return FinalVfsFile_init_$Create$(this, path);
  };
  protoOf(Vfs).toString = function () {
    return get_portableSimpleName(getKClassFromExpression(this));
  };
  var VfsOpenMode_READ_instance;
  var VfsOpenMode_WRITE_instance;
  var VfsOpenMode_APPEND_instance;
  var VfsOpenMode_CREATE_OR_TRUNCATE_instance;
  var VfsOpenMode_CREATE_instance;
  var VfsOpenMode_CREATE_NEW_instance;
  var VfsOpenMode_entriesInitialized;
  function VfsOpenMode_initEntries() {
    if (VfsOpenMode_entriesInitialized)
      return Unit_getInstance();
    VfsOpenMode_entriesInitialized = true;
    VfsOpenMode_READ_instance = new VfsOpenMode('READ', 0, 'rb', false);
    VfsOpenMode_WRITE_instance = new VfsOpenMode('WRITE', 1, 'r+b', true, true);
    VfsOpenMode_APPEND_instance = new VfsOpenMode('APPEND', 2, 'a+b', true, true);
    VfsOpenMode_CREATE_OR_TRUNCATE_instance = new VfsOpenMode('CREATE_OR_TRUNCATE', 3, 'w+b', true, true, true);
    VfsOpenMode_CREATE_instance = new VfsOpenMode('CREATE', 4, 'w+b', true, true);
    VfsOpenMode_CREATE_NEW_instance = new VfsOpenMode('CREATE_NEW', 5, 'w+b', true);
  }
  function VfsOpenMode(name, ordinal, cmode, write, createIfNotExists, truncate) {
    createIfNotExists = createIfNotExists === VOID ? false : createIfNotExists;
    truncate = truncate === VOID ? false : truncate;
    Enum.call(this, name, ordinal);
    this.d2b_1 = cmode;
    this.e2b_1 = write;
    this.f2b_1 = createIfNotExists;
    this.g2b_1 = truncate;
  }
  function VfsStat(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id) {
    device = device === VOID ? new Long(-1, -1) : device;
    inode = inode === VOID ? new Long(-1, -1) : inode;
    mode = mode === VOID ? 511 : mode;
    owner = owner === VOID ? 'nobody' : owner;
    group = group === VOID ? 'nobody' : group;
    createTime = createTime === VOID ? Companion_getInstance_2().o17_1 : createTime;
    modifiedTime = modifiedTime === VOID ? createTime : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? modifiedTime : lastAccessTime;
    extraInfo = extraInfo === VOID ? null : extraInfo;
    kind = kind === VOID ? null : kind;
    id = id === VOID ? null : id;
    this.v26_1 = file;
    this.w26_1 = exists;
    this.x26_1 = isDirectory;
    this.y26_1 = size;
    this.z26_1 = device;
    this.a27_1 = inode;
    this.b27_1 = mode;
    this.c27_1 = owner;
    this.d27_1 = group;
    this.e27_1 = createTime;
    this.f27_1 = modifiedTime;
    this.g27_1 = lastAccessTime;
    this.h27_1 = extraInfo;
    this.i27_1 = kind;
    this.j27_1 = id;
  }
  protoOf(VfsStat).t21 = function () {
    return this.v26_1.t21();
  };
  protoOf(VfsStat).h2b = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = this.v26_1.i2b();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.VfsStat.<get-enrichedFile>.<anonymous>' call
    tmp0_also.s23_1 = this;
    tmp$ret$0 = tmp0_also;
    return tmp$ret$0;
  };
  protoOf(VfsStat).j2b = function (showFile) {
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    var tmp0_also = ArrayList_init_$Create$(16);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.VfsStat.toString.<anonymous>' call
    if (showFile) {
      tmp0_also.b('file=' + this.v26_1);
    } else {
      tmp0_also.b('file=' + this.v26_1.u28());
    }
    tmp0_also.b('exists=' + this.w26_1);
    tmp0_also.b('isDirectory=' + this.x26_1);
    tmp0_also.b('size=' + toString(this.y26_1));
    tmp0_also.b('device=' + toString(this.z26_1));
    tmp0_also.b('inode=' + toString(this.a27_1));
    tmp0_also.b('mode=' + this.b27_1);
    tmp0_also.b('owner=' + this.c27_1);
    tmp0_also.b('group=' + this.d27_1);
    tmp0_also.b('createTime=' + new DateTime(this.e27_1));
    tmp0_also.b('modifiedTime=' + new DateTime(this.f27_1));
    tmp0_also.b('lastAccessTime=' + new DateTime(this.g27_1));
    tmp0_also.b('extraInfo=' + toString_0(this.h27_1));
    if (!(this.i27_1 == null)) {
      tmp0_also.b('kind=' + this.i27_1);
    }
    tmp0_also.b('id=' + this.j27_1);
    tmp$ret$0 = tmp0_also;
    return 'VfsStat(' + joinToString(tmp$ret$0, ', ') + ')';
  };
  protoOf(VfsStat).toString = function () {
    return this.j2b(true);
  };
  protoOf(VfsStat).k2b = function (file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id) {
    return new VfsStat(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id);
  };
  protoOf(VfsStat).k27 = function (file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id, $super) {
    file = file === VOID ? this.v26_1 : file;
    exists = exists === VOID ? this.w26_1 : exists;
    isDirectory = isDirectory === VOID ? this.x26_1 : isDirectory;
    size = size === VOID ? this.y26_1 : size;
    device = device === VOID ? this.z26_1 : device;
    inode = inode === VOID ? this.a27_1 : inode;
    mode = mode === VOID ? this.b27_1 : mode;
    owner = owner === VOID ? this.c27_1 : owner;
    group = group === VOID ? this.d27_1 : group;
    createTime = createTime === VOID ? this.e27_1 : createTime;
    modifiedTime = modifiedTime === VOID ? this.f27_1 : modifiedTime;
    lastAccessTime = lastAccessTime === VOID ? this.g27_1 : lastAccessTime;
    extraInfo = extraInfo === VOID ? this.h27_1 : extraInfo;
    kind = kind === VOID ? this.i27_1 : kind;
    id = id === VOID ? this.j27_1 : id;
    return $super === VOID ? this.k2b(file, exists, isDirectory, size, device, inode, mode, owner, group, createTime, modifiedTime, lastAccessTime, extraInfo, kind, id) : $super.k2b.call(this, file, exists, isDirectory, size, device, inode, mode, owner, group, new DateTime(createTime), new DateTime(modifiedTime), new DateTime(lastAccessTime), extraInfo, kind, id);
  };
  protoOf(VfsStat).hashCode = function () {
    var result = this.v26_1.hashCode();
    result = imul(result, 31) + (this.w26_1 | 0) | 0;
    result = imul(result, 31) + (this.x26_1 | 0) | 0;
    result = imul(result, 31) + this.y26_1.hashCode() | 0;
    result = imul(result, 31) + this.z26_1.hashCode() | 0;
    result = imul(result, 31) + this.a27_1.hashCode() | 0;
    result = imul(result, 31) + this.b27_1 | 0;
    result = imul(result, 31) + getStringHashCode(this.c27_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.d27_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.e27_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.f27_1) | 0;
    result = imul(result, 31) + DateTime__hashCode_impl_yf0bc9(this.g27_1) | 0;
    result = imul(result, 31) + (this.h27_1 == null ? 0 : hashCode(this.h27_1)) | 0;
    result = imul(result, 31) + (this.i27_1 == null ? 0 : hashCode(this.i27_1)) | 0;
    result = imul(result, 31) + (this.j27_1 == null ? 0 : getStringHashCode(this.j27_1)) | 0;
    return result;
  };
  protoOf(VfsStat).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof VfsStat))
      return false;
    var tmp0_other_with_cast = other instanceof VfsStat ? other : THROW_CCE();
    if (!this.v26_1.equals(tmp0_other_with_cast.v26_1))
      return false;
    if (!(this.w26_1 === tmp0_other_with_cast.w26_1))
      return false;
    if (!(this.x26_1 === tmp0_other_with_cast.x26_1))
      return false;
    if (!this.y26_1.equals(tmp0_other_with_cast.y26_1))
      return false;
    if (!this.z26_1.equals(tmp0_other_with_cast.z26_1))
      return false;
    if (!this.a27_1.equals(tmp0_other_with_cast.a27_1))
      return false;
    if (!(this.b27_1 === tmp0_other_with_cast.b27_1))
      return false;
    if (!(this.c27_1 === tmp0_other_with_cast.c27_1))
      return false;
    if (!(this.d27_1 === tmp0_other_with_cast.d27_1))
      return false;
    if (!equals(this.e27_1, tmp0_other_with_cast.e27_1))
      return false;
    if (!equals(this.f27_1, tmp0_other_with_cast.f27_1))
      return false;
    if (!equals(this.g27_1, tmp0_other_with_cast.g27_1))
      return false;
    if (!equals(this.h27_1, tmp0_other_with_cast.h27_1))
      return false;
    if (!equals(this.i27_1, tmp0_other_with_cast.i27_1))
      return false;
    if (!(this.j27_1 == tmp0_other_with_cast.j27_1))
      return false;
    return true;
  };
  function Companion_6() {
    Companion_instance_6 = this;
  }
  var Companion_instance_6;
  function Companion_getInstance_12() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function VfsCachedStatContext(stat) {
    Companion_getInstance_12();
    this.l2b_1 = stat;
  }
  protoOf(VfsCachedStatContext).e1 = function () {
    return Companion_getInstance_12();
  };
  function VfsOpenMode_READ_getInstance() {
    VfsOpenMode_initEntries();
    return VfsOpenMode_READ_instance;
  }
  function VfsOpenMode_CREATE_OR_TRUNCATE_getInstance() {
    VfsOpenMode_initEntries();
    return VfsOpenMode_CREATE_OR_TRUNCATE_instance;
  }
  function $readStringCOROUTINE$72(_this__u8e3s4, charset, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.u2b_1 = _this__u8e3s4;
    this.v2b_1 = charset;
  }
  protoOf($readStringCOROUTINE$72).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.u2b_1.w2b(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return toString_2(ARGUMENT, this.v2b_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $writeStringCOROUTINE$73(_this__u8e3s4, data, attributes, charset, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.f2c_1 = _this__u8e3s4;
    this.g2c_1 = data;
    this.h2c_1 = attributes;
    this.i2c_1 = charset;
  }
  protoOf($writeStringCOROUTINE$73).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.f2c_1.j2c(toByteArray(this.g2c_1, this.i2c_1), arrayConcat([this.h2c_1, [Companion_getInstance_11().u21()]]), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readAsSyncStreamCOROUTINE$74(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s2c_1 = _this__u8e3s4;
  }
  protoOf($readAsSyncStreamCOROUTINE$74).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.s2c_1.w2b(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return openSync(ARGUMENT);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $statCOROUTINE$75(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b2d_1 = _this__u8e3s4;
  }
  protoOf($statCOROUTINE$75).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.c2d_1 = this.b2d_1.s23_1;
            if (this.c2d_1 == null) {
              this.y7_1 = 1;
              suspendResult = this.b2d_1.p23_1.q28(this.b2d_1.q23_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.d2d_1 = this.c2d_1;
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.d2d_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return this.d2d_1;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $existsCOROUTINE$77(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.m2d_1 = _this__u8e3s4;
  }
  protoOf($existsCOROUTINE$77).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.m2d_1.p23_1.q28(this.m2d_1.q23_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            this.n2d_1 = ARGUMENT.w26_1;
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              var e = this.b8_1;
              var tmp_1 = this;
              if (false) {
                printStackTrace(e);
              }
              tmp_1.n2d_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            var tmp0_elvis_lhs = this.n2d_1;
            return tmp0_elvis_lhs == null ? false : tmp0_elvis_lhs;
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 3) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  function VfsFile(vfs, path) {
    VfsNamed.call(this, get_pathInfo(path));
    this.p23_1 = vfs;
    this.q23_1 = path;
    this.r23_1 = new Mixin();
    this.s23_1 = null;
  }
  protoOf(VfsFile).d1i = function (_set____db54di) {
    this.r23_1.d1i(_set____db54di);
  };
  protoOf(VfsFile).e1i = function () {
    return this.r23_1.e1i();
  };
  protoOf(VfsFile).np = function () {
    return new VfsFile(this.p23_1, get_folder(this));
  };
  protoOf(VfsFile).w28 = function () {
    return this.p23_1.w28();
  };
  protoOf(VfsFile).u28 = function () {
    return this.p23_1.v28(this.q23_1);
  };
  protoOf(VfsFile).p1b = function (path) {
    return new VfsFile(this.p23_1, _PathInfo___get_fullPath__impl__21fx6x(combine(get_pathInfo(this.q23_1), get_pathInfo(path))));
  };
  protoOf(VfsFile).g26 = function (content, attributes, $completion) {
    var tmp0 = this.p23_1.p28(this.q23_1, content, toList_3(attributes), $completion);
    return tmp0;
  };
  protoOf(VfsFile).j2c = function (data, attributes, $completion) {
    var tmp0 = this.p23_1.b29(this.q23_1, data, toList_3(attributes), $completion);
    return tmp0;
  };
  protoOf(VfsFile).d25 = function (mode, $completion) {
    var tmp0 = this.p23_1.n28(this.q23_1, mode, $completion);
    return tmp0;
  };
  protoOf(VfsFile).r25 = function (range, $completion) {
    var tmp0 = this.p23_1.o28(this.q23_1, range, $completion);
    return tmp0;
  };
  protoOf(VfsFile).o2d = function (range, $completion) {
    var tmp0 = this.p23_1.o28(this.q23_1, toLongRange(range), $completion);
    return tmp0;
  };
  protoOf(VfsFile).p2d = function ($completion) {
    var tmp0 = this.p23_1.o28(this.q23_1, get_LONG_ZERO_TO_MAX_RANGE(), $completion);
    return tmp0;
  };
  protoOf(VfsFile).w2b = function ($completion) {
    var tmp0 = this.p2d($completion);
    return tmp0;
  };
  protoOf(VfsFile).q2d = function ($completion) {
    var tmp0 = this.p2d($completion);
    return tmp0;
  };
  protoOf(VfsFile).r2d = function (charset, $completion) {
    var tmp = new $readStringCOROUTINE$72(this, charset, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(VfsFile).s2d = function (charset, $completion, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.r2d(charset, $completion) : $super.r2d.call(this, charset, $completion);
  };
  protoOf(VfsFile).t2d = function (data, attributes, charset, $completion) {
    var tmp = new $writeStringCOROUTINE$73(this, data, attributes, charset, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(VfsFile).u2d = function (data, attributes, charset, $completion, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.t2d(data, attributes, charset, $completion) : $super.t2d.call(this, data, attributes, charset, $completion);
  };
  protoOf(VfsFile).v2d = function ($completion) {
    var tmp = new $readAsSyncStreamCOROUTINE$74(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(VfsFile).t26 = function ($completion) {
    var tmp = new $statCOROUTINE$75(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(VfsFile).w2d = function ($completion) {
    var tmp = new $existsCOROUTINE$77(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(VfsFile).x27 = function ($completion) {
    var tmp0 = this.p23_1.s28(this.q23_1, $completion);
    return tmp0;
  };
  protoOf(VfsFile).x2d = function (attributes, $completion) {
    var tmp0 = this.p23_1.t28(this.q23_1, attributes, $completion);
    return tmp0;
  };
  protoOf(VfsFile).l28 = function (attributes, $completion) {
    var tmp0 = this.x2d(toList_3(attributes), $completion);
    return tmp0;
  };
  protoOf(VfsFile).y2d = function ($completion) {
    var tmp0 = this.p23_1.c29(this.q23_1, $completion);
    return tmp0;
  };
  protoOf(VfsFile).t23 = function ($completion) {
    var tmp0 = this.p23_1.r28(this.q23_1, $completion);
    return tmp0;
  };
  protoOf(VfsFile).z2d = function () {
    return Companion_getInstance_13().a2e(this);
  };
  protoOf(VfsFile).g24 = function ($completion) {
    var tmp0 = this.p23_1.m28(this.q23_1, $completion);
    return tmp0;
  };
  protoOf(VfsFile).toString = function () {
    return '' + this.p23_1 + '[' + this.q23_1 + ']';
  };
  protoOf(VfsFile).b2e = function (vfs, path) {
    return new VfsFile(vfs, path);
  };
  protoOf(VfsFile).i2b = function (vfs, path, $super) {
    vfs = vfs === VOID ? this.p23_1 : vfs;
    path = path === VOID ? this.q23_1 : path;
    return $super === VOID ? this.b2e(vfs, path) : $super.b2e.call(this, vfs, path);
  };
  protoOf(VfsFile).hashCode = function () {
    var result = hashCode(this.p23_1);
    result = imul(result, 31) + getStringHashCode(this.q23_1) | 0;
    return result;
  };
  protoOf(VfsFile).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof VfsFile))
      return false;
    var tmp0_other_with_cast = other instanceof VfsFile ? other : THROW_CCE();
    if (!equals(this.p23_1, tmp0_other_with_cast.p23_1))
      return false;
    if (!(this.q23_1 === tmp0_other_with_cast.q23_1))
      return false;
    return true;
  };
  function FinalVfsFile_init_$Init$(vfs, path, $this) {
    FinalVfsFile.call($this, vfs.p1b(path));
    return $this;
  }
  function FinalVfsFile_init_$Create$(vfs, path) {
    return FinalVfsFile_init_$Init$(vfs, path, objectCreate(protoOf(FinalVfsFile)));
  }
  function FinalVfsFile(file) {
    this.c2e_1 = file;
  }
  protoOf(FinalVfsFile).d2e = function () {
    return this.c2e_1.p23_1;
  };
  protoOf(FinalVfsFile).e2e = function () {
    return this.c2e_1.q23_1;
  };
  protoOf(FinalVfsFile).toString = function () {
    return 'FinalVfsFile(file=' + this.c2e_1 + ')';
  };
  protoOf(FinalVfsFile).hashCode = function () {
    return this.c2e_1.hashCode();
  };
  protoOf(FinalVfsFile).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof FinalVfsFile))
      return false;
    var tmp0_other_with_cast = other instanceof FinalVfsFile ? other : THROW_CCE();
    if (!this.c2e_1.equals(tmp0_other_with_cast.c2e_1))
      return false;
    return true;
  };
  function withCatalogJail(_this__u8e3s4) {
    return (new CatalogVfs(_this__u8e3s4.z2d())).w28();
  }
  function $getLengthCOROUTINE$95(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.n2e_1 = _this__u8e3s4;
  }
  protoOf($getLengthCOROUTINE$95).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp0_safe_receiver = this.n2e_1.q2e_1;
            if ((tmp0_safe_receiver == null ? null : tmp0_safe_receiver.w26_1) === true) {
              this.o2e_1 = this.n2e_1.q2e_1.y26_1;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.n2e_1.p2e_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.o2e_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return this.o2e_1;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function CatalogVfs$open$slambda(this$0, $path, $mode, resultContinuation) {
    this.z2e_1 = this$0;
    this.a2f_1 = $path;
    this.b2f_1 = $mode;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(CatalogVfs$open$slambda).d2f = function ($this$withContext, $completion) {
    var tmp = this.d1o($this$withContext, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs$open$slambda).g8 = function (p1, $completion) {
    return this.d2f((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(CatalogVfs$open$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = protoOf(Proxy).n28.call(this.z2e_1, this.a2f_1, this.b2f_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return ARGUMENT.t29_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(CatalogVfs$open$slambda).d1o = function ($this$withContext, completion) {
    var i = new CatalogVfs$open$slambda(this.z2e_1, this.a2f_1, this.b2f_1, completion);
    i.c2f_1 = $this$withContext;
    return i;
  };
  function CatalogVfs$open$slambda_0(this$0, $path, $mode, resultContinuation) {
    var i = new CatalogVfs$open$slambda(this$0, $path, $mode, resultContinuation);
    var l = function ($this$withContext, $completion) {
      return i.d2f($this$withContext, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function CatalogVfs$open$2($base, $fstat) {
    this.p2e_1 = $base;
    this.q2e_1 = $fstat;
    AsyncStreamBase.call(this);
  }
  protoOf(CatalogVfs$open$2).e2f = function (position, buffer, offset, len, $completion) {
    var tmp0 = this.p2e_1.e2f(position, buffer, offset, len, $completion);
    return tmp0;
  };
  protoOf(CatalogVfs$open$2).f2f = function (position, buffer, offset, len, $completion) {
    var tmp0 = this.p2e_1.f2f(position, buffer, offset, len, $completion);
    return tmp0;
  };
  protoOf(CatalogVfs$open$2).h1y = function ($completion) {
    var tmp = new $getLengthCOROUTINE$95(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs$open$2).u1w = function ($completion) {
    var tmp0 = this.p2e_1.u1w($completion);
    return tmp0;
  };
  function $openCOROUTINE$88(_this__u8e3s4, path, mode, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.o2f_1 = _this__u8e3s4;
    this.p2f_1 = path;
    this.q2f_1 = mode;
  }
  protoOf($openCOROUTINE$88).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.o2f_1.y2f(this.p2f_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.r2f_1 = suspendResult;
            this.y7_1 = 2;
            var tmp_0 = new VfsCachedStatContext(this.r2f_1);
            suspendResult = withContext(tmp_0, CatalogVfs$open$slambda_0(this.o2f_1, this.p2f_1, this.q2f_1, null), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var base = suspendResult;
            return buffered(toAsyncStream(new CatalogVfs$open$2(base, this.r2f_1)));
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $statCOROUTINE$89(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.h2g_1 = _this__u8e3s4;
    this.i2g_1 = path;
  }
  protoOf($statCOROUTINE$89).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.h2g_1.y2f(this.i2g_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp0_elvis_lhs = suspendResult;
            return tmp0_elvis_lhs == null ? this.h2g_1.a29(normalize(_PathInfo___init__impl__vpw96a(this.i2g_1)), VOID, true) : tmp0_elvis_lhs;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $statOrNullCOROUTINE$90(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.r2g_1 = _this__u8e3s4;
    this.s2g_1 = path;
  }
  protoOf($statOrNullCOROUTINE$90).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.t2g_1 = normalize(_PathInfo___init__impl__vpw96a(this.s2g_1));
            if (this.t2g_1 === '/' ? true : this.t2g_1 === '') {
              return this.r2g_1.y28('/', true, new Long(0, 0), VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, true);
            }

            this.u2g_1 = get_baseName_0(_PathInfo___init__impl__vpw96a(this.t2g_1));
            this.y7_1 = 1;
            suspendResult = this.r2g_1.a2h(_PathInfo___get_fullPath__impl__21fx6x(get_parent(_PathInfo___init__impl__vpw96a(this.t2g_1))), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.v2g_1 = suspendResult;
            if (this.v2g_1 == null) {
              var tmp_0 = this;
              tmp_0.x2g_1 = this.r2g_1.w2f_1;
              this.y2g_1 = this.x2g_1.h3(this.s2g_1);
              if (this.y2g_1 == null) {
                this.y7_1 = 2;
                suspendResult = this.r2g_1.u2f_1.p1b(this.s2g_1).t26(this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              } else {
                var tmp_1 = this;
                tmp_1.z2g_1 = this.y2g_1;
                this.y7_1 = 3;
                continue $sm;
              }
            } else {
              this.w2g_1 = this.v2g_1;
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 2:
            var answer = suspendResult;
            this.x2g_1.e5(this.s2g_1, answer);
            ;
            this.z2g_1 = answer;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            var tmp_2 = this;
            return this.z2g_1;
          case 4:
            var info = this.w2g_1;
            var tmp1_elvis_lhs = info.h3(this.u2g_1);
            return tmp1_elvis_lhs == null ? this.r2g_1.a29(this.t2g_1, VOID, true) : tmp1_elvis_lhs;
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $listFlowCOROUTINE$91(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.j2h_1 = _this__u8e3s4;
    this.k2h_1 = path;
  }
  protoOf($listFlowCOROUTINE$91).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.j2h_1.l2h(this.k2h_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp1_map = suspendResult;
            var tmp0_mapTo = ArrayList_init_$Create$(tmp1_map.l());
            var tmp0_iterator = tmp1_map.d1().h();
            while (tmp0_iterator.j()) {
              var item = tmp0_iterator.k();
              tmp0_mapTo.b(item.f1().h2b());
            }

            var ARGUMENT = tmp0_mapTo;
            return asFlow(ARGUMENT);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $cachedListSimpleStatsCOROUTINE$92(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.u2h_1 = _this__u8e3s4;
    this.v2h_1 = path;
  }
  protoOf($cachedListSimpleStatsCOROUTINE$92).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.u2h_1.a2h(this.v2h_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp0_elvis_lhs = suspendResult;
            return tmp0_elvis_lhs == null ? emptyMap() : tmp0_elvis_lhs;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $cachedListSimpleStatsOrNullCOROUTINE$93(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.e2i_1 = _this__u8e3s4;
    this.f2i_1 = path;
  }
  protoOf($cachedListSimpleStatsOrNullCOROUTINE$93).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.g2i_1 = normalize(_PathInfo___init__impl__vpw96a(this.f2i_1));
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.i2i_1 = this.e2i_1.x2f_1;
            var tmp_1 = this;
            tmp_1.j2i_1 = this.i2i_1.get(this.g2i_1);
            if (!(this.j2i_1 == null)) {
              this.h2i_1 = this.j2i_1;
              this.y7_1 = 4;
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 2:
            this.y7_1 = 3;
            suspendResult = this.e2i_1.k2i(this.g2i_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var out = suspendResult;
            this.i2i_1.set(this.g2i_1, out);
            this.h2i_1 = out;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 4;
            continue $sm;
          case 4:
            return this.h2i_1;
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $listSimpleStatsOrNullCOROUTINE$94(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.t2i_1 = _this__u8e3s4;
    this.u2i_1 = path;
  }
  protoOf($listSimpleStatsOrNullCOROUTINE$94).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.t2i_1.u2f_1.p1b(this.u2i_1).p1b('$catalog.json').s2d(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.v2i_1 = suspendResult;
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof Error) {
              var e = this.b8_1;
              var tmp_1 = this;
              if (!(e instanceof FileNotFoundException)) {
                var tmp1_error = this.t2i_1.v2f_1;
                var tmp0_log = Level_ERROR_getInstance();
                if (tmp1_error.q1n(tmp0_log)) {
                  tmp1_error.r1n(tmp0_log, e);
                }
              }
              return null;
            } else {
              throw this.b8_1;
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            var catalogJsonString = this.v2i_1;
            var data = get_dyn(Json_getInstance().w2i(catalogJsonString));
            var tmp0_subject = _Dyn___get_value__impl__bemnlg(data);
            if (!(tmp0_subject == null) ? isInterface(tmp0_subject, List) : false) {
              var tmp3_map = _Dyn___get_list__impl__qpwjp(data);
              var tmp2_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp3_map, 10));
              var tmp0_iterator = tmp3_map.h();
              while (tmp0_iterator.j()) {
                var item = tmp0_iterator.k().k21_1;
                var localName = get_baseName_0(_PathInfo___init__impl__vpw96a(_Dyn___get_str__impl__dup4cs(Dyn__get_impl_6l2p80_0(item, 'name'))));
                tmp2_mapTo.b(this.t2i_1.y28(this.u2i_1 + '/' + localName, _Dyn___get_bool__impl__nv3iun(Dyn__get_impl_6l2p80_0(item, 'isDirectory')), _Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(item, 'size')), VOID, VOID, VOID, VOID, VOID, Companion_getInstance_2().r17(_Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(item, 'createTime'))), Companion_getInstance_2().r17(_Dyn___get_long__impl__wdxl7n(Dyn__get_impl_6l2p80_0(item, 'modifiedTime'))), VOID, VOID, VOID, true));
              }
              var tmp5_associateBy = tmp2_mapTo;
              var capacity = coerceAtLeast(mapCapacity(collectionSizeOrDefault(tmp5_associateBy, 10)), 16);
              var tmp4_associateByTo = LinkedHashMap_init_$Create$_0(capacity);
              var tmp0_iterator_0 = tmp5_associateBy.h();
              while (tmp0_iterator_0.j()) {
                var element = tmp0_iterator_0.k();
                tmp4_associateByTo.e5(get_baseName(element), element);
              }
              return tmp4_associateByTo;
            } else {
              var out = LinkedHashMap_init_$Create$();
              var tmp1_iterator = _Dyn___get_keys__impl__o4xmor(data).h();
              while (tmp1_iterator.j()) {
                var key = tmp1_iterator.k().k21_1;
                var fileName = _Dyn___get_str__impl__dup4cs(key);
                var info = Dyn__get_impl_6l2p80(data, key);
                var tmp2_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 0));
                var size = tmp2_elvis_lhs == null ? new Long(0, 0) : tmp2_elvis_lhs;
                var tmp3_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 1));
                var creationTime = tmp3_elvis_lhs == null ? new Long(0, 0) : tmp3_elvis_lhs;
                var tmp4_elvis_lhs = Dyn__toLongOrNull_impl_ws7y8j(Dyn__get_impl_6l2p80_0(info, 2));
                var modifiedTime = tmp4_elvis_lhs == null ? creationTime : tmp4_elvis_lhs;
                var isDirectory = endsWith(fileName, '/');
                var baseName = trimEnd(fileName, charArrayOf([_Char___init__impl__6a9atx(47)]));
                var tmp6_set = this.t2i_1.y28(this.u2i_1 + '/' + baseName, isDirectory, size, VOID, VOID, VOID, VOID, VOID, Companion_getInstance_2().r17(creationTime), Companion_getInstance_2().r17(modifiedTime), VOID, VOID, VOID, true);
                out.e5(baseName, tmp6_set);
              }
              return out;
            }

            return Unit_getInstance();
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 3) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  function CatalogVfs(parent) {
    Proxy.call(this);
    this.u2f_1 = parent;
    this.v2f_1 = Companion_getInstance().b1n('CatalogVfs');
    this.w2f_1 = LinkedHashMap_init_$Create$();
    this.x2f_1 = FastStringMap();
  }
  protoOf(CatalogVfs).n28 = function (path, mode, $completion) {
    var tmp = new $openCOROUTINE$88(this, path, mode, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).n23 = function (path, $completion) {
    return this.u2f_1.p1b(path);
  };
  protoOf(CatalogVfs).q28 = function (path, $completion) {
    var tmp = new $statCOROUTINE$89(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).y2f = function (path, $completion) {
    var tmp = new $statOrNullCOROUTINE$90(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).r28 = function (path, $completion) {
    var tmp = new $listFlowCOROUTINE$91(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).l2h = function (path, $completion) {
    var tmp = new $cachedListSimpleStatsCOROUTINE$92(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).a2h = function (path, $completion) {
    var tmp = new $cachedListSimpleStatsOrNullCOROUTINE$93(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CatalogVfs).k2i = function (path, $completion) {
    var tmp = new $listSimpleStatsOrNullCOROUTINE$94(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function Companion_7() {
    Companion_instance_7 = this;
  }
  protoOf(Companion_7).a2e = function (jailRoot) {
    return (new JailVfs(jailRoot, Unit_getInstance())).w28();
  };
  var Companion_instance_7;
  function Companion_getInstance_13() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function JailVfs(jailRoot, dummy) {
    Companion_getInstance_13();
    Proxy.call(this);
    this.z2i_1 = jailRoot;
    this.a2j_1 = normalize(this.z2i_1.t21());
  }
  protoOf(JailVfs).n23 = function (path, $completion) {
    return this.z2i_1.p1b(trim(normalize(get_pathInfo(path)), charArrayOf([_Char___init__impl__6a9atx(47)])));
  };
  protoOf(JailVfs).l22 = function (_this__u8e3s4, $completion) {
    var outPath = normalize(get_pathInfo(_this__u8e3s4.q23_1));
    if (!startsWith(outPath, this.a2j_1))
      throw UnsupportedOperationException_init_$Create$('Jail not base root : ' + _this__u8e3s4.q23_1 + ' | ' + this.a2j_1);
    var tmp$ret$1;
    // Inline function 'kotlin.text.substring' call
    var tmp0_substring = this.a2j_1.length;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = outPath;
    tmp$ret$1 = tmp$ret$0.substring(tmp0_substring);
    return this.u26(tmp$ret$1);
  };
  protoOf(JailVfs).u28 = function () {
    return this.z2i_1.u28();
  };
  protoOf(JailVfs).toString = function () {
    return 'JailVfs(' + this.z2i_1 + ')';
  };
  var resourcesVfsDebug;
  function StandardVfs() {
  }
  protoOf(StandardVfs).b2j = function () {
    throw new NotImplementedError();
  };
  function Companion_8() {
    Companion_instance_8 = this;
  }
  var Companion_instance_8;
  function Companion_getInstance_14() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function LocalVfs() {
    Companion_getInstance_14();
    Vfs.call(this);
  }
  protoOf(LocalVfs).toString = function () {
    return 'LocalVfs';
  };
  function get_resourcesVfs() {
    return get_standardVfs().b2j();
  }
  function get_localCurrentDirVfs() {
    return get_applicationVfs();
  }
  function Mountable() {
  }
  function MountableVfs(closeMounts, callback, $completion) {
    closeMounts = closeMounts === VOID ? false : closeMounts;
    var tmp = new $MountableVfsCOROUTINE$113(closeMounts, callback, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function unmountInternal($this, folder) {
    removeAll($this.r2j_1, MountableVfs$unmountInternal$lambda(folder));
  }
  function resort($this) {
    // Inline function 'kotlin.collections.sortByDescending' call
    var tmp0_sortByDescending = $this.r2j_1;
    if (tmp0_sortByDescending.l() > 1) {
      var tmp$ret$0;
      // Inline function 'kotlin.comparisons.compareByDescending' call
      var tmp = MountableVfs$resort$lambda;
      tmp$ret$0 = new sam$kotlin_Comparator$0(tmp);
      sortWith(tmp0_sortByDescending, tmp$ret$0);
    }
  }
  function sam$kotlin_Comparator$0(function_0) {
    this.s2j_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0).l8 = function (a, b) {
    return this.s2j_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function MountableVfs$unmountInternal$lambda($folder) {
    return function (it) {
      return it.a5_1 === normalize(get_pathInfo($folder));
    };
  }
  function MountableVfs$resort$lambda(a, b) {
    var tmp$ret$2;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$0;
    // Inline function 'korlibs.io.file.std.MountableVfs.resort.<anonymous>' call
    tmp$ret$0 = b.a5_1.length;
    var tmp = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'korlibs.io.file.std.MountableVfs.resort.<anonymous>' call
    tmp$ret$1 = a.a5_1.length;
    tmp$ret$2 = compareValues(tmp, tmp$ret$1);
    return tmp$ret$2;
  }
  function $closeCOROUTINE$114(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b2k_1 = _this__u8e3s4;
  }
  protoOf($closeCOROUTINE$114).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            if (this.b2k_1.q2j_1) {
              var tmp_0 = this;
              tmp_0.c2k_1 = this.b2k_1.r2j_1;
              this.d2k_1 = 0;
              this.y7_1 = 1;
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 1:
            if (!(this.d2k_1 < this.c2k_1.l())) {
              this.y7_1 = 3;
              continue $sm;
            }

            var tmp_1 = this;
            var tmp0 = this.d2k_1;
            this.d2k_1 = tmp0 + 1 | 0;
            tmp_1.e2k_1 = this.c2k_1.n(tmp0);
            this.y7_1 = 2;
            suspendResult = this.e2k_1.b5_1.p23_1.u1w(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            this.y7_1 = 4;
            continue $sm;
          case 4:
            return Unit_getInstance();
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function MountableVfs_0(closeMounts) {
    Proxy.call(this);
    this.q2j_1 = closeMounts;
    this.r2j_1 = ArrayList_init_$Create$_0();
  }
  protoOf(MountableVfs_0).u1w = function ($completion) {
    var tmp = new $closeCOROUTINE$114(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MountableVfs_0).c2j = function (folder, file) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.file.std.MountableVfs.mount.<anonymous>' call
    unmountInternal(this, folder);
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.r2j_1;
    var tmp1_plusAssign = to(normalize(get_pathInfo(folder)), file);
    tmp0_plusAssign.b(tmp1_plusAssign);
    resort(this);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(MountableVfs_0).n23 = function (path, $completion) {
    var rpath = normalize(get_pathInfo(path));
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = this.r2j_1;
    var n = 0;
    while (n < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.io.file.std.MountableVfs.access.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0);
      var base = tmp1__anonymous__uwfjfc.c5();
      var file = tmp1__anonymous__uwfjfc.d5();
      if (startsWith(rpath, base)) {
        var tmp$ret$1;
        // Inline function 'kotlin.text.substring' call
        var tmp0_substring = base.length;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = rpath;
        tmp$ret$1 = tmp$ret$0.substring(tmp0_substring);
        return file.p1b(tmp$ret$1);
      }
    }
    throw new FileNotFoundException(path);
  };
  protoOf(MountableVfs_0).toString = function () {
    return 'MountableVfs';
  };
  function $MountableVfsCOROUTINE$113(closeMounts, callback, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l2j_1 = closeMounts;
    this.m2j_1 = callback;
  }
  protoOf($MountableVfsCOROUTINE$113).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            var tmp_0 = this;
            tmp_0.n2j_1 = new MountableVfs_0(this.l2j_1);
            this.y7_1 = 1;
            suspendResult = this.m2j_1(this.n2j_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = this.n2j_1;
            return ARGUMENT.w28();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function StandardPathsBase() {
  }
  function UrlVfs(url, client, failFromStatus) {
    client = client === VOID ? createHttpClient() : client;
    failFromStatus = failFromStatus === VOID ? true : failFromStatus;
    return (new UrlVfs_0(url.r2k(VOID, VOID, VOID, VOID, '', null).s2k(), Unit_getInstance(), client, failFromStatus)).p1b(url.k2k_1);
  }
  function $readCOROUTINE$122(_this__u8e3s4, position, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b2l_1 = _this__u8e3s4;
    this.c2l_1 = position;
    this.d2l_1 = buffer;
    this.e2l_1 = offset;
    this.f2l_1 = len;
  }
  protoOf($readCOROUTINE$122).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            if (this.f2l_1 === 0)
              return 0;
            this.y7_1 = 1;
            suspendResult = _readRangeBase(this.b2l_1.l2l_1, this.b2l_1.m2l_1, until_0(this.c2l_1, this.c2l_1.b9(toLong(this.f2l_1))), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.g2l_1 = suspendResult;
            this.h2l_1 = this.g2l_1.s2l_1;
            this.i2l_1 = this.e2l_1;
            this.j2l_1 = this.f2l_1;
            this.k2l_1 = 0;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            if (!(this.j2l_1 > 0)) {
              this.y7_1 = 5;
              continue $sm;
            }

            this.y7_1 = 3;
            suspendResult = this.h2l_1.t1y(this.d2l_1, this.i2l_1, this.j2l_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            var read = suspendResult;
            if (read < 0 ? this.k2l_1 === 0 : false)
              return read;
            if (read <= 0) {
              this.y7_1 = 5;
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 4:
            this.j2l_1 = this.j2l_1 - read | 0;
            this.k2l_1 = this.k2l_1 + read | 0;
            this.i2l_1 = this.i2l_1 + read | 0;
            this.y7_1 = 2;
            continue $sm;
          case 5:
            return this.k2l_1;
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getLengthCOROUTINE$123(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d2m_1 = _this__u8e3s4;
  }
  protoOf($getLengthCOROUTINE$123).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.d2m_1.g1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              this.e2m_1 = this.d2m_1.n2l_1.y26_1;
              this.y7_1 = 2;
              continue $sm;
            } else {
              var tmp_0 = this;
              unsupported_0();
            }

            break;
          case 2:
            return this.e2m_1;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function _readRangeBase($this, fullUrl, range, $completion) {
    var tmp = new $_readRangeBaseCOROUTINE$118($this, fullUrl, range, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function HttpHeaders() {
  }
  function UrlVfs$open$2(this$0, $fullUrl, $stat) {
    this.l2l_1 = this$0;
    this.m2l_1 = $fullUrl;
    this.n2l_1 = $stat;
    AsyncStreamBase.call(this);
  }
  protoOf(UrlVfs$open$2).e2f = function (position, buffer, offset, len, $completion) {
    var tmp = new $readCOROUTINE$122(this, position, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UrlVfs$open$2).g1y = function ($completion) {
    return this.n2l_1.y26_1.k1(new Long(0, 0)) >= 0;
  };
  protoOf(UrlVfs$open$2).h1y = function ($completion) {
    var tmp = new $getLengthCOROUTINE$123(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function $openCOROUTINE$116(_this__u8e3s4, path, mode, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.z2m_1 = _this__u8e3s4;
    this.a2n_1 = path;
    this.b2n_1 = mode;
  }
  protoOf($openCOROUTINE$116).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.z7_1 = 5;
            this.c2n_1 = this.z2m_1.k2n(this.a2n_1);
            if (startsWith(this.c2n_1, 'file:')) {
              this.y7_1 = 4;
              suspendResult = this.z2m_1.h2n_1.m2n(this.c2n_1, VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            var tmp_0 = this;
            var tmp0_safe_receiver = this.s5().o8(Companion_getInstance_12());
            tmp_0.d2n_1 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.l2b_1;
            if (this.d2n_1 == null) {
              this.y7_1 = 2;
              suspendResult = this.z2m_1.q28(this.a2n_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.e2n_1 = this.d2n_1;
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.e2n_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            var stat = this.e2n_1;
            var tmp_1 = stat.h27_1;
            var response = tmp_1 instanceof Response ? tmp_1 : null;
            if (!stat.w26_1) {
              throw new FileNotFoundException('Unexistant ' + this.c2n_1 + ' : ' + response);
            }

            return buffered(toAsyncStream(new UrlVfs$open$2(this.z2m_1, this.c2n_1, stat)));
          case 4:
            var ARGUMENT = suspendResult;
            return openAsync(ARGUMENT);
          case 5:
            this.z7_1 = 6;
            var tmp_2 = this.b8_1;
            if (tmp_2 instanceof RuntimeException) {
              var e = this.b8_1;
              var tmp2_elvis_lhs = e.message;
              throw new FileNotFoundException(tmp2_elvis_lhs == null ? 'error' : tmp2_elvis_lhs);
            } else {
              throw this.b8_1;
            }

            break;
          case 6:
            throw this.b8_1;
          case 7:
            this.z7_1 = 6;
            return Unit_getInstance();
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 6) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  function $_readRangeBaseCOROUTINE$118(_this__u8e3s4, fullUrl, range, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.n2m_1 = _this__u8e3s4;
    this.o2m_1 = fullUrl;
    this.p2m_1 = range;
  }
  protoOf($_readRangeBaseCOROUTINE$118).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.n2m_1.h2n_1.y2n(Companion_getInstance_21().o2n_1, this.o2m_1, Headers_init_$Create$_0(this.p2m_1.equals(get_LONG_ZERO_TO_MAX_RANGE()) ? LinkedHashMap_init_$Create$() : linkedHashMapOf([to('range', 'bytes=' + toString(this.p2m_1.u()) + '-' + toString(this.p2m_1.v()))])), VOID, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.q2m_1 = suspendResult;
            if (this.n2m_1.i2n_1) {
              if (this.q2m_1.o2l_1 === 404)
                throw new FileNotFoundException(this.o2m_1 + ' not found');
              this.y7_1 = 2;
              suspendResult = this.q2m_1.z2n(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            ;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.q2m_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readRangeCOROUTINE$119(_this__u8e3s4, path, range, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.i2o_1 = _this__u8e3s4;
    this.j2o_1 = path;
    this.k2o_1 = range;
  }
  protoOf($readRangeCOROUTINE$119).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = _readRangeBase(this.i2o_1, this.i2o_1.k2n(this.j2o_1), this.k2o_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.l2o_1 = suspendResult;
            this.m2o_1 = this.l2o_1.s2l_1;
            this.y7_1 = 2;
            suspendResult = readAll(this.m2o_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $putCOROUTINE$120(_this__u8e3s4, path, content, attributes, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.v2o_1 = _this__u8e3s4;
    this.w2o_1 = path;
    this.x2o_1 = content;
    this.y2o_1 = attributes;
  }
  protoOf($putCOROUTINE$120).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            var tmp_0 = this.x2o_1;
            if (!(tmp_0 instanceof AsyncStream)) {
              invalidOp('UrlVfs.put requires content to be AsyncStream');
            }

            var tmp_1 = this;
            var tmp$ret$0;
            l$ret$1: do {
              var tmp0_iterator = this.y2o_1.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                if (element instanceof HttpHeaders) {
                  tmp$ret$0 = element;
                  break l$ret$1;
                }
              }
              tmp$ret$0 = null;
            }
             while (false);
            var tmp_2 = tmp$ret$0;
            tmp_1.z2o_1 = (tmp_2 == null ? true : tmp_2 instanceof HttpHeaders) ? tmp_2 : THROW_CCE();
            var tmp_3 = this;
            var tmp$ret$2;
            l$ret$3: do {
              var tmp0_iterator_0 = this.y2o_1.h();
              while (tmp0_iterator_0.j()) {
                var element_0 = tmp0_iterator_0.k();
                if (element_0 instanceof MimeType) {
                  tmp$ret$2 = element_0;
                  break l$ret$3;
                }
              }
              tmp$ret$2 = null;
            }
             while (false);
            var tmp_4 = tmp$ret$2;
            var tmp0_elvis_lhs = (tmp_4 == null ? true : tmp_4 instanceof MimeType) ? tmp_4 : THROW_CCE();
            tmp_3.a2p_1 = tmp0_elvis_lhs == null ? Companion_getInstance_19().e2p_1 : tmp0_elvis_lhs;
            var tmp_5 = this;
            var tmp1_safe_receiver = this.z2o_1;
            var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.m2p_1;
            tmp_5.b2p_1 = tmp2_elvis_lhs == null ? Headers_init_$Create$([]) : tmp2_elvis_lhs;
            this.y7_1 = 1;
            suspendResult = this.x2o_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.c2p_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.v2o_1.h2n_1.y2n(Companion_getInstance_21().r2n_1, this.v2o_1.k2n(this.w2o_1), this.b2p_1.s2p([to(Companion_getInstance_23().n2p_1, toString(this.c2p_1)), to(Companion_getInstance_23().o2p_1, this.a2p_1.p2p_1)]), this.x2o_1, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            ;
            this.y7_1 = 3;
            suspendResult = this.x2o_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            return suspendResult;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $statCOROUTINE$121(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b2q_1 = _this__u8e3s4;
    this.c2q_1 = path;
  }
  protoOf($statCOROUTINE$121).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.d2q_1 = this.b2q_1.k2n(this.c2q_1);
            if (startsWith(this.d2q_1, 'file:')) {
              this.z7_1 = 3;
              this.y7_1 = 2;
              suspendResult = this.b2q_1.h2n_1.m2n(this.d2q_1, VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.b2q_1.h2n_1.y2n(Companion_getInstance_21().p2n_1, this.d2q_1, VOID, VOID, VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            var result = suspendResult;
            var tmp_0 = this;
            var tmp_1;
            if (result.t2l_1) {
              var tmp0_safe_receiver = result.q2l_1.p1b(Companion_getInstance_23().n2p_1);
              var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toLongOrNull_0(tmp0_safe_receiver);
              tmp_1 = this.b2q_1.y28(this.c2q_1, false, tmp1_elvis_lhs == null ? new Long(-1, -1) : tmp1_elvis_lhs, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, result);
            } else {
              tmp_1 = this.b2q_1.a29(this.c2q_1, result);
            }

            tmp_0.e2q_1 = tmp_1;
            this.y7_1 = 5;
            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            var ARGUMENT_0 = ARGUMENT.length;
            var size = toLong(ARGUMENT_0);
            this.f2q_1 = this.b2q_1.y28(this.c2q_1, false, size, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, null);
            this.z7_1 = 6;
            this.y7_1 = 4;
            continue $sm;
          case 3:
            this.z7_1 = 6;
            var tmp_2 = this.b8_1;
            if (tmp_2 instanceof Error) {
              var e = this.b8_1;
              var tmp_3 = this;
              printStackTrace(e);
              tmp_3.f2q_1 = this.b2q_1.a29(this.c2q_1);
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 4:
            this.z7_1 = 6;
            this.e2q_1 = this.f2q_1;
            this.y7_1 = 5;
            continue $sm;
          case 5:
            return this.e2q_1;
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e_0 = $p;
        if (this.z7_1 === 6) {
          throw e_0;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e_0;
        }
      }
     while (true);
  };
  function UrlVfs_0(url, dummy, client, failFromStatus) {
    client = client === VOID ? createHttpClient() : client;
    failFromStatus = failFromStatus === VOID ? true : failFromStatus;
    Vfs.call(this);
    this.f2n_1 = url;
    this.g2n_1 = dummy;
    this.h2n_1 = client;
    this.i2n_1 = failFromStatus;
    this.j2n_1 = this.f2n_1;
  }
  protoOf(UrlVfs_0).u28 = function () {
    return this.j2n_1;
  };
  protoOf(UrlVfs_0).k2n = function (path) {
    var result = trim(this.f2n_1, charArrayOf([_Char___init__impl__6a9atx(47)])) + new Char(_Char___init__impl__6a9atx(47)) + trim(path, charArrayOf([_Char___init__impl__6a9atx(47)]));
    return result;
  };
  protoOf(UrlVfs_0).n28 = function (path, mode, $completion) {
    var tmp = new $openCOROUTINE$116(this, path, mode, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UrlVfs_0).o28 = function (path, range, $completion) {
    var tmp = new $readRangeCOROUTINE$119(this, path, range, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UrlVfs_0).p28 = function (path, content, attributes, $completion) {
    var tmp = new $putCOROUTINE$120(this, path, content, attributes, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UrlVfs_0).q28 = function (path, $completion) {
    var tmp = new $statCOROUTINE$121(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UrlVfs_0).r28 = function (path, $completion) {
    unsupported_0();
  };
  protoOf(UrlVfs_0).toString = function () {
    return 'UrlVfs';
  };
  function UrlVfs_1(url, client, failFromStatus) {
    client = client === VOID ? createHttpClient() : client;
    failFromStatus = failFromStatus === VOID ? true : failFromStatus;
    return UrlVfs(Companion_getInstance_20().b1n(url), client, failFromStatus);
  }
  var bytesTempPool$delegate;
  function get_smallBytesPool() {
    _init_properties_TempBytes_kt__9ttz0b();
    var tmp$ret$0;
    // Inline function 'korlibs.io.lang.threadLocal.getValue' call
    var tmp0_getValue = smallBytesPool$factory();
    tmp$ret$0 = smallBytesPool$delegate.j2q_1.bw();
    return tmp$ret$0;
  }
  var smallBytesPool$delegate;
  function bytesTempPool$delegate$lambda() {
    _init_properties_TempBytes_kt__9ttz0b();
    return Pool_init_$Create$(1, bytesTempPool$delegate$lambda$lambda);
  }
  function bytesTempPool$delegate$lambda$lambda(it) {
    _init_properties_TempBytes_kt__9ttz0b();
    return new Int8Array(65536);
  }
  function smallBytesPool$delegate$lambda() {
    _init_properties_TempBytes_kt__9ttz0b();
    return Pool_init_$Create$(16, smallBytesPool$delegate$lambda$lambda);
  }
  function smallBytesPool$delegate$lambda$lambda(it) {
    _init_properties_TempBytes_kt__9ttz0b();
    return new Int8Array(16);
  }
  function smallBytesPool$factory() {
    return getPropertyCallableRef('smallBytesPool', 0, KProperty0, function () {
      return get_smallBytesPool();
    }, null);
  }
  var properties_initialized_TempBytes_kt_bjneux;
  function _init_properties_TempBytes_kt__9ttz0b() {
    if (properties_initialized_TempBytes_kt_bjneux) {
    } else {
      properties_initialized_TempBytes_kt_bjneux = true;
      bytesTempPool$delegate = new threadLocal(bytesTempPool$delegate$lambda);
      smallBytesPool$delegate = new threadLocal(smallBytesPool$delegate$lambda);
    }
  }
  var CHARSET_PROVIDERS;
  var CHARSET_PROVIDERS_LOCK;
  function get_LATIN1() {
    _init_properties_Charset_kt__c47y1m();
    return LATIN1;
  }
  var LATIN1;
  var UTF16_LE;
  function get_UTF16_BE() {
    _init_properties_Charset_kt__c47y1m();
    return UTF16_BE;
  }
  var UTF16_BE;
  function ISO_8859_1() {
    ISO_8859_1_instance = this;
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.lang.ISO_8859_1.<init>.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < 256)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        tmp0_apply.ib(numberToChar(n));
      }
       while (inductionVariable < 256);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    SingleByteCharset.call(this, 'ISO-8859-1', tmp$ret$1);
  }
  var ISO_8859_1_instance;
  function ISO_8859_1_getInstance() {
    if (ISO_8859_1_instance == null)
      new ISO_8859_1();
    return ISO_8859_1_instance;
  }
  function UTF16Charset(le) {
    Charset.call(this, 'UTF-16-' + (le ? 'LE' : 'BE'));
    this.u2q_1 = le;
  }
  protoOf(UTF16Charset).r2q = function (nbytes) {
    return imul(nbytes, 2);
  };
  protoOf(UTF16Charset).s2q = function (nchars) {
    return imul(nchars, 2);
  };
  protoOf(UTF16Charset).p2q = function (out, src, start, end) {
    var consumed = 0;
    var progression = step(until(start, end), 2);
    var inductionVariable = progression.p1_1;
    var last = progression.q1_1;
    var step_0 = progression.r1_1;
    if ((step_0 > 0 ? inductionVariable <= last : false) ? true : step_0 < 0 ? last <= inductionVariable : false)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + step_0 | 0;
        var char = numberToChar(readS16(src, n, this.u2q_1));
        out.ib(char);
        consumed = consumed + 2 | 0;
      }
       while (!(n === last));
    return consumed;
  };
  protoOf(UTF16Charset).o2q = function (out, src, start, end) {
    var temp = new Int8Array(2);
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.code' call
        var tmp0__get_code__88qj9g = charSequenceGet(src, n);
        tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
        write16(temp, 0, tmp$ret$0, this.u2q_1);
        out.a1j(temp);
      }
       while (inductionVariable < end);
  };
  function Companion_9() {
    Companion_instance_9 = this;
  }
  protoOf(Companion_9).v2q = function (_this__u8e3s4, codePoint) {
    if ((55296 <= codePoint ? codePoint <= 57343 : false) ? true : codePoint > 65535) {
      var U0 = codePoint - 65536 | 0;
      var hs = extract(U0, 10, 10);
      var ls = extract(U0, 0, 10);
      _this__u8e3s4.ib(numberToChar(55296 | hs));
      _this__u8e3s4.ib(numberToChar(56320 | ls));
    } else {
      _this__u8e3s4.ib(numberToChar(codePoint));
    }
  };
  var Companion_instance_9;
  function Companion_getInstance_15() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function Charset(name) {
    Companion_getInstance_15();
    this.w2q_1 = name;
  }
  protoOf(Charset).r2q = function (nbytes) {
    return imul(nbytes, 2);
  };
  protoOf(Charset).s2q = function (nchars) {
    return imul(nchars, 2);
  };
  function SingleByteCharset(name, conv) {
    BaseSingleByteCharset.call(this, name);
    this.m2q_1 = conv;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = IntIntMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.lang.SingleByteCharset.v.<anonymous>' call
    var inductionVariable = 0;
    var last = this.m2q_1.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.code' call
        var tmp0__get_code__88qj9g = charSequenceGet(this.m2q_1, n);
        tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
        tmp0_apply.p1e(tmp$ret$0, n);
      }
       while (inductionVariable < last);
    tmp$ret$1 = tmp0_apply;
    tmp.n2q_1 = tmp$ret$1;
  }
  protoOf(SingleByteCharset).o2q = function (out, src, start, end) {
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.code' call
        var tmp0__get_code__88qj9g = charSequenceGet(src, n);
        tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
        var c = tmp$ret$0;
        var tmp$ret$1;
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        var tmp1_append = this.n2q_1.y9(c) ? toByte(this.n2q_1.n(c)) : 63;
        out.b1j(tmp1_append);
        tmp$ret$1 = out;
      }
       while (inductionVariable < end);
  };
  protoOf(SingleByteCharset).p2q = function (out, src, start, end) {
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        out.ib(charSequenceGet(this.m2q_1, src[n] & 255));
      }
       while (inductionVariable < end);
    return end - start | 0;
  };
  function BaseSingleByteCharset(name) {
    Charset.call(this, name);
  }
  protoOf(BaseSingleByteCharset).r2q = function (nbytes) {
    return nbytes;
  };
  protoOf(BaseSingleByteCharset).s2q = function (nchars) {
    return nchars;
  };
  function toString_2(_this__u8e3s4, charset, start, end) {
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.length : end;
    _init_properties_Charset_kt__c47y1m();
    var out = StringBuilder_init_$Create$_0(charset.r2q(end - start | 0));
    charset.p2q(out, _this__u8e3s4, start, end);
    return out.toString();
  }
  function toByteArray(_this__u8e3s4, charset, start, end) {
    charset = charset === VOID ? get_UTF8() : charset;
    start = start === VOID ? 0 : start;
    end = end === VOID ? _this__u8e3s4.length : end;
    _init_properties_Charset_kt__c47y1m();
    var out = ByteArrayBuilder_init_$Create$(charset.s2q(end - start | 0));
    charset.o2q(out, _this__u8e3s4, start, end);
    return out.c1j();
  }
  function createByte($this, codePoint, shift) {
    return codePoint >> shift & 63 | 128;
  }
  function UTC8CharsetBase(name) {
    Charset.call(this, name);
  }
  protoOf(UTC8CharsetBase).r2q = function (nbytes) {
    return imul(nbytes, 2);
  };
  protoOf(UTC8CharsetBase).s2q = function (nchars) {
    return imul(nchars, 2);
  };
  protoOf(UTC8CharsetBase).o2q = function (out, src, start, end) {
    // Inline function 'korlibs.io.lang.Companion.decodeCodePoints' call
    var tmp1_decodeCodePoints = Companion_getInstance_15();
    var highSurrogate = 0;
    var inductionVariable = start;
    if (inductionVariable < end)
      loop: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var char = Char__toInt_impl_vasixd(charSequenceGet(src, n));
        var tmp;
        if (55296 <= char ? char <= 57343 : false) {
          var tmp1_subject = extract(char, 10, 6);
          var tmp_0;
          switch (tmp1_subject) {
            case 54:
              highSurrogate = char & 1023;
              continue loop;
            case 55:
              tmp_0 = 65536 + (highSurrogate << 10 | char & 1023) | 0;
              break;
            default:
              var tmp0_error = 'Unknown ' + char;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
          }
          tmp = tmp_0;
        } else {
          tmp = char;
        }
        var codePoint = tmp;
        // Inline function 'korlibs.io.lang.UTC8CharsetBase.encode.<anonymous>' call
        if ((codePoint & -128) === 0) {
          var tmp$ret$0;
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var tmp0_append = toByte(codePoint);
          out.b1j(tmp0_append);
          tmp$ret$0 = out;
        } else {
          if ((codePoint & -2048) === 0) {
            var tmp$ret$1;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp1_append = toByte(codePoint >> 6 & 31 | 192);
            out.b1j(tmp1_append);
            tmp$ret$1 = out;
          } else if ((codePoint & -65536) === 0) {
            var tmp$ret$2;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp2_append = toByte(codePoint >> 12 & 15 | 224);
            out.b1j(tmp2_append);
            tmp$ret$2 = out;
            var tmp$ret$3;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp3_append = toByte(createByte(this, codePoint, 6));
            out.b1j(tmp3_append);
            tmp$ret$3 = out;
          } else if ((codePoint & -2097152) === 0) {
            var tmp$ret$4;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp4_append = toByte(codePoint >> 18 & 7 | 240);
            out.b1j(tmp4_append);
            tmp$ret$4 = out;
            var tmp$ret$5;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp5_append = toByte(createByte(this, codePoint, 12));
            out.b1j(tmp5_append);
            tmp$ret$5 = out;
            var tmp$ret$6;
            // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
            var tmp6_append = toByte(createByte(this, codePoint, 6));
            out.b1j(tmp6_append);
            tmp$ret$6 = out;
          }
          var tmp$ret$7;
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var tmp7_append = toByte(codePoint & 63 | 128);
          out.b1j(tmp7_append);
          tmp$ret$7 = out;
        }
      }
       while (inductionVariable < end);
  };
  protoOf(UTC8CharsetBase).p2q = function (out, src, start, end) {
    if ((start < 0 ? true : start > src.length) ? true : end < 0 ? true : end > src.length) {
      // Inline function 'kotlin.error' call
      throw IllegalStateException_init_$Create$('Out of bounds');
    }
    var i = start;
    loop: while (i < end) {
      var c = src[i] & 255;
      var tmp0_subject = c >> 4;
      if (0 <= tmp0_subject ? tmp0_subject <= 7 : false) {
        Companion_getInstance_15().v2q(out, c);
        i = i + 1 | 0;
      } else if (12 <= tmp0_subject ? tmp0_subject <= 13 : false) {
        if ((i + 1 | 0) >= end)
          break loop;
        Companion_getInstance_15().v2q(out, (c & 31) << 6 | src[i + 1 | 0] & 63);
        i = i + 2 | 0;
      } else if (tmp0_subject === 14) {
        if ((i + 2 | 0) >= end)
          break loop;
        Companion_getInstance_15().v2q(out, (c & 15) << 12 | (src[i + 1 | 0] & 63) << 6 | src[i + 2 | 0] & 63);
        i = i + 3 | 0;
      } else if (tmp0_subject === 15) {
        if ((i + 3 | 0) >= end)
          break loop;
        Companion_getInstance_15().v2q(out, insert(insert(insert(insert(0, extract(src[i + 0 | 0], 0, 3), 18, 3), extract(src[i + 1 | 0], 0, 6), 12, 6), extract(src[i + 2 | 0], 0, 6), 6, 6), extract(src[i + 3 | 0], 0, 6), 0, 6));
        i = i + 4 | 0;
      } else {
        out.ib(_Char___init__impl__6a9atx(65533));
        i = i + 1 | 0;
      }
    }
    return i - start | 0;
  };
  function ASCII() {
    ASCII_instance = this;
    var tmp = 0;
    var tmp_0 = 128;
    var tmp_1 = charArray(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$0;
      // Inline function 'korlibs.io.lang.ASCII.<init>.<anonymous>' call
      tmp$ret$0 = numberToChar(tmp_2);
      tmp_1[tmp_2] = tmp$ret$0;
      tmp = tmp + 1 | 0;
    }
    SingleByteCharset.call(this, 'ASCII', concatToString(tmp_1) + '\xC7\xFC\xE9\xE2\xE4\xE0\xE5\xE7\xEA\xEB\xE8\xEF\xEE\xEC\xC4\xC5\xC9\xE6\xC6\xF4\xF6\xF2\xFB\xF9\xFF\xD6\xDC\xF8\xA3\xD8\xD7\u0192\xE1\xED\xF3\xFA\xF1\xD1\xAA\xBA\xBF\xAE\xAC\xBD\xBC\xA1\xAB\xBB\u2591\u2592\u2593\u2502\u2524\xC1\xC2\xC0\xA9\u2563\u2551\u2557\u255D\xA2\xA5\u2510\u2514\u2534\u252C\u251C\u2500\u253C\xE3\xC3\u255A\u2554\u2569\u2566\u2560\u2550\u256C\xA4\xF0\xD0\xCA\xCB\xC8\u0131\xCD\xCE\xCF\u2518\u250C\u2588\u2584\xA6\xCC\u2580\xD3\xDF\xD4\xD2\xF5\xD5\xB5\xFE\xDE\xDA\xDB\xD9\xFD\xDD\xAF\xB4\xAD\xB1\u2017\xBE\xB6\xA7\xF7\xB8\xB0\xA8\xB7\xB9\xB3\xB2\u25A0\xA0');
  }
  var ASCII_instance;
  function ASCII_getInstance() {
    if (ASCII_instance == null)
      new ASCII();
    return ASCII_instance;
  }
  function Charsets() {
    Charsets_instance = this;
  }
  protoOf(Charsets).y2q = function () {
    return get_LATIN1();
  };
  var Charsets_instance;
  function Charsets_getInstance() {
    if (Charsets_instance == null)
      new Charsets();
    return Charsets_instance;
  }
  var properties_initialized_Charset_kt_dajgec;
  function _init_properties_Charset_kt__c47y1m() {
    if (properties_initialized_Charset_kt_dajgec) {
    } else {
      properties_initialized_Charset_kt_dajgec = true;
      var tmp$ret$0;
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp$ret$0 = ArrayList_init_$Create$_0();
      CHARSET_PROVIDERS = tmp$ret$0;
      CHARSET_PROVIDERS_LOCK = new NonRecursiveLock();
      LATIN1 = ISO_8859_1_getInstance();
      UTF16_LE = new UTF16Charset(true);
      UTF16_BE = new UTF16Charset(false);
    }
  }
  function Closeable$Companion$invoke$1($callback) {
    this.z2q_1 = $callback;
  }
  protoOf(Closeable$Companion$invoke$1).n1q = function () {
    return this.z2q_1();
  };
  function Companion_10() {
    Companion_instance_10 = this;
  }
  protoOf(Companion_10).a2r = function (callback) {
    return new Closeable$Companion$invoke$1(callback);
  };
  var Companion_instance_10;
  function Companion_getInstance_16() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function Closeable() {
  }
  function cancel_0(_this__u8e3s4) {
    return _this__u8e3s4.b2r(CancellationException_init_$Create$(''));
  }
  function CloseableCancellable() {
  }
  function CancellableGroup() {
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    tmp.c2r_1 = tmp$ret$0;
  }
  protoOf(CancellableGroup).d2r = function (c) {
    var tmp0_this = this;
    // Inline function 'kotlin.collections.plusAssign' call
    var tmp0_plusAssign = tmp0_this.c2r_1;
    var tmp1_plusAssign = cancellable(c);
    tmp0_plusAssign.b(tmp1_plusAssign);
  };
  protoOf(CancellableGroup).n1q = function () {
    this.b2r(CancellationException_init_$Create$_0());
  };
  protoOf(CancellableGroup).b2r = function (e) {
    cancel_1(this.c2r_1, e);
  };
  function cancellable(_this__u8e3s4) {
    var tmp = cancellable$lambda(_this__u8e3s4);
    return new sam$korlibs_io_lang_Cancellable$0(tmp);
  }
  function cancel_1(_this__u8e3s4, e) {
    e = e === VOID ? CancellationException_init_$Create$('') : e;
    var tmp$ret$0;
    // Inline function 'kotlin.run' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp0_iterator = _this__u8e3s4.h();
    while (tmp0_iterator.j()) {
      var c = tmp0_iterator.k();
      c.b2r(e);
    }
    tmp$ret$0 = Unit_getInstance();
    return tmp$ret$0;
  }
  function sam$korlibs_io_lang_Cancellable$0(function_0) {
    this.e2r_1 = function_0;
  }
  protoOf(sam$korlibs_io_lang_Cancellable$0).b2r = function (e) {
    return this.e2r_1(e);
  };
  function cancellable$lambda($this_cancellable) {
    return function (it) {
      $this_cancellable.n1q();
      return Unit_getInstance();
    };
  }
  var customEnvironments;
  function Companion_11() {
    Companion_instance_11 = this;
  }
  protoOf(Companion_11).p1b = function (key) {
    var tmp0_safe_receiver = customEnvironments;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.p1b(key);
    return tmp1_elvis_lhs == null ? EnvironmentInternal_getInstance().p1b(key) : tmp1_elvis_lhs;
  };
  var Companion_instance_11;
  function Companion_getInstance_17() {
    if (Companion_instance_11 == null)
      new Companion_11();
    return Companion_instance_11;
  }
  function unsupported_0(msg) {
    msg = msg === VOID ? 'unsupported' : msg;
    throw UnsupportedOperationException_init_$Create$(msg);
  }
  function invalidOp(msg) {
    throw new InvalidOperationException(msg);
  }
  function InvalidOperationException(str) {
    str = str === VOID ? 'Invalid Operation' : str;
    Exception_init_$Init$(str, this);
    captureStack(this, InvalidOperationException);
  }
  function FileAlreadyExistsException(msg) {
    IOException.call(this, msg);
    captureStack(this, FileAlreadyExistsException);
  }
  function get_invalidOp() {
    throw new InvalidOperationException();
  }
  function reserved(msg) {
    throw new ReservedException(msg);
  }
  function get_reserved() {
    throw new ReservedException();
  }
  function get_unreachable() {
    throw new UnreachableException();
  }
  function ReservedException(str) {
    str = str === VOID ? 'Reserved' : str;
    Exception_init_$Init$(str, this);
    captureStack(this, ReservedException);
  }
  function UnreachableException(str) {
    str = str === VOID ? 'Unreachable' : str;
    Exception_init_$Init$(str, this);
    captureStack(this, UnreachableException);
  }
  function unexpected(msg) {
    throw new UnexpectedException(msg);
  }
  function UnexpectedException(str) {
    str = str === VOID ? 'Unexpected' : str;
    Exception_init_$Init$(str, this);
    captureStack(this, UnexpectedException);
  }
  var formatRegex;
  var replaceNonPrintableCharactersRegex$delegate;
  function substr(_this__u8e3s4, start, length) {
    _init_properties_StringExt_kt__eg42u6();
    var low = clamp(start >= 0 ? start : _this__u8e3s4.length + start | 0, 0, _this__u8e3s4.length);
    var high = clamp(length >= 0 ? low + length | 0 : _this__u8e3s4.length + length | 0, 0, _this__u8e3s4.length);
    var tmp;
    if (high >= low) {
      var tmp$ret$1;
      // Inline function 'kotlin.text.substring' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = _this__u8e3s4;
      tmp$ret$1 = tmp$ret$0.substring(low, high);
      tmp = tmp$ret$1;
    } else {
      tmp = '';
    }
    return tmp;
  }
  function substringEquals(_this__u8e3s4, a, aIndex, b, bIndex, count) {
    _init_properties_StringExt_kt__eg42u6();
    if (count === 0)
      return true;
    if (aIndex < 0 ? true : bIndex < 0)
      return false;
    if ((aIndex + count | 0) > a.length)
      return false;
    if ((bIndex + count | 0) > b.length)
      return false;
    var inductionVariable = 0;
    if (inductionVariable < count)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(new Char(charSequenceGet(a, aIndex + n | 0)), new Char(charSequenceGet(b, bIndex + n | 0))))
          return false;
      }
       while (inductionVariable < count);
    return true;
  }
  function String_fromCharArray(arrays, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? arrays.length - offset | 0 : size;
    _init_properties_StringExt_kt__eg42u6();
    return concatToString_0(arrays, offset, offset + size | 0);
  }
  function substr_0(_this__u8e3s4, start) {
    _init_properties_StringExt_kt__eg42u6();
    return substr(_this__u8e3s4, start, _this__u8e3s4.length);
  }
  function lastIndexOfOrNull(_this__u8e3s4, char, startIndex) {
    startIndex = startIndex === VOID ? get_lastIndex(_this__u8e3s4) : startIndex;
    _init_properties_StringExt_kt__eg42u6();
    var tmp$ret$1;
    // Inline function 'kotlin.takeIf' call
    var tmp0_takeIf = lastIndexOf(_this__u8e3s4, char, startIndex);
    // Inline function 'kotlin.contracts.contract' call
    var tmp;
    var tmp$ret$0;
    // Inline function 'korlibs.io.lang.lastIndexOfOrNull.<anonymous>' call
    tmp$ret$0 = tmp0_takeIf >= 0;
    if (tmp$ret$0) {
      tmp = tmp0_takeIf;
    } else {
      tmp = null;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  }
  function splitInChunks(_this__u8e3s4, size) {
    _init_properties_StringExt_kt__eg42u6();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    var out = tmp$ret$0;
    var pos = 0;
    while (pos < _this__u8e3s4.length) {
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp$ret$3;
      // Inline function 'kotlin.text.substring' call
      var tmp2_substring = pos;
      var tmp$ret$1;
      // Inline function 'kotlin.math.min' call
      var tmp0_min = _this__u8e3s4.length;
      var tmp1_min = pos + size | 0;
      tmp$ret$1 = Math.min(tmp0_min, tmp1_min);
      var tmp3_substring = tmp$ret$1;
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = _this__u8e3s4;
      tmp$ret$3 = tmp$ret$2.substring(tmp2_substring, tmp3_substring);
      var tmp4_plusAssign = tmp$ret$3;
      out.b(tmp4_plusAssign);
      pos = pos + size | 0;
    }
    return out;
  }
  function splitKeep(_this__u8e3s4, regex) {
    _init_properties_StringExt_kt__eg42u6();
    var str = _this__u8e3s4;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    var out = tmp$ret$0;
    var lastPos = 0;
    var tmp0_iterator = regex.zk(_this__u8e3s4).h();
    while (tmp0_iterator.j()) {
      var part = tmp0_iterator.k();
      var prange = part.nl();
      if (!(lastPos === prange.u())) {
        // Inline function 'kotlin.collections.plusAssign' call
        var tmp$ret$2;
        // Inline function 'kotlin.text.substring' call
        var tmp0_substring = lastPos;
        var tmp1_substring = prange.u();
        var tmp$ret$1;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$1 = str;
        tmp$ret$2 = tmp$ret$1.substring(tmp0_substring, tmp1_substring);
        var tmp2_plusAssign = tmp$ret$2;
        out.b(tmp2_plusAssign);
      }
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp3_plusAssign = substring(str, prange);
      out.b(tmp3_plusAssign);
      lastPos = prange.v() + 1 | 0;
    }
    if (!(lastPos === str.length)) {
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp$ret$4;
      // Inline function 'kotlin.text.substring' call
      var tmp4_substring = lastPos;
      var tmp$ret$3;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$3 = str;
      tmp$ret$4 = tmp$ret$3.substring(tmp4_substring);
      var tmp5_plusAssign = tmp$ret$4;
      out.b(tmp5_plusAssign);
    }
    return out;
  }
  function withInsertion(_this__u8e3s4, index, insertedText) {
    _init_properties_StringExt_kt__eg42u6();
    var before = substr(_this__u8e3s4, 0, index);
    var after = substr(_this__u8e3s4, index, _this__u8e3s4.length);
    return before + insertedText + after;
  }
  function withoutRange(_this__u8e3s4, range) {
    _init_properties_StringExt_kt__eg42u6();
    return substr(_this__u8e3s4, 0, range.p1_1) + substr_0(_this__u8e3s4, range.q1_1 + 1 | 0);
  }
  function withoutIndex(_this__u8e3s4, index) {
    _init_properties_StringExt_kt__eg42u6();
    return substr(_this__u8e3s4, 0, index) + substr_0(_this__u8e3s4, index + 1 | 0);
  }
  function replaceNonPrintableCharactersRegex$delegate$lambda() {
    _init_properties_StringExt_kt__eg42u6();
    return Regex_init_$Create$('[^ -~]');
  }
  var properties_initialized_StringExt_kt_sd962o;
  function _init_properties_StringExt_kt__eg42u6() {
    if (properties_initialized_StringExt_kt_sd962o) {
    } else {
      properties_initialized_StringExt_kt_sd962o = true;
      formatRegex = Regex_init_$Create$('%([-]?\\d+)?(\\w)');
      replaceNonPrintableCharactersRegex$delegate = lazy(replaceNonPrintableCharactersRegex$delegate$lambda);
    }
  }
  function threadLocal$local$1(this$0) {
    this.g2r_1 = this$0;
    NativeThreadLocal.call(this);
  }
  protoOf(threadLocal$local$1).h2r = function () {
    return this.g2r_1.i2q_1();
  };
  function threadLocal(gen) {
    this.i2q_1 = gen;
    var tmp = this;
    tmp.j2q_1 = new threadLocal$local$1(this);
  }
  function WStringReader(str, position) {
    position = position === VOID ? 0 : position;
    this.i2r_1 = str;
    this.j2r_1 = position;
  }
  protoOf(WStringReader).k2r = function () {
    return this.i2r_1.hf() - this.j2r_1 | 0;
  };
  protoOf(WStringReader).p2r = function () {
    return this.j2r_1 >= this.i2r_1.hf();
  };
  protoOf(WStringReader).q2r = function () {
    return !this.p2r();
  };
  protoOf(WStringReader).r2r = function (offset) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.io.lang.WString.getOrElse' call
      var tmp0_getOrElse = this.i2r_1;
      var tmp1_getOrElse = this.j2r_1 + offset | 0;
      if (tmp1_getOrElse < 0 ? true : tmp1_getOrElse >= tmp0_getOrElse.hf()) {
        var tmp$ret$0;
        // Inline function 'korlibs.io.lang.WStringReader.peek.<anonymous>' call
        tmp$ret$0 = _WChar___init__impl__4vbl4k(0);
        tmp$ret$1 = tmp$ret$0;
        break $l$block;
      }
      tmp$ret$1 = tmp0_getOrElse.s2r(tmp1_getOrElse);
    }
    return tmp$ret$1;
  };
  protoOf(WStringReader).t2r = function (offset, $super) {
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.r2r(offset) : $super.r2r.call(this, offset).u2r_1;
  };
  protoOf(WStringReader).v2r = function (count) {
    var tmp0_this = this;
    tmp0_this.j2r_1 = tmp0_this.j2r_1 + count | 0;
  };
  protoOf(WStringReader).w2r = function (offset, len) {
    return substr_1(this.i2r_1, this.j2r_1 + offset | 0, len);
  };
  function _WChar___init__impl__4vbl4k(codePoint) {
    return codePoint;
  }
  function _WChar___get_codePoint__impl__c8brwu($this) {
    return $this;
  }
  function Companion_12() {
    Companion_instance_12 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.intArrayOf' call
    tmp$ret$0 = new Int32Array([]);
    tmp.x2r_1 = new WString(tmp$ret$0, '');
  }
  protoOf(Companion_12).y2r = function (codePoints) {
    return this.z2r(codePoints);
  };
  protoOf(Companion_12).b1n = function (string) {
    return this.a2s(string);
  };
  protoOf(Companion_12).a2s = function (string) {
    if (string === '')
      return this.x2r_1;
    var codePoints = new Int32Array(string.length);
    var tmp$ret$2;
    // Inline function 'korlibs.io.lang.forEachCodePoint' call
    var string_0 = string;
    var m = 0;
    var n = 0;
    while (n < string_0.length) {
      var tmp$ret$0;
      // Inline function 'kotlin.code' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__get_code__88qj9g = charSequenceGet(string_0, tmp0);
      tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
      var value = tmp$ret$0;
      var error = false;
      if ((value & 63488) === 55296 ? n < string_0.length : false) {
        var tmp$ret$1;
        // Inline function 'kotlin.code' call
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        var tmp1__get_code__adl84j = charSequenceGet(string_0, tmp1);
        tmp$ret$1 = Char__toInt_impl_vasixd(tmp1__get_code__adl84j);
        var extra = tmp$ret$1;
        if (!((extra & 64512) === 56320)) {
          var tmp2 = n;
          n = tmp2 - 1 | 0;
          error = true;
        } else {
          var dataHigh = value & 1023;
          var dataLow = extra & 1023;
          value = (dataLow | dataHigh << 10) + 65536 | 0;
        }
      }
      // Inline function 'korlibs.io.lang.Companion.fromString.<anonymous>' call
      var tmp3 = m;
      m = tmp3 + 1 | 0;
      var tmp2__anonymous__z9zvc9 = tmp3;
      var tmp3__anonymous__ufb84q = value;
      var tmp4__anonymous__pkmkx7 = error;
      codePoints[tmp2__anonymous__z9zvc9] = tmp3__anonymous__ufb84q;
    }
    tmp$ret$2 = m;
    var length = tmp$ret$2;
    return new WString(copyOf(codePoints, length), string);
  };
  protoOf(Companion_12).z2r = function (codePoints) {
    var tmp$ret$1;
    // Inline function 'kotlin.collections.count' call
    var count = 0;
    var indexedObject = codePoints;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'korlibs.io.lang.Companion.fromCodePoints.<anonymous>' call
      tmp$ret$0 = element >= 65536;
      if (tmp$ret$0) {
        count = count + 1 | 0;
      }
    }
    tmp$ret$1 = count;
    var surrogateCount = tmp$ret$1;
    var out = StringBuilder_init_$Create$_0(codePoints.length + surrogateCount | 0);
    var indexedObject_0 = codePoints;
    var inductionVariable_0 = 0;
    var last_0 = indexedObject_0.length;
    while (inductionVariable_0 < last_0) {
      var codePoint = indexedObject_0[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      if (codePoint > 65535) {
        var U1 = codePoint - 65536 | 0;
        var W1 = 55296 | (U1 >>> 10 | 0) & 1023;
        var W2 = 56320 | (U1 >>> 0 | 0) & 1023;
        out.ib(numberToChar(W1));
        out.ib(numberToChar(W2));
      } else {
        out.ib(numberToChar(codePoint));
      }
    }
    return new WString(codePoints, out.toString());
  };
  var Companion_instance_12;
  function Companion_getInstance_18() {
    if (Companion_instance_12 == null)
      new Companion_12();
    return Companion_instance_12;
  }
  function WString(codePoints, string) {
    Companion_getInstance_18();
    this.l2r_1 = codePoints;
    this.m2r_1 = string;
    this.n2r_1 = 0;
    this.o2r_1 = false;
  }
  protoOf(WString).hf = function () {
    return this.l2r_1.length;
  };
  protoOf(WString).s2r = function (index) {
    return _WChar___init__impl__4vbl4k(this.l2r_1[index]);
  };
  protoOf(WString).b2s = function (startIndex, endIndex) {
    var tmp = copyOfRange(this.l2r_1, startIndex, endIndex);
    var tmp$ret$1;
    // Inline function 'kotlin.text.substring' call
    var tmp0_substring = this.m2r_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_substring;
    tmp$ret$1 = tmp$ret$0.substring(startIndex, endIndex);
    return new WString(tmp, tmp$ret$1);
  };
  protoOf(WString).hashCode = function () {
    if (!this.o2r_1) {
      this.o2r_1 = true;
      this.n2r_1 = contentHashCode(this.l2r_1);
    }
    return this.n2r_1;
  };
  protoOf(WString).equals = function (other) {
    var tmp;
    if (other instanceof WString) {
      tmp = contentEquals(this.l2r_1, other.l2r_1);
    } else {
      tmp = false;
    }
    return tmp;
  };
  protoOf(WString).toString = function () {
    return this.m2r_1;
  };
  function substr_1(_this__u8e3s4, start, length) {
    length = length === VOID ? _this__u8e3s4.hf() : length;
    var low = clamp(start >= 0 ? start : _this__u8e3s4.hf() + start | 0, 0, _this__u8e3s4.hf());
    var high = clamp(length >= 0 ? low + length | 0 : _this__u8e3s4.hf() + length | 0, 0, _this__u8e3s4.hf());
    return high < low ? Companion_getInstance_18().b1n('') : _this__u8e3s4.b2s(low, high);
  }
  function get_MimeType_byExtensions() {
    _init_properties_MimeType_kt__q8p0lu();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = MimeType_byExtensions$factory();
    tmp$ret$0 = MimeType_byExtensions$delegate.f1();
    return tmp$ret$0;
  }
  var MimeType_byExtensions$delegate;
  function Companion_13() {
    Companion_instance_13 = this;
    this.d2p_1 = new MimeType('application/octet-stream', listOf('bin'));
    this.e2p_1 = new MimeType('application/json', listOf('json'));
    this.f2p_1 = new MimeType('image/png', listOf('png'));
    this.g2p_1 = new MimeType('image/jpeg', listOf_0(['jpg', 'jpeg']));
    this.h2p_1 = new MimeType('image/gif', listOf('gif'));
    this.i2p_1 = new MimeType('text/html', listOf_0(['htm', 'html']));
    this.j2p_1 = new MimeType('text/plain', listOf_0(['txt', 'text']));
    this.k2p_1 = new MimeType('text/css', listOf('css'));
    this.l2p_1 = new MimeType('application/javascript', listOf('js'));
    this.c2s([this.d2p_1, this.e2p_1, this.f2p_1, this.g2p_1, this.h2p_1, this.i2p_1, this.j2p_1, this.k2p_1, this.l2p_1]);
  }
  protoOf(Companion_13).d2s = function (mimeType) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = mimeType.q2p_1;
    var n = 0;
    while (n < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.io.net.Companion.register.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0);
      // Inline function 'kotlin.collections.set' call
      var tmp0_set = get_MimeType_byExtensions();
      tmp0_set.e5(tmp1__anonymous__uwfjfc, mimeType);
    }
  };
  protoOf(Companion_13).c2s = function (mimeTypes) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < mimeTypes.length) {
      // Inline function 'korlibs.io.net.Companion.register.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = mimeTypes[tmp0];
      Companion_getInstance_19().d2s(tmp0__anonymous__q1qw7t);
    }
  };
  var Companion_instance_13;
  function Companion_getInstance_19() {
    if (Companion_instance_13 == null)
      new Companion_13();
    return Companion_instance_13;
  }
  function MimeType(mime, exts) {
    Companion_getInstance_19();
    this.p2p_1 = mime;
    this.q2p_1 = exts;
  }
  function MimeType_byExtensions$delegate$lambda() {
    _init_properties_MimeType_kt__q8p0lu();
    return LinkedHashMap_init_$Create$();
  }
  function MimeType_byExtensions$factory() {
    return getPropertyCallableRef('MimeType_byExtensions', 0, KProperty0, function () {
      return get_MimeType_byExtensions();
    }, null);
  }
  var properties_initialized_MimeType_kt_1v95zk;
  function _init_properties_MimeType_kt__q8p0lu() {
    if (properties_initialized_MimeType_kt_1v95zk) {
    } else {
      properties_initialized_MimeType_kt_1v95zk = true;
      MimeType_byExtensions$delegate = lazy(MimeType_byExtensions$delegate$lambda);
    }
  }
  function QueryString() {
    QueryString_instance = this;
  }
  protoOf(QueryString).e2s = function (str) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    var out = tmp$ret$0;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var tmp0_fastForEach = split_0(str, charArrayOf([_Char___init__impl__6a9atx(38)]));
    var n = 0;
    while (n < tmp0_fastForEach.l()) {
      // Inline function 'korlibs.io.net.QueryString.decode.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp1__anonymous__uwfjfc = tmp0_fastForEach.n(tmp0);
      var parts = split_0(tmp1__anonymous__uwfjfc, charArrayOf([_Char___init__impl__6a9atx(61)]), VOID, 2);
      var key = Companion_getInstance_20().f2s(parts.n(0), get_UTF8(), true);
      var tmp = Companion_getInstance_20();
      var tmp$ret$2;
      // Inline function 'kotlin.collections.getOrElse' call
      var tmp_0;
      if (1 <= get_lastIndex_0(parts)) {
        tmp_0 = parts.n(1);
      } else {
        var tmp$ret$1;
        // Inline function 'korlibs.io.net.QueryString.decode.<anonymous>.<anonymous>' call
        tmp$ret$1 = key;
        tmp_0 = tmp$ret$1;
      }
      tmp$ret$2 = tmp_0;
      var value = tmp.f2s(tmp$ret$2, get_UTF8(), true);
      var tmp$ret$5;
      // Inline function 'kotlin.collections.getOrPut' call
      var value_0 = out.h3(key);
      var tmp_1;
      if (value_0 == null) {
        var tmp$ret$4;
        // Inline function 'korlibs.io.net.QueryString.decode.<anonymous>.<anonymous>' call
        var tmp$ret$3;
        // Inline function 'kotlin.collections.arrayListOf' call
        tmp$ret$3 = ArrayList_init_$Create$_0();
        tmp$ret$4 = tmp$ret$3;
        var answer = tmp$ret$4;
        out.e5(key, answer);
        tmp_1 = answer;
      } else {
        tmp_1 = value_0;
      }
      tmp$ret$5 = tmp_1;
      var list = tmp$ret$5;
      // Inline function 'kotlin.collections.plusAssign' call
      list.b(value);
    }
    return out;
  };
  var QueryString_instance;
  function QueryString_getInstance() {
    if (QueryString_instance == null)
      new QueryString();
    return QueryString_instance;
  }
  function Companion_14() {
    Companion_instance_14 = this;
    this.g2q_1 = 0;
    this.h2q_1 = Regex_init_$Create$('\\w+:');
  }
  protoOf(Companion_14).g2s = function (scheme) {
    var tmp0_subject = scheme;
    switch (tmp0_subject) {
      case 'ftp':
        return 21;
      case 'ftps':
        return 990;
      case 'http':
      case 'ws':
        return 80;
      case 'https':
      case 'wss':
        return 443;
      default:
        return -1;
    }
  };
  protoOf(Companion_14).h2s = function (scheme, userInfo, host, path, query, fragment, opaque, port) {
    return new URL(opaque, scheme, userInfo, host, path, query, fragment, port);
  };
  protoOf(Companion_14).i2s = function (scheme, userInfo, host, path, query, fragment, opaque, port, $super) {
    opaque = opaque === VOID ? false : opaque;
    port = port === VOID ? this.g2q_1 : port;
    return $super === VOID ? this.h2s(scheme, userInfo, host, path, query, fragment, opaque, port) : $super.h2s.call(this, scheme, userInfo, host, path, query, fragment, opaque, port);
  };
  protoOf(Companion_14).b1n = function (url) {
    var r = new StrReader(url);
    var schemeColon = r.o2s(this.h2q_1);
    var tmp;
    if (!(schemeColon == null)) {
      var isHierarchical = !(r.p2s('//') == null);
      var nonScheme = r.q2s();
      var scheme = dropLast(schemeColon, 1);
      var nonFragment = substringBefore(nonScheme, _Char___init__impl__6a9atx(35));
      var fragment = substringAfterOrNull(nonScheme, _Char___init__impl__6a9atx(35));
      var nonQuery = substringBefore(nonFragment, _Char___init__impl__6a9atx(63));
      var query = substringAfterOrNull(nonFragment, _Char___init__impl__6a9atx(63));
      var authority = substringBefore(nonQuery, _Char___init__impl__6a9atx(47));
      var path = substringAfterOrNull(nonQuery, _Char___init__impl__6a9atx(47));
      var hostWithPort = substringAfter(authority, _Char___init__impl__6a9atx(64));
      var userInfo = substringBeforeOrNull(authority, _Char___init__impl__6a9atx(64));
      var host = substringBefore(hostWithPort, _Char___init__impl__6a9atx(58));
      var port = substringAfterOrNull(hostWithPort, _Char___init__impl__6a9atx(58));
      var tmp2_$this = Companion_getInstance_20();
      var tmp3_opaque = !isHierarchical;
      var tmp$ret$2;
      // Inline function 'kotlin.takeIf' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp_0;
      var tmp$ret$1;
      // Inline function 'korlibs.io.net.Companion.invoke.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.text.isNotEmpty' call
      tmp$ret$0 = charSequenceLength(host) > 0;
      tmp$ret$1 = tmp$ret$0;
      if (tmp$ret$1) {
        tmp_0 = host;
      } else {
        tmp_0 = null;
      }
      tmp$ret$2 = tmp_0;
      var tmp4_host = tmp$ret$2;
      var tmp5_path = !(path == null) ? '/' + path : '';
      var tmp0_safe_receiver = port;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toIntOrNull(tmp0_safe_receiver);
      var tmp6_port = tmp1_elvis_lhs == null ? this.g2q_1 : tmp1_elvis_lhs;
      tmp = tmp2_$this.h2s(scheme, userInfo, tmp4_host, tmp5_path, query, fragment, tmp3_opaque, tmp6_port);
    } else {
      var nonFragment_0 = substringBefore(url, _Char___init__impl__6a9atx(35));
      var fragment_0 = substringAfterOrNull(url, _Char___init__impl__6a9atx(35));
      var path_0 = substringBefore(nonFragment_0, _Char___init__impl__6a9atx(63));
      var query_0 = substringAfterOrNull(nonFragment_0, _Char___init__impl__6a9atx(63));
      tmp = Companion_getInstance_20().i2s(null, null, null, path_0, query_0, fragment_0, false);
    }
    return tmp;
  };
  protoOf(Companion_14).r2s = function (url) {
    return !((new StrReader(url)).o2s(this.h2q_1) == null);
  };
  protoOf(Companion_14).s2s = function (base, access) {
    var tmp;
    if (this.r2s(access)) {
      tmp = access;
    } else if (startsWith(access, '/')) {
      tmp = Companion_getInstance_20().b1n(base).r2k(VOID, VOID, VOID, VOID, access).s2k();
    } else {
      var tmp$ret$1;
      // Inline function 'kotlin.run' call
      var tmp0_run = Companion_getInstance_20().b1n(base);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.io.net.Companion.resolve.<anonymous>' call
      tmp$ret$0 = tmp0_run.r2k(VOID, VOID, VOID, VOID, '/' + trimStart(normalize(get_pathInfo(substringBeforeLast(tmp0_run.k2k_1, _Char___init__impl__6a9atx(47)) + '/' + access)), charArrayOf([_Char___init__impl__6a9atx(47)]))).s2k();
      tmp$ret$1 = tmp$ret$0;
      tmp = tmp$ret$1;
    }
    return tmp;
  };
  protoOf(Companion_14).f2s = function (s, charset, formUrlEncoded) {
    var bos = ByteArrayBuilder_init_$Create$();
    var len = s.length;
    var n = 0;
    while (n < len) {
      var c = charSequenceGet(s, n);
      var tmp0_subject = c;
      if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(37)))) {
        var tmp$ret$0;
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        var tmp0_append = toByte(toInt(substr(s, n + 1 | 0, 2), 16));
        bos.b1j(tmp0_append);
        tmp$ret$0 = bos;
        n = n + 2 | 0;
      } else if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(43))))
        if (formUrlEncoded) {
          var tmp$ret$1;
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var tmp1_append = 32;
          bos.b1j(tmp1_append);
          tmp$ret$1 = bos;
        } else {
          var tmp$ret$2;
          // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
          var tmp2_append = 43;
          bos.b1j(tmp2_append);
          tmp$ret$2 = bos;
        }
       else {
        var tmp$ret$3;
        // Inline function 'korlibs.memory.ByteArrayBuilder.append' call
        var tmp3_append = Char__toByte_impl_7s7yt0(c);
        bos.b1j(tmp3_append);
        tmp$ret$3 = bos;
      }
      var tmp1 = n;
      n = tmp1 + 1 | 0;
    }
    return toString_2(bos.c1j(), charset);
  };
  var Companion_instance_14;
  function Companion_getInstance_20() {
    if (Companion_instance_14 == null)
      new Companion_14();
    return Companion_instance_14;
  }
  function URL$fullUrl$delegate$lambda(this$0) {
    return function () {
      return this$0.t2s().toString();
    };
  }
  function URL$fullUrlWithoutScheme$delegate$lambda(this$0) {
    return function () {
      return this$0.t2s(false).toString();
    };
  }
  function URL$pathWithQuery$delegate$lambda(this$0) {
    return function () {
      var tmp;
      if (!(this$0.l2k_1 == null)) {
        tmp = this$0.k2k_1 + '?' + this$0.l2k_1;
      } else {
        tmp = this$0.k2k_1;
      }
      return tmp;
    };
  }
  function URL(isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort) {
    Companion_getInstance_20();
    this.g2k_1 = isOpaque;
    this.h2k_1 = scheme;
    this.i2k_1 = userInfo;
    this.j2k_1 = host;
    this.k2k_1 = path;
    this.l2k_1 = query;
    this.m2k_1 = fragment;
    this.n2k_1 = defaultPort;
    var tmp = this;
    tmp.o2k_1 = lazy(URL$fullUrl$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.p2k_1 = lazy(URL$fullUrlWithoutScheme$delegate$lambda(this));
    var tmp_1 = this;
    tmp_1.q2k_1 = lazy(URL$pathWithQuery$delegate$lambda(this));
  }
  protoOf(URL).u2s = function () {
    return Companion_getInstance_20().g2s(this.h2k_1);
  };
  protoOf(URL).v2s = function () {
    return this.n2k_1 === Companion_getInstance_20().g2q_1 ? this.u2s() : this.n2k_1;
  };
  protoOf(URL).s2k = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = fullUrl$factory();
    tmp$ret$0 = this.o2k_1.f1();
    return tmp$ret$0;
  };
  protoOf(URL).w2s = function (includeScheme, out) {
    if (includeScheme ? !(this.h2k_1 == null) : false) {
      out.bc('' + this.h2k_1 + ':');
      if (!this.g2k_1) {
        out.bc('//');
      }
    }
    if (!(this.i2k_1 == null)) {
      out.bc('' + this.i2k_1 + '@');
    }
    if (!(this.j2k_1 == null)) {
      out.bc(this.j2k_1);
    }
    if (!(this.v2s() === Companion_getInstance_20().g2q_1) ? !(this.v2s() === this.u2s()) : false) {
      out.ib(_Char___init__impl__6a9atx(58)).ac(this.v2s());
    }
    out.bc(this.k2k_1);
    if (!(this.l2k_1 == null)) {
      out.bc('?' + this.l2k_1);
    }
    if (!(this.m2k_1 == null)) {
      out.bc('#' + this.m2k_1);
    }
    return out;
  };
  protoOf(URL).t2s = function (includeScheme, out, $super) {
    includeScheme = includeScheme === VOID ? true : includeScheme;
    out = out === VOID ? StringBuilder_init_$Create$() : out;
    return $super === VOID ? this.w2s(includeScheme, out) : $super.w2s.call(this, includeScheme, out);
  };
  protoOf(URL).toString = function () {
    return this.s2k();
  };
  protoOf(URL).x2s = function (isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort) {
    return new URL(isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort);
  };
  protoOf(URL).r2k = function (isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort, $super) {
    isOpaque = isOpaque === VOID ? this.g2k_1 : isOpaque;
    scheme = scheme === VOID ? this.h2k_1 : scheme;
    userInfo = userInfo === VOID ? this.i2k_1 : userInfo;
    host = host === VOID ? this.j2k_1 : host;
    path = path === VOID ? this.k2k_1 : path;
    query = query === VOID ? this.l2k_1 : query;
    fragment = fragment === VOID ? this.m2k_1 : fragment;
    defaultPort = defaultPort === VOID ? this.n2k_1 : defaultPort;
    return $super === VOID ? this.x2s(isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort) : $super.x2s.call(this, isOpaque, scheme, userInfo, host, path, query, fragment, defaultPort);
  };
  protoOf(URL).hashCode = function () {
    var result = this.g2k_1 | 0;
    result = imul(result, 31) + (this.h2k_1 == null ? 0 : getStringHashCode(this.h2k_1)) | 0;
    result = imul(result, 31) + (this.i2k_1 == null ? 0 : getStringHashCode(this.i2k_1)) | 0;
    result = imul(result, 31) + (this.j2k_1 == null ? 0 : getStringHashCode(this.j2k_1)) | 0;
    result = imul(result, 31) + getStringHashCode(this.k2k_1) | 0;
    result = imul(result, 31) + (this.l2k_1 == null ? 0 : getStringHashCode(this.l2k_1)) | 0;
    result = imul(result, 31) + (this.m2k_1 == null ? 0 : getStringHashCode(this.m2k_1)) | 0;
    result = imul(result, 31) + this.n2k_1 | 0;
    return result;
  };
  protoOf(URL).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof URL))
      return false;
    var tmp0_other_with_cast = other instanceof URL ? other : THROW_CCE();
    if (!(this.g2k_1 === tmp0_other_with_cast.g2k_1))
      return false;
    if (!(this.h2k_1 == tmp0_other_with_cast.h2k_1))
      return false;
    if (!(this.i2k_1 == tmp0_other_with_cast.i2k_1))
      return false;
    if (!(this.j2k_1 == tmp0_other_with_cast.j2k_1))
      return false;
    if (!(this.k2k_1 === tmp0_other_with_cast.k2k_1))
      return false;
    if (!(this.l2k_1 == tmp0_other_with_cast.l2k_1))
      return false;
    if (!(this.m2k_1 == tmp0_other_with_cast.m2k_1))
      return false;
    if (!(this.n2k_1 === tmp0_other_with_cast.n2k_1))
      return false;
    return true;
  };
  function fullUrl$factory() {
    return getPropertyCallableRef('fullUrl', 1, KProperty1, function (receiver) {
      return receiver.s2k();
    }, null);
  }
  var Methods_ALL_instance;
  var Methods_OPTIONS_instance;
  var Methods_GET_instance;
  var Methods_HEAD_instance;
  var Methods_POST_instance;
  var Methods_PUT_instance;
  var Methods_DELETE_instance;
  var Methods_TRACE_instance;
  var Methods_CONNECT_instance;
  var Methods_PATCH_instance;
  var Methods_entriesInitialized;
  function Methods_initEntries() {
    if (Methods_entriesInitialized)
      return Unit_getInstance();
    Methods_entriesInitialized = true;
    Methods_ALL_instance = new Methods('ALL', 0);
    Methods_OPTIONS_instance = new Methods('OPTIONS', 1);
    Methods_GET_instance = new Methods('GET', 2);
    Methods_HEAD_instance = new Methods('HEAD', 3);
    Methods_POST_instance = new Methods('POST', 4);
    Methods_PUT_instance = new Methods('PUT', 5);
    Methods_DELETE_instance = new Methods('DELETE', 6);
    Methods_TRACE_instance = new Methods('TRACE', 7);
    Methods_CONNECT_instance = new Methods('CONNECT', 8);
    Methods_PATCH_instance = new Methods('PATCH', 9);
  }
  function Companion_15() {
    Companion_instance_15 = this;
    this.n2n_1 = Methods_OPTIONS_getInstance();
    this.o2n_1 = Methods_GET_getInstance();
    this.p2n_1 = Methods_HEAD_getInstance();
    this.q2n_1 = Methods_POST_getInstance();
    this.r2n_1 = Methods_PUT_getInstance();
    this.s2n_1 = Methods_DELETE_getInstance();
    this.t2n_1 = Methods_TRACE_getInstance();
    this.u2n_1 = Methods_CONNECT_getInstance();
    this.v2n_1 = Methods_PATCH_getInstance();
    this.w2n_1 = listOf_0([this.n2n_1, this.o2n_1, this.p2n_1, this.q2n_1, this.r2n_1, this.s2n_1, this.t2n_1, this.u2n_1, this.v2n_1]);
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = this.w2n_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.net.http.Companion.valuesMap.<anonymous>' call
      tmp$ret$0 = to(item.a2_1, item);
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.x2n_1 = toMap(tmp$ret$2);
  }
  var Companion_instance_15;
  function Companion_getInstance_21() {
    if (Companion_instance_15 == null)
      new Companion_15();
    return Companion_instance_15;
  }
  function Companion_16() {
    Companion_instance_16 = this;
  }
  var Companion_instance_16;
  function Companion_getInstance_22() {
    if (Companion_instance_16 == null)
      new Companion_16();
    return Companion_instance_16;
  }
  function Headers_init_$Init$(items, $this) {
    Headers.call($this, toList_3(items));
    return $this;
  }
  function Headers_init_$Create$(items) {
    return Headers_init_$Init$(items, objectCreate(protoOf(Headers)));
  }
  function Headers_init_$Init$_0(map, $this) {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(map.l());
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = map.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.io.net.http.Headers.<init>.<anonymous>' call
      tmp$ret$1 = to(item.e1(), item.f1());
      tmp0_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp0_mapTo;
    tmp$ret$3 = tmp$ret$2;
    Headers.call($this, tmp$ret$3);
    return $this;
  }
  function Headers_init_$Create$_0(map) {
    return Headers_init_$Init$_0(map, objectCreate(protoOf(Headers)));
  }
  function Headers_init_$Init$_1(str, $this) {
    Headers.call($this, Companion_getInstance_23().y2s(str).r2p_1);
    return $this;
  }
  function Headers_init_$Create$_1(str) {
    return Headers_init_$Init$_1(str, objectCreate(protoOf(Headers)));
  }
  function Companion_17() {
    Companion_instance_17 = this;
    this.n2p_1 = 'Content-Length';
    this.o2p_1 = 'Content-Type';
  }
  protoOf(Companion_17).y2s = function (str) {
    if (str == null)
      return Headers_init_$Create$([]);
    var tmp$ret$5;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = split(str, ['\n']);
    var tmp$ret$4;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$3;
      // Inline function 'korlibs.io.net.http.Companion.parse.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.text.trim' call
      tmp$ret$0 = toString(trim_0(isCharSequence(item) ? item : THROW_CCE()));
      var parts = split_0(tmp$ret$0, charArrayOf([_Char___init__impl__6a9atx(58)]), VOID, 2);
      var tmp;
      if (parts.l() >= 2) {
        var tmp$ret$1;
        // Inline function 'kotlin.text.trim' call
        var tmp0_trim = parts.n(0);
        tmp$ret$1 = toString(trim_0(isCharSequence(tmp0_trim) ? tmp0_trim : THROW_CCE()));
        var tmp_0 = tmp$ret$1;
        var tmp$ret$2;
        // Inline function 'kotlin.text.trim' call
        var tmp1_trim = parts.n(1);
        tmp$ret$2 = toString(trim_0(isCharSequence(tmp1_trim) ? tmp1_trim : THROW_CCE()));
        tmp = to(tmp_0, tmp$ret$2);
      } else {
        tmp = null;
      }
      tmp$ret$3 = tmp;
      tmp0_mapTo.b(tmp$ret$3);
    }
    tmp$ret$4 = tmp0_mapTo;
    tmp$ret$5 = tmp$ret$4;
    return new Headers(filterNotNull(tmp$ret$5));
  };
  var Companion_instance_17;
  function Companion_getInstance_23() {
    if (Companion_instance_17 == null)
      new Companion_17();
    return Companion_instance_17;
  }
  function sam$kotlin_Comparator$0_0(function_0) {
    this.z2s_1 = function_0;
  }
  protoOf(sam$kotlin_Comparator$0_0).l8 = function (a, b) {
    return this.z2s_1(a, b);
  };
  protoOf(sam$kotlin_Comparator$0_0).compare = function (a, b) {
    return this.l8(a, b);
  };
  function Http$Headers$toListGrouped$lambda(a, b) {
    var tmp$ret$6;
    // Inline function 'kotlin.comparisons.compareValuesBy' call
    var tmp$ret$2;
    // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp0_toLowerCase = a.a5_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_toLowerCase;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    tmp$ret$2 = tmp$ret$1;
    var tmp = tmp$ret$2;
    var tmp$ret$5;
    // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
    var tmp$ret$4;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp0_toLowerCase_0 = b.a5_1;
    var tmp$ret$3;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$3 = tmp0_toLowerCase_0;
    tmp$ret$4 = tmp$ret$3.toLowerCase();
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = compareValues(tmp, tmp$ret$5);
    return tmp$ret$6;
  }
  function Methods(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function HttpException(statusCode, msg, statusText, headers) {
    Companion_getInstance_22();
    msg = msg === VOID ? 'Error' + statusCode : msg;
    var tmp;
    if (statusText === VOID) {
      var tmp0_elvis_lhs = HttpStatusMessage_getInstance().a2t_1.h3(statusCode);
      tmp = tmp0_elvis_lhs == null ? 'Error' + statusCode : tmp0_elvis_lhs;
    } else {
      tmp = statusText;
    }
    statusText = tmp;
    headers = headers === VOID ? Headers_init_$Create$([]) : headers;
    IOException.call(this, '' + statusCode + ' ' + statusText + ' - ' + msg);
    captureStack(this, HttpException);
    this.b2t_1 = statusCode;
    this.c2t_1 = msg;
    this.d2t_1 = statusText;
    this.e2t_1 = headers;
  }
  function Headers(items) {
    Companion_getInstance_23();
    this.r2p_1 = items;
  }
  protoOf(Headers).h = function () {
    return this.r2p_1.h();
  };
  protoOf(Headers).p1b = function (key) {
    return this.f2t(key);
  };
  protoOf(Headers).f2t = function (key) {
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.firstOrNull' call
      var tmp0_firstOrNull = this.r2p_1;
      var tmp0_iterator = tmp0_firstOrNull.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'korlibs.io.net.http.Headers.getFirst.<anonymous>' call
        tmp$ret$0 = equals_0(element.a5_1, key, true);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      tmp$ret$1 = null;
    }
    var tmp0_safe_receiver = tmp$ret$1;
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver.b5_1;
  };
  protoOf(Headers).g2t = function () {
    var tmp$ret$15;
    // Inline function 'kotlin.collections.sortedBy' call
    var tmp$ret$13;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$6;
    // Inline function 'kotlin.collections.groupBy' call
    var tmp1_groupBy = this.r2p_1;
    var tmp$ret$5;
    // Inline function 'kotlin.collections.groupByTo' call
    var tmp0_groupByTo = LinkedHashMap_init_$Create$();
    var tmp0_iterator = tmp1_groupBy.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.text.toLowerCase' call
      var tmp0_toLowerCase = element.a5_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_toLowerCase;
      tmp$ret$1 = tmp$ret$0.toLowerCase();
      tmp$ret$2 = tmp$ret$1;
      var key = tmp$ret$2;
      var tmp$ret$4;
      // Inline function 'kotlin.collections.getOrPut' call
      var value = tmp0_groupByTo.h3(key);
      var tmp;
      if (value == null) {
        var tmp$ret$3;
        // Inline function 'kotlin.collections.groupByTo.<anonymous>' call
        tmp$ret$3 = ArrayList_init_$Create$_0();
        var answer = tmp$ret$3;
        tmp0_groupByTo.e5(key, answer);
        tmp = answer;
      } else {
        tmp = value;
      }
      tmp$ret$4 = tmp;
      var list = tmp$ret$4;
      list.b(element);
    }
    tmp$ret$5 = tmp0_groupByTo;
    tmp$ret$6 = tmp$ret$5;
    var tmp3_map = tmp$ret$6;
    var tmp$ret$12;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp2_mapTo = ArrayList_init_$Create$(tmp3_map.l());
    var tmp$ret$7;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$7 = tmp3_map.d1().h();
    var tmp0_iterator_0 = tmp$ret$7;
    while (tmp0_iterator_0.j()) {
      var item = tmp0_iterator_0.k();
      var tmp$ret$11;
      // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>' call
      var tmp_0 = first(item.f1()).a5_1;
      var tmp$ret$10;
      // Inline function 'kotlin.collections.map' call
      var tmp1_map = item.f1();
      var tmp$ret$9;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
      var tmp0_iterator_1 = tmp1_map.h();
      while (tmp0_iterator_1.j()) {
        var item_0 = tmp0_iterator_1.k();
        var tmp$ret$8;
        // Inline function 'korlibs.io.net.http.Headers.toListGrouped.<anonymous>.<anonymous>' call
        tmp$ret$8 = item_0.b5_1;
        tmp0_mapTo.b(tmp$ret$8);
      }
      tmp$ret$9 = tmp0_mapTo;
      tmp$ret$10 = tmp$ret$9;
      tmp$ret$11 = to(tmp_0, tmp$ret$10);
      tmp2_mapTo.b(tmp$ret$11);
    }
    tmp$ret$12 = tmp2_mapTo;
    tmp$ret$13 = tmp$ret$12;
    var tmp4_sortedBy = tmp$ret$13;
    var tmp$ret$14;
    // Inline function 'kotlin.comparisons.compareBy' call
    var tmp_1 = Http$Headers$toListGrouped$lambda;
    tmp$ret$14 = new sam$kotlin_Comparator$0_0(tmp_1);
    tmp$ret$15 = sortedWith(tmp4_sortedBy, tmp$ret$14);
    return tmp$ret$15;
  };
  protoOf(Headers).h2t = function (newHeaders) {
    var tmp$ret$4;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(newHeaders, 10));
    var tmp0_iterator = newHeaders.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$2;
      // Inline function 'korlibs.io.net.http.Headers.withReplaceHeaders.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.text.toLowerCase' call
      var tmp0_toLowerCase = item.a5_1;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_toLowerCase;
      tmp$ret$1 = tmp$ret$0.toLowerCase();
      tmp$ret$2 = tmp$ret$1;
      tmp0_mapTo.b(tmp$ret$2);
    }
    tmp$ret$3 = tmp0_mapTo;
    tmp$ret$4 = tmp$ret$3;
    var replaceKeys = toSet(tmp$ret$4);
    var tmp$ret$9;
    // Inline function 'kotlin.collections.filter' call
    var tmp2_filter = this.r2p_1;
    var tmp$ret$8;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp1_filterTo = ArrayList_init_$Create$_0();
    var tmp0_iterator_0 = tmp2_filter.h();
    while (tmp0_iterator_0.j()) {
      var element = tmp0_iterator_0.k();
      var tmp$ret$7;
      // Inline function 'korlibs.io.net.http.Headers.withReplaceHeaders.<anonymous>' call
      var tmp$ret$6;
      // Inline function 'kotlin.text.toLowerCase' call
      var tmp0_toLowerCase_0 = element.a5_1;
      var tmp$ret$5;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$5 = tmp0_toLowerCase_0;
      tmp$ret$6 = tmp$ret$5.toLowerCase();
      tmp$ret$7 = !replaceKeys.q(tmp$ret$6);
      if (tmp$ret$7) {
        tmp1_filterTo.b(element);
      }
    }
    tmp$ret$8 = tmp1_filterTo;
    tmp$ret$9 = tmp$ret$8;
    return new Headers(plus_0(tmp$ret$9, toList(newHeaders)));
  };
  protoOf(Headers).s2p = function (newHeaders) {
    return this.h2t(toList_3(newHeaders));
  };
  protoOf(Headers).i2t = function (newHeaders) {
    return this.h2t(toList(newHeaders));
  };
  protoOf(Headers).toString = function () {
    return 'Headers(' + joinToString(this.g2t(), ', ') + ')';
  };
  protoOf(Headers).hashCode = function () {
    return hashCode(this.r2p_1);
  };
  protoOf(Headers).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Headers))
      return false;
    var tmp0_other_with_cast = other instanceof Headers ? other : THROW_CCE();
    if (!equals(this.r2p_1, tmp0_other_with_cast.r2p_1))
      return false;
    return true;
  };
  function Methods_OPTIONS_getInstance() {
    Methods_initEntries();
    return Methods_OPTIONS_instance;
  }
  function Methods_GET_getInstance() {
    Methods_initEntries();
    return Methods_GET_instance;
  }
  function Methods_HEAD_getInstance() {
    Methods_initEntries();
    return Methods_HEAD_instance;
  }
  function Methods_POST_getInstance() {
    Methods_initEntries();
    return Methods_POST_instance;
  }
  function Methods_PUT_getInstance() {
    Methods_initEntries();
    return Methods_PUT_instance;
  }
  function Methods_DELETE_getInstance() {
    Methods_initEntries();
    return Methods_DELETE_instance;
  }
  function Methods_TRACE_getInstance() {
    Methods_initEntries();
    return Methods_TRACE_instance;
  }
  function Methods_CONNECT_getInstance() {
    Methods_initEntries();
    return Methods_CONNECT_instance;
  }
  function Methods_PATCH_getInstance() {
    Methods_initEntries();
    return Methods_PATCH_instance;
  }
  function get__defaultHttpFactory() {
    _init_properties_HttpClient_kt__huy6s3();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _defaultHttpFactory$factory();
    tmp$ret$0 = _defaultHttpFactory$delegate.f1();
    return tmp$ret$0;
  }
  var _defaultHttpFactory$delegate;
  function Companion_18() {
    Companion_instance_18 = this;
  }
  var Companion_instance_18;
  function Companion_getInstance_24() {
    if (Companion_instance_18 == null)
      new Companion_18();
    return Companion_instance_18;
  }
  function HttpClient$Response$responseCharset$delegate$lambda() {
    return get_UTF8();
  }
  function $readAllBytesCOROUTINE$127(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.r2t_1 = _this__u8e3s4;
  }
  protoOf($readAllBytesCOROUTINE$127).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = readAll(this.r2t_1.s2l_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var allContent = suspendResult;
            return allContent;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readAllStringCOROUTINE$128(_this__u8e3s4, charset, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.a2u_1 = _this__u8e3s4;
    this.b2u_1 = charset;
  }
  protoOf($readAllStringCOROUTINE$128).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.a2u_1.c2u(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var bytes = suspendResult;
            return toString_2(bytes, this.b2u_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $checkErrorsCOROUTINE$129(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l2u_1 = _this__u8e3s4;
  }
  protoOf($checkErrorsCOROUTINE$129).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            if (!this.l2u_1.t2l_1) {
              this.m2u_1 = this.l2u_1.o2l_1;
              this.y7_1 = 2;
              suspendResult = this.l2u_1.n2u(VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            return this.l2u_1;
          case 2:
            var ARGUMENT = suspendResult;
            throw new HttpException(this.m2u_1, ARGUMENT, this.l2u_1.p2l_1);
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function Companion_19() {
    Companion_instance_19 = this;
    this.o2u_1 = new RequestConfig();
  }
  var Companion_instance_19;
  function Companion_getInstance_25() {
    if (Companion_instance_19 == null)
      new Companion_19();
    return Companion_instance_19;
  }
  function Response(status, statusText, headers, rawContent, content) {
    Companion_getInstance_24();
    this.o2l_1 = status;
    this.p2l_1 = statusText;
    this.q2l_1 = headers;
    this.r2l_1 = rawContent;
    this.s2l_1 = content;
    this.t2l_1 = this.o2l_1 < 400;
    var tmp = this;
    tmp.u2l_1 = lazy(HttpClient$Response$responseCharset$delegate$lambda);
  }
  protoOf(Response).c2u = function ($completion) {
    var tmp = new $readAllBytesCOROUTINE$127(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Response).p2u = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = responseCharset$factory();
    tmp$ret$0 = this.u2l_1.f1();
    return tmp$ret$0;
  };
  protoOf(Response).q2u = function (charset, $completion) {
    var tmp = new $readAllStringCOROUTINE$128(this, charset, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Response).n2u = function (charset, $completion, $super) {
    charset = charset === VOID ? this.p2u() : charset;
    return $super === VOID ? this.q2u(charset, $completion) : $super.q2u.call(this, charset, $completion);
  };
  protoOf(Response).z2n = function ($completion) {
    var tmp = new $checkErrorsCOROUTINE$129(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Response).r2u = function (content) {
    return new CompletedResponse(this.o2l_1, this.p2l_1, this.q2l_1, content);
  };
  protoOf(Response).toString = function () {
    return 'Response(status=' + this.o2l_1 + ', statusText=' + this.p2l_1 + ', headers=' + this.q2l_1 + ', rawContent=' + this.r2l_1 + ', content=' + this.s2l_1 + ')';
  };
  protoOf(Response).hashCode = function () {
    var result = this.o2l_1;
    result = imul(result, 31) + getStringHashCode(this.p2l_1) | 0;
    result = imul(result, 31) + this.q2l_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.r2l_1) | 0;
    result = imul(result, 31) + hashCode(this.s2l_1) | 0;
    return result;
  };
  protoOf(Response).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Response))
      return false;
    var tmp0_other_with_cast = other instanceof Response ? other : THROW_CCE();
    if (!(this.o2l_1 === tmp0_other_with_cast.o2l_1))
      return false;
    if (!(this.p2l_1 === tmp0_other_with_cast.p2l_1))
      return false;
    if (!this.q2l_1.equals(tmp0_other_with_cast.q2l_1))
      return false;
    if (!equals(this.r2l_1, tmp0_other_with_cast.r2l_1))
      return false;
    if (!equals(this.s2l_1, tmp0_other_with_cast.s2l_1))
      return false;
    return true;
  };
  function CompletedResponse(status, statusText, headers, content) {
    this.s2u_1 = status;
    this.t2u_1 = statusText;
    this.u2u_1 = headers;
    this.v2u_1 = content;
    this.w2u_1 = this.s2u_1 < 400;
  }
  protoOf(CompletedResponse).toString = function () {
    return 'CompletedResponse(status=' + this.s2u_1 + ', statusText=' + this.t2u_1 + ', headers=' + this.u2u_1 + ', content=' + this.v2u_1 + ')';
  };
  protoOf(CompletedResponse).hashCode = function () {
    var result = this.s2u_1;
    result = imul(result, 31) + getStringHashCode(this.t2u_1) | 0;
    result = imul(result, 31) + this.u2u_1.hashCode() | 0;
    result = imul(result, 31) + (this.v2u_1 == null ? 0 : hashCode(this.v2u_1)) | 0;
    return result;
  };
  protoOf(CompletedResponse).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CompletedResponse))
      return false;
    var tmp0_other_with_cast = other instanceof CompletedResponse ? other : THROW_CCE();
    if (!(this.s2u_1 === tmp0_other_with_cast.s2u_1))
      return false;
    if (!(this.t2u_1 === tmp0_other_with_cast.t2u_1))
      return false;
    if (!this.u2u_1.equals(tmp0_other_with_cast.u2u_1))
      return false;
    if (!equals(this.v2u_1, tmp0_other_with_cast.v2u_1))
      return false;
    return true;
  };
  function RequestConfig(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) {
    Companion_getInstance_25();
    followRedirects = followRedirects === VOID ? true : followRedirects;
    throwErrors = throwErrors === VOID ? false : throwErrors;
    maxRedirects = maxRedirects === VOID ? 10 : maxRedirects;
    referer = referer === VOID ? null : referer;
    simulateBrowser = simulateBrowser === VOID ? false : simulateBrowser;
    this.x2u_1 = followRedirects;
    this.y2u_1 = throwErrors;
    this.z2u_1 = maxRedirects;
    this.a2v_1 = referer;
    this.b2v_1 = simulateBrowser;
  }
  protoOf(RequestConfig).c2v = function (followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) {
    return new RequestConfig(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser);
  };
  protoOf(RequestConfig).d2v = function (followRedirects, throwErrors, maxRedirects, referer, simulateBrowser, $super) {
    followRedirects = followRedirects === VOID ? this.x2u_1 : followRedirects;
    throwErrors = throwErrors === VOID ? this.y2u_1 : throwErrors;
    maxRedirects = maxRedirects === VOID ? this.z2u_1 : maxRedirects;
    referer = referer === VOID ? this.a2v_1 : referer;
    simulateBrowser = simulateBrowser === VOID ? this.b2v_1 : simulateBrowser;
    return $super === VOID ? this.c2v(followRedirects, throwErrors, maxRedirects, referer, simulateBrowser) : $super.c2v.call(this, followRedirects, throwErrors, maxRedirects, referer, simulateBrowser);
  };
  protoOf(RequestConfig).toString = function () {
    return 'RequestConfig(followRedirects=' + this.x2u_1 + ', throwErrors=' + this.y2u_1 + ', maxRedirects=' + this.z2u_1 + ', referer=' + this.a2v_1 + ', simulateBrowser=' + this.b2v_1 + ')';
  };
  protoOf(RequestConfig).hashCode = function () {
    var result = this.x2u_1 | 0;
    result = imul(result, 31) + (this.y2u_1 | 0) | 0;
    result = imul(result, 31) + this.z2u_1 | 0;
    result = imul(result, 31) + (this.a2v_1 == null ? 0 : getStringHashCode(this.a2v_1)) | 0;
    result = imul(result, 31) + (this.b2v_1 | 0) | 0;
    return result;
  };
  protoOf(RequestConfig).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RequestConfig))
      return false;
    var tmp0_other_with_cast = other instanceof RequestConfig ? other : THROW_CCE();
    if (!(this.x2u_1 === tmp0_other_with_cast.x2u_1))
      return false;
    if (!(this.y2u_1 === tmp0_other_with_cast.y2u_1))
      return false;
    if (!(this.z2u_1 === tmp0_other_with_cast.z2u_1))
      return false;
    if (!(this.a2v_1 == tmp0_other_with_cast.a2v_1))
      return false;
    if (!(this.b2v_1 === tmp0_other_with_cast.b2v_1))
      return false;
    return true;
  };
  function mergeUrls($this, base, append) {
    return Companion_getInstance_20().s2s(base, append);
  }
  function Companion_20() {
    Companion_instance_20 = this;
    this.e2v_1 = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.81 Safari/537.36';
    this.f2v_1 = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8';
    this.g2v_1 = 'en-us';
    this.h2v_1 = 'gzip, deflate';
    this.i2v_1 = 'Close';
  }
  protoOf(Companion_20).j2v = function (headers, host) {
    var out = Headers_init_$Create$([to('User-Agent', this.e2v_1), to('Accept', this.f2v_1), to('Accept-Language', this.g2v_1), to('Accept-Encoding', this.h2v_1), to('Connection', this.i2v_1)]).i2t(headers);
    return !(host == null) ? out.s2p([to('Host', host)]) : out;
  };
  var Companion_instance_20;
  function Companion_getInstance_26() {
    if (Companion_instance_20 == null)
      new Companion_20();
    return Companion_instance_20;
  }
  function $requestCOROUTINE$132(_this__u8e3s4, method, url, headers, content, config, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s2v_1 = _this__u8e3s4;
    this.t2v_1 = method;
    this.u2v_1 = url;
    this.v2v_1 = headers;
    this.w2v_1 = content;
    this.x2v_1 = config;
  }
  protoOf($requestCOROUTINE$132).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 9;
            this.y2v_1 = this.w2v_1;
            if (this.y2v_1 == null) {
              this.z2v_1 = null;
              this.y7_1 = 2;
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = this.y2v_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.z2v_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.a2w_1 = this.z2v_1;
            this.b2w_1 = this.a2w_1 == null ? new Long(0, 0) : this.a2w_1;
            this.c2w_1 = this.v2v_1;
            var tmp_0;
            if (!(this.w2v_1 == null)) {
              var tmp$ret$0;
              l$ret$1: do {
                var tmp_1;
                var tmp_2 = this.v2v_1;
                if (isInterface(tmp_2, Collection)) {
                  tmp_1 = this.v2v_1.p();
                } else {
                  tmp_1 = false;
                }
                if (tmp_1) {
                  tmp$ret$0 = false;
                  break l$ret$1;
                }
                var tmp0_iterator = this.v2v_1.h();
                while (tmp0_iterator.j()) {
                  var element = tmp0_iterator.k();
                  if (equals_0(element.a5_1, Companion_getInstance_23().n2p_1, true)) {
                    tmp$ret$0 = true;
                    break l$ret$1;
                  }
                }
                tmp$ret$0 = false;
              }
               while (false);
              tmp_0 = !tmp$ret$0;
            } else {
              tmp_0 = false;
            }

            if (tmp_0) {
              this.c2w_1 = this.c2w_1.s2p([to(Companion_getInstance_23().n2p_1, toString(this.b2w_1))]);
            }

            if (this.x2v_1.b2v_1) {
              this.c2w_1 = Companion_getInstance_26().j2v(this.c2w_1, null);
            }

            this.y7_1 = 3;
            suspendResult = this.s2v_1.g2w(this.t2v_1, this.u2v_1, this.c2w_1, this.w2v_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 3:
            this.d2w_1 = suspendResult;
            if (this.x2v_1.y2u_1) {
              this.y7_1 = 4;
              suspendResult = this.d2w_1.z2n(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 5;
              continue $sm;
            }

            break;
          case 4:
            ;
            this.y7_1 = 5;
            continue $sm;
          case 5:
            this.e2w_1 = this.d2w_1;
            if (this.x2v_1.x2u_1 ? this.x2v_1.z2u_1 >= 0 : false) {
              this.f2w_1 = this.e2w_1.q2l_1.p1b('location');
              if (!(this.f2w_1 == null)) {
                this.y7_1 = 8;
                suspendResult = this.s2v_1.h2w(this.t2v_1, mergeUrls(this.s2v_1, this.u2v_1, this.f2w_1), this.v2v_1.s2p([to('Referer', this.u2v_1)]), this.w2v_1, this.x2v_1.d2v(VOID, VOID, this.x2v_1.z2u_1 - 1 | 0), this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              } else {
                this.y7_1 = 6;
                continue $sm;
              }
            } else {
              this.y7_1 = 7;
              continue $sm;
            }

            break;
          case 6:
            this.y7_1 = 7;
            continue $sm;
          case 7:
            return this.e2w_1;
          case 8:
            return suspendResult;
          case 9:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 9) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $requestAsBytesCOROUTINE$134(_this__u8e3s4, method, url, headers, content, config, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.q2w_1 = _this__u8e3s4;
    this.r2w_1 = method;
    this.s2w_1 = url;
    this.t2w_1 = headers;
    this.u2w_1 = content;
    this.v2w_1 = config;
  }
  protoOf($requestAsBytesCOROUTINE$134).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.q2w_1.h2w(this.r2w_1, this.s2w_1, this.t2w_1, this.u2w_1, this.v2w_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.w2w_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.w2w_1.c2u(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            return this.w2w_1.r2u(ARGUMENT);
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesCOROUTINE$135(_this__u8e3s4, url, config, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.f2x_1 = _this__u8e3s4;
    this.g2x_1 = url;
    this.h2x_1 = config;
  }
  protoOf($readBytesCOROUTINE$135).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.f2x_1.i2x(Companion_getInstance_21().o2n_1, this.g2x_1, VOID, VOID, this.h2x_1.d2v(VOID, true), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return ARGUMENT.v2u_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function HttpClient() {
    Companion_getInstance_26();
    this.l2n_1 = false;
  }
  protoOf(HttpClient).h2w = function (method, url, headers, content, config, $completion) {
    var tmp = new $requestCOROUTINE$132(this, method, url, headers, content, config, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(HttpClient).y2n = function (method, url, headers, content, config, $completion, $super) {
    headers = headers === VOID ? Headers_init_$Create$([]) : headers;
    content = content === VOID ? null : content;
    config = config === VOID ? Companion_getInstance_25().o2u_1 : config;
    return $super === VOID ? this.h2w(method, url, headers, content, config, $completion) : $super.h2w.call(this, method, url, headers, content, config, $completion);
  };
  protoOf(HttpClient).j2x = function (method, url, headers, content, config, $completion) {
    var tmp = new $requestAsBytesCOROUTINE$134(this, method, url, headers, content, config, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(HttpClient).i2x = function (method, url, headers, content, config, $completion, $super) {
    headers = headers === VOID ? Headers_init_$Create$([]) : headers;
    content = content === VOID ? null : content;
    config = config === VOID ? new RequestConfig() : config;
    return $super === VOID ? this.j2x(method, url, headers, content, config, $completion) : $super.j2x.call(this, method, url, headers, content, config, $completion);
  };
  protoOf(HttpClient).k2x = function (url, config, $completion) {
    var tmp = new $readBytesCOROUTINE$135(this, url, config, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(HttpClient).m2n = function (url, config, $completion, $super) {
    config = config === VOID ? new RequestConfig() : config;
    return $super === VOID ? this.k2x(url, config, $completion) : $super.k2x.call(this, url, config, $completion);
  };
  function createHttpClient() {
    _init_properties_HttpClient_kt__huy6s3();
    return get_defaultHttpFactory().l2x();
  }
  function HttpStatusMessage() {
    HttpStatusMessage_instance = this;
    this.a2t_1 = linkedMapOf([to(100, 'Continue'), to(101, 'Switching Protocols'), to(200, 'OK'), to(201, 'Created'), to(202, 'Accepted'), to(203, 'Non-Authoritative Information'), to(204, 'No Content'), to(205, 'Reset Content'), to(206, 'Partial Content'), to(300, 'Multiple Choices'), to(301, 'Moved Permanently'), to(302, 'Found'), to(303, 'See Other'), to(304, 'Not Modified'), to(305, 'Use Proxy'), to(307, 'Temporary Redirect'), to(400, 'Bad Request'), to(401, 'Unauthorized'), to(402, 'Payment Required'), to(403, 'Forbidden'), to(404, 'Not Found'), to(405, 'Method Not Allowed'), to(406, 'Not Acceptable'), to(407, 'Proxy Authentication Required'), to(408, 'Request Timeout'), to(409, 'Conflict'), to(410, 'Gone'), to(411, 'Length Required'), to(412, 'Precondition Failed'), to(413, 'Request Entity Too Large'), to(414, 'Request-URI Too Long'), to(415, 'Unsupported Media Type'), to(416, 'Requested Range Not Satisfiable'), to(417, 'Expectation Failed'), to(418, "I'm a teapot"), to(422, 'Unprocessable Entity (WebDAV - RFC 4918)'), to(423, 'Locked (WebDAV - RFC 4918)'), to(424, 'Failed Dependency (WebDAV) (RFC 4918)'), to(425, 'Unassigned'), to(426, 'Upgrade Required (RFC 7231)'), to(428, 'Precondition Required'), to(429, 'Too Many Requests'), to(431, 'Request Header Fileds Too Large)'), to(449, 'Error449'), to(451, 'Unavailable for Legal Reasons'), to(500, 'Internal Server Error'), to(501, 'Not Implemented'), to(502, 'Bad Gateway'), to(503, 'Service Unavailable'), to(504, 'Gateway Timeout'), to(505, 'HTTP Version Not Supported'), to(506, 'Variant Also Negotiates (RFC 2295)'), to(507, 'Insufficient Storage (WebDAV - RFC 4918)'), to(508, 'Loop Detected (WebDAV)'), to(509, 'Bandwidth Limit Exceeded'), to(510, 'Not Extended (RFC 2774)'), to(511, 'Network Authentication Required')]);
  }
  var HttpStatusMessage_instance;
  function HttpStatusMessage_getInstance() {
    if (HttpStatusMessage_instance == null)
      new HttpStatusMessage();
    return HttpStatusMessage_instance;
  }
  function ProxiedHttpFactory(parent) {
    this.m2x_1 = parent;
    this.n2x_1 = parent;
  }
  protoOf(ProxiedHttpFactory).l2x = function () {
    return this.n2x_1.l2x();
  };
  function get_defaultHttpFactory() {
    _init_properties_HttpClient_kt__huy6s3();
    return get__defaultHttpFactory();
  }
  function _defaultHttpFactory$delegate$lambda() {
    _init_properties_HttpClient_kt__huy6s3();
    return new ProxiedHttpFactory(get_httpFactory());
  }
  function _defaultHttpFactory$factory() {
    return getPropertyCallableRef('_defaultHttpFactory', 0, KProperty0, function () {
      return get__defaultHttpFactory();
    }, null);
  }
  function responseCharset$factory() {
    return getPropertyCallableRef('responseCharset', 1, KProperty1, function (receiver) {
      return receiver.p2u();
    }, null);
  }
  var properties_initialized_HttpClient_kt_t7kzvv;
  function _init_properties_HttpClient_kt__huy6s3() {
    if (properties_initialized_HttpClient_kt_t7kzvv) {
    } else {
      properties_initialized_HttpClient_kt_t7kzvv = true;
      _defaultHttpFactory$delegate = lazy(_defaultHttpFactory$delegate$lambda);
    }
  }
  function Fixed(value) {
    this.o2x_1 = value;
  }
  protoOf(Fixed).p2x = function () {
    return this.o2x_1;
  };
  protoOf(Fixed).toString = function () {
    return 'Fixed(value=' + this.o2x_1 + ')';
  };
  protoOf(Fixed).hashCode = function () {
    return hashCode(this.o2x_1);
  };
  protoOf(Fixed).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Fixed))
      return false;
    var tmp0_other_with_cast = other instanceof Fixed ? other : THROW_CCE();
    if (!equals(this.o2x_1, tmp0_other_with_cast.o2x_1))
      return false;
    return true;
  };
  function Resourceable(value) {
    return new Fixed(value);
  }
  function Resources(coroutineContext, root, parent) {
    root = root === VOID ? get_resourcesVfs() : root;
    parent = parent === VOID ? null : parent;
    this.q2x_1 = coroutineContext;
    this.r2x_1 = root;
    this.s2x_1 = parent;
    this.t2x_1 = LinkedHashMap_init_$Create$();
  }
  function Companion_21() {
    Companion_instance_21 = this;
    this.u2x_1 = new Context(false, false);
    this.v2x_1 = new Context(true, true);
  }
  var Companion_instance_21;
  function Companion_getInstance_27() {
    if (Companion_instance_21 == null)
      new Companion_21();
    return Companion_instance_21;
  }
  function Context(optimizedNumericLists, useFastArrayList) {
    Companion_getInstance_27();
    useFastArrayList = useFastArrayList === VOID ? false : useFastArrayList;
    this.w2x_1 = optimizedNumericLists;
    this.x2x_1 = useFastArrayList;
  }
  protoOf(Context).y2x = function () {
    return this.x2x_1 ? FastArrayList_init_$Create$() : ArrayList_init_$Create$_0();
  };
  protoOf(Context).toString = function () {
    return 'Context(optimizedNumericLists=' + this.w2x_1 + ', useFastArrayList=' + this.x2x_1 + ')';
  };
  protoOf(Context).hashCode = function () {
    var result = this.w2x_1 | 0;
    result = imul(result, 31) + (this.x2x_1 | 0) | 0;
    return result;
  };
  protoOf(Context).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Context))
      return false;
    var tmp0_other_with_cast = other instanceof Context ? other : THROW_CCE();
    if (!(this.w2x_1 === tmp0_other_with_cast.w2x_1))
      return false;
    if (!(this.x2x_1 === tmp0_other_with_cast.x2x_1))
      return false;
    return true;
  };
  function parseBooleanOrNull($this, s, context) {
    var tmp;
    if (s.z2x('true')) {
      tmp = true;
    } else if (s.z2x('false')) {
      tmp = false;
    } else if (s.z2x('null')) {
      tmp = null;
    } else {
      invalidJson$default($this);
    }
    return tmp;
  }
  function parseObject($this, s, context) {
    s.a2y(_Char___init__impl__6a9atx(123));
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = LinkedHashMap_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.json.Json.parseObject.<anonymous>' call
    obj: while (true) {
      var tmp0_subject = s.b2y().c2y();
      if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(125))))
        break obj;
      else if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(44))))
        continue obj;
      else {
        s.d2y();
      }
      var tmp = Json_getInstance().e2y(s, context);
      var key = (!(tmp == null) ? typeof tmp === 'string' : false) ? tmp : THROW_CCE();
      s.b2y().a2y(_Char___init__impl__6a9atx(58));
      var value = Json_getInstance().e2y(s, context);
      // Inline function 'kotlin.collections.set' call
      tmp0_apply.e5(key, value);
    }
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function parseArray($this, s, context) {
    var out = null;
    var outNumber = null;
    s.a2y(_Char___init__impl__6a9atx(91));
    array: while (true) {
      var tmp0_subject = s.b2y().c2y();
      if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(93))))
        break array;
      else if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(44))))
        continue array;
      else {
        s.d2y();
      }
      var v = s.f2y();
      if ((out == null ? context.w2x_1 : false) ? (_Char___init__impl__6a9atx(48) <= v ? v <= _Char___init__impl__6a9atx(57) : false) ? true : equals(new Char(v), new Char(_Char___init__impl__6a9atx(45))) : false) {
        if (outNumber == null) {
          outNumber = new DoubleArrayList();
        }
        outNumber.n19(parseNumber($this, s));
      } else {
        if (out == null)
          out = context.y2x();
        if (!(outNumber == null)) {
          // Inline function 'korlibs.datastructure.iterators.fastForEach' call
          var tmp0_fastForEach = outNumber;
          var n = 0;
          while (n < tmp0_fastForEach.l()) {
            // Inline function 'korlibs.io.serialization.json.Json.parseArray.<anonymous>' call
            var tmp0 = n;
            n = tmp0 + 1 | 0;
            var tmp1__anonymous__uwfjfc = tmp0_fastForEach.z18(tmp0);
            out.b(tmp1__anonymous__uwfjfc);
          }
          outNumber = null;
        }
        out.b($this.e2y(s, context));
      }
    }
    var tmp1_elvis_lhs = outNumber;
    var tmp2_elvis_lhs = tmp1_elvis_lhs == null ? out : tmp1_elvis_lhs;
    return tmp2_elvis_lhs == null ? context.y2x() : tmp2_elvis_lhs;
  }
  function parseNumber($this, s) {
    var start = s.l2s_1;
    // Inline function 'korlibs.io.util.BaseStrReader.skipWhile' call
    $l$loop: while (true) {
      var tmp;
      if (s.q2r()) {
        var tmp$ret$0;
        // Inline function 'korlibs.io.serialization.json.Json.parseNumber.<anonymous>' call
        var tmp0__anonymous__q1qw7t = s.f2y();
        tmp$ret$0 = (((((Char__compareTo_impl_ypi4mb(tmp0__anonymous__q1qw7t, _Char___init__impl__6a9atx(48)) >= 0 ? Char__compareTo_impl_ypi4mb(tmp0__anonymous__q1qw7t, _Char___init__impl__6a9atx(57)) <= 0 : false) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(46)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(101)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(69)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(45)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(43)));
        tmp = tmp$ret$0;
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$loop;
      }
      s.g2y();
    }
    var end = s.l2s_1;
    return NumberParser_getInstance().h2y(s.j2s_1, start, end);
  }
  function invalidJson($this, msg) {
    throw new IOException(msg);
  }
  function invalidJson$default($this, msg, $super) {
    msg = msg === VOID ? 'Invalid JSON' : msg;
    return invalidJson($this, msg);
  }
  function Json() {
    Json_instance = this;
  }
  protoOf(Json).i2y = function (s, context) {
    return this.e2y(new StrReader(s), context);
  };
  protoOf(Json).w2i = function (s, context, $super) {
    context = context === VOID ? Companion_getInstance_27().u2x_1 : context;
    return $super === VOID ? this.i2y(s, context) : $super.i2y.call(this, s, context);
  };
  protoOf(Json).j2y = function (s, context) {
    return get_dyn(this.i2y(s, context));
  };
  protoOf(Json).k2y = function (s, context, $super) {
    context = context === VOID ? Companion_getInstance_27().u2x_1 : context;
    return $super === VOID ? this.j2y(s, context) : $super.j2y.call(this, s, context).k21_1;
  };
  protoOf(Json).e2y = function (s, context) {
    var ic = s.b2y().f2y();
    var tmp;
    if (equals(new Char(ic), new Char(_Char___init__impl__6a9atx(123)))) {
      tmp = parseObject(this, s, context);
    } else if (equals(new Char(ic), new Char(_Char___init__impl__6a9atx(91)))) {
      tmp = parseArray(this, s, context);
    } else if (((((((((((equals(new Char(ic), new Char(_Char___init__impl__6a9atx(45))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(43)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(48)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(49)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(50)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(51)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(52)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(53)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(54)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(55)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(56)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(57)))) {
      var dres = parseNumber(this, s);
      tmp = numberToInt(dres) === dres ? numberToInt(dres) : dres;
    } else if ((equals(new Char(ic), new Char(_Char___init__impl__6a9atx(116))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(102)))) ? true : equals(new Char(ic), new Char(_Char___init__impl__6a9atx(110)))) {
      tmp = parseBooleanOrNull(this, s, context);
    } else if (equals(new Char(ic), new Char(_Char___init__impl__6a9atx(34)))) {
      tmp = s.l2y();
    } else {
      invalidJson(this, "Not expected '" + new Char(ic) + "'");
    }
    return tmp;
  };
  var Json_instance;
  function Json_getInstance() {
    if (Json_instance == null)
      new Json();
    return Json_instance;
  }
  function parse$level(stream) {
    var tmp$ret$0;
    // Inline function 'kotlin.collections.arrayListOf' call
    tmp$ret$0 = ArrayList_init_$Create$_0();
    var children = tmp$ret$0;
    while (stream.j()) {
      var tag = stream.k();
      var tmp0_subject = tag;
      if (tmp0_subject instanceof ProcessingInstructionTag)
      ;
      else {
        if (tmp0_subject instanceof CommentTag) {
          children.b(Companion_getInstance_28().d2z(tag.c2z_1));
        } else {
          if (tmp0_subject instanceof Text) {
            children.b(tag.z2y_1 ? Companion_getInstance_28().b2z(tag.y2y_1) : Companion_getInstance_28().a2z(tag.y2y_1));
          } else {
            if (tmp0_subject instanceof OpenCloseTag) {
              var tmp = Companion_getInstance_28();
              var tmp$ret$1;
              // Inline function 'kotlin.collections.listOf' call
              tmp$ret$1 = emptyList();
              children.b(tmp.x2y(tag.r2y_1, tag.s2y_1, tmp$ret$1));
            } else {
              if (tmp0_subject instanceof OpenTag) {
                var out = parse$level(stream);
                var tmp1_safe_receiver = out.n2y_1;
                if (!((tmp1_safe_receiver == null ? null : tmp1_safe_receiver.q2y_1) === tag.o2y_1)) {
                  var tmp2_safe_receiver = out.n2y_1;
                  throw IllegalArgumentException_init_$Create$('Expected ' + tag.o2y_1 + ' but was ' + (tmp2_safe_receiver == null ? null : tmp2_safe_receiver.q2y_1));
                }
                children.b(new Xml(Type_NODE_getInstance(), tag.o2y_1, tag.p2y_1, out.m2y_1, ''));
              } else {
                if (tmp0_subject instanceof CloseTag)
                  return new Xml$Companion$parse$Level(children, tag);
                else {
                  throw IllegalArgumentException_init_$Create$('Unhandled ' + tag);
                }
              }
            }
          }
        }
      }
    }
    return new Xml$Companion$parse$Level(children, null);
  }
  function Xml$Companion$parse$Level(children, close) {
    this.m2y_1 = children;
    this.n2y_1 = close;
  }
  protoOf(Xml$Companion$parse$Level).toString = function () {
    return 'Level(children=' + this.m2y_1 + ', close=' + this.n2y_1 + ')';
  };
  protoOf(Xml$Companion$parse$Level).hashCode = function () {
    var result = hashCode(this.m2y_1);
    result = imul(result, 31) + (this.n2y_1 == null ? 0 : this.n2y_1.hashCode()) | 0;
    return result;
  };
  protoOf(Xml$Companion$parse$Level).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Xml$Companion$parse$Level))
      return false;
    var tmp0_other_with_cast = other instanceof Xml$Companion$parse$Level ? other : THROW_CCE();
    if (!equals(this.m2y_1, tmp0_other_with_cast.m2y_1))
      return false;
    if (!equals(this.n2y_1, tmp0_other_with_cast.n2y_1))
      return false;
    return true;
  };
  var Type_NODE_instance;
  var Type_TEXT_instance;
  var Type_COMMENT_instance;
  var Type_entriesInitialized;
  function Type_initEntries() {
    if (Type_entriesInitialized)
      return Unit_getInstance();
    Type_entriesInitialized = true;
    Type_NODE_instance = new Type('NODE', 0);
    Type_TEXT_instance = new Type('TEXT', 1);
    Type_COMMENT_instance = new Type('COMMENT', 2);
  }
  function ProcessingInstructionTag(name, attributes) {
    Element_0.call(this);
    this.e2z_1 = name;
    this.f2z_1 = attributes;
  }
  protoOf(ProcessingInstructionTag).toString = function () {
    return 'ProcessingInstructionTag(name=' + this.e2z_1 + ', attributes=' + this.f2z_1 + ')';
  };
  protoOf(ProcessingInstructionTag).hashCode = function () {
    var result = getStringHashCode(this.e2z_1);
    result = imul(result, 31) + hashCode(this.f2z_1) | 0;
    return result;
  };
  protoOf(ProcessingInstructionTag).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof ProcessingInstructionTag))
      return false;
    var tmp0_other_with_cast = other instanceof ProcessingInstructionTag ? other : THROW_CCE();
    if (!(this.e2z_1 === tmp0_other_with_cast.e2z_1))
      return false;
    if (!equals(this.f2z_1, tmp0_other_with_cast.f2z_1))
      return false;
    return true;
  };
  function OpenCloseTag(name, attributes) {
    Element_0.call(this);
    this.r2y_1 = name;
    this.s2y_1 = attributes;
  }
  protoOf(OpenCloseTag).toString = function () {
    return 'OpenCloseTag(name=' + this.r2y_1 + ', attributes=' + this.s2y_1 + ')';
  };
  protoOf(OpenCloseTag).hashCode = function () {
    var result = getStringHashCode(this.r2y_1);
    result = imul(result, 31) + hashCode(this.s2y_1) | 0;
    return result;
  };
  protoOf(OpenCloseTag).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof OpenCloseTag))
      return false;
    var tmp0_other_with_cast = other instanceof OpenCloseTag ? other : THROW_CCE();
    if (!(this.r2y_1 === tmp0_other_with_cast.r2y_1))
      return false;
    if (!equals(this.s2y_1, tmp0_other_with_cast.s2y_1))
      return false;
    return true;
  };
  function OpenTag(name, attributes) {
    Element_0.call(this);
    this.o2y_1 = name;
    this.p2y_1 = attributes;
  }
  protoOf(OpenTag).toString = function () {
    return 'OpenTag(name=' + this.o2y_1 + ', attributes=' + this.p2y_1 + ')';
  };
  protoOf(OpenTag).hashCode = function () {
    var result = getStringHashCode(this.o2y_1);
    result = imul(result, 31) + hashCode(this.p2y_1) | 0;
    return result;
  };
  protoOf(OpenTag).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof OpenTag))
      return false;
    var tmp0_other_with_cast = other instanceof OpenTag ? other : THROW_CCE();
    if (!(this.o2y_1 === tmp0_other_with_cast.o2y_1))
      return false;
    if (!equals(this.p2y_1, tmp0_other_with_cast.p2y_1))
      return false;
    return true;
  };
  function CommentTag(text) {
    Element_0.call(this);
    this.c2z_1 = text;
  }
  protoOf(CommentTag).toString = function () {
    return 'CommentTag(text=' + this.c2z_1 + ')';
  };
  protoOf(CommentTag).hashCode = function () {
    return getStringHashCode(this.c2z_1);
  };
  protoOf(CommentTag).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CommentTag))
      return false;
    var tmp0_other_with_cast = other instanceof CommentTag ? other : THROW_CCE();
    if (!(this.c2z_1 === tmp0_other_with_cast.c2z_1))
      return false;
    return true;
  };
  function CloseTag(name) {
    Element_0.call(this);
    this.q2y_1 = name;
  }
  protoOf(CloseTag).toString = function () {
    return 'CloseTag(name=' + this.q2y_1 + ')';
  };
  protoOf(CloseTag).hashCode = function () {
    return getStringHashCode(this.q2y_1);
  };
  protoOf(CloseTag).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof CloseTag))
      return false;
    var tmp0_other_with_cast = other instanceof CloseTag ? other : THROW_CCE();
    if (!(this.q2y_1 === tmp0_other_with_cast.q2y_1))
      return false;
    return true;
  };
  function Text(text, cdata) {
    cdata = cdata === VOID ? false : cdata;
    Element_0.call(this);
    this.y2y_1 = text;
    this.z2y_1 = cdata;
  }
  protoOf(Text).toString = function () {
    return 'Text(text=' + this.y2y_1 + ', cdata=' + this.z2y_1 + ')';
  };
  protoOf(Text).hashCode = function () {
    var result = getStringHashCode(this.y2y_1);
    result = imul(result, 31) + (this.z2y_1 | 0) | 0;
    return result;
  };
  protoOf(Text).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Text))
      return false;
    var tmp0_other_with_cast = other instanceof Text ? other : THROW_CCE();
    if (!(this.y2y_1 === tmp0_other_with_cast.y2y_1))
      return false;
    if (!(this.z2y_1 === tmp0_other_with_cast.z2y_1))
      return false;
    return true;
  };
  function matchStringOrId(_this__u8e3s4, $this) {
    var tmp0_elvis_lhs = _this__u8e3s4.g2z();
    return tmp0_elvis_lhs == null ? _this__u8e3s4.h2z() : tmp0_elvis_lhs;
  }
  function xmlSequence($this, r) {
    return sequence(Xml$Stream$xmlSequence$slambda_0(r, null));
  }
  function Xml2Iterable(reader2) {
    this.i2z_1 = reader2;
    this.j2z_1 = this.i2z_1.z19();
  }
  protoOf(Xml2Iterable).h = function () {
    return xmlSequence(Stream_getInstance(), this.j2z_1).h();
  };
  function Element_0() {
  }
  function Xml$Stream$xmlSequence$slambda($r, resultContinuation) {
    this.s2z_1 = $r;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(Xml$Stream$xmlSequence$slambda).w2z = function ($this$sequence, $completion) {
    var tmp = this.x2z($this$sequence, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(Xml$Stream$xmlSequence$slambda).g8 = function (p1, $completion) {
    return this.w2z(p1 instanceof SequenceScope ? p1 : THROW_CCE(), $completion);
  };
  protoOf(Xml$Stream$xmlSequence$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 7;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!!this.s2z_1.p2r()) {
              this.y7_1 = 6;
              continue $sm;
            }

            var tmp_0 = this;
            var tmp0_elvis_lhs = this.s2z_1.y2z(_Char___init__impl__6a9atx(60));
            tmp_0.u2z_1 = tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
            if (charSequenceLength(this.u2z_1) > 0) {
              this.y7_1 = 2;
              suspendResult = this.t2z_1.l5(new Text(Entities_getInstance().c30(this.u2z_1)), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            if (this.s2z_1.p2r()) {
              this.y7_1 = 6;
              continue $sm;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            break;
          case 4:
            this.s2z_1.a2y(_Char___init__impl__6a9atx(60));
            var tmp_1 = this;
            var tmp_2;
            if (this.s2z_1.f30('![CDATA[')) {
              var tmp$ret$2;
              l$ret$3: do {
                var start = this.s2z_1.g30();
                var tmp$ret$0;
                l$ret$1: do {
                  try {
                    $l$loop: while (!this.s2z_1.p2r()) {
                      if (this.s2z_1.h30(']]>', false))
                        break $l$loop;
                      this.s2z_1.w1h(1);
                    }
                    tmp$ret$0 = Unit_getInstance();
                    break l$ret$1;
                  } catch ($p) {
                    var t = $p;
                    tmp$ret$2 = this.s2z_1.i30(start);
                    break l$ret$3;
                  }
                }
                 while (false);
                tmp$ret$2 = this.s2z_1.i30(start);
                break l$ret$3;
              }
               while (false);
              var text = tmp$ret$2;
              this.s2z_1.j30(']]>');
              var tmp0_also = new Text(text);
              tmp0_also.z2y_1 = true;
              tmp_2 = tmp0_also;
            } else if (this.s2z_1.f30('!--')) {
              var tmp$ret$6;
              l$ret$7: do {
                var start_0 = this.s2z_1.g30();
                var tmp$ret$4;
                l$ret$5: do {
                  try {
                    $l$loop_0: while (!this.s2z_1.p2r()) {
                      if (this.s2z_1.h30('-->', false))
                        break $l$loop_0;
                      this.s2z_1.w1h(1);
                    }
                    tmp$ret$4 = Unit_getInstance();
                    break l$ret$5;
                  } catch ($p) {
                    var t_0 = $p;
                    tmp$ret$6 = this.s2z_1.i30(start_0);
                    break l$ret$7;
                  }
                }
                 while (false);
                tmp$ret$6 = this.s2z_1.i30(start_0);
                break l$ret$7;
              }
               while (false);
              var text_0 = tmp$ret$6;
              this.s2z_1.j30('-->');
              tmp_2 = new CommentTag(text_0);
            } else {
              this.s2z_1.b2y();
              var processingInstruction = this.s2z_1.d30(_Char___init__impl__6a9atx(63));
              var processingEntityOrDocType = this.s2z_1.d30(_Char___init__impl__6a9atx(33));
              var close = this.s2z_1.d30(_Char___init__impl__6a9atx(47)) ? true : processingEntityOrDocType;
              this.s2z_1.b2y();
              var tmp1_elvis_lhs = this.s2z_1.h2z();
              var tmp_3;
              if (tmp1_elvis_lhs == null) {
                var tmp1_error = "Couldn't match identifier after '<', offset=" + this.s2z_1.e30() + ", around='" + this.s2z_1.y1h(10) + "'";
                throw IllegalStateException_init_$Create$(toString(tmp1_error));
              } else {
                tmp_3 = tmp1_elvis_lhs;
              }
              var name = tmp_3;
              this.s2z_1.b2y();
              var attributes = LinkedHashMap_init_$Create$();
              while ((!equals(new Char(this.s2z_1.f2y()), new Char(_Char___init__impl__6a9atx(63))) ? !equals(new Char(this.s2z_1.f2y()), new Char(_Char___init__impl__6a9atx(47))) : false) ? !equals(new Char(this.s2z_1.f2y()), new Char(_Char___init__impl__6a9atx(62))) : false) {
                var tmp2_elvis_lhs = matchStringOrId(this.s2z_1, Stream_getInstance());
                var tmp_4;
                if (tmp2_elvis_lhs == null) {
                  throw IllegalArgumentException_init_$Create$('Malformed document or unsupported xml construct around ~' + this.s2z_1.y1h(10) + "~ for name '" + name + "'");
                } else {
                  tmp_4 = tmp2_elvis_lhs;
                }
                var key = tmp_4;
                this.s2z_1.b2y();
                if (this.s2z_1.f30('=')) {
                  this.s2z_1.b2y();
                  var argsQuote = matchStringOrId(this.s2z_1, Stream_getInstance());
                  var tmp_5;
                  if (!(argsQuote == null) ? !(startsWith(argsQuote, "'") ? true : startsWith(argsQuote, '"')) : false) {
                    tmp_5 = argsQuote;
                  } else if (!(argsQuote == null)) {
                    var tmp_6 = Entities_getInstance();
                    var tmp2_substring = argsQuote.length - 1 | 0;
                    tmp_5 = tmp_6.c30(argsQuote.substring(1, tmp2_substring));
                  } else {
                    tmp_5 = Entities_getInstance().c30(ensureNotNull(this.s2z_1.h2z()));
                  }
                  var tmp3_set = tmp_5;
                  attributes.e5(key, tmp3_set);
                } else {
                  attributes.e5(key, key);
                }
                this.s2z_1.b2y();
              }
              var openclose = this.s2z_1.d30(_Char___init__impl__6a9atx(47));
              var processingInstructionEnd = this.s2z_1.d30(_Char___init__impl__6a9atx(63));
              this.s2z_1.a2y(_Char___init__impl__6a9atx(62));
              tmp_2 = (processingInstruction ? true : processingEntityOrDocType) ? new ProcessingInstructionTag(name, attributes) : openclose ? new OpenCloseTag(name, attributes) : close ? new CloseTag(name) : new OpenTag(name, attributes);
            }

            tmp_1.v2z_1 = tmp_2;
            this.y7_1 = 5;
            suspendResult = this.t2z_1.l5(this.v2z_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            this.y7_1 = 1;
            continue $sm;
          case 6:
            return Unit_getInstance();
          case 7:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 7) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(Xml$Stream$xmlSequence$slambda).x2z = function ($this$sequence, completion) {
    var i = new Xml$Stream$xmlSequence$slambda(this.s2z_1, completion);
    i.t2z_1 = $this$sequence;
    return i;
  };
  function Xml$Stream$xmlSequence$slambda_0($r, resultContinuation) {
    var i = new Xml$Stream$xmlSequence$slambda($r, resultContinuation);
    var l = function ($this$sequence, $completion) {
      return i.w2z($this$sequence, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function Companion_22() {
    Companion_instance_22 = this;
    this.t2y_1 = '_raw_';
    this.u2y_1 = '_text_';
    this.v2y_1 = '_cdata_';
    this.w2y_1 = '_comment_';
  }
  protoOf(Companion_22).x2y = function (tagName, attributes, children) {
    var tmp = Type_NODE_getInstance();
    var tmp$ret$7;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$3;
    // Inline function 'kotlin.collections.filter' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = LinkedHashMap_init_$Create$();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = attributes.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.io.serialization.xml.Companion.Tag.<anonymous>' call
      tmp$ret$1 = !(element.f1() == null);
      if (tmp$ret$1) {
        tmp0_filterTo.e5(element.e1(), element.f1());
      }
    }
    tmp$ret$2 = tmp0_filterTo;
    tmp$ret$3 = tmp$ret$2;
    var tmp2_map = tmp$ret$3;
    var tmp$ret$6;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp1_mapTo = ArrayList_init_$Create$(tmp2_map.l());
    var tmp$ret$4;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$4 = tmp2_map.d1().h();
    var tmp0_iterator_0 = tmp$ret$4;
    while (tmp0_iterator_0.j()) {
      var item = tmp0_iterator_0.k();
      var tmp$ret$5;
      // Inline function 'korlibs.io.serialization.xml.Companion.Tag.<anonymous>' call
      tmp$ret$5 = to(item.e1(), toString_0(item.f1()));
      tmp1_mapTo.b(tmp$ret$5);
    }
    tmp$ret$6 = tmp1_mapTo;
    tmp$ret$7 = tmp$ret$6;
    return new Xml(tmp, tagName, toMap(tmp$ret$7), children, '');
  };
  protoOf(Companion_22).a2z = function (text) {
    var tmp = Type_TEXT_getInstance();
    var tmp_0 = LinkedHashMap_init_$Create$();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.listOf' call
    tmp$ret$0 = emptyList();
    return new Xml(tmp, '_text_', tmp_0, tmp$ret$0, text);
  };
  protoOf(Companion_22).b2z = function (text) {
    var tmp = Type_TEXT_getInstance();
    var tmp_0 = LinkedHashMap_init_$Create$();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.listOf' call
    tmp$ret$0 = emptyList();
    return new Xml(tmp, '_cdata_', tmp_0, tmp$ret$0, text);
  };
  protoOf(Companion_22).d2z = function (text) {
    var tmp = Type_COMMENT_getInstance();
    var tmp_0 = LinkedHashMap_init_$Create$();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.listOf' call
    tmp$ret$0 = emptyList();
    return new Xml(tmp, '_comment_', tmp_0, tmp$ret$0, text);
  };
  protoOf(Companion_22).k30 = function (str) {
    try {
      var stream = Stream_getInstance().k30(str).h();
      var children = parse$level(stream).m2y_1;
      var tmp$ret$1;
      $l$block: {
        // Inline function 'kotlin.collections.firstOrNull' call
        var tmp0_iterator = children.h();
        while (tmp0_iterator.j()) {
          var element = tmp0_iterator.k();
          var tmp$ret$0;
          // Inline function 'korlibs.io.serialization.xml.Companion.parse.<anonymous>' call
          tmp$ret$0 = element.l30_1.equals(Type_NODE_getInstance());
          if (tmp$ret$0) {
            tmp$ret$1 = element;
            break $l$block;
          }
        }
        tmp$ret$1 = null;
      }
      var tmp0_elvis_lhs = tmp$ret$1;
      var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? firstOrNull(children) : tmp0_elvis_lhs;
      return tmp1_elvis_lhs == null ? Companion_getInstance_28().a2z('') : tmp1_elvis_lhs;
    } catch ($p) {
      if ($p instanceof NoSuchElementException) {
        var t = $p;
        println('ERROR: XML: ' + str + ' thrown a NoSuchElementException');
        return Companion_getInstance_28().a2z('!!ERRORED!!');
      } else {
        throw $p;
      }
    }
  };
  var Companion_instance_22;
  function Companion_getInstance_28() {
    if (Companion_instance_22 == null)
      new Companion_22();
    return Companion_instance_22;
  }
  function Type(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function Entities() {
    Entities_instance = this;
    this.z2z_1 = linkedMapOf([to(new Char(_Char___init__impl__6a9atx(34)), '&quot;'), to(new Char(_Char___init__impl__6a9atx(39)), '&apos;'), to(new Char(_Char___init__impl__6a9atx(60)), '&lt;'), to(new Char(_Char___init__impl__6a9atx(62)), '&gt;'), to(new Char(_Char___init__impl__6a9atx(38)), '&amp;')]);
    var tmp = this;
    var tmp_0 = Companion_getInstance_30();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp0_toTypedArray = this.z2z_1.j3();
    tmp$ret$0 = copyToArray(tmp0_toTypedArray);
    tmp.a30_1 = tmp_0.s30(tmp$ret$0);
    this.b30_1 = flip(this.z2z_1);
  }
  protoOf(Entities).t30 = function (str) {
    var tmp$ret$2;
    // Inline function 'korlibs.io.lang.eachBuilder' call
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.lang.eachBuilder.<anonymous>' call
    var inductionVariable = 0;
    var last = str.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.io.serialization.xml.Entities.encode.<anonymous>' call
        var tmp1__anonymous__uwfjfc = charSequenceGet(str, n);
        var entry = Entities_getInstance().z2z_1.h3(new Char(tmp1__anonymous__uwfjfc));
        if (!(entry == null)) {
          tmp0_apply.bc(entry);
        } else {
          tmp0_apply.ib(tmp1__anonymous__uwfjfc);
        }
      }
       while (inductionVariable < last);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(Entities).c30 = function (str) {
    return this.u30(new StrReader(str));
  };
  protoOf(Entities).u30 = function (r) {
    var tmp$ret$5;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.serialization.xml.Entities.decode.<anonymous>' call
    $l$loop: while (!r.p2r()) {
      tmp0_apply.bc(r.y2z(_Char___init__impl__6a9atx(38)));
      if (r.p2r())
        break $l$loop;
      r.a2y(_Char___init__impl__6a9atx(38));
      var tmp0_elvis_lhs = r.v30(_Char___init__impl__6a9atx(59));
      var value = tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
      var full = '&' + value;
      if (startsWith_0(value, _Char___init__impl__6a9atx(35))) {
        var tmp$ret$1;
        // Inline function 'kotlin.text.substring' call
        var tmp0_substring = value.length - 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = value;
        tmp$ret$1 = tmp$ret$0.substring(1, tmp0_substring);
        tmp0_apply.ib(numberToChar(toInt_0(tmp$ret$1)));
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.collections.contains' call
        var tmp1_contains = Entities_getInstance().b30_1;
        var tmp$ret$2;
        // Inline function 'kotlin.collections.containsKey' call
        tmp$ret$2 = (isInterface(tmp1_contains, Map) ? tmp1_contains : THROW_CCE()).b3(full);
        tmp$ret$3 = tmp$ret$2;
        if (tmp$ret$3) {
          tmp0_apply.ac(Entities_getInstance().b30_1.h3(full));
        } else {
          tmp0_apply.bc(full);
        }
      }
    }
    tmp$ret$4 = tmp0_apply;
    tmp$ret$5 = tmp$ret$4.toString();
    return tmp$ret$5;
  };
  var Entities_instance;
  function Entities_getInstance() {
    if (Entities_instance == null)
      new Entities();
    return Entities_instance;
  }
  function Stream() {
    Stream_instance = this;
  }
  protoOf(Stream).k30 = function (str) {
    return this.w30(new StrReader(str));
  };
  protoOf(Stream).w30 = function (r) {
    return new Xml2Iterable(r);
  };
  var Stream_instance;
  function Stream_getInstance() {
    if (Stream_instance == null)
      new Stream();
    return Stream_instance;
  }
  function Type_NODE_getInstance() {
    Type_initEntries();
    return Type_NODE_instance;
  }
  function Type_TEXT_getInstance() {
    Type_initEntries();
    return Type_TEXT_instance;
  }
  function Type_COMMENT_getInstance() {
    Type_initEntries();
    return Type_COMMENT_instance;
  }
  function Xml(type, name, attributes, allChildren, content) {
    Companion_getInstance_28();
    this.l30_1 = type;
    this.m30_1 = name;
    this.n30_1 = attributes;
    this.o30_1 = allChildren;
    this.p30_1 = content;
    this.q30_1 = toCaseInsensitiveMap(this.n30_1);
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.text.trim' call
    var tmp$ret$1;
    // Inline function 'kotlin.text.toLowerCase' call
    var tmp0_toLowerCase = this.m30_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_toLowerCase;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    var tmp1_trim = tmp$ret$1;
    tmp$ret$2 = toString(trim_0(isCharSequence(tmp1_trim) ? tmp1_trim : THROW_CCE()));
    tmp.r30_1 = tmp$ret$2;
  }
  protoOf(Xml).x30 = function () {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = toList_0(this.n30_1);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.serialization.xml.Xml.<get-attributesStr>.<anonymous>' call
      tmp$ret$0 = ' ' + item.a5_1 + '="' + item.b5_1 + '"';
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    return joinToString(tmp$ret$2, '');
  };
  protoOf(Xml).y30 = function () {
    var tmp0_subject = this.l30_1;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        var tmp_0;
        if (this.o30_1.p()) {
          tmp_0 = '<' + this.m30_1 + this.x30() + '/>';
        } else {
          var tmp$ret$2;
          // Inline function 'kotlin.collections.map' call
          var tmp1_map = this.o30_1;
          var tmp$ret$1;
          // Inline function 'kotlin.collections.mapTo' call
          var tmp0_mapTo = ArrayList_init_$Create$(collectionSizeOrDefault(tmp1_map, 10));
          var tmp0_iterator = tmp1_map.h();
          while (tmp0_iterator.j()) {
            var item = tmp0_iterator.k();
            var tmp$ret$0;
            // Inline function 'korlibs.io.serialization.xml.Xml.<get-outerXml>.<anonymous>' call
            tmp$ret$0 = item.y30();
            tmp0_mapTo.b(tmp$ret$0);
          }
          tmp$ret$1 = tmp0_mapTo;
          tmp$ret$2 = tmp$ret$1;
          var children = joinToString(tmp$ret$2, '');
          tmp_0 = '<' + this.m30_1 + this.x30() + '>' + children + '<\/' + this.m30_1 + '>';
        }

        tmp = tmp_0;
        break;
      case 1:
        var tmp1_subject = this.m30_1;
        var tmp_1;
        Companion_getInstance_28();
        if (tmp1_subject === '_text_') {
          tmp_1 = Entities_getInstance().t30(this.p30_1);
        } else {
          Companion_getInstance_28();
          if (tmp1_subject === '_cdata_') {
            tmp_1 = '<![CDATA[' + this.p30_1 + ']]>';
          } else {
            Companion_getInstance_28();
            if (tmp1_subject === '_raw_') {
              tmp_1 = this.p30_1;
            } else {
              tmp_1 = this.p30_1;
            }
          }
        }

        tmp = tmp_1;
        break;
      case 2:
        tmp = '<!--' + this.p30_1 + '-->';
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(Xml).p1b = function (name) {
    return this.z30(name);
  };
  protoOf(Xml).z30 = function (name) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.filter' call
    var tmp1_filter = this.o30_1;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.filterTo' call
    var tmp0_filterTo = ArrayList_init_$Create$_0();
    var tmp0_iterator = tmp1_filter.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.serialization.xml.Xml.children.<anonymous>' call
      tmp$ret$0 = equals_0(element.m30_1, name, true);
      if (tmp$ret$0) {
        tmp0_filterTo.b(element);
      }
    }
    tmp$ret$1 = tmp0_filterTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  };
  protoOf(Xml).a31 = function (name, defaultValue) {
    var tmp0_safe_receiver = this.q30_1.h3(name);
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toIntOrNull(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? defaultValue : tmp1_elvis_lhs;
  };
  protoOf(Xml).b31 = function (name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? 0 : defaultValue;
    return $super === VOID ? this.a31(name, defaultValue) : $super.a31.call(this, name, defaultValue);
  };
  protoOf(Xml).c31 = function (name, defaultValue) {
    var tmp0_elvis_lhs = this.q30_1.h3(name);
    return tmp0_elvis_lhs == null ? defaultValue : tmp0_elvis_lhs;
  };
  protoOf(Xml).d31 = function (name, defaultValue, $super) {
    defaultValue = defaultValue === VOID ? '' : defaultValue;
    return $super === VOID ? this.c31(name, defaultValue) : $super.c31.call(this, name, defaultValue);
  };
  protoOf(Xml).e31 = function (name) {
    var tmp0_safe_receiver = this.q30_1.h3(name);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      var tmp$ret$2;
      // Inline function 'kotlin.text.toFloatOrNull' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = toDoubleOrNull(tmp0_safe_receiver);
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp$ret$1;
      tmp = tmp$ret$2;
    }
    return tmp;
  };
  protoOf(Xml).f31 = function (name) {
    return this.q30_1.h3(name);
  };
  protoOf(Xml).toString = function () {
    return this.y30();
  };
  protoOf(Xml).hashCode = function () {
    var result = this.l30_1.hashCode();
    result = imul(result, 31) + getStringHashCode(this.m30_1) | 0;
    result = imul(result, 31) + hashCode(this.n30_1) | 0;
    result = imul(result, 31) + hashCode(this.o30_1) | 0;
    result = imul(result, 31) + getStringHashCode(this.p30_1) | 0;
    return result;
  };
  protoOf(Xml).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Xml))
      return false;
    var tmp0_other_with_cast = other instanceof Xml ? other : THROW_CCE();
    if (!this.l30_1.equals(tmp0_other_with_cast.l30_1))
      return false;
    if (!(this.m30_1 === tmp0_other_with_cast.m30_1))
      return false;
    if (!equals(this.n30_1, tmp0_other_with_cast.n30_1))
      return false;
    if (!equals(this.o30_1, tmp0_other_with_cast.o30_1))
      return false;
    if (!(this.p30_1 === tmp0_other_with_cast.p30_1))
      return false;
    return true;
  };
  function Xml_0(str) {
    return Companion_getInstance_28().k30(str);
  }
  function get_1(_this__u8e3s4, name) {
    return children(_this__u8e3s4, name);
  }
  function children(_this__u8e3s4, name) {
    var tmp$ret$2;
    // Inline function 'kotlin.collections.flatMap' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.flatMapTo' call
    var tmp0_flatMapTo = ArrayList_init_$Create$_0();
    var tmp0_iterator = _this__u8e3s4.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      var tmp$ret$0;
      // Inline function 'korlibs.io.serialization.xml.children.<anonymous>' call
      tmp$ret$0 = element.z30(name);
      var list = tmp$ret$0;
      addAll(tmp0_flatMapTo, list);
    }
    tmp$ret$1 = tmp0_flatMapTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  var EMPTY_BYTE_ARRAY;
  function readInternal($this, buffer, offset, len, $completion) {
    var tmp = new $readInternalCOROUTINE$158($this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function writeInternal($this, buffer, offset, len, $completion) {
    var tmp = new $writeInternalCOROUTINE$159($this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function AsyncStream$read$slambda(this$0, $buffer, $offset, $len, resultContinuation) {
    this.m32_1 = this$0;
    this.n32_1 = $buffer;
    this.o32_1 = $offset;
    this.p32_1 = $len;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(AsyncStream$read$slambda).q32 = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncStream$read$slambda).un = function ($completion) {
    return this.q32($completion);
  };
  protoOf(AsyncStream$read$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = readInternal(this.m32_1, this.n32_1, this.o32_1, this.p32_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return suspendResult;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(AsyncStream$read$slambda).g1p = function (completion) {
    var i = new AsyncStream$read$slambda(this.m32_1, this.n32_1, this.o32_1, this.p32_1, completion);
    return i;
  };
  function AsyncStream$read$slambda_0(this$0, $buffer, $offset, $len, resultContinuation) {
    var i = new AsyncStream$read$slambda(this$0, $buffer, $offset, $len, resultContinuation);
    var l = function ($completion) {
      return i.q32($completion);
    };
    l.$arity = 0;
    return l;
  }
  function AsyncStream$write$slambda(this$0, $buffer, $offset, $len, resultContinuation) {
    this.z32_1 = this$0;
    this.a33_1 = $buffer;
    this.b33_1 = $offset;
    this.c33_1 = $len;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(AsyncStream$write$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncStream$write$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(AsyncStream$write$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = writeInternal(this.z32_1, this.a33_1, this.b33_1, this.c33_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(AsyncStream$write$slambda).g1p = function (completion) {
    var i = new AsyncStream$write$slambda(this.z32_1, this.a33_1, this.b33_1, this.c33_1, completion);
    return i;
  };
  function AsyncStream$write$slambda_0(this$0, $buffer, $offset, $len, resultContinuation) {
    var i = new AsyncStream$write$slambda(this$0, $buffer, $offset, $len, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function $readCOROUTINE$156(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.l33_1 = _this__u8e3s4;
    this.m33_1 = buffer;
    this.n33_1 = offset;
    this.o33_1 = len;
  }
  protoOf($readCOROUTINE$156).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (this.l33_1.v29_1) {
              this.y7_1 = 2;
              suspendResult = this.l33_1.x29_1.i1p(AsyncStream$read$slambda_0(this.l33_1, this.m33_1, this.n33_1, this.o33_1, null), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = readInternal(this.l33_1, this.m33_1, this.n33_1, this.o33_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.p33_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 2:
            this.p33_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.p33_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $writeCOROUTINE$157(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.y33_1 = _this__u8e3s4;
    this.z33_1 = buffer;
    this.a34_1 = offset;
    this.b34_1 = len;
  }
  protoOf($writeCOROUTINE$157).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (this.y33_1.v29_1) {
              this.y7_1 = 2;
              suspendResult = this.y33_1.y29_1.i1p(AsyncStream$write$slambda_0(this.y33_1, this.z33_1, this.a34_1, this.b34_1, null), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = writeInternal(this.y33_1, this.z33_1, this.a34_1, this.b34_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.y7_1 = 3;
            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return Unit_getInstance();
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readInternalCOROUTINE$158(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.o31_1 = _this__u8e3s4;
    this.p31_1 = buffer;
    this.q31_1 = offset;
    this.r31_1 = len;
  }
  protoOf($readInternalCOROUTINE$158).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.o31_1.t29_1.e2f(this.o31_1.u29_1, this.p31_1, this.q31_1, this.r31_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var read = suspendResult;
            if (read >= 0) {
              var tmp0_this = this.o31_1;
              var tmp_0 = tmp0_this;
              var tmp0_plus = tmp0_this.u29_1;
              tmp_0.u29_1 = tmp0_plus.b9(toLong(read));
            }

            return read;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $writeInternalCOROUTINE$159(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.a32_1 = _this__u8e3s4;
    this.b32_1 = buffer;
    this.c32_1 = offset;
    this.d32_1 = len;
  }
  protoOf($writeInternalCOROUTINE$159).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.a32_1.t29_1.f2f(this.a32_1.u29_1, this.b32_1, this.c32_1, this.d32_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp0_this = this.a32_1;
            var tmp_0 = tmp0_this;
            var tmp0_plus = tmp0_this.u29_1;
            tmp_0.u29_1 = tmp0_plus.b9(toLong(this.d32_1));
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getAvailableCOROUTINE$160(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.k34_1 = _this__u8e3s4;
  }
  protoOf($getAvailableCOROUTINE$160).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.k34_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.l34_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.k34_1.t20(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            return this.l34_1.l1(ARGUMENT);
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function AsyncStream(base, position, queue) {
    position = position === VOID ? new Long(0, 0) : position;
    queue = queue === VOID ? false : queue;
    this.t29_1 = base;
    this.u29_1 = position;
    this.v29_1 = queue;
    this.w29_1 = new Mixin();
    this.x29_1 = new AsyncThread();
    this.y29_1 = new AsyncThread();
  }
  protoOf(AsyncStream).d1i = function (_set____db54di) {
    this.w29_1.d1i(_set____db54di);
  };
  protoOf(AsyncStream).e1i = function () {
    return this.w29_1.e1i();
  };
  protoOf(AsyncStream).toString = function () {
    return 'AsyncStream(' + this.t29_1 + ', position=' + toString(this.u29_1) + ')';
  };
  protoOf(AsyncStream).t1y = function (buffer, offset, len, $completion) {
    var tmp = new $readCOROUTINE$156(this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncStream).s1v = function (buffer, offset, len, $completion) {
    var tmp = new $writeCOROUTINE$157(this, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncStream).t20 = function ($completion) {
    return this.u29_1;
  };
  protoOf(AsyncStream).h1y = function ($completion) {
    var tmp0 = this.t29_1.h1y($completion);
    return tmp0;
  };
  protoOf(AsyncStream).m34 = function ($completion) {
    var tmp0 = this.g1y($completion);
    return tmp0;
  };
  protoOf(AsyncStream).n34 = function ($completion) {
    var tmp = new $getAvailableCOROUTINE$160(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(AsyncStream).u1w = function ($completion) {
    var tmp0 = this.t29_1.u1w($completion);
    return tmp0;
  };
  function buffered(_this__u8e3s4, blockSize, blocksToRead) {
    blockSize = blockSize === VOID ? 2048 : blockSize;
    blocksToRead = blocksToRead === VOID ? 16 : blocksToRead;
    _init_properties_AsyncStream_kt__y7xune();
    return toAsyncStream(new BufferedStreamBase(_this__u8e3s4.t29_1, blockSize, blocksToRead), _this__u8e3s4.u29_1);
  }
  function toAsyncStream(_this__u8e3s4, position) {
    position = position === VOID ? new Long(0, 0) : position;
    _init_properties_AsyncStream_kt__y7xune();
    return new AsyncStream(_this__u8e3s4, position);
  }
  function AsyncStreamBase() {
  }
  protoOf(AsyncStreamBase).e2f = function (position, buffer, offset, len, $completion) {
    throw UnsupportedOperationException_init_$Create$_0();
  };
  protoOf(AsyncStreamBase).f2f = function (position, buffer, offset, len, $completion) {
    throw UnsupportedOperationException_init_$Create$_0();
  };
  protoOf(AsyncStreamBase).h1y = function ($completion) {
    throw UnsupportedOperationException_init_$Create$_0();
  };
  protoOf(AsyncStreamBase).u1w = function ($completion) {
    return Unit_getInstance();
  };
  function readAll(_this__u8e3s4, $completion) {
    var tmp = new $readAllCOROUTINE$163(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readBytesExact(_this__u8e3s4, len, $completion) {
    var tmp = new $readBytesExactCOROUTINE$166(_this__u8e3s4, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function $hasLengthCOROUTINE$167(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d36_1 = _this__u8e3s4;
  }
  protoOf($hasLengthCOROUTINE$167).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.d36_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            this.e36_1 = ARGUMENT.k1(new Long(0, 0)) >= 0;
            this.z7_1 = 3;
            this.y7_1 = 4;
            continue $sm;
          case 2:
            this.z7_1 = 3;
            var tmp_0 = this.b8_1;
            if (tmp_0 instanceof UnsupportedOperationException) {
              var t = this.b8_1;
              var tmp_1 = this;
              tmp_1.e36_1 = false;
              this.y7_1 = 4;
              continue $sm;
            } else {
              throw this.b8_1;
            }

            break;
          case 3:
            throw this.b8_1;
          case 4:
            this.z7_1 = 3;
            return this.e36_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function AsyncGetLengthStream() {
  }
  function copyTo(_this__u8e3s4, target, chunkSize, $completion) {
    chunkSize = chunkSize === VOID ? 8388608 : chunkSize;
    var tmp = new $copyToCOROUTINE$168(_this__u8e3s4, target, chunkSize, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readU16BE_0(_this__u8e3s4, $completion) {
    var tmp = new $readU16BECOROUTINE$169(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readS32BE_0(_this__u8e3s4, $completion) {
    var tmp = new $readS32BECOROUTINE$170(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function AsyncPositionLengthStream() {
  }
  function $_readCOROUTINE$171(_this__u8e3s4, position, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.i38_1 = _this__u8e3s4;
    this.j38_1 = position;
    this.k38_1 = buffer;
    this.l38_1 = offset;
    this.m38_1 = len;
  }
  protoOf($_readCOROUTINE$171).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 7;
            this.y7_1 = 1;
            suspendResult = this.i38_1.q38_1.g1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              this.y7_1 = 2;
              suspendResult = this.i38_1.q38_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.n38_1 = false;
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.o38_1 = suspendResult;
            this.n38_1 = this.j38_1.k1(this.o38_1) >= 0;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            if (this.n38_1) {
              return -1;
            } else {
              this.y7_1 = 4;
              continue $sm;
            }

            ;
            break;
          case 4:
            var tmp_0 = this;
            var tmp0_div = this.i38_1.t38_1;
            tmp_0.p38_1 = this.j38_1.jb(toLong(tmp0_div));
            if (!this.i38_1.v38_1.equals(this.p38_1)) {
              this.y7_1 = 5;
              var tmp1_times = this.i38_1.t38_1;
              suspendResult = readBytes(this.i38_1.q38_1, this.p38_1.ta(toLong(tmp1_times)), this.i38_1.t38_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 6;
              continue $sm;
            }

            break;
          case 5:
            var ARGUMENT = suspendResult;
            this.i38_1.u38_1 = ARGUMENT;
            this.i38_1.v38_1 = this.p38_1;
            this.y7_1 = 6;
            continue $sm;
          case 6:
            var tmp2_rem = this.i38_1.t38_1;
            var soffset = this.j38_1.c9(toLong(tmp2_rem)).t1();
            var available = this.i38_1.u38_1.length - soffset | 0;
            var toRead = Math.min(available, this.m38_1);
            arraycopy(this.i38_1.u38_1, soffset, this.k38_1, this.l38_1, toRead);
            return toRead;
          case 7:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 7) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function BufferedStreamBase(base, blockSize, blocksToRead) {
    blockSize = blockSize === VOID ? 2048 : blockSize;
    blocksToRead = blocksToRead === VOID ? 16 : blocksToRead;
    AsyncStreamBase.call(this);
    this.q38_1 = base;
    this.r38_1 = blockSize;
    this.s38_1 = blocksToRead;
    this.t38_1 = imul(this.r38_1, this.s38_1);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.byteArrayOf' call
    tmp$ret$0 = new Int8Array([]);
    tmp.u38_1 = tmp$ret$0;
    this.v38_1 = new Long(-1, -1);
  }
  protoOf(BufferedStreamBase).e2f = function (position, buffer, offset, len, $completion) {
    var tmp0 = this.w38(position, buffer, offset, len, $completion);
    return tmp0;
  };
  protoOf(BufferedStreamBase).w38 = function (position, buffer, offset, len, $completion) {
    var tmp = new $_readCOROUTINE$171(this, position, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(BufferedStreamBase).f2f = function (position, buffer, offset, len, $completion) {
    var tmp0 = this.q38_1.f2f(position, buffer, offset, len, $completion);
    return tmp0;
  };
  protoOf(BufferedStreamBase).h1y = function ($completion) {
    var tmp0 = this.q38_1.h1y($completion);
    return tmp0;
  };
  protoOf(BufferedStreamBase).u1w = function ($completion) {
    var tmp0 = this.q38_1.u1w($completion);
    return tmp0;
  };
  function AsyncGetPositionStream() {
  }
  function readExact(_this__u8e3s4, buffer, offset, len, $completion) {
    var tmp = new $readExactCOROUTINE$172(_this__u8e3s4, buffer, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function sliceWithBounds(_this__u8e3s4, start, end, closeParent, $completion) {
    closeParent = closeParent === VOID ? false : closeParent;
    var tmp = new $sliceWithBoundsCOROUTINE$173(_this__u8e3s4, start, end, closeParent, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function MemoryAsyncStreamBase(data) {
    AsyncStreamBase.call(this);
    this.z39_1 = data;
  }
  protoOf(MemoryAsyncStreamBase).a3a = function () {
    return this.z39_1.l();
  };
  protoOf(MemoryAsyncStreamBase).h1y = function ($completion) {
    return toLong(this.a3a());
  };
  protoOf(MemoryAsyncStreamBase).b3a = function (position) {
    if (position.k1(new Long(0, 0)) < 0) {
      invalidOp('Invalid position ' + toString(position));
    }
  };
  protoOf(MemoryAsyncStreamBase).e2f = function (position, buffer, offset, len, $completion) {
    this.b3a(position);
    var tmp$ret$0;
    // Inline function 'kotlin.ranges.contains' call
    var tmp0_contains = until(0, this.a3a());
    tmp$ret$0 = contains_0(isInterface(tmp0_contains, ClosedRange) ? tmp0_contains : THROW_CCE(), position);
    if (!tmp$ret$0)
      return 0;
    var tmp$ret$2;
    // Inline function 'kotlin.math.min' call
    var tmp1_min = toLong(this.a3a());
    var tmp$ret$1;
    // Inline function 'kotlin.Long.plus' call
    tmp$ret$1 = position.b9(toLong(len));
    var tmp2_min = tmp$ret$1;
    tmp$ret$2 = tmp1_min.k1(tmp2_min) <= 0 ? tmp1_min : tmp2_min;
    var end = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.math.max' call
    var tmp3_max = end.l1(position).t1();
    tmp$ret$3 = Math.max(tmp3_max, 0);
    var actualLen = tmp$ret$3;
    arraycopy(this.z39_1.w1i_1, position.t1(), buffer, offset, actualLen);
    return actualLen;
  };
  protoOf(MemoryAsyncStreamBase).f2f = function (position, buffer, offset, len, $completion) {
    this.b3a(position);
    var tmp = this.z39_1;
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.z39_1.l();
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    tmp$ret$0 = position.b9(toLong(len));
    var tmp1_max = tmp$ret$0.t1();
    tmp$ret$1 = Math.max(tmp0_max, tmp1_max);
    tmp.a1a(tmp$ret$1);
    arraycopy(buffer, offset, this.z39_1.w1i_1, position.t1(), len);
    return Unit_getInstance();
  };
  protoOf(MemoryAsyncStreamBase).u1w = function ($completion) {
    return Unit_getInstance();
  };
  protoOf(MemoryAsyncStreamBase).toString = function () {
    return 'MemoryAsyncStreamBase(' + this.z39_1.l() + ')';
  };
  function readBytes(_this__u8e3s4, position, count, $completion) {
    var tmp = new $readBytesCOROUTINE$176(_this__u8e3s4, position, count, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function clampPosition($this, position) {
    return clamp_0(position, $this.p3a_1, $this.q3a_1);
  }
  function clampPositionLen($this, position, len) {
    if (position.k1(new Long(0, 0)) < 0)
      throw IllegalArgumentException_init_$Create$('Invalid position');
    var targetStartPosition = clampPosition($this, $this.p3a_1.b9(position));
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    tmp$ret$0 = targetStartPosition.b9(toLong(len));
    var targetEndPosition = clampPosition($this, tmp$ret$0);
    var targetLen = targetEndPosition.l1(targetStartPosition).t1();
    return new Pair(targetStartPosition, targetLen);
  }
  function $closeCOROUTINE$177(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b3b_1 = _this__u8e3s4;
  }
  protoOf($closeCOROUTINE$177).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            if (this.b3b_1.r3a_1) {
              this.y7_1 = 1;
              suspendResult = this.b3b_1.o3a_1.u1w(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function SliceAsyncStreamBase(base, baseStart, baseEnd, closeParent) {
    AsyncStreamBase.call(this);
    this.o3a_1 = base;
    this.p3a_1 = baseStart;
    this.q3a_1 = baseEnd;
    this.r3a_1 = closeParent;
    this.s3a_1 = this.q3a_1.l1(this.p3a_1);
  }
  protoOf(SliceAsyncStreamBase).e2f = function (position, buffer, offset, len, $completion) {
    var tmp0_container = clampPositionLen(this, position, len);
    var targetStartPosition = tmp0_container.c5();
    var targetLen = tmp0_container.d5();
    var tmp0 = this.o3a_1.e2f(targetStartPosition, buffer, offset, targetLen, $completion);
    return tmp0;
  };
  protoOf(SliceAsyncStreamBase).f2f = function (position, buffer, offset, len, $completion) {
    var tmp0_container = clampPositionLen(this, position, len);
    var targetStartPosition = tmp0_container.c5();
    var targetLen = tmp0_container.d5();
    var tmp0 = this.o3a_1.f2f(targetStartPosition, buffer, offset, targetLen, $completion);
    return tmp0;
  };
  protoOf(SliceAsyncStreamBase).h1y = function ($completion) {
    return this.s3a_1;
  };
  protoOf(SliceAsyncStreamBase).u1w = function ($completion) {
    var tmp = new $closeCOROUTINE$177(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(SliceAsyncStreamBase).toString = function () {
    return 'SliceAsyncStreamBase(' + this.o3a_1 + ', ' + toString(this.p3a_1) + ', ' + toString(this.q3a_1) + ')';
  };
  function readBytesUpTo(_this__u8e3s4, len, $completion) {
    var tmp = new $readBytesUpToCOROUTINE$178(_this__u8e3s4, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readBytesUpToCopy(_this__u8e3s4, out, $completion) {
    var tmp = new $readBytesUpToCopyCOROUTINE$183(_this__u8e3s4, out, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function getAvailable(_this__u8e3s4, $completion) {
    var tmp = new $getAvailableCOROUTINE$184(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readBytesUpTo_0(_this__u8e3s4, out, offset, len, $completion) {
    var tmp = new $readBytesUpToCOROUTINE$185(_this__u8e3s4, out, offset, len, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function sliceStart(_this__u8e3s4, start, closeParent, $completion) {
    start = start === VOID ? new Long(0, 0) : start;
    closeParent = closeParent === VOID ? false : closeParent;
    var tmp = new $sliceStartCOROUTINE$187(_this__u8e3s4, start, closeParent, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readString(_this__u8e3s4, len, charset, $completion) {
    charset = charset === VOID ? get_UTF8() : charset;
    var tmp = new $readStringCOROUTINE$189(_this__u8e3s4, len, charset, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function sliceHere(_this__u8e3s4, closeParent, $completion) {
    closeParent = closeParent === VOID ? false : closeParent;
    var tmp = new $sliceHereCOROUTINE$190(_this__u8e3s4, closeParent, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readU16(_this__u8e3s4, endian, $completion) {
    var tmp = new $readU16COROUTINE$191(_this__u8e3s4, endian, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readS32(_this__u8e3s4, endian, $completion) {
    var tmp = new $readS32COROUTINE$192(_this__u8e3s4, endian, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function readStringz(_this__u8e3s4, len, charset, $completion) {
    charset = charset === VOID ? get_UTF8() : charset;
    var tmp = new $readStringzCOROUTINE$193(_this__u8e3s4, len, charset, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function skipToAlign(_this__u8e3s4, alignment, offset) {
    _init_properties_AsyncStream_kt__y7xune();
    var tmp = _this__u8e3s4;
    var tmp$ret$1;
    // Inline function 'kotlin.Long.minus' call
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    var tmp0_plus = _this__u8e3s4.u29_1;
    tmp$ret$0 = tmp0_plus.b9(toLong(offset));
    var tmp1_minus = nextAlignedTo(tmp$ret$0, toLong(alignment));
    tmp$ret$1 = tmp1_minus.l1(toLong(offset));
    tmp.u29_1 = tmp$ret$1;
  }
  function readS32LE_0(_this__u8e3s4, $completion) {
    var tmp = new $readS32LECOROUTINE$195(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function sliceWithSize(_this__u8e3s4, start, length, closeParent, $completion) {
    closeParent = closeParent === VOID ? false : closeParent;
    var tmp0 = sliceWithBounds(_this__u8e3s4, start, start.b9(length), closeParent, $completion);
    return tmp0;
  }
  function readU16LE_0(_this__u8e3s4, $completion) {
    var tmp = new $readU16LECOROUTINE$198(_this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  }
  function toAsyncInputStream(_this__u8e3s4) {
    _init_properties_AsyncStream_kt__y7xune();
    return new toAsyncInputStream$1(_this__u8e3s4);
  }
  function toAsyncOutputStream(_this__u8e3s4) {
    _init_properties_AsyncStream_kt__y7xune();
    return new toAsyncOutputStream$1(_this__u8e3s4);
  }
  function $getPositionCOROUTINE$203(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.c3h_1 = _this__u8e3s4;
  }
  protoOf($getPositionCOROUTINE$203).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.c3h_1.f3h_1;
            var tmp0_safe_receiver = isInterface(tmp_1, SyncPositionStream) ? tmp_1 : null;
            tmp_0.d3h_1 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.g3h();
            if (this.d3h_1 == null) {
              this.y7_1 = 1;
              suspendResult = getPosition.call(this.c3h_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.e3h_1 = this.d3h_1;
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.e3h_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return this.e3h_1;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getLengthCOROUTINE$204(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.p3h_1 = _this__u8e3s4;
  }
  protoOf($getLengthCOROUTINE$204).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            var tmp_0 = this;
            var tmp_1 = this.p3h_1.f3h_1;
            var tmp0_safe_receiver = isInterface(tmp_1, SyncLengthStream) ? tmp_1 : null;
            tmp_0.q3h_1 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.hf();
            if (this.q3h_1 == null) {
              this.y7_1 = 1;
              suspendResult = getLength.call(this.p3h_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.r3h_1 = this.q3h_1;
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.r3h_1 = suspendResult;
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return this.r3h_1;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function toAsyncInputStream$1($this_toAsyncInputStream) {
    this.f3h_1 = $this_toAsyncInputStream;
  }
  protoOf(toAsyncInputStream$1).t1y = function (buffer, offset, len, $completion) {
    return this.f3h_1.v1h(buffer, offset, len);
  };
  protoOf(toAsyncInputStream$1).u1w = function ($completion) {
    var tmp = this.f3h_1;
    var tmp0_safe_receiver = isInterface(tmp, Closeable) ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.n1q();
    }
    return Unit_getInstance();
  };
  protoOf(toAsyncInputStream$1).t20 = function ($completion) {
    var tmp = new $getPositionCOROUTINE$203(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(toAsyncInputStream$1).h1y = function ($completion) {
    var tmp = new $getLengthCOROUTINE$204(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function toAsyncOutputStream$1($this_toAsyncOutputStream) {
    this.s3h_1 = $this_toAsyncOutputStream;
  }
  protoOf(toAsyncOutputStream$1).s1v = function (buffer, offset, len, $completion) {
    this.s3h_1.t3h(buffer, offset, len);
    return Unit_getInstance();
  };
  protoOf(toAsyncOutputStream$1).u1w = function ($completion) {
    var tmp = this.s3h_1;
    var tmp0_safe_receiver = isInterface(tmp, Closeable) ? tmp : null;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.n1q();
    }
    return Unit_getInstance();
  };
  function $readAllCOROUTINE$163(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.w34_1 = _this__u8e3s4;
  }
  protoOf($readAllCOROUTINE$163).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 18;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            this.z7_1 = 16;
            var tmp_0;
            var tmp_1 = this.w34_1;
            if (isInterface(tmp_1, AsyncGetPositionStream)) {
              var tmp_2 = this.w34_1;
              tmp_0 = isInterface(tmp_2, AsyncGetLengthStream);
            } else {
              tmp_0 = false;
            }

            if (tmp_0) {
              this.y7_1 = 10;
              suspendResult = this.w34_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              var tmp_3 = this.w34_1;
              if (tmp_3 instanceof AsyncStream) {
                this.y7_1 = 2;
                suspendResult = this.w34_1.m34(this);
                if (suspendResult === get_COROUTINE_SUSPENDED()) {
                  return suspendResult;
                }
                continue $sm;
              } else {
                this.z34_1 = false;
                this.y7_1 = 3;
                continue $sm;
              }
            }

            break;
          case 2:
            this.z34_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            if (this.z34_1) {
              this.y7_1 = 8;
              suspendResult = this.w34_1.n34(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.a35_1 = ByteArrayBuilder_init_$Create$();
              this.b35_1 = new Int8Array(4096);
              this.y7_1 = 4;
              continue $sm;
            }

            ;
            break;
          case 4:
            if (false) {
              this.y7_1 = 7;
              continue $sm;
            }

            this.y7_1 = 5;
            suspendResult = this.w34_1.t1y(this.b35_1, 0, this.b35_1.length, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            this.c35_1 = suspendResult;
            if (this.c35_1 <= 0) {
              this.y7_1 = 7;
              continue $sm;
            } else {
              this.y7_1 = 6;
              continue $sm;
            }

            break;
          case 6:
            this.a35_1.z1i(this.b35_1, 0, this.c35_1);
            this.y7_1 = 4;
            continue $sm;
          case 7:
            this.y34_1 = this.a35_1.c1j();
            this.y7_1 = 13;
            continue $sm;
          case 8:
            this.d35_1 = suspendResult;
            this.e35_1 = this.d35_1.t1();
            this.y7_1 = 9;
            suspendResult = readBytesExact(this.w34_1, this.e35_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 9:
            this.y34_1 = suspendResult;
            this.y7_1 = 13;
            continue $sm;
          case 10:
            this.f35_1 = suspendResult;
            this.y7_1 = 11;
            suspendResult = this.w34_1.t20(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 11:
            this.g35_1 = suspendResult;
            this.h35_1 = this.f35_1.l1(this.g35_1);
            this.y7_1 = 12;
            suspendResult = readBytesExact(this.w34_1, this.h35_1.t1(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 12:
            this.y34_1 = suspendResult;
            this.y7_1 = 13;
            continue $sm;
          case 13:
            this.x34_1 = this.y34_1;
            this.z7_1 = 18;
            this.y7_1 = 14;
            continue $sm;
          case 14:
            this.i35_1 = this.x34_1;
            this.y7_1 = 15;
            suspendResult = this.w34_1.u1w(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 15:
            return this.i35_1;
          case 16:
            this.z7_1 = 18;
            this.j35_1 = this.b8_1;
            this.y7_1 = 17;
            suspendResult = this.w34_1.u1w(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 17:
            throw this.j35_1;
          case 18:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 18) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesExactCOROUTINE$166(_this__u8e3s4, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.s35_1 = _this__u8e3s4;
    this.t35_1 = len;
  }
  protoOf($readBytesExactCOROUTINE$166).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            var tmp_0 = this;
            tmp_0.u35_1 = new Int8Array(this.t35_1);
            this.y7_1 = 1;
            suspendResult = readExact(this.s35_1, this.u35_1, 0, this.t35_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return this.u35_1;
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $copyToCOROUTINE$168(_this__u8e3s4, target, chunkSize, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.n36_1 = _this__u8e3s4;
    this.o36_1 = target;
    this.p36_1 = chunkSize;
  }
  protoOf($copyToCOROUTINE$168).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 17;
            var tmp_0;
            var tmp_1 = this.n36_1;
            if (tmp_1 instanceof AsyncStream) {
              var tmp_2 = this.n36_1.t29_1;
              tmp_0 = tmp_2 instanceof MemoryAsyncStreamBase;
            } else {
              tmp_0 = false;
            }

            if (tmp_0) {
              this.y7_1 = 16;
              suspendResult = this.o36_1.s1v(this.n36_1.t29_1.z39_1.w1i_1, this.n36_1.u29_1.t1(), this.n36_1.t29_1.a3a() - this.n36_1.u29_1.t1() | 0, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            var tmp_3 = this.n36_1;
            if (isInterface(tmp_3, AsyncGetLengthStream)) {
              this.y7_1 = 2;
              suspendResult = this.n36_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.q36_1 = this.p36_1;
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.r36_1 = suspendResult;
            var tmp_4 = this;
            tmp_4.s36_1 = toLong(this.p36_1);
            this.t36_1 = this.r36_1.k1(this.s36_1) <= 0 ? this.r36_1 : this.s36_1;
            this.q36_1 = this.t36_1.t1();
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.u36_1 = this.q36_1;
            this.v36_1 = new Long(0, 0);
            var tmp_5 = this;
            tmp_5.w36_1 = new Int8Array(this.u36_1);
            this.y7_1 = 4;
            continue $sm;
          case 4:
            this.z7_1 = 10;
            this.y7_1 = 5;
            continue $sm;
          case 5:
            if (false) {
              this.y7_1 = 9;
              continue $sm;
            }

            this.y7_1 = 6;
            suspendResult = this.n36_1.t1y(this.w36_1, 0, this.w36_1.length, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 6:
            this.y36_1 = suspendResult;
            if (this.y36_1 <= 0) {
              this.y7_1 = 9;
              continue $sm;
            } else {
              this.y7_1 = 7;
              continue $sm;
            }

            break;
          case 7:
            this.y7_1 = 8;
            suspendResult = this.o36_1.s1v(this.w36_1, 0, this.y36_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 8:
            var tmp_6 = this;
            var tmp0_plus = this.v36_1;
            tmp_6.v36_1 = tmp0_plus.b9(toLong(this.y36_1));
            this.y7_1 = 5;
            continue $sm;
          case 9:
            this.x36_1 = Unit_getInstance();
            this.z7_1 = 17;
            this.y7_1 = 13;
            continue $sm;
          case 10:
            this.z7_1 = 17;
            this.z36_1 = this.b8_1;
            if (false) {
              this.y7_1 = 11;
              suspendResult = this.n36_1.u1w(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 12;
              continue $sm;
            }

            break;
          case 11:
            this.y7_1 = 12;
            continue $sm;
          case 12:
            throw this.z36_1;
          case 13:
            if (false) {
              this.y7_1 = 14;
              suspendResult = this.n36_1.u1w(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 15;
              continue $sm;
            }

            break;
          case 14:
            this.y7_1 = 15;
            continue $sm;
          case 15:
            return this.v36_1;
          case 16:
            return toLong(this.n36_1.t29_1.a3a());
          case 17:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 17) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readU16BECOROUTINE$169(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.i37_1 = _this__u8e3s4;
  }
  protoOf($readU16BECOROUTINE$169).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 9;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.k37_1 = get_smallBytesPool();
            this.l37_1 = this.k37_1.a1g();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.z7_1 = 8;
            this.y7_1 = 4;
            suspendResult = this.i37_1.t1y(this.l37_1, 0, 2, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            var read = suspendResult;
            if (!(read === 2)) {
              var tmp0_error = "Couldn't read exact size=2 but read=" + read;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }

            var tmp_1 = this;
            tmp_1.m37_1 = readU16BE(this.l37_1, 0);
            this.z7_1 = 9;
            this.y7_1 = 5;
            var tmp_2 = this;
            continue $sm;
          case 5:
            var tmp_3 = this.m37_1;
            this.k37_1.b1g(this.l37_1);
            ;
            this.j37_1 = tmp_3;
            this.y7_1 = 7;
            continue $sm;
          case 6:
            this.k37_1.b1g(this.l37_1);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 7;
            continue $sm;
          case 7:
            return this.j37_1;
          case 8:
            this.z7_1 = 9;
            var t = this.b8_1;
            this.k37_1.b1g(this.l37_1);
            ;
            throw t;
          case 9:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 9) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readS32BECOROUTINE$170(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.v37_1 = _this__u8e3s4;
  }
  protoOf($readS32BECOROUTINE$170).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 9;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.x37_1 = get_smallBytesPool();
            this.y37_1 = this.x37_1.a1g();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.z7_1 = 8;
            this.y7_1 = 4;
            suspendResult = this.v37_1.t1y(this.y37_1, 0, 4, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            var read = suspendResult;
            if (!(read === 4)) {
              var tmp0_error = "Couldn't read exact size=4 but read=" + read;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }

            var tmp_1 = this;
            tmp_1.z37_1 = readS32BE(this.y37_1, 0);
            this.z7_1 = 9;
            this.y7_1 = 5;
            var tmp_2 = this;
            continue $sm;
          case 5:
            var tmp_3 = this.z37_1;
            this.x37_1.b1g(this.y37_1);
            ;
            this.w37_1 = tmp_3;
            this.y7_1 = 7;
            continue $sm;
          case 6:
            this.x37_1.b1g(this.y37_1);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 7;
            continue $sm;
          case 7:
            return this.w37_1;
          case 8:
            this.z7_1 = 9;
            var t = this.b8_1;
            this.x37_1.b1g(this.y37_1);
            ;
            throw t;
          case 9:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 9) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readExactCOROUTINE$172(_this__u8e3s4, buffer, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.f39_1 = _this__u8e3s4;
    this.g39_1 = buffer;
    this.h39_1 = offset;
    this.i39_1 = len;
  }
  protoOf($readExactCOROUTINE$172).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.j39_1 = this.i39_1;
            this.k39_1 = this.h39_1;
            this.l39_1 = this.f39_1;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (!(this.j39_1 > 0)) {
              this.y7_1 = 4;
              continue $sm;
            }

            this.y7_1 = 2;
            suspendResult = this.l39_1.t1y(this.g39_1, this.k39_1, this.j39_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var read = suspendResult;
            if (read < 0) {
              this.y7_1 = 4;
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 3:
            if (read === 0)
              throw new EOFException('Not enough data. Expected=' + this.i39_1 + ', Read=' + (this.i39_1 - this.j39_1 | 0) + ', Remaining=' + this.j39_1);
            this.k39_1 = this.k39_1 + read | 0;
            this.j39_1 = this.j39_1 - read | 0;
            this.y7_1 = 1;
            continue $sm;
          case 4:
            return Unit_getInstance();
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $sliceWithBoundsCOROUTINE$173(_this__u8e3s4, start, end, closeParent, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.u39_1 = _this__u8e3s4;
    this.v39_1 = start;
    this.w39_1 = end;
    this.x39_1 = closeParent;
  }
  protoOf($sliceWithBoundsCOROUTINE$173).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.y7_1 = 1;
            suspendResult = this.u39_1.g1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            if (suspendResult) {
              this.y7_1 = 2;
              suspendResult = this.u39_1.h1y(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y39_1 = this.w39_1;
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 2:
            this.y39_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            var rlen = this.y39_1;
            var len = rlen.k1(new Long(0, 0)) >= 0 ? rlen : this.w39_1;
            var clampedStart = clamp_0(this.v39_1, new Long(0, 0), len);
            var clampedEnd = clamp_0(this.w39_1, new Long(0, 0), len);
            var tmp0_subject = this.u39_1.t29_1;
            var tmp_0;
            if (tmp0_subject instanceof SliceAsyncStreamBase) {
              tmp_0 = new SliceAsyncStreamBase(this.u39_1.t29_1.o3a_1, this.u39_1.t29_1.p3a_1.b9(clampedStart), this.u39_1.t29_1.p3a_1.b9(clampedEnd), this.x39_1);
            } else {
              tmp_0 = new SliceAsyncStreamBase(this.u39_1.t29_1, clampedStart, clampedEnd, this.x39_1);
            }

            return toAsyncStream(tmp_0);
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesCOROUTINE$176(_this__u8e3s4, position, count, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.k3a_1 = _this__u8e3s4;
    this.l3a_1 = position;
    this.m3a_1 = count;
  }
  protoOf($readBytesCOROUTINE$176).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.n3a_1 = new Int8Array(this.m3a_1);
            this.y7_1 = 1;
            suspendResult = this.k3a_1.e2f(this.l3a_1, this.n3a_1, 0, this.n3a_1.length, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var readLen = suspendResult;
            return copyOf_0(this.n3a_1, readLen);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesUpToCOROUTINE$178(_this__u8e3s4, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.k3b_1 = _this__u8e3s4;
    this.l3b_1 = len;
  }
  protoOf($readBytesUpToCOROUTINE$178).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 10;
            this.m3b_1 = 4096;
            if (this.l3b_1 <= this.m3b_1) {
              this.y7_1 = 9;
              suspendResult = readBytesUpToCopy(this.k3b_1, new Int8Array(this.l3b_1), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            var tmp_0 = this.k3b_1;
            if (isInterface(tmp_0, AsyncPositionLengthStream)) {
              this.y7_1 = 7;
              suspendResult = getAvailable(this.k3b_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 2:
            this.n3b_1 = this.l3b_1;
            this.o3b_1 = new Int8Array(this.m3b_1);
            this.p3b_1 = ByteArrayBuilder_init_$Create$();
            this.y7_1 = 3;
            continue $sm;
          case 3:
            if (!(this.n3b_1 > 0)) {
              this.y7_1 = 6;
              continue $sm;
            }

            this.y7_1 = 4;
            var tmp1_min = this.o3b_1.length;
            var tmp2_min = this.n3b_1;
            suspendResult = this.k3b_1.t1y(this.o3b_1, 0, Math.min(tmp1_min, tmp2_min), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            this.q3b_1 = suspendResult;
            if (this.q3b_1 <= 0) {
              this.y7_1 = 6;
              continue $sm;
            } else {
              this.y7_1 = 5;
              continue $sm;
            }

            break;
          case 5:
            this.p3b_1.z1i(this.o3b_1, 0, this.q3b_1);
            this.n3b_1 = this.n3b_1 - this.q3b_1 | 0;
            this.y7_1 = 3;
            continue $sm;
          case 6:
            return this.p3b_1.c1j();
          case 7:
            this.r3b_1 = suspendResult;
            this.s3b_1 = toIntClamp(this.r3b_1);
            this.t3b_1 = Math.min(this.l3b_1, this.s3b_1);
            this.u3b_1 = new Int8Array(this.t3b_1);
            this.y7_1 = 8;
            suspendResult = readBytesUpToCopy(this.k3b_1, this.u3b_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 8:
            return suspendResult;
          case 9:
            return suspendResult;
          case 10:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 10) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesUpToCopyCOROUTINE$183(_this__u8e3s4, out, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.d3c_1 = _this__u8e3s4;
    this.e3c_1 = out;
  }
  protoOf($readBytesUpToCopyCOROUTINE$183).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = readBytesUpTo_0(this.d3c_1, this.e3c_1, 0, this.e3c_1.length, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var pos = suspendResult;
            return this.e3c_1.length === pos ? this.e3c_1 : copyOf_0(this.e3c_1, pos);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $getAvailableCOROUTINE$184(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.n3c_1 = _this__u8e3s4;
  }
  protoOf($getAvailableCOROUTINE$184).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.n3c_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.o3c_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = this.n3c_1.t20(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var ARGUMENT = suspendResult;
            return this.o3c_1.l1(ARGUMENT);
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readBytesUpToCOROUTINE$185(_this__u8e3s4, out, offset, len, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.x3c_1 = _this__u8e3s4;
    this.y3c_1 = out;
    this.z3c_1 = offset;
    this.a3d_1 = len;
  }
  protoOf($readBytesUpToCOROUTINE$185).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            this.b3d_1 = 0;
            this.c3d_1 = this.a3d_1;
            this.d3d_1 = this.z3c_1;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (false) {
              this.y7_1 = 5;
              continue $sm;
            }

            this.y7_1 = 2;
            suspendResult = this.x3c_1.t1y(this.y3c_1, this.d3d_1, this.c3d_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var result = suspendResult;
            if (result <= 0) {
              this.y7_1 = 5;
              continue $sm;
            } else {
              this.y7_1 = 3;
              continue $sm;
            }

            break;
          case 3:
            this.d3d_1 = this.d3d_1 + result | 0;
            this.c3d_1 = this.c3d_1 - result | 0;
            this.b3d_1 = this.b3d_1 + result | 0;
            this.y7_1 = 1;
            continue $sm;
          case 4:
            throw this.b8_1;
          case 5:
            return this.b3d_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $sliceStartCOROUTINE$187(_this__u8e3s4, start, closeParent, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.m3d_1 = _this__u8e3s4;
    this.n3d_1 = start;
    this.o3d_1 = closeParent;
  }
  protoOf($sliceStartCOROUTINE$187).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            suspendResult = this.m3d_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.p3d_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = sliceWithBounds(this.m3d_1, this.n3d_1, this.p3d_1, this.o3d_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readStringCOROUTINE$189(_this__u8e3s4, len, charset, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.y3d_1 = _this__u8e3s4;
    this.z3d_1 = len;
    this.a3e_1 = charset;
  }
  protoOf($readStringCOROUTINE$189).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = readBytesExact(this.y3d_1, this.z3d_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return toString_2(ARGUMENT, this.a3e_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $sliceHereCOROUTINE$190(_this__u8e3s4, closeParent, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.j3e_1 = _this__u8e3s4;
    this.k3e_1 = closeParent;
  }
  protoOf($sliceHereCOROUTINE$190).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.l3e_1 = this.j3e_1.u29_1;
            this.y7_1 = 1;
            suspendResult = this.j3e_1.h1y(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.m3e_1 = suspendResult;
            this.y7_1 = 2;
            suspendResult = sliceWithSize(this.j3e_1, this.l3e_1, this.m3e_1, this.k3e_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            return suspendResult;
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readU16COROUTINE$191(_this__u8e3s4, endian, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.v3e_1 = _this__u8e3s4;
    this.w3e_1 = endian;
  }
  protoOf($readU16COROUTINE$191).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (this.w3e_1.equals(Endian_LITTLE_ENDIAN_getInstance())) {
              this.y7_1 = 2;
              suspendResult = readU16LE_0(this.v3e_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = readU16BE_0(this.v3e_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.x3e_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 2:
            this.x3e_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.x3e_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readS32COROUTINE$192(_this__u8e3s4, endian, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.g3f_1 = _this__u8e3s4;
    this.h3f_1 = endian;
  }
  protoOf($readS32COROUTINE$192).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 4;
            if (this.h3f_1.equals(Endian_LITTLE_ENDIAN_getInstance())) {
              this.y7_1 = 2;
              suspendResult = readS32LE_0(this.g3f_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              suspendResult = readS32BE_0(this.g3f_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 1:
            this.i3f_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 2:
            this.i3f_1 = suspendResult;
            this.y7_1 = 3;
            continue $sm;
          case 3:
            return this.i3f_1;
          case 4:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 4) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readStringzCOROUTINE$193(_this__u8e3s4, len, charset, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.r3f_1 = _this__u8e3s4;
    this.s3f_1 = len;
    this.t3f_1 = charset;
  }
  protoOf($readStringzCOROUTINE$193).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = readBytesExact(this.r3f_1, this.s3f_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var res = suspendResult;
            var index = indexOf_2(res, 0);
            return toString_2(copyOf_0(res, index < 0 ? this.s3f_1 : index), this.t3f_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readS32LECOROUTINE$195(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.c3g_1 = _this__u8e3s4;
  }
  protoOf($readS32LECOROUTINE$195).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 9;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.e3g_1 = get_smallBytesPool();
            this.f3g_1 = this.e3g_1.a1g();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.z7_1 = 8;
            this.y7_1 = 4;
            suspendResult = this.c3g_1.t1y(this.f3g_1, 0, 4, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            var read = suspendResult;
            if (!(read === 4)) {
              var tmp0_error = "Couldn't read exact size=4 but read=" + read;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }

            var tmp_1 = this;
            tmp_1.g3g_1 = readS32LE(this.f3g_1, 0);
            this.z7_1 = 9;
            this.y7_1 = 5;
            var tmp_2 = this;
            continue $sm;
          case 5:
            var tmp_3 = this.g3g_1;
            this.e3g_1.b1g(this.f3g_1);
            ;
            this.d3g_1 = tmp_3;
            this.y7_1 = 7;
            continue $sm;
          case 6:
            this.e3g_1.b1g(this.f3g_1);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 7;
            continue $sm;
          case 7:
            return this.d3g_1;
          case 8:
            this.z7_1 = 9;
            var t = this.b8_1;
            this.e3g_1.b1g(this.f3g_1);
            ;
            throw t;
          case 9:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 9) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $readU16LECOROUTINE$198(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.p3g_1 = _this__u8e3s4;
  }
  protoOf($readU16LECOROUTINE$198).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 9;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.r3g_1 = get_smallBytesPool();
            this.s3g_1 = this.r3g_1.a1g();
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.z7_1 = 8;
            this.y7_1 = 4;
            suspendResult = this.p3g_1.t1y(this.s3g_1, 0, 2, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            var read = suspendResult;
            if (!(read === 2)) {
              var tmp0_error = "Couldn't read exact size=2 but read=" + read;
              throw IllegalStateException_init_$Create$(toString(tmp0_error));
            }

            var tmp_1 = this;
            tmp_1.t3g_1 = readU16LE(this.s3g_1, 0);
            this.z7_1 = 9;
            this.y7_1 = 5;
            var tmp_2 = this;
            continue $sm;
          case 5:
            var tmp_3 = this.t3g_1;
            this.r3g_1.b1g(this.s3g_1);
            ;
            this.q3g_1 = tmp_3;
            this.y7_1 = 7;
            continue $sm;
          case 6:
            this.r3g_1.b1g(this.s3g_1);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 7;
            continue $sm;
          case 7:
            return this.q3g_1;
          case 8:
            this.z7_1 = 9;
            var t = this.b8_1;
            this.r3g_1.b1g(this.s3g_1);
            ;
            throw t;
          case 9:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 9) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  var properties_initialized_AsyncStream_kt_gaoyh8;
  function _init_properties_AsyncStream_kt__y7xune() {
    if (properties_initialized_AsyncStream_kt_gaoyh8) {
    } else {
      properties_initialized_AsyncStream_kt_gaoyh8 = true;
      EMPTY_BYTE_ARRAY = new Int8Array(0);
    }
  }
  function offset($this, count) {
    var out = $this.x3h_1;
    var tmp0_this = $this;
    tmp0_this.x3h_1 = tmp0_this.x3h_1 + count | 0;
    return out;
  }
  function FastByteArrayInputStream(ba, offset, start, end) {
    offset = offset === VOID ? 0 : offset;
    start = start === VOID ? 0 : start;
    end = end === VOID ? ba.length : end;
    this.u3h_1 = ba;
    this.v3h_1 = start;
    this.w3h_1 = end;
    this.x3h_1 = offset + this.v3h_1 | 0;
  }
  protoOf(FastByteArrayInputStream).y3h = function (value) {
    this.x3h_1 = this.v3h_1 + value | 0;
  };
  protoOf(FastByteArrayInputStream).g3h = function () {
    return this.x3h_1 - this.v3h_1 | 0;
  };
  protoOf(FastByteArrayInputStream).hf = function () {
    return this.w3h_1 - this.v3h_1 | 0;
  };
  protoOf(FastByteArrayInputStream).k2r = function () {
    return this.w3h_1 - this.x3h_1 | 0;
  };
  protoOf(FastByteArrayInputStream).q2r = function () {
    return this.k2r() > 0;
  };
  protoOf(FastByteArrayInputStream).z3h = function (_this__u8e3s4) {
    return coerceIn(_this__u8e3s4, this.v3h_1, this.w3h_1);
  };
  protoOf(FastByteArrayInputStream).a3i = function (offset, length, charset) {
    var start = this.v3h_1 + offset | 0;
    var end = start + length | 0;
    return toString_2(this.u3h_1, charset, start, end);
  };
  protoOf(FastByteArrayInputStream).b3i = function (size) {
    var out = this.c3i(this.g3h(), size);
    var tmp0_this = this;
    tmp0_this.x3h_1 = tmp0_this.x3h_1 + size | 0;
    return out;
  };
  protoOf(FastByteArrayInputStream).d3i = function (offset) {
    return new FastByteArrayInputStream(this.u3h_1, 0, this.z3h(this.v3h_1 + offset | 0), this.w3h_1);
  };
  protoOf(FastByteArrayInputStream).e3i = function (offset, $super) {
    offset = offset === VOID ? 0 : offset;
    return $super === VOID ? this.d3i(offset) : $super.d3i.call(this, offset);
  };
  protoOf(FastByteArrayInputStream).z19 = function () {
    return new FastByteArrayInputStream(this.u3h_1, this.g3h(), this.v3h_1, this.w3h_1);
  };
  protoOf(FastByteArrayInputStream).c3i = function (offset, len) {
    return new FastByteArrayInputStream(this.u3h_1, 0, this.z3h(this.v3h_1 + offset | 0), this.z3h((this.v3h_1 + offset | 0) + len | 0));
  };
  protoOf(FastByteArrayInputStream).v2r = function (count) {
    this.x3h_1 = coerceIn(this.x3h_1 + count | 0, this.v3h_1, this.w3h_1);
  };
  protoOf(FastByteArrayInputStream).f3i = function (count) {
    return this.v2r(-count | 0);
  };
  protoOf(FastByteArrayInputStream).g3i = function (len) {
    var out = copyOfRange_0(this.u3h_1, this.x3h_1, this.x3h_1 + len | 0);
    var tmp0_this = this;
    tmp0_this.x3h_1 = tmp0_this.x3h_1 + len | 0;
    return out;
  };
  protoOf(FastByteArrayInputStream).h3i = function () {
    return readS8(this.u3h_1, offset(this, 1));
  };
  protoOf(FastByteArrayInputStream).i3i = function () {
    return readU8(this.u3h_1, offset(this, 1));
  };
  protoOf(FastByteArrayInputStream).j3i = function () {
    return readS16BE(this.u3h_1, offset(this, 2));
  };
  protoOf(FastByteArrayInputStream).k3i = function () {
    return readU16BE(this.u3h_1, offset(this, 2));
  };
  protoOf(FastByteArrayInputStream).l3i = function () {
    return readU24BE(this.u3h_1, offset(this, 3));
  };
  protoOf(FastByteArrayInputStream).m3i = function () {
    return readS32BE(this.u3h_1, offset(this, 4));
  };
  protoOf(FastByteArrayInputStream).n3i = function () {
    return readS64BE(this.u3h_1, offset(this, 8));
  };
  protoOf(FastByteArrayInputStream).o3i = function (count) {
    return readByteArray(this.u3h_1, offset(this, count), count);
  };
  protoOf(FastByteArrayInputStream).p3i = function (count) {
    return readShortArrayBE(this.u3h_1, offset(this, imul(count, 2)), count);
  };
  protoOf(FastByteArrayInputStream).q3i = function (count) {
    return readCharArrayBE(this.u3h_1, offset(this, imul(count, 2)), count);
  };
  protoOf(FastByteArrayInputStream).r3i = function (count) {
    return readIntArrayLE(this.u3h_1, offset(this, imul(count, 4)), count);
  };
  protoOf(FastByteArrayInputStream).s3i = function (count) {
    return readIntArrayBE(this.u3h_1, offset(this, imul(count, 4)), count);
  };
  protoOf(FastByteArrayInputStream).t3i = function (len, charset) {
    var res = this.o3i(len);
    var index = indexOf_2(res, 0);
    return toString_2(copyOf_0(res, index < 0 ? len : index), charset);
  };
  protoOf(FastByteArrayInputStream).u3i = function (len, charset, $super) {
    charset = charset === VOID ? get_UTF8() : charset;
    return $super === VOID ? this.t3i(len, charset) : $super.t3i.call(this, len, charset);
  };
  function openFastStream(_this__u8e3s4, offset) {
    offset = offset === VOID ? 0 : offset;
    return new FastByteArrayInputStream(_this__u8e3s4, offset);
  }
  function toSyncStream(_this__u8e3s4) {
    return (new ReadonlySyncStreamBase(_this__u8e3s4.u3h_1, _this__u8e3s4.v3h_1, _this__u8e3s4.w3h_1 - _this__u8e3s4.v3h_1 | 0)).w3i();
  }
  function openAsync(_this__u8e3s4, mode) {
    mode = mode === VOID ? 'r' : mode;
    return toAsyncStream(new MemoryAsyncStreamBase(new ByteArrayBuilder(_this__u8e3s4, VOID, true)), new Long(0, 0));
  }
  function SyncStream(base, position) {
    position = position === VOID ? new Long(0, 0) : position;
    this.x3i_1 = base;
    this.y3i_1 = new Mixin();
    this.z3i_1 = this.x3i_1.v3i_1;
    this.a3j_1 = this.x3i_1.f3j();
    this.b3j_1 = position;
    this.c3j_1 = position;
    this.d3j_1 = position;
    this.e3j_1 = new Long(0, 0);
  }
  protoOf(SyncStream).d1i = function (_set____db54di) {
    this.y3i_1.d1i(_set____db54di);
  };
  protoOf(SyncStream).e1i = function () {
    return this.y3i_1.e1i();
  };
  protoOf(SyncStream).g3j = function (value) {
    if (this.a3j_1)
      this.b3j_1 = value;
    else {
      this.h3j(value);
    }
  };
  protoOf(SyncStream).i3j = function () {
    return this.a3j_1 ? this.b3j_1 : this.g3h();
  };
  protoOf(SyncStream).j3j = function (value) {
    if (this.a3j_1)
      this.c3j_1 = value;
    else {
      this.h3j(value);
    }
  };
  protoOf(SyncStream).k3j = function () {
    return this.a3j_1 ? this.c3j_1 : this.g3h();
  };
  protoOf(SyncStream).h3j = function (value) {
    var tmp;
    if (this.a3j_1) {
      this.g3j(value);
      tmp = Unit_getInstance();
    } else {
      this.d3j_1 = value;
      tmp = Unit_getInstance();
    }
    return tmp;
  };
  protoOf(SyncStream).g3h = function () {
    return this.a3j_1 ? this.i3j() : this.d3j_1;
  };
  protoOf(SyncStream).v1h = function (buffer, offset, len) {
    var read = this.x3i_1.l3j(this.i3j(), buffer, offset, len);
    var tmp0_this = this;
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    var tmp0_plus = tmp0_this.i3j();
    tmp$ret$0 = tmp0_plus.b9(toLong(read));
    tmp0_this.g3j(tmp$ret$0);
    return read;
  };
  protoOf(SyncStream).v2r = function (count) {
    var tmp0_this = this;
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    var tmp0_plus = tmp0_this.i3j();
    tmp$ret$0 = tmp0_plus.b9(toLong(count));
    tmp0_this.g3j(tmp$ret$0);
  };
  protoOf(SyncStream).m3j = function () {
    var size = this.v1h(this.z3i_1, 0, 1);
    if (size <= 0)
      return -1;
    var tmp$ret$0;
    // Inline function 'korlibs.memory.unsigned' call
    var tmp0__get_unsigned__3e7on0 = this.z3i_1[0];
    tmp$ret$0 = tmp0__get_unsigned__3e7on0 & 255;
    return tmp$ret$0;
  };
  protoOf(SyncStream).t3h = function (buffer, offset, len) {
    this.x3i_1.n3j(this.k3j(), buffer, offset, len);
    var tmp0_this = this;
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    var tmp0_plus = tmp0_this.k3j();
    tmp$ret$0 = tmp0_plus.b9(toLong(len));
    tmp0_this.j3j(tmp$ret$0);
  };
  protoOf(SyncStream).hf = function () {
    return this.x3i_1.hf();
  };
  protoOf(SyncStream).o3j = function () {
    return this.hf().l1(this.i3j());
  };
  protoOf(SyncStream).k2r = function () {
    return this.o3j();
  };
  protoOf(SyncStream).n1q = function () {
    return this.x3i_1.n1q();
  };
  protoOf(SyncStream).z19 = function () {
    return new SyncStream(this.x3i_1, this.g3h());
  };
  protoOf(SyncStream).toString = function () {
    return 'SyncStream(' + this.x3i_1 + ', ' + toString(this.g3h()) + ')';
  };
  function openSync(_this__u8e3s4, mode) {
    mode = mode === VOID ? 'r' : mode;
    return toSyncStream_0(new MemorySyncStreamBase(new ByteArrayBuilder(_this__u8e3s4)), new Long(0, 0));
  }
  function readString_0(_this__u8e3s4, len, charset) {
    charset = charset === VOID ? get_UTF8() : charset;
    return toString_2(readBytes_0(_this__u8e3s4, len), charset);
  }
  function SyncInputStream() {
  }
  function SyncPositionStream() {
  }
  function SyncLengthStream() {
  }
  function SyncStreamBase() {
    this.v3i_1 = new Int8Array(16);
  }
  protoOf(SyncStreamBase).f3j = function () {
    return false;
  };
  protoOf(SyncStreamBase).l3j = function (position, buffer, offset, len) {
    unsupported_0();
  };
  protoOf(SyncStreamBase).n3j = function (position, buffer, offset, len) {
    unsupported_0();
  };
  protoOf(SyncStreamBase).hf = function () {
    unsupported_0();
  };
  protoOf(SyncStreamBase).n1q = function () {
    return Unit_getInstance();
  };
  protoOf(SyncStreamBase).p3j = function (position) {
    return new SyncStream(this, position);
  };
  protoOf(SyncStreamBase).w3i = function (position, $super) {
    position = position === VOID ? new Long(0, 0) : position;
    return $super === VOID ? this.p3j(position) : $super.p3j.call(this, position);
  };
  function toSyncStream_0(_this__u8e3s4, position) {
    position = position === VOID ? new Long(0, 0) : position;
    return new SyncStream(_this__u8e3s4, position);
  }
  function MemorySyncStreamBase(data) {
    SyncStreamBase.call(this);
    this.r3j_1 = data;
  }
  protoOf(MemorySyncStreamBase).a3a = function () {
    return this.r3j_1.l();
  };
  protoOf(MemorySyncStreamBase).hf = function () {
    return toLong(this.r3j_1.l());
  };
  protoOf(MemorySyncStreamBase).b3a = function (position) {
    if (position.k1(new Long(0, 0)) < 0) {
      invalidOp('Invalid position ' + toString(position));
    }
  };
  protoOf(MemorySyncStreamBase).l3j = function (position, buffer, offset, len) {
    this.b3a(position);
    var ipos = position.t1();
    var tmp$ret$0;
    // Inline function 'kotlin.ranges.contains' call
    var tmp0_contains = until(0, this.a3a());
    tmp$ret$0 = contains_0(isInterface(tmp0_contains, ClosedRange) ? tmp0_contains : THROW_CCE(), position);
    if (!tmp$ret$0)
      return 0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp1_min = this.a3a();
    var tmp2_min = ipos + len | 0;
    tmp$ret$1 = Math.min(tmp1_min, tmp2_min);
    var end = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp3_max = end - ipos | 0;
    tmp$ret$2 = Math.max(tmp3_max, 0);
    var actualLen = tmp$ret$2;
    arraycopy(this.r3j_1.w1i_1, ipos, buffer, offset, actualLen);
    return actualLen;
  };
  protoOf(MemorySyncStreamBase).n3j = function (position, buffer, offset, len) {
    this.b3a(position);
    var tmp = this.r3j_1;
    var tmp$ret$1;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.r3j_1.l();
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    tmp$ret$0 = position.b9(toLong(len));
    var tmp1_max = tmp$ret$0.t1();
    tmp$ret$1 = Math.max(tmp0_max, tmp1_max);
    tmp.a1a(tmp$ret$1);
    arraycopy(buffer, offset, this.r3j_1.w1i_1, position.t1(), len);
  };
  protoOf(MemorySyncStreamBase).n1q = function () {
    return Unit_getInstance();
  };
  protoOf(MemorySyncStreamBase).toString = function () {
    return 'MemorySyncStreamBase(' + this.r3j_1.l() + ')';
  };
  function readBytes_0(_this__u8e3s4, len) {
    var bytes = new Int8Array(len);
    var out = _this__u8e3s4.v1h(bytes, 0, len);
    return !(out === len) ? copyOf_0(bytes, out) : bytes;
  }
  function MemorySyncStream(data) {
    return toSyncStream_0(new MemorySyncStreamBase(data));
  }
  function readU8_0(_this__u8e3s4) {
    return _this__u8e3s4.m3j();
  }
  function readS32BE_1(_this__u8e3s4) {
    return readU8_0(_this__u8e3s4) << 24 | readU8_0(_this__u8e3s4) << 16 | readU8_0(_this__u8e3s4) << 8 | readU8_0(_this__u8e3s4);
  }
  function get_eof(_this__u8e3s4) {
    return _this__u8e3s4.k2r().k1(new Long(0, 0)) <= 0;
  }
  function readStringz_0(_this__u8e3s4, len, charset) {
    charset = charset === VOID ? get_UTF8() : charset;
    var res = readBytes_0(_this__u8e3s4, len);
    var index = indexOf_0(res, 0);
    return toString_2(copyOf_0(res, index < 0 ? len : index), charset);
  }
  function readStream(_this__u8e3s4, length) {
    return readSlice(_this__u8e3s4, length);
  }
  function readAll_0(_this__u8e3s4) {
    return readBytes_0(_this__u8e3s4, _this__u8e3s4.k2r().t1());
  }
  function sliceStart_0(_this__u8e3s4, start) {
    start = start === VOID ? new Long(0, 0) : start;
    return sliceWithBounds_0(_this__u8e3s4, start, _this__u8e3s4.hf());
  }
  function ReadonlySyncStreamBase(data, offset, size) {
    offset = offset === VOID ? 0 : offset;
    size = size === VOID ? data.length - offset | 0 : size;
    SyncStreamBase.call(this);
    this.t3j_1 = data;
    this.u3j_1 = offset;
    this.v3j_1 = size;
  }
  protoOf(ReadonlySyncStreamBase).a3a = function () {
    return this.v3j_1;
  };
  protoOf(ReadonlySyncStreamBase).hf = function () {
    return toLong(this.t3j_1.length);
  };
  protoOf(ReadonlySyncStreamBase).b3a = function (position) {
    if (position.k1(new Long(0, 0)) < 0) {
      invalidOp('Invalid position ' + toString(position));
    }
  };
  protoOf(ReadonlySyncStreamBase).l3j = function (position, buffer, offset, len) {
    this.b3a(position);
    var ipos = position.t1();
    var tmp$ret$0;
    // Inline function 'kotlin.ranges.contains' call
    var tmp0_contains = until(0, this.a3a());
    tmp$ret$0 = contains_0(isInterface(tmp0_contains, ClosedRange) ? tmp0_contains : THROW_CCE(), position);
    if (!tmp$ret$0)
      return 0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp1_min = this.a3a();
    var tmp2_min = ipos + len | 0;
    tmp$ret$1 = Math.min(tmp1_min, tmp2_min);
    var end = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp3_max = end - ipos | 0;
    tmp$ret$2 = Math.max(tmp3_max, 0);
    var actualLen = tmp$ret$2;
    arraycopy(this.t3j_1, ipos + this.u3j_1 | 0, buffer, offset, actualLen);
    return actualLen;
  };
  protoOf(ReadonlySyncStreamBase).n1q = function () {
    return Unit_getInstance();
  };
  protoOf(ReadonlySyncStreamBase).toString = function () {
    return 'ReadonlySyncStreamBase(' + this.t3j_1.length + ')';
  };
  function sliceWithBounds_0(_this__u8e3s4, start, end) {
    var len = _this__u8e3s4.hf();
    var clampedStart = clamp_0(start, new Long(0, 0), len);
    var clampedEnd = clamp_0(end, new Long(0, 0), len);
    var tmp = _this__u8e3s4.x3i_1;
    if (tmp instanceof SliceSyncStreamBase) {
      return toSyncStream_0(new SliceSyncStreamBase(_this__u8e3s4.x3i_1.x3j_1, _this__u8e3s4.x3i_1.y3j_1.b9(clampedStart), _this__u8e3s4.x3i_1.y3j_1.b9(clampedEnd)));
    } else {
      return toSyncStream_0(new SliceSyncStreamBase(_this__u8e3s4.x3i_1, clampedStart, clampedEnd));
    }
  }
  function readSlice(_this__u8e3s4, length) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = sliceWithSize_0(_this__u8e3s4, _this__u8e3s4.g3h(), length);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.stream.readSlice.<anonymous>' call
    var tmp0_this = _this__u8e3s4;
    tmp0_this.h3j(tmp0_this.g3h().b9(length));
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function clampPosition_0($this, position) {
    return clamp_0(position, $this.y3j_1, $this.z3j_1);
  }
  function clampPositionLen_0($this, position, len) {
    if (position.k1(new Long(0, 0)) < 0)
      throw IllegalArgumentException_init_$Create$('Invalid position');
    var targetStartPosition = clampPosition_0($this, $this.y3j_1.b9(position));
    var tmp$ret$0;
    // Inline function 'kotlin.Long.plus' call
    tmp$ret$0 = targetStartPosition.b9(toLong(len));
    var targetEndPosition = clampPosition_0($this, tmp$ret$0);
    var targetLen = targetEndPosition.l1(targetStartPosition).t1();
    return new Pair(targetStartPosition, targetLen);
  }
  function SliceSyncStreamBase(base, baseStart, baseEnd) {
    SyncStreamBase.call(this);
    this.x3j_1 = base;
    this.y3j_1 = baseStart;
    this.z3j_1 = baseEnd;
    this.a3k_1 = this.z3j_1.l1(this.y3j_1);
  }
  protoOf(SliceSyncStreamBase).f3j = function () {
    return this.x3j_1.f3j();
  };
  protoOf(SliceSyncStreamBase).hf = function () {
    return this.a3k_1;
  };
  protoOf(SliceSyncStreamBase).l3j = function (position, buffer, offset, len) {
    var tmp0_container = clampPositionLen_0(this, position, len);
    var targetStartPosition = tmp0_container.c5();
    var targetLen = tmp0_container.d5();
    return this.x3j_1.l3j(targetStartPosition, buffer, offset, targetLen);
  };
  protoOf(SliceSyncStreamBase).n3j = function (position, buffer, offset, len) {
    var tmp0_container = clampPositionLen_0(this, position, len);
    var targetStartPosition = tmp0_container.c5();
    var targetLen = tmp0_container.d5();
    return this.x3j_1.n3j(targetStartPosition, buffer, offset, targetLen);
  };
  protoOf(SliceSyncStreamBase).n1q = function () {
    return Unit_getInstance();
  };
  protoOf(SliceSyncStreamBase).toString = function () {
    return 'SliceAsyncStreamBase(' + this.x3j_1 + ', ' + toString(this.y3j_1) + ', ' + toString(this.z3j_1) + ')';
  };
  function sliceWithSize_0(_this__u8e3s4, position, length) {
    return sliceWithBounds_0(_this__u8e3s4, position, position.b9(length));
  }
  function readBytesExact_0(_this__u8e3s4, len) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new Int8Array(len);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.stream.readBytesExact.<anonymous>' call
    readExact_0(_this__u8e3s4, tmp0_apply, 0, len);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function readU16LE_1(_this__u8e3s4) {
    return readU8_0(_this__u8e3s4) | readU8_0(_this__u8e3s4) << 8;
  }
  function readS32LE_1(_this__u8e3s4) {
    return readU8_0(_this__u8e3s4) | readU8_0(_this__u8e3s4) << 8 | readU8_0(_this__u8e3s4) << 16 | readU8_0(_this__u8e3s4) << 24;
  }
  function readU32LE(_this__u8e3s4) {
    return toLong(readU8_0(_this__u8e3s4) | readU8_0(_this__u8e3s4) << 8 | readU8_0(_this__u8e3s4) << 16 | readU8_0(_this__u8e3s4) << 24).be(new Long(-1, 0));
  }
  function readS16LE(_this__u8e3s4) {
    return signExtend(readU16LE_1(_this__u8e3s4), 16);
  }
  function readStream_0(_this__u8e3s4, length) {
    return readSlice(_this__u8e3s4, toLong(length));
  }
  function readAvailable(_this__u8e3s4) {
    return readBytes_0(_this__u8e3s4, _this__u8e3s4.k2r().t1());
  }
  function readExact_0(_this__u8e3s4, out, offset, len) {
    var ooffset = offset;
    var remaining = len;
    while (remaining > 0) {
      var read = _this__u8e3s4.v1h(out, ooffset, remaining);
      if (read <= 0) {
        throw RuntimeException_init_$Create$('EOF');
      }
      remaining = remaining - read | 0;
      ooffset = ooffset + read | 0;
    }
  }
  function indexOf_2(_this__u8e3s4, v, startOffset, endOffset, default_0) {
    startOffset = startOffset === VOID ? 0 : startOffset;
    endOffset = endOffset === VOID ? _this__u8e3s4.length : endOffset;
    default_0 = default_0 === VOID ? -1 : default_0;
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.io.util._indexOf' call
      var inductionVariable = startOffset;
      if (inductionVariable < endOffset)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$0;
          // Inline function 'korlibs.io.util.indexOf.<anonymous>' call
          tmp$ret$0 = _this__u8e3s4[n];
          if (tmp$ret$0 === v) {
            tmp$ret$1 = n;
            break $l$block;
          }
        }
         while (inductionVariable < endOffset);
      tmp$ret$1 = default_0;
    }
    return tmp$ret$1;
  }
  function isWhitespaceFast(_this__u8e3s4) {
    return ((equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(32))) ? true : equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(9)))) ? true : equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(13)))) ? true : equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(10)));
  }
  function isLetterDigitOrUnderscore(_this__u8e3s4) {
    return (isLetterOrDigit(_this__u8e3s4) ? true : equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(95)))) ? true : equals(new Char(_this__u8e3s4), new Char(_Char___init__impl__6a9atx(36)));
  }
  function isLetterOrDigit(_this__u8e3s4) {
    return isLetter(_this__u8e3s4) ? true : isDigit(_this__u8e3s4);
  }
  function isLetter(_this__u8e3s4) {
    return (_Char___init__impl__6a9atx(97) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(122) : false) ? true : _Char___init__impl__6a9atx(65) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(90) : false;
  }
  function isDigit(_this__u8e3s4) {
    return _Char___init__impl__6a9atx(48) <= _this__u8e3s4 ? _this__u8e3s4 <= _Char___init__impl__6a9atx(57) : false;
  }
  function Text_0() {
  }
  function Marker() {
  }
  function Line(str) {
    this.c3k_1 = str;
  }
  protoOf(Line).b3k = function () {
    return this.c3k_1;
  };
  protoOf(Line).toString = function () {
    return 'Line(str=' + this.c3k_1 + ')';
  };
  protoOf(Line).hashCode = function () {
    return getStringHashCode(this.c3k_1);
  };
  protoOf(Line).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Line))
      return false;
    var tmp0_other_with_cast = other instanceof Line ? other : THROW_CCE();
    if (!(this.c3k_1 === tmp0_other_with_cast.c3k_1))
      return false;
    return true;
  };
  function LineDeferred() {
  }
  function EmptyLineOnce() {
    EmptyLineOnce_instance = this;
  }
  var EmptyLineOnce_instance;
  function EmptyLineOnce_getInstance() {
    if (EmptyLineOnce_instance == null)
      new EmptyLineOnce();
    return EmptyLineOnce_instance;
  }
  function Indent() {
    Indent_instance = this;
  }
  var Indent_instance;
  function Indent_getInstance() {
    if (Indent_instance == null)
      new Indent();
    return Indent_instance;
  }
  function Unindent() {
    Unindent_instance = this;
  }
  var Unindent_instance;
  function Unindent_getInstance() {
    if (Unindent_instance == null)
      new Unindent();
    return Unindent_instance;
  }
  function INDENTS() {
    INDENTS_instance = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp_0 = 0;
    var tmp_1 = 1024;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_1), null);
    var tmp_2 = tmp$ret$0;
    while (tmp_0 < tmp_1) {
      var tmp_3 = tmp_0;
      var tmp$ret$1;
      // Inline function 'korlibs.io.util.INDENTS.INDENTS.<anonymous>' call
      tmp$ret$1 = '';
      tmp_2[tmp_3] = tmp$ret$1;
      tmp_0 = tmp_0 + 1 | 0;
    }
    var tmp0_apply = tmp_2;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.INDENTS.INDENTS.<anonymous>' call
    var indent = StringBuilder_init_$Create$();
    var inductionVariable = 0;
    var last = tmp0_apply.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        tmp0_apply[n] = indent.toString();
        indent.bc('\t');
      }
       while (inductionVariable < last);
    tmp$ret$2 = tmp0_apply;
    tmp.d3k_1 = tmp$ret$2;
  }
  protoOf(INDENTS).n = function (index) {
    if (index >= this.d3k_1.length) {
      // Inline function 'kotlin.TODO' call
      var tmp0_TODO = 'Too much indentation ' + index;
      throw new NotImplementedError('An operation is not implemented: ' + tmp0_TODO);
    }
    return index <= 0 ? '' : this.d3k_1[index];
  };
  var INDENTS_instance;
  function INDENTS_getInstance() {
    if (INDENTS_instance == null)
      new INDENTS();
    return INDENTS_instance;
  }
  function Companion_23() {
    Companion_instance_23 = this;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'korlibs.io.util.Companion.gen' call
    var tmp0_gen = Companion_getInstance_29();
    var indenter = new Indenter();
    var tmp$ret$0;
    // Inline function 'korlibs.io.util.Companion.EMPTY.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = indenter;
    tmp.e3k_1 = tmp$ret$1;
  }
  protoOf(Companion_23).f3k = function (init) {
    var tmp$ret$0;
    // Inline function 'korlibs.io.util.Companion.gen' call
    var indenter = new Indenter();
    init(indenter);
    tmp$ret$0 = indenter;
    return tmp$ret$0;
  };
  var Companion_instance_23;
  function Companion_getInstance_29() {
    if (Companion_instance_23 == null)
      new Companion_23();
    return Companion_instance_23;
  }
  function IndenterEvaluator(markHandler, indentEmptyLines, doIndent) {
    this.g3k_1 = markHandler;
    this.h3k_1 = indentEmptyLines;
    this.i3k_1 = doIndent;
    this.j3k_1 = StringBuilder_init_$Create$();
    this.k3k_1 = 0;
    this.l3k_1 = true;
    this.m3k_1 = 0;
    this.n3k_1 = false;
  }
  protoOf(IndenterEvaluator).o3k = function (str) {
    return this.j3k_1.bc(str);
  };
  protoOf(IndenterEvaluator).p3k = function (str) {
    return this.j3k_1.ib(str);
  };
  protoOf(IndenterEvaluator).q3k = function () {
    if (this.i3k_1) {
      this.p3k(_Char___init__impl__6a9atx(10));
    }
    var tmp0_this = this;
    var tmp1 = tmp0_this.k3k_1;
    tmp0_this.k3k_1 = tmp1 + 1 | 0;
    this.l3k_1 = true;
  };
  protoOf(IndenterEvaluator).r3k = function (actions) {
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < actions.l()) {
      // Inline function 'korlibs.io.util.IndenterEvaluator.eval.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp0__anonymous__q1qw7t = actions.n(tmp0);
      var tmp0_subject = tmp0__anonymous__q1qw7t;
      if (isInterface(tmp0_subject, Text_0)) {
        if (this.l3k_1) {
          var tmp;
          if (!this.h3k_1) {
            var tmp$ret$0;
            // Inline function 'kotlin.text.isEmpty' call
            var tmp0_isEmpty = tmp0__anonymous__q1qw7t.b3k();
            tmp$ret$0 = charSequenceLength(tmp0_isEmpty) === 0;
            tmp = tmp$ret$0;
          } else {
            tmp = false;
          }
          if (tmp) {
            this.q3k();
          } else {
            if (this.i3k_1) {
              this.o3k(INDENTS_getInstance().n(this.m3k_1));
            } else {
              this.o3k(' ');
            }
          }
        }
        this.o3k(tmp0__anonymous__q1qw7t.b3k());
        if (tmp0__anonymous__q1qw7t instanceof Line) {
          var tmp1_this = this;
          var tmp_0 = tmp1_this;
          var tmp_1 = tmp1_this.k3k_1;
          var tmp$ret$2;
          // Inline function 'kotlin.text.count' call
          var tmp1_count = tmp0__anonymous__q1qw7t.b3k();
          var count = 0;
          var indexedObject = tmp1_count;
          var inductionVariable = 0;
          var last = indexedObject.length;
          while (inductionVariable < last) {
            var element = charSequenceGet(indexedObject, inductionVariable);
            inductionVariable = inductionVariable + 1 | 0;
            var tmp$ret$1;
            // Inline function 'korlibs.io.util.IndenterEvaluator.eval.<anonymous>.<anonymous>' call
            tmp$ret$1 = equals(new Char(element), new Char(_Char___init__impl__6a9atx(10)));
            if (tmp$ret$1) {
              count = count + 1 | 0;
            }
          }
          tmp$ret$2 = count;
          tmp_0.k3k_1 = tmp_1 + tmp$ret$2 | 0;
          this.q3k();
        } else {
          this.l3k_1 = false;
        }
        this.n3k_1 = true;
      } else {
        if (tmp0_subject instanceof LineDeferred) {
          this.r3k(tmp0__anonymous__q1qw7t.w3k_1().t3k_1);
        } else {
          if (equals(tmp0_subject, Indent_getInstance()) ? true : equals(tmp0_subject, Unindent_getInstance())) {
            this.n3k_1 = false;
            var tmp2_this = this;
            tmp2_this.m3k_1 = tmp2_this.m3k_1 + (equals(tmp0__anonymous__q1qw7t, Indent_getInstance()) ? 1 : -1) | 0;
          } else {
            if (equals(tmp0_subject, EmptyLineOnce_getInstance())) {
              if (this.n3k_1) {
                this.q3k();
                this.n3k_1 = false;
              }
            } else {
              if (tmp0_subject instanceof Marker) {
                var tmp3_safe_receiver = this.g3k_1;
                if (tmp3_safe_receiver == null)
                  null;
                else
                  tmp3_safe_receiver(this.j3k_1, this.k3k_1, tmp0__anonymous__q1qw7t.s3k_1);
              }
            }
          }
        }
      }
    }
  };
  function Indenter(actions) {
    Companion_getInstance_29();
    var tmp;
    if (actions === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.arrayListOf' call
      tmp$ret$0 = ArrayList_init_$Create$_0();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = actions;
    }
    actions = tmp;
    this.t3k_1 = actions;
    this.u3k_1 = true;
    this.v3k_1 = '';
  }
  protoOf(Indenter).x3k = function (indenter) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.line.<anonymous>' call
    this.t3k_1.m(indenter.t3k_1);
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(Indenter).y3k = function (str) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.line.<anonymous>' call
    this.t3k_1.b(new Line(str));
    tmp$ret$0 = this;
    return tmp$ret$0;
  };
  protoOf(Indenter).z3k = function () {
    this.t3k_1.b(Indent_getInstance());
  };
  protoOf(Indenter).a3l = function () {
    this.t3k_1.b(Unindent_getInstance());
  };
  protoOf(Indenter).b3l = function (markHandler, doIndent) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new IndenterEvaluator(markHandler, this.u3k_1, doIndent);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.Indenter.toString.<anonymous>' call
    tmp0_apply.r3k(this.t3k_1);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0.j3k_1.toString();
  };
  protoOf(Indenter).toString = function () {
    return this.b3l(null, true);
  };
  var MINUS_ZERO_D;
  function get_niceStr(_this__u8e3s4) {
    return get_niceStr_0(_this__u8e3s4);
  }
  function niceStr(_this__u8e3s4, decimalPlaces) {
    return niceStr_0(_this__u8e3s4, decimalPlaces);
  }
  function niceStr_0(_this__u8e3s4, decimalPlaces) {
    return get_niceStr_0(roundDecimalPlaces(_this__u8e3s4, decimalPlaces));
  }
  function toStringDecimal(_this__u8e3s4, decimalPlaces, skipTrailingZeros) {
    skipTrailingZeros = skipTrailingZeros === VOID ? false : skipTrailingZeros;
    if (isNanOrInfinite(_this__u8e3s4))
      return _this__u8e3s4.toString();
    var res = normalizeZero(roundDecimalPlaces(_this__u8e3s4, decimalPlaces)).toString();
    var eup = indexOf_1(res, _Char___init__impl__6a9atx(69));
    var elo = indexOf_1(res, _Char___init__impl__6a9atx(101));
    var eIndex = eup >= 0 ? eup : elo;
    var tmp;
    if (eIndex >= 0) {
      var tmp$ret$1;
      // Inline function 'kotlin.text.substring' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = res;
      tmp$ret$1 = tmp$ret$0.substring(0, eIndex);
      var base = tmp$ret$1;
      var tmp$ret$3;
      // Inline function 'kotlin.text.substring' call
      var tmp0_substring = eIndex + 1 | 0;
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = res;
      tmp$ret$3 = tmp$ret$2.substring(tmp0_substring);
      var exp = toInt_0(tmp$ret$3);
      var rbase = contains_1(base, '.') ? base : base + '.0';
      var tmp$ret$4;
      // Inline function 'kotlin.math.absoluteValue' call
      tmp$ret$4 = abs(exp);
      var zeros = repeat('0', tmp$ret$4 + 2 | 0);
      var part = exp > 0 ? rbase + zeros : zeros + rbase;
      var pointIndex2 = indexOf(part, '.');
      var pointIndex = pointIndex2 < 0 ? part.length : pointIndex2;
      var outIndex = pointIndex + exp | 0;
      var part2 = replace_0(part, '.', '');
      var tmp$ret$8;
      // Inline function 'kotlin.text.buildString' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$7;
      // Inline function 'kotlin.apply' call
      var tmp1_apply = StringBuilder_init_$Create$();
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>' call
      var tmp$ret$5;
      $l$block_0: {
        // Inline function 'kotlin.collections.all' call
        var tmp0_all = until(0, outIndex);
        var tmp_0;
        if (isInterface(tmp0_all, Collection)) {
          tmp_0 = tmp0_all.p();
        } else {
          tmp_0 = false;
        }
        if (tmp_0) {
          tmp$ret$5 = true;
          break $l$block_0;
        }
        var inductionVariable = tmp0_all.p1_1;
        var last = tmp0_all.q1_1;
        if (inductionVariable <= last)
          do {
            var element = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var tmp$ret$6;
            // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
            tmp$ret$6 = equals(new Char(charSequenceGet(part2, element)), new Char(_Char___init__impl__6a9atx(48)));
            if (!tmp$ret$6) {
              tmp$ret$5 = false;
              break $l$block_0;
            }
          }
           while (!(element === last));
        tmp$ret$5 = true;
      }
      if (tmp$ret$5) {
        tmp1_apply.ib(_Char___init__impl__6a9atx(48));
      } else {
        tmp1_apply.ik(part2, 0, outIndex);
      }
      tmp1_apply.ib(_Char___init__impl__6a9atx(46));
      tmp1_apply.ik(part2, outIndex, part2.length);
      tmp$ret$7 = tmp1_apply;
      tmp$ret$8 = tmp$ret$7.toString();
      tmp = tmp$ret$8;
    } else {
      tmp = res;
    }
    var rez = tmp;
    var pointIndex_0 = indexOf_1(rez, _Char___init__impl__6a9atx(46));
    var tmp_1;
    if (pointIndex_0 >= 0) {
      var tmp$ret$10;
      // Inline function 'kotlin.text.substring' call
      var tmp$ret$9;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$9 = rez;
      tmp$ret$10 = tmp$ret$9.substring(0, pointIndex_0);
      tmp_1 = tmp$ret$10;
    } else {
      tmp_1 = rez;
    }
    var integral = tmp_1;
    if (decimalPlaces === 0)
      return integral;
    var tmp_2;
    if (pointIndex_0 >= 0) {
      var tmp$ret$12;
      // Inline function 'kotlin.text.substring' call
      var tmp2_substring = pointIndex_0 + 1 | 0;
      var tmp$ret$11;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$11 = rez;
      tmp$ret$12 = tmp$ret$11.substring(tmp2_substring);
      tmp_2 = trimEnd(tmp$ret$12, charArrayOf([_Char___init__impl__6a9atx(48)]));
    } else {
      tmp_2 = '';
    }
    var decimal = tmp_2;
    var tmp$ret$18;
    // Inline function 'kotlin.text.buildString' call
    var tmp4_buildString = (2 + integral.length | 0) + decimalPlaces | 0;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$17;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = StringBuilder_init_$Create$_0(tmp4_buildString);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>' call
    tmp3_apply.bc(integral);
    var tmp_3;
    var tmp$ret$13;
    // Inline function 'kotlin.text.isNotEmpty' call
    tmp$ret$13 = charSequenceLength(decimal) > 0;
    if (tmp$ret$13) {
      tmp_3 = true;
    } else {
      tmp_3 = !skipTrailingZeros;
    }
    if (tmp_3) {
      var tmp$ret$14;
      // Inline function 'kotlin.math.min' call
      var tmp0_min = decimal.length;
      tmp$ret$14 = Math.min(tmp0_min, decimalPlaces);
      var decimalCount = tmp$ret$14;
      var tmp$ret$15;
      $l$block_2: {
        // Inline function 'kotlin.collections.all' call
        var tmp1_all = until(0, decimalCount);
        var tmp_4;
        if (isInterface(tmp1_all, Collection)) {
          tmp_4 = tmp1_all.p();
        } else {
          tmp_4 = false;
        }
        if (tmp_4) {
          tmp$ret$15 = true;
          break $l$block_2;
        }
        var inductionVariable_0 = tmp1_all.p1_1;
        var last_0 = tmp1_all.q1_1;
        if (inductionVariable_0 <= last_0)
          do {
            var element_0 = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var tmp$ret$16;
            // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
            tmp$ret$16 = equals(new Char(charSequenceGet(decimal, element_0)), new Char(_Char___init__impl__6a9atx(48)));
            if (!tmp$ret$16) {
              tmp$ret$15 = false;
              break $l$block_2;
            }
          }
           while (!(element_0 === last_0));
        tmp$ret$15 = true;
      }
      var allZeros = tmp$ret$15;
      if (!skipTrailingZeros ? true : !allZeros) {
        tmp3_apply.ib(_Char___init__impl__6a9atx(46));
        tmp3_apply.ik(decimal, 0, decimalCount);
        if (!skipTrailingZeros) {
          // Inline function 'kotlin.repeat' call
          var tmp2_repeat = decimalPlaces - decimalCount | 0;
          // Inline function 'kotlin.contracts.contract' call
          var inductionVariable_1 = 0;
          if (inductionVariable_1 < tmp2_repeat)
            do {
              var index = inductionVariable_1;
              inductionVariable_1 = inductionVariable_1 + 1 | 0;
              // Inline function 'korlibs.io.util.toStringDecimal.<anonymous>.<anonymous>' call
              tmp3_apply.ib(_Char___init__impl__6a9atx(48));
            }
             while (inductionVariable_1 < tmp2_repeat);
        }
      }
    }
    tmp$ret$17 = tmp3_apply;
    tmp$ret$18 = tmp$ret$17.toString();
    return tmp$ret$18;
  }
  function toStringDecimal_0(_this__u8e3s4, decimalPlaces, skipTrailingZeros) {
    skipTrailingZeros = skipTrailingZeros === VOID ? false : skipTrailingZeros;
    return toStringDecimal(_this__u8e3s4, decimalPlaces, skipTrailingZeros);
  }
  function get_niceStr_0(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.<get-niceStr>.<anonymous>' call
    appendNice(tmp0_apply, _this__u8e3s4);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  }
  function roundDecimalPlaces(_this__u8e3s4, places) {
    if (places < 0)
      return _this__u8e3s4;
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    var tmp0_pow = places;
    tmp$ret$0 = Math.pow(10.0, tmp0_pow);
    var placesFactor = tmp$ret$0;
    return round(_this__u8e3s4 * placesFactor) / placesFactor;
  }
  function normalizeZero(_this__u8e3s4) {
    return _this__u8e3s4 === MINUS_ZERO_D ? 0.0 : _this__u8e3s4;
  }
  function appendNice(_this__u8e3s4, value) {
    if (isAlmostEquals(round(value), value))
      if (value >= IntCompanionObject_getInstance().MIN_VALUE ? value <= IntCompanionObject_getInstance().MAX_VALUE : false) {
        _this__u8e3s4.ac(numberToInt(value));
      } else {
        _this__u8e3s4.ac(numberToLong(value));
      }
     else {
      _this__u8e3s4.ac(value);
    }
  }
  function isAlmostEquals(_this__u8e3s4, other, epsilon) {
    epsilon = epsilon === VOID ? 1.0E-6 : epsilon;
    var tmp$ret$0;
    // Inline function 'kotlin.math.absoluteValue' call
    var tmp0__get_absoluteValue__nukmtt = _this__u8e3s4 - other;
    tmp$ret$0 = Math.abs(tmp0__get_absoluteValue__nukmtt);
    return tmp$ret$0 < epsilon;
  }
  function NumberParser() {
    NumberParser_instance = this;
  }
  protoOf(NumberParser).c3l = function (str, start, end, radix) {
    var positive = true;
    var out = 0;
    var inductionVariable = start;
    if (inductionVariable < end)
      loop: do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = charSequenceGet(str, n);
        if (equals(new Char(c), new Char(_Char___init__impl__6a9atx(45))) ? true : equals(new Char(c), new Char(_Char___init__impl__6a9atx(43)))) {
          positive = equals(new Char(c), new Char(_Char___init__impl__6a9atx(43)));
        } else {
          var value = ctypeAsInt(c);
          if (value < 0)
            break loop;
          out = imul(out, radix);
          out = out + value | 0;
        }
      }
       while (inductionVariable < end);
    return positive ? out : -out | 0;
  };
  protoOf(NumberParser).h2y = function (str, start, end) {
    var out = 0.0;
    var frac = 1.0;
    var pointSeen = false;
    var eSeen = false;
    var negate = false;
    var negateExponent = false;
    var exponent = 0;
    var inductionVariable = start;
    if (inductionVariable < end)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = charSequenceGet(str, n);
        var tmp1_subject = c;
        if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(101))) ? true : equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(69))))
          eSeen = true;
        else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(45)))) {
          if (eSeen)
            negateExponent = true;
          else
            negate = true;
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(46))))
          pointSeen = true;
        else {
          if (eSeen) {
            exponent = imul(exponent, 10);
            exponent = exponent + ctypeAsInt(c) | 0;
          } else {
            if (pointSeen)
              frac = frac / 10;
            out = out * 10;
            out = out + ctypeAsInt(c);
          }
        }
      }
       while (inductionVariable < end);
    var tmp = out * frac;
    var tmp$ret$0;
    // Inline function 'kotlin.math.pow' call
    var tmp0_pow = negateExponent ? -exponent | 0 : exponent;
    tmp$ret$0 = Math.pow(10.0, tmp0_pow);
    var res = tmp * tmp$ret$0;
    return negate ? -res : res;
  };
  var NumberParser_instance;
  function NumberParser_getInstance() {
    if (NumberParser_instance == null)
      new NumberParser();
    return NumberParser_instance;
  }
  function ctypeAsInt(_this__u8e3s4) {
    return (Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(48)) >= 0 ? Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(57)) <= 0 : false) ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(48)) : (Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(97)) >= 0 ? Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(122)) <= 0 : false) ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(97)) + 10 | 0 : (Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(65)) >= 0 ? Char__compareTo_impl_ypi4mb(_this__u8e3s4, _Char___init__impl__6a9atx(90)) <= 0 : false) ? Char__minus_impl_a2frrh(_this__u8e3s4, _Char___init__impl__6a9atx(65)) + 10 | 0 : -1;
  }
  function Once() {
    this.d3l_1 = false;
  }
  function get_LONG_ZERO_TO_MAX_RANGE() {
    _init_properties_RangeExt_kt__vz0y4k();
    return LONG_ZERO_TO_MAX_RANGE;
  }
  var LONG_ZERO_TO_MAX_RANGE;
  function toLongRange(_this__u8e3s4) {
    _init_properties_RangeExt_kt__vz0y4k();
    return toLong(_this__u8e3s4.u()).n1(toLong(_this__u8e3s4.v()));
  }
  var properties_initialized_RangeExt_kt_dqkq5i;
  function _init_properties_RangeExt_kt__vz0y4k() {
    if (properties_initialized_RangeExt_kt_dqkq5i) {
    } else {
      properties_initialized_RangeExt_kt_dqkq5i = true;
      var tmp = new Long(0, 0);
      Companion_getInstance_4();
      LONG_ZERO_TO_MAX_RANGE = tmp.n1(new Long(-1, 2147483647));
    }
  }
  function get_length(_this__u8e3s4) {
    return (_this__u8e3s4.q1_1 - _this__u8e3s4.p1_1 | 0) + 1 | 0;
  }
  function Companion_24() {
    Companion_instance_24 = this;
  }
  protoOf(Companion_24).s30 = function (lits) {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.toTypedArray' call
    var tmp$ret$2;
    // Inline function 'kotlin.collections.map' call
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(lits.length);
    var indexedObject = lits;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'korlibs.io.util.Companion.fromList.<anonymous>' call
      tmp$ret$0 = item.length;
      tmp0_mapTo.b(tmp$ret$0);
    }
    tmp$ret$1 = tmp0_mapTo;
    tmp$ret$2 = tmp$ret$1;
    var tmp1_toTypedArray = distinct(reversed(sorted(tmp$ret$2)));
    tmp$ret$3 = copyToArray(tmp1_toTypedArray);
    var lengths = tmp$ret$3;
    var tmp$ret$4;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp$ret$4 = LinkedHashMap_init_$Create$();
    var map = tmp$ret$4;
    // Inline function 'korlibs.datastructure.iterators.fastForEach' call
    var n = 0;
    while (n < lits.length) {
      // Inline function 'korlibs.io.util.Companion.fromList.<anonymous>' call
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var tmp2__anonymous__z9zvc9 = lits[tmp0];
      // Inline function 'kotlin.collections.set' call
      map.e5(tmp2__anonymous__z9zvc9, true);
    }
    return new Literals(lits, map, lengths);
  };
  var Companion_instance_24;
  function Companion_getInstance_30() {
    if (Companion_instance_24 == null)
      new Companion_24();
    return Companion_instance_24;
  }
  function Companion_25() {
    Companion_instance_25 = this;
  }
  var Companion_instance_25;
  function Companion_getInstance_31() {
    if (Companion_instance_25 == null)
      new Companion_25();
    return Companion_instance_25;
  }
  function posSkip($this, count) {
    var out = $this.l2s_1;
    var tmp0_this = $this;
    tmp0_this.l2s_1 = tmp0_this.l2s_1 + count | 0;
    return out;
  }
  function substr_2($this, pos, length) {
    var tmp$ret$3;
    // Inline function 'kotlin.text.substring' call
    var tmp3_substring = $this.j2s_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = $this.n2s_1;
    tmp$ret$0 = Math.min(pos, tmp0_min);
    var tmp4_substring = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp1_min = pos + length | 0;
    var tmp2_min = $this.n2s_1;
    tmp$ret$1 = Math.min(tmp1_min, tmp2_min);
    var tmp5_substring = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$2 = tmp3_substring;
    tmp$ret$3 = tmp$ret$2.substring(tmp4_substring, tmp5_substring);
    return tmp$ret$3;
  }
  function Literals(lits, map, lengths) {
    Companion_getInstance_30();
    this.e3l_1 = lits;
    this.f3l_1 = map;
    this.g3l_1 = lengths;
  }
  protoOf(Literals).toString = function () {
    return 'Literals(' + joinToString_0(this.e3l_1, ' ') + ')';
  };
  function StrReader(str, file, pos) {
    Companion_getInstance_31();
    file = file === VOID ? 'file' : file;
    pos = pos === VOID ? 0 : pos;
    BaseStrReader.call(this);
    this.j2s_1 = str;
    this.k2s_1 = file;
    this.l2s_1 = pos;
    this.m2s_1 = charArray(this.j2s_1.length);
    this.n2s_1 = this.j2s_1.length;
  }
  protoOf(StrReader).e30 = function () {
    return this.l2s_1;
  };
  protoOf(StrReader).k2r = function () {
    return this.n2s_1 - this.l2s_1 | 0;
  };
  protoOf(StrReader).p2r = function () {
    return this.l2s_1 >= this.j2s_1.length;
  };
  protoOf(StrReader).i30 = function (start) {
    return this.h3l(start, this.l2s_1);
  };
  protoOf(StrReader).b2y = function () {
    protoOf(BaseStrReader).b2y.call(this);
    return this;
  };
  protoOf(StrReader).h3l = function (start, end) {
    if (start === end)
      return '';
    var tmp$ret$1;
    // Inline function 'kotlin.text.substring' call
    var tmp0_substring = this.j2s_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_substring;
    tmp$ret$1 = tmp$ret$0.substring(start, end);
    return tmp$ret$1;
  };
  protoOf(StrReader).y1h = function (count) {
    return substr_2(this, this.l2s_1, count);
  };
  protoOf(StrReader).f2y = function () {
    return this.q2r() ? charSequenceGet(this.j2s_1, this.l2s_1) : _Char___init__impl__6a9atx(0);
  };
  protoOf(StrReader).i3l = function (char) {
    var skipPos = indexOf_1(this.j2s_1, char, this.l2s_1);
    this.l2s_1 = skipPos >= 0 ? skipPos : this.n2s_1;
  };
  protoOf(StrReader).j3l = function (char) {
    this.i3l(char);
    if (this.q2r() ? equals(new Char(this.f2y()), new Char(char)) : false) {
      this.w1h(1);
    }
  };
  protoOf(StrReader).y2z = function (char) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.io.util.BaseStrReader.slice' call
      var start = this.g30();
      try {
        // Inline function 'korlibs.io.util.StrReader.readUntil.<anonymous>' call
        this.i3l(char);
      }finally {
        tmp$ret$0 = this.i30(start);
        break $l$block;
      }
    }
    return tmp$ret$0;
  };
  protoOf(StrReader).v30 = function (char) {
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.io.util.BaseStrReader.slice' call
      var start = this.g30();
      try {
        // Inline function 'korlibs.io.util.StrReader.readUntilIncluded.<anonymous>' call
        this.j3l(char);
      }finally {
        tmp$ret$0 = this.i30(start);
        break $l$block;
      }
    }
    return tmp$ret$0;
  };
  protoOf(StrReader).k3l = function (count) {
    var tmp0_this = this;
    tmp0_this.l2s_1 = tmp0_this.l2s_1 - count | 0;
    return this;
  };
  protoOf(StrReader).d2y = function (count, $super) {
    count = count === VOID ? 1 : count;
    return $super === VOID ? this.k3l(count) : $super.k3l.call(this, count);
  };
  protoOf(StrReader).g2y = function () {
    return this.q2r() ? charSequenceGet(this.j2s_1, posSkip(this, 1)) : _Char___init__impl__6a9atx(0);
  };
  protoOf(StrReader).c2y = function () {
    return this.q2r() ? charSequenceGet(this.j2s_1, posSkip(this, 1)) : _Char___init__impl__6a9atx(0);
  };
  protoOf(StrReader).q2s = function () {
    return this.l3l(this.k2r());
  };
  protoOf(StrReader).w1h = function (count) {
    var tmp0_this = this;
    tmp0_this.l2s_1 = tmp0_this.l2s_1 + count | 0;
    return this;
  };
  protoOf(StrReader).m3l = function (lit, consume) {
    if (!substringEquals(StringCompanionObject_getInstance(), this.j2s_1, this.l2s_1, lit, 0, lit.length))
      return null;
    if (consume) {
      var tmp0_this = this;
      tmp0_this.l2s_1 = tmp0_this.l2s_1 + lit.length | 0;
    }
    return lit;
  };
  protoOf(StrReader).o2s = function (v) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.substring' call
    var tmp0_substring = this.j2s_1;
    var tmp1_substring = this.l2s_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_substring;
    tmp$ret$1 = tmp$ret$0.substring(tmp1_substring);
    var tmp0_elvis_lhs = v.xk(tmp$ret$1);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var result = tmp;
    var m = ensureNotNull(result.ol().n(0)).al_1;
    var tmp1_this = this;
    tmp1_this.l2s_1 = tmp1_this.l2s_1 + m.length | 0;
    return m;
  };
  protoOf(StrReader).z19 = function () {
    return new StrReader(this.j2s_1, this.k2s_1, this.l2s_1);
  };
  protoOf(StrReader).n3l = function (count, radix) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.k2r();
    tmp$ret$0 = Math.min(tmp0_min, count);
    var readCount = tmp$ret$0;
    this.w1h(readCount);
    return NumberParser_getInstance().c3l(this.j2s_1, this.l2s_1 - readCount | 0, this.l2s_1, radix);
  };
  protoOf(StrReader).o3l = function (reportErrors) {
    var out = this.m2s_1;
    var outp = 0;
    var quotec = this.c2y();
    var tmp0_subject = quotec;
    if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(34))) ? true : equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(39))))
    ;
    else {
      invalidOp('Invalid string literal');
    }
    var closed = false;
    loop: while (this.q2r()) {
      var c = this.c2y();
      if (equals(new Char(c), new Char(_Char___init__impl__6a9atx(92)))) {
        var cc = this.c2y();
        var tmp2 = outp;
        outp = tmp2 + 1 | 0;
        var tmp1_subject = cc;
        var tmp;
        if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(92)))) {
          tmp = _Char___init__impl__6a9atx(92);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(47)))) {
          tmp = _Char___init__impl__6a9atx(47);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(39)))) {
          tmp = _Char___init__impl__6a9atx(39);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(34)))) {
          tmp = _Char___init__impl__6a9atx(34);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(98)))) {
          tmp = _Char___init__impl__6a9atx(8);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(102)))) {
          tmp = _Char___init__impl__6a9atx(12);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(110)))) {
          tmp = _Char___init__impl__6a9atx(10);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(114)))) {
          tmp = _Char___init__impl__6a9atx(13);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(116)))) {
          tmp = _Char___init__impl__6a9atx(9);
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(117)))) {
          tmp = numberToChar(this.n3l(4, 16));
        } else {
          throw new IOException("Invalid char '" + new Char(cc) + "'");
        }
        out[tmp2] = tmp;
      } else if (equals(new Char(c), new Char(quotec))) {
        closed = true;
        break loop;
      } else {
        var tmp3 = outp;
        outp = tmp3 + 1 | 0;
        out[tmp3] = c;
      }
    }
    if (!closed ? reportErrors : false) {
      throw RuntimeException_init_$Create$("String literal not closed! '" + this.j2s_1 + "'");
    }
    return String_fromCharArray(out, 0, outp);
  };
  protoOf(StrReader).l2y = function (reportErrors, $super) {
    reportErrors = reportErrors === VOID ? true : reportErrors;
    return $super === VOID ? this.o3l(reportErrors) : $super.o3l.call(this, reportErrors);
  };
  protoOf(StrReader).p3l = function (offset) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.getOrElse' call
    var tmp0_getOrElse = this.j2s_1;
    var tmp1_getOrElse = this.l2s_1 + offset | 0;
    var tmp;
    if (tmp1_getOrElse >= 0 ? tmp1_getOrElse <= get_lastIndex(tmp0_getOrElse) : false) {
      tmp = charSequenceGet(tmp0_getOrElse, tmp1_getOrElse);
    } else {
      var tmp$ret$0;
      // Inline function 'korlibs.io.util.StrReader.peekOffset.<anonymous>' call
      tmp$ret$0 = _Char___init__impl__6a9atx(0);
      tmp = tmp$ret$0;
    }
    tmp$ret$1 = tmp;
    return tmp$ret$1;
  };
  function BaseStrReader() {
  }
  protoOf(BaseStrReader).q2r = function () {
    return !this.p2r();
  };
  protoOf(BaseStrReader).f2y = function () {
    return this.p3l(0);
  };
  protoOf(BaseStrReader).g2y = function () {
    var out = this.f2y();
    this.w1h(1);
    return out;
  };
  protoOf(BaseStrReader).y2z = function (char) {
    var out = StringBuilder_init_$Create$();
    $l$loop: while (this.q2r()) {
      var result = this.f2y();
      if (equals(new Char(result), new Char(char)))
        break $l$loop;
      out.ib(result);
      this.w1h(1);
    }
    return out.toString();
  };
  protoOf(BaseStrReader).a2y = function (expected) {
    var readed = this.g2y();
    if (!equals(new Char(readed), new Char(expected)))
      throw IllegalArgumentException_init_$Create$("Expected '" + new Char(expected) + "' but found '" + new Char(readed) + "' at " + this.e30());
  };
  protoOf(BaseStrReader).p2s = function (lit, consume, $super) {
    consume = consume === VOID ? true : consume;
    return $super === VOID ? this.m3l(lit, consume) : $super.m3l.call(this, lit, consume);
  };
  protoOf(BaseStrReader).g30 = function () {
    return this.e30();
  };
  protoOf(BaseStrReader).b2y = function () {
    // Inline function 'korlibs.io.util.BaseStrReader.skipWhile' call
    $l$loop: while (true) {
      var tmp;
      if (this.q2r()) {
        var tmp$ret$0;
        // Inline function 'korlibs.io.util.BaseStrReader.skipSpaces.<anonymous>' call
        var tmp0__anonymous__q1qw7t = this.f2y();
        tmp$ret$0 = isWhitespaceFast(tmp0__anonymous__q1qw7t);
        tmp = tmp$ret$0;
      } else {
        tmp = false;
      }
      if (!tmp) {
        break $l$loop;
      }
      this.g2y();
    }
    return this;
  };
  protoOf(BaseStrReader).z2x = function (str) {
    return !(this.m3l(str, true) == null);
  };
  protoOf(BaseStrReader).h2z = function () {
    var tmp$ret$5;
    // Inline function 'kotlin.takeIf' call
    var tmp$ret$2;
    // Inline function 'korlibs.io.util.BaseStrReader.matchWhile' call
    var tmp$ret$1;
    $l$block: {
      // Inline function 'korlibs.io.util.BaseStrReader.slice' call
      var start = this.g30();
      try {
        // Inline function 'korlibs.io.util.BaseStrReader.matchWhile.<anonymous>' call
        // Inline function 'korlibs.io.util.BaseStrReader.skipWhile' call
        $l$loop: while (true) {
          var tmp;
          if (this.q2r()) {
            var tmp$ret$0;
            // Inline function 'korlibs.io.util.BaseStrReader.matchIdentifier.<anonymous>' call
            var tmp0__anonymous__q1qw7t = this.f2y();
            tmp$ret$0 = ((isLetterDigitOrUnderscore(tmp0__anonymous__q1qw7t) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(45)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(126)))) ? true : equals(new Char(tmp0__anonymous__q1qw7t), new Char(_Char___init__impl__6a9atx(58)));
            tmp = tmp$ret$0;
          } else {
            tmp = false;
          }
          if (!tmp) {
            break $l$loop;
          }
          this.g2y();
        }
      }finally {
        tmp$ret$1 = this.i30(start);
        break $l$block;
      }
    }
    tmp$ret$2 = tmp$ret$1;
    var tmp1_takeIf = tmp$ret$2;
    // Inline function 'kotlin.contracts.contract' call
    var tmp_0;
    var tmp$ret$4;
    // Inline function 'korlibs.io.util.BaseStrReader.matchIdentifier.<anonymous>' call
    var tmp$ret$3;
    // Inline function 'kotlin.text.isNotEmpty' call
    tmp$ret$3 = charSequenceLength(tmp1_takeIf) > 0;
    tmp$ret$4 = tmp$ret$3;
    if (tmp$ret$4) {
      tmp_0 = tmp1_takeIf;
    } else {
      tmp_0 = null;
    }
    tmp$ret$5 = tmp_0;
    return tmp$ret$5;
  };
  protoOf(BaseStrReader).g2z = function () {
    var tmp0_subject = this.f2y();
    var tmp;
    if (equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(39))) ? true : equals(new Char(tmp0_subject), new Char(_Char___init__impl__6a9atx(34)))) {
      var tmp$ret$0;
      $l$block: {
        // Inline function 'korlibs.io.util.BaseStrReader.slice' call
        var start = this.g30();
        try {
          // Inline function 'korlibs.io.util.BaseStrReader.matchSingleOrDoubleQuoteString.<anonymous>' call
          var quoteType = this.g2y();
          this.y2z(quoteType);
          this.g2y();
        }finally {
          tmp$ret$0 = this.i30(start);
          break $l$block;
        }
      }
      tmp = tmp$ret$0;
    } else {
      tmp = null;
    }
    return tmp;
  };
  protoOf(BaseStrReader).h30 = function (str, consume) {
    var inductionVariable = 0;
    var last = str.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (!equals(new Char(this.p3l(n)), new Char(charSequenceGet(str, n))))
          return false;
      }
       while (inductionVariable < last);
    if (consume) {
      this.w1h(str.length);
    }
    return true;
  };
  protoOf(BaseStrReader).f30 = function (str, consume, $super) {
    consume = consume === VOID ? true : consume;
    return $super === VOID ? this.h30(str, consume) : $super.h30.call(this, str, consume);
  };
  protoOf(BaseStrReader).d30 = function (str) {
    if (!equals(new Char(this.f2y()), new Char(str)))
      return false;
    this.w1h(1);
    return true;
  };
  protoOf(BaseStrReader).l3l = function (count) {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.y1h(count);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.util.BaseStrReader.read.<anonymous>' call
    this.w1h(count);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  };
  protoOf(BaseStrReader).j30 = function (expected) {
    var readed = this.l3l(expected.length);
    if (!(readed === expected))
      throw IllegalArgumentException_init_$Create$("Expected '" + expected + "' but found '" + readed + "' at " + this.e30());
    return readed;
  };
  function quote(_this__u8e3s4) {
    return !(_this__u8e3s4 == null) ? '"' + escape(_this__u8e3s4) + '"' : 'null';
  }
  function escape(_this__u8e3s4) {
    var out = StringBuilder_init_$Create$();
    var inductionVariable = 0;
    var last = _this__u8e3s4.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var c = charSequenceGet(_this__u8e3s4, n);
        var tmp1_subject = c;
        if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(92)))) {
          out.bc('\\\\');
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(34)))) {
          out.bc('\\"');
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(10)))) {
          out.bc('\\n');
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(13)))) {
          out.bc('\\r');
        } else if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(9)))) {
          out.bc('\\t');
        } else if (_Char___init__impl__6a9atx(0) <= tmp1_subject ? tmp1_subject <= _Char___init__impl__6a9atx(31) : false) {
          out.bc('\\x');
          out.ib(Hex_getInstance().x1l(extract(Char__toInt_impl_vasixd(c), 4, 4)));
          out.ib(Hex_getInstance().x1l(extract(Char__toInt_impl_vasixd(c), 0, 4)));
        } else {
          out.ib(c);
        }
      }
       while (inductionVariable < last);
    return out.toString();
  }
  function unquote(_this__u8e3s4) {
    var tmp;
    if (isQuoted(_this__u8e3s4)) {
      var tmp$ret$1;
      // Inline function 'kotlin.text.substring' call
      var tmp0_substring = _this__u8e3s4.length - 1 | 0;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = _this__u8e3s4;
      tmp$ret$1 = tmp$ret$0.substring(1, tmp0_substring);
      tmp = unescape(tmp$ret$1);
    } else {
      tmp = _this__u8e3s4;
    }
    return tmp;
  }
  function isQuoted(_this__u8e3s4) {
    return startsWith_0(_this__u8e3s4, _Char___init__impl__6a9atx(34)) ? endsWith_0(_this__u8e3s4, _Char___init__impl__6a9atx(34)) : false;
  }
  function unescape(_this__u8e3s4) {
    var out = StringBuilder_init_$Create$();
    var n = 0;
    while (n < _this__u8e3s4.length) {
      var tmp0 = n;
      n = tmp0 + 1 | 0;
      var c = charSequenceGet(_this__u8e3s4, tmp0);
      var tmp1_subject = c;
      if (equals(new Char(tmp1_subject), new Char(_Char___init__impl__6a9atx(92)))) {
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        var c2 = charSequenceGet(_this__u8e3s4, tmp2);
        var tmp3_subject = c2;
        if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(92)))) {
          out.ib(_Char___init__impl__6a9atx(92));
        } else if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(34)))) {
          out.ib(_Char___init__impl__6a9atx(34));
        } else if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(110)))) {
          out.ib(_Char___init__impl__6a9atx(10));
        } else if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(114)))) {
          out.ib(_Char___init__impl__6a9atx(13));
        } else if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(116)))) {
          out.ib(_Char___init__impl__6a9atx(9));
        } else if (equals(new Char(tmp3_subject), new Char(_Char___init__impl__6a9atx(117)))) {
          var tmp$ret$1;
          // Inline function 'kotlin.text.substring' call
          var tmp0_substring = n;
          var tmp1_substring = n + 4 | 0;
          var tmp$ret$0;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$0 = _this__u8e3s4;
          tmp$ret$1 = tmp$ret$0.substring(tmp0_substring, tmp1_substring);
          var chars = tmp$ret$1;
          n = n + 4 | 0;
          out.ib(numberToChar(toInt(chars, 16)));
        } else {
          out.bc('\\' + new Char(c2));
        }
      } else {
        out.ib(c);
      }
    }
    return out.toString();
  }
  function substringAfterOrNull(_this__u8e3s4, delimiter) {
    return contains(_this__u8e3s4, delimiter) ? substringAfter(_this__u8e3s4, delimiter) : null;
  }
  function substringBeforeOrNull(_this__u8e3s4, delimiter) {
    return contains(_this__u8e3s4, delimiter) ? substringBefore(_this__u8e3s4, delimiter) : null;
  }
  function Adler32() {
    Adler32_instance = this;
    this.q1w_1 = 65521;
    this.r1w_1 = 1;
  }
  protoOf(Adler32).s1w = function (old, data, offset, len) {
    var s1 = (old >>> 0 | 0) & 65535;
    var s2 = (old >>> 16 | 0) & 65535;
    var inductionVariable = offset;
    var last = offset + len | 0;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        s1 = (s1 + (data[n] & 255) | 0) % 65521 | 0;
        s2 = (s2 + s1 | 0) % 65521 | 0;
      }
       while (inductionVariable < last);
    return s2 << 16 | s1;
  };
  var Adler32_instance;
  function Adler32_getInstance() {
    if (Adler32_instance == null)
      new Adler32();
    return Adler32_instance;
  }
  function get_jsGlobalDynamic() {
    _init_properties_KorioNativeJs_kt__151su();
    return jsGlobalDynamic;
  }
  var jsGlobalDynamic;
  function get_jsGlobal() {
    _init_properties_KorioNativeJs_kt__151su();
    return jsGlobal;
  }
  var jsGlobal;
  function get_isDenoJs() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isDenoJs$factory();
    tmp$ret$0 = isDenoJs$delegate.f1();
    return tmp$ret$0;
  }
  var isDenoJs$delegate;
  function get_isWeb() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isWeb$factory();
    tmp$ret$0 = isWeb$delegate.f1();
    return tmp$ret$0;
  }
  var isWeb$delegate;
  function get_isWorker() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isWorker$factory();
    tmp$ret$0 = isWorker$delegate.f1();
    return tmp$ret$0;
  }
  var isWorker$delegate;
  function get_isNodeJs() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = isNodeJs$factory();
    tmp$ret$0 = isNodeJs$delegate.f1();
    return tmp$ret$0;
  }
  var isNodeJs$delegate;
  function get_jsRuntime() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = jsRuntime$factory();
    tmp$ret$0 = jsRuntime$delegate.f1();
    return tmp$ret$0;
  }
  var jsRuntime$delegate;
  function get_isShell() {
    _init_properties_KorioNativeJs_kt__151su();
    return (!get_isWeb() ? !get_isNodeJs() : false) ? !get_isWorker() : false;
  }
  function jsObjectToMap(obj) {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$2;
    // Inline function 'kotlin.collections.associate' call
    var tmp2_associate = jsObjectKeysArray(obj);
    var capacity = coerceAtLeast(mapCapacity(tmp2_associate.length), 16);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.associateTo' call
    var tmp1_associateTo = LinkedHashMap_init_$Create$_0(capacity);
    var indexedObject = tmp2_associate;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp$ret$0;
      // Inline function 'korlibs.io.jsObjectToMap.<anonymous>' call
      tmp$ret$0 = to(element, obj[element]);
      var tmp0_plusAssign = tmp$ret$0;
      tmp1_associateTo.e5(tmp0_plusAssign.a5_1, tmp0_plusAssign.b5_1);
    }
    tmp$ret$1 = tmp1_associateTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function jsEmptyObj() {
    _init_properties_KorioNativeJs_kt__151su();
    return {};
  }
  function jsEmptyArray() {
    _init_properties_KorioNativeJs_kt__151su();
    return [];
  }
  function jsToObjectMap(obj) {
    _init_properties_KorioNativeJs_kt__151su();
    if (obj == null)
      return null;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    var out = tmp$ret$0;
    var keys = jsObjectKeys(obj);
    var inductionVariable = 0;
    var last = keys.length;
    if (inductionVariable < last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var key = keys[n];
        // Inline function 'kotlin.collections.set' call
        var tmp0_set = '' + key;
        var tmp1_set = obj[key];
        out.e5(tmp0_set, tmp1_set);
      }
       while (inductionVariable < last);
    return out;
  }
  function jsToArray(obj) {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp = 0;
    var tmp_0 = obj.length;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_0), null);
    var tmp_1 = tmp$ret$0;
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$1;
      // Inline function 'korlibs.io.jsToArray.<anonymous>' call
      tmp$ret$1 = obj[tmp_2];
      tmp_1[tmp_2] = tmp$ret$1;
      tmp = tmp + 1 | 0;
    }
    return tmp_1;
  }
  function jsObjectKeysArray(obj) {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp = jsToArray(jsObjectKeys(obj));
    return isArray(tmp) ? tmp : THROW_CCE();
  }
  function jsObjectKeys(obj) {
    _init_properties_KorioNativeJs_kt__151su();
    return Object.keys(obj);
  }
  function isDenoJs$delegate$lambda() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof Deno === 'object' && Deno.statSync;
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isWeb$delegate$lambda() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof window === 'object';
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isWorker$delegate$lambda() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof importScripts === 'function';
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function isNodeJs$delegate$lambda() {
    _init_properties_KorioNativeJs_kt__151su();
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = typeof process !== 'undefined' && process.release && process.release.name.search(/node|io.js/) !== -1;
    tmp$ret$0 = tmp0_unsafeCast;
    return tmp$ret$0;
  }
  function jsRuntime$delegate$lambda() {
    _init_properties_KorioNativeJs_kt__151su();
    return get_isDenoJs() ? JsRuntimeDeno_getInstance() : get_isNodeJs() ? JsRuntimeNode_getInstance() : JsRuntimeBrowser_getInstance();
  }
  function isDenoJs$factory() {
    return getPropertyCallableRef('isDenoJs', 0, KProperty0, function () {
      return get_isDenoJs();
    }, null);
  }
  function isWeb$factory() {
    return getPropertyCallableRef('isWeb', 0, KProperty0, function () {
      return get_isWeb();
    }, null);
  }
  function isWorker$factory() {
    return getPropertyCallableRef('isWorker', 0, KProperty0, function () {
      return get_isWorker();
    }, null);
  }
  function isNodeJs$factory() {
    return getPropertyCallableRef('isNodeJs', 0, KProperty0, function () {
      return get_isNodeJs();
    }, null);
  }
  function jsRuntime$factory() {
    return getPropertyCallableRef('jsRuntime', 0, KProperty0, function () {
      return get_jsRuntime();
    }, null);
  }
  var properties_initialized_KorioNativeJs_kt_y4p8os;
  function _init_properties_KorioNativeJs_kt__151su() {
    if (properties_initialized_KorioNativeJs_kt_y4p8os) {
    } else {
      properties_initialized_KorioNativeJs_kt_y4p8os = true;
      jsGlobalDynamic = typeof globalThis !== 'undefined' ? globalThis : typeof global !== 'undefined' ? global : self;
      jsGlobal = get_jsGlobalDynamic();
      isDenoJs$delegate = lazy(isDenoJs$delegate$lambda);
      isWeb$delegate = lazy(isWeb$delegate$lambda);
      isWorker$delegate = lazy(isWorker$delegate$lambda);
      isNodeJs$delegate = lazy(isNodeJs$delegate$lambda);
      jsRuntime$delegate = lazy(jsRuntime$delegate$lambda);
    }
  }
  function runBlockingNoJs(context, block) {
    context = context === VOID ? EmptyCoroutineContext_getInstance() : context;
    unexpected('Calling runBlockingNoJs on JavaScript');
  }
  function Deflate(windowBits) {
    return new DeflatePortable(windowBits);
  }
  function korAtomic(initial) {
    return new KorAtomicLong(initial, true);
  }
  function korAtomic_0(initial) {
    return new KorAtomicRef(initial, true);
  }
  function korAtomic_1(initial) {
    return new KorAtomicInt(initial, true);
  }
  function DynamicInternal() {
    DynamicInternal_instance = this;
  }
  protoOf(DynamicInternal).m21 = function () {
    return get_jsGlobalDynamic();
  };
  protoOf(DynamicInternal).o21 = function (instance, key) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = instance;
    return tmp$ret$0[key];
  };
  protoOf(DynamicInternal).n21 = function (instance, key, value) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = instance;
    tmp$ret$0[key] = value;
  };
  var DynamicInternal_instance;
  function DynamicInternal_getInstance() {
    if (DynamicInternal_instance == null)
      new DynamicInternal();
    return DynamicInternal_instance;
  }
  function toVfs(_this__u8e3s4) {
    var file = _this__u8e3s4;
    return (new toVfs$1(file)).p1b(file.name);
  }
  function openAsync_0(_this__u8e3s4) {
    return buffered(toAsyncStream(new BlobAsyncBaseStream(_this__u8e3s4)), 65536, 16);
  }
  function BlobAsyncBaseStream$read$lambda($reader, $len, $buffer, $offset, $deferred) {
    return function (it) {
      var tmp$ret$0;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = $reader.result;
      tmp$ret$0 = tmp0_unsafeCast;
      var ab = tmp$ret$0;
      var tmp$ret$1;
      // Inline function 'kotlin.math.min' call
      var tmp1_min = ab.byteLength;
      tmp$ret$1 = Math.min(tmp1_min, $len);
      var minLen = tmp$ret$1;
      arraycopy(toByteArray_0(new Int8Array(ab)), 0, $buffer, $offset, minLen);
      return $deferred.ku(minLen);
    };
  }
  function BlobAsyncBaseStream$read$lambda_0($deferred, $reader) {
    return function (it) {
      return $deferred.ju(newThrowable('' + $reader.error));
    };
  }
  function BlobAsyncBaseStream(blob) {
    AsyncStreamBase.call(this);
    this.q3l_1 = blob;
  }
  protoOf(BlobAsyncBaseStream).u1w = function ($completion) {
    return Unit_getInstance();
  };
  protoOf(BlobAsyncBaseStream).e2f = function (position, buffer, offset, len, $completion) {
    var deferred = CompletableDeferred();
    var reader = new FileReader();
    reader.onload = BlobAsyncBaseStream$read$lambda(reader, len, buffer, offset, deferred);
    reader.onerror = BlobAsyncBaseStream$read$lambda_0(deferred, reader);
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = this.q3l_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    reader.readAsArrayBuffer(tmp$ret$1.slice(position.wi(), position.wi() + len));
    var tmp0 = deferred.qr($completion);
    return tmp0;
  };
  protoOf(BlobAsyncBaseStream).h1y = function ($completion) {
    return numberToLong(this.q3l_1.size);
  };
  function toVfs$1($file) {
    this.s3l_1 = $file;
    Vfs.call(this);
    this.r3l_1 = $file.name;
  }
  protoOf(toVfs$1).u28 = function () {
    return this.r3l_1;
  };
  protoOf(toVfs$1).n28 = function (path, mode, $completion) {
    return openAsync_0(this.s3l_1);
  };
  protoOf(toVfs$1).r28 = function (path, $completion) {
    var tmp;
    switch (path) {
      case '/':
      case '':
        tmp = asFlow(listOf(this.p1b(this.s3l_1.name)));
        break;
      default:
        var tmp$ret$0;
        // Inline function 'kotlin.collections.listOf' call
        tmp$ret$0 = emptyList();

        tmp = asFlow(tmp$ret$0);
        break;
    }
    return tmp;
  };
  var File_separatorChar;
  function get_standardVfs() {
    _init_properties_LocalVfsJs_kt__kywvdr();
    return standardVfs;
  }
  var standardVfs;
  function get_applicationVfs() {
    _init_properties_LocalVfsJs_kt__kywvdr();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = applicationVfs$factory();
    tmp$ret$0 = applicationVfs$delegate.f1();
    return tmp$ret$0;
  }
  var applicationVfs$delegate;
  var applicationDataVfsOrNull;
  var cacheVfsOrNull;
  var externalStorageVfs$delegate;
  var userHomeVfs$delegate;
  var tempVfsOrNull;
  function localVfs(path, async) {
    async = async === VOID ? true : async;
    _init_properties_LocalVfsJs_kt__kywvdr();
    return get_jsRuntime().x3l(path);
  }
  function standardVfs$o$resourcesVfs$delegate$lambda() {
    return get_applicationVfs().z2d();
  }
  function standardVfs$o$rootLocalVfs$delegate$lambda() {
    return localVfs('.');
  }
  function standardVfs$1() {
    StandardVfs.call(this);
    var tmp = this;
    tmp.y3l_1 = lazy(standardVfs$o$resourcesVfs$delegate$lambda);
    var tmp_0 = this;
    tmp_0.z3l_1 = lazy(standardVfs$o$rootLocalVfs$delegate$lambda);
  }
  protoOf(standardVfs$1).b2j = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = resourcesVfs$factory();
    tmp$ret$0 = this.y3l_1.f1();
    return tmp$ret$0;
  };
  function applicationVfs$delegate$lambda() {
    _init_properties_LocalVfsJs_kt__kywvdr();
    return localVfs('.');
  }
  function externalStorageVfs$delegate$lambda() {
    _init_properties_LocalVfsJs_kt__kywvdr();
    return localVfs('.');
  }
  function userHomeVfs$delegate$lambda() {
    _init_properties_LocalVfsJs_kt__kywvdr();
    return localVfs('.');
  }
  function applicationVfs$factory() {
    return getPropertyCallableRef('applicationVfs', 0, KProperty0, function () {
      return get_applicationVfs();
    }, null);
  }
  function resourcesVfs$factory() {
    return getPropertyCallableRef('resourcesVfs', 1, KProperty1, function (receiver) {
      return receiver.b2j();
    }, null);
  }
  var properties_initialized_LocalVfsJs_kt_51m659;
  function _init_properties_LocalVfsJs_kt__kywvdr() {
    if (properties_initialized_LocalVfsJs_kt_51m659) {
    } else {
      properties_initialized_LocalVfsJs_kt_51m659 = true;
      standardVfs = new standardVfs$1();
      applicationVfs$delegate = lazy(applicationVfs$delegate$lambda);
      applicationDataVfsOrNull = null;
      cacheVfsOrNull = null;
      externalStorageVfs$delegate = lazy(externalStorageVfs$delegate$lambda);
      userHomeVfs$delegate = lazy(userHomeVfs$delegate$lambda);
      tempVfsOrNull = null;
    }
  }
  function StandardPaths() {
    StandardPaths_instance = this;
  }
  var StandardPaths_instance;
  function StandardPaths_getInstance() {
    if (StandardPaths_instance == null)
      new StandardPaths();
    return StandardPaths_instance;
  }
  function get_portableSimpleName(_this__u8e3s4) {
    var tmp0_elvis_lhs = _this__u8e3s4.zi();
    return tmp0_elvis_lhs == null ? 'unknown' : tmp0_elvis_lhs;
  }
  function EnvironmentInternal() {
    EnvironmentInternal_instance = this;
  }
  protoOf(EnvironmentInternal).p1b = function (key) {
    return get_jsRuntime().a3m(key);
  };
  var EnvironmentInternal_instance;
  function EnvironmentInternal_getInstance() {
    if (EnvironmentInternal_instance == null)
      new EnvironmentInternal();
    return EnvironmentInternal_instance;
  }
  function FileNotFoundException(msg) {
    IOException.call(this, msg);
    captureStack(this, FileNotFoundException);
  }
  function IOException(msg) {
    Exception_init_$Init$(msg, this);
    captureStack(this, IOException);
  }
  function EOFException(msg) {
    IOException.call(this, msg);
    captureStack(this, EOFException);
  }
  function NativeThreadLocal() {
    this.k2q_1 = this.h2r();
  }
  protoOf(NativeThreadLocal).bw = function () {
    return this.k2q_1;
  };
  function get_UTF8() {
    _init_properties_UTF8_kt__b4hq3z();
    return UTF8;
  }
  var UTF8;
  var JS_UTF8;
  function JS_UTF8$1() {
    UTC8CharsetBase.call(this, 'UTF-8');
    var tmp = this;
    var tmp_0;
    try {
      tmp_0 = new TextDecoder('utf-8');
    } catch ($p) {
      var tmp_1;
      {
        var e = $p;
        tmp_1 = null;
      }
      tmp_0 = tmp_1;
    }
    tmp.c3m_1 = tmp_0;
    var tmp_2 = this;
    var tmp_3;
    try {
      tmp_3 = new TextEncoder('utf-8');
    } catch ($p) {
      var tmp_4;
      {
        var e_0 = $p;
        tmp_4 = null;
      }
      tmp_3 = tmp_4;
    }
    tmp_2.d3m_1 = tmp_3;
  }
  protoOf(JS_UTF8$1).p2q = function (out, src, start, end) {
    if (!(this.c3m_1 == null)) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = src;
      tmp$ret$1 = tmp$ret$0;
      var srcBuffer = tmp$ret$1;
      out.bc(this.c3m_1.decode(new Int8Array(srcBuffer.buffer, start, end - start | 0)));
    } else {
      protoOf(UTC8CharsetBase).p2q.call(this, out, src, start, end);
    }
    return end - start | 0;
  };
  protoOf(JS_UTF8$1).o2q = function (out, src, start, end) {
    if (!(this.d3m_1 == null)) {
      var tmp$ret$0;
      // Inline function 'kotlin.text.substring' call
      tmp$ret$0 = toString(charSequenceSubSequence(src, start, end));
      var ba = this.d3m_1.encode(tmp$ret$0);
      var tmp$ret$2;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = new Int8Array(ba.buffer);
      var tmp$ret$1;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$1 = tmp0_unsafeCast;
      tmp$ret$2 = tmp$ret$1;
      out.a1j(tmp$ret$2);
    } else {
      protoOf(UTC8CharsetBase).o2q.call(this, out, src, start, end);
    }
  };
  var properties_initialized_UTF8_kt_emb36b;
  function _init_properties_UTF8_kt__b4hq3z() {
    if (properties_initialized_UTF8_kt_emb36b) {
    } else {
      properties_initialized_UTF8_kt_emb36b = true;
      UTF8 = new UTC8CharsetBase('UTF-8');
      JS_UTF8 = new JS_UTF8$1();
    }
  }
  function get_httpFactory() {
    _init_properties_HttpFactoryJs_kt__a3ro2v();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = httpFactory$factory();
    tmp$ret$0 = httpFactory$delegate.f1();
    return tmp$ret$0;
  }
  var httpFactory$delegate;
  function httpFactory$delegate$lambda() {
    _init_properties_HttpFactoryJs_kt__a3ro2v();
    return new httpFactory$2$1();
  }
  function httpFactory$2$1() {
  }
  protoOf(httpFactory$2$1).l2x = function () {
    return get_jsRuntime().e3m();
  };
  function httpFactory$factory() {
    return getPropertyCallableRef('httpFactory', 0, KProperty0, function () {
      return get_httpFactory();
    }, null);
  }
  var properties_initialized_HttpFactoryJs_kt_on1x9h;
  function _init_properties_HttpFactoryJs_kt__a3ro2v() {
    if (properties_initialized_HttpFactoryJs_kt_on1x9h) {
    } else {
      properties_initialized_HttpFactoryJs_kt_on1x9h = true;
      httpFactory$delegate = lazy(httpFactory$delegate$lambda);
    }
  }
  function Companion_26() {
    Companion_instance_26 = this;
    this.f3m_1 = Companion_getInstance().b1n('JsRuntime');
  }
  var Companion_instance_26;
  function Companion_getInstance_32() {
    if (Companion_instance_26 == null)
      new Companion_26();
    return Companion_instance_26;
  }
  function _get__envs__kxwcr8($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _envs$factory();
    tmp$ret$0 = $this.v3l_1.f1();
    return tmp$ret$0;
  }
  function _get__envsUC__n5sgyq($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = _envsUC$factory();
    tmp$ret$0 = $this.w3l_1.f1();
    return tmp$ret$0;
  }
  function JsRuntime$_envs$delegate$lambda(this$0) {
    return function () {
      return this$0.g3m();
    };
  }
  function JsRuntime$_envsUC$delegate$lambda(this$0) {
    return function () {
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mapKeys' call
      var tmp2_mapKeys = _get__envs__kxwcr8(this$0);
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mapKeysTo' call
      var tmp1_mapKeysTo = LinkedHashMap_init_$Create$_0(mapCapacity(tmp2_mapKeys.l()));
      var tmp$ret$4;
      // Inline function 'kotlin.collections.associateByTo' call
      var tmp0_associateByTo = tmp2_mapKeys.d1();
      var tmp0_iterator = tmp0_associateByTo.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$2;
        // Inline function 'korlibs.io.runtime.JsRuntime._envsUC$delegate.<anonymous>.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.text.uppercase' call
        var tmp0_uppercase = element.e1();
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_uppercase;
        tmp$ret$1 = tmp$ret$0.toUpperCase();
        tmp$ret$2 = tmp$ret$1;
        var tmp = tmp$ret$2;
        var tmp$ret$3;
        // Inline function 'kotlin.collections.mapKeysTo.<anonymous>' call
        tmp$ret$3 = element.f1();
        tmp1_mapKeysTo.e5(tmp, tmp$ret$3);
      }
      tmp$ret$4 = tmp1_mapKeysTo;
      tmp$ret$5 = tmp$ret$4;
      tmp$ret$6 = tmp$ret$5;
      return toMap_0(tmp$ret$6);
    };
  }
  function JsRuntime() {
    Companion_getInstance_32();
    this.t3l_1 = 'unknown';
    this.u3l_1 = get_isDenoJs() ? 'deno.js' : get_isWeb() ? 'web.js' : get_isNodeJs() ? 'node.js' : get_isWorker() ? 'worker.js' : get_isShell() ? 'shell.js' : 'js';
    var tmp = this;
    tmp.v3l_1 = lazy(JsRuntime$_envs$delegate$lambda(this));
    var tmp_0 = this;
    tmp_0.w3l_1 = lazy(JsRuntime$_envsUC$delegate$lambda(this));
  }
  protoOf(JsRuntime).i3m = function () {
    return '.';
  };
  protoOf(JsRuntime).a3m = function (key) {
    var tmp = _get__envsUC__n5sgyq(this);
    var tmp$ret$1;
    // Inline function 'kotlin.text.uppercase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = key;
    tmp$ret$1 = tmp$ret$0.toUpperCase();
    return tmp.h3(tmp$ret$1);
  };
  protoOf(JsRuntime).x3l = function (path) {
    throw new NotImplementedError();
  };
  protoOf(JsRuntime).e3m = function () {
    throw new NotImplementedError();
  };
  function _envs$factory() {
    return getPropertyCallableRef('_envs', 1, KProperty1, function (receiver) {
      return _get__envs__kxwcr8(receiver);
    }, null);
  }
  function _envsUC$factory() {
    return getPropertyCallableRef('_envsUC', 1, KProperty1, function (receiver) {
      return _get__envsUC__n5sgyq(receiver);
    }, null);
  }
  function JsRuntimeBrowser$href$delegate$lambda() {
    var tmp0_safe_receiver = document.location;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.href;
    return tmp1_elvis_lhs == null ? '.' : tmp1_elvis_lhs;
  }
  function JsRuntimeBrowser$baseUrl$delegate$lambda() {
    return endsWith(JsRuntimeBrowser_getInstance().q3m(), '/') ? JsRuntimeBrowser_getInstance().q3m() : substringBeforeLast(JsRuntimeBrowser_getInstance().q3m(), _Char___init__impl__6a9atx(47));
  }
  function JsRuntimeBrowser() {
    JsRuntimeBrowser_instance = this;
    JsRuntime.call(this);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = navigator.platform;
    tmp$ret$0 = tmp0_unsafeCast;
    tmp.n3m_1 = tmp$ret$0;
    var tmp_0 = this;
    tmp_0.o3m_1 = lazy(JsRuntimeBrowser$href$delegate$lambda);
    var tmp_1 = this;
    tmp_1.p3m_1 = lazy(JsRuntimeBrowser$baseUrl$delegate$lambda);
  }
  protoOf(JsRuntimeBrowser).q3m = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = href$factory();
    tmp$ret$0 = this.o3m_1.f1();
    return tmp$ret$0;
  };
  protoOf(JsRuntimeBrowser).r3m = function () {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = baseUrl$factory();
    tmp$ret$0 = this.p3m_1.f1();
    return tmp$ret$0;
  };
  protoOf(JsRuntimeBrowser).h3m = function (path) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError('An operation is not implemented: Not yet implemented');
  };
  protoOf(JsRuntimeBrowser).i3m = function () {
    return this.r3m();
  };
  protoOf(JsRuntimeBrowser).g3m = function () {
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp = QueryString_getInstance();
    var tmp0_safe_receiver = document.location;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.search;
    var tmp1_map = tmp.e2s(trimStart(tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs, charArrayOf([_Char___init__impl__6a9atx(63)])));
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$(tmp1_map.l());
    var tmp$ret$0;
    // Inline function 'kotlin.collections.iterator' call
    tmp$ret$0 = tmp1_map.d1().h();
    var tmp0_iterator = tmp$ret$0;
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'korlibs.io.runtime.browser.JsRuntimeBrowser.envs.<anonymous>' call
      var tmp_0 = item.e1();
      var tmp0_elvis_lhs = firstOrNull(item.f1());
      tmp$ret$1 = to(tmp_0, tmp0_elvis_lhs == null ? item.e1() : tmp0_elvis_lhs);
      tmp0_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp0_mapTo;
    tmp$ret$3 = tmp$ret$2;
    return toMap(tmp$ret$3);
  };
  protoOf(JsRuntimeBrowser).x3l = function (path) {
    var tmp$ret$2;
    // Inline function 'kotlin.also' call
    var tmp0_also = withCatalogJail(UrlVfs_1(this.i3m()).p1b(path)).w28();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.io.runtime.browser.JsRuntimeBrowser.openVfs.<anonymous>' call
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_32().f3m_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'korlibs.io.runtime.browser.JsRuntimeBrowser.openVfs.<anonymous>.<anonymous>' call
      tmp$ret$0 = 'BROWSER openVfs: currentDir=' + JsRuntimeBrowser_getInstance().i3m() + ', path=' + path + ', urlVfs=' + tmp0_also;
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    tmp$ret$2 = tmp0_also;
    return tmp$ret$2;
  };
  protoOf(JsRuntimeBrowser).e3m = function () {
    return new HttpClientBrowserJs();
  };
  var JsRuntimeBrowser_instance;
  function JsRuntimeBrowser_getInstance() {
    if (JsRuntimeBrowser_instance == null)
      new JsRuntimeBrowser();
    return JsRuntimeBrowser_instance;
  }
  function Companion_27() {
    Companion_instance_27 = this;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'kotlin.text.lowercase' call
    var tmp0_lowercase = Companion_getInstance_23().n2p_1;
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_lowercase;
    tmp$ret$1 = tmp$ret$0.toLowerCase();
    tmp.s3m_1 = setOf(['connection', tmp$ret$1]);
  }
  var Companion_instance_27;
  function Companion_getInstance_33() {
    if (Companion_instance_27 == null)
      new Companion_27();
    return Companion_instance_27;
  }
  function HttpClientBrowserJs$requestInternal$lambda($xhr, $deferred) {
    return function (e) {
      var tmp$ret$3;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      var tmp0_unsafeCast = $xhr.response;
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$0 = tmp0_unsafeCast;
      tmp$ret$1 = tmp$ret$0;
      var tmp1_unsafeCast = new Int8Array(tmp$ret$1);
      var tmp$ret$2;
      // Inline function 'kotlin.js.asDynamic' call
      tmp$ret$2 = tmp1_unsafeCast;
      tmp$ret$3 = tmp$ret$2;
      var out = tmp$ret$3;
      return $deferred.ku(new Response($xhr.status, $xhr.statusText, Headers_init_$Create$_1($xhr.getAllResponseHeaders()), openAsync(out), openAsync(out)));
    };
  }
  function HttpClientBrowserJs$requestInternal$lambda_0($deferred, $xhr, $url) {
    return function (e) {
      return $deferred.ju(RuntimeException_init_$Create$('Error status=' + $xhr.status + ",'" + $xhr.statusText + "' opening " + $url));
    };
  }
  function HttpClientBrowserJs$requestInternal$lambda_1($deferred, $xhr) {
    return function (it) {
      var tmp;
      if ($deferred.xu()) {
        $xhr.abort();
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function $requestInternalCOROUTINE$205(_this__u8e3s4, method, url, headers, content, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.b3n_1 = _this__u8e3s4;
    this.c3n_1 = method;
    this.d3n_1 = url;
    this.e3n_1 = headers;
    this.f3n_1 = content;
  }
  protoOf($requestInternalCOROUTINE$205).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.g3n_1 = CompletableDeferred(Job());
            this.h3n_1 = new XMLHttpRequest();
            this.h3n_1.open(this.c3n_1.mm(), this.d3n_1, true);
            var tmp1__get_ARRAYBUFFER__gbce7n = null;
            var tmp0_unsafeCast = 'arraybuffer';
            this.h3n_1.responseType = tmp0_unsafeCast;
            this.h3n_1.onload = HttpClientBrowserJs$requestInternal$lambda(this.h3n_1, this.g3n_1);
            this.h3n_1.onerror = HttpClientBrowserJs$requestInternal$lambda_0(this.g3n_1, this.h3n_1, this.d3n_1);
            var tmp0_iterator = this.e3n_1.h();
            while (tmp0_iterator.j()) {
              var header = tmp0_iterator.k();
              var tmp2_lowercase = header.a5_1;
              var tmp3_trim = tmp2_lowercase.toLowerCase();
              var hnname = toString(trim_0(isCharSequence(tmp3_trim) ? tmp3_trim : THROW_CCE()));
              if (!Companion_getInstance_33().s3m_1.q(hnname)) {
                this.h3n_1.setRequestHeader(header.a5_1, header.b5_1);
              }
            }

            this.g3n_1.vp(HttpClientBrowserJs$requestInternal$lambda_1(this.g3n_1, this.h3n_1));
            ;
            if (!(this.f3n_1 == null)) {
              this.y7_1 = 1;
              suspendResult = readAll(this.f3n_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.h3n_1.send();
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.i3n_1 = suspendResult;
            this.h3n_1.send(this.i3n_1);
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.j3n_1 = this.f3n_1;
            if (this.j3n_1 == null) {
              this.k3n_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              this.y7_1 = 3;
              suspendResult = this.j3n_1.u1w(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 3:
            var tmp_0 = this;
            tmp_0.k3n_1 = Unit_getInstance();
            this.y7_1 = 4;
            continue $sm;
          case 4:
            ;
            this.y7_1 = 5;
            suspendResult = this.g3n_1.qr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            return suspendResult;
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function HttpClientBrowserJs() {
    Companion_getInstance_33();
    HttpClient.call(this);
  }
  protoOf(HttpClientBrowserJs).g2w = function (method, url, headers, content, $completion) {
    var tmp = new $requestInternalCOROUTINE$205(this, method, url, headers, content, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function href$factory() {
    return getPropertyCallableRef('href', 1, KProperty1, function (receiver) {
      return receiver.q3m();
    }, null);
  }
  function baseUrl$factory() {
    return getPropertyCallableRef('baseUrl', 1, KProperty1, function (receiver) {
      return receiver.r3m();
    }, null);
  }
  function JsRuntimeDeno() {
    JsRuntimeDeno_instance = this;
    JsRuntime.call(this);
  }
  protoOf(JsRuntimeDeno).h3m = function (path) {
    var tmp;
    try {
      Deno.statSync(path);
      tmp = true;
    } catch ($p) {
      var tmp_0;
      {
        var e = $p;
        tmp_0 = false;
      }
      tmp = tmp_0;
    }
    return tmp;
  };
  protoOf(JsRuntimeDeno).i3m = function () {
    return Deno.cwd();
  };
  protoOf(JsRuntimeDeno).a3m = function (key) {
    return Deno.env.get(key);
  };
  protoOf(JsRuntimeDeno).g3m = function () {
    return jsObjectToMap(Deno.env.toObject());
  };
  var JsRuntimeDeno_instance;
  function JsRuntimeDeno_getInstance() {
    if (JsRuntimeDeno_instance == null)
      new JsRuntimeDeno();
    return JsRuntimeDeno_instance;
  }
  function get_nodeFS() {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = nodeFS$factory();
    tmp$ret$0 = nodeFS$delegate.f1();
    return tmp$ret$0;
  }
  var nodeFS$delegate;
  function _get_fs__ndc19a($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = fs$factory();
    tmp$ret$0 = $this.v3n_1.f1();
    return tmp$ret$0;
  }
  function _get_path__dbvv7q($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = path$factory();
    tmp$ret$0 = $this.w3n_1.f1();
    return tmp$ret$0;
  }
  function JsRuntimeNode$fs$delegate$lambda() {
    return require_node('fs');
  }
  function JsRuntimeNode$path$delegate$lambda() {
    return require_node('path');
  }
  function JsRuntimeNode() {
    JsRuntimeNode_instance = this;
    JsRuntime.call(this);
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = process.platform;
    tmp$ret$0 = tmp0_unsafeCast;
    tmp.u3n_1 = tmp$ret$0;
    var tmp_0 = this;
    tmp_0.v3n_1 = lazy(JsRuntimeNode$fs$delegate$lambda);
    var tmp_1 = this;
    tmp_1.w3n_1 = lazy(JsRuntimeNode$path$delegate$lambda);
  }
  protoOf(JsRuntimeNode).h3m = function (path) {
    return _get_fs__ndc19a(this).existsSync(path);
  };
  protoOf(JsRuntimeNode).i3m = function () {
    return _get_path__dbvv7q(this).resolve('.');
  };
  protoOf(JsRuntimeNode).a3m = function (key) {
    return process.env[key];
  };
  protoOf(JsRuntimeNode).g3m = function () {
    return jsObjectToMap(process.env);
  };
  protoOf(JsRuntimeNode).x3l = function (path) {
    var tmp;
    if (path === '.') {
      var path_0 = get_jsRuntime().i3m();
      tmp = ((get_jsRuntime().h3m(path_0 + '/node_modules') ? get_jsRuntime().h3m(path_0 + '/kotlin') : false) ? get_jsRuntime().h3m(path_0 + '/package.json') : false) ? path_0 + '/kotlin' : path_0;
    } else {
      tmp = path;
    }
    var rpath = tmp;
    return (new NodeJsLocalVfs()).p1b(rpath);
  };
  protoOf(JsRuntimeNode).e3m = function () {
    return new HttpClientNodeJs();
  };
  var JsRuntimeNode_instance;
  function JsRuntimeNode_getInstance() {
    if (JsRuntimeNode_instance == null)
      new JsRuntimeNode();
    return JsRuntimeNode_instance;
  }
  function require_node(name) {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    return eval('(' + get_REQ() + "uire('" + name + "'))");
  }
  function getFullPath($this, path) {
    return normalize(get_pathInfo(path));
  }
  function NodeJsLocalVfs$listFlow$lambda($deferred) {
    return function (err, items) {
      var tmp;
      if (!(err == null)) {
        $deferred.ju(err);
        tmp = Unit_getInstance();
      } else {
        $deferred.ku(items);
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function NodeJsLocalVfs$stat$lambda($deferred, this$0, $path) {
    return function (err, stats) {
      var tmp$ret$3;
      // Inline function 'kotlin.runCatching' call
      var tmp;
      try {
        var tmp$ret$1;
        // Inline function 'kotlin.Companion.success' call
        var tmp0_success = Companion_getInstance_1();
        var tmp$ret$0;
        // Inline function 'korlibs.io.runtime.node.NodeJsLocalVfs.stat.<anonymous>.<anonymous>' call
        tmp$ret$0 = this$0.x3n(stats, $path);
        var tmp1_success = tmp$ret$0;
        tmp$ret$1 = _Result___init__impl__xyqfz8(tmp1_success);
        tmp = tmp$ret$1;
      } catch ($p) {
        var tmp_0;
        if ($p instanceof Error) {
          var e = $p;
          var tmp$ret$2;
          // Inline function 'kotlin.Companion.failure' call
          var tmp2_failure = Companion_getInstance_1();
          tmp$ret$2 = _Result___init__impl__xyqfz8(createFailure(e));
          tmp_0 = tmp$ret$2;
        } else {
          throw $p;
        }
        tmp = tmp_0;
      }
      tmp$ret$3 = tmp;
      completeWith($deferred, tmp$ret$3);
      return Unit_getInstance();
    };
  }
  function NodeJsLocalVfs$mkdir$lambda($cancellable) {
    return function (err) {
      var tmp$ret$1;
      // Inline function 'kotlin.coroutines.resume' call
      var tmp1_resume = err == null;
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.success' call
      var tmp0_success = Companion_getInstance_1();
      tmp$ret$0 = _Result___init__impl__xyqfz8(tmp1_resume);
      $cancellable.q5(tmp$ret$0);
      tmp$ret$1 = Unit_getInstance();
      return Unit_getInstance();
    };
  }
  function NodeJsLocalVfs$mkdir$lambda_0(it) {
    return Unit_getInstance();
  }
  function NodeJsLocalVfs$delete$lambda($cancellable) {
    return function (err) {
      var tmp$ret$1;
      // Inline function 'kotlin.coroutines.resume' call
      var tmp1_resume = err == null;
      var tmp$ret$0;
      // Inline function 'kotlin.Companion.success' call
      var tmp0_success = Companion_getInstance_1();
      tmp$ret$0 = _Result___init__impl__xyqfz8(tmp1_resume);
      $cancellable.q5(tmp$ret$0);
      tmp$ret$1 = Unit_getInstance();
      return Unit_getInstance();
    };
  }
  function NodeJsLocalVfs$delete$lambda_0(it) {
    return Unit_getInstance();
  }
  function NodeJsLocalVfs$_open$lambda($path, $cmode, $cancellable, $file) {
    return function (err, fd) {
      var tmp;
      if (!(err == null) ? true : fd == null) {
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        var tmp1_resumeWithException = new FileNotFoundException("Can't open '" + $path + "' with mode '" + $cmode + "': err=" + err);
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.failure' call
        var tmp0_failure = Companion_getInstance_1();
        tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_resumeWithException));
        $cancellable.q5(tmp$ret$0);
        tmp$ret$1 = Unit_getInstance();
        tmp = tmp$ret$1;
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp3_resume = toAsyncStream(new NodeFDStream($file, fd));
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.success' call
        var tmp2_success = Companion_getInstance_1();
        tmp$ret$2 = _Result___init__impl__xyqfz8(tmp3_resume);
        $cancellable.q5(tmp$ret$2);
        tmp$ret$3 = Unit_getInstance();
        tmp = tmp$ret$3;
      }
      return Unit_getInstance();
    };
  }
  function NodeJsLocalVfs$_open$lambda_0(it) {
    return Unit_getInstance();
  }
  function $listFlowCOROUTINE$207(_this__u8e3s4, path, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.g3o_1 = _this__u8e3s4;
    this.h3o_1 = path;
  }
  protoOf($listFlowCOROUTINE$207).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.i3o_1 = CompletableDeferred();
            var tmp_0 = get_nodeFS();
            tmp_0.readdir(this.h3o_1, NodeJsLocalVfs$listFlow$lambda(this.i3o_1));
            this.y7_1 = 1;
            suspendResult = this.i3o_1.qr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp1_map = suspendResult;
            var tmp0_mapTo = ArrayList_init_$Create$(tmp1_map.length);
            var indexedObject = tmp1_map;
            var inductionVariable = 0;
            var last = indexedObject.length;
            while (inductionVariable < last) {
              var item = indexedObject[inductionVariable];
              inductionVariable = inductionVariable + 1 | 0;
              tmp0_mapTo.b(this.g3o_1.u26(this.h3o_1 + '/' + item));
            }

            var files = tmp0_mapTo;
            return flowOf(copyToArray(files).slice());
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $openCOROUTINE$209(_this__u8e3s4, path, mode, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.r3o_1 = _this__u8e3s4;
    this.s3o_1 = path;
    this.t3o_1 = mode;
  }
  protoOf($openCOROUTINE$209).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 5;
            this.u3o_1 = this.t3o_1;
            this.v3o_1 = this.u3o_1.b2_1;
            if (this.v3o_1 === 0) {
              this.w3o_1 = 'r';
              this.y7_1 = 3;
              continue $sm;
            } else {
              if (this.v3o_1 === 1) {
                this.w3o_1 = 'r+';
                this.y7_1 = 3;
                continue $sm;
              } else {
                if (this.v3o_1 === 3) {
                  this.w3o_1 = 'w+';
                  this.y7_1 = 3;
                  continue $sm;
                } else {
                  if (this.v3o_1 === 5) {
                    this.y7_1 = 1;
                    suspendResult = this.r3o_1.q28(this.s3o_1, this);
                    if (suspendResult === get_COROUTINE_SUSPENDED()) {
                      return suspendResult;
                    }
                    continue $sm;
                  } else {
                    if (this.v3o_1 === 4) {
                      this.w3o_1 = 'wx+';
                      this.y7_1 = 3;
                      continue $sm;
                    } else {
                      if (this.v3o_1 === 2) {
                        this.w3o_1 = 'a';
                        this.y7_1 = 3;
                        continue $sm;
                      } else {
                        var tmp_0 = this;
                        noWhenBranchMatchedException();
                      }
                    }
                  }
                }
              }
            }

            break;
          case 1:
            this.x3o_1 = suspendResult;
            if (this.x3o_1.w26_1) {
              throw new FileAlreadyExistsException(this.s3o_1);
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 2:
            this.w3o_1 = 'w+';
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.y3o_1 = this.w3o_1;
            this.y7_1 = 4;
            suspendResult = this.r3o_1.z3o(this.s3o_1, this.y3o_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 4:
            return suspendResult;
          case 5:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 5) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function NodeJsLocalVfs() {
    LocalVfs.call(this);
  }
  protoOf(NodeJsLocalVfs).x3n = function (_this__u8e3s4, path) {
    var tmp0_elvis_lhs = _this__u8e3s4;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return this.a29(path);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var stats = tmp;
    return this.y28(path, stats.isDirectory(), numberToLong(stats.size), numberToLong(stats.dev), numberToLong(stats.ino), numberToInt(stats.mode), VOID, VOID, Companion_getInstance_2().q17(stats.ctimeMs), Companion_getInstance_2().q17(stats.mtimeMs), Companion_getInstance_2().q17(stats.atimeMs));
  };
  protoOf(NodeJsLocalVfs).r28 = function (path, $completion) {
    var tmp = new $listFlowCOROUTINE$207(this, path, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(NodeJsLocalVfs).q28 = function (path, $completion) {
    var deferred = CompletableDeferred();
    var tmp = get_nodeFS();
    tmp.stat(path, NodeJsLocalVfs$stat$lambda(deferred, this, path));
    var tmp0 = deferred.qr($completion);
    return tmp0;
  };
  protoOf(NodeJsLocalVfs).t28 = function (path, attributes, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeJsLocalVfs.mkdir.<anonymous>' call
    var tmp = get_nodeFS();
    var tmp_0 = getFullPath(this, path);
    var tmp_1 = toInt('777', 8);
    tmp.mkdir(tmp_0, tmp_1, NodeJsLocalVfs$mkdir$lambda(cancellable));
    cancellable.bs(NodeJsLocalVfs$mkdir$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeJsLocalVfs).s28 = function (path, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeJsLocalVfs.delete.<anonymous>' call
    var tmp = get_nodeFS();
    var tmp_0 = getFullPath(this, path);
    tmp.unlink(tmp_0, NodeJsLocalVfs$delete$lambda(cancellable));
    cancellable.bs(NodeJsLocalVfs$delete$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeJsLocalVfs).n28 = function (path, mode, $completion) {
    var tmp = new $openCOROUTINE$209(this, path, mode, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(NodeJsLocalVfs).z3o = function (path, cmode, $completion) {
    var file = this.u26(path);
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeJsLocalVfs._open.<anonymous>' call
    var tmp = get_nodeFS();
    var tmp_0 = getFullPath(this, path);
    tmp.open(tmp_0, cmode, NodeJsLocalVfs$_open$lambda(path, cmode, cancellable, file));
    cancellable.bs(NodeJsLocalVfs$_open$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeJsLocalVfs).toString = function () {
    return 'NodeJsLocalVfs';
  };
  function HttpClientNodeJs$requestInternal$lambda$lambda($body) {
    return function (d) {
      return $body.push(d);
    };
  }
  function HttpClientNodeJs$requestInternal$lambda$lambda_0($body, $statusCode, $statusMessage, $jsHeadersObj, $deferred) {
    return function () {
      var tmp$ret$0;
      // Inline function 'kotlin.js.asDynamic' call
      var tmp0_asDynamic = get_jsGlobal();
      tmp$ret$0 = tmp0_asDynamic;
      var r = tmp$ret$0.Buffer.concat($body);
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      tmp$ret$1 = r;
      var u8array = new Int8Array(tmp$ret$1);
      var out = new Int8Array(u8array.length);
      var inductionVariable = 0;
      var last = u8array.length;
      var tmp;
      if (inductionVariable < last) {
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$3;
          // Inline function 'org.khronos.webgl.get' call
          var tmp$ret$2;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$2 = u8array;
          tmp$ret$3 = tmp$ret$2[n];
          out[n] = tmp$ret$3;
        }
         while (inductionVariable < last);
        tmp = Unit_getInstance();
      }
      var tmp$ret$8;
      // Inline function 'kotlin.collections.mapValues' call
      var tmp1_elvis_lhs = jsToObjectMap($jsHeadersObj);
      var tmp3_mapValues = tmp1_elvis_lhs == null ? LinkedHashMap_init_$Create$() : tmp1_elvis_lhs;
      var tmp$ret$7;
      // Inline function 'kotlin.collections.mapValuesTo' call
      var tmp2_mapValuesTo = LinkedHashMap_init_$Create$_0(mapCapacity(tmp3_mapValues.l()));
      var tmp$ret$6;
      // Inline function 'kotlin.collections.associateByTo' call
      var tmp1_associateByTo = tmp3_mapValues.d1();
      var tmp0_iterator = tmp1_associateByTo.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$4;
        // Inline function 'kotlin.collections.mapValuesTo.<anonymous>' call
        tmp$ret$4 = element.e1();
        var tmp_0 = tmp$ret$4;
        var tmp$ret$5;
        // Inline function 'korlibs.io.runtime.node.HttpClientNodeJs.requestInternal.<anonymous>.<anonymous>.<anonymous>' call
        tmp$ret$5 = toString_0(element.f1());
        tmp2_mapValuesTo.e5(tmp_0, tmp$ret$5);
      }
      tmp$ret$6 = tmp2_mapValuesTo;
      tmp$ret$7 = tmp$ret$6;
      tmp$ret$8 = tmp$ret$7;
      var response = new Response($statusCode, $statusMessage, Headers_init_$Create$_0(tmp$ret$8), openAsync(out), openAsync(out));
      return $deferred.ku(response);
    };
  }
  function HttpClientNodeJs$requestInternal$lambda($deferred) {
    return function (res) {
      var statusCode = res.statusCode;
      var tmp0_elvis_lhs = res.statusMessage;
      var statusMessage = tmp0_elvis_lhs == null ? '' : tmp0_elvis_lhs;
      var jsHeadersObj = res.headers;
      var body = jsEmptyArray();
      res.on('data', HttpClientNodeJs$requestInternal$lambda$lambda(body));
      return res.on('end', HttpClientNodeJs$requestInternal$lambda$lambda_0(body, statusCode, statusMessage, jsHeadersObj, $deferred));
    };
  }
  function HttpClientNodeJs$requestInternal$lambda_0($deferred) {
    return function (e) {
      return $deferred.ju(RuntimeException_init_$Create$('Error: ' + e));
    };
  }
  function HttpClientNodeJs$requestInternal$lambda_1($deferred, $r) {
    return function (it) {
      var tmp;
      if ($deferred.xu()) {
        tmp = $r.abort();
      }
      return Unit_getInstance();
    };
  }
  function $requestInternalCOROUTINE$214(_this__u8e3s4, method, url, headers, content, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.i3p_1 = _this__u8e3s4;
    this.j3p_1 = method;
    this.k3p_1 = url;
    this.l3p_1 = headers;
    this.m3p_1 = content;
  }
  protoOf($requestInternalCOROUTINE$214).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 6;
            this.n3p_1 = CompletableDeferred(Job());
            this.o3p_1 = require_node('http');
            this.p3p_1 = require_node('url');
            this.q3p_1 = this.p3p_1.parse(this.k3p_1);
            this.r3p_1 = jsEmptyObj();
            var tmp0_iterator = this.l3p_1.h();
            while (tmp0_iterator.j()) {
              var header = tmp0_iterator.k();
              this.r3p_1[header.a5_1] = header.b5_1;
            }

            this.s3p_1 = jsEmptyObj();
            this.s3p_1.method = this.j3p_1.mm();
            this.s3p_1.host = this.q3p_1['hostname'];
            this.s3p_1.port = this.q3p_1['port'];
            this.s3p_1.path = this.q3p_1['path'];
            this.s3p_1.agent = false;
            this.s3p_1.encoding = null;
            this.s3p_1.headers = this.r3p_1;
            var tmp_0 = this;
            tmp_0.t3p_1 = this.o3p_1.request(this.s3p_1, HttpClientNodeJs$requestInternal$lambda(this.n3p_1)).on('error', HttpClientNodeJs$requestInternal$lambda_0(this.n3p_1));
            this.n3p_1.vp(HttpClientNodeJs$requestInternal$lambda_1(this.n3p_1, this.t3p_1));
            ;
            if (!(this.m3p_1 == null)) {
              this.y7_1 = 1;
              suspendResult = readAll(this.m3p_1, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.t3p_1.end();
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.u3p_1 = suspendResult;
            this.v3p_1 = toTypedArray(this.u3p_1);
            this.t3p_1.end(this.v3p_1);
            this.y7_1 = 2;
            continue $sm;
          case 2:
            this.w3p_1 = this.m3p_1;
            if (this.w3p_1 == null) {
              this.x3p_1 = null;
              this.y7_1 = 4;
              continue $sm;
            } else {
              this.y7_1 = 3;
              suspendResult = this.w3p_1.u1w(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            }

            break;
          case 3:
            var tmp_1 = this;
            tmp_1.x3p_1 = Unit_getInstance();
            this.y7_1 = 4;
            continue $sm;
          case 4:
            ;
            this.y7_1 = 5;
            suspendResult = this.n3p_1.qr(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            return suspendResult;
          case 6:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 6) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function HttpClientNodeJs() {
    HttpClient.call(this);
  }
  protoOf(HttpClientNodeJs).g2w = function (method, url, headers, content, $completion) {
    var tmp = new $requestInternalCOROUTINE$214(this, method, url, headers, content, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function get_REQ() {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    return 'req';
  }
  function checkFd($this) {
    if ($this.a3q_1 == null) {
      // Inline function 'kotlin.error' call
      var tmp0_error = 'File ' + $this.z3p_1 + ' already closed';
      throw IllegalStateException_init_$Create$(toString(tmp0_error));
    }
  }
  function NodeFDStream$read$lambda(this$0, $cancellable) {
    return function (err, bytesRead, buf) {
      var tmp;
      if (!(err == null)) {
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        var tmp1_resumeWithException = new IOException('Error reading from ' + this$0.z3p_1 + ' :: err=' + err);
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.failure' call
        var tmp0_failure = Companion_getInstance_1();
        tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_resumeWithException));
        $cancellable.q5(tmp$ret$0);
        tmp$ret$1 = Unit_getInstance();
        tmp = tmp$ret$1;
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.success' call
        var tmp2_success = Companion_getInstance_1();
        tmp$ret$2 = _Result___init__impl__xyqfz8(bytesRead);
        $cancellable.q5(tmp$ret$2);
        tmp$ret$3 = Unit_getInstance();
        tmp = tmp$ret$3;
      }
      return Unit_getInstance();
    };
  }
  function NodeFDStream$read$lambda_0(it) {
    return Unit_getInstance();
  }
  function NodeFDStream$write$lambda(this$0, $cancellable) {
    return function (err, bytesWritten, buffer) {
      var tmp;
      if (!(err == null)) {
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        var tmp1_resumeWithException = new IOException('Error writting to ' + this$0.z3p_1 + ' :: err=' + err);
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.failure' call
        var tmp0_failure = Companion_getInstance_1();
        tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_resumeWithException));
        $cancellable.q5(tmp$ret$0);
        tmp$ret$1 = Unit_getInstance();
        tmp = tmp$ret$1;
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.success' call
        var tmp2_success = Companion_getInstance_1();
        tmp$ret$2 = _Result___init__impl__xyqfz8(Unit_getInstance());
        $cancellable.q5(tmp$ret$2);
        tmp$ret$3 = Unit_getInstance();
        tmp = tmp$ret$3;
      }
      return Unit_getInstance();
    };
  }
  function NodeFDStream$write$lambda_0(it) {
    return Unit_getInstance();
  }
  function NodeFDStream$getLength$lambda(this$0, $cancellable) {
    return function (err, stats) {
      var tmp;
      if (!(err == null)) {
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.resumeWithException' call
        var tmp1_resumeWithException = new IOException('Error getting length from ' + this$0.z3p_1 + ' :: err=' + err);
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.failure' call
        var tmp0_failure = Companion_getInstance_1();
        tmp$ret$0 = _Result___init__impl__xyqfz8(createFailure(tmp1_resumeWithException));
        $cancellable.q5(tmp$ret$0);
        tmp$ret$1 = Unit_getInstance();
        tmp = tmp$ret$1;
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp3_resume = numberToLong(stats.size);
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.success' call
        var tmp2_success = Companion_getInstance_1();
        tmp$ret$2 = _Result___init__impl__xyqfz8(tmp3_resume);
        $cancellable.q5(tmp$ret$2);
        tmp$ret$3 = Unit_getInstance();
        tmp = tmp$ret$3;
      }
      return Unit_getInstance();
    };
  }
  function NodeFDStream$getLength$lambda_0(it) {
    return Unit_getInstance();
  }
  function NodeFDStream$close$lambda(this$0, $cancellable) {
    return function (err) {
      this$0.a3q_1 = null;
      var tmp;
      if (!(err == null)) {
        var tmp$ret$1;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp$ret$0;
        // Inline function 'kotlin.Companion.success' call
        var tmp0_success = Companion_getInstance_1();
        tmp$ret$0 = _Result___init__impl__xyqfz8(Unit_getInstance());
        $cancellable.q5(tmp$ret$0);
        tmp$ret$1 = Unit_getInstance();
        tmp = tmp$ret$1;
      } else {
        var tmp$ret$3;
        // Inline function 'kotlin.coroutines.resume' call
        var tmp$ret$2;
        // Inline function 'kotlin.Companion.success' call
        var tmp1_success = Companion_getInstance_1();
        tmp$ret$2 = _Result___init__impl__xyqfz8(Unit_getInstance());
        $cancellable.q5(tmp$ret$2);
        tmp$ret$3 = Unit_getInstance();
        tmp = tmp$ret$3;
      }
      return Unit_getInstance();
    };
  }
  function NodeFDStream$close$lambda_0(it) {
    return Unit_getInstance();
  }
  function $closeCOROUTINE$217(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.j3q_1 = _this__u8e3s4;
  }
  protoOf($closeCOROUTINE$217).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            if (!(this.j3q_1.a3q_1 == null)) {
              this.y7_1 = 2;
              var tmp0__anonymous__q1qw7t = this;
              var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
              cancellable.tt();
              var tmp_0 = get_nodeFS();
              var tmp_1 = this.j3q_1.a3q_1;
              tmp_0.close(tmp_1, NodeFDStream$close$lambda(this.j3q_1, cancellable));
              cancellable.bs(NodeFDStream$close$lambda_0);
              suspendResult = returnIfSuspended(cancellable.vq(), this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 1;
              continue $sm;
            }

            break;
          case 1:
            return Unit_getInstance();
          case 2:
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function NodeFDStream(file, fd) {
    AsyncStreamBase.call(this);
    this.z3p_1 = file;
    this.a3q_1 = fd;
  }
  protoOf(NodeFDStream).e2f = function (position, buffer, offset, len, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeFDStream.read.<anonymous>' call
    checkFd(this);
    var tmp = get_nodeFS();
    var tmp_0 = this.a3q_1;
    var tmp_1 = toNodeJsBuffer(buffer);
    var tmp_2 = position.wi();
    tmp.read(tmp_0, tmp_1, offset, len, tmp_2, NodeFDStream$read$lambda(this, cancellable));
    cancellable.bs(NodeFDStream$read$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeFDStream).f2f = function (position, buffer, offset, len, $completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeFDStream.write.<anonymous>' call
    checkFd(this);
    var tmp = get_nodeFS();
    var tmp_0 = this.a3q_1;
    var tmp_1 = toNodeJsBuffer(buffer);
    var tmp_2 = position.wi();
    tmp.write(tmp_0, tmp_1, offset, len, tmp_2, NodeFDStream$write$lambda(this, cancellable));
    cancellable.bs(NodeFDStream$write$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeFDStream).h1y = function ($completion) {
    var tmp$ret$0;
    // Inline function 'kotlinx.coroutines.suspendCancellableCoroutine.<anonymous>' call
    var tmp0__anonymous__q1qw7t = $completion;
    var cancellable = new CancellableContinuationImpl(intercepted(tmp0__anonymous__q1qw7t), get_MODE_CANCELLABLE());
    cancellable.tt();
    // Inline function 'korlibs.io.runtime.node.NodeFDStream.getLength.<anonymous>' call
    checkFd(this);
    var tmp = get_nodeFS();
    var tmp_0 = this.a3q_1;
    tmp.fstat(tmp_0, NodeFDStream$getLength$lambda(this, cancellable));
    cancellable.bs(NodeFDStream$getLength$lambda_0);
    tmp$ret$0 = cancellable.vq();
    var tmp0 = tmp$ret$0;
    return tmp0;
  };
  protoOf(NodeFDStream).u1w = function ($completion) {
    var tmp = new $closeCOROUTINE$217(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function toNodeJsBuffer(_this__u8e3s4) {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp0_unsafeCast = asUint8Array(_this__u8e3s4);
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_unsafeCast;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function asUint8Array(_this__u8e3s4) {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    var i = asInt8Array(_this__u8e3s4);
    return new Uint8Array(i.buffer, i.byteOffset, i.length);
  }
  function asInt8Array(_this__u8e3s4) {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    var tmp$ret$1;
    // Inline function 'kotlin.js.unsafeCast' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    tmp$ret$1 = tmp$ret$0;
    return tmp$ret$1;
  }
  function nodeFS$delegate$lambda() {
    _init_properties_JsRuntimeNode_kt__v0s2kf();
    return require_node('fs');
  }
  function nodeFS$factory() {
    return getPropertyCallableRef('nodeFS', 0, KProperty0, function () {
      return get_nodeFS();
    }, null);
  }
  function fs$factory() {
    return getPropertyCallableRef('fs', 1, KProperty1, function (receiver) {
      return _get_fs__ndc19a(receiver);
    }, null);
  }
  function path$factory() {
    return getPropertyCallableRef('path', 1, KProperty1, function (receiver) {
      return _get_path__dbvv7q(receiver);
    }, null);
  }
  var properties_initialized_JsRuntimeNode_kt_f9ymrl;
  function _init_properties_JsRuntimeNode_kt__v0s2kf() {
    if (properties_initialized_JsRuntimeNode_kt_f9ymrl) {
    } else {
      properties_initialized_JsRuntimeNode_kt_f9ymrl = true;
      nodeFS$delegate = lazy(nodeFS$delegate$lambda);
    }
  }
  function toByteArray_0(_this__u8e3s4) {
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = _this__u8e3s4;
    var tout = tmp$ret$0;
    var tmp;
    if (!(tout == null) ? isByteArray(tout) : false) {
      var tmp$ret$1;
      // Inline function 'kotlin.js.unsafeCast' call
      tmp$ret$1 = tout;
      tmp = tmp$ret$1;
    } else {
      var out = new Int8Array(_this__u8e3s4.length);
      var inductionVariable = 0;
      var last = out.length - 1 | 0;
      if (inductionVariable <= last)
        do {
          var n = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          var tmp$ret$3;
          // Inline function 'org.khronos.webgl.get' call
          var tmp$ret$2;
          // Inline function 'kotlin.js.asDynamic' call
          tmp$ret$2 = _this__u8e3s4;
          tmp$ret$3 = tmp$ret$2[n];
          out[n] = tmp$ret$3;
        }
         while (inductionVariable <= last);
      tmp = out;
    }
    return tmp;
  }
  //region block: post-declaration
  protoOf(runBlockingNoSuspensions$1$context$1).o8 = get;
  protoOf(runBlockingNoSuspensions$1$context$1).u8 = fold;
  protoOf(runBlockingNoSuspensions$1$context$1).t8 = minusKey;
  protoOf(runBlockingNoSuspensions$1$context$1).v8 = plus;
  protoOf(runBlockingNoSuspensions$1$context$1).n8 = releaseInterceptedContinuation;
  protoOf(runBlockingNoSuspensions$1$context$1).lv = invokeOnTimeout;
  protoOf(VfsCachedStatContext).o8 = get_0;
  protoOf(VfsCachedStatContext).u8 = fold;
  protoOf(VfsCachedStatContext).t8 = minusKey_0;
  protoOf(VfsCachedStatContext).v8 = plus;
  protoOf(AsyncStreamBase).g1y = hasLength;
  protoOf(CatalogVfs$open$2).g1y = hasLength;
  protoOf(AsyncStream).g1y = hasLength;
  protoOf(BufferedStreamBase).g1y = hasLength;
  protoOf(MemoryAsyncStreamBase).g1y = hasLength;
  protoOf(SliceAsyncStreamBase).g1y = hasLength;
  protoOf(toAsyncInputStream$1).g1y = hasLength;
  protoOf(BlobAsyncBaseStream).g1y = hasLength;
  protoOf(StandardPaths).f2k = appPreferencesFolder;
  protoOf(NodeFDStream).g1y = hasLength;
  //endregion
  //region block: init
  resourcesVfsDebug = false;
  customEnvironments = null;
  MINUS_ZERO_D = -0.0;
  File_separatorChar = _Char___init__impl__6a9atx(47);
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = delay_0;
  _.$_$.b = launchUnscopedAndWait;
  _.$_$.c = withTimeout_0;
  _.$_$.d = MountableVfs;
  _.$_$.e = readAll;
  _.$_$.f = readBytesExact;
  _.$_$.g = readS32;
  _.$_$.h = readStringz;
  _.$_$.i = readString;
  _.$_$.j = readU16;
  _.$_$.k = sliceHere;
  _.$_$.l = sliceStart;
  _.$_$.m = Dyn__get_impl_6l2p80_0;
  _.$_$.n = _Dyn___get_int__impl__sh8lhm;
  _.$_$.o = Dyn__set_impl_2ozvx8;
  _.$_$.p = Dyn__toFloatDefault_impl_tuvm0c;
  _.$_$.q = Dyn__toList_impl_pxssgr;
  _.$_$.r = Dyn__toStringOrNull_impl_7e6cgy;
  _.$_$.s = Dyn__toString_impl_9l0v3s;
  _.$_$.t = _WChar___get_codePoint__impl__c8brwu;
  _.$_$.u = Companion_getInstance_7;
  _.$_$.v = Companion_getInstance_10;
  _.$_$.w = StandardPaths_getInstance;
  _.$_$.x = ASCII_getInstance;
  _.$_$.y = Charsets_getInstance;
  _.$_$.z = Companion_getInstance_16;
  _.$_$.a1 = Companion_getInstance_17;
  _.$_$.b1 = Companion_getInstance_18;
  _.$_$.c1 = Json_getInstance;
  _.$_$.d1 = Companion_getInstance_29;
  _.$_$.e1 = AsyncSignal;
  _.$_$.f1 = Signal;
  _.$_$.g1 = invoke;
  _.$_$.h1 = launchImmediately;
  _.$_$.i1 = launchImmediately_0;
  _.$_$.j1 = launchUnscoped;
  _.$_$.k1 = launchUnscoped_0;
  _.$_$.l1 = launch_1;
  _.$_$.m1 = launch_0;
  _.$_$.n1 = runBlockingNoJs;
  _.$_$.o1 = runBlockingNoSuspensions;
  _.$_$.p1 = uncompress_1;
  _.$_$.q1 = KorAtomicRef_0;
  _.$_$.r1 = incrementAndGet;
  _.$_$.s1 = korAtomic_1;
  _.$_$.t1 = korAtomic;
  _.$_$.u1 = get_dyn;
  _.$_$.v1 = LocalVfs;
  _.$_$.w1 = Mountable;
  _.$_$.x1 = UrlVfs_0;
  _.$_$.y1 = get_localCurrentDirVfs;
  _.$_$.z1 = get_resourcesVfs;
  _.$_$.a2 = get_baseName;
  _.$_$.b2 = toVfs;
  _.$_$.c2 = CancellableGroup;
  _.$_$.d2 = cancel;
  _.$_$.e2 = CloseableCancellable;
  _.$_$.f2 = Closeable;
  _.$_$.g2 = FileNotFoundException;
  _.$_$.h2 = get_UTF16_BE;
  _.$_$.i2 = get_UTF8;
  _.$_$.j2 = WStringReader;
  _.$_$.k2 = WString;
  _.$_$.l2 = cancel_0;
  _.$_$.m2 = get_invalidOp;
  _.$_$.n2 = invalidOp;
  _.$_$.o2 = get_portableSimpleName;
  _.$_$.p2 = get_reserved;
  _.$_$.q2 = reserved;
  _.$_$.r2 = splitInChunks;
  _.$_$.s2 = splitKeep;
  _.$_$.t2 = substr;
  _.$_$.u2 = substr_0;
  _.$_$.v2 = toString_2;
  _.$_$.w2 = get_unreachable;
  _.$_$.x2 = unsupported_0;
  _.$_$.y2 = withInsertion;
  _.$_$.z2 = withoutIndex;
  _.$_$.a3 = withoutRange;
  _.$_$.b3 = Resourceable;
  _.$_$.c3 = Resources;
  _.$_$.d3 = Xml_0;
  _.$_$.e3 = get_1;
  _.$_$.f3 = FastByteArrayInputStream;
  _.$_$.g3 = get_eof;
  _.$_$.h3 = openAsync;
  _.$_$.i3 = openFastStream;
  _.$_$.j3 = openSync;
  _.$_$.k3 = readAll_0;
  _.$_$.l3 = readAvailable;
  _.$_$.m3 = readBytesExact_0;
  _.$_$.n3 = readBytes_0;
  _.$_$.o3 = readS16LE;
  _.$_$.p3 = readS32BE_1;
  _.$_$.q3 = readS32LE_1;
  _.$_$.r3 = readStream;
  _.$_$.s3 = readStream_0;
  _.$_$.t3 = readStringz_0;
  _.$_$.u3 = readString_0;
  _.$_$.v3 = readU16LE_1;
  _.$_$.w3 = readU32LE;
  _.$_$.x3 = readU8_0;
  _.$_$.y3 = skipToAlign;
  _.$_$.z3 = sliceStart_0;
  _.$_$.a4 = toSyncStream;
  _.$_$.b4 = Indenter;
  _.$_$.c4 = Once;
  _.$_$.d4 = get_length;
  _.$_$.e4 = get_niceStr_0;
  _.$_$.f4 = get_niceStr;
  _.$_$.g4 = niceStr;
  _.$_$.h4 = toStringDecimal_0;
  _.$_$.i4 = toStringDecimal;
  _.$_$.j4 = unquote;
  //endregion
  return _;
}));
