(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './korge-root-korma.js', './korge-root-korim.js', './korge-root-korge.js', './korge-root-korgw.js', './kotlin-kotlin-stdlib-js-ir.js', './korge-root-korinject.js', './korge-root-klogger.js', './kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js', './XenoTacticV2-XenoTactic-GameLogic.js', './korge-root-klock.js', './XenoTacticV2-XenoECS.js', './korge-root-korio.js', './XenoTacticV2-XenoKorgeCommon.js', './kotlinx-serialization-kotlinx-serialization-json-js-ir.js', './kotlinx-serialization-kotlinx-serialization-core-js-ir.js', './korge-root-kmem.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./korge-root-korma.js'), require('./korge-root-korim.js'), require('./korge-root-korge.js'), require('./korge-root-korgw.js'), require('./kotlin-kotlin-stdlib-js-ir.js'), require('./korge-root-korinject.js'), require('./korge-root-klogger.js'), require('./kotlinx.coroutines-kotlinx-coroutines-core-js-ir.js'), require('./XenoTacticV2-XenoTactic-GameLogic.js'), require('./korge-root-klock.js'), require('./XenoTacticV2-XenoECS.js'), require('./korge-root-korio.js'), require('./XenoTacticV2-XenoKorgeCommon.js'), require('./kotlinx-serialization-kotlinx-serialization-json-js-ir.js'), require('./kotlinx-serialization-kotlinx-serialization-core-js-ir.js'), require('./korge-root-kmem.js'));
  else {
    if (typeof this['korge-root-korma'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korma' was not found. Please, check whether 'korge-root-korma' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-korim'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korim' was not found. Please, check whether 'korge-root-korim' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-korge'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korge' was not found. Please, check whether 'korge-root-korge' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-korgw'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korgw' was not found. Please, check whether 'korge-root-korgw' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-korinject'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korinject' was not found. Please, check whether 'korge-root-korinject' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-klogger'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-klogger' was not found. Please, check whether 'korge-root-klogger' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' was not found. Please, check whether 'kotlinx.coroutines-kotlinx-coroutines-core-js-ir' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['XenoTacticV2-XenoTactic-GameLogic'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'XenoTacticV2-XenoTactic-GameLogic' was not found. Please, check whether 'XenoTacticV2-XenoTactic-GameLogic' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-klock'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-klock' was not found. Please, check whether 'korge-root-klock' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['XenoTacticV2-XenoECS'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'XenoTacticV2-XenoECS' was not found. Please, check whether 'XenoTacticV2-XenoECS' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-korio'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-korio' was not found. Please, check whether 'korge-root-korio' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['XenoTacticV2-XenoKorgeCommon'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'XenoTacticV2-XenoKorgeCommon' was not found. Please, check whether 'XenoTacticV2-XenoKorgeCommon' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['kotlinx-serialization-kotlinx-serialization-json-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'kotlinx-serialization-kotlinx-serialization-json-js-ir' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-json-js-ir' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['kotlinx-serialization-kotlinx-serialization-core-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'kotlinx-serialization-kotlinx-serialization-core-js-ir' was not found. Please, check whether 'kotlinx-serialization-kotlinx-serialization-core-js-ir' is loaded prior to 'XenoTacticV2-korge'.");
    }
    if (typeof this['korge-root-kmem'] === 'undefined') {
      throw new Error("Error loading module 'XenoTacticV2-korge'. Its dependency 'korge-root-kmem' was not found. Please, check whether 'korge-root-kmem' is loaded prior to 'XenoTacticV2-korge'.");
    }
    root['XenoTacticV2-korge'] = factory(typeof this['XenoTacticV2-korge'] === 'undefined' ? {} : this['XenoTacticV2-korge'], this['korge-root-korma'], this['korge-root-korim'], this['korge-root-korge'], this['korge-root-korgw'], this['kotlin-kotlin-stdlib-js-ir'], this['korge-root-korinject'], this['korge-root-klogger'], this['kotlinx.coroutines-kotlinx-coroutines-core-js-ir'], this['XenoTacticV2-XenoTactic-GameLogic'], this['korge-root-klock'], this['XenoTacticV2-XenoECS'], this['korge-root-korio'], this['XenoTacticV2-XenoKorgeCommon'], this['kotlinx-serialization-kotlinx-serialization-json-js-ir'], this['kotlinx-serialization-kotlinx-serialization-core-js-ir'], this['korge-root-kmem']);
  }
}(this, function (_, kotlin_com_soywiz_korlibs_korma_korma, kotlin_com_soywiz_korlibs_korim_korim, kotlin_com_soywiz_korlibs_korge2_korge, kotlin_com_soywiz_korlibs_korgw_korgw, kotlin_kotlin, kotlin_com_soywiz_korlibs_korinject_korinject, kotlin_com_soywiz_korlibs_klogger_klogger, kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core, kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic, kotlin_com_soywiz_korlibs_klock_klock, kotlin_com_xenotactic_ecs_XenoECS, kotlin_com_soywiz_korlibs_korio_korio, kotlin_com_xeno_korge_common_XenoKorgeCommon, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_json, kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core, kotlin_com_soywiz_korlibs_kmem_kmem) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var Size_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.u;
  var Colors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.i1;
  var Companion_getInstance = kotlin_com_soywiz_korlibs_korma_korma.$_$.d5;
  var Companion_getInstance_0 = kotlin_com_soywiz_korlibs_korma_korma.$_$.s4;
  var KorgeDisplayMode = kotlin_com_soywiz_korlibs_korge2_korge.$_$.w2;
  var Quality_AUTOMATIC_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.w1;
  var VOID = kotlin_kotlin.$_$.mj;
  var Korge = kotlin_com_soywiz_korlibs_korge2_korge.$_$.x2;
  var CoroutineImpl = kotlin_kotlin.$_$.lb;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var protoOf = kotlin_kotlin.$_$.nd;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var AsyncInjector = kotlin_com_soywiz_korlibs_korinject_korinject.$_$.e;
  var views = kotlin_com_soywiz_korlibs_korge2_korge.$_$.c;
  var get_COROUTINE_SUSPENDED = kotlin_kotlin.$_$.ua;
  var classMeta = kotlin_kotlin.$_$.zb;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var println = kotlin_kotlin.$_$.pb;
  var FixedSizeContainer = kotlin_com_soywiz_korlibs_korge2_korge.$_$.w1;
  var Stage = kotlin_com_soywiz_korlibs_korge2_korge.$_$.e2;
  var Companion_getInstance_1 = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.f;
  var Level_DEBUG_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.a;
  var Dispatchers_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.h;
  var CoroutineScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.s;
  var EventBus = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o1;
  var getKClass = kotlin_kotlin.$_$.e;
  var get_AlphaTransition = kotlin_com_soywiz_korlibs_korge2_korge.$_$.o;
  var Companion_getInstance_2 = kotlin_com_soywiz_korlibs_korma_korma.$_$.g5;
  var withEasing = kotlin_com_soywiz_korlibs_korge2_korge.$_$.r;
  var Companion_getInstance_3 = kotlin_com_soywiz_korlibs_korma_korma.$_$.e5;
  var findFirstAscendant = kotlin_com_soywiz_korlibs_korge2_korge.$_$.k2;
  var Size = kotlin_com_soywiz_korlibs_korma_korma.$_$.k7;
  var SceneContainer = kotlin_com_soywiz_korlibs_korge2_korge.$_$.p;
  var addTo = kotlin_com_soywiz_korlibs_korge2_korge.$_$.h2;
  var Companion_getInstance_4 = kotlin_com_soywiz_korlibs_klock_klock.$_$.w;
  var get_EmptyContinuation = kotlin_kotlin.$_$.xa;
  var objectMeta = kotlin_kotlin.$_$.md;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.u;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var SingletonInjectionDoesNotExistException = kotlin_com_xenotactic_ecs_XenoECS.$_$.j;
  var GameUnit__toInt_impl_9qttgv = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p5;
  var getAvailablePathingPointsFromBlockingEntities = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p2;
  var UpdatedPathLineEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u1;
  var get_GAME_WIDTH = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z2;
  var get_GAME_HEIGHT = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y2;
  var GameMap = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w1;
  var toGameUnit = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c4;
  var GRectInt = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v1;
  var rectangleIntersects = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x3;
  var max = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s3;
  var GameUnit__minus_impl_uz6kmr = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l5;
  var GameUnit__plus_impl_rh1b6r = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m5;
  var min = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u3;
  var Rock = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c2;
  var PathFinder_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x6;
  var toString = kotlin_kotlin.$_$.sd;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var getVisibleLocalArea = kotlin_com_soywiz_korlibs_korge2_korge.$_$.m2;
  var getVisibleGlobalArea = kotlin_com_soywiz_korlibs_korge2_korge.$_$.l2;
  var getVisibleWindowArea = kotlin_com_soywiz_korlibs_korge2_korge.$_$.n2;
  var trimIndent = kotlin_kotlin.$_$.dh;
  var get_DefaultTtfFontAsBitmap = kotlin_com_soywiz_korlibs_korim_korim.$_$.n2;
  var Companion_getInstance_5 = kotlin_com_soywiz_korlibs_korim_korim.$_$.o1;
  var get_DefaultStringTextRenderer = kotlin_com_soywiz_korlibs_korim_korim.$_$.f3;
  var Companion_getInstance_6 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.h;
  var Text = kotlin_com_soywiz_korlibs_korge2_korge.$_$.f2;
  var Companion_getInstance_7 = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.l3;
  var numberToInt = kotlin_kotlin.$_$.jd;
  var xy = kotlin_com_soywiz_korlibs_korge2_korge.$_$.v2;
  var hashCode = kotlin_kotlin.$_$.lc;
  var equals = kotlin_kotlin.$_$.cc;
  var EntityId = kotlin_com_xenotactic_ecs_XenoECS.$_$.h;
  var EntityId__hashCode_impl_1s8fhw = kotlin_com_xenotactic_ecs_XenoECS.$_$.o;
  var getNumberHashCode = kotlin_kotlin.$_$.ic;
  var ScrollDeltaMode_PIXEL_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.x;
  var Companion_getInstance_8 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n6;
  var Type_DRAG_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.a1;
  var Type_UP_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.d1;
  var Type_DOWN_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.z;
  var Type_SCROLL_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.c1;
  var toScale = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f4;
  var minus = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t3;
  var times = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a4;
  var MouseButton_RIGHT_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.v;
  var NotImplementedError = kotlin_kotlin.$_$.xh;
  var Companion_getInstance_9 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p6;
  var Fixed = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j2;
  var MapEntityType_TELEPORT_OUT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w4;
  var IllegalArgumentException_init_$Create$ = kotlin_kotlin.$_$.m1;
  var checkCanPlaceTowerEntity = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n3;
  var Ok_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u6;
  var InformErrorMessageEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q1;
  var Errors = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i3;
  var checkCanPlaceEntity = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m3;
  var MapEntityType_START_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t4;
  var MapEntityType_FINISH_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q4;
  var MapEntityType_CHECKPOINT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p4;
  var MapEntityType_TELEPORT_IN_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v4;
  var MapEntityType_TOWER_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x4;
  var MapEntityType_SUPPLY_DEPOT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u4;
  var setOf = kotlin_kotlin.$_$.l9;
  var MapEntityType_ROCK_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r4;
  var GameUnit__tup_impl_pf7bck = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s5;
  var noWhenBranchMatchedException = kotlin_kotlin.$_$.dj;
  var Type_MOVE_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.b1;
  var RectangleEntity = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l2;
  var GameplayState = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s2;
  var GameMapApi = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d3;
  var Companion_getInstance_10 = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.i3;
  var Key_EQUAL_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.c;
  var Key_MINUS_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.i;
  var Companion_getInstance_11 = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.h3;
  var Key_ESCAPE_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.d;
  var Enum = kotlin_kotlin.$_$.rh;
  var MouseButton_LEFT_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.t;
  var MouseButton_MIDDLE_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.u;
  var DraggableInfo = kotlin_com_soywiz_korlibs_korge2_korge.$_$.j;
  var Vector2_init_$Create$ = kotlin_com_soywiz_korlibs_korma_korma.$_$.w;
  var Companion_getInstance_12 = kotlin_com_soywiz_korlibs_klock_klock.$_$.v;
  var Vector2 = kotlin_com_soywiz_korlibs_korma_korma.$_$.m7;
  var xy_0 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.u2;
  var Companion_getInstance_13 = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.j3;
  var SmallBlocker = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d2;
  var Tower = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i2;
  var Checkpoint = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a2;
  var TeleportOut = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h2;
  var TeleportIn = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g2;
  var Finish = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b2;
  var Start = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f2;
  var until = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l4;
  var GameUnitTuple = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x1;
  var PreSelectionComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w;
  var SelectedComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z;
  var SelectedComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k6;
  var PreSelectionComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j6;
  var SolidRect = kotlin_com_soywiz_korlibs_korge2_korge.$_$.d2;
  var alpha = kotlin_com_soywiz_korlibs_korge2_korge.$_$.i2;
  var Type_CLICK_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.y;
  var Collection = kotlin_kotlin.$_$.d5;
  var isInterface = kotlin_kotlin.$_$.xc;
  var GameUnit = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f3;
  var GameUnit__hashCode_impl_playmc = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k5;
  var Long = kotlin_kotlin.$_$.vh;
  var RangeComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y;
  var SizeComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a1;
  var BottomLeftPositionComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e;
  var GameUnit__toWorldUnit_impl_m803zs = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r5;
  var GameUnit__div_impl_u8qjua = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j5;
  var WorldUnit__toFloat_impl_2n0xxa = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a6;
  var RGBA__withAd_impl_cralao = kotlin_com_soywiz_korlibs_korim_korim.$_$.v;
  var RGBA = kotlin_com_soywiz_korlibs_korim_korim.$_$.i2;
  var Circle = kotlin_com_soywiz_korlibs_korge2_korge.$_$.u1;
  var onAdd = kotlin_com_xenotactic_ecs_XenoECS.$_$.c;
  var onReplace = kotlin_com_xenotactic_ecs_XenoECS.$_$.f;
  var onExisting = kotlin_com_xenotactic_ecs_XenoECS.$_$.d;
  var ComponentListener = kotlin_com_xenotactic_ecs_XenoECS.$_$.g;
  var UIEntityContainerComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f1;
  var toWorldDimensions = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j4;
  var Graphics = kotlin_com_soywiz_korlibs_korge2_korge.$_$.x1;
  var ShapeBuilder = kotlin_com_soywiz_korlibs_korim_korim.$_$.p3;
  var StrokeInfo = kotlin_com_soywiz_korlibs_korma_korma.$_$.t5;
  var centerOn = kotlin_com_soywiz_korlibs_korge2_korge.$_$.o1;
  var UISelectionComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k1;
  var UIEntityViewComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g1;
  var onAddOrReplace = kotlin_com_xenotactic_ecs_XenoECS.$_$.b;
  var UIMapEntityTextComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j1;
  var DamageUpgradeComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g;
  var calculateCostOfUpgrades = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k3;
  var SpeedUpgradeComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b1;
  var calculateUpgradeDecision = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l3;
  var MutableGoldState = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u2;
  var EntitySpeedAreaComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l;
  var SpeedAreaColorUtil_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t6;
  var getCenterPoint = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p3;
  var toPoint = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d4;
  var MapEntityType_SPEED_AREA_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s4;
  var numberToDouble = kotlin_kotlin.$_$.id;
  var UIProgressBar = kotlin_com_soywiz_korlibs_korge2_korge.$_$.u;
  var AddedEntityEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m1;
  var AddedMonsterEntityEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n1;
  var Container = kotlin_com_soywiz_korlibs_korge2_korge.$_$.v1;
  var WorldUnit__div_impl_b90n6a = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v5;
  var TowerType_BASIC_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y4;
  var UIGunBarrelComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h1;
  var MaterialColors_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.j1;
  var WorldUnit__minus_impl_yejnmb = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x5;
  var _WorldUnit___get_value__impl__xwm9rd = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b6;
  var Size_init_$Create$_0 = kotlin_com_soywiz_korlibs_korma_korma.$_$.t;
  var GlobalResources_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q6;
  var UIEightDirectionalSprite = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n4;
  var anchor = kotlin_com_soywiz_korlibs_korge2_korge.$_$.j2;
  var UIEightDirectionalSpriteComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e1;
  var UIHealthBarComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i1;
  var MaxHealthComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s;
  var FamilyConfiguration = kotlin_com_xenotactic_ecs_XenoECS.$_$.i;
  var WorldUnit = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j3;
  var xy_1 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m4;
  var RuntimeException = kotlin_kotlin.$_$.ai;
  var RuntimeException_init_$Init$ = kotlin_kotlin.$_$.w1;
  var captureStack = kotlin_kotlin.$_$.tb;
  var AStarSearcher_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w6;
  var Companion_getInstance_14 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m6;
  var Level_INFO_getInstance = kotlin_com_soywiz_korlibs_klogger_klogger.$_$.c;
  var GameWorld = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y1;
  var Random = kotlin_kotlin.$_$.zd;
  var listOf = kotlin_kotlin.$_$.p8;
  var Companion_getInstance_15 = kotlin_com_xenotactic_ecs_XenoECS.$_$.p;
  var EntityStartComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m;
  var EntityFinishComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.j;
  var Success = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o2;
  var plus = kotlin_kotlin.$_$.b9;
  var Failure = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n2;
  var LinkedHashSet_init_$Create$ = kotlin_kotlin.$_$.w;
  var toRectangleEntity = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e4;
  var emptyList = kotlin_kotlin.$_$.e7;
  var collectionSizeOrDefault = kotlin_kotlin.$_$.e6;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var TeleportPair = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.m2;
  var ResizeMapEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t1;
  var Scene = kotlin_com_soywiz_korlibs_korge2_korge.$_$.q;
  var World = kotlin_com_xenotactic_ecs_XenoECS.$_$.m;
  var Engine = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x2;
  var GameMapDimensionsState = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q2;
  var centerOnStage = kotlin_com_soywiz_korlibs_korge2_korge.$_$.n1;
  var ComponentEntityContainer = kotlin_com_xenotactic_ecs_XenoECS.$_$.a;
  var centerXOnStage = kotlin_com_soywiz_korlibs_korge2_korge.$_$.q1;
  var CoroutineScope_0 = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.t;
  var Key_UP_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.n;
  var Key_DOWN_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.b;
  var Key_LEFT_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.g;
  var Key_RIGHT_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.j;
  var launch = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.g1;
  var MainScope = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.z;
  var draggable = kotlin_com_soywiz_korlibs_korge2_korge.$_$.l;
  var onStageResized = kotlin_com_soywiz_korlibs_korge2_korge.$_$.o2;
  var _TimeSpan___init__impl__hqf6tm = kotlin_com_soywiz_korlibs_klock_klock.$_$.d;
  var addFixedUpdater = kotlin_com_soywiz_korlibs_korge2_korge.$_$.g2;
  var THROW_ISE = kotlin_kotlin.$_$.di;
  var Monotonic_getInstance = kotlin_kotlin.$_$.r4;
  var ValueTimeMark__elapsedNow_impl_eonqvs = kotlin_kotlin.$_$.g2;
  var TimedValue = kotlin_kotlin.$_$.jh;
  var Duration = kotlin_kotlin.$_$.ih;
  var _Duration___get_inWholeNanoseconds__impl__r5x4mr = kotlin_kotlin.$_$.e2;
  var Pair = kotlin_kotlin.$_$.yh;
  var MouseEvents = kotlin_com_soywiz_korlibs_korge2_korge.$_$.k;
  var launchImmediately = kotlin_com_soywiz_korlibs_korio_korio.$_$.h1;
  var Success_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b;
  var Failure_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a;
  var chunked = kotlin_kotlin.$_$.d6;
  var withIndex = kotlin_kotlin.$_$.pa;
  var get_baseName = kotlin_com_soywiz_korlibs_korio_korio.$_$.a2;
  var Success_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c6;
  var getGoldenJsonFiles = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q3;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var toGameMap = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b4;
  var verify = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c;
  var toList = kotlin_kotlin.$_$.ea;
  var List = kotlin_kotlin.$_$.k5;
  var UIButton = kotlin_com_soywiz_korlibs_korge2_korge.$_$.s;
  var alignLeftToRightOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.g1;
  var get_mouse = kotlin_com_soywiz_korlibs_korge2_korge.$_$.n;
  var alignTopToBottomOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.j1;
  var Type_UP_getInstance_0 = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.r;
  var KProperty1 = kotlin_kotlin.$_$.re;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var MapToId_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r6;
  var take = kotlin_kotlin.$_$.w9;
  var KeyEvent = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.t4;
  var TimeSpan__plus_impl_qdd6zo = kotlin_com_soywiz_korlibs_klock_klock.$_$.o;
  var TimeSpan = kotlin_com_soywiz_korlibs_klock_klock.$_$.z;
  var TimeSpan__compareTo_impl_zidry4 = kotlin_com_soywiz_korlibs_klock_klock.$_$.e;
  var listOf_0 = kotlin_kotlin.$_$.q8;
  var GameSimulator = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e3;
  var get_keys = kotlin_com_soywiz_korlibs_korge2_korge.$_$.m;
  var _Duration___get_inWholeMilliseconds__impl__msfiry = kotlin_kotlin.$_$.d2;
  var throwUninitializedPropertyAccessException = kotlin_kotlin.$_$.ij;
  var SpacingBetween = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.d;
  var UIText = kotlin_com_soywiz_korlibs_korge2_korge.$_$.x;
  var Companion_getInstance_16 = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.f;
  var Companion_getInstance_17 = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.e;
  var Column = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.a;
  var SolidBackgroundColor = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.c;
  var Padding = kotlin_com_xeno_korge_common_XenoKorgeCommon.$_$.b;
  var System = kotlin_com_xenotactic_ecs_XenoECS.$_$.k;
  var PathSequenceTraversalComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v;
  var AnimationComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d;
  var ComputedSpeedEffectComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f;
  var MonsterComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t;
  var HealthComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r;
  var VelocityComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l1;
  var setOf_0 = kotlin_kotlin.$_$.k9;
  var ProjectileComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.x;
  var MutableCenterPositionComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u;
  var EntityTowerComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p;
  var TargetingComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d1;
  var WorldUnit__toDouble_impl_9mljzf = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z5;
  var Vector2_init_$Create$_0 = kotlin_com_soywiz_korlibs_korma_korma.$_$.v;
  var IPoint = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z1;
  var rotation = kotlin_com_soywiz_korlibs_korge2_korge.$_$.p2;
  var dispatch$default = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.y1;
  var dispatch = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.n4;
  var onEvents = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.o4;
  var getStringHashCode = kotlin_kotlin.$_$.kc;
  var ByWidthAndHeight = kotlin_com_soywiz_korlibs_korge2_korge.$_$.b2;
  var scaleWhileMaintainingAspect = kotlin_com_soywiz_korlibs_korge2_korge.$_$.q2;
  var alignLeftToLeftOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.e1;
  var IndexedValue = kotlin_kotlin.$_$.g5;
  var Signal = kotlin_com_soywiz_korlibs_korio_korio.$_$.f1;
  var toList_0 = kotlin_kotlin.$_$.da;
  var firstOrNull = kotlin_kotlin.$_$.l7;
  var toInt = kotlin_kotlin.$_$.qg;
  var size = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z3;
  var UIWindow = kotlin_com_soywiz_korlibs_korge2_korge.$_$.z;
  var get_UI_DEFAULT_PADDING = kotlin_com_soywiz_korlibs_korge2_korge.$_$.a1;
  var UIVerticalStack = kotlin_com_soywiz_korlibs_korge2_korge.$_$.y;
  var Companion_getInstance_18 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.f;
  var GameUnit__toString_impl_3u51th = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q5;
  var UITextInput = kotlin_com_soywiz_korlibs_korge2_korge.$_$.w;
  var Companion_getInstance_19 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.e;
  var centerXOn = kotlin_com_soywiz_korlibs_korge2_korge.$_$.r1;
  var UIHorizontalStack = kotlin_com_soywiz_korlibs_korge2_korge.$_$.t;
  var alignBottomToBottomOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.b1;
  var Vector2Int = kotlin_com_soywiz_korlibs_korma_korma.$_$.l7;
  var Companion_getInstance_20 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.g;
  var RGBA__hashCode_impl_h59qf6 = kotlin_com_soywiz_korlibs_korim_korim.$_$.n;
  var objectCreate = kotlin_kotlin.$_$.ld;
  var launch_0 = kotlin_com_soywiz_korlibs_korio_korio.$_$.m1;
  var alignLeftToLeftOf_0 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.d1;
  var rectCorner = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w3;
  var RoundRect = kotlin_com_soywiz_korlibs_korge2_korge.$_$.z1;
  var alignRightToRightOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.i1;
  var existsBlocking = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o3;
  var Map = kotlin_kotlin.$_$.m5;
  var alignBottomToTopOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.c1;
  var toSet = kotlin_kotlin.$_$.ef;
  var onRemove = kotlin_com_xenotactic_ecs_XenoECS.$_$.e;
  var alignLeftToLeftOf_1 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.f1;
  var _GameUnit___get_value__impl__9ocgox = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t5;
  var ByHeight = kotlin_com_soywiz_korlibs_korge2_korge.$_$.a2;
  var RemovedTowerEntityEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s1;
  var MutableCurrentlySelectedTowerState = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.t2;
  var toWorldUnit = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k4;
  var TowerType_HIGH_DAMAGE_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z4;
  var onAttachDetach = kotlin_com_soywiz_korlibs_korge2_korge.$_$.i;
  var Image_init_$Create$ = kotlin_com_soywiz_korlibs_korge2_korge.$_$.d;
  var centerYOn = kotlin_com_soywiz_korlibs_korge2_korge.$_$.s1;
  var mutableListOf = kotlin_kotlin.$_$.w8;
  var windowed = kotlin_kotlin.$_$.na;
  var alignRightToRightOf_0 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.h1;
  var get_GRID_SIZE = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c3;
  var get_BORDER_RATIO = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v2;
  var get_GRID_LINES_RATIO = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a3;
  var get_GRID_NUMBERS_RATIO = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b3;
  var get_PATH_LINES_RATIO = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g3;
  var RockCounterUtil_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.s6;
  var GameUnit__toFloat_impl_sc3672 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o5;
  var toWorldCoordinates = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h4;
  var xy_2 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.s2;
  var xy_3 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.t2;
  var rangeTo = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v3;
  var WorldUnit__minus_impl_yejnmb_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w5;
  var WorldUnit__plus_impl_n1o1x9 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y5;
  var SpeedArea = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e2;
  var UIEntity = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o4;
  var delay = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.e;
  var GlobalScope_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.i;
  var EmptyShape_getInstance = kotlin_com_soywiz_korlibs_korim_korim.$_$.q1;
  var GpuShapeView = kotlin_com_soywiz_korlibs_korge2_korge.$_$.t1;
  var Mutex = kotlin_org_jetbrains_kotlinx_kotlinx_coroutines_core.$_$.o;
  var toWorldCoordinates_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g4;
  var size_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y3;
  var toWorldDimensions_0 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i4;
  var to = kotlin_kotlin.$_$.lj;
  var Companion_getInstance_21 = kotlin_com_soywiz_korlibs_korim_korim.$_$.l1;
  var get_DefaultTtfFont = kotlin_com_soywiz_korlibs_korim_korim.$_$.m2;
  var BitmapEffect = kotlin_com_soywiz_korlibs_korim_korim.$_$.r1;
  var renderToBitmap = kotlin_com_soywiz_korlibs_korge2_korge.$_$.b;
  var launch_1 = kotlin_com_soywiz_korlibs_korio_korio.$_$.l1;
  var _RectCorners___init__impl__w373y9 = kotlin_com_soywiz_korlibs_korma_korma.$_$.x3;
  var alignTopToTopOf = kotlin_com_soywiz_korlibs_korge2_korge.$_$.l1;
  var UIScrollable = kotlin_com_soywiz_korlibs_korge2_korge.$_$.v;
  var Companion_getInstance_22 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.l6;
  var Companion_getInstance_23 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.v6;
  var Point = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h3;
  var EntityTypeComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.q;
  var GameMapPathState = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r2;
  var GraphicsRenderer_SYSTEM_getInstance = kotlin_com_soywiz_korlibs_korge2_korge.$_$.a;
  var _WorldUnit___init__impl__qt45s3 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.u5;
  var _GameUnit___init__impl__teirvv = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i5;
  var GameUnit__toDouble_impl_5m2yph = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n5;
  var roundDecimalPlaces = kotlin_com_soywiz_korlibs_korma_korma.$_$.h8;
  var SolverSettings = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c7;
  var StandardSolver3 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d7;
  var MaxPath_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.y6;
  var SolverParams = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a7;
  var Success_1 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b7;
  var Unsuccessful_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.z6;
  var Companion_getInstance_24 = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o6;
  var AsyncSignal = kotlin_com_soywiz_korlibs_korio_korio.$_$.e1;
  var alignTopToTopOf_0 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.m1;
  var alignTopToBottomOf_0 = kotlin_com_soywiz_korlibs_korge2_korge.$_$.k1;
  var GoldStateUpdated = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.p1;
  var RemovedSupplyDepotEntityEvent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r1;
  var toStringDecimal = kotlin_com_soywiz_korlibs_korio_korio.$_$.i4;
  var ByWidth = kotlin_com_soywiz_korlibs_korge2_korge.$_$.c2;
  var Key_SHIFT_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.k;
  var EntityRenderComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k;
  var get_resourcesVfs = kotlin_com_soywiz_korlibs_korio_korio.$_$.z1;
  var PlayerData = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.k2;
  var Default_getInstance = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_json.$_$.a;
  var arrayOf = kotlin_kotlin.$_$.qi;
  var createKType = kotlin_kotlin.$_$.b;
  var serializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.s2;
  var KSerializer = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_core.$_$.n2;
  var Json = kotlin_org_jetbrains_kotlinx_kotlinx_serialization_json.$_$.b;
  var EntityStartComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g6;
  var EntityFinishComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e6;
  var EntityCheckpointComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h;
  var EntityTeleportInComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.n;
  var EntityTeleportOutComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.o;
  var EntityRockComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f6;
  var EntityBlockingComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d6;
  var EntityTowerComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i6;
  var EntityCostComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.i;
  var SupplyCostComponent = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c1;
  var EntitySupplyDepotComponent_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h6;
  var StagingEntity_init_$Create$ = kotlin_com_xenotactic_ecs_XenoECS.$_$.n;
  var intersectRectangles = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.r3;
  var removeAll = kotlin_kotlin.$_$.d9;
  var Angle__inBetween_impl_ojsmql = kotlin_com_soywiz_korlibs_korma_korma.$_$.l1;
  var NoSuchElementException_init_$Create$ = kotlin_kotlin.$_$.u1;
  var get_degrees = kotlin_com_soywiz_korlibs_korma_korma.$_$.p7;
  var Angle = kotlin_com_soywiz_korlibs_korma_korma.$_$.v6;
  var rangeTo_0 = kotlin_kotlin.$_$.ie;
  var EightDirection_RIGHT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.e5;
  var EightDirection_UP_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.h5;
  var EightDirection_LEFT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.d5;
  var EightDirection_DOWN_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.c5;
  var EightDirection_UP_RIGHT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.g5;
  var EightDirection_UP_LEFT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.f5;
  var EightDirection_DOWN_LEFT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.a5;
  var EightDirection_DOWN_RIGHT_getInstance = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.b5;
  var roundToInt = kotlin_kotlin.$_$.vd;
  var clamp = kotlin_com_soywiz_korlibs_kmem_kmem.$_$.j3;
  var Vector2_init_$Create$_1 = kotlin_com_soywiz_korlibs_korma_korma.$_$.x;
  var ScrollDeltaMode_LINE_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.w;
  var MouseButton_BUTTON_WHEEL_getInstance = kotlin_com_soywiz_korlibs_korgw_korgw.$_$.s;
  var get_ENTITY_LABEL_SIZE = kotlin_com_xenotactic_gamelogic_XenoTactic_GameLogic.$_$.w2;
  var firstOrNull_0 = kotlin_kotlin.$_$.xe;
  //endregion
  //region block: pre-declaration
  setMetadataFor(main$slambda$slambda, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_1, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_3, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_5, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_7, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_9, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_11, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda$slambda_13, 'main$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(main$slambda, 'main$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(DebugPathingPoints, 'DebugPathingPoints', classMeta);
  setMetadataFor(None, 'None', objectMeta, DebugPathingPoints);
  setMetadataFor(DebugEComponent, 'DebugEComponent', classMeta);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(GameMapControllerEComponent, 'GameMapControllerEComponent', classMeta);
  setMetadataFor(GoalEComponent, 'GoalEComponent', classMeta);
  setMetadataFor(MutableShowRangeTimeComponent, 'MutableShowRangeTimeComponent', classMeta);
  setMetadataFor(ObjectPlacementEComponent, 'ObjectPlacementEComponent', classMeta);
  setMetadataFor(ResizeDebugComponent, 'ResizeDebugComponent', classMeta);
  setMetadataFor(UIPreSelectionComponent, 'UIPreSelectionComponent', classMeta);
  setMetadataFor(UIShowRangeComponent, 'UIShowRangeComponent', classMeta);
  setMetadataFor(AddEntityEvent, 'AddEntityEvent', classMeta);
  setMetadataFor(EntitySelectionChangedEvent, 'EntitySelectionChangedEvent', objectMeta);
  setMetadataFor(EscapeButtonActionEvent, 'EscapeButtonActionEvent', objectMeta);
  setMetadataFor(ExitGameSceneEvent, 'ExitGameSceneEvent', objectMeta);
  setMetadataFor(GoldensEntryClickEvent, 'GoldensEntryClickEvent', classMeta);
  setMetadataFor(GoldensEntryHoverOnEvent, 'GoldensEntryHoverOnEvent', classMeta);
  setMetadataFor(LeftControlAndEqual, 'LeftControlAndEqual', objectMeta);
  setMetadataFor(LeftControlAndMinus, 'LeftControlAndMinus', objectMeta);
  setMetadataFor(PlayMapEvent, 'PlayMapEvent', classMeta);
  setMetadataFor(PointerActionChangeEvent, 'PointerActionChangeEvent', objectMeta);
  setMetadataFor(RemovedEntityEvent, 'RemovedEntityEvent', classMeta);
  setMetadataFor(SpawnCreepEvent, 'SpawnCreepEvent', objectMeta);
  setMetadataFor(UpgradeTowerDamageEvent, 'UpgradeTowerDamageEvent', classMeta);
  setMetadataFor(UpgradeTowerSpeedEvent, 'UpgradeTowerSpeedEvent', classMeta);
  setMetadataFor(UpgradedTowerDamageEvent, 'UpgradedTowerDamageEvent', classMeta);
  setMetadataFor(UpgradedTowerSpeedEvent, 'UpgradedTowerSpeedEvent', classMeta);
  setMetadataFor(CameraInputProcessor$slambda, 'CameraInputProcessor$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(CameraInputProcessor$slambda_1, 'CameraInputProcessor$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(CameraInputProcessor, 'CameraInputProcessor', classMeta);
  setMetadataFor(PlacedEntityEvent, 'PlacedEntityEvent', classMeta);
  setMetadataFor(EditorPlacementInputProcessor, 'EditorPlacementInputProcessor', classMeta);
  setMetadataFor(KeyInputProcessor, 'KeyInputProcessor', classMeta);
  setMetadataFor(MouseDragStateType, 'MouseDragStateType', classMeta, Enum);
  setMetadataFor(MouseDragStateSettings, 'MouseDragStateSettings', classMeta);
  setMetadataFor(MouseDragInputProcessor, 'MouseDragInputProcessor', classMeta);
  setMetadataFor(ObjectPlacementInputProcessor, 'ObjectPlacementInputProcessor', classMeta);
  setMetadataFor(SelectorMouseProcessorV2, 'SelectorMouseProcessorV2', classMeta);
  setMetadataFor(RemoveEntityData, 'RemoveEntityData', classMeta);
  setMetadataFor(PointerAction, 'PointerAction', classMeta);
  setMetadataFor(Inactive, 'Inactive', objectMeta, PointerAction);
  setMetadataFor(HighlightForPlacement, 'HighlightForPlacement', classMeta, PointerAction);
  setMetadataFor(RemoveEntityAtPlace, 'RemoveEntityAtPlace', classMeta, PointerAction);
  setMetadataFor(MutableShowRangeTimeComponentListener, 'MutableShowRangeTimeComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(PreSelectionComponentListener, 'PreSelectionComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(SelectionComponentListener, 'SelectionComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(UIMapEntityComponentListener, 'UIMapEntityComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(UIMapEntityTextComponentListener, 'UIMapEntityTextComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(UIShowRangeComponentListener, 'UIShowRangeComponentListener', classMeta, VOID, [ComponentListener]);
  setMetadataFor(TowerUpgradeEventListeners$slambda, 'TowerUpgradeEventListeners$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(TowerUpgradeEventListeners$slambda_1, 'TowerUpgradeEventListeners$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(TowerUpgradeEventListeners, 'TowerUpgradeEventListeners', classMeta);
  setMetadataFor(RemoveUIEntitiesEvent, 'RemoveUIEntitiesEvent', classMeta);
  setMetadataFor(UIMapEventListeners$slambda, 'UIMapEventListeners$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEventListeners$slambda_1, 'UIMapEventListeners$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEventListeners$slambda_3, 'UIMapEventListeners$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEventListeners, 'UIMapEventListeners', classMeta);
  setMetadataFor(SetInitialPositionForUIEntityFamilyListener, 'SetInitialPositionForUIEntityFamilyListener', classMeta);
  setMetadataFor(RandomMapGeneratorMaxAttemptsError, 'RandomMapGeneratorMaxAttemptsError', classMeta, RuntimeException);
  setMetadataFor(MapGeneratorConfigurationV2, 'MapGeneratorConfigurationV2', classMeta);
  setMetadataFor(GenerationContext, 'GenerationContext', classMeta);
  setMetadataFor(MapGeneratorResultV2, 'MapGeneratorResultV2', classMeta);
  setMetadataFor(Success_2, 'Success', classMeta, MapGeneratorResultV2);
  setMetadataFor(Failure_1, 'Failure', classMeta, MapGeneratorResultV2);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(RandomMapGeneratorV2, 'RandomMapGeneratorV2', classMeta);
  setMetadataFor(CheckpointsGenerator, 'CheckpointsGenerator', classMeta);
  setMetadataFor(FinishGenerator, 'FinishGenerator', objectMeta);
  setMetadataFor(RocksGenerator, 'RocksGenerator', classMeta);
  setMetadataFor(SpeedAreaGenerator, 'SpeedAreaGenerator', classMeta);
  setMetadataFor(StartGenerator, 'StartGenerator', objectMeta);
  setMetadataFor(TeleportsGenerator, 'TeleportsGenerator', classMeta);
  setMetadataFor(EditorSceneV2$sceneInit$slambda, 'EditorSceneV2$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(EditorSceneV2$sceneInit$slambda_1, 'EditorSceneV2$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(EditorSceneV2, 'EditorSceneV2', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(GameScene$sceneInit$slambda$slambda, 'GameScene$sceneInit$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(Companion_1, 'Companion', objectMeta);
  setMetadataFor(GameScene$sceneInit$slambda, 'GameScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GameScene$sceneInit$slambda_1, 'GameScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GameScene$sceneInit$slambda_3, 'GameScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GameScene, 'GameScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(MapFilterOptions, 'MapFilterOptions', classMeta, Enum);
  setMetadataFor(Companion_2, 'Companion', objectMeta);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$lambda$slambda, 'GoldensViewerScene$sceneInit$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_1, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$lambda$slambda_1, 'GoldensViewerScene$sceneInit$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_3, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$lambda$slambda_3, 'GoldensViewerScene$sceneInit$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_5, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$lambda$slambda_5, 'GoldensViewerScene$sceneInit$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_7, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_9, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(GoldensViewerScene$sceneInit$slambda_11, 'GoldensViewerScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($sceneDestroyCOROUTINE$1, '$sceneDestroyCOROUTINE$1', classMeta, CoroutineImpl);
  setMetadataFor(GoldensViewerScene, 'GoldensViewerScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(Companion_3, 'Companion', objectMeta);
  setMetadataFor(MapViewerScene$sceneInit$lambda$lambda$slambda, 'MapViewerScene$sceneInit$lambda$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(MapViewerScene$sceneInit$lambda$lambda$lambda$slambda, 'MapViewerScene$sceneInit$lambda$lambda$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($sceneInitCOROUTINE$2, '$sceneInitCOROUTINE$2', classMeta, CoroutineImpl);
  setMetadataFor(MapViewerScene, 'MapViewerScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(PlayScene$sceneInit$slambda, 'PlayScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(PlayScene, 'PlayScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(Companion_4, 'Companion', objectMeta);
  setMetadataFor(RootScene$sceneInit$slambda, 'RootScene$sceneInit$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor($sceneInitCOROUTINE$4, '$sceneInitCOROUTINE$4', classMeta, CoroutineImpl);
  setMetadataFor($sceneAfterDestroyCOROUTINE$5, '$sceneAfterDestroyCOROUTINE$5', classMeta, CoroutineImpl);
  setMetadataFor($sceneDestroyCOROUTINE$6, '$sceneDestroyCOROUTINE$6', classMeta, CoroutineImpl);
  setMetadataFor(RootScene, 'RootScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(Companion_5, 'Companion', objectMeta);
  setMetadataFor(TestScene, 'TestScene', classMeta, Scene, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(DeadUIZonesState, 'DeadUIZonesState', classMeta);
  setMetadataFor(EditorState, 'EditorState', classMeta);
  setMetadataFor(MouseDragSettingsState, 'MouseDragSettingsState', classMeta);
  setMetadataFor(UIEightDirectionalMonsterAnimationSystem, 'UIEightDirectionalMonsterAnimationSystem', classMeta, System);
  setMetadataFor(UIEightDirectionalMonsterSpriteDirectionSystem, 'UIEightDirectionalMonsterSpriteDirectionSystem', classMeta, System);
  setMetadataFor(UIMonsterHealthRenderSystem, 'UIMonsterHealthRenderSystem', classMeta, System);
  setMetadataFor(UIMonsterUpdatePositionSystem, 'UIMonsterUpdatePositionSystem', classMeta, System);
  setMetadataFor(UIMutableRangeTimeUpdateSystem, 'UIMutableRangeTimeUpdateSystem', classMeta, System);
  setMetadataFor(UIProjectileRenderSystem, 'UIProjectileRenderSystem', classMeta, System);
  setMetadataFor(UITargetingRenderSystem, 'UITargetingRenderSystem', classMeta, System);
  setMetadataFor(Companion_6, 'Companion', objectMeta);
  setMetadataFor(UITowerGunRotatingSystem, 'UITowerGunRotatingSystem', classMeta, System);
  setMetadataFor(UIActiveTextNotifier$slambda, 'UIActiveTextNotifier$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIActiveTextNotifier, 'UIActiveTextNotifier', classMeta, Container);
  setMetadataFor(UIDebugInfo$pathLengthText$slambda, 'UIDebugInfo$pathLengthText$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIDebugInfo, 'UIDebugInfo', classMeta, Container);
  setMetadataFor(DropdownOptionClickedEvent, 'DropdownOptionClickedEvent', classMeta);
  setMetadataFor(UIDropdownOption, 'UIDropdownOption', classMeta);
  setMetadataFor(Empty, 'Empty', objectMeta, UIDropdownOption);
  setMetadataFor(NumberOption, 'NumberOption', classMeta, UIDropdownOption);
  setMetadataFor(StringOption, 'StringOption', classMeta, UIDropdownOption);
  setMetadataFor(UIDropdownSettings, 'UIDropdownSettings', classMeta);
  setMetadataFor(UIDropDownEntry, 'UIDropDownEntry', classMeta, Container);
  setMetadataFor(Companion_7, 'Companion', objectMeta);
  setMetadataFor(UIDropdown$resetWithOptions$slambda, 'UIDropdown$resetWithOptions$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIDropdown$resetWithOptions$lambda$slambda, 'UIDropdown$resetWithOptions$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIDropdown$slambda, 'UIDropdown$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIDropdown$lambda$slambda, 'UIDropdown$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIDropdown$slambda_1, 'UIDropdown$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIDropdown$lambda$slambda_1, 'UIDropdown$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIDropdown$slambda_3, 'UIDropdown$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIDropdown$lambda$slambda_3, 'UIDropdown$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIDropdown, 'UIDropdown', classMeta, Container);
  setMetadataFor(UIEditorButtonsV2$slambda$slambda, 'UIEditorButtonsV2$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$slambda$lambda$slambda, 'UIEditorButtonsV2$slambda$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_1, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda_1, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_3, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda_3, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_5, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda_5, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_7, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda_7, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_9, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$lambda$slambda_9, 'UIEditorButtonsV2$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIEditorButtonsV2$slambda_11, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$slambda_13, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2$slambda_15, 'UIEditorButtonsV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIEditorButtonsV2, 'UIEditorButtonsV2', classMeta, Container);
  setMetadataFor(Companion_8, 'Companion', objectMeta);
  setMetadataFor(UIFixedGrid, 'UIFixedGrid', classMeta, Container);
  setMetadataFor(MapWithMetadata, 'MapWithMetadata', classMeta);
  setMetadataFor(EntrySettings, 'EntrySettings', classMeta);
  setMetadataFor(Companion_9, 'Companion', objectMeta);
  setMetadataFor(UIGoldensViewerEntry$lambda$slambda, 'UIGoldensViewerEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGoldensViewerEntry$slambda, 'UIGoldensViewerEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGoldensViewerEntry$lambda$slambda_1, 'UIGoldensViewerEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGoldensViewerEntry$slambda_1, 'UIGoldensViewerEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGoldensViewerEntry$lambda$slambda_3, 'UIGoldensViewerEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGoldensViewerEntry, 'UIGoldensViewerEntry', classMeta, Container);
  setMetadataFor(ViewType, 'ViewType', classMeta, Enum);
  setMetadataFor(UIGuiContainer$addTowerView$slambda, 'UIGuiContainer$addTowerView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addTowerView$lambda$slambda, 'UIGuiContainer$addTowerView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$addTowerView$slambda_1, 'UIGuiContainer$addTowerView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addTowerView$lambda$slambda_1, 'UIGuiContainer$addTowerView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$addTowerView$slambda_3, 'UIGuiContainer$addTowerView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addTowerView$lambda$slambda_3, 'UIGuiContainer$addTowerView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$slambda, 'UIGuiContainer$addSupplyDepotView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$lambda$slambda, 'UIGuiContainer$addSupplyDepotView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$slambda_1, 'UIGuiContainer$addSupplyDepotView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$lambda$slambda_1, 'UIGuiContainer$addSupplyDepotView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$slambda_3, 'UIGuiContainer$addSupplyDepotView$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$addSupplyDepotView$lambda$slambda_3, 'UIGuiContainer$addSupplyDepotView$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_1, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_1, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_3, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_3, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_5, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_5, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_7, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_7, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_9, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$slambda_11, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_9, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_13, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_11, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_15, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_13, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_17, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_15, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_19, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_17, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_21, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_19, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_23, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_21, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_25, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_23, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$slambda_27, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$lambda$slambda_25, 'UIGuiContainer$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIGuiContainer$4, VOID, classMeta, VOID, [ComponentListener]);
  setMetadataFor(UIGuiContainer$5, VOID, classMeta, VOID, [ComponentListener]);
  setMetadataFor(UIGuiContainer$slambda_29, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer$slambda_31, 'UIGuiContainer$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIGuiContainer, 'UIGuiContainer', classMeta);
  setMetadataFor(UIHeaderSection, 'UIHeaderSection', classMeta, Enum);
  setMetadataFor(UIHeader$slambda, 'UIHeader$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIHeader$lambda$slambda, 'UIHeader$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIHeader$slambda_1, 'UIHeader$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIHeader$lambda$slambda_1, 'UIHeader$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIHeader, 'UIHeader', classMeta, Container);
  setMetadataFor(BoardType, 'BoardType', classMeta, Enum);
  setMetadataFor(UIMapSettings, 'UIMapSettings', classMeta);
  setMetadataFor(UIMap$viewRockCounters$slambda, 'UIMap$viewRockCounters$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor($viewRockCountersCOROUTINE$7, '$viewRockCountersCOROUTINE$7', classMeta, CoroutineImpl);
  setMetadataFor(UIMap, 'UIMap', classMeta, Container, VOID, VOID, VOID, [0]);
  setMetadataFor(Companion_10, 'Companion', objectMeta);
  setMetadataFor(UIMapBox$updateMap$slambda, 'UIMapBox$updateMap$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapBox, 'UIMapBox', classMeta, Container);
  setMetadataFor(Companion_11, 'Companion', objectMeta);
  setMetadataFor(UIMapEntry$slambda, 'UIMapEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEntry$lambda$slambda, 'UIMapEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapEntry$slambda_1, 'UIMapEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEntry$lambda$slambda_1, 'UIMapEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapEntry$slambda_3, 'UIMapEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEntry$lambda$slambda_3, 'UIMapEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapEntry$slambda_5, 'UIMapEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEntry$lambda$slambda_5, 'UIMapEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapEntry$slambda_7, 'UIMapEntry$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapEntry$lambda$slambda_7, 'UIMapEntry$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapEntry, 'UIMapEntry', classMeta, Container);
  setMetadataFor(UIMapInspector, 'UIMapInspector', classMeta, Container);
  setMetadataFor(UIMapOverlayOutsideClickedEvent, 'UIMapOverlayOutsideClickedEvent', objectMeta);
  setMetadataFor(Companion_12, 'Companion', objectMeta);
  setMetadataFor(UIMapOverlay$setOverlay$slambda, 'UIMapOverlay$setOverlay$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapOverlay$setOverlay$lambda$slambda, 'UIMapOverlay$setOverlay$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapOverlay$setOverlay$slambda_1, 'UIMapOverlay$setOverlay$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapOverlay$setOverlay$lambda$slambda_1, 'UIMapOverlay$setOverlay$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIMapOverlay, 'UIMapOverlay', classMeta, Container);
  setMetadataFor(UIMapSettingsV2, 'UIMapSettingsV2', classMeta);
  setMetadataFor(UIMapV2$slambda, 'UIMapV2$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIMapV2, 'UIMapV2', classMeta, Container);
  setMetadataFor(NotificationTextUpdateEvent, 'NotificationTextUpdateEvent', classMeta);
  setMetadataFor(UINotificationText$slambda, 'UINotificationText$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UINotificationText$slambda_1, 'UINotificationText$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UINotificationText$slambda_3, 'UINotificationText$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UINotificationText, 'UINotificationText', classMeta, Container);
  setMetadataFor(UIPathText, 'UIPathText', classMeta, Container);
  setMetadataFor(UIPlacementButton, 'UIPlacementButton', classMeta, Enum);
  setMetadataFor(UIPlacement$slambda$slambda, 'UIPlacement$slambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$slambda$lambda$slambda, 'UIPlacement$slambda$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(Companion_13, 'Companion', objectMeta);
  setMetadataFor(UIPlacement$slambda, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_1, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_1, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_3, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_3, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_5, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_5, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_7, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_7, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_9, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_9, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_11, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_11, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_13, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_13, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_15, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement$lambda$slambda_15, 'UIPlacement$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UIPlacement$slambda_17, 'UIPlacement$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UIPlacement, 'UIPlacement', classMeta, Container);
  setMetadataFor(UITextRect, 'UITextRect', classMeta, Container);
  setMetadataFor(UITextWithShadow, 'UITextWithShadow', classMeta, Container);
  setMetadataFor(UITooltipDescription, 'UITooltipDescription', classMeta, Container);
  setMetadataFor(UITopRightResourcesGui$slambda, 'UITopRightResourcesGui$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITopRightResourcesGui$slambda_1, 'UITopRightResourcesGui$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITopRightResourcesGui$slambda_3, 'UITopRightResourcesGui$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITopRightResourcesGui$slambda_5, 'UITopRightResourcesGui$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITopRightResourcesGui, 'UITopRightResourcesGui', classMeta, Container);
  setMetadataFor(UITowerDetails$slambda, 'UITowerDetails$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerDetails$slambda_1, 'UITowerDetails$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerDetails$slambda_3, 'UITowerDetails$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerDetails$slambda_5, 'UITowerDetails$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerDetails$slambda_7, 'UITowerDetails$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerDetails, 'UITowerDetails', classMeta, Container);
  setMetadataFor(UITowerUpgradeIcon$slambda, 'UITowerUpgradeIcon$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerUpgradeIcon$slambda_1, 'UITowerUpgradeIcon$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerUpgradeIcon$lambda$slambda, 'UITowerUpgradeIcon$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UITowerUpgradeIcon$slambda_3, 'UITowerUpgradeIcon$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerUpgradeIcon$lambda$slambda_1, 'UITowerUpgradeIcon$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UITowerUpgradeIcon$slambda_5, 'UITowerUpgradeIcon$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(UITowerUpgradeIcon$lambda$slambda_3, 'UITowerUpgradeIcon$lambda$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [0]);
  setMetadataFor(UITowerUpgradeIcon, 'UITowerUpgradeIcon', classMeta, Container);
  setMetadataFor(MapBridge, 'MapBridge', classMeta);
  setMetadataFor(MapRendererUpdater$slambda, 'MapRendererUpdater$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(MapRendererUpdater$slambda_1, 'MapRendererUpdater$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(MapRendererUpdater$slambda_3, 'MapRendererUpdater$slambda', classMeta, CoroutineImpl, VOID, VOID, VOID, [1]);
  setMetadataFor(MapRendererUpdater, 'MapRendererUpdater', classMeta);
  setMetadataFor($getPlayerDataCOROUTINE$8, '$getPlayerDataCOROUTINE$8', classMeta, CoroutineImpl);
  setMetadataFor($savePlayerDataCOROUTINE$9, '$savePlayerDataCOROUTINE$9', classMeta, CoroutineImpl);
  setMetadataFor(PlayerDataApi, 'PlayerDataApi', objectMeta, VOID, VOID, VOID, VOID, [0, 1]);
  setMetadataFor(StagingEntityUtils, 'StagingEntityUtils', objectMeta);
  setMetadataFor(DirectionMatcher, 'DirectionMatcher', classMeta);
  //endregion
  function main($completion) {
    var tmp0_virtualSize = Size_init_$Create$(1920, 1080);
    var tmp1_windowSize = Size_init_$Create$(1280, 720);
    var tmp2_backgroundColor = Colors_getInstance().p57('#2b2b2b');
    var tmp3_displayMode = new KorgeDisplayMode(Companion_getInstance().b43(), Companion_getInstance_0().o3q_1, false);
    var tmp4_quality = Quality_AUTOMATIC_getInstance();
    var tmp = new Korge(VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, VOID, tmp1_windowSize, tmp0_virtualSize, tmp3_displayMode, VOID, tmp2_backgroundColor, tmp4_quality, VOID, VOID, true);
    var tmp0 = tmp.r9n(main$slambda_0(null), $completion);
    return tmp0;
  }
  function main$slambda$slambda($globalBus, $mapBridge, resultContinuation) {
    this.ih6_1 = $globalBus;
    this.jh6_1 = $mapBridge;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda).lh6 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda).g8 = function (p1, $completion) {
    return this.lh6(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var ARGUMENT = suspendResult;
            return new RootScene(ARGUMENT, this.ih6_1, this.jh6_1);
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(main$slambda$slambda).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda(this.ih6_1, this.jh6_1, completion);
    i.kh6_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_0($globalBus, $mapBridge, resultContinuation) {
    var i = new main$slambda$slambda($globalBus, $mapBridge, resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.lh6($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_1($mapBridge, resultContinuation) {
    this.uh6_1 = $mapBridge;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_1).wh6 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_1).g8 = function (p1, $completion) {
    return this.wh6(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println('mapping GameScene');
          return new GameScene(this.uh6_1);
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_1).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_1(this.uh6_1, completion);
    i.vh6_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_2($mapBridge, resultContinuation) {
    var i = new main$slambda$slambda_1($mapBridge, resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.wh6($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_3($globalBus, resultContinuation) {
    this.fh7_1 = $globalBus;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_3).hh7 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_3).g8 = function (p1, $completion) {
    return this.hh7(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new MapViewerScene(this.fh7_1);
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_3).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_3(this.fh7_1, completion);
    i.gh7_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_4($globalBus, resultContinuation) {
    var i = new main$slambda$slambda_3($globalBus, resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.hh7($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_5(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_5).rh7 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_5).g8 = function (p1, $completion) {
    return this.rh7(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new GoldensViewerScene();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_5).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_5(completion);
    i.qh7_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_6(resultContinuation) {
    var i = new main$slambda$slambda_5(resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.rh7($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_7(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_7).bh8 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_7).g8 = function (p1, $completion) {
    return this.bh8(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new TestScene();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_7).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_7(completion);
    i.ah8_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_8(resultContinuation) {
    var i = new main$slambda$slambda_7(resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.bh8($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_9(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_9).lh8 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_9).g8 = function (p1, $completion) {
    return this.lh8(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new EditorSceneV2();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_9).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_9(completion);
    i.kh8_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_10(resultContinuation) {
    var i = new main$slambda$slambda_9(resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.lh8($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_11(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_11).vh8 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_11).g8 = function (p1, $completion) {
    return this.vh8(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new PlayScene();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_11).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_11(completion);
    i.uh8_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_12(resultContinuation) {
    var i = new main$slambda$slambda_11(resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.vh8($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$slambda_13($this_start, $globalBus, $mapBridge, resultContinuation) {
    this.eh9_1 = $this_start;
    this.fh9_1 = $globalBus;
    this.gh9_1 = $mapBridge;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda$slambda_13).lh6 = function ($this$mapPrototype, $completion) {
    var tmp = this.s9z($this$mapPrototype, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda$slambda_13).g8 = function (p1, $completion) {
    return this.lh6(p1 instanceof AsyncInjector ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return new RootScene(this.eh9_1.gae(), this.fh9_1, this.gh9_1);
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(main$slambda$slambda_13).s9z = function ($this$mapPrototype, completion) {
    var i = new main$slambda$slambda_13(this.eh9_1, this.fh9_1, this.gh9_1, completion);
    i.hh9_1 = $this$mapPrototype;
    return i;
  };
  function main$slambda$slambda_14($this_start, $globalBus, $mapBridge, resultContinuation) {
    var i = new main$slambda$slambda_13($this_start, $globalBus, $mapBridge, resultContinuation);
    var l = function ($this$mapPrototype, $completion) {
      return i.lh6($this$mapPrototype, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function main$slambda$lambda(it) {
    return it instanceof FixedSizeContainer;
  }
  function main$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(main$slambda).t9l = function ($this$start, $completion) {
    var tmp = this.u9l($this$start, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(main$slambda).g8 = function (p1, $completion) {
    return this.t9l(p1 instanceof Stage ? p1 : THROW_CCE(), $completion);
  };
  protoOf(main$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            Companion_getInstance_1().x1m(Level_DEBUG_getInstance());
            this.rh9_1 = new EventBus(CoroutineScope(Dispatchers_getInstance().fv_1));
            println('Preparing main module');
            println(this.qh9_1.gae());
            this.sh9_1 = new MapBridge();
            var tmp0_apply = this.qh9_1.bb1();
            var tmp0_mapPrototype = main$slambda$slambda_0(this.rh9_1, this.sh9_1, null);
            tmp0_apply.f9j(getKClass(RootScene), tmp0_mapPrototype);
            ;
            var tmp1_mapPrototype = main$slambda$slambda_2(this.sh9_1, null);
            tmp0_apply.f9j(getKClass(GameScene), tmp1_mapPrototype);
            ;
            var tmp2_mapPrototype = main$slambda$slambda_4(this.rh9_1, null);
            tmp0_apply.f9j(getKClass(MapViewerScene), tmp2_mapPrototype);
            ;
            var tmp3_mapPrototype = main$slambda$slambda_6(null);
            tmp0_apply.f9j(getKClass(GoldensViewerScene), tmp3_mapPrototype);
            ;
            var tmp4_mapPrototype = main$slambda$slambda_8(null);
            tmp0_apply.f9j(getKClass(TestScene), tmp4_mapPrototype);
            ;
            var tmp5_mapPrototype = main$slambda$slambda_10(null);
            tmp0_apply.f9j(getKClass(EditorSceneV2), tmp5_mapPrototype);
            ;
            var tmp6_mapPrototype = main$slambda$slambda_12(null);
            tmp0_apply.f9j(getKClass(PlayScene), tmp6_mapPrototype);
            ;
            var tmp7_mapPrototype = main$slambda$slambda_14(this.qh9_1, this.rh9_1, this.sh9_1, null);
            tmp0_apply.f9j(getKClass(RootScene), tmp7_mapPrototype);
            ;
            ;
            var tmp_0 = this;
            tmp_0.th9_1 = withEasing(get_AlphaTransition(), Companion_getInstance_2().v4m());
            var tmp_1 = this;
            tmp_1.uh9_1 = Companion_getInstance_3().g43_1;
            this.y7_1 = 1;
            suspendResult = views(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.vh9_1 = suspendResult;
            this.wh9_1 = this.uh9_1;
            this.xh9_1 = this.wh9_1.c5();
            this.yh9_1 = this.wh9_1.d5();
            this.zh9_1 = this.xh9_1;
            this.aha_1 = this.yh9_1;
            if (this.xh9_1 === 0.0 ? this.yh9_1 === 0.0 : false) {
              var tmp_2 = findFirstAscendant(this.qh9_1, main$slambda$lambda);
              var base = (tmp_2 == null ? true : tmp_2 instanceof FixedSizeContainer) ? tmp_2 : null;
              var tmp_3 = this;
              var tmp1_safe_receiver = base;
              var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : tmp1_safe_receiver.x18();
              tmp_3.zh9_1 = tmp2_elvis_lhs == null ? this.vh9_1.l9q_1.x18() : tmp2_elvis_lhs;
              var tmp_4 = this;
              var tmp3_safe_receiver = base;
              var tmp4_elvis_lhs = tmp3_safe_receiver == null ? null : tmp3_safe_receiver.y18();
              tmp_4.aha_1 = tmp4_elvis_lhs == null ? this.vh9_1.l9q_1.y18() : tmp4_elvis_lhs;
            }

            var tmp_5 = this;
            tmp_5.bha_1 = new SceneContainer(this.vh9_1, this.th9_1, 'sceneContainer', new Size(this.zh9_1, this.aha_1));
            var tmp_6 = this;
            tmp_6.cha_1 = addTo(this.bha_1, this.qh9_1);
            var tmp_7 = this;
            tmp_7.dha_1 = Companion_getInstance_4().m18(0.0);
            var tmp_8 = this;
            tmp_8.eha_1 = this.cha_1.d9w_1;
            this.y7_1 = 2;
            suspendResult = this.cha_1.wbd(getKClass(RootScene), [].slice(), this.dha_1, this.eha_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            ;
            ;
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(main$slambda).u9l = function ($this$start, completion) {
    var i = new main$slambda(completion);
    i.qh9_1 = $this$start;
    return i;
  };
  function main$slambda_0(resultContinuation) {
    var i = new main$slambda(resultContinuation);
    var l = function ($this$start, $completion) {
      return i.t9l($this$start, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function None() {
    None_instance = this;
    DebugPathingPoints.call(this);
  }
  var None_instance;
  function None_getInstance() {
    if (None_instance == null)
      new None();
    return None_instance;
  }
  function DebugPathingPoints() {
  }
  function DebugEComponent$updatePathingPoints$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapControllerEComponent);
  }
  function DebugEComponent(engine) {
    this.fha_1 = engine;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.gha_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$1 = ArrayList_init_$Create$();
    tmp_0.hha_1 = tmp$ret$1;
    this.iha_1 = false;
    this.jha_1 = None_getInstance();
  }
  protoOf(DebugEComponent).kha = function () {
    if (!this.iha_1) {
      this.hha_1.e4();
      return Unit_getInstance();
    }
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.fha_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapControllerEComponent));
    tmp$ret$0 = (tmp == null ? true : tmp instanceof GameMapControllerEComponent) ? tmp : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_0;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(DebugEComponent$updatePathingPoints$lambda);
    } else {
      tmp_0 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_0;
    var mapComponent = tmp$ret$1;
    var gameMap = mapComponent.pha();
    this.hha_1.e4();
    this.hha_1.m(getAvailablePathingPointsFromBlockingEntities(gameMap.zga(), GameUnit__toInt_impl_9qttgv(gameMap.bg9_1), GameUnit__toInt_impl_9qttgv(gameMap.cg9_1), gameMap.jga()));
  };
  function updateShortestPath($this, path) {
    $this.oha_1 = path;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp0_getSingletonOrNull = $this.lha_1.hgo_1;
    var tmp = tmp0_getSingletonOrNull.wg2_1.h3(getKClass(DebugEComponent));
    tmp$ret$0 = (tmp == null ? true : tmp instanceof DebugEComponent) ? tmp : THROW_CCE();
    var tmp0_safe_receiver = tmp$ret$0;
    if (tmp0_safe_receiver == null)
      null;
    else {
      tmp0_safe_receiver.kha();
    }
    var tmp_0 = $this.oha_1;
    var tmp1_safe_receiver = $this.oha_1;
    $this.mha_1.bg7(new UpdatedPathLineEvent(tmp_0, tmp1_safe_receiver == null ? null : tmp1_safe_receiver.ggk_1));
  }
  function Companion() {
    Companion_instance = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(GameMapControllerEComponent).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.qha_1 = tmp$ret$0;
  }
  var Companion_instance;
  function Companion_getInstance_25() {
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function GameMapControllerEComponent(engine, eventBus, gameMap) {
    Companion_getInstance_25();
    gameMap = gameMap === VOID ? new GameMap(get_GAME_WIDTH(), get_GAME_HEIGHT()) : gameMap;
    this.lha_1 = engine;
    this.mha_1 = eventBus;
    this.nha_1 = gameMap;
    this.oha_1 = null;
  }
  protoOf(GameMapControllerEComponent).mg8 = function () {
    return this.nha_1.cg9_1;
  };
  protoOf(GameMapControllerEComponent).lg8 = function () {
    return this.nha_1.bg9_1;
  };
  protoOf(GameMapControllerEComponent).cgb = function (x, y) {
    return this.nha_1.cgb(x, y);
  };
  protoOf(GameMapControllerEComponent).dgb = function (x, y) {
    return this.nha_1.dgb(x, y);
  };
  protoOf(GameMapControllerEComponent).egb = function (roundedGridX, roundedGridY) {
    return this.nha_1.egb(roundedGridX, roundedGridY);
  };
  protoOf(GameMapControllerEComponent).uga = function (entity) {
    return this.nha_1.uga(entity);
  };
  protoOf(GameMapControllerEComponent).rha = function (entities) {
    var gameMapRect = new GRectInt(toGameUnit(0), toGameUnit(0), this.nha_1.bg9_1, this.nha_1.cg9_1);
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.all' call
      var indexedObject = entities;
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var element = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        var tmp$ret$0;
        // Inline function 'com.xenotactic.korge.components.GameMapControllerEComponent.placeEntities.<anonymous>' call
        tmp$ret$0 = rectangleIntersects(gameMapRect, element.dgh());
        if (!tmp$ret$0) {
          tmp$ret$1 = false;
          break $l$block;
        }
      }
      tmp$ret$1 = true;
    }
    var allEntitiesIntersectMap = tmp$ret$1;
    if (!allEntitiesIntersectMap)
      return Unit_getInstance();
    var indexedObject_0 = entities;
    var inductionVariable_0 = 0;
    var last_0 = indexedObject_0.length;
    while (inductionVariable_0 < last_0) {
      var entity = indexedObject_0[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var tmp1_subject = entity;
      var tmp;
      if (tmp1_subject instanceof Rock) {
        var newX = max(entity.jg8(), 0);
        var newY = max(entity.kg8(), 0);
        var entityWidth = GameUnit__minus_impl_uz6kmr(entity.lg8(), GameUnit__minus_impl_uz6kmr(newX, entity.jg8()));
        var entityHeight = GameUnit__minus_impl_uz6kmr(entity.mg8(), GameUnit__minus_impl_uz6kmr(newY, entity.kg8()));
        var newWidth = GameUnit__minus_impl_uz6kmr(min(this.nha_1.bg9_1, GameUnit__plus_impl_rh1b6r(entity.jg8(), entityWidth)), entity.jg8());
        var newHeight = GameUnit__minus_impl_uz6kmr(min(this.nha_1.cg9_1, GameUnit__plus_impl_rh1b6r(entity.kg8(), entityHeight)), entity.kg8());
        tmp = new Rock(newX, newY, newWidth, newHeight);
      } else {
        tmp = entity;
      }
      var placementEntity = tmp;
      this.nha_1.og9(placementEntity);
      this.mha_1.bg7(new AddEntityEvent(placementEntity));
    }
    println('new gamemap: ' + this.nha_1);
    updateShortestPath(this, PathFinder_getInstance().tgj(this.nha_1));
  };
  protoOf(GameMapControllerEComponent).og9 = function (entity) {
    this.rha([entity]);
  };
  protoOf(GameMapControllerEComponent).tga = function (entity) {
    this.nha_1.tga(entity);
    updateShortestPath(this, PathFinder_getInstance().tgj(this.nha_1));
    this.mha_1.bg7(new RemovedEntityEvent(entity));
  };
  protoOf(GameMapControllerEComponent).sha = function (map) {
    this.nha_1 = map;
    updateShortestPath(this, PathFinder_getInstance().tgj(this.nha_1));
  };
  protoOf(GameMapControllerEComponent).pha = function () {
    return this.nha_1;
  };
  function GoalEComponent(engine, eventBus) {
    this.tha_1 = engine;
    this.uha_1 = eventBus;
    this.vha_1 = null;
    this.wha_1 = null;
  }
  function MutableShowRangeTimeComponent(showTimeRemainingMillis) {
    this.xha_1 = showTimeRemainingMillis;
  }
  protoOf(MutableShowRangeTimeComponent).toString = function () {
    return 'MutableShowRangeTimeComponent(showTimeRemainingMillis=' + toString(this.xha_1) + ')';
  };
  protoOf(MutableShowRangeTimeComponent).hashCode = function () {
    return this.xha_1.hashCode();
  };
  protoOf(MutableShowRangeTimeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MutableShowRangeTimeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof MutableShowRangeTimeComponent ? other : THROW_CCE();
    if (!this.xha_1.equals(tmp0_other_with_cast.xha_1))
      return false;
    return true;
  };
  function ObjectPlacementEComponent() {
    this.yha_1 = Inactive_getInstance();
  }
  function getText($this, width, height) {
    var parent = getReferenceParent($this.zha_1);
    return trimIndent('\n            text.pos: ' + $this.ahb_1.e30() + '\n            width: ' + width + '\n            height: ' + height + '\n            view.windowBounds: ' + $this.zha_1.obn() + '\n            view.parent.windowBounds: ' + ensureNotNull($this.zha_1.np()).obn() + '\n            view.width: ' + $this.zha_1.x18() + '\n            view.height: ' + $this.zha_1.y18() + '\n            view.getBounds(): ' + $this.zha_1.abo() + '\n            view.getBoundsNoAnchoring(): ' + $this.zha_1.xbn() + '\n            view.globalXY(): ' + $this.zha_1.wbl() + '\n            view.globalBounds: ' + $this.zha_1.tbn() + '\n            view.getGlobalBounds(): ' + $this.zha_1.vbn() + '\n            view.getLocalBounds(): ' + $this.zha_1.san() + '\n            view.getLocalBoundsInternal(): ' + $this.zha_1.wbg() + '\n            view.getVisibleLocalArea(): ' + getVisibleLocalArea($this.zha_1) + '\n            view.getVisibleGlobalArea(): ' + getVisibleGlobalArea($this.zha_1) + '\n            view.getVisibleWindowArea(): ' + getVisibleWindowArea($this.zha_1) + '\n            parent.getVisibleLocalArea(): ' + getVisibleLocalArea(parent) + '\n            parent.getVisibleGlobalArea(): ' + getVisibleGlobalArea(parent) + '\n            parent.getVisibleWindowArea(): ' + getVisibleWindowArea(parent) + '\n        ');
  }
  function getText$default($this, width, height, $super) {
    width = width === VOID ? 0 : width;
    height = height === VOID ? 0 : height;
    return getText($this, width, height);
  }
  function ResizeDebugComponent$setup$lambda(this$0) {
    return function (it) {
      this$0.na0(it.a7h_1, it.b7h_1);
      return Unit_getInstance();
    };
  }
  function ResizeDebugComponent(view) {
    this.zha_1 = view;
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = this.zha_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text('', 12.0, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, tmp2_text);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.ahb_1 = tmp$ret$3;
    this.ahb_1.fbu(getText$default(this));
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp0_visible = this.ahb_1;
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_visible.u96(false);
    tmp$ret$4 = tmp0_visible;
    tmp$ret$5 = tmp$ret$4;
  }
  protoOf(ResizeDebugComponent).bhb = function (eventListener) {
    var tmp = [Companion_getInstance_7()];
    eventListener.a7d(tmp, ResizeDebugComponent$setup$lambda(this));
  };
  protoOf(ResizeDebugComponent).na0 = function (width, height) {
    var txt = getText(this, width, height);
    this.ahb_1.fbu(txt);
    xy(this.ahb_1, numberToInt(getVisibleLocalArea(this.zha_1).z3r_1), numberToInt(getVisibleLocalArea(this.zha_1).a3s_1));
  };
  function UIPreSelectionComponent(graphics) {
    this.chb_1 = graphics;
  }
  protoOf(UIPreSelectionComponent).toString = function () {
    return 'UIPreSelectionComponent(graphics=' + this.chb_1 + ')';
  };
  protoOf(UIPreSelectionComponent).hashCode = function () {
    return hashCode(this.chb_1);
  };
  protoOf(UIPreSelectionComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIPreSelectionComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIPreSelectionComponent ? other : THROW_CCE();
    if (!equals(this.chb_1, tmp0_other_with_cast.chb_1))
      return false;
    return true;
  };
  function UIShowRangeComponent(view) {
    this.dhb_1 = view;
  }
  protoOf(UIShowRangeComponent).toString = function () {
    return 'UIShowRangeComponent(view=' + this.dhb_1 + ')';
  };
  protoOf(UIShowRangeComponent).hashCode = function () {
    return hashCode(this.dhb_1);
  };
  protoOf(UIShowRangeComponent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIShowRangeComponent))
      return false;
    var tmp0_other_with_cast = other instanceof UIShowRangeComponent ? other : THROW_CCE();
    if (!equals(this.dhb_1, tmp0_other_with_cast.dhb_1))
      return false;
    return true;
  };
  function AddEntityEvent(entity) {
    this.ehb_1 = entity;
  }
  protoOf(AddEntityEvent).toString = function () {
    return 'AddEntityEvent(entity=' + this.ehb_1 + ')';
  };
  protoOf(AddEntityEvent).hashCode = function () {
    return hashCode(this.ehb_1);
  };
  protoOf(AddEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof AddEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof AddEntityEvent ? other : THROW_CCE();
    if (!equals(this.ehb_1, tmp0_other_with_cast.ehb_1))
      return false;
    return true;
  };
  function EntitySelectionChangedEvent() {
    EntitySelectionChangedEvent_instance = this;
  }
  var EntitySelectionChangedEvent_instance;
  function EntitySelectionChangedEvent_getInstance() {
    if (EntitySelectionChangedEvent_instance == null)
      new EntitySelectionChangedEvent();
    return EntitySelectionChangedEvent_instance;
  }
  function EscapeButtonActionEvent() {
    EscapeButtonActionEvent_instance = this;
  }
  var EscapeButtonActionEvent_instance;
  function EscapeButtonActionEvent_getInstance() {
    if (EscapeButtonActionEvent_instance == null)
      new EscapeButtonActionEvent();
    return EscapeButtonActionEvent_instance;
  }
  function ExitGameSceneEvent() {
    ExitGameSceneEvent_instance = this;
  }
  var ExitGameSceneEvent_instance;
  function ExitGameSceneEvent_getInstance() {
    if (ExitGameSceneEvent_instance == null)
      new ExitGameSceneEvent();
    return ExitGameSceneEvent_instance;
  }
  function GoldensEntryClickEvent(gameMap) {
    this.fhb_1 = gameMap;
  }
  protoOf(GoldensEntryClickEvent).toString = function () {
    return 'GoldensEntryClickEvent(gameMap=' + this.fhb_1 + ')';
  };
  protoOf(GoldensEntryClickEvent).hashCode = function () {
    return this.fhb_1.hashCode();
  };
  protoOf(GoldensEntryClickEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GoldensEntryClickEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GoldensEntryClickEvent ? other : THROW_CCE();
    if (!this.fhb_1.equals(tmp0_other_with_cast.fhb_1))
      return false;
    return true;
  };
  function GoldensEntryHoverOnEvent(mapFile, gameMap, verificationResult) {
    this.ghb_1 = mapFile;
    this.hhb_1 = gameMap;
    this.ihb_1 = verificationResult;
  }
  protoOf(GoldensEntryHoverOnEvent).toString = function () {
    return 'GoldensEntryHoverOnEvent(mapFile=' + this.ghb_1 + ', gameMap=' + this.hhb_1 + ', verificationResult=' + this.ihb_1 + ')';
  };
  protoOf(GoldensEntryHoverOnEvent).hashCode = function () {
    var result = this.ghb_1.hashCode();
    result = imul(result, 31) + this.hhb_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.ihb_1) | 0;
    return result;
  };
  protoOf(GoldensEntryHoverOnEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GoldensEntryHoverOnEvent))
      return false;
    var tmp0_other_with_cast = other instanceof GoldensEntryHoverOnEvent ? other : THROW_CCE();
    if (!this.ghb_1.equals(tmp0_other_with_cast.ghb_1))
      return false;
    if (!this.hhb_1.equals(tmp0_other_with_cast.hhb_1))
      return false;
    if (!equals(this.ihb_1, tmp0_other_with_cast.ihb_1))
      return false;
    return true;
  };
  function LeftControlAndEqual() {
    LeftControlAndEqual_instance = this;
  }
  var LeftControlAndEqual_instance;
  function LeftControlAndEqual_getInstance() {
    if (LeftControlAndEqual_instance == null)
      new LeftControlAndEqual();
    return LeftControlAndEqual_instance;
  }
  function LeftControlAndMinus() {
    LeftControlAndMinus_instance = this;
  }
  var LeftControlAndMinus_instance;
  function LeftControlAndMinus_getInstance() {
    if (LeftControlAndMinus_instance == null)
      new LeftControlAndMinus();
    return LeftControlAndMinus_instance;
  }
  function PlayMapEvent(gameMap) {
    this.jhb_1 = gameMap;
  }
  protoOf(PlayMapEvent).toString = function () {
    return 'PlayMapEvent(gameMap=' + this.jhb_1 + ')';
  };
  protoOf(PlayMapEvent).hashCode = function () {
    return this.jhb_1.hashCode();
  };
  protoOf(PlayMapEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PlayMapEvent))
      return false;
    var tmp0_other_with_cast = other instanceof PlayMapEvent ? other : THROW_CCE();
    if (!this.jhb_1.equals(tmp0_other_with_cast.jhb_1))
      return false;
    return true;
  };
  function PointerActionChangeEvent() {
    PointerActionChangeEvent_instance = this;
  }
  var PointerActionChangeEvent_instance;
  function PointerActionChangeEvent_getInstance() {
    if (PointerActionChangeEvent_instance == null)
      new PointerActionChangeEvent();
    return PointerActionChangeEvent_instance;
  }
  function RemovedEntityEvent(entity) {
    this.khb_1 = entity;
  }
  protoOf(RemovedEntityEvent).toString = function () {
    return 'RemovedEntityEvent(entity=' + this.khb_1 + ')';
  };
  protoOf(RemovedEntityEvent).hashCode = function () {
    return hashCode(this.khb_1);
  };
  protoOf(RemovedEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemovedEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof RemovedEntityEvent ? other : THROW_CCE();
    if (!equals(this.khb_1, tmp0_other_with_cast.khb_1))
      return false;
    return true;
  };
  function SpawnCreepEvent() {
    SpawnCreepEvent_instance = this;
  }
  var SpawnCreepEvent_instance;
  function SpawnCreepEvent_getInstance() {
    if (SpawnCreepEvent_instance == null)
      new SpawnCreepEvent();
    return SpawnCreepEvent_instance;
  }
  function UpgradeTowerDamageEvent(numUpgrades) {
    this.lhb_1 = numUpgrades;
  }
  protoOf(UpgradeTowerDamageEvent).toString = function () {
    return 'UpgradeTowerDamageEvent(numUpgrades=' + this.lhb_1 + ')';
  };
  protoOf(UpgradeTowerDamageEvent).hashCode = function () {
    return this.lhb_1;
  };
  protoOf(UpgradeTowerDamageEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpgradeTowerDamageEvent))
      return false;
    var tmp0_other_with_cast = other instanceof UpgradeTowerDamageEvent ? other : THROW_CCE();
    if (!(this.lhb_1 === tmp0_other_with_cast.lhb_1))
      return false;
    return true;
  };
  function UpgradeTowerSpeedEvent(numUpgrades) {
    this.mhb_1 = numUpgrades;
  }
  protoOf(UpgradeTowerSpeedEvent).toString = function () {
    return 'UpgradeTowerSpeedEvent(numUpgrades=' + this.mhb_1 + ')';
  };
  protoOf(UpgradeTowerSpeedEvent).hashCode = function () {
    return this.mhb_1;
  };
  protoOf(UpgradeTowerSpeedEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpgradeTowerSpeedEvent))
      return false;
    var tmp0_other_with_cast = other instanceof UpgradeTowerSpeedEvent ? other : THROW_CCE();
    if (!(this.mhb_1 === tmp0_other_with_cast.mhb_1))
      return false;
    return true;
  };
  function UpgradedTowerDamageEvent(towerId, newDamage, newDamageUpgrade) {
    this.nhb_1 = towerId;
    this.ohb_1 = newDamage;
    this.phb_1 = newDamageUpgrade;
  }
  protoOf(UpgradedTowerDamageEvent).toString = function () {
    return 'UpgradedTowerDamageEvent(towerId=' + new EntityId(this.nhb_1) + ', newDamage=' + this.ohb_1 + ', newDamageUpgrade=' + this.phb_1 + ')';
  };
  protoOf(UpgradedTowerDamageEvent).hashCode = function () {
    var result = EntityId__hashCode_impl_1s8fhw(this.nhb_1);
    result = imul(result, 31) + getNumberHashCode(this.ohb_1) | 0;
    result = imul(result, 31) + this.phb_1 | 0;
    return result;
  };
  protoOf(UpgradedTowerDamageEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpgradedTowerDamageEvent))
      return false;
    var tmp0_other_with_cast = other instanceof UpgradedTowerDamageEvent ? other : THROW_CCE();
    if (!(this.nhb_1 === tmp0_other_with_cast.nhb_1))
      return false;
    if (!equals(this.ohb_1, tmp0_other_with_cast.ohb_1))
      return false;
    if (!(this.phb_1 === tmp0_other_with_cast.phb_1))
      return false;
    return true;
  };
  function UpgradedTowerSpeedEvent(towerId, newWeaponSpeedMillis, newAttacksPerSecond, newSpeedUpgrade) {
    this.qhb_1 = towerId;
    this.rhb_1 = newWeaponSpeedMillis;
    this.shb_1 = newAttacksPerSecond;
    this.thb_1 = newSpeedUpgrade;
  }
  protoOf(UpgradedTowerSpeedEvent).toString = function () {
    return 'UpgradedTowerSpeedEvent(towerId=' + new EntityId(this.qhb_1) + ', newWeaponSpeedMillis=' + this.rhb_1 + ', newAttacksPerSecond=' + this.shb_1 + ', newSpeedUpgrade=' + this.thb_1 + ')';
  };
  protoOf(UpgradedTowerSpeedEvent).hashCode = function () {
    var result = EntityId__hashCode_impl_1s8fhw(this.qhb_1);
    result = imul(result, 31) + getNumberHashCode(this.rhb_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.shb_1) | 0;
    result = imul(result, 31) + this.thb_1 | 0;
    return result;
  };
  protoOf(UpgradedTowerSpeedEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UpgradedTowerSpeedEvent))
      return false;
    var tmp0_other_with_cast = other instanceof UpgradedTowerSpeedEvent ? other : THROW_CCE();
    if (!(this.qhb_1 === tmp0_other_with_cast.qhb_1))
      return false;
    if (!equals(this.rhb_1, tmp0_other_with_cast.rhb_1))
      return false;
    if (!equals(this.shb_1, tmp0_other_with_cast.shb_1))
      return false;
    if (!(this.thb_1 === tmp0_other_with_cast.thb_1))
      return false;
    return true;
  };
  function onMouseEvent($this, event) {
    var tmp0_subject = event.o7e_1;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 7) {
      var tmp$ret$0;
      // Inline function 'korlibs.event.MouseEvent.scrollDeltaYPixels' call
      tmp$ret$0 = event.i7f(ScrollDeltaMode_PIXEL_getInstance());
      $this.chc(tmp$ret$0);
    } else
    ;
  }
  function zoomOutCamera($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = $this.ahc_1;
    var tmp1_max = $this.uhb_1.qbl().t42() - 0.04;
    tmp$ret$0 = Math.max(tmp0_max, tmp1_max);
    var newZoomFactor = tmp$ret$0;
    $this.dhc(newZoomFactor);
  }
  function zoomInCamera($this) {
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = $this.bhc_1;
    var tmp1_min = $this.uhb_1.qbl().t42() + 0.04;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var newZoomFactor = tmp$ret$0;
    $this.dhc(newZoomFactor);
  }
  function CameraInputProcessor$slambda(this$0, resultContinuation) {
    this.mhc_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(CameraInputProcessor$slambda).ohc = function (it, $completion) {
    var tmp = this.phc(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CameraInputProcessor$slambda).g8 = function (p1, $completion) {
    return this.ohc(p1 instanceof LeftControlAndMinus ? p1 : THROW_CCE(), $completion);
  };
  protoOf(CameraInputProcessor$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          zoomOutCamera(this.mhc_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(CameraInputProcessor$slambda).phc = function (it, completion) {
    var i = new CameraInputProcessor$slambda(this.mhc_1, completion);
    i.nhc_1 = it;
    return i;
  };
  function CameraInputProcessor$slambda_0(this$0, resultContinuation) {
    var i = new CameraInputProcessor$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ohc(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function CameraInputProcessor$slambda_1(this$0, resultContinuation) {
    this.yhc_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(CameraInputProcessor$slambda_1).ahd = function (it, $completion) {
    var tmp = this.bhd(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(CameraInputProcessor$slambda_1).g8 = function (p1, $completion) {
    return this.ahd(p1 instanceof LeftControlAndEqual ? p1 : THROW_CCE(), $completion);
  };
  protoOf(CameraInputProcessor$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          zoomInCamera(this.yhc_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(CameraInputProcessor$slambda_1).bhd = function (it, completion) {
    var i = new CameraInputProcessor$slambda_1(this.yhc_1, completion);
    i.zhc_1 = it;
    return i;
  };
  function CameraInputProcessor$slambda_2(this$0, resultContinuation) {
    var i = new CameraInputProcessor$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ahd(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function CameraInputProcessor$setup$lambda(this$0) {
    return function (it) {
      onMouseEvent(this$0, it);
      return Unit_getInstance();
    };
  }
  function CameraInputProcessor(view, engine) {
    this.uhb_1 = view;
    this.vhb_1 = engine;
    this.whb_1 = 0.0;
    this.xhb_1 = 0.0;
    this.yhb_1 = false;
    this.zhb_1 = Companion_getInstance_8().bgd_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.vhb_1.dgo_1;
    var tmp1_register = CameraInputProcessor$slambda_0(this, null);
    tmp$ret$0 = tmp0_register.cg7(getKClass(LeftControlAndMinus), tmp1_register);
    var tmp$ret$1;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.vhb_1.dgo_1;
    var tmp3_register = CameraInputProcessor$slambda_2(this, null);
    tmp$ret$1 = tmp2_register.cg7(getKClass(LeftControlAndEqual), tmp3_register);
    this.ahc_1 = 0.2;
    this.bhc_1 = 2.0;
  }
  protoOf(CameraInputProcessor).bhb = function (eventListener) {
    var tmp = [Type_DRAG_getInstance(), Type_UP_getInstance(), Type_DOWN_getInstance(), Type_SCROLL_getInstance()];
    eventListener.a7d(tmp, CameraInputProcessor$setup$lambda(this));
  };
  protoOf(CameraInputProcessor).chc = function (amountY) {
    if (amountY > -0.5) {
      zoomOutCamera(this);
    } else if (amountY < 0.5) {
      zoomInCamera(this);
    }
  };
  protoOf(CameraInputProcessor).dhc = function (newZoomFactor) {
    var prevZoomFactor = this.uhb_1.qbl();
    this.uhb_1.pbl(toScale(newZoomFactor));
    var tmp0_this = this.uhb_1;
    tmp0_this.cbl(tmp0_this.e6g() + times(this.uhb_1.x18(), minus(prevZoomFactor, newZoomFactor)) / 2);
    var tmp1_this = this.uhb_1;
    tmp1_this.dbl(tmp1_this.f6g() + times(this.uhb_1.y18(), minus(prevZoomFactor, newZoomFactor)) / 2);
  };
  function PlacedEntityEvent(entityType) {
    this.chd_1 = entityType;
  }
  protoOf(PlacedEntityEvent).toString = function () {
    return 'PlacedEntityEvent(entityType=' + this.chd_1 + ')';
  };
  protoOf(PlacedEntityEvent).hashCode = function () {
    return this.chd_1.hashCode();
  };
  protoOf(PlacedEntityEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof PlacedEntityEvent))
      return false;
    var tmp0_other_with_cast = other instanceof PlacedEntityEvent ? other : THROW_CCE();
    if (!this.chd_1.equals(tmp0_other_with_cast.chd_1))
      return false;
    return true;
  };
  function onMouseEvent_0($this, views, event) {
    if (!$this.ghd_1.shd_1 ? true : !$this.khd_1.q(event.o7e_1)) {
      return Unit_getInstance();
    }
    if (event.o7e_1.equals(Type_DOWN_getInstance()) ? event.s7e_1.equals(MouseButton_RIGHT_getInstance()) : false) {
      $this.phd_1 = true;
      return Unit_getInstance();
    }
    if (event.o7e_1.equals(Type_UP_getInstance()) ? event.s7e_1.equals(MouseButton_RIGHT_getInstance()) : false) {
      $this.phd_1 = false;
      return Unit_getInstance();
    }
    if ($this.phd_1) {
      return Unit_getInstance();
    }
    var globalXY = views.dak();
    var tmp0_container = $this.jhd_1.chh(globalXY.p3r_1, globalXY.q3r_1);
    var gridX = tmp0_container.c5();
    var gridY = tmp0_container.d5();
    if ($this.ghd_1.thd_1.equals(MapEntityType_ROCK_getInstance())) {
      handleRockPlacement($this, event.o7e_1, gridX, gridY);
    } else if (setOf([MapEntityType_START_getInstance(), MapEntityType_FINISH_getInstance(), MapEntityType_CHECKPOINT_getInstance(), MapEntityType_TELEPORT_IN_getInstance(), MapEntityType_TELEPORT_OUT_getInstance(), MapEntityType_TOWER_getInstance(), MapEntityType_SUPPLY_DEPOT_getInstance()]).q($this.ghd_1.thd_1)) {
      var tmp = Companion_getInstance_9().zgh($this.ghd_1.thd_1);
      var tmp1_container = tmp instanceof Fixed ? tmp : THROW_CCE();
      var entityWidth = tmp1_container.ogb();
      var entityHeight = tmp1_container.pgb();
      var tmp2_container = $this.jhd_1.dhh(gridX, gridY, entityWidth, entityHeight);
      var gridXInt = tmp2_container.ogb();
      var gridYInt = tmp2_container.pgb();
      $this.jhd_1.ehh(gridXInt, gridYInt, entityWidth, entityHeight);
      if (event.o7e_1.equals(Type_UP_getInstance())) {
        var entityToAdd = createEntityToAdd($this, $this.ghd_1.thd_1, gridXInt, gridYInt);
        var tmp3_subject = $this.ghd_1.thd_1;
        var tmp0 = tmp3_subject.b2_1;
        switch (tmp0) {
          case 0:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 1:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 2:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 3:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 5:
            $this.qhd_1 = entityToAdd;
            $this.ghd_1.thd_1 = MapEntityType_TELEPORT_OUT_getInstance();
            $this.jhd_1.fhh(entityToAdd);
            $this.fhd_1.dgo_1.bg7(new NotificationTextUpdateEvent($this.ihd_1.pgs($this.ghd_1.thd_1)));
            return Unit_getInstance();
          case 6:
            // Inline function 'kotlin.require' call
            var tmp0_require = !($this.qhd_1 == null);
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'kotlin.require' call
            // Inline function 'kotlin.contracts.contract' call
            if (!tmp0_require) {
              var tmp$ret$0;
              // Inline function 'kotlin.require.<anonymous>' call
              tmp$ret$0 = 'Failed requirement.';
              var message = tmp$ret$0;
              throw IllegalArgumentException_init_$Create$(toString(message));
            }

            $this.ihd_1.mgs([ensureNotNull($this.qhd_1), entityToAdd]);
            $this.fhd_1.dgo_1.bg7(new PlacedEntityEvent($this.ghd_1.thd_1));
            $this.jhd_1.ghh();
            return Unit_getInstance();
          case 7:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 8:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 9:
            // Inline function 'kotlin.TODO' call

            throw new NotImplementedError();
          case 4:
            var it = checkCanPlaceTowerEntity($this.fhd_1, entityToAdd);
            if (it instanceof Errors) {
              $this.fhd_1.dgo_1.bg7(new InformErrorMessageEvent("Unable to place '" + $this.ghd_1.thd_1 + "': " + it.lgt()));
              return Unit_getInstance();
            } else {
              if (equals(it, Ok_getInstance()))
              ;
            }

            $this.ihd_1.mgs([entityToAdd]);
            $this.fhd_1.dgo_1.bg7(new PlacedEntityEvent($this.ghd_1.thd_1));
            ;
            break;
          case 10:
            var it_0 = checkCanPlaceEntity($this.fhd_1, entityToAdd);
            if (it_0 instanceof Errors) {
              $this.fhd_1.dgo_1.bg7(new InformErrorMessageEvent("Unable to place '" + $this.ghd_1.thd_1 + "': " + it_0.lgt()));
              return Unit_getInstance();
            } else {
              if (equals(it_0, Ok_getInstance()))
              ;
            }

            $this.ihd_1.mgs([entityToAdd]);
            $this.fhd_1.dgo_1.bg7(new PlacedEntityEvent($this.ghd_1.thd_1));
            ;
            break;
        }
      }
    } else {
      // Inline function 'kotlin.TODO' call
      var tmp1_TODO = 'Unsupported entity type: ' + $this.ghd_1.thd_1;
      throw new NotImplementedError('An operation is not implemented: ' + tmp1_TODO);
    }
  }
  function createEntityToAdd($this, entityType, gridXInt, gridYInt) {
    var position = GameUnit__tup_impl_pf7bck(gridXInt, gridYInt);
    var tmp0_subject = entityType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = StagingEntityUtils_getInstance().hhh(position);
        break;
      case 1:
        tmp = StagingEntityUtils_getInstance().ihh(position);
        break;
      case 2:
        tmp = StagingEntityUtils_getInstance().jhh($this.ihd_1.sg9(), position);
        break;
      case 3:
        throw new NotImplementedError();
      case 4:
        tmp = StagingEntityUtils_getInstance().khh(position, $this.hhd_1.ogo_1);
        break;
      case 5:
        tmp = StagingEntityUtils_getInstance().lhh($this.ihd_1.igs(), position);
        break;
      case 6:
        tmp = StagingEntityUtils_getInstance().mhh($this.ihd_1.igs(), position);
        break;
      case 7:
        throw new NotImplementedError();
      case 8:
        throw new NotImplementedError();
      case 9:
        throw new NotImplementedError();
      case 10:
        tmp = StagingEntityUtils_getInstance().nhh(position, $this.hhd_1.rgo_1);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function handleRockPlacement($this, eventType, gridX, gridY) {
    if (eventType.equals(Type_DOWN_getInstance()) ? true : eventType.equals(Type_MOVE_getInstance())) {
      $this.lhd_1 = gridX;
      $this.mhd_1 = gridY;
    }
    $this.nhd_1 = gridX;
    $this.ohd_1 = gridY;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = $this.lhd_1;
    var tmp1_min = $this.nhd_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var lowGridX = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.math.min' call
    var tmp2_min = $this.mhd_1;
    var tmp3_min = $this.ohd_1;
    tmp$ret$1 = Math.min(tmp2_min, tmp3_min);
    var lowGridY = tmp$ret$1;
    var tmp$ret$3;
    // Inline function 'kotlin.math.ceil' call
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp4_max = $this.lhd_1;
    var tmp5_max = $this.nhd_1;
    tmp$ret$2 = Math.max(tmp4_max, tmp5_max);
    var tmp6_ceil = tmp$ret$2;
    tmp$ret$3 = Math.ceil(tmp6_ceil);
    var highGridX = tmp$ret$3;
    var tmp$ret$5;
    // Inline function 'kotlin.math.ceil' call
    var tmp$ret$4;
    // Inline function 'kotlin.math.max' call
    var tmp7_max = $this.mhd_1;
    var tmp8_max = $this.ohd_1;
    tmp$ret$4 = Math.max(tmp7_max, tmp8_max);
    var tmp9_ceil = tmp$ret$4;
    tmp$ret$5 = Math.ceil(tmp9_ceil);
    var highGridY = tmp$ret$5;
    var tmp$ret$6;
    // Inline function 'kotlin.math.max' call
    var tmp10_max = numberToInt(highGridX) - numberToInt(lowGridX) | 0;
    tmp$ret$6 = Math.max(tmp10_max, 1);
    var width = toGameUnit(tmp$ret$6);
    var tmp$ret$7;
    // Inline function 'kotlin.math.max' call
    var tmp11_max = numberToInt(highGridY) - numberToInt(lowGridY) | 0;
    tmp$ret$7 = Math.max(tmp11_max, 1);
    var height = toGameUnit(tmp$ret$7);
    var roundedGridX = toGameUnit(numberToInt(lowGridX));
    var roundedGridY = toGameUnit(numberToInt(lowGridY));
    if (eventType.equals(Type_UP_getInstance())) {
      $this.ihd_1.mgs([StagingEntityUtils_getInstance().ohh(new RectangleEntity(roundedGridX, roundedGridY, width, height))]);
      handleRockPlacement($this, Type_MOVE_getInstance(), gridX, gridY);
    } else {
      $this.jhd_1.ehh(roundedGridX, roundedGridY, width, height);
    }
  }
  function EditorPlacementInputProcessor$editorState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(EditorState);
  }
  function EditorPlacementInputProcessor$gameplayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function EditorPlacementInputProcessor$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function EditorPlacementInputProcessor$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function EditorPlacementInputProcessor$setup$lambda(this$0) {
    return function (it) {
      onMouseEvent_0(this$0, this$0.dhd_1, it);
      return Unit_getInstance();
    };
  }
  function EditorPlacementInputProcessor(views, view, engine) {
    this.dhd_1 = views;
    this.ehd_1 = view;
    this.fhd_1 = engine;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.fhd_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(EditorState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof EditorState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(EditorPlacementInputProcessor$editorState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ghd_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.fhd_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameplayState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorPlacementInputProcessor$gameplayState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.hhd_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.fhd_1.hgo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof GameMapApi) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorPlacementInputProcessor$gameMapApi$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.ihd_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_2 = this.fhd_1.hgo_1;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_9 = tmp0_getSingleton_2.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$6 = (tmp_9 == null ? true : tmp_9 instanceof UIMapV2) ? tmp_9 : THROW_CCE();
    var tmp0_elvis_lhs_2 = tmp$ret$6;
    var tmp_10;
    if (tmp0_elvis_lhs_2 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorPlacementInputProcessor$uiMap$lambda);
    } else {
      tmp_10 = tmp0_elvis_lhs_2;
    }
    tmp$ret$7 = tmp_10;
    tmp_8.jhd_1 = tmp$ret$7;
    this.khd_1 = setOf([Type_DOWN_getInstance(), Type_DRAG_getInstance(), Type_UP_getInstance(), Type_MOVE_getInstance()]);
    this.lhd_1 = 0.0;
    this.mhd_1 = 0.0;
    this.nhd_1 = 0.0;
    this.ohd_1 = 0.0;
    this.phd_1 = false;
    this.qhd_1 = null;
  }
  protoOf(EditorPlacementInputProcessor).bhb = function (eventListener) {
    var tmp = [Type_DOWN_getInstance(), Type_DRAG_getInstance(), Type_UP_getInstance(), Type_MOVE_getInstance()];
    eventListener.a7d(tmp, EditorPlacementInputProcessor$setup$lambda(this));
  };
  function KeyInputProcessor$setup$lambda(this$0) {
    return function (it) {
      this$0.shh(it);
      return Unit_getInstance();
    };
  }
  function KeyInputProcessor(view, engine) {
    this.phh_1 = view;
    this.qhh_1 = engine;
    this.rhh_1 = false;
  }
  protoOf(KeyInputProcessor).bhb = function (eventListener) {
    var tmp = Companion_getInstance_10().r7d_1.slice();
    eventListener.a7d(tmp, KeyInputProcessor$setup$lambda(this));
  };
  protoOf(KeyInputProcessor).shh = function (event) {
    println(event);
    var tmp0_subject = event.u7d_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 1:
        var tmp1_subject = event.w7d_1;
        if (tmp1_subject.equals(Companion_getInstance_11().y7j())) {
          this.rhh_1 = true;
        } else if (tmp1_subject.equals(Key_MINUS_getInstance())) {
          if (this.rhh_1) {
            this.qhh_1.dgo_1.bg7(LeftControlAndMinus_getInstance());
          }
        } else if (tmp1_subject.equals(Key_EQUAL_getInstance())) {
          if (this.rhh_1) {
            this.qhh_1.dgo_1.bg7(LeftControlAndEqual_getInstance());
          }
        } else {
        }

        break;
      case 0:
        var tmp2_subject = event.w7d_1;
        if (tmp2_subject.equals(Key_ESCAPE_getInstance())) {
          this.qhh_1.dgo_1.bg7(EscapeButtonActionEvent_getInstance());
        } else if (tmp2_subject.equals(Companion_getInstance_11().y7j())) {
          this.rhh_1 = false;
        } else {
        }

        break;
      case 2:
        ;
        break;
    }
  };
  var MouseDragStateType_UNKNOWN_instance;
  var MouseDragStateType_START_instance;
  var MouseDragStateType_DRAG_instance;
  var MouseDragStateType_END_instance;
  var MouseDragStateType_entriesInitialized;
  function MouseDragStateType_initEntries() {
    if (MouseDragStateType_entriesInitialized)
      return Unit_getInstance();
    MouseDragStateType_entriesInitialized = true;
    MouseDragStateType_UNKNOWN_instance = new MouseDragStateType('UNKNOWN', 0);
    MouseDragStateType_START_instance = new MouseDragStateType('START', 1);
    MouseDragStateType_DRAG_instance = new MouseDragStateType('DRAG', 2);
    MouseDragStateType_END_instance = new MouseDragStateType('END', 3);
  }
  function MouseDragStateType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function MouseDragStateSettings(isEnabled, allowLeftClickDragging, allowRightClickDragging, allowMiddleClickDragging) {
    isEnabled = isEnabled === VOID ? true : isEnabled;
    allowLeftClickDragging = allowLeftClickDragging === VOID ? true : allowLeftClickDragging;
    allowRightClickDragging = allowRightClickDragging === VOID ? true : allowRightClickDragging;
    allowMiddleClickDragging = allowMiddleClickDragging === VOID ? true : allowMiddleClickDragging;
    this.thh_1 = isEnabled;
    this.uhh_1 = allowLeftClickDragging;
    this.vhh_1 = allowRightClickDragging;
    this.whh_1 = allowMiddleClickDragging;
  }
  protoOf(MouseDragStateSettings).toString = function () {
    return 'MouseDragStateSettings(isEnabled=' + this.thh_1 + ', allowLeftClickDragging=' + this.uhh_1 + ', allowRightClickDragging=' + this.vhh_1 + ', allowMiddleClickDragging=' + this.whh_1 + ')';
  };
  protoOf(MouseDragStateSettings).hashCode = function () {
    var result = this.thh_1 | 0;
    result = imul(result, 31) + (this.uhh_1 | 0) | 0;
    result = imul(result, 31) + (this.vhh_1 | 0) | 0;
    result = imul(result, 31) + (this.whh_1 | 0) | 0;
    return result;
  };
  protoOf(MouseDragStateSettings).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MouseDragStateSettings))
      return false;
    var tmp0_other_with_cast = other instanceof MouseDragStateSettings ? other : THROW_CCE();
    if (!(this.thh_1 === tmp0_other_with_cast.thh_1))
      return false;
    if (!(this.uhh_1 === tmp0_other_with_cast.uhh_1))
      return false;
    if (!(this.vhh_1 === tmp0_other_with_cast.vhh_1))
      return false;
    if (!(this.whh_1 === tmp0_other_with_cast.whh_1))
      return false;
    return true;
  };
  function getState($this, event) {
    var tmp0_subject = event.o7e_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 3:
        if (event.s7e_1.equals(MouseButton_LEFT_getInstance())) {
          return MouseDragStateType_START_getInstance();
        }

        if (event.s7e_1.equals(MouseButton_MIDDLE_getInstance())) {
          return MouseDragStateType_START_getInstance();
        }

        if (event.s7e_1.equals(MouseButton_RIGHT_getInstance())) {
          return MouseDragStateType_START_getInstance();
        }

        return MouseDragStateType_UNKNOWN_getInstance();
      case 2:
        if (event.s7e_1.equals(MouseButton_LEFT_getInstance())) {
          return MouseDragStateType_END_getInstance();
        }

        if (event.s7e_1.equals(MouseButton_MIDDLE_getInstance())) {
          return MouseDragStateType_END_getInstance();
        }

        if (event.s7e_1.equals(MouseButton_RIGHT_getInstance())) {
          return MouseDragStateType_END_getInstance();
        }

        return MouseDragStateType_UNKNOWN_getInstance();
      case 1:
        return MouseDragStateType_DRAG_getInstance();
      default:
        return MouseDragStateType_UNKNOWN_getInstance();
    }
  }
  function reset($this) {
    $this.hhi_1 = false;
  }
  function MouseDragInputProcessor$setup$lambda(this$0) {
    return function (it) {
      this$0.ihi(it);
      return Unit_getInstance();
    };
  }
  function MouseDragInputProcessor(views, view, settings) {
    settings = settings === VOID ? new MouseDragStateSettings() : settings;
    this.xhh_1 = views;
    this.yhh_1 = view;
    this.zhh_1 = settings;
    this.ahi_1 = true;
    this.bhi_1 = new DraggableInfo(this.yhh_1);
    this.chi_1 = setOf([Type_DOWN_getInstance(), Type_DRAG_getInstance(), Type_UP_getInstance()]);
    this.dhi_1 = MouseButton_LEFT_getInstance();
    this.ehi_1 = Vector2_init_$Create$();
    this.fhi_1 = 0.0;
    this.ghi_1 = 0.0;
    this.hhi_1 = false;
  }
  protoOf(MouseDragInputProcessor).bhb = function (eventListener) {
    var tmp = [Type_DOWN_getInstance(), Type_DRAG_getInstance(), Type_UP_getInstance()];
    eventListener.a7d(tmp, MouseDragInputProcessor$setup$lambda(this));
  };
  protoOf(MouseDragInputProcessor).jhi = function (fn) {
    fn(this.zhh_1);
    reset(this);
  };
  protoOf(MouseDragInputProcessor).ihi = function (event) {
    if (!this.zhh_1.thh_1)
      return Unit_getInstance();
    if (!this.chi_1.q(event.o7e_1)) {
      return Unit_getInstance();
    }
    if (!this.zhh_1.uhh_1 ? event.s7e_1.equals(MouseButton_LEFT_getInstance()) : false) {
      return Unit_getInstance();
    }
    if (!this.zhh_1.vhh_1 ? event.s7e_1.equals(MouseButton_RIGHT_getInstance()) : false) {
      return Unit_getInstance();
    }
    if (this.hhi_1) {
      if (!event.o7e_1.equals(Type_DRAG_getInstance()) ? !event.s7e_1.equals(this.dhi_1) : false) {
        return Unit_getInstance();
      }
    }
    var state = getState(this, event);
    if (state.equals(MouseDragStateType_UNKNOWN_getInstance())) {
      // Inline function 'kotlin.require' call
      var tmp0_require = !this.hhi_1;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'kotlin.require' call
      // Inline function 'kotlin.contracts.contract' call
      if (!tmp0_require) {
        var tmp$ret$0;
        // Inline function 'kotlin.require.<anonymous>' call
        tmp$ret$0 = 'Failed requirement.';
        var message = tmp$ret$0;
        throw IllegalArgumentException_init_$Create$(toString(message));
      }
      return Unit_getInstance();
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = !state.equals(MouseDragStateType_UNKNOWN_getInstance());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    this.ehi_1 = this.xhh_1.dak();
    var tmp0_subject = state;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 1:
        this.dhi_1 = event.s7e_1;
        this.hhi_1 = true;
        this.fhi_1 = this.ehi_1.p3r_1;
        this.ghi_1 = this.ehi_1.q3r_1;
        this.bhi_1.p1g();
        ;
        break;
      case 3:
        this.hhi_1 = false;
        break;
      default:
        ;
        break;
    }
    var deltaX = this.ehi_1.p3r_1 - this.fhi_1;
    var deltaY = this.ehi_1.q3r_1 - this.ghi_1;
    this.bhi_1.gag(deltaX, deltaY, state.equals(MouseDragStateType_START_getInstance()), state.equals(MouseDragStateType_END_getInstance()), Companion_getInstance_12().s17(), this.fhi_1, this.ghi_1, this.ehi_1.p3r_1, this.ehi_1.q3r_1);
    if (this.hhi_1) {
      this.khi(event);
    }
  };
  protoOf(MouseDragInputProcessor).khi = function (event) {
    var state = getState(this, event);
    var view = this.yhh_1;
    if (state.equals(MouseDragStateType_START_getInstance())) {
      this.bhi_1.cah_1 = view.e30();
    }
    this.bhi_1.dah_1 = view.e30();
    this.bhi_1.eah_1 = new Vector2(this.bhi_1.gah() + this.bhi_1.bag(view), this.bhi_1.hah() + this.bhi_1.dag(view));
    this.bhi_1.fah_1 = new Vector2(this.bhi_1.kah() - this.bhi_1.iah(), this.bhi_1.lah() - this.bhi_1.jah());
    if (this.ahi_1) {
      xy_0(view, this.bhi_1.eah_1);
    }
  };
  protoOf(MouseDragInputProcessor).toString = function () {
    return 'MouseDragInputProcessor(views=' + this.xhh_1 + ', view=' + this.yhh_1 + ', settings=' + this.zhh_1 + ')';
  };
  protoOf(MouseDragInputProcessor).hashCode = function () {
    var result = hashCode(this.xhh_1);
    result = imul(result, 31) + hashCode(this.yhh_1) | 0;
    result = imul(result, 31) + this.zhh_1.hashCode() | 0;
    return result;
  };
  protoOf(MouseDragInputProcessor).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MouseDragInputProcessor))
      return false;
    var tmp0_other_with_cast = other instanceof MouseDragInputProcessor ? other : THROW_CCE();
    if (!equals(this.xhh_1, tmp0_other_with_cast.xhh_1))
      return false;
    if (!equals(this.yhh_1, tmp0_other_with_cast.yhh_1))
      return false;
    if (!this.zhh_1.equals(tmp0_other_with_cast.zhh_1))
      return false;
    return true;
  };
  function MouseDragStateType_UNKNOWN_getInstance() {
    MouseDragStateType_initEntries();
    return MouseDragStateType_UNKNOWN_instance;
  }
  function MouseDragStateType_START_getInstance() {
    MouseDragStateType_initEntries();
    return MouseDragStateType_START_instance;
  }
  function MouseDragStateType_DRAG_getInstance() {
    MouseDragStateType_initEntries();
    return MouseDragStateType_DRAG_instance;
  }
  function MouseDragStateType_END_getInstance() {
    MouseDragStateType_initEntries();
    return MouseDragStateType_END_instance;
  }
  function ObjectPlacementInputProcessor$objectPlacementComponent$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(ObjectPlacementEComponent);
  }
  function ObjectPlacementInputProcessor$gameMapComponent$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapControllerEComponent);
  }
  function ObjectPlacementInputProcessor$setup$lambda(this$0) {
    return function (it) {
      this$0.ihi(it);
      return Unit_getInstance();
    };
  }
  function ObjectPlacementInputProcessor(view, uiMapView, engine, eventBus) {
    this.lhi_1 = view;
    this.mhi_1 = uiMapView;
    this.nhi_1 = engine;
    this.ohi_1 = eventBus;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.nhi_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(ObjectPlacementEComponent));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof ObjectPlacementEComponent) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(ObjectPlacementInputProcessor$objectPlacementComponent$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.phi_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.nhi_1.hgo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameMapControllerEComponent));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameMapControllerEComponent) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(ObjectPlacementInputProcessor$gameMapComponent$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.qhi_1 = tmp$ret$3;
  }
  protoOf(ObjectPlacementInputProcessor).bhb = function (eventListener) {
    var tmp = Companion_getInstance_13().h7e_1.slice();
    eventListener.a7d(tmp, ObjectPlacementInputProcessor$setup$lambda(this));
  };
  protoOf(ObjectPlacementInputProcessor).ihi = function (event) {
    var tmp0_container = this.mhi_1.bhm(event.q7e_1, event.r7e_1);
    var gridX = tmp0_container.c5();
    var gridY = tmp0_container.d5();
    println(event);
    var tmp1_subject = event.o7e_1;
    var tmp0 = tmp1_subject.b2_1;
    switch (tmp0) {
      case 3:
        println(event);
        this.chm(event.s7e_1);
        ;
        break;
      case 0:
        this.dhm(gridX, gridY);
        ;
        break;
      default:
        // Inline function 'kotlin.TODO' call

        throw new NotImplementedError();
    }
    this.mhi_1.ehm(this.phi_1.yha_1);
  };
  protoOf(ObjectPlacementInputProcessor).chm = function (button) {
    if (button.equals(MouseButton_LEFT_getInstance())) {
      var pointerAction = this.phi_1.yha_1;
      var tmp0_subject = pointerAction;
      if (equals(tmp0_subject, Inactive_getInstance())) {
        return Unit_getInstance();
      } else {
        if (tmp0_subject instanceof HighlightForPlacement) {
          if (pointerAction.lhm_1 == null) {
            return Unit_getInstance();
          }
          var tmp1_subject = pointerAction.khm_1;
          if (tmp1_subject instanceof Start) {
            // Inline function 'kotlin.TODO' call
            throw new NotImplementedError();
          } else {
            if (tmp1_subject instanceof Finish) {
              // Inline function 'kotlin.TODO' call
              throw new NotImplementedError();
            } else {
              if (tmp1_subject instanceof TeleportIn) {
                // Inline function 'kotlin.TODO' call
                throw new NotImplementedError();
              } else {
                if (tmp1_subject instanceof TeleportOut) {
                  // Inline function 'kotlin.TODO' call
                  throw new NotImplementedError();
                } else {
                  if (tmp1_subject instanceof Rock) {
                    println('Placing: ' + pointerAction);
                    this.qhi_1.og9(pointerAction.khm_1.cgh(ensureNotNull(pointerAction.lhm_1)));
                  } else {
                    if (tmp1_subject instanceof Checkpoint) {
                      // Inline function 'kotlin.TODO' call
                      throw new NotImplementedError();
                    } else {
                      var tmp;
                      if (tmp1_subject instanceof Tower) {
                        tmp = true;
                      } else {
                        tmp = tmp1_subject instanceof SmallBlocker;
                      }
                      if (tmp) {
                        var entityAtPlace = pointerAction.khm_1.cgh(ensureNotNull(pointerAction.lhm_1));
                        if (!this.qhi_1.uga(entityAtPlace)) {
                          this.qhi_1.og9(entityAtPlace);
                        }
                      } else {
                        throw new NotImplementedError();
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
          if (tmp0_subject instanceof RemoveEntityAtPlace) {
            var data = pointerAction.ghm_1;
            if (!(data == null)) {
              this.qhi_1.tga(data.jhm_1);
              pointerAction.ghm_1 = null;
              this.mhi_1.ehm(pointerAction);
            }
          } else {
            var tmp0_TODO = 'Unsupported: ' + pointerAction;
            throw new NotImplementedError('An operation is not implemented: ' + tmp0_TODO);
          }
        }
      }
    }
  };
  protoOf(ObjectPlacementInputProcessor).dhm = function (gridX, gridY) {
    var pointerAction = this.phi_1.yha_1;
    var tmp0_subject = pointerAction;
    if (equals(tmp0_subject, Inactive_getInstance())) {
      return Unit_getInstance();
    } else {
      if (tmp0_subject instanceof HighlightForPlacement) {
        var currentEntity = pointerAction.khm_1;
        var tmp1_container = this.mhi_1.mhm(gridX, gridY, currentEntity.lg8(), currentEntity.mg8());
        var gridXToInt = tmp1_container.ogb();
        var gridYToInt = tmp1_container.pgb();
        if (!until(0, this.qhi_1.lg8()).ygt(gridXToInt) ? true : !until(0, this.qhi_1.mg8()).ygt(gridYToInt)) {
          pointerAction.lhm_1 = null;
          return Unit_getInstance();
        }
        pointerAction.lhm_1 = new GameUnitTuple(gridXToInt, gridYToInt);
        this.mhi_1.ehm(pointerAction);
      } else {
        if (tmp0_subject instanceof RemoveEntityAtPlace) {
          var tmp$ret$0;
          // Inline function 'kotlin.math.floor' call
          tmp$ret$0 = Math.floor(gridX);
          var roundedGridX = toGameUnit(numberToInt(tmp$ret$0));
          var tmp$ret$1;
          // Inline function 'kotlin.math.floor' call
          tmp$ret$1 = Math.floor(gridY);
          var roundedGridY = toGameUnit(numberToInt(tmp$ret$1));
          if (!(pointerAction.ghm_1 == null)) {
            var removeEntityData = ensureNotNull(pointerAction.ghm_1);
            if (equals(removeEntityData.hhm_1, roundedGridX) ? equals(removeEntityData.ihm_1, roundedGridY) : false) {
              return Unit_getInstance();
            }
          }
          var tmp2_subject = pointerAction.fhm_1;
          var tmp0 = tmp2_subject.b2_1;
          var tmp;
          switch (tmp0) {
            case 0:
              throw new NotImplementedError();
            case 1:
              throw new NotImplementedError();
            case 2:
              throw new NotImplementedError();
            case 3:
              tmp = this.qhi_1.cgb(roundedGridX, roundedGridY);
              break;
            case 4:
              tmp = this.qhi_1.dgb(roundedGridX, roundedGridY);
              break;
            case 5:
              throw new NotImplementedError();
            case 6:
              throw new NotImplementedError();
            case 7:
              throw new NotImplementedError();
            case 8:
              throw new NotImplementedError();
            case 9:
              throw new NotImplementedError();
            case 10:
              tmp = this.qhi_1.egb(roundedGridX, roundedGridY);
              break;
            default:
              noWhenBranchMatchedException();
              break;
          }
          var firstEntityAtPoint = tmp;
          if (firstEntityAtPoint == null) {
            pointerAction.ghm_1 = null;
          } else {
            pointerAction.ghm_1 = new RemoveEntityData(roundedGridX, roundedGridY, firstEntityAtPoint);
          }
        } else {
          var tmp0_TODO = 'Unsupported: ' + pointerAction;
          throw new NotImplementedError('An operation is not implemented: ' + tmp0_TODO);
        }
      }
    }
  };
  function SelectorMouseProcessorV2$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function SelectorMouseProcessorV2$deadUIZonesState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(DeadUIZonesState);
  }
  function SelectorMouseProcessorV2$setup$lambda(this$0) {
    return function (it) {
      this$0.ihi(it);
      return Unit_getInstance();
    };
  }
  function SelectorMouseProcessorV2$onMouseEvent$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(PreSelectionComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function SelectorMouseProcessorV2$onMouseEvent$lambda_0($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(SelectedComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function SelectorMouseProcessorV2$onMouseEvent$lambda_1($this$modifyEntity) {
    $this$modifyEntity.cg3(SelectedComponent_getInstance());
    return Unit_getInstance();
  }
  function SelectorMouseProcessorV2$onMouseEvent$lambda_2($this$modifyEntity) {
    $this$modifyEntity.cg3(PreSelectionComponent_getInstance());
    return Unit_getInstance();
  }
  function SelectorMouseProcessorV2(views, view, engine, isEnabled) {
    isEnabled = isEnabled === VOID ? true : isEnabled;
    this.nhm_1 = views;
    this.ohm_1 = view;
    this.phm_1 = engine;
    this.qhm_1 = isEnabled;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.phm_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapApi) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(SelectorMouseProcessorV2$gameMapApi$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.rhm_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.phm_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(DeadUIZonesState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof DeadUIZonesState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(SelectorMouseProcessorV2$deadUIZonesState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.shm_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = this.ohm_1;
    var tmp3_solidRect = Colors_getInstance().o4s_1;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$(0, 0), tmp3_solidRect);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, tmp2_solidRect);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$4 = Unit_getInstance();
    tmp$ret$5 = tmp0_apply;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    var tmp4_visible = alpha(tmp$ret$7, 0.25);
    var tmp$ret$8;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp4_visible.u96(false);
    tmp$ret$8 = tmp4_visible;
    tmp$ret$9 = tmp$ret$8;
    tmp_5.thm_1 = tmp$ret$9;
    this.uhm_1 = false;
    this.vhm_1 = false;
    this.whm_1 = Vector2_init_$Create$();
    this.xhm_1 = Vector2_init_$Create$();
  }
  protoOf(SelectorMouseProcessorV2).p1g = function () {
    this.uhm_1 = false;
    this.vhm_1 = false;
  };
  protoOf(SelectorMouseProcessorV2).bhb = function (eventListener) {
    var tmp = Companion_getInstance_13().h7e_1.slice();
    eventListener.a7d(tmp, SelectorMouseProcessorV2$setup$lambda(this));
  };
  protoOf(SelectorMouseProcessorV2).ihi = function (event) {
    if (!this.qhm_1)
      return Unit_getInstance();
    if (event.o7e_1.equals(Type_MOVE_getInstance()))
      return Unit_getInstance();
    if (event.o7e_1.equals(Type_DOWN_getInstance()) ? event.s7e_1.equals(MouseButton_LEFT_getInstance()) : false) {
      this.uhm_1 = true;
      this.vhm_1 = true;
      this.whm_1 = this.nhm_1.dak();
    }
    if (event.o7e_1.equals(Type_CLICK_getInstance()) ? event.s7e_1.equals(MouseButton_LEFT_getInstance()) : false) {
      this.p1g();
      return Unit_getInstance();
    }
    this.xhm_1 = this.nhm_1.dak();
    var tmp$ret$0;
    $l$block_0: {
      // Inline function 'kotlin.collections.any' call
      var tmp0_any = this.shm_1.yhm_1;
      var tmp;
      if (isInterface(tmp0_any, Collection)) {
        tmp = tmp0_any.p();
      } else {
        tmp = false;
      }
      if (tmp) {
        tmp$ret$0 = false;
        break $l$block_0;
      }
      var tmp0_iterator = tmp0_any.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
        var tmp_0;
        if (element.ibn(this.xhm_1)) {
          println('Hit view: ' + element);
          tmp_0 = true;
        } else {
          tmp_0 = false;
        }
        tmp$ret$1 = tmp_0;
        if (tmp$ret$1) {
          tmp$ret$0 = true;
          break $l$block_0;
        }
      }
      tmp$ret$0 = false;
    }
    if (tmp$ret$0) {
      return Unit_getInstance();
    }
    if (this.uhm_1) {
      println(trimIndent('\n                startPosition: ' + this.whm_1 + '\n                currentPosition: ' + this.xhm_1 + '\n            '));
      println('Dragging!');
      var tmp$ret$2;
      // Inline function 'kotlin.apply' call
      var tmp1_apply = this.thm_1;
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
      tmp1_apply.u96(true);
      tmp1_apply.ebm(this.xhm_1.p3r_1 - this.whm_1.p3r_1);
      tmp1_apply.gbm(this.xhm_1.q3r_1 - this.whm_1.q3r_1);
      xy_0(tmp1_apply, this.whm_1);
      tmp$ret$2 = tmp1_apply;
      var intersectingEntities = this.rhm_1.ogs(this.thm_1.vbn());
      println('intersectingEntities: ' + intersectingEntities);
      // Inline function 'kotlin.collections.forEach' call
      var tmp2_forEach = this.phm_1.ego_1.zgb_1.vg1();
      var tmp0_iterator_0 = tmp2_forEach.h();
      while (tmp0_iterator_0.j()) {
        var element_0 = tmp0_iterator_0.k().zg0_1;
        // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
        this.phm_1.ego_1.vgb_1.gg3(element_0, SelectorMouseProcessorV2$onMouseEvent$lambda);
      }
      if (event.o7e_1.equals(Type_UP_getInstance()) ? event.s7e_1.equals(MouseButton_LEFT_getInstance()) : false) {
        this.uhm_1 = false;
        this.thm_1.u96(false);
        // Inline function 'kotlin.collections.forEach' call
        var tmp3_forEach = this.phm_1.ego_1.agc_1.vg1();
        var tmp0_iterator_1 = tmp3_forEach.h();
        while (tmp0_iterator_1.j()) {
          var element_1 = tmp0_iterator_1.k().zg0_1;
          // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
          this.phm_1.ego_1.vgb_1.gg3(element_1, SelectorMouseProcessorV2$onMouseEvent$lambda_0);
        }
        // Inline function 'kotlin.collections.forEach' call
        var tmp0_iterator_2 = intersectingEntities.h();
        while (tmp0_iterator_2.j()) {
          var element_2 = tmp0_iterator_2.k().zg0_1;
          // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
          this.phm_1.ego_1.vgb_1.gg3(element_2, SelectorMouseProcessorV2$onMouseEvent$lambda_1);
        }
      } else {
        // Inline function 'kotlin.collections.forEach' call
        var tmp0_iterator_3 = intersectingEntities.h();
        while (tmp0_iterator_3.j()) {
          var element_3 = tmp0_iterator_3.k().zg0_1;
          // Inline function 'com.xenotactic.korge.input_processors.SelectorMouseProcessorV2.onMouseEvent.<anonymous>' call
          this.phm_1.ego_1.vgb_1.gg3(element_3, SelectorMouseProcessorV2$onMouseEvent$lambda_2);
        }
      }
    }
  };
  function RemoveEntityData(x, y, entity) {
    this.hhm_1 = x;
    this.ihm_1 = y;
    this.jhm_1 = entity;
  }
  protoOf(RemoveEntityData).toString = function () {
    return 'RemoveEntityData(x=' + new GameUnit(this.hhm_1) + ', y=' + new GameUnit(this.ihm_1) + ', entity=' + this.jhm_1 + ')';
  };
  protoOf(RemoveEntityData).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.hhm_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ihm_1) | 0;
    result = imul(result, 31) + hashCode(this.jhm_1) | 0;
    return result;
  };
  protoOf(RemoveEntityData).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemoveEntityData))
      return false;
    var tmp0_other_with_cast = other instanceof RemoveEntityData ? other : THROW_CCE();
    if (!equals(this.hhm_1, tmp0_other_with_cast.hhm_1))
      return false;
    if (!equals(this.ihm_1, tmp0_other_with_cast.ihm_1))
      return false;
    if (!equals(this.jhm_1, tmp0_other_with_cast.jhm_1))
      return false;
    return true;
  };
  function Inactive() {
    Inactive_instance = this;
    PointerAction.call(this);
  }
  var Inactive_instance;
  function Inactive_getInstance() {
    if (Inactive_instance == null)
      new Inactive();
    return Inactive_instance;
  }
  function HighlightForPlacement(mapEntity, placementLocation) {
    placementLocation = placementLocation === VOID ? null : placementLocation;
    PointerAction.call(this);
    this.khm_1 = mapEntity;
    this.lhm_1 = placementLocation;
  }
  protoOf(HighlightForPlacement).toString = function () {
    return 'HighlightForPlacement(mapEntity=' + this.khm_1 + ', placementLocation=' + this.lhm_1 + ')';
  };
  protoOf(HighlightForPlacement).hashCode = function () {
    var result = hashCode(this.khm_1);
    result = imul(result, 31) + (this.lhm_1 == null ? 0 : this.lhm_1.hashCode()) | 0;
    return result;
  };
  protoOf(HighlightForPlacement).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof HighlightForPlacement))
      return false;
    var tmp0_other_with_cast = other instanceof HighlightForPlacement ? other : THROW_CCE();
    if (!equals(this.khm_1, tmp0_other_with_cast.khm_1))
      return false;
    if (!equals(this.lhm_1, tmp0_other_with_cast.lhm_1))
      return false;
    return true;
  };
  function RemoveEntityAtPlace(entityType, data) {
    data = data === VOID ? null : data;
    PointerAction.call(this);
    this.fhm_1 = entityType;
    this.ghm_1 = data;
  }
  protoOf(RemoveEntityAtPlace).toString = function () {
    return 'RemoveEntityAtPlace(entityType=' + this.fhm_1 + ', data=' + this.ghm_1 + ')';
  };
  protoOf(RemoveEntityAtPlace).hashCode = function () {
    var result = this.fhm_1.hashCode();
    result = imul(result, 31) + (this.ghm_1 == null ? 0 : this.ghm_1.hashCode()) | 0;
    return result;
  };
  protoOf(RemoveEntityAtPlace).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemoveEntityAtPlace))
      return false;
    var tmp0_other_with_cast = other instanceof RemoveEntityAtPlace ? other : THROW_CCE();
    if (!this.fhm_1.equals(tmp0_other_with_cast.fhm_1))
      return false;
    if (!equals(this.ghm_1, tmp0_other_with_cast.ghm_1))
      return false;
    return true;
  };
  function PointerAction() {
  }
  function MutableShowRangeTimeComponentListener$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function MutableShowRangeTimeComponentListener$onAddOrReplace$lambda($rangeView) {
    return function ($this$modifyEntity) {
      $this$modifyEntity.og2(new UIShowRangeComponent($rangeView));
      return Unit_getInstance();
    };
  }
  function MutableShowRangeTimeComponentListener$onRemove$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(UIShowRangeComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function MutableShowRangeTimeComponentListener(engine) {
    this.zhm_1 = engine;
    this.ahn_1 = this.zhm_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.zhm_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(MutableShowRangeTimeComponentListener$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.bhn_1 = tmp$ret$1;
  }
  protoOf(MutableShowRangeTimeComponentListener).chn = function (entityId, old, new_0) {
    // Inline function 'kotlin.require' call
    var tmp0_require = old == null;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    // Inline function 'kotlin.require' call
    var tmp1_require = new_0.xha_1.k1(new Long(0, 0)) > 0;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp1_require) {
      var tmp$ret$1;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$1 = 'Failed requirement.';
      var message_0 = tmp$ret$1;
      throw IllegalArgumentException_init_$Create$(toString(message_0));
    }
    var rangeComponent = this.ahn_1.lg3(entityId, getKClass(RangeComponent));
    var sizeComponent = this.ahn_1.lg3(entityId, getKClass(SizeComponent));
    var bottomLeftPositionComponent = this.ahn_1.lg3(entityId, getKClass(BottomLeftPositionComponent));
    var radius = GameUnit__toWorldUnit_impl_m803zs(rangeComponent.sg4_1, this.bhn_1.dhn());
    var tmp0_container = this.bhn_1.ehn(GameUnit__plus_impl_rh1b6r(GameUnit__minus_impl_uz6kmr(bottomLeftPositionComponent.xg3_1, rangeComponent.sg4_1), GameUnit__div_impl_u8qjua(sizeComponent.xg4_1, 2)), GameUnit__plus_impl_rh1b6r(GameUnit__plus_impl_rh1b6r(bottomLeftPositionComponent.yg3_1, rangeComponent.sg4_1), GameUnit__div_impl_u8qjua(sizeComponent.yg4_1, 2)));
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp3_addTo = new Circle(WorldUnit__toFloat_impl_2n0xxa(radius), new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().k4s_1, 0.15)), new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().k4s_1, 0.75)), 5.0);
    var tmp4_addTo = this.bhn_1.vhg_1;
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(tmp3_addTo, tmp4_addTo);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_component.MutableShowRangeTimeComponentListener.onAddOrReplace.<anonymous>' call
    tmp2_apply.cbl(WorldUnit__toFloat_impl_2n0xxa(worldX));
    tmp2_apply.dbl(WorldUnit__toFloat_impl_2n0xxa(worldY));
    tmp$ret$2 = tmp2_apply;
    tmp$ret$3 = tmp$ret$2;
    var rangeView = tmp$ret$3;
    this.ahn_1.gg3(entityId, MutableShowRangeTimeComponentListener$onAddOrReplace$lambda(rangeView));
  };
  protoOf(MutableShowRangeTimeComponentListener).rg0 = function (entityId, old, new_0) {
    var tmp = (old == null ? true : old instanceof MutableShowRangeTimeComponent) ? old : THROW_CCE();
    return this.chn(entityId, tmp, new_0 instanceof MutableShowRangeTimeComponent ? new_0 : THROW_CCE());
  };
  protoOf(MutableShowRangeTimeComponentListener).fhn = function (entityId, component) {
    this.ahn_1.gg3(entityId, MutableShowRangeTimeComponentListener$onRemove$lambda);
  };
  protoOf(MutableShowRangeTimeComponentListener).wg0 = function (entityId, component) {
    return this.fhn(entityId, component instanceof MutableShowRangeTimeComponent ? component : THROW_CCE());
  };
  function PreSelectionComponentListener$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function PreSelectionComponentListener$onAddOrReplace$lambda($selectionBox) {
    return function ($this$modifyEntity) {
      $this$modifyEntity.og2(new UIPreSelectionComponent($selectionBox));
      return Unit_getInstance();
    };
  }
  function PreSelectionComponentListener$onRemove$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(UIPreSelectionComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function PreSelectionComponentListener(engine) {
    this.ghn_1 = engine;
    this.hhn_1 = this.ghn_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ghn_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(PreSelectionComponentListener$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ihn_1 = tmp$ret$1;
    this.jhn_1 = RGBA__withAd_impl_cralao(Colors_getInstance().a4y_1, 0.5);
  }
  protoOf(PreSelectionComponentListener).khn = function (entityId, old, new_0) {
    var uiEntityContainerComponent = this.hhn_1.lg3(entityId, getKClass(UIEntityContainerComponent));
    var sizeComponent = this.hhn_1.lg3(entityId, getKClass(SizeComponent));
    var tmp0_container = toWorldDimensions(sizeComponent.xg4_1, sizeComponent.yg4_1, this.ihn_1.dhn());
    var worldWidth = tmp0_container.c5().ngw_1;
    var worldHeight = tmp0_container.d5().ngw_1;
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(new Graphics(), uiEntityContainerComponent.eg5_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_component.PreSelectionComponentListener.onAddOrReplace.<anonymous>' call
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result;
    var tmp$ret$2;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp6_stroke = this.jhn_1;
      var tmp7_stroke = new StrokeInfo(6.0);
      if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block;
      }
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp0_stroke = tmp7_stroke.j49_1;
      var tmp1_stroke = tmp7_stroke.m49_1;
      var tmp2_stroke = tmp7_stroke.o49_1;
      var tmp3_stroke = tmp7_stroke.p49_1;
      var tmp4_stroke = tmp7_stroke.q49_1;
      var tmp5_stroke = tmp7_stroke.r49_1;
      {
        tmp0_apply_0.a5s();
      }
      // Inline function 'com.xenotactic.korge.listeners_component.PreSelectionComponentListener.onAddOrReplace.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply_0.l4l(0.0, 0.0, WorldUnit__toFloat_impl_2n0xxa(worldWidth), WorldUnit__toFloat_impl_2n0xxa(worldHeight));
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      tmp0_apply_0.t5t();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        tmp0_apply_0.u5t(tmp0_stroke);
        tmp0_apply_0.v5t(tmp1_stroke);
        tmp0_apply_0.w5t(tmp2_stroke);
        tmp0_apply_0.x5t(tmp3_stroke);
        tmp0_apply_0.y5t(tmp4_stroke);
        tmp0_apply_0.z5t(tmp5_stroke);
        tmp0_apply_0.a5u(new RGBA(tmp6_stroke));
      }finally {
        tmp0_apply_0.b5u();
      }
    }
    result = tmp$ret$0;
    tmp$ret$1 = tmp0_apply_0;
    tmp$ret$2 = tmp$ret$1.o5q();
    tmp0_apply.zen(tmp$ret$2);
    tmp$ret$3 = result;
    centerOn(tmp0_apply, uiEntityContainerComponent.eg5_1);
    tmp$ret$4 = tmp0_apply;
    var selectionBox = tmp$ret$4;
    this.hhn_1.gg3(entityId, PreSelectionComponentListener$onAddOrReplace$lambda(selectionBox));
  };
  protoOf(PreSelectionComponentListener).rg0 = function (entityId, old, new_0) {
    var tmp = (old == null ? true : old instanceof PreSelectionComponent) ? old : THROW_CCE();
    return this.khn(entityId, tmp, new_0 instanceof PreSelectionComponent ? new_0 : THROW_CCE());
  };
  protoOf(PreSelectionComponentListener).lhn = function (entityId, component) {
    var uiEntityContainerComponent = this.hhn_1.lg3(entityId, getKClass(UIEntityContainerComponent));
    var uiPreSelectionComponent = this.hhn_1.lg3(entityId, getKClass(UIPreSelectionComponent));
    uiEntityContainerComponent.eg5_1.rbk(uiPreSelectionComponent.chb_1);
    this.hhn_1.gg3(entityId, PreSelectionComponentListener$onRemove$lambda);
  };
  protoOf(PreSelectionComponentListener).wg0 = function (entityId, component) {
    return this.lhn(entityId, component instanceof PreSelectionComponent ? component : THROW_CCE());
  };
  protoOf(PreSelectionComponentListener).mhn = function (entityId, component) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError('An operation is not implemented: Not yet implemented');
  };
  protoOf(PreSelectionComponentListener).ag1 = function (entityId, component) {
    return this.mhn(entityId, component instanceof PreSelectionComponent ? component : THROW_CCE());
  };
  function SelectionComponentListener$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function SelectionComponentListener$onAddOrReplace$lambda($selectionBox) {
    return function ($this$modifyEntity) {
      $this$modifyEntity.og2(new UISelectionComponent($selectionBox));
      return Unit_getInstance();
    };
  }
  function SelectionComponentListener$onRemove$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(UISelectionComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function SelectionComponentListener(engine) {
    this.nhn_1 = engine;
    this.ohn_1 = this.nhn_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.nhn_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(SelectionComponentListener$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.phn_1 = tmp$ret$1;
    this.qhn_1 = Colors_getInstance().a4y_1;
  }
  protoOf(SelectionComponentListener).rhn = function (entityId, old, new_0) {
    var uiEntityContainerComponent = this.ohn_1.lg3(entityId, getKClass(UIEntityContainerComponent));
    var sizeComponent = this.ohn_1.lg3(entityId, getKClass(SizeComponent));
    var tmp0_container = toWorldDimensions(sizeComponent.xg4_1, sizeComponent.yg4_1, this.phn_1.dhn());
    var worldWidth = tmp0_container.c5().ngw_1;
    var worldHeight = tmp0_container.d5().ngw_1;
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(new Graphics(), uiEntityContainerComponent.eg5_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_component.SelectionComponentListener.onAddOrReplace.<anonymous>' call
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result;
    var tmp$ret$2;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp6_stroke = this.qhn_1;
      var tmp7_stroke = new StrokeInfo(6.0);
      if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block;
      }
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp0_stroke = tmp7_stroke.j49_1;
      var tmp1_stroke = tmp7_stroke.m49_1;
      var tmp2_stroke = tmp7_stroke.o49_1;
      var tmp3_stroke = tmp7_stroke.p49_1;
      var tmp4_stroke = tmp7_stroke.q49_1;
      var tmp5_stroke = tmp7_stroke.r49_1;
      {
        tmp0_apply_0.a5s();
      }
      // Inline function 'com.xenotactic.korge.listeners_component.SelectionComponentListener.onAddOrReplace.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply_0.l4l(0.0, 0.0, WorldUnit__toFloat_impl_2n0xxa(worldWidth), WorldUnit__toFloat_impl_2n0xxa(worldHeight));
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      tmp0_apply_0.t5t();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        tmp0_apply_0.u5t(tmp0_stroke);
        tmp0_apply_0.v5t(tmp1_stroke);
        tmp0_apply_0.w5t(tmp2_stroke);
        tmp0_apply_0.x5t(tmp3_stroke);
        tmp0_apply_0.y5t(tmp4_stroke);
        tmp0_apply_0.z5t(tmp5_stroke);
        tmp0_apply_0.a5u(new RGBA(tmp6_stroke));
      }finally {
        tmp0_apply_0.b5u();
      }
    }
    result = tmp$ret$0;
    tmp$ret$1 = tmp0_apply_0;
    tmp$ret$2 = tmp$ret$1.o5q();
    tmp0_apply.zen(tmp$ret$2);
    tmp$ret$3 = result;
    centerOn(tmp0_apply, uiEntityContainerComponent.eg5_1);
    tmp$ret$4 = tmp0_apply;
    var selectionBox = tmp$ret$4;
    this.ohn_1.gg3(entityId, SelectionComponentListener$onAddOrReplace$lambda(selectionBox));
    this.nhn_1.dgo_1.bg7(EntitySelectionChangedEvent_getInstance());
  };
  protoOf(SelectionComponentListener).rg0 = function (entityId, old, new_0) {
    var tmp = (old == null ? true : old instanceof SelectedComponent) ? old : THROW_CCE();
    return this.rhn(entityId, tmp, new_0 instanceof SelectedComponent ? new_0 : THROW_CCE());
  };
  protoOf(SelectionComponentListener).shn = function (entityId, component) {
    println('Removed selection for entity: ' + new EntityId(entityId));
    var uiEntityContainerComponent = this.ohn_1.mg3(entityId, getKClass(UIEntityContainerComponent));
    if (uiEntityContainerComponent == null) {
      return Unit_getInstance();
    }
    var uiSelectionComponent = this.ohn_1.lg3(entityId, getKClass(UISelectionComponent));
    uiEntityContainerComponent.eg5_1.rbk(uiSelectionComponent.kg5_1);
    this.ohn_1.gg3(entityId, SelectionComponentListener$onRemove$lambda);
    this.nhn_1.dgo_1.bg7(EntitySelectionChangedEvent_getInstance());
  };
  protoOf(SelectionComponentListener).wg0 = function (entityId, component) {
    return this.shn(entityId, component instanceof SelectedComponent ? component : THROW_CCE());
  };
  protoOf(SelectionComponentListener).thn = function (entityId, component) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError('An operation is not implemented: Not yet implemented');
  };
  protoOf(SelectionComponentListener).ag1 = function (entityId, component) {
    return this.thn(entityId, component instanceof SelectedComponent ? component : THROW_CCE());
  };
  function UIMapEntityComponentListener() {
  }
  protoOf(UIMapEntityComponentListener).uhn = function (entityId, component) {
    component.fg5_1.mbn();
  };
  protoOf(UIMapEntityComponentListener).wg0 = function (entityId, component) {
    return this.uhn(entityId, component instanceof UIEntityViewComponent ? component : THROW_CCE());
  };
  function UIMapEntityTextComponentListener$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIMapEntityTextComponentListener(engine) {
    this.vhn_1 = engine;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.vhn_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIMapEntityTextComponentListener$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.whn_1 = tmp$ret$1;
  }
  protoOf(UIMapEntityTextComponentListener).xhn = function (entityId, component) {
    this.whn_1.uhg_1.rbk(component.jg5_1);
  };
  protoOf(UIMapEntityTextComponentListener).wg0 = function (entityId, component) {
    return this.xhn(entityId, component instanceof UIMapEntityTextComponent ? component : THROW_CCE());
  };
  protoOf(UIMapEntityTextComponentListener).yhn = function (entityId, component) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError('An operation is not implemented: Not yet implemented');
  };
  protoOf(UIMapEntityTextComponentListener).ag1 = function (entityId, component) {
    return this.yhn(entityId, component instanceof UIMapEntityTextComponent ? component : THROW_CCE());
  };
  function UIShowRangeComponentListener$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIShowRangeComponentListener(engine) {
    this.zhn_1 = engine;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.zhn_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIShowRangeComponentListener$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.aho_1 = tmp$ret$1;
  }
  protoOf(UIShowRangeComponentListener).bho = function (entityId, component) {
    this.aho_1.vhg_1.rbk(component.dhb_1);
  };
  protoOf(UIShowRangeComponentListener).wg0 = function (entityId, component) {
    return this.bho(entityId, component instanceof UIShowRangeComponent ? component : THROW_CCE());
  };
  function handleUpgradeTowerDamageEvent($this, event) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = $this.dho_1.agc_1.tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      var tmp$ret$0;
      $l$block_0: {
        // Inline function 'com.xenotactic.korge.listeners_event.TowerUpgradeEventListeners.handleUpgradeTowerDamageEvent.<anonymous>' call
        if (!$this.dho_1.zgc(element)) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_0;
        }
        var damageUpgradeComponent = $this.eho_1.lg3(element, getKClass(DamageUpgradeComponent));
        var costOfUpgrades = calculateCostOfUpgrades(damageUpgradeComponent.cg4_1, $this.gho_1.pgo_1, event.lhb_1);
        if (costOfUpgrades > $this.hho_1.agp_1) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_0;
        }
        $this.eho_1.gg3(element, TowerUpgradeEventListeners$handleUpgradeTowerDamageEvent$lambda(damageUpgradeComponent, event, $this, costOfUpgrades, element));
      }
    }
  }
  function handleUpgradeTowerSpeedEvent($this, event) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = $this.dho_1.agc_1.tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      var tmp$ret$0;
      $l$block_1: {
        // Inline function 'com.xenotactic.korge.listeners_event.TowerUpgradeEventListeners.handleUpgradeTowerSpeedEvent.<anonymous>' call
        if (!$this.dho_1.zgc(element)) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_1;
        }
        var speedUpgradeComponent = $this.eho_1.lg3(element, getKClass(SpeedUpgradeComponent));
        if (speedUpgradeComponent.ag5_1 === $this.gho_1.mgo_1) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_1;
        }
        if ((speedUpgradeComponent.ag5_1 + event.mhb_1 | 0) > $this.gho_1.mgo_1) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block_1;
        }
        var upgradeDecision = calculateUpgradeDecision($this.hho_1.agp_1, speedUpgradeComponent.ag5_1, $this.gho_1.mgo_1, $this.gho_1.qgo_1, event.mhb_1);
        if (upgradeDecision.ogw_1 === event.mhb_1) {
          $this.eho_1.gg3(element, TowerUpgradeEventListeners$handleUpgradeTowerSpeedEvent$lambda(speedUpgradeComponent, upgradeDecision, $this, element));
        }
      }
    }
  }
  function TowerUpgradeEventListeners$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function TowerUpgradeEventListeners$gamePlayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function TowerUpgradeEventListeners$mutableGoldState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableGoldState);
  }
  function TowerUpgradeEventListeners$slambda(this$0, resultContinuation) {
    this.rho_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(TowerUpgradeEventListeners$slambda).tho = function (it, $completion) {
    var tmp = this.uho(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(TowerUpgradeEventListeners$slambda).g8 = function (p1, $completion) {
    return this.tho(p1 instanceof UpgradeTowerDamageEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(TowerUpgradeEventListeners$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          handleUpgradeTowerDamageEvent(this.rho_1, this.sho_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(TowerUpgradeEventListeners$slambda).uho = function (it, completion) {
    var i = new TowerUpgradeEventListeners$slambda(this.rho_1, completion);
    i.sho_1 = it;
    return i;
  };
  function TowerUpgradeEventListeners$slambda_0(this$0, resultContinuation) {
    var i = new TowerUpgradeEventListeners$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.tho(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function TowerUpgradeEventListeners$slambda_1(this$0, resultContinuation) {
    this.dhp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(TowerUpgradeEventListeners$slambda_1).fhp = function (it, $completion) {
    var tmp = this.ghp(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(TowerUpgradeEventListeners$slambda_1).g8 = function (p1, $completion) {
    return this.fhp(p1 instanceof UpgradeTowerSpeedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(TowerUpgradeEventListeners$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          handleUpgradeTowerSpeedEvent(this.dhp_1, this.ehp_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(TowerUpgradeEventListeners$slambda_1).ghp = function (it, completion) {
    var i = new TowerUpgradeEventListeners$slambda_1(this.dhp_1, completion);
    i.ehp_1 = it;
    return i;
  };
  function TowerUpgradeEventListeners$slambda_2(this$0, resultContinuation) {
    var i = new TowerUpgradeEventListeners$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.fhp(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function TowerUpgradeEventListeners$handleUpgradeTowerDamageEvent$lambda($damageUpgradeComponent, $event, this$0, $costOfUpgrades, $element) {
    return function ($this$modifyEntity) {
      var newDamageUpgrade = $damageUpgradeComponent.cg4_1 + $event.lhb_1 | 0;
      var tmp0_this = this$0.hho_1;
      tmp0_this.bgp(tmp0_this.agp_1 - $costOfUpgrades | 0);
      $this$modifyEntity.bg3(new DamageUpgradeComponent(newDamageUpgrade));
      var newDamage = this$0.fho_1.fgr($element);
      this$0.cho_1.dgo_1.bg7(new UpgradedTowerDamageEvent($element, newDamage, newDamageUpgrade));
      return Unit_getInstance();
    };
  }
  function TowerUpgradeEventListeners$handleUpgradeTowerSpeedEvent$lambda($speedUpgradeComponent, $upgradeDecision, this$0, $element) {
    return function ($this$modifyEntity) {
      var newSpeedUpgrade = $speedUpgradeComponent.ag5_1 + $upgradeDecision.ogw_1 | 0;
      $this$modifyEntity.bg3(new SpeedUpgradeComponent(newSpeedUpgrade));
      var tmp0_this = this$0.hho_1;
      tmp0_this.bgp(tmp0_this.agp_1 - $upgradeDecision.pgw_1 | 0);
      var newWeaponSpeedMillis = this$0.fho_1.ugq($element);
      var newAttacksPerSecond = this$0.fho_1.sgs($element);
      this$0.cho_1.dgo_1.bg7(new UpgradedTowerSpeedEvent($element, newWeaponSpeedMillis, newAttacksPerSecond, newSpeedUpgrade));
      return Unit_getInstance();
    };
  }
  function TowerUpgradeEventListeners(engine) {
    this.cho_1 = engine;
    this.dho_1 = this.cho_1.ego_1;
    this.eho_1 = this.dho_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.cho_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapApi) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(TowerUpgradeEventListeners$gameMapApi$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.fho_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.cho_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameplayState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(TowerUpgradeEventListeners$gamePlayState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.gho_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.cho_1.ggo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(MutableGoldState));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof MutableGoldState) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(TowerUpgradeEventListeners$mutableGoldState$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.hho_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs_2 = getKClass(TowerUpgradeEventListeners).zi();
    tmp$ret$6 = tmp0_invoke.b1n(tmp0_elvis_lhs_2 == null ? 'NoClassName' : tmp0_elvis_lhs_2);
    tmp_8.iho_1 = tmp$ret$6;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.cho_1.dgo_1;
    var tmp1_register = TowerUpgradeEventListeners$slambda_0(this, null);
    tmp$ret$7 = tmp0_register.cg7(getKClass(UpgradeTowerDamageEvent), tmp1_register);
    var tmp$ret$8;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.cho_1.dgo_1;
    var tmp3_register = TowerUpgradeEventListeners$slambda_2(this, null);
    tmp$ret$8 = tmp2_register.cg7(getKClass(UpgradeTowerSpeedEvent), tmp3_register);
  }
  function RemoveUIEntitiesEvent(entities) {
    this.hhp_1 = entities;
  }
  protoOf(RemoveUIEntitiesEvent).toString = function () {
    return 'RemoveUIEntitiesEvent(entities=' + this.hhp_1 + ')';
  };
  protoOf(RemoveUIEntitiesEvent).hashCode = function () {
    return hashCode(this.hhp_1);
  };
  protoOf(RemoveUIEntitiesEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof RemoveUIEntitiesEvent))
      return false;
    var tmp0_other_with_cast = other instanceof RemoveUIEntitiesEvent ? other : THROW_CCE();
    if (!equals(this.hhp_1, tmp0_other_with_cast.hhp_1))
      return false;
    return true;
  };
  function handleRemoveUIEntitiesEvent($this, entities) {
    $this.khp_1.qgs(entities);
  }
  function handleSpeedAreasRender($this) {
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var tmp1_updateShape = $this.jhp_1.shg_1;
    var result;
    var tmp$ret$1;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp0_forEach = $this.lhp_1.dgc_1.tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleSpeedAreasRender.<anonymous>.<anonymous>' call
      var sizeComponent = $this.lhp_1.ggc_1.tg0(element);
      var tmp0_container = toWorldDimensions(sizeComponent.xg4_1, sizeComponent.yg4_1, $this.jhp_1.dhn());
      var worldWidth = tmp0_container.c5().ngw_1;
      var worldHeight = tmp0_container.d5().ngw_1;
      var speedEffect = $this.mhp_1.lg3(element, getKClass(EntitySpeedAreaComponent)).hg4_1;
      var speedAreaColor = RGBA__withAd_impl_cralao(SpeedAreaColorUtil_getInstance().ggw(speedEffect, VOID, VOID, 0.3, 0.9, 1.2, 2.0), 0.4);
      var radius = WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2;
      var bottomLeftPositionComponent = $this.mhp_1.lg3(element, getKClass(BottomLeftPositionComponent));
      var centerPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
      var worldPoint = toPoint($this.jhp_1.ehn(centerPoint.dg6_1, centerPoint.eg6_1));
      // Inline function 'korlibs.image.vector.Context2d.fill' call
      {
        tmp0_apply.a5s();
      }
      // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleSpeedAreasRender.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply.c3t(worldPoint, radius);
      tmp0_apply.s75(new RGBA(speedAreaColor), null);
    }
    result = Unit_getInstance();
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.o5q();
    tmp1_updateShape.zen(tmp$ret$1);
    tmp$ret$2 = result;
  }
  function handleAddedEntityEvent($this, entityId) {
    var entityTypeComponent = $this.lhp_1.hgc_1.tg0(entityId);
    if (entityTypeComponent.lg4_1.equals(MapEntityType_SPEED_AREA_getInstance())) {
      handleSpeedAreasRender($this);
    }
    $this.mhp_1.gg3(entityId, UIMapEventListeners$handleAddedEntityEvent$lambda($this, entityId, entityTypeComponent));
  }
  function createHealthBar($this, diameterGameUnit, maxHealth) {
    var diameter = GameUnit__toWorldUnit_impl_m803zs(diameterGameUnit, $this.jhp_1.dhn());
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new UIProgressBar(new Size(WorldUnit__toFloat_impl_2n0xxa(diameter), WorldUnit__toFloat_impl_2n0xxa(diameter) / 4.0), numberToDouble(maxHealth), numberToDouble(maxHealth));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.createHealthBar.<anonymous>' call
    var tmp0_this = tmp0_apply;
    tmp0_this.cbl(tmp0_this.e6g() - WorldUnit__toFloat_impl_2n0xxa(diameter) / 2.0);
    var tmp1_this = tmp0_apply;
    tmp1_this.dbl(tmp1_this.f6g() - WorldUnit__toFloat_impl_2n0xxa(diameter) / 5.0 * 4.0);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function UIMapEventListeners$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIMapEventListeners$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function UIMapEventListeners$slambda(this$0, resultContinuation) {
    this.vhp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEventListeners$slambda).xhp = function (it, $completion) {
    var tmp = this.yhp(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEventListeners$slambda).g8 = function (p1, $completion) {
    return this.xhp(p1 instanceof AddedEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEventListeners$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          handleAddedEntityEvent(this.vhp_1, this.whp_1.jg6_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEventListeners$slambda).yhp = function (it, completion) {
    var i = new UIMapEventListeners$slambda(this.vhp_1, completion);
    i.whp_1 = it;
    return i;
  };
  function UIMapEventListeners$slambda_0(this$0, resultContinuation) {
    var i = new UIMapEventListeners$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xhp(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEventListeners$slambda_1(this$0, resultContinuation) {
    this.hhq_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEventListeners$slambda_1).jhq = function (it, $completion) {
    var tmp = this.khq(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEventListeners$slambda_1).g8 = function (p1, $completion) {
    return this.jhq(p1 instanceof RemoveUIEntitiesEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEventListeners$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          handleRemoveUIEntitiesEvent(this.hhq_1, this.ihq_1.hhp_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEventListeners$slambda_1).khq = function (it, completion) {
    var i = new UIMapEventListeners$slambda_1(this.hhq_1, completion);
    i.ihq_1 = it;
    return i;
  };
  function UIMapEventListeners$slambda_2(this$0, resultContinuation) {
    var i = new UIMapEventListeners$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.jhq(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEventListeners$slambda_3(this$0, resultContinuation) {
    this.thq_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEventListeners$slambda_3).vhq = function (it, $completion) {
    var tmp = this.whq(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEventListeners$slambda_3).g8 = function (p1, $completion) {
    return this.vhq(p1 instanceof AddedMonsterEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEventListeners$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.thq_1.xhq(this.uhq_1.kg6_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEventListeners$slambda_3).whq = function (it, completion) {
    var i = new UIMapEventListeners$slambda_3(this.thq_1, completion);
    i.uhq_1 = it;
    return i;
  };
  function UIMapEventListeners$slambda_4(this$0, resultContinuation) {
    var i = new UIMapEventListeners$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.vhq(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEventListeners$handleAddedEntityEvent$lambda(this$0, $entityId, $entityTypeComponent) {
    return function ($this$modifyEntity) {
      var sizeComponent = this$0.lhp_1.ggc_1.tg0($entityId);
      var uiEntityContainer = new Container();
      var borderSize = this$0.jhp_1.yhq();
      var tmp0_container = toWorldDimensions(sizeComponent.xg4_1, sizeComponent.yg4_1, this$0.jhp_1.dhn());
      var worldWidth = tmp0_container.c5().ngw_1;
      var worldHeight = tmp0_container.d5().ngw_1;
      var tmp1_subject = $entityTypeComponent.lg4_1;
      var tmp0 = tmp1_subject.b2_1;
      var tmp;
      switch (tmp0) {
        case 2:
          addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().u4v_1)), uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 1:
          addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().r4t_1)), uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 0:
          var tmp$ret$0;
          // Inline function 'kotlin.apply' call
          var tmp0_apply = new Circle(WorldUnit__toFloat_impl_2n0xxa(WorldUnit__div_impl_b90n6a(worldWidth, 2)), new RGBA(Colors_getInstance().m4s_1));
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedEntityEvent.<anonymous>.<anonymous>' call
          addTo(tmp0_apply, uiEntityContainer);
          tmp$ret$0 = tmp0_apply;

          tmp = Unit_getInstance();
          break;
        case 4:
          var tower = createUIEntityContainerForTower(worldWidth, worldHeight, TowerType_BASIC_getInstance(), uiEntityContainer);
          $this$modifyEntity.og2(new UIGunBarrelComponent(ensureNotNull(tower.gbk(get_GUN_VIEW_NAME()))));
          tmp = Unit_getInstance();
          break;
        case 10:
          createUIEntityContainerForSupplyDepot(worldWidth, worldHeight, uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 3:
          var tmp$ret$4;
          // Inline function 'korlibs.korge.view.solidRect' call
          var tmp3_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
          var tmp4_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
          var tmp5_solidRect = MaterialColors_getInstance().a5g_1;
          var tmp$ret$3;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp2_addTo = new SolidRect(new Size(tmp3_solidRect, tmp4_solidRect), tmp5_solidRect);
          var tmp$ret$2;
          // Inline function 'kotlin.apply' call
          var tmp1_apply = addTo(tmp2_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$1;
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          tmp$ret$1 = Unit_getInstance();
          tmp$ret$2 = tmp1_apply;
          tmp$ret$3 = tmp$ret$2;
          tmp$ret$4 = tmp$ret$3;

          ;
          var tmp$ret$8;
          // Inline function 'korlibs.korge.view.solidRect' call
          var tmp8_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb(worldWidth, borderSize));
          var tmp9_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb(worldHeight, borderSize));
          var tmp10_solidRect = MaterialColors_getInstance().e5g_1;
          var tmp$ret$7;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp7_addTo = new SolidRect(Size_init_$Create$_0(tmp8_solidRect, tmp9_solidRect), tmp10_solidRect);
          var tmp$ret$6;
          // Inline function 'kotlin.apply' call
          var tmp6_apply = addTo(tmp7_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$5;
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          tmp$ret$5 = Unit_getInstance();
          tmp$ret$6 = tmp6_apply;
          tmp$ret$7 = tmp$ret$6;
          tmp$ret$8 = tmp$ret$7;

          centerOn(tmp$ret$8, uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 5:
          addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2, new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().n4s_1, 0.6))), uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 6:
          addTo(new Circle(WorldUnit__toFloat_impl_2n0xxa(worldWidth) / 2, new RGBA(RGBA__withAd_impl_cralao(Colors_getInstance().m4s_1, 0.6))), uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 7:
          var tmp$ret$12;
          // Inline function 'korlibs.korge.view.solidRect' call
          var tmp13_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
          var tmp14_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
          var tmp15_solidRect = MaterialColors_getInstance().w5d_1;
          var tmp$ret$11;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp12_addTo = new SolidRect(new Size(tmp13_solidRect, tmp14_solidRect), tmp15_solidRect);
          var tmp$ret$10;
          // Inline function 'kotlin.apply' call
          var tmp11_apply = addTo(tmp12_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$9;
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          tmp$ret$9 = Unit_getInstance();
          tmp$ret$10 = tmp11_apply;
          tmp$ret$11 = tmp$ret$10;
          tmp$ret$12 = tmp$ret$11;

          ;
          var tmp$ret$16;
          // Inline function 'korlibs.korge.view.solidRect' call
          var tmp18_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb(worldWidth, borderSize));
          var tmp19_solidRect = _WorldUnit___get_value__impl__xwm9rd(WorldUnit__minus_impl_yejnmb(worldHeight, borderSize));
          var tmp20_solidRect = MaterialColors_getInstance().a5e_1;
          var tmp$ret$15;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp17_addTo = new SolidRect(Size_init_$Create$_0(tmp18_solidRect, tmp19_solidRect), tmp20_solidRect);
          var tmp$ret$14;
          // Inline function 'kotlin.apply' call
          var tmp16_apply = addTo(tmp17_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$13;
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          tmp$ret$13 = Unit_getInstance();
          tmp$ret$14 = tmp16_apply;
          tmp$ret$15 = tmp$ret$14;
          tmp$ret$16 = tmp$ret$15;

          centerOn(tmp$ret$16, uiEntityContainer);
          tmp = Unit_getInstance();
          break;
        case 8:
          var tmp$ret$20;
          // Inline function 'korlibs.korge.view.solidRect' call
          var tmp23_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldWidth);
          var tmp24_solidRect = WorldUnit__toFloat_impl_2n0xxa(worldHeight);
          var tmp25_solidRect = RGBA__withAd_impl_cralao(Colors_getInstance().k4s_1, 0.0);
          var tmp$ret$19;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp22_addTo = new SolidRect(new Size(tmp23_solidRect, tmp24_solidRect), tmp25_solidRect);
          var tmp$ret$18;
          // Inline function 'kotlin.apply' call
          var tmp21_apply = addTo(tmp22_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$17;
          // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
          tmp$ret$17 = Unit_getInstance();
          tmp$ret$18 = tmp21_apply;
          tmp$ret$19 = tmp$ret$18;
          tmp$ret$20 = tmp$ret$19;

          tmp = Unit_getInstance();
          break;
        case 9:
          var tmp$ret$22;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp27_addTo = new UIEightDirectionalSprite(GlobalResources_getInstance().igv());
          var tmp$ret$21;
          // Inline function 'kotlin.apply' call
          var tmp26_apply = addTo(tmp27_addTo, uiEntityContainer);
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedEntityEvent.<anonymous>.<anonymous>' call
          anchor(tmp26_apply, Companion_getInstance_0().x3q());
          tmp26_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
          tmp26_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
          tmp$ret$21 = tmp26_apply;
          tmp$ret$22 = tmp$ret$21;

          tmp = Unit_getInstance();
          break;
      }
      var tmp$ret$23;
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedEntityEvent.<anonymous>.<anonymous>' call
      var tmp0_subject = $entityTypeComponent.lg4_1;
      var tmp0_0 = tmp0_subject.b2_1;
      switch (tmp0_0) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 10:
          addTo(uiEntityContainer, this$0.jhp_1.thg_1);
          ;
          break;
        case 8:
          addTo(uiEntityContainer, this$0.jhp_1.rhg_1);
          ;
          break;
        case 9:
          addTo(uiEntityContainer, this$0.jhp_1.xhg_1);
          ;
          break;
      }
      tmp$ret$23 = uiEntityContainer;
      $this$modifyEntity.og2(new UIEntityViewComponent(uiEntityContainer));
      $this$modifyEntity.og2(new UIEntityContainerComponent(uiEntityContainer));
      var text = getText_0($entityTypeComponent.lg4_1, $entityId, this$0.mhp_1);
      var tmp_0;
      if (!(text == null)) {
        var tmp$ret$24;
        // Inline function 'kotlin.apply' call
        var tmp28_apply = makeEntityLabelText(text);
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedEntityEvent.<anonymous>.<anonymous>' call
        addTo(tmp28_apply, this$0.jhp_1.uhg_1);
        tmp28_apply.gbm(this$0.jhp_1.dhn() / 2);
        tmp28_apply.ebm(tmp28_apply.hbm() * tmp28_apply.zbl() / tmp28_apply.bbm());
        centerOn(tmp28_apply, uiEntityContainer);
        tmp$ret$24 = tmp28_apply;
        var textView = tmp$ret$24;
        $this$modifyEntity.og2(new UIMapEntityTextComponent(textView));
        tmp_0 = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function UIMapEventListeners$handleAddedMonsterEntityEvent$lambda($spriteContainer, $uiSprite, $healthBar) {
    return function ($this$modifyEntity) {
      $this$modifyEntity.og2(new UIEntityViewComponent($spriteContainer));
      $this$modifyEntity.og2(new UIEightDirectionalSpriteComponent($uiSprite));
      $this$modifyEntity.og2(new UIHealthBarComponent($healthBar));
      return Unit_getInstance();
    };
  }
  function UIMapEventListeners(engine) {
    this.ihp_1 = engine;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ihp_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIMapEventListeners$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.jhp_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.ihp_1.hgo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameMapApi) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(UIMapEventListeners$gameMapApi$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.khp_1 = tmp$ret$3;
    this.lhp_1 = this.ihp_1.ego_1;
    this.mhp_1 = this.lhp_1.vgb_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.ihp_1.dgo_1;
    var tmp1_register = UIMapEventListeners$slambda_0(this, null);
    tmp$ret$4 = tmp0_register.cg7(getKClass(AddedEntityEvent), tmp1_register);
    var tmp$ret$5;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.ihp_1.dgo_1;
    var tmp3_register = UIMapEventListeners$slambda_2(this, null);
    tmp$ret$5 = tmp2_register.cg7(getKClass(RemoveUIEntitiesEvent), tmp3_register);
    var tmp$ret$6;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp4_register = this.ihp_1.dgo_1;
    var tmp5_register = UIMapEventListeners$slambda_4(this, null);
    tmp$ret$6 = tmp4_register.cg7(getKClass(AddedMonsterEntityEvent), tmp5_register);
  }
  protoOf(UIMapEventListeners).xhq = function (entityId) {
    var sizeComponent = this.mhp_1.lg3(entityId, getKClass(SizeComponent));
    var maxHealthComponent = this.mhp_1.lg3(entityId, getKClass(MaxHealthComponent));
    var tmp0_container = toWorldDimensions(sizeComponent.xg4_1, sizeComponent.yg4_1, this.jhp_1.dhn());
    var worldWidth = tmp0_container.c5().ngw_1;
    var worldHeight = tmp0_container.d5().ngw_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container = this.jhp_1.xhg_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, tmp2_container);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var spriteContainer = tmp$ret$3;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new UIEightDirectionalSprite(GlobalResources_getInstance().igv());
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, spriteContainer);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedMonsterEntityEvent.<anonymous>' call
    anchor(tmp3_apply, Companion_getInstance_0().x3q());
    tmp3_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
    tmp3_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
    tmp$ret$4 = tmp3_apply;
    tmp$ret$5 = tmp$ret$4;
    var uiSprite = tmp$ret$5;
    var tmp$ret$6;
    // Inline function 'kotlin.apply' call
    var tmp5_apply = createHealthBar(this, sizeComponent.xg4_1, maxHealthComponent.ng4_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.listeners_event.UIMapEventListeners.handleAddedMonsterEntityEvent.<anonymous>' call
    addTo(tmp5_apply, spriteContainer);
    tmp$ret$6 = tmp5_apply;
    var healthBar = tmp$ret$6;
    this.mhp_1.gg3(entityId, UIMapEventListeners$handleAddedMonsterEntityEvent$lambda(spriteContainer, uiSprite, healthBar));
  };
  function SetInitialPositionForUIEntityFamilyListener$uiMapV2$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function SetInitialPositionForUIEntityFamilyListener(world) {
    this.zhq_1 = world;
    this.ahr_1 = new FamilyConfiguration(setOf([getKClass(UIEntityViewComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent)]));
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.zhq_1.zfz_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(SetInitialPositionForUIEntityFamilyListener$uiMapV2$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.bhr_1 = tmp$ret$1;
  }
  protoOf(SetInitialPositionForUIEntityFamilyListener).jg2 = function () {
    return this.ahr_1;
  };
  protoOf(SetInitialPositionForUIEntityFamilyListener).hg2 = function (entityId) {
    var uiEntityViewComponent = this.zhq_1.lg3(entityId, getKClass(UIEntityViewComponent));
    var bottomLeftPositionComponent = this.zhq_1.lg3(entityId, getKClass(BottomLeftPositionComponent));
    var sizeComponent = this.zhq_1.lg3(entityId, getKClass(SizeComponent));
    var tmp0_container = this.bhr_1.chr(bottomLeftPositionComponent.xg3_1, bottomLeftPositionComponent.yg3_1, sizeComponent.yg4_1);
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    println(trimIndent('\n            entityId: ' + new EntityId(entityId) + ', bottomLeftPositionComponent: ' + bottomLeftPositionComponent + ', sizeComponent: ' + sizeComponent + ', worldX: ' + new WorldUnit(worldX) + ', worldY: ' + new WorldUnit(worldY) + ' \n        '));
    xy_1(uiEntityViewComponent.fg5_1, worldX, worldY);
  };
  protoOf(SetInitialPositionForUIEntityFamilyListener).gg2 = function (entityId) {
    return Unit_getInstance();
  };
  protoOf(SetInitialPositionForUIEntityFamilyListener).lg2 = function (entityId) {
    // Inline function 'kotlin.TODO' call
    throw new NotImplementedError('An operation is not implemented: Not yet implemented');
  };
  function RandomMapGeneratorMaxAttemptsError(messageFn) {
    RuntimeException_init_$Init$(messageFn(), this);
    captureStack(this, RandomMapGeneratorMaxAttemptsError);
  }
  function MapGeneratorConfigurationV2(seed, generators, width, height, searcher, failureAfterTotalAttempts) {
    width = width === VOID ? get_GAME_WIDTH() : width;
    height = height === VOID ? get_GAME_HEIGHT() : height;
    searcher = searcher === VOID ? AStarSearcher_getInstance() : searcher;
    failureAfterTotalAttempts = failureAfterTotalAttempts === VOID ? 2000 : failureAfterTotalAttempts;
    this.dhr_1 = seed;
    this.ehr_1 = generators;
    this.fhr_1 = width;
    this.ghr_1 = height;
    this.hhr_1 = searcher;
    this.ihr_1 = failureAfterTotalAttempts;
  }
  protoOf(MapGeneratorConfigurationV2).toString = function () {
    return 'MapGeneratorConfigurationV2(seed=' + toString(this.dhr_1) + ', generators=' + this.ehr_1 + ', width=' + new GameUnit(this.fhr_1) + ', height=' + new GameUnit(this.ghr_1) + ', searcher=' + this.hhr_1 + ', failureAfterTotalAttempts=' + this.ihr_1 + ')';
  };
  protoOf(MapGeneratorConfigurationV2).hashCode = function () {
    var result = this.dhr_1.hashCode();
    result = imul(result, 31) + hashCode(this.ehr_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.fhr_1) | 0;
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.ghr_1) | 0;
    result = imul(result, 31) + hashCode(this.hhr_1) | 0;
    result = imul(result, 31) + this.ihr_1 | 0;
    return result;
  };
  protoOf(MapGeneratorConfigurationV2).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapGeneratorConfigurationV2))
      return false;
    var tmp0_other_with_cast = other instanceof MapGeneratorConfigurationV2 ? other : THROW_CCE();
    if (!this.dhr_1.equals(tmp0_other_with_cast.dhr_1))
      return false;
    if (!equals(this.ehr_1, tmp0_other_with_cast.ehr_1))
      return false;
    if (!equals(this.fhr_1, tmp0_other_with_cast.fhr_1))
      return false;
    if (!equals(this.ghr_1, tmp0_other_with_cast.ghr_1))
      return false;
    if (!equals(this.hhr_1, tmp0_other_with_cast.hhr_1))
      return false;
    if (!(this.ihr_1 === tmp0_other_with_cast.ihr_1))
      return false;
    return true;
  };
  function getRandomPointWithinMapBounds($this, entityWidth, entityHeight) {
    return Companion_getInstance_14().z6e($this.mhr_1.m9(0, (GameUnit__toInt_impl_9qttgv($this.jhr_1) - GameUnit__toInt_impl_9qttgv(entityWidth) | 0) + 1 | 0), $this.mhr_1.m9(0, (GameUnit__toInt_impl_9qttgv($this.khr_1) - GameUnit__toInt_impl_9qttgv(entityHeight) | 0) + 1 | 0));
  }
  function GenerationContext(width, height, gameWorld, random, failureAfterTotalAttempts) {
    this.jhr_1 = width;
    this.khr_1 = height;
    this.lhr_1 = gameWorld;
    this.mhr_1 = random;
    this.nhr_1 = failureAfterTotalAttempts;
    this.ohr_1 = this.lhr_1.vgb_1;
    this.phr_1 = 0;
  }
  protoOf(GenerationContext).qhr = function (entityType) {
    var tmp0_subject = entityType;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 1:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 2:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 3:
        throw new NotImplementedError();
      case 4:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 5:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 6:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      case 7:
        tmp = Companion_getInstance_14().z6e(1, 1);
        break;
      case 8:
        throw new NotImplementedError();
      case 9:
        throw new NotImplementedError();
      case 10:
        tmp = Companion_getInstance_14().z6e(2, 2);
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  };
  protoOf(GenerationContext).rhr = function (errorMessageFn) {
    var tmp0_this = this;
    var tmp1 = tmp0_this.phr_1;
    tmp0_this.phr_1 = tmp1 + 1 | 0;
    if (this.phr_1 > this.nhr_1) {
      throw new RandomMapGeneratorMaxAttemptsError(errorMessageFn);
    }
  };
  protoOf(GenerationContext).shr = function (entitySize) {
    return getRandomPointWithinMapBounds(this, entitySize.kgb(), entitySize.lgb());
  };
  protoOf(GenerationContext).thr = function (entityWidth, entityHeight) {
    return Companion_getInstance_14().z6e(this.mhr_1.m9((-GameUnit__toInt_impl_9qttgv(entityWidth) | 0) + 1 | 0, GameUnit__toInt_impl_9qttgv(this.jhr_1) - 1 | 0), this.mhr_1.m9((-GameUnit__toInt_impl_9qttgv(entityHeight) | 0) + 1 | 0, GameUnit__toInt_impl_9qttgv(this.khr_1) - 1 | 0));
  };
  protoOf(GenerationContext).toString = function () {
    return 'GenerationContext(width=' + new GameUnit(this.jhr_1) + ', height=' + new GameUnit(this.khr_1) + ', gameWorld=' + this.lhr_1 + ', random=' + this.mhr_1 + ', failureAfterTotalAttempts=' + this.nhr_1 + ')';
  };
  protoOf(GenerationContext).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.jhr_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.khr_1) | 0;
    result = imul(result, 31) + hashCode(this.lhr_1) | 0;
    result = imul(result, 31) + hashCode(this.mhr_1) | 0;
    result = imul(result, 31) + this.nhr_1 | 0;
    return result;
  };
  protoOf(GenerationContext).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof GenerationContext))
      return false;
    var tmp0_other_with_cast = other instanceof GenerationContext ? other : THROW_CCE();
    if (!equals(this.jhr_1, tmp0_other_with_cast.jhr_1))
      return false;
    if (!equals(this.khr_1, tmp0_other_with_cast.khr_1))
      return false;
    if (!equals(this.lhr_1, tmp0_other_with_cast.lhr_1))
      return false;
    if (!equals(this.mhr_1, tmp0_other_with_cast.mhr_1))
      return false;
    if (!(this.nhr_1 === tmp0_other_with_cast.nhr_1))
      return false;
    return true;
  };
  function Success_2(width, height, gameWorld) {
    MapGeneratorResultV2.call(this);
    this.uhr_1 = width;
    this.vhr_1 = height;
    this.whr_1 = gameWorld;
  }
  protoOf(Success_2).xhr = function () {
    return this.whr_1;
  };
  protoOf(Success_2).toString = function () {
    return 'Success(width=' + new GameUnit(this.uhr_1) + ', height=' + new GameUnit(this.vhr_1) + ', gameWorld=' + this.whr_1 + ')';
  };
  protoOf(Success_2).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.uhr_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.vhr_1) | 0;
    result = imul(result, 31) + hashCode(this.whr_1) | 0;
    return result;
  };
  protoOf(Success_2).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Success_2))
      return false;
    var tmp0_other_with_cast = other instanceof Success_2 ? other : THROW_CCE();
    if (!equals(this.uhr_1, tmp0_other_with_cast.uhr_1))
      return false;
    if (!equals(this.vhr_1, tmp0_other_with_cast.vhr_1))
      return false;
    if (!equals(this.whr_1, tmp0_other_with_cast.whr_1))
      return false;
    return true;
  };
  function Failure_1(width, height, gameWorld, errors) {
    MapGeneratorResultV2.call(this);
    this.yhr_1 = width;
    this.zhr_1 = height;
    this.ahs_1 = gameWorld;
    this.bhs_1 = errors;
  }
  protoOf(Failure_1).xhr = function () {
    return this.ahs_1;
  };
  protoOf(Failure_1).toString = function () {
    return 'Failure(width=' + new GameUnit(this.yhr_1) + ', height=' + new GameUnit(this.zhr_1) + ', gameWorld=' + this.ahs_1 + ', errors=' + this.bhs_1 + ')';
  };
  protoOf(Failure_1).hashCode = function () {
    var result = GameUnit__hashCode_impl_playmc(this.yhr_1);
    result = imul(result, 31) + GameUnit__hashCode_impl_playmc(this.zhr_1) | 0;
    result = imul(result, 31) + hashCode(this.ahs_1) | 0;
    result = imul(result, 31) + hashCode(this.bhs_1) | 0;
    return result;
  };
  protoOf(Failure_1).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof Failure_1))
      return false;
    var tmp0_other_with_cast = other instanceof Failure_1 ? other : THROW_CCE();
    if (!equals(this.yhr_1, tmp0_other_with_cast.yhr_1))
      return false;
    if (!equals(this.zhr_1, tmp0_other_with_cast.zhr_1))
      return false;
    if (!equals(this.ahs_1, tmp0_other_with_cast.ahs_1))
      return false;
    if (!equals(this.bhs_1, tmp0_other_with_cast.bhs_1))
      return false;
    return true;
  };
  function MapGeneratorResultV2() {
  }
  function Companion_0() {
    Companion_instance_0 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(RandomMapGeneratorV2).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.chs_1 = tmp$ret$0;
  }
  protoOf(Companion_0).dhs = function (config) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = this.chs_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.random.Companion.generate.<anonymous>' call
      tmp$ret$0 = 'Generating random map for config: ' + config;
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return (new RandomMapGeneratorV2(config)).ihs();
  };
  var Companion_instance_0;
  function Companion_getInstance_26() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function RandomMapGeneratorV2(config) {
    Companion_getInstance_26();
    this.ehs_1 = config;
    this.fhs_1 = new GameWorld();
    this.ghs_1 = Random(this.ehs_1.dhr_1);
    this.hhs_1 = new GenerationContext(this.ehs_1.fhr_1, this.ehs_1.ghr_1, this.fhs_1, this.ghs_1, this.ehs_1.ihr_1);
  }
  protoOf(RandomMapGeneratorV2).ihs = function () {
    var tmp0_iterator = this.ehs_1.ehr_1.h();
    while (tmp0_iterator.j()) {
      var it = tmp0_iterator.k();
      try {
        it.jhs(this.hhs_1);
      } catch ($p) {
        if ($p instanceof RandomMapGeneratorMaxAttemptsError) {
          var e = $p;
          return new Failure_1(this.ehs_1.fhr_1, this.ehs_1.ghr_1, this.fhs_1, listOf(ensureNotNull(e.message)));
        } else {
          throw $p;
        }
      }
    }
    return new Success_2(this.ehs_1.fhr_1, this.ehs_1.ghr_1, this.fhs_1);
  };
  function CheckpointsGenerator$run$lambda($i) {
    return function () {
      return 'Failed to create place CHECKPOINT ' + $i + '.';
    };
  }
  function CheckpointsGenerator$run$lambda_0($i, $position, $size) {
    return function ($this$addEntityReturnStateful) {
      $this$addEntityReturnStateful.ag3(StagingEntityUtils_getInstance().khs($i, $position._v, $size));
      return Unit_getInstance();
    };
  }
  function CheckpointsGenerator(numCheckpoints) {
    this.lhs_1 = numCheckpoints;
  }
  protoOf(CheckpointsGenerator).jhs = function (context) {
    var startEntity = context.ohr_1.og3(Companion_getInstance_15().zg1([getKClass(EntityStartComponent)]));
    var finishEntity = context.ohr_1.og3(Companion_getInstance_15().zg1([getKClass(EntityFinishComponent)]));
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var addedCheckpoints = tmp$ret$0;
    var size = context.qhr(MapEntityType_CHECKPOINT_getInstance());
    var inductionVariable = 0;
    var last = this.lhs_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var position = {_v: null};
        $l$1: do {
          $l$0: do {
            context.rhr(CheckpointsGenerator$run$lambda(i));
            position._v = context.shr(size);
          }
           while (false);
          var tmp;
          if (intersectsEntity(startEntity, position._v, size) ? true : intersectsEntity(finishEntity, position._v, size)) {
            tmp = true;
          } else {
            var tmp$ret$1;
            $l$block_0: {
              // Inline function 'kotlin.collections.any' call
              var tmp_0;
              if (isInterface(addedCheckpoints, Collection)) {
                tmp_0 = addedCheckpoints.p();
              } else {
                tmp_0 = false;
              }
              if (tmp_0) {
                tmp$ret$1 = false;
                break $l$block_0;
              }
              var tmp0_iterator = addedCheckpoints.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                var tmp$ret$2;
                // Inline function 'com.xenotactic.korge.random.generators.CheckpointsGenerator.run.<anonymous>' call
                tmp$ret$2 = intersectsEntity(element, position._v, size);
                if (tmp$ret$2) {
                  tmp$ret$1 = true;
                  break $l$block_0;
                }
              }
              tmp$ret$1 = false;
            }
            tmp = tmp$ret$1;
          }
        }
         while (tmp);
        var addedCheckpoint = context.ohr_1.hg3(CheckpointsGenerator$run$lambda_0(i, position, size));
        addedCheckpoints.b(addedCheckpoint);
      }
       while (inductionVariable < last);
  };
  function FinishGenerator$run$lambda() {
    return "Failed to create FINISH entity in a spot that didn't intersect with START.";
  }
  function FinishGenerator$run$lambda_0($position, $size) {
    return function ($this$addEntity) {
      $this$addEntity.ag3(StagingEntityUtils_getInstance().mhs($position._v, $size));
      return Unit_getInstance();
    };
  }
  function FinishGenerator() {
    FinishGenerator_instance = this;
  }
  protoOf(FinishGenerator).jhs = function (context) {
    var startEntity = context.ohr_1.og3(Companion_getInstance_15().zg1([getKClass(EntityStartComponent)]));
    var size = context.qhr(MapEntityType_FINISH_getInstance());
    var position = {_v: null};
    do {
      context.rhr(FinishGenerator$run$lambda);
      position._v = context.shr(size);
    }
     while (intersectsEntity(startEntity, position._v, size));
    context.ohr_1.fg3(FinishGenerator$run$lambda_0(position, size));
  };
  var FinishGenerator_instance;
  function FinishGenerator_getInstance() {
    if (FinishGenerator_instance == null)
      new FinishGenerator();
    return FinishGenerator_instance;
  }
  function RocksGenerator$run$lambda($i) {
    return function () {
      return 'Failed to create place ROCK ' + $i + '.';
    };
  }
  function RocksGenerator$run$lambda_0($rock) {
    return function ($this$addEntity) {
      $this$addEntity.ag3(StagingEntityUtils_getInstance().ohh($rock._v));
      return Unit_getInstance();
    };
  }
  function RocksGenerator(numRocks) {
    this.nhs_1 = numRocks;
    this.ohs_1 = Companion_getInstance_14().z6e(2, 4);
    this.phs_1 = Companion_getInstance_14().z6e(4, 2);
  }
  protoOf(RocksGenerator).jhs = function (context) {
    var width = context.jhr_1;
    var height = context.khr_1;
    var startEntity = context.lhr_1.ngc();
    var finishEntity = context.lhr_1.ogc();
    var addedCheckpoints = context.lhr_1.pgc();
    var addedTpIns = context.lhr_1.qgc();
    var addedTpOuts = context.lhr_1.rgc();
    var tmp$ret$2;
    // Inline function 'kotlin.let' call
    var tmp0_let = context.lhr_1.ygc(width, height);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'com.xenotactic.korge.random.generators.RocksGenerator.run.<anonymous>' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(tmp0_let instanceof Success)) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    tmp$ret$1 = tmp0_let.xgj_1.sgj();
    tmp$ret$2 = tmp$ret$1;
    var currentPath = tmp$ret$2;
    var tmp$ret$3;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$3 = ArrayList_init_$Create$();
    var addedRocks = tmp$ret$3;
    var inductionVariable = 0;
    var last = this.nhs_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var rock = {_v: null};
        $l$loop_0: do {
          context.rhr(RocksGenerator$run$lambda(i));
          var rockDimensions = context.mhr_1.n9() ? this.ohs_1 : this.phs_1;
          var rockPosition = context.shr(rockDimensions);
          rock._v = new RectangleEntity(rockPosition.dg6_1, rockPosition.eg6_1, rockDimensions.kgb(), rockDimensions.lgb());
          var newRockList = plus(addedRocks, rock._v);
          var tmp;
          var tmp_0;
          var tmp_1;
          if (isFullyCoveredBy(startEntity, newRockList) ? true : isFullyCoveredBy(finishEntity, newRockList)) {
            tmp_1 = true;
          } else {
            var tmp$ret$4;
            $l$block_0: {
              // Inline function 'kotlin.collections.any' call
              var tmp_2;
              if (isInterface(addedCheckpoints, Collection)) {
                tmp_2 = addedCheckpoints.p();
              } else {
                tmp_2 = false;
              }
              if (tmp_2) {
                tmp$ret$4 = false;
                break $l$block_0;
              }
              var tmp0_iterator = addedCheckpoints.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                var tmp$ret$5;
                // Inline function 'com.xenotactic.korge.random.generators.RocksGenerator.run.<anonymous>' call
                tmp$ret$5 = isFullyCoveredBy(element, newRockList);
                if (tmp$ret$5) {
                  tmp$ret$4 = true;
                  break $l$block_0;
                }
              }
              tmp$ret$4 = false;
            }
            tmp_1 = tmp$ret$4;
          }
          if (tmp_1) {
            tmp_0 = true;
          } else {
            var tmp$ret$6;
            $l$block_2: {
              // Inline function 'kotlin.collections.any' call
              var tmp_3;
              if (isInterface(addedTpIns, Collection)) {
                tmp_3 = addedTpIns.p();
              } else {
                tmp_3 = false;
              }
              if (tmp_3) {
                tmp$ret$6 = false;
                break $l$block_2;
              }
              var tmp0_iterator_0 = addedTpIns.h();
              while (tmp0_iterator_0.j()) {
                var element_0 = tmp0_iterator_0.k();
                var tmp$ret$7;
                // Inline function 'com.xenotactic.korge.random.generators.RocksGenerator.run.<anonymous>' call
                tmp$ret$7 = isFullyCoveredBy(element_0, newRockList);
                if (tmp$ret$7) {
                  tmp$ret$6 = true;
                  break $l$block_2;
                }
              }
              tmp$ret$6 = false;
            }
            tmp_0 = tmp$ret$6;
          }
          if (tmp_0) {
            tmp = true;
          } else {
            var tmp$ret$8;
            $l$block_4: {
              // Inline function 'kotlin.collections.any' call
              var tmp_4;
              if (isInterface(addedTpOuts, Collection)) {
                tmp_4 = addedTpOuts.p();
              } else {
                tmp_4 = false;
              }
              if (tmp_4) {
                tmp$ret$8 = false;
                break $l$block_4;
              }
              var tmp0_iterator_1 = addedTpOuts.h();
              while (tmp0_iterator_1.j()) {
                var element_1 = tmp0_iterator_1.k();
                var tmp$ret$9;
                // Inline function 'com.xenotactic.korge.random.generators.RocksGenerator.run.<anonymous>' call
                tmp$ret$9 = isFullyCoveredBy(element_1, newRockList);
                if (tmp$ret$9) {
                  tmp$ret$8 = true;
                  break $l$block_4;
                }
              }
              tmp$ret$8 = false;
            }
            tmp = tmp$ret$8;
          }
          if (tmp) {
            continue $l$loop_0;
          }
          if (currentPath.wgk(rock._v.wg8())) {
            var possibleNewPath = context.lhr_1.ygc(width, height, listOf(rock._v));
            var tmp1_subject = possibleNewPath;
            if (tmp1_subject instanceof Failure)
              continue $l$loop_0;
            else {
              if (tmp1_subject instanceof Success)
                currentPath = possibleNewPath.xgj_1.sgj();
            }
          }
          break $l$loop_0;
        }
         while (true);
        addedRocks.b(rock._v);
        context.ohr_1.fg3(RocksGenerator$run$lambda_0(rock));
      }
       while (inductionVariable < last);
  };
  function SpeedAreaGenerator$run$lambda($position, $diameter, $speedEffect) {
    return function ($this$addEntity) {
      $this$addEntity.ag3(StagingEntityUtils_getInstance().qhs($position, $diameter, $speedEffect));
      return Unit_getInstance();
    };
  }
  function SpeedAreaGenerator(numSpeedAreas) {
    this.rhs_1 = numSpeedAreas;
  }
  protoOf(SpeedAreaGenerator).jhs = function (context) {
    // Inline function 'kotlin.repeat' call
    var tmp0_repeat = this.rhs_1;
    // Inline function 'kotlin.contracts.contract' call
    var inductionVariable = 0;
    if (inductionVariable < tmp0_repeat)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'com.xenotactic.korge.random.generators.SpeedAreaGenerator.run.<anonymous>' call
        var radius = context.mhr_1.m9(1, 11);
        var diameter = toGameUnit(imul(radius, 2));
        var speedEffect = context.mhr_1.p9(0.25, 1.9);
        var position = context.thr(diameter, diameter);
        context.ohr_1.fg3(SpeedAreaGenerator$run$lambda(position, diameter, speedEffect));
      }
       while (inductionVariable < tmp0_repeat);
  };
  function StartGenerator$run$lambda($point, $size) {
    return function ($this$addEntity) {
      $this$addEntity.ag3(StagingEntityUtils_getInstance().shs($point, $size));
      return Unit_getInstance();
    };
  }
  function StartGenerator() {
    StartGenerator_instance = this;
  }
  protoOf(StartGenerator).jhs = function (context) {
    var size = context.qhr(MapEntityType_START_getInstance());
    var point = context.shr(size);
    context.ohr_1.fg3(StartGenerator$run$lambda(point, size));
  };
  var StartGenerator_instance;
  function StartGenerator_getInstance() {
    if (StartGenerator_instance == null)
      new StartGenerator();
    return StartGenerator_instance;
  }
  function TeleportsGenerator$run$lambda($i) {
    return function () {
      return 'Failed to create place TELEPORT IN ' + $i + '.';
    };
  }
  function TeleportsGenerator$run$lambda_0($i, $teleportInPosition, $attemptedPlacementPoints) {
    return function () {
      return trimIndent('\n                                Failed to place TELEPORT OUT ' + $i + '.\n                                stagedTeleportIn: ' + $teleportInPosition._v + '\n                                Attempted points: ' + $attemptedPlacementPoints + '\n                            ');
    };
  }
  function TeleportsGenerator$run$lambda_1($i, $teleportInPosition, $teleportInSize) {
    return function ($this$addEntityReturnStateful) {
      $this$addEntityReturnStateful.ag3(StagingEntityUtils_getInstance().ths($i, $teleportInPosition._v, $teleportInSize));
      return Unit_getInstance();
    };
  }
  function TeleportsGenerator$run$lambda_2($i, $teleportOutPosition, $teleportOutSize) {
    return function ($this$addEntityReturnStateful) {
      $this$addEntityReturnStateful.ag3(StagingEntityUtils_getInstance().uhs($i, $teleportOutPosition._v, $teleportOutSize));
      return Unit_getInstance();
    };
  }
  function TeleportsGenerator(numTeleports) {
    this.vhs_1 = numTeleports;
  }
  protoOf(TeleportsGenerator).jhs = function (context) {
    var startEntity = context.lhr_1.ngc();
    var finishEntity = context.lhr_1.ogc();
    var addedCheckpoints = context.lhr_1.pgc();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    var addedTpIns = tmp$ret$0;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$1 = ArrayList_init_$Create$();
    var addedTpOuts = tmp$ret$1;
    var teleportInSize = context.qhr(MapEntityType_TELEPORT_IN_getInstance());
    var teleportOutSize = context.qhr(MapEntityType_TELEPORT_OUT_getInstance());
    var inductionVariable = 0;
    var last = this.vhs_1;
    if (inductionVariable < last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var teleportInPosition = {_v: null};
        $l$1: do {
          $l$0: do {
            context.rhr(TeleportsGenerator$run$lambda(i));
            teleportInPosition._v = context.shr(teleportInSize);
          }
           while (false);
          var tmp;
          var tmp_0;
          var tmp_1;
          if (intersectsEntity(startEntity, teleportInPosition._v, teleportInSize) ? true : intersectsEntity(finishEntity, teleportInPosition._v, teleportInSize)) {
            tmp_1 = true;
          } else {
            var tmp$ret$2;
            $l$block_0: {
              // Inline function 'kotlin.collections.any' call
              var tmp_2;
              if (isInterface(addedCheckpoints, Collection)) {
                tmp_2 = addedCheckpoints.p();
              } else {
                tmp_2 = false;
              }
              if (tmp_2) {
                tmp$ret$2 = false;
                break $l$block_0;
              }
              var tmp0_iterator = addedCheckpoints.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                var tmp$ret$3;
                // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
                tmp$ret$3 = intersectsEntity(element, teleportInPosition._v, teleportInSize);
                if (tmp$ret$3) {
                  tmp$ret$2 = true;
                  break $l$block_0;
                }
              }
              tmp$ret$2 = false;
            }
            tmp_1 = tmp$ret$2;
          }
          if (tmp_1) {
            tmp_0 = true;
          } else {
            var tmp$ret$4;
            $l$block_2: {
              // Inline function 'kotlin.collections.any' call
              var tmp_3;
              if (isInterface(addedTpIns, Collection)) {
                tmp_3 = addedTpIns.p();
              } else {
                tmp_3 = false;
              }
              if (tmp_3) {
                tmp$ret$4 = false;
                break $l$block_2;
              }
              var tmp0_iterator_0 = addedTpIns.h();
              while (tmp0_iterator_0.j()) {
                var element_0 = tmp0_iterator_0.k();
                var tmp$ret$5;
                // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
                tmp$ret$5 = intersectsEntity(element_0, teleportInPosition._v, teleportInSize);
                if (tmp$ret$5) {
                  tmp$ret$4 = true;
                  break $l$block_2;
                }
              }
              tmp$ret$4 = false;
            }
            tmp_0 = tmp$ret$4;
          }
          if (tmp_0) {
            tmp = true;
          } else {
            var tmp$ret$6;
            $l$block_4: {
              // Inline function 'kotlin.collections.any' call
              var tmp_4;
              if (isInterface(addedTpOuts, Collection)) {
                tmp_4 = addedTpOuts.p();
              } else {
                tmp_4 = false;
              }
              if (tmp_4) {
                tmp$ret$6 = false;
                break $l$block_4;
              }
              var tmp0_iterator_1 = addedTpOuts.h();
              while (tmp0_iterator_1.j()) {
                var element_1 = tmp0_iterator_1.k();
                var tmp$ret$7;
                // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
                tmp$ret$7 = intersectsEntity(element_1, teleportInPosition._v, teleportInSize);
                if (tmp$ret$7) {
                  tmp$ret$6 = true;
                  break $l$block_4;
                }
              }
              tmp$ret$6 = false;
            }
            tmp = tmp$ret$6;
          }
        }
         while (tmp);
        var teleportOutPosition = {_v: null};
        var tmp$ret$8;
        // Inline function 'kotlin.collections.mutableSetOf' call
        tmp$ret$8 = LinkedHashSet_init_$Create$();
        var attemptedPlacementPoints = tmp$ret$8;
        var isFirstAttempt = true;
        $l$3: do {
          $l$2: do {
            do {
              if (!isFirstAttempt) {
                context.rhr(TeleportsGenerator$run$lambda_0(i, teleportInPosition, attemptedPlacementPoints));
              }
              teleportOutPosition._v = context.shr(teleportOutSize);
              isFirstAttempt = false;
            }
             while (attemptedPlacementPoints.q(teleportOutPosition._v));
            attemptedPlacementPoints.b(teleportOutPosition._v);
          }
           while (false);
          var tmp_5;
          var tmp_6;
          var tmp_7;
          if ((intersectRectangles_0(teleportInPosition._v, teleportInSize, teleportOutPosition._v, teleportOutSize) ? true : intersectsEntity(startEntity, teleportOutPosition._v, teleportOutSize)) ? true : intersectsEntity(finishEntity, teleportOutPosition._v, teleportOutSize)) {
            tmp_7 = true;
          } else {
            var tmp$ret$9;
            $l$block_6: {
              // Inline function 'kotlin.collections.any' call
              var tmp_8;
              if (isInterface(addedTpIns, Collection)) {
                tmp_8 = addedTpIns.p();
              } else {
                tmp_8 = false;
              }
              if (tmp_8) {
                tmp$ret$9 = false;
                break $l$block_6;
              }
              var tmp0_iterator_2 = addedTpIns.h();
              while (tmp0_iterator_2.j()) {
                var element_2 = tmp0_iterator_2.k();
                var tmp$ret$10;
                // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
                tmp$ret$10 = intersectsEntity(element_2, teleportOutPosition._v, teleportOutSize);
                if (tmp$ret$10) {
                  tmp$ret$9 = true;
                  break $l$block_6;
                }
              }
              tmp$ret$9 = false;
            }
            tmp_7 = tmp$ret$9;
          }
          if (tmp_7) {
            tmp_6 = true;
          } else {
            var tmp$ret$11;
            $l$block_8: {
              // Inline function 'kotlin.collections.any' call
              var tmp_9;
              if (isInterface(addedCheckpoints, Collection)) {
                tmp_9 = addedCheckpoints.p();
              } else {
                tmp_9 = false;
              }
              if (tmp_9) {
                tmp$ret$11 = false;
                break $l$block_8;
              }
              var tmp0_iterator_3 = addedCheckpoints.h();
              while (tmp0_iterator_3.j()) {
                var element_3 = tmp0_iterator_3.k();
                var tmp$ret$12;
                // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
                tmp$ret$12 = intersectsEntity(element_3, teleportOutPosition._v, teleportOutSize);
                if (tmp$ret$12) {
                  tmp$ret$11 = true;
                  break $l$block_8;
                }
              }
              tmp$ret$11 = false;
            }
            tmp_6 = tmp$ret$11;
          }
          if (tmp_6) {
            tmp_5 = true;
          } else {
            var tmp_10 = PathFinder_getInstance();
            var tmp_11 = toRectangleEntity(startEntity);
            var tmp_12 = toRectangleEntity(finishEntity);
            var tmp_13 = emptyList();
            var tmp$ret$15;
            // Inline function 'kotlin.collections.map' call
            var tmp$ret$14;
            // Inline function 'kotlin.collections.mapTo' call
            var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(addedCheckpoints, 10));
            var tmp0_iterator_4 = addedCheckpoints.h();
            while (tmp0_iterator_4.j()) {
              var item = tmp0_iterator_4.k();
              var tmp$ret$13;
              // Inline function 'com.xenotactic.korge.random.generators.TeleportsGenerator.run.<anonymous>' call
              tmp$ret$13 = toRectangleEntity(item);
              tmp0_mapTo.b(tmp$ret$13);
            }
            tmp$ret$14 = tmp0_mapTo;
            tmp$ret$15 = tmp$ret$14;
            var tmp_14 = tmp_10.xgc(context.jhr_1, context.khr_1, tmp_11, tmp_12, tmp_13, tmp$ret$15, listOf(new TeleportPair(new RectangleEntity(teleportInPosition._v.dg6_1, teleportInPosition._v.eg6_1, teleportInSize.kgb(), teleportInSize.lgb()), new RectangleEntity(teleportOutPosition._v.dg6_1, teleportOutPosition._v.eg6_1, teleportOutSize.kgb(), teleportOutSize.lgb()), i)));
            tmp_5 = tmp_14 instanceof Failure;
          }
        }
         while (tmp_5);
        var addedTeleportIn = context.ohr_1.hg3(TeleportsGenerator$run$lambda_1(i, teleportInPosition, teleportInSize));
        addedTpIns.b(addedTeleportIn);
        var addedTeleportOut = context.ohr_1.hg3(TeleportsGenerator$run$lambda_2(i, teleportOutPosition, teleportOutSize));
        addedTpOuts.b(addedTeleportOut);
      }
       while (inductionVariable < last);
  };
  function EditorSceneV2$sceneInit$slambda($uiMapV2, resultContinuation) {
    this.eht_1 = $uiMapV2;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(EditorSceneV2$sceneInit$slambda).ght = function (it, $completion) {
    var tmp = this.hht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(EditorSceneV2$sceneInit$slambda).g8 = function (p1, $completion) {
    return this.ght(p1 instanceof UpdatedPathLineEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(EditorSceneV2$sceneInit$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.eht_1.iht(this.fht_1.dg8_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(EditorSceneV2$sceneInit$slambda).hht = function (it, completion) {
    var i = new EditorSceneV2$sceneInit$slambda(this.eht_1, completion);
    i.fht_1 = it;
    return i;
  };
  function EditorSceneV2$sceneInit$slambda_0($uiMapV2, resultContinuation) {
    var i = new EditorSceneV2$sceneInit$slambda($uiMapV2, resultContinuation);
    var l = function (it, $completion) {
      return i.ght(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function EditorSceneV2$sceneInit$slambda_1($uiMapV2, resultContinuation) {
    this.rht_1 = $uiMapV2;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(EditorSceneV2$sceneInit$slambda_1).tht = function (it, $completion) {
    var tmp = this.uht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(EditorSceneV2$sceneInit$slambda_1).g8 = function (p1, $completion) {
    return this.tht(p1 instanceof ResizeMapEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(EditorSceneV2$sceneInit$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.rht_1.vht(this.sht_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(EditorSceneV2$sceneInit$slambda_1).uht = function (it, completion) {
    var i = new EditorSceneV2$sceneInit$slambda_1(this.rht_1, completion);
    i.sht_1 = it;
    return i;
  };
  function EditorSceneV2$sceneInit$slambda_2($uiMapV2, resultContinuation) {
    var i = new EditorSceneV2$sceneInit$slambda_1($uiMapV2, resultContinuation);
    var l = function (it, $completion) {
      return i.tht(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function EditorSceneV2() {
    Scene.call(this);
  }
  protoOf(EditorSceneV2).mb4 = function (_this__u8e3s4, $completion) {
    var eventBus = new EventBus(this);
    var gameWorld = new World();
    var mouseDragSettingsState = new MouseDragSettingsState();
    var width = toGameUnit(10);
    var height = toGameUnit(10);
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new Engine(eventBus, new GameWorld(gameWorld));
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    tmp0_apply.ggo_1.xg2(new GameMapDimensionsState(tmp0_apply, width, height));
    tmp0_apply.hgo_1.xg2(new GameMapApi(tmp0_apply));
    tmp0_apply.hgo_1.xg2(mouseDragSettingsState);
    tmp$ret$0 = tmp0_apply;
    var engine = tmp$ret$0;
    var uiMapV2 = addTo(new UIMapV2(engine), _this__u8e3s4);
    centerOnStage(uiMapV2);
    var mouseDragInputProcessor = new MouseDragInputProcessor(this.gae(), uiMapV2, mouseDragSettingsState.ghu_1);
    mouseDragInputProcessor.bhb(_this__u8e3s4);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    engine.ggo_1.xg2(new EditorState(engine));
    engine.hgo_1.xg2(mouseDragInputProcessor);
    engine.hgo_1.xg2(uiMapV2);
    tmp$ret$1 = engine;
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    gameWorld.zfz_1 = engine.hgo_1;
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp1_addComponentListener = new PreSelectionComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp0_addComponentListener = gameWorld.bg0_1;
    var tmp = tmp0_addComponentListener.lg1(getKClass(PreSelectionComponent));
    var container = tmp instanceof ComponentEntityContainer ? tmp : THROW_CCE();
    container.yg0(tmp1_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp3_addComponentListener = new SelectionComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp2_addComponentListener = gameWorld.bg0_1;
    var tmp_0 = tmp2_addComponentListener.lg1(getKClass(SelectedComponent));
    var container_0 = tmp_0 instanceof ComponentEntityContainer ? tmp_0 : THROW_CCE();
    container_0.yg0(tmp3_addComponentListener);
    tmp$ret$2 = gameWorld;
    var editorPlacementInputProcessor = new EditorPlacementInputProcessor(this.gae(), _this__u8e3s4, engine);
    editorPlacementInputProcessor.bhb(_this__u8e3s4);
    var cameraInputProcessor = new CameraInputProcessor(uiMapV2, engine);
    cameraInputProcessor.bhb(_this__u8e3s4);
    var keyInputProcessor = new KeyInputProcessor(_this__u8e3s4, engine);
    keyInputProcessor.bhb(_this__u8e3s4);
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp1_apply = new SelectorMouseProcessorV2(this.gae(), _this__u8e3s4, engine);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    engine.hgo_1.xg2(tmp1_apply);
    tmp$ret$3 = tmp1_apply;
    var selectorMouseProcessorV2 = tmp$ret$3;
    selectorMouseProcessorV2.bhb(_this__u8e3s4);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(new UIEditorButtonsV2(engine, _this__u8e3s4), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    tmp2_apply.whw();
    tmp$ret$4 = tmp2_apply;
    var uiEditorButtonsV2 = tmp$ret$4;
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(new UINotificationText(engine, 'N/A'), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.EditorSceneV2.sceneInit.<anonymous>' call
    centerXOnStage(tmp3_apply);
    tmp$ret$5 = tmp3_apply;
    var notificationText = tmp$ret$5;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp4_register = EditorSceneV2$sceneInit$slambda_0(uiMapV2, null);
    tmp$ret$6 = eventBus.cg7(getKClass(UpdatedPathLineEvent), tmp4_register);
    var tmp$ret$7;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp5_register = EditorSceneV2$sceneInit$slambda_2(uiMapV2, null);
    tmp$ret$7 = eventBus.cg7(getKClass(ResizeMapEvent), tmp5_register);
    return Unit_getInstance();
  };
  function GameScene$sceneInit$slambda$slambda(this$0, resultContinuation) {
    this.fhx_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GameScene$sceneInit$slambda$slambda).c1o = function ($this$launch, $completion) {
    var tmp = this.d1o($this$launch, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameScene$sceneInit$slambda$slambda).g8 = function (p1, $completion) {
    return this.c1o((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GameScene$sceneInit$slambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.fhx_1.cb1().tbd(VOID, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GameScene$sceneInit$slambda$slambda).d1o = function ($this$launch, completion) {
    var i = new GameScene$sceneInit$slambda$slambda(this.fhx_1, completion);
    i.ghx_1 = $this$launch;
    return i;
  };
  function GameScene$sceneInit$slambda$slambda_0(this$0, resultContinuation) {
    var i = new GameScene$sceneInit$slambda$slambda(this$0, resultContinuation);
    var l = function ($this$launch, $completion) {
      return i.c1o($this$launch, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function Companion_1() {
    Companion_instance_1 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(GameScene).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.hhx_1 = tmp$ret$0;
  }
  var Companion_instance_1;
  function Companion_getInstance_27() {
    if (Companion_instance_1 == null)
      new Companion_1();
    return Companion_instance_1;
  }
  function GameScene$sceneInit$lambda($tmp4_apply) {
    return function ($this$onStageResized, width, height) {
      alignRightToRightOfWindow($tmp4_apply);
      alignBottomToBottomOfWindow($tmp4_apply);
      return Unit_getInstance();
    };
  }
  function GameScene$sceneInit$slambda($uiMap, resultContinuation) {
    this.qhx_1 = $uiMap;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GameScene$sceneInit$slambda).uhx = function (it, $completion) {
    var tmp = this.vhx(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameScene$sceneInit$slambda).g8 = function (p1, $completion) {
    return this.uhx(p1 instanceof UIPlacementButton ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GameScene$sceneInit$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            println('Button clicked: ' + this.rhx_1);
            this.shx_1 = this.rhx_1;
            this.thx_1 = this.shx_1.b2_1;
            if (this.thx_1 === 0) {
              this.y7_1 = 1;
              suspendResult = this.qhx_1.whx(this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 2;
              continue $sm;
            }

            break;
          case 1:
            this.y7_1 = 2;
            continue $sm;
          case 2:
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GameScene$sceneInit$slambda).vhx = function (it, completion) {
    var i = new GameScene$sceneInit$slambda(this.qhx_1, completion);
    i.rhx_1 = it;
    return i;
  };
  function GameScene$sceneInit$slambda_0($uiMap, resultContinuation) {
    var i = new GameScene$sceneInit$slambda($uiMap, resultContinuation);
    var l = function (it, $completion) {
      return i.uhx(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GameScene$sceneInit$lambda_0($tmp7_apply) {
    return function ($this$onStageResized, width, height) {
      alignBottomToBottomOfWindow($tmp7_apply);
      return Unit_getInstance();
    };
  }
  function GameScene$sceneInit$slambda_1($tmp7_apply, resultContinuation) {
    this.fhy_1 = $tmp7_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GameScene$sceneInit$slambda_1).ght = function (it, $completion) {
    var tmp = this.hht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameScene$sceneInit$slambda_1).g8 = function (p1, $completion) {
    return this.ght(p1 instanceof UpdatedPathLineEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GameScene$sceneInit$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.fhy_1.ri0(this.ghy_1.eg8_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GameScene$sceneInit$slambda_1).hht = function (it, completion) {
    var i = new GameScene$sceneInit$slambda_1(this.fhy_1, completion);
    i.ghy_1 = it;
    return i;
  };
  function GameScene$sceneInit$slambda_2($tmp7_apply, resultContinuation) {
    var i = new GameScene$sceneInit$slambda_1($tmp7_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.ght(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GameScene$sceneInit$lambda_1(this$0, $uiMap, $MOVE_MAP_DELTA) {
    return function ($this$addFixedUpdater) {
      var keys = this$0.gae().i3();
      var tmp;
      if (keys.saa(Key_UP_getInstance())) {
        $uiMap.dbl($uiMap.f6g() + $MOVE_MAP_DELTA);
        tmp = Unit_getInstance();
      }
      var tmp_0;
      if (keys.saa(Key_DOWN_getInstance())) {
        $uiMap.dbl($uiMap.f6g() - $MOVE_MAP_DELTA);
        tmp_0 = Unit_getInstance();
      }
      var tmp_1;
      if (keys.saa(Key_LEFT_getInstance())) {
        $uiMap.cbl($uiMap.e6g() + $MOVE_MAP_DELTA);
        tmp_1 = Unit_getInstance();
      }
      var tmp_2;
      if (keys.saa(Key_RIGHT_getInstance())) {
        $uiMap.cbl($uiMap.e6g() - $MOVE_MAP_DELTA);
        tmp_2 = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function GameScene$sceneInit$slambda_3(this$0, resultContinuation) {
    this.ai1_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GameScene$sceneInit$slambda_3).ci1 = function (it, $completion) {
    var tmp = this.di1(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GameScene$sceneInit$slambda_3).g8 = function (p1, $completion) {
    return this.ci1(p1 instanceof ExitGameSceneEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GameScene$sceneInit$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          launch(this.ai1_1, VOID, VOID, GameScene$sceneInit$slambda$slambda_0(this.ai1_1, null));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GameScene$sceneInit$slambda_3).di1 = function (it, completion) {
    var i = new GameScene$sceneInit$slambda_3(this.ai1_1, completion);
    i.bi1_1 = it;
    return i;
  };
  function GameScene$sceneInit$slambda_4(this$0, resultContinuation) {
    var i = new GameScene$sceneInit$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ci1(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GameScene(mapBridge) {
    Companion_getInstance_27();
    Scene.call(this);
    this.oi1_1 = mapBridge;
    this.pi1_1 = new EventBus(MainScope());
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.debug' call
    var tmp1_debug = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_DEBUG_getInstance();
    var tmp;
    if (tmp1_debug.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.<anonymous>' call
      tmp$ret$0 = 'init called';
      tmp1_debug.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
  }
  protoOf(GameScene).mb4 = function (_this__u8e3s4, $completion) {
    Companion_getInstance_1().x1m(Level_DEBUG_getInstance());
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.debug' call
    var tmp1_debug = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_DEBUG_getInstance();
    var tmp;
    if (tmp1_debug.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneInit.<anonymous>' call
      tmp$ret$0 = 'sceneInit called';
      tmp1_debug.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    var engine = new Engine(this.pi1_1);
    var gameMapControllerComponent = new GameMapControllerEComponent(engine, this.pi1_1);
    var objectPlacementComponent = new ObjectPlacementEComponent();
    engine.hgo_1.xg2(gameMapControllerComponent);
    engine.hgo_1.xg2(objectPlacementComponent);
    var gameMap = this.oi1_1.qi1_1;
    gameMapControllerComponent.sha(gameMap);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = new UIMap(gameMap, engine, gameMapControllerComponent.oha_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneInit.<anonymous>' call
    draggable(tmp2_apply);
    tmp$ret$2 = tmp2_apply;
    var uiMap = tmp$ret$2;
    new MapRendererUpdater(engine, uiMap, this.pi1_1);
    engine.hgo_1.xg2(uiMap);
    var cameraInputProcessor = new CameraInputProcessor(uiMap, engine);
    cameraInputProcessor.dhc(0.7);
    cameraInputProcessor.bhb(_this__u8e3s4);
    var objectPlacementInputProcessor = new ObjectPlacementInputProcessor(_this__u8e3s4, uiMap, engine, this.pi1_1);
    objectPlacementInputProcessor.bhb(_this__u8e3s4);
    var resizeDebugComponent = new ResizeDebugComponent(_this__u8e3s4);
    resizeDebugComponent.bhb(_this__u8e3s4);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp$ret$3;
    // Inline function 'com.xenotactic.korge.ui.uiPlacement' call
    var tmp3_uiPlacement = this.pi1_1;
    tmp$ret$3 = addTo(new UIPlacement(engine, tmp3_uiPlacement), _this__u8e3s4);
    var tmp4_apply = tmp$ret$3;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneInit.<anonymous>' call
    onStageResized(tmp4_apply, true, GameScene$sceneInit$lambda(tmp4_apply));
    tmp4_apply.ei4_1.l1r(GameScene$sceneInit$slambda_0(uiMap, null));
    tmp$ret$4 = tmp4_apply;
    var uiPlacement = tmp$ret$4;
    var tmp$ret$7;
    // Inline function 'kotlin.run' call
    var tmp$ret$5;
    // Inline function 'com.xenotactic.korge.ui.uiActiveTextNotifier' call
    var tmp5_uiActiveTextNotifier = this.pi1_1;
    tmp$ret$5 = addTo(new UIActiveTextNotifier(engine, tmp5_uiActiveTextNotifier), _this__u8e3s4);
    var tmp6_run = tmp$ret$5;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$6;
    // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneInit.<anonymous>' call
    tmp$ret$6 = centerXOnStage(tmp6_run);
    tmp$ret$7 = tmp$ret$6;
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp8_addTo = new UIPathText();
    var tmp$ret$9;
    // Inline function 'kotlin.apply' call
    var tmp7_apply = addTo(tmp8_addTo, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneInit.<anonymous>' call
    onStageResized(tmp7_apply, true, GameScene$sceneInit$lambda_0(tmp7_apply));
    var tmp$ret$8;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.pi1_1;
    var tmp1_register = GameScene$sceneInit$slambda_2(tmp7_apply, null);
    tmp$ret$8 = tmp0_register.cg7(getKClass(UpdatedPathLineEvent), tmp1_register);
    var tmp0_safe_receiver = gameMapControllerComponent.oha_1;
    tmp7_apply.ri0(tmp0_safe_receiver == null ? null : tmp0_safe_receiver.ggk_1);
    tmp$ret$9 = tmp7_apply;
    tmp$ret$10 = tmp$ret$9;
    var pathText = tmp$ret$10;
    var keyInputProcessor = new KeyInputProcessor(_this__u8e3s4, engine);
    keyInputProcessor.bhb(_this__u8e3s4);
    var goalComponent = new GoalEComponent(engine, this.pi1_1);
    engine.hgo_1.xg2(goalComponent);
    var MOVE_MAP_DELTA = 7;
    var tmp_0 = _TimeSpan___init__impl__hqf6tm(10.0);
    addFixedUpdater(_this__u8e3s4, tmp_0, VOID, VOID, GameScene$sceneInit$lambda_1(this, uiMap, MOVE_MAP_DELTA));
    var tmp$ret$11;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp9_register = this.pi1_1;
    var tmp10_register = GameScene$sceneInit$slambda_4(this, null);
    tmp$ret$11 = tmp9_register.cg7(getKClass(ExitGameSceneEvent), tmp10_register);
    return Unit_getInstance();
  };
  protoOf(GameScene).ob4 = function ($completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneAfterInit.<anonymous>' call
      tmp$ret$0 = 'sceneAfterInit called';
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return Unit_getInstance();
  };
  protoOf(GameScene).qb4 = function ($completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.debug' call
    var tmp1_debug = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_DEBUG_getInstance();
    var tmp;
    if (tmp1_debug.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneDestroy.<anonymous>' call
      tmp$ret$0 = 'sceneDestroy called';
      tmp1_debug.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return Unit_getInstance();
  };
  protoOf(GameScene).pb4 = function ($completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.debug' call
    var tmp1_debug = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_DEBUG_getInstance();
    var tmp;
    if (tmp1_debug.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneBeforeLeaving.<anonymous>' call
      tmp$ret$0 = 'sceneBeforeLeaving called';
      tmp1_debug.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return Unit_getInstance();
  };
  protoOf(GameScene).kb4 = function ($completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.debug' call
    var tmp1_debug = Companion_getInstance_27().hhx_1;
    var tmp0_log = Level_DEBUG_getInstance();
    var tmp;
    if (tmp1_debug.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GameScene.sceneAfterDestroy.<anonymous>' call
      tmp$ret$0 = 'sceneAfterDestroy called';
      tmp1_debug.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return Unit_getInstance();
  };
  var MapFilterOptions_ALL_instance;
  var MapFilterOptions_GOOD_MAPS_ONLY_instance;
  var MapFilterOptions_BAD_MAPS_ONLY_instance;
  function values() {
    return [MapFilterOptions_ALL_getInstance(), MapFilterOptions_GOOD_MAPS_ONLY_getInstance(), MapFilterOptions_BAD_MAPS_ONLY_getInstance()];
  }
  function valueOf(value) {
    switch (value) {
      case 'ALL':
        return MapFilterOptions_ALL_getInstance();
      case 'GOOD_MAPS_ONLY':
        return MapFilterOptions_GOOD_MAPS_ONLY_getInstance();
      case 'BAD_MAPS_ONLY':
        return MapFilterOptions_BAD_MAPS_ONLY_getInstance();
      default:
        MapFilterOptions_initEntries();
        THROW_ISE();
        break;
    }
  }
  var MapFilterOptions_entriesInitialized;
  function MapFilterOptions_initEntries() {
    if (MapFilterOptions_entriesInitialized)
      return Unit_getInstance();
    MapFilterOptions_entriesInitialized = true;
    MapFilterOptions_ALL_instance = new MapFilterOptions('ALL', 0, 'All maps');
    MapFilterOptions_GOOD_MAPS_ONLY_instance = new MapFilterOptions('GOOD_MAPS_ONLY', 1, 'Good maps');
    MapFilterOptions_BAD_MAPS_ONLY_instance = new MapFilterOptions('BAD_MAPS_ONLY', 2, 'Bad maps');
  }
  function MapFilterOptions(name, ordinal, text) {
    Enum.call(this, name, ordinal);
    this.hi4_1 = text;
  }
  function Companion_2() {
    Companion_instance_2 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(GoldensViewerScene).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.ii4_1 = tmp$ret$0;
  }
  var Companion_instance_2;
  function Companion_getInstance_28() {
    if (Companion_instance_2 == null)
      new Companion_2();
    return Companion_instance_2;
  }
  function sceneInit$resetGridWithCurrentDropdownValue(pageDropdown, mapGrid, chunkedMaps, eventBus) {
    var tmp$ret$7;
    // Inline function 'com.xenotactic.gamelogic.utils.measureTime' call
    var tmp$ret$6;
    // Inline function 'kotlin.time.measureTimedValue' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$5;
    // Inline function 'kotlin.time.measureTimedValue' call
    var tmp0_measureTimedValue = Monotonic_getInstance();
    // Inline function 'kotlin.contracts.contract' call
    var mark = tmp0_measureTimedValue.nc();
    var tmp$ret$4;
    // Inline function 'com.xenotactic.gamelogic.utils.measureTime.<anonymous>' call
    var currOption = pageDropdown.fi7();
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!(currOption instanceof NumberOption)) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    var tmp$ret$3;
    // Inline function 'kotlin.collections.map' call
    var tmp1_map = chunkedMaps._v.n(numberToInt(currOption.hi7_1));
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
    var tmp0_iterator = tmp1_map.h();
    while (tmp0_iterator.j()) {
      var item = tmp0_iterator.k();
      var tmp$ret$1;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.resetGridWithCurrentDropdownValue.<anonymous>.<anonymous>' call
      tmp$ret$1 = GoldensViewerScene$sceneInit$resetGridWithCurrentDropdownValue$lambda(eventBus, item);
      tmp0_mapTo.b(tmp$ret$1);
    }
    tmp$ret$2 = tmp0_mapTo;
    tmp$ret$3 = tmp$ret$2;
    mapGrid.aia(tmp$ret$3);
    tmp$ret$4 = Unit_getInstance();
    var result = tmp$ret$4;
    tmp$ret$5 = new TimedValue(Unit_getInstance(), ValueTimeMark__elapsedNow_impl_eonqvs(mark));
    tmp$ret$6 = tmp$ret$5;
    var ret = tmp$ret$6;
    {
      if ('resetGridWithCurrentDropdownValue time' == null) {
        println('Took ' + new Duration(ret.pc_1));
      } else {
        println('resetGridWithCurrentDropdownValue time: Took ' + new Duration(ret.pc_1));
      }
    }
    var tmp = _Duration___get_inWholeNanoseconds__impl__r5x4mr(ret.pc_1);
    tmp$ret$7 = new Pair(tmp, Unit_getInstance());
  }
  function GoldensViewerScene$sceneInit$slambda($pageDropdown, resultContinuation) {
    this.jia_1 = $pageDropdown;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.jia_1.lia();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda).yak = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda(this.jia_1, completion);
    i.kia_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_0($pageDropdown, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda($pageDropdown, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.uia_1 = $tmp1_onClick;
    this.via_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.uia_1(this.via_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda).g1p = function (completion) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda(this.uia_1, this.via_1, completion);
    return i;
  };
  function GoldensViewerScene$sceneInit$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, GoldensViewerScene$sceneInit$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function GoldensViewerScene$sceneInit$slambda_1($pageDropdown, resultContinuation) {
    this.eib_1 = $pageDropdown;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.eib_1.gib();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_1).yak = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_1(this.eib_1, completion);
    i.fib_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_2($pageDropdown, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_1($pageDropdown, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda$slambda_1($tmp1_onClick, $it, resultContinuation) {
    this.pib_1 = $tmp1_onClick;
    this.qib_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pib_1(this.qib_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_1).g1p = function (completion) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_1(this.pib_1, this.qib_1, completion);
    return i;
  };
  function GoldensViewerScene$sceneInit$lambda$slambda_2($tmp1_onClick, $it, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_1($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda_0($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, GoldensViewerScene$sceneInit$lambda$slambda_2($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function GoldensViewerScene$sceneInit$slambda_3($pageDropdown, $mapGrid, $chunkedMaps, $eventBus, resultContinuation) {
    this.zib_1 = $pageDropdown;
    this.aic_1 = $mapGrid;
    this.bic_1 = $chunkedMaps;
    this.cic_1 = $eventBus;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_3).eic = function (it, $completion) {
    var tmp = this.fic(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_3).g8 = function (p1, $completion) {
    return this.eic(p1 instanceof DropdownOptionClickedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          sceneInit$resetGridWithCurrentDropdownValue(this.zib_1, this.aic_1, this.bic_1, this.cic_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_3).fic = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_3(this.zib_1, this.aic_1, this.bic_1, this.cic_1, completion);
    i.dic_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_4($pageDropdown, $mapGrid, $chunkedMaps, $eventBus, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_3($pageDropdown, $mapGrid, $chunkedMaps, $eventBus, resultContinuation);
    var l = function (it, $completion) {
      return i.eic(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda$slambda_3($tmp17_onDropdownChange, $it, resultContinuation) {
    this.oic_1 = $tmp17_onDropdownChange;
    this.pic_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.oic_1(this.pic_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_3).g1p = function (completion) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_3(this.oic_1, this.pic_1, completion);
    return i;
  };
  function GoldensViewerScene$sceneInit$lambda$slambda_4($tmp17_onDropdownChange, $it, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_3($tmp17_onDropdownChange, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda_1($tmp17_onDropdownChange) {
    return function (it) {
      var tmp = Dispatchers_getInstance().fv_1;
      launchImmediately(tmp, GoldensViewerScene$sceneInit$lambda$slambda_4($tmp17_onDropdownChange, it, null));
      return Unit_getInstance();
    };
  }
  function GoldensViewerScene$sceneInit$slambda_5($chunkedMaps, $allGoldenMapsVfsFiles, $maxColumns, $maxRows, $pageDropdown, $mapGrid, $eventBus, resultContinuation) {
    this.yic_1 = $chunkedMaps;
    this.zic_1 = $allGoldenMapsVfsFiles;
    this.aid_1 = $maxColumns;
    this.bid_1 = $maxRows;
    this.cid_1 = $pageDropdown;
    this.did_1 = $mapGrid;
    this.eid_1 = $eventBus;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_5).eic = function (it, $completion) {
    var tmp = this.fic(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_5).g8 = function (p1, $completion) {
    return this.eic(p1 instanceof DropdownOptionClickedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp_0 = this.fid_1.gid_1;
          var tmp0_require = tmp_0 instanceof StringOption;
          if (!tmp0_require) {
            var message = 'Failed requirement.';
            throw IllegalArgumentException_init_$Create$(toString(message));
          }
          var enum_0 = valueOf(this.fid_1.gid_1.iid_1);
          var tmp0_subject = enum_0;
          var tmp0 = tmp0_subject.b2_1;
          var tmp_1;
          switch (tmp0) {
            case 0:
              tmp_1 = this.zic_1;
              break;
            case 1:
              var tmp1_filterTo = ArrayList_init_$Create$();
              var tmp0_iterator = this.zic_1.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                var tmp_2 = element.lid_1;
                if (tmp_2 instanceof Success_0) {
                  tmp1_filterTo.b(element);
                }
              }

              tmp_1 = tmp1_filterTo;
              break;
            case 2:
              var tmp2_filterTo = ArrayList_init_$Create$();
              var tmp0_iterator_0 = this.zic_1.h();
              while (tmp0_iterator_0.j()) {
                var element_0 = tmp0_iterator_0.k();
                var tmp_3 = element_0.lid_1;
                if (tmp_3 instanceof Failure_0) {
                  tmp2_filterTo.b(element_0);
                }
              }

              tmp_1 = tmp2_filterTo;
              break;
            default:
              noWhenBranchMatchedException();
              break;
          }
          this.yic_1._v = chunked(tmp_1, imul(this.aid_1, this.bid_1));
          var tmp4_map = withIndex(this.yic_1._v);
          var tmp3_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp4_map, 10));
          var tmp0_iterator_1 = tmp4_map.h();
          while (tmp0_iterator_1.j()) {
            var item = tmp0_iterator_1.k();
            tmp3_mapTo.b(new NumberOption('Page ' + item.r4_1, item.r4_1));
          }
          this.cid_1.mid(tmp3_mapTo);
          sceneInit$resetGridWithCurrentDropdownValue(this.cid_1, this.did_1, this.yic_1, this.eid_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_5).fic = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_5(this.yic_1, this.zic_1, this.aid_1, this.bid_1, this.cid_1, this.did_1, this.eid_1, completion);
    i.fid_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_6($chunkedMaps, $allGoldenMapsVfsFiles, $maxColumns, $maxRows, $pageDropdown, $mapGrid, $eventBus, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_5($chunkedMaps, $allGoldenMapsVfsFiles, $maxColumns, $maxRows, $pageDropdown, $mapGrid, $eventBus, resultContinuation);
    var l = function (it, $completion) {
      return i.eic(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda$slambda_5($tmp18_onDropdownChange, $it, resultContinuation) {
    this.vid_1 = $tmp18_onDropdownChange;
    this.wid_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_5).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_5).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.vid_1(this.wid_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$lambda$slambda_5).g1p = function (completion) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_5(this.vid_1, this.wid_1, completion);
    return i;
  };
  function GoldensViewerScene$sceneInit$lambda$slambda_6($tmp18_onDropdownChange, $it, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$lambda$slambda_5($tmp18_onDropdownChange, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda_2($tmp18_onDropdownChange) {
    return function (it) {
      var tmp = Dispatchers_getInstance().fv_1;
      launchImmediately(tmp, GoldensViewerScene$sceneInit$lambda$slambda_6($tmp18_onDropdownChange, it, null));
      return Unit_getInstance();
    };
  }
  function GoldensViewerScene$sceneInit$slambda_7($overlayContainer, $engine, resultContinuation) {
    this.fie_1 = $overlayContainer;
    this.gie_1 = $engine;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_7).iie = function (it, $completion) {
    var tmp = this.jie(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_7).g8 = function (p1, $completion) {
    return this.iie(p1 instanceof GoldensEntryClickEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.fie_1.vig(new UIMap(this.hie_1.fhb_1, this.gie_1, PathFinder_getInstance().tgj(this.hie_1.fhb_1)));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_7).jie = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_7(this.fie_1, this.gie_1, completion);
    i.hie_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_8($overlayContainer, $engine, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_7($overlayContainer, $engine, resultContinuation);
    var l = function (it, $completion) {
      return i.iie(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$slambda_9($overlayContainer, resultContinuation) {
    this.eih_1 = $overlayContainer;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_9).gih = function (it, $completion) {
    var tmp = this.hih(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_9).g8 = function (p1, $completion) {
    return this.gih(p1 instanceof UIMapOverlayOutsideClickedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.eih_1.iih();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_9).hih = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_9(this.eih_1, completion);
    i.fih_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_10($overlayContainer, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_9($overlayContainer, resultContinuation);
    var l = function (it, $completion) {
      return i.gih(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$slambda_11($descriptionText, resultContinuation) {
    this.rih_1 = $descriptionText;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(GoldensViewerScene$sceneInit$slambda_11).tih = function (it, $completion) {
    var tmp = this.uih(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_11).g8 = function (p1, $completion) {
    return this.tih(p1 instanceof GoldensEntryHoverOnEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var verificationResult = this.sih_1.ihb_1;
          var tmp0_subject = verificationResult;
          var tmp_0;
          if (equals(tmp0_subject, Success_getInstance())) {
            tmp_0 = get_baseName(this.sih_1.ghb_1) + ': LGTM';
          } else {
            if (tmp0_subject instanceof Failure_0) {
              tmp_0 = get_baseName(this.sih_1.ghb_1) + ': ' + verificationResult.wgz_1;
            } else {
              throw new NotImplementedError();
            }
          }
          this.rih_1.fbu(tmp_0);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(GoldensViewerScene$sceneInit$slambda_11).uih = function (it, completion) {
    var i = new GoldensViewerScene$sceneInit$slambda_11(this.rih_1, completion);
    i.sih_1 = it;
    return i;
  };
  function GoldensViewerScene$sceneInit$slambda_12($descriptionText, resultContinuation) {
    var i = new GoldensViewerScene$sceneInit$slambda_11($descriptionText, resultContinuation);
    var l = function (it, $completion) {
      return i.tih(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function GoldensViewerScene$sceneInit$lambda_3($overlayContainer) {
    return function (it) {
      var tmp;
      if (it.w7d_1.equals(Key_ESCAPE_getInstance())) {
        $overlayContainer.iih();
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function GoldensViewerScene$sceneInit$resetGridWithCurrentDropdownValue$lambda($eventBus, $item) {
    return function (_anonymous_parameter_0__qggqh8, _anonymous_parameter_1__qggqgd, width, height) {
      return UIGoldensViewerEntry_init_$Create$($eventBus, $item, width, height);
    };
  }
  function $sceneDestroyCOROUTINE$1(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.dii_1 = _this__u8e3s4;
  }
  protoOf($sceneDestroyCOROUTINE$1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = protoOf(Scene).qb4.call(this.dii_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp1_info = Companion_getInstance_28().ii4_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'sceneDestroy');
            }

            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function GoldensViewerScene() {
    Companion_getInstance_28();
    Scene.call(this);
  }
  protoOf(GoldensViewerScene).mb4 = function (_this__u8e3s4, $completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_28().ii4_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
      tmp$ret$0 = 'sceneInit';
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    var eventBus = new EventBus(CoroutineScope(Dispatchers_getInstance().fv_1));
    var engine = new Engine(eventBus);
    var maxColumns = 7;
    var maxRows = 5;
    var tmp$ret$5;
    // Inline function 'korlibs.datastructure.iterators.parallelMap' call
    var tmp4_parallelMap = getGoldenJsonFiles();
    var tmp$ret$4;
    // Inline function 'kotlin.also' call
    var tmp$ret$2;
    // Inline function 'kotlin.arrayOfNulls' call
    var tmp2_arrayOfNulls = tmp4_parallelMap.l();
    tmp$ret$2 = fillArrayVal(Array(tmp2_arrayOfNulls), null);
    var tmp3_also = tmp$ret$2;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.datastructure.iterators.parallelMap.<anonymous>' call
    // Inline function 'korlibs.datastructure.iterators.parallelForeach' call
    var tmp0_parallelForeach = tmp4_parallelMap.l();
    var inductionVariable = 0;
    if (inductionVariable < tmp0_parallelForeach)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        // Inline function 'korlibs.datastructure.iterators.parallelMap.<anonymous>.<anonymous>' call
        var tmp$ret$3;
        // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
        var tmp5__anonymous__kpxxpo = tmp4_parallelMap.n(n);
        var gameMap = ensureNotNull(toGameMap(tmp5__anonymous__kpxxpo));
        tmp$ret$3 = new MapWithMetadata(tmp5__anonymous__kpxxpo, gameMap, verify(gameMap));
        tmp3_also[n] = tmp$ret$3;
      }
       while (inductionVariable < tmp0_parallelForeach);
    tmp$ret$4 = tmp3_also;
    var tmp_0 = toList(tmp$ret$4);
    tmp$ret$5 = isInterface(tmp_0, List) ? tmp_0 : THROW_CCE();
    var allGoldenMapsVfsFiles = tmp$ret$5;
    var chunkedMaps = {_v: chunked(allGoldenMapsVfsFiles, imul(maxColumns, maxRows))};
    var mapGrid = uiFixedGrid(_this__u8e3s4, maxColumns, maxRows, 1000.0, 450.0, 10.0, 10.0);
    var tmp$ret$6;
    // Inline function 'com.xenotactic.korge.ui.uiDropdown' call
    var tmp6_uiDropdown = emptyList();
    var tmp7_uiDropdown = new UIDropdownSettings();
    tmp$ret$6 = addTo(new UIDropdown(tmp6_uiDropdown, tmp7_uiDropdown), _this__u8e3s4);
    var pageDropdown = tmp$ret$6;
    var tmp0_size = Size_init_$Create$_0(50.0, 20.0);
    var tmp$ret$12;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp$ret$11;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(new UIButton(tmp0_size, 'Prev', null), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
    alignLeftToRightOf(tmp8_apply, pageDropdown);
    var tmp$ret$10;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick = GoldensViewerScene$sceneInit$slambda_0(pageDropdown, null);
    var tmp$ret$9;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent = click$factory();
    var tmp0_safe_receiver = tmp8_apply;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$8;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$7;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$7 = tmp_1.f1q(GoldensViewerScene$sceneInit$lambda(tmp1_safe_receiver, tmp1_onClick));
      tmp$ret$8 = tmp$ret$7;
    }
    tmp$ret$9 = tmp8_apply;
    tmp$ret$10 = tmp$ret$9;
    tmp$ret$11 = tmp8_apply;
    tmp$ret$12 = tmp$ret$11;
    var prevButton = tmp$ret$12;
    var tmp1_size = Size_init_$Create$_0(50.0, 20.0);
    var tmp$ret$18;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp$ret$17;
    // Inline function 'kotlin.apply' call
    var tmp9_apply = addTo(new UIButton(tmp1_size, 'Next', null), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
    alignLeftToRightOf(tmp9_apply, prevButton);
    var tmp$ret$16;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_0 = GoldensViewerScene$sceneInit$slambda_2(pageDropdown, null);
    var tmp$ret$15;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_0 = click$factory_0();
    var tmp0_safe_receiver_0 = tmp9_apply;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$14;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$13;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = tmp0_doMouseEvent_0.get(tmp1_safe_receiver_0);
      tmp$ret$13 = tmp_2.f1q(GoldensViewerScene$sceneInit$lambda_0(tmp1_safe_receiver_0, tmp1_onClick_0));
      tmp$ret$14 = tmp$ret$13;
    }
    tmp$ret$15 = tmp9_apply;
    tmp$ret$16 = tmp$ret$15;
    tmp$ret$17 = tmp9_apply;
    tmp$ret$18 = tmp$ret$17;
    var nextButton = tmp$ret$18;
    var tmp$ret$23;
    // Inline function 'kotlin.apply' call
    var tmp$ret$22;
    // Inline function 'com.xenotactic.korge.ui.uiDropdown' call
    var tmp$ret$21;
    // Inline function 'kotlin.collections.map' call
    var tmp11_map = values();
    var tmp$ret$20;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp10_mapTo = ArrayList_init_$Create$_0(tmp11_map.length);
    var indexedObject = tmp11_map;
    var inductionVariable_0 = 0;
    var last = indexedObject.length;
    while (inductionVariable_0 < last) {
      var item = indexedObject[inductionVariable_0];
      inductionVariable_0 = inductionVariable_0 + 1 | 0;
      var tmp$ret$19;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
      tmp$ret$19 = new StringOption(item.hi4_1, item.toString());
      tmp10_mapTo.b(tmp$ret$19);
    }
    tmp$ret$20 = tmp10_mapTo;
    tmp$ret$21 = tmp$ret$20;
    var tmp12_uiDropdown = tmp$ret$21;
    var tmp13_uiDropdown = new UIDropdownSettings();
    tmp$ret$22 = addTo(new UIDropdown(tmp12_uiDropdown, tmp13_uiDropdown), _this__u8e3s4);
    var tmp14_apply = tmp$ret$22;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
    alignLeftToRightOf(tmp14_apply, nextButton, 20.0);
    tmp$ret$23 = tmp14_apply;
    var mapFilterDropdown = tmp$ret$23;
    var tmp$ret$26;
    // Inline function 'kotlin.collections.map' call
    var tmp16_map = withIndex(chunked(allGoldenMapsVfsFiles, imul(maxColumns, maxRows)));
    var tmp$ret$25;
    // Inline function 'kotlin.collections.mapTo' call
    var tmp15_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp16_map, 10));
    var tmp0_iterator = tmp16_map.h();
    while (tmp0_iterator.j()) {
      var item_0 = tmp0_iterator.k();
      var tmp$ret$24;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
      tmp$ret$24 = new NumberOption('Page ' + item_0.r4_1, item_0.r4_1);
      tmp15_mapTo.b(tmp$ret$24);
    }
    tmp$ret$25 = tmp15_mapTo;
    tmp$ret$26 = tmp$ret$25;
    pageDropdown.mid(tmp$ret$26);
    sceneInit$resetGridWithCurrentDropdownValue(pageDropdown, mapGrid, chunkedMaps, eventBus);
    // Inline function 'com.xenotactic.korge.ui.UIDropdown.onDropdownChange' call
    var tmp17_onDropdownChange = GoldensViewerScene$sceneInit$slambda_4(pageDropdown, mapGrid, chunkedMaps, eventBus, null);
    pageDropdown.ei7_1.f1q(GoldensViewerScene$sceneInit$lambda_1(tmp17_onDropdownChange));
    // Inline function 'com.xenotactic.korge.ui.UIDropdown.onDropdownChange' call
    var tmp18_onDropdownChange = GoldensViewerScene$sceneInit$slambda_6(chunkedMaps, allGoldenMapsVfsFiles, maxColumns, maxRows, pageDropdown, mapGrid, eventBus, null);
    mapFilterDropdown.ei7_1.f1q(GoldensViewerScene$sceneInit$lambda_2(tmp18_onDropdownChange));
    alignTopToBottomOf(mapGrid, pageDropdown);
    var tmp$ret$28;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp20_info = Companion_getInstance_28().ii4_1;
    var tmp19_log = Level_INFO_getInstance();
    var tmp_3;
    if (tmp20_info.q1n(tmp19_log)) {
      var tmp$ret$27;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
      tmp$ret$27 = trimIndent('\n                mapGrid.scaledWidth: ' + mapGrid.fbm() + '\n                mapGrid.scaledHeight: ' + mapGrid.hbm() + '\n            ');
      tmp20_info.r1n(tmp19_log, tmp$ret$27);
      tmp_3 = Unit_getInstance();
    }
    tmp$ret$28 = tmp_3;
    var tmp$ret$32;
    // Inline function 'korlibs.korge.view.text' call
    var tmp23_text = Companion_getInstance_6().odd_1;
    var tmp24_text = Colors_getInstance().k4s_1;
    var tmp25_text = get_DefaultTtfFontAsBitmap();
    var tmp26_text = Companion_getInstance_5().g5r_1;
    var tmp27_text = get_DefaultStringTextRenderer();
    var tmp28_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp22_addTo = new Text('', tmp23_text, tmp24_text, tmp25_text, tmp26_text, tmp27_text, tmp28_text, null, null);
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp21_apply = addTo(tmp22_addTo, _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$29;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$29 = Unit_getInstance();
    tmp$ret$30 = tmp21_apply;
    tmp$ret$31 = tmp$ret$30;
    tmp$ret$32 = tmp$ret$31;
    var descriptionText = tmp$ret$32;
    alignTopToBottomOf(descriptionText, mapGrid);
    var overlayContainer = new UIMapOverlay(engine);
    var tmp$ret$33;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp29_register = GoldensViewerScene$sceneInit$slambda_8(overlayContainer, engine, null);
    tmp$ret$33 = eventBus.cg7(getKClass(GoldensEntryClickEvent), tmp29_register);
    var tmp$ret$34;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp30_register = GoldensViewerScene$sceneInit$slambda_10(overlayContainer, null);
    tmp$ret$34 = eventBus.cg7(getKClass(UIMapOverlayOutsideClickedEvent), tmp30_register);
    var tmp$ret$35;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp31_register = GoldensViewerScene$sceneInit$slambda_12(descriptionText, null);
    tmp$ret$35 = eventBus.cg7(getKClass(GoldensEntryHoverOnEvent), tmp31_register);
    var tmp_4 = Type_UP_getInstance_0();
    _this__u8e3s4.s7c(tmp_4, GoldensViewerScene$sceneInit$lambda_3(overlayContainer));
    var tmp$ret$37;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp33_info = Companion_getInstance_28().ii4_1;
    var tmp32_log = Level_INFO_getInstance();
    var tmp_5;
    if (tmp33_info.q1n(tmp32_log)) {
      var tmp$ret$36;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneInit.<anonymous>' call
      tmp$ret$36 = 'sceneInit: Finished initializing';
      tmp33_info.r1n(tmp32_log, tmp$ret$36);
      tmp_5 = Unit_getInstance();
    }
    tmp$ret$37 = tmp_5;
    return Unit_getInstance();
  };
  protoOf(GoldensViewerScene).nb4 = function (_this__u8e3s4, $completion) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_28().ii4_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.scenes.GoldensViewerScene.sceneMain.<anonymous>' call
      tmp$ret$0 = 'sceneMain';
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    return Unit_getInstance();
  };
  protoOf(GoldensViewerScene).qb4 = function ($completion) {
    var tmp = new $sceneDestroyCOROUTINE$1(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function MapFilterOptions_ALL_getInstance() {
    MapFilterOptions_initEntries();
    return MapFilterOptions_ALL_instance;
  }
  function MapFilterOptions_GOOD_MAPS_ONLY_getInstance() {
    MapFilterOptions_initEntries();
    return MapFilterOptions_GOOD_MAPS_ONLY_instance;
  }
  function MapFilterOptions_BAD_MAPS_ONLY_getInstance() {
    MapFilterOptions_initEntries();
    return MapFilterOptions_BAD_MAPS_ONLY_instance;
  }
  function click$factory() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_0() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function Companion_3() {
    Companion_instance_3 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(MapViewerScene).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.oii_1 = tmp$ret$0;
  }
  var Companion_instance_3;
  function Companion_getInstance_29() {
    if (Companion_instance_3 == null)
      new Companion_3();
    return Companion_instance_3;
  }
  function MapViewerScene$sceneInit$lambda$lambda(this$0, $item) {
    return function (it) {
      this$0.zii_1.bg7(new PlayMapEvent($item.kid_1));
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda$lambda$slambda($playerData, resultContinuation) {
    this.iij_1 = $playerData;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MapViewerScene$sceneInit$lambda$lambda$slambda).c1o = function ($this$launch, $completion) {
    var tmp = this.d1o($this$launch, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$slambda).g8 = function (p1, $completion) {
    return this.c1o((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = PlayerDataApi_getInstance().nij(this.iij_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$slambda).d1o = function ($this$launch, completion) {
    var i = new MapViewerScene$sceneInit$lambda$lambda$slambda(this.iij_1, completion);
    i.jij_1 = $this$launch;
    return i;
  };
  function MapViewerScene$sceneInit$lambda$lambda$slambda_0($playerData, resultContinuation) {
    var i = new MapViewerScene$sceneInit$lambda$lambda$slambda($playerData, resultContinuation);
    var l = function ($this$launch, $completion) {
      return i.c1o($this$launch, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MapViewerScene$sceneInit$lambda$lambda_0($playerData, $item, this$0) {
    return function (it) {
      var tmp0_set = $playerData.egi_1;
      var tmp1_set = MapToId_getInstance().vgv($item.kid_1);
      var tmp2_set = $item.kid_1;
      tmp0_set.e5(tmp1_set, tmp2_set);
      launch(this$0, VOID, VOID, MapViewerScene$sceneInit$lambda$lambda$slambda_0($playerData, null));
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda$lambda_1($item, $mapInspector) {
    return function (it) {
      println('Map clicked! ' + $item.kid_1);
      $mapInspector.kim($item.kid_1);
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda($item, this$0, $playerData, $mapInspector) {
    return function (x, y, width, height) {
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = new UIMapEntry($item.kid_1, width, height);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.scenes.MapViewerScene.sceneInit.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply.bip_1.g1q(MapViewerScene$sceneInit$lambda$lambda(this$0, $item));
      tmp0_apply.cip_1.g1q(MapViewerScene$sceneInit$lambda$lambda_0($playerData, $item, this$0));
      tmp0_apply.dip_1.g1q(MapViewerScene$sceneInit$lambda$lambda_1($item, $mapInspector));
      tmp$ret$0 = tmp0_apply;
      return tmp$ret$0;
    };
  }
  function MapViewerScene$sceneInit$lambda_0($header, $mapGrid, $mapInspector) {
    return function ($this$onStageResized, width, height) {
      alignLeftToLeftOfWindow($header);
      alignTopToTopOfWindow($header);
      alignTopToBottomOf($mapGrid, $header);
      alignLeftToLeftOfWindow($mapGrid);
      alignTopToBottomOf($mapInspector, $header);
      alignRightToRightOfWindow($mapInspector);
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda$lambda$lambda(this$0, $map) {
    return function (it) {
      this$0.zii_1.bg7(new PlayMapEvent($map));
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda$lambda$lambda$slambda($playerData, resultContinuation) {
    this.mip_1 = $playerData;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MapViewerScene$sceneInit$lambda$lambda$lambda$slambda).c1o = function ($this$launch, $completion) {
    var tmp = this.d1o($this$launch, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$lambda$slambda).g8 = function (p1, $completion) {
    return this.c1o((!(p1 == null) ? isInterface(p1, CoroutineScope_0) : false) ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = PlayerDataApi_getInstance().nij(this.mip_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(MapViewerScene$sceneInit$lambda$lambda$lambda$slambda).d1o = function ($this$launch, completion) {
    var i = new MapViewerScene$sceneInit$lambda$lambda$lambda$slambda(this.mip_1, completion);
    i.nip_1 = $this$launch;
    return i;
  };
  function MapViewerScene$sceneInit$lambda$lambda$lambda$slambda_0($playerData, resultContinuation) {
    var i = new MapViewerScene$sceneInit$lambda$lambda$lambda$slambda($playerData, resultContinuation);
    var l = function ($this$launch, $completion) {
      return i.c1o($this$launch, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MapViewerScene$sceneInit$lambda$lambda$lambda_0($playerData, $map, this$0) {
    return function (it) {
      var tmp0_set = $playerData.egi_1;
      var tmp1_set = MapToId_getInstance().vgv($map);
      tmp0_set.e5(tmp1_set, $map);
      launch(this$0, VOID, VOID, MapViewerScene$sceneInit$lambda$lambda$lambda$slambda_0($playerData, null));
      return Unit_getInstance();
    };
  }
  function MapViewerScene$sceneInit$lambda$lambda_2($map, this$0, $playerData) {
    return function (x, y, width, height) {
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = new UIMapEntry($map, width, height);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.scenes.MapViewerScene.sceneInit.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply.bip_1.g1q(MapViewerScene$sceneInit$lambda$lambda$lambda(this$0, $map));
      tmp0_apply.cip_1.g1q(MapViewerScene$sceneInit$lambda$lambda$lambda_0($playerData, $map, this$0));
      tmp$ret$0 = tmp0_apply;
      return tmp$ret$0;
    };
  }
  function MapViewerScene$sceneInit$lambda_1($header, $mapGrid, $playEntries, $playerData, this$0) {
    return function (it) {
      var tmp0_subject = it;
      var tmp0 = tmp0_subject.b2_1;
      var tmp;
      switch (tmp0) {
        case 0:
          $header.bis(it);
          $mapGrid.aia($playEntries);
          tmp = Unit_getInstance();
          break;
        case 1:
          throw new NotImplementedError();
        case 2:
          $header.bis(it);
          var tmp$ret$4;
          // Inline function 'kotlin.collections.map' call
          var tmp1_map = $playerData.egi_1;
          var tmp$ret$3;
          // Inline function 'kotlin.collections.mapTo' call
          var tmp0_mapTo = ArrayList_init_$Create$_0(tmp1_map.l());
          var tmp$ret$0;
          // Inline function 'kotlin.collections.iterator' call
          tmp$ret$0 = tmp1_map.d1().h();
          var tmp0_iterator = tmp$ret$0;
          while (tmp0_iterator.j()) {
            var item = tmp0_iterator.k();
            var tmp$ret$2;
            // Inline function 'com.xenotactic.korge.scenes.MapViewerScene.sceneInit.<anonymous>.<anonymous>' call
            var tmp$ret$1;
            // Inline function 'kotlin.collections.component2' call
            tmp$ret$1 = item.f1();
            var map = tmp$ret$1;
            tmp$ret$2 = MapViewerScene$sceneInit$lambda$lambda_2(map, this$0, $playerData);
            tmp0_mapTo.b(tmp$ret$2);
          }
          tmp$ret$3 = tmp0_mapTo;
          tmp$ret$4 = tmp$ret$3;

          $mapGrid.aia(tmp$ret$4);
          tmp = Unit_getInstance();
          break;
      }
      return Unit_getInstance();
    };
  }
  function $sceneInitCOROUTINE$2(_this__u8e3s4, _this__u8e3s4_0, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.kis_1 = _this__u8e3s4;
    this.lis_1 = _this__u8e3s4_0;
  }
  protoOf($sceneInitCOROUTINE$2).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            println('MapViewerScene: Init');
            this.mis_1 = 50.0;
            this.y7_1 = 1;
            suspendResult = PlayerDataApi_getInstance().ois(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.nis_1 = suspendResult;
            this.nis_1.dgi_1 = 'XenoTactic';
            this.y7_1 = 2;
            suspendResult = PlayerDataApi_getInstance().nij(this.nis_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            var header = addTo(new UIHeader(this.nis_1.dgi_1, this.mis_1, getVisibleGlobalArea(this.lis_1).c41()), this.lis_1);
            var maxColumns = 4;
            var maxRows = 5;
            var tmp1_map = take(getGoldenJsonFiles(), imul(maxColumns, maxRows));
            var tmp0_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(tmp1_map, 10));
            var tmp0_iterator = tmp1_map.h();
            while (tmp0_iterator.j()) {
              var item = tmp0_iterator.k();
              var gameMap = ensureNotNull(toGameMap(item));
              tmp0_mapTo.b(new MapWithMetadata(item, gameMap, verify(gameMap)));
            }

            var goldenMaps = tmp0_mapTo;
            var mapInspector = addTo(new UIMapInspector(), this.lis_1);
            var tmp2_mapTo = ArrayList_init_$Create$_0(collectionSizeOrDefault(goldenMaps, 10));
            var tmp0_iterator_0 = goldenMaps.h();
            while (tmp0_iterator_0.j()) {
              var item_0 = tmp0_iterator_0.k();
              tmp2_mapTo.b(MapViewerScene$sceneInit$lambda(item_0, this.kis_1, this.nis_1, mapInspector));
            }

            var playEntries = tmp2_mapTo;
            var mapGrid = uiFixedGrid(this.lis_1, maxColumns, maxRows, 700.0, 500.0, 10.0, 10.0);
            mapGrid.aia(playEntries);
            onStageResized(this.lis_1, true, MapViewerScene$sceneInit$lambda_0(header, mapGrid, mapInspector));
            ;
            var resizeDebugComponent = new ResizeDebugComponent(this.lis_1);
            resizeDebugComponent.bhb(this.lis_1);
            header.yir_1.g1q(MapViewerScene$sceneInit$lambda_1(header, mapGrid, playEntries, this.nis_1, this.kis_1));
            ;
            var tmp4_info = Companion_getInstance_29().oii_1;
            var tmp3_log = Level_INFO_getInstance();
            if (tmp4_info.q1n(tmp3_log)) {
              tmp4_info.r1n(tmp3_log, 'Finished initializing MapViewerScene.');
            }

            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function MapViewerScene(globalEventBus) {
    Companion_getInstance_29();
    Scene.call(this);
    this.zii_1 = globalEventBus;
  }
  protoOf(MapViewerScene).mb4 = function (_this__u8e3s4, $completion) {
    var tmp = new $sceneInitCOROUTINE$2(this, _this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function PlayScene$sceneInit$slambda($editorState, resultContinuation) {
    this.xis_1 = $editorState;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(PlayScene$sceneInit$slambda).sab = function (it, $completion) {
    var tmp = this.tab(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(PlayScene$sceneInit$slambda).g8 = function (p1, $completion) {
    return this.sab(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(PlayScene$sceneInit$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.xis_1.zis();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(PlayScene$sceneInit$slambda).tab = function (it, completion) {
    var i = new PlayScene$sceneInit$slambda(this.xis_1, completion);
    i.yis_1 = it;
    return i;
  };
  function PlayScene$sceneInit$slambda_0($editorState, resultContinuation) {
    var i = new PlayScene$sceneInit$slambda($editorState, resultContinuation);
    var l = function (it, $completion) {
      return i.sab(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function PlayScene$sceneInit$lambda($gameSimulator, $accumulatedTime, $deltaTime, $updateInfoTextFrequency, $uiDebugInfo) {
    return function ($this$addFixedUpdater) {
      var tmp$ret$1;
      // Inline function 'kotlin.time.measureTime' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'kotlin.time.measureTime' call
      var tmp0_measureTime = Monotonic_getInstance();
      // Inline function 'kotlin.contracts.contract' call
      var mark = tmp0_measureTime.nc();
      // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>.<anonymous>' call
      $gameSimulator.ngt();
      tmp$ret$0 = ValueTimeMark__elapsedNow_impl_eonqvs(mark);
      tmp$ret$1 = tmp$ret$0;
      var updateTime = tmp$ret$1;
      $accumulatedTime._v = new TimeSpan(TimeSpan__plus_impl_qdd6zo($accumulatedTime._v.j18_1, $deltaTime));
      var tmp;
      if (TimeSpan__compareTo_impl_zidry4($accumulatedTime._v.j18_1, $updateInfoTextFrequency) >= 0) {
        $uiDebugInfo.liv_1.fbu('Update time: ' + new Duration(updateTime));
        $accumulatedTime._v = new TimeSpan(Companion_getInstance_4().y17_1);
        tmp = Unit_getInstance();
      }
      return Unit_getInstance();
    };
  }
  function PlayScene() {
    Scene.call(this);
  }
  protoOf(PlayScene).mb4 = function (_this__u8e3s4, $completion) {
    var eventBus = new EventBus(this);
    var seed = new Long(1352, 0);
    var width = toGameUnit(30);
    var height = toGameUnit(20);
    var randomMap = Companion_getInstance_26().dhs(new MapGeneratorConfigurationV2(seed, listOf_0([StartGenerator_getInstance(), FinishGenerator_getInstance(), new CheckpointsGenerator(2), new RocksGenerator(10), new TeleportsGenerator(2), new SpeedAreaGenerator(5)]), width, height));
    var gameWorld = new GameWorld();
    var mouseDragSettingsState = new MouseDragSettingsState();
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new Engine(eventBus, gameWorld);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    tmp0_apply.ggo_1.xg2(mouseDragSettingsState);
    tmp0_apply.ggo_1.xg2(new DeadUIZonesState());
    tmp$ret$0 = tmp0_apply;
    var engine = tmp$ret$0;
    var gameSimulator = new GameSimulator(width, height, engine);
    var uiMapV2 = addTo(new UIMapV2(engine), _this__u8e3s4);
    engine.hgo_1.xg2(uiMapV2);
    centerOnStage(uiMapV2);
    var cameraInputProcessor = new CameraInputProcessor(uiMapV2, engine);
    cameraInputProcessor.bhb(_this__u8e3s4);
    var mouseDragInputProcessor = new MouseDragInputProcessor(this.gae(), uiMapV2, mouseDragSettingsState.ghu_1);
    mouseDragInputProcessor.bhb(_this__u8e3s4);
    engine.hgo_1.xg2(mouseDragInputProcessor);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp1_apply = new SelectorMouseProcessorV2(this.gae(), _this__u8e3s4, engine);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    engine.hgo_1.xg2(tmp1_apply);
    tmp$ret$1 = tmp1_apply;
    var selectorMouseProcessorV2 = tmp$ret$1;
    selectorMouseProcessorV2.bhb(_this__u8e3s4);
    var editorState = new EditorState(engine);
    engine.ggo_1.xg2(editorState);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = engine.fgo_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    tmp2_apply.b(new UIMapEventListeners(engine));
    tmp2_apply.b(new TowerUpgradeEventListeners(engine));
    tmp$ret$2 = tmp2_apply;
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = gameWorld.vgb_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    tmp3_apply.zfz_1 = engine.hgo_1;
    tmp3_apply.ig3(new SetInitialPositionForUIEntityFamilyListener(tmp3_apply));
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp1_addComponentListener = new PreSelectionComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp0_addComponentListener = tmp3_apply.bg0_1;
    var tmp = tmp0_addComponentListener.lg1(getKClass(PreSelectionComponent));
    var container = tmp instanceof ComponentEntityContainer ? tmp : THROW_CCE();
    container.yg0(tmp1_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp3_addComponentListener = new SelectionComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp2_addComponentListener = tmp3_apply.bg0_1;
    var tmp_0 = tmp2_addComponentListener.lg1(getKClass(SelectedComponent));
    var container_0 = tmp_0 instanceof ComponentEntityContainer ? tmp_0 : THROW_CCE();
    container_0.yg0(tmp3_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp5_addComponentListener = new UIMapEntityComponentListener();
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp4_addComponentListener = tmp3_apply.bg0_1;
    var tmp_1 = tmp4_addComponentListener.lg1(getKClass(UIEntityViewComponent));
    var container_1 = tmp_1 instanceof ComponentEntityContainer ? tmp_1 : THROW_CCE();
    container_1.yg0(tmp5_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp7_addComponentListener = new UIMapEntityTextComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp6_addComponentListener = tmp3_apply.bg0_1;
    var tmp_2 = tmp6_addComponentListener.lg1(getKClass(UIMapEntityTextComponent));
    var container_2 = tmp_2 instanceof ComponentEntityContainer ? tmp_2 : THROW_CCE();
    container_2.yg0(tmp7_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp9_addComponentListener = new MutableShowRangeTimeComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp8_addComponentListener = tmp3_apply.bg0_1;
    var tmp_3 = tmp8_addComponentListener.lg1(getKClass(MutableShowRangeTimeComponent));
    var container_3 = tmp_3 instanceof ComponentEntityContainer ? tmp_3 : THROW_CCE();
    container_3.yg0(tmp9_addComponentListener);
    // Inline function 'com.xenotactic.ecs.World.addComponentListener' call
    var tmp11_addComponentListener = new UIShowRangeComponentListener(engine);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp10_addComponentListener = tmp3_apply.bg0_1;
    var tmp_4 = tmp10_addComponentListener.lg1(getKClass(UIShowRangeComponent));
    var container_4 = tmp_4 instanceof ComponentEntityContainer ? tmp_4 : THROW_CCE();
    container_4.yg0(tmp11_addComponentListener);
    tmp3_apply.jg3(new UIMonsterUpdatePositionSystem(tmp3_apply));
    tmp3_apply.jg3(new UIEightDirectionalMonsterSpriteDirectionSystem(tmp3_apply));
    tmp3_apply.jg3(new UIEightDirectionalMonsterAnimationSystem(tmp3_apply));
    tmp3_apply.jg3(new UITargetingRenderSystem(engine));
    tmp3_apply.jg3(new UITowerGunRotatingSystem(engine));
    tmp3_apply.jg3(new UIProjectileRenderSystem(engine));
    tmp3_apply.jg3(new UIMutableRangeTimeUpdateSystem(tmp3_apply));
    tmp3_apply.jg3(new UIMonsterHealthRenderSystem(tmp3_apply));
    tmp$ret$3 = tmp3_apply;
    gameSimulator.agt_1.lgs(randomMap.xhr());
    var uiDebugInfo = new UIDebugInfo(engine);
    engine.hgo_1.xg2(uiDebugInfo);
    new UIGuiContainer(_this__u8e3s4, engine, gameSimulator.agt_1);
    var tmp$ret$6;
    // Inline function 'korlibs.korge.input.keys' call
    var tmp$ret$5;
    // Inline function 'kotlin.run' call
    var tmp4_run = get_keys(_this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    var tmp_5 = Key_ESCAPE_getInstance();
    tmp$ret$4 = tmp4_run.eaf(tmp_5, PlayScene$sceneInit$slambda_0(editorState, null));
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    var tmp5_apply = addTo(new UINotificationText(engine, 'N/A'), _this__u8e3s4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.PlayScene.sceneInit.<anonymous>' call
    centerXOnStage(tmp5_apply);
    tmp$ret$7 = tmp5_apply;
    var notificationText = tmp$ret$7;
    var editorPlacementInputProcessor = new EditorPlacementInputProcessor(this.gae(), _this__u8e3s4, engine);
    editorPlacementInputProcessor.bhb(_this__u8e3s4);
    var deltaTime = _TimeSpan___init__impl__hqf6tm(_Duration___get_inWholeMilliseconds__impl__msfiry(gameSimulator.bgt_1).wi());
    var accumulatedTime = {_v: new TimeSpan(Companion_getInstance_4().y17_1)};
    var updateInfoTextFrequency = _TimeSpan___init__impl__hqf6tm(250.0);
    addFixedUpdater(_this__u8e3s4, deltaTime, VOID, VOID, PlayScene$sceneInit$lambda(gameSimulator, accumulatedTime, deltaTime, updateInfoTextFrequency, uiDebugInfo));
    return Unit_getInstance();
  };
  function get_VIEWS_INSTANCE() {
    var tmp = VIEWS_INSTANCE;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('VIEWS_INSTANCE');
    }
  }
  var VIEWS_INSTANCE;
  function Companion_4() {
    Companion_instance_4 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(RootScene).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.xiv_1 = tmp$ret$0;
  }
  var Companion_instance_4;
  function Companion_getInstance_30() {
    if (Companion_instance_4 == null)
      new Companion_4();
    return Companion_instance_4;
  }
  function RootScene$sceneInit$slambda(this$0, resultContinuation) {
    this.giw_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(RootScene$sceneInit$slambda).liw = function (it, $completion) {
    var tmp = this.miw(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(RootScene$sceneInit$slambda).g8 = function (p1, $completion) {
    return this.liw(p1 instanceof PlayMapEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(RootScene$sceneInit$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            var tmp1_info = Companion_getInstance_30().xiv_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'Setting up game scene...');
            }

            this.giw_1.ziw_1.qi1_1 = this.hiw_1.jhb_1;
            var tmp_0 = this;
            tmp_0.iiw_1 = this.giw_1.cb1();
            var tmp_1 = this;
            tmp_1.jiw_1 = Companion_getInstance_4().m18(0.0);
            var tmp_2 = this;
            tmp_2.kiw_1 = this.iiw_1.d9w_1;
            this.y7_1 = 1;
            suspendResult = this.iiw_1.ubd(getKClass(GameScene), [].slice(), this.jiw_1, this.kiw_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(RootScene$sceneInit$slambda).miw = function (it, completion) {
    var i = new RootScene$sceneInit$slambda(this.giw_1, completion);
    i.hiw_1 = it;
    return i;
  };
  function RootScene$sceneInit$slambda_0(this$0, resultContinuation) {
    var i = new RootScene$sceneInit$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.liw(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function $sceneInitCOROUTINE$4(_this__u8e3s4, _this__u8e3s4_0, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.iix_1 = _this__u8e3s4;
    this.jix_1 = _this__u8e3s4_0;
  }
  protoOf($sceneInitCOROUTINE$4).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            VIEWS_INSTANCE = this.iix_1.xiw_1;
            var tmp2_text = Colors_getInstance().k4s_1;
            var tmp3_text = get_DefaultTtfFontAsBitmap();
            var tmp4_text = Companion_getInstance_5().g5r_1;
            var tmp5_text = get_DefaultStringTextRenderer();
            var tmp6_text = Companion_getInstance_6().pdd_1;
            var tmp1_addTo = new Text('Loading...', 50.0, tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, null, null);
            var tmp0_apply = addTo(tmp1_addTo, this.jix_1);
            ;
            centerOnStage(tmp0_apply);
            ;
            this.y7_1 = 1;
            suspendResult = GlobalResources_getInstance().w22(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp7_register = this.iix_1.yiw_1;
            var tmp8_register = RootScene$sceneInit$slambda_0(this.iix_1, null);
            tmp7_register.cg7(getKClass(PlayMapEvent), tmp8_register);
            ;
            var tmp_0 = this;
            tmp_0.kix_1 = this.iix_1.cb1();
            var tmp_1 = this;
            tmp_1.lix_1 = Companion_getInstance_4().m18(0.0);
            var tmp_2 = this;
            tmp_2.mix_1 = this.kix_1.d9w_1;
            this.y7_1 = 2;
            suspendResult = this.kix_1.wbd(getKClass(PlayScene), [].slice(), this.lix_1, this.mix_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            ;
            return Unit_getInstance();
          case 3:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $sceneAfterDestroyCOROUTINE$5(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.vix_1 = _this__u8e3s4;
  }
  protoOf($sceneAfterDestroyCOROUTINE$5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = protoOf(Scene).kb4.call(this.vix_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp1_info = Companion_getInstance_30().xiv_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'sceneAfterDestroy called');
            }

            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $sceneDestroyCOROUTINE$6(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.eiy_1 = _this__u8e3s4;
  }
  protoOf($sceneDestroyCOROUTINE$6).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = protoOf(Scene).qb4.call(this.eiy_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var tmp1_info = Companion_getInstance_30().xiv_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'sceneDestroy called');
            }

            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function RootScene(views, globalEventBus, mapBridge) {
    Companion_getInstance_30();
    Scene.call(this);
    this.xiw_1 = views;
    this.yiw_1 = globalEventBus;
    this.ziw_1 = mapBridge;
  }
  protoOf(RootScene).ab4 = function (_set____db54di) {
    this.xiw_1 = _set____db54di;
  };
  protoOf(RootScene).gae = function () {
    return this.xiw_1;
  };
  protoOf(RootScene).mb4 = function (_this__u8e3s4, $completion) {
    var tmp = new $sceneInitCOROUTINE$4(this, _this__u8e3s4, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(RootScene).kb4 = function ($completion) {
    var tmp = new $sceneAfterDestroyCOROUTINE$5(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(RootScene).qb4 = function ($completion) {
    var tmp = new $sceneDestroyCOROUTINE$6(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  function Companion_5() {
    Companion_instance_5 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(TestScene).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.fiy_1 = tmp$ret$0;
  }
  var Companion_instance_5;
  function Companion_getInstance_31() {
    if (Companion_instance_5 == null)
      new Companion_5();
    return Companion_instance_5;
  }
  function TestScene$sceneInit$lambda($this$addTo) {
    var rowSpacing = new SpacingBetween(0.0);
    $this$addTo.kh5(TestScene$sceneInit$lambda$lambda(rowSpacing));
    $this$addTo.kh5(TestScene$sceneInit$lambda$lambda_0(rowSpacing));
    return Unit_getInstance();
  }
  function TestScene$sceneInit$lambda$lambda$lambda($this$invoke) {
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda);
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda_0);
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda_1);
    return Unit_getInstance();
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda() {
    return new UIText('User Name', Size_init_$Create$(100, 18));
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda_0() {
    return new UIText('Kills', Size_init_$Create$(64, 18));
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda_1() {
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new UIText('Damage');
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.scenes.TestScene.sceneInit.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    tmp0_apply.wdd(1.0);
    tmp$ret$0 = tmp0_apply;
    return tmp$ret$0;
  }
  function TestScene$sceneInit$lambda$lambda($rowSpacing) {
    return function () {
      var tmp = Companion_getInstance_16();
      var tmp_0 = Companion_getInstance_17().zh5([$rowSpacing]);
      return tmp.vh5(tmp_0, TestScene$sceneInit$lambda$lambda$lambda);
    };
  }
  function TestScene$sceneInit$lambda$lambda$lambda_0($this$invoke) {
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda_2);
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda_3);
    $this$invoke.xh5(TestScene$sceneInit$lambda$lambda$lambda$lambda_4);
    return Unit_getInstance();
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda_2() {
    return new UIText('Xenotactic', Size_init_$Create$(100, 18));
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda_3() {
    return new UIText('13', Size_init_$Create$(64, 18));
  }
  function TestScene$sceneInit$lambda$lambda$lambda$lambda_4() {
    return new UIText('123456');
  }
  function TestScene$sceneInit$lambda$lambda_0($rowSpacing) {
    return function () {
      var tmp = Companion_getInstance_16();
      var tmp_0 = Companion_getInstance_17().zh5([$rowSpacing]);
      return tmp.vh5(tmp_0, TestScene$sceneInit$lambda$lambda$lambda_0);
    };
  }
  function TestScene() {
    Companion_getInstance_31();
    Scene.call(this);
  }
  protoOf(TestScene).mb4 = function (_this__u8e3s4, $completion) {
    var tmp = new Column();
    var tmp_0 = ensureNotNull(_this__u8e3s4.laa());
    var tmp_1 = Companion_getInstance_17().zh5([new SpacingBetween(5.0), new SolidBackgroundColor(Colors_getInstance().m4s_1), new Padding(5.0)]);
    var col = tmp.uh5(tmp_0, tmp_1, TestScene$sceneInit$lambda);
    col.ih5_1.pbl(toScale(3));
    centerOnStage(col.ih5_1);
    return Unit_getInstance();
  };
  function DeadUIZonesState(zones) {
    var tmp;
    if (zones === VOID) {
      var tmp$ret$0;
      var tmp$ret$0_0;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$0 = ArrayList_init_$Create$();
      tmp$ret$0_0 = Unit_getInstance();
      tmp = tmp$ret$0;
    } else {
      tmp = zones;
    }
    zones = tmp;
    this.yhm_1 = zones;
  }
  protoOf(DeadUIZonesState).toString = function () {
    return 'DeadUIZonesState(zones=' + this.yhm_1 + ')';
  };
  protoOf(DeadUIZonesState).hashCode = function () {
    return hashCode(this.yhm_1);
  };
  protoOf(DeadUIZonesState).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DeadUIZonesState))
      return false;
    var tmp0_other_with_cast = other instanceof DeadUIZonesState ? other : THROW_CCE();
    if (!equals(this.yhm_1, tmp0_other_with_cast.yhm_1))
      return false;
    return true;
  };
  function EditorState$uiMapV2$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function EditorState$mouseDragInputProcessor$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MouseDragInputProcessor);
  }
  function EditorState$gameMapApi$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapApi);
  }
  function EditorState$selectorMouseProcessor$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(SelectorMouseProcessorV2);
  }
  function EditorState$switchToPlayingMode$lambda($this$adjustSettings) {
    $this$adjustSettings.thh_1 = true;
    return Unit_getInstance();
  }
  function EditorState$switchToEditingMode$lambda($this$adjustSettings) {
    $this$adjustSettings.uhh_1 = false;
    return Unit_getInstance();
  }
  function EditorState(engine, isEditingEnabled, entityTypeToPlace) {
    isEditingEnabled = isEditingEnabled === VOID ? false : isEditingEnabled;
    entityTypeToPlace = entityTypeToPlace === VOID ? MapEntityType_ROCK_getInstance() : entityTypeToPlace;
    this.rhd_1 = engine;
    this.shd_1 = isEditingEnabled;
    this.thd_1 = entityTypeToPlace;
    this.uhd_1 = 'N/A';
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.rhd_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(EditorState$uiMapV2$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.vhd_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.rhd_1.hgo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(MouseDragInputProcessor));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof MouseDragInputProcessor) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorState$mouseDragInputProcessor$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.whd_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.rhd_1.hgo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(GameMapApi));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof GameMapApi) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorState$gameMapApi$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.xhd_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_2 = this.rhd_1.hgo_1;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_9 = tmp0_getSingleton_2.wg2_1.h3(getKClass(SelectorMouseProcessorV2));
    tmp$ret$6 = (tmp_9 == null ? true : tmp_9 instanceof SelectorMouseProcessorV2) ? tmp_9 : THROW_CCE();
    var tmp0_elvis_lhs_2 = tmp$ret$6;
    var tmp_10;
    if (tmp0_elvis_lhs_2 == null) {
      throw new SingletonInjectionDoesNotExistException(EditorState$selectorMouseProcessor$lambda);
    } else {
      tmp_10 = tmp0_elvis_lhs_2;
    }
    tmp$ret$7 = tmp_10;
    tmp_8.yhd_1 = tmp$ret$7;
  }
  protoOf(EditorState).qiy = function (entityType) {
    if (this.shd_1) {
      this.zis();
    } else {
      this.riy(entityType);
    }
  };
  protoOf(EditorState).zis = function () {
    this.rhd_1.dgo_1.bg7(new NotificationTextUpdateEvent(this.uhd_1));
    this.whd_1.jhi(EditorState$switchToPlayingMode$lambda);
    this.shd_1 = false;
    this.vhd_1.siy();
    this.vhd_1.ghh();
    this.yhd_1.qhm_1 = true;
    println('selectorMouseProcessor is enabled');
  };
  protoOf(EditorState).riy = function (entityType) {
    this.rhd_1.dgo_1.bg7(new NotificationTextUpdateEvent(this.xhd_1.pgs(entityType)));
    this.whd_1.jhi(EditorState$switchToEditingMode$lambda);
    this.shd_1 = true;
    this.thd_1 = entityType;
    this.yhd_1.qhm_1 = false;
    this.yhd_1.p1g();
  };
  function MouseDragSettingsState() {
    this.ghu_1 = new MouseDragStateSettings(VOID, false);
  }
  function UIEightDirectionalMonsterAnimationSystem(world) {
    System.call(this);
    this.viy_1 = world;
    this.wiy_1 = new FamilyConfiguration(setOf([getKClass(UIEightDirectionalSpriteComponent), getKClass(PathSequenceTraversalComponent), getKClass(AnimationComponent)]));
  }
  protoOf(UIEightDirectionalMonsterAnimationSystem).jg2 = function () {
    return this.wiy_1;
  };
  protoOf(UIEightDirectionalMonsterAnimationSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIEightDirectionalMonsterAnimationSystem.update.<anonymous>' call
      var uiEightDirectionalSpriteComponent = this.viy_1.lg3(element, getKClass(UIEightDirectionalSpriteComponent));
      var animationComponent = this.viy_1.lg3(element, getKClass(AnimationComponent));
      var computedSpeedEffectComponent = this.viy_1.lg3(element, getKClass(ComputedSpeedEffectComponent));
      var tmp0_this = animationComponent;
      tmp0_this.rg3_1 = tmp0_this.rg3_1 + _Duration___get_inWholeMilliseconds__impl__msfiry(deltaTime).wi();
      var baseChangeAfterSpeedEffectTimeMillis = animationComponent.qg3_1 / computedSpeedEffectComponent.ag4_1;
      while (animationComponent.rg3_1 >= baseChangeAfterSpeedEffectTimeMillis) {
        uiEightDirectionalSpriteComponent.dg5_1.sh2();
        var tmp1_this = animationComponent;
        tmp1_this.rg3_1 = tmp1_this.rg3_1 - baseChangeAfterSpeedEffectTimeMillis;
      }
    }
  };
  function UIEightDirectionalMonsterSpriteDirectionSystem(world) {
    System.call(this);
    this.ziy_1 = world;
    this.aiz_1 = new FamilyConfiguration(setOf([getKClass(UIEightDirectionalSpriteComponent), getKClass(PathSequenceTraversalComponent)]));
  }
  protoOf(UIEightDirectionalMonsterSpriteDirectionSystem).jg2 = function () {
    return this.aiz_1;
  };
  protoOf(UIEightDirectionalMonsterSpriteDirectionSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIEightDirectionalMonsterSpriteDirectionSystem.update.<anonymous>' call
      var uiEightDirectionalSpriteComponent = this.ziy_1.lg3(element, getKClass(UIEightDirectionalSpriteComponent));
      var pathSequenceTraversalComponent = this.ziy_1.lg3(element, getKClass(PathSequenceTraversalComponent));
      var tmp$ret$1;
      // Inline function 'kotlin.let' call
      var tmp0_let = pathSequenceTraversalComponent.qg4_1;
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.systems.UIEightDirectionalMonsterSpriteDirectionSystem.update.<anonymous>.<anonymous>' call
      tmp$ret$0 = tmp0_let.ngk().mgb().dgd(tmp0_let.hgk().mgb());
      tmp$ret$1 = tmp$ret$0;
      var angle = tmp$ret$1;
      var direction8 = getDirection8(angle);
      uiEightDirectionalSpriteComponent.dg5_1.qh2(direction8);
    }
  };
  function UIMonsterHealthRenderSystem(world) {
    System.call(this);
    this.diz_1 = world;
    this.eiz_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(HealthComponent), getKClass(MaxHealthComponent), getKClass(UIHealthBarComponent)]));
  }
  protoOf(UIMonsterHealthRenderSystem).jg2 = function () {
    return this.eiz_1;
  };
  protoOf(UIMonsterHealthRenderSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIMonsterHealthRenderSystem.update.<anonymous>' call
      var healthComponent = this.diz_1.lg3(element, getKClass(HealthComponent));
      var maxHealthComponent = this.diz_1.lg3(element, getKClass(MaxHealthComponent));
      var uiHealthBarComponent = this.diz_1.lg3(element, getKClass(UIHealthBarComponent));
      uiHealthBarComponent.hg5_1.hd2(maxHealthComponent.ng4_1);
      uiHealthBarComponent.hg5_1.fd2(healthComponent.mg4_1);
    }
  };
  function UIMonsterUpdatePositionSystem$uiMapV2$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIMonsterUpdatePositionSystem(world) {
    System.call(this);
    this.hiz_1 = world;
    this.iiz_1 = new FamilyConfiguration(setOf([getKClass(MonsterComponent), getKClass(UIEntityViewComponent), getKClass(PathSequenceTraversalComponent), getKClass(VelocityComponent)]));
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.hiz_1.zfz_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIMonsterUpdatePositionSystem$uiMapV2$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.jiz_1 = tmp$ret$1;
  }
  protoOf(UIMonsterUpdatePositionSystem).jg2 = function () {
    return this.iiz_1;
  };
  protoOf(UIMonsterUpdatePositionSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIMonsterUpdatePositionSystem.update.<anonymous>' call
      var traversal = this.hiz_1.lg3(element, getKClass(PathSequenceTraversalComponent)).qg4_1;
      var currentPoint = traversal.ngk();
      var uiEntityViewComponent = this.hiz_1.lg3(element, getKClass(UIEntityViewComponent));
      var tmp0_container = this.jiz_1.chr(currentPoint.dg6_1, currentPoint.eg6_1, toGameUnit(0));
      var worldX = tmp0_container.c5().ngw_1;
      var worldY = tmp0_container.d5().ngw_1;
      xy_1(uiEntityViewComponent.fg5_1, worldX, worldY);
    }
  };
  function UIMutableRangeTimeUpdateSystem$update$lambda($this$modifyEntity) {
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.ModifyEntityApi.removeComponent' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.ComponentService.removeComponentForEntity' call
    var tmp0_removeComponentForEntity = $this$modifyEntity.zg2_1.bg0_1;
    var tmp1_removeComponentForEntity = $this$modifyEntity.yg2_1;
    tmp$ret$0 = tmp0_removeComponentForEntity.ig1(tmp1_removeComponentForEntity, getKClass(MutableShowRangeTimeComponent));
    tmp$ret$1 = tmp$ret$0;
    return Unit_getInstance();
  }
  function UIMutableRangeTimeUpdateSystem(world) {
    System.call(this);
    this.miz_1 = world;
    this.niz_1 = new FamilyConfiguration(setOf_0(getKClass(MutableShowRangeTimeComponent)));
  }
  protoOf(UIMutableRangeTimeUpdateSystem).jg2 = function () {
    return this.niz_1;
  };
  protoOf(UIMutableRangeTimeUpdateSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIMutableRangeTimeUpdateSystem.update.<anonymous>' call
      var comp = this.miz_1.lg3(element, getKClass(MutableShowRangeTimeComponent));
      var tmp0_this = comp;
      tmp0_this.xha_1 = tmp0_this.xha_1.l1(_Duration___get_inWholeMilliseconds__impl__msfiry(deltaTime));
      if (comp.xha_1.k1(new Long(0, 0)) <= 0) {
        this.miz_1.gg3(element, UIMutableRangeTimeUpdateSystem$update$lambda);
      }
    }
  };
  function UIProjectileRenderSystem$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIProjectileRenderSystem(engine) {
    System.call(this);
    this.qiz_1 = engine;
    this.riz_1 = new FamilyConfiguration(setOf([getKClass(ProjectileComponent), getKClass(MutableCenterPositionComponent)]));
    this.siz_1 = this.qiz_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.qiz_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIProjectileRenderSystem$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.tiz_1 = tmp$ret$1;
  }
  protoOf(UIProjectileRenderSystem).jg2 = function () {
    return this.riz_1;
  };
  protoOf(UIProjectileRenderSystem).vg2 = function (deltaTime) {
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var tmp1_updateShape = this.tiz_1.zhg_1;
    var result;
    var tmp$ret$1;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UIProjectileRenderSystem.update.<anonymous>.<anonymous>' call
      var mutableCenterPositionComponent = this.siz_1.lg3(element, getKClass(MutableCenterPositionComponent));
      var worldPoint = this.tiz_1.ehn(mutableCenterPositionComponent.og4_1, mutableCenterPositionComponent.pg4_1);
      // Inline function 'korlibs.image.vector.Context2d.fill' call
      var tmp0_fill = Colors_getInstance().m4s_1;
      {
        tmp0_apply.a5s();
      }
      // Inline function 'com.xenotactic.korge.systems.UIProjectileRenderSystem.update.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply.c3t(toPoint(worldPoint), 10.0);
      tmp0_apply.s75(new RGBA(tmp0_fill), null);
    }
    result = Unit_getInstance();
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.o5q();
    tmp1_updateShape.zen(tmp$ret$1);
    tmp$ret$2 = result;
  };
  function UITargetingRenderSystem$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UITargetingRenderSystem(engine) {
    System.call(this);
    this.wiz_1 = engine;
    this.xiz_1 = new FamilyConfiguration(setOf([getKClass(EntityTowerComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent), getKClass(RangeComponent), getKClass(TargetingComponent)]));
    this.yiz_1 = this.wiz_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.wiz_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UITargetingRenderSystem$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ziz_1 = tmp$ret$1;
  }
  protoOf(UITargetingRenderSystem).jg2 = function () {
    return this.xiz_1;
  };
  protoOf(UITargetingRenderSystem).vg2 = function (deltaTime) {
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var tmp1_updateShape = this.ziz_1.yhg_1;
    var result;
    var tmp$ret$2;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UITargetingRenderSystem.update.<anonymous>.<anonymous>' call
      var targetingComponent = this.yiz_1.lg3(element, getKClass(TargetingComponent));
      var sizeComponent = this.yiz_1.lg3(element, getKClass(SizeComponent));
      var bottomLeftPositionComponent = this.yiz_1.lg3(element, getKClass(BottomLeftPositionComponent));
      var centerPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
      var monsterCenterPoint = this.yiz_1.lg3(targetingComponent.cg5_1, getKClass(PathSequenceTraversalComponent)).qg4_1.ngk();
      var tmp0_container = this.ziz_1.ehn(centerPoint.dg6_1, centerPoint.eg6_1);
      var towerWorldX = tmp0_container.c5().ngw_1;
      var towerWorldY = tmp0_container.d5().ngw_1;
      var tmp1_container = this.ziz_1.ehn(monsterCenterPoint.dg6_1, monsterCenterPoint.eg6_1);
      var monsterWorldX = tmp1_container.c5().ngw_1;
      var monsterWorldY = tmp1_container.d5().ngw_1;
      var tmp$ret$0;
      $l$block: {
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp6_stroke = RGBA__withAd_impl_cralao(Colors_getInstance().m4s_1, 0.5);
        var tmp7_stroke = new StrokeInfo(2.0);
        if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        }
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp0_stroke = tmp7_stroke.j49_1;
        var tmp1_stroke = tmp7_stroke.m49_1;
        var tmp2_stroke = tmp7_stroke.o49_1;
        var tmp3_stroke = tmp7_stroke.p49_1;
        var tmp4_stroke = tmp7_stroke.q49_1;
        var tmp5_stroke = tmp7_stroke.r49_1;
        {
          tmp0_apply.a5s();
        }
        // Inline function 'com.xenotactic.korge.systems.UITargetingRenderSystem.update.<anonymous>.<anonymous>.<anonymous>' call
        tmp0_apply.z4k(Vector2_init_$Create$_0(WorldUnit__toDouble_impl_9mljzf(towerWorldX), WorldUnit__toDouble_impl_9mljzf(towerWorldY)), Vector2_init_$Create$_0(WorldUnit__toDouble_impl_9mljzf(monsterWorldX), WorldUnit__toDouble_impl_9mljzf(monsterWorldY)));
        // Inline function 'korlibs.image.vector.Context2d.keep' call
        tmp0_apply.t5t();
        try {
          // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
          tmp0_apply.u5t(tmp0_stroke);
          tmp0_apply.v5t(tmp1_stroke);
          tmp0_apply.w5t(tmp2_stroke);
          tmp0_apply.x5t(tmp3_stroke);
          tmp0_apply.y5t(tmp4_stroke);
          tmp0_apply.z5t(tmp5_stroke);
          tmp0_apply.a5u(new RGBA(tmp6_stroke));
        }finally {
          tmp0_apply.b5u();
        }
      }
    }
    result = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1.o5q();
    tmp1_updateShape.zen(tmp$ret$2);
    tmp$ret$3 = result;
  };
  function Companion_6() {
    Companion_instance_6 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UITowerGunRotatingSystem).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.aj0_1 = tmp$ret$0;
  }
  var Companion_instance_6;
  function Companion_getInstance_32() {
    if (Companion_instance_6 == null)
      new Companion_6();
    return Companion_instance_6;
  }
  function UITowerGunRotatingSystem$uiMap$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UITowerGunRotatingSystem(engine) {
    Companion_getInstance_32();
    System.call(this);
    this.dj0_1 = engine;
    this.ej0_1 = new FamilyConfiguration(setOf([getKClass(EntityTowerComponent), getKClass(BottomLeftPositionComponent), getKClass(SizeComponent), getKClass(UIGunBarrelComponent), getKClass(TargetingComponent)]));
    this.fj0_1 = this.dj0_1.ego_1.vgb_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.dj0_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UITowerGunRotatingSystem$uiMap$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.gj0_1 = tmp$ret$1;
  }
  protoOf(UITowerGunRotatingSystem).jg2 = function () {
    return this.ej0_1;
  };
  protoOf(UITowerGunRotatingSystem).vg2 = function (deltaTime) {
    // Inline function 'kotlin.sequences.forEach' call
    var tmp0_forEach = this.ug2().tg1();
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.systems.UITowerGunRotatingSystem.update.<anonymous>' call
      var sizeComponent = this.fj0_1.lg3(element, getKClass(SizeComponent));
      var bottomLeftPositionComponent = this.fj0_1.lg3(element, getKClass(BottomLeftPositionComponent));
      var uiGunBarrelComponent = this.fj0_1.lg3(element, getKClass(UIGunBarrelComponent));
      var targetingComponent = this.fj0_1.lg3(element, getKClass(TargetingComponent));
      var towerCenterPoint = getCenterPoint(bottomLeftPositionComponent, sizeComponent);
      var monsterCenterPoint = this.fj0_1.lg3(targetingComponent.cg5_1, getKClass(PathSequenceTraversalComponent)).qg4_1.ngk();
      var tmp0_container = this.gj0_1.ehn(towerCenterPoint.dg6_1, towerCenterPoint.eg6_1);
      var towerWorldX = tmp0_container.c5().ngw_1;
      var towerWorldY = tmp0_container.d5().ngw_1;
      var tmp1_container = this.gj0_1.ehn(monsterCenterPoint.dg6_1, monsterCenterPoint.eg6_1);
      var monsterWorldX = tmp1_container.c5().ngw_1;
      var monsterWorldY = tmp1_container.d5().ngw_1;
      var angle = (new IPoint(WorldUnit__toDouble_impl_9mljzf(towerWorldX), WorldUnit__toDouble_impl_9mljzf(towerWorldY))).dgd(new IPoint(WorldUnit__toDouble_impl_9mljzf(monsterWorldX), WorldUnit__toDouble_impl_9mljzf(monsterWorldY)));
      rotation(uiGunBarrelComponent.gg5_1, angle);
    }
  };
  function afterPointerActionChange($this) {
    var pointerAction = $this.sj2_1.yha_1;
    if (equals(pointerAction, Inactive_getInstance())) {
      $this.tj2_1.mbm(0.0);
      return Unit_getInstance();
    }
    $this.tj2_1.mbm(1.0);
    var tmp0_subject = pointerAction;
    if (equals(tmp0_subject, Inactive_getInstance())) {
      // Inline function 'kotlin.TODO' call
      throw new NotImplementedError();
    } else {
      if (tmp0_subject instanceof HighlightForPlacement) {
        $this.tj2_1.fbu('Placing entity: ' + pointerAction.khm_1.ygg());
      } else {
        if (tmp0_subject instanceof RemoveEntityAtPlace) {
          var tmp1_subject = pointerAction.fhm_1;
          var tmp0 = tmp1_subject.b2_1;
          var tmp;
          switch (tmp0) {
            case 0:
              throw new NotImplementedError();
            case 1:
              throw new NotImplementedError();
            case 2:
              throw new NotImplementedError();
            case 3:
              tmp = 'Removing rock';
              break;
            case 4:
              tmp = 'Removing tower';
              break;
            case 5:
              throw new NotImplementedError();
            case 6:
              throw new NotImplementedError();
            case 7:
              throw new NotImplementedError();
            case 8:
              throw new NotImplementedError();
            case 9:
              throw new NotImplementedError();
            case 10:
              tmp = 'Removing supply depot';
              break;
            default:
              noWhenBranchMatchedException();
              break;
          }
          $this.tj2_1.fbu(tmp);
        }
      }
    }
  }
  function UIActiveTextNotifier$placementComponent$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(ObjectPlacementEComponent);
  }
  function UIActiveTextNotifier$slambda(this$0, resultContinuation) {
    this.cj3_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIActiveTextNotifier$slambda).ej3 = function (it, $completion) {
    var tmp = this.fj3(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIActiveTextNotifier$slambda).g8 = function (p1, $completion) {
    return this.ej3(p1 instanceof PointerActionChangeEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIActiveTextNotifier$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          afterPointerActionChange(this.cj3_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIActiveTextNotifier$slambda).fj3 = function (it, completion) {
    var i = new UIActiveTextNotifier$slambda(this.cj3_1, completion);
    i.dj3_1 = it;
    return i;
  };
  function UIActiveTextNotifier$slambda_0(this$0, resultContinuation) {
    var i = new UIActiveTextNotifier$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ej3(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIActiveTextNotifier(engine, eventBus) {
    Container.call(this);
    this.qj2_1 = engine;
    this.rj2_1 = eventBus;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.qj2_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(ObjectPlacementEComponent));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof ObjectPlacementEComponent) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIActiveTextNotifier$placementComponent$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.sj2_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_5().p5r_1;
    var tmp3_text = Companion_getInstance_6().odd_1;
    var tmp4_text = Colors_getInstance().k4s_1;
    var tmp5_text = get_DefaultTtfFontAsBitmap();
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text('Hello world', tmp3_text, tmp4_text, tmp5_text, tmp2_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$2 = Unit_getInstance();
    tmp$ret$3 = tmp0_apply;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
    tmp_2.tj2_1 = alpha(tmp$ret$5, 0.0);
    var tmp$ret$6;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp8_register = this.rj2_1;
    var tmp9_register = UIActiveTextNotifier$slambda_0(this, null);
    tmp$ret$6 = tmp8_register.cg7(getKClass(PointerActionChangeEvent), tmp9_register);
  }
  function UIDebugInfo$pathLengthText$slambda($tmp0_apply, resultContinuation) {
    this.oj3_1 = $tmp0_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDebugInfo$pathLengthText$slambda).ght = function (it, $completion) {
    var tmp = this.hht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDebugInfo$pathLengthText$slambda).g8 = function (p1, $completion) {
    return this.ght(p1 instanceof UpdatedPathLineEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIDebugInfo$pathLengthText$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp0_safe_receiver = this.pj3_1.eg8_1;
          var tmp_0;
          var tmp_1 = tmp0_safe_receiver;
          if ((tmp_1 == null ? null : new GameUnit(tmp_1)) == null) {
            tmp_0 = null;
          } else {
            tmp_0 = GameUnit__toInt_impl_9qttgv(tmp0_safe_receiver);
          }
          this.oj3_1.fbu('Path length: ' + tmp_0);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIDebugInfo$pathLengthText$slambda).hht = function (it, completion) {
    var i = new UIDebugInfo$pathLengthText$slambda(this.oj3_1, completion);
    i.pj3_1 = it;
    return i;
  };
  function UIDebugInfo$pathLengthText$slambda_0($tmp0_apply, resultContinuation) {
    var i = new UIDebugInfo$pathLengthText$slambda($tmp0_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.ght(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIDebugInfo(engine) {
    Container.call(this);
    this.jiv_1 = engine;
    this.kiv_1 = this.jiv_1.dgo_1;
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_6().odd_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text('Hello world', tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.liv_1 = tmp$ret$3;
    var tmp_0 = this;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_0 = Companion_getInstance_6().odd_1;
    var tmp3_text_0 = Colors_getInstance().k4s_1;
    var tmp4_text_0 = get_DefaultTtfFontAsBitmap();
    var tmp5_text_0 = Companion_getInstance_5().g5r_1;
    var tmp6_text_0 = get_DefaultStringTextRenderer();
    var tmp7_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text('Hello world', tmp2_text_0, tmp3_text_0, tmp4_text_0, tmp5_text_0, tmp6_text_0, tmp7_text_0, null, null);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIDebugInfo.pathLengthText.<anonymous>' call
    alignTopToBottomOf(tmp0_apply_0, this.liv_1);
    var tmp$ret$4;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.kiv_1;
    var tmp1_register = UIDebugInfo$pathLengthText$slambda_0(tmp0_apply_0, null);
    tmp$ret$4 = tmp0_register.cg7(getKClass(UpdatedPathLineEvent), tmp1_register);
    tmp$ret$5 = tmp0_apply_0;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    tmp_0.miv_1 = tmp$ret$7;
  }
  function DropdownOptionClickedEvent(newOption) {
    this.gid_1 = newOption;
  }
  protoOf(DropdownOptionClickedEvent).toString = function () {
    return 'DropdownOptionClickedEvent(newOption=' + this.gid_1 + ')';
  };
  protoOf(DropdownOptionClickedEvent).hashCode = function () {
    return hashCode(this.gid_1);
  };
  protoOf(DropdownOptionClickedEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DropdownOptionClickedEvent))
      return false;
    var tmp0_other_with_cast = other instanceof DropdownOptionClickedEvent ? other : THROW_CCE();
    if (!equals(this.gid_1, tmp0_other_with_cast.gid_1))
      return false;
    return true;
  };
  function Empty() {
    Empty_instance = this;
    UIDropdownOption.call(this);
  }
  protoOf(Empty).s6x = function () {
    return 'NULL';
  };
  var Empty_instance;
  function Empty_getInstance() {
    if (Empty_instance == null)
      new Empty();
    return Empty_instance;
  }
  function NumberOption(text, data) {
    UIDropdownOption.call(this);
    this.gi7_1 = text;
    this.hi7_1 = data;
  }
  protoOf(NumberOption).s6x = function () {
    return this.gi7_1;
  };
  protoOf(NumberOption).toString = function () {
    return 'NumberOption(text=' + this.gi7_1 + ', data=' + toString(this.hi7_1) + ')';
  };
  protoOf(NumberOption).hashCode = function () {
    var result = getStringHashCode(this.gi7_1);
    result = imul(result, 31) + hashCode(this.hi7_1) | 0;
    return result;
  };
  protoOf(NumberOption).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof NumberOption))
      return false;
    var tmp0_other_with_cast = other instanceof NumberOption ? other : THROW_CCE();
    if (!(this.gi7_1 === tmp0_other_with_cast.gi7_1))
      return false;
    if (!equals(this.hi7_1, tmp0_other_with_cast.hi7_1))
      return false;
    return true;
  };
  function StringOption(text, data) {
    UIDropdownOption.call(this);
    this.hid_1 = text;
    this.iid_1 = data;
  }
  protoOf(StringOption).s6x = function () {
    return this.hid_1;
  };
  protoOf(StringOption).toString = function () {
    return 'StringOption(text=' + this.hid_1 + ', data=' + this.iid_1 + ')';
  };
  protoOf(StringOption).hashCode = function () {
    var result = getStringHashCode(this.hid_1);
    result = imul(result, 31) + getStringHashCode(this.iid_1) | 0;
    return result;
  };
  protoOf(StringOption).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof StringOption))
      return false;
    var tmp0_other_with_cast = other instanceof StringOption ? other : THROW_CCE();
    if (!(this.hid_1 === tmp0_other_with_cast.hid_1))
      return false;
    if (!(this.iid_1 === tmp0_other_with_cast.iid_1))
      return false;
    return true;
  };
  function UIDropdownOption() {
  }
  function UIDropdownSettings(dropdownWidth, dropdownHeight, dropdownEntryTextPaddingLeft) {
    dropdownWidth = dropdownWidth === VOID ? 100.0 : dropdownWidth;
    dropdownHeight = dropdownHeight === VOID ? 20.0 : dropdownHeight;
    dropdownEntryTextPaddingLeft = dropdownEntryTextPaddingLeft === VOID ? 5.0 : dropdownEntryTextPaddingLeft;
    this.qj3_1 = dropdownWidth;
    this.rj3_1 = dropdownHeight;
    this.sj3_1 = dropdownEntryTextPaddingLeft;
  }
  protoOf(UIDropdownSettings).toString = function () {
    return 'UIDropdownSettings(dropdownWidth=' + this.qj3_1 + ', dropdownHeight=' + this.rj3_1 + ', dropdownEntryTextPaddingLeft=' + this.sj3_1 + ')';
  };
  protoOf(UIDropdownSettings).hashCode = function () {
    var result = getNumberHashCode(this.qj3_1);
    result = imul(result, 31) + getNumberHashCode(this.rj3_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.sj3_1) | 0;
    return result;
  };
  protoOf(UIDropdownSettings).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIDropdownSettings))
      return false;
    var tmp0_other_with_cast = other instanceof UIDropdownSettings ? other : THROW_CCE();
    if (!equals(this.qj3_1, tmp0_other_with_cast.qj3_1))
      return false;
    if (!equals(this.rj3_1, tmp0_other_with_cast.rj3_1))
      return false;
    if (!equals(this.sj3_1, tmp0_other_with_cast.sj3_1))
      return false;
    return true;
  };
  function UIDropDownEntry(entryWidth, entryHeight, textPaddingLeft, data) {
    Container.call(this);
    this.cj6_1 = entryWidth;
    this.dj6_1 = entryHeight;
    this.ej6_1 = textPaddingLeft;
    this.fj6_1 = data;
    this.gj6_1 = this.cj6_1 - this.ej6_1;
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = this.cj6_1;
    var tmp3_solidRect = this.dj6_1;
    var tmp4_solidRect = Colors_getInstance().k4s_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(new Size(tmp2_solidRect, tmp3_solidRect), tmp4_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.ij6_1 = tmp$ret$3;
    var tmp_0 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text = this.fj6_1.s4_1.s6x();
    var tmp8_text = this.dj6_1;
    var tmp9_text = Colors_getInstance().l4s_1;
    var tmp10_text = get_DefaultTtfFontAsBitmap();
    var tmp11_text = Companion_getInstance_5().g5r_1;
    var tmp12_text = get_DefaultStringTextRenderer();
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo = new Text(tmp7_text, tmp8_text, tmp9_text, tmp10_text, tmp11_text, tmp12_text, true, null, null);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp5_apply = addTo(tmp6_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIDropDownEntry.<anonymous>' call
    scaleWhileMaintainingAspect(tmp5_apply, new ByWidthAndHeight(this.gj6_1, this.dj6_1));
    alignLeftToLeftOf(tmp5_apply, this.ij6_1, this.ej6_1);
    tmp$ret$4 = tmp5_apply;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp_0.hj6_1 = tmp$ret$6;
  }
  protoOf(UIDropDownEntry).jj6 = function (color) {
    this.ij6_1.sc1(color);
  };
  protoOf(UIDropDownEntry).kj6 = function (option) {
    this.fj6_1 = option;
    this.hj6_1.fbu(option.s4_1.s6x());
  };
  function decrementWindowIndex($this, delta) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_33().lj6_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.decrementWindowIndex.<anonymous>' call
      tmp$ret$0 = trimIndent('\n                decrement index\n                currWindowIndex: ' + $this.yi6_1 + '\n            ');
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    var tmp_0 = $this;
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp2_max = $this.yi6_1 - delta | 0;
    tmp$ret$2 = Math.max(0, tmp2_max);
    tmp_0.yi6_1 = tmp$ret$2;
  }
  function decrementWindowIndex$default($this, delta, $super) {
    delta = delta === VOID ? 1 : delta;
    return decrementWindowIndex($this, delta);
  }
  function incrementWindowIndex($this, delta) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_33().lj6_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.incrementWindowIndex.<anonymous>' call
      tmp$ret$0 = trimIndent('\n                incrementing index\n                currWindowIndex: ' + $this.yi6_1 + '\n                options.size: ' + $this.vi6_1.l() + '\n            ');
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    var tmp_0 = $this;
    var tmp$ret$3;
    // Inline function 'kotlin.math.min' call
    var tmp$ret$2;
    // Inline function 'kotlin.math.max' call
    var tmp2_max = ($this.vi6_1.l() - $this.bi7_1 | 0) - 1 | 0;
    tmp$ret$2 = Math.max(tmp2_max, 0);
    var tmp3_min = tmp$ret$2;
    var tmp4_min = $this.yi6_1 + delta | 0;
    tmp$ret$3 = Math.min(tmp3_min, tmp4_min);
    tmp_0.yi6_1 = tmp$ret$3;
  }
  function incrementWindowIndex$default($this, delta, $super) {
    delta = delta === VOID ? 1 : delta;
    return incrementWindowIndex($this, delta);
  }
  function updateMainEntry($this, option) {
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_33().lj6_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.updateMainEntry.<anonymous>' call
      tmp$ret$0 = 'Main entry was updated!';
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    $this.wi6_1.kj6(option);
    var event = new DropdownOptionClickedEvent(option.s4_1);
    $this.ei7_1.h1q(event);
  }
  function updateDropdownEntries($this) {
    var iterator = $this.ai7_1.h();
    var index = 0;
    while (iterator.j()) {
      var i = index;
      index = index + 1 | 0;
      var dropDownEntry = iterator.k();
      var optionIndex = $this.yi6_1 + i | 0;
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.updateDropdownEntries.<anonymous>' call
      dropDownEntry.kj6($this.vi6_1.n(optionIndex));
      tmp$ret$0 = dropDownEntry;
    }
    setSelectedDropdownEntryColorIfExists($this);
  }
  function setSelectedDropdownEntryColorIfExists($this) {
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = $this.ai7_1;
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.setSelectedDropdownEntryColorIfExists.<anonymous>' call
      element.jj6(element.fj6_1.r4_1 === $this.wi6_1.fj6_1.r4_1 ? $this.di7_1 : $this.ci7_1);
    }
  }
  function openDropdown($this) {
    addTo($this.zi6_1, $this);
    $this.xi6_1 = true;
  }
  function closeDropdown($this) {
    $this.zi6_1.mbn();
    $this.xi6_1 = false;
  }
  function Companion_7() {
    Companion_instance_7 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIDropdown).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.lj6_1 = tmp$ret$0;
    this.mj6_1 = new IndexedValue(0, Empty_getInstance());
  }
  var Companion_instance_7;
  function Companion_getInstance_33() {
    if (Companion_instance_7 == null)
      new Companion_7();
    return Companion_instance_7;
  }
  function UIDropdown$resetWithOptions$slambda($option, this$0, $curr, resultContinuation) {
    this.vj6_1 = $option;
    this.wj6_1 = this$0;
    this.xj6_1 = $curr;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$resetWithOptions$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$resetWithOptions$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIDropdown$resetWithOptions$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_info = Companion_getInstance_33().lj6_1;
          var tmp0_log = Level_INFO_getInstance();
          if (tmp1_info.q1n(tmp0_log)) {
            tmp1_info.r1n(tmp0_log, 'Clicked option: ' + this.vj6_1);
          }
          updateMainEntry(this.wj6_1, this.xj6_1.fj6_1);
          closeDropdown(this.wj6_1);
          setSelectedDropdownEntryColorIfExists(this.wj6_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIDropdown$resetWithOptions$slambda).yak = function (it, completion) {
    var i = new UIDropdown$resetWithOptions$slambda(this.vj6_1, this.wj6_1, this.xj6_1, completion);
    i.yj6_1 = it;
    return i;
  };
  function UIDropdown$resetWithOptions$slambda_0($option, this$0, $curr, resultContinuation) {
    var i = new UIDropdown$resetWithOptions$slambda($option, this$0, $curr, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIDropdown$resetWithOptions$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.hj7_1 = $tmp1_onClick;
    this.ij7_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$resetWithOptions$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$resetWithOptions$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIDropdown$resetWithOptions$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.hj7_1(this.ij7_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIDropdown$resetWithOptions$lambda$slambda).g1p = function (completion) {
    var i = new UIDropdown$resetWithOptions$lambda$slambda(this.hj7_1, this.ij7_1, completion);
    return i;
  };
  function UIDropdown$resetWithOptions$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIDropdown$resetWithOptions$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIDropdown$resetWithOptions$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIDropdown$resetWithOptions$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIDropdown$slambda(this$0, resultContinuation) {
    this.rj7_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIDropdown$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          openDropdown(this.rj7_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIDropdown$slambda).yak = function (it, completion) {
    var i = new UIDropdown$slambda(this.rj7_1, completion);
    i.sj7_1 = it;
    return i;
  };
  function UIDropdown$slambda_0(this$0, resultContinuation) {
    var i = new UIDropdown$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIDropdown$lambda$slambda($tmp4_onClick, $it, resultContinuation) {
    this.bj8_1 = $tmp4_onClick;
    this.cj8_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIDropdown$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.bj8_1(this.cj8_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIDropdown$lambda$slambda).g1p = function (completion) {
    var i = new UIDropdown$lambda$slambda(this.bj8_1, this.cj8_1, completion);
    return i;
  };
  function UIDropdown$lambda$slambda_0($tmp4_onClick, $it, resultContinuation) {
    var i = new UIDropdown$lambda$slambda($tmp4_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIDropdown$lambda($tmp1_safe_receiver, $tmp4_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIDropdown$lambda$slambda_0($tmp4_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIDropdown$slambda_1(this$0, resultContinuation) {
    this.lj8_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIDropdown$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          closeDropdown(this.lj8_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIDropdown$slambda_1).yak = function (it, completion) {
    var i = new UIDropdown$slambda_1(this.lj8_1, completion);
    i.mj8_1 = it;
    return i;
  };
  function UIDropdown$slambda_2(this$0, resultContinuation) {
    var i = new UIDropdown$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIDropdown$lambda$slambda_1($tmp6_onUpOutside, $it, resultContinuation) {
    this.vj8_1 = $tmp6_onUpOutside;
    this.wj8_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIDropdown$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.vj8_1(this.wj8_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIDropdown$lambda$slambda_1).g1p = function (completion) {
    var i = new UIDropdown$lambda$slambda_1(this.vj8_1, this.wj8_1, completion);
    return i;
  };
  function UIDropdown$lambda$slambda_2($tmp6_onUpOutside, $it, resultContinuation) {
    var i = new UIDropdown$lambda$slambda_1($tmp6_onUpOutside, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIDropdown$lambda_0($tmp1_safe_receiver, $tmp6_onUpOutside) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIDropdown$lambda$slambda_2($tmp6_onUpOutside, it, null));
      return Unit_getInstance();
    };
  }
  function UIDropdown$slambda_3(this$0, resultContinuation) {
    this.fj9_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIDropdown$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (!this.fj9_1.xi6_1)
            return Unit_getInstance();
          if (isScrollDown(this.gj9_1)) {
            incrementWindowIndex$default(this.fj9_1);
          }
          if (isScrollUp(this.gj9_1)) {
            decrementWindowIndex$default(this.fj9_1);
          }
          updateDropdownEntries(this.fj9_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIDropdown$slambda_3).yak = function (it, completion) {
    var i = new UIDropdown$slambda_3(this.fj9_1, completion);
    i.gj9_1 = it;
    return i;
  };
  function UIDropdown$slambda_4(this$0, resultContinuation) {
    var i = new UIDropdown$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIDropdown$lambda$slambda_3($tmp9_onScroll, $it, resultContinuation) {
    this.pj9_1 = $tmp9_onScroll;
    this.qj9_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIDropdown$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIDropdown$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIDropdown$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pj9_1(this.qj9_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIDropdown$lambda$slambda_3).g1p = function (completion) {
    var i = new UIDropdown$lambda$slambda_3(this.pj9_1, this.qj9_1, completion);
    return i;
  };
  function UIDropdown$lambda$slambda_4($tmp9_onScroll, $it, resultContinuation) {
    var i = new UIDropdown$lambda$slambda_3($tmp9_onScroll, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIDropdown$lambda_1($tmp1_safe_receiver, $tmp9_onScroll) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIDropdown$lambda$slambda_4($tmp9_onScroll, it, null));
      return Unit_getInstance();
    };
  }
  function UIDropdown(initialOptions, settings) {
    Companion_getInstance_33();
    initialOptions = initialOptions === VOID ? emptyList() : initialOptions;
    settings = settings === VOID ? new UIDropdownSettings() : settings;
    Container.call(this);
    this.si6_1 = settings.qj3_1;
    this.ti6_1 = settings.rj3_1;
    this.ui6_1 = settings.sj3_1;
    this.vi6_1 = emptyList();
    this.xi6_1 = false;
    this.yi6_1 = 0;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$0 = ArrayList_init_$Create$();
    tmp.ai7_1 = tmp$ret$0;
    this.bi7_1 = 10;
    this.ci7_1 = Colors_getInstance().k4s_1;
    this.di7_1 = Colors_getInstance().a4y_1;
    this.ei7_1 = new Signal();
    this.wi6_1 = this.rj9(this, this.si6_1, this.ti6_1, this.ui6_1, Companion_getInstance_33().mj6_1);
    var tmp_0 = this;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$1 = Unit_getInstance();
    tmp$ret$2 = tmp0_apply;
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    tmp_0.zi6_1 = tmp$ret$4;
    this.mid(initialOptions);
    var tmp$ret$8;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp3_onClick = this.wi6_1;
    var tmp4_onClick = UIDropdown$slambda_0(this, null);
    var tmp$ret$7;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent = click$factory_1();
    var tmp0_safe_receiver = tmp3_onClick;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$6;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$5;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = tmp2_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$5 = tmp_1.f1q(UIDropdown$lambda(tmp1_safe_receiver, tmp4_onClick));
      tmp$ret$6 = tmp$ret$5;
    }
    tmp$ret$7 = tmp3_onClick;
    tmp$ret$8 = tmp$ret$7;
    var tmp$ret$12;
    // Inline function 'korlibs.korge.input.onUpOutside' call
    var tmp6_onUpOutside = UIDropdown$slambda_2(this, null);
    var tmp$ret$11;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp5_doMouseEvent = upOutside$factory();
    var tmp0_safe_receiver_0 = this;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$10;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$9;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = tmp5_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$9 = tmp_2.f1q(UIDropdown$lambda_0(tmp1_safe_receiver_0, tmp6_onUpOutside));
      tmp$ret$10 = tmp$ret$9;
    }
    tmp$ret$11 = this;
    tmp$ret$12 = tmp$ret$11;
    var tmp$ret$16;
    // Inline function 'korlibs.korge.input.onScroll' call
    var tmp8_onScroll = this.zi6_1;
    var tmp9_onScroll = UIDropdown$slambda_4(this, null);
    var tmp$ret$15;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp7_doMouseEvent = scroll$factory();
    var tmp0_safe_receiver_1 = tmp8_onScroll;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$14;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$13;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_3 = tmp7_doMouseEvent.get(tmp1_safe_receiver_1);
      tmp$ret$13 = tmp_3.f1q(UIDropdown$lambda_1(tmp1_safe_receiver_1, tmp9_onScroll));
      tmp$ret$14 = tmp$ret$13;
    }
    tmp$ret$15 = tmp8_onScroll;
    tmp$ret$16 = tmp$ret$15;
  }
  protoOf(UIDropdown).rj9 = function (_this__u8e3s4, entryWidth, entryHeight, textPaddingLeft, data) {
    return addTo(new UIDropDownEntry(entryWidth, entryHeight, textPaddingLeft, data), _this__u8e3s4);
  };
  protoOf(UIDropdown).mid = function (newOptions) {
    this.yi6_1 = 0;
    var tmp$ret$1;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp1_info = Companion_getInstance_33().lj6_1;
    var tmp0_log = Level_INFO_getInstance();
    var tmp;
    if (tmp1_info.q1n(tmp0_log)) {
      var tmp$ret$0;
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.resetWithOptions.<anonymous>' call
      tmp$ret$0 = trimIndent('\n                Previous options size: ' + this.vi6_1.l() + '\n                new options size: ' + newOptions.l() + '\n                newOptions: ' + newOptions + '\n            ');
      tmp1_info.r1n(tmp0_log, tmp$ret$0);
      tmp = Unit_getInstance();
    }
    tmp$ret$1 = tmp;
    this.vi6_1 = toList_0(withIndex(newOptions));
    var tmp0_elvis_lhs = firstOrNull(this.vi6_1);
    this.wi6_1.kj6(tmp0_elvis_lhs == null ? Companion_getInstance_33().mj6_1 : tmp0_elvis_lhs);
    this.ai7_1.e4();
    var tmp$ret$6;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = this.zi6_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIDropdown.resetWithOptions.<anonymous>' call
    tmp2_apply.ibk();
    var iterator = this.vi6_1.h();
    var index = 0;
    $l$loop: while (iterator.j()) {
      var i = index;
      index = index + 1 | 0;
      var option = iterator.k();
      if (i >= this.bi7_1)
        break $l$loop;
      var curr = this.rj9(tmp2_apply, this.si6_1, this.ti6_1, this.ui6_1, option);
      var tmp$ret$5;
      // Inline function 'korlibs.korge.input.onClick' call
      var tmp1_onClick = UIDropdown$resetWithOptions$slambda_0(option, this, curr, null);
      var tmp$ret$4;
      // Inline function 'korlibs.korge.input.doMouseEvent' call
      var tmp0_doMouseEvent = click$factory_2();
      var tmp0_safe_receiver = curr;
      var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
      if (tmp1_safe_receiver == null)
        null;
      else {
        var tmp$ret$3;
        // Inline function 'kotlin.let' call
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$2;
        // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
        var tmp_0 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
        tmp$ret$2 = tmp_0.f1q(UIDropdown$resetWithOptions$lambda(tmp1_safe_receiver, tmp1_onClick));
        tmp$ret$3 = tmp$ret$2;
      }
      tmp$ret$4 = curr;
      tmp$ret$5 = tmp$ret$4;
      var tmp2_this = curr;
      tmp2_this.dbl(tmp2_this.f6g() + (this.ti6_1 + 5) * i);
      this.ai7_1.b(curr);
    }
    tmp$ret$6 = tmp2_apply;
    this.zi6_1.mbn();
    setSelectedDropdownEntryColorIfExists(this);
    var tmp$ret$8;
    // Inline function 'korlibs.logger.Logger.info' call
    var tmp4_info = Companion_getInstance_33().lj6_1;
    var tmp3_log = Level_INFO_getInstance();
    var tmp_1;
    if (tmp4_info.q1n(tmp3_log)) {
      var tmp$ret$7;
      // Inline function 'com.xenotactic.korge.ui.UIDropdown.resetWithOptions.<anonymous>' call
      tmp$ret$7 = trimIndent('\n                currWindowIndex: ' + this.yi6_1 + '\n            ');
      tmp4_info.r1n(tmp3_log, tmp$ret$7);
      tmp_1 = Unit_getInstance();
    }
    tmp$ret$8 = tmp_1;
  };
  protoOf(UIDropdown).gib = function () {
    var tmp = this.vi6_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.wi6_1.fj6_1.r4_1 + 1 | 0;
    var tmp1_min = this.vi6_1.l() - 1 | 0;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    var nextOption = tmp.n(tmp$ret$0);
    updateMainEntry(this, nextOption);
    incrementWindowIndex$default(this);
    updateDropdownEntries(this);
  };
  protoOf(UIDropdown).lia = function () {
    var tmp = this.vi6_1;
    var tmp$ret$0;
    // Inline function 'kotlin.math.max' call
    var tmp0_max = this.wi6_1.fj6_1.r4_1 - 1 | 0;
    tmp$ret$0 = Math.max(tmp0_max, 0);
    var previousOption = tmp.n(tmp$ret$0);
    updateMainEntry(this, previousOption);
    decrementWindowIndex$default(this);
    updateDropdownEntries(this);
  };
  protoOf(UIDropdown).fi7 = function () {
    return this.wi6_1.fj6_1.s4_1;
  };
  function click$factory_1() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function upOutside$factory() {
    return getPropertyCallableRef('upOutside', 1, KProperty1, function (receiver) {
      return receiver.ba9_1;
    }, null);
  }
  function scroll$factory() {
    return getPropertyCallableRef('scroll', 1, KProperty1, function (receiver) {
      return receiver.ja9_1;
    }, null);
  }
  function click$factory_2() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function UIEditorButtonsV2$slambda$slambda$lambda($this$adjustSettings) {
    $this$adjustSettings.thh_1 = true;
    return Unit_getInstance();
  }
  function UIEditorButtonsV2$slambda$lambda($this$adjustSettings) {
    $this$adjustSettings.thh_1 = false;
    return Unit_getInstance();
  }
  function UIEditorButtonsV2$slambda$slambda($thisWindow, this$0, $widthInput, $heightInput, resultContinuation) {
    this.aja_1 = $thisWindow;
    this.bja_1 = this$0;
    this.cja_1 = $widthInput;
    this.dja_1 = $heightInput;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.aja_1.n1q();
          this.bja_1.vhw_1.jhi(UIEditorButtonsV2$slambda$slambda$lambda);
          this.bja_1.thw_1.cgo(toGameUnit(toInt(this.cja_1.s6x())), toGameUnit(toInt(this.dja_1.s6x())));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda$slambda).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda$slambda(this.aja_1, this.bja_1, this.cja_1, this.dja_1, completion);
    i.eja_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda$slambda_0($thisWindow, this$0, $widthInput, $heightInput, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda$slambda($thisWindow, this$0, $widthInput, $heightInput, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$slambda$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.nja_1 = $tmp1_onClick;
    this.oja_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$slambda$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.nja_1(this.oja_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda$lambda$slambda).g1p = function (completion) {
    var i = new UIEditorButtonsV2$slambda$lambda$slambda(this.nja_1, this.oja_1, completion);
    return i;
  };
  function UIEditorButtonsV2$slambda$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$slambda$lambda_0($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$slambda$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$uiMapV2$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(UIMapV2);
  }
  function UIEditorButtonsV2$gameMapDimensionsState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapDimensionsState);
  }
  function UIEditorButtonsV2$editorState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(EditorState);
  }
  function UIEditorButtonsV2$mouseDragInputProcessor$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MouseDragInputProcessor);
  }
  function UIEditorButtonsV2$slambda(this$0, resultContinuation) {
    this.xja_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (this.xja_1.uhw_1.shd_1 ? this.xja_1.uhw_1.thd_1.equals(MapEntityType_START_getInstance()) : false) {
            this.xja_1.uhw_1.zis();
          } else {
            this.xja_1.uhw_1.riy(MapEntityType_START_getInstance());
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda(this.xja_1, completion);
    i.yja_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_0(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.hjb_1 = $tmp1_onClick;
    this.ijb_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.hjb_1(this.ijb_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda(this.hjb_1, this.ijb_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_1(this$0, resultContinuation) {
    this.rjb_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (this.rjb_1.uhw_1.shd_1 ? this.rjb_1.uhw_1.thd_1.equals(MapEntityType_FINISH_getInstance()) : false) {
            this.rjb_1.uhw_1.zis();
          } else {
            this.rjb_1.uhw_1.riy(MapEntityType_FINISH_getInstance());
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_1).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_1(this.rjb_1, completion);
    i.sjb_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_2(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda_1($tmp1_onClick, $it, resultContinuation) {
    this.bjc_1 = $tmp1_onClick;
    this.cjc_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.bjc_1(this.cjc_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_1).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda_1(this.bjc_1, this.cjc_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_2($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda_1($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda_0($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_2($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_3(this$0, resultContinuation) {
    this.ljc_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (this.ljc_1.uhw_1.shd_1 ? this.ljc_1.uhw_1.thd_1.equals(MapEntityType_FINISH_getInstance()) : false) {
            this.ljc_1.uhw_1.zis();
          } else {
            this.ljc_1.uhw_1.riy(MapEntityType_CHECKPOINT_getInstance());
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_3).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_3(this.ljc_1, completion);
    i.mjc_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_4(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda_3($tmp1_onClick, $it, resultContinuation) {
    this.vjc_1 = $tmp1_onClick;
    this.wjc_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.vjc_1(this.wjc_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_3).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda_3(this.vjc_1, this.wjc_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_4($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda_3($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda_1($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_4($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_5(this$0, resultContinuation) {
    this.fjd_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_5).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_5).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (this.fjd_1.uhw_1.shd_1 ? this.fjd_1.uhw_1.thd_1.equals(MapEntityType_FINISH_getInstance()) : false) {
            this.fjd_1.uhw_1.zis();
          } else {
            this.fjd_1.uhw_1.riy(MapEntityType_TELEPORT_IN_getInstance());
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_5).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_5(this.fjd_1, completion);
    i.gjd_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_6(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_5(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda_5($tmp1_onClick, $it, resultContinuation) {
    this.pjd_1 = $tmp1_onClick;
    this.qjd_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda_5).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_5).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pjd_1(this.qjd_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_5).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda_5(this.pjd_1, this.qjd_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_6($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda_5($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda_2($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_6($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_7(this$0, resultContinuation) {
    this.zjd_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_7).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_7).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (this.zjd_1.uhw_1.shd_1 ? this.zjd_1.uhw_1.thd_1.equals(MapEntityType_ROCK_getInstance()) : false) {
            this.zjd_1.uhw_1.zis();
          } else {
            this.zjd_1.uhw_1.riy(MapEntityType_ROCK_getInstance());
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_7).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_7(this.zjd_1, completion);
    i.aje_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_8(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_7(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda_7($tmp1_onClick, $it, resultContinuation) {
    this.jje_1 = $tmp1_onClick;
    this.kje_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda_7).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_7).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.jje_1(this.kje_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_7).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda_7(this.jje_1, this.kje_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_8($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda_7($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda_3($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_8($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_9(this$0, resultContinuation) {
    this.tje_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_9).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_9).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.tje_1.vhw_1.jhi(UIEditorButtonsV2$slambda$lambda);
          var tmp2_uiWindow = this.tje_1.rhw_1;
          var tmp3_uiWindow = size(150.0, 150.0);
          var tmp0_apply = addTo(new UIWindow('Resize Map', tmp3_uiWindow), tmp2_uiWindow);
          var tmp1_also = tmp0_apply;
          var tmp4__anonymous__pkmkx7 = tmp1_also.tdv_1.pd5_1;
          var thisWindow = tmp1_also;
          var tmp1_uiVerticalStack = get_UI_DEFAULT_PADDING();
          var tmp0_apply_0 = addTo(new UIVerticalStack(null, tmp1_uiVerticalStack, true), tmp4__anonymous__pkmkx7);
          var tmp1_uiText = Companion_getInstance_18().kd7_1;
          var tmp0_apply_1 = addTo(new UIText('Width:', tmp1_uiText), tmp0_apply_0);
          var tmp3_uiTextInput = GameUnit__toString_impl_3u51th(this.tje_1.shw_1.vje());
          var tmp4_uiTextInput = Size_init_$Create$(128, 24);
          var tmp2_also = addTo(new UITextInput(tmp3_uiTextInput, tmp4_uiTextInput), tmp0_apply_0);
          var widthInput = tmp2_also;
          var tmp6_uiText = Companion_getInstance_18().kd7_1;
          var tmp5_apply = addTo(new UIText('Height:', tmp6_uiText), tmp0_apply_0);
          var tmp8_uiTextInput = GameUnit__toString_impl_3u51th(this.tje_1.shw_1.wje());
          var tmp9_uiTextInput = Size_init_$Create$(128, 24);
          var tmp7_also = addTo(new UITextInput(tmp8_uiTextInput, tmp9_uiTextInput), tmp0_apply_0);
          var heightInput = tmp7_also;
          var tmp11_uiButton = Companion_getInstance_19().dc4_1;
          var tmp10_apply = addTo(new UIButton(tmp11_uiButton, 'Apply', null), tmp0_apply_0);
          var tmp1_onClick = UIEditorButtonsV2$slambda$slambda_0(thisWindow, this.tje_1, widthInput, heightInput, null);
          var tmp0_doMouseEvent = click$factory_9();
          var tmp0_safe_receiver = tmp10_apply;
          var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
          if (tmp1_safe_receiver == null)
            null;
          else {
            var tmp_0 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
            tmp_0.f1q(UIEditorButtonsV2$slambda$lambda_0(tmp1_safe_receiver, tmp1_onClick));
          }
          centerXOn(tmp0_apply_0, tmp1_also);
          centerOn(tmp1_also, this.tje_1.rhw_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_9).yak = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_9(this.tje_1, completion);
    i.uje_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_10(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_9(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$lambda$slambda_9($tmp1_onClick, $it, resultContinuation) {
    this.fjf_1 = $tmp1_onClick;
    this.gjf_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$lambda$slambda_9).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_9).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.fjf_1(this.gjf_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$lambda$slambda_9).g1p = function (completion) {
    var i = new UIEditorButtonsV2$lambda$slambda_9(this.fjf_1, this.gjf_1, completion);
    return i;
  };
  function UIEditorButtonsV2$lambda$slambda_10($tmp1_onClick, $it, resultContinuation) {
    var i = new UIEditorButtonsV2$lambda$slambda_9($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIEditorButtonsV2$lambda_4($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIEditorButtonsV2$lambda$slambda_10($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIEditorButtonsV2$slambda_11($addStartButton, $addFinishButton, this$0, resultContinuation) {
    this.pjf_1 = $addStartButton;
    this.qjf_1 = $addFinishButton;
    this.rjf_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_11).tjf = function (it, $completion) {
    var tmp = this.ujf(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_11).g8 = function (p1, $completion) {
    return this.tjf(p1 instanceof PlacedEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp0_subject = this.sjf_1.chd_1;
          var tmp0 = tmp0_subject.b2_1;
          switch (tmp0) {
            case 0:
              this.pjf_1.ycg();
              ;
              break;
            case 1:
              this.qjf_1.ycg();
              ;
              break;
            case 2:
              ;
              break;
            case 3:
              throw new NotImplementedError();
            case 4:
              throw new NotImplementedError();
            case 5:
              throw new NotImplementedError();
            case 6:
              ;
              break;
            case 7:
              throw new NotImplementedError();
            case 8:
              throw new NotImplementedError();
            case 9:
              throw new NotImplementedError();
            case 10:
              throw new NotImplementedError();
          }
          this.rjf_1.uhw_1.zis();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_11).ujf = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_11(this.pjf_1, this.qjf_1, this.rjf_1, completion);
    i.sjf_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_12($addStartButton, $addFinishButton, this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_11($addStartButton, $addFinishButton, this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.tjf(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$slambda_13(this$0, resultContinuation) {
    this.djg_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_13).fjg = function (it, $completion) {
    var tmp = this.gjg(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_13).g8 = function (p1, $completion) {
    return this.fjg(p1 instanceof EscapeButtonActionEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.djg_1.uhw_1.zis();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_13).gjg = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_13(this.djg_1, completion);
    i.ejg_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_14(this$0, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_13(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.fjg(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2$slambda_15(this$0, $deleteEntitiesButton, $buttonStack, resultContinuation) {
    this.pjg_1 = this$0;
    this.qjg_1 = $deleteEntitiesButton;
    this.rjg_1 = $buttonStack;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIEditorButtonsV2$slambda_15).tjg = function (it, $completion) {
    var tmp = this.ujg(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIEditorButtonsV2$slambda_15).g8 = function (p1, $completion) {
    return this.tjg(p1 instanceof EntitySelectionChangedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIEditorButtonsV2$slambda_15).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println('EntitySelectionChangedEvent: Went in here?! ' + this.pjg_1.qhw_1.ego_1.agc_1.ug1());
          if (this.pjg_1.qhw_1.ego_1.agc_1.ug1().p()) {
            this.qjg_1.mbn();
          } else {
            this.rjg_1.jbk(this.qjg_1);
          }
          this.pjg_1.whw();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIEditorButtonsV2$slambda_15).ujg = function (it, completion) {
    var i = new UIEditorButtonsV2$slambda_15(this.pjg_1, this.qjg_1, this.rjg_1, completion);
    i.sjg_1 = it;
    return i;
  };
  function UIEditorButtonsV2$slambda_16(this$0, $deleteEntitiesButton, $buttonStack, resultContinuation) {
    var i = new UIEditorButtonsV2$slambda_15(this$0, $deleteEntitiesButton, $buttonStack, resultContinuation);
    var l = function (it, $completion) {
      return i.tjg(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIEditorButtonsV2(engine, baseView) {
    Container.call(this);
    this.qhw_1 = engine;
    this.rhw_1 = baseView;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.qhw_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(UIMapV2));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof UIMapV2) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIEditorButtonsV2$uiMapV2$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.shw_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.qhw_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameMapDimensionsState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameMapDimensionsState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(UIEditorButtonsV2$gameMapDimensionsState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.thw_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.qhw_1.ggo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(EditorState));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof EditorState) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(UIEditorButtonsV2$editorState$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.uhw_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_2 = this.qhw_1.hgo_1;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_9 = tmp0_getSingleton_2.wg2_1.h3(getKClass(MouseDragInputProcessor));
    tmp$ret$6 = (tmp_9 == null ? true : tmp_9 instanceof MouseDragInputProcessor) ? tmp_9 : THROW_CCE();
    var tmp0_elvis_lhs_2 = tmp$ret$6;
    var tmp_10;
    if (tmp0_elvis_lhs_2 == null) {
      throw new SingletonInjectionDoesNotExistException(UIEditorButtonsV2$mouseDragInputProcessor$lambda);
    } else {
      tmp_10 = tmp0_elvis_lhs_2;
    }
    tmp$ret$7 = tmp_10;
    tmp_8.vhw_1 = tmp$ret$7;
    var tmp$ret$46;
    // Inline function 'korlibs.korge.ui.uiHorizontalStack' call
    var tmp1_uiHorizontalStack = get_UI_DEFAULT_PADDING();
    var tmp$ret$45;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(new UIHorizontalStack(null, tmp1_uiHorizontalStack, true), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>' call
    var tmp$ret$13;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(new UIButton(tmp1_uiButton, 'Add Start', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$11;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick = UIEditorButtonsV2$slambda_0(this, null);
    var tmp$ret$10;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent = click$factory_3();
    var tmp0_safe_receiver = tmp0_apply_0;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_11 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$8 = tmp_11.f1q(UIEditorButtonsV2$lambda(tmp1_safe_receiver, tmp1_onClick));
      tmp$ret$9 = tmp$ret$8;
    }
    tmp$ret$10 = tmp0_apply_0;
    tmp$ret$11 = tmp$ret$10;
    tmp$ret$12 = tmp0_apply_0;
    tmp$ret$13 = tmp$ret$12;
    var addStartButton = tmp$ret$13;
    var tmp$ret$19;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp3_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$18;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(new UIButton(tmp3_uiButton, 'Add Finish', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$17;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_0 = UIEditorButtonsV2$slambda_2(this, null);
    var tmp$ret$16;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_0 = click$factory_4();
    var tmp0_safe_receiver_0 = tmp2_apply;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$15;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$14;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_12 = tmp0_doMouseEvent_0.get(tmp1_safe_receiver_0);
      tmp$ret$14 = tmp_12.f1q(UIEditorButtonsV2$lambda_0(tmp1_safe_receiver_0, tmp1_onClick_0));
      tmp$ret$15 = tmp$ret$14;
    }
    tmp$ret$16 = tmp2_apply;
    tmp$ret$17 = tmp$ret$16;
    tmp$ret$18 = tmp2_apply;
    tmp$ret$19 = tmp$ret$18;
    var addFinishButton = tmp$ret$19;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp5_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$24;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(new UIButton(tmp5_uiButton, 'Add Checkpoint', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$23;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_1 = UIEditorButtonsV2$slambda_4(this, null);
    var tmp$ret$22;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_1 = click$factory_5();
    var tmp0_safe_receiver_1 = tmp4_apply;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$21;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$20;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_13 = tmp0_doMouseEvent_1.get(tmp1_safe_receiver_1);
      tmp$ret$20 = tmp_13.f1q(UIEditorButtonsV2$lambda_1(tmp1_safe_receiver_1, tmp1_onClick_1));
      tmp$ret$21 = tmp$ret$20;
    }
    tmp$ret$22 = tmp4_apply;
    tmp$ret$23 = tmp$ret$22;
    tmp$ret$24 = tmp4_apply;
    tmp$ret$25 = tmp$ret$24;
    var addCheckpoint = tmp$ret$25;
    var tmp$ret$31;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp7_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp6_apply = addTo(new UIButton(tmp7_uiButton, 'Add Teleport', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$29;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_2 = UIEditorButtonsV2$slambda_6(this, null);
    var tmp$ret$28;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_2 = click$factory_6();
    var tmp0_safe_receiver_2 = tmp6_apply;
    var tmp1_safe_receiver_2 = tmp0_safe_receiver_2 == null ? null : get_mouse(tmp0_safe_receiver_2);
    if (tmp1_safe_receiver_2 == null)
      null;
    else {
      var tmp$ret$27;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$26;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_14 = tmp0_doMouseEvent_2.get(tmp1_safe_receiver_2);
      tmp$ret$26 = tmp_14.f1q(UIEditorButtonsV2$lambda_2(tmp1_safe_receiver_2, tmp1_onClick_2));
      tmp$ret$27 = tmp$ret$26;
    }
    tmp$ret$28 = tmp6_apply;
    tmp$ret$29 = tmp$ret$28;
    tmp$ret$30 = tmp6_apply;
    tmp$ret$31 = tmp$ret$30;
    var addTeleport = tmp$ret$31;
    var tmp$ret$37;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp9_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$36;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(new UIButton(tmp9_uiButton, 'Add rocks', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$35;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_3 = UIEditorButtonsV2$slambda_8(this, null);
    var tmp$ret$34;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_3 = click$factory_7();
    var tmp0_safe_receiver_3 = tmp8_apply;
    var tmp1_safe_receiver_3 = tmp0_safe_receiver_3 == null ? null : get_mouse(tmp0_safe_receiver_3);
    if (tmp1_safe_receiver_3 == null)
      null;
    else {
      var tmp$ret$33;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$32;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_15 = tmp0_doMouseEvent_3.get(tmp1_safe_receiver_3);
      tmp$ret$32 = tmp_15.f1q(UIEditorButtonsV2$lambda_3(tmp1_safe_receiver_3, tmp1_onClick_3));
      tmp$ret$33 = tmp$ret$32;
    }
    tmp$ret$34 = tmp8_apply;
    tmp$ret$35 = tmp$ret$34;
    tmp$ret$36 = tmp8_apply;
    tmp$ret$37 = tmp$ret$36;
    var tmp$ret$43;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp11_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$42;
    // Inline function 'kotlin.apply' call
    var tmp10_apply = addTo(new UIButton(tmp11_uiButton, 'Resize map', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIEditorButtonsV2.<anonymous>.<anonymous>' call
    var tmp$ret$41;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_4 = UIEditorButtonsV2$slambda_10(this, null);
    var tmp$ret$40;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_4 = click$factory_8();
    var tmp0_safe_receiver_4 = tmp10_apply;
    var tmp1_safe_receiver_4 = tmp0_safe_receiver_4 == null ? null : get_mouse(tmp0_safe_receiver_4);
    if (tmp1_safe_receiver_4 == null)
      null;
    else {
      var tmp$ret$39;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$38;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_16 = tmp0_doMouseEvent_4.get(tmp1_safe_receiver_4);
      tmp$ret$38 = tmp_16.f1q(UIEditorButtonsV2$lambda_4(tmp1_safe_receiver_4, tmp1_onClick_4));
      tmp$ret$39 = tmp$ret$38;
    }
    tmp$ret$40 = tmp10_apply;
    tmp$ret$41 = tmp$ret$40;
    tmp$ret$42 = tmp10_apply;
    tmp$ret$43 = tmp$ret$42;
    var tmp$ret$44;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp12_register = this.qhw_1.dgo_1;
    var tmp13_register = UIEditorButtonsV2$slambda_12(addStartButton, addFinishButton, this, null);
    tmp$ret$44 = tmp12_register.cg7(getKClass(PlacedEntityEvent), tmp13_register);
    tmp$ret$45 = tmp0_apply;
    tmp$ret$46 = tmp$ret$45;
    var buttonStack = tmp$ret$46;
    var tmp$ret$47;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.qhw_1.dgo_1;
    var tmp3_register = UIEditorButtonsV2$slambda_14(this, null);
    tmp$ret$47 = tmp2_register.cg7(getKClass(EscapeButtonActionEvent), tmp3_register);
    var deleteEntitiesButton = new UIButton(VOID, 'Delete entities');
    var tmp$ret$48;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp4_register = this.qhw_1.dgo_1;
    var tmp5_register = UIEditorButtonsV2$slambda_16(this, deleteEntitiesButton, buttonStack, null);
    tmp$ret$48 = tmp4_register.cg7(getKClass(EntitySelectionChangedEvent), tmp5_register);
  }
  protoOf(UIEditorButtonsV2).whw = function () {
    centerXOn(this, this.rhw_1);
    alignBottomToBottomOf(this, this.rhw_1);
  };
  function click$factory_3() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_4() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_5() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_6() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_7() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_8() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_9() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function get_EMPTY_BOX_FN() {
    _init_properties_UIFixedGrid_kt__dnbms4();
    return EMPTY_BOX_FN;
  }
  var EMPTY_BOX_FN;
  function uiFixedGrid(_this__u8e3s4, maxColumns, maxRows, gridWidth, gridHeight, entryPaddingHorizontal, entryPaddingVertical, initialEntries) {
    initialEntries = initialEntries === VOID ? emptyList() : initialEntries;
    _init_properties_UIFixedGrid_kt__dnbms4();
    return addTo(new UIFixedGrid(maxColumns, maxRows, gridWidth, gridHeight, entryPaddingHorizontal, entryPaddingVertical, initialEntries, get_EMPTY_BOX_FN()), _this__u8e3s4);
  }
  function calculateXPosition($this, x) {
    return x * $this.wi9_1 + (x + 1 | 0) * numberToDouble($this.ti9_1);
  }
  function calculateYPosition($this, y) {
    return y * $this.xi9_1 + (y + 1 | 0) * numberToDouble($this.ui9_1);
  }
  function Companion_8() {
    Companion_instance_8 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIFixedGrid).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.vjg_1 = tmp$ret$0;
  }
  var Companion_instance_8;
  function Companion_getInstance_34() {
    if (Companion_instance_8 == null)
      new Companion_8();
    return Companion_instance_8;
  }
  function UIFixedGrid(maxColumns, maxRows, gridWidth, gridHeight, entryPaddingHorizontal, entryPaddingVertical, initialEntries, defaultInitializerFn, backgroundColor) {
    Companion_getInstance_34();
    initialEntries = initialEntries === VOID ? emptyList() : initialEntries;
    defaultInitializerFn = defaultInitializerFn === VOID ? get_EMPTY_BOX_FN() : defaultInitializerFn;
    backgroundColor = backgroundColor === VOID ? Colors_getInstance().s4t_1 : backgroundColor;
    Container.call(this);
    this.ri9_1 = maxColumns;
    this.si9_1 = maxRows;
    this.ti9_1 = entryPaddingHorizontal;
    this.ui9_1 = entryPaddingVertical;
    this.vi9_1 = defaultInitializerFn;
    this.wi9_1 = (numberToDouble(gridWidth) - numberToDouble(this.ti9_1) * (this.ri9_1 + 1 | 0)) / this.ri9_1;
    this.xi9_1 = (numberToDouble(gridHeight) - numberToDouble(this.ui9_1) * (this.si9_1 + 1 | 0)) / this.si9_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = numberToDouble(gridWidth);
    var tmp3_solidRect = numberToDouble(gridHeight);
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(new Size(tmp2_solidRect, tmp3_solidRect), backgroundColor);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    println(trimIndent('\n            gridEntryViewWidth: ' + this.wi9_1 + '\n            gridEntryViewHeight: ' + this.xi9_1 + '\n        '));
    var tmp = this;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$4 = Unit_getInstance();
    tmp$ret$5 = tmp0_apply_0;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    tmp.yi9_1 = tmp$ret$7;
    var tmp_0 = this;
    var tmp$ret$8;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$8 = LinkedHashMap_init_$Create$();
    tmp_0.zi9_1 = tmp$ret$8;
    this.aia(initialEntries);
  }
  protoOf(UIFixedGrid).wjg = function (x, y, view) {
    var point = new Vector2Int(x, y);
    var currView = ensureNotNull(this.zi9_1.h3(point));
    currView.mbn();
    var scaledView = scaleWhileMaintainingAspect(view, new ByWidthAndHeight(this.wi9_1, this.xi9_1));
    addTo(scaledView, this.yi9_1);
    scaledView.cbl(calculateXPosition(this, x));
    scaledView.dbl(calculateYPosition(this, y));
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.zi9_1;
    tmp0_set.e5(point, scaledView);
  };
  protoOf(UIFixedGrid).xjg = function (x, y) {
    this.wjg(x, y, this.vi9_1(x, y, this.wi9_1, this.xi9_1));
  };
  protoOf(UIFixedGrid).aia = function (entries) {
    this.yi9_1.ibk();
    var entriesIterator = entries.h();
    var inductionVariable = 0;
    var last = this.si9_1;
    if (inductionVariable < last)
      rowloop: do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        var last_0 = this.ri9_1;
        if (inductionVariable_0 < last_0)
          do {
            var x = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            var fn = entriesIterator.j() ? entriesIterator.k() : this.vi9_1;
            var gridEntry = fn(x, y, this.wi9_1, this.xi9_1);
            gridEntry.cbl(calculateXPosition(this, x));
            gridEntry.dbl(calculateYPosition(this, y));
            this.yi9_1.jbk(gridEntry);
            // Inline function 'kotlin.collections.set' call
            var tmp0_set = this.zi9_1;
            var tmp1_set = new Vector2Int(x, y);
            tmp0_set.e5(tmp1_set, gridEntry);
          }
           while (inductionVariable_0 < last_0);
      }
       while (inductionVariable < last);
  };
  protoOf(UIFixedGrid).e4 = function () {
    var inductionVariable = 0;
    var last = this.si9_1;
    if (inductionVariable < last)
      rowloop: do {
        var y = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var inductionVariable_0 = 0;
        var last_0 = this.ri9_1;
        if (inductionVariable_0 < last_0)
          do {
            var x = inductionVariable_0;
            inductionVariable_0 = inductionVariable_0 + 1 | 0;
            this.xjg(x, y);
          }
           while (inductionVariable_0 < last_0);
      }
       while (inductionVariable < last);
  };
  function EMPTY_BOX_FN$lambda(x, y, width, height) {
    _init_properties_UIFixedGrid_kt__dnbms4();
    return Companion_getInstance_20().neu(width, height, MaterialColors_getInstance().o5b_1);
  }
  var properties_initialized_UIFixedGrid_kt_1dbde2;
  function _init_properties_UIFixedGrid_kt__dnbms4() {
    if (properties_initialized_UIFixedGrid_kt_1dbde2) {
    } else {
      properties_initialized_UIFixedGrid_kt_1dbde2 = true;
      EMPTY_BOX_FN = EMPTY_BOX_FN$lambda;
    }
  }
  function MapWithMetadata(file, map, verificationResult) {
    this.jid_1 = file;
    this.kid_1 = map;
    this.lid_1 = verificationResult;
  }
  protoOf(MapWithMetadata).toString = function () {
    return 'MapWithMetadata(file=' + this.jid_1 + ', map=' + this.kid_1 + ', verificationResult=' + this.lid_1 + ')';
  };
  protoOf(MapWithMetadata).hashCode = function () {
    var result = this.jid_1.hashCode();
    result = imul(result, 31) + this.kid_1.hashCode() | 0;
    result = imul(result, 31) + hashCode(this.lid_1) | 0;
    return result;
  };
  protoOf(MapWithMetadata).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof MapWithMetadata))
      return false;
    var tmp0_other_with_cast = other instanceof MapWithMetadata ? other : THROW_CCE();
    if (!this.jid_1.equals(tmp0_other_with_cast.jid_1))
      return false;
    if (!this.kid_1.equals(tmp0_other_with_cast.kid_1))
      return false;
    if (!equals(this.lid_1, tmp0_other_with_cast.lid_1))
      return false;
    return true;
  };
  function EntrySettings(outlineRectangleColor, isDeleteButtonVisible) {
    this.yjg_1 = outlineRectangleColor;
    this.zjg_1 = isDeleteButtonVisible;
  }
  protoOf(EntrySettings).toString = function () {
    return 'EntrySettings(outlineRectangleColor=' + new RGBA(this.yjg_1) + ', isDeleteButtonVisible=' + this.zjg_1 + ')';
  };
  protoOf(EntrySettings).hashCode = function () {
    var result = RGBA__hashCode_impl_h59qf6(this.yjg_1);
    result = imul(result, 31) + (this.zjg_1 | 0) | 0;
    return result;
  };
  protoOf(EntrySettings).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof EntrySettings))
      return false;
    var tmp0_other_with_cast = other instanceof EntrySettings ? other : THROW_CCE();
    if (!(this.yjg_1 === tmp0_other_with_cast.yjg_1))
      return false;
    if (!(this.zjg_1 === tmp0_other_with_cast.zjg_1))
      return false;
    return true;
  };
  function UIGoldensViewerEntry_init_$Init$(eventBus, mapData, entryWidth, entryHeight, $this) {
    UIGoldensViewerEntry.call($this, eventBus, mapData, numberToDouble(entryWidth), numberToDouble(entryHeight));
    return $this;
  }
  function UIGoldensViewerEntry_init_$Create$(eventBus, mapData, entryWidth, entryHeight) {
    return UIGoldensViewerEntry_init_$Init$(eventBus, mapData, entryWidth, entryHeight, objectCreate(protoOf(UIGoldensViewerEntry)));
  }
  function Companion_9() {
    Companion_instance_9 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIGoldensViewerEntry).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.ajh_1 = tmp$ret$0;
  }
  var Companion_instance_9;
  function Companion_getInstance_35() {
    if (Companion_instance_9 == null)
      new Companion_9();
    return Companion_instance_9;
  }
  function UIGoldensViewerEntry$lambda$slambda($gameMapFile, this$0, $tmp24_apply, resultContinuation) {
    this.jjh_1 = $gameMapFile;
    this.kjh_1 = this$0;
    this.ljh_1 = $tmp24_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGoldensViewerEntry$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.jjh_1.x27(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            ;
            this.kjh_1.bjk(this.kjh_1.ajk_1.s6x() + ' (Deleted)');
            this.ljh_1.u96(false);
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda).g1p = function (completion) {
    var i = new UIGoldensViewerEntry$lambda$slambda(this.jjh_1, this.kjh_1, this.ljh_1, completion);
    return i;
  };
  function UIGoldensViewerEntry$lambda$slambda_0($gameMapFile, this$0, $tmp24_apply, resultContinuation) {
    var i = new UIGoldensViewerEntry$lambda$slambda($gameMapFile, this$0, $tmp24_apply, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGoldensViewerEntry$lambda($gameMapFile, this$0, $tmp24_apply) {
    return function (it) {
      println(get_baseName($gameMapFile) + ': delete button clicked!');
      var tmp = Dispatchers_getInstance().fv_1;
      launch_0(tmp, UIGoldensViewerEntry$lambda$slambda_0($gameMapFile, this$0, $tmp24_apply, null));
      return Unit_getInstance();
    };
  }
  function UIGoldensViewerEntry$slambda($gameMapFile, this$0, $gameMap, resultContinuation) {
    this.kjk_1 = $gameMapFile;
    this.ljk_1 = this$0;
    this.mjk_1 = $gameMap;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGoldensViewerEntry$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGoldensViewerEntry$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGoldensViewerEntry$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println(get_baseName(this.kjk_1) + ': This was clicked!');
          this.ljk_1.vjj_1.bg7(new GoldensEntryClickEvent(this.mjk_1));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGoldensViewerEntry$slambda).yak = function (it, completion) {
    var i = new UIGoldensViewerEntry$slambda(this.kjk_1, this.ljk_1, this.mjk_1, completion);
    i.njk_1 = it;
    return i;
  };
  function UIGoldensViewerEntry$slambda_0($gameMapFile, this$0, $gameMap, resultContinuation) {
    var i = new UIGoldensViewerEntry$slambda($gameMapFile, this$0, $gameMap, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGoldensViewerEntry$lambda$slambda_1($tmp26_onClick, $it, resultContinuation) {
    this.wjk_1 = $tmp26_onClick;
    this.xjk_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGoldensViewerEntry$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.wjk_1(this.xjk_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_1).g1p = function (completion) {
    var i = new UIGoldensViewerEntry$lambda$slambda_1(this.wjk_1, this.xjk_1, completion);
    return i;
  };
  function UIGoldensViewerEntry$lambda$slambda_2($tmp26_onClick, $it, resultContinuation) {
    var i = new UIGoldensViewerEntry$lambda$slambda_1($tmp26_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGoldensViewerEntry$lambda_0($tmp1_safe_receiver, $tmp26_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGoldensViewerEntry$lambda$slambda_2($tmp26_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGoldensViewerEntry$slambda_1(this$0, $gameMapFile, $gameMap, $verificationResult, resultContinuation) {
    this.gjl_1 = this$0;
    this.hjl_1 = $gameMapFile;
    this.ijl_1 = $gameMap;
    this.jjl_1 = $verificationResult;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGoldensViewerEntry$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGoldensViewerEntry$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGoldensViewerEntry$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.gjl_1.vjj_1.bg7(new GoldensEntryHoverOnEvent(this.hjl_1, this.ijl_1, this.jjl_1));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGoldensViewerEntry$slambda_1).yak = function (it, completion) {
    var i = new UIGoldensViewerEntry$slambda_1(this.gjl_1, this.hjl_1, this.ijl_1, this.jjl_1, completion);
    i.kjl_1 = it;
    return i;
  };
  function UIGoldensViewerEntry$slambda_2(this$0, $gameMapFile, $gameMap, $verificationResult, resultContinuation) {
    var i = new UIGoldensViewerEntry$slambda_1(this$0, $gameMapFile, $gameMap, $verificationResult, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGoldensViewerEntry$lambda$slambda_3($tmp28_onOver, $it, resultContinuation) {
    this.tjl_1 = $tmp28_onOver;
    this.ujl_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGoldensViewerEntry$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.tjl_1(this.ujl_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGoldensViewerEntry$lambda$slambda_3).g1p = function (completion) {
    var i = new UIGoldensViewerEntry$lambda$slambda_3(this.tjl_1, this.ujl_1, completion);
    return i;
  };
  function UIGoldensViewerEntry$lambda$slambda_4($tmp28_onOver, $it, resultContinuation) {
    var i = new UIGoldensViewerEntry$lambda$slambda_3($tmp28_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGoldensViewerEntry$lambda_1($tmp1_safe_receiver, $tmp28_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGoldensViewerEntry$lambda$slambda_4($tmp28_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGoldensViewerEntry(eventBus, mapData, entryWidth, entryHeight) {
    Companion_getInstance_35();
    Container.call(this);
    this.vjj_1 = eventBus;
    this.wjj_1 = mapData;
    this.xjj_1 = 3;
    this.yjj_1 = entryWidth - imul(this.xjj_1, 2);
    this.zjj_1 = 20;
    var gameMapFile = this.wjj_1.jid_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = MaterialColors_getInstance().k5g_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(new Size(entryWidth, entryHeight), tmp2_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var entryBackground = tmp$ret$3;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp5_solidRect = size(entryWidth, this.zjj_1);
    var tmp6_solidRect = MaterialColors_getInstance().n5g_1;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new SolidRect(tmp5_solidRect, tmp6_solidRect);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$4 = Unit_getInstance();
    tmp$ret$5 = tmp3_apply;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    var entryTextSection = tmp$ret$7;
    var tmp = this;
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.text' call
    var tmp9_text = get_baseName(gameMapFile);
    var tmp10_text = this.zjj_1;
    var tmp11_text = Colors_getInstance().k4s_1;
    var tmp12_text = get_DefaultTtfFontAsBitmap();
    var tmp13_text = Companion_getInstance_5().g5r_1;
    var tmp14_text = get_DefaultStringTextRenderer();
    var tmp15_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp8_addTo = new Text(tmp9_text, tmp10_text, tmp11_text, tmp12_text, tmp13_text, tmp14_text, tmp15_text, null, null);
    var tmp$ret$9;
    // Inline function 'kotlin.apply' call
    var tmp7_apply = addTo(tmp8_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$8;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$8 = Unit_getInstance();
    tmp$ret$9 = tmp7_apply;
    tmp$ret$10 = tmp$ret$9;
    tmp$ret$11 = tmp$ret$10;
    var tmp16_apply = tmp$ret$11;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGoldensViewerEntry.<anonymous>' call
    alignLeftToLeftOf_0(tmp16_apply, entryTextSection, this.xjj_1);
    tmp$ret$12 = tmp16_apply;
    tmp.ajk_1 = tmp$ret$12;
    var GAME_MAP_SECTION_HEIGHT = entryHeight - this.zjj_1;
    var gameMap = this.wjj_1.kid_1;
    var verificationResult = this.wjj_1.lid_1;
    var tmp$ret$13;
    // Inline function 'com.xenotactic.korge.ui.uiMapBox' call
    tmp$ret$13 = addTo(new UIMapBox(gameMap, numberToDouble(entryWidth), numberToDouble(GAME_MAP_SECTION_HEIGHT), 1.0), this);
    var mapBox = tmp$ret$13;
    alignTopToBottomOf(mapBox, entryTextSection);
    var tmp0_subject = verificationResult;
    var tmp_0;
    if (equals(tmp0_subject, Success_getInstance())) {
      tmp_0 = new EntrySettings(Colors_getInstance().n4s_1, false);
    } else {
      if (tmp0_subject instanceof Failure_0) {
        tmp_0 = new EntrySettings(Colors_getInstance().m4s_1, true);
      } else {
        throw new NotImplementedError();
      }
    }
    var entrySettings = tmp_0;
    var tmp$ret$17;
    // Inline function 'korlibs.korge.view.roundRect' call
    var tmp19_roundRect = size(entryWidth, entryHeight);
    var tmp20_roundRect = rectCorner(0.0, 0.0);
    var tmp21_roundRect = Colors_getInstance().q4s_1;
    var tmp22_roundRect = entrySettings.yjg_1;
    var tmp23_roundRect = this.xjj_1;
    var tmp$ret$16;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp18_addTo = new RoundRect(tmp19_roundRect, tmp20_roundRect, new RGBA(tmp21_roundRect), new RGBA(tmp22_roundRect), tmp23_roundRect, true);
    var tmp$ret$15;
    // Inline function 'kotlin.apply' call
    var tmp17_apply = addTo(tmp18_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$14;
    // Inline function 'korlibs.korge.view.roundRect.<anonymous>' call
    tmp$ret$14 = Unit_getInstance();
    tmp$ret$15 = tmp17_apply;
    tmp$ret$16 = tmp$ret$15;
    tmp$ret$17 = tmp$ret$16;
    var outlineRect = tmp$ret$17;
    if (existsBlocking(gameMapFile)) {
      var tmp1_size = size(55, 25);
      var tmp$ret$19;
      // Inline function 'korlibs.korge.ui.uiButton' call
      var tmp$ret$18;
      // Inline function 'kotlin.apply' call
      var tmp24_apply = addTo(new UIButton(tmp1_size, 'Delete', null), this);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UIGoldensViewerEntry.<anonymous>' call
      alignRightToRightOf(tmp24_apply, entryBackground, 10.0);
      alignBottomToBottomOf(tmp24_apply, entryBackground, 10.0);
      tmp24_apply.u96(entrySettings.zjg_1);
      tmp24_apply.rc7_1.g1q(UIGoldensViewerEntry$lambda(gameMapFile, this, tmp24_apply));
      tmp$ret$18 = tmp24_apply;
      tmp$ret$19 = tmp$ret$18;
      var deleteButton = tmp$ret$19;
    } else {
      this.bjk(this.ajk_1.s6x() + ' (Deleted)');
    }
    var tmp$ret$23;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp26_onClick = UIGoldensViewerEntry$slambda_0(gameMapFile, this, gameMap, null);
    var tmp$ret$22;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp25_doMouseEvent = click$factory_10();
    var tmp0_safe_receiver = mapBox;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$21;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$20;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = tmp25_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$20 = tmp_1.f1q(UIGoldensViewerEntry$lambda_0(tmp1_safe_receiver, tmp26_onClick));
      tmp$ret$21 = tmp$ret$20;
    }
    tmp$ret$22 = mapBox;
    tmp$ret$23 = tmp$ret$22;
    var tmp$ret$27;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp28_onOver = UIGoldensViewerEntry$slambda_2(this, gameMapFile, gameMap, verificationResult, null);
    var tmp$ret$26;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp27_doMouseEvent = over$factory();
    var tmp0_safe_receiver_0 = this;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$25;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$24;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = tmp27_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$24 = tmp_2.f1q(UIGoldensViewerEntry$lambda_1(tmp1_safe_receiver_0, tmp28_onOver));
      tmp$ret$25 = tmp$ret$24;
    }
    tmp$ret$26 = this;
    tmp$ret$27 = tmp$ret$26;
  }
  protoOf(UIGoldensViewerEntry).bjk = function (title) {
    this.ajk_1.fbu(title);
    scaleWhileMaintainingAspect(this.ajk_1, new ByWidthAndHeight(this.yjj_1, this.zjj_1));
  };
  function click$factory_10() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  var ViewType_NONE_instance;
  var ViewType_SINGLE_TOWER_SELECTION_instance;
  var ViewType_SINGLE_TOWER_SELECTION_UPGRADE_instance;
  var ViewType_MULTI_TOWER_SELECTION_instance;
  var ViewType_SINGLE_DEPOT_SELECTION_instance;
  var ViewType_entriesInitialized;
  function ViewType_initEntries() {
    if (ViewType_entriesInitialized)
      return Unit_getInstance();
    ViewType_entriesInitialized = true;
    ViewType_NONE_instance = new ViewType('NONE', 0);
    ViewType_SINGLE_TOWER_SELECTION_instance = new ViewType('SINGLE_TOWER_SELECTION', 1);
    ViewType_SINGLE_TOWER_SELECTION_UPGRADE_instance = new ViewType('SINGLE_TOWER_SELECTION_UPGRADE', 2);
    ViewType_MULTI_TOWER_SELECTION_instance = new ViewType('MULTI_TOWER_SELECTION', 3);
    ViewType_SINGLE_DEPOT_SELECTION_instance = new ViewType('SINGLE_DEPOT_SELECTION', 4);
  }
  function ViewType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function UIGuiContainer$slambda$lambda($this$modifyEntity) {
    var defaultShowTimeMillis = new Long(4000, 0);
    var tmp$ret$3;
    $l$block_0: {
      // Inline function 'com.xenotactic.ecs.ModifyEntityApi.getComponentOrAdd' call
      var tmp$ret$1;
      $l$block: {
        // Inline function 'com.xenotactic.ecs.ComponentService.getComponentForEntityOrNull' call
        var tmp2_getComponentForEntityOrNull = $this$modifyEntity.zg2_1.bg0_1;
        var tmp3_getComponentForEntityOrNull = $this$modifyEntity.yg2_1;
        var tmp$ret$0;
        // Inline function 'kotlin.collections.get' call
        var tmp0_get = tmp2_getComponentForEntityOrNull.cg1_1;
        var tmp1_get = getKClass(MutableShowRangeTimeComponent);
        tmp$ret$0 = (isInterface(tmp0_get, Map) ? tmp0_get : THROW_CCE()).h3(tmp1_get);
        var tmp0_elvis_lhs = tmp$ret$0;
        var tmp;
        if (tmp0_elvis_lhs == null) {
          tmp$ret$1 = null;
          break $l$block;
        } else {
          tmp = tmp0_elvis_lhs;
        }
        var arr = tmp;
        var tmp_0 = arr.ug0(tmp3_getComponentForEntityOrNull);
        tmp$ret$1 = (tmp_0 == null ? true : tmp_0 instanceof MutableShowRangeTimeComponent) ? tmp_0 : THROW_CCE();
      }
      var component = tmp$ret$1;
      if (component == null) {
        var tmp$ret$2;
        // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
        tmp$ret$2 = new MutableShowRangeTimeComponent(defaultShowTimeMillis);
        var newComponent = tmp$ret$2;
        $this$modifyEntity.og2(newComponent);
        tmp$ret$3 = newComponent;
        break $l$block_0;
      }
      tmp$ret$3 = component;
    }
    var comp = tmp$ret$3;
    comp.xha_1 = defaultShowTimeMillis;
    return Unit_getInstance();
  }
  function UIGuiContainer$editorState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(EditorState);
  }
  function UIGuiContainer$gameplayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function UIGuiContainer$deadUIZonesState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(DeadUIZonesState);
  }
  function UIGuiContainer$mutableGoldState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableGoldState);
  }
  function UIGuiContainer$addTowerView$slambda(this$0, resultContinuation) {
    this.djm_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addTowerView$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.djm_1.ljm_1.qiy(MapEntityType_TOWER_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$slambda).yak = function (it, completion) {
    var i = new UIGuiContainer$addTowerView$slambda(this.djm_1, completion);
    i.ejm_1 = it;
    return i;
  };
  function UIGuiContainer$addTowerView$slambda_0(this$0, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.ojn_1 = $tmp1_onClick;
    this.pjn_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ojn_1(this.pjn_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda).g1p = function (completion) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda(this.ojn_1, this.pjn_1, completion);
    return i;
  };
  function UIGuiContainer$addTowerView$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addTowerView$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$addTowerView$slambda_1(this$0, $tooltip, $tmp0_apply, resultContinuation) {
    this.yjn_1 = this$0;
    this.zjn_1 = $tooltip;
    this.ajo_1 = $tmp0_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addTowerView$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.yjn_1.qjm_1;
          var tmp0_apply = addTo(this.zjn_1, tmp1_addTo);
          scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(this.yjn_1.ujm_1, this.yjn_1.ujm_1));
          alignBottomToTopOf(tmp0_apply, this.ajo_1, 5.0);
          centerXOn(tmp0_apply, this.ajo_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$slambda_1).yak = function (it, completion) {
    var i = new UIGuiContainer$addTowerView$slambda_1(this.yjn_1, this.zjn_1, this.ajo_1, completion);
    i.bjo_1 = it;
    return i;
  };
  function UIGuiContainer$addTowerView$slambda_2(this$0, $tooltip, $tmp0_apply, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$slambda_1(this$0, $tooltip, $tmp0_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda$slambda_1($tmp3_onOver, $it, resultContinuation) {
    this.kjo_1 = $tmp3_onOver;
    this.ljo_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.kjo_1(this.ljo_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_1).g1p = function (completion) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda_1(this.kjo_1, this.ljo_1, completion);
    return i;
  };
  function UIGuiContainer$addTowerView$lambda$slambda_2($tmp3_onOver, $it, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda_1($tmp3_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda_0($tmp1_safe_receiver, $tmp3_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addTowerView$lambda$slambda_2($tmp3_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$addTowerView$slambda_3($tooltip, resultContinuation) {
    this.ujo_1 = $tooltip;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addTowerView$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ujo_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$slambda_3).yak = function (it, completion) {
    var i = new UIGuiContainer$addTowerView$slambda_3(this.ujo_1, completion);
    i.vjo_1 = it;
    return i;
  };
  function UIGuiContainer$addTowerView$slambda_4($tooltip, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$slambda_3($tooltip, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda$slambda_3($tmp5_onOut, $it, resultContinuation) {
    this.ejp_1 = $tmp5_onOut;
    this.fjp_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ejp_1(this.fjp_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addTowerView$lambda$slambda_3).g1p = function (completion) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda_3(this.ejp_1, this.fjp_1, completion);
    return i;
  };
  function UIGuiContainer$addTowerView$lambda$slambda_4($tmp5_onOut, $it, resultContinuation) {
    var i = new UIGuiContainer$addTowerView$lambda$slambda_3($tmp5_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addTowerView$lambda_1($tmp1_safe_receiver, $tmp5_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addTowerView$lambda$slambda_4($tmp5_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$addSupplyDepotView$slambda(this$0, resultContinuation) {
    this.ojp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ojp_1.ljm_1.qiy(MapEntityType_SUPPLY_DEPOT_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda).yak = function (it, completion) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda(this.ojp_1, completion);
    i.pjp_1 = it;
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$slambda_0(this$0, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.yjp_1 = $tmp1_onClick;
    this.zjp_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.yjp_1(this.zjp_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda).g1p = function (completion) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda(this.yjp_1, this.zjp_1, completion);
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addSupplyDepotView$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$addSupplyDepotView$slambda_1(this$0, $tooltip, $tmp0_apply, resultContinuation) {
    this.ijq_1 = this$0;
    this.jjq_1 = $tooltip;
    this.kjq_1 = $tmp0_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.ijq_1.qjm_1;
          var tmp0_apply = addTo(this.jjq_1, tmp1_addTo);
          scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(this.ijq_1.ujm_1, this.ijq_1.ujm_1));
          alignBottomToTopOf(tmp0_apply, this.kjq_1, 5.0);
          centerXOn(tmp0_apply, this.kjq_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_1).yak = function (it, completion) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda_1(this.ijq_1, this.jjq_1, this.kjq_1, completion);
    i.ljq_1 = it;
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$slambda_2(this$0, $tooltip, $tmp0_apply, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda_1(this$0, $tooltip, $tmp0_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda$slambda_1($tmp3_onOver, $it, resultContinuation) {
    this.ujq_1 = $tmp3_onOver;
    this.vjq_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ujq_1(this.vjq_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_1).g1p = function (completion) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda_1(this.ujq_1, this.vjq_1, completion);
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$lambda$slambda_2($tmp3_onOver, $it, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda_1($tmp3_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda_0($tmp1_safe_receiver, $tmp3_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addSupplyDepotView$lambda$slambda_2($tmp3_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$addSupplyDepotView$slambda_3($tooltip, resultContinuation) {
    this.ejr_1 = $tooltip;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ejr_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$slambda_3).yak = function (it, completion) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda_3(this.ejr_1, completion);
    i.fjr_1 = it;
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$slambda_4($tooltip, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$slambda_3($tooltip, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda$slambda_3($tmp5_onOut, $it, resultContinuation) {
    this.ojr_1 = $tmp5_onOut;
    this.pjr_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ojr_1(this.pjr_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$addSupplyDepotView$lambda$slambda_3).g1p = function (completion) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda_3(this.ojr_1, this.pjr_1, completion);
    return i;
  };
  function UIGuiContainer$addSupplyDepotView$lambda$slambda_4($tmp5_onOut, $it, resultContinuation) {
    var i = new UIGuiContainer$addSupplyDepotView$lambda$slambda_3($tmp5_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$addSupplyDepotView$lambda_1($tmp1_safe_receiver, $tmp5_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$addSupplyDepotView$lambda$slambda_4($tmp5_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda(completion);
    i.yjr_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_0(resultContinuation) {
    var i = new UIGuiContainer$slambda(resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.hjs_1 = $tmp1_onClick;
    this.ijs_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.hjs_1(this.ijs_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda(this.hjs_1, this.ijs_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_0() {
    return 'Singleton injection does not exist: ' + getKClass(UIDebugInfo);
  }
  function UIGuiContainer$slambda_1(this$0, resultContinuation) {
    this.rjs_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println('Spawn creep button clicked!');
          this.rjs_1.hjm_1.ngs();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_1).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_1(this.rjs_1, completion);
    i.sjs_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_2(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_1($tmp1_onClick, $it, resultContinuation) {
    this.bjt_1 = $tmp1_onClick;
    this.cjt_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.bjt_1(this.cjt_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_1).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_1(this.bjt_1, this.cjt_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_2($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_1($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_1($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_2($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_3(this$0, resultContinuation) {
    this.ljt_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println('print world button clicked!');
          println(this.ljt_1.jjm_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_3).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_3(this.ljt_1, completion);
    i.mjt_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_4(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_3($tmp1_onClick, $it, resultContinuation) {
    this.vjt_1 = $tmp1_onClick;
    this.wjt_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.vjt_1(this.wjt_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_3).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_3(this.vjt_1, this.wjt_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_4($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_3($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_2($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_4($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_5(this$0, resultContinuation) {
    this.fju_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_5).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_5).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp0_safe_receiver = this.fju_1.pjm_1;
          if (tmp0_safe_receiver == null)
            null;
          else {
            tmp0_safe_receiver.ogt();
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_5).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_5(this.fju_1, completion);
    i.gju_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_6(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_5(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_5($tmp1_onClick, $it, resultContinuation) {
    this.pju_1 = $tmp1_onClick;
    this.qju_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_5).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_5).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pju_1(this.qju_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_5).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_5(this.pju_1, this.qju_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_6($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_5($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_3($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_6($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_7(this$0, $tmp6_apply, resultContinuation) {
    this.zju_1 = this$0;
    this.ajv_1 = $tmp6_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_7).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_7).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          println('delete entities button clicked!');
          this.zju_1.kjm_1.bg7(new RemoveUIEntitiesEvent(toSet(this.zju_1.ijm_1.agc_1.tg1())));
          this.ajv_1.ycg();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_7).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_7(this.zju_1, this.ajv_1, completion);
    i.bjv_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_8(this$0, $tmp6_apply, resultContinuation) {
    var i = new UIGuiContainer$slambda_7(this$0, $tmp6_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_7($tmp1_onClick, $it, resultContinuation) {
    this.kjv_1 = $tmp1_onClick;
    this.ljv_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_7).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_7).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.kjv_1(this.ljv_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_7).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_7(this.kjv_1, this.ljv_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_8($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_7($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_4($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_8($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_9(this$0, $tmp6_apply, resultContinuation) {
    this.ujv_1 = this$0;
    this.vjv_1 = $tmp6_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_9).tjg = function (it, $completion) {
    var tmp = this.ujg(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_9).g8 = function (p1, $completion) {
    return this.tjg(p1 instanceof EntitySelectionChangedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          if (isEmpty(this.ujv_1.ijm_1.agc_1.tg1())) {
            this.vjv_1.ycg();
          } else {
            this.vjv_1.zbg();
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_9).ujg = function (it, completion) {
    var i = new UIGuiContainer$slambda_9(this.ujv_1, this.vjv_1, completion);
    i.wjv_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_10(this$0, $tmp6_apply, resultContinuation) {
    var i = new UIGuiContainer$slambda_9(this$0, $tmp6_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.tjg(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$slambda_11(this$0, resultContinuation) {
    this.fjw_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_11).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_11).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.fjw_1.kjm_1.bg7(new RemoveUIEntitiesEvent(toSet(this.fjw_1.ijm_1.agc_1.tg1())));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_11).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_11(this.fjw_1, completion);
    i.gjw_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_12(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_11(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_9($tmp1_onClick, $it, resultContinuation) {
    this.pjw_1 = $tmp1_onClick;
    this.qjw_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_9).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_9).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pjw_1(this.qjw_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_9).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_9(this.pjw_1, this.qjw_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_10($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_9($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_5($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_10($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_13(this$0, $tooltip, $tmp15_apply, resultContinuation) {
    this.zjw_1 = this$0;
    this.ajx_1 = $tooltip;
    this.bjx_1 = $tmp15_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_13).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_13).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.zjw_1.qjm_1;
          var tmp0_apply = addTo(this.ajx_1, tmp1_addTo);
          alignBottomToTopOf(tmp0_apply, this.bjx_1, 5.0);
          alignRightToRightOf(tmp0_apply, this.bjx_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_13).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_13(this.zjw_1, this.ajx_1, this.bjx_1, completion);
    i.cjx_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_14(this$0, $tooltip, $tmp15_apply, resultContinuation) {
    var i = new UIGuiContainer$slambda_13(this$0, $tooltip, $tmp15_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_11($tmp3_onOver, $it, resultContinuation) {
    this.ljx_1 = $tmp3_onOver;
    this.mjx_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_11).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_11).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ljx_1(this.mjx_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_11).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_11(this.ljx_1, this.mjx_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_12($tmp3_onOver, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_11($tmp3_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_6($tmp1_safe_receiver, $tmp3_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_12($tmp3_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_7($tooltip) {
    return function ($this$onAttachDetach, it) {
      $tooltip.mbn();
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_15($tooltip, resultContinuation) {
    this.vjx_1 = $tooltip;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_15).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_15).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_15).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.vjx_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_15).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_15(this.vjx_1, completion);
    i.wjx_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_16($tooltip, resultContinuation) {
    var i = new UIGuiContainer$slambda_15($tooltip, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_13($tmp5_onOut, $it, resultContinuation) {
    this.fjy_1 = $tmp5_onOut;
    this.gjy_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_13).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_13).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.fjy_1(this.gjy_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_13).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_13(this.fjy_1, this.gjy_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_14($tmp5_onOut, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_13($tmp5_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_8($tmp1_safe_receiver, $tmp5_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_14($tmp5_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_17(this$0, resultContinuation) {
    this.pjy_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_17).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_17).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_17).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp0_safe_receiver = this.pjy_1.xjm_1.vgo_1;
          var tmp_0 = tmp0_safe_receiver;
          if ((tmp_0 == null ? null : new EntityId(tmp_0)) == null)
            null;
          else {
            this.pjy_1.jjm_1.gg3(tmp0_safe_receiver, UIGuiContainer$slambda$lambda);
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_17).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_17(this.pjy_1, completion);
    i.qjy_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_18(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_17(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_15($tmp1_onClick, $it, resultContinuation) {
    this.zjy_1 = $tmp1_onClick;
    this.ajz_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_15).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_15).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_15).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.zjy_1(this.ajz_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_15).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_15(this.zjy_1, this.ajz_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_16($tmp1_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_15($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_9($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_16($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_19(this$0, $tooltip, $tmp16_apply, resultContinuation) {
    this.jjz_1 = this$0;
    this.kjz_1 = $tooltip;
    this.ljz_1 = $tmp16_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_19).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_19).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_19).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.jjz_1.qjm_1;
          var tmp0_apply = addTo(this.kjz_1, tmp1_addTo);
          alignBottomToTopOf(tmp0_apply, this.ljz_1, 5.0);
          alignRightToRightOf(tmp0_apply, this.ljz_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_19).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_19(this.jjz_1, this.kjz_1, this.ljz_1, completion);
    i.mjz_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_20(this$0, $tooltip, $tmp16_apply, resultContinuation) {
    var i = new UIGuiContainer$slambda_19(this$0, $tooltip, $tmp16_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_17($tmp3_onOver, $it, resultContinuation) {
    this.vjz_1 = $tmp3_onOver;
    this.wjz_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_17).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_17).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_17).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.vjz_1(this.wjz_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_17).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_17(this.vjz_1, this.wjz_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_18($tmp3_onOver, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_17($tmp3_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_10($tmp1_safe_receiver, $tmp3_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_18($tmp3_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_11($tooltip) {
    return function ($this$onAttachDetach, it) {
      $tooltip.mbn();
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_21($tooltip, resultContinuation) {
    this.fk0_1 = $tooltip;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_21).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_21).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_21).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.fk0_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_21).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_21(this.fk0_1, completion);
    i.gk0_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_22($tooltip, resultContinuation) {
    var i = new UIGuiContainer$slambda_21($tooltip, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_19($tmp5_onOut, $it, resultContinuation) {
    this.pk0_1 = $tmp5_onOut;
    this.qk0_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_19).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_19).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_19).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pk0_1(this.qk0_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_19).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_19(this.pk0_1, this.qk0_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_20($tmp5_onOut, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_19($tmp5_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_12($tmp1_safe_receiver, $tmp5_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_20($tmp5_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_23(this$0, resultContinuation) {
    this.zk0_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_23).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_23).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_23).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.zk0_1.bk1();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_23).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_23(this.zk0_1, completion);
    i.ak1_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_24(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_23(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_21($tmp5_onClick, $it, resultContinuation) {
    this.kk1_1 = $tmp5_onClick;
    this.lk1_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_21).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_21).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_21).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.kk1_1(this.lk1_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_21).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_21(this.kk1_1, this.lk1_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_22($tmp5_onClick, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_21($tmp5_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_13($tmp1_safe_receiver, $tmp5_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_22($tmp5_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_25(this$0, $tooltip, $tmp17_apply, resultContinuation) {
    this.uk1_1 = this$0;
    this.vk1_1 = $tooltip;
    this.wk1_1 = $tmp17_apply;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_25).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_25).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_25).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.uk1_1.qjm_1;
          var tmp0_apply = addTo(this.vk1_1, tmp1_addTo);
          scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(this.uk1_1.ujm_1, this.uk1_1.ujm_1));
          alignBottomToTopOf(tmp0_apply, this.wk1_1, 5.0);
          centerXOn(tmp0_apply, this.wk1_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_25).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_25(this.uk1_1, this.vk1_1, this.wk1_1, completion);
    i.xk1_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_26(this$0, $tooltip, $tmp17_apply, resultContinuation) {
    var i = new UIGuiContainer$slambda_25(this$0, $tooltip, $tmp17_apply, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_23($tmp7_onOver, $it, resultContinuation) {
    this.gk2_1 = $tmp7_onOver;
    this.hk2_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_23).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_23).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_23).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.gk2_1(this.hk2_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_23).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_23(this.gk2_1, this.hk2_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_24($tmp7_onOver, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_23($tmp7_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_14($tmp1_safe_receiver, $tmp7_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_24($tmp7_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$slambda_27($tooltip, resultContinuation) {
    this.qk2_1 = $tooltip;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_27).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_27).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_27).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.qk2_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_27).yak = function (it, completion) {
    var i = new UIGuiContainer$slambda_27(this.qk2_1, completion);
    i.rk2_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_28($tooltip, resultContinuation) {
    var i = new UIGuiContainer$slambda_27($tooltip, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$lambda$slambda_25($tmp9_onOut, $it, resultContinuation) {
    this.ak3_1 = $tmp9_onOut;
    this.bk3_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$lambda$slambda_25).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$lambda$slambda_25).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIGuiContainer$lambda$slambda_25).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ak3_1(this.bk3_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIGuiContainer$lambda$slambda_25).g1p = function (completion) {
    var i = new UIGuiContainer$lambda$slambda_25(this.ak3_1, this.bk3_1, completion);
    return i;
  };
  function UIGuiContainer$lambda$slambda_26($tmp9_onOut, $it, resultContinuation) {
    var i = new UIGuiContainer$lambda$slambda_25($tmp9_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIGuiContainer$lambda_15($tmp1_safe_receiver, $tmp9_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIGuiContainer$lambda$slambda_26($tmp9_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_16($tooltip) {
    return function ($this$onAttachDetach, it) {
      $tooltip.mbn();
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_17(this$0) {
    return function (it) {
      return this$0.jjm_1.lg3(it.zg0_1, getKClass(DamageUpgradeComponent)).cg4_1;
    };
  }
  function UIGuiContainer$lambda_18(this$0) {
    return function (it) {
      this$0.kjm_1.bg7(new UpgradeTowerDamageEvent(it));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$lambda_19(this$0) {
    return function (it) {
      return this$0.jjm_1.lg3(it.zg0_1, getKClass(SpeedUpgradeComponent)).ag5_1;
    };
  }
  function UIGuiContainer$lambda_20(this$0) {
    return function (it) {
      this$0.kjm_1.bg7(new UpgradeTowerSpeedEvent(it));
      return Unit_getInstance();
    };
  }
  function UIGuiContainer$4(this$0, $towerDamageUpgradeView) {
    this.ck3_1 = this$0;
    this.dk3_1 = $towerDamageUpgradeView;
  }
  protoOf(UIGuiContainer$4).ek3 = function (entityId, old, new_0) {
    var tmp = new EntityId(entityId);
    var tmp_0 = this.ck3_1.xjm_1.vgo_1;
    if (equals(tmp, tmp_0 == null ? null : new EntityId(tmp_0))) {
      this.dk3_1.ak6(this.dk3_1.xk5_1);
    }
  };
  protoOf(UIGuiContainer$4).rg0 = function (entityId, old, new_0) {
    var tmp = (old == null ? true : old instanceof DamageUpgradeComponent) ? old : THROW_CCE();
    return this.ek3(entityId, tmp, new_0 instanceof DamageUpgradeComponent ? new_0 : THROW_CCE());
  };
  function UIGuiContainer$5(this$0, $towerSpeedUpgradeView) {
    this.bk6_1 = this$0;
    this.ck6_1 = $towerSpeedUpgradeView;
  }
  protoOf(UIGuiContainer$5).dk6 = function (entityId, old, new_0) {
    var tmp = new EntityId(entityId);
    var tmp_0 = this.bk6_1.xjm_1.vgo_1;
    if (equals(tmp, tmp_0 == null ? null : new EntityId(tmp_0))) {
      this.ck6_1.ak6(this.ck6_1.xk5_1);
    }
  };
  protoOf(UIGuiContainer$5).rg0 = function (entityId, old, new_0) {
    var tmp = (old == null ? true : old instanceof SpeedUpgradeComponent) ? old : THROW_CCE();
    return this.dk6(entityId, tmp, new_0 instanceof SpeedUpgradeComponent ? new_0 : THROW_CCE());
  };
  function UIGuiContainer$slambda_29(this$0, $bottomRightGridHeight, $tooltipUpgradeDamage, $tooltipUpgradeSpeed, $showRangeView, $towerDamageUpgradeView, $towerSpeedUpgradeView, $towerUpgradeView, $sellEntitiesView, $bottomRightGridHorizontalPadding, $multiTowerSelectGrid, resultContinuation) {
    this.mk6_1 = this$0;
    this.nk6_1 = $bottomRightGridHeight;
    this.ok6_1 = $tooltipUpgradeDamage;
    this.pk6_1 = $tooltipUpgradeSpeed;
    this.qk6_1 = $showRangeView;
    this.rk6_1 = $towerDamageUpgradeView;
    this.sk6_1 = $towerSpeedUpgradeView;
    this.tk6_1 = $towerUpgradeView;
    this.uk6_1 = $sellEntitiesView;
    this.vk6_1 = $bottomRightGridHorizontalPadding;
    this.wk6_1 = $multiTowerSelectGrid;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_29).tjg = function (it, $completion) {
    var tmp = this.ujg(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_29).g8 = function (p1, $completion) {
    return this.tjg(p1 instanceof EntitySelectionChangedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_29).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.mk6_1.yk6();
          if (this.mk6_1.ijm_1.agc_1.l() === 1 ? this.mk6_1.ijm_1.zgc(this.mk6_1.ijm_1.agc_1.sg1()) : false) {
            this.mk6_1.wjm_1 = ViewType_SINGLE_TOWER_SELECTION_getInstance();
            var towerId = this.mk6_1.ijm_1.agc_1.sg1();
            this.mk6_1.xjm_1.vgo_1 = towerId;
            println('Selected one tower entity!');
            var tmp0_apply = this.mk6_1.rjm_1;
            var towerDamage = this.mk6_1.hjm_1.fgr(towerId);
            var weaponSpeedMillis = this.mk6_1.hjm_1.ugq(towerId);
            var attacksPerSecond = this.mk6_1.hjm_1.rgs(towerId, weaponSpeedMillis);
            var rangeComponent = this.mk6_1.jjm_1.lg3(towerId, getKClass(RangeComponent));
            var damageUpgradeComponent = this.mk6_1.jjm_1.lg3(towerId, getKClass(DamageUpgradeComponent));
            var speedUpgradeComponent = this.mk6_1.jjm_1.lg3(towerId, getKClass(SpeedUpgradeComponent));
            var tmp1_addTo = new UITowerDetails(towerDamage, weaponSpeedMillis, attacksPerSecond, _GameUnit___get_value__impl__9ocgox(rangeComponent.sg4_1), damageUpgradeComponent.cg4_1, speedUpgradeComponent.ag5_1, this.mk6_1.mjm_1.mgo_1, this.mk6_1.gjm_1);
            var tmp0_apply_0 = addTo(tmp1_addTo, tmp0_apply);
            scaleWhileMaintainingAspect(tmp0_apply_0, new ByHeight(this.nk6_1));
            centerXOnStage(tmp0_apply);
            alignBottomToBottomOfWindow(tmp0_apply);
            this.ok6_1.jk9(this.mk6_1.mjm_1.pgo_1 + damageUpgradeComponent.cg4_1 | 0);
            this.pk6_1.jk9(this.mk6_1.mjm_1.qgo_1 + speedUpgradeComponent.ag5_1 | 0);
            this.mk6_1.yjm_1.wjg(3, 0, this.qk6_1);
            this.mk6_1.yjm_1.wjg(0, 1, this.rk6_1);
            this.mk6_1.yjm_1.wjg(1, 1, this.sk6_1);
            this.mk6_1.yjm_1.wjg(2, 1, this.tk6_1);
            this.mk6_1.yjm_1.wjg(3, 1, this.uk6_1);
            var tmp2_addTo = this.mk6_1.zjm_1;
            var tmp3_addTo = this.mk6_1.qjm_1;
            var tmp1_apply = addTo(tmp2_addTo, tmp3_addTo);
            alignLeftToLeftOf_1(tmp1_apply, this.mk6_1.yjm_1, this.vk6_1 / 2.0);
            alignBottomToTopOf(tmp1_apply, this.mk6_1.yjm_1);
          } else if (this.mk6_1.ijm_1.agc_1.l() === 1 ? this.mk6_1.ijm_1.agd(this.mk6_1.ijm_1.agc_1.sg1()) : false) {
            this.mk6_1.wjm_1 = ViewType_SINGLE_DEPOT_SELECTION_getInstance();
            var towerId_0 = this.mk6_1.ijm_1.agc_1.sg1();
            this.mk6_1.xjm_1.vgo_1 = towerId_0;
            this.mk6_1.yjm_1.wjg(3, 1, this.uk6_1);
            var tmp5_addTo = this.mk6_1.zjm_1;
            var tmp6_addTo = this.mk6_1.qjm_1;
            var tmp4_apply = addTo(tmp5_addTo, tmp6_addTo);
            alignLeftToLeftOf_1(tmp4_apply, this.mk6_1.yjm_1, this.vk6_1 / 2.0);
            alignBottomToTopOf(tmp4_apply, this.mk6_1.yjm_1);
          } else if (this.mk6_1.ijm_1.agc_1.l() > 1) {
            this.mk6_1.wjm_1 = ViewType_MULTI_TOWER_SELECTION_getInstance();
            var tmp7_apply = this.mk6_1.rjm_1;
            addTo(this.wk6_1, tmp7_apply);
            centerXOnStage(tmp7_apply);
            alignBottomToBottomOfWindow(tmp7_apply);
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_29).ujg = function (it, completion) {
    var i = new UIGuiContainer$slambda_29(this.mk6_1, this.nk6_1, this.ok6_1, this.pk6_1, this.qk6_1, this.rk6_1, this.sk6_1, this.tk6_1, this.uk6_1, this.vk6_1, this.wk6_1, completion);
    i.xk6_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_30(this$0, $bottomRightGridHeight, $tooltipUpgradeDamage, $tooltipUpgradeSpeed, $showRangeView, $towerDamageUpgradeView, $towerSpeedUpgradeView, $towerUpgradeView, $sellEntitiesView, $bottomRightGridHorizontalPadding, $multiTowerSelectGrid, resultContinuation) {
    var i = new UIGuiContainer$slambda_29(this$0, $bottomRightGridHeight, $tooltipUpgradeDamage, $tooltipUpgradeSpeed, $showRangeView, $towerDamageUpgradeView, $towerSpeedUpgradeView, $towerUpgradeView, $sellEntitiesView, $bottomRightGridHorizontalPadding, $multiTowerSelectGrid, resultContinuation);
    var l = function (it, $completion) {
      return i.tjg(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer$slambda_31(this$0, resultContinuation) {
    this.sk9_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIGuiContainer$slambda_31).uk9 = function (it, $completion) {
    var tmp = this.vk9(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIGuiContainer$slambda_31).g8 = function (p1, $completion) {
    return this.uk9(p1 instanceof RemovedTowerEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIGuiContainer$slambda_31).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp_0 = this.sk9_1.xjm_1.vgo_1;
          if (equals(tmp_0 == null ? null : new EntityId(tmp_0), new EntityId(this.tk9_1.yg7_1))) {
            this.sk9_1.yk6();
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIGuiContainer$slambda_31).vk9 = function (it, completion) {
    var i = new UIGuiContainer$slambda_31(this.sk9_1, completion);
    i.tk9_1 = it;
    return i;
  };
  function UIGuiContainer$slambda_32(this$0, resultContinuation) {
    var i = new UIGuiContainer$slambda_31(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.uk9(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIGuiContainer(hstage, engine, gameMapApi) {
    this.fjm_1 = hstage;
    this.gjm_1 = engine;
    this.hjm_1 = gameMapApi;
    this.ijm_1 = this.gjm_1.ego_1;
    this.jjm_1 = this.ijm_1.vgb_1;
    this.kjm_1 = this.gjm_1.dgo_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.gjm_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(EditorState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof EditorState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIGuiContainer$editorState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ljm_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.gjm_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameplayState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(UIGuiContainer$gameplayState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.mjm_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$5;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_1 = this.gjm_1.ggo_1;
    var tmp$ret$4;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_6 = tmp0_getSingleton_1.wg2_1.h3(getKClass(DeadUIZonesState));
    tmp$ret$4 = (tmp_6 == null ? true : tmp_6 instanceof DeadUIZonesState) ? tmp_6 : THROW_CCE();
    var tmp0_elvis_lhs_1 = tmp$ret$4;
    var tmp_7;
    if (tmp0_elvis_lhs_1 == null) {
      throw new SingletonInjectionDoesNotExistException(UIGuiContainer$deadUIZonesState$lambda);
    } else {
      tmp_7 = tmp0_elvis_lhs_1;
    }
    tmp$ret$5 = tmp_7;
    tmp_5.njm_1 = tmp$ret$5;
    var tmp_8 = this;
    var tmp$ret$7;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_2 = this.gjm_1.ggo_1;
    var tmp$ret$6;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_9 = tmp0_getSingleton_2.wg2_1.h3(getKClass(MutableGoldState));
    tmp$ret$6 = (tmp_9 == null ? true : tmp_9 instanceof MutableGoldState) ? tmp_9 : THROW_CCE();
    var tmp0_elvis_lhs_2 = tmp$ret$6;
    var tmp_10;
    if (tmp0_elvis_lhs_2 == null) {
      throw new SingletonInjectionDoesNotExistException(UIGuiContainer$mutableGoldState$lambda);
    } else {
      tmp_10 = tmp0_elvis_lhs_2;
    }
    tmp$ret$7 = tmp_10;
    tmp_8.ojm_1 = tmp$ret$7;
    var tmp_11 = this;
    var tmp$ret$8;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp0_getSingletonOrNull = this.gjm_1.hgo_1;
    var tmp_12 = tmp0_getSingletonOrNull.wg2_1.h3(getKClass(GameSimulator));
    tmp$ret$8 = (tmp_12 == null ? true : tmp_12 instanceof GameSimulator) ? tmp_12 : THROW_CCE();
    tmp_11.pjm_1 = tmp$ret$8;
    var tmp_13 = this;
    var tmp$ret$12;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container = this.fjm_1;
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$10;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, tmp2_container);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$9;
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.stage.<anonymous>' call
    tmp$ret$9 = Unit_getInstance();
    tmp$ret$10 = tmp0_apply;
    tmp$ret$11 = tmp$ret$10;
    tmp$ret$12 = tmp$ret$11;
    tmp_13.qjm_1 = tmp$ret$12;
    var tmp_14 = this;
    var tmp$ret$16;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container_0 = this.qjm_1;
    var tmp$ret$15;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$14;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, tmp2_container_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$13;
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.middleSelectionContainer.<anonymous>' call
    tmp$ret$13 = Unit_getInstance();
    tmp$ret$14 = tmp0_apply_0;
    tmp$ret$15 = tmp$ret$14;
    tmp$ret$16 = tmp$ret$15;
    tmp_14.rjm_1 = tmp$ret$16;
    var tmp_15 = this;
    var tmp$ret$20;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container_1 = this.qjm_1;
    var tmp$ret$19;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = new Container(false);
    var tmp$ret$18;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_1, tmp2_container_1);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$17;
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.settingsWindows.<anonymous>' call
    tmp$ret$17 = Unit_getInstance();
    tmp$ret$18 = tmp0_apply_1;
    tmp$ret$19 = tmp$ret$18;
    tmp$ret$20 = tmp$ret$19;
    tmp_15.sjm_1 = tmp$ret$20;
    this.tjm_1 = 400.0;
    this.ujm_1 = this.tjm_1 / 1.5;
    this.vjm_1 = 50.0;
    this.wjm_1 = ViewType_NONE_getInstance();
    this.xjm_1 = new MutableCurrentlySelectedTowerState(null);
    this.ajn_1 = new UITextRect('Global\nDamage\nUpgrade', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    this.bjn_1 = new UITextRect('Global\nRange\nUpgrade', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    this.cjn_1 = new UITextRect('Income\nUpgrade', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    this.djn_1 = createUIEntityContainerForTower(toWorldUnit(this.vjm_1), toWorldUnit(this.vjm_1), TowerType_HIGH_DAMAGE_getInstance());
    var tmp_16 = this;
    var tmp$ret$33;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_2 = new UITextRect('Add\nTower', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.addTowerView.<anonymous>' call
    var tmp$ret$24;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick = UIGuiContainer$addTowerView$slambda_0(this, null);
    var tmp$ret$23;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent = click$factory_11();
    var tmp0_safe_receiver = tmp0_apply_2;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$22;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$21;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_17 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$21 = tmp_17.f1q(UIGuiContainer$addTowerView$lambda(tmp1_safe_receiver, tmp1_onClick));
      tmp$ret$22 = tmp$ret$21;
    }
    tmp$ret$23 = tmp0_apply_2;
    tmp$ret$24 = tmp$ret$23;
    var tooltip = new UITooltipDescription(this.mjm_1.ogo_1);
    var tmp$ret$28;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp3_onOver = UIGuiContainer$addTowerView$slambda_2(this, tooltip, tmp0_apply_2, null);
    var tmp$ret$27;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent = over$factory_0();
    var tmp0_safe_receiver_0 = tmp0_apply_2;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$26;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$25;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_18 = tmp2_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$25 = tmp_18.f1q(UIGuiContainer$addTowerView$lambda_0(tmp1_safe_receiver_0, tmp3_onOver));
      tmp$ret$26 = tmp$ret$25;
    }
    tmp$ret$27 = tmp0_apply_2;
    tmp$ret$28 = tmp$ret$27;
    var tmp$ret$32;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp5_onOut = UIGuiContainer$addTowerView$slambda_4(tooltip, null);
    var tmp$ret$31;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp4_doMouseEvent = out$factory();
    var tmp0_safe_receiver_1 = tmp0_apply_2;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$30;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$29;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_19 = tmp4_doMouseEvent.get(tmp1_safe_receiver_1);
      tmp$ret$29 = tmp_19.f1q(UIGuiContainer$addTowerView$lambda_1(tmp1_safe_receiver_1, tmp5_onOut));
      tmp$ret$30 = tmp$ret$29;
    }
    tmp$ret$31 = tmp0_apply_2;
    tmp$ret$32 = tmp$ret$31;
    tmp$ret$33 = tmp0_apply_2;
    tmp_16.ejn_1 = tmp$ret$33;
    var tmp_20 = this;
    var tmp$ret$46;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_3 = new UITextRect('Add\nSupply\nDepot', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.addSupplyDepotView.<anonymous>' call
    var tmp$ret$37;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_0 = UIGuiContainer$addSupplyDepotView$slambda_0(this, null);
    var tmp$ret$36;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_0 = click$factory_12();
    var tmp0_safe_receiver_2 = tmp0_apply_3;
    var tmp1_safe_receiver_2 = tmp0_safe_receiver_2 == null ? null : get_mouse(tmp0_safe_receiver_2);
    if (tmp1_safe_receiver_2 == null)
      null;
    else {
      var tmp$ret$35;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$34;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_21 = tmp0_doMouseEvent_0.get(tmp1_safe_receiver_2);
      tmp$ret$34 = tmp_21.f1q(UIGuiContainer$addSupplyDepotView$lambda(tmp1_safe_receiver_2, tmp1_onClick_0));
      tmp$ret$35 = tmp$ret$34;
    }
    tmp$ret$36 = tmp0_apply_3;
    tmp$ret$37 = tmp$ret$36;
    var tooltip_0 = new UITooltipDescription(this.mjm_1.rgo_1, 0, 'SUPPLY DEPOT', 'Adds ' + this.mjm_1.ugo_1 + ' supply.');
    var tmp$ret$41;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp3_onOver_0 = UIGuiContainer$addSupplyDepotView$slambda_2(this, tooltip_0, tmp0_apply_3, null);
    var tmp$ret$40;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent_0 = over$factory_1();
    var tmp0_safe_receiver_3 = tmp0_apply_3;
    var tmp1_safe_receiver_3 = tmp0_safe_receiver_3 == null ? null : get_mouse(tmp0_safe_receiver_3);
    if (tmp1_safe_receiver_3 == null)
      null;
    else {
      var tmp$ret$39;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$38;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_22 = tmp2_doMouseEvent_0.get(tmp1_safe_receiver_3);
      tmp$ret$38 = tmp_22.f1q(UIGuiContainer$addSupplyDepotView$lambda_0(tmp1_safe_receiver_3, tmp3_onOver_0));
      tmp$ret$39 = tmp$ret$38;
    }
    tmp$ret$40 = tmp0_apply_3;
    tmp$ret$41 = tmp$ret$40;
    var tmp$ret$45;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp5_onOut_0 = UIGuiContainer$addSupplyDepotView$slambda_4(tooltip_0, null);
    var tmp$ret$44;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp4_doMouseEvent_0 = out$factory_0();
    var tmp0_safe_receiver_4 = tmp0_apply_3;
    var tmp1_safe_receiver_4 = tmp0_safe_receiver_4 == null ? null : get_mouse(tmp0_safe_receiver_4);
    if (tmp1_safe_receiver_4 == null)
      null;
    else {
      var tmp$ret$43;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$42;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_23 = tmp4_doMouseEvent_0.get(tmp1_safe_receiver_4);
      tmp$ret$42 = tmp_23.f1q(UIGuiContainer$addSupplyDepotView$lambda_1(tmp1_safe_receiver_4, tmp5_onOut_0));
      tmp$ret$43 = tmp$ret$42;
    }
    tmp$ret$44 = tmp0_apply_3;
    tmp$ret$45 = tmp$ret$44;
    tmp$ret$46 = tmp0_apply_3;
    tmp_20.fjn_1 = tmp$ret$46;
    var tmp$ret$55;
    // Inline function 'korlibs.korge.view.container' call
    var tmp2_container_2 = this.qjm_1;
    var tmp$ret$54;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_2 = new Container(false);
    var tmp$ret$53;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_4 = addTo(tmp1_addTo_2, tmp2_container_2);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    var tmp$ret$52;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$51;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_5 = addTo(new UIButton(tmp1_uiButton, 'Settings', null), tmp0_apply_4);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    var tmp$ret$50;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_1 = UIGuiContainer$slambda_0(null);
    var tmp$ret$49;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_1 = click$factory_13();
    var tmp0_safe_receiver_5 = tmp0_apply_5;
    var tmp1_safe_receiver_5 = tmp0_safe_receiver_5 == null ? null : get_mouse(tmp0_safe_receiver_5);
    if (tmp1_safe_receiver_5 == null)
      null;
    else {
      var tmp$ret$48;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$47;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_24 = tmp0_doMouseEvent_1.get(tmp1_safe_receiver_5);
      tmp$ret$47 = tmp_24.f1q(UIGuiContainer$lambda(tmp1_safe_receiver_5, tmp1_onClick_1));
      tmp$ret$48 = tmp$ret$47;
    }
    tmp$ret$49 = tmp0_apply_5;
    tmp$ret$50 = tmp$ret$49;
    tmp$ret$51 = tmp0_apply_5;
    tmp$ret$52 = tmp$ret$51;
    tmp0_apply_4.pbl(toScale(1.5));
    tmp$ret$53 = tmp0_apply_4;
    tmp$ret$54 = tmp$ret$53;
    tmp$ret$55 = tmp$ret$54;
    var topLeftPanel = tmp$ret$55;
    var tmp$ret$57;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp3_getSingleton = this.gjm_1.hgo_1;
    var tmp$ret$56;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_25 = tmp3_getSingleton.wg2_1.h3(getKClass(UIDebugInfo));
    tmp$ret$56 = (tmp_25 == null ? true : tmp_25 instanceof UIDebugInfo) ? tmp_25 : THROW_CCE();
    var tmp0_elvis_lhs_3 = tmp$ret$56;
    var tmp_26;
    if (tmp0_elvis_lhs_3 == null) {
      throw new SingletonInjectionDoesNotExistException(UIGuiContainer$lambda_0);
    } else {
      tmp_26 = tmp0_elvis_lhs_3;
    }
    tmp$ret$57 = tmp_26;
    var uiDebugInfo = tmp$ret$57;
    var tmp$ret$59;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo = this.qjm_1;
    var tmp$ret$58;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(uiDebugInfo, tmp5_addTo);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    alignTopToBottomOf(tmp4_apply, topLeftPanel);
    tmp$ret$58 = tmp4_apply;
    tmp$ret$59 = tmp$ret$58;
    var tmp$ret$87;
    // Inline function 'korlibs.korge.view.container' call
    var tmp8_container = this.qjm_1;
    var tmp$ret$86;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp7_addTo = new Container(false);
    var tmp$ret$85;
    // Inline function 'kotlin.apply' call
    var tmp6_apply = addTo(tmp7_addTo, tmp8_container);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    var tmp$ret$65;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton_0 = Companion_getInstance_19().dc4_1;
    var tmp$ret$64;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_6 = addTo(new UIButton(tmp1_uiButton_0, 'Spawn creep', null), tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    var tmp$ret$63;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_2 = UIGuiContainer$slambda_2(this, null);
    var tmp$ret$62;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_2 = click$factory_14();
    var tmp0_safe_receiver_6 = tmp0_apply_6;
    var tmp1_safe_receiver_6 = tmp0_safe_receiver_6 == null ? null : get_mouse(tmp0_safe_receiver_6);
    if (tmp1_safe_receiver_6 == null)
      null;
    else {
      var tmp$ret$61;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$60;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_27 = tmp0_doMouseEvent_2.get(tmp1_safe_receiver_6);
      tmp$ret$60 = tmp_27.f1q(UIGuiContainer$lambda_1(tmp1_safe_receiver_6, tmp1_onClick_2));
      tmp$ret$61 = tmp$ret$60;
    }
    tmp$ret$62 = tmp0_apply_6;
    tmp$ret$63 = tmp$ret$62;
    tmp$ret$64 = tmp0_apply_6;
    tmp$ret$65 = tmp$ret$64;
    var spawnCreepButton = tmp$ret$65;
    var tmp$ret$71;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp3_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$70;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(new UIButton(tmp3_uiButton, 'Print world', null), tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    var tmp$ret$69;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_3 = UIGuiContainer$slambda_4(this, null);
    var tmp$ret$68;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_3 = click$factory_15();
    var tmp0_safe_receiver_7 = tmp2_apply;
    var tmp1_safe_receiver_7 = tmp0_safe_receiver_7 == null ? null : get_mouse(tmp0_safe_receiver_7);
    if (tmp1_safe_receiver_7 == null)
      null;
    else {
      var tmp$ret$67;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$66;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_28 = tmp0_doMouseEvent_3.get(tmp1_safe_receiver_7);
      tmp$ret$66 = tmp_28.f1q(UIGuiContainer$lambda_2(tmp1_safe_receiver_7, tmp1_onClick_3));
      tmp$ret$67 = tmp$ret$66;
    }
    tmp$ret$68 = tmp2_apply;
    tmp$ret$69 = tmp$ret$68;
    tmp$ret$70 = tmp2_apply;
    tmp$ret$71 = tmp$ret$70;
    var printWorldButton = tmp$ret$71;
    var tmp$ret$77;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp5_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$76;
    // Inline function 'kotlin.apply' call
    var tmp4_apply_0 = addTo(new UIButton(tmp5_uiButton, 'Print event log', null), tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    var tmp$ret$75;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_4 = UIGuiContainer$slambda_6(this, null);
    var tmp$ret$74;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_4 = click$factory_16();
    var tmp0_safe_receiver_8 = tmp4_apply_0;
    var tmp1_safe_receiver_8 = tmp0_safe_receiver_8 == null ? null : get_mouse(tmp0_safe_receiver_8);
    if (tmp1_safe_receiver_8 == null)
      null;
    else {
      var tmp$ret$73;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$72;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_29 = tmp0_doMouseEvent_4.get(tmp1_safe_receiver_8);
      tmp$ret$72 = tmp_29.f1q(UIGuiContainer$lambda_3(tmp1_safe_receiver_8, tmp1_onClick_4));
      tmp$ret$73 = tmp$ret$72;
    }
    tmp$ret$74 = tmp4_apply_0;
    tmp$ret$75 = tmp$ret$74;
    tmp$ret$76 = tmp4_apply_0;
    tmp$ret$77 = tmp$ret$76;
    var printEventLog = tmp$ret$77;
    var tmp$ret$84;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp7_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$83;
    // Inline function 'kotlin.apply' call
    var tmp6_apply_0 = addTo(new UIButton(tmp7_uiButton, 'Delete Entities', null), tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    tmp6_apply_0.ycg();
    var tmp$ret$81;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_5 = UIGuiContainer$slambda_8(this, tmp6_apply_0, null);
    var tmp$ret$80;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_5 = click$factory_17();
    var tmp0_safe_receiver_9 = tmp6_apply_0;
    var tmp1_safe_receiver_9 = tmp0_safe_receiver_9 == null ? null : get_mouse(tmp0_safe_receiver_9);
    if (tmp1_safe_receiver_9 == null)
      null;
    else {
      var tmp$ret$79;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$78;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_30 = tmp0_doMouseEvent_5.get(tmp1_safe_receiver_9);
      tmp$ret$78 = tmp_30.f1q(UIGuiContainer$lambda_4(tmp1_safe_receiver_9, tmp1_onClick_5));
      tmp$ret$79 = tmp$ret$78;
    }
    tmp$ret$80 = tmp6_apply_0;
    tmp$ret$81 = tmp$ret$80;
    var tmp$ret$82;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.kjm_1;
    var tmp3_register = UIGuiContainer$slambda_10(this, tmp6_apply_0, null);
    tmp$ret$82 = tmp2_register.cg7(getKClass(EntitySelectionChangedEvent), tmp3_register);
    tmp$ret$83 = tmp6_apply_0;
    tmp$ret$84 = tmp$ret$83;
    var deleteEntitiesButton = tmp$ret$84;
    distributeVertically(listOf_0([spawnCreepButton, printWorldButton, printEventLog, deleteEntitiesButton]));
    tmp6_apply.pbl(toScale(2.0));
    tmp$ret$85 = tmp6_apply;
    tmp$ret$86 = tmp$ret$85;
    tmp$ret$87 = tmp$ret$86;
    var buttonsPanel = tmp$ret$87;
    alignBottomToBottomOfWindow(buttonsPanel);
    var bottomRightGridHeight = this.tjm_1 / 2;
    var bottomRightGridHorizontalPadding = 5.0;
    var tmp_31 = this;
    var tmp$ret$89;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp10_addTo = new UIFixedGrid(4, 2, this.tjm_1, bottomRightGridHeight, bottomRightGridHorizontalPadding, 5.0, VOID, VOID, MaterialColors_getInstance().t5b_1);
    var tmp11_addTo = this.qjm_1;
    var tmp$ret$88;
    // Inline function 'kotlin.apply' call
    var tmp9_apply = addTo(tmp10_addTo, tmp11_addTo);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    tmp9_apply.y9r_1 = 'Bottom right grid';
    alignBottomToBottomOfWindow(tmp9_apply);
    alignRightToRightOfWindow(tmp9_apply);
    tmp$ret$88 = tmp9_apply;
    tmp$ret$89 = tmp$ret$88;
    tmp_31.yjm_1 = tmp$ret$89;
    var tmp$ret$91;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp13_addTo = new UITopRightResourcesGui(this.gjm_1);
    var tmp14_addTo = this.qjm_1;
    var tmp$ret$90;
    // Inline function 'kotlin.apply' call
    var tmp12_apply = addTo(tmp13_addTo, tmp14_addTo);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    scaleWhileMaintainingAspect(tmp12_apply, new ByHeight(25.0));
    var tmp0_this = tmp12_apply;
    tmp0_this.dbl(tmp0_this.f6g() + 5.0);
    alignRightToRightOfWindow(tmp12_apply, 10.0);
    tmp$ret$90 = tmp12_apply;
    tmp$ret$91 = tmp$ret$90;
    var topRightResources = tmp$ret$91;
    var multiTowerSelectGridWidth = 400.0;
    var multiTowerSelectGridHeight = multiTowerSelectGridWidth / 3;
    var multiTowerSelectGrid = new UIFixedGrid(10, 3, multiTowerSelectGridWidth, multiTowerSelectGridHeight, 3.0, 3.0);
    var tmp$ret$104;
    // Inline function 'kotlin.apply' call
    var tmp15_apply = new UITextRect('Sell\nEntities', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    var tmp$ret$95;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_6 = UIGuiContainer$slambda_12(this, null);
    var tmp$ret$94;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_6 = click$factory_18();
    var tmp0_safe_receiver_10 = tmp15_apply;
    var tmp1_safe_receiver_10 = tmp0_safe_receiver_10 == null ? null : get_mouse(tmp0_safe_receiver_10);
    if (tmp1_safe_receiver_10 == null)
      null;
    else {
      var tmp$ret$93;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$92;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_32 = tmp0_doMouseEvent_6.get(tmp1_safe_receiver_10);
      tmp$ret$92 = tmp_32.f1q(UIGuiContainer$lambda_5(tmp1_safe_receiver_10, tmp1_onClick_6));
      tmp$ret$93 = tmp$ret$92;
    }
    tmp$ret$94 = tmp15_apply;
    tmp$ret$95 = tmp$ret$94;
    var tooltip_1 = new UITooltipDescription(null, null, 'SELL', 'Sell tower(s), refunds 100% of the\nbasic tower cost, but not upgrades.');
    var tmp$ret$99;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp3_onOver_1 = UIGuiContainer$slambda_14(this, tooltip_1, tmp15_apply, null);
    var tmp$ret$98;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent_1 = over$factory_2();
    var tmp0_safe_receiver_11 = tmp15_apply;
    var tmp1_safe_receiver_11 = tmp0_safe_receiver_11 == null ? null : get_mouse(tmp0_safe_receiver_11);
    if (tmp1_safe_receiver_11 == null)
      null;
    else {
      var tmp$ret$97;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$96;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_33 = tmp2_doMouseEvent_1.get(tmp1_safe_receiver_11);
      tmp$ret$96 = tmp_33.f1q(UIGuiContainer$lambda_6(tmp1_safe_receiver_11, tmp3_onOver_1));
      tmp$ret$97 = tmp$ret$96;
    }
    tmp$ret$98 = tmp15_apply;
    tmp$ret$99 = tmp$ret$98;
    onAttachDetach(tmp15_apply, VOID, UIGuiContainer$lambda_7(tooltip_1));
    var tmp$ret$103;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp5_onOut_1 = UIGuiContainer$slambda_16(tooltip_1, null);
    var tmp$ret$102;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp4_doMouseEvent_1 = out$factory_1();
    var tmp0_safe_receiver_12 = tmp15_apply;
    var tmp1_safe_receiver_12 = tmp0_safe_receiver_12 == null ? null : get_mouse(tmp0_safe_receiver_12);
    if (tmp1_safe_receiver_12 == null)
      null;
    else {
      var tmp$ret$101;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$100;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_34 = tmp4_doMouseEvent_1.get(tmp1_safe_receiver_12);
      tmp$ret$100 = tmp_34.f1q(UIGuiContainer$lambda_8(tmp1_safe_receiver_12, tmp5_onOut_1));
      tmp$ret$101 = tmp$ret$100;
    }
    tmp$ret$102 = tmp15_apply;
    tmp$ret$103 = tmp$ret$102;
    tmp$ret$104 = tmp15_apply;
    var sellEntitiesView = tmp$ret$104;
    this.gjm_1.ggo_1.xg2(this.xjm_1);
    var tmp$ret$117;
    // Inline function 'kotlin.apply' call
    var tmp16_apply = new UITextRect('Show\nRange', this.vjm_1, this.vjm_1, 5.0, GlobalResources_getInstance().ugv());
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    var tmp$ret$108;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_7 = UIGuiContainer$slambda_18(this, null);
    var tmp$ret$107;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_7 = click$factory_19();
    var tmp0_safe_receiver_13 = tmp16_apply;
    var tmp1_safe_receiver_13 = tmp0_safe_receiver_13 == null ? null : get_mouse(tmp0_safe_receiver_13);
    if (tmp1_safe_receiver_13 == null)
      null;
    else {
      var tmp$ret$106;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$105;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_35 = tmp0_doMouseEvent_7.get(tmp1_safe_receiver_13);
      tmp$ret$105 = tmp_35.f1q(UIGuiContainer$lambda_9(tmp1_safe_receiver_13, tmp1_onClick_7));
      tmp$ret$106 = tmp$ret$105;
    }
    tmp$ret$107 = tmp16_apply;
    tmp$ret$108 = tmp$ret$107;
    var tooltip_2 = new UITooltipDescription(null, null, 'SHOW RANGE', 'Show the range of the selected tower(s).');
    var tmp$ret$112;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp3_onOver_2 = UIGuiContainer$slambda_20(this, tooltip_2, tmp16_apply, null);
    var tmp$ret$111;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent_2 = over$factory_3();
    var tmp0_safe_receiver_14 = tmp16_apply;
    var tmp1_safe_receiver_14 = tmp0_safe_receiver_14 == null ? null : get_mouse(tmp0_safe_receiver_14);
    if (tmp1_safe_receiver_14 == null)
      null;
    else {
      var tmp$ret$110;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$109;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_36 = tmp2_doMouseEvent_2.get(tmp1_safe_receiver_14);
      tmp$ret$109 = tmp_36.f1q(UIGuiContainer$lambda_10(tmp1_safe_receiver_14, tmp3_onOver_2));
      tmp$ret$110 = tmp$ret$109;
    }
    tmp$ret$111 = tmp16_apply;
    tmp$ret$112 = tmp$ret$111;
    onAttachDetach(tmp16_apply, VOID, UIGuiContainer$lambda_11(tooltip_2));
    var tmp$ret$116;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp5_onOut_2 = UIGuiContainer$slambda_22(tooltip_2, null);
    var tmp$ret$115;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp4_doMouseEvent_2 = out$factory_2();
    var tmp0_safe_receiver_15 = tmp16_apply;
    var tmp1_safe_receiver_15 = tmp0_safe_receiver_15 == null ? null : get_mouse(tmp0_safe_receiver_15);
    if (tmp1_safe_receiver_15 == null)
      null;
    else {
      var tmp$ret$114;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$113;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_37 = tmp4_doMouseEvent_2.get(tmp1_safe_receiver_15);
      tmp$ret$113 = tmp_37.f1q(UIGuiContainer$lambda_12(tmp1_safe_receiver_15, tmp5_onOut_2));
      tmp$ret$114 = tmp$ret$113;
    }
    tmp$ret$115 = tmp16_apply;
    tmp$ret$116 = tmp$ret$115;
    tmp$ret$117 = tmp16_apply;
    var showRangeView = tmp$ret$117;
    var tooltipUpgradeDamage = new UITooltipDescription(this.mjm_1.pgo_1, null, 'Upgrade Damage', 'Upgrade tower damage.');
    var tooltipUpgradeSpeed = new UITooltipDescription(this.mjm_1.qgo_1, null, 'Upgrade Speed', 'Upgrade tower speed.');
    var tmp$ret$133;
    // Inline function 'kotlin.apply' call
    var tmp17_apply = new Container();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    var tmp$ret$120;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = GlobalResources_getInstance().qgv();
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$119;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_3 = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$118;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_7 = addTo(tmp1_addTo_3, tmp17_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>.<anonymous>' call
    tmp0_apply_7.wdd(this.vjm_1);
    tmp0_apply_7.odz(this.vjm_1);
    tmp$ret$118 = tmp0_apply_7;
    tmp$ret$119 = tmp$ret$118;
    tmp$ret$120 = tmp$ret$119;
    var tmp$ret$124;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp5_onClick = UIGuiContainer$slambda_24(this, null);
    var tmp$ret$123;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp4_doMouseEvent_3 = click$factory_20();
    var tmp0_safe_receiver_16 = tmp17_apply;
    var tmp1_safe_receiver_16 = tmp0_safe_receiver_16 == null ? null : get_mouse(tmp0_safe_receiver_16);
    if (tmp1_safe_receiver_16 == null)
      null;
    else {
      var tmp$ret$122;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$121;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_38 = tmp4_doMouseEvent_3.get(tmp1_safe_receiver_16);
      tmp$ret$121 = tmp_38.f1q(UIGuiContainer$lambda_13(tmp1_safe_receiver_16, tmp5_onClick));
      tmp$ret$122 = tmp$ret$121;
    }
    tmp$ret$123 = tmp17_apply;
    tmp$ret$124 = tmp$ret$123;
    var tooltip_3 = new UITooltipDescription(null, null, 'Upgrade Tower', 'Choose from a selection\nof tower upgrades.');
    var tmp$ret$128;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp7_onOver = UIGuiContainer$slambda_26(this, tooltip_3, tmp17_apply, null);
    var tmp$ret$127;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp6_doMouseEvent = over$factory_4();
    var tmp0_safe_receiver_17 = tmp17_apply;
    var tmp1_safe_receiver_17 = tmp0_safe_receiver_17 == null ? null : get_mouse(tmp0_safe_receiver_17);
    if (tmp1_safe_receiver_17 == null)
      null;
    else {
      var tmp$ret$126;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$125;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_39 = tmp6_doMouseEvent.get(tmp1_safe_receiver_17);
      tmp$ret$125 = tmp_39.f1q(UIGuiContainer$lambda_14(tmp1_safe_receiver_17, tmp7_onOver));
      tmp$ret$126 = tmp$ret$125;
    }
    tmp$ret$127 = tmp17_apply;
    tmp$ret$128 = tmp$ret$127;
    var tmp$ret$132;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp9_onOut = UIGuiContainer$slambda_28(tooltip_3, null);
    var tmp$ret$131;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp8_doMouseEvent = out$factory_3();
    var tmp0_safe_receiver_18 = tmp17_apply;
    var tmp1_safe_receiver_18 = tmp0_safe_receiver_18 == null ? null : get_mouse(tmp0_safe_receiver_18);
    if (tmp1_safe_receiver_18 == null)
      null;
    else {
      var tmp$ret$130;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$129;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_40 = tmp8_doMouseEvent.get(tmp1_safe_receiver_18);
      tmp$ret$129 = tmp_40.f1q(UIGuiContainer$lambda_15(tmp1_safe_receiver_18, tmp9_onOut));
      tmp$ret$130 = tmp$ret$129;
    }
    tmp$ret$131 = tmp17_apply;
    tmp$ret$132 = tmp$ret$131;
    onAttachDetach(tmp17_apply, VOID, UIGuiContainer$lambda_16(tooltip_3));
    tmp$ret$133 = tmp17_apply;
    var towerUpgradeView = tmp$ret$133;
    var tmp_41 = GlobalResources_getInstance().ogv();
    var tmp_42 = UIGuiContainer$lambda_17(this);
    var towerDamageUpgradeView = new UITowerUpgradeIcon(this.gjm_1, tooltipUpgradeDamage, this.ujm_1, this, tmp_41, this.mjm_1.pgo_1, tmp_42, UIGuiContainer$lambda_18(this));
    var tmp_43 = GlobalResources_getInstance().pgv();
    var tmp_44 = UIGuiContainer$lambda_19(this);
    var towerSpeedUpgradeView = new UITowerUpgradeIcon(this.gjm_1, tooltipUpgradeSpeed, this.ujm_1, this, tmp_43, this.mjm_1.qgo_1, tmp_44, UIGuiContainer$lambda_20(this));
    var textHeightSize = bottomRightGridHeight / 7;
    var tmp_45 = this;
    var tmp$ret$134;
    // Inline function 'kotlin.apply' call
    var tmp0_font = GlobalResources_getInstance().ugv();
    var tmp18_apply = new Text('HOLD SHIFT (+5)', 12.0, VOID, tmp0_font);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIGuiContainer.<anonymous>' call
    scaleWhileMaintainingAspect(tmp18_apply, new ByHeight(textHeightSize));
    tmp$ret$134 = tmp18_apply;
    tmp_45.zjm_1 = tmp$ret$134;
    this.njm_1.yhm_1.b(this.yjm_1);
    this.wk9();
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp19_addComponentListener = this.ijm_1.vgb_1.bg0_1;
    var tmp20_addComponentListener = new UIGuiContainer$4(this, towerDamageUpgradeView);
    var tmp_46 = tmp19_addComponentListener.lg1(getKClass(DamageUpgradeComponent));
    var container = tmp_46 instanceof ComponentEntityContainer ? tmp_46 : THROW_CCE();
    container.yg0(tmp20_addComponentListener);
    // Inline function 'com.xenotactic.ecs.ComponentService.addComponentListener' call
    var tmp21_addComponentListener = this.ijm_1.vgb_1.bg0_1;
    var tmp22_addComponentListener = new UIGuiContainer$5(this, towerSpeedUpgradeView);
    var tmp_47 = tmp21_addComponentListener.lg1(getKClass(SpeedUpgradeComponent));
    var container_0 = tmp_47 instanceof ComponentEntityContainer ? tmp_47 : THROW_CCE();
    container_0.yg0(tmp22_addComponentListener);
    var tmp$ret$135;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp23_register = this.kjm_1;
    var tmp24_register = UIGuiContainer$slambda_30(this, bottomRightGridHeight, tooltipUpgradeDamage, tooltipUpgradeSpeed, showRangeView, towerDamageUpgradeView, towerSpeedUpgradeView, towerUpgradeView, sellEntitiesView, bottomRightGridHorizontalPadding, multiTowerSelectGrid, null);
    tmp$ret$135 = tmp23_register.cg7(getKClass(EntitySelectionChangedEvent), tmp24_register);
    var tmp$ret$136;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp25_register = this.kjm_1;
    var tmp26_register = UIGuiContainer$slambda_32(this, null);
    tmp$ret$136 = tmp25_register.cg7(getKClass(RemovedTowerEntityEvent), tmp26_register);
  }
  protoOf(UIGuiContainer).wk9 = function () {
    this.zjm_1.mbn();
    this.yjm_1.e4();
    this.yjm_1.wjg(0, 0, this.ajn_1);
    this.yjm_1.wjg(1, 0, this.bjn_1);
    this.yjm_1.wjg(2, 0, this.cjn_1);
    this.yjm_1.wjg(0, 1, this.ejn_1);
    this.yjm_1.wjg(1, 1, this.fjn_1);
  };
  protoOf(UIGuiContainer).yk6 = function () {
    var tmp0_subject = this.wjm_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        ;
        break;
      case 1:
        this.xjm_1.vgo_1 = null;
        this.rjm_1.ibk();
        this.zjm_1.mbn();
        this.yjm_1.xjg(0, 1);
        this.yjm_1.xjg(1, 1);
        ;
        break;
      case 3:
        this.rjm_1.ibk();
        ;
        break;
      case 4:
        break;
      case 2:
        ;
        break;
    }
    this.wk9();
  };
  protoOf(UIGuiContainer).bk1 = function () {
    this.yk6();
    this.wjm_1 = ViewType_SINGLE_TOWER_SELECTION_UPGRADE_getInstance();
    this.yjm_1.e4();
    this.yjm_1.wjg(0, 0, this.djn_1);
  };
  function ViewType_NONE_getInstance() {
    ViewType_initEntries();
    return ViewType_NONE_instance;
  }
  function ViewType_SINGLE_TOWER_SELECTION_getInstance() {
    ViewType_initEntries();
    return ViewType_SINGLE_TOWER_SELECTION_instance;
  }
  function ViewType_SINGLE_TOWER_SELECTION_UPGRADE_getInstance() {
    ViewType_initEntries();
    return ViewType_SINGLE_TOWER_SELECTION_UPGRADE_instance;
  }
  function ViewType_MULTI_TOWER_SELECTION_getInstance() {
    ViewType_initEntries();
    return ViewType_MULTI_TOWER_SELECTION_instance;
  }
  function ViewType_SINGLE_DEPOT_SELECTION_getInstance() {
    ViewType_initEntries();
    return ViewType_SINGLE_DEPOT_SELECTION_instance;
  }
  function click$factory_11() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_0() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function click$factory_12() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_1() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_0() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function click$factory_13() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_14() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_15() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_16() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_17() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_18() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_2() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_1() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function click$factory_19() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_3() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_2() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function click$factory_20() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_4() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_3() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  var UIHeaderSection_PLAY_instance;
  var UIHeaderSection_EDITOR_instance;
  var UIHeaderSection_MY_MAPS_instance;
  var UIHeaderSection_entriesInitialized;
  function UIHeaderSection_initEntries() {
    if (UIHeaderSection_entriesInitialized)
      return Unit_getInstance();
    UIHeaderSection_entriesInitialized = true;
    UIHeaderSection_PLAY_instance = new UIHeaderSection('PLAY', 0);
    UIHeaderSection_EDITOR_instance = new UIHeaderSection('EDITOR', 1);
    UIHeaderSection_MY_MAPS_instance = new UIHeaderSection('MY_MAPS', 2);
  }
  function UIHeaderSection(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function UIHeader$slambda(this$0, resultContinuation) {
    this.fka_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIHeader$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIHeader$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIHeader$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.fka_1.yir_1.h1q(UIHeaderSection_PLAY_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIHeader$slambda).yak = function (it, completion) {
    var i = new UIHeader$slambda(this.fka_1, completion);
    i.gka_1 = it;
    return i;
  };
  function UIHeader$slambda_0(this$0, resultContinuation) {
    var i = new UIHeader$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIHeader$lambda$slambda($tmp3_onClick, $it, resultContinuation) {
    this.pka_1 = $tmp3_onClick;
    this.qka_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIHeader$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIHeader$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIHeader$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.pka_1(this.qka_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIHeader$lambda$slambda).g1p = function (completion) {
    var i = new UIHeader$lambda$slambda(this.pka_1, this.qka_1, completion);
    return i;
  };
  function UIHeader$lambda$slambda_0($tmp3_onClick, $it, resultContinuation) {
    var i = new UIHeader$lambda$slambda($tmp3_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIHeader$lambda($tmp1_safe_receiver, $tmp3_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIHeader$lambda$slambda_0($tmp3_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIHeader$slambda_1(this$0, resultContinuation) {
    this.zka_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIHeader$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIHeader$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIHeader$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.zka_1.yir_1.h1q(UIHeaderSection_MY_MAPS_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIHeader$slambda_1).yak = function (it, completion) {
    var i = new UIHeader$slambda_1(this.zka_1, completion);
    i.akb_1 = it;
    return i;
  };
  function UIHeader$slambda_2(this$0, resultContinuation) {
    var i = new UIHeader$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIHeader$lambda$slambda_1($tmp3_onClick, $it, resultContinuation) {
    this.jkb_1 = $tmp3_onClick;
    this.kkb_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIHeader$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIHeader$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIHeader$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.jkb_1(this.kkb_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIHeader$lambda$slambda_1).g1p = function (completion) {
    var i = new UIHeader$lambda$slambda_1(this.jkb_1, this.kkb_1, completion);
    return i;
  };
  function UIHeader$lambda$slambda_2($tmp3_onClick, $it, resultContinuation) {
    var i = new UIHeader$lambda$slambda_1($tmp3_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIHeader$lambda_0($tmp1_safe_receiver, $tmp3_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIHeader$lambda$slambda_2($tmp3_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIHeader$lambda_1($textSpacing) {
    return function (it) {
      return alignLeftToRightOf(it.n(1), it.n(0), $textSpacing);
    };
  }
  function UIHeader(userName, headerHeight, headerWidth) {
    headerHeight = headerHeight === VOID ? 50.0 : headerHeight;
    Container.call(this);
    this.xir_1 = userName;
    this.yir_1 = new Signal();
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.ais_1 = tmp$ret$0;
    var HEADER_TITLE_PADDING_LEFT = 10.0;
    var PROFILE_PADDING_TOP_AND_BOTTOM = 10.0;
    var PROFILE_PADDING_RIGHT = 5.0;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = Colors_getInstance().a4t_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$_0(headerWidth, headerHeight), tmp2_solidRect);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$1 = Unit_getInstance();
    tmp$ret$2 = tmp0_apply;
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    var headerSection = tmp$ret$4;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.text' call
    var tmp5_text = this.xir_1;
    var tmp6_text = Colors_getInstance().k4s_1;
    var tmp7_text = get_DefaultTtfFontAsBitmap();
    var tmp8_text = Companion_getInstance_5().g5r_1;
    var tmp9_text = get_DefaultStringTextRenderer();
    var tmp10_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new Text(tmp5_text, 30.0, tmp6_text, tmp7_text, tmp8_text, tmp9_text, tmp10_text, null, null);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIHeader.<anonymous>' call
    centerYOn(tmp3_apply, headerSection);
    var tmp0_this = tmp3_apply;
    tmp0_this.cbl(tmp0_this.e6g() + HEADER_TITLE_PADDING_LEFT);
    tmp$ret$5 = tmp3_apply;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    var headerTextSize = 20.0;
    var textSpacing = 50.0;
    var textWidth = 75.0;
    var tmp$ret$28;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp12_addTo = new Container(false);
    var tmp$ret$26;
    // Inline function 'kotlin.apply' call
    var tmp11_apply = addTo(tmp12_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIHeader.<anonymous>' call
    var tmp$ret$14;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Colors_getInstance().k4s_1;
    var tmp3_text = get_DefaultTtfFontAsBitmap();
    var tmp4_text = Companion_getInstance_5().g5r_1;
    var tmp5_text_0 = get_DefaultStringTextRenderer();
    var tmp6_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$13;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text('Play', headerTextSize, tmp2_text, tmp3_text, tmp4_text, tmp5_text_0, tmp6_text_0, null, null);
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, tmp11_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIHeader.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var tmp0_set = this.ais_1;
    var tmp1_set = UIHeaderSection_PLAY_getInstance();
    tmp0_set.e5(tmp1_set, tmp0_apply_0);
    var tmp$ret$11;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp3_onClick = UIHeader$slambda_0(this, null);
    var tmp$ret$10;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent = click$factory_21();
    var tmp0_safe_receiver = tmp0_apply_0;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_0 = tmp2_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$8 = tmp_0.f1q(UIHeader$lambda(tmp1_safe_receiver, tmp3_onClick));
      tmp$ret$9 = tmp$ret$8;
    }
    tmp$ret$10 = tmp0_apply_0;
    tmp$ret$11 = tmp$ret$10;
    tmp$ret$12 = tmp0_apply_0;
    tmp$ret$13 = tmp$ret$12;
    tmp$ret$14 = tmp$ret$13;
    var playText = tmp$ret$14;
    var tmp$ret$21;
    // Inline function 'korlibs.korge.view.text' call
    var tmp9_text_0 = Colors_getInstance().k4s_1;
    var tmp10_text_0 = get_DefaultTtfFontAsBitmap();
    var tmp11_text = Companion_getInstance_5().g5r_1;
    var tmp12_text = get_DefaultStringTextRenderer();
    var tmp13_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$20;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp8_addTo = new Text('My Maps', headerTextSize, tmp9_text_0, tmp10_text_0, tmp11_text, tmp12_text, tmp13_text, null, null);
    var tmp$ret$19;
    // Inline function 'kotlin.apply' call
    var tmp7_apply = addTo(tmp8_addTo, tmp11_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIHeader.<anonymous>.<anonymous>' call
    // Inline function 'kotlin.collections.set' call
    var tmp0_set_0 = this.ais_1;
    var tmp1_set_0 = UIHeaderSection_MY_MAPS_getInstance();
    tmp0_set_0.e5(tmp1_set_0, tmp7_apply);
    var tmp$ret$18;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp3_onClick_0 = UIHeader$slambda_2(this, null);
    var tmp$ret$17;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent_0 = click$factory_22();
    var tmp0_safe_receiver_0 = tmp7_apply;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$16;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$15;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = tmp2_doMouseEvent_0.get(tmp1_safe_receiver_0);
      tmp$ret$15 = tmp_1.f1q(UIHeader$lambda_0(tmp1_safe_receiver_0, tmp3_onClick_0));
      tmp$ret$16 = tmp$ret$15;
    }
    tmp$ret$17 = tmp7_apply;
    tmp$ret$18 = tmp$ret$17;
    tmp$ret$19 = tmp7_apply;
    tmp$ret$20 = tmp$ret$19;
    tmp$ret$21 = tmp$ret$20;
    var myMapsText = tmp$ret$21;
    var textViews = mutableListOf([playText, myMapsText]);
    windowed(textViews, 2, VOID, VOID, UIHeader$lambda_1(textSpacing));
    var tmp_2 = this;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp16_solidRect = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, 0.5);
    var tmp$ret$24;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp15_addTo = new SolidRect(Size_init_$Create$(25, 25), tmp16_solidRect);
    var tmp$ret$23;
    // Inline function 'kotlin.apply' call
    var tmp14_apply = addTo(tmp15_addTo, tmp11_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$22;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$22 = Unit_getInstance();
    tmp$ret$23 = tmp14_apply;
    tmp$ret$24 = tmp$ret$23;
    tmp$ret$25 = tmp$ret$24;
    tmp_2.zir_1 = tmp$ret$25;
    tmp11_apply.bbk(this.lkb());
    this.bis(UIHeaderSection_PLAY_getInstance());
    println('uiHeader:');
    println(trimIndent('\n                    playText.scaledDimensions(): ' + scaledDimensions(playText) + '\n                    myMapsText.scaledDimensions(): ' + scaledDimensions(myMapsText) + '\n                '));
    tmp$ret$26 = tmp11_apply;
    tmp$ret$27 = tmp$ret$26;
    tmp$ret$28 = tmp$ret$27;
    var headerOptions = tmp$ret$28;
    centerYOn(headerOptions, headerSection);
    var tmp0_this_0 = headerOptions;
    tmp0_this_0.cbl(tmp0_this_0.e6g() + 200.0);
    var tmp$ret$32;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp15_solidRect = headerHeight - PROFILE_PADDING_TOP_AND_BOTTOM;
    var tmp16_solidRect_0 = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, 0.25);
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp14_addTo = new SolidRect(Size_init_$Create$_0(100.0, tmp15_solidRect), tmp16_solidRect_0);
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp13_apply = addTo(tmp14_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$29;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$29 = Unit_getInstance();
    tmp$ret$30 = tmp13_apply;
    tmp$ret$31 = tmp$ret$30;
    tmp$ret$32 = tmp$ret$31;
    var profileSection = tmp$ret$32;
    alignRightToRightOf_0(profileSection, headerSection, PROFILE_PADDING_RIGHT);
    centerYOn(profileSection, headerSection);
    var tmp$ret$36;
    // Inline function 'korlibs.korge.view.text' call
    var tmp19_text = this.xir_1;
    var tmp20_text = Colors_getInstance().k4s_1;
    var tmp21_text = get_DefaultTtfFontAsBitmap();
    var tmp22_text = Companion_getInstance_5().g5r_1;
    var tmp23_text = get_DefaultStringTextRenderer();
    var tmp24_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$35;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp18_addTo = new Text(tmp19_text, 20.0, tmp20_text, tmp21_text, tmp22_text, tmp23_text, tmp24_text, null, null);
    var tmp$ret$34;
    // Inline function 'kotlin.apply' call
    var tmp17_apply = addTo(tmp18_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$33 = Unit_getInstance();
    tmp$ret$34 = tmp17_apply;
    tmp$ret$35 = tmp$ret$34;
    tmp$ret$36 = tmp$ret$35;
    var profileText = tmp$ret$36;
    centerOn(profileText, profileSection);
  }
  protoOf(UIHeader).lkb = function () {
    var tmp = this.zir_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('selectedSectionBox');
    }
  };
  protoOf(UIHeader).bis = function (uiHeaderSection) {
    var uiText = ensureNotNull(this.ais_1.h3(uiHeaderSection));
    this.lkb().ebm(uiText.fbm() + 10);
    this.lkb().gbm(uiText.hbm() + 10);
    centerOn(this.lkb(), uiText);
  };
  function UIHeaderSection_PLAY_getInstance() {
    UIHeaderSection_initEntries();
    return UIHeaderSection_PLAY_instance;
  }
  function UIHeaderSection_MY_MAPS_getInstance() {
    UIHeaderSection_initEntries();
    return UIHeaderSection_MY_MAPS_instance;
  }
  function click$factory_21() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_22() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function get_ENTITY_TEXT_FONT() {
    _init_properties_UIMap_kt__tglk2e();
    return ENTITY_TEXT_FONT;
  }
  var ENTITY_TEXT_FONT;
  var BoardType_SOLID_instance;
  var BoardType_CHECKERED_1X1_instance;
  var BoardType_CHECKERED_2X2_instance;
  var BoardType_entriesInitialized;
  function BoardType_initEntries() {
    if (BoardType_entriesInitialized)
      return Unit_getInstance();
    BoardType_entriesInitialized = true;
    BoardType_SOLID_instance = new BoardType('SOLID', 0);
    BoardType_CHECKERED_1X1_instance = new BoardType('CHECKERED_1X1', 1);
    BoardType_CHECKERED_2X2_instance = new BoardType('CHECKERED_2X2', 2);
  }
  function BoardType(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function UIMapSettings(gridSize, borderRatio, gridLinesRatio, gridNumbersRatio, pathLinesRatio, drawGridNumbers, boardType) {
    gridSize = gridSize === VOID ? get_GRID_SIZE() : gridSize;
    borderRatio = borderRatio === VOID ? get_BORDER_RATIO() : borderRatio;
    gridLinesRatio = gridLinesRatio === VOID ? get_GRID_LINES_RATIO() : gridLinesRatio;
    gridNumbersRatio = gridNumbersRatio === VOID ? get_GRID_NUMBERS_RATIO() : gridNumbersRatio;
    pathLinesRatio = pathLinesRatio === VOID ? get_PATH_LINES_RATIO() : pathLinesRatio;
    drawGridNumbers = drawGridNumbers === VOID ? true : drawGridNumbers;
    boardType = boardType === VOID ? BoardType_CHECKERED_1X1_getInstance() : boardType;
    this.mkb_1 = gridSize;
    this.nkb_1 = borderRatio;
    this.okb_1 = gridLinesRatio;
    this.pkb_1 = gridNumbersRatio;
    this.qkb_1 = pathLinesRatio;
    this.rkb_1 = drawGridNumbers;
    this.skb_1 = boardType;
    this.tkb_1 = this.mkb_1 * this.nkb_1;
    this.ukb_1 = this.mkb_1 * this.okb_1;
    this.vkb_1 = this.mkb_1 * this.pkb_1;
    this.wkb_1 = this.mkb_1 * numberToDouble(this.qkb_1);
  }
  protoOf(UIMapSettings).toString = function () {
    return 'UIMapSettings(gridSize=' + this.mkb_1 + ', borderRatio=' + this.nkb_1 + ', gridLinesRatio=' + this.okb_1 + ', gridNumbersRatio=' + this.pkb_1 + ', pathLinesRatio=' + toString(this.qkb_1) + ', drawGridNumbers=' + this.rkb_1 + ', boardType=' + this.skb_1 + ')';
  };
  protoOf(UIMapSettings).hashCode = function () {
    var result = getNumberHashCode(this.mkb_1);
    result = imul(result, 31) + getNumberHashCode(this.nkb_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.okb_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.pkb_1) | 0;
    result = imul(result, 31) + hashCode(this.qkb_1) | 0;
    result = imul(result, 31) + (this.rkb_1 | 0) | 0;
    result = imul(result, 31) + this.skb_1.hashCode() | 0;
    return result;
  };
  protoOf(UIMapSettings).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIMapSettings))
      return false;
    var tmp0_other_with_cast = other instanceof UIMapSettings ? other : THROW_CCE();
    if (!equals(this.mkb_1, tmp0_other_with_cast.mkb_1))
      return false;
    if (!equals(this.nkb_1, tmp0_other_with_cast.nkb_1))
      return false;
    if (!equals(this.okb_1, tmp0_other_with_cast.okb_1))
      return false;
    if (!equals(this.pkb_1, tmp0_other_with_cast.pkb_1))
      return false;
    if (!equals(this.qkb_1, tmp0_other_with_cast.qkb_1))
      return false;
    if (!(this.rkb_1 === tmp0_other_with_cast.rkb_1))
      return false;
    if (!this.skb_1.equals(tmp0_other_with_cast.skb_1))
      return false;
    return true;
  };
  function renderRockCounters($this) {
    var rockCounters = RockCounterUtil_getInstance().agw($this.ahl_1);
    var tmp0_iterator = until(0, $this.ahl_1.bg9_1).h();
    while (tmp0_iterator.j()) {
      var x = tmp0_iterator.k().vg3_1;
      var tmp1_iterator = until(0, $this.ahl_1.cg9_1).h();
      while (tmp1_iterator.j()) {
        var y = tmp1_iterator.k().vg3_1;
        var num = rockCounters.a19(GameUnit__toInt_impl_9qttgv(x), GameUnit__toInt_impl_9qttgv(y));
        if (num > 0) {
          var tmp2_container = toWorldCoordinates($this.fhl_1, new IPoint(GameUnit__toFloat_impl_sc3672(x) + 0.5, GameUnit__toFloat_impl_sc3672(y) + 0.5), $this.ahl_1.cg9_1);
          var worldX = tmp2_container.c5().ngw_1;
          var worldY = tmp2_container.d5().ngw_1;
          var tmp$ret$4;
          // Inline function 'kotlin.apply' call
          var tmp3_$receiver = $this.uhl_1;
          var tmp4_text = num.toString();
          var tmp5_alignment = Companion_getInstance_5().t5r_1;
          var tmp6_font = get_ENTITY_TEXT_FONT();
          var tmp$ret$3;
          // Inline function 'korlibs.korge.view.text' call
          var tmp2_text = Colors_getInstance().k4s_1;
          var tmp3_text = get_DefaultStringTextRenderer();
          var tmp4_text_0 = Companion_getInstance_6().pdd_1;
          var tmp$ret$2;
          // Inline function 'korlibs.korge.view.addTo' call
          var tmp1_addTo = new Text(tmp4_text, 15.0, tmp2_text, tmp6_font, tmp5_alignment, tmp3_text, tmp4_text_0, null, null);
          var tmp$ret$1;
          // Inline function 'kotlin.apply' call
          var tmp0_apply = addTo(tmp1_addTo, tmp3_$receiver);
          // Inline function 'kotlin.contracts.contract' call
          var tmp$ret$0;
          // Inline function 'korlibs.korge.view.text.<anonymous>' call
          tmp$ret$0 = Unit_getInstance();
          tmp$ret$1 = tmp0_apply;
          tmp$ret$2 = tmp$ret$1;
          tmp$ret$3 = tmp$ret$2;
          var tmp5_apply = xy_2(tmp$ret$3, _WorldUnit___get_value__impl__xwm9rd(worldX), _WorldUnit___get_value__impl__xwm9rd(worldY));
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'com.xenotactic.korge.ui.UIMap.renderRockCounters.<anonymous>' call
          tmp5_apply.gbm($this.fhl_1 / 2);
          tmp5_apply.ebm(tmp5_apply.hbm() * tmp5_apply.zbl() / tmp5_apply.bbm());
          tmp$ret$4 = tmp5_apply;
          var component = tmp$ret$4;
          // Inline function 'kotlin.collections.set' call
          var tmp6_set = $this.mhl_1;
          var tmp7_set = new GameUnitTuple(x, y);
          tmp6_set.e5(tmp7_set, component);
        }
      }
    }
    $this.uhl_1.u96(false);
  }
  function drawBoard($this) {
    println('Drawing board');
    var tmp0_subject = $this.dhl_1.skb_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        var tmp$ret$3;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp2_solidRect = $this.nhl_1;
        var tmp3_solidRect = $this.fhl_1 * _GameUnit___get_value__impl__9ocgox($this.ahl_1.bg9_1);
        var tmp4_solidRect = $this.fhl_1 * _GameUnit___get_value__impl__9ocgox($this.ahl_1.cg9_1);
        var tmp5_solidRect = MaterialColors_getInstance().h5c_1;
        var tmp$ret$2;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp1_addTo = new SolidRect(Size_init_$Create$_0(tmp3_solidRect, tmp4_solidRect), tmp5_solidRect);
        var tmp$ret$1;
        // Inline function 'kotlin.apply' call
        var tmp0_apply = addTo(tmp1_addTo, tmp2_solidRect);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$0;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$0 = Unit_getInstance();
        tmp$ret$1 = tmp0_apply;
        tmp$ret$2 = tmp$ret$1;
        tmp$ret$3 = tmp$ret$2;

        ;
        break;
      case 1:
        var altColorWidth = true;
        var tmp1_iterator = until(0, $this.ahl_1.bg9_1).h();
        while (tmp1_iterator.j()) {
          var i = tmp1_iterator.k().vg3_1;
          var altColorHeight = altColorWidth;
          var tmp2_iterator = until(0, $this.ahl_1.cg9_1).h();
          while (tmp2_iterator.j()) {
            var j = tmp2_iterator.k().vg3_1;
            var currColor = altColorHeight ? MaterialColors_getInstance().h5c_1 : MaterialColors_getInstance().j5c_1;
            var tmp$ret$7;
            // Inline function 'korlibs.korge.view.solidRect' call
            var tmp8_solidRect = $this.nhl_1;
            var tmp9_solidRect = $this.fhl_1;
            var tmp10_solidRect = $this.fhl_1;
            var tmp$ret$6;
            // Inline function 'korlibs.korge.view.addTo' call
            var tmp7_addTo = new SolidRect(new Size(tmp9_solidRect, tmp10_solidRect), currColor);
            var tmp$ret$5;
            // Inline function 'kotlin.apply' call
            var tmp6_apply = addTo(tmp7_addTo, tmp8_solidRect);
            // Inline function 'kotlin.contracts.contract' call
            var tmp$ret$4;
            // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
            tmp$ret$4 = Unit_getInstance();
            tmp$ret$5 = tmp6_apply;
            tmp$ret$6 = tmp$ret$5;
            tmp$ret$7 = tmp$ret$6;
            xy_1(tmp$ret$7, GameUnit__toWorldUnit_impl_m803zs(i, $this.fhl_1), GameUnit__toWorldUnit_impl_m803zs(j, $this.fhl_1));
            altColorHeight = !altColorHeight;
          }
          altColorWidth = !altColorWidth;
        }

        break;
      case 2:
        var altColorWidth_0 = true;
        var gridSize = $this.fhl_1 * 2;
        var inductionVariable = 0;
        var last = (GameUnit__toInt_impl_9qttgv($this.ahl_1.bg9_1) + 1 | 0) / 2 | 0;
        if (inductionVariable < last)
          do {
            var i_0 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var altColorHeight_0 = altColorWidth_0;
            var inductionVariable_0 = 0;
            var last_0 = (GameUnit__toInt_impl_9qttgv($this.ahl_1.cg9_1) + 1 | 0) / 2 | 0;
            if (inductionVariable_0 < last_0)
              do {
                var j_0 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var gridWidth = imul(i_0 + 1 | 0, 2) > _GameUnit___get_value__impl__9ocgox($this.ahl_1.bg9_1) ? $this.fhl_1 : gridSize;
                var gridHeight = imul(j_0 + 1 | 0, 2) > _GameUnit___get_value__impl__9ocgox($this.ahl_1.cg9_1) ? $this.fhl_1 : gridSize;
                var currColor_0 = altColorHeight_0 ? MaterialColors_getInstance().h5c_1 : MaterialColors_getInstance().j5c_1;
                var tmp$ret$11;
                // Inline function 'korlibs.korge.view.solidRect' call
                var tmp13_solidRect = $this.nhl_1;
                var tmp$ret$10;
                // Inline function 'korlibs.korge.view.addTo' call
                var tmp12_addTo = new SolidRect(new Size(gridWidth, gridHeight), currColor_0);
                var tmp$ret$9;
                // Inline function 'kotlin.apply' call
                var tmp11_apply = addTo(tmp12_addTo, tmp13_solidRect);
                // Inline function 'kotlin.contracts.contract' call
                var tmp$ret$8;
                // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
                tmp$ret$8 = Unit_getInstance();
                tmp$ret$9 = tmp11_apply;
                tmp$ret$10 = tmp$ret$9;
                tmp$ret$11 = tmp$ret$10;
                xy_3(tmp$ret$11, i_0 * gridSize, j_0 * gridSize);
                altColorHeight_0 = !altColorHeight_0;
              }
               while (inductionVariable_0 < last_0);
            altColorWidth_0 = !altColorWidth_0;
          }
           while (inductionVariable < last);
        break;
    }
    println('Finished drawing board!');
  }
  function drawGridLines($this) {
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
    var tmp1_updateShape = $this.qhl_1;
    var tmp$ret$2;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
    // Inline function 'com.xenotactic.korge.ui.UIMap.drawGridLines.<anonymous>' call
    var tmp$ret$0;
    $l$block: {
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp6_stroke = Colors_getInstance().l4s_1;
      var tmp7_stroke = new StrokeInfo($this.hhl_1);
      if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
        tmp$ret$0 = Unit_getInstance();
        break $l$block;
      }
      // Inline function 'korlibs.image.vector.Context2d.stroke' call
      var tmp0_stroke = tmp7_stroke.j49_1;
      var tmp1_stroke = tmp7_stroke.m49_1;
      var tmp2_stroke = tmp7_stroke.o49_1;
      var tmp3_stroke = tmp7_stroke.p49_1;
      var tmp4_stroke = tmp7_stroke.q49_1;
      var tmp5_stroke = tmp7_stroke.r49_1;
      {
        tmp0_apply.a5s();
      }
      // Inline function 'com.xenotactic.korge.ui.UIMap.drawGridLines.<anonymous>.<anonymous>' call
      var progression = rangeTo(0, $this.ahl_1.bg9_1);
      var inductionVariable = progression.p1_1;
      var last = progression.q1_1;
      if (inductionVariable <= last)
        do {
          var i = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          tmp0_apply.z4k(new Vector2(i * $this.fhl_1, 0.0), new Vector2(i * $this.fhl_1, GameUnit__toFloat_impl_sc3672($this.ahl_1.cg9_1) * $this.fhl_1));
        }
         while (!(i === last));
      var progression_0 = rangeTo(0, $this.ahl_1.cg9_1);
      var inductionVariable_0 = progression_0.p1_1;
      var last_0 = progression_0.q1_1;
      if (inductionVariable_0 <= last_0)
        do {
          var j = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          tmp0_apply.z4k(new Vector2(0.0, j * $this.fhl_1), new Vector2(GameUnit__toFloat_impl_sc3672($this.ahl_1.bg9_1) * $this.fhl_1, j * $this.fhl_1));
        }
         while (!(j === last_0));
      // Inline function 'korlibs.image.vector.Context2d.keep' call
      tmp0_apply.t5t();
      try {
        // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
        tmp0_apply.u5t(tmp0_stroke);
        tmp0_apply.v5t(tmp1_stroke);
        tmp0_apply.w5t(tmp2_stroke);
        tmp0_apply.x5t(tmp3_stroke);
        tmp0_apply.y5t(tmp4_stroke);
        tmp0_apply.z5t(tmp5_stroke);
        tmp0_apply.a5u(new RGBA(tmp6_stroke));
      }finally {
        tmp0_apply.b5u();
      }
    }
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1.o5q();
    tmp1_updateShape.zen(tmp$ret$2);
  }
  function drawGridNumbers($this) {
    $this.ohl_1.ibk();
    if (!$this.dhl_1.rkb_1) {
      return Unit_getInstance();
    }
    var tmp0_iterator = until(0, $this.ahl_1.bg9_1).h();
    while (tmp0_iterator.j()) {
      var i = tmp0_iterator.k().vg3_1;
      var tmp$ret$3;
      // Inline function 'korlibs.korge.view.text' call
      var tmp2_text = $this.ohl_1;
      var tmp3_text = GameUnit__toString_impl_3u51th(i);
      var tmp4_text = $this.ihl_1;
      var tmp5_text = Companion_getInstance_5().w5r_1;
      var tmp6_text = Colors_getInstance().k4s_1;
      var tmp7_text = get_DefaultTtfFontAsBitmap();
      var tmp8_text = get_DefaultStringTextRenderer();
      var tmp9_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$2;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo = new Text(tmp3_text, tmp4_text, tmp6_text, tmp7_text, tmp5_text, tmp8_text, tmp9_text, null, null);
      var tmp$ret$1;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = addTo(tmp1_addTo, tmp2_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$0 = Unit_getInstance();
      tmp$ret$1 = tmp0_apply;
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$3 = tmp$ret$2;
      xy_1(tmp$ret$3, GameUnit__toWorldUnit_impl_m803zs(i, $this.fhl_1), toWorldUnit(0));
      var tmp$ret$7;
      // Inline function 'korlibs.korge.view.text' call
      var tmp12_text = $this.ohl_1;
      var tmp13_text = GameUnit__toString_impl_3u51th(i);
      var tmp14_text = $this.ihl_1;
      var tmp15_text = Colors_getInstance().k4s_1;
      var tmp16_text = get_DefaultTtfFontAsBitmap();
      var tmp17_text = Companion_getInstance_5().g5r_1;
      var tmp18_text = get_DefaultStringTextRenderer();
      var tmp19_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$6;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp11_addTo = new Text(tmp13_text, tmp14_text, tmp15_text, tmp16_text, tmp17_text, tmp18_text, tmp19_text, null, null);
      var tmp$ret$5;
      // Inline function 'kotlin.apply' call
      var tmp10_apply = addTo(tmp11_addTo, tmp12_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$4;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$4 = Unit_getInstance();
      tmp$ret$5 = tmp10_apply;
      tmp$ret$6 = tmp$ret$5;
      tmp$ret$7 = tmp$ret$6;
      xy_1(tmp$ret$7, GameUnit__toWorldUnit_impl_m803zs(i, $this.fhl_1), GameUnit__toWorldUnit_impl_m803zs($this.ahl_1.cg9_1, $this.fhl_1));
    }
    var tmp1_iterator = until(0, $this.ahl_1.cg9_1).h();
    while (tmp1_iterator.j()) {
      var j = tmp1_iterator.k().vg3_1;
      var tmp$ret$11;
      // Inline function 'korlibs.korge.view.text' call
      var tmp22_text = $this.ohl_1;
      var tmp23_text = GameUnit__toString_impl_3u51th(j);
      var tmp24_text = $this.ihl_1;
      var tmp25_text = Companion_getInstance_5().m5r_1;
      var tmp26_text = Colors_getInstance().k4s_1;
      var tmp27_text = get_DefaultTtfFontAsBitmap();
      var tmp28_text = get_DefaultStringTextRenderer();
      var tmp29_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$10;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp21_addTo = new Text(tmp23_text, tmp24_text, tmp26_text, tmp27_text, tmp25_text, tmp28_text, tmp29_text, null, null);
      var tmp$ret$9;
      // Inline function 'kotlin.apply' call
      var tmp20_apply = addTo(tmp21_addTo, tmp22_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$8 = Unit_getInstance();
      tmp$ret$9 = tmp20_apply;
      tmp$ret$10 = tmp$ret$9;
      tmp$ret$11 = tmp$ret$10;
      xy_1(tmp$ret$11, toWorldUnit(-10.0), WorldUnit__minus_impl_yejnmb_0(GameUnit__toWorldUnit_impl_m803zs($this.ahl_1.cg9_1, $this.fhl_1), GameUnit__toWorldUnit_impl_m803zs(j, $this.fhl_1)));
      var tmp$ret$15;
      // Inline function 'korlibs.korge.view.text' call
      var tmp32_text = $this.ohl_1;
      var tmp33_text = GameUnit__toString_impl_3u51th(j);
      var tmp34_text = $this.ihl_1;
      var tmp35_text = Companion_getInstance_5().k5r_1;
      var tmp36_text = Colors_getInstance().k4s_1;
      var tmp37_text = get_DefaultTtfFontAsBitmap();
      var tmp38_text = get_DefaultStringTextRenderer();
      var tmp39_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$14;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp31_addTo = new Text(tmp33_text, tmp34_text, tmp36_text, tmp37_text, tmp35_text, tmp38_text, tmp39_text, null, null);
      var tmp$ret$13;
      // Inline function 'kotlin.apply' call
      var tmp30_apply = addTo(tmp31_addTo, tmp32_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$12;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$12 = Unit_getInstance();
      tmp$ret$13 = tmp30_apply;
      tmp$ret$14 = tmp$ret$13;
      tmp$ret$15 = tmp$ret$14;
      xy_1(tmp$ret$15, WorldUnit__plus_impl_n1o1x9(GameUnit__toWorldUnit_impl_m803zs($this.ahl_1.bg9_1, $this.fhl_1), toWorldUnit(10)), WorldUnit__minus_impl_yejnmb_0(GameUnit__toWorldUnit_impl_m803zs($this.ahl_1.cg9_1, $this.fhl_1), GameUnit__toWorldUnit_impl_m803zs(j, $this.fhl_1)));
    }
  }
  function createEntityView($this, entity) {
    var tmp = entity.c7d();
    var tmp_0 = entity.lg8();
    var tmp_1 = entity.mg8();
    var tmp_2;
    if (entity instanceof SpeedArea) {
      tmp_2 = entity.sgg_1;
    } else {
      tmp_2 = null;
    }
    return new UIEntity(tmp, tmp_0, tmp_1, $this.fhl_1, $this.ghl_1, tmp_2);
  }
  function renderEntityInternal($this, entity) {
    var tmp0_container = toWorldCoordinates_2($this.fhl_1, entity, $this.ahl_1.bg9_1, $this.ahl_1.cg9_1);
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = createEntityView($this, entity);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMap.renderEntityInternal.<anonymous>' call
    if (entity instanceof SpeedArea) {
      addTo(tmp0_apply, $this.rhl_1);
    } else {
      addTo(tmp0_apply, $this.shl_1);
    }
    xy_1(tmp0_apply, worldX, worldY);
    tmp$ret$0 = tmp0_apply;
    var uiEntity = tmp$ret$0;
    var tmp$ret$3;
    // Inline function 'kotlin.collections.getOrPut' call
    var tmp1_getOrPut = $this.khl_1;
    var value = tmp1_getOrPut.h3(entity);
    var tmp;
    if (value == null) {
      var tmp$ret$2;
      // Inline function 'com.xenotactic.korge.ui.UIMap.renderEntityInternal.<anonymous>' call
      var tmp$ret$1;
      // Inline function 'kotlin.collections.mutableListOf' call
      tmp$ret$1 = ArrayList_init_$Create$();
      tmp$ret$2 = tmp$ret$1;
      var answer = tmp$ret$2;
      tmp1_getOrPut.e5(entity, answer);
      tmp = answer;
    } else {
      tmp = value;
    }
    tmp$ret$3 = tmp;
    var drawnEntitiesList = tmp$ret$3;
    drawnEntitiesList.b(uiEntity);
    return uiEntity;
  }
  function renderEntityTextInternal($this, entity) {
    if ($this.lhl_1.b3(entity)) {
      return Unit_getInstance();
    }
    var text = entity.egh().rgh();
    if (!(text == null)) {
      var tmp0_container = toWorldCoordinates($this.fhl_1, entity.vg8(), $this.ahl_1.cg9_1);
      var worldX = tmp0_container.c5().ngw_1;
      var worldY = tmp0_container.d5().ngw_1;
      var tmp$ret$0;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = makeEntityLabelText(text);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UIMap.renderEntityTextInternal.<anonymous>' call
      addTo(tmp0_apply, $this.whl_1);
      xy_1(tmp0_apply, worldX, worldY);
      tmp0_apply.gbm($this.fhl_1 / 2);
      tmp0_apply.ebm(tmp0_apply.hbm() * tmp0_apply.zbl() / tmp0_apply.bbm());
      tmp$ret$0 = tmp0_apply;
      var component = tmp$ret$0;
      // Inline function 'kotlin.collections.set' call
      var tmp1_set = $this.lhl_1;
      tmp1_set.e5(entity, component);
    }
  }
  function renderEntities($this) {
    var tmp0_iterator = $this.ahl_1.yga().h();
    while (tmp0_iterator.j()) {
      var entity = tmp0_iterator.k();
      $this.xkb(entity);
    }
  }
  function UIMap$viewRockCounters$slambda(this$0, resultContinuation) {
    this.gkc_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMap$viewRockCounters$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMap$viewRockCounters$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMap$viewRockCounters$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = delay(new Long(5000, 0), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            this.gkc_1.uhl_1.u96(false);
            this.gkc_1.vhl_1.j14();
            println('End rock counter routine');
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMap$viewRockCounters$slambda).g1p = function (completion) {
    var i = new UIMap$viewRockCounters$slambda(this.gkc_1, completion);
    return i;
  };
  function UIMap$viewRockCounters$slambda_0(this$0, resultContinuation) {
    var i = new UIMap$viewRockCounters$slambda(this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function $viewRockCountersCOROUTINE$7(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.pkc_1 = _this__u8e3s4;
  }
  protoOf($viewRockCountersCOROUTINE$7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            if (this.pkc_1.vhl_1.f14()) {
              println('Rock counters mutex is locked, skipping');
              return Unit_getInstance();
            }

            this.y7_1 = 1;
            suspendResult = this.pkc_1.vhl_1.h14(VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            println('Started rock counter routine');
            this.pkc_1.uhl_1.u96(true);
            var tmp_0 = GlobalScope_getInstance().to();
            launch_0(tmp_0, UIMap$viewRockCounters$slambda_0(this.pkc_1, null));
            ;
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function UIMap(gameMap, engine, shortestPath, uiMapSettings, initialRenderEntities) {
    engine = engine === VOID ? null : engine;
    shortestPath = shortestPath === VOID ? null : shortestPath;
    uiMapSettings = uiMapSettings === VOID ? new UIMapSettings() : uiMapSettings;
    initialRenderEntities = initialRenderEntities === VOID ? true : initialRenderEntities;
    Container.call(this);
    this.ahl_1 = gameMap;
    this.bhl_1 = engine;
    this.chl_1 = shortestPath;
    this.dhl_1 = uiMapSettings;
    this.ehl_1 = initialRenderEntities;
    this.fhl_1 = this.dhl_1.mkb_1;
    this.ghl_1 = this.dhl_1.tkb_1;
    this.hhl_1 = this.dhl_1.ukb_1;
    this.ihl_1 = this.dhl_1.vkb_1;
    this.jhl_1 = this.dhl_1.wkb_1;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$();
    tmp.khl_1 = tmp$ret$0;
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$1 = LinkedHashMap_init_$Create$();
    tmp_0.lhl_1 = tmp$ret$1;
    var tmp_1 = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.mutableMapOf' call
    tmp$ret$2 = LinkedHashMap_init_$Create$();
    tmp_1.mhl_1 = tmp$ret$2;
    var tmp_2 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$3;
    // Inline function 'com.xenotactic.korge.ui.UIMap._boardLayer.<anonymous>' call
    tmp$ret$3 = Unit_getInstance();
    tmp$ret$4 = tmp0_apply;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp_2.nhl_1 = tmp$ret$6;
    var tmp_3 = this;
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$8;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$7 = Unit_getInstance();
    tmp$ret$8 = tmp0_apply_0;
    tmp$ret$9 = tmp$ret$8;
    tmp$ret$10 = tmp$ret$9;
    tmp_3.ohl_1 = tmp$ret$10;
    var tmp_4 = this;
    var tmp$ret$14;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$13;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = new Container(false);
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_1, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$11 = Unit_getInstance();
    tmp$ret$12 = tmp0_apply_1;
    tmp$ret$13 = tmp$ret$12;
    tmp$ret$14 = tmp$ret$13;
    tmp_4.phl_1 = tmp$ret$14;
    var tmp_5 = this;
    var tmp$ret$20;
    // Inline function 'korlibs.korge.view.vector.gpuGraphics' call
    var tmp1_gpuGraphics = this.phl_1;
    var tmp$ret$19;
    // Inline function 'korlibs.korge.view.vector.gpuShapeView' call
    var tmp$ret$18;
    // Inline function 'kotlin.also' call
    var tmp0_also = new GpuShapeView(EmptyShape_getInstance(), true);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
    var tmp$ret$17;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$16;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_2 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>.<anonymous>' call
    var tmp$ret$15;
    // Inline function 'com.xenotactic.korge.ui.UIMap._gridLinesGraphics.<anonymous>' call
    tmp$ret$15 = Unit_getInstance();
    tmp$ret$16 = tmp0_apply_2;
    tmp$ret$17 = tmp$ret$16.o5q();
    tmp0_also.zen(tmp$ret$17);
    tmp$ret$18 = tmp0_also;
    tmp$ret$19 = addTo(tmp$ret$18, tmp1_gpuGraphics);
    tmp$ret$20 = tmp$ret$19;
    tmp_5.qhl_1 = tmp$ret$20;
    var tmp_6 = this;
    var tmp$ret$24;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$23;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_2 = new Container(false);
    var tmp$ret$22;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_3 = addTo(tmp1_addTo_2, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$21;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$21 = Unit_getInstance();
    tmp$ret$22 = tmp0_apply_3;
    tmp$ret$23 = tmp$ret$22;
    tmp$ret$24 = tmp$ret$23;
    tmp_6.rhl_1 = tmp$ret$24;
    var tmp_7 = this;
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp$ret$28;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_3 = new Container(false);
    var tmp$ret$26;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_4 = addTo(tmp1_addTo_3, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$25;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$25 = Unit_getInstance();
    tmp$ret$26 = tmp0_apply_4;
    tmp$ret$27 = tmp$ret$26;
    tmp$ret$28 = tmp$ret$27;
    var tmp2_apply = tmp$ret$28;
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$29;
    // Inline function 'com.xenotactic.korge.ui.UIMap._entityLayer.<anonymous>' call
    tmp$ret$29 = Unit_getInstance();
    tmp$ret$30 = tmp2_apply;
    tmp_7.shl_1 = tmp$ret$30;
    var tmp_8 = this;
    var tmp$ret$34;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_4 = new Container(false);
    var tmp$ret$32;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_5 = addTo(tmp1_addTo_4, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$31 = Unit_getInstance();
    tmp$ret$32 = tmp0_apply_5;
    tmp$ret$33 = tmp$ret$32;
    tmp$ret$34 = tmp$ret$33;
    tmp_8.thl_1 = tmp$ret$34;
    var tmp_9 = this;
    var tmp$ret$38;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$37;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_5 = new Container(false);
    var tmp$ret$36;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_6 = addTo(tmp1_addTo_5, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$35;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$35 = Unit_getInstance();
    tmp$ret$36 = tmp0_apply_6;
    tmp$ret$37 = tmp$ret$36;
    tmp$ret$38 = tmp$ret$37;
    tmp_9.uhl_1 = tmp$ret$38;
    this.vhl_1 = Mutex();
    var tmp_10 = this;
    var tmp$ret$42;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$41;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_6 = new Container(false);
    var tmp$ret$40;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_7 = addTo(tmp1_addTo_6, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$39;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$39 = Unit_getInstance();
    tmp$ret$40 = tmp0_apply_7;
    tmp$ret$41 = tmp$ret$40;
    tmp$ret$42 = tmp$ret$41;
    tmp_10.whl_1 = tmp$ret$42;
    var tmp_11 = this;
    var tmp$ret$46;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$45;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_7 = new Container(false);
    var tmp$ret$44;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_8 = addTo(tmp1_addTo_7, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$43;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$43 = Unit_getInstance();
    tmp$ret$44 = tmp0_apply_8;
    tmp$ret$45 = tmp$ret$44;
    tmp$ret$46 = tmp$ret$45;
    tmp_11.xhl_1 = tmp$ret$46;
    var tmp_12 = this;
    var tmp$ret$52;
    // Inline function 'korlibs.korge.view.vector.gpuGraphics' call
    var tmp1_gpuGraphics_0 = this.xhl_1;
    var tmp$ret$51;
    // Inline function 'korlibs.korge.view.vector.gpuShapeView' call
    var tmp$ret$50;
    // Inline function 'kotlin.also' call
    var tmp0_also_0 = new GpuShapeView(EmptyShape_getInstance(), true);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
    var tmp$ret$49;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$48;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_9 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>.<anonymous>' call
    var tmp$ret$47;
    // Inline function 'com.xenotactic.korge.ui.UIMap._pathingLinesGraphics.<anonymous>' call
    tmp$ret$47 = Unit_getInstance();
    tmp$ret$48 = tmp0_apply_9;
    tmp$ret$49 = tmp$ret$48.o5q();
    tmp0_also_0.zen(tmp$ret$49);
    tmp$ret$50 = tmp0_also_0;
    tmp$ret$51 = addTo(tmp$ret$50, tmp1_gpuGraphics_0);
    tmp$ret$52 = tmp$ret$51;
    tmp_12.yhl_1 = tmp$ret$52;
    var tmp_13 = this;
    var tmp$ret$56;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$55;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_8 = new Container(false);
    var tmp$ret$54;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_10 = addTo(tmp1_addTo_8, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$53;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$53 = Unit_getInstance();
    tmp$ret$54 = tmp0_apply_10;
    tmp$ret$55 = tmp$ret$54;
    tmp$ret$56 = tmp$ret$55;
    tmp_13.zhl_1 = tmp$ret$56;
    var tmp_14 = this;
    var tmp$ret$62;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$60;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = Colors_getInstance().a4y_1;
    var tmp$ret$59;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_9 = new SolidRect(Size_init_$Create$(0, 0), tmp2_solidRect);
    var tmp$ret$58;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_11 = addTo(tmp1_addTo_9, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$57;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$57 = Unit_getInstance();
    tmp$ret$58 = tmp0_apply_11;
    tmp$ret$59 = tmp$ret$58;
    tmp$ret$60 = tmp$ret$59;
    var tmp3_visible = alpha(tmp$ret$60, 0.5);
    var tmp$ret$61;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp3_visible.u96(false);
    tmp$ret$61 = tmp3_visible;
    tmp$ret$62 = tmp$ret$61;
    tmp_14.ahm_1 = tmp$ret$62;
    drawBoard(this);
    drawGridNumbers(this);
    drawGridLines(this);
    if (this.ehl_1) {
      renderEntities(this);
    }
    renderRockCounters(this);
    this.iht(this.chl_1);
  }
  protoOf(UIMap).wje = function () {
    return this.ahl_1.cg9_1;
  };
  protoOf(UIMap).vje = function () {
    return this.ahl_1.bg9_1;
  };
  protoOf(UIMap).whx = function ($completion) {
    var tmp = new $viewRockCountersCOROUTINE$7(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMap).xkb = function (entity) {
    renderEntityTextInternal(this, entity);
    return renderEntityInternal(this, entity);
  };
  protoOf(UIMap).iht = function (pathSequence) {
    if (!(pathSequence == null)) {
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
      var tmp1_updateShape = this.yhl_1;
      var tmp$ret$2;
      // Inline function 'korlibs.image.vector.buildShape' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$1;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = new ShapeBuilder(null, null);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
      // Inline function 'com.xenotactic.korge.ui.UIMap.renderPathLines.<anonymous>' call
      var tmp$ret$0;
      $l$block: {
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp6_stroke = Colors_getInstance().a4y_1;
        var tmp7_stroke = new StrokeInfo(this.jhl_1);
        if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        }
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp0_stroke = tmp7_stroke.j49_1;
        var tmp1_stroke = tmp7_stroke.m49_1;
        var tmp2_stroke = tmp7_stroke.o49_1;
        var tmp3_stroke = tmp7_stroke.p49_1;
        var tmp4_stroke = tmp7_stroke.q49_1;
        var tmp5_stroke = tmp7_stroke.r49_1;
        {
          tmp0_apply.a5s();
        }
        // Inline function 'com.xenotactic.korge.ui.UIMap.renderPathLines.<anonymous>.<anonymous>' call
        var tmp0_iterator = pathSequence.fgk_1.h();
        while (tmp0_iterator.j()) {
          var path = tmp0_iterator.k();
          var tmp1_iterator = path.jgj().h();
          while (tmp1_iterator.j()) {
            var segment = tmp1_iterator.k();
            var worldP1 = toWorldCoordinates_0(this.fhl_1, segment.fgj_1, this.ahl_1.cg9_1);
            var worldP2 = toWorldCoordinates_0(this.fhl_1, segment.ggj_1, this.ahl_1.cg9_1);
            tmp0_apply.z4k(toPoint(worldP1), toPoint(worldP2));
          }
        }
        // Inline function 'korlibs.image.vector.Context2d.keep' call
        tmp0_apply.t5t();
        try {
          // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
          tmp0_apply.u5t(tmp0_stroke);
          tmp0_apply.v5t(tmp1_stroke);
          tmp0_apply.w5t(tmp2_stroke);
          tmp0_apply.x5t(tmp3_stroke);
          tmp0_apply.y5t(tmp4_stroke);
          tmp0_apply.z5t(tmp5_stroke);
          tmp0_apply.a5u(new RGBA(tmp6_stroke));
        }finally {
          tmp0_apply.b5u();
        }
      }
      tmp$ret$1 = tmp0_apply;
      tmp$ret$2 = tmp$ret$1.o5q();
      tmp1_updateShape.zen(tmp$ret$2);
    }
  };
  protoOf(UIMap).ehh = function (gridX, gridY, entityWidth, entityHeight) {
    var tmp0_container = toWorldCoordinates_0(this.fhl_1, new GameUnitTuple(gridX, gridY), this.ahl_1.cg9_1, entityHeight);
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    var tmp1_container = toWorldDimensions(entityWidth, entityHeight, this.fhl_1);
    var worldWidth = tmp1_container.c5().ngw_1;
    var worldHeight = tmp1_container.d5().ngw_1;
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp0_visible = xy_1(size_0(this.ahm_1, worldWidth, worldHeight), worldX, worldY);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_visible.u96(true);
    tmp$ret$0 = tmp0_visible;
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(UIMap).siy = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp0_visible = this.ahm_1;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_visible.u96(false);
    tmp$ret$0 = tmp0_visible;
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(UIMap).ehm = function (pointerAction) {
    var tmp0_subject = pointerAction;
    if (equals(tmp0_subject, Inactive_getInstance())) {
      this.siy();
    } else {
      if (tmp0_subject instanceof HighlightForPlacement) {
        if (!(pointerAction.lhm_1 == null)) {
          var tmp1_container = ensureNotNull(pointerAction.lhm_1);
          var gridX = tmp1_container.ogb();
          var gridY = tmp1_container.pgb();
          this.ehh(gridX, gridY, pointerAction.khm_1.lg8(), pointerAction.khm_1.mg8());
        }
      } else {
        if (tmp0_subject instanceof RemoveEntityAtPlace) {
          var data = pointerAction.ghm_1;
          if (data == null) {
            this.siy();
          } else {
            var tmp2_container = toWorldCoordinates_0(this.fhl_1, data.jhm_1.zgg(), this.ahl_1.cg9_1, data.jhm_1.mg8());
            var worldX = tmp2_container.c5().ngw_1;
            var worldY = tmp2_container.d5().ngw_1;
            var tmp3_container = toWorldDimensions_0(data.jhm_1, this.fhl_1);
            var worldWidth = tmp3_container.c5().ngw_1;
            var worldHeight = tmp3_container.d5().ngw_1;
            var tmp$ret$1;
            // Inline function 'korlibs.korge.view.visible' call
            var tmp0_visible = xy_1(size_0(this.ahm_1, worldWidth, worldHeight), worldX, worldY);
            var tmp$ret$0;
            // Inline function 'kotlin.also' call
            // Inline function 'kotlin.contracts.contract' call
            // Inline function 'korlibs.korge.view.visible.<anonymous>' call
            tmp0_visible.u96(true);
            tmp$ret$0 = tmp0_visible;
            tmp$ret$1 = tmp$ret$0;
          }
        }
      }
    }
  };
  protoOf(UIMap).bhm = function (globalMouseX, globalMouseY) {
    var localXY = this.nhl_1.fao(Vector2_init_$Create$_0(globalMouseX, globalMouseY));
    var unprojected = new Vector2(localXY.p3r_1, _GameUnit___get_value__impl__9ocgox(this.wje()) * this.fhl_1 - localXY.q3r_1);
    var gridX = unprojected.p3r_1 / this.fhl_1;
    var gridY = unprojected.q3r_1 / this.fhl_1;
    return to(gridX, gridY);
  };
  protoOf(UIMap).mhm = function (gridX, gridY, entityWidth, entityHeight) {
    return getRoundedGridCoordinates(gridX, gridY, entityWidth, entityHeight, this.vje(), this.wje());
  };
  function BoardType_SOLID_getInstance() {
    BoardType_initEntries();
    return BoardType_SOLID_instance;
  }
  function BoardType_CHECKERED_1X1_getInstance() {
    BoardType_initEntries();
    return BoardType_CHECKERED_1X1_instance;
  }
  function BoardType_CHECKERED_2X2_getInstance() {
    BoardType_initEntries();
    return BoardType_CHECKERED_2X2_instance;
  }
  var properties_initialized_UIMap_kt_sl3frc;
  function _init_properties_UIMap_kt__tglk2e() {
    if (properties_initialized_UIMap_kt_sl3frc) {
    } else {
      properties_initialized_UIMap_kt_sl3frc = true;
      ENTITY_TEXT_FONT = Companion_getInstance_21().r5j(get_DefaultTtfFont(), 30.0, VOID, VOID, VOID, VOID, new BitmapEffect(VOID, VOID, VOID, VOID, VOID, 1));
    }
  }
  function Companion_10() {
    Companion_instance_10 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIMapBox).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.qkc_1 = tmp$ret$0;
  }
  var Companion_instance_10;
  function Companion_getInstance_36() {
    if (Companion_instance_10 == null)
      new Companion_10();
    return Companion_instance_10;
  }
  function UIMapBox$updateMap$slambda(this$0, resultContinuation) {
    this.zkc_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapBox$updateMap$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapBox$updateMap$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapBox$updateMap$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = renderToBitmap(this.zkc_1.pkf_1, get_VIEWS_INSTANCE(), VOID, VOID, VOID, VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            var asBitMap = suspendResult;
            this.zkc_1.pkf_1.ibk();
            var tmp2_image = this.zkc_1.pkf_1;
            var tmp3_image = Companion_getInstance_0().k3q_1;
            var tmp1_addTo = Image_init_$Create$(asBitMap, tmp3_image);
            var tmp0_apply = addTo(tmp1_addTo, tmp2_image);
            ;
            var mapImage = tmp0_apply;
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapBox$updateMap$slambda).g1p = function (completion) {
    var i = new UIMapBox$updateMap$slambda(this.zkc_1, completion);
    return i;
  };
  function UIMapBox$updateMap$slambda_0(this$0, resultContinuation) {
    var i = new UIMapBox$updateMap$slambda(this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapBox(gameMap, boxWidth, boxHeight, gameMapGridSize, paddingTopAndBottom, paddingLeftAndRight, calculateMapPath) {
    Companion_getInstance_36();
    gameMapGridSize = gameMapGridSize === VOID ? 25.0 : gameMapGridSize;
    paddingTopAndBottom = paddingTopAndBottom === VOID ? 5.0 : paddingTopAndBottom;
    paddingLeftAndRight = paddingLeftAndRight === VOID ? 5.0 : paddingLeftAndRight;
    calculateMapPath = calculateMapPath === VOID ? false : calculateMapPath;
    Container.call(this);
    this.jkf_1 = boxWidth;
    this.kkf_1 = boxHeight;
    this.lkf_1 = paddingTopAndBottom;
    this.mkf_1 = paddingLeftAndRight;
    this.nkf_1 = numberToDouble(gameMapGridSize);
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = this.jkf_1;
    var tmp3_solidRect = this.kkf_1;
    var tmp4_solidRect = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, 0.4);
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$_0(tmp2_solidRect, tmp3_solidRect), tmp4_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.okf_1 = tmp$ret$3;
    var tmp_0 = this;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$4 = Unit_getInstance();
    tmp$ret$5 = tmp0_apply_0;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    tmp_0.pkf_1 = tmp$ret$7;
    this.qkf(gameMap, calculateMapPath);
  }
  protoOf(UIMapBox).qkf = function (gameMap, calculateMapPath) {
    var currentMapContainerHeight = _GameUnit___get_value__impl__9ocgox(gameMap.cg9_1) * this.nkf_1;
    var currentMapContainerWidth = _GameUnit___get_value__impl__9ocgox(gameMap.bg9_1) * this.nkf_1;
    var maxMapHeight = this.kkf_1 - this.lkf_1 * 2;
    var maxMapWidth = this.jkf_1 - this.mkf_1 * 2;
    var scaledByHeight = maxMapHeight / currentMapContainerHeight;
    var scaledByWidth = maxMapWidth / currentMapContainerWidth;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    tmp$ret$0 = Math.min(scaledByHeight, scaledByWidth);
    var mapScale = tmp$ret$0;
    this.pkf_1.pbl(toScale(mapScale));
    this.pkf_1.ibk();
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.pkf_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapBox.updateMap.<anonymous>' call
    var tmp = calculateMapPath ? PathFinder_getInstance().tgj(gameMap) : null;
    var tmp0_gridSize = this.nkf_1;
    var tmp1_pathLinesRatio = 0.5 / mapScale * get_PATH_LINES_RATIO();
    var tmp2_boardType = BoardType_SOLID_getInstance();
    new UIMap(gameMap, VOID, tmp, new UIMapSettings(tmp0_gridSize, VOID, VOID, VOID, tmp1_pathLinesRatio, false, tmp2_boardType));
    tmp$ret$1 = tmp0_apply;
    centerOn(this.pkf_1, this.okf_1);
    var tmp_0 = GlobalScope_getInstance();
    launch_1(tmp_0, UIMapBox$updateMap$slambda_0(this, null));
  };
  function Companion_11() {
    Companion_instance_11 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIMapEntry).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.rkf_1 = tmp$ret$0;
  }
  var Companion_instance_11;
  function Companion_getInstance_37() {
    if (Companion_instance_11 == null)
      new Companion_11();
    return Companion_instance_11;
  }
  function UIMapEntry$slambda(this$0, resultContinuation) {
    this.akg_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEntry$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.akg_1.dip_1.h1q(Unit_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEntry$slambda).yak = function (it, completion) {
    var i = new UIMapEntry$slambda(this.akg_1, completion);
    i.bkg_1 = it;
    return i;
  };
  function UIMapEntry$slambda_0(this$0, resultContinuation) {
    var i = new UIMapEntry$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEntry$lambda$slambda($tmp4_onClick, $it, resultContinuation) {
    this.kkg_1 = $tmp4_onClick;
    this.lkg_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapEntry$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.kkg_1(this.lkg_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapEntry$lambda$slambda).g1p = function (completion) {
    var i = new UIMapEntry$lambda$slambda(this.kkg_1, this.lkg_1, completion);
    return i;
  };
  function UIMapEntry$lambda$slambda_0($tmp4_onClick, $it, resultContinuation) {
    var i = new UIMapEntry$lambda$slambda($tmp4_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapEntry$lambda($tmp1_safe_receiver, $tmp4_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapEntry$lambda$slambda_0($tmp4_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapEntry$slambda_1(this$0, resultContinuation) {
    this.ukg_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEntry$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ukg_1.bip_1.h1q(this.vkg_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEntry$slambda_1).yak = function (it, completion) {
    var i = new UIMapEntry$slambda_1(this.ukg_1, completion);
    i.vkg_1 = it;
    return i;
  };
  function UIMapEntry$slambda_2(this$0, resultContinuation) {
    var i = new UIMapEntry$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEntry$lambda$slambda_1($tmp1_onClick, $it, resultContinuation) {
    this.ekh_1 = $tmp1_onClick;
    this.fkh_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapEntry$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ekh_1(this.fkh_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapEntry$lambda$slambda_1).g1p = function (completion) {
    var i = new UIMapEntry$lambda$slambda_1(this.ekh_1, this.fkh_1, completion);
    return i;
  };
  function UIMapEntry$lambda$slambda_2($tmp1_onClick, $it, resultContinuation) {
    var i = new UIMapEntry$lambda$slambda_1($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapEntry$lambda_0($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapEntry$lambda$slambda_2($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapEntry$slambda_3(this$0, resultContinuation) {
    this.okh_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEntry$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.okh_1.cip_1.h1q(this.pkh_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEntry$slambda_3).yak = function (it, completion) {
    var i = new UIMapEntry$slambda_3(this.okh_1, completion);
    i.pkh_1 = it;
    return i;
  };
  function UIMapEntry$slambda_4(this$0, resultContinuation) {
    var i = new UIMapEntry$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEntry$lambda$slambda_3($tmp1_onClick, $it, resultContinuation) {
    this.ykh_1 = $tmp1_onClick;
    this.zkh_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapEntry$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ykh_1(this.zkh_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapEntry$lambda$slambda_3).g1p = function (completion) {
    var i = new UIMapEntry$lambda$slambda_3(this.ykh_1, this.zkh_1, completion);
    return i;
  };
  function UIMapEntry$lambda$slambda_4($tmp1_onClick, $it, resultContinuation) {
    var i = new UIMapEntry$lambda$slambda_3($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapEntry$lambda_1($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapEntry$lambda$slambda_4($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapEntry$slambda_5($outlineRect, resultContinuation) {
    this.iki_1 = $outlineRect;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$slambda_5).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$slambda_5).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEntry$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          alpha(this.iki_1, 1.0);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEntry$slambda_5).yak = function (it, completion) {
    var i = new UIMapEntry$slambda_5(this.iki_1, completion);
    i.jki_1 = it;
    return i;
  };
  function UIMapEntry$slambda_6($outlineRect, resultContinuation) {
    var i = new UIMapEntry$slambda_5($outlineRect, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEntry$lambda$slambda_5($tmp19_onOver, $it, resultContinuation) {
    this.ski_1 = $tmp19_onOver;
    this.tki_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$lambda$slambda_5).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$lambda$slambda_5).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapEntry$lambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ski_1(this.tki_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapEntry$lambda$slambda_5).g1p = function (completion) {
    var i = new UIMapEntry$lambda$slambda_5(this.ski_1, this.tki_1, completion);
    return i;
  };
  function UIMapEntry$lambda$slambda_6($tmp19_onOver, $it, resultContinuation) {
    var i = new UIMapEntry$lambda$slambda_5($tmp19_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapEntry$lambda_2($tmp1_safe_receiver, $tmp19_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapEntry$lambda$slambda_6($tmp19_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapEntry$slambda_7($outlineRect, resultContinuation) {
    this.ckj_1 = $outlineRect;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$slambda_7).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$slambda_7).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapEntry$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          alpha(this.ckj_1, 0.0);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapEntry$slambda_7).yak = function (it, completion) {
    var i = new UIMapEntry$slambda_7(this.ckj_1, completion);
    i.dkj_1 = it;
    return i;
  };
  function UIMapEntry$slambda_8($outlineRect, resultContinuation) {
    var i = new UIMapEntry$slambda_7($outlineRect, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapEntry$lambda$slambda_7($tmp21_onOut, $it, resultContinuation) {
    this.mkj_1 = $tmp21_onOut;
    this.nkj_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapEntry$lambda$slambda_7).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapEntry$lambda$slambda_7).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapEntry$lambda$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.mkj_1(this.nkj_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapEntry$lambda$slambda_7).g1p = function (completion) {
    var i = new UIMapEntry$lambda$slambda_7(this.mkj_1, this.nkj_1, completion);
    return i;
  };
  function UIMapEntry$lambda$slambda_8($tmp21_onOut, $it, resultContinuation) {
    var i = new UIMapEntry$lambda$slambda_7($tmp21_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapEntry$lambda_3($tmp1_safe_receiver, $tmp21_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapEntry$lambda$slambda_8($tmp21_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapEntry(gameMap, _width, _height, _mapSectionWidthRatio, _buttonSectionWidthRatio) {
    Companion_getInstance_37();
    _width = _width === VOID ? 200.0 : _width;
    _height = _height === VOID ? 125.0 : _height;
    _mapSectionWidthRatio = _mapSectionWidthRatio === VOID ? 0.75 : _mapSectionWidthRatio;
    _buttonSectionWidthRatio = _buttonSectionWidthRatio === VOID ? 0.25 : _buttonSectionWidthRatio;
    Container.call(this);
    this.uio_1 = gameMap;
    this.vio_1 = _width * _mapSectionWidthRatio;
    this.wio_1 = 5.0;
    this.xio_1 = _width * _buttonSectionWidthRatio;
    this.yio_1 = 3.0;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'kotlin.math.min' call
    var tmp0_min = this.wio_1;
    var tmp1_min = this.yio_1;
    tmp$ret$0 = Math.min(tmp0_min, tmp1_min);
    tmp.zio_1 = tmp$ret$0;
    this.bip_1 = new Signal();
    this.cip_1 = new Signal();
    this.dip_1 = new Signal();
    var tmp_0 = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.korge.ui.uiMapBox' call
    var tmp0_uiMapBox = this.uio_1;
    var tmp1_uiMapBox = this.vio_1;
    tmp$ret$1 = addTo(new UIMapBox(tmp0_uiMapBox, numberToDouble(tmp1_uiMapBox), numberToDouble(_height), 25.0), this);
    tmp_0.aip_1 = tmp$ret$1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp3_onClick = this.aip_1;
    var tmp4_onClick = UIMapEntry$slambda_0(this, null);
    var tmp$ret$4;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp2_doMouseEvent = click$factory_23();
    var tmp0_safe_receiver = tmp3_onClick;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$3;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$2;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_1 = tmp2_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$2 = tmp_1.f1q(UIMapEntry$lambda(tmp1_safe_receiver, tmp4_onClick));
      tmp$ret$3 = tmp$ret$2;
    }
    tmp$ret$4 = tmp3_onClick;
    tmp$ret$5 = tmp$ret$4;
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp7_solidRect = this.xio_1;
    var tmp8_solidRect = Colors_getInstance().b4x_1;
    var tmp$ret$8;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo = new SolidRect(Size_init_$Create$_0(tmp7_solidRect, _height), tmp8_solidRect);
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    var tmp5_apply = addTo(tmp6_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$6 = Unit_getInstance();
    tmp$ret$7 = tmp5_apply;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var buttonsSection = tmp$ret$9;
    alignLeftToRightOf(buttonsSection, this.aip_1);
    var buttonStackWidth = this.xio_1 - this.yio_1 * 2;
    var buttonPaddingHeight = 5.0;
    var buttonHeight = (_height - buttonPaddingHeight * 3) / 3;
    var tmp$ret$26;
    // Inline function 'korlibs.korge.ui.uiVerticalStack' call
    var tmp$ret$25;
    // Inline function 'kotlin.apply' call
    var tmp9_apply = addTo(new UIVerticalStack(buttonStackWidth, buttonPaddingHeight, true), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapEntry.<anonymous>' call
    var tmp$ret$15;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton = size(buttonStackWidth, buttonHeight);
    var tmp$ret$14;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(new UIButton(tmp1_uiButton, 'Play', null), tmp9_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapEntry.<anonymous>.<anonymous>' call
    var tmp$ret$13;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick = UIMapEntry$slambda_2(this, null);
    var tmp$ret$12;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent = click$factory_24();
    var tmp0_safe_receiver_0 = tmp0_apply;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$11;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$10;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_2 = tmp0_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$10 = tmp_2.f1q(UIMapEntry$lambda_0(tmp1_safe_receiver_0, tmp1_onClick));
      tmp$ret$11 = tmp$ret$10;
    }
    tmp$ret$12 = tmp0_apply;
    tmp$ret$13 = tmp$ret$12;
    tmp$ret$14 = tmp0_apply;
    tmp$ret$15 = tmp$ret$14;
    var playButton = tmp$ret$15;
    var tmp$ret$21;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp3_uiButton = size(buttonStackWidth, buttonHeight);
    var tmp$ret$20;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(new UIButton(tmp3_uiButton, 'Save', null), tmp9_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapEntry.<anonymous>.<anonymous>' call
    var tmp$ret$19;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_0 = UIMapEntry$slambda_4(this, null);
    var tmp$ret$18;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_0 = click$factory_25();
    var tmp0_safe_receiver_1 = tmp2_apply;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$17;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$16;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_3 = tmp0_doMouseEvent_0.get(tmp1_safe_receiver_1);
      tmp$ret$16 = tmp_3.f1q(UIMapEntry$lambda_1(tmp1_safe_receiver_1, tmp1_onClick_0));
      tmp$ret$17 = tmp$ret$16;
    }
    tmp$ret$18 = tmp2_apply;
    tmp$ret$19 = tmp$ret$18;
    tmp$ret$20 = tmp2_apply;
    tmp$ret$21 = tmp$ret$20;
    var saveButton = tmp$ret$21;
    var tmp$ret$24;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp5_uiButton = size(buttonStackWidth, buttonHeight);
    var tmp$ret$23;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(new UIButton(tmp5_uiButton, 'Hide', null), tmp9_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$22;
    // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
    tmp$ret$22 = Unit_getInstance();
    tmp$ret$23 = tmp4_apply;
    tmp$ret$24 = tmp$ret$23;
    var deleteButton = tmp$ret$24;
    tmp$ret$25 = tmp9_apply;
    tmp$ret$26 = tmp$ret$25;
    var buttonStack = tmp$ret$26;
    var tmp$ret$31;
    // Inline function 'kotlin.apply' call
    var tmp$ret$30;
    // Inline function 'korlibs.korge.view.roundRect' call
    var tmp12_roundRect = size(_width, _height);
    var tmp13_roundRect = _RectCorners___init__impl__w373y9(0.0, 0.0);
    var tmp14_roundRect = Colors_getInstance().q4s_1;
    var tmp15_roundRect = Colors_getInstance().a4y_1;
    var tmp16_roundRect = this.zio_1;
    var tmp$ret$29;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp11_addTo = new RoundRect(tmp12_roundRect, tmp13_roundRect, new RGBA(tmp14_roundRect), new RGBA(tmp15_roundRect), tmp16_roundRect, true);
    var tmp$ret$28;
    // Inline function 'kotlin.apply' call
    var tmp10_apply = addTo(tmp11_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.roundRect.<anonymous>' call
    tmp$ret$27 = Unit_getInstance();
    tmp$ret$28 = tmp10_apply;
    tmp$ret$29 = tmp$ret$28;
    tmp$ret$30 = tmp$ret$29;
    var tmp17_apply = tmp$ret$30;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapEntry.<anonymous>' call
    alpha(tmp17_apply, 0.0);
    tmp$ret$31 = tmp17_apply;
    var outlineRect = tmp$ret$31;
    alignTopToTopOf(buttonStack, buttonsSection);
    centerOn(buttonStack, buttonsSection);
    var tmp$ret$35;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp19_onOver = UIMapEntry$slambda_6(outlineRect, null);
    var tmp$ret$34;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp18_doMouseEvent = over$factory_5();
    var tmp0_safe_receiver_2 = this;
    var tmp1_safe_receiver_2 = tmp0_safe_receiver_2 == null ? null : get_mouse(tmp0_safe_receiver_2);
    if (tmp1_safe_receiver_2 == null)
      null;
    else {
      var tmp$ret$33;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$32;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_4 = tmp18_doMouseEvent.get(tmp1_safe_receiver_2);
      tmp$ret$32 = tmp_4.f1q(UIMapEntry$lambda_2(tmp1_safe_receiver_2, tmp19_onOver));
      tmp$ret$33 = tmp$ret$32;
    }
    tmp$ret$34 = this;
    tmp$ret$35 = tmp$ret$34;
    var tmp$ret$39;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp21_onOut = UIMapEntry$slambda_8(outlineRect, null);
    var tmp$ret$38;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp20_doMouseEvent = out$factory_4();
    var tmp0_safe_receiver_3 = this;
    var tmp1_safe_receiver_3 = tmp0_safe_receiver_3 == null ? null : get_mouse(tmp0_safe_receiver_3);
    if (tmp1_safe_receiver_3 == null)
      null;
    else {
      var tmp$ret$37;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$36;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_5 = tmp20_doMouseEvent.get(tmp1_safe_receiver_3);
      tmp$ret$36 = tmp_5.f1q(UIMapEntry$lambda_3(tmp1_safe_receiver_3, tmp21_onOut));
      tmp$ret$37 = tmp$ret$36;
    }
    tmp$ret$38 = this;
    tmp$ret$39 = tmp$ret$38;
  }
  function click$factory_23() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_24() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_25() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function over$factory_5() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_4() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function UIMapInspector$setMap$lambda(it) {
    return alignTopToBottomOf(it.n(1), it.n(0));
  }
  function UIMapInspector(inspectorHeight) {
    inspectorHeight = inspectorHeight === VOID ? 430.0 : inspectorHeight;
    Container.call(this);
    this.xil_1 = inspectorHeight;
    this.yil_1 = 250.0;
    this.zil_1 = 5.0;
    this.aim_1 = 5.0;
    this.bim_1 = 5.0;
    this.cim_1 = this.yil_1 - this.bim_1 * 2;
    this.dim_1 = 175.0;
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = this.yil_1;
    var tmp3_solidRect = this.xil_1;
    var tmp4_solidRect = MaterialColors_getInstance().n5g_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$_0(tmp2_solidRect, tmp3_solidRect), tmp4_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.eim_1 = tmp$ret$3;
    var tmp_0 = this;
    var tmp$ret$8;
    // Inline function 'kotlin.apply' call
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect_0 = this.cim_1;
    var tmp3_solidRect_0 = this.dim_1;
    var tmp4_solidRect_0 = MaterialColors_getInstance().k5g_1;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new SolidRect(Size_init_$Create$_0(tmp2_solidRect_0, tmp3_solidRect_0), tmp4_solidRect_0);
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$4 = Unit_getInstance();
    tmp$ret$5 = tmp0_apply_0;
    tmp$ret$6 = tmp$ret$5;
    tmp$ret$7 = tmp$ret$6;
    var tmp5_apply = tmp$ret$7;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.mapBoxArea.<anonymous>' call
    centerXOn(tmp5_apply, this.eim_1);
    alignTopToTopOf(tmp5_apply, this.eim_1, this.zil_1);
    tmp$ret$8 = tmp5_apply;
    tmp_0.fim_1 = tmp$ret$8;
    var tmp_1 = this;
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_6().odd_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = new Text('No map selected', tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$9;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_1, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.noMapSelectedText.<anonymous>' call
    centerOn(tmp0_apply_1, this.fim_1);
    tmp$ret$9 = tmp0_apply_1;
    tmp$ret$10 = tmp$ret$9;
    tmp$ret$11 = tmp$ret$10;
    tmp_1.gim_1 = tmp$ret$11;
    var tmp_2 = this;
    var tmp$ret$15;
    // Inline function 'korlibs.korge.ui.uiScrollable' call
    var tmp2_uiScrollable = Size_init_$Create$_0(this.cim_1, 200.0);
    var tmp$ret$14;
    // Inline function 'kotlin.also' call
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_2 = addTo(new UIScrollable(tmp2_uiScrollable, true), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.content.<anonymous>' call
    tmp0_apply_2.ed6_1 = Colors_getInstance().q4s_1;
    tmp$ret$12 = tmp0_apply_2;
    var tmp1_also = tmp$ret$12;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    var tmp$ret$13;
    // Inline function 'korlibs.korge.ui.uiScrollable.<anonymous>' call
    var tmp3__anonymous__ufb84q = tmp1_also.pd5_1;
    tmp$ret$13 = Unit_getInstance();
    tmp$ret$14 = tmp1_also;
    tmp$ret$15 = tmp$ret$14;
    tmp_2.iim_1 = tmp$ret$15;
    var SECTION_BUTTONS_PADDING = 10.0;
    var SECTION_BUTTONS_HEIGHT = 25.0;
    var gameMap = Companion_getInstance_22().z6e(10, 10);
    var tmp_3 = this;
    var tmp$ret$19;
    // Inline function 'kotlin.apply' call
    var tmp$ret$16;
    // Inline function 'com.xenotactic.korge.ui.uiMapBox' call
    var tmp0_uiMapBox = this.cim_1;
    var tmp1_uiMapBox = this.dim_1;
    tmp$ret$16 = addTo(new UIMapBox(gameMap, numberToDouble(tmp0_uiMapBox), numberToDouble(tmp1_uiMapBox), 25.0), this);
    var tmp2_apply = tmp$ret$16;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.<anonymous>' call
    centerXOn(tmp2_apply, this.eim_1);
    alignTopToTopOf(tmp2_apply, this.eim_1, this.zil_1);
    var tmp$ret$18;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$17;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp2_apply.u96(false);
    tmp$ret$17 = tmp2_apply;
    tmp$ret$18 = tmp$ret$17;
    tmp$ret$19 = tmp2_apply;
    tmp_3.him_1 = tmp$ret$19;
    var sectionButtonWidth = (this.yil_1 - this.bim_1 * 2 - SECTION_BUTTONS_PADDING) / 2;
    var tmp$ret$28;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new Container(false);
    var tmp$ret$26;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.<anonymous>' call
    var tmp$ret$22;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton = size(sectionButtonWidth, SECTION_BUTTONS_HEIGHT);
    var tmp$ret$21;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_3 = addTo(new UIButton(tmp1_uiButton, 'Details', null), tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$20;
    // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
    tmp$ret$20 = Unit_getInstance();
    tmp$ret$21 = tmp0_apply_3;
    tmp$ret$22 = tmp$ret$21;
    var sectionButton1 = tmp$ret$22;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp3_uiButton = size(sectionButtonWidth, SECTION_BUTTONS_HEIGHT);
    var tmp$ret$24;
    // Inline function 'kotlin.apply' call
    var tmp2_apply_0 = addTo(new UIButton(tmp3_uiButton, 'Scores', null), tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$23;
    // Inline function 'korlibs.korge.ui.uiButton.<anonymous>' call
    tmp$ret$23 = Unit_getInstance();
    tmp$ret$24 = tmp2_apply_0;
    tmp$ret$25 = tmp$ret$24;
    var sectionButton2 = tmp$ret$25;
    alignLeftToRightOf(sectionButton2, sectionButton1, SECTION_BUTTONS_PADDING);
    tmp$ret$26 = tmp3_apply;
    tmp$ret$27 = tmp$ret$26;
    tmp$ret$28 = tmp$ret$27;
    var sectionButtonsContainer = tmp$ret$28;
    centerXOn(sectionButtonsContainer, this.eim_1);
    alignTopToBottomOf(sectionButtonsContainer, this.okj(), this.zil_1);
    centerXOn(this.iim_1, this.eim_1);
    alignTopToBottomOf(this.iim_1, sectionButtonsContainer, this.zil_1);
    var tmp_4 = this;
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text_0 = Colors_getInstance().k4s_1;
    var tmp8_text = get_DefaultTtfFontAsBitmap();
    var tmp9_text = Companion_getInstance_5().g5r_1;
    var tmp10_text = get_DefaultStringTextRenderer();
    var tmp11_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$32;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo = new Text('Map ID: asdfasdfasdfasdfsdf', 10.0, tmp7_text_0, tmp8_text, tmp9_text, tmp10_text, tmp11_text, null, null);
    var tmp$ret$31;
    // Inline function 'kotlin.apply' call
    var tmp5_apply_0 = addTo(tmp6_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.<anonymous>' call
    alignLeftToLeftOf_1(tmp5_apply_0, this.eim_1, this.bim_1);
    alignBottomToBottomOf(tmp5_apply_0, this.eim_1, this.aim_1);
    var tmp$ret$30;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$29;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp5_apply_0.u96(false);
    tmp$ret$29 = tmp5_apply_0;
    tmp$ret$30 = tmp$ret$29;
    tmp$ret$31 = tmp5_apply_0;
    tmp$ret$32 = tmp$ret$31;
    tmp$ret$33 = tmp$ret$32;
    tmp_4.jim_1 = tmp$ret$33;
  }
  protoOf(UIMapInspector).okj = function () {
    var tmp = this.him_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('mapSection');
    }
  };
  protoOf(UIMapInspector).pkj = function () {
    var tmp = this.jim_1;
    if (!(tmp == null))
      return tmp;
    else {
      throwUninitializedPropertyAccessException('mapIdText');
    }
  };
  protoOf(UIMapInspector).kim = function (gameMap) {
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = this.okj();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.setMap.<anonymous>' call
    tmp0_apply.qkf(gameMap, true);
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_apply.u96(true);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp0_apply;
    this.gim_1.u96(false);
    this.iim_1.ibk();
    var tmp$ret$34;
    // Inline function 'kotlin.apply' call
    var tmp1_apply = this.iim_1;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.setMap.<anonymous>' call
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$32;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$31;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo, tmp1_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapInspector.setMap.<anonymous>.<anonymous>' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_6().odd_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text('Map Settings', tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_0, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$3 = Unit_getInstance();
    tmp$ret$4 = tmp0_apply_1;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    var tmp = tmp$ret$6;
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.text' call
    var tmp10_text = '- Width: ' + new GameUnit(gameMap.bg9_1);
    var tmp11_text = Companion_getInstance_6().odd_1;
    var tmp12_text = Colors_getInstance().k4s_1;
    var tmp13_text = get_DefaultTtfFontAsBitmap();
    var tmp14_text = Companion_getInstance_5().g5r_1;
    var tmp15_text = get_DefaultStringTextRenderer();
    var tmp16_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp9_addTo = new Text(tmp10_text, tmp11_text, tmp12_text, tmp13_text, tmp14_text, tmp15_text, tmp16_text, null, null);
    var tmp$ret$8;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(tmp9_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$7;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$7 = Unit_getInstance();
    tmp$ret$8 = tmp8_apply;
    tmp$ret$9 = tmp$ret$8;
    tmp$ret$10 = tmp$ret$9;
    var tmp_0 = tmp$ret$10;
    var tmp$ret$14;
    // Inline function 'korlibs.korge.view.text' call
    var tmp19_text = '- Height: ' + new GameUnit(gameMap.cg9_1);
    var tmp20_text = Companion_getInstance_6().odd_1;
    var tmp21_text = Colors_getInstance().k4s_1;
    var tmp22_text = get_DefaultTtfFontAsBitmap();
    var tmp23_text = Companion_getInstance_5().g5r_1;
    var tmp24_text = get_DefaultStringTextRenderer();
    var tmp25_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$13;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp18_addTo = new Text(tmp19_text, tmp20_text, tmp21_text, tmp22_text, tmp23_text, tmp24_text, tmp25_text, null, null);
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp17_apply = addTo(tmp18_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$11 = Unit_getInstance();
    tmp$ret$12 = tmp17_apply;
    tmp$ret$13 = tmp$ret$12;
    tmp$ret$14 = tmp$ret$13;
    var tmp_1 = tmp$ret$14;
    var tmp$ret$18;
    // Inline function 'korlibs.korge.view.text' call
    var tmp28_text = '- # Checkpoints: ' + gameMap.sg9();
    var tmp29_text = Companion_getInstance_6().odd_1;
    var tmp30_text = Colors_getInstance().k4s_1;
    var tmp31_text = get_DefaultTtfFontAsBitmap();
    var tmp32_text = Companion_getInstance_5().g5r_1;
    var tmp33_text = get_DefaultStringTextRenderer();
    var tmp34_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$17;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp27_addTo = new Text(tmp28_text, tmp29_text, tmp30_text, tmp31_text, tmp32_text, tmp33_text, tmp34_text, null, null);
    var tmp$ret$16;
    // Inline function 'kotlin.apply' call
    var tmp26_apply = addTo(tmp27_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$15;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$15 = Unit_getInstance();
    tmp$ret$16 = tmp26_apply;
    tmp$ret$17 = tmp$ret$16;
    tmp$ret$18 = tmp$ret$17;
    var tmp_2 = tmp$ret$18;
    var tmp$ret$22;
    // Inline function 'korlibs.korge.view.text' call
    var tmp37_text = '- # Teleports: ' + gameMap.tg9();
    var tmp38_text = Companion_getInstance_6().odd_1;
    var tmp39_text = Colors_getInstance().k4s_1;
    var tmp40_text = get_DefaultTtfFontAsBitmap();
    var tmp41_text = Companion_getInstance_5().g5r_1;
    var tmp42_text = get_DefaultStringTextRenderer();
    var tmp43_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$21;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp36_addTo = new Text(tmp37_text, tmp38_text, tmp39_text, tmp40_text, tmp41_text, tmp42_text, tmp43_text, null, null);
    var tmp$ret$20;
    // Inline function 'kotlin.apply' call
    var tmp35_apply = addTo(tmp36_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$19;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$19 = Unit_getInstance();
    tmp$ret$20 = tmp35_apply;
    tmp$ret$21 = tmp$ret$20;
    tmp$ret$22 = tmp$ret$21;
    var tmp_3 = tmp$ret$22;
    var tmp$ret$26;
    // Inline function 'korlibs.korge.view.text' call
    var tmp46_text = '- # Rocks: ' + gameMap.ug9();
    var tmp47_text = Companion_getInstance_6().odd_1;
    var tmp48_text = Colors_getInstance().k4s_1;
    var tmp49_text = get_DefaultTtfFontAsBitmap();
    var tmp50_text = Companion_getInstance_5().g5r_1;
    var tmp51_text = get_DefaultStringTextRenderer();
    var tmp52_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp45_addTo = new Text(tmp46_text, tmp47_text, tmp48_text, tmp49_text, tmp50_text, tmp51_text, tmp52_text, null, null);
    var tmp$ret$24;
    // Inline function 'kotlin.apply' call
    var tmp44_apply = addTo(tmp45_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$23;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$23 = Unit_getInstance();
    tmp$ret$24 = tmp44_apply;
    tmp$ret$25 = tmp$ret$24;
    tmp$ret$26 = tmp$ret$25;
    var tmp_4 = tmp$ret$26;
    var tmp$ret$30;
    // Inline function 'korlibs.korge.view.text' call
    var tmp55_text = '- # Speed Areas: ' + gameMap.vg9();
    var tmp56_text = Companion_getInstance_6().odd_1;
    var tmp57_text = Colors_getInstance().k4s_1;
    var tmp58_text = get_DefaultTtfFontAsBitmap();
    var tmp59_text = Companion_getInstance_5().g5r_1;
    var tmp60_text = get_DefaultStringTextRenderer();
    var tmp61_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$29;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp54_addTo = new Text(tmp55_text, tmp56_text, tmp57_text, tmp58_text, tmp59_text, tmp60_text, tmp61_text, null, null);
    var tmp$ret$28;
    // Inline function 'kotlin.apply' call
    var tmp53_apply = addTo(tmp54_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$27 = Unit_getInstance();
    tmp$ret$28 = tmp53_apply;
    tmp$ret$29 = tmp$ret$28;
    tmp$ret$30 = tmp$ret$29;
    var texts = mutableListOf([tmp, tmp_0, tmp_1, tmp_2, tmp_3, tmp_4, tmp$ret$30]);
    windowed(texts, 2, VOID, VOID, UIMapInspector$setMap$lambda);
    tmp$ret$31 = tmp0_apply_0;
    tmp$ret$32 = tmp$ret$31;
    tmp$ret$33 = tmp$ret$32;
    var mapSettingsSection = tmp$ret$33;
    tmp$ret$34 = tmp1_apply;
    this.pkj().fbu('Map ID: ' + MapToId_getInstance().vgv(gameMap));
    this.pkj().u96(true);
  };
  function UIMapOverlayOutsideClickedEvent() {
    UIMapOverlayOutsideClickedEvent_instance = this;
  }
  var UIMapOverlayOutsideClickedEvent_instance;
  function UIMapOverlayOutsideClickedEvent_getInstance() {
    if (UIMapOverlayOutsideClickedEvent_instance == null)
      new UIMapOverlayOutsideClickedEvent();
    return UIMapOverlayOutsideClickedEvent_instance;
  }
  function Companion_12() {
    Companion_instance_12 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIMapOverlay).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.qkj_1 = tmp$ret$0;
  }
  var Companion_instance_12;
  function Companion_getInstance_38() {
    if (Companion_instance_12 == null)
      new Companion_12();
    return Companion_instance_12;
  }
  function UIMapOverlay$setOverlay$slambda(this$0, resultContinuation) {
    this.zkj_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapOverlay$setOverlay$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapOverlay$setOverlay$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapOverlay$setOverlay$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_info = Companion_getInstance_38().qkj_1;
          var tmp0_log = Level_INFO_getInstance();
          if (tmp1_info.q1n(tmp0_log)) {
            tmp1_info.r1n(tmp0_log, 'background clicked!');
          }
          this.zkj_1.tig_1.dgo_1.bg7(UIMapOverlayOutsideClickedEvent_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapOverlay$setOverlay$slambda).yak = function (it, completion) {
    var i = new UIMapOverlay$setOverlay$slambda(this.zkj_1, completion);
    i.akk_1 = it;
    return i;
  };
  function UIMapOverlay$setOverlay$slambda_0(this$0, resultContinuation) {
    var i = new UIMapOverlay$setOverlay$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapOverlay$setOverlay$lambda$slambda($tmp6_onClick, $it, resultContinuation) {
    this.jkk_1 = $tmp6_onClick;
    this.kkk_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapOverlay$setOverlay$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.jkk_1(this.kkk_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda).g1p = function (completion) {
    var i = new UIMapOverlay$setOverlay$lambda$slambda(this.jkk_1, this.kkk_1, completion);
    return i;
  };
  function UIMapOverlay$setOverlay$lambda$slambda_0($tmp6_onClick, $it, resultContinuation) {
    var i = new UIMapOverlay$setOverlay$lambda$slambda($tmp6_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapOverlay$setOverlay$lambda($tmp1_safe_receiver, $tmp6_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapOverlay$setOverlay$lambda$slambda_0($tmp6_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapOverlay$setOverlay$slambda_1(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapOverlay$setOverlay$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapOverlay$setOverlay$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapOverlay$setOverlay$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapOverlay$setOverlay$slambda_1).yak = function (it, completion) {
    var i = new UIMapOverlay$setOverlay$slambda_1(completion);
    i.tkk_1 = it;
    return i;
  };
  function UIMapOverlay$setOverlay$slambda_2(resultContinuation) {
    var i = new UIMapOverlay$setOverlay$slambda_1(resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapOverlay$setOverlay$lambda$slambda_1($tmp8_onClick, $it, resultContinuation) {
    this.ckl_1 = $tmp8_onClick;
    this.dkl_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapOverlay$setOverlay$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ckl_1(this.dkl_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIMapOverlay$setOverlay$lambda$slambda_1).g1p = function (completion) {
    var i = new UIMapOverlay$setOverlay$lambda$slambda_1(this.ckl_1, this.dkl_1, completion);
    return i;
  };
  function UIMapOverlay$setOverlay$lambda$slambda_2($tmp8_onClick, $it, resultContinuation) {
    var i = new UIMapOverlay$setOverlay$lambda$slambda_1($tmp8_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIMapOverlay$setOverlay$lambda_0($tmp1_safe_receiver, $tmp8_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIMapOverlay$setOverlay$lambda$slambda_2($tmp8_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIMapOverlay(engine) {
    Companion_getInstance_38();
    Container.call(this);
    this.tig_1 = engine;
    this.uig_1 = false;
  }
  protoOf(UIMapOverlay).vig = function (view) {
    if (this.uig_1)
      return Unit_getInstance();
    var globalAreaDimensions = getVisibleGlobalArea(this);
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = globalAreaDimensions.c41();
    var tmp3_solidRect = globalAreaDimensions.d41();
    var tmp4_solidRect = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, 0.7);
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$_0(tmp2_solidRect, tmp3_solidRect), tmp4_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var background = tmp$ret$3;
    var tmp$ret$7;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp6_onClick = UIMapOverlay$setOverlay$slambda_0(this, null);
    var tmp$ret$6;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp5_doMouseEvent = click$factory_26();
    var tmp0_safe_receiver = background;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$5;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$4;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp = tmp5_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$4 = tmp.f1q(UIMapOverlay$setOverlay$lambda(tmp1_safe_receiver, tmp6_onClick));
      tmp$ret$5 = tmp$ret$4;
    }
    tmp$ret$6 = background;
    tmp$ret$7 = tmp$ret$6;
    scaleWhileMaintainingAspect(view, new ByWidthAndHeight(globalAreaDimensions.c41(), globalAreaDimensions.d41()));
    centerOn(view, background);
    addTo(view, this);
    var tmp$ret$11;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp8_onClick = UIMapOverlay$setOverlay$slambda_2(null);
    var tmp$ret$10;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp7_doMouseEvent = click$factory_27();
    var tmp0_safe_receiver_0 = view;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_0 = tmp7_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$8 = tmp_0.f1q(UIMapOverlay$setOverlay$lambda_0(tmp1_safe_receiver_0, tmp8_onClick));
      tmp$ret$9 = tmp$ret$8;
    }
    tmp$ret$10 = view;
    tmp$ret$11 = tmp$ret$10;
    var cameraInputProcessor = new CameraInputProcessor(view, this.tig_1);
    cameraInputProcessor.bhb(this);
    this.uig_1 = true;
  };
  protoOf(UIMapOverlay).iih = function () {
    this.r7c();
    this.ibk();
    this.uig_1 = false;
  };
  function click$factory_26() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_27() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function UIMapSettingsV2(gridSize, borderRatio, gridLinesRatio, gridNumbersRatio, pathLinesRatio, drawGridNumbers, boardType, drawGridLines) {
    gridSize = gridSize === VOID ? get_GRID_SIZE() : gridSize;
    borderRatio = borderRatio === VOID ? get_BORDER_RATIO() : borderRatio;
    gridLinesRatio = gridLinesRatio === VOID ? get_GRID_LINES_RATIO() : gridLinesRatio;
    gridNumbersRatio = gridNumbersRatio === VOID ? get_GRID_NUMBERS_RATIO() : gridNumbersRatio;
    pathLinesRatio = pathLinesRatio === VOID ? get_PATH_LINES_RATIO() : pathLinesRatio;
    drawGridNumbers = drawGridNumbers === VOID ? true : drawGridNumbers;
    boardType = boardType === VOID ? BoardType_CHECKERED_2X2_getInstance() : boardType;
    drawGridLines = drawGridLines === VOID ? true : drawGridLines;
    this.ekl_1 = gridSize;
    this.fkl_1 = borderRatio;
    this.gkl_1 = gridLinesRatio;
    this.hkl_1 = gridNumbersRatio;
    this.ikl_1 = pathLinesRatio;
    this.jkl_1 = drawGridNumbers;
    this.kkl_1 = boardType;
    this.lkl_1 = drawGridLines;
    this.mkl_1 = this.ekl_1 * this.fkl_1;
    this.nkl_1 = this.ekl_1 * this.gkl_1;
    this.okl_1 = this.ekl_1 * this.hkl_1;
    this.pkl_1 = this.ekl_1 * this.ikl_1;
  }
  protoOf(UIMapSettingsV2).toString = function () {
    return 'UIMapSettingsV2(gridSize=' + this.ekl_1 + ', borderRatio=' + this.fkl_1 + ', gridLinesRatio=' + this.gkl_1 + ', gridNumbersRatio=' + this.hkl_1 + ', pathLinesRatio=' + this.ikl_1 + ', drawGridNumbers=' + this.jkl_1 + ', boardType=' + this.kkl_1 + ', drawGridLines=' + this.lkl_1 + ')';
  };
  protoOf(UIMapSettingsV2).hashCode = function () {
    var result = getNumberHashCode(this.ekl_1);
    result = imul(result, 31) + getNumberHashCode(this.fkl_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.gkl_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.hkl_1) | 0;
    result = imul(result, 31) + getNumberHashCode(this.ikl_1) | 0;
    result = imul(result, 31) + (this.jkl_1 | 0) | 0;
    result = imul(result, 31) + this.kkl_1.hashCode() | 0;
    result = imul(result, 31) + (this.lkl_1 | 0) | 0;
    return result;
  };
  protoOf(UIMapSettingsV2).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof UIMapSettingsV2))
      return false;
    var tmp0_other_with_cast = other instanceof UIMapSettingsV2 ? other : THROW_CCE();
    if (!equals(this.ekl_1, tmp0_other_with_cast.ekl_1))
      return false;
    if (!equals(this.fkl_1, tmp0_other_with_cast.fkl_1))
      return false;
    if (!equals(this.gkl_1, tmp0_other_with_cast.gkl_1))
      return false;
    if (!equals(this.hkl_1, tmp0_other_with_cast.hkl_1))
      return false;
    if (!equals(this.ikl_1, tmp0_other_with_cast.ikl_1))
      return false;
    if (!(this.jkl_1 === tmp0_other_with_cast.jkl_1))
      return false;
    if (!this.kkl_1.equals(tmp0_other_with_cast.kkl_1))
      return false;
    if (!(this.lkl_1 === tmp0_other_with_cast.lkl_1))
      return false;
    return true;
  };
  function _get_gridNumberFontSize__q3mbx0($this) {
    return $this.jhg_1.okl_1;
  }
  function resetUIMap($this) {
    drawBoardV2($this);
    drawGridNumbers_0($this);
  }
  function drawBoardV2($this) {
    println('Drawing board');
    var tmp$ret$10;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var tmp1_updateShape = $this.phg_1;
    var result;
    var tmp$ret$9;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$8;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp0_subject = $this.jhg_1.kkl_1;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        var tmp$ret$3;
        // Inline function 'korlibs.korge.view.solidRect' call
        var tmp2_solidRect = $this.ohg_1;
        var tmp3_solidRect = _WorldUnit___get_value__impl__xwm9rd(GameUnit__toWorldUnit_impl_m803zs($this.vje(), $this.dhn()));
        var tmp4_solidRect = _WorldUnit___get_value__impl__xwm9rd(GameUnit__toWorldUnit_impl_m803zs($this.wje(), $this.dhn()));
        var tmp5_solidRect = MaterialColors_getInstance().h5c_1;
        var tmp$ret$2;
        // Inline function 'korlibs.korge.view.addTo' call
        var tmp1_addTo = new SolidRect(Size_init_$Create$_0(tmp3_solidRect, tmp4_solidRect), tmp5_solidRect);
        var tmp$ret$1;
        // Inline function 'kotlin.apply' call
        var tmp0_apply_0 = addTo(tmp1_addTo, tmp2_solidRect);
        // Inline function 'kotlin.contracts.contract' call
        var tmp$ret$0;
        // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
        tmp$ret$0 = Unit_getInstance();
        tmp$ret$1 = tmp0_apply_0;
        tmp$ret$2 = tmp$ret$1;
        tmp$ret$3 = tmp$ret$2;

        ;
        break;
      case 1:
        var altColorWidth = true;
        var tmp1_iterator = until(0, $this.vje()).h();
        while (tmp1_iterator.j()) {
          var i = tmp1_iterator.k().vg3_1;
          var altColorHeight = altColorWidth;
          var xGrid = GameUnit__toWorldUnit_impl_m803zs(i, $this.dhn());
          var tmp2_iterator = until(0, $this.wje()).h();
          while (tmp2_iterator.j()) {
            var j = tmp2_iterator.k().vg3_1;
            var currColor = altColorHeight ? MaterialColors_getInstance().h5c_1 : MaterialColors_getInstance().j5c_1;
            // Inline function 'korlibs.image.vector.Context2d.fillStyle' call
            var oldStyle = tmp0_apply.t5l();
            tmp0_apply.u5l(new RGBA(currColor));
            try {
              // Inline function 'com.xenotactic.korge.ui.UIMapV2.drawBoardV2.<anonymous>.<anonymous>' call
              var tmp$ret$4;
              // Inline function 'korlibs.image.vector.Context2d.fillRect' call
              var tmp0_fillRect = WorldUnit__toFloat_impl_2n0xxa(xGrid);
              var tmp1_fillRect = WorldUnit__toFloat_impl_2n0xxa(GameUnit__toWorldUnit_impl_m803zs(j, $this.dhn()));
              var tmp2_fillRect = $this.dhn();
              var tmp3_fillRect = $this.dhn();
              tmp0_apply.v5l(numberToDouble(tmp0_fillRect), numberToDouble(tmp1_fillRect), numberToDouble(tmp2_fillRect), numberToDouble(tmp3_fillRect));
              tmp$ret$4 = Unit_getInstance();
            }finally {
              tmp0_apply.u5l(oldStyle);
            }
            altColorHeight = !altColorHeight;
          }
          altColorWidth = !altColorWidth;
        }

        break;
      case 2:
        var altColorWidth_0 = true;
        var checkeredGridSize = $this.dhn() * 2;
        var inductionVariable = 0;
        var last = (GameUnit__toInt_impl_9qttgv($this.vje()) + 1 | 0) / 2 | 0;
        if (inductionVariable < last)
          do {
            var i_0 = inductionVariable;
            inductionVariable = inductionVariable + 1 | 0;
            var altColorHeight_0 = altColorWidth_0;
            var xGridPosition = i_0 * checkeredGridSize;
            var inductionVariable_0 = 0;
            var last_0 = (GameUnit__toInt_impl_9qttgv($this.wje()) + 1 | 0) / 2 | 0;
            if (inductionVariable_0 < last_0)
              do {
                var j_0 = inductionVariable_0;
                inductionVariable_0 = inductionVariable_0 + 1 | 0;
                var gridWidth = imul(i_0 + 1 | 0, 2) > _GameUnit___get_value__impl__9ocgox($this.vje()) ? $this.dhn() : checkeredGridSize;
                var gridHeight = imul(j_0 + 1 | 0, 2) > _GameUnit___get_value__impl__9ocgox($this.wje()) ? $this.dhn() : checkeredGridSize;
                var currColor_0 = altColorHeight_0 ? MaterialColors_getInstance().h5c_1 : MaterialColors_getInstance().j5c_1;
                // Inline function 'korlibs.image.vector.Context2d.fillStyle' call
                var oldStyle_0 = tmp0_apply.t5l();
                tmp0_apply.u5l(new RGBA(currColor_0));
                try {
                  // Inline function 'com.xenotactic.korge.ui.UIMapV2.drawBoardV2.<anonymous>.<anonymous>' call
                  var tmp$ret$5;
                  // Inline function 'korlibs.image.vector.Context2d.fillRect' call
                  var tmp0_fillRect_0 = j_0 * checkeredGridSize;
                  tmp0_apply.v5l(numberToDouble(xGridPosition), numberToDouble(tmp0_fillRect_0), numberToDouble(gridWidth), numberToDouble(gridHeight));
                  tmp$ret$5 = Unit_getInstance();
                }finally {
                  tmp0_apply.u5l(oldStyle_0);
                }
                altColorHeight_0 = !altColorHeight_0;
              }
               while (inductionVariable_0 < last_0);
            altColorWidth_0 = !altColorWidth_0;
          }
           while (inductionVariable < last);
        break;
    }
    var tmp;
    if ($this.jhg_1.lkl_1) {
      var alpha = 0.5;
      var strokeInfo = new StrokeInfo(1.0);
      var tmp5_iterator = until(0, $this.vje()).h();
      while (tmp5_iterator.j()) {
        var x = tmp5_iterator.k().vg3_1;
        var tmp$ret$6;
        $l$block: {
          // Inline function 'korlibs.image.vector.Context2d.stroke' call
          var tmp12_stroke = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, alpha);
          if (new RGBA(tmp12_stroke) == null ? true : strokeInfo == null) {
            tmp$ret$6 = Unit_getInstance();
            break $l$block;
          }
          // Inline function 'korlibs.image.vector.Context2d.stroke' call
          var tmp6_stroke = strokeInfo.j49_1;
          var tmp7_stroke = strokeInfo.m49_1;
          var tmp8_stroke = strokeInfo.o49_1;
          var tmp9_stroke = strokeInfo.p49_1;
          var tmp10_stroke = strokeInfo.q49_1;
          var tmp11_stroke = strokeInfo.r49_1;
          {
            tmp0_apply.a5s();
          }
          // Inline function 'com.xenotactic.korge.ui.UIMapV2.drawBoardV2.<anonymous>.<anonymous>' call
          tmp0_apply.z4k(Point(GameUnit__toWorldUnit_impl_m803zs(x, $this.dhn()), Companion_getInstance_23().mgw_1), Point(GameUnit__toWorldUnit_impl_m803zs(x, $this.dhn()), GameUnit__toWorldUnit_impl_m803zs($this.wje(), $this.dhn())));
          // Inline function 'korlibs.image.vector.Context2d.keep' call
          tmp0_apply.t5t();
          try {
            // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
            tmp0_apply.u5t(tmp6_stroke);
            tmp0_apply.v5t(tmp7_stroke);
            tmp0_apply.w5t(tmp8_stroke);
            tmp0_apply.x5t(tmp9_stroke);
            tmp0_apply.y5t(tmp10_stroke);
            tmp0_apply.z5t(tmp11_stroke);
            tmp0_apply.a5u(new RGBA(tmp12_stroke));
          }finally {
            tmp0_apply.b5u();
          }
        }
      }
      var tmp6_iterator = until(0, $this.wje()).h();
      while (tmp6_iterator.j()) {
        var y = tmp6_iterator.k().vg3_1;
        var tmp$ret$7;
        $l$block_0: {
          // Inline function 'korlibs.image.vector.Context2d.stroke' call
          var tmp19_stroke = RGBA__withAd_impl_cralao(Colors_getInstance().l4s_1, alpha);
          if (new RGBA(tmp19_stroke) == null ? true : strokeInfo == null) {
            tmp$ret$7 = Unit_getInstance();
            break $l$block_0;
          }
          // Inline function 'korlibs.image.vector.Context2d.stroke' call
          var tmp13_stroke = strokeInfo.j49_1;
          var tmp14_stroke = strokeInfo.m49_1;
          var tmp15_stroke = strokeInfo.o49_1;
          var tmp16_stroke = strokeInfo.p49_1;
          var tmp17_stroke = strokeInfo.q49_1;
          var tmp18_stroke = strokeInfo.r49_1;
          {
            tmp0_apply.a5s();
          }
          // Inline function 'com.xenotactic.korge.ui.UIMapV2.drawBoardV2.<anonymous>.<anonymous>' call
          tmp0_apply.z4k(Point(Companion_getInstance_23().mgw_1, GameUnit__toWorldUnit_impl_m803zs(y, $this.dhn())), Point(GameUnit__toWorldUnit_impl_m803zs($this.vje(), $this.dhn()), GameUnit__toWorldUnit_impl_m803zs(y, $this.dhn())));
          // Inline function 'korlibs.image.vector.Context2d.keep' call
          tmp0_apply.t5t();
          try {
            // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
            tmp0_apply.u5t(tmp13_stroke);
            tmp0_apply.v5t(tmp14_stroke);
            tmp0_apply.w5t(tmp15_stroke);
            tmp0_apply.x5t(tmp16_stroke);
            tmp0_apply.y5t(tmp17_stroke);
            tmp0_apply.z5t(tmp18_stroke);
            tmp0_apply.a5u(new RGBA(tmp19_stroke));
          }finally {
            tmp0_apply.b5u();
          }
        }
      }
      tmp = Unit_getInstance();
    }
    result = tmp;
    tmp$ret$8 = tmp0_apply;
    tmp$ret$9 = tmp$ret$8.o5q();
    tmp1_updateShape.zen(tmp$ret$9);
    tmp$ret$10 = result;
    println('Finished drawing board!');
  }
  function drawGridNumbers_0($this) {
    $this.qhg_1.ibk();
    if (!$this.jhg_1.jkl_1) {
      return Unit_getInstance();
    }
    var tmp0_iterator = until(0, $this.vje()).h();
    while (tmp0_iterator.j()) {
      var i = tmp0_iterator.k().vg3_1;
      var iNumString = GameUnit__toInt_impl_9qttgv(i).toString();
      var tmp$ret$3;
      // Inline function 'korlibs.korge.view.text' call
      var tmp2_text = $this.qhg_1;
      var tmp3_text = _get_gridNumberFontSize__q3mbx0($this);
      var tmp4_text = Companion_getInstance_5().w5r_1;
      var tmp5_text = Colors_getInstance().k4s_1;
      var tmp6_text = get_DefaultTtfFontAsBitmap();
      var tmp7_text = get_DefaultStringTextRenderer();
      var tmp8_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$2;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo = new Text(iNumString, tmp3_text, tmp5_text, tmp6_text, tmp4_text, tmp7_text, tmp8_text, null, null);
      var tmp$ret$1;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = addTo(tmp1_addTo, tmp2_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$0;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$0 = Unit_getInstance();
      tmp$ret$1 = tmp0_apply;
      tmp$ret$2 = tmp$ret$1;
      tmp$ret$3 = tmp$ret$2;
      xy_1(tmp$ret$3, GameUnit__toWorldUnit_impl_m803zs(i, $this.dhn()), toWorldUnit(0));
      var tmp$ret$7;
      // Inline function 'korlibs.korge.view.text' call
      var tmp11_text = $this.qhg_1;
      var tmp12_text = _get_gridNumberFontSize__q3mbx0($this);
      var tmp13_text = Colors_getInstance().k4s_1;
      var tmp14_text = get_DefaultTtfFontAsBitmap();
      var tmp15_text = Companion_getInstance_5().g5r_1;
      var tmp16_text = get_DefaultStringTextRenderer();
      var tmp17_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$6;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp10_addTo = new Text(iNumString, tmp12_text, tmp13_text, tmp14_text, tmp15_text, tmp16_text, tmp17_text, null, null);
      var tmp$ret$5;
      // Inline function 'kotlin.apply' call
      var tmp9_apply = addTo(tmp10_addTo, tmp11_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$4;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$4 = Unit_getInstance();
      tmp$ret$5 = tmp9_apply;
      tmp$ret$6 = tmp$ret$5;
      tmp$ret$7 = tmp$ret$6;
      xy_1(tmp$ret$7, GameUnit__toWorldUnit_impl_m803zs(i, $this.dhn()), GameUnit__toWorldUnit_impl_m803zs($this.wje(), $this.dhn()));
    }
    var tmp1_iterator = until(0, $this.wje()).h();
    while (tmp1_iterator.j()) {
      var j = tmp1_iterator.k().vg3_1;
      var jNumString = GameUnit__toInt_impl_9qttgv(j).toString();
      var tmp$ret$11;
      // Inline function 'korlibs.korge.view.text' call
      var tmp20_text = $this.qhg_1;
      var tmp21_text = _get_gridNumberFontSize__q3mbx0($this);
      var tmp22_text = Companion_getInstance_5().m5r_1;
      var tmp23_text = Colors_getInstance().k4s_1;
      var tmp24_text = get_DefaultTtfFontAsBitmap();
      var tmp25_text = get_DefaultStringTextRenderer();
      var tmp26_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$10;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp19_addTo = new Text(jNumString, tmp21_text, tmp23_text, tmp24_text, tmp22_text, tmp25_text, tmp26_text, null, null);
      var tmp$ret$9;
      // Inline function 'kotlin.apply' call
      var tmp18_apply = addTo(tmp19_addTo, tmp20_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$8 = Unit_getInstance();
      tmp$ret$9 = tmp18_apply;
      tmp$ret$10 = tmp$ret$9;
      tmp$ret$11 = tmp$ret$10;
      xy_1(tmp$ret$11, toWorldUnit(-10.0), WorldUnit__minus_impl_yejnmb_0(GameUnit__toWorldUnit_impl_m803zs($this.wje(), $this.dhn()), GameUnit__toWorldUnit_impl_m803zs(j, $this.dhn())));
      var tmp$ret$15;
      // Inline function 'korlibs.korge.view.text' call
      var tmp29_text = $this.qhg_1;
      var tmp30_text = _get_gridNumberFontSize__q3mbx0($this);
      var tmp31_text = Companion_getInstance_5().k5r_1;
      var tmp32_text = Colors_getInstance().k4s_1;
      var tmp33_text = get_DefaultTtfFontAsBitmap();
      var tmp34_text = get_DefaultStringTextRenderer();
      var tmp35_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$14;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp28_addTo = new Text(jNumString, tmp30_text, tmp32_text, tmp33_text, tmp31_text, tmp34_text, tmp35_text, null, null);
      var tmp$ret$13;
      // Inline function 'kotlin.apply' call
      var tmp27_apply = addTo(tmp28_addTo, tmp29_text);
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$12;
      // Inline function 'korlibs.korge.view.text.<anonymous>' call
      tmp$ret$12 = Unit_getInstance();
      tmp$ret$13 = tmp27_apply;
      tmp$ret$14 = tmp$ret$13;
      tmp$ret$15 = tmp$ret$14;
      xy_1(tmp$ret$15, WorldUnit__plus_impl_n1o1x9(GameUnit__toWorldUnit_impl_m803zs($this.vje(), $this.dhn()), toWorldUnit(10)), WorldUnit__minus_impl_yejnmb_0(GameUnit__toWorldUnit_impl_m803zs($this.wje(), $this.dhn()), GameUnit__toWorldUnit_impl_m803zs(j, $this.dhn())));
    }
  }
  function createEntityView_0($this, entity) {
    var entityType = entity.tfz(getKClass(EntityTypeComponent));
    var size = entity.tfz(getKClass(SizeComponent));
    var tmp = $this.dhn();
    var tmp_0 = $this.yhq();
    var tmp_1;
    if (entityType.lg4_1.equals(MapEntityType_SPEED_AREA_getInstance())) {
      tmp_1 = entity.tfz(getKClass(EntitySpeedAreaComponent)).hg4_1;
    } else {
      tmp_1 = null;
    }
    return new UIEntity(entityType.lg4_1, size.xg4_1, size.yg4_1, tmp, tmp_0, tmp_1);
  }
  function UIMapV2$gameMapDimensionsState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapDimensionsState);
  }
  function UIMapV2$gameMapPathState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapPathState);
  }
  function UIMapV2$slambda(this$0, resultContinuation) {
    this.ykl_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIMapV2$slambda).ght = function (it, $completion) {
    var tmp = this.hht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIMapV2$slambda).g8 = function (p1, $completion) {
    return this.ght(p1 instanceof UpdatedPathLineEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIMapV2$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ykl_1.iht(this.zkl_1.dg8_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIMapV2$slambda).hht = function (it, completion) {
    var i = new UIMapV2$slambda(this.ykl_1, completion);
    i.zkl_1 = it;
    return i;
  };
  function UIMapV2$slambda_0(this$0, resultContinuation) {
    var i = new UIMapV2$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ght(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIMapV2(engine, uiMapSettingsV2) {
    uiMapSettingsV2 = uiMapSettingsV2 === VOID ? new UIMapSettingsV2() : uiMapSettingsV2;
    Container.call(this);
    this.ihg_1 = engine;
    this.jhg_1 = uiMapSettingsV2;
    this.khg_1 = this.ihg_1.ego_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ihg_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapDimensionsState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapDimensionsState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIMapV2$gameMapDimensionsState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.lhg_1 = tmp$ret$1;
    this.mhg_1 = this.jhg_1.pkl_1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.ihg_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(GameMapPathState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof GameMapPathState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(UIMapV2$gameMapPathState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.nhg_1 = tmp$ret$3;
    var tmp_5 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2._boardLayer.<anonymous>' call
    tmp0_apply.y9r_1 = '_boardLayer';
    tmp$ret$4 = tmp0_apply;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp_5.ohg_1 = tmp$ret$6;
    var tmp_6 = this;
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.graphics' call
    var tmp1_graphics = GraphicsRenderer_SYSTEM_getInstance();
    var tmp$ret$10;
    // Inline function 'kotlin.also' call
    var tmp0_also = addTo(new Graphics(EmptyShape_getInstance(), tmp1_graphics), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result;
    var tmp$ret$8;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    this.y9r_1 = '_boardGraphicsLayer';
    result = Unit_getInstance();
    tmp$ret$7 = tmp0_apply_0;
    tmp$ret$8 = tmp$ret$7.o5q();
    tmp0_also.zen(tmp$ret$8);
    tmp$ret$9 = result;
    tmp0_also.geo();
    tmp$ret$10 = tmp0_also;
    tmp$ret$11 = tmp$ret$10;
    tmp_6.phg_1 = tmp$ret$11;
    var tmp_7 = this;
    var tmp$ret$14;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$13;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2._gridNumberLayer.<anonymous>' call
    tmp0_apply_1.y9r_1 = '_gridNumberLayer';
    tmp$ret$12 = tmp0_apply_1;
    tmp$ret$13 = tmp$ret$12;
    tmp$ret$14 = tmp$ret$13;
    tmp_7.qhg_1 = tmp$ret$14;
    var tmp_8 = this;
    var tmp$ret$17;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$16;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = new Container(false);
    var tmp$ret$15;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_2 = addTo(tmp1_addTo_1, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.speedAreaLayer.<anonymous>' call
    tmp0_apply_2.y9r_1 = 'speedAreaLayer';
    tmp$ret$15 = tmp0_apply_2;
    tmp$ret$16 = tmp$ret$15;
    tmp$ret$17 = tmp$ret$16;
    tmp_8.rhg_1 = tmp$ret$17;
    var tmp_9 = this;
    var tmp$ret$22;
    // Inline function 'korlibs.korge.view.graphics' call
    var tmp1_graphics_0 = GraphicsRenderer_SYSTEM_getInstance();
    var tmp$ret$21;
    // Inline function 'kotlin.also' call
    var tmp0_also_0 = addTo(new Graphics(EmptyShape_getInstance(), tmp1_graphics_0), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
    var tmp$ret$20;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result_0;
    var tmp$ret$19;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$18;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_3 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    this.y9r_1 = 'speedAreaLayerGraphics';
    result_0 = Unit_getInstance();
    tmp$ret$18 = tmp0_apply_3;
    tmp$ret$19 = tmp$ret$18.o5q();
    tmp0_also_0.zen(tmp$ret$19);
    tmp$ret$20 = result_0;
    tmp0_also_0.geo();
    tmp$ret$21 = tmp0_also_0;
    tmp$ret$22 = tmp$ret$21;
    tmp_9.shg_1 = tmp$ret$22;
    var tmp_10 = this;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$24;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_2 = new Container(false);
    var tmp$ret$23;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_4 = addTo(tmp1_addTo_2, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.entityLayer.<anonymous>' call
    tmp0_apply_4.y9r_1 = 'entityLayer';
    tmp$ret$23 = tmp0_apply_4;
    tmp$ret$24 = tmp$ret$23;
    tmp$ret$25 = tmp$ret$24;
    tmp_10.thg_1 = tmp$ret$25;
    var tmp_11 = this;
    var tmp$ret$28;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_3 = new Container(false);
    var tmp$ret$26;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_5 = addTo(tmp1_addTo_3, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.entityLabelLayer.<anonymous>' call
    tmp0_apply_5.y9r_1 = 'entityLabelLayer';
    tmp$ret$26 = tmp0_apply_5;
    tmp$ret$27 = tmp$ret$26;
    tmp$ret$28 = tmp$ret$27;
    tmp_11.uhg_1 = tmp$ret$28;
    var tmp_12 = this;
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$30;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_4 = new Container(false);
    var tmp$ret$29;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_6 = addTo(tmp1_addTo_4, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.rangeIndicatorLayer.<anonymous>' call
    tmp0_apply_6.y9r_1 = 'rangeIndicatorLayer';
    tmp$ret$29 = tmp0_apply_6;
    tmp$ret$30 = tmp$ret$29;
    tmp$ret$31 = tmp$ret$30;
    tmp_12.vhg_1 = tmp$ret$31;
    var tmp_13 = this;
    var tmp$ret$36;
    // Inline function 'korlibs.korge.view.vector.gpuGraphics' call
    var tmp$ret$35;
    // Inline function 'korlibs.korge.view.vector.gpuShapeView' call
    var tmp$ret$34;
    // Inline function 'kotlin.also' call
    var tmp0_also_1 = new GpuShapeView(EmptyShape_getInstance(), true);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
    var tmp$ret$33;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$32;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_7 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
    // Inline function 'korlibs.korge.view.vector.gpuShapeView.<anonymous>.<anonymous>' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2._pathingLinesGraphics.<anonymous>' call
    this.y9r_1 = '_pathingLinesGraphics';
    tmp$ret$32 = tmp0_apply_7;
    tmp$ret$33 = tmp$ret$32.o5q();
    tmp0_also_1.zen(tmp$ret$33);
    tmp$ret$34 = tmp0_also_1;
    tmp$ret$35 = addTo(tmp$ret$34, this);
    tmp$ret$36 = tmp$ret$35;
    tmp_13.whg_1 = tmp$ret$36;
    var tmp_14 = this;
    var tmp$ret$39;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$38;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_5 = new Container(false);
    var tmp$ret$37;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_8 = addTo(tmp1_addTo_5, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.monsterLayer.<anonymous>' call
    tmp0_apply_8.y9r_1 = 'monsterLayer';
    tmp$ret$37 = tmp0_apply_8;
    tmp$ret$38 = tmp$ret$37;
    tmp$ret$39 = tmp$ret$38;
    tmp_14.xhg_1 = tmp$ret$39;
    var tmp_15 = this;
    var tmp$ret$45;
    // Inline function 'korlibs.korge.view.graphics' call
    var tmp1_graphics_1 = GraphicsRenderer_SYSTEM_getInstance();
    var tmp$ret$44;
    // Inline function 'kotlin.also' call
    var tmp0_also_2 = addTo(new Graphics(EmptyShape_getInstance(), tmp1_graphics_1), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
    var tmp$ret$43;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result_1;
    var tmp$ret$42;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$41;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_9 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp$ret$40;
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.targetingLinesLayer.<anonymous>' call
    tmp$ret$40 = Unit_getInstance();
    result_1 = tmp$ret$40;
    tmp$ret$41 = tmp0_apply_9;
    tmp$ret$42 = tmp$ret$41.o5q();
    tmp0_also_2.zen(tmp$ret$42);
    tmp$ret$43 = result_1;
    tmp0_also_2.geo();
    tmp$ret$44 = tmp0_also_2;
    tmp$ret$45 = tmp$ret$44;
    tmp_15.yhg_1 = tmp$ret$45;
    var tmp_16 = this;
    var tmp$ret$51;
    // Inline function 'korlibs.korge.view.graphics' call
    var tmp1_graphics_2 = GraphicsRenderer_SYSTEM_getInstance();
    var tmp$ret$50;
    // Inline function 'kotlin.also' call
    var tmp0_also_3 = addTo(new Graphics(EmptyShape_getInstance(), tmp1_graphics_2), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.graphics.<anonymous>' call
    var tmp$ret$49;
    // Inline function 'korlibs.korge.view.Graphics.updateShape' call
    var result_2;
    var tmp$ret$48;
    // Inline function 'korlibs.image.vector.buildShape' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$47;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_10 = new ShapeBuilder(null, null);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.Graphics.updateShape.<anonymous>' call
    var tmp$ret$46;
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.projectileLayer.<anonymous>' call
    tmp$ret$46 = Unit_getInstance();
    result_2 = tmp$ret$46;
    tmp$ret$47 = tmp0_apply_10;
    tmp$ret$48 = tmp$ret$47.o5q();
    tmp0_also_3.zen(tmp$ret$48);
    tmp$ret$49 = result_2;
    tmp0_also_3.geo();
    tmp$ret$50 = tmp0_also_3;
    tmp$ret$51 = tmp$ret$50;
    tmp_16.zhg_1 = tmp$ret$51;
    var tmp_17 = this;
    var tmp$ret$55;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$54;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_6 = new Container(false);
    var tmp$ret$53;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_11 = addTo(tmp1_addTo_6, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$52;
    // Inline function 'korlibs.korge.view.container.<anonymous>' call
    tmp$ret$52 = Unit_getInstance();
    tmp$ret$53 = tmp0_apply_11;
    tmp$ret$54 = tmp$ret$53;
    tmp$ret$55 = tmp$ret$54;
    tmp_17.ahh_1 = tmp$ret$55;
    var tmp_18 = this;
    var tmp$ret$61;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp$ret$59;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = Colors_getInstance().a4y_1;
    var tmp$ret$58;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_7 = new SolidRect(Size_init_$Create$(0, 0), tmp2_solidRect);
    var tmp$ret$57;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_12 = addTo(tmp1_addTo_7, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$56;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$56 = Unit_getInstance();
    tmp$ret$57 = tmp0_apply_12;
    tmp$ret$58 = tmp$ret$57;
    tmp$ret$59 = tmp$ret$58;
    var tmp3_visible = alpha(tmp$ret$59, 0.5);
    var tmp$ret$60;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp3_visible.u96(false);
    tmp$ret$60 = tmp3_visible;
    tmp$ret$61 = tmp$ret$60;
    tmp_18.bhh_1 = tmp$ret$61;
    resetUIMap(this);
    var tmp$ret$62;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.ihg_1.dgo_1;
    var tmp1_register = UIMapV2$slambda_0(this, null);
    tmp$ret$62 = tmp0_register.cg7(getKClass(UpdatedPathLineEvent), tmp1_register);
  }
  protoOf(UIMapV2).dhn = function () {
    return this.jhg_1.ekl_1;
  };
  protoOf(UIMapV2).yhq = function () {
    return this.jhg_1.mkl_1;
  };
  protoOf(UIMapV2).vje = function () {
    return this.lhg_1.ago_1;
  };
  protoOf(UIMapV2).wje = function () {
    return this.lhg_1.bgo_1;
  };
  protoOf(UIMapV2).vht = function (event) {
    // Inline function 'kotlin.require' call
    var tmp0_require = equals(this.lhg_1.ago_1, event.bg8_1) ? equals(this.lhg_1.bgo_1, event.cg8_1) : false;
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'kotlin.require' call
    // Inline function 'kotlin.contracts.contract' call
    if (!tmp0_require) {
      var tmp$ret$0;
      // Inline function 'kotlin.require.<anonymous>' call
      tmp$ret$0 = 'Failed requirement.';
      var message = tmp$ret$0;
      throw IllegalArgumentException_init_$Create$(toString(message));
    }
    resetUIMap(this);
    var heightDiffWorldUnit = GameUnit__toWorldUnit_impl_m803zs(GameUnit__minus_impl_uz6kmr(event.cg8_1, event.ag8_1), this.dhn());
    // Inline function 'kotlin.sequences.forEach' call
    var tmp1_forEach = this.khg_1.ygb_1.tg1();
    var tmp0_iterator = tmp1_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k().zg0_1;
      // Inline function 'com.xenotactic.korge.ui.UIMapV2.handleResizeMapEvent.<anonymous>' call
      var uiMapEntityComponent = this.khg_1.igc_1.tg0(element);
      var tmp0_this = uiMapEntityComponent.fg5_1;
      tmp0_this.dbl(tmp0_this.f6g() + WorldUnit__toFloat_impl_2n0xxa(heightDiffWorldUnit));
      var uiMapEntityTextComponent = this.khg_1.kgc_1.ug0(element);
      if (!(uiMapEntityTextComponent == null)) {
        var tmp1_this = uiMapEntityTextComponent.jg5_1;
        tmp1_this.dbl(tmp1_this.f6g() + WorldUnit__toFloat_impl_2n0xxa(heightDiffWorldUnit));
      }
    }
    this.iht(this.nhg_1.jgo_1);
  };
  protoOf(UIMapV2).chr = function (x, y, entityHeight) {
    return new Pair(new WorldUnit(_WorldUnit___init__impl__qt45s3(_GameUnit___get_value__impl__9ocgox(x) * this.dhn())), new WorldUnit(_WorldUnit___init__impl__qt45s3(_GameUnit___get_value__impl__9ocgox(GameUnit__minus_impl_uz6kmr(GameUnit__minus_impl_uz6kmr(this.wje(), y), entityHeight)) * this.dhn())));
  };
  protoOf(UIMapV2).ehn = function (x, y, entityHeight, $super) {
    entityHeight = entityHeight === VOID ? _GameUnit___init__impl__teirvv(0) : entityHeight;
    return $super === VOID ? this.chr(x, y, entityHeight) : $super.chr.call(this, new GameUnit(x), new GameUnit(y), new GameUnit(entityHeight));
  };
  protoOf(UIMapV2).chh = function (globalMouseX, globalMouseY) {
    var localXY = this.fao(new Vector2(globalMouseX, globalMouseY));
    var unprojected = new Vector2(localXY.p3r_1, _GameUnit___get_value__impl__9ocgox(this.wje()) * this.dhn() - localXY.q3r_1);
    var gridX = unprojected.p3r_1 / this.dhn();
    var gridY = unprojected.q3r_1 / this.dhn();
    return to(gridX, gridY);
  };
  protoOf(UIMapV2).dhh = function (gridX, gridY, entityWidth, entityHeight) {
    return getRoundedGridCoordinates(gridX, gridY, entityWidth, entityHeight, this.vje(), this.wje());
  };
  protoOf(UIMapV2).ehh = function (gridX, gridY, entityWidth, entityHeight) {
    var tmp0_container = toWorldCoordinates_0(this.dhn(), new GameUnitTuple(gridX, gridY), this.wje(), entityHeight);
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    var tmp1_container = toWorldDimensions(entityWidth, entityHeight, this.dhn());
    var worldWidth = tmp1_container.c5().ngw_1;
    var worldHeight = tmp1_container.d5().ngw_1;
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp0_visible = xy_1(size_0(this.bhh_1, worldWidth, worldHeight), worldX, worldY);
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_visible.u96(true);
    tmp$ret$0 = tmp0_visible;
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(UIMapV2).fhh = function (entity) {
    var tmp0_container = toWorldCoordinates_1(this.dhn(), entity, this.vje(), this.wje());
    var worldX = tmp0_container.c5().ngw_1;
    var worldY = tmp0_container.d5().ngw_1;
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = createEntityView_0(this, entity);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIMapV2.renderHighlightEntity.<anonymous>' call
    addTo(tmp0_apply, this.ahh_1);
    xy_1(tmp0_apply, worldX, worldY);
    tmp$ret$0 = tmp0_apply;
  };
  protoOf(UIMapV2).ghh = function () {
    this.ahh_1.ibk();
  };
  protoOf(UIMapV2).siy = function () {
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.visible' call
    var tmp0_visible = this.bhh_1;
    var tmp$ret$0;
    // Inline function 'kotlin.also' call
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.korge.view.visible.<anonymous>' call
    tmp0_visible.u96(false);
    tmp$ret$0 = tmp0_visible;
    tmp$ret$1 = tmp$ret$0;
  };
  protoOf(UIMapV2).iht = function (pathSequence) {
    if (!(pathSequence == null)) {
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape' call
      var tmp1_updateShape = this.whg_1;
      var tmp$ret$2;
      // Inline function 'korlibs.image.vector.buildShape' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$1;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = new ShapeBuilder(null, null);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.vector.GpuShapeView.updateShape.<anonymous>' call
      // Inline function 'com.xenotactic.korge.ui.UIMapV2.renderPathLines.<anonymous>' call
      var tmp$ret$0;
      $l$block: {
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp6_stroke = RGBA__withAd_impl_cralao(Colors_getInstance().a4y_1, 0.75);
        var tmp7_stroke = new StrokeInfo(this.mhg_1);
        if (new RGBA(tmp6_stroke) == null ? true : tmp7_stroke == null) {
          tmp$ret$0 = Unit_getInstance();
          break $l$block;
        }
        // Inline function 'korlibs.image.vector.Context2d.stroke' call
        var tmp0_stroke = tmp7_stroke.j49_1;
        var tmp1_stroke = tmp7_stroke.m49_1;
        var tmp2_stroke = tmp7_stroke.o49_1;
        var tmp3_stroke = tmp7_stroke.p49_1;
        var tmp4_stroke = tmp7_stroke.q49_1;
        var tmp5_stroke = tmp7_stroke.r49_1;
        {
          tmp0_apply.a5s();
        }
        // Inline function 'com.xenotactic.korge.ui.UIMapV2.renderPathLines.<anonymous>.<anonymous>' call
        var tmp0_iterator = pathSequence.fgk_1.h();
        while (tmp0_iterator.j()) {
          var path = tmp0_iterator.k();
          var tmp1_iterator = path.jgj().h();
          while (tmp1_iterator.j()) {
            var segment = tmp1_iterator.k();
            var worldP1 = toWorldCoordinates_0(this.dhn(), segment.fgj_1, this.wje());
            var worldP2 = toWorldCoordinates_0(this.dhn(), segment.ggj_1, this.wje());
            tmp0_apply.z4k(toPoint(worldP1), toPoint(worldP2));
          }
        }
        // Inline function 'korlibs.image.vector.Context2d.keep' call
        tmp0_apply.t5t();
        try {
          // Inline function 'korlibs.image.vector.Context2d.stroke.<anonymous>' call
          tmp0_apply.u5t(tmp0_stroke);
          tmp0_apply.v5t(tmp1_stroke);
          tmp0_apply.w5t(tmp2_stroke);
          tmp0_apply.x5t(tmp3_stroke);
          tmp0_apply.y5t(tmp4_stroke);
          tmp0_apply.z5t(tmp5_stroke);
          tmp0_apply.a5u(new RGBA(tmp6_stroke));
        }finally {
          tmp0_apply.b5u();
        }
      }
      tmp$ret$1 = tmp0_apply;
      tmp$ret$2 = tmp$ret$1.o5q();
      tmp1_updateShape.zen(tmp$ret$2);
    }
  };
  function NotificationTextUpdateEvent(text) {
    this.akm_1 = text;
  }
  protoOf(NotificationTextUpdateEvent).toString = function () {
    return 'NotificationTextUpdateEvent(text=' + this.akm_1 + ')';
  };
  protoOf(NotificationTextUpdateEvent).hashCode = function () {
    return getStringHashCode(this.akm_1);
  };
  protoOf(NotificationTextUpdateEvent).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof NotificationTextUpdateEvent))
      return false;
    var tmp0_other_with_cast = other instanceof NotificationTextUpdateEvent ? other : THROW_CCE();
    if (!(this.akm_1 === tmp0_other_with_cast.akm_1))
      return false;
    return true;
  };
  function resize($this) {
    centerXOnStage($this.mko_1);
    centerXOnStage($this.nko_1);
  }
  function notifyErrorText($this, s) {
    $this.nko_1.fbu(s);
    $this.qko_1 = $this.oko_1;
    $this.nko_1.u96(true);
  }
  function UINotificationText$slambda(this$0, resultContinuation) {
    this.akp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UINotificationText$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UINotificationText$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UINotificationText$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 3;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            if (false) {
              this.y7_1 = 4;
              continue $sm;
            }

            var tmp_0 = this.akp_1;
            var tmp0_maxOf = this.akp_1.qko_1.l1(this.akp_1.rko_1);
            tmp_0.qko_1 = tmp0_maxOf.k1(new Long(0, 0)) >= 0 ? tmp0_maxOf : new Long(0, 0);
            this.akp_1.nko_1.mbm(this.akp_1.qko_1.dn() / this.akp_1.oko_1.l1(this.akp_1.pko_1).dn());
            if (this.akp_1.qko_1.k1(new Long(0, 0)) <= 0) {
              this.akp_1.nko_1.u96(false);
            }

            this.y7_1 = 2;
            suspendResult = delay(this.akp_1.rko_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 1;
            continue $sm;
          case 3:
            throw this.b8_1;
          case 4:
            return Unit_getInstance();
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 3) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UINotificationText$slambda).g1p = function (completion) {
    var i = new UINotificationText$slambda(this.akp_1, completion);
    return i;
  };
  function UINotificationText$slambda_0(this$0, resultContinuation) {
    var i = new UINotificationText$slambda(this$0, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UINotificationText$slambda_1(this$0, resultContinuation) {
    this.jkp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UINotificationText$slambda_1).lkp = function (it, $completion) {
    var tmp = this.mkp(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UINotificationText$slambda_1).g8 = function (p1, $completion) {
    return this.lkp(p1 instanceof NotificationTextUpdateEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UINotificationText$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.jkp_1.mko_1.fbu(this.kkp_1.akm_1);
          resize(this.jkp_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UINotificationText$slambda_1).mkp = function (it, completion) {
    var i = new UINotificationText$slambda_1(this.jkp_1, completion);
    i.kkp_1 = it;
    return i;
  };
  function UINotificationText$slambda_2(this$0, resultContinuation) {
    var i = new UINotificationText$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.lkp(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UINotificationText$slambda_3(this$0, resultContinuation) {
    this.vkp_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UINotificationText$slambda_3).xkp = function (it, $completion) {
    var tmp = this.ykp(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UINotificationText$slambda_3).g8 = function (p1, $completion) {
    return this.xkp(p1 instanceof InformErrorMessageEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UINotificationText$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          notifyErrorText(this.vkp_1, this.wkp_1.vg7_1);
          resize(this.vkp_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UINotificationText$slambda_3).ykp = function (it, completion) {
    var i = new UINotificationText$slambda_3(this.vkp_1, completion);
    i.wkp_1 = it;
    return i;
  };
  function UINotificationText$slambda_4(this$0, resultContinuation) {
    var i = new UINotificationText$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xkp(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UINotificationText(engine, text) {
    text = text === VOID ? 'N/A' : text;
    Container.call(this);
    this.kko_1 = engine;
    this.lko_1 = 20.0;
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = this.lko_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text(text, tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'com.xenotactic.korge.ui.UINotificationText.notificationText.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.mko_1 = tmp$ret$3;
    var tmp_0 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_0 = this.lko_1;
    var tmp3_text_0 = Colors_getInstance().m4s_1;
    var tmp4_text_0 = get_DefaultTtfFontAsBitmap();
    var tmp5_text_0 = Companion_getInstance_5().g5r_1;
    var tmp6_text_0 = get_DefaultStringTextRenderer();
    var tmp7_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text('Unable to place entity!', tmp2_text_0, tmp3_text_0, tmp4_text_0, tmp5_text_0, tmp6_text_0, tmp7_text_0, null, null);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UINotificationText.errorText.<anonymous>' call
    alignTopToBottomOf(tmp0_apply_0, this.mko_1);
    tmp0_apply_0.u96(false);
    tmp$ret$4 = tmp0_apply_0;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    tmp_0.nko_1 = tmp$ret$6;
    this.oko_1 = new Long(3000, 0);
    this.pko_1 = new Long(2000, 0);
    this.qko_1 = this.oko_1;
    this.rko_1 = new Long(100, 0);
    resize(this);
    var tmp_1 = Dispatchers_getInstance().fv_1;
    launchImmediately(tmp_1, UINotificationText$slambda_0(this, null));
    var tmp$ret$7;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.kko_1.dgo_1;
    var tmp1_register = UINotificationText$slambda_2(this, null);
    tmp$ret$7 = tmp0_register.cg7(getKClass(NotificationTextUpdateEvent), tmp1_register);
    var tmp$ret$8;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.kko_1.dgo_1;
    var tmp3_register = UINotificationText$slambda_4(this, null);
    tmp$ret$8 = tmp2_register.cg7(getKClass(InformErrorMessageEvent), tmp3_register);
  }
  function UIPathText() {
    Container.call(this);
    var tmp = this;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_6().odd_1;
    var tmp3_text = Colors_getInstance().k4s_1;
    var tmp4_text = get_DefaultTtfFontAsBitmap();
    var tmp5_text = Companion_getInstance_5().g5r_1;
    var tmp6_text = get_DefaultStringTextRenderer();
    var tmp7_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text('Path Length: N/A', tmp2_text, tmp3_text, tmp4_text, tmp5_text, tmp6_text, tmp7_text, null, null);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    tmp.qi0_1 = tmp$ret$3;
  }
  protoOf(UIPathText).ri0 = function (pathLength) {
    var tmp = pathLength;
    if ((tmp == null ? null : new GameUnit(tmp)) == null) {
      this.qi0_1.fbu('Path Length: N/A');
    } else {
      this.qi0_1.fbu('Path Length: ' + roundDecimalPlaces(GameUnit__toDouble_impl_5m2yph(pathLength), 2));
    }
  };
  var UIPlacementButton_VIEW_ROCK_COUNTERS_instance;
  var UIPlacementButton_entriesInitialized;
  function UIPlacementButton_initEntries() {
    if (UIPlacementButton_entriesInitialized)
      return Unit_getInstance();
    UIPlacementButton_entriesInitialized = true;
    UIPlacementButton_VIEW_ROCK_COUNTERS_instance = new UIPlacementButton('VIEW_ROCK_COUNTERS', 0);
  }
  function UIPlacementButton(name, ordinal) {
    Enum.call(this, name, ordinal);
  }
  function UIPlacement$slambda$slambda$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapControllerEComponent);
  }
  function UIPlacement$slambda$slambda(this$0, resultContinuation) {
    this.hkq_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var solver = new StandardSolver3(new SolverSettings());
          var tmp0_getSingleton = this.hkq_1.ai4_1.hgo_1;
          var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapControllerEComponent));
          var tmp0_elvis_lhs = (tmp_0 == null ? true : tmp_0 instanceof GameMapControllerEComponent) ? tmp_0 : THROW_CCE();
          var tmp_1;
          if (tmp0_elvis_lhs == null) {
            throw new SingletonInjectionDoesNotExistException(UIPlacement$slambda$slambda$lambda);
          } else {
            tmp_1 = tmp0_elvis_lhs;
          }
          var mapComponent = tmp_1;
          var solution = solver.hgn(mapComponent.pha(), new SolverParams(5, MaxPath_getInstance()));
          var tmp0_subject = solution;
          if (equals(tmp0_subject, Unsuccessful_getInstance()))
          ;
          else {
            if (tmp0_subject instanceof Success_1) {
              var tmp1_forEach = solution.kgn_1.wgm_1.ygm_1;
              var tmp0_iterator = tmp1_forEach.h();
              while (tmp0_iterator.j()) {
                var element = tmp0_iterator.k();
                mapComponent.og9(new Tower(element.dg6_1, element.eg6_1));
              }
            }
          }
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda$slambda).yak = function (it, completion) {
    var i = new UIPlacement$slambda$slambda(this.hkq_1, completion);
    i.ikq_1 = it;
    return i;
  };
  function UIPlacement$slambda$slambda_0(this$0, resultContinuation) {
    var i = new UIPlacement$slambda$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$slambda$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.rkq_1 = $tmp1_onClick;
    this.skq_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$slambda$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.rkq_1(this.skq_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$slambda$lambda$slambda).g1p = function (completion) {
    var i = new UIPlacement$slambda$lambda$slambda(this.rkq_1, this.skq_1, completion);
    return i;
  };
  function UIPlacement$slambda$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$slambda$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$slambda$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$slambda$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function afterPointerActionChange_0($this) {
    $this.bi4_1.bg7(PointerActionChangeEvent_getInstance());
  }
  function Companion_13() {
    Companion_instance_13 = this;
    var tmp = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(UIPlacement).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp.tkq_1 = tmp$ret$0;
  }
  var Companion_instance_13;
  function Companion_getInstance_39() {
    if (Companion_instance_13 == null)
      new Companion_13();
    return Companion_instance_13;
  }
  function UIPlacement$placementComponent$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(ObjectPlacementEComponent);
  }
  function UIPlacement$slambda(this$0, resultContinuation) {
    this.ckr_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ckr_1.ei4_1.m13(UIPlacementButton_VIEW_ROCK_COUNTERS_getInstance(), this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$slambda).yak = function (it, completion) {
    var i = new UIPlacement$slambda(this.ckr_1, completion);
    i.dkr_1 = it;
    return i;
  };
  function UIPlacement$slambda_0(this$0, resultContinuation) {
    var i = new UIPlacement$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda($tmp1_onClick, $it, resultContinuation) {
    this.mkr_1 = $tmp1_onClick;
    this.nkr_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.mkr_1(this.nkr_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda(this.mkr_1, this.nkr_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_0($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_0($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_1(this$0, resultContinuation) {
    this.wkr_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.wkr_1.ci4_1.yha_1 = new HighlightForPlacement(new Tower(toGameUnit(0), toGameUnit(0)));
          afterPointerActionChange_0(this.wkr_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_1).yak = function (it, completion) {
    var i = new UIPlacement$slambda_1(this.wkr_1, completion);
    i.xkr_1 = it;
    return i;
  };
  function UIPlacement$slambda_2(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_1($tmp1_onClick, $it, resultContinuation) {
    this.gks_1 = $tmp1_onClick;
    this.hks_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.gks_1(this.hks_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_1).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_1(this.gks_1, this.hks_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_2($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_1($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_0($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_2($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_3(this$0, resultContinuation) {
    this.qks_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.qks_1.ci4_1.yha_1 = new HighlightForPlacement(new SmallBlocker(toGameUnit(0), toGameUnit(0)));
          afterPointerActionChange_0(this.qks_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_3).yak = function (it, completion) {
    var i = new UIPlacement$slambda_3(this.qks_1, completion);
    i.rks_1 = it;
    return i;
  };
  function UIPlacement$slambda_4(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_3($tmp1_onClick, $it, resultContinuation) {
    this.akt_1 = $tmp1_onClick;
    this.bkt_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.akt_1(this.bkt_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_3).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_3(this.akt_1, this.bkt_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_4($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_3($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_1($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_4($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_5(this$0, resultContinuation) {
    this.kkt_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_5).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_5).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.kkt_1.ci4_1.yha_1 = new HighlightForPlacement(Companion_getInstance_24().kgh_1);
          afterPointerActionChange_0(this.kkt_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_5).yak = function (it, completion) {
    var i = new UIPlacement$slambda_5(this.kkt_1, completion);
    i.lkt_1 = it;
    return i;
  };
  function UIPlacement$slambda_6(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_5(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_5($tmp1_onClick, $it, resultContinuation) {
    this.ukt_1 = $tmp1_onClick;
    this.vkt_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_5).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_5).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ukt_1(this.vkt_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_5).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_5(this.ukt_1, this.vkt_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_6($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_5($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_2($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_6($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_7(this$0, resultContinuation) {
    this.eku_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_7).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_7).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.eku_1.ci4_1.yha_1 = new RemoveEntityAtPlace(MapEntityType_TOWER_getInstance());
          afterPointerActionChange_0(this.eku_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_7).yak = function (it, completion) {
    var i = new UIPlacement$slambda_7(this.eku_1, completion);
    i.fku_1 = it;
    return i;
  };
  function UIPlacement$slambda_8(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_7(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_7($tmp1_onClick, $it, resultContinuation) {
    this.oku_1 = $tmp1_onClick;
    this.pku_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_7).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_7).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.oku_1(this.pku_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_7).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_7(this.oku_1, this.pku_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_8($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_7($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_3($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_8($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_9(this$0, resultContinuation) {
    this.yku_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_9).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_9).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.yku_1.ci4_1.yha_1 = new RemoveEntityAtPlace(MapEntityType_ROCK_getInstance());
          afterPointerActionChange_0(this.yku_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_9).yak = function (it, completion) {
    var i = new UIPlacement$slambda_9(this.yku_1, completion);
    i.zku_1 = it;
    return i;
  };
  function UIPlacement$slambda_10(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_9(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_9($tmp1_onClick, $it, resultContinuation) {
    this.ikv_1 = $tmp1_onClick;
    this.jkv_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_9).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_9).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ikv_1(this.jkv_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_9).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_9(this.ikv_1, this.jkv_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_10($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_9($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_4($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_10($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_11(this$0, resultContinuation) {
    this.skv_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_11).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_11).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_debug = Companion_getInstance_39().tkq_1;
          var tmp0_log = Level_DEBUG_getInstance();
          if (tmp1_debug.q1n(tmp0_log)) {
            tmp1_debug.r1n(tmp0_log, 'Spawn creep button clicked!');
          }
          this.skv_1.bi4_1.bg7(SpawnCreepEvent_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_11).yak = function (it, completion) {
    var i = new UIPlacement$slambda_11(this.skv_1, completion);
    i.tkv_1 = it;
    return i;
  };
  function UIPlacement$slambda_12(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_11(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_11($tmp1_onClick, $it, resultContinuation) {
    this.ckw_1 = $tmp1_onClick;
    this.dkw_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_11).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_11).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_11).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ckw_1(this.dkw_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_11).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_11(this.ckw_1, this.dkw_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_12($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_11($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_5($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_12($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_13($tmp14_apply, this$0, resultContinuation) {
    this.mkw_1 = $tmp14_apply;
    this.nkw_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_13).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_13).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp2_uiWindow = ensureNotNull(this.mkw_1.np());
          var tmp3_uiWindow = Size_init_$Create$(256, 256);
          var tmp0_apply = addTo(new UIWindow('Debug Window', tmp3_uiWindow), tmp2_uiWindow);
          var tmp1_also = tmp0_apply;
          var tmp4__anonymous__pkmkx7 = tmp1_also.tdv_1.pd5_1;
          var tmp1_uiButton = Companion_getInstance_19().dc4_1;
          var tmp0_apply_0 = addTo(new UIButton(tmp1_uiButton, '', null), tmp4__anonymous__pkmkx7);
          tmp0_apply_0.fbu('Solve');
          var tmp1_onClick = UIPlacement$slambda$slambda_0(this.nkw_1, null);
          var tmp0_doMouseEvent = click$factory_37();
          var tmp0_safe_receiver = tmp0_apply_0;
          var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
          if (tmp1_safe_receiver == null)
            null;
          else {
            var tmp_0 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
            tmp_0.f1q(UIPlacement$slambda$lambda(tmp1_safe_receiver, tmp1_onClick));
          }
          var window_0 = centerOnStage(tmp1_also);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_13).yak = function (it, completion) {
    var i = new UIPlacement$slambda_13(this.mkw_1, this.nkw_1, completion);
    i.okw_1 = it;
    return i;
  };
  function UIPlacement$slambda_14($tmp14_apply, this$0, resultContinuation) {
    var i = new UIPlacement$slambda_13($tmp14_apply, this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_13($tmp1_onClick, $it, resultContinuation) {
    this.xkw_1 = $tmp1_onClick;
    this.ykw_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_13).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_13).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_13).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.xkw_1(this.ykw_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_13).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_13(this.xkw_1, this.ykw_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_14($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_13($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_6($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_14($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_15(this$0, resultContinuation) {
    this.hkx_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_15).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_15).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_15).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.hkx_1.bi4_1.bg7(ExitGameSceneEvent_getInstance());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_15).yak = function (it, completion) {
    var i = new UIPlacement$slambda_15(this.hkx_1, completion);
    i.ikx_1 = it;
    return i;
  };
  function UIPlacement$slambda_16(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_15(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement$lambda$slambda_15($tmp1_onClick, $it, resultContinuation) {
    this.rkx_1 = $tmp1_onClick;
    this.skx_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$lambda$slambda_15).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$lambda$slambda_15).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UIPlacement$lambda$slambda_15).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.rkx_1(this.skx_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UIPlacement$lambda$slambda_15).g1p = function (completion) {
    var i = new UIPlacement$lambda$slambda_15(this.rkx_1, this.skx_1, completion);
    return i;
  };
  function UIPlacement$lambda$slambda_16($tmp1_onClick, $it, resultContinuation) {
    var i = new UIPlacement$lambda$slambda_15($tmp1_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UIPlacement$lambda_7($tmp1_safe_receiver, $tmp1_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UIPlacement$lambda$slambda_16($tmp1_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UIPlacement$slambda_17(this$0, resultContinuation) {
    this.bky_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UIPlacement$slambda_17).fjg = function (it, $completion) {
    var tmp = this.gjg(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UIPlacement$slambda_17).g8 = function (p1, $completion) {
    return this.fjg(p1 instanceof EscapeButtonActionEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UIPlacement$slambda_17).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.bky_1.dky();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UIPlacement$slambda_17).gjg = function (it, completion) {
    var i = new UIPlacement$slambda_17(this.bky_1, completion);
    i.cky_1 = it;
    return i;
  };
  function UIPlacement$slambda_18(this$0, resultContinuation) {
    var i = new UIPlacement$slambda_17(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.fjg(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UIPlacement(engine, eventBus) {
    Companion_getInstance_39();
    Container.call(this);
    this.ai4_1 = engine;
    this.bi4_1 = eventBus;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ai4_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(ObjectPlacementEComponent));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof ObjectPlacementEComponent) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UIPlacement$placementComponent$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ci4_1 = tmp$ret$1;
    this.ei4_1 = new AsyncSignal();
    var tmp_2 = this;
    var tmp$ret$57;
    // Inline function 'korlibs.korge.ui.uiVerticalStack' call
    var tmp1_uiVerticalStack = get_UI_DEFAULT_PADDING();
    var tmp$ret$56;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(new UIVerticalStack(null, tmp1_uiVerticalStack, true), this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>' call
    var tmp$ret$7;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp1_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$6;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(new UIButton(tmp1_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp0_apply_0.xby(12.0);
    tmp0_apply_0.fbu('View rock counters');
    var tmp$ret$5;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick = UIPlacement$slambda_0(this, null);
    var tmp$ret$4;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent = click$factory_28();
    var tmp0_safe_receiver = tmp0_apply_0;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$3;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$2;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_3 = tmp0_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$2 = tmp_3.f1q(UIPlacement$lambda(tmp1_safe_receiver, tmp1_onClick));
      tmp$ret$3 = tmp$ret$2;
    }
    tmp$ret$4 = tmp0_apply_0;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp0_apply_0;
    tmp$ret$7 = tmp$ret$6;
    var tmp$ret$13;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp3_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$12;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(new UIButton(tmp3_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp2_apply.fbu('Tower');
    var tmp$ret$11;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_0 = UIPlacement$slambda_2(this, null);
    var tmp$ret$10;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_0 = click$factory_29();
    var tmp0_safe_receiver_0 = tmp2_apply;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$9;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$8;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_4 = tmp0_doMouseEvent_0.get(tmp1_safe_receiver_0);
      tmp$ret$8 = tmp_4.f1q(UIPlacement$lambda_0(tmp1_safe_receiver_0, tmp1_onClick_0));
      tmp$ret$9 = tmp$ret$8;
    }
    tmp$ret$10 = tmp2_apply;
    tmp$ret$11 = tmp$ret$10;
    tmp$ret$12 = tmp2_apply;
    tmp$ret$13 = tmp$ret$12;
    var tmp$ret$19;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp5_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$18;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(new UIButton(tmp5_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp4_apply.fbu('Small Blocker');
    var tmp$ret$17;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_1 = UIPlacement$slambda_4(this, null);
    var tmp$ret$16;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_1 = click$factory_30();
    var tmp0_safe_receiver_1 = tmp4_apply;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$15;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$14;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_5 = tmp0_doMouseEvent_1.get(tmp1_safe_receiver_1);
      tmp$ret$14 = tmp_5.f1q(UIPlacement$lambda_1(tmp1_safe_receiver_1, tmp1_onClick_1));
      tmp$ret$15 = tmp$ret$14;
    }
    tmp$ret$16 = tmp4_apply;
    tmp$ret$17 = tmp$ret$16;
    tmp$ret$18 = tmp4_apply;
    tmp$ret$19 = tmp$ret$18;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp7_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$24;
    // Inline function 'kotlin.apply' call
    var tmp6_apply = addTo(new UIButton(tmp7_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp6_apply.fbu('Rock');
    var tmp$ret$23;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_2 = UIPlacement$slambda_6(this, null);
    var tmp$ret$22;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_2 = click$factory_31();
    var tmp0_safe_receiver_2 = tmp6_apply;
    var tmp1_safe_receiver_2 = tmp0_safe_receiver_2 == null ? null : get_mouse(tmp0_safe_receiver_2);
    if (tmp1_safe_receiver_2 == null)
      null;
    else {
      var tmp$ret$21;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$20;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_6 = tmp0_doMouseEvent_2.get(tmp1_safe_receiver_2);
      tmp$ret$20 = tmp_6.f1q(UIPlacement$lambda_2(tmp1_safe_receiver_2, tmp1_onClick_2));
      tmp$ret$21 = tmp$ret$20;
    }
    tmp$ret$22 = tmp6_apply;
    tmp$ret$23 = tmp$ret$22;
    tmp$ret$24 = tmp6_apply;
    tmp$ret$25 = tmp$ret$24;
    var tmp$ret$31;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp9_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(new UIButton(tmp9_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp8_apply.fbu('Remove Tower');
    var tmp$ret$29;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_3 = UIPlacement$slambda_8(this, null);
    var tmp$ret$28;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_3 = click$factory_32();
    var tmp0_safe_receiver_3 = tmp8_apply;
    var tmp1_safe_receiver_3 = tmp0_safe_receiver_3 == null ? null : get_mouse(tmp0_safe_receiver_3);
    if (tmp1_safe_receiver_3 == null)
      null;
    else {
      var tmp$ret$27;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$26;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_7 = tmp0_doMouseEvent_3.get(tmp1_safe_receiver_3);
      tmp$ret$26 = tmp_7.f1q(UIPlacement$lambda_3(tmp1_safe_receiver_3, tmp1_onClick_3));
      tmp$ret$27 = tmp$ret$26;
    }
    tmp$ret$28 = tmp8_apply;
    tmp$ret$29 = tmp$ret$28;
    tmp$ret$30 = tmp8_apply;
    tmp$ret$31 = tmp$ret$30;
    var tmp$ret$37;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp11_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$36;
    // Inline function 'kotlin.apply' call
    var tmp10_apply = addTo(new UIButton(tmp11_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp10_apply.fbu('Remove Rock');
    var tmp$ret$35;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_4 = UIPlacement$slambda_10(this, null);
    var tmp$ret$34;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_4 = click$factory_33();
    var tmp0_safe_receiver_4 = tmp10_apply;
    var tmp1_safe_receiver_4 = tmp0_safe_receiver_4 == null ? null : get_mouse(tmp0_safe_receiver_4);
    if (tmp1_safe_receiver_4 == null)
      null;
    else {
      var tmp$ret$33;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$32;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_8 = tmp0_doMouseEvent_4.get(tmp1_safe_receiver_4);
      tmp$ret$32 = tmp_8.f1q(UIPlacement$lambda_4(tmp1_safe_receiver_4, tmp1_onClick_4));
      tmp$ret$33 = tmp$ret$32;
    }
    tmp$ret$34 = tmp10_apply;
    tmp$ret$35 = tmp$ret$34;
    tmp$ret$36 = tmp10_apply;
    tmp$ret$37 = tmp$ret$36;
    var tmp$ret$43;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp13_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$42;
    // Inline function 'kotlin.apply' call
    var tmp12_apply = addTo(new UIButton(tmp13_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp12_apply.fbu('Spawn creep');
    var tmp$ret$41;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_5 = UIPlacement$slambda_12(this, null);
    var tmp$ret$40;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_5 = click$factory_34();
    var tmp0_safe_receiver_5 = tmp12_apply;
    var tmp1_safe_receiver_5 = tmp0_safe_receiver_5 == null ? null : get_mouse(tmp0_safe_receiver_5);
    if (tmp1_safe_receiver_5 == null)
      null;
    else {
      var tmp$ret$39;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$38;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_9 = tmp0_doMouseEvent_5.get(tmp1_safe_receiver_5);
      tmp$ret$38 = tmp_9.f1q(UIPlacement$lambda_5(tmp1_safe_receiver_5, tmp1_onClick_5));
      tmp$ret$39 = tmp$ret$38;
    }
    tmp$ret$40 = tmp12_apply;
    tmp$ret$41 = tmp$ret$40;
    tmp$ret$42 = tmp12_apply;
    tmp$ret$43 = tmp$ret$42;
    var tmp$ret$49;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp15_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$48;
    // Inline function 'kotlin.apply' call
    var tmp14_apply = addTo(new UIButton(tmp15_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp14_apply.fbu('Debug');
    var tmp$ret$47;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_6 = UIPlacement$slambda_14(tmp14_apply, this, null);
    var tmp$ret$46;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_6 = click$factory_35();
    var tmp0_safe_receiver_6 = tmp14_apply;
    var tmp1_safe_receiver_6 = tmp0_safe_receiver_6 == null ? null : get_mouse(tmp0_safe_receiver_6);
    if (tmp1_safe_receiver_6 == null)
      null;
    else {
      var tmp$ret$45;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$44;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_10 = tmp0_doMouseEvent_6.get(tmp1_safe_receiver_6);
      tmp$ret$44 = tmp_10.f1q(UIPlacement$lambda_6(tmp1_safe_receiver_6, tmp1_onClick_6));
      tmp$ret$45 = tmp$ret$44;
    }
    tmp$ret$46 = tmp14_apply;
    tmp$ret$47 = tmp$ret$46;
    tmp$ret$48 = tmp14_apply;
    tmp$ret$49 = tmp$ret$48;
    var tmp$ret$55;
    // Inline function 'korlibs.korge.ui.uiButton' call
    var tmp17_uiButton = Companion_getInstance_19().dc4_1;
    var tmp$ret$54;
    // Inline function 'kotlin.apply' call
    var tmp16_apply = addTo(new UIButton(tmp17_uiButton, '', null), tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UIPlacement.<anonymous>.<anonymous>' call
    tmp16_apply.fbu('Exit');
    var tmp$ret$53;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp1_onClick_7 = UIPlacement$slambda_16(this, null);
    var tmp$ret$52;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp0_doMouseEvent_7 = click$factory_36();
    var tmp0_safe_receiver_7 = tmp16_apply;
    var tmp1_safe_receiver_7 = tmp0_safe_receiver_7 == null ? null : get_mouse(tmp0_safe_receiver_7);
    if (tmp1_safe_receiver_7 == null)
      null;
    else {
      var tmp$ret$51;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$50;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_11 = tmp0_doMouseEvent_7.get(tmp1_safe_receiver_7);
      tmp$ret$50 = tmp_11.f1q(UIPlacement$lambda_7(tmp1_safe_receiver_7, tmp1_onClick_7));
      tmp$ret$51 = tmp$ret$50;
    }
    tmp$ret$52 = tmp16_apply;
    tmp$ret$53 = tmp$ret$52;
    tmp$ret$54 = tmp16_apply;
    tmp$ret$55 = tmp$ret$54;
    tmp$ret$56 = tmp0_apply;
    tmp$ret$57 = tmp$ret$56;
    tmp_2.di4_1 = tmp$ret$57;
    var tmp$ret$58;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.bi4_1;
    var tmp3_register = UIPlacement$slambda_18(this, null);
    tmp$ret$58 = tmp2_register.cg7(getKClass(EscapeButtonActionEvent), tmp3_register);
  }
  protoOf(UIPlacement).dky = function () {
    this.ci4_1.yha_1 = Inactive_getInstance();
    afterPointerActionChange_0(this);
  };
  function UIPlacementButton_VIEW_ROCK_COUNTERS_getInstance() {
    UIPlacementButton_initEntries();
    return UIPlacementButton_VIEW_ROCK_COUNTERS_instance;
  }
  function click$factory_28() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_29() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_30() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_31() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_32() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_33() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_34() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_35() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_36() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function click$factory_37() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function UITextRect(text, width, height, padding, font) {
    Container.call(this);
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = Colors_getInstance().n4t_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$_0(width, height), tmp2_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var textBg = tmp$ret$3;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text' call
    var tmp5_text = Companion_getInstance_6().odd_1;
    var tmp6_text = Colors_getInstance().k4s_1;
    var tmp7_text = Companion_getInstance_5().g5r_1;
    var tmp8_text = get_DefaultStringTextRenderer();
    var tmp9_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new Text(text, tmp5_text, tmp6_text, font, tmp7_text, tmp8_text, tmp9_text, null, null);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITextRect.<anonymous>' call
    tmp3_apply.tbs_1 = false;
    scaleWhileMaintainingAspect(tmp3_apply, new ByWidthAndHeight(width - padding, height - padding));
    centerOn(tmp3_apply, textBg);
    tmp$ret$4 = tmp3_apply;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
  }
  function UITextWithShadow(initialText) {
    Container.call(this);
    this.nl0_1 = Companion_getInstance_6().odd_1;
    var tmp = this;
    var tmp0_font = GlobalResources_getInstance().ugv();
    var tmp1_color = Colors_getInstance().l4s_1;
    var tmp2_textSize = this.nl0_1 + 3;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_5().g5r_1;
    var tmp3_text = get_DefaultStringTextRenderer();
    var tmp4_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Text(initialText, tmp2_textSize, tmp1_color, tmp0_font, tmp2_text, tmp3_text, tmp4_text, null, null);
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITextWithShadow.t1.<anonymous>' call
    tmp0_apply.tbs_1 = false;
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    tmp.ol0_1 = tmp$ret$2;
    var tmp_0 = this;
    var tmp0_font_0 = GlobalResources_getInstance().ugv();
    var tmp1_color_0 = Colors_getInstance().a4y_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_0 = Companion_getInstance_6().odd_1;
    var tmp3_text_0 = Companion_getInstance_5().g5r_1;
    var tmp4_text_0 = get_DefaultStringTextRenderer();
    var tmp5_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text(initialText, tmp2_text_0, tmp1_color_0, tmp0_font_0, tmp3_text_0, tmp4_text_0, tmp5_text, null, null);
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITextWithShadow.t2.<anonymous>' call
    tmp0_apply_0.tbs_1 = false;
    centerOn(tmp0_apply_0, this.ol0_1);
    tmp$ret$3 = tmp0_apply_0;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
    tmp_0.pl0_1 = tmp$ret$5;
  }
  protoOf(UITextWithShadow).fbu = function (value) {
    this.ol0_1.fbu(value);
    this.pl0_1.fbu(value);
  };
  function UITooltipDescription$lambda(it) {
    return alignLeftToRightOf(it.n(1), it.n(0), 10.0);
  }
  function UITooltipDescription(goldCost, supplyCost, titleText, descriptionText) {
    goldCost = goldCost === VOID ? null : goldCost;
    supplyCost = supplyCost === VOID ? null : supplyCost;
    titleText = titleText === VOID ? 'BASIC TOWER' : titleText;
    descriptionText = descriptionText === VOID ? 'A basic tower.' : descriptionText;
    Container.call(this);
    this.ik9_1 = null;
    var padding = 5.0;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = MaterialColors_getInstance().s5d_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$(20, 20), tmp2_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var bg = tmp$ret$3;
    var textColor = Colors_getInstance().l4s_1;
    var headerTextSize = 24.0;
    var descriptionTextSize = 20.0;
    var tmp$ret$41;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$40;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = new Container(false);
    var tmp$ret$39;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = GlobalResources_getInstance().ugv();
    var tmp3_text = Companion_getInstance_5().g5r_1;
    var tmp4_text = get_DefaultStringTextRenderer();
    var tmp5_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Text(titleText, headerTextSize, textColor, tmp2_text, tmp3_text, tmp4_text, tmp5_text, null, null);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>' call
    alignTopToTopOf_0(tmp0_apply_0, bg, padding);
    alignLeftToLeftOf(tmp0_apply_0, bg, padding);
    tmp$ret$4 = tmp0_apply_0;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    var titleTextUI = tmp$ret$6;
    var hasCostSection = false;
    var tmp$ret$32;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp7_addTo = new Container(false);
    var tmp$ret$30;
    // Inline function 'kotlin.apply' call
    var tmp6_apply = addTo(tmp7_addTo, tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>' call
    var tmp$ret$7;
    // Inline function 'kotlin.collections.mutableListOf' call
    tmp$ret$7 = ArrayList_init_$Create$();
    var viewsToAlign = tmp$ret$7;
    var tmp0_safe_receiver = goldCost;
    if (tmp0_safe_receiver == null)
      null;
    else {
      var tmp$ret$18;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$17;
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>' call
      hasCostSection = true;
      var tmp$ret$16;
      // Inline function 'korlibs.korge.view.container' call
      var tmp$ret$15;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo_1 = new Container(false);
      var tmp$ret$14;
      // Inline function 'kotlin.apply' call
      var tmp0_apply_1 = addTo(tmp1_addTo_1, tmp6_apply);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      var tmp$ret$10;
      // Inline function 'korlibs.korge.view.image' call
      var tmp2_image = GlobalResources_getInstance().rgv();
      var tmp3_image = Companion_getInstance_0().k3q_1;
      var tmp$ret$9;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo_2 = Image_init_$Create$(tmp2_image, tmp3_image);
      var tmp$ret$8;
      // Inline function 'kotlin.apply' call
      var tmp0_apply_2 = addTo(tmp1_addTo_2, tmp0_apply_1);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply_2.zdx_1 = false;
      tmp$ret$8 = tmp0_apply_2;
      tmp$ret$9 = tmp$ret$8;
      tmp$ret$10 = tmp$ret$9;
      var i = tmp$ret$10;
      var tmp = this;
      var tmp0_text = goldCost.toString();
      var tmp1_font = GlobalResources_getInstance().ugv();
      var tmp$ret$13;
      // Inline function 'korlibs.korge.view.text' call
      var tmp6_text = Companion_getInstance_5().g5r_1;
      var tmp7_text = get_DefaultStringTextRenderer();
      var tmp8_text = Companion_getInstance_6().pdd_1;
      var tmp$ret$12;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp5_addTo = new Text(tmp0_text, 40.0, textColor, tmp1_font, tmp6_text, tmp7_text, tmp8_text, null, null);
      var tmp$ret$11;
      // Inline function 'kotlin.apply' call
      var tmp4_apply = addTo(tmp5_addTo, tmp0_apply_1);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      scaleWhileMaintainingAspect(tmp4_apply, new ByHeight(i.lbm()));
      alignLeftToRightOf(tmp4_apply, i, 5.0);
      centerYOn(tmp4_apply, i);
      tmp$ret$11 = tmp4_apply;
      tmp$ret$12 = tmp$ret$11;
      tmp$ret$13 = tmp$ret$12;
      tmp.ik9_1 = tmp$ret$13;
      tmp$ret$14 = tmp0_apply_1;
      tmp$ret$15 = tmp$ret$14;
      tmp$ret$16 = tmp$ret$15;
      var goldCostSection = tmp$ret$16;
      tmp$ret$17 = viewsToAlign.b(goldCostSection);
      tmp$ret$18 = tmp$ret$17;
    }
    var tmp1_safe_receiver = supplyCost;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$29;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$28;
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>' call
      hasCostSection = true;
      var tmp$ret$27;
      // Inline function 'korlibs.korge.view.container' call
      var tmp$ret$26;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo_3 = new Container(false);
      var tmp$ret$25;
      // Inline function 'kotlin.apply' call
      var tmp0_apply_3 = addTo(tmp1_addTo_3, tmp6_apply);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      var tmp$ret$21;
      // Inline function 'korlibs.korge.view.image' call
      var tmp2_image_0 = GlobalResources_getInstance().sgv();
      var tmp3_image_0 = Companion_getInstance_0().k3q_1;
      var tmp$ret$20;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo_4 = Image_init_$Create$(tmp2_image_0, tmp3_image_0);
      var tmp$ret$19;
      // Inline function 'kotlin.apply' call
      var tmp0_apply_4 = addTo(tmp1_addTo_4, tmp0_apply_3);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      tmp0_apply_4.zdx_1 = false;
      tmp$ret$19 = tmp0_apply_4;
      tmp$ret$20 = tmp$ret$19;
      tmp$ret$21 = tmp$ret$20;
      var i_0 = tmp$ret$21;
      var tmp0_text_0 = supplyCost.toString();
      var tmp1_font_0 = GlobalResources_getInstance().ugv();
      var tmp$ret$24;
      // Inline function 'korlibs.korge.view.text' call
      var tmp6_text_0 = Companion_getInstance_5().g5r_1;
      var tmp7_text_0 = get_DefaultStringTextRenderer();
      var tmp8_text_0 = Companion_getInstance_6().pdd_1;
      var tmp$ret$23;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp5_addTo_0 = new Text(tmp0_text_0, 40.0, textColor, tmp1_font_0, tmp6_text_0, tmp7_text_0, tmp8_text_0, null, null);
      var tmp$ret$22;
      // Inline function 'kotlin.apply' call
      var tmp4_apply_0 = addTo(tmp5_addTo_0, tmp0_apply_3);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
      scaleWhileMaintainingAspect(tmp4_apply_0, new ByHeight(i_0.lbm()));
      alignLeftToRightOf(tmp4_apply_0, i_0, 5.0);
      centerYOn(tmp4_apply_0, i_0);
      tmp$ret$22 = tmp4_apply_0;
      tmp$ret$23 = tmp$ret$22;
      tmp$ret$24 = tmp$ret$23;
      var t = tmp$ret$24;
      tmp$ret$25 = tmp0_apply_3;
      tmp$ret$26 = tmp$ret$25;
      tmp$ret$27 = tmp$ret$26;
      var supplyCostSection = tmp$ret$27;
      tmp$ret$28 = viewsToAlign.b(supplyCostSection);
      tmp$ret$29 = tmp$ret$28;
    }
    windowed(viewsToAlign, 2, VOID, VOID, UITooltipDescription$lambda);
    scaleWhileMaintainingAspect(tmp6_apply, new ByHeight(23.0));
    alignLeftToLeftOf(tmp6_apply, bg, padding);
    alignTopToBottomOf(tmp6_apply, titleTextUI);
    tmp$ret$30 = tmp6_apply;
    tmp$ret$31 = tmp$ret$30;
    tmp$ret$32 = tmp$ret$31;
    var costSection = tmp$ret$32;
    var tmp$ret$38;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp0_font = GlobalResources_getInstance().tgv();
    var tmp$ret$36;
    // Inline function 'korlibs.korge.view.text' call
    var tmp10_text = Companion_getInstance_5().g5r_1;
    var tmp11_text = get_DefaultStringTextRenderer();
    var tmp12_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$35;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp9_addTo = new Text(descriptionText, descriptionTextSize, textColor, tmp0_font, tmp10_text, tmp11_text, tmp12_text, null, null);
    var tmp$ret$34;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(tmp9_addTo, tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$33 = Unit_getInstance();
    tmp$ret$34 = tmp8_apply;
    tmp$ret$35 = tmp$ret$34;
    tmp$ret$36 = tmp$ret$35;
    var tmp14_addTo = tmp$ret$36;
    var tmp$ret$37;
    // Inline function 'kotlin.apply' call
    var tmp13_apply = addTo(tmp14_addTo, tmp3_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITooltipDescription.<anonymous>.<anonymous>' call
    alignTopToBottomOf_0(tmp13_apply, hasCostSection ? costSection : titleTextUI, padding);
    alignLeftToLeftOf(tmp13_apply, bg, padding);
    tmp$ret$37 = tmp13_apply;
    tmp$ret$38 = tmp$ret$37;
    var descriptionTextUI = tmp$ret$38;
    tmp$ret$39 = tmp3_apply;
    tmp$ret$40 = tmp$ret$39;
    tmp$ret$41 = tmp$ret$40;
    var content = tmp$ret$41;
    bg.wdd(content.x18() + padding * 2);
    bg.odz(content.y18() + padding * 2);
  }
  protoOf(UITooltipDescription).jk9 = function (newCost) {
    var tmp0_safe_receiver = this.ik9_1;
    if (tmp0_safe_receiver == null) {
    } else {
      tmp0_safe_receiver.fbu(newCost.toString());
    }
  };
  function UITopRightResourcesGui$gameplayState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameplayState);
  }
  function UITopRightResourcesGui$mutableGoldState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableGoldState);
  }
  function UITopRightResourcesGui$lambda(gold) {
    return gold.toString();
  }
  function UITopRightResourcesGui$slambda($t, $calculateTextFn, resultContinuation) {
    this.yl0_1 = $t;
    this.zl0_1 = $calculateTextFn;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITopRightResourcesGui$slambda).bl1 = function (it, $completion) {
    var tmp = this.cl1(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITopRightResourcesGui$slambda).g8 = function (p1, $completion) {
    return this.bl1(p1 instanceof GoldStateUpdated ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITopRightResourcesGui$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.yl0_1.fbu(this.zl0_1(this.al1_1.ug7_1));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITopRightResourcesGui$slambda).cl1 = function (it, completion) {
    var i = new UITopRightResourcesGui$slambda(this.yl0_1, this.zl0_1, completion);
    i.al1_1 = it;
    return i;
  };
  function UITopRightResourcesGui$slambda_0($t, $calculateTextFn, resultContinuation) {
    var i = new UITopRightResourcesGui$slambda($t, $calculateTextFn, resultContinuation);
    var l = function (it, $completion) {
      return i.bl1(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITopRightResourcesGui$lambda_0(this$0) {
    return function () {
      return '' + this$0.nl3_1.ugc() + '/' + this$0.nl3_1.vgc(this$0.ql3_1.tgo_1, this$0.ql3_1.ugo_1, this$0.ql3_1.sgo_1);
    };
  }
  function UITopRightResourcesGui$slambda_1($t, $calculateTextFn, resultContinuation) {
    this.al4_1 = $t;
    this.bl4_1 = $calculateTextFn;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITopRightResourcesGui$slambda_1).xhp = function (it, $completion) {
    var tmp = this.yhp(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITopRightResourcesGui$slambda_1).g8 = function (p1, $completion) {
    return this.xhp(p1 instanceof AddedEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITopRightResourcesGui$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.al4_1.fbu(this.bl4_1());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITopRightResourcesGui$slambda_1).yhp = function (it, completion) {
    var i = new UITopRightResourcesGui$slambda_1(this.al4_1, this.bl4_1, completion);
    i.cl4_1 = it;
    return i;
  };
  function UITopRightResourcesGui$slambda_2($t, $calculateTextFn, resultContinuation) {
    var i = new UITopRightResourcesGui$slambda_1($t, $calculateTextFn, resultContinuation);
    var l = function (it, $completion) {
      return i.xhp(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITopRightResourcesGui$slambda_3($t, $calculateTextFn, resultContinuation) {
    this.ll4_1 = $t;
    this.ml4_1 = $calculateTextFn;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITopRightResourcesGui$slambda_3).uk9 = function (it, $completion) {
    var tmp = this.vk9(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITopRightResourcesGui$slambda_3).g8 = function (p1, $completion) {
    return this.uk9(p1 instanceof RemovedTowerEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITopRightResourcesGui$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ll4_1.fbu(this.ml4_1());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITopRightResourcesGui$slambda_3).vk9 = function (it, completion) {
    var i = new UITopRightResourcesGui$slambda_3(this.ll4_1, this.ml4_1, completion);
    i.nl4_1 = it;
    return i;
  };
  function UITopRightResourcesGui$slambda_4($t, $calculateTextFn, resultContinuation) {
    var i = new UITopRightResourcesGui$slambda_3($t, $calculateTextFn, resultContinuation);
    var l = function (it, $completion) {
      return i.uk9(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITopRightResourcesGui$slambda_5($t, $calculateTextFn, resultContinuation) {
    this.wl4_1 = $t;
    this.xl4_1 = $calculateTextFn;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITopRightResourcesGui$slambda_5).zl4 = function (it, $completion) {
    var tmp = this.al5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITopRightResourcesGui$slambda_5).g8 = function (p1, $completion) {
    return this.zl4(p1 instanceof RemovedSupplyDepotEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITopRightResourcesGui$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.wl4_1.fbu(this.xl4_1());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITopRightResourcesGui$slambda_5).al5 = function (it, completion) {
    var i = new UITopRightResourcesGui$slambda_5(this.wl4_1, this.xl4_1, completion);
    i.yl4_1 = it;
    return i;
  };
  function UITopRightResourcesGui$slambda_6($t, $calculateTextFn, resultContinuation) {
    var i = new UITopRightResourcesGui$slambda_5($t, $calculateTextFn, resultContinuation);
    var l = function (it, $completion) {
      return i.zl4(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITopRightResourcesGui(engine) {
    Container.call(this);
    this.ml3_1 = engine;
    this.nl3_1 = this.ml3_1.ego_1;
    this.ol3_1 = this.nl3_1.vgb_1;
    this.pl3_1 = this.ml3_1.dgo_1;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ml3_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameplayState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameplayState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UITopRightResourcesGui$gameplayState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.ql3_1 = tmp$ret$1;
    var tmp_2 = this;
    var tmp$ret$3;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton_0 = this.ml3_1.ggo_1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_3 = tmp0_getSingleton_0.wg2_1.h3(getKClass(MutableGoldState));
    tmp$ret$2 = (tmp_3 == null ? true : tmp_3 instanceof MutableGoldState) ? tmp_3 : THROW_CCE();
    var tmp0_elvis_lhs_0 = tmp$ret$2;
    var tmp_4;
    if (tmp0_elvis_lhs_0 == null) {
      throw new SingletonInjectionDoesNotExistException(UITopRightResourcesGui$mutableGoldState$lambda);
    } else {
      tmp_4 = tmp0_elvis_lhs_0;
    }
    tmp$ret$3 = tmp_4;
    tmp_2.rl3_1 = tmp$ret$3;
    var tmp$ret$13;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$12;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new Container(false);
    var tmp$ret$11;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = GlobalResources_getInstance().rgv();
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>.<anonymous>' call
    tmp0_apply_0.zdx_1 = false;
    tmp$ret$4 = tmp0_apply_0;
    tmp$ret$5 = tmp$ret$4;
    tmp$ret$6 = tmp$ret$5;
    var i = tmp$ret$6;
    var calculateTextFn = UITopRightResourcesGui$lambda;
    var tmp0_text = calculateTextFn(this.rl3_1.agp_1);
    var tmp1_font = GlobalResources_getInstance().ugv();
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.text' call
    var tmp6_text = Colors_getInstance().k4s_1;
    var tmp7_text = Companion_getInstance_5().g5r_1;
    var tmp8_text = get_DefaultStringTextRenderer();
    var tmp9_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$8;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo = new Text(tmp0_text, 40.0, tmp6_text, tmp1_font, tmp7_text, tmp8_text, tmp9_text, null, null);
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(tmp5_addTo, tmp0_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>.<anonymous>' call
    scaleWhileMaintainingAspect(tmp4_apply, new ByHeight(i.hbm()));
    alignLeftToRightOf(tmp4_apply, i, 5.0);
    centerYOn(tmp4_apply, i);
    tmp$ret$7 = tmp4_apply;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var t = tmp$ret$9;
    var tmp$ret$10;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp10_register = this.pl3_1;
    var tmp11_register = UITopRightResourcesGui$slambda_0(t, calculateTextFn, null);
    tmp$ret$10 = tmp10_register.cg7(getKClass(GoldStateUpdated), tmp11_register);
    tmp$ret$11 = tmp0_apply;
    tmp$ret$12 = tmp$ret$11;
    tmp$ret$13 = tmp$ret$12;
    var goldSection = tmp$ret$13;
    var tmp$ret$25;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$24;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp3_addTo = new Container(false);
    var tmp$ret$23;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(tmp3_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>' call
    var tmp$ret$16;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image_0 = GlobalResources_getInstance().sgv();
    var tmp3_image_0 = Companion_getInstance_0().k3q_1;
    var tmp$ret$15;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = Image_init_$Create$(tmp2_image_0, tmp3_image_0);
    var tmp$ret$14;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_1, tmp2_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>.<anonymous>' call
    tmp0_apply_1.zdx_1 = false;
    tmp$ret$14 = tmp0_apply_1;
    tmp$ret$15 = tmp$ret$14;
    tmp$ret$16 = tmp$ret$15;
    var i_0 = tmp$ret$16;
    var calculateTextFn_0 = UITopRightResourcesGui$lambda_0(this);
    var tmp0_text_0 = calculateTextFn_0();
    var tmp1_font_0 = GlobalResources_getInstance().ugv();
    var tmp$ret$19;
    // Inline function 'korlibs.korge.view.text' call
    var tmp6_text_0 = Colors_getInstance().k4s_1;
    var tmp7_text_0 = Companion_getInstance_5().g5r_1;
    var tmp8_text_0 = get_DefaultStringTextRenderer();
    var tmp9_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$18;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo_0 = new Text(tmp0_text_0, 40.0, tmp6_text_0, tmp1_font_0, tmp7_text_0, tmp8_text_0, tmp9_text_0, null, null);
    var tmp$ret$17;
    // Inline function 'kotlin.apply' call
    var tmp4_apply_0 = addTo(tmp5_addTo_0, tmp2_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITopRightResourcesGui.<anonymous>.<anonymous>' call
    scaleWhileMaintainingAspect(tmp4_apply_0, new ByHeight(i_0.hbm()));
    alignLeftToRightOf(tmp4_apply_0, i_0, 5.0);
    centerYOn(tmp4_apply_0, i_0);
    tmp$ret$17 = tmp4_apply_0;
    tmp$ret$18 = tmp$ret$17;
    tmp$ret$19 = tmp$ret$18;
    var t_0 = tmp$ret$19;
    var tmp$ret$20;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp10_register_0 = this.pl3_1;
    var tmp11_register_0 = UITopRightResourcesGui$slambda_2(t_0, calculateTextFn_0, null);
    tmp$ret$20 = tmp10_register_0.cg7(getKClass(AddedEntityEvent), tmp11_register_0);
    var tmp$ret$21;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp12_register = this.pl3_1;
    var tmp13_register = UITopRightResourcesGui$slambda_4(t_0, calculateTextFn_0, null);
    tmp$ret$21 = tmp12_register.cg7(getKClass(RemovedTowerEntityEvent), tmp13_register);
    var tmp$ret$22;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp14_register = this.pl3_1;
    var tmp15_register = UITopRightResourcesGui$slambda_6(t_0, calculateTextFn_0, null);
    tmp$ret$22 = tmp14_register.cg7(getKClass(RemovedSupplyDepotEntityEvent), tmp15_register);
    alignLeftToRightOf(tmp2_apply, goldSection, 40.0);
    tmp$ret$23 = tmp2_apply;
    tmp$ret$24 = tmp$ret$23;
    tmp$ret$25 = tmp$ret$24;
    var supplySection = tmp$ret$25;
  }
  function UITowerDetails$slambda($t2, resultContinuation) {
    this.jl5_1 = $t2;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerDetails$slambda).ll5 = function (it, $completion) {
    var tmp = this.ml5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerDetails$slambda).g8 = function (p1, $completion) {
    return this.ll5(p1 instanceof UpgradedTowerDamageEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerDetails$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.jl5_1.fbu(toStringDecimal(this.kl5_1.ohb_1, 2));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerDetails$slambda).ml5 = function (it, completion) {
    var i = new UITowerDetails$slambda(this.jl5_1, completion);
    i.kl5_1 = it;
    return i;
  };
  function UITowerDetails$slambda_0($t2, resultContinuation) {
    var i = new UITowerDetails$slambda($t2, resultContinuation);
    var l = function (it, $completion) {
      return i.ll5(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerDetails$slambda_1($t2, resultContinuation) {
    this.vl5_1 = $t2;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerDetails$slambda_1).xl5 = function (it, $completion) {
    var tmp = this.yl5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerDetails$slambda_1).g8 = function (p1, $completion) {
    return this.xl5(p1 instanceof UpgradedTowerSpeedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerDetails$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.vl5_1.fbu(toStringDecimal(this.wl5_1.shb_1, 2) + ' ATK/s');
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerDetails$slambda_1).yl5 = function (it, completion) {
    var i = new UITowerDetails$slambda_1(this.vl5_1, completion);
    i.wl5_1 = it;
    return i;
  };
  function UITowerDetails$slambda_2($t2, resultContinuation) {
    var i = new UITowerDetails$slambda_1($t2, resultContinuation);
    var l = function (it, $completion) {
      return i.xl5(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerDetails$slambda_3($t2, resultContinuation) {
    this.hl6_1 = $t2;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerDetails$slambda_3).xl5 = function (it, $completion) {
    var tmp = this.yl5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerDetails$slambda_3).g8 = function (p1, $completion) {
    return this.xl5(p1 instanceof UpgradedTowerSpeedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerDetails$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.hl6_1.fbu(toStringDecimal(this.il6_1.rhb_1 / 1000.0, 2));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerDetails$slambda_3).yl5 = function (it, completion) {
    var i = new UITowerDetails$slambda_3(this.hl6_1, completion);
    i.il6_1 = it;
    return i;
  };
  function UITowerDetails$slambda_4($t2, resultContinuation) {
    var i = new UITowerDetails$slambda_3($t2, resultContinuation);
    var l = function (it, $completion) {
      return i.xl5(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerDetails$slambda_5($damageUpgradesText, resultContinuation) {
    this.rl6_1 = $damageUpgradesText;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerDetails$slambda_5).ll5 = function (it, $completion) {
    var tmp = this.ml5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerDetails$slambda_5).g8 = function (p1, $completion) {
    return this.ll5(p1 instanceof UpgradedTowerDamageEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerDetails$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.rl6_1.fbu(this.sl6_1.phb_1.toString());
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerDetails$slambda_5).ml5 = function (it, completion) {
    var i = new UITowerDetails$slambda_5(this.rl6_1, completion);
    i.sl6_1 = it;
    return i;
  };
  function UITowerDetails$slambda_6($damageUpgradesText, resultContinuation) {
    var i = new UITowerDetails$slambda_5($damageUpgradesText, resultContinuation);
    var l = function (it, $completion) {
      return i.ll5(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerDetails$slambda_7($speedUpgradesText, $maxSpeedUpgrades, resultContinuation) {
    this.bl7_1 = $speedUpgradesText;
    this.cl7_1 = $maxSpeedUpgrades;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerDetails$slambda_7).xl5 = function (it, $completion) {
    var tmp = this.yl5(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerDetails$slambda_7).g8 = function (p1, $completion) {
    return this.xl5(p1 instanceof UpgradedTowerSpeedEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerDetails$slambda_7).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.bl7_1.fbu('' + this.dl7_1.thb_1 + '/' + this.cl7_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerDetails$slambda_7).yl5 = function (it, completion) {
    var i = new UITowerDetails$slambda_7(this.bl7_1, this.cl7_1, completion);
    i.dl7_1 = it;
    return i;
  };
  function UITowerDetails$slambda_8($speedUpgradesText, $maxSpeedUpgrades, resultContinuation) {
    var i = new UITowerDetails$slambda_7($speedUpgradesText, $maxSpeedUpgrades, resultContinuation);
    var l = function (it, $completion) {
      return i.xl5(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerDetails(damage, weaponSpeedMillis, attacksPerSecond, range, damageUpgrades, speedUpgrades, maxSpeedUpgrades, engine) {
    engine = engine === VOID ? null : engine;
    Container.call(this);
    var tmp0_safe_receiver = engine;
    var eventBus = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.dgo_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.solidRect' call
    var tmp2_solidRect = MaterialColors_getInstance().x5f_1;
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = new SolidRect(Size_init_$Create$(550, 300), tmp2_solidRect);
    var tmp$ret$1;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'korlibs.korge.view.solidRect.<anonymous>' call
    tmp$ret$0 = Unit_getInstance();
    tmp$ret$1 = tmp0_apply;
    tmp$ret$2 = tmp$ret$1;
    tmp$ret$3 = tmp$ret$2;
    var solidRect = tmp$ret$3;
    var padding = 10.0;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp4_addTo = createUIEntityContainerForTower(toWorldUnit(220), toWorldUnit(220));
    var tmp$ret$4;
    // Inline function 'kotlin.apply' call
    var tmp3_apply = addTo(tmp4_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>' call
    centerYOn(tmp3_apply, solidRect);
    alignLeftToLeftOf_1(tmp3_apply, solidRect, padding);
    tmp$ret$4 = tmp3_apply;
    tmp$ret$5 = tmp$ret$4;
    var towerImage = tmp$ret$5;
    var textColor = Colors_getInstance().l4s_1;
    var paddingBetweenImageAndText = 15.0;
    var tmp$ret$71;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$70;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo = new Container(false);
    var tmp$ret$69;
    // Inline function 'kotlin.apply' call
    var tmp5_apply = addTo(tmp6_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>' call
    var tmp$ret$51;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$50;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new Container(false);
    var tmp$ret$49;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, tmp5_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>' call
    var textSize = 30.0;
    var textPadding = 6.0;
    var tmp$ret$16;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$15;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_1 = new Container(false);
    var tmp$ret$14;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_1 = addTo(tmp1_addTo_1, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    var tmp0_font = GlobalResources_getInstance().ugv();
    var tmp$ret$9;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text = Companion_getInstance_5().g5r_1;
    var tmp3_text = get_DefaultStringTextRenderer();
    var tmp4_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$8;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_2 = new Text('Damage:', textSize, textColor, tmp0_font, tmp2_text, tmp3_text, tmp4_text, null, null);
    var tmp$ret$7;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_2 = addTo(tmp1_addTo_2, tmp0_apply_1);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$6 = Unit_getInstance();
    tmp$ret$7 = tmp0_apply_2;
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var t1 = tmp$ret$9;
    var tmp1_text = toStringDecimal(damage, 2);
    var tmp2_font = GlobalResources_getInstance().tgv();
    var tmp$ret$12;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text = Companion_getInstance_5().g5r_1;
    var tmp8_text = get_DefaultStringTextRenderer();
    var tmp9_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$11;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo_0 = new Text(tmp1_text, textSize, textColor, tmp2_font, tmp7_text, tmp8_text, tmp9_text, null, null);
    var tmp$ret$10;
    // Inline function 'kotlin.apply' call
    var tmp5_apply_0 = addTo(tmp6_addTo_0, tmp0_apply_1);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    alignLeftToRightOf(tmp5_apply_0, t1, textPadding);
    tmp$ret$10 = tmp5_apply_0;
    tmp$ret$11 = tmp$ret$10;
    tmp$ret$12 = tmp$ret$11;
    var t2 = tmp$ret$12;
    var tmp3_safe_receiver = eventBus;
    if (tmp3_safe_receiver == null)
      null;
    else {
      var tmp$ret$13;
      // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
      var tmp10_register = UITowerDetails$slambda_0(t2, null);
      tmp$ret$13 = tmp3_safe_receiver.cg7(getKClass(UpgradedTowerDamageEvent), tmp10_register);
    }
    tmp$ret$14 = tmp0_apply_1;
    tmp$ret$15 = tmp$ret$14;
    tmp$ret$16 = tmp$ret$15;
    var damageTextSection = tmp$ret$16;
    var tmp$ret$27;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$26;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp3_addTo = new Container(false);
    var tmp$ret$25;
    // Inline function 'kotlin.apply' call
    var tmp2_apply = addTo(tmp3_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    var tmp0_font_0 = GlobalResources_getInstance().ugv();
    var tmp$ret$20;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_0 = Companion_getInstance_5().g5r_1;
    var tmp3_text_0 = get_DefaultStringTextRenderer();
    var tmp4_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$19;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_3 = new Text('Speed:', textSize, textColor, tmp0_font_0, tmp2_text_0, tmp3_text_0, tmp4_text_0, null, null);
    var tmp$ret$18;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_3 = addTo(tmp1_addTo_3, tmp2_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$17;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$17 = Unit_getInstance();
    tmp$ret$18 = tmp0_apply_3;
    tmp$ret$19 = tmp$ret$18;
    tmp$ret$20 = tmp$ret$19;
    var t1_0 = tmp$ret$20;
    var tmp1_text_0 = toStringDecimal(attacksPerSecond, 2) + ' ATK/s';
    var tmp2_font_0 = GlobalResources_getInstance().tgv();
    var tmp$ret$23;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text_0 = Companion_getInstance_5().g5r_1;
    var tmp8_text_0 = get_DefaultStringTextRenderer();
    var tmp9_text_0 = Companion_getInstance_6().pdd_1;
    var tmp$ret$22;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo_1 = new Text(tmp1_text_0, textSize, textColor, tmp2_font_0, tmp7_text_0, tmp8_text_0, tmp9_text_0, null, null);
    var tmp$ret$21;
    // Inline function 'kotlin.apply' call
    var tmp5_apply_1 = addTo(tmp6_addTo_1, tmp2_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    alignLeftToRightOf(tmp5_apply_1, t1_0, textPadding);
    tmp$ret$21 = tmp5_apply_1;
    tmp$ret$22 = tmp$ret$21;
    tmp$ret$23 = tmp$ret$22;
    var t2_0 = tmp$ret$23;
    var tmp3_safe_receiver_0 = eventBus;
    if (tmp3_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$24;
      // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
      var tmp10_register_0 = UITowerDetails$slambda_2(t2_0, null);
      tmp$ret$24 = tmp3_safe_receiver_0.cg7(getKClass(UpgradedTowerSpeedEvent), tmp10_register_0);
    }
    tmp$ret$25 = tmp2_apply;
    tmp$ret$26 = tmp$ret$25;
    tmp$ret$27 = tmp$ret$26;
    var speedTextSection = tmp$ret$27;
    var tmp$ret$38;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$37;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo = new Container(false);
    var tmp$ret$36;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(tmp5_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    var tmp0_font_1 = GlobalResources_getInstance().ugv();
    var tmp$ret$31;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_1 = Companion_getInstance_5().g5r_1;
    var tmp3_text_1 = get_DefaultStringTextRenderer();
    var tmp4_text_1 = Companion_getInstance_6().pdd_1;
    var tmp$ret$30;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_4 = new Text('Weapon Speed:', textSize, textColor, tmp0_font_1, tmp2_text_1, tmp3_text_1, tmp4_text_1, null, null);
    var tmp$ret$29;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_4 = addTo(tmp1_addTo_4, tmp4_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$28;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$28 = Unit_getInstance();
    tmp$ret$29 = tmp0_apply_4;
    tmp$ret$30 = tmp$ret$29;
    tmp$ret$31 = tmp$ret$30;
    var t1_1 = tmp$ret$31;
    var tmp1_text_1 = toStringDecimal(weaponSpeedMillis / 1000.0, 2);
    var tmp2_font_1 = GlobalResources_getInstance().tgv();
    var tmp$ret$34;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text_1 = Companion_getInstance_5().g5r_1;
    var tmp8_text_1 = get_DefaultStringTextRenderer();
    var tmp9_text_1 = Companion_getInstance_6().pdd_1;
    var tmp$ret$33;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo_2 = new Text(tmp1_text_1, textSize, textColor, tmp2_font_1, tmp7_text_1, tmp8_text_1, tmp9_text_1, null, null);
    var tmp$ret$32;
    // Inline function 'kotlin.apply' call
    var tmp5_apply_2 = addTo(tmp6_addTo_2, tmp4_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    alignLeftToRightOf(tmp5_apply_2, t1_1, textPadding);
    tmp$ret$32 = tmp5_apply_2;
    tmp$ret$33 = tmp$ret$32;
    tmp$ret$34 = tmp$ret$33;
    var t2_1 = tmp$ret$34;
    var tmp3_safe_receiver_1 = eventBus;
    if (tmp3_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$35;
      // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
      var tmp10_register_1 = UITowerDetails$slambda_4(t2_1, null);
      tmp$ret$35 = tmp3_safe_receiver_1.cg7(getKClass(UpgradedTowerSpeedEvent), tmp10_register_1);
    }
    tmp$ret$36 = tmp4_apply;
    tmp$ret$37 = tmp$ret$36;
    tmp$ret$38 = tmp$ret$37;
    var weaponSpeedTextSection = tmp$ret$38;
    var tmp$ret$48;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$47;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp7_addTo = new Container(false);
    var tmp$ret$46;
    // Inline function 'kotlin.apply' call
    var tmp6_apply = addTo(tmp7_addTo, tmp0_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    var tmp0_font_2 = GlobalResources_getInstance().ugv();
    var tmp$ret$42;
    // Inline function 'korlibs.korge.view.text' call
    var tmp2_text_2 = Companion_getInstance_5().g5r_1;
    var tmp3_text_2 = get_DefaultStringTextRenderer();
    var tmp4_text_2 = Companion_getInstance_6().pdd_1;
    var tmp$ret$41;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_5 = new Text('Range:', textSize, textColor, tmp0_font_2, tmp2_text_2, tmp3_text_2, tmp4_text_2, null, null);
    var tmp$ret$40;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_5 = addTo(tmp1_addTo_5, tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$39;
    // Inline function 'korlibs.korge.view.text.<anonymous>' call
    tmp$ret$39 = Unit_getInstance();
    tmp$ret$40 = tmp0_apply_5;
    tmp$ret$41 = tmp$ret$40;
    tmp$ret$42 = tmp$ret$41;
    var t1_2 = tmp$ret$42;
    var tmp1_text_2 = toStringDecimal(range, 2);
    var tmp2_font_2 = GlobalResources_getInstance().tgv();
    var tmp$ret$45;
    // Inline function 'korlibs.korge.view.text' call
    var tmp7_text_2 = Companion_getInstance_5().g5r_1;
    var tmp8_text_2 = get_DefaultStringTextRenderer();
    var tmp9_text_2 = Companion_getInstance_6().pdd_1;
    var tmp$ret$44;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp6_addTo_3 = new Text(tmp1_text_2, textSize, textColor, tmp2_font_2, tmp7_text_2, tmp8_text_2, tmp9_text_2, null, null);
    var tmp$ret$43;
    // Inline function 'kotlin.apply' call
    var tmp5_apply_3 = addTo(tmp6_addTo_3, tmp6_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>.<anonymous>' call
    alignLeftToRightOf(tmp5_apply_3, t1_2, textPadding);
    tmp$ret$43 = tmp5_apply_3;
    tmp$ret$44 = tmp$ret$43;
    tmp$ret$45 = tmp$ret$44;
    tmp$ret$46 = tmp6_apply;
    tmp$ret$47 = tmp$ret$46;
    tmp$ret$48 = tmp$ret$47;
    var rangeTextSection = tmp$ret$48;
    distributeVertically(listOf_0([damageTextSection, speedTextSection, weaponSpeedTextSection, rangeTextSection]));
    tmp$ret$49 = tmp0_apply_0;
    tmp$ret$50 = tmp$ret$49;
    tmp$ret$51 = tmp$ret$50;
    var textContainer = tmp$ret$51;
    var tmp$ret$68;
    // Inline function 'korlibs.korge.view.container' call
    var tmp$ret$67;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp3_addTo_0 = new Container(false);
    var tmp$ret$66;
    // Inline function 'kotlin.apply' call
    var tmp2_apply_0 = addTo(tmp3_addTo_0, tmp5_apply);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>' call
    var iconPadding = 15.0;
    var iconSize = 80.0;
    var tmp$ret$54;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = GlobalResources_getInstance().ogv();
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$53;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_6 = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$52;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_6 = addTo(tmp1_addTo_6, tmp2_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    tmp0_apply_6.zdx_1 = false;
    scaleWhileMaintainingAspect(tmp0_apply_6, new ByWidth(iconSize));
    tmp$ret$52 = tmp0_apply_6;
    tmp$ret$53 = tmp$ret$52;
    tmp$ret$54 = tmp$ret$53;
    var damageIcon = tmp$ret$54;
    var tmp$ret$57;
    // Inline function 'korlibs.korge.view.image' call
    var tmp6_image = GlobalResources_getInstance().pgv();
    var tmp7_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$56;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo_0 = Image_init_$Create$(tmp6_image, tmp7_image);
    var tmp$ret$55;
    // Inline function 'kotlin.apply' call
    var tmp4_apply_0 = addTo(tmp5_addTo_0, tmp2_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    tmp4_apply_0.zdx_1 = false;
    scaleWhileMaintainingAspect(tmp4_apply_0, new ByWidth(iconSize));
    alignLeftToRightOf(tmp4_apply_0, damageIcon, iconPadding);
    tmp$ret$55 = tmp4_apply_0;
    tmp$ret$56 = tmp$ret$55;
    tmp$ret$57 = tmp$ret$56;
    var cooldownIcon = tmp$ret$57;
    var textSize_0 = 25.0;
    var tmp$ret$60;
    // Inline function 'korlibs.korge.view.text' call
    var tmp10_text = damageUpgrades.toString();
    var tmp11_text = GlobalResources_getInstance().ugv();
    var tmp12_text = Companion_getInstance_5().g5r_1;
    var tmp13_text = get_DefaultStringTextRenderer();
    var tmp14_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$59;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp9_addTo = new Text(tmp10_text, textSize_0, textColor, tmp11_text, tmp12_text, tmp13_text, tmp14_text, null, null);
    var tmp$ret$58;
    // Inline function 'kotlin.apply' call
    var tmp8_apply = addTo(tmp9_addTo, tmp2_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    tmp8_apply.tbs_1 = false;
    centerOn(tmp8_apply, damageIcon);
    alignTopToBottomOf(tmp8_apply, damageIcon, 5.0);
    tmp$ret$58 = tmp8_apply;
    tmp$ret$59 = tmp$ret$58;
    tmp$ret$60 = tmp$ret$59;
    var damageUpgradesText = tmp$ret$60;
    var tmp0_safe_receiver_0 = eventBus;
    if (tmp0_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$61;
      // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
      var tmp15_register = UITowerDetails$slambda_6(damageUpgradesText, null);
      tmp$ret$61 = tmp0_safe_receiver_0.cg7(getKClass(UpgradedTowerDamageEvent), tmp15_register);
    }
    var tmp$ret$64;
    // Inline function 'korlibs.korge.view.text' call
    var tmp18_text = '' + speedUpgrades + '/' + maxSpeedUpgrades;
    var tmp19_text = GlobalResources_getInstance().ugv();
    var tmp20_text = Companion_getInstance_5().g5r_1;
    var tmp21_text = get_DefaultStringTextRenderer();
    var tmp22_text = Companion_getInstance_6().pdd_1;
    var tmp$ret$63;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp17_addTo = new Text(tmp18_text, textSize_0, textColor, tmp19_text, tmp20_text, tmp21_text, tmp22_text, null, null);
    var tmp$ret$62;
    // Inline function 'kotlin.apply' call
    var tmp16_apply = addTo(tmp17_addTo, tmp2_apply_0);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerDetails.<anonymous>.<anonymous>.<anonymous>' call
    tmp16_apply.tbs_1 = false;
    centerOn(tmp16_apply, cooldownIcon);
    alignTopToBottomOf(tmp16_apply, cooldownIcon, 5.0);
    tmp$ret$62 = tmp16_apply;
    tmp$ret$63 = tmp$ret$62;
    tmp$ret$64 = tmp$ret$63;
    var speedUpgradesText = tmp$ret$64;
    var tmp1_safe_receiver = eventBus;
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$65;
      // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
      var tmp23_register = UITowerDetails$slambda_8(speedUpgradesText, maxSpeedUpgrades, null);
      tmp$ret$65 = tmp1_safe_receiver.cg7(getKClass(UpgradedTowerSpeedEvent), tmp23_register);
    }
    centerXOn(tmp2_apply_0, textContainer);
    alignTopToBottomOf(tmp2_apply_0, textContainer, 15.0);
    tmp$ret$66 = tmp2_apply_0;
    tmp$ret$67 = tmp$ret$66;
    tmp$ret$68 = tmp$ret$67;
    var iconsContainer = tmp$ret$68;
    centerYOn(tmp5_apply, solidRect);
    alignLeftToRightOf(tmp5_apply, towerImage, paddingBetweenImageAndText);
    tmp$ret$69 = tmp5_apply;
    tmp$ret$70 = tmp$ret$69;
    tmp$ret$71 = tmp$ret$70;
    var rightSection = tmp$ret$71;
  }
  function _get_currentTowerId__9w71uo($this) {
    return $this.wk5_1.vgo_1;
  }
  function UITowerUpgradeIcon$mutableCurrentlySelectedTowerState$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(MutableCurrentlySelectedTowerState);
  }
  function UITowerUpgradeIcon$lambda(this$0) {
    return function (it) {
      this$0.ak6(5);
      return Unit_getInstance();
    };
  }
  function UITowerUpgradeIcon$slambda(this$0, resultContinuation) {
    this.ml7_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$slambda).sab = function (it, $completion) {
    var tmp = this.tab(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$slambda).g8 = function (p1, $completion) {
    return this.sab(p1 instanceof KeyEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerUpgradeIcon$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ml7_1.ak6(1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$slambda).tab = function (it, completion) {
    var i = new UITowerUpgradeIcon$slambda(this.ml7_1, completion);
    i.nl7_1 = it;
    return i;
  };
  function UITowerUpgradeIcon$slambda_0(this$0, resultContinuation) {
    var i = new UITowerUpgradeIcon$slambda(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.sab(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerUpgradeIcon$slambda_1(this$0, resultContinuation) {
    this.wl7_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$slambda_1).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$slambda_1).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerUpgradeIcon$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          var tmp1_addTo = this.wl7_1.pk5_1;
          var tmp2_addTo = this.wl7_1.rk5_1.qjm_1;
          var tmp0_apply = addTo(tmp1_addTo, tmp2_addTo);
          scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(this.wl7_1.qk5_1, this.wl7_1.qk5_1));
          alignBottomToTopOf(tmp0_apply, this.wl7_1, 5.0);
          centerXOn(tmp0_apply, this.wl7_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$slambda_1).yak = function (it, completion) {
    var i = new UITowerUpgradeIcon$slambda_1(this.wl7_1, completion);
    i.xl7_1 = it;
    return i;
  };
  function UITowerUpgradeIcon$slambda_2(this$0, resultContinuation) {
    var i = new UITowerUpgradeIcon$slambda_1(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerUpgradeIcon$lambda$slambda($tmp2_onOver, $it, resultContinuation) {
    this.gl8_1 = $tmp2_onOver;
    this.hl8_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$lambda$slambda).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.gl8_1(this.hl8_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda).g1p = function (completion) {
    var i = new UITowerUpgradeIcon$lambda$slambda(this.gl8_1, this.hl8_1, completion);
    return i;
  };
  function UITowerUpgradeIcon$lambda$slambda_0($tmp2_onOver, $it, resultContinuation) {
    var i = new UITowerUpgradeIcon$lambda$slambda($tmp2_onOver, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UITowerUpgradeIcon$lambda_0($tmp1_safe_receiver, $tmp2_onOver) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UITowerUpgradeIcon$lambda$slambda_0($tmp2_onOver, it, null));
      return Unit_getInstance();
    };
  }
  function UITowerUpgradeIcon$slambda_3(this$0, resultContinuation) {
    this.ql8_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$slambda_3).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$slambda_3).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerUpgradeIcon$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ql8_1.pk5_1.mbn();
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$slambda_3).yak = function (it, completion) {
    var i = new UITowerUpgradeIcon$slambda_3(this.ql8_1, completion);
    i.rl8_1 = it;
    return i;
  };
  function UITowerUpgradeIcon$slambda_4(this$0, resultContinuation) {
    var i = new UITowerUpgradeIcon$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerUpgradeIcon$lambda$slambda_1($tmp4_onOut, $it, resultContinuation) {
    this.al9_1 = $tmp4_onOut;
    this.bl9_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$lambda$slambda_1).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_1).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.al9_1(this.bl9_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_1).g1p = function (completion) {
    var i = new UITowerUpgradeIcon$lambda$slambda_1(this.al9_1, this.bl9_1, completion);
    return i;
  };
  function UITowerUpgradeIcon$lambda$slambda_2($tmp4_onOut, $it, resultContinuation) {
    var i = new UITowerUpgradeIcon$lambda$slambda_1($tmp4_onOut, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UITowerUpgradeIcon$lambda_1($tmp1_safe_receiver, $tmp4_onOut) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UITowerUpgradeIcon$lambda$slambda_2($tmp4_onOut, it, null));
      return Unit_getInstance();
    };
  }
  function UITowerUpgradeIcon$slambda_5(this$0, resultContinuation) {
    this.kl9_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$slambda_5).xak = function (it, $completion) {
    var tmp = this.yak(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$slambda_5).g8 = function (p1, $completion) {
    return this.xak(p1 instanceof MouseEvents ? p1 : THROW_CCE(), $completion);
  };
  protoOf(UITowerUpgradeIcon$slambda_5).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.kl9_1.vk5_1(this.kl9_1.xk5_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$slambda_5).yak = function (it, completion) {
    var i = new UITowerUpgradeIcon$slambda_5(this.kl9_1, completion);
    i.ll9_1 = it;
    return i;
  };
  function UITowerUpgradeIcon$slambda_6(this$0, resultContinuation) {
    var i = new UITowerUpgradeIcon$slambda_5(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.xak(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function UITowerUpgradeIcon$lambda$slambda_3($tmp6_onClick, $it, resultContinuation) {
    this.ul9_1 = $tmp6_onClick;
    this.vl9_1 = $it;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(UITowerUpgradeIcon$lambda$slambda_3).s1s = function ($completion) {
    var tmp = this.g1p($completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_3).un = function ($completion) {
    return this.s1s($completion);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 2;
            this.y7_1 = 1;
            suspendResult = this.ul9_1(this.vl9_1, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 1:
            return Unit_getInstance();
          case 2:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 2) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  protoOf(UITowerUpgradeIcon$lambda$slambda_3).g1p = function (completion) {
    var i = new UITowerUpgradeIcon$lambda$slambda_3(this.ul9_1, this.vl9_1, completion);
    return i;
  };
  function UITowerUpgradeIcon$lambda$slambda_4($tmp6_onClick, $it, resultContinuation) {
    var i = new UITowerUpgradeIcon$lambda$slambda_3($tmp6_onClick, $it, resultContinuation);
    var l = function ($completion) {
      return i.s1s($completion);
    };
    l.$arity = 0;
    return l;
  }
  function UITowerUpgradeIcon$lambda_2($tmp1_safe_receiver, $tmp6_onClick) {
    return function (it) {
      var tmp = $tmp1_safe_receiver.to();
      launchImmediately(tmp, UITowerUpgradeIcon$lambda$slambda_4($tmp6_onClick, it, null));
      return Unit_getInstance();
    };
  }
  function UITowerUpgradeIcon(engine, tooltip, tooltipSize, guiContainer, icon, initialUpgradeCost, getCurrentUpgradesFn, onUpgradeClick) {
    Container.call(this);
    this.ok5_1 = engine;
    this.pk5_1 = tooltip;
    this.qk5_1 = tooltipSize;
    this.rk5_1 = guiContainer;
    this.sk5_1 = icon;
    this.tk5_1 = initialUpgradeCost;
    this.uk5_1 = getCurrentUpgradesFn;
    this.vk5_1 = onUpgradeClick;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ok5_1.ggo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(MutableCurrentlySelectedTowerState));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof MutableCurrentlySelectedTowerState) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(UITowerUpgradeIcon$mutableCurrentlySelectedTowerState$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.wk5_1 = tmp$ret$1;
    this.xk5_1 = 1;
    var tmp_2 = this;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = this.sk5_1;
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$3;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerUpgradeIcon.img.<anonymous>' call
    tmp0_apply.zdx_1 = false;
    scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(50.0, 50.0));
    tmp$ret$2 = tmp0_apply;
    tmp$ret$3 = tmp$ret$2;
    tmp$ret$4 = tmp$ret$3;
    tmp_2.yk5_1 = tmp$ret$4;
    var tmp_3 = this;
    var tmp$ret$6;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo_0 = new UITextWithShadow('+1');
    var tmp$ret$5;
    // Inline function 'kotlin.apply' call
    var tmp0_apply_0 = addTo(tmp1_addTo_0, this);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.ui.UITowerUpgradeIcon.upgradeNumText.<anonymous>' call
    scaleWhileMaintainingAspect(tmp0_apply_0, new ByWidthAndHeight(40.0, 40.0));
    centerOn(tmp0_apply_0, this.yk5_1);
    tmp$ret$5 = tmp0_apply_0;
    tmp$ret$6 = tmp$ret$5;
    tmp_3.zk5_1 = tmp$ret$6;
    var tmp$ret$9;
    // Inline function 'korlibs.korge.input.keys' call
    var tmp$ret$8;
    // Inline function 'kotlin.run' call
    var tmp0_run = get_keys(this);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$7;
    // Inline function 'com.xenotactic.korge.ui.UITowerUpgradeIcon.<anonymous>' call
    var tmp_4 = Key_SHIFT_getInstance();
    tmp0_run.caf(tmp_4, UITowerUpgradeIcon$lambda(this));
    var tmp_5 = Key_SHIFT_getInstance();
    tmp$ret$7 = tmp0_run.gaf(tmp_5, UITowerUpgradeIcon$slambda_0(this, null));
    tmp$ret$8 = tmp$ret$7;
    tmp$ret$9 = tmp$ret$8;
    var tmp$ret$13;
    // Inline function 'korlibs.korge.input.onOver' call
    var tmp2_onOver = UITowerUpgradeIcon$slambda_2(this, null);
    var tmp$ret$12;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp1_doMouseEvent = over$factory_6();
    var tmp0_safe_receiver = this;
    var tmp1_safe_receiver = tmp0_safe_receiver == null ? null : get_mouse(tmp0_safe_receiver);
    if (tmp1_safe_receiver == null)
      null;
    else {
      var tmp$ret$11;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$10;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_6 = tmp1_doMouseEvent.get(tmp1_safe_receiver);
      tmp$ret$10 = tmp_6.f1q(UITowerUpgradeIcon$lambda_0(tmp1_safe_receiver, tmp2_onOver));
      tmp$ret$11 = tmp$ret$10;
    }
    tmp$ret$12 = this;
    tmp$ret$13 = tmp$ret$12;
    var tmp$ret$17;
    // Inline function 'korlibs.korge.input.onOut' call
    var tmp4_onOut = UITowerUpgradeIcon$slambda_4(this, null);
    var tmp$ret$16;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp3_doMouseEvent = out$factory_5();
    var tmp0_safe_receiver_0 = this;
    var tmp1_safe_receiver_0 = tmp0_safe_receiver_0 == null ? null : get_mouse(tmp0_safe_receiver_0);
    if (tmp1_safe_receiver_0 == null)
      null;
    else {
      var tmp$ret$15;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$14;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_7 = tmp3_doMouseEvent.get(tmp1_safe_receiver_0);
      tmp$ret$14 = tmp_7.f1q(UITowerUpgradeIcon$lambda_1(tmp1_safe_receiver_0, tmp4_onOut));
      tmp$ret$15 = tmp$ret$14;
    }
    tmp$ret$16 = this;
    tmp$ret$17 = tmp$ret$16;
    var tmp$ret$21;
    // Inline function 'korlibs.korge.input.onClick' call
    var tmp6_onClick = UITowerUpgradeIcon$slambda_6(this, null);
    var tmp$ret$20;
    // Inline function 'korlibs.korge.input.doMouseEvent' call
    var tmp5_doMouseEvent = click$factory_38();
    var tmp0_safe_receiver_1 = this;
    var tmp1_safe_receiver_1 = tmp0_safe_receiver_1 == null ? null : get_mouse(tmp0_safe_receiver_1);
    if (tmp1_safe_receiver_1 == null)
      null;
    else {
      var tmp$ret$19;
      // Inline function 'kotlin.let' call
      // Inline function 'kotlin.contracts.contract' call
      var tmp$ret$18;
      // Inline function 'korlibs.korge.input.doMouseEvent.<anonymous>' call
      var tmp_8 = tmp5_doMouseEvent.get(tmp1_safe_receiver_1);
      tmp$ret$18 = tmp_8.f1q(UITowerUpgradeIcon$lambda_2(tmp1_safe_receiver_1, tmp6_onClick));
      tmp$ret$19 = tmp$ret$18;
    }
    tmp$ret$20 = this;
    tmp$ret$21 = tmp$ret$20;
  }
  protoOf(UITowerUpgradeIcon).ak6 = function (newNumUpgrades) {
    this.xk5_1 = newNumUpgrades;
    var tmp = _get_currentTowerId__9w71uo(this);
    var currentUpgrades = this.uk5_1(ensureNotNull(tmp == null ? null : new EntityId(tmp)));
    this.pk5_1.jk9(calculateCostOfUpgrades(currentUpgrades, this.tk5_1, newNumUpgrades));
    this.zk5_1.fbu('+' + newNumUpgrades);
    centerOn(this.zk5_1, this.yk5_1);
  };
  function over$factory_6() {
    return getPropertyCallableRef('over', 1, KProperty1, function (receiver) {
      return receiver.va8_1;
    }, null);
  }
  function out$factory_5() {
    return getPropertyCallableRef('out', 1, KProperty1, function (receiver) {
      return receiver.wa8_1;
    }, null);
  }
  function click$factory_38() {
    return getPropertyCallableRef('click', 1, KProperty1, function (receiver) {
      return receiver.ua8_1;
    }, null);
  }
  function MapBridge(gameMap) {
    gameMap = gameMap === VOID ? Companion_getInstance_22().ag9(10, 10, []) : gameMap;
    this.qi1_1 = gameMap;
  }
  function MapRendererUpdater$slambda$lambda($event) {
    return function ($this$addEntity) {
      $this$addEntity.bg3(new EntityRenderComponent($event.ehb_1));
      return Unit_getInstance();
    };
  }
  function MapRendererUpdater$gameMapControllerComponent$lambda() {
    return 'Singleton injection does not exist: ' + getKClass(GameMapControllerEComponent);
  }
  function MapRendererUpdater$slambda(this$0, resultContinuation) {
    this.ela_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MapRendererUpdater$slambda).gla = function (event, $completion) {
    var tmp = this.hla(event, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MapRendererUpdater$slambda).g8 = function (p1, $completion) {
    return this.gla(p1 instanceof AddEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MapRendererUpdater$slambda).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.ela_1.ila_1.ego_1.vgb_1.fg3(MapRendererUpdater$slambda$lambda(this.fla_1));
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(MapRendererUpdater$slambda).hla = function (event, completion) {
    var i = new MapRendererUpdater$slambda(this.ela_1, completion);
    i.fla_1 = event;
    return i;
  };
  function MapRendererUpdater$slambda_0(this$0, resultContinuation) {
    var i = new MapRendererUpdater$slambda(this$0, resultContinuation);
    var l = function (event, $completion) {
      return i.gla(event, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MapRendererUpdater$slambda_1(resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MapRendererUpdater$slambda_1).vla = function (it, $completion) {
    var tmp = this.wla(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MapRendererUpdater$slambda_1).g8 = function (p1, $completion) {
    return this.vla(p1 instanceof RemovedEntityEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MapRendererUpdater$slambda_1).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          throw new NotImplementedError();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(MapRendererUpdater$slambda_1).wla = function (it, completion) {
    var i = new MapRendererUpdater$slambda_1(completion);
    i.ula_1 = it;
    return i;
  };
  function MapRendererUpdater$slambda_2(resultContinuation) {
    var i = new MapRendererUpdater$slambda_1(resultContinuation);
    var l = function (it, $completion) {
      return i.vla(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MapRendererUpdater$slambda_3(this$0, resultContinuation) {
    this.flb_1 = this$0;
    CoroutineImpl.call(this, resultContinuation);
  }
  protoOf(MapRendererUpdater$slambda_3).ght = function (it, $completion) {
    var tmp = this.hht(it, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(MapRendererUpdater$slambda_3).g8 = function (p1, $completion) {
    return this.ght(p1 instanceof UpdatedPathLineEvent ? p1 : THROW_CCE(), $completion);
  };
  protoOf(MapRendererUpdater$slambda_3).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        if (tmp === 0) {
          this.z7_1 = 1;
          this.flb_1.jla_1.iht(this.flb_1.lla_1.oha_1);
          return Unit_getInstance();
        } else if (tmp === 1) {
          throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        throw e;
      }
     while (true);
  };
  protoOf(MapRendererUpdater$slambda_3).hht = function (it, completion) {
    var i = new MapRendererUpdater$slambda_3(this.flb_1, completion);
    i.glb_1 = it;
    return i;
  };
  function MapRendererUpdater$slambda_4(this$0, resultContinuation) {
    var i = new MapRendererUpdater$slambda_3(this$0, resultContinuation);
    var l = function (it, $completion) {
      return i.ght(it, $completion);
    };
    l.$arity = 1;
    return l;
  }
  function MapRendererUpdater(engine, uiMap, eventBus) {
    this.ila_1 = engine;
    this.jla_1 = uiMap;
    this.kla_1 = eventBus;
    var tmp = this;
    var tmp$ret$1;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingleton' call
    var tmp0_getSingleton = this.ila_1.hgo_1;
    var tmp$ret$0;
    // Inline function 'com.xenotactic.ecs.TypedInjections.getSingletonOrNull' call
    var tmp_0 = tmp0_getSingleton.wg2_1.h3(getKClass(GameMapControllerEComponent));
    tmp$ret$0 = (tmp_0 == null ? true : tmp_0 instanceof GameMapControllerEComponent) ? tmp_0 : THROW_CCE();
    var tmp0_elvis_lhs = tmp$ret$0;
    var tmp_1;
    if (tmp0_elvis_lhs == null) {
      throw new SingletonInjectionDoesNotExistException(MapRendererUpdater$gameMapControllerComponent$lambda);
    } else {
      tmp_1 = tmp0_elvis_lhs;
    }
    tmp$ret$1 = tmp_1;
    tmp.lla_1 = tmp$ret$1;
    var tmp$ret$2;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp0_register = this.kla_1;
    var tmp1_register = MapRendererUpdater$slambda_0(this, null);
    tmp$ret$2 = tmp0_register.cg7(getKClass(AddEntityEvent), tmp1_register);
    var tmp$ret$3;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp2_register = this.kla_1;
    var tmp3_register = MapRendererUpdater$slambda_2(null);
    tmp$ret$3 = tmp2_register.cg7(getKClass(RemovedEntityEvent), tmp3_register);
    var tmp$ret$4;
    // Inline function 'com.xenotactic.gamelogic.events.EventBus.register' call
    var tmp4_register = this.kla_1;
    var tmp5_register = MapRendererUpdater$slambda_4(this, null);
    tmp$ret$4 = tmp4_register.cg7(getKClass(UpdatedPathLineEvent), tmp5_register);
  }
  function PlayerDataApi$jsonParser$lambda($this$Json) {
    $this$Json.jfq_1 = true;
    return Unit_getInstance();
  }
  function $getPlayerDataCOROUTINE$8(_this__u8e3s4, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.plb_1 = _this__u8e3s4;
  }
  protoOf($getPlayerDataCOROUTINE$8).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 18;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.rlb_1 = this.plb_1.kij_1;
            this.y7_1 = 2;
            suspendResult = this.rlb_1.g14(null, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.y7_1 = 4;
            continue $sm;
          case 4:
            this.y7_1 = 5;
            continue $sm;
          case 5:
            this.z7_1 = 17;
            var tmp1_info = PlayerDataApi_getInstance().mij_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'Getting player data');
            }

            this.ulb_1 = get_resourcesVfs().p1b('player_data.json');
            this.y7_1 = 6;
            suspendResult = this.ulb_1.w2d(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 6:
            if (suspendResult) {
              this.y7_1 = 8;
              continue $sm;
            } else {
              this.y7_1 = 7;
              continue $sm;
            }

            break;
          case 7:
            this.slb_1 = new PlayerData();
            this.z7_1 = 18;
            this.y7_1 = 14;
            var tmp_1 = this;
            continue $sm;
          case 8:
            this.y7_1 = 9;
            suspendResult = this.ulb_1.w2d(this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 9:
            if (suspendResult) {
              var tmp_2 = this;
              tmp_2.wlb_1 = Default_getInstance();
              this.y7_1 = 11;
              suspendResult = this.ulb_1.s2d(VOID, this);
              if (suspendResult === get_COROUTINE_SUSPENDED()) {
                return suspendResult;
              }
              continue $sm;
            } else {
              this.y7_1 = 10;
              continue $sm;
            }

            break;
          case 10:
            this.vlb_1 = null;
            if (false) {
              this.y7_1 = 8;
              continue $sm;
            }

            this.y7_1 = 12;
            continue $sm;
          case 11:
            var tmp5_decodeFromString = suspendResult;
            var tmp_3 = this;
            var tmp3_serializer = this.wlb_1.of9();
            var tmp2_cast = serializer(tmp3_serializer, createKType(getKClass(PlayerData), arrayOf([]), false));
            tmp_3.vlb_1 = this.wlb_1.pfp(isInterface(tmp2_cast, KSerializer) ? tmp2_cast : THROW_CCE(), tmp5_decodeFromString);
            this.y7_1 = 12;
            continue $sm;
          case 12:
            var ARGUMENT = this.vlb_1;
            this.tlb_1 = ensureNotNull(ARGUMENT);
            this.z7_1 = 18;
            this.y7_1 = 13;
            continue $sm;
          case 13:
            var tmp_4 = this.tlb_1;
            this.rlb_1.i14(null);
            ;
            var tmp_5 = this;
            return tmp_4;
          case 14:
            var tmp_6 = this.slb_1;
            this.rlb_1.i14(null);
            ;
            this.qlb_1 = tmp_6;
            this.y7_1 = 16;
            continue $sm;
          case 15:
            this.rlb_1.i14(null);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 16;
            continue $sm;
          case 16:
            return this.qlb_1;
          case 17:
            this.z7_1 = 18;
            var t = this.b8_1;
            this.rlb_1.i14(null);
            ;
            throw t;
          case 18:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 18) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function $savePlayerDataCOROUTINE$9(_this__u8e3s4, playerData, resultContinuation) {
    CoroutineImpl.call(this, resultContinuation);
    this.flc_1 = _this__u8e3s4;
    this.glc_1 = playerData;
  }
  protoOf($savePlayerDataCOROUTINE$9).f8 = function () {
    var suspendResult = this.a8_1;
    $sm: do
      try {
        var tmp = this.y7_1;
        switch (tmp) {
          case 0:
            this.z7_1 = 10;
            this.y7_1 = 1;
            continue $sm;
          case 1:
            var tmp_0 = this;
            tmp_0.ilc_1 = this.flc_1.kij_1;
            this.y7_1 = 2;
            suspendResult = this.ilc_1.g14(null, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 2:
            this.y7_1 = 3;
            continue $sm;
          case 3:
            this.y7_1 = 4;
            continue $sm;
          case 4:
            this.z7_1 = 9;
            var tmp1_info = PlayerDataApi_getInstance().mij_1;
            var tmp0_log = Level_INFO_getInstance();
            if (tmp1_info.q1n(tmp0_log)) {
              tmp1_info.r1n(tmp0_log, 'Saving player data...');
            }

            var tmp_1 = this;
            var tmp4_encodeToString = PlayerDataApi_getInstance().lij_1;
            var tmp3_serializer = tmp4_encodeToString.of9();
            var tmp2_cast = serializer(tmp3_serializer, createKType(getKClass(PlayerData), arrayOf([]), false));
            tmp_1.klc_1 = tmp4_encodeToString.ofp(isInterface(tmp2_cast, KSerializer) ? tmp2_cast : THROW_CCE(), this.glc_1);
            this.y7_1 = 5;
            suspendResult = get_resourcesVfs().p1b('player_data.json').u2d(this.klc_1, [], VOID, this);
            if (suspendResult === get_COROUTINE_SUSPENDED()) {
              return suspendResult;
            }

            continue $sm;
          case 5:
            var tmp_2 = this;
            var tmp6_info = PlayerDataApi_getInstance().mij_1;
            var tmp5_log = Level_INFO_getInstance();
            var tmp_3;
            if (tmp6_info.q1n(tmp5_log)) {
              tmp6_info.r1n(tmp5_log, 'Finished saving player data.');
              tmp_3 = Unit_getInstance();
            }

            tmp_2.jlc_1 = tmp_3;
            this.z7_1 = 10;
            this.y7_1 = 6;
            var tmp_4 = this;
            continue $sm;
          case 6:
            var tmp_5 = this;
            this.ilc_1.i14(null);
            tmp_5.hlc_1 = Unit_getInstance();
            this.y7_1 = 8;
            continue $sm;
          case 7:
            this.ilc_1.i14(null);
            ;
            if (false) {
              this.y7_1 = 1;
              continue $sm;
            }

            this.y7_1 = 8;
            continue $sm;
          case 8:
            return Unit_getInstance();
          case 9:
            this.z7_1 = 10;
            var t = this.b8_1;
            this.ilc_1.i14(null);
            ;
            throw t;
          case 10:
            throw this.b8_1;
        }
      } catch ($p) {
        var e = $p;
        if (this.z7_1 === 10) {
          throw e;
        } else {
          this.y7_1 = this.z7_1;
          this.b8_1 = e;
        }
      }
     while (true);
  };
  function PlayerDataApi() {
    PlayerDataApi_instance = this;
    this.kij_1 = Mutex();
    var tmp = this;
    tmp.lij_1 = Json(VOID, PlayerDataApi$jsonParser$lambda);
    var tmp_0 = this;
    var tmp$ret$0;
    // Inline function 'korlibs.logger.Companion.invoke' call
    var tmp0_invoke = Companion_getInstance_1();
    var tmp0_elvis_lhs = getKClass(PlayerDataApi).zi();
    tmp$ret$0 = tmp0_invoke.b1n(tmp0_elvis_lhs == null ? 'NoClassName' : tmp0_elvis_lhs);
    tmp_0.mij_1 = tmp$ret$0;
  }
  protoOf(PlayerDataApi).ois = function ($completion) {
    var tmp = new $getPlayerDataCOROUTINE$8(this, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  protoOf(PlayerDataApi).nij = function (playerData, $completion) {
    var tmp = new $savePlayerDataCOROUTINE$9(this, playerData, $completion);
    tmp.a8_1 = Unit_getInstance();
    tmp.b8_1 = null;
    return tmp.f8();
  };
  var PlayerDataApi_instance;
  function PlayerDataApi_getInstance() {
    if (PlayerDataApi_instance == null)
      new PlayerDataApi();
    return PlayerDataApi_instance;
  }
  function StagingEntityUtils$createStart$lambda($position, $size) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(EntityStartComponent_getInstance());
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_START_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createFinish$lambda($position, $size) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(EntityFinishComponent_getInstance());
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_FINISH_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createCheckpoint$lambda($position, $size, $sequenceNum) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(new EntityCheckpointComponent($sequenceNum));
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_CHECKPOINT_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createTeleportIn$lambda($position, $size, $sequenceNum) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(new EntityTeleportInComponent($sequenceNum));
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_TELEPORT_IN_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createTeleportOut$lambda($position, $size, $sequenceNum) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(new EntityTeleportOutComponent($sequenceNum));
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_TELEPORT_OUT_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createRock$lambda($rect) {
    return function ($this$$receiver) {
      $this$$receiver.og2(getBottomLeftPositionComponent($rect));
      $this$$receiver.og2(getSizeComponent($rect));
      $this$$receiver.og2(EntityRockComponent_getInstance());
      $this$$receiver.og2(EntityBlockingComponent_getInstance());
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_ROCK_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createSpeedArea$lambda($position, $diameter, $speedEffect) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(new SizeComponent($diameter, $diameter));
      $this$$receiver.og2(new EntitySpeedAreaComponent($speedEffect));
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_SPEED_AREA_getInstance()));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createTower$lambda($position, $size, $cost) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(EntityBlockingComponent_getInstance());
      $this$$receiver.og2(EntityTowerComponent_getInstance());
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_TOWER_getInstance()));
      $this$$receiver.og2(new EntityCostComponent($cost));
      $this$$receiver.og2(new SupplyCostComponent(1));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils$createSupplyDepot$lambda($position, $size, $cost) {
    return function ($this$$receiver) {
      $this$$receiver.og2(toBottomLeftPositionComponent($position));
      $this$$receiver.og2(toSizeComponent($size));
      $this$$receiver.og2(EntityBlockingComponent_getInstance());
      $this$$receiver.og2(EntitySupplyDepotComponent_getInstance());
      $this$$receiver.og2(new EntityTypeComponent(MapEntityType_SUPPLY_DEPOT_getInstance()));
      $this$$receiver.og2(new EntityCostComponent($cost));
      return Unit_getInstance();
    };
  }
  function StagingEntityUtils() {
    StagingEntityUtils_instance = this;
  }
  protoOf(StagingEntityUtils).shs = function (position, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createStart$lambda(position, size));
  };
  protoOf(StagingEntityUtils).hhh = function (position, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.shs(position, size) : $super.shs.call(this, position, size);
  };
  protoOf(StagingEntityUtils).mhs = function (position, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createFinish$lambda(position, size));
  };
  protoOf(StagingEntityUtils).ihh = function (position, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.mhs(position, size) : $super.mhs.call(this, position, size);
  };
  protoOf(StagingEntityUtils).khs = function (sequenceNum, position, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createCheckpoint$lambda(position, size, sequenceNum));
  };
  protoOf(StagingEntityUtils).jhh = function (sequenceNum, position, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.khs(sequenceNum, position, size) : $super.khs.call(this, sequenceNum, position, size);
  };
  protoOf(StagingEntityUtils).ths = function (sequenceNum, position, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createTeleportIn$lambda(position, size, sequenceNum));
  };
  protoOf(StagingEntityUtils).lhh = function (sequenceNum, position, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.ths(sequenceNum, position, size) : $super.ths.call(this, sequenceNum, position, size);
  };
  protoOf(StagingEntityUtils).uhs = function (sequenceNum, position, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createTeleportOut$lambda(position, size, sequenceNum));
  };
  protoOf(StagingEntityUtils).mhh = function (sequenceNum, position, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.uhs(sequenceNum, position, size) : $super.uhs.call(this, sequenceNum, position, size);
  };
  protoOf(StagingEntityUtils).ohh = function (rect) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createRock$lambda(rect));
  };
  protoOf(StagingEntityUtils).qhs = function (position, diameter, speedEffect) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createSpeedArea$lambda(position, diameter, speedEffect));
  };
  protoOf(StagingEntityUtils).llc = function (position, cost, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createTower$lambda(position, size, cost));
  };
  protoOf(StagingEntityUtils).khh = function (position, cost, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.llc(position, cost, size) : $super.llc.call(this, position, cost, size);
  };
  protoOf(StagingEntityUtils).mlc = function (position, cost, size) {
    return StagingEntity_init_$Create$(StagingEntityUtils$createSupplyDepot$lambda(position, size, cost));
  };
  protoOf(StagingEntityUtils).nhh = function (position, cost, size, $super) {
    size = size === VOID ? Companion_getInstance_14().z6e(2, 2) : size;
    return $super === VOID ? this.mlc(position, cost, size) : $super.mlc.call(this, position, cost, size);
  };
  var StagingEntityUtils_instance;
  function StagingEntityUtils_getInstance() {
    if (StagingEntityUtils_instance == null)
      new StagingEntityUtils();
    return StagingEntityUtils_instance;
  }
  function get_DIRECTION_MATCHERS() {
    _init_properties_component_utils_kt__nm8fa5();
    return DIRECTION_MATCHERS;
  }
  var DIRECTION_MATCHERS;
  function toBottomLeftPositionComponent(_this__u8e3s4) {
    _init_properties_component_utils_kt__nm8fa5();
    return new BottomLeftPositionComponent(_this__u8e3s4.dg6_1, _this__u8e3s4.eg6_1);
  }
  function toSizeComponent(_this__u8e3s4) {
    _init_properties_component_utils_kt__nm8fa5();
    return new SizeComponent(_this__u8e3s4.kgb(), _this__u8e3s4.lgb());
  }
  function intersectsEntity(_this__u8e3s4, position, size) {
    _init_properties_component_utils_kt__nm8fa5();
    var thisPosition = _this__u8e3s4.tfz(getKClass(BottomLeftPositionComponent));
    var thisSize = _this__u8e3s4.tfz(getKClass(SizeComponent));
    return intersectRectangles(GameUnit__toDouble_impl_5m2yph(thisPosition.xg3_1), GameUnit__toDouble_impl_5m2yph(thisPosition.yg3_1), GameUnit__toDouble_impl_5m2yph(thisSize.xg4_1), GameUnit__toDouble_impl_5m2yph(thisSize.yg4_1), GameUnit__toDouble_impl_5m2yph(position.dg6_1), GameUnit__toDouble_impl_5m2yph(position.eg6_1), GameUnit__toDouble_impl_5m2yph(size.kgb()), GameUnit__toDouble_impl_5m2yph(size.lgb()));
  }
  function isFullyCoveredBy(_this__u8e3s4, rectangleEntities) {
    _init_properties_component_utils_kt__nm8fa5();
    var thisPosition = _this__u8e3s4.tfz(getKClass(BottomLeftPositionComponent));
    var thisSize = _this__u8e3s4.tfz(getKClass(SizeComponent));
    var tmp$ret$0;
    // Inline function 'kotlin.collections.mutableSetOf' call
    tmp$ret$0 = LinkedHashSet_init_$Create$();
    var entityPoints = tmp$ret$0;
    var tmp0_iterator = until(0, thisSize.xg4_1).h();
    while (tmp0_iterator.j()) {
      var i = tmp0_iterator.k().vg3_1;
      var tmp1_iterator = until(0, thisSize.yg4_1).h();
      while (tmp1_iterator.j()) {
        var j = tmp1_iterator.k().vg3_1;
        entityPoints.b(new GameUnitTuple(GameUnit__plus_impl_rh1b6r(thisPosition.xg3_1, i), GameUnit__plus_impl_rh1b6r(thisPosition.yg3_1, j)));
      }
    }
    println('entityPoints: ' + entityPoints);
    var tmp2_iterator = rectangleEntities.h();
    while (tmp2_iterator.j()) {
      var rectangleEntity = tmp2_iterator.k();
      // Inline function 'kotlin.collections.minusAssign' call
      var tmp0_minusAssign = rectangleEntity.bg6();
      removeAll(entityPoints, tmp0_minusAssign);
      if (entityPoints.p())
        return true;
    }
    return false;
  }
  function intersectRectangles_0(thisPosition, thisSize, otherPosition, otherSize) {
    _init_properties_component_utils_kt__nm8fa5();
    return intersectRectangles(GameUnit__toDouble_impl_5m2yph(thisPosition.dg6_1), GameUnit__toDouble_impl_5m2yph(thisPosition.eg6_1), GameUnit__toDouble_impl_5m2yph(thisSize.kgb()), GameUnit__toDouble_impl_5m2yph(thisSize.lgb()), GameUnit__toDouble_impl_5m2yph(otherPosition.dg6_1), GameUnit__toDouble_impl_5m2yph(otherPosition.eg6_1), GameUnit__toDouble_impl_5m2yph(otherSize.kgb()), GameUnit__toDouble_impl_5m2yph(otherSize.lgb()));
  }
  function DirectionMatcher(closedRange, direction) {
    this.nlc_1 = closedRange;
    this.olc_1 = direction;
  }
  protoOf(DirectionMatcher).toString = function () {
    return 'DirectionMatcher(closedRange=' + this.nlc_1 + ', direction=' + this.olc_1 + ')';
  };
  protoOf(DirectionMatcher).hashCode = function () {
    var result = hashCode(this.nlc_1);
    result = imul(result, 31) + this.olc_1.hashCode() | 0;
    return result;
  };
  protoOf(DirectionMatcher).equals = function (other) {
    if (this === other)
      return true;
    if (!(other instanceof DirectionMatcher))
      return false;
    var tmp0_other_with_cast = other instanceof DirectionMatcher ? other : THROW_CCE();
    if (!equals(this.nlc_1, tmp0_other_with_cast.nlc_1))
      return false;
    if (!this.olc_1.equals(tmp0_other_with_cast.olc_1))
      return false;
    return true;
  };
  function getDirection8(angle) {
    _init_properties_component_utils_kt__nm8fa5();
    var tmp$ret$1;
    $l$block: {
      // Inline function 'kotlin.collections.first' call
      var tmp0_first = get_DIRECTION_MATCHERS();
      var tmp0_iterator = tmp0_first.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$0;
        // Inline function 'com.xenotactic.korge.utils.getDirection8.<anonymous>' call
        tmp$ret$0 = Angle__inBetween_impl_ojsmql(angle, element.nlc_1);
        if (tmp$ret$0) {
          tmp$ret$1 = element;
          break $l$block;
        }
      }
      throw NoSuchElementException_init_$Create$('Collection contains no element matching the predicate.');
    }
    var matcher = tmp$ret$1;
    return matcher.olc_1;
  }
  function getBottomLeftPositionComponent(_this__u8e3s4) {
    _init_properties_component_utils_kt__nm8fa5();
    return new BottomLeftPositionComponent(_this__u8e3s4.jg8(), _this__u8e3s4.kg8());
  }
  function getSizeComponent(_this__u8e3s4) {
    _init_properties_component_utils_kt__nm8fa5();
    return new SizeComponent(_this__u8e3s4.lg8(), _this__u8e3s4.mg8());
  }
  function get_GUN_VIEW_NAME() {
    return GUN_VIEW_NAME;
  }
  var GUN_VIEW_NAME;
  function populateTowerBaseUiEntityContainer(worldWidth, worldHeight, uiEntityContainer) {
    _init_properties_component_utils_kt__nm8fa5();
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = GlobalResources_getInstance().mgv();
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, uiEntityContainer);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.utils.populateTowerBaseUiEntityContainer.<anonymous>' call
    tmp0_apply.zdx_1 = false;
    tmp0_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
    tmp0_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    var tmp$ret$5;
    // Inline function 'korlibs.korge.view.image' call
    var tmp6_image = GlobalResources_getInstance().ngv();
    var tmp7_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$4;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp5_addTo = Image_init_$Create$(tmp6_image, tmp7_image);
    var tmp$ret$3;
    // Inline function 'kotlin.apply' call
    var tmp4_apply = addTo(tmp5_addTo, uiEntityContainer);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.utils.populateTowerBaseUiEntityContainer.<anonymous>' call
    tmp4_apply.zdx_1 = false;
    tmp4_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
    tmp4_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
    tmp$ret$3 = tmp4_apply;
    tmp$ret$4 = tmp$ret$3;
    tmp$ret$5 = tmp$ret$4;
  }
  function createUIEntityContainerForTower(worldWidth, worldHeight, towerType, uiEntityContainer) {
    towerType = towerType === VOID ? TowerType_BASIC_getInstance() : towerType;
    uiEntityContainer = uiEntityContainer === VOID ? new Container() : uiEntityContainer;
    _init_properties_component_utils_kt__nm8fa5();
    populateTowerBaseUiEntityContainer(worldWidth, worldHeight, uiEntityContainer);
    var tmp0_subject = towerType;
    var tmp0 = tmp0_subject.b2_1;
    if (tmp0 === 0) {
      var tmp$ret$4;
      // Inline function 'korlibs.korge.view.image' call
      var tmp2_image = GlobalResources_getInstance().jgv();
      var tmp3_image = Companion_getInstance_0().k3q_1;
      var tmp$ret$3;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp1_addTo = Image_init_$Create$(tmp2_image, tmp3_image);
      var tmp$ret$2;
      // Inline function 'kotlin.apply' call
      var tmp0_apply = addTo(tmp1_addTo, uiEntityContainer);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.utils.createUIEntityContainerForTower.<anonymous>' call
      tmp0_apply.zdx_1 = false;
      anchor(tmp0_apply, Companion_getInstance_0().x3q());
      xy_1(tmp0_apply, WorldUnit__div_impl_b90n6a(worldWidth, 2), WorldUnit__div_impl_b90n6a(worldHeight, 2));
      scaleWhileMaintainingAspect(tmp0_apply, new ByWidthAndHeight(WorldUnit__toDouble_impl_9mljzf(worldWidth), WorldUnit__toDouble_impl_9mljzf(worldHeight)));
      var tmp$ret$1;
      // Inline function 'korlibs.korge.view.name' call
      var tmp0_name = 'gun';
      var tmp$ret$0;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.name.<anonymous>' call
      tmp0_apply.y9r_1 = tmp0_name;
      tmp$ret$0 = tmp0_apply;
      tmp$ret$1 = tmp$ret$0;
      tmp$ret$2 = tmp0_apply;
      tmp$ret$3 = tmp$ret$2;
      tmp$ret$4 = tmp$ret$3;
    } else if (tmp0 === 1) {
      var tmp$ret$9;
      // Inline function 'korlibs.korge.view.image' call
      var tmp6_image = GlobalResources_getInstance().kgv();
      var tmp7_image = Companion_getInstance_0().k3q_1;
      var tmp$ret$8;
      // Inline function 'korlibs.korge.view.addTo' call
      var tmp5_addTo = Image_init_$Create$(tmp6_image, tmp7_image);
      var tmp$ret$7;
      // Inline function 'kotlin.apply' call
      var tmp4_apply = addTo(tmp5_addTo, uiEntityContainer);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'com.xenotactic.korge.utils.createUIEntityContainerForTower.<anonymous>' call
      tmp4_apply.zdx_1 = false;
      anchor(tmp4_apply, Companion_getInstance_0().x3q());
      xy_1(tmp4_apply, WorldUnit__div_impl_b90n6a(worldWidth, 2), WorldUnit__div_impl_b90n6a(worldHeight, 2));
      scaleWhileMaintainingAspect(tmp4_apply, new ByWidthAndHeight(WorldUnit__toDouble_impl_9mljzf(worldWidth), WorldUnit__toDouble_impl_9mljzf(worldHeight)));
      var tmp$ret$6;
      // Inline function 'korlibs.korge.view.name' call
      var tmp0_name_0 = 'gun';
      var tmp$ret$5;
      // Inline function 'kotlin.also' call
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.korge.view.name.<anonymous>' call
      tmp4_apply.y9r_1 = tmp0_name_0;
      tmp$ret$5 = tmp4_apply;
      tmp$ret$6 = tmp$ret$5;
      tmp$ret$7 = tmp4_apply;
      tmp$ret$8 = tmp$ret$7;
      tmp$ret$9 = tmp$ret$8;
    }
    return uiEntityContainer;
  }
  function createUIEntityContainerForSupplyDepot(worldWidth, worldHeight, uiEntityContainer) {
    uiEntityContainer = uiEntityContainer === VOID ? new Container() : uiEntityContainer;
    _init_properties_component_utils_kt__nm8fa5();
    populateTowerBaseUiEntityContainer(worldWidth, worldHeight, uiEntityContainer);
    var tmp$ret$2;
    // Inline function 'korlibs.korge.view.image' call
    var tmp2_image = GlobalResources_getInstance().lgv();
    var tmp3_image = Companion_getInstance_0().k3q_1;
    var tmp$ret$1;
    // Inline function 'korlibs.korge.view.addTo' call
    var tmp1_addTo = Image_init_$Create$(tmp2_image, tmp3_image);
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = addTo(tmp1_addTo, uiEntityContainer);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'com.xenotactic.korge.utils.createUIEntityContainerForSupplyDepot.<anonymous>' call
    tmp0_apply.zdx_1 = false;
    tmp0_apply.ebm(WorldUnit__toFloat_impl_2n0xxa(worldWidth));
    tmp0_apply.gbm(WorldUnit__toFloat_impl_2n0xxa(worldHeight));
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0;
    tmp$ret$2 = tmp$ret$1;
    return uiEntityContainer;
  }
  var properties_initialized_component_utils_kt_1aj5r3;
  function _init_properties_component_utils_kt__nm8fa5() {
    if (properties_initialized_component_utils_kt_1aj5r3) {
    } else {
      properties_initialized_component_utils_kt_1aj5r3 = true;
      DIRECTION_MATCHERS = listOf_0([new DirectionMatcher(rangeTo_0(new Angle(get_degrees(330)), new Angle(get_degrees(30))), EightDirection_RIGHT_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(60)), new Angle(get_degrees(120))), EightDirection_UP_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(150)), new Angle(get_degrees(210))), EightDirection_LEFT_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(240)), new Angle(get_degrees(300))), EightDirection_DOWN_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(30)), new Angle(get_degrees(60))), EightDirection_UP_RIGHT_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(120)), new Angle(get_degrees(150))), EightDirection_UP_LEFT_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(210)), new Angle(get_degrees(240))), EightDirection_DOWN_LEFT_getInstance()), new DirectionMatcher(rangeTo_0(new Angle(get_degrees(300)), new Angle(get_degrees(330))), EightDirection_DOWN_RIGHT_getInstance())]);
    }
  }
  function getRoundedGridCoordinates(gridX, gridY, entityWidth, entityHeight, mapWidth, mapHeight) {
    var tmp;
    if (GameUnit__toInt_impl_9qttgv(entityWidth) === 1) {
      var tmp$ret$0;
      // Inline function 'kotlin.math.floor' call
      var tmp0_floor = numberToDouble(gridX) - _GameUnit___get_value__impl__9ocgox(entityWidth) / 2;
      tmp$ret$0 = Math.floor(tmp0_floor);
      tmp = numberToInt(tmp$ret$0);
    } else {
      tmp = roundToInt(numberToDouble(gridX) - _GameUnit___get_value__impl__9ocgox(entityWidth) / 2);
    }
    var roundedGridX = tmp;
    var tmp_0;
    if (GameUnit__toInt_impl_9qttgv(entityHeight) === 1) {
      var tmp$ret$1;
      // Inline function 'kotlin.math.floor' call
      var tmp1_floor = numberToDouble(gridY) - _GameUnit___get_value__impl__9ocgox(entityHeight) / 2;
      tmp$ret$1 = Math.floor(tmp1_floor);
      tmp_0 = numberToInt(tmp$ret$1);
    } else {
      tmp_0 = roundToInt(numberToDouble(gridY) - _GameUnit___get_value__impl__9ocgox(entityHeight) / 2);
    }
    var roundedGridY = tmp_0;
    var gridXToInt = toGameUnit(clamp(roundedGridX, 0, GameUnit__toInt_impl_9qttgv(mapWidth) - GameUnit__toInt_impl_9qttgv(entityWidth) | 0));
    var gridYToInt = toGameUnit(clamp(roundedGridY, 0, GameUnit__toInt_impl_9qttgv(mapHeight) - GameUnit__toInt_impl_9qttgv(entityHeight) | 0));
    return new GameUnitTuple(gridXToInt, gridYToInt);
  }
  function getReferenceParent(_this__u8e3s4) {
    var parentView = ensureNotNull(_this__u8e3s4.np());
    var tmp0_elvis_lhs = parentView.ybj();
    return tmp0_elvis_lhs == null ? parentView : tmp0_elvis_lhs;
  }
  function alignLeftToLeftOfWindow(_this__u8e3s4) {
    _this__u8e3s4.cbl(getVisibleLocalArea(getReferenceParent(_this__u8e3s4)).z3r_1);
    return _this__u8e3s4;
  }
  function alignTopToTopOfWindow(_this__u8e3s4) {
    _this__u8e3s4.dbl(getVisibleLocalArea(getReferenceParent(_this__u8e3s4)).a3s_1);
    return _this__u8e3s4;
  }
  function alignBottomToBottomOfWindow(_this__u8e3s4) {
    var windowsArea = getVisibleGlobalArea(_this__u8e3s4);
    return alignBottomToBottomOfWindow_0(_this__u8e3s4, numberToInt(windowsArea.b3s_1) + numberToInt(windowsArea.z3r_1) | 0, numberToInt(windowsArea.c3s_1));
  }
  function alignBottomToBottomOfWindow_0(_this__u8e3s4, resizedWidth, resizedHeight, yOffset) {
    yOffset = yOffset === VOID ? 0 : yOffset;
    var refParent = getReferenceParent(_this__u8e3s4);
    var resizeWHToLocal = refParent.fao(Vector2_init_$Create$_1(resizedWidth, resizedHeight));
    _this__u8e3s4.dbl(resizeWHToLocal.q3r_1 + yOffset - _this__u8e3s4.hbm());
    return _this__u8e3s4;
  }
  function alignRightToRightOfWindow(_this__u8e3s4, padding) {
    padding = padding === VOID ? 0.0 : padding;
    _this__u8e3s4.cbl(getVisibleGlobalArea(getReferenceParent(_this__u8e3s4)).b3s_1 - _this__u8e3s4.x18() - padding);
    return _this__u8e3s4;
  }
  function scaledDimensions(_this__u8e3s4) {
    return new Pair(_this__u8e3s4.fbm(), _this__u8e3s4.hbm());
  }
  function isScrollDown(_this__u8e3s4) {
    var event = _this__u8e3s4.aaa_1;
    var tmp;
    if (event.o7e_1.equals(Type_SCROLL_getInstance()) ? event.s7e_1.equals(MouseButton_BUTTON_WHEEL_getInstance()) : false) {
      var tmp$ret$0;
      // Inline function 'korlibs.event.MouseEvent.scrollDeltaYLines' call
      tmp$ret$0 = event.i7f(ScrollDeltaMode_LINE_getInstance());
      tmp = tmp$ret$0 > 0;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function isScrollUp(_this__u8e3s4) {
    var event = _this__u8e3s4.aaa_1;
    var tmp;
    if (event.o7e_1.equals(Type_SCROLL_getInstance()) ? event.s7e_1.equals(MouseButton_BUTTON_WHEEL_getInstance()) : false) {
      var tmp$ret$0;
      // Inline function 'korlibs.event.MouseEvent.scrollDeltaYLines' call
      tmp$ret$0 = event.i7f(ScrollDeltaMode_LINE_getInstance());
      tmp = tmp$ret$0 < 0;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function makeEntityLabelText(text) {
    return new Text(text, get_ENTITY_LABEL_SIZE(), VOID, get_ENTITY_TEXT_FONT());
  }
  function getText_0(_this__u8e3s4, entityId, world) {
    var tmp0_subject = _this__u8e3s4;
    var tmp0 = tmp0_subject.b2_1;
    var tmp;
    switch (tmp0) {
      case 0:
        tmp = 'START';
        break;
      case 1:
        tmp = 'FINISH';
        break;
      case 2:
        var entityCheckpointComponent = world.lg3(entityId, getKClass(EntityCheckpointComponent));
        tmp = 'CP ' + entityCheckpointComponent.eg4();
        break;
      case 3:
        tmp = null;
        break;
      case 4:
        tmp = null;
        break;
      case 5:
        var entityTeleportInComponent = world.lg3(entityId, getKClass(EntityTeleportInComponent));
        tmp = 'TP ' + entityTeleportInComponent.eg4() + ' IN';
        break;
      case 6:
        var entityTeleportOutComponent = world.lg3(entityId, getKClass(EntityTeleportOutComponent));
        tmp = 'TP ' + entityTeleportOutComponent.eg4() + ' OUT';
        break;
      case 7:
        tmp = null;
        break;
      case 8:
        var entitySpeedAreaComponent = world.lg3(entityId, getKClass(EntitySpeedAreaComponent));
        tmp = entitySpeedAreaComponent.ig4();
        break;
      case 9:
        tmp = null;
        break;
      case 10:
        tmp = null;
        break;
      default:
        noWhenBranchMatchedException();
        break;
    }
    return tmp;
  }
  function isEmpty(_this__u8e3s4) {
    return firstOrNull_0(_this__u8e3s4) == null;
  }
  function distributeVertically(views) {
    windowed(views, 2, VOID, VOID, distributeVertically$lambda);
  }
  function toWorldCoordinates_1(gridSize, entity, gameWidth, gameHeight) {
    var position = entity.tfz(getKClass(BottomLeftPositionComponent));
    var size = entity.tfz(getKClass(SizeComponent));
    return toWorldCoordinates_0(gridSize, position.zg3(), gameHeight, size.yg4_1);
  }
  function toWorldCoordinates_2(gridSize, entity, gameWidth, gameHeight) {
    return toWorldCoordinates_0(gridSize, entity.zgg(), gameHeight, entity.mg8());
  }
  function distributeVertically$lambda(it) {
    return alignTopToBottomOf(it.n(1), it.n(0));
  }
  //region block: post-declaration
  protoOf(MutableShowRangeTimeComponentListener).pg0 = onAdd;
  protoOf(MutableShowRangeTimeComponentListener).ng0 = onReplace;
  protoOf(MutableShowRangeTimeComponentListener).ag1 = onExisting;
  protoOf(PreSelectionComponentListener).pg0 = onAdd;
  protoOf(PreSelectionComponentListener).ng0 = onReplace;
  protoOf(SelectionComponentListener).pg0 = onAdd;
  protoOf(SelectionComponentListener).ng0 = onReplace;
  protoOf(UIMapEntityComponentListener).pg0 = onAdd;
  protoOf(UIMapEntityComponentListener).ng0 = onReplace;
  protoOf(UIMapEntityComponentListener).rg0 = onAddOrReplace;
  protoOf(UIMapEntityComponentListener).ag1 = onExisting;
  protoOf(UIMapEntityTextComponentListener).pg0 = onAdd;
  protoOf(UIMapEntityTextComponentListener).ng0 = onReplace;
  protoOf(UIMapEntityTextComponentListener).rg0 = onAddOrReplace;
  protoOf(UIShowRangeComponentListener).pg0 = onAdd;
  protoOf(UIShowRangeComponentListener).ng0 = onReplace;
  protoOf(UIShowRangeComponentListener).rg0 = onAddOrReplace;
  protoOf(UIShowRangeComponentListener).ag1 = onExisting;
  protoOf(UIActiveTextNotifier).z7c = dispatch$default;
  protoOf(UIActiveTextNotifier).b7d = dispatch;
  protoOf(UIActiveTextNotifier).a7d = onEvents;
  protoOf(UIDebugInfo).z7c = dispatch$default;
  protoOf(UIDebugInfo).b7d = dispatch;
  protoOf(UIDebugInfo).a7d = onEvents;
  protoOf(UIDropDownEntry).z7c = dispatch$default;
  protoOf(UIDropDownEntry).b7d = dispatch;
  protoOf(UIDropDownEntry).a7d = onEvents;
  protoOf(UIDropdown).z7c = dispatch$default;
  protoOf(UIDropdown).b7d = dispatch;
  protoOf(UIDropdown).a7d = onEvents;
  protoOf(UIEditorButtonsV2).z7c = dispatch$default;
  protoOf(UIEditorButtonsV2).b7d = dispatch;
  protoOf(UIEditorButtonsV2).a7d = onEvents;
  protoOf(UIFixedGrid).z7c = dispatch$default;
  protoOf(UIFixedGrid).b7d = dispatch;
  protoOf(UIFixedGrid).a7d = onEvents;
  protoOf(UIGoldensViewerEntry).z7c = dispatch$default;
  protoOf(UIGoldensViewerEntry).b7d = dispatch;
  protoOf(UIGoldensViewerEntry).a7d = onEvents;
  protoOf(UIGuiContainer$4).pg0 = onAdd;
  protoOf(UIGuiContainer$4).ng0 = onReplace;
  protoOf(UIGuiContainer$4).wg0 = onRemove;
  protoOf(UIGuiContainer$4).ag1 = onExisting;
  protoOf(UIGuiContainer$5).pg0 = onAdd;
  protoOf(UIGuiContainer$5).ng0 = onReplace;
  protoOf(UIGuiContainer$5).wg0 = onRemove;
  protoOf(UIGuiContainer$5).ag1 = onExisting;
  protoOf(UIHeader).z7c = dispatch$default;
  protoOf(UIHeader).b7d = dispatch;
  protoOf(UIHeader).a7d = onEvents;
  protoOf(UIMap).z7c = dispatch$default;
  protoOf(UIMap).b7d = dispatch;
  protoOf(UIMap).a7d = onEvents;
  protoOf(UIMapBox).z7c = dispatch$default;
  protoOf(UIMapBox).b7d = dispatch;
  protoOf(UIMapBox).a7d = onEvents;
  protoOf(UIMapEntry).z7c = dispatch$default;
  protoOf(UIMapEntry).b7d = dispatch;
  protoOf(UIMapEntry).a7d = onEvents;
  protoOf(UIMapInspector).z7c = dispatch$default;
  protoOf(UIMapInspector).b7d = dispatch;
  protoOf(UIMapInspector).a7d = onEvents;
  protoOf(UIMapOverlay).z7c = dispatch$default;
  protoOf(UIMapOverlay).b7d = dispatch;
  protoOf(UIMapOverlay).a7d = onEvents;
  protoOf(UIMapV2).z7c = dispatch$default;
  protoOf(UIMapV2).b7d = dispatch;
  protoOf(UIMapV2).a7d = onEvents;
  protoOf(UINotificationText).z7c = dispatch$default;
  protoOf(UINotificationText).b7d = dispatch;
  protoOf(UINotificationText).a7d = onEvents;
  protoOf(UIPathText).z7c = dispatch$default;
  protoOf(UIPathText).b7d = dispatch;
  protoOf(UIPathText).a7d = onEvents;
  protoOf(UIPlacement).z7c = dispatch$default;
  protoOf(UIPlacement).b7d = dispatch;
  protoOf(UIPlacement).a7d = onEvents;
  protoOf(UITextRect).z7c = dispatch$default;
  protoOf(UITextRect).b7d = dispatch;
  protoOf(UITextRect).a7d = onEvents;
  protoOf(UITextWithShadow).z7c = dispatch$default;
  protoOf(UITextWithShadow).b7d = dispatch;
  protoOf(UITextWithShadow).a7d = onEvents;
  protoOf(UITooltipDescription).z7c = dispatch$default;
  protoOf(UITooltipDescription).b7d = dispatch;
  protoOf(UITooltipDescription).a7d = onEvents;
  protoOf(UITopRightResourcesGui).z7c = dispatch$default;
  protoOf(UITopRightResourcesGui).b7d = dispatch;
  protoOf(UITopRightResourcesGui).a7d = onEvents;
  protoOf(UITowerDetails).z7c = dispatch$default;
  protoOf(UITowerDetails).b7d = dispatch;
  protoOf(UITowerDetails).a7d = onEvents;
  protoOf(UITowerUpgradeIcon).z7c = dispatch$default;
  protoOf(UITowerUpgradeIcon).b7d = dispatch;
  protoOf(UITowerUpgradeIcon).a7d = onEvents;
  //endregion
  //region block: init
  GUN_VIEW_NAME = 'gun';
  //endregion
  main(get_EmptyContinuation());
  return _;
}));
