(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-krypto'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-krypto'.");
    }
    root['korge-root-krypto'] = factory(typeof this['korge-root-krypto'] === 'undefined' ? {} : this['korge-root-krypto'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var imul = Math.imul;
  var VOID = kotlin_kotlin.$_$.mj;
  var charSequenceGet = kotlin_kotlin.$_$.wb;
  var Char__toInt_impl_vasixd = kotlin_kotlin.$_$.o2;
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var toByte = kotlin_kotlin.$_$.pd;
  var copyOf = kotlin_kotlin.$_$.v6;
  var protoOf = kotlin_kotlin.$_$.nd;
  var replace = kotlin_kotlin.$_$.zf;
  var objectMeta = kotlin_kotlin.$_$.md;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var charSequenceLength = kotlin_kotlin.$_$.xb;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var Char__plus_impl_qi7pgj = kotlin_kotlin.$_$.l2;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.d1;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Base64, 'Base64', objectMeta);
  setMetadataFor(Hex, 'Hex', objectMeta);
  //endregion
  function fromBase64(_this__u8e3s4, ignoreSpaces, url) {
    ignoreSpaces = ignoreSpaces === VOID ? false : ignoreSpaces;
    url = url === VOID ? false : url;
    return ignoreSpaces ? Base64_getInstance().v1l(_this__u8e3s4, url) : Base64_getInstance().u1l(_this__u8e3s4, url);
  }
  function readU8(_this__u8e3s4, $this, index) {
    return _this__u8e3s4[index] & 255;
  }
  function Base64() {
    Base64_instance = this;
    this.q1l_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    this.r1l_1 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_=';
    this.s1l_1 = toDecodeArray(this.q1l_1);
    this.t1l_1 = toDecodeArray(this.r1l_1);
  }
  protoOf(Base64).u1l = function (str, url) {
    var tmp = 0;
    var tmp_0 = str.length;
    var tmp_1 = new Int8Array(tmp_0);
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$1;
      // Inline function 'korlibs.crypto.encoding.Base64.decode.<anonymous>' call
      var tmp$ret$0;
      // Inline function 'kotlin.code' call
      var tmp0__get_code__88qj9g = charSequenceGet(str, tmp_2);
      tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
      tmp$ret$1 = toByte(tmp$ret$0);
      tmp_1[tmp_2] = tmp$ret$1;
      tmp = tmp + 1 | 0;
    }
    var src = tmp_1;
    var dst = new Int8Array(src.length);
    return copyOf(dst, this.w1l(src, dst, url));
  };
  protoOf(Base64).v1l = function (str, url) {
    return this.u1l(replace(replace(replace(replace(str, ' ', ''), '\n', ''), '\r', ''), '\t', ''), url);
  };
  protoOf(Base64).w1l = function (src, dst, url) {
    var tmp;
    if (url) {
      tmp = this.t1l_1;
    } else {
      tmp = this.s1l_1;
    }
    var decodeArray = tmp;
    var m = 0;
    var n = 0;
    $l$loop: while (n < src.length) {
      var d = decodeArray[readU8(src, this, n)];
      if (d < 0) {
        var tmp0 = n;
        n = tmp0 + 1 | 0;
        continue $l$loop;
      }
      var tmp_0;
      if (n < src.length) {
        var tmp1 = n;
        n = tmp1 + 1 | 0;
        tmp_0 = decodeArray[readU8(src, this, tmp1)];
      } else {
        tmp_0 = 64;
      }
      var b0 = tmp_0;
      var tmp_1;
      if (n < src.length) {
        var tmp2 = n;
        n = tmp2 + 1 | 0;
        tmp_1 = decodeArray[readU8(src, this, tmp2)];
      } else {
        tmp_1 = 64;
      }
      var b1 = tmp_1;
      var tmp_2;
      if (n < src.length) {
        var tmp3 = n;
        n = tmp3 + 1 | 0;
        tmp_2 = decodeArray[readU8(src, this, tmp3)];
      } else {
        tmp_2 = 64;
      }
      var b2 = tmp_2;
      var tmp_3;
      if (n < src.length) {
        var tmp4 = n;
        n = tmp4 + 1 | 0;
        tmp_3 = decodeArray[readU8(src, this, tmp4)];
      } else {
        tmp_3 = 64;
      }
      var b3 = tmp_3;
      var tmp5 = m;
      m = tmp5 + 1 | 0;
      dst[tmp5] = toByte(b0 << 2 | b1 >> 4);
      if (b2 < 64) {
        var tmp6 = m;
        m = tmp6 + 1 | 0;
        dst[tmp6] = toByte(b1 << 4 | b2 >> 2);
        if (b3 < 64) {
          var tmp7 = m;
          m = tmp7 + 1 | 0;
          dst[tmp7] = toByte(b2 << 6 | b3);
        }
      }
    }
    return m;
  };
  var Base64_instance;
  function Base64_getInstance() {
    if (Base64_instance == null)
      new Base64();
    return Base64_instance;
  }
  function toDecodeArray(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.also' call
    var tmp0_also = new Int32Array(256);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.crypto.encoding.toDecodeArray.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable <= 255)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        tmp0_also[n] = -1;
      }
       while (inductionVariable <= 255);
    var inductionVariable_0 = 0;
    var last = charSequenceLength(_this__u8e3s4) - 1 | 0;
    if (inductionVariable_0 <= last)
      do {
        var n_0 = inductionVariable_0;
        inductionVariable_0 = inductionVariable_0 + 1 | 0;
        var tmp$ret$0;
        // Inline function 'kotlin.code' call
        var tmp0__get_code__88qj9g = charSequenceGet(_this__u8e3s4, n_0);
        tmp$ret$0 = Char__toInt_impl_vasixd(tmp0__get_code__88qj9g);
        tmp0_also[tmp$ret$0] = n_0;
      }
       while (inductionVariable_0 <= last);
    tmp$ret$1 = tmp0_also;
    return tmp$ret$1;
  }
  function Hex() {
    Hex_instance = this;
  }
  protoOf(Hex).x1l = function (v) {
    return v >= 10 ? Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(97), v - 10 | 0) : Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(48), v);
  };
  protoOf(Hex).y1l = function (v) {
    return v >= 10 ? Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(65), v - 10 | 0) : Char__plus_impl_qi7pgj(_Char___init__impl__6a9atx(48), v);
  };
  protoOf(Hex).z1l = function (appender, value) {
    appender.ib(this.x1l((value >>> 4 | 0) & 15));
    appender.ib(this.x1l((value >>> 0 | 0) & 15));
  };
  protoOf(Hex).a1m = function (src) {
    var tmp$ret$4;
    // Inline function 'korlibs.crypto.encoding.Hex.encodeBase' call
    var tmp$ret$3;
    // Inline function 'kotlin.text.buildString' call
    var tmp1_buildString = imul(src.length, 2);
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$2;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$(tmp1_buildString);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.crypto.encoding.Hex.encodeBase.<anonymous>' call
    // Inline function 'korlibs.crypto.encoding.Hex.encode' call
    var tmp0_encode = Hex_getInstance();
    var inductionVariable = 0;
    var last = src.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = src[n] & 255;
        var tmp$ret$0;
        // Inline function 'korlibs.crypto.encoding.Hex.encodeLower.<anonymous>' call
        var tmp2__anonymous__z9zvc9 = (v >>> 4 | 0) & 15;
        tmp$ret$0 = Hex_getInstance().x1l(tmp2__anonymous__z9zvc9);
        tmp0_apply.ib(tmp$ret$0);
        var tmp$ret$1;
        // Inline function 'korlibs.crypto.encoding.Hex.encodeLower.<anonymous>' call
        var tmp3__anonymous__ufb84q = (v >>> 0 | 0) & 15;
        tmp$ret$1 = Hex_getInstance().x1l(tmp3__anonymous__ufb84q);
        tmp0_apply.ib(tmp$ret$1);
      }
       while (inductionVariable <= last);
    tmp$ret$2 = tmp0_apply;
    tmp$ret$3 = tmp$ret$2.toString();
    tmp$ret$4 = tmp$ret$3;
    return tmp$ret$4;
  };
  var Hex_instance;
  function Hex_getInstance() {
    if (Hex_instance == null)
      new Hex();
    return Hex_instance;
  }
  function get_hex(_this__u8e3s4) {
    return '0x' + get_shex(_this__u8e3s4);
  }
  function get_hex_0(_this__u8e3s4) {
    return Hex_getInstance().a1m(_this__u8e3s4);
  }
  function get_shex(_this__u8e3s4) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$(8);
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.crypto.encoding.<get-shex>.<anonymous>' call
    var inductionVariable = 0;
    if (inductionVariable < 8)
      do {
        var n = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var v = (_this__u8e3s4 >>> imul(7 - n | 0, 4) | 0) & 15;
        tmp0_apply.ib(Hex_getInstance().y1l(v));
      }
       while (inductionVariable < 8);
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  }
  function appendHexByte(_this__u8e3s4, value) {
    return Hex_getInstance().z1l(_this__u8e3s4, value);
  }
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Hex_getInstance;
  _.$_$.b = appendHexByte;
  _.$_$.c = fromBase64;
  _.$_$.d = get_hex_0;
  _.$_$.e = get_hex;
  //endregion
  return _;
}));
