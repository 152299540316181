(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports', './kotlin-kotlin-stdlib-js-ir.js'], factory);
  else if (typeof exports === 'object')
    factory(module.exports, require('./kotlin-kotlin-stdlib-js-ir.js'));
  else {
    if (typeof this['kotlin-kotlin-stdlib-js-ir'] === 'undefined') {
      throw new Error("Error loading module 'korge-root-klogger'. Its dependency 'kotlin-kotlin-stdlib-js-ir' was not found. Please, check whether 'kotlin-kotlin-stdlib-js-ir' is loaded prior to 'korge-root-klogger'.");
    }
    root['korge-root-klogger'] = factory(typeof this['korge-root-klogger'] === 'undefined' ? {} : this['korge-root-klogger'], this['kotlin-kotlin-stdlib-js-ir']);
  }
}(this, function (_, kotlin_kotlin) {
  'use strict';
  //region block: imports
  var Unit_getInstance = kotlin_kotlin.$_$.z4;
  var Enum = kotlin_kotlin.$_$.rh;
  var protoOf = kotlin_kotlin.$_$.nd;
  var classMeta = kotlin_kotlin.$_$.zb;
  var VOID = kotlin_kotlin.$_$.mj;
  var setMetadataFor = kotlin_kotlin.$_$.od;
  var _Char___init__impl__6a9atx = kotlin_kotlin.$_$.h2;
  var interfaceMeta = kotlin_kotlin.$_$.nc;
  var println = kotlin_kotlin.$_$.pb;
  var StringBuilder_init_$Create$ = kotlin_kotlin.$_$.e1;
  var joinTo = kotlin_kotlin.$_$.d8;
  var replace = kotlin_kotlin.$_$.ag;
  var mapCapacity = kotlin_kotlin.$_$.r8;
  var coerceAtLeast = kotlin_kotlin.$_$.ce;
  var LinkedHashMap_init_$Create$ = kotlin_kotlin.$_$.t;
  var objectMeta = kotlin_kotlin.$_$.md;
  var THROW_ISE = kotlin_kotlin.$_$.di;
  var emptyMap = kotlin_kotlin.$_$.f7;
  var HashMap_init_$Create$ = kotlin_kotlin.$_$.p;
  var ensureNotNull = kotlin_kotlin.$_$.ti;
  var KMutableProperty1 = kotlin_kotlin.$_$.pe;
  var getPropertyCallableRef = kotlin_kotlin.$_$.jc;
  var to = kotlin_kotlin.$_$.lj;
  var LinkedHashMap_init_$Create$_0 = kotlin_kotlin.$_$.u;
  var charArrayOf = kotlin_kotlin.$_$.ub;
  var split = kotlin_kotlin.$_$.cg;
  var get_lastIndex = kotlin_kotlin.$_$.f8;
  var ArrayList_init_$Create$ = kotlin_kotlin.$_$.l;
  var THROW_CCE = kotlin_kotlin.$_$.bi;
  var isArray = kotlin_kotlin.$_$.oc;
  var fillArrayVal = kotlin_kotlin.$_$.ec;
  var trimStart = kotlin_kotlin.$_$.fh;
  var ArrayList_init_$Create$_0 = kotlin_kotlin.$_$.k;
  var firstOrNull = kotlin_kotlin.$_$.l7;
  var toMap = kotlin_kotlin.$_$.ga;
  var KProperty0 = kotlin_kotlin.$_$.qe;
  var lazy = kotlin_kotlin.$_$.cj;
  //endregion
  //region block: pre-declaration
  setMetadataFor(Color, 'Color', classMeta, Enum);
  function appendAnsiScape(_this__u8e3s4, code, extra, char) {
    _this__u8e3s4.ib(_Char___init__impl__6a9atx(27));
    _this__u8e3s4.ib(_Char___init__impl__6a9atx(91));
    _this__u8e3s4.ac(code);
    if (!(extra == null)) {
      _this__u8e3s4.bc(extra);
    }
    _this__u8e3s4.ib(char);
    return _this__u8e3s4;
  }
  function appendAnsiScape$default(_this__u8e3s4, code, extra, char, $super) {
    extra = extra === VOID ? null : extra;
    char = char === VOID ? _Char___init__impl__6a9atx(109) : char;
    return $super === VOID ? this.e1m(_this__u8e3s4, code, extra, char) : appendAnsiScape(_this__u8e3s4, code, extra, char);
  }
  function appendReset(_this__u8e3s4) {
    return this.f1m(_this__u8e3s4, 0);
  }
  function appendFgColor(_this__u8e3s4, color, bright) {
    return this.f1m(_this__u8e3s4, 30 + color.d1m_1 | 0, bright ? ';1' : null);
  }
  function appendFgColor$default(_this__u8e3s4, color, bright, $super) {
    bright = bright === VOID ? false : bright;
    return $super === VOID ? this.h1m(_this__u8e3s4, color, bright) : appendFgColor(_this__u8e3s4, color, bright);
  }
  setMetadataFor(AnsiEscape, 'AnsiEscape', interfaceMeta);
  setMetadataFor(Kind, 'Kind', classMeta, Enum);
  setMetadataFor(BaseConsole, 'BaseConsole', classMeta, VOID, [AnsiEscape]);
  setMetadataFor(Companion, 'Companion', objectMeta);
  setMetadataFor(Companion_0, 'Companion', objectMeta);
  setMetadataFor(Level, 'Level', classMeta, Enum);
  setMetadataFor(ConsoleLogOutput, 'ConsoleLogOutput', objectMeta);
  setMetadataFor(Logger, 'Logger', classMeta);
  setMetadataFor(Console, 'Console', objectMeta, BaseConsole);
  setMetadataFor(DefaultLogOutput, 'DefaultLogOutput', objectMeta);
  setMetadataFor(KloggerAtomicRef, 'KloggerAtomicRef', classMeta);
  //endregion
  var Color_BLACK_instance;
  var Color_RED_instance;
  var Color_GREEN_instance;
  var Color_YELLOW_instance;
  var Color_BLUE_instance;
  var Color_PURPLE_instance;
  var Color_CYAN_instance;
  var Color_WHITE_instance;
  var Color_entriesInitialized;
  function Color_initEntries() {
    if (Color_entriesInitialized)
      return Unit_getInstance();
    Color_entriesInitialized = true;
    Color_BLACK_instance = new Color('BLACK', 0, 0);
    Color_RED_instance = new Color('RED', 1, 1);
    Color_GREEN_instance = new Color('GREEN', 2, 2);
    Color_YELLOW_instance = new Color('YELLOW', 3, 3);
    Color_BLUE_instance = new Color('BLUE', 4, 4);
    Color_PURPLE_instance = new Color('PURPLE', 5, 5);
    Color_CYAN_instance = new Color('CYAN', 6, 6);
    Color_WHITE_instance = new Color('WHITE', 7, 7);
  }
  function Color(name, ordinal, index) {
    Enum.call(this, name, ordinal);
    this.d1m_1 = index;
  }
  function Color_RED_getInstance() {
    Color_initEntries();
    return Color_RED_instance;
  }
  function Color_GREEN_getInstance() {
    Color_initEntries();
    return Color_GREEN_instance;
  }
  function Color_YELLOW_getInstance() {
    Color_initEntries();
    return Color_YELLOW_instance;
  }
  function Color_BLUE_getInstance() {
    Color_initEntries();
    return Color_BLUE_instance;
  }
  function Color_CYAN_getInstance() {
    Color_initEntries();
    return Color_CYAN_instance;
  }
  function AnsiEscape() {
  }
  var baseConsoleHook;
  var Kind_ERROR_instance;
  var Kind_WARN_instance;
  var Kind_INFO_instance;
  var Kind_DEBUG_instance;
  var Kind_TRACE_instance;
  var Kind_LOG_instance;
  var Kind_entriesInitialized;
  function Kind_initEntries() {
    if (Kind_entriesInitialized)
      return Unit_getInstance();
    Kind_entriesInitialized = true;
    Kind_ERROR_instance = new Kind('ERROR', 0, 0, Color_RED_getInstance());
    Kind_WARN_instance = new Kind('WARN', 1, 1, Color_YELLOW_getInstance());
    Kind_INFO_instance = new Kind('INFO', 2, 2, Color_BLUE_getInstance());
    Kind_DEBUG_instance = new Kind('DEBUG', 3, 3, Color_CYAN_getInstance());
    Kind_TRACE_instance = new Kind('TRACE', 4, 4, Color_GREEN_getInstance());
    Kind_LOG_instance = new Kind('LOG', 5, 5, null);
  }
  function Kind(name, ordinal, level, color) {
    Enum.call(this, name, ordinal);
    this.l1m_1 = level;
    this.m1m_1 = color;
  }
  function BaseConsole$logInternal$ref($boundThis) {
    var l = function (p0, p1) {
      $boundThis.n1m(p0, p1);
      return Unit_getInstance();
    };
    l.callableName = 'logInternal';
    return l;
  }
  function Kind_ERROR_getInstance() {
    Kind_initEntries();
    return Kind_ERROR_instance;
  }
  function Kind_WARN_getInstance() {
    Kind_initEntries();
    return Kind_WARN_instance;
  }
  function Kind_LOG_getInstance() {
    Kind_initEntries();
    return Kind_LOG_instance;
  }
  function BaseConsole() {
  }
  protoOf(BaseConsole).o1m = function (kind, msg) {
    var hook = baseConsoleHook;
    if (!(hook == null)) {
      hook(kind, msg, BaseConsole$logInternal$ref(this));
    } else {
      this.n1m(kind, msg.slice());
    }
  };
  protoOf(BaseConsole).n1m = function (kind, msg) {
    println(this.p1m(kind, msg.slice()));
  };
  protoOf(BaseConsole).p1m = function (kind, msg) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.buildString' call
    // Inline function 'kotlin.contracts.contract' call
    var tmp$ret$0;
    // Inline function 'kotlin.apply' call
    var tmp0_apply = StringBuilder_init_$Create$();
    // Inline function 'kotlin.contracts.contract' call
    // Inline function 'korlibs.logger.BaseConsole.logToString.<anonymous>' call
    var color = kind.m1m_1;
    if (!(color == null)) {
      this.i1m(tmp0_apply, color);
    }
    joinTo(msg, tmp0_apply, ', ');
    if (!(color == null)) {
      this.g1m(tmp0_apply);
    }
    tmp$ret$0 = tmp0_apply;
    tmp$ret$1 = tmp$ret$0.toString();
    return tmp$ret$1;
  };
  protoOf(BaseConsole).q1m = function (msg) {
    return this.o1m(Kind_LOG_getInstance(), msg.slice());
  };
  protoOf(BaseConsole).r1m = function (msg) {
    return this.o1m(Kind_WARN_getInstance(), msg.slice());
  };
  protoOf(BaseConsole).s1m = function (msg) {
    return this.o1m(Kind_ERROR_getInstance(), msg.slice());
  };
  function normalizeName($this, name) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.uppercase' call
    var tmp0_uppercase = replace(replace(name, _Char___init__impl__6a9atx(46), _Char___init__impl__6a9atx(95)), _Char___init__impl__6a9atx(47), _Char___init__impl__6a9atx(95));
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = tmp0_uppercase;
    tmp$ret$1 = tmp$ret$0.toUpperCase();
    return tmp$ret$1;
  }
  var Level_NONE_instance;
  var Level_FATAL_instance;
  var Level_ERROR_instance;
  var Level_WARN_instance;
  var Level_INFO_instance;
  var Level_DEBUG_instance;
  var Level_TRACE_instance;
  function Companion() {
    Companion_instance = this;
    var tmp = this;
    var tmp$ret$2;
    // Inline function 'kotlin.collections.associateBy' call
    var tmp1_associateBy = values();
    var capacity = coerceAtLeast(mapCapacity(tmp1_associateBy.length), 16);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.associateByTo' call
    var tmp0_associateByTo = LinkedHashMap_init_$Create$(capacity);
    var indexedObject = tmp1_associateBy;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp$ret$0;
      // Inline function 'korlibs.logger.Companion.BY_NAME.<anonymous>' call
      tmp$ret$0 = element.a2_1;
      tmp0_associateByTo.e5(tmp$ret$0, element);
    }
    tmp$ret$1 = tmp0_associateByTo;
    tmp$ret$2 = tmp$ret$1;
    tmp.t1m_1 = tmp$ret$2;
  }
  protoOf(Companion).p1b = function (name) {
    var tmp$ret$1;
    // Inline function 'kotlin.text.uppercase' call
    var tmp$ret$0;
    // Inline function 'kotlin.js.asDynamic' call
    tmp$ret$0 = name;
    tmp$ret$1 = tmp$ret$0.toUpperCase();
    var tmp0_elvis_lhs = this.t1m_1.h3(tmp$ret$1);
    return tmp0_elvis_lhs == null ? Level_NONE_getInstance() : tmp0_elvis_lhs;
  };
  var Companion_instance;
  function Companion_getInstance() {
    Level_initEntries();
    if (Companion_instance == null)
      new Companion();
    return Companion_instance;
  }
  function values() {
    return [Level_NONE_getInstance(), Level_FATAL_getInstance(), Level_ERROR_getInstance(), Level_WARN_getInstance(), Level_INFO_getInstance(), Level_DEBUG_getInstance(), Level_TRACE_getInstance()];
  }
  function valueOf(value) {
    switch (value) {
      case 'NONE':
        return Level_NONE_getInstance();
      case 'FATAL':
        return Level_FATAL_getInstance();
      case 'ERROR':
        return Level_ERROR_getInstance();
      case 'WARN':
        return Level_WARN_getInstance();
      case 'INFO':
        return Level_INFO_getInstance();
      case 'DEBUG':
        return Level_DEBUG_getInstance();
      case 'TRACE':
        return Level_TRACE_getInstance();
      default:
        Level_initEntries();
        THROW_ISE();
        break;
    }
  }
  var Level_entriesInitialized;
  function Level_initEntries() {
    if (Level_entriesInitialized)
      return Unit_getInstance();
    Level_entriesInitialized = true;
    Level_NONE_instance = new Level('NONE', 0, 0);
    Level_FATAL_instance = new Level('FATAL', 1, 1);
    Level_ERROR_instance = new Level('ERROR', 2, 2);
    Level_WARN_instance = new Level('WARN', 3, 3);
    Level_INFO_instance = new Level('INFO', 4, 4);
    Level_DEBUG_instance = new Level('DEBUG', 5, 5);
    Level_TRACE_instance = new Level('TRACE', 6, 6);
    Companion_getInstance();
  }
  function Companion_0() {
    Companion_instance_0 = this;
    this.u1m_1 = new KloggerAtomicRef(emptyMap());
    this.v1m_1 = new KloggerAtomicRef(null);
    this.w1m_1 = new KloggerAtomicRef(DefaultLogOutput_getInstance());
  }
  protoOf(Companion_0).x1m = function (_set____db54di) {
    return setValue(this.v1m_1, this, defaultLevel$factory(), _set____db54di);
  };
  protoOf(Companion_0).y1m = function () {
    return getValue(this.v1m_1, this, defaultLevel$factory_0());
  };
  protoOf(Companion_0).z1m = function (_set____db54di) {
    return setValue(this.w1m_1, this, defaultOutput$factory(), _set____db54di);
  };
  protoOf(Companion_0).a1n = function () {
    return getValue(this.w1m_1, this, defaultOutput$factory_0());
  };
  protoOf(Companion_0).b1n = function (name) {
    var normalizedName = normalizeName(this, name);
    var tmp$ret$0;
    // Inline function 'korlibs.logger.get' call
    var tmp0_get = this.u1m_1;
    tmp$ret$0 = tmp0_get.c1n_1.h3(normalizedName);
    if (tmp$ret$0 == null) {
      var logger = new Logger(name, normalizedName, true);
      var tmp0_safe_receiver = get_miniEnvironmentVariablesUC().h3('LOG_' + normalizedName);
      if (tmp0_safe_receiver == null)
        null;
      else {
        var tmp$ret$1;
        // Inline function 'kotlin.also' call
        // Inline function 'kotlin.contracts.contract' call
        // Inline function 'korlibs.logger.Companion.invoke.<anonymous>' call
        logger.j1n(Companion_getInstance().p1b(tmp0_safe_receiver));
        tmp$ret$1 = tmp0_safe_receiver;
      }
      if (this.u1m_1.c1n_1.p()) {
        var tmp1_safe_receiver = get_miniEnvironmentVariablesUC().h3('LOG_LEVEL');
        if (tmp1_safe_receiver == null)
          null;
        else {
          var tmp$ret$2;
          // Inline function 'kotlin.also' call
          // Inline function 'kotlin.contracts.contract' call
          // Inline function 'korlibs.logger.Companion.invoke.<anonymous>' call
          Companion_getInstance_0().x1m(Companion_getInstance().p1b(tmp1_safe_receiver));
          tmp$ret$2 = tmp1_safe_receiver;
        }
      }
      // Inline function 'korlibs.logger.set' call
      var tmp2_set = this.u1m_1;
      // Inline function 'korlibs.logger.atomic.KloggerAtomicRef.update' call
      var tmp = tmp2_set;
      var tmp$ret$4;
      // Inline function 'korlibs.logger.set.<anonymous>' call
      var tmp1__anonymous__uwfjfc = tmp2_set.c1n_1;
      var tmp$ret$3;
      // Inline function 'kotlin.also' call
      var tmp0_also = HashMap_init_$Create$(tmp1__anonymous__uwfjfc);
      // Inline function 'kotlin.contracts.contract' call
      // Inline function 'korlibs.logger.set.<anonymous>.<anonymous>' call
      // Inline function 'kotlin.collections.set' call
      tmp0_also.e5(normalizedName, logger);
      tmp$ret$3 = tmp0_also;
      tmp$ret$4 = tmp$ret$3;
      tmp.c1n_1 = tmp$ret$4;
    }
    var tmp$ret$5;
    // Inline function 'korlibs.logger.get' call
    var tmp3_get = this.u1m_1;
    tmp$ret$5 = tmp3_get.c1n_1.h3(normalizedName);
    return ensureNotNull(tmp$ret$5);
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_0();
    return Companion_instance_0;
  }
  function Level(name, ordinal, index) {
    Enum.call(this, name, ordinal);
    this.m1n_1 = index;
  }
  function ConsoleLogOutput() {
    ConsoleLogOutput_instance = this;
  }
  protoOf(ConsoleLogOutput).n1n = function (logger, level, msg) {
    var tmp0_subject = level;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 2:
        Console_getInstance().s1m([logger.d1n_1, msg]);
        ;
        break;
      case 3:
        Console_getInstance().r1m([logger.d1n_1, msg]);
        ;
        break;
      default:
        Console_getInstance().q1m([logger.d1n_1, msg]);
        ;
        break;
    }
  };
  var ConsoleLogOutput_instance;
  function ConsoleLogOutput_getInstance() {
    if (ConsoleLogOutput_instance == null)
      new ConsoleLogOutput();
    return ConsoleLogOutput_instance;
  }
  function Level_NONE_getInstance() {
    Level_initEntries();
    return Level_NONE_instance;
  }
  function Level_FATAL_getInstance() {
    Level_initEntries();
    return Level_FATAL_instance;
  }
  function Level_ERROR_getInstance() {
    Level_initEntries();
    return Level_ERROR_instance;
  }
  function Level_WARN_getInstance() {
    Level_initEntries();
    return Level_WARN_instance;
  }
  function Level_INFO_getInstance() {
    Level_initEntries();
    return Level_INFO_instance;
  }
  function Level_DEBUG_getInstance() {
    Level_initEntries();
    return Level_DEBUG_instance;
  }
  function Level_TRACE_getInstance() {
    Level_initEntries();
    return Level_TRACE_instance;
  }
  function Logger(name, normalizedName, dummy) {
    Companion_getInstance_0();
    this.d1n_1 = name;
    this.e1n_1 = normalizedName;
    this.f1n_1 = dummy;
    this.g1n_1 = null;
    this.h1n_1 = null;
    this.i1n_1 = null;
  }
  protoOf(Logger).j1n = function (value) {
    this.h1n_1 = value;
  };
  protoOf(Logger).o1n = function () {
    var tmp0_elvis_lhs = this.h1n_1;
    var tmp1_elvis_lhs = tmp0_elvis_lhs == null ? Companion_getInstance_0().y1m() : tmp0_elvis_lhs;
    return tmp1_elvis_lhs == null ? Level_WARN_getInstance() : tmp1_elvis_lhs;
  };
  protoOf(Logger).p1n = function () {
    var tmp0_elvis_lhs = this.i1n_1;
    return tmp0_elvis_lhs == null ? Companion_getInstance_0().a1n() : tmp0_elvis_lhs;
  };
  protoOf(Logger).q1n = function (level) {
    return level.m1n_1 <= this.o1n().m1n_1;
  };
  protoOf(Logger).r1n = function (level, msg) {
    this.p1n().n1n(this, level, msg);
  };
  function defaultLevel$factory() {
    return getPropertyCallableRef('defaultLevel', 1, KMutableProperty1, function (receiver) {
      return receiver.y1m();
    }, function (receiver, value) {
      return receiver.x1m(value);
    });
  }
  function defaultLevel$factory_0() {
    return getPropertyCallableRef('defaultLevel', 1, KMutableProperty1, function (receiver) {
      return receiver.y1m();
    }, function (receiver, value) {
      return receiver.x1m(value);
    });
  }
  function defaultOutput$factory() {
    return getPropertyCallableRef('defaultOutput', 1, KMutableProperty1, function (receiver) {
      return receiver.a1n();
    }, function (receiver, value) {
      return receiver.z1m(value);
    });
  }
  function defaultOutput$factory_0() {
    return getPropertyCallableRef('defaultOutput', 1, KMutableProperty1, function (receiver) {
      return receiver.a1n();
    }, function (receiver, value) {
      return receiver.z1m(value);
    });
  }
  function getValue(_this__u8e3s4, receiver, property) {
    return _this__u8e3s4.c1n_1;
  }
  function setValue(_this__u8e3s4, receiver, property, value) {
    _this__u8e3s4.c1n_1 = value;
  }
  var _miniEnvironmentVariablesUC;
  function get_miniEnvironmentVariablesUC() {
    if (_miniEnvironmentVariablesUC == null) {
      var tmp$ret$6;
      // Inline function 'kotlin.collections.mapKeys' call
      var tmp2_mapKeys = get_miniEnvironmentVariables();
      var tmp$ret$5;
      // Inline function 'kotlin.collections.mapKeysTo' call
      var tmp1_mapKeysTo = LinkedHashMap_init_$Create$(mapCapacity(tmp2_mapKeys.l()));
      var tmp$ret$4;
      // Inline function 'kotlin.collections.associateByTo' call
      var tmp0_associateByTo = tmp2_mapKeys.d1();
      var tmp0_iterator = tmp0_associateByTo.h();
      while (tmp0_iterator.j()) {
        var element = tmp0_iterator.k();
        var tmp$ret$2;
        // Inline function 'korlibs.logger.internal.<get-miniEnvironmentVariablesUC>.<anonymous>' call
        var tmp$ret$1;
        // Inline function 'kotlin.text.uppercase' call
        var tmp0_uppercase = element.e1();
        var tmp$ret$0;
        // Inline function 'kotlin.js.asDynamic' call
        tmp$ret$0 = tmp0_uppercase;
        tmp$ret$1 = tmp$ret$0.toUpperCase();
        tmp$ret$2 = tmp$ret$1;
        var tmp = tmp$ret$2;
        var tmp$ret$3;
        // Inline function 'kotlin.collections.mapKeysTo.<anonymous>' call
        tmp$ret$3 = element.f1();
        tmp1_mapKeysTo.e5(tmp, tmp$ret$3);
      }
      tmp$ret$4 = tmp1_mapKeysTo;
      tmp$ret$5 = tmp$ret$4;
      tmp$ret$6 = tmp$ret$5;
      _miniEnvironmentVariablesUC = tmp$ret$6;
    }
    return ensureNotNull(_miniEnvironmentVariablesUC);
  }
  function Console() {
    Console_instance = this;
    BaseConsole.call(this);
  }
  protoOf(Console).n1m = function (kind, msg) {
    var tmp0_subject = kind;
    var tmp0 = tmp0_subject.b2_1;
    switch (tmp0) {
      case 0:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.error.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
      case 1:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.warn.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
      case 2:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.info.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
      case 3:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
      case 4:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
      case 5:
        (function () {
          var $externalVarargReceiverTmp = console;
          return $externalVarargReceiverTmp.log.apply($externalVarargReceiverTmp, [].slice.call(msg.slice()));
        }.call(this));
        ;
        break;
    }
  };
  var Console_instance;
  function Console_getInstance() {
    if (Console_instance == null)
      new Console();
    return Console_instance;
  }
  function DefaultLogOutput() {
    DefaultLogOutput_instance = this;
  }
  protoOf(DefaultLogOutput).n1n = function (logger, level, msg) {
    return ConsoleLogOutput_getInstance().n1n(logger, level, msg);
  };
  var DefaultLogOutput_instance;
  function DefaultLogOutput_getInstance() {
    if (DefaultLogOutput_instance == null)
      new DefaultLogOutput();
    return DefaultLogOutput_instance;
  }
  function KloggerAtomicRef(initial) {
    this.c1n_1 = initial;
  }
  function get_miniEnvironmentVariables() {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp$ret$0;
    // Inline function 'kotlin.getValue' call
    var tmp0_getValue = miniEnvironmentVariables$factory();
    tmp$ret$0 = miniEnvironmentVariables$delegate.f1();
    return tmp$ret$0;
  }
  var miniEnvironmentVariables$delegate;
  function jsObjectToMap(obj) {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp$ret$2;
    // Inline function 'kotlin.collections.associate' call
    var tmp2_associate = jsObjectKeysArray(obj);
    var capacity = coerceAtLeast(mapCapacity(tmp2_associate.length), 16);
    var tmp$ret$1;
    // Inline function 'kotlin.collections.associateTo' call
    var tmp1_associateTo = LinkedHashMap_init_$Create$(capacity);
    var indexedObject = tmp2_associate;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      // Inline function 'kotlin.collections.plusAssign' call
      var tmp$ret$0;
      // Inline function 'korlibs.logger.internal.jsObjectToMap.<anonymous>' call
      tmp$ret$0 = to(element, obj[element]);
      var tmp0_plusAssign = tmp$ret$0;
      tmp1_associateTo.e5(tmp0_plusAssign.a5_1, tmp0_plusAssign.b5_1);
    }
    tmp$ret$1 = tmp1_associateTo;
    tmp$ret$2 = tmp$ret$1;
    return tmp$ret$2;
  }
  function QueryString_decode(str) {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp$ret$0;
    // Inline function 'kotlin.collections.linkedMapOf' call
    tmp$ret$0 = LinkedHashMap_init_$Create$_0();
    var out = tmp$ret$0;
    // Inline function 'kotlin.collections.forEach' call
    var tmp0_forEach = split(str, charArrayOf([_Char___init__impl__6a9atx(38)]));
    var tmp0_iterator = tmp0_forEach.h();
    while (tmp0_iterator.j()) {
      var element = tmp0_iterator.k();
      // Inline function 'korlibs.logger.internal.QueryString_decode.<anonymous>' call
      var parts = split(element, charArrayOf([_Char___init__impl__6a9atx(61)]), VOID, 2);
      var key = parts.n(0);
      var tmp$ret$2;
      // Inline function 'kotlin.collections.getOrElse' call
      var tmp;
      if (1 <= get_lastIndex(parts)) {
        tmp = parts.n(1);
      } else {
        var tmp$ret$1;
        // Inline function 'korlibs.logger.internal.QueryString_decode.<anonymous>.<anonymous>' call
        tmp$ret$1 = key;
        tmp = tmp$ret$1;
      }
      tmp$ret$2 = tmp;
      var value = tmp$ret$2;
      var tmp$ret$5;
      // Inline function 'kotlin.collections.getOrPut' call
      var value_0 = out.h3(key);
      var tmp_0;
      if (value_0 == null) {
        var tmp$ret$4;
        // Inline function 'korlibs.logger.internal.QueryString_decode.<anonymous>.<anonymous>' call
        var tmp$ret$3;
        // Inline function 'kotlin.collections.arrayListOf' call
        tmp$ret$3 = ArrayList_init_$Create$();
        tmp$ret$4 = tmp$ret$3;
        var answer = tmp$ret$4;
        out.e5(key, answer);
        tmp_0 = answer;
      } else {
        tmp_0 = value_0;
      }
      tmp$ret$5 = tmp_0;
      var list = tmp$ret$5;
      // Inline function 'kotlin.collections.plusAssign' call
      list.b(value);
    }
    return out;
  }
  function jsObjectKeysArray(obj) {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp = jsToArray(jsObjectKeys(obj));
    return isArray(tmp) ? tmp : THROW_CCE();
  }
  function jsToArray(obj) {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp = 0;
    var tmp_0 = obj.length;
    var tmp$ret$0;
    // Inline function 'kotlin.arrayOfNulls' call
    tmp$ret$0 = fillArrayVal(Array(tmp_0), null);
    var tmp_1 = tmp$ret$0;
    while (tmp < tmp_0) {
      var tmp_2 = tmp;
      var tmp$ret$1;
      // Inline function 'korlibs.logger.internal.jsToArray.<anonymous>' call
      tmp$ret$1 = obj[tmp_2];
      tmp_1[tmp_2] = tmp$ret$1;
      tmp = tmp + 1 | 0;
    }
    return tmp_1;
  }
  function jsObjectKeys(obj) {
    _init_properties_environmentVariablesJs_kt__w01231();
    return Object.keys(obj);
  }
  function miniEnvironmentVariables$delegate$lambda() {
    _init_properties_environmentVariablesJs_kt__w01231();
    var tmp;
    if (!(typeof process === 'undefined')) {
      tmp = jsObjectToMap(process.env);
    } else if (!(typeof Deno === 'undefined')) {
      tmp = jsObjectToMap(Deno.env);
    } else {
      var tmp$ret$3;
      // Inline function 'kotlin.collections.map' call
      var tmp0_safe_receiver = document.location;
      var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.search;
      var tmp1_map = QueryString_decode(trimStart(tmp1_elvis_lhs == null ? '' : tmp1_elvis_lhs, charArrayOf([_Char___init__impl__6a9atx(63)])));
      var tmp$ret$2;
      // Inline function 'kotlin.collections.mapTo' call
      var tmp0_mapTo = ArrayList_init_$Create$_0(tmp1_map.l());
      var tmp$ret$0;
      // Inline function 'kotlin.collections.iterator' call
      tmp$ret$0 = tmp1_map.d1().h();
      var tmp0_iterator = tmp$ret$0;
      while (tmp0_iterator.j()) {
        var item = tmp0_iterator.k();
        var tmp$ret$1;
        // Inline function 'korlibs.logger.internal.miniEnvironmentVariables$delegate.<anonymous>.<anonymous>' call
        var tmp_0 = item.e1();
        var tmp0_elvis_lhs = firstOrNull(item.f1());
        tmp$ret$1 = to(tmp_0, tmp0_elvis_lhs == null ? item.e1() : tmp0_elvis_lhs);
        tmp0_mapTo.b(tmp$ret$1);
      }
      tmp$ret$2 = tmp0_mapTo;
      tmp$ret$3 = tmp$ret$2;
      tmp = toMap(tmp$ret$3);
    }
    return tmp;
  }
  function miniEnvironmentVariables$factory() {
    return getPropertyCallableRef('miniEnvironmentVariables', 0, KProperty0, function () {
      return get_miniEnvironmentVariables();
    }, null);
  }
  var properties_initialized_environmentVariablesJs_kt_1pqsun;
  function _init_properties_environmentVariablesJs_kt__w01231() {
    if (properties_initialized_environmentVariablesJs_kt_1pqsun) {
    } else {
      properties_initialized_environmentVariablesJs_kt_1pqsun = true;
      miniEnvironmentVariables$delegate = lazy(miniEnvironmentVariables$delegate$lambda);
    }
  }
  var isNative;
  //region block: post-declaration
  protoOf(BaseConsole).e1m = appendAnsiScape;
  protoOf(BaseConsole).f1m = appendAnsiScape$default;
  protoOf(BaseConsole).g1m = appendReset;
  protoOf(BaseConsole).h1m = appendFgColor;
  protoOf(BaseConsole).i1m = appendFgColor$default;
  protoOf(Console).e1m = appendAnsiScape;
  protoOf(Console).f1m = appendAnsiScape$default;
  protoOf(Console).g1m = appendReset;
  protoOf(Console).h1m = appendFgColor;
  protoOf(Console).i1m = appendFgColor$default;
  //endregion
  //region block: init
  baseConsoleHook = null;
  _miniEnvironmentVariablesUC = null;
  isNative = false;
  //endregion
  //region block: exports
  _.$_$ = _.$_$ || {};
  _.$_$.a = Level_DEBUG_getInstance;
  _.$_$.b = Level_ERROR_getInstance;
  _.$_$.c = Level_INFO_getInstance;
  _.$_$.d = Level_TRACE_getInstance;
  _.$_$.e = Level_WARN_getInstance;
  _.$_$.f = Companion_getInstance_0;
  _.$_$.g = valueOf;
  //endregion
  return _;
}));
